var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./step-customize-workspace.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import DocumentIcon from "app/components/common/document-icon";
import FeaturesModal from "app/components/common/features-modal";
import generateGuid from "app/utils/generateGuid";
import DocumentsModal from "app/components/common/documents-modal";
import FontIcon from "app/components/common/font-icon";
import TextTruncate from "react-text-truncate"; // recommend
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import { FormattedMessage } from "react-intl";
import MarkdownEditor from "app/components/common/form-markdowneditor";
var StepCustomizeWorkspace = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var rwc = React.useContext(RequestWizardContext);
    var _u = React.useState([]), availableTemplatesSelectOptions = _u[0], setAvailableTemplatesSelectOptions = _u[1];
    // on first run, set the state to can proceed.
    React.useEffect(function () {
        if (props.step.canProgress === false) {
            rwc.setStepProgress(props.step.key, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        if (rwc.availableTemplates.length > 0) {
            if (availableTemplatesSelectOptions.length === 0) {
                var options_1 = [];
                rwc.availableTemplates.forEach(function (availTemplate) {
                    options_1.push({
                        id: availTemplate.GUID !== undefined ? availTemplate.GUID : "",
                        title: availTemplate.Title !== undefined ? availTemplate.Title : "",
                        value: availTemplate.GUID !== undefined ? availTemplate.GUID : ""
                    });
                });
                setAvailableTemplatesSelectOptions(options_1);
            }
        }
    }, [rwc.availableTemplates, availableTemplatesSelectOptions]);
    var onTemplateChange = function (updatedTemplateGuid) {
        var foundTemplate = rwc.availableTemplates.find(function (t) { return t.GUID === updatedTemplateGuid; });
        if (foundTemplate !== undefined) {
            rwc.updateCurrentRequest({ Template: foundTemplate });
        }
    };
    var getFeatureIcon = function (feature) {
        if (feature !== undefined) {
            var featureIcon = feature.Icon;
            if (featureIcon !== undefined) {
                return React.createElement(LineAwesomeIcon, { iconString: featureIcon });
            }
        }
        else {
            return React.createElement(FontAwesomeIcon, { icon: Icons.faBox });
        }
    };
    var getDocumentIcon = function (documentTempl) {
        if (documentTempl !== undefined && documentTempl.Type !== undefined) {
            return React.createElement(DocumentIcon, { docType: documentTempl.Type });
        }
    };
    var removeFeature = function (tbaToRemove) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.AssociatedFeatures) === null || _a === void 0 ? void 0 : _a.filter(function (feat) { return feat.GUID !== tbaToRemove.GUID; });
        if (tempArray === undefined) {
            tempArray = [];
        }
        rwc.updateCurrentRequest({ AssociatedFeatures: __spreadArray([], tempArray, true) });
    };
    var addFeatures = function (feats) {
        var tempArray = [];
        feats.forEach(function (f) {
            var newTBA = {
                GUID: generateGuid(),
                Item: {
                    GUID: f.GUID,
                    Title: f.Title,
                    Description: f.Description,
                    Icon: f.Icon
                }
            };
            tempArray.push(newTBA);
        });
        if (rwc.currentRequest.AssociatedFeatures !== undefined) {
            if (rwc.currentRequest.AssociatedFeatures.length > 0) {
                tempArray = __spreadArray(__spreadArray([], rwc.currentRequest.AssociatedFeatures, true), tempArray, true);
            }
        }
        rwc.updateCurrentRequest({ AssociatedFeatures: tempArray });
        // console.log("ADD FEATURE!", feat);
    };
    var addDocs = function (docTemplates) {
        var tempArray = [];
        docTemplates.forEach(function (dt) {
            var newTBA = {
                GUID: generateGuid(),
                Item: {
                    GUID: dt.GUID,
                    Title: dt.Title,
                    Description: dt.Description,
                    Type: dt.Type
                }
            };
            tempArray.push(newTBA);
        });
        if (rwc.currentRequest.AssociatedDocumentTemplates !== undefined) {
            if (rwc.currentRequest.AssociatedDocumentTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], rwc.currentRequest.AssociatedDocumentTemplates, true), tempArray, true);
            }
        }
        rwc.updateCurrentRequest({ AssociatedDocumentTemplates: tempArray });
        // console.log("ADD FEATURE!", feat);
    };
    var removeDoc = function (tbaToRemove) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.AssociatedDocumentTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (temp) { return temp.GUID !== tbaToRemove.GUID; });
        if (tempArray === undefined) {
            tempArray = [];
        }
        rwc.updateCurrentRequest({ AssociatedDocumentTemplates: __spreadArray([], tempArray, true) });
    };
    var canFeatBeRemoved = function (feat) {
        var returnString = "";
        if (feat !== undefined) {
            if (rwc.currentRequest.Template !== undefined) {
                if (rwc.currentRequest.Template.AssociatedFeatures !== undefined) {
                    rwc.currentRequest.Template.AssociatedFeatures.forEach(function (assFeat) {
                        if (assFeat.Item !== undefined) {
                            if (assFeat.Item.GUID === feat.GUID) {
                                if (assFeat.Required === false) {
                                    //console.log("Working?")
                                    returnString = " can-be-removed";
                                }
                            }
                        }
                    });
                    if (rwc.currentRequest.Template.AssociatedFeatures.length === 0) {
                        returnString = " can-be-removed";
                    }
                }
                else {
                    returnString = " can-be-removed";
                }
            }
        }
        return returnString;
    };
    var canDocBeRemoved = function (doc) {
        var returnString = "";
        var isRequired = false;
        //console.log("is required? doc ", doc);
        //console.log("is required?", rwc.currentRequest.Template?.AssociatedDocumentTemplates)
        if (doc !== undefined) {
            if (rwc.currentRequest.Template !== undefined) {
                if (rwc.currentRequest.Template.AssociatedDocumentTemplates !== undefined) {
                    rwc.currentRequest.Template.AssociatedDocumentTemplates.forEach(function (assDoc) {
                        if (assDoc.Item !== undefined) {
                            if (assDoc.Item.GUID === doc.GUID) {
                                if (assDoc.Required === true) {
                                    isRequired = true;
                                }
                            }
                        }
                    });
                }
            }
        }
        if (isRequired === false) {
            returnString = " can-be-removed";
        }
        return returnString;
    };
    var renderFeatureBoxContent = function (assFeat) {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "icon feats" }, getFeatureIcon(assFeat.Item)),
            React.createElement("div", { className: "locked" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLock })),
            React.createElement("div", { className: "remove", onClick: function () {
                    removeFeature(assFeat);
                } },
                React.createElement(FontAwesomeIcon, { icon: Icons.faTimes })),
            React.createElement("div", { className: "title" },
                React.createElement(TextTruncate, { line: 2, element: "span", truncateText: "\u2026", text: (_a = assFeat.Item) === null || _a === void 0 ? void 0 : _a.Title }))));
    };
    var renderDocTempBoxContent = function (assDoc) {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "icon docs" }, getDocumentIcon(assDoc.Item)),
            React.createElement("div", { className: "locked" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLock })),
            React.createElement("div", { className: "remove", onClick: function () {
                    removeDoc(assDoc);
                } },
                React.createElement(FontAwesomeIcon, { icon: Icons.faTimes })),
            React.createElement("div", { className: "title" },
                React.createElement(TextTruncate, { line: 2, maxCalculateTimes: 20, element: "span", truncateText: "\u2026", text: (_a = assDoc.Item) === null || _a === void 0 ? void 0 : _a.Title }))));
    };
    return (React.createElement("div", { className: "step-customize-workspace" },
        React.createElement("div", { className: "your-workspace", "data-pw": "your-workspace" }, rwc.currentRequest.Template !== undefined ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "selected-workspace-thumb" },
                React.createElement("div", { className: "title" },
                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.selected_workspace", defaultMessage: "Selected Workspace" })),
                React.createElement("div", { className: "preview-image" }, rwc.currentRequest.Template.Image !== null && rwc.currentRequest.Template.Image !== "" ? (React.createElement("div", { className: "image", style: { backgroundImage: "url('" + rwc.currentRequest.Template.Image + "')" } })) : (React.createElement("div", { className: "missing-image" },
                    React.createElement(FontIcon, { iconString: "cubes" }))))),
            React.createElement("div", { className: "workspace-title", "data-pw": "workspace-title" },
                availableTemplatesSelectOptions.length > 0 ? (React.createElement("h3", null, rwc.currentRequest.Template.Title)) : (
                // <FormDropdownSelect
                // // enableSearch={true}
                // list={availableTemplatesSelectOptions}
                // onChange={(selectedTemplateGuid)=>{onTemplateChange(selectedTemplateGuid)}}
                // defaultValue={rwc.currentRequest.Template.GUID}
                // />
                React.createElement(React.Fragment, null)),
                React.createElement("div", { className: "description" },
                    React.createElement(MarkdownEditor, { enabledStyles: { all: true }, readOnly: true, templateText: rwc.currentRequest.Template.Description }))))) : (React.createElement(React.Fragment, null))),
        rwc.currentRequest.Template !== undefined && ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.AssociatedFeatures) !== undefined && ((_c = (_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.AssociatedFeatures) === null || _c === void 0 ? void 0 : _c.length) !== 0 ? (React.createElement("div", { className: "features", "data-pw": "features" },
            React.createElement("div", { className: "section" },
                React.createElement("div", { className: "title" },
                    React.createElement("span", { className: "text" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.features", defaultMessage: "Features" })),
                    React.createElement("span", { className: "bull" }, "\u2022"),
                    React.createElement("span", { className: "count" },
                        " ", (_d = rwc.currentRequest.AssociatedFeatures) === null || _d === void 0 ? void 0 :
                        _d.length,
                        " ",
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.selected", defaultMessage: "Selected" }))),
                React.createElement("div", { className: "items" },
                    rwc.currentRequest.AssociatedFeatures !== undefined &&
                        rwc.currentRequest.AssociatedFeatures.length !== ((_f = (_e = rwc.currentRequest.Template) === null || _e === void 0 ? void 0 : _e.AssociatedFeatures) === null || _f === void 0 ? void 0 : _f.length) &&
                        rwc.currentRequest.Template !== undefined &&
                        rwc.currentRequest.Template.AllowFeatureCustomization !== false ? (React.createElement(FeaturesModal, { handleSelected: function (feats) {
                            addFeatures(feats);
                        }, associatedFeatures: rwc.currentRequest.AssociatedFeatures, availableFeatures: ((_h = (_g = rwc.currentRequest.Template) === null || _g === void 0 ? void 0 : _g.AssociatedFeatures) === null || _h === void 0 ? void 0 : _h.filter(function (ass) {
                            return !ass.Required;
                        })).map(function (ass) { return ass.Item; }) },
                        React.createElement("div", { className: "add-item", "data-pw": "add-item" },
                            React.createElement("div", { className: "icon-holder" },
                                React.createElement(FontAwesomeIcon, { icon: Icons.faPlus })),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.add_feature", defaultMessage: "Add Feature" }))))) : (React.createElement(React.Fragment, null)),
                    rwc.currentRequest.AssociatedFeatures !== undefined && rwc.currentRequest.AssociatedFeatures !== undefined && rwc.currentRequest.AssociatedFeatures.length > 0 ? (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedFeatures.map(function (assFeat) { return (React.createElement("div", { className: "item" + canFeatBeRemoved(assFeat.Item), key: assFeat.GUID }, canFeatBeRemoved(assFeat.Item) === "" ? (
                    // <InfoTooltip content="Preset features cannot be removed" placement="top">
                    React.createElement(React.Fragment, null, renderFeatureBoxContent(assFeat))) : (
                    // </InfoTooltip>
                    renderFeatureBoxContent(assFeat)))); }))) : (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedFeatures !== undefined && rwc.currentRequest.AssociatedFeatures.length !== ((_k = (_j = rwc.currentRequest.Template) === null || _j === void 0 ? void 0 : _j.AssociatedFeatures) === null || _k === void 0 ? void 0 : _k.length) ? (
                    // <div className="list-empty">No features selected</div>
                    React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "list-empty" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.no_features_available", defaultMessage: "No features available" }))))))))) : null,
        rwc.currentRequest.Template !== undefined &&
            ((_l = rwc.currentRequest.Template) === null || _l === void 0 ? void 0 : _l.AssociatedDocumentTemplates) !== undefined &&
            ((_o = (_m = rwc.currentRequest.Template) === null || _m === void 0 ? void 0 : _m.AssociatedDocumentTemplates) === null || _o === void 0 ? void 0 : _o.length) !== 0 ? (React.createElement("div", { className: "doc-templates", "data-pw": "doc-templates" },
            React.createElement("div", { className: "section" },
                React.createElement("div", { className: "title" },
                    React.createElement("span", { className: "text" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.document_templates", defaultMessage: "Document Templates" })),
                    React.createElement("span", { className: "bull" }, "\u2022"),
                    React.createElement("span", { className: "count" },
                        " ", (_p = rwc.currentRequest.AssociatedDocumentTemplates) === null || _p === void 0 ? void 0 :
                        _p.length,
                        " ",
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.selected", defaultMessage: "Selected" }))),
                React.createElement("div", { className: "items" },
                    rwc.currentRequest !== undefined &&
                        rwc.currentRequest.AssociatedDocumentTemplates !== undefined &&
                        rwc.currentRequest.Template !== undefined &&
                        rwc.currentRequest.Template.AllowDocumentsCustomization !== false ? (React.createElement(DocumentsModal, { handleSelected: function (doctemps) {
                            addDocs(doctemps);
                        }, showUpload: false, showSuggestions: true, associatedDocumentTemplates: rwc.currentRequest.AssociatedDocumentTemplates, availableDocumentTemplates: ((_r = (_q = rwc.currentRequest.Template) === null || _q === void 0 ? void 0 : _q.AssociatedDocumentTemplates) === null || _r === void 0 ? void 0 : _r.filter(function (ass) {
                            return !ass.Required;
                        })).map(function (ass) { return ass.Item; }) },
                        React.createElement("div", { className: "add-item", "data-pw": "add-item" },
                            React.createElement("div", { className: "icon-holder" },
                                React.createElement(FontAwesomeIcon, { icon: Icons.faPlus })),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.add_template", defaultMessage: "Add Template" }))))) : (React.createElement(React.Fragment, null)),
                    rwc.currentRequest !== undefined && rwc.currentRequest.AssociatedDocumentTemplates !== undefined && rwc.currentRequest.AssociatedDocumentTemplates.length !== 0 ? (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedDocumentTemplates.map(function (assDoc) { return (React.createElement("div", { className: "item" + canDocBeRemoved(assDoc.Item), key: assDoc.GUID }, canDocBeRemoved(assDoc.Item) === "" ? (
                    // <InfoTooltip content="Preset document templates cannot be removed" placement="top">
                    React.createElement(React.Fragment, null, renderDocTempBoxContent(assDoc))) : (
                    // </InfoTooltip>
                    renderDocTempBoxContent(assDoc)))); }))) : (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedDocumentTemplates !== undefined &&
                        rwc.currentRequest.AssociatedDocumentTemplates.length !== ((_t = (_s = rwc.currentRequest.Template) === null || _s === void 0 ? void 0 : _s.AssociatedDocumentTemplates) === null || _t === void 0 ? void 0 : _t.length) ? (
                    // <div className="list-empty">No features selected</div>
                    React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "list-empty" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.no_document_templates", defaultMessage: "No document templates" }))))))))) : null));
});
export default StepCustomizeWorkspace;
