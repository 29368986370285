import * as React from "react";
var LineAwesomeIcon = React.memo(function (props) {
    var _a = React.useState(props.iconString), iconString = _a[0], setIconString = _a[1];
    React.useEffect(function () {
        var _a;
        if (props.iconString !== undefined && ((_a = props.iconString) === null || _a === void 0 ? void 0 : _a.indexOf("la")) === -1) {
            setIconString("la la-" + props.iconString);
        }
        else {
            setIconString(props.iconString);
        }
    }, [props.iconString]);
    if (iconString !== undefined) {
        return (React.createElement("i", { className: iconString })
        // <i className={"la la-" + iconString} />
        );
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
export default LineAwesomeIcon;
