var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./cards/step-card.scss";
import { Redirect } from "react-router-dom";
import StepCardName from "./cards/1-card-name";
import StepCardPolicyExecution from "./cards/2-policy-execution";
import StepCardNotificationFormat from "./cards/3-card-notification-format";
import LifecycleWizard from "app/components/insights/common/lifecycle-wizard";
import LifecycleTabs from "app/components/insights/common/lifecycle-tabs";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { useDispatch, useSelector } from "react-redux";
import Store from "app/store";
import * as LifecycleGuestPolicyListStore from "app/store/lifecycle-guest-policies/list";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current/";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
var GuestReviewDetails = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var _a = React.useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var dispatch = useDispatch();
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    React.useEffect(function () {
        if (props.match.params.guid === undefined || props.match.params.guid === null) {
            dispatch(LifecycleGuestPolicyCurrentStore.Clear());
            dispatch(LifecycleGuestPolicyCurrentStore.Update(LifecycleGuestPolicyCurrentStore.createEmptyPolicy(LifecycleGuestPolicyType.Review)));
        }
        else if (props.match.params.guid) {
            dispatch(LifecycleGuestPolicyCurrentStore.Load(LifecycleGuestPolicyType.Review, props.match.params.guid));
        }
    }, [props.match.params.guid]);
    React.useEffect(function () {
        if (currentPolicy.GUID !== undefined) {
            setIsLoading(false);
        }
    }, [currentPolicy]);
    var wizardSteps = React.useState([
        {
            title: "Name",
            key: "name",
            card: StepCardName,
            complete: false,
            current: true,
            hidden: false
        },
        {
            title: "Policy Execution",
            key: "policyexecution",
            card: StepCardPolicyExecution,
            current: false,
            complete: false,
            hidden: false
        },
        {
            title: "Notification Format",
            key: "notificationformat",
            card: StepCardNotificationFormat,
            current: false,
            complete: false,
            hidden: false
        }
    ])[0];
    var tabs = React.useState([
        {
            title: "Name",
            key: "name",
            card: StepCardName,
            valid: true,
            hidden: false
        },
        {
            title: "Policy Execution",
            key: "policyexecution",
            card: StepCardPolicyExecution,
            valid: true,
            hidden: false
        },
        {
            title: "Notification Format",
            key: "notificationformat",
            card: StepCardNotificationFormat,
            valid: true,
            hidden: false
        }
    ])[0];
    var save = function () {
        var asyncCallType = AsyncCallType.Update;
        if (props.match.params.guid === undefined || props.match.params.guid.indexOf("clone") > -1) {
            // First, add the new template to template list
            asyncCallType = AsyncCallType.Add;
            dispatch(LifecycleGuestPolicyListStore.AddCurrentItem());
        }
        else {
            dispatch(LifecycleGuestPolicyListStore.UpdateCurrentItem());
        }
        var state = Store.getState();
        var thePolicy = state.lifecycleGuestPoliciesCurrentState.item;
        if (thePolicy.GUID != null) {
            console.log("Setup Wait", thePolicy);
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, thePolicy.GUID).then(function () {
                console.log("Save - Create/Update for policy complete");
                setBackToList(true);
                dispatch(LifecycleGuestPolicyCurrentStore.Clear());
            });
        }
    };
    var clearCurrentTemplate = function () {
        dispatch(LifecycleGuestPolicyCurrentStore.Clear());
    };
    var _c = React.useState({
        title: "Policies",
        subTitle: "Create Guest Review Policy",
        buttonCancelText: "Cancel",
        buttonFinishText: "Finish",
        buttonPreviousText: "Previous",
        buttonNextText: "Save and continue to next step",
        navTitle: "Back to Guest Policies",
        navRouteString: "/guests/policies/",
        onSave: save
    }), wizardConfig = _c[0], setWizardConfig = _c[1];
    var _d = React.useState({
        title: "Guest Review Policy",
        titleLink: "/guests/policies/",
        buttonCancelText: "Cancel",
        buttonSaveText: "Save",
        onSave: save,
        onClear: clearCurrentTemplate
    }), tabsConfig = _d[0], setTabsConfig = _d[1];
    var getWizardSteps = function () {
        return wizardSteps.map(function (ws) { return (__assign(__assign({}, ws), { hidden: false })); });
    };
    var getTabs = function () {
        return tabs.map(function (ws) { return (__assign(__assign({}, ws), { hidden: false })); });
    };
    return backToList ? (React.createElement(Redirect, { to: "/guests/policies" })) : (React.createElement(React.Fragment, null, props.match.params.guid === undefined ? (React.createElement(LifecycleWizard, { config: wizardConfig, stepss: getWizardSteps() })) : props.match.params.guid.indexOf("clone") > -1 ? (React.createElement(LifecycleWizard, { config: wizardConfig, stepss: getWizardSteps() })) : (React.createElement(React.Fragment, null,
        React.createElement(LifecycleTabs, { isLoading: isLoading, config: tabsConfig, tabs: getTabs() })))));
});
export default GuestReviewDetails;
