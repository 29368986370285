var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { TenantListActionTypes } from "./types";
export function Load(tenants) {
    if (tenants === undefined)
        tenants = [];
    return {
        type: TenantListActionTypes.Tenant_List_Load,
        payload: __spreadArray([], tenants, true)
    };
}
export function Load_Page(state) {
    if (state === undefined)
        state = {};
    return {
        type: TenantListActionTypes.Tenant_List_Load_Page,
        payload: state
    };
}
export function Refresh(tenants) {
    return {
        type: TenantListActionTypes.Tenant_List_Refresh,
        payload: []
    };
}
export function LoadForRequest() {
    return {
        type: TenantListActionTypes.Tenant_List_LoadForRequest,
        payload: []
    };
}
export function AddItem(tenant) {
    return {
        type: TenantListActionTypes.Tenant_List_Item_Add,
        payload: __assign({}, tenant)
    };
}
export function UpdateItem(tenant) {
    return {
        type: TenantListActionTypes.Tenant_List_Item_Update,
        payload: __assign({}, tenant)
    };
}
export function DeleteItem(tenant) {
    return {
        type: TenantListActionTypes.Tenant_List_Item_Delete,
        payload: __assign({}, tenant)
    };
}
export var tenantListMiddlware = function (store, next, action) {
    next(action);
};
