import { TenantInsight } from "appv2/api/partner-tools/msp-tenant-details";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
export var TENANT_INSIGHTS_FILTERS_LIST = [
    {
        type: "selectable",
        title: "Trial",
        value: TenantInsight.Trial,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.scales01,
        color: "var(--mantine-color-purple-8)",
        tooltip: "Tenant is in the 28-day trial of Orchestry."
    },
    {
        type: "selectable",
        title: "At Risk",
        value: TenantInsight.AtRisk,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.lightning02,
        color: "var(--mantine-color-error-8)",
        tooltip: "Tenant is considered at risk due to presence of at least three Orchestry under-utilization flags (e.g., No Templates)."
    },
    {
        type: "selectable",
        title: "Disabled Review",
        value: TenantInsight.Disabled,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.reviewProcess,
        color: "var(--mantine-color-blue-8)",
        tooltip: "Tenant has disabled Reviews of its Health Checks."
    },
    {
        type: "selectable",
        title: "Self-led Review",
        value: TenantInsight.SelfLed,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.reviewProcess,
        color: "var(--mantine-color-blue-8)",
        tooltip: "Tenant has elected to perform the Reviews of its Health Checks internally."
    },
    {
        type: "selectable",
        title: "Partner-led Review",
        value: TenantInsight.PartnerLed,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.reviewProcess,
        color: "var(--mantine-color-blue-8)",
        tooltip: "Tenant has elected to delegate Reviews of its Health Checks to you."
    },
    {
        type: "selectable",
        title: "Update Required",
        value: TenantInsight.UpdateRequired,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.arrowCircleBrokenUp,
        tooltip: "Tenant is not using the latest version of Orchestry and should update."
    },
    {
        type: "selectable",
        title: "No Metadata",
        value: TenantInsight.NoMetadata,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.tag01,
        tooltip: "Tenant has no custom Orchestry Workspace Metadata defined"
    },
    {
        type: "selectable",
        title: "No Templates",
        value: TenantInsight.NoTemplates,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.perspective02,
        tooltip: "Tenant has no custom Workspace Templates defined."
    },
    {
        type: "selectable",
        title: "No Naming Conventions",
        value: TenantInsight.NoNamingConventions,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.underline01,
        tooltip: "Tenant has no custom Orchestry Workspace Naming Conventions defined."
    },
    {
        type: "selectable",
        title: "No Guest Requests",
        value: TenantInsight.NoGuestRequests,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.userPlus01,
        tooltip: "Tenant has no custom Orchestry Guest Request policies."
    },
    {
        type: "selectable",
        title: "Never Provisioning",
        value: TenantInsight.NeverProvisioning,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.playSquare,
        tooltip: "Tenant has not performed a single Orchestry workspace provisioning request."
    },
    {
        type: "selectable",
        title: "No Lifecycle",
        value: TenantInsight.NoLifecycle,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.repeat04,
        tooltip: "Tenant has no custom Orchestry Workspace Lifecycle policies."
    },
    {
        type: "selectable",
        title: "Never Archived",
        value: TenantInsight.NeverArchived,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.archive,
        tooltip: "Tenant has not performed a single Orchestry workspace archival."
    },
    {
        type: "selectable",
        title: "No Team Information Tab",
        value: TenantInsight.NoTeamInformationTab,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.table,
        tooltip: "Tenant does not have a single instance of the Orchestry Team Information Tab"
    },
    {
        type: "selectable",
        title: "Inactive Admins",
        value: TenantInsight.InactiveAdmins,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.usersEdit,
        tooltip: "Orchestry Administrators have not logged in for over 30 days."
    },
    {
        type: "selectable",
        title: "Inactive Users",
        value: TenantInsight.InactiveUsers,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.usersCheck,
        tooltip: "Orchestry user base accounts for less than 25% of total users."
    },
    {
        type: "selectable",
        title: "High Storage",
        value: TenantInsight.HighStorage,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.database,
        tooltip: "Tenant storage used is over 90% of its quota."
    },
    {
        type: "selectable",
        title: "Many Recommendations",
        value: TenantInsight.ManyRecommendations,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.checkVerified02,
        tooltip: "Tenant has more than 50% of total Orchestry recommendations in an Open or Snoozed state."
    },
    {
        type: "selectable",
        title: "No Guest Reviews",
        value: TenantInsight.NoGuestReviews,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.usersCheck,
        tooltip: "Tenant has no custom Orchestry Guest Review policies."
    },
    {
        type: "selectable",
        title: "New Customer",
        value: TenantInsight.NewCustomer,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.rocket02,
        tooltip: "Tenant installed Orchestry within the past three months."
    },
    {
        type: "selectable",
        title: "No Teams App",
        value: TenantInsight.NoTeamsApp,
        selected: false,
        isInArray: true,
        group: "Insights",
        icon: ORCH_ICONS.xCircle,
        tooltip: "The Orchestry Teams App is not currently installed."
    }
];
