import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export var healthChecksSettingsApi = createApi({
    reducerPath: "healthChecksSettingsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/HealthCheckSettings" }),
    tagTypes: ["HealthChecksSettings"],
    endpoints: function (builder) { return ({
        getHealthChecksSettings: builder.query({
            query: function () { return "/GetHealthCheckSettings"; },
            providesTags: ["HealthChecksSettings"]
        }),
        updateHealthChecksSettings: builder.mutation({
            query: function (updateHealthChecksSettingsCommand) { return ({
                url: "/UpdateHealthCheckSettings",
                method: "PUT",
                body: updateHealthChecksSettingsCommand
            }); }
        }),
        testNotifications: builder.mutation({
            query: function (testNotifications) { return ({
                url: "/TestNotifications",
                method: "PUT",
                body: testNotifications
            }); }
        })
    }); }
});
export var useGetHealthChecksSettingsQuery = healthChecksSettingsApi.useGetHealthChecksSettingsQuery, useUpdateHealthChecksSettingsMutation = healthChecksSettingsApi.useUpdateHealthChecksSettingsMutation, useTestNotificationsMutation = healthChecksSettingsApi.useTestNotificationsMutation;
