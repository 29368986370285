import { Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { orchestryToast } from "appv2/components/orchestry-notifications/orchestry-toast-notification.component";
import React from "react";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
export var OrchestryImageDropzoneRejectionToast = function (errorMessage) {
    orchestryToast(React.createElement(Text, { span: true, c: "text-primary.0", size: "sm" }, errorMessage), {
        autoClose: 2500,
        progressBarVariant: "error",
        toastId: "orchestry-image-dropzone-rejection-toast",
        icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "error-5", iconClassName: ORCH_ICONS.x })
    });
};
