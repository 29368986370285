var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./guest-request-workflow-column-actions.scss";
import { GuestRequestApprovalContext } from "app/components/lifecycle/guests/workflow/request/guest-request-workflow-insight-report";
import { SubtitleButton } from "app/components/common/subtitle";
import InfoTooltip from "app/components/common/infotooltip";
import OrchModal from "app/components/common/orch-modal";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
var GuestRequestWorkflowColumnAction = function (props) {
    var _a, _b;
    var guestRequestContext = React.useContext(GuestRequestApprovalContext);
    var _c = React.useState(null), currentAction = _c[0], setCurrentAction = _c[1];
    var _d = React.useState(false), showCommentBox = _d[0], setShowCommentBox = _d[1];
    var intl = useIntl();
    React.useEffect(function () {
        console.log("Request actions updated", guestRequestContext.requestActions);
        if (guestRequestContext.requestActions.some(function (rq) { return rq.userId === props.Item.UserId; })) {
            setCurrentAction(guestRequestContext.requestActions.find(function (rq) { return rq.userId === props.Item.UserId; }));
            console.log(currentAction);
        }
        else {
            setCurrentAction(null);
        }
    }, [guestRequestContext.requestActions]);
    var setActionAction = function (action) {
        var tempAction = {
            userId: props.Item.UserId,
            action: action,
            comment: null
        };
        if (currentAction !== null) {
            tempAction = __assign(__assign({}, currentAction), { action: action });
        }
        guestRequestContext.addOrUpdateAction(tempAction);
    };
    var setActionComment = function (comment, action) {
        if (action === void 0) { action = null; }
        var tempAction = {
            userId: props.Item.UserId,
            action: action,
            comment: comment
        };
        if (currentAction !== null) {
            tempAction = __assign(__assign({}, currentAction), { comment: comment, action: action !== null ? action : currentAction.action });
        }
        guestRequestContext.addOrUpdateAction(tempAction);
    };
    var hasEmptyComment = function () {
        return currentAction === null || (currentAction !== null && (currentAction.comment === null || currentAction.comment === ""));
    };
    var isRejected = function () {
        return currentAction !== null && currentAction.action !== null && currentAction.action === "Reject";
    };
    var rejectButtonClick = function () {
        setActionAction("Reject");
        if (hasEmptyComment()) {
            setShowCommentBox(true);
        }
    };
    return (React.createElement("div", { className: "guest-request-workflow-column-action" },
        React.createElement("div", { className: "approve" }, currentAction !== null && currentAction.action === "Approve" ? (React.createElement(SubtitleButton, { noMargin: true, variant: "success", title: "Approve", faIcon: "circle-check" })) : (React.createElement("button", { title: "Approve", type: "button", className: "subtitle-button color-outline-green btn", onClick: function () { return setActionAction("Approve"); } },
            React.createElement("i", { className: "fa-light fa-circle-check", "aria-hidden": "true" }),
            intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.approve", defaultMessage: "Approve" }))
        // <SubtitleButton noMargin={true} color="outline-gray" title="Approve" faIcon="circle-check" onClick={() => setActionAction("Approve")} />
        )),
        React.createElement("div", { className: "reject" }, currentAction !== null && currentAction.action === "Reject" ? (React.createElement(SubtitleButton, { noMargin: false, variant: "danger", title: "Reject", faIcon: "circle-xmark" })) : (React.createElement("button", { title: "Reject", type: "button", className: "subtitle-button color-outline-red btn kt-margin-l-10", onClick: function () { return rejectButtonClick(); } },
            React.createElement("i", { className: "fa-light fa-circle-xmark", "aria-hidden": "true" }),
            intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.reject", defaultMessage: "Reject" }))
        // <SubtitleButton noMargin={false} color="outline-gray" title="Reject" faIcon="circle-xmark" onClick={() => setActionAction("Reject")} />
        )),
        React.createElement("div", { className: "comment" + (isRejected() && hasEmptyComment() === true ? " required-comment" : "") },
            React.createElement(OrchModal, { simpleButton: true, size: "md", headerContents: React.createElement("h5", null,
                    "Please enter a comment about ", (_b = (_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) === null || _b === void 0 ? void 0 :
                    _b.DisplayName), showCloseButton: false, showModalOverride: showCommentBox, showModalToggleOverride: setShowCommentBox, footerContents: React.createElement(React.Fragment, null,
                    React.createElement(SubtitleButton, { title: "Close", onClick: function () { return setShowCommentBox(false); } })), 
                // footerPrimaryText={"Close"}
                // footerPrimaryFunction={() => setShowCommentBox(false)}
                buttonContents: React.createElement(React.Fragment, null, currentAction !== null && currentAction.comment !== null && currentAction.comment !== "" ? (React.createElement("i", { className: "fa-regular fa-message-check" })) : isRejected() && hasEmptyComment() ? (React.createElement(InfoTooltip, { content: React.createElement(React.Fragment, null, "A comment is required for a rejection") },
                    React.createElement("i", { className: "fa-regular fa-message-plus" }))) : (React.createElement("i", { className: "fa-regular fa-message-plus" }))) },
                React.createElement(Form.Control, { as: "textarea", value: currentAction !== null && currentAction.comment !== null ? currentAction.comment : "", onChange: function (event) { return setActionComment(event.currentTarget.value); } })))));
};
export default GuestRequestWorkflowColumnAction;
