import * as React from "react";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
var CardFailedArchivalReport = React.memo(function () {
    return React.createElement("div", { className: "card-failed-archival-report", "data-pw": "failed-archival" },
        React.createElement(Portlet, { className: "side-by-side-portlets" },
            React.createElement(PortletHeader, { title: "Unsuccessful Archivals" }),
            React.createElement(PortletBody, null,
                React.createElement("div", { "data-pw": "text" }, "Orchestry tracks any archival attempts that were unsuccessful, and stores logs files for troubleshooting."),
                React.createElement("span", null,
                    React.createElement("a", { href: "/lifecycle/listing/insight/workspaces-archival-failed", className: "mt-2 btn btn-info subtitle-button btn-sm d-inline-block", "data-pw": "generate-report" }, "Generate Report")))));
});
export default CardFailedArchivalReport;
