/**
 * The supported mime types for the logo image
 */
export var ORCHESTRY_LOGO_IMAGE_MIME_TYPE = ["image/png", "image/gif", "image/jpeg", "image/svg+xml"];
/**
 * The maximum size of the logo image in bytes
 */
export var ORCHESTRY_LOGO_MAX_SIZE_BYTES = 1024 * 80; // 80KB
/*
 * The maximum width of a supported mobile device
 */
export var ORCHESTRY_MOBILE_BREAKPOINT = 480;
/**
 * The default locale.
 */
export var DEFAULT_LOCALE = "en";
