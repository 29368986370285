var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { useSelector } from "react-redux";
import FormToggleControl from "app/components/common/form-toggle-control";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import "react-tagsinput/react-tagsinput.css";
import { PortletBody, Portlet } from "app/components/common/portlet";
import DisableArea from "app/components/common/disable-area";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import * as TableList from "app/components/common/table-list";
import lineAwesomeHelper from "app/utils/lineAwesomeHelper";
import InfoTooltip from "app/components/common/infotooltip";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import GroupSettingsService from "app/services/group-settings";
import OrchModal from "app/components/common/orch-modal";
var SettingsClassifications = function (props) {
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState(), classificationConfig = _a[0], setClassificationConfig = _a[1];
    var _b = React.useState(), orgClassifications = _b[0], setOrgClassification = _b[1];
    var iconOptions = React.useState(lineAwesomeHelper.getIconOptionsForDropDown())[0];
    React.useEffect(function () {
        GroupSettingsService.getOrgClassifications().then(function (orgClasses) {
            console.log("Org Classifciations", orgClasses);
            setOrgClassification(orgClasses);
        });
    }, []);
    React.useEffect(function () {
        if (props.pageLoaded === false && orgClassifications !== undefined && props.currentTenantConfigInternalState.ClassificationConfigJson !== undefined) {
            var tempClassConfig_1 = {
                IsEnabled: false,
                ClassifiedConfigs: []
            };
            if (props.currentTenantConfigInternalState.ClassificationConfigJson !== null && props.currentTenantConfigInternalState.ClassificationConfigJson.indexOf("[") > -1) {
                tempClassConfig_1 = JSON.parse(props.currentTenantConfigInternalState.ClassificationConfigJson);
            }
            orgClassifications
                .filter(function (oc) { return !tempClassConfig_1.ClassifiedConfigs.some(function (cc) { return cc.Key === oc.name; }); })
                .forEach(function (oc) {
                tempClassConfig_1.ClassifiedConfigs = __spreadArray(__spreadArray([], tempClassConfig_1.ClassifiedConfigs, true), [
                    {
                        Key: oc.name,
                        Description: oc.description,
                        Icon: null,
                        IsEnabled: false
                    }
                ], false);
            });
            props.setPageLoaded(true);
            console.log("Setting setClassificationConfig", tempClassConfig_1);
            setClassificationConfig(tempClassConfig_1);
        }
    }, [props.currentTenantConfigInternalState, orgClassifications, props.pageLoaded]);
    React.useEffect(function () {
        if (classificationConfig !== undefined) {
            var classificationConfigJson = JSON.stringify(classificationConfig);
            console.log("Updating Configs", classificationConfigJson);
            props.setCurrentTenantConfigInternalState(__assign(__assign({}, props.currentTenantConfigInternalState), { ClassificationConfigJson: classificationConfigJson }));
        }
    }, [classificationConfig]);
    React.useEffect(function () {
        checkIfSaveable();
    }, [props.currentTenantConfigInternalState]);
    // Checks for edits
    var checkIfSaveable = function () {
        if (props.currentTenantConfigInternalState.GUID !== undefined) {
            if (props.currentTenantConfigInternalState.GUID !== "") {
                // Used to detect if the page has been edited at least once.
                //if (!(currentTenantConfiguration.ClassificationConfigJson === null)) {
                if (JSON.stringify(props.currentTenantConfigInternalState) !== JSON.stringify(currentTenantConfiguration)) {
                    console.log("any edits");
                    if (props.anyEdits === false) {
                        props.setAnyEdits(true);
                        props.setFormValid(true);
                    }
                }
                //}
            }
        }
    };
    var IsLabelOutOfSyncWithOrg = function (label) {
        var itemsOutOfScync = [];
        if (props.currentTenantConfigInternalState.ClassificationConfigJson !== undefined &&
            props.currentTenantConfigInternalState.ClassificationConfigJson !== null &&
            props.currentTenantConfigInternalState.ClassificationConfigJson.indexOf("[") > -1 &&
            orgClassifications !== undefined &&
            orgClassifications.length > 0) {
            var currentTenantConfigObj = JSON.parse(props.currentTenantConfigInternalState.ClassificationConfigJson);
            if (currentTenantConfigObj !== undefined && Array.isArray(currentTenantConfigObj.ClassifiedConfigs)) {
                var currentLabelFromServer = currentTenantConfigObj.ClassifiedConfigs.find(function (cc) { return cc.Key === label.Key; });
                if (currentLabelFromServer !== undefined) {
                    var foundOrgLabelConfig = orgClassifications.find(function (slfo) { return slfo.name === label.Key; });
                    if (foundOrgLabelConfig !== undefined) {
                        if (foundOrgLabelConfig.name !== currentLabelFromServer.Key) {
                            //console.log(foundOrgLabelConfig, "this far");
                            //return true;
                            itemsOutOfScync.push(React.createElement("div", null, "- Name out of sync"));
                        }
                    }
                    if (foundOrgLabelConfig !== undefined) {
                        if (foundOrgLabelConfig.description !== currentLabelFromServer.Description) {
                            //console.log(foundOrgLabelConfig, "this far");
                            //return true;
                            itemsOutOfScync.push(React.createElement("div", null, "- Description out of sync"));
                        }
                    }
                }
            }
        }
        if (itemsOutOfScync.length === 0) {
            return null;
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, "The following items are out of sync:"),
                itemsOutOfScync.map(function (item) { return (React.createElement(React.Fragment, null, item)); }),
                React.createElement("div", { style: { marginTop: "15px" } }, "Click this icon to sync classification with Orchestry")));
        }
    };
    var syncLabelWithOrchestry = function (label) {
        if (orgClassifications !== undefined) {
            var folc = orgClassifications.find(function (oc) { return oc.name === label.Key; });
            if (folc !== undefined && classificationConfig !== undefined) {
                var tempConfigJson = __assign(__assign({}, classificationConfig), { LabelConfigs: classificationConfig.ClassifiedConfigs.map(function (lcm) {
                        return lcm.Key !== label.Key
                            ? lcm
                            : __assign(__assign({}, lcm), { Name: folc !== undefined && folc.name !== undefined ? folc.name : lcm.Key, Description: folc !== undefined && folc.description !== undefined ? folc.description : lcm.Description });
                    }) });
                setClassificationConfig(tempConfigJson);
            }
        }
    };
    return (React.createElement("div", { className: "classification-config sensitivity-labels-config fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.setFormValid(isValid);
            }, UniqueIdentifier: "ClassificationValidation" }, props.pageLoaded === false ? (React.createElement(React.Fragment, null,
            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100, showHeader: false },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 }),
                React.createElement("div", { style: { display: "flex", marginTop: "25px" } },
                    React.createElement("div", { style: { width: "50px", marginRight: "20px" } },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25 })),
                    React.createElement("div", { style: { width: "300px" } },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25, bodyWidth: 300 })))),
            React.createElement(SkeletonLoaderPortlet, { showHeader: false },
                React.createElement(SkeletonFakeTableRow, { repeatCols: 3 },
                    React.createElement(SkeletonFakeTableCol, null,
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })),
                    React.createElement(SkeletonFakeTableCol, null,
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })),
                    React.createElement(SkeletonFakeTableCol, null,
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })),
                    React.createElement(SkeletonFakeTableCol, null,
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })))))) : classificationConfig !== undefined ? (React.createElement(React.Fragment, null,
            React.createElement(Portlet, { className: "toggle-portlet" },
                React.createElement(PortletBody, null,
                    React.createElement("p", { className: "strong margin-bottom-25" }, "Orchestry allows the use of Group Classifications on Workspace Templates. Once enabled you can either directly assign a classification to a template or you can allow the user to pick a classification during the request submission process."),
                    React.createElement(FormToggleControl, { checked: classificationConfig.IsEnabled, label: "Enable the use of Group/Team Classifications in Orchestry?", handleChange: function (checked) {
                            setClassificationConfig(__assign(__assign({}, classificationConfig), { IsEnabled: checked }));
                        }, border: false, strongText: true, marginBottom: false }))),
            React.createElement(DisableArea, { isEnabled: classificationConfig.IsEnabled, useOverlay: true },
                React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "label-configs" },
                    React.createElement(TableList.Context, { listItems: classificationConfig.ClassifiedConfigs, wrappedInPortlet: true, showLoader: false },
                        React.createElement(TableList.TableList, { noResultsText: React.createElement(React.Fragment, null,
                                React.createElement("div", { style: { marginBottom: "20px" } },
                                    "There are no classifications configured for this tenant. You can add classifications using",
                                    " ",
                                    React.createElement("a", { href: "https://docs.microsoft.com/en-us/azure/active-directory/enterprise-users/groups-settings-cmdlets", target: "blank" }, "PowerShell"),
                                    "."),
                                React.createElement(OrchModal, { buttonContents: "View PowerShell Commands" },
                                    React.createElement("div", { className: "powershell-help", style: { backgroundColor: "#f7f8fa", padding: "15px" } },
                                        React.createElement("code", null,
                                            "Install-Module AzureADPreview",
                                            React.createElement("br", null),
                                            "Connect-AzureAD",
                                            React.createElement("br", null),
                                            React.createElement("br", null)
                                        // If you have never set group settings before
                                        ,
                                            "// If you have never set group settings before",
                                            React.createElement("br", null),
                                            "$TemplateId = (Get-AzureADDirectorySettingTemplate | where { $_.DisplayName -eq \"Group.Unified\" }).Id",
                                            React.createElement("br", null),
                                            "$Template = Get-AzureADDirectorySettingTemplate | where -Property Id -Value $TemplateId -EQ",
                                            React.createElement("br", null),
                                            "$Setting = $Template.CreateDirectorySetting()",
                                            React.createElement("br", null),
                                            "$Setting[\"ClassificationList\"] = \"Confidential,Public,External\"",
                                            React.createElement("br", null),
                                            "$Setting[\"ClassificationDescriptions\"] = \"Confidential:Very private,Public:Everyone can see,External:Any guests\"",
                                            React.createElement("br", null),
                                            "New-AzureADDirectorySetting -DirectorySetting $Setting",
                                            React.createElement("br", null),
                                            React.createElement("br", null)
                                        // If you get an error from the above code.
                                        ,
                                            "// If you get an error from the above code.",
                                            React.createElement("br", null),
                                            "$Setting = Get-AzureADDirectorySetting | ? { $_.DisplayName -eq \"Group.Unified\"}",
                                            React.createElement("br", null),
                                            "$Setting[\"ClassificationList\"] = \"Confidential,Public,External\"",
                                            React.createElement("br", null),
                                            "$Setting[\"ClassificationDescriptions\"] = \"Confidential:Very private,Public:Everyone can see,External:Any guests\"",
                                            React.createElement("br", null),
                                            "Set-AzureADDirectorySetting -Id $Setting.Id -DirectorySetting $Setting")))) },
                            React.createElement(TableList.HeaderItem, { className: "sync-check" }),
                            React.createElement(TableList.HeaderItem, { className: "label-toggle" }, "Available In Orchestry"),
                            React.createElement(TableList.HeaderItem, { className: "icon-tr" }, "Icon"),
                            React.createElement(TableList.HeaderItem, null, "Name"),
                            React.createElement(TableList.HeaderItem, null, "Description"),
                            React.createElement(TableList.ContentItemCustomRender, { className: "alert-not-matching", render: function (lc) {
                                    return IsLabelOutOfSyncWithOrg(lc) !== null ? (React.createElement("div", { className: "out-of-sync-warning" },
                                        React.createElement(InfoTooltip, { placement: "right", title: "Classification override out of sync with M365", content: React.createElement(React.Fragment, null, IsLabelOutOfSyncWithOrg(lc)) },
                                            React.createElement("div", { onClick: function () { return syncLabelWithOrchestry(lc); }, style: { cursor: "pointer" } },
                                                React.createElement("i", { style: { color: "#cccc20" }, className: "fa fa-exclamation-triangle" }))))) : null;
                                } }),
                            React.createElement(TableList.ContentItemToggle, { listDataProperyName: "IsEnabled", className: "label-toggle", alignment: "left", handleChange: function (lc, checked) {
                                    return setClassificationConfig(__assign(__assign({}, classificationConfig), { ClassifiedConfigs: classificationConfig.ClassifiedConfigs.map(function (lcm) { return (lcm.Key !== lc.Key ? lcm : __assign(__assign({}, lcm), { IsEnabled: checked })); }) }));
                                } }),
                            React.createElement(TableList.ContentItemCustomRender, { className: "icon-selector", render: function (lc) { return (React.createElement("div", null,
                                    React.createElement(FormDropdownSelect, { list: iconOptions, defaultValue: lc.Icon, placeholder: "No Icon", enableSearch: true, waitToRenderDDItemsUntilOpen: true, onChange: function (value) {
                                            return setClassificationConfig(__assign(__assign({}, classificationConfig), { ClassifiedConfigs: classificationConfig.ClassifiedConfigs.map(function (lcm) { return (lcm.Key !== lc.Key ? lcm : __assign(__assign({}, lcm), { Icon: value })); }) }));
                                        } }))); } }),
                            React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return lc.Key; }, isMobileTitle: true }),
                            React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return lc.Description; } })))),
                classificationConfig.IsEnabled ? (React.createElement("div", { style: { marginTop: "-26px" } },
                    React.createElement(FormValidator, { UniqueIdentifier: "label-configs", FieldName: "available labels", ShowAsInfo: true, ShowFirst: true, Validators: ["is-true"], ErrorMessage: "Please toggle at least one classification to be available", Value: classificationConfig.ClassifiedConfigs.some(function (lc) { return lc.IsEnabled === true; }) }))) : null))) : null)));
};
export default SettingsClassifications;
