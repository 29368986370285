import FormButton from "app/components/common/form-button";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as TenantListStore from "app/store/tenants/list";
import axios from "axios";
import * as React from "react";
import Chart from "react-apexcharts";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./orchestry-admin-tool-api-throttling.scss";
var OrchestryAdminToolSqlThrottlingHours = React.memo(function () {
    var dispatch = useDispatch();
    var _a = React.useState(null), data = _a[0], setData = _a[1];
    var _b = React.useState(null), waitData = _b[0], setWaitData = _b[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _c = React.useState(null), servers = _c[0], setServers = _c[1];
    var _d = React.useState(document.defaultThrottleServer), selectedServer = _d[0], setSelectedServer = _d[1];
    var _e = React.useState(false), isLoading = _e[0], setIsLoading = _e[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        getData();
        getServers();
    }, []);
    React.useEffect(function () {
        // let timerID: NodeJS.Timeout | null; //= setInterval(timer, 5000); //every 5 seconds
        // timerID = setInterval(() => {
        //    getData(300);
        // }, 10000);
        // return function cleanup() {
        //    //When we leave component we stop the timer
        //    if (timerID !== null) {
        //       clearInterval(timerID);
        //    }
        // };
        getData(300);
    }, [selectedServer]);
    var getData = function (top, tenantGuid) {
        if (top === void 0) { top = 300; }
        // setData([]);
        setIsLoading(true);
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/SqlTimelineMinutes?top=" + top + (selectedServer !== undefined ? "&serverUrl=" + selectedServer : "");
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            formatData("Data", response.data);
            formatData("Wait", response.data);
            // formatData("Data", testData as ThrottleSecond[]);
            // formatData("Wait", testData as ThrottleSecond[]);
            setIsLoading(false);
        })
            .catch(function (response) { });
    };
    var getServers = function () {
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/Servers";
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            setServers(response.data);
            if (selectedServer === undefined || selectedServer === "") {
                setSelectedServer(response.data[0]);
                document.defaultThrottleServer = response.data[0];
            }
        })
            .catch(function (response) { });
    };
    var formatDataByType = function (details, callType, databaseName) {
        var returnCount = 0;
        var foundCount = undefined;
        if (callType == null) {
            foundCount = details.filter(function (sc) { return sc.DatabaseName == databaseName; });
        }
        else {
            foundCount = details.filter(function (sc) { return sc.CallType === callType && sc.DatabaseName == databaseName; });
        }
        if (foundCount !== undefined) {
            foundCount.forEach(function (fc) {
                returnCount += fc.ItemDtu;
            });
        }
        return returnCount;
    };
    var getMetrics = function (details, 
    //entityName: "OrchestryGraph" | "OrchestrySharePoint" | "ServiceGraph" | "ServiceManagementAPI" | "ServiceSharePoint" | "CosmosDb",
    callType, databaseName) {
        var metrics = [];
        var foundCount = undefined;
        if (callType == null) {
            foundCount = details.find(function (sc) { return sc.DatabaseName == databaseName; });
        }
        else {
            foundCount = details.find(function (sc) { return sc.CallType === callType && sc.DatabaseName == databaseName; });
        }
        if (foundCount !== undefined) {
            metrics = foundCount.CallMetrics;
        }
        return metrics;
    };
    var getServerNames = function (newData) {
        var serverNames = [];
        newData.forEach(function (element) {
            if (element !== undefined) {
                element.SuccessfulDtu.forEach(function (i) {
                    if (!serverNames.some(function (sn) { return sn == i.DatabaseName; })) {
                        serverNames.push(i.DatabaseName);
                    }
                });
                element.WaitedDtu.forEach(function (i) {
                    if (!serverNames.some(function (sn) { return sn == i.DatabaseName; })) {
                        serverNames.push(i.DatabaseName);
                    }
                });
            }
        });
        return serverNames;
    };
    function generateReadableHexColor() {
        var brightnessThreshold = 128; // Adjust this value for readability
        var letters = "0123456789ABCDEF";
        var color;
        do {
            color = "#";
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
        } while (calculateBrightness(color) > brightnessThreshold);
        return color;
    }
    function calculateBrightness(hexColor) {
        var r = parseInt(hexColor.substring(1, 3), 16);
        var g = parseInt(hexColor.substring(3, 5), 16);
        var b = parseInt(hexColor.substring(5, 7), 16);
        return (r * 299 + g * 587 + b * 114) / 1000;
    }
    var formatData = function (type, newData) {
        var dataArray = [];
        var serverNames = getServerNames(newData);
        serverNames.forEach(function (serverName) {
            var queries = {
                id: serverName,
                name: serverName,
                color: generateReadableHexColor(),
                data: []
            };
            newData.forEach(function (element) {
                if (element !== undefined) {
                    var DetailsArray = type === "Data" ? element.SuccessfulDtu : element.WaitedDtu;
                    queries.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, null, serverName), metrics: getMetrics(DetailsArray, null, serverName) });
                }
            });
            dataArray.push(queries);
        });
        // let query: DataGroup = {
        //    id: "Query",
        //    name: "Query",
        //    color: "#7E66B0",
        //    data: []
        // };
        // let crud: DataGroup = {
        //    id: "CRUD",
        //    name: "CRUD",
        //    color: "#69b066",
        //    data: []
        // };
        // newData.forEach(element => {
        //    if (element !== undefined) {
        //       const DetailsArray = type === "Data" ? element.SuccessfulDtu : element.WaitedDtu;
        //       query.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "Query"), metrics: getMetrics(DetailsArray, "Query") });
        //       crud.data.push({ x: new Date(element.DateTimeKey).getTime(), y: formatDataByType(DetailsArray, "CRUD"), metrics: getMetrics(DetailsArray, "CRUD") });
        //    }
        // });
        // dataArray.push(query);
        // dataArray.push(crud);
        // console.log(type, dataArray);
        if (type === "Data") {
            setData(dataArray);
        }
        else {
            setWaitData(dataArray);
        }
    };
    var outputMetricTable = function (metrics) {
        var outputHtml = "";
        metrics.forEach(function (m) {
            outputHtml +=
                "<tr><td>" + (m.EntityName === null ? "" : m.EntityName.indexOf("AnonymousType") > -1 ? "Unknown" : m.EntityName) + "</td><td style='padding-left:10px;'>" + m.Count + "<td></tr>";
        });
        return outputHtml;
    };
    var tooltip = function (_a) {
        var series = _a.series, seriesIndex = _a.seriesIndex, dataPointIndex = _a.dataPointIndex, w = _a.w;
        var returnHtml = "";
        console.log("seriesIndex", seriesIndex);
        series.forEach(function (element, index) {
            var data = w.globals.initialSeries[index].data[dataPointIndex];
            var series = w.globals.initialSeries[index];
            if (index == seriesIndex) {
                console.log("data", w.globals.initialSeries[index]);
                returnHtml +=
                    "<div class='custom-tooltip' style='padding:10px;'>" + "<b>" + series.name + "</b>: " + data.y + "<table style='font-size:10px;'>" + outputMetricTable(data.metrics) + "</table></div>";
                //}
            }
            else {
                returnHtml += "<div class='custom-tooltip' style='padding:10px;'><b>" + series.name + "</b>: " + data.y + "</div>";
            }
        });
        return returnHtml;
    };
    return (React.createElement("div", { className: "orchestry-admin-tool-api-throttling fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 5 },
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, "Select Server"),
                    servers === undefined || servers == null || servers.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedServer, list: servers.map(function (s) { return ({
                            id: s,
                            value: s,
                            title: s
                        }); }), onChange: function (serverUrl) {
                            setSelectedServer(serverUrl);
                            document.defaultThrottleServer = serverUrl;
                        } })))),
            React.createElement(Col, null,
                React.createElement("div", { className: "refresh-button", style: { marginTop: "25px" } }, isLoading === false ? React.createElement(FormButton, { onClick: function () { return getData(); }, text: "Refresh Data", theme: "blue" }) : React.createElement(SkeletonLoaderFakeBox, null)))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12, className: "left-col" },
                React.createElement("strong", null, "API Call Metrics"),
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null, data !== null ? (React.createElement("div", { className: "", style: { height: "250px" } },
                        React.createElement(Chart, { options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        enabled: true,
                                        easing: "easeinout",
                                        speed: 800,
                                        animateGradually: {
                                            enabled: true,
                                            delay: 150
                                        },
                                        dynamicAnimation: {
                                            enabled: true,
                                            speed: 350
                                        }
                                    }
                                },
                                theme: {
                                    palette: "palette2" // upto palette10
                                },
                                tooltip: {
                                    x: {
                                        format: "mm:ss"
                                    },
                                    custom: tooltip
                                },
                                stroke: {
                                    width: 1
                                },
                                xaxis: {
                                    type: "datetime"
                                }
                            }, series: data, type: "line", height: "250" }))) : null)))),
        React.createElement(Row, null,
            React.createElement(Col, { className: "right-col" },
                React.createElement("strong", null, "Throttled Call Metrics"),
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null, waitData !== null ? (React.createElement("div", { className: "", style: { height: "250px" } },
                        React.createElement(Chart, { options: {
                                chart: {
                                    id: "basic-bar2",
                                    animations: {
                                        enabled: true,
                                        easing: "easeinout",
                                        speed: 800,
                                        animateGradually: {
                                            enabled: true,
                                            delay: 150
                                        },
                                        dynamicAnimation: {
                                            enabled: true,
                                            speed: 350
                                        }
                                    }
                                },
                                theme: {
                                    palette: "palette2" // upto palette10
                                },
                                tooltip: {
                                    x: {
                                        format: "mm:ss"
                                    },
                                    custom: tooltip
                                },
                                stroke: {
                                    width: 1
                                },
                                xaxis: {
                                    type: "datetime"
                                }
                            }, series: waitData, type: "line", height: "250" }))) : null))))));
});
export default OrchestryAdminToolSqlThrottlingHours;
