import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./notification-toast.scss";
import { TOAST_DEFAULT_AUTO_CLOSE_MS } from "./toast.constants";
// toast.configure({
//     autoClose: 8000,
//     draggable: false,
//     position: toast.POSITION.BOTTOM_LEFT
// });
var NotificationToast = React.memo(function (props) {
    //https://github.com/fkhadra/react-toastify#demo
    return (React.createElement("div", null,
        React.createElement(ToastContainer, { autoClose: TOAST_DEFAULT_AUTO_CLOSE_MS, position: toast.POSITION.BOTTOM_LEFT })));
});
export default NotificationToast;
