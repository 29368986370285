var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DirectorySearchActionTypes } from "./types";
import Store from "app/store";
import { cloneDeep, mergeWith } from "lodash";
export function Load() {
    return {
        type: DirectorySearchActionTypes.Directory_Load,
        payload: {}
    };
}
export function LoadRefiners() {
    return {
        type: DirectorySearchActionTypes.Directory_Load_Refiners,
        payload: {}
    };
}
export function LoadFavs() {
    return {
        type: DirectorySearchActionTypes.Directory_Load_Favs,
        payload: {}
    };
}
export function LoadRefinersAndFavs() {
    return {
        type: DirectorySearchActionTypes.Directory_Load_RefinersAndFavs,
        payload: {}
    };
}
export function Refresh() {
    Store.dispatch(UpdateIsLoading(true));
    var state = Store.getState();
    var searchState = cloneDeep(state.directorySearchState);
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    return {
        type: DirectorySearchActionTypes.Directory_Refresh,
        payload: searchState
    };
}
export function TypeAhead(DirectorySearchState) {
    return {
        type: DirectorySearchActionTypes.Directory_Search,
        payload: DirectorySearchState
    };
}
export function UpdatePaging(directoryPaging) {
    Store.dispatch(UpdateIsLoading(true));
    var state = Store.getState();
    var searchState = __assign({}, state.directorySearchState);
    searchState.Paging = mergeWith(searchState.Paging, directoryPaging);
    searchState.Paging.PrevPageUrls = directoryPaging.PrevPageUrls;
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    return {
        type: DirectorySearchActionTypes.Directory_Update_Paging,
        payload: searchState
    };
}
export function UpdateContext(directoryContext) {
    Store.dispatch(UpdateIsLoading(true));
    var state = Store.getState();
    var searchState = __assign({}, state.directorySearchState);
    searchState.Context = mergeWith(searchState.Context, directoryContext);
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    searchState.Paging.CurrentPage = 1;
    searchState.Paging.PrevPageUrls = [];
    searchState.Paging.CurrentPageUrl = "";
    return {
        type: DirectorySearchActionTypes.Directory_Update_Context,
        payload: searchState
    };
}
export function UpdateRefiners(directoryRefiners) {
    Store.dispatch(UpdateIsLoading(true));
    var state = Store.getState();
    var searchStateSource = cloneDeep(state.directorySearchState);
    // searchState.Refiners = directoryRefiners;
    // searchState.Columns = [];
    // searchState.Items = [];
    // searchState.Favs = [];
    // searchState.Paging.CurrentPage = 1;
    // searchState.Paging.PrevPageUrls = [];
    // searchState.Paging.CurrentPageUrl = "";
    //searchState.Refiners = mergeWith(searchState.Context, directoryContext);
    var searchState = {
        Refiners: directoryRefiners,
        Paging: searchStateSource.Paging,
        Context: searchStateSource.Context
    };
    searchState.Paging.CurrentPage = 1;
    searchState.Paging.PrevPageUrls = [];
    searchState.Paging.CurrentPageUrl = "";
    searchState.Paging.ResultsPerPage =
        searchStateSource.Paging.ResultsPerPage === undefined && searchStateSource.Paging.ResultsPerPage === null
            ? searchStateSource.Context.View === "List"
                ? 10
                : 12
            : searchStateSource.Paging.ResultsPerPage;
    return {
        type: DirectorySearchActionTypes.Directory_Update_Refiners,
        payload: searchState
    };
}
export function UpdateView(directoryContext) {
    Store.dispatch(UpdateIsLoading(true));
    var state = Store.getState();
    var searchState = __assign({}, state.directorySearchState);
    searchState.Context = mergeWith(searchState.Context, directoryContext);
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    searchState.Paging.ResultsPerPage = directoryContext.View === "List" ? 10 : 12;
    searchState.Paging.CurrentPage = 1;
    searchState.Paging.PrevPageUrls = [];
    searchState.Paging.CurrentPageUrl = "";
    return {
        type: DirectorySearchActionTypes.Directory_Update_View,
        payload: searchState
    };
}
export function UpdateFavs(directoryFavs) {
    Store.dispatch(UpdateFavsOnServer(directoryFavs.map(function (f) { return f.SiteId; }).join("|")));
    var state = Store.getState();
    var searchState = cloneDeep(state.directorySearchState);
    //const tempArray = JSON.parse(JSON.stringify(directoryFavs));
    searchState.Favs = directoryFavs;
    //console.log("Favs", searchState);
    //searchState.IsFavsLoading = true;
    // searchState.Favs = tempArray.map((f:DirectoryItem)=> {
    //    // if(f !== undefined) {
    //    //    if(f.TeamUrl !== null){
    //    //       f.TeamUrl = stripHtmlTags(f.TeamUrl);
    //    //    }
    //    // }
    //    return f;
    // });
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Refiners = [];
    return {
        type: DirectorySearchActionTypes.Directory_Update_Favs,
        payload: searchState
    };
}
export function UpdateFavsOnServer(favIdsWithPip) {
    return {
        type: DirectorySearchActionTypes.Directory_Update_FavsOnServer,
        payload: favIdsWithPip
    };
}
export function UpdateIsLoading(isLoading) {
    return {
        type: DirectorySearchActionTypes.Directory_Update_IsLoading,
        payload: isLoading
    };
}
export function Clear() {
    return {
        type: DirectorySearchActionTypes.Directory_Clear
    };
}
export var directorySearchMiddlware = function (store, next, action) {
    if (action.type === DirectorySearchActionTypes.Directory_Load) {
        //store.dispatch(UpdateIsLoading(true));
    }
    next(action);
};
