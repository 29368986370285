import React from "react";
export var OrchestryComboboxIcon = function (_a) {
    var isExpanded = _a.isExpanded, closedIcon = _a.closedIcon, openIcon = _a.openIcon, onClick = _a.onClick;
    var onExpandedIconMouseDown = function (event) {
        // This fixes a mantine bug where when the icon is clicked to close the dropdown, the dropdown re-opens immediately after
        // because the mouse down event is passed to the dropdown and it thinks it's a click event inside the dropdown.
        event.stopPropagation();
    };
    return isExpanded ? React.createElement("i", { className: openIcon, onMouseDown: onExpandedIconMouseDown, onClick: onClick }) : React.createElement("i", { onClick: onClick, className: closedIcon });
};
