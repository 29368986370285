import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { HealthCheckStatus } from "appv2/api/health-checks/health-check-status.type";
import { usePublishHealthCheckMutation } from "appv2/api/health-checks/health-checks.api";
import { useOrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/use-orchestry-annotation-section.hook";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useURLParamDisplayMode } from "appv2/hooks/use-display-mode.hook";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useHealthCheckRouteParams } from "../../health-checks/use-health-check-route-params.hook";
import { useHealthChecksFilters } from "../../health-checks/use-health-checks-filters.hook";
import { useHealthCheckActivity } from "../tabs/health-check-activity-tab/use-health-check-activity.hook";
import { useGetHealthCheckCustomRecommendations } from "../tabs/health-check-additions-tab/use-get-health-check-custom-recommendations.hook";
import { useHealthCheckAdditions } from "../tabs/health-check-additions-tab/use-health-check-additions";
import { HealthCheckPages } from "./health-check-pages.type";
import { useHealthCheckAnnotationCounts } from "./use-health-check-annotation-counts.hook";
import { useHealthCheckCanEdit } from "./use-health-check-can-edit.hook";
import { useHealthCheckCanShare } from "./use-health-check-can-share.hook";
export var useHealthCheckPage = function (locale) {
    var intl = useIntl();
    if (locale) {
        intl.locale = locale;
        moment.locale(locale);
    }
    var HEALTH_CHECKS_TABLE_FILTERS_LIST = useHealthChecksFilters().HEALTH_CHECKS_TABLE_FILTERS_LIST;
    var _a = useHealthCheckRouteParams(), tenantGuid = _a.tenantGuid, userType = _a.userType, healthCheckGuid = _a.healthCheckGuid;
    var _b = usePublishHealthCheckMutation(), recompileHealthCheck = _b[0], _c = _b[1], isRecompileHealthCheckSucccess = _c.isSuccess, isPublishLoading = _c.isLoading;
    var _d = useURLParamDisplayMode(), displayMode = _d.displayMode, setDisplayMode = _d.setDisplayMode;
    var isInEditMode = displayMode === "edit";
    var editButtonText = isInEditMode ? intl.formatMessage({ id: "controls.stop_editing", defaultMessage: "Stop Editing" }) : intl.formatMessage({ id: "controls.edit", defaultMessage: "Edit" });
    var history = useHistory();
    var onBackButtonClicked = function () {
        if (userType === "partner") {
            history.push("/partner-tools/tenants/view/".concat(tenantGuid, "?tab=HealthChecks"));
        }
        else {
            history.push("/health-checks");
        }
    };
    var _e = useHealthCheckAnnotationCounts(), generalAnnotationCount = _e.generalAnnotationCount, totalAnnotationCount = _e.totalAnnotationCount, isGeneralAnnotationLoading = _e.isGeneralAnnotationLoading, isAnnotationLoading = _e.isLoading;
    var totalPagedRecommendationsCount = useGetHealthCheckCustomRecommendations().totalPagedRecommendationsCount;
    var additionsTabCount = generalAnnotationCount + totalPagedRecommendationsCount;
    var _f = useHealthCheckActivity(), startDate = _f.startDate, endDate = _f.endDate, tenantName = _f.tenantName, isHealthCheckActivityLoading = _f.isLoading, title = _f.title, healthCheckBrandingImage = _f.healthCheckBrandingImage, partnerBrandingImage = _f.partnerBrandingImage, clientDownloadLink = _f.clientDownloadLink, partnerDownloadLink = _f.partnerDownloadLink, showHealthCheckNotFoundPage = _f.showHealthCheckNotFoundPage, status = _f.status;
    var _g = useState(false), userClickedPublish = _g[0], setUserClickedPublish = _g[1];
    var _h = useState(false), isDraft = _h[0], setIsDraft = _h[1];
    var downloadLink = userType === "partner" ? partnerDownloadLink : clientDownloadLink;
    var downloadToolTipText = !!downloadLink
        ? intl.formatMessage({ id: "healthcheck.download.tooltip.enabled", defaultMessage: "Updates made to a Health Check may take up to 60 minutes to reflect in the PDF." })
        : intl.formatMessage({ id: "healthcheck.download.tooltip.disabled", defaultMessage: "A recently published Health Check may take up to a minute to be available for download." });
    var localizedHealthChecks = intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "page.health_checks.title", defaultMessage: "Health Checks" });
    var pageTitle = userType === "partner" ? "Customer Tenants" : localizedHealthChecks;
    var subtitle = userType === "partner" ? tenantName : undefined;
    useEffect(function () {
        if (status === "Draft") {
            setIsDraft(true);
        }
    }, [status]);
    var pagedRecommendations = useHealthCheckAdditions().pagedRecommendations;
    var generalAnnotations = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.General, true, undefined).annotations;
    var tabsOnChange = function (value) {
        setTab(value !== null && value !== void 0 ? value : HealthCheckPages.Activity);
    };
    var shouldShowTabs = displayMode !== "pdf" && ((pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) || (generalAnnotations === null || generalAnnotations === void 0 ? void 0 : generalAnnotations.length) || isInEditMode);
    var shouldShowActionButtons = displayMode !== "pdf";
    var _j = useHealthCheckCanShare(), userCanShareHealthCheck = _j.userCanShareHealthCheck, isUserCanShareLoading = _j.isLoading;
    var _k = useHealthCheckCanEdit(), userCanEdit = _k.userCanEdit, isUserCanEditLoading = _k.isLoading;
    var shouldShowMutativeButtons = userCanEdit;
    var isLoading = isHealthCheckActivityLoading || isUserCanEditLoading || isUserCanShareLoading;
    var tabs = [
        {
            text: intl.formatMessage({ id: "pages.health_check.tabs.activity", defaultMessage: "Activity" }),
            value: HealthCheckPages.Activity,
            tooltipText: intl.formatMessage({ id: "pages.health_check.tabs.activity.tooltip", defaultMessage: "View the activity for the specified date range." }),
            hideTabCount: true
        },
        {
            text: intl.formatMessage({ id: "pages.health_check.tabs.additions", defaultMessage: "Additions" }),
            value: HealthCheckPages.Additions,
            count: additionsTabCount,
            tooltipText: intl.formatMessage({ id: "pages.health_check.tabs.additions.tooltip", defaultMessage: "View the Annotations and Custom Recommendations additions." })
        }
    ];
    var _l = useState(HealthCheckPages.Activity), tab = _l[0], setTab = _l[1];
    var onShareClicked = useCallback(function () {
        openOrchestryModal("shareHealthCheckModal", { uniqueId: healthCheckGuid, intl: intl, tenantGuid: tenantGuid }, "lg");
    }, [healthCheckGuid, tenantGuid, intl]);
    var onDownloadClicked = useCallback(function () {
        window.open(downloadLink);
    }, [downloadLink]);
    var onEditClicked = function () {
        var newIsEditMode = !isInEditMode;
        setDisplayMode(newIsEditMode ? "edit" : "view");
        if (!newIsEditMode && tab === HealthCheckPages.Additions && !(pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) && !(generalAnnotations === null || generalAnnotations === void 0 ? void 0 : generalAnnotations.length)) {
            setTab(HealthCheckPages.Activity);
        }
    };
    var onPublishClicked = function () {
        recompileHealthCheck({ userType: userType, healthCheckGuid: healthCheckGuid !== null && healthCheckGuid !== void 0 ? healthCheckGuid : "", tenantGuid: tenantGuid });
        OrchestrySuccessToast(intl.formatMessage({ id: "page.health_check.publish_success_toast", defaultMessage: "Health Check published and PDF queued for generation" }));
        setIsDraft(false);
        setUserClickedPublish(true);
        setDisplayMode("view");
    };
    var isLogo = partnerBrandingImage || healthCheckBrandingImage;
    useEffect(function () {
        if (!userCanEdit && displayMode !== "pdf") {
            setDisplayMode("view");
        }
    }, [userCanEdit, setDisplayMode, displayMode]);
    return {
        intl: intl,
        HEALTH_CHECKS_TABLE_FILTERS_LIST: HEALTH_CHECKS_TABLE_FILTERS_LIST,
        pageTitle: pageTitle,
        subtitle: subtitle,
        onBackButtonClicked: onBackButtonClicked,
        tabs: tabs,
        tab: tab,
        tabsOnChange: tabsOnChange,
        onShareClicked: onShareClicked,
        onDownloadClicked: onDownloadClicked,
        onEditClicked: onEditClicked,
        onPublishClicked: onPublishClicked,
        startDate: startDate,
        endDate: endDate,
        tenantName: tenantName,
        isLoading: isLoading,
        title: title,
        editButtonText: editButtonText,
        shouldShowTabs: shouldShowTabs,
        shouldShowActionButtons: shouldShowActionButtons,
        isPublishButtonDisabled: isPublishLoading,
        isPublishButtonLoading: isLoading || isPublishLoading,
        isEditButtonDisabled: isPublishLoading,
        showPublishButton: isDraft,
        disableShareButton: isDraft,
        disableDownloadButton: !downloadLink,
        downloadToolTipText: downloadToolTipText,
        healthCheckBrandingImage: healthCheckBrandingImage,
        partnerBrandingImage: partnerBrandingImage,
        isLogo: isLogo,
        shouldShowMutativeButtons: shouldShowMutativeButtons,
        showHealthCheckNotFoundPage: showHealthCheckNotFoundPage,
        userCanShare: userCanShareHealthCheck,
        status: userClickedPublish ? HealthCheckStatus.Published : status,
        isInEditMode: isInEditMode,
        totalAnnotationCount: totalAnnotationCount,
        isAnnotationLoading: isAnnotationLoading,
        isGeneralAnnotationLoading: isGeneralAnnotationLoading
    };
};
