import { Group } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryAlertWrapper } from "appv2/components/orchestry-alert/orchestry-alert-wrapper.component";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHistory } from "react-router";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { HealthCheckRecommendations } from "./health-check-recommendations.component";
export var RecommendationsSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, _b = _a.data, _c = _b === void 0 ? {} : _b, EndDate = _c.EndDate, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.Recommendations, _g = _f === void 0 ? {} : _f, OpenRecommendationsCount = _g.OpenRecommendationsCount, isLoading = _a.isLoading, isMobile = _a.isMobile, showViewDetailsButton = _a.showViewDetailsButton;
    var history = useHistory();
    var navigateToRecommendationsPage = function () { return history.push("/recommendations"); };
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.Recommendations, renderListOfAnnotations: true, sectionTitle: React.createElement(Group, { gap: 8 },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, colorVariant: "primary-7", size: "sm", variant: "light-circle", imgIconWidth: 14, imgIconHeight: 14, imgIconSrc: ORCH_SVG_ICONS.Logomark }),
            React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.orchestry_recommendations_section.title", defaultMessage: "Orchestry Recommendations" }) }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", size: "16px", lh: "24px", fw: 300 }, intl.formatMessage({ id: "as_of_date", defaultMessage: "As of {date}" }, { date: getReadableDate(EndDate) }))) },
        React.createElement(React.Fragment, null,
            React.createElement(OrchestryAlertWrapper, { variant: "primary", isMobile: isMobile, isLoading: isLoading, mt: 8, mb: 24, icon: React.createElement(OrchestryFontIcon, { isLoading: isLoading, iconClassName: ORCH_ICONS.alertCircle, color: "var(--mantine-color-primary-8)", fontSize: 24, lineHeight: "24px" }), title: intl.formatMessage({ id: "page.health_check.recommendations.current_status", defaultMessage: "Current Status" }), message: intl.formatMessage({ id: "page.health_check.recommendations.num_currently_open", defaultMessage: "You have {count} Orchestry Recommendations currently open." }, { count: OpenRecommendationsCount }), linkLabel: showViewDetailsButton ? intl.formatMessage({ id: "page.health_check.recommendations.view_details", defaultMessage: "View Details" }) : undefined, onClick: showViewDetailsButton ? navigateToRecommendationsPage : undefined }),
            React.createElement(HealthCheckRecommendations, null))));
};
