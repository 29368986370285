import { ChannelCurrentActionTypes } from "./types";
export function Load(channel) {
    if (channel === undefined)
        channel = {};
    //console.log("Load Current Template > ", template)
    return {
        type: ChannelCurrentActionTypes.Channel_Current_Load,
        payload: channel
    };
}
export function Update(channel) {
    return {
        type: ChannelCurrentActionTypes.Channel_Current_Update,
        payload: channel
    };
}
export function Clear() {
    return {
        type: ChannelCurrentActionTypes.Channel_Current_Clear
    };
}
export var channelCurrentMiddlware = function (store, next, action) {
    next(action);
};
