import * as React from "react";
import "./office-profile-popover.scss";
import UsersService from "app/services/users";
import LocalSessionService from "app/services/local-session";
import { Collapse } from "react-bootstrap";
import OfficeProfilePicture from "../office-profile-picture";
var OfficeProfilePopover = React.memo(function (props) {
    var _a = React.useState(null), item = _a[0], setItem = _a[1];
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    var _c = React.useState(null), delayHandler = _c[0], setDelayHandler = _c[1];
    // const [watchHandler, setWatchHandler] = React.useState<NodeJS.Timeout|null>(null);
    var _d = React.useState(false), showPopover = _d[0], setShowPopover = _d[1];
    var popoverElement = React.useRef();
    var _e = React.useState(false), moreOpen = _e[0], setMoreOpen = _e[1];
    var _f = React.useState(0), popoverX = _f[0], setPopoverX = _f[1];
    var _g = React.useState(0), popoverY = _g[0], setPopoverY = _g[1];
    var _h = React.useState(false), loadOnce = _h[0], setLoadOnce = _h[1];
    var _j = React.useState(), userPresence = _j[0], setUserPresence = _j[1];
    var _k = React.useState(props.type !== undefined ? props.type : "User"), type = _k[0], setType = _k[1];
    React.useEffect(function () {
        console.log("userInformation changed", item);
    }, [item]);
    var handleMouseEnter = function (event) {
        var viewportOffset = event.currentTarget.getBoundingClientRect();
        setLoadOnce(true);
        // console.log("mouse position", event.clientX, event.clientY);
        // console.log("mouse position 2", event.currentTarget.offsetTop, event.currentTarget.offsetLeft);
        // console.log("mouse position 3", viewportOffset.top, viewportOffset.left);
        // setPopoverX(event.pageX);
        // setPopoverY(event.pageY);
        // setPopoverX(event.clientX);
        // setPopoverY(event.clientY);
        setPopoverX(viewportOffset.left);
        setPopoverY(viewportOffset.top + event.currentTarget.offsetHeight);
        var timeout = setTimeout(function () {
            getData();
            setShowPopover(true);
        }, 500);
        setDelayHandler(timeout);
    };
    var handleMouseLeave = function () {
        if (delayHandler !== null) {
            clearTimeout(delayHandler);
            setShowPopover(false);
        }
    };
    //https://docs.microsoft.com/en-us/microsoftteams/presence-admins
    var presenceClasss = [
        { Key: "PresenceUnknown", Class: "white", Title: "Presence Unknown" },
        { Key: "AvailableIdle", Class: "green", Title: "Available" },
        { Key: "Away", Class: "", Title: "Away" },
        { Key: "BeRightBack", Class: "yellow", Title: "Be Right Back" },
        { Key: "Busy", Class: "red", Title: "Busy" },
        { Key: "BusyIdle", Class: "red", Title: "Busy" },
        { Key: "DoNotDisturb", Class: "red", Title: "Do Not Disturb" },
        { Key: "Offline", Class: "white", Title: "Offline" },
        { Key: "InACall", Class: "red", Title: "In A Call" },
        { Key: "InAConferenceCall", Class: "red", Title: "In A Conference Call" },
        { Key: "Inactive", Class: "yellow", Title: "Inactive" },
        { Key: "InAMeeting", Class: "red", Title: "In A Meeting" },
        { Key: "OffWork", Class: "white", Title: "Off Work" },
        { Key: "OutOfOffice", Class: "white", Title: "Out Of Office" },
        { Key: "Presenting", Class: "red", Title: "Presenting" },
        { Key: "UrgentInterruptionsOnly", Class: "red", Title: "Urgent Interruptions Only" }
    ];
    var sessionKey = "office_profile_popinfo_" + props.uid;
    var getData = function () {
        if (props.uid !== undefined) {
            if (props.uid === "00000000-0000-0000-0000-000000000000") {
            }
            else if (LocalSessionService.KeyExists(sessionKey)) {
                setLoading(false);
                setItem(LocalSessionService.GetObject(sessionKey));
            }
            else if (props.type === undefined || (props.type !== undefined && props.type === "User")) {
                UsersService.getUserByID(props.uid).then(function (resultData) {
                    setLoading(false);
                    setItem(resultData);
                    LocalSessionService.SetObject(sessionKey, resultData);
                });
            }
            else if (props.type === "Group") {
                // GroupsService.getGroupBase64Photo(props.uid).then((resultData)=>{
                //    setLoading(false);
                //    setUserInformation(resultData as string);
                //    LocalSessionService.Set(sessionKey, resultData as string);
                // })
            }
            else if (props.type === "Teams") {
                // TeamsService.getBase64Photo(props.uid).then((resultData)=>{
                //    setLoading(false);
                //    setUserInformation(resultData as string);
                //    LocalSessionService.Set(sessionKey, resultData as string);
                // })
            }
            else if (props.type === "SharePoint") {
                // SharePointService.getBase64Photo(props.uid).then((resultData)=>{
                //    setLoading(false);
                //    setUserInformation(resultData as string);
                //    LocalSessionService.Set(sessionKey, resultData as string);
                // })
            }
        }
        if (props.uid !== undefined) {
            if (props.type === undefined || (props.type !== undefined && props.type === "User")) {
                UsersService.getUserPresenceByID(props.uid).then(function (resultData) {
                    console.log("Presence Data");
                    setUserPresence(resultData.activity);
                });
            }
        }
    };
    var checkPopoverPosition = function (whatisthis) {
        //console.log("what is this", whatisthis);
        var popover = whatisthis.closest(".popover-wrapper");
        // console.log("popover", popover)
        var viewportOffset = popover.getBoundingClientRect();
        var bounding = popover.getBoundingClientRect();
        // console.log("popover viewport", viewportOffset.top, viewportOffset.left);
        // console.log("popover viewport", bounding.bottom, window.innerHeight, document.documentElement.clientHeight);
        // var w = window.innerWidth;
        // var h = window.innerHeight;
        // console.log("popover window dimentions", window.innerHeight, window.innerWidth);
        if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
            var needToMove = Math.ceil(bounding.bottom - window.innerHeight);
            var styles = getComputedStyle(popover);
            var top = Math.ceil(Number(styles.top.replace("px", "")));
            var left = Math.ceil(Number(styles.left.replace("px", "")));
            // console.log("Popover Element is offscreen", needToMove, top, left);
            //setPopoverX(viewportOffset.left);
            setPopoverY(top - needToMove);
        }
    };
    return (React.createElement("span", { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, className: "popover-hover" },
        props.children,
        loadOnce === true && type === "User" ? (React.createElement("div", { className: "office-profile-popover" },
            React.createElement(Collapse, { in: showPopover, onEntered: function (s) { return checkPopoverPosition(s); } },
                React.createElement("div", { className: "popover-wrapper", style: { top: popoverY + "px", left: popoverX + "px" }, ref: popoverElement },
                    React.createElement("div", { className: "popover-contents" },
                        React.createElement("div", { className: "skeleton fadeinskel-1s" + (item === null ? " show-loading" : "") },
                            React.createElement("div", { className: "left" },
                                React.createElement("div", { className: "circle animated-wave" })),
                            React.createElement("div", { className: "right" },
                                React.createElement("div", { className: "title animated-wave" }),
                                React.createElement("div", { className: "position animated-wave" }))),
                        item !== undefined && item !== null ? (React.createElement("div", { className: "item-info fadein-500ms" + (item !== null ? " show-item" : "") + (moreOpen === true ? " more-open" : "") },
                            React.createElement("div", { className: "top-area" },
                                React.createElement("div", { className: "left" },
                                    React.createElement("div", { className: "picture" }, item !== null ? React.createElement(OfficeProfilePicture, { size: "md", type: "User", uid: props.uid, displayName: item.DisplayName }) : null)),
                                React.createElement("div", { className: "right" },
                                    React.createElement("div", { className: "top-right-left" },
                                        React.createElement("div", { className: "display-name" }, item.DisplayName),
                                        React.createElement("div", { className: "job-title" }, item.JobTitle)),
                                    React.createElement("div", { className: "actions" },
                                        React.createElement("div", { className: "icons" },
                                            React.createElement("div", { className: "chat" },
                                                React.createElement("a", { href: "msteams://teams.microsoft.com/l/chat/0/0?users=" + encodeURIComponent(item.UserPrincipalName) + "&topicname=Chat" },
                                                    React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/chat.xs.png" }))),
                                            React.createElement("div", { className: "email" },
                                                React.createElement("a", { href: "mailto:" + item.UserPrincipalName },
                                                    React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/email.xs.png" }))),
                                            item.BusinessPhones !== undefined && item.BusinessPhones !== null && Array.isArray(item.BusinessPhones) && item.BusinessPhones.length > 0 ? (React.createElement("div", { className: "call" },
                                                React.createElement("a", { href: "tel:" + item.BusinessPhones[0].Phone },
                                                    React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/phone.xs.png" })))) : null),
                                        React.createElement("div", { className: "presence-wrapper" })))),
                            React.createElement("div", { className: "view-more-bar", onClick: function () { return setMoreOpen(true); } },
                                React.createElement("i", { className: "fas fa-caret-down" })),
                            React.createElement(Collapse, { in: moreOpen, onEntered: function (s) { return checkPopoverPosition(s); } },
                                React.createElement("div", { className: "more-info" },
                                    React.createElement("div", { className: "chat" },
                                        React.createElement("div", { className: "icon" },
                                            React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/chat.xs.png" })),
                                        React.createElement("div", { className: "text" },
                                            React.createElement("a", { href: "msteams://teams.microsoft.com/l/chat/0/0?users=" + encodeURIComponent(item.UserPrincipalName) + "&topicname=Chat" }, item.UserPrincipalName))),
                                    React.createElement("div", { className: "email" },
                                        React.createElement("div", { className: "icon" },
                                            React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/email.xs.png" })),
                                        React.createElement("div", { className: "text" },
                                            React.createElement("a", { href: "mailto:" + item.UserPrincipalName }, item.UserPrincipalName))),
                                    item.BusinessPhones !== undefined &&
                                        item.BusinessPhones !== null &&
                                        Array.isArray(item.BusinessPhones) &&
                                        item.BusinessPhones.map(function (phone) { return (React.createElement("div", { className: "call" },
                                            React.createElement("div", { className: "icon" },
                                                React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/phone.xs.png" })),
                                            React.createElement("div", { className: "text" },
                                                React.createElement("a", { href: "tel:" + phone.Phone }, phone.Phone)))); }),
                                    React.createElement("div", { className: "office" },
                                        React.createElement("div", { className: "icon" },
                                            React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/office.xs.png" })),
                                        React.createElement("div", { className: "text" }, item.Office)),
                                    React.createElement("div", { className: "city" },
                                        React.createElement("div", { className: "icon" },
                                            React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/pin.xs.png" })),
                                        React.createElement("div", { className: "text" }, item.CountryOrRegion)))))) : null))))) : null));
});
export default OfficeProfilePopover;
