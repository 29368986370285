import * as React from "react";
import "./settings-installation-teams-app-status.scss";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import FormToggleControl from "app/components/common/form-toggle-control";
import AnimatedLoader from "app/components/common/animated-loader";
import "react-tagsinput/react-tagsinput.css";
import { PortletHeader, Portlet, PortletBody } from "app/components/common/portlet";
import * as TenantCurrentStore from "app/store/tenants/current";
import InstallService from "app/services/install";
import PortletAlert from "app/components/common/portlet-alert";
import DisableArea from "app/components/common/disable-area";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var SettingsInstallationTeamsAppStatus = React.memo(function (_a) {
    var _b;
    var InstallStatusData = _a.InstallStatusData;
    var dispatch = useDispatch();
    // const currentTenantConfiguration:TenantConfiguration = useSelector((state: RootState) => state.tenantConfigurationCurrentState.item);
    // const currentTenant:Tenant = useSelector((state: RootState) => state.tenantCurrentState.item);
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _c = React.useState(InstallStatusData), installStatus = _c[0], setInstallStatus = _c[1];
    var _d = React.useState(false), loadingInstallStatus = _d[0], setLoadingInstallStatus = _d[1];
    var _e = React.useState(false), loadingInstallTeamApp = _e[0], setLoadingInstallTeamApp = _e[1];
    var _f = React.useState(undefined), teamsAppError = _f[0], setTeamsAppError = _f[1];
    React.useEffect(function () {
        if (InstallStatusData === undefined) {
            setLoadingInstallStatus(true);
        }
        if (InstallStatusData !== undefined) {
            setLoadingInstallStatus(false);
        }
        setInstallStatus(InstallStatusData);
    }, [InstallStatusData]);
    var isTeamsAppUptoDate = function () {
        if (installStatus !== undefined) {
            if (installStatus.TeamAppDefinition !== null) {
                if (installStatus.TeamAppDefinition.version === installStatus.TeamAppManifest.version) {
                    return true;
                }
            }
        }
        return false;
    };
    var installTeamsApp = function () {
        setLoadingInstallTeamApp(true);
        InstallService.InstallTeamsApp().then(function (data) {
            if (data.worked === true) {
                InstallService.GetInstallStatus().then(function (data) {
                    setInstallStatus(data);
                    setLoadingInstallTeamApp(false);
                });
            }
            else {
                setTeamsAppError(data.error);
                setLoadingInstallTeamApp(false);
                console.log("Install Error > " + data.error);
            }
        });
    };
    return (React.createElement("div", { className: "settings-installation-teams-app-status fadein-500ms" },
        React.createElement(Portlet, { className: "portlet-bold-text teams-app-status" },
            React.createElement(PortletHeader, { title: "Teams App Install Status" }),
            React.createElement(PortletBody, null,
                installStatus !== undefined ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "install-item" },
                        React.createElement("div", { className: "status-icon" + (isTeamsAppUptoDate() ? " yes" : " no") }, isTeamsAppUptoDate() ? React.createElement("i", { className: "fa fa-check" }) : React.createElement("i", { className: "fa fa-times" })),
                        React.createElement("div", { className: "right" },
                            React.createElement("div", { className: "title" }, isTeamsAppUptoDate()
                                ? "You're running the latest version of the Orchestry Teams app!"
                                : installStatus.TeamAppDefinition === null
                                    ? "It doesn't look like you've installed the Orchestry Teams app yet"
                                    : "Your Orchestry Teams app is out of date"),
                            isTeamsAppUptoDate() ? (React.createElement("div", { className: "description" },
                                React.createElement(React.Fragment, null,
                                    "team-app-version ",
                                    React.createElement("span", { className: "version-highlight" },
                                        "ta.",
                                        installStatus.TeamAppManifest.version)))) : (React.createElement("div", null,
                                "team-app-version",
                                " ",
                                React.createElement("span", { className: "version-highlight red" }, installStatus !== null && installStatus.TeamAppDefinition !== null ? (_b = installStatus.TeamAppDefinition) === null || _b === void 0 ? void 0 : _b.version : "0.0.0"),
                                " ",
                                "\u00BB ",
                                React.createElement("span", { className: "version-highlight green" }, installStatus.TeamAppManifest.version))))),
                    React.createElement("div", { className: "teams-app-install-instructions" }, isTeamsAppUptoDate() ? null : (React.createElement("div", { className: "teams-app-install-choice" },
                        !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                        React.createElement("div", null, "Please select an option below:"),
                        React.createElement(Row, { className: "install-choices" },
                            React.createElement(Col, { className: "click-to-install" },
                                React.createElement("h4", null, "Automated"),
                                React.createElement("div", { className: "text-description" }, "Use the following button to install the latest version of the Orchestry Teams app into your tenant."),
                                teamsAppError !== undefined ? (React.createElement("span", { style: { color: "red" } }, teamsAppError)) : loadingInstallTeamApp === false ? (React.createElement(DisableArea, { isEnabled: orchestryContext.IsCurrentUserAzureGlobalAdmin, useOverlay: true },
                                    React.createElement("div", { className: "btn btn-success install-teams-app", onClick: function () { return installTeamsApp(); } },
                                        React.createElement("i", { className: "far fa-arrow-alt-circle-up" }),
                                        "Install Teams App"))) : (React.createElement("div", { style: { marginTop: "10px" } },
                                    React.createElement(AnimatedLoader, { size: "xxsmall" })))),
                            React.createElement(Col, { className: "manual-install" },
                                React.createElement("h4", null, "Manual"),
                                React.createElement("div", { className: "text-description" }, "Use the following button to download the Teams App zip to install into the teams catalog."),
                                React.createElement(DisableArea, { isEnabled: orchestryContext.IsCurrentUserAzureGlobalAdmin, useOverlay: true },
                                    React.createElement("div", { className: "btn btn-success install-teams-app", onClick: function () {
                                            window.location.href = "/api/install/GetTeamsZipFile";
                                        } },
                                        React.createElement("i", { className: "far fa-arrow-alt-circle-down" }),
                                        "Download Team App Install Zip"))))))))) : (React.createElement(AnimatedLoader, { size: "xxsmall" })),
                React.createElement(SettingsInstallationTeamsAppInstallToggle, null)))));
});
export default SettingsInstallationTeamsAppStatus;
var SettingsInstallationTeamsAppInstallToggle = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "teams-app-skip-install fadein-500ms" },
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "teams-app-install-choice" },
                !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                React.createElement("div", { className: "permission-options lifecycle-toggle" },
                    React.createElement("div", { className: "permissions-area" },
                        React.createElement("div", { className: "permissions-content" }, currentTenant === undefined || (currentTenant !== undefined && currentTenant.SkipTeamsAppInstall === undefined) ? (React.createElement("div", { style: { marginBottom: "2rem", marginTop: "10px" } },
                            React.createElement(SkeletonLoaderFakeBox, null))) : (React.createElement("div", { className: "content-wrap" },
                            React.createElement(FormToggleControl, { border: true, checked: !currentTenant.SkipTeamsAppInstall, label: "Install/update Orchestry teams app in future Orchestry updates?", handleChange: function (checked) { return dispatch(TenantCurrentStore.UpdateSilent({ SkipTeamsAppInstall: !checked })); } }))))))))));
});
