var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./lifecycle-tabs.scss";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { NavLink, Prompt } from "react-router-dom";
import { SubtitleButton } from "app/components/common/subtitle";
import { useSelector } from "react-redux";
import * as PageHeader from "app/components/common/page-header";
import { FormValidatorRollup } from "app/components/common/form-validation";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var LifecycleTabs = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var _a = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : props.tabs[0].key), hash = _a[0], setHash = _a[1];
    var _b = React.useState(false), hasSubmitted = _b[0], setHasSubmitted = _b[1];
    var _c = React.useState(props.tabs), currentTabs = _c[0], setCurrentTabs = _c[1];
    var _d = React.useState([]), isValidItems = _d[0], setValidItems = _d[1];
    var refIsValidItems = React.useRef(isValidItems);
    var _e = React.useState(false), showUnsafeToNavigatePrompt = _e[0], setShowUnsafeToNavigatePrompt = _e[1];
    var _f = React.useState(""), originalState = _f[0], setOriginalState = _f[1];
    var _g = React.useState(false), isChanged = _g[0], setIsChanged = _g[1];
    React.useEffect(function () {
        if (window.location.hash !== "")
            setHash(window.location.hash);
    }, []);
    var save = function () {
        if (areAllTabsDone()) {
            setHasSubmitted(true);
            setShowUnsafeToNavigatePrompt(false);
            props.config.onSave();
        }
    };
    var setHashAnchor = function (newHash) {
        //window.location.hash = newHash;
    };
    var setValid = function (isValid, key) {
        if (isValid === true) {
            if (refIsValidItems.current.some(function (vi) { return vi === key; }) === false) {
                refIsValidItems.current.push(key);
            }
        }
        else {
            if (refIsValidItems.current.some(function (vi) { return vi === key; }) === true) {
                refIsValidItems.current = refIsValidItems.current.filter(function (vi) { return vi !== key; });
            }
        }
        if (key !== undefined) {
            setCurrentTabs(currentTabs.map(function (s) { return (s.key === key ? __assign(__assign({}, s), { valid: isValid }) : __assign({}, s)); }));
        }
    };
    var areAllTabsDone = function () {
        // return refIsValidItems.current.length === currentTabs.length;
        var currentTabsLength = props.tabs.length;
        if (currentPolicy.Option_Content_Action === "Delete") {
            currentTabsLength = currentTabsLength - 1;
        }
        var isValidLength = refIsValidItems.current.length;
        if (currentPolicy.Option_Content_Action === "Delete") {
            isValidLength = refIsValidItems.current.filter(function (vi) { return vi !== "actions"; }).length;
        }
        // console.log("areAllTabsDone", refIsValidItems.current, currentTabs, currentTabs.length, props.tabs.length);
        return isValidLength === currentTabsLength;
    };
    React.useEffect(function () {
        //console.log("currentTabs", currentTabs);
    }, [currentTabs]);
    var isStepHidden = function (step) {
        var foundStep = props.tabs.find(function (s) { return s.key === step.key; });
        if (foundStep !== undefined) {
            return foundStep.hidden;
        }
        return false;
    };
    return (React.createElement("div", { className: "lifecycle-tabs fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: props.config.title, titleLink: props.config.titleLink, subTitle: currentPolicy !== null && currentPolicy.Name !== null ? currentPolicy.Name : "Edit" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { onMouseEnter: function () { return setShowUnsafeToNavigatePrompt(false); }, onMouseLeave: function () { return setShowUnsafeToNavigatePrompt(false); }, to: props.config.titleLink },
                React.createElement(SubtitleButton, { title: props.config.buttonCancelText, variant: "secondary", onClick: function () { return props.config.onClear(); } })),
            areAllTabsDone() === true ? (React.createElement("div", { onClick: function () {
                    save();
                }, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: props.config.buttonSaveText, variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "light" })))),
        React.createElement(Prompt, { message: "Are you sure you want to leave and lose changes?", when: showUnsafeToNavigatePrompt }),
        React.createElement(Tab.Container, { id: "lifecycle-tabs", defaultActiveKey: hash },
            React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                        React.createElement(Nav, { as: "ul" }, props.tabs
                            .filter(function (s) {
                            if (currentPolicy.Option_Content_Action === "Delete" && s.key === "actions") {
                                return false;
                            }
                            else if (!currentPolicy.SecondStage_Enabled && s.key === "secondstep") {
                                return false;
                            }
                            else {
                                return s.card !== null && s.hidden === false;
                            }
                        })
                            .map(function (tab, tabIndex) {
                            return props.isLoading === true ? (React.createElement("div", { className: "skeleton-nav-item", key: "skeleton-nav-item-" + tabIndex },
                                React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 20 }))) : (React.createElement(Nav.Item, { as: "li", "data-pw": "nav-item", onClick: function (event) {
                                    setHashAnchor(tab.key);
                                }, key: tabIndex },
                                React.createElement(Nav.Link, { eventKey: tab.key },
                                    React.createElement("span", null, tab.title),
                                    React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: tab.key, HoverPlacement: "bottom" }))));
                        }))),
                    props.isLoading === true ? (React.createElement(Row, null,
                        React.createElement(Col, { md: 9 },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 300 })),
                        React.createElement(Col, { md: 3 },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 300 })))) : (React.createElement(Tab.Content, null, props.tabs
                        .filter(function (s) { return s.card !== null; })
                        .map(function (tab, tabIndex) { return (React.createElement(Tab.Pane, { eventKey: tab.key, key: tabIndex },
                        React.createElement(tab.card, { EditMode: true, StepKey: tab.key, OnProceedChange: function (isValid) { return setValid(isValid, tab.key); } }))); }))))))));
});
export default LifecycleTabs;
