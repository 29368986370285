import axios from "axios";
var SharePointService = /** @class */ (function () {
    function SharePointService() {
    }
    SharePointService.currentSPByNameSearches = [];
    SharePointService.urlDoesExist = [];
    SharePointService.validatorSearchs = [];
    SharePointService.taxTermResolveBatches = [];
    SharePointService.cachedTerms = [];
    SharePointService.settingsLabels = {
        Same: {
            title: "Same as organization-level setting"
        },
        SharingDomainRestrictionMode: {
            None: {
                title: "No limits"
            },
            AllowList: {
                title: "Allow specific domains"
            },
            BlockList: {
                title: "Block specific domains"
            }
        },
        SharingCapability: {
            ExternalUserAndGuestSharing: {
                title: "Anyone",
                description: "Anyone Users can share files and folders using links that don't require sign-in."
            },
            ExistingExternalUserSharingOnly: {
                title: "Existing guests",
                description: "Only guests already in your organization's directory."
            },
            ExternalUserSharingOnly: {
                title: "New and existing guests",
                description: "Guests must sign in or provide a verification code."
            },
            Disabled: {
                title: "Only people in your organization",
                description: "No external sharing allowed."
            }
        },
        DefaultLinkPermission: {
            None: {
                title: "Same as organization-level setting"
            },
            View: {
                title: "View"
            },
            Edit: {
                title: "Edit"
            }
        },
        DefaultSharingLinkType: {
            None: {
                title: "People with existing access"
            },
            Direct: {
                title: "Specific People (only the people the user specifies)"
            },
            Internal: {
                title: "Only people in your organization"
            },
            AnonymousAccess: {
                title: "Anyone with the link"
            }
        }
    };
    SharePointService.SPSearchForSiteCollectionByName = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (SharePointService.currentSPByNameSearches.length > 0) {
                SharePointService.currentSPByNameSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                SharePointService.currentSPByNameSearches.push({
                    id: SharePointService.currentSPByNameSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SharePoint/SPSearchForSiteCollectionByName?searchTerm=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    SharePointService.SPSearchAllSiteCollections = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/SharePoint/SPSearchGetAllSiteCollections";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    SharePointService.SearchForSiteCollectionByNameComm = function (searchTerm) {
        return SharePointService.SearchForSiteCollectionByName(searchTerm, "CommSite");
    };
    SharePointService.SearchForSiteCollectionByNameTeamSite = function (searchTerm) {
        return SharePointService.SearchForSiteCollectionByName(searchTerm, "TeamSite");
    };
    SharePointService.SearchForSiteCollectionByNameTeam = function (searchTerm) {
        return SharePointService.SearchForSiteCollectionByName(searchTerm, "Team");
    };
    SharePointService.SearchForSiteCollectionByNameAll = function (searchTerm) {
        return SharePointService.SearchForSiteCollectionByName(searchTerm, "All");
    };
    SharePointService.SearchForSiteCollectionByName = function (searchTerm, type) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (SharePointService.currentSPByNameSearches.length > 0) {
                SharePointService.currentSPByNameSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                SharePointService.currentSPByNameSearches.push({
                    id: SharePointService.currentSPByNameSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SharePoint/SearchForSiteCollectionByName?searchTerm=" + searchTerm + (type !== null ? "&wsType=" + type : "");
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    SharePointService.GetAllSiteCollectionsComm = function () {
        return SharePointService.GetAllSiteCollections("CommSite");
    };
    SharePointService.GetAllSiteCollectionsTeamSite = function () {
        return SharePointService.GetAllSiteCollections("TeamSite");
    };
    SharePointService.GetAllSiteCollectionsTeam = function () {
        return SharePointService.GetAllSiteCollections("Team");
    };
    SharePointService.GetAllSiteCollectionsAll = function () {
        return SharePointService.GetAllSiteCollections("All");
    };
    SharePointService.GetAllSiteCollections = function (type) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/SharePoint/GetAllSiteCollection" + (type !== null ? "?wsType=" + type : "");
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    SharePointService.GetHubSiteCollections = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/SharePoint/GetHubSiteCollections";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    SharePointService.GetHubSiteCollectionsByName = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (SharePointService.currentSPByNameSearches.length > 0) {
                SharePointService.currentSPByNameSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                SharePointService.currentSPByNameSearches.push({
                    id: SharePointService.currentSPByNameSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SharePoint/GetHubSiteCollectionsByName?term=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    // public static DoesSiteCollectionExist = (url: string, isCommSite: boolean) => {
    //    return new Promise((resolve, reject) => {
    //       // First let's configure the ajax request
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       axiosConfig.url = "/api/SharePoint/DoesSiteCollectionExist?url=" + url + `&isCommSite=${isCommSite}}`;
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data as any[]);
    //          })
    //          .catch((response: any) => {
    //             //console.log("Axios Response");
    //             //reject(response);
    //          });
    //    });
    // };
    SharePointService.DoesSiteCollectionExistWithUrlReturned = function (url, isCommSite) {
        return new Promise(function (resolve, reject) {
            var _a;
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (SharePointService.urlDoesExist.length > 0) {
                SharePointService.urlDoesExist.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if ((url === null || url === void 0 ? void 0 : url.length) !== 0) {
                SharePointService.urlDoesExist.push({
                    id: ((_a = SharePointService.urlDoesExist) === null || _a === void 0 ? void 0 : _a.length) + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SharePoint/DoesSiteCollectionExistGetFullUrl?url=" + url + "&isCommSite=".concat(isCommSite);
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    SharePointService.GetAllListsFromWeb = function (relativeWebUrl, groupId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            if (relativeWebUrl !== undefined && relativeWebUrl !== null && relativeWebUrl !== "") {
                axiosConfig.url = "/api/SharePoint/GetAllListsInWeb?webUrl=" + relativeWebUrl;
            }
            if (groupId !== undefined && groupId !== null && groupId !== "") {
                axiosConfig.url = "/api/SharePoint/GetAllListsInWeb?groupId=" + groupId;
            }
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    SharePointService.ValidateWorkspaceName = function (workspaceName, siteUrl, isCommSite, workspaceNameWithoutNamingPolicy, geo) {
        return new Promise(function (resolve, reject) {
            //console.log("SharePointService.validatorSearchs",SharePointService.validatorSearchs);
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (SharePointService.validatorSearchs.length > 0) {
                SharePointService.validatorSearchs.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (workspaceName.length !== 0) {
                SharePointService.validatorSearchs.push({
                    id: SharePointService.validatorSearchs.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                var encodedWorkspaceName = encodeURIComponent(workspaceName);
                var encodedSiteUrl = siteUrl ? encodeURIComponent(siteUrl) : "";
                var encodedGeo = geo ? "&geo=".concat(encodeURIComponent(geo)) : "";
                var workspaceNameWithoutNamingPolicyParam = workspaceNameWithoutNamingPolicy ? "&workspaceNameWithoutNamingPolicy=".concat(encodeURIComponent(workspaceNameWithoutNamingPolicy)) : "";
                axiosConfig.url = "/api/SharePoint/ValidateWorkspaceName?workspaceName=".concat(encodedWorkspaceName, "&siteUrl=").concat(encodedSiteUrl, "&isCommSite=").concat(isCommSite).concat(encodedGeo).concat(workspaceNameWithoutNamingPolicyParam);
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
        });
    };
    SharePointService.ValidateOrchestryMetadataFieldName = function (metadataName) {
        return new Promise(function (resolve, reject) {
            //console.log("SharePointService.validatorSearchs",SharePointService.validatorSearchs);
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            var validatorSearchsSizeBefore = SharePointService.validatorSearchs.length;
            if (SharePointService.validatorSearchs.length > 0) {
                SharePointService.validatorSearchs.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (metadataName.length !== 0) {
                SharePointService.validatorSearchs.push({
                    id: SharePointService.validatorSearchs.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                setTimeout(function () {
                    var validatorSearchsSizeAfter = SharePointService.validatorSearchs.length;
                    if (validatorSearchsSizeBefore === validatorSearchsSizeAfter - 1) {
                        // First let's configure the ajax request
                        var axiosConfig = {};
                        axiosConfig.method = "GET";
                        axiosConfig.url = "/api/SharePoint/ValidateMetadataFieldName?metadataName=" + metadataName;
                        axiosConfig.responseType = "json";
                        axiosConfig.cancelToken = source.token;
                        axios(axiosConfig)
                            .then(function (response) {
                            resolve(response.data);
                        })
                            .catch(function (response) {
                            reject(response);
                        });
                    }
                }, 200);
            }
        });
    };
    SharePointService.getBase64Photo = function (siteUrl) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/sharepoint/GetBase64Logo?siteUrl=" + siteUrl;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    SharePointService.GetTaxonomyTree = function (termSetId, termId) {
        return new Promise(function (resolve, reject) {
            var queryString = "";
            if (termSetId !== undefined && termSetId !== null && termSetId.length > 0) {
                queryString += "?termSetId=" + termSetId;
            }
            if (termId !== undefined && termId !== null && termId.length > 0) {
                queryString += "&termId=" + termId;
            }
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/SharePoint/GetTaxonomyTree" + queryString;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    SharePointService.GetTaxonomyTermSet = function (termSetId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/SharePoint/GetTaxonomyTermSet?termSetId=" + termSetId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    SharePointService.GetTaxonomyTerm = function (termId) {
        return new Promise(function (resolve, reject) {
            var cachedTerm = SharePointService.cachedTerms.find(function (t) { return t.Id === termId; });
            if (cachedTerm !== undefined) {
                resolve(cachedTerm);
            }
            else {
                if (SharePointService.taxTermResolveBatches.length === 0) {
                    SharePointService.taxTermResolveBatches.push({
                        tids: [
                            {
                                tid: termId,
                                resolve: resolve
                            }
                        ]
                    });
                }
                else {
                    SharePointService.taxTermResolveBatches[SharePointService.taxTermResolveBatches.length - 1].tids.push({
                        tid: termId,
                        resolve: resolve
                    });
                }
                var arraySizeBefore_1 = SharePointService.taxTermResolveBatches[SharePointService.taxTermResolveBatches.length - 1].tids.length;
                //const batchArrayBefore = SharePointService.taxTermResolveBatches.length - 1;
                // First let's configure the ajax request
                var axiosConfig_1 = {};
                //axiosConfig.method = "GET"
                axiosConfig_1.method = "POST";
                axiosConfig_1.url = "/api/SharePoint/GetTaxonomyTerms";
                axiosConfig_1.responseType = "json";
                setTimeout(function () {
                    var arraySizeAfter = SharePointService.taxTermResolveBatches[SharePointService.taxTermResolveBatches.length - 1].tids.length;
                    var currentBatchArray = SharePointService.taxTermResolveBatches.length - 1;
                    if (arraySizeBefore_1 === arraySizeAfter) {
                        // Start a new array for user profile pic requests.
                        SharePointService.taxTermResolveBatches.push({
                            tids: []
                        });
                        var guidArray = SharePointService.taxTermResolveBatches[currentBatchArray].tids.map(function (tid) { return tid.tid; });
                        var formData = new FormData();
                        formData.append("terms-json-array", JSON.stringify(guidArray));
                        axiosConfig_1.data = formData;
                        console.log("QUERY BATCH NOW > TIDS ARRAY > " + guidArray);
                        axios(axiosConfig_1)
                            .then(function (response) {
                            console.log("QUERY BATCH NOW > END");
                            console.log("Term Response", response);
                            var terms = response.data;
                            SharePointService.taxTermResolveBatches[currentBatchArray].tids.forEach(function (term) {
                                var foundTerm = terms.find(function (t) { return t.Id === term.tid; });
                                if (foundTerm !== undefined) {
                                    if (!SharePointService.cachedTerms.some(function (t) { return t.Id === foundTerm.Id; })) {
                                        SharePointService.cachedTerms.push(foundTerm);
                                    }
                                }
                                //console.log("TermData", foundTerm);
                                term.resolve(foundTerm);
                            });
                            //if(Array.isArray(response.data)){
                            //}
                            //resolve(response.data as string);
                        })
                            .catch(function (response) {
                            //console.log("Axios Response");
                            reject(response);
                        });
                    }
                }, 200);
            }
            // axios(axiosConfig)
            //    .then((response: AxiosResponse) => {
            //       resolve(response.data as Term);
            //    })
            //    .catch((response: any) => {
            //       //console.log("Axios Response");
            //       //reject(response);
            //    });
        });
    };
    SharePointService.GetDefaultTenantSharingSettings = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/SharePoint/GetDefaultTenantSharingSettings";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return SharePointService;
}());
export default SharePointService;
