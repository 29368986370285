import React from "react";
import "./tree.scss";
import UserProfilePicture from "../user-profile-picture";
import { Collapse } from "react-bootstrap";
import TeamExplorerService from "app/services/team-explorer";
import AnimatedLoader from "../animated-loader";
import { Portlet, PortletBody } from "app/components/common/portlet";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
var Tree = React.memo(function (props) {
    var _a = React.useState({}), RootNode = _a[0], setRootNode = _a[1];
    var _b = React.useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = React.useState(undefined), currentObject = _c[0], setCurrentObject = _c[1];
    var TreeTypes = [
        {
            Type: "root",
            FaIcon: "fa-building"
        },
        {
            Type: "team",
            FaIcon: "",
            Menu: [
                {
                    Title: "Delete"
                }
            ]
        },
        {
            Type: "messages",
            Color: "#7f7ff9",
            FaIcon: "fa-inbox"
        },
        {
            Type: "message",
            Color: "#9e9efd",
            FaIcon: "fa-comment-alt"
        },
        {
            Type: "channels",
            Color: "#43a743",
            FaIcon: "fa-circle"
        },
        {
            Type: "channel",
            Color: "#52c752",
            FaIcon: "fa-circle-notch"
        },
        {
            Type: "tabs",
            Color: "#b7b72c",
            FaIcon: "fa-layer-group"
        },
        {
            Type: "tab",
            Color: "#dada38",
            FaIcon: "fa-minus"
        },
        {
            Type: "groups",
            Color: "#c333c3",
            FaIcon: "fa-users"
        },
        {
            Type: "owners",
            Color: "#c333c3",
            FaIcon: "fa-user-tie"
        },
        {
            Type: "members",
            Color: "#c333c3",
            FaIcon: "fa-user"
        },
        {
            Type: "user",
            Color: "#c333c3",
            FaIcon: "fa-user-circle"
        },
        {
            Type: "folder",
            Color: "#ff6f6f",
            FaIcon: "fa-folder-open"
        },
        {
            Type: "files",
            Color: "#fb5454",
            FaIcon: "fa-copy"
        },
        {
            Type: "file",
            Color: "#ff6f6f",
            FaIcon: "fa-file"
        },
        {
            Type: "sitecollection",
            Color: "#c55151",
            FaIcon: "fa-globe"
        }
    ];
    var _d = React.useState({
        Type: "root",
        Title: "Org Name",
        Children: [
            {
                Type: "team",
                Title: "Team",
                Children: [
                    {
                        Type: "messages",
                        Title: "Messages",
                        Children: [
                            {
                                Type: "message",
                                Title: "Hey guys, how about ..."
                            },
                            {
                                Type: "message",
                                Title: "This was fun but I think ..."
                            }
                        ]
                    },
                    {
                        Type: "files",
                        Title: "Files",
                        Children: [
                            {
                                Type: "file",
                                Title: "Office Seating.docx"
                            },
                            {
                                Type: "folder",
                                Title: "General"
                            }
                        ]
                    },
                    {
                        Type: "channels",
                        Title: "Channels",
                        Children: [
                            {
                                Type: "channel",
                                Title: "Channel 1",
                                Children: [
                                    {
                                        Title: "Tabs",
                                        Type: "tabs",
                                        Children: [
                                            {
                                                Title: "Tab 1",
                                                Type: "tab"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                Type: "channel",
                                Title: "Channel 1",
                                Children: [
                                    {
                                        Title: "Tabs",
                                        Type: "tabs",
                                        Children: [
                                            {
                                                Title: "Tab 1",
                                                Type: "tab"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                Type: "channel",
                                Title: "Channel 1",
                                Children: [
                                    {
                                        Title: "Tabs",
                                        Type: "tabs",
                                        Children: [
                                            {
                                                Title: "Tab 1",
                                                Type: "tab"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        Type: "groups",
                        Title: "Users",
                        Children: [
                            {
                                Type: "owners",
                                Title: "Owners"
                            },
                            {
                                Type: "members",
                                Title: "Members"
                            }
                        ]
                    },
                    {
                        Type: "site-collection",
                        Title: "Group Site"
                    }
                ]
            }
        ]
    }), TreeData = _d[0], setTreeData = _d[1];
    React.useEffect(function () {
        TeamExplorerService.GetNodeRoot().then(function (data) {
            console.log("Root Data", data);
            setRootNode(data);
            setIsLoading(false);
        });
    }, []);
    var setCurrentObjectWithCheck = function (curObj) {
        if (curObj !== null) {
            setCurrentObject(curObj);
        }
    };
    // const RenderRoot = () => {
    //   return (<>
    //     {RenderNodes(TreeData)}
    //     </>)
    // }
    var renderPropertiesWithTitles = function (obj) {
        return obj !== undefined
            ? Object.keys(obj).map(function (key, i) { return (React.createElement("div", { className: "property-item" },
                React.createElement("div", { className: "property-key" }, key),
                React.createElement("div", { className: "property-value" }, typeof obj[key] === "string" ? (React.createElement("span", { className: "string" }, obj[key])) : typeof obj[key] === "object" ? (Array.isArray(obj[key]) === false ? (React.createElement("div", { className: "sub-properties" }, renderPropertiesWithTitles(obj[key]))) : (React.createElement("div", { className: "sub-array" },
                    React.createElement("div", { className: "show" }, obj[key].length)))) : (React.createElement("div", { className: "catchall" }, obj[key]))))); })
            : null;
    };
    return (React.createElement("div", { className: "tree-wrapper" },
        React.createElement(Portlet, { className: "navigation" },
            React.createElement(PortletBody, null,
                React.createElement(DynamicScrollbar, { maxHeight: 300, shouldShow: true, dynamicHeight: true },
                    React.createElement("div", { className: "tree" },
                        isLoading ? React.createElement(AnimatedLoader, { size: "xsmall", theme: "dark" }) : null,
                        React.createElement(TreeNode, { node: RootNode, treeTypes: TreeTypes, setCurrentObject: setCurrentObjectWithCheck }))))),
        React.createElement(Portlet, { className: "properties-window" },
            React.createElement(PortletBody, null,
                React.createElement("div", { className: "properties" }, renderPropertiesWithTitles(currentObject))))));
});
export default Tree;
var TreeNode = React.memo(function (props) {
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = React.useState(false), isLoadingChildren = _b[0], setIsLoadingChildren = _b[1];
    var _c = React.useState(false), canHaveChildren = _c[0], setCanHaveChildren = _c[1];
    var _d = React.useState(), children = _d[0], setChildren = _d[1];
    var typesWithDynamicChildren = ["channels", "owners", "members", "tabs", "messages", "files"];
    var ToggleNode = function (element) {
        if (props.node.Type === "channels" && props.teamId !== undefined && children === undefined) {
            setIsLoadingChildren(true);
            TeamExplorerService.GetNodeTeamChannels(props.teamId).then(function (data) {
                setIsLoadingChildren(false);
                setChildren(data);
                setIsOpen(!isOpen);
            });
        }
        else if (props.node.Type === "tabs" && props.teamId !== undefined && props.channelId !== undefined && children === undefined) {
            setIsLoadingChildren(true);
            TeamExplorerService.GetNodeTeamChannelTabs(props.teamId, props.channelId).then(function (data) {
                setIsLoadingChildren(false);
                setChildren(data);
                setIsOpen(!isOpen);
            });
        }
        else if (props.node.Type === "messages" && props.teamId !== undefined && props.channelId !== undefined && children === undefined) {
            setIsLoadingChildren(true);
            TeamExplorerService.GetNodeTeamChannelMessages(props.teamId, props.channelId).then(function (data) {
                setIsLoadingChildren(false);
                setChildren(data);
                setIsOpen(!isOpen);
            });
        }
        else if (props.node.Type === "owners" && props.teamId !== undefined && children === undefined) {
            setIsLoadingChildren(true);
            TeamExplorerService.GetNodeTeamUserOwners(props.teamId).then(function (data) {
                setIsLoadingChildren(false);
                setChildren(data);
                setIsOpen(!isOpen);
            });
        }
        else if (props.node.Type === "members" && props.teamId !== undefined && children === undefined) {
            setIsLoadingChildren(true);
            TeamExplorerService.GetNodeTeamUserMembers(props.teamId).then(function (data) {
                setIsLoadingChildren(false);
                setChildren(data);
                setIsOpen(!isOpen);
            });
        }
        else {
            setIsOpen(!isOpen);
        }
    };
    React.useEffect(function () {
        if (props.node.Type === "root") {
            setIsOpen(true);
        }
        if (props.node.Children !== undefined && props.node.Children !== null) {
            setChildren(props.node.Children);
        }
        //@ts-ignore
        if (props.node.Type !== undefined && typesWithDynamicChildren.includes(props.node.Type)) {
            setCanHaveChildren(true);
        }
    }, [props.node]);
    if (props.node !== undefined && props.node !== null) {
        var matchType = props.treeTypes.filter(function (tt) { return tt.Type === props.node.Type; })[0];
        //matchType.Type !== undefined ? matchType.Type : "";
        return (React.createElement("div", { className: "node-wrapper" + (isOpen ? " open" : "") },
            React.createElement("div", { className: "node" + (matchType !== undefined && matchType.Type !== undefined ? " node-" + (matchType === null || matchType === void 0 ? void 0 : matchType.Type) : "") },
                children !== undefined || canHaveChildren ? (React.createElement("div", { className: "node-expand-icon", onClick: function (event) { return ToggleNode(event.currentTarget); } },
                    React.createElement("i", { className: "fa fa-caret-right" }))) : null,
                React.createElement("div", { className: "node-icon-image", style: { color: matchType === null || matchType === void 0 ? void 0 : matchType.Color } },
                    (matchType === null || matchType === void 0 ? void 0 : matchType.FaIcon) ? React.createElement("i", { className: "fa " + (matchType === null || matchType === void 0 ? void 0 : matchType.FaIcon) }) : null,
                    (matchType === null || matchType === void 0 ? void 0 : matchType.Image) ? React.createElement("img", { src: matchType === null || matchType === void 0 ? void 0 : matchType.Image }) : null,
                    (matchType === null || matchType === void 0 ? void 0 : matchType.Type) === "team" ? React.createElement(UserProfilePicture, { size: "xs", displayName: props.node.Title }) : null),
                React.createElement("div", { className: "node-title" + (props.node.Object !== null ? " has-object" : ""), onClick: function () { return props.setCurrentObject(props.node.Object); } }, props.node.Title !== undefined ? decodeURIComponent(props.node.Title) : "")),
            isLoadingChildren ? (React.createElement("div", { className: "loading" },
                React.createElement(AnimatedLoader, { size: "xsmall", theme: "dark" }))) : null,
            React.createElement(React.Fragment, null, children !== undefined ? (
            //@ts-ignore
            React.createElement(Collapse, { in: isOpen },
                React.createElement("ul", { className: "list-unstyled" }, children.map(function (node, nindex) { return (React.createElement("li", { key: nindex },
                    React.createElement(TreeNode, { node: node, treeTypes: props.treeTypes, teamId: props.node.Type === "team" ? props.node.ResourceId : props.teamId !== undefined ? props.teamId : undefined, channelId: props.node.Type === "channel" ? props.node.ResourceId : props.channelId !== undefined ? props.channelId : undefined, setCurrentObject: props.setCurrentObject }))); })))) : null)));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
