import React from "react";
import "./dropdown-associated.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Button, Popover } from "react-bootstrap";
import AssociateTemplate from "app/components/common/associate-template";
var DropdownAssociated = React.memo(function (props, state) {
    var popover = (React.createElement(Popover, { id: "popover-basic", className: "dropdown-associated-popover" },
        React.createElement(AssociateTemplate, { associatedTemplates: props.associatedTemplates, onAssociatedAdd: function (ass) {
                props.onAssociatedAdd(ass);
            }, onAssociatedRemove: function (ass) {
                props.onAssociatedRemove(ass);
            }, libraryMode: props.libraryMode },
            React.createElement(Popover.Title, { as: "div" },
                React.createElement(AssociateTemplate.Count, null)),
            React.createElement(Popover.Content, null,
                React.createElement("div", { className: "at-items" },
                    React.createElement(AssociateTemplate.SelectedItems, null)),
                React.createElement("h6", null, "Associate more"),
                React.createElement("div", { className: "at-search" },
                    React.createElement(AssociateTemplate.Search, null))))));
    return (React.createElement(OverlayTrigger, { trigger: "click", placement: "bottom-end", 
        //popperConfig={{fallbackPlacement:"auto",  }}
        // fallbackPlacement="auto"
        //@ts-ignore
        flip: true, overlay: popover, rootClose: true },
        React.createElement(Button, { className: "dropdown-associated-button" },
            React.createElement("span", null, props.buttonText),
            React.createElement(FontAwesomeIcon, { icon: faChevronDown }))));
});
export default DropdownAssociated;
