import * as React from "react";
var ReportColumnTemplateType = React.memo(function (props) {
    var _a = React.useState(props.Item), item = _a[0], setItem = _a[1];
    React.useEffect(function () {
        if (props.Item.ExtendedProperties !== undefined &&
            props.Item.ExtendedProperties != null &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== undefined &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails != null) {
            setItem(props.Item.ExtendedProperties.CosmosWorkspaceDetails);
        }
        else {
            setItem(props.Item);
        }
    }, [props.Item]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "cat-icon" }, item.Application !== "Team" ? React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" }) : React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png" })),
        React.createElement("div", { className: "cat-title" },
            React.createElement("div", { className: "cat-title-text" },
                item.Application === "Team" ? React.createElement("span", null, "Microsoft Team") : null,
                item.Application === "TeamSite" ? React.createElement("span", null, "Team Site") : null,
                item.Application === "CommunicationSite" ? React.createElement("span", null, "Communication Site") : null),
            item.Privacy !== "Custom" ? React.createElement("div", { className: "cat-title-privacy" }, item.Privacy) : null)));
});
export default ReportColumnTemplateType;
