var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as DocumentTemplateListStore from "app/store/document-templates/list";
import * as TableList from "app/components/common/table-list";
import { SubtitleButton } from "app/components/common/subtitle";
var DocumentTemplateList = React.memo(function () {
    var dispatch = useDispatch();
    var documentTemplates = useSelector(function (state) { return state.documentTemplateListState.items; });
    console.log("DocumentTemplates", documentTemplates);
    React.useEffect(function () {
        dispatch(DocumentTemplateListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onAssociatedAdd = function (feat, templateToAdd) {
        var tempArray = [templateToAdd];
        if (feat.AssociatedTemplates !== undefined) {
            if (feat.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), feat.AssociatedTemplates, true);
            }
        }
        dispatch(DocumentTemplateListStore.UpdateItem(Object.assign(feat, { AssociatedTemplates: tempArray })));
    };
    var onAssociatedRemove = function (feat, tbaToRemove) {
        var _a;
        dispatch(DocumentTemplateListStore.UpdateItem(Object.assign(feat, { AssociatedTemplates: (_a = feat.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; }) })));
    };
    return (React.createElement("div", { className: "documentTemplates-lists" },
        React.createElement(PageHeader.SetLeftContent, { subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a document template" })),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { exact: true, to: "/workspaces/document-templates/new" },
                React.createElement(SubtitleButton, { title: "New Template", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: documentTemplates, asyncListLoadUID: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Document Template Name"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Type"),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, null, "Category"),
                React.createElement(TableList.HeaderItem, { type: "centered", helpText: "" }, "Associated"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/workspaces/document-templates/edit/{GUID}" }),
                React.createElement(TableList.ContentItemDocumentFileType, { listDataProperyName: "Type" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Category" }),
                React.createElement(TableList.ContentItemAssociatedTemplates, { onAssociatedAdd: function (item, tba) {
                        onAssociatedAdd(item, tba);
                    }, onAssociatedRemove: function (item, tba) {
                        onAssociatedRemove(item, tba);
                    } }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/document-templates/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/document-templates/edit/{GUID}"
                    onRemove: function (item) { return dispatch(DocumentTemplateListStore.DeleteItem(item)); }, deleteCheckType: "document-template", deleteCheckItemNameProperty: "Title" })))));
});
export default DocumentTemplateList;
