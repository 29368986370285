import * as React from "react";
import "./user-item.scss";
import UserProfilePicture from "app/components/common/user-profile-picture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
var UserItem = React.memo(function (_a) {
    var user = _a.user, showBadge = _a.showBadge, onRemove = _a.onRemove, showBorder = _a.showBorder, size = _a.size;
    var remove = function (user) {
        if (onRemove !== undefined) {
            onRemove(user);
        }
    };
    return (React.createElement("div", { className: "user-item" + (onRemove === undefined ? " hide-remove" : "") + (showBorder !== undefined && showBorder === false ? " no-border" : "") + (size !== undefined ? " size-" + size : ""), key: user.GUID },
        React.createElement("div", { className: "pic" },
            showBadge !== undefined && showBadge ? React.createElement("div", { className: "principal-type type-user" }, "U") : null,
            React.createElement(UserProfilePicture, { uid: user.GUID, displayName: user.DisplayName })),
        React.createElement("span", null, user.DisplayName),
        onRemove !== undefined ? (React.createElement("div", { className: "remove", onClick: function () {
                remove(user);
            } },
            React.createElement(FontAwesomeIcon, { icon: faTimes }))) : null));
});
export default UserItem;
