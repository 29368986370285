import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
var RequestDetailsLifecycle = React.memo(function (props) {
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var intl = useIntl();
    React.useEffect(function () {
        dispatch(LifecyclePolicyCurrentStore.Clear());
        if (currentTemplate !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LifecycleArchivePolicyGuid) !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LifecycleArchivePolicyGuid) !== null) {
            dispatch(LifecyclePolicyCurrentStore.Load("Archival", currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LifecycleArchivePolicyGuid));
        }
    }, [currentTemplate]);
    var getPolicyName = function () {
        if (currentTemplate !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LifecycleArchivePolicyGuid) !== undefined) {
            if (currentPolicy !== null && currentPolicy.Name !== null) {
                return currentPolicy.Name;
            }
        }
        return null;
    };
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.lifecycle.title", defaultMessage: "Lifecycle" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-4 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.lifecycle.policy_label", defaultMessage: "Renewal / Archival Policy" })),
                React.createElement("div", { className: "col-8" }, currentTemplate === null || currentTemplate === undefined || currentTemplate.GUID === null || currentTemplate.GUID === undefined ? (React.createElement(SkeletonLoaderFakeBox, null)) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LifecycleArchivalIsProtectedFromArchivals) === false && getPolicyName() !== null && getPolicyName() !== undefined ? (React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, getPolicyName())) : (intl.formatMessage({ id: "page.request_details.lifecycle.renewal_archival_policy.no_policy_label", defaultMessage: "-- no policy --" })))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-4 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.lifecycle.excluded_from_archival_label", defaultMessage: "Protected From Archivals" })),
                React.createElement("div", { className: "col-8" }, currentTemplate === null || currentTemplate === undefined || currentTemplate.GUID === null || currentTemplate.GUID === undefined ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LifecycleArchivalIsProtectedFromArchivals) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })))))))));
});
export default RequestDetailsLifecycle;
