// import { MiddlewareFunction} from 'app/store/middleware'
import { UserCurrentActionTypes } from "./types";
export function Load(user) {
    if (user === undefined) {
        user = {
            DisplayName: "",
            Id: "",
            GivenName: "",
            JobTitle: "",
            Surname: "",
            UserPrincipalName: "",
            GUID: "",
            Company: "",
            CountryOrRegion: "",
            Department: "",
            Office: "",
            StateOrProvince: ""
        };
    }
    return {
        type: UserCurrentActionTypes.User_Current_Load,
        payload: user
    };
}
export var userCurrentMiddlware = function (store, next, action) {
    if (action.type === UserCurrentActionTypes.User_Current_Load) {
        // (action.payload as User).AssociatedFeatures?.forEach((feat:UserBaseAssociation) => {
        // })
        // // action.payload.forEach((feat:Feature) => {
        // //     if(feat.icon !== undefined)
        // //         feat.icon = fontAwesomeHelper.getIconFromName(feat.icon?.iconName);
        // // });
        // console.log("Middleware", action.payload);
        next(action);
    }
    else {
        next(action);
    }
};
