import React from "react";
import { CSSTransition } from "react-transition-group";
import "./workspace-links.scss";
import SharePointLogo from "./logos/SharePoint.png";
import OutlookLogo from "./logos/Outlook.png";
import PlannerLogo from "./logos/Planner.png";
import OneNoteLogo from "./logos/OneNote.png";
import AzureLogo from "./logos/Azure.png";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { getIconClassName } from "@uifabric/styling";
import { FormattedMessage, useIntl } from "react-intl";
function nFormatter(num, digits) {
    var lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
var PaginationToolbar = function (_a) {
    var currentPage = _a.currentPage, totalResults = _a.totalResults, setCurrentPage = _a.setCurrentPage, setScrollDirection = _a.setScrollDirection;
    var isLastPage = function () {
        //console.log("Last Page Check", (Math.ceil((Number(props.totalResults) / props.currentResultsPerPage))))
        return currentPage + 1 === Math.ceil(Number(totalResults) / 5);
    };
    return (React.createElement("div", { className: "paging" },
        React.createElement("div", { className: "kt-pagination  kt-pagination--brand" },
            React.createElement("ul", { className: "kt-pagination__links" },
                currentPage > 0 ? (React.createElement("li", { className: "kt-pagination__link--prev", onClick: function () {
                        setCurrentPage(currentPage - 1);
                        setScrollDirection("to-the-left");
                    } },
                    React.createElement("a", null,
                        React.createElement("i", { className: "fa fa-angle-left kt-font-brand" })))) : (React.createElement("li", { className: "kt-pagination__link--prev disabled" },
                    React.createElement("a", null,
                        React.createElement("i", { className: "fa fa-angle-left kt-font-brand" })))),
                isLastPage() !== true ? (React.createElement("li", { className: "kt-pagination__link--next", onClick: function () {
                        setCurrentPage(currentPage + 1);
                        setScrollDirection("to-the-right");
                    } },
                    React.createElement("a", null,
                        React.createElement("i", { className: "fa fa-angle-right kt-font-brand" })))) : null))));
};
var SingleLinkItem = function (_a) {
    var text = _a.text, url = _a.url, count = _a.count, leftIcon = _a.leftIcon, leftIconBackgroundColour = _a.leftIconBackgroundColour, leftImage = _a.leftImage;
    return (React.createElement("div", { className: "single-link-item" },
        React.createElement("div", { className: "left-icon ".concat(leftIconBackgroundColour, " ") },
            leftIcon ? React.createElement("i", { className: leftIcon }) : null,
            !leftIcon && leftImage ? React.createElement("img", { src: leftImage }) : null),
        React.createElement("div", { className: "text" },
            React.createElement("span", null,
                React.createElement("span", null, text),
                React.createElement("span", { className: "count-text" }, count !== null && count !== undefined ? " (".concat(nFormatter(count, 1), ")") : "")),
            React.createElement("i", { className: "fa fa-external-link" }),
            React.createElement("a", { title: url, target: "_blank", rel: "noreferrer", className: "link", href: url }))));
};
var scrollClasses = {
    enter: "to-the-right",
    enterActive: "to-the-right-active",
    enterDone: "to-the-right-done",
    exit: "to-the-left",
    exitActive: "to-the-left-active",
    exitDone: "to-the-left-done"
};
var MultiItemLink = function (_a) {
    var _b, _c;
    var text = _a.text, urls = _a.urls, count = _a.count, leftIcon = _a.leftIcon, leftIconBackgroundColour = _a.leftIconBackgroundColour, leftImage = _a.leftImage;
    var _d = React.useState(false), containerOpen = _d[0], setContainerOpen = _d[1];
    var _e = React.useState(0), currentPage = _e[0], setcurrentPage = _e[1];
    var _f = React.useState(""), scrollDireciton = _f[0], setscrollDirection = _f[1];
    return (React.createElement(React.Fragment, null, urls.length > 1 ? (React.createElement("div", { className: "multi-link-item ".concat(containerOpen ? "is-open" : "is-closed", " light-").concat(leftIconBackgroundColour) },
        React.createElement("div", { onClick: function () { return setContainerOpen(function (value) { return !value; }); }, className: "link-item-body" },
            React.createElement("div", { className: "left-icon ".concat(leftIconBackgroundColour) },
                leftIcon ? React.createElement("i", { className: leftIcon }) : null,
                !leftIcon && leftImage ? React.createElement("img", { src: leftImage }) : null),
            React.createElement("div", { className: "body-text" },
                React.createElement("span", null,
                    React.createElement("span", null, text),
                    React.createElement("span", { className: "count-text" }, count !== null && count !== undefined ? " (".concat(nFormatter(count, 1), ")") : "")),
                React.createElement("i", { className: "fa fa-chevron-down" }))),
        React.createElement(CSSTransition, { in: containerOpen, classNames: "show", timeout: 0 },
            React.createElement(CSSTransition, { in: scrollDireciton === "to-the-right" ? true : false, classNames: scrollClasses, timeout: 500 },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "body-items ".concat(urls.length > 5 ? "more-than-5" : null).trim() },
                        urls.slice(currentPage * 5, currentPage * 5 + 5).map(function (url, index) {
                            return (React.createElement("div", { className: "item-container" },
                                React.createElement("div", { className: "item" },
                                    React.createElement("div", { className: "item-text" },
                                        React.createElement("div", { className: "link-text" },
                                            React.createElement("i", { className: "fa-solid fa-file-lines" }),
                                            React.createElement("div", null, url.Key)),
                                        React.createElement("a", { title: url.Value, target: "_blank", rel: "noopener noreferrer", href: url.Value })),
                                    React.createElement("i", { className: "fa fa-external-link" })),
                                React.createElement("hr", { className: "list-separator" })));
                        }),
                        urls.length > 5 ? (React.createElement("div", { className: "pagination" },
                            React.createElement(PaginationToolbar, { totalResults: urls.length, currentPage: currentPage, setCurrentPage: setcurrentPage, setScrollDirection: setscrollDirection }))) : null)))))) : (React.createElement(SingleLinkItem, { text: (_b = urls[0]) === null || _b === void 0 ? void 0 : _b.Key, url: (_c = urls[0]) === null || _c === void 0 ? void 0 : _c.Value, leftIcon: leftIcon, leftIconBackgroundColour: leftIconBackgroundColour, leftImage: leftImage }))));
};
var WorkspaceLinks = React.memo(function (_a) {
    var _b, _c;
    var links = _a.links, showTitle = _a.showTitle;
    var intl = useIntl();
    return (React.createElement(Portlet, { className: "workspace-links" },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.component_title.workspace_links", defaultMessage: "Workspace Links" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "workspace-links-container-common" },
                showTitle === undefined || (showTitle !== undefined && showTitle === true) ? (React.createElement("div", { className: "title" },
                    React.createElement(FormattedMessage, { id: "component.workspace_links.title", defaultMessage: "Workspace Links" }))) : null,
                links !== undefined && links != null ? (React.createElement("div", { className: "links" },
                    React.createElement("div", { className: "verticle-section-title" }, intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.workspace_links", defaultMessage: "Workspace Links" })),
                    React.createElement(SingleLinkItem, { leftImage: SharePointLogo, url: (links === null || links === void 0 ? void 0 : links.SharePointUrl) || "", text: intl.formatMessage({ id: "component.workspace_links.sharepoint_site.title", defaultMessage: "SharePoint Site" }), leftIconBackgroundColour: "dark-green" }),
                    React.createElement(SingleLinkItem, { leftIcon: "fa-solid fa-folder-bookmark", url: (links === null || links === void 0 ? void 0 : links.SharePointDocUrl) || "", text: intl.formatMessage({ id: "component.workspace_links.documents.title", defaultMessage: "Documents" }), leftIconBackgroundColour: "dark-green", count: (links === null || links === void 0 ? void 0 : links.SharePointDocumentCount) || 0 }),
                    React.createElement(SingleLinkItem, { leftImage: OutlookLogo, url: (links === null || links === void 0 ? void 0 : links.EmailUrl) || "", text: intl.formatMessage({ id: "component.workspace_links.group_mailbox.title", defaultMessage: "Group Mailbox" }), leftIconBackgroundColour: "light-blue", count: (links === null || links === void 0 ? void 0 : links.EmailCount) || 0 }),
                    React.createElement(SingleLinkItem, { leftIcon: "fa-solid fa-calendar-days", url: (links === null || links === void 0 ? void 0 : links.CalendarUrl) || "", text: intl.formatMessage({ id: "component.workspace_links.group_calendar.title", defaultMessage: "Group Calendar" }), leftIconBackgroundColour: "light-blue", count: (links === null || links === void 0 ? void 0 : links.CalendarCount) || 0 }),
                    (links === null || links === void 0 ? void 0 : links.TeamUrl) !== undefined ? ((links === null || links === void 0 ? void 0 : links.TeamUrl) ? (React.createElement(SingleLinkItem, { leftIcon: getIconClassName("TeamsLogo"), url: links.TeamUrl, text: "Team", leftIconBackgroundColour: "teams-purple" })) : (links === null || links === void 0 ? void 0 : links.TeamUrl) === null ? null : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }))) : null,
                    (links === null || links === void 0 ? void 0 : links.TeamAdminCenterUrl) !== undefined ? ((links === null || links === void 0 ? void 0 : links.TeamAdminCenterUrl) ? (React.createElement(SingleLinkItem, { leftIcon: getIconClassName("AdminALogoInverse32"), url: links.TeamAdminCenterUrl, text: "Teams Admin Centre", leftIconBackgroundColour: "teams-purple" })) : (links === null || links === void 0 ? void 0 : links.TeamAdminCenterUrl) === null ? null : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }))) : null,
                    (links === null || links === void 0 ? void 0 : links.AzureAdUrl) !== undefined ? ((links === null || links === void 0 ? void 0 : links.AzureAdUrl) ? (React.createElement(SingleLinkItem, { leftImage: AzureLogo, url: links.AzureAdUrl, text: "Azure AD Admin", leftIconBackgroundColour: "light-blue" })) : (links === null || links === void 0 ? void 0 : links.AzureAdUrl) === null ? null : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }))) : null,
                    links.PlannerUrls !== undefined && links.PlannerUrls ? (links.PlannerUrls.length > 0 ? (React.createElement(MultiItemLink, { leftImage: PlannerLogo, leftIconBackgroundColour: "green", text: intl.formatMessage({ id: "component.workspace_links.planner_plans.title", defaultMessage: "Planner Plans" }), urls: (links === null || links === void 0 ? void 0 : links.PlannerUrls) || [], count: ((_b = links === null || links === void 0 ? void 0 : links.PlannerUrls) === null || _b === void 0 ? void 0 : _b.length) || 0 })) : null) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })),
                    links.NotebookUrls !== undefined && links.NotebookUrls ? (links.NotebookUrls.length > 0 ? (React.createElement(MultiItemLink, { leftImage: OneNoteLogo, leftIconBackgroundColour: "purple", text: intl.formatMessage({ id: "component.workspace_links.notebooks.title", defaultMessage: "Notebooks" }), urls: (links === null || links === void 0 ? void 0 : links.NotebookUrls) || [], count: ((_c = links === null || links === void 0 ? void 0 : links.NotebookUrls) === null || _c === void 0 ? void 0 : _c.length) || 0 })) : null) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })))) : (React.createElement("div", { className: "skeletons" },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })))))));
});
export default WorkspaceLinks;
