import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { metronic, toAbsoluteUrl } from "_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as GlobeIcon } from "_metronic/layout/assets/layout-svg-icons/Globe.svg";
import { LocalizationService } from "app/services/localization";
import Store from "app/store";
import * as OrchestryContextStore from "app/store/orchestry-context/current";

export const languages = LocalizationService.AllLangauges;

const LanguageSelector = React.memo(props => {
   //class LanguageSelector extends React.Component {
   const dispatch = useDispatch();

   //render() {
   const { lang, iconType, setLanguage } = props;
   const currentLanguage = languages.find(x => x.lang === lang);

   const getAvailLangauges = () => {
      const state = Store.getState();
      const cultureAvail = state.orchestryContextCurrentState.item.CultureAvailable;
      if (cultureAvail != null && Array.isArray(cultureAvail) && cultureAvail.length > 0) {
         return languages.filter(l => cultureAvail.some(ca => ca == l.lang));
      } else {
         return [currentLanguage];
      }
   };

   return getAvailLangauges().length > 1 ? (
      <>
         <Dropdown className="kt-header__topbar-item kt-header__topbar-item--langs" drop="down" alignRight>
            <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
               <span
                  className={clsx("kt-header__topbar-icon", {
                     "kt-header__topbar-icon--brand": iconType === "brand"
                  })}>
                  {/* <img src={currentLanguage.flag} alt={currentLanguage.name} /> */}
                  {/* <img src={"/media/icons/svg/Home/Globe.svg"} alt={currentLanguage.name} /> */}
                  {/* <GlobeIcon name={currentLanguage.name} className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg" /> */}
                  <div className="lang-letters" title={currentLanguage.name}>
                     <span>{currentLanguage.lang}</span>
                  </div>
               </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
               <ul className="kt-nav">
                  {getAvailLangauges().map(language => (
                     <li
                        key={language.lang}
                        className={clsx("kt-nav__item", {
                           "kt-nav__item--active": language.lang === currentLanguage.lang
                        })}>
                        <span
                           onClick={() => {
                              setLanguage(language.lang);
                              LocalizationService.setUserCulture(language.lang);
                              dispatch(OrchestryContextStore.ShowLoader(true));

                              // Don't need this for now as the page refreshes
                              // this.setState({ open: false });

                              //setTimeout(()=> window.location.reload(), 400);
                           }}
                           className={clsx("kt-nav__link", {
                              "kt-nav__link--active": language.lang === currentLanguage.lang
                           })}>
                           {/* <div className="lang-letters" title={language.name}>
                              <span>{language.lang}</span>
                           </div> */}
                           {/* <span className="kt-nav__link-icon">
                    <img src={language.flag} alt={language.name} />
                  </span> */}
                           <span className="kt-nav__link-text">{language.name}</span>
                        </span>
                     </li>
                  ))}
               </ul>
            </Dropdown.Menu>
         </Dropdown>
      </>
   ) : null;
   //}
});

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(mapStateToProps, metronic.i18n.actions)(LanguageSelector);
