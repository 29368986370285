import { useGetHealthChecksQuery } from "appv2/api/health-checks/health-checks.api";
import { useIntl } from "react-intl";
import { useHealthCheckRouteParams } from "../health-checks/use-health-check-route-params.hook";
export var useHealthCheckTeamsPage = function () {
    var _a, _b;
    var intl = useIntl();
    var _c = useHealthCheckRouteParams(), tenantGuid = _c.tenantGuid, healthCheckGuid = _c.healthCheckGuid;
    var _d = useGetHealthChecksQuery([
        "customer",
        tenantGuid,
        {
            queryParameters: {
                filter: "(UniqueId == \"".concat(healthCheckGuid, "\")"),
                orderBy: "",
                page: 1,
                pageSize: 1
            }
        }
    ]), pagedHealthChecks = _d.data, healthChecksIsLoading = _d.isLoading;
    var healthCheckName = (_b = (_a = pagedHealthChecks === null || pagedHealthChecks === void 0 ? void 0 : pagedHealthChecks.Items[0]) === null || _a === void 0 ? void 0 : _a.Name) !== null && _b !== void 0 ? _b : "";
    var isLoading = healthChecksIsLoading;
    return {
        intl: intl,
        tenantGuid: tenantGuid,
        healthCheckGuid: healthCheckGuid,
        healthCheckName: healthCheckName,
        isLoading: isLoading
    };
};
