var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditItem from "app/assets/img/icon-edit-pen.svg";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import DocumentIcon from "app/components/common/document-icon";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalItemGuest from "app/components/common/principal-item-guest";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import TextTruncate from "react-text-truncate"; // recommend
import { SHAREPOINT_MULTIGEO_CODES } from "../3_details/sharepoint-geocodes";
import "./step-confirmation.scss";
var StepConfirmation = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f;
    var rwc = React.useContext(RequestWizardContext);
    var _g = React.useState(false), showModal = _g[0], setShowModal = _g[1];
    var _h = React.useState([]), ownerMembersCombined = _h[0], setOwnerMemebersCombined = _h[1];
    var handleClose = function () { return setShowModal(false); };
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    React.useEffect(function () {
        // setTimeout(()=>{
        //     setShowModal(true);
        // },30000)
        if (props.step.canProgress === false) {
            rwc.setStepProgress(props.step.key, true);
        }
        setOwnerMemebersCombined(__spreadArray(__spreadArray([], getOwners(), true), getMembers().filter(function (u) { return !getOwners().some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); }), true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rwc.currentRequest.Owners, rwc.currentRequest.Members]);
    var getFeatureIcon = function (feature) {
        if (feature !== undefined) {
            var featureIcon = feature.Icon;
            if (featureIcon !== undefined) {
                return React.createElement(LineAwesomeIcon, { iconString: featureIcon });
            }
        }
        else {
            return React.createElement(FontAwesomeIcon, { icon: Icons.faBox });
        }
    };
    var getDocumentIcon = function (documentTempl) {
        if (documentTempl !== undefined && documentTempl.Type !== undefined) {
            return React.createElement(DocumentIcon, { size: "18", docType: documentTempl.Type });
        }
    };
    var getOwners = function () {
        var _a;
        var owners = [];
        if (currentUser !== undefined) {
            currentUser.Locked = true;
            owners.push(currentUser);
        }
        if ((((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateTeamCloneMembers) === true &&
            (rwc.currentRequest.RequestType === "Team" || (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernGroup"))) ||
            (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernCommunications")) {
            if (rwc.liveOwners !== undefined) {
                var tempOwners = rwc.liveOwners.filter(function (u) { return u.UserPrincipalName !== null; }).filter(function (u) { return !owners.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
                owners = __spreadArray(__spreadArray([], owners, true), tempOwners, true);
            }
        }
        if (rwc.currentRequest.Owners !== undefined && Array.isArray(rwc.currentRequest.Owners)) {
            var tempOwners = rwc.currentRequest.Owners.filter(function (u) { return !owners.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
            owners = __spreadArray(__spreadArray([], owners, true), tempOwners, true);
        }
        return owners;
    };
    var getMembers = function () {
        var _a;
        var members = [];
        if ((((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateTeamCloneMembers) === true &&
            (rwc.currentRequest.RequestType === "Team" || (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernGroup"))) ||
            (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernCommunications")) {
            if (rwc.liveMembers !== undefined) {
                var tempMembers = rwc.liveMembers.filter(function (u) { return !members.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
                members = __spreadArray(__spreadArray([], members, true), tempMembers, true);
            }
        }
        if (rwc.currentRequest.Members !== undefined && Array.isArray(rwc.currentRequest.Members)) {
            var tempMembers = rwc.currentRequest.Members.filter(function (u) { return !members.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
            members = __spreadArray(__spreadArray([], members, true), tempMembers, true);
        }
        return members;
    };
    var getNameWithNamingPolicies = function () {
        // if(
        //    rwc.currentRequest.Template?.NamingPolicyEnabled === true &&
        //    rwc.currentRequest.Template.NamingPolicyGUID !== undefined &&
        //    rwc.currentRequest.Template.NamingPolicyGUID !== null &&
        //    rwc.currentNamingPolicy !== undefined &&
        //    rwc.currentNamingPolicy.RenameWorkspaceTitle === true &&
        //    rwc.currentUser !== undefined
        // ){
        //    if(rwc.currentRequest.Name !== undefined){
        //       return GetNamingPolicyPreview(rwc.currentNamingPolicy, rwc.currentRequest.Name, rwc.currentUser, "name");
        //    }
        // } else {
        return rwc.currentRequest.Name;
        // }
    };
    return (React.createElement("div", { className: "step-confirmation" },
        React.createElement(Modal, { show: showModal, onHide: handleClose },
            React.createElement(Modal.Body, { className: "text-center" },
                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.dont_lose_request", defaultMessage: "Hey! Are you done? Don't lose your request!" }),
                React.createElement("br", null),
                React.createElement("br", null))),
        React.createElement("div", null,
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "area-details" },
                        React.createElement("div", { className: "workspace-title" },
                            React.createElement("span", { className: "text" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.workspace_details", defaultMessage: "Workspace Details" })),
                            React.createElement("div", { className: "edit-button", "data-pw": "edit-workspace-details", onClick: function () {
                                    rwc.setCurrentStep("create");
                                } },
                                React.createElement("img", { src: EditItem }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.edit_button", defaultMessage: "Edit" })))),
                        React.createElement("div", { className: "workspace-details" },
                            rwc.currentRequest.Image !== undefined && rwc.currentRequest.Image !== null && rwc.currentRequest.Image !== "" ? (React.createElement("div", { className: "logo" },
                                React.createElement("div", { className: "image", style: { backgroundImage: "url(" + rwc.currentRequest.Image + ")" } }))) : (React.createElement("div", { className: "type" },
                                React.createElement("div", { className: "icon" }, rwc.currentRequest.RequestType === "SharePoint" ? React.createElement("img", { src: iconSP }) : rwc.currentRequest.RequestType === "Team" ? React.createElement("img", { src: iconTeams }) : React.createElement("img", { src: iconViva })))),
                            React.createElement("div", { className: "details" },
                                React.createElement("h2", null, getNameWithNamingPolicies()),
                                React.createElement("p", null, rwc.currentRequest.Description)))))),
            rwc.skipStepKeys.includes("customize-workspace") === false ? (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "feats-and-temps" },
                    React.createElement(Col, { className: "left" },
                        React.createElement("div", { className: "section-title" },
                            React.createElement("span", { className: "text" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.features", defaultMessage: "Features" })),
                            ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.AllowFeatureCustomization) === true ? (React.createElement("div", { className: "edit-button", "data-pw": "edit-features", onClick: function () {
                                    rwc.setCurrentStep("customize-workspace");
                                } },
                                React.createElement("img", { src: EditItem }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.edit_button", defaultMessage: "Edit" })))) : null)),
                    React.createElement(Col, null,
                        React.createElement("div", { className: "section-title" },
                            React.createElement("span", { className: "text" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.templates", defaultMessage: "Templates" })),
                            ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.AllowDocumentsCustomization) === true ? (React.createElement("div", { className: "edit-button", "data-pw": "edit-templates", onClick: function () {
                                    rwc.setCurrentStep("customize-workspace");
                                } },
                                React.createElement("img", { src: EditItem }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.edit_button", defaultMessage: "Edit" })))) : null))),
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement("div", { className: "item-well" },
                            React.createElement("div", { className: "items" }, rwc.currentRequest.AssociatedFeatures !== undefined && rwc.currentRequest.AssociatedFeatures !== undefined && rwc.currentRequest.AssociatedFeatures.length > 0 ? (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedFeatures.map(function (assFeat) {
                                var _a;
                                return (React.createElement("div", { className: "item", key: assFeat.GUID },
                                    React.createElement("div", { className: "icon" }, getFeatureIcon(assFeat.Item)),
                                    React.createElement("div", { className: "title" },
                                        React.createElement(TextTruncate, { line: 2, maxCalculateTimes: 20, element: "div", truncateText: "\u2026", text: (_a = assFeat.Item) === null || _a === void 0 ? void 0 : _a.Title }))));
                            }))) : (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedFeatures !== undefined && rwc.currentRequest.AssociatedFeatures.length !== ((_d = (_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.AssociatedFeatures) === null || _d === void 0 ? void 0 : _d.length) ? (React.createElement("div", { className: "list-empty" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.no_features_selected", defaultMessage: "No features selected" }))) : (React.createElement("div", { className: "list-empty" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.no_features_available", defaultMessage: "No features available" })))))))),
                    React.createElement(Col, { className: "right" },
                        React.createElement("div", { className: "item-well" },
                            React.createElement("div", { className: "items" }, rwc.currentRequest !== undefined && rwc.currentRequest.AssociatedDocumentTemplates !== undefined && rwc.currentRequest.AssociatedDocumentTemplates.length !== 0 ? (React.createElement(React.Fragment, null, rwc.currentRequest.AssociatedDocumentTemplates.map(function (assDoc) {
                                var _a;
                                return (React.createElement("div", { className: "item", key: assDoc.GUID },
                                    React.createElement("div", { className: "icon" }, getDocumentIcon(assDoc.Item)),
                                    React.createElement("div", { className: "title" },
                                        React.createElement(TextTruncate, { line: 2, maxCalculateTimes: 20, element: "div", truncateText: "\u2026", text: (_a = assDoc.Item) === null || _a === void 0 ? void 0 : _a.Title }))));
                            }))) : (React.createElement("div", { className: "list-empty" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.no_document_templates", defaultMessage: "No document templates" }))))))))) : null,
            rwc.steps.some(function (stp) { return stp.key === "channels"; }) !== undefined && ((_e = rwc.steps.find(function (stp) { return stp.key === "channels"; })) === null || _e === void 0 ? void 0 : _e.stepEnabled) === true ? (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "channels" },
                    React.createElement(Col, null,
                        React.createElement("div", { className: "section-title" },
                            React.createElement("span", { className: "text" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.channels", defaultMessage: "Channels" })),
                            React.createElement("div", { className: "edit-button", "data-pw": "edit-channels", onClick: function () {
                                    rwc.setCurrentStep("channels");
                                } },
                                React.createElement("img", { src: EditItem }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.edit_button", defaultMessage: "Edit" })))))),
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement("div", { className: "item-well no-cols" },
                            React.createElement("div", { className: "items" }, (rwc.currentRequest !== undefined &&
                                rwc.currentRequest.RequestChannels !== undefined &&
                                rwc.currentRequest.RequestChannels !== null &&
                                rwc.currentRequest.RequestChannels.length !== 0) ||
                                (rwc.cloneTeamChannels !== undefined && rwc.cloneTeamChannels !== null && rwc.cloneTeamChannels.length !== 0) ? (React.createElement(React.Fragment, null,
                                rwc.cloneTeamChannels !== undefined
                                    ? rwc.cloneTeamChannels.map(function (chan) { return (React.createElement("div", { className: "item", key: chan === null || chan === void 0 ? void 0 : chan.id },
                                        React.createElement("div", { className: "title" }, chan === null || chan === void 0 ? void 0 : chan.displayName))); })
                                    : null,
                                rwc.currentRequest !== undefined && rwc.currentRequest.RequestChannels !== null && rwc.currentRequest.RequestChannels !== undefined
                                    ? rwc.currentRequest.RequestChannels.map(function (rqstChan) { return (React.createElement("div", { className: "item", key: rqstChan.GUID },
                                        React.createElement("div", { className: "title" }, rqstChan.Title))); })
                                    : null)) : (React.createElement("div", { className: "list-empty" },
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.no_channels", defaultMessage: "No channels" }))))))))) : null,
            React.createElement(Row, { className: "permissions" },
                React.createElement(Col, null,
                    React.createElement("div", { className: "section-title" },
                        React.createElement("span", { className: "text" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.permissions_and_sharing", defaultMessage: "Permissions and Sharing" })),
                        React.createElement("div", { className: "edit-button", "data-pw": "edit-permissions", onClick: function () {
                                rwc.setCurrentStep("permissions");
                            } },
                            React.createElement("img", { src: EditItem }),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.edit_button", defaultMessage: "Edit" })))))),
            React.createElement(Row, { className: "permissions-info" },
                React.createElement(Col, { className: "permissions-details-left" },
                    React.createElement("div", { className: "following-text" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.following_team_members_can_access", defaultMessage: "Following team members can access it:" }))),
                React.createElement(Col, { className: "permissions-details-right" },
                    rwc.currentRequest.Classification !== null ? (React.createElement("div", { className: "sensitivity-text" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.classification_is", defaultMessage: "Classification is" }),
                            " "),
                        React.createElement("span", { className: "sensitivity-type" }, rwc.currentRequest.Classification))) : null,
                    rwc.getSelectedSensitivityLabel() !== null ? (React.createElement("div", { className: "sensitivity-text" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.sensitivity_is", defaultMessage: "Sensitivity is" }),
                            " "),
                        React.createElement("span", { className: "sensitivity-type" }, (_f = rwc.getSelectedSensitivityLabel()) === null || _f === void 0 ? void 0 : _f.Name))) : null,
                    rwc.currentRequest.RequestMultiGeoDataLocation && (React.createElement("div", { className: "geography-text" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.geography_is", defaultMessage: "Geography is" }),
                            " "),
                        React.createElement("span", { className: "geography-type" },
                            rwc.currentRequest.RequestMultiGeoDataLocation,
                            " (",
                            SHAREPOINT_MULTIGEO_CODES[rwc.currentRequest.RequestMultiGeoDataLocation],
                            ")"))),
                    React.createElement("div", { className: "permission-text" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.workspace_is", defaultMessage: "This workspace is" }),
                            " "),
                        React.createElement("span", { className: "permission-type" }, rwc.currentRequest.PermissionType === "Private" ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.private_label", defaultMessage: "Private" })) : (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.public_label", defaultMessage: "Public" })))))),
            React.createElement(Row, { className: "area-permissions" },
                React.createElement(Col, null,
                    React.createElement("div", { className: "user-list" },
                        ownerMembersCombined.map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true })
                        // <div className="user" key={user.Id}>
                        //       <div className="pic">
                        //          <OfficeProfilePicture uid={user.UserPrincipalName} displayName={user.DisplayName} type={user.Type} />
                        //       </div>
                        //       <span>{user.DisplayName}</span>
                        // </div>
                        ); }),
                        ownerMembersCombined.length === 0 ? (React.createElement("div", { className: "no-results" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_confirmation.no_members_added", defaultMessage: "No members added" }))) : null,
                        Array.isArray(rwc.guestStorage) && rwc.guestStorage.map(function (guestUser) { return React.createElement(PrincipalItemGuest, { principal: guestUser, key: guestUser.Id, showBadge: true }); })))))));
});
export default StepConfirmation;
