var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// import * as React from 'react';
import React from "react";
import "./features-modal.scss";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormSearchbox from "app/components/common/form-searchbox";
import { useSelector, useDispatch } from "react-redux";
import * as FeatureListStore from "app/store/features/list";
import * as LibraryFeatureListStore from "app/store/library-features/list";
import highlightWords from "app/utils/highlightWords";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import * as FeatureCurrentStore from "app/store/features/current";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import { useIntl } from "react-intl";
//class FeaturesModal extends React.Component<IProps, IState> {
var FeaturesModal = React.memo(function (props, state) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(), selectedFeature = _b[0], setSelectedFeature = _b[1];
    var _c = React.useState([]), addFeatures = _c[0], setAddFeatures = _c[1];
    var _d = React.useState([]), list = _d[0], setList = _d[1];
    var _e = React.useState(""), searchTerm = _e[0], setSearchTerm = _e[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var scrollRef;
    var currentFeature = useSelector(function (state) { return state.featureCurrentState.item; });
    var availableFeatures;
    var selectorTarget = "";
    if (props.libraryMode !== undefined && props.libraryMode === true) {
        availableFeatures = useSelector(function (state) { return state.libraryFeatureListState.items; }); //eslint-disable-line
    }
    else {
        availableFeatures = useSelector(function (state) { return state.featureListState.items; }); //eslint-disable-line
    }
    if (props.availableFeatures !== undefined) {
        availableFeatures = props.availableFeatures;
    }
    //console.log("Feature Modal > availableFeatures", props.availableFeatures);
    React.useEffect(function () {
        if (props.associatedFeatures !== undefined && availableFeatures !== undefined) {
            //console.log("Associated featured changed", availableFeatures);
            var selectedFeatures = props.associatedFeatures.filter(function (af) {
                var isNotLocked = true;
                if (availableFeatures.some(function (availFeat) { var _a; return (availFeat === null || availFeat === void 0 ? void 0 : availFeat.GUID) === ((_a = af.Item) === null || _a === void 0 ? void 0 : _a.GUID); })) {
                    isNotLocked = false;
                }
                //console.log("ass feat check ", af, isNotLocked);
                return isNotLocked;
            });
            //let selectedFeatures = [...props.associatedFeatures];
            //setAddFeatures(selectedFeatures.map((f) => f.Item) as Feature[]);
            setAddFeatures(selectedFeatures.map(function (f) { return f.Item; }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableFeatures, props.associatedFeatures]);
    React.useEffect(function () {
        if (props.libraryMode !== undefined && props.libraryMode === true) {
            dispatch(LibraryFeatureListStore.Load());
        }
        else {
            dispatch(FeatureListStore.Load());
        }
        return function () {
            dispatch(FeatureCurrentStore.Clear());
        };
    }, []);
    React.useEffect(function () {
        if (list.length === 0) {
            //console.log("List Length 0")
            setList(availableFeatures);
        }
        else if (list.map(function (l) { return l.GUID; }).join() !== availableFeatures.map(function (l) { return l.GUID; }).join()) {
            setList(availableFeatures);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableFeatures]);
    var filterItems = function (value) {
        if (scrollRef !== undefined) {
            scrollRef.scrollTo(0, 0);
        }
        if (value.length > 0) {
            var foundOptions = availableFeatures.filter(function (i) { return i.Title !== undefined && i.Title.toLowerCase().indexOf(value.toLowerCase()) > -1; });
            //console.log("foundoptions", foundOptions);
            setList(__spreadArray([], foundOptions, true));
            setSearchTerm(value);
        }
        else {
            setList(__spreadArray([], availableFeatures, true));
            setSearchTerm("");
        }
    };
    var toggleModal = function () {
        setShowModal(!showModal);
        //is now closed
        if (showModal === true) {
            setTimeout(function () {
                setSelectedFeature({});
            }, 500);
            //console.log("closed");
        }
    };
    var setSelected = function (feature) {
        //if(!isFeatEnabled(feature)){
        if (selectedFeature === undefined || (selectedFeature !== undefined && selectedFeature.GUID !== feature.GUID)) {
            setSelectedFeature(feature);
            if (feature.Image === undefined) {
                dispatch(FeatureCurrentStore.Load({ GUID: feature.GUID }));
            }
        }
        //}
    };
    React.useEffect(function () {
        if (currentFeature.GUID !== undefined && selectedFeature !== undefined && selectedFeature.GUID !== undefined) {
            if (selectedFeature.GUID === currentFeature.GUID) {
                setSelectedFeature(currentFeature);
            }
        }
    }, [currentFeature]);
    var isFeatEnabled = function (feat) {
        var matched = false;
        if (feat !== undefined) {
            if (addFeatures !== undefined) {
                addFeatures.forEach(function (f, index) {
                    if (f.GUID === feat.GUID) {
                        matched = true;
                    }
                });
            }
        }
        return matched;
    };
    var toggleSelectedFeatureToBeAdded = function (feat, event) {
        event.stopPropagation();
        if (feat !== undefined) {
            if (isFeatPreSelected(feat) === false) {
                if (addFeatures.some(function (f) { return f.GUID === feat.GUID; }) === false) {
                    setAddFeatures(__spreadArray(__spreadArray([], addFeatures, true), [feat], false));
                }
                else {
                    setAddFeatures(addFeatures.filter(function (f) { return f.GUID !== feat.GUID; }));
                }
            }
        }
    };
    var isFeatPreSelected = function (feat) {
        var isPreSelected = false;
        if (props.associatedFeatures !== undefined) {
            isPreSelected = props.associatedFeatures.some(function (af) { var _a; return ((_a = af.Item) === null || _a === void 0 ? void 0 : _a.GUID) === feat.GUID; });
        }
        return isPreSelected;
    };
    var getNonPreselectedAddFeatures = function () {
        return addFeatures.filter(function (f) { return !isFeatPreSelected(f); });
    };
    var addFeaturesSave = function () {
        props.handleSelected(getNonPreselectedAddFeatures());
        toggleModal();
    };
    return (React.createElement(React.Fragment, null,
        props.children === undefined || props.children === null ? (React.createElement("div", { className: "button-custom b-orange", onClick: function () { return toggleModal(); } },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            React.createElement("span", null, intl.formatMessage({ id: "component.feature-select-modal.more-features", defaultMessage: "More Features" })))) : (React.createElement("span", { onClick: function () { return toggleModal(); } }, props.children)),
        React.createElement(Modal, { centered: true, show: showModal, onHide: function () { return toggleModal(); }, animation: true, className: "features-modal" },
            React.createElement(Container, { fluid: true },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 5, className: "col-left" },
                        React.createElement("div", { className: "left-area" },
                            React.createElement(FormSearchbox, { placeholder: intl.formatMessage({ id: "component.document-select-modal.search", defaultMessage: "Search" }), handler: function (input) {
                                    filterItems(input.target.value);
                                }, useAltStyle: true }),
                            React.createElement("div", { className: "feature-list" },
                                React.createElement(DynamicScrollbar, { shouldShow: list !== undefined && list.length > 9 ? true : false, maxHeight: 500 },
                                    React.createElement("ul", { className: "list-unstyled" },
                                        list &&
                                            list.map(function (feature, index) { return (React.createElement("li", { key: index, onClick: function () { return setSelected(feature); }, className: "" +
                                                    ((selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.GUID) === feature.GUID ? "feat-selected" : "") +
                                                    " " +
                                                    (isFeatEnabled(feature) ? "feat-enabled" : "") +
                                                    " " +
                                                    (isFeatPreSelected(feature) ? "pre-selected" : "") },
                                                React.createElement("span", { className: "icon" }, feature.Icon && React.createElement(LineAwesomeIcon, { iconString: feature.Icon })),
                                                React.createElement("span", { className: "text", dangerouslySetInnerHTML: { __html: highlightWords(searchTerm, feature.Title !== undefined ? feature.Title : "") } }),
                                                React.createElement("div", { className: "action", onClick: function (event) {
                                                        toggleSelectedFeatureToBeAdded(feature, event);
                                                        setSelected(feature);
                                                    } },
                                                    React.createElement("i", { className: "add fa fa-plus" }),
                                                    React.createElement("i", { className: "check fa fa-check" })))); }),
                                        list.length === 0 && React.createElement("li", null, intl.formatMessage({ id: "component.feature-select-modal.no-results", defaultMessage: "No Results" }))))))),
                    React.createElement(Col, { sm: 7, className: "col-right" },
                        React.createElement("div", { className: "right-area" },
                            React.createElement("div", null,
                                (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.Image) !== undefined && (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.Image) !== null && (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.Image) !== "" ? (React.createElement("div", { className: "preview-image", style: { backgroundImage: "url('" + (selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.Image) + "')" } })) : null,
                                React.createElement("div", { className: "infobox" },
                                    React.createElement("h5", null, selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.Title),
                                    React.createElement("p", null, selectedFeature === null || selectedFeature === void 0 ? void 0 : selectedFeature.Description))),
                            React.createElement("div", { className: "actions" },
                                React.createElement("div", { className: "cancel", onClick: function () { return toggleModal(); } },
                                    React.createElement("span", null, intl.formatMessage({ id: "general.label.cancel", defaultMessage: "Cancel" }))),
                                addFeatures === undefined || (addFeatures !== undefined && getNonPreselectedAddFeatures().length === 0) ? (React.createElement("div", { className: "save disabled" },
                                    React.createElement("i", { className: "la la-plus" }),
                                    React.createElement("span", null,
                                        intl.formatMessage({ id: "component.feature-select-modal.add_feature", defaultMessage: "Add Feature" }),
                                        " (",
                                        React.createElement("span", { className: "count" }, "0"),
                                        ")"))) : (React.createElement("div", { className: "save", onClick: function () {
                                        addFeaturesSave();
                                    } },
                                    React.createElement("i", { className: "la la-plus" }),
                                    React.createElement("span", null,
                                        intl.formatMessage({ id: "component.feature-select-modal.add_feature", defaultMessage: "Add Feature" }),
                                        getNonPreselectedAddFeatures().length > 1 ? React.createElement(React.Fragment, null, "s") : null,
                                        " (",
                                        React.createElement("span", { className: "count" }, getNonPreselectedAddFeatures().length),
                                        ")")))))))))));
});
export default FeaturesModal;
