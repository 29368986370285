var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AsyncActionTypes, AsyncCallType, AsyncStatus } from "app/store/async";
import axios from "axios";
import { actionMap } from "./config";
export function startAsync() {
    return {
        type: AsyncActionTypes.Async_Start,
        payload: []
    };
}
var AsyncActionCreators = /** @class */ (function () {
    function AsyncActionCreators() {
    }
    AsyncActionCreators.Start = function (asyncItem) {
        asyncItem.status = AsyncStatus.Started;
        asyncItem.lastUpdated = new Date();
        return {
            type: AsyncActionTypes.Async_Start,
            payload: asyncItem
        };
    };
    AsyncActionCreators.Succeeded = function (asyncItem) {
        asyncItem.status = AsyncStatus.Succeeded;
        asyncItem.lastUpdated = new Date();
        return {
            type: AsyncActionTypes.Async_Succeed,
            payload: asyncItem
        };
    };
    AsyncActionCreators.Failed = function (asyncItem) {
        asyncItem.status = AsyncStatus.Failed;
        asyncItem.lastUpdated = new Date();
        return {
            type: AsyncActionTypes.Async_Failed,
            payload: asyncItem
        };
    };
    AsyncActionCreators.Invalid = function (asyncItem) {
        asyncItem.status = AsyncStatus.Invalid;
        asyncItem.lastUpdated = new Date();
        return {
            type: AsyncActionTypes.Async_Invalid,
            payload: asyncItem
        };
    };
    return AsyncActionCreators;
}());
export { AsyncActionCreators };
export var asyncMiddlware = function (store, next, action) {
    var state = store.getState();
    var didMatch = false;
    //console.log("actionMap", actionMap);
    // For each action map.
    actionMap.forEach(function (am) {
        // If the type matches.
        if (action.type === am.actionType) {
            var apiUrl = "";
            // Used below to know when to run next()
            didMatch = true;
            // Setup the request URL.
            if (am.keyType === "guid") {
                apiUrl = am.ApiUrl.replace("{key}", action.payload.GUID);
            }
            else if (am.keyType === "Id") {
                apiUrl = am.ApiUrl.replace("{key}", action.payload.Id);
            }
            else if (am.ApiUrl.indexOf("{") > -1) {
                apiUrl = replaceNameLink(am.ApiUrl, action.payload);
            }
            else {
                apiUrl = am.ApiUrl;
            }
            // Try to get current async item based on key.
            var isAlreadyInState = false;
            var isInvalid = false;
            var ai_1 = {
                status: AsyncStatus.Started,
                actionType: action.type,
                callType: am.callType,
                key: am.keyType === "guid" ? action.payload.GUID : am.actionType.toString(),
                lastUpdated: new Date(),
                itemPayload: action.payload
            };
            // Invalidate specific actions if configured to do so.
            if (am.invalidates !== undefined) {
                am.invalidates.forEach(function (invalidateAction) {
                    var findAI = state.asyncState.items.find(function (i) { return i.key === invalidateAction; });
                    if (findAI !== undefined) {
                        store.dispatch(AsyncActionCreators.Invalid(findAI));
                    }
                });
            }
            // Search to see if the list is invalidated.
            var searchKey_1 = am.keyType === "guid" ? action.payload.GUID : am.actionType.toString();
            var findInvalidStatusOnAI = state.asyncState.items.find(function (i) { return i.key === searchKey_1; });
            if (findInvalidStatusOnAI !== undefined) {
                if (findInvalidStatusOnAI.status === AsyncStatus.Invalid) {
                    isInvalid = true;
                    //console.log("Is Invalid >", action);
                }
            }
            // // If the async item exists.
            // Check to see if values are cached for List or Get
            if (true) {
                if (am.stateLocationForArray !== undefined && isInvalid === false) {
                    var stateArray = am.stateLocationForArray.split(".").reduce(function (o, i) { return o[i]; }, state);
                    if (am.callType === AsyncCallType.List) {
                        if (stateArray.length > 1) {
                            isAlreadyInState = true;
                            action.payload = stateArray;
                        }
                    }
                    //else if (am.callType === AsyncCallType.Get){
                    if (am.callType === AsyncCallType.Get) {
                        if (stateArray.length > 0) {
                            var stateItem = stateArray.find(function (item) { return item.GUID === action.payload.GUID; });
                            //console.log("Async - Get Item", stateArray, stateItem);
                            isAlreadyInState = true;
                            action.payload = stateItem;
                        }
                    }
                }
            }
            // If value is already in state load from that instead.
            if (isAlreadyInState) {
                //console.log("Loading from Cache ", action.type);
                next(action);
                store.dispatch(AsyncActionCreators.Succeeded(__assign({}, ai_1)));
            }
            // Load from Server if not previously cached.
            else {
                // Setup Async Log Item
                ai_1 = {
                    status: AsyncStatus.Started,
                    actionType: action.type,
                    callType: am.callType,
                    key: am.keyType === "guid" ? action.payload.GUID : am.keyType === "Id" ? action.payload.Id : am.actionType.toString(),
                    lastUpdated: new Date(),
                    itemPayload: action.payload
                };
                // Some calls like adding an item or updating an item don't need to wait for the ajax call to finish.
                // This check allows me make the app a lot snappier.
                var runNextBeforeCall_1 = [AsyncCallType.Add, AsyncCallType.Update, AsyncCallType.Delete].some(function (ct) { return ct === am.callType; });
                // Start the ajx call.
                store.dispatch(AsyncActionCreators.Start(__assign({}, ai_1)));
                if (runNextBeforeCall_1) {
                    next(action);
                }
                // Make Async call.
                asyncCall(ai_1, am, action, apiUrl)
                    .then(function (data) {
                    //console.log("Ajax Data1", data, action)
                    if ((ai_1.callType === AsyncCallType.Get || ai_1.callType === AsyncCallType.AddGet) && Array.isArray(data) === true) {
                        data.forEach(function (item) {
                            if (am.keyType !== "null") {
                                if (item.GUID === action.payload.GUID) {
                                    action.payload = item;
                                }
                                if (action.payload.GUID === "" && data.length === 1) {
                                    action.payload = item;
                                }
                            }
                            else {
                                console.log("Ajax Data1 For Each", data, action);
                                action.payload = item;
                            }
                        });
                    }
                    else {
                        action.payload = data;
                    }
                    // ## PROD MODE #################
                    // This is what it should be without dev mode
                    //action.payload = data
                    if (!runNextBeforeCall_1) {
                        next(action);
                    }
                    store.dispatch(AsyncActionCreators.Succeeded(__assign({}, ai_1)));
                })
                    .catch(function (error) {
                    //console.log("Ajax Error", error);
                    ai_1.error = error;
                    store.dispatch(AsyncActionCreators.Failed(__assign({}, ai_1)));
                    // next(null);
                })
                    .finally(function () { });
            }
        }
    });
    //console.log("async action", action);
    if (didMatch === false) {
        next(action);
    }
};
export function asyncCall(asyncItem, asyncActionMapping, action, apiUrl) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    // First let's configure the ajax request
                    var axiosConfig = {};
                    // Determine Ajax Method
                    if ([AsyncCallType.Add, AsyncCallType.AddGet].some(function (ct) { return ct === asyncActionMapping.callType; }))
                        axiosConfig.method = "PUT";
                    else if ([AsyncCallType.Update].some(function (ct) { return ct === asyncActionMapping.callType; }))
                        axiosConfig.method = "PATCH";
                    else if (AsyncCallType.Delete === asyncActionMapping.callType)
                        axiosConfig.method = "DELETE";
                    else if ([AsyncCallType.Get, AsyncCallType.Search, AsyncCallType.List, AsyncCallType.GetCustom].some(function (ct) { return ct === asyncActionMapping.callType; }))
                        axiosConfig.method = "GET";
                    else if ([AsyncCallType.Post].some(function (ct) { return ct === asyncActionMapping.callType; }))
                        axiosConfig.method = "POST";
                    // Set the API URL
                    axiosConfig.url = apiUrl;
                    // Set any Data Needed
                    if (axiosConfig.method !== "GET") {
                        var bodyFormData = new FormData();
                        bodyFormData.set("data", JSON.stringify(action.payload));
                        axiosConfig.data = bodyFormData;
                    }
                    // Set Reponse Type
                    axiosConfig.responseType = "json";
                    axios(axiosConfig)
                        .then(function (response) {
                        if (response["data"] !== undefined) {
                            var returnData = response.data;
                            resolve(returnData);
                        }
                        else {
                            resolve(asyncActionMapping.responseDataType);
                        }
                    })
                        .catch(function (response) {
                        //console.log("Axios Response");
                        reject(response);
                    });
                })];
        });
    });
}
var replaceNameLink = function (url, payload) {
    var propertyNameToReplace = url.substring(url.lastIndexOf("{") + 1, url.lastIndexOf("}"));
    var propertyToReplaceValue;
    if (propertyNameToReplace.indexOf(".") < 0) {
        propertyToReplaceValue = payload[propertyNameToReplace];
    }
    else {
        propertyToReplaceValue = propertyNameToReplace.split(".").reduce(function (o, i) { return o[i]; }, payload);
    }
    url = url.replace("{" + propertyNameToReplace + "}", propertyToReplaceValue);
    if (url.lastIndexOf("{") > -1) {
        url = replaceNameLink(url, payload);
    }
    return url;
};
