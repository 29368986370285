import axios from "axios";
var InstallCodesService = /** @class */ (function () {
    function InstallCodesService() {
    }
    InstallCodesService.GetCodes = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/InstallCodes";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject([]);
            });
        });
    };
    InstallCodesService.AddUpdate = function (installCode) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "PATCH";
            axiosConfig.url = "/api/InstallCodes";
            axiosConfig.responseType = "json";
            var formData = new FormData();
            formData.append("data", JSON.stringify(installCode));
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallCodesService.GetCompleteProgressLogsFromRequest = function (requestGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Requests/GetCompleteProgressLog?guid=" + requestGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return InstallCodesService;
}());
export default InstallCodesService;
