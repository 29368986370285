import { Card, Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-card-skeleton.scss";
export var RecommendationCardSkeleton = function () { return (React.createElement(Card, { className: "recommendation-card-skeleton", radius: "md", "data-padding-size": "lg" },
    React.createElement("div", { className: "left-section" },
        React.createElement(Skeleton, { width: 191, height: 12.25, mt: 8, radius: 17.5 }),
        React.createElement(Skeleton, { width: 473, height: 12.25, mt: 11, radius: 17.5 }),
        React.createElement("div", { className: "badge-section" },
            React.createElement(Skeleton, { width: 78, height: 22, radius: 16 }),
            React.createElement(Skeleton, { width: 78, height: 22, ml: 4, radius: 16 }),
            React.createElement(Skeleton, { circle: true, height: 16, ml: 4 }))),
    React.createElement("div", { className: "right-section" },
        React.createElement(Skeleton, { circle: true, height: 32, mr: 8 }),
        React.createElement(Skeleton, { width: 33, height: 10.5, radius: 15, mr: 61 }),
        React.createElement(Skeleton, { width: 12, height: 6, mr: 8, radius: 10 })))); };
