import * as Icons from "@fortawesome/free-solid-svg-icons";
export var MetadataFieldTypes = [
    {
        Title: "Choice",
        Value: "Choice",
        Icon: Icons.faTasks
    },
    {
        Title: "Date",
        Value: "Date",
        Icon: Icons.faCalendarDay
    },
    {
        Title: "Text field",
        Value: "Text",
        Icon: Icons.faFont
    },
    {
        Title: "Managed Metadata",
        Value: "ManagedMetadata",
        Icon: Icons.faTags
    },
    {
        Title: "External Link",
        Value: "Link",
        Icon: Icons.faLink
    }
    // {
    //    Title: "Person Or Group",
    //    Value: "PersonOrGroup",
    //    Icon: Icons.faUsers
    // },
    // {
    //    Title: "Number",
    //    Value: "Number",
    //    Icon: Icons.faHashtag
    // }
];
export var MetadataSingleMultiples = [
    {
        Title: "Single",
        Value: "Single",
        Icon: Icons.faMinus
    },
    {
        Title: "Multiple",
        Value: "Multiple",
        Icon: Icons.faAlignJustify
    }
];
