import { Card, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { CardKeyValue } from "../../card-key-value.component";
import { OrchestryCardTitle } from "../../orchestry-card-title.component";
import { useTenantPage } from "../../use-tenant-page.hook";
export var Recommendations = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Recommendations, _c = _b === void 0 ? {} : _b, NumberOfActiveRecommendationsCount = _c.NumberOfActiveRecommendationsCount, NumberOfSnoozedRecommendationsCount = _c.NumberOfSnoozedRecommendationsCount, NumberOfNARecommendationsCount = _c.NumberOfNARecommendationsCount, NumberOfResolvedRecommendationsCount = _c.NumberOfResolvedRecommendationsCount, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, h: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Recommendations" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.checkVerified02, keyString: "Number of Active Recommendations", value: NumberOfActiveRecommendationsCount === null || NumberOfActiveRecommendationsCount === void 0 ? void 0 : NumberOfActiveRecommendationsCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.clockSnooze, keyString: "Number of Snoozed Recommendations", value: NumberOfSnoozedRecommendationsCount === null || NumberOfSnoozedRecommendationsCount === void 0 ? void 0 : NumberOfSnoozedRecommendationsCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.archive, keyString: "Number of N/A Recommendations", value: NumberOfNARecommendationsCount === null || NumberOfNARecommendationsCount === void 0 ? void 0 : NumberOfNARecommendationsCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.archive, keyString: "Number of Resolved Recommendations", value: NumberOfResolvedRecommendationsCount === null || NumberOfResolvedRecommendationsCount === void 0 ? void 0 : NumberOfResolvedRecommendationsCount.toLocaleString() }))));
};
