import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIconClassName } from "@uifabric/styling";
import CommSiteLogo from "app/assets/img/icon-sharepoint.svg";
import TeamsLogo from "app/assets/img/icon-teams.svg";
import VivaLogo from "app/assets/img/icon-viva-engage.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
import MetadataField from "app/components/common/metadata-field";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import DirectoryService from "app/services/directory";
import * as DirectoryStore from "app/store/directory/search";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import * as TeamInformationCurrentStore from "app/store/team-information/current";
import { MicrosoftAppType } from "app/store/team-information/current";
import formatDataSize from "app/utils/formatDataSize";
import { useGuestAccess } from "app/utils/hooks/useGuestAccess";
import TeamClientAppNavigation from "app/utils/teamClientAppNavigation";
import moment from "moment";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./lifecycle-workspace-details.scss";
var templateTypeMap = {
    Team: { label: "Microsoft Team", token: "page.archival_approval.workspace_type_value.team", icon: TeamsLogo },
    "SharePoint Team": { label: "Team Site", token: "page.archival_approval.workspace_type_value.sharepoint_team", icon: CommSiteLogo },
    "SharePoint Communications": { label: "Communication Site", token: "page.archival_approval.workspace_type_value.sharepoint_communications", icon: CommSiteLogo },
    "Viva Engage Community": { label: "Viva Engage Community", token: "page.archival_approval.workspace_type_value.viva_engage_community", icon: VivaLogo }
};
var LifecycleWorkspaceDetails = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var intl = useIntl();
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var groupId = (_a = reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupId) !== null && _a !== void 0 ? _a : "";
    var history = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.history; });
    var listMetadata = useSelector(function (state) { return state.metadataListState.items; });
    var policyWorkspaceMembers = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.members; });
    var directoryItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.directoryItem; });
    var dispatch = useDispatch();
    var _j = React.useState(false), showMetadataModal = _j[0], setshowMetadataModal = _j[1];
    var _k = React.useState(undefined), customMetadataValuesJson = _k[0], setCustomMetadataValuesJson = _k[1];
    var _l = React.useState(true), editShowInDirectory = _l[0], setEditShowInDirectory = _l[1];
    var _m = React.useState(false), updateItemLoading = _m[0], setUpdateItemLoading = _m[1];
    var _o = React.useState(true), isEditValid = _o[0], setIsEditValid = _o[1];
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    // Our guest policy is serving the purpose of adding guests in lieu of M365's in reportItem?.GroupSettingsGuestAccessEnabled.
    var isGuestAccessEnabled = useGuestAccess();
    var isInMinimalMode = function () {
        return props.minimalMode !== undefined && props.minimalMode === true;
    };
    React.useEffect(function () {
        if (directoryItem !== null) {
            setEditShowInDirectory(directoryItem.IsVisibleInDirectory);
        }
    }, [directoryItem]);
    React.useEffect(function () {
        if (reportItem !== null && isInMinimalMode() === false) {
            dispatch(LifecyclePolicyWorkspaceStore.LoadDirectoryItem(reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId));
        }
    }, [reportItem]);
    React.useEffect(function () {
        if (groupId !== "") {
            dispatch(TeamInformationCurrentStore.LoadCosmosObject(groupId, MicrosoftAppType.Teams));
        }
    }, [groupId]);
    var updateCustomMetadataValue = function (mdfv) {
        var currentValues = [mdfv];
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            var foundCurrent_1 = false;
            currentValues = valuesArray.map(function (fv) {
                if (fv.FieldGUID === mdfv.FieldGUID) {
                    foundCurrent_1 = true;
                    return mdfv;
                }
                else {
                    return fv;
                }
            });
            if (foundCurrent_1 === false) {
                currentValues.push(mdfv);
            }
        }
        setCustomMetadataValuesJson(JSON.stringify(currentValues));
    };
    var getCurrentMetadataFieldValue = function (md) {
        var value = undefined;
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            valuesArray.forEach(function (mdfv, index) {
                if (mdfv.FieldGUID === md.GUID) {
                    value = mdfv.Values;
                }
                else if (mdfv.FieldTitle === md.Title) {
                    value = mdfv.Values;
                }
            });
        }
        if (value === undefined && directoryItem) {
            directoryItem.Metadata.forEach(function (metadata, mdIndex) {
                if (metadata.SpColumnsName === md.SPFieldInternalName) {
                    if (metadata.Values !== undefined && metadata.Values !== null && metadata.Values !== "") {
                        if (md.FieldType === "Choice" && md.SingleMultiple === "Multiple") {
                            value = metadata.Values.replace(/\|/g, "\r\n");
                        }
                        else if (md.FieldType === "Date") {
                            value = moment(metadata.Values).format("YYYY-MM-DD");
                        }
                        else if (md.FieldType === "ManagedMetadata") {
                            var tempTermsArray_1 = [];
                            if (metadata.Values.length > 0 && metadata.Values.indexOf("{") > -1) {
                                var parsedValues = JSON.parse(metadata.Values);
                                if (Array.isArray(parsedValues)) {
                                    parsedValues.forEach(function (t) {
                                        tempTermsArray_1.push({
                                            NodeType: "Term",
                                            Id: t.TermGuid !== undefined ? t.TermGuid : t.TermID
                                        });
                                    });
                                }
                                else {
                                    tempTermsArray_1.push({
                                        NodeType: "Term",
                                        Id: parsedValues.TermGuid !== undefined ? parsedValues.TermGuid : parsedValues.TermID
                                    });
                                }
                            }
                            value = JSON.stringify(tempTermsArray_1);
                        }
                        else {
                            value = metadata.Values;
                        }
                    }
                }
            });
        }
        return value;
    };
    var UpdateToDirectory = function () {
        setUpdateItemLoading(true);
        if (directoryItem && reportItem) {
            DirectoryService.updateSiteMetadataWorkspace(directoryItem.Name, directoryItem.SiteCollectionUrl, reportItem.SiteId, editShowInDirectory === true ? "true" : "false", customMetadataValuesJson !== undefined ? customMetadataValuesJson : "", directoryItem.TemplateType === "Team" ? "Team" : "SharePoint").then(function (data) {
                dispatch(LifecyclePolicyWorkspaceStore.ClearDirectoryItem());
                dispatch(DirectoryStore.Refresh());
                setUpdateItemLoading(false);
                setshowMetadataModal(false);
                dispatch(LifecyclePolicyWorkspaceStore.LoadDirectoryItem(reportItem.SiteId));
            });
        }
    };
    /**
     * Returns true if the metadata link should be rendered for the given field name.
     * Renders the links only for the first field that has links because we ground the links under one field.
     *
     * @param fieldName The name of the field
     */
    var shouldRenderMetadataLink = function (fieldName) {
        if (directoryItem === null) {
            return false;
        }
        var links = directoryItem.Metadata.filter(function (md) { return md.Type === "Link" && !!md.Values; });
        if (!links || !(links.length > 0)) {
            return false;
        }
        /**
         * If the field name is not the same as the first link's field name, then we don't render the links
         * because we don't want to render the links for every field, only for the first one.
         */
        if (fieldName !== links[0].SpColumnsName) {
            return false;
        }
        return true;
    };
    /**
     * Renders all the external links in a group for the first field that has external links
     * @param fieldName  The name of the field
     * @param values The values of the field
     */
    var renderExternalLinks = function () {
        if (!(directoryItem === null || directoryItem === void 0 ? void 0 : directoryItem.Metadata)) {
            return React.createElement(React.Fragment, null);
        }
        var links = directoryItem.Metadata.filter(function (md) { return md.Type === "Link" && !!md.Values; });
        return (React.createElement("div", { className: "links-group" }, links.map(function (link, index) {
            return link.Values && (React.createElement("div", { className: "link" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLink }),
                React.createElement("a", { key: index, href: link.Values, target: "_blank", rel: "noreferrer" }, link.DisplayName)));
        })));
    };
    var getMetadataValue = function (item, fieldName) {
        var re = React.createElement(React.Fragment, null);
        var foundMetadata = item.Metadata.find(function (md) { return md.SpColumnsName === fieldName; });
        if (foundMetadata !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null) {
            if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-choice-single") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-choice-multiple") {
                var values = foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.split("|");
                re = (React.createElement(React.Fragment, null, values === null || values === void 0 ? void 0 : values.map(function (v, index) { return (React.createElement("div", { key: index }, v)); })));
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-date") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== "") {
                    re = React.createElement(React.Fragment, null, moment((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined ? foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.replace(/\"/g, "") : foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values).format("MMM Do YYYY"));
                }
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-link" && shouldRenderMetadataLink(fieldName)) {
                re = renderExternalLinks();
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-text-single") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-text-multiple") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-managed-metadata-single") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.length) > 0 && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.indexOf("{")) > -1) {
                    var tagObject = JSON.parse(foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                    if (tagObject !== null && tagObject.TermGuid !== undefined) {
                        re = (React.createElement(ManagedMetadataValue, { node: {
                                NodeType: "Term",
                                Id: tagObject.TermGuid
                            } }));
                    }
                }
            }
            else if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.RenderType) === "custom-managed-metadata-multiple") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.length) > 0 && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.indexOf("[")) > -1) {
                    var tagObjects = JSON.parse(foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                    if (Array.isArray(tagObjects) && tagObjects.length > 0) {
                        re = (React.createElement("ul", { className: "managed-metadata-list" }, tagObjects.map(function (to, index) { return (React.createElement("li", { key: index },
                            React.createElement("div", { className: "managed-metadata-item", "data-term-guid": to.TermID },
                                React.createElement("i", { className: "la la-tag" }),
                                React.createElement("span", null, to.Label)))); })));
                    }
                }
            }
        }
        return re;
    };
    var getWorkspaceLink = function () {
        switch (reportItem.Application) {
            case "Team":
                return reportItem.TeamUrl;
            case "CommunicationSite":
            case "TeamSite":
                return reportItem.SiteUrl;
            case "VivaEngageCommunity":
                return reportItem.VivaEngageCommunityUrl;
            default:
                return "";
        }
    };
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var hostClientType = teamContext === null || teamContext === void 0 ? void 0 : teamContext.app.host.clientType;
    var openWorkspaceLink = React.useCallback(function (event) {
        var url = getWorkspaceLink();
        TeamClientAppNavigation.goToTeamsLink(url, event, hostClientType);
    }, [hostClientType]);
    return (React.createElement(Portlet, { className: "lifecycle-workspace-details", "data-pw": "workspace-details" },
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "overview-content" },
                React.createElement("div", { className: "left" }, reportItem === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, bodyWidth: 40 })) : (React.createElement(React.Fragment, null,
                    React.createElement(OfficeProfilePicture, { size: "sm", type: reportItem.Application !== undefined && reportItem.Application !== null ? (reportItem.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: reportItem.Base64Logo })))),
                React.createElement("div", { className: "right" },
                    React.createElement("div", { className: "top-stats" },
                        React.createElement("div", { className: "ts-left" },
                            React.createElement("div", { className: "ws-title", "data-pw": "ws-title" }, reportItem === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 36, marginTop: 15 })) : (React.createElement(React.Fragment, null,
                                reportItem.Title,
                                React.createElement("a", { className: "link-span", onClick: function (event) { return openWorkspaceLink(event); }, href: getWorkspaceLink(), target: "_blank" },
                                    React.createElement("i", { className: "fas fa-external-link-alt", "data-pw": "ws-link" }))))),
                            React.createElement("div", { className: "ws-description", "data-pw": "ws-description" }, reportItem === null ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 21, marginTop: 15 }) : reportItem.Description))),
                    reportItem === null && teamInformation === null ? (React.createElement("div", { className: "bottom-stats" },
                        React.createElement("div", { className: "stat" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 42, marginTop: 15 })))) : (React.createElement("div", { className: "bottom-stats", "data-pw": "bottom-stats" },
                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteCreatedDate) !== undefined && (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteCreatedDate) !== null ? (React.createElement("div", { className: "stat" },
                            React.createElement("div", { className: "stat-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.created", defaultMessage: "Created" })),
                            React.createElement("div", { className: "stat-content" }, moment(reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteCreatedDate).format("LL")))) : null,
                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) !== null ? (React.createElement("div", { className: "stat" },
                            React.createElement("div", { className: "stat-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.created_with", defaultMessage: "Created With" })),
                            React.createElement("div", { className: "stat-content" }, (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) === "AzurePortal" ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "created-with-icon" },
                                    React.createElement("i", { className: getIconClassName("AzureLogo") })),
                                React.createElement("div", { className: "created-with-text" },
                                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.azure_ad", defaultMessage: "Azure AD Portal" })))) : (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) === "SharePoint" ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "created-with-icon" },
                                    React.createElement("i", { className: getIconClassName("SharepointLogo") })),
                                React.createElement("div", { className: "created-with-text" },
                                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.sharepoint", defaultMessage: "SharePoint" })))) : (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) === "ExchangeAdminCenter" ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "created-with-icon" },
                                    React.createElement("i", { className: getIconClassName("ExchangeLogo") })),
                                React.createElement("div", { className: "created-with-text" },
                                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.exchange_admin", defaultMessage: "Exchange Admin Center" })))) : (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) === "TeamsApp" ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "created-with-icon" },
                                    React.createElement("i", { className: getIconClassName("TeamsLogo") })),
                                React.createElement("div", { className: "created-with-text" },
                                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.microsoft_teams", defaultMessage: "Microsoft Teams" })))) : (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) === "Orchestry" ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "created-with-icon" },
                                    React.createElement("img", { src: "/favicon-16x16.png" })),
                                React.createElement("div", { className: "created-with-text" }, "Orchestry"))) : (reportItem === null || reportItem === void 0 ? void 0 : reportItem.CreationSource) === "VivaEngage" ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "created-with-icon" },
                                    React.createElement("img", { src: VivaLogo })),
                                React.createElement("div", { className: "created-with-text" }, "Viva Engage"))) : null))) : null,
                        history !== null && ((_d = (_c = (_b = history.CreationHistory) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.Action === "Create"; })[0]) === null || _c === void 0 ? void 0 : _c.User) === null || _d === void 0 ? void 0 : _d.DisplayName) ? (React.createElement("div", { className: "stat" },
                            React.createElement("div", { className: "stat-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.requested_by", defaultMessage: "Requested By" })),
                            React.createElement("div", { className: "stat-content profile-stat" }, history !== null ? (React.createElement(React.Fragment, null,
                                React.createElement(OfficeProfilePicture, { size: "xs", type: "User", uid: (_e = history.CreationHistory.filter(function (item) { return item.Action === "Create"; })[0]) === null || _e === void 0 ? void 0 : _e.User.ClaimsId }), (_f = history.CreationHistory.filter(function (item) { return item.Action === "Create"; })[0]) === null || _f === void 0 ? void 0 :
                                _f.User.DisplayName)) : (React.createElement(React.Fragment, null,
                                React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 80, bodyHeight: 25, marginTop: 3 })))))) : null,
                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateType) != null && (React.createElement("div", { className: "stat" },
                            React.createElement("div", { className: "stat-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.workspace_type", defaultMessage: "Workspace Type" })),
                            React.createElement("div", { className: "stat-content" },
                                React.createElement("div", { className: "workspace-type-icon" },
                                    React.createElement("img", { src: templateTypeMap[reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateType].icon })),
                                React.createElement("div", { className: "orch-template-title", "data-pw": "template-type" },
                                    React.createElement(FormattedMessage, { id: templateTypeMap[reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateType].token, defaultMessage: templateTypeMap[reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateType].label }))))),
                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateName) !== null && (React.createElement("div", { className: "stat" },
                            React.createElement("div", { className: "stat-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.template_name", defaultMessage: "Template" })),
                            React.createElement("div", { className: "stat-content" },
                                React.createElement("div", { className: "orch-template-title" }, reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateName)))),
                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSiteStorageUsed) !== null && (React.createElement("div", { className: "stat" },
                            React.createElement("div", { className: "stat-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.storage_used", defaultMessage: "Storage Used" })),
                            React.createElement("div", { className: "stat-content" },
                                React.createElement("div", { className: "orch-template-title" }, formatDataSize(reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSiteStorageUsed))))))),
                    isInMinimalMode() === false ? (React.createElement(React.Fragment, null,
                        reportItem === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 29, marginTop: 15 })) : (React.createElement("div", { className: "bottom-sharing-settings", "data-pw": "sharing-settings" },
                            React.createElement("div", { className: "sharing-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.sharing_settings", defaultMessage: "Sharing Settings" })),
                            React.createElement("div", { className: "sharing-content" },
                                (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Privacy) !== null && (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Privacy) !== "Custom" && (React.createElement("div", { className: "sharing-item" },
                                    React.createElement("div", { className: "sharing-item-name" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.permission_type", defaultMessage: "Permission Type" })),
                                    React.createElement("div", { className: "sharing-item-value" },
                                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Privacy) === "Public" && (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: getIconClassName("UnlockSolid") }),
                                            React.createElement("span", null,
                                                React.createElement(FormattedMessage, { id: "page.archival_approval.permission_type_value.public", defaultMessage: "Public" })))),
                                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Privacy) === "Private" && (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: getIconClassName("LockSolid") }),
                                            React.createElement("span", null,
                                                React.createElement(FormattedMessage, { id: "page.archival_approval.permission_type_value.private", defaultMessage: "Private" }))))))),
                                (reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupSettingsGuestAccessEnabled) !== null && (React.createElement("div", { className: "sharing-item" },
                                    React.createElement("div", { className: "sharing-item-name" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.guest_access", defaultMessage: "Guest Access" })),
                                    React.createElement("div", { className: "sharing-item-value" },
                                        React.createElement("div", { className: "sharing-checkbox" }, isGuestAccessEnabled || (reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupSettingsGuestAccessEnabled) === true ? React.createElement("i", { className: "fa fa-check" }) : React.createElement("i", { className: "fa fa-times" })),
                                        React.createElement("span", null, (reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupSettingsGuestAccessEnabled) === true ? (React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.enabled", defaultMessage: "Enabled" })) : isGuestAccessEnabled === true ? (React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.enabled_via_orchestry", defaultMessage: "Enabled via Orchestry" })) : (React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.disabled", defaultMessage: "Disabled" })))))),
                                (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSettingsSharingCapability) !== null && (React.createElement("div", { className: "sharing-item" },
                                    React.createElement("div", { className: "sharing-item-name" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.sharepoint_sharing", defaultMessage: "SharePoint Sharing" })),
                                    React.createElement("div", { className: "sharing-item-value" },
                                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSettingsSharingCapability) === "Disabled" && (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: getIconClassName("RecruitmentManagement") }),
                                            React.createElement("span", null,
                                                React.createElement(FormattedMessage, { id: "page.archival_approval.sharepoint_sharing_value.only_people_in_your_org", defaultMessage: "Only people in your organization" })))),
                                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSettingsSharingCapability) === "ExternalUserSharingOnly" && (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: getIconClassName("AddGroup") }),
                                            React.createElement("span", null,
                                                React.createElement(FormattedMessage, { id: "page.archival_approval.sharepoint_sharing_value.new_and_existing", defaultMessage: "New and Existing Guests" })))),
                                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSettingsSharingCapability) === "ExternalUserAndGuestSharing" && (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: getIconClassName("Globe") }),
                                            React.createElement("span", null,
                                                React.createElement(FormattedMessage, { id: "page.archival_approval.sharepoint_sharing_value.anyone", defaultMessage: "Anyone" })))),
                                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointSettingsSharingCapability) === "ExistingExternalUserSharingOnly" && (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: getIconClassName("SecurityGroup") }),
                                            React.createElement("span", null,
                                                React.createElement(FormattedMessage, { id: "page.archival_approval.sharepoint_sharing_value.existing_guests", defaultMessage: "Existing Guests" })))))))))),
                        reportItem === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 29, marginTop: 15 })) : (React.createElement("div", { className: "metadata-content", "data-pw": "metadata-content" },
                            React.createElement("div", { className: "metadata-title" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.metadata", defaultMessage: "Metadata" }),
                                (policyWorkspaceMembers === null || policyWorkspaceMembers === void 0 ? void 0 : policyWorkspaceMembers.IsOwner) ? (React.createElement("div", { className: "metadata-item-edit" },
                                    React.createElement("i", { className: "far fa-edit", onClick: function () { return setshowMetadataModal(true); } }))) : null),
                            React.createElement("div", { className: "metadata-container" }, directoryItem && directoryItem.Metadata && ((_g = directoryItem === null || directoryItem === void 0 ? void 0 : directoryItem.Metadata) === null || _g === void 0 ? void 0 : _g.length) > 0
                                ? (_h = directoryItem === null || directoryItem === void 0 ? void 0 : directoryItem.Metadata) === null || _h === void 0 ? void 0 : _h.map(function (metadataItem, index) {
                                    if ((metadataItem === null || metadataItem === void 0 ? void 0 : metadataItem.Type) === "Link" && !shouldRenderMetadataLink(metadataItem.SpColumnsName))
                                        return null;
                                    return (React.createElement("div", { className: "metadata-item", key: index },
                                        (metadataItem === null || metadataItem === void 0 ? void 0 : metadataItem.Type) === "Link" ? (React.createElement("span", null, intl.formatMessage({ id: "workspaces.metadata.external_links", defaultMessage: "External Links" }))) : (React.createElement("div", { className: "metadata-item-title" }, metadataItem.DisplayName)),
                                        React.createElement("div", { className: "metadata-item-value" }, getMetadataValue(directoryItem, metadataItem.SpColumnsName))));
                                })
                                : null),
                            directoryItem && directoryItem.Metadata ? null : (React.createElement("div", { className: "metadata-loader" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 12, marginTop: 15 }))))),
                        showMetadataModal === true ? (React.createElement(Modal, { centered: true, show: showMetadataModal, onHide: function () { return setshowMetadataModal(!showMetadataModal); }, animation: true, className: "directory-edit-item-modal directory-modal", backdropClassName: "directory-edit-item-backdrop directory-modal-backdrop" },
                            React.createElement(Modal.Header, { closeButton: true },
                                React.createElement(Modal.Title, null, "Update directory item metadata")),
                            React.createElement(Modal.Body, null,
                                React.createElement("div", { className: "content" },
                                    React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                                            setIsEditValid(isValid);
                                        }, UniqueIdentifier: "UpdateDirectoryItemMetadata" },
                                        React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 500 }, listMetadata.map(function (mdi, index) { return (
                                        // <>{mdi.ShowInDetailsForm === true ?
                                        React.createElement("div", { key: index },
                                            React.createElement(MetadataField, { key: index, metaDataItem: mdi, onChange: function (mdfv) {
                                                    updateCustomMetadataValue(mdfv);
                                                }, defaultValue: getCurrentMetadataFieldValue(mdi) }, mdi.RequiredInDetailsForm !== undefined && mdi.RequiredInDetailsForm === true ? (React.createElement("div", null,
                                                mdi.FieldType === "Date" || mdi.FieldType === "Text" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-not-empty"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                                mdi.FieldType === "Choice" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                                mdi.FieldType === "ManagedMetadata" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null)) : null))
                                        // :null}</>
                                        ); }))))),
                            React.createElement(Modal.Footer, null, updateItemLoading === false ? (React.createElement("div", { className: "buttons" },
                                React.createElement("div", { className: "cancel", onClick: function () { return setshowMetadataModal(!showMetadataModal); } }, "Cancel"),
                                isEditValid === true ? (React.createElement("div", { className: "save", onClick: function () { return UpdateToDirectory(); } },
                                    React.createElement("i", { className: "far fa-edit" }),
                                    " Update Item in Directory")) : (React.createElement("div", { className: "save no-valid" },
                                    React.createElement("i", { className: "far fa-edit" }),
                                    " Update Item in Directory")))) : (React.createElement("div", { className: "loading" },
                                React.createElement(AnimatedLoader, { size: "small" })))))) : null)) : null,
                    reportItem === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 29, marginTop: 15 })) : (React.createElement("div", { className: "tags" },
                        props.showDaysInactive !== undefined && props.showDaysInactive === true
                            ? reportItem !== null && (React.createElement("div", { className: "tag inactive" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.days_inactive", defaultMessage: "Days Inactive" }),
                                ": ",
                                React.createElement("strong", null, reportItem === null || reportItem === void 0 ? void 0 : reportItem.DaysInactive)))
                            : null,
                        (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SensitivityLabel) !== null ? (React.createElement("div", { className: "tag" },
                            React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.sensitivity_label", defaultMessage: "Sensitivity Label" }),
                            ": ",
                            React.createElement("strong", null, reportItem === null || reportItem === void 0 ? void 0 : reportItem.SensitivityLabel))) : ((reportItem === null || reportItem === void 0 ? void 0 : reportItem.Classification) !== null && (React.createElement("div", { className: "tag" },
                            React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.classification", defaultMessage: "Classification" }),
                            ": ",
                            React.createElement("strong", null, reportItem === null || reportItem === void 0 ? void 0 : reportItem.Classification)))))))))));
});
export default LifecycleWorkspaceDetails;
