/**
 * Recommendation Priority
 */
export var RecommendationPriority;
(function (RecommendationPriority) {
    /**
     * This is Urgent
     */
    RecommendationPriority["Urgent"] = "Urgent";
    /**
     * This is Important
     */
    RecommendationPriority["Important"] = "Important";
    /**
     * This is a Best Practice
     */
    RecommendationPriority["BestPractice"] = "BestPractice";
})(RecommendationPriority || (RecommendationPriority = {}));
