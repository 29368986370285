import { Group, Stack, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import withIntlProvider from "appv2/components/orchestry-with-intl-provider-hoc/orchestry-with-intl-provider-hoc.component";
import React from "react";
import { useDeleteAnnotationModal } from "./use-delete-annotation-modal.hook";
var DeleteAnnotationModalContent = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, uniqueId = _b.uniqueId, body = _b.body, annotationCategory = _b.annotationCategory, tenantGuid = _b.tenantGuid, associatedGUID = _b.associatedGUID, healthCheckGuid = _b.healthCheckGuid;
    var _c = useDeleteAnnotationModal(tenantGuid, annotationCategory, body, associatedGUID, healthCheckGuid), intl = _c.intl, isSuccess = _c.isSuccess, isLoading = _c.isLoading, onDeleteClicked = _c.onDeleteClicked, bodyShortened = _c.bodyShortened;
    React.useEffect(function () {
        if (isSuccess) {
            context.closeModal(id);
            OrchestrySuccessToast(intl.formatMessage({ id: "page.health_checks.toast.annotation_deleted", defaultMessage: "Annotation \"{body}\" Successfully Deleted" }, { body: bodyShortened }));
        }
    }, [isSuccess, context, id, intl, bodyShortened]);
    return (React.createElement(Stack, { gap: 24, ff: "var(--mantine-font-family)" },
        React.createElement(Group, { gap: 16 },
            React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", size: "lg", colorVariant: "error-7", iconClassName: ORCH_ICONS.trash01 }),
            React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check_delete_modal.delete_annotation", defaultMessage: "Delete Annotation" }))),
        React.createElement(Text, { c: "text-secondary.0", fz: "14px", fw: 400, lh: "20px" }, intl.formatMessage({ id: "page.health_check.annotation_delete_modal.are_you_sure", defaultMessage: 'Are you sure you want to delete this annotation: "{body}"? This action cannot be undone.' }, { body: bodyShortened })),
        React.createElement(Group, { gap: 12, justify: "right" },
            React.createElement(OrchestryButton, { disabled: isLoading, variant: "white", size: "sm", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "general.label.cancel", defaultMessage: "Cancel" })),
            React.createElement(OrchestryButton, { disabled: isLoading, variant: "danger", size: "sm", onClick: function () { return onDeleteClicked(uniqueId); } }, intl.formatMessage({ id: "controls.delete", defaultMessage: "Delete" })))));
};
export var DeleteAnnotationModal = withIntlProvider(DeleteAnnotationModalContent);
