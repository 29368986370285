export var UserListMode;
(function (UserListMode) {
    UserListMode[UserListMode["Owners"] = 0] = "Owners";
    UserListMode[UserListMode["Members"] = 1] = "Members";
    UserListMode[UserListMode["Guests"] = 2] = "Guests";
})(UserListMode || (UserListMode = {}));
export var UsersListActionTypes;
(function (UsersListActionTypes) {
    UsersListActionTypes["Users_List_Load"] = "Users_List_Load";
    UsersListActionTypes["Users_List_Refresh"] = "Users_List_Refresh";
    UsersListActionTypes["Users_List_Page"] = "Users_List_Page";
    UsersListActionTypes["Users_List_Clear"] = "Users_List_Clear";
    UsersListActionTypes["Users_List_IsLoading"] = "Users_List_IsLoading";
})(UsersListActionTypes || (UsersListActionTypes = {}));
