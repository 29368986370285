import * as React from "react";
import "./form-delete-confirmation.scss";
import { Modal, Button } from "react-bootstrap";
import DeleteCheckService from "app/services/delete-check";
import AnimatedLoader from "../animated-loader";
import { NavLink } from "react-router-dom";
var FormDeleteConfirmation = React.memo(function (props) {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(false), showLoader = _b[0], setShowLoader = _b[1];
    var _c = React.useState(), deleteCheckResponse = _c[0], setDeleteCheckResponse = _c[1];
    var _d = React.useState("You are about to delete this item. Would you like to proceed?"), deleteItemMessage = _d[0], setDeleteItemMessage = _d[1];
    var _e = React.useState("Deletion"), actionVerbFutureTense = _e[0], setActionVerbFutureTense = _e[1];
    var _f = React.useState("delete"), actionVerb = _f[0], setActionVerb = _f[1];
    var toggleModal = function () {
        var newValue = !showModal;
        setShowModal(newValue);
        //is now closed
        if (newValue === true) {
            console.log("props.DeleteCheckType", props.DeleteCheckType);
            if (props.DeleteCheckType === "tenants") {
                SetDeleteMessage();
            }
            else if (props.DeleteCheckType !== undefined && props.DeleteCheckItemGuid !== undefined) {
                SetDeleteMessage();
                setShowLoader(true);
                DeleteCheckService.GetCheckResponse(props.DeleteCheckItemGuid, props.DeleteCheckType).then(function (checkResponse) {
                    console.log("delete check response", checkResponse);
                    setDeleteCheckResponse(checkResponse);
                    setShowLoader(false);
                });
            }
        }
    };
    var SetDeleteMessage = function () {
        var newMessage = "";
        switch (props.DeleteCheckType) {
            case "document-template":
                newMessage = "You are about to delete <strong>{ItemName}</strong>. Would you like to proceed?";
                break;
            case "lifecycle-archival-policy":
                newMessage = "You are about to delete <strong>{ItemName}</strong>. Would you like to proceed?";
                break;
            case "tenants":
                newMessage = "You are about to archive this tenant. Would you like to proceed?";
                setActionVerbFutureTense("Archival");
                setActionVerb("archive");
                // code block
                break;
            default:
            // code block
        }
        if (newMessage !== "") {
            if (props.ItemName !== undefined && props.ItemName !== null && props.ItemName !== "") {
                newMessage = newMessage.replace("{ItemName}", props.ItemName);
            }
            setDeleteItemMessage(newMessage);
        }
    };
    var OnProceed = function () {
        props.OnProceed();
        toggleModal();
    };
    var OnCancel = function () {
        if (props.OnCancel !== undefined) {
            props.OnCancel();
        }
        toggleModal();
    };
    var HadDeleteCheckError = function () {
        var isErrorExists = false;
        if (deleteCheckResponse !== undefined) {
            if (deleteCheckResponse.Groups !== undefined) {
                deleteCheckResponse.Groups.forEach(function (group) {
                    if (group.Priority === "Error") {
                        isErrorExists = true;
                    }
                });
            }
        }
        return isErrorExists;
    };
    return (React.createElement("span", null,
        props.children === undefined || props.children === null ? (React.createElement("div", { className: "button-custom b-orange", onClick: function () { return toggleModal(); } },
            React.createElement("div", null, "Delete Something"))) : (React.createElement("span", { onClick: function () { return toggleModal(); } }, props.children)),
        React.createElement(Modal, { size: "sm", centered: true, show: showModal, onHide: function () { return toggleModal(); }, animation: true, className: "delete-confirm-modal" }, showLoader === true ? (React.createElement("div", { className: "loading-wrapper" },
            React.createElement(AnimatedLoader, { size: "small" }))) : (React.createElement(React.Fragment, null, deleteCheckResponse !== undefined ? (React.createElement(React.Fragment, null,
            React.createElement(Modal.Header, { closeButton: true }, HadDeleteCheckError() ? React.createElement(Modal.Title, null,
                "You cannot ",
                actionVerb,
                " this item right now") : React.createElement(Modal.Title, null,
                "Confirm ",
                actionVerbFutureTense)),
            React.createElement(Modal.Body, null, deleteCheckResponse !== undefined && deleteCheckResponse.Groups !== undefined && deleteCheckResponse.Groups.length > 0 ? (deleteCheckResponse.Groups.map(function (group, groupIndex) { return (React.createElement("div", { className: "group-reponse", key: groupIndex },
                React.createElement("div", { className: "group-title title-priority-" + group.Priority }, group.Title),
                React.createElement("div", { className: "group-message" }, group.Message),
                group.Items !== undefined && group.Items.length > 0 ? (React.createElement("ul", { className: "group-items list-unstyled" }, group.Items.map(function (item, itemIndex) { return (React.createElement("li", { className: "item", key: itemIndex },
                    React.createElement("i", { className: "fa fa-caret-right" }),
                    item.DetailsUrl !== undefined && item.DetailsUrl !== null && item.DetailsUrl !== "" ? (React.createElement(NavLink, { exact: true, to: item.DetailsUrl }, item.Title)) : (React.createElement("div", { className: "link" }, item.Title)))); }))) : null)); })) : (React.createElement("p", { dangerouslySetInnerHTML: { __html: deleteItemMessage } }))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", "data-pw": "secondary-button", onClick: function () { return OnCancel(); } }, "Cancel"),
                HadDeleteCheckError() === false ? (React.createElement(Button, { variant: "primary", "data-pw": "primary-button", onClick: function () { return OnProceed(); } },
                    "Proceed with ",
                    actionVerb)) : null))) : (React.createElement(React.Fragment, null,
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Are you sure?")),
            React.createElement(Modal.Body, null,
                React.createElement("p", { dangerouslySetInnerHTML: { __html: deleteItemMessage } })),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: function () { return OnCancel(); } }, "Cancel"),
                React.createElement(Button, { variant: "primary", onClick: function () { return OnProceed(); } },
                    "Proceed with ",
                    actionVerb)))))))));
});
export default FormDeleteConfirmation;
