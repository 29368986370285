import * as React from "react";
import { Form } from "react-bootstrap";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
export var GuestPolicyType;
(function (GuestPolicyType) {
    GuestPolicyType[GuestPolicyType["Request"] = 0] = "Request";
    GuestPolicyType[GuestPolicyType["Review"] = 1] = "Review";
})(GuestPolicyType || (GuestPolicyType = {}));
var ReportFilterGuestPolicies = React.memo(function (props) {
    var placeholderText = "-- Select a Policy --";
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.PolicyType === GuestPolicyType.Request ? "Guest Request Policy" : "Guest Review Policy"),
        props.FilterPoliciesLoading === null || props.FilterPoliciesLoading === undefined || props.FilterPoliciesLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (props.FilterPolicies !== undefined && (React.createElement(FormDropdownMultiSelect, { list: props.FilterPolicies.map(function (fc, index) {
                return ({
                    id: fc.GUID,
                    title: fc.Name,
                    value: fc.GUID
                });
            }), placeholder: placeholderText, values: props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue.length > 0
                ? props.Refiner.CurrentValue.split("|")
                    //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID == cv))
                    .map(function (cv, index) {
                    var _a, _b;
                    return ({
                        id: cv,
                        title: (_b = (_a = props.FilterPolicies) === null || _a === void 0 ? void 0 : _a.find(function (fc) { return fc.GUID === cv; })) === null || _b === void 0 ? void 0 : _b.Name,
                        value: cv
                    });
                })
                : [], onChange: function (values) { return props.FilterOnChange(props.Refiner, values.map(function (v) { return v.value; }).join("|")); } })))));
});
export default ReportFilterGuestPolicies;
