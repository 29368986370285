var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./card-nightly-workspace-data-export.scss";
import { useSelector, useDispatch } from "react-redux";
import FormToggleControl from "app/components/common/form-toggle-control";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import { PortletBody, Portlet, PortletHeader } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import LifecycleService from "app/services/lifecycle";
import { SubtitleButton } from "app/components/common/subtitle";
var CardNightlyWorkspaceDataExport = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState(false), tenantConfigLoaded = _a[0], setTenantConfigLoaded = _a[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _b = React.useState(), crawlHistoryItem = _b[0], setCrawlHistoryItem = _b[1];
    React.useEffect(function () {
        LifecycleService.GetLatestCompleteETLLog().then(function (crawlHistoryItem) {
            setCrawlHistoryItem(crawlHistoryItem);
        });
    }, []);
    // On first load, Load the data from the server.
    React.useEffect(function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(function () {
            //console.log("tenant config loaded");
            setTenantConfigLoaded(true);
        });
    }, []);
    return (React.createElement("div", { className: "card-nightly-workspace-data-export", "data-pw": "nightly-workspace-data-export" },
        React.createElement(Portlet, null,
            React.createElement(PortletHeader, { title: "Nightly Workspace Data Export", toolbar: React.createElement("a", { href: orchestryContext.TenantSharePointUrl + "/sites/orchestry/Storage/reports", target: "_blank" },
                    React.createElement(SubtitleButton, { title: "View File Storage In Orchestry Site", type: "button", size: "sm", variant: "info" })) }),
            React.createElement(PortletBody, null, crawlHistoryItem === undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40 })) : crawlHistoryItem === null || (crawlHistoryItem !== null && crawlHistoryItem.WorkspaceCount === null) ? (React.createElement(React.Fragment, null, "Please wait until the current crawl has completed to configure export.")) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "description", "data-pw": "description" }, "If checked Orchestry will export all compiled data about all workspaces to a CSV and save/update it every night to the Orchestry site within your tenant."),
                React.createElement(FormToggleControl, { checked: currentTenantConfiguration.LifecycleExportNightlyDataToSharePoint !== undefined ? currentTenantConfiguration.LifecycleExportNightlyDataToSharePoint : false, label: "Export nightly CSV of all workspace data?", handleChange: function (checked) {
                        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), { LifecycleExportNightlyDataToSharePoint: checked })));
                    } })))))));
});
export default CardNightlyWorkspaceDataExport;
