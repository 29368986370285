var _a, _b;
//guest membership types
export var GuestMembershipStatus;
(function (GuestMembershipStatus) {
    GuestMembershipStatus[GuestMembershipStatus["AccessEnabled"] = 0] = "AccessEnabled";
    GuestMembershipStatus[GuestMembershipStatus["PendingApproval"] = 1] = "PendingApproval";
    GuestMembershipStatus[GuestMembershipStatus["PendingReview"] = 2] = "PendingReview";
    GuestMembershipStatus[GuestMembershipStatus["RejectedApproval"] = 3] = "RejectedApproval";
    GuestMembershipStatus[GuestMembershipStatus["RejectedReview"] = 4] = "RejectedReview";
    GuestMembershipStatus[GuestMembershipStatus["Deleted"] = 5] = "Deleted";
})(GuestMembershipStatus || (GuestMembershipStatus = {}));
export var GuestManagementAccessViolations;
(function (GuestManagementAccessViolations) {
    GuestManagementAccessViolations[GuestManagementAccessViolations["GuestsNotAllowed"] = 0] = "GuestsNotAllowed";
    GuestManagementAccessViolations[GuestManagementAccessViolations["BlockedDomain"] = 1] = "BlockedDomain";
})(GuestManagementAccessViolations || (GuestManagementAccessViolations = {}));
export var AccessViolationMap = (_a = {},
    _a[GuestManagementAccessViolations.GuestsNotAllowed] = "Guest Not Allowed",
    _a[GuestManagementAccessViolations.BlockedDomain] = "Blocked Domain",
    _a);
export var GuestMembershipStatusMap = (_b = {},
    _b[GuestMembershipStatus.AccessEnabled] = "general.membership.status.access_enabled",
    _b[GuestMembershipStatus.PendingApproval] = "general.membership.status.pending_approval",
    _b[GuestMembershipStatus.PendingReview] = "general.membership.status.pending_review",
    _b[GuestMembershipStatus.RejectedApproval] = "general.membership.status.rejected_approval",
    _b[GuestMembershipStatus.RejectedReview] = "general.membership.status.rejected_review",
    _b[GuestMembershipStatus.Deleted] = "general.membership.status.deleted",
    _b);
export var ReportAggregateType;
(function (ReportAggregateType) {
    ReportAggregateType[ReportAggregateType["DISTINCT"] = 0] = "DISTINCT";
    ReportAggregateType[ReportAggregateType["COUNT"] = 1] = "COUNT";
    ReportAggregateType[ReportAggregateType["SUM"] = 2] = "SUM";
    ReportAggregateType[ReportAggregateType["AVG"] = 3] = "AVG";
    ReportAggregateType[ReportAggregateType["MAX"] = 4] = "MAX";
    ReportAggregateType[ReportAggregateType["MIN"] = 5] = "MIN";
})(ReportAggregateType || (ReportAggregateType = {}));
export var ReportDatasource;
(function (ReportDatasource) {
    ReportDatasource[ReportDatasource["CosmosDB"] = 0] = "CosmosDB";
    ReportDatasource[ReportDatasource["SQLServer"] = 1] = "SQLServer";
    ReportDatasource[ReportDatasource["Domain"] = 2] = "Domain";
    ReportDatasource[ReportDatasource["Company"] = 3] = "Company";
    ReportDatasource[ReportDatasource["GuestUser"] = 4] = "GuestUser";
    ReportDatasource[ReportDatasource["ExternalLink"] = 5] = "ExternalLink";
})(ReportDatasource || (ReportDatasource = {}));
