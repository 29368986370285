export var BulkEditActionTypes;
(function (BulkEditActionTypes) {
    BulkEditActionTypes["BulkEdit_Load"] = "BulkEdit_Load";
    BulkEditActionTypes["BulkEdit_UpdateCart"] = "BulkEdit_UpdateCart";
})(BulkEditActionTypes || (BulkEditActionTypes = {}));
// Directory_Search_Action_Clear |
// Directory_Search_Action_Refresh |
// Directory_Search_Action_Load_Refiners |
// Directory_Search_Action_Load_Favs |
// Directory_Search_Action_Load_RefinersAndFavs |
// Directory_Search_Action_Search |
// Directory_Search_Action_Update_Paging |
// Directory_Search_Action_Update_Context |
// Directory_Search_Action_Update_Refiners |
// Directory_Search_Action_Update_View |
// Directory_Search_Action_Update_Favs |
// Directory_Search_Action_Update_FavsOnServer |
// Directory_Search_Action_Update_IsLoading
