import * as AsyncStore from "app/store/async";
import * as RequestListStore from "app/store/requests/list";
export var requestNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            //      {
            //       Text: "Submitting Workspace request...",
            //       ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Add,
            //   },
            {
                Text: "Workspace request updating...",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Update
            },
            {
                Text: "Deleting workspace request from requests list...",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            //      {
            //       Text: "Workspace request submitted",
            //       ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Add,
            //   },
            {
                Text: "Workspace request successfully saved",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Update
            },
            {
                Text: "Workspace request successfully deleted from requests list.",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when submitting workspace request",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Add
            },
            {
                Text: "Error when updating workspace request in requests list.",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Update
            },
            {
                Text: "Error when deleting workspace request from requests list.",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Item_Delete
            },
            {
                Text: "Error when trying to load workspace request list.",
                ActionType: RequestListStore.RequestListActionTypes.Request_List_Load
            }
        ]
    }
];
