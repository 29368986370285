var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as microsoftTeams from "@microsoft/teams-js";
import LocalSessionService from "app/services/local-session";
import TeamsDetectionService from "app/services/teams-detection";
import * as AuthStore from "app/store/auth";
import axios from "axios";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService() {
    }
    //https://github.com/axios/axios#interceptors
    AuthenticationService.setupAxiosAuthInterceptors = function (axios, store) {
        axios.interceptors.request.use(function (request) {
            return Promise.resolve(request);
            // return new Promise((resolve, reject) => {
            //    if (Cookies.get("orchestry-token-expiration") != null) {
            //       let expirationDateMinus30Minutes = moment.utc(Cookies.get("orchestry-token-expiration") as string).subtract(30, "minute").toDate();
            //       let nowDate = moment().utc().toDate();
            //       if (nowDate > expirationDateMinus30Minutes) {
            //          console.log("Expired Token", expirationDateMinus30Minutes, " <> ", nowDate);
            //          if (TeamsDetectionService.IsInTeamsApp()) {
            //             console.log("not authenticated > In Teams App!");
            //             AuthenticationService.loginTeamsUserIntoOrchestry().then((data) => {
            //                resolve(request);
            //             }).catch((error) => {
            //                reject();
            //             })
            //          } else {
            //             resolve(request)
            //          }
            //       } else {
            //          resolve(request)
            //       }
            //    } else {
            //       resolve(request)
            //    }
            // });
        }, function (config) {
            //console.log("Axios Request");
            //console.log("Interceptor", store.getState());
            //const { auth: { authToken } } = store.getState();
            //if (authToken) {
            //config.headers.Authorization = `Bearer ${authToken}`;
            //}
            return config;
        }, function (err) {
            return Promise.reject(err);
        });
        axios.interceptors.response.use(function (response) { return response; }, function (error) {
            //console.log("Axios Error", error);
            if (error.response) {
                if (error.response.status === 403) {
                    if (TeamsDetectionService.IsInTeamsApp()) {
                        console.log("not authenticated > In Teams App!");
                        AuthenticationService.loginTeamsUserIntoOrchestry()
                            .then(function (data) {
                            console.log("Logged into teams again!", data);
                        })
                            .catch(function (error) { });
                    }
                    else {
                        console.log("not authenticated > Not in teams app");
                        store.dispatch(AuthStore.Logout());
                    }
                }
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log(error.response.data);
                console.log(error.response.status);
                //console.log(error.response.headers);
            }
            else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            }
            else {
                // Something happened in setting up the request that triggered an Error
                //console.log('Error', error.message);
            }
            //console.log(error.config);
            return Promise.reject(error);
        });
    };
    var _a;
    _a = AuthenticationService;
    AuthenticationService.getToken = function () {
        return new Promise(function (resolve, reject) {
            // If the token is cached, then use that.
            // If the current datetime is greater than now, then query the API for a new one.
            var shouldRunAjax = true;
            if (LocalSessionService.KeyExists("at")) {
                var accessToken = LocalSessionService.GetObject("at");
                if (new Date() < new Date(accessToken.ExpiresOn)) {
                    shouldRunAjax = false;
                    resolve(accessToken.AccessToken);
                }
            }
            if (shouldRunAjax) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/Auth/GetAccessToken";
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    console.log("Got Access Token", new Date(response.data.ExpiresOn));
                    //LocalSessionService.SetDate("accessTokenExpires", new Date(response.data.ExpiresOn));
                    LocalSessionService.SetObject("at", response.data);
                    resolve(response.data.AccessToken);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
        });
    };
    AuthenticationService.getAccessTokenWithInfo = function () {
        console.log();
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Auth/GetAccessToken";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Got Access Token" , new Date(response.data.ExpiresOn));
                //LocalSessionService.SetDate("accessTokenExpires", new Date(response.data.ExpiresOn));
                //LocalSessionService.SetObject("at", response.data);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    AuthenticationService.isAccessTokenValid = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Auth/IsAccessTokenValid";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data.isTokenValid);
            })
                .catch(function (response) {
                resolve(false);
            });
        });
    };
    AuthenticationService.loginTeamsUserIntoOrchestry = function () {
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            function parseJwt(token) {
                try {
                    return JSON.parse(atob(token.split(".")[1]));
                }
                catch (e) {
                    return null;
                }
            }
            var authTokenRequest, e_1;
            return __generator(_a, function (_b) {
                switch (_b.label) {
                    case 0:
                        authTokenRequest = {
                            successCallback: function (result) {
                                var user = parseJwt(result);
                                var formData = new FormData();
                                formData.append("jwt_bearer_token", result);
                                var axiosConfig = {};
                                axiosConfig.method = "POST";
                                axiosConfig.url = "/api/Auth/LoginTeamsOrSharePointUser";
                                axiosConfig.responseType = "json";
                                axiosConfig.data = formData;
                                axios(axiosConfig)
                                    .then(function (response) {
                                    resolve(response.data);
                                    console.log("Teams Auth Response", response.data);
                                })
                                    .catch(function (response) {
                                    reject(response);
                                });
                            },
                            failureCallback: function (error) {
                                console.log(error);
                            }
                        };
                        if (!(document.SharePointToken !== undefined && document.SharePointToken !== null && document.SharePointToken.length > 1)) return [3 /*break*/, 1];
                        authTokenRequest.successCallback(document.SharePointToken);
                        return [3 /*break*/, 4];
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, microsoftTeams.app.initialize()];
                    case 2:
                        _b.sent();
                        microsoftTeams.authentication.getAuthToken(authTokenRequest);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        reject(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    AuthenticationService.signOut = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/Account/EndSession";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    AuthenticationService.getConsentStatus = function (serviceAppConfig) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Auth/AppConsentStatus" + (serviceAppConfig ? "?serviceAppConfig=" + serviceAppConfig : "");
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    AuthenticationService.installUpdateServiceApp = function (serviceAppConfig) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Auth/InstallUpdateServiceApp" + (serviceAppConfig ? "?serviceAppConfig=" + serviceAppConfig : "");
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    AuthenticationService.isServiceAppPermissionsWorking = function (serviceAppConfig) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Auth/IsServiceAppPermissionsReady" + (serviceAppConfig ? "?serviceAppConfig=" + serviceAppConfig : "");
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return AuthenticationService;
}());
export default AuthenticationService;
var GraphAuthenticationProvider = /** @class */ (function () {
    function GraphAuthenticationProvider() {
    }
    /**
     * This method will get called before every request to the msgraph server
     * This should return a Promise that resolves to an accessToken (in case of success) or rejects with error (in case of failure)
     * Basically this method will contain the implementation for getting and refreshing accessTokens
     */
    GraphAuthenticationProvider.prototype.getAccessToken = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, AuthenticationService.getToken()];
            });
        });
    };
    return GraphAuthenticationProvider;
}());
export { GraphAuthenticationProvider };
