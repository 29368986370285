/**
 * Annotation Category
 */
export var HealthCheckAnnotationCategory;
(function (HealthCheckAnnotationCategory) {
    /**
     * General Annotation (Not Associated with a specific category)
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["General"] = 0] = "General";
    /**
     * Orchestry Usage
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["OrchestryUsage"] = 1] = "OrchestryUsage";
    /**
     * Workspaces
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["Workspaces"] = 2] = "Workspaces";
    /**
     * Benchmarks
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["Benchmarks"] = 3] = "Benchmarks";
    /**
     * Sharing Links Created
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["SharingLinksCreated"] = 4] = "SharingLinksCreated";
    /**
     * Storage Analysis
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["StorageAnalysis"] = 5] = "StorageAnalysis";
    /**
     * Teams Engagement This Month
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["TeamsEngagementThisMonth"] = 6] = "TeamsEngagementThisMonth";
    /**
     * Leaderboard
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["Leaderboard"] = 7] = "Leaderboard";
    /**
     * Recommendations
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["Recommendations"] = 8] = "Recommendations";
    /**
     * Time Savings
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["TimeSavings"] = 9] = "TimeSavings";
    /**
     * Message Center Message
     * Requires AssociatedGUID to be set
     */
    HealthCheckAnnotationCategory[HealthCheckAnnotationCategory["MessageCenterMessage"] = 10] = "MessageCenterMessage";
})(HealthCheckAnnotationCategory || (HealthCheckAnnotationCategory = {}));
