var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecommendationPriority } from "appv2/api/recommendations/recommendation-priority.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { CategoryMap, ProductMap } from "./recommendations-page.constants";
import { RECOMMENDATIONS_FILTERS_LIST } from "./utils/recommendations-filter.utils";
/**
 * Represents the filter groups for the recommendations page.
 */
export var RECOMMENDATIONS_FILTER_GROUPS = ["Priority", "Category", "Product", "Source"];
export var generateFilterExpression = function (filters) {
    var groupedFilters = filters.reduce(function (groups, filter) {
        var key = filter.group;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(filter);
        return groups;
    }, {});
    var groupExpressions = Object.entries(groupedFilters).map(function (_a) {
        var group = _a[0], filters = _a[1];
        var expressions = filters.map(function (filter) {
            if (filter.value && filter.selected) {
                if (group === "Priority") {
                    return "Definition_Priority == \"".concat(filter.value, "\"");
                }
                else if (group === "Category") {
                    var categoryGuid = CategoryMap[filter.value];
                    return "Definition_Categories.ToLower().Contains(\"".concat(categoryGuid, "\")");
                }
                else if (group === "Product") {
                    var productGuid = ProductMap[filter.value];
                    return "Definition_Products.ToLower().Contains(\"".concat(productGuid, "\")");
                }
                else if (group === "Visibility") {
                    return "Visibility == \"".concat(filter.value, "\"");
                }
                else if (group === "Source") {
                    return "Source == \"".concat(filter.value, "\"");
                }
            }
            return "";
        });
        var finalExpressions = expressions.filter(function (value) { return value.length > 0; });
        return finalExpressions.length > 0 ? "(".concat(finalExpressions.join(" || "), ")") : "";
    });
    var finalGroupExpressions = groupExpressions.filter(function (groupExpression) { return groupExpression.length > 0; });
    return finalGroupExpressions.join(" && ");
};
export var getFilterFromValue = function (value) { var _a; return (_a = RECOMMENDATIONS_FILTERS_LIST.find(function (filter) { var _a; return ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === value.toLowerCase(); })) !== null && _a !== void 0 ? _a : { type: "selectable" }; };
export var generatePriorityMetricConfig = function (recommendationStats, filterCallback) {
    return {
        onClick: function (value) { return filterCallback({ filter: { type: "selectable", value: value, group: "Priority" }, source: "PriorityTile" }); },
        pieChartConfig: {
            size: "xxs",
            legend: {
                items: [
                    {
                        iconConfig: { size: "xs", variant: "light-circle", className: ORCH_ICONS.zap },
                        count: recommendationStats.RecommendationsPriorityCounts.Urgent || 0,
                        label: RecommendationPriority.Urgent,
                        value: RecommendationPriority.Urgent
                    },
                    {
                        iconConfig: { size: "xs", variant: "light-circle", className: ORCH_ICONS.alertOctagon },
                        count: recommendationStats.RecommendationsPriorityCounts.Important || 0,
                        label: RecommendationPriority.Important,
                        value: RecommendationPriority.Important
                    },
                    {
                        iconConfig: { size: "xs", variant: "light-circle", className: ORCH_ICONS.star02 },
                        count: recommendationStats.RecommendationsPriorityCounts.BestPractice || 0,
                        label: "Best Practice",
                        value: RecommendationPriority.BestPractice
                    }
                ]
            },
            colours: ["error.4", "warning.4", "blue.3"],
            series: [
                recommendationStats.RecommendationsPriorityCounts.Urgent || 0,
                recommendationStats.RecommendationsPriorityCounts.Important || 0,
                recommendationStats.RecommendationsPriorityCounts.BestPractice || 0
            ],
            labels: ["Urgent", "Important", "Best Practice"]
        }
    };
};
export var generateTopCategoryConfig = function (recommendationStats, filterCallback) {
    return {
        onClick: function (value) { return filterCallback({ filter: { type: "selectable", value: value, group: "Category" }, source: "TopCategoryTile" }); },
        categoryItems: [
            {
                value: "sprawl",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["sprawl"].toLowerCase()],
                iconProps: {
                    textLabel: "Sprawl",
                    iconColorVariant: "primary-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "sprawl",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.sprawl
                }
            },
            // { commented out until phase 2
            //    value: "licensing",
            //    count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["licensing"].toLowerCase()],
            //    iconProps: {
            //       textLabel: "Licensing",
            //       iconColorVariant: "Licensing",
            //       iconSize: "xxs",
            //       iconVariant: "light-circle",
            //       textColorVariant: "licensing",
            //       textSize: "xs",
            //       iconClassName: ORCH_ICONS.award03
            //    }
            // },
            {
                value: "storage",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["storage"].toLowerCase()],
                iconProps: {
                    textLabel: "Storage",
                    iconColorVariant: "success-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "storage",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.database01
                }
            },
            {
                value: "governance",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["governance"].toLowerCase()],
                iconProps: {
                    textLabel: "Governance",
                    iconColorVariant: "brown-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "governance",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.shieldCheck
                }
            },
            {
                value: "security",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["security"].toLowerCase()],
                iconProps: {
                    textLabel: "Security",
                    iconColorVariant: "pink-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "security",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.lock01
                }
            },
            {
                value: "architecture",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["architecture"].toLowerCase()],
                iconProps: {
                    textLabel: "Architecture",
                    iconColorVariant: "purple-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "architecture",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.gitBranch01
                }
            },
            {
                value: "adoption",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["adoption"].toLowerCase()],
                iconProps: {
                    textLabel: "Adoption",
                    iconColorVariant: "orange-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "adoption",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.checkHeart
                }
            },
            {
                value: "cost savings",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["cost savings"].toLowerCase()],
                iconProps: {
                    textLabel: "Cost Saving",
                    iconColorVariant: "magenta-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "costsaving",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.currencyDollar
                }
            },
            {
                value: "guests",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["guests"].toLowerCase()],
                iconProps: {
                    textLabel: "Guests",
                    iconColorVariant: "blue-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "guests",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.user03
                }
            },
            {
                value: "communications",
                count: recommendationStats.RecommendationsCategoryCounts[CategoryMap["communications"].toLowerCase()],
                iconProps: {
                    textLabel: "Communications",
                    iconColorVariant: "success-8",
                    iconSize: "xxs",
                    iconVariant: "light-circle",
                    textColorVariant: "communications",
                    textSize: "xs",
                    iconClassName: ORCH_ICONS.messageSquare02
                }
            }
        ]
    };
};
export var generateSpotlightFilter = function (_a, setSpotlightValue, setPriorityMetricConfig, setTopCategoryConfig, setAppliedFilters) {
    var filter = _a.filter, source = _a.source;
    setSpotlightValue(filter);
    if (filter) {
        if (source === "PriorityTile") {
            setPriorityMetricConfig(function (config) {
                return __assign(__assign({}, config), { value: filter === null || filter === void 0 ? void 0 : filter.value });
            });
            setTopCategoryConfig(function (config) {
                return __assign(__assign({}, config), { value: undefined });
            });
        }
        else if (source === "TopCategoryTile") {
            setTopCategoryConfig(function (config) {
                return __assign(__assign({}, config), { value: filter === null || filter === void 0 ? void 0 : filter.value });
            });
            setPriorityMetricConfig(function (config) {
                return __assign(__assign({}, config), { value: undefined });
            });
        }
        else if (source === "CardChip") {
            setTopCategoryConfig(function (config) {
                return __assign(__assign({}, config), { value: undefined });
            });
            setPriorityMetricConfig(function (config) {
                return __assign(__assign({}, config), { value: undefined });
            });
        }
        setAppliedFilters(function (filters) {
            return filters.map(function (appliedFilter) { var _a, _b; return (((_a = appliedFilter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = filter.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ? __assign(__assign({}, appliedFilter), { selected: true }) : __assign(__assign({}, appliedFilter), { selected: false })); });
        });
    }
    else {
        setPriorityMetricConfig(function (config) {
            return __assign(__assign({}, config), { value: undefined });
        });
        setTopCategoryConfig(function (config) {
            return __assign(__assign({}, config), { value: undefined });
        });
    }
};
