import React from "react";
import "./user-guest-list-webpart.scss";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { UserListMode } from "app/store/users/list";
import * as UsersListStore from "app/store/users/list";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { ReportSearchSlotName } from "app/store/lifecycle-report/search";
import ReportColumnGuestName from "app/components/insights/common/report/report-list-table/components/report-column-guest-name";
import ReportColumnGuestMembershipStatus from "app/components/insights/common/report/report-list-table/components/report-column-guest-membership-status";
/**
 * The user guest list, but shown in the team information page sharepoint webpart. Is the same as insight report fields, but only a subset and less dynamic.
 */
export var UserGuestListWebpart = React.memo(function (props) {
    var usersPageContext = useSelector(function (state) { return state.usersListState[props.mode === UserListMode.Guests ? "guests" : props.mode === UserListMode.Members ? "members" : "owners"]; });
    var dispatch = useDispatch();
    var intl = useIntl();
    var _a = React.useState(true), minimized = _a[0], setMinimized = _a[1];
    var lifecycleReport = useSelector(function (state) { return state.lifecycleReportState[ReportSearchSlotName.GuestInsightsSlot]; });
    var lifecycleReportContext = lifecycleReport === null || lifecycleReport === void 0 ? void 0 : lifecycleReport.Context;
    var users = lifecycleReport === null || lifecycleReport === void 0 ? void 0 : lifecycleReport.Items;
    React.useEffect(function () {
        dispatch(LifecycleReportStore.LoadByState({
            Context: {
                View: "workspace-guest-memberships",
                DynamicValues: { groupId: props.groupId }
            }
        }, ReportSearchSlotName.GuestInsightsSlot));
    }, []);
    var getModeTitle = function () {
        return props.mode === UserListMode.Guests
            ? "Guests"
            : props.mode === UserListMode.Members
                ? intl.formatMessage({ id: "general.members", defaultMessage: "Members" })
                : intl.formatMessage({ id: "general.owners", defaultMessage: "Owners" });
    };
    React.useEffect(function () {
        dispatch(UsersListStore.Clear(props.mode));
        dispatch(UsersListStore.Load(props.mode, props.siteId !== undefined ? props.siteId : null, props.groupId !== undefined ? props.groupId : null));
        return function () {
            dispatch(UsersListStore.Clear(props.mode));
        };
    }, [props.mode, props.groupId, props.siteId]);
    var pageNext = function () {
        if (usersPageContext !== null && usersPageContext.Mode !== null) {
            if (minimized === true) {
                setMinimized(false);
            }
            else {
                dispatch(UsersListStore.IsLoading(usersPageContext.Mode, true));
                dispatch(UsersListStore.Page(usersPageContext));
            }
        }
    };
    var RenderListOfUsers = function () {
        //let users: User[] = [];
        return (React.createElement("div", { className: "list-of-members" },
            React.createElement("div", { className: "title" },
                React.createElement("h2", null,
                    getModeTitle(),
                    " (",
                    users.length,
                    ")")),
            React.createElement("div", { className: "user-list-wrapper" },
                React.createElement("div", { className: "list" }, users.map(function (user) { return (React.createElement("div", { className: "user-container" },
                    React.createElement(ReportColumnGuestName, { Item: user, goToLink: function () { return console.log("clicked"); }, reportColumn: {}, daysInactive: lifecycleReportContext.InactiveDays !== undefined ? lifecycleReportContext.InactiveDays : 90, hideLinks: true, hideStatus: true }),
                    React.createElement(ReportColumnGuestMembershipStatus, { Item: user }))); })))));
    };
    return (React.createElement("div", { className: "user-guest-list-component-webpart" }, usersPageContext.FirstLoadDone === false ? (React.createElement("div", null,
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 33, marginTop: 15 }),
        React.createElement("div", { className: "list", style: { marginTop: "20px", marginBottom: "0px" } },
            React.createElement("div", { className: "user-container" },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 86 }))))) : (React.createElement(React.Fragment, null, RenderListOfUsers()))));
});
