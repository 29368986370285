var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RequestCurrentActionTypes } from "./types";
import generateGuid from "app/utils/generateGuid";
var initialState = {
    item: {
        GUID: generateGuid(),
        ShowInDirectory: true
    }
};
export function requestCurrentReducer(state, action) {
    if (state === void 0) { state = __assign({}, initialState); }
    switch (action.type) {
        case RequestCurrentActionTypes.Request_Current_Load: {
            console.log("request current load > ", action.payload);
            //console.log(RequestCurrentActionTypes.Request_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case RequestCurrentActionTypes.Request_Current_Add: {
            // let mergedNewArray = [...state.items, action.payload];
            // mergedNewArray = mergedNewArray.sort(compare);
            return {
                item: action.payload
            };
        }
        case RequestCurrentActionTypes.Request_Current_Refresh: {
            console.log("request current load > ", action.payload);
            //console.log(RequestCurrentActionTypes.Request_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case RequestCurrentActionTypes.Request_Current_Update: {
            var updatedRequest = Object.assign({}, state.item, action.payload);
            return {
                item: updatedRequest
            };
        }
        case RequestCurrentActionTypes.Request_Current_Update_Status: {
            var updatedRequest = Object.assign({}, state.item, action.payload);
            return {
                item: updatedRequest
            };
        }
        case RequestCurrentActionTypes.Request_Current_Clear: {
            return {
                item: {
                    GUID: generateGuid()
                }
            };
        }
        default:
            return state;
    }
}
