import { ApprovalWorkflowListActionTypes } from "app/store/approval-workflows/list/types";
import { AsyncCallType } from "app/store/async/types";
import * as RequestListTypes from "app/store/requests/list";
export var approvalWorkflowListActionMap = [
    {
        actionType: ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/ApprovalWorkflows",
        responseDataType: {},
        invalidates: [
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/ApprovalWorkflows",
        responseDataType: {},
        invalidates: [
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/ApprovalWorkflows",
        responseDataType: {},
        invalidates: [
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "approvalWorkflowListState.items",
        keyType: "typeName",
        ApiUrl: "/api/ApprovalWorkflows",
        responseDataType: []
    }
];
