import { collapseWhitespace } from "appv2/utils/string.utils";
import { useState } from "react";
/**
 * Hook to manage search text input and query search text
 */
export var usePageSearch = function (setActivePage) {
    var _a = useState(""), querySearchText = _a[0], setQuerySearchText = _a[1];
    var _b = useState(""), searchTextInputValue = _b[0], setSearchTextInputValue = _b[1];
    var clearSearch = function () {
        setQuerySearchText("");
        setSearchTextInputValue("");
    };
    var onSearchBoxChange = function (value) {
        setSearchTextInputValue(value);
        if (value.length === 0) {
            setQuerySearchText("");
        }
    };
    var onSearchBoxKeyDown = function (event) {
        if (event.key === "Enter") {
            setQuerySearchText(event.currentTarget.value);
            setActivePage(1);
        }
    };
    var onSearchBoxXClicked = function () {
        clearSearch();
        setActivePage(1);
    };
    var highlightSearchWords = collapseWhitespace(querySearchText).split(" ");
    return {
        querySearchText: querySearchText,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        highlightSearchWords: highlightSearchWords
    };
};
