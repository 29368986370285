import * as React from "react";
import "./step-card-notification-format.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Collapse } from "react-bootstrap";
import FormSelectBox from "app/components/common/form-select-box";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current/";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import PortletAlert from "app/components/common/portlet-alert";
var StepCardNotificationFormat = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "step-card-guest-request-notification-format policy-card", "data-pw": "step-card-notification" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "middle card-border" },
                        !currentPolicy.AllowGuestAccessForWorkspaces ? (React.createElement("div", { className: "notifications-not-required" },
                            React.createElement("h4", null, "Notification Format"),
                            React.createElement("span", { className: "description-text" },
                                React.createElement(PortletAlert, { icon: "info", type: "warning", message: React.createElement("p", { className: "text" },
                                        "The setting on the Guest Request tab has disabled guest access to Workspaces - ",
                                        React.createElement("u", null, "notifications are not required"),
                                        ". To configure a Notification Format, change the setting to permit guests.") })))) : (React.createElement(React.Fragment, null,
                            React.createElement("h4", null, "Notification Format"),
                            React.createElement("p", { className: "text" }, "Choose the format that you wish to send notifications by. You can choose multiple formats and Orchestry will ensure they are all kept in sync"))),
                        React.createElement(Collapse, { in: currentPolicy.AllowGuestAccessForWorkspaces },
                            React.createElement("div", { className: "notification-select" },
                                React.createElement(FormSelectBox, { style: "check-list", allowMultiple: true, list: [
                                        {
                                            value: "email",
                                            title: "Email",
                                            icon: "la la-envelope-open-text"
                                        },
                                        {
                                            value: "teams-alert",
                                            title: "Teams Notification",
                                            icon: "la la-bell"
                                        }
                                    ], defaultValue: currentPolicy.Notification_Formats, value: currentPolicy.Notification_Formats, onChange: function (value) {
                                        return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({
                                            Notification_Formats: value
                                        }));
                                    } }),
                                React.createElement(FormValidator, { FieldName: "Notification Formats", UniqueIdentifier: "request-notification-formats", Validators: ["string-not-empty"], Value: currentPolicy.Notification_Formats, OnScreen: props.IsCurrent })))))))));
});
export default StepCardNotificationFormat;
