import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import React from "react";
import { useSelector } from "react-redux";
import "./team-information-title.scss";
export var TeamInformationTitle = function () {
    var _a, _b;
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    return (React.createElement("div", { className: "team-information-title" }, teamContext === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 36, marginTop: 15, bodyWidth: 300 })) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "nested-title" }, (_b = (_a = teamContext.team) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.Title)))));
};
