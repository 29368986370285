import { Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { orchestryToast } from "appv2/components/orchestry-notifications/orchestry-toast-notification.component";
import React from "react";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
export var OrchestryProcessingToast = function (successMessage) {
    orchestryToast(React.createElement(Text, { span: true, c: "text-primary.0", size: "sm" }, successMessage), {
        autoClose: 2500,
        progressBarVariant: "processing",
        toastId: "processing-toast",
        icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "purple-7", iconClassName: ORCH_ICONS.loading02 })
    });
};
