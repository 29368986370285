import { AsyncCallType } from "app/store/async/types";
import * as DirectoryStoreTypes from "app/store/directory/search/types";
export var directorySearchActionMap = [
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Search,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/directory/search",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Load,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/directory/state",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Load_Refiners,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/directory/StateRefiners",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Load_Favs,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/directory/StateFavs",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Load_RefinersAndFavs,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/directory/StateRefinersAndFavs",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Update_View,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/directory/update",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Refresh,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/directory/update",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Update_Paging,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/directory/update",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Update_Context,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/directory/update",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Update_Refiners,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/directory/update",
        responseDataType: {}
    },
    {
        actionType: DirectoryStoreTypes.DirectorySearchActionTypes.Directory_Update_FavsOnServer,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/directory/UpdateFavs",
        responseDataType: {}
    }
];
