var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Highlight, Skeleton } from "@mantine/core";
import React from "react";
import { calculateLineHeightPixels, convertFontSizeToNumber } from "../orchestry-text/orchestry-text.utils";
var DEFAULT_FONTSIZE_TO_WIDTH = {
    0: 0,
    8: 60,
    9: 60,
    10: 80,
    11: 80,
    12: 80,
    13: 80,
    14: 100,
    15: 100,
    16: 100,
    17: 100,
    18: 190,
    19: 190,
    20: 120,
    21: 120,
    22: 120,
    23: 130,
    24: 130,
    30: 110,
    36: 100
};
export var OrchestryHighlight = function (_a) {
    var _b, _c, _d, _e, _f;
    var isLoading = _a.isLoading, children = _a.children, skeletonWidth = _a.skeletonWidth, props = __rest(_a, ["isLoading", "children", "skeletonWidth"]);
    if (isLoading) {
        // We need to do some work to convert the font size to a number for the skeleton height
        // and then calculate the line height in pixels to set the margin top and bottom to mimic
        // the text if the line height is stacking it.
        var fontSize = (_c = (_b = props.fz) !== null && _b !== void 0 ? _b : props.size) !== null && _c !== void 0 ? _c : 10;
        var convertedSize = convertFontSizeToNumber(fontSize);
        var lineHeight = calculateLineHeightPixels((_d = props.lh) !== null && _d !== void 0 ? _d : 20);
        var margin = (lineHeight - convertedSize) / 2;
        //If text has margin top or bottom, we need to add that to the line height margin
        var convertedMarginTop = convertFontSizeToNumber((_e = props.mt) !== null && _e !== void 0 ? _e : 0);
        var convertedMarginBotton = convertFontSizeToNumber((_f = props.mb) !== null && _f !== void 0 ? _f : 0);
        var marginTop = convertedMarginTop + margin;
        var marginBottom = convertedMarginBotton + margin;
        // This filters out all the text props and leaves the BoxProps. It would be nice to find a smarter way to do this as this is a bit brittle.
        var width = skeletonWidth !== null && skeletonWidth !== void 0 ? skeletonWidth : DEFAULT_FONTSIZE_TO_WIDTH[convertedSize];
        return React.createElement(Skeleton, __assign({ h: convertedSize, w: width, mt: marginTop, mb: marginBottom }, props));
    }
    return React.createElement(Highlight, __assign({}, props), children);
};
