import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useRecommendationsFilters } from "../use-recommendations-filters.hook";
import { generatePriorityMetricConfig, generateTopCategoryConfig, getFilterFromValue } from "../recommendations-page.utils";
import { useRecommendationsAdminApi } from "./use-recommendations-admin-api.hook";
export var useRecommendationsAdminPage = function (_a) {
    var tid = _a.tid;
    var _b = useState(RecommendationRecordVisibility.Visible), tab = _b[0], setTab = _b[1];
    var history = useHistory();
    var _c = useRecommendationsFilters(), appliedFilters = _c.appliedFilters, visibilityFilter = _c.visibilityFilter, priorityMetricConfig = _c.priorityMetricConfig, topCategoryConfig = _c.topCategoryConfig, applySpotlightFilter = _c.applySpotlightFilter, applyFilterButtonFilters = _c.applyFilterButtonFilters, clearAllFilters = _c.clearAllFilters, removeFilter = _c.removeFilter, setPriorityMetricConfig = _c.setPriorityMetricConfig, setTopCategoryConfig = _c.setTopCategoryConfig, setVisibilityFilter = _c.setVisibilityFilter, appliedFiltersDependency = _c.appliedFiltersDependency;
    var _d = useRecommendationsAdminApi(appliedFilters, visibilityFilter, appliedFiltersDependency, { tid: tid }), pagedRecommendations = _d.pagedRecommendations, recommendationCategories = _d.recommendationCategories, recommendationProducts = _d.recommendationProducts, recommendationRecordVisibilityCounts = _d.recommendationRecordVisibilityCounts, recommendationStats = _d.recommendationStats, togglePrioritySort = _d.togglePrioritySort, activePage = _d.activePage, setActivePage = _d.setActivePage, resultsPerPage = _d.resultsPerPage, setResultsPerPage = _d.setResultsPerPage, querySearchText = _d.querySearchText, highlightSearchWords = _d.highlightSearchWords, searchTextInputValue = _d.searchTextInputValue, onSearchBoxChange = _d.onSearchBoxChange, onSearchBoxKeyDown = _d.onSearchBoxKeyDown, onSearchBoxXClicked = _d.onSearchBoxXClicked, recommendationStatsSuccessful = _d.recommendationStatsSuccessful, pagedRecommendationsIsFetching = _d.pagedRecommendationsIsFetching, recommendationRecordVisibilityCountsIsLoading = _d.recommendationRecordVisibilityCountsIsLoading, recommendationStatsIsFetching = _d.recommendationStatsIsFetching, isError = _d.isError, isPageLoading = _d.isPageLoading;
    useEffect(function () {
        if (recommendationStats) {
            setPriorityMetricConfig(generatePriorityMetricConfig(recommendationStats, applySpotlightFilter));
            setTopCategoryConfig(generateTopCategoryConfig(recommendationStats, applySpotlightFilter));
        }
    }, [recommendationStatsSuccessful, recommendationStatsIsFetching, applySpotlightFilter, recommendationStats]);
    var updateVisibilityFilter = function (value) {
        clearAllFilters();
        setActivePage(1);
        setVisibilityFilter({ type: "selectable", value: value, group: "Visibility", selected: true });
    };
    var tabs = useMemo(function () {
        return [
            {
                text: "Open",
                value: RecommendationRecordVisibility.Visible,
                count: recommendationRecordVisibilityCounts === null || recommendationRecordVisibilityCounts === void 0 ? void 0 : recommendationRecordVisibilityCounts.Visible,
                onClick: function (value) { return updateVisibilityFilter(value); },
                tooltipText: "View a list of all Recommendations that are currently Open"
            },
            {
                text: "Resolved",
                value: RecommendationRecordVisibility.Resolved,
                count: recommendationRecordVisibilityCounts === null || recommendationRecordVisibilityCounts === void 0 ? void 0 : recommendationRecordVisibilityCounts.Resolved,
                onClick: function (value) { return updateVisibilityFilter(value); },
                tooltipText: "View a list of all Recommendations that are currently Resolved"
            },
            {
                text: "Snoozed",
                value: RecommendationRecordVisibility.Snoozed,
                count: recommendationRecordVisibilityCounts === null || recommendationRecordVisibilityCounts === void 0 ? void 0 : recommendationRecordVisibilityCounts.Snoozed,
                onClick: function (value) { return updateVisibilityFilter(value); },
                tooltipText: "View a list of all Recommendations that are currently Snoozed"
            }
        ];
    }, [recommendationRecordVisibilityCounts, updateVisibilityFilter]);
    var badgeOnClick = function (value) { return applySpotlightFilter({ filter: getFilterFromValue(value), source: "CardChip" }); };
    var navigateToResolutionsPage = function () { return history.push("/orchestry-admin/tool/tenant-recommendations-browser/resolutions"); };
    return {
        pagedRecommendations: pagedRecommendations,
        recommendationCategories: recommendationCategories,
        recommendationProducts: recommendationProducts,
        recommendationRecordVisibilityCounts: recommendationRecordVisibilityCounts,
        priorityMetricConfig: priorityMetricConfig,
        topCategoryConfig: topCategoryConfig,
        removeFilter: removeFilter,
        appliedFilters: appliedFilters,
        recommendationStatsSuccessful: recommendationStatsSuccessful,
        updateVisibilityFilter: updateVisibilityFilter,
        visibilityFilter: visibilityFilter,
        clearAllFilters: clearAllFilters,
        applySpotlightFilter: applySpotlightFilter,
        applyFilterButtonFilters: applyFilterButtonFilters,
        isError: isError,
        pagedRecommendationsIsFetching: pagedRecommendationsIsFetching,
        recommendationStatsIsFetching: recommendationStatsIsFetching,
        recommendationRecordVisibilityCountsIsLoading: recommendationRecordVisibilityCountsIsLoading,
        shouldShowShareSuggestion: (visibilityFilter === null || visibilityFilter === void 0 ? void 0 : visibilityFilter.value) === RecommendationRecordVisibility.Visible,
        shouldShowViewHistory: (visibilityFilter === null || visibilityFilter === void 0 ? void 0 : visibilityFilter.value) === RecommendationRecordVisibility.Resolved,
        navigateToResolutionsPage: navigateToResolutionsPage,
        isTabsDeactivated: (visibilityFilter === null || visibilityFilter === void 0 ? void 0 : visibilityFilter.value) === RecommendationRecordVisibility.NA,
        recommendationStats: recommendationStats,
        shouldShowRecommendationsStats: !appliedFilters.some(function (filter) { return filter.selected; }) &&
            visibilityFilter.value === RecommendationRecordVisibility.Visible &&
            Boolean((pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items) && (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items.length) > 0),
        searchTextInputValue: searchTextInputValue,
        querySearchText: querySearchText,
        highlightSearchWords: highlightSearchWords,
        setActivePage: setActivePage,
        activePage: activePage,
        setResultsPerPage: setResultsPerPage,
        resultsPerPage: resultsPerPage,
        badgeOnClick: badgeOnClick,
        tab: tab,
        setTab: setTab,
        tabs: tabs,
        togglePrioritySort: togglePrioritySort,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        isPageLoading: isPageLoading
    };
};
