export var TenantPages;
(function (TenantPages) {
    /**
     * Shows a details page of the tenant.
     */
    TenantPages["Details"] = "Details";
    /**
     * Shows an analytics page of the tenant.
     */
    TenantPages["Analytics"] = "Analytics";
    /**
     * Shows a page with the list of health checks of the tenant.
     */
    TenantPages["HealthChecks"] = "HealthChecks";
})(TenantPages || (TenantPages = {}));
