import { Loader, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryFormErrorText } from "appv2/components/orchestry-form-error-text/orchestry-form-error-text.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryTextInput } from "appv2/components/orchestry-text-input/orchestry-text-input.component";
import React, { useEffect } from "react";
export var SecretInputControl = function (_a) {
    var isLoading = _a.isLoading, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, isError = _a.isError, isLookupLoading = _a.isLookupLoading, tenantGuid = _a.tenantGuid, partnerOrganizationName = _a.partnerOrganizationName, onSecretFoundChange = _a.onSecretFoundChange;
    var isSecretFound = !!tenantGuid;
    var icon = isLookupLoading ? (React.createElement(Loader, { size: "xs", color: "var(--mantine-color-primary-6)" })) : isSecretFound ? (React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.check, color: "var(--mantine-color-gray-9)", fontSize: "16px", lineHeight: "16px" })) : isError ? (React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.x, color: "var(--mantine-color-error-6)", fontSize: "20px", lineHeight: "20px" })) : (React.createElement(React.Fragment, null));
    useEffect(function () {
        if (onSecretFoundChange !== undefined)
            onSecretFoundChange(isSecretFound);
    }, [isSecretFound]);
    return (React.createElement(Stack, { gap: 8, mih: "65px" },
        React.createElement(OrchestryTextInput, { isLoading: isLoading, c: "text-tertiary.0", fw: "300", fz: "14px", lh: "20px", rightSection: icon, value: value, onChange: function (event) { return onChange(event.target.value); }, onBlur: function () { return onBlur(); } }),
        isLookupLoading ? (React.createElement(React.Fragment, null)) : isSecretFound ? (React.createElement(OrchestryHighlight, { isLoading: isLoading, c: "text-secondary.0", fz: "12px", fw: 300, lh: "18px", highlight: partnerOrganizationName !== null && partnerOrganizationName !== void 0 ? partnerOrganizationName : "", highlightStyles: {
                padding: 0,
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                backgroundColor: "var(--mantine-color-text-secondary-0)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
            } }, "Connected with Partner ".concat(partnerOrganizationName))) : isError ? (React.createElement(OrchestryFormErrorText, { error: "Invalid Partner Connection Secret. Please try again." })) : (React.createElement(React.Fragment, null))));
};
