import React from "react";
import { useDispatch } from "react-redux";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { Form } from "react-bootstrap";
import "./card-default-inactivity.scss";
export var DefaultInactivity = function (_a) {
    var inactivity = _a.inactivity;
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "card-default-inactivity" }, inactivity !== undefined ? (React.createElement(React.Fragment, null,
        React.createElement("p", { "data-pw": "description" }, "Threshold (in number of days) of inactivity for a Guest to show as inactive"),
        React.createElement(Form.Control, { type: "number", defaultValue: "90", min: 1, value: inactivity !== null && inactivity > 0 ? inactivity.toString() : "90", onChange: function (e) {
                return dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave({ LCDefaultGuestInactivityDays: Number(e.target.value) }));
            } }))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 50, bodyWidth: "100%" }))));
};
