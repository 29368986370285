var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import MembersIcon from "app/assets/img/members-icon.svg";
import PortletAlert from "app/components/common/portlet-alert";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalSearchModal from "app/components/common/principal-search-modal";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
var RequestWizardPermissionsOwners = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var intl = useIntl();
    var rwc = React.useContext(RequestWizardContext);
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var ownerContainerElement = React.useRef();
    var addOwner = function (user) {
        var tempArray = [user];
        if (rwc.currentRequest.Owners !== undefined) {
            if (rwc.currentRequest.Owners.length > 0) {
                if (rwc.currentRequest.Owners.some(function (u) { return u.UserPrincipalName === user.UserPrincipalName; })) {
                    tempArray = __spreadArray([], rwc.currentRequest.Owners, true);
                }
                else {
                    tempArray = __spreadArray(__spreadArray([], rwc.currentRequest.Owners, true), tempArray, true);
                }
            }
        }
        rwc.updateCurrentRequest({ Owners: tempArray });
    };
    var removeOwner = function (user) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.Owners) === null || _a === void 0 ? void 0 : _a.filter(function (owner) { return owner.UserPrincipalName !== user.UserPrincipalName; });
        if (tempArray === undefined) {
            tempArray = [];
        }
        if (currentUser !== undefined) {
            if (currentUser.Id !== undefined) {
                if (currentUser.Id === user.Id) {
                    rwc.setHasRemovedSelfFromOwners(true);
                }
            }
        }
        rwc.updateCurrentRequest({ Owners: __spreadArray([], tempArray, true) });
    };
    var getOwners = function (lockedUsers) {
        var _a;
        var owners = [];
        if (currentUser !== undefined) {
            currentUser.Locked = true;
            currentUser.Type = "User";
            owners.push(currentUser);
        }
        if ((((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateTeamCloneMembers) === true &&
            (rwc.currentRequest.RequestType === "Team" || (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernGroup"))) ||
            (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernCommunications")) {
            if (rwc.liveOwners !== undefined) {
                //console.log("Owner compare", rwc.liveOwners, owners);
                var tempOwners = rwc.liveOwners.filter(function (u) { return u.UserPrincipalName !== null; }).filter(function (u) { return !owners.some(function (us) { return us.UserPrincipalName.toLowerCase() === u.UserPrincipalName.toLowerCase(); }); });
                tempOwners = tempOwners.map(function (to) {
                    to.Locked = true;
                    return to;
                });
                owners = __spreadArray(__spreadArray([], owners, true), tempOwners, true);
            }
        }
        if (rwc.currentRequest.Owners !== undefined) {
            var tempOwners = rwc.currentRequest.Owners.filter(function (u) { return !owners.some(function (us) { return us.UserPrincipalName.toLowerCase() === u.UserPrincipalName.toLowerCase(); }); });
            tempOwners = tempOwners.map(function (to) {
                to.Locked = false;
                return to;
            });
            owners = __spreadArray(__spreadArray([], owners, true), tempOwners, true);
        }
        if (lockedUsers !== undefined) {
            owners = owners.filter(function (u) { return (u.Locked !== undefined && u.Locked === lockedUsers ? true : false); });
        }
        return owners;
    };
    React.useEffect(function () {
        var owners = getOwners();
        rwc.updateCurrentRequest({ Owners: owners });
        // add when mounted
        document.addEventListener("mousedown", userListBoxClickHandler);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", userListBoxClickHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var userListBoxClickHandler = function (e) {
        if (ownerContainerElement.current !== undefined) {
            if (ownerContainerElement.current !== null) {
                if (ownerContainerElement.current === e.target) {
                    var addButton = document.querySelector(".step-permissions .users .left .link");
                    if (addButton !== null) {
                        addButton.click();
                    }
                }
            }
        }
    };
    var clickAddButton = function (side) {
        var addButton = document.querySelector(".step-permissions .users ." + side + " .link");
        if (addButton !== null) {
            addButton.click();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "adder-top" },
            React.createElement("div", { className: "title-add" },
                React.createElement("span", { className: "title" },
                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.owners", defaultMessage: "Owners" }),
                    " ",
                    React.createElement("span", { className: "count" },
                        "(",
                        React.createElement("span", { className: "num-pad" }, getOwners().length),
                        ")")),
                React.createElement(PrincipalSearchModal, { suggestedTitle: intl.formatMessage({ id: "page.request_wizard.step_permissions.suggested_owners", defaultMessage: "Suggested Owners" }), mode: "usersAndGroups", title: intl.formatMessage({ id: "page.request_wizard.step_permissions.add_owners", defaultMessage: "Add Owners" }), selectedPrincipals: getOwners(), excludeGuests: props.ShouldExcludeGuests, onPrincipalSelected: function (principal) {
                        addOwner(principal);
                    }, onPrinciaplRemoved: function (principal) {
                        removeOwner(principal);
                    } },
                    React.createElement("div", { className: "add-action", "data-pw": "add-owners" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "la la-plus" })),
                        React.createElement("div", { className: "text" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.add_owners", defaultMessage: "Add Owners" }))))),
            React.createElement("div", { className: "info" },
                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.owners_description", defaultMessage: "Owners who have administrative privileges" }))),
        React.createElement("div", { className: "user-list " +
                (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.MinOwners) !== undefined && ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.MinOwners) > 0
                    ? rwc.currentRequest.Owners !== undefined && rwc.currentRequest.Owners.length < ((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.MinOwners)
                        ? "min-warning"
                        : "min-success"
                    : ""), ref: ownerContainerElement },
            ((_d = rwc.currentRequest.Template) === null || _d === void 0 ? void 0 : _d.MinOwners) !== undefined && ((_e = rwc.currentRequest.Template) === null || _e === void 0 ? void 0 : _e.MinOwners) > 0 ? (rwc.currentRequest.Owners !== undefined && rwc.currentRequest.Owners.length >= ((_f = rwc.currentRequest.Template) === null || _f === void 0 ? void 0 : _f.MinOwners) ? (React.createElement(PortletAlert, { type: "success", icon: "check", size: "small", message: intl.formatMessage({
                    id: "page.request_wizard.step_permissions.valid_user_count",
                    defaultMessage: "You have selected at least {minnumber} additional {users}"
                }, {
                    minnumber: (_g = rwc.currentRequest.Template) === null || _g === void 0 ? void 0 : _g.MinOwners,
                    users: ((_h = rwc.currentRequest.Template) === null || _h === void 0 ? void 0 : _h.MinOwners) !== 1
                        ? intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_owners_label_plural", defaultMessage: "owners" })
                        : intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_owners_label_singular", defaultMessage: "owner" })
                }) })) : (React.createElement(PortletAlert, { type: "danger", icon: "info", size: "small", message: intl.formatMessage({
                    id: "page.request_wizard.step_permissions.invalid_user_count",
                    defaultMessage: "Please select at least {minnumber} additional {users}"
                }, {
                    minnumber: (_j = rwc.currentRequest.Template) === null || _j === void 0 ? void 0 : _j.MinOwners,
                    users: ((_k = rwc.currentRequest.Template) === null || _k === void 0 ? void 0 : _k.MinOwners) !== 1
                        ? intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_owners_label_plural", defaultMessage: "owners" })
                        : intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_owners_label_singular", defaultMessage: "owner" })
                }) }))) : null,
            ((_l = rwc.currentRequest.Template) === null || _l === void 0 ? void 0 : _l.MinOwners) !== undefined && ((_m = rwc.currentRequest.Template) === null || _m === void 0 ? void 0 : _m.MinOwners) > 0
                ? rwc.currentRequest.Owners !== undefined && rwc.currentRequest.Owners.length < ((_o = rwc.currentRequest.Template) === null || _o === void 0 ? void 0 : _o.MinOwners)
                    ? __spreadArray([], Array(((_p = rwc.currentRequest.Template) === null || _p === void 0 ? void 0 : _p.MinOwners) - rwc.currentRequest.Owners.length), true).map(function (x, i) { return (React.createElement("div", { className: "principal-item holder", onClick: function () { return clickAddButton("left"); } },
                        React.createElement("div", { className: "pic" },
                            React.createElement("div", { className: "profile-picture two-letter size-xs" })),
                        React.createElement("span", null, "\u00A0"))); })
                    : rwc.currentRequest.Owners === undefined
                        ? __spreadArray([], Array((_q = rwc.currentRequest.Template) === null || _q === void 0 ? void 0 : _q.MinOwners), true).map(function (x, i) { return (React.createElement("div", { className: "principal-item holder", onClick: function () { return clickAddButton("left"); } },
                            React.createElement("div", { className: "pic" },
                                React.createElement("div", { className: "profile-picture two-letter size-xs" })),
                            React.createElement("span", null, "\u00A0"))); })
                        : null
                : null,
            getOwners().length === 0 ? (React.createElement("div", { className: "no-results" },
                React.createElement("div", { className: "no-icon-text" },
                    React.createElement("img", { src: MembersIcon }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.no_owners_yet", defaultMessage: "No owners yet" }))))) : null,
            currentUser !== undefined ? React.createElement(PrincipalItem, { principal: currentUser, key: currentUser.Id, showBadge: true, onRemove: function (principal) { return removeOwner(principal); } }) : null,
            getOwners(false).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeOwner(principal); } })); }),
            getOwners(true).filter(function (u) { return (u.UserPrincipalName !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.UserPrincipalName) ? true : false); }).length > 0 ? (React.createElement("div", { className: "default-users-divider" },
                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.default_owners", defaultMessage: "Default Owners" }))) : null,
            getOwners(true)
                .filter(function (u) { return (u.UserPrincipalName !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.UserPrincipalName) ? true : false); })
                .map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeOwner(principal); } })); }))));
});
export default RequestWizardPermissionsOwners;
