// import { MiddlewareFunction} from 'app/store/middleware'
import { ProfileImageCurrentActionTypes } from "./types";
export function Load(profileImage) {
    return {
        type: ProfileImageCurrentActionTypes.ProfileImages_Current_Load,
        payload: [profileImage]
    };
}
export function AddUpdateItem(profileImage) {
    return {
        type: ProfileImageCurrentActionTypes.ProfileImages_AddUpdate,
        payload: profileImage
    };
}
