import * as React from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
import { FormattedMessage, useIntl } from "react-intl";
var RequestDetailsMetadata = React.memo(function (props) {
    //console.log("Request Param Guid", props.match.params.guid);
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var _a = React.useState([]), customMetadata = _a[0], setCustomMetadata = _a[1];
    var intl = useIntl();
    React.useEffect(function () {
        if (currentRequest.CustomMetadataValuesJson !== undefined && currentRequest.CustomMetadataValuesJson !== null) {
            var metaString = currentRequest.CustomMetadataValuesJson;
            //console.log("MetaString", metaString)
            setCustomMetadata(JSON.parse(metaString));
        }
    }, [currentRequest]);
    return (React.createElement(Portlet, { fluidHeight: true, className: "request-details-metadata" },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.metadata.metadata_label", defaultMessage: "Metadata" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-4 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.metadata.show_in_directory", defaultMessage: "Show in directory:" })),
                React.createElement("div", { className: "col-8" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, currentRequest.ShowInDirectory === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" }))))),
            customMetadata.map(function (md, mdIndex) {
                var _a;
                return (React.createElement("div", { className: "form-group form-group-xs row", key: mdIndex },
                    React.createElement("label", { className: "col-4 col-form-label metadata-table-field-title" },
                        md.FieldTitle,
                        ":"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, md.FieldType === "ManagedMetadata" ? (md.Values !== undefined && md.Values !== null && md.Values.length > 0 && md.Values.indexOf("{") > -1 ? (JSON.parse(md.Values).map(function (t) { return React.createElement(ManagedMetadataValue, { node: t }); })) : null) : (React.createElement("span", { style: { wordWrap: "break-word" }, dangerouslySetInnerHTML: { __html: (_a = md.Values) === null || _a === void 0 ? void 0 : _a.replace(/(?:\r\n|\r|\n)/g, "<br>") } }))))));
            }))));
});
export default RequestDetailsMetadata;
