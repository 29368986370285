var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { Portlet, PortletBody } from "../portlet";
import "./error-boundary.scss";
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false, error: null, errorInfo: null };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        this.setState({ hasError: true, error: error, errorInfo: errorInfo });
        console.error(error, errorInfo);
    };
    ErrorBoundary.prototype.render = function () {
        var _a, _b, _c;
        if (this.state.hasError) {
            var stack = (_a = this.state.errorInfo) === null || _a === void 0 ? void 0 : _a.componentStack;
            var firstFiveLines = stack === null || stack === void 0 ? void 0 : stack.split("\n").slice(0, 6);
            return (React.createElement(Portlet, null,
                React.createElement(PortletBody, { className: "error-boundary" },
                    React.createElement("div", { className: "error-header" },
                        React.createElement("img", { className: "meteor", src: "/media/orchestry/error.png" }),
                        React.createElement("span", null,
                            React.createElement("h1", { className: "main-header" }, "We're sorry."),
                            React.createElement("h2", { className: "main-subheader" }, "Something went wrong, please try again."))),
                    React.createElement("div", { className: "error-message" },
                        React.createElement("h3", { className: "error-message-header" }, "If the issue persists, please screenshot this page and include it with your Orchestry Support ticket."),
                        React.createElement("br", null),
                        React.createElement("div", { className: "error-message-body" },
                            React.createElement("strong", null, "User Info:"),
                            React.createElement("p", null,
                                "Name: ", (_b = this.props.userCurrentState) === null || _b === void 0 ? void 0 :
                                _b.DisplayName),
                            React.createElement("p", null,
                                "Server Region: ",
                                this.props.orchestryContext.ServerRegion),
                            React.createElement("p", null,
                                "Tenant Region: ",
                                this.props.orchestryContext.TenantRegion),
                            React.createElement("p", null,
                                "Tenant Name: ",
                                this.props.orchestryContext.TenantName),
                            React.createElement("br", null),
                            React.createElement("strong", null, "Error Information:"),
                            React.createElement("p", null,
                                "Occurred at: ",
                                new Date().toLocaleString()),
                            React.createElement("p", null,
                                "Message: ", (_c = this.state.error) === null || _c === void 0 ? void 0 :
                                _c.message),
                            React.createElement("br", null),
                            React.createElement("strong", null, "Browser Information:"),
                            React.createElement("p", null,
                                "User-Agent: ",
                                navigator.userAgent),
                            React.createElement("p", null,
                                "Path: ",
                                window.location.href.toString()),
                            React.createElement("br", null),
                            React.createElement("strong", null, "Stack Trace:"),
                            React.createElement("div", null, firstFiveLines === null || firstFiveLines === void 0 ? void 0 : firstFiveLines.map(function (line, k) { return (React.createElement("div", { key: "first-five-lines" + k }, line)); })))))));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(Component));
export default ErrorBoundary;
