import * as React from "react";
import "./user-search-dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Popover, OverlayTrigger } from "react-bootstrap";
import UsersService from "app/services/users";
import AnimatedLoader from "../animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import useWindowDimensions from "app/utils/windowDimentions";
import OfficeProfilePicture from "../office-profile-picture";
var UserSearchDropdown = React.memo(function (props) {
    var width = useWindowDimensions().width;
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var inputEl = React.useRef();
    //@ts-ignore
    var overlayTriggerEl = React.useRef();
    var spanEl = React.useRef();
    var popoverEl = React.useRef();
    var _b = React.useState([]), searchResults = _b[0], setSearchResults = _b[1];
    var _c = React.useState([]), suggestedUsers = _c[0], setSuggestedUsers = _c[1];
    var _d = React.useState(false), mobileOpen = _d[0], setMobileOpen = _d[1];
    var _e = React.useState(props.theme !== undefined ? props.theme : "default"), theme = _e[0], setTheme = _e[1];
    var _f = React.useState(false), isOpen = _f[0], setIsOpen = _f[1];
    //const [totalAjaxRequest, setTotalAjaxRequest] = React.useState(0);
    //const [totalAjaxRequestComplete, setTotalAjaxRequestComplete] = React.useState(0);
    //console.log("rerender");
    var _g = React.useState(-1), cursor = _g[0], setCursor = _g[1];
    // // On first run, get the suggested users
    React.useEffect(function () {
        setIsLoading(true);
        UsersService.getSuggestedPeople(5).then(function (resultData) {
            setIsLoading(false);
            setSuggestedUsers(resultData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        if (props.setIsOpen !== undefined) {
            props.setIsOpen(isOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    // Get the search results.
    var getResults = function (searchValue) {
        //setTimeout(() => {
        //console.log("timeout", searchValue, inputEl.current.value, searchValue === inputEl.current.value)
        if (searchValue === inputEl.current.value) {
            //
            if (searchValue.length === 0) {
                setIsLoading(false);
                setSearchResults([]);
                // This will cancel the last request.
                if (props.includeGroups !== undefined && props.includeGroups) {
                    UsersService.searchGraphUsersAndGroups(searchValue, 50).then(function (resultData) { });
                }
                else {
                    UsersService.searchGraphUsers(searchValue, 50, props.excludeGuests !== undefined ? props.excludeGuests : false).then(function (resultData) { });
                }
            }
            else {
                if (props.includeGroups !== undefined && props.includeGroups) {
                    UsersService.searchGraphUsersAndGroups(searchValue, 50).then(function (resultData) {
                        //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                        try {
                            if (searchValue === inputEl.current.value) {
                                setIsLoading(false);
                                setSearchResults(resultData);
                            }
                        }
                        catch (_a) { }
                    });
                }
                else {
                    UsersService.searchGraphUsers(searchValue, 50).then(function (resultData) {
                        //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                        try {
                            if (searchValue === inputEl.current.value) {
                                setIsLoading(false);
                                setSearchResults(resultData);
                            }
                        }
                        catch (_a) { }
                    });
                }
            }
        }
        //},500)
    };
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        }
        else if (e.keyCode === 40 && cursor < currentResults.length - 1) {
            setCursor(cursor + 1);
        }
        else if (e.keyCode === 13) {
            if (cursor !== -1) {
                clickUser(currentResults[cursor]);
            }
        }
    };
    var shouldShowSuggestions = function () {
        var shouldShow = true;
        if (searchResults !== undefined && searchResults.length > 0) {
            shouldShow = false;
        }
        if (inputEl !== undefined) {
            if (inputEl.current !== undefined) {
                if (inputEl.current !== null) {
                    if (inputEl.current.value !== undefined) {
                        if (inputEl.current.value !== null) {
                            //console.log("inputEl", inputEl)
                            if (inputEl.current.value.length > 0) {
                                shouldShow = false;
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldShowNoResult = function () {
        var shouldShow = false;
        if (searchResults !== undefined) {
            if (searchResults.length === 0) {
                if (inputEl !== undefined) {
                    if (inputEl.current !== undefined) {
                        if (inputEl.current !== null) {
                            if (inputEl.current.value !== undefined) {
                                if (inputEl.current.value !== null) {
                                    if (inputEl.current.value.length > 0) {
                                        if (isLoading === false) {
                                            shouldShow = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldUseScrollbar = function () {
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (currentResults.length > 5) {
            return true;
        }
        return false;
    };
    var clickUser = function (user) {
        props.onUserSelected(user);
        try {
            // @ts-ignore
            overlayTriggerEl.current.hide();
        }
        catch (_a) { }
        setMobileOpen(false);
        setCursor(-1);
        setSearchResults([]);
        inputEl.current.value = "";
    };
    var content = (React.createElement("div", { ref: popoverEl, className: "people-content theme-content-" + theme },
        React.createElement("div", { className: "people-header" },
            React.createElement("input", { type: "text", placeholder: "Search names or roles", onChange: function (event) {
                    setIsLoading(true);
                    getResults(event.target.value);
                }, onKeyDown: function (event) {
                    handleKeyDown(event);
                }, ref: inputEl }),
            React.createElement("div", { className: "icon" },
                theme === "default" ? React.createElement(FontAwesomeIcon, { icon: Icons.faSearch }) : null,
                theme === "dropdown" ? React.createElement("i", { className: "la la-search" }) : null)),
        React.createElement("div", { className: "people-list" },
            React.createElement("ul", { className: "list-unstyled" },
                React.createElement(DynamicScrollbar, { shouldShow: shouldUseScrollbar(), maxHeight: 200 },
                    isLoading === false &&
                        searchResults !== undefined &&
                        searchResults.map(function (rs, index) {
                            var _a;
                            return (React.createElement("li", { className: cursor === index ? "selected" : undefined, key: index, onMouseEnter: function () {
                                    setCursor(-1);
                                }, onClick: function () {
                                    clickUser(rs);
                                } },
                                React.createElement("div", { className: "pic" },
                                    React.createElement(OfficeProfilePicture, { uid: rs.Id, displayName: rs.DisplayName, size: theme === "default" ? "xs" : theme === "dropdown" ? "sm" : "xs" })),
                                React.createElement("div", { className: "name" },
                                    rs.DisplayName,
                                    rs.UserPrincipalName !== undefined && rs.UserPrincipalName !== null && rs.UserPrincipalName.indexOf("#EXT#") > -1 && React.createElement("span", { className: "guest-note" }, "(Guest)")),
                                React.createElement("div", { className: "job-title" }, rs.JobTitle),
                                props.includeGroups !== undefined && props.includeGroups ? React.createElement("div", { className: "principal-type type-" + ((_a = rs.Type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) }, rs.Type === "User" ? "U" : "G") : null));
                        }),
                    shouldShowSuggestions() && suggestedUsers !== undefined ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "suggested-members" }, props.suggestedTitle !== undefined ? props.suggestedTitle : "Suggested Members"),
                        suggestedUsers.map(function (rs, index) { return (React.createElement("li", { className: cursor === index ? "selected" : undefined, key: index, onMouseEnter: function () {
                                setCursor(-1);
                            }, onClick: function () {
                                clickUser(rs);
                            } },
                            React.createElement("div", { className: "pic" },
                                React.createElement(OfficeProfilePicture, { uid: rs.Id, displayName: rs.DisplayName, size: theme === "default" ? "xs" : theme === "dropdown" ? "sm" : "xs" })),
                            React.createElement("div", { className: "name" }, rs.DisplayName),
                            React.createElement("div", { className: "job-title" }, rs.JobTitle))); }))) : null,
                    shouldShowNoResult() ? React.createElement("li", { className: "no-results" }, "No results") : null,
                    isLoading ? (React.createElement("li", { className: "loader" },
                        React.createElement(AnimatedLoader, { size: "small", theme: "dark" }))) : null)))));
    var popover = (React.createElement(Popover, { id: "popover-basic", className: "people-picker theme-" + theme }, content));
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleClick = function (e) {
        var setHidden = false;
        if (popoverEl.current !== undefined) {
            if (popoverEl.current !== null) {
                if (popoverEl.current.contains !== undefined) {
                    if (popoverEl.current.contains !== null) {
                        if (popoverEl.current.contains(e.target)) {
                        }
                        else {
                            try {
                                setHidden = true;
                            }
                            catch (_a) { }
                        }
                    }
                }
            }
        }
        if (spanEl.current !== undefined) {
            if (spanEl.current !== null) {
                if (spanEl.current.contains !== undefined) {
                    if (spanEl.current.contains !== null) {
                        if (spanEl.current.contains(e.target)) {
                            setHidden = false;
                        }
                        else {
                            try {
                            }
                            catch (_b) { }
                        }
                    }
                }
            }
        }
        if (setHidden === true) {
            // @ts-ignore
            overlayTriggerEl.current.hide();
        }
    };
    if (props.userPopover !== undefined && props.userPopover === false) {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { onClick: function () {
                    setMobileOpen(!mobileOpen);
                }, ref: spanEl }, props.children),
            React.createElement(React.Fragment, null, mobileOpen ? content : null)));
    }
    else {
        return (React.createElement(React.Fragment, null, width < 769 ? (React.createElement(React.Fragment, null,
            React.createElement("span", { onClick: function () {
                    setMobileOpen(!mobileOpen);
                }, ref: spanEl }, props.children),
            React.createElement(React.Fragment, null, mobileOpen ? React.createElement("div", { className: "people-picker theme-" + theme }, content) : null))) : (React.createElement("span", { ref: spanEl },
            React.createElement(OverlayTrigger, { trigger: ["click"], onEnter: function () { return setIsOpen(true); }, onExit: function () { return setIsOpen(false); }, placement: theme === "default" ? "right-start" : theme === "dropdown" ? "bottom" : "right-start", overlay: popover, 
                //@ts-ignore
                ref: overlayTriggerEl }, props.children)))));
    }
    // return (
    //     <OverlayTrigger trigger={['click']} placement="right-start" overlay={popover} ref={overlayTriggerEl}>
    //         {props.children}
    //     </OverlayTrigger>
    // )
});
export default UserSearchDropdown;
