import * as React from "react";
import { Form } from "react-bootstrap";
import iconSP from "app/assets/img/office-icons/sp.png";
import iconTeams from "app/assets/img/office-icons/teams.png";
import iconViva from "app/assets/img/icon-viva-engage.svg";
var ReportFilterTemplateText = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        React.createElement("div", null,
            React.createElement("div", { className: "type-group", "data-pw": "type-group" },
                React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue === "team" ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, "team"); }, "data-pw": "type-team" },
                    React.createElement("div", { className: "image" },
                        React.createElement("img", { src: iconTeams }),
                        React.createElement("div", { className: "words" }, "Microsoft Team"))),
                React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue === "sharepoint-team" ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, "sharepoint-team"); }, "data-pw": "type-team-site" },
                    React.createElement("div", { className: "image" },
                        React.createElement("img", { src: iconSP }),
                        React.createElement("div", { className: "words" }, "Team Site"))),
                React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue === "sharepoint-comm" ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, "sharepoint-comm"); }, "data-pw": "type-comms-site" },
                    React.createElement("div", { className: "image" },
                        React.createElement("img", { src: iconSP }),
                        React.createElement("div", { className: "words" }, "Comms Site"))),
                React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue == "viva-engage" ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, "viva-engage"); } },
                    React.createElement("div", { className: "image" },
                        React.createElement("img", { src: iconViva }),
                        React.createElement("div", { className: "words" }, "Viva Engage")))))));
});
export default ReportFilterTemplateText;
