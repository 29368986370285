import { modals } from "@mantine/modals";
import { ShareHealthCheckModal } from "appv2/pages/beacon/health-check/health-check/share-health-check-modal/share-health-check-modal.component";
import { AddAnnotationModal } from "appv2/pages/beacon/health-check/tabs/health-check-additions-tab/add-annotation-modal.component";
import { DeleteAnnotationModal } from "appv2/pages/beacon/health-check/tabs/health-check-additions-tab/delete-annotation-modal.component";
import { DeleteHealthCheckModal } from "appv2/pages/beacon/health-checks/delete-health-check-modal.component";
import { AddCustomRecommendationModal } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-action-buttons-section/add-edit-custom-recommendation-modal/add-custom-recommendation-modal.component";
import { UpdateCustomRecommendationModal } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-action-buttons-section/add-edit-custom-recommendation-modal/update-custom-recommendation-modal.component";
import { DeleteCustomRecommendationModal } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-action-buttons-section/delete-recommendation-modal.component";
import { ResolveRecommendationModal } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-resolve-modal/recommendation-card-resolve-modal.component";
import { RecommendationViewDetailsModal } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-view-details-modal/recommendation-card-view-details-modal.component";
import { RecommendationShareSuggestionModal } from "appv2/pages/beacon/recommendations/recommendation-footer/recommendation-share-suggestion/recommendation-share-suggestion-modal/recommendation-share-suggestion-modal.component";
import { AddTenantManagersModal } from "appv2/pages/partner-tools/tenant/tenant-details/add-tenant-managers-modal.component";
import { DisconnectTenantModal } from "appv2/pages/settings/partners/disconnect-tenant-modal.component";
/**
 * Defines the available orchestry modal types and their corresponding components.
 */
export var orchestryModalDefinitions = {
    resolveRecommendationModal: ResolveRecommendationModal,
    recommendationViewDetailsModal: RecommendationViewDetailsModal,
    recommendationShareSuggestionModal: RecommendationShareSuggestionModal,
    deleteHealthCheckModal: DeleteHealthCheckModal,
    disconnectTenantModal: DisconnectTenantModal,
    addCustomRecommendationModal: AddCustomRecommendationModal,
    updateCustomRecommendationModal: UpdateCustomRecommendationModal,
    deleteCustomRecommendationModal: DeleteCustomRecommendationModal,
    shareHealthCheckModal: ShareHealthCheckModal,
    addAnnotationModal: AddAnnotationModal,
    deleteAnnotationModal: DeleteAnnotationModal,
    addTenantManagersModal: AddTenantManagersModal
};
/**
 * Wrapper function to open an orchestry modal.
 * This enforce the modal key to be one of the defined modal keys.
 * @param modalKey The modal key
 * @param innerProps The inner props
 * @param size The size of the modal
 */
export var openOrchestryModal = function (modalKey, innerProps, size) {
    modals.openContextModal({ modal: modalKey, innerProps: innerProps, size: size });
};
