import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { InstallerMode } from "app/components/installer/installer-wizard";
import OrchestryCheckUpgrade from "app/components/orchestry-context/orchestry-check-upgrade";
import { ServiceAppPermissionConfiguration, TenantLicenseStatus } from "app/store/tenants";
import { OrchestryLicenseBadge } from "appv2/components/orchestry-license-badge/orchestry-license-badge.component";
import * as React from "react";
import { useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./settings-installation-status.scss";
var SettingsInstallationStatus = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(false), showUpdateModal = _a[0], setShowUpdateModal = _a[1];
    var _b = React.useState(false), showUpgradeDowngradeModal = _b[0], setShowUpgradeDowngradeModal = _b[1];
    var _c = React.useState(InstallerMode.LicenseUpgradeOrchestryTrial), ugradeDowngradeInstallerMode = _c[0], setUgradeDowngradeInstallerMode = _c[1];
    var _d = React.useState(false), testModeActivated = _d[0], setTestModeActivated = _d[1];
    var _e = React.useState(false), canUpgradeToFullOrchestry = _e[0], setCanUpgradeToFullOrchestry = _e[1];
    var _f = React.useState(""), input = _f[0], setInput = _f[1];
    var cheatCode = "orchestrytest";
    React.useEffect(function () {
        var handleKeyPress = function (event) {
            setInput(function (prevInput) {
                var newInput = prevInput + event.key;
                if (newInput.includes(cheatCode)) {
                    setTestModeActivated(true);
                    return ""; // Reset the input
                }
                return newInput;
            });
        };
        window.addEventListener("keypress", handleKeyPress);
        return function () {
            window.removeEventListener("keypress", handleKeyPress);
        };
    }, []);
    React.useEffect(function () {
        if (orchestryContext.ExtendedProperties.CanUpgradeToFullOrchestry !== undefined && orchestryContext.ExtendedProperties.CanUpgradeToFullOrchestry !== null) {
            setCanUpgradeToFullOrchestry(orchestryContext.ExtendedProperties.CanUpgradeToFullOrchestry);
        }
    }, [orchestryContext]);
    var addDotsToVersion = function (version) {
        var splitVersion = ("" + version).split("");
        return splitVersion.join(".");
    };
    var isForceUpdate = function () {
        return ((orchestryContext !== null &&
            orchestryContext !== undefined &&
            orchestryContext.LastConsentedVersion !== null &&
            orchestryContext.LastConsentedVersion !== undefined &&
            orchestryContext.LastConsentedVersion >= orchestryContext.OrchestryAppPermissionVersion) === false ||
            (orchestryContext !== null &&
                orchestryContext !== undefined &&
                orchestryContext.ServiceAppPermissionConfig !== null &&
                ((orchestryContext.ServiceAppPermissionConfig === ServiceAppPermissionConfiguration.OrchestryReadWriteAll &&
                    orchestryContext.ServiceAppLastConsentedVersion !== null &&
                    orchestryContext.ServiceAppLastConsentedVersion !== undefined &&
                    orchestryContext.ServiceAppLastConsentedVersion >= orchestryContext.ServiceAppPermissionVersion === false) ||
                    (orchestryContext.ServiceAppPermissionConfig === ServiceAppPermissionConfiguration.OrchestryReadAll &&
                        orchestryContext.ServiceAppReadAllLastConsentedVersion !== null &&
                        orchestryContext.ServiceAppReadAllLastConsentedVersion !== undefined &&
                        orchestryContext.ServiceAppReadAllLastConsentedVersion >= orchestryContext.ServiceAppReadAllPermissionVersion === false))) ||
            (orchestryContext !== null &&
                orchestryContext !== undefined &&
                orchestryContext.LastInstalledVersion !== null &&
                orchestryContext.LastInstalledVersion !== undefined &&
                orchestryContext.LastInstalledVersion >= orchestryContext.OrchestryAppInstallVersion) === false);
    };
    var openUpgradeDowngradeModal = function (installerMode) {
        setUgradeDowngradeInstallerMode(installerMode);
        setShowUpgradeDowngradeModal(true);
    };
    return (React.createElement("div", { className: "settings-installation-status" },
        React.createElement(Portlet, { className: "portlet-bold-text top-left" },
            React.createElement(PortletHeader, { title: "Orchestry Install Status", toolbar: React.createElement("div", { className: "license-status" },
                    React.createElement(OrchestryLicenseBadge, { tenantLicenseStatus: orchestryContext.LicenseStatus })) }),
            React.createElement(PortletBody, null,
                React.createElement("div", { className: "install-item" },
                    React.createElement("div", { className: "status-icon" + (orchestryContext.IsUpToDate === false ? " needs-update" : "") }, orchestryContext.IsUpToDate ? React.createElement("i", { className: "fa fa-check" }) : React.createElement("i", { className: "fa fa-times" })),
                    React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "title" }, orchestryContext.IsUpToDate ? "You're running the latest version of Orchestry!" : "Your Orchestry is out of date. Please update!"),
                        React.createElement("div", { className: "description" }))),
                React.createElement("div", { className: "install-item-small" },
                    React.createElement("div", { className: "status-icon" },
                        React.createElement("i", { className: "fas fa-key" })),
                    React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "title" }, orchestryContext !== undefined && orchestryContext.LastConsentedVersion !== null && orchestryContext.LastConsentedVersion >= orchestryContext.OrchestryAppPermissionVersion
                            ? "You've consented to the latest primary Orchestry app version"
                            : "Your primary Orchestry app permissions are out of date"),
                        React.createElement("div", { className: "description" }, orchestryContext !== undefined && orchestryContext.LastConsentedVersion !== null && orchestryContext.LastConsentedVersion >= orchestryContext.OrchestryAppPermissionVersion ? (React.createElement(React.Fragment, null,
                            "primary-app-consent-version ",
                            React.createElement("span", { className: "version-highlight" },
                                "p.",
                                addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.OrchestryAppPermissionVersion)))) : (React.createElement(React.Fragment, null,
                            "primary-app-consent-version",
                            " ",
                            React.createElement("span", { className: "version-highlight red" },
                                "p.",
                                (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastConsentedVersion) !== null ? addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastConsentedVersion) : "0.0.0.0.0"),
                            " ",
                            React.createElement("span", { className: "raquo" }, "\u00BB"),
                            " ",
                            React.createElement("span", { className: "version-highlight green" },
                                "p.",
                                addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.OrchestryAppPermissionVersion))))))),
                React.createElement("div", { className: "install-item-small" },
                    React.createElement("div", { className: "status-icon" },
                        React.createElement("i", { className: "fas fa-key" })),
                    orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadWriteAll ? (React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "title" }, orchestryContext !== undefined &&
                            orchestryContext.ServiceAppLastConsentedVersion !== null &&
                            orchestryContext.ServiceAppLastConsentedVersion >= orchestryContext.ServiceAppPermissionVersion
                            ? "You've consented to the latest service app version"
                            : "Your service app permissions are out of date"),
                        React.createElement("div", { className: "description" },
                            orchestryContext !== undefined &&
                                orchestryContext.ServiceAppLastConsentedVersion !== null &&
                                orchestryContext.ServiceAppLastConsentedVersion >= orchestryContext.ServiceAppPermissionVersion ? (React.createElement(React.Fragment, null,
                                "service-app-consent-version ",
                                React.createElement("span", { className: "version-highlight" },
                                    "sa.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                "service-app-consent-version",
                                " ",
                                React.createElement("span", { className: "version-highlight red" },
                                    "sa.",
                                    orchestryContext.ServiceAppLastConsentedVersion !== null ? addDotsToVersion(orchestryContext.ServiceAppLastConsentedVersion) : "0.0.0.0.0"),
                                " ",
                                React.createElement("span", { className: "raquo" }, "\u00BB"),
                                " ",
                                React.createElement("span", { className: "version-highlight green" },
                                    "sa.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppPermissionVersion)))),
                            React.createElement("span", { className: "service-pill" }, "Read & Write")))) : (React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "title" }, orchestryContext !== undefined &&
                            orchestryContext.ServiceAppReadAllLastConsentedVersion !== null &&
                            orchestryContext.ServiceAppReadAllLastConsentedVersion >= orchestryContext.ServiceAppReadAllPermissionVersion
                            ? "You've consented to the latest service app version"
                            : "Your service app permissions are out of date"),
                        React.createElement("div", { className: "description" },
                            orchestryContext !== undefined &&
                                orchestryContext.ServiceAppReadAllLastConsentedVersion !== null &&
                                orchestryContext.ServiceAppReadAllLastConsentedVersion >= orchestryContext.ServiceAppReadAllPermissionVersion ? (React.createElement(React.Fragment, null,
                                "service-app-consent-version ",
                                React.createElement("span", { className: "version-highlight" },
                                    "sa.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppReadAllPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                "service-app-consent-version",
                                " ",
                                React.createElement("span", { className: "version-highlight red" },
                                    "sa.",
                                    orchestryContext.ServiceAppReadAllLastConsentedVersion !== null ? addDotsToVersion(orchestryContext.ServiceAppReadAllLastConsentedVersion) : "0.0.0.0.0"),
                                " ",
                                React.createElement("span", { className: "raquo" }, "\u00BB"),
                                " ",
                                React.createElement("span", { className: "version-highlight green" },
                                    "sa.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppReadAllPermissionVersion)))),
                            React.createElement("span", { className: "service-pill" }, "Read-Only"))))),
                React.createElement("div", { className: "install-item-small" },
                    React.createElement("div", { className: "status-icon" },
                        React.createElement("i", { className: "fas fa-cloud-download-alt" })),
                    React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "title" }, orchestryContext !== undefined && orchestryContext.LastInstalledVersion !== null && orchestryContext.LastInstalledVersion >= orchestryContext.OrchestryAppInstallVersion
                            ? "You've installed the latest version of Orchestry"
                            : "Your Orchestry installation is out of date"),
                        React.createElement("div", { className: "description" }, orchestryContext !== undefined && orchestryContext.LastInstalledVersion !== null && orchestryContext.LastInstalledVersion >= orchestryContext.OrchestryAppInstallVersion ? (React.createElement(React.Fragment, null,
                            "install-version ",
                            React.createElement("span", { className: "version-highlight" },
                                "i.",
                                addDotsToVersion(orchestryContext.OrchestryAppInstallVersion)))) : (React.createElement(React.Fragment, null,
                            "install-version",
                            " ",
                            React.createElement("span", { className: "version-highlight red" },
                                "i.",
                                (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion) !== null && (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion) !== undefined
                                    ? addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion)
                                    : "0.0.0.0.0"),
                            " ",
                            React.createElement("span", { className: "raquo" }, "\u00BB"),
                            " ",
                            React.createElement("span", { className: "version-highlight green" },
                                "i.",
                                addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.OrchestryAppInstallVersion))))))),
                React.createElement("div", { className: "install-item-small" },
                    React.createElement("div", { className: "status-icon" }),
                    React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "description buttons" },
                            React.createElement("div", { className: "btn btn-success force-update", onClick: function () { return setShowUpdateModal(true); } },
                                React.createElement("i", { className: "far fa-arrow-alt-circle-up" }),
                                isForceUpdate() ? "Update" : "Force Update"),
                            React.createElement(OrchestryCheckUpgrade, { installMode: InstallerMode.Update, setOverrideShow: setShowUpdateModal, overrideShow: showUpdateModal, forceUpdate: isForceUpdate() }),
                            testModeActivated ||
                                (orchestryContext.HasHadBeaconLicenseBefore === true && orchestryContext.LicenseStatus === TenantLicenseStatus.OrchestryTrial) ||
                                (orchestryContext.HasHadBeaconLicenseBefore === true &&
                                    orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadWriteAll &&
                                    orchestryContext.LicenseStatus === TenantLicenseStatus.Beacon) ? (React.createElement("div", { className: "btn btn-primary force-update", onClick: function () { return openUpgradeDowngradeModal(InstallerMode.LicenseDowngradeBeacon); } },
                                React.createElement("i", { className: "far fa-arrow-alt-circle-down" }),
                                orchestryContext.LicenseStatus === TenantLicenseStatus.Beacon ? "Downgrade to Read-Only" : "Downgrade to Beacon")) : null,
                            testModeActivated ||
                                (orchestryContext.LicenseStatus === TenantLicenseStatus.Beacon &&
                                    orchestryContext.TrialExpires !== null &&
                                    new Date(orchestryContext.TrialExpires) > new Date() &&
                                    canUpgradeToFullOrchestry == false) ? (React.createElement("div", { className: "btn btn-primary force-update", onClick: function () { return openUpgradeDowngradeModal(InstallerMode.LicenseUpgradeOrchestryTrial); } },
                                React.createElement("i", { className: "far fa-arrow-alt-circle-up" }),
                                "Upgrade to Orchestry Trial")) : null,
                            testModeActivated || (orchestryContext.LicenseStatus === TenantLicenseStatus.Beacon && canUpgradeToFullOrchestry) ? (React.createElement("div", { className: "btn btn-primary force-update", onClick: function () { return openUpgradeDowngradeModal(InstallerMode.LicenseUpgradeOrchestryFull); } },
                                React.createElement("i", { className: "far fa-arrow-alt-circle-up" }),
                                "Upgrade to Orchestry")) : null,
                            React.createElement(OrchestryCheckUpgrade, { installMode: ugradeDowngradeInstallerMode, setOverrideShow: setShowUpgradeDowngradeModal, overrideShow: showUpgradeDowngradeModal }))))))));
});
export default SettingsInstallationStatus;
