import InsightsProvisioned from "app/components/common/insights-provisioned";
import { Portlet, PortletHeader } from "app/components/common/portlet";
import RequestList from "app/components/requests/request-list";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import "./home-admin.scss";
var HomeAdmin = React.memo(function () {
    var intl = useIntl();
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var isUser = currentUserHasRole(OrchRole.User); // A beacon user is not a normal user and does not have access to the home page.
    if (!isUser) {
        return null; //A beacon user is not a normal user and does not have access to the home page.
    }
    return (React.createElement("div", { className: "home-admin fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 9 },
                React.createElement(RequestList, null)),
            React.createElement(Col, { md: 3 },
                React.createElement(Portlet, { className: "" },
                    React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.home.important_links", defaultMessage: "Important Links" }) }),
                    React.createElement("ul", { className: "ob-links-list" },
                        React.createElement("li", null,
                            React.createElement("a", { href: "https://success.orchestry.com/knowledge", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "list-type la la-book-open" }),
                                intl.formatMessage({
                                    id: "page.home.important_links.knowledge_base",
                                    defaultMessage: "Knowledge Base"
                                }),
                                React.createElement("i", { className: "arrow fa fa-chevron-right" }))),
                        React.createElement("li", null,
                            React.createElement("a", { href: "https://success.orchestry.com/tickets-view", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "list-type la la-ticket-alt" }),
                                intl.formatMessage({
                                    id: "page.home.important_links.submit_a_ticket",
                                    defaultMessage: "Submit a Tickt"
                                }),
                                React.createElement("i", { className: "arrow fa fa-chevron-right" }))),
                        React.createElement("li", null,
                            React.createElement("a", { href: "http://success.orchestry.com/support/solutions/61000132844", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "list-type la la-book-reader" }),
                                intl.formatMessage({
                                    id: "page.home.important_links.onboarding_guide",
                                    defaultMessage: "Onboarding Guide"
                                }),
                                React.createElement("i", { className: "arrow fa fa-chevron-right" }))),
                        React.createElement("li", null,
                            React.createElement("a", { href: "https://orchestry.com/", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "list-type la la-globe-europe" }),
                                intl.formatMessage({
                                    id: "page.home.important_links.orchestry_website",
                                    defaultMessage: "Orchestry Website"
                                }),
                                React.createElement("i", { className: "arrow fa fa-chevron-right" }))),
                        React.createElement("li", null,
                            React.createElement("a", { href: "https://twitter.com/OrchestrySoft", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "list-type fab la-twitter" }),
                                intl.formatMessage({
                                    id: "page.home.important_links.orchestry_twitter",
                                    defaultMessage: "Orchestry Twitter"
                                }),
                                React.createElement("i", { className: "arrow fa fa-chevron-right" }))))),
                React.createElement(InsightsProvisioned, null)))));
});
export default HomeAdmin;
