import { Card, Grid } from "@mantine/core";
import React from "react";
import { OrchestrySettingsPageControlInformation } from "./orchestry-settings-page-control-information/orchestry-settings-page-control-information.component";
export var OrchestrySettingsPageControl = function (_a) {
    var title = _a.title, description = _a.description, children = _a.children, isLoading = _a.isLoading;
    return (React.createElement(Grid, { grow: true, columns: 10, gutter: 48, align: "center" },
        React.createElement(Grid.Col, { miw: 300, span: 5, mb: "24px" },
            React.createElement(OrchestrySettingsPageControlInformation, { isLoading: isLoading, title: title }, description)),
        React.createElement(Grid.Col, { miw: 300, span: 5 },
            React.createElement(Card, { p: 0, pb: 16, style: { overflow: "visible" } }, children))));
};
