import { Table } from "@mantine/core";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { RecommendationCardBadgeGroup } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-badge-group";
import { RecommendationsPriorityIndicator } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-control-section/recommendation-priority-indicator.component";
import React from "react";
import { useIntl } from "react-intl";
export var HealthCheckRecommendationRow = function (_a) {
    var _b, _c, _d;
    var recommendation = _a.recommendation, isLoading = _a.isLoading;
    var _e = recommendation.RecommendationRecord, Visibility = _e.Visibility, Definition_Priority = _e.Definition_Priority, DescriptionKey = _e.DescriptionKey, Description = _e.Description, DescriptionXValue = _e.DescriptionXValue, DescriptionYValue = _e.DescriptionYValue, DescriptionZValue = _e.DescriptionZValue;
    var intl = useIntl();
    var descriptionXLocalized = (_b = DescriptionXValue === null || DescriptionXValue === void 0 ? void 0 : DescriptionXValue.toLocaleString()) !== null && _b !== void 0 ? _b : "";
    var descriptionYLocalized = (_c = DescriptionYValue === null || DescriptionYValue === void 0 ? void 0 : DescriptionYValue.toLocaleString()) !== null && _c !== void 0 ? _c : "";
    var descriptionZLocalized = (_d = DescriptionZValue === null || DescriptionZValue === void 0 ? void 0 : DescriptionZValue.toLocaleString()) !== null && _d !== void 0 ? _d : "";
    return (React.createElement(Table.Tr, { pt: 8, pb: 8, style: { display: "flex", justifyContent: "space-between", alignItems: "center" } },
        React.createElement(Table.Td, { style: { width: "150px" } },
            React.createElement(RecommendationsPriorityIndicator, { isLoading: isLoading, priority: Definition_Priority, visibility: Visibility })),
        React.createElement(Table.Td, { style: { flex: 1, display: "flex", justifyContent: "flex-start" } },
            React.createElement(OrchestryHighlight, { isLoading: isLoading, c: "text-primary.0", fz: "14px", fw: 400, lh: "20px", highlight: [descriptionXLocalized, descriptionYLocalized, descriptionZLocalized], highlightStyles: {
                    flex: 1,
                    padding: 0,
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    backgroundColor: "var(--mantine-color-text-primary-0)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent"
                } }, DescriptionKey
                ? intl.formatMessage({ id: DescriptionKey, defaultMessage: "Recommendation" }, { x: descriptionXLocalized, y: descriptionYLocalized, z: descriptionZLocalized })
                : Description)),
        React.createElement(Table.Td, { style: { display: "flex", justifyContent: "flex-start", marginTop: "3px", maxWidth: "700px", flexDirection: "row-reverse" } },
            React.createElement(RecommendationCardBadgeGroup, { isLoading: isLoading, recommendation: recommendation }))));
};
