import { LifecyclePolicyListActionTypes } from "app/store/lifecycle-policies/list/types";
import { AsyncCallType } from "app/store/async/types";
export var lifecyclePolicyListActionMap = [
    {
        actionType: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/LifecyclePolicies",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/LifecyclePolicies",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/LifecyclePolicies",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load,
        callType: AsyncCallType.List,
        // stateLocationForArray: "lifecyclePolicyListState.items",
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicies?policyType={policyType}",
        responseDataType: []
    },
    {
        actionType: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Refresh,
        callType: AsyncCallType.Refresh,
        // stateLocationForArray: "lifecyclePolicyListState.items",
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicies?policyType={policyType}",
        responseDataType: []
    }
];
