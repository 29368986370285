import * as React from "react";
import "./lifecycle-details-tab-lifecycle.scss";
import { useSelector } from "react-redux";
import { ReportSearchSlotName } from "app/store/lifecycle-report";
import { Row, Col } from "react-bootstrap";
import LifecyclePolicyAssignments from "app/components/insights/workspaces/report-details/components/lifecycle-policy-assignments";
import LifecycleHistory from "app/components/insights/workspaces/report-details/components/lifecycle-history";
import LifecycleLogs from "app/components/insights/workspaces/report-details/components/lifecycle-logs";
var LifecycleDetailsTabLifecycle = React.memo(function (_a) {
    var siteId = _a.siteId, currentHash = _a.currentHash;
    var histories = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.history; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    return (React.createElement("div", { className: "lifecycle-details-tab-lifecycle" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 8 },
                React.createElement(LifecycleHistory, { type: "Creation", histories: histories }),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 12 },
                        React.createElement(LifecycleHistory, { type: "Archival", histories: histories, secondStageEnabled: currentPolicy.SecondStage_Enabled || (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalSecondStageArchivedDate) != null }))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 12 },
                        React.createElement(LifecycleHistory, { type: "Guest", histories: histories })))),
            React.createElement(Col, { md: 4 },
                React.createElement(LifecyclePolicyAssignments, { siteId: siteId, Slot: ReportSearchSlotName.WorkspaceInsightsSlot }),
                React.createElement(LifecycleLogs, { siteId: siteId })))));
});
export default LifecycleDetailsTabLifecycle;
