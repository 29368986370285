export var LifecycleGuestCurrentActionTypes;
(function (LifecycleGuestCurrentActionTypes) {
    LifecycleGuestCurrentActionTypes["LifecycleGuest_Current_Load"] = "LifecycleGuest_Current_Load";
    LifecycleGuestCurrentActionTypes["LifecycleGuest_Current_Load_With_CosmosItem"] = "LifecycleGuest_Current_Load_With_CosmosItem";
    LifecycleGuestCurrentActionTypes["LifecycleGuest_Current_Load_CosmosItem"] = "LifecycleGuest_Current_Load_CosmosItem";
    LifecycleGuestCurrentActionTypes["LifecycleGuest_Current_Load_CosmosItem_From_Memory"] = "LifecycleGuest_Current_Load_CosmosItem_From_Memory";
    LifecycleGuestCurrentActionTypes["LifecycleGuest_Current_Load_History"] = "LifecycleGuest_Current_Load_History";
    LifecycleGuestCurrentActionTypes["LifecycleGuest_Current_Clear"] = "LifecycleGuest_Current_Clear"; //,
    // LifecycleGuest_Current_Load_HistoryPage = "LifecycleGuest_Current_Load_HistoryPage"
})(LifecycleGuestCurrentActionTypes || (LifecycleGuestCurrentActionTypes = {}));
