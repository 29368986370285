import * as React from "react";
import "./group-item.scss";
import GroupProfilePicture from "app/components/common/group-profile-picture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
var GroupItem = React.memo(function (_a) {
    var group = _a.group, showBadge = _a.showBadge, onRemove = _a.onRemove;
    var remove = function (group) {
        if (onRemove !== undefined) {
            onRemove(group);
        }
    };
    return (React.createElement("div", { className: "group-item" + (onRemove === undefined ? " hide-remove" : ""), key: group.GUID },
        React.createElement("div", { className: "pic" },
            showBadge !== undefined && showBadge ? React.createElement("div", { className: "principal-type type-group" }, "G") : null,
            React.createElement(GroupProfilePicture, { uid: group.GUID, displayName: group.DisplayName })),
        React.createElement("span", null, group.DisplayName),
        onRemove !== undefined ? (React.createElement("div", { className: "remove", onClick: function () {
                remove(group);
            } },
            React.createElement(FontAwesomeIcon, { icon: faTimes }))) : null));
});
export default GroupItem;
