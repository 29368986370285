import * as React from "react";
import "./lifecycle-settings.scss";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as LifecyclePoliciesListStore from "app/store/lifecycle-policies/list";
import { SubtitleButton } from "app/components/common/subtitle";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { CardTeam, CardTeamSite, CardCommunicationSite } from "app/components/lifecycle/workspaces/settings/cards/card-policy-selection";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import FormValidationContext, { FormValidatorRollup } from "app/components/common/form-validation";
import CardETLLogs from "app/components/lifecycle/workspaces/settings/cards/card-etl-logs";
import CardReportMetadataCols from "app/components/lifecycle/workspaces/settings/cards/card-report-metadata-cols";
import CardResetStatusTool from "app/components/lifecycle/workspaces/settings/cards/card-reset-status-tool";
import CardInactivitySettings from "app/components/lifecycle/workspaces/settings/cards/card-inactivity-settings";
import CardNightlyWorkspaceDataExport from "app/components/lifecycle/workspaces/settings/cards/card-nightly-workspace-data-export";
import CardCrawlSettings from "app/components/lifecycle/workspaces/settings/cards/card-crawl-settings";
import PortletAlert from "app/components/common/portlet-alert";
import CardFailedArchivalReport from "./cards/card-failed-archival-report";
var LifecycleSettings = React.memo(function () {
    var dispatch = useDispatch();
    var lifecyclePolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState(false), isValid = _a[0], setIsValid = _a[1];
    var _b = React.useState("team"), hash = _b[0], setHash = _b[1];
    var _c = React.useState("team"), currentTabs = _c[0], setCurrentTabs = _c[1];
    React.useEffect(function () {
        dispatch(LifecyclePoliciesListStore.Load("Archival"));
        dispatch(TenantConfigurationCurrentStore.Load());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var _d = React.useState({
        title: "Policies",
        titleLink: "/lifecycle/policies/archival/",
        subTitle: "Edit Renewal / Archival Policy",
        buttonCancelText: "Cancel",
        buttonSaveText: "Save",
        onSave: function () { },
        onClear: function () { }
        //onStepsUpdated: updateStepsInConfig
    }), tabsConfig = _d[0], setTabsConfig = _d[1];
    React.useEffect(function () {
        if (window.location.hash !== "")
            setHash(window.location.hash);
    }, []);
    var save = function () {
        dispatch(TenantConfigurationCurrentStore.UpdateSaveToServer());
        // if (areAllTabsDone()) {
        //    setHasSubmitted(true);
        //    props.config.onSave();
        // }
    };
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
    };
    return (React.createElement("div", { className: "lifecycle-settings fadein-500ms", "data-pw": "lifecycle-settings" },
        React.createElement(PageHeader.SetLeftContent, { title: "Lifecycle Management Settings" }),
        React.createElement(PageHeader.SetRightContent, null, isValid === true ? (React.createElement("div", { style: { display: "inline-block" } },
            React.createElement(SubtitleButton, { title: "Save", variant: "success", onClick: function () { return save(); } }))) : (React.createElement("div", { style: { display: "inline-block" } },
            React.createElement(SubtitleButton, { title: "Save", variant: "secondary" })))),
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setIsValid(isValid);
            }, UniqueIdentifier: "lifecycle-settings" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 6 },
                    React.createElement(Tab.Container, { id: "lifecycle-tabs", defaultActiveKey: hash },
                        React.createElement(Portlet, { "data-pw": "portlet" },
                            React.createElement(PortletHeader, { title: "Default Lifecycle Settings" }),
                            React.createElement(PortletBody, null,
                                React.createElement("p", { className: "ptext", "data-pw": "settings-description" }, "You can specify default actions and policies for Teams, Groups and Sites created outside of Orchestry. For Orchestry Workspaces please configure this from within the Workspace Template section."),
                                React.createElement(PortletAlert, { size: "small", type: "warning", icon: "alert", message: "Any assignment of policies will not be reflected in the workspace reports until after tonight's crawl." }),
                                React.createElement("div", { className: "nav nav-tabs nav-tabs-line ", "data-pw": "nav-tabs" },
                                    React.createElement(Nav, { as: "ul" }, lifecyclePolicies === undefined ||
                                        currentTenantConfiguration === undefined ||
                                        (currentTenantConfiguration !== undefined && currentTenantConfiguration.GUID === undefined) ? (React.createElement("div", { className: "skeleton-nav-item", style: { marginBottom: "15px" } },
                                        React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 20 }))) : (React.createElement(React.Fragment, null,
                                        React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                                setHashAnchor("team");
                                            }, key: "team", "data-pw": "team-tab" },
                                            React.createElement(Nav.Link, { eventKey: "team" },
                                                React.createElement("span", null, "Teams"),
                                                React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: "lifecycle-settings", Category: "team" }))),
                                        React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                                setHashAnchor("team-site");
                                            }, key: "team-site", "data-pw": "team-site-tab" },
                                            React.createElement(Nav.Link, { eventKey: "team-site" },
                                                React.createElement("span", null, "Team Sites / Viva Engage Communities"),
                                                React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: "lifecycle-settings", Category: "team-site" }))),
                                        React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                                setHashAnchor("comm-site");
                                            }, key: "comm-site", "data-pw": "comm-site-tab" },
                                            React.createElement(Nav.Link, { eventKey: "comm-site" },
                                                React.createElement("span", null, "Communication Sites"),
                                                React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: "lifecycle-settings", Category: "comm-site" }))))))),
                                lifecyclePolicies === undefined ||
                                    lifecyclePolicies === null ||
                                    currentTenantConfiguration === undefined ||
                                    currentTenantConfiguration === null ||
                                    (currentTenantConfiguration !== undefined && currentTenantConfiguration.GUID === undefined) ? (React.createElement(Row, null,
                                    React.createElement(Col, { md: 12 },
                                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 100 })))) : (React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "team", key: "team" },
                                        React.createElement(CardTeam, null)),
                                    React.createElement(Tab.Pane, { eventKey: "team-site", key: "team-site" },
                                        React.createElement(CardTeamSite, null)),
                                    React.createElement(Tab.Pane, { eventKey: "comm-site", key: "comm-site" },
                                        React.createElement(CardCommunicationSite, null))))))),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(CardInactivitySettings, null)),
                        React.createElement(Col, null,
                            React.createElement(CardFailedArchivalReport, null))),
                    React.createElement(CardReportMetadataCols, null)),
                React.createElement(Col, null,
                    React.createElement(CardCrawlSettings, null),
                    React.createElement(CardNightlyWorkspaceDataExport, null),
                    React.createElement(CardResetStatusTool, null),
                    React.createElement(CardETLLogs, null))))));
});
export default LifecycleSettings;
