import * as React from "react";
import "./request-status.scss";
import FontIcon from "app/components/common/font-icon";
import { useIntl } from "react-intl";
var RequestStatus = React.memo(function (props) {
    var intl = useIntl();
    var statuses = [
        {
            icon: "check-circle",
            color: "#2DA02C",
            text: intl.formatMessage({ id: "page.request_status.label.created", defaultMessage: "Created" }),
            statusValue: "Created"
        },
        {
            icon: "exclamation-circle",
            color: "#D87A00",
            text: intl.formatMessage({ id: "page.request_status.label.approval_required", defaultMessage: "Approval Required" }),
            statusValue: "ApprovalRequired"
        },
        {
            icon: "check-circle",
            color: "#2DA02C",
            text: intl.formatMessage({ id: "page.request_status.label.approved", defaultMessage: "Approved" }),
            statusValue: "Approved"
        },
        {
            icon: "info-circle",
            color: "#FF0000",
            text: intl.formatMessage({ id: "page.request_status.label.approval_rejected", defaultMessage: "Approval rejected" }),
            statusValue: "Rejected"
        },
        {
            icon: "thumbs-up",
            color: "#2DA02C",
            text: intl.formatMessage({ id: "page.request_status.label.ready_to_provision", defaultMessage: "Ready to provision" }),
            statusValue: "ReadyToProvision"
        },
        {
            icon: "spinner",
            color: "#1084C6",
            text: intl.formatMessage({ id: "page.request_status.label.provisioning", defaultMessage: "Provisioning" }),
            statusValue: "Provisioning"
        },
        {
            icon: "spinner",
            color: "#1084C6",
            text: intl.formatMessage({ id: "page.request_status.label.retrying", defaultMessage: "Retrying" }),
            statusValue: "Retry"
        },
        {
            icon: "check-circle",
            color: "#2DA02C",
            text: intl.formatMessage({ id: "page.request_status.label.provisioned", defaultMessage: "Provisioned" }),
            statusValue: "Provisioned"
        },
        {
            icon: "times-circle",
            color: "#FF0000",
            text: intl.formatMessage({ id: "page.request_status.label.failed", defaultMessage: "Failed" }),
            statusValue: "Failed"
        },
        {
            icon: "circle",
            color: "rgb(136, 136, 136)",
            text: intl.formatMessage({ id: "page.request_status.label.archived", defaultMessage: "Archived" }),
            statusValue: "Archived"
        }
    ];
    var getRequestTextAndIcon = function () {
        var currentStatus = statuses.filter(function (s) { return (s.statusValue === props.request.Status ? true : false); })[0];
        if (currentStatus !== undefined) {
            if (props.style === undefined || (props.style !== undefined && props.style === "icon")) {
                return (React.createElement("div", { className: "request-status", style: { color: currentStatus.color } },
                    React.createElement("div", { className: "status-icon" },
                        React.createElement(FontIcon, { iconString: currentStatus.icon, color: currentStatus.color })),
                    React.createElement("div", { className: "status-text", "data-pw": "request-status" }, currentStatus.text)));
            }
            else {
                return (React.createElement("div", { className: "request-status background-box", style: { backgroundColor: currentStatus.color, color: currentStatus.color } },
                    React.createElement("div", { className: "status-text" }, currentStatus.text)));
            }
        }
    };
    return React.createElement(React.Fragment, null, getRequestTextAndIcon());
});
export default RequestStatus;
