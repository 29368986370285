import iconSP from "app/assets/img/icon-sharepoint.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import DisableArea from "app/components/common/disable-area";
import FormOfficeAssetPicker from "app/components/common/form-office-asset-picker";
import FormToggleControl from "app/components/common/form-toggle-control";
import { FormValidator } from "app/components/common/form-validation";
import InfoToolTip from "app/components/common/infotooltip";
import PortletAlert from "app/components/common/portlet-alert";
import SharePointService from "app/services/sharepoint";
import TeamsService from "app/services/teams";
import * as TemplateCurrentStore from "app/store/templates/current";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./tab-live-template.scss";
var TabLiveTemplate = React.memo(function (props, state) {
    var dispatch = useDispatch();
    var _a = React.useState(), availableLists = _a[0], setAvailableLists = _a[1];
    var _b = React.useState(false), isTemplateSelected = _b[0], setIsTemplateSelected = _b[1];
    var _c = React.useState(), teamSPUrl = _c[0], setTeamSPUrl = _c[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        if (props.currentTemplate.LiveTemplateSiteCloneContentInLibs !== undefined && props.currentTemplate.LiveTemplateSiteCloneContentInLibs === true) {
            var assetObject = null;
            var lookingUpTeamLists = false;
            if (props.currentTemplate.LiveTemplateTeamID !== undefined && props.currentTemplate.LiveTemplateTeamID !== null && props.currentTemplate.LiveTemplateTeamID !== "") {
                //@ts-ignore
                assetObject = JSON.parse(props.currentTemplate.LiveTemplateTeamID);
                lookingUpTeamLists = true;
                if (assetObject !== null) {
                    SharePointService.GetAllListsFromWeb("", assetObject.GUID).then(function (data) {
                        setAvailableLists(data);
                    });
                }
            }
            if (lookingUpTeamLists === false &&
                props.currentTemplate.LiveTemplateSiteUrl !== undefined &&
                props.currentTemplate.LiveTemplateSiteUrl !== null &&
                props.currentTemplate.LiveTemplateSiteUrl !== "") {
                //@ts-ignore
                assetObject = JSON.parse(props.currentTemplate.LiveTemplateSiteUrl);
                SharePointService.GetAllListsFromWeb(assetObject.URL, "").then(function (data) {
                    setAvailableLists(data);
                });
            }
            console.log("Live Object", assetObject);
        }
    }, [props.currentTemplate.LiveTemplateSiteCloneContentInLibs, props.currentTemplate.LiveTemplateTeamID, props.currentTemplate.LiveTemplateSiteUrl]);
    var changeTeamPicker = function (serializedItem) {
        if (serializedItem !== props.currentTemplate.LiveTemplateTeamID) {
            dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteCloneContentInLibsIds: "" }));
            setAvailableLists(undefined);
        }
        dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamID: serializedItem }));
        //console.log("Team Selected", (JSON.parse(serializedItem)).GUID);
    };
    var changeSitePicker = function (serializedItem) {
        if (serializedItem !== props.currentTemplate.LiveTemplateSiteUrl) {
            dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteCloneContentInLibsIds: "" }));
            setAvailableLists(undefined);
        }
        dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteUrl: serializedItem }));
    };
    React.useEffect(function () {
        var isEnabled = false;
        if (props.currentTemplate.TemplateType === "Team") {
            isEnabled = true;
        }
        else if (props.currentTemplate.TemplateType === "SharePoint" && props.currentTemplate.SharePointTemplateType !== undefined) {
            isEnabled = true;
        }
        setIsTemplateSelected(!isEnabled);
    }, [props.currentTemplate.TemplateType, props.currentTemplate.SharePointTemplateType]);
    React.useEffect(function () {
        if (props.currentTemplate.LiveTemplateTeamID !== undefined && props.currentTemplate.LiveTemplateTeamID !== null && props.currentTemplate.LiveTemplateTeamID !== "") {
            TeamsService.GetTeamSPUrl(JSON.parse(props.currentTemplate.LiveTemplateTeamID).GUID).then(function (url) {
                setTeamSPUrl(url.replace(orchestryContext.TenantSharePointUrl, ""));
            });
        }
        else {
            setTeamSPUrl("");
        }
    }, [props.currentTemplate.LiveTemplateTeamID]);
    return (React.createElement("div", { className: "template-tab-live-template", "data-pw": "live-template-tab" },
        React.createElement(DisableArea, { isDisabled: currentTenantConfiguration.IsMultiGeoEnabled && props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced" },
            React.createElement(Container, { fluid: true },
                React.createElement(Row, null,
                    React.createElement(Col, { md: 6 },
                        isTemplateSelected === true ? (React.createElement(PortletAlert, { type: "warning", icon: "alert", size: "small", title: "Live template option disabled until Template Type selected", message: React.createElement("div", { className: "lock-alert" }, "Please select a Template Type on the Details Tab to enable live template options") })) : null,
                        React.createElement(Form.Group, { controlId: "EnableLiveTemplates", className: "form-group standalone-checkbox" },
                            React.createElement(FormToggleControl, { disabled: isTemplateSelected, checked: props.currentTemplate.LiveTemplateEnabled, label: "Enable live template", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ LiveTemplateEnabled: checked })); } }),
                            React.createElement(InfoToolTip, null,
                                "A live template links this template with a current team or site. Updates to the live site will be reflected in provisioned requests for this template going forward.",
                                React.createElement("br", null),
                                React.createElement("br", null),
                                "Please note that Workspaces already created with this template will not have any changes retroactively applied")))),
                React.createElement(DisableArea, { isEnabled: props.currentTemplate.LiveTemplateEnabled, useOverlay: true },
                    React.createElement("div", { className: "living-template-container", "data-pw": "living-template-container" },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, "data-pw": "live-team-template" },
                                props.currentTemplate.TemplateType === "Team" ? (React.createElement(React.Fragment, null,
                                    React.createElement("h2", null, "Live Team Template"),
                                    React.createElement("div", { className: "content-well" },
                                        React.createElement("div", { className: "picker" },
                                            React.createElement(FormOfficeAssetPicker, { pickerType: "Teams", value: props.currentTemplate.LiveTemplateTeamID, onChange: function (serializedItem) { return changeTeamPicker(serializedItem); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "team-picker", ErrorMessage: "You must select a Team to act as a live template", Validators: ["string-not-empty"], Enabled: props.currentTemplate.LiveTemplateEnabled === true, Value: props.currentTemplate.LiveTemplateTeamID })),
                                        React.createElement(DisableArea, { isEnabled: props.currentTemplate.LiveTemplateTeamID !== undefined && props.currentTemplate.LiveTemplateTeamID !== null && props.currentTemplate.LiveTemplateTeamID !== "" },
                                            React.createElement("div", { className: "" },
                                                React.createElement("div", { className: "teams-options" },
                                                    React.createElement(Form.Group, { controlId: "teams-options-cloning-1", className: "form-group checkbox-green" },
                                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateTeamCloneChannels, onChange: function (event) {
                                                                return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneChannels: event.target.checked }));
                                                            }, label: "Clone Channels" })),
                                                    React.createElement(DisableArea, { isEnabled: props.currentTemplate.LiveTemplateTeamCloneChannels },
                                                        React.createElement("div", { className: "channel-options sub-option" },
                                                            React.createElement(Form.Group, { controlId: "teams-options-cloning-2", className: "form-group checkbox-green" },
                                                                React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateTeamCloneTabs, onChange: function (event) {
                                                                        return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneTabs: event.target.checked }));
                                                                    }, label: "Clone Tabs" })),
                                                            React.createElement(DisableArea, { isEnabled: props.currentTemplate.LiveTemplateTeamCloneTabs },
                                                                React.createElement("div", { className: "tab-options sub-option" },
                                                                    React.createElement(Form.Group, { controlId: "teams-options-cloning-tab-1", className: "form-group checkbox-green" },
                                                                        React.createElement(Form.Check, { type: "checkbox", checked: !props.currentTemplate.LiveTemplateTeamCloneWikiTabs, onChange: function (event) {
                                                                                return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneWikiTabs: !event.target.checked }));
                                                                            }, label: "Remove Wiki Tabs" })))))),
                                                    React.createElement(Form.Group, { controlId: "teams-options-cloning-3", className: "form-group checkbox-green" },
                                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateTeamCloneMembers, defaultChecked: props.currentTemplate.LiveTemplateTeamCloneMembers, onChange: function (event) {
                                                                return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneMembers: event.target.checked }));
                                                            }, label: "Clone Members & Owners" })),
                                                    React.createElement(Form.Group, { controlId: "teams-options-cloning-4", className: "form-group checkbox-green" },
                                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateTeamCloneSecuritySettings, onChange: function (event) {
                                                                return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneSecuritySettings: event.target.checked }));
                                                            }, label: "Clone Security Settings" })),
                                                    React.createElement(Form.Group, { controlId: "teams-options-cloning-5", className: "form-group checkbox-green" },
                                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateTeamCloneApps, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneApps: event.target.checked })); }, label: "Clone Apps" })),
                                                    React.createElement("h5", null, "Underlying SharePoint Site Options"),
                                                    props.currentTemplate.LiveTemplateTeamID !== undefined &&
                                                        props.currentTemplate.LiveTemplateTeamID !== null &&
                                                        props.currentTemplate.LiveTemplateTeamID !== "" ? (React.createElement("div", { className: "sharepoint-url" }, orchestryContext.TenantSharePointUrl !== undefined && teamSPUrl !== undefined ? (React.createElement("a", { href: orchestryContext.TenantSharePointUrl + teamSPUrl, target: "_blank", rel: "noopener noreferrer" },
                                                        React.createElement("img", { src: iconSP }),
                                                        React.createElement("span", null, teamSPUrl))) : (React.createElement(AnimatedLoader, { size: "xxsmall" })))) : null,
                                                    React.createElement(Form.Group, { controlId: "site-options-cloning-sp-1", className: "form-group checkbox-green" },
                                                        React.createElement(Form.Check, { type: "checkbox", defaultChecked: props.currentTemplate.LiveTemplateTeamCloneSharePointGroupSite, checked: props.currentTemplate.LiveTemplateTeamCloneSharePointGroupSite, onChange: function (event) {
                                                                return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneSharePointGroupSite: event.target.checked }));
                                                            }, label: "Clone Group's SharePoint Site" })),
                                                    React.createElement(DisableArea, { isEnabled: props.currentTemplate.LiveTemplateTeamCloneSharePointGroupSite },
                                                        React.createElement("div", { className: "sharepoint-options sub-option" },
                                                            React.createElement(Form.Group, { controlId: "site-options-cloning-sp-2", className: "form-group checkbox-green" },
                                                                React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateSiteClonePages, defaultChecked: props.currentTemplate.LiveTemplateSiteClonePages, onChange: function (event) {
                                                                        return dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteClonePages: event.target.checked }));
                                                                    }, label: "Clone additional pages" })),
                                                            React.createElement(Form.Group, { controlId: "site-options-cloning-sp-3", className: "form-group checkbox-green" },
                                                                React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateSiteCloneContentInLibs, defaultChecked: props.currentTemplate.LiveTemplateSiteCloneContentInLibs, onChange: function (event) {
                                                                        return dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteCloneContentInLibs: event.target.checked }));
                                                                    }, label: "Clone content within libraries & lists" })))))))))) : null,
                                props.currentTemplate.TemplateType === "SharePoint" ? (React.createElement(React.Fragment, null,
                                    React.createElement("h2", null, "Live SharePoint Site Template"),
                                    React.createElement("div", { className: "picker" },
                                        React.createElement(FormOfficeAssetPicker, { pickerType: props.currentTemplate.SharePointTemplateType === "ModernGroup" ? "TeamSiteSharePoint" : "CommSharePoint", value: props.currentTemplate.LiveTemplateSiteUrl, onChange: function (serializedItem) { return changeSitePicker(serializedItem); }, sharePointTemplateType: props.currentTemplate.SharePointTemplateType }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "sp-site-picker", ErrorMessage: "You must select a SharePoint site to act as a live template", Enabled: props.currentTemplate.LiveTemplateEnabled === true, Validators: ["string-not-empty"], Value: props.currentTemplate.LiveTemplateSiteUrl })),
                                    React.createElement(DisableArea, { isEnabled: props.currentTemplate.LiveTemplateSiteUrl !== undefined && props.currentTemplate.LiveTemplateSiteUrl !== null && props.currentTemplate.LiveTemplateSiteUrl !== "" },
                                        React.createElement("div", { className: "" },
                                            React.createElement("div", { className: "site-options" },
                                                props.currentTemplate.SharePointTemplateType === "ModernGroup" && (React.createElement(Form.Group, { controlId: "teams-options-cloning-3", className: "form-group checkbox-green" },
                                                    React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateTeamCloneMembers, onChange: function (event) {
                                                            return dispatch(TemplateCurrentStore.Update({ LiveTemplateTeamCloneMembers: event.target.checked }));
                                                        }, label: "Clone Members & Owners" }))),
                                                React.createElement(Form.Group, { controlId: "site-options-cloning-sc-1", className: "form-group checkbox-green" },
                                                    React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateSiteClonePages, defaultChecked: props.currentTemplate.LiveTemplateSiteClonePages, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteClonePages: event.target.checked })); }, label: "Clone additional pages" })),
                                                React.createElement(Form.Group, { controlId: "site-options-cloning-sc-2", className: "form-group checkbox-green" },
                                                    React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplateSiteCloneContentInLibs, defaultChecked: props.currentTemplate.LiveTemplateSiteCloneContentInLibs, onChange: function (event) {
                                                            return dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteCloneContentInLibs: event.target.checked }));
                                                        }, label: "Clone content within libraries" }))))))) : null),
                            React.createElement(Col, { md: 6, "data-pw": "planner-template" },
                                React.createElement("h2", null, "Planner Template"),
                                React.createElement("div", { className: "site-options" },
                                    React.createElement("p", { style: { fontWeight: 500 } }, "Any planner plans part of the group will be automatically copied over"),
                                    React.createElement(Form.Group, { controlId: "planner-options-cloning-2", className: "form-group checkbox-green" },
                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplatePlannerCloneBuckets, defaultChecked: props.currentTemplate.LiveTemplatePlannerCloneBuckets, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ LiveTemplatePlannerCloneBuckets: event.target.checked })); }, label: "Copy Buckets" })),
                                    React.createElement(Form.Group, { controlId: "planner-options-cloning-3", className: "form-group checkbox-green" },
                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplatePlannerCloneTasks, defaultChecked: props.currentTemplate.LiveTemplatePlannerCloneTasks, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ LiveTemplatePlannerCloneTasks: event.target.checked })); }, label: "Copy Tasks" })),
                                    React.createElement(Form.Group, { controlId: "planner-options-cloning-4", className: "form-group checkbox-green" },
                                        React.createElement(Form.Check, { type: "checkbox", checked: props.currentTemplate.LiveTemplatePlannerPrependWorkspaceName, defaultChecked: props.currentTemplate.LiveTemplatePlannerPrependWorkspaceName, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ LiveTemplatePlannerPrependWorkspaceName: event.target.checked })); }, label: "Use Workspace name in Planner name" })))))))))));
});
export default TabLiveTemplate;
