import { Popover, Skeleton, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React, { useEffect, useState } from "react";
import { OrchestryButton } from "../orchestry-button/orchestry-button.component";
import { OrchestryFilterCount } from "./orchestry-filter-count/orchestry-filter-count.component";
import { OrchestryFilterItems } from "./orchestry-filter-items/orchestry-filter-items.component";
import "./orchestry-filter-selector.scss";
import { useOrchestryFilterSelector } from "./use-orchestry-filter-selector.hook";
/**
 * A component that renders a filter selector with a button that opens a popover containing filter options.
 * Techdebt: This component should be called OrchestryFilterSelector. Also, we can have fitlers that are not pills, so this should maybe be called orchestry pill selector
 * or maybe eventually  refactored so that it can be used in tandum with other filters, maybe in accordions for things like reports. Or maybe we need to do a custom Filter Selector for each scenario?
 */
export var OrchestryFilterSelector = React.forwardRef(function (props, ref) {
    var _a;
    var _b = useState(false), showPopover = _b[0], setShowPopover = _b[1];
    var _c = useOrchestryFilterSelector(props.filters), filters = _c.filters, setFilters = _c.setFilters, selectedCount = _c.selectedCount, updateFilterState = _c.updateFilterState, onClearAllButtonClicked = _c.onClearAllButtonClicked, filterGroups = _c.filterGroups, intl = _c.intl;
    useEffect(function () {
        setFilters(props.filters);
    }, [setFilters, props.filters]);
    if (props.isLoading) {
        return React.createElement(Skeleton, { height: 42, width: 98 });
    }
    return (React.createElement("div", { ref: ref },
        React.createElement(Popover, { shadow: "xl", position: (_a = props.popoverPosition) !== null && _a !== void 0 ? _a : "bottom", opened: showPopover, onChange: setShowPopover },
            React.createElement(Popover.Target, null,
                React.createElement("div", null,
                    React.createElement(OrchestryButton, { className: "filter-selector-button", size: props.buttonSize, onClick: function () { return setShowPopover(function (show) { return !show; }); }, variant: "filter" },
                        React.createElement(Text, { className: "filter-selector-button-title", size: props.buttonSize }, props.buttonTitle),
                        React.createElement(OrchestryFilterCount, { count: selectedCount }),
                        React.createElement("i", { className: ORCH_ICONS.filterFunnel02 })))),
            React.createElement(Popover.Dropdown, { className: "filter-selector-content" },
                React.createElement(OrchestryFilterItems, { filters: filters, onClick: updateFilterState, groups: filterGroups }),
                React.createElement("div", { className: "footer" },
                    React.createElement(OrchestryButton, { variant: "tertiary", onClick: function () {
                            onClearAllButtonClicked();
                            props.onClear();
                        } }, intl.formatMessage({ id: "controls.clear_all", defaultMessage: "Clear All" })),
                    React.createElement(OrchestryButton, { variant: "primary", onClick: function () {
                            props.onApply(filters);
                            setShowPopover(false);
                        } }, intl.formatMessage({ id: "controls.apply", defaultMessage: "Apply" })))))));
});
