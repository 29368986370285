//@ts-ignore
function textEllipsis(str, maxLength, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.side, side = _c === void 0 ? "end" : _c, _d = _b.ellipsis, ellipsis = _d === void 0 ? "..." : _d;
    if (str !== undefined && str !== null && str.length > maxLength) {
        switch (side) {
            case "start":
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case "end":
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
}
export default textEllipsis;
//Examples:
//  var short = textEllipsis('a very long text', 10);
//  console.log(short);
//  // "a very ..."
//  var short = textEllipsis('a very long text', 10, { side: 'start' });
//  console.log(short);
//  // "...ng text"
//  var short = textEllipsis('a very long text', 10, { textEllipsis: ' END' });
//  console.log(short);
//  // "a very END"
