var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Text } from "@mantine/core";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { useUpdateRecommendationVisibilityMutation } from "appv2/api/recommendations/recommendations.api";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { orchestryToast } from "appv2/components/orchestry-notifications/orchestry-toast-notification.component";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import React from "react";
// The hook for the RecommendationCardResolveModal component
export var useRecommendationCardResolveModal = function (_a) {
    var context = _a.context, modalId = _a.modalId, recommendation = _a.recommendation;
    var _b = useUpdateRecommendationVisibilityMutation({ fixedCacheKey: "update-recommendation-visibility" }), updateRecommendationVisibility = _b[0], _c = _b[1], isLoading = _c.isLoading, isError = _c.isError, isSuccess = _c.isSuccess, data = _c.data, error = _c.error;
    // On click of the submit button
    var onSubmitClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateRecommendationVisibility({
                        RecommendationGUID: recommendation.Recommendation.Guid,
                        Visibility: RecommendationRecordVisibility.Resolved
                    })];
                case 1:
                    _a.sent();
                    orchestryToast(React.createElement(Text, { c: "text-primary.0", size: "sm" }, "Queued for pending sync"), {
                        autoClose: 2500,
                        progressBarVariant: "requeuetoast",
                        toastId: "recommendation-card-resolve-modal-success-toast",
                        icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "blue-7", iconClassName: ORCH_ICONS.scan })
                    });
                    context.closeModal(modalId);
                    return [2 /*return*/];
            }
        });
    }); };
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    var beaconRemediationSteps = '<div style="padding: 5px"><ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a href="http://www.google.com" target="_blank">Lorem Impsum</a> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li><li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</li><li>"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</li></ul></div>';
    return {
        onSubmitClick: onSubmitClick,
        isLoading: isLoading,
        isError: isError,
        isSuccess: isSuccess,
        data: data,
        error: error,
        currentUserIsBeaconUser: currentUserIsBeaconUser,
        beaconRemediationSteps: beaconRemediationSteps
    };
};
