import * as React from "react";
import { Form } from "react-bootstrap";
var ReportFilterArchived = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group checkbox-green", key: props.Index },
        React.createElement(Form.Label, null, "Archived Workspaces Display"),
        React.createElement("div", { className: "type-group" },
            React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue === null ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, null); } },
                React.createElement("span", null, "Show Both")),
            React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue === "True" ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, "True"); } },
                React.createElement("span", null, "Only Archived")),
            React.createElement("div", { className: "type-item custom-button" + (props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue === "False" ? " selected" : ""), onClick: function () { return props.FilterOnChange(props.Refiner, "False"); } },
                React.createElement("span", null, "Exclude Archived")))));
});
export default ReportFilterArchived;
