import { Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { useRecommendationCardActionButtonsSection } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-action-buttons-section/use-recommendation-card-action-buttons-section";
import { RecommendationCard } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card.component";
import React from "react";
export var HealthCheckRecommendationCard = function (_a) {
    var recommendation = _a.recommendation;
    var _b = useRecommendationCardActionButtonsSection(recommendation), onDeleteRecommendationClicked = _b.onDeleteRecommendationClicked, onEditRecommendationClicked = _b.onEditRecommendationClicked, displayMode = _b.displayMode;
    return (React.createElement(Group, { gap: 24 },
        React.createElement(RecommendationCard, { key: recommendation.RecommendationRecord.Id, recommendation: recommendation }),
        displayMode === "edit" && (React.createElement(Group, { pr: 8 },
            React.createElement(OrchestryFontIcon, { onClick: onEditRecommendationClicked, iconClassName: ORCH_ICONS.edit02, fontSize: 18, lineHeight: "18px", color: "var(--mantine-color-gray-5)" }),
            React.createElement(OrchestryFontIcon, { onClick: onDeleteRecommendationClicked, iconClassName: ORCH_ICONS.trash02, fontSize: 18, lineHeight: "18px", color: "var(--mantine-color-gray-5)" })))));
};
