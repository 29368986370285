import React from "react";
import { useDispatch } from "react-redux";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggleControl from "app/components/common/form-toggle-control";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { Collapse } from "react-bootstrap";
import { FormValidator } from "app/components/common/form-validation";
export var GuestReviewCard = React.memo(function (_a) {
    var requestTemplateList = _a.requestTemplateList, _b = _a.dropdownEnabled, dropdownEnabled = _b === void 0 ? false : _b, defaultTemplate = _a.defaultTemplate;
    var dispatch = useDispatch();
    return (React.createElement("div", null,
        React.createElement(FormToggleControl, { checked: dropdownEnabled, label: "Apply default Guest Review Policy to groups without policies.", handleChange: function (checked) { return dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave({ LCGuestReviewDefaultEnabled: checked, LCGuestReviewDefaultTemplateGUID: "" })); } }),
        React.createElement(Collapse, { in: dropdownEnabled },
            React.createElement("div", null,
                React.createElement(FormDropdownSelect, { value: defaultTemplate, defaultValue: defaultTemplate, list: requestTemplateList, onChange: function (value) { return dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave({ LCGuestReviewDefaultTemplateGUID: value })); } }),
                React.createElement(FormValidator, { Category: "guest-review", ErrorMessage: "Must Select a Policy", ShowFirst: true, UniqueIdentifier: "default-request-policy", Validators: ["dropdown-has-value"], Value: defaultTemplate, Enabled: dropdownEnabled })))));
});
