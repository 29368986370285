// import { MiddlewareFunction} from 'app/store/middleware'
import { ApprovalWorkflowCurrentActionTypes } from "./types";
export function Load(approvalWorkflow) {
    if (approvalWorkflow === undefined)
        approvalWorkflow = {};
    //console.log("Load Current ApprovalWorkflow > ", approvalWorkflow)
    return {
        type: ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load,
        payload: approvalWorkflow
    };
}
export function Update(approvalWorkflow) {
    return {
        type: ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Update,
        payload: approvalWorkflow
    };
}
export function Clear() {
    return {
        type: ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Clear
    };
}
export var approvalWorkflowCurrentMiddlware = function (store, next, action) {
    if (action.type === ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load) {
        // (action.payload as ApprovalWorkflow).AssociatedFeatures?.forEach((feat:ApprovalWorkflowBaseAssociation) => {
        // })
        // // action.payload.forEach((feat:Feature) => {
        // //     if(feat.icon !== undefined)
        // //         feat.icon = fontAwesomeHelper.getIconFromName(feat.icon?.iconName);
        // // });
        // console.log("Middleware", action.payload);
        next(action);
    }
    else {
        next(action);
    }
};
