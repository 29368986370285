import * as React from "react";
// import './request-details.scss';
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
var RequestDetailsTemplateGroupConfig = React.memo(function (props) {
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var intl = useIntl();
    return currentRequest.Template !== undefined && currentRequest.Template.GUID !== undefined && currentRequest.Template.GUID !== null && currentTemplate !== undefined && currentTemplate !== null ? (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.template_config.group_configuration.title", defaultMessage: "Group Configuration" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-8 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.guest_access_enabled", defaultMessage: "Guest Access Enabled:" })),
                React.createElement("div", { className: "col-4" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupAllowToAddGuests) === null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupAllowToAddGuests) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-8 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.hide_from_gal", defaultMessage: "Hide from my organizations global address list:" })),
                React.createElement("div", { className: "col-4" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupHideFromAddressLists) === null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupHideFromAddressLists) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-8 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.hide_from_exchange", defaultMessage: "Hide from Exchange Client:" })),
                React.createElement("div", { className: "col-4" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupHideFromOutlookClients) === null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupHideFromOutlookClients) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-8 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.disable_group_email", defaultMessage: "Disable Group Welcome Email:" })),
                React.createElement("div", { className: "col-4" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupDisableWelcomeEmail) === null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupDisableWelcomeEmail) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-8 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_external_senders", defaultMessage: "Allow external senders to email this group:" })),
                React.createElement("div", { className: "col-4" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupAllowExternalSenders) === null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupAllowExternalSenders) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-8 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.subscribe_all_members", defaultMessage: "Subscribe All Members to Group Emails and Events:" })),
                React.createElement("div", { className: "col-4" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupSubscribeAllMembersEmailsEvents) == null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupSubscribeAllMembersEmailsEvents) === false ? (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" })) : (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })))))))) : null;
});
export default RequestDetailsTemplateGroupConfig;
