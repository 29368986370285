import React from "react";
import "./orchestry-trial-information.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import { Modal } from "react-bootstrap";
var OrchestryTrialInformation = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(false), showTrialInfo = _a[0], setShowTrialInfo = _a[1];
    var _b = React.useState(0), daysLeft = _b[0], setDaysLeft = _b[1];
    var _c = React.useState(false), showTrialModal = _c[0], setShowTrialModal = _c[1];
    var isInTrial = function () {
        var trial = false;
        if (orchestryContext.DoesTenantHaveSubscription === false) {
            if (orchestryContext.TrialExpires !== undefined && orchestryContext.TrialExpires !== null) {
                var expiresDatetime = moment.utc(orchestryContext.TrialExpires).local();
                if (expiresDatetime.isValid()) {
                    if (expiresDatetime.isAfter(moment.now())) {
                        trial = true;
                        setDaysLeft(expiresDatetime.diff(moment.now(), "days"));
                    }
                }
            }
        }
        return trial;
    };
    React.useEffect(function () {
        if (orchestryContext.DoesTenantHaveSubscription === false && isInTrial()) {
            setShowTrialInfo(true);
        }
    }, [orchestryContext]);
    if (showTrialInfo) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "orchestry-trial-information", onClick: function () {
                    setShowTrialModal(!showTrialModal);
                } },
                React.createElement("div", { className: "num" }, daysLeft),
                React.createElement("div", { className: "days-left" },
                    React.createElement("span", { className: "text" }, "Days left in trial"),
                    React.createElement("div", { className: "expirey-date" },
                        "Expires on ",
                        moment.utc(orchestryContext.TrialExpires).format("LL")))),
            React.createElement(Modal, { size: "lg", centered: true, show: showTrialModal, onHide: function () { return setShowTrialModal(false); }, animation: true, className: "trial-modal" },
                React.createElement("div", { style: { padding: "20px" } }, "Upgrade to Orchestry before your trial expires to maintain service."))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
export default OrchestryTrialInformation;
