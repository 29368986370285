import * as React from "react";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormCheckArray from "app/components/common/form-checkarray";
import PortletAlert from "app/components/common/portlet-alert";
import { GuestNamingPolicyEditor } from "app/components/common/form-guest-naming-policy";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { defaultGuestDetailOptions } from "app/store/lifecycle-guest-policies/current/actions";
import "./guest-request.scss";
import FormToggle from "app/components/common/form-toggle";
var StepCardGuestRequest = React.memo(function (props) {
    var _a;
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var guestProfileExample = function () {
        var _a;
        return (React.createElement("div", { className: "guest-profile-container" },
            React.createElement(OfficeProfilePicture, { base64image: currentPolicy.GuestDefaultProfileImage }),
            React.createElement("p", null, currentPolicy.GuestNameConfiguration && currentPolicy.GuestNameConfiguration.length > 0 ? ((_a = currentPolicy.GuestNameConfiguration) === null || _a === void 0 ? void 0 : _a.map(function (el) {
                return el.type === "string" ? React.createElement("span", { style: { color: "purple" } }, el.value) : React.createElement("span", { style: { color: "green" } }, el.title);
            })) : (React.createElement("span", { style: { color: "green" } }, "Display Name")))));
    };
    var guestNameFormat = React.useMemo(function () {
        var _a;
        return (React.createElement(PortletAlert, { size: "regular", type: "warning", icon: "info", title: "Guest Names will be formatted as follows:", message: React.createElement("p", null, (_a = currentPolicy.GuestNameConfiguration) === null || _a === void 0 ? void 0 : _a.map(function (el) {
                return el.value && el.type === "string" ? React.createElement("span", { style: { color: "purple" } }, el.value) : React.createElement("span", { style: { color: "green" } }, el.title);
            })) }));
    }, [JSON.stringify(currentPolicy.GuestNameConfiguration)]);
    return (React.createElement("div", { className: "step-card-guest-request policy-card", "data-pw": "step-card-guest-request" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "left-side card-border" },
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement("div", { className: "guest-request-container", "data-pw": "guest-request-container" },
                                    React.createElement("h4", null, "Guest Request"),
                                    React.createElement(Form.Group, { className: "form-group", controlId: "workspace-access" },
                                        React.createElement(Row, { className: "align-items-center", "data-pw": "allow-guest-access" },
                                            React.createElement(Col, { lg: 4 },
                                                React.createElement(Form.Label, null,
                                                    "Allow Guest Access For Workspaces ",
                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                React.createElement("p", { className: "text" }, "Controls if you wish to allow guests to access the Workspace and if an approval is required")),
                                            React.createElement(Col, { lg: 2 },
                                                React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "guest-access-for-workspace" },
                                                    React.createElement(FormDropdownSelect, { list: [
                                                            {
                                                                id: "no",
                                                                title: "No",
                                                                value: false
                                                            },
                                                            {
                                                                id: "yes",
                                                                title: "Yes - Orchestry Approvals",
                                                                value: true
                                                            }
                                                        ], value: currentPolicy.AllowGuestAccessForWorkspaces, onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ AllowGuestAccessForWorkspaces: value })); } })),
                                                React.createElement(FormValidator, { FieldName: "Guest Workspace Access Rule", UniqueIdentifier: "guest-access-for-workspace", Validators: ["dropdown-has-value"], Value: currentPolicy.AllowGuestAccessForWorkspaces, OnScreen: props.IsCurrent }))),
                                        React.createElement(Collapse, { in: currentPolicy.AllowGuestAccessForWorkspaces || currentPolicy.AllowGuestAccessForWorkspaces === undefined },
                                            React.createElement(Row, { "data-pw": "require-guest-justification" },
                                                React.createElement(Col, { lg: 4 },
                                                    React.createElement(Form.Label, null,
                                                        "Require Guest Justification ",
                                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                                    React.createElement("p", { className: "text" }, "Whether requests to add new / existing guests to workspaces require a business justification.")),
                                                React.createElement(Col, { lg: 2 },
                                                    React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "require-guest-justification" },
                                                        React.createElement(FormDropdownSelect, { list: [
                                                                {
                                                                    id: "no",
                                                                    title: "No",
                                                                    value: false
                                                                },
                                                                {
                                                                    id: "yes",
                                                                    title: "Yes",
                                                                    value: true
                                                                }
                                                            ], onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ RequireGuestJustification: value })); }, value: currentPolicy.RequireGuestJustification })),
                                                    React.createElement(FormValidator, { FieldName: "Guest Request Justification Requirement", UniqueIdentifier: "require-guest-justification", Validators: ["dropdown-has-value"], Value: currentPolicy.RequireGuestJustification, Enabled: currentPolicy.AllowGuestAccessForWorkspaces, OnScreen: props.IsCurrent })))))))),
                        React.createElement(Collapse, { in: currentPolicy.AllowGuestAccessForWorkspaces || currentPolicy.AllowGuestAccessForWorkspaces === undefined },
                            React.createElement("div", null,
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement("div", { className: "guest-details-container", "data-pw": "guest-details-container" },
                                            React.createElement("h4", null, "Guest Details"),
                                            React.createElement("p", { className: "text" }, "Allows you to capture additional information when a user is requesting a Guest. This can be very useful to ensure you know about your guests"),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "guest-details" },
                                                React.createElement(Row, null,
                                                    React.createElement(Col, { md: 2, lg: 2, xl: 2 },
                                                        React.createElement(Form.Label, null, "Capture Additional Details")),
                                                    React.createElement(Col, { md: 6, lg: 6, xl: 6 },
                                                        React.createElement(FormCheckArray, { defaultItems: defaultGuestDetailOptions, currentItems: currentPolicy.GuestDetailOptions, onChange: function (items) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ GuestDetailOptions: items })); } }))))))),
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement("div", { className: "guest-name-configuration-container", "data-pw": "guest-name-configuration-container" },
                                            React.createElement("h4", null, "Guest Name Configuration"),
                                            React.createElement("p", { className: "text" }, "You can set the display name format to be applied to all guests upon creation."),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "guest-details" },
                                                React.createElement(Row, null,
                                                    React.createElement(Col, null,
                                                        React.createElement("div", { className: "toggle" },
                                                            React.createElement(FormToggle, { handleChange: function (checked) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ GuestNamingConventionEnabled: checked })); }, checked: currentPolicy.GuestNamingConventionEnabled }),
                                                            React.createElement(Form.Label, null, "Guest Name Configurations Enabled")),
                                                        React.createElement(Collapse, { in: currentPolicy.GuestNamingConventionEnabled },
                                                            React.createElement("div", null,
                                                                guestNameFormat,
                                                                React.createElement("div", { className: "divider-line" }),
                                                                React.createElement(GuestNamingPolicyEditor, { enabled: currentPolicy.GuestNamingConventionEnabled, elements: currentPolicy.GuestNameConfiguration || [], guestDetailItems: ((_a = currentPolicy.GuestDetailOptions) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.isChecked || item.isDefault; }).map(function (item) {
                                                                        return { id: item.id, title: item.label, value: item.id };
                                                                    })) || [], onChange: function (items) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ GuestNameConfiguration: items })); } })))))))))))))))));
});
export default StepCardGuestRequest;
