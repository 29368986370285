var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Skeleton, Stack, Textarea } from "@mantine/core";
import React from "react";
export var OrchestryTextArea = function (_a) {
    var isLoading = _a.isLoading, skeletonHeight = _a.skeletonHeight, skeletonWidth = _a.skeletonWidth, props = __rest(_a, ["isLoading", "skeletonHeight", "skeletonWidth"]);
    if (isLoading) {
        return (React.createElement(Stack, { gap: 0 },
            props.label && React.createElement(Skeleton, { h: 20, w: 70, mb: 6 }),
            props.description && React.createElement(Skeleton, { h: 13, w: 70, mb: 4 }),
            React.createElement(Skeleton, { h: skeletonHeight !== null && skeletonHeight !== void 0 ? skeletonHeight : 33, w: skeletonWidth !== null && skeletonWidth !== void 0 ? skeletonWidth : "100%" })));
    }
    return React.createElement(Textarea, __assign({}, props));
};
