var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AsyncWait } from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import FormButton from "app/components/common/form-button";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggleControl from "app/components/common/form-toggle-control";
import FormValidationContext from "app/components/common/form-validation";
import OrchModal from "app/components/common/orch-modal";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import GuestService from "app/services/guests";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
import { InactivityThresholdMap as ReviewRecurrence } from "app/store/lifecycle-guest-policies/types";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { LifecyclePolicyWorkspaceCurrentActionTypes } from "app/store/lifecycle-policy-workspaces/current";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import generateGuid from "app/utils/generateGuid";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./archival-policy-selector.scss";
var ArchivalPolicySelector = React.memo(function (_a) {
    var _b, _c, _d, _e;
    var siteId = _a.siteId, Slot = _a.Slot;
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var assignablePolicies = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.assignAblePolicies; });
    var _f = React.useState(false), loadingForcedReview = _f[0], setLoadingForcedReview = _f[1];
    var defaultArchivalPolicies = useSelector(function (state) { var _a; return (_a = state.lifecyclePolicyWorkspacesCurrentState.defaultPolicies) === null || _a === void 0 ? void 0 : _a.defaultArchivalPolicies; });
    var defaultGuestPolicies = useSelector(function (state) { var _a; return (_a = state.lifecyclePolicyWorkspacesCurrentState.defaultPolicies) === null || _a === void 0 ? void 0 : _a.defaultGuestPolicies; });
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var _g = React.useState(false), isSettingPolicy = _g[0], setIsSettingPolicy = _g[1];
    var _h = React.useState(true), isValid = _h[0], setIsValid = _h[1];
    var uniqueComponentId = React.useState(generateGuid())[0];
    var _j = React.useState(false), showArchivalEditModal = _j[0], setShowArchivalEditModal = _j[1];
    var _k = React.useState(false), showGuestRequestEditModal = _k[0], setshowGuestRequestEditModal = _k[1];
    var _l = React.useState(false), showGuestReviewEditModal = _l[0], setshowGuestReviewEditModal = _l[1];
    var _m = React.useState(false), showGuestReviewForceConfirmModal = _m[0], setShowGuestReviewForceConfirmModal = _m[1];
    var _o = React.useState(), archivalThresholdSource = _o[0], setarchivalThresholdSource = _o[1];
    var _p = React.useState([]), overrideOptions = _p[0], setoverrideOptions = _p[1];
    React.useEffect(function () {
        dispatch(LifecyclePolicyWorkspaceStore.LoadDefaultPolicies());
        dispatch(LifecyclePolicyWorkspaceStore.LoadAvailablePolicies(siteId));
    }, []);
    var refreshAfterUpdate = function () {
        setIsSettingPolicy(true);
        AsyncWait(AsyncCallType.Update, AsyncStatus.Succeeded, undefined, null, LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update).then(function () {
            dispatch(LifecyclePolicyWorkspaceStore.LoadHistory(siteId));
            dispatch(LifecycleReportStore.Refresh(Slot));
            setIsSettingPolicy(false);
        });
    };
    var getDefaultArchivalPolicy = function () {
        var foundDefaultWorkspace = defaultArchivalPolicies === null || defaultArchivalPolicies === void 0 ? void 0 : defaultArchivalPolicies.find(function (ap) { return ap.Application === (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.Application) || (ap.Application === "TeamSite" && (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.Application) === "VivaEngageCommunity"); });
        //console.log("getDefaultPolicy", foundDefaultWorkspace, defaultArchivalPolicies);
        return foundDefaultWorkspace !== undefined ? foundDefaultWorkspace : null;
    };
    var getDefaultReviewPolicy = function () {
        var _a;
        var policy = (_a = defaultGuestPolicies === null || defaultGuestPolicies === void 0 ? void 0 : defaultGuestPolicies.find(function (gp) { return gp.Type === LifecycleGuestPolicyType.Review; })) !== null && _a !== void 0 ? _a : undefined;
        return policy;
    };
    var getDefaultRequestPolicy = function () {
        var _a;
        return (_a = defaultGuestPolicies === null || defaultGuestPolicies === void 0 ? void 0 : defaultGuestPolicies.find(function (gp) { return gp.Type === LifecycleGuestPolicyType.Request; })) !== null && _a !== void 0 ? _a : undefined;
    };
    var getAssignableArchivalPolicies = function () {
        var policies = [];
        var defaultPolicy = getDefaultArchivalPolicy();
        if (defaultPolicy !== undefined && defaultPolicy !== null) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (assignablePolicies !== null) {
            policies = __spreadArray(__spreadArray([], policies, true), assignablePolicies.ArchivalPolicies.map(function (ap) { return ({
                id: ap.GUID,
                value: ap.GUID,
                title: ap.Name,
                description: ap.Description
            }); }), true);
        }
        return policies;
    };
    var getAssignableReviewPolicies = function () {
        var policies = [];
        var defaultPolicy = getDefaultReviewPolicy();
        if (defaultPolicy !== undefined) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (assignablePolicies !== null) {
            policies = __spreadArray(__spreadArray([], policies, true), assignablePolicies.GuestPolicies.filter(function (p) { return p.Type === LifecycleGuestPolicyType.Review; }).map(function (ap) { return ({
                id: ap.GUID,
                value: ap.GUID,
                title: ap.Name,
                description: ap.Description
            }); }), true);
        }
        return policies;
    };
    var getAssignableRequestPolicies = function () {
        var policies = [];
        var defaultPolicy = getDefaultRequestPolicy();
        if (defaultPolicy !== undefined) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (assignablePolicies !== null) {
            policies = __spreadArray(__spreadArray([], policies, true), assignablePolicies.GuestPolicies.filter(function (p) { return p.Type === LifecycleGuestPolicyType.Request; }).map(function (gp) { return ({
                id: gp.GUID,
                value: gp.GUID,
                title: gp.Name,
                description: gp.Description
            }); }), true);
        }
        return policies;
    };
    var getAssignableArchivalDays = function () {
        var _a, _b;
        var options = [];
        var inheritOption = {
            id: "inherit",
            value: null,
            title: "Inherit from selected policy",
            description: "Use the amount of days configured on policy."
        };
        if (getAssignedArchivalPolicy() != null) {
            inheritOption.title = "".concat((_a = getAssignedArchivalPolicy().InactivityDaysThreshold) === null || _a === void 0 ? void 0 : _a.toString(), " Days [inherited from selected policy]");
        }
        else if (getDefaultArchivalPolicy() != null) {
            inheritOption.title = "".concat((_b = getDefaultArchivalPolicy().InactivityDaysThreshold) === null || _b === void 0 ? void 0 : _b.toString(), " Days [inherited from default policy]");
        }
        options.push(inheritOption);
        options = __spreadArray(__spreadArray([], options, true), [
            { id: "5", value: 5, title: "5 Days" },
            { id: "15", value: 15, title: "15 Days" },
            { id: "30", value: 30, title: "30 Days" },
            { id: "45", value: 45, title: "45 Days" },
            { id: "60", value: 60, title: "60 Days" },
            { id: "90", value: 90, title: "90 Days" },
            { id: "120", value: 120, title: "120 Days" },
            { id: "150", value: 150, title: "150 Days" },
            { id: "180", value: 180, title: "180 Days" },
            { id: "270", value: 270, title: "270 Days" },
            { id: "365", value: 365, title: "365 Days" },
            { id: "730", value: 730, title: "730 Days (2 Years)" },
            { id: "1095", value: 1095, title: "1095 Days (3 Years)" }
        ], false);
        if (policyWorkspace && archivalThresholdSource === "Override") {
            options.forEach(function (option) {
                if (option.value === policyWorkspace.ArchivalPolicyOverrideThreshold)
                    option.title = option.title + " [workspace override]";
            });
        }
        return options;
    };
    var getAssignedArchivalPolicy = function () {
        if (assignablePolicies !== null && assignablePolicies.ArchivalPolicies.length > 0 && policyWorkspace !== null && policyWorkspace.ArchivalPolicyGuid !== null) {
            var foundPolicy = assignablePolicies.ArchivalPolicies.find(function (ap) { return ap.GUID === policyWorkspace.ArchivalPolicyGuid; });
            return foundPolicy !== undefined ? foundPolicy : null;
        }
        return null;
    };
    var getAssignedReviewPolicy = function () {
        if (assignablePolicies !== null && assignablePolicies.GuestPolicies.length > 0 && policyWorkspace !== null && policyWorkspace.GuestReviewPolicyGuid !== null) {
            var foundPolicy = assignablePolicies.GuestPolicies.find(function (gp) { return gp.GUID === policyWorkspace.GuestReviewPolicyGuid; });
            return foundPolicy !== undefined ? foundPolicy : null;
        }
        return null;
    };
    var getAssignedRequestPolicy = function () {
        if (assignablePolicies !== null && assignablePolicies.GuestPolicies.length > 0 && policyWorkspace !== null && policyWorkspace.GuestRequestPolicyGuid !== null) {
            var foundPolicy = assignablePolicies.GuestPolicies.find(function (gp) { return gp.GUID === policyWorkspace.GuestRequestPolicyGuid; });
            return foundPolicy !== undefined ? foundPolicy : null;
        }
        return null;
    };
    var getAssignedArchivalPolicyThreshold = function () {
        if (policyWorkspace !== null && policyWorkspace.ArchivalPolicyOverrideThreshold !== null) {
            return policyWorkspace.ArchivalPolicyOverrideThreshold;
        }
        else if (policyWorkspace !== null && policyWorkspace.ArchivalPolicyGuid !== null && getAssignedArchivalPolicy() !== null) {
            return getAssignedArchivalPolicy().InactivityDaysThreshold;
        }
    };
    React.useEffect(function () {
        var assignedArchivalPolicy = getAssignedArchivalPolicy();
        setarchivalThresholdSource("Policy");
        if (assignedArchivalPolicy != null &&
            (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalPolicyOverrideThreshold) != null &&
            assignedArchivalPolicy.InactivityDaysThreshold !== (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalPolicyOverrideThreshold))
            setarchivalThresholdSource("Override");
        setoverrideOptions(getAssignableArchivalDays());
    }, [JSON.stringify(getAssignedArchivalPolicy()), policyWorkspace, policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalPolicyOverrideThreshold, JSON.stringify(overrideOptions)]);
    var getAssignedGuestReviewPolicyThreshold = function () {
        if (policyWorkspace !== null &&
            policyWorkspace !== undefined &&
            policyWorkspace.GuestReviewPolicyGuid !== null &&
            policyWorkspace.GuestReviewPolicyGuid !== undefined &&
            getAssignedReviewPolicy() !== null) {
            var lifeCyclePolicy = getAssignedReviewPolicy();
            if (lifeCyclePolicy.GuestReviewRecurrence) {
                return ReviewRecurrence[lifeCyclePolicy.GuestReviewRecurrence];
            }
            // This is a strange bug in the backend!
            // This is a strange bug in the backend!
            // When the policy is set to review guests every 30 days, the InactivityThreshold field is populated with 30
            // For all other number of days (60, 90, 120), the GuestReviewRecurrence field is populated.
            if (lifeCyclePolicy.InactivityThreshold) {
                return lifeCyclePolicy.InactivityThreshold;
            }
            debugger;
            debugger;
            // This <<is>> an exception and should not happen. If there is a policy, it should have the GuestReviewRecurrence field.
            return undefined;
        }
    };
    var getDefaultGuestReviewPolicyThreshold = function () {
        var defaultPolicy = getDefaultReviewPolicy();
        if (defaultPolicy && defaultPolicy.GuestReviewRecurrence) {
            return ReviewRecurrence[defaultPolicy.GuestReviewRecurrence];
        }
        // This <<may>> be an exception. If there is no default policy, this method should not have been called.
        // If there is a default policy and its GuestReviewRecurrence is null, there is a problem!
        return undefined;
    };
    var getArchivalPolicy = function () {
        return getAssignedArchivalPolicy() !== null ? getAssignedArchivalPolicy() : getDefaultArchivalPolicy();
    };
    var forceArchival = function () {
        var policy = getArchivalPolicy();
        dispatch(LifecyclePolicyWorkspaceStore.LoadNewStatus(true));
        dispatch(LifecyclePolicyWorkspaceStore.UpdateProperties({ ArchivalForced: true }));
        refreshAfterUpdate();
        setShowArchivalEditModal(false);
    };
    var forceGuestReview = function () {
        if (reportItem !== undefined && reportItem !== null) {
            setLoadingForcedReview(true);
            GuestService.ForceGuestReview(reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId).then(function () {
                setshowGuestReviewEditModal(false);
                setShowGuestReviewForceConfirmModal(false);
                setLoadingForcedReview(false);
                dispatch(LifecyclePolicyWorkspaceStore.LoadHistory(siteId));
                dispatch(LifecyclePolicyWorkspaceStore.ForcedGuestReviewSuccess());
            });
        }
        // setShowArchivalEditModal(false);
    };
    var isOrchestrySC = function () {
        if (orchestryContext.TenantSharePointUrl !== undefined && reportItem !== undefined && reportItem !== null && reportItem.SiteCollectionUrl !== null) {
            if (reportItem.SiteCollectionUrl.toLowerCase() === (orchestryContext.TenantSharePointUrl + "/sites/orchestry").toLowerCase()) {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "archival-policy-selector" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setIsValid(isValid);
            }, UniqueIdentifier: "policy-editing-" + uniqueComponentId }, reportItem === null || defaultArchivalPolicies === null || policyWorkspace === null || assignablePolicies === null ? (React.createElement(React.Fragment, null,
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 30 }))) : (React.createElement("div", { className: "selector" },
            React.createElement("div", { className: "policies", "data-pw": "ws-policies" },
                React.createElement("div", { className: "policy-item", "data-pw": "renewal-policy" },
                    React.createElement("div", { className: "left" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-box-archive" }))),
                    React.createElement("div", { className: "right" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "text-top", "data-pw": "renewal-policy-label" }, "Renewal / Archival Policy"),
                            React.createElement("div", { className: "text-bottom" }, policyWorkspace.ArchivalProtectedFromArchival === true ? (React.createElement("div", { className: "protected" },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("i", { className: "fa fa-shield-alt" })),
                                React.createElement("div", { className: "text" }, "Excluded from Archivals"))) : getAssignedArchivalPolicy() !== null && getAssignedArchivalPolicy() !== undefined ? (React.createElement(React.Fragment, null,
                                React.createElement("span", { className: "pname" }, getAssignedArchivalPolicy().Name),
                                React.createElement("span", { className: "days ".concat(archivalThresholdSource === "Override" ? "override" : null).trim(), title: "Days of inactivity until policy is triggered" },
                                    React.createElement("i", { className: "fa fa-bolt" }),
                                    getAssignedArchivalPolicyThreshold() + " Days",
                                    React.createElement("span", { className: "threshold-days ".concat(archivalThresholdSource === "Override" ? "override" : null).trim() }, archivalThresholdSource === "Override" ? " [workspace override]" : null)))) : getDefaultArchivalPolicy() !== null && getDefaultArchivalPolicy() !== undefined ? (React.createElement(React.Fragment, null,
                                React.createElement("span", { className: "pname" }, (_b = getDefaultArchivalPolicy()) === null || _b === void 0 ? void 0 :
                                    _b.Name,
                                    " ",
                                    React.createElement("span", null, "(Default)")),
                                React.createElement("span", { className: "days", title: "Days of inactivity until policy is triggered" },
                                    React.createElement("i", { className: "fa fa-bolt" }),
                                    " ", (_c = getDefaultArchivalPolicy()) === null || _c === void 0 ? void 0 :
                                    _c.InactivityDaysThreshold))) : (React.createElement("span", { className: "pname" }, "Currently no associated renewal / archival policy"))))),
                    React.createElement("div", { className: "actions", "data-pw": "actions" },
                        React.createElement(OrchModal, { headerText: "Edit Renewal / Archival Policy", modalClassName: "archival-policy-settings-modal", showCloseButton: true, simpleButton: true, showModalOverride: showArchivalEditModal, showModalToggleOverride: setShowArchivalEditModal, size: "lg", buttonContents: (policyWorkspace.ArchivalStatus === "Active" || policyWorkspace.ArchivalStatus === "Extended") && policyWorkspace.ArchivalForced === false ? (React.createElement(SubtitleButton, { laIcon: "pen", title: "Edit", size: "sm", variant: "secondary" })) : (React.createElement(React.Fragment, null)) },
                            React.createElement(Row, { "data-pw": "edit-renewal-policy-content" },
                                React.createElement(Col, { md: 12 },
                                    React.createElement(DisableArea, { isDisabled: isSettingPolicy, useOverlay: true },
                                        React.createElement("div", null,
                                            React.createElement(DisableArea, { isDisabled: policyWorkspace.ArchivalProtectedFromArchival || policyWorkspace.ArchivalStatus === "Extended", useOverlay: true },
                                                React.createElement(Form.Group, { className: "form-group", controlId: "control-select-mode" },
                                                    React.createElement(Form.Label, null, "Renewal / Archival Policy"),
                                                    React.createElement(FormDropdownSelect, { list: getAssignableArchivalPolicies(), defaultValue: policyWorkspace.ArchivalPolicyGuid, onChange: function (value) {
                                                            dispatch(LifecyclePolicyWorkspaceStore.UpdateProperties({ ArchivalPolicyGuid: value }));
                                                            refreshAfterUpdate();
                                                        } })),
                                                React.createElement(DisableArea, { isDisabled: policyWorkspace.ArchivalPolicyGuid === null, useOverlay: true, zIndex: 9 },
                                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-select-days-threshold" },
                                                        React.createElement(Form.Label, null, "Inactivity Days Threshold"),
                                                        policyWorkspace.ArchivalPolicyGuid === null && getDefaultArchivalPolicy() === null ? (React.createElement(Form.Control, { disabled: true, value: "" })) : policyWorkspace.ArchivalPolicyGuid !== null || getDefaultArchivalPolicy() !== null ? (
                                                        // <></>
                                                        React.createElement(FormDropdownSelect, { list: overrideOptions, defaultValue: policyWorkspace.ArchivalPolicyOverrideThreshold, value: policyWorkspace.ArchivalPolicyOverrideThreshold, onChange: function (value) {
                                                                dispatch(LifecyclePolicyWorkspaceStore.UpdateProperties({ ArchivalPolicyOverrideThreshold: value }));
                                                                refreshAfterUpdate();
                                                            } })) : null))),
                                            React.createElement(DisableArea, { isDisabled: policyWorkspace.ArchivalProtectedFromArchival, useOverlay: true }, (policyWorkspace.ArchivalStatus === "Active" || policyWorkspace.ArchivalStatus === "Extended") &&
                                                policyWorkspace.ArchivalForced === false &&
                                                (getAssignedArchivalPolicy() !== null || getDefaultArchivalPolicy() !== null) ? (React.createElement("div", { title: "Force Archival" },
                                                React.createElement(OrchModal, { buttonContents: React.createElement(FormButton, { theme: "blue", text: "Force Archival", icon: "la la-archive", onClick: function () { } }), size: "sm", modalClassName: "lifecycle-force-archival-confirm-modal", modalBgClassName: "lifecycle-force-archival-confirm-modal-bg", simpleButton: true, footerCloseText: "No", footerPrimaryText: "Yes", footerPrimaryFunction: function () {
                                                        forceArchival();
                                                    } },
                                                    React.createElement("div", { className: "confim-text" },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("i", { className: "la la-archive" })),
                                                        React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", message: "You are about to execute the attached Renewal / Archival Policy" }),
                                                        React.createElement("div", { className: "text" }, "This will execute the attached Renewal / Archival Policy associated to this Workspace. Are you sure?"))))) : (React.createElement(FormButton, { theme: "gray", text: "Force Archival", icon: "la la-archive" }))),
                                            React.createElement(DisableArea, { isDisabled: policyWorkspace.ArchivalStatus === "Extended", useOverlay: true },
                                                React.createElement(Form.Group, { controlId: "control-exclude-protect-from-archival", className: "form-group exclude-group" },
                                                    React.createElement(Form.Label, null, "Exclude Workspace from Archivals"),
                                                    React.createElement(FormToggleControl, { checked: policyWorkspace.ArchivalProtectedFromArchival, label: "If excluded this Workspace will not be subject to any Renewal / Archival Policies.", handleChange: function (checked) {
                                                            dispatch(LifecyclePolicyWorkspaceStore.UpdateProperties({ ArchivalProtectedFromArchival: checked }));
                                                            refreshAfterUpdate();
                                                        } })))))))))),
                orchestryContext.GuestManagementEnabled === true ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "policy-item", "data-pw": "guest-request-policy" },
                        React.createElement("div", { className: "left" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "fa-light fa-user-plus" }))),
                        React.createElement("div", { className: "right" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "text-top", "data-pw": "guest-request-policy-label" }, "Guest Request Policy"),
                                React.createElement("div", { className: "text-bottom" }, getAssignedRequestPolicy() !== null && getAssignedRequestPolicy() !== undefined ? (React.createElement(React.Fragment, null,
                                    React.createElement("span", { className: "pname" }, getAssignedRequestPolicy().Name))) : getDefaultRequestPolicy() !== null && getDefaultRequestPolicy() !== undefined ? (React.createElement(React.Fragment, null,
                                    React.createElement("span", { className: "pname" }, (_d = getDefaultRequestPolicy()) === null || _d === void 0 ? void 0 :
                                        _d.Name,
                                        " ",
                                        React.createElement("span", null, "(Default)")))) : reportItem.Application !== "CommunicationSite" ? (React.createElement("span", { className: "pname" }, "Currently no associated Guest Request Policy")) : (React.createElement("span", { className: "pname" }, "Currently not supported for Communication Sites"))))),
                        reportItem.Application !== "CommunicationSite" && reportItem.TemplateType !== "Viva Engage Community" ? (React.createElement("div", { className: "actions", "data-pw": "actions" },
                            React.createElement(OrchModal, { headerText: "Edit Guest Request Policy", modalClassName: "guest-request-policy-settings-modal", showCloseButton: true, simpleButton: true, showModalOverride: showGuestRequestEditModal, showModalToggleOverride: setshowGuestRequestEditModal, size: "lg", buttonContents: (policyWorkspace.ArchivalStatus === "Active" || policyWorkspace.ArchivalStatus === "Extended" || policyWorkspace.ArchivalStatus === "PendingApproval") &&
                                    (reportItem.SharePointTeamSiteHasNoGroup === null ||
                                        (reportItem.SharePointTeamSiteHasNoGroup !== null && reportItem.SharePointTeamSiteHasNoGroup === false)) ? (React.createElement(SubtitleButton, { laIcon: "pen", title: "Edit", size: "sm", variant: "secondary" })) : (
                                // <div className="action edit">
                                //    <div className="icon btn btn-sm btn-clean btn-icon btn-icon-sm"><i className="la la-pen"></i></div>
                                //    <span className="edit-text"></span>
                                // </div>
                                React.createElement(React.Fragment, null)) },
                                React.createElement(Row, null,
                                    React.createElement(Col, { md: 12 },
                                        React.createElement(DisableArea, { isDisabled: isSettingPolicy, useOverlay: true },
                                            React.createElement("div", null,
                                                React.createElement(DisableArea, { useOverlay: true },
                                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-select-mode" },
                                                        React.createElement(Form.Label, null, "Guest Request Policy"),
                                                        React.createElement(FormDropdownSelect, { list: getAssignableRequestPolicies(), defaultValue: policyWorkspace.GuestRequestPolicyGuid, onChange: function (value) {
                                                                dispatch(LifecyclePolicyWorkspaceStore.UpdateProperties({ GuestRequestPolicyGuid: value }));
                                                                refreshAfterUpdate();
                                                            } })))))))))) : null),
                    React.createElement("div", { className: "policy-item", "data-pw": "guest-review-policy" },
                        React.createElement("div", { className: "left" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "fa-light fa-user-check" }))),
                        React.createElement("div", { className: "right" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "text-top", "data-pw": "guest-review-policy-label" }, "Guest Review Policy"),
                                React.createElement("div", { className: "text-bottom" }, getAssignedReviewPolicy() !== null && getAssignedReviewPolicy() !== undefined ? (React.createElement(React.Fragment, null,
                                    React.createElement("span", { className: "pname" }, getAssignedReviewPolicy().Name),
                                    React.createElement("span", { className: "days", title: "Days of inactivity until policy is triggered" },
                                        React.createElement("i", { className: "fa fa-bolt" }),
                                        " ",
                                        getAssignedGuestReviewPolicyThreshold() + " Days"))) : getDefaultReviewPolicy() !== null && getDefaultReviewPolicy() !== undefined ? (React.createElement(React.Fragment, null,
                                    React.createElement("span", { className: "pname" }, (_e = getDefaultReviewPolicy()) === null || _e === void 0 ? void 0 :
                                        _e.Name,
                                        " ",
                                        React.createElement("span", null, "(Default)")),
                                    React.createElement("span", { className: "days", title: "Days of inactivity until policy is triggered" },
                                        React.createElement("i", { className: "fa fa-bolt" }),
                                        " ",
                                        getDefaultGuestReviewPolicyThreshold() + " Days"))) : reportItem.Application !== "CommunicationSite" ? (React.createElement("span", { className: "pname" }, "Currently no associated Guest Review Policy")) : (React.createElement("span", { className: "pname" }, "Currently not supported for Communication Sites"))))),
                        reportItem.Application !== "CommunicationSite" && reportItem.TemplateType !== "Viva Engage Community" ? (React.createElement("div", { className: "actions", "data-pw": "actions" },
                            React.createElement(OrchModal, { headerText: "Edit Guest Review Policy", modalClassName: "guest-review-policy-settings-modal", showCloseButton: true, simpleButton: true, showModalOverride: showGuestReviewEditModal, showModalToggleOverride: setshowGuestReviewEditModal, size: "lg", buttonContents: (policyWorkspace.ArchivalStatus === "Active" || policyWorkspace.ArchivalStatus === "Extended" || policyWorkspace.ArchivalStatus === "PendingApproval") &&
                                    (reportItem.SharePointTeamSiteHasNoGroup === null ||
                                        (reportItem.SharePointTeamSiteHasNoGroup !== null && reportItem.SharePointTeamSiteHasNoGroup === false)) ? (React.createElement(SubtitleButton, { laIcon: "pen", title: "Edit", size: "sm", variant: "secondary" })) : (
                                // <div className="action edit">
                                //    <div className="icon btn btn-sm btn-clean btn-icon btn-icon-sm"><i className="la la-pen"></i></div>
                                //    <span className="edit-text"></span>
                                // </div>
                                React.createElement(React.Fragment, null)) },
                                React.createElement(Row, null,
                                    React.createElement(Col, { md: 12 },
                                        React.createElement(DisableArea, { isDisabled: isSettingPolicy, useOverlay: true },
                                            React.createElement("div", null,
                                                React.createElement(DisableArea, { useOverlay: true },
                                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-select-mode" },
                                                        React.createElement(Form.Label, null, "Guest Review Policy"),
                                                        React.createElement(FormDropdownSelect, { list: getAssignableReviewPolicies(), defaultValue: policyWorkspace.GuestReviewPolicyGuid, onChange: function (value) {
                                                                dispatch(LifecyclePolicyWorkspaceStore.UpdateProperties({ GuestReviewPolicyGuid: value }));
                                                                refreshAfterUpdate();
                                                            } }))),
                                                getAssignedReviewPolicy() !== null || getDefaultReviewPolicy() !== null ? (React.createElement("div", { className: "force-modal" },
                                                    React.createElement(OrchModal, { buttonContents: React.createElement(FormButton, { theme: "blue", text: "Force Guest Review", icon: "fa-light fa-user-check", onClick: function () { } }), size: "sm", modalClassName: "lifecycle-force-review-confirm-modal", modalBgClassName: "lifecycle-force-review-confirm-modal-bg", simpleButton: true, showModalOverride: showGuestReviewForceConfirmModal, showModalToggleOverride: setShowGuestReviewForceConfirmModal, footerContents: React.createElement("div", { className: "lifecycle-force-review-confirm-buttons" },
                                                            React.createElement("div", { className: "filler" }),
                                                            loadingForcedReview ? (React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 38 })) : (React.createElement(React.Fragment, null,
                                                                React.createElement(SubtitleButton, { color: "outline-gray", title: "No", onClick: function () { return setShowGuestReviewForceConfirmModal(false); } }),
                                                                React.createElement(SubtitleButton, { color: "brandblue", title: "Yes", onClick: function () { return forceGuestReview(); } })))) },
                                                        React.createElement("div", { className: "confim-text" },
                                                            React.createElement("div", { className: "icon" },
                                                                React.createElement("i", { className: "fa-light fa-user-check" })),
                                                            React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", message: "You are about to execute the attached Guest Review Policy" }),
                                                            React.createElement("div", { className: "text" }, "This will execute the attached Guest Review Policy associated to this Workspace. Are you sure?"))))) : (React.createElement(FormButton, { theme: "gray", text: "Force Guest Review", icon: "fa-light fa-user-check" }))))))))) : null))) : null))))));
});
export default ArchivalPolicySelector;
