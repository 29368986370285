var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
export var Leaf = function (_a) {
    var attributes = _a.attributes, children = _a.children, leaf = _a.leaf;
    if (leaf.bold) {
        children = React.createElement("strong", null, children);
    }
    if (leaf.italic) {
        children = React.createElement("em", null, children);
    }
    if (leaf.underline) {
        children = React.createElement("u", null, children);
    }
    if (leaf.h1 || leaf.h2 || leaf.h3) {
        var fontSize = leaf.h1 ? 20 : leaf.h2 ? 18 : leaf.h3 ? 16 : 12;
        var fontWeight = leaf.h1 ? 700 : leaf.h2 ? 700 : leaf.h3 ? 500 : 400;
        children = React.createElement("span", { style: { fontSize: fontSize, fontWeight: fontWeight } }, children);
    }
    return (React.createElement("span", __assign({ style: { width: "100%" } }, attributes), children));
};
