import * as React from "react";
import "./lifecycle-history.scss";
import { useSelector } from "react-redux";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import moment from "moment";
import FontIcon from "app/components/common/font-icon";
import UserProfilePicture from "app/components/common/user-profile-picture";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalItemDynamic from "app/components/common/principal-item-dynamic";
var LifecycleHistory = React.memo(function (_a) {
    var type = _a.type, histories = _a.histories, secondStageEnabled = _a.secondStageEnabled;
    return (React.createElement("div", { className: "lifecycle-history", "data-pw": "lifecycle-history" },
        React.createElement(Portlet, { className: "history-portlet" + (" portlet-type-" + type.toLowerCase()) },
            React.createElement(PortletHeader, { title: type === "Creation" ? "Creation History" : type === "Archival" ? "Renewal / Archival History" : type === "Guest" ? "Guest History" : "Renewal / Archival History" }),
            React.createElement(PortletBody, { className: type === "Creation" ? "body-creation" : type === "Archival" ? "body-archival" : "body-renewal" }, histories !== null ? (type === "Creation" ? (React.createElement("div", { className: "kt-widget4" },
                histories.CreationHistory === null ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 }) : null,
                histories.CreationHistory !== null && histories.CreationHistory.map(function (ch) { return React.createElement(HistoryCreationItem, { hist: ch }); }),
                histories.CreationHistory === null || histories.CreationHistory.length === 0 ? (React.createElement("div", { className: "no-results" },
                    React.createElement("i", { className: "fa fa-history", "aria-hidden": "true" }),
                    React.createElement("div", null, "There isn't any history to display here yet!"))) : null)) : type === "Archival" ? (React.createElement("div", { className: "policy-history-items" },
                histories.ArchivalHistory === null ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 }) : null,
                histories.ArchivalHistory.map(function (ah) { return (React.createElement(HistoryPolicyItem, { hist: ah, secondStageEnabled: secondStageEnabled })); }),
                histories.ArchivalHistory.length === 0 && (React.createElement("div", { className: "no-results" },
                    React.createElement("i", { className: "fa fa-history", "aria-hidden": "true" }),
                    React.createElement("div", null, "There isn't any history to display here yet!"))))) : type === "Guest" ? (React.createElement("div", { className: "policy-history-items" },
                histories.GuestHistory === null ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 }) : null,
                histories.GuestHistory.map(function (ah) { return (React.createElement(HistoryPolicyItem, { hist: ah })); }),
                histories.GuestHistory.length === 0 && (React.createElement("div", { className: "no-results" },
                    React.createElement("i", { className: "fa fa-history", "aria-hidden": "true" }),
                    React.createElement("div", null, "There isn't any history to display here yet!"))))) : (React.createElement(React.Fragment, null))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 }))))));
});
export default LifecycleHistory;
var HistoryPolicyItem = React.memo(function (_a) {
    var _b, _c, _d, _e, _f;
    var hist = _a.hist, secondStageEnabled = _a.secondStageEnabled;
    return (React.createElement("div", { className: "ph-item" },
        React.createElement("div", { className: "ph-top" },
            React.createElement("div", { className: "ph-action-icon" }, hist.Action === "AssignPolicy" ? (React.createElement("div", { className: "icon icon-green" },
                React.createElement("i", { className: "fa fa-certificate" }))) : hist.Action === "ChangePolicy" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-sync" }))) : hist.Action === "RemovePolicy" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-times" }))) : hist.Action === "ApprovalPending" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-business-time" }))) : hist.Action === "Approved" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-thumbs-up" }))) : hist.Action === "ApprovedForArchive" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-thumbs-up" }))) : hist.Action === "ApprovedForDelete" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-thumbs-up" }))) : hist.Action === "Extended" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-clock" }))) : hist.Action === "ReadyToArchive" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-flag-checkered" }))) : hist.Action === "Processing" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-rocket" }))) : hist.Action === "Done" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-archive" }))) : hist.Action === "Failed" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-exclamation-circle" }))) : hist.Action === "ForcedArchive" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-rocket" }))) : hist.Action === "ToggledProtection" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-shield-alt" }))) : hist.Action === "ExtensionCancelled" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-undo" }))) : hist.Action === "ExtensionExpired" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-clock" }))) : hist.Action === "NoActionTaken" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-exclamation-triangle" }))) : hist.Action === "AutomaticArchivalTriggered" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-magic" }))) : hist.Action === "ExecutionNotificationSent" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === "NotificationSent" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === "ReminderNotificationSent" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === "NoActionTakenNotification" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === "SetToActive" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-undo" }))) : hist.Action === "SetToDone" ? (React.createElement("div", { className: "icon icon-purple" },
                React.createElement("i", { className: "fa fa-archive" }))) : hist.Action === "NoUsersToSendNotification" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-user-times" }))) : hist.Action === "GuestReviewPolicyInitiated" ? (React.createElement("div", { className: "icon icon-blue" },
                React.createElement("i", { className: "fa fa-magic" }))) : hist.Action === "GuestRemoved" ? (React.createElement("div", { className: "icon icon-red" },
                React.createElement("i", { className: "fa fa-user-xmark" }))) : hist.Action === "GuestAdded" ? (React.createElement("div", { className: "icon icon-green" },
                React.createElement("i", { className: "fa fa-user-plus" }))) : hist.Action === "SiteDeletionFailedRetentionPolicy" ? (React.createElement("div", { className: "icon icon-yellow" },
                React.createElement("i", { className: "fa fa-exclamation-circle" }))) : null),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "action-title" }, hist.Action === "AssignPolicy" ? (React.createElement(React.Fragment, null, "Assigned policy to workspace")) : hist.Action === "ChangePolicy" ? (React.createElement(React.Fragment, null, "Changed assigned policy on workspace")) : hist.Action === "RemovePolicy" ? (React.createElement(React.Fragment, null, "Removed policy from workspace")) : hist.Action === "ApprovalPending" ? (React.createElement(React.Fragment, null, "Waiting for approval")) : hist.Action === "Approved" || hist.Action === "ApprovedForArchive" ? (React.createElement(React.Fragment, null, "Workspace archival has been approved!")) : hist.Action === "ApprovedForDelete" ? (React.createElement(React.Fragment, null, "Workspace has been deleted")) : hist.Action === "Extended" ? (React.createElement(React.Fragment, null, "Workspace has been renewed")) : hist.Action === "SiteDeletionFailedRetentionPolicy" ? (React.createElement(React.Fragment, null, "SharePoint Site Deletion Failed")) : hist.Action === "ReadyToArchive" ? (React.createElement(React.Fragment, null, "".concat(((_b = hist.ExtendedProperties) === null || _b === void 0 ? void 0 : _b.isSecondStage)
                    ? "Workspace is ready to archive (second step)"
                    : secondStageEnabled
                        ? "Workspace is ready to archive (first step)"
                        : "Workspace is ready to archive"))) : hist.Action === "Processing" ? (React.createElement(React.Fragment, null, "".concat(((_c = hist.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.isSecondStage) ? "Starting archival process (second step)" : secondStageEnabled ? "Starting archival process (first step)" : "Starting archival process"))) : hist.Action === "Done" ? (React.createElement(React.Fragment, null, "".concat(((_d = hist.ExtendedProperties) === null || _d === void 0 ? void 0 : _d.isSecondStage)
                    ? "Workspace has been archived (second step)"
                    : secondStageEnabled
                        ? "Workspace has been archived (first step)"
                        : "Workspace has been archived"))) : hist.Action === "Failed" ? (React.createElement(React.Fragment, null, "Workspace failed to archive")) : hist.Action === "ForcedArchive" ? (React.createElement(React.Fragment, null, "Workspace forced to archive")) : hist.Action === "ToggledProtection" ? (React.createElement(React.Fragment, null, "Workspace protection toggled")) : hist.Action === "ExtensionCancelled" ? (React.createElement(React.Fragment, null, "Workspace reverted to active due to activity")) : hist.Action === "ExtensionExpired" ? (React.createElement(React.Fragment, null, "Workspace extension expired")) : hist.Action === "ExecutionNotificationSent" ? (React.createElement(React.Fragment, null, "".concat(((_e = hist.ExtendedProperties) === null || _e === void 0 ? void 0 : _e.isSecondStage)
                    ? "Notification sent informing of second step archival"
                    : secondStageEnabled
                        ? "Notification sent informing of first step archival"
                        : "Notification sent informing of archival"))) : hist.Action === "NoActionTaken" ? (React.createElement(React.Fragment, null, "No action taken for archival approval")) : hist.Action === "NotificationSent" ? (React.createElement(React.Fragment, null, "First notification sent asking for archival approval")) : hist.Action === "ReminderNotificationSent" ? (React.createElement(React.Fragment, null, "Reminder notification sent asking for archival approval")) : hist.Action === "AutomaticArchivalTriggered" ? (React.createElement(React.Fragment, null, "".concat(((_f = hist.ExtendedProperties) === null || _f === void 0 ? void 0 : _f.isSecondStage)
                    ? "Workspace Archival triggered by inactivity threshold to execute automatically (second step)"
                    : secondStageEnabled
                        ? "Workspace Archival triggered by inactivity threshold to execute automatically (first step)"
                        : "Workspace Archival triggered by inactivity threshold to execute automatically"))) : hist.Action === "NoActionTakenNotification" ? (React.createElement(React.Fragment, null, "Workspace Archival request had no actions taken")) : hist.Action === "SetToActive" ? (React.createElement(React.Fragment, null, "Workspace reset to an Active status")) : hist.Action === "SetToDone" ? (React.createElement(React.Fragment, null, "Workspace set to Archived based on pre-existing archival condition")) : hist.Action === "NoUsersToSendNotification" ? (React.createElement(React.Fragment, null, "No Users Available for Notification")) : hist.Action === "GuestReviewPolicyInitiated" ? (React.createElement(React.Fragment, null, "Guest Review initiated")) : hist.Action === "GuestRemoved" ? (React.createElement(React.Fragment, null, "Guest Removed")) : hist.Action === "GuestAdded" ? (React.createElement(React.Fragment, null, "Guest Added")) : null),
                React.createElement("div", { className: "user-and-date" },
                    hist.User !== undefined && hist.User !== null && hist.User.ClaimsId !== undefined && hist.User.ClaimsId !== null ? (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "con" }, "by "),
                        React.createElement("span", { className: "userspan" },
                            React.createElement(PrincipalItem, { principal: {
                                    Id: hist.User.ClaimsId,
                                    GUID: hist.User.ClaimsId,
                                    JobTitle: "",
                                    Company: "",
                                    CountryOrRegion: "",
                                    Department: "",
                                    DisplayName: hist.User.DisplayName,
                                    UserPrincipalName: hist.User.UPN,
                                    GivenName: hist.User.DisplayName,
                                    Office: "",
                                    StateOrProvince: "",
                                    Surname: "",
                                    Type: "User"
                                }, showBorder: false })))) : null,
                    React.createElement("span", { className: "con" }, " on "),
                    React.createElement("span", { className: "datespan" }, moment
                        .utc(hist.Date)
                        .local()
                        .format("llll"))))),
        React.createElement("div", { className: "ph-action" }, hist.Action === "AssignPolicy" || hist.Action === "ChangePolicy" || hist.Action === "RemovePolicy" ? (React.createElement("div", { className: "action-policy-name-with-completed-user" },
            (hist.ExtendedProperties.PolicyName !== undefined && hist.ExtendedProperties.PolicyName !== null) ||
                (hist.ExtendedProperties.PreviousName !== undefined && hist.ExtendedProperties.PreviousName !== null) ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Policy Name"),
                hist.Action === "ChangePolicy" && hist.ExtendedProperties.PreviousName !== undefined && hist.ExtendedProperties.PreviousName !== hist.ExtendedProperties.PolicyName ? (React.createElement("div", { className: "property-change" },
                    React.createElement("div", { className: "from" }, hist.ExtendedProperties.PreviousName),
                    React.createElement("i", { className: "fa fa-chevron-right" }),
                    React.createElement("div", { className: "to" }, hist.ExtendedProperties.PolicyName))) : hist.Action === "RemovePolicy" ? (React.createElement("div", { className: "property" }, hist.ExtendedProperties.PreviousName)) : (React.createElement("div", { className: "property" }, hist.ExtendedProperties.PolicyName)))) : null,
            hist.ExtendedProperties.PolicyDays !== undefined && hist.ExtendedProperties.PolicyDays !== null ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Days Inactive To Trigger"),
                hist.Action === "ChangePolicy" && hist.ExtendedProperties.PreviousDays !== undefined && hist.ExtendedProperties.PreviousDays !== null ? (React.createElement("div", { className: "property-change" },
                    React.createElement("div", { className: "from" }, hist.ExtendedProperties.PreviousDays === "" ? "Inherit from policy" : hist.ExtendedProperties.PreviousDays),
                    React.createElement("i", { className: "fa fa-chevron-right" }),
                    React.createElement("div", { className: "to" }, hist.ExtendedProperties.PolicyDays === "" ? "Inherit from policy" : hist.ExtendedProperties.PolicyDays))) : (React.createElement("div", { className: "property" }, hist.ExtendedProperties.PolicyDays === "" ? "Inherit from policy" : hist.ExtendedProperties.PolicyDays)))) : null)) : hist.Action === "NotificationSent" || hist.Action === "ReminderNotificationSent" || hist.Action === "NoActionTakenNotification" || hist.Action === "ExecutionNotificationSent" ? (React.createElement("div", { className: "action-notification action-policy-name-with-completed-user" },
            hist.ExtendedProperties.ReminderNotification !== undefined ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Reminder Number"),
                React.createElement("div", { className: "property" }, hist.ExtendedProperties.ReminderNotification))) : null,
            React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Sent To"),
                React.createElement("div", { className: "property" }, hist.ExtendedProperties.UsersEmailed !== null && hist.ExtendedProperties.UsersEmailed !== undefined ? (hist.ExtendedProperties.UsersEmailed.length > 0 ? (React.createElement("div", { className: "emails" }, hist.ExtendedProperties.UsersEmailed.split(",").map(function (userGuid) { return (React.createElement(PrincipalItemDynamic, { principalIdOrEmail: userGuid, showBorder: false, size: "sm" })); }))) : (React.createElement("div", { className: "empty-users" }, "No users found for notifications"))) : null)),
            hist.ExtendedProperties.DeleteAlternative !== null && hist.ExtendedProperties.DeleteAlternative !== undefined ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Delete Alternative"),
                React.createElement("div", { className: "property" }, hist.ExtendedProperties.DeleteAlternative))) : null)) : hist.Action === "Extended" ? (React.createElement("div", { className: "action-policy-name-with-completed-user action-extended" },
            React.createElement("div", { className: "extended-message" }, hist.ExtendedProperties.ExtendedDays !== undefined && hist.ExtendedProperties.ExtendedType !== undefined ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Note"),
                React.createElement("div", { className: "property" },
                    "Workspace has been renewed by ",
                    hist.ExtendedProperties.ExtendedDays,
                    " days based on ",
                    hist.ExtendedProperties.ExtendedType))) : null),
            hist.ExtendedProperties.AdminOverride !== undefined ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Admin Override"),
                React.createElement("div", { className: "property" }, hist.ExtendedProperties.AdminOverride))) : null,
            React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Comment"),
                React.createElement("div", { className: "property" }, hist.Comment)))) : hist.Action === "ApprovedForArchive" || hist.Action === "ApprovedForDelete" ? (React.createElement("div", { className: "action-policy-name-with-completed-user action-extended" },
            hist.ExtendedProperties.DeleteAlternative !== null && hist.ExtendedProperties.DeleteAlternative !== undefined && hist.ExtendedProperties.DeleteAlternative === "Enabled" ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Archival Alternative"),
                React.createElement("div", { className: "property" }, hist.Action === "ApprovedForDelete" ? "Delete" : "Archive"))) : null,
            React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Comment"),
                React.createElement("div", { className: "property" }, hist.Comment)))) : hist.Action === "ToggledProtection" ? (React.createElement("div", { className: "action-policy-name-with-completed-user" },
            React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Exclude from being archived"),
                React.createElement("div", { className: "property-change" },
                    React.createElement("div", { className: "from" }, hist.ExtendedProperties.From),
                    React.createElement("i", { className: "fa fa-chevron-right" }),
                    React.createElement("div", { className: "to" }, hist.ExtendedProperties.To))))) : hist.Action === "GuestAdded" || hist.Action === "GuestRemoved" ? (React.createElement(React.Fragment, null, hist.ExtendedProperties.GuestUserId !== undefined ? (React.createElement("div", { className: "action-notification action-policy-name-with-completed-user" },
            React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "User"),
                React.createElement("div", { className: "property" }, hist.ExtendedProperties.GuestUserId !== undefined ? (React.createElement(PrincipalItemDynamic, { principalIdOrEmail: hist.ExtendedProperties.GuestUserId, showBorder: false, size: "sm" })) : null)),
            hist.ExtendedProperties.GuestManagementMembershipAction !== undefined && hist.ExtendedProperties.GuestManagementMembershipAction !== "GroupRemoved" ? (React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Workflow"),
                React.createElement("div", { className: "property" }, hist.ExtendedProperties.GuestManagementMembershipAction === "GroupReviewReject"
                    ? "User review"
                    : hist.ExtendedProperties.GuestManagementMembershipAction === "GroupAccessGranted"
                        ? "User Access Granted"
                        : hist.ExtendedProperties.GuestManagementMembershipAction === "GroupReviewNoActionExecuted"
                            ? "No action taken"
                            : ""))) : null)) : null)) : hist.Action === "Done" ||
            hist.Action === "Processing" ||
            hist.Action === "ApprovalPending" ||
            hist.Action === "AutomaticArchivalTriggered" ||
            hist.Action === "ReadyToArchive" ? null : hist.Comment !== null ? (React.createElement("div", { className: "action-policy-name-with-completed-user" },
            React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Comment"),
                React.createElement("div", { className: "property" }, hist.Comment)))) : null)));
});
var HistoryCreationItem = React.memo(function (_a) {
    var hist = _a.hist;
    var currentReportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    return (React.createElement("div", { className: "kt-widget4__item history-creation-item" },
        React.createElement("div", { className: "kt-widget4__pic kt-widget4__pic--pic" },
            React.createElement("div", { className: "user-pic" },
                React.createElement("div", { className: "badge kt-badge kt-badge--rounded " +
                        ("Create" === hist.Action ? "kt-badge--info" : "") +
                        ("Approve" === hist.Action ? "kt-badge--success" : "") +
                        ("Reject" === hist.Action ? "kt-badge--danger" : "") +
                        ("ProvisioningStarted" === hist.Action ? "kt-badge--info" : "") +
                        ("ProvisioningComplete" === hist.Action ? "kt-badge--success" : "") +
                        ("Archive" === hist.Action ? "kt-badge--info" : "") +
                        ("Edit" === hist.Action ? "kt-badge--info" : "") +
                        ("Retry" === hist.Action ? "kt-badge--success" : "") +
                        ("Reapprove" === hist.Action ? "kt-badge--success" : "") },
                    "Create" === hist.Action ? React.createElement(FontIcon, { iconString: "certificate" }) : null,
                    "Approve" === hist.Action ? React.createElement(FontIcon, { iconString: "thumbs-up" }) : null,
                    "Reject" === hist.Action ? React.createElement(FontIcon, { iconString: "thumbs-down" }) : null,
                    "ProvisioningStarted" === hist.Action ? React.createElement(FontIcon, { iconString: "play" }) : null,
                    "ProvisioningComplete" === hist.Action ? React.createElement(FontIcon, { iconString: "check" }) : null,
                    "Archive" === hist.Action ? React.createElement(FontIcon, { iconString: "archive" }) : null,
                    "Edit" === hist.Action ? React.createElement(FontIcon, { iconString: "pen" }) : null,
                    "Retry" === hist.Action ? React.createElement(FontIcon, { iconString: "sync" }) : null,
                    "Reapprove" === hist.Action ? React.createElement(FontIcon, { iconString: "sync" }) : null),
                React.createElement(UserProfilePicture, { size: "sm", uid: hist.User.ClaimsId, displayName: hist.User.DisplayName }))),
        React.createElement("div", { className: "kt-widget4__info" },
            React.createElement("div", { className: "kt-widget4__username" },
                React.createElement("span", { className: "user", style: { fontWeight: 600 } },
                    hist.User.DisplayName,
                    " "),
                React.createElement("span", { className: "action" },
                    "Create" === hist.Action ? "created the request" : null,
                    "Approve" === hist.Action ? React.createElement("span", null, "provided approval for step") : null,
                    "Reject" === hist.Action ? "rejected the request" : null,
                    "ProvisioningStarted" === hist.Action
                        ? "started provisioning the workspace" +
                            (currentReportItem !== null && currentReportItem.TemplateName !== null ? " using the " + currentReportItem.TemplateName + " template" : null)
                        : null,
                    "ProvisioningComplete" === hist.Action ? "completed workspace provisioning" : null,
                    "Archive" === hist.Action ? "has archived the request" : null,
                    "Edit" === hist.Action ? "has edited the request" : null,
                    "Retry" === hist.Action ? "has retried to provision the request" : null,
                    "Reapprove" === hist.Action ? "has restarted approval process" : null),
                hist.Comment !== undefined && hist.Comment !== null && hist.Comment !== "" ? React.createElement("div", { className: "feedback", dangerouslySetInnerHTML: { __html: hist.Comment } }) : null),
            React.createElement("p", { className: "kt-widget4__text" }, moment
                .utc(hist.Date)
                .local()
                .format("llll")))));
});
