import { LibraryChannelCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {}
};
export function libraryChannelCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LibraryChannelCurrentActionTypes.LibraryChannel_Current_Load: {
            //console.log(LibraryChannelCurrentActionTypes.LibraryChannel_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case LibraryChannelCurrentActionTypes.LibraryChannel_Current_Update: {
            // let associatedItemsUpdated = false;
            var updatedLibraryChannel = Object.assign({}, state.item, action.payload);
            // if(state.item.AssociatedLibraryTemplates.length !== updatedLibraryChannel.AssociatedLibraryTemplates.length)
            // {
            //     console.log("Array Changed");
            //     associatedItemsUpdated = true;
            // }
            //console.log(LibraryChannelCurrentActionTypes.LibraryChannel_Current_Update, updatedLibraryChannel)
            return {
                item: updatedLibraryChannel
            };
        }
        case LibraryChannelCurrentActionTypes.LibraryChannel_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
