var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-guest-naming-policy.scss";
import { Row, Col, Form } from "react-bootstrap";
import FormDropdownSelect from "../form-dropdown-select";
import { FormValidator, FormValidatorWrappingElement } from "../form-validation";
import FontIcon from "../font-icon";
import generateGuid from "app/utils/generateGuid";
var guestDetailType = [
    { id: "guest-detail", value: "guest-detail", title: "Guest Detail" },
    { id: "string", value: "string", title: "String" }
];
export var GuestNamingPolicyEditor = function (_a) {
    var onChange = _a.onChange, elements = _a.elements, guestDetailItems = _a.guestDetailItems, _b = _a.enabled, enabled = _b === void 0 ? false : _b;
    var _c = React.useState(elements), currentElements = _c[0], setcurrentElements = _c[1];
    var _d = React.useState(guestDetailItems), currentDetailItems = _d[0], setcurrentDetailItems = _d[1];
    var update = function (GUID, value) {
        var _a;
        var updatedElements = currentElements;
        var indexOfUpdatedElement = updatedElements.map(function (item) { return item.GUID; }).indexOf(GUID);
        if (indexOfUpdatedElement === null || indexOfUpdatedElement === undefined)
            return;
        updatedElements[indexOfUpdatedElement].value = value;
        updatedElements[indexOfUpdatedElement].title = (_a = currentDetailItems.filter(function (item) { return item.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.title;
        setcurrentElements(__spreadArray([], updatedElements, true));
    };
    var updateType = function (GUID, value) {
        var _a;
        var updatedElements = currentElements;
        var indexOfUpdatedElement = updatedElements.map(function (item) { return item.GUID; }).indexOf(GUID);
        if (indexOfUpdatedElement === null || indexOfUpdatedElement === undefined)
            return;
        updatedElements[indexOfUpdatedElement].value = undefined;
        updatedElements[indexOfUpdatedElement].type = value;
        updatedElements[indexOfUpdatedElement].title = (_a = currentDetailItems.filter(function (item) { return item.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.title;
        setcurrentElements(__spreadArray([], updatedElements, true));
    };
    var add = function (value) {
        var newElement = {
            GUID: generateGuid(),
            type: value
        };
        setcurrentElements(__spreadArray(__spreadArray([], currentElements, true), [newElement], false));
    };
    var remove = function (GUID) {
        var updatedElements = currentElements;
        var indexOfRemoved = updatedElements.map(function (item) { return item.GUID; }).indexOf(GUID);
        updatedElements.splice(indexOfRemoved, 1);
        setcurrentElements(__spreadArray([], updatedElements, true));
    };
    React.useEffect(function () {
        onChange(currentElements);
    }, [JSON.stringify(currentElements)]);
    React.useEffect(function () {
        setcurrentDetailItems(guestDetailItems);
    }, [JSON.stringify(guestDetailItems)]);
    return (React.createElement("div", { className: "form-guest-naming-policy" },
        React.createElement("div", { className: "guest-fix-editor" },
            enabled
                ? currentElements.map(function (element, index) { return (React.createElement(Row, { className: "fix-item editable-item", key: index },
                    React.createElement(Col, { sm: 6, className: "fix-left" },
                        React.createElement("div", { className: "count" }, index + 1),
                        React.createElement(FormDropdownSelect, { list: guestDetailType, defaultValue: element.type, onChange: function (value) {
                                updateType(element.GUID, value);
                            } })),
                    React.createElement(Col, { sm: 6, className: "fix-right" },
                        element.type === "guest-detail" ? (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: currentDetailItems, value: element.value, showRedOutlineIfNothingSelected: true, onChange: function (value) {
                                    update(element.GUID, value);
                                } }),
                            React.createElement("div", null,
                                React.createElement(FormValidator, { UniqueIdentifier: "guest-name-dropdown-" + index + element.GUID, Validators: ["dropdown-has-value"], Value: element.value, Category: "", FieldName: "Metadata Type" })))) : element.type === "string" ? (React.createElement(React.Fragment, null,
                            React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "guest-name-string-input-" + index + element.GUID, ClassNameNotValid: "red-outline" },
                                React.createElement(Form.Control, { type: "text", placeholder: "", value: element.value, onChange: function (e) {
                                        update(element.GUID, e.target.value);
                                    } })),
                            React.createElement("span", null,
                                React.createElement(FormValidator, { UniqueIdentifier: "guest-name-string-input-" + index + element.GUID, Validators: ["string-not-empty"], Value: element.value, Category: "", FieldName: "String" })))) : null,
                        React.createElement("div", { className: "remove", onClick: function () {
                                remove(element.GUID);
                            } },
                            React.createElement(FontIcon, { iconString: "times-circle" }))))); })
                : null,
            React.createElement(Row, { className: "fix-item new-mode" },
                React.createElement(Col, { sm: 6, className: "fix-left" },
                    React.createElement(FormDropdownSelect, { placeholder: "--Add Naming Attribute--", list: guestDetailType, onChange: function (value) {
                            add(value);
                        }, afterSelectReset: true })),
                React.createElement(Col, { sm: 6, className: "fix-right" })))));
};
