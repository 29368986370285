var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import Async from "app/components/common/async";
import InfoTooltip from "app/components/common/infotooltip";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
import NoResults from "app/components/common/no-results";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import ReadMore from "app/components/common/read-more";
import DirectoryUpdateItem from "app/components/directory/directory-components/directory-update-item";
import DirectoryViewMembers from "app/components/directory/directory-components/directory-view-members";
import * as AsyncTypes from "app/store/async";
import * as DirectoryStore from "app/store/directory/search";
import { DirectorySearchActionTypes } from "app/store/directory/search/types";
import convertStringToSafePropertyName from "app/utils/convertStringToSafePropertyName";
import moment from "moment";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import "./directory-list-table.scss";
var DirectoryListTable = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var directoryFavs = useSelector(function (state) { return state.directorySearchState.Favs; });
    var showFavs = useSelector(function (state) { return state.directorySearchState.Context.ShowFavs; });
    var directoryIsLoading = useSelector(function (state) { return state.directorySearchState.IsLoading; });
    var _a = React.useState(1000), scollAreaHeight = _a[0], setScrollAreaHeight = _a[1];
    var _b = React.useState(0), fixedScrollLeft = _b[0], setFixedScrollLeft = _b[1];
    var _c = React.useState(false), fixedMode = _c[0], setFixedMode = _c[1];
    var getPropertyValue = function (object, property) {
        if (object !== undefined) {
        }
    };
    /**
     * Renders all the external links in a group for the first field that has external links
     * @param fieldName  The name of the field
     * @param values The values of the field
     */
    var renderExternalLinks = function (item) {
        var itemNames = directory.Columns.filter(function (md) { return md.Type === "custom-link"; }).map(function (md) { return md.ProperyName; });
        var links = item.Metadata.filter(function (md) { return itemNames.some(function (itemName) { return itemName === md.SpColumnsName; }); });
        if (!links) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { className: "links-group" }, links.map(function (link, index) {
            return !!(link === null || link === void 0 ? void 0 : link.Values) && (React.createElement("div", { className: "link" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLink }),
                React.createElement("a", { key: index, href: link.Values, target: "_blank", rel: "noreferrer" }, link.DisplayName)));
        })));
    };
    var getMetadataValue = function (item, fieldName, renderType) {
        var re = React.createElement(React.Fragment, null);
        var foundMetadata = item.Metadata.find(function (md) { return md.SpColumnsName === fieldName; });
        if (foundMetadata !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null) {
            if (renderType === "custom-choice-single") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if (renderType === "custom-choice-multiple") {
                var values = foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.split("|");
                re = (React.createElement(React.Fragment, null, values === null || values === void 0 ? void 0 : values.map(function (v, index) { return (React.createElement("div", { key: index }, v)); })));
            }
            else if (renderType === "custom-date") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== "") {
                    re = React.createElement(React.Fragment, null, moment((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined ? foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.replace(/\"/g, "") : foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values).format("MMM Do YYYY"));
                }
            }
            else if (renderType === "custom-text-single") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if (renderType === "custom-link") {
                re = renderExternalLinks(item);
            }
            else if (renderType === "custom-text-multiple") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if (renderType === "custom-managed-metadata-single") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.length) > 0 && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.indexOf("{")) > -1) {
                    var tagObject = JSON.parse(foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                    if (tagObject !== null && tagObject !== undefined && tagObject.TermID !== null && tagObject.TermID !== undefined) {
                        console.log(tagObject.TermID);
                        re = (React.createElement(ManagedMetadataValue, { node: {
                                NodeType: "Term",
                                Id: tagObject.TermID
                            } }));
                    }
                }
            }
            else if (renderType === "custom-managed-metadata-multiple") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.length) > 0 && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.indexOf("[")) > -1) {
                    var tagObjects = JSON.parse(foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                    if (Array.isArray(tagObjects) && tagObjects.length > 0) {
                        re = (React.createElement("ul", { className: "managed-metadata-list" }, tagObjects.map(function (to) { return (React.createElement("li", null,
                            React.createElement("div", { className: "managed-metadata-item", "data-term-guid": to.TermID },
                                React.createElement("i", { className: "la la-tag" }),
                                React.createElement("span", null, to.Label)))); })));
                    }
                }
            }
        }
        // console.log("getMetadataValue", re, item, fieldName, renderType);
        return re;
    };
    var updateScrollAreaHeight = function (height) {
        //const newHeight = height + 20;
        var newHeight = height;
        if (scollAreaHeight !== newHeight) {
            setScrollAreaHeight(newHeight);
        }
    };
    var AddFavorite = function (item) {
        var tempArray = __spreadArray([], directoryFavs, true);
        tempArray.push(__assign({}, item));
        dispatch(DirectoryStore.UpdateFavs(tempArray));
    };
    var RemoveFavorite = function (item) {
        var tempArray = directoryFavs.filter(function (i) { return i.SiteId !== item.SiteId; });
        dispatch(DirectoryStore.UpdateFavs(__spreadArray([], tempArray, true)));
    };
    var sort = function (sortColumn) {
        var sortDirection = "";
        if (sortColumn !== directory.Context.Sort) {
            sortDirection = "asc";
        }
        else if (sortColumn === directory.Context.Sort) {
            if (directory.Context.SortDirection === "desc") {
                sortDirection = "asc";
            }
            else {
                sortDirection = "desc";
            }
        }
        dispatch(DirectoryStore.UpdateContext({
            Sort: sortColumn,
            //@ts-ignore
            SortDirection: sortDirection
        }));
    };
    React.useEffect(function () {
        var table = document.querySelector(".directory-list-table table");
        if (table !== undefined && (table === null || table === void 0 ? void 0 : table.clientHeight) !== undefined) {
            updateScrollAreaHeight(table.clientHeight);
        }
    }, [directory.Items]);
    /**
     * Returns true if the metadata link should be rendered for the given field name.
     * Renders the links only for the first field that has links because we ground the links under one field.
     *
     * @param fieldName The name of the field
     */
    var shouldRenderMetadataLink = function (fieldName) {
        var itemNames = directory.Columns.filter(function (md) { return md.Type === "custom-link"; }).map(function (md) { return md.ProperyName; });
        var links = directory.Items.flatMap(function (item) { return item.Metadata.filter(function (md) { return itemNames.some(function (itemName) { return itemName === md.SpColumnsName; }); }); });
        if (!links || !(links.length > 0)) {
            return false;
        }
        /**
         * If the field name is not the same as the first link's field name, then we don't render the links
         * because we don't want to render the links for every field, only for the first one.
         */
        if (fieldName !== links[0].SpColumnsName) {
            return false;
        }
        return true;
    };
    return (React.createElement("div", { className: "directory-list-table " + (fixedMode === true ? "fixed-first-column" : ""), "data-pw": "directory-list-table" },
        React.createElement(Async, { uid: DirectorySearchActionTypes.Directory_Load, status: AsyncTypes.AsyncStatus.Started },
            React.createElement("div", { className: "loading-skeleton" },
                React.createElement("div", { className: "header" }),
                React.createElement("div", { className: "items" }, [1, 1, 1, 1, 1, 1, 1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                    React.createElement("div", { className: "pic" },
                        React.createElement("div", { className: "circle animated-wave" })),
                    React.createElement("div", { className: "title" },
                        React.createElement("div", { className: "title-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box" })))); })))),
        React.createElement(Async, { uid: DirectorySearchActionTypes.Directory_Load, status: AsyncTypes.AsyncStatus.Succeeded },
            React.createElement(Scrollbar, { noScrollY: true, style: { minHeight: scollAreaHeight + "px" }, onScroll: function (values) { return setFixedScrollLeft(values.scrollLeft); } },
                React.createElement("table", { onLoad: function (table) {
                        updateScrollAreaHeight(table.currentTarget.clientHeight);
                    }, className: directoryIsLoading === true ? " loading-from-server" : "" },
                    React.createElement("thead", null,
                        React.createElement("tr", null, directory.Columns.map(function (col, colIndex) {
                            return col.ProperyName !== "Privacy" && (col.Type !== "custom-link" || shouldRenderMetadataLink(col.ProperyName)) ? (React.createElement("td", { style: col.Type === "workspace-name"
                                    ? {
                                        left: fixedScrollLeft + "px"
                                    }
                                    : {}, className: "column-" + col.Type + " " + (col.SortEnabled === false ? " sort-disabled" : ""), "data-pw": "column-name", key: colIndex },
                                col.Type === "workspace-name" ? (React.createElement("div", { className: "pin-first-column-wrap", "data-pw": "pin-first-column-wrap" },
                                    React.createElement("span", { className: "pin-first-column", onClick: function () { return setFixedMode(!fixedMode); } },
                                        React.createElement(InfoTooltip, { content: intl.formatMessage({
                                                id: "component.directory.list_table.pin_col",
                                                defaultMessage: "Pin workspace column from scrolling"
                                            }), placement: "right", delayShow: 1000 },
                                            React.createElement("i", { className: "la la-thumbtack" }))))) : null,
                                React.createElement("div", { onClick: function () {
                                        if (col.SortEnabled === true && col.Type !== "custom-link") {
                                            sort(col.ProperyName);
                                        }
                                    } },
                                    col.Type === "custom-link" ? React.createElement("span", null, intl.formatMessage({ id: "workspaces.metadata.external_links", defaultMessage: "External Links" })) : null,
                                    col.Type !== "custom-link" ? React.createElement("span", null, col.Title) : null,
                                    col.SortEnabled === true && col.Type !== "custom-link" ? (React.createElement("div", { "data-pw": "sort", className: "sort" + (col.ProperyName === directory.Context.Sort ? " current-sort" : "") },
                                        React.createElement("div", { className: "desc" + (directory.Context.SortDirection === "desc" ? " current-direction" : ""), "data-pw": "column-desc" },
                                            React.createElement("i", { className: "fas fa-caret-down" })),
                                        React.createElement("div", { className: "asc" + (directory.Context.SortDirection === "asc" ? " current-direction" : ""), "data-pw": "column-asc" },
                                            React.createElement("i", { className: "fas fa-caret-up" })))) : null))) : null;
                        }))),
                    React.createElement("tbody", null,
                        directory.Items.map(function (item, idIndex) { return (React.createElement("tr", { key: idIndex }, directory.Columns.map(function (col, colIndex) {
                            return (col.Type !== "custom-link" || shouldRenderMetadataLink(col.ProperyName)) && (React.createElement("td", { style: col.Type === "workspace-name"
                                    ? {
                                        left: fixedScrollLeft + "px"
                                    }
                                    : {}, key: "dir-item-" + idIndex + "-col-" + colIndex, className: "fadeinskel-1s column-type-" + convertStringToSafePropertyName(col.Type) },
                                React.createElement("div", null,
                                    col.Type === "template-type" ? (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "cat-icon" }, item[col.ProperyName] === "SharePoint Communications" || item[col.ProperyName] === "SharePoint Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" })) : item[col.ProperyName] === "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png" })) : (React.createElement("img", { src: iconViva }))),
                                        React.createElement("div", { className: "cat-title", "data-pw": "type" },
                                            React.createElement("div", { className: "cat-title-text" },
                                                item.TemplateType === "Team" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.list_table.team", defaultMessage: "Microsoft Team" }))) : null,
                                                item.TemplateType === "SharePoint Team" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.list_table.team_site", defaultMessage: "Team Site" }))) : null,
                                                item.TemplateType === "SharePoint Communications" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.list_table.comm_site", defaultMessage: "Communication Site" }))) : null,
                                                item.TemplateType === "Viva Engage Community" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.list_table.viva_engage_community", defaultMessage: "Viva Engage Community" }))) : null),
                                            item.Privacy !== "Custom" ? React.createElement("div", { className: "cat-title-privacy" }, item.Privacy) : null))) : null,
                                    col.Type === "user" ? (React.createElement(React.Fragment, null,
                                        React.createElement(DirectoryViewMembers, { directoryItem: item }))) : null,
                                    col.Type === "text" ? (React.createElement("p", { "data-pw": "workspace-type" }, item[col.ProperyName])) : null,
                                    col.Type === "description" ? (React.createElement("p", { "data-pw": "description" },
                                        React.createElement(ReadMore, { lines: 3 }, item[col.ProperyName]))) : null,
                                    col.Type === "bold-text" ? (React.createElement("p", null,
                                        React.createElement("strong", null, item[col.ProperyName]))) : null,
                                    col.Type === "workspace-name" ? (React.createElement(React.Fragment, null,
                                        showFavs ? (React.createElement("div", { className: "fav-star " + (directoryFavs.some(function (i) { return i.SiteId === item.SiteId; }) === true ? "on" : "off"), "data-pw": "fav-star" },
                                            React.createElement("div", { className: "star-icon" },
                                                React.createElement("i", { className: "la la-star star-on", onClick: function () { return RemoveFavorite(item); } }),
                                                React.createElement("i", { className: "la la-star-o star-off", onClick: function () { return AddFavorite(item); } })))) : (React.createElement("div", { className: "fav-star" })),
                                        React.createElement("a", { className: "profile-icon", onClick: function (event) { return props.goToTeamsLink(event, props.getDirectoryItemUrl(item)); }, href: props.getDirectoryItemUrl(item), target: "_blank" },
                                            React.createElement(OfficeProfilePicture, { size: "sm", type: item.TemplateType !== undefined && item.TemplateType !== null
                                                    ? item.TemplateType.indexOf("SharePoint") > -1
                                                        ? "SharePoint"
                                                        : "Teams"
                                                    : "Teams", base64image: item.Base64Logo }),
                                            item.IsVisibleInDirectory === false ? (React.createElement("div", { className: "hidden-workspace-icon" },
                                                React.createElement(InfoTooltip, { content: intl.formatMessage({
                                                        id: "component.directory.list_table.hidden_workspace",
                                                        defaultMessage: "Hidden Workspace"
                                                    }) },
                                                    React.createElement("i", { className: "fa fa-eye-slash" })))) : null),
                                        React.createElement("a", { className: "name-wrapper", "data-pw": "workspace-name", onClick: function (event) { return props.goToTeamsLink(event, props.getDirectoryItemUrl(item)); }, href: props.getDirectoryItemUrl(item), target: "_blank" },
                                            React.createElement("span", null, item.Name)),
                                        React.createElement(DirectoryUpdateItem, { directoryItem: item }))) : null,
                                    col.Type === "custom-choice-single" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                    col.Type === "custom-choice-multiple" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                    col.Type === "custom-date" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                    col.Type === "custom-link" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                    col.Type === "custom-text-single" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                    col.Type === "custom-text-multiple" ? (React.createElement(ReadMore, { lines: 3 }, getMetadataValue(item, col.ProperyName, col.Type))) : // <>{getMetadataValue(item, col.ProperyName, col.Type)}</>
                                        null,
                                    col.Type === "custom-managed-metadata-single" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                    col.Type === "custom-managed-metadata-multiple" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null)));
                        }))); }),
                        directory.Items.length === 0 ? (React.createElement("tr", { className: "no-results" },
                            React.createElement("td", { colSpan: 9 },
                                React.createElement(NoResults, null)))) : null))))));
});
export default DirectoryListTable;
