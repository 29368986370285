import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./team-information-pill.scss";
export var TeamInformationPrivacyPill = function () {
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    if (teamInformation === null) {
        return React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 0, bodyWidth: 60, style: { marginRight: "10px" } });
    }
    var showPrivacyPill = teamInformation.Privacy !== null && teamInformation.Privacy !== undefined && teamInformation.Privacy !== "";
    if (!showPrivacyPill) {
        return null;
    }
    return (React.createElement("div", { className: "privacy team-information-pill" },
        React.createElement("div", { className: "nested-property" },
            teamInformation.Privacy === "Public" ? React.createElement("i", { className: "fa-light fa-earth-americas" }) : React.createElement("i", { className: "fa-regular fa-" + "lock" }),
            React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "page.request_details.details.permissions_type.".concat(teamInformation.Privacy.toLowerCase(), "_label"), defaultMessage: teamInformation.Privacy })))));
};
