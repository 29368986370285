import React from "react";
export var OrchestryButtonIcon = function (props) {
    switch (props.type) {
        case "font":
            return React.createElement("i", { "data-font-icon": true, style: { color: props.iconColor, fontSize: props.iconFontSize }, className: props.iconName });
        case "svg":
            return (React.createElement(React.Fragment, null,
                React.createElement("img", { "data-svg": "".concat(props.activeIconName ? "base" : "nohover"), src: props.baseIconName, alt: "icon" }),
                props.activeIconName && React.createElement("img", { "data-svg": "active", src: props.activeIconName, alt: "icon" })));
        default:
            return null;
    }
};
