import { TenantInsight } from "appv2/api/partner-tools/msp-tenant-details";
import { useGetMspTenantsQuery } from "appv2/api/partner-tools/partners.api";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
/**
 * Hook to determine if the current user is a partner viewing a self-led tenant.
 */
export var useUserIsPartnerNotViewingPartnerLedTenant = function () {
    var _a = useHealthCheckRouteParams(), userType = _a.userType, tenantGuid = _a.tenantGuid;
    var _b = useGetMspTenantsQuery({
        queryParameters: {
            filter: "(TenantGuid == \"".concat(tenantGuid, "\")"),
            orderBy: "",
            page: 1,
            pageSize: 1
        }
    }, { skip: userType !== "partner" }), data = _b.data, isLoading = _b.isLoading;
    var tenantIsNotPartnerLed = data === null || data === void 0 ? void 0 : data.Items[0].Insights.every(function (insight) { return insight !== TenantInsight.PartnerLed; });
    var userIsPartnerViewingNotPartnerLedTenant = userType === "partner" && tenantIsNotPartnerLed;
    return { userIsPartnerViewingNotPartnerLedTenant: userIsPartnerViewingNotPartnerLedTenant, isLoading: isLoading };
};
