var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./workspace-bulk-action-remediate-modal.scss";
import { useSelector, useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import OrchModal from "app/components/common/orch-modal";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import * as LifecyclePoliciesListStore from "app/store/lifecycle-policies/list";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import LifecycleReportService from "app/services/lifecycle-report";
import LifecycleService from "app/services/lifecycle";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { LifecyclePolicyListActionTypes } from "app/store/lifecycle-policies/list";
import InfoTooltip from "app/components/common/infotooltip";
import AnimatedLoader from "app/components/common/animated-loader";
import moment from "moment";
var LifecycleWorkspaceBulkActionRemediateModal = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState([]), siteIdsToProcess = _a[0], setSiteIdsToProcess = _a[1];
    var reportItems = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Items; });
    var reportContext = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Context; });
    var lifecycleArchivalPolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var _b = React.useState(null), defaultPolicies = _b[0], setDefaultPolicies = _b[1];
    var _c = React.useState([]), assignedPoliciesChanges = _c[0], setAssignedPolicyChanges = _c[1];
    var _d = React.useState(false), modalOpen = _d[0], setModalOpen = _d[1];
    var _e = React.useState(false), archivalPoliciesLoaded = _e[0], setArchivalPoliciesLoaded = _e[1];
    var _f = React.useState(false), processing = _f[0], setProcessing = _f[1];
    var _g = React.useState([]), clonePreviewItems = _g[0], setClonePreviewItems = _g[1];
    var _h = React.useState(false), showComplete = _h[0], setShowComplete = _h[1];
    var _j = React.useState(null), bulkAssignJobId = _j[0], setBulkAssignJobId = _j[1];
    var _k = React.useState(null), remediationStatuses = _k[0], setRemediationStatuses = _k[1];
    var _l = React.useState(1), remediationCurrentStep = _l[0], setRemediationCurrentStep = _l[1];
    var _m = React.useState(null), remediateRestoreJobId = _m[0], setRemediateRestoreJobId = _m[1];
    var _o = React.useState(null), remediateRestoreJobStatus = _o[0], setRemediateRestoreJobStatus = _o[1];
    var _p = React.useState(false), hideIneligible = _p[0], setHideIneligible = _p[1];
    // const [previousStatsLoading, setPreviousStatsLoading] = React.useState(false);
    var _q = React.useState([]), previousStatsToLoad = _q[0], setPreviousStatsToLoad = _q[1];
    var _r = React.useState(""), previousStatsThatIsLoading = _r[0], setPreviousStatsThatIsLoading = _r[1];
    var _s = React.useState([]), previousArchivalIntegrityChecks = _s[0], setPreviousArchivalIntegrityChecks = _s[1];
    var _t = React.useState(false), executeButtonClicked = _t[0], setExecuteButtonClicked = _t[1];
    var _u = React.useState([]), executingUpdatingStats = _u[0], setExecutingUpdatingStats = _u[1];
    var _v = React.useState([]), executingThatIsUpdatingStatus = _v[0], setExecutingThatIsUpdatingStatus = _v[1];
    var _w = React.useState([]), executedUpgradedDone = _w[0], setExecutedUpgradedDone = _w[1];
    var _x = React.useState(false), executedComplete = _x[0], setExecutedComplete = _x[1];
    var _y = React.useState(180), tableContentMarginTop = _y[0], setTableContentMarginTop = _y[1];
    var remediationHeaderTable = React.useRef();
    var _z = React.useState(true), isArchivalReport = _z[0], setIsArchivalReport = _z[1];
    React.useEffect(function () {
        // if (reportContext !== null && reportContext.View !== null) {
        //    var isContextArchivalReport = reportContext.View.indexOf("recently-archived") > -1;
        //    if (isContextArchivalReport !== isArchivalReport) {
        //       setIsArchivalReport(isContextArchivalReport);
        //    }
        // }
    }, [reportContext]);
    React.useEffect(function () {
        if (props.SiteReportItemIds !== undefined && modalOpen === true) {
            setSiteIdsToProcess(props.SiteReportItemIds);
        }
    }, [props.SiteReportItemIds, modalOpen]);
    React.useEffect(function () {
        if (modalOpen === true && processing === false) {
            setShowComplete(false);
            setExecutedComplete(false);
            setExecuteButtonClicked(false);
            setExecutedUpgradedDone([]);
            setAssignedPolicyChanges([]);
            setExecutingThatIsUpdatingStatus([]);
            setExecutingUpdatingStats([]);
            setPreviousStatsToLoad([]);
            setPreviousStatsThatIsLoading("");
            setHideIneligible(false);
            setRemediateRestoreJobStatus(null);
            setRemediateRestoreJobId(null);
            LifecycleReportService.getDefaultPolicies().then(function (policies) {
                setDefaultPolicies(policies.defaultArchivalPolicies);
            });
        }
        if (modalOpen === true) {
            updateContentMarginTopHeight();
            dispatch(LifecyclePoliciesListStore.Load("Archival"));
            AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, undefined, null, LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load).then(function () {
                setTimeout(function () { return setArchivalPoliciesLoaded(true); }, 500);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen]);
    React.useEffect(function () {
        if (modalOpen === true && processing === false) {
            setRemediationCurrentStep(1);
            setRemediationStatuses(null);
            setShowComplete(false);
            LifecycleService.BulkRemediationInitialStatus(props.SiteReportItemIds).then(function (statuses) {
                setRemediationStatuses(statuses);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen]);
    React.useEffect(function () {
        updateContentMarginTopHeight();
        window.addEventListener("resize", updateContentMarginTopHeight);
        return function () {
            window.removeEventListener("resize", updateContentMarginTopHeight);
        };
    }, [reportItems]);
    var updateContentMarginTopHeight = function () {
        var newHeight = 0;
        try {
            if (remediationHeaderTable !== undefined && remediationHeaderTable.current !== undefined) {
                setTableContentMarginTop(remediationHeaderTable.current.clientHeight);
            }
        }
        catch (_a) { }
    };
    var canForcePolicy = function (reportItem) {
        var canForce = true;
        if (reportItem.IsArchived || reportItem.ArchivalStatus === "Done") {
            canForce = false;
        }
        if (reportItem.IsDeleted || reportItem.ArchivalStatus === "Deleted") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Failed") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "PendingApproval") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Processing") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "ReadyToArchive") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Extended") {
            canForce = false;
        }
        if (reportItem.IsProtected === true) {
            canForce = false;
        }
        // if (reportItem.SharePointTeamSiteHasNoGroup == true) {
        //    canForce = false;
        // }
        //DeletionBlockedByRetentionPolicy
        var policy = getPolicyForWorkspace(reportItem);
        if (policy === null) {
            canForce = false;
        }
        return canForce;
    };
    var getExecuteAction = function (reportItem) {
        var title = "";
        var desc = "";
        var canDo = true;
        title = "Force Archival";
        if (reportItem.IsArchived || reportItem.ArchivalStatus === "Done") {
            // title = "Nothing";
            title = "Already Archived";
            canDo = false;
        }
        if (reportItem.IsDeleted || reportItem.ArchivalStatus === "Deleted") {
            // title = "Nothing";
            title = "Deleted";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Failed") {
            // title = "Nothing";
            title = "Previous Execution Failed";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "PendingApproval") {
            // title = "Nothing";
            title = "Archival already in progress - waiting for approval";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Processing") {
            // title = "Nothing";
            title = "Already running archival";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "ReadyToArchive") {
            // title = "Nothing";
            title = "Already in queue for archival";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Extended") {
            // title = "Nothing";
            title = "Workspace Renewed";
            canDo = false;
        }
        if (reportItem.IsProtected === true) {
            // title = "Nothing";
            title = "Workpspace is protected from Archival";
            canDo = false;
        }
        // if (reportItem.SharePointTeamSiteHasNoGroup == true) {
        //    // title = "Nothing";
        //    title = "Workpspace group is missing from Team Site. This is usually because of a pending deletion.";
        //    canDo = false;
        // }
        var policy = getPolicyForWorkspace(reportItem);
        if (policy === null) {
            // title = "Nothing";
            title = "There is no policy assigned";
            canDo = false;
        }
        if (policy !== null) {
            return (React.createElement("div", { className: "action" },
                React.createElement("div", { className: "a-left" },
                    React.createElement("i", { className: "far fa-" + (canDo === true ? "check-circle" : "circle") })),
                React.createElement("div", { className: "a-right" },
                    React.createElement("div", { className: "a-title" }, title),
                    desc !== "" ? React.createElement("div", { className: "a-description" }, desc) : null)));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    var getDefaultPolicy = function (reportItem) {
        var foundDefaultWorkspace = defaultPolicies === null || defaultPolicies === void 0 ? void 0 : defaultPolicies.find(function (ap) { return ap.Application === reportItem.Application; });
        return foundDefaultWorkspace !== null && foundDefaultWorkspace !== undefined ? foundDefaultWorkspace : null;
    };
    var getAssignablePolicies = function (reportItem) {
        var policies = [];
        var defaultPolicy = getDefaultPolicy(reportItem);
        if (defaultPolicy !== null && defaultPolicy !== undefined) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (lifecycleArchivalPolicies !== null && lifecycleArchivalPolicies !== undefined) {
            policies = __spreadArray(__spreadArray([], policies, true), lifecycleArchivalPolicies
                .filter(function (ap) { return ap.Application === reportItem.Application; })
                .map(function (ap) { return ({
                id: ap.GUID,
                value: ap.GUID,
                title: ap.Name,
                description: ap.Description
            }); }), true);
        }
        return policies;
    };
    var undoAssignedPolicy = function (siteId) {
        setAssignedPolicyChanges(__spreadArray([], assignedPoliciesChanges.filter(function (cp) {
            if (cp.SiteId !== siteId) {
                return true;
            }
            else {
                return false;
            }
        }), true));
    };
    var updateAssignedPolicies = function (item, apGuid) {
        var apc = assignedPoliciesChanges.find(function (apc) { return apc.SiteId === item.SiteId; });
        if (apc === undefined) {
            if (item.ArchivalPolicyGuid !== apGuid) {
                setAssignedPolicyChanges(__spreadArray(__spreadArray([], assignedPoliciesChanges, true), [{ SiteId: item.SiteId, ArchivalPolicyGuid: apGuid, IsProtected: item.IsProtected }], false));
            }
        }
        else {
            if (apc.ArchivalPolicyGuid === item.ArchivalPolicyGuid && apc.IsProtected === item.IsProtected) {
                setAssignedPolicyChanges(assignedPoliciesChanges.filter(function (apc) { return (apc.SiteId === item.SiteId ? false : true); }));
            }
            else {
                setAssignedPolicyChanges(__spreadArray([], assignedPoliciesChanges.map(function (cp) { return (cp.SiteId === item.SiteId ? __assign(__assign({}, cp), { ArchivalPolicyGuid: apGuid }) : cp); }), true));
            }
        }
    };
    var updateProtected = function (item, isProtected) {
        var apc = assignedPoliciesChanges.find(function (apc) { return apc.SiteId === item.SiteId; });
        if (apc === undefined) {
            setAssignedPolicyChanges(__spreadArray(__spreadArray([], assignedPoliciesChanges, true), [{ SiteId: item.SiteId, ArchivalPolicyGuid: item.ArchivalPolicyGuid, IsProtected: isProtected }], false));
        }
        else {
            if (apc.IsProtected === item.IsProtected && apc.ArchivalPolicyGuid === item.ArchivalPolicyGuid) {
                setAssignedPolicyChanges(assignedPoliciesChanges.filter(function (apc) { return (apc.SiteId === item.SiteId ? false : true); }));
            }
            else {
                setAssignedPolicyChanges(__spreadArray([], assignedPoliciesChanges.map(function (cp) { return (cp.SiteId === item.SiteId ? __assign(__assign({}, cp), { IsProtected: isProtected }) : cp); }), true));
            }
        }
        //setAssignedPolicyChanges([...assignedPoliciesChanges.map(cp => (cp.SiteId === item.SiteId ? { ...item, IsProtected: isProtected } : cp))]);
    };
    var getPolicyForWorkspace = function (reportItem) {
        var policyName = null;
        if (reportItem.ArchivalPolicyName !== null && reportItem.ArchivalPolicyName !== undefined) {
            policyName = reportItem.ArchivalPolicyName;
        }
        else {
            var foundDefaultPolicy = getDefaultPolicy(reportItem);
            if (foundDefaultPolicy !== null && foundDefaultPolicy !== undefined) {
                policyName = foundDefaultPolicy.Name;
            }
        }
        return policyName;
    };
    var getCountOfExecutablePolicies = function () {
        var count = 0;
        reportItems
            .filter(function (ri) { return siteIdsToProcess === null || siteIdsToProcess === void 0 ? void 0 : siteIdsToProcess.some(function (pg) { return pg === ri.SiteId; }); })
            .forEach(function (item) {
            if (canForcePolicy(item)) {
                count++;
            }
        });
        return count;
    };
    var getExecutablePoliciesSiteIds = function () {
        var ids = [];
        reportItems
            .filter(function (ri) { return siteIdsToProcess === null || siteIdsToProcess === void 0 ? void 0 : siteIdsToProcess.some(function (pg) { return pg === ri.SiteId; }); })
            .forEach(function (item) {
            if (isWorkspaceEligibleForRemediationExecution(item)) {
                ids.push(item.SiteId);
            }
        });
        return ids;
    };
    var getDropdownPolicyValue = function (item) {
        var apc = assignedPoliciesChanges.find(function (apc) { return apc.SiteId === item.SiteId; });
        if (apc !== undefined) {
            return apc.ArchivalPolicyGuid;
        }
        return item.ArchivalPolicyGuid;
    };
    var cloneSelectionToAllWorkspaces = function (item) {
        var tempAssignedPolicyChanges = __spreadArray([], assignedPoliciesChanges, true);
        var newPolicyGuid = getDropdownPolicyValue(item);
        reportItems
            .filter(function (ri) { return (siteIdsToProcess === null || siteIdsToProcess === void 0 ? void 0 : siteIdsToProcess.some(function (sid) { return sid === ri.SiteId; })) && ri.SiteId !== item.SiteId && ri.Application === item.Application; })
            .forEach(function (reportItem) {
            if (isWorkspaceEligibleForPolicyReAssignmentForRemediation(reportItem)) {
                var apc = tempAssignedPolicyChanges.find(function (apc) { return apc.SiteId === reportItem.SiteId; });
                if (apc === undefined) {
                    tempAssignedPolicyChanges.push({ SiteId: reportItem.SiteId, ArchivalPolicyGuid: newPolicyGuid, IsProtected: reportItem.IsProtected });
                }
                else {
                    if (apc.ArchivalPolicyGuid === reportItem.ArchivalPolicyGuid && apc.IsProtected === reportItem.IsProtected) {
                        tempAssignedPolicyChanges = tempAssignedPolicyChanges.filter(function (apc) { return (apc.SiteId === reportItem.SiteId ? false : true); });
                    }
                    else {
                        tempAssignedPolicyChanges = __spreadArray([], tempAssignedPolicyChanges.map(function (cp) { return (cp.SiteId === reportItem.SiteId ? __assign(__assign({}, cp), { ArchivalPolicyGuid: newPolicyGuid }) : cp); }), true);
                    }
                }
            }
        });
        setAssignedPolicyChanges(tempAssignedPolicyChanges);
        //}
        setClonePreviewItems([]);
    };
    var isPolicyOrProtectedNew = function (item) {
        var foundPolicyAssignment = assignedPoliciesChanges.find(function (ri) { return ri.SiteId === item.SiteId; });
        if (foundPolicyAssignment !== null && foundPolicyAssignment !== undefined) {
            return item.ArchivalPolicyGuid !== foundPolicyAssignment.ArchivalPolicyGuid || item.IsProtected !== foundPolicyAssignment.IsProtected;
        }
        return false;
    };
    var isWorkspaceEligibleForPolicyReAssignmentForRemediation = function (reportItem) {
        var isEligible = true;
        if (reportItem.ArchivalStatus !== "Done") {
            isEligible = false;
        }
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            var status = getRemediationStatus(reportItem);
            if (status !== null && status !== undefined) {
                if (status.SharePointStatus !== "Active") {
                    isEligible = false;
                }
            }
        }
        return isEligible;
    };
    var isWorkspaceEligibleForRemediationExecution = function (reportItem) {
        var isEligible = true;
        if (reportItem.ArchivalStatus !== "Done") {
            isEligible = false;
        }
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            var status = getRemediationStatus(reportItem);
            if (status !== null && status !== undefined) {
                if (status.SharePointStatus !== "Active") {
                    isEligible = false;
                }
            }
        }
        if (getPolicyForWorkspace(reportItem) === null && getDropdownPolicyValue(reportItem) === null) {
            isEligible = false;
        }
        if (reportItem.Application === "VivaEngageCommunity")
            isEligible = false;
        return isEligible;
    };
    var getWorkspaceNoEligibleForPolicyChangeReason = function (reportItem) {
        var isEligible = "";
        if (reportItem.ArchivalStatus !== "Done") {
            isEligible = "You cannot remediate a workspace that is not already archived. The current archival status is: " + getWorkspaceArchivalStatusText(reportItem);
        }
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            var remediationStatus = getRemediationStatus(reportItem);
            if (remediationStatus !== null && remediationStatus !== undefined) {
                if (remediationStatus.SharePointStatus !== "Active") {
                    isEligible = "The associated SharePoint site is not active.";
                }
            }
        }
        return isEligible;
    };
    var getsWorkspaceEligibleForRemediationExecutionReason = function (reportItem) {
        var isEligible = getWorkspaceNoEligibleForPolicyChangeReason(reportItem);
        if (getPolicyForWorkspace(reportItem) === null && getDropdownPolicyValue(reportItem) === null) {
            isEligible = "No Assigned Policy";
        }
        return isEligible;
    };
    var onClickAssignPolicies = function () {
        setProcessing(true);
        LifecycleService.BulkAssignArchivalPolicies(assignedPoliciesChanges).then(function (jobIdData) {
            setBulkAssignJobId(jobIdData.jobId);
            //setProcessing(false);
            // setShowComplete(true);
            // setTimeout(() => {
            // dispatch(LifecycleReportStore.Refresh());
            // }, 1000);
        });
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (bulkAssignJobId !== null && bulkAssignJobId !== undefined) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                LifecycleService.GetBulkAssignStatus(bulkAssignJobId).then(function (data) {
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_1 = false;
                        runAgain();
                    }
                    else {
                        // setShowComplete(true);
                        setTimeout(function () {
                            dispatch(LifecycleReportStore.Refresh(props.Slot));
                            AsyncWait(undefined, AsyncStatus.Succeeded, null, null, "Report_Refresh").then(function () {
                                setTimeout(function () {
                                    setProcessing(false);
                                    setAssignedPolicyChanges([]);
                                }, 500);
                            });
                        }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (bulkAssignJobId !== null && bulkAssignJobId !== undefined) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null && timerID !== undefined) {
                clearInterval(timerID);
            }
        };
    }, [bulkAssignJobId]);
    var getWorkspaceArchivalStatusText = function (reportItem) {
        if (reportItem !== null && reportItem !== undefined) {
            if (reportItem.ArchivalStatus === "Active" || reportItem.ArchivalStatus === null) {
                // if (reportItem.DaysInactive !== null && props.inactiveDays !== null && reportItem.DaysInactive >= props.inactiveDays) {
                //    return "Inactive";
                // } else {
                return "Active";
                // }
            }
            if (reportItem.ArchivalStatus === "PendingApproval") {
                return "Pending Approval";
            }
            if (reportItem.ArchivalStatus === "Extended") {
                return "Workspace Renewed";
            }
            if (reportItem.ArchivalStatus === "ReadyToArchive") {
                return "Ready to Archive";
            }
            if (reportItem.ArchivalStatus === "Processing") {
                return "Processing Archival";
            }
            if (reportItem.ArchivalStatus === "Done") {
                return "Archived";
            }
            if (reportItem.ArchivalStatus === "Failed") {
                return "Archival Failed";
            }
            if (reportItem.ArchivalStatus === "Deleted") {
                return "Deleted";
            }
        }
    };
    var getWorkspaceArchivalStatusClass = function (reportItem) {
        var returnClass = "active";
        if (reportItem.ArchivalStatus !== null && reportItem.ArchivalStatus !== undefined) {
            if (reportItem.ArchivalStatus.toLowerCase() === "active") {
                // if (reportItem.DaysInactive !== null && props.inactiveDays !== null && reportItem.DaysInactive >= props.inactiveDays) {
                //    returnClass = "inactive";
                // }
            }
            else {
                returnClass = reportItem.ArchivalStatus.toLowerCase();
            }
        }
        else {
            returnClass = "active";
        }
        return returnClass;
    };
    var getRemediationStatus = function (reportItem) {
        // console.log(reportItem.SiteId, remediationStatuses);
        if (remediationStatuses !== null && remediationStatuses !== undefined && Array.isArray(remediationStatuses) && remediationStatuses.some(function (rs) { return rs.SiteId === reportItem.SiteId; })) {
            var foundStatus = remediationStatuses.find(function (rs) { return rs.SiteId === reportItem.SiteId; });
            // console.log(reportItem.SiteId, foundStatus);
            return foundStatus;
        }
        else {
            return null;
        }
    };
    var getRemediationGroupOrSiteStatus = function (remediateGroupOrSiteStatus) {
        return (React.createElement("div", { className: "remediation-status status-color-" + remediateGroupOrSiteStatus.toLowerCase() }, remediateGroupOrSiteStatus === "NotFound" ? ("Not Found") : remediateGroupOrSiteStatus === "Active" ? ("Active") : remediateGroupOrSiteStatus === "CouldNotRestore" ? ("Could Not Restore") : remediateGroupOrSiteStatus === "Loading" ? (React.createElement("div", { className: "lds-ellipsis" },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))) : remediateGroupOrSiteStatus === "NotTeamOrTeamSite" ? ("Not Team Or TeamSite") : remediateGroupOrSiteStatus === "FoundInRecycleBin" ? ("Recycle Bin") : ("")));
    };
    var getRemediationStepClass = function (stepNo) {
        if (stepNo === remediationCurrentStep) {
            return "step-current";
        }
        if (stepNo > remediationCurrentStep) {
            return "step-waiting";
        }
        if (stepNo < remediationCurrentStep) {
            return "step-done";
        }
    };
    var isWorkspaceRestorable = function (item) {
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            var remediateStatus = remediationStatuses.find(function (rs) { return rs.SiteId === item.SiteId; });
            if (remediateStatus !== null && remediateStatus !== undefined) {
                if (remediateStatus.SharePointStatus === "FoundInRecycleBin") {
                    return true;
                }
            }
        }
        return false;
    };
    var isWorkspaceAlreadyRestored = function (item) {
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            var remediateStatus = remediationStatuses.find(function (rs) { return rs.SiteId === item.SiteId; });
            if (remediateStatus !== null && remediateStatus !== undefined) {
                if (remediateStatus.SharePointStatus === "Active" && (remediateStatus.GroupStatus === "Active" || remediateStatus.GroupStatus === "NotTeamOrTeamSite")) {
                    return true;
                }
            }
        }
        return false;
    };
    var onClickRemediateRestoreJobStart = function () {
        if (onClickRemediateRestoreJobStart !== null) {
            setRemediateRestoreJobStatus("Pending");
            var statuesToSend = remediationStatuses;
            statuesToSend = statuesToSend.filter(function (ss) { return ss.GroupStatus === "FoundInRecycleBin" || ss.SharePointStatus === "FoundInRecycleBin"; });
            LifecycleService.BulkRemediationRestorationStartJob(statuesToSend).then(function (jobId) {
                // console.log("jobId", jobId);
                setRemediateRestoreJobId(jobId);
                //setProcessing(false);
            });
        }
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (remediateRestoreJobId !== null && remediateRestoreJobId !== undefined) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_2 = true;
                LifecycleService.BulkGetRemediationRestorationJobStatus(remediateRestoreJobId).then(function (data) {
                    if (data !== undefined && data !== null && data.statusObject !== undefined && data.statusObject !== null) {
                        var updates = data.statusObject;
                        if (remediationStatuses !== undefined && remediationStatuses !== null) {
                            setRemediationStatuses(remediationStatuses.map(function (r) {
                                var foundUpdate = updates.find(function (rs) { return rs.SiteId === r.SiteId; });
                                if (foundUpdate !== undefined) {
                                    return foundUpdate;
                                }
                                else {
                                    return r;
                                }
                            }));
                        }
                    }
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_2 = false;
                        runAgain();
                    }
                    else {
                        setRemediateRestoreJobStatus("Done");
                        setProcessing(false);
                        setTimeout(function () { }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (remediateRestoreJobId !== null && remediateRestoreJobId !== undefined) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null && timerID !== undefined) {
                clearInterval(timerID);
            }
        };
    }, [remediateRestoreJobId]);
    var removeWorkspaceFromList = function (reportItem) {
        if (siteIdsToProcess !== undefined) {
            setSiteIdsToProcess(siteIdsToProcess.filter(function (id) { return (id === reportItem.SiteId ? false : true); }));
        }
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            setRemediationStatuses(remediationStatuses.filter(function (rs) { return (rs.SiteId === reportItem.SiteId ? false : true); }));
        }
        setAssignedPolicyChanges(assignedPoliciesChanges.filter(function (ap) { return (ap.SiteId === reportItem.SiteId ? false : true); }));
    };
    var anyRestoreableItemsAtAll = function () {
        var anyRestoreable = false;
        if (remediationStatuses !== null && remediationStatuses !== undefined) {
            remediationStatuses.forEach(function (rs) {
                if (rs.GroupStatus === "FoundInRecycleBin" || rs.SharePointStatus === "FoundInRecycleBin") {
                    anyRestoreable = true;
                }
            });
        }
        return anyRestoreable;
    };
    var getPreviousArchivalIntegrityCheck = function (reportItem) {
        var previousCheck = previousArchivalIntegrityChecks.find(function (pc) { return pc.SiteId === reportItem.SiteId; });
        if (previousCheck !== undefined) {
            return previousCheck;
        }
        if (reportItem.ArchivalIntegrityCheck !== null && reportItem.ArchivalIntegrityCheck !== undefined) {
            return reportItem.ArchivalIntegrityCheck;
        }
        return null;
    };
    var getPreviousArchivalIntegrityCheckSums = function (reportItem) {
        if (getPreviousArchivalIntegrityCheck(reportItem) !== null) {
            var pc = getPreviousArchivalIntegrityCheck(reportItem);
            var sums = {
                fileCountDest: 0,
                fileCountSource: 0,
                missingInDestCount: 0
            };
            if (pc !== null && pc !== undefined) {
                sums.fileCountDest = pc.TotalDestFiles;
                sums.fileCountSource = pc.TotalSourceFiles;
                sums.missingInDestCount = pc.TotalMissingFiles;
                // pc.Libraries.forEach(l => {
                //    sums.fileCountDest += l.FilesCountInDestination;
                //    sums.fileCountSource += l.FilesCountInSource;
                //    sums.missingInDestCount += l.MissingFilesFromDestionation.length;
                // });
            }
            return sums;
        }
        return null;
    };
    React.useEffect(function () {
        setPreviousStatsThatIsLoading("");
        if (previousStatsToLoad.length !== 0) {
            var siteToLoadId = previousStatsToLoad[0];
            setPreviousStatsThatIsLoading(siteToLoadId);
            var sitesArray = [];
            sitesArray.push(siteToLoadId);
            LifecycleService.BulkRemediationPreviousArchivalDocumentStats(sitesArray).then(function (data) {
                setPreviousArchivalIntegrityChecks(__spreadArray(__spreadArray([], previousArchivalIntegrityChecks, true), [data[0]], false));
                setPreviousStatsToLoad(previousStatsToLoad.filter(function (ps) { return (ps === siteToLoadId ? false : true); }));
            });
        }
    }, [previousStatsToLoad]);
    var onLoadPreviousArchivalStatsClick = function () {
        var siteIdsToLoadStats = reportItems.filter(function (ri) { return (siteIdsToProcess === null || siteIdsToProcess === void 0 ? void 0 : siteIdsToProcess.some(function (pg) { return pg === ri.SiteId; })) && isWorkspaceEligibleForRemediationExecution(ri); }).map(function (ri) { return ri.SiteId; });
        setPreviousStatsToLoad(siteIdsToLoadStats);
    };
    // const onLoadPreviousArchivalStatsSingleClick = (item: ReportItem) => {
    //    var sitesArray = [];
    //    sitesArray.push(item.SiteId);
    //    LifecycleService.BulkRemediationPreviousArchivalDocumentStats(sitesArray).then(data => {
    //       setPreviousArchivalIntegrityChecks([...previousArchivalIntegrityChecks, data[0]]);
    //       setPreviousStatsToLoad(previousStatsToLoad.filter(ps => (ps === siteToLoadId ? false : true)));
    //    });
    // };
    var PreviousArchivalStatusModal = function (ppm) {
        var _a;
        var getContentAction = function (contentAction) {
            var values = [
                {
                    value: "LeaveContentInPlace",
                    title: "Archive In Place",
                    // description: "",
                    tooltip: "This option is best suited if you want to simply set your existing Teams or Groups to read only without moving content",
                    icon: "las la-archive"
                },
                {
                    value: "MoveContentOnlyToDifferentSiteLibrary",
                    title: "Copy and Delete",
                    subtitle: "To Document Library",
                    // description: "",
                    tooltip: "Copy the content of the current Site, Team or Group to a document library within an archival site. It then deletes the underlying Team, Site or Group",
                    icon: "las la-folder-open" //share-square
                },
                {
                    value: "MoveContentToDifferentSite",
                    title: "Copy and Delete",
                    subtitle: "To Sub Site",
                    // description: "",
                    tooltip: "Copy the structure and content of the current Site, Team or Group to a subsite within a site setup specifically for archiving as a modern subsite. It then deletes the underlying Team, Site or Group",
                    icon: "las la-file-export" //share-square
                },
                {
                    value: "Delete",
                    title: "Delete",
                    // description: "",
                    tooltip: "This option will delete the Site, Group or Team. Use with caution!",
                    icon: "las la-trash"
                }
            ];
            var foundAction = values.find(function (v) { return v.value === contentAction; });
            if (foundAction !== undefined) {
                return foundAction;
            }
            return null;
        };
        return (React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null, ppm.children), simpleButton: true, headerText: "Previous Archival Statistics", size: "md", modalClassName: "remediate-previous-stats-modal", modalBgClassName: "remediate-previous-stats-modal-bg", showCloseButton: true, footerCloseText: "Close" },
            React.createElement("div", { className: "previous-stats" },
                React.createElement("table", { className: "policy-information" },
                    React.createElement("tr", null,
                        React.createElement("td", null, "Policy Name"),
                        React.createElement("td", null, ppm.IntegrityCheck.PolicyName)),
                    React.createElement("tr", null,
                        React.createElement("td", null, "Policy Content Action"),
                        React.createElement("td", null, (ppm.IntegrityCheck.PolicyContentAction !== null && ppm.IntegrityCheck.PolicyContentAction !== undefined) ? (React.createElement(React.Fragment, null, getContentAction(ppm.IntegrityCheck.PolicyContentAction) !== null ? (React.createElement("div", { className: "content-action" },
                            React.createElement("span", null, (_a = getContentAction(ppm.IntegrityCheck.PolicyContentAction)) === null || _a === void 0 ? void 0 : _a.title))) : null)) : null))),
                (ppm.IntegrityCheck.PolicyContentAction !== null && ppm.IntegrityCheck.PolicyContentAction !== undefined && ppm.IntegrityCheck.PolicyContentAction !== "Delete" && ppm.IntegrityCheck.PolicyContentAction !== "LeaveContentInPlace") ? (React.createElement(React.Fragment, null,
                    React.createElement("h6", null, "Libraries"),
                    ppm.IntegrityCheck.Libraries.length === 0 ? "No libraries found" : null,
                    ppm.IntegrityCheck.Libraries.map(function (lib) { return (React.createElement("table", { className: "library-information" },
                        React.createElement("tr", null,
                            React.createElement("td", null, "Library Title"),
                            React.createElement("td", null,
                                React.createElement("strong", null, lib.LibraryTitle))),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Source Url"),
                            React.createElement("td", { className: "url" }, lib.SourceUrl)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Destination Url"),
                            React.createElement("td", { className: "url" }, lib.DestinationUrl)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Files in source"),
                            React.createElement("td", null, lib.FilesCountInSource)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Files in destination"),
                            React.createElement("td", null, lib.FilesCountInDestination)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Files missing in destination"),
                            React.createElement("td", null, lib.MissingFilesFromDestionation.length === 0 ? (React.createElement(React.Fragment, null, lib.FilesCountMissingFromDestination)) : (React.createElement("ul", null, lib.MissingFilesFromDestionation.map(function (mf) { return (React.createElement("li", null, mf)); }))))))); }))) : null)));
    };
    React.useEffect(function () {
        setExecutingThatIsUpdatingStatus([]);
        if (executingUpdatingStats.length !== 0) {
            var siteToLoadId = executingUpdatingStats[0];
            var sitesArray = [];
            sitesArray.push(siteToLoadId);
            setExecutingThatIsUpdatingStatus(sitesArray);
            LifecycleService.BulkRemediationExecuteArchivalStatusUpdate(sitesArray).then(function (data) {
                setExecutedUpgradedDone(__spreadArray(__spreadArray([], executedUpgradedDone, true), sitesArray, true));
                setExecutingUpdatingStats(executingUpdatingStats.filter(function (ps) { return !sitesArray.some(function (s) { return s === ps; }); }));
            });
        }
        else {
            if (executeButtonClicked === true && executedUpgradedDone.length > 0) {
                LifecycleService.BulkRemediationExecuteArchivalPolicyEnforcement(executedUpgradedDone).then(function (data) {
                    setExecutedComplete(true);
                });
            }
        }
    }, [executingUpdatingStats]);
    var onClickExecutePolicies = function () {
        setExecuteButtonClicked(true);
        setPreviousStatsToLoad([]);
        var siteIdsToExecute = reportItems.filter(function (ri) { return (siteIdsToProcess === null || siteIdsToProcess === void 0 ? void 0 : siteIdsToProcess.some(function (pg) { return pg === ri.SiteId; })) && isWorkspaceEligibleForRemediationExecution(ri); }).map(function (ri) { return ri.SiteId; });
        setExecutingUpdatingStats(siteIdsToExecute);
    };
    var renderPreviousStatisticsTd = function (item) {
        var _a, _b, _c;
        return (React.createElement("td", { className: "td-remediation-archival-statistics" }, previousStatsThatIsLoading === item.SiteId ? (React.createElement("div", { className: "stat-loading" },
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }))) : getPreviousArchivalIntegrityCheckSums(item) !== null ? (React.createElement(PreviousArchivalStatusModal, { ReportItem: item, IntegrityCheck: getPreviousArchivalIntegrityCheck(item) },
            React.createElement("div", { className: "count-table-button" },
                React.createElement("table", { className: "counts-table" },
                    React.createElement("tr", null,
                        React.createElement("td", null, "Dest"),
                        React.createElement("td", null, (_a = getPreviousArchivalIntegrityCheckSums(item)) === null || _a === void 0 ? void 0 :
                            _a.fileCountDest,
                            " ")),
                    React.createElement("tr", null,
                        React.createElement("td", null, "Source"),
                        React.createElement("td", null, (_b = getPreviousArchivalIntegrityCheckSums(item)) === null || _b === void 0 ? void 0 :
                            _b.fileCountSource,
                            " ")),
                    React.createElement("tr", null,
                        React.createElement("td", { className: getPreviousArchivalIntegrityCheck(item).TotalMissingFiles > 0 ? "red" : "" }, "Missing"),
                        React.createElement("td", { className: getPreviousArchivalIntegrityCheck(item).TotalMissingFiles > 0 ? "red" : "" }, (_c = getPreviousArchivalIntegrityCheckSums(item)) === null || _c === void 0 ? void 0 :
                            _c.missingInDestCount,
                            " "))),
                getPreviousArchivalIntegrityCheck(item).TotalMissingFiles > 0 ? (React.createElement("div", { className: "missing-alert" },
                    React.createElement("i", { className: "fa-sharp fa-regular fa-file-exclamation" }))) : null))) : null));
    };
    var closeToolAfterExecution = function () {
        dispatch(LifecycleReportStore.Refresh(props.Slot));
        setModalOpen(false);
    };
    return (React.createElement("div", { className: "workspace-bulk-action-remediate-modal", style: { display: isArchivalReport === true ? "block" : "none" } },
        React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null, props.children), simpleButton: true, headerText: "Remediate", size: "xl", modalClassName: "workspace-bulk-action-remediate-modal lifecycle-report-bulk-action-modal-restore", showModalOverride: modalOpen, showModalToggleOverride: function (shouldShow) { return setModalOpen(shouldShow); }, showCloseButton: processing === true ? false : true, footerCloseText: showComplete === true ? "Close" : processing === true ? undefined : "Cancel" },
            React.createElement("div", null,
                React.createElement("table", { className: "remediation-header-table" + " remediation-step-" + remediationCurrentStep, ref: remediationHeaderTable },
                    React.createElement("tr", null,
                        React.createElement("th", { className: "td-remediation-workspace-name" }, "Workspace"),
                        remediationCurrentStep === 1 ? (React.createElement(React.Fragment, null,
                            React.createElement("th", { className: "td-remediation-archival-statistics" }, "Previous Archival Statistics"),
                            React.createElement("th", { className: "td-remediation-workspace-actions" }, "Workspace Actions"))) : null,
                        React.createElement("th", { className: "td-remediation-group-status", style: {} }, "Group Status"),
                        React.createElement("th", { className: "td-remediation-site-status" }, "SharePoint Status"),
                        remediationCurrentStep === 2 ? (React.createElement(React.Fragment, null,
                            React.createElement("th", { className: "td-remediation-archival-date" }, "Archive Date"),
                            React.createElement("th", { className: "td-remediation-restoreable" }, "Restorable?"))) : null,
                        React.createElement("th", { className: "td-remediation-policy" }, "Policy"),
                        remediationCurrentStep === 3 ? React.createElement("th", { className: "td-remediation-policy-action" }, "Policy Action") : null,
                        remediationCurrentStep === 4 ? (React.createElement(React.Fragment, null,
                            React.createElement("th", { className: "td-remediation-archival-statistics" }, "Previous Archival Statistics"),
                            React.createElement("th", { className: "td-remediation-archival-actions" }, "Archival Actions"))) : null,
                        React.createElement("th", { className: "td-remediation-archival-eligibility" }, "Archival Eligibility"),
                        remediationCurrentStep === 4 ? (React.createElement(React.Fragment, null, executeButtonClicked === true || executedComplete === true ? (React.createElement(React.Fragment, null,
                            React.createElement("th", { className: "td-remediation-archival-execution-update-status" }, "Status Update"),
                            React.createElement("th", { className: "td-remediation-archival-execution-executed" }, "Execution"))) : (React.createElement("th", { className: "td-remediation-archival-status" }, "Archival Status")))) : null),
                    React.createElement("tr", { className: "remediation-steps" },
                        React.createElement("td", { className: getRemediationStepClass(1), colSpan: remediationCurrentStep === 1 ? 3 : 1 },
                            React.createElement("h6", null, "Confirm Workspaces"),
                            remediationCurrentStep === 1 ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "help-text" }, "Please look through the list of workspaces and confirm you would like to remediate the following workspaces?"),
                                React.createElement("div", { className: "right-align-button" }, remediationStatuses !== null && remediationStatuses !== undefined ? (React.createElement(Button, { onClick: function () {
                                        setRemediationCurrentStep(2);
                                    } }, "Next Step")) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 107 }))))) : (React.createElement(React.Fragment, null)),
                            React.createElement("div", { className: "navigate" },
                                React.createElement("div", { className: "right", onClick: function () {
                                        setRemediationCurrentStep(2);
                                    } },
                                    React.createElement("i", { className: "fa-solid fa-caret-right" })))),
                        React.createElement("td", { colSpan: remediationCurrentStep === 2 ? 4 : 2, className: getRemediationStepClass(2) },
                            React.createElement("h6", null, "Restoration"),
                            remediationCurrentStep === 2 ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "help-text" }, "Attempt to restore groups and site from the recycle bin? Only workspaces with an Active SharePoint site will be allowed to proceed with remediation."),
                                React.createElement("div", { className: "right-align-button" }, remediationStatuses !== null && remediationStatuses !== undefined ? (remediateRestoreJobStatus === null ? (React.createElement(React.Fragment, null, anyRestoreableItemsAtAll() === true ? (React.createElement(React.Fragment, null,
                                    React.createElement(Button, { variant: "secondary", onClick: function () { return setRemediationCurrentStep(3); }, style: { marginRight: "10px", backgroundColor: "white" } }, "Next Step"),
                                    React.createElement(Button, { onClick: function () {
                                            onClickRemediateRestoreJobStart();
                                        } }, "Start restoration"))) : (React.createElement(Button, { onClick: function () {
                                        setRemediationCurrentStep(3);
                                    } }, "Next Step")))) : remediateRestoreJobStatus === "Done" ? (React.createElement(Button, { onClick: function () {
                                        setRemediationCurrentStep(3);
                                    } }, "Next Step")) : (React.createElement(Button, { variant: "secondary" }, "... restoring ...."))) : (React.createElement(Button, { variant: "secondary" }, "Start restoration"))))) : (React.createElement(React.Fragment, null, remediationCurrentStep < 2 ? "" : "")),
                            React.createElement("div", { className: "navigate" },
                                React.createElement("div", { className: "left", onClick: function () {
                                        setRemediationCurrentStep(1);
                                    } },
                                    React.createElement("i", { className: "fa-solid fa-caret-left" })),
                                React.createElement("div", { className: "right", onClick: function () {
                                        setRemediationCurrentStep(3);
                                    } },
                                    React.createElement("i", { className: "fa-solid fa-caret-right" })))),
                        React.createElement("td", { colSpan: remediationCurrentStep === 3 ? 2 : 1, className: getRemediationStepClass(3) },
                            React.createElement("h6", null, "Policy Assignments"),
                            remediationCurrentStep === 3 ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "help-text" }, "Use this step to update assigned archvial policies for your workspaces?"),
                                React.createElement("div", { className: "right-align-button" }, remediationStatuses !== null && remediationStatuses !== undefined && lifecycleArchivalPolicies !== null && lifecycleArchivalPolicies !== undefined ? (React.createElement(React.Fragment, null, assignedPoliciesChanges.length === 0 ? (React.createElement(Button, { onClick: function () {
                                        setRemediationCurrentStep(4);
                                    } }, "Next Step")) : processing === false ? (React.createElement(Button, { onClick: function () {
                                        onClickAssignPolicies();
                                    } }, "Update Assigned Policies")) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 107 })))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 107 }))))) : (React.createElement(React.Fragment, null, remediationCurrentStep < 3 ? "" : "")),
                            React.createElement("div", { className: "navigate" },
                                React.createElement("div", { className: "left", onClick: function () {
                                        setRemediationCurrentStep(2);
                                    } },
                                    React.createElement("i", { className: "fa-solid fa-caret-left" })),
                                assignedPoliciesChanges.length === 0 ? (React.createElement("div", { className: "right", onClick: function () {
                                        setRemediationCurrentStep(4);
                                    } },
                                    React.createElement("i", { className: "fa-solid fa-caret-right" }))) : null)),
                        React.createElement("td", { className: getRemediationStepClass(4), colSpan: remediationCurrentStep === 4 ? (executeButtonClicked === true || executedComplete === true ? 5 : 4) : 1 },
                            React.createElement("h6", null, "Policy Execution"),
                            remediationCurrentStep === 4 ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "help-text" }, "Force execute associated workspaces that are eligible for archival again."),
                                React.createElement("div", { className: "action-top" },
                                    React.createElement("ul", null,
                                        executeButtonClicked === false && executedComplete === false ? (React.createElement(React.Fragment, null, previousStatsToLoad.length === 0 ? (React.createElement("div", { onClick: function () { return onLoadPreviousArchivalStatsClick(); } },
                                            React.createElement(InfoTooltip, { placement: "left", delayHide: 0, delayShow: 0, content: "This comparison tool assumes that the policy used to archive this workspace has not changed since the original archival. If the policy's archival location or type has changed it will result in false reporting." },
                                                React.createElement("li", { className: "action" },
                                                    React.createElement("i", { className: "fa-solid fa-download" }),
                                                    React.createElement("span", null, "Load Eligible Statistics"))))) : (React.createElement("li", { className: "action" },
                                            React.createElement(AnimatedLoader, { size: "xxsmall" }),
                                            React.createElement("span", null, "Load Eligible Statistics"))))) : null,
                                        React.createElement("li", { className: "action", onClick: function () { return setHideIneligible(!hideIneligible); } }, hideIneligible === false ? (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: "fa-sharp fa-solid fa-eye-slash" }),
                                            React.createElement("span", null, "Hide Ineligble Workspaces"))) : (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: "fa-sharp fa-solid fa-eye" }),
                                            React.createElement("span", null, "Show Ineligble Workspaces")))))),
                                React.createElement("div", { className: "right-align-button" }, executedComplete === true ? (React.createElement(React.Fragment, null,
                                    React.createElement(NavLink, { exact: true, to: "/lifecycle/queue" },
                                        React.createElement(Button, { variant: "secondary", style: { marginRight: "10px", backgroundColor: "white" } }, "View Archival Queue")),
                                    React.createElement(Button, { onClick: function () { return closeToolAfterExecution(); } }, "Close Remediation Tool"))) : getExecutablePoliciesSiteIds().length === 0 ? (React.createElement(React.Fragment, null,
                                    React.createElement(Button, { variant: "secondary", disabled: true }, "Execute Archivals"))) : remediationStatuses !== null && remediationStatuses !== undefined && lifecycleArchivalPolicies !== null && lifecycleArchivalPolicies !== undefined && executeButtonClicked === false ? (React.createElement(React.Fragment, null,
                                    React.createElement(Button, { className: "execute-archivals", onClick: function () {
                                            onClickExecutePolicies();
                                        } },
                                        "Execute Archivals ",
                                        React.createElement("span", null, getExecutablePoliciesSiteIds().length)))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 107 }))))) : null,
                            executeButtonClicked === false ? (React.createElement("div", { className: "navigate" },
                                React.createElement("div", { className: "left", onClick: function () {
                                        setRemediationCurrentStep(3);
                                    } },
                                    React.createElement("i", { className: "fa-solid fa-caret-left" })))) : null)))),
            React.createElement("div", { className: "remediation-body-table remediation-scroll", style: { paddingTop: tableContentMarginTop + "px" } },
                React.createElement("table", { className: "remediation-step-" + remediationCurrentStep }, reportItems
                    .filter(function (ri) { return (siteIdsToProcess === null || siteIdsToProcess === void 0 ? void 0 : siteIdsToProcess.some(function (pg) { return pg === ri.SiteId; })) && (hideIneligible === false || (hideIneligible === true && isWorkspaceEligibleForRemediationExecution(ri))); })
                    .map(function (item) {
                    var _a, _b, _c;
                    return (
                    // <tr className={(getPolicyForWorkspace(item) === null ? "no-policy" : "") + (canForcePolicy(item) ? "" : " cant-force")}>
                    React.createElement("tr", { className: !isWorkspaceEligibleForRemediationExecution(item) ? "no-policy" : "" },
                        React.createElement("td", { className: "td-remediation-workspace-name body" },
                            React.createElement("div", { className: "title-area" },
                                React.createElement("div", { className: "ta-icon" },
                                    React.createElement(OfficeProfilePicture, { size: "xs", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                                React.createElement("div", { className: "m365-icon" }, item.Application !== "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png", title: item.Application })) : (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png", title: item.Application }))),
                                React.createElement("div", { className: "ta-title-and-url" },
                                    React.createElement("div", { className: "ta-title" }, item.Title),
                                    React.createElement("div", { className: "ta-url" },
                                        React.createElement("a", { href: item.SiteUrl, target: "_blank" }, item.SiteUrl))))),
                        remediationCurrentStep === 1 ? (React.createElement(React.Fragment, null,
                            renderPreviousStatisticsTd(item),
                            React.createElement("td", { className: "td-remediation-workspace-actions" }, remediationStatuses !== null ? (React.createElement("div", { className: "remove-workspace", onClick: function () { return removeWorkspaceFromList(item); } },
                                React.createElement("i", { className: "fa-solid fa-xmark" }),
                                React.createElement("span", null, "Remove"))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 28, bodyWidth: 83 }))))) : null,
                        React.createElement("td", { className: "td-remediation-group-status" }, remediationStatuses === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 20 })) : (getRemediationGroupOrSiteStatus((_a = getRemediationStatus(item)) === null || _a === void 0 ? void 0 : _a.GroupStatus))),
                        React.createElement("td", { className: "td-remediation-site-status" }, remediationStatuses === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 20 })) : (getRemediationGroupOrSiteStatus((_b = getRemediationStatus(item)) === null || _b === void 0 ? void 0 : _b.SharePointStatus))),
                        remediationCurrentStep === 2 ? (React.createElement(React.Fragment, null,
                            React.createElement("td", { className: "td-remediation-archival-date" }, item.ArchivedDate !== null ? moment(item.ArchivedDate).format("YYYY-MM-DD") : ""),
                            React.createElement("td", { className: "td-remediation-restoreable" }, isWorkspaceRestorable(item) ? (React.createElement("i", { className: "fa-regular fa-hexagon", title: "Restorable" })) : (React.createElement(React.Fragment, null, isWorkspaceAlreadyRestored(item) ? (React.createElement("i", { className: "fa-regular fa-hexagon-check", title: "Already Restored" })) : (React.createElement("i", { className: "fa-regular fa-hexagon-xmark", title: "Cannot be restored" }))))))) : null,
                        React.createElement("td", { className: "td-remediation-policy" }, defaultPolicies === null || archivalPoliciesLoaded === false ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })) : (React.createElement("div", { className: "dropdowns" + (clonePreviewItems.some(function (pi) { return item.SiteId === pi; }) ? " clone-preview" : "") }, isWorkspaceEligibleForPolicyReAssignmentForRemediation(item) && getAssignablePolicies(item).length > 1 ? (React.createElement(React.Fragment, null, item.IsProtected ? (React.createElement("div", { className: "form-control no-selection-allowed excluded-from-archival" + (remediationCurrentStep !== 3 ? " disabled" : "") },
                            React.createElement("div", { className: "no-dd-title" }, "-- Excluded from archival --"))) : (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: getAssignablePolicies(item), disabled: remediationCurrentStep !== 3 || getAssignablePolicies(item).length < 2 || getDropdownPolicyValue(item) === "protected", value: getDropdownPolicyValue(item), onChange: function (value) {
                                    updateAssignedPolicies(item, value);
                                } }))))) : (React.createElement("div", { className: "form-control no-selection-allowed" + (remediationCurrentStep !== 3 ? " disabled" : "") },
                            React.createElement("div", { className: "no-dd-title" }, (_c = getAssignablePolicies(item).find(function (ap) { return ap.value === getDropdownPolicyValue(item); })) === null || _c === void 0 ? void 0 : _c.title),
                            React.createElement("div", { className: "no-dd-info" },
                                React.createElement(InfoTooltip, { title: isWorkspaceEligibleForPolicyReAssignmentForRemediation(item) === false
                                        ? getWorkspaceNoEligibleForPolicyChangeReason(item)
                                        : getAssignablePolicies(item).length < 2
                                            ? "There are no policies available"
                                            : "" }))))))),
                        remediationCurrentStep === 3 ? (React.createElement("td", { className: "td-remediation-policy-action" },
                            React.createElement("div", { className: "actions" },
                                React.createElement("div", { className: "clone", onClick: function () { return cloneSelectionToAllWorkspaces(item); }, onMouseEnter: function () {
                                        return getAssignablePolicies(item).length > 1 && isWorkspaceEligibleForPolicyReAssignmentForRemediation(item)
                                            ? setClonePreviewItems(reportItems
                                                .filter(function (ri) { return isWorkspaceEligibleForPolicyReAssignmentForRemediation(ri) === true && ri.Application === item.Application && ri.SiteId !== item.SiteId; })
                                                .map(function (ri) { return ri.SiteId; }))
                                            : null;
                                    }, onMouseLeave: function () { return setClonePreviewItems([]); } }, isWorkspaceEligibleForPolicyReAssignmentForRemediation(item) === false ? (React.createElement("div", { className: "btn disabled" },
                                    React.createElement("i", { className: "fa fa-clone" }))) : (
                                // <InfoTooltip placement="right" delayHide={0} content={"Clone this policy to all workspaces of the same application type (" + item.Application + ")."}>
                                React.createElement("div", { className: "btn", title: "Clone this policy to all workspaces of the same application type" },
                                    React.createElement("i", { className: "fa fa-clone" }))
                                //</InfoTooltip>
                                )),
                                React.createElement("div", { className: "undo", onClick: function () { return undoAssignedPolicy(item.SiteId); } }, isPolicyOrProtectedNew(item) === false || isWorkspaceEligibleForPolicyReAssignmentForRemediation(item) === false ? (React.createElement("div", { className: "btn disabled" },
                                    React.createElement("i", { className: "fa fa-undo-alt" }))) : (
                                // <InfoTooltip placement="right" delayHide={0} content={"Undo policy assignment change"}>
                                React.createElement("div", { className: "btn", title: "Undo policy assignment change" },
                                    React.createElement("i", { className: "fa fa-undo-alt" }))
                                // </InfoTooltip>
                                ))))) : null,
                        remediationCurrentStep === 4 ? (React.createElement(React.Fragment, null,
                            renderPreviousStatisticsTd(item),
                            React.createElement("td", { className: "td-remediation-archival-actions" },
                                React.createElement("div", { className: "btn remove-workspace", onClick: function () { return removeWorkspaceFromList(item); }, title: "Remove from remediate tool" },
                                    React.createElement("i", { className: "fa-solid fa-xmark" }))))) : null,
                        React.createElement("td", { className: "td-remediation-archival-eligibility" }, remediationStatuses === null && lifecycleArchivalPolicies !== null && lifecycleArchivalPolicies !== undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, bodyWidth: 20 })) : (React.createElement(React.Fragment, null, isWorkspaceEligibleForRemediationExecution(item) ? (React.createElement("span", { className: "archival-eligible" },
                            React.createElement("i", { className: "fa-solid fa-check" }),
                            " Eligible")) : (React.createElement("span", { className: "archival-ineligible" },
                            React.createElement(InfoTooltip, { title: getsWorkspaceEligibleForRemediationExecutionReason(item) }),
                            " Ineligible"))))),
                        remediationCurrentStep === 4 ? (React.createElement(React.Fragment, null, executeButtonClicked === true || executedComplete === true ? (React.createElement(React.Fragment, null,
                            React.createElement("td", { className: "td-remediation-archival-execution-update-status" }, executingThatIsUpdatingStatus.some(function (s) { return s === item.SiteId; }) ? (React.createElement(AnimatedLoader, { size: "xsmall" })) : executedUpgradedDone.some(function (s) { return s === item.SiteId; }) ? (React.createElement("i", { className: "fa-regular fa-circle-check" })) : (React.createElement("i", { className: "fa-regular fa-circle" }))),
                            React.createElement("td", { className: "td-remediation-archival-execution-executed" }, executedComplete === true ? React.createElement("i", { className: "fa-regular fa-circle-check" }) : React.createElement("i", { className: "fa-regular fa-circle" })))) : (React.createElement("td", { className: "td-remediation-archival-status" },
                            React.createElement("div", { className: "ws-archival-status lc-stats-" + getWorkspaceArchivalStatusClass(item) }, getWorkspaceArchivalStatusText(item)))))) : null));
                }))))));
});
export default LifecycleWorkspaceBulkActionRemediateModal;
