var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Accordion } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import styles from "./orchestry-accordion.module.scss";
export var OrchestryAccordion = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Accordion, __assign({ classNames: { chevron: styles.orchestry_accordion__chevron }, chevron: React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.chevronUp, fontSize: 24, lineHeight: "24px", color: "var(--mantine-color-black)" }) }, props), children));
};
