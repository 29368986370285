var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./library-configuration.scss";
import { NavLink } from "react-router-dom";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as LibraryConfigurationCurrentStore from "app/store/library-configuration/current";
import Async from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import AnimatedLoader from "app/components/common/animated-loader";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
var LibrarySettings = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentLibraryConfiguration = useSelector(function (state) { return state.libraryConfigurationCurrentState.item; });
    var _a = React.useState({}), currentLibraryConfigInternalState = _a[0], setCurrentLibraryConfigInternalState = _a[1];
    var _b = React.useState([]), channelCategories = _b[0], setChannelCategories = _b[1];
    var _c = React.useState([]), templateCategories = _c[0], setTemplateCategories = _c[1];
    var _d = React.useState([]), featureCategories = _d[0], setFeatureCategories = _d[1];
    var _e = React.useState([]), documentTemplateCategories = _e[0], setDocumentTemplateCategories = _e[1];
    var saveDiv = React.useRef();
    var _f = React.useState(false), anyEdits = _f[0], setAnyEdits = _f[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        dispatch(LibraryConfigurationCurrentStore.Load());
    }, []);
    // After load is complete, populate the copy of the server data in memory for editing.
    React.useEffect(function () {
        if (currentLibraryConfiguration.GUID !== undefined) {
            if (currentLibraryConfiguration.GUID !== "") {
                var currentLibraryConfigCopy = __assign({}, currentLibraryConfiguration);
                if (currentLibraryConfiguration.ChannelCategoriesJson !== undefined && currentLibraryConfiguration.ChannelCategoriesJson !== "") {
                    setChannelCategories(JSON.parse(currentLibraryConfiguration.ChannelCategoriesJson));
                }
                if (currentLibraryConfiguration.TemplateCategoriesJson !== undefined && currentLibraryConfiguration.TemplateCategoriesJson !== "") {
                    setTemplateCategories(JSON.parse(currentLibraryConfiguration.TemplateCategoriesJson));
                }
                if (currentLibraryConfiguration.FeatureCategoriesJson !== undefined && currentLibraryConfiguration.FeatureCategoriesJson !== "") {
                    setFeatureCategories(JSON.parse(currentLibraryConfiguration.FeatureCategoriesJson));
                }
                if (currentLibraryConfiguration.DocumentTemplateCategoriesJson !== undefined && currentLibraryConfiguration.DocumentTemplateCategoriesJson !== "") {
                    setDocumentTemplateCategories(JSON.parse(currentLibraryConfiguration.DocumentTemplateCategoriesJson));
                }
                setCurrentLibraryConfigInternalState(currentLibraryConfigCopy);
            }
        }
    }, [currentLibraryConfiguration]);
    React.useEffect(function () {
        //const stringBlockwords:string = ;
        setCurrentLibraryConfigInternalState(__assign(__assign({}, currentLibraryConfigInternalState), { ChannelCategoriesJson: JSON.stringify(channelCategories), TemplateCategoriesJson: JSON.stringify(templateCategories), FeatureCategoriesJson: JSON.stringify(featureCategories), DocumentTemplateCategoriesJson: JSON.stringify(documentTemplateCategories) }));
    }, [channelCategories, templateCategories, featureCategories, documentTemplateCategories]);
    //after the current config object is updated, check to see if the save button should be highlighted.
    React.useEffect(function () {
        checkIfSaveable();
    }, [currentLibraryConfigInternalState]);
    // Checks for edits
    var checkIfSaveable = function () {
        //console.log("currentLibraryConfigInternalState",currentLibraryConfigInternalState);
        if (currentLibraryConfigInternalState.GUID !== undefined) {
            if (currentLibraryConfigInternalState.GUID !== "") {
                if (JSON.stringify(currentLibraryConfigInternalState) !== JSON.stringify(currentLibraryConfiguration)) {
                    if (anyEdits === false) {
                        setAnyEdits(true);
                    }
                }
            }
        }
    };
    // On save, save to server and set save button back to non-edited.
    var save = function () {
        dispatch(LibraryConfigurationCurrentStore.Update(__assign({}, currentLibraryConfigInternalState)));
        setAnyEdits(false);
    };
    //
    React.useEffect(function () {
        try {
            saveDiv.current.onclick = save;
        }
        catch (_a) { }
    });
    return (React.createElement("div", { className: "document-template-categories fadein-500ms" },
        props.isModal === undefined ? (React.createElement(Subtitle, { title: "Library Settings", subtitle: "Edit" },
            React.createElement(NavLink, { to: "/settings" },
                React.createElement(SubtitleButton, { title: "Cancel", variant: "light" })),
            React.createElement("div", { ref: saveDiv, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" })))) : null,
        React.createElement(Async, { uid: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Load, status: AsyncStatus.Started },
            React.createElement(AnimatedLoader, { text: "Loading..." })),
        React.createElement(Async, { uid: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Load, status: AsyncStatus.Succeeded }, currentLibraryConfigInternalState.GUID !== "" ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, { className: "" },
                            React.createElement(Col, { md: props.isModal !== undefined && props.isModal === true ? 12 : 6 },
                                React.createElement("div", { className: "blocked-words-form" },
                                    React.createElement(Form.Label, null, "Template Library Categories"),
                                    React.createElement("div", { className: "blocked-words" },
                                        React.createElement(TagsInput, { value: templateCategories !== null ? templateCategories : [], onChange: function (tags) { return setTemplateCategories(tags); }, addKeys: [9, 13, 188], removeKeys: [8, 46] })),
                                    React.createElement("p", { className: "blocked-help" }, "Hit tab, comma, or return to add category. Hit backspace/delete to remove.")))),
                        React.createElement(Row, { className: "" },
                            React.createElement(Col, { md: props.isModal !== undefined && props.isModal === true ? 12 : 6 },
                                React.createElement("div", { className: "blocked-words-form" },
                                    React.createElement(Form.Label, null, "Channel Library Categories"),
                                    React.createElement("div", { className: "blocked-words" },
                                        React.createElement(TagsInput, { value: channelCategories !== null ? channelCategories : [], onChange: function (tags) { return setChannelCategories(tags); }, addKeys: [9, 13, 188], removeKeys: [8, 46] })),
                                    React.createElement("p", { className: "blocked-help" }, "Hit tab, comma, or return to add category. Hit backspace/delete to remove.")))),
                        React.createElement(Row, { className: "" },
                            React.createElement(Col, { md: props.isModal !== undefined && props.isModal === true ? 12 : 6 },
                                React.createElement("div", { className: "blocked-words-form" },
                                    React.createElement(Form.Label, null, "Feature Library Categories"),
                                    React.createElement("div", { className: "blocked-words" },
                                        React.createElement(TagsInput, { value: featureCategories !== null ? featureCategories : [], onChange: function (tags) { return setFeatureCategories(tags); }, addKeys: [9, 13, 188], removeKeys: [8, 46] })),
                                    React.createElement("p", { className: "blocked-help" }, "Hit tab, comma, or return to add category. Hit backspace/delete to remove.")))),
                        React.createElement(Row, { className: "" },
                            React.createElement(Col, { md: props.isModal !== undefined && props.isModal === true ? 12 : 6 },
                                React.createElement("div", { className: "blocked-words-form" },
                                    React.createElement(Form.Label, null, "Document Template Library Categories"),
                                    React.createElement("div", { className: "blocked-words" },
                                        React.createElement(TagsInput, { value: documentTemplateCategories !== null ? documentTemplateCategories : [], onChange: function (tags) { return setDocumentTemplateCategories(tags); }, addKeys: [9, 13, 188], removeKeys: [8, 46] })),
                                    React.createElement("p", { className: "blocked-help" }, "Hit tab, comma, or return to add category. Hit backspace/delete to remove."))))))))) : null),
        props.isModal !== undefined && props.isModal === true ? (React.createElement("div", { className: "modal-controls" },
            React.createElement("div", { ref: saveDiv, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" })))) : null));
});
export default LibrarySettings;
