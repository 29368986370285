var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./orchestry-admin-tool-database-browser.scss";
import { Row, Col, Form, Table } from "react-bootstrap";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { useDispatch, useSelector } from "react-redux";
import * as TenantListStore from "app/store/tenants/list";
import * as PageHeader from "app/components/common/page-header";
import DisableArea from "app/components/common/disable-area";
import OrchestryService from "app/services/orchestry";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import DatabaseBrowserService from "app/services/database-browser";
import Picklist from "app/components/common/form-picklist";
import { Controlled as CodeMirror } from "react-codemirror2";
import { format } from "sql-formatter";
import { SubtitleButton } from "app/components/common/subtitle";
require("codemirror/mode/sql/sql");
var OrchestryAdminToolDatabaseBrowser = React.memo(function () {
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _a = React.useState("107fa605-5f29-47c5-96b2-177085a18439"), selectedTenant = _a[0], setSelectedTenant = _a[1];
    var _b = React.useState(null), selectedRegion = _b[0], setSelectedRegion = _b[1];
    var _c = React.useState(), activeRegions = _c[0], setActiveRegions = _c[1];
    var _d = React.useState(null), databases = _d[0], setDatabases = _d[1];
    var _e = React.useState(null), selectedDatabase = _e[0], setSelectedDatabase = _e[1];
    var _f = React.useState(null), selectedTable = _f[0], setSelectedTable = _f[1];
    var _g = React.useState(undefined), queryFromBuilder = _g[0], setQueryFromBuilder = _g[1];
    var _h = React.useState([]), availableColumns = _h[0], setAvailableColumns = _h[1];
    var _j = React.useState(false), queryRunning = _j[0], setQueryRunning = _j[1];
    var _k = React.useState(null), results = _k[0], setResults = _k[1];
    var _l = React.useState("columns_all"), colMode = _l[0], setColMode = _l[1];
    var _m = React.useState(100), colTopNumber = _m[0], setColTopNumber = _m[1];
    var _o = React.useState(null), sortField = _o[0], setSortField = _o[1];
    var _p = React.useState("ASC"), sortDirection = _p[0], setSortDirection = _p[1];
    var _q = React.useState(false), hiddenModeOn = _q[0], setHiddenModeOn = _q[1];
    var _r = React.useState(""), hiddenModeSql = _r[0], setHiddenModeSql = _r[1];
    var _s = React.useState(true), isUserSuperAdmin = _s[0], setIsUserSuperAdmin = _s[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        if (orchestryContext.ExtendedProperties["SensitiveInformationAccess"] !== undefined && orchestryContext.ExtendedProperties["SensitiveInformationAccess"] == true) {
            OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
                setActiveRegions(regions);
                setSelectedRegion(regions[0].value);
            });
            DatabaseBrowserService.GetDatabases().then(function (dbs) {
                setDatabases(dbs);
                setSelectedDatabase(dbs[0].Name);
            });
        }
        else {
            setIsUserSuperAdmin(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        setQueryFromBuilder(undefined);
        setSortField(null);
        setAvailableColumns(getSelectedFields().map(function (col) {
            return ({
                Identifier: col.name,
                Name: col.name,
                Picked: false
            });
        }));
    }, [selectedTable]);
    React.useEffect(function () {
        if (hiddenModeOn == true) {
            setHiddenModeSql(getSql());
        }
    }, [hiddenModeOn]);
    React.useEffect(function () {
        if (selectedDatabase !== null) {
            var foundDatabase = databases.find(function (db) { return db.Name == selectedDatabase; });
            if (foundDatabase !== undefined) {
                setSelectedTable(foundDatabase.Tables[0].Name);
            }
        }
    }, [selectedDatabase]);
    React.useEffect(function () { }, [colMode]);
    var getSelectedFields = function () {
        if (selectedDatabase !== null && selectedTable != null) {
            var foundDatabase = databases.find(function (db) { return db.Name == selectedDatabase; });
            if (foundDatabase !== undefined) {
                var foundTable = foundDatabase.Tables.find(function (tb) { return tb.Name == selectedTable; });
                if (foundTable !== undefined) {
                    return foundTable.Columns;
                }
            }
        }
        return [];
    };
    var getTableOptions = function () {
        if (selectedDatabase !== null) {
            var foundDatabase = databases.find(function (db) { return db.Name == selectedDatabase; });
            if (foundDatabase !== undefined) {
                return foundDatabase.Tables.map(function (tb, index) {
                    return ({
                        id: "" + index,
                        title: tb.Name,
                        value: tb.Name
                    });
                });
            }
        }
        return [];
    };
    var getTableColumnsOptions = function () {
        var columns = getSelectedFields();
        var returnOptions = [];
        if (columns !== undefined && columns !== null) {
            returnOptions = __spreadArray(__spreadArray([], returnOptions, true), [
                columns.map(function (c, index) {
                    return ({
                        id: "" + index,
                        title: c.name,
                        value: c.name
                    });
                })
            ], false);
        }
        return [];
    };
    var getDatabasesOptions = function () {
        if (databases !== null) {
            return databases.map(function (db, index) {
                return ({
                    id: "" + index,
                    title: db.Name,
                    value: db.Name
                });
            });
        }
        return [];
    };
    var getSql = function () {
        var sql = "";
        var columns = "";
        if (colMode != null) {
            if (colMode == "columns_all") {
                columns = "*";
            }
            else if (colMode == "columns_specific") {
                if (availableColumns !== undefined && availableColumns !== null && Array.isArray(availableColumns)) {
                    var loopColumns = availableColumns.filter(function (c) { return c.Picked == true; });
                    if (loopColumns.length > 0) {
                        loopColumns.forEach(function (c, index) {
                            if (index != 0) {
                                columns += ",";
                            }
                            if (selectedDatabase == "Cosmos") {
                                columns += c.Name;
                            }
                            else {
                                columns += c.Name;
                            }
                        });
                    }
                }
                if (columns == "") {
                    columns = "*";
                }
            }
            if (colMode.indexOf("columns") > -1) {
                sql += "SELECT TOP " + colTopNumber + " " + columns;
            }
            if (colMode == "count") {
                if (selectedDatabase == "Cosmos") {
                    sql += "SELECT COUNT(c.id) as Count";
                }
                else {
                    sql += "SELECT COUNT(Id) as Count";
                }
            }
            if (selectedTable != null) {
                if (selectedDatabase == "Cosmos") {
                    sql += " FROM c";
                }
                else {
                    sql += " FROM " + selectedTable;
                }
            }
            if (queryFromBuilder !== undefined && formatQuery(queryFromBuilder, { format: "sql", parseNumbers: true }) != "") {
                sql += " WHERE " + formatQuery(queryFromBuilder, { format: "sql", parseNumbers: true });
            }
            if (sortField != null) {
                if (selectedDatabase == "Cosmos") {
                    sql += " ORDER BY " + sortField + " " + sortDirection;
                }
                else {
                    sql += " ORDER BY " + sortField + " " + sortDirection;
                }
            }
        }
        //{queryFromBuilder !== undefined ? <pre>{formatQuery(queryFromBuilder, "sql")}</pre> : null}
        return format(sql)
            .replace(" and ", " AND ")
            .replace(" or ", " OR ");
    };
    var executeQuery = function (sqlString) {
        var sql = sqlString === undefined ? getSql() : sqlString;
        if (sql != "" && selectedDatabase != null && selectedTenant != null && selectedTable != null) {
            setQueryRunning(true);
            DatabaseBrowserService.Query(selectedDatabase, sql, selectedTenant, selectedTable).then(function (results) {
                setQueryRunning(false);
                setResults(results);
            });
        }
    };
    var getTableHeaders = function () {
        var headers = [];
        if (results !== undefined && results !== null && Array.isArray(results)) {
            for (var key in results[0]) {
                headers.push(key);
            }
        }
        return headers;
    };
    var getTableRowValues = function (row) {
        var values = [];
        for (var key in row) {
            try {
                if (row[key] == null) {
                    values.push("null");
                }
                else {
                    if (typeof row[key] == "object") {
                        values.push(JSON.stringify(row[key]));
                    }
                    else {
                        values.push(row[key].toString());
                    }
                }
            }
            catch (_a) {
                values.push("error");
            }
        }
        return values;
    };
    var executeHiddenQuery = function () {
        executeQuery(hiddenModeSql);
    };
    return (React.createElement("div", { className: "orchestry-admin-tool-database-browser fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Tools", subTitle: "Database Browser" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement("div", { onClick: function () { return setHiddenModeOn(!hiddenModeOn); }, style: { width: "100px", height: "40px" } })),
        React.createElement(DisableArea, { disabledText: "Only employees granted access to view sensitive information may use this tool", isEnabled: isUserSuperAdmin, useOverlay: true },
            React.createElement("div", null,
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Region"),
                            activeRegions === null || activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) { return setSelectedRegion(value); } })))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Database"),
                            databases === null || databases === undefined || databases.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: getDatabasesOptions(), value: selectedDatabase, onChange: function (value) { return setSelectedDatabase(value); } })))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Database for Tenant "),
                            tenants.length === 0 || selectedRegion == null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedTenant, list: tenants
                                    .filter(function (t) { return t.DisplayName !== null && t.DisplayName !== undefined && t.DisplayName !== "" && t.Region == selectedRegion; })
                                    .map(function (t) { return ({
                                    id: t.GUID !== null && t.GUID !== undefined ? t.GUID : "",
                                    value: t.GUID !== null && t.GUID !== undefined ? t.GUID : "",
                                    title: t.DisplayName !== null && t.DisplayName !== undefined ? t.DisplayName : ""
                                }); }), onChange: function (tenantGuid) {
                                    setSelectedTenant(tenantGuid);
                                } })))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Table"),
                            databases === null || databases === undefined || databases.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: getTableOptions(), value: selectedTable, onChange: function (value) { return setSelectedTable(value); } }))))),
                hiddenModeOn == false ? (React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(Portlet, { className: "smaller-portlet" },
                            React.createElement(PortletHeader, { title: "Columns" }),
                            React.createElement(PortletBody, null,
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "form-group" },
                                            React.createElement(Form.Label, null, "Mode"),
                                            React.createElement(FormDropdownSelect, { list: [
                                                    {
                                                        id: "0",
                                                        title: "Columns - All",
                                                        value: "columns_all"
                                                    },
                                                    {
                                                        id: "1",
                                                        title: "Columns - Specific Columns",
                                                        value: "columns_specific"
                                                    },
                                                    {
                                                        id: "2",
                                                        title: "Count",
                                                        value: "count"
                                                    }
                                                ], value: colMode, onChange: function (value) { return setColMode(value); } }))),
                                    React.createElement(Col, null, colMode != "count" ? (React.createElement(Form.Group, { className: "form-group" },
                                        React.createElement(Form.Label, null, "Rows to return"),
                                        React.createElement(FormDropdownSelect, { list: [
                                                {
                                                    id: "0",
                                                    title: "100",
                                                    value: "100"
                                                },
                                                {
                                                    id: "1",
                                                    title: "500",
                                                    value: "500"
                                                },
                                                {
                                                    id: "2",
                                                    title: "1000",
                                                    value: "1000"
                                                }
                                            ], value: colTopNumber, onChange: function (value) { return setColTopNumber(value); } }))) : null)),
                                colMode == "columns_specific" ? (React.createElement(React.Fragment, null, databases === null || databases === undefined || databases.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(Picklist, { data: availableColumns, maxHeight: 250, onChange: function (data) { return setAvailableColumns(data); } })))) : null)),
                        React.createElement(Portlet, { className: "smaller-portlet" },
                            React.createElement(PortletHeader, { title: "Order By" }),
                            React.createElement(PortletBody, null,
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "form-group" },
                                            React.createElement(Form.Label, null, "Field"),
                                            React.createElement(FormDropdownSelect, { list: __spreadArray(__spreadArray([], [
                                                    {
                                                        id: "100",
                                                        title: "Default Sort",
                                                        value: null
                                                    }
                                                ], false), getSelectedFields().map(function (sf, index) { return ({
                                                    id: index,
                                                    title: sf.name,
                                                    value: sf.name
                                                }); }), true), value: sortField, onChange: function (value) { return setSortField(value); } }))),
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "form-group" },
                                            React.createElement(Form.Label, null, "Direction"),
                                            React.createElement(FormDropdownSelect, { list: [
                                                    {
                                                        id: "0",
                                                        title: "Ascending",
                                                        value: "ASC"
                                                    },
                                                    {
                                                        id: "1",
                                                        title: "Descending",
                                                        value: "DESC"
                                                    }
                                                ], value: sortDirection, onChange: function (value) { return setSortDirection(value); } }))))))),
                    React.createElement(Col, null,
                        React.createElement(Portlet, { className: "smaller-portlet" },
                            React.createElement(PortletHeader, { title: "Where Query" }),
                            React.createElement(PortletBody, null,
                                React.createElement(QueryBuilder, { fields: getSelectedFields(), query: queryFromBuilder, onQueryChange: function (q) { return setQueryFromBuilder(q); } }))),
                        React.createElement(Portlet, { className: "smaller-portlet" },
                            React.createElement(PortletHeader, { title: "SQL" }),
                            React.createElement(PortletBody, null,
                                React.createElement(CodeMirror, { value: getSql(), options: {
                                        mode: "sql",
                                        // theme: 'material',
                                        lineNumbers: true
                                        // scrollbarStyle:null
                                    }, onBeforeChange: function (editor, data, value) {
                                        // this.setState({value});
                                        // setTextFileContentTempSave(value);
                                    }, onChange: function (editor, data, value) {
                                        console.log(editor, data, value);
                                    } }),
                                React.createElement("div", { className: "run-button" }, queryRunning ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(SubtitleButton, { size: "sm", color: "orange", title: "Run Query", onClick: function () { return executeQuery(); }, customIcon: "fa fa-rectangle-terminal", noMargin: true, disabled: !(selectedDatabase != null && selectedTenant != null && selectedTable != null) })))))))) : (React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(Portlet, { className: "smaller-portlet" },
                            React.createElement(PortletHeader, { title: "Sarah's Advanced SQL Mode > CAUTION" }),
                            React.createElement(PortletBody, null,
                                React.createElement(CodeMirror, { value: hiddenModeSql, options: {
                                        mode: "sql",
                                        // theme: 'material',
                                        lineNumbers: true
                                        // scrollbarStyle:null
                                    }, onBeforeChange: function (editor, data, value) {
                                        setHiddenModeSql(value);
                                        console.log(value);
                                    }, onChange: function (editor, data, value) {
                                        setHiddenModeSql(value);
                                        console.log(value);
                                    } }),
                                React.createElement("div", { className: "run-button" }, queryRunning ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(SubtitleButton, { size: "sm", color: "orange", title: "Run Query", onClick: function () { return executeHiddenQuery(); }, customIcon: "fa fa-rectangle-terminal", noMargin: true, disabled: !(selectedDatabase != null && selectedTenant != null && selectedTable != null) })))))))),
                React.createElement(Row, null,
                    React.createElement(Col, null, results !== undefined && results !== null && Array.isArray(results) ? (React.createElement("div", { className: "results-table" },
                        React.createElement(Table, { striped: true, bordered: true, hover: true },
                            React.createElement("thead", null,
                                React.createElement("tr", null, getTableHeaders().map(function (h) { return (React.createElement("th", null, h)); }))),
                            React.createElement("tbody", null, results.map(function (row) { return (React.createElement("tr", null, getTableRowValues(row).map(function (r) { return (React.createElement("td", null, r)); }))); }))))) : (React.createElement("div", null, "Please run a query to see results"))))))));
});
export default OrchestryAdminToolDatabaseBrowser;
