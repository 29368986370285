import { Group } from "@mantine/core";
import React from "react";
import { OrchestryTooltip } from "../../orchestry-tooltip/orchestry-tooltip.component";
import { OrchestryFilterItem } from "./orchestry-filter-item.component";
import { OrchestryFilterItems } from "./orchestry-filter-items.component";
export var OrchestryFilterItemsWithOverflowIndicator = function (_a) {
    var maxItems = _a.maxItems, filters = _a.filters, isLoading = _a.isLoading;
    var maxFilterItems = filters.slice(0, maxItems);
    var overflowedFilterItems = filters.slice(maxItems);
    var overflowCount = filters.length - maxItems;
    var overflowFilterProps = {
        type: "selectable",
        title: "+".concat(overflowCount),
        value: overflowCount.toString(),
        selected: false
    };
    return (React.createElement(Group, { gap: 4, style: { flexWrap: "wrap" } },
        maxFilterItems.map(function (filterProps) {
            return React.createElement(OrchestryFilterItem, { isLoading: isLoading, key: filterProps.value, filterProps: filterProps });
        }),
        React.createElement(OrchestryTooltip, { color: "var(--mantine-color-white)", style: { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", position: "fixed" }, p: 12, multiline: true, maw: 350, label: React.createElement(OrchestryFilterItems, { filters: overflowedFilterItems, isLoading: isLoading }) },
            React.createElement(OrchestryFilterItem, { isLoading: isLoading, key: "overflow-filter", filterProps: overflowFilterProps }))));
};
