import * as React from "react";
import "./step-complete.scss";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import AnimatedLoader from "app/components/common/animated-loader";
import RequestService from "app/services/requests";
import ProgressReport from "app/components/common/progress-report";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AirPlane from "app/assets/img/airplane.svg";
import { FormattedMessage } from "react-intl";
var StepComplete = React.memo(function (props) {
    var rwc = React.useContext(RequestWizardContext);
    var _a = React.useState(""), jobId = _a[0], setJobId = _a[1];
    var _b = React.useState(false), progressDone = _b[0], setProgressDone = _b[1];
    var _c = React.useState(undefined), finishedRequestUrl = _c[0], setFinishedRequestUrl = _c[1];
    var _d = React.useState(), needsApproval = _d[0], setNeedsApproval = _d[1];
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var inTeams = React.useState(document.getElementsByTagName("html")[0].classList.contains("in-teams"))[0];
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    //const doesHaveApproval = () => {
    React.useEffect(function () {
        var _a;
        console.log("Page 7 Current Request Update", currentRequest);
        if (currentRequest.Template !== undefined && currentRequest.Template.RequireApprovalBeforeProvisioning !== undefined) {
            console.log("rwc.currentRequest.Template.RequireApprovalBeforeProvisioning", (_a = currentRequest.Template) === null || _a === void 0 ? void 0 : _a.RequireApprovalBeforeProvisioning);
            setNeedsApproval(currentRequest.Template.RequireApprovalBeforeProvisioning);
        }
        else {
            if (currentRequest.status === "Error") {
                setNeedsApproval(undefined);
            }
        }
    }, [currentRequest]);
    //}
    React.useEffect(function () {
        if (needsApproval !== undefined && needsApproval === false) {
            if (currentRequest.ProvisioningJobId === undefined ||
                (currentRequest.ProvisioningJobId !== undefined && (currentRequest.ProvisioningJobId === null || currentRequest.ProvisioningJobId.length === 0))) {
                waitForJobId(currentRequest.GUID !== undefined ? currentRequest.GUID : "");
            }
            else if ((currentRequest.ProvisioningJobId !== undefined && currentRequest.ProvisioningJobId !== null) || currentRequest.ProvisioningJobId !== "") {
                setJobId(currentRequest.ProvisioningJobId);
            }
        }
    }, [needsApproval]);
    var waitForJobId = function (rGuid) {
        RequestService.GetJobId(rGuid)
            .then(function (returnJobId) {
            if (returnJobId !== undefined && returnJobId !== null && returnJobId.length > 0) {
                setJobId(returnJobId);
            }
            else {
                setTimeout(function () {
                    waitForJobId(rGuid);
                }, 1500);
            }
        })
            .catch(function () { });
    };
    React.useEffect(function () {
        if (progressDone === true && currentRequest.GUID !== undefined) {
            RequestService.GetRequestUrl(currentRequest.GUID)
                .then(function (url) {
                if (url !== undefined && url !== null && url.length > 0) {
                    setFinishedRequestUrl(url);
                }
            })
                .catch(function () { });
        }
    }, [progressDone]);
    var requestNewWorkspce = function () {
        rwc.reset();
    };
    return (React.createElement("div", { className: "step-complete" },
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, null, needsApproval !== undefined ? (React.createElement(React.Fragment, null, needsApproval === true ? (React.createElement("div", { className: "waiting-for-approval", "data-pw": "waiting-for-approval" },
                    React.createElement("div", { className: "airplane-image" },
                        React.createElement("img", { src: AirPlane })),
                    React.createElement("h3", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.submitted_for_approval", defaultMessage: "{workspacename} has been submitted for approval!", values: { workspacename: React.createElement("span", null, currentRequest.Name) } })),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.waiting_for_approval", defaultMessage: "Once we get approval we'll create your workspace and send you an email when it's ready." })),
                    React.createElement("div", { className: "request-another" },
                        React.createElement("div", { className: "btn", onClick: function () { return requestNewWorkspce(); } },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.request_another_workspace", defaultMessage: "Request another workspace" }))))) : (React.createElement("div", { className: "view-progress" },
                    React.createElement("div", null,
                        progressDone === false ? (React.createElement(React.Fragment, null,
                            React.createElement("h3", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.this_might_take_a_minute", defaultMessage: "This might take a minute..." })),
                            React.createElement("h6", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.creation_will_continue_background", defaultMessage: "Don't worry, creation will continue in the background" })))) : (React.createElement("h3", null,
                            currentRequest.Name,
                            " ",
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.successfully_created", defaultMessage: "Successfully Created!" }))),
                        React.createElement("div", { className: "progress-panel" },
                            React.createElement(ProgressReport, { hideProgressLog: true, turnOffAfter: false, jobId: jobId, showBeforeJobId: true, onComplete: function () {
                                    setProgressDone(true);
                                } })),
                        progressDone === false ? (React.createElement("div", { className: "close-wrapper" },
                            React.createElement("div", { className: "request-another" },
                                React.createElement("div", { className: "btn", onClick: function () { return requestNewWorkspce(); } },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.request_another_workspace", defaultMessage: "Request another workspace" }))),
                            !isInSharepoint && (React.createElement("div", { className: "view-directory" },
                                React.createElement(NavLink, { exact: true, className: "btn", to: inTeams ? "/tabs/workspace-directory" : "/directory/view" },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.goto_workspace_directory", defaultMessage: "Go to Workspace Directory" })))))) : (React.createElement("div", { className: "finished-url" }, finishedRequestUrl === undefined ? (React.createElement("div", { className: "waiting-for-url" },
                            React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement(React.Fragment, null,
                            isInSharepoint && (React.createElement("div", { className: "back-to-request" },
                                React.createElement("a", { href: "/tabs/workspace-request", rel: "noopener noreferrer" },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.back_to_requests", defaultMessage: "Back to Requests" })))),
                            React.createElement("div", { className: "link-to-new-asset" },
                                React.createElement("a", { href: finishedRequestUrl, target: "_blank", rel: "noopener noreferrer" },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_complete.goto_workspace", defaultMessage: "Go to your workspace" })))))))))))) : (React.createElement(React.Fragment, null, (currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.status) === "Error" ? (React.createElement("div", { className: "error-message" },
                    React.createElement("div", { className: "title" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_customize.error_encountered", defaultMessage: "Error Encountered" })),
                    React.createElement("div", { className: "message" }, currentRequest.message))) : (React.createElement("div", { className: "saving-to-server" },
                    React.createElement(AnimatedLoader, null))))))))));
});
export default StepComplete;
