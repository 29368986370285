var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { useCallback, useState } from "react";
import { generateSpotlightFilter } from "./recommendations-page.utils";
import { RECOMMENDATIONS_FILTERS_LIST } from "./utils/recommendations-filter.utils";
export var useRecommendationsFilters = function () {
    var _a = useState(), spotlightValue = _a[0], setSpotlightValue = _a[1];
    var _b = useState({ type: "selectable", value: RecommendationRecordVisibility.Visible, group: "Visibility", selected: true }), visibilityFilter = _b[0], setVisibilityFilter = _b[1];
    var _c = useState(RECOMMENDATIONS_FILTERS_LIST), appliedFilters = _c[0], setAppliedFilters = _c[1];
    var _d = useState(), priorityMetricConfig = _d[0], setPriorityMetricConfig = _d[1];
    var _e = useState(), topCategoryConfig = _e[0], setTopCategoryConfig = _e[1];
    var appliedFiltersDependency = __spreadArray(__spreadArray([], appliedFilters, true), [visibilityFilter], false);
    var applySpotlightFilter = useCallback(function (_a) {
        var filter = _a.filter, source = _a.source;
        generateSpotlightFilter({ filter: filter, source: source }, setSpotlightValue, setPriorityMetricConfig, setTopCategoryConfig, setAppliedFilters);
    }, []);
    var applyFilterButtonFilters = function (filters) {
        setAppliedFilters(filters);
        applySpotlightFilter({ filter: undefined });
    };
    var clearAllFilters = function () {
        setAppliedFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        applySpotlightFilter({ filter: undefined });
    };
    var removeFilter = function (filter) {
        var _a, _b;
        var selectableFilter = filter;
        var newAppliedFilters = appliedFilters.map(function (appliedFilter) { var _a, _b; return ((_a = appliedFilter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = selectableFilter.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ? __assign(__assign({}, appliedFilter), { selected: false }) : appliedFilter; });
        setAppliedFilters(newAppliedFilters);
        if (((_a = spotlightValue === null || spotlightValue === void 0 ? void 0 : spotlightValue.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = selectableFilter.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()))
            applySpotlightFilter({ filter: undefined });
    };
    return {
        appliedFilters: appliedFilters,
        visibilityFilter: visibilityFilter,
        priorityMetricConfig: priorityMetricConfig,
        topCategoryConfig: topCategoryConfig,
        applySpotlightFilter: applySpotlightFilter,
        applyFilterButtonFilters: applyFilterButtonFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        setPriorityMetricConfig: setPriorityMetricConfig,
        setTopCategoryConfig: setTopCategoryConfig,
        setVisibilityFilter: setVisibilityFilter,
        appliedFiltersDependency: appliedFiltersDependency
    };
};
