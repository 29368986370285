/**
 * Healthcheck Report Status
 */
export var HealthCheckStatus;
(function (HealthCheckStatus) {
    /**
     * Draft
     */
    HealthCheckStatus["Draft"] = "Draft";
    /**
     * Processing
     */
    HealthCheckStatus["Processing"] = "Processing";
    /**
     * Published
     */
    HealthCheckStatus["Published"] = "Published";
})(HealthCheckStatus || (HealthCheckStatus = {}));
