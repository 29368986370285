import { DEFAULT_PRIORITY_SORT } from "appv2/api/types/query-parameters.types";
import { useState } from "react";
/**
 * A hook for managing the query parameter sort order.
 */
export var useQueryParameterSort = function (_a) {
    var defaultSort = _a.defaultSort;
    var _b = useState(defaultSort !== null && defaultSort !== void 0 ? defaultSort : DEFAULT_PRIORITY_SORT), prioritySort = _b[0], setPrioritySort = _b[1];
    var togglePrioritySort = function () {
        setPrioritySort(prioritySort === "asc" ? "desc" : "asc");
    };
    return {
        prioritySort: prioritySort,
        togglePrioritySort: togglePrioritySort
    };
};
