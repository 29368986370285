var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, Text } from "@mantine/core";
import { OrchestryIconLabel } from "appv2/components/orchestry-icon-label/orchestry-icon-label.component";
import React from "react";
import "./recommendation-number-stat.scss";
export var RecommendationNumberStat = function (_a) {
    var count = _a.count, iconLabel = _a.iconLabel, onClick = _a.onClick, percentChange = _a.percentChange, footerLabel = _a.footerLabel;
    return (React.createElement(Card, { className: "recommendation-number-stat-card", onClick: onClick, variant: "stats-card-link" },
        React.createElement(OrchestryIconLabel, __assign({}, iconLabel)),
        React.createElement(Text, { size: "dxs", c: "text-primary.0" }, count),
        React.createElement("div", { className: "recommendation-number-stat-footer" },
            React.createElement(Text, { c: "gray.5", size: "xs", fw: 500 }, footerLabel))));
};
