import { MetadataCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {}
};
export function metadataCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MetadataCurrentActionTypes.Metadata_Current_Load: {
            //console.log(MetadataCurrentActionTypes.Metadata_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case MetadataCurrentActionTypes.Metadata_Current_Update: {
            // let associatedItemsUpdated = false;
            var updatedMetadata = Object.assign({}, state.item, action.payload);
            // if(state.item.AssociatedTemplates.length !== updatedMetadata.AssociatedTemplates.length)
            // {
            //     console.log("Array Changed");
            //     associatedItemsUpdated = true;
            // }
            //console.log(MetadataCurrentActionTypes.Metadata_Current_Update, updatedMetadata)
            return {
                item: updatedMetadata
            };
        }
        case MetadataCurrentActionTypes.Metadata_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
