import { Group, Stack } from "@mantine/core";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { OrchestryDataTable } from "appv2/components/orchestry-data-table/orchestry-data-table.component";
import { OrchestryFilterSelector } from "appv2/components/orchestry-filter-selector/orchestry-filter-selector.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { OrchestrySearchBox } from "appv2/components/orchestry-searchbox/orchestry-searchbox.component";
import React from "react";
import "./orchestry-admin-tool-tenant-event-browser.scss";
import { useTenantEventBrowserPage } from "./use-tenant-event-browser-page.hook";
export var AdminTenantEventBrowser = function () {
    var _a = useTenantEventBrowserPage(), table = _a.table, activePage = _a.activePage, resultsPerPage = _a.resultsPerPage, setActivePage = _a.setActivePage, setResultsPerPage = _a.setResultsPerPage, clearAllFilters = _a.clearAllFilters, setAppliedFilters = _a.setAppliedFilters, appliedFilters = _a.appliedFilters, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, onSearchBoxXClicked = _a.onSearchBoxXClicked, searchTextInputValue = _a.searchTextInputValue, isLoading = _a.isLoading, totalEventCount = _a.totalEventCount, totalPageEventCount = _a.totalPageEventCount;
    return (React.createElement(Portlet, { className: "orchestry-admin-tool-tenant-event-browser" },
        React.createElement(PortletBody, null,
            React.createElement(Stack, { gap: 8 },
                React.createElement(Group, { gap: 8, justify: "right" },
                    React.createElement(OrchestrySearchBox, { skeletonWidth: 320, isLoading: isLoading, placeholder: "Search for a Tenant Name", onXClicked: onSearchBoxXClicked, onChange: onSearchBoxChange, value: searchTextInputValue, onKeyDown: onSearchBoxKeyDown, radius: "6px" }),
                    React.createElement(OrchestryFilterSelector, { isLoading: isLoading, popoverPosition: "bottom-end", buttonTitle: "Filters", filters: appliedFilters, onApply: setAppliedFilters, onClear: clearAllFilters, buttonSize: "sm" })),
                React.createElement(Stack, { gap: 0 },
                    React.createElement(OrchestryDataTable, { table: table }),
                    React.createElement(OrchestryPagination, { variant: "table", onResultsPerPageChange: function (value) {
                            setResultsPerPage(value);
                            localStorage.setItem("tenant-events-resultsPerPage", value);
                        }, resultsPerPage: resultsPerPage, activePage: activePage !== null && activePage !== void 0 ? activePage : 0, setactivePage: setActivePage, totalCount: totalEventCount !== null && totalEventCount !== void 0 ? totalEventCount : 0, pageTotal: totalPageEventCount !== null && totalPageEventCount !== void 0 ? totalPageEventCount : 0 }))))));
};
