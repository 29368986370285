import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var useGrowthDonutChartTile = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useHealthCheckActivity(), intl = _h.intl, isMobile = _h.isMobile, isLoading = _h.isLoading, _j = _h.data, _k = _j === void 0 ? {} : _j, _l = _k.MetricsData, _m = _l === void 0 ? {} : _l, _o = _m.Workspaces, _p = _o === void 0 ? {} : _o, WorkspaceTypeGrowth = _p.WorkspaceTypeGrowth;
    // The backend has an intermediate Data key that we need to remove.
    // This is because of how the donut chart delta text format is expected by mantine.
    // In order for the backend to dynamically set properties, it was easiest to add an intermediate Data key.
    var teamsData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "Teams"; });
    var privateChannelsData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "Private Channels"; });
    var teamSitesData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "Teams Sites"; });
    var communicationSitesData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "Comm Sites"; });
    var classicSitesData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "Classic Sites"; });
    var vivaEngageData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "Viva Engage"; });
    var oneDriveSpacesData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.find(function (item) { return item.Type === "OneDrive Spaces"; });
    var teamsValues = (_a = teamsData === null || teamsData === void 0 ? void 0 : teamsData.Total) !== null && _a !== void 0 ? _a : 0;
    var privateChannelsValues = (_b = privateChannelsData === null || privateChannelsData === void 0 ? void 0 : privateChannelsData.Total) !== null && _b !== void 0 ? _b : 0;
    var teamSitesValues = (_c = teamSitesData === null || teamSitesData === void 0 ? void 0 : teamSitesData.Total) !== null && _c !== void 0 ? _c : 0;
    var communicationSitesValues = (_d = communicationSitesData === null || communicationSitesData === void 0 ? void 0 : communicationSitesData.Total) !== null && _d !== void 0 ? _d : 0;
    var classicSitesValues = (_e = classicSitesData === null || classicSitesData === void 0 ? void 0 : classicSitesData.Total) !== null && _e !== void 0 ? _e : 0;
    var vivaEngageValues = (_f = vivaEngageData === null || vivaEngageData === void 0 ? void 0 : vivaEngageData.Total) !== null && _f !== void 0 ? _f : 0;
    var oneDriveSpacesValues = (_g = oneDriveSpacesData === null || oneDriveSpacesData === void 0 ? void 0 : oneDriveSpacesData.Total) !== null && _g !== void 0 ? _g : 0;
    var total = teamsValues + privateChannelsValues + teamSitesValues + communicationSitesValues + classicSitesValues + vivaEngageValues + oneDriveSpacesValues;
    var donutChartData = WorkspaceTypeGrowth === null || WorkspaceTypeGrowth === void 0 ? void 0 : WorkspaceTypeGrowth.map(function (item) {
        var _a;
        return (_a = {},
            _a[item.Type] = item.Total,
            _a.PreviousValue = item.PreviousValue,
            _a.DeltaValue = item.DeltaValue,
            _a.DeltaPercentage = item.DeltaPercentage,
            _a.DataCoverageDaysCount = item.DataCoverageDaysCount,
            _a);
    });
    return {
        intl: intl,
        isLoading: isLoading,
        teamsData: teamsData,
        privateChannelsData: privateChannelsData,
        teamSitesData: teamSitesData,
        communicationSitesData: communicationSitesData,
        classicSitesData: classicSitesData,
        vivaEngageData: vivaEngageData,
        oneDriveSpacesData: oneDriveSpacesData,
        total: total,
        WorkspaceTypeGrowth: WorkspaceTypeGrowth,
        teamsValues: teamsValues,
        privateChannelsValues: privateChannelsValues,
        teamSitesValues: teamSitesValues,
        communicationSitesValues: communicationSitesValues,
        classicSitesValues: classicSitesValues,
        vivaEngageValues: vivaEngageValues,
        oneDriveSpacesValues: oneDriveSpacesValues,
        donutChartData: donutChartData,
        isMobile: isMobile
    };
};
