import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import axios from "axios";
var AppInsightsService = /** @class */ (function () {
    function AppInsightsService() {
    }
    AppInsightsService.GetKey = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/Start";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Install Start Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    AppInsightsService.Enable = function () {
        //console.log("App Insights", process.env.NODE_ENV);
        if (process.env.NODE_ENV !== "development") {
            var insightsKey = "ee1bbcf1-4c60-42ba-b6ce-d195b4ea9cfb";
            if (window.location.host === "app.orchestry.com") {
                insightsKey = "43f62908-c838-4ae4-a440-4f6c83b1255d";
            }
            if (window.location.host === "app-dev.orchestry.com") {
                insightsKey = "378e2e60-5aec-4feb-86bb-3df5282d5aad";
            }
            if (window.location.host === "app-beta.orchestry.com") {
                insightsKey = "5c146d78-181f-43a2-ae1d-6402b9bb5cac";
            }
            if (window.location.host === "app-qa.orchestry.com" || window.location.host === "app-earlyaccess.orchestry.com") {
                insightsKey = "e0e7805a-fc5c-4b6f-9f14-b3f933eba0b5";
            }
            var appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: insightsKey,
                    disableFetchTracking: false,
                    enableCorsCorrelation: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    enableAutoRouteTracking: true,
                    excludeRequestFromAutoTrackingPatterns: [/.*\.pendo\.io/]
                    /* ...Other Configuration Options... */
                }
            });
            appInsights.loadAppInsights();
            appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
            console.log("App Insights On");
        }
        else {
            console.log("App Insights Off");
        }
        // AppInsightsService.GetKey().then((key)=> {
        //   const appInsights = new ApplicationInsights({ config: {
        //     instrumentationKey: 'ee1bbcf1-4c60-42ba-b6ce-d195b4ea9cfb'
        //     /* ...Other Configuration Options... */
        //   } });
        //   appInsights.loadAppInsights();
        //   appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
        //   console.log("App Insights On");
        // })
    };
    return AppInsightsService;
}());
export default AppInsightsService;
AppInsightsService.Enable();
