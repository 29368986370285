import * as React from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { useIntl, FormattedMessage } from "react-intl";
var RequestDetailsTemplateTeamConfig = React.memo(function (props) {
    var intl = useIntl();
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.template_config.team_config", defaultMessage: "Team Configuration" }) }),
        React.createElement(PortletBody, null,
            React.createElement("h6", null,
                React.createElement(FormattedMessage, { id: "page.request_details.template_config.team_permissions", defaultMessage: "Team Permissions" })),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_guest_creating_channels", defaultMessage: "Allow creating and updating channels" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowMemberCreatingAndUpdatingChannels) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_members_create_private_channel", defaultMessage: "Allow members to create private channels" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowMemberCreatePrivateChannels) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_members_delete_channel", defaultMessage: "Allow members to delete and restore channels" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowMembersToDeleteAndRestoreChannels) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_members_add_remove_apps", defaultMessage: "Allow members to add and remove apps" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowMembersToAddAndRemoveApps) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_members_create_tabs", defaultMessage: "Allow members to create, update, and remove tabs" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowMembersToCreateUpdateAndRemoveTabs) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_members_create_connectors", defaultMessage: "Allow members to create, update, and remove connectors" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowMembersToCreateUpdateAndRemoveConnectors) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.give_members_option_delete_message", defaultMessage: "Give members the option to delete their messages" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.GiveMembersTheOptionToDeleteTheirMessages) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.give_members_edit_messages", defaultMessage: "Give members the option to edit their messages" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.GiveMembersTheOptionToEditTheirMessages) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("h6", { style: { marginTop: "20px" } },
                React.createElement(FormattedMessage, { id: "page.request_details.template_config.guest_permissions", defaultMessage: "Guests Permissions" })),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_user_create_channels", defaultMessage: "Allow creating and updating channels" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowGuestCreatingAndUpdatingChannels) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_guests_delete_channels", defaultMessage: "Allow guests to delete channels" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowGuestsToDeleteChannels) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))))));
});
export default RequestDetailsTemplateTeamConfig;
