var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useGetRecommendationHistoryQuery } from "appv2/api/recommendations/recommendations.api";
import { hasMoreData } from "appv2/utils/paging.utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
/**
 * The default page size for the recommendation history before expansion.
 */
var DEFAULT_PAGE_SIZE = 2;
// Is one larger than what we show. On expanded but no infinite scroll, one gets cropped with overflow hidden.
// Then when we show infinite scroll, the overflow becomes visible allows scrolling.
var EXPANDED_PAGE_SIZE = 9;
/**
 * Helper hook for recomendation card history.
 */
export var useRecommendationCardHistory = function (_a) {
    var _b, _c, _d;
    var recommendation = _a.recommendation, isCardExpanded = _a.isCardExpanded;
    var tid = useSelector(function (state) { return state.recommendationsPage.tid; });
    var _e = React.useState(DEFAULT_PAGE_SIZE), pageSize = _e[0], setPageSize = _e[1];
    var _f = React.useState(false), showInfiniteScroll = _f[0], setShowInfiniteScroll = _f[1];
    var _g = React.useState(false), isExpanded = _g[0], setIsExpanded = _g[1];
    var _h = React.useState(1), page = _h[0], setPage = _h[1];
    var _j = useState([]), infiniteScrollItems = _j[0], setInfiniteScrollItems = _j[1];
    var _k = useGetRecommendationHistoryQuery({
        queryParameters: {
            filter: "RecommendationGUID=\"".concat((_b = recommendation.RecommendationRecord) === null || _b === void 0 ? void 0 : _b.RecommendationGUID, "\""),
            orderBy: "UpdatedDate desc",
            page: page,
            pageSize: pageSize
        },
        tid: tid
    }, { skip: !isCardExpanded }), data = _k.data, error = _k.error, isFetching = _k.isFetching, isUninitialized = _k.isUninitialized;
    var dataItems = data === null || data === void 0 ? void 0 : data.Items;
    useEffect(function () {
        if (dataItems) {
            setInfiniteScrollItems(__spreadArray(__spreadArray([], infiniteScrollItems, true), dataItems, true));
        }
    }, [dataItems]);
    var onExpandedButtonClick = function (event) {
        setPageSize(isExpanded ? DEFAULT_PAGE_SIZE : EXPANDED_PAGE_SIZE);
        setIsExpanded(!isExpanded);
        // reset infinite scroll state
        setInfiniteScrollItems([]);
        setPage(1);
        setShowInfiniteScroll(false);
        event.stopPropagation();
    };
    var onShowAllButtonClick = function (event) {
        setShowInfiniteScroll(true);
        event.stopPropagation();
    };
    var getNextPage = function () { return setPage(page + 1); };
    var countIsGreaterThanDefaultPageSize = data && data.TotalCount > DEFAULT_PAGE_SIZE;
    var countIsGreaterThanExpandedPageSize = data && data.TotalCount > EXPANDED_PAGE_SIZE;
    var renderShowAllButton = countIsGreaterThanExpandedPageSize && isExpanded && !showInfiniteScroll;
    var showDefaultPageSizeSkeleton = isCardExpanded && (isUninitialized || isFetching) && pageSize === DEFAULT_PAGE_SIZE;
    var showExpandedPageSizeSkeleton = isCardExpanded && isFetching && !showInfiniteScroll && pageSize === EXPANDED_PAGE_SIZE;
    var showHistoryLabel = !showDefaultPageSizeSkeleton && !showExpandedPageSizeSkeleton;
    return {
        data: data,
        error: error,
        isFetching: isFetching,
        isExpanded: isExpanded,
        onExpandedButtonClick: onExpandedButtonClick,
        onShowAllButtonClick: onShowAllButtonClick,
        renderShowAllButton: renderShowAllButton,
        showInfiniteScroll: showInfiniteScroll,
        shouldRenderExpandableButton: countIsGreaterThanDefaultPageSize,
        shouldShowHistory: (!!data && data.TotalCount > 0) || showDefaultPageSizeSkeleton,
        showDefaultPageSizeSkeleton: showDefaultPageSizeSkeleton,
        showExpandedPageSizeSkeleton: showExpandedPageSizeSkeleton,
        hasMoreDataForInfiniteScroll: hasMoreData(data) && showInfiniteScroll,
        dataLength: (_d = (_c = data === null || data === void 0 ? void 0 : data.Items) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0,
        getNextPage: getNextPage,
        page: page,
        infiniteScrollItems: infiniteScrollItems,
        showHistoryLabel: showHistoryLabel
    };
};
