import * as React from "react";
import { Form } from "react-bootstrap";
import ManagedMetadataPicker from "app/components/common/mananged-metadata-picker";
var ReportFilterManagedMetaData = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        React.createElement("div", { className: "metadata-input" },
            React.createElement(ManagedMetadataPicker, { startNodeJson: props.Refiner.ExtendedProperties.TermStartJson, allowMultiple: true, placeholder: "Search...", allowTextEntry: true, mmdJsonValue: props.Refiner.CurrentValue, view: "input-picker", onChange: function (metadataJson) { return props.FilterOnChange(props.Refiner, metadataJson); } }))));
});
export default ReportFilterManagedMetaData;
