import "@mantine/core/styles.css";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestryImageDropzone } from "appv2/components/orchestry-image-dropzone/orchestry-image-dropzone.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import { OrchestrySettingsPageControl } from "appv2/components/orchestry-settings-page-control/orchestry-settings-page-control.component";
import { ORCHESTRY_LOGO_IMAGE_MIME_TYPE, ORCHESTRY_LOGO_MAX_SIZE_BYTES } from "appv2/types/globals.types";
import React from "react";
import { CustomRecommendationBrandingDescription } from "./recommendations-settings-control-descriptions.component";
import "./recommendations-settings-page.scss";
import { useRecommendationsSettingsPage } from "./use-recommendations-settings-page.hook";
export var RecommendationSettingsPage = function () {
    var _a = useRecommendationsSettingsPage(), form = _a.form, onBackButtonClicked = _a.onBackButtonClicked, handleFormSubmit = _a.handleFormSubmit, handleImageDrop = _a.handleImageDrop, isSaveDisabled = _a.isSaveDisabled, isPageLoading = _a.isPageLoading;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "recommendation-settings-page" },
                React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
                    React.createElement(Subtitle, { title: "Recommendations", subtitle: "Settings" },
                        React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, "Go Back to Recommendations")),
                    React.createElement(OrchestryPageHeader, null,
                        React.createElement(OrchestryPageTitle, { title: "Recommendations", subTitle: "Configure your Recommendations settings including branding." }),
                        React.createElement(OrchestryActionButtons, null,
                            React.createElement(OrchestrySaveButton, { disabled: isSaveDisabled }))),
                    React.createElement(OrchestrySettingsPageControl, { title: "Custom Recommendation Branding", description: CustomRecommendationBrandingDescription() },
                        React.createElement(OrchestryImageDropzone, { loading: isPageLoading, onDrop: handleImageDrop, accept: ORCHESTRY_LOGO_IMAGE_MIME_TYPE, maxSize: ORCHESTRY_LOGO_MAX_SIZE_BYTES, base64Value: form.getInputProps("BrandingImage").value, imageStyles: { width: "38px", height: "38px" }, onPreviewRemoveClick: function () { return form.setFieldValue("BrandingImage", ""); } })),
                    React.createElement(OrchestryDivider, null))))));
};
