import React from "react";
import QuickActionsPanel from "_metronic/partials/layout/QuickActionsPanel";
import LanguageSelector from "_metronic/partials/layout/LanguageSelector";
import UserProfile from "_metronic/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import OrchestryTrialInformation from "app/components/orchestry-context/orchestry-trial-information";
import { useSelector } from "react-redux";

const Topbar = React.memo(() => {
   const orchectryContext = useSelector(state => state.orchestryContextCurrentState.item);

   return (
      <div className="kt-header__topbar">
         <OrchestryTrialInformation />

         {orchectryContext.IsCurrentUserOrchestryAdmin == true ? <QuickActionsPanel bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")} skin="dark" iconType="" useSVG="true" gridNavSkin="light" /> : null}

         <LanguageSelector orchestryContext={orchectryContext} />

         <UserProfile showAvatar={true} showHi={true} showBadge={false} />
      </div>
   );
});
export default Topbar;
