import * as React from "react";
import "./card-reset-status-tool.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import LifecycleService from "app/services/lifecycle";
import * as TableList from "app/components/common/table-list";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import AnimatedLoader from "app/components/common/animated-loader";
import { NavLink } from "react-router-dom";
var CardResetStatusTool = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(""), lifecycleSearchTerm = _a[0], setLifecycleSearchTerm = _a[1];
    var _b = React.useState(false), searchLoading = _b[0], setSearchLoading = _b[1];
    var _c = React.useState(), reportItems = _c[0], setReportItems = _c[1];
    var _d = React.useState(false), resettingItem = _d[0], setResettingItem = _d[1];
    var _e = React.useState(null), resetSiteId = _e[0], setResetSiteId = _e[1];
    var _f = React.useState(null), resetSiteTitle = _f[0], setResetSiteTitle = _f[1];
    var loadSearchResults = function () {
        if (lifecycleSearchTerm !== undefined) {
            if (lifecycleSearchTerm !== "") {
                setSearchLoading(true);
                LifecycleService.SearchForNonActiveWorkspace(lifecycleSearchTerm).then(function (items) {
                    console.log("Workspace Search Results", items);
                    setReportItems(items);
                    setSearchLoading(false);
                });
            }
            else {
                setSearchLoading(false);
                setReportItems([]);
            }
        }
    };
    React.useEffect(function () {
        loadSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lifecycleSearchTerm]);
    var resetStatus = function (siteId, title) {
        setResettingItem(true);
        LifecycleService.ResetWorkspacePolicyToActive(siteId).then(function () {
            setResettingItem(false);
            setLifecycleSearchTerm("");
            setSearchLoading(false);
            setResetSiteId(siteId);
            setResetSiteTitle(title);
        });
    };
    var searchAgain = function () {
        setResetSiteId(null);
        setResetSiteTitle(null);
    };
    var getRelativeUrl = function (url) {
        var returnUrl = "";
        if (orchestryContext.TenantSharePointUrl !== undefined) {
            returnUrl = url.replace(orchestryContext.TenantSharePointUrl, "");
        }
        return returnUrl;
    };
    return (React.createElement("div", { className: "card-reset-workspace-status", "data-pw": "reset-workspace-status" },
        React.createElement(Portlet, null,
            React.createElement(PortletHeader, { title: "Reset Workspace Status To Active" }),
            React.createElement(PortletBody, null,
                React.createElement(Form.Group, { className: "form-group", "data-pw": "form-group" },
                    React.createElement(Form.Label, null, "Search for Workspaces by name"),
                    React.createElement(Form.Control, { placeholder: "Workspace Name", autoComplete: "off", value: lifecycleSearchTerm, onChange: function (event) { return setLifecycleSearchTerm(event.target.value); } })),
                resettingItem === true ? (React.createElement("div", { className: "resetting-please-wait" },
                    React.createElement(AnimatedLoader, { type: "squares", size: "xxsmall" }),
                    React.createElement("div", { className: "text" }, "Resetting Workspace to active..."))) : resetSiteId !== null && resetSiteTitle !== null ? (React.createElement("div", { className: "reset-done" },
                    React.createElement(NavLink, { to: "/lifecycle/listing/" + (resetSiteId !== null ? resetSiteId : "") }, resetSiteTitle !== null ? resetSiteTitle : ""),
                    React.createElement("div", { className: "text" }, "Workspace status to set to Active"),
                    React.createElement("div", { className: "btn btn-success", onClick: function () { return searchAgain(); } }, "Reset another workspace"))) : reportItems !== undefined || searchLoading === true ? (searchLoading === true ? (React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 17 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                    React.createElement(SkeletonFakeTableCol, null,
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 20 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })))) : (React.createElement(TableList.Context, { showLoader: false, wrappedInPortlet: false, listItems: reportItems },
                    React.createElement(TableList.TableList, null,
                        React.createElement(TableList.HeaderItem, null, "Name"),
                        React.createElement(TableList.HeaderItem, { type: "centered" }, "Current Status"),
                        React.createElement(TableList.HeaderItem, { type: "centered" }, "Actions"),
                        React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", { className: "reset-title" },
                                React.createElement("strong", null, item.Title),
                                React.createElement("a", { target: "_blank", title: item.SiteUrl, className: "sharepoint-link", href: item.SiteUrl }, getRelativeUrl(item.SiteUrl)))); } }),
                        React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "ArchivalStatus" }),
                        React.createElement(TableList.ContentItemCustomRender, { className: "center-actions", render: function (item) {
                                return item.ArchivalStatus === "Active" ? (React.createElement("span", null, "Already Active")) : (React.createElement("div", { className: "delete btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () { return resetStatus(item.SiteId, item.Title); } },
                                    React.createElement("i", { className: "fa fa-undo" })));
                            } }))))) : null))));
});
export default CardResetStatusTool;
