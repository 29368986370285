import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import HealthChecksTeamsPreviewImage from "appv2/assets/img/health-check-teams-preview.png";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import "./health-check-ready-teams-page.scss";
import { useHealthCheckTeamsPage } from "./use-health-check-teams-page.hook";
export var HealthCheckReadyTeamsPage = function () {
    var _a = useHealthCheckTeamsPage(), intl = _a.intl, healthCheckGuid = _a.healthCheckGuid, healthCheckName = _a.healthCheckName, isLoading = _a.isLoading;
    return (React.createElement("div", { className: "health-check-ready-teams-page" },
        React.createElement("div", { className: "orchestry" },
            React.createElement("img", { src: ORCH_SVG_ICONS.Logomark, className: "logo", alt: "Orchestry" }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", ml: 8, fw: "700", size: "20px", lh: "20px" }, intl.formatMessage({ id: "page.health_check_ready_teams.orchestry", defaultMessage: "Orchestry" }))),
        React.createElement("div", { className: "title" },
            React.createElement("img", { src: ORCH_SVG_ICONS.PartyPopper, className: "party-popper", alt: "Congratulations!" }),
            React.createElement(OrchestryText, { isLoading: isLoading, ml: 8, c: "text-primary.0", fw: "900", size: "20px", lh: "20px" }, intl.formatMessage({ id: "page.health_check_ready_teams.title", defaultMessage: "Health Check Ready" }))),
        React.createElement(OrchestryHighlight, { isLoading: isLoading, mt: 28, mb: 28, c: "text-primary.0", fw: "300", size: "14px", lh: "14px", highlight: [healthCheckName], highlightStyles: { fontWeight: 700, backgroundColor: "var(--mantine-color-text-primary-0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" } }, intl.formatMessage({ id: "page.health_check_ready_teams.subTitle", defaultMessage: "A Health Check called {healthCheckName} is ready to view" }, {
            healthCheckName: healthCheckName
        })),
        React.createElement("img", { className: "preview-image", src: HealthChecksTeamsPreviewImage, alt: "health-check" }),
        React.createElement(OrchestryText, { isLoading: isLoading, mt: 20, c: "text-primary.0", fw: "300", size: "14px", lh: "14px" }, intl.formatMessage({ id: "page.health_check_ready_teams.description", defaultMessage: "This experience is designed for a full Browser experience." })),
        React.createElement(OrchestryText, { isLoading: isLoading, mt: 8, c: "text-primary.0", fw: "500", size: "14px", lh: "14px" }, intl.formatMessage({ id: "page.health_check_ready_teams.button_directive", defaultMessage: "Click the link below to access the Health Check." })),
        React.createElement(OrchestryButton, { isLoading: isLoading, target: "_blank", rel: "noopener noreferrer", component: "a", href: "/health-checks/view/".concat(healthCheckGuid), className: "button", size: "md", mt: 40, iconType: "font", iconLeft: ORCH_ICONS.calendarHeart01, variant: "primary" }, intl.formatMessage({ id: "page.health_check_ready_teams.button_text", defaultMessage: "Open Health Check in Browser" }))));
};
