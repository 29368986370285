var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./table-list.scss";
import { Table } from "react-bootstrap";
import { findMultipleByObjectDisplayName } from "app/utils/findByObjectDisplayName";
import InfoToolTip from "app/components/common/infotooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import DropdownAssociated from "app/components/common/dropdown-associated";
import { NavLink } from "react-router-dom";
import FormSearchbox from "app/components/common/form-searchbox";
import highlightWords from "app/utils/highlightWords";
import Async from "app/components/common/async";
import * as AsyncTypes from "app/store/async";
import FormToggle from "app/components/common/form-toggle";
import DocumentIcon from "app/components/common/document-icon";
import moment from "moment";
import FormDeleteConfirmation from "app/components/common/form-delete-confirmation";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import { SkeletonLoaderFakeBox } from "../skeleton-loaders";
import { useIntl, FormattedMessage } from "react-intl";
var TabletListContext = React.createContext({});
export var Context = React.memo(function (props) {
    var _a = React.useState([]), list = _a[0], setList = _a[1];
    var _b = React.useState(""), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState([]), headerTitles = _c[0], setHeaderTitles = _c[1];
    var _d = React.useState(false), hasPaging = _d[0], setHasPaging = _d[1];
    // React.useEffect(() => {
    //     //console.log("Table List Update", props.listItems?.length, list.length);
    // });
    var clearSearch = function () {
        if (props.listItems !== undefined) {
            setList(__spreadArray([], props.listItems, true));
        }
        setSearchTerm("");
    };
    var filterItems = function (value) {
        if (props.listItems !== undefined) {
            if (value.length > 0) {
                //console.log("props.listItems", props.listItems);
                var foundOptions = props.listItems.filter(function (f) {
                    var isMatch = false;
                    if (f.Title !== undefined && f.Title !== null) {
                        if (!isMatch)
                            isMatch = f.Title.toLowerCase().indexOf(value.toLowerCase()) > -1;
                    }
                    if (f.Description && f.Description !== null) {
                        if (!isMatch)
                            isMatch = f.Description.toLowerCase().indexOf(value.toLowerCase()) > -1;
                    }
                    if (f.DisplayName && f.DisplayName !== null) {
                        if (!isMatch)
                            isMatch = f.DisplayName.toLowerCase().indexOf(value.toLowerCase()) > -1;
                    }
                    if (f.Name && f.Name !== null) {
                        if (!isMatch)
                            isMatch = f.Name.toLowerCase().indexOf(value.toLowerCase()) > -1;
                    }
                    if (f.TenantDisplayName && f.TenantDisplayName !== null) {
                        if (!isMatch)
                            isMatch = f.TenantDisplayName.toLowerCase().indexOf(value.toLowerCase()) > -1;
                    }
                    return isMatch;
                });
                //console.log("foundoptions", foundOptions);
                setList(__spreadArray([], foundOptions, true));
                setSearchTerm(value);
            }
            else {
                //console.log("props.listItems", props.listItems);
                setList(__spreadArray([], props.listItems, true));
                setSearchTerm("");
            }
        }
    };
    React.useEffect(function () {
        //console.log("Table List Items", props.listItems);
    }, [props.listItems]);
    var orchestryListSearchFilterEvent = function (e) {
        filterItems(e.detail);
        //console.log('Search Event Keydown Heard!', e.detail);
    };
    var orchestryListSearchClearEvent = function (e) {
        clearSearch();
        //console.log('Search Event Clear Heard!', e.detail);
    };
    React.useEffect(function () {
        //console.log("Table List Items Events");
        document.addEventListener("orchestry-list-search-filter", orchestryListSearchFilterEvent);
        document.addEventListener("orchestry-list-search-clear", orchestryListSearchClearEvent);
        return function () {
            document.removeEventListener("orchestry-list-search-filter", orchestryListSearchFilterEvent);
            document.removeEventListener("orchestry-list-search-clear", orchestryListSearchClearEvent);
        };
    }, [props.listItems]);
    React.useEffect(function () {
        if (props.listItems !== undefined) {
            //console.log("Set List to LilstItems", props.listItems)
            //if(props.listItems.length !== 0 && searchTerm.length === 0){
            if (searchTerm.length === 0) {
                setList(props.listItems);
                //console.log("List Items Set", props.listItems)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listItems, searchTerm.length]);
    var getResponsiveClassNames = function (listItemProps) {
        var returnClassNames = "";
        if (listItemProps.desktopShow !== undefined && listItemProps.desktopShow === false) {
            returnClassNames += " hide-desktop";
        }
        if (listItemProps.mobileShow !== undefined && listItemProps.desktopShow === false) {
            returnClassNames += " hide-mobile";
        }
        if (listItemProps.isMobileTitle !== undefined && listItemProps.isMobileTitle === true) {
            //console.log("is mobile title!")
            returnClassNames += " is-mobile-title";
        }
        return returnClassNames;
    };
    var getResponsiveTitle = function (listItemIndex) {
        //console.log("Header Titles", headerTitles, listItemIndex);
        if (listItemIndex !== undefined) {
            return headerTitles[listItemIndex] !== undefined && headerTitles[listItemIndex] !== "undefined" ? React.createElement("div", { className: "mobile-title" }, headerTitles[listItemIndex]) : null;
        }
        else {
            return null;
        }
        // return <span dangerouslySetInnerHTML={{ __html: ""}}></span>;
    };
    var responsiveTitleClick = function (listItemProps, event) {
        if (listItemProps.isMobileTitle !== undefined && listItemProps.isMobileTitle === true) {
            var parentTR = event.currentTarget.parentElement;
            if (parentTR === null || parentTR === void 0 ? void 0 : parentTR.classList.contains("mobile-open")) {
                parentTR === null || parentTR === void 0 ? void 0 : parentTR.classList.remove("mobile-open");
            }
            else {
                parentTR === null || parentTR === void 0 ? void 0 : parentTR.classList.add("mobile-open");
            }
        }
    };
    var tlc = {
        rootProps: props,
        headerTitles: headerTitles,
        setHeaderTitles: setHeaderTitles,
        listItems: list,
        searchTerm: searchTerm,
        clearSearch: clearSearch,
        filterItems: filterItems,
        showLoader: props.showLoader !== undefined ? props.showLoader : true,
        getResponsiveClassNames: getResponsiveClassNames,
        getResponsiveTitle: getResponsiveTitle,
        responsiveTitleClick: responsiveTitleClick,
        hasPaging: hasPaging,
        setHasPaging: setHasPaging,
        addRowClick: props.addRowClick,
        addRowText: props.addRowText,
        rowClick: props.rowClick
    };
    return (React.createElement(TabletListContext.Provider, { value: tlc },
        React.createElement(React.Fragment, null, props.children)));
});
export var SearchBox = React.memo(function (props) {
    //const tlc = React.useContext(TabletListContext) as ITableListContext;
    var dispatchSearchChange = function (value) {
        //@ts-ignore
        var event = new CustomEvent("orchestry-list-search-filter", { detail: value });
        document.dispatchEvent(event);
    };
    var dispatchSearchClear = function () {
        //@ts-ignore
        var event = new CustomEvent("orchestry-list-search-clear");
        document.dispatchEvent(event);
    };
    return (React.createElement(FormSearchbox, { placeholder: props.placeholder ? props.placeholder : "Search", 
        // handler={(input) => {tlc.filterItems && tlc.filterItems(input.target.value) }}
        handler: function (input) {
            dispatchSearchChange(input.target.value);
        }, clearButtonHandler: function () {
            dispatchSearchClear();
        } }));
});
export var TableList = React.memo(function (props) {
    var intl = useIntl();
    var tlc = React.useContext(TabletListContext);
    var headerChildren = findMultipleByObjectDisplayName(props.children, HeaderItem);
    var contentChildren = findMultipleByObjectDisplayName(props.children, ContentItem);
    var tableWrapper = React.useRef();
    var tableRef = React.useRef();
    var _a = React.useState("1px"), minHeight = _a[0], setMinHeight = _a[1];
    React.useEffect(function () {
        if (tlc.headerTitles !== null && tlc.headerTitles !== undefined) {
            if (tlc.headerTitles.length === 0) {
                var titles_1 = [];
                headerChildren.forEach(function (header, index) {
                    if (header.props.mobileTitle !== undefined) {
                        titles_1.push(header.props.mobileTitle + "");
                    }
                    else {
                        titles_1.push(header.props.children + "");
                    }
                });
                tlc.setHeaderTitles(titles_1);
            }
        }
    }, [tlc.headerTitles]);
    React.useEffect(function () {
        if (tlc.hasPaging) {
            setTimeout(function () {
                var _a;
                setMinHeight(((_a = tableWrapper.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) + "px");
                //console.log("tBody Ref", tableWrapper.current?.offsetHeight, tableWrapper.current?.style.minHeight);
            }, 100);
        }
    }, [tlc.hasPaging, tlc.rootProps.listItems]);
    //console.log("tlc.headerTitles", tlc.headerTitles);
    var assignPropToContextChildren = function (listItem, cc) {
        //console.log("assignPropToContextChildren", listItem)
        var copyCC = [];
        cc.forEach(function (contextChild, index) {
            copyCC.push(React.cloneElement(contextChild, {
                listItem: listItem,
                theIndex: index,
                key: index
            }));
        });
        //console.log("copyCC", copyCC)
        return copyCC;
    };
    var tableElement = (React.createElement(React.Fragment, null,
        React.createElement(Table, { ref: tableRef, className: "table-list-table table-hover" + (props.tstyle && props.tstyle === "border-bottom-on" ? " border-bottom-on" : "") },
            React.createElement("thead", null,
                React.createElement("tr", null, headerChildren)),
            React.createElement("tbody", null,
                tlc.showLoader === true ? (React.createElement(Async, { uid: tlc.rootProps.asyncListLoadUID, status: AsyncTypes.AsyncStatus.Started },
                    React.createElement(React.Fragment, null,
                        React.createElement("tr", null, headerChildren.map(function (child, index) { return (React.createElement("td", { key: "tl-1-" + index },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32 }))); })),
                        React.createElement("tr", null, headerChildren.map(function (child, index) { return (React.createElement("td", { style: { borderTopWidth: 0 }, key: "tl-2-" + index },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32 }))); })),
                        React.createElement("tr", null, headerChildren.map(function (child, index) { return (React.createElement("td", { style: { borderTopWidth: 0 }, key: "tl-3-" + index },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32 }))); }))))) : null,
                React.createElement(Async, { uid: tlc.rootProps.asyncListLoadUID, status: AsyncTypes.AsyncStatus.Succeeded, overrideShow: tlc.showLoader === false ? true : false },
                    tlc.listItems.map(function (listItem) {
                        //const trRef = React.useRef() as React.MutableRefObject<HTMLTableRowElement>;
                        return (React.createElement("tr", { key: listItem.GUID, onClick: function () {
                                if (tlc.rowClick !== undefined) {
                                    tlc.rowClick(listItem);
                                }
                            }, style: tlc.rowClick !== undefined ? { cursor: "pointer" } : {} }, assignPropToContextChildren(listItem, contentChildren)));
                    }),
                    tlc.addRowClick !== undefined && tlc.addRowText !== undefined ? (React.createElement("tr", { className: "add-row" }, __spreadArray([], Array(headerChildren.length), true).map(function (v, index) {
                        return index === 0 ? (React.createElement("td", { key: "tlc-1-" + index },
                            React.createElement("div", { className: "add-col-item", onClick: function () {
                                    tlc.addRowClick !== undefined && tlc.addRowClick();
                                } },
                                React.createElement("span", { className: "btn btn-sm btn-clean btn-icon btn-icon-sm" },
                                    React.createElement("i", { className: "fa fa-plus" })),
                                React.createElement("span", null, tlc.addRowText)))) : (React.createElement("td", { key: "tlc-1-" + index },
                            React.createElement("span", null)));
                    }))) : (tlc.listItems.length === 0 && (React.createElement("tr", { className: "fadein-500ms no-results" },
                        React.createElement("td", { colSpan: headerChildren.length, className: "text-center no-results-td" },
                            React.createElement("span", { className: "fadein-500ms" }, props.noResultsText !== undefined ? props.noResultsText : intl.formatMessage({ id: "component.directory.list_table.no_results", defaultMessage: "No Results" })))))))))));
    if (tlc.rootProps.wrappedInPortlet !== undefined && tlc.rootProps.wrappedInPortlet === false) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { ref: tableWrapper, className: "table-list no-portlet", style: { minHeight: minHeight }, "data-pw": "table-list" }, tableElement)));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { ref: tableWrapper, className: "table-list kt-portlet kt-portlet--mobile", style: { minHeight: minHeight } },
                React.createElement("div", { className: "kt-portlet__body" }, tableElement))));
    }
});
export var SearchPaging = React.memo(function (props) {
    var tlc = React.useContext(TabletListContext);
    React.useEffect(function () {
        tlc.setHasPaging(true);
    }, []);
    var _a = React.useState(function () {
        var arrayOfItems = [10, 20, 30, 50, 100];
        if (arrayOfItems.some(function (ps) { return ps === props.currentResultsPerPage; }) === false) {
            arrayOfItems.push(props.currentResultsPerPage);
        }
        return arrayOfItems;
    }), pageSizes = _a[0], setPageSizes = _a[1];
    React.useEffect(function () {
        if (props.currentResultsPerPage !== null) {
        }
    }, [props.currentResultsPerPage]);
    var renderPageNumber = function (math) {
        var pageNumber = props.currentPage + math;
        var isUnderTotal = pageNumber < Math.ceil(Number(props.totalResults) / props.currentResultsPerPage) + 1;
        //console.log("Total Results", "Page Number > " + pageNumber, isUnderTotal);
        if (pageNumber > 0 && isUnderTotal) {
            return (React.createElement("li", { onClick: function () {
                    props.setCurrentPage(pageNumber);
                } },
                React.createElement("a", { href: "#" }, pageNumber)));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    var isLastPage = function () {
        //console.log("Last Page Check", (Math.ceil((Number(props.totalResults) / props.currentResultsPerPage))))
        return props.currentPage === Math.ceil(Number(props.totalResults) / props.currentResultsPerPage);
    };
    if (props.totalResults !== undefined && props.totalResults > 0) {
        return (React.createElement("div", { className: "paging" },
            React.createElement("div", { className: "kt-pagination  kt-pagination--brand" },
                React.createElement("ul", { className: "kt-pagination__links" },
                    props.currentPage > 2 ? (React.createElement("li", { className: "kt-pagination__link--first", onClick: function () {
                            props.setCurrentPage(1);
                        } },
                        React.createElement("a", { href: "#" },
                            React.createElement("i", { className: "fa fa-angle-double-left kt-font-brand" })))) : (React.createElement("li", { className: "kt-pagination__link--first", style: { opacity: "0.3" } },
                        React.createElement("a", { href: "#" },
                            React.createElement("i", { className: "fa fa-angle-double-left kt-font-brand" })))),
                    props.currentPage > 1 ? (React.createElement("li", { className: "kt-pagination__link--next", onClick: function () {
                            props.setCurrentPage(props.currentPage - 1);
                        } },
                        React.createElement("a", { href: "#" },
                            React.createElement("i", { className: "fa fa-angle-left kt-font-brand" })))) : (React.createElement("li", { className: "kt-pagination__link--next", style: { opacity: "0.3" } },
                        React.createElement("a", { href: "#" },
                            React.createElement("i", { className: "fa fa-angle-left kt-font-brand" })))),
                    renderPageNumber(-2),
                    renderPageNumber(-1),
                    React.createElement("li", { className: "kt-pagination__link--active" },
                        React.createElement("a", { href: "#" }, props.currentPage)),
                    renderPageNumber(1),
                    renderPageNumber(2),
                    isLastPage() !== true ? (React.createElement("li", { className: "kt-pagination__link--prev", onClick: function () {
                            props.setCurrentPage(props.currentPage + 1);
                        } },
                        React.createElement("a", { href: "#" },
                            React.createElement("i", { className: "fa fa-angle-right kt-font-brand" })))) : null),
                React.createElement("div", { className: "kt-pagination__toolbar" },
                    React.createElement("select", { className: "form-control kt-font-brand", value: props.currentResultsPerPage, style: { width: "62px" }, onChange: function (event) {
                            props.setCurrentResultsPerPage(Number(event.currentTarget.value));
                            props.setCurrentPage(1);
                        } }, pageSizes.map(function (size, index) { return (React.createElement("option", { value: size, key: "pagination-tb-" + index, selected: props.currentResultsPerPage === size }, size)); })),
                    React.createElement("span", { className: "pagination__desc" },
                        React.createElement(FormattedMessage, { id: "component.table_list.results_per_page", defaultMessage: "Results per page" }))))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
SearchPaging.displayName = "SearchPaging";
export var HeaderItem = React.memo(function (props) {
    var tlc = React.useContext(TabletListContext);
    return (React.createElement("th", { className: (props.type && props.type === "centered" ? "text-center" : "") + (props.className !== undefined ? " " + props.className : "") },
        React.createElement("div", { className: "direction", onClick: function () {
                if (props.ReloadData !== undefined)
                    props.ReloadData(props.DataProperyName);
            } },
            props.children,
            props.icon && React.createElement(FontAwesomeIcon, { icon: props.icon, className: "icon-grey" }),
            props.helpText && React.createElement(InfoToolTip, null, props.helpText),
            props.sortable && props.sortable === true ? (React.createElement("div", { className: "sort" + (props.DataProperyName === tlc.rootProps.sortBy ? " current-sort" : "") },
                React.createElement("div", { className: "asc" + (tlc.rootProps.sortOrder === "ascending" ? " current-direction" : "") },
                    React.createElement("i", { className: "fas fa-caret-up" })),
                React.createElement("div", { className: "desc" + (tlc.rootProps.sortOrder === "descending" ? " current-direction" : "") },
                    React.createElement("i", { className: "fas fa-caret-down" })))) : null)));
});
HeaderItem.displayName = "HeaderItem";
export var ContentItem = React.memo(function (props) {
    //console.log(props.listItem);
    var tlc = React.useContext(TabletListContext);
    // Had to add this incase properties are nested.
    var currentTDValue;
    var currentTDSubValue = null;
    if (props.listDataProperyName.indexOf(".") < 0) {
        currentTDValue = props.listItem[props.listDataProperyName];
    }
    else {
        try {
            currentTDValue = props.listDataProperyName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
        }
        catch (_a) { }
    }
    if (props.listDataSubProperyName !== undefined) {
        if (props.listDataSubProperyName.indexOf(".") < 0) {
            currentTDSubValue = props.listItem[props.listDataSubProperyName];
        }
        else {
            try {
                currentTDSubValue = props.listDataSubProperyName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
            }
            catch (_b) { }
        }
    }
    var navLinkUrl = "";
    if (props.link !== undefined) {
        navLinkUrl = props.link;
        var replaceNameLink_1 = function () {
            var propertyNameToReplace = navLinkUrl.substring(navLinkUrl.lastIndexOf("{") + 1, navLinkUrl.lastIndexOf("}"));
            var propertyToReplaceValue;
            if (propertyNameToReplace.indexOf(".") < 0) {
                propertyToReplaceValue = props.listItem[propertyNameToReplace];
            }
            else {
                propertyToReplaceValue = propertyNameToReplace.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
            }
            navLinkUrl = navLinkUrl.replace("{" + propertyNameToReplace + "}", propertyToReplaceValue);
            if (navLinkUrl.lastIndexOf("{") > -1) {
                replaceNameLink_1();
            }
        };
        replaceNameLink_1();
    }
    if (typeof currentTDValue === "string") {
        currentTDValue = currentTDValue.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }
    if (currentTDValue === undefined || currentTDValue === null) {
        currentTDValue = "";
    }
    if (props.type === "icon-only") {
        return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: "text-center" + tlc.getResponsiveClassNames(props), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
            React.createElement(React.Fragment, null,
                tlc.getResponsiveTitle(props.theIndex),
                currentTDValue ? React.createElement(FontAwesomeIcon, { icon: currentTDValue }) : React.createElement(React.Fragment, null))))));
    }
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: (props.type && props.type === "strong" ? " td-bold" : "") + (props.type && props.type === "centered" ? " text-center" : "") + tlc.getResponsiveClassNames(props), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            props.listDataProperyName.indexOf("DisplayName") > -1 ||
                props.listDataProperyName.indexOf("Name") > -1 ||
                props.listDataProperyName.indexOf("Title") > -1 ||
                props.listDataProperyName.indexOf("Description") > -1 ? (navLinkUrl !== "" ? (React.createElement(NavLink, { className: "title-link", "data-pw": "title-link", exact: true, to: navLinkUrl },
                React.createElement(React.Fragment, null,
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: highlightWords(tlc.searchTerm, currentTDValue) } }),
                    currentTDSubValue !== null && currentTDSubValue !== undefined && React.createElement("div", { className: "sub-value" }, currentTDSubValue)))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { dangerouslySetInnerHTML: { __html: highlightWords(tlc.searchTerm, currentTDValue) } }),
                currentTDSubValue !== null && currentTDSubValue !== undefined && React.createElement("div", { className: "sub-value" }, currentTDSubValue)))) : navLinkUrl !== "" ? (React.createElement(NavLink, { className: "title-link", exact: true, to: navLinkUrl },
                React.createElement(React.Fragment, null,
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: currentTDValue } }),
                    currentTDSubValue !== null && currentTDSubValue !== undefined && React.createElement("div", { className: "sub-value" }, currentTDSubValue)))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { dangerouslySetInnerHTML: { __html: currentTDValue } }),
                currentTDSubValue !== null && currentTDSubValue !== undefined && React.createElement("div", { className: "sub-value" }, currentTDSubValue))))))));
});
ContentItem.displayName = "ContentItem";
export var ContentItemIconText = React.memo(function (props) {
    //console.log(props.listItem);
    var tlc = React.useContext(TabletListContext);
    // Had to add this incase properties are nested.
    var currentTDTitleValue;
    if (props.listDataProperyTitleName.indexOf(".") < 0) {
        currentTDTitleValue = props.listItem[props.listDataProperyTitleName];
    }
    else {
        currentTDTitleValue = props.listDataProperyTitleName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
    }
    var currentTDIconValue;
    if (props.listDataProperyIconName.indexOf(".") < 0) {
        currentTDIconValue = props.listItem[props.listDataProperyIconName];
    }
    else {
        currentTDIconValue = props.listDataProperyIconName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
    }
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: "td-icon-text" + (props.type && props.type === "strong" ? " td-bold" : "") + (props.type && props.type === "centered" ? " text-center" : "") + tlc.getResponsiveClassNames(props), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
        tlc.getResponsiveTitle(props.theIndex),
        currentTDIconValue ? React.createElement(FontAwesomeIcon, { icon: currentTDIconValue }) : React.createElement(React.Fragment, null),
        currentTDTitleValue))));
});
ContentItemIconText.displayName = "ContentItem";
export var ContentItemLineAwesomeIcon = React.memo(function (props) {
    //console.log(props.listItem);
    var tlc = React.useContext(TabletListContext);
    var currentTDIconValue;
    if (props.listDataProperyName.indexOf(".") < 0) {
        currentTDIconValue = props.listItem[props.listDataProperyName];
    }
    else {
        currentTDIconValue = props.listDataProperyName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
    }
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: "td-la-icon" + (props.type && props.type === "centered" ? " text-center" : "") + tlc.getResponsiveClassNames(props), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            currentTDIconValue ? React.createElement(LineAwesomeIcon, { iconString: currentTDIconValue }) : React.createElement(React.Fragment, null))))));
});
ContentItemLineAwesomeIcon.displayName = "ContentItem";
export var ContentItemBooleanIcon = React.memo(function (props) {
    //console.log(props.listItem);
    var tlc = React.useContext(TabletListContext);
    var currentBooleanValue;
    if (props.listDataProperyName.indexOf(".") < 0) {
        currentBooleanValue = props.listItem[props.listDataProperyName];
    }
    else {
        currentBooleanValue = props.listDataProperyName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
    }
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: "td-bool-icon text-center " + tlc.getResponsiveClassNames(props), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            currentBooleanValue === null ? (React.createElement(React.Fragment, null)) : currentBooleanValue === true ? (React.createElement("div", { className: "icon-is-true", title: props.listDataProperyName },
                React.createElement("i", { className: props.trueIcon !== null && props.trueIcon !== undefined ? props.trueIcon : "far fa-check-circle" }))) : (React.createElement("div", { className: "icon-is-false", title: props.listDataProperyName },
                React.createElement("i", { className: props.falseIcon !== null && props.falseIcon !== undefined ? props.falseIcon : "far fa-circle" }))))))));
});
ContentItemBooleanIcon.displayName = "ContentItem";
export var ContentItemAssociatedTemplates = React.memo(function (props) {
    var _a;
    var tlc = React.useContext(TabletListContext);
    return (React.createElement(React.Fragment, null, props.listItem && props.onAssociatedAdd !== undefined && props.onAssociatedRemove !== undefined && (React.createElement("td", { className: "text-center" + tlc.getResponsiveClassNames(props) },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            React.createElement(DropdownAssociated, { buttonText: String((_a = props.listItem.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.length), associatedTemplates: props.listItem.AssociatedTemplates ? props.listItem.AssociatedTemplates : [], onAssociatedAdd: function (tba) {
                    props.onAssociatedAdd && props.onAssociatedAdd(props.listItem, tba);
                }, onAssociatedRemove: function (tba) {
                    props.onAssociatedRemove && props.onAssociatedRemove(props.listItem, tba);
                }, libraryMode: props.libraryMode }))))));
});
ContentItemAssociatedTemplates.displayName = "ContentItem";
export var ContentItemActions = React.memo(function (props) {
    var tlc = React.useContext(TabletListContext);
    var navLinkUrl = props.editNavLinkTo !== undefined ? props.editNavLinkTo : "";
    var _a = React.useState(""), deleteCheckItemNameProperty = _a[0], setDeleteCheckItemNameProperty = _a[1];
    var replaceNameLink = function () {
        var propertyNameToReplace = navLinkUrl.substring(navLinkUrl.lastIndexOf("{") + 1, navLinkUrl.lastIndexOf("}"));
        var propertyToReplaceValue;
        if (propertyNameToReplace.indexOf(".") < 0) {
            propertyToReplaceValue = props.listItem[propertyNameToReplace];
        }
        else {
            propertyToReplaceValue = propertyNameToReplace.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
        }
        navLinkUrl = navLinkUrl.replace("{" + propertyNameToReplace + "}", propertyToReplaceValue);
        if (navLinkUrl.lastIndexOf("{") > -1) {
            replaceNameLink();
        }
    };
    replaceNameLink();
    var getItemName = function () {
        var propertyToReplaceValue;
        if (props.deleteCheckItemNameProperty !== undefined) {
            if (props.deleteCheckItemNameProperty.indexOf(".") < 0) {
                propertyToReplaceValue = props.listItem[props.deleteCheckItemNameProperty];
            }
            else {
                propertyToReplaceValue = props.deleteCheckItemNameProperty.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
            }
        }
        setDeleteCheckItemNameProperty(propertyToReplaceValue);
    };
    React.useEffect(function () {
        getItemName();
    }, []);
    var shouldHideRemove = false;
    if (props.conditionalHideRemove !== undefined) {
        if (props.listItem[props.conditionalHideRemove] !== undefined) {
            shouldHideRemove = props.listItem[props.conditionalHideRemove];
        }
    }
    var shouldShowEditAsView = false;
    if (props.conditionalEditAsView !== undefined) {
        if (props.listItem[props.conditionalEditAsView] !== undefined) {
            shouldShowEditAsView = props.listItem[props.conditionalEditAsView];
        }
    }
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: "td-action" },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            props.onCustomAction !== null && props.onCustomAction !== undefined ? (React.createElement("div", { className: "btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () { return (props.onCustomAction !== undefined ? props.onCustomAction(props.listItem) : null); } }, props.customActionIcon !== null && props.customActionIcon !== undefined ? React.createElement("i", { className: props.customActionIcon }) : null)) : null,
            props.editNavLinkTo && (React.createElement(NavLink, { exact: true, to: navLinkUrl },
                React.createElement("div", { className: "edit btn btn-sm btn-clean btn-icon btn-icon-sm" }, shouldShowEditAsView === false ? React.createElement(FontAwesomeIcon, { icon: Icons.faPen }) : React.createElement(FontAwesomeIcon, { icon: Icons.faEye })))),
            props.onEdit !== undefined && (props.hideEdit === null || props.hideEdit === undefined || props.hideEdit(props.listItem)) && (React.createElement("div", { className: "edit btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () { return (props.onEdit !== undefined ? props.onEdit(props.listItem) : null); } },
                React.createElement(FontAwesomeIcon, { icon: Icons.faPen }))),
            props.onSave !== undefined && (props.hideSave === null || props.hideSave === undefined || props.hideSave(props.listItem)) && (React.createElement("div", { className: "edit btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () { return (props.onSave !== undefined ? props.onSave(props.listItem) : null); } },
                React.createElement(FontAwesomeIcon, { icon: Icons.faSave }))),
            props.onRemove !== undefined && shouldHideRemove === false ? (React.createElement(FormDeleteConfirmation, { ItemName: deleteCheckItemNameProperty, OnProceed: function () {
                    props.onRemove && props.onRemove(props.listItem);
                }, DeleteCheckItemGuid: props.listItem.GUID, DeleteCheckType: props.deleteCheckType },
                React.createElement("div", { className: "delete btn btn-sm btn-clean btn-icon btn-icon-sm", "data-pw": "delete-button" },
                    React.createElement("i", { className: "fa-regular fa-trash-can-xmark" })))) : null)))));
});
ContentItemActions.displayName = "ContentItem";
export var ContentItemCustomRender = function (props) {
    var tlc = React.useContext(TabletListContext);
    var _a = React.useState(0), stateRederCount = _a[0], setStateRenderCount = _a[1];
    React.useEffect(function () {
        setStateRenderCount(stateRederCount + 1);
    }, [props.listItem]);
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: (props.className !== null && props.className !== undefined ? props.className + " " : "") + tlc.getResponsiveClassNames(props) + (" rc-" + stateRederCount), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
        tlc.getResponsiveTitle(props.theIndex),
        props.render(props.listItem)))));
};
ContentItemCustomRender.displayName = "ContentItem";
export var ContentItemToggle = React.memo(function (props) {
    var tlc = React.useContext(TabletListContext);
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: (props.alignment !== undefined && props.alignment === "left" ? "" : "text-center") + tlc.getResponsiveClassNames(props) + (props.className !== undefined ? " " + props.className : "") },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            React.createElement(FormToggle, { checked: props.listItem[props.listDataProperyName], handleChange: function (checked) { return props.handleChange(props.listItem, checked); } }))))));
});
ContentItemToggle.displayName = "ContentItem";
export var ContentItemDate = React.memo(function (props) {
    var tlc = React.useContext(TabletListContext);
    var value;
    if (props.listDataProperyName.indexOf(".") < 0) {
        value = props.listItem[props.listDataProperyName];
    }
    else {
        value = props.listDataProperyName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
    }
    var datetime = moment.utc(value).local();
    var isInvalid = datetime.isValid;
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: " " + tlc.getResponsiveClassNames(props) + (props.type && props.type === "strong" ? " td-bold" : "") + (props.type && props.type === "centered" ? " text-center" : "") },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            datetime.isValid() ? props.datetimeFormat !== undefined ? React.createElement("span", null, datetime.format(props.datetimeFormat)) : React.createElement("span", null, datetime.format("LLL")) : React.createElement("div", null, "---"))))));
});
ContentItemDate.displayName = "ContentItem";
export var ContentItemDocumentFileType = React.memo(function (props) {
    var tlc = React.useContext(TabletListContext);
    var currentType;
    if (props.listDataProperyName.indexOf(".") < 0) {
        currentType = props.listItem[props.listDataProperyName];
    }
    else {
        currentType = props.listDataProperyName.split(".").reduce(function (o, i) { return o[i]; }, props.listItem);
    }
    return (React.createElement(React.Fragment, null, props.listItem && (React.createElement("td", { className: "text-center td-doc-icon" + tlc.getResponsiveClassNames(props), onClick: function (event) { return tlc.responsiveTitleClick(props, event); } },
        React.createElement(React.Fragment, null,
            tlc.getResponsiveTitle(props.theIndex),
            React.createElement(DocumentIcon, { docType: currentType }))))));
});
ContentItemDocumentFileType.displayName = "ContentItem";
