import React from "react";
var DevHangfire = React.memo(function () {
    React.useEffect(function () {
        // we used fixed positioning in the mobile request form.
        document.body.classList.add("diable-transform-to-allow-fixed-positioning");
    }, []);
    //let iframeSrc = "https://localhost:44316/hangfire";
    //if(window.location.href.indexOf("3000") === -1){
    var iframeSrc = "/hangfire";
    //}
    return (React.createElement("div", null,
        React.createElement("style", { dangerouslySetInnerHTML: {
                __html: "\n        .hangfire-iframe {\n            position: fixed;\n            top: 66px;\n            left: 265px;\n            height: calc(100% - 66px);\n            width: calc(100% - 265px);\n            /* right: 0; */\n            /* bottom: 0; */\n            z-index: 9999;\n            border: 0px solid transparent;\n        }\n        "
            } }),
        React.createElement("iframe", { className: "hangfire-iframe", src: iframeSrc })));
});
export default DevHangfire;
