import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { AppType } from "app/services/permission-info";
import { OrchestryPermissionInfoEditorTableNoResults } from "./orchestry-permission-info-editor-table-no-results";
import "../orchestry-permission-info-editor.scss";
import { getReadableDateTime } from "app/utils/dateUtils";
export var OrchestryPermissionInfoEditorTable = function (_a) {
    var onTableRowClicked = _a.onTableRowClicked, permissionInfoItems = _a.permissionInfoItems, selectedAppType = _a.selectedAppType;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement(Row, null,
                React.createElement(Col, { md: 12 }, permissionInfoItems.length < 1 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32, bodyWidth: 237 })) : (React.createElement(React.Fragment, null,
                    React.createElement(Row, { className: "value-titles" },
                        React.createElement(Col, null, "Name"),
                        React.createElement(Col, null, "Updated Date"),
                        selectedAppType === AppType.ServiceApp && React.createElement(Col, null, "Service App Permission Id"),
                        React.createElement(Col, null, "Permission Type"),
                        React.createElement(Col, null, "Description"),
                        React.createElement(Col, null, "Grouping"),
                        React.createElement(Col, null, "Orchestry Details")),
                    permissionInfoItems
                        .filter(function (permissionInfoItem) { return permissionInfoItem.AppType === selectedAppType; })
                        .map(function (permissionInfoItem) { return (React.createElement(Row, { key: permissionInfoItem.id, className: "value-edit", onClick: function () { return onTableRowClicked(permissionInfoItem); } },
                        React.createElement(Col, null, permissionInfoItem.Name),
                        React.createElement(Col, null, getReadableDateTime(new Date(permissionInfoItem.UpdatedDate))),
                        selectedAppType === AppType.ServiceApp && React.createElement(Col, null, permissionInfoItem.ServiceAppPermissionID),
                        React.createElement(Col, null, permissionInfoItem.PermissionType),
                        React.createElement(Col, null, permissionInfoItem.Description),
                        React.createElement(Col, null, permissionInfoItem.Grouping),
                        React.createElement(Col, null, permissionInfoItem.OrchestryDetails))); }),
                    React.createElement(OrchestryPermissionInfoEditorTableNoResults, { permissionInfoItems: permissionInfoItems }))))))));
};
