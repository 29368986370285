import * as React from "react";
import "./disable-area.scss";
var DisableArea = React.memo(function (props) {
    var isDisabled = function () {
        var is = false;
        if (props.isDisabled !== undefined && props.isDisabled === true) {
            is = true;
        }
        if (props.isEnabled !== undefined && props.isEnabled === false) {
            is = true;
        }
        return is;
    };
    var getStyle = function () {
        if (props.zIndex !== undefined) {
            return { zIndex: props.zIndex };
        }
        return {};
    };
    return (React.createElement("div", { className: "disable-area" +
            (isDisabled() ? " is-disabled" : "") +
            (props.useOverlay !== undefined && props.useOverlay ? " use-overlay" : "") +
            (props.cursorType !== undefined && props.cursorType !== null ? " cursor-" + props.cursorType : ""), style: getStyle() },
        props.disabledText !== undefined && isDisabled() ? React.createElement("span", { className: "disable-text" }, props.disabledText) : null,
        props.children));
});
export default DisableArea;
