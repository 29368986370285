import * as AsyncStore from "app/store/async";
import * as ChannelListStore from "app/store/channels/list";
export var channelNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to channels list...",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Title}</strong> in channels list...",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Title}</strong> from channels list...",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to channels list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully updated in channels list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully deleted from channels list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to channels list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Title}</strong> to channels list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Title}</strong> from channels list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete
            },
            {
                Text: "Error when trying to load channel list.",
                ActionType: ChannelListStore.ChannelListActionTypes.Channel_List_Load
            }
        ]
    }
];
