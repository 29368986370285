import { Group, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryTooltip } from "../orchestry-tooltip/orchestry-tooltip.component";
export var OrchestryDataTableToolTipHeader = function (_a) {
    var label = _a.label, tooltipLabel = _a.tooltipLabel;
    return (React.createElement(Group, { gap: "4px" },
        React.createElement(Text, { c: "text-primary.0", size: "12px", fw: 500, lh: "18px" }, label),
        React.createElement(OrchestryTooltip, { arrowSize: 15, multiline: true, w: 300, flex: 1, label: tooltipLabel },
            React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.helpCircle, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-gray-5)" }))));
};
