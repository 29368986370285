import * as React from "react";
import "./tab-security.scss";
import { Form, Row, Col } from "react-bootstrap";
import InfoToolTip from "app/components/common/infotooltip";
import { useDispatch } from "react-redux";
import * as TemplateCurrentStore from "app/store/templates/current";
import { Collapse } from "react-bootstrap";
import { FormValidator } from "app/components/common/form-validation";
import DisableArea from "app/components/common/disable-area";
import PortletAlert from "app/components/common/portlet-alert";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
var TabSecurity = React.memo(function (props, state) {
    var dispatch = useDispatch();
    var radioTemplatePermissionOnChange = function (event) {
        if (event.currentTarget.value === "LimitTemplateToSpecificActiveDirectoryGroups") {
            dispatch(TemplateCurrentStore.Update({
                LimitTemplateToSpecificActiveDirectoryGroups: true,
                AllowAllUsersToRequestTemplate: false
            }));
        }
        else {
            dispatch(TemplateCurrentStore.Update({
                LimitTemplateToSpecificActiveDirectoryGroups: false,
                AllowAllUsersToRequestTemplate: true
            }));
        }
    };
    return (React.createElement("div", { className: "template-tab-security", "data-pw": "security-tab-content" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h2", null, "Template Permissions"))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(Form.Group, { controlId: "formBasicCheckbox1", className: "form-group radio-green", "data-pw": "form-group-radio" },
                    React.createElement(Form.Check, { type: "radio", name: "access", id: "lallow-all-users", value: "AllowAllUsersToRequestTemplate", defaultChecked: props.currentTemplate.AllowAllUsersToRequestTemplate, onChange: radioTemplatePermissionOnChange, label: React.createElement(React.Fragment, null,
                            "Allow all users to request template",
                            React.createElement(InfoToolTip, null, "By default all users will be able to see and request this Workspace")) }),
                    React.createElement(Form.Check, { type: "radio", name: "access", id: "limit-to-active-direction", defaultChecked: props.currentTemplate.LimitTemplateToSpecificActiveDirectoryGroups, onChange: radioTemplatePermissionOnChange, value: "LimitTemplateToSpecificActiveDirectoryGroups", label: React.createElement(React.Fragment, null,
                            "Limit template to specific Azure Active Directory Group",
                            "(s)",
                            React.createElement(InfoToolTip, null,
                                "This option will limit the templates visible to the specified Azure Active Directory Group",
                                "(s)")) })),
                React.createElement(Collapse, { in: props.currentTemplate.LimitTemplateToSpecificActiveDirectoryGroups, appear: true },
                    React.createElement("div", { className: "form-group-input" },
                        React.createElement(FormUserGroupSelect, { defaultValue: props.currentTemplate.TemplateActiveDirectoryGroup, allowMultiple: true, returnType: "groups", label: "Template Active Directory Group(s):", onChange: function (groups) {
                                dispatch(TemplateCurrentStore.Update({ TemplateActiveDirectoryGroup: groups }));
                            } }),
                        props.currentTemplate.LimitTemplateToSpecificActiveDirectoryGroups === true ? (React.createElement(FormValidator, { UniqueIdentifier: "template-active-directory-group", Validators: ["string-is-more-than-3"], ShowAsInfo: true, ShowFirst: true, ErrorMessage: "Please select at least one group", Value: props.currentTemplate.TemplateActiveDirectoryGroup })) : null)))),
        props.currentTemplate.TemplateType !== "SharePoint" && props.currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(React.Fragment, null,
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("h2", null, "Teams Group Permissions"))),
            props.currentTemplate.LiveTemplateEnabled === true && props.currentTemplate.LiveTemplateTeamCloneSecuritySettings === true ? (React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", message: "Teams permissions are disabled because this template is currently configured to clone security settings from a Live Team. To enable these options please navigate to the Live Template Tab and disable the 'Clone Security Settings' option" })) : null,
            React.createElement(DisableArea, { isDisabled: props.currentTemplate.LiveTemplateEnabled === true && props.currentTemplate.LiveTemplateTeamCloneSecuritySettings === true },
                React.createElement(Row, { "data-pw": "group-permissions" },
                    React.createElement(Col, { lg: 6 },
                        React.createElement("h3", null, "Teams Member Permissions"),
                        React.createElement("div", null,
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox6", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow members to create and update channels",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowMemberCreatingAndUpdatingChannels, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowMemberCreatingAndUpdatingChannels: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox21", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow members to create private channels",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowMemberCreatePrivateChannels, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowMemberCreatePrivateChannels: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox7", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow members to delete and restore channels",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowMembersToDeleteAndRestoreChannels, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowMembersToDeleteAndRestoreChannels: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox8", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow members to add and remove apps",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowMembersToAddAndRemoveApps, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowMembersToAddAndRemoveApps: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox10", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow members to create, update, and remove tabs",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowMembersToCreateUpdateAndRemoveTabs, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowMembersToCreateUpdateAndRemoveTabs: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox11", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow members to create, update, and remove connectors",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowMembersToCreateUpdateAndRemoveConnectors, onChange: function (event) {
                                        return dispatch(TemplateCurrentStore.Update({ AllowMembersToCreateUpdateAndRemoveConnectors: event.target.checked }));
                                    } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox12", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Give members the option to delete their messages",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.GiveMembersTheOptionToDeleteTheirMessages, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ GiveMembersTheOptionToDeleteTheirMessages: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckboxEdit", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Give members the option to edit their messages",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.GiveMembersTheOptionToEditTheirMessages, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ GiveMembersTheOptionToEditTheirMessages: event.target.checked })); } })))),
                    React.createElement(Col, { lg: 6, className: "tg-right" },
                        React.createElement("h3", null, "Teams Guest Permissions"),
                        React.createElement("div", null,
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox15", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow creating and updating channels",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowGuestCreatingAndUpdatingChannels, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowGuestCreatingAndUpdatingChannels: event.target.checked })); } })),
                            React.createElement(Form.Group, { controlId: "formBasicCheckbox16", className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                        "Allow guests to delete channels",
                                        React.createElement(InfoToolTip, null)), defaultChecked: props.currentTemplate.AllowGuestsToDeleteChannels, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ AllowGuestsToDeleteChannels: event.target.checked })); } })))))))) : null));
});
export default TabSecurity;
