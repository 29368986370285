var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./step-channels.scss";
import InfoTooltip from "app/components/common/infotooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form } from "react-bootstrap";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import getStringTwoLetterAcronym from "app/utils/getStringTwoLetterAcronym";
import generateGuid from "app/utils/generateGuid";
import TeamsService from "app/services/teams";
import AnimatedLoader from "app/components/common/animated-loader";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import { useIntl, FormattedMessage } from "react-intl";
var StepChannels = React.memo(function (props) {
    var intl = useIntl();
    var rwc = React.useContext(RequestWizardContext);
    var _a = React.useState(""), newChannelName = _a[0], setNewChannelName = _a[1];
    var _b = React.useState(false), isNewChannelFormValid = _b[0], setIsNewChannelFormValid = _b[1];
    var _c = React.useState(false), isClone = _c[0], setIsClone = _c[1];
    var _d = React.useState(false), isCloneLoading = _d[0], setIsCloneLoading = _d[1];
    var _e = React.useState(false), showSuggestions = _e[0], setShowSuggestions = _e[1];
    // on first run, set the state to can proceed.
    React.useEffect(function () {
        if (props.step.canProgress === false) {
            rwc.setStepProgress(props.step.key, true);
        }
        setShowSuggestions(getTemplateChannels(false).length > 0 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        var _a;
        if (rwc.currentRequest.RequestType === "Team") {
            if ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateEnabled) {
                if (rwc.currentRequest.Template.LiveTemplateTeamID !== undefined && rwc.currentRequest.Template.LiveTemplateTeamID !== null) {
                    var teamObject = JSON.parse(rwc.currentRequest.Template.LiveTemplateTeamID);
                    if (teamObject !== null && teamObject !== undefined && teamObject.GUID !== null && teamObject.GUID !== undefined) {
                        setIsClone(true);
                        setIsCloneLoading(true);
                        TeamsService.GetTeamChannels(teamObject.GUID).then(function (channels) {
                            //console.log("Channels", channels);
                            rwc.setCloneTeamChannels(channels);
                            setIsCloneLoading(false);
                        });
                    }
                }
            }
        }
    }, []);
    React.useEffect(function () {
        if (props.step.key === rwc.getCurrentStep().key) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        //console.log("cloneTeamChannels", rwc.cloneTeamChannels);
    }, [rwc.cloneTeamChannels]);
    var getTemplateChannels = function (isRequired) {
        var returnArray = [];
        if (rwc.currentRequest.Template !== null && rwc.currentRequest.Template !== undefined) {
            if (rwc.currentRequest.Template.AssociatedChannels !== null && rwc.currentRequest.Template.AssociatedChannels !== undefined) {
                rwc.currentRequest.Template.AssociatedChannels.forEach(function (chan) {
                    if (chan.Required === isRequired) {
                        if (chan.Item !== null && chan.Item !== undefined) {
                            returnArray.push(chan.Item);
                        }
                    }
                });
            }
        }
        return returnArray;
    };
    var getSelectedChannels = function (isLocked) {
        //const requiredTemplateGuids = getTemplateChannels(isLocked).map(chan => chan.GUID);
        var _a;
        var selectedArray = (_a = rwc.currentRequest.RequestChannels) === null || _a === void 0 ? void 0 : _a.filter(function (rc) {
            return isLocked === !rc.IsNew;
            //return requiredTemplateGuids.some(guid => guid === rc.GUID) ? true : false;
        });
        if (selectedArray !== null && selectedArray !== undefined) {
            return selectedArray;
        }
        else {
            return [];
        }
    };
    var getSuggestedChannels = function () {
        var selectedChannels = getSelectedChannels(false);
        //console.log("getSuggestedChannels", selectedChannels);
        var suggestedArray = getTemplateChannels(false).filter(function (chan) {
            return selectedChannels.some(function (selChan) { return (selChan === null || selChan === void 0 ? void 0 : selChan.GUID) === (chan === null || chan === void 0 ? void 0 : chan.GUID); }) ? false : true;
        });
        suggestedArray = suggestedArray.filter(function (si) {
            var _a;
            var suggestionExists = !((_a = rwc.currentRequest.RequestChannels) === null || _a === void 0 ? void 0 : _a.some(function (sc) { return sc.Title === si.Title; }));
            return suggestionExists;
        });
        if (suggestedArray !== null && suggestedArray !== undefined) {
            return suggestedArray;
        }
        else {
            return [];
        }
    };
    var addSelectedChannel = function (channelName, existingChannel) {
        var newRequestChannel = {
            GUID: generateGuid(),
            Title: existingChannel ? existingChannel.Title : channelName,
            Description: existingChannel ? existingChannel.Description : "",
            Privacy: existingChannel ? existingChannel.Privacy : "",
            IsNew: true
        };
        var tempArray = [newRequestChannel];
        if (rwc.currentRequest.RequestChannels !== null && rwc.currentRequest.RequestChannels !== undefined) {
            if (rwc.currentRequest.RequestChannels.length > 0) {
                tempArray = __spreadArray(__spreadArray([], rwc.currentRequest.RequestChannels, true), tempArray, true);
            }
        }
        rwc.updateCurrentRequest({ RequestChannels: tempArray });
    };
    var removeSelectedChannel = function (chanToRemove) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.RequestChannels) === null || _a === void 0 ? void 0 : _a.filter(function (rc) { return rc.GUID !== (chanToRemove === null || chanToRemove === void 0 ? void 0 : chanToRemove.GUID); });
        if (tempArray === null || tempArray === undefined) {
            tempArray = [];
        }
        rwc.updateCurrentRequest({ RequestChannels: __spreadArray([], tempArray, true) });
    };
    var isNewChannelValid = function () {
        var isValid = true;
        if (newChannelName.length === 0) {
            isValid = false;
        }
        if (!isNewChannelFormValid) {
            isValid = false;
        }
        if (rwc.cloneTeamChannels !== null && rwc.cloneTeamChannels !== undefined) {
            if (rwc.cloneTeamChannels.length > 0) {
                rwc.cloneTeamChannels.forEach(function (chan) {
                    var _a;
                    if (chan.displayName !== null && chan.displayName !== undefined) {
                        if (((_a = chan.displayName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === newChannelName.toLowerCase().trim()) {
                            isValid = false;
                        }
                    }
                });
            }
        }
        if (rwc.currentRequest.Template !== null && rwc.currentRequest.Template !== undefined) {
            if (rwc.currentRequest.Template.AssociatedChannels !== null && rwc.currentRequest.Template.AssociatedChannels !== undefined) {
                rwc.currentRequest.Template.AssociatedChannels.forEach(function (chan) {
                    var _a;
                    if (chan.Item !== null && chan.Item !== undefined) {
                        if (((_a = chan.Item.Title) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === newChannelName.toLowerCase().trim()) {
                            isValid = false;
                        }
                    }
                });
            }
        }
        if (rwc.currentRequest !== null && rwc.currentRequest !== undefined) {
            if (rwc.currentRequest.RequestChannels !== null && rwc.currentRequest.RequestChannels !== undefined) {
                rwc.currentRequest.RequestChannels.forEach(function (chan) {
                    var _a;
                    if (chan !== null && chan !== undefined) {
                        if (((_a = chan.Title) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === newChannelName.toLowerCase().trim()) {
                            isValid = false;
                        }
                    }
                });
            }
        }
        return isValid;
    };
    var addNewChannel = function () {
        if (isNewChannelValid()) {
            addSelectedChannel(newChannelName);
            setNewChannelName("");
        }
    };
    var handleKeyDownOnAddChannelInput = function (e) {
        if (e.keyCode === 13) {
            addNewChannel();
        }
    };
    return (React.createElement("div", { className: "step-channels" },
        React.createElement(Row, null,
            React.createElement(Col, { className: "left", md: 6, lg: 7 },
                React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                        setIsNewChannelFormValid(isValid);
                    }, UniqueIdentifier: "RequestWizardStepChannels" },
                    React.createElement("div", { className: "channel-name" },
                        React.createElement("h4", null,
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.create_new_channel", defaultMessage: "Create a new channel" })),
                        React.createElement("div", { className: "channel-input" + (isNewChannelValid() ? " valid" : ""), "data-pw": "channel-input" },
                            React.createElement(Form.Group, { className: "form-group", controlId: "control-group-name" },
                                React.createElement(Form.Control, { placeholder: intl.formatMessage({ id: "page.request_wizard.step_channels.enter_channel_name", defaultMessage: "Enter channel name" }), value: newChannelName, autoComplete: "off", disabled: isCloneLoading, onKeyDown: function (event) {
                                        handleKeyDownOnAddChannelInput(event);
                                    }, onChange: function (event) {
                                        setNewChannelName(event.target.value);
                                    } }),
                                React.createElement("div", { className: "submit-channel", "data-pw": "add-channel", onClick: function () { return addNewChannel(); } },
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faPlus }),
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.add_channel", defaultMessage: "Add channel" })))),
                            newChannelName !== null && newChannelName !== undefined && newChannelName.length > 0 ? (React.createElement(FormValidator, { UniqueIdentifier: "channel-name", Validators: ["string-not-empty", "string-is-more-than-1", "string-is-less-than-51", "no-illegal-characters", "does-not-end-in-full-stop"], FieldName: intl.formatMessage({ id: "page.request_wizard.step_select.filters.name", defaultMessage: "Name" }), ShowFirst: false, Value: newChannelName })) : null))),
                showSuggestions ? (React.createElement("div", { className: "channel-suggestions" },
                    React.createElement("h4", null, "Suggestions"),
                    React.createElement("div", { className: "suggestion-items" },
                        getSuggestedChannels().map(function (chan) {
                            var _a;
                            return (React.createElement(InfoTooltip, { content: chan.Description, placement: "bottom", key: chan.GUID, disabled: chan.Description === null || ((_a = chan.Description) === null || _a === void 0 ? void 0 : _a.length) === 0 },
                                React.createElement("div", { className: "suggestion", onClick: function () {
                                        addSelectedChannel(chan.Title, chan);
                                    } },
                                    chan.Title,
                                    chan.Privacy === "Private" ? (React.createElement("div", { className: "private" },
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.private", defaultMessage: "Private" }))) : null)));
                        }),
                        getSuggestedChannels().length === 0 && (React.createElement("div", { className: "no-suggestions" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.there_are_no_suggestions", defaultMessage: "There are no suggestions" })))))) : null),
            React.createElement(Col, { className: "right", md: 6, lg: 5 },
                React.createElement("div", { className: "team-channels" },
                    React.createElement("h4", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.teams_channels", defaultMessage: "Team's channels" })),
                    React.createElement("div", { className: "team-mock" },
                        React.createElement("div", { className: "left-mock-bar" }),
                        React.createElement("div", { className: "top-mock-bar" },
                            React.createElement("div", { className: "team-title" },
                                rwc.currentRequest.Image !== undefined && rwc.currentRequest.Image !== null && rwc.currentRequest.Image !== "" ? (React.createElement("div", { className: "icon with-image" },
                                    React.createElement("div", { className: "icon-image", style: { backgroundImage: "url('" + rwc.currentRequest.Image + "')" } }))) : (React.createElement("div", { className: "icon" }, rwc.currentRequest.Name ? getStringTwoLetterAcronym(rwc.currentRequest.Name) : "")),
                                React.createElement("div", { className: "title" }, rwc.currentRequest.Name))),
                        React.createElement("div", { className: "team-mock-list" },
                            React.createElement("ul", { className: "list-unstyled" },
                                isCloneLoading ? (React.createElement("li", { className: "channels-loading" },
                                    React.createElement(AnimatedLoader, { size: "xsmall" }),
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.loading_channels", defaultMessage: "Loading channels..." })))) : null,
                                rwc.cloneTeamChannels !== null && rwc.cloneTeamChannels !== undefined
                                    ? rwc.cloneTeamChannels.map(function (chan) { return (React.createElement("li", { className: "locked", key: chan === null || chan === void 0 ? void 0 : chan.id },
                                        React.createElement("div", { className: "channel" },
                                            React.createElement("div", { className: "title" }, chan === null || chan === void 0 ? void 0 : chan.displayName),
                                            React.createElement("div", { className: "icon with-lock" },
                                                React.createElement(InfoTooltip, { content: intl.formatMessage({
                                                        id: "page.request_wizard.step_channels.preset_channel_cannot_be_removed",
                                                        defaultMessage: "Preset channel cannot be removed"
                                                    }), placement: "top" },
                                                    React.createElement("div", { className: "lock" },
                                                        React.createElement(FontAwesomeIcon, { icon: Icons.faLock }))))))); })
                                    : null,
                                isClone === false ? (React.createElement("li", { className: "locked" },
                                    React.createElement("div", { className: "channel" },
                                        React.createElement("div", { className: "title" },
                                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_channels.general", defaultMessage: "General" })),
                                        React.createElement("div", { className: "icon with-lock" },
                                            React.createElement(InfoTooltip, { content: intl.formatMessage({ id: "page.request_wizard.step_channels.preset_channel_cannot_be_removed", defaultMessage: "Preset channel cannot be removed" }), placement: "top" },
                                                React.createElement("div", { className: "lock" },
                                                    React.createElement(FontAwesomeIcon, { icon: Icons.faLock }))))))) : null,
                                isCloneLoading !== true
                                    ? getSelectedChannels(true).map(function (chan) { return (React.createElement("li", { className: "locked", key: chan === null || chan === void 0 ? void 0 : chan.GUID },
                                        React.createElement("div", { className: "channel" },
                                            React.createElement("div", { className: "title" }, chan === null || chan === void 0 ? void 0 : chan.Title),
                                            React.createElement("div", { className: "icon with-lock" },
                                                React.createElement(InfoTooltip, { content: intl.formatMessage({
                                                        id: "page.request_wizard.step_channels.preset_channel_cannot_be_removed",
                                                        defaultMessage: "Preset channel cannot be removed"
                                                    }), placement: "top" },
                                                    React.createElement("div", { className: "lock" },
                                                        React.createElement(FontAwesomeIcon, { icon: Icons.faLock }))))))); })
                                    : null,
                                getSelectedChannels(false).map(function (chan) { return (React.createElement("li", { key: chan === null || chan === void 0 ? void 0 : chan.GUID },
                                    React.createElement("div", { className: "channel" },
                                        React.createElement("div", { className: "title" }, chan === null || chan === void 0 ? void 0 : chan.Title),
                                        React.createElement("div", { className: "icon", onClick: function () {
                                                removeSelectedChannel(chan);
                                            } },
                                            React.createElement("div", { className: "close" },
                                                React.createElement(FontAwesomeIcon, { icon: Icons.faTimes })))))); })))))))));
});
export default StepChannels;
