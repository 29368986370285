import * as React from "react";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
var TemplateType = React.memo(function (props) {
    if (props.templateType !== undefined) {
        if (props.templateType === "SharePoint") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint", className: "fadein-500ms" }))));
        }
        else if (props.templateType === "Team") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" }))));
        }
        else {
            return React.createElement("span", null, props.templateType);
        }
    }
    else {
        return React.createElement("span", null);
    }
});
export default TemplateType;
