import withIntlProvider from "appv2/components/orchestry-with-intl-provider-hoc/orchestry-with-intl-provider-hoc.component";
import React from "react";
import { AddEditCustomRecommendationModal } from "./add-edit-custom-recommendation-modal.component";
import { useAddCustomRecommendationModal } from "./use-add-custom-recommendation-modal.hook";
var AddCustomRecommendationModalContent = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, healthCheckGuid = _b.healthCheckGuid, onRecommendationSuccessfullyAdded = _b.onRecommendationSuccessfullyAdded, tenantGuid = _b.tenantGuid;
    var _c = useAddCustomRecommendationModal(context, id, onRecommendationSuccessfullyAdded, healthCheckGuid, tenantGuid), form = _c.form, appliedFilters = _c.appliedFilters, filterGroups = _c.filterGroups, updateFilterState = _c.updateFilterState, handleFormSubmit = _c.handleFormSubmit, isLoading = _c.isLoading, filterGroupErrors = _c.filterGroupErrors;
    return (React.createElement(AddEditCustomRecommendationModal, { form: form, appliedFilters: appliedFilters, filterGroups: filterGroups, updateFilterState: updateFilterState, handleFormSubmit: handleFormSubmit, isLoading: isLoading, filterGroupErrors: filterGroupErrors, context: context, id: id, innerProps: { onRecommendationSuccessfullyAdded: onRecommendationSuccessfullyAdded }, title: "Add Custom Recommendation", buttonLabel: "Add" }));
};
export var AddCustomRecommendationModal = withIntlProvider(AddCustomRecommendationModalContent);
