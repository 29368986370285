import { Container, Grid, Group, Stack } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryAnnotations } from "appv2/components/orchestry-annotation-section/orchestry-annotations.component";
import { useOrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/use-orchestry-annotation-section.hook";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { ArchivalsTile } from "./tiles/archivals-tile.component";
import { GuestsTile } from "./tiles/guests-tile.component";
import { NotificationsTile } from "./tiles/notifications-tile.component";
import { ProvisioningTile } from "./tiles/provisioning-tile.component";
import { StorageSavedTile } from "./tiles/storage-saved-tile.component";
export var OrchestryUsageSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, isMobile = _a.isMobile;
    var shouldRenderListOfAnnotations = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.OrchestryUsage, true, undefined).shouldRenderListOfAnnotations;
    return (React.createElement(Stack, { gap: 8 },
        React.createElement(Container, { id: "orchestry-usage-section", fluid: true, mt: "24px", w: "100%", bg: "var(--mantine-color-gray-0)", p: "24px", style: { borderRadius: "8px", border: "1px solid var(--mantine-color-primary-3)" } },
            React.createElement(OrchestryAnnotationSection, { gap: 16, annotationCategory: HealthCheckAnnotationCategory.OrchestryUsage, renderListOfAnnotations: false, sectionTitle: React.createElement(Group, { gap: "8px" },
                    React.createElement(OrchestryFeaturedIcon, { size: "sm", imgIconWidth: 16, imgIconHeight: 16, isLoading: isLoading, variant: "double-circle", colorVariant: "primary-7", imgIconSrc: ORCH_SVG_ICONS.LogoMarkColour }),
                    React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.orchestry_usage.title", defaultMessage: "Orchestry Usage" }) })) },
                React.createElement(Grid, { grow: isMobile, gutter: "16px", columns: 5 },
                    React.createElement(Grid.Col, { span: 1, miw: "202px" },
                        React.createElement(ProvisioningTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: "202px" },
                        React.createElement(ArchivalsTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: "202px" },
                        React.createElement(GuestsTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: "202px" },
                        React.createElement(StorageSavedTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: "202px" },
                        React.createElement(NotificationsTile, null))))),
        shouldRenderListOfAnnotations && React.createElement(OrchestryAnnotations, { renderListLabelWithAnnotations: true, annotationCategory: HealthCheckAnnotationCategory.OrchestryUsage })));
};
