import { Center, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { useIntl } from "react-intl";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestryNoRecordsFound = function () {
    var intl = useIntl();
    return (React.createElement(Center, { mt: 40, mb: 48 },
        React.createElement(Stack, { gap: 0, align: "center", justify: "center" },
            React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "primary-9", iconClassName: ORCH_ICONS.search }),
            React.createElement(OrchestryText, { c: "text-primary.0", fz: "16px", fw: 400, lh: "24px", mt: 12 }, intl.formatMessage({
                id: "datatable.no_records_found",
                defaultMessage: "No records found"
            })),
            React.createElement(OrchestryText, { mt: 4, c: "text-secondary.0", fz: "14px", fw: 300, lh: "20px", ta: "center" }, intl.formatMessage({
                id: "datatable.no_records_found_subtitle",
                defaultMessage: "Your search did not match any records."
            })))));
};
