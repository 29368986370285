import * as React from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import { useIntl, FormattedMessage } from "react-intl";
var RequestDetailsFeatures = React.memo(function (props) {
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var intl = useIntl();
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.features.title", defaultMessage: "Features" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "kt-widget4" }, currentRequest.AssociatedFeatures !== undefined && currentRequest.AssociatedFeatures.length !== 0 ? (currentRequest.AssociatedFeatures.map(function (ass, assFeatIndex) {
                var _a;
                return (React.createElement("div", { className: "kt-widget4__item", key: assFeatIndex },
                    React.createElement("span", { className: "kt-widget4__icon" },
                        React.createElement(LineAwesomeIcon, { iconString: ass.Item.Icon })),
                    React.createElement("span", { className: "kt-widget4__title kt-widget4__title--light" }, (_a = ass.Item) === null || _a === void 0 ? void 0 : _a.Title)));
            })) : (React.createElement("div", { className: "no-results" },
                "-- ",
                React.createElement(FormattedMessage, { id: "page.request_details.features.no_features", defaultMessage: "no features" }),
                " --"))))));
});
export default RequestDetailsFeatures;
