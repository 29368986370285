import OrchModal from "app/components/common/orch-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { ReportSearchSlotName } from "app/store/lifecycle-report";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import LifecycleReportChooseList from "../report-choose-list";
import "./report-insights-button.scss";
var ReportInsightsButton = function (props) {
    var lifecycleReportIsLoading = useSelector(function (state) { var _a; return (_a = state.lifecycleReportState[ReportSearchSlotName.InsightsButton]) === null || _a === void 0 ? void 0 : _a.IsLoading; });
    var lifecycleReportViews = useSelector(function (state) { var _a; return (_a = state.lifecycleReportState[ReportSearchSlotName.InsightsButton]) === null || _a === void 0 ? void 0 : _a.Views; });
    var currentView = props.CurrentView !== null && props.CurrentView !== undefined ? props.CurrentView : "all-guests";
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (lifecycleReportViews === undefined || lifecycleReportViews === null || lifecycleReportViews.length === 0) {
            setTimeout(function () {
                dispatch(LifecycleReportStore.LoadViews({
                    Context: {
                        View: currentView
                    }
                }, ReportSearchSlotName.InsightsButton));
            }, 500);
        }
    }, []);
    return (React.createElement("div", { className: "report-insights-button", "data-pw": "insights-button" },
        React.createElement("div", { className: "view-selector" }, lifecycleReportViews !== null && lifecycleReportViews !== undefined && lifecycleReportViews.length > 0 && currentView !== null && currentView !== undefined && currentView !== "" ? (React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null,
                React.createElement("i", { className: "la la-chalkboard" }),
                " Reports"), size: "xl", modalClassName: "insights-modals", showCloseButton: true },
            React.createElement(LifecycleReportChooseList, { isLoading: lifecycleReportIsLoading, NamePrefix: props.NamePrefix, reports: lifecycleReportViews, selectedName: props.HideBoldSelected !== undefined && props.HideBoldSelected === true ? undefined : currentView, Datasource: props.Datasource, onSelected: function (value) {
                    props.OnSelected(value);
                } }))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, bodyWidth: 150 })))));
};
export default ReportInsightsButton;
