import { Card, Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var AnonymousLinksTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.SharingLinksCreated, _g = _f === void 0 ? {} : _f, _h = _g.AnonymousLinks, _j = _h === void 0 ? {} : _h, Total = _j.Total, PreviousValue = _j.PreviousValue, DeltaValue = _j.DeltaValue, DeltaPercentage = _j.DeltaPercentage, DataCoverageDaysCount = _j.DataCoverageDaysCount, DataCoverageAvailable = _j.DataCoverageAvailable;
    return (
    // overflow visible used to show the tooltip
    React.createElement(Card, { variant: "default", h: "100%", pt: 20, pb: 20, pl: 16, pr: 16, style: { overflow: "visible" } },
        React.createElement(Group, { gap: "8px" },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "xs", variant: "light-circle", colorVariant: "gray-7", iconClassName: ORCH_ICONS.link }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "18", fw: 500, lh: "28px" }, intl.formatMessage({ id: "page.health_check.anonymous_links_tile.title", defaultMessage: "Anonymous" }))),
        React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "30", fw: 500, lh: "38px", mt: 8, mb: 8 }, Total === null || Total === void 0 ? void 0 : Total.toLocaleString()),
        React.createElement(Group, { justify: "space-between", align: "Flex-end" },
            React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, align: "Flex-start", lastPeriodValue: PreviousValue, deltaValue: DeltaValue, deltaPercentage: DeltaPercentage, daysAgoCount: DataCoverageDaysCount, dataCoverageAvailable: DataCoverageAvailable }),
            React.createElement(OrchestryTooltip, { multiline: true, w: 300, label: intl.formatMessage({
                    id: "page.health_check.anonymous_links_tile.tooltip",
                    defaultMessage: "Links to files or folders using links that can be used by anyone and doesn’t require sign in."
                }) },
                React.createElement(OrchestryFontIcon, { isLoading: isLoading, fontSize: 24, iconClassName: ORCH_ICONS.infoCircle, color: "var(--mantine-color-gray-5)", lineHeight: "24px" })))));
};
