var _a;
import { RecommendationHistoryEventType } from "appv2/api/recommendations/recommendation-history-event-type";
import ORCH_SVG_ICONS from "../../../../../../assets/icons/orchestry-svgs/svg-icons";
/**
 * Gets the badge value for the given index.
 * @param index The index of the badge to get the value for. The first badge is highlighted.
 */
export var getBadgeColor = function (index) { return (index === 0 ? "var(--mantine-color-primary-9)" : "var(--mantine-color-text-tertiary-0)"); };
export var RECOMMENDATION_HISTORY_EVENT_TYPE_TO_TEXT_MAPPING = (_a = {},
    _a[RecommendationHistoryEventType.Opened] = "Opened",
    _a[RecommendationHistoryEventType.MarkedAsResolved] = "Marked As Resolved",
    _a[RecommendationHistoryEventType.AutoResolved] = "Auto Resolved",
    _a[RecommendationHistoryEventType.Snoozed] = "Snoozed",
    _a[RecommendationHistoryEventType.UnsnoozedByUser] = "Unsnoozed",
    _a[RecommendationHistoryEventType.Created] = "Created",
    _a);
var ORCHESTRY_NAME = "Orchestry";
var isModifiedByOrchestry = function (model) { return model.ModifiedByName === ORCHESTRY_NAME; };
/**
 * Gets the avatar url for the given recommendation history model.
 * @param model The recommendation history model to get the avatar url for.
 */
export var getAvatarUrl = function (model) { return (isModifiedByOrchestry(model) ? ORCH_SVG_ICONS.Logomark : model.UserPhoto); };
/**
 * Gets the avatar variant for the given recommendation history model.
 * @param model The recommendation history model to get the avatar variant for.
 */
export var getAvatarVariant = function (model) { return (isModifiedByOrchestry(model) ? "icon" : ""); };
