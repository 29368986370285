import UsersService from "app/services/users";
import { useEffect, useState } from "react";
/**
 * Gets a user's base64 photo by their ID from the UsersService API.
 *
 * @param userId The user ID to get the photo for
 */
export var useGetUserPhoto = function (userId) {
    var _a = useState(null), base64Image = _a[0], setBase64Image = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    useEffect(function () {
        if (userId) {
            setIsLoading(true);
            UsersService.getUserBase64Photo(userId).then(function (image) {
                setIsLoading(false);
                setBase64Image(image);
            });
        }
    }, [userId]);
    return { base64Image: base64Image, isLoading: isLoading };
};
