import { Stack } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
export var HealthCheckNameDescription = function (intl) { return (React.createElement(OrchestryText, { c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, intl.formatMessage({
    id: "page.generate_new_health_check.name.description",
    defaultMessage: "Give your Health Check a unique name."
}))); };
export var HealthCheckStartDateDescription = function (intl, orchesteryInstallationDate) { return (React.createElement(Stack, { gap: 8 },
    React.createElement(OrchestryText, { c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, intl.formatMessage({
        id: "page.generate_new_health_check.start_date.description",
        defaultMessage: "Identify the date range you wish to use for this Health Check."
    })),
    React.createElement(OrchestryText, { c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, intl.formatMessage({
        id: "page.generate_new_health_check.start_date.note",
        defaultMessage: "Note: Health Checks will contain incomplete data for any data prior to your Orchestry Installation {date}"
    }, { date: "(".concat(getReadableDate(orchesteryInstallationDate), ")") })))); };
