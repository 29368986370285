import * as React from "react";
import "./library-template-list.scss";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as LibraryTemplateListStore from "app/store/library-templates/list";
import * as TableList from "app/components/common/table-list";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import InfoTooltip from "app/components/common/infotooltip";
var LibraryTemplateList = React.memo(function () {
    var dispatch = useDispatch();
    var librarytemplates = useSelector(function (state) { return state.libraryTemplateListState.items; });
    //console.log("LibraryTemplate LIst Component LibraryTemplates > ", librarytemplates);
    React.useEffect(function () {
        dispatch(LibraryTemplateListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var renderType = function (librarytemplate) {
        //console.log("renderType", librarytemplate.LibraryTemplateType);
        if (librarytemplate.TemplateType === "SharePoint") {
            return (React.createElement("div", { className: "wp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint", className: "fadein-500ms" })),
                React.createElement("span", null, "Online Workspace")));
        }
        if (librarytemplate.TemplateType === "Team") {
            return (React.createElement("div", { className: "wp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" })),
                React.createElement("span", null, "Chat-based Workspace")));
        }
        return React.createElement(React.Fragment, null);
    };
    var toggleAvailability = function (librarytemplate, checked) {
        // Change this to use List_Item_Update and do the true/false logic here.
        //dispatch(LibraryTemplateListStore.UpdateItem(currentLibraryTemplate));
        librarytemplate = Object.assign(librarytemplate, { AllowTenantsToUseTemplate: checked });
        dispatch({ type: LibraryTemplateListStore.LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Update, payload: librarytemplate });
    };
    var IsTemplateLocked = function (librarytemplate) {
        if (librarytemplate.IsLocked !== undefined && librarytemplate.IsLocked === true) {
            return (React.createElement(InfoTooltip, { content: "This template is used when a new customer signs up. It can't be deleted", placement: "bottom" },
                React.createElement("i", { className: "fa fa-lock" })));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement("div", { className: "library-template-list fadein-500ms" },
        React.createElement(Subtitle, { subtitle: React.createElement(React.Fragment, null, "List") },
            React.createElement(NavLink, { exact: true, to: "/orchestry-admin/library/templates/new" },
                React.createElement(SubtitleButton, { title: "New Template", faIcon: "plus", color: "orange" }))),
        React.createElement(PageHeader.SetLeftContent, null,
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a template" })),
        React.createElement(TableList.Context, { listItems: librarytemplates, asyncListLoadUID: LibraryTemplateListStore.LibraryTemplateListActionTypes.LibraryTemplate_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Template Name"),
                React.createElement(TableList.HeaderItem, null),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, null, "Type"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Position"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Available"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/orchestry-admin/library/templates/edit/{GUID}" }),
                React.createElement(TableList.ContentItemCustomRender, { render: IsTemplateLocked, className: "td-lock-icon", link: "/orchestry-admin/library/templates/edit/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItemCustomRender, { render: renderType, className: "td-type" }),
                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Position" }),
                React.createElement(TableList.ContentItemToggle, { listDataProperyName: "AllowTenantsToUseTemplate", handleChange: function (item, checked) { return toggleAvailability(item, checked); } }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/orchestry-admin/library/templates/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/orchestry-admin/library/templates/edit/{GUID}"
                    onRemove: function (item) { return dispatch(LibraryTemplateListStore.DeleteItem(item)); }, conditionalHideRemove: "IsLocked", conditionalEditAsView: "IsLocked" })))));
});
export default LibraryTemplateList;
