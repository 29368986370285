import axios from "axios";
var FeatureFilesService = /** @class */ (function () {
    function FeatureFilesService() {
    }
    FeatureFilesService.GetFiles = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/FeatureFiles/GetFiles";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    FeatureFilesService.GetTemplateFileContents = function (fileName) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/FeatureFiles/GetPNPFileContexts?fileName=" + fileName;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    FeatureFilesService.GetFileInsidePNPFile = function (pnpFileName, fileName) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/FeatureFiles/GetPNPContentsFile?pnpFileName=" + pnpFileName + "&fileName=" + fileName;
            axiosConfig.responseType = "text";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    FeatureFilesService.ExtractTemplate = function (url, fileName, handlers, boolValues, listIds) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("url", url);
            formData.append("fileName", fileName);
            formData.append("handlers", handlers);
            formData.append("boolValues", boolValues);
            formData.append("listsIds", listIds);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/FeatureFiles/ExtractTemplate";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    FeatureFilesService.ApplyTemplate = function (url, fileName) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("url", url);
            formData.append("fileName", fileName);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/FeatureFiles/ApplyTemplate";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    FeatureFilesService.ApplyTemplates = function (urls, fileName) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("urls", JSON.stringify(urls));
            formData.append("fileName", fileName);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/FeatureFiles/ApplyTemplates";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    FeatureFilesService.DeleteFile = function (fileName) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/FeatureFiles/DeleteFile?fileName=" + fileName;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return FeatureFilesService;
}());
export default FeatureFilesService;
