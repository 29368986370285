import { Highlight, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { titleCase } from "title-case";
import { RecommendationCardBadgeGroup } from "./recommendation-card-badge-group";
import "./recommendation-card-details-section.scss";
import { determineDateString, isRecommendationNewlyLaunched, wasRecommendationRecentlyOpened } from "./recommendation-card-details-section.utils";
export var RecommendationCardDetailsSection = function (_a) {
    var _b, _c, _d, _e, _f;
    var recommendation = _a.recommendation, badgeOnClick = _a.badgeOnClick, highlightSearchWords = _a.highlightSearchWords, currentTab = _a.currentTab, isExpandable = _a.isExpandable;
    return (React.createElement("div", { className: "recommendation-card-details-section" },
        React.createElement("div", { className: "header" },
            React.createElement(Highlight, { c: "text-primary.0", size: "sm", fw: 700, highlight: highlightSearchWords !== null && highlightSearchWords !== void 0 ? highlightSearchWords : "" }, titleCase((_d = (_c = (_b = recommendation.RecommendationRecord) === null || _b === void 0 ? void 0 : _b.DisplayName) === null || _c === void 0 ? void 0 : _c.toLocaleLowerCase()) !== null && _d !== void 0 ? _d : (_f = (_e = recommendation.Recommendation) === null || _e === void 0 ? void 0 : _e.DisplayName) === null || _f === void 0 ? void 0 : _f.toLocaleLowerCase())),
            recommendation.RecommendationRecord.DisplayNameInfo && (React.createElement("div", { className: "info-circle" },
                React.createElement(OrchestryTooltip, { label: recommendation.RecommendationRecord.DisplayNameInfo, withinPortal: true, multiline: true, w: 375, position: "top-start" },
                    React.createElement("i", { className: "orchicon ".concat(ORCH_ICONS.infoCircle) })))),
            isExpandable && wasRecommendationRecentlyOpened(recommendation) && (React.createElement("div", { className: "new-badge" },
                React.createElement(OrchestryBadge, { color: "var(--mantine-color-success-7)", title: "Recently Opened", value: "recentlyopened", icon: ORCH_ICONS.star05 }))),
            isExpandable && isRecommendationNewlyLaunched(recommendation) && (React.createElement("div", { className: "just-launched-badge" },
                React.createElement(OrchestryBadge, { title: "Just Launched", value: "justlaunched", icon: ORCH_ICONS.confetti })))),
        React.createElement(Highlight, { className: "description", c: "text-secondary.0", size: "sm", fw: 400, highlight: highlightSearchWords !== null && highlightSearchWords !== void 0 ? highlightSearchWords : "" }, recommendation.RecommendationRecord.Description),
        React.createElement("div", { className: "metadata" },
            React.createElement(RecommendationCardBadgeGroup, { recommendation: recommendation, badgeOnClick: badgeOnClick }),
            React.createElement(Text, { c: "text-tertiary.0", size: "xs" }, determineDateString(currentTab, recommendation.RecommendationRecord)))));
};
