import axios from "axios";
var InsightsService = /** @class */ (function () {
    function InsightsService() {
    }
    InsightsService.GetProvisionedStats = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Insights/getprovisionedstats";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return InsightsService;
}());
export default InsightsService;
