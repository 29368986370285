import clsx from "clsx";
import React from "react";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import "./orchestry-icon-label.scss";
/**
 * Renders an icon with a label beside it. Both the icon and the label can be styled.
 */
export var OrchestryIconLabel = function (_a) {
    var colour = _a.colour, iconSize = _a.iconSize, iconVariant = _a.iconVariant, iconColorVariant = _a.iconColorVariant, iconClassName = _a.iconClassName, textSize = _a.textSize, textFW = _a.textFW, textColorVariant = _a.textColorVariant, textLabel = _a.textLabel, disabled = _a.disabled, isLoading = _a.isLoading;
    return (React.createElement("div", { className: clsx("orchestry-icon-label") },
        React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, disabled: disabled, size: iconSize, variant: iconVariant, colorVariant: iconColorVariant, iconClassName: iconClassName }),
        React.createElement(OrchestryText, { isLoading: isLoading, c: colour, fw: textFW, lh: "", size: textSize, "data-disabled": disabled, "data-color-variant": textColorVariant === null || textColorVariant === void 0 ? void 0 : textColorVariant.toLowerCase() }, textLabel)));
};
