var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Skeleton } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryAutocomplete } from "../orchestry-autocomplete/orchestry-autocomplete.component";
import { OrchestryTooltip } from "../orchestry-tooltip/orchestry-tooltip.component";
/**
 * A component that displays a text input with a list of suggestions based on the input,
 * however user is not limited to suggestions and can type anything. The list of suggestions
 * is also not required.
 */
export var OrchestrySearchBox = function (_a) {
    var onXClicked = _a.onXClicked, tooltipLabel = _a.tooltipLabel, isLoading = _a.isLoading, skeletonWidth = _a.skeletonWidth, autocompleteProps = __rest(_a, ["onXClicked", "tooltipLabel", "isLoading", "skeletonWidth"]);
    if (isLoading) {
        return React.createElement(Skeleton, { h: 40, w: skeletonWidth });
    }
    return (React.createElement(OrchestryTooltip, { label: tooltipLabel, withinPortal: false },
        React.createElement(OrchestryAutocomplete, __assign({}, autocompleteProps, { variant: "default-searchbox", rightSection: !!autocompleteProps.value ? React.createElement("i", { className: ORCH_ICONS.x, onClick: function () { return onXClicked(); } }) : React.createElement("i", { className: ORCH_ICONS.searchMd }) }))));
};
