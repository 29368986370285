import * as React from "react";
import "./group-profile-picture.scss";
// import UsersService from 'app/services/users';
import getStringTwoLetterAcronym from "app/utils/getStringTwoLetterAcronym";
import FontIcon from "app/components/common/font-icon";
import AnimatedLoader from "app/components/common/animated-loader";
var GroupProfilePicture = React.memo(function (props) {
    // const [base64Image, setBase64Image] = React.useState("");
    // const [loading, setLoading] = React.useState(false);
    var base64Image = React.useState("")[0];
    var loading = React.useState(false)[0];
    // On first run, get the suggested users
    React.useEffect(function () {
        // let sessionKey = 'profile_pic_48x48_' + props.uid;
        // if(LocalSessionService.KeyExists(sessionKey)){
        //     setLoading(false);
        //     setBase64Image(LocalSessionService.Get(sessionKey) as string);
        // } else{
        //     UsersService.getUserBase64Photo(props.uid).then((resultData) => {
        //         setLoading(false);
        //         setBase64Image(resultData as string);
        //         LocalSessionService.Set(sessionKey, resultData as string);
        //     })
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (loading) {
        return React.createElement(AnimatedLoader, { size: "xsmall", theme: "dark" });
    }
    else if (base64Image !== "") {
        return React.createElement("img", { src: base64Image, alt: props.alt ? props.alt : "" });
    }
    else if (props.displayName) {
        return React.createElement("div", { className: "group-profile-picture-placeholder" }, getStringTwoLetterAcronym(props.displayName));
    }
    else {
        return (React.createElement("div", { className: "group-profile-picture-placeholder icon" },
            React.createElement(FontIcon, { iconString: "users" })));
    }
});
export default GroupProfilePicture;
