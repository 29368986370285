var highlightWords = function (searchTerm, words) {
    if (words === undefined)
        return "";
    if (words === null)
        return "";
    var capitalize = function (v) {
        return v.charAt(0).toUpperCase() + v.slice(1);
    };
    if (searchTerm === "")
        return words;
    var escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    words = words.replace(new RegExp(escapedSearchTerm.toLowerCase(), "g"), "<strong>" + searchTerm.toLowerCase() + "</strong>");
    words = words.replace(new RegExp(capitalize(escapedSearchTerm), "g"), "<strong>" + capitalize(searchTerm) + "</strong>");
    return words;
};
export default highlightWords;
