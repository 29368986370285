import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
var OrchestryCheckInstall = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    //const [isInTrial, setIsInTrial] = React.useState(false);
    //const [hasInstalled, setHasInstalled] = React.useState(orchestryContext.HasInstalledOnce);
    // const checkIsInTrial = () => {
    //     let trial = false;
    //     if(orchestryContext.DoesTenantHaveSubscription === false){
    //         if(orchestryContext.TrialExpires !== undefined && orchestryContext.TrialExpires !== null) {
    //             let expiresDatetime = moment(orchestryContext.TrialExpires).local();
    //             if(expiresDatetime.isValid()){
    //                 if(expiresDatetime.isAfter(moment.now())){
    //                     trial = true;
    //                 }
    //             }
    //         }
    //     }
    //     setIsInTrial(trial);
    // }
    // const checkHasInstalledOnce = () => {
    //     setHasInstalled(orchestryContext.HasInstalledOnce);
    // }
    // React.useEffect(()=>{
    //     //checkIsInTrial();
    //     checkHasInstalledOnce();
    // },[orchestryContext]);
    if (orchestryContext.HasInstalledOnce) {
        return React.createElement(React.Fragment, null, props.children);
    }
    else {
        return React.createElement(Redirect, { to: "/install" });
    }
});
export default OrchestryCheckInstall;
