import { AsyncWait } from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import FormToggleControl from "app/components/common/form-toggle-control";
import InfoTooltip from "app/components/common/infotooltip";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { AsyncStatus } from "app/store/async";
import * as OrchestryContextStore from "app/store/orchestry-context/current";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./settings-installation-feature-enablement.scss";
var SettingsInstallationFeatureEnablement = React.memo(function (_a) {
    var InstallStatusData = _a.InstallStatusData;
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    return (React.createElement("div", { className: "settings-installation-feature-enablement fadein-500ms" },
        React.createElement(Portlet, { className: "feature-enablement" },
            React.createElement(PortletHeader, { title: "Feature Enablement" }),
            React.createElement(PortletBody, { className: "feature-portlet-body" },
                React.createElement("p", null, "You can opt to disable Workspace Lifecycle features or Guest Management features"),
                React.createElement("div", { className: "feature-toggles" },
                    React.createElement("div", { className: "workspace-toggle" },
                        React.createElement("h6", { className: "feature-title" },
                            "Lifecycle Management ",
                            React.createElement(InfoTooltip, { title: "Orchestry retrieves data daily to monitor workspace status and apply lifecycle policies" })),
                        React.createElement(SettingsInstallationLifecycle, null)),
                    React.createElement("div", { className: "guest-toggle" },
                        React.createElement("h6", { className: "feature-title" },
                            "Guest Management ",
                            React.createElement(InfoTooltip, { title: "Orchestry retrieves data daily to monitor guest status and apply guest policies" })),
                        InstallStatusData !== undefined && InstallStatusData.LastConsentedServiceVersion <= 10009 ? (React.createElement(PortletAlert, { title: "You must update to the most recent version of Orchestry", type: "warning", size: "small", icon: "alert", outline: false })) : InstallStatusData !== undefined && InstallStatusData.HasP1License === false ? (React.createElement(PortletAlert, { title: "Your tenant does not have the minimum required P1 license", type: "warning", size: "small", icon: "alert", outline: false })) : null,
                        InstallStatusData !== undefined && currentTenant !== undefined && currentTenant.GUID !== undefined ? (React.createElement(DisableArea, { useOverlay: true, isEnabled: (InstallStatusData.LastConsentedServiceVersion >= 10010 && InstallStatusData.HasP1License === true) || currentTenant.GuestManagementEnabled === true },
                            React.createElement(SettingsInstallationGuests, null))) : (React.createElement(SkeletonLoaderFakeBox, null))),
                    React.createElement("div", { className: "viva-toggle" },
                        React.createElement("h6", { className: "feature-title" }, "Viva Engage (Yammer) Provisioning"),
                        InstallStatusData !== undefined && InstallStatusData.LastConsentedVersion < 10056 ? (React.createElement(PortletAlert, { title: "You must update to the most recent version of Orchestry to enable this feature", type: "warning", size: "small", icon: "alert", outline: false })) : null,
                        InstallStatusData !== undefined && currentTenant !== undefined && currentTenant.GUID !== undefined ? (React.createElement(DisableArea, { useOverlay: true, isDisabled: InstallStatusData.LastConsentedVersion < 10056 },
                            React.createElement(SettingsInstallationViva, null))) : (React.createElement(SkeletonLoaderFakeBox, null))),
                    React.createElement("div", { className: "edu-toggle" },
                        React.createElement("h6", { className: "feature-title" },
                            "Microsoft Education ",
                            React.createElement(InfoTooltip, { title: "Orchestry supports specialized Teams templates for use in Education" })),
                        InstallStatusData !== undefined && InstallStatusData.IsEduTenant === false ? (React.createElement(PortletAlert, { title: "Your tenant does not have the required Microsoft Education features", type: "warning", size: "small", icon: "alert", outline: false })) : null,
                        InstallStatusData !== undefined && currentTenantConfiguration !== undefined ? (React.createElement(DisableArea, { useOverlay: true, isEnabled: InstallStatusData.IsEduTenant === true || currentTenantConfiguration.EduFeaturesEnabled === true },
                            React.createElement(SettingsEduTenantToggle, null))) : (React.createElement(SkeletonLoaderFakeBox, null))))))));
});
export default SettingsInstallationFeatureEnablement;
var SettingsInstallationLifecycle = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState(null), loadingAfterLifecycleToggle = _a[0], setLoadingAfterLifecycleToggle = _a[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (currentTenant.LifecycleEnabled !== undefined && currentTenant.GUID !== undefined) {
            if (orchestryContext.LifecycleEnabled !== currentTenant.LifecycleEnabled) {
                setLoadingAfterLifecycleToggle(true);
                AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Tenant_Current_Silent_Update").then(function () {
                    setLoadingAfterLifecycleToggle(false);
                    dispatch(OrchestryContextStore.Load());
                });
            }
        }
    }, [currentTenant.LifecycleEnabled]);
    return (React.createElement("div", { className: "settings-lifecycle-delegates fadein-500ms" },
        React.createElement("div", { className: "lifecycle-install-instructions" },
            React.createElement("div", { className: "teams-app-install-choice" },
                !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                React.createElement("div", { className: "permission-options lifecycle-toggle" }, currentTenant !== undefined && currentTenant.LifecycleEnabled !== undefined && loadingAfterLifecycleToggle !== true ? (React.createElement("div", { className: "content-wrap" },
                    React.createElement(FormToggleControl, { border: true, checked: currentTenant.LifecycleEnabled, label: "Enable Orchestry's Lifecycle Features", handleChange: function (checked) { return dispatch(TenantCurrentStore.UpdateSilent({ LifecycleEnabled: checked })); } }))) : (React.createElement("div", { style: { marginBottom: "2rem", marginTop: "10px" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 }))))))));
});
var SettingsInstallationGuests = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState(null), loadingAfterGuestToggle = _a[0], setLoadingAfterGuestToggle = _a[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (currentTenant.GuestManagementEnabled !== undefined && currentTenant.GUID !== undefined) {
            if (orchestryContext.GuestManagementEnabled !== currentTenant.GuestManagementEnabled) {
                setLoadingAfterGuestToggle(true);
                AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Tenant_Current_Silent_Update").then(function () {
                    setLoadingAfterGuestToggle(false);
                    dispatch(OrchestryContextStore.Load());
                });
            }
        }
    }, [currentTenant.GuestManagementEnabled]);
    return (React.createElement("div", { className: "settings-lifecycle-delegates fadein-500ms" },
        React.createElement("div", { className: "lifecycle-install-instructions" },
            React.createElement("div", { className: "teams-app-install-choice" },
                !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                React.createElement("div", { className: "permission-options lifecycle-toggle" }, currentTenant !== undefined && currentTenant.GuestManagementEnabled !== undefined && loadingAfterGuestToggle !== true ? (React.createElement("div", { className: "content-wrap" },
                    React.createElement(FormToggleControl, { border: true, checked: currentTenant.GuestManagementEnabled, label: "Enable Orchestry's Guest Management Features", handleChange: function (checked) { return dispatch(TenantCurrentStore.UpdateSilent({ GuestManagementEnabled: checked })); } }))) : (React.createElement("div", { style: { marginBottom: "2rem" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 }))))))));
});
var SettingsInstallationViva = React.memo(function (_a) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _b = React.useState(currentTenant.VivaEngageEnabled), vivaEnabledStatus = _b[0], setvivaEnabledStatus = _b[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (vivaEnabledStatus !== currentTenant.VivaEngageEnabled)
            dispatch(TenantCurrentStore.UpdateSilent({ VivaEngageEnabled: vivaEnabledStatus }));
    }, [vivaEnabledStatus]);
    return (React.createElement("div", { className: "settings-lifecycle-delegates fadein-500ms" },
        React.createElement("div", { className: "lifecycle-install-instructions" },
            React.createElement("div", { className: "teams-app-install-choice" },
                !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                React.createElement("div", { className: "permission-options lifecycle-toggle" }, currentTenant !== undefined && currentTenant.VivaEngageEnabled !== undefined ? (React.createElement("div", { className: "content-wrap" },
                    React.createElement(FormToggleControl, { border: true, checked: vivaEnabledStatus, label: "Enable Viva Engage Provisioning", handleChange: function (checked) { return setvivaEnabledStatus(checked); } }))) : (React.createElement("div", { style: { marginBottom: "2rem", marginTop: "10px" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 }))))))));
});
var SettingsEduTenantToggle = React.memo(function () {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    // React.useEffect(() => {
    //    if (currentTenant.LifecycleEnabled !== undefined && currentTenant.GUID !== undefined) {
    //       if (orchestryContext.LifecycleEnabled !== currentTenant.LifecycleEnabled) {
    //          setLoadingAfterLifecycleToggle(true);
    //          AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Tenant_Current_Silent_Update").then(() => {
    //             setLoadingAfterLifecycleToggle(false);
    //             dispatch(OrchestryContextStore.Load());
    //          });
    //       }
    //    }
    // }, [currentTenant.LifecycleEnabled]);
    return (React.createElement("div", { className: "settings-edu-features-enabled fadein-500ms" },
        React.createElement("div", { className: "permission-options lifecycle-toggle" }, currentTenantConfiguration !== undefined && currentTenantConfiguration.EduFeaturesEnabled !== undefined ? (React.createElement("div", { className: "content-wrap" },
            React.createElement(FormToggleControl, { border: true, marginBottom: false, strongText: true, checked: currentTenantConfiguration.EduFeaturesEnabled === null ? false : currentTenantConfiguration.EduFeaturesEnabled, label: "Enable Orchestry's Education Features", handleChange: function (checked) { return dispatch(TenantConfigurationCurrentStore.UpdateConfig({ EduFeaturesEnabled: checked })); } }))) : (React.createElement("div", { style: { marginBottom: "2rem", marginTop: "10px" } },
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 }))))));
});
