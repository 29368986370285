import axios from "axios";
var TenantService = /** @class */ (function () {
    function TenantService() {
    }
    // const formData = new FormData();
    // formData.append('file', file);
    TenantService.isUserLoggedInAndGrantedConsent = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantconfiguration/IsUserLoggedInAndGrantedConsent";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.tryGetTenantInfo = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantconfiguration/TryGetTenantInfo";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.isInstalledLatestVersion = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantconfiguration/IsInstalledLatestVersion";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.getAllTenants = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantconfiguration/GetAllTenants";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.seedTestDataToTenant = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/debug/SeedTestDataToTenant?tenantId=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.clearTenantCache = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenants/ClearCache";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.unarchiveTenant = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify({ GUID: tenantGuid }));
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/tenants/Unarchive";
            axiosConfig.responseType = "json";
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.getPartnerTenants = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenants/GetTenantPartnerInfo?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantService.disconnectPartnerTenant = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var bodyFormData = new FormData();
            bodyFormData.set("TenantGuid", tenantGuid);
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/tenants/DisconnectPartnerTenant";
            axiosConfig.responseType = "json";
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return TenantService;
}());
export default TenantService;
