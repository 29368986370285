import axios from "axios";
var SensitivityLabelsService = /** @class */ (function () {
    function SensitivityLabelsService() {
    }
    SensitivityLabelsService.isTenantHaveLabelsEnabled = undefined;
    SensitivityLabelsService.orgLabels = undefined;
    SensitivityLabelsService.orchLabels = undefined;
    SensitivityLabelsService.userLabels = undefined;
    SensitivityLabelsService.getOrganizationLabels = function () {
        return new Promise(function (resolve, reject) {
            if (SensitivityLabelsService.orgLabels === undefined) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SensitivityLabels/GetOrganizationLabels";
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    SensitivityLabelsService.orgLabels = response.data;
                    resolve(response.data);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
            else {
                resolve(SensitivityLabelsService.orgLabels);
            }
        });
    };
    SensitivityLabelsService.getOrchestryAllowedLabels = function () {
        return new Promise(function (resolve, reject) {
            if (SensitivityLabelsService.orchLabels === undefined) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SensitivityLabels/GetOrchestryAllowedLabels";
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    SensitivityLabelsService.orchLabels = response.data;
                    resolve(response.data);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
            else {
                resolve(SensitivityLabelsService.orchLabels);
            }
        });
    };
    SensitivityLabelsService.getUserLabels = function () {
        return new Promise(function (resolve, reject) {
            if (SensitivityLabelsService.userLabels === undefined) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SensitivityLabels/GetUserLabels";
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    SensitivityLabelsService.userLabels = response.data;
                    resolve(response.data);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
            else {
                resolve(SensitivityLabelsService.userLabels);
            }
        });
    };
    SensitivityLabelsService.doesTenantHaveLabelsEnabled = function () {
        return new Promise(function (resolve, reject) {
            if (SensitivityLabelsService.isTenantHaveLabelsEnabled === undefined) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/SensitivityLabels/DoesTenantHaveLabelsEnabled";
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    SensitivityLabelsService.isTenantHaveLabelsEnabled = response.data;
                    resolve(response.data);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
            else {
                resolve(SensitivityLabelsService.isTenantHaveLabelsEnabled);
            }
        });
    };
    SensitivityLabelsService.getTemplateLabels = function (templateGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Templates/SensitivityLabels?templateGuid=" + templateGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //SensitivityLabelsService.orgLabels = response.data;
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return SensitivityLabelsService;
}());
export default SensitivityLabelsService;
