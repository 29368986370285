var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import PortletAlert from "app/components/common/portlet-alert";
import TenantService from "app/services/tenant";
import { ServiceAppPermissionConfiguration } from "app/store/tenants/types";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { InstallWizardContext } from "../..";
var InstallerStepUpdate = React.memo(function (props) {
    var iwc = React.useContext(InstallWizardContext);
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var isInTeams = document.getElementsByTagName("html")[0].classList.contains("in-teams");
    var addDotsToVersion = function (version) {
        if (version === null) {
            return "0.0.0.0.0";
        }
        var splitVersion = ("" + version).split("");
        return splitVersion.join(".");
    };
    React.useEffect(function () {
        // Check to see if granting can be skipped.
        TenantService.tryGetTenantInfo().then(function (tenantInfo) {
            if (tenantInfo.exists === true) {
                iwc.setTenantInformation(__assign(__assign({}, iwc.tenantInformation), { DisplayName: tenantInfo.DisplayName }));
            }
        });
        if (isInTeams === true) {
            iwc.setStepProgress(props.step.key, false);
        }
        iwc.setServiceAppConfig(orchestryContext.ServiceAppPermissionConfig);
    }, []);
    return (React.createElement("div", { className: "installer-step-information fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h3", null, "Update needed!"),
                React.createElement("p", null, "Orchestry needs to update permissions and various other assets within your organization's Teams and SharePoint."),
                React.createElement("p", null, "We've verified that you are a global administrator and have access to perform this action."),
                React.createElement("p", null, "It's important to update Orchestry to make sure it continues to work properly."),
                React.createElement("div", { className: "versions" },
                    React.createElement("div", { className: "install-item-small" },
                        React.createElement("div", { className: "status-icon" },
                            React.createElement("i", { className: "fas fa-key" })),
                        React.createElement("div", { className: "right" },
                            React.createElement("div", { className: "title" }, (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastConsentedVersion) != null &&
                                orchestryContext.LastConsentedVersion >= orchestryContext.OrchestryAppPermissionVersion
                                ? "You've consented to the latest Orchestry app version"
                                : "Your Orchestry app permissions are out of date"),
                            React.createElement("div", { className: "description" }, (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastConsentedVersion) != null &&
                                orchestryContext.LastConsentedVersion >= orchestryContext.OrchestryAppPermissionVersion ? (React.createElement(React.Fragment, null,
                                "orchestry-app-consent-version ",
                                React.createElement("span", { className: "version-highlight" },
                                    "p.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.OrchestryAppPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                "orchestry-app-consent-version",
                                " ",
                                React.createElement("span", { className: "version-highlight red" },
                                    "p.",
                                    (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastConsentedVersion) != null ? addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastConsentedVersion) : "0.0.0.0.0"),
                                " ",
                                React.createElement("span", { className: "raquo" }, "\u00BB"),
                                " ",
                                React.createElement("span", { className: "version-highlight green" },
                                    "p.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.OrchestryAppPermissionVersion))))))),
                    React.createElement("div", { className: "install-item-small" },
                        React.createElement("div", { className: "status-icon" },
                            React.createElement("i", { className: "fas fa-key" })),
                        orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadWriteAll ? (React.createElement("div", { className: "right" },
                            React.createElement("div", { className: "title" }, (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppLastConsentedVersion) !== null &&
                                orchestryContext.ServiceAppLastConsentedVersion >= orchestryContext.ServiceAppPermissionVersion
                                ? "You've consented to the latest service app version"
                                : "Your service app permissions are out of date"),
                            React.createElement("div", { className: "description" },
                                (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppLastConsentedVersion) !== null &&
                                    orchestryContext.ServiceAppLastConsentedVersion >= orchestryContext.ServiceAppPermissionVersion ? (React.createElement(React.Fragment, null,
                                    "service-app-consent-version ",
                                    React.createElement("span", { className: "version-highlight" },
                                        "sa.",
                                        addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                    "service-app-consent-version",
                                    " ",
                                    React.createElement("span", { className: "version-highlight red" },
                                        "sa.",
                                        orchestryContext.ServiceAppLastConsentedVersion !== null ? addDotsToVersion(orchestryContext.ServiceAppLastConsentedVersion) : "0.0.0.0.0"),
                                    " ",
                                    React.createElement("span", { className: "raquo" }, "\u00BB"),
                                    " ",
                                    React.createElement("span", { className: "version-highlight green" },
                                        "sa.",
                                        addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppPermissionVersion)))),
                                React.createElement("span", { className: "service-pill" }, "Read & Write")))) : (React.createElement("div", { className: "right" },
                            React.createElement("div", { className: "title" }, (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppReadAllLastConsentedVersion) !== null &&
                                orchestryContext.ServiceAppReadAllLastConsentedVersion >= orchestryContext.ServiceAppReadAllPermissionVersion
                                ? "You've consented to the latest service app version"
                                : "Your service app permissions are out of date"),
                            React.createElement("div", { className: "description" },
                                (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppReadAllLastConsentedVersion) !== null &&
                                    orchestryContext.ServiceAppReadAllLastConsentedVersion >= orchestryContext.ServiceAppReadAllPermissionVersion ? (React.createElement(React.Fragment, null,
                                    "service-app-consent-version ",
                                    React.createElement("span", { className: "version-highlight" },
                                        "sa.",
                                        addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppReadAllPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                    "service-app-consent-version",
                                    " ",
                                    React.createElement("span", { className: "version-highlight red" },
                                        "sa.",
                                        orchestryContext.ServiceAppReadAllLastConsentedVersion !== null ? addDotsToVersion(orchestryContext.ServiceAppReadAllLastConsentedVersion) : "0.0.0.0.0"),
                                    " ",
                                    React.createElement("span", { className: "raquo" }, "\u00BB"),
                                    " ",
                                    React.createElement("span", { className: "version-highlight green" },
                                        "sa.",
                                        addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.ServiceAppReadAllPermissionVersion)))),
                                React.createElement("span", { className: "service-pill" }, "Read-Only"))))),
                    React.createElement("div", { className: "install-item-small" },
                        React.createElement("div", { className: "status-icon" },
                            React.createElement("i", { className: "fas fa-cloud-download-alt" })),
                        React.createElement("div", { className: "right" },
                            React.createElement("div", { className: "title" }, (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion) != null && orchestryContext.LastInstalledVersion >= orchestryContext.OrchestryAppInstallVersion
                                ? "You've installed the latest version of Orchestry"
                                : "Your Orchestry installation is out of date"),
                            React.createElement("div", { className: "description" }, (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion) != null && orchestryContext.LastInstalledVersion >= orchestryContext.OrchestryAppInstallVersion ? (React.createElement(React.Fragment, null,
                                "install-version ",
                                React.createElement("span", { className: "version-highlight" },
                                    "i.",
                                    addDotsToVersion(orchestryContext.OrchestryAppInstallVersion)))) : (React.createElement(React.Fragment, null,
                                "install-version",
                                " ",
                                React.createElement("span", { className: "version-highlight red" },
                                    "i.",
                                    (orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion) != null ? addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.LastInstalledVersion) : "0.0.0.0.0"),
                                " ",
                                React.createElement("span", { className: "raquo" }, "\u00BB"),
                                " ",
                                React.createElement("span", { className: "version-highlight green" },
                                    "i.",
                                    addDotsToVersion(orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.OrchestryAppInstallVersion)))))))),
                isInTeams ? (React.createElement(PortletAlert, { type: "danger", icon: "alert", size: "small", title: "You cannot update Orchestry when running from Teams", message: React.createElement("span", null,
                        "Please",
                        " ",
                        React.createElement("a", { href: window.location.origin, target: "_blank" }, "open Orchestry in your browser"),
                        " ",
                        "to continue.") })) : null))));
});
export default InstallerStepUpdate;
