// import { MiddlewareFunction} from 'app/store/middleware'
import { RequestCurrentActionTypes } from "./types";
export function Load(request) {
    if (request === undefined)
        request = {};
    console.log("Load Current Request > ACTION > ", request);
    return {
        type: RequestCurrentActionTypes.Request_Current_Load,
        payload: request
    };
}
export function Add(request) {
    if (request === undefined)
        request = {};
    if (request.Template !== undefined) {
        if (request.Template.GUID !== undefined) {
            var templateGuid = request.Template.GUID;
            request.Template = { GUID: templateGuid };
        }
    }
    //console.log("Load Current Request > ACTION > ", request)
    return {
        type: RequestCurrentActionTypes.Request_Current_Add,
        payload: request
    };
}
export function Refresh(request) {
    if (request === undefined)
        request = {};
    console.log("Load Current Request > ACTION > ", request);
    return {
        type: RequestCurrentActionTypes.Request_Current_Refresh,
        payload: request
    };
}
export function Update(request) {
    return {
        type: RequestCurrentActionTypes.Request_Current_Update,
        payload: request
    };
}
export function UpdateStatus(requestStatusUpdate) {
    return {
        type: RequestCurrentActionTypes.Request_Current_Update_Status,
        payload: requestStatusUpdate
    };
}
export function Clear() {
    return {
        type: RequestCurrentActionTypes.Request_Current_Clear
    };
}
export var requestCurrentMiddlware = function (store, next, action) {
    if (action.type === RequestCurrentActionTypes.Request_Current_Load) {
        // (action.payload as Request).AssociatedFeatures?.forEach((feat:RequestBaseAssociation) => {
        // })
        // // action.payload.forEach((feat:Feature) => {
        // //     if(feat.icon !== undefined)
        // //         feat.icon = fontAwesomeHelper.getIconFromName(feat.icon?.iconName);
        // // });
        // console.log("Middleware", action.payload);
        next(action);
    }
    else {
        next(action);
    }
};
