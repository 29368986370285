import axios from "axios";
var InstallService = /** @class */ (function () {
    function InstallService() {
    }
    InstallService.start = function (accessCode) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/Start?accessCode=" + accessCode;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Install Start Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.updateOrchestryInfrastructureTemplate = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/UpdateOrchestryInfrastructureTemplate";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("UpdateOrchestryInfrastructureTemplate Response", response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.installOrchestryInfrastructureSiteCollection = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/InstallOrchestryInfrastructureSiteCollection";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("UpdateOrchestryInfrastructureTemplate Response", response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.InstallTeamsApp = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/InstallTeamsAppForOrchestryAdmins";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("UpdateOrchestryInfrastructureTemplate Response", response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.createTenantAzureStorageBlogContainer = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/CreateTenantAzureStorageBlogContainer";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response", response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.isUserGlobalAdmin = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/IsUserGlobalAdmin";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.GetInstallStatus = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/GetInstallStatus";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.GetWebPartInstallStatus = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/GetWebPartInstallStatus";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.AddUpdateWebparts = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/AddUpdateWebParts";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.GetWebPartAPIRequests = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/GetWebPartRequest";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    InstallService.IsAccessCodeCorrect = function (code) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Install/AccessCodeCorrect?code=" + code;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("createTenantAzureStorageBlogContainer Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return InstallService;
}());
export default InstallService;
