import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import Authenticate from "app/components/authentication/authenticate";
import NotificationToast from "app/components/common/notification-toast";
import { useGrowthbook } from "app/utils/hooks/useGrowthbook";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
/**
 * This is needed because useGrowthbook depends on react-redux useSelector, which requires a Provider
 */
var GrowthbookWrapper = function (_a) {
    var basename = _a.basename;
    var growthbook = useGrowthbook();
    React.useEffect(function () {
        growthbook === null || growthbook === void 0 ? void 0 : growthbook.init();
    }, [growthbook]);
    return (React.createElement(GrowthBookProvider, { growthbook: growthbook !== null && growthbook !== void 0 ? growthbook : new GrowthBook() },
        React.createElement(BrowserRouter, { basename: basename },
            React.createElement(LastLocationProvider, null,
                React.createElement(Authenticate, null,
                    React.createElement(Routes, null),
                    React.createElement(NotificationToast, null))))));
};
export default GrowthbookWrapper;
