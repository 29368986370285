import { mapRecommendationFilterNameToSVGIcon } from "./recommendation.utils";
import { useRecommendationsPage } from "./use-recommendations-page.hook";
/**
 * Gets the name and icon of a product
 * @param productGuid The guid of the product
 */
export var useRecommendationProduct = function (productGuid) {
    var _a, _b;
    var recommendationProducts = useRecommendationsPage().recommendationProducts;
    var product = (_a = recommendationProducts === null || recommendationProducts === void 0 ? void 0 : recommendationProducts.filter(function (product) { return product.Guid.toLowerCase() === productGuid.toLowerCase(); })[0]) !== null && _a !== void 0 ? _a : null;
    var productIcon = mapRecommendationFilterNameToSVGIcon((_b = product === null || product === void 0 ? void 0 : product.Name) === null || _b === void 0 ? void 0 : _b.toLowerCase());
    return {
        product: { name: product === null || product === void 0 ? void 0 : product.Name, icon: productIcon }
    };
};
