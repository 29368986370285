export var GuestManagementUserStatus;
(function (GuestManagementUserStatus) {
    GuestManagementUserStatus["Active"] = "Active";
    GuestManagementUserStatus["Inactive"] = "Inactive";
    GuestManagementUserStatus["PendingApproval"] = "PendingApproval";
    GuestManagementUserStatus["Deleted"] = "Deleted";
    GuestManagementUserStatus["PendingAcceptance"] = "PendingAcceptance";
})(GuestManagementUserStatus || (GuestManagementUserStatus = {}));
export var GuestManagementAccessViolations;
(function (GuestManagementAccessViolations) {
    GuestManagementAccessViolations["GuestsNotAllowed"] = "GuestsNotAllowed";
    GuestManagementAccessViolations["BlockedDomain"] = "BlockedDomain";
})(GuestManagementAccessViolations || (GuestManagementAccessViolations = {}));
export var GuestManagementUserCreationSource;
(function (GuestManagementUserCreationSource) {
    GuestManagementUserCreationSource["Azure"] = "Azure";
    GuestManagementUserCreationSource["Orchestry"] = "Orchestry";
})(GuestManagementUserCreationSource || (GuestManagementUserCreationSource = {}));
export var GuestManagementMembershipAction;
(function (GuestManagementMembershipAction) {
    GuestManagementMembershipAction["InviteResent"] = "InviteResent";
    GuestManagementMembershipAction["Deleted"] = "Deleted";
    GuestManagementMembershipAction["CreatedUser"] = "CreatedUser";
    GuestManagementMembershipAction["GroupAdded"] = "GroupAdded";
    GuestManagementMembershipAction["GroupAccessRequested"] = "GroupAccessRequested";
    GuestManagementMembershipAction["GroupAccessNotification"] = "GroupAccessNotification";
    GuestManagementMembershipAction["GroupAccessNotificationNoAction"] = "GroupAccessNotificationNoAction";
    GuestManagementMembershipAction["GroupAccessAutomatic"] = "GroupAccessAutomatic";
    GuestManagementMembershipAction["GroupAccessGranted"] = "GroupAccessGranted";
    GuestManagementMembershipAction["GroupAccessRejected"] = "GroupAccessRejected";
    GuestManagementMembershipAction["GroupReviewInitiated"] = "GroupReviewInitiated";
    GuestManagementMembershipAction["GroupReviewNotification"] = "GroupReviewNotification";
    GuestManagementMembershipAction["GroupReviewNotificationNoAction"] = "GroupReviewNotificationNoAction";
    GuestManagementMembershipAction["GroupReviewRenewed"] = "GroupReviewRenewed";
    GuestManagementMembershipAction["GroupReviewReject"] = "GroupReviewReject";
    GuestManagementMembershipAction["GroupReviewNoActionExecuted"] = "GroupReviewNoActionExecuted";
    GuestManagementMembershipAction["GroupReviewReset"] = "GroupReviewReset";
    GuestManagementMembershipAction["GroupRemoved"] = "GroupRemoved";
})(GuestManagementMembershipAction || (GuestManagementMembershipAction = {}));
