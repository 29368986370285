import * as React from "react";
import "./no-results.scss";
import { useIntl, FormattedMessage } from "react-intl";
var NoResults = React.memo(function (props) {
    var intl = useIntl();
    return (React.createElement("div", { className: "no-results-panel", "data-pw": "no-results" },
        React.createElement("div", null,
            React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "fa fa-" + (props.faIcon !== undefined ? props.faIcon : "file-alt") })),
            React.createElement("div", { className: "no-results-text", "data-pw": "no-results-text" }, props.mainText !== undefined ? React.createElement(React.Fragment, null, props.mainText) : React.createElement(FormattedMessage, { id: "component.no_results.no_results", defaultMessage: "No Results" })),
            React.createElement("div", { className: "please-try", "data-pw": "please-try" }, props.subText !== undefined ? props.subText : intl.formatMessage({ id: "component.no_results.please_try_again", defaultMessage: "Please try again" })))));
});
export default NoResults;
