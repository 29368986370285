export var HealthCheckStartDate;
(function (HealthCheckStartDate) {
    /**
     * The start date is 30 days ago.
     */
    HealthCheckStartDate["30DaysAgo"] = "30DaysAgo";
    /**
     * The start date is 60 days ago.
     */
    HealthCheckStartDate["60DaysAgo"] = "60DaysAgo";
    /**
     * The start date is 90 days ago.
     */
    HealthCheckStartDate["90DaysAgo"] = "90DaysAgo";
    /**
     * The start date is 1 year ago.
     */
    HealthCheckStartDate["1YearAgo"] = "1YearAgo";
    /**
     * The start date is a custom date.
     */
    HealthCheckStartDate["Custom"] = "Custom";
})(HealthCheckStartDate || (HealthCheckStartDate = {}));
