import { AuthActionTypes } from "./types";
import AuthenticationService from "app/services/authentication";
import Cookies from "js-cookie";
export function Login(auth) {
    if (auth === undefined)
        auth = {};
    return {
        type: AuthActionTypes.Auth_Login,
        payload: auth
    };
}
export function GetAccessToken() {
    return {
        type: AuthActionTypes.Auth_GetAccessToken,
        payload: { isAuthorized: false }
    };
}
export function IsAccessTokenValid() {
    return {
        type: AuthActionTypes.Auth_IsAccessTokenValid,
        payload: { isAuthorized: false }
    };
}
export function Logout() {
    return {
        type: AuthActionTypes.Auth_Logout
    };
}
export var authMiddlware = function (store, next, action) {
    if (action.type === AuthActionTypes.Auth_Login) {
        //  console.log("Login Done > ", action.payload);
        //LocalSessionService.SetObject("is_authorized", (action.payload  as AuthState).isAuthorized);
        next(action);
    }
    else if (action.type === AuthActionTypes.Auth_Logout) {
        //   console.log("Logout > Remove Session, Context and Cookies");
        //LocalSessionService.Remove("is_authorized");
        Cookies.remove("ASP.NET_SessionId");
        Cookies.remove("orchestry-region");
        Cookies.remove("orchestry-token-expiration");
        Cookies.remove("orchestry-token");
        AuthenticationService.signOut();
        next(action);
    }
    else if (action.type === AuthActionTypes.Auth_IsAccessTokenValid) {
        // Is the auth set in the storage?
        //const isAuthorized: Boolean | null = LocalSessionService.GetBoolean("is_authorized");
        //console.log("storageAuth", storageAuthString);
        // if (isAuthorized !== undefined && isAuthorized !== null && isAuthorized  == true) {
        //    action.payload = { isAuthorized: true }
        //    next(action);
        // }
        // If the storage has the auth stored >
        // If the auth isn't storage.
        // else {
        //console.log("getAccessTokenWithInfo middleware");
        AuthenticationService.isAccessTokenValid()
            .then(function (isValid) {
            //LocalSessionService.SetObject("is_authorized", isValid);
            //  if(data !== undefined && data.AccessToken !== undefined){
            //      const authObject = { ...data, isAuthorized:true };
            //      LocalExpiringStorageService.setStorage("auth", JSON.stringify(authObject), authObject.ExpiresOn);
            //      action.payload = authObject;
            //  }
            action.payload = { isAuthorized: isValid };
            next(action);
        })
            .catch(function (error) {
            action.payload = { isAuthorized: false };
            next(action);
        });
        // }
    }
    else {
        next(action);
    }
};
