var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import Async from "app/components/common/async";
import InfoTooltip from "app/components/common/infotooltip";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
import NoResults from "app/components/common/no-results";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import ReadMore from "app/components/common/read-more";
import DirectoryUpdateItem from "app/components/directory/directory-components/directory-update-item";
import DirectoryViewMembers from "app/components/directory/directory-components/directory-view-members";
import * as AsyncTypes from "app/store/async";
import * as DirectoryStore from "app/store/directory/search";
import { DirectorySearchActionTypes } from "app/store/directory/search/types";
import moment from "moment";
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./directory-list-cards.scss";
var DirectoryListCards = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var directoryFavs = useSelector(function (state) { return state.directorySearchState.Favs; });
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var directoryIsLoading = useSelector(function (state) { return state.directorySearchState.IsLoading; });
    console.log("directory", directory);
    var AddFavorite = function (item) {
        var tempArray = __spreadArray([], directoryFavs, true);
        tempArray.push(__assign({}, item));
        dispatch(DirectoryStore.UpdateFavs(tempArray));
    };
    var RemoveFavorite = function (item) {
        var tempArray = directoryFavs.filter(function (i) { return i.SiteId !== item.SiteId; });
        dispatch(DirectoryStore.UpdateFavs(__spreadArray([], tempArray, true)));
    };
    return (React.createElement("div", { "data-pw": "directory-list-cards", className: "directory-list-cards" + (directoryIsLoading === true ? " loading-from-server" : "") },
        React.createElement(Async, { uid: DirectorySearchActionTypes.Directory_Load, status: AsyncTypes.AsyncStatus.Started },
            React.createElement("div", { className: "loading-skeleton" },
                React.createElement("div", { className: "items" }, [1, 1, 1, 1, 1, 1, 1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "skel-card animated-wave", key: index },
                    React.createElement("div", { className: "information" },
                        React.createElement("div", { className: "pic" },
                            React.createElement("div", { className: "circle" })),
                        React.createElement("div", { className: "title" },
                            React.createElement("div", { className: "title-box" }))))); })))),
        React.createElement(Async, { uid: DirectorySearchActionTypes.Directory_Load, status: AsyncTypes.AsyncStatus.Succeeded },
            React.createElement("div", { className: "card-items" },
                directory.Items.map(function (item) { return (React.createElement(DirectoryCard, { AddFavorite: function (fitem) { return AddFavorite(fitem); }, RemoveFavorite: function (fitem) { return RemoveFavorite(fitem); }, directoryFavs: directoryFavs, item: item, goToTeamsLink: props.goToTeamsLink, getDirectoryItemUrl: props.getDirectoryItemUrl })); }),
                directory.Items.length === 0 ? (React.createElement("div", { className: "card-no-results" },
                    React.createElement(NoResults, null))) : null))));
});
export default DirectoryListCards;
var DirectoryCard = React.memo(function (props) {
    var intl = useIntl();
    var titleEl = React.useRef();
    var _a = React.useState(false), centerAlignOff = _a[0], setCenterAlignOff = _a[1];
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var _b = React.useState(false), metadataOpen = _b[0], setMetadataOpen = _b[1];
    var showFavs = useSelector(function (state) { return state.directorySearchState.Context.ShowFavs; });
    React.useEffect(function () {
        if (titleEl.current !== null) {
            if (titleEl.current.offsetHeight > 42) {
                setCenterAlignOff(true);
            }
            // console.log("Title Height", titleEl.current.offsetHeight);
        }
    }, []);
    /**
     * Renders all the external links in a group for the first field that has external links
     * @param fieldName  The name of the field
     * @param values The values of the field
     */
    var renderExternalLinks = function (item) {
        var itemNames = directory.Columns.filter(function (md) { return md.Type === "custom-link"; }).map(function (md) { return md.ProperyName; });
        var links = item.Metadata.filter(function (md) { return itemNames.some(function (itemName) { return itemName === md.SpColumnsName; }); });
        if (!links) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { className: "links-group" }, links.map(function (link, index) {
            return (link === null || link === void 0 ? void 0 : link.Values) && (React.createElement("div", { className: "link" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLink }),
                React.createElement("a", { key: index, href: link.Values, target: "_blank", rel: "noreferrer" }, link.DisplayName)));
        })));
    };
    var getMetadataValue = function (item, fieldName, renderType) {
        var re = null;
        var foundMetadata = item.Metadata.find(function (md) { return md.SpColumnsName === fieldName; });
        if (foundMetadata !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null) {
            if (renderType === "custom-choice-single") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if (renderType === "custom-choice-multiple") {
                var values = foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.split("|");
                re = (React.createElement(React.Fragment, null, values === null || values === void 0 ? void 0 : values.map(function (v, index) { return (React.createElement("div", { key: index }, v)); })));
            }
            else if (renderType === "custom-date") {
                re = React.createElement(React.Fragment, null, moment((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined ? foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.replace(/\"/g, "") : foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values).format("MMM Do YYYY"));
            }
            else if (renderType === "custom-link") {
                re = renderExternalLinks(item);
            }
            else if (renderType === "custom-text-single") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
            }
            else if (renderType === "custom-text-multiple") {
                re = React.createElement(React.Fragment, null, foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                // } else if (renderType === "custom-managed-metadata-single"){
                //    re = <>{foundMetadata?.Values}</>
                // } else if (renderType === "custom-managed-metadata-multiple"){
                //    re = <>{foundMetadata?.Values}</>
                // }
            }
            else if (renderType === "custom-managed-metadata-single") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.length) > 0 && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.indexOf("{")) > -1) {
                    var tagObject = JSON.parse(foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                    if (tagObject !== null && tagObject.TermID !== undefined) {
                        re = (React.createElement(ManagedMetadataValue, { node: {
                                NodeType: "Term",
                                Id: tagObject.TermID
                            } }));
                    }
                }
            }
            else if (renderType === "custom-managed-metadata-multiple") {
                if ((foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== undefined && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values) !== null && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.length) > 0 && (foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values.indexOf("[")) > -1) {
                    var tagObjects = JSON.parse(foundMetadata === null || foundMetadata === void 0 ? void 0 : foundMetadata.Values);
                    if (Array.isArray(tagObjects) && tagObjects.length > 0) {
                        re = (React.createElement("ul", { className: "managed-metadata-list" }, tagObjects.map(function (to) { return (React.createElement("li", null,
                            React.createElement("div", { className: "managed-metadata-item", "data-term-guid": to.TermID },
                                React.createElement("i", { className: "la la-tag" }),
                                React.createElement("span", null, to.Label)))); })));
                    }
                }
            }
        }
        // console.log("getMetadataValue", re, item, fieldName, renderType);
        return re;
    };
    var hasMetadataProperties = function () {
        var hasProperties = false;
        directory.Columns.forEach(function (col, colIndex) {
            if (getMetadataValue(props.item, col.ProperyName, col.Type) !== null) {
                hasProperties = true;
            }
        });
        return hasProperties;
    };
    /**
     * Returns true if the metadata link should be rendered for the given field name.
     * Renders the links only for the first field that has links because we ground the links under one field.
     * Also checks if any of the field have a value, if not, then we don't render the links.
     *
     * @param fieldName The name of the field
     */
    var shouldRenderMetadataLink = function (item, fieldName) {
        var _a;
        var itemNames = directory.Columns.filter(function (md) { return md.Type === "custom-link"; }).map(function (md) { return md.ProperyName; });
        var links = item.Metadata.filter(function (md) { return !!md.Values && itemNames.some(function (itemName) { return itemName === md.SpColumnsName; }); });
        if (!links || !(links.length > 0)) {
            return false;
        }
        /**
         * If the field name is not the same as the first link's field name, then we don't render the links
         * because we don't want to render the links for every field, only for the first one.
         */
        if (fieldName !== ((_a = links[0]) === null || _a === void 0 ? void 0 : _a.SpColumnsName)) {
            return false;
        }
        return true;
    };
    return (React.createElement("div", { className: "card", "data-pw": "card" },
        React.createElement("div", { className: "card-top" + (centerAlignOff === true ? " center-align-off" : "") },
            React.createElement("div", { className: "card-logo", "data-pw": "card-logo" },
                React.createElement("a", { className: "name-wrapper", onClick: function (event) { return props.goToTeamsLink(event, props.getDirectoryItemUrl(props.item)); }, href: props.getDirectoryItemUrl(props.item), target: "_blank" },
                    React.createElement("div", { className: "profile-icon" },
                        React.createElement(OfficeProfilePicture, { size: "sm", type: props.item.TemplateType.indexOf("SharePoint") > -1 ? "SharePoint" : "Teams", base64image: props.item.Base64Logo }),
                        props.item.IsVisibleInDirectory === false ? (React.createElement("div", { className: "hidden-workspace-icon" },
                            React.createElement(InfoTooltip, { content: intl.formatMessage({ id: "component.directory.cards.hidden_workspace", defaultMessage: "Hidden Workspace" }) },
                                React.createElement("i", { className: "fa fa-eye-slash" })))) : null))),
            React.createElement("div", { className: "card-title", "data-pw": "card-title", ref: titleEl },
                React.createElement("a", { className: "name-wrapper", onClick: function (event) { return props.goToTeamsLink(event, props.getDirectoryItemUrl(props.item)); }, href: props.getDirectoryItemUrl(props.item), target: "_blank" },
                    React.createElement("span", null, props.item.Name))),
            React.createElement("div", { className: "card-menu" },
                showFavs ? (React.createElement("div", { className: "fav-star " + (props.directoryFavs.some(function (i) { return i.SiteId === props.item.SiteId; }) === true ? "on" : "off") },
                    React.createElement("div", { className: "star-icon", "data-pw": "star-icon" },
                        React.createElement("i", { className: "la la-star star-on", onClick: function () { return props.RemoveFavorite(props.item); } }),
                        React.createElement("i", { className: "la la-star-o star-off", onClick: function () { return props.AddFavorite(props.item); } })))) : (React.createElement("div", { className: "fav-star" })),
                React.createElement(DirectoryUpdateItem, { directoryItem: props.item, openBelow: true }))),
        React.createElement("div", { className: "card-details" },
            React.createElement("div", { className: "card-type" },
                React.createElement("div", { className: "cat-icon", "data-pw": "card-type-icon" }, props.item.TemplateType === "SharePoint Communications" || props.item.TemplateType === "SharePoint Team" ? (React.createElement("img", { src: "/media/orchestry/icon-sharepoint.svg" })) : props.item.TemplateType === "Team" ? (React.createElement("img", { src: "/media/orchestry/icon-teams.svg" })) : (React.createElement("img", { src: iconViva }))),
                React.createElement("div", { className: "cat-title" },
                    React.createElement("div", { className: "cat-title-text", "data-pw": "card-type-title" },
                        props.item.TemplateType === "Team" ? (React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "component.directory.favs.ms_teams", defaultMessage: "Microsoft Team" }))) : null,
                        props.item.TemplateType === "SharePoint Team" ? (React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "component.directory.favs.team_site", defaultMessage: "Team Site" }))) : null,
                        props.item.TemplateType === "SharePoint Communications" ? (React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "component.directory.favs.comm_site", defaultMessage: "Communication Site" }))) : null,
                        props.item.TemplateType === "Viva Engage Community" ? (React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "component.directory.favs.viva_engage_community", defaultMessage: "Viva Engage Community" }))) : null),
                    props.item.Privacy !== "Custom" ? (React.createElement("div", { className: "cat-title-privacy", "data-pw": "card-title-privacy" }, props.item.Privacy)) : null),
                React.createElement("div", { className: "users" },
                    React.createElement(DirectoryViewMembers, { directoryItem: props.item }))),
            React.createElement("div", { className: "card-description", "data-pw": "card-description" }, props.item.Description)),
        hasMetadataProperties() === true ? (React.createElement("div", { className: "card-bottom" + (metadataOpen === true ? " bot-open" : "") },
            React.createElement("div", { className: "card-show-properties", onClick: function () { return setMetadataOpen(!metadataOpen); } },
                React.createElement("div", null,
                    React.createElement("i", { className: "fa fa-caret-down" }),
                    " ",
                    React.createElement(FormattedMessage, { id: "component.directory.cards.show_more", defaultMessage: "Show More" }))),
            React.createElement(Collapse, { in: metadataOpen },
                React.createElement("div", { className: "card-properties" }, directory.Columns.map(function (col, colIndex) { return (React.createElement(React.Fragment, null,
                    col.Type === "custom-choice-single" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null,
                    col.Type === "custom-choice-multiple" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null,
                    col.Type === "custom-date" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null,
                    col.Type === "custom-link" && shouldRenderMetadataLink(props.item, col.ProperyName) && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, intl.formatMessage({ id: "workspaces.metadata.external_links", defaultMessage: "External Links" })),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null,
                    col.Type === "custom-text-single" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null,
                    col.Type === "custom-text-multiple" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(ReadMore, { lines: 3 }, getMetadataValue(props.item, col.ProperyName, col.Type))))) : // <>{getMetadataValue(item, col.ProperyName, col.Type)}</>
                        null,
                    col.Type === "custom-managed-metadata-single" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null,
                    col.Type === "custom-managed-metadata-multiple" && getMetadataValue(props.item, col.ProperyName, col.Type) !== null ? (React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "title" }, col.Title),
                        React.createElement("div", { className: "value" },
                            React.createElement(React.Fragment, null, getMetadataValue(props.item, col.ProperyName, col.Type))))) : null)); }))))) : null));
});
