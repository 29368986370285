var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import FormToggleControl from "app/components/common/form-toggle-control";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import "react-tagsinput/react-tagsinput.css";
import { PortletBody, Portlet } from "app/components/common/portlet";
import SensitivityLabelsService from "app/services/sensitivity-labels";
import DisableArea from "app/components/common/disable-area";
import PortletAlert from "app/components/common/portlet-alert";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import * as TableList from "app/components/common/table-list";
import lineAwesomeHelper from "app/utils/lineAwesomeHelper";
import InfoTooltip from "app/components/common/infotooltip";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import SharePointService from "app/services/sharepoint";
var SettingsSensitivityLabels = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState({
        IsEnabled: false,
        LabelConfigs: []
    }), sensitivityConfig = _a[0], setSensitivityConfig = _a[1];
    var _b = React.useState(), isSensitivityLabelsEnabledOnTenant = _b[0], setIsSensitivityLabelsEnabledOnTenant = _b[1];
    var _c = React.useState(), sensitivityLabelsForOrg = _c[0], setSensitivityLabelsForOrg = _c[1];
    var _d = React.useState(false), toggledAtLeastOnce = _d[0], setToggledAtLeastOnce = _d[1];
    // const [formValid, setFormValid] = React.useState(false);
    var iconOptions = React.useState(lineAwesomeHelper.getIconOptionsForDropDown())[0];
    React.useEffect(function () {
        setIsSensitivityLabelsEnabledOnTenant(props.doesTenantHaveLabelsEnabled);
        if (props.doesTenantHaveLabelsEnabled) {
            SensitivityLabelsService.getOrganizationLabels().then(function (orgLabels) {
                setSensitivityLabelsForOrg(orgLabels);
            });
        }
    }, [props.doesTenantHaveLabelsEnabled]);
    // React.useEffect(() => {
    //    if (currentTenantConfiguration !== isUndefined) {
    //       let currentTenantConfigCopy: TenantConfiguration = { ...currentTenantConfiguration };
    //       props.setCurrentTenantConfigInternalState(currentTenantConfigCopy);
    //    }
    // }, [currentTenantConfiguration]);
    React.useEffect(function () {
        if (isSensitivityLabelsEnabledOnTenant !== undefined) {
            if (isSensitivityLabelsEnabledOnTenant === true) {
                if (sensitivityLabelsForOrg !== undefined) {
                    var tempSensitivityConfig_1 = __assign({}, sensitivityConfig);
                    if (currentTenantConfiguration !== undefined && currentTenantConfiguration.SensitivityLabelConfigJson !== undefined) {
                        if (currentTenantConfiguration.SensitivityLabelConfigJson === "" ||
                            currentTenantConfiguration.SensitivityLabelConfigJson === "null" ||
                            currentTenantConfiguration.SensitivityLabelConfigJson === null) {
                            // setLabelPicklistItems(sensitivityLabelsForOrg.map((sl) => (
                            //    {
                            //       Identifier : sl.id,
                            //       Name : sl.name,
                            //       Picked : true
                            //    } as PicklistItem
                            // )));
                        }
                        else {
                            tempSensitivityConfig_1 = JSON.parse(currentTenantConfiguration.SensitivityLabelConfigJson);
                        }
                        // If the configs are empty but there are labels from the org.
                        if ((tempSensitivityConfig_1.LabelConfigs === undefined || tempSensitivityConfig_1.LabelConfigs.length === 0) && sensitivityLabelsForOrg.length > 0) {
                            tempSensitivityConfig_1.LabelConfigs = sensitivityLabelsForOrg
                                .filter(function (sl) { return sl.IsActive === true; })
                                .map(function (sl) {
                                return ({
                                    Enabled: false,
                                    Id: sl.Id,
                                    Name: sl.Name,
                                    Description: sl.Description,
                                    Icon: null,
                                    PermissionOverride: sl.ClassificationPrivacy !== null ? (sl.ClassificationPrivacy === "private" ? "Private" : sl.ClassificationPrivacy === "public" ? "Public" : null) : null
                                });
                            });
                            //
                        }
                        else if (tempSensitivityConfig_1.LabelConfigs.length > 0 && sensitivityLabelsForOrg.length > 0) {
                            // tempSensitivityConfig.LabelConfigs = tempSensitivityConfig.LabelConfigs
                            //    .map((sl) => (
                            //       {
                            //          ...sl,
                            //          PermissionOverride: (() => {
                            //             let orgPrivacySetting = sensitivityLabelsForOrg.find((slfo) => slfo.Id === sl.Id)?.ClassificationPrivacy;
                            //             if (orgPrivacySetting !== null) {
                            //                return orgPrivacySetting === "private" ? "Private" : orgPrivacySetting === "public" ? "Public" : orgPrivacySetting === "unspecified" ? null : sl.PermissionOverride;
                            //             } else {
                            //                return sl.PermissionOverride;
                            //             }
                            //          })(),
                            //          SPSharingCapabilityOverride: (() => {
                            //             let orgPrivacySetting = sensitivityLabelsForOrg.find((slfo) => slfo.Id === sl.Id)?.ClassificationExtSharing;
                            //             if (orgPrivacySetting !== undefined) {
                            //                return orgPrivacySetting as "ExternalUserAndGuestSharing" | "ExistingExternalUserSharingOnly" | "ExternalUserSharingOnly" | "Disabled" | null;
                            //             } else {
                            //                return sl.SPSharingCapabilityOverride;
                            //             }
                            //          })(),
                            //       }
                            //    ));
                            // Add missing values
                            sensitivityLabelsForOrg
                                .filter(function (sl) { return sl.IsActive === true; })
                                .filter(function (sl) { return !tempSensitivityConfig_1.LabelConfigs.some(function (lc) { return lc.Id === sl.Id; }); })
                                .forEach(function (sl) {
                                tempSensitivityConfig_1.LabelConfigs.push({
                                    Enabled: false,
                                    Name: sl.Name,
                                    Description: sl.Description,
                                    Id: sl.Id,
                                    Icon: null,
                                    PermissionOverride: sl.ClassificationPrivacy !== null ? (sl.ClassificationPrivacy === "private" ? "Private" : sl.ClassificationPrivacy === "public" ? "Public" : null) : null
                                });
                            });
                        }
                    }
                    setSensitivityConfig(tempSensitivityConfig_1);
                    props.setPageLoaded(true);
                }
            }
            else {
                props.setPageLoaded(true);
            }
        }
    }, [currentTenantConfiguration, isSensitivityLabelsEnabledOnTenant, sensitivityLabelsForOrg]);
    React.useEffect(function () {
        var sensitivityConfigJson = JSON.stringify(sensitivityConfig);
        console.log("Updating Configs", sensitivityConfigJson);
        props.setCurrentTenantConfigInternalState(__assign(__assign({}, props.currentTenantConfigInternalState), { SensitivityLabelConfigJson: sensitivityConfigJson }));
    }, [sensitivityConfig]);
    //after the current config object is updated, check to see if the save button should be highlighted.
    React.useEffect(function () {
        //console.log("checked box", currentTenantConfigInternalState.DirectoryImportNonOrchestrySites);
        checkIfSaveable();
    }, [props.currentTenantConfigInternalState]);
    // Checks for edits
    var checkIfSaveable = function () {
        if (props.currentTenantConfigInternalState.GUID !== undefined) {
            if (props.currentTenantConfigInternalState.GUID !== "") {
                // Used to detect if the page has been edited at least once.
                if (!(currentTenantConfiguration.SensitivityLabelConfigJson === null)) {
                    if (JSON.stringify(props.currentTenantConfigInternalState) !== JSON.stringify(currentTenantConfiguration)) {
                        if (props.anyEdits === false) {
                            props.setAnyEdits(true);
                        }
                    }
                }
                else {
                    if (props.anyEdits === false) {
                        props.setAnyEdits(true);
                    }
                }
            }
        }
    };
    // const GetSL = (id: string): SensitivityLabel => {
    //    let foundLabel = sensitivityLabelsForOrg?.find((sl) => sl.Id === id);
    //    return !foundLabel ? {} as SensitivityLabel : foundLabel;
    // }
    var replaceOrgSettingsWithProperValues = function (value) {
        if (value === undefined) {
            return null;
        }
        if (value !== null && value === "unspecified") {
            return null;
        }
        if (value !== null && value === "private") {
            return "Private";
        }
        if (value !== null && value === "public") {
            return "Public";
        }
        return value;
    };
    var IsLabelOutOfSyncWithOrg = function (label) {
        var itemsOutOfScync = [];
        if (props.currentTenantConfigInternalState.SensitivityLabelConfigJson !== undefined &&
            props.currentTenantConfigInternalState.SensitivityLabelConfigJson !== null &&
            props.currentTenantConfigInternalState.SensitivityLabelConfigJson.indexOf("[") > -1 &&
            sensitivityLabelsForOrg !== undefined &&
            sensitivityLabelsForOrg.length > 0) {
            var currentTenantConfigObj = JSON.parse(props.currentTenantConfigInternalState.SensitivityLabelConfigJson);
            if (currentTenantConfigObj !== undefined && Array.isArray(currentTenantConfigObj.LabelConfigs)) {
                var currentLabelFromServer = currentTenantConfigObj.LabelConfigs.find(function (cl) { return cl.Id === label.Id; });
                if (currentLabelFromServer !== undefined) {
                    var foundOrgLabelConfig = sensitivityLabelsForOrg.find(function (slfo) { return slfo.Id === label.Id; });
                    if (foundOrgLabelConfig !== undefined) {
                        if (foundOrgLabelConfig.Name !== currentLabelFromServer.Name) {
                            //console.log(foundOrgLabelConfig, "this far");
                            //return true;
                            itemsOutOfScync.push(React.createElement("div", null, "- Name out of sync"));
                        }
                    }
                    if (foundOrgLabelConfig !== undefined) {
                        if (foundOrgLabelConfig.Description !== currentLabelFromServer.Description) {
                            //console.log(foundOrgLabelConfig, "this far");
                            //return true;
                            itemsOutOfScync.push(React.createElement("div", null, "- Description out of sync"));
                        }
                    }
                    if (foundOrgLabelConfig !== undefined) {
                        if (foundOrgLabelConfig.SharePointConnected === true) {
                            if (replaceOrgSettingsWithProperValues(foundOrgLabelConfig.ClassificationPrivacy) !== currentLabelFromServer.PermissionOverride) {
                                //console.log(foundOrgLabelConfig, "this far");
                                //return true;
                                itemsOutOfScync.push(React.createElement("div", null, "- Privacy Override out of sync"));
                            }
                        }
                    }
                    if (foundOrgLabelConfig !== undefined) {
                        if (foundOrgLabelConfig.SharePointConnected === true) {
                            if (replaceOrgSettingsWithProperValues(foundOrgLabelConfig.ClassificationExtSharing) !== currentLabelFromServer.SPSharingCapabilityOverride) {
                                //return true;
                                itemsOutOfScync.push(React.createElement("div", null, "- SharePoint Sharing out of sync"));
                            }
                        }
                    }
                }
            }
        }
        if (itemsOutOfScync.length === 0) {
            return null;
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, "The following items are out of sync:"),
                itemsOutOfScync.map(function (item) { return (React.createElement(React.Fragment, null, item)); }),
                React.createElement("div", { style: { marginTop: "15px" } }, "Click this icon to sync label with Orchestry")));
        }
    };
    var syncLabelWithOrchestry = function (label) {
        if (sensitivityLabelsForOrg !== undefined) {
            var folc = sensitivityLabelsForOrg.find(function (slfo) { return slfo.Id === label.Id; });
            if (folc !== undefined) {
                var tempSensitivityConfigJson = __assign(__assign({}, sensitivityConfig), { LabelConfigs: sensitivityConfig.LabelConfigs.map(function (lcm) {
                        return lcm.Id !== label.Id
                            ? lcm
                            : __assign(__assign({}, lcm), { Name: folc !== undefined && folc.Name !== undefined ? folc.Name : lcm.Name, Description: folc !== undefined && folc.Description !== undefined ? folc.Description : lcm.Description, PermissionOverride: (function () {
                                    if (folc !== undefined && folc.SharePointConnected === true) {
                                        return replaceOrgSettingsWithProperValues(folc.ClassificationPrivacy);
                                    }
                                    else {
                                        return lcm.PermissionOverride;
                                    }
                                })(), SPSharingCapabilityOverride: (function () {
                                    if (folc !== undefined && folc.SharePointConnected === true) {
                                        return replaceOrgSettingsWithProperValues(folc.ClassificationExtSharing);
                                    }
                                    else {
                                        return lcm.SPSharingCapabilityOverride;
                                    }
                                })() });
                    }) });
                setSensitivityConfig(tempSensitivityConfigJson);
            }
        }
    };
    return (React.createElement("div", { className: "sensitivity-labels-config fadein-500ms" }, props.pageLoaded === true ? (React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
            props.setFormValid(isValid);
        }, UniqueIdentifier: "SensitivityValidation", IsSubmitted: props.hasSubmitted }, props.currentTenantConfigInternalState.GUID !== "" ? (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "" },
            React.createElement(Col, { md: 12 },
                isSensitivityLabelsEnabledOnTenant === false ? (React.createElement(PortletAlert, { type: "warning", icon: "info", title: "Sensitivity Labels are not enabled within your organization", message: React.createElement("div", { className: "lock-alert" },
                        React.createElement("div", null,
                            "To enabled Sensitivity Labels follow the guide",
                            " ",
                            React.createElement("a", { href: "https://docs.microsoft.com/en-us/microsoft-365/compliance/sensitivity-labels-sharepoint-onedrive-files?view=o365-worldwide", target: "_blank", rel: "noopener noreferrer" }, "here"),
                            "."),
                        React.createElement("div", null,
                            "If you already have Sensitivity Labels deployed you can enable them on the ",
                            React.createElement(NavLink, { to: "/settings/groups" }, "Group Settings"),
                            " page."),
                        React.createElement("div", null,
                            "Alternativly, you can use Group Classifications instead of Sensitivity Labels to tag Teams and Groups. Setup classifications on the",
                            " ",
                            React.createElement(NavLink, { to: "/settings/groups" }, "Group Settings"),
                            " page.")) })) : null,
                React.createElement(DisableArea, { isEnabled: isSensitivityLabelsEnabledOnTenant, useOverlay: true },
                    React.createElement(Portlet, { className: "toggle-portlet" },
                        React.createElement(PortletBody, null,
                            React.createElement("p", { className: "strong margin-bottom-25" }, "Orchestry allows the use of Sensitivity Labels on Workspace Templates. Once enabled you can either directly assign a label to a template or you can allow the user to pick a label during the request submission process."),
                            React.createElement("div", { className: "settings-sensitivity-toggle" },
                                React.createElement(FormToggleControl, { checked: sensitivityConfig.IsEnabled, label: "Enable the use of Sensitivity Labels in Orchestry?", handleChange: function (checked) {
                                        setSensitivityConfig(__assign(__assign({}, sensitivityConfig), { IsEnabled: checked }));
                                        setToggledAtLeastOnce(true);
                                    }, border: false, strongText: true, marginBottom: false })))),
                    React.createElement(DisableArea, { isEnabled: sensitivityConfig.IsEnabled, useOverlay: true },
                        React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "label-configs" },
                            React.createElement(TableList.Context, { listItems: sensitivityConfig.LabelConfigs, wrappedInPortlet: true, showLoader: false },
                                React.createElement(TableList.TableList, null,
                                    React.createElement(TableList.HeaderItem, { className: "sync-check" }),
                                    React.createElement(TableList.HeaderItem, { className: "label-toggle" }, "Enabled"),
                                    React.createElement(TableList.HeaderItem, { className: "icon-tr" }, "Icon"),
                                    React.createElement(TableList.HeaderItem, null, "Label Name"),
                                    React.createElement(TableList.HeaderItem, null, "Description"),
                                    React.createElement(TableList.HeaderItem, null,
                                        "Privacy Setting",
                                        React.createElement(InfoTooltip, { delayHide: 2000 },
                                            "Please match this setting to your",
                                            " ",
                                            React.createElement("a", { href: "https://compliance.microsoft.com/informationprotection?viewid=sensitivitylabels", target: "_blank", rel: "noopener noreferrer" }, "information protection label configuration"),
                                            ". If this setting does not match you may experience unexpected behavior")),
                                    React.createElement(TableList.HeaderItem, null,
                                        "Group External Users Setting",
                                        " ",
                                        React.createElement(InfoTooltip, { delayHide: 2000 },
                                            "Please match this setting to your",
                                            " ",
                                            React.createElement("a", { href: "https://compliance.microsoft.com/informationprotection?viewid=sensitivitylabels", target: "_blank", rel: "noopener noreferrer" }, "information protection label configuration"),
                                            ". If this setting does not match you may experience unexpected behavior")),
                                    React.createElement(TableList.HeaderItem, null,
                                        "SharePoint Sharing Setting",
                                        React.createElement(InfoTooltip, { delayHide: 2000 },
                                            "Please match this setting to your",
                                            " ",
                                            React.createElement("a", { href: "https://compliance.microsoft.com/informationprotection?viewid=sensitivitylabels", target: "_blank", rel: "noopener noreferrer" }, "information protection label configuration"),
                                            ". If this setting does not match you may experience unexpected behavior")),
                                    React.createElement(TableList.ContentItemCustomRender, { className: "alert-not-matching", render: function (lc) {
                                            return IsLabelOutOfSyncWithOrg(lc) !== null ? (React.createElement("div", { className: "out-of-sync-warning" },
                                                React.createElement(InfoTooltip, { placement: "right", title: "Label override out of sync with M365", content: React.createElement(React.Fragment, null, IsLabelOutOfSyncWithOrg(lc)) },
                                                    React.createElement("div", { onClick: function () { return syncLabelWithOrchestry(lc); }, style: { cursor: "pointer" } },
                                                        React.createElement("i", { style: { color: "#cccc20" }, className: "fa fa-exclamation-triangle" }))))) : null;
                                        } }),
                                    React.createElement(TableList.ContentItemToggle, { listDataProperyName: "Enabled", className: "label-toggle", alignment: "left", handleChange: function (lc, checked) {
                                            return setSensitivityConfig(__assign(__assign({}, sensitivityConfig), { LabelConfigs: sensitivityConfig.LabelConfigs.map(function (lcm) { return (lcm.Id !== lc.Id ? lcm : __assign(__assign({}, lcm), { Enabled: checked })); }) }));
                                        } }),
                                    React.createElement(TableList.ContentItemCustomRender, { className: "icon-selector", render: function (lc) { return (React.createElement("div", null,
                                            React.createElement(FormDropdownSelect, { list: iconOptions, defaultValue: lc.Icon, placeholder: "No Icon", enableSearch: true, waitToRenderDDItemsUntilOpen: true, onChange: function (value) {
                                                    return setSensitivityConfig(__assign(__assign({}, sensitivityConfig), { LabelConfigs: sensitivityConfig.LabelConfigs.map(function (lcm) { return (lcm.Id !== lc.Id ? lcm : __assign(__assign({}, lcm), { Icon: value })); }) }));
                                                } }))); } }),
                                    React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return lc.Name; }, isMobileTitle: true }),
                                    React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return lc.Description; } }),
                                    React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return (React.createElement("div", null,
                                            React.createElement(FormDropdownSelect, { list: [
                                                    {
                                                        id: "none",
                                                        value: null,
                                                        title: "Not Defined"
                                                    },
                                                    {
                                                        id: "public",
                                                        value: "Public",
                                                        title: "Public" //,
                                                        //description:"Apply one specifed label to all workspaces created from this template."
                                                    },
                                                    {
                                                        id: "private",
                                                        value: "Private",
                                                        title: "Private" //,
                                                        //description:"Allow the user to select a label based on your organization's label policies"
                                                    }
                                                ], value: lc.PermissionOverride, disabled: sensitivityLabelsForOrg !== undefined &&
                                                    (sensitivityLabelsForOrg === null || sensitivityLabelsForOrg === void 0 ? void 0 : sensitivityLabelsForOrg.length) > 0 &&
                                                    sensitivityLabelsForOrg.some(function (slfo) { return slfo.Id === lc.Id && slfo.SharePointConnected === true; }), onChange: function (value) {
                                                    return setSensitivityConfig(__assign(__assign({}, sensitivityConfig), { LabelConfigs: sensitivityConfig.LabelConfigs.map(function (lcm) { return (lcm.Id !== lc.Id ? lcm : __assign(__assign({}, lcm), { PermissionOverride: value })); }) }));
                                                } }))); } }),
                                    React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return (React.createElement("div", null,
                                            React.createElement(FormDropdownSelect, { list: [
                                                    {
                                                        id: "none",
                                                        value: null,
                                                        title: "Not Defined"
                                                    },
                                                    {
                                                        id: "public",
                                                        value: true,
                                                        title: "Allow",
                                                        description: "Allow Group owners add people outside your organization to the group as guests."
                                                    },
                                                    {
                                                        id: "private",
                                                        value: false,
                                                        title: "Don't Allow",
                                                        description: "Deny Group owners to add people outside your organization to the group as guests."
                                                    }
                                                ], value: lc.GroupUserAccessOverride === undefined ? null : lc.GroupUserAccessOverride, 
                                                //disabled={sensitivityLabelsForOrg !== undefined && sensitivityLabelsForOrg?.length > 0 && sensitivityLabelsForOrg.some((slfo) => slfo.Id === lc.Id && slfo.ClassificationPrivacy !== null)}
                                                onChange: function (value) {
                                                    return setSensitivityConfig(__assign(__assign({}, sensitivityConfig), { LabelConfigs: sensitivityConfig.LabelConfigs.map(function (lcm) { return (lcm.Id !== lc.Id ? lcm : __assign(__assign({}, lcm), { GroupUserAccessOverride: value })); }) }));
                                                } }))); } }),
                                    React.createElement(TableList.ContentItemCustomRender, { render: function (lc) { return (React.createElement("div", null,
                                            React.createElement(FormDropdownSelect, { list: [
                                                    // {
                                                    //    id: "none",
                                                    //    value: null,
                                                    //    title: "No Override"
                                                    // },
                                                    // {
                                                    //    id: "public",
                                                    //    value: true,
                                                    //    title: "Allow",
                                                    //    description:"Allow Group owners add people outside your organization to the group as guests."
                                                    // },
                                                    // {
                                                    //    id: "private",
                                                    //    value: false,
                                                    //    title: "Don't Allow",
                                                    //    description:"Deny Group owners to add people outside your organization to the group as guests."
                                                    // }
                                                    {
                                                        id: "0",
                                                        value: null,
                                                        title: "Not Defined"
                                                    },
                                                    {
                                                        id: "1",
                                                        value: "ExternalUserAndGuestSharing",
                                                        title: SharePointService.settingsLabels.SharingCapability.ExternalUserAndGuestSharing.title,
                                                        description: SharePointService.settingsLabels.SharingCapability.ExternalUserAndGuestSharing.description
                                                    },
                                                    {
                                                        id: "2",
                                                        value: "ExistingExternalUserSharingOnly",
                                                        title: SharePointService.settingsLabels.SharingCapability.ExistingExternalUserSharingOnly.title,
                                                        description: SharePointService.settingsLabels.SharingCapability.ExistingExternalUserSharingOnly.description
                                                    },
                                                    {
                                                        id: "3",
                                                        value: "ExternalUserSharingOnly",
                                                        title: SharePointService.settingsLabels.SharingCapability.ExternalUserSharingOnly.title,
                                                        description: SharePointService.settingsLabels.SharingCapability.ExternalUserSharingOnly.description
                                                    },
                                                    {
                                                        id: "4",
                                                        value: "Disabled",
                                                        title: SharePointService.settingsLabels.SharingCapability.Disabled.title,
                                                        description: SharePointService.settingsLabels.SharingCapability.Disabled.description
                                                    }
                                                ], value: lc.SPSharingCapabilityOverride === undefined ? null : lc.SPSharingCapabilityOverride, disabled: sensitivityLabelsForOrg !== undefined &&
                                                    (sensitivityLabelsForOrg === null || sensitivityLabelsForOrg === void 0 ? void 0 : sensitivityLabelsForOrg.length) > 0 &&
                                                    sensitivityLabelsForOrg.some(function (slfo) { return slfo.Id === lc.Id && slfo.SharePointConnected === true; }), onChange: function (value) {
                                                    return setSensitivityConfig(__assign(__assign({}, sensitivityConfig), { LabelConfigs: sensitivityConfig.LabelConfigs.map(function (lcm) { return (lcm.Id !== lc.Id ? lcm : __assign(__assign({}, lcm), { SPSharingCapabilityOverride: value })); }) }));
                                                } }))); } })))),
                        sensitivityConfig.IsEnabled ? (React.createElement("div", { style: { marginTop: "-26px" } },
                            React.createElement(FormValidator, { UniqueIdentifier: "label-configs", FieldName: "available labels", ShowAsInfo: true, ShowFirst: true, Validators: ["is-true"], ErrorMessage: "Please toggle at least one label to be available", Value: sensitivityConfig.LabelConfigs.some(function (lc) { return lc.Enabled === true; }) }))) : null))),
            React.createElement(Col, { md: 6 })))) : null)) : (React.createElement(React.Fragment, null,
        React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100, showHeader: false },
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 }),
            React.createElement("div", { style: { display: "flex", marginTop: "25px" } },
                React.createElement("div", { style: { width: "50px", marginRight: "20px" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25 })),
                React.createElement("div", { style: { width: "300px" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25, bodyWidth: 300 })))),
        React.createElement(SkeletonLoaderPortlet, { showHeader: false },
            React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
                React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                React.createElement(SkeletonFakeTableCol, { widthPercent: 17 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                React.createElement(SkeletonFakeTableCol, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                React.createElement(SkeletonFakeTableCol, { widthPercent: 20 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 }))))))));
});
export default SettingsSensitivityLabels;
