import { Group, Stack } from "@mantine/core";
import MICROSOFT_SVG_ICONS from "appv2/assets/icons/microsoft-svgs/microsoft-svg-icons";
import { OrchestryColorLabel } from "appv2/components/orchestry-color-label/orchestry-color-label.component";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestrySVGIcon } from "appv2/components/orchestry-svg-icon/orchestry-svg-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useGrowthDonutChartTile } from "./use-growth-donut-chart-tile";
export var GrowthDonutChartTileLegendTeamsSection = function () {
    var _a = useGrowthDonutChartTile(), intl = _a.intl, isLoading = _a.isLoading, teamsData = _a.teamsData, privateChannelsData = _a.privateChannelsData;
    return (React.createElement(Stack, { gap: 4 },
        React.createElement(Group, { gap: 8 },
            React.createElement(OrchestrySVGIcon, { isLoading: isLoading, iconNeutral: MICROSOFT_SVG_ICONS.Teams, size: "md" }),
            React.createElement(OrchestryText, { isLoading: isLoading, fw: 500, c: "text-secondary.0", fz: "14", lh: "20px" }, intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.teams_section.title", defaultMessage: "Teams" }))),
        React.createElement(OrchestryDivider, { margin: "0" }),
        React.createElement(Stack, { gap: 0 },
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "#4E56BF", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.teams", defaultMessage: "Teams" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: teamsData === null || teamsData === void 0 ? void 0 : teamsData.Total, deltaPercentage: teamsData === null || teamsData === void 0 ? void 0 : teamsData.DeltaPercentage, deltaValue: teamsData === null || teamsData === void 0 ? void 0 : teamsData.DeltaValue })),
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "#757DE0", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.private_channels", defaultMessage: "Private Channels" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: privateChannelsData === null || privateChannelsData === void 0 ? void 0 : privateChannelsData.Total, deltaPercentage: privateChannelsData === null || privateChannelsData === void 0 ? void 0 : privateChannelsData.DeltaPercentage, deltaValue: privateChannelsData === null || privateChannelsData === void 0 ? void 0 : privateChannelsData.DeltaValue })))));
};
