import { FeatureCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {}
};
export function featureCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FeatureCurrentActionTypes.Feature_Current_Load: {
            //console.log(FeatureCurrentActionTypes.Feature_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case FeatureCurrentActionTypes.Feature_Current_Update: {
            // let associatedItemsUpdated = false;
            var updatedFeature = Object.assign({}, state.item, action.payload);
            // if(state.item.AssociatedTemplates.length !== updatedFeature.AssociatedTemplates.length)
            // {
            //     console.log("Array Changed");
            //     associatedItemsUpdated = true;
            // }
            //console.log(FeatureCurrentActionTypes.Feature_Current_Update, updatedFeature)
            return {
                item: updatedFeature
            };
        }
        case FeatureCurrentActionTypes.Feature_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
