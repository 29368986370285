import { useGetTenantEventsQuery } from "appv2/api/orchestry-admin-tools/tenant-event-browser/tenant-event-browser.api";
import { usePageSearch } from "appv2/hooks/use-page-search.hook";
import { generateGroupedFilterExpression, getOrderByExpression } from "appv2/utils/paging.utils";
import { useMemo, useState } from "react";
export var useTenantEventBrowserApi = function (appliedFilters) {
    var _a = useState([{ id: "CreatedDate", desc: true }]), sorting = _a[0], setSorting = _a[1];
    var _b = useState(1), activePage = _b[0], setActivePage = _b[1];
    var _c = useState(function () { var _a; return (_a = localStorage.getItem("tenant-events-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"; }), resultsPerPage = _c[0], setResultsPerPage = _c[1];
    var _d = usePageSearch(setActivePage), querySearchText = _d.querySearchText, highlightSearchWords = _d.highlightSearchWords, searchTextInputValue = _d.searchTextInputValue, onSearchBoxChange = _d.onSearchBoxChange, onSearchBoxKeyDown = _d.onSearchBoxKeyDown, onSearchBoxXClicked = _d.onSearchBoxXClicked;
    var filterExpression = useMemo(function () { return generateGroupedFilterExpression(appliedFilters); }, [appliedFilters]);
    var _e = useGetTenantEventsQuery({
        queryParameters: { orderBy: getOrderByExpression(sorting), page: activePage, pageSize: Number(resultsPerPage), filter: filterExpression },
        searchText: querySearchText
    }), data = _e.data, isFetching = _e.isFetching, isLoading = _e.isLoading, refetch = _e.refetch;
    var totalEventCount = data === null || data === void 0 ? void 0 : data.TotalCount;
    var totalPageEventCount = data === null || data === void 0 ? void 0 : data.PageTotal;
    return {
        data: data,
        isFetching: isFetching,
        isLoading: isLoading,
        refetch: refetch,
        sorting: sorting,
        setSorting: setSorting,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        querySearchText: querySearchText,
        highlightSearchWords: highlightSearchWords,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        totalEventCount: totalEventCount,
        totalPageEventCount: totalPageEventCount
    };
};
