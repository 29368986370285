import * as React from "react";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as MetadataListStore from "app/store/metadata/list";
import * as TableList from "app/components/common/table-list";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import InfoTooltip from "app/components/common/infotooltip";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
var MetadataList = React.memo(function () {
    var dispatch = useDispatch();
    var metadata = useSelector(function (state) { return state.metadataListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    console.log("Metadata", metadata);
    React.useEffect(function () {
        dispatch(MetadataListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var toggleShowInForm = function (mdi, checked) {
        mdi = Object.assign(mdi, { ShowInDetailsForm: checked });
        dispatch(MetadataListStore.UpdateItem(mdi));
    };
    var renderContent = function (md) {
        if (md.FieldType === "ManagedMetadata") {
            if (md.Values !== undefined && md.Values !== null && md.Values.indexOf("[") > -1) {
                var mdValues = JSON.parse(md.Values);
                if (Array.isArray(mdValues) && mdValues.length > 0) {
                    // return <>Term Id: {mdValues[0].Id}</>
                    return React.createElement(ManagedMetadataValue, { node: mdValues[0], showFullPath: true });
                }
                else {
                    return React.createElement(React.Fragment, null, md.Values);
                }
            }
            else {
                return React.createElement(React.Fragment, null, md.Values);
            }
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { dangerouslySetInnerHTML: { __html: md.Values !== null && md.Values !== undefined ? md.Values.replace(/(?:\r\n|\r|\n)/g, "<br>") : "" } })));
        }
    };
    var renderConnectionStatus = function (md) {
        if (md.SPFieldInternalName !== undefined && md.SPFieldInternalName !== null) {
            return (React.createElement(React.Fragment, null,
                React.createElement(InfoTooltip, { content: React.createElement("span", null,
                        "This metadata item is correctly linked to a field in the Orchestry Directory using the internal field name ",
                        React.createElement("strong", null, md.SPFieldInternalName)), placement: "bottom" },
                    React.createElement("i", { style: { color: "rgb(98 202 98 / 52%)" }, className: "fa fa-link" }))));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(InfoTooltip, { content: React.createElement("span", null, "There was a problem installing this metadata field in the directory list. Please contact support."), placement: "bottom" },
                    React.createElement("i", { style: { color: "#ff5252" }, className: "fa fa-unlink" }))));
        }
    };
    var renderFieldType = function (md) {
        if (md.FieldType !== undefined && md.FieldType !== null) {
            return React.createElement(React.Fragment, null, md.FieldType.replace("ManagedMetadata", "Managed Metadata"));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement("div", { className: "metadata-lists" },
        React.createElement(PageHeader.SetLeftContent, null,
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find metadata" })),
        React.createElement(Subtitle, { subtitle: "List" },
            React.createElement("div", { style: { display: "inline-block" } },
                React.createElement("a", { href: orchestryContext.TenantSharePointUrl + orchestryContext.OrchestrySiteCollectionUrl + "/Lists/WorkspaceDirectory", target: "_blank", rel: "noopener noreferrer" },
                    React.createElement(SubtitleButton, { title: "View Directory List in SharePoint", faIcon: "list-alt", variant: "info" }))),
            React.createElement(NavLink, { exact: true, to: "/workspaces/metadata/new" },
                React.createElement(SubtitleButton, { title: "New Metadata", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: metadata, asyncListLoadUID: MetadataListStore.MetadataListActionTypes.Metadata_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Name"),
                React.createElement(TableList.HeaderItem, null, "Type"),
                React.createElement(TableList.HeaderItem, null, "Values"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "SP Connection"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Order"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Show in Details Form"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/workspaces/metadata/edit/{GUID}" }),
                React.createElement(TableList.ContentItemCustomRender, { className: "", render: renderFieldType }),
                React.createElement(TableList.ContentItemCustomRender, { className: "", render: renderContent }),
                React.createElement(TableList.ContentItemCustomRender, { className: "text-center", render: renderConnectionStatus }),
                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Position" }),
                React.createElement(TableList.ContentItemToggle, { handleChange: function (item, checked) {
                        toggleShowInForm(item, checked);
                    }, listDataProperyName: "ShowInDetailsForm" }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/metadata/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/metadata/edit/{GUID}"
                    onRemove: function (item) { return dispatch(MetadataListStore.DeleteItem(item)); }, deleteCheckType: "metadata" })))));
});
export default MetadataList;
