import { Card, Grid, Stack } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { ActioningRecommendationsTile } from "./tiles/actioning-recommendation-tile.component";
import { MaintainingTemplatesTile } from "./tiles/maintaining-templates-tile.component";
import { ManagingGuestsTile } from "./tiles/managing-guests-tile.component";
import { ManagingWorkspacesTile } from "./tiles/managing-workspaces-tile.component";
import { ProvisioningNewWorkspacesTile } from "./tiles/provisioning-new-workspaces-tile.component";
// When a tenant is upgraded from Beacon to full Orchestry, the Beacon-specific tiles on old reports
// should still show as they were before the upgrade. This is because the report data is still relevant to
// the old tenant's Beacon license. This component renders the data with no paywall but with the old Beacon
// styling.
export var BeaconSavingsSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.YourSavings, _g = _f === void 0 ? {} : _f, TotalHours = _g.TotalHours;
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.TimeSavings, renderListOfAnnotations: true, sectionTitle: React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.your_potential_savings_section.title", defaultMessage: "Your Potential Savings with Orchestry" }) }) },
        React.createElement(Card, { w: "100%", p: "24px", style: { borderRadius: "8px", border: "1px solid var(--mantine-color-error-7)" }, variant: "shadow" },
            React.createElement(Stack, { gap: 8 },
                React.createElement(OrchestryHighlight, { pl: 16, pr: 16, isLoading: isLoading, c: "text-primary.0", fz: "18", fw: 400, lh: "28px", highlight: ["".concat(TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString())], highlightStyles: {
                        padding: 0,
                        fontWeight: 500,
                        backgroundColor: "var(--mantine-color-text-primary-0)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent"
                    } }, intl.formatMessage({ id: "page.health_check.your_potential_savings_tile.title", defaultMessage: "Total Hours Potentially Saved: {count} Hours" }, { count: TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString() })),
                React.createElement(Card, { p: 0 },
                    React.createElement(Grid, { gutter: 0, columns: 5, pb: "50px" },
                        React.createElement(Grid.Col, { span: 1, miw: 213 },
                            React.createElement(ActioningRecommendationsTile, null)),
                        React.createElement(Grid.Col, { span: 1, miw: 213 },
                            React.createElement(MaintainingTemplatesTile, null)),
                        React.createElement(Grid.Col, { span: 1, miw: 213 },
                            React.createElement(ProvisioningNewWorkspacesTile, null)),
                        React.createElement(Grid.Col, { span: 1, miw: 213 },
                            React.createElement(ManagingGuestsTile, null)),
                        React.createElement(Grid.Col, { span: 1, miw: 213 },
                            React.createElement(ManagingWorkspacesTile, null))))))));
};
