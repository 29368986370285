import * as AsyncStore from "app/store/async";
import * as LibraryFeatureListStore from "app/store/library-features/list";
export var libraryFeatureNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to features list...",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Title}</strong> in features list...",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Title}</strong> from features list...",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to features list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Add
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully updated in features list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Update
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully deleted from features list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to features list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Title}</strong> to features list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Title}</strong> from features list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Item_Delete
            },
            {
                Text: "Error when trying to load feature list.",
                ActionType: LibraryFeatureListStore.LibraryFeatureListActionTypes.LibraryFeature_List_Load
            }
        ]
    }
];
