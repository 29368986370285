export var TenantLicenseStatus;
(function (TenantLicenseStatus) {
    /**
     * Tenant has no license. Default state.
     */
    TenantLicenseStatus["Unlicensed"] = "Unlicensed";
    /**
     * Tenant has a beacon license
     */
    TenantLicenseStatus["Beacon"] = "Beacon";
    /**
     * Tenant has an orchestry trial license
     */
    TenantLicenseStatus["OrchestryTrial"] = "OrchestryTrial";
    /**
     * Tenant has an orchestry license
     */
    TenantLicenseStatus["Orchestry"] = "Orchestry";
})(TenantLicenseStatus || (TenantLicenseStatus = {}));
