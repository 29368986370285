import * as React from "react";
import { Scrollbar } from "react-scrollbars-custom";
import "./dynamic-scrollbar.scss";
//https://github.com/xobotyi/react-scrollbars-custom#usage
var DynamicScrollbar = React.memo(function (_a) {
    var shouldShow = _a.shouldShow, maxHeight = _a.maxHeight, children = _a.children, keepScrolledToBottom = _a.keepScrolledToBottom, handleScrolledBottom = _a.handleScrolledBottom, dynamicHeight = _a.dynamicHeight;
    var _b = React.useState(maxHeight), dynamicMaxHeight = _b[0], setDynamicMaxHeight = _b[1];
    var wrapperEl = React.useRef();
    var scrollEl = React.useRef();
    var _c = React.useState(false), hasScrolled = _c[0], setHasScrolled = _c[1];
    React.useEffect(function () {
        setDynamicMaxHeight(maxHeight);
    }, [maxHeight]);
    var updateScrollAreaHeight = function () {
        var newHeight = 0;
        var pageWrapper = document.querySelector("#kt_content > .kt-container");
        var pageContainer = document.querySelector("#kt_content ");
        var coords = getCoords(wrapperEl.current);
        newHeight = window.innerHeight - coords.top;
        if (pageContainer !== null && pageWrapper !== null) {
            var bottomMargin = window.getComputedStyle(pageWrapper, null).getPropertyValue("margin-bottom");
            var bottomPadding = window.getComputedStyle(pageContainer, null).getPropertyValue("padding-bottom");
            //newHeight = newHeight + (Number("-" + bottomMargin.replace("px", "")) + Number("-" + bottomPadding.replace("px", "")));
            newHeight = newHeight + (Number("-" + bottomMargin.replace("px", "")) + Number("-" + bottomPadding.replace("px", "")));
        }
        console.log("updateScrollAreaHeight", newHeight);
        if (dynamicMaxHeight !== newHeight) {
            setDynamicMaxHeight(newHeight);
        }
    };
    function getCoords(elem) {
        // crossbrowser version
        var box = elem.getBoundingClientRect();
        var body = document.body;
        var docEl = document.documentElement;
        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;
        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;
        return { top: Math.round(top), left: Math.round(left) };
    }
    React.useEffect(function () {
        if (dynamicHeight !== undefined && dynamicHeight === true) {
            if (window.innerWidth > 1000) {
            }
            updateScrollAreaHeight();
            window.addEventListener("resize", updateScrollAreaHeight);
        }
        return function () {
            window.removeEventListener("resize", updateScrollAreaHeight);
        };
    }, [dynamicHeight]);
    React.useEffect(function () {
        //let dynElement = wrapperEl.current.children[0];
        //console.log("hasScrolled",hasScrolled);
        //console.log("scrollHeight", scrollEl.current.scrollHeight);
        //console.log("clientHeight ", scrollEl.current.clientHeight);
        if (keepScrolledToBottom !== undefined && keepScrolledToBottom === true) {
            if (hasScrolled === false) {
                scrollEl.current.scrollTo(0, scrollEl.current.scrollHeight - 10);
            }
        }
    }, [children]);
    var onScroll = function (event) {
        //console.log("Scrolled stats", );
        if (scrollEl !== undefined && scrollEl !== null && scrollEl.current !== undefined && scrollEl.current !== null) {
            if (scrollEl.current.scrollTop + scrollEl.current.clientHeight === event.contentScrollHeight) {
                //console.log("scroll match", true)
                if (hasScrolled === true) {
                    setHasScrolled(false);
                }
            }
            else {
                //console.log("scroll match", false)
                if (hasScrolled === false) {
                    setHasScrolled(true);
                }
            }
            if (handleScrolledBottom !== undefined) {
                var targetScroll = scrollEl.current.scrollHeight - scrollEl.current.clientHeight;
                if (scrollEl.current.scrollTop > targetScroll - 100) {
                    handleScrolledBottom();
                }
                //console.log("scroll", scrollEl.current, scrollEl.current.scrollTop,targetScroll)
            }
        }
    };
    //console.log("shouldShow",shouldShow);
    if (shouldShow) {
        return (React.createElement("div", { ref: wrapperEl, className: "dynamic-scrollbar" },
            keepScrolledToBottom !== undefined ? (React.createElement("div", { className: "auto-scroll-indicator" },
                React.createElement("span", { className: "text" }, "AutoScroll"),
                React.createElement("span", { className: "icon" }, hasScrolled === true ? "Off" : "On"))) : null,
            React.createElement(Scrollbar, { ref: scrollEl, onScrollStop: function (event) {
                    onScroll(event);
                }, style: { minHeight: dynamicMaxHeight + "px", maxHeight: dynamicMaxHeight + "px" } }, children)));
    }
    else {
        return (React.createElement("div", { className: "dynamic-scrollbar", ref: wrapperEl }, children));
    }
});
export default DynamicScrollbar;
