var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import MembersIcon from "app/assets/img/members-icon.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import { AsyncWait } from "app/components/common/async";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalItemDynamic from "app/components/common/principal-item-dynamic";
import PrincipalSearchModal from "app/components/common/principal-search-modal";
import RequestService from "app/services/requests";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
import * as GuestManagementPolicyCurrentStore from "app/store/lifecycle-guest-policies/current";
import * as RequestCurrentStore from "app/store/requests/current";
import * as React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./request-details-users.scss";
var RequestDetailsUsers = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState({}), requestorUser = _a[0], setRequestorUser = _a[1];
    //const [owners, setOwners] = React.useState([] as User[]);
    //const [members, setMembers] = React.useState([] as User[]);
    var _b = React.useState([]), liveOwners = _b[0], setLiveOwners = _b[1];
    var _c = React.useState([]), liveMembers = _c[0], setLiveMembers = _c[1];
    var _d = React.useState(false), loadingLive = _d[0], setLoadingLive = _d[1];
    var _e = React.useState(false), guestRequestPolicyLoading = _e[0], setGuestRequestPolicyLoading = _e[1];
    var currentGuestRequestPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var _f = React.useState(false), shouldExcludeGuestsBecauseRequestPolicy = _f[0], setShouldExcludeGuestsBecauseRequestPolicy = _f[1];
    var _g = React.useState(false), shouldExcludeGuestsBecauseSensitivityLabelOrSetting = _g[0], setShouldExcludeGuestsBecauseSensitivityLabelOrSetting = _g[1];
    var _h = React.useState(false), shouldExcludeGuests = _h[0], setShouldExcludeGuests = _h[1];
    var _j = React.useState(), sensitivityConfig = _j[0], setSensitivityConfig = _j[1];
    React.useEffect(function () {
        // should be included in the owners group.
        // if (requestorUser.Id === undefined) {
        //    if (currentRequest.Requestor?.Id !== undefined) {
        //       UsersService.getUserByID(currentRequest.Requestor?.Id).then((user) => {
        //          setRequestorUser({...user as User, Locked:true, Type:"User"} as User);
        //       })
        //    }
        // }
        if (currentRequest.Owners !== undefined) {
            if (typeof currentRequest.Owners === "string") {
                var ownerString = currentRequest.Owners;
                try {
                    var ownerArray = JSON.parse(ownerString);
                    if (Array.isArray(ownerArray)) {
                        dispatch(RequestCurrentStore.Update({ Owners: ownerArray }));
                        //setRequestOwners(ownerArray);
                    }
                }
                catch (_a) { }
            }
        }
        if (currentRequest.Members !== undefined) {
            if (typeof currentRequest.Members === "string") {
                var membersString = currentRequest.Members;
                try {
                    var membersArray = JSON.parse(membersString);
                    if (Array.isArray(membersArray)) {
                        dispatch(RequestCurrentStore.Update({ Members: membersArray }));
                        // setRequestMembers(membersArray);
                    }
                }
                catch (_b) { }
            }
        }
    }, [currentRequest.Requestor, currentRequest.Owners, currentRequest.Members]);
    React.useEffect(function () {
        if (currentTemplate !== undefined && currentTemplate !== null && currentTemplate.GUID !== undefined) {
            if (currentTemplate.LiveTemplateEnabled === true) {
                setLoadingLive(true);
                RequestService.GetLiveTemplateUsers(currentTemplate.GUID).then(function (data) {
                    setLiveOwners(data.Owners);
                    setLiveMembers(data.Members);
                    setLoadingLive(false);
                });
            }
        }
    }, [currentTemplate]);
    React.useEffect(function () {
        if (currentTenantConfiguration.SensitivityLabelConfigJson !== undefined &&
            currentTenantConfiguration.SensitivityLabelConfigJson !== null &&
            currentTenantConfiguration.SensitivityLabelConfigJson !== "") {
            var tenantLabelConfig = null;
            tenantLabelConfig = JSON.parse(currentTenantConfiguration.SensitivityLabelConfigJson);
            //console.log("Setting Sen Config", tenantLabelConfig)
            setSensitivityConfig(tenantLabelConfig);
        }
    }, []);
    React.useEffect(function () {
        if (Array.isArray(currentRequest.Owners)) {
            props.SetOwnerCount(getOwners().length);
        }
        else {
            props.SetOwnerCount(liveOwners.length);
        }
        if (Array.isArray(currentRequest.Members)) {
            props.SetMembersCount(getMembers().length);
        }
        else {
            props.SetMembersCount(liveMembers.length);
        }
    }, [requestorUser, currentRequest.Owners, currentRequest.Members, liveOwners, liveMembers]);
    //}, [requestorUser, requestOwners, requestMembers, liveOwners, liveMembers]);
    var addMember = function (user) {
        var tempArray = [user];
        if (currentRequest.Members !== undefined && currentRequest.Members !== null) {
            if (currentRequest.Members.length > 0) {
                if (currentRequest.Members.some(function (u) { return (u.UserPrincipalName !== null && user.UserPrincipalName !== null ? u.UserPrincipalName === user.UserPrincipalName : u.Id === user.Id); })) {
                    tempArray = __spreadArray([], currentRequest.Members, true);
                }
                else {
                    tempArray = __spreadArray(__spreadArray([], currentRequest.Members, true), tempArray, true);
                }
            }
        }
        dispatch(RequestCurrentStore.Update({ Members: tempArray }));
        //rwc.updateCurrentRequest({ Members: tempArray });
    };
    var removeMember = function (user) {
        var _a;
        var tempArray = (_a = currentRequest.Members) === null || _a === void 0 ? void 0 : _a.filter(function (member) {
            return member.UserPrincipalName !== null && user.UserPrincipalName !== null ? member.UserPrincipalName !== user.UserPrincipalName : member.Id !== user.Id;
        });
        if (tempArray === undefined) {
            tempArray = [];
        }
        dispatch(RequestCurrentStore.Update({ Members: tempArray }));
        //rwc.updateCurrentRequest({ Members: [...tempArray] });
    };
    var addOwner = function (user) {
        var tempArray = [user];
        if (currentRequest.Owners !== undefined) {
            if (currentRequest.Owners.length > 0) {
                if (currentRequest.Owners.some(function (u) { return (u.UserPrincipalName !== null && user.UserPrincipalName !== null ? u.UserPrincipalName === user.UserPrincipalName : u.Id === user.Id); })) {
                    tempArray = __spreadArray([], currentRequest.Owners, true);
                }
                else {
                    tempArray = __spreadArray(__spreadArray([], currentRequest.Owners, true), tempArray, true);
                }
            }
        }
        dispatch(RequestCurrentStore.Update({ Owners: tempArray }));
        //rwc.updateCurrentRequest({ Owners: tempArray });
    };
    var removeOwner = function (user) {
        var _a;
        var tempArray = (_a = currentRequest.Owners) === null || _a === void 0 ? void 0 : _a.filter(function (owner) {
            return owner.UserPrincipalName !== null && user.UserPrincipalName !== null ? owner.UserPrincipalName !== user.UserPrincipalName : owner.Id !== user.Id;
        });
        if (tempArray === undefined) {
            tempArray = [];
        }
        // if (currentUser !== undefined) {
        //    if (currentUser.Id !== undefined) {
        //       if (currentUser.Id === user.Id) {
        //          rwc.setHasRemovedSelfFromOwners(true);
        //       }
        //    }
        // }
        dispatch(RequestCurrentStore.Update({ Owners: tempArray }));
        //rwc.updateCurrentRequest({ Owners: [...tempArray] });
    };
    var getOwners = function (lockedUsers) {
        var _a;
        var returnOwners = [];
        // if (requestorUser.DisplayName !== undefined && requestorUser.DisplayName !== "") {
        //    requestorUser.Locked = true;
        //    requestorUser.Type = "User";
        //    returnOwners.push(requestorUser);
        // }
        if ((((_a = currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateTeamCloneMembers) === true &&
            (currentRequest.RequestType === "Team" || (currentRequest.RequestType === "SharePoint" && currentRequest.SharePointTemplateType === "ModernGroup"))) ||
            (currentRequest.RequestType === "SharePoint" && currentRequest.SharePointTemplateType === "ModernCommunications")) {
            if (liveOwners !== undefined) {
                //console.log("Owner compare", rwc.liveOwners, owners);
                var tempOwners = liveOwners
                    .filter(function (u) { return u.UserPrincipalName !== null; })
                    .filter(function (u) { return !returnOwners.some(function (ro) { return (ro.UserPrincipalName !== null ? ro.UserPrincipalName.toLowerCase() === u.UserPrincipalName.toLowerCase() : false); }); });
                tempOwners = tempOwners.map(function (to) {
                    to.Locked = true;
                    return to;
                });
                returnOwners = __spreadArray(__spreadArray([], returnOwners, true), tempOwners, true);
            }
        }
        if (Array.isArray(currentRequest.Owners)) {
            //console.log("owners", currentRequest.Owners)
            //let tempOwners = currentRequest.Owners.filter((u) => !returnOwners.some(us => us.UserPrincipalName.toLowerCase() === u.UserPrincipalName.toLowerCase()));
            var tempOwners = currentRequest.Owners.filter(function (u) {
                var alreadyExists = false;
                returnOwners.forEach(function (o) {
                    if (o.UserPrincipalName !== null && u.UserPrincipalName !== null) {
                        alreadyExists = o.UserPrincipalName.toLowerCase() === u.UserPrincipalName.toLowerCase();
                    } //else if(o.Id !== null && u.Id !== null) {
                    //    alreadyExists = o.Id === u.Id
                    // }
                });
                return !alreadyExists;
            });
            tempOwners = tempOwners.map(function (to) {
                to.Locked = false;
                return to;
            });
            returnOwners = __spreadArray(__spreadArray([], returnOwners, true), tempOwners, true);
        }
        if (lockedUsers !== undefined) {
            returnOwners = returnOwners.filter(function (u) { return (u.Locked !== undefined && u.Locked === lockedUsers ? true : false); });
        }
        //console.log("returnOwners", returnOwners);
        return returnOwners;
    };
    var getMembers = function (lockedUsers) {
        var _a;
        var returnMembers = [];
        if ((((_a = currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateTeamCloneMembers) === true &&
            (currentRequest.RequestType === "Team" || (currentRequest.RequestType === "SharePoint" && currentRequest.SharePointTemplateType === "ModernGroup"))) ||
            (currentRequest.RequestType === "SharePoint" && currentRequest.SharePointTemplateType === "ModernCommunications")) {
            if (liveMembers !== undefined) {
                var tempMembers = liveMembers.filter(function (u) { return u.UserPrincipalName !== null; }).filter(function (u) { return !returnMembers.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
                tempMembers = tempMembers.map(function (to) {
                    to.Locked = true;
                    return to;
                });
                returnMembers = __spreadArray(__spreadArray([], returnMembers, true), tempMembers, true);
            }
        }
        //if (currentRequest !== undefined && currentRequest.Members !== undefined && currentRequest.Members !== null) {
        if (Array.isArray(currentRequest.Members)) {
            //let tempMembers = currentRequest.Members.filter((u) => !returnMembers.some(us => us.UserPrincipalName === u.UserPrincipalName));
            var tempMembers = currentRequest.Members.filter(function (u) {
                var alreadyExists = false;
                returnMembers.forEach(function (m) {
                    if (m.UserPrincipalName !== null && u.UserPrincipalName !== null) {
                        alreadyExists = m.UserPrincipalName.toLowerCase() === u.UserPrincipalName.toLowerCase();
                    } //else if(m.Id !== null && u.Id !== null) {
                    //alreadyExists = m.Id === u.Id
                    //}
                });
                return !alreadyExists;
            });
            tempMembers = tempMembers.map(function (to) {
                to.Locked = false;
                return to;
            });
            returnMembers = __spreadArray(__spreadArray([], returnMembers, true), tempMembers, true);
        }
        if (lockedUsers !== undefined) {
            returnMembers = returnMembers.filter(function (u) { return (u.Locked !== undefined && u.Locked === lockedUsers ? true : false); });
        }
        return returnMembers;
    };
    React.useEffect(function () {
        if (props.EditModeOn === true) {
            // Look for guest request policy
            if (currentTemplate !== undefined && currentTemplate !== null && currentTemplate.GUID !== undefined && currentTemplate.GUID !== null) {
                var guestRequestPolicyGuid = null;
                if (currentTemplate.TemplateType === "Team" || (currentTemplate.TemplateType === "SharePoint" && currentTemplate.SharePointTemplateType === "ModernGroup")) {
                    if (currentTemplate.GuestRequestPolicyGuid !== null) {
                        guestRequestPolicyGuid = currentTemplate.GuestRequestPolicyGuid;
                    }
                    else if (currentTenantConfiguration.LCGuestRequestDefaultEnabled === true) {
                        if (currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID !== null && currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID !== "") {
                            guestRequestPolicyGuid = currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID;
                        }
                    }
                }
                if (guestRequestPolicyGuid !== null) {
                    setGuestRequestPolicyLoading(true);
                    dispatch(GuestManagementPolicyCurrentStore.Load(LifecycleGuestPolicyType.Request, guestRequestPolicyGuid));
                    AsyncWait(AsyncCallType.Get, AsyncStatus.Succeeded, undefined, undefined, "LifecycleGuestPolicy_Current_Load").then(function () {
                        setGuestRequestPolicyLoading(false);
                    });
                }
                else {
                    setGuestRequestPolicyLoading(false);
                    dispatch(GuestManagementPolicyCurrentStore.Clear());
                }
            }
        }
    }, [props.EditModeOn]);
    React.useEffect(function () {
        if (currentGuestRequestPolicy !== null && currentGuestRequestPolicy.GUID !== undefined && currentGuestRequestPolicy.GUID !== null) {
            setShouldExcludeGuestsBecauseRequestPolicy(true);
        }
        else {
            setShouldExcludeGuestsBecauseRequestPolicy(false);
        }
    }, [currentGuestRequestPolicy]);
    React.useEffect(function () {
        var excludeGuests = false;
        if ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupAllowToAddGuests) === false) {
            excludeGuests = true;
        }
        else if (currentRequest.SensitivityLabel !== undefined && currentRequest.SensitivityLabel !== null) {
            var senLabel = JSON.parse(currentRequest.SensitivityLabel);
            if (senLabel.Config !== undefined && senLabel.Config.GroupUserAccessOverride !== undefined && senLabel.Config.GroupUserAccessOverride === false) {
                excludeGuests = true;
            }
            console.log("senLabel", senLabel);
        }
        setShouldExcludeGuestsBecauseSensitivityLabelOrSetting(excludeGuests);
    }, [currentRequest.SensitivityLabel, currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigGroupAllowToAddGuests]);
    React.useEffect(function () {
        if (shouldExcludeGuestsBecauseRequestPolicy === true || shouldExcludeGuestsBecauseSensitivityLabelOrSetting === true) {
            setShouldExcludeGuests(true);
        }
        else {
            setShouldExcludeGuests(false);
        }
    }, [shouldExcludeGuestsBecauseRequestPolicy, shouldExcludeGuestsBecauseSensitivityLabelOrSetting]);
    React.useEffect(function () {
        console.log("setShouldExcludeGuests", shouldExcludeGuests);
    }, [shouldExcludeGuests]);
    return (React.createElement(Portlet, { fluidHeight: true, className: "request-details-users owner-members" + (props.EditModeOn ? " edit-mode-on" : " edit-mode-off") },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.users.users_label", defaultMessage: "Users" }) }),
        React.createElement(PortletBody, null,
            Array.isArray(currentRequest.Owners) ? (React.createElement("div", { className: "kt-section", style: { margin: "0" } },
                React.createElement("div", { className: "kt-section__title title-add" },
                    React.createElement("span", { className: "title" },
                        React.createElement(FormattedMessage, { id: "page.request_details.users.owners", defaultMessage: "Owners" }),
                        " ",
                        React.createElement("span", { className: "count" },
                            "(",
                            React.createElement("span", { className: "num-pad" },
                                React.createElement(FormattedNumber, { value: getOwners().length })),
                            ")")),
                    React.createElement(PrincipalSearchModal, { suggestedTitle: intl.formatMessage({ id: "page.request_details.users.suggested_owners", defaultMessage: "Suggested Owners" }), mode: "usersAndGroups", title: intl.formatMessage({ id: "page.request_details.users.add_owners", defaultMessage: "Add Owners" }), selectedPrincipals: getOwners(), onPrincipalSelected: function (principal) {
                            addOwner(principal);
                        }, onPrinciaplRemoved: function (principal) {
                            removeOwner(principal);
                        } },
                        React.createElement("div", { className: "add-action" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "la la-plus" })),
                            React.createElement("div", { className: "text" },
                                React.createElement(FormattedMessage, { id: "page.request_details.users.add_owners", defaultMessage: "Add Owners" }))))),
                React.createElement("div", { className: "kt-section__content user-content" },
                    React.createElement("div", { className: "kt-widget31 " },
                        loadingLive === false && getOwners().length === 0 ? (React.createElement("div", { className: "no-results" },
                            React.createElement("div", { className: "no-icon-text" },
                                React.createElement("img", { src: MembersIcon }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.users.no_owners_yet", defaultMessage: "No owners yet" }))))) : null,
                        getOwners(false).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeOwner(principal); } })); }),
                        getOwners(true).filter(function (u) { return (u.UserPrincipalName !== (requestorUser === null || requestorUser === void 0 ? void 0 : requestorUser.UserPrincipalName) ? true : false); }).length > 0 ? (React.createElement("div", { className: "default-users-divider" },
                            React.createElement(FormattedMessage, { id: "page.request_details.users.default_owners", defaultMessage: "Default Owners" }))) : null,
                        loadingLive ? React.createElement(AnimatedLoader, { size: "xxsmall", text: intl.formatMessage({ id: "page.request_details.users.loading_users", defaultMessage: "Loading template users" }) }) : null,
                        getOwners(true)
                            .filter(function (u) { return (u.UserPrincipalName !== (requestorUser === null || requestorUser === void 0 ? void 0 : requestorUser.UserPrincipalName) ? true : false); })
                            .map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeOwner(principal); } })); }))))) : null,
            React.createElement("div", { className: "kt-separator kt-separator--space-lg kt-separator--border-dashed" }),
            React.createElement("div", { className: "kt-section", style: { margin: "0" } },
                React.createElement("div", { className: "kt-section__title title-add" },
                    React.createElement("span", { className: "title" },
                        React.createElement(FormattedMessage, { id: "page.request_details.users.members", defaultMessage: "Members" }),
                        " ",
                        React.createElement("span", { className: "count" },
                            "(",
                            React.createElement("span", { className: "num-pad" },
                                React.createElement(FormattedNumber, { value: getMembers().length })),
                            ")")),
                    React.createElement(PrincipalSearchModal, { suggestedTitle: intl.formatMessage({ id: "page.request_details.users.suggested_members", defaultMessage: "Suggested Members" }), title: intl.formatMessage({ id: "page.request_details.users.add_members", defaultMessage: "Add Members" }), mode: "usersAndGroups", excludeGuests: shouldExcludeGuests, selectedPrincipals: getMembers(), onPrincipalSelected: function (principal) {
                            addMember(principal);
                        }, onPrinciaplRemoved: function (principal) {
                            removeMember(principal);
                        } },
                        React.createElement("div", { className: "add-action" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "la la-plus" })),
                            React.createElement("div", { className: "text" },
                                React.createElement(FormattedMessage, { id: "page.request_details.users.add_members", defaultMessage: "Add Members" }))))),
                React.createElement("div", { className: "kt-section__content user-content" },
                    React.createElement("div", { className: "kt-widget31 " },
                        loadingLive === false && getMembers().length === 0 ? (React.createElement("div", { className: "no-results" },
                            React.createElement("div", { className: "no-icon-text" },
                                React.createElement("img", { src: MembersIcon }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.users.no_members_yet", defaultMessage: "No members yet" }))))) : null,
                        getMembers(false).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeMember(principal); } })); }),
                        getMembers(true).length > 0 ? (React.createElement("div", { className: "default-users-divider" },
                            React.createElement(FormattedMessage, { id: "page.request_details.users.default_members", defaultMessage: "Default Members" }))) : null,
                        loadingLive ? React.createElement(AnimatedLoader, { size: "xxsmall", text: intl.formatMessage({ id: "page.request_details.users.loading_users", defaultMessage: "Loading template users" }) }) : null,
                        getMembers(true).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeMember(principal); } })); })))),
            currentRequest.Guests !== undefined && currentRequest.Guests !== null && currentRequest.Guests.length > 0 ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "kt-separator kt-separator--space-lg kt-separator--border-dashed" }),
                React.createElement("div", { className: "kt-section", style: { margin: "0" } },
                    React.createElement("div", { className: "kt-section__title title-add" },
                        React.createElement("span", { className: "title" },
                            React.createElement(FormattedMessage, { id: "page.request_details.users.guests", defaultMessage: "Guests" }),
                            " ",
                            React.createElement("span", { className: "count" },
                                "(",
                                React.createElement("span", { className: "num-pad" },
                                    React.createElement(FormattedNumber, { value: currentRequest.Guests.length })),
                                ")"))),
                    React.createElement("div", { className: "kt-section__content user-content" },
                        React.createElement("div", { className: "kt-widget31 " }, currentRequest.Guests.map(function (user) { return (React.createElement(PrincipalItemDynamic, { principalIdOrEmail: user.UserId, hideGuestNote: true })
                        // <>{user.UserId}</>
                        ); })))))) : null)));
});
export default RequestDetailsUsers;
