import AnimatedLoader from "app/components/common/animated-loader";
import FormToggleControl from "app/components/common/form-toggle-control";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalSearchModal from "app/components/common/principal-search-modal";
import { SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import GroupSettingsService from "app/services/group-settings";
import GroupsService from "app/services/groups";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "react-tagsinput/react-tagsinput.css";
import "./settings-groups.scss";
var SettingsGroups = React.memo(function () {
    var _a = React.useState(false), anyEdits = _a[0], setAnyEdits = _a[1];
    var _b = React.useState(), directoryValues = _b[0], setDirectoryValues = _b[1];
    var _c = React.useState(""), startDirectoryValuesString = _c[0], setStartDirectoryValuesString = _c[1];
    var _d = React.useState(), directoryTemplateValues = _d[0], setDirectoryTemplateValues = _d[1];
    var _e = React.useState(), groupForCreation = _e[0], setGroupForCreation = _e[1];
    var _f = React.useState(), loadingGroupForCreation = _f[0], setLoadingGroupForCreation = _f[1];
    React.useEffect(function () {
        GroupSettingsService.getGlobalSettings().then(function (data) {
            setDirectoryValues(data);
            setStartDirectoryValuesString(JSON.stringify(data));
        });
        GroupSettingsService.getGlobalSettingTemplates().then(function (data) {
            setDirectoryTemplateValues(data);
        });
    }, []);
    React.useEffect(function () {
        if (groupForCreation === undefined) {
            setDirectoryValue("GroupCreationAllowedGroupId", "");
        }
        else if (groupForCreation.length > 0) {
            setDirectoryValue("GroupCreationAllowedGroupId", groupForCreation[0].Id);
        }
    }, [groupForCreation]);
    React.useEffect(function () {
        console.log("directoryValues", directoryValues);
        if (groupForCreation === undefined) {
            var potentialGroupId = getDirectoryValue("GroupCreationAllowedGroupId");
            if (potentialGroupId !== null && potentialGroupId !== "") {
                setLoadingGroupForCreation(true);
                GroupsService.getGroup(potentialGroupId).then(function (group) {
                    setLoadingGroupForCreation(false);
                    if (group !== null) {
                        setGroupForCreation([group]);
                    }
                });
            }
            else {
                setLoadingGroupForCreation(false);
            }
        }
        else {
            setLoadingGroupForCreation(false);
        }
        checkIfSaveable();
    }, [directoryValues]);
    var getDirectoryValue = function (key) {
        return GroupSettingsService.getDirectoryValue(key);
    };
    var getDirectoryValueDescription = function (key) {
        return GroupSettingsService.getDirectoryValueDescription(key);
    };
    var setDirectoryValue = function (key, value) {
        setDirectoryValues(GroupSettingsService.setDirectoryValue(key, value));
    };
    // Checks for edits
    var checkIfSaveable = function () {
        if (directoryValues !== undefined) {
            if (startDirectoryValuesString !== "") {
                //console.log(JSON.stringify(currentTenantConfigInternalState), "\n\n\n", JSON.stringify(currentTenantConfiguration));
                if (JSON.stringify(directoryValues) !== startDirectoryValuesString) {
                    //console.log("Does Match",false);
                    if (anyEdits === false) {
                        setAnyEdits(true);
                    }
                }
            }
        }
    };
    // On save, save to server and set save button back to non-edited.
    var save = function () {
        if (directoryValues !== undefined) {
            GroupSettingsService.setGlobalSettings(directoryValues).then(function () { });
        }
        setAnyEdits(false);
    };
    return (React.createElement("div", { className: "settings-groups fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Settings" }),
        React.createElement(PageHeader.SetRightContent, null, anyEdits === true ? (React.createElement("div", { onClick: function () { return save(); }, style: { display: "inline-block" } },
            React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
            React.createElement(SubtitleButton, { title: "Save", variant: "secondary" })))),
        directoryValues !== undefined && directoryTemplateValues !== undefined && getDirectoryValue("EnableGroupCreation") !== null ? (React.createElement(Row, null,
            React.createElement(Col, { md: 6 },
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Global Group Settings" }),
                    React.createElement(PortletBody, null,
                        getDirectoryValue("EnableGroupCreation") !== null && (React.createElement("div", { className: "group-creation-toggle custom-toggle" },
                            React.createElement("div", { className: "description" }, getDirectoryValueDescription("EnableGroupCreation")),
                            React.createElement(FormToggleControl, { checked: getDirectoryValue("EnableGroupCreation"), label: "Enable Group and Team Creation", handleChange: function (checked) { return setDirectoryValue("EnableGroupCreation", checked); } }))),
                        getDirectoryValue("AllowGuestsToAccessGroups") !== null && (React.createElement("div", { className: "custom-toggle" },
                            React.createElement("div", { className: "description" }, getDirectoryValueDescription("AllowGuestsToAccessGroups")),
                            React.createElement(FormToggleControl, { checked: getDirectoryValue("AllowGuestsToAccessGroups"), label: "Allow Guests To Access Groups", handleChange: function (checked) { return setDirectoryValue("AllowGuestsToAccessGroups", checked); } }))),
                        getDirectoryValue("AllowGuestsToBeGroupOwner") !== null && (React.createElement("div", { className: "custom-toggle" },
                            React.createElement("div", { className: "description" }, getDirectoryValueDescription("AllowGuestsToBeGroupOwner")),
                            React.createElement(FormToggleControl, { checked: getDirectoryValue("AllowGuestsToBeGroupOwner"), label: "Allow Guests To Be Group Owner", handleChange: function (checked) { return setDirectoryValue("AllowGuestsToBeGroupOwner", checked); } }))),
                        getDirectoryValue("AllowToAddGuests") !== null && (React.createElement("div", { className: "custom-toggle" },
                            React.createElement("div", { className: "description" }, getDirectoryValueDescription("AllowToAddGuests")),
                            React.createElement(FormToggleControl, { checked: getDirectoryValue("AllowToAddGuests"), label: "Allow To Add Guests", handleChange: function (checked) { return setDirectoryValue("AllowToAddGuests", checked); } }))),
                        getDirectoryValue("EnableMIPLabels") !== null && (React.createElement("div", { className: "custom-toggle" },
                            React.createElement("div", { className: "description", style: { marginBottom: "0px" } }, getDirectoryValueDescription("EnableMIPLabels")),
                            React.createElement("div", { className: "description", style: { fontStyle: "italic" } },
                                "Use the ",
                                React.createElement(NavLink, { to: "/settings/classifications-and-labels" }, "Sensitivity Labels and Classifications configuration page"),
                                " to enable their use in Orchestry"),
                            React.createElement(FormToggleControl, { checked: getDirectoryValue("EnableMIPLabels"), label: "Enable Sensitivity (MIP) Labels", handleChange: function (checked) { return setDirectoryValue("EnableMIPLabels", checked); } }))),
                        getDirectoryValue("UsageGuidelinesUrl") !== null && (React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Usage Guidelines Url"),
                            React.createElement("div", { className: "description" }, getDirectoryValueDescription("UsageGuidelinesUrl")),
                            React.createElement(Form.Control, { defaultValue: getDirectoryValue("UsageGuidelinesUrl"), onChange: function (event) { return setDirectoryValue("UsageGuidelinesUrl", event.target.value); } }))),
                        getDirectoryValue("GroupCreationAllowedGroupId") !== null && (React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Group Creation Allowed Group Id"),
                            React.createElement("div", { className: "description" }, getDirectoryValueDescription("GroupCreationAllowedGroupId")),
                            React.createElement("div", { className: "group-area form-control" }, loadingGroupForCreation === undefined || loadingGroupForCreation === true ? (React.createElement(AnimatedLoader, { size: "small" })) : (React.createElement(React.Fragment, null,
                                groupForCreation !== undefined &&
                                    groupForCreation.map(function (group) { return React.createElement(PrincipalItem, { principal: group, showBadge: true, onRemove: function () { return setGroupForCreation(undefined); } }); }),
                                groupForCreation === undefined || groupForCreation.length === 0 ? (React.createElement(PrincipalSearchModal, { suggestedTitle: "Suggested Users", mode: "groups", title: "Select Group", selectedPrincipals: groupForCreation, onPrincipalSelected: function (principal) {
                                        setGroupForCreation([principal]);
                                    }, onPrinciaplRemoved: function (principal) {
                                        setGroupForCreation(undefined);
                                    } },
                                    React.createElement("div", { className: "add-action" },
                                        React.createElement("div", { className: "add-button" },
                                            React.createElement("i", { className: "fa fa-plus" }),
                                            React.createElement("span", null, "Select Group"))))) : null)))))))))) : (React.createElement(Row, null,
            React.createElement(Col, { md: 6 },
                React.createElement(SkeletonLoaderPortlet, { bodyHeight: 500 }))))));
});
export default SettingsGroups;
