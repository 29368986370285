import * as React from "react";
var LifecycleStrings = React.memo(function (_a) {
    var type = _a.type, value = _a.value;
    var getString = function () {
        var returnString = "";
        if (type === "ArchivalReason") {
            if (value === "OrchestryDelete") {
                returnString = "Delete";
            }
            if (value === "OrchestryCopyContentOnly") {
                returnString = "Copy to Folder";
            }
            if (value === "OrchestryInPlace") {
                returnString = "In-Place";
            }
            if (value === "OrchestryCopy") {
                returnString = "Copy to Subsite";
            }
            if (value === "Microsoft365Archived") {
                returnString = "Microsoft 365 Archived";
            }
        }
        if (type === "SecondStepArchivalMethod") {
            if (value === "MoveContentOnlyToDifferentSiteLibrary") {
                returnString = "Copy to Folder";
            }
            if (value === "Delete") {
                returnString = "Delete";
            }
        }
        return returnString;
    };
    return React.createElement("span", { className: "lifecycle-string" }, getString());
});
export default LifecycleStrings;
