import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
var ReportFilterDate = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("div", { className: "date-title" }, "After"),
                React.createElement(Form.Control, { type: "date", value: props.Refiner.CurrentValue !== null ? props.Refiner.CurrentValue.split("|")[0] : "", onChange: function (event) {
                        return props.FilterOnChange(props.Refiner, {
                            from: event.target.value
                        });
                    } })),
            React.createElement(Col, null,
                React.createElement("div", { className: "date-title" }, "Before"),
                React.createElement(Form.Control, { type: "date", value: props.Refiner.CurrentValue !== null ? props.Refiner.CurrentValue.split("|")[1] : "", onChange: function (event) {
                        return props.FilterOnChange(props.Refiner, {
                            to: event.target.value
                        });
                    } })))));
});
export default ReportFilterDate;
