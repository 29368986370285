import React from "react";
import { Route, Switch } from "react-router-dom";
import RequestList from "app/components/requests/request-list";
import RequestQueue from "app/components/requests/request-queue";
import RequestDetails from "app/components/requests/request-details";
import { RequestWizardInsideOrchestry } from "app/components/requests/request-wizard";
export default function RequestPages() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/requests", component: RequestList }),
        React.createElement(Route, { exact: true, path: "/requests/new" },
            React.createElement(RequestWizardInsideOrchestry, null)),
        React.createElement(Route, { exact: true, path: "/requests/queue" },
            React.createElement(RequestQueue, null)),
        React.createElement(Route, { exact: true, path: "/requests/view/:guid/:action?", component: RequestDetails })));
}
