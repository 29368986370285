import SharePointService from "app/services/sharepoint";
import * as React from "react";
import AnimatedLoader from "../animated-loader";
import "./mananged-metadata-value.scss";
var ManagedMetadataValue = React.memo(function (props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var _b = React.useState(""), loadingId = _b[0], setLoadingId = _b[1];
    var _c = React.useState(), loadedNode = _c[0], setLoadedNode = _c[1];
    var MetadataTreeType = [
        {
            Type: "TermGroup",
            FaIcon: "la-folder"
        },
        {
            Type: "TermSet",
            FaIcon: "la-tags"
        },
        {
            Type: "Term",
            FaIcon: "la-tag"
        }
    ];
    React.useEffect(function () {
        if (loadedNode === undefined || (loadedNode !== undefined && loadedNode.Id !== props.node.Id)) {
            if (loadingId === "" || loadingId !== props.node.Id) {
                setLoading(true);
                setLoadingId(props.node.Id);
                if (props.node.NodeType === "TermSet") {
                    SharePointService.GetTaxonomyTermSet(props.node.Id).then(function (node) {
                        setLoading(false);
                        setLoadedNode(node);
                        setLoadingId("");
                    });
                }
                else {
                    SharePointService.GetTaxonomyTerm(props.node.Id).then(function (node) {
                        setLoading(false);
                        setLoadedNode(node);
                        setLoadingId("");
                    });
                }
            }
        }
    }, [props.node]);
    var getNodeIcon = function (node) {
        var matchType = MetadataTreeType.filter(function (tt) { return tt.Type === node.NodeType; })[0];
        return React.createElement("i", { className: "la " + (matchType === null || matchType === void 0 ? void 0 : matchType.FaIcon) });
    };
    var renderPathForName = function () {
        if (loadedNode !== undefined) {
            if (loadedNode.NodeType === "TermSet") {
                return (React.createElement(React.Fragment, null,
                    getNodeIcon({ NodeType: "TermGroup" }),
                    " ",
                    React.createElement("span", null, loadedNode.TermGroupName),
                    React.createElement("span", { className: "path-seperator" },
                        React.createElement("i", { className: "fas fa-chevron-right" })),
                    React.createElement(React.Fragment, null,
                        getNodeIcon(loadedNode),
                        " ",
                        React.createElement("span", null, loadedNode.Name))));
            }
            else if (loadedNode.NodeType === "Term") {
                return (React.createElement(React.Fragment, null,
                    getNodeIcon({ NodeType: "TermGroup" }),
                    " ",
                    React.createElement("span", null, loadedNode.TermGroupName),
                    React.createElement("span", { className: "path-seperator" },
                        React.createElement("i", { className: "fas fa-chevron-right" })),
                    getNodeIcon({ NodeType: "TermSet" }),
                    " ",
                    React.createElement("span", null, loadedNode.TermSetName),
                    React.createElement("span", { className: "path-seperator" },
                        React.createElement("i", { className: "fas fa-chevron-right" })),
                    React.createElement(React.Fragment, null,
                        getNodeIcon(loadedNode),
                        " ",
                        React.createElement("span", null, loadedNode.Name))));
                // return <>{loadedNode.PathOfTerm}</>
            }
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement("div", { className: "mananged-metadata-value" }, loading === true ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : loadedNode !== undefined ? (props.showFullPath !== undefined && props.showFullPath === true ? (renderPathForName()) : (React.createElement(React.Fragment, null,
        getNodeIcon(loadedNode),
        " ",
        React.createElement("span", { className: "metadata-name" }, loadedNode.Name)))) : null));
});
export default ManagedMetadataValue;
