import { Group, Stack } from "@mantine/core";
import MICROSOFT_SVG_ICONS from "appv2/assets/icons/microsoft-svgs/microsoft-svg-icons";
import { OrchestryColorLabel } from "appv2/components/orchestry-color-label/orchestry-color-label.component";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestrySVGIcon } from "appv2/components/orchestry-svg-icon/orchestry-svg-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useGrowthDonutChartTile } from "./use-growth-donut-chart-tile";
export var GrowthDonutChartTileLegendSharePointSection = function () {
    var _a = useGrowthDonutChartTile(), intl = _a.intl, isLoading = _a.isLoading, teamSitesData = _a.teamSitesData, communicationSitesData = _a.communicationSitesData, classicSitesData = _a.classicSitesData;
    return (React.createElement(Stack, { gap: 4 },
        React.createElement(Group, { gap: 8 },
            React.createElement(OrchestrySVGIcon, { isLoading: isLoading, iconNeutral: MICROSOFT_SVG_ICONS.Sharepoint, size: "md" }),
            React.createElement(OrchestryText, { isLoading: isLoading, fw: 500, c: "text-secondary.0", fz: "14", lh: "20px" }, intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.sharepoint_section.title", defaultMessage: "SharePoint" }))),
        React.createElement(OrchestryDivider, { margin: "0" }),
        React.createElement(Stack, { gap: 0 },
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "#037F83", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.teams_sites", defaultMessage: "Teams Sites" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: teamSitesData === null || teamSitesData === void 0 ? void 0 : teamSitesData.Total, deltaPercentage: teamSitesData === null || teamSitesData === void 0 ? void 0 : teamSitesData.DeltaPercentage, deltaValue: teamSitesData === null || teamSitesData === void 0 ? void 0 : teamSitesData.DeltaValue })),
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "#26B0BC", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.communication_sites", defaultMessage: "Comm Sites" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: communicationSitesData === null || communicationSitesData === void 0 ? void 0 : communicationSitesData.Total, deltaPercentage: communicationSitesData === null || communicationSitesData === void 0 ? void 0 : communicationSitesData.DeltaPercentage, deltaValue: communicationSitesData === null || communicationSitesData === void 0 ? void 0 : communicationSitesData.DeltaValue })),
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "#85BFC2", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.classic_sites", defaultMessage: "Classic Sites" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: classicSitesData === null || classicSitesData === void 0 ? void 0 : classicSitesData.Total, deltaPercentage: classicSitesData === null || classicSitesData === void 0 ? void 0 : classicSitesData.DeltaPercentage, deltaValue: classicSitesData === null || classicSitesData === void 0 ? void 0 : classicSitesData.DeltaValue })))));
};
