var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * Returns an array of unique filter groups from the given array of filters.
 * @param initialFilters - The array of filters to extract filter groups from.
 * @returns An array of unique filter groups.
 */
export var getFilterGroups = function (initialFilters) {
    return initialFilters.reduce(function (groups, filter) {
        if (filter.group && !groups.includes(filter.group)) {
            groups.push(filter.group);
        }
        return groups;
    }, []);
};
/**
 * Gets the filter object for the given value from the given array of filters.
 *
 * @param value The value to find the filter for
 * @param filters The array of filters to search
 */
export var getFilter = function (value, filters) { return filters.find(function (filter) { return filter.value === value; }); };
/**
 * Groups the given array of filters by their group property.
 *
 * @param filters The array of filters to group
 */
export var groupFilters = function (filters) {
    return filters.reduce(function (groups, filter) {
        var _a = filter.group, group = _a === void 0 ? "default" : _a, rest = __rest(filter, ["group"]);
        if (!groups[group]) {
            groups[group] = [];
        }
        groups[group].push(rest);
        return groups;
    }, {});
};
