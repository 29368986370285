var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
/**
 * Hook to get and set the display mode from the URL.
 */
export var useURLParamDisplayMode = function () {
    var _a;
    var location = useLocation();
    var history = useHistory();
    var search = location.search;
    var queryParams = new URLSearchParams(search);
    var displayMode = ((_a = queryParams.get("displayMode")) !== null && _a !== void 0 ? _a : "view");
    var setDisplayMode = useRef(function (newDisplayMode) {
        var newQueryParams = new URLSearchParams(search);
        newQueryParams.set("displayMode", newDisplayMode);
        history.replace(__assign(__assign({}, location), { search: newQueryParams.toString() }));
    }).current;
    return { displayMode: displayMode, setDisplayMode: setDisplayMode };
};
