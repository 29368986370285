import * as AsyncStore from "app/store/async";
import * as LifecyclePolicyListStore from "app/store/lifecycle-policies/list";
export var lifecyclePoliciesNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Name}</strong> to Renewal / Archival policies list...",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Name}</strong> in Renewal / Archival policies list...",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Name}</strong> from Renewal / Archival policies list...",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Name}</strong> successfully added to Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add
            },
            {
                Text: "<strong>{Item.Name}</strong> successfully updated in Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update
            },
            {
                Text: "<strong>{Item.Name}</strong> successfully deleted from Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Name}</strong> to Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Name}</strong> to Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Name}</strong> from Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Delete
            },
            {
                Text: "Error when trying to load Renewal / Archival policies list.",
                ActionType: LifecyclePolicyListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load
            }
        ]
    }
];
