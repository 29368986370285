import * as React from "react";
import "./recommended-actions.scss";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { useHistory } from "react-router-dom";
var DashboardRecommendedActions = React.memo(function (props) {
    var history = useHistory();
    var showRedemptions = false;
    var isLoading = false;
    return (React.createElement("div", { className: "recommended-actions", "data-testid": "recommended-actions-container", "data-pw": "recommended-actions" },
        React.createElement("div", { className: "recommended-actions-header" },
            React.createElement("h4", { "data-testid": "header-title", "data-pw": "header-title" }, "Recommended Actions")),
        React.createElement("div", { className: "recommended-actions-body" }, isLoading ? (React.createElement(React.Fragment, null,
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 120, bodyWidth: 390, marginBottom: 10 }),
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 120, bodyWidth: 390, marginBottom: 10 }),
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 120, bodyWidth: 390, marginBottom: 10 }))) : (React.createElement(React.Fragment, null,
            props.Count_RecommendedDeletions > 0 ? (React.createElement(Card, null,
                React.createElement("span", { className: "icon-container fa-stack fa-xl" },
                    React.createElement("i", { className: "fa fa-circle fa-stack-2x icon-bg" }),
                    React.createElement("i", { className: "fa fa-trash-can-xmark fa-sm fa-stack-1x" })),
                React.createElement(Card.Body, null,
                    React.createElement(Card.Title, null,
                        props.Count_RecommendedDeletions,
                        " Guest Account Deletion",
                        props.Count_RecommendedDeletions > 0 ? "s" : ""),
                    "Guest accounts should be deleted based on inactivity, guest access policy violation or because they have no current access.",
                    React.createElement("div", { className: "button-wrapper" },
                        React.createElement(Button, { onClick: function () { return history.push("/guests/listing/insight/guests-recommended-for-deletion"); } },
                            React.createElement("i", { className: "fa-regular fa-check" }),
                            " Review"))))) : null,
            showRedemptions ? (React.createElement(React.Fragment, null,
                React.createElement(Card, null,
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null, "12 Guest Redemption Reminders"),
                        "Several accounts are awaiting redemption by the guest for over 14 days and may need a reminder issued.",
                        React.createElement("div", { className: "button-wrapper" },
                            React.createElement(Button, { onClick: function () { return history.push("/guests/listing/insight/guests-not-redeemed"); } },
                                React.createElement("i", { className: "fa-regular fa-check" }),
                                " Review")))),
                React.createElement(Card, null,
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null, "12 Guest Redemption Reminders"),
                        "Several accounts are awaiting redemption by the guest for over 14 days and may need a reminder issued.",
                        React.createElement("div", { className: "button-wrapper" },
                            React.createElement(Button, { onClick: function () { return history.push("/guests/listing/insight/guests-not-redeemed"); } },
                                React.createElement("i", { className: "fa-regular fa-check" }),
                                " Review")))))) : null)))));
});
export default DashboardRecommendedActions;
