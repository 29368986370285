import * as React from "react";
import "./policy-execution-card.scss";
import * as GuestLifecyclePolicyCurrentStore from "app/store/lifecycle-guest-policies/current/";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import { ExecutionApprovers, GuestLifecycleTimeUnit } from "app/store/lifecycle-guest-policies/types";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import FormWebhook from "app/components/common/form-webhook";
import PortletAlert from "app/components/common/portlet-alert";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import { initialRequestNotificationText } from "app/store/lifecycle-guest-policies/current/constants";
var StepCardPolicyExecution = React.memo(function (props) {
    var _a, _b;
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "step-card-guest-request-policy-execution policy-card", "data-pw": "guest-request-policy-execution" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement("div", { className: "middle card-border" },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        !currentPolicy.AllowGuestAccessForWorkspaces ? (React.createElement("div", { className: "execution-not-required" },
                            React.createElement("h4", null, "Policy Execution"),
                            React.createElement("span", { className: "description-text" },
                                React.createElement(PortletAlert, { icon: "info", message: React.createElement("p", { className: "text" },
                                        "The setting on the Guest Request tab has disabled guest access to Workspaces - ",
                                        React.createElement("u", null, "policy execution is not required"),
                                        ". To configure Policy Execution, change the setting to permit guests."), type: "warning" })))) : null,
                        React.createElement(Collapse, { in: currentPolicy.AllowGuestAccessForWorkspaces },
                            React.createElement("div", { className: "main-container" },
                                React.createElement(Form.Group, { className: "form-group", controlId: "policy-approval" },
                                    React.createElement(Row, { className: "align-items-center" },
                                        React.createElement(Col, null,
                                            React.createElement("div", { className: "policy-approval-container", "data-pw": "policy-approval-container" },
                                                React.createElement("h4", null, "Policy Approvers"),
                                                React.createElement("p", null, "Requests will neither be accepted nor rejected automatically. Approvers will need to review incoming guest requests made through Orchestry."),
                                                React.createElement(Row, { className: "align-items-center", "data-pw": "approvers" },
                                                    React.createElement(Col, null,
                                                        React.createElement("div", { className: "label" },
                                                            "Approvers ",
                                                            React.createElement("span", { className: "required-astrix" }, "*")),
                                                        React.createElement(FormDropdownSelect, { list: [
                                                                {
                                                                    id: "delegatetoowners",
                                                                    value: ExecutionApprovers.WorkspaceOwners,
                                                                    title: "Delegate to Workspace Owners"
                                                                },
                                                                { id: "specificusers", value: ExecutionApprovers.SpecificUsers, title: "Specific Users/Groups" }
                                                            ], onChange: function (value) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestApprovers: value })); }, value: currentPolicy.RequestApprovers }),
                                                        React.createElement(FormValidator, { FieldName: "Guest Approval Requirement", UniqueIdentifier: "require-approvals", Validators: ["dropdown-has-value"], Value: currentPolicy.RequestApprovers, Enabled: currentPolicy.AllowGuestAccessForWorkspaces, OnScreen: props.IsCurrent }))),
                                                React.createElement(Collapse, { in: currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers },
                                                    React.createElement("div", null,
                                                        React.createElement(Row, { className: "user-select-row align-items-center" },
                                                            React.createElement(Col, null,
                                                                React.createElement(FormUserGroupSelect, { displayAddAsButton: true, onChange: function (groups) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestSpecificApprovers: groups })); }, returnType: "usersAndGroups", allowMultiple: true, defaultValue: currentPolicy.RequestSpecificApprovers }),
                                                                React.createElement(FormValidator, { FieldName: "Assign and Send To User(s) or Group(s)", UniqueIdentifier: "policy-approval", Validators: ["string-not-empty", "string-array-has-items"], Enabled: currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers, Value: currentPolicy.RequestSpecificApprovers }))),
                                                        React.createElement(Row, { className: "align-items-center" },
                                                            React.createElement(Col, null,
                                                                React.createElement("div", { className: "label" },
                                                                    "Notification Text ",
                                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                                React.createElement(MarkdownEditor, { onChange: function (value) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestNotificationText: value })); }, enabledStyles: { all: true }, templateText: currentPolicy.RequestNotificationText, initialTextString: initialRequestNotificationText }),
                                                                React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-notificationtext", FieldName: "Notification Text", Validators: ["string-not-empty"], OnScreen: currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers, Value: currentPolicy.RequestNotificationText || initialRequestNotificationText, Enabled: currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers }))),
                                                        React.createElement("hr", null),
                                                        React.createElement(Row, null,
                                                            React.createElement(Col, null,
                                                                React.createElement(Form.Group, { className: "form-group", controlId: "retry-cadence" },
                                                                    React.createElement("div", { className: "retry-cadence-container" },
                                                                        React.createElement("h6", null, "Notification Cadence"),
                                                                        React.createElement(Row, { className: "align-items-center" },
                                                                            React.createElement(Col, null,
                                                                                React.createElement("span", null,
                                                                                    React.createElement("div", { className: "label" },
                                                                                        "Send Notification every ",
                                                                                        React.createElement("span", { className: "required-astrix" }, "*"))))),
                                                                        React.createElement(Row, { className: "align-items-center" },
                                                                            React.createElement(Col, null,
                                                                                React.createElement(Row, { className: "align-items-center" },
                                                                                    React.createElement(Col, { lg: 6 },
                                                                                        React.createElement(Form.Control, { type: "number", min: 0, onChange: function (e) {
                                                                                                return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestNotificationRetryCadence: Number(e.target.value) }));
                                                                                            }, value: (_a = currentPolicy.RequestNotificationRetryCadence) === null || _a === void 0 ? void 0 : _a.toString(), placeholder: "quantity" }),
                                                                                        React.createElement(FormValidator, { FieldName: "Retry Cadence Quantity", UniqueIdentifier: "retry-cadence", Validators: ["number-not-empty"], Value: currentPolicy.RequestNotificationRetryCadence, Enabled: currentPolicy.AllowGuestAccessForWorkspaces && currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers, OnScreen: props.IsCurrent })),
                                                                                    React.createElement(Col, { lg: 6 },
                                                                                        React.createElement(FormDropdownSelect, { list: [
                                                                                                { id: "days", title: "Days", value: GuestLifecycleTimeUnit.Days },
                                                                                                { id: "weeks", title: "Weeks", value: GuestLifecycleTimeUnit.Weeks },
                                                                                                { id: "months", title: "Months", value: GuestLifecycleTimeUnit.Months }
                                                                                            ], onChange: function (value) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestNotificationRetryCadenceUnit: value })); }, value: currentPolicy.RequestNotificationRetryCadenceUnit, placeholder: "unit" }),
                                                                                        React.createElement(FormValidator, { FieldName: "Retry Cadence Unit", UniqueIdentifier: "retry-cadence-unit", Validators: ["dropdown-has-value"], Value: currentPolicy.RequestNotificationRetryCadenceUnit, Enabled: currentPolicy.AllowGuestAccessForWorkspaces && currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers, OnScreen: props.IsCurrent }))))),
                                                                        React.createElement(Row, { className: "align-items-center" },
                                                                            React.createElement(Col, null,
                                                                                React.createElement("span", { className: "retry-container" },
                                                                                    React.createElement("span", { className: "span-label" }, "Try"),
                                                                                    React.createElement(Form.Control, { type: "number", min: 0, onChange: function (e) {
                                                                                            return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestMaximumNotificationRetries: Number(e.target.value) }));
                                                                                        }, value: (_b = currentPolicy.RequestMaximumNotificationRetries) === null || _b === void 0 ? void 0 : _b.toString(), placeholder: "quantity" }),
                                                                                    React.createElement("span", { className: "span-label" },
                                                                                        "times ",
                                                                                        React.createElement("span", { className: "required-astrix" }, "*"))),
                                                                                React.createElement(FormValidator, { FieldName: "Total Number of Retries", UniqueIdentifier: "retry-count", Validators: ["number-not-empty"], Value: currentPolicy.RequestMaximumNotificationRetries, Enabled: currentPolicy.AllowGuestAccessForWorkspaces && currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers, OnScreen: props.IsCurrent }))))))))))))),
                                React.createElement(Collapse, { in: currentPolicy.RequestApprovers === ExecutionApprovers.SpecificUsers },
                                    React.createElement("div", null,
                                        React.createElement(Form.Group, { className: "form-group", controlId: "no-action-taken" },
                                            React.createElement(Row, { className: "align-items-center" },
                                                React.createElement(Col, null,
                                                    React.createElement("div", { className: "if-no-action-container" },
                                                        React.createElement("h4", null, "If No Action Taken"),
                                                        React.createElement(Row, { className: "align-items-center" },
                                                            React.createElement(Col, null,
                                                                React.createElement("div", { className: "escalate-to-container" },
                                                                    React.createElement(Row, { className: "align-items-center" },
                                                                        React.createElement("div", { className: "label" }, "Escalate to these users for manual intervention")),
                                                                    React.createElement(Row, null,
                                                                        React.createElement(Col, null,
                                                                            React.createElement(FormUserGroupSelect, { onChange: function (groups) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestNoActionTakenEscalateTo: groups })); }, returnType: "usersAndGroups", defaultValue: currentPolicy.RequestNoActionTakenEscalateTo, displayAddAsButton: true, allowMultiple: true })))))))))))),
                                React.createElement(Row, { className: "align-items-center" },
                                    React.createElement(Col, null,
                                        React.createElement(Row, { className: "align-items-center" },
                                            React.createElement(Col, null,
                                                React.createElement(Form.Group, { className: "form-group", controlId: "webhook" },
                                                    React.createElement("div", { className: "webhook-container", "data-pw": "webhook-container" },
                                                        React.createElement(Row, { className: "align-items-center" },
                                                            React.createElement(Col, null,
                                                                React.createElement("h6", null, "Call Webhook"),
                                                                React.createElement(FormWebhook, { OnWebhookSecretChange: function (value) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestNoActionTakenWebhookSecret: value })); }, OnWebhookUrlChange: function (value) { return dispatch(GuestLifecyclePolicyCurrentStore.UpdateProperties({ RequestNoActionTakenWebhookUrl: value })); }, webhookSecret: currentPolicy.RequestNoActionTakenWebhookSecret || null, webhookUrl: currentPolicy.RequestNoActionTakenWebhookUrl || null, exampleJson: { RequestNoActionTakenWebhookSecret: currentPolicy.RequestNoActionTakenWebhookSecret } })))))))))))))))));
});
export default StepCardPolicyExecution;
