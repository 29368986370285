var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// import { MiddlewareFunction} from 'app/store/middleware'
import { LifecyclePolicyWorkspaceCurrentActionTypes } from "./types";
import Store from "app/store";
import { cloneDeep } from "lodash";
import { Generic_Toast_Notification } from "app/store/notifications/types";
export function Load(SiteId) {
    var policy = createEmptyPolicyWorkspace(SiteId);
    //console.log("Load Current LifecyclePolicy > ", policy)
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load,
        payload: policy
    };
}
export function LoadStatus(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Status,
        payload: { SiteId: SiteId }
    };
}
export function LoadStats(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Stats,
        payload: { SiteId: SiteId }
    };
}
export function LoadHistory(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_History,
        payload: { SiteId: SiteId }
    };
}
export function LoadLogs(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Logs,
        payload: { SiteId: SiteId }
    };
}
export function LoadLinks(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Links,
        payload: { SiteId: SiteId }
    };
}
export var LoadNotebookAndPlanner = function (SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_NotebookAndPlanner,
        payload: { SiteId: SiteId }
    };
};
export function LoadMembers(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Members,
        payload: { SiteId: SiteId }
    };
}
export function LoadMembersByGroupId(GroupId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Members_ByGroupId,
        payload: { GroupId: GroupId }
    };
}
export function LoadAvailablePolicies(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_AvailablePolicies,
        payload: { SiteId: SiteId }
    };
}
export function LoadDefaultPolicies() {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_DefaultPolicies,
        payload: null
    };
}
export function LoadReportItem(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_ReportItem,
        payload: { SiteId: SiteId }
    };
}
export function LoadArchivalPolicy(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_ArchivalPolicy,
        payload: { SiteId: SiteId }
    };
}
export function UpdateReportItem(reportItem) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_ReportItem,
        payload: reportItem
    };
}
export function UpdateNextETLCrawl(SiteId) {
    Store.dispatch(UpdateIsLoading(true));
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_NextETLCrawl,
        payload: SiteId
    };
}
export function Update(policy) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update,
        payload: policy
    };
}
export function LoadDirectoryItem(SiteId) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_DirectoryItem,
        payload: { SiteId: SiteId }
    };
}
export function ClearDirectoryItem() {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Clear_DirectoryItem
    };
}
export function UpdateProperties(properties) {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecyclePolicyWorkspacesCurrentState.item);
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update,
        payload: __assign(__assign({}, policyState), properties)
    };
}
export function UpdatePropertiesNoServer(properties) {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecyclePolicyWorkspacesCurrentState.item);
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_Properties_NoServer,
        payload: __assign(__assign({}, policyState), properties)
    };
}
export function WorkflowActionSubmit(action) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_WorkflowSubmit,
        payload: action
    };
}
export function OverrideWorkspaceStatus(action) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Set_Status_Override,
        payload: action
    };
}
export function LoadNewStatus(isLoading) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Set_LoadingNewStatus,
        payload: isLoading
    };
}
export function Clear() {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Clear
    };
}
export var policyCurrentMiddlware = function (store, next, action) {
    if (action.type === LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load) {
        next(action);
    }
    else {
        next(action);
    }
};
export var createEmptyPolicyWorkspace = function (SiteId) {
    return {
        SiteId: SiteId
    };
};
export var GuestWorkflowActionSubmit = function (action) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_GuestWorkflowSubmit_Action,
        payload: action
    };
};
export function UpdateIsLoading(isLoading) {
    return {
        type: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_IsLoading,
        payload: isLoading
    };
}
//the type of the return object is seen by the main reducers
//actionType in the payload is what the toaster checks for to display the proper message confiugred in
//the config-notifications.ts file
export var ForcedGuestReviewSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Forced_Review_Success", actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_GuestReviewForced_Success }
    };
};
export var DeleteGuestSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Delete_Guest_Success", actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_DeleteGuest_Success }
    };
};
export var RemoveFromWorkspaceSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Remove_Guest_From_Workspace_Success", actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_RemoveGuestFromWorkspace_Success }
    };
};
export var ResendInviteSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Resend_Invite_Success", actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_ResendInvite_Success }
    };
};
export var ResendInviteFailure = function () {
    return {
        type: Generic_Toast_Notification.Generic_Failure,
        payload: { key: "Resend_Invite_Failure", actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_ResendInvite_Failure_DeletedUser }
    };
};
