import axios from "axios";
// The crawler service contains REST calls to get various data that supports the admin crawler pages.
var CrawlerService = /** @class */ (function () {
    function CrawlerService() {
    }
    // Get a list of files from the SharePoint storage library.
    CrawlerService.getETLCachedFiles = function (tenantGuid, fileType) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/AdminCrawlerStats/GetCachedETLFilesList?tenantGuid=" + tenantGuid + "&type=" + fileType;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    // Get the contents of a file
    CrawlerService.getETLCachedFile = function (tenantGuid, fileName, fileType) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/AdminCrawlerStats/GetCachedETLFile?tenantGuid=" + tenantGuid + "&fileName=" + fileName + "&type=" + fileType;
            axiosConfig.responseType = "text";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    // Search workspaces based on search term.
    CrawlerService.searchForWorkspacesForDetailedStats = function (tenantGuid, searchTerm) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/AdminCrawlerStats/WorkspaceStatSearch?tenantGuid=" + tenantGuid + "&searchTerm=" + searchTerm;
            axiosConfig.responseType = "text";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    // Get the detailed stats of a specific workspace via site id.
    CrawlerService.getWorkspaceDetailedStats = function (tenantGuid, siteId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/AdminCrawlerStats/WorkspaceDetailedStats?tenantGuid=" + tenantGuid + "&siteId=" + siteId;
            axiosConfig.responseType = "text";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return CrawlerService;
}());
export default CrawlerService;
