import { Modal, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
import { Link } from "react-router-dom";
export var RecommendationsNotAvailableModal = function () {
    return (React.createElement(Modal, { yOffset: "25vh", withCloseButton: false, closeOnEscape: false, closeOnClickOutside: false, withinPortal: false, onClose: function () { return null; }, opened: true, variant: "recnotavailable", padding: "md" },
        React.createElement(Text, { size: "xl", fw: 500, c: "primary" }, "Orchestry Update Required"),
        React.createElement(OrchestryFeaturedIcon, { size: "xl", colorVariant: "gray-5", variant: "light-circle", iconClassName: ORCH_ICONS.packagePlus }),
        React.createElement(Text, { c: "text-primary.0" },
            "Navigate to ",
            React.createElement(Link, { to: "/settings/installation" }, "Installation"),
            " under the Settings Menu and update Orchestry to the latest version to utilize this new feature.")));
};
