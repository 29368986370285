var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as ChannelListStore from "app/store/channels/list";
import * as TableList from "app/components/common/table-list";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
var ChannelList = React.memo(function () {
    var dispatch = useDispatch();
    var channels = useSelector(function (state) { return state.channelListState.items; });
    //@ts-ignore
    //const layoutContext = <LayoutContextConsumer>{({ subheader: { title, breadcrumb, description} }) => { console.log("title", title)}}</LayoutContextConsumer>
    // console.log("layoutContext", layoutContext);
    // const { subheader: { title, breadcrumb } } = useLayoutContext();
    // LayoutSubheader({title:title, breadcrumb:breadcrumb, description:"Testing"});
    console.log("Channels", channels);
    React.useEffect(function () {
        dispatch(ChannelListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onAssociatedAdd = function (feat, templateToAdd) {
        var tempArray = [templateToAdd];
        if (feat.AssociatedTemplates !== undefined) {
            if (feat.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), feat.AssociatedTemplates, true);
            }
        }
        dispatch(ChannelListStore.UpdateItem(Object.assign(feat, { AssociatedTemplates: tempArray })));
    };
    var onAssociatedRemove = function (feat, tbaToRemove) {
        var _a;
        dispatch(ChannelListStore.UpdateItem(Object.assign(feat, { AssociatedTemplates: (_a = feat.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; }) })));
    };
    return (React.createElement("div", { className: "channels-lists" },
        React.createElement(PageHeader.SetLeftContent, { subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a channel" })),
        React.createElement(Subtitle, { subtitle: "List" },
            React.createElement(NavLink, { exact: true, to: "/workspaces/channels/new" },
                React.createElement(SubtitleButton, { title: "New Channel", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: channels, asyncListLoadUID: ChannelListStore.ChannelListActionTypes.Channel_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Channel Name"),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, null, "Privacy"),
                React.createElement(TableList.HeaderItem, { type: "centered", helpText: "" }, "Associated"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/workspaces/channels/edit/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Privacy" }),
                React.createElement(TableList.ContentItemAssociatedTemplates, { onAssociatedAdd: function (item, tba) {
                        onAssociatedAdd(item, tba);
                    }, onAssociatedRemove: function (item, tba) {
                        onAssociatedRemove(item, tba);
                    } }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/channels/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/channels/edit/{GUID}"
                    onRemove: function (item) { return dispatch(ChannelListStore.DeleteItem(item)); }, deleteCheckType: "channel" })))));
});
export default ChannelList;
