import * as React from "react";
import "./card-etl-logs.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import LifecycleService from "app/services/lifecycle";
import * as TableList from "app/components/common/table-list";
import moment from "moment";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var CardETLLogs = React.memo(function (props) {
    var _a = React.useState(null), logItems = _a[0], setLogItems = _a[1];
    //@ts-ignore
    var isDebug = window["OrchestryDebug"] !== undefined && window["OrchestryDebug"] === true ? true : false;
    React.useEffect(function () {
        LifecycleService.GetETLLogs(5).then(function (etlLogs) {
            //console.log(etlLogs)
            setLogItems(etlLogs);
        });
    }, []);
    var dateRender = function (stringDate, withTime) {
        if (stringDate === null) {
            return "";
        }
        return moment
            .utc(stringDate)
            .local()
            .format(withTime === true ? "LLL" : "LL");
    };
    return (React.createElement("div", { className: "card-etl-logs", "data-pw": "crawl-logs" },
        React.createElement(Portlet, null,
            React.createElement(PortletHeader, { title: "Last 5 Lifecycle Crawls" }),
            React.createElement(PortletBody, null, logItems === null ? (React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
                React.createElement(SkeletonFakeTableCol, { widthPercent: 30 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                React.createElement(SkeletonFakeTableCol, { widthPercent: 40 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                React.createElement(SkeletonFakeTableCol, { widthPercent: 30 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })))) : (React.createElement(TableList.Context, { listItems: logItems, wrappedInPortlet: false, showLoader: false },
                React.createElement(TableList.TableList, null,
                    React.createElement(TableList.HeaderItem, null, "Crawl For Day"),
                    React.createElement(TableList.HeaderItem, null, "Crawl Ended"),
                    React.createElement(TableList.HeaderItem, null, "Count"),
                    React.createElement(TableList.HeaderItem, null, "Status"),
                    React.createElement(TableList.ContentItemCustomRender, { render: function (item) {
                            return moment.utc(item.CrawlDay).format("LL");
                        }, isMobileTitle: true }),
                    React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return dateRender(item.CrawlEnded, true); } }),
                    React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (item.WorkspaceCount === null ? 0 : item.WorkspaceCount); } }),
                    React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return item.Status; } }))))))));
});
export default CardETLLogs;
