import * as React from "react";
import "./report-column-guest-membership-status-new-existing.scss";
var ReportColumnGuestMembershipStatusNewExisting = React.memo(function (props) {
    var getStatus = function () {
        var _a;
        if (props.Item.ExtendedProperties !== undefined &&
            ((_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) !== undefined &&
            props.Item.ExtendedProperties.CosmosUserDetails.ExternalUserState !== undefined) {
            if (props.Item.ExtendedProperties.CosmosUserDetails.ExternalUserState === "Accepted") {
                return "Existing";
            }
            else {
                return "New";
            }
        }
        return "New";
    };
    var getClassName = function () {
        return getStatus().toLowerCase();
    };
    return (React.createElement("div", { className: "report-column-guest-membership-status-new-existing" },
        React.createElement("div", { className: "status-box " + "guest-status-" + getClassName() }, getStatus())));
});
export default ReportColumnGuestMembershipStatusNewExisting;
