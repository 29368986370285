import * as LibraryChannelListStore from "app/store/library-channels/list";
import { AsyncCallType } from "app/store/async/types";
import * as LibraryTemplateListTypes from "app/store/library-templates/list";
import { LibraryTemplateCurrentActionTypes } from "app/store/library-templates/current";
export var libraryChannelListActionMap = [
    {
        actionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/librarychannels",
        responseDataType: "",
        invalidates: [LibraryTemplateListTypes.LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/librarychannels",
        responseDataType: "",
        invalidates: [LibraryTemplateListTypes.LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/librarychannels",
        responseDataType: true,
        invalidates: [LibraryTemplateListTypes.LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "libraryChannelListState.items",
        keyType: "typeName",
        ApiUrl: "/api/librarychannels",
        responseDataType: []
    }
];
