import * as Icons from "@fortawesome/free-solid-svg-icons";
import * as microsoftTeams from "@microsoft/teams-js";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import "./team-configuration.scss";
var TeamConfiguration = React.memo(function (props) {
    var intl = useIntl();
    var _a = React.useState(), currentTab = _a[0], setCurrentTab = _a[1];
    var _b = React.useState(true), showSiteInfo = _b[0], setShowSiteInfo = _b[1];
    var _c = React.useState(true), showTeamOwners = _c[0], setShowTeamOwners = _c[1];
    var _d = React.useState(true), showTeamMembers = _d[0], setShowTeamMembers = _d[1];
    var tabTypes = [
        {
            id: "team-information",
            title: intl.formatMessage({ id: "layout.teams_app.chrome.team_config.team_information", defaultMessage: "Team Information" }),
            //description: "Can display various aspects about the team",
            value: "team-information",
            icon: Icons.faInfoCircle
        } //,
        //  {
        //    id: "workspace-request",
        //    title: "My Workspace Requests",
        //    //description: "Can display various aspects about the team",
        //    value: "my-workspace-requests",
        //    icon: Icons.faSuitcase
        //  },
        //  {
        //    id: "team-directory",
        //    title: "Workspace Directory",
        //    value: "workspace-directory",
        //    icon: Icons.faSitemap
        //  }
    ];
    React.useEffect(function () {
        console.log("current tab", currentTab);
        microsoftTeams.pages.config.setValidityState(currentTab !== undefined && currentTab !== null && currentTab !== "");
    }, [currentTab]);
    React.useEffect(function () {
        document.body.classList.add("teams-tab-config");
        microsoftTeams.app
            .initialize()
            .then(function () {
            microsoftTeams.pages.getConfig().then(function (settingsData) {
                var urlParams = new URLSearchParams(settingsData.contentUrl);
                // urlParams.has('type');  // true
                // urlParams.get('id');    // 1234
                // urlParams.getAll('id'); // ["1234"]
                // urlParams.toString();   // type=product&id=1234
                if (settingsData.contentUrl !== undefined && settingsData.contentUrl.indexOf("/tabs/team-information") > -1) {
                    setCurrentTab("team-information");
                    setShowSiteInfo(urlParams.get("siteinfo") === "true" ? true : false);
                    setShowTeamOwners(urlParams.get("owners") === "true" ? true : false);
                    setShowTeamMembers(urlParams.get("members") === "true" ? true : false);
                }
            });
            microsoftTeams.pages.config.registerOnSaveHandler(function (saveEvent) {
                var tabUrl = window.location.protocol + "//" + window.location.host + "/tabs/" + currentTab + "?tid={tid}";
                var queryParams = "";
                if (currentTab === "team-information") {
                    queryParams += "&siteinfo=" + showSiteInfo;
                    // queryParams += "&features=" + showSiteInfo;
                    // queryParams += "&channels=" + showSiteInfo;
                    // queryParams += "&documents=" + showSiteInfo;
                    queryParams += "&owners=" + showTeamOwners;
                    queryParams += "&members=" + showTeamMembers;
                }
                // Let the Microsoft Teams platform know what you want to load based on
                // what the user configured on this page
                microsoftTeams.pages.config.setConfig({
                    contentUrl: tabUrl + queryParams,
                    websiteUrl: tabUrl + queryParams,
                    entityId: tabUrl + queryParams,
                    suggestedDisplayName: currentTab === "team-information"
                        ? intl.formatMessage({ id: "layout.teams_app.chrome.team_config.team_information", defaultMessage: "Team Information" })
                        : currentTab === "workspace-request"
                            ? intl.formatMessage({ id: "layout.teams_app.chrome.team_config.request_workspace", defaultMessage: "Request Workspace" })
                            : ""
                });
                // Tells Microsoft Teams platform that we are done saving our settings. Microsoft Teams waits
                // for the app to call this API before it dismisses the dialog. If the wait times out, you will
                // see an error indicating that the configuration settings could not be saved.
                saveEvent.notifySuccess();
            });
        })
            .catch(function (e) {
            console.error("Failed to initialize Microsoft Teams SDK: ", e);
        });
    }, []);
    React.useEffect(function () {
        microsoftTeams.pages.config.registerOnSaveHandler(function (saveEvent) {
            var tabUrl = window.location.protocol + "//" + window.location.host + "/tabs/" + currentTab + "?tid={tid}";
            var queryParams = "";
            if (currentTab === "team-information") {
                queryParams += "&siteinfo=" + showSiteInfo;
                //   queryParams += "&features=" + showSiteInfo;
                //   queryParams += "&channels=" + showSiteInfo;
                //   queryParams += "&documents=" + showSiteInfo;
                queryParams += "&owners=" + showTeamOwners;
                queryParams += "&members=" + showTeamMembers;
            }
            console.log("Save", queryParams);
            // Let the Microsoft Teams platform know what you want to load based on
            // what the user configured on this page
            microsoftTeams.pages.config.setConfig({
                contentUrl: tabUrl + queryParams,
                websiteUrl: tabUrl + queryParams,
                entityId: tabUrl + queryParams,
                suggestedDisplayName: currentTab === "team-information" ? intl.formatMessage({ id: "layout.teams_app.chrome.team_config.team_information", defaultMessage: "Team Information" }) : ""
            });
            // Tells Microsoft Teams platform that we are done saving our settings. Microsoft Teams waits
            // for the app to call this API before it dismisses the dialog. If the wait times out, you will
            // see an error indicating that the configuration settings could not be saved.
            saveEvent.notifySuccess();
        });
    });
    return (React.createElement("div", { className: "configuration-tab" },
        React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
            React.createElement(Form.Label, null,
                React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_config.what_type_of_tab", defaultMessage: "What type of Orchestry tab would you like to add to this team?" })),
            React.createElement(FormDropdownSelect, { list: tabTypes, value: currentTab, onChange: function (value) { return setCurrentTab(value); }, placeholder: intl.formatMessage({ id: "general.label.dropdown.please_select", defaultMessage: "-- Please Select --" }) }))));
});
export default TeamConfiguration;
