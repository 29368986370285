import { faLock, faLockOpen, faUsers } from "@fortawesome/free-solid-svg-icons";
export var PermissionTypes = [
    {
        Title: "Public or Private User Choice",
        Description: "Let the user decide",
        Value: "Choice",
        Icon: faUsers
    },
    {
        Title: "Private",
        Description: "Only members have access",
        Value: "Private",
        Icon: faLock
    },
    {
        Title: "Public",
        Description: "Everyone in my organization has access",
        Value: "Public",
        Icon: faLockOpen
    } //,
    // {
    //     Title: 'Organization',
    //     Description: "(Everyone in my organization is automatically added)",
    //     Value:"Organization",
    //     Icon:faUsers
    // }
];
