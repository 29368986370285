var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import MembersIcon from "app/assets/img/members-icon.svg";
import PrincipalGuestsPickerModal from "app/components/common/principal-guest-picker-modal";
import PrincipalItemGuest from "app/components/common/principal-item-guest";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import "./request-wizard-permissions-guests.scss";
var RequestWizardPermissionsGuests = React.memo(function (props) {
    var _a;
    var rwc = React.useContext(RequestWizardContext);
    var guestContainerElement = React.useRef();
    React.useEffect(function () {
        if (rwc !== null &&
            rwc !== undefined &&
            rwc.currentGuestRequestPolicy !== null &&
            rwc.currentGuestRequestPolicy !== undefined &&
            rwc.currentGuestRequestPolicy.GUID !== null &&
            rwc.currentGuestRequestPolicy.GUID !== undefined) {
            props.SetShouldExcludeGuests(true);
        }
        else {
            props.SetShouldExcludeGuests(false);
        }
    }, [rwc.currentGuestRequestPolicy]);
    var addGuest = function (user) {
        var tempArray = [user];
        if (rwc.guestStorage !== undefined) {
            if (rwc.guestStorage.length > 0) {
                if (rwc.guestStorage.some(function (u) { return u.mail === user.mail; })) {
                    tempArray = __spreadArray([], rwc.guestStorage, true);
                }
                else {
                    tempArray = __spreadArray(__spreadArray([], rwc.guestStorage, true), tempArray, true);
                }
            }
        }
        rwc.setGuestStorage(__spreadArray([], tempArray, true));
        rwc.updateCurrentRequest({
            Guests: tempArray.map(function (g) { return ({
                UserId: g.id,
                Justification: g.orchestryProperties !== undefined && g.orchestryProperties !== null && g.orchestryProperties.justification !== undefined && g.orchestryProperties.justification !== null
                    ? g.orchestryProperties.justification
                    : null
            }); })
        });
    };
    var removeGuest = function (user) {
        var _a, _b;
        var tempArray = (_b = (_a = rwc.guestStorage) === null || _a === void 0 ? void 0 : _a.filter(function (member) { return member.mail !== user.mail || member.id !== user.id; })) !== null && _b !== void 0 ? _b : [];
        rwc.setGuestStorage(tempArray);
        rwc.updateCurrentRequest({
            Guests: tempArray.map(function (g) { return ({
                UserId: g.id,
                Justification: g.orchestryProperties !== undefined && g.orchestryProperties !== null && g.orchestryProperties.justification !== undefined && g.orchestryProperties.justification !== null
                    ? g.orchestryProperties.justification
                    : null
            }); })
        });
    };
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", userListBoxClickHandler);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", userListBoxClickHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var userListBoxClickHandler = function (e) {
        if (guestContainerElement.current !== undefined) {
            if (guestContainerElement.current !== null) {
                if (guestContainerElement.current === e.target) {
                    var addButton = document.querySelector(".request-wizard-permissions-guests .title-add .link");
                    if (addButton !== null) {
                        addButton.click();
                    }
                }
            }
        }
    };
    // const clickAddButton = (side: "left" | "right") => {
    //    let addButton = document.querySelector(".step-permissions .users ." + side + " .link") as HTMLElement;
    //    if (addButton !== null) {
    //       addButton.click();
    //    }
    // };
    var isGuestBoxEnabled = function () {
        var _a;
        console.log("");
        console.log("isGuestBoxEnabled()");
        if (props.DoesSensitivityLabelAllowGuests === false) {
            console.log("  Hiding because DoesSensitivityLabelAllowGuests = ", props.DoesSensitivityLabelAllowGuests);
            return props.DoesSensitivityLabelAllowGuests;
        }
        if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.GuestRequestPolicyGuid) !== null) {
            if (rwc.currentGuestRequestPolicy !== null) {
                if (rwc !== null && rwc !== undefined && rwc.currentGuestRequestPolicy !== undefined && rwc.currentGuestRequestPolicy.AllowGuestAccessForWorkspaces === true) {
                    console.log("  Showing because AllowGuestAccessForWorkspaces - return true");
                    return true;
                }
            }
        }
        console.log("  Hiding because unhandled - return false");
        return false;
    };
    var getGuests = function () {
        var guests = [];
        if (rwc.guestStorage !== undefined) {
            guests = __spreadArray([], rwc.guestStorage, true);
        }
        return guests;
    };
    return isGuestBoxEnabled() === true ? (React.createElement("div", { className: "request-wizard-permissions-guests" },
        React.createElement("div", { className: "adder-top" },
            React.createElement("div", { className: "title-add" },
                React.createElement("span", { className: "title" },
                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.guests", defaultMessage: "Guests" }),
                    " ",
                    React.createElement("span", { className: "count" },
                        "(",
                        React.createElement("span", { className: "num-pad" }, getGuests().length),
                        ")")),
                React.createElement(PrincipalGuestsPickerModal, { editingUserId: (_a = rwc.currentUser) === null || _a === void 0 ? void 0 : _a.Id, onPickerSubmit: function () { }, selectedGuests: getGuests(), guestRequestPolicy: rwc.currentGuestRequestPolicy, onPickerClosed: function () { return getGuests().forEach(function (guest) { return removeGuest(guest); }); }, onGuestSelected: function (principal) {
                        addGuest(principal);
                    }, onGuestRemoved: function (principal) {
                        removeGuest(principal);
                    } },
                    React.createElement("div", { className: "add-action" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "la la-plus" })),
                        React.createElement("div", { className: "text" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.add_guests", defaultMessage: "Add Guests" }))))),
            React.createElement("div", { className: "info" },
                React.createElement("div", null,
                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.guests_description", defaultMessage: "Guests are users from outside your organization who can see and participate." })),
                React.createElement("strong", null,
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.guests_description_note", defaultMessage: "Note: Your organization may require guests to be approved post-request." }))))),
        React.createElement("div", { className: "user-list-guests", ref: guestContainerElement },
            getGuests().length === 0 ? (React.createElement("div", { className: "no-results" },
                React.createElement("div", { className: "no-icon-text" },
                    React.createElement("img", { src: MembersIcon }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.no_guests_yet", defaultMessage: "No guests yet" }))))) : null,
            getGuests().map(function (user) { return (React.createElement(PrincipalItemGuest, { principal: user, key: user.id, onRemove: function (principal) { return removeGuest(principal); } })); })))) : null;
});
export default RequestWizardPermissionsGuests;
