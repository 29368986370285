import { Group, Stack, Text } from "@mantine/core";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import withIntlProvider from "appv2/components/orchestry-with-intl-provider-hoc/orchestry-with-intl-provider-hoc.component";
import { mapFormGroupsStringToIds } from "appv2/utils/control.utils";
import React from "react";
import { useAddTenantManagersModal } from "./use-add-tenant-managers-modal-content.hook";
export var AddTenantManagersModalContent = function (_a) {
    var context = _a.context, id = _a.id, tenantGuid = _a.innerProps.tenantGuid;
    var _b = useAddTenantManagersModal({ context: context, id: id, innerProps: { tenantGuid: tenantGuid } }), intl = _b.intl, form = _b.form, tenantManagersCount = _b.tenantManagersCount, isPageLoading = _b.isPageLoading, handleFormSubmit = _b.handleFormSubmit;
    return (React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
        React.createElement(Stack, { gap: 16, ff: "var(--mantine-font-family)" },
            React.createElement(Group, { gap: 16 },
                React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", size: "lg", colorVariant: "primary-6", iconClassName: ORCH_ICONS.usersEdit }),
                React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, "Configure Tenant Managers")),
            React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, "Tenant Managers (".concat(tenantManagersCount, ")")),
            React.createElement(Text, { c: "text-secondary.0", fz: "14px", fw: 400, lh: "20px" }, "The following users have been granted access to manage the tenant."),
            React.createElement(FormUserGroupSelect, { isLoading: isPageLoading, defaultValue: undefined, allowMultiple: true, returnType: "users", label: "Tenant Managers:", value: form.getInputProps("tenantManagers").value, onChange: function (groups) { return form.setFieldValue("tenantManagers", mapFormGroupsStringToIds(groups)); }, excludeGuests: true, shouldUseDynamic: true, error: form.getInputProps("tenantManagers").error })),
        React.createElement(Group, { gap: 12, justify: "right" },
            React.createElement(OrchestryButton, { disabled: isPageLoading, variant: "white", size: "md", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "controls.cancel", defaultMessage: "Cancel" })),
            React.createElement(OrchestrySaveButton, { disabled: isPageLoading, variant: "primary", type: "submit" }, intl.formatMessage({ id: "controls.save", defaultMessage: "Save" })))));
};
export var AddTenantManagersModal = withIntlProvider(AddTenantManagersModalContent);
