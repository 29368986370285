/**
 * Maps the stringified groups to their stringified GUIDs so that we do not store any PII in the tenant DBs.
 *
 * Sarah plans to rewrite the FormUserGroupSelect control soon.
 *
 * @param groupsString
 */
export var mapFormGroupsStringToIds = function (groupsString) {
    var groups = JSON.parse(groupsString);
    var idList = groups.map(function (group) {
        return { Id: group.Id }; //Ideally, this would be a comma-delimited lsit of GUIDs, but the form uses this object until Sarah's rewrite.
    });
    return JSON.stringify(idList);
};
