import DocumentTemplatePreview from "app/components/common/document-template-preview";
import FormFileUploadBox from "app/components/common/form-fileuploadbox";
import FileService from "app/services/files";
import React from "react";
import { Modal } from "react-bootstrap";
import "./upload-template.scss";
var UploadTemplate = React.memo(function (props) {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(), fileUploadPercentage = _b[0], setFileUploadPercentage = _b[1];
    var _c = React.useState(""), uploadFileName = _c[0], setUploadFileName = _c[1];
    var _d = React.useState(), uploadFile = _d[0], setUploadFile = _d[1];
    React.useEffect(function () {
        if (showModal === false) {
            setUploadFileName("");
            setUploadFile(undefined);
        }
    }, [showModal]);
    var onUploadProgress = function (progress) {
        //@ts-ignore
        setFileUploadPercentage((progress.loaded / progress.total) * 100 + "%");
        console.log("Progress", progress);
    };
    var uploadFileAsync = function (file, fileName) {
        return new Promise(function (resolve, reject) {
            var continueUpload = function () {
                //const state = Store.getState();
                //let currentFeature = state.featureCurrentState.item;
                if (file !== undefined) {
                    var formData = new FormData();
                    formData.append("file", file);
                    formData.append("fileName", fileName);
                    FileService.uploadDocument(formData, "/api/FeatureFiles/UploadPNPFile", onUploadProgress).then(function () {
                        console.log("upload done");
                        setFileUploadPercentage(undefined);
                        props.afterUpload();
                        setTimeout(function () {
                            setShowModal(false);
                        }, 1000);
                        // const tempState = Store.getState();
                        // let findAI = tempState.asyncState.items.find(i => i.key === currentFeature.GUID);
                        // if(findAI !== undefined){
                        //    console.log("Found Async Action", findAI);
                        //    dispatch(AsyncActionCreators.Invalid(findAI))
                        // }
                        resolve("whatever");
                    });
                }
            };
            continueUpload();
        });
    };
    var addFile = function (file, fileName) {
        var regex = /\.pnp$|\.xml$/;
        if (!regex.test(fileName)) {
            window.alert("Only .xml/.pnp files are accepted");
            return;
        }
        // let tempArray = [...saveJobs.filter(file=> file.Type !== "file"), {
        //    Title: "Upload " + fileName,
        //    Type:"file",
        //    Method:()=>{ return uploadFileAsync(file, fileName);}
        // }];
        // //@ts-ignore
        // setSaveJobs(tempArray);
        setUploadFileName(fileName);
        setUploadFile(file);
        //const fileExtension = fileName !== undefined ? fileName.split('.').pop() : "";
        // const state = Store.getState();
        // let currentFeature = state.featureCurrentState.item;
        // if(currentFeature.Title === undefined || (currentFeature.Title !== undefined && currentFeature.Title === null) || (currentFeature.Title !== undefined && currentFeature.Title === "")) {
        //     //@ts-ignore
        //     dispatch(FeatureCurrentStore.Update({Title:fileName.replace("."+fileExtension, ""), Type:fileExtension}));
        // } else {
        //    //  dispatch(FeatureCurrentStore.Update({Type:fileExtension}));
        // }
    };
    return (React.createElement("div", { className: "upload-template" },
        React.createElement("div", { className: "apply-feature-button", onClick: function () { return setShowModal(true); } }, props.children),
        React.createElement(Modal, { centered: true, show: showModal, onHide: function () { return setShowModal(!showModal); }, animation: true, className: "upload-template-modal", backdropClassName: "upload-template-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Upload Template")),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "feature-extraction-wrapper" },
                    uploadFileName !== "" ? (React.createElement("div", { className: "document-preview" },
                        React.createElement(DocumentTemplatePreview, { fileName: uploadFileName, OnRemove: function () {
                                setUploadFileName("");
                            }, noPreviewText: "...", showFileName: true }))) : (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "instructions" }, "Please upload a PnP (Patterns and Practices) .pnp file"),
                        React.createElement("p", { className: "pnp-schema" },
                            "Orchestry currently supports",
                            " ",
                            React.createElement("a", { href: "https://github.com/pnp/PnP-Provisioning-Schema/blob/master/ProvisioningSchema-2021-03.md", target: "_blank", rel: "noopener noreferrer" }, "PNP schema 2021/03")),
                        React.createElement("div", { className: "upload-area" },
                            React.createElement("div", { className: "left" },
                                React.createElement(FormFileUploadBox
                                //defaultBase64File={currentFeature.File}
                                , { 
                                    //defaultBase64File={currentFeature.File}
                                    uploadText: "Upload or drop a pnp file here", enforceSafeFileName: true, onDrop: function (file, fileName) { return addFile(file, fileName); } }))))),
                    fileUploadPercentage !== undefined ? (React.createElement("div", { className: "progress" },
                        React.createElement("div", { className: "progress-bar progress-bar-striped bg-success", style: { width: fileUploadPercentage } }))) : null)),
            React.createElement(Modal.Footer, null,
                React.createElement("div", { className: "action-buttons" }, fileUploadPercentage !== undefined ? (React.createElement("button", { className: "submit-button btn btn-success", disabled: true }, "Upload")) : (React.createElement("button", { className: "submit-button btn btn-success", onClick: function () { return uploadFileAsync(uploadFile, uploadFileName); } }, "Upload")))))));
});
export default UploadTemplate;
