var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from "react";
import { useIntl } from "react-intl";
import { getFilterGroups } from "./orchestry-filter-selector.utils";
/**
 * Hook that manages the state of a filter selector component.
 * @param initialFilters - The initial filters to be displayed.
 */
export var useOrchestryFilterSelector = function (initialFilters) {
    var _a = useState(initialFilters), filters = _a[0], setFilters = _a[1];
    var filterGroups = getFilterGroups(filters);
    var intl = useIntl();
    var updateFilterState = function (value) {
        var updatedFilters = filters.map(function (filter) {
            if (filter.value === value) {
                return __assign(__assign({}, filter), { selected: !filter.selected });
            }
            return filter;
        });
        setFilters(updatedFilters);
    };
    var onClearAllButtonClicked = function () {
        var clearedFilters = filters.map(function (filter) {
            return __assign(__assign({}, filter), { selected: false });
        });
        setFilters(clearedFilters);
    };
    var selectedCount = filters.reduce(function (count, filter) { return (filter.selected ? count + 1 : count); }, 0);
    return { filters: filters, setFilters: setFilters, selectedCount: selectedCount, updateFilterState: updateFilterState, onClearAllButtonClicked: onClearAllButtonClicked, filterGroups: filterGroups, intl: intl };
};
