import { OrchestrySVGBadge } from "appv2/components/orchestry-svg-badge/orchestry-svg-badge.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { useRecommendationProduct } from "../use-recommendation-product.hook";
export var RecommendationCardProductBadge = function (_a) {
    var productGuid = _a.productGuid, onClick = _a.onClick;
    var product = useRecommendationProduct(productGuid).product;
    if (!(product === null || product === void 0 ? void 0 : product.name) || !(product === null || product === void 0 ? void 0 : product.icon))
        return null;
    return (React.createElement(OrchestryTooltip, { withinPortal: false, label: product.name },
        React.createElement(OrchestrySVGBadge, { key: productGuid, value: product.name.toLowerCase(), svgIcon: product.icon, onClick: onClick })));
};
