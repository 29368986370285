import { Group, Stack } from "@mantine/core";
import { convertUtcDatetimeToLocal, getReadableDate } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryAvatar } from "../orchestry-avatar/orchestry-avatar.component";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestryAnnotation = function (_a) {
    var uniqueId = _a.uniqueId, onDeleteClicked = _a.onDeleteClicked, authorDisplayName = _a.authorDisplayName, authorBase64Photo = _a.authorBase64Photo, annotationDate = _a.annotationDate, children = _a.children, body = _a.body;
    return (React.createElement(Stack, { gap: 8 },
        React.createElement(Group, { gap: 8 },
            React.createElement(OrchestryAvatar, { variant: "", src: authorBase64Photo, userDisplayName: authorDisplayName }),
            React.createElement(OrchestryText, { c: "var(--mantine-color-gray-7)", size: "14px", fw: 400, lh: "20px" }, authorDisplayName),
            React.createElement(OrchestryText, { c: "var(--mantine-color-text-tertiary-0)", size: "12px", fw: 300, lh: "18px" }, getReadableDate(convertUtcDatetimeToLocal(annotationDate))),
            onDeleteClicked && (React.createElement(OrchestryFontIcon, { onClick: function () { return onDeleteClicked(uniqueId, body); }, iconClassName: ORCH_ICONS.trash02, color: "var(--mantine-color-gray-4)", fontSize: 14, lineHeight: "14px" }))),
        children));
};
