import { Group, Stack } from "@mantine/core";
import Subtitle from "app/components/common/subtitle";
import OrchestryContext from "app/components/orchestry-context";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryBrandingLogo } from "appv2/components/orchestry-branding-logo/orchestry-branding-logo.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryPageLoadedIndicator } from "appv2/components/orchestry-page-loaded-indicator/orchestry-page-loaded-indicator.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { useAppBackgroundColorChange } from "appv2/hooks/use-app-background-color-change.hook";
import React from "react";
import { useLocation } from "react-router-dom";
import { HealthCheckFooter } from "../health-check/health-check/health-check-footer.component";
import { useHealthCheckPage } from "../health-check/health-check/use-health-check-page.hook";
import { HealthCheckActivityTab } from "../health-check/tabs/health-check-activity-tab/health-check-activity-tab.component";
import { HealthCheckAdditionsTab } from "../health-check/tabs/health-check-additions-tab/health-check-additions-tab.component";
import "./health-check-pdf-page.scss";
export var HealthCheckPdfPage = function () {
    var _a;
    var location = useLocation();
    var queryParams = new URLSearchParams(location.search);
    var locale = (_a = queryParams.get("locale")) !== null && _a !== void 0 ? _a : "en";
    var _b = useHealthCheckPage(locale), intl = _b.intl, startDate = _b.startDate, endDate = _b.endDate, isLoading = _b.isLoading, title = _b.title, partnerBrandingImage = _b.partnerBrandingImage, healthCheckBrandingImage = _b.healthCheckBrandingImage, tenantName = _b.tenantName;
    useAppBackgroundColorChange("var(--mantine-color-white)");
    return (React.createElement("div", { className: "health-check-pdf-page" },
        React.createElement(OrchestryContext, null,
            React.createElement(OrchestryPageLoadedIndicator, null),
            React.createElement(Group, { justify: "space-between", align: "center" },
                React.createElement(Stack, { gap: 4 },
                    React.createElement(Subtitle, { title: intl.formatMessage({ id: "page.health_checks.title", defaultMessage: "Health Checks" }) }),
                    React.createElement(OrchestryText, { c: "text-primary.0", fw: "700", size: "30px", lh: "38px", isLoading: isLoading, skeletonWidth: 260 }, title),
                    React.createElement(Group, { gap: 4 },
                        React.createElement(Group, { gap: 4 },
                            React.createElement(OrchestryText, { skeletonWidth: 24, isLoading: isLoading, c: "text-primary.0", size: "16px", fw: 400, lh: "30px" }, intl.formatMessage({ id: "pages.health_check.for_prefix", defaultMessage: "For" })),
                            React.createElement(OrchestryFontIcon, { isLoading: isLoading, iconClassName: ORCH_ICONS.building06, color: "var(--mantine-color-primary-7)", fontSize: 18, lineHeight: "18px" }),
                            React.createElement(OrchestryText, { c: "text-primary.0", fw: "600", size: "16px", lh: "30px", isLoading: isLoading, skeletonWidth: 60 }, tenantName)),
                        React.createElement(OrchestryText, { skeletonWidth: 300, isLoading: isLoading, c: "text-primary.0", size: "16px", fw: 400, lh: "30px" }, intl.formatMessage({ id: "from_to", defaultMessage: "from {startDate} to {endDate}" }, { startDate: startDate, endDate: endDate })))),
                React.createElement(Group, { gap: 20, justify: "flex-end" },
                    React.createElement(OrchestryBrandingLogo, { base64Value: partnerBrandingImage, imageAlt: "partner-logo" }),
                    React.createElement(OrchestryBrandingLogo, { base64Value: healthCheckBrandingImage, imageAlt: "customer-logo" }))),
            React.createElement(Stack, null,
                React.createElement(HealthCheckActivityTab, null),
                React.createElement(HealthCheckAdditionsTab, null)),
            React.createElement(HealthCheckFooter, { isLoading: isLoading }))));
};
