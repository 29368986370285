import * as React from "react";
import "./lifecycle-archivals-list.scss";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as LifecyclePoliciesListStore from "app/store/lifecycle-policies/list";
import * as TableList from "app/components/common/table-list";
import { SubtitleButton } from "app/components/common/subtitle";
import vivaIcon from "app/assets/img/icon-viva-engage.svg";
var LifecycleArchivalsList = React.memo(function () {
    var dispatch = useDispatch();
    var lifecyclePolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    //
    //console.log("Template LIst Component Templates > ", templates);
    React.useEffect(function () {
        dispatch(LifecyclePoliciesListStore.Load("Archival"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: "lifecycle-archivals-list fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Renewal / Archival Policies", subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find an renewal / archival policy" })),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { exact: true, to: "/lifecycle/policies/archival/details" },
                React.createElement(SubtitleButton, { title: "New Renewal / Archival Policy", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: lifecyclePolicies, asyncListLoadUID: LifecyclePoliciesListStore.LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Policy Name"),
                React.createElement(TableList.HeaderItem, { className: "description-header" }, "Description"),
                React.createElement(TableList.HeaderItem, null, "Application"),
                React.createElement(TableList.HeaderItem, null, "Archival Method"),
                React.createElement(TableList.HeaderItem, null, "Policy Execution"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Inactivity Threshold"),
                React.createElement(TableList.HeaderItem, { type: "actions", mobileTitle: "Actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Name", isMobileTitle: true, link: "/lifecycle/policies/archival/details/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement(React.Fragment, null, item.Application === "Team" ? (React.createElement("div", { className: "application-with-icon", "data-pw": "team-application" },
                        React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-48.png" }),
                        React.createElement("span", null, "Team"))) : item.Application === "TeamSite" ? (React.createElement("div", { className: "application-with-icon teamsite", "data-pw": "team-site-application" },
                        React.createElement("div", { className: "teamsiteviva-label" },
                            React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-48.png" }),
                            React.createElement("span", null, "Team Site")),
                        React.createElement("div", { className: "teamsiteviva-label" },
                            React.createElement("img", { src: vivaIcon }),
                            React.createElement("span", null, "Viva Engage")))) : (React.createElement("div", { className: "application-with-icon commsite", "data-pw": "communication-site-application" },
                        React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-48.png" }),
                        React.createElement("span", null, "Communication Site"))))); } }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", { className: "content-option-with-icon", "data-pw": "archival-method" }, item.Option_Content_Action === "LeaveContentInPlace" ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "las la-archive" }),
                        React.createElement("span", null, "Leave In Place"))) : item.Option_Content_Action === "MoveContentToDifferentSite" ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "las la-file-export" }),
                        React.createElement("span", null, "Move Site"))) : item.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary" ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "las la-file-export" }),
                        React.createElement("span", null, "Move Content Only"))) : (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "las la-trash" }),
                        React.createElement("span", null, "Delete"))))); } }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", { className: "content-option-with-icon", "data-pw": "policy-execution" }, item.Execution_Type === "ApproveExecution" ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "la la-check-circle" }),
                        React.createElement("span", null, "Approve Execution"))) : (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "la la-refresh" }),
                        React.createElement("span", null, "Execute Automatically"))))); } }),
                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "InactivityDaysThreshold" }),
                React.createElement(TableList.ContentItemActions, { onRemove: function (item) { return dispatch(LifecyclePoliciesListStore.DeleteItem(item)); }, deleteCheckItemNameProperty: "Name", deleteCheckType: "lifecycle-archival-policy" })))));
});
export default LifecycleArchivalsList;
