var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import "./guest-common-add.scss";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import PrincipalGuestsPickerModal from "app/components/common/principal-guest-picker-modal";
import { SubtitleButton } from "app/components/common/subtitle";
import GuestService from "app/services/guests";
import OrchModal from "app/components/common/orch-modal";
import AnimatedLoader from "app/components/common/animated-loader";
import PrincipalItemGuest from "app/components/common/principal-item-guest";
import { useIntl } from "react-intl";
var GuestCommonAddForceOpenTimerID; //= setInterval(timer, 5000); //every 5 seconds
var GuestCommonAddGetGuestsTimerID; //= setInterval(timer, 5000); //every 5 seconds
var GuestCommonAddRetrievingGuests = false;
var GuestCommonAdd = React.memo(function (props) {
    var _a = React.useState([]), graphGuestUsers = _a[0], setGraphGuestUsers = _a[1];
    var _b = React.useState(false), savingNewGuests = _b[0], setSavingNewGuests = _b[1];
    var intl = useIntl();
    var _c = React.useState(false), retrievingGuests = _c[0], setRetrievingGuests = _c[1];
    var _d = React.useState(false), forceOpen = _d[0], setForceOpen = _d[1];
    React.useEffect(function () {
        if (GuestCommonAddForceOpenTimerID !== null) {
            clearInterval(GuestCommonAddForceOpenTimerID);
        }
        if (props.forceOpen !== undefined) {
            GuestCommonAddForceOpenTimerID = setTimeout(function () {
                setForceOpen(props.forceOpen);
            }, 500);
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (GuestCommonAddForceOpenTimerID !== null) {
                clearInterval(GuestCommonAddForceOpenTimerID);
            }
        };
    }, [props.forceOpen]);
    React.useEffect(function () {
        if (graphGuestUsers === null) {
            getGuests();
        }
    }, []);
    React.useEffect(function () {
        // setGraphGuestUsers(null);
        getGuests();
        console.log("reload-trigger");
    }, [props.reloadGuestsTrigger]);
    var getGuests = function () {
        // if (GuestCommonAddGetGuestsTimerID !== null) {
        //    clearInterval(GuestCommonAddGetGuestsTimerID);
        // }
        // GuestCommonAddGetGuestsTimerID = setTimeout(() => {
        if (GuestCommonAddRetrievingGuests === false) {
            GuestCommonAddRetrievingGuests = true;
            GuestService.GetGuestsForGroup(props.groupId).then(function (guestUsers) {
                guestUsers.forEach(function (g) {
                    if (g.orchestryProperties !== null && g.orchestryProperties !== undefined) {
                        g.orchestryProperties.isLocked = true;
                    }
                    else {
                        g.orchestryProperties = {
                            isLocked: true,
                            isExistingUser: true,
                            justification: "",
                            existingUserFieldsUpdated: []
                        };
                    }
                });
                setGraphGuestUsers(guestUsers);
                GuestCommonAddRetrievingGuests = false;
            });
        }
        // }, 500);
    };
    var getNewlyAddedGuests = function () {
        var newGuestsToAdd = graphGuestUsers === null || graphGuestUsers === void 0 ? void 0 : graphGuestUsers.filter(function (g) {
            return g.orchestryProperties === undefined ||
                (g.orchestryProperties !== undefined && g.orchestryProperties.isLocked === undefined) ||
                (g.orchestryProperties !== undefined && g.orchestryProperties.isLocked !== undefined && g.orchestryProperties.isLocked === false);
        });
        return newGuestsToAdd !== undefined ? newGuestsToAdd : [];
    };
    var pickerSubmit = function () {
        var newGuestsToAdd = getNewlyAddedGuests();
        if (newGuestsToAdd !== null && newGuestsToAdd.length > 0) {
            setSavingNewGuests(true);
            var payload = {
                GroupId: props.groupId,
                UserIdsWithJustifications: []
            };
            payload.UserIdsWithJustifications = newGuestsToAdd.map(function (ng) { return ({
                UserId: ng.id,
                Justification: ng.orchestryProperties.justification
            }); });
            GuestService.AddGuestsInGroupWithRequestPolicy(payload).then(function (guestUsers) {
                setSavingNewGuests(false);
                setGraphGuestUsers(null);
                if (props.onAddingComplete !== undefined) {
                    props.onAddingComplete();
                }
            });
        }
    };
    return (React.createElement("div", { className: "guest-common-add" },
        graphGuestUsers === null || graphGuestUsers === undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 150, bodyHeight: 38 })) : (React.createElement(PrincipalGuestsPickerModal, { onClosed: props.onPickerClosed, editingUserId: props.currentUserId, selectedGuests: graphGuestUsers, guestRequestPolicy: props.guestRequestPolicy, onGuestSelected: function (principal) {
                setGraphGuestUsers(__spreadArray(__spreadArray([], graphGuestUsers, true), [principal], false));
            }, forceOpen: forceOpen, onPickerSubmit: function () { return pickerSubmit(); }, onGuestRemoved: function (principal) {
                setGraphGuestUsers(function (currentUsers) {
                    return currentUsers
                        ? __spreadArray([], currentUsers.filter(function (g) {
                            return g.id !== principal.id;
                        }), true) : [];
                });
            } },
            React.createElement("div", { className: "add-action" },
                React.createElement(SubtitleButton, { faIcon: "plus", title: intl.formatMessage({ id: "components.lifecycle.common.guest_add.add_guests", defaultMessage: "Add Guests" }), color: "orange" })))),
        React.createElement(OrchModal, { showModalOverride: savingNewGuests, showModalToggleOverride: setSavingNewGuests, showCloseButton: false, allowClose: false, modalClassName: "guest-common-add-modal" },
            React.createElement("div", { className: "loader" },
                React.createElement(AnimatedLoader, { type: "squares", size: "xxsmall" })),
            React.createElement("div", { className: "loader-saving-new-guests" }, intl.formatMessage({ id: "components.lifecycle.common.guest_add.please_wait", defaultMessage: "Requesting guests to be added to the team... please wait" })),
            React.createElement("div", { className: "user-list" }, getNewlyAddedGuests().map(function (guest) { return (React.createElement(PrincipalItemGuest, { principal: guest })); })))));
});
export default GuestCommonAdd;
