import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LocalizationService } from "app/services/localization";

const actionTypes = {
   SetLanguage: "i18n/SET_LANGUAGE",
   SetLoading: "i18n/SET_LOADING"
};

const initialState = {
   lang: "en",
   loadingMessages: true
};

export const reducer = persistReducer({ storage, key: "i18n" }, (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.SetLanguage:
         LocalizationService.currentLocale = action.payload.lang;
         return { ...state, lang: action.payload.lang };
      case actionTypes.SetLoading:
         // console.log("set Loading", action.payload.isLoading);
         return { ...state, loadingMessages: action.payload.isLoading };
      default:
         return state;
   }
});

export const actions = {
   setLanguage: lang => ({ type: actionTypes.SetLanguage, payload: { lang } }),
   setLoading: isLoading => ({ type: actionTypes.SetLoading, payload: { isLoading } })
};
