import * as React from "react";
// import "./orchestry-admin-tool-cosmos-throttling.scss";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggleControl from "app/components/common/form-toggle-control";
import { Portlet, PortletBody } from "app/components/common/portlet";
import PortletAlert from "app/components/common/portlet-alert";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalSearchModal from "app/components/common/principal-search-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TenantListStore from "app/store/tenants/list";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
var OrchestryAdminToolBulkTeamProvisioning = React.memo(function () {
    var dispatch = useDispatch();
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _a = React.useState(), selectedTenant = _a[0], setSelectedTenant = _a[1];
    var _b = React.useState(), selectedUser = _b[0], setSelectedUser = _b[1];
    var _c = React.useState(null), numberOfTeams = _c[0], setNumberOfTeams = _c[1];
    var _d = React.useState(0), numberOfChannels = _d[0], setNumberOfChannels = _d[1];
    var _e = React.useState(0), numberOfMessages = _e[0], setNumberOfMessages = _e[1];
    var _f = React.useState(false), enableUserInsert = _f[0], setEnableUserInsert = _f[1];
    var _g = React.useState(null), submittingDone = _g[0], setSubmittingDone = _g[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
    }, []);
    React.useEffect(function () {
        if (numberOfTeams !== null && numberOfTeams !== undefined && numberOfTeams > 500) {
            setNumberOfChannels(0);
            setNumberOfMessages(0);
            setEnableUserInsert(false);
        }
    }, [numberOfTeams]);
    React.useEffect(function () {
        setSelectedUser(undefined);
    }, [selectedTenant]);
    var queueBulkOperation = function () {
        setSubmittingDone(false);
        var formData = new FormData();
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url =
            "/api/Maintenance/ProvisionBulkTeams?tenantGuid=" +
                selectedTenant +
                "&userId=" +
                selectedUser[0].Id +
                "&numberOfTeams=" +
                numberOfTeams +
                "&maxChannels=" +
                numberOfChannels +
                "&maxNumberOfMessages=" +
                numberOfMessages +
                "&addUsers=" +
                enableUserInsert;
        axiosConfig.responseType = "json";
        axiosConfig.data = formData;
        axios(axiosConfig)
            .then(function (response) {
            setSubmittingDone(true);
        })
            .catch(function (response) { });
    };
    return (React.createElement("div", { className: "home-admin fadein-500ms" },
        React.createElement(PortletAlert, { type: "elevate", icon: "alert", message: React.createElement("div", { style: { fontWeight: 400, fontSize: "12px" } },
                "You must add the correct permissions to your Orchestry Service App in the tenant you are going to provision the teams in.",
                React.createElement("div", null, "Please follow these instructions to add them:"),
                React.createElement("ol", null,
                    React.createElement("li", null,
                        "Go to ",
                        React.createElement("a", { href: "https://portal.azure.com/" }, "Azure Portal"),
                        " for the tenant."),
                    React.createElement("li", null, "Go to App Registrations and click on \"All Applications\""),
                    React.createElement("li", null,
                        "Click on the ",
                        React.createElement("strong", null, "Orchestry Service App [Environment]")),
                    React.createElement("li", null,
                        "Go to API permissions in the left nav > Click ",
                        React.createElement("strong", null, "[+Add a permission]")),
                    React.createElement("li", null,
                        "In the Request API permissions click Microsoft Graph > Application Permissions > Teamwork > Check ",
                        React.createElement("strong", null, "Teamwork.Migrate.All"),
                        " > Click Add Permissions"),
                    React.createElement("li", null,
                        "Finally back on the API permissions page, click the ",
                        React.createElement("strong", null, "[Grant admin consent for Orchestry Software Inc]"),
                        " and follow the prompts!"))) }),
        React.createElement(Portlet, null),
        React.createElement(PortletAlert, { type: "warning", icon: "info", message: "Please leave number of channels and number of messages to zero unless specifically needed. Also do not enabled the random adding of users unless needed. I've exerienced thorttling issues and it doesn't scale well. If you would like to use this feature you can only provision a max of 500 teams." }),
        React.createElement(Portlet, null,
            React.createElement(PortletBody, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Select Tenant"),
                            tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, defaultValue: selectedTenant, list: tenants
                                    .filter(function (t) { return t.DisplayName !== undefined && t.DisplayName !== ""; })
                                    .map(function (t) { return ({
                                    id: t.GUID !== undefined ? t.GUID : "",
                                    value: t.GUID !== undefined ? t.GUID : "",
                                    title: t.DisplayName !== undefined ? t.DisplayName : ""
                                }); }), onChange: function (tenantGuid) {
                                    setSelectedTenant(tenantGuid);
                                } })))),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Default Owner"),
                            selectedTenant !== undefined ? (selectedUser === undefined || (selectedUser !== undefined && selectedUser.length === 0) ? (React.createElement(PrincipalSearchModal, { mode: "users", title: "Find Owners", showSuggested: false, selectedPrincipals: selectedUser, adminTenantGuid: selectedTenant, onPrincipalSelected: function (principal) {
                                    setSelectedUser([principal]);
                                }, onPrinciaplRemoved: function (principal) {
                                    setSelectedUser([]);
                                } },
                                React.createElement(SubtitleButton, { noMargin: true, title: "Select Owner" }))) : (React.createElement("div", null,
                                React.createElement(PrincipalItem, { principal: selectedUser[0], key: selectedUser[0].Id, showBadge: true, onRemove: function (principal) { return setSelectedUser([]); } })))) : (React.createElement(Form.Control, { placeholder: "Select tenant first", disabled: true }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Number of Teams:"),
                            React.createElement(Form.Control, { type: "number", onChange: function (event) { return setNumberOfTeams(Number(event.target.value)); } }))),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Number of Channels (0 for none):"),
                            React.createElement(Form.Control, { value: numberOfChannels.toString(), type: "number", disabled: !(numberOfTeams !== undefined && numberOfTeams !== null && numberOfTeams > 0 && numberOfTeams < 501), onChange: function (event) { return setNumberOfChannels(Number(event.target.value)); } }))),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Number of Messages (0 for none):"),
                            React.createElement(Form.Control, { value: numberOfMessages.toString(), type: "number", disabled: !(numberOfTeams !== undefined && numberOfTeams !== null && numberOfTeams > 0 && numberOfTeams < 501), onChange: function (event) { return setNumberOfMessages(Number(event.target.value)); } })))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Add random owners and members (Up to 30):"),
                            React.createElement(FormToggleControl, { checked: enableUserInsert, disabled: !(numberOfTeams !== undefined && numberOfTeams !== null && numberOfTeams > 0 && numberOfTeams < 501), handleChange: function (checked) { return setEnableUserInsert(checked); }, label: "Add random selection of users from tenant to team" })))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 }, submittingDone === false ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : submittingDone === true ? (React.createElement("div", null, "Submitted - Go checkout Hangfire to see the job!")) : (React.createElement(SubtitleButton, { noMargin: true, title: "Queue Bulk Job in Hangfire", disabled: !(selectedUser !== undefined && selectedUser.length !== 0 && numberOfTeams !== null), onClick: function () { return queueBulkOperation(); } }))))))));
});
export default OrchestryAdminToolBulkTeamProvisioning;
