import withIntlProvider from "appv2/components/orchestry-with-intl-provider-hoc/orchestry-with-intl-provider-hoc.component";
import React from "react";
import { AddEditCustomRecommendationModal } from "./add-edit-custom-recommendation-modal.component";
import { useUpdateCustomRecommendationModal } from "./use-update-custom-recommendation-modal.hook";
var UpdateCustomRecommendationModalContent = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, onRecommendationSuccessfullyUpdated = _b.onRecommendationSuccessfullyUpdated, uniqueId = _b.uniqueId, tenantGuid = _b.tenantGuid;
    var _c = useUpdateCustomRecommendationModal(context, id, onRecommendationSuccessfullyUpdated, uniqueId, tenantGuid), form = _c.form, appliedFilters = _c.appliedFilters, filterGroups = _c.filterGroups, updateFilterState = _c.updateFilterState, handleFormSubmit = _c.handleFormSubmit, isLoading = _c.isLoading, filterGroupErrors = _c.filterGroupErrors;
    return (React.createElement(AddEditCustomRecommendationModal, { form: form, appliedFilters: appliedFilters, filterGroups: filterGroups, updateFilterState: updateFilterState, handleFormSubmit: handleFormSubmit, isLoading: isLoading, filterGroupErrors: filterGroupErrors, context: context, id: id, innerProps: { onRecommendationSuccessfullyUpdated: onRecommendationSuccessfullyUpdated, uniqueId: uniqueId }, title: "Update Custom Recommendation", buttonLabel: "Save" }));
};
export var UpdateCustomRecommendationModal = withIntlProvider(UpdateCustomRecommendationModalContent);
