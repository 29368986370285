import { Grid } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { GuestAccountsTile } from "./tiles/guest-accounts-tile.component";
import { MicrosoftSecureScoreTile } from "./tiles/microsoft-secure-score-tile.component";
import { WorkspaceInactivityTile } from "./tiles/workspace-inactivity-tile.component";
export var BenchmarksSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading;
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.Benchmarks, renderListOfAnnotations: true, sectionTitle: React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.benchmarks_section.title", defaultMessage: "Benchmarks" }) }) },
        React.createElement(Grid, { mt: 8, align: "stretch", grow: true, gutter: "16px", style: { flexWrap: "wrap", gridAutoRows: "min-content" } },
            React.createElement(Grid.Col, { span: 4 },
                React.createElement(WorkspaceInactivityTile, null)),
            React.createElement(Grid.Col, { span: 4 },
                React.createElement(GuestAccountsTile, null)),
            React.createElement(Grid.Col, { span: 4 },
                React.createElement(MicrosoftSecureScoreTile, null)))));
};
