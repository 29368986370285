import { LibraryFeatureCurrentActionTypes } from "./types";
export function Load(libraryfeature) {
    if (libraryfeature === undefined)
        libraryfeature = {};
    //console.log("Load Current LibraryTemplate > ", librarytemplate)
    return {
        type: LibraryFeatureCurrentActionTypes.LibraryFeature_Current_Load,
        payload: libraryfeature
    };
}
export function Update(libraryfeature) {
    return {
        type: LibraryFeatureCurrentActionTypes.LibraryFeature_Current_Update,
        payload: libraryfeature
    };
}
export function Clear() {
    return {
        type: LibraryFeatureCurrentActionTypes.LibraryFeature_Current_Clear
    };
}
export var libraryfeatureCurrentMiddlware = function (store, next, action) {
    // if(action.type === LibraryFeatureCurrentActionTypes.LibraryFeature_Current_Load){
    //     let feat:LibraryFeature = action.payload;
    //     if(feat.Icon !== undefined)
    //         feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //     // if(feat.AssociatedLibraryTemplates !== undefined){
    //     //     let atStrings = "";
    //     //     feat.AssociatedLibraryTemplates.forEach((at, index) => {
    //     //         atStrings += at.GUID + "|";
    //     //     })
    //     //     feat._associatedLibraryTemplates = atStrings;
    //     // }
    //     action.payload = feat;
    //     next(action);
    // }
    // else if(action.type === LibraryFeatureCurrentActionTypes.LibraryFeature_Current_Update){
    //     //let feat:LibraryFeature = action.payload;
    //     // fix icon before saving somehow?
    //     // if(feat.Icon !== undefined)
    //     //     feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //     // if(feat.AssociatedLibraryTemplates !== undefined){
    //     //     let atStrings = "";
    //     //     feat.AssociatedLibraryTemplates.forEach((at, index) => {
    //     //         atStrings += at.GUID + "|";
    //     //     })
    //     //     feat._associatedLibraryTemplates = atStrings;
    //     // }
    //     // action.payload = feat;
    //     next(action);
    // } else {
    next(action);
    //}
};
