import * as React from "react";
import { NavLink } from "react-router-dom";
import "./report-column-number-with-link.scss";
var ReportColumnNumberWithLink = React.memo(function (props) {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, (_a = props.item[props.col.ProperyName]) === null || _a === void 0 ? void 0 :
            _a.toLocaleString(),
            "\u00A0",
            props.col.ExtraProperties !== undefined && props.col.ExtraProperties !== null && props.col.ExtraProperties.Icon !== undefined && props.col.ExtraProperties.Icon !== null && (React.createElement(NavLink, { exact: true, to: "/guests/listing/" + props.item.UserId },
                React.createElement("i", { className: props.col.ExtraProperties.Icon }))))));
});
export default ReportColumnNumberWithLink;
