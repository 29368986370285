import * as React from "react";
import "./insights-provisioned.scss";
import { Portlet, PortletBody } from "app/components/common/portlet";
import InsightsService from "app/services/insights";
import AnimatedLoader from "../animated-loader";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import { useIntl } from "react-intl";
var InsightsProvisioned = React.memo(function () {
    var _a = React.useState(), stats = _a[0], setStates = _a[1];
    var intl = useIntl();
    React.useEffect(function () {
        InsightsService.GetProvisionedStats().then(function (statsData) {
            setStates(statsData);
        });
    }, []);
    return (React.createElement(Portlet, { className: "insights-provisioned" },
        React.createElement(PortletBody, null, stats === undefined ? (React.createElement("div", { className: "loading-wrapper" },
            React.createElement(AnimatedLoader, { size: "xsmall", theme: "dark" }))) : (React.createElement("div", { className: "counts" },
            React.createElement("div", { className: "ob-vertical-stat-count-large" },
                React.createElement("div", { className: "count" }, stats === null || stats === void 0 ? void 0 : stats.ProvisionedWorkspaces),
                React.createElement("div", { className: "title" }, intl.formatMessage({ id: "page.home.workspaces_provisioned", defaultMessage: "Workspaces Provisioned" }))),
            React.createElement("div", { className: "ob-vertical-stat-count" },
                React.createElement("div", { className: "logo" },
                    React.createElement("img", { src: iconTeams })),
                React.createElement("div", { className: "title" }, intl.formatMessage({ id: "page.home.teams", defaultMessage: "Teams" })),
                React.createElement("div", { className: "count" }, stats === null || stats === void 0 ? void 0 : stats.ProvisionedWorkspaceTeams)),
            React.createElement("div", { className: "ob-vertical-stat-count" },
                React.createElement("div", { className: "logo" },
                    React.createElement("img", { src: iconSP })),
                React.createElement("div", { className: "title" }, intl.formatMessage({ id: "page.home.SharePoint", defaultMessage: "SharePoint" })),
                React.createElement("div", { className: "count" }, stats === null || stats === void 0 ? void 0 : stats.ProvisionedWorkspaceSharePoint)),
            React.createElement("div", { className: "ob-vertical-stat-count" },
                React.createElement("div", { className: "logo" },
                    React.createElement("img", { src: iconViva })),
                React.createElement("div", { className: "title" }, intl.formatMessage({ id: "page.home.VivaEngageCommunity", defaultMessage: "Viva Engage Community" })),
                React.createElement("div", { className: "count" }, stats === null || stats === void 0 ? void 0 : stats.ProvisionedWorkspaceVivaEngage)))))));
});
export default InsightsProvisioned;
