var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { templateNotificationConfigMap } from "app/store/templates/list/config-notifications";
import { featureNotificationConfigMap } from "app/store/features/list/config-notifications";
import { documentTemplateNotificationConfigMap } from "app/store/document-templates/list/config-notifications";
import { channelNotificationConfigMap } from "app/store/channels/list/config-notifications";
import { requestNotificationConfigMap } from "app/store/requests/list/config-notifications";
import { metadataNotificationConfigMap } from "app/store/metadata/list/config-notifications";
import { approvalWorkflowNotificationConfigMap } from "app/store/approval-workflows/list/config-notifications";
import { tenantConfigurationNotificationConfigMap } from "app/store/tenant-configuration/current/config-notifications";
import { libraryTemplateNotificationConfigMap } from "app/store/library-templates/list/config-notifications";
import { libraryFeatureNotificationConfigMap } from "app/store/library-features/list/config-notifications";
import { libraryChannelNotificationConfigMap } from "app/store/library-channels/list/config-notifications";
import { libraryDocumentTemplateNotificationConfigMap } from "app/store/library-document-templates/list/config-notifications";
import { libraryConfigurationNotificationConfigMap } from "app/store/library-configuration/current/config-notifications";
import { orchestryContextNotificationConfigMap } from "app/store/orchestry-context/current/config-notifications";
import { tenantCurrentNotificationConfigMap } from "app/store/tenants/current/config-notifications";
import { tenantNotificationConfigMap } from "app/store/tenants/list/config-notifications";
import { namingPolicyNotificationConfigMap } from "app/store/naming-policies/list/config-notifications";
import { lifecyclePoliciesNotificationConfigMap } from "app/store/lifecycle-policies/list/config-notifications";
import { lifecyclePolicyWorkspacesNotificationConfigMap } from "app/store/lifecycle-policy-workspaces/current/config-notifications";
import { guestNotificationConfigMap } from "../lifecycle-guest-policies/current/config-notifications";
import { lifecycleReportNotificationConfigMap } from "../lifecycle-report/search/config-notifications";
export var notificationConfigMap = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], featureNotificationConfigMap, true), templateNotificationConfigMap, true), documentTemplateNotificationConfigMap, true), channelNotificationConfigMap, true), requestNotificationConfigMap, true), metadataNotificationConfigMap, true), approvalWorkflowNotificationConfigMap, true), tenantConfigurationNotificationConfigMap, true), libraryFeatureNotificationConfigMap, true), libraryTemplateNotificationConfigMap, true), libraryChannelNotificationConfigMap, true), libraryDocumentTemplateNotificationConfigMap, true), libraryConfigurationNotificationConfigMap, true), orchestryContextNotificationConfigMap, true), tenantNotificationConfigMap, true), tenantCurrentNotificationConfigMap, true), namingPolicyNotificationConfigMap, true), lifecyclePoliciesNotificationConfigMap, true), lifecyclePolicyWorkspacesNotificationConfigMap, true), guestNotificationConfigMap, true), lifecycleReportNotificationConfigMap, true);
