import PortletAlert from "app/components/common/portlet-alert";
import WorkspaceLinks from "app/components/common/workspace-links";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import TeamInformationActions from "../team-information-actions";
import { useTeamInformationActions } from "../team-information-actions/team-information-actions.hooks";
import { TeamInformationHeader } from "../team-information-header";
import { TeamInformationPageUsersWebpart } from "../team-information-page-users/team-information-page-users-webpart/team-information-page-users-webpart.component";
import TeamInformationUserSummary from "../team-information-user-summary";
import "./team-information-page-details.scss";
var TeamInformationPageDetails = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f;
    var teamLinks = useSelector(function (state) { return state.teamInformationState.teamLinks; });
    var teamInformationWebPartState = useSelector(function (state) { return state.teamInformationWebPartState; });
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var showPageUsers = ((_a = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _a === void 0 ? void 0 : _a.showUserDetails) !== false && isInSharepoint;
    var shouldShowActions = useTeamInformationActions();
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var tenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var showSecondColumn = (((_b = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _b === void 0 ? void 0 : _b.showActions) !== false && shouldShowActions) || ((_c = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _c === void 0 ? void 0 : _c.showUserSummary) !== false;
    var multiGeoNotSupported = tenantConfiguration.IsMultiGeoEnabled && teamInformation && (teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.Geo) !== tenantConfiguration.DefaultGeo;
    return (React.createElement("div", { className: clsx("team-information-page-details", { "team-information-page-details-sharepoint": isInSharepoint }) },
        React.createElement(TeamInformationHeader, null),
        multiGeoNotSupported && (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.multigeo_not_supported", defaultMessage: "This experience is not currently supported as this workspace exists outside the tenant's default geo." }) })),
        React.createElement(Row, null,
            ((_d = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _d === void 0 ? void 0 : _d.showLinks) !== false && (React.createElement(Col, null,
                React.createElement(WorkspaceLinks, { links: teamLinks, showTitle: false }))),
            showSecondColumn && (React.createElement(Col, null,
                ((_e = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _e === void 0 ? void 0 : _e.showActions) !== false && React.createElement(TeamInformationActions, null),
                ((_f = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _f === void 0 ? void 0 : _f.showUserSummary) !== false && React.createElement(TeamInformationUserSummary, null)))),
        showPageUsers && (React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(TeamInformationPageUsersWebpart, null))))));
});
export default TeamInformationPageDetails;
