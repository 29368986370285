var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useState } from "react";
import { createEditor, Transforms, Range } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { Toolbar } from "./components/Toolbar";
import { withInlines } from "./helpers";
import { Leaf } from "./components/Leaf";
import { Element } from "./components/Element";
import FooterImageUploadBox from "app/components/common/form-footeruploadbox";
import isKeyHotkey from "is-hotkey";
import "./form-markdowneditor.scss";
/**
 * @param {string} layout - Layout of the form (default|image|imagentext|text)
 * @param {InterfaceStyles} enabledStyles - Styles that are enabled in the editor toolbar
 * @param {string} initialTextString - Optional - String to be displayed in the editor by default, otherwise the editor will be empty
 * @param {string} [templateImage] - Optional - Base64 string of the image to be displayed in the template (provide for default)
 * @param {React.CSSProperties} [style] - Optional - Styles applied to the workspace container (main container)
 * @param {string} [imageLink] - Optional - Link to wrap the image in (provide for default)
 * @param {string} [templateText] - Optional - Text to be displayed in the footer (provide for default)
 * @param {React.Dispatch<React.SetStateAction<Descendant[]>} settemplateText - State Function to set the template text
 * @param {React.Dispatch<React.SetStateAction<string>>} [setTemplateImage] - Optional - State Function to set the template image
 * @param {React.Dispatch<React.SetStateAction<string>>} [setImageLink] - Optional - State Function to set the image link
 */
var jsonStringTest = /\'?\[\{/;
export var MarkdownEditor = function (_a) {
    var templateImage = _a.templateImage, imageLink = _a.imageLink, setImageLink = _a.setImageLink, templateText = _a.templateText, setTemplateImage = _a.setTemplateImage, _b = _a.layout, layout = _b === void 0 ? "text" : _b, enabledStyles = _a.enabledStyles, initialTextString = _a.initialTextString, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c, style = _a.style, onChange = _a.onChange;
    var editorRef = React.useRef();
    if (!editorRef.current)
        editorRef.current = withInlines(withReact(createEditor()));
    var editor = editorRef.current;
    var onKeyDown = function (event) {
        var selection = editor.selection;
        if (selection && Range.isCollapsed(selection)) {
            var nativeEvent = event.nativeEvent;
            if (isKeyHotkey("left", nativeEvent)) {
                event.preventDefault();
                Transforms.move(editor, { unit: "offset", reverse: true });
                return;
            }
            if (isKeyHotkey("right", nativeEvent)) {
                event.preventDefault();
                Transforms.move(editor, { unit: "offset" });
                return;
            }
        }
    };
    var _d = useState(
    //@ts-ignore
    function () { return (jsonStringTest.test(templateText) ? JSON.parse(templateText) : [{ type: "text", children: [{ text: templateText || initialTextString || "" }] }]); }), value = _d[0], setValue = _d[1];
    var renderLeaf = React.useCallback(function (props) { return React.createElement(Leaf, __assign({}, props)); }, []);
    var slateChangeHandler = function (value) {
        setValue(value);
        var isAstChange = editor.operations.some(function (op) { return "set_selection" !== op.type; });
        if (isAstChange && onChange) {
            var safeValue = JSON.stringify(value);
            onChange(safeValue);
        }
    };
    return (React.createElement("div", { className: "editor-workspace", "data-pw": "editor", style: __assign({}, style) }, layout !== "default" ? (React.createElement(Slate, { editor: editor, value: value, onChange: slateChangeHandler },
        readOnly ? null : React.createElement(Toolbar, { layout: layout, enabledStyles: enabledStyles }),
        React.createElement("div", { className: "editor ".concat(readOnly ? "" : "border", " ").concat(layout === "text" || layout === "imagentext" || readOnly ? "" : "no-text").trim() },
            React.createElement("div", { className: "imageUpload ".concat(layout === "imagentext" || layout === "image" ? "" : "hidden") },
                React.createElement(FooterImageUploadBox, { defaultBase64Image: templateImage, onDrop: function (imageData) { return setTemplateImage === null || setTemplateImage === void 0 ? void 0 : setTemplateImage(imageData); }, setImageLink: setImageLink, imageLink: imageLink, layout: layout })),
            React.createElement("div", { className: "editable ".concat(layout === "image" ? "hidden" : "") },
                React.createElement(Editable, { id: "editable", spellCheck: true, autoFocus: true, readOnly: readOnly, renderElement: function (props) { return React.createElement(Element, __assign({}, props)); }, renderLeaf: renderLeaf, placeholder: "Enter Text Here ...", style: { width: "100%" }, onKeyDown: onKeyDown }))))) : null));
};
export default MarkdownEditor;
