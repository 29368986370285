var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-image-upload-gallery.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import FileService from "app/services/files";
import generateGuid from "app/utils/generateGuid";
var FormImageUploadGallery = React.memo(function (props, state) {
    //class FormImageUploadBox extends React.Component<IProps> {
    //const [image, setImage] = React.useState(props.defaultBase64Image ? props.defaultBase64Image : "");
    var _a = React.useState(), fileUploadPercentage = _a[0], setFileUploadPercentage = _a[1];
    var _b = React.useState([]), images = _b[0], setImages = _b[1];
    var _c = React.useState(), imageGuids = _c[0], setImageGuids = _c[1];
    React.useEffect(function () {
        var imagesString = "";
        images.forEach(function (img, index) {
            imagesString += img.GUID;
            if (index + 1 < images.length) {
                imagesString += "|";
            }
        });
        console.log("Images Changes", props.ImageGuids, imagesString);
        if (props.ImageGuids !== imagesString) {
            if (props.OnImageGuidsChange !== undefined) {
                props.OnImageGuidsChange(imagesString);
            }
        }
    }, [images]);
    var onDrop = React.useCallback(function (acceptedFiles) {
        // Do something with the files
        //console.log("acceptedFiles", acceptedFiles);
        //let readBytesCompleted = 0;
        acceptedFiles.forEach(function (acceptedFile) {
            // Ensure it's an image
            if (acceptedFile.type.match(/image.*/)) {
                console.log("it's an image!", acceptedFile);
                var reader = new FileReader();
                //reader.readAsDataURL(acceptedFile);
                reader.onload = function (event) {
                    var _a;
                    var image = new Image();
                    image.onload = function (imageEvent) {
                        var _a;
                        // Resize the image
                        var canvas = document.createElement("canvas"), max_size = props.maxSize !== undefined ? props.maxSize : 800, width = image.width, height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        }
                        else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        (_a = canvas.getContext("2d")) === null || _a === void 0 ? void 0 : _a.drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL("image/jpeg");
                        var tempImages = __spreadArray([], images, true);
                        var imageGuid = generateGuid();
                        tempImages.push({ GUID: imageGuid, Base64String: dataUrl });
                        uploadFile(dataURLToBlob(dataUrl), imageGuid, tempImages);
                        //setImages([...tempImages]);
                        //console.log("resized image", dataUrl);
                        //setImage(String(dataUrl));
                    };
                    //console.log("original image", String(event.target?.result));
                    image.src = String((_a = event.target) === null || _a === void 0 ? void 0 : _a.result);
                };
                reader.readAsDataURL(acceptedFile);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);
    var dataURLToBlob = function (dataURL) {
        var BASE64_MARKER = ";base64,";
        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            var parts = dataURL.split(",");
            var contentType = parts[0].split(":")[1];
            var raw = parts[1];
            return new Blob([raw], { type: contentType });
        }
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(":")[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    };
    React.useEffect(function () {
        if (props.ImageGuids !== undefined && props.ImageGuids !== null && props.ImageGuids !== "" && props.ItemGuid !== undefined) {
            var loadImages = props.ImageGuids.split("|");
            var tempImages_1 = [];
            loadImages.forEach(function (imagGuid) {
                tempImages_1.push({ GUID: imagGuid, ImageUrl: props.ApiUrlGet + "?itemGuid=" + props.ItemGuid + "&imageGuid=" + imagGuid + "&thumbnail=true" });
            });
            setImages(tempImages_1);
        }
    }, []);
    // React.useEffect(() => {
    //     //console.log("Image Resized!", image);
    //     props.onDrop(image);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[image])
    var dzOptions = {
        onDrop: onDrop
    };
    //if(props.allowMultiple !== undefined)
    dzOptions.multiple = true;
    //5000000 = 5mb
    dzOptions.maxSize = 5000000;
    dzOptions.multiple = false;
    dzOptions.onDropRejected = function (event) {
        alert("File too big. Please upload an image smaller than 3mb");
    };
    var _d = useDropzone(dzOptions), getRootProps = _d.getRootProps, getInputProps = _d.getInputProps;
    //const {getRootProps, getInputProps} = useDropzone();
    var onUploadProgress = function (progress) {
        setFileUploadPercentage((progress.loaded / progress.total) * 100 + "%");
        console.log("Progress", progress);
    };
    var uploadFile = function (file, itemGuid, tempImages) {
        var continueUpload = function () {
            if (file !== undefined && props.ItemGuid !== undefined) {
                var formData = new FormData();
                formData.append("file", file);
                formData.append("parent-item-guid", props.ItemGuid);
                formData.append("item-guid", itemGuid);
                FileService.uploadDocument(formData, props.ApiUrlUpload, onUploadProgress).then(function (data) {
                    setFileUploadPercentage(undefined);
                    setImages(__spreadArray([], tempImages, true));
                    //console.log("upload done", imagesString);
                });
            }
        };
        if (props.BeforeUpload !== undefined) {
            props.BeforeUpload().then(function () {
                continueUpload();
            });
        }
        else {
            continueUpload();
        }
    };
    var deleteImage = function (image) {
        var formData = new FormData();
        formData.append("parent-item-guid", props.ItemGuid);
        formData.append("item-guid", image.GUID);
        FileService.deleteDocument(formData, props.ApiUrlDelete).then(function (data) {
            // if(props.OnImageGuidsChange !== undefined){
            //     props.OnImageGuidsChange(data);
            var tempImages = images.filter(function (i) { return i.GUID !== image.GUID; });
            setImages(tempImages);
            //}
        });
    };
    return (React.createElement("div", { className: "form-image-upload-gallery" },
        images.length > 0 ? (React.createElement("div", { className: "gallery" },
            React.createElement("div", { className: "gallery-content" }, images.map(function (image, index) { return (React.createElement("div", { className: "image-wrapper", key: index },
                image.ImageUrl !== undefined ? (React.createElement("div", { className: "image-preview", style: { backgroundImage: "url('" + image.ImageUrl + "')" } })) : (React.createElement("div", { className: "image-preview", style: { backgroundImage: "url('" + image.Base64String + "')" } })),
                React.createElement("div", { className: "delete btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () { return deleteImage(image); } },
                    React.createElement(FontAwesomeIcon, { icon: faTrashAlt })))); })))) : null,
        React.createElement("div", __assign({ className: "image-upload-button" }, getRootProps()),
            React.createElement("div", { className: "imageupload  btn btn-success" },
                React.createElement("input", __assign({}, getInputProps())),
                React.createElement("div", { className: "" },
                    React.createElement(FontAwesomeIcon, { icon: faUpload }),
                    React.createElement("span", { style: { marginLeft: "15px" } }, "Upload Image"))))));
});
export default FormImageUploadGallery;
