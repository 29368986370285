import * as React from "react";
import "./settings-installation-webpart-status.scss";
import { useSelector } from "react-redux";
import AnimatedLoader from "app/components/common/animated-loader";
import "react-tagsinput/react-tagsinput.css";
import { PortletHeader, Portlet, PortletBody } from "app/components/common/portlet";
import InstallService from "app/services/install";
import PortletAlert from "app/components/common/portlet-alert";
var SettingsInstallationWebpartStatus = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(), webpartStatus = _a[0], setWebpartStatus = _a[1];
    var _b = React.useState(false), installingWebparts = _b[0], setInstallingWebparts = _b[1];
    var _c = React.useState(""), webpartInstallError = _c[0], setWebpartInstallError = _c[1];
    var _d = React.useState(), permissionRequests = _d[0], setPermissionRequests = _d[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        getAndMaybeWaitForWebPartStatus();
    }, []);
    var getAndMaybeWaitForWebPartStatus = function () {
        InstallService.GetWebPartInstallStatus().then(function (data) {
            setWebpartStatus(data);
            if (data.appCatalogUrl === null) {
                setTimeout(function () {
                    getAndMaybeWaitForWebPartStatus();
                }, 10000);
            }
        });
    };
    var convertUrlToAdmin = function (url) {
        if (url !== undefined) {
            var convertedPieces = url.split(".");
            convertedPieces[0] = convertedPieces[0] + "-admin";
            return convertedPieces.join(".");
        }
        else {
            return "";
        }
    };
    var installOrUpdateWebPart = function () {
        setInstallingWebparts(true);
        InstallService.AddUpdateWebparts().then(function (data) {
            if (data === "") {
                InstallService.GetWebPartInstallStatus().then(function (data) {
                    setWebpartStatus(data);
                    setInstallingWebparts(false);
                });
            }
            else {
                setWebpartInstallError(data);
                setInstallingWebparts(false);
            }
        });
    };
    var getAPIRequests = function () {
        InstallService.GetWebPartAPIRequests().then(function (data) {
            if (data.length > 0) {
                var filteredOrchRequests = data.filter(function (r) { return r.PackageName === (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.packageTitle); });
                if (filteredOrchRequests.length > 0) {
                    setPermissionRequests(data.filter(function (r) { return r.PackageName === (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.packageTitle); }));
                    setTimeout(function () {
                        getAPIRequests();
                    }, 10000);
                }
                else {
                    setPermissionRequests([]);
                }
            }
            else {
                setPermissionRequests([]);
            }
        });
    };
    var areWebpartsUptoDate = function () {
        if (getMetadataWebpartVersion() === (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appLatestVersion)) {
            return true;
        }
        else {
            return false;
        }
    };
    var areWebpartseDeployed = function () {
        if (areWebpartsUptoDate() && (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) != null && webpartStatus.appMetadata.Deployed === true) {
            return true;
        }
        return false;
    };
    var getMetadataWebpartVersion = function () {
        var installedAppMetadata = webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata;
        if (installedAppMetadata !== undefined && installedAppMetadata !== null) {
            var installedAppVersion = installedAppMetadata.AppCatalogVersion.Major +
                "." +
                installedAppMetadata.AppCatalogVersion.Minor +
                "." +
                installedAppMetadata.AppCatalogVersion.Build +
                "." +
                installedAppMetadata.AppCatalogVersion.Revision;
            return installedAppVersion;
        }
        return "";
    };
    return (React.createElement("div", { className: "settings-installation-webpart-status fadein-500ms" },
        React.createElement(Portlet, { className: "portlet-bold-text" },
            React.createElement(PortletHeader, { title: "Orchestry Web Parts Status and Installation" }),
            React.createElement(PortletBody, null,
                orchestryContext.IsCurrentUserAzureGlobalAdmin === false ? (React.createElement(PortletAlert, { type: "danger", icon: "info", size: "small", message: React.createElement("span", null,
                        "In order to perform the following steps you must have the SharePoint Administrator role in the M365 admin center. For more information please see this",
                        " ",
                        React.createElement("a", { rel: "noopener noreferrer", href: "https://docs.microsoft.com/en-us/microsoft-365/admin/add-users/about-admin-roles?view=o365-worldwide", target: "_blank" }, "link")) })) : null,
                webpartStatus !== undefined ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "wp-item" + ((webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appCatalogUrl) === null ? " not-done" : "") },
                        React.createElement("div", { className: "step-info" },
                            React.createElement("div", { className: "step-num-icon" },
                                React.createElement("div", { className: "number" }, "1"),
                                React.createElement("div", { className: "icon" }, (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appCatalogUrl) != null ? React.createElement("i", { className: "fas fa-check" }) : React.createElement("i", { className: "fas fa-times" }))),
                            React.createElement("div", { className: "step-name" }, "SharePoint Application Catalog")),
                        React.createElement("div", { className: "step-content" }, (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appCatalogUrl) !== null ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "text" }, "Great! We've found your SharePoint App Catalog."),
                            React.createElement("a", { href: webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appCatalogUrl, target: "_blank", rel: "noopener noreferrer" }, webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appCatalogUrl))) : (React.createElement("div", { className: "instructions" },
                            React.createElement("p", { style: { color: "red" } }, "You do not have a SharePoint App Catalog created!"),
                            React.createElement("div", { className: "text" }, "Follow these steps to get an App Catalog created and then come back to this page to continue:"),
                            React.createElement("ol", null,
                                React.createElement("li", null,
                                    "Navigate to the",
                                    " ",
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: convertUrlToAdmin(orchestryContext.TenantSharePointUrl) }, "SharePoint Admin Center")),
                                React.createElement("li", null,
                                    "Navigate to the",
                                    " ",
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: convertUrlToAdmin(orchestryContext.TenantSharePointUrl) + "/_layouts/15/online/ManageAppCatalog.aspx" }, "Manage App Catalog"),
                                    " ",
                                    "page."),
                                React.createElement("li", null, "Select the Create a new app catalog site radio"),
                                React.createElement("li", null, "Click OK"),
                                React.createElement("li", null, "Enter a title. We suggest 'App Catalog'"),
                                React.createElement("li", null, "Enter a site url beside the '/sites/' dropdown. We suggest 'app-catalog'"),
                                React.createElement("li", null, "Enter yourself as the Administrator"),
                                React.createElement("li", null, "Click OK"),
                                React.createElement("li", null, "Wait for the catalog to be created. It could take anywhere from 5-10 minutes to show up here."),
                                React.createElement("li", null, "You're done!")))))),
                    React.createElement("div", { className: "wp-item" + ((webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === true && areWebpartseDeployed() === true ? "" : " not-done") },
                        React.createElement("div", { className: "step-info" },
                            React.createElement("div", { className: "step-num-icon" },
                                React.createElement("div", { className: "number" }, "2"),
                                React.createElement("div", { className: "icon" }, (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) != null && areWebpartsUptoDate() === true && areWebpartseDeployed() === true ? (React.createElement("i", { className: "fas fa-check" })) : (React.createElement("i", { className: "fas fa-times" })))),
                            React.createElement("div", { className: "step-name" }, "Web Part Installation & Deployment")),
                        (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appCatalogUrl) === null ? (React.createElement("div", { className: "step-content" }, "You must have a SharePoint App Catalog created before you can install the Orchestry Web Parts.")) : (React.createElement("div", { className: "step-content" },
                            (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === true && areWebpartseDeployed() ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "text" }, "You're running with latest version of the Orchestry web parts."),
                                React.createElement("div", { className: "version-number" },
                                    "webparts-version ",
                                    React.createElement("span", { className: "version-highlight" },
                                        "wp.",
                                        webpartStatus.appLatestVersion)))) : (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === true && areWebpartseDeployed() === false ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "text", style: { color: "red" } }, "You've installed the latest version, but we need to deploy the web parts."),
                                React.createElement("div", { className: "version-number" },
                                    React.createElement(React.Fragment, null,
                                        "webparts-version ",
                                        React.createElement("span", { className: "version-highlight" },
                                            "wp.",
                                            webpartStatus.appLatestVersion))))) : (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === false ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "text", style: { color: "red" } }, "We need to update the Orchestry webparts to the latest version."),
                                React.createElement("div", { className: "version-number" },
                                    "webparts-version ",
                                    getMetadataWebpartVersion(),
                                    " ",
                                    React.createElement("i", { className: "fas fa-angle-double-right" }),
                                    " ",
                                    webpartStatus.appLatestVersion))) : (React.createElement("p", { style: { color: "red" } }, "We need to install the Orchestry webparts in your SharePoint app catalog.")),
                            webpartInstallError === "" ? (React.createElement("div", { className: "instructions" }, installingWebparts ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (React.createElement("div", { className: "btn btn-success install-deploy", onClick: function () { return installOrUpdateWebPart(); } },
                                React.createElement("i", { className: "fas fa-arrow-alt-circle-down" }),
                                (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === true && webpartStatus.appMetadata.Deployed === false ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
                                    (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === true
                                        ? "Reinstall"
                                        : (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === false
                                            ? "Update"
                                            : "Install",
                                    " ",
                                    "and",
                                    " ")),
                                "Deploy Web Parts")))) : (React.createElement("div", { className: "webpart-error" }, webpartInstallError))))),
                    React.createElement("div", { className: "wp-item undetermined" },
                        React.createElement("div", { className: "step-info" },
                            React.createElement("div", { className: "step-num-icon" },
                                React.createElement("div", { className: "number" }, "3"),
                                React.createElement("div", { className: "icon" },
                                    React.createElement("i", { className: "fas fa-cog" }))),
                            React.createElement("div", { className: "step-name" }, "API Access")),
                        (webpartStatus === null || webpartStatus === void 0 ? void 0 : webpartStatus.appMetadata) !== null && areWebpartsUptoDate() === true && areWebpartseDeployed() === true ? (React.createElement("div", { className: "step-content" },
                            React.createElement("div", { className: "text" }, "Follow these steps to approve API access for the Orchestry Web Parts:"),
                            React.createElement("div", { className: "instructions" },
                                React.createElement("ol", null,
                                    React.createElement("li", null,
                                        "Navigate to the",
                                        " ",
                                        React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: convertUrlToAdmin(orchestryContext.TenantSharePointUrl) }, "SharePoint Admin Center")),
                                    React.createElement("li", null,
                                        "Navigate to the",
                                        " ",
                                        React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: convertUrlToAdmin(orchestryContext.TenantSharePointUrl) + "/_layouts/15/online/AdminHome.aspx#/webApiPermissionManagement" }, "API access"),
                                        " ",
                                        "page.",
                                        React.createElement("ul", null,
                                            React.createElement("li", null,
                                                React.createElement("i", null, "Note 1: Sometimes this page takes a couple seconds to load. It will appear as if nothing is under pending request or approved requests, but give it some time.")),
                                            React.createElement("li", null,
                                                React.createElement("i", null, "Note 2: If you're upgrading Orchestry web parts and permissions have remained the same, sometimes just opening this page will approve requests for new versions.")))),
                                    React.createElement("li", null,
                                        "Once loaded, please approve all Microsoft Graph requests for package '", webpartStatus === null || webpartStatus === void 0 ? void 0 :
                                        webpartStatus.packageTitle,
                                        "'."),
                                    React.createElement("li", null,
                                        "Ensure all '", webpartStatus === null || webpartStatus === void 0 ? void 0 :
                                        webpartStatus.packageTitle,
                                        "' are under the approved request group.",
                                        React.createElement("ul", null,
                                            React.createElement("li", null,
                                                React.createElement("i", null, "Note: After approving requests we've noticed a delay of up to 15 minutes for Web Parts to start working as intended.")))))))) : (React.createElement("div", { className: "step-content" }, "To setup API access make sure your App Catalog is created and you've installed the latest version of the Orchestry Web Parts."))))) : (React.createElement("div", { className: "loader" },
                    React.createElement(AnimatedLoader, { size: "small" })))))));
});
export default SettingsInstallationWebpartStatus;
