import { Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
import "./no-results.scss";
export var RecommendationListNoResult = function (_a) {
    var icon = _a.icon, header = _a.header, body = _a.body, view = _a.view, suggestOnClickHandler = _a.suggestOnClickHandler;
    return (React.createElement("div", { className: "recommendations-list-no-results" },
        React.createElement("div", { className: "no-results-body" },
            React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "gray-8", iconClassName: icon }),
            React.createElement(Text, { size: "lg", c: "text-primary.0" }, header),
            React.createElement(Text, { size: "sm", c: "text-tertiary.0" }, body),
            React.createElement("div", { className: "buttons" },
                view !== "search" && (React.createElement(OrchestryButton, { component: "a", target: "_blank", href: "http://7107021.hs-sites.com/knowledge/recommendations-overview", variant: "secondary-gray", size: "md" }, "Learn More")),
                view === "open" && suggestOnClickHandler && (React.createElement(OrchestryButton, { onClick: suggestOnClickHandler, size: "md", variant: "secondary-color", iconType: "font", iconLeft: ORCH_ICONS.lightbulb05 }, "Suggest a Recommendation"))))));
};
