import axios from "axios";
var UsersGuestsService = /** @class */ (function () {
    function UsersGuestsService() {
    }
    UsersGuestsService.currentGraphUsersGuestSearches = [];
    UsersGuestsService.userPhotoResolveBatches = [];
    UsersGuestsService.userResolveBatches = [];
    UsersGuestsService.getSuggestedGuestUsers = function (size) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/guests/Suggestions" + (size !== undefined ? "?size=" + size : "");
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    UsersGuestsService.searchGraphGuests = function (searchTerm, size) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersGuestsService.currentSearches.length + 1)
            if (UsersGuestsService.currentGraphUsersGuestSearches.length > 0) {
                UsersGuestsService.currentGraphUsersGuestSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancelToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                UsersGuestsService.currentGraphUsersGuestSearches.push({
                    id: UsersGuestsService.currentGraphUsersGuestSearches.length + 1,
                    cancelToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');;
                var urlPart = "Search";
                searchTerm = searchTerm.replace("&", "%26");
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/guests/" + urlPart + "?queryText=" + searchTerm + (size !== undefined ? "&size=" + size : "");
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    UsersGuestsService.getGuestByMail = function (mail) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersGuestsService.currentSearches.length + 1)
            if (UsersGuestsService.currentGraphUsersGuestSearches.length > 0) {
                UsersGuestsService.currentGraphUsersGuestSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancelToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (mail.length !== 0) {
                UsersGuestsService.currentGraphUsersGuestSearches.push({
                    id: UsersGuestsService.currentGraphUsersGuestSearches.length + 1,
                    cancelToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');;
                var urlPart = "MailExists";
                mail = mail.replace("&", "%26");
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/guests/" + urlPart + "?mail=" + mail;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    UsersGuestsService.searchCompanies = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersGuestsService.currentSearches.length + 1)
            if (UsersGuestsService.currentGraphUsersGuestSearches.length > 0) {
                UsersGuestsService.currentGraphUsersGuestSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancelToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                UsersGuestsService.currentGraphUsersGuestSearches.push({
                    id: UsersGuestsService.currentGraphUsersGuestSearches.length + 1,
                    cancelToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');;
                var urlPart = "CompanySearch";
                searchTerm = searchTerm.replace("&", "%26");
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/guests/" + urlPart + "?searchTerm=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    UsersGuestsService.addNewGuest = function (guestUpdate, userWhoIsCreatingGuest) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(guestUpdate));
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/AddNewUser?userWhoIsCreatingGuest=" + userWhoIsCreatingGuest;
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Document Template Upload" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    UsersGuestsService.updateGuest = function (guestUpdate) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(guestUpdate));
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/UpdateUser";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Document Template Upload" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    UsersGuestsService.addCompany = function (companyName) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            var cleanName = companyName.includes(";new") ? companyName.split(";")[0] : companyName;
            formData.append("data", JSON.stringify({ CompanyName: cleanName }));
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/AddNewCompany";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    UsersGuestsService.BulkResendGuestInvites = function (payload) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/BulkResendInvite";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(payload));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Axios Response success");
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response error");
                //reject(response);
            });
        });
    };
    UsersGuestsService.BulkResendInviteStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/guests/BulkResendInviteStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    UsersGuestsService.BulkRemoveGuestsFromWorkspace = function (payload) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/BulkRemoveGuestsFromWorkspace";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(payload));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    UsersGuestsService.BulkRemoveGuestsFromWorkspaceStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/guests/BulkRemoveGuestsFromWorkspaceStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    UsersGuestsService.DeleteGuestFromAzure = function (userId) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Guests/DeleteGuest?guestId=" + userId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) { });
        });
    };
    UsersGuestsService.BulkDeleteGuestFromAzure = function (payload) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/BulkDeleteGuestFromAzure";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(payload));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    UsersGuestsService.BulkDeleteGuestFromAzureStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/guests/BulkDeleteGuestFromAzureStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    UsersGuestsService.RemoveGuestFromWorkspace = function (guestId, groupId) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Guests/RemoveGuestFromWorkspace?guestId=" + guestId + "&groupId=" + groupId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                console.error(response);
            });
        });
    };
    UsersGuestsService.BulkRemoveWorkspacesFromGuest = function (payload) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/guests/BulkRemoveWorkspacesFromGuest";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(payload));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    UsersGuestsService.BulkRemoveWorkspacesFromGuestStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/guests/BulkRemoveWorkspacesFromGuestStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return UsersGuestsService;
}());
export default UsersGuestsService;
