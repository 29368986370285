import * as React from "react";
import "./orchestry-admin-request-stats.scss";
import { Row, Col } from "react-bootstrap";
import { Portlet, PortletBody } from "app/components/common/portlet";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import OrchestryService from "app/services/orchestry";
import RequestService from "app/services/requests";
import * as TableList from "app/components/common/table-list";
import * as PageHeader from "app/components/common/page-header";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var OrchestryAdminRequestStats = React.memo(function () {
    var _a = React.useState(), activeRegions = _a[0], setActiveRegions = _a[1];
    var _b = React.useState(null), selectedRegion = _b[0], setSelectedRegion = _b[1];
    var _c = React.useState(), stats = _c[0], setStats = _c[1];
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    React.useEffect(function () {
        if (activeRegions !== undefined) {
            setSelectedRegion(activeRegions[0].value);
        }
    }, [activeRegions]);
    React.useEffect(function () {
        if (selectedRegion !== null) {
            RequestService.GetTenantStats(selectedRegion).then(function (stats) {
                setStats(stats);
            });
        }
    }, [selectedRegion]);
    return (React.createElement("div", { className: "orchestry-admin-request-stats fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Request", subTitle: "Stats" }),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "region-select" }, activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) { return setSelectedRegion(value); } }))),
                        React.createElement(TableList.Context, { listItems: stats, showLoader: false, wrappedInPortlet: false },
                            React.createElement(TableList.TableList, null,
                                React.createElement(TableList.HeaderItem, null,
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Tenant Name"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Pending"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Processing"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Configed Queue Size"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Provisioned 24H"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Failed 24H"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Approval Required 24H"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Approval Rejected 24H"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Provisioned 7D"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Failed 7D"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Approval Required 7D"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Approval Rejected 7D"))),
                                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "DisplayName", isMobileTitle: true }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Pending" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Processing" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "TenantConfigQueueItems" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past24HoursProvisioned" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past24HoursFailed" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past24HoursApprovalRequired" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past24HoursApprovalRejected" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past7DaysProvisioned" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past7DaysFailed" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past7DaysApprovalRequired" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Past7DaysApprovalRejected" })))))))));
});
export default OrchestryAdminRequestStats;
