import { Container, Skeleton } from "@mantine/core";
import clsx from "clsx";
import React from "react";
import "./orchestry-font-icon.scss";
export var OrchestryFontIcon = React.forwardRef(function (_a, ref) {
    var iconClassName = _a.iconClassName, color = _a.color, fontSize = _a.fontSize, fontWeight = _a.fontWeight, isLoading = _a.isLoading, lineHeight = _a.lineHeight, onClick = _a.onClick, variant = _a.variant, marginTop = _a.marginTop;
    if (isLoading) {
        return (React.createElement("div", null,
            React.createElement(Skeleton, { h: fontSize, w: fontSize, circle: true, mt: marginTop })));
    }
    return (React.createElement(Container, { ref: ref, p: 0, m: 0, fz: fontSize, fw: fontWeight, h: fontSize, c: color, lh: lineHeight, mt: marginTop },
        React.createElement("i", { className: clsx("orchestry_font_icon", iconClassName), "data-variant": variant, style: { cursor: !!onClick ? "pointer" : "default" }, onClick: onClick })));
});
