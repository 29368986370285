import * as React from "react";
import "./report-view-members.scss";
import { Modal } from "react-bootstrap";
import LifecycleReportService from "app/services/lifecycle-report";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import OfficeProfilePopover from "app/components/common/office-profile-popover";
import { useIntl, FormattedMessage } from "react-intl";
var LifecycleReportViewMembers = React.memo(function (props) {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(), owners = _b[0], setOwners = _b[1];
    var _c = React.useState(), members = _c[0], setMembers = _c[1];
    var _d = React.useState(), visitors = _d[0], setVisitors = _d[1];
    var _e = React.useState(true), hasAccess = _e[0], setHasAccess = _e[1];
    var _f = React.useState(true), loading = _f[0], setLoading = _f[1];
    var intl = useIntl();
    React.useEffect(function () {
        if (showModal === true) {
            LifecycleReportService.getMembersForReportItem(props.lifecycleReportItem.SiteCollectionUrl).then(function (results) {
                setLoading(false);
                var memResponse = results;
                setHasAccess(memResponse.HasAccess);
                if (memResponse.HasAccess === true) {
                    setOwners(memResponse.Owners);
                    setMembers(memResponse.Members);
                    setVisitors(memResponse.Visitors);
                }
                console.log("Lifecycle Item", memResponse);
            });
        }
    }, [showModal]);
    var renderListOfUsers = function (type) {
        var users = [];
        if (type === "Owners" && owners !== undefined) {
            users = owners;
        }
        if (type === "Members" && members !== undefined) {
            users = members;
        }
        if (type === "Visitors" && visitors !== undefined) {
            users = visitors;
        }
        return (React.createElement("div", { className: "list-of-members" },
            React.createElement("div", { className: "title" },
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, { id: "component.directory.view_members.{type.toLowerCase()}", defaultMessage: type }),
                    type,
                    " ",
                    React.createElement("span", { className: "count" },
                        "(",
                        React.createElement("span", { className: "number" }, users.length),
                        ")"))),
            React.createElement("div", { className: "list" },
                users.map(function (user) { return (React.createElement(OfficeProfilePopover, { type: user.Type, uid: user.UserPrincipalName },
                    React.createElement("div", { className: "user" },
                        React.createElement("div", { className: "pp" },
                            React.createElement(OfficeProfilePicture, { uid: user.UserPrincipalName, displayName: user.DisplayName, size: "sm", type: user.Type })),
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "name" }, user.DisplayName),
                            user.Type === "User" ? (React.createElement("div", { className: "email" },
                                React.createElement("a", { href: "mailto:" + user.UserPrincipalName }, user.UserPrincipalName))) : null)))); }),
                users.length === 0 ? React.createElement("div", { className: "no-users" },
                    React.createElement(FormattedMessage, { id: "component.directory.view_members.there_are_no_types", defaultMessage: "There are no {type}", values: { type: type } })) : null)));
    };
    return (React.createElement("div", { className: "lifecycle-report-view-members" },
        React.createElement("div", { className: "members-button", onClick: function () { return setShowModal(!showModal); } },
            React.createElement("img", { src: "/media/orchestry/svg-imgs/directory-icons/members.png" })),
        showModal === true ? (React.createElement(Modal, { centered: true, show: showModal, onHide: function () { return setShowModal(!showModal); }, animation: true, className: "lifecycle-report-view-members-modal lifecycleReport-modal", backdropClassName: "lifecycle-report-view-members-backdrop lifecycleReport-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null,
                    props.lifecycleReportItem.TemplateType,
                    " Users")),
            React.createElement(Modal.Body, null, loading === true ? (React.createElement("div", { className: "content-loading" },
                React.createElement("div", { className: "loading-skeleton", style: { minHeight: "500px" } },
                    React.createElement("div", { className: "header animated-wave" }),
                    React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                        React.createElement("div", { className: "pic" },
                            React.createElement("div", { className: "circle animated-wave" })),
                        React.createElement("div", { className: "title" },
                            React.createElement("div", { className: "title-box animated-wave" })))); })),
                    React.createElement("div", { className: "header animated-wave" }),
                    React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                        React.createElement("div", { className: "pic" },
                            React.createElement("div", { className: "circle animated-wave" })),
                        React.createElement("div", { className: "title" },
                            React.createElement("div", { className: "title-box animated-wave" })))); }))))) : (React.createElement("div", { className: "content" },
                React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 500 }, hasAccess === false ? (React.createElement("div", { className: "has-access" }, "Sorry but you don't have permissions to view the details of this site")) : (React.createElement(React.Fragment, null,
                    renderListOfUsers("Owners"),
                    renderListOfUsers("Members"),
                    props.lifecycleReportItem.Privacy === "Custom" ? React.createElement(React.Fragment, null, renderListOfUsers("Visitors")) : null)))))),
            React.createElement(Modal.Footer, null,
                React.createElement("div", { className: "buttons" },
                    React.createElement("div", { className: "cancel", onClick: function () { return setShowModal(!showModal); } }, "Close"))))) : null));
});
export default LifecycleReportViewMembers;
