import axios from "axios";
var JobService = /** @class */ (function () {
    function JobService() {
    }
    JobService.GetProgress = function (jobId) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Jobs/GetProgress?jobId=" + jobId;
            axiosConfig.responseType = "json";
            var hasBeenTimeoutRejected = false;
            var hasBeenCompleted = false;
            setTimeout(function () {
                if (hasBeenCompleted === false) {
                    hasBeenTimeoutRejected = true;
                    reject();
                }
            }, 10000);
            axios(axiosConfig)
                .then(function (response) {
                hasBeenCompleted = true;
                if (hasBeenTimeoutRejected === false) {
                    resolve(response.data);
                }
            })
                .catch(function (response) {
                hasBeenCompleted = true;
                if (hasBeenTimeoutRejected === false) {
                    reject(response);
                }
            });
        });
    };
    JobService.GetProgressWithLogs = function (jobId, logTrim) {
        return new Promise(function (resolve, reject) {
            // console.log("logTrim",logTrim)
            if (logTrim === undefined || logTrim === null || !Number.isInteger(logTrim)) {
                logTrim = 0;
            }
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Jobs/GetProgressWithLogs?jobId=" + jobId + "&logtrim=" + logTrim;
            axiosConfig.responseType = "json";
            var hasBeenTimeoutRejected = false;
            var hasBeenCompleted = false;
            setTimeout(function () {
                if (hasBeenCompleted === false) {
                    hasBeenTimeoutRejected = true;
                    reject();
                }
            }, 10000);
            axios(axiosConfig)
                .then(function (response) {
                hasBeenCompleted = true;
                if (hasBeenTimeoutRejected === false) {
                    resolve(response.data);
                }
            })
                .catch(function (response) {
                hasBeenCompleted = true;
                if (hasBeenTimeoutRejected === false) {
                    reject(response);
                }
            });
        });
    };
    JobService.GetCompleteProgressLogsFromRequest = function (requestGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Requests/GetCompleteProgressLog?guid=" + requestGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return JobService;
}());
export default JobService;
