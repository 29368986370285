var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./lifecycle-archival-details.scss";
import "app/components/lifecycle/workspaces/policies/cards/step-card.scss";
import { useSelector, useDispatch } from "react-redux";
import LifecycleWizard from "app/components/insights/common/lifecycle-wizard";
import StepCardName from "app/components/lifecycle/workspaces/policies/cards/step-card-name";
import StepCardSelectAction from "app/components/lifecycle/workspaces/policies/cards/step-card-select-action";
import StepCardArchiveActions from "app/components/lifecycle/workspaces/policies/cards/step-card-archive-actions";
import StepCardPolicyExecution from "app/components/lifecycle/workspaces/policies/cards/step-card-policy-execution";
import StepCardSecondStage from "app/components/lifecycle/workspaces/policies/cards/step-card-second-stage";
import StepCardNotificationFormat from "app/components/lifecycle/workspaces/policies/cards/step-card-notification-format";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import * as LifecyclePolicyListStore from "app/store/lifecycle-policies/list";
import LifecycleTabs from "app/components/insights/common/lifecycle-tabs";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { AsyncWait } from "app/components/common/async";
import { Redirect, useHistory } from "react-router-dom";
import Store from "app/store";
import generateGuid from "app/utils/generateGuid";
var LifecycleArchivalDetails = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var lifecyclePolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var history = useHistory();
    // const policies = useSelector((state: RootState) => state.lifecyclePoliciesListState);
    var _a = React.useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var dispatch = useDispatch();
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var wizardSteps = React.useState([
        {
            title: "Name",
            key: "name",
            card: StepCardName,
            complete: false,
            current: true,
            hidden: false
        },
        {
            title: "Select Archival Method",
            key: "selectaction",
            card: StepCardSelectAction,
            complete: false,
            current: false,
            hidden: false
        },
        {
            title: "Configure Actions",
            key: "actions",
            card: StepCardArchiveActions,
            complete: true,
            current: false,
            hidden: false
        },
        {
            title: "Policy Execution",
            key: "policyexecution",
            card: StepCardPolicyExecution,
            complete: true,
            current: false,
            hidden: false
        },
        {
            title: "Second Step",
            key: "secondstep",
            card: StepCardSecondStage,
            complete: true,
            current: false,
            hidden: false
        },
        {
            title: "Notification Format",
            key: "notification",
            card: StepCardNotificationFormat,
            complete: true,
            current: false,
            hidden: false
        }
    ])[0];
    var tabs = React.useState([
        {
            title: "Name",
            key: "name",
            card: StepCardName,
            valid: true,
            hidden: false
        },
        {
            title: "Select Archival Method",
            key: "selectaction",
            card: StepCardSelectAction,
            valid: true,
            hidden: false
        },
        {
            title: "Configure Actions",
            key: "actions",
            card: StepCardArchiveActions,
            valid: true,
            hidden: false
        },
        {
            title: "Policy Execution",
            key: "policyexecution",
            card: StepCardPolicyExecution,
            valid: true,
            hidden: false
        },
        {
            title: "Second Step",
            key: "secondstep",
            card: StepCardSecondStage,
            valid: true,
            hidden: false
        },
        {
            title: "Notification Format",
            key: "notification",
            card: StepCardNotificationFormat,
            valid: true,
            hidden: false
        }
    ])[0];
    React.useEffect(function () {
        // console.log("currentPolicy", currentPolicy)
        if (currentPolicy.GUID !== undefined) {
            setIsLoading(false);
        }
    }, [currentPolicy]);
    React.useEffect(function () {
        //dispatch(LifecyclePolicyCurrentStore.Clear());
        return function () {
            dispatch(LifecyclePolicyCurrentStore.Clear());
        };
    }, []);
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        //dispatch(LifecyclePolicyCurrentStore.Clear());
        //console.log("guid", props.guid);
        if (props.match.params.guid === undefined) {
            // Add a new template with guid.
            dispatch(LifecyclePolicyCurrentStore.Clear());
            dispatch(LifecyclePolicyCurrentStore.Update(LifecyclePolicyCurrentStore.createEmptyPolicy("Archival")));
            dispatch(LifecyclePolicyCurrentStore.Update({
                Notification_Formats: "email|teams-alert",
                WorkspaceExtension_ExtensionType: "Inactivity",
                Execution_Type: "ExecuteAutomatically",
                Execution_Approvers_ApprovalType: "WorkspaceOwners",
                Execution_Approvers_Approval_NotificationText: "This Workspace has been classified as inactive and has been marked for archival. Please review the associated Workspace and choose from one of the options below.",
                Execution_RetryCadence_NotifyEvery: 1,
                Execution_RetryCadence_NotifyEveryUnit: "Days",
                Execution_RetryCadence_NotifyAmountOfTimes: 3,
                Option_Content_Lists_Process: true,
                Option_Content_Lists_Scope: "AllLists",
                Option_Content_Documents_Scope: "AllDocumentLibraries",
                Option_Content_Documents_Versions: "AllVersions",
                Option_Content_Documents_Process: true,
                Option_Content_Pages_Process: true,
                Option_Content_Pages_Versions: "AllVersions",
                Execution_Approvers_Notification_NotificationText: "This notification is informational only and is intended to inform you that the attached workspace has been archived."
            }));
        }
        else if (props.match.params.guid !== undefined && props.match.params.guid.indexOf("clone") > -1) {
            var foundSource = lifecyclePolicies.find(function (p) { var _a; return p.GUID === ((_a = props.match.params.guid) === null || _a === void 0 ? void 0 : _a.replace("clone-", "")); });
            if (foundSource !== undefined) {
                var cloned = JSON.parse(JSON.stringify(foundSource));
                cloned.GUID = generateGuid();
                cloned.Application = null;
                cloned.Description = null;
                cloned.Name = null;
                cloned.Id = undefined;
                cloned.CreatedDate = undefined;
                cloned.UpdatedDate = undefined;
                cloned.Version = undefined;
                console.log("cloned", cloned);
                dispatch(LifecyclePolicyCurrentStore.Update(cloned));
            }
            else {
                history.push("/lifecycle/policies/archival/details");
            }
        }
        else {
            dispatch(LifecyclePolicyCurrentStore.Load("Archival", props.match.params.guid));
        }
    }, [props.match.params.guid]);
    //bandaid to fix null notificaiton text strings for newly edited policies
    React.useEffect(function () {
        if (isLoading === false && currentPolicy.Execution_Approvers_Notification_NotificationText == null) {
            dispatch(LifecyclePolicyCurrentStore.Update({
                Execution_Approvers_Notification_NotificationText: "This notification is informational only and is intended to inform you that the attached workspace has been archived."
            }));
        }
        if (isLoading === false && currentPolicy.Execution_Approvers_Approval_NotificationText == null) {
            dispatch(LifecyclePolicyCurrentStore.Update({
                Execution_Approvers_Approval_NotificationText: "This Workspace has been classified as inactive and has been marked for archival. Please review the associated Workspace and choose from one of the options below."
            }));
        }
    }, [isLoading]);
    var save = function () {
        var asyncCallType = AsyncCallType.Update;
        if (props.match.params.guid === undefined || props.match.params.guid.indexOf("clone") > -1) {
            // First, add the new template to template list
            asyncCallType = AsyncCallType.Add;
            dispatch(LifecyclePolicyListStore.AddCurrentItem());
        }
        else {
            dispatch(LifecyclePolicyListStore.UpdateCurrentItem());
        }
        var state = Store.getState();
        var thePolicy = state.lifecyclePoliciesCurrentState.item;
        if (thePolicy.GUID !== null) {
            console.log("Setup Wait", thePolicy);
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, thePolicy.GUID).then(function () {
                console.log("Save - Create/Update for policy complete");
                setBackToList(true);
                dispatch(LifecyclePolicyCurrentStore.Clear());
            });
        }
    };
    var clearCurrentTemplate = function () {
        dispatch(LifecyclePolicyCurrentStore.Clear());
    };
    var _c = React.useState({
        title: "Policies",
        subTitle: "Create Renewal / Archival Policy",
        buttonCancelText: "Cancel",
        buttonFinishText: "Finish",
        buttonPreviousText: "Previous",
        buttonNextText: "Save and continue to next step",
        navTitle: "Back to Renewal / Archival Policies",
        navRouteString: "/lifecycle/policies/archival/",
        onSave: save
        //onStepsUpdated: updateStepsInConfig
    }), wizardConfig = _c[0], setWizardConfig = _c[1];
    var _d = React.useState({
        title: "Renewal / Archival Policies",
        titleLink: "/lifecycle/policies/archival/",
        buttonCancelText: "Cancel",
        buttonSaveText: "Save",
        onSave: save,
        onClear: clearCurrentTemplate
        //onStepsUpdated: updateStepsInConfig
    }), tabsConfig = _d[0], setTabsConfig = _d[1];
    // React.useEffect(()=>{
    //    //console.log("steps updated");
    // }, [steps])
    var getWizardSteps = function () {
        return wizardSteps.map(function (ws) { return (__assign(__assign({}, ws), { hidden: false })); });
    };
    var getTabs = function () {
        return tabs.map(function (ws) { return (__assign(__assign({}, ws), { hidden: false })); });
    };
    return backToList ? (React.createElement(Redirect, { to: "/lifecycle/policies/archival" })) : (React.createElement(React.Fragment, null, props.match.params.guid === undefined ? (React.createElement(LifecycleWizard, { config: wizardConfig, stepss: getWizardSteps() })) : props.match.params.guid.indexOf("clone") > -1 ? (
    // <LifecycleWizard config={wizardConfig} stepss={wizardSteps.map((ws) => ws.key === "name" ? ws : ({ ...ws, complete: true }))} />
    React.createElement(LifecycleWizard, { config: wizardConfig, stepss: getWizardSteps() })) : (React.createElement(React.Fragment, null,
        React.createElement(LifecycleTabs, { isLoading: isLoading, config: tabsConfig, tabs: getTabs() })))));
});
export default LifecycleArchivalDetails;
