var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { LineChart } from "@mantine/charts";
import "@mantine/charts/styles.css";
import { Skeleton } from "@mantine/core";
import React from "react";
import { OrchestryChartDeltaTooltip } from "../orchestry-chart-delta-tooltip/orchestry-chart-delta-tooltip.component";
/**
 * Renders a Line Chart.
 */
export var OrchestryLineChart = function (_a) {
    var data = _a.data, series = _a.series, curveType = _a.curveType, height = _a.height, withDots = _a.withDots, referenceLines = _a.referenceLines, yAxisRange = _a.yAxisRange, lastPeriodData = _a.lastPeriodData, hideDots = _a.hideDots, withXAxis = _a.withXAxis, withYAxis = _a.withYAxis, dataKey = _a.dataKey, isLoading = _a.isLoading, props = __rest(_a, ["data", "series", "curveType", "height", "withDots", "referenceLines", "yAxisRange", "lastPeriodData", "hideDots", "withXAxis", "withYAxis", "dataKey", "isLoading"]);
    if (isLoading) {
        return React.createElement(Skeleton, __assign({ w: "100%", h: height }, props));
    }
    return (React.createElement(LineChart, __assign({ dataKey: dataKey, h: height, data: data, dotProps: { r: hideDots ? 0 : 3, strokeWidth: hideDots ? 0 : 2, fill: "#fff" }, activeDotProps: { r: hideDots ? 4 : 3, strokeWidth: 0, stroke: "#fff" }, curveType: curveType, series: series, strokeDasharray: "-1 10" //-1 because we want solid line
        , tooltipAnimationDuration: 200, tickLine: "none", gridAxis: "none", withDots: withDots !== null && withDots !== void 0 ? withDots : false, withXAxis: false, withYAxis: false, yAxisProps: { domain: yAxisRange }, referenceLines: referenceLines, tooltipProps: lastPeriodData
            ? {
                content: function (_a) {
                    var label = _a.label, payload = _a.payload;
                    return React.createElement(OrchestryChartDeltaTooltip, { label: label, payload: payload, lastPeriodData: lastPeriodData });
                }
            }
            : undefined }, props)));
};
