/**
 * Enums for Recommendation History Event Type.
 */
export var RecommendationHistoryEventType;
(function (RecommendationHistoryEventType) {
    /**
     * Recommendation was Opened
     */
    RecommendationHistoryEventType["Opened"] = "Opened";
    /**
     * Recommendation was Marked as Resolved by a User
       This does not mean the Recommendation has already been resolved
       but rather, the next time the Recommendation is processed, it will be marked as resolved.
     */
    RecommendationHistoryEventType["MarkedAsResolved"] = "MarkedAsResolved";
    /**
     * Recommendation was Auto Resolved by Orchestry
     */
    RecommendationHistoryEventType["AutoResolved"] = "AutoResolved";
    /**
     * Recommendation was Snoozed by the User
     */
    RecommendationHistoryEventType["Snoozed"] = "Snoozed";
    /**
     * Recommendation was UnSnoozed by the User
     */
    RecommendationHistoryEventType["UnsnoozedByUser"] = "UnsnoozedByUser";
    /**
     * Custom Recommendation was Created
     */
    RecommendationHistoryEventType["Created"] = "Created";
})(RecommendationHistoryEventType || (RecommendationHistoryEventType = {}));
