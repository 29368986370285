var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import { AsyncWait } from "app/components/common/async";
import FontIcon from "app/components/common/font-icon";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import InfoTooltip from "app/components/common/infotooltip";
import * as PageHeader from "app/components/common/page-header";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import ChromeLanguage from "app/components/teams-tabs/team-chrome/chrome-language";
import RequestService from "app/services/requests";
import SensitivityLabelsService from "app/services/sensitivity-labels";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
import * as GuestManagementPolicyCurrentStore from "app/store/lifecycle-guest-policies/current";
import * as MetadataListStore from "app/store/metadata/list";
import * as NamingPolicyCurrentStore from "app/store/naming-policies/current";
import * as RequestCurrentStore from "app/store/requests/current";
import { RequestCurrentActionTypes } from "app/store/requests/current/types";
import * as TemplateListStore from "app/store/templates/list";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as UserCurrentStore from "app/store/users/current";
import generateGuid from "app/utils/generateGuid";
import isOutOfViewport from "app/utils/isOutOfViewport";
import clsx from "clsx";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./request-wizard.scss";
import StepSelectWorkspace from "./steps/1_select";
import StepCustomizeWorkspace from "./steps/2_customize";
import StepDetails from "./steps/3_details";
import StepChannels from "./steps/4_channels";
import StepPermissions from "./steps/5_permissions";
import StepConfirmation from "./steps/6_confirmation";
import StepCompleted from "./steps/7_complete";
export var RequestWizardContext = React.createContext({});
var RequestWizard = React.memo(function (props) {
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var intl = useIntl();
    React.useEffect(function () {
        // we used fixed positioning in the mobile request form.
        document.body.classList.add("diable-transform-to-allow-fixed-positioning");
    }, []);
    var originalSteps = [
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.select_workspace.title", defaultMessage: "Select Workspace" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.select_workspace.nav_title", defaultMessage: "Select Workspace" }),
            disabledContinueButtonText: intl.formatMessage({
                id: "page.request_wizard.step.select_workspace.disabled_button_text",
                defaultMessage: "Please select a Workspace before pressing continue."
            }),
            key: "select-workspace",
            canProgress: false,
            current: true,
            component: StepSelectWorkspace,
            stepEnabled: true
        },
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.customize_workspace.title", defaultMessage: "Customize your Workspace" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.customize_workspace.nav_title", defaultMessage: "Customize" }),
            key: "customize-workspace",
            canProgress: false,
            current: false,
            component: StepCustomizeWorkspace,
            stepEnabled: true
        },
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.details.title", defaultMessage: "What would you like to create?" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.details.nav_title", defaultMessage: "Details" }),
            disabledContinueButtonText: intl.formatMessage({ id: "page.request_wizard.step.details.disabled_button_text", defaultMessage: "Please complete the form below before continuing" }),
            key: "create",
            canProgress: false,
            current: false,
            component: StepDetails,
            stepEnabled: true
        },
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.channels.title", defaultMessage: "Let's setup your channels" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.channels.nav_title", defaultMessage: "Channels" }),
            subtitle: intl.formatMessage({ id: "page.request_wizard.step.channels.sub_title", defaultMessage: "Channels help you organize your teams" }),
            key: "channels",
            canProgress: false,
            current: false,
            component: StepChannels,
            stepEnabled: true
        },
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.permissions.title", defaultMessage: "Permissions" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.permissions.nav_title", defaultMessage: "Permissions" }),
            subtitle: intl.formatMessage({ id: "page.request_wizard.step.permissions.sub_title", defaultMessage: "Select who would you like to share this with" }),
            disabledContinueButtonText: intl.formatMessage({
                id: "page.request_wizard.step.permissions.disabled_button_text",
                defaultMessage: "Please select a Permission level for the Workspace before continuing"
            }),
            key: "permissions",
            canProgress: false,
            current: false,
            component: StepPermissions,
            stepEnabled: true
        },
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.confirm.title", defaultMessage: "Almost done!" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.confirm.nav_title", defaultMessage: "Confirm" }),
            subtitle: intl.formatMessage({ id: "page.request_wizard.step.confirm.disabled_button_text", defaultMessage: "Please have a look and confirm your details before completing setup." }),
            key: "confirmation",
            canProgress: false,
            current: false,
            component: StepConfirmation,
            stepEnabled: true
        },
        {
            title: intl.formatMessage({ id: "page.request_wizard.step.done.title", defaultMessage: "Done!" }),
            navTitle: intl.formatMessage({ id: "page.request_wizard.step.done.nav_title", defaultMessage: "Confirm" }),
            key: "complete",
            canProgress: true,
            current: false,
            component: StepCompleted,
            stepEnabled: true
        }
    ];
    var _a = React.useState({}), previousStep = _a[0], setPreviousStep = _a[1];
    var _b = React.useState([]), steps = _b[0], setSteps = _b[1];
    var _c = React.useState([]), stepNavigateMethods = _c[0], setStepNavigateMethods = _c[1];
    var _d = React.useState([]), skipStepKeys = _d[0], setSkipStepKeys = _d[1];
    var _e = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "select-workspace"), hash = _e[0], setHash = _e[1];
    var dispatch = useDispatch();
    var availableTemplates = useSelector(function (state) { return state.templateListState.items; });
    var user = useSelector(function (state) { return state.userCurrentState.item; });
    var listMetadata = useSelector(function (state) { return state.metadataListState.items; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentNamingPolicy = useSelector(function (state) { return state.namingPolicyCurrentState.item; });
    var _f = React.useState(false), hasRemovedSelfFromOwners = _f[0], setHasRemovedSelfFromOwners = _f[1];
    var _g = React.useState(false), isSiteUrlCustom = _g[0], setIsSiteUrlCustom = _g[1];
    var _h = React.useState(false), nextPageAnimate = _h[0], setNextPageAnimate = _h[1];
    var _j = React.useState(false), descriptionFocused = _j[0], setDescriptionFocused = _j[1];
    var stepDivRef = React.useRef();
    var _k = React.useState([]), cloneTeamChannels = _k[0], setCloneTeamChannels = _k[1];
    var _l = React.useState([]), allTeams = _l[0], setAllTeams = _l[1];
    var _m = React.useState([]), liveOwners = _m[0], setLiveOwners = _m[1];
    var _o = React.useState([]), liveMembers = _o[0], setLiveMembers = _o[1];
    var _p = React.useState(""), nameStorage = _p[0], setNameStorage = _p[1];
    var _q = React.useState(false), hideTopButton = _q[0], setHideTopButton = _q[1];
    var _r = React.useState(), sensitivityLabels = _r[0], setSensitivityLabels = _r[1];
    var _s = React.useState(), sensitivityConfig = _s[0], setSensitivityConfig = _s[1];
    var _t = React.useState(false), sensitivityLabelsLoading = _t[0], setSensitivityLabelsLoading = _t[1];
    var _u = React.useState(), classificationLabels = _u[0], setClassificationLabels = _u[1];
    var _v = React.useState(), classificationConfig = _v[0], setClassificationConfig = _v[1];
    var _w = React.useState(false), classificationLabelsLoading = _w[0], setClassificationLabelsLoading = _w[1];
    var _x = React.useState(false), namingPolicyLoading = _x[0], setNamingPolicyLoading = _x[1];
    var _y = React.useState(null), metadataLoaded = _y[0], setMetadataLoaded = _y[1];
    var currentGuestRequestPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var _z = React.useState(false), guestRequestPolicyLoading = _z[0], setGuestRequestPolicyLoading = _z[1];
    var _0 = React.useState([]), guestStorage = _0[0], setGuestStorage = _0[1];
    // Set tab for testing
    React.useEffect(function () {
        if (availableTemplates.length > 0) {
            // setStepProgress("select-workspace", true);
            // setStepProgress("customize-workspace", true);
            // setStepProgress("create", true);
            // setStepProgress("channels", true);
            // setStepProgress("permissions", true);
            // setCurrentStep("permissions");
            // updateCurrentRequest({ Template: availableTemplates[0] });
            // rwc.updateCurrentRequest({ Name: "Skip Channel Title", Description: "This is a perfect description of my new team" });
        }
    }, [availableTemplates]);
    var updateCurrentRequest = function (update) {
        var _a, _b;
        var templateUpdated = false;
        //console.log("Update request", update);
        // If the template is changing from another selected one, or from a null template.
        if (update.Template) {
            if (currentRequest.Template) {
                if (currentRequest.Template.GUID !== update.Template.GUID)
                    templateUpdated = true;
            }
            else {
                templateUpdated = true;
            }
        }
        // If a new template has been assigned then we need to overwrite the request associations.
        // We also need to setup the template type.
        if (templateUpdated) {
            var tmplt_1 = update.Template;
            var stepsToSkip = [];
            update.Name = "";
            update.SiteURL = "";
            setIsSiteUrlCustom(false);
            setCloneTeamChannels(undefined);
            update.RequestType = tmplt_1.TemplateType;
            update.SharePointTemplateType = tmplt_1.SharePointTemplateType;
            if (tmplt_1.PermissionType !== undefined) {
                //@ts-ignore
                update.PermissionType = tmplt_1.PermissionType !== "Choice" ? tmplt_1.PermissionType : undefined;
            }
            else {
                update.RequestType = undefined;
            }
            SensitivityLabelsService.doesTenantHaveLabelsEnabled().then(function (isEnabled) {
                if (isEnabled === true) {
                    if (tmplt_1.SensitivityLabelType !== undefined && tmplt_1.SensitivityLabelType !== null && tmplt_1.SensitivityLabelType !== "None") {
                        var tenantLabelConfig = null;
                        if (currentTenantConfiguration.SensitivityLabelConfigJson !== undefined &&
                            currentTenantConfiguration.SensitivityLabelConfigJson !== null &&
                            currentTenantConfiguration.SensitivityLabelConfigJson !== "") {
                            tenantLabelConfig = JSON.parse(currentTenantConfiguration.SensitivityLabelConfigJson);
                            //console.log("Setting Sen Config", tenantLabelConfig)
                            setSensitivityConfig(tenantLabelConfig);
                        }
                        if (tmplt_1.SensitivityLabelsConfigJson !== undefined && tmplt_1.SensitivityLabelsConfigJson !== null && tenantLabelConfig !== null) {
                            var labels = JSON.parse(tmplt_1.SensitivityLabelsConfigJson);
                            if (labels.length > 0 && tmplt_1.GUID !== undefined) {
                                setSensitivityLabelsLoading(true);
                                SensitivityLabelsService.getTemplateLabels(tmplt_1.GUID).then(function (labels) {
                                    setSensitivityLabels(labels);
                                    setSensitivityLabelsLoading(false);
                                });
                            }
                        }
                    }
                    else {
                        setSensitivityLabels(undefined);
                    }
                }
                else {
                    if (tmplt_1.ClassificationType !== undefined && tmplt_1.ClassificationType !== null && tmplt_1.ClassificationType !== "None") {
                        var tenantClassificationConfig = null;
                        if (currentTenantConfiguration.ClassificationConfigJson !== undefined && currentTenantConfiguration.ClassificationConfigJson !== null) {
                            tenantClassificationConfig = JSON.parse(currentTenantConfiguration.ClassificationConfigJson);
                            //console.log("Setting Sen Config", tenantLabelConfig)
                            setClassificationConfig(tenantClassificationConfig);
                        }
                        if (tmplt_1.ClassificationsConfigJson !== undefined && tmplt_1.ClassificationsConfigJson !== null && tenantClassificationConfig !== null) {
                            var labels_1 = JSON.parse(tmplt_1.ClassificationsConfigJson);
                            if (labels_1.length > 0 && tmplt_1.GUID !== undefined) {
                                setClassificationLabels(tenantClassificationConfig.ClassifiedConfigs.filter(function (cc) { return labels_1.some(function (l) { return l === cc.Key; }); }));
                                setClassificationLabelsLoading(false);
                            }
                        }
                    }
                    else {
                        setClassificationLabels(undefined);
                    }
                }
            });
            if (tmplt_1.LiveTemplateEnabled === true && tmplt_1.LiveTemplateTeamCloneMembers && tmplt_1.GUID !== undefined) {
                RequestService.GetLiveTemplateUsers(tmplt_1.GUID).then(function (data) {
                    setLiveOwners(data.Owners);
                    setLiveMembers(data.Members);
                    updateCurrentRequest({ Owners: data.Owners, Members: data.Members });
                });
            }
            else {
                setLiveOwners([]);
                setLiveMembers([]);
            }
            //Get Naming Convension
            if (tmplt_1.NamingPolicyEnabled !== undefined && tmplt_1.NamingPolicyEnabled === true) {
                if (tmplt_1.NamingPolicyGUID !== undefined) {
                    setNamingPolicyLoading(true);
                    dispatch(NamingPolicyCurrentStore.Load({ GUID: tmplt_1.NamingPolicyGUID }));
                    AsyncWait(undefined, AsyncStatus.Succeeded, tmplt_1.NamingPolicyGUID, undefined, undefined).then(function () {
                        setNamingPolicyLoading(false);
                    });
                }
                else {
                    setNamingPolicyLoading(false);
                    dispatch(NamingPolicyCurrentStore.Clear());
                    // setGuestStorage([]);
                    // updateCurrentRequest({ Guests: [] });
                }
            }
            else {
                setNamingPolicyLoading(false);
                dispatch(NamingPolicyCurrentStore.Clear());
                // setGuestStorage([]);
                // updateCurrentRequest({ Guests: [] });
            }
            //Get GuestPolicy
            // let testPolicyGuid = "eb862726-1e05-434a-06f8-31d4a84a716a";
            // setGuestRequestPolicyLoading(true);
            // dispatch(GuestManagementPolicyCurrentStore.Load(LifecycleGuestPolicyType.Request, testPolicyGuid));
            // AsyncWait(AsyncCallType.Get, AsyncStatus.Succeeded, undefined, undefined, "LifecycleGuestPolicy_Current_Load").then(() => {
            //    setGuestRequestPolicyLoading(false);
            // });
            var guestRequestPolicyGuid = null;
            if (tmplt_1.TemplateType === "Team" || (tmplt_1.TemplateType === "SharePoint" && tmplt_1.SharePointTemplateType === "ModernGroup")) {
                if (tmplt_1.GuestRequestPolicyGuid !== null) {
                    guestRequestPolicyGuid = tmplt_1.GuestRequestPolicyGuid;
                }
                else if (currentTenantConfiguration.LCGuestRequestDefaultEnabled === true) {
                    if (currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID !== null && currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID !== "") {
                        guestRequestPolicyGuid = currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID;
                    }
                }
            }
            if (guestRequestPolicyGuid !== null) {
                setGuestRequestPolicyLoading(true);
                dispatch(GuestManagementPolicyCurrentStore.Load(LifecycleGuestPolicyType.Request, guestRequestPolicyGuid));
                AsyncWait(AsyncCallType.Get, AsyncStatus.Succeeded, undefined, undefined, "LifecycleGuestPolicy_Current_Load").then(function () {
                    setGuestRequestPolicyLoading(false);
                });
            }
            else {
                setGuestRequestPolicyLoading(false);
                dispatch(GuestManagementPolicyCurrentStore.Clear());
            }
            // ADD OR REMOVE Channels Tab
            if (update.RequestType !== undefined) {
                if (update.RequestType === "SharePoint" || update.RequestType === "VivaEngageCommunity") {
                    //setStepEnabled("channels", false);
                    //skipStep("channels");
                    stepsToSkip.push("channels");
                }
                if (update.RequestType === "Team") {
                    if (tmplt_1.AllowChannelsCustomization === true) {
                        //setStepEnabled("channels", true);
                        //removeSkipStep("channels");
                    }
                    else {
                        //setStepEnabled("channels", false);
                        //skipStep("channels");
                        stepsToSkip.push("channels");
                    }
                }
            }
            // If the template has channel associations.
            if (tmplt_1.AssociatedChannels !== undefined && tmplt_1.AssociatedChannels.length > 0) {
                var newRequestChans_1 = [];
                tmplt_1.AssociatedChannels.forEach(function (ass) {
                    var _a, _b, _c;
                    if (ass.Required) {
                        newRequestChans_1.push({
                            GUID: generateGuid(),
                            Title: (_a = ass.Item) === null || _a === void 0 ? void 0 : _a.Title,
                            //@ts-ignore
                            Privacy: (_b = ass.Item) === null || _b === void 0 ? void 0 : _b.Privacy,
                            Description: (_c = ass.Item) === null || _c === void 0 ? void 0 : _c.Description,
                            IsNew: false
                        });
                        //console.log("associated channel", newRequestChans)
                    }
                });
                update.RequestChannels = __spreadArray([], newRequestChans_1, true);
            }
            else {
                update.RequestChannels = [];
            }
            //console.log("update.RequestChannels", update.RequestChannels)
            // If the template has feature associations.
            if (tmplt_1.AssociatedFeatures !== undefined && tmplt_1.AssociatedFeatures.length > 0) {
                var newFeatAss_1 = [];
                tmplt_1.AssociatedFeatures.forEach(function (ass) {
                    if (ass.Required) {
                        newFeatAss_1.push({
                            GUID: generateGuid(),
                            Item: __assign({}, ass.Item)
                        });
                    }
                });
                update.AssociatedFeatures = __spreadArray([], newFeatAss_1, true);
            }
            else {
                update.AssociatedFeatures = [];
            }
            // If the template has feature associations.
            if (tmplt_1.AssociatedDocumentTemplates !== undefined && tmplt_1.AssociatedDocumentTemplates.length > 0) {
                var newDocTempAss_1 = [];
                tmplt_1.AssociatedDocumentTemplates.forEach(function (ass) {
                    if (ass.Required) {
                        newDocTempAss_1.push({
                            GUID: generateGuid(),
                            Item: __assign({}, ass.Item)
                        });
                    }
                });
                update.AssociatedDocumentTemplates = __spreadArray([], newDocTempAss_1, true);
            }
            else {
                update.AssociatedDocumentTemplates = [];
            }
            // ADD OR REMOVE CUSTOMIZE Tab
            if ((tmplt_1.AllowFeatureCustomization === false && tmplt_1.AllowDocumentsCustomization === false) || update.RequestType === "VivaEngageCommunity") {
                //setStepEnabled("customize-workspace", false);
                //skipStep("customize-workspace");
                stepsToSkip.push("customize-workspace");
            }
            else {
                if (((_a = tmplt_1.AssociatedDocumentTemplates) === null || _a === void 0 ? void 0 : _a.length) === 0 && ((_b = tmplt_1.AssociatedFeatures) === null || _b === void 0 ? void 0 : _b.length) === 0) {
                    stepsToSkip.push("customize-workspace");
                }
                //setStepEnabled("customize-workspace", true);
                //removeSkipStep("customize-workspace");
            }
            setSkipStepKeys(stepsToSkip);
        }
        //setCurrentRequest(Object.assign({}, currentRequest, update));
        dispatch(RequestCurrentStore.Update(update));
        //console.log("Current Request > Updates = ", currentRequest);
    };
    // Load the lists on first run
    React.useEffect(function () {
        setSteps(__spreadArray([], originalSteps, true));
        dispatch(TemplateListStore.Clear());
        dispatch(TemplateListStore.LoadForRequest());
        dispatch(TenantConfigurationCurrentStore.Load());
        dispatch(MetadataListStore.Load([]));
        dispatch(UserCurrentStore.Load());
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Metadata_List_Load").then(function () {
            setMetadataLoaded(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // TeamsService.GetAllTeamsList().then((teamList)=> {
        //    setAllTeams(teamList as any[]);
        // });
        return function () {
            dispatch(RequestCurrentStore.Clear());
        };
    }, []);
    // Set the request user
    React.useEffect(function () {
        if (user !== undefined) {
            dispatch(RequestCurrentStore.Update({ Requestor: user }));
            //setCurrentRequest(Object.assign({}, currentRequest, {Requestor:user}));
            //AppcuesService.IdentifyUser();
        }
    }, [user]);
    // Set the progress property of a step
    var setStepProgress = function (stepKey, canProgress, disableText) {
        var newSteps = [];
        steps.forEach(function (step) {
            if (step.key === stepKey) {
                newSteps.push(__assign(__assign({}, step), { canProgress: canProgress, disabledContinueButtonText: disableText !== undefined ? disableText : step.disabledContinueButtonText }));
            }
            else {
                newSteps.push(__assign({}, step));
            }
        });
        setSteps(newSteps);
    };
    var setStepEnabled = function (stepKey, enabled) {
        var newSteps = [];
        steps.forEach(function (step) {
            if (step.key === stepKey) {
                if (step.stepEnabled !== enabled) {
                    newSteps.push(__assign(__assign({}, step), { stepEnabled: enabled }));
                }
                else {
                    newSteps.push(__assign({}, step));
                }
            }
            else {
                newSteps.push(__assign({}, step));
            }
        });
        //console.log("set step enabled", newSteps, stepKey,enabled)
        setSteps(newSteps);
    };
    // Set the current displayed step
    var setCurrentStep = function (stepKey) {
        var newSteps = [];
        steps.forEach(function (step) {
            var didMatch = false;
            if (stepKey !== undefined) {
                if (step.key === stepKey)
                    didMatch = true;
            }
            if (didMatch) {
                newSteps.push(__assign(__assign({}, step), { current: true }));
            }
            else {
                newSteps.push(__assign(__assign({}, step), { current: false }));
            }
        });
        setSteps(newSteps);
    };
    var updateStepDisabledContinueButtonText = function (stepKey, text) {
        var newSteps = [];
        steps.forEach(function (step) {
            var didMatch = false;
            if (stepKey !== undefined) {
                if (step.key === stepKey)
                    didMatch = true;
            }
            if (didMatch) {
                newSteps.push(__assign(__assign({}, step), { disabledContinueButtonText: text }));
            }
            else {
                newSteps.push(__assign({}, step));
            }
        });
        setSteps(newSteps);
    };
    // Get the currently displayed step
    var getCurrentStep = function () {
        var foundStep = steps.find(function (e) { return e.current === true; });
        return foundStep !== undefined ? foundStep : {};
    };
    // Navigate to previouw step
    var gotoPrevStep = function () {
        var prevStep = steps[getIndexOfCurrentStep() - 1];
        if (prevStep.stepEnabled === false) {
            prevStep = steps[getIndexOfCurrentStep() - 2];
            if (prevStep.stepEnabled === false) {
                prevStep = steps[getIndexOfCurrentStep() - 3];
            }
        }
        if (stepNavigateMethods.some(function (ns) { return ns.stepKey === getCurrentStep().key; })) {
            stepNavigateMethods.find(function (ns) { return ns.stepKey === getCurrentStep().key; }).onNavigateClick();
        }
        setCurrentStep(prevStep.key);
        //clear the current request if you navigate back to the first step
        if (prevStep.key === "select-workspace") {
            reset();
        }
        window.scrollTo(0, 0);
    };
    // Navigate to the next step
    var gotoNextStep = function () {
        var nextStep = steps[getIndexOfCurrentStep() + 1];
        if (nextStep.stepEnabled === false) {
            nextStep = steps[getIndexOfCurrentStep() + 2];
            if (nextStep.stepEnabled === false) {
                nextStep = steps[getIndexOfCurrentStep() + 3];
            }
        }
        if (stepNavigateMethods.some(function (ns) { return ns.stepKey === getCurrentStep().key; })) {
            stepNavigateMethods.find(function (ns) { return ns.stepKey === getCurrentStep().key; }).onNavigateClick();
        }
        stepDivRef.current.classList.add("animate-next-step-1");
        setCurrentStep(nextStep.key);
        //},300)
        window.scrollTo(0, 0);
    };
    var setOnNavigateClick = function (stepKey, onMethod) {
        var newSteps = __spreadArray([], stepNavigateMethods, true);
        if (newSteps.some(function (ns) { return ns.stepKey === stepKey; })) {
            newSteps = newSteps.map(function (ns) {
                return ns.stepKey === stepKey
                    ? {
                        stepKey: stepKey,
                        onNavigateClick: onMethod
                    }
                    : ns;
            });
        }
        else {
            newSteps = __spreadArray(__spreadArray([], newSteps, true), [
                {
                    stepKey: stepKey,
                    onNavigateClick: onMethod
                }
            ], false);
        }
        setStepNavigateMethods(newSteps);
    };
    // Get the index of the current step. duh.
    var getIndexOfCurrentStep = function () {
        var currentStepKey = getCurrentStep().key;
        var foundIndex = 0;
        steps.forEach(function (stp, stpIndex) {
            if (stp.key === currentStepKey) {
                foundIndex = stpIndex;
            }
        });
        return foundIndex;
    };
    var reset = function () {
        setNameStorage("");
        setLiveMembers([]);
        setLiveOwners([]);
        setGuestStorage([]);
        setSteps(__spreadArray([], originalSteps, true));
        setIsSiteUrlCustom(false);
        setHasRemovedSelfFromOwners(false);
        dispatch(NamingPolicyCurrentStore.Clear());
        dispatch(RequestCurrentStore.Clear());
        setDescriptionFocused(false);
        rwc.currentNamingPolicy = undefined;
    };
    // Auto Add and remove steps
    React.useEffect(function () {
        if (steps.length > 0) {
            var newSteps_1 = [];
            steps.forEach(function (step) {
                if (skipStepKeys.includes(step.key)) {
                    if (step.stepEnabled === true) {
                        newSteps_1.push(__assign(__assign({}, step), { stepEnabled: false }));
                    }
                    else {
                        newSteps_1.push(__assign({}, step));
                    }
                }
                else {
                    if (step.stepEnabled === false) {
                        newSteps_1.push(__assign(__assign({}, step), { stepEnabled: true }));
                    }
                    else {
                        newSteps_1.push(__assign({}, step));
                    }
                }
            });
            //console.log("set steps", newSteps);
            setSteps(newSteps_1);
        }
    }, [skipStepKeys]);
    // Save the request to the server.
    var submitRequest = function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, RequestCurrentActionTypes.Request_Current_Add).then(function () {
            gotoNextStep();
        });
        dispatch(RequestCurrentStore.Add(currentRequest));
        //dispatch(RequestListStore.AddItem(currentRequest));
    };
    var getSelectedSensitivityLabel = function () {
        var selectedLabel = null;
        if (currentRequest.SensitivityLabel !== undefined && currentRequest.SensitivityLabel !== null && currentRequest.SensitivityLabel.indexOf("{") > -1) {
            selectedLabel = JSON.parse(currentRequest.SensitivityLabel);
        }
        else {
        }
        return selectedLabel;
    };
    var getOrchestryMetadataValues = function (jsonString) {
        var values = [];
        var currentValuesFromRequest = [];
        if (jsonString === undefined) {
            jsonString = currentRequest.CustomMetadataValuesJson;
        }
        if (jsonString !== undefined) {
            currentValuesFromRequest = JSON.parse(jsonString);
        }
        //console.log("getOrchestryMetadataValues()",currentRequest);
        rwc.listMetadata.forEach(function (lmd) {
            var value = currentValuesFromRequest.find(function (cv) { return cv.FieldGUID === lmd.GUID; });
            if (value === undefined) {
                // value = "{" + lmd.Title + "}";
                value = {
                    FieldGUID: lmd.GUID,
                    FieldType: lmd.FieldType,
                    FieldTitle: lmd.Title,
                    Values: "{" + lmd.Title + "}"
                };
            }
            values.push(value);
        });
        return values;
    };
    // The content of the current wizard.
    var rwc = {
        rootProps: props,
        currentRequest: currentRequest,
        updateCurrentRequest: updateCurrentRequest,
        availableTemplates: availableTemplates,
        steps: steps,
        setStepProgress: setStepProgress,
        setCurrentStep: setCurrentStep,
        getCurrentStep: getCurrentStep,
        gotoPrevStep: gotoPrevStep,
        gotoNextStep: gotoNextStep,
        hasRemovedSelfFromOwners: hasRemovedSelfFromOwners,
        setHasRemovedSelfFromOwners: setHasRemovedSelfFromOwners,
        submitRequest: submitRequest,
        isSiteUrlCustom: isSiteUrlCustom,
        setIsSiteUrlCustom: setIsSiteUrlCustom,
        currentTenantConfiguration: currentTenantConfiguration,
        listMetadata: listMetadata,
        currentUser: user,
        cloneTeamChannels: cloneTeamChannels,
        setCloneTeamChannels: setCloneTeamChannels,
        skipStepKeys: skipStepKeys,
        allTeams: allTeams,
        setAllTeams: setAllTeams,
        liveOwners: liveOwners,
        liveMembers: liveMembers,
        currentNamingPolicy: currentNamingPolicy,
        nameStorage: nameStorage,
        setNameStorage: setNameStorage,
        descriptionFocused: descriptionFocused,
        setDescriptionFocused: setDescriptionFocused,
        reset: reset,
        updateStepDisabledContinueButtonText: updateStepDisabledContinueButtonText,
        sensitivityLabels: sensitivityLabels,
        sensitivityConfig: sensitivityConfig,
        sensitivityLabelsLoading: sensitivityLabelsLoading,
        getSelectedSensitivityLabel: getSelectedSensitivityLabel,
        classificationLabels: classificationLabels,
        classificationConfig: classificationConfig,
        classificationLabelsLoading: classificationLabelsLoading,
        getOrchestryMetadataValues: getOrchestryMetadataValues,
        setOnNavigateClick: setOnNavigateClick,
        metadataLoaded: metadataLoaded,
        currentGuestRequestPolicy: currentGuestRequestPolicy,
        guestStorage: guestStorage,
        setGuestStorage: setGuestStorage
        // hasChangedSiteUrl:hasChangedSiteUrl,
        // setHasChangedSiteUrl:setHasChangedSiteUrl
    };
    // Used for navigation.
    React.useEffect(function () {
        if (window.location.hash !== "") {
            setHash(window.location.hash);
        }
    }, []);
    var checkIfBottomNavigationIsOutOfViewport = function () {
        var isOut = isOutOfViewport(".bottom-area .continue-button");
        setHideTopButton(!isOut.any);
    };
    React.useEffect(function () {
        setTimeout(function () {
            // checkIfBottomNavigationIsOutOfViewport();
        }, 500);
    }, [steps]);
    return (React.createElement(React.Fragment, null, steps !== undefined ? (React.createElement(RequestWizardContext.Provider, { value: rwc },
        React.createElement("div", { className: clsx("request-wizard", { "has-subtitle": getCurrentStep().subtitle !== undefined && getCurrentStep().subtitle !== "" }, { "add-padding": props.addPadding !== undefined && props.addPadding === true }, { "request-wizard-sharepoint": isInSharepoint }) },
            getCurrentStep().key !== "complete" ? (React.createElement("div", { className: "left-side" },
                React.createElement("div", { className: "progress-navigation", "data-pw": "progress-navigation" },
                    React.createElement("ul", { className: "list-unstyled" }, steps.map(function (daStep, stepIndex) {
                        return daStep.stepEnabled === true && daStep.key !== "complete" ? (React.createElement("li", { key: "il-" + stepIndex, onClick: function () {
                                if (daStep.canProgress) {
                                    setCurrentStep(daStep.key);
                                }
                            }, className: (daStep.key === getCurrentStep().key ? " current" : "") +
                                (stepIndex < getIndexOfCurrentStep() ? " done" : "") +
                                (daStep.canProgress === true ? " can-progress" : "") },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement("div", { className: "circle" }),
                                React.createElement("div", { className: "check" },
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faCheck })),
                                React.createElement("div", { className: "title", "data-pw": "title" }, daStep.navTitle)))) : null;
                    }))),
                getCurrentStep().key !== "select-workspace" && getCurrentStep().key !== "customize-workspace" ? (React.createElement("div", { className: "your-workspace", "data-pw": "your-workspace" }, rwc.currentRequest.Template !== undefined ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "selected-workspace-thumb" },
                        React.createElement("div", { className: "title" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.ui.selected_workspace", defaultMessage: "Selected Workspace" })),
                        React.createElement("div", { className: "preview-image" },
                            rwc.currentRequest.Template.Image !== null && rwc.currentRequest.Template.Image !== "" ? (React.createElement("div", { className: "image", style: { backgroundImage: "url('" + rwc.currentRequest.Template.Image + "')" } })) : (React.createElement("div", { className: "missing-image" },
                                React.createElement(FontIcon, { iconString: "cubes" }))),
                            React.createElement("div", { className: "type" },
                                React.createElement("div", { className: "icon" }, rwc.currentRequest.RequestType === "SharePoint" ? (React.createElement("img", { src: iconSP })) : rwc.currentRequest.RequestType === "Team" ? (React.createElement("img", { src: iconTeams })) : (React.createElement("img", { src: iconViva }))),
                                React.createElement("div", { className: "text-wrapper" }, rwc.currentRequest.RequestType === "Team" ? (React.createElement("div", { className: "text" },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.ui.microsoft_teams", defaultMessage: "Microsoft Teams" }))) : rwc.currentRequest.RequestType === "SharePoint" ? (React.createElement("div", { className: "text" },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.ui.sharepoint_online", defaultMessage: "SharePoint Online" }))) : (React.createElement("div", { className: "text" },
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.ui.viva_engage", defaultMessage: "Viva Engage" }))))))),
                    React.createElement("div", { className: "workspace-title", "data-pw": "workspace-title" },
                        React.createElement("h3", null, rwc.currentRequest.Template.Title),
                        React.createElement("div", { className: "description" },
                            React.createElement(MarkdownEditor, { readOnly: true, templateText: rwc.currentRequest.Template.Description, enabledStyles: { all: true } }))))) : (React.createElement(React.Fragment, null)))) : null)) : null,
            React.createElement("div", { className: "right-side", ref: stepDivRef },
                getCurrentStep().key !== "complete" ? (React.createElement("div", { className: "top-area" },
                    React.createElement("div", { className: "wrapper" },
                        React.createElement("div", { className: "step-title" },
                            React.createElement("div", { className: "text", "data-pw": "step-title" }, getCurrentStep().title),
                            getCurrentStep().subtitle !== undefined && getCurrentStep().subtitle !== "" ? React.createElement("div", { className: "sub-text" }, getCurrentStep().subtitle) : React.createElement(React.Fragment, null)),
                        React.createElement("div", { className: "right" + (hideTopButton === true ? " hide-as-bottom-button-visible" : "") },
                            isInSharepoint && (React.createElement("div", { className: "language-selector" },
                                React.createElement(ChromeLanguage, null))),
                            React.createElement("div", { className: "previous" }, getIndexOfCurrentStep() !== 0 && getCurrentStep().key !== "complete" ? (React.createElement("div", { className: "back-button", "data-pw": "back-button", onClick: function () {
                                    gotoPrevStep();
                                } },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.ui.go_to_previous", defaultMessage: "Go to previous step" })))) : (React.createElement(React.Fragment, null))),
                            React.createElement("div", { className: "next" },
                                getCurrentStep().key !== "complete" && getCurrentStep().key !== "confirmation" ? (React.createElement(React.Fragment, null, getCurrentStep().canProgress === true && namingPolicyLoading === false && guestRequestPolicyLoading === false ? (React.createElement("div", { className: "continue-button ", "data-pw": "continue-button", onClick: function () {
                                        gotoNextStep();
                                    } },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.ui.continue", defaultMessage: "Continue" })),
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faArrowRight, style: { marginLeft: "10px" } }))) : (React.createElement(React.Fragment, null, namingPolicyLoading === true || guestRequestPolicyLoading === true ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 37, bodyWidth: 98 })) : getCurrentStep().disabledContinueButtonText !== undefined ? (React.createElement(InfoTooltip, { content: React.createElement("span", { dangerouslySetInnerHTML: {
                                            __html: (getCurrentStep().disabledContinueButtonText !== undefined ? getCurrentStep().disabledContinueButtonText : "")
                                        } }), placement: "left", theme: "dark" },
                                    React.createElement("div", { className: "continue-button disabled", "data-pw": "disabled-continue" },
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "page.request_wizard.ui.continue", defaultMessage: "Continue" })),
                                        React.createElement(FontAwesomeIcon, { icon: Icons.faArrowRight, style: { marginLeft: "10px" } })))) : (React.createElement("div", { className: "continue-button disabled" },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.ui.continue", defaultMessage: "Continue" })),
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faArrowRight, style: { marginLeft: "10px" } }))))))) : null,
                                getCurrentStep().key === "confirmation" ? (React.createElement("div", { className: "continue-button ", "data-pw": "continue-button", onClick: function () {
                                        submitRequest();
                                        gotoNextStep();
                                    } },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.ui.yes_i_am", defaultMessage: "Yes, I am done!" })),
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faCheck, style: { marginLeft: "10px" } }))) : null))))) : null,
                React.createElement("div", { className: "step-content-all" }, steps.map(function (daStep, daIndex) { return (React.createElement("span", { key: "step_content_" + daIndex }, daStep.key === getCurrentStep().key ? (React.createElement("div", { key: "step-container-" + daIndex, className: "step-container" + (daStep.key === getCurrentStep().key ? " current" : "") + (daIndex < getIndexOfCurrentStep() ? " done" : "") }, daStep.key === getCurrentStep().key ? React.createElement(daStep.component, { step: daStep, key: "step-sub-container-" + daIndex }) : null)) : null)); })),
                getCurrentStep().key !== "complete" ? (React.createElement("div", { className: "bottom-area" },
                    React.createElement("div", { className: "wrapper" },
                        React.createElement("div", { className: "step-title" }),
                        React.createElement("div", { className: "right" },
                            React.createElement("div", { className: "previous" }, getIndexOfCurrentStep() !== 0 && getCurrentStep().key !== "complete" ? (React.createElement("div", { className: "back-button", "data-pw": "back-button", onClick: function () {
                                    gotoPrevStep();
                                } },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_wizard.ui.go_to_previous", defaultMessage: "Go to previous step" })))) : (React.createElement(React.Fragment, null))),
                            React.createElement("div", { className: "next" },
                                getCurrentStep().key !== "complete" && getCurrentStep().key !== "confirmation" ? (React.createElement(React.Fragment, null, getCurrentStep().canProgress === true && namingPolicyLoading === false && guestRequestPolicyLoading === false ? (React.createElement("div", { className: "continue-button ", "data-pw": "continue-button", onClick: function () {
                                        gotoNextStep();
                                    } },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.ui.continue", defaultMessage: "Continue" })),
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faArrowRight, style: { marginLeft: "10px" } }))) : (React.createElement(React.Fragment, null, namingPolicyLoading === true && guestRequestPolicyLoading === true ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 37, bodyWidth: 98 })) : getCurrentStep().disabledContinueButtonText !== undefined ? (React.createElement(InfoTooltip, { content: React.createElement("span", { dangerouslySetInnerHTML: {
                                            __html: (getCurrentStep().disabledContinueButtonText !== undefined ? getCurrentStep().disabledContinueButtonText : "")
                                        } }), placement: "left", theme: "dark" },
                                    React.createElement("div", { className: "continue-button disabled", "data-pw": "disabled-continue" },
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "page.request_wizard.ui.continue", defaultMessage: "Continue" })),
                                        React.createElement(FontAwesomeIcon, { icon: Icons.faArrowRight, style: { marginLeft: "10px" } })))) : (React.createElement("div", { className: "continue-button disabled" },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.ui.continue", defaultMessage: "Continue" })),
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faArrowRight, style: { marginLeft: "10px" } }))))))) : null,
                                getCurrentStep().key === "confirmation" ? (React.createElement("div", { className: "continue-button ", "data-pw": "continue-button", onClick: function () {
                                        submitRequest();
                                    } },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.ui.yes_i_am", defaultMessage: "Yes, I am done!" })),
                                    React.createElement(FontAwesomeIcon, { icon: Icons.faCheck, style: { marginLeft: "10px" } }))) : null))))) : null)))) : (React.createElement(React.Fragment, null))));
});
export default RequestWizard;
export var RequestWizardInsideOrchestry = React.memo(function (props) {
    var intl = useIntl();
    return (React.createElement("div", { className: "request-wizard-inside-orchestry", "data-pw": "request-wizard" },
        React.createElement(PageHeader.SetLeftContent, { title: intl.formatMessage({ id: "page.request_details.workspace_request_label", defaultMessage: "Workspace Requests" }), subTitle: intl.formatMessage({ id: "page.request_wizard.ui.new", defaultMessage: "New" }), titleLink: "/requests" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/requests" },
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "page.request_wizard.ui.back_to_requests", defaultMessage: "Back to requests" }), variant: "secondary", faIcon: "arrow-left" }))),
        React.createElement(RequestWizard, null)));
});
