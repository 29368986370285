import * as React from "react";
var EmptyCheck = React.memo(function (props) {
    if (props.content === undefined) {
        return React.createElement(React.Fragment, null);
    }
    if (Array.isArray(props.content)) {
        if (props.content.length > 0) {
            return React.createElement(React.Fragment, null, props.children);
        }
    }
    if (typeof props.content === "string") {
        if (props.returnContent !== undefined && props.returnContent) {
            return React.createElement(React.Fragment, null, props.content);
        }
        else {
            return React.createElement(React.Fragment, null, props.children);
        }
    }
    if (typeof props.content === "object") {
        return React.createElement(React.Fragment, null, props.children);
    }
    return React.createElement(React.Fragment, null);
});
export default EmptyCheck;
