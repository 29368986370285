import { useIsPageLoading } from "appv2/hooks/use-is-page-loading.hook";
import React from "react";
/**
 * Renders a page loaded indicator that is used to determine if the page is loaded.
 * Renders Loading... if the page is still loading for debugging purposes.
 * Used for things like PDF generation.
 */
export var OrchestryPageLoadedIndicator = function () {
    var isPageLoading = useIsPageLoading();
    if (isPageLoading)
        return React.createElement(React.Fragment, null, "Loading...");
    return React.createElement("span", { id: "page-loaded" });
};
