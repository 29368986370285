import { Collapse, Group, Stack } from "@mantine/core";
import { Portlet, PortletBody } from "app/components/common/portlet";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryDataTable } from "appv2/components/orchestry-data-table/orchestry-data-table.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { OrchestryQueryContext } from "appv2/components/orchestry-query-context/orchestry-query-context.component";
import { OrchestrySearchBox } from "appv2/components/orchestry-searchbox/orchestry-searchbox.component";
import { PartnerToolsFilterSelector } from "appv2/pages/partner-tools/partner-tools-filter-selector.component";
import React from "react";
import { usePartnerToolsPage } from "./use-partner-tools-page.hook";
import "./partner-tools-page.scss";
var FILTER_GROUPS = ["LicenseType", "InstallDate_DateOnly", "RenewalDate_DateOnly", "Insights"];
var FILTER_GROUP_TITLES = ["License Type", "Install Date", "Renewal Date", "Insights"];
export var PartnerToolsPage = function () {
    var _a = usePartnerToolsPage(), table = _a.table, isPageLoading = _a.isPageLoading, activePage = _a.activePage, setActivePage = _a.setActivePage, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, licenseTypeFilters = _a.licenseTypeFilters, insightFilters = _a.insightFilters, clearAllFilters = _a.clearAllFilters, removeFilter = _a.removeFilter, totalMspTenantsCount = _a.totalMspTenantsCount, pageTotal = _a.pageTotal, searchTextInputValue = _a.searchTextInputValue, pageOptions = _a.pageOptions, onSettingsButtonClicked = _a.onSettingsButtonClicked, installDateFilter = _a.installDateFilter, setInstallDateFilter = _a.setInstallDateFilter, renewalDateFilter = _a.renewalDateFilter, setRenewalDateFilter = _a.setRenewalDateFilter, onApplyFilters = _a.onApplyFilters, appliedFilters = _a.appliedFilters, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, onSearchBoxXClicked = _a.onSearchBoxXClicked, shouldShowSettingsButton = _a.shouldShowSettingsButton;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "partner-tools-page" },
                React.createElement(OrchestryPageHeader, null,
                    React.createElement(OrchestryPageTitle, { title: "Customer Tenants", subTitle: "Review a consolidated list of all your Orchestry customers, their status and useful insights about their usage patterns." }),
                    React.createElement(OrchestryActionButtons, null, shouldShowSettingsButton && (React.createElement(OrchestryButton, { size: "md", iconType: "font", iconLeft: ORCH_ICONS.settings, variant: "primary", onClick: onSettingsButtonClicked }, "Settings")))),
                React.createElement(Stack, { gap: 8 },
                    React.createElement(Group, { gap: 8, justify: "right" },
                        React.createElement(OrchestrySearchBox, { skeletonWidth: 320, isLoading: isPageLoading, placeholder: "Search for a Tenant", tooltipLabel: "Click to perform a search within your tenants", onXClicked: onSearchBoxXClicked, onChange: onSearchBoxChange, value: searchTextInputValue, onKeyDown: onSearchBoxKeyDown, radius: "6px" }),
                        React.createElement(PartnerToolsFilterSelector, { isLoading: isPageLoading, licenseFilters: licenseTypeFilters, insightFilters: insightFilters, onApply: onApplyFilters, onClear: clearAllFilters, installDateFilter: installDateFilter, onInstallDateChange: setInstallDateFilter, renewalDateFilter: renewalDateFilter, onRenewalDateChange: setRenewalDateFilter })),
                    React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: appliedFilters.some(function (filter) { return filter.selected; }) },
                        React.createElement(OrchestryQueryContext, { filterGroups: FILTER_GROUPS, filterGroupTitles: FILTER_GROUP_TITLES, appliedFilters: appliedFilters, totalResults: totalMspTenantsCount !== null && totalMspTenantsCount !== void 0 ? totalMspTenantsCount : 0, removeFilter: removeFilter, clearAllFilters: clearAllFilters })),
                    React.createElement(Stack, { gap: 0 },
                        React.createElement(OrchestryDataTable, { table: table }),
                        React.createElement(OrchestryPagination, { variant: "table", onResultsPerPageChange: function (value) {
                                setResultsPerPage(value);
                                localStorage.setItem("health-checks-resultsPerPage", value);
                            }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: totalMspTenantsCount !== null && totalMspTenantsCount !== void 0 ? totalMspTenantsCount : 0, pageTotal: pageTotal !== null && pageTotal !== void 0 ? pageTotal : 0, resultOptions: pageOptions })))))));
};
