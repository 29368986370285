import { TenantListActionTypes } from "app/store/tenants/list/types";
import { AsyncCallType } from "app/store/async/types";
export var tenantListActionMap = [
    {
        actionType: TenantListActionTypes.Tenant_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/Tenants",
        responseDataType: {}
    },
    {
        actionType: TenantListActionTypes.Tenant_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/Tenants",
        responseDataType: {}
    },
    {
        actionType: TenantListActionTypes.Tenant_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/Tenants",
        responseDataType: {}
    },
    {
        actionType: TenantListActionTypes.Tenant_List_Load,
        callType: AsyncCallType.List,
        // stateLocationForArray: "tenantListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Tenants",
        responseDataType: []
    },
    {
        actionType: TenantListActionTypes.Tenant_List_Load_Page,
        callType: AsyncCallType.List,
        // stateLocationForArray: "requestListState.items",
        keyType: "typeName",
        ApiUrl: "/api/tenants/Page?ResultsPerPage={paging.ResultsPerPage}&CurrentPage={paging.CurrentPage}&View={view}&SortBy={paging.SortBy}&SortOrder={paging.SortOrder}",
        responseDataType: []
    },
    {
        actionType: TenantListActionTypes.Tenant_List_Refresh,
        callType: AsyncCallType.Refresh,
        // stateLocationForArray: "tenantListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Tenants",
        responseDataType: []
    }
    // {
    //     actionType: TenantListActionTypes.Tenant_List_LoadForRequest,
    //     callType: AsyncCallType.List,
    //     keyType:"typeName",
    //     ApiUrl: "/api/Tenants/RequestAvailable",
    //     responseDataType: [] as Tenant[]
    // }
];
