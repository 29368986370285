import { useTenantEventBrowserApi } from "./use-tenant-event-browser-api.hook";
import { useTenantEventBrowserFilters } from "./use-tenant-event-browser-filters.hook";
import { useTenantEventBrowserTable } from "./use-tenant-event-browser-table-hook";
export var useTenantEventBrowserPage = function () {
    var _a = useTenantEventBrowserFilters(), appliedFilters = _a.appliedFilters, clearAllFilters = _a.clearAllFilters, removeFilter = _a.removeFilter, setAppliedFilters = _a.setAppliedFilters;
    var _b = useTenantEventBrowserApi(appliedFilters), data = _b.data, isFetching = _b.isFetching, isLoading = _b.isLoading, setSorting = _b.setSorting, sorting = _b.sorting, setResultsPerPage = _b.setResultsPerPage, activePage = _b.activePage, resultsPerPage = _b.resultsPerPage, setActivePage = _b.setActivePage, highlightSearchWords = _b.highlightSearchWords, onSearchBoxChange = _b.onSearchBoxChange, onSearchBoxKeyDown = _b.onSearchBoxKeyDown, onSearchBoxXClicked = _b.onSearchBoxXClicked, searchTextInputValue = _b.searchTextInputValue, totalEventCount = _b.totalEventCount, totalPageEventCount = _b.totalPageEventCount;
    var table = useTenantEventBrowserTable(data, sorting, setSorting, isLoading, isFetching, highlightSearchWords).table;
    return {
        table: table,
        setResultsPerPage: setResultsPerPage,
        activePage: activePage,
        resultsPerPage: resultsPerPage,
        setActivePage: setActivePage,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        setAppliedFilters: setAppliedFilters,
        appliedFilters: appliedFilters,
        isLoading: isLoading,
        highlightSearchWords: highlightSearchWords,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        searchTextInputValue: searchTextInputValue,
        totalEventCount: totalEventCount,
        totalPageEventCount: totalPageEventCount
    };
};
