import * as React from "react";
import "./principals-list.scss";
import UsersService from "app/services/users";
import PrincipalItem from "../principal-item";
import { SkeletonLoaderFakeBox } from "../skeleton-loaders";
var PrincipalsList = React.memo(function (_a) {
    var siteUrl = _a.siteUrl, userType = _a.userType, groupId = _a.groupId;
    var _b = React.useState(), users = _b[0], setUsers = _b[1];
    var _c = React.useState(true), loading = _c[0], setLoading = _c[1];
    React.useEffect(function () {
        setLoading(true);
        UsersService.getAllUsers(siteUrl, userType, groupId).then(function (users) {
            setUsers(users);
            setLoading(false);
        });
    }, [siteUrl]);
    return (React.createElement("div", { className: "principals-list" }, loading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : users !== undefined ? (React.createElement("div", { className: "list fadein-1s" },
        users.map(function (u, uIndex) { return (uIndex < 10 && u.GUID !== null ? React.createElement(PrincipalItem, { key: uIndex, principal: u, style: "pic-only" }) : null); }),
        users.length > 9 ? (React.createElement("div", { className: "principal-item-pic" },
            React.createElement("div", { className: "pic plus-more" },
                React.createElement("div", { className: "profile-picture two-letter" },
                    React.createElement("span", null,
                        "+ ",
                        users.length - 9))))) : null)) : null));
});
export default PrincipalsList;
