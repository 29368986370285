import OrchestryAdminFailureStats from "app/components/orchestry-admin/orchestry-admin-failure-stats";
import OrchestryAdminHome from "app/components/orchestry-admin/orchestry-admin-home";
import OrchestryAdminRecommendationsAnalytics from "app/components/orchestry-admin/orchestry-admin-recommendations-analytics";
import OrchestryAdminToolApiThrottling from "app/components/orchestry-admin/orchestry-admin-tool-api-throttling";
import OrchestryAdminToolApiThrottlingErrors from "app/components/orchestry-admin/orchestry-admin-tool-api-throttling-errors";
import OrchestryAdminToolApiThrottlingHours from "app/components/orchestry-admin/orchestry-admin-tool-api-throttling-hours";
import OrchestryAdminToolAzureTableThrottling from "app/components/orchestry-admin/orchestry-admin-tool-azure-table-throttling";
import OrchestryAdminToolAzureTableThrottlingHours from "app/components/orchestry-admin/orchestry-admin-tool-azure-table-throttling-hours";
import OrchestryAdminToolBulkGuestProvisioning from "app/components/orchestry-admin/orchestry-admin-tool-bulk-guest-provisioning";
import OrchestryAdminToolBulkTeamProvisioning from "app/components/orchestry-admin/orchestry-admin-tool-bulk-team-provisioning";
import OrchestryAdminToolCosmosThrottling from "app/components/orchestry-admin/orchestry-admin-tool-cosmos-throttling";
import OrchestryAdminToolCosmosThrottlingHours from "app/components/orchestry-admin/orchestry-admin-tool-cosmos-throttling-hours";
import OrchestryAdminToolDatabaseBrowser from "app/components/orchestry-admin/orchestry-admin-tool-database-browser";
import OrchestryAdminToolLifecycleTesting from "app/components/orchestry-admin/orchestry-admin-tool-lifecycle-testing";
import OrchestryAdminToolSqlThrottling from "app/components/orchestry-admin/orchestry-admin-tool-sql-throttling";
import OrchestryAdminToolSqlThrottlingHours from "app/components/orchestry-admin/orchestry-admin-tool-sql-throttling-hours";
import OrchestryAdminToolStressTest from "app/components/orchestry-admin/orchestry-admin-tool-stress-test";
import OrchestryTenantDatabasesList from "app/components/orchestry-admin/orchestry-tenants-database-list";
import React from "react";
import { Route, Switch } from "react-router-dom";
import OrchestryAdminETLStats from "app/components/orchestry-admin/orchestry-admin-etl-crawl-stats";
import OrchestryAdminETLTimeline from "app/components/orchestry-admin/orchestry-admin-etl-crawl-timeline";
import OrchestryAdminRequestStats from "app/components/orchestry-admin/orchestry-admin-request-stats";
import OrchestryInstallCodes from "app/components/orchestry-admin/orchestry-install-codes";
import OrchestryTenantsDetails from "app/components/orchestry-admin/orchestry-tenant-details";
import OrchestryTenantsList from "app/components/orchestry-admin/orchestry-tenants-list";
import LibraryChannelEdit from "app/components/orchestry-admin/library/library-channels/library-channel-edit";
import LibraryChannelList from "app/components/orchestry-admin/library/library-channels/library-channel-list";
import LibraryChannelNew from "app/components/orchestry-admin/library/library-channels/library-channel-new";
import LibraryDocumentTemplateEdit from "app/components/orchestry-admin/library/library-document-templates/library-document-template-edit";
import LibraryDocumentTemplateList from "app/components/orchestry-admin/library/library-document-templates/library-document-template-list";
import LibraryDocumentTemplateNew from "app/components/orchestry-admin/library/library-document-templates/library-document-template-new";
import LibraryFeatureEdit from "app/components/orchestry-admin/library/library-features/library-feature-edit";
import LibraryFeatureList from "app/components/orchestry-admin/library/library-features/library-feature-list";
import LibraryFeatureNew from "app/components/orchestry-admin/library/library-features/library-feature-new";
import LibraryTemplateEdit from "app/components/orchestry-admin/library/library-templates/library-template-edit";
import LibraryTemplateList from "app/components/orchestry-admin/library/library-templates/library-template-list";
import LibraryTemplateNew from "app/components/orchestry-admin/library/library-templates/library-template-new";
import OrchestryAdminReports from "app/components/orchestry-admin/orchestry-admin-reports";
import LibrarySettings from "app/components/orchestry-admin/library/library-settings";
import OrchestryAdminJobBrowser from "app/components/orchestry-admin/orchestry-admin-job-browser";
import OrchestryRecommendationSuggestions from "app/components/orchestry-admin/orchestry-admin-recommendations-suggestions";
import TeamExplorer from "app/components/team-explorer";
import { RecommendationsPageAdmin } from "appv2/pages/beacon/recommendations/admin/recommendations-admin-page.component";
import { ResolutionsAdminPage } from "appv2/pages/beacon/resolutions/admin/resolutions-admin-page.component";
import { AdminTenantEventBrowser } from "appv2/pages/orchestry-admin-pages/orchestry-admin-tool-tenant-event-browser/tenant-event-browser.component";
export default function OrchestryAdminPages() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/orchestry-admin" },
            React.createElement(OrchestryAdminHome, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/requests" },
            React.createElement(OrchestryAdminRequestStats, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/etl-crawl" },
            React.createElement(OrchestryAdminETLStats, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/etl-timeline" },
            React.createElement(OrchestryAdminETLTimeline, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/throttling" },
            React.createElement(OrchestryAdminToolApiThrottling, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/throttling-errors" },
            React.createElement(OrchestryAdminToolApiThrottlingErrors, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/cosmos-throttling" },
            React.createElement(OrchestryAdminToolCosmosThrottling, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/azure-table-throttling" },
            React.createElement(OrchestryAdminToolAzureTableThrottling, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/sql-throttling" },
            React.createElement(OrchestryAdminToolSqlThrottling, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/throttling-24hours" },
            React.createElement(OrchestryAdminToolApiThrottlingHours, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/cosmos-throttling-24hours" },
            React.createElement(OrchestryAdminToolCosmosThrottlingHours, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/azure-table-throttling-24hours" },
            React.createElement(OrchestryAdminToolAzureTableThrottlingHours, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/stats/sql-throttling-24hours" },
            React.createElement(OrchestryAdminToolSqlThrottlingHours, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/failure-stats" },
            React.createElement(OrchestryAdminFailureStats, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/stress-test" },
            React.createElement(OrchestryAdminToolStressTest, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/lifecycle-testing" },
            React.createElement(OrchestryAdminToolLifecycleTesting, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/bulk-team-provisioning" },
            React.createElement(OrchestryAdminToolBulkTeamProvisioning, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/bulk-guest-provisioning" },
            React.createElement(OrchestryAdminToolBulkGuestProvisioning, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/database-browser" },
            React.createElement(OrchestryAdminToolDatabaseBrowser, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/database-information" },
            React.createElement(OrchestryTenantDatabasesList, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/tenant-recommendations-browser" },
            React.createElement(RecommendationsPageAdmin, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/tenant-recommendations-browser/resolutions" },
            React.createElement(ResolutionsAdminPage, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/orchestry-admin-recommendations-analytics" },
            React.createElement(OrchestryAdminRecommendationsAnalytics, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/orchestry-admin-recommendations-suggestions" },
            React.createElement(OrchestryRecommendationSuggestions, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tenants" },
            React.createElement(OrchestryTenantsList, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tenants/details" },
            React.createElement(OrchestryTenantsDetails, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/templates", component: LibraryTemplateList }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/templates/new" },
            React.createElement(LibraryTemplateNew, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/templates/edit/:guid", component: LibraryTemplateEdit }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/channels", component: LibraryChannelList }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/channels/new" },
            React.createElement(LibraryChannelNew, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/channels/edit/:guid", component: LibraryChannelEdit }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/features", component: LibraryFeatureList }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/features/new" },
            React.createElement(LibraryFeatureNew, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/features/edit/:guid", component: LibraryFeatureEdit }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/document-templates", component: LibraryDocumentTemplateList }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/document-templates/new" },
            React.createElement(LibraryDocumentTemplateNew, null)),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/document-templates/edit/:guid", component: LibraryDocumentTemplateEdit }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/templates", component: LibraryTemplateList }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/library/settings", component: LibrarySettings }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/team-explorer", component: TeamExplorer }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/job-browser", component: OrchestryAdminJobBrowser }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/install-codes", component: OrchestryInstallCodes }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/tenant-event-browser", component: AdminTenantEventBrowser }),
        React.createElement(Route, { exact: true, path: "/orchestry-admin/reports/churn", component: OrchestryAdminReports })));
}
