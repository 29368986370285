var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { toast } from "react-toastify";
import "./orchestry-toast-notification.scss";
var toastTimeShirt = 50;
export var OrchestryToastNotification = function (props) {
    var _a, _b;
    var time = Number((_a = props.options) === null || _a === void 0 ? void 0 : _a.autoClose);
    var style = {
        animationDuration: "".concat(time, "ms"),
        transformOrigin: "left"
    };
    setTimeout(function () {
        var _a;
        toast.dismiss((_a = props.options) === null || _a === void 0 ? void 0 : _a.toastId);
    }, time + toastTimeShirt);
    return (React.createElement("div", { className: "orchestry-toast-notification-body" },
        React.createElement("div", null, props.content),
        ((_b = props.options) === null || _b === void 0 ? void 0 : _b.autoClose) && (React.createElement("div", { "data-variant": props.options.progressBarVariant, className: "bar-container" },
            React.createElement("div", { "data-variant": props.options.progressBarVariant, className: "bar", style: style })))));
};
/**
 * Displays a toast notification using the react-toastify library with custom options.
 * @param content The content to be displayed in the toast notification.
 * @param options The options for the toast notification.
 * @returns The toast notification object.
 */
export var orchestryToast = function (content, options) {
    toast(React.createElement(OrchestryToastNotification, { content: content, options: options }), __assign(__assign({}, options), { autoClose: false, className: "beacon-toast", hideProgressBar: true }));
};
