var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Container, Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import clsx from "clsx";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import "./orchestry-alert.scss";
export var OrchestryAlertMobile = function (_a) {
    var icon = _a.icon, title = _a.title, message = _a.message, linkLabel = _a.linkLabel, onClick = _a.onClick, isLoading = _a.isLoading, props = __rest(_a, ["icon", "title", "message", "linkLabel", "onClick", "isLoading"]);
    return (React.createElement(Container, __assign({ fluid: true, p: 16, w: "100%", className: clsx("orchestry-alert", { link: !!linkLabel }), onClick: onClick }, props),
        React.createElement(Stack, { gap: 0 },
            React.createElement(Group, { gap: 16 },
                icon,
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "16", fw: 500, lh: "20px" }, title)),
            React.createElement(OrchestryText, { mt: 8, isLoading: isLoading, c: "text-primary.0", fz: "14", fw: 300, lh: "20px" }, message),
            linkLabel && (React.createElement(Group, { justify: "flex-end", gap: 8 },
                React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-primary-9)", fz: "12", fw: 400, lh: "18px" }, linkLabel),
                React.createElement(OrchestryFontIcon, { isLoading: isLoading, iconClassName: ORCH_ICONS.arrowRight, fontSize: "16px", lineHeight: "16px", color: "var(--mantine-color-primary-9)" }))))));
};
