import * as LibraryDocumentTemplateListStore from "app/store/library-document-templates/list";
import { AsyncCallType } from "app/store/async/types";
import * as LibraryTemplateListTypes from "app/store/library-templates/list";
import * as LibraryTemplateCurrentTypes from "app/store/library-templates/current";
export var libraryDocumentTemplateListActionMap = [
    {
        actionType: LibraryDocumentTemplateListStore.LibraryDocumentTemplateListActionTypes.LibraryDocumentTemplate_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/libraryDocumentTemplates",
        responseDataType: "",
        invalidates: [LibraryTemplateListTypes.LibraryTemplateListActionTypes.LibraryTemplate_List_Load]
    },
    {
        actionType: LibraryDocumentTemplateListStore.LibraryDocumentTemplateListActionTypes.LibraryDocumentTemplate_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/libraryDocumentTemplates",
        responseDataType: "",
        invalidates: [LibraryTemplateListTypes.LibraryTemplateListActionTypes.LibraryTemplate_List_Load]
    },
    {
        actionType: LibraryDocumentTemplateListStore.LibraryDocumentTemplateListActionTypes.LibraryDocumentTemplate_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/libraryDocumentTemplates",
        responseDataType: true,
        invalidates: [LibraryTemplateListTypes.LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentTypes.LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryDocumentTemplateListStore.LibraryDocumentTemplateListActionTypes.LibraryDocumentTemplate_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "libraryDocumentTemplateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/libraryDocumentTemplates",
        responseDataType: []
    },
    {
        actionType: LibraryDocumentTemplateListStore.LibraryDocumentTemplateListActionTypes.LibraryDocumentTemplate_List_Refresh,
        callType: AsyncCallType.Refresh,
        stateLocationForArray: "libraryDocumentTemplateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/libraryDocumentTemplates",
        responseDataType: []
    }
];
