// import { MiddlewareFunction} from 'app/store/middleware'
import { LifecycleGuestCurrentActionTypes } from "./types";
export function Load(guestId) {
    return {
        type: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load,
        payload: { UserId: guestId }
    };
}
export function LoadWithCosmosObject(guestId) {
    return {
        type: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_With_CosmosItem,
        payload: { UserId: guestId }
    };
}
export function LoadCosmosItem(guestId) {
    return {
        type: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_CosmosItem,
        payload: { UserId: guestId }
    };
}
export function LoadCosmosItemFromObject(cosmosUser) {
    return {
        type: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_CosmosItem_From_Memory,
        payload: cosmosUser
    };
}
export function LoadHistory(guestId, historyType, take, skip) {
    if (take === void 0) { take = 10; }
    if (skip === void 0) { skip = 0; }
    return {
        type: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_History,
        payload: { UserId: guestId, Skip: skip, Take: take, HistoryType: historyType }
    };
}
export function Clear() {
    return {
        type: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Clear,
        payload: {}
    };
}
export var policyCurrentMiddlware = function (store, next, action) {
    if (action.type === LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load) {
        next(action);
    }
    else {
        next(action);
    }
};
export var createEmptyPolicyWorkspace = function (guestId) {
    return {
        UserId: guestId
    };
};
