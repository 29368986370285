import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export var recommendationSettingsApi = createApi({
    reducerPath: "recommendationSettingsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/RecommendationSettings" }),
    tagTypes: ["RecommendationSettings"],
    endpoints: function (builder) { return ({
        getRecommendationSettings: builder.query({
            query: function () { return "/GetRecommendationSettings"; },
            providesTags: ["RecommendationSettings"]
        }),
        updateRecommendationSettings: builder.mutation({
            query: function (updateRecommendationsSettingsCommand) { return ({
                url: "/UpdateRecommendationSettings",
                method: "PUT",
                body: updateRecommendationsSettingsCommand
            }); }
        })
    }); }
});
export var useGetRecommendationSettingsQuery = recommendationSettingsApi.useGetRecommendationSettingsQuery, useUpdateRecommendationSettingsMutation = recommendationSettingsApi.useUpdateRecommendationSettingsMutation;
