var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./orchestry-localization-editor-users.scss";
import { Form, Button } from "react-bootstrap";
import { LocalizationService } from "app/services/localization";
import OrchModal from "app/components/common/orch-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { PortletBody } from "app/components/common/portlet";
import NoResults from "app/components/common/no-results";
var OrchestryLocalizationEditorUsers = React.memo(function () {
    var _a = React.useState(), editors = _a[0], setEditors = _a[1];
    var _b = React.useState(false), orchUserSearchModalOpen = _b[0], setOrchUserSearchModalOpen = _b[1];
    var _c = React.useState(""), userSearchboxText = _c[0], setUserSearchboxText = _c[1];
    var _d = React.useState(), userSearchResults = _d[0], setUserSearchResults = _d[1];
    var _e = React.useState(null), updatingUser = _e[0], setUpdatingUser = _e[1];
    React.useEffect(function () {
        LocalizationService.getLocalizationEditors().then(function (data) {
            setEditors(data);
        });
    }, []);
    var searchForUsers = function () {
        if (userSearchboxText !== "") {
            LocalizationService.getOrchestryUsers(userSearchboxText).then(function (users) {
                setUserSearchResults(users);
            });
        }
        else {
            setUserSearchResults(undefined);
        }
    };
    var selectUser = function (ut) {
        var upsertUser = __assign(__assign({}, ut.user), { IsLocalizationEditor: true });
        setUpdatingUser(ut.user.ClaimsId);
        LocalizationService.updateLocalizationEditor(upsertUser).then(function (data) {
            setOrchUserSearchModalOpen(false);
            setUpdatingUser(null);
            setEditors(undefined);
            LocalizationService.getLocalizationEditors().then(function (data) {
                setEditors(data);
            });
        });
    };
    var removeEditor = function (ut) {
        var upsertUser = __assign(__assign({}, ut.user), { IsLocalizationEditor: false });
        setUpdatingUser(ut.user.ClaimsId);
        LocalizationService.updateLocalizationEditor(upsertUser).then(function (data) {
            setOrchUserSearchModalOpen(false);
            setUpdatingUser(null);
            setEditors(undefined);
            LocalizationService.getLocalizationEditors().then(function (data) {
                setEditors(data);
            });
        });
    };
    var getShowLanguages = function () {
        return LocalizationService.AllLangauges.filter(function (i) { return i.lang !== "en"; });
    };
    var toggleLangaugeForUser = function (ut, lang) {
        var upsertUser = __assign({}, ut.user);
        setUpdatingUser(ut.user.ClaimsId);
        if (upsertUser.LocalizationEditorLangs !== undefined && upsertUser.LocalizationEditorLangs.some(function (l) { return l === lang; })) {
            upsertUser.LocalizationEditorLangs = upsertUser.LocalizationEditorLangs.filter(function (l) { return (l === lang ? false : true); });
        }
        else if (upsertUser.LocalizationEditorLangs !== undefined) {
            upsertUser.LocalizationEditorLangs = __spreadArray(__spreadArray([], upsertUser.LocalizationEditorLangs, true), [lang], false);
        }
        setEditors(editors === null || editors === void 0 ? void 0 : editors.map(function (e) { return (e.user.UPN !== ut.user.UPN ? e : __assign(__assign({}, ut), { user: upsertUser })); }));
        LocalizationService.updateLocalizationEditor(upsertUser).then(function (data) {
            setUpdatingUser(null);
        });
    };
    return (React.createElement("div", { className: "orchestry-localization-editor-users fadein-500ms" },
        editors === undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })) : (React.createElement("div", null, editors.length === 0 ? (React.createElement(NoResults, { subText: "No localization editors found" })) : (React.createElement("div", { className: "local-editors" },
            React.createElement("div", { className: "result-items" },
                React.createElement("div", { className: "result-user-item header" },
                    React.createElement("div", { className: "name" }, "Name and Tenant"),
                    React.createElement("div", { className: "upn" }, "UPN"),
                    React.createElement("div", { className: "langs" }, "Languages"),
                    React.createElement("div", { className: "action" })),
                editors.map(function (ut) {
                    return updatingUser !== null && ut.user.ClaimsId === updatingUser ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })) : (React.createElement("div", { className: "result-user-item" },
                        React.createElement("div", { className: "name" },
                            React.createElement("div", { className: "content" }, ut.user.DisplayName),
                            React.createElement("div", { className: "tenant" }, ut.tenant.DisplayName)),
                        React.createElement("div", { className: "upn" }, ut.user.UPN),
                        React.createElement("div", { className: "lang-stats" }, getShowLanguages().map(function (li) {
                            var _a;
                            return (React.createElement(React.Fragment, null,
                                React.createElement("div", { onClick: function () { return toggleLangaugeForUser(ut, li.lang); }, className: "lang-status " + (((_a = ut.user.LocalizationEditorLangs) === null || _a === void 0 ? void 0 : _a.some(function (l) { return l === li.lang; })) ? "lang-green" : "lang-red") }, li.lang)));
                        })),
                        React.createElement("div", { className: "action" },
                            React.createElement("i", { className: "fa fa-trash", onClick: function () { return removeEditor(ut); } }))));
                })))))),
        React.createElement(OrchModal, { showModalOverride: orchUserSearchModalOpen, showModalToggleOverride: setOrchUserSearchModalOpen, modalBgClassName: "orchestry-localization-editor-users-search-bg", modalClassName: "orchestry-localization-editor-users-search", buttonContents: "Add Editor", headerText: "Search for editor", showCloseButton: true, size: "md" },
            React.createElement(PortletBody, null,
                React.createElement("div", { className: "user-search-row" },
                    React.createElement(Form.Control, { placeholder: "Enter user name", value: userSearchboxText, onChange: function (event) { return setUserSearchboxText(event.target.value); } }),
                    React.createElement(Button, { onClick: function () { return searchForUsers(); } }, "Search")),
                React.createElement("div", { className: "user-search-results" }, userSearchResults === undefined ? (React.createElement("div", { className: "start-search" }, "Please use the search box above to search for any user that has ever logged into Orchestry.")) : userSearchResults !== undefined && userSearchResults.length === 0 ? (React.createElement(NoResults, { subText: "No users found for search '" + userSearchboxText + "'" })) : (React.createElement("div", { className: "result-items" },
                    React.createElement("div", { className: "result-user-item header" },
                        React.createElement("div", { className: "name" }, "Name"),
                        React.createElement("div", { className: "upn" }, "UPN"),
                        React.createElement("div", { className: "tenant" }, "Tenant Name")),
                    userSearchResults.map(function (ut) {
                        return updatingUser !== null && ut.user.ClaimsId === updatingUser ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })) : (React.createElement("div", { className: "result-user-item", onClick: function () { return selectUser(ut); } },
                            React.createElement("div", { className: "name" }, ut.user.DisplayName),
                            React.createElement("div", { className: "upn" }, ut.user.UPN),
                            React.createElement("div", { className: "tenant" }, ut.tenant.DisplayName)));
                    }))))))));
});
export default OrchestryLocalizationEditorUsers;
