import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import HealthChecksTeamsPreviewImage from "appv2/assets/img/health-check-teams-preview.png";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import "./health-check-review-teams-page.scss";
import { usePartnerHealthCheckTeamsPage } from "./use-partner-health-check-teams-page.hook";
export var PartnerHealthCheckReviewTeamsPage = function () {
    var _a = usePartnerHealthCheckTeamsPage(), intl = _a.intl, tenantGuid = _a.tenantGuid, healthCheckGuid = _a.healthCheckGuid, healthCheckName = _a.healthCheckName, tenantName = _a.tenantName, isLoading = _a.isLoading;
    return (React.createElement("div", { className: "health-check-review-teams-page" },
        React.createElement("div", { className: "orchestry" },
            React.createElement("img", { src: ORCH_SVG_ICONS.Logomark, className: "logo", alt: "Orchestry" }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", ml: 8, fw: "700", size: "20px", lh: "20px" }, intl.formatMessage({ id: "page.health_check_review_teams.orchestry", defaultMessage: "Orchestry" }))),
        React.createElement("div", { className: "title" },
            React.createElement("img", { src: ORCH_SVG_ICONS.PartyPopper, className: "party-popper", alt: "Congratulations!" }),
            React.createElement(OrchestryText, { isLoading: isLoading, ml: 8, c: "text-primary.0", fw: "900", size: "20px", lh: "20px" }, intl.formatMessage({ id: "page.health_check_review_teams.partner.title", defaultMessage: "A Customer Health Check is Ready for Review" }))),
        React.createElement(OrchestryHighlight, { isLoading: isLoading, mt: 28, mb: 28, c: "text-primary.0", fw: "300", size: "14px", lh: "14px", highlight: [healthCheckName, tenantName], highlightStyles: { fontWeight: 700, backgroundColor: "var(--mantine-color-text-primary-0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" } }, intl.formatMessage({ id: "page.health_check_review_teams.partner.subTitle", defaultMessage: "A Health Check for {tenantName} called {healthCheckName} is Ready for Review" }, {
            tenantName: tenantName,
            healthCheckName: healthCheckName
        })),
        React.createElement("img", { className: "preview-image", src: HealthChecksTeamsPreviewImage, alt: "health-check" }),
        React.createElement(OrchestryText, { isLoading: isLoading, mt: 20, c: "text-primary.0", fw: "300", size: "14px", lh: "14px" }, intl.formatMessage({ id: "page.health_check_review_teams.description", defaultMessage: "This experience is designed for a full Browser experience." })),
        React.createElement(OrchestryText, { isLoading: isLoading, mt: 8, c: "text-primary.0", fw: "500", size: "14px", lh: "14px" }, intl.formatMessage({ id: "page.health_check_review_teams.button_directive", defaultMessage: "Click the link below to access the Health Check." })),
        React.createElement(OrchestryButton, { isLoading: isLoading, target: "_blank", rel: "noopener noreferrer", component: "a", href: "/partner-tools/tenants/view/".concat(tenantGuid, "/health-checks/view/").concat(healthCheckGuid), className: "button", size: "md", mt: 40, iconType: "font", iconLeft: ORCH_ICONS.calendarHeart01, variant: "primary" }, intl.formatMessage({ id: "page.health_check_review_teams.button_text", defaultMessage: "Open Health Check in Browser" }))));
};
