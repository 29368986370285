import * as AuthStore from "app/store/auth";
import { useGetSpecificRecommendationRecordsVisibilityCountsQuery, useGetSpecificRecommendationsQuery, useGetSpecificRecommendationsStatsQuery } from "appv2/api/recommendations/recommendations.api";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRecommendationsBaseApi } from "../use-recommendations-base-api.hook";
var isFetchBaseQueryErrorType = function (error) { return "status" in error; };
export var useRecommendationsAdminApi = function (appliedFilters, visibilityFilter, appliedFiltersDependency, _a) {
    var tid = _a.tid;
    var _b = useRecommendationsBaseApi(appliedFilters, visibilityFilter, appliedFiltersDependency), recommendationCategories = _b.recommendationCategories, recommendationProducts = _b.recommendationProducts, togglePrioritySort = _b.togglePrioritySort, activePage = _b.activePage, setActivePage = _b.setActivePage, resultsPerPage = _b.resultsPerPage, setResultsPerPage = _b.setResultsPerPage, querySearchText = _b.querySearchText, highlightSearchWords = _b.highlightSearchWords, searchTextInputValue = _b.searchTextInputValue, onSearchBoxChange = _b.onSearchBoxChange, onSearchBoxKeyDown = _b.onSearchBoxKeyDown, onSearchBoxXClicked = _b.onSearchBoxXClicked, isError = _b.isError, pageOptions = _b.pageOptions, filterExpression = _b.filterExpression, prioritySort = _b.prioritySort;
    var dispatch = useDispatch();
    var _c = useGetSpecificRecommendationsQuery({
        queryParameters: {
            filter: filterExpression,
            orderBy: "Definition_Priority ".concat(prioritySort),
            page: activePage,
            pageSize: Number(resultsPerPage)
        },
        searchText: querySearchText,
        tid: tid
    }), pagedRecommendations = _c.data, pagedRecommendationsError = _c.error, pagedRecommendationsIsFetching = _c.isFetching, pagedRecommendationsIsLoading = _c.isLoading;
    var _d = useGetSpecificRecommendationRecordsVisibilityCountsQuery({ tid: tid }), recommendationRecordVisibilityCounts = _d.data, recommendationRecordCountserror = _d.error, recommendationRecordVisibilityCountsIsLoading = _d.isLoading;
    var _e = useGetSpecificRecommendationsStatsQuery({ tid: tid }), recommendationStats = _e.data, recommendationStatserror = _e.error, recommendationStatsIsFetching = _e.isFetching, recommendationStatsIsLoading = _e.isLoading, recommendationStatsSuccessful = _e.isSuccess;
    var isPageLoading = pagedRecommendationsIsLoading || recommendationStatsIsLoading || recommendationRecordVisibilityCountsIsLoading;
    useEffect(function () {
        if (pagedRecommendationsError && isFetchBaseQueryErrorType(pagedRecommendationsError) && pagedRecommendationsError.status === 403) {
            dispatch(AuthStore.Logout());
        }
    }, [pagedRecommendationsError]);
    return {
        pagedRecommendations: pagedRecommendations,
        recommendationCategories: recommendationCategories,
        recommendationProducts: recommendationProducts,
        recommendationRecordVisibilityCounts: recommendationRecordVisibilityCounts,
        recommendationStats: recommendationStats,
        togglePrioritySort: togglePrioritySort,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        querySearchText: querySearchText,
        highlightSearchWords: highlightSearchWords,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        recommendationStatsSuccessful: recommendationStatsSuccessful,
        pagedRecommendationsIsFetching: pagedRecommendationsIsFetching,
        recommendationRecordVisibilityCountsIsLoading: recommendationRecordVisibilityCountsIsLoading,
        isPageLoading: isPageLoading,
        recommendationStatsIsFetching: recommendationStatsIsFetching,
        isError: isError || pagedRecommendationsError || recommendationRecordCountserror || recommendationStatserror,
        pageOptions: pageOptions
    };
};
