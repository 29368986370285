var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-select-box.scss";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
var FormSelectBox = React.memo(function (props) {
    var seperator = React.useState(props.multipleSeperator === undefined ? "|" : props.multipleSeperator)[0];
    var isSelected = function (matchValue) {
        var selected = false;
        if (props.allowMultiple !== undefined && props.allowMultiple === true) {
            if (props.value !== undefined && props.value !== null && props.value !== "") {
                var items = props.value.split(seperator);
                selected = items.some(function (i) { return i === matchValue; });
            }
        }
        else {
            selected = props.value !== undefined && props.value === matchValue;
        }
        return selected ? " selected" : "";
    };
    var onClick = function (item) {
        if (props.allowMultiple !== undefined && props.allowMultiple === true) {
            var tempValue = "";
            if (props.value !== undefined && props.value !== null && props.value !== "") {
                var items = props.value.split(seperator);
                var alreadyExists = false;
                alreadyExists = items.some(function (i) { return i === item.value; });
                if (alreadyExists) {
                    items = items.filter(function (i) { return i !== item.value; });
                }
                else {
                    items = __spreadArray(__spreadArray([], items, true), [item.value], false);
                }
                tempValue = items.join(seperator);
            }
            else {
                tempValue = item.value;
            }
            props.onChange(tempValue);
        }
        else {
            props.onChange(item.object !== undefined ? item.object : item.value);
        }
    };
    return (React.createElement("div", { className: "form-select-box" +
            (props.style !== undefined ? " style-" + props.style : " style-normal") +
            (" fsb-cols-" + (props.style !== undefined && props.style === "check-list" ? "1" : props.list.length)) }, props.list.map(function (item) { return (React.createElement("div", { className: "select-box-item ".concat(item.disabled ? "disabled" : "").trim() +
            (isSelected(item.value) ? " selected" : "") +
            (item.image !== undefined || item.icon !== undefined || item.iconJsx !== undefined ? " has-image-or-icon" : ""), "data-pw": "select-box-item", key: item.value, onClick: function () {
            if (!item.disabled)
                onClick(item);
        } },
        React.createElement("div", { className: "select-box-checkbox" },
            React.createElement("i", { className: "fa fa-check" })),
        item.tooltip ? (React.createElement("div", { style: { color: item.tooltip.color }, className: "select-box-tooltip" },
            React.createElement(OverlayTrigger, { overlay: React.createElement(Popover, { id: "popover-basic", className: "orchestry-tooltip popover-basic" }, item.tooltip.content !== undefined ? (React.createElement(Popover.Content, null,
                    React.createElement("span", null, item.tooltip.content))) : null), placement: "top" },
                React.createElement("i", { className: item.tooltip.icon })))) : null,
        React.createElement("div", { className: "select-box-wrapper" },
            React.createElement("div", { className: "left-icon-image" },
                item.icon !== undefined && item.icon !== null ? (React.createElement("div", { className: "select-box-icon" },
                    React.createElement("i", { className: item.icon }))) : null,
                item.iconJsx !== undefined ? React.createElement("div", { className: "select-box-icon-jsx" }, item.iconJsx) : null,
                item.image !== undefined ? (React.createElement("div", { className: "select-box-image" },
                    React.createElement("img", { src: item.image }))) : null),
            React.createElement("div", { className: "mid-text" },
                React.createElement("div", { className: "select-box-title", "data-pw": "select-box-title" }, item.title),
                item.subtitle !== undefined ? React.createElement("div", { className: "select-box-subtitle" }, item.subtitle) : null,
                item.description !== undefined && item.description !== null && item.description !== "" ? React.createElement("span", { className: "select-box-description" }, item.description) : null),
            props.style !== undefined && props.style === "check-list" && (React.createElement("div", { className: "right-text" },
                React.createElement("div", { className: "checkbox" },
                    React.createElement(Form.Check, { type: "checkbox", label: "", checked: isSelected(item.value) === " selected", onChange: function (wha) { return console.log(wha); } }))))))); })));
});
export default FormSelectBox;
