var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import AssociateTemplate from "app/components/common/associate-template";
import { AsyncWait } from "app/components/common/async";
import DocumentTemplatePreview from "app/components/common/document-template-preview";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormFileUploadBox from "app/components/common/form-fileuploadbox";
import FormImageUploadBox from "app/components/common/form-imageuploadbox";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import SaveProgression from "app/components/common/save-progression";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import FeatureFiles from "app/components/tools/feature-files";
import FileService from "app/services/files";
import Store from "app/store";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { AsyncActionCreators } from "app/store/async/actions";
import * as FeatureCurrentStore from "app/store/features/current";
import * as FeatureListStore from "app/store/features/list";
import generateGuid from "app/utils/generateGuid";
import lineAwesomeHelper from "app/utils/lineAwesomeHelper";
import * as React from "react";
import { Col, Container, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import "./feature-details.scss";
var FeatureDetails = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(props.guid), lookupGuid = _a[0], setLookupGuid = _a[1];
    var currentFeature = useSelector(function (state) { return state.featureCurrentState.item; });
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var _c = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "details"), hash = _c[0], setHash = _c[1];
    var _d = React.useState(), fileUploadPercentage = _d[0], setFileUploadPercentage = _d[1];
    var _e = React.useState(false), formValid = _e[0], setFormValid = _e[1];
    var iconOptions = React.useState(lineAwesomeHelper.getIconOptionsForDropDown())[0];
    var _f = React.useState(false), showModal = _f[0], setShowModal = _f[1];
    var _g = React.useState(null), featureCustomized = _g[0], setfeatureCustomized = _g[1];
    var _h = React.useState([
        {
            Title: "Save Feature Information",
            Type: "item",
            Method: function () {
                return saveItemAsync();
            }
        }
    ]), saveJobs = _h[0], setSaveJobs = _h[1];
    var _j = React.useState(""), uploadFileName = _j[0], setUploadFileName = _j[1];
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (currentFeature.GUID !== undefined) {
            dispatch(FeatureCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current template when navigating to the new template page.
            var guid = generateGuid();
            // Add a new template with guid. set customized to true, all user created features are custom.
            dispatch(FeatureCurrentStore.Update({ GUID: guid, AssociatedTemplates: [], IsCustomized: true }));
        }
        else {
            // Load the /edit/guid load.
            dispatch(FeatureCurrentStore.Load({ GUID: lookupGuid }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var clearCurrentFeature = function () {
        dispatch(FeatureCurrentStore.Clear());
    };
    React.useEffect(function () {
        // dispatch(FeatureCurrentStore.Clear());
        return function () {
            dispatch(FeatureCurrentStore.Clear());
        };
    }, []);
    var saveAndAddModifyFeature = function () {
        var state = Store.getState();
        var currentFeature = state.featureCurrentState.item;
        if (lookupGuid === undefined) {
            dispatch(FeatureListStore.AddItem(currentFeature));
        }
        else {
            dispatch(FeatureListStore.UpdateItem(currentFeature));
        }
    };
    var saveItemAsync = function () {
        return new Promise(function (resolve, reject) {
            var state = Store.getState();
            var currentfeature = state.featureCurrentState.item;
            saveAndAddModifyFeature();
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                asyncCallType = AsyncCallType.Add;
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentfeature.GUID).then(function () {
                resolve();
            });
            ///reject();
        });
    };
    var onAssociatedAdd = function (templateToAdd) {
        //console.log("ADD Ass Details > ", templateToAdd)
        var tempArray = [templateToAdd];
        //setAssociatedTemplates([...tempArray, ...currentFeature.AssociatedTemplates]);
        if (currentFeature.AssociatedTemplates !== undefined) {
            if (currentFeature.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), currentFeature.AssociatedTemplates, true);
            }
        }
        dispatch(FeatureCurrentStore.Update({ AssociatedTemplates: tempArray }));
    };
    var onAssociatedRemove = function (tbaToRemove) {
        var _a;
        dispatch(FeatureCurrentStore.Update({
            AssociatedTemplates: (_a = currentFeature.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
    };
    var onUploadProgress = function (progress) {
        setFileUploadPercentage((progress.loaded / progress.total) * 100 + "%");
        console.log("Progress", progress);
    };
    var uploadFileAsync = function (file, fileName) {
        return new Promise(function (resolve, reject) {
            var continueUpload = function () {
                var state = Store.getState();
                var currentFeature = state.featureCurrentState.item;
                if (file !== undefined && currentFeature.GUID !== undefined) {
                    var formData = new FormData();
                    formData.append("file", file);
                    formData.append("feature-guid", currentFeature.GUID);
                    FileService.uploadDocument(formData, "/api/features/UploadPNPFile", onUploadProgress).then(function () {
                        console.log("upload done");
                        setFileUploadPercentage(undefined);
                        var tempState = Store.getState();
                        var findAI = tempState.asyncState.items.find(function (i) { return i.key === currentFeature.GUID; });
                        if (findAI !== undefined) {
                            console.log("Found Async Action", findAI);
                            dispatch(AsyncActionCreators.Invalid(findAI));
                        }
                        dispatch(FeatureCurrentStore.Update({ IsCustomized: true }));
                        resolve();
                    });
                }
            };
            continueUpload();
        });
    };
    var addFile = function (file, fileName) {
        var regex = /\.pnp$|\.xml$/;
        if (!regex.test(fileName)) {
            window.alert("Only .xml/.pnp files are accepted");
            return;
        }
        var tempArray = __spreadArray(__spreadArray([], saveJobs.filter(function (file) { return file.Type !== "file"; }), true), [
            {
                Title: "Upload " + fileName,
                Type: "file",
                Method: function () {
                    return uploadFileAsync(file, fileName);
                }
            }
        ], false);
        //@ts-ignore
        setSaveJobs(tempArray);
        setUploadFileName(fileName);
        var fileExtension = fileName !== undefined ? fileName.split(".").pop() : "";
        var state = Store.getState();
        var currentFeature = state.featureCurrentState.item;
        if (currentFeature.IsCustomized === false) {
            setfeatureCustomized(function () { return true; });
            dispatch(FeatureCurrentStore.Update({ IsCustomized: true }));
        }
        if (currentFeature.Title === undefined || (currentFeature.Title !== undefined && currentFeature.Title === null) || (currentFeature.Title !== undefined && currentFeature.Title === "")) {
            //@ts-ignore
            dispatch(FeatureCurrentStore.Update({ Title: fileName.replace("." + fileExtension, ""), Type: fileExtension }));
        }
        else {
            //  dispatch(FeatureCurrentStore.Update({Type:fileExtension}));
        }
    };
    var onFileRemove = function () {
        if (currentFeature !== undefined && currentFeature.ConfigFile !== undefined && currentFeature.ConfigFile !== null && currentFeature.ConfigFile !== "") {
            dispatch(FeatureCurrentStore.Update({ ConfigFile: undefined }));
        }
    };
    var redirect = function () {
        setBackToList(true);
        dispatch(FeatureCurrentStore.Clear());
    };
    var onExtractedSelected = function (fileName) {
        var tempArray = __spreadArray(__spreadArray([], saveJobs.filter(function (file) { return file.Type !== "file"; }), true), [
            {
                Title: "Copy extracted file to feature > " + fileName,
                Type: "file",
                Method: function () {
                    return copyAzureFileAsync(fileName);
                }
            }
        ], false);
        //@ts-ignore
        setSaveJobs(tempArray);
        setUploadFileName(fileName);
        setShowModal(false);
        var state = Store.getState();
        var fileExtension = fileName !== undefined ? fileName.split(".").pop() : "";
        var currentFeature = state.featureCurrentState.item;
        if (currentFeature.IsCustomized === false) {
            setfeatureCustomized(function () { return true; });
            dispatch(FeatureCurrentStore.Update({ IsCustomized: true }));
        }
        if (currentFeature.Title === undefined || (currentFeature.Title !== undefined && currentFeature.Title === null) || (currentFeature.Title !== undefined && currentFeature.Title === "")) {
            //@ts-ignore
            dispatch(FeatureCurrentStore.Update({ Title: fileName.replace("." + fileExtension, ""), Type: fileExtension }));
        }
        else {
            //  dispatch(FeatureCurrentStore.Update({Type:fileExtension}));
        }
    };
    var copyAzureFileAsync = function (fileName) {
        return new Promise(function (resolve, reject) {
            var continueCopyExtracted = function () {
                var state = Store.getState();
                var currentFeature = state.featureCurrentState.item;
                if (fileName !== undefined && currentFeature.GUID !== undefined) {
                    var formData = new FormData();
                    formData.append("fileName", fileName);
                    formData.append("feature-guid", currentFeature.GUID);
                    FileService.copyAzureDocument(formData, "/api/features/CopyAzureFile").then(function () {
                        var tempState = Store.getState();
                        var findAI = tempState.asyncState.items.find(function (i) { return i.key === currentFeature.GUID; });
                        if (findAI !== undefined) {
                            console.log("Found Async Action", findAI);
                            dispatch(AsyncActionCreators.Invalid(findAI));
                        }
                        resolve();
                    });
                }
            };
            continueCopyExtracted();
        });
    };
    return (React.createElement("div", { className: "feature-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "FeatureValidation" },
            backToList && React.createElement(Redirect, { to: "/workspaces/features" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Features", subTitle: props.guid ? "Edit" : "New", titleLink: "/workspaces/features" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/features", onClick: function () { return clearCurrentFeature(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement(SaveProgression, { confirmation: featureCustomized
                        ? {
                            confirmationTitle: "Confirm PnP File Overwrite",
                            confirmButtonText: "Confirm",
                            cancelButtonText: "Cancel",
                            confirmationMessage: (React.createElement(React.Fragment, null,
                                React.createElement("p", null, "This Feature currently uses a default Orchestry PnP File. "),
                                React.createElement("p", null, "Saving these changes will unlink all templates associated with this Feature from receiving future updates or changes from Orchestry.")))
                        }
                        : undefined, OnComplete: function () { return redirect(); }, Jobs: saveJobs },
                    React.createElement("div", { style: { display: "inline-block" } },
                        React.createElement(SubtitleButton, { title: "Save", variant: "success" })))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            currentFeature.GUID !== undefined ? (React.createElement(Tab.Container, { id: "feature-tabs", defaultActiveKey: hash },
                React.createElement("div", { className: "kt-portlet" },
                    React.createElement("div", { className: "kt-portlet__body" },
                        React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                            React.createElement(Nav, { as: "ul" },
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("details"); } },
                                    React.createElement(Nav.Link, { eventKey: "details" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-clipboard-list" }),
                                            " Details"))),
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("configuration"); } },
                                    React.createElement(Nav.Link, { eventKey: "configuration" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-cog" }),
                                            " Configuration"))))),
                        React.createElement(Tab.Content, null,
                            React.createElement(Tab.Pane, { eventKey: "details" },
                                React.createElement(Container, { fluid: true },
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                                React.createElement(Form.Label, null,
                                                    "Name ",
                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                React.createElement(Form.Control, { placeholder: "Enter Name", defaultValue: currentFeature.Title, onChange: function (event) { return dispatch(FeatureCurrentStore.Update({ Title: event.target.value })); } }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-2"], Value: currentFeature.Title })),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                                                React.createElement(Form.Label, null, "Description (Optional)"),
                                                React.createElement(Form.Control, { as: "textarea", 
                                                    //placeholder="Tell people the purpose of this feature"
                                                    rows: 3, defaultValue: currentFeature.Description, onChange: function (event) { return dispatch(FeatureCurrentStore.Update({ Description: event.target.value })); } })),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-icons" },
                                                React.createElement(Form.Label, null,
                                                    "Icons ",
                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                React.createElement(FormDropdownSelect, { list: iconOptions, theme: "dark-icons", placeholder: "Please select icon", enableSearch: true, defaultValue: currentFeature.Icon, waitToRenderDDItemsUntilOpen: true, onChange: function (value) { return dispatch(FeatureCurrentStore.Update({ Icon: value })); } }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "icons", Validators: ["dropdown-has-value"], Value: currentFeature.Icon }))),
                                        React.createElement(Col, { md: 5, lg: 4, xl: 3, className: "right-col" },
                                            React.createElement(FormImageUploadBox, { defaultBase64Image: currentFeature.Image, maxSize: 900, text: "Upload or drop an image here", onDrop: function (file) { return dispatch(FeatureCurrentStore.Update({ Image: file })); } })))),
                                React.createElement("div", { className: "divider-line", style: { marginTop: "80px" } }),
                                React.createElement(Container, { fluid: true },
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                            React.createElement("h3", null, "Associated templates"),
                                            React.createElement("div", null, currentFeature.GUID !== undefined && (React.createElement(AssociateTemplate, { associatedTemplates: currentFeature.AssociatedTemplates ? currentFeature.AssociatedTemplates : [], onAssociatedAdd: function (ass) {
                                                    onAssociatedAdd(ass);
                                                }, onAssociatedRemove: function (ass) {
                                                    onAssociatedRemove(ass);
                                                } },
                                                React.createElement("div", { className: "at-search" },
                                                    React.createElement(AssociateTemplate.Search, null)),
                                                React.createElement("div", { className: "at-count" },
                                                    React.createElement(AssociateTemplate.Count, null)),
                                                React.createElement("div", { className: "at-items" },
                                                    React.createElement(AssociateTemplate.SelectedItems, null))))))))),
                            React.createElement(Tab.Pane, { eventKey: "configuration" },
                                React.createElement(Row, null,
                                    React.createElement(Col, { md: 8, lg: 7, xl: 6 },
                                        React.createElement(Form.Group, { controlId: "config-type", className: "form-group radio-green" },
                                            React.createElement(Form.Label, null, "PnP (Patterns and Practices) .xml/.pnp file"),
                                            React.createElement("p", { className: "pnp-schema" },
                                                "Orchestry currently supports",
                                                " ",
                                                React.createElement("a", { href: "https://github.com/pnp/PnP-Provisioning-Schema/blob/master/ProvisioningSchema-2021-03.md", target: "_blank", rel: "noopener noreferrer" }, "PNP schema 2021/03")),
                                            currentFeature.IsCustomized ? (React.createElement("div", { role: "alert", className: "customized-alert alert alert-solid-info alert-bold" },
                                                React.createElement("div", { className: "alert-icon" },
                                                    React.createElement("i", { className: "fa-regular fa-circle-info" })),
                                                React.createElement("div", { className: "alert-text" }, "This Feature's PnP File has been customized"))) : null,
                                            React.createElement(React.Fragment, null, currentFeature.ConfigFile !== undefined && currentFeature.ConfigFile !== null && currentFeature.ConfigFile !== "" ? (React.createElement("div", { className: "document-preview" },
                                                React.createElement(DocumentTemplatePreview, { fileName: currentFeature.ConfigFile, azureLink: "/api/features/Download?FeatureGuid=" + currentFeature.GUID.toString(), OnRemove: function () {
                                                        onFileRemove();
                                                        if (featureCustomized) {
                                                            setfeatureCustomized(false);
                                                            dispatch(FeatureCurrentStore.Update({ IsCustomized: false }));
                                                        }
                                                    }, showFileName: true }))) : (React.createElement(React.Fragment, null, uploadFileName !== "" ? (React.createElement("div", { className: "document-preview" },
                                                React.createElement(DocumentTemplatePreview, { fileName: uploadFileName, OnRemove: function () {
                                                        setUploadFileName("");
                                                        if (featureCustomized) {
                                                            setfeatureCustomized(false);
                                                            dispatch(FeatureCurrentStore.Update({ IsCustomized: false }));
                                                        }
                                                    }, noPreviewText: "Please save the feature to upload this document", showFileName: true }))) : (React.createElement(React.Fragment, null,
                                                React.createElement("p", { className: "instructions" }, "Please upload a PnP (Patterns and Practices) .xml/.pnp file or extract a template to use."),
                                                React.createElement("div", { className: "upload-area" },
                                                    React.createElement("div", { className: "left" },
                                                        React.createElement(FormFileUploadBox, { defaultBase64File: currentFeature.File, uploadText: "Upload or drop a document here", enforceSafeFileName: true, onDrop: function (file, fileName) { return addFile(file, fileName); } })),
                                                    React.createElement("div", { className: "or" }, "Or"),
                                                    React.createElement("div", { className: "right" },
                                                        React.createElement("div", { className: "select-extracted-file", onClick: function () { return setShowModal(!showModal); } },
                                                            React.createElement("div", { className: "icon" },
                                                                React.createElement("i", { className: "las la-box-open" })),
                                                            React.createElement("div", { className: "text" }, "Use extracted template"))),
                                                    React.createElement(Modal, { centered: true, show: showModal, onHide: function () { return setShowModal(!showModal); }, animation: true, className: "feature-file-modal", backdropClassName: "feature-file-modal-backdrop" },
                                                        React.createElement(FeatureFiles, { onSelect: function (filename) { return onExtractedSelected(filename); } })))))))),
                                            fileUploadPercentage !== undefined ? (React.createElement("div", { className: "progress" },
                                                React.createElement("div", { className: "progress-bar progress-bar-striped bg-success", style: { width: fileUploadPercentage } }))) : null))))))))) : (React.createElement(Portlet, null,
                React.createElement(PortletBody, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 46, marginBottom: 25, marginTop: 20 }),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6, lg: 5, xl: 5 },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginBottom: 26 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginBottom: 26 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })),
                        React.createElement(Col, { md: 5, lg: 4, xl: 4, className: "right-col" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 300, marginBottom: 7 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19 })))))))));
});
export default FeatureDetails;
