/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import "./notification-panel.scss";
import { Nav, Tab } from "react-bootstrap";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import { useSelector, useDispatch } from "react-redux";
import * as UserNotificationListStore from "app/store/user-notifications/list";
import moment from "moment";
import { NavLink } from "react-router-dom";
import KTOffcanvas from "_metronic/_assets/js/offcanvas";
import { useIntl, FormattedMessage } from "react-intl";
export var NotificationPanel = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var notifications = useSelector(function (state) { return state.userNotificationListState.items; });
    var _a = React.useState(), offCanvas = _a[0], setOffCanvas = _a[1];
    var notificationTypes = [
        {
            Type: "RequestApproval",
            Title: intl.formatMessage({ id: "component.notification_panel.waiting_for_approval", defaultMessage: "Waiting for Approval" }),
            Link: "/requests/view/{ResourceGuid}",
            Actions: [
                {
                    Title: intl.formatMessage({ id: "component.notification_panel.decline", defaultMessage: "Decline" }),
                    Link: "/requests/view/{ResourceGuid}/reject"
                },
                {
                    Title: intl.formatMessage({ id: "component.notification_panel.approve", defaultMessage: "Decline" }),
                    Link: "/requests/view/{ResourceGuid}/approve"
                }
            ]
        },
        {
            Type: "RequestRecent",
            Title: intl.formatMessage({ id: "component.notification_panel.recently_requested", defaultMessage: "Recently Requested" }),
            Link: "/requests/view/{ResourceGuid}",
            Actions: [
                {
                    Title: intl.formatMessage({ id: "component.notification_panel.dismiss", defaultMessage: "Dismiss" }),
                    Method: function (item) {
                        dismissItem(item);
                    }
                }
            ]
        },
        {
            Type: "RequestAutomaticallyProvisioned",
            Title: intl.formatMessage({ id: "component.notification_panel.automatically_provisioned", defaultMessage: "Automatically Provisioned" }),
            Link: "/requests/view/{ResourceGuid}",
            Actions: [
                {
                    Title: intl.formatMessage({ id: "component.notification_panel.dismiss", defaultMessage: "Dismiss" }),
                    Method: function (item) {
                        dismissItem(item);
                    }
                }
            ]
        },
        {
            Type: "RequestWithApprovalsProvisioned",
            Title: intl.formatMessage({ id: "component.notification_panel.provisioned_with_approvals", defaultMessage: "Request Approved and Provisioned" }),
            Link: "/requests/view/{ResourceGuid}",
            Actions: [
                {
                    Title: intl.formatMessage({ id: "component.notification_panel.dismiss", defaultMessage: "Dismiss" }),
                    Method: function (item) {
                        dismissItem(item);
                    }
                }
            ]
        },
        {
            Type: "Error",
            Title: intl.formatMessage({ id: "component.notification_panel.error", defaultMessage: "An Error has occured" }),
            Link: "/requests/view/{ResourceGuid}",
            Actions: [
                {
                    Title: intl.formatMessage({ id: "component.notification_panel.dismiss", defaultMessage: "Dismiss" }),
                    Method: function (item) {
                        dismissItem(item);
                    }
                }
            ]
        }
    ];
    var getContent = function (un) {
        var currentType = notificationTypes.find(function (nt) { return nt.Type === un.Type; });
        var Title = "";
        var Time = moment
            .utc(un.CreatedDate)
            .local()
            .format("LLL");
        var Actions = React.createElement("div", null);
        if (currentType !== undefined) {
            return (React.createElement("div", { key: un.GUID },
                React.createElement("div", { className: "notification-header" },
                    currentType !== undefined && currentType.Link !== null && currentType.Link !== "" ? (React.createElement(NavLink, { to: setupActionLink(un.ResourceGuid, currentType.Link), onClick: function () {
                            closePanel();
                        } },
                        React.createElement("div", { className: "notification-title" }, currentType.Title))) : (React.createElement("div", { className: "notification-title" }, currentType.Title)),
                    React.createElement("div", { className: "notification-date clone-notification-panel" }, Time)),
                currentType !== undefined && currentType.Link !== null && currentType.Link !== "" ? (React.createElement(NavLink, { to: setupActionLink(un.ResourceGuid, currentType.Link), onClick: function () {
                        closePanel();
                    } },
                    React.createElement("div", { className: "message", dangerouslySetInnerHTML: { __html: un.Message } }))) : (React.createElement("div", { className: "message", dangerouslySetInnerHTML: { __html: un.Message } })),
                React.createElement("div", { className: "actions" }, //
                //@ts-ignore
                currentType.Actions.map(function (action, index) { return (React.createElement("span", { key: index },
                    action.Link !== undefined ? (React.createElement(NavLink, { className: "btn size-small kt-margin-r-10 btn-" + (action.Link.indexOf("/reject") > -1 ? "decline" : "") + (action.Link.indexOf("/approve") ? "approve" : ""), to: setupActionLink(un.ResourceGuid, action.Link), onClick: function () {
                            closePanel();
                        } }, action.Title)) : null,
                    action.Method !== undefined ? (React.createElement("div", { className: "action-button", onClick: function () {
                            action.Method(un);
                        } }, action.Title)) : null)); }))));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    var setupActionLink = function (resourceGuid, linkUrl) {
        return linkUrl.replace("{ResourceGuid}", resourceGuid);
    };
    var dismissItem = function (un) {
        dispatch(UserNotificationListStore.DeleteItem(un));
    };
    React.useEffect(function () {
        var panel = document.getElementById("kt_quick_panel");
        setOffCanvas(
        //@ts-ignore
        new KTOffcanvas(panel, {
            overlay: true,
            baseClass: "kt-quick-panel",
            closeBy: "clone-notification-panel",
            toggleBy: "kt_quick_panel_toggler_btn"
        }));
    }, []);
    var closePanel = function () {
        if (offCanvas !== undefined) {
            offCanvas.hide();
        }
    };
    return (React.createElement("div", { id: "kt_quick_panel", className: "kt-quick-panel notification-panel" },
        React.createElement("a", { href: "#", className: "kt-quick-panel__close", id: "kt_quick_panel_close_btn", onClick: function () {
                closePanel();
            } },
            React.createElement("i", { className: "flaticon2-delete" })),
        React.createElement(Tab.Container, { defaultActiveKey: "Notifications" },
            React.createElement("div", { className: "kt-quick-panel__nav" },
                React.createElement(Nav, { className: "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x" },
                    React.createElement(Nav.Item, { className: "nav-item" },
                        React.createElement(Nav.Link, { eventKey: "Notifications", className: "nav-link show" },
                            React.createElement(FormattedMessage, { id: "component.notification_panel.title", defaultMessage: "Notifications" }))))),
            React.createElement("div", { className: "kt-quick-panel__content" },
                React.createElement(Tab.Content, null,
                    React.createElement(Tab.Pane, { eventKey: "Notifications" },
                        React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: window.innerHeight - 120 },
                            React.createElement("div", { className: "kt-notification" },
                                notifications.map(function (notification) { return (React.createElement("div", { key: notification.GUID, className: "kt-notification__item" }, getContent(notification))); }),
                                notifications === undefined || (notifications !== undefined && notifications.length === 0) ? (React.createElement("div", { className: "no-results" },
                                    React.createElement(FormattedMessage, { id: "component.notification_panel.no_new_notifications", defaultMessage: "No new notifications" }))) : null))))))));
});
export default NotificationPanel;
