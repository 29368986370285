import DynamicScollbar from "app/components/common/dynamic-scollbar";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { InstallWizardContext } from "../..";
import "./terms.component.scss";
// Instructions to saving a word doc as html.
// Save the word document as html. Make sure to pick "Web Page, Filtered" as the format.
// Open the html file and replace the following characters:
// “ with "
// ” with "
// ’ with '
// – with -
// eslint-disable-next-line import/no-webpack-loader-syntax
var htmlModule = require("raw-loader!../../../../../assets/static/SaaSAgreement-20240418.html");
var html = htmlModule.default;
var InstallerStepTerms = React.memo(function (props) {
    var iwc = React.useContext(InstallWizardContext);
    return (React.createElement("div", { className: "installer-step-terms fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { style: { fontSize: "14px" } },
                React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
                    React.createElement("div", { className: "kt-heading kt-heading--md" },
                        React.createElement("h2", null, "Terms of Service")),
                    React.createElement("div", { className: "scroll-area" },
                        React.createElement(DynamicScollbar, { maxHeight: 400, shouldShow: true, handleScrolledBottom: function () {
                                console.log("bottom");
                                console.log(iwc.termsReachedBottom);
                                iwc.setTermsReachedBottom(true);
                            } },
                            React.createElement("div", { className: "scroll-inner-wrapper" },
                                React.createElement("div", { dangerouslySetInnerHTML: { __html: html } })))),
                    React.createElement("div", { className: "read-notice" },
                        React.createElement(Collapse, { in: iwc.termsReachedBottom === false },
                            React.createElement("div", { className: "please-read" }, "Please fully read the terms of service to the bottom."))),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox21", className: "form-group checkbox-green" + (!iwc.termsReachedBottom ? " bottom-not-reached" : "") },
                        React.createElement(Form.Check, { type: "checkbox", disabled: !iwc.termsReachedBottom, label: React.createElement(React.Fragment, null, "I agree with these terms"), defaultChecked: iwc.hasAgreedToTerms, onChange: function (event) { return iwc.setHasAgreedToTerms(event.target.checked); } })))))));
});
export default InstallerStepTerms;
