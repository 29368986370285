export var ReportSearchSlotName;
(function (ReportSearchSlotName) {
    ReportSearchSlotName["WorkspaceInsightsSlot"] = "WorkspaceInsightsSlot";
    ReportSearchSlotName["WorkspaceMembershipsSlot"] = "WorkspaceMembershipsSlot";
    ReportSearchSlotName["GuestInsightsSlot"] = "GuestInsightsSlot";
    ReportSearchSlotName["GuestMembershipsSlot"] = "GuestMembershipsSlot";
    ReportSearchSlotName["GuestDashboardSlot"] = "GuestDashboardSlot";
    ReportSearchSlotName["InsightsButton"] = "InsightsButton";
})(ReportSearchSlotName || (ReportSearchSlotName = {}));
export var ReportSearchActionTypes;
(function (ReportSearchActionTypes) {
    ReportSearchActionTypes["Report_Load"] = "Report_Load";
    ReportSearchActionTypes["Report_Load_WithContext"] = "Report_Load_WithContext";
    ReportSearchActionTypes["Report_Load_Minimal"] = "Report_Load_Minimal";
    ReportSearchActionTypes["Report_Clear"] = "Report_Clear";
    ReportSearchActionTypes["Report_Refresh"] = "Report_Refresh";
    ReportSearchActionTypes["Report_RefreshSilent"] = "Report_RefreshSilent";
    ReportSearchActionTypes["Report_Load_Refiners"] = "Report_Load_Refiners";
    ReportSearchActionTypes["Report_Load_Views"] = "Report_Load_Views";
    ReportSearchActionTypes["Report_Load_RefinersAndFavs"] = "Report_Load_RefinersAndFavs";
    ReportSearchActionTypes["Report_Load_Favs"] = "Report_Load_Favs";
    ReportSearchActionTypes["Report_Search"] = "Report_Search";
    ReportSearchActionTypes["Report_Update_Paging"] = "Report_Update_Paging";
    ReportSearchActionTypes["Report_Update_Context"] = "Report_Update_Context";
    ReportSearchActionTypes["Report_Update_Refiners"] = "Report_Update_Refiners";
    ReportSearchActionTypes["Report_Update_View"] = "Report_Update_View";
    ReportSearchActionTypes["Report_Update_Favs"] = "Report_Update_Favs";
    ReportSearchActionTypes["Report_Update_FavsOnServer"] = "Report_Update_FavsOnServer";
    ReportSearchActionTypes["Report_Update_CachedResultItem"] = "Report_Update_CachedResultItem";
    ReportSearchActionTypes["Report_GenerateFile"] = "Report_GenerateFile";
    ReportSearchActionTypes["Report_Update_IsLoading"] = "Report_Update_IsLoading";
    ReportSearchActionTypes["Report_Update_IsChangingView"] = "Report_Update_IsChangingView";
    ReportSearchActionTypes["Report_Update_SilentRefreshItems"] = "Report_Update_SilentRefreshItems";
    ReportSearchActionTypes["Report_Bulk_DeleteGuestFromAzure_Success"] = "Report_Bulk_DeleteGuestFromAzure_Success";
    ReportSearchActionTypes["Report_Bulk_ResendInvite_Success"] = "Report_Bulk_ResendInvite_Success";
    ReportSearchActionTypes["Report_Bulk_RemoveGuestFromWorkspaces_Success"] = "Report_Bulk_RemoveGuestFromWorkspaces_Success";
})(ReportSearchActionTypes || (ReportSearchActionTypes = {}));
