import { ApprovalWorkflowCurrentActionTypes } from "./types";
var initialState = {
    item: {}
};
export function approvalWorkflowCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load: {
            //console.log(ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Update: {
            var updatedApprovalWorkflow = Object.assign({}, state.item, action.payload);
            return {
                item: updatedApprovalWorkflow
            };
        }
        case ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
