import { RequestListActionTypes } from "app/store/requests/list/types";
import { AsyncCallType } from "app/store/async/types";
export var requestListActionMap = [
    {
        actionType: RequestListActionTypes.Request_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/Requests",
        responseDataType: {},
        invalidates: [RequestListActionTypes.Request_List_Load]
    },
    {
        actionType: RequestListActionTypes.Request_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/Requests",
        responseDataType: {},
        invalidates: [RequestListActionTypes.Request_List_Load]
    },
    {
        actionType: RequestListActionTypes.Request_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/Requests",
        responseDataType: {},
        invalidates: [RequestListActionTypes.Request_List_Load]
    },
    {
        actionType: RequestListActionTypes.Request_List_Load,
        callType: AsyncCallType.List,
        // stateLocationForArray: "requestListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Requests",
        responseDataType: []
    },
    {
        actionType: RequestListActionTypes.Request_List_Load_Page,
        callType: AsyncCallType.List,
        // stateLocationForArray: "requestListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Requests/Page?ResultsPerPage={paging.ResultsPerPage}&CurrentPage={paging.CurrentPage}&View={view}",
        responseDataType: []
    },
    {
        actionType: RequestListActionTypes.Request_List_Load_User_Page,
        callType: AsyncCallType.List,
        // stateLocationForArray: "requestListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Requests/Page?ResultsPerPage={paging.ResultsPerPage}&CurrentPage={paging.CurrentPage}&View={view}",
        responseDataType: []
    }
];
