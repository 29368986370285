var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Tooltip } from "@mantine/core";
import React from "react";
var defaultProps = {
    withinPortal: false,
    multiline: false
};
export var OrchestryTooltip = function (props) {
    if (!props.label) {
        return React.createElement(React.Fragment, null, props.children);
    }
    return (React.createElement(Tooltip, __assign({ zIndex: 99, arrowPosition: "center", arrowOffset: 16, arrowSize: 8, withArrow: true, position: "top-start", openDelay: 500, transitionProps: { duration: 300 } }, props), props.children));
};
OrchestryTooltip.defaultProps = defaultProps;
