import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
var fontAwesomeHelper = /** @class */ (function () {
    function fontAwesomeHelper() {
    }
    fontAwesomeHelper.getIconName = function (icon) {
        return icon.iconName;
    };
    fontAwesomeHelper.getIconFromName = function (iconName) {
        //let foundIcon:Icons.IconDefinition = findIconDefinition({iconName: 'user', prefix: 'fas'})
        var foundIcon = {};
        Object.values(Icons).forEach(function (i) {
            //console.log(i, "item");
            if (i.iconName === iconName) {
                //console.log("font-match", i.iconName, iconName);
                foundIcon = i;
            }
        });
        return foundIcon;
    };
    fontAwesomeHelper.getIconOptionsForDropDown = function () {
        //let optionList:Option = [];
        //console.log("Icon LIst", library["definitions"].fas);
        var optionList = [];
        Object.values(Icons).forEach(function (i, index) {
            if (i.iconName !== undefined) {
                var tempTitle = i.iconName.split("-").join(" ");
                //tempTitle = tempTitle),
                optionList.push({
                    id: String(index),
                    title: fontAwesomeHelper.titleCase(tempTitle),
                    value: i.iconName,
                    icon: i
                });
            }
        });
        //console.log("font-match", optionList);
        return optionList;
    };
    fontAwesomeHelper.titleCase = function (str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(" ");
    };
    return fontAwesomeHelper;
}());
export default fontAwesomeHelper;
