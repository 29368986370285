var ORCH_SVG_ICONS = {
    BeaconBlackSmall: require("./beacon-black-small.svg").default,
    Beacon: require("./beacon.svg").default,
    BeaconLogomark: require("./BeaconLogomark.svg").default,
    CheckIcon: require("./Check icon.svg").default,
    Info: require("./info.svg").default,
    LogoLarge: require("./logo-large.svg").default,
    LogoMarkBlackSmall: require("./logo-mark-black-small.svg").default,
    LogoMarkColorSmall: require("./logo-mark-color-small.svg").default,
    LogoMarkColour: require("./logo-mark-colour.svg").default,
    LogoMarkMono: require("./logo-mark-mono.svg").default,
    Logomark: require("./Logomark.svg").default,
    PartyPopper: require("./party-popper.svg").default,
    ThumbsDown: require("./thumbs-down.svg").default,
    ThumbsUp: require("./thumbs-up.svg").default,
};
export default ORCH_SVG_ICONS;
