export var DetermineRequestSource = function (document) {
    var source = "WebApp";
    if (document["OrchestryIsInTeams"]) {
        source = "TeamsApp";
    }
    if (document["OrchestryIsInSharePoint"]) {
        source = "WebPart";
    }
    return source;
};
