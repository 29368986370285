import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import FormImageUploadBox from "app/components/common/form-imageuploadbox";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import InfoTooltip from "app/components/common/infotooltip";
import MetadataField from "app/components/common/metadata-field";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import TeamLink from "app/components/common/team-link";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import NamingPolicyPreview, { GetNamingPolicyPreview } from "app/components/workspaces/naming-policies/naming-policy-preview";
import SharePointService from "app/services/sharepoint";
import { MetadataConfigurationType } from "app/store/templates";
import moment from "moment";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { DetermineRequestSource } from "./details-step.utils";
import { SHAREPOINT_MULTIGEO_CODES } from "./sharepoint-geocodes";
import "./step-details.scss";
var nameTypingTimer;
var urlTypingTimer;
var StepDetails = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
    var intl = useIntl();
    var rwc = React.useContext(RequestWizardContext);
    var _9 = React.useState(false), isPageValid = _9[0], setIsPageValid = _9[1];
    var _10 = React.useState(false), isNameLoading = _10[0], setIsNameLoading = _10[1];
    var _11 = React.useState(((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.TemplateType) === "VivaEngageCommunity"), isVivaEngageCommunity = _11[0], setisVivaEngageCommunity = _11[1];
    var _12 = React.useState(true), isNameValid = _12[0], setIsNameValid = _12[1];
    var _13 = React.useState([]), blockedWordsBeingUsed = _13[0], setBlockedWordsBeingUsed = _13[1];
    var _14 = React.useState(), teamWithExactName = _14[0], setTeamWithExactName = _14[1];
    var _15 = React.useState([]), teamsWithSimilarNames = _15[0], setTeamsWithSimilarNames = _15[1];
    var _16 = React.useState(), validationAjaxResults = _16[0], setValidationAjaxResults = _16[1];
    //const [siteUrl, setSiteUrl] = React.useState("");
    var _17 = React.useState(false), siteUrlIsLoading = _17[0], setSiteUrlIsLoading = _17[1];
    var _18 = React.useState(false), siteUrlIsValid = _18[0], setSiteUrlIsValid = _18[1];
    var _19 = React.useState(""), siteUrlFullUrl = _19[0], setSiteUrlFullUrl = _19[1];
    var _20 = React.useState(false), siteUrlHasBeenEdited = _20[0], setSiteUrlHasBeenEdited = _20[1];
    var _21 = React.useState(true), nameFocused = _21[0], setNameFocused = _21[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var defaultDescriptionTemplate = intl.formatMessage({
        id: "page.request_wizard.step_details.default_description",
        defaultMessage: "[WorkspaceName] requested by [FullNameOfRequestor] on [TodaysDate]"
    });
    var _22 = React.useState(false), namingPolicyRequiredFieldsDone = _22[0], setNamingPolicyRequiredFieldsDone = _22[1];
    var _23 = React.useState(null), lastCustomMetdataFieldEditedGuid = _23[0], setLastCustomMetdataFieldEditedGuid = _23[1];
    var _24 = React.useState(true), geoSelected = _24[0], setgeoSelected = _24[1];
    var currentTemplateConfig = useSelector(function (state) { return state.requestCurrentState.item.Template; });
    var currentTenantConfig = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        var _a;
        rwc.setStepProgress(props.step.key, isPageValid);
        if (rwc.isSiteUrlCustom) {
            setSiteUrlHasBeenEdited(true);
            runUrlValidators();
        }
        //if(rwc.nameStorage){
        rwc.updateCurrentRequest({
            //Name: rwc.nameStorage,
            ShowInDirectory: rwc.currentRequest.ShowInDirectory === undefined ? true : rwc.currentRequest.ShowInDirectory
        });
        //}
        var focusInput = document.querySelector(".workspace-name .name input");
        if (focusInput !== null) {
            if (getNamingPolicyRequiredGuids().length === 0) {
                focusInput.focus();
            }
        }
        if (needsMetadataValuesForNamingPolicy() === false) {
            setNamingPolicyRequiredFieldsDone(true);
        }
        if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.TemplateMultiGeoDataLocationSource) === "RequestorsChoice") {
            setgeoSelected(false);
        }
        var requestSource = DetermineRequestSource(document);
        console.log(requestSource);
        rwc.updateCurrentRequest({ RequestSource: requestSource });
        // rwc.setOnNavigateClick(props.step.key, () => {
        //    console.log("NAVIGATED AWAY FROM " + props.step.key);
        // });
    }, []);
    React.useEffect(function () {
        if (namingPolicyRequiredFieldsDone === true) {
            window.scroll({
                top: 0,
                behavior: "smooth"
            });
            startNameValidation();
        }
    }, [namingPolicyRequiredFieldsDone]);
    React.useEffect(function () {
        var _a;
        if (rwc.currentRequest.NewRequestName !== undefined && rwc.currentRequest.NewRequestName !== null) {
            var newName = rwc.currentRequest.NewRequestName;
            if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === true &&
                rwc.currentRequest.Template.NamingPolicyGUID !== undefined &&
                rwc.currentRequest.Template.NamingPolicyGUID !== null &&
                rwc.currentNamingPolicy !== undefined &&
                rwc.currentNamingPolicy.RenameWorkspaceTitle === true &&
                rwc.currentUser !== undefined &&
                rwc.currentRequest.NewRequestName !== undefined) {
                newName = GetNamingPolicyPreview(rwc.currentNamingPolicy, rwc.currentRequest.NewRequestName, rwc.currentUser, "name", rwc.getOrchestryMetadataValues(rwc.currentRequest.CustomMetadataValuesJson));
                // newName = GetNamingPolicyPreview(rwc.currentNamingPolicy, rwc.currentRequest.NewRequestName, rwc.currentUser, "name");
            }
            if (newName !== rwc.currentRequest.Name) {
                rwc.updateCurrentRequest({ Name: newName });
            }
        }
        startNameValidation();
        if (siteUrlHasBeenEdited === false && isWorkspaceNameEditingEnabled() === true) {
            setSiteUrlIsValid(false);
            setSafeUrl(rwc.currentRequest.NewRequestName);
        }
    }, [rwc.currentRequest.NewRequestName, rwc.currentRequest.CustomMetadataValuesJson, (_b = rwc.currentNamingPolicy) === null || _b === void 0 ? void 0 : _b.Title]);
    React.useEffect(function () {
        if (siteUrlHasBeenEdited) {
            setSiteUrlIsLoading(true);
            setSiteUrlIsValid(false);
            runUrlValidators();
        }
    }, [rwc.currentRequest.SiteURL]);
    React.useEffect(function () {
        var _a, _b;
        if ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.DefaultWorkspaceLogo)
            rwc.updateCurrentRequest({ Image: (_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.DefaultWorkspaceLogo });
    }, [(_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.DefaultWorkspaceLogo]);
    React.useEffect(function () {
        checkIfNameIsValid();
    }, [rwc.currentRequest.NewRequestName, teamWithExactName]);
    var startNameValidation = function () {
        if ((rwc.currentRequest.NewRequestName !== undefined && rwc.currentRequest.NewRequestName.length > 1 && namingPolicyRequiredFieldsDone === true) ||
            (isWorkspaceNameEditingEnabled() === false && namingPolicyRequiredFieldsDone === true)) {
            setIsNameLoading(true);
            runNameValidators();
        }
        else {
            //if(isNameLoading === true)
            setTeamWithExactName(undefined);
            setTeamsWithSimilarNames([]);
            setBlockedWordsBeingUsed([]);
            setIsNameLoading(false);
        }
    };
    var checkIfNameIsValid = function () {
        var tempIsValid = true;
        if (rwc.currentRequest.NewRequestName === undefined || rwc.currentRequest.NewRequestName === null) {
            tempIsValid = false;
        }
        if (rwc.currentRequest.NewRequestName !== undefined && rwc.currentRequest.NewRequestName.length < 2) {
            tempIsValid = false;
        }
        if (teamWithExactName !== undefined) {
            tempIsValid = false;
        }
        if (blockedWordsBeingUsed.length > 0) {
            tempIsValid = false;
        }
        setIsNameValid(tempIsValid);
    };
    var runNameValidators = function () {
        clearTimeout(nameTypingTimer);
        nameTypingTimer = setTimeout(function () {
            var _a, _b, _c;
            if (rwc.currentRequest.NewRequestName !== undefined || (isWorkspaceNameEditingEnabled() === false && namingPolicyRequiredFieldsDone === true)) {
                setTeamWithExactName(undefined);
                setTeamsWithSimilarNames([]);
                setBlockedWordsBeingUsed([]);
                var nameBeforePolicyApplied = rwc.currentRequest.NewRequestName !== undefined ? rwc.currentRequest.NewRequestName : "";
                var nameWithPolicyApplied = nameBeforePolicyApplied;
                if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === true &&
                    rwc.currentRequest.Template.NamingPolicyGUID !== undefined &&
                    rwc.currentRequest.Template.NamingPolicyGUID !== null &&
                    rwc.currentNamingPolicy !== undefined &&
                    // rwc.currentNamingPolicy.RenameWorkspaceUrl === true &&
                    rwc.currentUser !== undefined) {
                    nameWithPolicyApplied = GetNamingPolicyPreview(rwc.currentNamingPolicy, nameWithPolicyApplied, rwc.currentUser, "name", rwc.getOrchestryMetadataValues());
                }
                var siteUrl = (!siteUrlHasBeenEdited ? getSafeUrl(rwc.currentRequest.NewRequestName) : rwc.currentRequest.SiteURL);
                var isCommSite = ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.TemplateType) === "SharePoint" && ((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.SharePointTemplateType) === "ModernCommunications";
                SharePointService.ValidateWorkspaceName(nameWithPolicyApplied, siteUrl, isCommSite, nameBeforePolicyApplied, rwc.currentRequest.RequestMultiGeoDataLocation)
                    .then(function (results) {
                    //console.log("ajax done", rwc.currentRequest.NewRequestName?.length );
                    if (results.Cancel === undefined) {
                        if (results.ExactSameNameSite !== undefined && results.ExactSameNameSite[0] !== undefined && results.ExactSameNameSite[0].ResultRows !== undefined) {
                            if (results.ExactSameNameSite[0].ResultRows.length > 0) {
                                var newResults = results.ExactSameNameSite[0].ResultRows.filter(function (rr) {
                                    return true;
                                });
                                results.ExactSameNameSite[0].ResultRows = newResults;
                            }
                        }
                        if (results.SimilarSites !== undefined && results.SimilarSites[0] !== undefined && results.SimilarSites[0].ResultRows !== undefined) {
                            if (results.SimilarSites[0].ResultRows.length > 0) {
                                var newResults = results.SimilarSites[0].ResultRows.filter(function (rr) {
                                    return true;
                                });
                                results.SimilarSites[0].ResultRows = newResults;
                            }
                        }
                        if (results.SimilarSitesWithoutNamingPolicy !== undefined &&
                            results.SimilarSitesWithoutNamingPolicy !== null &&
                            results.SimilarSitesWithoutNamingPolicy[0] !== undefined &&
                            results.SimilarSitesWithoutNamingPolicy[0].ResultRows !== undefined) {
                            if (results.SimilarSitesWithoutNamingPolicy[0].ResultRows.length > 0) {
                                if (results.SimilarSites !== undefined && results.SimilarSites[0] !== undefined && results.SimilarSites[0].ResultRows !== undefined) {
                                    results.SimilarSitesWithoutNamingPolicy[0].ResultRows.forEach(function (npRr) {
                                        if (!results.SimilarSites[0].ResultRows.some(function (s) { return s.SiteId === npRr.SiteId; })) {
                                            results.SimilarSites[0].ResultRows.push(npRr);
                                        }
                                    });
                                }
                            }
                        }
                        setValidationAjaxResults(results);
                    }
                })
                    .catch(function () {
                    console.log("err");
                });
                if (rwc.currentTenantConfiguration.CustomBlockedWordsList !== undefined && rwc.currentTenantConfiguration.CustomBlockedWordsList !== "") {
                    var currentWords_1 = JSON.parse(rwc.currentTenantConfiguration.CustomBlockedWordsList);
                    var matchedWords_1 = [];
                    if (Array.isArray(currentWords_1)) {
                        var values = nameWithPolicyApplied.split(/[^A-Za-z0-9]/);
                        //console.log("Is Blocked", currentWords, values);
                        values.forEach(function (v) {
                            var foundWordMatch = currentWords_1.find(function (cw) { return cw.toLowerCase() === v.toLowerCase(); });
                            if (foundWordMatch !== undefined && foundWordMatch !== null && foundWordMatch.length > 0) {
                                matchedWords_1.push(foundWordMatch);
                            }
                        });
                    }
                    if (matchedWords_1.length > 0) {
                        setBlockedWordsBeingUsed(matchedWords_1);
                    }
                }
                //setGroupName(value);
            }
        }, 1000);
        //console.log("Timer", nameTypingTimer, rwc.currentRequest.NewRequestName);
    };
    var runUrlValidators = function () {
        clearTimeout(urlTypingTimer);
        urlTypingTimer = setTimeout(function () {
            var _a, _b;
            if (rwc.currentRequest.SiteURL !== undefined) {
                var isCommSite = ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.TemplateType) === "SharePoint" && ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.SharePointTemplateType) === "ModernCommunications";
                SharePointService.DoesSiteCollectionExistWithUrlReturned(rwc.currentRequest.SiteURL, isCommSite).then(function (results) {
                    if (results.Cancel === undefined) {
                        setSiteUrlIsValid(!results.DoesSiteExist);
                        setSiteUrlFullUrl(results.SiteUrl);
                        setSiteUrlIsLoading(false);
                    }
                });
            }
        }, 1000);
        //console.log("Timer", nameTypingTimer, rwc.currentRequest.NewRequestName);
    };
    // Put in effect so that I could access name length after call.
    React.useEffect(function () {
        if (validationAjaxResults !== undefined &&
            ((rwc.currentRequest.NewRequestName !== undefined && rwc.currentRequest.NewRequestName.length > 1) || (isWorkspaceNameEditingEnabled() === false && namingPolicyRequiredFieldsDone === true))) {
            setIsNameLoading(false);
            if (validationAjaxResults.DoesSiteExist !== undefined) {
                if (!siteUrlHasBeenEdited) {
                    setSiteUrlIsValid(!validationAjaxResults.DoesSiteExist);
                    setSiteUrlFullUrl(validationAjaxResults.SiteUrl);
                }
            }
            if (validationAjaxResults.ExactSameNameSite !== undefined &&
                validationAjaxResults.ExactSameNameSite !== null &&
                validationAjaxResults.ExactSameNameSite[0] !== undefined &&
                validationAjaxResults.ExactSameNameSite[0].ResultRows !== undefined) {
                validationAjaxResults.ExactSameNameSite[0].ResultRows.forEach(function (site) {
                    var _a;
                    var nameWithPolicyApplied = rwc.currentRequest.NewRequestName !== undefined ? rwc.currentRequest.NewRequestName : "";
                    if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === true &&
                        rwc.currentRequest.Template.NamingPolicyGUID !== undefined &&
                        rwc.currentRequest.Template.NamingPolicyGUID !== null &&
                        rwc.currentNamingPolicy !== undefined &&
                        rwc.currentUser !== undefined) {
                        nameWithPolicyApplied = GetNamingPolicyPreview(rwc.currentNamingPolicy, nameWithPolicyApplied, rwc.currentUser, "name", rwc.getOrchestryMetadataValues());
                    }
                    if (site.Title.toLowerCase() === nameWithPolicyApplied.toLowerCase()) {
                        //console.log("exact match!", site.Title.toLowerCase());
                        setTeamWithExactName(site);
                    }
                    //}
                });
            }
            if (validationAjaxResults.SimilarSites !== undefined && validationAjaxResults.SimilarSites[0] !== undefined && validationAjaxResults.SimilarSites[0].ResultRows !== undefined) {
                if (validationAjaxResults.ExactSameNameSite !== undefined &&
                    validationAjaxResults.ExactSameNameSite[0] !== undefined &&
                    validationAjaxResults.ExactSameNameSite[0].ResultRows !== undefined &&
                    validationAjaxResults.ExactSameNameSite[0].ResultRows !== null) {
                    setTeamsWithSimilarNames(validationAjaxResults.SimilarSites[0].ResultRows.filter(function (rr) { return validationAjaxResults.ExactSameNameSite[0].ResultRows.some(function (err) { return err.SiteId === rr.SiteId; }) === false; }));
                }
                else {
                    setTeamsWithSimilarNames(validationAjaxResults.SimilarSites[0].ResultRows);
                }
            }
        }
    }, [validationAjaxResults]);
    var getSafeUrl = function (value) {
        var _a, _b, _c, _d;
        var safeUrl = "";
        //console.log("getSafeUrl", value);
        if (value === undefined) {
            value = "";
        }
        if (value !== undefined) {
            if (value.length > 0 ||
                (isWorkspaceNameEditingEnabled() === false && namingPolicyRequiredFieldsDone === true) ||
                (isWorkspaceUrlEditingEnabled() === false && namingPolicyRequiredFieldsDone === true)) {
                //if(rwc.currentTenantConfiguration !== undefined && rwc.currentTenantConfiguration.GroupNamingConventionsEnabled && rwc.currentUser !== undefined){
                if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === true &&
                    rwc.currentRequest.Template.NamingPolicyGUID !== undefined &&
                    rwc.currentRequest.Template.NamingPolicyGUID !== null &&
                    ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.TemplateType) !== "VivaEngageCommunity" &&
                    rwc.currentNamingPolicy !== undefined &&
                    rwc.currentNamingPolicy.RenameWorkspaceUrl === true &&
                    rwc.currentUser !== undefined) {
                    var nameValue = isWorkspaceUrlEditingEnabled() === true ? value : "";
                    //safeUrl = GetSettingsNamingConventionsPreview(rwc.currentTenantConfiguration, value, rwc.currentUser);
                    safeUrl = GetNamingPolicyPreview(rwc.currentNamingPolicy, nameValue, rwc.currentUser, "url", rwc.getOrchestryMetadataValues()).replace(/[^a-zA-Z0-9_-]+/g, "");
                }
                else if (rwc.currentNamingPolicy &&
                    rwc.currentUser &&
                    ((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.NamingPolicyEnabled) === true &&
                    rwc.currentRequest.Template.NamingPolicyGUID !== undefined &&
                    rwc.currentRequest.Template.NamingPolicyGUID !== null &&
                    ((_d = rwc.currentRequest.Template) === null || _d === void 0 ? void 0 : _d.TemplateType) === "VivaEngageCommunity") {
                    var nameValue_1 = isWorkspaceUrlEditingEnabled() === true ? value : "";
                    safeUrl = GetNamingPolicyPreview(rwc.currentNamingPolicy, nameValue_1, rwc.currentUser, "name", rwc.getOrchestryMetadataValues()).replace(/[^a-zA-Z0-9_-]+/g, "");
                }
                else {
                    safeUrl = value.replace(/[^a-zA-Z0-9_-]+/g, "");
                }
            }
            else {
                safeUrl = "";
            }
        }
        //console.log("safeUrl",safeUrl)
        return limitSiteUrlTo64Characters(safeUrl);
    };
    var setSafeUrl = function (value) {
        rwc.updateCurrentRequest({ SiteURL: getSafeUrl(value) });
    };
    function preventIllegalVivaCharacters(e) {
        var key = e.key;
        if (!(key >= "0" && key <= "9") && !(key >= "A" && key <= "Z") && !(key >= "a" && key <= "z") && !(key === "-" || key === "_" || key === "." || key === " ")) {
            e.preventDefault();
        }
    }
    React.useEffect(function () {
        if (siteUrlHasBeenEdited) {
            rwc.setIsSiteUrlCustom(true);
            setTimeout(function () {
                var foundinput = document.getElementById("siteUrl");
                if (foundinput !== undefined && foundinput !== null) {
                    foundinput.focus();
                }
            }, 1000);
        }
    }, [siteUrlHasBeenEdited]);
    React.useEffect(function () {
        rwc.setStepProgress(props.step.key, isPageValid);
    }, [isPageValid]);
    var updateCustomMetadataValue = function (mdfv) {
        // let wasWorkspaceNameUpdate = false;
        // let wasWorkspaceUrlUpdate = false;
        var requiredMdFieldsInNamingPolicy = getNamingPolicyRequiredGuids();
        var currentValues = [mdfv];
        if (rwc.currentRequest.CustomMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(rwc.currentRequest.CustomMetadataValuesJson);
            var foundCurrent_1 = false;
            currentValues = valuesArray.map(function (fv) {
                if (fv.FieldGUID === mdfv.FieldGUID) {
                    foundCurrent_1 = true;
                    return mdfv;
                }
                else {
                    return fv;
                }
            });
            if (foundCurrent_1 === false) {
                currentValues.push(mdfv);
            }
            //console.log("does match for mapping", currentValues)
        }
        setLastCustomMetdataFieldEditedGuid(mdfv.FieldGUID);
        rwc.updateCurrentRequest({
            CustomMetadataValuesJson: JSON.stringify(currentValues)
        });
        // if (requiredMdFieldsInNamingPolicy.some(g => g === mdfv.FieldGUID)) {
        //    //setTimeout(()=>{
        //       runValidationsAfterRequiredNamingPolicyMetadataValueUpdate();
        //    //},500)
        // }
        //console.log("updateCustomMetadataValue", currentValues);
    };
    var getCurrentMetadataFieldValue = function (md) {
        var value = undefined;
        if (rwc.currentRequest.CustomMetadataValuesJson !== undefined) {
            //console.log("rwc.currentRequest.CustomMetadataValuesJson", rwc.currentRequest.CustomMetadataValuesJson)
            var valuesArray = JSON.parse(rwc.currentRequest.CustomMetadataValuesJson);
            valuesArray.forEach(function (mdfv, index) {
                if (mdfv.FieldGUID === md.GUID) {
                    value = mdfv.Values;
                }
                else if (mdfv.FieldTitle === md.Title) {
                    value = mdfv.Values;
                }
            });
        }
        if (value !== undefined) {
            //console.log(value);
        }
        return value;
    };
    var getRelativeUrl = function (url) {
        var returnUrl = "";
        if (orchestryContext.TenantSharePointUrl !== undefined && url === orchestryContext.TenantSharePointUrl) {
            returnUrl = url;
        }
        else if (orchestryContext.TenantSharePointUrl !== undefined && url !== orchestryContext.TenantSharePointUrl) {
            returnUrl = url.replace(orchestryContext.TenantSharePointUrl, "");
        }
        return returnUrl;
    };
    React.useEffect(function () {
        if (rwc.descriptionFocused === false && isWorkspaceNameEditingEnabled()) {
            var currentName = rwc.currentRequest.NewRequestName === undefined ? "" : rwc.currentRequest.NewRequestName;
            var currentUserName = rwc.currentUser === undefined ? "" : rwc.currentUser.DisplayName;
            var currentDate = moment().format("LLL");
            var description = defaultDescriptionTemplate
                .replace("[WorkspaceName]", currentName)
                .replace("[FullNameOfRequestor]", currentUserName)
                .replace("[TodaysDate]", currentDate);
            rwc.updateCurrentRequest({ Description: description });
        }
    }, [rwc.currentRequest.NewRequestName]);
    React.useEffect(function () {
        // console.log("teamsWithSimilarNames",teamsWithSimilarNames);
    }, [teamsWithSimilarNames]);
    var limitSiteUrlTo64Characters = function (url) {
        var _a;
        if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.TemplateType) === "SharePoint" && rwc.currentRequest.Template.SharePointTemplateType === "ModernCommunications") {
            return url;
        }
        if (url === undefined || url === null) {
            return url;
        }
        if (url.length > 64) {
            return url.substr(0, 63);
        }
        else {
            return url;
        }
    };
    var getNamingPolicyRequiredGuids = function () {
        var namingPolicyRequiredFieldGuids = [];
        rwc.listMetadata.forEach(function (mdField) {
            var _a;
            if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === true) {
                if (rwc.currentNamingPolicy !== undefined) {
                    var addGuid = function (isEnabled, jsonData) {
                        if (isEnabled !== undefined && isEnabled === true) {
                            if (jsonData !== undefined && jsonData !== null) {
                                if (jsonData.indexOf(mdField.GUID) > -1) {
                                    if (namingPolicyRequiredFieldGuids.some(function (g) { return g === mdField.GUID; }) === false) {
                                        namingPolicyRequiredFieldGuids.push(mdField.GUID);
                                    }
                                }
                            }
                        }
                    };
                    addGuid(rwc.currentNamingPolicy.TitlePrefixEnabled, rwc.currentNamingPolicy.TitlePrefixValue);
                    addGuid(rwc.currentNamingPolicy.TitleSuffixEnabled, rwc.currentNamingPolicy.TitleSuffixValue);
                    addGuid(rwc.currentNamingPolicy.UrlPrefixEnabled, rwc.currentNamingPolicy.UrlPrefixValue);
                    addGuid(rwc.currentNamingPolicy.UrlSuffixEnabled, rwc.currentNamingPolicy.UrlSuffixValue);
                }
            }
        });
        return namingPolicyRequiredFieldGuids;
    };
    var getOchestryMetadataFields = function (onlyNamingPolicyFields) {
        var _a, _b, _c;
        if (onlyNamingPolicyFields === void 0) { onlyNamingPolicyFields = null; }
        var returnMetadataFields = null;
        var namingPolicyRequiredFieldGuids = getNamingPolicyRequiredGuids();
        var availableFields = [];
        if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.MetadataCustomizedFieldsEnabled) === true && ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.MetadataConfigurationType) === MetadataConfigurationType.Subset) {
            availableFields = rwc.listMetadata.filter(function (md) { var _a, _b; return ((_b = (_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.MetadataFields) === null || _b === void 0 ? void 0 : _b.some(function (mf) { return mf === md.GUID; })) && (md.ShowInDetailsForm === true || namingPolicyRequiredFieldGuids.some(function (npg) { return npg === md.GUID; })); });
        }
        else if (((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.MetadataConfigurationType) === MetadataConfigurationType.Inherit) {
            availableFields = rwc.listMetadata.filter(function (md) { return md.ShowInDetailsForm === true || namingPolicyRequiredFieldGuids.some(function (npg) { return npg === md.GUID; }); });
        }
        else {
            availableFields = [];
        }
        if (onlyNamingPolicyFields === true) {
            returnMetadataFields = availableFields.filter(function (md) { return namingPolicyRequiredFieldGuids.some(function (npg) { return npg === md.GUID; }); });
        }
        else if (onlyNamingPolicyFields === false) {
            returnMetadataFields = availableFields.filter(function (md) { return namingPolicyRequiredFieldGuids.some(function (npg) { return npg === md.GUID; }) === false; });
        }
        else {
            returnMetadataFields = availableFields;
        }
        return returnMetadataFields;
    };
    var needsMetadataValuesForNamingPolicy = function () {
        var namingPolicyRequiredFieldGuids = getNamingPolicyRequiredGuids();
        var needsValues = false;
        var currentValuesFromRequest = [];
        if (rwc.currentRequest.CustomMetadataValuesJson !== undefined) {
            currentValuesFromRequest = JSON.parse(rwc.currentRequest.CustomMetadataValuesJson);
        }
        rwc.listMetadata
            .filter(function (md) { return namingPolicyRequiredFieldGuids.some(function (npg) { return npg === md.GUID; }); })
            .forEach(function (lmd) {
            var value = currentValuesFromRequest.find(function (cv) { return cv.FieldGUID === lmd.GUID; });
            if (value === undefined || (value !== undefined && (value.Values === null || value.Values === ""))) {
                needsValues = true;
            }
        });
        return needsValues;
    };
    var renderCustomFields = function (onlyNamingPolicyFields) {
        if (onlyNamingPolicyFields === void 0) { onlyNamingPolicyFields = null; }
        return (React.createElement("div", { className: "metadata-fields" }, getOchestryMetadataFields(onlyNamingPolicyFields).map(function (mdi, index) {
            return (React.createElement("div", { key: index },
                React.createElement(MetadataField, { key: index, metaDataItem: mdi, onChange: function (mdfv) {
                        updateCustomMetadataValue(mdfv);
                    }, managedMetaDataPlaceholder: intl.formatMessage({ id: "page.request_wizard.step.details.managed_metadata_placeholder", defaultMessage: "Type term to tag" }), defaultValue: getCurrentMetadataFieldValue(mdi) },
                    mdi.FieldType === "Link" && getCurrentMetadataFieldValue(mdi) !== undefined ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-url" + mdi.SPFieldInternalName, Validators: ["is-valid-url"], Value: getCurrentMetadataFieldValue(mdi), ShowFirst: true })) : null,
                    mdi.RequiredInDetailsForm !== undefined && mdi.RequiredInDetailsForm === true ? (React.createElement("div", null,
                        mdi.FieldType === "Date" || mdi.FieldType === "Text" || mdi.FieldType === "Link" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + mdi.SPFieldInternalName, Validators: ["string-not-empty"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                        mdi.FieldType === "Choice" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + mdi.SPFieldInternalName, Validators: ["array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                        mdi.FieldType === "ManagedMetadata" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + mdi.SPFieldInternalName, Validators: ["string-array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null)) : null)));
        })));
    };
    React.useEffect(function () {
        if (namingPolicyRequiredFieldsDone === true && getNamingPolicyRequiredGuids().length > 0) {
            if (getNamingPolicyRequiredGuids().some(function (npg) { return npg === lastCustomMetdataFieldEditedGuid; })) {
                setSafeUrl(rwc.currentRequest.NewRequestName);
                startNameValidation();
                if (isWorkspaceNameEditingEnabled() === false && rwc.currentNamingPolicy !== undefined && rwc.currentUser !== undefined) {
                    var newName = GetNamingPolicyPreview(rwc.currentNamingPolicy, "", rwc.currentUser, "name", rwc.getOrchestryMetadataValues(rwc.currentRequest.CustomMetadataValuesJson));
                    if (newName !== rwc.currentRequest.Name) {
                        rwc.updateCurrentRequest({ Name: newName });
                    }
                }
            }
        }
    }, [namingPolicyRequiredFieldsDone, rwc.currentRequest.CustomMetadataValuesJson]);
    //watch the current request Name attribute in order to pass through names made by metadata based naming policies or auto gen'd naming policies
    React.useEffect(function () {
        if (isWorkspaceNameEditingEnabled() === false && rwc.currentNamingPolicy !== null && rwc.currentNamingPolicy !== undefined && rwc.currentUser !== null && rwc.currentUser !== undefined) {
            var newName = GetNamingPolicyPreview(rwc.currentNamingPolicy, "", rwc.currentUser, "name", rwc.getOrchestryMetadataValues(rwc.currentRequest.CustomMetadataValuesJson));
            if (newName !== rwc.currentRequest.Name && newName !== null && newName !== undefined && newName !== "") {
                setSafeUrl(newName);
                rwc.updateCurrentRequest({ Name: newName });
            }
            var currentName = rwc.currentRequest.Name === undefined ? "" : rwc.currentRequest.Name;
            var currentUserName = rwc.currentUser === undefined ? "" : rwc.currentUser.DisplayName;
            var currentDate = moment().format("LLL");
            var description = defaultDescriptionTemplate
                .replace("[WorkspaceName]", currentName)
                .replace("[FullNameOfRequestor]", currentUserName)
                .replace("[TodaysDate]", currentDate);
            rwc.updateCurrentRequest({ Description: description });
        }
    }, [(_d = rwc.currentNamingPolicy) === null || _d === void 0 ? void 0 : _d.Title, rwc.currentRequest.Name]);
    var isWorkspaceNameEditingEnabled = function () {
        var _a, _b, _c, _d, _e;
        return (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === false ||
            (((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.NamingPolicyEnabled) === true &&
                rwc.currentNamingPolicy !== undefined &&
                (((_c = rwc.currentNamingPolicy) === null || _c === void 0 ? void 0 : _c.RenameWorkspaceTitle) === false || (((_d = rwc.currentNamingPolicy) === null || _d === void 0 ? void 0 : _d.RenameWorkspaceTitle) === true && ((_e = rwc.currentNamingPolicy) === null || _e === void 0 ? void 0 : _e.TitleInputDisabled) === false))));
    };
    var isWorkspaceUrlEditingEnabled = function () {
        var _a, _b, _c, _d, _e;
        return (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.NamingPolicyEnabled) === false ||
            (((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.NamingPolicyEnabled) === true &&
                rwc.currentNamingPolicy !== undefined &&
                (((_c = rwc.currentNamingPolicy) === null || _c === void 0 ? void 0 : _c.RenameWorkspaceUrl) === false || (((_d = rwc.currentNamingPolicy) === null || _d === void 0 ? void 0 : _d.RenameWorkspaceUrl) === true && ((_e = rwc.currentNamingPolicy) === null || _e === void 0 ? void 0 : _e.UrlInputDisabled) === false))));
    };
    var setSelectedGeoLocation = function (geo) {
        rwc.updateCurrentRequest({ RequestMultiGeoDataLocation: geo });
        if (geo !== currentTenantConfig.DefaultGeo) {
            rwc.updateCurrentRequest({ ShowInDirectory: false });
        }
        else {
            rwc.updateCurrentRequest({ ShowInDirectory: true });
        }
    };
    return (React.createElement("div", { className: "step-details" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setIsPageValid(isValid && namingPolicyRequiredFieldsDone);
            }, UniqueIdentifier: "RequestWizardStepDetails" }, rwc.currentRequest.RequestType !== undefined && rwc.currentRequest.RequestType !== null ? (React.createElement("div", { className: "form-items" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 8 },
                    getNamingPolicyRequiredGuids().length > 0 ? (namingPolicyRequiredFieldsDone === false ? (React.createElement("div", { className: "naming-policy-fields-top" },
                        rwc.currentNamingPolicy !== undefined &&
                            rwc.currentNamingPolicy !== null &&
                            rwc.currentNamingPolicy.Instructions !== undefined &&
                            rwc.currentNamingPolicy.Instructions !== null &&
                            rwc.currentNamingPolicy.Instructions !== "" ? (React.createElement("div", { className: "naming-policy-instructions" },
                            React.createElement(PortletAlert, { size: "small", type: "success", icon: "pen", outline: false, message: rwc.currentNamingPolicy.Instructions }))) : null,
                        renderCustomFields(true),
                        React.createElement("div", { className: "bottom-button-container" },
                            React.createElement(SubtitleButton, { color: "brandblue", title: intl.formatMessage({
                                    id: "page.request_wizard.step_details.next_button",
                                    defaultMessage: "Next"
                                }), faIcon: "arrow-down", disabled: needsMetadataValuesForNamingPolicy(), onClick: function () {
                                    setNamingPolicyRequiredFieldsDone(true);
                                } })))) : null) : null,
                    (currentTemplateConfig === null || currentTemplateConfig === void 0 ? void 0 : currentTemplateConfig.TemplateMultiGeoDataLocationSource) === "RequestorsChoice" && namingPolicyRequiredFieldsDone && !geoSelected ? (React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement("div", { className: "select-geo" },
                                React.createElement("div", { className: "geo-header" },
                                    React.createElement(PortletAlert, { size: "small", type: "success", icon: "pen", outline: false, message: React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.select_geo", defaultMessage: "A data location is required for this workspace. Please select from the options below:" }) })),
                                React.createElement("div", { className: "naming-policy-fields-top" },
                                    React.createElement("div", { className: "permission-radios" }, (_f = (_e = rwc.currentRequest.Template) === null || _e === void 0 ? void 0 : _e.TemplateMultiGeoDataLocations) === null || _f === void 0 ? void 0 : _f.split(",").map(function (geo) { return (React.createElement("div", { className: "radio-item" + (rwc.currentRequest.RequestMultiGeoDataLocation === geo ? " selected" : ""), onClick: function () { return setSelectedGeoLocation(geo); } },
                                        React.createElement("div", { className: "icon" },
                                            React.createElement("i", { className: "las la-globe" })),
                                        React.createElement("div", { className: "text" },
                                            React.createElement("h6", null, geo),
                                            React.createElement("p", null, SHAREPOINT_MULTIGEO_CODES[geo])),
                                        React.createElement("div", { className: "checkbox" },
                                            React.createElement("i", { className: "fa fa-check" })))); })),
                                    React.createElement("div", { className: "bottom-button-container" },
                                        React.createElement(SubtitleButton, { color: "brandblue", title: intl.formatMessage({
                                                id: "page.request_wizard.step_details.next_button",
                                                defaultMessage: "Next"
                                            }), faIcon: "arrow-down", disabled: rwc.currentRequest.RequestMultiGeoDataLocation === undefined || rwc.currentRequest.RequestMultiGeoDataLocation === "", onClick: function () {
                                                setgeoSelected(true);
                                            } }))))))) : null,
                    (getNamingPolicyRequiredGuids().length > 0 && namingPolicyRequiredFieldsDone === true && geoSelected) || (getNamingPolicyRequiredGuids().length === 0 && geoSelected) ? (React.createElement("div", { className: "main-area" },
                        rwc.currentNamingPolicy !== undefined &&
                            rwc.currentNamingPolicy !== null &&
                            rwc.currentNamingPolicy.Instructions !== undefined &&
                            rwc.currentNamingPolicy.Instructions !== null &&
                            rwc.currentNamingPolicy.Instructions !== "" ? (React.createElement("div", { className: "naming-policy-instructions" },
                            React.createElement(PortletAlert, { size: "small", type: "success", icon: "pen", outline: false, message: rwc.currentNamingPolicy.Instructions }))) : null,
                        React.createElement("div", { className: "workspace-name-with-validator", "data-pw": "workspace-name" },
                            isWorkspaceNameEditingEnabled() ? (React.createElement("div", { className: "workspace-name" + (nameFocused === true ? " name-focused" : "") },
                                React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "name-validator" },
                                    React.createElement("div", { className: "name", "data-pw": "name" },
                                        React.createElement(Form.Control, { placeholder: intl.formatMessage({
                                                id: "page.request_wizard.step_details.enter_name_of_workspace",
                                                defaultMessage: "Enter name of your Workspace"
                                            }), value: rwc.currentRequest.NewRequestName, autoComplete: "off", onFocus: function () { return setNameFocused(true); }, onBlur: function () { return setNameFocused(false); }, 
                                            //@ts-ignore
                                            onChange: function (event) {
                                                return rwc.updateCurrentRequest({
                                                    NewRequestName: event.currentTarget.value
                                                });
                                            }, onKeyDown: isVivaEngageCommunity ? function (e) { return preventIllegalVivaCharacters(e); } : undefined }))))) : null,
                            ((_g = rwc.currentRequest.Template) === null || _g === void 0 ? void 0 : _g.NamingPolicyEnabled) === true && rwc.currentNamingPolicy !== undefined && rwc.currentNamingPolicy.RenameWorkspaceTitle === true ? (React.createElement("div", { className: "workspace-name-policy-enabled" + (isWorkspaceNameEditingEnabled() === false ? " title-disabled" : "") },
                                React.createElement(InfoTooltip, { content: isWorkspaceNameEditingEnabled() === true ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.your_name_will_be_altered", defaultMessage: "Your workspace will have its name altered based on the rules setup by your organization" })) : (React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.autogenerated_workspace_name", defaultMessage: "As per your organizations policy the name of your workspace will be automatically generated" })), type: "info" },
                                    React.createElement("i", { className: "fa fa-info-circle" })),
                                rwc.metadataLoaded === false ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 })) : (React.createElement(NamingPolicyPreview, { value: rwc.currentRequest.NewRequestName, type: "name", user: rwc.currentUser, currentNamingPolicy: rwc.currentNamingPolicy, customMetadataValues: rwc.getOrchestryMetadataValues() })))) : null,
                            React.createElement(FormValidator, { UniqueIdentifier: "name-validator", Validators: ["string-is-more-than-1"], ErrorMessage: intl.formatMessage({
                                    id: "page.request_wizard.step_details.name_must_have_more_than_1_char",
                                    defaultMessage: "Name must have more than 1 characters"
                                }), Value: rwc.currentRequest.NewRequestName, Enabled: namingPolicyRequiredFieldsDone && isWorkspaceNameEditingEnabled() }),
                            React.createElement(FormValidator, { UniqueIdentifier: "workspace-name-length-less-than-x", Validators: ["string-is-less-than-x"], Parameter: (_h = rwc.currentNamingPolicy) === null || _h === void 0 ? void 0 : _h.CharacterLimit, ErrorMessage: intl.formatMessage({
                                    id: "workspace-name-length-less-than-x",
                                    defaultMessage: "Workspace name must be ".concat((_j = rwc.currentNamingPolicy) === null || _j === void 0 ? void 0 : _j.CharacterLimit, " or fewer characters")
                                }), Value: rwc.currentRequest.Name, Enabled: ((_k = rwc.currentNamingPolicy) === null || _k === void 0 ? void 0 : _k.CharacterLimitEnabled) || false })),
                        React.createElement("div", { className: "image-wrapper" },
                            React.createElement("div", { className: "team-image" }, ((_l = rwc.currentRequest.Template) === null || _l === void 0 ? void 0 : _l.DefaultWorkspaceLogo) ? (React.createElement("div", { className: "default-team-logo" },
                                React.createElement(InfoTooltip, { placement: "bottom", content: React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.default_workspace_logo", defaultMessage: "This template does not allow the workspace logo to be customized" }), type: "info" },
                                    React.createElement(FormImageUploadBox, { disabled: true, defaultBase64Image: rwc.currentRequest.Template.DefaultWorkspaceLogo, onDrop: function () { } })))) : (React.createElement(React.Fragment, null,
                                React.createElement(FormImageUploadBox, { defaultBase64Image: rwc.currentRequest.Image, onDrop: function (imageData) {
                                        rwc.updateCurrentRequest({
                                            Image: imageData
                                        });
                                    }, text: (rwc.currentRequest.RequestType === "Team"
                                        ? intl.formatMessage({
                                            id: "page.request_wizard.step_details.label_team",
                                            defaultMessage: "Team"
                                        })
                                        : intl.formatMessage({
                                            id: "page.request_wizard.step_details.label_site",
                                            defaultMessage: "Site"
                                        })) +
                                        " " +
                                        intl.formatMessage({
                                            id: "page.request_wizard.step_details.label_logo",
                                            defaultMessage: "Logo"
                                        }) }),
                                React.createElement("div", { className: "image-size" }, "124 x 124")))),
                            React.createElement("div", { className: "unique-id" },
                                React.createElement("div", { className: "group-name", "data-pw": "group-name" },
                                    React.createElement(Form.Group, { className: "form-group" },
                                        React.createElement(Form.Label, null, ((_m = rwc.currentRequest.Template) === null || _m === void 0 ? void 0 : _m.TemplateType) === "Team" ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.group_name", defaultMessage: "Group name" })) : (React.createElement(React.Fragment, null,
                                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.site_url", defaultMessage: "Site URL" }),
                                            ((_o = rwc.currentRequest.Template) === null || _o === void 0 ? void 0 : _o.TemplateType) === "VivaEngageCommunity" && isWorkspaceUrlEditingEnabled() && (React.createElement(React.Fragment, null,
                                                " ",
                                                React.createElement(InfoTooltip, { content: React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.url_rename_cannot_be_applied_viva", defaultMessage: "Viva Engage Communities do not support URL renaming" }), type: "info" },
                                                    React.createElement("i", { className: "fa fa-info-circle" }))))))),
                                        ((_p = rwc.currentRequest.Template) === null || _p === void 0 ? void 0 : _p.NamingPolicyEnabled) !== undefined &&
                                            ((_q = rwc.currentRequest.Template) === null || _q === void 0 ? void 0 : _q.NamingPolicyEnabled) === true &&
                                            rwc.currentNamingPolicy !== undefined &&
                                            rwc.currentNamingPolicy.RenameWorkspaceUrl === true ? (React.createElement(React.Fragment, null,
                                            React.createElement("div", { className: "custom-tooltip-wrapper" },
                                                React.createElement("div", { className: "custom-tooltip" },
                                                    React.createElement("div", { className: "body" },
                                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.the_url_will_be_automatically_generated", defaultMessage: "The {sitetype} will be automatically generated.", values: {
                                                                sitetype: ((_r = rwc.currentRequest.Template) === null || _r === void 0 ? void 0 : _r.TemplateType) === "SharePoint"
                                                                    ? intl.formatMessage({
                                                                        id: "page.request_wizard.step_details.site_url",
                                                                        defaultMessage: "site url"
                                                                    }) + " "
                                                                    : intl.formatMessage({
                                                                        id: "page.request_wizard.step_details.group_name",
                                                                        defaultMessage: "group name"
                                                                    }) + " "
                                                            } }))),
                                                React.createElement("div", { className: "form-control disabled" }, rwc.currentRequest.SiteURL !== ""
                                                    ? rwc.currentRequest.SiteURL
                                                    : // <SettingsNamingConventionsPreview currentTenantConfiguration={rwc.currentTenantConfiguration} groupName={groupName} user={rwc.currentUser} />
                                                        null),
                                                React.createElement(FormValidator, { UniqueIdentifier: "site-url-not-blank-2", Validators: ["string-is-more-than-1"], ShowFirst: true, ErrorMessage: (((_s = rwc.currentRequest.Template) === null || _s === void 0 ? void 0 : _s.TemplateType) === "SharePoint"
                                                        ? intl.formatMessage({
                                                            id: "page.request_wizard.step_details.site_url",
                                                            defaultMessage: "site url"
                                                        }) + " "
                                                        : intl.formatMessage({
                                                            id: "page.request_wizard.step_details.group_name",
                                                            defaultMessage: "group name"
                                                        }) + " ") +
                                                        intl.formatMessage({
                                                            id: "page.request_wizard.step_details.site_url_not_blank",
                                                            defaultMessage: "must not be blank. \r\n If this field cannot be edited, contact your administrator for assistance."
                                                        }), Value: rwc.currentRequest.SiteURL, Enabled: rwc != null &&
                                                        rwc.currentRequest != null &&
                                                        rwc.currentRequest.NewRequestName != null &&
                                                        rwc.currentRequest.NewRequestName.length > 0 &&
                                                        isWorkspaceNameEditingEnabled() })))) : null,
                                        ((_t = rwc.currentRequest.Template) === null || _t === void 0 ? void 0 : _t.NamingPolicyEnabled) === undefined ||
                                            (((_u = rwc.currentRequest.Template) === null || _u === void 0 ? void 0 : _u.NamingPolicyEnabled) !== undefined && ((_v = rwc.currentRequest.Template) === null || _v === void 0 ? void 0 : _v.NamingPolicyEnabled) === false) ||
                                            rwc.currentNamingPolicy === undefined ||
                                            (rwc.currentNamingPolicy !== undefined && rwc.currentNamingPolicy.RenameWorkspaceUrl === false) ? (React.createElement(React.Fragment, null,
                                            React.createElement("div", { className: "site-url" + (rwc.currentRequest.SiteURL !== undefined && rwc.currentRequest.SiteURL.length > 1 ? " show-edit" : "") }, siteUrlHasBeenEdited === false ? (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: "form-control" }, rwc.currentRequest.SiteURL),
                                                React.createElement(FormValidator, { UniqueIdentifier: "site-url-not-blank", Validators: ["string-is-more-than-1"], ShowFirst: !isWorkspaceNameEditingEnabled(), ErrorMessage: (((_w = rwc.currentRequest.Template) === null || _w === void 0 ? void 0 : _w.TemplateType) === "SharePoint"
                                                        ? intl.formatMessage({
                                                            id: "page.request_wizard.step_details.site_url",
                                                            defaultMessage: "site url"
                                                        }) + " "
                                                        : intl.formatMessage({
                                                            id: "page.request_wizard.step_details.group_name",
                                                            defaultMessage: "group name"
                                                        }) + " ") +
                                                        intl.formatMessage({
                                                            id: "page.request_wizard.step_details.site_url_not_blank",
                                                            defaultMessage: "must not be blank. If this field cannot be edited, contact your administrator for assistance."
                                                        }), Value: rwc.currentRequest.SiteURL }),
                                                rwc.currentRequest.RequestType !== "VivaEngageCommunity" ? (React.createElement("div", { className: "action-edit", "data-pw": "action-edit", onClick: function () { return setSiteUrlHasBeenEdited(true); } },
                                                    React.createElement("i", { className: "fa fa-pen" }))) : null)) : (React.createElement(React.Fragment, null,
                                                React.createElement(Form.Control
                                                // disabled={true}
                                                , { 
                                                    // disabled={true}
                                                    value: rwc.currentRequest.SiteURL, maxLength: ((_x = rwc.currentRequest.Template) === null || _x === void 0 ? void 0 : _x.TemplateType) === "SharePoint" &&
                                                        ((_y = rwc.currentRequest.Template) === null || _y === void 0 ? void 0 : _y.SharePointTemplateType) === "ModernCommunications"
                                                        ? undefined
                                                        : 63, id: "siteUrl", autoComplete: "off", 
                                                    //@ts-ignore
                                                    onChange: function (event) { return setSafeUrl(event.currentTarget.value); } }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "site-url-characters", Validators: ["string-is-more-than-1"], ShowFirst: false, ErrorMessage: (((_z = rwc.currentRequest.Template) === null || _z === void 0 ? void 0 : _z.TemplateType) === "SharePoint"
                                                        ? intl.formatMessage({
                                                            id: "page.request_wizard.step_details.site_url",
                                                            defaultMessage: "site url"
                                                        }) + " "
                                                        : intl.formatMessage({
                                                            id: "page.request_wizard.step_details.group_name",
                                                            defaultMessage: "group name"
                                                        }) + " ") +
                                                        intl.formatMessage({
                                                            id: "page.request_wizard.step_details.must_be_more_than_1_char",
                                                            defaultMessage: "must be more than 1 character"
                                                        }), Value: rwc.currentRequest.SiteURL })))))) : null,
                                        ((_0 = rwc.currentRequest.Template) === null || _0 === void 0 ? void 0 : _0.TemplateType) === "SharePoint" && ((_1 = rwc.currentRequest.Template) === null || _1 === void 0 ? void 0 : _1.SharePointTemplateType) === "ModernCommunications" ? null : rwc
                                            .currentRequest.SiteURL !== undefined && rwc.currentRequest.SiteURL.length > 62 ? (React.createElement("div", { style: { marginTop: "10px" } },
                                            React.createElement(PortletAlert, { outline: false, icon: "alert", size: "xsmall", message: "The group name cannot contain more than 64 characters. Orchestry has automatically removed any characters over the 64 character limit.", type: "warning" }))) : null,
                                        React.createElement("div", { className: "site-url-validation" },
                                            React.createElement(Collapse, { in: (siteUrlHasBeenEdited === false &&
                                                    isNameLoading === true &&
                                                    ((rwc.currentRequest.NewRequestName !== undefined && rwc.currentRequest.NewRequestName.length > 1) ||
                                                        (isWorkspaceNameEditingEnabled() === false && namingPolicyRequiredFieldsDone === true))) ||
                                                    (siteUrlHasBeenEdited === true && siteUrlIsLoading && rwc.currentRequest.SiteURL !== undefined && rwc.currentRequest.SiteURL.length > 1), appear: true },
                                                React.createElement("div", null,
                                                    React.createElement("div", { className: "url-loading" },
                                                        React.createElement(AnimatedLoader, { size: "xxsmall" }),
                                                        React.createElement(FormValidator, { Hide: true, UniqueIdentifier: "loading-siteurl-is-not-happening", Validators: ["is-false"], Value: siteUrlIsLoading })))),
                                            React.createElement(Collapse, { in: (siteUrlHasBeenEdited === true && rwc.currentRequest.SiteURL !== undefined && rwc.currentRequest.SiteURL.length > 1 && siteUrlIsLoading === false) ||
                                                    (siteUrlHasBeenEdited === false &&
                                                        isNameLoading === false &&
                                                        ((rwc.currentRequest.NewRequestName !== undefined && rwc.currentRequest.NewRequestName.length > 1) ||
                                                            (isWorkspaceNameEditingEnabled() === false && namingPolicyRequiredFieldsDone === true))), appear: true },
                                                React.createElement("div", { className: "url-validation" },
                                                    React.createElement("div", { className: "url", "data-pw": "url" }, siteUrlFullUrl),
                                                    ((_2 = rwc.currentRequest) === null || _2 === void 0 ? void 0 : _2.SiteURL) !== "" ? (React.createElement("div", { className: "results" }, siteUrlIsValid ? (React.createElement("div", { className: "is-valid" },
                                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.available", defaultMessage: "Available" }))) : (React.createElement("div", { className: "is-not-valid" },
                                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.not_available", defaultMessage: "Not Available" }))))) : null)),
                                            React.createElement(FormValidator, { UniqueIdentifier: "url-length", Validators: ["string-is-less-than-x"], Parameter: (_3 = rwc.currentNamingPolicy) === null || _3 === void 0 ? void 0 : _3.CharacterLimit, ErrorMessage: intl.formatMessage({
                                                    id: "url-length-less-than-x",
                                                    defaultMessage: "Site URL must be ".concat((_4 = rwc.currentNamingPolicy) === null || _4 === void 0 ? void 0 : _4.CharacterLimit, " or fewer characters")
                                                }), Value: rwc.currentRequest.SiteURL, Enabled: ((_5 = rwc.currentNamingPolicy) === null || _5 === void 0 ? void 0 : _5.CharacterLimitEnabled) || false }),
                                            (siteUrlHasBeenEdited === true && rwc.currentRequest.SiteURL !== undefined && rwc.currentRequest.SiteURL.length > 1 && siteUrlIsLoading === false) ||
                                                (siteUrlHasBeenEdited === false && rwc.currentRequest.Name !== undefined && rwc.currentRequest.Name.length > 1 && isNameLoading === false) ? (React.createElement(FormValidator, { UniqueIdentifier: "site-url-available", Validators: ["is-true"], ShowFirst: true, Hide: true, ErrorMessage: (((_6 = rwc.currentRequest.Template) === null || _6 === void 0 ? void 0 : _6.TemplateType) === "SharePoint"
                                                    ? intl.formatMessage({
                                                        id: "page.request_wizard.step_details.site_url",
                                                        defaultMessage: "site url"
                                                    }) + " "
                                                    : intl.formatMessage({
                                                        id: "page.request_wizard.step_details.group_name",
                                                        defaultMessage: "group name"
                                                    }) + " ") +
                                                    intl.formatMessage({
                                                        id: "page.request_wizard.step_details.must_be_available",
                                                        defaultMessage: "must be available"
                                                    }), Value: siteUrlIsValid })) : null))))),
                        React.createElement(Form.Group, { controlId: "control-description", className: "form-group description-group" },
                            React.createElement(Form.Label, null,
                                ((_7 = rwc.currentRequest.Template) === null || _7 === void 0 ? void 0 : _7.TemplateType) === "Team" ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.label_team", defaultMessage: "Team" })) : (React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.label_site", defaultMessage: "Site" })),
                                " ",
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.description", defaultMessage: "Description (Optional)" })),
                            React.createElement("div", { className: "custom-tooltip-wrapper" },
                                React.createElement("div", { className: "custom-tooltip" },
                                    React.createElement("div", { className: "body" },
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.please_provide_a_description", defaultMessage: "Please provide a {teamorsite} description", values: {
                                                teamorsite: ((_8 = rwc.currentRequest.Template) === null || _8 === void 0 ? void 0 : _8.TemplateType) === "Team"
                                                    ? intl.formatMessage({
                                                        id: "page.request_wizard.step_details.label_team",
                                                        defaultMessage: "team"
                                                    })
                                                    : intl.formatMessage({
                                                        id: "page.request_wizard.step_details.label_site",
                                                        defaultMessage: "site"
                                                    })
                                            } }))),
                                React.createElement(Form.Control, { as: "textarea", rows: 3, className: "textarea-description", value: rwc.currentRequest.Description, onFocus: function () { return rwc.setDescriptionFocused(true); }, onChange: function (event) {
                                        rwc.updateCurrentRequest({
                                            Description: event.target.value
                                        });
                                    } }))),
                        renderCustomFields(getNamingPolicyRequiredGuids().length === 0 ? null : getNamingPolicyRequiredGuids().length > 0 && namingPolicyRequiredFieldsDone ? null : false))) : null),
                React.createElement(Col, { md: 4 }, isNameLoading ? (React.createElement("div", { className: "loading", "data-pw": "loading" },
                    React.createElement(AnimatedLoader, { text: intl.formatMessage({
                            id: "page.request_wizard.step_details.validating_workspace_name",
                            defaultMessage: "validating workspace name..."
                        }) }),
                    React.createElement(FormValidator, { UniqueIdentifier: "loading-name-is-not-happening", Validators: ["is-false"], Hide: true, Value: isNameLoading }))) : (React.createElement("div", { className: "validation-feedback" },
                    teamWithExactName !== undefined ? (React.createElement("div", { className: "warnings exact-error" },
                        React.createElement("div", { className: "top" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.there_already_is_an_existing_workspace", defaultMessage: "There already is an existing workspaces with the name of \u2018{workspacename}\u2019", values: {
                                    workspacename: rwc.currentRequest.Name
                                } })),
                        React.createElement("div", { className: "teams" }, teamWithExactName.GroupId === null ||
                            teamWithExactName.GroupId === "00000000-0000-0000-0000-000000000000" ||
                            (teamWithExactName.isTeam !== undefined && teamWithExactName.isTeam === false) ? (React.createElement("a", { href: teamWithExactName.SPSiteURL, target: "_blank", rel: "noopener noreferrer" },
                            React.createElement("div", { className: "item" },
                                React.createElement("div", { className: "text" },
                                    React.createElement("div", { className: "title" }, teamWithExactName.Title),
                                    React.createElement("div", { className: "type-text" },
                                        React.createElement("i", { className: "fa fa-external-link-alt" }),
                                        getRelativeUrl(teamWithExactName.SPSiteURL))),
                                React.createElement("div", { className: "type" },
                                    React.createElement("img", { src: iconSP, alt: "share point" }))))) : teamWithExactName.isTeam !== undefined && teamWithExactName.isTeam === true ? (React.createElement(React.Fragment, null,
                            React.createElement(TeamLink, { teamGuid: teamWithExactName.GroupId },
                                React.createElement("div", { className: "item" },
                                    React.createElement("div", { className: "text" },
                                        React.createElement("div", { className: "title" }, teamWithExactName.Title),
                                        React.createElement("div", { className: "type-text" },
                                            React.createElement("i", { className: "fa fa-external-link-alt" }),
                                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.ms_team", defaultMessage: "Microsoft Team" }))),
                                    React.createElement("div", { className: "type" },
                                        React.createElement("img", { src: iconTeams, alt: "teams" })))))) : null))) : null,
                    blockedWordsBeingUsed.length > 0 ? (React.createElement("div", { className: "warnings exact-error" },
                        React.createElement("div", { className: "top" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.the_name_of_your_workspace_contains_a_blocked_word", defaultMessage: "The name of your workspace contains a word that your oganization has blocked. Please remove the follow word(s):" })),
                        React.createElement("div", { className: "blocked-words" }, blockedWordsBeingUsed.map(function (sn, index) { return (React.createElement("div", { className: "word", key: index },
                            React.createElement("i", { className: "fa fa-chevron-right" }),
                            " ",
                            sn)); })))) : null,
                    teamsWithSimilarNames.length > 0 ? (React.createElement("div", { className: "warnings" },
                        React.createElement("div", { className: "top" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.there_are_other_workspaces_similar_names", defaultMessage: "There are other workspaces with similar names as \u2018{workspacename}\u2019", values: {
                                    workspacename: rwc.currentRequest.Name
                                } })),
                        React.createElement("div", { className: "teams" },
                            React.createElement(DynamicScrollbar, { maxHeight: teamWithExactName === undefined ? 500 : 300, shouldShow: (teamWithExactName !== undefined && teamsWithSimilarNames.length > 3) || (teamWithExactName === undefined && teamsWithSimilarNames.length > 6)
                                    ? true
                                    : false }, teamsWithSimilarNames.map(function (site, sIndex) {
                                return teamWithExactName !== undefined && teamWithExactName.SiteId === site.SiteId ? null : site.GroupId === null ||
                                    site.GroupId === "00000000-0000-0000-0000-000000000000" ||
                                    (site.isTeam !== undefined && site.isTeam === false) ? (React.createElement("a", { href: site.SPSiteURL, target: "_blank", rel: "noopener noreferrer", key: sIndex },
                                    React.createElement("div", { className: "item" },
                                        React.createElement("div", { className: "text" },
                                            React.createElement("div", { className: "title" }, site.Title),
                                            React.createElement("div", { className: "type-text" },
                                                React.createElement("i", { className: "fa fa-external-link-alt" }),
                                                rwc.currentRequest.RequestMultiGeoDataLocation !== undefined ? site.SPSiteURL : getRelativeUrl(site.SPSiteURL))),
                                        React.createElement("div", { className: "type" },
                                            React.createElement("img", { src: iconSP, alt: "share point" }))))) : site.isTeam !== undefined && site.isTeam === true ? (React.createElement(React.Fragment, null,
                                    React.createElement(TeamLink, { teamGuid: site.GroupId },
                                        React.createElement("div", { className: "item" },
                                            React.createElement("div", { className: "text" },
                                                React.createElement("div", { className: "title" }, site.Title),
                                                React.createElement("div", { className: "type-text" },
                                                    React.createElement("i", { className: "fa fa-external-link-alt" }),
                                                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_details.ms_team", defaultMessage: "Microsoft Team" }))),
                                            React.createElement("div", { className: "type" },
                                                React.createElement("img", { src: iconTeams, alt: "teams" })))))) : null;
                            }))))) : null,
                    React.createElement(FormValidator, { UniqueIdentifier: "exact-site-match", Validators: ["is-true"], Hide: true, Value: teamWithExactName === undefined }),
                    React.createElement(FormValidator, { UniqueIdentifier: "blocked-words-match", Validators: ["is-true"], Hide: true, Value: blockedWordsBeingUsed.length === 0 }))))))) : null)));
});
export default StepDetails;
