var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
import ManagedMetadataPicker from "app/components/common/mananged-metadata-picker";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import UserItem from "app/components/common/user-item";
import UserSearchDropdown from "app/components/common/user-search-dropdown";
import ChromeLanguage from "app/components/teams-tabs/team-chrome/chrome-language";
import DirectoryService from "app/services/directory";
import TemplatesService from "app/services/templates";
import * as DirectoryStore from "app/store/directory/search";
import highlightWords from "app/utils/highlightWords";
import useWindowDimensions from "app/utils/windowDimentions";
import clsx from "clsx";
import debounce from "lodash/debounce";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./directory-filters.scss";
var DirectoryFilters = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var directoryRefiners = useSelector(function (state) { return state.directorySearchState.Refiners; });
    var directoryIsLoading = useSelector(function (state) { return state.directorySearchState.IsLoading; });
    var currentView = useSelector(function (state) { return state.directorySearchState.Context.View; });
    var _a = React.useState(false), filterDropdownOpen = _a[0], setFilterDropdownOpen = _a[1];
    var filterDropdown = React.useRef();
    var _b = React.useState(false), sortDropdownOpen = _b[0], setSortDropdownOpen = _b[1];
    var sortDropdown = React.useRef();
    var _c = React.useState(false), ownerDropdownOpen = _c[0], setOwnerDropdownOpen = _c[1];
    var _d = React.useState(), currentOwner = _d[0], setCurrentOwner = _d[1];
    var _e = React.useState("recently-created"), currentSort = _e[0], setCurrentSort = _e[1];
    var _f = React.useState(""), currentSearchTerm = _f[0], setCurrentSearchTerms = _f[1];
    var _g = React.useState(true), sortDirectionDesc = _g[0], setSortDirectionDesc = _g[1];
    var _h = React.useState(false), refinersLoaded = _h[0], setRefinersLoaded = _h[1];
    var _j = React.useState(false), showHiddenItems = _j[0], setShowHiddenItems = _j[1];
    var width = useWindowDimensions().width;
    var _k = React.useState([]), currentRefiners = _k[0], setCurrentRefiners = _k[1];
    var _l = React.useState(false), isSearchSuggestionsLoading = _l[0], setIsSearchSuggestionsLoading = _l[1];
    var searchInputEl = React.useRef();
    var _m = React.useState([]), searchSuggestionResults = _m[0], setSearchSuggestionResults = _m[1];
    var _o = React.useState(-1), searchSuggestCursor = _o[0], setSearchSuggestCursor = _o[1];
    var _p = React.useState(false), searchOpen = _p[0], setSearchOpen = _p[1];
    var _q = React.useState(false), searchSuggestionOpen = _q[0], setSearchSuggestionOpen = _q[1];
    var searchContainerElement = React.useRef();
    var _r = React.useState(), filterTemplates = _r[0], setFilterTemplates = _r[1];
    var _s = React.useState(), filterTemplatesLoading = _s[0], setFilterTemplatesLoading = _s[1];
    var _t = React.useState(), filterSensitivityLabels = _t[0], setFilterSensitivityLabels = _t[1];
    var _u = React.useState(), filterSensitivityLabelLoading = _u[0], setFilterSensitivityLabelLoading = _u[1];
    var _v = React.useState(), filterClassifications = _v[0], setFilterClassifications = _v[1];
    var _w = React.useState(), filterClassificationLoading = _w[0], setFilterClassificationLoading = _w[1];
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var getSearchSuggestionResults = function (searchValue) {
        setSearchSuggestCursor(-1);
        setCurrentSearchTerms(searchValue);
        setSearchSuggestionOpen(false);
        setTimeout(function () {
            if (searchValue === searchInputEl.current.value) {
                if (searchValue.length < 2) {
                    setSearchSuggestionOpen(false);
                    setIsSearchSuggestionsLoading(false);
                    setSearchSuggestionResults([]);
                    // This will cancel the last request.
                    DirectoryService.searchDirectory(searchValue).then(function (resultData) { });
                }
                else {
                    setSearchSuggestionOpen(true);
                    setIsSearchSuggestionsLoading(true);
                    DirectoryService.searchDirectory(searchValue).then(function (resultData) {
                        //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                        try {
                            if (searchValue === searchInputEl.current.value) {
                                setIsSearchSuggestionsLoading(false);
                                setSearchSuggestionResults(resultData);
                            }
                        }
                        catch (_a) { }
                    });
                }
            }
        }, 200);
    };
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", handleFilterClick);
        document.addEventListener("mousedown", handleSortClick);
        document.addEventListener("mousedown", searchSuggestionLookForCloseClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", handleFilterClick);
            document.removeEventListener("mousedown", handleSortClick);
            document.removeEventListener("mousedown", searchSuggestionLookForCloseClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        console.log("Refiner update", directoryRefiners);
        if (directory.Refiners.length !== 0) {
            if (currentRefiners.length === 0) {
                //console.log("Refiners updating from redux");
                setCurrentRefiners(__spreadArray([], directoryRefiners, true));
            }
            if (directory.Refiners.some(function (r) { return r.Type === "OrchTemplate"; }) && filterTemplates === undefined && filterTemplatesLoading === undefined) {
                setFilterTemplatesLoading(true);
                TemplatesService.GetDirectoryFilterValues().then(function (templates) {
                    setFilterTemplates(templates);
                    setFilterTemplatesLoading(false);
                });
            }
            if (directory.Refiners.some(function (r) { return r.Type === "Sensitivity"; }) && filterSensitivityLabels === undefined && filterSensitivityLabelLoading === undefined) {
                var sensitivityRefiner = directory.Refiners.find(function (r) { return r.Type === "Sensitivity"; });
                if (sensitivityRefiner !== undefined) {
                    if (sensitivityRefiner.ExtendedProperties.LabelsJson !== undefined) {
                        var labelConfigs = JSON.parse(sensitivityRefiner.ExtendedProperties.LabelsJson);
                        setFilterSensitivityLabels(labelConfigs.filter(function (l) { return l.Enabled === true; }));
                    }
                }
                setFilterSensitivityLabelLoading(false);
                // SensitivityLabelsService.getOrchestryAllowedLabels().then((labels) => {
                //    setFilterSensitivityLabels(labels as SensitivityLabel[]);
                //    setFilterSensitivityLabelLoading(false);
                // })
            }
            if (directory.Refiners.some(function (r) { return r.Type === "Classification"; }) && filterClassifications === undefined && filterClassificationLoading === undefined) {
                //setFilterClassificationLoading(true);
                var classificationRefiner = directory.Refiners.find(function (r) { return r.Type === "Classification"; });
                if (classificationRefiner !== undefined) {
                    if (classificationRefiner.ExtendedProperties.LabelsJson !== undefined) {
                        var labelConfigs = JSON.parse(classificationRefiner.ExtendedProperties.LabelsJson);
                        setFilterClassifications(labelConfigs.filter(function (l) { return l.IsEnabled === true; }));
                    }
                }
                //GroupSettingsService.getOrchestryClassifications().then((classifications) => {
                setFilterClassificationLoading(false);
                //})
            }
        }
    }, [directoryRefiners]);
    var handleFilterClick = function (e) {
        var hideFilterDropdown = false;
        if (filterDropdown.current !== undefined) {
            if (filterDropdown.current !== null) {
                if (filterDropdown.current.contains !== undefined) {
                    if (filterDropdown.current.contains !== null) {
                        if (filterDropdown.current.contains(e.target)) {
                        }
                        else {
                            try {
                                // @ts-ignore
                                //setFilterDropdownOpen(false);
                                hideFilterDropdown = true;
                            }
                            catch (_a) { }
                        }
                    }
                }
            }
        }
        var peoplemodal = document.querySelector("#popover-basic.people-picker");
        if (peoplemodal !== null) {
            if (peoplemodal.contains(e.target)) {
                hideFilterDropdown = false;
            }
        }
        var mmdmodal = document.querySelector(".tax-tree-modal");
        if (mmdmodal !== null) {
            if (mmdmodal.contains(e.target)) {
                hideFilterDropdown = false;
            }
        }
        //console.log("filter click handler",hideFilterDropdown);
        if (hideFilterDropdown === true) {
            setFilterDropdownOpen(false);
        }
    };
    var handleSortClick = function (e) {
        if (sortDropdown.current !== undefined) {
            if (sortDropdown.current !== null) {
                if (sortDropdown.current.contains !== undefined) {
                    if (sortDropdown.current.contains !== null) {
                        if (sortDropdown.current.contains(e.target)) {
                        }
                        else {
                            try {
                                // @ts-ignore
                                setSortDropdownOpen(false);
                            }
                            catch (_a) { }
                        }
                    }
                }
            }
        }
    };
    var sortDirectionClick = function (e) {
        dispatch(DirectoryStore.UpdateContext({ SortDirection: directory.Context.SortDirection === "asc" ? "desc" : "asc" }));
        //setSortDirectionDesc(!descending);
        //console.log("Sort Direction",descending);
        e.stopPropagation();
    };
    var sort = function (sortColumn) {
        var sortDirection = "";
        if (sortColumn !== "Relevance") {
            if (sortColumn !== directory.Context.Sort) {
                sortDirection = "asc";
            }
            else if (sortColumn === directory.Context.Sort) {
                if (directory.Context.SortDirection === "desc") {
                    sortDirection = "asc";
                }
                else {
                    sortDirection = "desc";
                }
            }
        }
        dispatch(DirectoryStore.UpdateContext({
            Sort: sortColumn,
            //@ts-ignore
            SortDirection: sortDirection
        }));
        setSortDropdownOpen(false);
    };
    var handleSearchSuggestKeyDown = function (e) {
        if (e.keyCode === 38 && searchSuggestCursor === 0) {
            setSearchSuggestCursor(-1);
        }
        else if (e.keyCode === 38 && searchSuggestCursor > 0) {
            setSearchSuggestCursor(searchSuggestCursor - 1);
        }
        else if (e.keyCode === 40 && searchSuggestCursor < searchSuggestionResults.length - 1) {
            setSearchSuggestCursor(searchSuggestCursor + 1);
        }
        else if (e.keyCode === 13) {
            if (searchSuggestCursor > -1) {
                var link = document.getElementById("suggest-link-" + (searchSuggestCursor + 1));
                if (link !== null) {
                    link.click();
                    // setSearchSuggestionOpen(false);
                    // setIsSearchSuggestionsLoading(false);
                    // setSearchSuggestionResults([]);
                }
            }
            else {
                setSearchSuggestionOpen(false);
                dispatch(DirectoryStore.UpdateContext({
                    SearchTerm: searchInputEl.current.value,
                    Sort: "Relevance"
                }));
            }
            //clickSuggestion(searchSuggestionResults[searchSuggestCursor]);
        }
    };
    var clickSuggestion = function (di) {
        setSearchSuggestCursor(-1);
    };
    var searchSuggestionShouldUseScrollbar = function () {
        if (searchSuggestionResults.length > 6) {
            return true;
        }
        return false;
    };
    var searchSuggestionLookForCloseClick = function (e) {
        var setHidden = false;
        if (searchContainerElement.current !== undefined) {
            if (searchContainerElement.current !== null) {
                if (searchContainerElement.current.contains !== undefined) {
                    if (searchContainerElement.current.contains !== null) {
                        if (searchContainerElement.current.contains(e.target)) {
                        }
                        else {
                            try {
                                setHidden = true;
                            }
                            catch (_a) { }
                        }
                    }
                }
            }
        }
        if (setHidden === true) {
            // @ts-ignore
            setSearchOpen(false);
            setSearchSuggestionOpen(false);
        }
    };
    var clearSearch = function (searchValue) {
        var _a;
        setSearchSuggestionOpen(false);
        setIsSearchSuggestionsLoading(false);
        setSearchSuggestionResults([]);
        searchInputEl.current.value = "";
        if (((_a = directory.Context.SearchTerm) === null || _a === void 0 ? void 0 : _a.length) !== 0 || searchValue === "") {
            dispatch(DirectoryStore.UpdateContext({
                SearchTerm: "",
                Sort: "Created",
                SortDirection: "desc"
            }));
        }
    };
    var detectEmptySearch = function (e) {
        if (e === "" || e === null) {
            clearSearch(e);
        }
    };
    var debouncedSearchChange = React.useMemo(function () { return debounce(detectEmptySearch, 200); }, []);
    var clickSearchIcon = function () {
        setSearchSuggestionOpen(false);
        //setIsSearchSuggestionsLoading(false);
        //setSearchSuggestionResults([]);
        //searchInputEl.current.value = "";
        //if(directory.Context.SearchTerm?.length !== 0){
        dispatch(DirectoryStore.UpdateContext({
            SearchTerm: searchInputEl.current.value
        }));
        //}
    };
    var updateRefiner = function (refiner, value) {
        //let foundRefiner = currentRefiners.find(r => r.FieldName === fieldName);
        // let refiner = directory.Refiners.find(r => r.FieldName === fieldName);
        //if(foundRefiner !== undefined && foundRefiner !== null){
        var tempRefiner = __assign({}, refiner);
        if (tempRefiner.Type === "TemplateText" ||
            tempRefiner.Type === "User" ||
            tempRefiner.Type === "Text" ||
            tempRefiner.Type === "ShowHidden" ||
            tempRefiner.Type === "OrchTemplate" ||
            tempRefiner.Type === "Sensitivity" ||
            tempRefiner.Type === "Classification" ||
            tempRefiner.Type === "Choice") {
            if (tempRefiner.CurrentValue === value) {
                tempRefiner.CurrentValue = null;
            }
            else {
                tempRefiner.CurrentValue = value;
            }
        }
        if (tempRefiner.Type === "PrivacyChoice") {
            var tempNewValue = null;
            if (tempRefiner.CurrentValue !== undefined && tempRefiner.CurrentValue !== null && tempRefiner.CurrentValue.length > 0) {
                var splitValues = [];
                splitValues = tempRefiner.CurrentValue.split("|");
                if (splitValues.includes(value)) {
                    splitValues = splitValues.filter(function (sv) { return sv !== value; });
                }
                else {
                    splitValues.push(value);
                }
                tempRefiner.CurrentValue = splitValues.join("|");
            }
            else {
                tempRefiner.CurrentValue = value;
            }
        }
        if (tempRefiner.Type === "ManagedMetadata") {
            // let tempNewValue:string|null = null;
            // if(value !== null && value.length > 0 && value.indexOf("[") > -1) {
            //    const selectedTermArray = JSON.parse(value) as Term[];
            //    if(Array.isArray(selectedTermArray)){
            //       tempNewValue = selectedTermArray.map(term => term.Id).join(",");
            //    }
            // }
            tempRefiner.CurrentValue = value;
        }
        if (tempRefiner.Type === "Date") {
            var tempNewValue = null;
            if (tempRefiner.CurrentValue !== undefined && tempRefiner.CurrentValue !== null && tempRefiner.CurrentValue.length > 0) {
                var splitValues = [];
                splitValues = tempRefiner.CurrentValue.split("|");
                if (value.from !== undefined) {
                    splitValues[0] = value.from;
                }
                if (value.to !== undefined) {
                    splitValues[1] = value.to;
                }
                tempRefiner.CurrentValue = splitValues.join("|");
            }
            else {
                if (value.from !== undefined) {
                    tempRefiner.CurrentValue = value.from + "|";
                }
                if (value.to !== undefined) {
                    tempRefiner.CurrentValue = "|" + value.to;
                }
            }
        }
        console.log("Update Refiner", tempRefiner, value);
        var newRefiners = currentRefiners.map(function (r) { return (r.FieldName === tempRefiner.FieldName ? tempRefiner : r); });
        if (tempRefiner.Type === "TemplateText" && tempRefiner.CurrentValue === "sharepoint-comm") {
            newRefiners = newRefiners.map(function (r) { return (r.FieldName !== "Privacy" ? r : __assign(__assign({}, r), { CurrentValue: "" })); });
        }
        console.log("Updated Refiners", newRefiners);
        setCurrentRefiners(newRefiners);
        //dispatch(DirectoryStore.UpdateRefiners(directory.Refiners.map(r => r.FieldName === tempRefiner.FieldName ? tempRefiner : r)))
        //}
    };
    var clearRefiners = function (e) {
        if (e !== undefined) {
            e.stopPropagation();
        }
        setCurrentRefiners(currentRefiners.map(function (r) {
            r.CurrentValue = null;
            return r;
        }));
        applyRefiners();
    };
    React.useEffect(function () {
        console.log("currentRefiners", currentRefiners);
        if (currentRefiners.length > 0) {
            if (refinersLoaded === false) {
                setRefinersLoaded(true);
            }
            else {
                applyRefiners();
            }
        }
    }, [currentRefiners]);
    var applyRefiners = function () {
        var tempRefiners = __spreadArray([], currentRefiners, true);
        dispatch(DirectoryStore.UpdateRefiners(currentRefiners.map(function (r) {
            var newR = __assign({}, r);
            if (newR.Type === "User" && newR.CurrentValue !== undefined && newR.CurrentValue !== null) {
                newR.CurrentValue = newR.CurrentValue.UserPrincipalName;
            }
            return newR;
        })));
        //setFilterDropdownOpen(false);
    };
    var countAppliedRefiners = function () {
        var count = 0;
        directoryRefiners.forEach(function (r, index) {
            if (r.CurrentValue !== undefined && r.CurrentValue !== null && r.CurrentValue !== "" && r.CurrentValue !== "[]" && r.Type !== "ShowHidden") {
                count++;
            }
        });
        return count;
    };
    var toggleAdminShowVisibleItems = function (refiner) {
        if (showHiddenItems === false) {
            updateRefiner(refiner, "True");
            setShowHiddenItems(true);
        }
        else {
            updateRefiner(refiner, "False");
            setShowHiddenItems(false);
        }
    };
    var getExtendedPropertyValueStartTermObject = function (refiner) {
        var returnTerm = null;
        if (refiner.ExtendedProperties.TermStartJson !== undefined &&
            refiner.ExtendedProperties.TermStartJson !== null &&
            refiner.ExtendedProperties.TermStartJson.length > 0 &&
            refiner.ExtendedProperties.TermStartJson.indexOf("{") > -1) {
            returnTerm = JSON.parse(refiner.ExtendedProperties.TermStartJson)[0];
        }
        return returnTerm;
    };
    return (React.createElement("div", { className: clsx("directory-filters", { "directory-filters-sharepoint": isInSharepoint }), "data-pw": "directory-filters" },
        React.createElement("div", { className: "search" + (searchOpen === true ? " search-open" : ""), ref: searchContainerElement },
            React.createElement("div", { className: "search-box", "data-pw": "search-box" },
                React.createElement("div", { className: "search-box-border" },
                    React.createElement("input", { placeholder: intl.formatMessage({ id: "component.directory.filters.search_for_workspace", defaultMessage: "Search for a workspace" }), onFocus: function () { return setSearchOpen(true); }, onChange: function (event) {
                            getSearchSuggestionResults(event.target.value);
                            debouncedSearchChange(event.target.value);
                        }, onKeyDown: function (event) {
                            handleSearchSuggestKeyDown(event);
                        }, ref: searchInputEl }),
                    isSearchSuggestionsLoading === true ? (React.createElement("div", { className: "loader" },
                        React.createElement(AnimatedLoader, { size: "xxsmall", theme: "dark" }))) : null,
                    searchInputEl !== undefined && searchInputEl.current !== undefined && searchInputEl.current.value.length > 0 ? (React.createElement("i", { className: "fas fa-times-circle", onClick: function () { return clearSearch(); }, "data-pw": "clear-search" })) : null,
                    React.createElement("i", { className: "la la-search", onClick: function () { return clickSearchIcon(); }, "data-pw": "search-icon" }))),
            React.createElement("div", { className: "no-suggestions" +
                    (searchSuggestionResults.length === 0 && isSearchSuggestionsLoading === false && searchSuggestionOpen === true && searchOpen === true && currentSearchTerm.length > 0
                        ? " no-suggestions-open"
                        : ""), "data-pw": "no-suggestions" },
                React.createElement(FormattedMessage, { id: "component.directory.filters.could_not_find_matching", defaultMessage: "Could not find any teams or sites matching" }),
                " ",
                React.createElement("span", null,
                    "'",
                    currentSearchTerm,
                    "'")),
            React.createElement("div", { "data-pw": "search-suggestion-list", className: "search-suggestion-list" + (searchSuggestionResults.length > 0 && searchSuggestionOpen === true && searchOpen === true ? " suggestions-open" : " suggestions-closed") },
                React.createElement("div", null, searchSuggestionResults.map(function (di, index) { return (
                // <li className={(cursor === index ? ' hover-selected' : "") + (isPrincipalSelected(rs) ? " selected":"")+ (isPrincipalLocked(rs) === true ? "  locked":"")} key={index} onMouseEnter={()=> { setCursor(-1) }} onClick={()=> {clickUser(rs)}}>
                React.createElement("a", { href: props.getDirectoryItemUrl(di), target: "_blank", rel: "noopener noreferrer", id: "suggest-link-" + (index + 1), key: index },
                    React.createElement("div", { className: "sug-item" + (searchSuggestCursor === index ? " selected" : ""), "data-pw": "suggestion-item" },
                        React.createElement("div", { className: "pic", "data-pw": "pic" },
                            React.createElement(OfficeProfilePicture, { size: "xs", type: di.TemplateType !== undefined && di.TemplateType !== null ? (di.TemplateType.indexOf("SharePoint") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: di.Base64Logo })),
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "name", dangerouslySetInnerHTML: { __html: highlightWords(searchInputEl.current.value, di.Name) }, "data-pw": "name" })),
                        React.createElement("div", { className: "privacy-icon", "data-pw": "privacy-icon" },
                            di.Privacy === "Private" ? React.createElement("i", { className: "fa fa-lock", title: intl.formatMessage({ id: "component.directory.filters.private", defaultMessage: "Private" }) }) : null,
                            di.Privacy === "Public" ? React.createElement("i", { className: "fas fa-users", title: intl.formatMessage({ id: "component.directory.filters.public", defaultMessage: "Public" }) }) : null,
                            di.Privacy === "Org" ? (React.createElement("i", { className: "fas fa-globe-americas", title: intl.formatMessage({ id: "component.directory.filters.organization", defaultMessage: "Organization" }) })) : null),
                        React.createElement("div", { className: "type-icon", "data-pw": "type-icon" }, di.TemplateType === "SharePoint Communications" || di.TemplateType === "SharePoint Team" ? (React.createElement("img", { src: "/media/orchestry/icon-sharepoint.svg", alt: di.TemplateType })) : di.TemplateType === "Team" ? (React.createElement("img", { src: "/media/orchestry/icon-teams.svg", alt: "Microsoft Team" })) : (React.createElement("img", { src: iconViva })))))); })))),
        currentRefiners.map(function (refiner, rIndex) {
            return refiner.Type === "ShowHidden" && props.isTabMode === false ? (React.createElement("div", { className: "admin" },
                React.createElement("div", { className: "admin-view-hidden custom-button", onClick: function () { return toggleAdminShowVisibleItems(refiner); }, "data-pw": "view-hidden-button" },
                    React.createElement("span", null, showHiddenItems === true ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "la la-eye-slash" }),
                        " ",
                        React.createElement(FormattedMessage, { id: "component.directory.filters.hide_hidden", defaultMessage: "Hide Hidden Workspaces" }))) : (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "la la-eye" }),
                        " ",
                        React.createElement(FormattedMessage, { id: "component.directory.filters.view_hidden", defaultMessage: "View Hidden Workspaces" }))))))) : null;
        }),
        isInSharepoint && React.createElement(ChromeLanguage, null),
        React.createElement("div", { className: "filters", ref: filterDropdown },
            React.createElement("div", { className: "filter-button custom-button", "data-pw": "filter-button", onClick: function () {
                    setFilterDropdownOpen(!filterDropdownOpen);
                } },
                React.createElement("div", { className: "filter-icon" },
                    React.createElement("div", { className: "custom-filter-button" },
                        React.createElement("div", { className: "line1" }),
                        React.createElement("div", { className: "line2" }),
                        React.createElement("div", { className: "line3" }),
                        React.createElement("div", { className: "line4" }))),
                React.createElement("div", { className: "filter-text", "data-pw": "filter-text" },
                    React.createElement(FormattedMessage, { id: "component.directory.filters.filters", defaultMessage: "Filters" })),
                React.createElement("div", { "data-pw": "filter-count", className: "filter-count" + (countAppliedRefiners() !== 0 ? " filter-count-shown" : "") },
                    React.createElement("div", null,
                        React.createElement("span", null, countAppliedRefiners()),
                        React.createElement("div", { "data-pw": "clear-filter", className: "clear-filter", onClick: function (e) { return clearRefiners(e); } },
                            React.createElement("i", { className: "fas fa-times" }))))),
            React.createElement(Collapse, { in: filterDropdownOpen },
                React.createElement("div", { className: "filter-dropdown", "data-pw": "filter-dropdown" },
                    React.createElement(DynamicScrollbar, { shouldShow: width < 769 ? true : false, maxHeight: 400 },
                        React.createElement("div", { className: "form-items" }, currentRefiners.map(function (refiner, rIndex) {
                            //@ts-ignore
                            return refiner.Type === "User" && false === true ? ( // Disabled until we figure out how
                            React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                React.createElement(UserSearchDropdown, { onUserSelected: function (user) {
                                        updateRefiner(refiner, user);
                                    }, includeGroups: false, theme: "dropdown", suggestedTitle: "", setIsOpen: function (isOpen) {
                                        setOwnerDropdownOpen(isOpen);
                                    } },
                                    React.createElement("div", { className: "user-dropdown" + (ownerDropdownOpen === true ? " is-open" : " is-closed") },
                                        refiner.CurrentValue === undefined || refiner.CurrentValue === null ? (React.createElement("div", { className: "please-select" }, "-- Select Owner --")) : (React.createElement(React.Fragment, null,
                                            React.createElement(UserItem, { user: refiner.CurrentValue, showBorder: false }),
                                            React.createElement("i", { className: "clear fas fa-times-circle", onClick: function (e) {
                                                    e.stopPropagation();
                                                    updateRefiner(refiner, null);
                                                } }))),
                                        React.createElement("i", { className: "arrow fas fa-chevron-down" }))))) : refiner.Type === "TemplateText" ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                React.createElement("div", null,
                                    React.createElement("div", { className: "type-group", "data-pw": "type-group" },
                                        React.createElement("div", { className: "type-item custom-button" + (refiner.CurrentValue !== null && refiner.CurrentValue === "team" ? " selected" : ""), onClick: function () { return updateRefiner(refiner, "team"); }, "data-pw": "type-team" },
                                            React.createElement("img", { src: iconTeams }),
                                            " ",
                                            React.createElement(FormattedMessage, { id: "component.directory.filters.ms_teams", defaultMessage: "Microsoft Team" })),
                                        React.createElement("div", { className: "type-item custom-button" + (refiner.CurrentValue !== null && refiner.CurrentValue === "sharepoint-team" ? " selected" : ""), onClick: function () { return updateRefiner(refiner, "sharepoint-team"); }, "data-pw": "type-team-site" },
                                            React.createElement("img", { src: iconSP }),
                                            " ",
                                            React.createElement(FormattedMessage, { id: "component.directory.filters.team_site", defaultMessage: "Team Site" })),
                                        React.createElement("div", { className: "type-item custom-button" + (refiner.CurrentValue !== null && refiner.CurrentValue === "sharepoint-comm" ? " selected" : ""), onClick: function () { return updateRefiner(refiner, "sharepoint-comm"); }, "data-pw": "type-comms-site" },
                                            React.createElement("img", { src: iconSP }),
                                            " ",
                                            React.createElement(FormattedMessage, { id: "component.directory.filters.comm_site", defaultMessage: "Comms Site" })),
                                        React.createElement("div", { className: "type-item custom-button" + (refiner.CurrentValue !== null && refiner.CurrentValue === "viva-engage" ? " selected" : ""), onClick: function () { return updateRefiner(refiner, "viva-engage"); }, "data-pw": "type-viva-engage" },
                                            React.createElement("img", { src: iconViva }),
                                            " ",
                                            React.createElement(FormattedMessage, { id: "component.directory.filters.viva_engage", defaultMessage: "Viva Engage" })))))) : refiner.Type === "PrivacyChoice" ? (React.createElement(Collapse, { in: currentRefiners.some(function (cr) { return cr.FieldName === "TemplateType" && cr.CurrentValue !== "sharepoint-comm"; }) },
                                React.createElement(Form.Group, { className: "form-group checkbox-green", key: rIndex },
                                    React.createElement(Form.Label, null, refiner.Title),
                                    React.createElement(Form.Check, { type: "checkbox", label: intl.formatMessage({ id: "component.directory.filters.private", defaultMessage: "Private" }), name: "privacy-private", id: "privacy-private", onChange: function () { return updateRefiner(refiner, "private"); }, checked: refiner.CurrentValue !== null && refiner.CurrentValue.indexOf("private") > -1 ? true : false }),
                                    React.createElement(Form.Check, { type: "checkbox", label: intl.formatMessage({ id: "component.directory.filters.public", defaultMessage: "Public" }), id: "privacy-public", name: "privacy-public", onChange: function () { return updateRefiner(refiner, "public"); }, checked: refiner.CurrentValue !== null && refiner.CurrentValue.indexOf("public") > -1 ? true : false })))) : refiner.Type === "Choice" ? (React.createElement(Form.Group, { className: "form-group checkbox-green", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                React.createElement(FormDropdownMultiSelect, { list: refiner.ChoiceValues.split(/\r?\n/).map(function (choiceValue, cvIndex) {
                                        return ({
                                            id: choiceValue,
                                            title: choiceValue,
                                            value: choiceValue
                                        });
                                    }), placeholder: "-- " +
                                        intl.formatMessage({ id: "component.directory.filters.select_a_filter_title", defaultMessage: "Select a {filtertitle}" }, { filtertitle: refiner.Title }) +
                                        " --", values: refiner.CurrentValue !== null && refiner.CurrentValue.length > 0
                                        ? refiner.CurrentValue.split("|")
                                            //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID === cv))
                                            .map(function (cv, index) {
                                            return ({
                                                id: cv,
                                                title: cv,
                                                value: cv
                                            });
                                        })
                                        : [], onChange: function (values) { return updateRefiner(refiner, values.map(function (v) { return v.value; }).join("|")); } }))) : refiner.Type === "OrchTemplate" ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                filterTemplatesLoading === undefined || filterTemplatesLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (filterTemplates !== undefined && (React.createElement(FormDropdownMultiSelect, { list: filterTemplates.map(function (ft, index) {
                                        return ({
                                            id: ft.GUID !== undefined ? ft.GUID : index.toString(),
                                            title: ft.Title !== undefined ? ft.Title : "",
                                            value: ft.GUID !== undefined ? ft.GUID : ""
                                        });
                                    }), placeholder: "-- " + intl.formatMessage({ id: "component.directory.filters.select_a_template", defaultMessage: "Select a Template" }) + " --", values: refiner.CurrentValue !== null && refiner.CurrentValue.length > 0
                                        ? refiner.CurrentValue.split("|")
                                            //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID === cv))
                                            .map(function (cv, index) {
                                            var _a;
                                            return ({
                                                id: cv,
                                                title: (_a = filterTemplates.find(function (ft) { return ft.GUID === cv; })) === null || _a === void 0 ? void 0 : _a.Title,
                                                value: cv
                                            });
                                        })
                                        : [], onChange: function (values) { return updateRefiner(refiner, values.map(function (v) { return v.value; }).join("|")); } }))))) : refiner.Type === "Sensitivity" ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                filterSensitivityLabelLoading === undefined || filterSensitivityLabelLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (filterSensitivityLabels !== undefined && (React.createElement(FormDropdownMultiSelect, { list: filterSensitivityLabels.map(function (sl, index) {
                                        return ({
                                            id: sl.Id,
                                            title: sl.Name,
                                            value: sl.Id,
                                            description: sl.Description !== null && sl.Description !== "" ? sl.Description : undefined,
                                            customIcon: sl.Icon !== null ? sl.Icon : "fa fa-tag"
                                        });
                                    }), placeholder: "-- " + intl.formatMessage({ id: "component.directory.filters.select_a_sensitivity", defaultMessage: "Select a Sensitivity" }) + " --", values: refiner.CurrentValue !== null && refiner.CurrentValue.length > 0
                                        ? refiner.CurrentValue.split("|")
                                            //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID === cv))
                                            .map(function (cv, index) {
                                            var _a;
                                            return ({
                                                id: cv,
                                                title: (_a = filterSensitivityLabels.find(function (sl) { return sl.Id === cv; })) === null || _a === void 0 ? void 0 : _a.Name,
                                                value: cv
                                            });
                                        })
                                        : [], onChange: function (values) { return updateRefiner(refiner, values.map(function (v) { return v.value; }).join("|")); } }))))) : refiner.Type === "Classification" ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                filterClassificationLoading === undefined || filterClassificationLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (filterClassifications !== undefined && (React.createElement(FormDropdownMultiSelect, { list: filterClassifications.map(function (fc, index) {
                                        return ({
                                            id: fc.Key,
                                            title: fc.Key,
                                            value: fc.Key,
                                            description: fc.Description !== null && fc.Description !== "" ? fc.Description : undefined,
                                            customIcon: fc.Icon !== null ? fc.Icon : "fa fa-tag"
                                        });
                                    }), placeholder: "-- " + intl.formatMessage({ id: "component.directory.filters.select_a_classification", defaultMessage: "Select a Classification" }) + " --", values: refiner.CurrentValue !== null && refiner.CurrentValue.length > 0
                                        ? refiner.CurrentValue.split("|")
                                            //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID === cv))
                                            .map(function (cv, index) {
                                            var _a;
                                            return ({
                                                id: cv,
                                                title: (_a = filterClassifications.find(function (fc) { return fc.Key === cv; })) === null || _a === void 0 ? void 0 : _a.Key,
                                                value: cv
                                            });
                                        })
                                        : [], onChange: function (values) { return updateRefiner(refiner, values.map(function (v) { return v.value; }).join("|")); } }))))) : refiner.Type === "Text" ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                React.createElement(Form.Control, { placeholder: intl.formatMessage({ id: "component.directory.filters.results_only_containing_this_text", defaultMessage: "results only containing this text" }), autoComplete: "off", value: refiner.CurrentValue !== null ? refiner.CurrentValue : "", onChange: function (event) { return updateRefiner(refiner, event.target.value); } }))) : refiner.Type === "Date" ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement("div", { className: "date-title" },
                                            React.createElement(FormattedMessage, { id: "component.directory.filters.after", defaultMessage: "After" })),
                                        React.createElement(Form.Control, { type: "date", value: refiner.CurrentValue !== null ? refiner.CurrentValue.split("|")[0] : "", onChange: function (event) { return updateRefiner(refiner, { from: event.target.value }); } })),
                                    React.createElement(Col, null,
                                        React.createElement("div", { className: "date-title" },
                                            React.createElement(FormattedMessage, { id: "component.directory.filters.before", defaultMessage: "Before" })),
                                        React.createElement(Form.Control, { type: "date", value: refiner.CurrentValue !== null ? refiner.CurrentValue.split("|")[1] : "", onChange: function (event) { return updateRefiner(refiner, { to: event.target.value }); } }))))) : refiner.Type === "ManagedMetadata" && getExtendedPropertyValueStartTermObject(refiner) !== null ? (React.createElement(Form.Group, { className: "form-group", key: rIndex },
                                React.createElement(Form.Label, null, refiner.Title),
                                React.createElement("div", { className: "metadata-input" },
                                    React.createElement(ManagedMetadataPicker, { startNodeJson: refiner.ExtendedProperties.TermStartJson, allowMultiple: true, placeholder: intl.formatMessage({ id: "component.directory.filters.metadata_search_placeholder", defaultMessage: "Search..." }), allowTextEntry: true, mmdJsonValue: refiner.CurrentValue, view: "input-picker", onChange: function (metadataJson) { return updateRefiner(refiner, metadataJson); } })))) : null;
                        }))),
                    React.createElement("div", { className: "footer" },
                        React.createElement("div", { className: "clear" },
                            React.createElement("div", { className: "clear-button", onClick: function () { return clearRefiners(); }, "data-pw": "clear-button" },
                                React.createElement(FormattedMessage, { id: "component.directory.filters.clear_button", defaultMessage: "Clear" }))),
                        React.createElement("div", { className: "filters-loading" }, directoryIsLoading === true ? React.createElement(AnimatedLoader, { size: "xsmall" }) : null))))),
        React.createElement("div", { className: "sort", ref: sortDropdown },
            React.createElement("div", { className: "sort-button custom-button" + (directory.Context.Sort === "Relevance" ? " hide-sort-arrow" : ""), "data-pw": "sort-button", onClick: function () {
                    setSortDropdownOpen(!sortDropdownOpen);
                } },
                React.createElement("div", { className: "direction", onClick: function (event) {
                        sortDirectionClick(event);
                    } }, directory.Context.SortDirection === "asc" ? (React.createElement("div", { className: "up", "data-pw": "up" },
                    React.createElement("i", { className: "la la-long-arrow-up" }))) : (React.createElement("div", { className: "down", "data-pw": "down" },
                    React.createElement("i", { className: "la la-long-arrow-down" })))),
                directory.Columns.map(function (col, index) { return (col.ProperyName === directory.Context.Sort ? React.createElement("span", null, col.Title) : null); }),
                directory.Columns.some(function (col) { return col.ProperyName === directory.Context.Sort; }) === false ? (
                // <span>{directory.Context.Sort}</span>
                React.createElement(React.Fragment, null, directory.Context.Sort === "Created" ? (React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "component.directory.filters.created", defaultMessage: "Created" }))) : directory.Context.Sort === "Relevance" ? (React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "component.directory.filters.relevance", defaultMessage: "Relevance" }))) : null)) : null),
            React.createElement(Collapse, { in: sortDropdownOpen },
                React.createElement("div", { className: "sort-dropdown", "data-pw": "sort-dropdown" },
                    React.createElement("div", { className: "sort-item", "data-pw": "sort-item", onClick: function () {
                            sort("Created");
                        } },
                        React.createElement(FormattedMessage, { id: "component.directory.filters.created", defaultMessage: "Created" })),
                    React.createElement("div", { className: "sort-item", "data-pw": "sort-item", onClick: function () {
                            sort("Relevance");
                        } },
                        React.createElement(FormattedMessage, { id: "component.directory.filters.relevance", defaultMessage: "Relevance" })),
                    directory.Columns.map(function (col, index) {
                        return col.SortEnabled ? (React.createElement("div", { key: index, className: "sort-item", "data-pw": "sort-item", onClick: function () {
                                sort(col.ProperyName);
                            } }, col.Title)) : null;
                    })))),
        React.createElement("div", { className: "view" },
            React.createElement("div", { className: "view-selector" },
                React.createElement("div", { className: "list custom-button" + (currentView === "List" ? " selected" : ""), "data-pw": "list-view-button", onClick: function () {
                        dispatch(DirectoryStore.UpdateView({ View: "List" }));
                    } },
                    React.createElement("div", { className: "list-icon" },
                        React.createElement("div", { className: "top-bar" }),
                        React.createElement("div", { className: "middle-bar" }),
                        React.createElement("div", { className: "bottom-bar" }))),
                React.createElement("div", { className: "cards custom-button" + (currentView === "Cards" ? " selected" : ""), "data-pw": "cards-view-button", onClick: function () {
                        dispatch(DirectoryStore.UpdateView({ View: "Cards" }));
                    } },
                    React.createElement("i", { className: "flaticon2-menu-4" }))))));
});
export default DirectoryFilters;
