import { TeamInformationCurrentActionTypes } from "./types";
export function LoadTeamContext(teamContext) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamContext,
        payload: teamContext
    };
}
export function LoadTeamTabConfiguration(teamTabConfiguration) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamTabConfiguration,
        payload: teamTabConfiguration
    };
}
export function LoadCosmosObject(teamId, microsoftAppType) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamInformation,
        payload: { TeamId: teamId, MicrosoftAppType: microsoftAppType }
    };
}
export function LoadTeamLinks(teamId) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamLinks,
        payload: { TeamId: teamId }
    };
}
export function LoadTeamLinksNotebookAndPlanner(teamId) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamLinks_NotebookAndPlanner,
        payload: { TeamId: teamId }
    };
}
export function LoadDirectoryMetadata(groupId) {
    if (groupId === void 0) { groupId = ""; }
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_DirectoryMetadata,
        payload: { GroupId: groupId }
    };
}
export function UpdateTeamState(updates) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Update_TeamState,
        payload: updates
    };
}
export function UpdateTeamInformation(updates) {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Update_TeamInformation,
        payload: updates
    };
}
export function ClearDirectory() {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Clear_DirectoryMetadata
    };
}
export function Clear() {
    return {
        type: TeamInformationCurrentActionTypes.TeamInformation_Current_Clear
    };
}
