import React from "react";
import "./team-information-chrome.scss";
import { useSelector, useDispatch } from "react-redux";
import * as TeamInformationCurrentStore from "app/store/team-information/current";
var TeamInformationChrome = React.memo(function (props) {
    var _a;
    var currentKey = useSelector(function (state) { return state.teamInformationState.teamTabState.currentTabKey; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        //dispatch(TeamInformationCurrentStore.UpdateTeamState({ currentTabKey: props.tabs[0].key }));
    }, []);
    return (React.createElement("div", { className: "team-information-chrome" },
        React.createElement("div", { className: "chrome-tab-navigation" }, props.tabs.map(function (tab) { return (React.createElement("div", { className: "tab" + (tab.key === currentKey ? " selected" : ""), onClick: function () { return dispatch(TeamInformationCurrentStore.UpdateTeamState({ currentTabKey: tab.key, forceAddGuestOpen: false })); } },
            React.createElement("div", { className: "tab-container" },
                React.createElement("i", { className: tab.fontClass }),
                React.createElement("span", { className: "title" }, tab.title)))); })),
        React.createElement("div", { className: "chrome-tab-content-area" }, (_a = props.tabs.find(function (t) { return t.key === currentKey; })) === null || _a === void 0 ? void 0 : _a.component)));
});
export default TeamInformationChrome;
