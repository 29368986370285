import * as React from "react";
import PrincipalItem from "app/components/common/principal-item";
import "./principal-item-not-found.scss";
var PrincipalItemNotFound = React.memo(function (_a) {
    var style = _a.style, showBorder = _a.showBorder, size = _a.size;
    var nullUser = {
        GUID: "0",
        Id: "0",
        Type: "User",
        Company: "",
        CountryOrRegion: "",
        Department: "",
        DisplayName: "User Account Not Found",
        GivenName: "User Account Not Found",
        JobTitle: "",
        Office: "",
        StateOrProvince: "",
        Surname: "",
        UserPrincipalName: ""
    };
    return React.createElement(PrincipalItem, { principal: nullUser, style: style, showBorder: showBorder, size: size, className: "principal-item-not-found", fontIconOverride: "fa-solid fa-user-xmark" });
});
export default PrincipalItemNotFound;
