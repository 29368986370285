var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AsyncWait } from "app/components/common/async";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import Picklist from "app/components/common/form-picklist";
import InfoToolTip from "app/components/common/infotooltip";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import ProgressReport from "app/components/common/progress-report";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import DirectoryService from "app/services/directory";
import { AsyncStatus } from "app/store/async";
import * as MetadataListStore from "app/store/metadata/list";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./directory-configuration.scss";
var DirectoryConfiguration = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var metadata = useSelector(function (state) { return state.metadataListState.items; });
    var _a = React.useState({}), currentTenantConfigInternalState = _a[0], setCurrentTenantConfigInternalState = _a[1];
    var _b = React.useState([]), directoryColumnSettings = _b[0], setDirectoryColumnSettings = _b[1];
    var saveDiv = React.useRef();
    var _c = React.useState(false), anyEdits = _c[0], setAnyEdits = _c[1];
    var _d = React.useState(false), metadataLoaded = _d[0], setMetadataLoaded = _d[1];
    var _e = React.useState(false), tenantConfigLoaded = _e[0], setTenantConfigLoaded = _e[1];
    var _f = React.useState(false), jobStartClick = _f[0], setJobStartClick = _f[1];
    var _g = React.useState(), jobId = _g[0], setJobId = _g[1];
    var orchestryColumns = React.useState([
        // {
        //    Name: "Name",
        //    FieldName: "Title",
        //    Include: true,
        //    CustomField: false
        // },
        {
            Name: "Users",
            FieldName: "OwnerEmail",
            Include: true,
            CustomField: false
        },
        {
            Name: "Description",
            FieldName: "Description",
            Include: true,
            CustomField: false
        },
        {
            Name: "Type",
            FieldName: "TemplateType",
            Include: true,
            CustomField: false
        },
        {
            Name: "Workspace Type",
            FieldName: "TemplateName",
            Include: false,
            CustomField: false
        },
        {
            Name: "Classification",
            FieldName: "Classification",
            Include: false,
            CustomField: false
        },
        {
            Name: "Sensitivity",
            FieldName: "SensitivityLabel",
            Include: false,
            CustomField: false
        }
    ])[0];
    var _h = React.useState([]), tabConfiguration = _h[0], setTabConfiguration = _h[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Metadata_List_Load").then(function () {
            setMetadataLoaded(true);
            //console.log("metadata loaded");
        });
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(function () {
            //console.log("tenant config loaded");
            setTenantConfigLoaded(true);
        });
        dispatch(MetadataListStore.Load([]));
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (metadataLoaded && tenantConfigLoaded) {
            var tempColumnOptions_1 = [];
            if (currentTenantConfiguration !== undefined && currentTenantConfiguration.DirectoryColumnConfiguration !== undefined) {
                if (currentTenantConfiguration.DirectoryColumnConfiguration === "" ||
                    currentTenantConfiguration.DirectoryColumnConfiguration === "null" ||
                    currentTenantConfiguration.DirectoryColumnConfiguration === null) {
                    tempColumnOptions_1 = __spreadArray([], orchestryColumns, true);
                    metadata.forEach(function (md) {
                        tempColumnOptions_1.push({
                            Name: md.Title + " (Custom)",
                            FieldName: md.SPFieldInternalName !== undefined ? md.SPFieldInternalName : "",
                            Include: false,
                            CustomField: true
                        });
                    });
                }
                else {
                    tempColumnOptions_1 = JSON.parse(currentTenantConfiguration.DirectoryColumnConfiguration);
                    // Remove any custom columns that are not longer a metadata setup in Orchestyr.
                    tempColumnOptions_1 = tempColumnOptions_1
                        .filter(function (colOption) {
                        if (colOption.CustomField === false)
                            return true;
                        if (metadata.some(function (md) { return md.SPFieldInternalName === colOption.FieldName; }))
                            return true;
                        return false;
                    })
                        // Filter out the name column.
                        .filter(function (col) { return (col.FieldName === "Title" ? false : true); });
                    // Add any missing Orchestry metadata items.
                    metadata.forEach(function (md) {
                        if (tempColumnOptions_1.some(function (co) { return co.FieldName === md.SPFieldInternalName; }) === false) {
                            tempColumnOptions_1.push({
                                Name: md.Title + " (Custom)",
                                FieldName: md.SPFieldInternalName !== undefined ? md.SPFieldInternalName : "",
                                Include: false,
                                CustomField: true
                            });
                        }
                    });
                    // Finally. Add any missing orchestry columns.
                    if (tempColumnOptions_1.filter(function (o) { return o.CustomField === false; }).length !== orchestryColumns.length) {
                        var tempColsNotCustom = tempColumnOptions_1.filter(function (o) { return o.CustomField === false; });
                        var tempColsCustom = tempColumnOptions_1.filter(function (o) { return o.CustomField === true; });
                        orchestryColumns.forEach(function (oc) {
                            if (tempColsNotCustom.some(function (o) { return o.FieldName === oc.FieldName; }) === false) {
                                tempColsNotCustom = __spreadArray(__spreadArray([], tempColsNotCustom, true), [__assign(__assign({}, oc), { Include: false })], false);
                            }
                            else {
                                tempColsNotCustom = tempColsNotCustom.map(function (o) { return (o.FieldName === oc.FieldName ? __assign(__assign({}, o), { Name: oc.Name }) : o); });
                            }
                        });
                        tempColumnOptions_1 = __spreadArray(__spreadArray([], tempColsNotCustom, true), tempColsCustom, true);
                    }
                }
            }
            setDirectoryColumnSettings(tempColumnOptions_1);
            var currentTenantConfigCopy = __assign({}, currentTenantConfiguration);
            setCurrentTenantConfigInternalState(currentTenantConfigCopy);
            if (currentTenantConfiguration.DirectoryImportJobId !== undefined && currentTenantConfiguration.DirectoryImportJobId !== null && currentTenantConfiguration.DirectoryImportJobId !== "") {
                setJobId(currentTenantConfiguration.DirectoryImportJobId);
            }
        }
    }, [metadataLoaded, tenantConfigLoaded]);
    React.useEffect(function () {
        var directoryColumns = JSON.stringify(directoryColumnSettings);
        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryColumnConfiguration: directoryColumns }));
        setTabConfiguration(directoryColumnSettings.map(function (dc) {
            return ({
                Identifier: dc.FieldName,
                Name: dc.Name,
                Picked: dc.Include
            });
        }));
    }, [directoryColumnSettings]);
    //after the current config object is updated, check to see if the save button should be highlighted.
    React.useEffect(function () {
        console.log("checked box", currentTenantConfigInternalState.DirectoryImportNonOrchestrySites);
        checkIfSaveable();
    }, [currentTenantConfigInternalState]);
    // Checks for edits
    var checkIfSaveable = function () {
        //console.log("currentTenantConfigInternalState",currentTenantConfigInternalState);
        if (currentTenantConfigInternalState.GUID !== undefined) {
            if (currentTenantConfigInternalState.GUID !== "") {
                //console.log(JSON.stringify(currentTenantConfigInternalState), "\n\n\n", JSON.stringify(currentTenantConfiguration));
                if (JSON.stringify(currentTenantConfigInternalState) !== JSON.stringify(currentTenantConfiguration)) {
                    //console.log("Does Match",false);
                    if (anyEdits === false) {
                        setAnyEdits(true);
                        //save();
                    }
                }
            }
        }
    };
    // On save, save to server and set save button back to non-edited.
    var save = function () {
        console.log("Current State", currentTenantConfigInternalState);
        dispatch(TenantConfigurationCurrentStore.UpdateDirectoryCats(__assign({}, currentTenantConfigInternalState)));
        setAnyEdits(false);
    };
    var updateColumnPicklist = function (picklist) {
        var newDirConfig = [];
        picklist.forEach(function (pli) {
            var foundDirConfigItem = directoryColumnSettings.find(function (dc) { return dc.FieldName === pli.Identifier; });
            if (foundDirConfigItem !== undefined) {
                newDirConfig = __spreadArray(__spreadArray([], newDirConfig, true), [__assign(__assign({}, foundDirConfigItem), { Include: pli.Picked })], false);
            }
        });
        setDirectoryColumnSettings(newDirConfig);
    };
    var toggleCheckbox = function (checked, item) {
        console.log("checkbox", checked, item);
        var temp = __assign({}, item);
        temp.Include = checked;
        setDirectoryColumnSettings(directoryColumnSettings.map(function (dcs) { return (dcs.FieldName === temp.FieldName ? temp : dcs); }));
    };
    var strartDirectoryUpdate = function () {
        var importAll = currentTenantConfigInternalState.DirectoryImportNonOrchestrySites !== undefined ? currentTenantConfigInternalState.DirectoryImportNonOrchestrySites : false;
        var importPrivateVisible = currentTenantConfigInternalState.DirectoryImportPrivateVisible !== undefined ? currentTenantConfigInternalState.DirectoryImportPrivateVisible : false;
        var importPublicVisible = currentTenantConfigInternalState.DirectoryImportPublicVisible !== undefined ? currentTenantConfigInternalState.DirectoryImportPublicVisible : false;
        DirectoryService.startImportAll(importAll, importPrivateVisible, importPublicVisible).then(function (jobId) {
            setJobId(jobId);
        });
        //currentTenantConfiguration.DirectoryImportNonOrchestrySites
    };
    var cancelImport = function () {
        if (jobId !== undefined && jobId != null && jobId !== "") {
            DirectoryService.CancelImport().then(function (cancelled) {
                //if(cancelled){
                setJobId(undefined);
                dispatch(TenantConfigurationCurrentStore.Load());
                //}
            });
        }
    };
    return (React.createElement("div", { className: "directory-config fadein-500ms" + (props.isModal !== undefined && props.isModal === true ? " is-modal" : "") },
        props.isModal === undefined ? (
        // <Subtitle title="Settings" subtitle="Document Template Categories">
        //     <NavLink to={"/settings"}><SubtitleButton title="Cancel" variant="light" /></NavLink>
        //     <div ref={saveDiv} style={{display:"inline-block"}}><SubtitleButton title="Save" variant="success" /></div>
        // </Subtitle>
        React.createElement(React.Fragment, null,
            React.createElement(PageHeader.SetLeftContent, { title: "Settings" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement("div", { onClick: function () { return save(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))))) : null,
        metadataLoaded === true && tenantConfigLoaded === true ? (React.createElement(React.Fragment, null, currentTenantConfigInternalState.GUID !== "" ? (React.createElement(React.Fragment, null,
            React.createElement(Row, { className: "" },
                React.createElement(Col, { md: 6 },
                    React.createElement(Portlet, { className: "indexing-portlet", "data-pw": "directory-data-indexing" },
                        React.createElement(PortletHeader, { title: "Directory Data Indexing" }),
                        React.createElement(PortletBody, null,
                            React.createElement(Form.Group, { controlId: "control-automatically", className: "form-group checkbox-green" },
                                React.createElement("div", { className: "import-newly-created", "data-pw": "index-options" },
                                    React.createElement("h5", null, "Index Options"),
                                    React.createElement(Form.Group, { controlId: "importNewlyCreate", className: "form-group checkbox-green", "data-pw": "import-teams-sites" },
                                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                                "Import teams and sites not created through Orchestry",
                                                React.createElement(InfoToolTip, null, "Teams and Modern SharePoint Sites not created through Orchestry will be imported into the Directory.")), defaultChecked: currentTenantConfiguration.DirectoryImportNonOrchestrySites, onChange: function (e) {
                                                setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryImportNonOrchestrySites: e.currentTarget.checked }));
                                            } })),
                                    React.createElement(Form.Group, { controlId: "importPrivate", className: "form-group checkbox-green", "data-pw": "import-private" },
                                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                                "Hide Private Sites or Teams",
                                                React.createElement(InfoToolTip, null, "If this is checked - when a new private site or team is imported into the directory it will be hidden from all users.")), defaultChecked: !currentTenantConfiguration.DirectoryImportPrivateVisible, onChange: function (e) {
                                                setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryImportPrivateVisible: !e.currentTarget.checked }));
                                            } })),
                                    React.createElement(Form.Group, { controlId: "importPublic", className: "form-group checkbox-green", "data-pw": "import-public" },
                                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                                "Hide Public Sites or Teams",
                                                React.createElement(InfoToolTip, null, "If this is checked - when a new public site or team is imported into the directory it will be hidden from all users")), defaultChecked: !currentTenantConfiguration.DirectoryImportPublicVisible, onChange: function (e) {
                                                setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryImportPublicVisible: !e.currentTarget.checked }));
                                            } })),
                                    React.createElement(Form.Group, { controlId: "allowUsers", className: "form-group checkbox-green", "data-pw": "allow-users" },
                                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                                "Allow users to determine if a new Workspace is added to the directory",
                                                React.createElement(InfoToolTip, null, "If checked the [Show in the Directory] toggle will be visible in the request wizard.")), defaultChecked: currentTenantConfiguration.DirectoryImportAllowUserSelectionToAdd, onChange: function (e) {
                                                setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryImportAllowUserSelectionToAdd: e.currentTarget.checked }));
                                            } }))),
                                React.createElement("div", { className: "import-all-items-now", "data-pw": "index-tools" },
                                    React.createElement("h5", null, "Index Tools"),
                                    React.createElement("p", null, "The following button will import and/or update teams and sites into the Orchestry directory."),
                                    React.createElement("p", null,
                                        React.createElement("i", null, "Please note: If you have checked the \"import newly created teams\" above, then this button will import ALL teams and sites in the tenant. If unchecked, this button will only attempt to update teams and sites already in the directory.")),
                                    jobId !== undefined ? (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "btn btn-danger", onClick: function () { return cancelImport(); } }, "Cancel Import"),
                                        React.createElement(ProgressReport, { showOnlySubBar: true, showBeforeJobId: true, jobId: jobId, onComplete: function () { return setJobId(undefined); }, turnOffAfter: false, hideProgressLog: true }))) : (React.createElement("div", { className: "subtitle-button btn btn-success", onClick: function () { return strartDirectoryUpdate(); }, "data-pw": "import-update-button" }, "Import and Update"))))))),
                React.createElement(Col, { md: 6 },
                    React.createElement(Portlet, { className: "column-choices", "data-pw": "directory-columns-table-view" },
                        React.createElement(PortletHeader, { title: "Directory columns visible in table view" }),
                        React.createElement(PortletBody, null,
                            React.createElement(Picklist, { data: tabConfiguration, onChange: function (data) { return updateColumnPicklist(data); } }))),
                    React.createElement(Portlet, { className: "display-options", "data-pw": "directory-display-options" },
                        React.createElement(PortletHeader, { title: "Directory Display Options" }),
                        React.createElement(PortletBody, null,
                            React.createElement("p", null, "Default Directory Display"),
                            React.createElement("div", { className: "blocked-words-form" },
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "1",
                                            title: "List",
                                            value: "List"
                                        },
                                        {
                                            id: "2",
                                            title: "Cards",
                                            value: "Cards"
                                        }
                                    ], defaultValue: currentTenantConfiguration.DirectoryDefaultView, onChange: function (e) {
                                        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryDefaultView: e }));
                                    } })),
                            React.createElement("br", null),
                            React.createElement(Form.Group, { controlId: "show-favs", className: "form-group checkbox-green", "data-pw": "show-fav-checkbox" },
                                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Show Favorites"), 
                                    // defaultChecked={currentTenantConfigInternalState.DirectoryShowFavorites}
                                    checked: currentTenantConfigInternalState.DirectoryShowFavorites, onChange: function (e) {
                                        console.log("DirectoryShowFavorites", e.currentTarget.checked);
                                        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryShowFavorites: e.currentTarget.checked }));
                                    } })),
                            React.createElement("br", null),
                            React.createElement("p", null, "Default Sort Order"),
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(FormDropdownSelect, { list: [
                                        { id: "1", title: "Alphabetical", value: "Title|asc" },
                                        { id: "2", title: "Created (Newest First)", value: "Created|desc" },
                                        { id: "3", title: "Created (Oldest First)", value: "Created|asc" }
                                    ], onChange: function (e) {
                                        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DirectoryDefaultSort: e }));
                                    }, defaultValue: currentTenantConfiguration.DirectoryDefaultSort || "Created|desc" })))))))) : null)) : (React.createElement(Row, { className: "" },
            React.createElement(Col, { md: 6 },
                React.createElement(SkeletonLoaderPortlet, { bodyHeight: 600 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30, marginTop: 15 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 300, marginTop: 15 }))),
            React.createElement(Col, { md: 6 },
                React.createElement(SkeletonLoaderPortlet, { bodyHeight: 250 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 })),
                React.createElement(SkeletonLoaderPortlet, { bodyHeight: 170 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, marginTop: 15, outlineOnly: true }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 15 })))))));
});
export default DirectoryConfiguration;
