import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { AsyncCallType } from "app/store/async/types";
import { ApprovalWorkflowCurrentActionTypes } from "app/store/approval-workflows/current/types";
import { ApprovalWorkflowListActionTypes } from "app/store/approval-workflows/list/types";
import { TemplateCurrentActionTypes } from "app/store/templates/current/types";
import { TemplateListActionTypes } from "app/store/templates/list/types";
export var tenantConfigurationCurrentActionMap = [
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {}
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {},
        invalidates: [ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load, ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Load]
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Save_To_Server,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {},
        invalidates: [ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load, ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Load]
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DocTempCats,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {},
        invalidates: [ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load, ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Load]
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TempCats,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {},
        invalidates: [TemplateCurrentActionTypes.Template_Current_Load, TemplateListActionTypes.Template_List_Load, TemplateListActionTypes.Template_List_LoadForRequest]
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DirectoryConfig,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {},
        invalidates: [TemplateCurrentActionTypes.Template_Current_Load, TemplateListActionTypes.Template_List_Load, TemplateListActionTypes.Template_List_LoadForRequest]
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Silent_Update,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {}
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TeamConfig,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {}
    },
    {
        actionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Config,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/TenantConfiguration",
        responseDataType: {}
    }
];
