var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Store from "app/store";
import { LifecycleGuestPolicyListActionTypes } from "./types";
import { cloneDeep } from "lodash";
export function Load(policies) {
    if (policies === undefined)
        policies = [];
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Load,
        payload: {
            items: __spreadArray([], policies, true)
        }
    };
}
export function Refresh() {
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Refresh,
        payload: {
            items: []
        }
    };
}
export function AddItem(policy) {
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Add,
        payload: __assign({}, policy)
    };
}
export function AddCurrentItem() {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecycleGuestPoliciesCurrentState.item);
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Add,
        payload: __assign({}, policyState)
    };
}
export function UpdateItem(policy) {
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Update,
        payload: __assign({}, policy)
    };
}
export function UpdateCurrentItem() {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecycleGuestPoliciesCurrentState.item);
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Update,
        payload: __assign({}, policyState)
    };
}
export function DeleteItem(policy) {
    return {
        type: LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Delete,
        payload: __assign({}, policy)
    };
}
export var guestPolicyListMiddlware = function (store, next, action) {
    var _a;
    if (action.type === LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Add || action.type === LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Update) {
        var payload = action.payload;
        if (!payload.GuestNamingConventionEnabled) {
            payload.GuestNameConfiguration = undefined;
        }
        if (payload.GuestProfilePhotoEnabled) {
            payload.GuestDefaultProfileImage = (_a = payload.GuestDefaultProfileImage) === null || _a === void 0 ? void 0 : _a.replace(new RegExp("data:.*,"), "");
        }
        else {
            payload.GuestDefaultProfileImage = undefined;
        }
        action.payload = payload;
    }
    next(action);
};
