import { PermissionInfoAction } from "../permission-info-action.type";
var OrchestryPermissionInfoEditorEditModalUtils = /** @class */ (function () {
    function OrchestryPermissionInfoEditorEditModalUtils() {
    }
    /**
     * Gets the title for the submit button.
     *
     * @param permissionInfoAction The action being performed on the permission info item.
     */
    OrchestryPermissionInfoEditorEditModalUtils.getSubmitButtonTitle = function (permissionInfoAction) { return (permissionInfoAction === PermissionInfoAction.Create ? "Add New Item" : "Save Edits"); };
    return OrchestryPermissionInfoEditorEditModalUtils;
}());
export { OrchestryPermissionInfoEditorEditModalUtils };
