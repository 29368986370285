var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useForm } from "@mantine/form";
import { HealthChecksSettingsReviewProcess } from "appv2/api/health-checks-settings/health-checks-settings-review-process.type";
import { HealthChecksSettingsScheduleCadence } from "appv2/api/health-checks-settings/health-checks-settings-schedule-cadence.type";
import { useGetHealthChecksSettingsQuery, useUpdateHealthChecksSettingsMutation } from "appv2/api/health-checks-settings/health-checks-settings.api";
import { useGetPartnersSettingsQuery, useVerifyPartnerSecretMutation } from "appv2/api/partners-settings/partners-settings.api";
import { getBaseTenantEntityInitialValues } from "appv2/api/types/base-tenant-entity.type";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
export var useHealthChecksSettingsPage = function () {
    var history = useHistory();
    var onBackButtonClicked = function () { return history.push("/health-checks"); };
    var intl = useIntl();
    var _a = useGetHealthChecksSettingsQuery(), healthChecksSettings = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, refetch = _a.refetch;
    var updateHealthChecksSettings = useUpdateHealthChecksSettingsMutation()[0];
    var _b = useGetPartnersSettingsQuery(), partnersSettings = _b.data, isPartnerSettingsLoading = _b.isLoading, isPartnersSettingsSuccess = _b.isSuccess, refetchPartnerSettings = _b.refetch;
    var _c = useVerifyPartnerSecretMutation(), verifyPartnerSecret = _c[0], _d = _c[1], partnerData = _d.data, isVerifyPartnerSecretLoading = _d.isLoading;
    var secretKey = partnersSettings === null || partnersSettings === void 0 ? void 0 : partnersSettings.SecretKey;
    useEffect(function () {
        if (isPartnersSettingsSuccess && secretKey) {
            verifyPartnerSecret(secretKey);
        }
    }, [isPartnersSettingsSuccess, verifyPartnerSecret, secretKey]);
    var partnerOrganizationName = partnerData === null || partnerData === void 0 ? void 0 : partnerData.OrganizationName;
    // Refetch the health checks settings when the page is navigated away from and back to
    useEffect(function () {
        refetch();
        refetchPartnerSettings();
    }, [refetch, refetchPartnerSettings]);
    var form = useForm({
        initialValues: __assign({ PartnerGUID: "", GenerateAutomatically: true, ScheduleCadence: HealthChecksSettingsScheduleCadence.Monthly, ReviewProcess: HealthChecksSettingsReviewProcess.Disabled, Reviewers: "", Viewers: "", HealthCheckReportBrandingImage: "" }, getBaseTenantEntityInitialValues()),
        validate: {
            Reviewers: function (value, allValues) {
                return allValues.ReviewProcess !== HealthChecksSettingsReviewProcess.SelfLed || (!!value && value !== "[]") ? null : "Reviewers are required for self-led review process.";
            }
        }
    });
    var onReviewProcessChange = function (value) {
        form.setFieldValue("ReviewProcess", value);
        form.setFieldValue("Reviewers", "[]");
    };
    useEffect(function () {
        if (healthChecksSettings) {
            form.setValues(healthChecksSettings);
            form.setInitialValues(healthChecksSettings);
        }
        // form is not a dependency because it is updated in the useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthChecksSettings]);
    /**
     * Handles the image drop event. Saves the base64 encoded image to the form.
     *
     * @param files The dropped files. Only the first file is used
     */
    var handleImageDrop = function (files) {
        var reader = new FileReader();
        reader.onload = function () {
            var fileData = reader.result;
            form.setFieldValue("HealthCheckReportBrandingImage", fileData);
        };
        reader.readAsDataURL(files[0]);
    };
    var handleFormSubmit = function (values) {
        form.setInitialValues(values);
        updateHealthChecksSettings(values).then(function () { return OrchestrySuccessToast("Health Checks settings saved successfully"); });
    };
    var isReviewProcessSelfLed = form.getInputProps("ReviewProcess").value === HealthChecksSettingsReviewProcess.SelfLed;
    var isReviewProcessPartnerLed = form.getInputProps("ReviewProcess").value === HealthChecksSettingsReviewProcess.PartnerLed;
    var hasConnectedPartner = !!partnerOrganizationName;
    var shouldDisplayCadenceControl = form.getInputProps("GenerateAutomatically").value;
    var isPageLoading = !healthChecksSettings || isLoading || isFetching || !form.values || isPartnerSettingsLoading || isVerifyPartnerSecretLoading;
    var isSaveDisabled = isPageLoading || !form.isDirty();
    var reviewProcessOptions = [
        { label: "Disabled", value: "Disabled" },
        { label: "Self-led", value: "SelfLed" },
        { label: "Partner-led", value: "PartnerLed" }
    ];
    return {
        intl: intl,
        form: form,
        onBackButtonClicked: onBackButtonClicked,
        handleFormSubmit: handleFormSubmit,
        handleImageDrop: handleImageDrop,
        isReviewProcessSelfLed: isReviewProcessSelfLed,
        isReviewProcessPartnerLed: isReviewProcessPartnerLed,
        hasConnectedPartner: hasConnectedPartner,
        partnerOrganizationName: partnerOrganizationName,
        shouldDisplayCadenceControl: shouldDisplayCadenceControl,
        isPageLoading: isPageLoading,
        isSaveDisabled: isSaveDisabled,
        reviewProcessOptions: reviewProcessOptions,
        onReviewProcessChange: onReviewProcessChange
    };
};
