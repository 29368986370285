var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./form-fileuploadbox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import DocumentIcon from "../document-icon";
var FormImageUploadBox = React.memo(function (props, state) {
    //class FormImageUploadBox extends React.Component<IProps> {
    //const [file, setFile] = React.useState(props.defaultBase64File ? props.defaultBase64File : "");
    //const [fileName, setFileName] = React.useState(props.fileName ? props.fileName : "");
    //const [fileUrl, setFileUrl] = React.useState(props.fileUrl ? props.fileUrl : "");
    var onDrop = React.useCallback(function (acceptedFiles) {
        // Do something with the files
        //console.log(acceptedFiles);
        //let readBytesCompleted = 0;
        acceptedFiles.forEach(function (acceptedFile) {
            //setFileName(acceptedFile.name);
            //setFile(acceptedFile);
            //https://stackoverflow.com/questions/32311081/check-for-special-characters-in-string
            if (props.enforceSafeFileName !== undefined && props.enforceSafeFileName === true) {
                //var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
                var format = /[!@#$%^&*()+=\[\]{};':"\\|,<>\/?]/;
                if (format.test(acceptedFile.name)) {
                    alert("You file contains illegal characters. Please make sure your file name only contains letters, underscores, dashes, numbers and spaces.");
                }
                else {
                    props.onDrop(acceptedFile, acceptedFile.name);
                }
            }
            else {
                props.onDrop(acceptedFile, acceptedFile.name);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // React.useEffect(() => {
    //     //console.log("Image Resized!", image);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[file])
    var dzOptions = {
        onDrop: onDrop
    };
    //if(props.allowMultiple !== undefined)
    //    dzOptions.multiple = props.allowMultiple;
    // 5000000 = 5mb
    dzOptions.maxSize = 104857600;
    dzOptions.multiple = false;
    dzOptions.onDropRejected = function (event) {
        alert("File too big. Please upload an image smaller than 50mb");
    };
    var _a = useDropzone(dzOptions), getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
    //const {getRootProps, getInputProps} = useDropzone();
    var getFileType = function () {
        var re = /(?:\.([^.]+))?$/;
        if (props.fileName !== undefined && props.fileName !== null) {
            //@ts-ignore
            return re.exec(props.fileName)[1];
        }
        return "";
    };
    return (React.createElement("div", __assign({ className: "form-fileuploadbox" }, getRootProps()),
        React.createElement("div", { className: "fileupload" + (props.fileName !== undefined && props.fileName !== null && props.fileName !== "" ? " has-file" : "") }, props.fileName === undefined || (props.fileName !== undefined && props.fileName === "") || (props.fileName !== undefined && props.fileName === null) ? (React.createElement(React.Fragment, null,
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "la la-upload" })),
            React.createElement("div", { className: "text" }, props.uploadText))) : (React.createElement("div", { className: "file-preview" },
            React.createElement("div", { className: "doc-icon" },
                React.createElement(DocumentIcon, { docType: getFileType() })),
            React.createElement("div", { className: "doc-name" }, props.fileName),
            React.createElement("div", { className: "icons-actions" },
                props.onRemove !== undefined ? (React.createElement("div", { className: "delete btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () {
                        if (props.onRemove !== undefined)
                            props.onRemove(props.fileName !== undefined ? props.fileName : "");
                    } },
                    React.createElement(FontAwesomeIcon, { icon: faTrashAlt }))) : null,
                props.fileUrl !== undefined ? (React.createElement("a", { href: props.fileUrl, target: "_blank" },
                    React.createElement("div", { className: "download btn btn-sm btn-clean btn-icon btn-icon-sm" },
                        React.createElement(FontAwesomeIcon, { icon: faDownload })))) : null))))));
});
export default FormImageUploadBox;
