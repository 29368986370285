var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ReportSearchActionTypes } from "./types";
import Store from "app/store";
import { cloneDeep, mergeWith } from "lodash";
import { Generic_Toast_Notification } from "app/store/notifications";
export function Load(slotName) {
    return {
        type: ReportSearchActionTypes.Report_Load,
        payload: { Slot: slotName }
    };
}
export function LoadByState(state, slotName) {
    Store.dispatch(UpdateIsChangingView(true, slotName));
    return {
        type: ReportSearchActionTypes.Report_Load_WithContext,
        payload: __assign(__assign({}, state), { Slot: slotName })
    };
}
export function LoadMinimal(slotName) {
    return {
        type: ReportSearchActionTypes.Report_Load_Minimal,
        payload: { Slot: slotName }
    };
}
export function LoadRefiners(state, slotName) {
    return {
        type: ReportSearchActionTypes.Report_Load_Refiners,
        payload: __assign(__assign({}, state), { Slot: slotName })
    };
}
export function LoadViews(state, slotName) {
    return {
        type: ReportSearchActionTypes.Report_Load_Views,
        payload: __assign(__assign({}, state), { Slot: slotName })
    };
}
export function Refresh(slotName) {
    Store.dispatch(UpdateIsLoading(true, slotName));
    var state = Store.getState();
    var searchState = cloneDeep(state.lifecycleReportState[slotName]);
    searchState.Slot = slotName;
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    return {
        type: ReportSearchActionTypes.Report_Refresh,
        payload: searchState
    };
}
export function SilentRefresh(slotName) {
    // Store.dispatch(UpdateIsLoading(true, slotName));
    var state = Store.getState();
    var searchState = cloneDeep(state.lifecycleReportState[slotName]);
    searchState.Slot = slotName;
    searchState.Columns = [];
    searchState.Items = [];
    searchState.ItemCount = null;
    searchState.Favs = [];
    return {
        type: ReportSearchActionTypes.Report_RefreshSilent,
        payload: searchState
    };
}
export function TypeAhead(state, slotName) {
    return {
        type: ReportSearchActionTypes.Report_Search,
        payload: __assign(__assign({}, state), { Slot: slotName })
    };
}
export function GenerateFile(slotName) {
    var state = Store.getState();
    var searchState = __assign({}, state.lifecycleReportState[slotName]);
    searchState.Slot = slotName;
    searchState.Items = [];
    return {
        type: ReportSearchActionTypes.Report_GenerateFile,
        payload: searchState
    };
}
export function UpdatePaging(directoryPaging, slotName) {
    Store.dispatch(UpdateIsLoading(true, slotName));
    var state = Store.getState();
    var searchState = __assign({}, state.lifecycleReportState[slotName]);
    searchState.Paging = mergeWith(searchState.Paging, directoryPaging);
    searchState.Paging.PrevPageUrls = directoryPaging.PrevPageUrls;
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    searchState.Slot = slotName;
    return {
        type: ReportSearchActionTypes.Report_Update_Paging,
        payload: searchState
    };
}
export function UpdateContext(directoryContext, slotName) {
    console.log("UpdateContext", slotName);
    Store.dispatch(UpdateIsLoading(true, slotName));
    var state = Store.getState();
    var searchState = __assign({}, state.lifecycleReportState[slotName]);
    searchState.Context = mergeWith(searchState.Context, directoryContext);
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    searchState.Paging.CurrentPage = 1;
    searchState.Paging.PrevPageUrls = [];
    searchState.Paging.CurrentPageUrl = "";
    searchState.Slot = slotName;
    return {
        type: ReportSearchActionTypes.Report_Update_Context,
        payload: searchState
    };
}
export function UpdateRefiners(directoryRefiners, slotName) {
    // console.log("UpdateRefiners", slotName);
    Store.dispatch(UpdateIsLoading(true, slotName));
    var state = Store.getState();
    var searchStateSource = cloneDeep(state.lifecycleReportState[slotName]);
    // searchState.Refiners = directoryRefiners;
    // searchState.Columns = [];
    // searchState.Items = [];
    // searchState.Favs = [];
    // searchState.Paging.CurrentPage = 1;
    // searchState.Paging.PrevPageUrls = [];
    // searchState.Paging.CurrentPageUrl = "";
    //searchState.Refiners = mergeWith(searchState.Context, directoryContext);
    var searchState = {
        Refiners: directoryRefiners,
        Paging: searchStateSource.Paging,
        Context: searchStateSource.Context,
        Slot: slotName
    };
    searchState.Paging.CurrentPage = 1;
    searchState.Paging.PrevPageUrls = [];
    searchState.Paging.CurrentPageUrl = "";
    searchState.Paging.ResultsPerPage =
        searchStateSource.Paging.ResultsPerPage === undefined && searchStateSource.Paging.ResultsPerPage === null
            ? searchStateSource.Context.View === "List"
                ? 10
                : 12
            : searchStateSource.Paging.ResultsPerPage;
    return {
        type: ReportSearchActionTypes.Report_Update_Refiners,
        payload: searchState
    };
}
export function UpdateView(directoryContext, slotName) {
    Store.dispatch(UpdateIsLoading(true, slotName));
    var state = Store.getState();
    var searchState = __assign({}, state.lifecycleReportState[slotName]);
    searchState.Slot = slotName;
    searchState.Context = mergeWith(searchState.Context, directoryContext);
    searchState.Columns = [];
    searchState.Items = [];
    searchState.Favs = [];
    searchState.Paging.CurrentPage = 1;
    searchState.Paging.PrevPageUrls = [];
    searchState.Paging.CurrentPageUrl = "";
    return {
        type: ReportSearchActionTypes.Report_Update_View,
        payload: searchState
    };
}
export function UpdateIsLoading(isLoading, slotName) {
    return {
        type: ReportSearchActionTypes.Report_Update_IsLoading,
        payload: {
            SlotName: slotName,
            Property: isLoading
        }
    };
}
export function UpdateIsChangingView(isChangingView, slotName) {
    return {
        type: ReportSearchActionTypes.Report_Update_IsChangingView,
        payload: {
            SlotName: slotName,
            Property: isChangingView
        }
    };
}
export function Clear(slotName) {
    return {
        type: ReportSearchActionTypes.Report_Clear,
        payload: {
            SlotName: slotName,
            Property: null
        }
    };
}
export var DeleteGuestFromAzureSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Report_Bulk_DeleteGuestFromAzure_Success", actionType: ReportSearchActionTypes.Report_Bulk_DeleteGuestFromAzure_Success }
    };
};
export var RemoveGuestFromWorkspacesSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Report_Bulk_RemoveGuestFromWorkspaces_Success", actionType: ReportSearchActionTypes.Report_Bulk_RemoveGuestFromWorkspaces_Success }
    };
};
export var ResendInviteSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Report_Bulk_ResendInvite_Success", actionType: ReportSearchActionTypes.Report_Bulk_ResendInvite_Success }
    };
};
export var directorySearchMiddlware = function (store, next, action) {
    if (action.type === ReportSearchActionTypes.Report_Load //||
    //     action.type === ReportSearchActionTypes.Report_Search ||
    //     action.type === ReportSearchActionTypes.Report_Update_Context ||
    //     action.type === ReportSearchActionTypes.Report_Update_Favs ||
    //     action.type === ReportSearchActionTypes.Report_Update_Paging ||
    //     action.type === ReportSearchActionTypes.Report_Update_Refiners
    ) {
        //store.dispatch(UpdateIsLoading(true));
    }
    next(action);
};
