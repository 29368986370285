import { Text } from "@mantine/core";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import { OrchestryListItem } from "appv2/components/orchestry-list-item/orchestry-list-item.component";
import React from "react";
import { RecommendationHistoryAvatar } from "../../recommendations/recommendation-card/recommendation-card-history/recommendation-history-metadata/recommendation-history-avatar.component";
import { RecommendationHistoryMetadata } from "../../recommendations/recommendation-card/recommendation-card-history/recommendation-history-metadata/recommendation-history-metadata.component";
export var RecommendationResolution = function (_a) {
    var _b, _c, _d;
    var item = _a.item;
    return (React.createElement(OrchestryListItem, { key: item.RecommendationHistoryModel.Id },
        React.createElement("div", { className: "metadata" },
            React.createElement(RecommendationHistoryAvatar, { recommendationHistory: item.RecommendationHistoryModel }),
            React.createElement("div", { className: "text" },
                React.createElement(Text, { c: "text-primary.0", fw: 500, size: "14px" }, (_c = (_b = item.RecommendationRecord) === null || _b === void 0 ? void 0 : _b.DisplayName) !== null && _c !== void 0 ? _c : (_d = item.Recommendation) === null || _d === void 0 ? void 0 : _d.DisplayName),
                React.createElement(RecommendationHistoryMetadata, { recommendationHistory: item.RecommendationHistoryModel, index: 0 }))),
        React.createElement("img", { className: "icon", src: ORCH_SVG_ICONS.CheckIcon, alt: "Check Icon" })));
};
