import * as React from "react";
import "./portlet-alert.scss";
var PortletAlert = React.memo(function (props) {
    return (React.createElement("div", { className: "portlet-alert" + (" type-" + props.type) + (props.size !== undefined ? " size-" + props.size : "") + (props.outline !== undefined && props.outline === false ? " no-outline" : "") },
        React.createElement("div", { className: "alert alert-solid-" + props.type + " alert-bold", role: "alert" },
            props.icon !== undefined ? (React.createElement("div", { className: "alert-icon ".concat(props.iconAlign || "").trim() },
                props.icon === "alert" ? React.createElement("i", { className: "flaticon-warning" }) : null,
                props.icon === "info" ? React.createElement("i", { className: "flaticon-exclamation-2" }) : null,
                props.icon === "question" ? React.createElement("i", { className: "flaticon-questions-circular-button" }) : null,
                props.icon === "check" ? React.createElement("i", { className: "flaticon2-check-mark" }) : null,
                props.icon === "pen" ? React.createElement("i", { className: "flaticon2-edit" }) : null,
                props.icon === "ban" ? React.createElement("i", { className: "fa fa-ban" }) : null)) : null,
            React.createElement("div", { className: "alert-content", "data-pw": "alert-content" },
                props.title !== undefined ? React.createElement("div", { className: "alert-title" }, props.title) : null,
                props.message !== undefined ? React.createElement("div", { className: "alert-text" }, props.message) : null))));
});
export default PortletAlert;
