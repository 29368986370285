import * as React from "react";
import "./teams-status.scss";
import { TeamsUserActivity } from "../teams-status";
import { SkeletonLoaderFakeBox } from "../skeleton-loaders";
import UsersService from "app/services/users";
export var TeamsPresence = function (props) {
    var _a = React.useState(null), userStatus = _a[0], setUserStatus = _a[1];
    React.useEffect(function () {
        UsersService.getUserPresenceByID(props.userId).then(function (status) {
            setUserStatus(status);
        });
    }, [props.userId]);
    return React.createElement("div", null, userStatus == null ? React.createElement(SkeletonLoaderFakeBox, null) : React.createElement(TeamsUserActivity, { status: userStatus.activity }));
};
