import * as React from "react";
import "./guest-report.scss";
import InsightsReport from "app/components/insights/common/report";
import GuestDetails from "app/components/insights/guests/guest-details";
import Subtitle from "app/components/common/subtitle";
import { useHistory } from "react-router-dom";
import { ReportDatasource, ReportSearchSlotName } from "app/store/lifecycle-report";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { useDispatch, useSelector } from "react-redux";
import UsersGuestsService from "app/services/users-guests";
import OrchModal from "app/components/common/orch-modal";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import { useIntl } from "react-intl";
var ResendModalMessage = function (_a) {
    var plural = _a.plural, deletedGuests = _a.deletedGuests;
    return (React.createElement("div", { className: "resend-modal-body" },
        React.createElement("p", null,
            "Are you sure you would like to resend the", "".concat(plural ? "se" : ""),
            " Azure invitation", "".concat(plural ? "s" : ""),
            "?"),
        deletedGuests && deletedGuests > 0 ? (React.createElement(React.Fragment, null,
            React.createElement("br", null),
            React.createElement("strong", null, "Warning:"),
            React.createElement("p", null,
                "Deleted Guest", "".concat(deletedGuests > 1 ? "s" : ""),
                " (",
                deletedGuests,
                ") will not be reinvited."))) : null));
};
var RemoveGuestFromWorkspaceMessage = function (_a) {
    var plural = _a.plural;
    return (React.createElement("div", null,
        React.createElement("p", null,
            "Are you sure you'd like to delete ", "".concat(plural ? "these guests" : "this guest"),
            " Azure?")));
};
var GuestListing = React.memo(function (props) {
    var _a = React.useState(), currentGuestId = _a[0], setCurrentGuestId = _a[1];
    var _b = React.useState(), currentReportItem = _b[0], setCurrentReportItem = _b[1];
    var _c = React.useState(false), processing = _c[0], setProcessing = _c[1];
    var _d = React.useState(null), bulkJob = _d[0], setBulkJob = _d[1];
    var _e = React.useState(false), showComplete = _e[0], setShowComplete = _e[1];
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState; });
    var history = useHistory();
    var intl = useIntl();
    React.useEffect(function () {
        if (props.match.params.guestId !== "insight") {
            //console.log("GUEST ID", props.match.params.guestId);
            setCurrentGuestId(props.match.params.guestId);
        }
    }, [props.match.params.guestId]);
    React.useEffect(function () {
        dispatch(LifecycleReportStore.Clear(ReportSearchSlotName.GuestMembershipsSlot));
    }, [props.match.params.guestId]);
    var navigateToDetails = function (reportItem) {
        setCurrentReportItem(reportItem);
    };
    var bulkApply = function (guestDetails, action, workspaceUrl, groupId, siteId) {
        if (guestDetails.length > 0 && workspaceUrl && action === "Resend") {
            UsersGuestsService.BulkResendGuestInvites({ guestDetails: guestDetails, inviteUrl: workspaceUrl }).then(function (value) {
                setBulkJob({ bulkJobId: value.jobId, jobType: "Resend" });
                setProcessing(true);
            });
        }
        if (guestDetails.length > 0 && action === "Delete") {
            if (guestDetails.length === 1 && guestDetails[0].id !== null && guestDetails[0].id !== undefined) {
                setProcessing(true);
                UsersGuestsService.DeleteGuestFromAzure(guestDetails[0].id).then(function (value) {
                    setProcessing(false);
                    setShowComplete(true);
                    dispatch(LifecycleReportStore.DeleteGuestFromAzureSuccess());
                    dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestInsightsSlot));
                });
            }
            else {
                UsersGuestsService.BulkDeleteGuestFromAzure({ guestDetails: guestDetails }).then(function (value) {
                    setBulkJob({ bulkJobId: value.jobId, jobType: "Delete" });
                    setProcessing(true);
                });
            }
        }
    };
    var customBulkActionResend = function (context, selectedIds) {
        var deletedGuests = 0;
        var inviteGuestDetails = context.Items.filter(function (item) { return selectedIds.includes(item.UserId); }).map(function (item) {
            if (item.Status === "Deleted")
                deletedGuests++;
            if (item.Mail != null && item.UserId != null && item.Status !== "Deleted") {
                return { id: item.UserId, email: item.Mail };
            }
            return { id: "", email: "" };
        });
        return (React.createElement(OrchModal, { modalClassName: "guest-insights-bulk-resend-invite-guest-modal", simpleButton: true, size: "sm", headerText: intl.formatMessage({
                id: "actions.bulk.resend_invite_modal.title",
                defaultMessage: "Resend Azure Guest Invitation"
            }), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa fa-paper-plane" }), "Resend Invite".concat(selectedIds.length > 1 ? "s" : ""))), footerPrimaryText: "".concat(selectedIds.length > 1 ? "Resend Invites" : "Resend Invite"), footerPrimaryFunction: function () { return bulkApply(inviteGuestDetails, "Resend", "https://account.activedirectory.windowsazure.com/?tenantid=".concat(orchestryContext.item.TenantGuid)); } },
            React.createElement(ResendModalMessage, { deletedGuests: deletedGuests, plural: selectedIds.length > 1 ? true : false })));
    };
    var customBulkActionDelete = function (context, selectedIds) {
        var deleteGuestDetails = selectedIds.map(function (id) {
            return { id: id, email: "" };
        });
        return (React.createElement(OrchModal, { modalClassName: "bulk-remove-guest-modal", simpleButton: true, size: "sm", headerText: "Delete Guest".concat(selectedIds.length > 1 ? "s" : "", " from Azure"), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa-regular fa-trash-can-xmark" }), "Delete Guest".concat(selectedIds.length > 1 ? "s" : "", " from Azure"))), footerPrimaryText: "".concat(selectedIds.length > 1 ? "Delete Guests" : "Delete Guest"), footerPrimaryFunction: function () {
                bulkApply(deleteGuestDetails, "Delete", undefined, "", "");
            } },
            React.createElement("div", { className: "column-popout-confirmation-dialog" },
                React.createElement("div", { className: "message" },
                    React.createElement(RemoveGuestFromWorkspaceMessage, { plural: selectedIds.length > 1 })))));
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.jobType) === "Delete") {
                    UsersGuestsService.BulkDeleteGuestFromAzureStatus(bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId).then(function (data) {
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            erroredOut_1 = false;
                            runAgain();
                        }
                        else {
                            AsyncWait(undefined, AsyncStatus.Succeeded, null, null, "Report_Refresh").then(function (res) {
                                setProcessing(false);
                                dispatch(LifecycleReportStore.DeleteGuestFromAzureSuccess());
                            });
                            setTimeout(function () {
                                dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestInsightsSlot));
                            }, 1000);
                        }
                    });
                }
                if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.jobType) === "Resend") {
                    UsersGuestsService.BulkResendInviteStatus(bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId).then(function (data) {
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            erroredOut_1 = false;
                            runAgain();
                        }
                        else {
                            setProcessing(false);
                            dispatch(LifecycleReportStore.ResendInviteSuccess());
                        }
                    });
                }
            }
            else {
                runAgain();
            }
        }
        if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId]);
    return (React.createElement("div", { className: "lifecycle-listing" },
        React.createElement(Subtitle, { title: "Guests", subtitle: "Guest Listings" }),
        React.createElement("div", { style: currentGuestId !== undefined ? { overflow: "hidden", height: 0 } : {} },
            React.createElement(InsightsReport, { NavigateToDetails: navigateToDetails, ActionParam: props.match.params.guestId, FilterParam: props.match.params.param2, Datasource: ReportDatasource.GuestUser, BasePath: "guests", SubPath: "listing", NamePrefix: "Guest", ResultName: "guest", ShowArchived: false, ShowExport: true, ShowDeleted: true, ShowBusyAnimation: processing, Slot: ReportSearchSlotName.GuestInsightsSlot, CustomBulkActions: [customBulkActionDelete, customBulkActionResend], ResultsDisabled: processing, NoResultSubText: "Current criteria have no matches", DataNotReadyYetType: "Guests" })),
        props.match.params.guestId !== undefined && React.createElement(GuestDetails, { guestId: props.match.params.guestId, cosmosUser: currentReportItem })));
});
export default GuestListing;
