import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { TenantPages } from "appv2/pages/partner-tools/tenant/tenant-page.types";
import { useUserCanCreateHealthCheck } from "./genererate-new-health-check/use-user-can-create-health-check.hook";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
import { useHealthChecksFilters } from "./use-health-checks-filters.hook";
import { useHealthChecksTable } from "./use-health-checks-table.hook";
import { useTenantHealthChecksApi } from "./use-tenant-health-checks-api.hook";
export var useTenantHealthChecksPage = function (tab) {
    var intl = useIntl();
    var history = useHistory();
    var isMobile = useIsMobile();
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var _a = useHealthCheckRouteParams(), userType = _a.userType, tenantGuid = _a.tenantGuid;
    var _b = useUserCanCreateHealthCheck(), userCanGenerateHealthCheck = _b.canGenerateHealthCheck, isUserCanCreateHealthCheckLoading = _b.isLoading;
    //TechDebt: this could be cleaned up now that userCanGenerateHealthCheck is improved.
    var canGenerateHealthCheck = userType === "partner"
        ? tab === TenantPages.HealthChecks &&
            userCanGenerateHealthCheck &&
            (currentUserHasRole(OrchRole.PartnerHubAdmin) || currentUserHasRole(OrchRole.PartnerTenantManager) || currentUserHasRole(OrchRole.PartnerAdmin))
        : currentUserHasRole(OrchRole.Admin) || currentUserHasRole(OrchRole.BeaconAdmin);
    var canViewHealthCheckSettings = currentUserHasRole(OrchRole.Admin) || currentUserHasRole(OrchRole.PartnerAdmin) || currentUserHasRole(OrchRole.BeaconAdmin);
    var _c = useHealthChecksFilters(), appliedFilters = _c.appliedFilters, setAppliedFilters = _c.setAppliedFilters, clearAllFilters = _c.clearAllFilters, removeFilter = _c.removeFilter, FILTER_GROUPS = _c.FILTER_GROUPS;
    var _d = useTenantHealthChecksApi(appliedFilters), pagedHealthChecks = _d.pagedHealthChecks, totalHealthChecksCount = _d.totalHealthChecksCount, pageTotal = _d.pageTotal, isAlertLoading = _d.isAlertLoading, isPageLoading = _d.isPageLoading, isTableLoading = _d.isTableLoading, healthChecksInDraftStateCount = _d.healthChecksInDraftStateCount, showAlert = _d.showAlert, sorting = _d.sorting, setSorting = _d.setSorting, pageOptions = _d.pageOptions, activePage = _d.activePage, setActivePage = _d.setActivePage, resultsPerPage = _d.resultsPerPage, setResultsPerPage = _d.setResultsPerPage, highlightSearchWords = _d.highlightSearchWords, searchTextInputValue = _d.searchTextInputValue, onSearchBoxChange = _d.onSearchBoxChange, onSearchBoxKeyDown = _d.onSearchBoxKeyDown, onSearchBoxXClicked = _d.onSearchBoxXClicked;
    var table = useHealthChecksTable(pagedHealthChecks, sorting, setSorting, isPageLoading, isTableLoading, highlightSearchWords, showAlert).table;
    var onSettingsClicked = function () { return history.push("settings/health-check"); };
    var onNewClicked = function () { return (userType === "partner" ? history.push("/partner-tools/tenants/view/".concat(tenantGuid, "/health-checks/new")) : history.push("/health-checks/new")); };
    return {
        isPageLoading: isPageLoading || isUserCanCreateHealthCheckLoading,
        intl: intl,
        onSettingsClicked: onSettingsClicked,
        searchTextInputValue: searchTextInputValue,
        appliedFilters: appliedFilters,
        setAppliedFilters: setAppliedFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        isMobile: isMobile,
        healthChecksInDraftStateCount: healthChecksInDraftStateCount,
        showAlert: showAlert,
        table: table,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        totalHealthChecksCount: totalHealthChecksCount,
        pageTotal: pageTotal,
        pageOptions: pageOptions,
        isAlertLoading: isAlertLoading,
        onNewClicked: onNewClicked,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        canGenerateHealthCheck: canGenerateHealthCheck,
        canViewHealthCheckSettings: canViewHealthCheckSettings,
        FILTER_GROUPS: FILTER_GROUPS
    };
};
