var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import LinkComponent from "./Link";
//@ts-ignore
export var Element = function (props) {
    var attributes = props.attributes, children = props.children, element = props.element;
    switch (element.type) {
        case "link":
            return React.createElement(LinkComponent, __assign({ style: { margin: 0 } }, props));
        default:
            return (React.createElement("p", __assign({ style: {
                    margin: 0,
                    wordWrap: "break-word"
                } }, attributes), children));
    }
};
