import { Portlet, PortletBody } from "app/components/common/portlet";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import React from "react";
import { HealthChecksPage } from "./health-checks-page.component";
import { useTenantHealthChecksPage } from "./use-tenant-health-checks-page.hook";
export var TenantHealthChecksPage = function () {
    var _a = useTenantHealthChecksPage(), appliedFilters = _a.appliedFilters, clearAllFilters = _a.clearAllFilters, removeFilter = _a.removeFilter, setAppliedFilters = _a.setAppliedFilters, searchTextInputValue = _a.searchTextInputValue, onSettingsClicked = _a.onSettingsClicked, isPageLoading = _a.isPageLoading, intl = _a.intl, isMobile = _a.isMobile, healthChecksInDraftStateCount = _a.healthChecksInDraftStateCount, showAlert = _a.showAlert, table = _a.table, activePage = _a.activePage, setActivePage = _a.setActivePage, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, totalHealthChecksCount = _a.totalHealthChecksCount, pageTotal = _a.pageTotal, pageOptions = _a.pageOptions, isAlertLoading = _a.isAlertLoading, onNewClicked = _a.onNewClicked, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, onSearchBoxXClicked = _a.onSearchBoxXClicked, canGenerateHealthCheck = _a.canGenerateHealthCheck, canViewHealthCheckSettings = _a.canViewHealthCheckSettings, FILTER_GROUPS = _a.FILTER_GROUPS;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement(OrchestryPageHeader, null,
                React.createElement(OrchestryPageTitle, { isLoading: isPageLoading, title: intl.formatMessage({ id: "page.health_checks.title", defaultMessage: "Health Checks" }) }),
                React.createElement(OrchestryActionButtons, null,
                    canGenerateHealthCheck && (React.createElement(OrchestryButton, { onClick: onNewClicked, isLoading: isPageLoading, skeletonHeight: 34, skeletonWidth: 80, iconType: "font", size: "md", iconLeft: ORCH_ICONS.plusCircle, variant: "secondary-gray" }, intl.formatMessage({ id: "controls.new", defaultMessage: "New" }))),
                    canViewHealthCheckSettings && (React.createElement(OrchestryButton, { isLoading: isPageLoading, skeletonHeight: 34, skeletonWidth: 102, iconType: "font", size: "md", iconLeft: ORCH_ICONS.settings, variant: "secondary-gray", onClick: onSettingsClicked }, intl.formatMessage({ id: "controls.settings", defaultMessage: "Settings" }))))),
            React.createElement(HealthChecksPage, { appliedFilters: appliedFilters, clearAllFilters: clearAllFilters, removeFilter: removeFilter, setAppliedFilters: setAppliedFilters, searchTextInputValue: searchTextInputValue, onSettingsClicked: onSettingsClicked, isPageLoading: isPageLoading, intl: intl, isMobile: isMobile, healthChecksInDraftStateCount: healthChecksInDraftStateCount, showAlert: showAlert, table: table, activePage: activePage, setActivePage: setActivePage, resultsPerPage: resultsPerPage, setResultsPerPage: setResultsPerPage, totalHealthChecksCount: totalHealthChecksCount, pageTotal: pageTotal, pageOptions: pageOptions, isAlertLoading: isAlertLoading, onNewClicked: onNewClicked, onSearchBoxChange: onSearchBoxChange, onSearchBoxKeyDown: onSearchBoxKeyDown, onSearchBoxXClicked: onSearchBoxXClicked, canGenerateHealthCheck: canGenerateHealthCheck, canViewHealthCheckSettings: canViewHealthCheckSettings, FILTER_GROUPS: FILTER_GROUPS }))));
};
