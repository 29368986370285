import * as LibraryChannelCurrentStore from "app/store/library-channels/current";
import { AsyncCallType } from "app/store/async/types";
export var libraryChannelCurrentActionMap = [
    {
        actionType: LibraryChannelCurrentStore.LibraryChannelCurrentActionTypes.LibraryChannel_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "libraryChannelListState.items",
        keyType: "guid",
        ApiUrl: "/api/LibraryChannels/?guid={key}",
        responseDataType: {}
    }
];
