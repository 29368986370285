import { Card, Group, Skeleton, Stack, Text } from "@mantine/core";
import DynamicScollbar from "app/components/common/dynamic-scollbar";
import { OrchestryCheckbox } from "appv2/components/orchestry-checkbox/orchestry-checkbox.component";
import React from "react";
import { PartnersSettingsConsentedControl } from "./partners-settings-consented-control.component";
import { OrchestryFormErrorText } from "appv2/components/orchestry-form-error-text/orchestry-form-error-text.component";
import { useIntl } from "react-intl";
import "./partner-settings-consent-control.scss";
// eslint-disable-next-line import/no-webpack-loader-syntax
var htmlModule = require("raw-loader!../../..//assets/static/PartnerAssociationTermsofService.html");
var html = htmlModule.default;
export var PartnerSettingsConsentControl = function (_a) {
    var consentedBy = _a.consentedBy, consentedDate = _a.consentedDate, consentorPhoto = _a.consentorPhoto, isLoading = _a.isLoading, onClick = _a.onClick, isConsented = _a.isConsented, error = _a.error, onDisconnectTenantClicked = _a.onDisconnectTenantClicked, customerTenantName = _a.customerTenantName, partnerHubTenantName = _a.partnerHubTenantName;
    var _b = React.useState(false), termsReachedBottom = _b[0], setTermsReachedBottom = _b[1];
    var disabled = !termsReachedBottom;
    var intl = useIntl();
    var onDisconnectTenantClick = function () {
        onDisconnectTenantClicked === null || onDisconnectTenantClicked === void 0 ? void 0 : onDisconnectTenantClicked();
        setTermsReachedBottom(false);
    };
    if (isLoading) {
        return (React.createElement(Stack, { gap: 20 },
            React.createElement(Skeleton, { height: 200, w: "100%" }),
            React.createElement(Skeleton, { height: 20, w: "350px" }),
            React.createElement(Group, { gap: 8 },
                React.createElement(Skeleton, { height: "20px", w: "20px" }),
                React.createElement(Skeleton, { height: "20px", w: "172px" }))));
    }
    if (consentedBy) {
        return (React.createElement(PartnersSettingsConsentedControl, { customerTenantName: customerTenantName, partnerHubTenantName: partnerHubTenantName, isLoading: isLoading, consentedDate: consentedDate, consentedBy: consentedBy, consentorPhoto: consentorPhoto, onDisconnectTenantClicked: onDisconnectTenantClick }));
    }
    return (React.createElement("div", { className: "partner-settings-consent-control" },
        React.createElement(Stack, { gap: 16 },
            React.createElement(Card, { pl: 14, pr: 0, pt: 10, pb: 10, mah: "200px", style: { border: "1px solid var(--border)" }, className: "context-text-box" },
                React.createElement(DynamicScollbar, { maxHeight: 180, shouldShow: true, handleScrolledBottom: function () {
                        setTermsReachedBottom(true);
                    } },
                    React.createElement("div", { className: "scroll-inner-wrapper" },
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: html } })))),
            !termsReachedBottom && (React.createElement(OrchestryFormErrorText, { error: intl.formatMessage({
                    id: "controls.please_fully_read_terms",
                    defaultMessage: "Please fully read the terms of service to the bottom."
                }), className: "error-text" })),
            React.createElement(OrchestryCheckbox, { disabled: disabled, checked: isConsented, color: "var(--mantine-color-primary-0)", iconColor: "var(--mantine-color-primary-7)", className: "consent-checkbox", onClick: onClick, label: React.createElement(Text, { fz: "14px", fw: disabled ? 300 : 400, c: disabled ? "var(--mantine-color-tertiary-0)" : "var(--mantine-color-gray-7)", lh: "20px", className: "consent-text" }, "I consent to these terms") }),
            React.createElement(OrchestryFormErrorText, { error: error, className: "bottom-error-text" }))));
};
