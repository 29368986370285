import { Card, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { CardKeyValue } from "../card-key-value.component";
import { OrchestryCardTitle } from "../orchestry-card-title.component";
import { useTenantPage } from "../use-tenant-page.hook";
export var Hosting = function () {
    var _a = useTenantPage(), _b = _a.tenantDetails, PrivateCosmos = _b.PrivateCosmos, PrivateAzureTable = _b.PrivateAzureTable, PrivateSql = _b.PrivateSql, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, mih: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Hosting", icon: ORCH_ICONS.database03, colorVariant: "primary-7" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Private Cosmos", value: PrivateCosmos === null || PrivateCosmos === void 0 ? void 0 : PrivateCosmos.toString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Private Azure Table", value: PrivateAzureTable === null || PrivateAzureTable === void 0 ? void 0 : PrivateAzureTable.toString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Private SQL", value: PrivateSql === null || PrivateSql === void 0 ? void 0 : PrivateSql.toString() }))));
};
