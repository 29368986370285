var Check = /** @class */ (function () {
    function Check() {
    }
    Check.isStringUndefinedNullEmpty = function (varible) {
        var isEmpty = true;
        if (varible !== undefined && varible !== null && varible !== "") {
            isEmpty = false;
        }
        return isEmpty;
    };
    Check.isSubStringUndefinedNullEmpty = function (rootObject, subPropery) {
        var isEmpty = true;
        if (rootObject !== undefined && rootObject !== null && rootObject !== "") {
            if (subPropery !== undefined) {
                if (rootObject.hasOwnProperty(subPropery)) {
                    //@ts-ignore
                    var subPropert = rootObject[subPropery];
                    if (subPropert !== undefined && subPropert !== null && subPropert !== "") {
                        isEmpty = false;
                    }
                }
            }
        }
        return isEmpty;
    };
    Check.isArrayUndefinedNullEmpty = function (array) {
        if (array !== undefined && array !== null && array.length > 0) {
        }
    };
    Check.isObject = function (item) {
        return item && typeof item === "object" && !Array.isArray(item);
    };
    return Check;
}());
export default Check;
