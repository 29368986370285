import { Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryTooltipGroup } from "appv2/components/orchestry-tooltip-group/orchestry-tooltip-group.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { useIntl } from "react-intl";
export var ToDatePill = function (_a) {
    var tileTooltipId = _a.tileTooltipId, tileTooltipDefaultMessage = _a.tileTooltipDefaultMessage, isLoading = _a.isLoading, totalToDate = _a.totalToDate, badgeColor = _a.badgeColor, mt = _a.mt;
    var intl = useIntl();
    return (React.createElement(Group, { mt: mt }, tileTooltipId ? (React.createElement(OrchestryTooltip, { multiline: true, w: 300, label: intl.formatMessage({
            id: tileTooltipId,
            defaultMessage: tileTooltipDefaultMessage
        }) },
        React.createElement(OrchestryTooltipGroup, null,
            React.createElement(OrchestryBadge, { isLoading: isLoading, color: badgeColor, title: intl.formatMessage({
                    id: "controls.count_to_date",
                    defaultMessage: "{count} to date"
                }, { count: totalToDate === null || totalToDate === void 0 ? void 0 : totalToDate.toLocaleString() }) },
                React.createElement(OrchestryFontIcon, { isLoading: isLoading, fontSize: 14, iconClassName: ORCH_ICONS.infoCircle, color: badgeColor, lineHeight: "13px" }))))) : (React.createElement(OrchestryBadge, { isLoading: isLoading, color: badgeColor, title: intl.formatMessage({
            id: "controls.count_to_date",
            defaultMessage: "{count} to date"
        }, { count: totalToDate === null || totalToDate === void 0 ? void 0 : totalToDate.toLocaleString() }) }))));
};
