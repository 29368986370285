import { Grid, Group } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { AnonymousLinksTile } from "./tiles/anonymous-links-tile.component";
import { OrganizationalLinksTile } from "./tiles/organizational-links-tile.component";
import { SecureGuestsLinksTile } from "./tiles/secure-guest-links-tile.component";
import { SecureInternalLinksTile } from "./tiles/secure-internal-links-tile.component";
export var SharingLinksCreatedSection = function () {
    var _a = useHealthCheckActivity(), EndDate = _a.data.EndDate, intl = _a.intl, isLoading = _a.isLoading, isMobile = _a.isMobile;
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.SharingLinksCreated, renderListOfAnnotations: true, sectionTitle: React.createElement(Group, { gap: 8 },
            React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.sharing_links_created_section.title", defaultMessage: "Sharing Links" }) }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", size: "16px", lh: "24px", fw: 300 }, intl.formatMessage({ id: "totals_as_of_date", defaultMessage: "Totals as of {date}" }, { date: getReadableDate(EndDate) }))) },
        React.createElement(Grid, { mt: 8, columns: 4, grow: isMobile, gutter: "16px" },
            React.createElement(Grid.Col, { span: 1, miw: 230 },
                React.createElement(AnonymousLinksTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: 230 },
                React.createElement(OrganizationalLinksTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: 230 },
                React.createElement(SecureInternalLinksTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: 230 },
                React.createElement(SecureGuestsLinksTile, null)))));
};
