var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./directory-paging.scss";
import { useSelector, useDispatch } from "react-redux";
import * as DirectoryStore from "app/store/directory/search";
import { Form } from "react-bootstrap";
import Check from "app/utils/check";
import { FormattedMessage } from "react-intl";
var DirectoryPaging = React.memo(function () {
    var dispatch = useDispatch();
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var Paging = useSelector(function (state) { return state.directorySearchState; }).Paging;
    var _a = React.useState(true), loading = _a[0], setLoading = _a[1];
    React.useEffect(function () {
        setLoading(false);
    }, [directory]);
    React.useEffect(function () {
        window.scrollTo(0, 0);
    }, [Paging.CurrentPage]);
    var prevClick = function () {
        if (loading === false) {
            setLoading(true);
            if (directory.Paging.PrevPageUrls !== undefined && directory.Paging.PrevPageUrls.length > 0) {
                var newCurrentPageUrl = directory.Paging.PrevPageUrls[directory.Paging.PrevPageUrls.length - 1];
                var newCurrentPage = directory.Paging.CurrentPage !== undefined ? directory.Paging.CurrentPage - 1 : 1;
                console.log("newCurrentPage", newCurrentPage);
                var newPrevPageUrls = directory.Paging.PrevPageUrls;
                var remove = newPrevPageUrls.pop();
                console.log("new Previous page urls", newPrevPageUrls);
                //const newPrevPageUrls:string[] = [];
                var newNextPageUrl = directory.Paging.CurrentPageUrl;
                dispatch(DirectoryStore.UpdatePaging({
                    CurrentPageUrl: newCurrentPageUrl,
                    CurrentPage: newCurrentPage,
                    //@ts-ignore
                    PrevPageUrls: newPrevPageUrls,
                    NextPageUrl: newNextPageUrl
                }));
            }
        }
    };
    var nextClick = function () {
        if (loading === false) {
            setLoading(true);
            var newCurrentPageUrl = directory.Paging.NextPageUrl;
            var newCurrentPage = directory.Paging.CurrentPage !== undefined ? directory.Paging.CurrentPage + 1 : 1;
            var newPrevPageUrls = directory.Paging.PrevPageUrls !== undefined ? __spreadArray(__spreadArray([], directory.Paging.PrevPageUrls, true), [directory.Paging.CurrentPageUrl], false) : [directory.Paging.CurrentPageUrl];
            var newNextPageUrl = "loading";
            dispatch(DirectoryStore.UpdatePaging({
                CurrentPageUrl: newCurrentPageUrl,
                CurrentPage: newCurrentPage,
                //@ts-ignore
                PrevPageUrls: newPrevPageUrls,
                NextPageUrl: newNextPageUrl,
                ResultsPerPage: directory.Paging.ResultsPerPage
            }));
        }
    };
    var numberClick = function (pageNumberIndex) {
        console.log("paging", directory.Paging.CurrentPage, pageNumberIndex);
        if (directory.Paging.CurrentPage !== pageNumberIndex) {
            if (loading === false) {
                setLoading(true);
                //@ts-ignore
                var newCurrentPageUrl = directory.Paging.PrevPageUrls[pageNumberIndex];
                var newCurrentPage = pageNumberIndex + 1;
                //@ts-ignore
                var newPrevPageUrls = directory.Paging.PrevPageUrls.filter(function (pp, index) {
                    if (index < pageNumberIndex) {
                        return true;
                    }
                    return false;
                });
                var newNextPageUrl = "loading";
                dispatch(DirectoryStore.UpdatePaging({
                    CurrentPageUrl: newCurrentPageUrl,
                    CurrentPage: newCurrentPage,
                    //@ts-ignore
                    PrevPageUrls: newPrevPageUrls,
                    NextPageUrl: newNextPageUrl,
                    ResultsPerPage: directory.Paging.ResultsPerPage
                }));
            }
        }
    };
    var changePageSize = function (newSize) {
        dispatch(DirectoryStore.UpdatePaging({
            CurrentPage: 1,
            CurrentPageUrl: "",
            NextPageUrl: "",
            PrevPageUrls: [],
            TotalResults: 0,
            ResultsPerPage: Number(newSize)
        }));
    };
    return (React.createElement("div", { "data-pw": "directory-paging", className: "directory-paging" + (loading === true ? " dir-loading" : "") },
        React.createElement("div", { className: "left" },
            React.createElement("div", { className: "pages-per-result" },
                React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "component.directory.paging.records_per_page", defaultMessage: "Records per page" })),
                directory.Context.View === "List" ? (React.createElement(Form.Control, { as: "select", defaultValue: Paging.ResultsPerPage, onChange: function (e) {
                        changePageSize(e.currentTarget.value);
                    }, value: Paging.ResultsPerPage },
                    React.createElement("option", { value: 10 }, "10"),
                    React.createElement("option", { value: 20 }, "20"),
                    React.createElement("option", { value: 30 }, "30"),
                    React.createElement("option", { value: 50 }, "50"))) : (React.createElement(Form.Control, { as: "select", defaultValue: Paging.ResultsPerPage, onChange: function (e) {
                        changePageSize(e.currentTarget.value);
                    }, value: Paging.ResultsPerPage },
                    React.createElement("option", { value: 12 }, "12"),
                    React.createElement("option", { value: 24 }, "24"),
                    React.createElement("option", { value: 36 }, "36"),
                    React.createElement("option", { value: 72 }, "72"),
                    React.createElement("option", { value: 120 }, "120"))))),
        React.createElement("div", { className: "right" },
            React.createElement("div", { className: "button-wrapper bw-prev " + (Paging.PrevPageUrls !== undefined && Paging.PrevPageUrls.length > 0 ? "shown" : "hidden") },
                React.createElement("div", { className: "button-prev", onClick: function () { return prevClick(); } },
                    React.createElement(FormattedMessage, { id: "component.directory.paging.previous", defaultMessage: "Previous" }))),
            React.createElement("div", { className: "buttons-paging" },
                Paging.PrevPageUrls !== undefined
                    ? Paging.PrevPageUrls.map(function (prevPage, pageIndex) {
                        var _a, _b, _c;
                        return Paging.PrevPageUrls !== undefined && ((((_a = Paging.PrevPageUrls) === null || _a === void 0 ? void 0 : _a.length) > 3 && pageIndex > ((_b = Paging.PrevPageUrls) === null || _b === void 0 ? void 0 : _b.length) - 4) || ((_c = Paging.PrevPageUrls) === null || _c === void 0 ? void 0 : _c.length) < 4) ? (React.createElement("span", { key: pageIndex },
                            React.createElement("div", { className: "page-number", onClick: function () { return numberClick(pageIndex); } }, pageIndex + 1))) : null;
                    })
                    : null,
                Paging.CurrentPage !== 0 ? (React.createElement("span", null,
                    React.createElement("div", { className: "page-number current-number" + (Paging.PrevPageUrls !== undefined && Paging.PrevPageUrls.length === 0 ? " only-one" : ""), onClick: function () { return numberClick(Paging.CurrentPage !== undefined ? Paging.CurrentPage : 1); } }, Paging.CurrentPage))) : null),
            React.createElement("div", { className: "button-wrapper bw-next " + (!Check.isStringUndefinedNullEmpty(Paging.NextPageUrl) ? (Paging.NextPageUrl !== "loading" ? "shown" : "loading") : "hidden") },
                React.createElement("div", { className: "button-next ", onClick: function () { return nextClick(); } },
                    React.createElement(FormattedMessage, { id: "component.directory.paging.next", defaultMessage: "Next" }))))));
});
export default DirectoryPaging;
