import * as UserNotificationListStore from "app/store/user-notifications/list";
import { AsyncCallType } from "app/store/async/types";
export var userNotificationListActionMap = [
    {
        actionType: UserNotificationListStore.UserNotificationListActionTypes.UserNotification_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/notifications",
        responseDataType: ""
    },
    {
        actionType: UserNotificationListStore.UserNotificationListActionTypes.UserNotification_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/notifications",
        responseDataType: ""
    },
    {
        actionType: UserNotificationListStore.UserNotificationListActionTypes.UserNotification_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/notifications",
        responseDataType: true
    },
    {
        actionType: UserNotificationListStore.UserNotificationListActionTypes.UserNotification_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "userNotificationListState.items",
        keyType: "typeName",
        ApiUrl: "/api/notifications",
        responseDataType: {}
    },
    {
        actionType: UserNotificationListStore.UserNotificationListActionTypes.UserNotification_List_Refresh,
        callType: AsyncCallType.Refresh,
        stateLocationForArray: "userNotificationListState.items",
        keyType: "typeName",
        ApiUrl: "/api/notifications",
        responseDataType: {}
    },
    {
        actionType: UserNotificationListStore.UserNotificationListActionTypes.UserNotification_List_Load_Later_Than,
        callType: AsyncCallType.Refresh,
        stateLocationForArray: "userNotificationListState.items",
        keyType: "match",
        ApiUrl: "/api/notifications?afterDate={AfterDate}",
        responseDataType: []
    }
];
