import { Text } from "@mantine/core";
import { mapRecommendationPriorityToColor } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-control-section/recommendation-priority-indicator.utils";
import React from "react";
import { OrchestryIconLabel } from "../orchestry-icon-label/orchestry-icon-label.component";
import "./orchestry-pie-chart-legend.scss";
/**
 * Renders a legend for an OrchestryPieChart.
 */
export var OrchestryPieChartLegend = function (props) { return (React.createElement("div", { className: "orchestry-pie-chart-legend-container" }, props.legendItems.map(function (item) {
    var _a, _b, _c;
    return (React.createElement("div", { key: item.value, className: "orchestry-pie-chart-legend-item" },
        React.createElement("div", { className: "orchestry-pie-chart-legend-item-text", onClick: function () {
                if (props.onClick) {
                    props.onClick(item.value);
                }
            } },
            React.createElement(OrchestryIconLabel, { iconSize: item.iconConfig.size, iconVariant: item.iconConfig.variant, iconColorVariant: mapRecommendationPriorityToColor[item.value], iconClassName: item.iconConfig.className, textSize: item.iconConfig.size, textFW: ((_a = props.selectedLabel) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = item.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ? 700 : 400, textColorVariant: item.value, textLabel: item.label }),
            React.createElement(Text, { className: "orchestry-pie-chart-legend-item-count", size: item.iconConfig.size }, ((_c = item.count) !== null && _c !== void 0 ? _c : 0) > 0 ? item.count : "-"))));
}))); };
