import axios from "axios";
// import GraphService from 'app/services/graph';
// import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
var GroupsService = /** @class */ (function () {
    function GroupsService() {
    }
    GroupsService.currentGraphGroupSearches = [];
    GroupsService.searchGraphGroups = function (searchTerm, size) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (GroupsService.currentGraphGroupSearches.length > 0) {
                GroupsService.currentGraphGroupSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                GroupsService.currentGraphGroupSearches.push({
                    id: GroupsService.currentGraphGroupSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/Groups/SearchForGroupByName?queryText=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    GroupsService.getSuggestedGroups = function (size) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/users/PeopleSuggestions" + (size !== undefined ? "?size=" + size : "");
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    GroupsService.getGroupBase64Photo = function (uid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/teams/GetBase64Logo?uid=" + uid;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    GroupsService.getGroup = function (uid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/groups/GetById?groupId=" + uid;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    GroupsService.getGroupEnblement = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/groups/GetEnableGroupCreation";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return GroupsService;
}());
export default GroupsService;
