var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./lifecycle-wizard.scss";
import * as PageHeader from "app/components/common/page-header";
import FormButton, { FormButtons } from "app/components/common/form-button";
import { FormValidatorRollup } from "app/components/common/form-validation";
import { NavLink, Prompt } from "react-router-dom";
import { SubtitleButton } from "app/components/common/subtitle";
import { useSelector } from "react-redux";
var LifecycleWizard = React.memo(function (_a) {
    var config = _a.config, stepss = _a.stepss;
    var dottedLine = (React.createElement("div", { className: "dotted-line" }, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (number) { return (React.createElement("div", { key: number, className: "a-dot" })); })));
    var _b = React.useState(stepss), currentSteps = _b[0], setCurrentSteps = _b[1];
    var _c = React.useState([]), hasBeenCurrent = _c[0], setHasBeenCurrent = _c[1];
    var _d = React.useState(false), finishClicked = _d[0], setFinishClicked = _d[1];
    var _e = React.useState(true), showUnsafeToNavigatePrompt = _e[0], setShowUnsafeToNavigatePrompt = _e[1];
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var _f = React.useState(!currentSteps.some(function (cs) { return cs.complete === false; })), allStepsDone = _f[0], setallStepsDone = _f[1];
    var goTo = function (goto, key) {
        var tempSteps = __spreadArray([], currentSteps.map(function (s) { return (__assign({}, s)); }), true);
        if (goto === "key" && key !== undefined) {
            //console.log("Go To", key);
            tempSteps = tempSteps.map(function (s) { return (s.key === key ? __assign(__assign({}, s), { current: true }) : __assign(__assign({}, s), { current: false })); });
            window.scrollTo(0, 0);
            setCurrentSteps(tempSteps);
        }
        if (goto === "next") {
            currentSteps.forEach(function (step, sIndex) {
                if (sIndex !== currentSteps.length - 1) {
                    if (step.current === true) {
                        if (isStepHidden(currentSteps[sIndex + 1])) {
                            goTo("key", currentSteps[sIndex + 2].key);
                        }
                        else {
                            goTo("key", currentSteps[sIndex + 1].key);
                        }
                    }
                }
            });
        }
        if (goto === "prev") {
            currentSteps.forEach(function (step, sIndex) {
                if (sIndex !== 0) {
                    if (step.current === true) {
                        if (isStepHidden(currentSteps[sIndex - 1])) {
                            goTo("key", currentSteps[sIndex - 2].key);
                        }
                        else {
                            goTo("key", currentSteps[sIndex - 1].key);
                        }
                    }
                }
            });
        }
    };
    var setComplete = function (isComplete, key) {
        if (key !== undefined) {
            if (currentSteps.some(function (ts) { return ts.key === key && ts.complete === isComplete; }) === false) {
                setCurrentSteps(function () {
                    return currentSteps.map(function (s) {
                        return s.key === key ? __assign(__assign({}, s), { complete: isComplete }) : __assign({}, s);
                    });
                });
            }
        }
    };
    var setHidden = function (isHidden, key) {
        //console.log("setComplete Requested", key, isComplete);
        //console.log("setCompleteSteps", JSON.stringify(currentSteps.filter((s) => s.key === key).map((s) => ({ ...s, card: null }))));
        if (key !== undefined) {
            //if(currentSteps.some((ts) => ts.key === key && ts.complete === isComplete)){
            //console.log("setComplete", key, isComplete);
            setCurrentSteps(currentSteps.map(function (s) { return (s.key === key ? __assign(__assign({}, s), { hidden: isHidden }) : __assign({}, s)); }));
            //}
        }
    };
    var getCurrentStep = function () {
        var currentFound = (currentSteps.some(function (s) { return s.current === true; }) ? currentSteps.find(function (s) { return s.current === true; }) : currentSteps[0]);
        if (hasBeenCurrent.some(function (cs) { return cs === (currentFound === null || currentFound === void 0 ? void 0 : currentFound.key); }) === false) {
            setHasBeenCurrent(__spreadArray(__spreadArray([], hasBeenCurrent, true), [currentFound === null || currentFound === void 0 ? void 0 : currentFound.key], false));
        }
        return currentFound;
    };
    React.useEffect(function () {
        setallStepsDone(!currentSteps.some(function (cs) { return cs.complete === false; }));
        if (getCurrentStep().key === "notification" && currentPolicy.SecondStage_Enabled === false) {
            setCurrentSteps(currentSteps.map(function (s) { return (s.key === "secondstep" ? __assign(__assign({}, s), { complete: true }) : __assign({}, s)); }));
        }
        if (getCurrentStep().key === "secondstep" &&
            currentPolicy.SecondStage_Enabled === true &&
            currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary" &&
            currentPolicy.SecondStage_Option_Content_Archival_Location == null &&
            currentPolicy.SecondStage_Option_Content_Archival_Location_Library == null) {
            setCurrentSteps(currentSteps.map(function (s) { return (s.key === "secondstep" ? __assign(__assign({}, s), { complete: false }) : __assign({}, s)); }));
        }
    }, [JSON.stringify(currentSteps)]);
    var areAllStepsDone = function () {
        setallStepsDone(!currentSteps.some(function (cs) { return cs.complete === false; }));
    };
    var isStepHidden = function (step) {
        if (currentPolicy.Option_Content_Action === "Delete" && step.key === "actions") {
            return true;
        }
        if (!currentPolicy.SecondStage_Enabled && step.key === "secondstep") {
            return true;
        }
        return false;
    };
    return (React.createElement("div", { className: "lifecycle-wizard" },
        React.createElement(PageHeader.SetLeftContent, { title: config.title, subTitle: config.subTitle }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "".concat(config.navRouteString), onMouseEnter: function () { return setShowUnsafeToNavigatePrompt(false); }, onMouseLeave: function () { return setShowUnsafeToNavigatePrompt(true); } },
                React.createElement(SubtitleButton, { title: config.navTitle, faIcon: "arrow-left", variant: "secondary" }))),
        React.createElement(Prompt, { message: "Are you sure you want to leave and lose changes?", when: showUnsafeToNavigatePrompt }),
        React.createElement("div", { className: "lifecycle-wizard-wrapper" },
            React.createElement("div", { className: "w-header" }, currentSteps
                .filter(function (s) {
                if (currentPolicy.Option_Content_Action === "Delete" && s.key === "actions") {
                    return false;
                }
                else if (!currentPolicy.SecondStage_Enabled && s.key === "secondstep") {
                    return false;
                }
                else {
                    return s.card != null && s.hidden === false;
                }
            })
                .map(function (step, stepIndex, filteredSteps) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { key: step.key, className: "step-block" +
                        (getCurrentStep().key === step.key ? " selected" : hasBeenCurrent.some(function (bc) { return bc === step.key; }) === false ? " never-been-current" : step.complete ? " complete" : ""), onClick: function () { return (step.complete === true ? goTo("key", step.key) : null); }, "data-pw": "step-block" },
                    React.createElement("div", { className: "icon" },
                        React.createElement("i", { className: "fa fa-check" })),
                    React.createElement("div", { className: "text", "data-pw": "step-title" }, step.title),
                    React.createElement("div", { className: "errors-counter" },
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: step.key, HoverPlacement: "bottom" })),
                    React.createElement("div", { className: "dotted-lines" }, filteredSteps.length !== stepIndex + 1 ? dottedLine : null)))); })),
            React.createElement("div", { className: "w-body" },
                React.createElement("div", { className: "cards" }, currentSteps
                    .filter(function (s) { return s.card !== null && s.hidden === false && isStepHidden(s) === false; })
                    .map(function (step) { return (React.createElement("div", { key: step.key, className: "step-card fadein-1s" + (" card-" + step.key) + (getCurrentStep().key === step.key ? " selected" : "") },
                    React.createElement(step.card, { IsCurrent: step.current, StepKey: step.key, OnProceedChange: function (canProceed) { return setComplete(canProceed, step.key); } }))); })),
                React.createElement("div", { className: "bottom-buttons" },
                    React.createElement(FormButtons, null,
                        getCurrentStep().key !== currentSteps[0].key && React.createElement(FormButton, { text: config.buttonPreviousText, onClick: function () { return goTo("prev"); } }),
                        getCurrentStep().key !== currentSteps[currentSteps.length - 1].key ? (React.createElement(React.Fragment, null, getCurrentStep().complete === true ? (React.createElement(FormButton, { text: config.buttonNextText, theme: "blue", onClick: function () {
                                goTo("next");
                                areAllStepsDone();
                            } })) : (React.createElement(FormButton, { text: config.buttonNextText, theme: "gray" },
                            React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: getCurrentStep().key }))))) : (React.createElement(React.Fragment, null, allStepsDone === true && finishClicked === false ? (React.createElement(FormButton, { text: config.buttonFinishText, theme: "blue", onClick: function () {
                                setShowUnsafeToNavigatePrompt(false);
                                config.onSave();
                                setFinishClicked(true);
                            } })) : (React.createElement(FormButton, { text: config.buttonFinishText, theme: "gray" }))))))))));
}, function (prevProps, nextProps) {
    return JSON.stringify(prevProps.stepss) !== JSON.stringify(nextProps.stepss);
});
export default LifecycleWizard;
