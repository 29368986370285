import * as React from "react";
import "./principal-item.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faLock } from "@fortawesome/free-solid-svg-icons";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import InfoTooltip from "../infotooltip";
import { useIntl, FormattedMessage } from "react-intl";
var PrincipalItem = React.memo(function (_a) {
    var _b;
    var principal = _a.principal, showBadge = _a.showBadge, onRemove = _a.onRemove, showBorder = _a.showBorder, size = _a.size, style = _a.style, base64image = _a.base64image, className = _a.className, fontIconOverride = _a.fontIconOverride, hideGuestNote = _a.hideGuestNote;
    var intl = useIntl();
    var remove = function (user) {
        if (onRemove !== null && onRemove !== undefined) {
            onRemove(user);
        }
    };
    return style === undefined || style === "normal" ? (React.createElement("div", { className: "principal-item" +
            (onRemove === undefined ? " hide-remove" : "") +
            (showBorder !== null && showBorder !== undefined && showBorder === false ? " no-border" : "") +
            (size !== null && size !== undefined ? " size-" + size : "") +
            (className !== null && className !== undefined ? " " + className : ""), key: principal.GUID },
        React.createElement("div", { className: "pic" },
            showBadge !== null && showBadge !== undefined && showBadge ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "principal-type type-" + ((_b = principal.Type) === null || _b === void 0 ? void 0 : _b.toLowerCase()) }, principal.Type === "User" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.user_label", defaultMessage: "User" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.user_acronym", defaultMessage: "U" }))) : principal.Type === "Group" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.group_label", defaultMessage: "Group" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.group_acronym", defaultMessage: "G" }))) : principal.Type === "SecurityGroup" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.security_label", defaultMessage: "Security Group" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.security_acronym", defaultMessage: "S" }))) : principal.Type === "DistributionGroup" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.distribution_label", defaultMessage: "Distribution List" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.distribution_acronym", defaultMessage: "D" }))) : ("G")))) : null,
            React.createElement(OfficeProfilePicture, { base64image: base64image, uid: principal.GUID, displayName: principal.DisplayName, type: principal.Type, fontIconOverride: fontIconOverride })),
        React.createElement("span", null,
            principal.DisplayName,
            hideGuestNote !== undefined && hideGuestNote !== null && hideGuestNote === true ? null : (React.createElement(React.Fragment, null, principal.UserPrincipalName !== null && principal.UserPrincipalName !== undefined && principal.UserPrincipalName.indexOf("#EXT#") > -1 && (React.createElement("span", { className: "guest-note" }, "(Guest)"))))),
        principal.Locked === true ? (React.createElement("div", { className: "locked" },
            React.createElement(FontAwesomeIcon, { icon: faLock }))) : (
        // <div className="remove" onClick={() => {removeOwner(user)}}><FontAwesomeIcon icon={Icons.faTimes} /></div>
        React.createElement(React.Fragment, null, onRemove !== null && onRemove !== undefined ? (React.createElement("div", { className: "remove", onClick: function () {
                remove(principal);
            } },
            React.createElement(FontAwesomeIcon, { icon: faTimes }))) : null)))) : (React.createElement("div", { className: "principal-item-pic" + (size !== null && size !== undefined ? " size-" + size : "") + (className !== null && className !== undefined ? " " + className : ""), key: principal.GUID },
        React.createElement(InfoTooltip, { content: principal.DisplayName },
            React.createElement("div", { className: "pic" },
                React.createElement(OfficeProfilePicture, { uid: principal.GUID, displayName: principal.DisplayName, type: principal.Type, fontIconOverride: fontIconOverride })))));
});
export default PrincipalItem;
