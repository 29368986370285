var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LifecyclePolicyWorkspaceCurrentActionTypes } from "./types";
import { AsyncActionTypes } from "app/store/async/types";
import moment from "moment";
var initialState = {
    item: null,
    history: null,
    stats: null,
    assignAblePolicies: null,
    links: null,
    logs: null,
    members: null,
    defaultPolicies: null,
    reportItem: null,
    overrideStatus: null,
    isNonActiveStatusLoading: null,
    archivalPolicy: null,
    guestRequestPolicy: null,
    guestReviewPolicy: null,
    directoryItem: null,
    forceETLCrawlIsLoading: null,
    EtlCrawlNext: false
};
window.lifecyclePolicyWorkspacesCurrentReducerUpdating = false;
export function lifecyclePolicyWorkspacesCurrentReducer(state, action) {
    //console.log("lifecyclePolicyWorkspacesCurrentReducerUpdating", window.lifecyclePolicyWorkspacesCurrentReducerUpdating);
    if (state === void 0) { state = initialState; }
    if (action.type === AsyncActionTypes.Async_Start || action.type === AsyncActionTypes.Async_Succeed) {
        return state;
    }
    var getState = function () {
        var _a, _b;
        switch (action.type) {
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load: {
                //console.log(LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load, action);
                return __assign(__assign({}, state), { item: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Status: {
                if (action.payload !== undefined) {
                    var properties = {};
                    var newStats = action.payload.ArchivalStatus;
                    // let shouldUpdate = true;
                    // let removeOverrideState = false;
                    // if (state.overrideStatus != null && state.overrideStatus.CurrentStatus == newStats) {
                    //    // do nothing. Don't update it because we are keeping the override.
                    //    shouldUpdate = false;
                    // }
                    // if (state.overrideStatus != null && state.overrideStatus.CurrentStatus != newStats) {
                    //    removeOverrideState = true
                    // }
                    // if (shouldUpdate) {
                    var showLoading = state.isNonActiveStatusLoading;
                    // console.log("Reducer Load Status", properties.ArchivalStatus != newStats)
                    if (((_a = state.item) === null || _a === void 0 ? void 0 : _a.ArchivalStatus) !== newStats) {
                        showLoading = false;
                    }
                    properties.ArchivalStatus = newStats;
                    properties.ArchivalJobId = action.payload.ArchivalJobId;
                    properties.HistoryCount = action.payload.HistoryCount;
                    if (properties.ArchivalStatus === "Done" && ((_b = state.item) === null || _b === void 0 ? void 0 : _b.ArchivalArchivedDate) === null) {
                        properties.ArchivalArchivedDate = moment.utc().format();
                    }
                    // }
                    return __assign(__assign({}, state), { 
                        //overrideStatus: removeOverrideState ? null : { ...state.overrideStatus },
                        isNonActiveStatusLoading: showLoading, item: __assign(__assign({}, state.item), properties) });
                }
                else {
                    return __assign({}, state);
                }
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Stats: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { stats: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_History: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { history: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Logs: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { logs: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Links: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { links: Object.assign({}, state.links, action.payload) });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_NotebookAndPlanner: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { links: Object.assign({}, state.links, action.payload) });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Members: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { members: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Members_ByGroupId: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { members: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_AvailablePolicies: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { assignAblePolicies: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_DefaultPolicies: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                return __assign(__assign({}, state), { defaultPolicies: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_ReportItem: {
                return __assign(__assign({}, state), { reportItem: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_ArchivalPolicy: {
                return __assign(__assign({}, state), { archivalPolicy: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_ReportItem: {
                return __assign(__assign({}, state), { reportItem: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_NextETLCrawl: {
                if (state.item !== null)
                    state.item.EtlCrawlNext = true;
                return __assign(__assign({}, state), { forceETLCrawlIsLoading: false });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_IsLoading: {
                state.forceETLCrawlIsLoading = action.payload;
                return state;
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update ||
                LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_Properties_NoServer:
                {
                    console.log("Update Policy", state.item, action.payload);
                    var properties = __assign({}, action.payload);
                    // let incomingStatus = properties.ArchivalStatus;
                    // let shouldOverrideStatus = true;
                    // let removeOverrideState = false;
                    // if (state.overrideStatus != null && state.overrideStatus.CurrentStatus == incomingStatus) {
                    //    // do nothing. Don't update it because we are keeping the override.
                    //    shouldOverrideStatus = true;
                    // }
                    // if (state.overrideStatus != null && state.overrideStatus.CurrentStatus != incomingStatus) {
                    //    removeOverrideState = true
                    // }
                    // if (shouldOverrideStatus) {
                    //    properties.ArchivalStatus = state.overrideStatus?.OverrideStatus;
                    // }
                    return __assign(__assign({}, state), { item: __assign(__assign({}, state.item), properties) });
                    // const updatedLifecyclePolicy = Object.assign({}, action.payload);
                    // return {
                    //    ...state,
                    //    item: updatedLifecyclePolicy
                    // }
                }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Clear: {
                return __assign({}, initialState);
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_WorkflowSubmit: {
                if (action.payload !== undefined) {
                    // let properties: any | LifecyclePolicyWorkspace = {
                    //    ArchivalStatus: action.payload.ReturnStatus
                    // }
                    return __assign(__assign({}, state), { 
                        // overrideStatus: {
                        //    CurrentStatus: state.item?.ArchivalStatus,
                        //    OverrideStatus: action.payload.ReturnStatus
                        // },
                        item: __assign({}, state.item //,
                        //...properties
                        ) });
                }
                else {
                    return __assign({}, state);
                }
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Set_Status_Override: {
                return __assign(__assign({}, state), { overrideStatus: __assign({}, action.payload) });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Set_LoadingNewStatus: {
                return __assign(__assign({}, state), { isNonActiveStatusLoading: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_DirectoryItem: {
                return __assign(__assign({}, state), { directoryItem: action.payload });
            }
            case LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Clear_DirectoryItem: {
                return __assign(__assign({}, state), { directoryItem: null });
            }
            default:
                return state;
        }
    };
    // const overrideStatusIfNeeded = (state:LifecyclePolicyWorkspaceCurrentState, newStatus:string) => {
    // }
    var wait = function () {
        if (window.lifecyclePolicyWorkspacesCurrentReducerUpdating === false) {
            //console.log("wait done " + action.type)
            window.lifecyclePolicyWorkspacesCurrentReducerUpdating = true;
            var newstate = getState();
            window.lifecyclePolicyWorkspacesCurrentReducerUpdating = false;
            return newstate;
        }
        else {
            //console.log("waiting for " + action.type)
            setTimeout(function () {
                return wait();
            }, 50);
        }
    };
    return wait();
}
