var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggleControl from "app/components/common/form-toggle-control";
import OrchModal from "app/components/common/orch-modal";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import * as TableList from "app/components/common/table-list";
import InstallCodesService from "app/services/install-codes";
import * as TenantListStore from "app/store/tenants/list";
import generateGuid from "app/utils/generateGuid";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./orchestry-install-codes.scss";
var OrchestryInstallCodes = React.memo(function () {
    var dispatch = useDispatch();
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _a = React.useState(null), codes = _a[0], setCodes = _a[1];
    var _b = React.useState(false), isEditOpen = _b[0], setIsEditOpen = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = React.useState(), currentCode = _d[0], setCurrentCode = _d[1];
    var _e = React.useState(), currentCodeToListTenants = _e[0], setCurrentCodeToListTenants = _e[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        refreshCodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var refreshCodes = function () {
        setIsLoading(true);
        InstallCodesService.GetCodes().then(function (codes) {
            setIsLoading(false);
            setCodes(codes);
        });
    };
    var saveCurrent = function () {
        InstallCodesService.AddUpdate(currentCode).then(function (code) {
            setCurrentCode(null);
            setIsEditOpen(false);
            refreshCodes();
        });
        setIsEditOpen(false);
    };
    var getTenantsWhereInstallCodeWasUsed = function (installCodeGuid) {
        return tenants.filter(function (t) { return t.InstallCode === installCodeGuid; });
    };
    var editForm = function () {
        return (React.createElement(React.Fragment, null, currentCode !== null && currentCode !== undefined ? (React.createElement(React.Fragment, null,
            React.createElement(Form.Group, { className: "form-group" },
                React.createElement(Form.Label, null,
                    "Tenant ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(FormDropdownSelect, { enableSearch: false, value: currentCode === null || currentCode === void 0 ? void 0 : currentCode.OwnedByTenantGuid, list: tenants.map(function (t, tNumber) {
                        return ({
                            id: tNumber.toString(),
                            title: t.DisplayName,
                            value: t.GUID
                        });
                    }), onChange: function (value) {
                        setCurrentCode(__assign(__assign({}, currentCode), { OwnedByTenantGuid: value }));
                    } })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                React.createElement(Form.Label, null,
                    "Code Description ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(Form.Control, { placeholder: "description", autoComplete: "off", defaultValue: currentCode.Description, onChange: function (event) { return setCurrentCode(__assign(__assign({}, currentCode), { Description: event.target.value })); } })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                React.createElement(Form.Label, null, "Code Source"),
                React.createElement(FormDropdownSelect, { onChange: function (value) { return setCurrentCode(__assign(__assign({}, currentCode), { CodeSource: value })); }, value: currentCode.CodeSource, defaultValue: currentCode.CodeSource || "Orchestry", list: [
                        { id: "0", title: "Orchestry", value: "Orchestry" },
                        { id: "1", title: "Partner", value: "Partner" }
                    ] })),
            React.createElement(FormToggleControl, { checked: currentCode === null || currentCode === void 0 ? void 0 : currentCode.Enabled, label: "Is the code enabled?", handleChange: function (checked) { return setCurrentCode(__assign(__assign({}, currentCode), { Enabled: checked })); } }),
            React.createElement(SubtitleButton, { title: "Save", onClick: function () { return saveCurrent(); } }))) : (React.createElement(SkeletonLoaderFakeBox, null))));
    };
    return (React.createElement("div", { className: "home-admin fadein-500ms tenant-list" },
        React.createElement(Subtitle, { subtitle: React.createElement(React.Fragment, null, "List") },
            React.createElement(OrchModal, { onOpen: function () {
                    return setCurrentCode({
                        Enabled: true,
                        GUID: generateGuid(),
                        Archived: false,
                        Description: "",
                        TenantName: null,
                        OwnedByTenantGuid: null,
                        TimesUsed: 0
                    });
                }, showModalOverride: isEditOpen, showModalToggleOverride: function (show) { return setIsEditOpen(show); }, buttonContents: React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fa fa-plus" }),
                    " Add Code") }, editForm())),
        React.createElement(Row, null,
            React.createElement(Col, null,
                isLoading === true ? (React.createElement(Portlet, null,
                    React.createElement(PortletBody, null,
                        React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 30 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 40 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 30 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })))))) : (React.createElement(TableList.Context, { listItems: codes === null ? undefined : codes, showLoader: codes === null ? true : false },
                    React.createElement(TableList.TableList, null,
                        React.createElement(TableList.HeaderItem, null, "Tenant Name"),
                        React.createElement(TableList.HeaderItem, null, "Code"),
                        React.createElement(TableList.HeaderItem, null, "Code Description"),
                        React.createElement(TableList.HeaderItem, null, "Code Issuer"),
                        React.createElement(TableList.HeaderItem, { type: "centered" }, "Enabled"),
                        React.createElement(TableList.HeaderItem, { type: "actions" }),
                        React.createElement(TableList.ContentItemCustomRender, { isMobileTitle: true, render: function (item) {
                                var _a;
                                return (React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: "tenant-title" }, (_a = tenants.find(function (t) { return t.GUID === item.OwnedByTenantGuid; })) === null || _a === void 0 ? void 0 : _a.DisplayName),
                                    React.createElement("div", { className: "tenant-guid" }, item.OwnedByTenantGuid)));
                            } }),
                        React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "GUID" }),
                        React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                        React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "CodeSource" }),
                        React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Enabled" }),
                        React.createElement(TableList.ContentItemActions, { customActionIcon: "fa fa-list", onCustomAction: function (item) {
                                setCurrentCodeToListTenants(item);
                            }, onEdit: function (item) {
                                setCurrentCode(item);
                                setIsEditOpen(true);
                            } })))),
                React.createElement(OrchModal, { showModalOverride: currentCodeToListTenants !== null && currentCodeToListTenants !== undefined, showModalToggleOverride: function (show) {
                        if (show === false) {
                            setCurrentCodeToListTenants(null);
                        }
                    }, headerText: "Tenants Installed Using Code" }, currentCodeToListTenants !== null && currentCodeToListTenants !== undefined
                    ? getTenantsWhereInstallCodeWasUsed(currentCodeToListTenants === null || currentCodeToListTenants === void 0 ? void 0 : currentCodeToListTenants.GUID).map(function (t) { return React.createElement("div", null, t.DisplayName); })
                    : null)))));
});
export default OrchestryInstallCodes;
