import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import clsx from "clsx";
import React from "react";
import "./recommendation-card-badge-group.scss";
import { RecommendationCardCategoryBadge } from "./recommendation-card-category-badge";
import { RecommendationCardProductBadge } from "./recommendation-card-product-badge";
/**
 * Renders the badge group for the recommendation card.
 */
export var RecommendationCardBadgeGroup = function (_a) {
    var _b, _c, _d, _e;
    var recommendation = _a.recommendation, badgeOnClick = _a.badgeOnClick, isLoading = _a.isLoading;
    var categoryGuids = ((_c = (_b = recommendation === null || recommendation === void 0 ? void 0 : recommendation.RecommendationRecord) === null || _b === void 0 ? void 0 : _b.Definition_Categories) === null || _c === void 0 ? void 0 : _c.split("|")) || [];
    var productGuids = ((_e = (_d = recommendation === null || recommendation === void 0 ? void 0 : recommendation.RecommendationRecord) === null || _d === void 0 ? void 0 : _d.Definition_Products) === null || _e === void 0 ? void 0 : _e.split("|")) || [];
    var isMobile = useIsMobile();
    return (React.createElement("div", { className: clsx("recommendation-card-badge-group", { "recommendation-card-badge-group-mobile": isMobile }) },
        React.createElement("div", { className: isMobile ? "category-group-mobile" : "category-group" }, categoryGuids.map(function (categoryGuid) { return (React.createElement(RecommendationCardCategoryBadge, { isLoading: isLoading, key: categoryGuid, categoryGuid: categoryGuid, onClick: badgeOnClick })); })),
        React.createElement("div", { className: "product-group" }, productGuids.map(function (productGuid) { return (React.createElement(RecommendationCardProductBadge, { key: productGuid, productGuid: productGuid, onClick: badgeOnClick })); }))));
};
