import * as React from "react";
import "./naming-policy-list.scss";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as NamingPolicyListStore from "app/store/naming-policies/list";
import * as TableList from "app/components/common/table-list";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
var NamingPolicyList = React.memo(function () {
    var dispatch = useDispatch();
    var namingPolicies = useSelector(function (state) { return state.namingPolicyListState.items; });
    console.log("NamingPolicy", namingPolicies);
    React.useEffect(function () {
        dispatch(NamingPolicyListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: "naming-policy-list" },
        React.createElement(PageHeader.SetLeftContent, null,
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find naming policies" })),
        React.createElement(Subtitle, { subtitle: "List" },
            React.createElement(NavLink, { exact: true, to: "/workspaces/naming-policies/new" },
                React.createElement(SubtitleButton, { title: "New Naming Policy", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: namingPolicies, asyncListLoadUID: NamingPolicyListStore.NamingPolicyListActionTypes.NamingPolicy_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Policy Name"),
                React.createElement(TableList.HeaderItem, null, "Policy Description"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/workspaces/naming-policies/edit/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/naming-policies/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/naming-policies/edit/{GUID}"
                    onRemove: function (item) { return dispatch(NamingPolicyListStore.DeleteItem(item)); }, deleteCheckType: "naming-policy" })))));
});
export default NamingPolicyList;
