import React from "react";
import { GuestManagementUserStatus } from "app/store/lifecycle-guest";
import moment from "moment";
import "./guest-status-box.scss";
var GuestStatusBox = React.memo(function (props) {
    var status = GetGuestStatusEnum(props.cosmosUser, props.daysInactive);
    var getClassName = function () {
        return status.toLowerCase();
    };
    return React.createElement("div", { "data-pw": "guest-status", className: "guest-status-box" + (props.useDefaultStyles === true ? " guest-status-box-default-styles " : " ") + "guest-status-" + getClassName() }, props.children);
});
export default GuestStatusBox;
export var GetGuestStatusEnum = function (cosmosUser, daysInactive) {
    var returnStatus = GuestManagementUserStatus.Active;
    if (cosmosUser.Status === GuestManagementUserStatus.Active) {
        //let daysInactiveDate = moment.utc().add(daysInactive * -1, "day").date;
        if (cosmosUser.LastActivity === null) {
            if (cosmosUser.CreatedDateTime !== null) {
                if (moment.utc(cosmosUser.CreatedDateTime).diff(moment.utc(), "day") <= daysInactive * -1) {
                    returnStatus = GuestManagementUserStatus.Inactive;
                }
            }
            else {
                returnStatus = GuestManagementUserStatus.Inactive;
            }
        }
        else if (moment.utc(cosmosUser.LastActivity).diff(moment.utc(), "day") <= daysInactive * -1) {
            returnStatus = GuestManagementUserStatus.Inactive;
        }
    }
    else if (cosmosUser.Status !== null) {
        returnStatus = cosmosUser.Status;
    }
    return returnStatus;
};
