var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DonutChart } from "@mantine/charts";
import "@mantine/charts/styles.css";
import { Center, Skeleton } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { OrchestryChartDeltaTooltip } from "../orchestry-chart-delta-tooltip/orchestry-chart-delta-tooltip.component";
/**
 * Renders a donut chart.
 */
export var OrchestryDonutChart = function (_a) {
    var lastPeriodData = _a.lastPeriodData, isLoading = _a.isLoading, formatNumberAndText = _a.formatNumberAndText, props = __rest(_a, ["lastPeriodData", "isLoading", "formatNumberAndText"]);
    var _b = useState({ marginLeft: 0, marginTop: 0 }), tooltipMargin = _b[0], setTooltipMargin = _b[1];
    var handleMouseMove = function (event) {
        var rect = event.currentTarget.getBoundingClientRect();
        var centerX = rect.width / 2;
        var centerY = rect.height / 2;
        var mouseX = event.clientX - rect.left;
        var mouseY = event.clientY - rect.top;
        var dx = mouseX - centerX;
        var dy = mouseY - centerY;
        var distance = Math.sqrt(dx * dx + dy * dy);
        var offset = 100;
        var marginLeft = (dx / distance) * offset;
        var marginTop = (dy / distance) * offset;
        setTooltipMargin({ marginLeft: marginLeft, marginTop: marginTop });
    };
    useEffect(function () {
        if (formatNumberAndText) {
            var observer_1 = new MutationObserver(function () {
                var label = document.querySelector(".mantine-DonutChart-label");
                if (label === null || label === void 0 ? void 0 : label.textContent) {
                    var _a = label.textContent.split(" "), number = _a[0], text = _a[1];
                    if (!label.innerHTML.includes('data-key="formatted"')) {
                        label.innerHTML = "<tspan x=\"50%\" dy=\"-0.3em\" data-key=\"formatted\" style=\"font-size: 20px; font-weight: 500; line-height: 30px\">".concat(number, "</tspan><tspan x=\"50%\" dy=\"1.9em\" style=\"color: var(--mantine-color-text-tertiary-0); font-size: 12px; font-weight: 400; line-height: 18px\">").concat(text, "</tspan>");
                    }
                }
            });
            observer_1.observe(document.body, { childList: true, subtree: true });
            // Clean up the observer when the component unmounts
            return function () { return observer_1.disconnect(); };
        }
    });
    if (isLoading) {
        return (React.createElement(Center, null,
            React.createElement(Skeleton, { h: props.h, w: props.w, circle: true })));
    }
    return (React.createElement("div", { onMouseMove: handleMouseMove },
        React.createElement(DonutChart, __assign({ tooltipProps: lastPeriodData
                ? {
                    content: function (_a) {
                        var label = _a.label, payload = _a.payload;
                        return React.createElement(OrchestryChartDeltaTooltip, { label: label, payload: payload, lastPeriodData: lastPeriodData });
                    },
                    wrapperStyle: __assign({}, tooltipMargin)
                }
                : undefined }, props), props.children)));
};
