import { AsyncActionTypes } from "app/store/async/types";
import * as TemplateListStore from "app/store/templates/list";
export var templateNotificationConfigMap = [
    {
        ActionType: AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to templates list...",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Title}</strong> in templates list...",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Title}</strong> from templates list...",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to templates list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Add
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully updated in templates list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Update
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully deleted from templates list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to templates list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Title}</strong> to templates list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Title}</strong> from templates list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Item_Delete
            },
            {
                Text: "Error when trying to load template list.",
                ActionType: TemplateListStore.TemplateListActionTypes.Template_List_Load
            }
        ]
    }
];
