import { Grid, Stack } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { GrowthChartTile } from "./tiles/growth-chart-tile.component";
import { GrowthDonutChartTile } from "./tiles/growth-donut-chart-tile.component";
export var WorkspacesSection = function (_a) {
    var hasOrchestryUsage = _a.hasOrchestryUsage;
    var _b = useHealthCheckActivity(), intl = _b.intl, isLoading = _b.isLoading;
    return (React.createElement(Stack, { mt: hasOrchestryUsage ? 48 : 24 },
        React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.Workspaces, renderListOfAnnotations: true, sectionTitle: React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.workspaces_section.title", defaultMessage: "Workspaces" }) }) },
            React.createElement(Grid, { grow: true, gutter: "16px", columns: 12 },
                React.createElement(Grid.Col, { span: 6 },
                    React.createElement(GrowthChartTile, null)),
                React.createElement(Grid.Col, { span: 6 },
                    React.createElement(GrowthDonutChartTile, null))))));
};
