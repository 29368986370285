import { Card, Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { roundAndLocalizeNumber } from "appv2/utils/number.utils";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var ManagingGuestsTile = function (_a) {
    var isBeaconLicense = _a.isBeaconLicense;
    var _b = useHealthCheckActivity(), intl = _b.intl, isLoading = _b.isLoading, _c = _b.data, _d = _c === void 0 ? {} : _c, _e = _d.MetricsData, _f = _e === void 0 ? {} : _e, _g = _f.YourSavings, _h = _g === void 0 ? {} : _g, ManagingGuests = _h.ManagingGuests;
    var count = isBeaconLicense ? 99 : ManagingGuests !== null && ManagingGuests !== void 0 ? ManagingGuests : 0;
    return (React.createElement(Card, { h: "100%", variant: "no-border", p: 16, maw: 213 },
        React.createElement(Group, { gap: "4px" },
            React.createElement(OrchestryFontIcon, { isLoading: isLoading, fontSize: 24, lineHeight: "24px", color: "var(--mantine-color-success-7)", iconClassName: ORCH_ICONS.hourglass03 }),
            React.createElement(OrchestryHighlight, { isLoading: isLoading, c: "text-primary.0", fz: "12", fw: 300, lh: "18px", highlight: ["".concat(roundAndLocalizeNumber(count, 2))], highlightStyles: {
                    padding: 0,
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "32px",
                    backgroundColor: "var(--mantine-color-text-primary-0)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent"
                } }, intl.formatMessage({ id: "num_hours", defaultMessage: "{count} hours" }, { count: roundAndLocalizeNumber(count, 2) }))),
        React.createElement(OrchestryText, { mt: 16, isLoading: isLoading, c: "text-secondary.0", fz: "14", fw: 400, lh: "20px" }, intl.formatMessage({ id: "page.health_check.total_hours_saved_tile.managing_guests", defaultMessage: "Managing Guests" }))));
};
