import { Group, Stack } from "@mantine/core";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import "./orchestry-page-title.scss";
export var OrchestryPageTitle = function (_a) {
    var title = _a.title, subTitle = _a.subTitle, isLoading = _a.isLoading, titleSkeletonWidth = _a.titleSkeletonWidth, children = _a.children, titleIcon = _a.titleIcon, titleIconColor = _a.titleIconColor, titleSuffix = _a.titleSuffix;
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { gap: 8, miw: 300 },
            React.createElement(Group, { gap: 8 },
                titleIcon && React.createElement(OrchestryFontIcon, { isLoading: isLoading, iconClassName: titleIcon, color: titleIconColor !== null && titleIconColor !== void 0 ? titleIconColor : "", fontSize: 24, lineHeight: "24px" }),
                React.createElement(OrchestryText, { c: "text-primary.0", fw: "500", size: "24px", lh: "30px", isLoading: isLoading, skeletonWidth: titleSkeletonWidth !== null && titleSkeletonWidth !== void 0 ? titleSkeletonWidth : 260 }, title),
                titleSuffix),
            !!subTitle && (React.createElement(OrchestryText, { c: "text-primary.0", fw: "500", size: "14px", lh: "20px", isLoading: isLoading }, subTitle))),
        children));
};
