import { LibraryTemplateCurrentActionTypes } from "app/store/library-templates/current/types";
import { AsyncCallType } from "app/store/async/types";
export var libraryTemplateCurrentActionMap = [
    {
        actionType: LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "libraryTemplateListState.items",
        keyType: "guid",
        ApiUrl: "/api/LibraryTemplates/?guid={key}",
        responseDataType: {}
    }
];
