import AnimatedLoader from "app/components/common/animated-loader";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import TeamsService from "app/services/teams";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import "./request-details-channels.scss";
var RequestDetailsChannels = React.memo(function (props) {
    var intl = useIntl();
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var _a = React.useState([]), cloneTeamChannels = _a[0], setCloneTeamChannels = _a[1];
    var _b = React.useState(false), loadingLiveTemplateChannels = _b[0], setLoadingLiveTemplateChannels = _b[1];
    React.useEffect(function () {
        var isLiveTeam = false;
        if (currentRequest.RequestType === "Team" && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.GUID) !== undefined) {
            if (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LiveTemplateEnabled) {
                if ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LiveTemplateTeamID) !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LiveTemplateTeamID) !== null) {
                    var teamObject = JSON.parse(currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LiveTemplateTeamID);
                    if (teamObject !== undefined && teamObject.GUID !== undefined) {
                        setLoadingLiveTemplateChannels(true);
                        TeamsService.GetTeamChannels(teamObject.GUID).then(function (channels) {
                            setLoadingLiveTemplateChannels(false);
                            setCloneTeamChannels(channels);
                            if (currentRequest.RequestChannels !== undefined && Array.isArray(currentRequest.RequestChannels) && Array.isArray(channels)) {
                                props.SetChannelsCount(currentRequest.RequestChannels.length + channels.length);
                                isLiveTeam = true;
                            }
                        });
                    }
                }
            }
            else {
            }
        }
        if (isLiveTeam === false && currentRequest.RequestChannels !== undefined && Array.isArray(currentRequest.RequestChannels)) {
            props.SetChannelsCount(currentRequest.RequestChannels.length);
        }
    }, [currentTemplate]);
    return (React.createElement(Portlet, { fluidHeight: true, className: "channels-portlet" },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.channels.title", defaultMessage: "Channels" }) }),
        React.createElement(PortletBody, null, currentRequest.RequestType === "Team" ? (React.createElement("div", { className: "kt-widget4" },
            loadingLiveTemplateChannels === true ? React.createElement(AnimatedLoader, { size: "small" }) : null,
            cloneTeamChannels !== undefined && Array.isArray(cloneTeamChannels) && cloneTeamChannels.length > 0
                ? cloneTeamChannels.map(function (chan, chanIndex) { return (React.createElement("div", { className: "kt-widget4__item", key: chanIndex },
                    React.createElement("span", { className: "kt-widget4__title kt-widget4__title--light" }, chan.displayName),
                    chan.membershipType !== undefined && chan.membershipType === "private" ? (React.createElement("div", { className: "private" },
                        React.createElement(FormattedMessage, { id: "page.request_details.channels.private_label", defaultMessage: "Private" }))) : null)); })
                : null,
            currentRequest.RequestChannels !== undefined && currentRequest.RequestChannels.length !== 0
                ? currentRequest.RequestChannels.map(function (chan, chanIndex) { return (React.createElement("div", { className: "kt-widget4__item", key: chanIndex },
                    React.createElement("span", { className: "kt-widget4__title kt-widget4__title--light" }, chan.Title),
                    chan.Privacy !== undefined && chan.Privacy === "Private" ? (React.createElement("div", { className: "private" },
                        React.createElement(FormattedMessage, { id: "page.request_details.channels.private_label", defaultMessage: "Private" }))) : null)); })
                : null)) : (React.createElement("div", { className: "no-results" },
            React.createElement(FormattedMessage, { id: "page.request_details.channels.not_used_in_sharepoint", defaultMessage: "Channels are not used in SharePoint workspaces" }))))));
});
export default RequestDetailsChannels;
