var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./channel-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as ChannelListStore from "app/store/channels/list";
import * as ChannelCurrentStore from "app/store/channels/current";
import generateGuid from "app/utils/generateGuid";
import { Col, Row, Container, Form } from "react-bootstrap";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import AssociateTemplate from "app/components/common/associate-template";
import { SubtitleButton } from "app/components/common/subtitle";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
var ChannelDetails = React.memo(function (props) {
    var dispatch = useDispatch();
    var lookupGuid = props.guid;
    var currentChannel = useSelector(function (state) { return state.channelCurrentState.item; });
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(false), formValid = _b[0], setFormValid = _b[1];
    var channelPrivacyType = React.useState([
        {
            id: "0",
            title: "Standard",
            value: "Standard"
        },
        {
            id: "1",
            title: "Private",
            value: "Private"
        }
    ])[0];
    console.log("Channel - Details - currentChannel", currentChannel);
    React.useEffect(function () {
        return function () {
            dispatch(ChannelCurrentStore.Clear());
        };
    }, []);
    // Should only be run once.
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (currentChannel.GUID !== undefined) {
            dispatch(ChannelCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current template when navigating to the new template page.
            var guid = generateGuid();
            // Add a new template with guid.
            dispatch(ChannelCurrentStore.Update({ GUID: guid, AssociatedTemplates: [] }));
        }
        else {
            // Load the /edit/guid load.
            dispatch(ChannelCurrentStore.Load({ GUID: lookupGuid }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid]);
    var clearCurrentChannel = function () {
        dispatch(ChannelCurrentStore.Clear());
    };
    var saveAndAddModifyChannel = function () {
        if (formValid) {
            console.log("Channel - Details - currentChannel", currentChannel);
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                // First, add the new template to template list
                asyncCallType = AsyncCallType.Add;
                dispatch(ChannelListStore.AddItem(currentChannel));
            }
            else {
                dispatch(ChannelListStore.UpdateItem(currentChannel));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentChannel.GUID).then(function () {
                setBackToList(true);
                dispatch(ChannelCurrentStore.Clear());
            });
        }
    };
    var onAssociatedAdd = function (templateToAdd) {
        //console.log("ADD Ass Details > ", templateToAdd)
        var tempArray = [templateToAdd];
        //setAssociatedTemplates([...tempArray, ...currentChannel.AssociatedTemplates]);
        if (currentChannel.AssociatedTemplates !== undefined) {
            if (currentChannel.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), currentChannel.AssociatedTemplates, true);
            }
        }
        dispatch(ChannelCurrentStore.Update({ AssociatedTemplates: tempArray }));
    };
    var onAssociatedRemove = function (tbaToRemove) {
        var _a;
        dispatch(ChannelCurrentStore.Update({
            AssociatedTemplates: (_a = currentChannel.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    return (React.createElement("div", { className: "channel-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
                console.log("is form valid", isValid);
            }, UniqueIdentifier: "channelvalidation" },
            backToList && React.createElement(Redirect, { to: "/workspaces/channels" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Channels", subTitle: props.guid ? "Edit" : "New", titleLink: "/workspaces/channels" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/channels", onClick: function () { return clearCurrentChannel(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement("div", { onClick: function () { return saveAndAddModifyChannel(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            currentChannel.GUID !== undefined && (React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                    React.createElement(Form.Label, null,
                                        "Name ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "channel-name" },
                                        React.createElement(Form.Control, { placeholder: "Enter Name", defaultValue: currentChannel.Title, onChange: function (event) { return dispatch(ChannelCurrentStore.Update({ Title: event.target.value })); } })),
                                    React.createElement(FormValidator, { UniqueIdentifier: "channel-name", Validators: ["string-not-empty", "string-is-more-than-1", "string-is-less-than-51", "no-illegal-characters", "does-not-end-in-full-stop"], FieldName: "Name", 
                                        // ErrorMessages={[
                                        //     {Type:"string-not-empty",ErrorMessage:"Name cannot be empty"},
                                        //     {Type:"string-is-more-than-3",ErrorMessage:"Name must be more than 3 characters"},
                                        //     {Type:"string-is-less-than-51",ErrorMessage:"Name cannot be more than 50 characters"},
                                        //     {Type:"no-illegal-characters",ErrorMessage:"Name must not contain illegal characters"}
                                        // ]}
                                        Value: currentChannel.Title })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                                    React.createElement(Form.Label, null, "Description (Optional)"),
                                    React.createElement(Form.Control, { as: "textarea", 
                                        //placeholder="Tell people the purpose of this channel"
                                        rows: 3, defaultValue: currentChannel.Description, onChange: function (event) { return dispatch(ChannelCurrentStore.Update({ Description: event.target.value })); } })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                                    React.createElement(Form.Label, null,
                                        "Privacy Type ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(FormDropdownSelect, { list: channelPrivacyType, defaultValue: currentChannel.Privacy, onChange: function (value) { return dispatch(ChannelCurrentStore.Update({ Privacy: value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "channel-privacy-type", Validators: ["dropdown-has-value"], Value: currentChannel.Privacy }))))),
                    React.createElement("div", { className: "divider-line", style: { marginTop: "80px" } }),
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement("h3", null, "Associated templates"),
                                React.createElement("div", null, currentChannel.GUID !== undefined && (React.createElement(AssociateTemplate, { associatedTemplates: currentChannel.AssociatedTemplates ? currentChannel.AssociatedTemplates : [], onAssociatedAdd: function (ass) {
                                        onAssociatedAdd(ass);
                                    }, onAssociatedRemove: function (ass) {
                                        onAssociatedRemove(ass);
                                    } },
                                    React.createElement("div", { className: "at-search" },
                                        React.createElement(AssociateTemplate.Search, null)),
                                    React.createElement("div", { className: "at-count" },
                                        React.createElement(AssociateTemplate.Count, null)),
                                    React.createElement("div", { className: "at-items" },
                                        React.createElement(AssociateTemplate.SelectedItems, null))))))))))))));
});
export default ChannelDetails;
