import { Center, Stack } from "@mantine/core";
import { Portlet, PortletBody } from "app/components/common/portlet";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useHealthCheckRouteParams } from "../../health-checks/use-health-check-route-params.hook";
export var HealthCheckNotFoundPage = function () {
    var intl = useIntl();
    var history = useHistory();
    var _a = useHealthCheckRouteParams(), userType = _a.userType, tenantGuid = _a.tenantGuid;
    var onBackButtonClicked = function () {
        if (userType === "partner") {
            history.push("/partner-tools/tenants/view/".concat(tenantGuid));
        }
        else {
            history.push("/health-checks");
        }
    };
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement(Center, { mt: 40, mb: 48 },
                React.createElement(Stack, { gap: 0, align: "center", justify: "center", w: 400 },
                    React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "primary-9", iconClassName: ORCH_ICONS.search }),
                    React.createElement(OrchestryText, { c: "text-primary.0", fz: "16px", fw: 400, lh: "24px", mt: 12 }, intl.formatMessage({
                        id: "healthcheck.not_found",
                        defaultMessage: "Health Check Not Found"
                    })),
                    React.createElement(OrchestryText, { mt: 4, c: "text-secondary.0", fz: "14px", fw: 300, lh: "20px", ta: "center" }, intl.formatMessage({
                        id: "healthcheck.not_found_subtitle",
                        defaultMessage: "Please visit the Health Checks page to view all Health Checks."
                    })))))));
};
