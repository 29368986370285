import React from "react";
import { Route, Switch } from "react-router-dom";
import RequestList from "app/components/requests/request-list";
import TemplateList from "app/components/workspaces/templates/template-list";
import TemplateNew from "app/components/workspaces/templates/template-new";
import TemplateEdit from "app/components/workspaces/templates/template-edit";
import FeatureList from "app/components/workspaces/features/feature-list";
import FeatureNew from "app/components/workspaces/features/feature-new";
import FeatureEdit from "app/components/workspaces/features/feature-edit";
import ChannelList from "app/components/workspaces/channels/channel-list";
import ChannelNew from "app/components/workspaces/channels/channel-new";
import ChannelEdit from "app/components/workspaces/channels/channel-edit";
import DocumentTemplateList from "app/components/workspaces/document-templates/document-template-list";
import DocumentTemplateNew from "app/components/workspaces/document-templates/document-template-new";
import DocumentTemplateEdit from "app/components/workspaces/document-templates/document-template-edit";
import MetadataList from "app/components/workspaces/metadata/metadata-list";
import MetadataNew from "app/components/workspaces/metadata/metadata-new";
import MetadataEdit from "app/components/workspaces/metadata/metadata-edit";
import ApprovalWorkflowList from "app/components/workspaces/approval-workflows/approval-workflows-list";
import ApprovalWorkflowNew from "app/components/workspaces/approval-workflows/approval-workflows-new";
import ApprovalWorkflowEdit from "app/components/workspaces/approval-workflows/approval-workflows-edit";
import NamingPolicyList from "app/components/workspaces/naming-policies/naming-policy-list";
import NamingPolicyNew from "app/components/workspaces/naming-policies/naming-policy-new";
import NamingPolicyEdit from "app/components/workspaces/naming-policies/naming-policy-edit";
import Approvals from "app/components/workspaces/approvals";
import RequestDetails from "app/components/requests/request-details";
import RequestWizard from "app/components/requests/request-wizard";
export default function WorkspacePages() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/workspaces/requests", component: RequestList }),
        React.createElement(Route, { exact: true, path: "/workspaces/requests/new", component: RequestWizard }),
        React.createElement(Route, { exact: true, path: "/workspaces/requests/view/:guid/:action?", component: RequestDetails }),
        React.createElement(Route, { exact: true, path: "/workspaces/templates", component: TemplateList }),
        React.createElement(Route, { exact: true, path: "/workspaces/templates/new" },
            React.createElement(TemplateNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/templates/edit/:guid", component: TemplateEdit }),
        React.createElement(Route, { exact: true, path: "/workspaces/features" },
            React.createElement(FeatureList, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/features/new" },
            React.createElement(FeatureNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/features/edit/:guid", component: FeatureEdit }),
        React.createElement(Route, { exact: true, path: "/workspaces/channels" },
            React.createElement(ChannelList, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/channels/new" },
            React.createElement(ChannelNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/channels/edit/:guid", component: ChannelEdit }),
        React.createElement(Route, { exact: true, path: "/workspaces/document-templates" },
            React.createElement(DocumentTemplateList, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/document-templates/new" },
            React.createElement(DocumentTemplateNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/document-templates/edit/:guid", component: DocumentTemplateEdit }),
        React.createElement(Route, { exact: true, path: "/workspaces/approvals" },
            React.createElement(Approvals, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/metadata" },
            React.createElement(MetadataList, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/metadata/new" },
            React.createElement(MetadataNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/metadata/edit/:guid", component: MetadataEdit }),
        React.createElement(Route, { exact: true, path: "/workspaces/approval-workflows" },
            React.createElement(ApprovalWorkflowList, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/approval-workflows/new" },
            React.createElement(ApprovalWorkflowNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/approval-workflows/edit/:guid", component: ApprovalWorkflowEdit }),
        React.createElement(Route, { exact: true, path: "/workspaces/naming-policies" },
            React.createElement(NamingPolicyList, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/naming-policies/new" },
            React.createElement(NamingPolicyNew, null)),
        React.createElement(Route, { exact: true, path: "/workspaces/naming-policies/edit/:guid", component: NamingPolicyEdit })));
}
