var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { IntlProvider } from "react-intl";
/**
 * Wraps a component with an IntlProvider to allow useIntl to be used.
 * Currently designed for a modal component, but could be extended to other components.
 *
 * @param WrappedComponent The component to wrap with the IntlProvider. Allows useIntl to be used.
 */
var withIntlProvider = function (WrappedComponent) {
    var ComponentWithIntlProvider = function (props) {
        var _a = props.innerProps, intl = _a.intl, restProps = __rest(_a, ["intl"]);
        var intlProviderProps = {
            locale: intl.locale,
            messages: intl.messages,
            children: React.createElement(WrappedComponent, __assign({}, props, { innerProps: restProps }))
        };
        return React.createElement(IntlProvider, __assign({}, intlProviderProps));
    };
    return ComponentWithIntlProvider;
};
export default withIntlProvider;
