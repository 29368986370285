/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { RootState } from "app/store";
import { User } from "app/store/users/types";
import { connect } from "react-redux";
import * as UserCurrentStore from "app/store/users/current";
import { useSelector, useDispatch } from "react-redux";
import { toAbsoluteUrl } from "_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import UserProfilePicture from "app/components/common/user-profile-picture";
import Store from "app/store";
import Async from "app/components/common/async";
import * as AsyncTypes from "app/store/async";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
// import AppcuesService from 'app/services/appcues';
import { useIntl, FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";

// const UserProfile = React.memo((props) => {
//   return(<></>);
// })
// export default UserProfile

const UserProfile = React.memo(({ showHi, showAvatar, showBadge }) => {
   const intl = useIntl();
   const dispatch = useDispatch();
   const user = useSelector(state => state.userCurrentState.item);
   const orchectryContext = useSelector(state => state.orchestryContextCurrentState.item);
   //console.log("current user", user);

   React.useEffect(() => {
      // Load the current user
      dispatch(UserCurrentStore.Load());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // React.useEffect(() => {
   //    AppcuesService.IdentifyUser();
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [user])

   // const state = Store.getState();
   // const orchectryContext = state.orchestryContextCurrentState.item;

   const getRegionDisplayName = region => {
      if (region == "US") {
         return intl.formatMessage({ id: "component.user_profile.north_america", defaultMessage: "North America" });
      }
      if (region == "AU") {
         return intl.formatMessage({ id: "component.user_profile.asia_pacific", defaultMessage: "Asia Pacific" });
      }
      if (region == "EU") {
         return intl.formatMessage({ id: "component.user_profile.europe", defaultMessage: "Europe" });
      }
      return region;
   };

   if (user !== undefined && user.GUID !== undefined) {
      //console.log("User",user)
      return (
         <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
            <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
               <div className="kt-header__topbar-user">
                  {/* {showHi && (
               <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
               </span>
            )} */}

                  {showHi && (
                     <span className="kt-header__topbar-username kt-hidden-mobile">
                        {user !== undefined ? (
                           <div className="display-name">
                              {user.DisplayName}
                              <span style={{ opacity: "0.7" }}>{user.IsGuest == true ? " (Guest)" : null}</span>
                           </div>
                        ) : null}
                        {orchectryContext.TenantName !== undefined ? <div className="tenant-name">{orchectryContext.TenantName}</div> : null}
                     </span>
                  )}

                  {user !== undefined && showAvatar ? <UserProfilePicture uid={user.GUID} displayName={user.DisplayName} /> : null}

                  {showBadge && (
                     <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                        {/* TODO: Should get from currentUser */}
                        John Doe
                     </span>
                  )}
               </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
               <div
                  className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                  style={{
                     //  backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
                     backgroundColor: "#3DA0A2"
                  }}>
                  <div className="kt-user-card__avatar">
                     {/* <img alt="Pic" className="kt-hidden" src={user.pic} /> */}
                     {user !== undefined ? <UserProfilePicture uid={user.GUID} displayName={user.DisplayName} /> : null}
                     {/* <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                  S
                </span> */}
                  </div>
                  <div className="kt-user-card__name">
                     <div className="orchestry-display-name">
                        {user !== undefined ? user.DisplayName : null}
                        {user !== undefined && user.IsGuest == true ? " (Guest)" : null}
                     </div>
                     <div className="orchestry-upn">{user !== undefined ? user.UserPrincipalName : null}</div>
                  </div>
               </div>
               <div className="kt-notification">
                  {/* <a className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    My Profile
                  </div>
                  <div className="kt-notification__item-time">
                    Account settings and more
                  </div>
                </div>
              </a>
              <a className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-mail kt-font-warning" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    My Messages
                  </div>
                  <div className="kt-notification__item-time">
                    Inbox and tasks
                  </div>
                </div>
              </a>
              <a className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-rocket-1 kt-font-danger" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    My Activities
                  </div>
                  <div className="kt-notification__item-time">
                    Logs and notifications
                  </div>
                </div>
              </a>
              <a className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-hourglass kt-font-brand" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    My Tasks
                  </div>
                  <div className="kt-notification__item-time">
                    latest tasks and projects
                  </div>
                </div>
              </a> */}
                  <div className="kt-notification__custom">
                     <div className="signout-button">
                        <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
                           <i className="fas fa-sign-out-alt"></i>

                           <FormattedMessage id="component.user_profile.sign_out" defaultMessage="Sign Out" />
                        </Link>
                     </div>
                     {orchectryContext.TenantName !== undefined ? (
                        <div className="kt-user-card__badge region-badges">
                           <div className="server-region">
                              <i className="fa fa-globe" title="Server Location"></i> <span title={orchectryContext.AzureSiteName}>{getRegionDisplayName(orchectryContext.ServerRegion)}</span>
                           </div>
                           <div className="tenant-region">
                              <i className="fas fa-database" title="Tenant Data Location"></i> <span title={orchectryContext.AzureSiteName}>{getRegionDisplayName(orchectryContext.TenantRegion)}</span>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </div>
            </Dropdown.Menu>
         </Dropdown>
      );
   } else {
      return (
         <div style={{ paddingTop: "10px" }}>
            <SkeletonLoaderFakeBox bodyHeight={44} bodyWidth={180} />
         </div>
      );
   }
});

export default UserProfile;
