import { useSelector } from "react-redux";
/**
 * Hook to determine if the actions should be shown. Made this a hook because we show the actions in the web part and in teams.
 */
export var useTeamInformationActions = function () {
    var _a, _b, _c;
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var teamInformationWebPartState = useSelector(function (state) { return state.teamInformationWebPartState; });
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var userIdExists = !!((_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.user) === null || _a === void 0 ? void 0 : _a.id) || !!(teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.UserId);
    var shouldShowActions = userIdExists &&
        ((_b = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _b === void 0 ? void 0 : _b.showActions) !== false &&
        !!teamInformation &&
        ((_c = teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.GuestRequestPolicy) === null || _c === void 0 ? void 0 : _c.AllowGuestAccessForWorkspaces) === true &&
        (teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.IsCurrentUserTeamOwner) === true &&
        (teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.SensitivityBlocksGuests) === false;
    return shouldShowActions;
};
