export var OrchRole;
(function (OrchRole) {
    /*
     * Never remove None or make it any other item than 0.
     */
    OrchRole["None"] = "None";
    /*
     * Base level access to Orchestry.
     * At the moment gives them access to any approvals or provisioning requests.
     */
    OrchRole["User"] = "User";
    /*
     * Admin level access to Orchestry. Includes beacon admin, customer admin and top level partner admin.
     */
    OrchRole["Admin"] = "Admin";
    /*
     * Internal admin - Orchestry employee.
     */
    OrchRole["InternalAdmin"] = "InternalAdmin";
    /*
     * Internal admin - Orchestry employee that is in Azure AD group for sensitive information.
     */
    OrchRole["InternalAdminSensitiveInfo"] = "InternalAdminSensitiveInfo";
    /*
     * Either internal employee or configured orchestry user.
     */
    OrchRole["InternalToolLanguageEditor"] = "InternalToolLanguageEditor";
    /*
     * Internal admin - Orchestry employee.
     */
    OrchRole["InternalToolPermissionInfoEditor"] = "InternalToolPermissionInfoEditor";
    /*
     * Configured user via HealthCheckSettings.
     */
    OrchRole["HealthCheckViewer"] = "HealthCheckViewer";
    /*
     * Configured user via HealthCheckSettings.
     */
    OrchRole["HealthCheckReviewer"] = "HealthCheckReviewer";
    /*
     * An Admin who has a tenant type set to "Partner Hub" tenant.
     */
    OrchRole["PartnerAdmin"] = "PartnerAdmin";
    /*
     * Configured on the partner settings page by Partner Admin on a "Partner Hub" tenant.
     */
    OrchRole["PartnerHubAdmin"] = "PartnerHubAdmin";
    /*
     * Configured on the partner tenant settings page by Partner Admin or Partner Hub Admin to manage a specific tenant.
     */
    OrchRole["PartnerTenantManager"] = "PartnerTenantManager";
    /*
     * Used for self-authentication when Orchestry is calling itself through HTTP requests.
     */
    OrchRole["OrchestrySelfAuthenticator"] = "OrchestrySelfAuthenticator";
    /**
     * A beacon user is a user that has access to some pages (like health checks), but they do not have access to most of the user pages.
     */
    OrchRole["BeaconUser"] = "BeaconUser";
    /**
     * A beacon admin is a user that has access to the admin pages, but they still have a limited set of permissions compared to a normal admin.
     */
    OrchRole["BeaconAdmin"] = "BeaconAdmin";
})(OrchRole || (OrchRole = {}));
