var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import ReactDOM from "react-dom";
import "./form-dropdown-select.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Scrollbar } from "react-scrollbars-custom";
import { Collapse } from "react-bootstrap";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
var FormDropdownSelect = /** @class */ (function (_super) {
    __extends(FormDropdownSelect, _super);
    function FormDropdownSelect(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClickOutside = function (event) {
            var domNode = ReactDOM.findDOMNode(_this);
            var element = event.target;
            if (!domNode || !domNode.contains(element)) {
                _this.clearSearch();
                _this.setState({
                    listOpen: false
                });
            }
        };
        _this.handleKeydown = function (event) {
            var _a;
            var isInDD = false;
            if (_this.dropdownDiv.current === event.target) {
                isInDD = true;
            }
            else if (event.target !== null && ((_a = _this.dropdownDiv.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                isInDD = true;
            }
            if (isInDD) {
                // Enter
                if (event.keyCode === 13) {
                    // if(this.state.listOpen === true){
                    // }
                    _this.toggleList();
                    event.preventDefault();
                }
                // Up
                if (event.keyCode === 38) {
                    // if(this.state.listOpen === true){
                    var foundIndex = _this.state.list.findIndex(function (i) { var _a; return i.value === ((_a = _this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.value); });
                    if (foundIndex !== null) {
                        if (foundIndex > 0) {
                            _this.setSelected(_this.state.list[foundIndex - 1], true);
                        }
                    }
                    event.preventDefault();
                    // }
                }
                // Down
                if (event.keyCode === 40) {
                    // if(this.state.listOpen === true){
                    var foundIndex = _this.state.list.findIndex(function (i) { var _a; return i.value === ((_a = _this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.value); });
                    if (foundIndex !== null) {
                        if (foundIndex + 1 < _this.state.list.length) {
                            _this.setSelected(_this.state.list[foundIndex + 1], true);
                        }
                    }
                    event.preventDefault();
                    // }
                }
            }
        };
        _this.setScrollRef = function (ref) {
            if (ref !== null) {
                //console.log("Ref", );
                _this.scrollRef = ref;
            }
        };
        _this.shouldShowRedline = function () {
            var _a;
            if (((_a = _this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === undefined) {
                if (_this.props.showRedOutlineIfNothingSelected !== undefined) {
                    if (_this.props.showRedOutlineIfNothingSelected === true) {
                        return true;
                    }
                }
            }
            return false;
        };
        _this.state = {
            list: props.list,
            listOpen: false,
            defaultHeaderTitle: _this.props.placeholder !== undefined ? _this.props.placeholder : "-- Please Select --",
            selectedLaIcon: "",
            selectedItem: {},
            searchTerm: ""
        };
        _this.dropdownDiv = React.createRef();
        _this.state = Object.assign(_this.state, {
            headerTitle: _this.state.defaultHeaderTitle
        });
        _this.scrollRef = {};
        return _this;
    }
    FormDropdownSelect.prototype.componentWillUnmount = function () {
        document.removeEventListener("click", this.handleClickOutside, true);
        document.removeEventListener("keydown", this.handleKeydown);
    };
    FormDropdownSelect.prototype.toggleList = function () {
        if (this.props.disabled === undefined || (this.props.disabled !== undefined && this.props.disabled === false)) {
            this.setState(function (prevState) { return ({
                listOpen: !prevState.listOpen
            }); });
        }
    };
    FormDropdownSelect.prototype.setSelected = function (item, listOpen, firstTime) {
        var sameValueAsCurrent = false;
        var _listOpen = listOpen !== undefined ? listOpen : false;
        // if(item.value === this.state.selectedItem?.value){
        //    sameValueAsCurrent = true;
        // }
        if (sameValueAsCurrent) {
            this.setState(__assign(__assign({}, this.state), { listOpen: _listOpen }));
        }
        else {
            this.setState({
                listOpen: _listOpen,
                selectedItem: __assign({}, item),
                selectedLaIcon: item.laIcon,
                headerTitle: item.title !== "" ? item.title : item.description
            });
            if (firstTime === undefined || (firstTime !== undefined && firstTime === false)) {
                // console.log("dd on change running");
                if (item.object !== undefined) {
                    this.props.onChange(item.object);
                }
                else {
                    this.props.onChange(item.value);
                }
            }
        }
        if (this.props.afterSelectReset !== undefined) {
            if (this.props.afterSelectReset === true) {
                this.setToNothing();
            }
        }
    };
    FormDropdownSelect.prototype.setToNothing = function () {
        this.setState({
            listOpen: false,
            selectedItem: {},
            headerTitle: this.state.defaultHeaderTitle
        });
    };
    FormDropdownSelect.prototype.componentDidMount = function () {
        var _this = this;
        document.addEventListener("click", this.handleClickOutside, true);
        document.addEventListener("keydown", this.handleKeydown);
        //var that = this;
        //console.log("FormDropdown", this.props.defaultValue);
        if (this.props.defaultValue !== undefined) {
            this.props.list.forEach(function (item, index) {
                //console.log("loop items", item.value, this.props.defaultValue);
                if (item.value === _this.props.defaultValue) {
                    _this.setSelected(item, undefined, true);
                    //console.log("matched dropdown", item);
                }
            });
        }
        else if (this.props.value !== undefined) {
            this.props.list.forEach(function (item, index) {
                if (item.value === _this.props.value) {
                    _this.setSelected(__assign({}, item), undefined, true);
                }
            });
        }
    };
    FormDropdownSelect.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        var _a;
        //if (this.props.value !== undefined) {
        // if (this.props.value === "")
        //
        if (this.props.value !== prevProps.value) {
            //console.log("componentDidUpdate", this.props.value, prevProps.value);
            var foundMatch_1 = false;
            this.props.list.forEach(function (item, index) {
                if (item.value === _this.props.value) {
                    foundMatch_1 = true;
                    _this.setSelected(__assign({}, item));
                }
            });
            if (foundMatch_1 === false) {
                console.log("didn't find match to new value");
                this.setState({
                    selectedLaIcon: "",
                    selectedItem: {},
                    headerTitle: this.state.defaultHeaderTitle
                });
            }
        }
        //} else {
        //}
        if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) {
            this.setState({
                list: this.props.list
            });
            if (!this.props.list.some(function (item) { return item.value === _this.props.value; })) {
                this.setToNothing();
                this.props.onChange((_a = this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.value);
            }
        }
        //if the list options are modified, and the currently selected items' title is changed in the option list
        //then sync the title of the selected item to the new option title
        var needToUpdateSelectedTitle = false;
        var listItemToCheck;
        if (this.state.selectedItem != null) {
            listItemToCheck = this.state.list.filter(function (listItem) { var _a, _b; return listItem.id === ((_a = _this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.id) && listItem.value === ((_b = _this.state.selectedItem) === null || _b === void 0 ? void 0 : _b.value); });
            if (listItemToCheck.length === 1 && this.state.selectedItem && this.state.selectedItem.title !== listItemToCheck[0].title) {
                needToUpdateSelectedTitle = true;
            }
        }
        if (needToUpdateSelectedTitle && listItemToCheck) {
            this.setState({ headerTitle: listItemToCheck[0].title });
            this.setState({ selectedItem: listItemToCheck[0] });
        }
    };
    FormDropdownSelect.prototype.filterItemsInDropDown = function (value) {
        if (typeof this.scrollRef.scrollTo === "function") {
            this.scrollRef.scrollTo(0, 0);
        }
        if (value.length > 0) {
            var foundOptions = this.props.list.filter(function (dropdownItems) { var _a; return ((_a = dropdownItems.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(value === null || value === void 0 ? void 0 : value.toLowerCase())) > -1; });
            this.setState({
                list: __spreadArray([], foundOptions, true),
                searchTerm: value
            });
        }
        else {
            this.setState({
                list: __spreadArray([], this.props.list, true),
                searchTerm: ""
            });
        }
    };
    FormDropdownSelect.prototype.clearSearch = function () {
        if (typeof this.scrollRef.scrollTo === "function") {
            this.scrollRef.scrollTo(0);
        }
        this.setState({
            list: __spreadArray([], this.props.list, true),
            searchTerm: ""
        });
    };
    FormDropdownSelect.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement("div", { ref: this.dropdownDiv, tabIndex: 0, className: "form-dropdown dd-wrapper" +
                (this.state.listOpen ? " open" : " closed") +
                (this.shouldShowRedline() ? " has-error" : "") +
                (this.props.disabled !== undefined && this.props.disabled === true ? " disabled" : "") },
            React.createElement("div", { className: "form-control dd-header", onClick: function () { return _this.toggleList(); }, "data-pw": "dd-header" },
                React.createElement("span", { className: "dd-header-title" + (this.state.headerTitle === this.props.placeholder || ((_a = this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === undefined ? " placeholder-text" : "") },
                    this.state.selectedItem !== undefined && this.state.selectedItem.image !== undefined ? React.createElement("img", { src: this.state.selectedItem.image, alt: this.state.headerTitle }) : "",
                    this.state.selectedItem !== undefined && this.state.selectedItem.icon !== undefined ? React.createElement(FontAwesomeIcon, { icon: this.state.selectedItem.icon }) : "",
                    this.state.selectedItem !== undefined && this.state.selectedItem.laIcon !== undefined ? React.createElement(LineAwesomeIcon, { iconString: this.state.selectedItem.laIcon }) : "",
                    this.state.selectedItem !== undefined && this.state.selectedItem.customIcon !== undefined && this.state.selectedItem.customIcon !== null ? (React.createElement("i", { className: this.state.selectedItem.customIcon })) : (""),
                    React.createElement("span", { className: "header-text", "data-pw": "header-text" }, this.state.headerTitle)),
                React.createElement("span", { className: "arrow" }, this.state.listOpen ? React.createElement(FontAwesomeIcon, { icon: faChevronUp }) : React.createElement(FontAwesomeIcon, { icon: faChevronDown }))),
            this.props.waitToRenderDDItemsUntilOpen === undefined ||
                (this.props.waitToRenderDDItemsUntilOpen !== undefined && this.props.waitToRenderDDItemsUntilOpen === false) ||
                (this.props.waitToRenderDDItemsUntilOpen !== undefined && this.props.waitToRenderDDItemsUntilOpen === true && this.state.listOpen === true) ? (React.createElement(Collapse, { in: this.state.listOpen, appear: true, timeout: 1000 },
                React.createElement("div", { className: "dd-list " + (this.props.theme !== undefined ? this.props.theme : ""), "data-pw": "dd-list" },
                    this.props.enableSearch !== undefined && this.props.enableSearch === true ? (React.createElement("div", { className: "searchbox" },
                        React.createElement("input", { type: "text", placeholder: "Search", value: this.state.searchTerm, onChange: function (input) {
                                _this.filterItemsInDropDown(input.target.value);
                            } }),
                        this.state.searchTerm.length > 0 ? (React.createElement("div", { className: "icon-delete" },
                            React.createElement(FontAwesomeIcon, { icon: faTimesCircle, onClick: function () {
                                    _this.clearSearch();
                                } }))) : (React.createElement("div", { className: "icon-search" },
                            React.createElement(FontAwesomeIcon, { icon: faSearch }))))) : (React.createElement(React.Fragment, null)),
                    this.state.list.length > 7 ? (React.createElement(Scrollbar, { ref: function (ref) {
                            _this.setScrollRef(ref);
                        } },
                        React.createElement("ul", { className: " list-unstyled" }, this.state.list.map(function (item, itemIndex) {
                            var _a;
                            return (React.createElement("li", { className: "dd-list-item " +
                                    (item.disabled ? "disabled" : "") +
                                    (((_a = _this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === item.id ? "dd-selected" : "") +
                                    (item.image !== undefined || item.icon !== undefined || item.laIcon !== undefined || (item.customIcon !== undefined && item.customIcon !== null)
                                        ? " has-image-or-icon"
                                        : ""), key: itemIndex, onClick: item.disabled ? undefined : function () { return _this.setSelected(item); } },
                                item.image !== undefined ? React.createElement("img", { src: item.image, alt: item.title }) : "",
                                item.icon !== undefined ? React.createElement(FontAwesomeIcon, { icon: item.icon }) : "",
                                item.laIcon !== undefined ? React.createElement(LineAwesomeIcon, { iconString: item.laIcon }) : "",
                                item.customIcon !== undefined && item.customIcon !== null ? React.createElement("i", { className: item.customIcon }) : "",
                                item.title,
                                item.description !== undefined && item.description !== null && item.description !== "" ? React.createElement("span", { className: "description" }, item.description) : null));
                        })))) : (React.createElement("ul", { className: " list-unstyled" }, this.state.list.map(function (item, itemIndex) {
                        var _a;
                        return (React.createElement("li", { className: "dd-list-item " +
                                (item.disabled ? "disabled" : "") +
                                (((_a = _this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.id) === item.id ? "dd-selected" : "") +
                                (item.image !== undefined || item.icon !== undefined || item.laIcon !== undefined || (item.customIcon !== undefined && item.customIcon !== null)
                                    ? " has-image-or-icon"
                                    : ""), key: itemIndex, "data-pw": "list-item", onClick: item.disabled ? undefined : function () { return _this.setSelected(item); } },
                            item.image !== undefined ? React.createElement("img", { src: item.image, alt: item.title }) : "",
                            item.icon !== undefined ? React.createElement(FontAwesomeIcon, { icon: item.icon }) : "",
                            item.laIcon !== undefined ? React.createElement(LineAwesomeIcon, { iconString: item.laIcon }) : "",
                            item.customIcon !== undefined && item.customIcon !== null ? React.createElement("i", { className: item.customIcon }) : "",
                            item.title,
                            item.description !== undefined && item.description !== null && item.description !== "" ? React.createElement("span", { className: "description" }, item.description) : null));
                    }))),
                    this.state.list.length === 0 ? (React.createElement("ul", { className: "list-unstyled" },
                        React.createElement("li", { className: "no-results" }, "-- No Results -- "))) : (React.createElement(React.Fragment, null))))) : null));
    };
    return FormDropdownSelect;
}(React.Component));
export default FormDropdownSelect;
