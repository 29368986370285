import * as React from "react";
import { Collapse, Form } from "react-bootstrap";
var ReportFilterPrivacyChoice = React.memo(function (props) {
    var _a;
    return (React.createElement(Collapse, { in: (_a = props.CurrentRefiners) === null || _a === void 0 ? void 0 : _a.some(function (cr) { return cr.FieldName === "TemplateType" && cr.CurrentValue !== "sharepoint-comm"; }), key: props.Index },
        React.createElement(Form.Group, { className: "form-group checkbox-green", key: props.Index },
            React.createElement(Form.Label, null, props.Refiner.Title),
            React.createElement(Form.Check, { type: "checkbox", label: "Private", name: "privacy-private", id: "privacy-private", onChange: function () { return props.FilterOnChange(props.Refiner, "Private"); }, checked: props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue.indexOf("Private") > -1 ? true : false }),
            React.createElement(Form.Check, { type: "checkbox", label: "Public", id: "privacy-public", name: "privacy-public", onChange: function () { return props.FilterOnChange(props.Refiner, "Public"); }, checked: props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue.indexOf("Public") > -1 ? true : false }))));
});
export default ReportFilterPrivacyChoice;
