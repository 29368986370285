import { AsyncWait } from "app/components/common/async";
import OrchModal from "app/components/common/orch-modal";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import InsightsReport from "app/components/insights/common/report";
import GuestCommonAdd from "app/components/lifecycle/guests/common/guest-common-add";
import UsersGuestsService from "app/services/users-guests";
import { AsyncStatus } from "app/store/async";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { ReportDatasource, ReportSearchSlotName } from "app/store/lifecycle-report";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import * as TeamInformationCurrentStore from "app/store/team-information/current";
import { UserListMode } from "app/store/users/list";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import UserList from "../user-list";
import "./team-information-page-users.scss";
var TeamInformationPageUsers = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f;
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var guestListItems = useSelector(function (state) { return state.lifecycleReportState[ReportSearchSlotName.GuestInsightsSlot].Items; });
    var tabState = useSelector(function (state) { return state.teamInformationState.teamTabState; });
    var _g = React.useState(true), guestPolicyEnabled = _g[0], setGuestPolicyEnabled = _g[1];
    var _h = React.useState(false), processing = _h[0], setProcessing = _h[1];
    var _j = React.useState(null), bulkJob = _j[0], setBulkJob = _j[1];
    var _k = React.useState(false), showComplete = _k[0], setShowComplete = _k[1];
    var intl = useIntl();
    var teamLinks = useSelector(function (state) { return state.teamInformationState.teamLinks; });
    //const [graphGuestUsers, setGraphGuestUsers] = React.useState<GuestUser[] | null>(null);
    var dispatch = useDispatch();
    React.useEffect(function () {
        var _a, _b;
        if (teamContext !== null && teamInformation !== null) {
            if (((_a = teamContext.user) === null || _a === void 0 ? void 0 : _a.id) !== null &&
                ((_b = teamContext.user) === null || _b === void 0 ? void 0 : _b.id) !== undefined &&
                teamInformation.GuestRequestPolicy !== null &&
                teamInformation.GuestRequestPolicy !== undefined &&
                teamInformation.GuestRequestPolicy.AllowGuestAccessForWorkspaces === true &&
                teamInformation.IsCurrentUserTeamOwner === true &&
                teamInformation.SensitivityBlocksGuests === false) {
                setGuestPolicyEnabled(true);
            }
            else {
                setGuestPolicyEnabled(false);
            }
        }
    }, [teamContext, teamInformation]);
    var customHeader = function (insightContext) {
        return (React.createElement("div", { className: "custom-header" },
            React.createElement("div", { className: "title-left" }, insightContext.IsLoading === true ? (React.createElement(SkeletonLoaderFakeBox, { style: { marginRight: "170px" } })) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "top" },
                    React.createElement("div", { className: "title" },
                        React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.title.guests", defaultMessage: "Guests" })),
                    React.createElement("div", { className: "count" }, insightContext.ItemCount)))))));
    };
    var onTotalGuestCountChange = function (count) {
        dispatch(TeamInformationCurrentStore.UpdateTeamInformation({ GuestCount: count }));
        //console.log("total guests", count);
    };
    var RemoveGuestFromWorkspaceMessage = function (_a) {
        var workspaceName = _a.workspaceName, plural = _a.plural;
        return (React.createElement("div", null,
            React.createElement("p", null, intl.formatMessage({
                id: "actions.bulk.remove_guest_modal.body",
                defaultMessage: "Are you sure you'd like to remove {isplural, plural, one {this guest from} other {these guests from}}:"
            }, { isplural: plural ? 2 : 0 })),
            React.createElement("p", null,
                React.createElement("strong", null, workspaceName))));
    };
    var ResendModalMessage = function (_a) {
        var workspaceName = _a.workspaceName, plural = _a.plural;
        return (React.createElement("div", null, workspaceName ? (React.createElement(React.Fragment, null,
            React.createElement("p", null, intl.formatMessage({
                id: "actions.bulk.resend_invite_modal.body_with_workspace_name",
                defaultMessage: "Would you like to resend {wsCount, plural, one {the invite} other {the invites}} for:"
            }, { wsCount: workspaceName.length > 0 ? 2 : 1 })),
            React.createElement("p", null,
                React.createElement("strong", null, workspaceName)),
            " ")) : (React.createElement(React.Fragment, null,
            React.createElement("p", null, intl.formatMessage({
                id: "actions.bulk.resend_invite_modal.body",
                defaultMessage: "Are you sure you would like to resend the invitation?"
            }))))));
    };
    var bulkApply = function (guestDetails, action, workspaceUrl, groupId, siteId) {
        if (guestDetails.length > 0 && workspaceUrl && action === "Resend") {
            UsersGuestsService.BulkResendGuestInvites({ guestDetails: guestDetails, inviteUrl: workspaceUrl }).then(function (value) {
                setBulkJob({ bulkJobId: value.jobId, jobType: "Resend" });
                setProcessing(true);
            });
        }
        if (guestDetails.length > 0 && groupId && siteId && action === "Remove") {
            UsersGuestsService.BulkRemoveGuestsFromWorkspace({ guestDetails: guestDetails, groupId: groupId, siteId: siteId }).then(function (value) {
                setBulkJob({ bulkJobId: value.jobId, jobType: "Remove" });
                setProcessing(true);
            });
        }
    };
    var customBulkActionResend = function (context, selectedIds) {
        var _a;
        var inviteGuestDetails = context.Items.map(function (item) {
            var _a, _b, _c, _d, _e, _f;
            if (((_b = (_a = item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) === null || _b === void 0 ? void 0 : _b.Mail) !== null && ((_d = (_c = item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.CosmosUserDetails) === null || _d === void 0 ? void 0 : _d.Mail) !== undefined) {
                return { id: item.UserId, email: (_f = (_e = item.ExtendedProperties) === null || _e === void 0 ? void 0 : _e.CosmosUserDetails) === null || _f === void 0 ? void 0 : _f.Mail };
            }
            return {};
        }).filter(function (guest) { return guest.email && guest.id !== null && guest.id !== undefined && selectedIds.includes(guest.id); });
        return (React.createElement(OrchModal, { modalClassName: "bulk-resend-invite-guest-modal", simpleButton: true, size: "sm", headerText: intl.formatMessage({
                id: "actions.bulk.resend_invite_modal.title",
                defaultMessage: "Resend Azure Guest Invitation"
            }), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa fa-paper-plane" }),
                    intl.formatMessage({
                        id: "actions.bulk.resend_invite_modal.resend_invites",
                        defaultMessage: "Resend {selectedCount, plural, one {Invite} other {Invites}}"
                    }, { selectedCount: selectedIds.length }))), footerPrimaryText: intl.formatMessage({
                id: "actions.bulk.resend_invite_modal.resend_invites",
                defaultMessage: "Resend {selectedCount, plural, one {Invite} other {Invites}}"
            }, { selectedCount: selectedIds.length }), footerPrimaryFunction: function () { var _a; return bulkApply(inviteGuestDetails, "Resend", ((_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.sharePointSite) === null || _a === void 0 ? void 0 : _a.teamSiteUrl) || (teamLinks === null || teamLinks === void 0 ? void 0 : teamLinks.SharePointUrl) || ""); } },
            React.createElement(ResendModalMessage, { workspaceName: ((_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _a === void 0 ? void 0 : _a.displayName) || "", plural: selectedIds.length > 1 ? true : false })));
    };
    var customBulkActionRemove = function (context, selectedIds) {
        var _a;
        var removeGuestDetails = selectedIds.map(function (id) {
            return { id: id, email: "" };
        });
        return (React.createElement(OrchModal, { modalClassName: "bulk-remove-guest-modal", simpleButton: true, size: "sm", headerText: intl.formatMessage({
                id: "actions.bulk.remove_guest_modal.title",
                defaultMessage: "Remove Guests from Workspace"
            }), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa-regular fa-user-xmark" }),
                    intl.formatMessage({
                        id: "actions.bulk.remove_guest_modal.remove_guests_from_ws",
                        defaultMessage: "Remove {selectedCount, plural, one {Guest} other {Guests}} from Workspace"
                    }, { selectedCount: selectedIds.length }))), footerPrimaryText: intl.formatMessage({
                id: "actions.bulk.remove_guest_modal.remove_guests",
                defaultMessage: "Remove {selectedCount, plural, one {Guest} other {Guests}}"
            }, { selectedCount: selectedIds.length }), footerPrimaryFunction: function () { var _a, _b; return bulkApply(removeGuestDetails, "Remove", undefined, (_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _a === void 0 ? void 0 : _a.groupId, (_b = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _b === void 0 ? void 0 : _b.internalId); } },
            React.createElement("div", { className: "column-popout-confirmation-dialog" },
                React.createElement("div", { className: "message" },
                    React.createElement(RemoveGuestFromWorkspaceMessage, { workspaceName: ((_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _a === void 0 ? void 0 : _a.displayName) || "", plural: selectedIds.length > 1 ? true : false })))));
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== null && (bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== undefined) {
                shouldRun = true;
            }
            if (shouldRun) {
                if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.jobType) === "Remove") {
                    UsersGuestsService.BulkRemoveGuestsFromWorkspaceStatus(bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId).then(function (data) {
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            runAgain();
                        }
                        else {
                            AsyncWait(undefined, AsyncStatus.Succeeded, null, null, "Report_Refresh").then(function (res) {
                                setProcessing(false);
                                setShowComplete(true);
                                dispatch(LifecyclePolicyWorkspaceStore.RemoveFromWorkspaceSuccess());
                            });
                            setTimeout(function () {
                                dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestInsightsSlot));
                            }, 1000);
                        }
                    });
                }
                if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.jobType) === "Resend") {
                    UsersGuestsService.BulkResendInviteStatus(bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId).then(function (data) {
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            runAgain();
                        }
                        else {
                            setProcessing(false);
                            dispatch(LifecyclePolicyWorkspaceStore.ResendInviteSuccess());
                        }
                    });
                }
            }
            else {
                runAgain();
            }
        }
        if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== null && (bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== undefined) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null && timerID !== undefined) {
                clearInterval(timerID);
            }
        };
    }, [bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId]);
    return (React.createElement("div", { className: "team-information-page-users" },
        React.createElement(Portlet, null,
            React.createElement(PortletBody, null, teamContext !== null ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "main-container" },
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(UserList, { mode: UserListMode.Owners, groupId: (_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _a === void 0 ? void 0 : _a.groupId }))),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(UserList, { mode: UserListMode.Members, groupId: (_b = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _b === void 0 ? void 0 : _b.groupId }))),
                    guestPolicyEnabled === false ? (React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(UserList, { mode: UserListMode.Guests, groupId: (_c = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _c === void 0 ? void 0 : _c.groupId })))) : (React.createElement(Row, { className: "insights-report" },
                        React.createElement(Col, null,
                            React.createElement("div", { className: "list-of-members" },
                                React.createElement("div", { className: "title-right" },
                                    React.createElement(GuestCommonAdd, { currentUserId: (_d = teamContext.user) === null || _d === void 0 ? void 0 : _d.id, groupId: (_e = teamContext.team) === null || _e === void 0 ? void 0 : _e.groupId, guestRequestPolicy: teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.GuestRequestPolicy, onAddingComplete: function () {
                                            dispatch(TeamInformationCurrentStore.UpdateTeamState({ currentTabKey: "users", forceAddGuestOpen: false }));
                                            dispatch(LifecycleReportStore.SilentRefresh(ReportSearchSlotName.GuestInsightsSlot));
                                        }, reloadGuestsTrigger: guestListItems, forceOpen: tabState.forceAddGuestOpen })),
                                React.createElement(InsightsReport, { NavigateToDetails: function () { }, ActionParam: undefined, FilterParam: "workspace-guest-memberships", Datasource: ReportDatasource.SQLServer, BasePath: "guests", SubPath: "listing", NamePrefix: "", ResultName: "guest", ShowPagingOnlyIfXAmountOfItems: 50, NoResultMainText: intl.formatMessage({ id: "component.teams-information.guests.no_guests", defaultMessage: "No guests" }), NoResultSubText: intl.formatMessage({
                                        id: "component.team-information.guests.there_are_no_guests_in_this_workspace",
                                        defaultMessage: "There are no guests in this workspace"
                                    }), ShowArchived: false, ShowExport: false, ShowFiltersAndSearch: false, ShowDeleted: false, InitialDynamicValues: { groupId: (_f = teamContext.team) === null || _f === void 0 ? void 0 : _f.groupId }, Slot: ReportSearchSlotName.GuestInsightsSlot, CustomHeader: function (context) { return customHeader(context); }, CustomBulkActions: [customBulkActionResend, customBulkActionRemove], OnTotalResultsChanged: onTotalGuestCountChange })))))))) : (React.createElement(SkeletonLoaderFakeBox, null))))));
});
export default TeamInformationPageUsers;
