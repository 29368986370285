import { Grid, Group } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { ActiveUsersTile } from "./tiles/active-users-tile.component";
import { ChannelMessagesTile } from "./tiles/channel-messages-tile.component";
import { ChatMessagesTile } from "./tiles/chat-messages-tile.component";
import { MentionsTile } from "./tiles/mentions-tile.component";
import { ReactionsTile } from "./tiles/reactions-tile.component";
export var TeamEngagementSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, isMobile = _a.isMobile;
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.TeamsEngagementThisMonth, renderListOfAnnotations: true, sectionTitle: React.createElement(Group, { gap: 0 },
            React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.team_engagement_section.title", defaultMessage: "Team Engagement" }) }),
            "\u00A0",
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", size: "18px", lh: "28px", fw: 400 }, intl.formatMessage({ id: "page.health_check.team_engagement_section.this_period", defaultMessage: "This Period" }))) },
        React.createElement(Grid, { grow: isMobile, gutter: "16px", columns: 5 },
            React.createElement(Grid.Col, { span: 1, miw: "210px" },
                React.createElement(ActiveUsersTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: "210px" },
                React.createElement(ChannelMessagesTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: "210px" },
                React.createElement(ChatMessagesTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: "210px" },
                React.createElement(ReactionsTile, null)),
            React.createElement(Grid.Col, { span: 1, miw: "210px" },
                React.createElement(MentionsTile, null)))));
};
