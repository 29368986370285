import * as React from "react";
import "./azure-storage-setup.scss";
import { useSelector, useDispatch } from "react-redux";
import { Form, Collapse } from "react-bootstrap";
import * as TenantCurrentStore from "app/store/tenants/current";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import axios from "axios";
import DisableArea from "../disable-area";
var AzureStorageSetup = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState(""), connectionString = _a[0], setConnectionString = _a[1];
    var _b = React.useState(null), connectionStringWorked = _b[0], setConnectionStringWorked = _b[1];
    var _c = React.useState(null), connectionStringAdded = _c[0], setConnectionStringAdded = _c[1];
    var _d = React.useState(false), openCustomAdd = _d[0], setOpenCustomAdd = _d[1];
    var _e = React.useState(false), verifiying = _e[0], setVerifiying = _e[1];
    var _f = React.useState(false), adding = _f[0], setAdding = _f[1];
    React.useEffect(function () {
        if (currentTenant.GUID === undefined || (currentTenant.GUID !== undefined && currentTenant.GUID === null)) {
            dispatch(TenantCurrentStore.LoadWithInfo(""));
        }
    }, []);
    React.useEffect(function () {
        console.log("Verifying", verifiying);
    }, [verifiying]);
    var VerifyConnection = function () {
        setConnectionStringWorked(null);
        setVerifiying(true);
        var axiosConfig = {};
        axiosConfig.method = "POST";
        axiosConfig.url = "/api/Tenants/TestAzureStorageKey";
        axiosConfig.responseType = "json";
        var bodyFormData = new FormData();
        bodyFormData.set("data", JSON.stringify({
            PrivateAzureTableDbConnectionString: connectionString
        }));
        axiosConfig.data = bodyFormData;
        axios(axiosConfig)
            .then(function (response) {
            setVerifiying(false);
            if (response.data.worked) {
                setConnectionStringWorked(true);
            }
            else {
                setConnectionStringWorked(false);
            }
        })
            .catch(function (response) { });
    };
    var AddKey = function () {
        setAdding(true);
        var axiosConfig = {};
        axiosConfig.method = "POST";
        axiosConfig.url = "/api/Tenants/AddAzureStorageKey";
        axiosConfig.responseType = "json";
        var bodyFormData = new FormData();
        bodyFormData.set("data", JSON.stringify({
            PrivateAzureTableDbConnectionString: connectionString
        }));
        axiosConfig.data = bodyFormData;
        axios(axiosConfig)
            .then(function (response) {
            if (response.data.worked) {
                setConnectionStringAdded(true);
                dispatch(TenantCurrentStore.LoadWithInfo(""));
            }
            else {
                setConnectionStringAdded(false);
            }
            setAdding(false);
        })
            .catch(function (response) { });
    };
    return (React.createElement("div", { className: "azure-storage-setup" },
        React.createElement("div", { className: "options-area" + (openCustomAdd === true ? " add-custom-cosmos" : "") },
            React.createElement("div", { className: "left" },
                React.createElement("img", { src: "/media/azure-storage-icon.png" })),
            React.createElement("div", { className: "right" }, currentTenant.HasPrivateAzureStorageConnectionString !== undefined && currentTenant.HasPrivateAzureStorageConnectionString === true ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "server-type" }, "Customer Hosted"),
                React.createElement("div", { className: "text" },
                    "Your tenant is configured to use a self-hosted Azure Storage instance. If this is in error please contact ",
                    React.createElement("a", { href: "mailto:support@orchestry.com" }, "Orchestry support"),
                    "."))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "server-type" }, "Orchestry Hosted"),
                React.createElement("div", { className: "text" }, "Your tenant is configured to use Orchestry's Azure Storage."),
                React.createElement("div", { className: "setup-custom" },
                    React.createElement(SubtitleButton, { title: openCustomAdd === false ? "Connect Self-Hosted Azure Storage" : "Hide Cutomer Hosted Setup", size: "sm", variant: "secondary", faIcon: "plug", onClick: function () { return setOpenCustomAdd(!openCustomAdd); } })))))),
        React.createElement(Collapse, { in: openCustomAdd === true, appear: true },
            React.createElement("div", null,
                React.createElement("div", { className: "add-connection-string" },
                    React.createElement("div", { className: "wp-item " + (connectionString.length === 0 ? "undetermined" : "success") },
                        React.createElement("div", { className: "step-info" },
                            React.createElement("div", { className: "step-num-icon" },
                                React.createElement("div", { className: "number" }, "1"),
                                React.createElement("div", { className: "icon" },
                                    React.createElement("i", { className: "fas fa-check icon-success" }),
                                    React.createElement("i", { className: "fas fa-times icon-failed" }),
                                    React.createElement("i", { className: "far fa-circle icon-undetermined" }))),
                            React.createElement("div", { className: "step-name" }, "Customer Hosted Azure Storage Connection String")),
                        React.createElement("div", { className: "step-content" },
                            React.createElement("div", { className: "text" }, "Please enter connection string of the Azure Storage instance created following the instructions in Orchestry documentation."),
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Control, { placeholder: "Azure Storage Connection String", value: connectionString, onChange: function (event) {
                                        setConnectionString(event.target.value);
                                        setConnectionStringWorked(null);
                                    } })))),
                    React.createElement(DisableArea, { isEnabled: connectionString.length !== 0, useOverlay: true },
                        React.createElement("div", { className: "wp-item " + (connectionStringWorked === null ? "undetermined" : connectionStringWorked === true ? "success" : "failed") },
                            React.createElement("div", { className: "step-info" },
                                React.createElement("div", { className: "step-num-icon" },
                                    React.createElement("div", { className: "number" }, "2"),
                                    React.createElement("div", { className: "icon" },
                                        React.createElement("i", { className: "fas fa-check icon-success" }),
                                        React.createElement("i", { className: "fas fa-times icon-failed" }),
                                        React.createElement("i", { className: "far fa-circle icon-undetermined" }))),
                                React.createElement("div", { className: "step-name" }, "Verify Connection String")),
                            React.createElement("div", { className: "step-content" },
                                React.createElement("div", { className: "text" }, "Let's make sure that Orchestry can connect to the Azure Storage. "),
                                verifiying === true ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32, bodyWidth: 237 })) : connectionStringWorked !== null && connectionStringWorked === false ? (React.createElement(SubtitleButton, { faIcon: "server", size: "sm", variant: "danger", title: "Try again and verify connection string", onClick: function () { return VerifyConnection(); }, disabled: connectionString.length === 0 })) : connectionStringWorked !== null && connectionStringWorked === true ? (React.createElement("div", { className: "worked" },
                                    React.createElement("i", { className: "fa fa-check" }),
                                    React.createElement("span", null, "Connected!"))) : (React.createElement(SubtitleButton, { faIcon: "server", size: "sm", color: "orange", title: "Verify connection string", onClick: function () { return VerifyConnection(); }, disabled: connectionString.length === 0 }))))),
                    React.createElement(DisableArea, { isEnabled: connectionStringWorked !== null && connectionStringWorked === true, useOverlay: true },
                        React.createElement("div", { className: "wp-item " + (connectionStringAdded === null ? "undetermined" : connectionStringAdded === true ? "success" : "failed") },
                            React.createElement("div", { className: "step-info" },
                                React.createElement("div", { className: "step-num-icon" },
                                    React.createElement("div", { className: "number" }, "3"),
                                    React.createElement("div", { className: "icon" },
                                        React.createElement("i", { className: "fas fa-check icon-success" }),
                                        React.createElement("i", { className: "fas fa-times icon-failed" }),
                                        React.createElement("i", { className: "far fa-circle icon-undetermined" }))),
                                React.createElement("div", { className: "step-name" }, "Submit Custom Connection String")),
                            React.createElement("div", { className: "step-content" },
                                React.createElement("div", { className: "text" }, "Please Note: This cannot be undone."),
                                adding === true ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32, bodyWidth: 237 })) : connectionStringAdded !== null && connectionStringAdded === false ? (React.createElement("div", { className: "worked" },
                                    React.createElement("i", { className: "fa fa-times" }),
                                    React.createElement("span", null,
                                        "Failed - Please contact ",
                                        React.createElement("a", { href: "mailto:support@orchestry.com" }, "Orchestry support"),
                                        "."))) : connectionStringAdded !== null && connectionStringAdded === true ? (React.createElement("div", { className: "worked" },
                                    React.createElement("i", { className: "fa fa-check" }),
                                    React.createElement("span", null, "Azure Storage successfully configured!"))) : (React.createElement(SubtitleButton, { faIcon: "server", color: "brandblue", size: "sm", title: "Change Azure Storage to customer hosted", onClick: function () { return AddKey(); } }))))))))));
});
export default AzureStorageSetup;
