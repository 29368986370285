import { Chip, Skeleton } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React, { useEffect, useState } from "react";
import { OrchestrySVGIcon } from "../orchestry-svg-icon/orchestry-svg-icon.component";
import { determinePadding } from "./orchestry-chip.utils";
/**
 * To consider: OrchestryFilterItem is a pass-through component to OrchestryChip. Should we merge it with
 * the OrchestryChip component and handle the onClick event better?
 *
 * Let's leave this until a future change gives clarity on the best approach.
 */
export var OrchestryChip = function (props) {
    var _a = useState(props.selected), selected = _a[0], setselected = _a[1];
    var shouldRenderFontIcon = Boolean(props.icon) && !props.svgIcon && selected;
    var shouldRenderSVGIcon = Boolean(props.svgIcon && Boolean(props.svgIcon.iconSelected) && !shouldRenderFontIcon);
    var isClickable = !!props.onClick;
    var shouldRenderClose = Boolean(props.onClick && selected && isClickable);
    useEffect(function () {
        setselected(props.selected);
    }, [props.selected]);
    if (props.isLoading) {
        return React.createElement(Skeleton, { w: 95, h: 28, radius: 16 });
    }
    return (React.createElement(Chip, { wrapperProps: {
            "data-icon-line-height": props.iconLineHeight,
            "data-clickable": isClickable,
            "data-padding": determinePadding(shouldRenderFontIcon, shouldRenderSVGIcon, shouldRenderClose),
            "data-size": props.size || "sm"
        }, color: props.color, variant: props.variantOverride ? props.variantOverride : !!props.color ? "light" : props.value, icon: React.createElement(React.Fragment, null), checked: selected, onClick: isClickable
            ? function () {
                var _a;
                setselected(function (selected) { return !selected; });
                (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props);
            }
            : undefined },
        shouldRenderFontIcon && React.createElement("i", { className: props.icon }),
        shouldRenderSVGIcon && React.createElement(OrchestrySVGIcon, { size: props.svgSize, iconNeutral: props.svgIcon.iconNeutral, iconSelected: props.svgIcon.iconSelected, selected: selected }),
        props.title,
        shouldRenderClose && React.createElement("i", { className: ORCH_ICONS.x })));
};
