import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import InfoTooltip from "app/components/common/infotooltip";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import * as TableList from "app/components/common/table-list";
import TenantService from "app/services/tenant";
import * as TenantListStore from "app/store/tenants/list";
import { PartnerToolsTableLicenseColumn } from "appv2/pages/partner-tools/partner-tools-table-columns.component";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TenantTypes } from "../orchestry-tenant-details/tabs/status/status.component";
import "./orchestry-tenants-list.scss";
var OrchestryTenantsList = React.memo(function () {
    var dispatch = useDispatch();
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var totalResults = useSelector(function (state) { var _a; return (_a = state.tenantListState.paging) === null || _a === void 0 ? void 0 : _a.TotalResults; });
    var _a = React.useState(1), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = React.useState(1000), currentResultsPerPage = _b[0], setCurrentResultsPerPage = _b[1];
    var _c = React.useState("all"), currentView = _c[0], setCurrentView = _c[1];
    var _d = React.useState(true), showViewSelector = _d[0], setShowViewSelector = _d[1];
    var _e = React.useState("DisplayName"), sortBy = _e[0], setSortBy = _e[1];
    var _f = React.useState("ascending"), sortOrder = _f[0], setSortOrder = _f[1];
    var _g = React.useState([
        {
            id: "0",
            title: "All Tenants (Excluding archived)",
            value: "all"
        },
        {
            id: "4",
            title: "At least one private database",
            value: "private"
        },
        {
            id: "6",
            title: "License - Beacon",
            value: "license-beacon"
        },
        {
            id: "6",
            title: "License - Orchestry",
            value: "license-orchestry"
        },
        {
            id: "6",
            title: "License - Orchestry Trial",
            value: "license-orchestry-trial"
        },
        {
            id: "6",
            title: "License - Unlicensed",
            value: "license-unlicensed"
        },
        {
            id: "7",
            title: "Archived Tenants",
            value: "archived"
        },
        {
            id: "8",
            title: "Tenant Type - Partner Hubs",
            value: "type-partner-hubs"
        },
        {
            id: "9",
            title: "Tenant Type - Partner Customers",
            value: "type-partner-customers"
        },
        {
            id: "10",
            title: "Tenant Type - Partner Sandboxes",
            value: "type-partner-sandbox"
        },
        {
            id: "11",
            title: "Tenant Type - Orchestry Internal",
            value: "type-orchestry-internal"
        },
        {
            id: "12",
            title: "Tenant Type - Orchestry Customer",
            value: "type-orchestry-customer"
        },
        {
            id: "13",
            title: "Tenant Type - Not Defined",
            value: "type-not-defined"
        }
    ]), listViews = _g[0], setListViews = _g[1];
    // React.useEffect(() => {
    //    dispatch(TenantListStore.Load())
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    React.useEffect(function () {
        // if (showViewSelector === true) {
        dispatch(TenantListStore.Load_Page({
            items: [],
            // view: orchestryContext.IsCurrentUserAzureGlobalAdmin || orchestryContext.IsCurrentUserOrchestryAdmin || orchestryContext.IsOrchestryMaintenance ? currentView : "my-approvals",
            view: currentView,
            paging: {
                CurrentPage: currentPage,
                ResultsPerPage: currentResultsPerPage,
                SortOrder: sortOrder,
                SortBy: sortBy
            }
        }));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, currentResultsPerPage, currentView, showViewSelector, sortOrder, sortBy]);
    var renderActions = function (tenant) {
        //console.log("renderType", template.TemplateType);
        return (React.createElement("div", null, tenant.GUID !== undefined ? (React.createElement(InfoTooltip, { content: "Provision Test Data for " + tenant.GUID },
            React.createElement("div", { className: "btn btn-sm btn-clean btn-icon btn-icon-sm", onClick: function () {
                    seedTestDataToTenant(tenant);
                } },
                React.createElement(FontAwesomeIcon, { icon: Icons.faCubes })))) : null));
    };
    var ReloadData = function (columnName) {
        // If column name hasn't changed, flip ascending/descending variable
        if (columnName === sortBy)
            setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
        else
            setSortOrder("descending");
        // Update column name
        setSortBy(columnName);
    };
    var renderGuid = function (tenant) {
        //console.log("renderType", template.TemplateType);
        if (tenant.GUID === "00000000-0000-0000-0000-000000000000") {
            return (React.createElement("div", null,
                React.createElement(InfoTooltip, { content: "Tenant is not yet registered in Orchestry. This means that nobody from this organization has yet logged into our system" },
                    React.createElement(FontAwesomeIcon, { icon: Icons.faTrafficLight, style: { cursor: "help", color: "#cece23", fontSize: "20px" } }))));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(InfoTooltip, { content: "Tenant Id > " + tenant.GUID },
                    React.createElement(FontAwesomeIcon, { icon: Icons.faFingerprint, style: { opacity: "0.3", cursor: "help", fontSize: "20px" } }))));
        }
    };
    var toggleSubscription = function (item, isSubscribed) {
        if (item.GUID !== undefined) {
            item = Object.assign(item, { IsSubscribed: isSubscribed });
            dispatch({ type: TenantListStore.TenantListActionTypes.Tenant_List_Item_Update, payload: item });
        }
    };
    var seedTestDataToTenant = function (item) {
        if (item.GUID !== undefined) {
            TenantService.seedTestDataToTenant(item.GUID).then(function () {
                alert("Test Data Seeding Complete!");
            });
        }
    };
    var getTenantType = function (tenantType) {
        var _a;
        var type = (_a = TenantTypes.find(function (x) { return x.value === tenantType; })) === null || _a === void 0 ? void 0 : _a.title;
        return type;
    };
    return (React.createElement("div", { className: "home-admin orchestry-tenant-list fadein-500ms tenant-list" },
        React.createElement(PageHeader.SetLeftContent, { title: "Orchestry Tenants", subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Enter tenant name" }),
            React.createElement("div", { style: { width: "300px" } },
                React.createElement(FormDropdownSelect, { list: listViews, defaultValue: currentView, onChange: function (value) {
                        setCurrentView(value);
                        setCurrentPage(1);
                    } }))),
        React.createElement(Portlet, { className: "current-status", fluidHeight: true },
            React.createElement(PortletBody, null,
                React.createElement(TableList.Context, { listItems: tenants, wrappedInPortlet: false, asyncListLoadUID: TenantListStore.TenantListActionTypes.Tenant_List_Load_Page, sortOrder: sortOrder, sortBy: sortBy },
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(TableList.TableList, null,
                                React.createElement(TableList.HeaderItem, { sortable: true, ReloadData: ReloadData, DataProperyName: "DisplayName" }, "Display Name"),
                                React.createElement(TableList.HeaderItem, { sortable: true, ReloadData: ReloadData, DataProperyName: "IsSubscribed" }, "License"),
                                React.createElement(TableList.HeaderItem, { sortable: false, ReloadData: ReloadData, DataProperyName: "HasPrivateCosmosDbConnectionString" },
                                    React.createElement("div", { style: { fontSize: "10px" } }, "Has Private Database")),
                                React.createElement(TableList.HeaderItem, { sortable: false }, "Region"),
                                React.createElement(TableList.HeaderItem, { sortable: false }, "Tenant Type"),
                                React.createElement(TableList.HeaderItem, { sortable: true, ReloadData: ReloadData, DataProperyName: "TrialExpires" }, "Trial Expires"),
                                React.createElement(TableList.HeaderItem, { sortable: true, ReloadData: ReloadData, DataProperyName: "LastInstalledVersion" }, "Current Version"),
                                React.createElement(TableList.HeaderItem, { type: "actions" }),
                                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "DisplayName", listDataSubProperyName: "GUID", isMobileTitle: true, link: "/orchestry-admin/tenants/details?GUID={GUID}" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) {
                                        return React.createElement(React.Fragment, null, PartnerToolsTableLicenseColumn(item.LicenseStatus));
                                    } }),
                                React.createElement(TableList.ContentItemCustomRender, { className: "td-bool-icon text-center", render: function (tenant) { return (React.createElement(React.Fragment, null, tenant.HasPrivateCosmosDbConnectionString || tenant.PrivateCosmosDbConnectionString || tenant.TenantDbSource === "Private" ? (React.createElement("div", { className: "icon-is-false" },
                                        React.createElement("i", { className: "far fa-check-circle", "aria-hidden": "true" }))) : (React.createElement("div", { className: "icon-is-true" },
                                        React.createElement("i", { className: "far fa-circle", "aria-hidden": "true" }))))); } }),
                                React.createElement(TableList.ContentItem, { listDataProperyName: "Region" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (tenant) { return React.createElement("span", null, getTenantType(tenant.TenantType)); } }),
                                React.createElement(TableList.ContentItemDate, { datetimeFormat: "LL", listDataProperyName: "TrialExpires" }),
                                React.createElement(TableList.ContentItem, { listDataProperyName: "LastInstalledVersion" }),
                                React.createElement(TableList.ContentItemActions, { onRemove: function (item) { return dispatch(TenantListStore.DeleteItem(item)); }, deleteCheckType: "tenants" })),
                            React.createElement(TableList.SearchPaging, { currentPage: currentPage, currentResultsPerPage: currentResultsPerPage, setCurrentPage: setCurrentPage, setCurrentResultsPerPage: setCurrentResultsPerPage, totalResults: totalResults }))))))));
});
export default OrchestryTenantsList;
