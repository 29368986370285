/**
 * Recommendation Priority
 */
export var RecommendationSource;
(function (RecommendationSource) {
    /**
     * Orchestry
     */
    RecommendationSource["Orchestry"] = "Orchestry";
    /**
     * Custom Recommendation
     */
    RecommendationSource["Custom"] = "Custom";
})(RecommendationSource || (RecommendationSource = {}));
