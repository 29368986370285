import FormDropdownSelect from "app/components/common/form-dropdown-select";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import FormNamingPolicy from "app/components/common/form-naming-policy";
import FormOfficeAssetPicker from "app/components/common/form-office-asset-picker";
import FormSelectBox from "app/components/common/form-select-box";
import FormTextList from "app/components/common/form-text-list";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import FormValidationContext, { FormValidator, FormValidatorRollup } from "app/components/common/form-validation";
import FormWebhook from "app/components/common/form-webhook";
import InfoToolTip from "app/components/common/infotooltip";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import SharePointService from "app/services/sharepoint";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import { debounce } from "lodash";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SecondStageNamingOptionPreview from "./naming-option-preview";
import "./step-card-second-stage.scss";
var StepCardSecondStage = React.memo(function (props) {
    var _a = React.useState(null), selectedSiteDocumentLibs = _a[0], setSelectedSiteDocumentLibs = _a[1];
    var _b = React.useState(), currentTab = _b[0], setCurrentTab = _b[1];
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        setCurrentTab(currentPolicy.Application === "Team" ? "team" : currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary" ? "naming" : "naming");
    }, [currentPolicy.Application, currentPolicy.SecondStage_ArchivalMethod]);
    React.useEffect(function () {
        if (currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary") {
            if (currentPolicy.SecondStage_Option_Content_Archival_Location !== null &&
                currentPolicy.SecondStage_Option_Content_Archival_Location !== undefined &&
                currentPolicy.SecondStage_Option_Content_Archival_Location !== "") {
                var spSite = JSON.parse(currentPolicy.SecondStage_Option_Content_Archival_Location);
                SharePointService.GetAllListsFromWeb(spSite.URL).then(function (lists) {
                    setSelectedSiteDocumentLibs(lists.filter(function (l) {
                        return l.BaseTemplate === 101 &&
                            l.ServerRelativeUrl.indexOf("/Style Library") === -1 &&
                            l.ServerRelativeUrl.indexOf("/SiteAssets") === -1 &&
                            l.ServerRelativeUrl.indexOf("/FormServerTemplates") === -1;
                    }));
                });
            }
        }
    }, [currentPolicy.SecondStage_ArchivalMethod, currentPolicy.SecondStage_Option_Content_Archival_Location]);
    return (React.createElement("div", { className: "step-card-second-stage policy-card", "data-pw": "step-card-second-step" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                        props.OnProceedChange(isValid);
                    }, UniqueIdentifier: props.StepKey },
                    React.createElement("div", { className: "second-stage-contents" },
                        React.createElement("div", { className: "middle card-border", "data-pw": "select-secondary-method" },
                            React.createElement("h4", null, "Select Secondary Method"),
                            React.createElement(Form.Group, { controlId: "second-stage-name" },
                                React.createElement(FormSelectBox, { list: [
                                        {
                                            value: "MoveContentOnlyToDifferentSiteLibrary",
                                            title: "Move to SharePoint Archive",
                                            subtitle: "Copy the desired contents of the current Site, Team or Group to a document library within an archival site - then delete the original underlying Team, Site or Group.",
                                            icon: "fa-regular fa-boxes-packing"
                                        },
                                        {
                                            value: "Delete",
                                            title: "Delete",
                                            subtitle: "Deletes the Site, Group or Team",
                                            icon: "fa-regular fa-trash"
                                        }
                                    ], value: currentPolicy.SecondStage_ArchivalMethod, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_ArchivalMethod: value })); } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_ArchivalMethod", FieldName: "Second Stage Archival Method", Validators: ["dropdown-has-value"], Value: currentPolicy.SecondStage_ArchivalMethod, Enabled: currentPolicy.SecondStage_Enabled, OnScreen: currentPolicy.SecondStage_Enabled }),
                                currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary" ? React.createElement("hr", null) : null),
                            React.createElement(Collapse, { in: currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary" },
                                React.createElement("div", null,
                                    React.createElement("h6", null,
                                        "Archival Location ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement("p", { className: "text" }, "Choose the Site and Document library that will be used for any moved content."),
                                    React.createElement(FormOfficeAssetPicker, { pickerType: "SharePoint", showSelectedText: false, value: currentPolicy.SecondStage_Option_Content_Archival_Location, onChange: function (serializedItem) {
                                            setSelectedSiteDocumentLibs(null);
                                            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                SecondStage_Option_Content_Archival_Location: serializedItem,
                                                SecondStage_Option_Content_Archival_Location_Library: null
                                            }));
                                        } }),
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 5 },
                                            React.createElement(Form.Group, { className: "document-lib-picker", "data-pw": "document-library" },
                                                React.createElement(Form.Label, null,
                                                    "Document Library ",
                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                currentPolicy.SecondStage_Option_Content_Archival_Location == null || currentPolicy.SecondStage_Option_Content_Archival_Location === "" ? (React.createElement(Form.Control, { disabled: true, placeholder: "Please select a SharePoint site above first..." })) : selectedSiteDocumentLibs === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { list: selectedSiteDocumentLibs.map(function (l) { return ({
                                                        id: l.Id,
                                                        value: l.Id,
                                                        title: l.Title,
                                                        description: l.ServerRelativeUrl
                                                    }); }), value: currentPolicy.SecondStage_Option_Content_Archival_Location_Library, onChange: function (selected) {
                                                        dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                            SecondStage_Option_Content_Archival_Location_Library: selected
                                                        }));
                                                    } }))))),
                                    React.createElement(FormValidator, { UniqueIdentifier: "move-content-to-different-site", ErrorMessage: "Select a Second Stage Archival Location under Actions > Content Actions", Validators: ["string-not-empty"], Enabled: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary", OnScreen: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary", Value: currentPolicy.SecondStage_Option_Content_Archival_Location }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "move-content-to-different-site-doc-lib", ErrorMessage: "Select a Second Stage Archival Location Document Library under Actions > Content Actions", Validators: ["string-not-empty"], Enabled: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary", OnScreen: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary", Value: currentPolicy.SecondStage_Option_Content_Archival_Location_Library }),
                                    React.createElement("hr", null))),
                            React.createElement(Collapse, { in: currentPolicy.SecondStage_ArchivalMethod !== null && currentPolicy.SecondStage_ArchivalMethod !== undefined },
                                React.createElement("div", { className: "vertical-property-value", "data-pw": "execute-second-step-after" },
                                    React.createElement("div", { className: "execute-label", "data-pw": "execute-label" },
                                        React.createElement("h6", null,
                                            "Execute second stage archival after ",
                                            React.createElement("span", { className: "required-astrix" }, "*")),
                                        React.createElement(InfoToolTip, null, "This value configures the additional amount of time that should pass between the first and second steps of the archival.")),
                                    React.createElement("div", null,
                                        React.createElement(FormDropdownSelect, { list: [
                                                {
                                                    id: "1",
                                                    title: "30 Days",
                                                    value: 30
                                                },
                                                {
                                                    id: "2",
                                                    title: "60 Days",
                                                    value: 60
                                                },
                                                {
                                                    id: "3",
                                                    title: "90 Days",
                                                    value: 90
                                                },
                                                {
                                                    id: "4",
                                                    title: "180 Days",
                                                    value: 180
                                                },
                                                {
                                                    id: "5",
                                                    title: "365 Days",
                                                    value: 365
                                                },
                                                {
                                                    id: "6",
                                                    title: "2 Years",
                                                    value: 730
                                                },
                                                {
                                                    id: "7",
                                                    title: "3 Years",
                                                    value: 1095
                                                },
                                                { id: "8", title: "5 Years", value: 1825 },
                                                {
                                                    id: "9",
                                                    title: "7 Years",
                                                    value: 2555
                                                },
                                                {
                                                    id: "10",
                                                    title: "9 Years",
                                                    value: 3285
                                                },
                                                {
                                                    id: "11",
                                                    title: "10 Years",
                                                    value: 3650
                                                }
                                            ], defaultValue: currentPolicy.SecondStage_DaysThreshold, value: currentPolicy.SecondStage_DaysThreshold, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_DaysThreshold: value })); } }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "secondstage-days-threshold", FieldName: "Second Stage Archival Threshold", Validators: ["dropdown-has-value"], Value: currentPolicy.SecondStage_DaysThreshold, Enabled: currentPolicy.SecondStage_Enabled &&
                                                currentPolicy.SecondStage_ArchivalMethod !== null &&
                                                currentPolicy.SecondStage_ArchivalMethod !== undefined &&
                                                currentPolicy.SecondStage_DaysThreshold !== null &&
                                                currentPolicy.SecondStage_DaysThreshold !== undefined, OnScreen: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_ArchivalMethod !== null && currentPolicy.SecondStage_ArchivalMethod !== undefined }))))),
                        React.createElement(Collapse, { in: currentPolicy.SecondStage_ArchivalMethod !== null && currentPolicy.SecondStage_ArchivalMethod !== undefined },
                            React.createElement("div", null,
                                React.createElement("div", { className: "copy-and-delete-cards" },
                                    React.createElement(Collapse, { in: currentPolicy.SecondStage_ArchivalMethod === "MoveContentOnlyToDifferentSiteLibrary" },
                                        React.createElement("div", { className: "middle card-border", "data-pw": "second-step-configure-actions" },
                                            React.createElement("h4", null, "Configure Actions"),
                                            React.createElement("div", { className: "action-row card-border" },
                                                React.createElement("div", { className: "left-nav" },
                                                    currentPolicy.Application === "Team" ? (React.createElement("div", { className: "nav-item" + (currentTab === "team" ? " selected" : ""), onClick: function () { return setCurrentTab("team"); } },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png" })),
                                                        React.createElement("div", { className: "text" }, "Teams Options"),
                                                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "team" }))) : null,
                                                    React.createElement("div", { className: "nav-item" + (currentTab === "naming" ? " selected" : ""), onClick: function () { return setCurrentTab("naming"); } },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("i", { className: "fa fa-bold" })),
                                                        React.createElement("div", { className: "text" }, "Naming Options"),
                                                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "naming" })),
                                                    React.createElement("div", { className: "nav-item" + (currentTab === "content" ? " selected" : ""), onClick: function () { return setCurrentTab("content"); } },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("i", { className: "fa fa-book" })),
                                                        React.createElement("div", { className: "text" }, "Content Actions"),
                                                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, RefreshProperty: currentTab, Category: "content" })),
                                                    React.createElement("div", { className: "nav-item" + (currentTab === "permissions" ? " selected" : ""), onClick: function () { return setCurrentTab("permissions"); } },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("i", { className: "fas fa-id-card" })),
                                                        React.createElement("div", { className: "text" }, "Security Options"),
                                                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "permissions" })),
                                                    React.createElement("div", { className: "nav-item" + (currentTab === "integrations" ? " selected" : ""), onClick: function () { return setCurrentTab("integrations"); } },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("i", { className: "flaticon-layers" })),
                                                        React.createElement("div", { className: "text" }, "Integrations"),
                                                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "integrations" }))),
                                                React.createElement("div", { className: "right-content-area" },
                                                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                                                            display: currentTab === "team" ? "block" : "none"
                                                        } },
                                                        React.createElement(SecondStageTabTeamOptions, null)),
                                                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                                                            display: currentTab === "naming" ? "block" : "none"
                                                        } },
                                                        React.createElement(SecondStageTabNamingOptions, null)),
                                                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                                                            display: currentTab === "content" ? "block" : "none"
                                                        } },
                                                        React.createElement(SecondStageTabContentOptions, null)),
                                                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                                                            display: currentTab === "permissions" ? "block" : "none"
                                                        } },
                                                        React.createElement(SecondStageTabSecurityOptions, null)),
                                                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                                                            display: currentTab === "integrations" ? "block" : "none"
                                                        } },
                                                        React.createElement(SecondStageTabIntegrations, null)))))),
                                    React.createElement("div", { className: "middle card-border", "data-pw": "second-step-policy-execution" },
                                        React.createElement("h4", null, "Policy Execution"),
                                        React.createElement(Form.Group, { controlId: "second-stage-notification" },
                                            React.createElement(FormSelectBox, { list: [
                                                    {
                                                        value: "ExecuteAutomatically",
                                                        title: "No Notification",
                                                        subtitle: "Policy will execute automatically without the need for any intervention. No notifications will be sent.",
                                                        iconJsx: (React.createElement("div", { className: "custom-icon-execute-workspaces" },
                                                            React.createElement("i", { className: "fa-regular fa-bell-slash" })))
                                                    },
                                                    {
                                                        value: "NotificationExecution",
                                                        title: "Notification",
                                                        subtitle: "Policy will execute automatically without the need for any intervention. An informational notification will be sent.",
                                                        iconJsx: (React.createElement("div", { className: "custom-icon-execute-workspaces" },
                                                            React.createElement("i", { className: "fa-regular fa-bell-on" })))
                                                    },
                                                    {
                                                        value: "ApproveExecution",
                                                        title: "Approval",
                                                        subtitle: "A Policy Approval will be required, and will allow the approver to either accept the archival or request an extension of the workspace.",
                                                        disabled: true,
                                                        iconJsx: (React.createElement("div", { className: "custom-icon-check-workspaces" },
                                                            React.createElement("i", { className: "fa-regular fa-badge-check" })))
                                                    }
                                                ], defaultValue: "ExecuteAutomatically", value: currentPolicy.SecondStage_Execution_Type, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_Execution_Type: value })); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Execution_Type", FieldName: "Second Stage Execution Type", Validators: ["dropdown-has-value"], Value: currentPolicy.SecondStage_Execution_Type, Enabled: currentPolicy.SecondStage_Enabled &&
                                                    currentPolicy.SecondStage_ArchivalMethod !== null &&
                                                    currentPolicy.SecondStage_ArchivalMethod !== undefined &&
                                                    currentPolicy.SecondStage_DaysThreshold !== null &&
                                                    currentPolicy.SecondStage_DaysThreshold !== undefined, OnScreen: currentPolicy.SecondStage_ArchivalMethod !== null &&
                                                    currentPolicy.SecondStage_ArchivalMethod !== undefined &&
                                                    currentPolicy.SecondStage_DaysThreshold !== null &&
                                                    currentPolicy.SecondStage_DaysThreshold !== undefined }))),
                                    React.createElement(Collapse, { in: currentPolicy.SecondStage_Execution_Type === "NotificationExecution" },
                                        React.createElement("div", { className: "middle card-border" },
                                            React.createElement("div", { className: "card-info fadein-1s" },
                                                React.createElement("div", { className: "content approvers", "data-pw": "policy-stakeholders" },
                                                    React.createElement(SecondStageNotification, null))))))))))))));
});
export default StepCardSecondStage;
var SecondStageNotification = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var debouncedDispatch = React.useCallback(debounce(dispatch, 500), []);
    var getSafeTemplateText = function () {
        if (currentPolicy.SecondStage_Execution_Notification_NotificationText) {
            if (currentPolicy.SecondStage_Execution_Notification_NotificationText.includes("\\r\\n")) {
                return JSON.stringify(currentPolicy.SecondStage_Execution_Notification_NotificationText.split("\\r\\n").map(function (item) {
                    return {
                        type: "text",
                        children: [{ text: item.trim() }]
                    };
                }));
            }
            else if (currentPolicy.SecondStage_Execution_Notification_NotificationText.includes("\r\n")) {
                return JSON.stringify(currentPolicy.SecondStage_Execution_Notification_NotificationText.split("\r\n").map(function (item) {
                    return {
                        type: "text",
                        children: [{ text: item.trim() }]
                    };
                }));
            }
        }
        return currentPolicy.SecondStage_Execution_Notification_NotificationText;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null, "Policy Stakeholders"),
        React.createElement("p", null, "Identify the stakeholders that you wish to notify and involve (if applicable) as part of this policy execution method."),
        React.createElement("div", { className: "vertical-property-value" },
            React.createElement("div", { className: "label" },
                "Stakeholders ",
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement("div", null,
                React.createElement(FormDropdownSelect, { list: [
                        {
                            id: "1",
                            title: "Workspace Owners",
                            value: "WorkspaceOwners"
                        },
                        {
                            id: "2",
                            title: "Workspace Members",
                            value: "WorkspaceMembers"
                        },
                        {
                            id: "3",
                            title: "Specific Users",
                            value: "SpecificUsers"
                        }
                    ], value: currentPolicy.SecondStage_Execution_Notification_Stakeholders, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_Execution_Notification_Stakeholders: value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Execution_Type", FieldName: "Stakeholders", Validators: ["dropdown-has-value"], Enabled: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_Execution_Type === "NotificationExecution", Value: currentPolicy.SecondStage_Execution_Notification_Stakeholders }))),
        React.createElement(Collapse, { in: currentPolicy.SecondStage_Execution_Notification_Stakeholders === "SpecificUsers" },
            React.createElement("div", { className: "approvers-specific-users" },
                React.createElement(FormUserGroupSelect, { defaultValue: currentPolicy.SecondStage_Execution_Notification_SpecificUsers === null || currentPolicy.SecondStage_Execution_Notification_SpecificUsers === undefined
                        ? undefined
                        : currentPolicy.SecondStage_Execution_Notification_SpecificUsers, allowMultiple: true, returnType: "usersAndGroups", displayAddAsButton: true, onChange: function (groups) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_Execution_Notification_SpecificUsers: groups })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Execution_Notification_SpecificUsers", 
                    //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                    FieldName: "Specific Notification Users", Validators: ["string-array-has-items"], Enabled: currentPolicy.SecondStage_Enabled &&
                        currentPolicy.SecondStage_Execution_Type === "NotificationExecution" &&
                        currentPolicy.SecondStage_Execution_Notification_Stakeholders === "SpecificUsers", Value: currentPolicy.SecondStage_Execution_Notification_SpecificUsers }))),
        React.createElement("div", { className: "sub-title" }, "Notification Text"),
        React.createElement(MarkdownEditor, { enabledStyles: { all: true }, layout: "text", templateText: getSafeTemplateText() || LifecyclePolicyCurrentStore.defaultSecondStageNotificationString, onChange: function (body) {
                debouncedDispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_Execution_Notification_NotificationText: body }));
            } })));
});
var SecondStageTabTeamOptions = function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Team Options"),
            React.createElement("h6", null, "Post Settings"),
            React.createElement(Form.Group, { controlId: "second-stage-export-channel-post-to-excel", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Export Channel Posts to Excel",
                        React.createElement(InfoToolTip, null, "Exports the posts from all the channels into an Excel file")), checked: currentPolicy.SecondStage_Option_Team_Post_ExportChannelPostsToExcel, onChange: function (event) {
                        dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Team_Post_ExportChannelPostsToExcel: event.target.checked
                        }));
                    } })))));
};
var SecondStageTabNamingOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Destination Folder Naming"),
            React.createElement(Form.Group, { controlId: "second-stage-change-team-group-name", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Folder Name",
                        React.createElement(InfoToolTip, null, "Changes the name of the folder in the destination document library")), checked: currentPolicy.SecondStage_Option_Naming_TeamGroup_Change, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Naming_TeamGroup_Change: event.target.checked
                        }));
                    } })),
            React.createElement(Collapse, { in: currentPolicy.SecondStage_Option_Naming_TeamGroup_Change === true },
                React.createElement("div", null,
                    React.createElement("div", { className: "naming-preview-format" },
                        React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", title: "Archived Folder Name", message: React.createElement(SecondStageNamingOptionPreview, { type: "name", currentPolicy: currentPolicy, title: "Workspace Name" }) })),
                    React.createElement(Form.Group, { controlId: "second-stage-add-teamgroup-prefix", className: "checkbox-blue" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Add Prefix",
                                " ",
                                currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixAdd === false && currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixAdd === false && (React.createElement("span", { className: "required-astrix" }, "*"))), checked: currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixAdd, onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    SecondStage_Option_Naming_TeamGroup_PrefixAdd: event.target.checked
                                }));
                            } })),
                    React.createElement(Collapse, { in: currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixAdd === true },
                        React.createElement("div", { className: "naming-policy-selector" },
                            React.createElement(FormNamingPolicy, { placeholder: "-- Add Prefix --", value: currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixValues, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        SecondStage_Option_Naming_TeamGroup_PrefixValues: value
                                    }));
                                }, metadataTypes: ["Date-us-long", "Date-uk-long", "Date-sortfriendly-long", "Date-us-short", "Date-uk-short", "Date-sortfriendly-short"], validatorCategory: "naming" }))),
                    React.createElement(Form.Group, { controlId: "second-stage-add-teamgroup-suffix", className: "checkbox-blue" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Add Suffix",
                                " ",
                                currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixAdd === false && currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixAdd === false && (React.createElement("span", { className: "required-astrix" }, "*"))), checked: currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixAdd, onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    SecondStage_Option_Naming_TeamGroup_SuffixAdd: event.target.checked
                                }));
                            } })),
                    React.createElement(Collapse, { in: currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixAdd === true },
                        React.createElement("div", { className: "naming-policy-selector" },
                            React.createElement(FormNamingPolicy, { placeholder: "-- Add Suffix --", value: currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixValues, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        SecondStage_Option_Naming_TeamGroup_SuffixValues: value
                                    }));
                                }, metadataTypes: ["Date-us-long", "Date-uk-long", "Date-sortfriendly-long", "Date-us-short", "Date-uk-short", "Date-sortfriendly-short"], validatorCategory: "naming" }))))),
            React.createElement(FormValidator, { UniqueIdentifier: "change-team-group-name", ErrorMessage: "Please configure at least one Folder prefix or suffix", Validators: ["is-false"], Category: "naming", Enabled: currentPolicy.SecondStage_Enabled && currentPolicy.SecondStage_Option_Naming_TeamGroup_Change === true, Value: currentPolicy.SecondStage_Option_Naming_TeamGroup_Change === true &&
                    currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixAdd === false &&
                    currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixAdd === false }))));
});
var SecondStageTabContentOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var scopeDropdownValues = React.useState([
        {
            id: "1",
            title: "Archive All Versions",
            value: "AllVersions"
        },
        {
            id: "2",
            title: "Archive Most Current Version",
            description: "All Previous versions will be removed",
            value: "MostCurrentVersion"
        },
        {
            id: "3",
            title: "Archive All Major Versions",
            description: "All Previous Minor Versions will be removed",
            value: "AllMajorVersions"
        }
    ])[0];
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Content Actions"),
            React.createElement("h6", null, "Documents"),
            React.createElement(Form.Group, { controlId: "second-stage-process-site-documents", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Process Site Documents",
                        React.createElement(InfoToolTip, null, "Process the Document Libraries in the SharePoint Site")), checked: currentPolicy.SecondStage_Option_Content_Documents_Process, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Content_Documents_Process: event.target.checked
                        }));
                    } })),
            React.createElement(Collapse, { in: currentPolicy.SecondStage_Option_Content_Documents_Process === true },
                React.createElement("div", null,
                    React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" },
                            "Scope ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", null,
                            React.createElement(FormDropdownSelect, { placeholder: "Select a scope", list: [
                                    {
                                        id: "1",
                                        title: "All Document Libraries",
                                        value: "AllDocumentLibraries"
                                    },
                                    {
                                        id: "2",
                                        title: "Choose Libraries",
                                        value: "ChooseLibraries"
                                    }
                                ], value: currentPolicy.SecondStage_Option_Content_Documents_Scope, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        SecondStage_Option_Content_Documents_Scope: value
                                    }));
                                } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Option_Content_Documents_Scope", FieldName: "Process Site Documents Scope", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.SecondStage_Option_Content_Documents_Process === true, Value: currentPolicy.SecondStage_Option_Content_Documents_Scope }))),
                    currentPolicy.SecondStage_Option_Content_Documents_Scope === "ChooseLibraries" ? (React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "list-lib-selector" },
                                React.createElement(FormTextList, { onChange: function (values) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            SecondStage_Option_Content_Documents_Scope_ChosenLibs: values
                                        }));
                                    }, itemsIcon: "fa fa-folder", splitCharacter: "|", values: currentPolicy.SecondStage_Option_Content_Documents_Scope_ChosenLibs, addButtonText: "Add document library name", saveButtonText: "Save name", addInputPlaceholderText: "Enter library name", noItemsAddedText: "No document library names added" }))))) : null,
                    React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" },
                            "Version ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", null,
                            React.createElement(FormDropdownSelect, { placeholder: "Select a version", list: scopeDropdownValues, value: currentPolicy.SecondStage_Option_Content_Documents_Versions, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        SecondStage_Option_Content_Documents_Versions: value
                                    }));
                                } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Option_Content_Documents_Versions", FieldName: "Process Site Documents Version", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.SecondStage_Option_Content_Documents_Process === true, Value: currentPolicy.SecondStage_Option_Content_Documents_Versions }))))),
            React.createElement("hr", null),
            React.createElement("h6", null, "Lists"),
            React.createElement(Form.Group, { controlId: "second-stage-process-site-lists", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Export Lists to Excel",
                        React.createElement(InfoToolTip, null, "Process any Lists in the SharePoint site and export them to an Excel File")), checked: currentPolicy.SecondStage_Option_Content_Lists_Process, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Content_Lists_Process: event.target.checked
                        }));
                    } })),
            React.createElement(Collapse, { in: currentPolicy.SecondStage_Option_Content_Lists_Process === true },
                React.createElement("div", null,
                    React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" },
                            "Scope ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", null,
                            React.createElement(FormDropdownSelect, { placeholder: "Select a scope", list: [
                                    {
                                        id: "1",
                                        title: "All Lists",
                                        value: "AllLists"
                                    },
                                    {
                                        id: "2",
                                        title: "Choose Lists",
                                        value: "ChooseLists"
                                    }
                                ], value: currentPolicy.SecondStage_Option_Content_Lists_Scope, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_Option_Content_Lists_Scope: value })); } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Option_Content_Lists_Scope", FieldName: "Process List Scope", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.SecondStage_Option_Content_Lists_Process === true, Value: currentPolicy.SecondStage_Option_Content_Lists_Scope }))),
                    currentPolicy.SecondStage_Option_Content_Lists_Scope === "ChooseLists" ? (React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "list-lib-selector" },
                                React.createElement(FormTextList, { onChange: function (values) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            SecondStage_Option_Content_Lists_Scope_ChosenLists: values
                                        }));
                                    }, itemsIcon: "fa fa-list-alt", splitCharacter: "|", values: currentPolicy.SecondStage_Option_Content_Lists_Scope_ChosenLists, addButtonText: "Add list name", saveButtonText: "Save name", addInputPlaceholderText: "Enter list name", noItemsAddedText: "No list names added yet" }))))) : null)),
            React.createElement("hr", null),
            React.createElement("h6", null, "Pages"),
            React.createElement(Form.Group, { controlId: "second-stage-process-site-pages", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Backup Pages to XML",
                        React.createElement(InfoToolTip, null, "Backup Pages to a PnP export xml file")), checked: currentPolicy.SecondStage_Option_Content_Pages_Process, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Content_Pages_Process: event.target.checked
                        }));
                    } })),
            React.createElement(Collapse, { in: currentPolicy.SecondStage_Option_Content_Pages_Process === true },
                React.createElement("div", null,
                    React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" },
                            "Version ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", null,
                            React.createElement(FormDropdownSelect, { placeholder: "Select a scope", list: scopeDropdownValues, value: currentPolicy.SecondStage_Option_Content_Pages_Versions, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        SecondStage_Option_Content_Pages_Versions: value
                                    }));
                                } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "SecondStage_Option_Content_Pages_Versions", FieldName: "Process Page Version", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.SecondStage_Option_Content_Pages_Process === true, Value: currentPolicy.SecondStage_Option_Content_Pages_Versions }))))))));
});
var TabGroupOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Group Options"),
            React.createElement(Form.Group, { controlId: "second-stage-export-planner-plans", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Export Planner Plans to Excel File",
                        React.createElement(InfoToolTip, null, "Exports any planner plans to an Excel file")), checked: currentPolicy.SecondStage_Option_Group_Planner_ExportPlannerPlansToExcelFile, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Group_Planner_ExportPlannerPlansToExcelFile: event.target.checked
                        }));
                    } })))));
});
var SecondStageTabSecurityOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement(Row, { className: "permissions-tab-content" },
        React.createElement(Col, null,
            React.createElement("h4", null, "Permissions"),
            React.createElement("h6", null, "Permission Configuration"),
            React.createElement(Form.Group, { controlId: "second-stage-extract-permission-configuration", className: "checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Extract permission configuration",
                        React.createElement(InfoToolTip, null, "Extracts the current Group and SharePoint site permissions into an Excel file")), checked: currentPolicy.SecondStage_Option_Permissions_Config_Extract, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Option_Permissions_Config_Extract: event.target.checked
                        }));
                    } })))));
});
var SecondStageTabIntegrations = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "webhook-tab" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h4", null, "Integrations"),
                React.createElement("h6", null, "Webhook"),
                React.createElement(FormWebhook, { exampleJson: {
                        Secret: "",
                        WorkspaceName: "Example Name",
                        WorkspaceType: "Team|TeamSite|CommunicationSite",
                        SiteId: "00000000-0000-0000-0000-000000000000",
                        SiteUrl: "https://example.sharepoint.com/sites/example",
                        GroupId: "00000000-0000-0000-0000-000000000000",
                        LastActivityDate: "2000-01-01T01:01:01.0000000",
                        DaysInactive: "7",
                        ArchivalPolicyName: "Example Name",
                        ArchivalType: "LeaveContentInPlace|Delete|MoveContentToDifferentSite",
                        ArchivalUrl: "https://example.sharepoint.com/sites/archival-site-collection/example",
                        IsTeam: true,
                        TemplateName: "Template Name",
                        TemplateId: "00000000-0000-0000-0000-000000000000",
                        ArchivalIntegrityCheck: {
                            SiteId: "00000000-0000-0000-0000-000000000000",
                            Error: null,
                            ErrorStack: null,
                            PolicyName: "Example Name",
                            PolicyContentAction: "MoveContentOnlyToDifferentSiteLibrary",
                            Libraries: [
                                {
                                    LibraryTitle: "Example Name",
                                    SourceUrl: "/sites/source-site-collection/example",
                                    DestinationUrl: "/sites/archival-site-collection/example/shared documents/archival-subsite/documents",
                                    FilesCountInSource: 123,
                                    FilesCountInDestination: 124,
                                    FilesCountMissingFromDestination: 0,
                                    MissingFilesFromDestionation: ["/file-example-name.xlsx"],
                                    MissingFilesFromSource: []
                                }
                            ],
                            TotalSourceFiles: 123,
                            TotalDestFiles: 124,
                            TotalMissingFiles: 0
                        }
                    }, webhookSecret: currentPolicy.SecondStage_Integrations_WebhookSecret, webhookUrl: currentPolicy.SecondStage_Integrations_WebhookUrl, OnWebhookSecretChange: function (value) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Integrations_WebhookSecret: value
                        }));
                    }, OnWebhookUrlChange: function (value) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            SecondStage_Integrations_WebhookUrl: value
                        }));
                    } })))));
});
