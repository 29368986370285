import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import AnimatedLoader from "app/components/common/animated-loader";
var ExternalApiDefinition = React.lazy(function () { return import("../../components/external-api/external-api-definition"); });
var ExternalApiConnections = React.lazy(function () { return import("../../components/external-api/external-api-connections"); });
var ExternalApiDocumentation = React.lazy(function () { return import("../../components/external-api/external-api-documentation"); });
export default function SettingPages() {
    return (React.createElement(Suspense, { fallback: React.createElement("div", { style: { textAlign: "center" } },
            React.createElement(AnimatedLoader, { size: "med" })) },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/client-api/definition", component: ExternalApiDefinition }),
            React.createElement(Route, { exact: true, path: "/client-api/connections", component: ExternalApiConnections }),
            React.createElement(Route, { exact: true, path: "/client-api/documentation", component: ExternalApiDocumentation }))));
}
