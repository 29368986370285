var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./card-policy-selection.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Collapse } from "react-bootstrap";
import { FormValidator } from "app/components/common/form-validation";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggleControl from "app/components/common/form-toggle-control";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
export var CardTeam = React.memo(function (props) {
    return (React.createElement("div", { className: "card-team", "data-pw": "card-team" },
        React.createElement(CardPolicySelection, { Application: "Team" })));
});
export var CardTeamSite = React.memo(function (props) {
    return (React.createElement("div", { className: "card-team", "data-pw": "card-team-site" },
        React.createElement(CardPolicySelection, { Application: "TeamSite" })));
});
export var CardCommunicationSite = React.memo(function (props) {
    return (React.createElement("div", { className: "card-team", "data-pw": "card-comm-site" },
        React.createElement(CardPolicySelection, { Application: "CommunicationSite" })));
});
var CardPolicySelection = React.memo(function (props) {
    var lifecyclePolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var dispatch = useDispatch();
    var getValue = function () {
        if (props.Application === "Team") {
            return currentTenantConfiguration.LCPolicyArchiveDefaultGuidTeam;
        }
        else if (props.Application === "TeamSite") {
            return currentTenantConfiguration.LCPolicyArchiveDefaultGuidTeamSite;
        }
        else {
            return currentTenantConfiguration.LCPolicyArchiveDefaultGuidCommSite;
        }
    };
    var getToggleValue = function () {
        if (props.Application === "Team") {
            return currentTenantConfiguration.LCPolicyArchiveDefaultEnabledTeam;
        }
        else if (props.Application === "TeamSite") {
            return currentTenantConfiguration.LCPolicyArchiveDefaultEnabledTeamSite;
        }
        else {
            return currentTenantConfiguration.LCPolicyArchiveDefaultEnabledCommSite;
        }
    };
    var updateToggle = function (value) {
        var update = {};
        if (props.Application === "Team") {
            update.LCPolicyArchiveDefaultEnabledTeam = value;
            if (value === false) {
                update.LCPolicyArchiveDefaultGuidTeam = null;
            }
        }
        if (props.Application === "TeamSite") {
            update.LCPolicyArchiveDefaultEnabledTeamSite = value;
            if (value === false) {
                update.LCPolicyArchiveDefaultGuidTeamSite = null;
            }
        }
        if (props.Application === "CommunicationSite") {
            update.LCPolicyArchiveDefaultEnabledCommSite = value;
            if (value === false) {
                update.LCPolicyArchiveDefaultGuidCommSite = null;
            }
        }
        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), update)));
    };
    var updatePolicy = function (value) {
        var update = {};
        if (props.Application === "Team") {
            update.LCPolicyArchiveDefaultGuidTeam = value;
        }
        if (props.Application === "TeamSite") {
            update.LCPolicyArchiveDefaultGuidTeamSite = value;
        }
        if (props.Application === "CommunicationSite") {
            update.LCPolicyArchiveDefaultGuidCommSite = value;
        }
        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), update)));
    };
    return (React.createElement("div", { className: "card-team" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12, className: "" },
                React.createElement(FormToggleControl, { checked: getToggleValue(), label: "Apply selected default Archival Policy to all " +
                        (props.Application === "Team" ? "teams" : props.Application === "TeamSite" ? "team sites / viva engage communities" : "communication sites"), handleChange: function (checked) { return updateToggle(checked); } }),
                React.createElement(Collapse, { in: getToggleValue() },
                    React.createElement("div", null,
                        React.createElement(FormDropdownSelect
                        /*
                        //@ts-ignore */
                        , { 
                            /*
                            //@ts-ignore */
                            list: lifecyclePolicies !== undefined
                                ? __spreadArray([], lifecyclePolicies
                                    .filter(function (lp) { return lp.Application === props.Application; })
                                    .map(function (lp) { return ({
                                    id: lp.GUID,
                                    value: lp.GUID,
                                    title: lp.Name,
                                    description: lp.Description
                                }); }), true) : [], value: getValue(), onChange: function (value) { return updatePolicy(value); } }),
                        getToggleValue() === true ? (React.createElement(FormValidator, { UniqueIdentifier: props.Application + "-policy-selected", FieldName: "Renewal / Archival Policy", Validators: ["string-not-empty"], Category: props.Application === "Team" ? "team" : props.Application === "TeamSite" ? "team-site" : "comm-site", Value: getValue() })) : null))))));
});
