import DisableArea from "app/components/common/disable-area";
import FormToggleControl from "app/components/common/form-toggle-control";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InstallWizardContext } from "../..";
var InstallerStepLifecycle = React.memo(function (props) {
    var dispatch = useDispatch();
    var iwc = React.useContext(InstallWizardContext);
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    // If there hasn't been any consent yet that means this is a first time run.
    // If it's a first run then set the default here to true.
    React.useEffect(function () {
        console.log("ENABLE LIFECYCLE", currentTenant, currentTenant.WhoConsentedUserGuid !== undefined);
        if (currentTenant.WhoConsentedUserGuid !== undefined &&
            (currentTenant.WhoConsentedUserGuid === null ||
                currentTenant.WhoConsentedUserGuid === "" ||
                (currentTenant.WhoConsentedUserGuid != null &&
                    currentTenant.LastInstalledVersion !== undefined &&
                    currentTenant.LastInstalledVersion != null &&
                    Number(currentTenant.LastInstalledVersion) < 10100))) {
            dispatch(TenantCurrentStore.Update({ LifecycleEnabled: true, GuestManagementEnabled: true }));
        }
        iwc.setStepProgress(props.step.key, true);
    }, []);
    React.useEffect(function () {
        if (currentTenant.LifecycleEnabled === false && currentTenant.GuestManagementEnabled === true) {
            dispatch(TenantCurrentStore.Update({ GuestManagementEnabled: false }));
        }
        dispatch(TenantCurrentStore.UpdateSilent());
    }, [currentTenant.LifecycleEnabled, currentTenant.GuestManagementEnabled]);
    return (React.createElement("div", { className: "installer-step-lifecycle fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { style: { fontSize: "14px" } },
                React.createElement(Row, null,
                    React.createElement(Col, { md: 6 },
                        React.createElement("h2", { className: "ob", style: { marginBottom: "15px" } }, "Lifecycle Management Functionality"),
                        React.createElement("p", { style: { marginBottom: "25px" } }, "Lifecycle Management with Orchestry gives you control from workspace creation through to deletion or archiving, without hefty licensing fees. Easily identify inactive workspaces and enforce workspace management policies to govern how they are handled going forward."),
                        React.createElement(FormToggleControl, { checked: currentTenant.LifecycleEnabled, label: "Enable Orchestry's Lifecycle Features", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ LifecycleEnabled: checked })); } }),
                        React.createElement(DisableArea, { isEnabled: currentTenant.LifecycleEnabled, useOverlay: true },
                            React.createElement("div", null,
                                React.createElement("h2", { className: "ob", style: { marginBottom: "15px", marginTop: "35px" } }, "Guest Management Functionality"),
                                React.createElement("p", { style: { marginBottom: "25px" } }, "Guest Management with Orchestry gives you control and visibility into your tenant's External Users (Guests) Easily see what they have access to. add controls around their creation, enforce better data capture on their accounts. and enforce periodic reviews of their access to reduce security risks."),
                                React.createElement(FormToggleControl, { checked: currentTenant.GuestManagementEnabled, label: "Enable Orchestry's Guest Management Features", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ GuestManagementEnabled: checked })); } })))),
                    React.createElement(Col, { md: 6, className: "orchestry-image" },
                        React.createElement("img", { src: "/media/orchestry/accelerating-your-success-office365-large-new.svg" })))))));
});
export default InstallerStepLifecycle;
