import * as MetadataCurrentStore from "app/store/metadata/current";
import { AsyncCallType } from "app/store/async/types";
export var metadataCurrentActionMap = [
    {
        actionType: MetadataCurrentStore.MetadataCurrentActionTypes.Metadata_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "metadataListState.items",
        keyType: "guid",
        ApiUrl: "/api/Metadata/?guid={key}",
        responseDataType: {}
    }
];
