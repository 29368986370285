import { AppType } from "app/services/permission-info";
import clsx from "clsx";
import * as React from "react";
import { Row, Col } from "react-bootstrap";
export var OrchestryPermissionInfoEditorFilter = function (_a) {
    var selectedAppType = _a.selectedAppType, setSelectedAppType = _a.setSelectedAppType;
    return (React.createElement(Row, null,
        React.createElement(Col, { md: 7 },
            React.createElement("div", { className: "pills" },
                React.createElement("div", { className: clsx("nav-item", { selected: selectedAppType === AppType.Orchestry }), onClick: function () { return setSelectedAppType(AppType.Orchestry); } }, "Orchestry"),
                React.createElement("div", { className: clsx("nav-item", { selected: selectedAppType === AppType.ServiceApp }), onClick: function () { return setSelectedAppType(AppType.ServiceApp); } }, "ServiceApp")))));
};
