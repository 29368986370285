import { useGetRecommendationSettingsQuery } from "appv2/api/recommendations-settings/recommendations-settings.api";
import { RecommendationSource } from "appv2/api/recommendations/recommendation-source.type";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
export var RecommendationCardSource = function (_a) {
    var source = _a.source;
    var isCustomSource = source === RecommendationSource.Custom;
    var _b = useGetRecommendationSettingsQuery(), data = _b.data, isLoading = _b.isLoading;
    var brandingImage = data === null || data === void 0 ? void 0 : data.BrandingImage;
    var isCustomWithoutBranding = isCustomSource && !brandingImage;
    var isCustomWithBranding = isCustomSource && brandingImage;
    var imgDimensions = isCustomWithBranding ? 19 : 16;
    return (React.createElement(OrchestryFeaturedIcon, { size: "sm", variant: "light-circle", colorVariant: isCustomSource ? "gray-7-gray-1" : "primary-7", iconClassName: isCustomWithoutBranding ? ORCH_ICONS.building06 : undefined, imgIconSrc: isCustomSource ? brandingImage : ORCH_SVG_ICONS.LogoMarkColorSmall, imgIconWidth: imgDimensions, imgIconHeight: imgDimensions, isLoading: isLoading }));
};
