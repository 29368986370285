var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import GraphService from 'app/services/graph';
// import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
import NotificationContent from "app/components/common/notification-content";
import SensitivityLabelsService from "./sensitivity-labels";
import trimChar from "app/utils/trimChar";
var GroupSettingsService = /** @class */ (function () {
    function GroupSettingsService() {
    }
    GroupSettingsService.directoryValues = [];
    GroupSettingsService.directoryTemplateValues = [];
    GroupSettingsService.getGlobalSettings = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/groups/GetGlobalConfiguration";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                GroupSettingsService.directoryValues = response.data;
                SensitivityLabelsService.isTenantHaveLabelsEnabled = GroupSettingsService.getDirectoryValue("EnableMIPLabels");
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    GroupSettingsService.getGlobalSettingTemplates = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/groups/GetGlobalConfigurationTemplates";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                GroupSettingsService.directoryTemplateValues = response.data;
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    GroupSettingsService.getSettingsAndTemplates = function () {
        return new Promise(function (resolve, reject) {
            var callsDone = 0;
            GroupSettingsService.getGlobalSettings().then(function (data) {
                callsDone++;
                checkCallsDone();
            });
            GroupSettingsService.getGlobalSettingTemplates().then(function (data) {
                callsDone++;
                checkCallsDone();
            });
            var checkCallsDone = function () {
                if (callsDone === 2) {
                    resolve({
                        settings: GroupSettingsService.directoryValues,
                        templates: GroupSettingsService.directoryTemplateValues
                    });
                }
            };
        });
    };
    GroupSettingsService.setGlobalSettings = function (values) {
        toast(React.createElement(NotificationContent, { text: "Saving Group Settings", mode: "loading" }), {
            toastId: "saving-group-settings",
            autoClose: false,
            type: toast.TYPE.DEFAULT
        });
        if (values === undefined) {
            values = GroupSettingsService.directoryValues;
        }
        else {
            GroupSettingsService.directoryValues = values;
        }
        SensitivityLabelsService.isTenantHaveLabelsEnabled = GroupSettingsService.getDirectoryValue("EnableMIPLabels");
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("values", JSON.stringify(values));
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/Groups/SetGlobalConfiguration";
            // removed the ability to set all configuration values as setting these values was messing with sensitivity labels being enabled.
            // When setting sensitivity labels via app only the setting isn't properly propogates like it is via powershell.
            //axiosConfig.url = "/api/Groups/SetClassificationConfiguration";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                toast.update("saving-group-settings", {
                    render: React.createElement(NotificationContent, { text: "Group Settings Updated", mode: "success" }),
                    type: toast.TYPE.DEFAULT,
                    autoClose: 2500
                });
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GroupSettingsService.getClassificationListTableData = function (values) {
        var _a, _b, _c;
        var returnListItems = [];
        if (values === undefined) {
            values = GroupSettingsService.directoryValues;
        }
        else {
            GroupSettingsService.directoryValues = values;
        }
        var listItems = values.some(function (dv) { return dv.name === "ClassificationList"; }) ? (_a = values.find(function (dv) { return dv.name === "ClassificationList"; })) === null || _a === void 0 ? void 0 : _a.value : "";
        //console.log("getClassificationListTableData", listItems);
        var descItems = values.some(function (dv) { return dv.name === "ClassificationDescriptions"; }) ? (_b = values.find(function (dv) { return dv.name === "ClassificationDescriptions"; })) === null || _b === void 0 ? void 0 : _b.value : "";
        var defaultItem = values.some(function (dtv) { return dtv.name === "DefaultClassification"; }) ? (_c = values.find(function (dtv) { return dtv.name === "DefaultClassification"; })) === null || _c === void 0 ? void 0 : _c.value : "";
        if (listItems !== undefined && listItems !== null && listItems !== "") {
            var listItemsArray = listItems.split(",");
            var descItemsArray = [];
            if (descItems !== undefined && descItems !== null && descItems !== "") {
                var splitOne = descItems.split(",");
                splitOne.forEach(function (si) {
                    if (si.indexOf(":") > -1) {
                        var splitTwo = si.split(":");
                        descItemsArray.push({
                            name: splitTwo[0],
                            description: splitTwo[1]
                        });
                    }
                });
            }
            if (Array.isArray(listItemsArray) && listItemsArray.length > 0) {
                returnListItems = listItemsArray.map(function (li) {
                    var _a;
                    return ({
                        name: li,
                        description: descItemsArray.some(function (di) { return di.name === li; }) ? (_a = descItemsArray.find(function (di) { return di.name === li; })) === null || _a === void 0 ? void 0 : _a.description : "",
                        isDefault: li === defaultItem
                    });
                });
            }
        }
        return returnListItems;
    };
    GroupSettingsService.setClassificationListDataItem = function (item, updateItem) {
        console.log(item.name, updateItem.name);
        var listTableData = GroupSettingsService.getClassificationListTableData();
        var newListTableData = [];
        if (listTableData !== undefined) {
            listTableData.forEach(function (ltd) {
                var newItem = __assign({}, ltd);
                if (ltd.name === item.name) {
                    if (updateItem.name !== ltd.name) {
                        newItem.name = updateItem.name;
                    }
                    if (updateItem.description !== ltd.description) {
                        newItem.description = updateItem.description;
                    }
                    if (updateItem.isDefault !== ltd.isDefault) {
                        newItem.isDefault = updateItem.isDefault;
                    }
                }
                else {
                    if (updateItem.isDefault === true) {
                        newItem.isDefault = false;
                    }
                }
                newListTableData.push(newItem);
            });
        }
        return GroupSettingsService.setClassificationListData(newListTableData);
    };
    GroupSettingsService.setClassificationListData = function (newListTableData) {
        var ClassificationList = "";
        var ClassificationDescriptions = "";
        var DefaultClassification = "";
        newListTableData
            .filter(function (dscli) { return dscli.name !== undefined && dscli.name !== null && dscli.name !== ""; })
            .forEach(function (dscli, index) {
            if (index !== 0) {
                ClassificationList += ",";
            }
            ClassificationList += dscli.name;
            if (dscli.description !== undefined && dscli.description !== null && dscli.description !== "") {
                if (index !== 0) {
                    ClassificationDescriptions += ",";
                }
                ClassificationDescriptions += dscli.name + ":" + dscli.description;
            }
            if (dscli.isDefault === true) {
                DefaultClassification = dscli.name;
            }
        });
        ClassificationList = trimChar(ClassificationList, ",");
        ClassificationDescriptions = trimChar(ClassificationDescriptions, ",");
        GroupSettingsService.setDirectoryValue("ClassificationList", ClassificationList);
        GroupSettingsService.setDirectoryValue("ClassificationDescriptions", ClassificationDescriptions);
        GroupSettingsService.setDirectoryValue("DefaultClassification", DefaultClassification);
        console.log("ClassificationList", ClassificationList);
        return newListTableData;
    };
    GroupSettingsService.getDirectoryValue = function (key) {
        if (GroupSettingsService.directoryValues !== undefined && GroupSettingsService.directoryTemplateValues !== undefined) {
            var foundValue = GroupSettingsService.directoryValues.find(function (dv) { return dv.name === key; });
            var foundTemplate = GroupSettingsService.directoryTemplateValues.find(function (dtv) { return dtv.name === key; });
            if (foundValue !== undefined && foundTemplate !== undefined) {
                if (foundTemplate.type === "System.Boolean") {
                    return foundValue.value.toLowerCase() === "true" ? true : false;
                }
                else {
                    return foundValue.value;
                }
            }
        }
        return null;
    };
    GroupSettingsService.getDirectoryValueDescription = function (key) {
        if (GroupSettingsService.directoryTemplateValues !== undefined) {
            var foundTemplate = GroupSettingsService.directoryTemplateValues.find(function (dtv) { return dtv.name === key; });
            if (foundTemplate !== undefined) {
                return foundTemplate.description;
            }
        }
        return "";
    };
    GroupSettingsService.setDirectoryValue = function (key, value) {
        if (GroupSettingsService.directoryValues !== undefined && GroupSettingsService.directoryTemplateValues !== undefined) {
            var foundValue = GroupSettingsService.directoryValues.find(function (dv) { return dv.name === key; });
            var foundTemplate = GroupSettingsService.directoryTemplateValues.find(function (dtv) { return dtv.name === key; });
            if (foundValue !== undefined && foundTemplate !== undefined) {
                var dvaluesWithoutKey = GroupSettingsService.directoryValues.filter(function (dv) { return dv.name !== key; });
                if (foundTemplate.type === "System.Boolean") {
                    GroupSettingsService.directoryValues = __spreadArray(__spreadArray([], dvaluesWithoutKey, true), [{ name: key, value: value === true ? "True" : "False" }], false);
                }
                else {
                    GroupSettingsService.directoryValues = __spreadArray(__spreadArray([], dvaluesWithoutKey, true), [{ name: key, value: value }], false);
                }
            }
        }
        return GroupSettingsService.directoryValues;
    };
    GroupSettingsService.getOrgClassifications = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/groups/GetOrgClassifications";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    GroupSettingsService.getOrchestryClassifications = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/groups/GetOrgClassifications";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    return GroupSettingsService;
}());
export default GroupSettingsService;
