/**
 * Category mapping.
 * Used to map the GUIDs in the backend to the front end values
 */
export var CategoryMap = {
    architecture: "75F6419A-2D38-4D31-9B69-F8328AC527D8",
    communications: "D3DE2AED-7E85-4F33-9DB9-0A9A18445B13",
    adoption: "9C97B3A8-6C5F-4F9B-AAC3-C5EFD3AC94D0",
    security: "68B76D06-DE3D-4B69-9D0A-F2B5CDEFE243",
    guests: "E7FA6C8E-55DA-4DA7-B21F-41565EABFD98",
    storage: "A2E21F7C-DC51-45A6-B9BF-87E54D2089D5",
    "cost savings": "84F97F5F-6621-4F11-B0E4-793D14C3D8A1",
    governance: "CAF1A466-17FF-495A-A9C4-D162F234548F",
    sprawl: "56B8C0E3-1A41-46F0-B144-ED7B02F60D0F",
    oversharing: "7011be4f-cfde-4607-b069-7b6ed2d06455"
    // licensing: "" phase 2
};
/**
 * Product mapping.
 * Used to map the GUIDs in the backend to the front end values
 */
export var ProductMap = {
    sharepoint: "2A92EFD1-4D35-44A7-91C8-9DDE935F1E7F",
    defender: "C8F4A6B9-3187-49E1-9B5E-8CE9D68DB6A9",
    purview: "F3B11711-75FD-4EAC-AB5E-7E785B3C2FD8",
    teams: "0A3E4D7D-C1F7-4D3B-80A3-0E4162E0B6C2",
    "viva connections": "5D8D41B5-7A33-4F23-B75B-751D9CC65617",
    "viva engage": "afbcb79c-0656-4c68-ad11-0fea49e6cef3",
    entra: "1B62C607-DA86-45E5-A79C-9FD007AAB7C8",
    onedrive: "8E9126A0-1A78-41D6-89A0-8C742E72417D",
    copilot: "17c79d8b-c206-4eb7-bc65-9d468399687e"
};
