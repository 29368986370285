var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getReadableDateRange } from "app/utils/dateUtils";
import { TenantLicenseStatus } from "appv2/api/partner-tools/tenant-license-status.type";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT } from "appv2/components/orchestry-date-picker-input/orchestry-date-picker-input.component";
import { useMemo, useState } from "react";
import { TENANT_INSIGHTS_FILTERS_LIST } from "./tenant/tenant-details/use-tenant-insight-filters.hook";
export var usePartnerToolsFilters = function () {
    var LICENSE_FILTERS_LIST = useMemo(function () { return [
        {
            title: "Trial",
            value: TenantLicenseStatus.OrchestryTrial,
            group: "LicenseType",
            selected: false,
            icon: ORCH_ICONS.hourglass03,
            variant: "trial-license",
            type: "selectable"
        },
        {
            title: "Orchestry",
            value: TenantLicenseStatus.Orchestry,
            group: "LicenseType",
            selected: false,
            svgIcon: { iconSelected: ORCH_SVG_ICONS.LogoMarkColorSmall },
            color: "var(--mantine-color-primary-8)",
            type: "selectable"
        },
        {
            title: "Beacon",
            value: TenantLicenseStatus.Beacon,
            group: "LicenseType",
            selected: false,
            svgIcon: { iconSelected: ORCH_SVG_ICONS.BeaconLogomark },
            svgSize: "xs",
            color: "var(--mantine-color-primary-8)",
            type: "selectable"
        },
        {
            title: "Unlicensed",
            value: TenantLicenseStatus.Unlicensed,
            group: "LicenseType",
            selected: false,
            icon: ORCH_ICONS.cloudOff,
            type: "selectable"
        }
    ]; }, []);
    var _a = useState(LICENSE_FILTERS_LIST), licenseTypeFilters = _a[0], setLicenseTypeFilters = _a[1];
    var _b = useState(TENANT_INSIGHTS_FILTERS_LIST), insightFilters = _b[0], setInsightFilters = _b[1];
    var _c = useState([]), installDateFilter = _c[0], setInstallDateFilter = _c[1];
    var _d = useState([]), renewalDateFilter = _d[0], setRenewalDateFilter = _d[1];
    var _e = useState([]), appliedFilters = _e[0], setAppliedFilters = _e[1];
    var onApplyFilters = function (licenseFilters, installDateFilter, renewalDateFilter, insightFilters) {
        var isInstallDateFilterEmpty = installDateFilter.filter(function (f) { return !!f; }).length < DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT;
        var isRenewalDateFilterEmpty = renewalDateFilter.filter(function (f) { return !!f; }).length < DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT;
        var installDateGroupedFilter = {
            icon: ORCH_ICONS.calendar,
            type: "input",
            inputFilterType: "date-range",
            value: installDateFilter,
            title: getReadableDateRange(installDateFilter[0], installDateFilter[1]),
            group: "InstallDate_DateOnly",
            selected: !isInstallDateFilterEmpty,
            color: "var(--mantine-color-gray-9)"
        };
        var renewalDateGroupedFilter = {
            icon: ORCH_ICONS.calendar,
            type: "input",
            inputFilterType: "date-range",
            value: renewalDateFilter,
            title: getReadableDateRange(renewalDateFilter[0], renewalDateFilter[1]),
            group: "RenewalDate_DateOnly",
            selected: !isRenewalDateFilterEmpty,
            color: "var(--mantine-color-gray-9)"
        };
        setAppliedFilters(__spreadArray(__spreadArray(__spreadArray([], licenseFilters, true), [installDateGroupedFilter, renewalDateGroupedFilter], false), insightFilters, true));
        setLicenseTypeFilters(licenseFilters);
        setInsightFilters(insightFilters);
    };
    var clearAllFilters = function () {
        setLicenseTypeFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        setInsightFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        setInstallDateFilter([]);
        setRenewalDateFilter([]);
        setAppliedFilters([]);
    };
    var removeFilter = function (filter) {
        var newAppliedFilters = appliedFilters.map(function (appliedFilter) { return (appliedFilter.title === filter.title ? __assign(__assign({}, appliedFilter), { selected: false }) : appliedFilter); });
        setAppliedFilters(newAppliedFilters);
        var newLicenseTypeFilters = licenseTypeFilters.map(function (f) { return (f.title === filter.title ? __assign(__assign({}, f), { selected: false }) : f); });
        setLicenseTypeFilters(newLicenseTypeFilters);
        var newInsightFilters = insightFilters.map(function (f) { return (f.title === filter.title ? __assign(__assign({}, f), { selected: false }) : f); });
        setInsightFilters(newInsightFilters);
        if (filter.group === "InstallDate_DateOnly") {
            setInstallDateFilter([]);
        }
        else if (filter.group === "RenewalDate_DateOnly") {
            setRenewalDateFilter([]);
        }
    };
    return {
        LICENSE_FILTERS_LIST: LICENSE_FILTERS_LIST,
        licenseTypeFilters: licenseTypeFilters,
        setLicenseTypeFilters: setLicenseTypeFilters,
        TENANT_INSIGHTS_FILTERS_LIST: TENANT_INSIGHTS_FILTERS_LIST,
        insightFilters: insightFilters,
        setInsightFilters: setInsightFilters,
        installDateFilter: installDateFilter,
        setInstallDateFilter: setInstallDateFilter,
        renewalDateFilter: renewalDateFilter,
        setRenewalDateFilter: setRenewalDateFilter,
        appliedFilters: appliedFilters,
        setAppliedFilters: setAppliedFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        onApplyFilters: onApplyFilters
    };
};
