var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./report-bulk-guest-action-modal.scss";
import { useSelector, useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import OrchModal from "app/components/common/orch-modal";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import * as LifecycleGuestPoliciesListStore from "app/store/lifecycle-guest-policies/list";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import LifecycleReportService from "app/services/lifecycle-report";
import LifecycleService from "app/services/lifecycle";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import InfoTooltip from "app/components/common/infotooltip";
import AnimatedLoader from "app/components/common/animated-loader";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
import { LifecycleGuestPolicyListActionTypes } from "app/store/lifecycle-guest-policies/list";
import GuestService from "app/services/guests";
import vivaIcon from "app/assets/img/icon-viva-engage.svg";
var LifecycleWorkspaceBulkGuestActionModal = React.memo(function (props) {
    var dispatch = useDispatch();
    var reportItems = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Items; });
    var lifecycleGuestPolicies = useSelector(function (state) { return state.lifecycleGuestPoliciesListState.items; });
    var _a = React.useState(null), defaultPolicies = _a[0], setDefaultPolicies = _a[1];
    var _b = React.useState([]), currentPolicies = _b[0], setCurrentPolicies = _b[1];
    var _c = React.useState(false), modalOpen = _c[0], setModalOpen = _c[1];
    var _d = React.useState(false), guestPoliciesLoaded = _d[0], setGuestPoliciesLoaded = _d[1];
    var _e = React.useState(false), processing = _e[0], setProcessing = _e[1];
    var _f = React.useState([]), clonePreviewItems = _f[0], setClonePreviewItems = _f[1];
    var _g = React.useState(false), showComplete = _g[0], setShowComplete = _g[1];
    var _h = React.useState(null), bulkAssignJobId = _h[0], setBulkAssignJobId = _h[1];
    var copyReference = React.useRef();
    React.useEffect(function () {
        if (modalOpen === true && processing === false) {
            setCurrentPolicies([]);
            setShowComplete(false);
            LifecycleReportService.getDefaultPolicies().then(function (policies) {
                setDefaultPolicies(policies.defaultGuestPolicies);
            });
        }
        if (props.Type === "AssignPolicy" && modalOpen === true) {
            setCurrentPolicies(reportItems
                .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
                .map(function (ri) { return (__assign({}, ri
            // SiteId: ri.SiteId,
            // ArchivalPolicyGuid: ri.ArchivalPolicyGuid,
            // IsProtected: ri.IsProtected
            )); }));
            dispatch(LifecycleGuestPoliciesListStore.Load());
            AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, undefined, null, LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Load).then(function () {
                setTimeout(function () { return setGuestPoliciesLoaded(true); }, 500);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen]);
    var canForcePolicy = function (reportItem) {
        var canForce = true;
        if (reportItem.IsArchived || reportItem.ArchivalStatus === "Done") {
            canForce = false;
        }
        if (reportItem.IsDeleted || reportItem.ArchivalStatus === "Deleted") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Failed") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Processing") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "ReadyToArchive") {
            canForce = false;
        }
        // if (reportItem.ArchivalStatus === "Extended") {
        //    canForce = false;
        // }
        if (reportItem.Application === "CommunicationSite") {
            canForce = false;
        }
        if (reportItem.SharePointTeamSiteHasNoGroup === true) {
            canForce = false;
        }
        if (reportItem.GuestCount !== null && reportItem.GuestCount !== undefined && reportItem.GuestCount < 1)
            canForce = false;
        var policy = getPolicyForWorkspace(reportItem);
        if (policy === null) {
            canForce = false;
        }
        return canForce;
    };
    var getExecuteAction = function (reportItem) {
        var title = "";
        var desc = "";
        var canDo = true;
        title = "Force Guest Review";
        if (reportItem.IsArchived || reportItem.ArchivalStatus === "Done") {
            // title = "Nothing";
            title = "Can't Force Review on Archived Workspaces";
            canDo = false;
        }
        if (reportItem.IsDeleted || reportItem.ArchivalStatus === "Deleted") {
            // title = "Nothing";
            title = "Deleted";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Processing") {
            // title = "Nothing";
            title = "Can't Force Review on Workspaces Undergoing Archival";
            canDo = false;
        }
        if (reportItem.SharePointTeamSiteHasNoGroup === true) {
            // title = "Nothing";
            title = "Workpspace group is missing from Team Site. This is usually because of a pending deletion.";
            canDo = false;
        }
        if (reportItem.Application === "CommunicationSite") {
            // title = "Nothing";
            title = "Guest Reviews not Supported for Communication Sites";
            canDo = false;
        }
        if (reportItem.GuestCount !== null && reportItem.GuestCount !== undefined && reportItem.GuestCount < 1) {
            title = "No Guests have Access to this Workspace";
            canDo = false;
        }
        var policy = getPolicyForWorkspace(reportItem);
        if (policy === null) {
            // title = "Nothing";
            title = "There is no policy assigned";
            canDo = false;
        }
        if (policy !== null) {
            return (React.createElement("div", { className: "action" },
                React.createElement("div", { className: "a-left" },
                    React.createElement("i", { className: "far fa-" + (canDo === true ? "check-circle" : "circle") })),
                React.createElement("div", { className: "a-right" },
                    React.createElement("div", { className: "a-title" }, title),
                    desc !== "" ? React.createElement("div", { className: "a-description" }, desc) : null)));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    var getDefaultPolicy = function (policyType) {
        var foundDefaultWorkspace = defaultPolicies === null || defaultPolicies === void 0 ? void 0 : defaultPolicies.find(function (gp) { return gp.Type === policyType; });
        return foundDefaultWorkspace !== undefined ? foundDefaultWorkspace : null;
    };
    var getAssignablePolicies = function (policyType) {
        var policies = [];
        var defaultPolicy = getDefaultPolicy(policyType);
        if (defaultPolicy) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (lifecycleGuestPolicies !== null) {
            policies = __spreadArray(__spreadArray([], policies, true), lifecycleGuestPolicies
                .filter(function (gp) { return gp.Type === policyType; })
                .map(function (gp) { return ({
                id: gp.GUID,
                value: gp.GUID,
                title: gp.Name,
                description: gp.Description
            }); }), true);
        }
        return policies;
    };
    var undoAssignedPolicy = function (siteId, guestPolicyType) {
        var policyGuidToSet = guestPolicyType === LifecycleGuestPolicyType.Request ? "GuestRequestPolicyGuid" : "GuestReviewPolicyGuid";
        var policyGuidToKeep = guestPolicyType === LifecycleGuestPolicyType.Request ? "GuestReviewPolicyGuid" : "GuestRequestPolicyGuid";
        setCurrentPolicies(__spreadArray([], currentPolicies.map(function (cp) {
            var _a;
            if (cp.SiteId !== siteId) {
                return cp;
            }
            else {
                var foundRi = reportItems.find(function (ri) { return ri.SiteId === siteId; });
                if (foundRi !== undefined) {
                    return __assign(__assign({}, foundRi), (_a = {}, _a[policyGuidToSet] = foundRi[policyGuidToSet], _a[policyGuidToKeep] = cp[policyGuidToKeep], _a));
                }
                else {
                    return cp;
                }
            }
        }), true));
    };
    var updateAssignedPolicies = function (item, policyGuid, guestPolicyType) {
        var guestPolicyProperty = guestPolicyType === LifecycleGuestPolicyType.Request ? "GuestRequestPolicyGuid" : "GuestReviewPolicyGuid";
        setCurrentPolicies(__spreadArray([], currentPolicies.map(function (cp) {
            var _a;
            return (cp.SiteId === item.SiteId ? __assign(__assign({}, item), (_a = {}, _a[guestPolicyProperty] = policyGuid, _a)) : cp);
        }), true));
    };
    var getPolicyForWorkspace = function (reportItem) {
        var policyName = null;
        if (reportItem.GuestReviewPolicyName !== null) {
            policyName = reportItem.GuestReviewPolicyName;
        }
        else {
            var foundDefaultPolicy = getDefaultPolicy(LifecycleGuestPolicyType.Review);
            if (foundDefaultPolicy !== null) {
                policyName = foundDefaultPolicy.Name;
            }
        }
        return policyName;
    };
    var getCountOfExecutablePolicies = function () {
        var count = 0;
        reportItems
            .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
            .forEach(function (item) {
            if (canForcePolicy(item)) {
                count++;
            }
        });
        return count;
    };
    var getExecutablePoliciesSiteIds = function () {
        var ids = [];
        reportItems
            .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
            .forEach(function (item) {
            if (canForcePolicy(item)) {
                ids.push(item.SiteId);
            }
        });
        return ids;
    };
    var getDropdownPolicyValue = function (item, policyType) {
        if (policyType === LifecycleGuestPolicyType.Review) {
            return item.GuestReviewPolicyGuid;
        }
        if (policyType === LifecycleGuestPolicyType.Request) {
            return item.GuestRequestPolicyGuid;
        }
    };
    var cloneSelectionToAllWorkspaces = function (item, policyType) {
        var policyGuid = policyType === LifecycleGuestPolicyType.Request ? "GuestRequestPolicyGuid" : "GuestReviewPolicyGuid";
        setCurrentPolicies(currentPolicies.map(function (cp) {
            var _a;
            if (cp.SiteId !== item.SiteId && isWorkspaceEligibleForPolicyReAssignment(cp)) {
                return __assign(__assign({}, cp), (_a = {}, _a[policyGuid] = item[policyGuid], _a));
            }
            return cp;
        }));
        setClonePreviewItems([]);
    };
    var getNewPolicyAssignments = function () {
        var tempAssignments = [];
        currentPolicies.forEach(function (cp) {
            if (isPolicyNew(cp, LifecycleGuestPolicyType.Request) || isPolicyNew(cp, LifecycleGuestPolicyType.Review)) {
                tempAssignments.push(cp);
            }
        });
        return tempAssignments;
    };
    var isPolicyNew = function (item, guestPolicyType) {
        var foundReportItem = reportItems.find(function (ri) { return ri.SiteId === item.SiteId; });
        if (foundReportItem !== undefined) {
            if (guestPolicyType === LifecycleGuestPolicyType.Request)
                return item.GuestRequestPolicyGuid !== foundReportItem.GuestRequestPolicyGuid;
            if (guestPolicyType === LifecycleGuestPolicyType.Review)
                return item.GuestReviewPolicyGuid !== foundReportItem.GuestReviewPolicyGuid;
        }
        return false;
    };
    var isWorkspaceEligibleForPolicyReAssignment = function (reportItem) {
        var isEligible = true;
        // if (reportItem.ArchivalStatus !== "Active") {
        //    isEligible = false;
        // }
        if (reportItem.IsArchived === true) {
            isEligible = false;
        }
        if (reportItem.IsDeleted === true) {
            isEligible = false;
        }
        if (reportItem.SharePointTeamSiteHasNoGroup === true) {
            isEligible = false;
        }
        if (reportItem.Application === "CommunicationSite") {
            isEligible = false;
        }
        if (reportItem.Application === "VivaEngageCommunity")
            isEligible = false;
        return isEligible;
    };
    var getWorkspaceNotEligibleReason = function (reportItem) {
        var isntEligible = "";
        // if (reportItem.ArchivalStatus !== "Active") {
        //    if (reportItem.ArchivalStatus === "PendingApproval" || reportItem.ArchivalStatus === "Extended") {
        //       isntEligible = "Workspace is not eligible for policy assignment due to its status. Please change or reset the Workspace status to assign a policy";
        //    } else {
        //       isntEligible = "Workspace is not active and therefore not eligible for policy assignment";
        //    }
        // }
        if (reportItem.IsArchived === true) {
            isntEligible = "Workspace is already archived and therefore not eligible for policy assignment";
        }
        if (reportItem.IsDeleted === true) {
            isntEligible = "Workspace is deleted and therefore not eligible for policy assignment";
        }
        if (reportItem.SharePointTeamSiteHasNoGroup === true) {
            isntEligible = "The group associated with the site is missing. This is usually because the group was deleted, but Microsoft hasn't deleted the site yet. This usually takes 48 hours.";
        }
        if (reportItem.Application === "CommunicationSite") {
            isntEligible = "Guest Policies are currently not supported for Communication Sites";
        }
        return isntEligible;
    };
    var onClickExecutePolicies = function () {
        setProcessing(true);
        GuestService.BulkExecuteGuestPolicies(getExecutablePoliciesSiteIds()).then(function () {
            setProcessing(false);
            setShowComplete(true);
            dispatch(LifecycleReportStore.Refresh(props.Slot));
        });
    };
    var onClickAssignPolicies = function () {
        setProcessing(true);
        GuestService.BulkAssignGuestPolicies(getNewPolicyAssignments()).then(function (jobIdData) {
            setBulkAssignJobId(jobIdData.jobId);
        });
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (bulkAssignJobId !== null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                LifecycleService.GetBulkAssignStatus(bulkAssignJobId).then(function (data) {
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_1 = false;
                        runAgain();
                    }
                    else {
                        setProcessing(false);
                        setShowComplete(true);
                        setTimeout(function () {
                            dispatch(LifecycleReportStore.Refresh(props.Slot));
                        }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (bulkAssignJobId !== null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [bulkAssignJobId]);
    return (React.createElement("div", { className: "lifecycle-report-bulk-action-modal" },
        React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null, props.children), simpleButton: true, headerText: props.Type === "AssignPolicy" ? "Assign Guest Lifecycle Policies to Workspaces" : "Execute Guest Lifecycle Policies on Workspaces", 
            // footerPrimaryText={props.Type === "AssignPolicy" ? "Assign Policies" : "Execute Policies"}
            size: "xl", modalClassName: "lifecycle-report-bulk-action-modal", showModalOverride: modalOpen, showModalToggleOverride: function (shouldShow) { return setModalOpen(shouldShow); }, showCloseButton: processing === true ? false : true, footerCloseText: showComplete === true ? "Close" : processing === true ? undefined : "Cancel", footerContents: props.Type === "ExecutePolicy" ? (React.createElement(React.Fragment, null, showComplete === true ? null : processing === false ? (React.createElement(Button, { variant: "primary", disabled: getCountOfExecutablePolicies() === 0, onClick: function () { return onClickExecutePolicies(); } },
                "Execute Policy On These ",
                getCountOfExecutablePolicies(),
                " Workspaces")) : (React.createElement(Button, { variant: "secondary" }, "Executing... Please wait...")))) : (React.createElement(React.Fragment, null, showComplete === true ? null : processing === false ? (React.createElement(Button, { variant: "primary", disabled: getNewPolicyAssignments().length === 0, onClick: function () { return onClickAssignPolicies(); } },
                "Assign Policies To ",
                getNewPolicyAssignments().length,
                " Workspaces")) : (React.createElement(Button, { variant: "secondary" }, "Assigning... Please wait...")))) }, props.Type === "ExecutePolicy" ? (defaultPolicies === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })) : showComplete === true ? (React.createElement("div", { className: "completed" },
            React.createElement("div", { className: "completed-box" },
                React.createElement("i", { className: "flaticon-list" }),
                React.createElement("div", { className: "done-text" }, "Done - Policies executed on workspaces!"),
                React.createElement(NavLink, { exact: true, to: "/lifecycle/queue" }, "Check out the status of these executions in the Lifecycle Queue")))) : processing === true ? (React.createElement("div", { className: "processing-card" },
            React.createElement(AnimatedLoader, { text: "Executing policies", type: "squares", size: "xxsmall" }))) : (React.createElement("table", null,
            React.createElement("tr", null,
                React.createElement("th", null, "Workspace"),
                React.createElement("th", null, "Review Policy"),
                React.createElement("th", null, "Guest Review Action")),
            reportItems
                .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
                .map(function (item) { return (React.createElement("tr", { className: (getPolicyForWorkspace(item) === null ? "no-policy" : "") + (canForcePolicy(item) ? "" : " cant-force") },
                React.createElement("td", null,
                    React.createElement("div", { className: "title-area" },
                        React.createElement("div", { className: "ta-icon" },
                            React.createElement(OfficeProfilePicture, { size: "xs", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                        React.createElement("div", { className: "m365-icon" }, item.Application !== "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png", title: item.Application })) : (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png", title: item.Application }))),
                        React.createElement("div", { className: "ta-title" }, item.Title))),
                React.createElement("td", null,
                    React.createElement("div", null, getPolicyForWorkspace(item) !== null ? getPolicyForWorkspace(item) : "No Policy")),
                React.createElement("td", null, getExecuteAction(item)))); })))) : defaultPolicies === null || guestPoliciesLoaded === false ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })) : showComplete === true ? (React.createElement("div", { className: "completed" },
            React.createElement("div", { className: "completed-box" },
                React.createElement("i", { className: "flaticon-list" }),
                React.createElement("div", { className: "done-text" }, "Done - Policies assigned to workspaces!")))) : processing === true ? (React.createElement("div", { className: "processing-card" },
            React.createElement(AnimatedLoader, { text: "Assigning policies to workspaces", type: "squares", size: "xxsmall" }))) : (React.createElement("table", null,
            React.createElement("tr", null,
                React.createElement("th", null, "Workspace"),
                React.createElement("th", null, "Guest Request Policy"),
                React.createElement("th", { className: "th-actions" }),
                React.createElement("th", null, "Guest Review Policy"),
                React.createElement("th", { className: "th-actions" })),
            currentPolicies.map(function (item) {
                var _a, _b;
                return (React.createElement("tr", { className: " item-to-be-updated" + (isWorkspaceEligibleForPolicyReAssignment(item) === true ? "" : " workspace-ineligible") },
                    React.createElement("td", null,
                        React.createElement("div", { className: "title-area" },
                            React.createElement("div", { className: "ta-icon" },
                                React.createElement(OfficeProfilePicture, { size: "xs", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                            React.createElement("div", { className: "m365-icon" }, item.Application === "CommunicationSite" || item.Application === "TeamSite" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png", title: item.Application })) : item.Application === "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png", title: item.Application })) : (React.createElement("img", { src: vivaIcon, title: item.Application }))),
                            React.createElement("div", { className: "ta-title" }, item.Title))),
                    React.createElement("td", null,
                        React.createElement("div", { className: "dropdowns" + (clonePreviewItems.some(function (pi) { return item.SiteId === pi; }) && copyReference.current === "Request" ? " clone-preview" : "") }, isWorkspaceEligibleForPolicyReAssignment(item) && getAssignablePolicies(LifecycleGuestPolicyType.Request).length > 1 ? (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: getAssignablePolicies(LifecycleGuestPolicyType.Request), disabled: getAssignablePolicies(LifecycleGuestPolicyType.Request).length < 2, value: getDropdownPolicyValue(item, LifecycleGuestPolicyType.Request), onChange: function (value) {
                                    updateAssignedPolicies(item, value, LifecycleGuestPolicyType.Request);
                                } }))) : (React.createElement("div", { className: "form-control no-selection-allowed" },
                            React.createElement("div", { className: "no-dd-title" }, (_a = getAssignablePolicies(LifecycleGuestPolicyType.Request).find(function (gp) { return gp.value === getDropdownPolicyValue(item, LifecycleGuestPolicyType.Request); })) === null || _a === void 0 ? void 0 : _a.title),
                            React.createElement("div", { className: "no-dd-info" },
                                React.createElement(InfoTooltip, { title: isWorkspaceEligibleForPolicyReAssignment(item) === false
                                        ? getWorkspaceNotEligibleReason(item)
                                        : getAssignablePolicies(LifecycleGuestPolicyType.Request).length < 2
                                            ? "There are no policies available"
                                            : "" })))))),
                    React.createElement("td", null,
                        React.createElement("div", { className: "actions" },
                            React.createElement("div", { className: "clone", onClick: function () { return cloneSelectionToAllWorkspaces(item, LifecycleGuestPolicyType.Request); }, onMouseEnter: function () {
                                    if (getAssignablePolicies(LifecycleGuestPolicyType.Request).length > 1 && isWorkspaceEligibleForPolicyReAssignment(item)) {
                                        setClonePreviewItems(reportItems.filter(function (ri) { return isWorkspaceEligibleForPolicyReAssignment(ri) === true && ri.SiteId !== item.SiteId; }).map(function (ri) { return ri.SiteId; }));
                                        copyReference.current = "Request";
                                    }
                                }, onMouseLeave: function () {
                                    setClonePreviewItems([]);
                                    copyReference.current = undefined;
                                } }, isWorkspaceEligibleForPolicyReAssignment(item) === false ? (React.createElement("div", { className: "btn disabled" },
                                React.createElement("i", { className: "fa fa-clone" }))) : (React.createElement(InfoTooltip, { placement: "right", delayHide: 0, content: "Clone Request policies to all selected workspaces." },
                                React.createElement("div", { className: "btn" },
                                    React.createElement("i", { className: "fa fa-clone" }))))),
                            React.createElement("div", { className: "undo", onClick: function () { return undoAssignedPolicy(item.SiteId, LifecycleGuestPolicyType.Request); } }, isPolicyNew(item, LifecycleGuestPolicyType.Request) === false || isWorkspaceEligibleForPolicyReAssignment(item) === false ? (React.createElement("div", { className: "btn disabled" },
                                React.createElement("i", { className: "fa fa-undo-alt" }))) : (React.createElement(InfoTooltip, { placement: "right", delayHide: 0, content: "Undo policy assignment change" },
                                React.createElement("div", { className: "btn" },
                                    React.createElement("i", { className: "fa fa-undo-alt" }))))))),
                    React.createElement("td", null,
                        React.createElement("div", { className: "dropdowns" + (clonePreviewItems.some(function (pi) { return item.SiteId === pi; }) && copyReference.current === "Review" ? " clone-preview" : "") }, isWorkspaceEligibleForPolicyReAssignment(item) && getAssignablePolicies(LifecycleGuestPolicyType.Review).length > 1 ? (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: getAssignablePolicies(LifecycleGuestPolicyType.Review), disabled: getAssignablePolicies(LifecycleGuestPolicyType.Review).length < 2, value: getDropdownPolicyValue(item, LifecycleGuestPolicyType.Review), onChange: function (value) {
                                    updateAssignedPolicies(item, value, LifecycleGuestPolicyType.Review);
                                } }))) : (React.createElement("div", { className: "form-control no-selection-allowed" },
                            React.createElement("div", { className: "no-dd-title" }, (_b = getAssignablePolicies(LifecycleGuestPolicyType.Review).find(function (gp) { return gp.value === getDropdownPolicyValue(item, LifecycleGuestPolicyType.Review); })) === null || _b === void 0 ? void 0 : _b.title),
                            React.createElement("div", { className: "no-dd-info" },
                                React.createElement(InfoTooltip, { title: isWorkspaceEligibleForPolicyReAssignment(item) === false
                                        ? getWorkspaceNotEligibleReason(item)
                                        : getAssignablePolicies(LifecycleGuestPolicyType.Review).length < 2
                                            ? "There are no policies available"
                                            : "" })))))),
                    React.createElement("td", null,
                        React.createElement("div", { className: "actions" },
                            React.createElement("div", { className: "clone", onClick: function () { return cloneSelectionToAllWorkspaces(item, LifecycleGuestPolicyType.Review); }, onMouseEnter: function () {
                                    if (getAssignablePolicies(LifecycleGuestPolicyType.Review).length > 1 && isWorkspaceEligibleForPolicyReAssignment(item)) {
                                        setClonePreviewItems(reportItems.filter(function (ri) { return isWorkspaceEligibleForPolicyReAssignment(ri) === true && ri.SiteId !== item.SiteId; }).map(function (ri) { return ri.SiteId; }));
                                        copyReference.current = "Review";
                                    }
                                }, onMouseLeave: function () {
                                    setClonePreviewItems([]);
                                    copyReference.current = undefined;
                                } }, isWorkspaceEligibleForPolicyReAssignment(item) === false ? (React.createElement("div", { className: "btn disabled" },
                                React.createElement("i", { className: "fa fa-clone" }))) : (React.createElement(InfoTooltip, { placement: "right", delayHide: 0, content: "Clone Review policies to all selected workspaces." },
                                React.createElement("div", { className: "btn" },
                                    React.createElement("i", { className: "fa fa-clone" }))))),
                            React.createElement("div", { className: "undo", onClick: function () { return undoAssignedPolicy(item.SiteId, LifecycleGuestPolicyType.Review); } }, isPolicyNew(item, LifecycleGuestPolicyType.Review) === false || isWorkspaceEligibleForPolicyReAssignment(item) === false ? (React.createElement("div", { className: "btn disabled" },
                                React.createElement("i", { className: "fa fa-undo-alt" }))) : (React.createElement(InfoTooltip, { placement: "right", delayHide: 0, content: "Undo policy assignment change" },
                                React.createElement("div", { className: "btn" },
                                    React.createElement("i", { className: "fa fa-undo-alt" })))))))));
            }))))));
});
export default LifecycleWorkspaceBulkGuestActionModal;
