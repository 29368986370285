import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import { LocalizationService } from "app/services/localization";
import { LayoutSplashScreen } from "_metronic";
import * as OrchestryContextStore from "app/store/orchestry-context/current";
import { actions } from "_metronic/ducks/i18n";

// import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";

const I18nProvider = React.memo(props => {
   // export default function I18nProvider({ children }) {
   const locale = useSelector(({ i18n }) => i18n.lang);
   const loadingMessages = useSelector(({ i18n }) => i18n.loadingMessages);
   const [messages, setMessages] = React.useState(null);
   const dispatch = useDispatch();

   React.useEffect(() => {
      // console.log("loading locale", locale);
      LocalizationService.getLocalizedMessages().then(dataMessages => {
         // console.log("messages", dataMessages);
         setMessages(dataMessages);
         dispatch(actions.setLoading(false));
      });
   }, []);

   React.useState(() => {}, [messages]);

   return (
      <>
         {!loadingMessages && (
            <IntlProvider locale={locale} messages={messages}>
               {props.children}
            </IntlProvider>
         )}
      </>
   );
});

export default I18nProvider;
