import { useEffect } from "react";
/**
 * Changes the background color of the app to the specified color.
 * This is useful for modals that need to change the background color of the app.
 * Also useful for PDF pages that need to change the background color of the app.
 * This hook will return a cleanup function that will reset the background color to its original value.
 *
 * @param color The color to change the background to
 */
export var useAppBackgroundColorChange = function (color) {
    useEffect(function () {
        var element = document.getElementById("root");
        if (element) {
            var originalElementBackgroundColor_1 = element.style.backgroundColor;
            var originalHtmlBackgroundColor_1 = document.documentElement.style.backgroundColor;
            document.documentElement.style.backgroundColor = color;
            element.style.backgroundColor = color;
            return function () {
                document.documentElement.style.backgroundColor = originalHtmlBackgroundColor_1;
                element.style.backgroundColor = originalElementBackgroundColor_1;
            };
        }
    }, [color]);
};
