var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Returns a new object with the specified keys removed.
 *
 * @param obj The object to remove keys from.
 * @param keys The keys to remove.
 */
export var omitKeys = function (obj, keys) {
    var newObj = __assign({}, obj);
    keys.forEach(function (key) { return delete newObj[key]; });
    return newObj;
};
