import * as React from "react";
import "./metadata-field.scss";
import { Form } from "react-bootstrap";
import convertTitleToSafePropertyName from "app/utils/convertStringToSafePropertyName";
import ManagedMetadataPicker from "../mananged-metadata-picker";
var MetadataField = React.memo(function (_a) {
    //const orchestryContext: OrchestryContextType.OrchestryContext = useSelector((state: RootState) => state.orchestryContextCurrentState.item);
    var _b, _c;
    var metaDataItem = _a.metaDataItem, defaultValue = _a.defaultValue, onChange = _a.onChange, children = _a.children, managedMetaDataPlaceholder = _a.managedMetaDataPlaceholder;
    // console.log("MetadataField", metaDataItem, defaultValue);
    var multiSelect = React.useRef();
    var returnValue = function (value) {
        if (metaDataItem.GUID !== undefined && metaDataItem.Title !== undefined) {
            onChange({
                FieldGUID: metaDataItem.GUID,
                FieldTitle: metaDataItem.Title,
                FieldType: metaDataItem.FieldType,
                Values: value
            });
        }
    };
    var returnValueMultiselect = function () {
        var values = "";
        var options = [].slice.call(multiSelect.current.querySelectorAll("input"));
        var selected = options.filter(function (option) { return option.checked; });
        if (selected !== undefined && selected.length > 0)
            values = selected.map(function (option) { return option.alt; }).join("\r\n");
        if (metaDataItem.GUID !== undefined && metaDataItem.Title !== undefined) {
            onChange({
                FieldGUID: metaDataItem.GUID,
                FieldTitle: metaDataItem.Title,
                FieldType: metaDataItem.FieldType,
                Values: values
            });
        }
    };
    if (metaDataItem.FieldType === "Choice") {
        if (metaDataItem.SingleMultiple === "Single") {
            return (React.createElement("div", { className: "metadata-field" },
                React.createElement("div", { className: "form-edit-choice-single" },
                    React.createElement("fieldset", null,
                        React.createElement(Form.Group, { className: "form-group radio-green" },
                            React.createElement(Form.Label, null,
                                metaDataItem.Title,
                                " ",
                                metaDataItem.RequiredInDetailsForm !== undefined && metaDataItem.RequiredInDetailsForm === true ? React.createElement("span", { className: "required-astrix" }, "*") : null),
                            metaDataItem.Instructions !== null ? (React.createElement("div", { className: "metadata-field-tooltip" },
                                React.createElement("div", { className: "body" }, metaDataItem.Instructions))) : null,
                            React.createElement("div", { className: "radios" }, (_b = metaDataItem.Values) === null || _b === void 0 ? void 0 : _b.split("\r\n").map(function (value, index) { return (React.createElement(Form.Check, { type: "radio", label: value, checked: defaultValue !== undefined && defaultValue === value ? true : false, name: convertTitleToSafePropertyName(metaDataItem.Title), id: convertTitleToSafePropertyName(metaDataItem.Title) + "_" + index, key: index, onChange: function () {
                                    returnValue(value);
                                } })); })),
                            children)))));
        }
        else {
            return (React.createElement("div", { className: "metadata-field" },
                React.createElement("div", { className: "form-edit-choice-multiple" },
                    React.createElement("fieldset", null,
                        React.createElement(Form.Group, { className: "form-group checkbox-green" },
                            React.createElement(Form.Label, null,
                                metaDataItem.Title,
                                " ",
                                metaDataItem.RequiredInDetailsForm !== undefined && metaDataItem.RequiredInDetailsForm === true ? React.createElement("span", { className: "required-astrix" }, "*") : null),
                            metaDataItem.Instructions !== null ? (React.createElement("div", { className: "metadata-field-tooltip" },
                                React.createElement("div", { className: "body" }, metaDataItem.Instructions))) : null,
                            React.createElement("div", { className: "checkboxes", ref: multiSelect }, (_c = metaDataItem.Values) === null || _c === void 0 ? void 0 : _c.split("\r\n").map(function (value, index) { return (React.createElement(Form.Check, { type: "checkbox", alt: value, label: value, checked: defaultValue !== undefined && defaultValue.split("\r\n").some(function (v) { return v === value; }) ? true : false, name: convertTitleToSafePropertyName(metaDataItem.Title), id: convertTitleToSafePropertyName(metaDataItem.Title) + "_" + index, key: index, onChange: function () {
                                    returnValueMultiselect();
                                } })); })),
                            children)))));
        }
    }
    if (metaDataItem.FieldType === "Date") {
        return (React.createElement("div", { className: "metadata-field" },
            React.createElement("div", { className: "form-edit-date" },
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null,
                        metaDataItem.Title,
                        " ",
                        metaDataItem.RequiredInDetailsForm !== undefined && metaDataItem.RequiredInDetailsForm === true ? React.createElement("span", { className: "required-astrix" }, "*") : null),
                    metaDataItem.Instructions !== null ? (React.createElement("div", { className: "metadata-field-tooltip" },
                        React.createElement("div", { className: "body" }, metaDataItem.Instructions))) : null,
                    React.createElement(Form.Control, { placeholder: "", defaultValue: defaultValue !== undefined && defaultValue !== "" ? defaultValue : undefined, type: "date", onChange: function (event) {
                            returnValue(event.target.value);
                        } }),
                    children))));
    }
    if (metaDataItem.FieldType === "Text" || metaDataItem.FieldType === "Link") {
        if (metaDataItem.SingleMultiple === "Single") {
            return (React.createElement("div", { className: "metadata-field" },
                React.createElement("div", { className: "form-edit-text" },
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(Form.Label, null,
                            metaDataItem.Title,
                            " ",
                            metaDataItem.RequiredInDetailsForm !== undefined && metaDataItem.RequiredInDetailsForm === true ? React.createElement("span", { className: "required-astrix" }, "*") : null),
                        metaDataItem.Instructions !== null ? (React.createElement("div", { className: "metadata-field-tooltip" },
                            React.createElement("div", { className: "body" }, metaDataItem.Instructions))) : null,
                        React.createElement(Form.Control, { placeholder: "", defaultValue: defaultValue, onChange: function (event) {
                                returnValue(event.target.value);
                            } }),
                        children))));
        }
        else {
            return (React.createElement("div", { className: "metadata-field" },
                React.createElement("div", { className: "form-edit-text" },
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(Form.Label, null,
                            metaDataItem.Title,
                            " ",
                            metaDataItem.RequiredInDetailsForm !== undefined && metaDataItem.RequiredInDetailsForm === true ? React.createElement("span", { className: "required-astrix" }, "*") : null),
                        metaDataItem.Instructions !== null ? (React.createElement("div", { className: "metadata-field-tooltip" },
                            React.createElement("div", { className: "body" }, metaDataItem.Instructions))) : null,
                        React.createElement(Form.Control, { as: "textarea", placeholder: "", rows: 5, defaultValue: defaultValue, onChange: function (event) {
                                returnValue(event.target.value);
                            } }),
                        children))));
        }
    }
    if (metaDataItem.FieldType === "ManagedMetadata") {
        return (React.createElement("div", { className: "metadata-field" },
            React.createElement("div", { className: "form-edit-date" },
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null,
                        metaDataItem.Title,
                        " ",
                        metaDataItem.RequiredInDetailsForm !== undefined && metaDataItem.RequiredInDetailsForm === true ? React.createElement("span", { className: "required-astrix" }, "*") : null),
                    metaDataItem.Instructions !== null ? (React.createElement("div", { className: "metadata-field-tooltip" },
                        React.createElement("div", { className: "body" }, metaDataItem.Instructions))) : null,
                    React.createElement(ManagedMetadataPicker, { allowMultiple: metaDataItem.SingleMultiple === "Single" ? false : true, allowTextEntry: true, view: "input-picker", placeholder: managedMetaDataPlaceholder, startNodeJson: metaDataItem.Values, mmdJsonValue: defaultValue, allowNonAvailableToBeTagged: false, onChange: function (metadataJson) {
                            returnValue(metadataJson);
                        } }),
                    children))));
    }
    // <div className="form-edit-url">
    //     <Form.Group className="form-group" controlId="control-link-text">
    //         <Form.Label>Link Text</Form.Label>
    //         <Form.Control
    //             placeholder="eg: Google"
    //             defaultValue=""
    //             type="text"
    //         />
    //     </Form.Group>
    //     <Form.Group className="form-group" controlId="control-link-url">
    //         <Form.Label>Link URL</Form.Label>
    //         <Form.Control
    //             placeholder="eg: https://www.google.com"
    //             defaultValue=""
    //             type="url"
    //         />
    //     </Form.Group>
    // </div>
    return React.createElement(React.Fragment, null);
});
export default MetadataField;
