import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import clsx from "clsx";
import React, { forwardRef } from "react";
import ORCH_SVG_ICONS from "../../assets/icons/orchestry-svgs/svg-icons";
import "./orchestry-thumbs.scss";
import { VoteState } from "./orchestry-thumbs.type";
/**
 * Displays a clickable Orchestry thumbs up and thumbs down.
 */
export var OrchestryThumbs = forwardRef(function (_a, ref) {
    var onClickThumbs = _a.onClickThumbs, voteValue = _a.voteValue;
    var _b = React.useState(voteValue || VoteState.Novote), voteState = _b[0], setVoteState = _b[1];
    React.useEffect(function () {
        setVoteState(voteValue || VoteState.Novote);
    }, [voteValue]);
    var onThumbsUpClick = function (e) {
        if (voteState === VoteState.Upvote) {
            setVoteState(VoteState.Novote);
            onClickThumbs(VoteState.Novote);
        }
        else {
            setVoteState(VoteState.Upvote);
            onClickThumbs(VoteState.Upvote);
        }
    };
    var onThumbsDownClick = function (e) {
        if (voteState === VoteState.Downvote) {
            setVoteState(VoteState.Novote);
            onClickThumbs(VoteState.Novote);
        }
        else {
            setVoteState(VoteState.Downvote);
            onClickThumbs(VoteState.Downvote);
        }
    };
    return (React.createElement("div", { ref: ref, className: "orchestry-thumbs" },
        React.createElement("i", { className: clsx(ORCH_ICONS.thumbsUp, { selected: voteState === VoteState.Upvote }), onClick: onThumbsUpClick }),
        React.createElement("img", { className: clsx("orch-thumbs-up-colored", { selected: voteState === VoteState.Upvote }), src: ORCH_SVG_ICONS.ThumbsUp, alt: "Selected Thumbs Up", onClick: onThumbsUpClick }),
        React.createElement("i", { className: clsx(ORCH_ICONS.thumbsDown, { selected: voteState === VoteState.Downvote }), onClick: onThumbsDownClick }),
        React.createElement("img", { className: clsx("orch-thumbs-down-colored", { selected: voteState === VoteState.Downvote }), src: ORCH_SVG_ICONS.ThumbsDown, alt: "Selected Thumbs Down", onClick: onThumbsDownClick })));
});
