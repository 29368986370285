import * as React from "react";
import { generateReasons } from "./reasons";
var ReportColumnGuestDeletionRecommendationReason = React.memo(function (props) {
    var _a = React.useState([]), reasons = _a[0], setReasons = _a[1];
    React.useEffect(function () {
        setReasons(generateReasons(props.Item, props.DaysInactive, false));
    }, [props.Item]);
    return (React.createElement("div", { className: "deletion-reason-recommendation" }, reasons && reasons.length > 0 ? (React.createElement("ul", null, reasons.map(function (reason, i) { return (React.createElement("li", { key: i, className: "color-" + reason.Color },
        React.createElement("span", null, reason.Title))); }))) : null));
});
export default ReportColumnGuestDeletionRecommendationReason;
