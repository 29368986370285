import * as ChannelCurrentStore from "app/store/channels/current";
import { AsyncCallType } from "app/store/async/types";
export var channelCurrentActionMap = [
    {
        actionType: ChannelCurrentStore.ChannelCurrentActionTypes.Channel_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "channelListState.items",
        keyType: "guid",
        ApiUrl: "/api/Channels/?guid={key}",
        responseDataType: {}
    }
];
