import React from "react";
import { RecommendationNewNumberStatSkeleton } from "./recommendation-new-number-stat-skeleton.component";
import { RecommendationPriorityMetricSkeleton } from "./recommendation-priority-metric-skeleton.component";
import { RecommendationResolutionsNumberStatSkeleton } from "./recommendation-resolutions-number-stat-skeleton.component";
import { RecommendationTopCategoriesSkeleton } from "./recommendation-top-categories-skeleton.component";
import "./recommendation-header-skeletons.scss";
export var RecommendationHeaderSkeletons = function () { return (React.createElement("div", { className: "recommendation-header-skeletons" },
    React.createElement(RecommendationPriorityMetricSkeleton, null),
    React.createElement(RecommendationResolutionsNumberStatSkeleton, null),
    React.createElement(RecommendationNewNumberStatSkeleton, null),
    React.createElement(RecommendationTopCategoriesSkeleton, null))); };
