import { Card, Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { roundAndLocalizeNumber } from "appv2/utils/number.utils";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
import { ToDatePill } from "../to-date-pill.component";
export var StorageSavedTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, numberOfDaysInPeriod = _a.numberOfDaysInPeriod, _b = _a.data.MetricsData.OrchestryUsage.StorageSaved, GBPurgedTotal = _b.GBPurgedTotal, FilesPurgedTotal = _b.FilesPurgedTotal, PreviousValue = _b.PreviousValue, DeltaValue = _b.DeltaValue, DeltaPercentage = _b.DeltaPercentage, DataCoverageAvailable = _b.DataCoverageAvailable, TotalToDate = _b.TotalToDate;
    return (React.createElement(Card, { variant: "shadow-border", mih: "100%", p: 24, style: { overflow: "visible" } },
        React.createElement(Group, { gap: "8px" },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "xs", variant: "light-circle", colorVariant: "purple-7", iconClassName: ORCH_ICONS.data }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "14", fw: 500, lh: "20px" }, intl.formatMessage({ id: "page.health_check.storage_saved_tile.title", defaultMessage: "Storage Saved" }))),
        React.createElement(OrchestryText, { skeletonWidth: 30, isLoading: isLoading, c: "text-primary.0", fz: 24, fw: 500, lh: "32px", mt: 8 }, roundAndLocalizeNumber(GBPurgedTotal, 2)),
        React.createElement(OrchestryText, { skeletonWidth: 60, isLoading: isLoading, c: "text-secondary.0", fz: 14, fw: 300, lh: "20px", mt: -4 }, intl.formatMessage({ id: "page.health_check.storage_saved_tile.gb_purged", defaultMessage: "GB Purged" })),
        React.createElement(OrchestryText, { skeletonWidth: 30, isLoading: isLoading, c: "text-primary.0", fz: 24, fw: 500, lh: "32px", mt: 8 }, FilesPurgedTotal.toLocaleString()),
        React.createElement(OrchestryText, { skeletonWidth: 60, isLoading: isLoading, c: "text-secondary.0", fz: 14, fw: 300, lh: "20px", mt: -4 }, intl.formatMessage({ id: "page.health_check.storage_saved_tile.files_purged", defaultMessage: "Files Purged" })),
        React.createElement(Group, { p: 0, mt: 12 },
            React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, align: "Flex-start", lastPeriodValue: Number(PreviousValue.toFixed(2)), deltaPercentage: DeltaPercentage, numberOfDaysInPeriod: numberOfDaysInPeriod, dataCoverageAvailable: DataCoverageAvailable, deltaValue: DeltaValue })),
        React.createElement(ToDatePill, { tileTooltipId: "page.health_check.storage_saved_tile.tooltip", tileTooltipDefaultMessage: "Both the delta value and total cumulative value (to date) reflect solely the GB purged.", isLoading: isLoading, totalToDate: TotalToDate !== null && TotalToDate !== void 0 ? TotalToDate : 0, badgeColor: "var(--mantine-color-purple-7)", mt: 8 })));
};
