import * as React from "react";
import "./principal-item-guest.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import InfoTooltip from "../infotooltip";
import { useIntl, FormattedMessage } from "react-intl";
var PrincipalItemGuest = React.memo(function (_a) {
    var _b;
    var principal = _a.principal, showBadge = _a.showBadge, onRemove = _a.onRemove, showBorder = _a.showBorder, size = _a.size, style = _a.style;
    var intl = useIntl();
    var remove = function (user) {
        if (onRemove !== undefined) {
            onRemove(user);
        }
    };
    var isGuestInGraph = function () {
        if (principal.id !== null) {
            return true;
        }
        return false;
    };
    return style === undefined || style === "normal" ? (React.createElement("div", { className: "principal-item-guest" +
            (onRemove === undefined ? " hide-remove" : "") +
            (showBorder !== undefined && showBorder === false ? " no-border" : "") +
            (size !== undefined ? " size-" + size : ""), key: principal.id },
        React.createElement("div", { className: "pic" },
            showBadge !== undefined && showBadge ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "principal-type type-" + ((_b = principal.Type) === null || _b === void 0 ? void 0 : _b.toLowerCase()) }, principal.Type === "User" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.user_label", defaultMessage: "User" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.user_acronym", defaultMessage: "U" }))) : principal.Type === "Group" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.group_label", defaultMessage: "Group" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.group_acronym", defaultMessage: "G" }))) : principal.Type === "SecurityGroup" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.security_label", defaultMessage: "Security Group" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.security_acronym", defaultMessage: "S" }))) : principal.Type === "DistributionGroup" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.distribution_label", defaultMessage: "Distribution List" }) },
                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.distribution_acronym", defaultMessage: "D" }))) : ("G")))) : null,
            React.createElement(OfficeProfilePicture, { uid: principal.id, displayName: principal.displayName, type: "User" })),
        React.createElement("span", null,
            principal.displayName,
            showBadge !== undefined && showBadge ? React.createElement("span", { className: "guest-note" }, "(Guest)") : null),
        React.createElement(React.Fragment, null, onRemove !== undefined ? (React.createElement("div", { className: "remove", onClick: function () {
                remove(principal);
            } },
            React.createElement(FontAwesomeIcon, { icon: faTimes }))) : null))) : (React.createElement("div", { className: "principal-item-guest" + (size !== undefined ? " size-" + size : ""), key: principal.id },
        React.createElement(InfoTooltip, { content: principal.displayName },
            React.createElement("div", { className: "pic" },
                React.createElement(OfficeProfilePicture, { uid: principal.id, displayName: principal.id, type: "User" })))));
});
export default PrincipalItemGuest;
