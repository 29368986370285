import { Group, Pagination } from "@mantine/core";
import React from "react";
import { useIntl } from "react-intl";
import { ReactComponent as ChevronLeft } from "../../assets/icons/orchestry-icons-raw/chevron-left-pagination.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/orchestry-icons-raw/chevron-right-pagination.svg";
import { OrchestryCombobox } from "../orchestry-combobox/orchestry-combobox.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import "./orchestry-pagination.scss";
/**
 * The default options for the results per page combobox.
 */
export var defaultResultOptions = [{ value: "25" }, { value: "50" }, { value: "75" }, { value: "100" }];
/**
 * Represents a paging bar and how many results the paged list should display at once
 */
export var OrchestryPagination = function (_a) {
    var activePage = _a.activePage, setactivePage = _a.setactivePage, pageTotal = _a.pageTotal, resultsPerPage = _a.resultsPerPage, onResultsPerPageChange = _a.onResultsPerPageChange, totalCount = _a.totalCount, resultOptions = _a.resultOptions, variant = _a.variant, isLoading = _a.isLoading;
    var hasMultiplePages = pageTotal > 1;
    var intl = useIntl();
    return (React.createElement(Group, { pt: 16, pr: 16, pb: 11, pl: 16, justify: "space-between", className: "orchestry-pagination", "data-variant": variant },
        React.createElement("div", { className: "results-per-page" },
            React.createElement(OrchestryText, { skeletonWidth: 36, isLoading: isLoading, c: "text-tertiary.0", size: "sm", fw: 400, lh: "20px" }, intl.formatMessage({ id: "controls.paging.show", defaultMessage: "Show" })),
            React.createElement(OrchestryCombobox, { isLoading: isLoading, skeletonWidth: 80, variant: "selected-value", defaultValue: resultsPerPage, onChange: onResultsPerPageChange, placeholder: "Select Value", options: resultOptions !== null && resultOptions !== void 0 ? resultOptions : defaultResultOptions })),
        React.createElement("div", { className: "pagination-items" },
            React.createElement(Pagination.Root, { value: activePage, onChange: setactivePage, total: pageTotal },
                React.createElement(Pagination.Previous, { disabled: !hasMultiplePages || activePage === 1, icon: ChevronLeft }),
                React.createElement(Pagination.Items, null),
                React.createElement(Pagination.Next, { disabled: !hasMultiplePages || activePage === pageTotal, icon: ChevronRight }))),
        React.createElement(OrchestryText, { skeletonWidth: 97, isLoading: isLoading, c: "text-tertiary.0", size: "sm", fw: 400, lh: "20px" }, intl.formatMessage({ id: "controls.paging.results", defaultMessage: "{count} results" }, {
            count: totalCount
        }))));
};
