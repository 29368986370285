import TeamsService from "app/services/teams";
import TeamClientAppNavigation from "app/utils/teamClientAppNavigation";
import * as React from "react";
import { useSelector } from "react-redux";
import AnimatedLoader from "../animated-loader";
import "./team-link.scss";
var TeamLink = React.memo(function (props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var openInTeamsApp = React.useState(props.openInTeamsIfPossible !== undefined ? props.openInTeamsIfPossible : false)[0];
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var hostClientType = teamContext === null || teamContext === void 0 ? void 0 : teamContext.app.host.clientType;
    var teamClick = function () {
        setLoading(true);
        TeamsService.GetTeamUrl(props.teamGuid).then(function (url) {
            if (url !== "") {
                if (openInTeamsApp) {
                    TeamClientAppNavigation.goToTeamsLink(url, undefined, hostClientType);
                }
                else {
                    window.open(url);
                }
            }
            setLoading(false);
        });
    };
    return (React.createElement("span", { className: "team-link", onClick: function () {
            teamClick();
        } },
        React.createElement("div", { className: "loading-link" + (loading ? " is-visible" : "") },
            React.createElement(AnimatedLoader, { size: "xxsmall" })),
        props.children));
});
export default TeamLink;
