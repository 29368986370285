var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { FeatureListActionTypes } from "./types";
export function Load(Features) {
    if (Features === undefined)
        Features = [];
    return {
        type: FeatureListActionTypes.Feature_List_Load,
        payload: __spreadArray([], Features, true)
    };
}
export function AddItem(Feature) {
    return {
        type: FeatureListActionTypes.Feature_List_Item_Add,
        payload: __assign({}, Feature)
    };
}
export function UpdateItem(Feature) {
    return {
        type: FeatureListActionTypes.Feature_List_Item_Update,
        payload: __assign({}, Feature)
    };
}
export function DeleteItem(Feature) {
    return {
        type: FeatureListActionTypes.Feature_List_Item_Delete,
        payload: __assign({}, Feature)
    };
}
export var featureListMiddlware = function (store, next, action) {
    // if(action.type === FeatureListActionTypes.Feature_List_Load){
    //     action.payload.forEach((feat:Feature) => {
    //         if(feat.Icon !== undefined)
    //             feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //     });
    //     //console.log("Middleware", action.payload);
    //     next(action);
    // }
    // // else if(action.type === FeatureListActionTypes.Feature_List_Item_Update || action.type === FeatureListActionTypes.Feature_List_Item_Add ) {
    // //     let feat:Feature = action.payload;
    // //     if(feat.Icon !== undefined)
    // //         console.log("Saving Icon MOd");
    // //         //feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    // // }
    // else {
    next(action);
    //}
};
