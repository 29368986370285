var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Autocomplete } from "@mantine/core";
import "@mantine/core/styles.css";
import React, { forwardRef } from "react";
/**
 * A component that displays a text input with a list of suggestions based on the
 * input, however user is not limited to suggestions and can type anything.
 * No default variant is provided, so we must specify it.
 */
export var OrchestryAutocomplete = forwardRef(function (props, ref) {
    return React.createElement(Autocomplete, __assign({ ref: ref }, props));
});
