import { AsyncCallType } from "app/store/async/types";
import { LifecycleGuestPolicyCurrentActionTypes } from "./types";
export var lifecycleGuestPolicyCurrentActionMap = [
    {
        actionType: LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "lifecycleGuestPoliciesCurrentState.item",
        keyType: "match",
        ApiUrl: "/api/LifecycleGuestPolicies/?guid={GUID}",
        responseDataType: {}
    }
];
