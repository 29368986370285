import { Group, Skeleton } from "@mantine/core";
import React from "react";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import "./orchestry-color-label.component.scss";
export var OrchestryColorLabel = function (_a) {
    var color = _a.color, label = _a.label, isLoading = _a.isLoading;
    if (isLoading) {
        return (React.createElement(Group, { gap: 8 },
            React.createElement(Skeleton, { w: 16, h: 16, circle: true }),
            React.createElement(Skeleton, { w: 100, h: 22, radius: "m" })));
    }
    return (React.createElement("div", { className: "orchestry-color-label" },
        React.createElement("div", { className: "color", style: { backgroundColor: color } }),
        React.createElement(OrchestryText, { isLoading: isLoading, fw: 400, c: "text-secondary.0", fz: "14", lh: "20px" }, label)));
};
