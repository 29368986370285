var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable jsx-a11y/anchor-has-content */
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import FontIcon from "app/components/common/font-icon";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import InfoTooltip from "app/components/common/infotooltip";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import NoResults from "app/components/common/no-results";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import "./step-select-workspace.scss";
var StepSelectWorkspace = React.memo(function (props) {
    var intl = useIntl();
    var _a = React.useState(false), filterOpen = _a[0], setFilterOpen = _a[1];
    var _b = React.useContext(RequestWizardContext), availableTemplates = _b.availableTemplates, currentRequest = _b.currentRequest, updateCurrentRequest = _b.updateCurrentRequest, setStepProgress = _b.setStepProgress, gotoNextStep = _b.gotoNextStep, currentNamingPolicy = _b.currentNamingPolicy;
    var filterDropdown = React.useRef();
    var _c = React.useState([]), stepAvailableTemplates = _c[0], setStepAvailableTemplates = _c[1];
    var _d = React.useState("featured"), selectedSort = _d[0], setSelectedSort = _d[1];
    var _e = React.useState(""), searchTerm = _e[0], setSearchTerm = _e[1];
    var _f = React.useState([]), availableCategories = _f[0], setAvailableCategories = _f[1];
    var _g = React.useState([]), selectedCategories = _g[0], setSelectedCategories = _g[1];
    var _h = React.useState(""), categorySearchTerm = _h[0], setCategorySearchTerm = _h[1];
    var tenantConfig = useSelector(function (state) { return state.tenantConfigurationCurrentState; });
    var templatesLoaded = useSelector(function (state) { return state.templateListState.loaded; });
    var _j = React.useState(false), pageLoaded = _j[0], setPageLoaded = _j[1];
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    React.useEffect(function () {
        if (stepAvailableTemplates.length === 0) {
            setStepAvailableTemplates(availableTemplates.sort(sortCompare));
            var categories_1 = [];
            availableTemplates.forEach(function (at) {
                if (at.Category !== undefined && at.Category !== null && at.Category !== "" && !categories_1.includes(at.Category)) {
                    categories_1.push(at.Category);
                }
            });
            setAvailableCategories(categories_1);
        }
    }, [availableTemplates]);
    React.useEffect(function () {
        document.addEventListener("click", handleClickOutside, true);
        return function () {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    var handleClickOutside = function (event) {
        try {
            var element = event.target;
            if (!filterDropdown !== undefined && !filterDropdown.current.contains(element)) {
                setFilterOpen(false);
            }
        }
        catch (_a) { }
    };
    var sortCompare = function (a, b) {
        if (selectedSort === "most-popular") {
            if (a.ArtifactsUsingThisTemplate !== undefined && b.ArtifactsUsingThisTemplate !== undefined) {
                if (a.ArtifactsUsingThisTemplate > b.ArtifactsUsingThisTemplate) {
                    return -1;
                }
                if (a.ArtifactsUsingThisTemplate < b.ArtifactsUsingThisTemplate) {
                    return 1;
                }
            }
        }
        else if (selectedSort === "featured") {
            if (a.Position !== undefined && b.Position !== undefined) {
                if (a.Position < b.Position) {
                    return -1;
                }
                if (a.Position > b.Position) {
                    return 1;
                }
                if (a.Position === b.Position && a.Title !== undefined && b.Title !== undefined) {
                    return a.Title.localeCompare(b.Title);
                }
            }
        }
        else if (selectedSort === "name") {
            if (a.Title !== undefined && b.Title !== undefined) {
                if (a.Title < b.Title) {
                    return -1;
                }
                if (a.Title > b.Title) {
                    return 1;
                }
            }
        }
        else {
            if (a.Position !== undefined && b.Position !== undefined) {
                if (a.Position < b.Position) {
                    return -1;
                }
                if (a.Position > b.Position) {
                    return 1;
                }
            }
        }
        return 0;
    };
    React.useEffect(function () {
        setStepAvailableTemplates(availableTemplates
            .filter(function (t) {
            var _a, _b;
            var match = true;
            if (searchTerm !== "" && t.Title !== undefined && ((_a = t.Title) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(searchTerm.toLowerCase())) < 0) {
                match = false;
            }
            if (match === false && searchTerm !== "" && t.Description !== undefined && ((_b = t.Description) === null || _b === void 0 ? void 0 : _b.toLowerCase().indexOf(searchTerm.toLowerCase())) < 0) {
                match = false;
            }
            if (selectedCategories.length > 0) {
                if (!selectedCategories.includes(t.Category !== undefined ? t.Category : "")) {
                    match = false;
                }
            }
            return match;
        })
            .sort(sortCompare));
    }, [searchTerm, selectedSort, selectedCategories]);
    var toggleFilterCategory = function (value, add) {
        if (add) {
            if (!selectedCategories.includes(value)) {
                setSelectedCategories(__spreadArray(__spreadArray([], selectedCategories, true), [value], false));
            }
        }
        else {
            if (selectedCategories.includes(value)) {
                setSelectedCategories(selectedCategories.filter(function (sc) { return sc !== value; }));
            }
        }
    };
    var workspaceClick = function (selectedTemplate) {
        if (currentRequest.Template !== undefined && selectedTemplate.GUID === currentRequest.Template.GUID) {
            if (selectedTemplate.NamingPolicyEnabled && selectedTemplate.NamingPolicyGUID === (currentNamingPolicy === null || currentNamingPolicy === void 0 ? void 0 : currentNamingPolicy.GUID))
                gotoNextStep();
            else if (!selectedTemplate.NamingPolicyEnabled)
                gotoNextStep();
        }
        else {
            updateCurrentRequest({ Template: selectedTemplate });
            switch (selectedTemplate.TemplateMultiGeoDataLocationSource) {
                case "Requestor":
                    var geo = currentUser === null || currentUser === void 0 ? void 0 : currentUser.PreferredDataLocation;
                    if (geo !== undefined && geo !== null) {
                        updateCurrentRequest({ RequestMultiGeoDataLocation: geo });
                    }
                    else {
                        geo = tenantConfig.item.DefaultGeo;
                        updateCurrentRequest({ RequestMultiGeoDataLocation: tenantConfig.item.DefaultGeo });
                    }
                    if (geo !== tenantConfig.item.DefaultGeo)
                        updateCurrentRequest({ ShowInDirectory: false });
                    break;
                case "Enforced":
                    updateCurrentRequest({ RequestMultiGeoDataLocation: selectedTemplate.TemplateMultiGeoDataLocations });
                    if (selectedTemplate.TemplateMultiGeoDataLocations !== tenantConfig.item.DefaultGeo)
                        updateCurrentRequest({ ShowInDirectory: false });
                    break;
                case "Default":
                    updateCurrentRequest({ RequestMultiGeoDataLocation: tenantConfig.item.DefaultGeo });
                    break;
                default:
                    break;
            }
            setStepProgress(props.step.key, true);
        }
    };
    React.useEffect(function () {
        if (tenantConfig.loadedOnce === true && templatesLoaded === true) {
            setPageLoaded(true);
        }
        else {
            setPageLoaded(false);
        }
    }, [tenantConfig.loadedOnce, templatesLoaded]);
    return (React.createElement("div", { className: "step-select-workspace", "data-pw": "step-select-workspace" }, pageLoaded === false ? (React.createElement("div", { className: "loading-skeleton" },
        React.createElement("div", { className: "sub-header" },
            React.createElement("div", { className: "filter-dropdown-wrapper" + (availableCategories.length === 0 ? " hidden" : ""), ref: filterDropdown },
                React.createElement("div", { className: "filter-button", onClick: function () {
                        setFilterOpen(!filterOpen);
                    } },
                    React.createElement("div", { className: "custom-filter-button" },
                        React.createElement("div", { className: "line1" }),
                        React.createElement("div", { className: "line2" }),
                        React.createElement("div", { className: "line3" }),
                        React.createElement("div", { className: "line4" })),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_select.filter", defaultMessage: "Filter" }))),
                React.createElement(Collapse, { in: filterOpen },
                    React.createElement("div", { className: "filter-dropdown" },
                        React.createElement("div", { className: "filter-search" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "flaticon-search" })),
                            React.createElement("input", { placeholder: intl.formatMessage({ id: "page.request_wizard.step_select.search", defaultMessage: "Search" }), onChange: function (event) {
                                    setCategorySearchTerm(event.target.value);
                                } })),
                        React.createElement("div", { className: "checkboxes" })))),
            React.createElement("div", { className: "search" },
                React.createElement("div", { className: "icon" },
                    React.createElement("i", { className: "flaticon-search" })),
                React.createElement("input", { placeholder: intl.formatMessage({ id: "page.request_wizard.step_select.search", defaultMessage: "Search" }), onChange: function (event) {
                        setSearchTerm(event.target.value);
                    } })),
            React.createElement("div", { className: "sort" },
                React.createElement("span", { className: "sort-by" },
                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_select.sort_by", defaultMessage: "Sort by:" })),
                React.createElement("div", { className: "dropdown" }))),
        React.createElement(Row, { className: "workflows-row" }, [1, 2, 3, 4, 5, 6].map(function (item, iIndex) { return (React.createElement(Col, { md: 6, lg: 4, xl: 4, key: iIndex },
            React.createElement("div", { className: "workspace" },
                React.createElement("div", { className: "preview-image animated-wave" }),
                React.createElement("div", { className: "title animated-wave" }),
                React.createElement("div", { className: "description animated-wave" }),
                React.createElement("div", { className: "features" },
                    React.createElement("ul", { className: "list-unstyled" },
                        React.createElement("li", null),
                        React.createElement("li", null),
                        React.createElement("li", null)))))); })))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sub-header" },
            React.createElement("div", { className: "filter-dropdown-wrapper" + (availableCategories.length === 0 ? " hidden" : ""), ref: filterDropdown },
                React.createElement("div", { className: "filter-button", onClick: function () {
                        setFilterOpen(!filterOpen);
                    } },
                    React.createElement("div", { className: "custom-filter-button" },
                        React.createElement("div", { className: "line1" }),
                        React.createElement("div", { className: "line2" }),
                        React.createElement("div", { className: "line3" }),
                        React.createElement("div", { className: "line4" })),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_select.filter", defaultMessage: "Filter" }))),
                React.createElement(Collapse, { in: filterOpen },
                    React.createElement("div", { className: "filter-dropdown" },
                        React.createElement("div", { className: "filter-search" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "flaticon-search" })),
                            React.createElement("input", { placeholder: intl.formatMessage({ id: "page.request_wizard.step_select.search", defaultMessage: "Search" }), onChange: function (event) {
                                    setCategorySearchTerm(event.target.value);
                                } })),
                        React.createElement("div", { className: "checkboxes" }, availableCategories.map(function (cat, catIndex) { return (React.createElement(React.Fragment, null, categorySearchTerm === "" || cat.toLocaleLowerCase().indexOf(categorySearchTerm.toLowerCase()) > -1 ? (React.createElement(Form.Group, { controlId: "formBasicCheckbox" + catIndex, className: "form-group checkbox-grey-large", key: catIndex },
                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, cat), checked: selectedCategories.includes(cat), onChange: function (event) {
                                    toggleFilterCategory(cat, event.currentTarget.checked);
                                } }))) : null)); }))))),
            React.createElement("div", { className: "search" },
                React.createElement("div", { className: "icon" },
                    React.createElement("i", { className: "flaticon-search" })),
                React.createElement("input", { placeholder: intl.formatMessage({ id: "page.request_wizard.step_select.search", defaultMessage: "Search" }), onChange: function (event) {
                        setSearchTerm(event.target.value);
                    } })),
            React.createElement("div", { className: "sort" },
                React.createElement("span", { className: "sort-by" },
                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_select.sort_by", defaultMessage: "Sort by:" })),
                React.createElement("div", { className: "dropdown" },
                    React.createElement(FormDropdownSelect, { defaultValue: "featured", list: [
                            {
                                id: "1",
                                title: intl.formatMessage({ id: "page.request_wizard.step_select.filters.featured", defaultMessage: "Featured" }),
                                value: "featured"
                            },
                            {
                                id: "2",
                                title: intl.formatMessage({ id: "page.request_wizard.step_select.filters.name", defaultMessage: "Name" }),
                                value: "name"
                            },
                            {
                                id: "3",
                                title: intl.formatMessage({ id: "page.request_wizard.step_select.filters.most_popular", defaultMessage: "Most popular" }),
                                value: "most-popular"
                            }
                        ], onChange: function (sortType) {
                            setSelectedSort(sortType);
                        } }))),
            React.createElement(Collapse, { in: selectedCategories.length > 0 },
                React.createElement("div", { className: "applied-tags" },
                    React.createElement("div", { className: "label" },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_select.applied", defaultMessage: "Applied:" })),
                    React.createElement("div", { className: "tags" }, selectedCategories.map(function (cat, catIndex) { return (React.createElement("div", { className: "tag", key: catIndex },
                        cat,
                        React.createElement("i", { className: "la la-close", onClick: function () {
                                toggleFilterCategory(cat, false);
                            } }))); })),
                    React.createElement("div", { className: "clear", onClick: function () {
                            setSelectedCategories([]);
                        } },
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_select.clear_all", defaultMessage: "Clear All" }))))),
        React.createElement(Row, { className: "workflows-row" }, stepAvailableTemplates.length !== 0 ? (
        // <GridGenerator containerFluid={true} cols={3}>
        stepAvailableTemplates.map(function (template, tindex) {
            var _a;
            return (React.createElement(Col, { md: 6, lg: 4, xl: 4, key: "col-" + tindex, className: "".concat(template.GUID !== undefined && currentRequest.Template !== undefined && template.GUID === currentRequest.Template.GUID ? " selected" : "") },
                React.createElement("div", { className: "workspace" + (template.GUID !== undefined && currentRequest.Template !== undefined && template.GUID === currentRequest.Template.GUID ? " selected" : ""), key: template.GUID, onClick: function (e) {
                        var _a;
                        if (e.target instanceof Element && e.target.id !== "more-info" && (e.target instanceof Element && ((_a = e.target) === null || _a === void 0 ? void 0 : _a.closest("a"))) === null)
                            workspaceClick(template);
                    }, "data-pw": "workspace" },
                    React.createElement("div", { className: "preview-image" },
                        template.Image !== null && template.Image !== "" ? (React.createElement("div", { className: "image", style: { backgroundImage: "url('" + template.Image + "')" } })) : (React.createElement("div", { className: "missing-image" },
                            React.createElement(FontIcon, { iconString: "cubes" }))),
                        template.TemplateType !== undefined && template.TemplateType === "Team" ? (React.createElement("div", { className: "workspace-type team-title-style" },
                            React.createElement(InfoTooltip, { content: intl.formatMessage({ id: "page.request_wizard.step_select.hover.teams", defaultMessage: "This is a Microsoft Teams Workspace" }), placement: "bottom" },
                                React.createElement("img", { className: "template-type-icon", src: iconTeams, alt: "Teams Workspace" }),
                                React.createElement("span", { className: "text" }, intl.formatMessage({ id: "page.request_wizard.step_select.template_name.team", defaultMessage: "Team" }))))) : null,
                        template.TemplateType !== undefined && template.TemplateType === "SharePoint" && template.SharePointTemplateType === "ModernCommunications" ? (React.createElement("div", { className: "workspace-type sharepoint-title-style" },
                            React.createElement(InfoTooltip, { content: intl.formatMessage({
                                    id: "page.request_wizard.step_select.hover.sharepoint.communicationsite",
                                    defaultMessage: "This is a SharePoint Communication Site"
                                }), placement: "bottom" },
                                React.createElement("img", { className: "template-type-icon", src: iconSP, alt: "SharePoint Communication Site" }),
                                React.createElement("span", { className: "text" }, intl.formatMessage({ id: "page.request_wizard.step_select.template_name.sharepointcommsite", defaultMessage: "Communication Site" }))))) : null,
                        template.TemplateType !== undefined && template.TemplateType === "SharePoint" && template.SharePointTemplateType === "ModernGroup" ? (React.createElement("div", { className: "workspace-type sharepoint-title-style" },
                            React.createElement(InfoTooltip, { content: intl.formatMessage({
                                    id: "page.request_wizard.step_select.hover.sharepoint.teamsite",
                                    defaultMessage: "This is a SharePoint Team Site"
                                }), placement: "bottom" },
                                React.createElement("img", { className: "template-type-icon", src: iconSP, alt: "SharePoint Team Site" }),
                                React.createElement("span", { className: "text" }, intl.formatMessage({ id: "page.request_wizard.step_select.template_name.sharepointteamsite", defaultMessage: "Team Site" }))))) : null,
                        template.TemplateType !== undefined && template.TemplateType === "VivaEngageCommunity" ? (React.createElement("div", { className: "workspace-type viva-title-style" },
                            React.createElement(InfoTooltip, { content: intl.formatMessage({
                                    id: "page.request_wizard.step_select.hover.vivaengage",
                                    defaultMessage: "This is a Viva Engage Workspace (previously known as Yammer)"
                                }), placement: "bottom" },
                                React.createElement("img", { className: "template-type-icon", src: iconViva, alt: "Viva Engage Community" }),
                                React.createElement("span", { className: "text" }, intl.formatMessage({ id: "page.request_wizard.step_select.template_name.vivaengage", defaultMessage: "Viva Engage" }))))) : null),
                    React.createElement("div", { className: "title" }, template.Title),
                    React.createElement("div", { className: "description" },
                        React.createElement(MarkdownEditor, { style: { boxShadow: "none" }, readOnly: true, templateText: template.Description, enabledStyles: { all: true } })),
                    template.TemplateMoreInfoButtonLink !== undefined && template.TemplateCardMoreInfoButton ? (React.createElement("a", { id: "more-info", title: template.TemplateMoreInfoButtonText, className: "link", href: template.TemplateMoreInfoButtonLink, rel: "noopener noreferrer", target: "_blank" },
                        React.createElement("div", { className: "more-info" },
                            React.createElement("i", { className: "fa fa-info-circle" }),
                            React.createElement("p", null, template.TemplateMoreInfoButtonText),
                            React.createElement("i", { className: "fas fa-external-link-alt link-icon" })))) : null,
                    !template.TemplateCardHideAdditionalFeatures ? (React.createElement("div", { className: "features" },
                        React.createElement("ul", { className: "list-unstyled" }, (_a = template.AssociatedFeatures) === null || _a === void 0 ? void 0 : _a.map(function (featureAss, featindex) { return (React.createElement("li", { key: featureAss.GUID },
                            React.createElement(InfoTooltip, { key: featureAss.GUID, title: featureAss.Item !== undefined ? featureAss.Item.Title : "", content: featureAss.Item !== undefined ? featureAss.Item.Description : undefined, placement: "bottom" }, featureAss.Item !== undefined && featureAss.Item.Icon !== undefined && featureAss.Item.Icon !== null ? (React.createElement(LineAwesomeIcon, { iconString: featureAss.Item.Icon })) : (React.createElement(React.Fragment, null))))); })))) : null)));
        })) : (
        // </GridGenerator>
        React.createElement("div", { className: "no-results" }, availableTemplates.length === 0 ? (React.createElement(NoResults, { subText: intl.formatMessage({
                id: "page.request_wizard.step_select.no_templates_available",
                defaultMessage: "Your administrator has not made any templates available to you yet"
            }) })) : (React.createElement(NoResults, { subText: intl.formatMessage({
                id: "page.request_wizard.step_select.there_are_no_workspaces_avail",
                defaultMessage: "There are no Workspaces Available. Please try again."
            }) })))))))));
});
export default StepSelectWorkspace;
