var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Skeleton } from "@mantine/core";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import { getReadableDateRange } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/nb"; //https://github.com/iamkun/dayjs/issues/732 means no should map to nb
import "dayjs/locale/pt";
import "dayjs/locale/sv";
import React from "react";
import { useSelector } from "react-redux";
import { OrchestryChip } from "../orchestry-chip/orchestry-chip.component";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import "./orchestry-date-picker-input.scss";
/**
 * The number of selected items in a fully selected date range.
 */
export var DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT = 2;
export var OrchestryDatePickerInput = function (props) {
    var locale = useSelector(function (_a) {
        var i18n = _a.i18n;
        return i18n.lang;
    });
    if (props.type === "range" && props.isFilter) {
        var range = props.value;
        if (range[0] && range[1]) {
            var readableRange = getReadableDateRange(range[0], range[1]);
            return React.createElement(OrchestryChip, { color: "var(--mantine-color-gray-9)", selected: true, size: "lg", icon: ORCH_ICONS.calendar, title: readableRange, onClick: function () { var _a; return (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, []); } });
        }
    }
    if (props.isLoading) {
        return React.createElement(Skeleton, __assign({ width: 225, height: 33 }, props));
    }
    return (React.createElement(DatesProvider, { settings: { locale: locale } },
        React.createElement(DatePickerInput, __assign({ fz: 12, classNames: {
                input: "orchestry-date-picker-input",
                calendarHeader: "orchestry-date-picker-input__calendar-header",
                day: "orchestry-date-picker-input__day",
                weekday: "orchestry-date-picker-input__weekday"
            }, w: "fit-content", leftSection: React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.calendar, color: "var(--mantine-color-gray-7)", fontSize: 16, lineHeight: "16px" }), leftSectionPointerEvents: "none", maxDate: props.maxDate ? props.maxDate : new Date(), popoverProps: { withinPortal: false } }, props))));
};
