import * as React from "react";
import { getDaysInactiveClass } from "../report-column-workspace-name";
import "./report-column-guest-days-inactive.scss";
var ReportColumnGuestDaysInactive = React.memo(function (props) {
    var _a;
    var _b = React.useState(null), userItem = _b[0], setUserItem = _b[1];
    React.useEffect(function () {
        var _a, _b;
        if (props.Item.ExtendedProperties !== undefined && props.Item.ExtendedProperties !== null && ((_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) !== undefined) {
            setUserItem((_b = props.Item.ExtendedProperties) === null || _b === void 0 ? void 0 : _b.CosmosUserDetails);
        }
        else {
            setUserItem(props.Item);
        }
    }, [props.Item]);
    return userItem !== null && userItem[props.Col.ProperyName] !== null ? (React.createElement("div", { className: "days-inactive" + (userItem[props.Col.ProperyName] !== undefined && userItem[props.Col.ProperyName] !== null ? getDaysInactiveClass(userItem[props.Col.ProperyName]) : "") }, userItem[props.Col.ProperyName] !== undefined && userItem[props.Col.ProperyName] !== null ? (_a = userItem[props.Col.ProperyName]) === null || _a === void 0 ? void 0 : _a.toLocaleString() : "NA")) : null;
});
export default ReportColumnGuestDaysInactive;
