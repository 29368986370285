import Async, { AsyncWait } from "app/components/common/async";
import FormValidationContext from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import * as AsyncTypes from "app/store/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { TenantType } from "app/store/tenants";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as TenantListStore from "app/store/tenants/list";
import getParameterByName from "app/utils/getQueryStringParam";
import { PartnerToolsTableLicenseColumn } from "appv2/pages/partner-tools/partner-tools-table-columns.component";
import { TenantAnalytics } from "appv2/pages/partner-tools/tenant/tenant-analytics/tenant-analytics.component";
import * as React from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import "./orchestry-tenant-details.scss";
import OrchestryTenantsDetailsConfiguration from "./tabs/configuration/configuration.component";
import OrchestryTenantsDetailsFeatureFlags from "./tabs/feature-flags/feature-flags.component";
import OrchestryTenantsDetailsPartner from "./tabs/partner/partner.component";
import OrchestryTenantsDetailsStatus from "./tabs/status/status.component";
var OrchestryTenantsDetails = React.memo(function () {
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var urlParams = new URLSearchParams(window.location.href);
    var lookupId = getParameterByName("Id");
    var lookupGuid = getParameterByName("GUID");
    var lookupEmail = getParameterByName("OwnerEmail");
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(false), formValid = _b[0], setFormValid = _b[1];
    var tenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    console.log("Tenant - Details", tenant);
    // Should only be run once.
    React.useEffect(function () {
        dispatch(TenantCurrentStore.Clear());
        if (lookupGuid !== "" && lookupGuid !== null) {
            //@ts-ignore
            dispatch(TenantCurrentStore.LoadWithInfo(lookupGuid));
        }
        // } else {
        //     // Load the /edit/guid load.
        //     dispatch(TenantCurrentStore.Load({GUID: lookupGuid}));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupGuid]);
    var saveAndAddModifyTenant = function () {
        if (formValid) {
            //console.log("Tenant - Details" , tenant);
            var asyncCallType = AsyncCallType.Update;
            dispatch(TenantListStore.UpdateItem(tenant));
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, tenant.GUID).then(function () {
                setBackToList(true);
                dispatch(TenantCurrentStore.Clear());
            });
        }
    };
    return (React.createElement("div", { className: "tenant-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
                console.log("is form valid", isValid);
            }, UniqueIdentifier: "tenantValidation" },
            backToList && React.createElement(Redirect, { to: "/orchestry-admin/tenants" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Tenants", subTitle: (tenant === null || tenant === void 0 ? void 0 : tenant.DisplayName) != "" ? tenant.DisplayName : "Details" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/orchestry-admin/tenants" },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement(React.Fragment, null,
                    React.createElement(Async, { uid: lookupGuid, status: AsyncTypes.AsyncStatus.Started },
                        React.createElement("div", { style: { display: "inline-block" } },
                            React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))),
                    React.createElement(Async, { uid: lookupGuid, status: AsyncTypes.AsyncStatus.Succeeded },
                        React.createElement("div", { onClick: function () { return saveAndAddModifyTenant(); }, style: { display: "inline-block" } },
                            React.createElement(SubtitleButton, { title: "Save", variant: "success" }))))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            tenant !== undefined && tenant.GUID !== undefined ? (React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Tab.Container, { id: "tenant-tabs", defaultActiveKey: "status" },
                        React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                            React.createElement(Nav, { as: "ul" },
                                React.createElement(Nav.Item, { as: "li" },
                                    React.createElement(Nav.Link, { eventKey: "status" },
                                        React.createElement("span", null, "Status"))),
                                React.createElement(Nav.Item, { as: "li" },
                                    React.createElement(Nav.Link, { eventKey: "cofiguration" },
                                        React.createElement("span", null, "Configuration"))),
                                React.createElement(Nav.Item, { as: "li" },
                                    React.createElement(Nav.Link, { eventKey: "feature-flags" },
                                        React.createElement("span", null, "Feature Flags"))),
                                React.createElement(Nav.Item, { as: "li" },
                                    React.createElement(Nav.Link, { eventKey: "analytics" },
                                        React.createElement("span", null, "Analytics"))),
                                (tenant.TenantType === TenantType.PartnerHub || tenant.TenantType === TenantType.PartnerCustomer) && (React.createElement(Nav.Item, { as: "li" },
                                    React.createElement(Nav.Link, { eventKey: "partner" },
                                        React.createElement("span", null, "Partner"))))),
                            React.createElement("div", { className: "tenant-status" }, PartnerToolsTableLicenseColumn(tenant === null || tenant === void 0 ? void 0 : tenant.LicenseStatus))),
                        React.createElement(Tab.Content, null,
                            React.createElement(Tab.Pane, { eventKey: "status" },
                                React.createElement(OrchestryTenantsDetailsStatus, null)),
                            React.createElement(Tab.Pane, { eventKey: "cofiguration" },
                                React.createElement(OrchestryTenantsDetailsConfiguration, null)),
                            React.createElement(Tab.Pane, { eventKey: "feature-flags" },
                                React.createElement(OrchestryTenantsDetailsFeatureFlags, null)),
                            React.createElement(Tab.Pane, { eventKey: "analytics" },
                                React.createElement(TenantAnalytics, null)),
                            React.createElement(Tab.Pane, { eventKey: "partner" },
                                React.createElement(OrchestryTenantsDetailsPartner, null))))))) : (React.createElement(Portlet, null,
                React.createElement(PortletBody, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginBottom: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginBottom: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginBottom: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginBottom: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginBottom: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginBottom: 20 })))))));
});
export default OrchestryTenantsDetails;
