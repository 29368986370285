import * as AsyncStore from "app/store/async";
import * as TenantListStore from "app/store/tenants/list";
export var tenantNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to tenants list...",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Add
            },
            {
                Text: "Updating tenant...",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Update
            },
            {
                Text: "Archiving tenant...",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to tenants list.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Add
            },
            {
                Text: "Successfully updated tenant.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Update
            },
            {
                Text: "Successfully archived tenant.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to tenants list.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Add
            },
            {
                Text: "Error when updating tenant.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Update
            },
            {
                Text: "Error when archiving tenant.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Item_Delete
            },
            {
                Text: "Error when trying to load tenant list.",
                ActionType: TenantListStore.TenantListActionTypes.Tenant_List_Load
            }
        ]
    }
];
