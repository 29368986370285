import React from "react";
import { useSelector } from "react-redux";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { FormattedMessage } from "react-intl";
import "./team-information-pill.scss";
import { useGuestAccess } from "app/utils/hooks/useGuestAccess";
export var TeamInformationGuestAccessPill = function () {
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var isGuestAccessEnabled = useGuestAccess();
    if (teamInformation === null) {
        return React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 0, bodyWidth: 60, style: { marginRight: "10px" } });
    }
    if (teamInformation.GuestAccess === null) {
        return null;
    }
    return (React.createElement("div", { className: "guest-access team-information-pill" },
        React.createElement("div", { className: "nested-property" }, isGuestAccessEnabled === true ? (React.createElement(React.Fragment, null,
            React.createElement("i", { className: "fa-regular fa-users" }),
            React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.property.guest_enabled", defaultMessage: "Guests Enabled" })))) : (React.createElement(React.Fragment, null,
            React.createElement("i", { className: "fa-regular fa-" + "user-lock" }),
            React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.property.guest_disabled", defaultMessage: "Guests Disabled" })))))));
};
