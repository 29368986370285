var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Portlet, PortletBody } from "app/components/common/portlet";
import { useGetAdminAnalyticsQuery } from "appv2/api/recommendations/recommendations.api";
import axios from "axios";
import * as React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import "./orchestry-admin-recommendations-analytics.scss";
var OrchestryRecommendationAnalytics = React.memo(function () {
    var _a = useGetAdminAnalyticsQuery(), data = _a.data, error = _a.error, isLoading = _a.isLoading;
    function getFormattedTimestamp() {
        var now = new Date();
        var year = now.getFullYear();
        var month = String(now.getMonth() + 1).padStart(2, "0");
        var day = String(now.getDate()).padStart(2, "0");
        var hours = String(now.getHours()).padStart(2, "0");
        var minutes = String(now.getMinutes()).padStart(2, "0");
        var seconds = String(now.getSeconds()).padStart(2, "0");
        return "".concat(year, "-").concat(month, "-").concat(day, "_").concat(hours, "-").concat(minutes, "-").concat(seconds);
    }
    var handleExportClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                axios({
                    url: "/api/recommendations/ExportAdminAnalytics",
                    method: "POST",
                    responseType: "blob"
                }).then(function (response) {
                    var url = window.URL.createObjectURL(new Blob([response.data], {
                        type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    var link = document.createElement("a");
                    var timestamp = getFormattedTimestamp();
                    link.href = url;
                    link.setAttribute("download", "Orchestry Report - Admin Recommendation Analytics_".concat(timestamp, ".xlsx"));
                    document.body.appendChild(link);
                    link.click();
                });
            }
            catch (error) {
                console.error("There was an error fetching results from GetAdminAnalytics", error);
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("div", { className: "recommendations-analytics fadein-500ms" },
        React.createElement(Portlet, { className: "portlet-filters" },
            React.createElement(PortletBody, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 12 },
                        React.createElement(Button, { onClick: handleExportClick }, "Export"),
                        isLoading ? React.createElement("h2", null, "Loading...") : "",
                        error ? React.createElement("h2", null, "Error occurred: error.toString()") : "",
                        React.createElement(Table, { striped: true, bordered: true, hover: true },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "Recommendation GUID"),
                                    React.createElement("th", null, "Display Name"),
                                    React.createElement("th", null, "Total Created"),
                                    React.createElement("th", null, "Total Open"),
                                    React.createElement("th", null, "Total Resolved"),
                                    React.createElement("th", null, "Total Snoozed"),
                                    React.createElement("th", null, "Total Snoozed Days"),
                                    React.createElement("th", null, "Avg Time to Resolution"),
                                    React.createElement("th", null, "Aggregated Useful Votes"),
                                    React.createElement("th", null, "Aggregated Useful Score"),
                                    React.createElement("th", null, "Weighted Useful Score"))),
                            React.createElement("tbody", null, data === null || data === void 0 ? void 0 : data.map(function (item) { return (React.createElement("tr", { key: item.Id },
                                React.createElement("td", null, item.RecommendationGUID),
                                React.createElement("td", null, item.DisplayName),
                                React.createElement("td", null, item.TotalCreated),
                                React.createElement("td", null, item.TotalOpen),
                                React.createElement("td", null, item.TotalResolved),
                                React.createElement("td", null, item.TotalSnoozed),
                                React.createElement("td", null, item.TotalSnoozedDays),
                                React.createElement("td", null, item.AvgTimeToResolution.toFixed(2)),
                                React.createElement("td", null, item.AggregatedUsefulVotes),
                                React.createElement("td", null, item.AggregatedUsefulScore),
                                React.createElement("td", null, item.WeightedUsefulScore.toFixed(1)))); })))))))));
});
export default OrchestryRecommendationAnalytics;
