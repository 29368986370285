import * as DocumentTemplateListStore from "app/store/document-templates/list";
import { AsyncCallType } from "app/store/async/types";
import * as TemplateListTypes from "app/store/templates/list";
import * as TemplateCurrentTypes from "app/store/templates/current";
export var documentTemplateListActionMap = [
    {
        actionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/documentTemplates",
        responseDataType: "",
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/documentTemplates",
        responseDataType: "",
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/documentTemplates",
        responseDataType: true,
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load, TemplateCurrentTypes.TemplateCurrentActionTypes.Template_Current_Load]
    },
    {
        actionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "documentTemplateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/documentTemplates",
        responseDataType: []
    },
    {
        actionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Refresh,
        callType: AsyncCallType.Refresh,
        stateLocationForArray: "documentTemplateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/documentTemplates",
        responseDataType: []
    }
];
