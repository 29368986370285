import moment from "moment";
import { HealthCheckStartDate } from "./health-check-start-date.type";
/**
 * Maps the HealthCheckStartDate to an ISO string.
 * Subtracting 1 from the days because we only have data up to the previous day.
 * @param startDate The start date to map.
 */
export var mapHealthCheckStartDateToIsoString = function (startDate) {
    switch (startDate) {
        case HealthCheckStartDate["30DaysAgo"]:
            return moment()
                .subtract(31, "days")
                .startOf("day")
                .utcOffset(0, true)
                .toISOString();
        case HealthCheckStartDate["60DaysAgo"]:
            return moment()
                .subtract(61, "days")
                .startOf("day")
                .utcOffset(0, true)
                .toISOString();
        case HealthCheckStartDate["90DaysAgo"]:
            return moment()
                .subtract(91, "days")
                .startOf("day")
                .utcOffset(0, true)
                .toISOString();
        case HealthCheckStartDate["1YearAgo"]:
            return moment()
                .subtract(1, "years")
                .subtract(1, "days")
                .startOf("day")
                .utcOffset(0, true)
                .toISOString();
        default:
            return "";
    }
};
