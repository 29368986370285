import { NamingPolicyCurrentActionTypes } from "app/store/naming-policies/current/types";
import { AsyncCallType } from "app/store/async/types";
export var namingPolicyCurrentActionMap = [
    {
        actionType: NamingPolicyCurrentActionTypes.NamingPolicy_Current_Load,
        callType: AsyncCallType.Get,
        //stateLocationForArray: "namingPolicyListState.items",
        keyType: "guid",
        ApiUrl: "/api/NamingPolicies/?guid={key}",
        responseDataType: {}
    },
    {
        actionType: NamingPolicyCurrentActionTypes.NamingPolicy_Current_LoadFromServer,
        callType: AsyncCallType.Get,
        //stateLocationForArray: "namingPolicyListState.items",
        keyType: "guid",
        ApiUrl: "/api/NamingPolicies/?guid={key}",
        responseDataType: {}
    }
];
