var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
var getBaseUrl = function (userType) {
    switch (userType) {
        case "partner":
            return "/Partners";
        case "customer":
            return "/HealthChecks";
        default:
            return "/HealthChecks";
    }
};
export var healthChecksApi = createApi({
    reducerPath: "healthChecksApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
    tagTypes: ["HealthChecks", "HealthCheck", "HealthCheckSharedWith", "HealthCheckAnnotations"],
    endpoints: function (builder) { return ({
        getHealthChecks: builder.query({
            query: function (_a) {
                var userType = _a[0], tenantGuid = _a[1], request = _a[2];
                return { url: "".concat(getBaseUrl(userType), "/GetHealthChecks?tenantGuid=").concat(tenantGuid), params: __assign(__assign({}, request.queryParameters), { SearchText: request.searchText }) };
            },
            providesTags: ["HealthChecks"]
        }),
        getHealthCheck: builder.query({
            query: function (_a) {
                var userType = _a[0], healthCheckGuid = _a[1], tenantGuid = _a[2];
                return ({
                    url: "".concat(getBaseUrl(userType), "/GetHealthCheck?healthCheckGuid=").concat(healthCheckGuid, "&tenantGuid=").concat(tenantGuid)
                });
            },
            providesTags: ["HealthCheck"]
        }),
        generateHealthCheck: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, healthCheck = _a.healthCheck;
                return ({
                    url: "".concat(getBaseUrl(userType), "/AddHealthChecks"),
                    method: "Post",
                    body: healthCheck
                });
            },
            invalidatesTags: ["HealthCheck"]
        }),
        recompileHealthCheckData: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, healthCheckGuid = _a.healthCheckGuid, tenantGuid = _a.tenantGuid;
                return ({
                    url: "".concat(getBaseUrl(userType), "/RecompileHealthCheckData?healthCheckGuid=").concat(healthCheckGuid, "&tenantGuid=").concat(tenantGuid),
                    method: "Get"
                });
            },
            invalidatesTags: ["HealthCheck"]
        }),
        publishHealthCheck: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, healthCheckGuid = _a.healthCheckGuid, tenantGuid = _a.tenantGuid;
                return ({
                    url: "".concat(getBaseUrl(userType), "/PublishHealthCheck?healthCheckGuid=").concat(healthCheckGuid, "&tenantGuid=").concat(tenantGuid),
                    method: "Get"
                });
            },
            invalidatesTags: ["HealthCheck"]
        }),
        deleteHealthChecks: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, deleteHealthChecksCommand = _a.deleteHealthChecksCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/DeleteHealthCheck"),
                    method: "Delete",
                    body: deleteHealthChecksCommand
                });
            },
            invalidatesTags: ["HealthChecks"]
        }),
        getHealthCheckSharedWith: builder.query({
            query: function (_a) {
                var userType = _a[0], healthCheckGuid = _a[1], tenantGuid = _a[2];
                return ({
                    url: "".concat(getBaseUrl(userType), "/GetHealthCheckSharedWith?healthCheckGuid=").concat(healthCheckGuid, "&tenantGuid=").concat(tenantGuid)
                });
            },
            providesTags: ["HealthCheckSharedWith"]
        }),
        updateHealthCheckSharedWith: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, updateHealthCheckSharedWithCommand = _a.updateHealthCheckSharedWithCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/UpdateHealthCheckSharedWith"),
                    method: "Put",
                    body: updateHealthCheckSharedWithCommand
                });
            },
            invalidatesTags: ["HealthCheckSharedWith"]
        }),
        addAnnotation: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, insertAnnotationCommand = _a.insertAnnotationCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/AddAnnotation"),
                    method: "Post",
                    body: insertAnnotationCommand
                });
            },
            invalidatesTags: function (result, error, _a) {
                var insertAnnotationCommand = _a.insertAnnotationCommand;
                return [{ type: "HealthCheckAnnotations", id: insertAnnotationCommand.Category.toString() }];
            }
        }),
        deleteAnnotation: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, deleteAnnotationCommand = _a.deleteAnnotationCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/DeleteAnnotation"),
                    method: "Delete",
                    body: deleteAnnotationCommand
                });
            },
            onQueryStarted: function (_a, _b) {
                var userType = _a.userType, deleteAnnotationCommand = _a.deleteAnnotationCommand, category = _a.category, associatedGuid = _a.associatedGuid, healthCheckGuid = _a.healthCheckGuid;
                var dispatch = _b.dispatch, getState = _b.getState;
                dispatch(healthChecksApi.util.updateQueryData("getAnnotations", [userType, healthCheckGuid, category, deleteAnnotationCommand.TenantGUID, associatedGuid], function (draft) {
                    var index = draft.findIndex(function (annotation) { return annotation.UniqueId === deleteAnnotationCommand.UniqueId; });
                    if (index !== -1) {
                        draft.splice(index, 1);
                    }
                }));
            }
        }),
        getAnnotations: builder.query({
            query: function (_a) {
                var userType = _a[0], healthCheckGuid = _a[1], annotationCategory = _a[2], tenantGuid = _a[3], associatedGuid = _a[4];
                return ({
                    url: "".concat(getBaseUrl(userType), "/GetAnnotations?tenantGuid=").concat(tenantGuid, "&healthcheckGuid=").concat(healthCheckGuid, "&annotationCategory=").concat(annotationCategory, "&associatedGuid=").concat(associatedGuid)
                });
            },
            providesTags: function (result, error, _a) {
                var userType = _a[0], healthCheckGuid = _a[1], annotationCategory = _a[2];
                return [{ type: "HealthCheckAnnotations", id: annotationCategory.toString() }];
            }
        })
    }); }
});
export var useGetHealthChecksQuery = healthChecksApi.useGetHealthChecksQuery, useDeleteHealthChecksMutation = healthChecksApi.useDeleteHealthChecksMutation, useGetHealthCheckQuery = healthChecksApi.useGetHealthCheckQuery, useGenerateHealthCheckMutation = healthChecksApi.useGenerateHealthCheckMutation, useGetHealthCheckSharedWithQuery = healthChecksApi.useGetHealthCheckSharedWithQuery, useUpdateHealthCheckSharedWithMutation = healthChecksApi.useUpdateHealthCheckSharedWithMutation, useAddAnnotationMutation = healthChecksApi.useAddAnnotationMutation, useDeleteAnnotationMutation = healthChecksApi.useDeleteAnnotationMutation, useRecompileHealthCheckDataMutation = healthChecksApi.useRecompileHealthCheckDataMutation, usePublishHealthCheckMutation = healthChecksApi.usePublishHealthCheckMutation, useGetAnnotationsQuery = healthChecksApi.useGetAnnotationsQuery;
