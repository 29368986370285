var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import "./apply-feature-file.scss";
import { Form, Modal } from "react-bootstrap";
import FeatureFilesService from "app/services/feature-files";
import ProgressReport from "app/components/common/progress-report";
import FormValidationContext, { FormValidator, FormValidatorFocus } from "app/components/common/form-validation";
import FormOfficeAssetPicker from "app/components/common/form-office-asset-picker";
var ApplyFeatureFile = React.memo(function (props) {
    var _a = React.useState(false), applyingTemplate = _a[0], setApplyingTemplate = _a[1];
    var _b = React.useState(), jobId = _b[0], setJobId = _b[1];
    var _c = React.useState(false), showModal = _c[0], setShowModal = _c[1];
    var _d = React.useState(false), applyFormValid = _d[0], setApplyFormValid = _d[1];
    var _e = React.useState([]), applyWebUrls = _e[0], setApplyWebUrls = _e[1];
    var _f = React.useState([]), webUrlDatas = _f[0], setWebUrlDatas = _f[1];
    var _g = React.useState(false), jobDone = _g[0], setJobDone = _g[1];
    React.useEffect(function () {
        if (webUrlDatas.length === 0) {
            setApplyWebUrls([]);
        }
        else {
            var tempUrlArray_1 = [];
            webUrlDatas.forEach(function (outputObject) {
                console.log("site object", outputObject);
                var outputValue = JSON.parse(outputObject);
                console.log("site url", outputValue);
                tempUrlArray_1.push(outputValue.URL);
            });
            setApplyWebUrls(tempUrlArray_1);
        }
    }, [webUrlDatas]);
    var applyTemplate = function () {
        setApplyingTemplate(true);
        FeatureFilesService.ApplyTemplates(applyWebUrls, props.fileName).then(function (newJobId) {
            console.log("jobId", newJobId);
            setJobId(newJobId);
        });
    };
    var jobFinished = function () {
        //setShowAddModal(false);
        setApplyingTemplate(false);
        setJobId(undefined);
        setJobDone(false);
    };
    var cleanUp = function () {
        setWebUrlDatas([]);
        setApplyWebUrls([]);
    };
    var canModalClose = function () {
        if (applyingTemplate === false) {
            setShowModal(!showModal);
        }
    };
    var canModalCloseNow = function () {
        if (applyingTemplate === false && jobId === undefined) {
            return true;
        }
        return false;
    };
    var modifyWebUrlDatas = function (newSelection, currentItem) {
        if (newSelection !== "") {
            setWebUrlDatas(__spreadArray(__spreadArray([], webUrlDatas.filter(function (wdu) { return wdu !== currentItem; }), true), [newSelection], false));
        }
        else {
            setWebUrlDatas(__spreadArray([], webUrlDatas.filter(function (wdu) { return wdu !== currentItem; }), true));
        }
    };
    return (React.createElement("div", { className: "apply-feature-file" },
        React.createElement("div", { className: "apply-feature-button", onClick: function () { return setShowModal(true); } }, props.children),
        React.createElement(Modal, { centered: true, show: showModal, size: "lg", onHide: function () { return canModalClose(); }, animation: true, className: "apply-feature-file-modal", backdropClassName: "apply-feature-file-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: canModalCloseNow() },
                React.createElement(Modal.Title, null, "Apply template to web(s)")),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "feature-extraction-wrapper" }, applyingTemplate === true ? (React.createElement(ProgressReport, { jobId: jobId, showOnlySubBar: true, onComplete: function () { return setJobDone(true); }, turnOffAfter: false, showBeforeJobId: true, showConsoleByDefault: true })) : (React.createElement(FormValidationContext, { UniqueIdentifier: "apply-pnp-form", OnChangeIsValid: function (isValid) { return setApplyFormValid(isValid); } },
                    React.createElement("p", null,
                        React.createElement("strong", null, props.fileName),
                        " ",
                        React.createElement("span", { style: { fontWeight: 500 } }, "will be applied to the following sites: ")),
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(FormValidatorFocus, { UniqueIdentifier: "url" },
                            webUrlDatas.map(function (wudItem) { return (React.createElement(FormOfficeAssetPicker, { pickerType: "AllSharePoint", value: wudItem, label: "Add SharePoint site", showSelectedText: false, 
                                // skipIntialListAll={true}
                                onChange: function (serializedItem) { return modifyWebUrlDatas(serializedItem, wudItem); } })); }),
                            React.createElement(FormOfficeAssetPicker, { pickerType: "AllSharePoint", value: null, browseText: "Add SharePoint site", showSelectedText: false, 
                                // skipIntialListAll={true}
                                onChange: function (serializedItem) { return setWebUrlDatas(__spreadArray(__spreadArray([], webUrlDatas.filter(function (wdu) { return wdu !== serializedItem; }), true), [serializedItem], false)); } })),
                        React.createElement(FormValidator, { UniqueIdentifier: "urls", FieldName: "Sites", ShowOnFocus: true, Validators: ["array-has-items"], Value: applyWebUrls })))))),
            React.createElement(Modal.Footer, null,
                React.createElement("div", { className: "action-buttons" }, jobDone === true ? (React.createElement(React.Fragment, null,
                    React.createElement("button", { className: "submit-button btn btn-success", style: { marginRight: "15px" }, onClick: function () { return jobFinished(); } }, "Start Over"),
                    React.createElement("button", { className: "submit-button btn btn-success", onClick: function () {
                            jobFinished();
                            cleanUp();
                            setShowModal(false);
                        } }, "Close"))) : applyFormValid === false || applyingTemplate === true ? (React.createElement("button", { className: "submit-button btn btn-success", disabled: true }, "Apply Template File(s)")) : (React.createElement("button", { className: "submit-button btn btn-success", onClick: function () { return applyTemplate(); } }, "Apply Template File(s)")))))));
});
export default ApplyFeatureFile;
