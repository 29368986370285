var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ProfileImageCurrentActionTypes } from "./types";
var initialState = {
    items: []
};
window.ProfileImageListReducerUpdating = false;
export function profileImageListReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var getState = function () {
        switch (action.type) {
            case ProfileImageCurrentActionTypes.ProfileImages_Current_Load: {
                return {
                    items: __spreadArray(__spreadArray([], state.items, true), action.payload, true)
                };
            }
            case ProfileImageCurrentActionTypes.ProfileImages_AddUpdate: {
                var currentItems = __spreadArray([], state.items, true);
                if (currentItems.length > 300) {
                    currentItems.splice(0, 1);
                }
                if (currentItems.some(function (ci) { return ci.id === action.payload.id; }) === false) {
                    currentItems.push(action.payload);
                }
                else {
                    currentItems = currentItems.map(function (ci) { return (ci.id === action.payload.id ? action.payload : ci); });
                }
                // console.log("Profile-Pics", currentItems, action);
                return {
                    items: __spreadArray([], currentItems, true)
                };
            }
            default:
                return state;
        }
    };
    var wait = function () {
        if (window.ProfileImageListReducerUpdating === false) {
            //console.log("wait done " + action.type)
            window.ProfileImageListReducerUpdating = true;
            var newstate = getState();
            window.ProfileImageListReducerUpdating = false;
            return newstate;
        }
        else {
            //console.log("waiting for " + action.type)
            setTimeout(function () {
                return wait();
            }, 50);
        }
    };
    return wait();
}
