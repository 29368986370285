import DisableArea from "app/components/common/disable-area";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormImageUploadBox from "app/components/common/form-imageuploadbox";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import FormToggleControl from "app/components/common/form-toggle-control";
import { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import InfoTooltip from "app/components/common/infotooltip";
import NotificationContent from "app/components/common/notification-content";
import SettingsTemplateCategories from "app/components/settings/settings-template-categories";
import { TeamSpecializations } from "app/store/team-specializations/types";
import * as TemplateCurrentStore from "app/store/templates/current";
import { WorkspaceTypes } from "app/store/workspaces/types";
import clsx from "clsx";
import debounce from "lodash/debounce";
import * as React from "react";
import { Col, Collapse, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./tab-details.scss";
var TabDetails = React.memo(function (props, state) {
    var dispatch = useDispatch();
    var debouncedDispatch = React.useCallback(debounce(dispatch, 200), []);
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState(), categories = _a[0], setCategories = _a[1];
    var _b = React.useState(false), showCategoryModal = _b[0], setShowCategoryModal = _b[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var isModernCommunications = props.currentTemplate.TemplateType === "SharePoint" && props.currentTemplate.SharePointTemplateType === "ModernCommunications";
    var teamSpecializations = React.useState(TeamSpecializations.map(function (wt, index) { return ({
        id: index + "",
        title: wt.Title,
        description: wt.Description,
        value: wt.Value
    }); }))[0];
    var toggleCatModal = function () {
        setShowCategoryModal(!showCategoryModal);
    };
    function determineTemplateLockState(wtValue) {
        var lockState = false;
        var lockReason = undefined;
        if (wtValue === "VivaEngageCommunity" && orchestryContext.LastConsentedVersion && orchestryContext.LastConsentedVersion < 10056) {
            lockState = true;
            lockReason = "You must update your Orchestry Installation to enable this feature";
            return [lockState, lockReason];
        }
        if (wtValue === "VivaEngageCommunity" && !orchestryContext.VivaEngageEnabled) {
            lockState = true;
            lockReason = "Viva Engage provisioning is not enabled for this tenant. Please update configuration in installation screen.";
            return [lockState, lockReason];
        }
        if (props.lookupGuid && wtValue === "VivaEngageCommunity" && currentTemplate.TemplateType !== "VivaEngageCommunity") {
            lockState = true;
            lockReason = "Cannot convert template to Viva Engage Community";
            return [lockState, lockReason];
        }
        else if (props.lookupGuid && wtValue !== "VivaEngageCommunity" && currentTemplate.TemplateType === "VivaEngageCommunity") {
            lockState = true;
            lockReason = "Cannot convert template from Viva Engage Community";
            return [lockState, lockReason];
        }
        return [lockState, lockReason];
    }
    React.useEffect(function () {
        if (currentTenantConfiguration.GUID !== undefined) {
            if (currentTenantConfiguration.GUID !== "") {
                if (currentTenantConfiguration.TemplateCategories !== undefined && currentTenantConfiguration.TemplateCategories !== null && currentTenantConfiguration.TemplateCategories !== "") {
                    var availableTemplateCats = JSON.parse(currentTenantConfiguration.TemplateCategories);
                    if (availableTemplateCats !== undefined && availableTemplateCats != null) {
                        if (availableTemplateCats.length !== 0) {
                            var newOptionArray_1 = [];
                            availableTemplateCats.forEach(function (docTemplateCat, index) {
                                newOptionArray_1.push({
                                    id: index + "",
                                    title: docTemplateCat,
                                    value: docTemplateCat
                                });
                            });
                            setCategories(newOptionArray_1);
                        }
                        else {
                            setCategories([]);
                        }
                    }
                }
            }
        }
    }, [currentTenantConfiguration]);
    // Setup the selection options for the dropdowns
    var workspaceType = React.useMemo(function () {
        return WorkspaceTypes.map(function (wt, index) {
            var _a = determineTemplateLockState(wt.Value), lockState = _a[0], lockReason = _a[1];
            return {
                id: index + "",
                title: wt.Title,
                description: lockReason ? lockReason : wt.Description,
                value: wt.Value,
                image: wt.Image,
                disabled: lockState
            };
        });
    }, [JSON.stringify(orchestryContext)]);
    React.useEffect(function () {
        if (props.currentTemplate.SharePointTemplateType !== undefined) {
            try {
                //console.log("CHANGE SHAREPOINT TEMPLATE TYPE")
                if (props.currentTemplate.LiveTemplateSiteUrl !== undefined && props.currentTemplate.LiveTemplateSiteUrl !== null) {
                    var site = JSON.parse(props.currentTemplate.LiveTemplateSiteUrl);
                    //console.log("CHANGE SHAREPOINT TEMPLATE TYPE site", site)
                    var SPTemplateType = "";
                    if (props.currentTemplate.SharePointTemplateType === "ModernGroup") {
                        SPTemplateType = "GROUP#0";
                    }
                    if (props.currentTemplate.SharePointTemplateType === "ModernCommunications") {
                        SPTemplateType = "SITEPAGEPUBLISHING#0";
                    }
                    //console.log("CHANGE SHAREPOINT TEMPLATE TYPE SPTemplateType", SPTemplateType)
                    //console.log("CHANGE SHAREPOINT TEMPLATE TYPE SPTemplateType site.Template, SPTemplateType", site.Template, SPTemplateType)
                    if (site.Template !== SPTemplateType) {
                        dispatch(TemplateCurrentStore.Update({ LiveTemplateSiteUrl: null }));
                        toast(React.createElement(NotificationContent, { text: "Removed selected Live SharePoint site in Configuration Tab because you've changed the SharePoint Template Type", mode: "success" }), {
                            toastId: "change-sp-alert",
                            autoClose: false,
                            pauseOnHover: true,
                            type: toast.TYPE.DEFAULT
                        });
                    }
                }
            }
            catch (_a) { }
        }
    }, [props.currentTemplate.SharePointTemplateType, props.currentTemplate.LiveTemplateSiteUrl]);
    var handleSharePointTemplateTypeChange = function (newTemplateValue) {
        switch (newTemplateValue) {
            case "ModernGroup":
                dispatch(TemplateCurrentStore.Update({ SharePointTemplateType: newTemplateValue }));
                break;
            case "ModernCommunications":
                dispatch(TemplateCurrentStore.Update({ SharePointTemplateType: newTemplateValue, GuestRequestPolicyGuid: null, GuestReviewPolicyGuid: null }));
                break;
            default:
                break;
        }
    };
    return (React.createElement(Row, { className: "details-tab", "data-pw": "details-tab" },
        React.createElement(Col, { md: 6, lg: 5, xl: 5, "data-pw": "template-details" },
            React.createElement("h2", null, "Template Details"),
            React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Set the configuration for this template and how it will behave in the Workspace Request Wizard"),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name", "data-pw": "template-name" },
                React.createElement(Form.Label, null,
                    "Name ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "name" },
                    React.createElement(Form.Control, { placeholder: "Template Name", "data-pw": "name-input", autoComplete: "off", defaultValue: props.currentTemplate.Title, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ Title: event.target.value })); } })),
                React.createElement(FormValidator, { UniqueIdentifier: "name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-2"], Value: props.currentTemplate.Title })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-description", "data-pw": "template-description" },
                React.createElement(Form.Label, null,
                    "Description",
                    React.createElement("span", { className: "required-astrix" }, "*"),
                    " \u00A0\u00A0",
                    React.createElement(InfoTooltip, null, "When creating a new request, only the first 4 lines of the description will be visible."),
                    React.createElement("br", null)),
                React.createElement(MarkdownEditor, { templateText: props.currentTemplate.Description, onChange: function (body) { return debouncedDispatch(TemplateCurrentStore.Update({ Description: body })); }, enabledStyles: { all: true }, layout: "text" }),
                React.createElement(FormValidator, { UniqueIdentifier: "description", FieldName: "Description", Validators: ["markdown-editor-min-characters-3"], Value: props.currentTemplate.Description })),
            React.createElement(Form.Group, { className: "form-group" },
                React.createElement(DisableArea, { isDisabled: props.currentTemplate.TemplateType === "VivaEngageCommunity" },
                    React.createElement(FormToggleControl, { checked: props.currentTemplate.TemplateCardHideAdditionalFeatures, label: "Hide additional feature icons along the bottom of template selection card", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ TemplateCardHideAdditionalFeatures: checked })); }, disabled: props.currentTemplate.TemplateType === "VivaEngageCommunity" })),
                React.createElement(Form.Group, { className: "form-group more-info-container", controlId: "more-info-button" },
                    React.createElement(FormToggleControl, { label: "Show More Information Button", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ TemplateCardMoreInfoButton: checked })); }, checked: props.currentTemplate.TemplateCardMoreInfoButton }),
                    React.createElement(Collapse, { in: props.currentTemplate.TemplateCardMoreInfoButton },
                        React.createElement("div", { className: "more-info-fields" }, props.currentTemplate.TemplateCardMoreInfoButton ? (React.createElement(React.Fragment, null,
                            React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "button-text" },
                                React.createElement(Form.Label, null,
                                    "Button Text ",
                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                React.createElement(Form.Control, { placeholder: "Enter Text Here ...", autoComplete: "off", defaultValue: props.currentTemplate.TemplateMoreInfoButtonText, onChange: function (event) { return debouncedDispatch(TemplateCurrentStore.Update({ TemplateMoreInfoButtonText: event.target.value })); } })),
                            React.createElement(FormValidator, { UniqueIdentifier: "button-text", FieldName: "Button Text", Validators: ["string-not-empty"], Value: props.currentTemplate.TemplateMoreInfoButtonText }),
                            React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "button-link" },
                                React.createElement(Form.Label, { id: "link-label" },
                                    "Link ",
                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                React.createElement(Form.Control, { onBlur: function (e) {
                                        debouncedDispatch(TemplateCurrentStore.Update({ TemplateMoreInfoButtonLink: encodeURI(e.target.value) }));
                                    }, placeholder: "Enter Link Here ...", autoComplete: "off", defaultValue: props.currentTemplate.TemplateMoreInfoButtonLink, onChange: function (event) {
                                        return debouncedDispatch(TemplateCurrentStore.Update({ TemplateMoreInfoButtonLink: encodeURI(event.target.value) }));
                                    } })),
                            React.createElement(FormValidator, { UniqueIdentifier: "button-link", FieldName: "Link", Validators: ["string-not-empty", "is-valid-url"], Value: props.currentTemplate.TemplateMoreInfoButtonLink }),
                            " ")) : null)))),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-type", "data-pw": "template-type" },
                React.createElement(Form.Label, null,
                    "Template Type ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(FormDropdownSelect, { list: workspaceType, defaultValue: props.currentTemplate.TemplateType, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ TemplateType: value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "template-type", FieldName: "Template Type", Validators: ["dropdown-has-value"], Value: props.currentTemplate.TemplateType }),
                props.currentTemplate.TemplateType === "Team" && (React.createElement(DisableArea, { isDisabled: currentTemplate.TemplateMultiGeoDataLocationSource !== "Default" && currentTenantConfiguration.IsMultiGeoEnabled },
                    React.createElement("div", { className: "team-information-tab-toggle", "data-pw": "team-information-tab-toggle" },
                        React.createElement(FormToggleControl, { checked: props.currentTemplate.ProvisionInfromationTab === undefined ? true : props.currentTemplate.ProvisionInfromationTab, label: "Provision Orchestry's team information tab in the General channel?", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ ProvisionInfromationTab: checked })); }, disabled: currentTemplate.TemplateMultiGeoDataLocationSource !== "Default" && currentTenantConfiguration.IsMultiGeoEnabled })),
                    React.createElement("div", { className: "team-information-tab-toggle", "data-pw": "team-information-tab-toggle" },
                        React.createElement(FormToggleControl, { checked: props.currentTemplate.ProvisionInformationWebpart === undefined ? true : props.currentTemplate.ProvisionInformationWebpart, label: "Provision Orchestry's workspace information webpart on the SharePoint home?", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ ProvisionInformationWebpart: checked })); }, disabled: currentTemplate.TemplateMultiGeoDataLocationSource !== "Default" && currentTenantConfiguration.IsMultiGeoEnabled }),
                        currentTemplate.TemplateMultiGeoDataLocationSource !== "Default" && currentTenantConfiguration.IsMultiGeoEnabled && (React.createElement(InfoTooltip, null, "Note: This function is currently not supported for sites provisioned outside the default PDL"))))),
                (props.currentTemplate.TemplateType === "SharePoint" || props.currentTemplate.TemplateType === "VivaEngageCommunity") && (React.createElement(DisableArea, { isDisabled: currentTemplate.TemplateMultiGeoDataLocationSource !== "Default" && currentTenantConfiguration.IsMultiGeoEnabled },
                    React.createElement("div", { className: clsx("team-information-tab-toggle", { "hide-control": isModernCommunications }), "data-pw": "team-information-tab-toggle" },
                        React.createElement(FormToggleControl, { checked: props.currentTemplate.ProvisionInformationWebpart === undefined ? true : props.currentTemplate.ProvisionInformationWebpart, label: "Provision Orchestry's workspace information webpart on the SharePoint home?", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ ProvisionInformationWebpart: checked })); } }),
                        currentTemplate.TemplateMultiGeoDataLocationSource !== "Default" && currentTenantConfiguration.IsMultiGeoEnabled && (React.createElement(InfoTooltip, null, "Note: This function is currently not supported for sites provisioned outside the default PDL")))))),
            orchestryContext.TenantIsEducation && props.currentTemplate.LiveTemplateEnabled === false ? (React.createElement(Collapse, { in: props.currentTemplate.TemplateType === "Team" },
                React.createElement("div", null,
                    React.createElement(Form.Group, { className: "form-group", controlId: "control-sharepoint-type" },
                        React.createElement(Form.Label, null, "Team Specialization"),
                        React.createElement(FormDropdownSelect, { list: teamSpecializations, defaultValue: props.currentTemplate.TeamTemplateSpecialization, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ TeamTemplateSpecialization: value })); } }))))) : null,
            React.createElement(Collapse, { in: props.currentTemplate.TemplateType === "SharePoint", appear: true },
                React.createElement("div", null,
                    React.createElement(Form.Group, { className: "form-group", controlId: "control-sharepoint-type" },
                        React.createElement(Form.Label, null,
                            "SharePoint Template Type ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement(FormDropdownSelect, { list: [
                                {
                                    id: "1",
                                    title: "Modern Group",
                                    value: "ModernGroup"
                                },
                                {
                                    id: "2",
                                    title: "Modern Communication",
                                    value: "ModernCommunications"
                                }
                            ], defaultValue: props.currentTemplate.SharePointTemplateType, onChange: function (value) { return handleSharePointTemplateTypeChange(value); } }),
                        props.currentTemplate.TemplateType === "SharePoint" ? (React.createElement(FormValidator, { UniqueIdentifier: "template-sharepoint-type", FieldName: "SharePoint Type", Validators: ["dropdown-has-value"], Value: props.currentTemplate.SharePointTemplateType })) : null))),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-position", "data-pw": "template-position" },
                React.createElement(Form.Label, null, "Position"),
                React.createElement(Form.Control, { type: "number", name: "points", step: "1", defaultValue: props.currentTemplate.Position, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ Position: Number(event.target.value) })); } })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-type", "data-pw": "template-category" },
                React.createElement(Form.Label, null,
                    "Category",
                    " ",
                    React.createElement("div", { className: "add-document-category", "data-pw": "add-category", onClick: function () {
                            toggleCatModal();
                        } },
                        React.createElement("i", { className: "fa fa-plus-circle" }))),
                categories !== undefined && categories.length !== 0 ? (React.createElement(FormDropdownSelect, { list: categories, defaultValue: props.currentTemplate.Category, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ Category: value })); } })) : (React.createElement(Form.Control, { type: "text", disabled: true, value: "Please add a category using the [+] icon above" })),
                React.createElement(Modal, { centered: true, show: showCategoryModal, onHide: function () { return toggleCatModal(); }, animation: true, className: "document-category-modal" },
                    React.createElement("div", { className: "close", onClick: function () { return toggleCatModal(); } },
                        React.createElement("i", { className: "fa fa-times" })),
                    React.createElement(SettingsTemplateCategories, { isModal: true }))),
            React.createElement(FormToggleControl, { checked: props.currentTemplate.Available, label: "Make this template available for users", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ Available: checked })); } }),
            React.createElement(FormToggleControl, { checked: props.currentTemplate.IsAvailableAsDirectoryFilter, label: "Show this template as an option in the directory Workspace Type filter", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ IsAvailableAsDirectoryFilter: checked })); } })),
        React.createElement(Col, { md: 5, lg: 4, xl: 4, className: "right-col" },
            React.createElement("div", { className: "image-uploads" },
                React.createElement("div", { className: "template-image", "data-pw": "template-image" },
                    React.createElement(Form.Group, null,
                        React.createElement("h2", null, "Template Image"),
                        React.createElement("div", { className: "subheader", "data-pw": "subheader" },
                            "Set the image shown for this template in the Workspace Request Wizard ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement(FormImageUploadBox, { defaultBase64Image: props.currentTemplate.Image, text: "Upload or drop an image here", onDrop: function (file) { return dispatch(TemplateCurrentStore.Update({ Image: file })); } }),
                        React.createElement("div", { className: "image-size", "data-pw": "image-size" }, "Maximum size - 600px x 600px"),
                        React.createElement(FormValidator, { UniqueIdentifier: "template-image-uploaded", Validators: ["string-not-empty"], ShowAsInfo: true, ShowFirst: true, ErrorMessage: "Please attach an image", Value: props.currentTemplate.Image }))),
                React.createElement("div", { className: "default-workspace-logo", "data-pw": "default-workspace-logo" },
                    React.createElement("h2", null, "Default Workspace Logo"),
                    React.createElement("div", { id: "default-logo-subheader", className: "subheader", "data-pw": "subheader" },
                        "Set a default logo that will be provisioned as part of the Workspace. ",
                        React.createElement("br", null),
                        " The requestor will not be able to change the logo during the request process."),
                    React.createElement(FormImageUploadBox, { defaultBase64Image: props.currentTemplate.DefaultWorkspaceLogo, text: "Upload or drop an image here", onDrop: function (file) { return dispatch(TemplateCurrentStore.Update({ DefaultWorkspaceLogo: file })); } }),
                    React.createElement("div", { className: "image-size", "data-pw": "image-size" }, "Maximum size - 124px x 124px")))))
    // </Container>
    );
});
export default TabDetails;
