import * as React from "react";
import "./orchestry-admin-etl-crawl-timeline.scss";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Portlet, PortletBody } from "app/components/common/portlet";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import OrchestryService from "app/services/orchestry";
import * as PageHeader from "app/components/common/page-header";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import LifecycleService from "app/services/lifecycle";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
var OrchestryAdminETLTimeline = React.memo(function () {
    var _a = React.useState(null), EtlTimeline = _a[0], SetEtlTimeline = _a[1];
    var _b = React.useState(800), height = _b[0], setHeight = _b[1];
    var _c = React.useState(800), chartHeight = _c[0], setChartHeight = _c[1];
    var _d = React.useState(), activeRegions = _d[0], setActiveRegions = _d[1];
    var _e = React.useState(null), selectedRegion = _e[0], setSelectedRegion = _e[1];
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    React.useEffect(function () {
        if (activeRegions !== undefined) {
            setSelectedRegion(activeRegions[0].value);
        }
    }, [activeRegions]);
    React.useEffect(function () {
        if (selectedRegion !== null) {
            LifecycleService.GetETLTimeline(selectedRegion).then(function (stats) {
                SetEtlTimeline(stats);
            });
        }
    }, [selectedRegion]);
    // React.useEffect(() => {
    //    LifecycleService.GetETLTimeline("US").then((stats) => {
    //       SetEtlTimeline(stats);
    //    })
    // }, [])
    return (React.createElement("div", { className: "orchestry-admin-etl-crawl-stats fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Crawler", subTitle: "Timeline" }),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement("div", { style: { display: "flex", alignItems: "center", marginBottom: "20px" } },
                    React.createElement(Form.Control, { value: height.toString(), type: "number", onChange: function (event) { return setHeight(Number(event.currentTarget.value)); }, style: { width: "200px" } }),
                    React.createElement(Button, { onClick: function () { return setChartHeight(height); }, style: { marginLeft: "15px" } }, "Set")),
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "region-select" }, activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) { return setSelectedRegion(value); } }))),
                        EtlTimeline === null ? (React.createElement(SkeletonLoaderFakeBox, null)) : chartHeight > 0 ? (React.createElement(ReactApexChart, { height: chartHeight, options: {
                                chart: {
                                    height: chartHeight,
                                    type: "rangeBar"
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true
                                    }
                                },
                                xaxis: {
                                    type: "datetime"
                                },
                                tooltip: {
                                    custom: function (_a) {
                                        var series = _a.series, seriesIndex = _a.seriesIndex, dataPointIndex = _a.dataPointIndex, w = _a.w;
                                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                        console.log("Hover", data);
                                        return ('<div class="orchestry-etl-timeline-tooltip">' +
                                            "<div class='title'>" +
                                            data.x +
                                            "</div>" +
                                            "<div class='property'>" +
                                            "<div>Crawl Start:</div>" +
                                            "<div>" +
                                            moment(data.item.CrawlStarted).format("HH:SS") +
                                            "</div>" +
                                            "</div>" +
                                            "<div class='property'>" +
                                            "<div>Crawl End:</div>" +
                                            "<div>" +
                                            moment(data.item.CrawlEnded).format("HH:SS") +
                                            "</div>" +
                                            "</div>" +
                                            "<div class='property'>" +
                                            "<div>Error:</div>" +
                                            "<div>" +
                                            (data.item.ErroredOut === true ? "True" : "False") +
                                            "</div>" +
                                            "</div>" +
                                            "<div class='property'>" +
                                            "<div>Workspace Count:</div>" +
                                            "<div>" +
                                            data.item.WorkspaceCount +
                                            "</div>" +
                                            "</div>" +
                                            "</div>");
                                    }
                                }
                            }, series: [
                                {
                                    data: EtlTimeline.map(function (item) { return ({
                                        x: item.TenantDisplayName,
                                        y: [new Date(item.CrawlStarted).getTime(), new Date(item.CrawlEnded).getTime()],
                                        fillColor: item.ErroredOut === true ? "#DE6A4C" : "#3B35BD",
                                        item: item
                                    }); })
                                }
                            ], type: "rangeBar" })) : null))))));
});
export default OrchestryAdminETLTimeline;
