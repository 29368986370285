import { UserCurrentActionTypes } from "app/store/users/current/types";
import { AsyncCallType } from "app/store/async/types";
export var userCurrentActionMap = [
    {
        actionType: UserCurrentActionTypes.User_Current_Load,
        callType: AsyncCallType.Get,
        keyType: "guid",
        ApiUrl: "/api/users/GetCurrentUser",
        responseDataType: {}
    }
];
