var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
var getBaseUrl = function (userType) {
    if (userType === void 0) { userType = null; }
    switch (userType) {
        case "partner":
            return "/Partners";
        case "internal":
            return "/Tenants";
        default:
            return "/Partners";
    }
};
export var partnersApi = createApi({
    reducerPath: "partnersApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
    tagTypes: ["PartnersTenants", "HealthChecks", "TenantManagers"],
    endpoints: function (builder) { return ({
        getMspTenants: builder.query({
            query: function (request) { return ({
                url: "".concat(getBaseUrl(), "/GetMspTenants"),
                params: __assign(__assign({}, request.queryParameters), { SearchText: request.searchText })
            }); },
            providesTags: ["PartnersTenants"]
        }),
        getTenantManagers: builder.query({
            query: function (tenantGuid) { return ({
                url: "".concat(getBaseUrl(), "/GetTenantManagers?tenantGuid=").concat(tenantGuid)
            }); },
            providesTags: ["TenantManagers"]
        }),
        updateTenantManagers: builder.mutation({
            query: function (updateTenantManagersCommand) { return ({
                url: "".concat(getBaseUrl(), "/UpdateTenantManagers"),
                method: "PUT",
                body: updateTenantManagersCommand
            }); },
            invalidatesTags: ["TenantManagers"]
        }),
        getMspTenantAnalytics: builder.query({
            query: function (_a) {
                var tenantGuid = _a[0], _b = _a[1], userType = _b === void 0 ? "partner" : _b;
                return ({
                    url: "".concat(getBaseUrl(userType), "/GetMspTenantAnalytics?tenantGuid=").concat(tenantGuid)
                });
            }
        }),
        getMspTenantDetails: builder.query({
            query: function (tenantGuid) { return ({
                url: "".concat(getBaseUrl(), "/GetMspTenantDetails?tenantGuid=").concat(tenantGuid)
            }); }
        })
    }); }
});
export var useGetMspTenantsQuery = partnersApi.useGetMspTenantsQuery, useGetTenantManagersQuery = partnersApi.useGetTenantManagersQuery, useUpdateTenantManagersMutation = partnersApi.useUpdateTenantManagersMutation, useGetMspTenantAnalyticsQuery = partnersApi.useGetMspTenantAnalyticsQuery, useGetMspTenantDetailsQuery = partnersApi.useGetMspTenantDetailsQuery;
