var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RecommendationSource } from "./recommendation-source.type";
var getBaseUrl = function (userType) {
    switch (userType) {
        case "partner":
            return "/Partners";
        case "customer":
            return "/recommendations";
        default:
            return "/recommendations";
    }
};
export var recommendationsApi = createApi({
    reducerPath: "recommendationsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
    tagTypes: [
        "Recommendations",
        "RecommendationHistory",
        "RecommendationCategories",
        "RecommendationProducts",
        "RecommendationRecordsVisibilityCount",
        "RecommendationsStats",
        "RecommendationVotes",
        "RecommendationAdminAnalytics",
        "RecommendationAdminSuggestions",
        "HealthCheckRecommendations"
    ],
    endpoints: function (builder) { return ({
        getRecommendations: builder.query({
            query: function (request) { return ({
                url: "".concat(getBaseUrl("customer"), "/GetRecommendations"),
                params: __assign(__assign({}, request.queryParameters), { SearchText: request.searchText })
            }); },
            providesTags: [{ type: "Recommendations", id: "list" }]
        }),
        getSpecificRecommendations: builder.query({
            query: function (request) { return ({
                url: "".concat(getBaseUrl("customer"), "/GetSpecificRecommendations"),
                params: __assign(__assign({}, request.queryParameters), { SearchText: request.searchText, tid: request.tid })
            }); },
            transformErrorResponse: function (error) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (error.status === "PARSING_ERROR" && error.originalStatus === 403) {
                        return [2 /*return*/, { status: 403, data: "You do not have permission to view this." }];
                    }
                    return [2 /*return*/, error];
                });
            }); },
            providesTags: [{ type: "Recommendations", id: "list" }]
        }),
        getHealthCheckRecommendations: builder.query({
            query: function (_a) {
                var userType = _a[0], request = _a[1], tenantGuid = _a[2];
                return ({
                    url: "".concat(getBaseUrl(userType), "/GetHealthCheckRecommendations?tenantGuid=").concat(tenantGuid),
                    params: __assign(__assign({}, request.queryParameters), { SearchText: request.searchText, healthCheckGuid: request.healthCheckGuid })
                });
            },
            providesTags: [{ type: "HealthCheckRecommendations", id: "list" }]
        }),
        /**
         * Gets the recommendation record history, optionally for admin purposes - will use the GetSpecificRecommendationHistory endpoint
         * if provided a tenant id.
         */
        getRecommendationHistory: builder.query({
            query: function (params) {
                if (params.tid) {
                    return { url: "".concat(getBaseUrl("customer"), "/GetSpecificRecommendationHistory"), params: __assign(__assign({}, params.queryParameters), { tid: params.tid }) };
                }
                else {
                    return { url: "".concat(getBaseUrl("customer"), "/GetRecommendationHistory"), params: __assign({}, params.queryParameters) };
                }
            },
            providesTags: ["RecommendationHistory"]
        }),
        getRecommendationCategories: builder.query({
            query: function () { return "".concat(getBaseUrl("customer"), "/GetRecommendationCategories"); },
            providesTags: ["RecommendationCategories"]
        }),
        getRecommendationProducts: builder.query({
            query: function () { return "".concat(getBaseUrl("customer"), "/GetRecommendationProducts"); },
            providesTags: ["RecommendationProducts"]
        }),
        getRecommendationRecordsVisibilityCounts: builder.query({
            query: function () { return "".concat(getBaseUrl("customer"), "/GetRecommendationRecordsVisibilityCount"); },
            providesTags: ["RecommendationRecordsVisibilityCount"]
        }),
        getSpecificRecommendationRecordsVisibilityCounts: builder.query({
            query: function (request) { return ({ url: "".concat(getBaseUrl("customer"), "/GetSpecificRecommendationRecordsVisibilityCount"), params: { tid: request.tid } }); },
            providesTags: ["RecommendationRecordsVisibilityCount"]
        }),
        getRecommendationsStats: builder.query({
            query: function () { return "".concat(getBaseUrl("customer"), "/GetRecommendationsStats"); },
            providesTags: ["RecommendationsStats"]
        }),
        getSpecificRecommendationsStats: builder.query({
            query: function (request) { return ({ url: "".concat(getBaseUrl("customer"), "/GetSpecificRecommendationsStats"), params: { tid: request.tid } }); },
            providesTags: ["RecommendationsStats"]
        }),
        getAdminAnalytics: builder.query({
            query: function (request) { return ({ url: "".concat(getBaseUrl("customer"), "/GetAdminAnalytics"), params: {} }); },
            providesTags: ["RecommendationAdminAnalytics"]
        }),
        getAdminSuggestions: builder.query({
            query: function (request) { return ({ url: "".concat(getBaseUrl("customer"), "/GetAdminSuggestions"), params: {} }); },
            providesTags: ["RecommendationAdminSuggestions"]
        }),
        createRecommendationSuggestion: builder.mutation({
            query: function (createRecommendationSuggestionCommand) { return ({
                url: "".concat(getBaseUrl("customer"), "/CreateRecommendationSuggestion"),
                method: "POST",
                body: createRecommendationSuggestionCommand
            }); }
        }),
        addRecommendation: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, createRecommendationRecordCommand = _a.createRecommendationRecordCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/AddRecommendation"),
                    method: "POST",
                    body: createRecommendationRecordCommand,
                    invalidatesTags: [
                        { type: "Recommendations", id: "list" },
                        { type: "HealthCheckRecommendations", id: "list" }
                    ]
                });
            }
        }),
        updateRecommendation: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, updateRecommendationRecordCommand = _a.updateRecommendationRecordCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/UpdateRecommendation"),
                    method: "PUT",
                    body: updateRecommendationRecordCommand
                });
            },
            onQueryStarted: function (_a, _b) { return __awaiter(void 0, void 0, void 0, function () {
                var mappedFields_1, getRecommendationCacheKeys, e_1;
                var _c, _d;
                var userType = _a.userType, _e = _a.updateRecommendationRecordCommand, UniqueId = _e.UniqueId, fields = __rest(_e, ["UniqueId"]);
                var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                return __generator(this, function (_f) {
                    switch (_f.label) {
                        case 0:
                            _f.trys.push([0, 2, , 3]);
                            mappedFields_1 = __assign(__assign({}, fields), { Definition_Categories: fields.Categories, Definition_Products: fields.Products, Definition_Priority: fields.Priority });
                            return [4 /*yield*/, queryFulfilled];
                        case 1:
                            _f.sent();
                            getRecommendationCacheKeys = (_d = (_c = getState().recommendationsApi.provided.Recommendations) === null || _c === void 0 ? void 0 : _c.list) !== null && _d !== void 0 ? _d : [];
                            getRecommendationCacheKeys.forEach(function (queryParameters) {
                                var _a, _b, _c;
                                var originalParameters = (_a = getState().recommendationsApi.queries[queryParameters]) === null || _a === void 0 ? void 0 : _a.originalArgs;
                                dispatch(recommendationsApi.util.updateQueryData("getRecommendations", originalParameters, function (draft) {
                                    draft.Items = draft.Items.map(function (item) {
                                        return item.RecommendationRecord.UniqueId === UniqueId
                                            ? __assign(__assign({}, item), { Recommendation: __assign(__assign({}, item.Recommendation), { DisplayName: fields.DisplayName, Priority: fields.Priority }), RecommendationRecord: __assign(__assign({}, item.RecommendationRecord), mappedFields_1) }) : item;
                                    });
                                }));
                                var getHealthCheckRecommendationCacheKeys = (_c = (_b = getState().recommendationsApi.provided.HealthCheckRecommendations) === null || _b === void 0 ? void 0 : _b.list) !== null && _c !== void 0 ? _c : [];
                                getHealthCheckRecommendationCacheKeys.forEach(function (queryParameters) {
                                    var _a;
                                    var originalHealthCheckParameters = (_a = getState().recommendationsApi.queries[queryParameters]) === null || _a === void 0 ? void 0 : _a.originalArgs;
                                    dispatch(recommendationsApi.util.updateQueryData("getHealthCheckRecommendations", originalHealthCheckParameters, function (draft) {
                                        draft.Items = draft.Items.map(function (item) {
                                            return item.RecommendationRecord.UniqueId === UniqueId
                                                ? __assign(__assign({}, item), { Recommendation: __assign(__assign({}, item.Recommendation), { DisplayName: fields.DisplayName, Priority: fields.Priority }), RecommendationRecord: __assign(__assign({}, item.RecommendationRecord), mappedFields_1) }) : item;
                                        });
                                    }));
                                });
                            });
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _f.sent();
                            console.error(e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }
        }),
        deleteRecommendation: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, deleteRecommendationRecordCommand = _a.deleteRecommendationRecordCommand;
                return ({
                    url: "".concat(getBaseUrl(userType), "/DeleteRecommendation"),
                    method: "Delete",
                    body: deleteRecommendationRecordCommand
                });
            },
            onQueryStarted: function (_a, _b) {
                var userType = _a.userType, UniqueId = _a.deleteRecommendationRecordCommand.UniqueId;
                var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                return __awaiter(void 0, void 0, void 0, function () {
                    var getRecommendationCacheKeys, getHealthCheckRecommendationCacheKeys, e_2;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, queryFulfilled];
                            case 1:
                                _c.sent();
                                getRecommendationCacheKeys = getState().recommendationsApi.provided.Recommendations.list;
                                getRecommendationCacheKeys.forEach(function (queryParameters) {
                                    var _a;
                                    var originalParameters = (_a = getState().recommendationsApi.queries[queryParameters]) === null || _a === void 0 ? void 0 : _a.originalArgs;
                                    dispatch(recommendationsApi.util.updateQueryData("getRecommendations", originalParameters, function (draft) {
                                        draft.Items = draft.Items.filter(function (item) { return item.RecommendationRecord.UniqueId !== UniqueId; });
                                    }));
                                });
                                getHealthCheckRecommendationCacheKeys = getState().recommendationsApi.provided.HealthCheckRecommendations.list;
                                getHealthCheckRecommendationCacheKeys.forEach(function (queryParameters) {
                                    var _a;
                                    var originalParameters = (_a = getState().recommendationsApi.queries[queryParameters]) === null || _a === void 0 ? void 0 : _a.originalArgs;
                                    dispatch(recommendationsApi.util.updateQueryData("getHealthCheckRecommendations", originalParameters, function (draft) {
                                        draft.Items = draft.Items.filter(function (item) { return item.RecommendationRecord.UniqueId !== UniqueId; });
                                        draft.TotalCount = draft.TotalCount - 1;
                                    }));
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _c.sent();
                                console.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            }
        }),
        updateRecommendationVisibility: builder.mutation({
            query: function (updateRecommendationVisibilityCommand) { return ({
                url: "".concat(getBaseUrl("customer"), "/UpdateRecommendationVisibility"),
                method: "PUT",
                body: updateRecommendationVisibilityCommand
            }); },
            onQueryStarted: function (_a, _b) {
                var RecommendationGUID = _a.RecommendationGUID, Visibility = _a.Visibility;
                var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                return __awaiter(void 0, void 0, void 0, function () {
                    var getRecommendationCacheKeys, previousVisibility_1, updatedRecord_1, isCustomRecommendation_1, updatedCategoryCounts_1, e_3;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 2, , 3]);
                                getRecommendationCacheKeys = getState().recommendationsApi.provided.Recommendations.list;
                                getRecommendationCacheKeys.forEach(function (queryParameters) {
                                    var _a;
                                    var recommendations = (_a = getState().recommendationsApi.queries[queryParameters]) === null || _a === void 0 ? void 0 : _a.data;
                                    var recommendation = recommendations === null || recommendations === void 0 ? void 0 : recommendations.Items.find(function (item) { return item.RecommendationRecord.RecommendationGUID === RecommendationGUID; });
                                    if (recommendation) {
                                        previousVisibility_1 = recommendation.RecommendationRecord.Visibility;
                                    }
                                });
                                return [4 /*yield*/, queryFulfilled];
                            case 1:
                                updatedRecord_1 = _c.sent();
                                isCustomRecommendation_1 = updatedRecord_1.data.RecommendationRecord.Source === RecommendationSource.Custom;
                                updatedCategoryCounts_1 = updatedRecord_1.data.RecommendationRecord.Definition_Categories.split("|").reduce(function (counts, category) {
                                    counts[category] = (counts[category] || 0) + 1;
                                    return counts;
                                }, {});
                                switch (Visibility) {
                                    case "Visible":
                                        dispatch(recommendationsApi.util.updateQueryData("getRecommendationRecordsVisibilityCounts", undefined, function (draft) {
                                            if (isCustomRecommendation_1) {
                                                draft.Visible += 1;
                                                if (previousVisibility_1 === "Snoozed") {
                                                    draft.Snoozed -= 1;
                                                }
                                                else if (previousVisibility_1 === "Resolved") {
                                                    draft.Resolved -= 1;
                                                }
                                            }
                                            else {
                                                draft.Snoozed -= 1; // If it's not a custom recommendation, it's being moved from Snoozed to Visible
                                            }
                                        }));
                                        break;
                                    case "Snoozed":
                                    case "Resolved":
                                        if (Visibility === "Resolved") {
                                            dispatch(recommendationsApi.util.updateQueryData("getRecommendationRecordsVisibilityCounts", undefined, function (draft) {
                                                draft.Visible -= 1;
                                                if (isCustomRecommendation_1) {
                                                    draft.Resolved += 1;
                                                }
                                            }));
                                        }
                                        else if (Visibility === "Snoozed") {
                                            dispatch(recommendationsApi.util.updateQueryData("getRecommendationRecordsVisibilityCounts", undefined, function (draft) {
                                                draft.Snoozed = draft.Snoozed !== undefined ? draft.Snoozed + 1 : 1;
                                                draft.Visible -= 1;
                                            }));
                                        }
                                        dispatch(recommendationsApi.util.updateQueryData("getRecommendationsStats", undefined, function (draft) {
                                            draft.RecommendationsPriorityCounts[updatedRecord_1.data.RecommendationRecord.Definition_Priority] -= 1;
                                            Object.keys(updatedCategoryCounts_1).forEach(function (key) {
                                                if (draft.RecommendationsCategoryCounts.hasOwnProperty(key)) {
                                                    draft.RecommendationsCategoryCounts[key] -= updatedCategoryCounts_1[key];
                                                }
                                            });
                                        }));
                                        break;
                                }
                                getRecommendationCacheKeys.forEach(function (queryParameters) {
                                    var _a;
                                    var originalParameters = (_a = getState().recommendationsApi.queries[queryParameters]) === null || _a === void 0 ? void 0 : _a.originalArgs;
                                    dispatch(recommendationsApi.util.updateQueryData("getRecommendations", originalParameters, function (draft) {
                                        var _a, _b, _c, _d;
                                        switch (Visibility) {
                                            case "Visible":
                                                draft.Items = draft.Items.filter(function (item) { return item.RecommendationRecord.RecommendationGUID !== RecommendationGUID; });
                                                if (isCustomRecommendation_1) {
                                                    if ((_a = originalParameters.queryParameters.filter) === null || _a === void 0 ? void 0 : _a.includes("Visible")) {
                                                        draft.Items.push(updatedRecord_1.data);
                                                        draft.Items.sort(function (a, b) {
                                                            return a.RecommendationRecord.Definition_Priority > b.RecommendationRecord.Definition_Priority ? -1 : 1;
                                                        });
                                                    }
                                                }
                                                break;
                                            case "Resolved":
                                                draft.Items = draft.Items.filter(function (item) { return item.RecommendationRecord.RecommendationGUID !== RecommendationGUID; });
                                                if (isCustomRecommendation_1) {
                                                    if ((_b = originalParameters.queryParameters.filter) === null || _b === void 0 ? void 0 : _b.includes("Resolved")) {
                                                        draft.Items.push(updatedRecord_1.data);
                                                        draft.Items.sort(function (a, b) {
                                                            return a.RecommendationRecord.Definition_Priority > b.RecommendationRecord.Definition_Priority ? -1 : 1;
                                                        });
                                                    }
                                                }
                                                break;
                                            case "Snoozed":
                                                if ((_c = originalParameters.queryParameters.filter) === null || _c === void 0 ? void 0 : _c.includes("Visible")) {
                                                    draft.Items = draft.Items.filter(function (item) { return item.RecommendationRecord.RecommendationGUID !== RecommendationGUID; });
                                                }
                                                if ((_d = originalParameters.queryParameters.filter) === null || _d === void 0 ? void 0 : _d.includes("Snoozed")) {
                                                    var snoozedRecommendationExists = draft.Items.some(function (item) { return item.RecommendationRecord.RecommendationGUID === RecommendationGUID; });
                                                    if (!snoozedRecommendationExists) {
                                                        draft.Items.push(updatedRecord_1.data);
                                                    }
                                                }
                                                draft.Items.sort(function (a, b) {
                                                    return a.RecommendationRecord.Definition_Priority > b.RecommendationRecord.Definition_Priority ? -1 : 1;
                                                });
                                                break;
                                        }
                                    }));
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _c.sent();
                                console.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            }
        }),
        updateRecommendationVote: builder.mutation({
            query: function (updateRecommendationVoteCommand) { return ({
                url: "".concat(getBaseUrl("customer"), "/UpsertRecommendationVote"),
                method: "PUT",
                body: updateRecommendationVoteCommand
            }); },
            onQueryStarted: function (_a, _b) {
                var RecommendationGUID = _a.RecommendationGUID, Vote = _a.Vote;
                var dispatch = _b.dispatch, getState = _b.getState, queryFulfilled = _b.queryFulfilled;
                return __awaiter(this, void 0, void 0, function () {
                    var e_4;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, queryFulfilled];
                            case 1:
                                _c.sent();
                                getState().recommendationsApi.provided.Recommendations.list.forEach(function (queryParameters) {
                                    var query = getState().recommendationsApi.queries[queryParameters];
                                    if (query) {
                                        dispatch(recommendationsApi.util.updateQueryData("getRecommendations", query.originalArgs, function (draft) {
                                            draft.Items = draft.Items.map(function (item) { return (item.RecommendationRecord.RecommendationGUID === RecommendationGUID ? __assign(__assign({}, item), { Vote: Vote }) : item); });
                                        }));
                                    }
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_4 = _c.sent();
                                console.error(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            }
        })
    }); }
});
export var useGetRecommendationsQuery = recommendationsApi.useGetRecommendationsQuery, useGetSpecificRecommendationsQuery = recommendationsApi.useGetSpecificRecommendationsQuery, useGetRecommendationHistoryQuery = recommendationsApi.useGetRecommendationHistoryQuery, useGetRecommendationCategoriesQuery = recommendationsApi.useGetRecommendationCategoriesQuery, useGetRecommendationProductsQuery = recommendationsApi.useGetRecommendationProductsQuery, useCreateRecommendationSuggestionMutation = recommendationsApi.useCreateRecommendationSuggestionMutation, useUpdateRecommendationVisibilityMutation = recommendationsApi.useUpdateRecommendationVisibilityMutation, useGetRecommendationRecordsVisibilityCountsQuery = recommendationsApi.useGetRecommendationRecordsVisibilityCountsQuery, useGetSpecificRecommendationRecordsVisibilityCountsQuery = recommendationsApi.useGetSpecificRecommendationRecordsVisibilityCountsQuery, useGetRecommendationsStatsQuery = recommendationsApi.useGetRecommendationsStatsQuery, useGetSpecificRecommendationsStatsQuery = recommendationsApi.useGetSpecificRecommendationsStatsQuery, useUpdateRecommendationVoteMutation = recommendationsApi.useUpdateRecommendationVoteMutation, useGetAdminSuggestionsQuery = recommendationsApi.useGetAdminSuggestionsQuery, useGetAdminAnalyticsQuery = recommendationsApi.useGetAdminAnalyticsQuery, useAddRecommendationMutation = recommendationsApi.useAddRecommendationMutation, useUpdateRecommendationMutation = recommendationsApi.useUpdateRecommendationMutation, useDeleteRecommendationMutation = recommendationsApi.useDeleteRecommendationMutation, useGetHealthCheckRecommendationsQuery = recommendationsApi.useGetHealthCheckRecommendationsQuery;
