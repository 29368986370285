var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-group-select.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import GroupSearchDropdown from "app/components/common/group-search-dropdown";
import GroupItem from "app/components/common/group-item";
import { Form } from "react-bootstrap";
var FormGroupSelect = React.memo(function (props) {
    var _a = React.useState([]), currentGroups = _a[0], setCurrentGroups = _a[1];
    var allowMultiple = React.useState(props.allowMultiple !== undefined ? props.allowMultiple : false)[0];
    // Set Default Values
    React.useEffect(function () {
        if (props.defaultValue !== undefined && props.defaultValue !== null) {
            if (props.defaultValue.length > 0) {
                var groupObjectsFromString = [];
                try {
                    groupObjectsFromString = JSON.parse(props.defaultValue);
                }
                catch (_a) { }
                if (groupObjectsFromString.length > 0) {
                    setCurrentGroups(groupObjectsFromString);
                }
            }
        }
        //defaultValue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var removeGroup = function (group) {
        if (allowMultiple === false) {
            setCurrentGroups([]);
        }
        else {
            setCurrentGroups(currentGroups.filter(function (g) { return (g.GUID === group.GUID ? false : true); }));
        }
    };
    React.useEffect(function () {
        props.onChange(JSON.stringify(currentGroups));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGroups]);
    return (React.createElement("div", { className: "form-group-select" },
        React.createElement(Form.Group, { className: "form-group", controlId: "control-approval-group" },
            props.label !== undefined ? React.createElement(Form.Label, null, props.label) : null,
            allowMultiple || currentGroups.length === 0 ? (React.createElement(GroupSearchDropdown, { onGroupSelected: function (group) {
                    setCurrentGroups(__spreadArray(__spreadArray([], currentGroups, true), [group], false));
                } }, props.displayAddAsButton !== undefined && props.displayAddAsButton ? (React.createElement("div", { className: "add-button" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faPlus }),
                React.createElement("span", null, "Add Group"))) : (React.createElement("div", { className: "add" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faPlusCircle }))))) : null,
            React.createElement("div", { className: "group-area form-control" },
                currentGroups.length > 0
                    ? currentGroups.map(function (group, gindex) { return (React.createElement(GroupItem, { group: group, onRemove: function (groupToRemove) {
                            removeGroup(groupToRemove);
                        }, key: gindex })); })
                    : null,
                currentGroups.length === 0 ? React.createElement("div", { className: "no-results" },
                    "No group", "".concat(allowMultiple ? "(s)" : ""),
                    " selected. Click the + button above to add a group.") : null))));
});
export default FormGroupSelect;
