import { Group, Skeleton, Switch, Text } from "@mantine/core";
import React from "react";
export var OrchestryToggle = function (_a) {
    var checked = _a.checked, onChange = _a.onChange, onLabel = _a.onLabel, offLabel = _a.offLabel, isLoading = _a.isLoading;
    if (isLoading) {
        return (React.createElement(Group, { gap: 12 },
            React.createElement(Skeleton, { height: 20, width: 42, radius: "md" }),
            React.createElement(Skeleton, { height: 20, width: 55, radius: "md" })));
    }
    return (React.createElement(Switch, { styles: { track: { cursor: "pointer" } }, color: "var(--mantine-color-primary-6)", checked: checked, onChange: function (event) { return onChange(event.currentTarget.checked); }, label: React.createElement(React.Fragment, null,
            checked && !!onLabel && (React.createElement(Text, { c: "text-primary.0", size: "14px", fw: 400, lh: "20px", styles: { root: { cursor: "pointer" } } }, onLabel)),
            !checked && !!offLabel && (React.createElement(Text, { c: "text-primary.0", size: "14px", fw: 400, lh: "20px", styles: { root: { cursor: "pointer" } } }, offLabel))) }));
};
