import * as React from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { FormattedMessage, useIntl } from "react-intl";
var RequestDetailsTemplateBaseConfig = React.memo(function (props) {
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var intl = useIntl();
    var trueLabel = intl.formatMessage({ id: "general.label.true", defaultMessage: "True" });
    var falseLabel = intl.formatMessage({ id: "general.label.false", defaultMessage: "False" });
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.template_config.base_configuration.title", defaultMessage: "Base Configuration" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.available_to_users", defaultMessage: "Available to users" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.Available) ? trueLabel : falseLabel))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.require_approval_before", defaultMessage: "Require Approval before provisioning" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.RequireApprovalBeforeProvisioning) ? trueLabel : falseLabel))))));
});
export default RequestDetailsTemplateBaseConfig;
