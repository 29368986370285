import * as React from "react";
import "./card-private-azure-storage.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import AzureStorageSetup from "app/components/common/azure-storage-setup";
var CardPrivateAzureStorage = React.memo(function (_a) {
    var _b = _a.ShowPortlet, ShowPortlet = _b === void 0 ? true : _b;
    return (React.createElement("div", { className: "card-private-azure-storage" }, ShowPortlet ? (React.createElement(Portlet, null,
        React.createElement(PortletHeader, { title: "Azure Storage Setup" }),
        React.createElement(PortletBody, null,
            React.createElement(AzureStorageSetup, null)))) : (React.createElement(AzureStorageSetup, null))));
});
export default CardPrivateAzureStorage;
