import DisableArea from "app/components/common/disable-area";
import NoResults from "app/components/common/no-results";
import LifecycleService from "app/services/lifecycle";
import moment from "moment";
import * as React from "react";
import "./report-choose-list.scss";
var LifecycleReportChooseList = React.memo(function (props) {
    var _a = React.useState(""), searchText = _a[0], setSearchText = _a[1];
    var _b = React.useState(undefined), lastCrawlLog = _b[0], setLastCrawlLog = _b[1];
    var _c = React.useState(props.reports), filteredViews = _c[0], setFilteredViews = _c[1];
    React.useEffect(function () {
        //console.log("reportItem Running?");
        LifecycleService.GetLatestCompleteETLLog().then(function (reportItem) {
            setLastCrawlLog(reportItem);
        });
    }, []);
    var getUniqueGroups = function () {
        var groups = [];
        filteredViews.forEach(function (g) {
            if (!groups.some(function (gn) { return gn === g.GroupName; })) {
                groups.push(g.GroupName);
            }
        });
        return groups;
    };
    React.useEffect(function () {
        if (searchText === "") {
            // Default View - Filter by Datasource only
            setFilteredViews(props.reports.filter(function (rv) { return rv.Datasource === props.Datasource; }));
        }
        else {
            // Search Query Entered - Filter by query and datasource
            setFilteredViews(props.reports
                .filter(function (rv) { return rv.Datasource === props.Datasource; })
                .filter(function (rv) {
                if (!rv.VisibleInChooseList)
                    return false;
                var lowerSearch = searchText.toLowerCase().split(" ");
                var bothNameMatch = true;
                var bothDescMatch = true;
                if (rv.Name != null) {
                    lowerSearch.forEach(function (searchTerm) {
                        if (rv.Name.toLowerCase().indexOf(searchTerm) === -1) {
                            bothNameMatch = false;
                        }
                    });
                }
                if (rv.Description != null) {
                    lowerSearch.forEach(function (searchTerm) {
                        if (rv.Description.toLowerCase().indexOf(searchTerm) === -1) {
                            bothDescMatch = false;
                        }
                    });
                }
                return bothNameMatch || bothDescMatch;
            }));
        }
    }, [searchText]);
    return (React.createElement("div", { className: "lifecycle-report-choose-list", "data-pw": "lifecycle-insights-list" },
        React.createElement("div", { className: "choose-header", "data-pw": "insights-list-header" },
            React.createElement("h4", null,
                props.NamePrefix,
                " Reports"),
            React.createElement("p", null, "Click on a report to see more details"),
            React.createElement("div", { className: "search-box-border", "data-pw": "search-box" },
                React.createElement("input", { placeholder: "Search", value: searchText, onChange: function (value) { return setSearchText(value.currentTarget.value); } }),
                React.createElement("i", { className: "la la-search" })),
            lastCrawlLog !== undefined && lastCrawlLog !== null ? (React.createElement("div", { className: "last-crawl-date", "data-pw": "last-crawl-date" },
                "Data Last Refreshed ",
                moment(lastCrawlLog.CrawlDay).format("LL"))) : null),
        React.createElement("div", { className: "choose-body" },
            React.createElement(DisableArea, { isDisabled: props.isLoading, useOverlay: true },
                getUniqueGroups().map(function (reportCat, index) { return (React.createElement("div", { className: "choose-group", key: "group-key-" + index, "data-pw": "insight-group" },
                    React.createElement("div", { className: "choose-sub-title", "data-pw": "group-title" }, reportCat),
                    React.createElement("div", { className: "choose-items", "data-pw": "group-items" }, filteredViews
                        .filter(function (r) {
                        return r.GroupName === reportCat && r.VisibleInChooseList;
                    })
                        .map(function (r) { return (React.createElement("div", { key: r.Id, className: "choose-item" + (r.Name === props.selectedName ? " selected" : ""), onClick: function () { return props.onSelected(r.Name); }, "data-pw": "insight-item" },
                        React.createElement("h6", null, r.Title),
                        React.createElement("p", null, r.Description),
                        React.createElement("div", { className: "Image" }))); })))); }),
                filteredViews.length === 0 && React.createElement(NoResults, null)))));
});
export default LifecycleReportChooseList;
