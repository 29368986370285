import { useGetRecommendationRecordsVisibilityCountsQuery, useGetRecommendationsQuery, useGetRecommendationsStatsQuery } from "appv2/api/recommendations/recommendations.api";
import { useRecommendationsBaseApi } from "./use-recommendations-base-api.hook";
export var useRecommendationsApi = function (appliedFilters, visibilityFilter, appliedFiltersDependency) {
    var _a = useRecommendationsBaseApi(appliedFilters, visibilityFilter, appliedFiltersDependency), recommendationCategories = _a.recommendationCategories, recommendationProducts = _a.recommendationProducts, togglePrioritySort = _a.togglePrioritySort, activePage = _a.activePage, setActivePage = _a.setActivePage, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, querySearchText = _a.querySearchText, highlightSearchWords = _a.highlightSearchWords, searchTextInputValue = _a.searchTextInputValue, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, onSearchBoxXClicked = _a.onSearchBoxXClicked, isError = _a.isError, pageOptions = _a.pageOptions, filterExpression = _a.filterExpression, prioritySort = _a.prioritySort;
    var _b = useGetRecommendationsQuery({
        queryParameters: {
            filter: filterExpression,
            orderBy: "Definition_Priority ".concat(prioritySort),
            page: activePage,
            pageSize: Number(resultsPerPage)
        },
        searchText: querySearchText
    }), pagedRecommendations = _b.data, pagedRecommendationsError = _b.error, pagedRecommendationsIsFetching = _b.isFetching, pagedRecommendationsIsLoading = _b.isLoading, pagedRecommendationsRefetch = _b.refetch;
    var _c = useGetRecommendationRecordsVisibilityCountsQuery(), recommendationRecordVisibilityCounts = _c.data, recommendationRecordCountserror = _c.error, recommendationRecordVisibilityCountsIsLoading = _c.isLoading, recommendationRecordVisibilityCountsRefetch = _c.refetch;
    var _d = useGetRecommendationsStatsQuery(), recommendationStats = _d.data, recommendationStatserror = _d.error, recommendationStatsIsFetching = _d.isFetching, recommendationStatsIsLoading = _d.isLoading, recommendationStatsSuccessful = _d.isSuccess, recommendationStatsRefetch = _d.refetch;
    var isPageLoading = pagedRecommendationsIsLoading || recommendationStatsIsLoading || recommendationRecordVisibilityCountsIsLoading;
    return {
        pagedRecommendations: pagedRecommendations,
        recommendationCategories: recommendationCategories,
        recommendationProducts: recommendationProducts,
        recommendationRecordVisibilityCounts: recommendationRecordVisibilityCounts,
        recommendationStats: recommendationStats,
        togglePrioritySort: togglePrioritySort,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        querySearchText: querySearchText,
        highlightSearchWords: highlightSearchWords,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        recommendationStatsSuccessful: recommendationStatsSuccessful,
        pagedRecommendationsIsFetching: pagedRecommendationsIsFetching,
        recommendationRecordVisibilityCountsIsLoading: recommendationRecordVisibilityCountsIsLoading,
        isPageLoading: isPageLoading,
        recommendationStatsIsFetching: recommendationStatsIsFetching,
        pagedRecommendationsRefetch: pagedRecommendationsRefetch,
        recommendationRecordVisibilityCountsRefetch: recommendationRecordVisibilityCountsRefetch,
        recommendationStatsRefetch: recommendationStatsRefetch,
        isError: isError || pagedRecommendationsError || recommendationRecordCountserror || recommendationStatserror,
        pageOptions: pageOptions
    };
};
