import { useIntl } from "react-intl";
/**
 * Hook to format enum suffixes using the intl provider.
 */
export var useEnumSuffixIntl = function () {
    var intl = useIntl();
    var formatEnumSuffixIntl = function (prefix, suffix, defaultMessage) {
        var id = "".concat(prefix).concat(suffix);
        return intl.formatMessage({ id: id, defaultMessage: defaultMessage });
    };
    return {
        formatEnumSuffixIntl: formatEnumSuffixIntl
    };
};
