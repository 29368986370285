import { Card, Collapse, Group, Stack } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryAnnotations } from "appv2/components/orchestry-annotation-section/orchestry-annotations.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { HealthCheckRecommendationsList } from "./health-check-recommendation-list.component";
import { useHealthCheckAdditions } from "./use-health-check-additions";
export var HealthCheckAdditionsTab = function () {
    var _a = useHealthCheckAdditions(), intl = _a.intl, shouldShowMutativeButtons = _a.shouldShowMutativeButtons, shouldRenderButtons = _a.shouldRenderButtons, pagedRecommendations = _a.pagedRecommendations, onAddAnnotationClicked = _a.onAddAnnotationClicked, onAddCustomRecommendationClicked = _a.onAddCustomRecommendationClicked, isCustomRecommendationListLoading = _a.isCustomRecommendationListLoading, isAnnotationsLoading = _a.isAnnotationsLoading, shouldRenderAnnotations = _a.shouldRenderAnnotations, shouldRenderRecommendations = _a.shouldRenderRecommendations, shouldRenderAdditionsTitle = _a.shouldRenderAdditionsTitle, additionsSectionsGap = _a.additionsSectionsGap;
    // The below title logic is done so that the title renders with the sections if there are page breaks and
    // covers the scenarios where either section is not rendered.
    return (React.createElement(Stack, { gap: additionsSectionsGap, mt: 24 },
        React.createElement("div", { id: "additions-annotations" },
            React.createElement(Stack, { gap: 16 },
                shouldRenderAdditionsTitle && shouldRenderAnnotations && (React.createElement(OrchestryText, { c: "text-primary.0", size: "24px", fw: 700, lh: "32px", mt: 12 }, intl.formatMessage({ id: "page.health_check.additions.title", defaultMessage: "Additions" }))),
                React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: shouldRenderAnnotations },
                    React.createElement(Card, { p: 24, variant: "default" },
                        React.createElement(Stack, { gap: 24 },
                            React.createElement(Group, { justify: "space-between" },
                                React.createElement(OrchestryText, { c: "text-primary.0", size: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check.annotations.title", defaultMessage: "Annotations" })),
                                shouldRenderButtons && (React.createElement(OrchestryButton, { disabled: isAnnotationsLoading, fadeTransitionOpacity: shouldShowMutativeButtons ? 1 : 0, onClick: shouldShowMutativeButtons ? onAddAnnotationClicked : undefined, iconLeft: ORCH_ICONS.plus, skeletonHeight: 34, skeletonWidth: 80, iconType: "font", size: "md", variant: "primary" }, intl.formatMessage({ id: "page.health_check.annotations.button.label", defaultMessage: "Add Annotation" })))),
                            React.createElement(OrchestryAnnotations, { margin: 0, renderListLabelWithAnnotations: false, annotationCategory: HealthCheckAnnotationCategory.General })))))),
        React.createElement("div", { id: "additions-custom-recommendations" },
            React.createElement(Stack, { gap: 16 },
                shouldRenderAdditionsTitle && !shouldRenderAnnotations && shouldRenderRecommendations && (React.createElement(OrchestryText, { c: "text-primary.0", size: "24px", fw: 700, lh: "32px", mt: 12 }, intl.formatMessage({ id: "page.health_check.additions.title", defaultMessage: "Additions" }))),
                React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: shouldRenderRecommendations },
                    React.createElement(Card, { p: 24, variant: "default" },
                        React.createElement(Stack, { gap: 24 },
                            React.createElement(Group, { justify: "space-between" },
                                React.createElement(OrchestryText, { c: "text-primary.0", size: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check.custom_recommendations.title", defaultMessage: "Custom Recommendations" })),
                                shouldRenderButtons && (React.createElement(OrchestryButton, { fadeTransitionOpacity: shouldShowMutativeButtons ? 1 : 0, onClick: shouldShowMutativeButtons ? onAddCustomRecommendationClicked : undefined, iconLeft: ORCH_ICONS.plus, disabled: isCustomRecommendationListLoading, skeletonHeight: 34, skeletonWidth: 80, iconType: "font", size: "md", variant: "primary" }, intl.formatMessage({ id: "page.health_check.recommendation.button.label", defaultMessage: "Add Recommendation" })))),
                            React.createElement(HealthCheckRecommendationsList, { recommendations: pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items, isLoading: isCustomRecommendationListLoading }))))))));
};
