import { Skeleton } from "@mantine/core";
import clsx from "clsx";
import React, { forwardRef } from "react";
import "./orchestry-featured-icon.scss";
var mapLightCircleSizeToPx = {
    xxs: 16,
    xs: 24,
    sm: 32,
    md: 40,
    lg: 48,
    xl: 56,
    xxl: 64
};
var mapDoubleCircleSizeToPx = {
    xxs: 16,
    xs: 24,
    sm: 32,
    md: 40,
    lg: 48,
    xl: 56,
    xxl: 64
};
var DEFAULT_SIZE = "sm";
var DEFAULT_IMG_ICON_SIZE_PX = 24;
export var OrchestryFeaturedIcon = forwardRef(function (_a, ref) {
    var size = _a.size, variant = _a.variant, colorVariant = _a.colorVariant, disabled = _a.disabled, iconClassName = _a.iconClassName, imgIconSrc = _a.imgIconSrc, isLoading = _a.isLoading, imgIconWidth = _a.imgIconWidth, imgIconHeight = _a.imgIconHeight, onClick = _a.onClick;
    var iconSize = size || DEFAULT_SIZE;
    var sizePx = variant === "light-circle" ? mapLightCircleSizeToPx[iconSize] : mapDoubleCircleSizeToPx[iconSize];
    if (isLoading) {
        return React.createElement(Skeleton, { height: sizePx, width: sizePx, circle: true });
    }
    return (React.createElement("div", { onClick: onClick, role: onClick ? "button" : undefined, ref: ref, className: clsx("orchestry-featured-icon", { button: onClick }, { "img-icon": !!imgIconSrc }), "data-size": iconSize, "data-variant": variant, "data-disabled": disabled, "data-color-variant": colorVariant === null || colorVariant === void 0 ? void 0 : colorVariant.toLowerCase() }, iconClassName !== undefined ? (React.createElement("i", { className: iconClassName })) : imgIconSrc !== undefined ? (React.createElement("img", { src: imgIconSrc, alt: "icon", width: imgIconWidth !== null && imgIconWidth !== void 0 ? imgIconWidth : DEFAULT_IMG_ICON_SIZE_PX, height: imgIconHeight !== null && imgIconHeight !== void 0 ? imgIconHeight : DEFAULT_IMG_ICON_SIZE_PX })) : null));
});
