var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import DocumentsModal from "app/components/common/documents-modal";
import * as LibraryTemplateCurrentStore from "app/store/library-templates/current";
import { useDispatch } from "react-redux";
import * as TableList from "app/components/common/table-list";
import generateGuid from "app/utils/generateGuid";
var TabDocuments = React.memo(function (props, state) {
    var dispatch = useDispatch();
    //console.log("DocumentTemplates tab > currentTemplate", props.currentTemplate)
    var toggleRequired = function (documentTemplateMap, checked) {
        documentTemplateMap.Required = checked;
        // Change this to use List_Item_Update and do the true/false logic here.
        if (props.currentLibraryTemplate.AssociatedDocumentTemplates !== undefined) {
            dispatch(LibraryTemplateCurrentStore.Update({ AssociatedDocumentTemplates: __spreadArray([], props.currentLibraryTemplate.AssociatedDocumentTemplates, true) }));
        }
    };
    var removeFeature = function (tbaToRemove) {
        var _a;
        dispatch(LibraryTemplateCurrentStore.Update({
            AssociatedDocumentTemplates: (_a = props.currentLibraryTemplate.AssociatedDocumentTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var addDocumentTemplates = function (docTemps) {
        //setAssociatedTemplates([...tempArray, ...currentFeature.AssociatedTemplates]);
        var tempArray = [];
        docTemps.forEach(function (dt) {
            var newTBA = {
                GUID: generateGuid(),
                Required: true,
                Item: {
                    GUID: dt.GUID,
                    Title: dt.Title,
                    Description: dt.Description,
                    Category: dt.Category
                }
            };
            tempArray.push(newTBA);
        });
        if (props.currentLibraryTemplate.AssociatedDocumentTemplates !== undefined) {
            if (props.currentLibraryTemplate.AssociatedDocumentTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), props.currentLibraryTemplate.AssociatedDocumentTemplates, true);
            }
        }
        var compare = function (a, b) {
            if (a.Item !== undefined && b.Item !== undefined) {
                if (a.Item.Title !== undefined && b.Item.Title !== undefined) {
                    if (a.Item.Title < b.Item.Title) {
                        return -1;
                    }
                    if (a.Item.Title > b.Item.Title) {
                        return 1;
                    }
                }
            }
            return 0;
        };
        tempArray.sort(compare);
        dispatch(LibraryTemplateCurrentStore.Update({ AssociatedDocumentTemplates: tempArray }));
    };
    return (
    // <Container fluid={true}>
    React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement(TableList.Context, { asyncListLoadUID: props.currentLibraryTemplate.GUID !== undefined ? props.currentLibraryTemplate.GUID : "", listItems: props.currentLibraryTemplate.AssociatedDocumentTemplates ? props.currentLibraryTemplate.AssociatedDocumentTemplates : [], showLoader: false, wrappedInPortlet: false },
                React.createElement(TableList.TableList, { tstyle: "border-bottom-on" },
                    React.createElement(TableList.HeaderItem, null, "Type"),
                    React.createElement(TableList.HeaderItem, null, "Title"),
                    React.createElement(TableList.HeaderItem, null, "Description"),
                    React.createElement(TableList.HeaderItem, null, "Category"),
                    React.createElement(TableList.HeaderItem, { type: "centered", icon: faLock }, "Required"),
                    React.createElement(TableList.HeaderItem, { type: "actions" }),
                    React.createElement(TableList.ContentItemDocumentFileType, { listDataProperyName: "Item.Type" }),
                    React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Item.Title", isMobileTitle: true }),
                    React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Item.Description" }),
                    React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Item.Category" }),
                    React.createElement(TableList.ContentItemToggle, { listDataProperyName: "Required", handleChange: function (item, checked) { return toggleRequired(item, checked); } }),
                    React.createElement(TableList.ContentItemActions, { editNavLinkTo: "/workspaces/document-templates/edit/{Item.GUID}", onRemove: function (item) { return removeFeature(item); } }))),
            React.createElement(DocumentsModal, { currentTemplate: props.currentLibraryTemplate, associatedDocumentTemplates: props.currentLibraryTemplate.AssociatedDocumentTemplates, handleSelected: function (docTemps) {
                    addDocumentTemplates(docTemps);
                }, isLibrary: true })))
    // </Container>
    );
});
export default TabDocuments;
