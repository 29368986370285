import { Grid } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { ExchangeTile } from "./tiles/exchange-tile.component";
import { OneDriveTile } from "./tiles/one-drive-tile.component";
import { SharepointTile } from "./tiles/sharepoint-tile.component";
import { TenantTile } from "./tiles/tenant-tile.component";
export var StorageAnalysisSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading;
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.StorageAnalysis, renderListOfAnnotations: true, sectionTitle: React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.storage_analysis_section.title", defaultMessage: "Storage Analysis" }) }) },
        React.createElement(Grid, { mt: 8, grow: true, gutter: "16px" },
            React.createElement(Grid.Col, { span: 3 },
                React.createElement(TenantTile, null)),
            React.createElement(Grid.Col, { span: 3 },
                React.createElement(OneDriveTile, null)),
            React.createElement(Grid.Col, { span: 3 },
                React.createElement(ExchangeTile, null)),
            React.createElement(Grid.Col, { span: 3 },
                React.createElement(SharepointTile, null)))));
};
