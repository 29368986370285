import * as React from "react";
import "./step-card-policy-execution.scss";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current/";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import { ReviewRecurrence as Recurrence, ExecutionApprovers, GuestLifecycleTimeUnit, ReviewNoActionType, InactivityThresholdMap } from "app/store/lifecycle-guest-policies/types";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import InfoTooltip from "app/components/common/infotooltip";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import FormWebhook from "app/components/common/form-webhook";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import { initialReviewNotificationText } from "app/store/lifecycle-guest-policies/current/constants";
var StepCardPolicyExecution = React.memo(function (props) {
    var _a, _b, _c;
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "step-card-guest-review-policy-execution policy-card", "data-pw": "guest-review-policy-execution" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement("div", { className: "left-side card-border" },
                React.createElement("div", { className: "main-container" },
                    React.createElement(Form.Group, { className: "form-group", controlId: "recurrence" },
                        React.createElement("div", { className: "guest-review-container mx-0", "data-pw": "guest-review-container" },
                            React.createElement("h4", null, "Guest Review"),
                            React.createElement(Row, { className: "align-items-center", "data-pw": "review-type" },
                                React.createElement(Col, { md: 2 },
                                    React.createElement(Form.Label, null, "Review Type")),
                                React.createElement(Col, null,
                                    React.createElement("div", { className: "fixed-description", "data-pw": "fixed-description" },
                                        React.createElement("div", { className: "interval-text text" },
                                            React.createElement("i", { className: "fa-light fa-calendar-clock" }),
                                            React.createElement("div", { className: "label" }, "Fixed Interval")),
                                        React.createElement("p", { className: "text" }, "Policy will fire based on a fixed review schedule.")))),
                            React.createElement(Row, { className: "align-items-center", "data-pw": "review-recurrence" },
                                React.createElement(Col, { lg: 2 },
                                    React.createElement(Form.Label, null,
                                        "Review Recurrence ",
                                        React.createElement("span", { className: "required-astrix" }, "*"))),
                                React.createElement(Col, { lg: 6 },
                                    React.createElement("div", { className: "recurrence-container" },
                                        React.createElement(FormDropdownSelect, { list: [
                                                { id: "monthly", title: "30 days", value: Recurrence.Monthly },
                                                { id: "quarterly", title: "90 days", value: Recurrence.Quarterly },
                                                { id: "semiannually", title: "180 days", value: Recurrence.SemiAnnually },
                                                { id: "annually", title: "365 days", value: Recurrence.Annually }
                                            ], value: currentPolicy.GuestReviewRecurrence, onChange: function (value) {
                                                return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ GuestReviewRecurrence: value, InactivityThreshold: InactivityThresholdMap[value] }));
                                            } }),
                                        React.createElement(InfoTooltip, { placement: "right" }, "The policy will recur based on the selected interval, and will be first triggered that period from when the policy is applied.")),
                                    React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-recurrence", FieldName: "Recurrence", Validators: ["dropdown-has-value"], Value: currentPolicy.GuestReviewRecurrence, OnScreen: props.IsCurrent }))))),
                    React.createElement(Form.Group, { className: "form-group", controlId: "policy-approval" },
                        React.createElement("div", { className: "policy-approval-container mx-0", "data-pw": "policy-approval-container" },
                            React.createElement("h4", null, "Policy Approvers"),
                            React.createElement(Row, { className: "align-items-center", "data-pw": "assign-send-to" },
                                React.createElement(Col, { lg: 2 },
                                    React.createElement("div", { className: "label" },
                                        "Assign and Send To ",
                                        React.createElement("span", { className: "required-astrix" }, "*"),
                                        " ")),
                                React.createElement(Col, { lg: 4 },
                                    React.createElement(Row, null,
                                        React.createElement(Col, null,
                                            React.createElement(FormDropdownSelect, { list: [
                                                    { id: "workspaceowners", title: "Workspace Owners", value: ExecutionApprovers.WorkspaceOwners },
                                                    { id: "specificusers", title: "Specific Users", value: ExecutionApprovers.SpecificUsers }
                                                ], onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewApprovers: value })); }, value: currentPolicy.ReviewApprovers }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-approver", FieldName: "Approver", Validators: ["dropdown-has-value"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewApprovers }))))),
                            React.createElement(Collapse, { in: currentPolicy.ReviewApprovers === ExecutionApprovers.SpecificUsers },
                                React.createElement("div", null,
                                    React.createElement(Row, { className: "align-items-center" },
                                        React.createElement(Col, null,
                                            React.createElement(FormUserGroupSelect, { allowMultiple: true, displayAddAsButton: true, onChange: function (groups) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewSpecificUsers: groups })); }, returnType: "usersAndGroups", defaultValue: currentPolicy.ReviewSpecificUsers }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-approver-specificusers", ErrorMessage: "Must select at least one approver", FieldName: "Approver", Validators: ["string-not-empty", "string-array-has-items"], Value: currentPolicy.ReviewSpecificUsers, OnScreen: props.IsCurrent, Enabled: currentPolicy.ReviewApprovers === ExecutionApprovers.SpecificUsers }))))),
                            React.createElement("div", null,
                                React.createElement(Row, { className: "align-items-center", "data-pw": "notification-text" },
                                    React.createElement(Col, null,
                                        React.createElement("div", { className: "label" },
                                            "Notification Text ",
                                            React.createElement("span", { className: "required-astrix" }, "*")),
                                        React.createElement(MarkdownEditor, { templateText: currentPolicy.ReviewNotificationText, onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNotificationText: value })); }, enabledStyles: { all: true }, initialTextString: initialReviewNotificationText }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-notificationtext", FieldName: "Notification Text", Validators: ["string-not-empty"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewNotificationText }))),
                                React.createElement("hr", null),
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "form-group", controlId: "retry-cadence" },
                                            React.createElement("div", { className: "retry-cadence-container mx-0", "data-pw": "retry-cadence-container" },
                                                React.createElement("h6", null, "Notification Cadence"),
                                                React.createElement(Row, { className: "align-items-center" },
                                                    React.createElement(Col, null,
                                                        React.createElement("div", { className: "label" },
                                                            "Send Notification every ",
                                                            React.createElement("span", { className: "required-astrix" }, "*")))),
                                                React.createElement(Row, { className: "align-items-center" },
                                                    React.createElement(Col, null,
                                                        React.createElement(Row, { className: "align-items-center" },
                                                            React.createElement(Col, { lg: 6 },
                                                                React.createElement(Form.Control, { type: "number", onChange: function (e) {
                                                                        return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNotificationRetryCadence: Number(e.target.value) }));
                                                                    }, min: 0, value: ((_a = currentPolicy.ReviewNotificationRetryCadence) === null || _a === void 0 ? void 0 : _a.toString()) || undefined, placeholder: "quantity" }),
                                                                React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-retrycadence", FieldName: "Retry Cadence Quantity", Validators: ["number-not-empty"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewNotificationRetryCadence })),
                                                            React.createElement(Col, { lg: 6 },
                                                                React.createElement(FormDropdownSelect, { list: [
                                                                        { id: "days", title: "Days", value: GuestLifecycleTimeUnit.Days },
                                                                        { id: "weeks", title: "Weeks", value: GuestLifecycleTimeUnit.Weeks },
                                                                        { id: "months", title: "Months", value: GuestLifecycleTimeUnit.Months }
                                                                    ], onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNotificationRetryCadenceUnit: value })); }, value: currentPolicy.ReviewNotificationRetryCadenceUnit, placeholder: "units" }),
                                                                React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-retrycadenceunit", FieldName: "Retry Cadence Unit", Validators: ["dropdown-has-value"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewNotificationRetryCadenceUnit }))))),
                                                React.createElement(Row, { className: "align-items-center" },
                                                    React.createElement(Col, null,
                                                        React.createElement("span", { className: "retry-container" },
                                                            React.createElement("div", { className: "label" }, "Try"),
                                                            React.createElement(Form.Control, { type: "number", min: 0, onChange: function (e) {
                                                                    return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewMaximumNotificationRetries: Number(e.target.value) }));
                                                                }, value: ((_b = currentPolicy.ReviewMaximumNotificationRetries) === null || _b === void 0 ? void 0 : _b.toString()) || undefined, placeholder: "quantity" }),
                                                            React.createElement("div", { className: "label" },
                                                                "times ",
                                                                React.createElement("span", { className: "required-astrix" }, "*"))),
                                                        React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-maxretries", FieldName: "Maximum Number of Retries", Validators: ["number-not-empty"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewMaximumNotificationRetries })))))))))),
                    React.createElement(Form.Group, { className: "form-group", controlId: "no-action-taken" },
                        React.createElement("div", { className: "if-no-action-container mx-0", "data-pw": "no-action-container" },
                            React.createElement("h4", null, "If No Action Taken "),
                            React.createElement(Row, { className: "align-items-center" },
                                React.createElement(Col, null,
                                    React.createElement("div", { className: "escalate-to-container", "data-pw": "escalate-to-container" },
                                        React.createElement(Row, null,
                                            React.createElement("div", { className: "label" }, " Escalate to these users for manual intervention")),
                                        React.createElement(Row, null,
                                            React.createElement(Col, null,
                                                React.createElement(FormUserGroupSelect, { allowMultiple: true, displayAddAsButton: true, onChange: function (groups) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNoActionTakenEscalateTo: groups })); }, returnType: "usersAndGroups", defaultValue: currentPolicy.ReviewNoActionTakenEscalateTo })))))),
                            React.createElement(Row, { className: "align-items-center" },
                                React.createElement(Col, null,
                                    React.createElement("div", { className: "escalate-quantity-container", "data-pw": "escalate-quantity-container" },
                                        React.createElement(Row, { className: "align-items-center" },
                                            React.createElement(Col, { lg: 1 },
                                                React.createElement("div", { className: "label" },
                                                    "After ",
                                                    React.createElement("span", { className: "required-astrix" }, "*"))),
                                            React.createElement(Col, { lg: 2 },
                                                React.createElement("span", null,
                                                    React.createElement(Form.Control, { type: "number", placeholder: "quantity", min: 0, onChange: function (e) {
                                                            return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNoActionTakenAfterNumber: Number(e.target.value) }));
                                                        }, value: ((_c = currentPolicy.ReviewNoActionTakenAfterNumber) === null || _c === void 0 ? void 0 : _c.toString()) || undefined })),
                                                React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-noactiontakenafternumber", FieldName: "No Action Taken After Quantity", Validators: ["number-not-empty"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewNoActionTakenAfterNumber })),
                                            React.createElement(Col, { lg: 2 },
                                                React.createElement(FormDropdownSelect, { list: [
                                                        { id: "days", title: "Days", value: GuestLifecycleTimeUnit.Days },
                                                        { id: "weeks", title: "Weeks", value: GuestLifecycleTimeUnit.Weeks },
                                                        { id: "months", title: "Months", value: GuestLifecycleTimeUnit.Months }
                                                    ], onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNoActionTakenAfterUnit: value })); }, value: currentPolicy.ReviewNoActionTakenAfterUnit, placeholder: "units" }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-noactiontakenafterunit", FieldName: "No Action Taken After Unit", Validators: ["dropdown-has-value"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewNoActionTakenAfterUnit })),
                                            React.createElement(Col, null,
                                                React.createElement("div", { className: "label" }, "following the final notification, execute the associated Archival Actions"))),
                                        React.createElement(Row, null,
                                            React.createElement(Col, null,
                                                React.createElement(Row, { className: "align-items-center" },
                                                    React.createElement(Col, { lg: 1 },
                                                        React.createElement("div", { className: "label" },
                                                            "Then ",
                                                            React.createElement("span", { className: "required-astrix" }, "*"))),
                                                    React.createElement(Col, { lg: 5 },
                                                        React.createElement(FormDropdownSelect, { list: [
                                                                { id: "removeallnonreviewed", title: "Remove all non-reviewed guests from group", value: ReviewNoActionType.RemoveNonReviewed },
                                                                { id: "leaveallnonreviewed", title: "Leave all non-reviewed guests in place", value: ReviewNoActionType.LeaveNonReviewed }
                                                            ], onChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNoActionTakenActionType: value })); }, value: currentPolicy.ReviewNoActionTakenActionType, placeholder: "action type" }),
                                                        React.createElement(FormValidator, { UniqueIdentifier: "stepcardexecutionpolicy-noactiontakenactiontype", FieldName: "No Action Taken Action Type", Validators: ["dropdown-has-value"], OnScreen: props.IsCurrent, Value: currentPolicy.ReviewNoActionTakenActionType })))))))))),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement("div", { className: "webhook-container", "data-pw": "webhook-container" },
                                    React.createElement(Row, null,
                                        React.createElement(Col, null,
                                            React.createElement("h6", null, "Call Webhook"),
                                            React.createElement(FormWebhook, { OnWebhookSecretChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNoActionTakenWebhookSecret: value })); }, OnWebhookUrlChange: function (value) { return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ ReviewNoActionTakenWebhookUrl: value })); }, webhookSecret: currentPolicy.ReviewNoActionTakenWebhookSecret || null, webhookUrl: currentPolicy.ReviewNoActionTakenWebhookUrl || null, exampleJson: {
                                                    Secret: "",
                                                    WorkspaceName: "",
                                                    WorkspaceType: "",
                                                    Action: "",
                                                    UserId: "",
                                                    TenantGuid: "",
                                                    GroupId: "",
                                                    SiteId: "",
                                                    SiteUrl: "",
                                                    PolicyName: "",
                                                    IsTeam: true
                                                } }))))))))))));
});
export default StepCardPolicyExecution;
