var getEnvironmentName = function () {
    if (document.location.href.indexOf("app-dev-") > -1) {
        var host = window.location.host;
        var subdomain = host.split(".")[0];
        return "Dev " + subdomain.replace("app-dev-", "");
    }
    else if (document.location.href.indexOf("app-dev") > -1) {
        return "Dev 01";
    }
    else if (document.location.href.indexOf("app-qa-") > -1) {
        var host = window.location.host;
        var subdomain = host.split(".")[0];
        return "QA " + subdomain.replace("app-qa-", "");
    }
    else if (document.location.href.indexOf("app-qa") > -1) {
        return "QA 01";
    }
    else if (document.location.href.indexOf("app-beta-") > -1) {
        var host = window.location.host;
        var subdomain = host.split(".")[0];
        return "Beta " + subdomain.replace("app-beta-", "");
    }
    else if (document.location.href.indexOf("app-beta") > -1) {
        return "Beta 01";
    }
    else if (document.location.href.indexOf("ngrok") > -1) {
        return "Local";
    }
    return null;
};
export default getEnvironmentName;
