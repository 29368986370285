var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Group, Stack, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryFilterItems } from "appv2/components/orchestry-filter-selector/orchestry-filter-items/orchestry-filter-items.component";
import { OrchestryTextArea } from "appv2/components/orchestry-text-area/orchestry-text-area.component";
import React from "react";
import { useIntl } from "react-intl";
export var AddEditCustomRecommendationModal = function (_a) {
    var form = _a.form, appliedFilters = _a.appliedFilters, filterGroups = _a.filterGroups, updateFilterState = _a.updateFilterState, handleFormSubmit = _a.handleFormSubmit, isLoading = _a.isLoading, filterGroupErrors = _a.filterGroupErrors, context = _a.context, id = _a.id, title = _a.title, buttonLabel = _a.buttonLabel;
    var intl = useIntl();
    return (React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
        React.createElement(Stack, { gap: 16, ff: "var(--mantine-font-family)" },
            React.createElement(Group, { gap: 16 },
                React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", size: "lg", colorVariant: "primary-6", iconClassName: ORCH_ICONS.checkVerified02 }),
                React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, title)),
            React.createElement(Text, { c: "text-secondary.0", fz: "14px", fw: 300, lh: "20px" }, "Custom Recommendations will appear alongside Orchestry Recommendations but be differentiated using the Source column."),
            React.createElement("div", null,
                React.createElement(Group, { gap: 48 },
                    React.createElement(Stack, { gap: 16, w: 402 },
                        React.createElement(OrchestryTextArea, __assign({ value: form.getInputProps("DisplayName").value, placeholder: "Enter Name", c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("DisplayName").error }, form.getInputProps("DisplayName"), { label: "Name", maxLength: 80, description: "Max 80 Characters", autosize: true, minRows: 1, isLoading: isLoading })),
                        React.createElement(OrchestryTextArea, __assign({ value: form.getInputProps("Description").value, placeholder: "Enter Description", c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("Description").error }, form.getInputProps("Description"), { label: "Description", maxLength: 110, description: "Max 110 Characters", autosize: true, minRows: 2, isLoading: isLoading, skeletonHeight: 51 })),
                        React.createElement(OrchestryTextArea, __assign({ value: form.getInputProps("RecommendationAction").value, placeholder: "Enter Recommended Action", c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("RecommendationAction").error }, form.getInputProps("RecommendationAction"), { label: "Recommended Action", maxLength: 400, description: "Max 400 Characters", autosize: true, minRows: 2, isLoading: isLoading, skeletonHeight: 51 }))),
                    React.createElement(Stack, { gap: 16, w: 402 },
                        React.createElement(OrchestryFilterItems, { isLoading: isLoading, groupErrors: filterGroupErrors, filters: appliedFilters, onClick: updateFilterState, groups: filterGroups })))),
            React.createElement(Group, { gap: 12, justify: "right" },
                React.createElement(OrchestryButton, { disabled: isLoading, variant: "white", size: "sm", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "controls.cancel", defaultMessage: "Cancel" })),
                React.createElement(OrchestryButton, { disabled: isLoading, variant: "primary", size: "sm", type: "submit" }, buttonLabel)))));
};
