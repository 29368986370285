import { BulkEditActionTypes } from "./types";
import Store from "app/store";
import { cloneDeep, mergeWith } from "lodash";
export function Load() {
    return {
        type: BulkEditActionTypes.BulkEdit_Load,
        payload: {}
    };
}
export function UpdateCart(cartType, cartUpdates) {
    var state = Store.getState();
    var cart = cloneDeep(state.bulkEditState[cartType]);
    console.log("Action Update Cart", cart);
    console.log("Action Update Cart", mergeWith(cart, cartUpdates));
    return {
        type: BulkEditActionTypes.BulkEdit_UpdateCart,
        payload: mergeWith(cart, cartUpdates)
    };
}
