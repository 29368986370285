import { useGetRecommendationHistoryQuery } from "appv2/api/recommendations/recommendations.api";
import { DEFAULT_PAGE_SIZE } from "appv2/api/types/query-parameters.types";
import React from "react";
/**
 * A helper hook for the recommendation resolutions component
 * @param page The page to get the results for
 * @param tid The tenant id to get the results for
 */
export var useRecommendationResolutions = function (page, tid) {
    var _a = React.useState(1), activePage = _a[0], setActivePage = _a[1];
    var _b = React.useState(function () { var _a; return (_a = localStorage.getItem("recommendations-resultsPerPage")) !== null && _a !== void 0 ? _a : DEFAULT_PAGE_SIZE; }), resultsPerPage = _b[0], setResultsPerPage = _b[1];
    var _c = useGetRecommendationHistoryQuery({
        queryParameters: {
            filter: "EventType==\"AutoResolved\" || (Source==\"Custom\" && EventType==\"MarkedAsResolved\")",
            orderBy: "UpdatedDate desc",
            page: activePage,
            pageSize: Number(resultsPerPage)
        },
        tid: tid
    }), data = _c.data, isFetching = _c.isFetching;
    return {
        data: data,
        setResultsPerPage: setResultsPerPage,
        resultsPerPage: resultsPerPage,
        activePage: activePage,
        setActivePage: setActivePage,
        isFetching: isFetching
    };
};
