import { Card, Grid } from "@mantine/core";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import React from "react";
import { OrchestryCardTitle } from "../../orchestry-card-title.component";
import { useTenantPage } from "../../use-tenant-page.hook";
import { TenantMetricsLastAdminLogin } from "./tenant-metrics-last-admin-login.component";
import { TenantMetricsLoggedInPercentage } from "./tenant-metrics-logged-in-percentage";
import { TenantMetricsLoggedInUsers } from "./tenant-metrics-logged-in-users.component";
import { TenantMetricsStorage } from "./tenant-metrics-storage.component";
export var TenantMetrics = function () {
    var isPageLoading = useTenantPage().isPageLoading;
    var isMobile = useIsMobile();
    return (React.createElement(Card, { variant: "default", p: 24, h: "100%", miw: isMobile ? undefined : 572 },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Tenant Metrics/Activity" }),
        React.createElement(Grid, { grow: true, mt: 24, gutter: 24, columns: 2 },
            React.createElement(Grid.Col, { span: 1 },
                React.createElement(TenantMetricsLastAdminLogin, null)),
            React.createElement(Grid.Col, { span: 1 },
                React.createElement(TenantMetricsLoggedInUsers, null)),
            React.createElement(Grid.Col, { span: 1 },
                React.createElement(TenantMetricsLoggedInPercentage, null)),
            React.createElement(Grid.Col, { span: 1 },
                React.createElement(TenantMetricsStorage, null)))));
};
