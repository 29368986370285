import * as React from "react";
import "./lifecycle-guest-details-history.scss";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import moment from "moment";
import PrincipalItemDynamic from "app/components/common/principal-item-dynamic";
import { GuestManagementMembershipAction } from "app/store/lifecycle-guest";
import * as LifecycleGuestStore from "app/store/lifecycle-guest/current";
var LifecycleGuestDetailsHistory = React.memo(function (_a) {
    var type = _a.type, history = _a.history, count = _a.count, guestId = _a.guestId;
    var dispatch = useDispatch();
    var _b = React.useState(0), currentPage = _b[0], setCurrentPage = _b[1];
    var pageSize = React.useState(10)[0];
    var _c = React.useState(false), showNext = _c[0], setShowNext = _c[1];
    var _d = React.useState(false), showPrev = _d[0], setShowPrev = _d[1];
    var _e = React.useState(true), loading = _e[0], setLoading = _e[1];
    React.useEffect(function () {
        if (history !== null) {
            if (count !== null && currentPage * pageSize + (history === null || history === void 0 ? void 0 : history.length) < count) {
                setShowNext(true);
            }
            else {
                setShowNext(false);
            }
        }
        if (currentPage === 0) {
            setShowPrev(false);
        }
        else {
            setShowPrev(true);
        }
    }, [history]);
    var page = function (direction) {
        setLoading(true);
        if (direction === "next") {
            var nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(LifecycleGuestStore.LoadHistory(guestId, type, 10, nextPage * pageSize));
        }
        if (direction === "prev") {
            var nextPage = currentPage - 1;
            setCurrentPage(nextPage);
            dispatch(LifecycleGuestStore.LoadHistory(guestId, type, 10, nextPage * pageSize));
        }
    };
    return (React.createElement("div", { className: "lifecycle-guest-details-history", "data-pw": "lifecycle-guest-details-history" },
        React.createElement(Portlet, { className: "history-portlet" + (" portlet-type-" + type.toLowerCase()) },
            React.createElement(PortletHeader, { title: type === "creation" ? "Creation History" : type === "access" ? "Access History" : type === "review" ? "Review History" : "Review History", toolbar: React.createElement("div", { className: "history-count", "data-pw": "history-count" },
                    React.createElement("div", null, count)) }),
            React.createElement(PortletBody, { className: "" }, history !== null ? (history.length === 0 ? (React.createElement("div", { className: "no-results" },
                React.createElement("i", { className: "fa fa-history", "aria-hidden": "true" }),
                React.createElement("div", null, "There isn't any history to display here yet!"))) : (React.createElement(React.Fragment, null,
                React.createElement("div", null, history.map(function (his) { return (React.createElement(LifecycleGuestDetailsHistoryItem, { hist: his })); })),
                React.createElement("div", { className: "paging" },
                    React.createElement("div", { className: "paging-context" },
                        guestId !== null && showPrev === true ? (React.createElement("div", { className: "prev", onClick: function () { return page("prev"); } },
                            React.createElement("i", { className: "fa fa-chevron-left" }))) : null,
                        guestId !== null && showNext === true ? (React.createElement("div", { className: "next", onClick: function () { return page("next"); } },
                            React.createElement("i", { className: "fa fa-chevron-right" }))) : null))))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 }))))));
});
export default LifecycleGuestDetailsHistory;
var LifecycleGuestDetailsHistoryItem = React.memo(function (_a) {
    var hist = _a.hist;
    var getIcon = function (hist) {
        return hist.Action === GuestManagementMembershipAction.CreatedUser ? (React.createElement("div", { className: "icon icon-green" },
            React.createElement("i", { className: "fa fa-certificate" }))) : hist.Action === GuestManagementMembershipAction.Deleted ? (React.createElement("div", { className: "icon icon-red" },
            React.createElement("i", { className: "fa fa-trash" }))) : hist.Action === GuestManagementMembershipAction.GroupAccessRequested ? (React.createElement("div", { className: "icon icon-blue" },
            React.createElement("i", { className: "fa fa-sync" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewInitiated ? (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-sync" }))) : hist.Action === GuestManagementMembershipAction.GroupAccessRejected ? (React.createElement("div", { className: "icon icon-red" },
            React.createElement("i", { className: "fa fa-times" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewReject ? (React.createElement("div", { className: "icon icon-red" },
            React.createElement("i", { className: "fa fa-times" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewNoActionExecuted ? (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-business-time" }))) : hist.Action === GuestManagementMembershipAction.GroupAccessGranted ? (React.createElement("div", { className: "icon icon-green" },
            React.createElement("i", { className: "fa fa-thumbs-up" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewRenewed ? (React.createElement("div", { className: "icon icon-green" },
            React.createElement("i", { className: "fa fa-thumbs-up" }))) : hist.Action === GuestManagementMembershipAction.GroupAccessAutomatic ? (React.createElement("div", { className: "icon icon-green" },
            React.createElement("i", { className: "fa fa-thumbs-up" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewNotificationNoAction ? (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-clock" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewNotification ? (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === GuestManagementMembershipAction.GroupAccessNotification ? (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === GuestManagementMembershipAction.GroupAccessNotificationNoAction ? (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-bell" }))) : hist.Action === GuestManagementMembershipAction.InviteResent ? (React.createElement("div", { className: "icon icon-yellow" },
            React.createElement("i", { className: "fa fa-paper-plane" }))) : hist.Action === GuestManagementMembershipAction.GroupReviewReset ? (React.createElement("div", { className: "icon icon-yellow" },
            React.createElement("i", { className: "fa fa-circle-dashed" }))) : (React.createElement("div", { className: "icon icon-purple" },
            React.createElement("i", { className: "fa fa-bell" })));
    };
    var getTitle = function (hist) {
        var workspaceLink = (React.createElement(NavLink, { exact: true, to: "/lifecycle/listing/" + hist.SiteId, className: "ws-name" }, hist.WorkspaceName));
        var title = hist.Action === GuestManagementMembershipAction.CreatedUser ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Guest Created"))) : hist.Action === GuestManagementMembershipAction.Deleted ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Guest Deleted"))) : hist.Action === GuestManagementMembershipAction.InviteResent ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Resent Invite"))) : hist.Action === GuestManagementMembershipAction.GroupAccessAutomatic ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Granted Automatically"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupAccessGranted ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Granted"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupAccessNotification ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Notification Sent"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupAccessNotificationNoAction ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "No Action Taken Notification Sent"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupAccessRejected ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Rejected"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupAccessRequested ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Requested"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupAdded ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Added"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupRemoved ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Access Removed"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewInitiated ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Review Initiated"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewNoActionExecuted ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "No Action Taken Executed"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewNotification ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Review Notification Sent"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewNotificationNoAction ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Review No Action Taken Notification Sent"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewReject ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Review Rejected"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewRenewed ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Review Renewed"),
            " for ",
            workspaceLink)) : hist.Action === GuestManagementMembershipAction.GroupReviewReset ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "status-action" }, "Review Reset"),
            " for ",
            workspaceLink)) : ("");
        return React.createElement("div", null, title);
    };
    var getProperties = function (hist) {
        var showProperties = false;
        var propertyValues = [];
        if (hist.ExtendedProperties !== undefined && hist.ExtendedProperties !== null) {
            if (hist.ExtendedProperties.ReviewedById !== undefined && hist.ExtendedProperties.ReviewedById !== null) {
                // propertyValues.push(
                //    <div className="property-with-name">
                //       <div className="title">Reviewed By</div>
                //       <div className="property">
                //          <PrincipalItemDynamic principalIdOrEmail={hist.ExtendedProperties.ReviewedById} showBorder={false} size={"sm"} />
                //       </div>
                //    </div>
                // );
            }
            if (hist.ExtendedProperties.AutomaticallyAdded !== undefined && hist.ExtendedProperties.AutomaticallyAdded !== null) {
                propertyValues.push(React.createElement("div", { className: "property-with-name" },
                    React.createElement("div", { className: "title" }, "Automatically Added"),
                    React.createElement("div", { className: "property" }, hist.ExtendedProperties.AutomaticallyAdded)));
            }
        }
        if (hist.Comment !== null && hist.Comment !== "") {
            propertyValues.push(React.createElement("div", { className: "property-with-name" },
                React.createElement("div", { className: "title" }, "Comment"),
                React.createElement("div", { className: "property" }, hist.Comment)));
        }
        if (propertyValues.length > 0) {
            return (React.createElement("div", { className: "h-action" },
                React.createElement("div", { className: "history-details" }, propertyValues.map(function (element) { return (React.createElement(React.Fragment, null, element)); }))));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement("div", { className: "h-item" },
        React.createElement("div", { className: "h-top" },
            React.createElement("div", { className: "h-action-icon" }, getIcon(hist)),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "action-title" }, getTitle(hist)),
                React.createElement("div", { className: "user-and-date" },
                    React.createElement("span", { className: "con" }, "by "),
                    React.createElement("span", { className: "userspan" },
                        React.createElement(PrincipalItemDynamic, { principalIdOrEmail: hist.ExecutorUserGuid, showBorder: false })),
                    React.createElement("span", { className: "con" }, " on "),
                    React.createElement("span", { className: "datespan" }, moment
                        .utc(hist.Date)
                        .local()
                        .format("llll"))))),
        getProperties(hist)));
});
