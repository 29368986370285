import * as React from "react";
var ReportColumnPolicies = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = React.useState(props.Item), item = _g[0], setItem = _g[1];
    React.useEffect(function () {
        if (props.Item.ExtendedProperties !== undefined &&
            props.Item.ExtendedProperties !== null &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== undefined &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== null) {
            setItem(props.Item.ExtendedProperties.CosmosWorkspaceDetails);
        }
        else {
            setItem(props.Item);
        }
    }, [props.Item]);
    var DefaultArchivalPolicy = function (reportItem) {
        // console.log("DefaultArchivalPolicy lifecycleReport.DefaultArchivalPolicies", lifecycleReport.DefaultArchivalPolicies);
        var returnPolicy = null;
        if (props.LifecycleReport.DefaultArchivalPolicies.some(function (ap) { return ap.Application === reportItem.Application || (ap.Application === "TeamSite" && reportItem.Application === "VivaEngageCommunity"); })) {
            console.log("in if");
            returnPolicy = props.LifecycleReport.DefaultArchivalPolicies.find(function (ap) { return ap.Application === reportItem.Application || (ap.Application === "TeamSite" && reportItem.Application === "VivaEngageCommunity"); });
        }
        // console.log("DefaultArchivalPolicy returnPolicy", returnPolicy);
        return returnPolicy;
    };
    var DefaultGuestRequestPolicy = function (reportItem) {
        var returnPolicy = null;
        if (props.LifecycleReport.DefaultGuestRequestPolicy !== null) {
            returnPolicy = props.LifecycleReport.DefaultGuestRequestPolicy;
        }
        return returnPolicy;
    };
    var DefaultGuestReviewPolicy = function (reportItem) {
        var returnPolicy = null;
        if (props.LifecycleReport.DefaultGuestReviewPolicy !== null) {
            returnPolicy = props.LifecycleReport.DefaultGuestReviewPolicy;
        }
        return returnPolicy;
    };
    return (React.createElement("div", { className: "report-policies" },
        props.Type === "ArchivalPolicy" &&
            (item.ArchivalPolicyGuid !== null || (item.ArchivalPolicyGuid === null && DefaultArchivalPolicy && DefaultArchivalPolicy(item) !== null) || item.IsProtected === true) ? (React.createElement("div", { className: "policy-item" },
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "title" },
                    React.createElement("i", { className: "fa-light\r\n                      fa-box-archive" }),
                    " ",
                    "Renewal / Archival Policy"),
                React.createElement("div", { className: "value" }, item.IsProtected ? (React.createElement("div", { className: "protected" },
                    React.createElement("div", { className: "text" }, "Excluded from Archival"))) : item.ArchivalPolicyGuid !== null ? (React.createElement("a", { href: "/lifecycle/policies/archival/details/" + item.ArchivalPolicyGuid, target: "_blank" }, item.ArchivalPolicyName)) : item.ArchivalPolicyGuid === null && (DefaultArchivalPolicy === null || DefaultArchivalPolicy === void 0 ? void 0 : DefaultArchivalPolicy(item)) !== null ? (React.createElement("a", { href: "/lifecycle/policies/archival/details/" + ((_a = DefaultArchivalPolicy(item)) === null || _a === void 0 ? void 0 : _a.GUID), target: "_blank" }, (_b = DefaultArchivalPolicy(item)) === null || _b === void 0 ? void 0 : _b.Name)) : null)))) : null,
        props.Type === "GuestPolicy" && (item.GuestRequestPolicyGuid !== null || (item.GuestRequestPolicyGuid === null && DefaultGuestRequestPolicy && DefaultGuestRequestPolicy(item) !== null)) ? (React.createElement("div", { className: "policy-item" },
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "title" },
                    React.createElement("i", { className: "fa-light fa-user-plus" }),
                    " Guest Request Policy"),
                React.createElement("div", { className: "value" }, item.GuestRequestPolicyGuid !== null && item.GuestRequestPolicyName !== null ? (React.createElement("a", { href: "/guests/policies/edit/Request/" + item.GuestRequestPolicyGuid, target: "_blank" }, item.GuestRequestPolicyName)) : item.GuestRequestPolicyGuid === null && DefaultGuestRequestPolicy && DefaultGuestRequestPolicy(item) !== null ? (React.createElement("a", { href: "/guests/policies/edit/Request/" + ((_c = DefaultGuestRequestPolicy(item)) === null || _c === void 0 ? void 0 : _c.GUID), target: "_blank" }, (_d = DefaultGuestRequestPolicy(item)) === null || _d === void 0 ? void 0 : _d.Name)) : null)))) : null,
        props.Type === "GuestPolicy" && (item.GuestReviewPolicyGuid !== null || (item.GuestReviewPolicyGuid === null && DefaultGuestReviewPolicy && DefaultGuestReviewPolicy(item) !== null)) ? (React.createElement("div", { className: "policy-item" },
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "title" },
                    React.createElement("i", { className: "fa-light fa-user-check" }),
                    " Guest Review Policy"),
                React.createElement("div", { className: "value" }, item.GuestReviewPolicyGuid !== null && item.GuestReviewPolicyName !== null ? (React.createElement("a", { href: "/guests/policies/edit/Review/" + item.GuestReviewPolicyGuid, target: "_blank" }, item.GuestReviewPolicyName)) : item.GuestReviewPolicyGuid === null && DefaultGuestReviewPolicy && DefaultGuestReviewPolicy(item) !== null ? (React.createElement("a", { href: "/guests/policies/edit/Review/" + ((_e = DefaultGuestReviewPolicy(item)) === null || _e === void 0 ? void 0 : _e.GUID), target: "_blank" }, (_f = DefaultGuestReviewPolicy(item)) === null || _f === void 0 ? void 0 : _f.Name)) : null)))) : null,
        (props.Type === "ArchivalPolicy" &&
            item.ArchivalPolicyGuid === null &&
            item.ArchivalPolicyGuid === null &&
            (!DefaultArchivalPolicy || DefaultArchivalPolicy(item) === null) &&
            item.IsProtected !== true) ||
            (props.Type === "GuestPolicy" &&
                item.GuestRequestPolicyGuid === null &&
                (DefaultGuestRequestPolicy === undefined || DefaultGuestRequestPolicy(item) === null) &&
                item.GuestReviewPolicyGuid === null &&
                (DefaultGuestReviewPolicy === undefined || DefaultGuestReviewPolicy(item) === null)) ? (React.createElement("div", { className: "empty-policy" }, "-----")) : null));
});
export default ReportColumnPolicies;
