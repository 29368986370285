import * as LibraryFeatureCurrentStore from "app/store/library-features/current";
import { AsyncCallType } from "app/store/async/types";
export var libraryFeatureCurrentActionMap = [
    {
        actionType: LibraryFeatureCurrentStore.LibraryFeatureCurrentActionTypes.LibraryFeature_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "featureListState.items",
        keyType: "guid",
        ApiUrl: "/api/LibraryFeatures/?guid={key}",
        responseDataType: {}
    }
];
