import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
import { TabCount } from "./orchestry-tabs-count.component";
import "./orchestry-tabs-count.scss";
/**
 * Renders Tab Number Count or Alert Icon
 */
export var OrchestryTabsInfo = function (_a) {
    var tabConfig = _a.tabConfig, showCount = _a.showCount, tabCountVariant = _a.tabCountVariant;
    return tabConfig.showAlert ? (React.createElement(OrchestryFeaturedIcon, { variant: "light-circle", iconClassName: ORCH_ICONS.infoCircle, colorVariant: "orange-7", size: "xs" })) : (React.createElement(React.Fragment, null, showCount && !tabConfig.hideTabCount && React.createElement(TabCount, { variant: tabCountVariant, count: tabConfig.count || 0 })));
};
