var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { UserNotificationListActionTypes } from "./types";
import mergeArrays from "app/utils/mergeArray";
var initialState = {
    items: []
};
export var compare = function (a, b) {
    if (a.CreatedDate !== undefined && b.CreatedDate !== undefined) {
        if (a.CreatedDate.toLowerCase() > b.CreatedDate.toLowerCase()) {
            return -1;
        }
        if (a.CreatedDate.toLowerCase() < b.CreatedDate.toLowerCase()) {
            return 1;
        }
    }
    return 0;
};
export function userNotificationListReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UserNotificationListActionTypes.UserNotification_List_Load: {
            var tempListToLoad = void 0;
            if (state.items.length === 1)
                tempListToLoad = mergeArrays(action.payload, state.items, "GUID");
            else
                tempListToLoad = mergeArrays(state.items, action.payload, "GUID");
            //console.log("message", action.payload);
            return {
                items: [].concat(tempListToLoad.sort(compare))
            };
        }
        case UserNotificationListActionTypes.UserNotification_List_Refresh: {
            var tempListToLoad = void 0;
            // if(state.items.length === 1)
            //     tempListToLoad = mergeArrays(action.payload, state.items, "GUID");
            // else
            //tempListToLoad = mergeArrays(state.items, action.payload, "GUID");
            tempListToLoad = action.payload;
            //console.log("message", action.payload);
            return {
                items: [].concat(tempListToLoad.sort(compare))
            };
        }
        case UserNotificationListActionTypes.UserNotification_List_Load_Later_Than: {
            if (Array.isArray(action.payload)) {
                var tempListToLoad = void 0;
                if (state.items.length === 1)
                    tempListToLoad = mergeArrays(action.payload, state.items, "GUID");
                else
                    tempListToLoad = mergeArrays(state.items, action.payload, "GUID");
                return {
                    items: [].concat(tempListToLoad.sort(compare))
                };
            }
            else {
                return state;
            }
        }
        case UserNotificationListActionTypes.UserNotification_List_Item_Delete: {
            return {
                items: state.items.filter(function (feat) { return feat.GUID !== action.payload.GUID; })
            };
        }
        case UserNotificationListActionTypes.UserNotification_List_Item_Add: {
            var mergedNewArray = __spreadArray(__spreadArray([], state.items, true), [action.payload], false);
            mergedNewArray = mergedNewArray.sort(compare);
            return {
                items: mergedNewArray
            };
        }
        case UserNotificationListActionTypes.UserNotification_List_Item_Update: {
            var mergedNewArray = void 0;
            // Not loaded yet.
            if (state.items.length === 0) {
                mergedNewArray = [action.payload];
            }
            // List is in memory
            else {
                mergedNewArray = state.items.map(function (chan) { return (chan.GUID === action.payload.GUID ? Object.assign({}, chan, action.payload) : chan); });
                mergedNewArray = mergedNewArray.sort(compare);
            }
            return {
                items: mergedNewArray
            };
        }
        default:
            return state;
    }
}
