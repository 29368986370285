// intl.formatMessage({ id: "page.request_details.details.enter_name_placeholder", defaultMessage: "Enter Name" })
// {currentRequest.RequestType !== "SharePoint"
//? currentRequest.RequestType
//: currentTemplate.SharePointTemplateType === "ModernCommunications"
//? "CommunicationsSite"
//: "TeamSite"}
export var getWorkspaceTypeName = function (intl, requestType, sharepointType) {
    switch (requestType) {
        case "SharePoint":
            console.log(sharepointType);
            return sharepointType === "ModernGroup"
                ? intl.formatMessage({ id: "page.request_wizard.step_select.template_name.sharepointteamsite", defaultMessage: "Team Site" })
                : intl.formatMessage({ id: "page.request_wizard.step_select.template_name.sharepointcommsite", defaultMessage: "Communication Site" });
        case "Team":
            return intl.formatMessage({ id: "page.request_wizard.step_details.ms_team", defaultMessage: "Microsoft Team" });
        case "VivaEngageCommunity":
            return intl.formatMessage({ id: "page.request_wizard.ui.viva_engage", defaultMessage: "Viva Engage" });
        default:
            return "";
    }
};
