import * as React from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import DocumentIcon from "app/components/common/document-icon";
import { useIntl, FormattedMessage } from "react-intl";
var RequestDetailsDocumentTemplates = React.memo(function (props) {
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var intl = useIntl();
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.document_templates.title", defaultMessage: "Document Templates" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "kt-widget4" }, currentRequest.AssociatedDocumentTemplates !== undefined && currentRequest.AssociatedDocumentTemplates.length !== 0 ? (currentRequest.AssociatedDocumentTemplates.map(function (ass, assDocIndex) { return (React.createElement("div", { className: "kt-widget4__item", key: assDocIndex },
                React.createElement("div", { className: "kt-widget4__pic kt-widget4__pic--icon" },
                    React.createElement(DocumentIcon, { docType: ass.Item.Type })),
                React.createElement("span", { className: "kt-widget4__title" }, ass.Item.Title),
                React.createElement("div", { className: "kt-widget4__tools" },
                    React.createElement("span", { className: "btn btn-clean btn-icon btn-sm" },
                        React.createElement("i", { className: "flaticon2-download-symbol-of-down-arrow-in-a-rectangle" }))))); })) : (React.createElement("div", { className: "no-results" },
                "-- ",
                React.createElement(FormattedMessage, { id: "page.request_details.document_templates.no_document_templates", defaultMessage: "no document templates" }),
                " --"))))));
});
export default RequestDetailsDocumentTemplates;
