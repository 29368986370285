var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useForm } from "@mantine/form";
import { useGetRecommendationSettingsQuery, useUpdateRecommendationSettingsMutation } from "appv2/api/recommendations-settings/recommendations-settings.api";
import { getBaseTenantEntityInitialValues } from "appv2/api/types/base-tenant-entity.type";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
export var useRecommendationsSettingsPage = function () {
    var history = useHistory();
    var onBackButtonClicked = function () { return history.push("/recommendations"); };
    var _a = useGetRecommendationSettingsQuery(), recommendationSettings = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = useUpdateRecommendationSettingsMutation(), updateRecommendationSettings = _b[0], isSuccess = _b[1].isSuccess;
    var form = useForm({
        initialValues: __assign({ BrandingImage: "" }, getBaseTenantEntityInitialValues())
    });
    var isSaveDisabled = !form.isDirty() || isLoading;
    var isPageLoading = !recommendationSettings || isLoading;
    // Refetch the settings when the page is navigated away from and back to
    useEffect(function () {
        refetch();
    }, [refetch]);
    useEffect(function () {
        if (recommendationSettings) {
            form.setInitialValues(recommendationSettings);
            form.setValues(recommendationSettings);
        }
        // form is not a dependency because it is updated in the useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendationSettings]);
    /**
     * Handles the image drop event. Saves the base64 encoded image to the form.
     *
     * @param files The dropped files. Only the first file is used
     */
    var handleImageDrop = function (files) {
        var reader = new FileReader();
        reader.onload = function () {
            var fileData = reader.result;
            form.setFieldValue("BrandingImage", fileData);
        };
        reader.readAsDataURL(files[0]);
    };
    useEffect(function () {
        if (isSuccess) {
            OrchestrySuccessToast("Recommendations settings saved successfully");
        }
    }, [isSuccess]);
    var handleFormSubmit = function (values) {
        form.setInitialValues(values);
        updateRecommendationSettings(values);
    };
    return {
        form: form,
        onBackButtonClicked: onBackButtonClicked,
        handleFormSubmit: handleFormSubmit,
        handleImageDrop: handleImageDrop,
        isSaveDisabled: isSaveDisabled,
        isPageLoading: isPageLoading
    };
};
