import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { toNumber, toSimplePercentageLocaleString } from "appv2/utils/string.utils";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import "./orchestry-delta-text.scss";
export var OrchestryDeltaText = function (_a) {
    var deltaValue = _a.deltaValue, deltaSuffix = _a.deltaSuffix, deltaPercentage = _a.deltaPercentage, isLoading = _a.isLoading;
    var isDeltaNullOrUndefined = deltaValue === null || deltaValue === undefined;
    var delta = toNumber(deltaValue !== null && deltaValue !== void 0 ? deltaValue : "0");
    var isDeltaPercentageNullOrUndefined = deltaPercentage === null || deltaPercentage === undefined;
    var deltaPercentageValue = deltaPercentage !== null && deltaPercentage !== void 0 ? deltaPercentage : 0;
    return (React.createElement("div", { className: "orchestry-delta-text" }, delta > 0 || deltaPercentageValue > 0 ? (React.createElement(React.Fragment, null,
        React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-green-5)", isLoading: isLoading, fontSize: 15, lineHeight: "15px", fontWeight: 800, iconClassName: ORCH_ICONS.arrowUp }),
        React.createElement(OrchestryText, { lh: "24px", isLoading: isLoading, fw: 400, c: "var(--mantine-color-success-7)", fz: "12" },
            !isDeltaNullOrUndefined ? deltaValue.toLocaleString() + (deltaSuffix !== null && deltaSuffix !== void 0 ? deltaSuffix : "") : "",
            !isDeltaNullOrUndefined && !isDeltaPercentageNullOrUndefined ? " (".concat(toSimplePercentageLocaleString(deltaPercentage), "%)") : "",
            isDeltaNullOrUndefined && !isDeltaPercentageNullOrUndefined ? "".concat(toSimplePercentageLocaleString(deltaPercentage), "%") : ""))) : delta < 0 || deltaPercentageValue < 0 ? (React.createElement(React.Fragment, null,
        React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-red-5)", isLoading: isLoading, fontSize: 15, lineHeight: "15px", fontWeight: 800, iconClassName: ORCH_ICONS.arrowDown }),
        React.createElement(OrchestryText, { lh: "24px", isLoading: isLoading, fw: 400, c: "var(--mantine-color-error-7)", fz: "12" },
            !isDeltaNullOrUndefined ? deltaValue.toLocaleString() + (deltaSuffix !== null && deltaSuffix !== void 0 ? deltaSuffix : "") : "",
            !isDeltaNullOrUndefined && !isDeltaPercentageNullOrUndefined ? " (".concat(toSimplePercentageLocaleString(deltaPercentage), "%)") : "",
            isDeltaNullOrUndefined && !isDeltaPercentageNullOrUndefined ? "".concat(toSimplePercentageLocaleString(deltaPercentage), "%") : ""))) : (React.createElement(React.Fragment, null,
        React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-gray-5)", isLoading: isLoading, fontSize: 16, lineHeight: "16px", fontWeight: 500, iconClassName: ORCH_ICONS.Tilde }),
        React.createElement(OrchestryText, { lh: "24px", isLoading: isLoading, fw: 400, c: "var(--mantine-color-gray-7)", fz: "12" },
            !isDeltaNullOrUndefined ? deltaValue.toLocaleString() + (deltaSuffix !== null && deltaSuffix !== void 0 ? deltaSuffix : "") : "",
            !isDeltaNullOrUndefined && !isDeltaPercentageNullOrUndefined ? " (".concat(toSimplePercentageLocaleString(deltaPercentage), "%)") : "",
            isDeltaNullOrUndefined && !isDeltaPercentageNullOrUndefined ? "".concat(toSimplePercentageLocaleString(deltaPercentage), "%") : "")))));
};
