import { Container, Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useTenantPage } from "../../use-tenant-page.hook";
export var TenantMetricsLoggedInUsers = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Metrics, _c = _b === void 0 ? {} : _b, LoggedInUsersCount = _c.LoggedInUsersCount, isPageLoading = _a.isPageLoading;
    return (React.createElement(Container, { p: 0 },
        React.createElement(React.Fragment, null,
            React.createElement(Group, { gap: 8, align: "center" },
                React.createElement(OrchestryFeaturedIcon, { isLoading: isPageLoading, iconClassName: ORCH_ICONS.userCheck01, variant: "light-circle", colorVariant: "blue-7", size: "sm" }),
                React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, "Logged In Users")),
            React.createElement(Group, { mt: 8, gap: 4, align: "center" },
                React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-secondary.0", size: "16px", fw: 700, lh: "24px" }, LoggedInUsersCount === null || LoggedInUsersCount === void 0 ? void 0 : LoggedInUsersCount.toLocaleString()),
                React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, "(Last 30 Days)")))));
};
