var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./metadata-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as MetadataListStore from "app/store/metadata/list";
import * as MetadataCurrentStore from "app/store/metadata/current";
import generateGuid from "app/utils/generateGuid";
import { Row, Col, Form, Container } from "react-bootstrap";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { MetadataFieldTypes, MetadataSingleMultiples } from "app/store/metadata/types";
import FormToggleControl from "app/components/common/form-toggle-control";
import FontIcon from "app/components/common/font-icon";
import { SubtitleButton } from "app/components/common/subtitle";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import SharePointService from "app/services/sharepoint";
import AnimatedLoader from "app/components/common/animated-loader";
import ManagedMetadataPicker from "app/components/common/mananged-metadata-picker";
import DisableArea from "app/components/common/disable-area";
import MetadataService from "app/services/metadata";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakePortletForm } from "app/components/common/skeleton-loaders";
var MetadataDetails = React.memo(function (props) {
    var dispatch = useDispatch();
    var lookupGuid = props.guid;
    var currentMetadata = useSelector(function (state) { return state.metadataCurrentState.item; });
    var listMetadata = useSelector(function (state) { return state.metadataListState.items; });
    var _a = React.useState(), choiceValues = _a[0], setChoiceValues = _a[1];
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var _c = React.useState(false), showChoiceValueSave = _c[0], setShowChoiceValueSave = _c[1];
    var _d = React.useState(false), formValid = _d[0], setFormValid = _d[1];
    var _e = React.useState(false), nameIsDuplicate = _e[0], setNameIsDuplicate = _e[1];
    var _f = React.useState(false), nameDuplicateLoading = _f[0], setNameDuplicateLoading = _f[1];
    var _g = React.useState(false), isChoiceDuplicate = _g[0], setIsChoiceDuplicate = _g[1];
    var _h = React.useState(false), hasClickedSave = _h[0], setHasClickedSave = _h[1];
    var _j = React.useState(true), isLoading = _j[0], setIsLoading = _j[1];
    var _k = React.useState(null), checkForRequiredOnLoad = _k[0], setCheckForRequiredOnLoad = _k[1];
    var _l = React.useState(undefined), isUsedInNamingPolicy = _l[0], setIsUsedInNamingPolicy = _l[1];
    var metadataFieldTypes = MetadataFieldTypes.map(function (ft, index) { return ({
        id: index + "",
        title: ft.Title,
        value: ft.Value,
        icon: ft.Icon
    }); });
    var metadataSingleMultiples = MetadataSingleMultiples.map(function (sm, index) { return ({
        id: index + "",
        title: sm.Title,
        value: sm.Value,
        icon: sm.Icon
    }); });
    console.log("Metadata - Details - currentMetadata", currentMetadata);
    // Should only be run once.
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (currentMetadata.GUID !== undefined) {
            dispatch(MetadataCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            var guid = generateGuid();
            dispatch(MetadataCurrentStore.Update({ GUID: guid, Position: listMetadata.length + 1, ShowInDetailsForm: true, SingleMultiple: "Single", RequiredInDetailsForm: true, ShowInTITab: true }));
        }
        else {
            setCheckForRequiredOnLoad(true);
            // Load the /edit/guid load.
            dispatch(MetadataCurrentStore.Load({ GUID: lookupGuid }));
            // AsyncWait(AsyncCallType.Get, AsyncStatus.Succeeded, lookupGuid).then(() => {
            //    if(currentMetadata)
            // })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid]);
    var clearCurrentMetadata = function () {
        dispatch(MetadataCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(MetadataCurrentStore.Clear());
        };
    }, []);
    var saveAndAddModifyMetadata = function () {
        if (hasClickedSave === false) {
            setHasClickedSave(true);
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                // First, add the new template to template list
                asyncCallType = AsyncCallType.Add;
                dispatch(MetadataListStore.AddItem(currentMetadata));
            }
            else {
                dispatch(MetadataListStore.UpdateItem(currentMetadata));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentMetadata.GUID).then(function () {
                dispatch(MetadataListStore.Clear());
                setBackToList(true);
                dispatch(MetadataCurrentStore.Clear());
            });
        }
    };
    // If the choices come back filled, then update them.
    React.useEffect(function () {
        console.log("Choice Values", currentMetadata.Values, choiceValues);
        if (currentMetadata.Values !== undefined) {
            if (currentMetadata.Values !== null) {
                if (choiceValues === undefined) {
                    setChoiceValues(currentMetadata.Values.split("\r\n"));
                }
            }
        }
        if (currentMetadata.GUID !== undefined) {
            setIsLoading(false);
            if (checkForRequiredOnLoad === true) {
                if (currentMetadata.RequiredInDetailsForm === true) {
                    setCheckForRequiredOnLoad(false);
                    setIsUsedInNamingPolicy(null);
                    MetadataService.IsUsedInNamingPolicy(currentMetadata.GUID).then(function (isUsedInPolicy) {
                        setIsUsedInNamingPolicy(isUsedInPolicy);
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMetadata]);
    var updateChoiceValue = function (value, index) {
        //@ts-ignore
        var newValues = __spreadArray([], choiceValues, true);
        newValues[index] = value;
        setChoiceValues(newValues);
        dispatch(MetadataCurrentStore.Update({ Values: newValues.join("\r\n") }));
    };
    var addChoiceValue = function () {
        var newValues = [];
        if (choiceValues !== undefined) {
            newValues = __spreadArray([], choiceValues, true);
        }
        newValues.push("");
        setChoiceValues(newValues);
        var waitCount = 0;
        var waitForNewFieldAdded = function (indexWaitingFor) {
            waitCount++;
            if (waitCount < 20) {
                setTimeout(function () {
                    var newInput = document.getElementById("choice-number-" + indexWaitingFor);
                    //console.log("newInput", "choice-number-"+ indexWaitingFor, newInput);
                    if (newInput === null) {
                        waitForNewFieldAdded(indexWaitingFor);
                    }
                    else {
                        newInput.focus();
                    }
                }, 200);
            }
        };
        waitForNewFieldAdded(newValues.length - 1);
    };
    var removeChoiceValue = function (value) {
        var newValues = [];
        if (choiceValues.length > 1) {
            newValues = choiceValues.filter(function (cv) { return cv !== value; });
        }
        setChoiceValues(newValues);
        dispatch(MetadataCurrentStore.Update({ Values: newValues.join("\r\n") }));
    };
    var isDirectoryFilterDisabled = function () {
        return currentMetadata.FieldType !== undefined &&
            ((currentMetadata.SingleMultiple !== undefined && currentMetadata.SingleMultiple === "Multiple" && currentMetadata.FieldType === "Text") ||
                (currentMetadata.SingleMultiple !== undefined && currentMetadata.SingleMultiple === "Multiple" && currentMetadata.FieldType === "Choice") ||
                (currentMetadata.SingleMultiple !== undefined && currentMetadata.SingleMultiple === "Multiple" && currentMetadata.FieldType === "ManagedMetadata"))
            ? true
            : false;
    };
    var isDisabled = function () {
        return lookupGuid === undefined ? false : true;
    };
    //disableFilterIfMultiple
    React.useEffect(function () {
        if (isDirectoryFilterDisabled()) {
            console.log("currentMetadata.ShowAsFilterInDirectory", currentMetadata.ShowAsFilterInDirectory);
            if (currentMetadata.ShowAsFilterInDirectory === true) {
                dispatch(MetadataCurrentStore.Update({ ShowAsFilterInDirectory: false }));
            }
        }
    }, [currentMetadata]);
    React.useEffect(function () {
        var isDup = false;
        if (currentMetadata.FieldType === "Choice" && choiceValues !== undefined) {
            //@ts-ignore
            choiceValues.forEach(function (cv) {
                var howManyTime = 0;
                //@ts-ignore
                choiceValues.forEach(function (cvInside) {
                    if (cv === cvInside) {
                        howManyTime++;
                    }
                });
                if (howManyTime > 1) {
                    isDup = true;
                }
            });
        }
        console.log("Is Duplicate", isDup, choiceValues);
        setIsChoiceDuplicate(isDup);
    }, [currentMetadata.FieldType, choiceValues]);
    React.useEffect(function () {
        var tempIsNameDuplicate = false;
        if (lookupGuid === undefined && currentMetadata.Title !== undefined && currentMetadata.Title.length > 3) {
            setNameDuplicateLoading(true);
            SharePointService.ValidateOrchestryMetadataFieldName(currentMetadata.Title).then(function (isDuplicate) {
                console.log("Is Duplicate Field Name", isDuplicate);
                setNameIsDuplicate(isDuplicate);
                setNameDuplicateLoading(false);
            });
        }
        else {
            setNameIsDuplicate(tempIsNameDuplicate);
        }
    }, [currentMetadata.Title]);
    React.useEffect(function () {
        if (currentMetadata.ShowInDetailsForm === false && currentMetadata.RequiredInDetailsForm === true) {
            dispatch(MetadataCurrentStore.Update({ RequiredInDetailsForm: false }));
        }
    }, [currentMetadata.ShowInDetailsForm]);
    return (React.createElement("div", { className: "metadata-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "MetadataValidation" },
            backToList && React.createElement(Redirect, { to: "/workspaces/metadata" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Metadata", subTitle: props.guid ? "Edit" : "New", titleLink: "/workspaces/metadata" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/metadata", onClick: function () { return clearCurrentMetadata(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true && nameDuplicateLoading === false ? (React.createElement("div", { onClick: function () { return saveAndAddModifyMetadata(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            isLoading ? React.createElement(SkeletonLoaderFakePortletForm, { twoCols: true }) : null,
            currentMetadata.GUID !== undefined && (React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement("div", { className: "warning-wrapper" + (isDisabled() ? " show-warning" : "") },
                                    isDisabled() ? (React.createElement("div", { className: "alert alert-solid-warning alert-bold", role: "alert" },
                                        React.createElement("div", { className: "alert-icon" },
                                            React.createElement("i", { className: "flaticon-warning" })),
                                        React.createElement("div", { className: "alert-text" }, "Once a metadata field has been created you cannot update the name or type. If you delete this field you will lose all data collected."))) : null,
                                    React.createElement(Form.Group, { controlId: "control-title", className: "form-group title-field" },
                                        React.createElement(Form.Label, null,
                                            "Name ",
                                            React.createElement("span", { className: "required-astrix" }, "*")),
                                        React.createElement("div", { className: "text-input-field" },
                                            React.createElement(Form.Control, { placeholder: "Enter Name", defaultValue: currentMetadata.Title, disabled: isDisabled(), onChange: function (event) { return dispatch(MetadataCurrentStore.Update({ Title: event.target.value })); } }),
                                            nameDuplicateLoading === true ? React.createElement(AnimatedLoader, { size: "xxsmall" }) : null),
                                        React.createElement(FormValidator, { UniqueIdentifier: "name-is-duplicate", FieldName: "Name Is Duplicate", Validators: ["is-false"], ErrorMessage: "Field name is already in use. Please select another", Value: nameIsDuplicate }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-2", "no-illegal-characters"], Value: currentMetadata.Title })),
                                    isDisabled() === false ? (React.createElement(Form.Group, { className: "form-group", controlId: "control-instructions" },
                                        React.createElement(Form.Label, null, "Instructions (Optional)"),
                                        React.createElement(Form.Control, { placeholder: "Enter details to help user", defaultValue: currentMetadata.Instructions, disabled: isDisabled(), onChange: function (event) { return dispatch(MetadataCurrentStore.Update({ Instructions: event.target.value })); } }))) : null,
                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-field-type" },
                                        React.createElement(Form.Label, null,
                                            "Field Type ",
                                            React.createElement("span", { className: "required-astrix" }, "*")),
                                        React.createElement(FormDropdownSelect, { list: metadataFieldTypes, placeholder: "Select type", defaultValue: currentMetadata.FieldType, disabled: isDisabled(), onChange: function (value) { return dispatch(MetadataCurrentStore.Update({ FieldType: value })); } }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "field-type", Validators: ["dropdown-has-value"], Value: currentMetadata.FieldType })),
                                    (currentMetadata.FieldType !== undefined && (currentMetadata.FieldType === "Choice" || currentMetadata.FieldType === "Text")) ||
                                        currentMetadata.FieldType === "ManagedMetadata" ? (React.createElement(Form.Group, { className: "form-group", controlId: "control-single-multiple" },
                                        React.createElement(Form.Label, null, currentMetadata.FieldType === "Choice"
                                            ? "Single choice (radios) or multiple choices (checkboxes)"
                                            : currentMetadata.FieldType === "ManagedMetadata"
                                                ? "Allow the selection of single or multiple terms"
                                                : "Single line of text or multiple lines of text"),
                                        React.createElement(FormDropdownSelect, { list: metadataSingleMultiples, placeholder: "Single or Multiple", defaultValue: currentMetadata.SingleMultiple, disabled: isDisabled(), onChange: function (value) { return dispatch(MetadataCurrentStore.Update({ SingleMultiple: value })); } }))) : null),
                                isDisabled() === true ? (React.createElement(React.Fragment, null,
                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-instructions" },
                                        React.createElement(Form.Label, null, "Instructions (Optional)"),
                                        React.createElement(Form.Control, { placeholder: "Enter details to help user", defaultValue: currentMetadata.Instructions, onChange: function (event) { return dispatch(MetadataCurrentStore.Update({ Instructions: event.target.value })); } })))) : null,
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-position" },
                                    React.createElement(Form.Label, null, "Position"),
                                    React.createElement(Form.Control, { type: "number", name: "points", step: "1", defaultValue: currentMetadata.Position, onChange: function (event) { return dispatch(MetadataCurrentStore.Update({ Position: +event.target.value })); } })),
                                React.createElement("br", null),
                                React.createElement(FormToggleControl, { checked: currentMetadata.ShowInTITab === null ? true : currentMetadata.ShowInTITab, label: "Show in Orchestry Information Panel", handleChange: function (checked) { return dispatch(MetadataCurrentStore.Update({ ShowInTITab: checked })); } }),
                                isUsedInNamingPolicy !== undefined && isUsedInNamingPolicy === null ? (React.createElement("div", { style: { marginBottom: "2rem" } },
                                    " ",
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 41 }))) : (React.createElement("div", { className: "warning-wrapper" +
                                        (isUsedInNamingPolicy !== undefined &&
                                            isUsedInNamingPolicy !== null &&
                                            isUsedInNamingPolicy.Items !== undefined &&
                                            isUsedInNamingPolicy.Items !== null &&
                                            isUsedInNamingPolicy.Items.length > 0
                                            ? " show-warning"
                                            : "") },
                                    isUsedInNamingPolicy !== undefined &&
                                        isUsedInNamingPolicy !== null &&
                                        isUsedInNamingPolicy.Items !== undefined &&
                                        isUsedInNamingPolicy.Items !== null &&
                                        isUsedInNamingPolicy.Items.length > 0 ? (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "alert alert-solid-warning alert-bold", role: "alert" },
                                            React.createElement("div", { className: "alert-icon" },
                                                React.createElement("i", { className: "flaticon-warning" })),
                                            React.createElement("div", { className: "alert-text" }, "You cannot modify this Metadata's required status because it is being used in the following Naming Policies.")),
                                        React.createElement("ul", null, isUsedInNamingPolicy.Items.map(function (np) { return (React.createElement("li", null,
                                            React.createElement(NavLink, { exact: true, to: np.DetailsUrl }, np.Title))); })))) : null,
                                    React.createElement(DisableArea, { isDisabled: isUsedInNamingPolicy !== undefined &&
                                            isUsedInNamingPolicy !== null &&
                                            isUsedInNamingPolicy.Items !== undefined &&
                                            isUsedInNamingPolicy.Items !== null &&
                                            isUsedInNamingPolicy.Items.length > 0, useOverlay: true },
                                        React.createElement(FormToggleControl, { checked: currentMetadata.ShowInDetailsForm, label: "Show in Request Form", handleChange: function (checked) { return dispatch(MetadataCurrentStore.Update({ ShowInDetailsForm: checked })); } })),
                                    React.createElement(DisableArea, { isDisabled: !currentMetadata.ShowInDetailsForm ||
                                            (isUsedInNamingPolicy !== undefined &&
                                                isUsedInNamingPolicy !== null &&
                                                isUsedInNamingPolicy.Items !== undefined &&
                                                isUsedInNamingPolicy.Items !== null &&
                                                isUsedInNamingPolicy.Items.length > 0), useOverlay: true },
                                        React.createElement("div", null,
                                            React.createElement(FormToggleControl, { checked: currentMetadata.RequiredInDetailsForm, label: "Required in Request Form", handleChange: function (checked) { return dispatch(MetadataCurrentStore.Update({ RequiredInDetailsForm: checked })); } }))))),
                                currentMetadata.FieldType !== "Link" && (React.createElement("div", { className: "directory-filter" + (isDirectoryFilterDisabled() ? " is-disabled" : "") },
                                    React.createElement(FormToggleControl, { checked: currentMetadata.ShowAsFilterInDirectory, disabled: isDirectoryFilterDisabled(), label: "Show as Filter in Workspace Directory", handleChange: function (checked) { return dispatch(MetadataCurrentStore.Update({ ShowAsFilterInDirectory: checked })); } }),
                                    isDirectoryFilterDisabled() ? (React.createElement("div", { className: "alert alert-solid-warning alert-bold", role: "alert" },
                                        React.createElement("div", { className: "alert-icon" },
                                            React.createElement("i", { className: "flaticon-warning" })),
                                        React.createElement("div", { className: "alert-text" }, "The selected type being set as multiple is not supported as a filter."))) : null))),
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                currentMetadata.FieldType !== undefined && currentMetadata.FieldType === "ManagedMetadata" ? (React.createElement(React.Fragment, null,
                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-values" },
                                        React.createElement(Form.Label, null, "Term Set Settings"),
                                        React.createElement("p", null, "Select the first level of the hierarchy to show in the column. All levels below the term you select will be seen when users choose a value."),
                                        React.createElement(ManagedMetadataPicker, { allowMultiple: false, allowTextEntry: true, view: "tree-picker", mmdJsonValue: currentMetadata.Values, onChange: function (metadataJson) {
                                                dispatch(MetadataCurrentStore.Update({ Values: metadataJson }));
                                            } })),
                                    React.createElement(FormValidator, { UniqueIdentifier: "mmd-values-at-least-1", ErrorMessage: "Please select a starting term", Validators: ["array-has-items"], Value: currentMetadata.Values !== undefined && currentMetadata.Values !== null && currentMetadata.Values.indexOf("[") > -1 ? JSON.parse(currentMetadata.Values) : [] }))) : null,
                                currentMetadata.FieldType !== undefined && currentMetadata.FieldType === "Choice" ? (React.createElement("div", { className: "form-edit-multiple-values" + (currentMetadata.SingleMultiple !== undefined ? " " + currentMetadata.SingleMultiple.toLowerCase() : " hidethis") },
                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-values" },
                                        React.createElement(Form.Label, null, "Choice Value(s)"),
                                        React.createElement("div", { className: "values" },
                                            choiceValues !== undefined &&
                                                choiceValues.map(function (cv, index) { return (React.createElement("div", { className: "item", key: index },
                                                    React.createElement("div", { className: "icon" },
                                                        React.createElement("span", { className: "check" },
                                                            React.createElement(FontIcon, { iconString: "check-square" })),
                                                        React.createElement("span", { className: "dot" },
                                                            React.createElement(FontIcon, { iconString: "dot-circle" })),
                                                        React.createElement("span", { className: "remove", onClick: function () {
                                                                removeChoiceValue(cv);
                                                            } },
                                                            React.createElement(FontIcon, { iconString: "trash" }))),
                                                    React.createElement("input", { type: "text", id: "choice-number-" + index, onFocus: function () { return setShowChoiceValueSave(true); }, onBlur: function () { return setShowChoiceValueSave(false); }, placeholder: "Enter option text here", value: cv, onChange: function (event) {
                                                            updateChoiceValue(event.target.value, index);
                                                        } }),
                                                    React.createElement(FormValidator, { UniqueIdentifier: "choice-value-" + index, Validators: ["string-not-empty"], Value: cv }))); }),
                                            choiceValues === undefined || choiceValues.length === 0 ? React.createElement("div", { className: "no-choices" }, "No choices entered") : null,
                                            React.createElement("div", { className: "add" },
                                                React.createElement("div", { className: "button-custom b-orange", onClick: function () {
                                                        addChoiceValue();
                                                    } },
                                                    React.createElement(FontIcon, { iconString: "plus" }),
                                                    React.createElement("span", null, "Add Option")),
                                                showChoiceValueSave ? (React.createElement("div", { className: "button-custom b-green", style: { marginLeft: "15px" }, onClick: function (event) {
                                                        event.currentTarget.focus();
                                                    } },
                                                    React.createElement("span", null, "Save Option"))) : null)),
                                        React.createElement(FormValidator, { UniqueIdentifier: "choice-values-at-least-1", ErrorMessage: "Please add at least one choice", Validators: ["array-has-items"], Value: choiceValues }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "choice-values-twice", ErrorMessage: "Duplicate values are not allowed", Validators: ["is-true"], ShowFirst: true, Value: !isChoiceDuplicate })))) : null,
                                currentMetadata.FieldType !== undefined && (currentMetadata.FieldType === "Choice" || currentMetadata.FieldType === "ManagedMetadata") ? (React.createElement("div", { className: "naming-policy-help" },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: "text-help" },
                                            "If this metadata is used in a naming policy and you would like to use a different value in the ",
                                            React.createElement("strong", null, "name"),
                                            " or ",
                                            React.createElement("strong", null, "url"),
                                            " of the workspace you can add one in square brackets."),
                                        React.createElement("div", { className: "example" },
                                            React.createElement("div", { className: "value" }, "Human Resources [HR]"),
                                            React.createElement("div", { className: "explain" },
                                                React.createElement("span", { className: "highlight" }, "{department}"),
                                                "_example_workspace_url ",
                                                React.createElement("span", { className: "connector" }, ">"),
                                                " ",
                                                React.createElement("span", { className: "highlight" }, "hr"),
                                                "_example_workspace_url")),
                                        React.createElement("div", { className: "example" },
                                            React.createElement("div", { className: "value" }, "Marketing & Operations [MO]"),
                                            React.createElement("div", { className: "explain" },
                                                React.createElement("span", { className: "highlight" }, "{department}"),
                                                " - Workspace Name Example ",
                                                React.createElement("span", { className: "connector" }, ">"),
                                                " ",
                                                React.createElement("span", { className: "highlight" }, "MO"),
                                                " ",
                                                "- Workspace Name Example"))))) : null)))))))));
});
export default MetadataDetails;
