var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, Text } from "@mantine/core";
import { OrchestryPieChart } from "appv2/components/orchestry-pie-chart/orchestry-pie-chart.component";
import React, { useEffect, useState } from "react";
import "./recommendation-priority-metric.scss";
export var RecommendationPriorityMetric = function (props) {
    var _a = useState(props.value), selectedLabel = _a[0], setselectedLabel = _a[1];
    useEffect(function () {
        setselectedLabel(props.value);
    }, [props.value]);
    return (React.createElement(Card, { className: "recommendation-priority-metric-card", variant: selectedLabel ? "stats-card-selected" : "stats-card-hover" },
        React.createElement("div", { className: "recommendation-priority-metric-card-body" },
            React.createElement(Text, { fw: 500, c: "text-primary.0", size: "sm" }, "Priority"),
            React.createElement(OrchestryPieChart, __assign({}, props.pieChartConfig, { value: props.value, onClick: function (value) {
                    if ((value === null || value === void 0 ? void 0 : value.toLowerCase()) !== (selectedLabel === null || selectedLabel === void 0 ? void 0 : selectedLabel.toLowerCase())) {
                        setselectedLabel(value);
                        props.onClick(value);
                    }
                    else {
                        setselectedLabel(undefined);
                        props.onClick(undefined);
                    }
                } })))));
};
