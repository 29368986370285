import * as React from "react";
import "./form-searchbox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
var FormSearchbox = React.memo(function (props) {
    var _a = React.useState(""), searchTerm = _a[0], setSearchTerm = _a[1];
    var inputChange = function (event) {
        setSearchTerm(event.target.value);
        props.handler(event);
    };
    var clearSearch = function () {
        setSearchTerm("");
        if (props.clearButtonHandler !== undefined) {
            props.clearButtonHandler();
        }
    };
    return (React.createElement("div", { className: "searchBox" + (props.useAltStyle !== undefined && props.useAltStyle ? " alt-style" : ""), "data-pw": "searchBox" },
        React.createElement("input", { value: searchTerm, placeholder: props.placeholder, onChange: function (event) {
                inputChange(event);
            } }),
        searchTerm.length > 0 && props.clearButtonHandler !== undefined ? (React.createElement("div", { className: "icon-delete" },
            React.createElement(FontAwesomeIcon, { icon: faTimesCircle, onClick: function () {
                    clearSearch();
                } }))) : (React.createElement("div", { className: "icon-search" }, props.useAltStyle !== undefined && props.useAltStyle === true ? React.createElement("i", { className: "flaticon-search" }) : React.createElement(FontAwesomeIcon, { icon: faSearch })))));
});
export default FormSearchbox;
