import * as React from "react";
import "./step-card-name.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import FormSelectBox from "app/components/common/form-select-box";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import DisableArea from "app/components/common/disable-area";
import vivaIcon from "app/assets/img/icon-viva-engage.svg";
var StepCardName = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "step-card-name policy-card", "data-pw": "step-card-name" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement(Row, null,
                React.createElement(Col, { className: "" },
                    React.createElement("div", { className: "left-side card-border" },
                        React.createElement("h4", null, "Name"),
                        React.createElement(Form.Group, { className: "form-group", controlId: "policy-name" },
                            React.createElement(Form.Label, null,
                                "Name ",
                                React.createElement("span", { className: "required-astrix" }, "*")),
                            React.createElement(Form.Control, { placeholder: "Enter name", "data-pw": "name-input", autoComplete: "off", defaultValue: currentPolicy.Name !== null ? currentPolicy.Name : "", onChange: function (event) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Name: event.target.value })); } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "stepcardname-name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-3"], Value: currentPolicy.Name })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "description" },
                            React.createElement(Form.Label, null,
                                "Description ",
                                React.createElement("span", { className: "required-astrix" }, "*")),
                            React.createElement(Form.Control, { as: "textarea", rows: 3, placeholder: "Enter description", "data-pw": "description-input", autoComplete: "off", defaultValue: currentPolicy.Description !== null ? currentPolicy.Description : "", onChange: function (event) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Description: event.target.value })); } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "stepcardname-description", FieldName: "Description", Validators: ["string-not-empty", "string-is-more-than-3"], Value: currentPolicy.Description })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "application", style: { marginBottom: "0" } },
                            React.createElement(Form.Label, null,
                                "Application ",
                                React.createElement("span", { className: "required-astrix" }, "*")),
                            props.EditMode !== undefined && props.EditMode === true ? React.createElement("div", { className: "application-description" }, "You cannot change the application on a existing policy") : null,
                            React.createElement(DisableArea, { isDisabled: props.EditMode !== undefined && props.EditMode === true ? true : false, useOverlay: true },
                                React.createElement(FormSelectBox, { list: [
                                        {
                                            value: "Team",
                                            title: "Team",
                                            iconJsx: (React.createElement("div", { className: "custom-icon-office-logo" },
                                                React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-48.png" })))
                                        },
                                        {
                                            value: "TeamSite",
                                            title: "Team Site / Viva Engage",
                                            iconJsx: (React.createElement("div", { className: "custom-icon-office-logo teamsiteviva" },
                                                React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-48.png" }),
                                                React.createElement("img", { src: vivaIcon })))
                                        },
                                        {
                                            value: "CommunicationSite",
                                            title: "Communication Site",
                                            iconJsx: (React.createElement("div", { className: "custom-icon-office-logo" },
                                                React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-48.png" })))
                                        }
                                    ], value: currentPolicy.Application, allowMultiple: false, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Application: value })); } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "application", FieldName: "Application", Validators: ["dropdown-has-value"], Value: currentPolicy.Application })))))))));
});
export default StepCardName;
