import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { HealthCheckPage } from "appv2/pages/beacon/health-check/health-check/health-check-page.component";
import { GenerateNewHealthCheckPage } from "appv2/pages/beacon/health-checks/genererate-new-health-check/generate-new-health-check-page.component";
import { PartnerToolsPage } from "appv2/pages/partner-tools/partner-tools-page.component";
import { PartnerSettingsPage } from "appv2/pages/partner-tools/settings/partner-settings-page.component";
import { TenantPage } from "appv2/pages/partner-tools/tenant/tenant-page.component";
import React from "react";
import { Route, Switch } from "react-router-dom";
export default function PartnerToolsPages() {
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var canGenerateHealthCheck = currentUserHasRole(OrchRole.PartnerHubAdmin) || currentUserHasRole(OrchRole.PartnerTenantManager) || currentUserHasRole(OrchRole.PartnerAdmin);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/partner-tools", component: PartnerToolsPage }),
        React.createElement(Route, { exact: true, path: "/partner-tools/settings", component: PartnerSettingsPage }),
        React.createElement(Route, { exact: true, path: "/partner-tools/tenants/view/:tenantGuid", component: TenantPage }),
        React.createElement(Route, { exact: true, path: "/partner-tools/tenants/view/:tenantGuid/health-checks/view/:healthCheckGuid", component: HealthCheckPage }),
        canGenerateHealthCheck && React.createElement(Route, { exact: true, path: "/partner-tools/tenants/view/:tenantGuid/health-checks/new", component: GenerateNewHealthCheckPage })));
}
