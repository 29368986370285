import * as MantineComponents from "@mantine/core";
import { createTheme } from "@mantine/core";
import autocompleteVariantClasses from "./appv2/components/orchestry-autocomplete/orchestry-autocomplete.module.scss";
import avatarVariantClasses from "./appv2/components/orchestry-avatar/orchestry-avatar.module.scss";
import buttonVariantClasses from "./appv2/components/orchestry-button/orchestry-button.module.scss";
import cardVariantClasses from "./appv2/components/orchestry-card/orchestry-card.module.scss";
import checkBoxVariantClasses from "./appv2/components/orchestry-checkbox/orchestry-checkbox.module.scss";
import chipVariantClasses from "./appv2/components/orchestry-chip/orchestry-chip.module.scss";
import comboboxVariantClasses from "./appv2/components/orchestry-combobox/orchestry-combobox.module.scss";
import inputBaseVariantClasses from "./appv2/components/orchestry-input-base/orchestry-input-base.module.scss";
import inputPlaceholderVariantClasses from "./appv2/components/orchestry-input-placeholder/orchestry-input-placeholder.module.scss";
import inputVariantClasses from "./appv2/components/orchestry-input/orchestry-input.module.scss";
import modalVariantClasses from "./appv2/components/orchestry-modal/orchestry-modal.module.scss";
import paginationVariantClasses from "./appv2/components/orchestry-pagination/orchestry-pagination.module.scss";
import progressVariantClasses from "./appv2/components/orchestry-progress/orchestry-progress.module.scss";
import tabsVariantClasses from "./appv2/components/orchestry-tabs/orchestry-tabs.module.scss";
import textVariantClasses from "./appv2/components/orchestry-text/orchestry-text.module.scss";
export var MANTINE_LIGHT_COLORS = {
    "--azure-ad-entra-bg": "#016BBD0D",
    "--azure-ad-entra-text": "#016BBD",
    "--sharepoint-bg": "#047A7F0D",
    "--sharepoint-text": "#047A7F",
    "--teams-bg": "#4C54B80D",
    "--teams-text": "#4C54B8",
    "--defender-text": "#0250B7",
    "--viva-connections-bg": "#0250B70D",
    "--viva-connections-text": "#0250B7",
    "--viva-engage-bg": "#0250B70D",
    "--viva-engage-text": "#0250B7",
    "--onedrive-bg": "#0250B70D",
    "--onedrive-text": "#0250B7",
    "--background---layer": "#ffffff",
    "--background---menu": "#fdffff",
    "--background---modal": "#34405440",
    "--background---table-header": "#f7f7f7",
    "--black": "#121212",
    "--border": "#e5e7eb",
    "--white": "#ffffff"
};
export var MANTINE_DARK_COLORS = {
    "--azure-ad-entra-bg": "#016BBD0D",
    "--azure-ad-entra-text": "#016BBD",
    "--sharepoint-bg": "#047A7F0D",
    "--sharepoint-text": "#047A7F",
    "--teams-bg": "#4C54B80D",
    "--teams-text": "#4C54B8",
    "--defender-text": "#0250B7",
    "--viva-connections-bg": "#0250B70D",
    "--viva-connections-text": "#0250B7",
    "--viva-engage-bg": "#0250B70D",
    "--viva-engage-text": "#0250B7",
    "--onedrive-bg": "#0250B70D",
    "--onedrive-text": "#0250B7",
    "--background---layer": "#32333e",
    "--background---menu": "#282932",
    "--background---modal": "#00000080",
    "--background---table-header": "#262626",
    "--black": "#ffffff",
    "--border": "#606060",
    "--white": "#262626"
};
export var MANTINE_COLORS = {
    blue: ["#ebf5ff", "#e1effe", "#c3ddfd", "#a4cafe", "#76a9fa", "#3F83F8", "#1c64f2", "#1a56db", "#1e429f", "#233876"],
    brown: ["#fbf7ee", "#f3e7cb", "#ebd7a9", "#e3c886", "#dbb863", "#d3a841", "#be932c", "#9c7824", "#795d1c", "#564314"],
    error: ["#fef3f2", "#fee4e2", "#fecdca", "#fda29b", "#f97066", "#f04438", "#d92d20", "#b42318", "#912018", "#7a271a"],
    gray: ["#f9fafb", "#f3f4f6", "#e5e7eb", "#d1d5db", "#9ca3af", "#6b7280", "#4b5563", "#374151", "#1f2a37", "#111928"],
    indigo: ["#f0f5ff", "#e5edff", "#cddbfe", "#b4c6fc", "#8da2fb", "#6875f5", "#5850ec", "#5145cd", "#42389d", "#362f78"],
    magenta: ["#efe3f4", "#e0c8e8", "#d0afdd", "#c297d1", "#b381c6", "#a66dba", "#985aaf", "#8b49a3", "#7f3a98", "#732c8c"],
    orange: ["#fff6ed", "#ffead5", "#fddcab", "#feb273", "#fd853a", "#fb6514", "#ec4a0a", "#c4320a", "#9c2a10", "#7e2410"],
    pink: ["#fdf2fa", "#fce7f6", "#fcceee", "#faa7e0", "#f670c7", "#ee46bc", "#dd2590", "#c11574", "#9e165f", "#851651"],
    primary: ["#e6f2f3", "#cce4e7", "#b3d7db", "#99c9cf", "#80bcc4", "#66afb8", "#4da1ac", "#3394a0", "#1a8694", "#007988"],
    purple: ["#f6f5ff", "#edebfe", "#dcd7fe", "#cabffd", "#ac94fa", "#9061f9", "#7e3af2", "#6c2bd9", "#5521b5", "#4a1d96"],
    success: ["#ecfdf3", "#d1fadf", "#a6f4c5", "#6ce9a6", "#32d583", "#12b76a", "#039855", "#027a48", "#05603a", "#054f31"],
    warning: ["#fffaeb", "#fef0c7", "#fedf89", "#fec84b", "#fdb022", "#f79009", "#dc6803", "#b54708", "#93370d", "#7a2e0e"],
    "text-primary": ["#222222", "#222222", "#222222", "#222222", "#222222", "#fefefe", "#fefefe", "#fefefe", "#fefefe", "#fefefe"],
    "text-secondary": ["#444444", "#444444", "#444444", "#444444", "#444444", "#f9fafb", "#f9fafb", "#f9fafb", "#f9fafb", "#f9fafb"],
    "text-tertiary": ["#595959", "#595959", "#595959", "#595959", "#595959", "#f3f4f6", "#f3f4f6", "#f3f4f6", "#f3f4f6", "#f3f4f6"]
};
/**
 * When updating any values here, be sure to run the generate-css-vars script to update our intelisense css vars.
 */
export var resolver = function (theme) { return ({
    dark: MANTINE_DARK_COLORS,
    light: MANTINE_LIGHT_COLORS,
    variables: {
        "--orchestry-border-radius": theme.other.orchestryBorderRadius,
        "--light-font-weight": theme.other.lightFontWeight,
        "--regular-font-weight": theme.other.regularFontWeight,
        "--medium-font-weight": theme.other.mediumFontWeight,
        "--bold-font-weight": theme.other.boldFontWeight,
        "--mantine-font-size-dxs": theme.other.displayFontSizeXs,
        "--mantine-line-height-dxs": theme.other.displayLineHeightXs,
        "--input-bd-focus": "var(--mantine-color-primary-7)",
        "--mantine-color-error": "var(--mantine-color-error-6) !important",
        "--input-color": "var(--mantine-color-gray-5) !important",
        "--input-bd": "var(--mantine-color-gray-5) !important",
        "--mantine-color-placeholder": "var(--mantine-color-gray-5) !important"
    }
}); };
export var orchestryTheme = createTheme({
    /** Put your mantine theme override here */
    components: {
        Avatar: MantineComponents.Avatar.extend({ classNames: avatarVariantClasses }),
        Button: MantineComponents.Button.extend({ classNames: buttonVariantClasses }),
        Chip: MantineComponents.Chip.extend({ classNames: chipVariantClasses }),
        Tabs: MantineComponents.Tabs.extend({ classNames: tabsVariantClasses }),
        Card: MantineComponents.Card.extend({ classNames: cardVariantClasses }),
        Autocomplete: MantineComponents.Autocomplete.extend({ classNames: autocompleteVariantClasses }),
        Text: MantineComponents.Text.extend({ classNames: textVariantClasses }),
        Pagination: MantineComponents.Pagination.extend({ classNames: paginationVariantClasses }),
        Combobox: MantineComponents.Combobox.extend({ classNames: comboboxVariantClasses }),
        Input: MantineComponents.Input.extend({ classNames: inputVariantClasses }),
        InputBase: MantineComponents.InputBase.extend({ classNames: inputBaseVariantClasses }),
        InputPlaceholder: MantineComponents.Input.extend({ classNames: inputPlaceholderVariantClasses }),
        Modal: MantineComponents.Modal.extend({ classNames: modalVariantClasses }),
        Progress: MantineComponents.Progress.extend({ classNames: progressVariantClasses }),
        Checkbox: MantineComponents.Checkbox.extend({ classNames: checkBoxVariantClasses })
    },
    defaultRadius: "8px",
    colors: MANTINE_COLORS,
    primaryColor: "primary",
    /**
     * Gets multiplied by the rem value to set various calculations in mantine components. Scales from 13px to 16px
     */
    scale: 1.23,
    black: "text-primary.0",
    fontFamily: "Lexend, sans-serif",
    shadows: {
        xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        sm: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
        md: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
        lg: "0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10)",
        xl: "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
        "2xl": "0px 24px 48px -12px rgba(16, 24, 40, 0.25)",
        "3xl": "0px 32px 64px -12px rgba(16, 24, 40, 0.20)"
    },
    other: {
        orchestryBorderRadius: "8px",
        lightFontWeight: 300,
        regularFontWeight: 400,
        mediumFontWeight: 500,
        boldFontWeight: 700,
        displayFontSizeXs: "24px",
        displayLineHeightXs: "32px"
    }
});
