import "@mantine/charts/styles.css";
import React from "react";
import { OrchestryDeltaText } from "../orchestry-delta-text/orchestry-delta-text.component";
/**
 * Get the delta data for the item
 *
 * @param item The item to get the delta data for
 * @param lastPeriodData The data for the last period
 * @param label The label for the item
 */
var getDeltaData = function (item, lastPeriodData, label) {
    var yAxisName = item.name;
    //item.payload is like this for area charts
    // {
    //   Apples: 3129
    //   date: "Mar 26"
    // }
    // filter out the y axis, so we only have the x axis
    var keysArray = Object.keys(item.payload).filter(function (key) { return key !== yAxisName; }); //Should only be the x axis key for area charts
    // For the donut chart, the keysArray has many values and we can not use it, but there is only one data point for the yAxisName,
    // where as the area chart could have multiple data points for the yAxisName (2 values on the same date for example)
    var data = lastPeriodData === null || lastPeriodData === void 0 ? void 0 : lastPeriodData.filter(function (data) { return (!label || (keysArray.length === 1 && data[keysArray[0]] === label)) && data[yAxisName] === item.value; });
    return data[0];
};
/**
 * Check if the item has days ago data. If the value is null or undefined, it does not have days ago data
 * If the value is a number, including 0, it has days ago data.
 * @param item The item to check if it has days ago data
 * @param lastPeriodData The data for the last period
 * @param label The label for the item
 */
export var hasDaysAgoData = function (item, lastPeriodData, label) {
    var deltaData = getDeltaData(item, lastPeriodData, label);
    return (deltaData === null || deltaData === void 0 ? void 0 : deltaData.DataCoverageDaysCount) !== undefined && (deltaData === null || deltaData === void 0 ? void 0 : deltaData.DataCoverageDaysCount) !== null;
};
/**
 * Get the value for the item
 *
 * @param item The item to get the value for
 * @param lastPeriodData The data for the last period
 * @param label The label for the item
 */
export var getValue = function (item, lastPeriodData, label) {
    var _a, _b;
    var deltaData = getDeltaData(item, lastPeriodData, label);
    var deltaValue = deltaData === null || deltaData === void 0 ? void 0 : deltaData.DeltaValue;
    var isValueAPercentage = deltaValue === null || deltaValue === undefined;
    return isValueAPercentage ? "".concat((_a = item.value) === null || _a === void 0 ? void 0 : _a.toLocaleString(), "%") : (_b = item.value) === null || _b === void 0 ? void 0 : _b.toLocaleString();
};
export var getDeltaString = function (item, lastPeriodData, label) {
    var deltaData = getDeltaData(item, lastPeriodData, label);
    return React.createElement(OrchestryDeltaText, { deltaValue: deltaData === null || deltaData === void 0 ? void 0 : deltaData.DeltaValue, deltaPercentage: deltaData === null || deltaData === void 0 ? void 0 : deltaData.DeltaPercentage });
};
/**
 * Get the previous day string for the item
 *
 * @param item The item to get the previous day string for
 * @param lastPeriodData The data for the previous day
 * @param label The label for the item
 * @param intl The intl object
 */
export var getPreviousDayString = function (item, lastPeriodData, label, intl) {
    var deltaData = getDeltaData(item, lastPeriodData, label);
    var deltaValue = deltaData === null || deltaData === void 0 ? void 0 : deltaData.DeltaValue;
    var deltaPreviousValue = deltaData === null || deltaData === void 0 ? void 0 : deltaData.PreviousValue;
    var isLastPeriodValueAPercentage = deltaValue === null || deltaValue === undefined;
    return intl.formatMessage({ id: "value_previous_day", defaultMessage: "{value} (previous day)" }, { value: isLastPeriodValueAPercentage ? "".concat(deltaPreviousValue === null || deltaPreviousValue === void 0 ? void 0 : deltaPreviousValue.toLocaleString(), "%") : deltaPreviousValue === null || deltaPreviousValue === void 0 ? void 0 : deltaPreviousValue.toLocaleString() });
};
/**
 * Get the previous day string for the item
 *
 * @param item The item to get the previous day string for
 * @param lastPeriodData The data for the previous day
 * @param label The label for the item
 * @param intl The intl object
 */
export var getDaysAgoString = function (item, lastPeriodData, label, intl) {
    var deltaData = getDeltaData(item, lastPeriodData, label);
    var deltaValue = deltaData === null || deltaData === void 0 ? void 0 : deltaData.DeltaValue;
    var deltaPreviousValue = deltaData === null || deltaData === void 0 ? void 0 : deltaData.PreviousValue;
    var isLastPeriodValueAPercentage = deltaValue === null || deltaValue === undefined;
    return intl.formatMessage({ id: "value_days_ago", defaultMessage: "{value} ({daysAgoCount} days ago)" }, { value: isLastPeriodValueAPercentage ? "".concat(deltaPreviousValue === null || deltaPreviousValue === void 0 ? void 0 : deltaPreviousValue.toLocaleString(), "%") : deltaPreviousValue === null || deltaPreviousValue === void 0 ? void 0 : deltaPreviousValue.toLocaleString(), daysAgoCount: deltaData === null || deltaData === void 0 ? void 0 : deltaData.DataCoverageDaysCount });
};
/**
 * Get the past delta string for the item
 *
 * @param item The item to get the delta string for
 * @param lastPeriodData The data for the last period
 * @param label The label for the item
 * @param intl The intl object
 */
export var getPastDeltaString = function (item, lastPeriodData, label, intl) {
    return hasDaysAgoData(item, lastPeriodData, label) ? getDaysAgoString(item, lastPeriodData, label, intl) : getPreviousDayString(item, lastPeriodData, label, intl);
};
