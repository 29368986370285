import { Group, Skeleton, Text } from "@mantine/core";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
export var OrchestryCardTitle = function (_a) {
    var icon = _a.icon, title = _a.title, colorVariant = _a.colorVariant, isLoading = _a.isLoading;
    if (isLoading) {
        return (React.createElement(Group, { gap: 8 },
            icon ? React.createElement(Skeleton, { height: 25, width: 25, radius: "md", mr: 8 }) : null,
            React.createElement(Skeleton, { height: 25, width: 150, radius: "md" })));
    }
    return (React.createElement(Group, { gap: 8 },
        icon && React.createElement(OrchestryFeaturedIcon, { variant: "light-circle", colorVariant: colorVariant, iconClassName: icon }),
        React.createElement(Text, { c: "text-primary.0", size: "18px", fw: 500, lh: "28px" }, title)));
};
