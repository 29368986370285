import * as AsyncStore from "app/store/async";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
export var tenantConfigurationNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Updating <strong> configuration</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update
            },
            {
                Text: "Updating <strong>Template Categories</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TempCats
            },
            {
                Text: "Updating <strong>Document Template Categories</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DocTempCats
            },
            {
                Text: "Updating <strong>Directory Configuration</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DirectoryConfig
            },
            {
                Text: "Updating <strong>Teams App Configuration</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TeamConfig
            },
            {
                Text: "Updating <strong>Settings</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Config
            },
            {
                Text: "Updating <strong>Settings</strong>",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Save_To_Server
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>Document Template Categories</strong> have been updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DocTempCats
            },
            {
                Text: "<strong>Template Categories</strong> have been updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TempCats
            },
            {
                Text: "<strong>Configuration</strong> successfully updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update
            },
            {
                Text: "<strong>Directory configuration</strong> successfully updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DirectoryConfig
            },
            {
                Text: "<strong>Teams App configuration</strong> successfully updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TeamConfig
            },
            {
                Text: "<strong>Settings</strong> successfully updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Config
            },
            {
                Text: "<strong>Settings</strong> successfully updated.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Save_To_Server
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when updating <strong>tenant configuration</strong>.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update
            },
            {
                Text: "Error when trying to load tenant configuration.",
                ActionType: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load
            }
        ]
    }
];
