import * as React from "react";
import { useSelector } from "react-redux";
import Store from "app/store";
var Async = React.memo(function (props) {
    var asyncItems = useSelector(function (state) { return state.asyncState.items; });
    var isMatched = false;
    var filteredItems = asyncItems.filter(function (o) {
        var match = true;
        if (props.callType !== undefined)
            if (props.callType !== o.callType)
                match = false;
        if (props.status !== undefined)
            if (props.status !== o.status)
                match = false;
        if (props.actionType !== undefined && props.actionType !== null) {
            if (props.actionType !== o.actionType)
                match = false;
        }
        if (props.uid !== undefined) {
            if (Array.isArray(props.uid)) {
                if (props.uid.some(function (uid) { return uid === o.key; }) === false) {
                    match = false;
                }
            }
            else {
                if (props.uid !== o.key)
                    match = false;
            }
        }
        return match;
    });
    if (filteredItems.length > 0) {
        isMatched = true;
    }
    else {
        if (props.overrideShow !== undefined) {
            if (props.overrideShow) {
                isMatched = true;
            }
        }
    }
    // console.log("Async Items", filteredItems.length, filteredItems, props, asyncItems);
    if (props.classNameHidden !== undefined || props.classNameShown !== undefined) {
        var tempClassName = "";
        if (isMatched) {
            tempClassName = props.classNameShown !== undefined ? props.classNameShown : "";
        }
        else {
            tempClassName = props.classNameHidden !== undefined ? props.classNameHidden : "";
        }
        return React.createElement("div", { className: "async-check " + tempClassName }, props.children);
    }
    else {
        if (isMatched) {
            if (props.onThen !== undefined) {
                props.onThen();
            }
            return React.createElement(React.Fragment, null, props.children);
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    }
});
export default Async;
export var AsyncWait = function (callType, status, uid, maxTries, actionType) {
    return new Promise(function (resolve, reject) {
        var loopCount = 0;
        var interval = setInterval(function () {
            var state = Store.getState();
            var asyncItems = state.asyncState.items;
            loopCount++;
            var isMatched = false;
            var filteredItems = asyncItems.filter(function (o) {
                var match = true;
                if (callType !== undefined && callType !== null)
                    if (callType !== o.callType)
                        match = false;
                if (status !== undefined && status !== undefined)
                    if (status !== o.status)
                        match = false;
                if (uid !== undefined && uid !== null) {
                    if (uid !== o.key)
                        match = false;
                }
                if (actionType !== undefined && actionType !== null) {
                    if (actionType !== o.actionType)
                        match = false;
                }
                return match;
            });
            if (filteredItems.length > 0) {
                isMatched = true;
            }
            if (isMatched) {
                //@ts-ignore
                resolve(uid || undefined);
                clearInterval(interval);
            }
            if (maxTries !== undefined && maxTries !== null) {
                if (loopCount > maxTries) {
                    reject();
                    clearInterval(interval);
                }
            }
        }, 1000);
    });
};
