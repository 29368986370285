var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./card-report-metadata-cols.scss";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import "react-tagsinput/react-tagsinput.css";
import { PortletBody, Portlet, PortletHeader } from "app/components/common/portlet";
import * as MetadataListStore from "app/store/metadata/list";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import DisableArea from "app/components/common/disable-area";
import Picklist from "app/components/common/form-picklist";
var CardReportMetadataCols = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var metadata = useSelector(function (state) { return state.metadataListState.items; });
    var _a = React.useState({}), currentTenantConfigInternalState = _a[0], setCurrentTenantConfigInternalState = _a[1];
    var _b = React.useState([]), reportColumnSettings = _b[0], setReportColumnSettings = _b[1];
    var _c = React.useState(false), metadataLoaded = _c[0], setMetadataLoaded = _c[1];
    var _d = React.useState(false), tenantConfigLoaded = _d[0], setTenantConfigLoaded = _d[1];
    var _e = React.useState([]), tabConfiguration = _e[0], setTabConfiguration = _e[1];
    var _f = React.useState(null), selectedColumn = _f[0], setSelectedColumn = _f[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Metadata_List_Load").then(function () {
            setMetadataLoaded(true);
            //console.log("metadata loaded");
        });
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(function () {
            //console.log("tenant config loaded");
            setTenantConfigLoaded(true);
        });
        dispatch(MetadataListStore.Load([]));
        //dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (metadataLoaded && tenantConfigLoaded) {
            var tempColumnOptions_1 = [];
            if (currentTenantConfiguration !== null &&
                currentTenantConfiguration !== undefined &&
                currentTenantConfiguration.LifecycleReportColumnConfiguration !== null &&
                currentTenantConfiguration.LifecycleReportColumnConfiguration !== undefined) {
                if (currentTenantConfiguration.LifecycleReportColumnConfiguration === "" ||
                    currentTenantConfiguration.LifecycleReportColumnConfiguration === "null" ||
                    currentTenantConfiguration.LifecycleReportColumnConfiguration === null) {
                    //tempColumnOptions = [...orchestryColumns];
                    tempColumnOptions_1 = [];
                    metadata.forEach(function (md) {
                        tempColumnOptions_1.push({
                            Name: md.Title + "",
                            FieldName: md.SPFieldInternalName !== null && md.SPFieldInternalName !== undefined ? md.SPFieldInternalName : "",
                            Include: false,
                            Refiner: false
                        });
                    });
                }
                else {
                    tempColumnOptions_1 = JSON.parse(currentTenantConfiguration.LifecycleReportColumnConfiguration);
                    // Remove any custom columns that are not longer a metadata setup in Orchestyr.
                    tempColumnOptions_1 = tempColumnOptions_1.filter(function (colOption) {
                        if (metadata.some(function (md) { return md.SPFieldInternalName === colOption.FieldName; }))
                            return true;
                        return false;
                    });
                    // Add any missing Orchestry metadata items.
                    metadata.forEach(function (md) {
                        if (tempColumnOptions_1.some(function (co) { return co.FieldName === md.SPFieldInternalName; }) === false) {
                            tempColumnOptions_1.push({
                                Name: md.Title + " (Custom)",
                                FieldName: md.SPFieldInternalName !== null && md.SPFieldInternalName !== undefined ? md.SPFieldInternalName : "",
                                Include: false,
                                Refiner: false
                            });
                        }
                    });
                }
            }
            setReportColumnSettings(tempColumnOptions_1);
            // let currentTenantConfigCopy: TenantConfiguration = { ...currentTenantConfiguration };
            // setCurrentTenantConfigInternalState(currentTenantConfigCopy);
        }
    }, [metadataLoaded, tenantConfigLoaded]);
    React.useEffect(function () {
        var reportColumns = JSON.stringify(reportColumnSettings);
        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), { LifecycleReportColumnConfiguration: reportColumns })));
        setTabConfiguration(reportColumnSettings.map(function (dc) {
            return ({
                Identifier: dc.FieldName,
                Name: dc.Name,
                Picked: dc.Include
            });
        }));
    }, [reportColumnSettings]);
    var updateColumnPicklist = function (picklist) {
        var newColConfigs = [];
        picklist.forEach(function (pli) {
            var foundReportColConfigItem = reportColumnSettings.find(function (dc) { return dc.FieldName === pli.Identifier; });
            if (foundReportColConfigItem !== null && foundReportColConfigItem !== undefined) {
                newColConfigs = __spreadArray(__spreadArray([], newColConfigs, true), [__assign(__assign({}, foundReportColConfigItem), { Include: pli.Picked })], false);
            }
        });
        setReportColumnSettings(newColConfigs);
    };
    // const toggleIncludeCheckbox = (checked: boolean, item: ReportMetadataColItem) => {
    //    console.log("checkbox", checked, item);
    //    var temp = { ...item };
    //    temp.Include = checked;
    //    setReportColumnSettings(
    //       reportColumnSettings.map((dcs: ReportMetadataColItem) => (dcs.FieldName === temp.FieldName ? temp : dcs))
    //    );
    // }
    var toggleRefinerCheckbox = function (checked, item) {
        console.log("checkbox", checked, item);
        var temp = __assign({}, item);
        temp.Refiner = checked;
        setReportColumnSettings(reportColumnSettings.map(function (dcs) { return (dcs.FieldName === temp.FieldName ? temp : dcs); }));
    };
    var isReportColumnFilterable = function (item) { var _a; return ((_a = metadata.find(function (md) { return md.SPFieldInternalName === item.FieldName; })) === null || _a === void 0 ? void 0 : _a.FieldType) !== "Link"; };
    var isReportFilterDisabled = function (item) {
        var currentMetadata = metadata.find(function (md) { return md.SPFieldInternalName === item.FieldName; });
        if (currentMetadata !== null && currentMetadata !== undefined) {
            return currentMetadata.FieldType !== null &&
                currentMetadata.FieldType !== undefined &&
                ((currentMetadata.SingleMultiple !== null && currentMetadata.SingleMultiple !== undefined && currentMetadata.SingleMultiple === "Multiple" && currentMetadata.FieldType === "Text") ||
                    (currentMetadata.SingleMultiple !== null && currentMetadata.SingleMultiple !== undefined && currentMetadata.SingleMultiple === "Multiple" && currentMetadata.FieldType === "Choice") ||
                    (currentMetadata.SingleMultiple !== null &&
                        currentMetadata.SingleMultiple !== undefined &&
                        currentMetadata.SingleMultiple === "Multiple" &&
                        currentMetadata.FieldType === "ManagedMetadata"))
                ? true
                : false;
        }
        else {
            return true;
        }
    };
    var getSelectedItem = function () {
        // if(colName === null)
        // setSelectedColumn(null);
        var foundConfig = reportColumnSettings.find(function (rc) { return rc.FieldName === selectedColumn; });
        return foundConfig !== null ? foundConfig : null;
    };
    var selectedItem = getSelectedItem();
    return (React.createElement("div", { className: "card-report-metadata-cols fadein-500ms", "data-pw": "report-column-settings" }, metadataLoaded === true && tenantConfigLoaded === true ? (React.createElement(React.Fragment, null, currentTenantConfigInternalState.GUID !== "" ? (React.createElement(Portlet, null,
        React.createElement(PortletHeader, { title: "Lifecycle Report Column Settings" }),
        React.createElement(PortletBody, null,
            React.createElement(Picklist, { data: tabConfiguration, onChange: function (data) { return updateColumnPicklist(data); }, onItemSelected: function (itemName) { return setSelectedColumn(itemName); }, maxHeight: 200 }),
            getSelectedItem() !== null && getSelectedItem() !== undefined && isReportColumnFilterable(selectedItem) ? (React.createElement("div", { className: "col-settings" },
                React.createElement("div", { className: "report-column-setting" },
                    React.createElement("div", { className: "title" },
                        React.createElement("span", null, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.Name)),
                    React.createElement("div", { className: "checks" },
                        React.createElement(DisableArea, { isDisabled: isReportFilterDisabled(selectedItem) },
                            React.createElement(Form.Group, { controlId: "control-refiner-" + selectedItem.FieldName, className: "form-group checkbox-green" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Enable filter for this column", checked: selectedItem.Refiner, onChange: function (e) {
                                        toggleRefinerCheckbox(e.currentTarget.checked, selectedItem);
                                    } }))))))) : null))) : null)) : (React.createElement(SkeletonLoaderPortlet, { bodyHeight: 250 },
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 })))));
});
export default CardReportMetadataCols;
