var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import PortletAlert from "app/components/common/portlet-alert";
import TenantService from "app/services/tenant";
import { ServiceAppPermissionConfiguration } from "app/store/tenants/types";
import { TenantLicenseStatus } from "appv2/api/partner-tools/tenant-license-status.type";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { InstallWizardContext, InstallerMode } from "../..";
var InstallerStepUpgradeDowngrade = React.memo(function (props) {
    var iwc = React.useContext(InstallWizardContext);
    var isInTeams = document.getElementsByTagName("html")[0].classList.contains("in-teams");
    React.useEffect(function () {
        // Check to see if granting can be skipped.
        TenantService.tryGetTenantInfo().then(function (tenantInfo) {
            if (tenantInfo.exists === true) {
                iwc.setTenantInformation(__assign(__assign({}, iwc.tenantInformation), { DisplayName: tenantInfo.DisplayName }));
            }
        });
        if (isInTeams === true) {
            iwc.setStepProgress(props.step.key, false);
        }
        if (iwc.mode == InstallerMode.LicenseUpgradeOrchestryTrial) {
            iwc.setServiceAppConfig(ServiceAppPermissionConfiguration.OrchestryReadWriteAll);
            iwc.setLicenseChange(TenantLicenseStatus.OrchestryTrial);
        }
        if (iwc.mode == InstallerMode.LicenseUpgradeOrchestryFull) {
            iwc.setServiceAppConfig(ServiceAppPermissionConfiguration.OrchestryReadWriteAll);
            iwc.setLicenseChange(TenantLicenseStatus.Orchestry);
        }
        if (iwc.mode == InstallerMode.LicenseDowngradeBeacon) {
            iwc.setServiceAppConfig(ServiceAppPermissionConfiguration.OrchestryReadAll);
            iwc.setLicenseChange(TenantLicenseStatus.Beacon);
        }
    }, []);
    return (React.createElement("div", { className: "installer-step-information fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                iwc.mode == InstallerMode.LicenseUpgradeOrchestryTrial || iwc.mode == InstallerMode.LicenseUpgradeOrchestryFull ? React.createElement("h3", null, "Start a trial of Orchestry") : React.createElement("h3", null, "Downgrade to Beacon"),
                iwc.mode == InstallerMode.LicenseUpgradeOrchestryTrial || iwc.mode == InstallerMode.LicenseUpgradeOrchestryFull ? (React.createElement("p", null, "You are attempting to upgrade from Beacon to Orchestry. In doing so, you will consent to a more permissive version of the Service App.")) : (React.createElement("p", null, "You are attempting to downgrade from Orchestry to Beacon. In doing so, you will consent to a less permissive version of the Service App.")),
                React.createElement("p", null, "We've verified that you are a global administrator and have access to perform this action."),
                isInTeams ? (React.createElement(PortletAlert, { type: "danger", icon: "alert", size: "small", title: "You cannot update Orchestry when running from Teams", message: React.createElement("span", null,
                        "Please",
                        " ",
                        React.createElement("a", { href: window.location.origin, target: "_blank" }, "open Orchestry in your browser"),
                        " ",
                        "to continue.") })) : null))));
});
export default InstallerStepUpgradeDowngrade;
