import axios from "axios";
var DeleteCheckService = /** @class */ (function () {
    function DeleteCheckService() {
    }
    DeleteCheckService.DeleteAPIUrls = [
        {
            Type: "template",
            Url: "/api/Templates/CanDelete?guid=",
            DetailsUrl: "/workspaces/templates/edit/"
        },
        {
            Type: "approval-workflow",
            Url: "/api/ApprovalWorkflows/CanDelete?guid=",
            DetailsUrl: "/workspaces/approval-workflows/edit/"
        },
        {
            Type: "request",
            DetailsUrl: "/requests/view/"
        },
        {
            Type: "feature",
            Url: "/api/Features/CanDelete?guid=",
            DetailsUrl: "/workspaces/features/edit/"
        },
        {
            Type: "channel",
            Url: "/api/Channels/CanDelete?guid=",
            DetailsUrl: "/workspaces/channels/edit/"
        },
        {
            Type: "document-template",
            Url: "/api/DocumentTemplates/CanDelete?guid=",
            DetailsUrl: "/workspaces/document-templates/edit/"
        },
        {
            Type: "metadata",
            Url: "/api/metadata/CanDelete?guid=",
            DetailsUrl: "/workspaces/metadata/edit/"
        },
        {
            Type: "naming-policy",
            Url: "/api/namingpolicies/CanDelete?guid=",
            DetailsUrl: "/workspaces/naming-policies/edit/"
        },
        {
            Type: "library-document-template",
            Url: "/api/namingpolicies/CanDelete?guid=",
            DetailsUrl: "/workspaces/naming-policies/edit/"
        },
        {
            Type: "lifecycle-archival-policy",
            Url: "/api/LifecyclePolicies/CanDelete?guid=",
            DetailsUrl: "/lifecycle/policies/archival/details/"
        },
        {
            Type: "lifecycle-policy-workspace",
            Url: "/api/not-applicable",
            DetailsUrl: "/lifecycle/listing/"
        },
        {
            Type: "lifecycle-guest-policy",
            Url: "/api/LifecycleGuestPolicies/CanDelete?guid=",
            DetailsUrl: "/guests/policies/"
        }
    ];
    DeleteCheckService.GetCheckResponse = function (itemGuid, itemType) {
        return new Promise(function (resolve, reject) {
            var deleteCheckUrl = DeleteCheckService.DeleteAPIUrls.find(function (dau) { return dau.Type === itemType; });
            if (deleteCheckUrl !== undefined) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = deleteCheckUrl.Url + itemGuid;
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    //console.log("Install Start Response" , response);
                    var deleteCheckData = response.data;
                    if (deleteCheckData.Groups !== undefined) {
                        deleteCheckData.Groups.forEach(function (group) {
                            var editUrl = DeleteCheckService.DeleteAPIUrls.find(function (dau) { return dau.Type === group.ItemType; });
                            if (editUrl !== null) {
                                if (group.Items !== undefined) {
                                    group.Items.forEach(function (gItem) {
                                        gItem.DetailsUrl = (editUrl === null || editUrl === void 0 ? void 0 : editUrl.DetailsUrl) + gItem.GUID;
                                        console.log("Edit URL", editUrl, gItem);
                                    });
                                }
                            }
                        });
                    }
                    resolve(deleteCheckData);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
            else {
                reject("Delete Check Problem - Service cannot find type");
            }
        });
    };
    return DeleteCheckService;
}());
export default DeleteCheckService;
