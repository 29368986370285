import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export var partnerSettingsApi = createApi({
    reducerPath: "partnerSettingsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/Partners" }),
    tagTypes: ["PartnerSettings"],
    endpoints: function (builder) { return ({
        getPartnerSettings: builder.query({
            query: function () { return "/GetPartnerSettings"; },
            providesTags: ["PartnerSettings"]
        }),
        updatePartnerSettings: builder.mutation({
            query: function (updatePartnerSettingsCommand) { return ({
                url: "/UpdatePartnerSettings",
                method: "PUT",
                body: updatePartnerSettingsCommand
            }); }
        })
    }); }
});
export var useGetPartnerSettingsQuery = partnerSettingsApi.useGetPartnerSettingsQuery, useUpdatePartnerSettingsMutation = partnerSettingsApi.useUpdatePartnerSettingsMutation;
