import { FeatureCurrentActionTypes } from "./types";
export function Load(feature) {
    if (feature === undefined)
        feature = {};
    //console.log("Load Current Template > ", template)
    return {
        type: FeatureCurrentActionTypes.Feature_Current_Load,
        payload: feature
    };
}
export function Update(feature) {
    return {
        type: FeatureCurrentActionTypes.Feature_Current_Update,
        payload: feature
    };
}
export function Clear() {
    return {
        type: FeatureCurrentActionTypes.Feature_Current_Clear
    };
}
export var featureCurrentMiddlware = function (store, next, action) {
    // if(action.type === FeatureCurrentActionTypes.Feature_Current_Load){
    //     let feat:Feature = action.payload;
    //     if(feat.Icon !== undefined)
    //         feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //     // if(feat.AssociatedTemplates !== undefined){
    //     //     let atStrings = "";
    //     //     feat.AssociatedTemplates.forEach((at, index) => {
    //     //         atStrings += at.GUID + "|";
    //     //     })
    //     //     feat._associatedTemplates = atStrings;
    //     // }
    //     action.payload = feat;
    //     next(action);
    // }
    // else if(action.type === FeatureCurrentActionTypes.Feature_Current_Update){
    //     //let feat:Feature = action.payload;
    //     // fix icon before saving somehow?
    //     // if(feat.Icon !== undefined)
    //     //     feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //     // if(feat.AssociatedTemplates !== undefined){
    //     //     let atStrings = "";
    //     //     feat.AssociatedTemplates.forEach((at, index) => {
    //     //         atStrings += at.GUID + "|";
    //     //     })
    //     //     feat._associatedTemplates = atStrings;
    //     // }
    //     // action.payload = feat;
    //     next(action);
    // } else {
    next(action);
    //}
};
