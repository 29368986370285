import * as AsyncStore from "app/store/async";
import { Generic_Toast_Notification } from "app/store/notifications/types";
import { LibraryTemplateListActionTypes } from "app/store/library-templates/list";
export var libraryTemplateNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to libraryTemplates list...",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Title}</strong> in libraryTemplates list...",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Title}</strong> from libraryTemplates list...",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to libraryTemplates list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Add
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully updated in libraryTemplates list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Update
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully deleted from libraryTemplates list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to libraryTemplates list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Title}</strong> to libraryTemplates list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Title}</strong> from libraryTemplates list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Delete
            },
            {
                Text: "Error when trying to load libraryTemplate list.",
                ActionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Load
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Template Successfully Copied",
                ActionType: LibraryTemplateListActionTypes.Template_Copied_Success
            }
        ]
    }
];
