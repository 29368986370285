var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./settings-language.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import Async, { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as PageHeader from "app/components/common/page-header";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { Portlet, PortletBody } from "app/components/common/portlet";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { LocalizationService } from "app/services/localization";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
import Store from "app/store";
import * as OrchestryContextStore from "app/store/orchestry-context/current";
var SettingsLanguage = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    var save = function () {
        var asyncCallType = AsyncCallType.Update;
        dispatch(TenantConfigurationCurrentStore.UpdateSaveToServer());
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Update_Save_To_Server").then(function () {
            console.log("config saved");
            Store.dispatch(OrchestryContextStore.Refresh({
                Culture: currentTenantConfiguration.LocalizationCultureDefault,
                CultureAvailable: currentTenantConfiguration.LocalizationCultureAvailable
            }));
        });
    };
    return (React.createElement("div", { className: "settings-language fadein-500ms" },
        backToList && React.createElement(Redirect, { to: "/settings" }),
        React.createElement(PageHeader.SetLeftContent, { title: "Settings", subTitle: "Edit" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/" },
                React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
            React.createElement("div", { onClick: function () { return save(); }, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" }))),
        React.createElement(SettingsLangaugeSelection, null)));
});
export default SettingsLanguage;
var SettingsLangaugeSelection = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    React.useEffect(function () {
        if (currentTenantConfiguration === null || (currentTenantConfiguration !== null && currentTenantConfiguration.GUID === undefined)) {
            dispatch(TenantConfigurationCurrentStore.Load());
        }
    }, []);
    React.useEffect(function () {
        if (currentTenantConfiguration.LocalizationCultureDefault === null && orchestryContext.Culture !== null) {
            updateDefaultLangauge(orchestryContext.Culture);
        }
    }, [currentTenantConfiguration]);
    var getAvailLangaugeValues = function () {
        var defaultValue = currentTenantConfiguration.LocalizationCultureDefault;
        var values = currentTenantConfiguration !== undefined && currentTenantConfiguration.LocalizationCultureAvailable !== undefined
            ? currentTenantConfiguration.LocalizationCultureAvailable.map(function (lang) {
                return ({
                    id: lang,
                    value: lang,
                    title: LocalizationService.AllLangauges.some(function (l) { return l.lang === lang; }) ? LocalizationService.AllLangauges.find(function (l) { return l.lang === lang; }).name : lang
                });
            })
            : [];
        if (values.some(function (v) { return v.value === defaultValue; }) === false) {
            console.log("Values dont contain default language", defaultValue);
            values.push({
                id: defaultValue,
                value: defaultValue,
                title: LocalizationService.AllLangauges.some(function (l) { return l.lang === defaultValue; }) ? LocalizationService.AllLangauges.find(function (l) { return l.lang === defaultValue; }).name : defaultValue
            });
        }
        return values;
    };
    var updateDefaultLangauge = function (value) {
        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), { LocalizationCultureDefault: value, LocalizationCultureAvailable: currentTenantConfiguration.LocalizationCultureAvailable !== undefined &&
                currentTenantConfiguration.LocalizationCultureAvailable !== null &&
                Array.isArray(currentTenantConfiguration.LocalizationCultureAvailable)
                ? currentTenantConfiguration.LocalizationCultureAvailable.some(function (ca) { return ca === value; }) === false
                    ? __spreadArray(__spreadArray([], currentTenantConfiguration.LocalizationCultureAvailable, true), [value], false) : currentTenantConfiguration.LocalizationCultureAvailable
                : [] })));
    };
    return (React.createElement("div", { className: "settings-language fadein-500ms" },
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Started },
            React.createElement(Row, null,
                React.createElement(Col, { md: 8, lg: 6, xl: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 140, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 7 })),
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 140, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 7 }))))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Succeeded },
            React.createElement(Row, null,
                React.createElement(Col, { md: 8, lg: 6, xl: 6 },
                    React.createElement(Portlet, null,
                        React.createElement(PortletBody, null,
                            React.createElement(Form.Group, { className: "form-group", controlId: "default-langauge" },
                                React.createElement(Form.Label, null, "Default Language"),
                                React.createElement("p", null, "The default language for all users"),
                                React.createElement(FormDropdownSelect, { list: LocalizationService.AllLangauges.map(function (lang) {
                                        return ({
                                            id: lang.lang,
                                            value: lang.lang,
                                            title: lang.name
                                        });
                                    }), value: currentTenantConfiguration.LocalizationCultureDefault !== null ? currentTenantConfiguration.LocalizationCultureDefault : "en", onChange: function (value) { return updateDefaultLangauge(value); } })),
                            React.createElement(Form.Group, { className: "form-group", controlId: "avail-langauge" },
                                React.createElement(Form.Label, null, "Available Language"),
                                React.createElement("p", null, "Any additional languages that will be available. If only a single language is selected then no language chooser will be shown."),
                                React.createElement(FormDropdownMultiSelect, { list: LocalizationService.AllLangauges.map(function (lang) {
                                        return ({
                                            id: lang.lang,
                                            value: lang.lang,
                                            title: lang.name,
                                            locked: lang.lang === currentTenantConfiguration.LocalizationCultureDefault
                                        });
                                    }), values: getAvailLangaugeValues(), onChange: function (values) {
                                        return dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), { LocalizationCultureAvailable: values.map(function (option) { return option.value; }) })));
                                    } })))))))));
});
