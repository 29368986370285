import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useHealthCheckRouteParams } from "../../health-checks/use-health-check-route-params.hook";
import { useUserIsPartnerNotViewingPartnerLedTenant } from "../../health-checks/use-user-is-partner-viewing-self-led-tenant.hook";
/**
 * Hook to determine if the current user can share the health check.
 */
export var useHealthCheckCanShare = function () {
    var _a = useUserIsPartnerNotViewingPartnerLedTenant(), userIsPartnerViewingNotPartnerLedTenant = _a.userIsPartnerViewingNotPartnerLedTenant, isLoading = _a.isLoading;
    var userType = useHealthCheckRouteParams().userType;
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var userIsPartnerViewingCustomer = userType === "partner";
    var userRoleCanShare = currentUserHasRole(OrchRole.Admin) || currentUserHasRole(OrchRole.BeaconAdmin) || currentUserHasRole(OrchRole.HealthCheckReviewer);
    return { userCanShareHealthCheck: !userIsPartnerViewingCustomer && userRoleCanShare && !userIsPartnerViewingNotPartnerLedTenant, isLoading: isLoading };
};
