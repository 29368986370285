import { useCustomMantineReactTable } from "appv2/components/orchestry-data-table/use-custom-mantine-react-table.hook";
import { useMemo, useState } from "react";
import { TenantEventBrowserAssociatedUserColumn, TenantEventBrowserTableDateColumn, TenantEventBrowserTableTextColumn, TenantEventBrowserTenantNameColumn } from "./tenant-event-browser-table-columns";
export var useTenantEventBrowserTable = function (pagedTenantEvents, sorting, setSorting, isPageLoading, isTableLoading, highlightSearchWords) {
    var _a;
    var _b = useState({
        TenantGUID: false,
        AssociatedUserDisplayName: false
    }), columnVisibility = _b[0], setColumnVisibility = _b[1];
    var onSortingChange = function (updaterOrValue) {
        setSorting(updaterOrValue);
    };
    var onColumnVisibilityChange = function (newVisibility) {
        setColumnVisibility(newVisibility);
    };
    var columns = useMemo(function () { return [
        {
            accessorKey: "TenantName",
            header: "Tenant Name",
            enableSorting: false,
            Cell: function (_a) {
                var row = _a.row;
                return TenantEventBrowserTenantNameColumn(row, highlightSearchWords);
            },
            grow: true
        },
        {
            accessorKey: "CreatedDate",
            header: "Event Date",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableDateColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "Property",
            header: "Property",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableTextColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "PreviousValue",
            header: "Previous Value",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableTextColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "NewValue",
            header: "New Value",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableTextColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "EventType",
            header: "Event Type",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableTextColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "ChangeSource",
            header: "Change Source",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableTextColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "AssociatedUser",
            header: "Initiated By",
            enableSorting: false,
            Cell: function (_a) {
                var row = _a.row;
                return TenantEventBrowserAssociatedUserColumn(row);
            },
            grow: true
        },
        {
            accessorKey: "AssociatedPartner",
            header: "Partner ID",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return TenantEventBrowserTableTextColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "TenantGUID",
            header: ""
        },
        {
            accessorKey: "AssociatedUserDisplayName",
            header: ""
        }
    ]; }, [highlightSearchWords]);
    var table = useCustomMantineReactTable({
        layoutMode: "grid",
        onSortingChange: onSortingChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        initialState: {
            sorting: sorting,
            columnVisibility: columnVisibility,
            isLoading: true
        },
        mantineLoadingOverlayProps: {
            visible: false
        },
        state: { sorting: sorting, columnVisibility: columnVisibility, isLoading: isTableLoading },
        columns: columns,
        data: (_a = pagedTenantEvents === null || pagedTenantEvents === void 0 ? void 0 : pagedTenantEvents.Items) !== null && _a !== void 0 ? _a : [],
        mantineTableContainerProps: {
            style: { maxHeight: "630px" }
        }
    }, isPageLoading ? undefined : !(pagedTenantEvents === null || pagedTenantEvents === void 0 ? void 0 : pagedTenantEvents.Items.length));
    return {
        table: table
    };
};
