var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Async, { AsyncWait } from "app/components/common/async";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import FormToggleControl from "app/components/common/form-toggle-control";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeTextSmall, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import "./settings-account.scss";
var initialLayout = function (footerImage, footerTemplateText) {
    if (footerImage && footerTemplateText)
        return "imagentext";
    if (footerImage)
        return "image";
    if (footerTemplateText)
        return "text";
    return "default";
};
var SettingsNotifications = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(""), returnAddress = _b[0], setReturnAddress = _b[1];
    var _c = React.useState(""), errorAddress = _c[0], setErrorAddress = _c[1];
    var _d = React.useState(""), allAddress = _d[0], setAllAddress = _d[1];
    var _e = React.useState(""), sendAsAddress = _e[0], setSendAsAddress = _e[1];
    var _f = React.useState(""), footerImage = _f[0], setfooterImage = _f[1];
    var _g = React.useState(""), imageLink = _g[0], setImageLink = _g[1];
    var _h = React.useState(null), footerTemplateText = _h[0], setfooterTemplateText = _h[1];
    var _j = React.useState("default"), layout = _j[0], setlayout = _j[1];
    var saveDiv = React.useRef();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (currentTenantConfiguration !== undefined && JSON.stringify(currentTenantConfiguration) !== "{}") {
            if (currentTenantConfiguration.NotificationReturnAccount !== undefined &&
                currentTenantConfiguration.NotificationReturnAccount !== null &&
                currentTenantConfiguration.NotificationReturnAccount !== "") {
                setReturnAddress(currentTenantConfiguration.NotificationReturnAccount);
            }
            if (currentTenantConfiguration.NotificationEmailAddressError !== undefined &&
                currentTenantConfiguration.NotificationEmailAddressError !== null &&
                currentTenantConfiguration.NotificationEmailAddressError !== "") {
                setErrorAddress(currentTenantConfiguration.NotificationEmailAddressError);
            }
            if (currentTenantConfiguration.NotificationEmailAddressAll !== undefined &&
                currentTenantConfiguration.NotificationEmailAddressAll !== null &&
                currentTenantConfiguration.NotificationEmailAddressAll !== "") {
                setAllAddress(currentTenantConfiguration.NotificationEmailAddressAll);
            }
            if (currentTenantConfiguration.NotificationSendAsAddress !== undefined &&
                currentTenantConfiguration.NotificationSendAsAddress !== null &&
                currentTenantConfiguration.NotificationSendAsAddress !== "") {
                setSendAsAddress(currentTenantConfiguration.NotificationSendAsAddress);
            }
            if (currentTenantConfiguration.CustomFooterImage !== undefined && currentTenantConfiguration.CustomFooterImage !== null && currentTenantConfiguration.CustomFooterImage !== "") {
                setfooterImage(currentTenantConfiguration.CustomFooterImage);
            }
            if (currentTenantConfiguration.CustomFooterTemplateText !== undefined &&
                currentTenantConfiguration.CustomFooterTemplateText !== null &&
                currentTenantConfiguration.CustomFooterTemplateText !== "") {
                setfooterTemplateText(currentTenantConfiguration.CustomFooterTemplateText);
            }
            if (currentTenantConfiguration.CustomFooterImageLink !== undefined && currentTenantConfiguration.CustomFooterImageLink !== null && currentTenantConfiguration.CustomFooterImageLink !== "") {
                setImageLink(currentTenantConfiguration.CustomFooterImageLink);
            }
            setlayout(initialLayout(currentTenantConfiguration.CustomFooterImage, currentTenantConfiguration.CustomFooterTemplateText));
        }
    }, [currentTenantConfiguration]);
    var save = function () {
        if (imageLink && !imageLink.match(/^https?:\/\//)) {
            alert("Please ensure link contains a proper protocol (http(s)://)");
            return;
        }
        var safeFooterImage = footerImage;
        var safeImageLink = imageLink;
        var safeFooterTemplateText = footerTemplateText;
        if (footerTemplateText) {
            if (
            //@ts-ignore
            footerTemplateText.length === 1 &&
                //@ts-ignore
                footerTemplateText[0].children.length === 1) {
                //@ts-ignore
                if (footerTemplateText[0].children[0].text.trim() === "") {
                    safeFooterTemplateText = "";
                }
            }
        }
        if (layout === "image") {
            safeFooterTemplateText = "";
        }
        else if (layout === "text") {
            setfooterImage("");
            safeFooterImage = "";
        }
        else if (layout === "default") {
            safeFooterTemplateText = "";
            safeFooterImage = "";
            safeImageLink = "";
        }
        var asyncCallType = AsyncCallType.Update;
        dispatch(TenantConfigurationCurrentStore.Update(__assign(__assign({}, currentTenantConfiguration), { NotificationReturnAccount: returnAddress, NotificationEmailAddressError: errorAddress, NotificationEmailAddressAll: allAddress, NotificationSendAsAddress: sendAsAddress, CustomFooterImage: safeFooterImage, CustomFooterImageLink: safeImageLink, CustomFooterTemplateText: safeFooterTemplateText })));
        AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentTenantConfiguration.GUID).then(function () {
            setBackToList(true);
            //dispatch(FeatureCurrentStore.Clear());
        });
    };
    React.useEffect(function () {
        try {
            saveDiv.current.onclick = save;
        }
        catch (_a) { }
    });
    // React.useEffect(()=>{
    //     if(currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup !== undefined && currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup !== ""){
    //         let data = JSON.parse(currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup);
    //         //console.log("currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup", data);
    //         setCurrentGroups(data);
    //     }
    // },[currentTenantConfiguration])
    // console.log("currentTenantConfiguration",)
    var getSendingUser = function () {
        if (returnAddress !== "") {
            if (returnAddress.length > 0) {
                var userFromString = [];
                try {
                    userFromString = JSON.parse(returnAddress);
                }
                catch (_a) { }
                if (userFromString[0] !== undefined) {
                    return userFromString[0].UserPrincipalName;
                }
            }
        }
        return "example-user@example.com";
    };
    var getSendAsAddress = function () {
        if (sendAsAddress !== "") {
            if (sendAsAddress.length > 0) {
                var userFromString = [];
                try {
                    userFromString = JSON.parse(sendAsAddress);
                }
                catch (_a) { }
                if (userFromString[0] !== undefined) {
                    if (userFromString[0].UserPrincipalName !== undefined && userFromString[0].UserPrincipalName !== null && userFromString[0].UserPrincipalName !== "") {
                        return userFromString[0].UserPrincipalName;
                    }
                    if (userFromString[0].Email !== undefined && userFromString[0].Email !== null && userFromString[0].Email !== "") {
                        return userFromString[0].Email;
                    }
                }
            }
        }
        return "example-group@example.com";
    };
    return (React.createElement("div", { className: "settings-account fadein-500ms" },
        backToList && React.createElement(Redirect, { to: "/settings" }),
        React.createElement(Subtitle, { title: "Settings", subtitle: "Notifications" },
            React.createElement(NavLink, { to: "/" },
                React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
            React.createElement("div", { ref: saveDiv, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" }))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Started },
            React.createElement(Row, null,
                React.createElement(Col, { md: 8, lg: 6, xl: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 140, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 7 })),
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 115, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, marginTop: 7 })),
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 115, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, marginTop: 7 }))),
                React.createElement(Col, { md: 8, lg: 6, xl: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 500, showHeader: false },
                        React.createElement(SkeletonLoaderFakeTextSmall, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 10, marginTop: 25 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 120, marginTop: 7 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 10, marginTop: 25 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 120, marginTop: 7 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 10, marginTop: 25 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 120, marginTop: 7 }))))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Succeeded },
            React.createElement(Row, null,
                React.createElement(Col, { md: 5 },
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement("div", { className: "kt-portlet" },
                                React.createElement("div", { className: "kt-portlet__body" }, currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup !== undefined ? (React.createElement(FormUserGroupSelect, { label: "Notification Sending User Address", placeholder: "No user account selected", returnType: "users", allowMultiple: false, defaultValue: currentTenantConfiguration.NotificationReturnAccount, onChange: function (userList) {
                                        setReturnAddress(userList);
                                    } })) : null)))),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement("div", { className: "kt-portlet" },
                                React.createElement("div", { className: "kt-portlet__body" }, currentTenantConfiguration.NotificationSendAsAddress !== undefined ? (React.createElement(React.Fragment, null,
                                    React.createElement(FormUserGroupSelect, { label: "Notification Send As Shared Mailbox", placeholder: "No group/shared mailbox selected", returnType: "usersAndGroups", allowMultiple: false, defaultValue: currentTenantConfiguration.NotificationSendAsAddress, onChange: function (userList) {
                                            setSendAsAddress(userList);
                                        } }),
                                    React.createElement("div", { className: "details-message" },
                                        React.createElement("strong", null, "Note:"),
                                        " To enable sending on behalf of a Shared Mailbox, the \"Notifications Sending User Address\" field above must be populated, and that user (",
                                        getSendingUser(),
                                        ") must be granted ",
                                        React.createElement("em", null, "Send As"),
                                        " rights on the selected mailbox. To do this, navigate to Exchange Administration and grant the above user Send As privileges to the mailbox object. Alternatively, you can add a user as a delegate using this",
                                        " ",
                                        React.createElement("a", { href: "https://docs.microsoft.com/en-us/powershell/module/exchange/add-recipientpermission?view=exchange-ps", target: "_blank", rel: "noopener noreferrer" }, "Exchange PowerShell"),
                                        " ",
                                        "command.",
                                        React.createElement("code", null,
                                            "Add-RecipientPermission \"",
                                            getSendAsAddress(),
                                            "\" -AccessRights SendAs -Trustee \"",
                                            getSendingUser(),
                                            "\"")))) : null))))),
                React.createElement(Col, null,
                    React.createElement(Portlet, null,
                        React.createElement(PortletHeader, { title: "Administrator Notification Options" }),
                        React.createElement(PortletBody, null,
                            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                React.createElement(FormToggleControl, { checked: currentTenantConfiguration.AdministratorProvisioningNotificationDisabled, label: "Disable Administrator Notifications for Provisioning", handleChange: function (checked) { return dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave({ AdministratorProvisioningNotificationDisabled: checked })); } })))),
                    React.createElement(Portlet, null,
                        React.createElement(PortletHeader, { title: "Customize Email Notification Footer" }),
                        React.createElement(PortletBody, null,
                            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                React.createElement("div", null,
                                    React.createElement("p", null, "Images will be resized to 124px by 96px for Footers with text, 248px by 96px for those without.")),
                                React.createElement("div", { className: "layout-config-v2" },
                                    React.createElement(Form.Group, { className: "form-group" },
                                        React.createElement(Form.Label, null, "Template"),
                                        React.createElement(FormDropdownSelect, { onChange: function (value) { return setlayout(value); }, value: layout, list: [
                                                {
                                                    id: "default",
                                                    title: "Default",
                                                    value: "default"
                                                },
                                                {
                                                    id: "imagentext",
                                                    title: "Image & Text",
                                                    value: "imagentext"
                                                },
                                                {
                                                    id: "text",
                                                    title: "Text Only",
                                                    value: "text"
                                                },
                                                {
                                                    id: "image",
                                                    title: "Image Only",
                                                    value: "image"
                                                }
                                            ] })),
                                    React.createElement("div", { className: "editor-group" }, layout !== "default" ? (React.createElement(React.Fragment, null,
                                        React.createElement(Form.Label, null, "Footer Editor"),
                                        React.createElement(MarkdownEditor, { templateImage: footerImage, imageLink: imageLink, templateText: footerTemplateText, onChange: function (body) {
                                                setfooterTemplateText(body);
                                            }, enabledStyles: { all: true }, setTemplateImage: setfooterImage, setImageLink: setImageLink, layout: layout }))) : null))))))))));
});
export default SettingsNotifications;
