import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
var getDisconnectTenantBaseUrl = function (userType) {
    switch (userType) {
        case "partner":
            return "/DisconnectPartnerTenant";
        case "customer":
            return "/DisconnectTenant";
        default:
            return "/DisconnectTenant";
    }
};
export var partnersSettingsApi = createApi({
    reducerPath: "partnersSettingsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/Partners" }),
    tagTypes: ["PartnersSettings"],
    endpoints: function (builder) { return ({
        getPartnersSettings: builder.query({
            query: function () { return "/GetPartnersSettings"; },
            providesTags: ["PartnersSettings"]
        }),
        updatePartnersSettings: builder.mutation({
            query: function (updatePartnerSettingsCommand) { return ({
                url: "/UpdatePartnersSettings",
                method: "PUT",
                body: updatePartnerSettingsCommand
            }); }
        }),
        verifyPartnerSecret: builder.mutation({
            query: function (secretKey) { return ({
                url: "/VerifyPartnerSecret/?secretKey=".concat(secretKey),
                method: "GET"
            }); }
        }),
        disconnectTenant: builder.mutation({
            query: function (_a) {
                var userType = _a.userType, disconnectPartnerTenantCommand = _a.disconnectPartnerTenantCommand;
                return ({
                    url: "".concat(getDisconnectTenantBaseUrl(userType)),
                    method: "POST",
                    body: disconnectPartnerTenantCommand
                });
            }
        })
    }); }
});
export var useGetPartnersSettingsQuery = partnersSettingsApi.useGetPartnersSettingsQuery, useUpdatePartnersSettingsMutation = partnersSettingsApi.useUpdatePartnersSettingsMutation, useDisconnectTenantMutation = partnersSettingsApi.useDisconnectTenantMutation, useVerifyPartnerSecretMutation = partnersSettingsApi.useVerifyPartnerSecretMutation;
