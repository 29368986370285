import * as React from "react";
import "./dashboard.scss";
import { Row, Col } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import Subtitle from "app/components/common/subtitle";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import moment from "moment";
import AnimatedLoader from "app/components/common/animated-loader";
import GuestService from "app/services/guests";
import { getIconClassName } from "@uifabric/styling";
import ReactApexChart from "react-apexcharts";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeTextSmall } from "app/components/common/skeleton-loaders";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios";
import OrchModal from "app/components/common/orch-modal";
import DashboardRecommendedActions from "./components/recommended-actions";
import { ReportDatasource, ReportSearchSlotName } from "app/store/lifecycle-report";
import ReportInsightsButton from "../../common/report/report-insights-button";
import InfoTooltip from "app/components/common/infotooltip";
import LifecycleService from "app/services/lifecycle";
import { useDispatch } from "react-redux";
var GuestDashboard = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    var _z = React.useState(null), stats = _z[0], setStats = _z[1];
    var _0 = React.useState(false), reportFileLoading = _0[0], setReportFileLoading = _0[1];
    var _1 = React.useState("month"), dateFilterOverview = _1[0], setDateFilterOverview = _1[1];
    var _2 = React.useState(null), insights = _2[0], setInsights = _2[1];
    var inTeams = React.useState(document.getElementsByTagName("html")[0].classList.contains("in-teams"))[0];
    var _3 = React.useState(undefined), etlPostInstallStatus = _3[0], setEtlPostInstallStatus = _3[1];
    var dispatch = useDispatch();
    var history = useHistory();
    React.useEffect(function () {
        var contentwrapper = document.getElementById("kt_content");
        if (contentwrapper !== null) {
            if (contentwrapper.classList.contains("guest-management-dashboard-content-wrapper") === false) {
                contentwrapper.classList.add("guest-management-dashboard-content-wrapper");
            }
        }
        return function () {
            var contentwrapper = document.getElementById("kt_content");
            if (contentwrapper !== null) {
                if (contentwrapper.classList.contains("guest-management-dashboard-content-wrapper") === true) {
                    contentwrapper.classList.remove("guest-management-dashboard-content-wrapper");
                }
            }
        };
    }, []);
    React.useEffect(function () { }, []);
    var formatToLineChartData = function (stats) {
        var returnArray = [];
        var addedItems = {
            id: "Added",
            color: "#eed312",
            data: []
        };
        var deletedItems = {
            id: "Deleted",
            color: "#38bcb2",
            data: []
        };
        stats === null || stats === void 0 ? void 0 : stats.forEach(function (stat) {
            if (stat.UnitDateTime !== undefined && stat.UnitDateTime !== null) {
                if (stat.DeletedCount !== undefined && stat.DeletedCount !== null) {
                    deletedItems.data.push({
                        x: stat.UnitDateTime,
                        y: stat.DeletedCount
                    });
                }
                if (stat.AddedCount !== undefined && stat.AddedCount !== null) {
                    addedItems.data.push({
                        x: stat.UnitDateTime,
                        y: stat.AddedCount
                    });
                }
            }
        });
        returnArray.push(addedItems);
        returnArray.push(deletedItems);
        return returnArray;
    };
    var formatMonthlyData = function (stats) {
        return formatToLineChartData(stats);
    };
    var formatWeeklyData = function (stats) {
        return formatToLineChartData(stats);
    };
    var formatQuarterlyData = function (stats) {
        return formatToLineChartData(stats);
    };
    var formatYearlyData = function (stats) {
        return formatToLineChartData(stats);
    };
    var formatTickData = function (tickValue) {
        if (dateFilterOverview === "month") {
            return moment(tickValue).format("MMM D");
        }
        if (dateFilterOverview === "week") {
            return moment(tickValue).format("MMM D");
        }
        if (dateFilterOverview === "quarter") {
            return (moment(tickValue)
                .add(-30, "days")
                .format("MMM D") +
                " - " +
                moment(tickValue).format("MMM D"));
        }
        return moment(tickValue).format("MMM YY");
    };
    var downloadReport = function () {
        setReportFileLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.set("data", JSON.stringify(stats));
        axios({
            url: "/api/GuestsDashboard/GenerateReport",
            method: "POST",
            data: bodyFormData,
            responseType: "blob" // important
        }).then(function (response) {
            var url = window.URL.createObjectURL(new Blob([response.data], { type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Orchestry Report - Guests Dashboard.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
            setReportFileLoading(false);
        });
    };
    React.useEffect(function () {
        if (stats !== null && insights === null) {
            var insights_1 = [];
            if (insights_1.length > 0) {
                insights_1[0].Current = true;
            }
            setInsights(insights_1);
        }
    }, [stats]);
    var getLineHistoricalData = function () {
        return dateFilterOverview === "month"
            ? formatMonthlyData(stats === null || stats === void 0 ? void 0 : stats.ThisMonth)
            : dateFilterOverview === "week"
                ? formatWeeklyData(stats === null || stats === void 0 ? void 0 : stats.ThisWeek)
                : dateFilterOverview === "quarter"
                    ? formatQuarterlyData(stats === null || stats === void 0 ? void 0 : stats.ThisQuarter)
                    : formatYearlyData(stats === null || stats === void 0 ? void 0 : stats.ThisYear);
    };
    var getAddDeletedHistoricalData = function (state) {
        return dateFilterOverview === "month"
            ? state === "current"
                ? stats === null || stats === void 0 ? void 0 : stats.ThisPeriod.ThisMonth
                : stats === null || stats === void 0 ? void 0 : stats.LastPeriod.ThisMonth
            : dateFilterOverview === "week"
                ? state === "current"
                    ? stats === null || stats === void 0 ? void 0 : stats.ThisPeriod.ThisWeek
                    : stats === null || stats === void 0 ? void 0 : stats.LastPeriod.ThisWeek
                : state === "current"
                    ? stats === null || stats === void 0 ? void 0 : stats.ThisPeriod.ThisYear
                    : stats === null || stats === void 0 ? void 0 : stats.LastPeriod.ThisYear;
    };
    var getAddDeletedChangeStat = function (statType) {
        var currentStat = null;
        var pastStat = null;
        var currentNumber = 0;
        var pastNumber = 0;
        if (dateFilterOverview === "month") {
            currentStat = stats === null || stats === void 0 ? void 0 : stats.ThisPeriod.ThisMonth;
            pastStat = stats === null || stats === void 0 ? void 0 : stats.LastPeriod.ThisMonth;
        }
        if (dateFilterOverview === "week") {
            currentStat = stats === null || stats === void 0 ? void 0 : stats.ThisPeriod.ThisWeek;
            pastStat = stats === null || stats === void 0 ? void 0 : stats.LastPeriod.ThisWeek;
        }
        if (dateFilterOverview === "year") {
            currentStat = stats === null || stats === void 0 ? void 0 : stats.ThisPeriod.ThisYear;
            pastStat = stats === null || stats === void 0 ? void 0 : stats.LastPeriod.ThisYear;
        }
        if (statType === "total") {
            if (currentStat !== null && currentStat !== undefined && currentStat.TotalCount !== undefined && currentStat.TotalCount !== null) {
                currentNumber = currentStat === null || currentStat === void 0 ? void 0 : currentStat.TotalCount;
            }
            if (pastStat !== null && pastStat !== undefined && pastStat.TotalCount !== undefined && pastStat.TotalCount !== null) {
                pastNumber = pastStat === null || pastStat === void 0 ? void 0 : pastStat.TotalCount;
            }
        }
        if (statType === "added") {
            if (currentStat !== null && currentStat !== undefined && currentStat.AddedCount !== undefined && currentStat.AddedCount !== null) {
                currentNumber = currentStat === null || currentStat === void 0 ? void 0 : currentStat.AddedCount;
            }
            if (pastStat !== null && pastStat !== undefined && pastStat.AddedCount !== undefined && pastStat.AddedCount !== null) {
                pastNumber = pastStat === null || pastStat === void 0 ? void 0 : pastStat.AddedCount;
            }
        }
        if (statType === "deleted") {
            if (currentStat !== null && currentStat !== undefined && currentStat.DeletedCount !== undefined && currentStat.DeletedCount !== null) {
                currentNumber = currentStat === null || currentStat === void 0 ? void 0 : currentStat.DeletedCount;
            }
            if (pastStat !== null && pastStat !== undefined && pastStat.DeletedCount !== undefined && pastStat.DeletedCount !== null) {
                pastNumber = pastStat === null || pastStat === void 0 ? void 0 : pastStat.DeletedCount;
            }
        }
        var getRelativeDifference = function (a, b) {
            var percent = 0;
            var al = a;
            var bl = b;
            if (b !== 0) {
                if (a !== 0) {
                    percent = ((al - bl) / bl) * 100;
                }
                else {
                    percent = -bl * 100;
                }
            }
            else {
                percent = al * 100;
            }
            return Math.round(percent);
        };
        var getChangeDirection = function () {
            var change = getRelativeDifference(currentNumber, pastNumber);
            var direction = "none";
            if (change > 0) {
                direction = "up";
            }
            if (change < 0) {
                direction = "down";
            }
            if (change === 0) {
                direction = "none";
            }
            return direction;
        };
        return (React.createElement("div", { className: "change-stat-box " + getChangeDirection() },
            React.createElement("div", { className: "change-stat" },
                React.createElement("div", { className: "icon" },
                    React.createElement("i", { className: "down fa fa-arrow-trend-down" }),
                    React.createElement("i", { className: "up fa fa-arrow-trend-up" })),
                React.createElement("div", { className: "count" },
                    React.createElement("span", { className: "up" }, "+"),
                    getRelativeDifference(currentNumber, pastNumber),
                    "%"))));
    };
    var getReportTitle = function (ReportTitle) {
        var _a;
        var rpt = (_a = stats === null || stats === void 0 ? void 0 : stats.ReportViews) === null || _a === void 0 ? void 0 : _a.filter(function (c) { return c.Name === ReportTitle; });
        if (rpt && rpt[0])
            return rpt[0].Title;
        else
            return "";
    };
    var getReportDescription = function (ReportTitle) {
        var _a;
        var rpt = (_a = stats === null || stats === void 0 ? void 0 : stats.ReportViews) === null || _a === void 0 ? void 0 : _a.filter(function (c) { return c.Name === ReportTitle; });
        if (rpt && rpt[0])
            return rpt[0].Description;
        else
            return "No description";
    };
    React.useEffect(function () {
        LifecycleService.ETLPostInstallStatus().then(function (postStatus) {
            setEtlPostInstallStatus(postStatus);
        });
        GuestService.GetGuestsDashboardData().then(function (data) {
            setStats(data);
        });
    }, []);
    return (React.createElement("div", { className: "guest-management-dashboard" },
        React.createElement(Subtitle, { title: "Guest Dashboard" },
            React.createElement("div", { className: "lifecycle-report-filters-nav-buttons" },
                React.createElement("div", { className: "guest-management-dashboard-recommended-deletions" },
                    React.createElement("div", { className: "export-button button-custom btn-secondary", onClick: function () { return downloadReport(); }, "data-pw": "export-button" },
                        reportFileLoading === true ? (React.createElement("span", { className: "loading" },
                            React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement("span", { className: "icon" },
                            React.createElement("i", { className: getIconClassName("ExcelDocument") }))),
                        React.createElement("span", { className: "text" }, "Export"))),
                React.createElement(ReportInsightsButton, { Datasource: ReportDatasource.GuestUser, Slot: ReportSearchSlotName.GuestInsightsSlot, NamePrefix: "Guest", HideBoldSelected: true, OnSelected: function (value) {
                        history.push("/guests/listing/insight/" + value);
                    }, CurrentView: "all-guests" }))),
        etlPostInstallStatus !== undefined && etlPostInstallStatus.PrivateServer === true && etlPostInstallStatus.CanConnectToPrivateServer === false ? (React.createElement("div", { className: "crawl-still-in-progress fadein-1s" },
            React.createElement("i", { className: "flaticon-interface-4" }),
            React.createElement("div", { className: "title" }, "Sorry, we can't connect to your private Cosmos db instance"),
            React.createElement("div", { className: "desc" }, "We are getting an error when trying to connect to you database."))) : etlPostInstallStatus !== undefined && etlPostInstallStatus.LastCrawlDidGuests === false ? (React.createElement("div", { className: "crawl-still-in-progress fadein-1s" },
            React.createElement("i", { className: "flaticon-interface-4" }),
            React.createElement("div", { className: "title" }, "Sorry, we are still collecting information about your guests"),
            React.createElement("div", { className: "desc" }, "The first scan can take between 24 and 48 hours."))) : null,
        stats === null ? (React.createElement(React.Fragment, null,
            React.createElement(SkeletonLoaderFakeTextSmall, { marginTop: 40, bodyHeight: 30 }),
            React.createElement(Row, { style: { marginTop: "20px" } },
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 }))),
            React.createElement(Row, { style: { marginTop: "20px" } },
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 }))),
            React.createElement(SkeletonLoaderFakeTextSmall, { marginTop: 40, bodyHeight: 30 }),
            React.createElement(Row, { style: { marginTop: "20px" } },
                React.createElement(Col, { md: 2 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 })),
                React.createElement(Col, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 123 }))),
            React.createElement(SkeletonLoaderFakeTextSmall, { marginTop: 40, bodyHeight: 30 }),
            React.createElement(Row, { style: { marginTop: "20px" } },
                React.createElement(Col, { md: 8 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 492 })),
                React.createElement(Col, { md: 4 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 492 }))))) : (React.createElement("div", { className: "new-dashboard" },
            React.createElement("div", { className: "top-area" },
                React.createElement("div", { className: "title-row top", "data-pw": "metrics-title" },
                    React.createElement("div", { className: "left" }, "Metrics")),
                (stats === null || stats === void 0 ? void 0 : stats.RecommendedCount_Deletions) > 0 ? (React.createElement("div", { className: "recommended-actions-button btn btn-secondary", "data-pw": "recommendations-button" },
                    React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null,
                            React.createElement("i", { className: "fa-solid fa-screwdriver-wrench" }),
                            "See ",
                            React.createElement("strong", null, stats ? stats.RecommendedCount_Deletions : 0),
                            " Recommendations",
                            React.createElement("i", { className: "far fa-chevron-right fa-sm" })), size: "md", modalClassName: "recommended-actions-modal", showCloseButton: true, simpleButton: false },
                        React.createElement("div", null,
                            React.createElement(DashboardRecommendedActions, { Count_RecommendedDeletions: stats ? stats.RecommendedCount_Deletions : 0 }))))) : null),
            React.createElement("div", { className: "status-row metrics", "data-pw": "metrics-status-top" },
                React.createElement(NavLink, { to: "/guests/listing/insight/all-guests" },
                    React.createElement("div", { className: "status-box color-one", "data-pw": "metrics-all-guests" },
                        React.createElement(InfoTooltip, null, getReportDescription("all-guests")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon color-one" },
                            React.createElement("i", { className: "fa-light fa-user-lock fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_a = stats.GuestCount_All) === null || _a === void 0 ? void 0 : _a.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("all-guests")))),
                React.createElement(NavLink, { to: "/lifecycle/listing/insight/workspaces-with-guests" },
                    React.createElement("div", { className: "status-box color-two", "data-pw": "metrics-workspaces-shared" },
                        React.createElement(InfoTooltip, null, getReportDescription("workspaces-with-guests")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-chart-network fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_b = stats.GuestCount_WorkspacesShared) === null || _b === void 0 ? void 0 : _b.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, "Workspaces Shared"))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-with-multiple-access" },
                    React.createElement("div", { className: "status-box color-three", "data-pw": "metrics-multi-access" },
                        React.createElement(InfoTooltip, null, getReportDescription("guests-with-multiple-access")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-layer-plus fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_c = stats.GuestCount_MultiAccess) === null || _c === void 0 ? void 0 : _c.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-with-multiple-access")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-with-no-access" },
                    React.createElement("div", { className: "status-box color-four", "data-pw": "metrics-no-access" },
                        React.createElement(InfoTooltip, null, getReportDescription("guests-with-no-access")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-shield-slash fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_d = stats.GuestCount_NoAccess) === null || _d === void 0 ? void 0 : _d.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-with-no-access"))))),
            React.createElement("div", { className: "status-row metrics", "data-pw": "metrics-status-bottom" },
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-with-unique-domains" },
                    React.createElement("div", { className: "status-box color-five", "data-pw": "metrics-unique-domains" },
                        React.createElement(InfoTooltip, null, getReportDescription("guests-with-unique-domains")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-buildings fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_e = stats.GuestCount_UniqueDomains) === null || _e === void 0 ? void 0 : _e.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-with-unique-domains")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guest-access-violations" },
                    React.createElement("div", { className: "status-box color-six", "data-pw": "metrics-access-violations" },
                        React.createElement(InfoTooltip, null, getReportDescription("guest-access-violations")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-hexagon-exclamation fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_f = stats.GuestCount_AccessViolations) === null || _f === void 0 ? void 0 : _f.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guest-access-violations")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-recently-requested" },
                    React.createElement("div", { className: "status-box color-seven", "data-pw": "metrics-recently-requested" },
                        React.createElement(InfoTooltip, null, getReportDescription("guests-recently-requested")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-clock-rotate-left fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_g = stats.GuestCount_RecentlyRequested) === null || _g === void 0 ? void 0 : _g.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-recently-requested")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-pending-review" },
                    React.createElement("div", { className: "status-box color-eight", "data-pw": "metrics-pending-review" },
                        React.createElement(InfoTooltip, null, getReportDescription("guests-pending-review")),
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa-light fa-arrows-rotate fa-2xl" })),
                        React.createElement("div", { className: "count countwithcolor", "data-pw": "count" }, (_h = stats.GuestCount_PendingReview) === null || _h === void 0 ? void 0 : _h.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-pending-review"))))),
            React.createElement("div", { className: "title-row", "data-pw": "status-title" },
                React.createElement("div", { className: "left" }, "Guest Lifecycle Status")),
            React.createElement("div", { className: "status-row", "data-pw": "guest-status" },
                React.createElement(NavLink, { to: "/guests/listing/insight/all-active-guests" },
                    React.createElement("div", { className: "status-box color-nine", "data-pw": "status-active" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_j = stats.GuestCount_Active) === null || _j === void 0 ? void 0 : _j.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("all-active-guests")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/all-inactive-guests" },
                    React.createElement("div", { className: "status-box color-ten", "data-pw": "status-inactive" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_k = stats.GuestCount_Inactive) === null || _k === void 0 ? void 0 : _k.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("all-inactive-guests")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-not-approved" },
                    React.createElement("div", { className: "status-box color-eleven", "data-pw": "status-pending-approval" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_l = stats.GuestCount_Redemption_Pending_Approval) === null || _l === void 0 ? void 0 : _l.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-not-approved")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-not-redeemed" },
                    React.createElement("div", { className: "status-box color-twelve", "data-pw": "status-not-redeemed" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_m = stats.GuestCount_NotRedeemed) === null || _m === void 0 ? void 0 : _m.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-not-redeemed")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-pending-review" },
                    React.createElement("div", { className: "status-box color-thirteen", "data-pw": "status-pending-review" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_o = stats.GuestCount_PendingReview) === null || _o === void 0 ? void 0 : _o.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-pending-review")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/guests-recently-renewed" },
                    React.createElement("div", { className: "status-box color-forteen", "data-pw": "status-recently-renewed" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_p = stats.GuestCount_RecentlyRenewed) === null || _p === void 0 ? void 0 : _p.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("guests-recently-renewed")))),
                React.createElement(NavLink, { to: "/guests/listing/insight/recently-deleted-guests" },
                    React.createElement("div", { className: "status-box color-fifteen", "data-pw": "status-recently-deleted" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_q = stats.GuestCount_RecentlyDeleted) === null || _q === void 0 ? void 0 : _q.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, getReportTitle("recently-deleted-guests"))))),
            React.createElement("div", { className: "title-row", "data-pw": "overview-title" },
                React.createElement("div", { className: "left" }, "Guests Overview"),
                React.createElement("div", { className: "right" })),
            React.createElement(Row, { className: "charts-row", "data-pw": "charts-row" },
                React.createElement(Col, { md: 8 },
                    React.createElement("div", { className: "bar-chart", "data-pw": "bar-chart" },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 9 },
                                React.createElement("div", { className: "title", "data-pw": "title" }, "Added vs Deleted Guests"),
                                React.createElement("div", { className: "chart", style: { height: "320px" }, "data-pw": "chart" },
                                    React.createElement(ResponsiveLine, { data: getLineHistoricalData(), tooltip: function (input) {
                                            var _a;
                                            // console.log(input)
                                            return (React.createElement("div", { className: "orchestry-guest-dashboard-line-chart-hover" },
                                                React.createElement("span", { className: "color-icon", style: { backgroundColor: input.point.serieColor } }),
                                                React.createElement("span", { className: "title" }, input.point.serieId),
                                                React.createElement("span", { className: "value" }, (_a = input.point.data.y) === null || _a === void 0 ? void 0 : _a.toLocaleString())));
                                        }, margin: { top: 40, right: 20, bottom: 90, left: 40 }, xScale: { type: "point" }, yScale: {
                                            type: "linear",
                                            min: "auto",
                                            max: "auto",
                                            stacked: false,
                                            reverse: false
                                        }, yFormat: " >-,.2f", xFormat: " >-,.0", curve: "catmullRom", axisTop: null, axisRight: null, axisBottom: {
                                            tickSize: 0,
                                            tickPadding: dateFilterOverview === "year" ? 10 : 20,
                                            tickRotation: dateFilterOverview === "year" ? 35 : 0,
                                            format: function (item) { return formatTickData(item); }
                                        }, defs: [
                                            {
                                                id: "dots",
                                                type: "patternDots",
                                                background: "inherit",
                                                color: "#eed312",
                                                size: 4,
                                                padding: 1,
                                                stagger: true
                                            },
                                            {
                                                id: "lines",
                                                type: "patternLines",
                                                background: "inherit",
                                                color: "#38bcb2",
                                                rotation: -25,
                                                lineWidth: 6,
                                                spacing: 10
                                            }
                                        ], axisLeft: {
                                            tickSize: 0,
                                            tickValues: 4,
                                            tickPadding: 20
                                        }, enableGridX: false, colors: { scheme: "nivo" }, lineWidth: 3, enablePoints: false, pointSize: 10, pointColor: { theme: "background" }, pointBorderWidth: 2, pointBorderColor: { from: "serieColor" }, pointLabelYOffset: -12, areaBlendMode: "multiply", useMesh: true, theme: {
                                            legends: {
                                                text: { fontFamily: '"TT Norms", Helvetica, sans-serif', fontWeight: 500, fontSize: "13px" }
                                            },
                                            labels: {
                                                text: { fontFamily: '"TT Norms", Helvetica, sans-serif', fontWeight: 500 }
                                            },
                                            axis: {
                                                ticks: {
                                                    text: {
                                                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                                                        fontWeight: 500,
                                                        fill: "#9EACB2"
                                                    }
                                                }
                                            }
                                        }, legends: [
                                            {
                                                anchor: "bottom",
                                                direction: "row",
                                                data: [
                                                    {
                                                        id: "Added",
                                                        label: "Added",
                                                        color: "#dcc3a3"
                                                    },
                                                    {
                                                        id: "Deleted",
                                                        label: "Deleted",
                                                        color: "#d77a66"
                                                    }
                                                ],
                                                justify: false,
                                                translateX: 0,
                                                translateY: 85,
                                                itemsSpacing: 25,
                                                itemWidth: 100,
                                                itemHeight: 12,
                                                itemDirection: "left-to-right",
                                                itemOpacity: 0.85,
                                                symbolSize: 12,
                                                effects: [
                                                    {
                                                        on: "hover",
                                                        style: {
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ] }))),
                            React.createElement(Col, { md: 3 },
                                React.createElement("div", { className: "added-vs-deleted-stat-boxes", "data-pw": "added-deleted-stat" },
                                    React.createElement("div", { className: "timeframe-picker" },
                                        React.createElement(FormDropdownSelect, { list: [
                                                {
                                                    id: "week",
                                                    title: "Last 7 Days",
                                                    value: "week"
                                                },
                                                {
                                                    id: "month",
                                                    title: "Last 28 days",
                                                    value: "month"
                                                },
                                                {
                                                    id: "year",
                                                    title: "Last 365 days",
                                                    value: "year"
                                                }
                                            ], value: dateFilterOverview, onChange: function (value) { return setDateFilterOverview(value); } })),
                                    React.createElement("div", { className: "stat-box", "data-pw": "added-stat" },
                                        React.createElement("div", { className: "stat-number" }, (_s = (_r = getAddDeletedHistoricalData("current")) === null || _r === void 0 ? void 0 : _r.AddedCount) === null || _s === void 0 ? void 0 : _s.toLocaleString()),
                                        React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Added guests"),
                                        React.createElement("div", { className: "prior-period", "data-pw": "prior-period" },
                                            "(", (_u = (_t = getAddDeletedHistoricalData("past")) === null || _t === void 0 ? void 0 : _t.AddedCount) === null || _u === void 0 ? void 0 :
                                            _u.toLocaleString(),
                                            " prior period)"),
                                        getAddDeletedChangeStat("added")),
                                    React.createElement("div", { className: "stat-box", "data-pw": "deleted-stat" },
                                        React.createElement("div", { className: "stat-number" }, (_w = (_v = getAddDeletedHistoricalData("current")) === null || _v === void 0 ? void 0 : _v.DeletedCount) === null || _w === void 0 ? void 0 : _w.toLocaleString()),
                                        React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Deleted guests"),
                                        React.createElement("div", { className: "prior-period", "data-pw": "prior-period" },
                                            "(", (_y = (_x = getAddDeletedHistoricalData("past")) === null || _x === void 0 ? void 0 : _x.DeletedCount) === null || _y === void 0 ? void 0 :
                                            _y.toLocaleString(),
                                            " prior period)"),
                                        getAddDeletedChangeStat("deleted"))))),
                        React.createElement("div", { className: "chart-disclaimer" }, "Note: Due to technical limitations this chart only shows guests that were deleted after Orchestry was installed"))),
                React.createElement(Col, { md: 4 },
                    React.createElement("div", { className: "pie-chart", "data-pw": "pie-chart" },
                        React.createElement("div", { className: "title", "data-pw": "title" }, "Redemption Status"),
                        React.createElement("div", { className: "chart", "data-pw": "chart" },
                            React.createElement(ReactApexChart, { height: 325, options: {
                                    colors: ["#eb9191", "#db5475", "#729ea1"],
                                    plotOptions: {
                                        pie: {
                                            donut: {
                                                size: "55%"
                                            }
                                        }
                                    },
                                    chart: {
                                        type: "donut"
                                    },
                                    stroke: {
                                        colors: ["#000"],
                                        width: 0
                                    },
                                    legend: {
                                        position: "bottom",
                                        fontSize: "13px",
                                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                                        fontWeight: 500,
                                        horizontalAlign: "left",
                                        offsetX: 20,
                                        offsetY: 20,
                                        width: 200,
                                        height: 90,
                                        markers: {
                                            width: 12,
                                            height: 12,
                                            strokeWidth: 0,
                                            // strokeColor: '#fff',
                                            // fillColors: undefined,
                                            radius: 2,
                                            // customHTML: undefined,
                                            // onClick: undefined,
                                            offsetX: -5
                                            // offsetY: 0
                                        },
                                        itemMargin: {
                                        // horizontal: 0,
                                        // vertical: 8
                                        }
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    grid: {
                                        padding: {
                                            bottom: 0,
                                            top: 0,
                                            left: -20,
                                            right: -20
                                        }
                                    },
                                    tooltip: {
                                        y: {
                                            formatter: function (value, series) {
                                                return value === null || value === void 0 ? void 0 : value.toLocaleString();
                                            }
                                        }
                                    },
                                    labels: ["Accepted", "Pending Approval", "Not Redeemed"]
                                    // responsive: [{
                                    //    breakpoint: 0,
                                    //    options: {
                                    //       chart: {
                                    //          width: 200
                                    //       },
                                    //       legend: {
                                    //          position: 'right'
                                    //       }
                                    //    }
                                    // }]
                                }, series: [stats === null || stats === void 0 ? void 0 : stats.GuestCount_Redemption_Accepted, stats === null || stats === void 0 ? void 0 : stats.GuestCount_Redemption_Pending_Approval, stats === null || stats === void 0 ? void 0 : stats.GuestCount_Redemption_Not_Redeemed], type: "donut" })))))))));
});
export default GuestDashboard;
