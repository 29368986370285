import * as React from "react";
import { Form } from "react-bootstrap";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var ReportFilterArchivalPolicies = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        props.FilterArchivalPoliciesLoading === undefined || props.FilterArchivalPoliciesLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (props.FilterArchivalPolicies !== undefined && (React.createElement(FormDropdownMultiSelect, { list: props.FilterArchivalPolicies.map(function (fc, index) {
                return ({
                    id: fc.GUID,
                    title: fc.Name,
                    value: fc.GUID
                });
            }), placeholder: "-- Select a Policy --", values: props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue.length > 0
                ? props.Refiner.CurrentValue.split("|")
                    //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID == cv))
                    .map(function (cv, index) {
                    var _a, _b;
                    return ({
                        id: cv,
                        title: (_b = (_a = props.FilterArchivalPolicies) === null || _a === void 0 ? void 0 : _a.find(function (fc) { return fc.GUID === cv; })) === null || _b === void 0 ? void 0 : _b.Name,
                        value: cv
                    });
                })
                : [], onChange: function (values) { return props.FilterOnChange(props.Refiner, values.map(function (v) { return v.value; }).join("|")); } })))));
});
export default ReportFilterArchivalPolicies;
