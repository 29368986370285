import * as React from "react";
import { NavLink } from "react-router-dom";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import InfoTooltip from "app/components/common/infotooltip";
import { getIconClassName } from "@uifabric/styling";
import { generateReasons } from "../report-column-guest-deletetion-recommendation-reason/reasons";
import { useSelector } from "react-redux";
import iconViva from "app/assets/img/icon-viva-engage.svg";
export var getDaysInactiveClass = function (days) {
    if (days > 60) {
        return " days-60";
    }
    else if (days > 30) {
        return " days-30";
    }
    else {
        return "";
    }
};
var ReportColumnWorkspaceName = React.memo(function (props) {
    var _a, _b, _c;
    var _d = React.useState(), workspaceOverlay = _d[0], setworkspaceOverlay = _d[1];
    var tenantConfig = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var guestMemberships = useSelector(function (state) { return state.lifecycleReportState.GuestMembershipsSlot.Items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _e = React.useState(props.Item), item = _e[0], setItem = _e[1];
    React.useEffect(function () {
        if (props.Item.ExtendedProperties !== undefined &&
            props.Item.ExtendedProperties !== null &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== undefined &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== null) {
            setItem(props.Item.ExtendedProperties.CosmosWorkspaceDetails);
        }
        else {
            setItem(props.Item);
        }
    }, [props.Item]);
    React.useEffect(function () {
        if (guestMemberships && guestMemberships.length > 0) {
            var reasons = generateReasons(
            //guestMemberships.filter(membership => membership.GroupId === item.GroupId)[0]?.ExtendedProperties?.GuestMembershipDetails,
            props.Item, tenantConfig.LCDefaultGuestInactivityDays || 90, true);
            var overlayItems = reasons === null || reasons === void 0 ? void 0 : reasons.filter(function (reason) { return reason.Reason === "GuestsNotAllowed"; }).map(function (reason) {
                return { message: reason.Title };
            });
            setworkspaceOverlay({ icon: { fontSize: 18, iconClassName: "fa-light fa-triangle-exclamation", shape: "square" }, overlayTitle: "Advisory", items: overlayItems });
        }
    }, [guestMemberships, tenantConfig.LCDefaultGuestInactivityDays, item.GroupId]);
    var getStatusText = function (reportItem) {
        if (reportItem !== null && reportItem !== undefined) {
            if (reportItem.ArchivalStatus === "Active" || reportItem.ArchivalStatus === null) {
                if (reportItem.DaysInactive !== null &&
                    reportItem.DaysInactive !== undefined &&
                    props.inactiveDays !== null &&
                    props.inactiveDays !== undefined &&
                    reportItem.DaysInactive >= props.inactiveDays) {
                    return "Inactive";
                }
                else {
                    return "Active";
                }
            }
            if (reportItem.ArchivalStatus === "PendingApproval") {
                return "Pending Approval";
            }
            if (reportItem.ArchivalStatus === "Extended") {
                return "Workspace Renewed";
            }
            if (reportItem.ArchivalStatus === "ReadyToArchive") {
                return "Ready to Archive";
            }
            if (reportItem.ArchivalStatus === "Processing") {
                return "Processing Archival";
            }
            if (reportItem.ArchivalStatus === "Done") {
                return "Archived";
            }
            if (reportItem.ArchivalStatus === "Failed") {
                return "Archival Failed";
            }
            if (reportItem.ArchivalStatus === "Deleted") {
                return "Deleted";
            }
        }
    };
    var getStatusClass = function (reportItem) {
        var returnClass = "active";
        if (reportItem.ArchivalStatus !== null) {
            if (reportItem.ArchivalStatus.toLowerCase() === "active") {
                if (reportItem.DaysInactive !== null &&
                    reportItem.DaysInactive !== undefined &&
                    props.inactiveDays !== null &&
                    props.inactiveDays !== undefined &&
                    reportItem.DaysInactive >= props.inactiveDays) {
                    returnClass = "inactive";
                }
            }
            else {
                returnClass = reportItem.ArchivalStatus.toLowerCase();
            }
        }
        else {
            returnClass = "active";
        }
        return returnClass;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "workspace-icon-container" },
            React.createElement(NavLink, { className: "profile-icon", exact: true, to: "/lifecycle/listing/" + item.SiteId, onClick: function (event) { return props.goToLink(item); } },
                React.createElement(OfficeProfilePicture, { size: "sm", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo, overlay: workspaceOverlay }),
                item.IsVisibleInDirectory === false ? (React.createElement("div", { className: "hidden-workspace-icon" },
                    React.createElement(InfoTooltip, { content: "Hidden Workspace" },
                        React.createElement("i", { className: "fa fa-eye-slash" })))) : null,
                item.IsArchived !== null && item.IsArchived !== undefined && item.IsArchived === true ? (React.createElement("div", { className: "archived-workspace-icon" },
                    React.createElement(InfoTooltip, { content: "Archived Workspace" },
                        React.createElement("i", { className: "la la-archive" })))) : null),
            React.createElement("div", { className: "subline" },
                item.Application !== null ? (React.createElement("div", { className: "cat-wrapper" },
                    React.createElement("div", { className: "cat-icon" }, item.Application === "CommunicationSite" ? (React.createElement("img", { title: "Communication Site", src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" })) : item.Application === "TeamSite" ? (React.createElement("img", { title: "Team Site", src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" })) : item.Application === "Team" ? (React.createElement("img", { title: "Microsoft Team", src: "/media/orchestry/svg-imgs/icon-teams-24.png" })) : (React.createElement("img", { title: "Viva Engage Community", src: iconViva }))))) : null,
                item.TemplateName !== null && (React.createElement("div", { className: "template-name" },
                    React.createElement("div", { className: "template-icon" },
                        React.createElement("img", { title: item.TemplateName, src: "/favicon-16x16.png" })))))),
        React.createElement("div", { className: "name-wrapper" },
            React.createElement(NavLink, { exact: true, to: "/lifecycle/listing/" + item.SiteId, onClick: function (event) { return props.goToLink(item); }, "data-pw": "workspace-title" },
                React.createElement("span", null, item.Title)),
            React.createElement("div", { className: "workspace-relative-url", "data-pw": "workspace-relative-url" }, item.Application !== "VivaEngageCommunity" ? (React.createElement("span", { title: (_a = item === null || item === void 0 ? void 0 : item.SiteUrl) === null || _a === void 0 ? void 0 : _a.replace((orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.TenantSharePointUrl) || "", "") }, (_b = item === null || item === void 0 ? void 0 : item.SiteUrl) === null || _b === void 0 ? void 0 : _b.replace((orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.TenantSharePointUrl) || "", ""))) : (React.createElement("span", { title: item === null || item === void 0 ? void 0 : item.VivaEngageCommunityUrl }, (_c = item === null || item === void 0 ? void 0 : item.VivaEngageCommunityUrl) === null || _c === void 0 ? void 0 : _c.substring(item.VivaEngageCommunityUrl.indexOf("/groups/"))))),
            React.createElement("div", { className: "ws-activity", "data-pw": "ws-activity" },
                item.ArchivalStatus !== undefined && item.ArchivalStatus !== null ? (React.createElement("div", { className: "ws-archival-status lc-stats-".concat(getStatusClass(item)) },
                    React.createElement("span", null, getStatusText(item)))) : null,
                item.IsArchived === false && item.ArchivalStatus !== "Failed" && item.ArchivalStatus !== "Deleted" && item.ArchivalStatus !== "Done" ? (React.createElement("div", { className: "ws-activity-items" },
                    item.TeamDaysInactive !== null && item.TeamDaysInactive !== undefined && (React.createElement("div", { className: "activity-icon".concat(getDaysInactiveClass(item.TeamDaysInactive)), title: item.TeamDaysInactive + " days since last Team activity" },
                        React.createElement("i", { className: getIconClassName("TeamsLogo") }),
                        " ",
                        React.createElement("span", null, item.TeamDaysInactive))),
                    item.GroupConversationsDaysInactive !== null && item.GroupConversationsDaysInactive !== undefined && (React.createElement("div", { className: "activity-icon".concat(getDaysInactiveClass(item.GroupConversationsDaysInactive)), title: item.GroupConversationsDaysInactive + " days since last Outlook conversation" },
                        React.createElement("i", { className: getIconClassName("OutlookLogo") }),
                        " ",
                        React.createElement("span", null, item.GroupConversationsDaysInactive))),
                    item.GroupEventsDaysInactive !== null && item.GroupEventsDaysInactive !== undefined && (React.createElement("div", { className: "activity-icon".concat(getDaysInactiveClass(item.GroupEventsDaysInactive)), title: item.GroupEventsDaysInactive + " days since last Outlook event modification" },
                        React.createElement("i", { className: getIconClassName("Calendar") }),
                        " ",
                        React.createElement("span", null, item.GroupEventsDaysInactive))),
                    item.SharePointDaysInactive !== null && item.SharePointDaysInactive !== undefined && (React.createElement("div", { className: "activity-icon".concat(getDaysInactiveClass(item.SharePointDaysInactive)), title: item.SharePointDaysInactive + " days since last SharePoint activity" },
                        React.createElement("i", { className: getIconClassName("SharepointLogo") }),
                        " ",
                        React.createElement("span", null, item.SharePointDaysInactive))))) : null))));
});
export default ReportColumnWorkspaceName;
