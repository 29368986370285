export var DirectorySearchActionTypes;
(function (DirectorySearchActionTypes) {
    DirectorySearchActionTypes["Directory_Load"] = "Directory_Load";
    DirectorySearchActionTypes["Directory_Clear"] = "Directory_Clear";
    DirectorySearchActionTypes["Directory_Refresh"] = "Directory_Refresh";
    DirectorySearchActionTypes["Directory_Load_Refiners"] = "Directory_Load_Refiners";
    DirectorySearchActionTypes["Directory_Load_RefinersAndFavs"] = "Directory_Load_RefinersAndFavs";
    DirectorySearchActionTypes["Directory_Load_Favs"] = "Directory_Load_Favs";
    DirectorySearchActionTypes["Directory_Search"] = "Directory_Search";
    DirectorySearchActionTypes["Directory_Update_Paging"] = "Directory_Update_Paging";
    DirectorySearchActionTypes["Directory_Update_Context"] = "Directory_Update_Context";
    DirectorySearchActionTypes["Directory_Update_Refiners"] = "Directory_Update_Refiners";
    DirectorySearchActionTypes["Directory_Update_View"] = "Directory_Update_View";
    DirectorySearchActionTypes["Directory_Update_Favs"] = "Directory_Update_Favs";
    DirectorySearchActionTypes["Directory_Update_FavsOnServer"] = "Directory_Update_FavsOnServer";
    DirectorySearchActionTypes["Directory_Update_IsLoading"] = "Directory_Update_IsLoading";
})(DirectorySearchActionTypes || (DirectorySearchActionTypes = {}));
