import PortletAlert from "app/components/common/portlet-alert";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { InstallWizardContext } from "../..";
import "./welcome.component.scss";
var InstallerStepWelcome = React.memo(function () {
    var iwc = React.useContext(InstallWizardContext);
    React.useEffect(function () {
        iwc.setStepProgress("welcome", true);
    }, []);
    return (React.createElement("div", { className: "installer-step-welcome fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { style: { fontSize: "14px" } },
                React.createElement("h1", { className: "ob", style: { marginBottom: "25px" } }, "Welcome to Orchestry"),
                React.createElement("p", null, "We are genuinely excited that you want to try our product!"),
                React.createElement("p", null, "To get started we need to install Orchestry in your organization's Office 365 environment"),
                React.createElement("p", null, "In order to install Orchestry you'll need global administrator rights."),
                React.createElement("p", null, "If you are a person who has these rights then please proceed with installation."),
                React.createElement("br", null),
                " ",
                React.createElement("br", null),
                React.createElement(PortletAlert, { type: "warning", icon: "info", size: "regular", title: "Global Administrator Rights are Required", message: "You must be a global administrator within your organization to proceed with this install" })))));
});
export default InstallerStepWelcome;
