var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LibraryTemplateListActionTypes } from "./types";
import { Generic_Toast_Notification } from "app/store/notifications";
import Store from "app/store";
export function Load(libraryTemplates) {
    Store.dispatch(UpdateIsLoading(true));
    if (libraryTemplates === undefined)
        libraryTemplates = [];
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_Load,
        payload: __spreadArray([], libraryTemplates, true)
    };
}
export function Refresh() {
    Store.dispatch(UpdateIsLoading(true));
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_Refresh,
        payload: []
    };
}
export function LoadForCopy() {
    Store.dispatch(UpdateIsLoading(true));
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_LoadForTemplateCopy,
        payload: []
    };
}
export function AddItem(libraryTemplate) {
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Add,
        payload: __assign({}, libraryTemplate)
    };
}
export function UpdateItem(libraryTemplate) {
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Update,
        payload: __assign({}, libraryTemplate)
    };
}
export function DeleteItem(libraryTemplate) {
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Delete,
        payload: __assign({}, libraryTemplate)
    };
}
export function UpdateIsLoading(isLoading) {
    return {
        type: LibraryTemplateListActionTypes.LibraryTemplate_List_Update_Is_Loading,
        payload: isLoading
    };
}
export var TemplateCopiedSuccess = function () {
    return {
        type: Generic_Toast_Notification.Generic_Success,
        payload: { key: "Template_Copied_Success", actionType: LibraryTemplateListActionTypes.Template_Copied_Success }
    };
};
export var libraryTemplateListMiddlware = function (store, next, action) {
    // if( action.type === LibraryTemplateListActionTypes.LibraryTemplate_List_Load ||
    //     action.type === LibraryTemplateListActionTypes.LibraryTemplate_List_Refresh ||
    //     action.type === LibraryTemplateListActionTypes.LibraryTemplate_List_LoadForTemplateCopy
    //     ){
    //     action.payload.forEach((temp:LibraryTemplate) => {
    //         if(temp.AssociatedFeatures){
    //             temp.AssociatedFeatures.forEach((feat:LibraryFeature) => {
    //                 if(feat.Icon !== undefined)
    //                     feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //             });
    //         }
    //     });
    //     next(action);
    // }
    // else {
    next(action);
    //}
};
