var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// import { MiddlewareFunction} from 'app/store/middleware'
import { LifecycleGuestPolicyCurrentActionTypes } from "./types";
import generateGuid from "app/utils/generateGuid";
import Store from "app/store";
import { cloneDeep } from "lodash";
import { LifecycleGuestPolicyType, ReviewRecurrence, GuestLifecycleTimeUnit, ExecutionApprovers, ReviewNoActionType } from "../types";
import { initialRequestNotificationText, initialReviewNotificationText } from "./constants";
export function Load(policyType, guid) {
    var policy = createEmptyPolicy(policyType, guid);
    return {
        type: LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Load,
        payload: policy
    };
}
export function Update(policy) {
    return {
        type: LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Update,
        payload: policy
    };
}
export function UpdateProperties(properties) {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecycleGuestPoliciesCurrentState.item);
    return {
        type: LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Update,
        payload: __assign(__assign({}, policyState), properties)
    };
}
export function Clear() {
    return {
        type: LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Clear
    };
}
export var guestPolicyCurrentMiddlware = function (store, next, action) {
    next(action);
};
export var defaultGuestDetailOptions = [
    {
        id: "givenName",
        label: "First Name",
        isDefault: true
    },
    {
        id: "surname",
        label: "Last Name",
        isDefault: true
    },
    {
        id: "mail",
        label: "Email",
        isDefault: true
    },
    {
        id: "jobTitle",
        label: "Job Title"
    },
    {
        id: "city",
        label: "City"
    },
    {
        id: "department",
        label: "Department"
    },
    {
        id: "state",
        label: "State or Province"
    },
    {
        id: "country",
        label: "Country"
    },
    {
        id: "companyName",
        label: "Company"
    },
    {
        id: "mobilePhone",
        label: "Mobile Phone"
    }
];
export var createEmptyPolicy = function (policyType, guid) {
    var newPolicy = {
        GUID: guid === undefined ? generateGuid() : guid,
        Name: undefined,
        Description: undefined,
        PolicyType: policyType,
        PolicyExecutionType: undefined,
        InactivityThreshold: 30,
        Notification_Formats: "email|teams-alert",
        Type: policyType,
        GuestNamingConventionEnabled: false,
        GuestReviewRecurrence: policyType === LifecycleGuestPolicyType.Review ? ReviewRecurrence.SemiAnnually : undefined,
        ReviewApprovers: policyType === LifecycleGuestPolicyType.Review ? ExecutionApprovers.WorkspaceOwners : undefined,
        ReviewNotificationRetryCadence: policyType === LifecycleGuestPolicyType.Review ? 1 : undefined,
        ReviewNotificationRetryCadenceUnit: policyType === LifecycleGuestPolicyType.Review ? GuestLifecycleTimeUnit.Days : undefined,
        ReviewMaximumNotificationRetries: policyType === LifecycleGuestPolicyType.Review ? 3 : undefined,
        ReviewNoActionTakenAfterNumber: policyType === LifecycleGuestPolicyType.Review ? 1 : undefined,
        ReviewNoActionTakenAfterUnit: policyType === LifecycleGuestPolicyType.Review ? GuestLifecycleTimeUnit.Weeks : undefined,
        ReviewNoActionTakenActionType: policyType === LifecycleGuestPolicyType.Review ? ReviewNoActionType.RemoveNonReviewed : undefined,
        ReviewNotificationText: policyType === LifecycleGuestPolicyType.Review ? initialReviewNotificationText : undefined,
        GuestDetailOptions: defaultGuestDetailOptions,
        AllowGuestAccessForWorkspaces: true,
        RequireGuestJustification: false,
        RequestApprovers: policyType === LifecycleGuestPolicyType.Request ? ExecutionApprovers.WorkspaceOwners : undefined,
        RequestNotificationRetryCadence: policyType === LifecycleGuestPolicyType.Request ? 1 : undefined,
        RequestNotificationRetryCadenceUnit: policyType === LifecycleGuestPolicyType.Request ? GuestLifecycleTimeUnit.Days : undefined,
        RequestMaximumNotificationRetries: policyType === LifecycleGuestPolicyType.Request ? 3 : undefined,
        RequestNotificationText: policyType === LifecycleGuestPolicyType.Request ? initialRequestNotificationText : undefined
    };
    return newPolicy;
};
