import * as React from "react";
import "./orchestry-admin-etl-crawl-stats-workspaces.scss";
import { Row, Col, Form } from "react-bootstrap";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import * as TableList from "app/components/common/table-list";
import OrchModal from "app/components/common/orch-modal";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import CrawlerService from "app/services/crawler";
import moment from "moment";
// This component facilitates the searching and the viewing of a workspaces detailed stats.
var OrchestryAdminEtlCrawlStatsWorkspaces = React.memo(function (props) {
    var _a = React.useState(undefined), workspaceSearchTerm = _a[0], setWorkspaceSearchTerm = _a[1];
    var _b = React.useState(false), searchLoading = _b[0], setSearchLoading = _b[1];
    var _c = React.useState(null), searchResults = _c[0], setSearchResults = _c[1];
    React.useEffect(function () {
        loadSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceSearchTerm]);
    var loadSearchResults = function () {
        if (workspaceSearchTerm !== undefined) {
            setSearchLoading(true);
            CrawlerService.searchForWorkspacesForDetailedStats(props.Tenant.TenantGuid, workspaceSearchTerm).then(function (items) {
                setSearchLoading(false);
                setSearchResults(items);
            });
        }
    };
    //getWorkspaceDetailedStats
    var rowClick = function () { };
    return (React.createElement("div", { className: "orchestry-admin-etl-crawl-stats-workspaces" },
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Search for Workspaces by name"),
            React.createElement(Form.Control, { placeholder: "Workspace Name", autoComplete: "off", defaultValue: workspaceSearchTerm, onChange: function (event) { return setWorkspaceSearchTerm(event.target.value); } })),
        searchResults != null ? (React.createElement(TableList.Context, { listItems: searchResults, showLoader: false, wrappedInPortlet: false },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", { className: "title-button" },
                        React.createElement(OrchModal, { simpleButton: true, buttonContents: item.Title, size: "xl", headerText: "Details > " + item.Title, modalClassName: "etl-crawler-etl-stats-workspace-details", modalBgClassName: "etl-crawler-etl-stats-workspace-details-bg", showCloseButton: true },
                            React.createElement(OrchestryAdminEtlCrawlStatsWorkspaceDetail, { Tenant: props.Tenant, ReportWorkspace: item })))); } })))) : searchLoading == true ? (React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
            React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
            React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
            React.createElement(SkeletonFakeTableCol, { widthPercent: 17 },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
            React.createElement(SkeletonFakeTableCol, null,
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
            React.createElement(SkeletonFakeTableCol, { widthPercent: 20 },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })))) : null));
});
export default OrchestryAdminEtlCrawlStatsWorkspaces;
// This component facilitates the searching and the viewing of a workspaces detailed stats.
var OrchestryAdminEtlCrawlStatsWorkspaceDetail = React.memo(function (props) {
    var _a = React.useState(false), loadingDetails = _a[0], setLoadingDetails = _a[1];
    var _b = React.useState(null), workspaceDetails = _b[0], setWorkspaceDetails = _b[1];
    React.useEffect(function () {
        loadDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ReportWorkspace]);
    var loadDetails = function () {
        setLoadingDetails(true);
        CrawlerService.getWorkspaceDetailedStats(props.Tenant.TenantGuid, props.ReportWorkspace.SiteId).then(function (item) {
            setLoadingDetails(false);
            setWorkspaceDetails(item);
        });
    };
    return (React.createElement("div", { className: "orchestry-admin-etl-crawl-stats-workspace-details" },
        React.createElement("div", null,
            React.createElement(Row, null,
                React.createElement(Col, { md: 6 },
                    React.createElement("div", null,
                        React.createElement(Portlet, null,
                            React.createElement(PortletHeader, { title: "Workspace Properties" }),
                            React.createElement(PortletBody, null,
                                React.createElement("div", null, Object.keys(props.ReportWorkspace).map(function (key, index) { return (React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement("strong", null, key)),
                                    React.createElement(Col, null, props.ReportWorkspace[key]))); })))))),
                React.createElement(Col, { md: 6 }, workspaceDetails != null ? (React.createElement("div", null,
                    React.createElement(Portlet, null,
                        React.createElement(PortletHeader, { title: "Members" }),
                        React.createElement(PortletBody, null,
                            React.createElement("div", null,
                                React.createElement(Row, null,
                                    React.createElement(Col, { sm: 6 },
                                        React.createElement("strong", null, "Principal Id")),
                                    React.createElement(Col, { sm: 3 },
                                        React.createElement("strong", null, "Principal Type")),
                                    React.createElement(Col, { sm: 3 },
                                        React.createElement("strong", null, "Team Member Type"))),
                                workspaceDetails.Memberships.map(function (membership) { return (React.createElement(Row, null,
                                    React.createElement(Col, { sm: 6 }, membership.PrincipalId),
                                    React.createElement(Col, { sm: 3 }, membership.PrincipalType),
                                    React.createElement(Col, { sm: 3 }, membership.TeamMemberType))); })))),
                    React.createElement(Portlet, null,
                        React.createElement(PortletHeader, { title: "Insights" }),
                        React.createElement(PortletBody, null,
                            React.createElement("div", { className: "insights-portlet" }, workspaceDetails.Insights.map(function (insight) { return (React.createElement("div", { className: "insight" }, insight.WorkspaceInsightType)); })))),
                    React.createElement(Portlet, null,
                        React.createElement(PortletHeader, { title: "Crawl History" }),
                        React.createElement(PortletBody, null,
                            React.createElement("div", { className: "history-portlet" }, workspaceDetails.CrawlHistory.map(function (crawlHistoy) { return (React.createElement("div", { className: "crawl-history" },
                                React.createElement("div", { className: "crawl-date" }, moment(crawlHistoy.CrawlDay).format("LL")),
                                React.createElement("div", { className: "crawl-plans" }, crawlHistoy.CrawlPlans.map(function (cp) { return (React.createElement("div", { className: "plan" }, cp)); })))); })))))) : null)))));
});
