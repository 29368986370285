import * as React from "react";
var ReportColumnInsights = React.memo(function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "insights" },
            Math.floor(Math.random() * 10) > 5 && (React.createElement("div", { className: "insight-item type-1" },
                React.createElement("i", { className: "fas fa-anchor" }))),
            Math.floor(Math.random() * 10) > 5 && (React.createElement("div", { className: "insight-item type-2" },
                React.createElement("i", { className: "fas fa-archive" }))),
            Math.floor(Math.random() * 10) > 5 && (React.createElement("div", { className: "insight-item type-3" },
                React.createElement("i", { className: "fas fa-bolt" }))),
            Math.floor(Math.random() * 10) > 5 && (React.createElement("div", { className: "insight-item type-4" },
                React.createElement("i", { className: "fas fa-boxes" }))))));
});
export default ReportColumnInsights;
