import { useDeleteHealthChecksMutation } from "appv2/api/health-checks/health-checks.api";
import { useIntl } from "react-intl";
export var useDeleteHealthCheckModal = function (tenantGuid) {
    var intl = useIntl();
    var _a = useDeleteHealthChecksMutation(), deleteHealthCheck = _a[0], _b = _a[1], isLoading = _b.isLoading, isSuccess = _b.isSuccess;
    var onDeleteClicked = function (uniqueId) {
        deleteHealthCheck({
            userType: tenantGuid ? "partner" : "customer",
            deleteHealthChecksCommand: { UniqueId: uniqueId, TenantGuid: tenantGuid }
        });
    };
    return {
        intl: intl,
        onDeleteClicked: onDeleteClicked,
        isLoading: isLoading,
        isSuccess: isSuccess
    };
};
