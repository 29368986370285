/**
 * Enum representing Health Check Type
 */
export var HealthCheckType;
(function (HealthCheckType) {
    /**
     * Tenant
     */
    HealthCheckType[HealthCheckType["Tenant"] = 0] = "Tenant";
})(HealthCheckType || (HealthCheckType = {}));
