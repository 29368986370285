import { Skeleton } from "@mantine/core";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import React, { forwardRef } from "react";
export var OrchestryLogo = forwardRef(function (_a, ref) {
    var isLoading = _a.isLoading;
    if (isLoading) {
        return React.createElement(Skeleton, { height: "32px", width: "132px" });
    }
    return (React.createElement("div", { ref: ref },
        React.createElement("img", { src: ORCH_SVG_ICONS.LogoLarge, alt: "logo" })));
});
