import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useSelector } from "react-redux";
/**
 * Hook to get the current user roles from the orchestry context.
 */
export var useCurrentUserRoles = function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentUserRoles = orchestryContext.CurrentUserRoles;
    var currentUserHasRole = function (role) { return currentUserRoles === null || currentUserRoles === void 0 ? void 0 : currentUserRoles.includes(role); };
    var currentUserIsBeaconUser = (currentUserRoles === null || currentUserRoles === void 0 ? void 0 : currentUserRoles.includes(OrchRole.BeaconUser)) || (currentUserRoles === null || currentUserRoles === void 0 ? void 0 : currentUserRoles.includes(OrchRole.BeaconAdmin));
    var currentUserIsAdmin = (currentUserRoles === null || currentUserRoles === void 0 ? void 0 : currentUserRoles.includes(OrchRole.Admin)) || (currentUserRoles === null || currentUserRoles === void 0 ? void 0 : currentUserRoles.includes(OrchRole.BeaconAdmin));
    return {
        currentUserRoles: currentUserRoles,
        currentUserHasRole: currentUserHasRole,
        currentUserIsBeaconUser: currentUserIsBeaconUser,
        currentUserIsAdmin: currentUserIsAdmin
    };
};
