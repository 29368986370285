import { useIntl } from "react-intl";
import { mapRecommendationFilterNameToIcon } from "./recommendation.utils";
import { useRecommendationsPage } from "./use-recommendations-page.hook";
/**
 * Gets the name and icon of a category
 * @param productGuid The guid of the category
 */
export var useRecommendationCategory = function (categoryGuid) {
    var _a, _b, _c, _d, _e;
    var recommendationCategories = useRecommendationsPage().recommendationCategories;
    var intl = useIntl();
    var category = (_a = recommendationCategories === null || recommendationCategories === void 0 ? void 0 : recommendationCategories.filter(function (category) { return category.Guid.toLowerCase() === categoryGuid.toLowerCase(); })[0]) !== null && _a !== void 0 ? _a : null;
    var categoryIcon = mapRecommendationFilterNameToIcon((_b = category === null || category === void 0 ? void 0 : category.Name) === null || _b === void 0 ? void 0 : _b.toLowerCase());
    var intlId = (_d = (_c = category === null || category === void 0 ? void 0 : category.Name) === null || _c === void 0 ? void 0 : _c.toLowerCase().replace(/ /g, "")) !== null && _d !== void 0 ? _d : "id";
    var localizedCategoryName = intl.formatMessage({ id: intlId, defaultMessage: (_e = category === null || category === void 0 ? void 0 : category.Name) !== null && _e !== void 0 ? _e : "Category" });
    return {
        category: { name: localizedCategoryName, icon: categoryIcon }
    };
};
