import { Highlight } from "@mantine/core";
import PrincipalItem from "app/components/common/principal-item";
import moment from "moment";
import React from "react";
export var TenantEventBrowserTenantNameColumn = function (row, highlightSearchWords) {
    var tenantGuid = row.getValue("TenantGUID");
    var tenantName = row.getValue("TenantName");
    return (React.createElement("a", { href: "/orchestry-admin/tenants/details?GUID=".concat(tenantGuid), target: "_blank" },
        React.createElement(Highlight, { title: tenantGuid, c: "text-primary.0", size: "14px", fw: 500, lh: "20px", highlight: highlightSearchWords }, tenantName)));
};
export var TenantEventBrowserAssociatedUserColumn = function (row) {
    var value = (row.getValue("AssociatedUserDisplayName") || row.getValue("AssociatedUser"));
    return React.createElement("div", null, value);
};
export var TenantEventBrowserTableTextColumn = function (value) {
    var displayValue = value !== null && value !== void 0 ? value : "null";
    return React.createElement("div", null, displayValue);
};
export var TenantEventBrowserTableDateColumn = function (value) {
    var date = moment(value);
    var formattedDate = date.locale(navigator.language).format("LLLL");
    return React.createElement("div", null, formattedDate);
};
export var TenantEventBrowserPrincipalColumn = function (userGuid) {
    return React.createElement(PrincipalItem, { principal: { GUID: userGuid } });
};
