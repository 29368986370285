import { AsyncCallType } from "app/store/async/types";
import { TeamInformationCurrentActionTypes } from "./types";
export var teamInformationCurrentActionMap = [
    {
        actionType: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamInformation,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/TeamInformation?teamId={TeamId}&microsoftAppType={MicrosoftAppType}",
        responseDataType: {}
    },
    {
        actionType: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamLinks,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/TeamInformation/Links?teamId={TeamId}"
    },
    {
        actionType: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamLinks_NotebookAndPlanner,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/TeamInformation/LinksPlannerAndOnenote?teamId={TeamId}"
    },
    {
        actionType: TeamInformationCurrentActionTypes.TeamInformation_Current_Load_DirectoryMetadata,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/TeamInformation/DirectoryMetadata?teamId={GroupId}",
        responseDataType: {}
    }
];
