var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Group, Stack, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryAnnotation } from "appv2/components/orchestry-annotation/orchestry-annotation.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import { OrchestryTextArea } from "appv2/components/orchestry-text-area/orchestry-text-area.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import withIntlProvider from "appv2/components/orchestry-with-intl-provider-hoc/orchestry-with-intl-provider-hoc.component";
import React from "react";
import { useAddAnnotationModal } from "./use-add-annotation-modal.hook";
export var AddAnnotationModalContent = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, healthCheckId = _b.healthCheckId, annotationCategory = _b.annotationCategory, associatedGUID = _b.associatedGUID, tenantGuid = _b.tenantGuid, onDeleteAnnotationClicked = _b.onDeleteAnnotationClicked, hideAnnotationList = _b.hideAnnotationList, shouldShowMutativeButtons = _b.shouldShowMutativeButtons;
    var _c = useAddAnnotationModal({
        context: context,
        id: id,
        innerProps: { healthCheckId: healthCheckId, annotationCategory: annotationCategory, tenantGuid: tenantGuid, associatedGUID: associatedGUID, hideAnnotationList: hideAnnotationList, shouldShowMutativeButtons: shouldShowMutativeButtons }
    }), intl = _c.intl, annotations = _c.annotations, form = _c.form, isLoading = _c.isLoading, handleFormSubmit = _c.handleFormSubmit, saveIsDisabled = _c.saveIsDisabled, shouldRenderAnnotationsList = _c.shouldRenderAnnotationsList;
    return (React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
        React.createElement(Stack, { gap: 16, ff: "var(--mantine-font-family)" },
            shouldRenderAnnotationsList ? (annotations === null || annotations === void 0 ? void 0 : annotations.map(function (annotation) {
                var _a, _b, _c, _d;
                return (React.createElement("div", { key: annotation.UniqueId },
                    React.createElement(OrchestryAnnotation, { body: annotation.Body, uniqueId: (_a = annotation.UniqueId) !== null && _a !== void 0 ? _a : "", userId: annotation.AuthorDisplayName, annotationDate: (_b = annotation.UpdatedDate) !== null && _b !== void 0 ? _b : "", onDeleteClicked: shouldShowMutativeButtons ? onDeleteAnnotationClicked : undefined, authorDisplayName: (_c = annotation.AuthorDisplayName) !== null && _c !== void 0 ? _c : "", authorBase64Photo: (_d = annotation.AuthorBase64Photo) !== null && _d !== void 0 ? _d : "" },
                        React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-text-secondary-0)", size: "12px", fw: 400, lh: "18px" }, annotation.Body))));
            })) : (React.createElement(Group, { gap: 16 },
                React.createElement(OrchestryFeaturedIcon, { variant: "light-circle", size: "lg", colorVariant: "primary-6", iconClassName: ORCH_ICONS.plusCircle }),
                React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check.annotations.button.label", defaultMessage: "Add Annotation" })))),
            shouldShowMutativeButtons && (React.createElement(OrchestryTextArea, __assign({ value: form.getInputProps("Body").value, placeholder: intl.formatMessage({ id: "page.health_check.annotation_modal.placeholder", defaultMessage: "Add your annotation here..." }), c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("Body").error }, form.getInputProps("Body"), { label: intl.formatMessage({ id: "page.health_check.annotation_modal.label", defaultMessage: "Annotation" }), maxLength: 2000, description: intl.formatMessage({ id: "page.health_check.annotation_modal.max_chars", defaultMessage: "Max 2000 Characters" }), autosize: true, minRows: 4 }))),
            React.createElement(Group, { gap: 12, justify: "right" }, shouldShowMutativeButtons ? (React.createElement(React.Fragment, null,
                React.createElement(OrchestryButton, { disabled: isLoading, variant: "white", size: "md", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "controls.cancel", defaultMessage: "Cancel" })),
                React.createElement(OrchestrySaveButton, { disabled: saveIsDisabled, variant: "primary", type: "submit" }, intl.formatMessage({ id: "controls.save", defaultMessage: "Save" })))) : (React.createElement(OrchestryButton, { disabled: isLoading, variant: "white", size: "md", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "controls.close", defaultMessage: "Close" })))))));
};
export var AddAnnotationModal = withIntlProvider(AddAnnotationModalContent);
