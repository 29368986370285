import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./orch-modal.scss";
import { FormattedMessage } from "react-intl";
//class DocumentsModal extends React.Component<IProps,IState> {
var OrchModal = React.memo(function (props, state) {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var toggleModal = function () {
        var currentShow = props.showModalOverride !== undefined ? props.showModalOverride : showModal;
        if (currentShow === false && props.onOpen !== undefined) {
            props.onOpen();
        }
        if (currentShow === true && props.onClose !== undefined) {
            props.onClose();
        }
        if (props.showModalToggleOverride !== undefined && props.showModalOverride !== undefined) {
            props.showModalToggleOverride(!props.showModalOverride);
        }
        else {
            setShowModal(!showModal);
        }
    };
    return (React.createElement(React.Fragment, null,
        props.buttonContents !== undefined ? (React.createElement(React.Fragment, null, props.simpleButton === undefined || (props.simpleButton !== undefined && props.simpleButton === false) ? (React.createElement("div", { className: "button-custom b-orange", onClick: function () { return toggleModal(); } }, props.buttonContents)) : (React.createElement("span", { onClick: function () { return toggleModal(); } }, props.buttonContents)))) : null,
        React.createElement(Modal, { size: props.size !== undefined && props.size !== "md" ? props.size : "lg", centered: true, show: props.showModalOverride !== undefined ? props.showModalOverride : showModal, onHide: function () {
                if (props.allowClose !== undefined && props.allowClose === false) {
                }
                else {
                    toggleModal();
                }
            }, animation: true, className: "orch-modal " + (props.modalClassName !== undefined ? props.modalClassName : undefined) + (props.size !== undefined && props.size === "md" ? " orch-modal-size-md" : ""), backdropClassName: props.modalBgClassName !== undefined ? props.modalBgClassName : undefined },
            props.headerContents !== undefined || props.headerText !== undefined || (props.showCloseButton !== undefined && props.showCloseButton === true) ? (React.createElement(Modal.Header, { closeButton: props.showCloseButton !== undefined ? props.showCloseButton : false },
                props.headerText !== undefined && React.createElement(Modal.Title, null, props.headerText),
                props.headerContents !== undefined && React.createElement(React.Fragment, null, props.headerContents))) : null,
            React.createElement(Modal.Body, null, props.children),
            props.footerContents !== undefined ? (React.createElement(Modal.Footer, null,
                props.footerCloseText !== undefined ? (React.createElement(Button, { variant: "secondary", onClick: function () { return toggleModal(); } }, props.footerCloseText !== null && props.footerCloseText !== undefined ? (React.createElement(React.Fragment, null, props.footerCloseText)) : (React.createElement(FormattedMessage, { id: "components.orch-modal.button.default.close", defaultMessage: "Close" })))) : null,
                props.footerContents)) : null,
            props.footerPrimaryFunction === undefined && props.footerPrimaryText === undefined ? null : (React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: function () { return toggleModal(); } }, props.footerCloseText !== null && props.footerCloseText !== undefined ? (React.createElement(React.Fragment, null, props.footerCloseText)) : (React.createElement(FormattedMessage, { id: "components.orch-modal.button.default.close", defaultMessage: "Close" }))),
                props.valid === undefined || (props.valid !== undefined && props.valid === true) ? (React.createElement(Button, { variant: "primary", onClick: function () {
                        props.footerPrimaryFunction !== undefined && props.footerPrimaryFunction();
                        toggleModal();
                    } }, props.footerPrimaryText !== null && props.footerPrimaryText !== undefined ? (React.createElement(React.Fragment, null, props.footerPrimaryText)) : (React.createElement(FormattedMessage, { id: "components.orch-modal.button.default.save_changes", defaultMessage: "Save Changes" })))) : (React.createElement(Button, { variant: "secondary" }, props.footerPrimaryText !== null && props.footerPrimaryText !== undefined ? (React.createElement(React.Fragment, null, props.footerPrimaryText)) : (React.createElement(FormattedMessage, { id: "components.orch-modal.button.default.save_changes", defaultMessage: "Save Changes" })))))))));
});
export default OrchModal;
