import * as TenantCurrentStore from "app/store/tenants/current";
import { AsyncCallType } from "app/store/async/types";
export var tenantCurrentActionMap = [
    {
        actionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "tenantListState.items",
        keyType: "guid",
        ApiUrl: "/api/Tenants/?guid={key}",
        responseDataType: {}
    },
    {
        actionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Load_With_Info,
        callType: AsyncCallType.Get,
        //stateLocationForArray: "tenantListState.items",
        keyType: "guid",
        ApiUrl: "/api/Tenants/GetWithInfo/?guid={key}",
        responseDataType: {}
    },
    // {
    //     actionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Update,
    //     callType: AsyncCallType.Update,
    //     keyType:"typeName",
    //     ApiUrl: "/api/Tenants",
    //     responseDataType: {}//,
    //     // invalidates: [
    //     //     FeatureListTypes.FeatureListActionTypes.Feature_List_Load,
    //     //     DocumentTemplateListTypes.DocumentTemplateListActionTypes.DocumentTemplate_List_Load,
    //     //     ChannelListTypes.ChannelListActionTypes.Channel_List_Load
    //     // ]
    // },
    {
        actionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Silent_Update,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/Tenants",
        responseDataType: {}
    }
];
