import { Card, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { CardKeyValue } from "../card-key-value.component";
import { OrchestryCardTitle } from "../orchestry-card-title.component";
import { useTenantPage } from "../use-tenant-page.hook";
export var SignUpFormDetails = function () {
    var _a = useTenantPage(), _b = _a.tenantDetails, TenantGuid = _b.TenantGuid, TenantUrl = _b.TenantUrl, LicenseKey = _b.LicenseKey, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, mih: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Signup Form Details", icon: ORCH_ICONS.listChecks, colorVariant: "blue-7" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Tenant GUID", value: TenantGuid }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Tenant URL", value: TenantUrl }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "License Key", value: LicenseKey }))));
};
