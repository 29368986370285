var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import FontIcon from "app/components/common/font-icon";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import PortletAlert from "app/components/common/portlet-alert";
import convertStringToSafeUrl from "app/utils/convertStringToSafeUrl";
import generateGuid from "app/utils/generateGuid";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./form-naming-policy.scss";
var FormNamingPolicy = React.memo(function (props) {
    var componentUID = React.useState(generateGuid())[0];
    var _a = React.useState(false), formValid = _a[0], setFormValid = _a[1];
    var _b = React.useState(props.type), policyType = _b[0], setPolicyType = _b[1];
    React.useEffect(function () {
        if (props.type === undefined) {
            setPolicyType("name");
        }
        else {
            setPolicyType(props.type);
        }
    }, [props.type]);
    React.useEffect(function () {
        if (props.onFormValidChange !== undefined) {
            props.onFormValidChange(formValid);
        }
    }, [formValid]);
    var namingConventionTypes = React.useState([
        {
            id: "1",
            title: "Metadata",
            value: "metadata"
        },
        {
            id: "2",
            title: "String",
            value: "string"
        }
    ])[0];
    var metadataTypes = React.useState([
        {
            id: "1",
            title: "Department",
            value: "Department"
        },
        {
            id: "2",
            title: "Company",
            value: "Company"
        },
        {
            id: "3",
            title: "Office",
            value: "Office"
        },
        {
            id: "4",
            title: "StateOrProvince",
            value: "StateOrProvince"
        },
        {
            id: "5",
            title: "CountryOrRegion",
            value: "CountryOrRegion"
        },
        {
            id: "6",
            title: "JobTitle",
            value: "JobTitle"
        },
        {
            id: "7",
            title: "Date - US Mode MM/DD/YYYY",
            value: "Date-us-long"
        },
        {
            id: "8",
            title: "Date - UK Mode DD/MM/YYYY",
            value: "Date-uk-long"
        },
        {
            id: "12",
            title: "Date - Sort Friendly Mode YYYY/MM/DD",
            value: "Date-sortfriendly-long"
        },
        {
            id: "9",
            title: "Date - Short US Mode M/D/YY",
            value: "Date-us-short"
        },
        {
            id: "10",
            title: "Date - Short UK Mode D/M/YY",
            value: "Date-uk-short"
        },
        {
            id: "11",
            title: "Date - Short Sort Friendly Mode YY/M/D",
            value: "Date-sortfriendly-short"
        }
    ])[0];
    var update = function (psfix, action) {
        var psFixArray = [];
        psFixArray = props.value !== null && props.value !== "" ? __spreadArray([], JSON.parse(props.value), true) : [];
        if (action === "add") {
            var newFix = {
                GUID: generateGuid(),
                type: psfix.type,
                value: ""
            };
            psFixArray = __spreadArray(__spreadArray([], psFixArray, true), [newFix], false);
        }
        if (action === "update") {
            psFixArray = psFixArray.map(function (ps) { return (ps.GUID === psfix.GUID ? psfix : ps); });
        }
        if (action === "remove") {
            psFixArray = psFixArray.filter(function (ps) { return (ps.GUID === psfix.GUID ? false : true); });
        }
        var jsonObject = JSON.stringify(psFixArray);
        props.onChange(jsonObject);
    };
    return (React.createElement("div", { className: "form-naming-policy" },
        React.createElement(Row, null,
            React.createElement(Col, null),
            React.createElement(Col, null,
                React.createElement("div", { className: "info-right" }, props.value !== null && props.value !== undefined && __spreadArray([], JSON.parse(props.value), true).some(function (fix) { return fix.type !== "metadata"; }) ? (React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", message: "Please note that any special characters will be stripped out due to not being supported for URL's" })) : null))),
        React.createElement("div", null,
            React.createElement("div", { className: "fix-editor" },
                props.value !== null && props.value !== undefined && props.value !== ""
                    ? __spreadArray([], JSON.parse(props.value), true).map(function (fix, fixIndex) { return (React.createElement(Row, { className: "fix-item editable-item", key: fixIndex },
                        React.createElement(Col, { sm: 6, className: "fix-left" },
                            React.createElement("div", { className: "count" }, fixIndex + 1),
                            React.createElement(FormDropdownSelect, { list: namingConventionTypes, value: fix.type, onChange: function (value) {
                                    update(__assign(__assign({}, fix), { type: value }), "update");
                                } })),
                        React.createElement(Col, { sm: 6, className: "fix-right" },
                            fix.type === "metadata" ? (React.createElement(React.Fragment, null,
                                React.createElement(FormDropdownSelect, { list: props.metadataTypes === null && props.metadataTypes === undefined
                                        ? metadataTypes
                                        : metadataTypes.filter(function (mdt) { var _a; return (_a = props.metadataTypes) === null || _a === void 0 ? void 0 : _a.some(function (mt) { return mdt.value === mt; }); }), value: fix.value, showRedOutlineIfNothingSelected: true, onChange: function (value) {
                                        update(__assign(__assign({}, fix), { value: value }), "update");
                                    } }),
                                React.createElement("div", null,
                                    React.createElement(FormValidator, { UniqueIdentifier: "title-prefix-dropdown-" + fixIndex + componentUID, Validators: ["dropdown-has-value"], Value: fix.value, Category: props.validatorCategory, FieldName: "Metadata Type" })))) : (React.createElement(React.Fragment, null,
                                React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "title-prefix-string-input-" + fixIndex + componentUID, ClassNameNotValid: "red-outline" },
                                    React.createElement(Form.Control, { type: "text", placeholder: "", value: fix.value, onChange: function (event) {
                                            if (policyType === "name") {
                                                update(__assign(__assign({}, fix), { value: event.target.value }), "update");
                                            }
                                            else {
                                                update(__assign(__assign({}, fix), { value: convertStringToSafeUrl(event.target.value) }), "update");
                                            }
                                        } })),
                                React.createElement("span", null,
                                    React.createElement(FormValidator, { UniqueIdentifier: "title-prefix-string-input-" + fixIndex + componentUID, Validators: ["string-not-empty"], Value: fix.value, Category: props.validatorCategory, FieldName: "String" })))),
                            React.createElement("div", { className: "remove", onClick: function () {
                                    update(fix, "remove");
                                } },
                                React.createElement(FontIcon, { iconString: "times" }))))); })
                    : null,
                React.createElement(Row, { className: "fix-item new-mode" },
                    React.createElement(Col, { sm: 6, className: "fix-left" },
                        React.createElement("div", { className: "count" },
                            React.createElement("i", { className: "fa fa-plus" })),
                        React.createElement(FormDropdownSelect, { placeholder: !props.placeholder ? "-- Select value --" : props.placeholder, list: namingConventionTypes, afterSelectReset: true, onChange: function (value) {
                                update({ GUID: "", type: value }, "add");
                            } })),
                    React.createElement(Col, { sm: 6, className: "fix-right" },
                        React.createElement("div", { className: "form-control" }),
                        React.createElement("div", { className: "remove" })))))));
});
export default FormNamingPolicy;
