import React from "react";
import "./team-information-actions.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TeamInformationCurrentStore from "app/store/team-information/current";
import { useTeamInformationActions } from "./team-information-actions.hooks";
var TeamInformationActions = function () {
    var dispatch = useDispatch();
    var intl = useIntl();
    var shouldShowActions = useTeamInformationActions();
    return shouldShowActions ? (React.createElement("div", { className: "team-information-actions" },
        React.createElement(Portlet, null,
            React.createElement(PortletHeader, { title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.component_title.workspace_actions", defaultMessage: "Workspace Actions" }) }),
            React.createElement(PortletBody, null,
                React.createElement("div", { className: "verticle-section-title" }, intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.workspace_actions", defaultMessage: "Workspace Actions" })),
                React.createElement("div", { className: "action-button" },
                    React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.button.add_guests", defaultMessage: "Add Guests" }), faIcon: "user-plus", color: "brandblue", noMargin: true, onClick: function () { return dispatch(TeamInformationCurrentStore.UpdateTeamState({ currentTabKey: "users", forceAddGuestOpen: true })); }, className: "button" })))))) : null;
};
export default TeamInformationActions;
