var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./library-channel-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as LibraryChannelListStore from "app/store/library-channels/list";
import * as LibraryChannelCurrentStore from "app/store/library-channels/current";
import generateGuid from "app/utils/generateGuid";
import { Col, Container, Form, Row } from "react-bootstrap";
import AssociateTemplate from "app/components/common/associate-template";
import { SubtitleButton } from "app/components/common/subtitle";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import * as LibraryConfigurationCurrentStore from "app/store/library-configuration/current";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
var LibraryChannelDetails = React.memo(function (props) {
    var dispatch = useDispatch();
    var lookupGuid = props.guid;
    var currentLibraryChannel = useSelector(function (state) { return state.libraryChannelCurrentState.item; });
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(false), formValid = _b[0], setFormValid = _b[1];
    var librarychannelPrivacyType = React.useState([
        {
            id: "0",
            title: "Standard",
            value: "Standard"
        },
        {
            id: "1",
            title: "Private",
            value: "Private"
        }
    ])[0];
    var currentLibraryConfiguration = useSelector(function (state) { return state.libraryConfigurationCurrentState.item; });
    var _c = React.useState(), channelCategories = _c[0], setChannelCategories = _c[1];
    React.useEffect(function () {
        dispatch(LibraryConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (currentLibraryConfiguration.GUID !== undefined) {
            if (currentLibraryConfiguration.GUID !== "") {
                if (currentLibraryConfiguration.ChannelCategoriesJson !== undefined &&
                    currentLibraryConfiguration.ChannelCategoriesJson !== null &&
                    currentLibraryConfiguration.ChannelCategoriesJson !== "") {
                    var availableCats = JSON.parse(currentLibraryConfiguration.ChannelCategoriesJson);
                    if (availableCats !== undefined && availableCats.length !== 0) {
                        var newOptionArray_1 = [];
                        availableCats.forEach(function (cat, index) {
                            newOptionArray_1.push({
                                id: index + "",
                                title: cat,
                                value: cat
                            });
                        });
                        setChannelCategories(newOptionArray_1);
                    }
                }
            }
        }
    }, [currentLibraryConfiguration]);
    console.log("LibraryChannel - Details - currentLibraryChannel", currentLibraryChannel);
    // Should only be run once.
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (currentLibraryChannel.GUID !== undefined) {
            dispatch(LibraryChannelCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current template when navigating to the new template page.
            var guid = generateGuid();
            // Add a new template with guid.
            dispatch(LibraryChannelCurrentStore.Update({
                GUID: guid,
                AssociatedTemplates: [],
                Privacy: "Standard"
            }));
        }
        else {
            // Load the /edit/guid load.
            dispatch(LibraryChannelCurrentStore.Load({ GUID: lookupGuid }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid]);
    var clearCurrentLibraryChannel = function () {
        dispatch(LibraryChannelCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(LibraryChannelCurrentStore.Clear());
        };
    }, []);
    var saveAndAddModifyLibraryChannel = function () {
        if (formValid) {
            console.log("LibraryChannel - Details - currentLibraryChannel", currentLibraryChannel);
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                // First, add the new template to template list
                asyncCallType = AsyncCallType.Add;
                dispatch(LibraryChannelListStore.AddItem(currentLibraryChannel));
            }
            else {
                dispatch(LibraryChannelListStore.UpdateItem(currentLibraryChannel));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentLibraryChannel.GUID).then(function () {
                setBackToList(true);
                dispatch(LibraryChannelCurrentStore.Clear());
            });
        }
    };
    var onAssociatedAdd = function (templateToAdd) {
        //console.log("ADD Ass Details > ", templateToAdd)
        var tempArray = [templateToAdd];
        //setAssociatedTemplates([...tempArray, ...currentLibraryChannel.AssociatedTemplates]);
        if (currentLibraryChannel.AssociatedTemplates !== undefined) {
            if (currentLibraryChannel.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), currentLibraryChannel.AssociatedTemplates, true);
            }
        }
        dispatch(LibraryChannelCurrentStore.Update({ AssociatedTemplates: tempArray }));
    };
    var onAssociatedRemove = function (tbaToRemove) {
        var _a;
        dispatch(LibraryChannelCurrentStore.Update({
            AssociatedTemplates: (_a = currentLibraryChannel.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    return (React.createElement("div", { className: "librarychannel-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "channelvalidation" },
            backToList && React.createElement(Redirect, { to: "/orchestry-admin/library/channels" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Channel Library", subTitle: props.guid ? "Edit" : "New", titleLink: "/orchestry-admin/library/channels" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/orchestry-admin/library/channels", onClick: function () { return clearCurrentLibraryChannel(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement("div", { onClick: function () { return saveAndAddModifyLibraryChannel(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "light" }))))) : null),
            currentLibraryChannel.GUID !== undefined && (React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                    React.createElement(Form.Label, null,
                                        "Name ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(Form.Control, { placeholder: "Enter Name", defaultValue: currentLibraryChannel.Title, onChange: function (event) { return dispatch(LibraryChannelCurrentStore.Update({ Title: event.target.value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "name", Validators: ["string-not-empty", "string-is-more-than-2", "string-is-less-than-51", "no-illegal-characters"], Value: currentLibraryChannel.Title })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                                    React.createElement(Form.Label, null, "Description (Optional)"),
                                    React.createElement(Form.Control, { as: "textarea", placeholder: "Tell people the purpose of this channel", rows: 3, defaultValue: currentLibraryChannel.Description, onChange: function (event) { return dispatch(LibraryChannelCurrentStore.Update({ Description: event.target.value })); } })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                                    React.createElement(Form.Label, null,
                                        "Privacy Type ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(FormDropdownSelect, { list: librarychannelPrivacyType, defaultValue: currentLibraryChannel.Privacy, onChange: function (value) { return dispatch(LibraryChannelCurrentStore.Update({ Privacy: value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "privacy-type", Validators: ["dropdown-has-value"], Value: currentLibraryChannel.Privacy })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                                    React.createElement(Form.Label, null, "Library Category"),
                                    channelCategories !== undefined ? (React.createElement(FormDropdownSelect, { list: channelCategories, defaultValue: currentLibraryChannel.Category, onChange: function (value) { return dispatch(LibraryChannelCurrentStore.Update({ Category: value })); } })) : null)))),
                    React.createElement("div", { className: "divider-line", style: { marginTop: "80px" } }),
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement("h3", null, "Associated templates"),
                                React.createElement("div", null, currentLibraryChannel.GUID !== undefined && (React.createElement(AssociateTemplate, { associatedTemplates: currentLibraryChannel.AssociatedTemplates ? currentLibraryChannel.AssociatedTemplates : [], onAssociatedAdd: function (ass) {
                                        onAssociatedAdd(ass);
                                    }, onAssociatedRemove: function (ass) {
                                        onAssociatedRemove(ass);
                                    }, libraryMode: true },
                                    React.createElement("div", { className: "at-search" },
                                        React.createElement(AssociateTemplate.Search, null)),
                                    React.createElement("div", { className: "at-count" },
                                        React.createElement(AssociateTemplate.Count, null)),
                                    React.createElement("div", { className: "at-items" },
                                        React.createElement(AssociateTemplate.SelectedItems, null))))))))))))));
});
export default LibraryChannelDetails;
