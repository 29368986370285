import axios from "axios";
import DeleteCheckService from "./delete-check";
var MetadataService = /** @class */ (function () {
    function MetadataService() {
    }
    MetadataService.IsUsedInNamingPolicy = function (guid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Metadata/IsUsedInNamingPolicy?guid=" + guid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                var deleteCheckData = response.data;
                if (deleteCheckData !== undefined) {
                    var editUrl_1 = DeleteCheckService.DeleteAPIUrls.find(function (dau) { return dau.Type === deleteCheckData.ItemType; });
                    if (editUrl_1 !== null) {
                        if (deleteCheckData.Items !== undefined) {
                            deleteCheckData.Items.forEach(function (gItem) {
                                gItem.DetailsUrl = (editUrl_1 === null || editUrl_1 === void 0 ? void 0 : editUrl_1.DetailsUrl) + gItem.GUID;
                                //console.log("Edit URL", editUrl, gItem);
                            });
                        }
                    }
                }
                resolve(deleteCheckData);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return MetadataService;
}());
export default MetadataService;
