import * as React from "react";
import "./lifecycle-policy-assignments.scss";
import { useSelector, useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeTextSmall } from "app/components/common/skeleton-loaders";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import moment from "moment";
import AnimatedLoader from "app/components/common/animated-loader";
import PolicySelector from "./archival-policy-selector";
import LifecycleApproval from "app/components/insights/workspaces/report-details/components/lifecycle-approval";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import LifecycleStrings from "app/components/insights/common/lifecycle-strings";
import { useIntl, FormattedMessage } from "react-intl";
var LifecyclePolicyAssignments = React.memo(function (_a) {
    var siteId = _a.siteId, minimalMode = _a.minimalMode, Slot = _a.Slot;
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    // const orchestryContext = useSelector(state => state.orchestryContextCurrentState.item);
    var intl = useIntl();
    var dispatch = useDispatch();
    var lifecycleReportContext = useSelector(function (state) { return state.lifecycleReportState[Slot].Context; });
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var isNewStatusLoading = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.isNonActiveStatusLoading; });
    var getStatus = function () {
        var returnStatus = "Active";
        if (policyWorkspace !== null && policyWorkspace.ArchivalStatus !== null) {
            return policyWorkspace.ArchivalStatus;
        }
        return returnStatus;
    };
    // Load the search results when we are not in the typical report listing context (Archival Approval Workflow page)
    React.useEffect(function () {
        if (minimalMode === true) {
            dispatch(LifecycleReportStore.LoadMinimal(Slot));
        }
    }, [minimalMode]);
    var getStatusText = function () {
        if (policyWorkspace !== null) {
            if (policyWorkspace.ArchivalStatus === "Active" || policyWorkspace.ArchivalStatus === null) {
                if (reportItem !== null &&
                    reportItem !== undefined &&
                    reportItem.DaysInactive !== null &&
                    reportItem.DaysInactive !== undefined &&
                    lifecycleReportContext !== null &&
                    lifecycleReportContext !== undefined &&
                    lifecycleReportContext.InactiveDays !== null &&
                    lifecycleReportContext.InactiveDays !== undefined &&
                    reportItem.DaysInactive >= lifecycleReportContext.InactiveDays) {
                    return "Inactive";
                }
                else {
                    return "Active";
                }
            }
            if (policyWorkspace.ArchivalStatus === "PendingApproval") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.pending", defaultMessage: "Pending Approval" });
            }
            if (policyWorkspace.ArchivalStatus === "Extended") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.extended", defaultMessage: "Workspace Renewed" });
            }
            if (policyWorkspace.ArchivalStatus === "ReadyToArchive") {
                if (currentPolicy !== null && currentPolicy !== undefined && currentPolicy.Option_Content_Action === "Delete") {
                    return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.ready_to_archive_delete", defaultMessage: "Ready to Delete" });
                }
                else {
                    return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.ready_to_archive", defaultMessage: "Ready to Archive" });
                }
            }
            if (policyWorkspace.ArchivalStatus === "Processing") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.processing", defaultMessage: "Processing Archival" });
            }
            if (policyWorkspace.ArchivalStatus === "Done" && policyWorkspace.ArchivalIsArchived && !policyWorkspace.ArchivalIsDeleted) {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.archived", defaultMessage: "Archived" });
            }
            if (policyWorkspace.ArchivalStatus === "Done" && policyWorkspace.ArchivalIsArchived && policyWorkspace.ArchivalIsDeleted) {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.deleted", defaultMessage: "Deleted" });
            }
            if (policyWorkspace.ArchivalStatus === "Deleted") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.deleted", defaultMessage: "Deleted" });
            }
            if (policyWorkspace.ArchivalStatus === "Failed") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.failed", defaultMessage: "Archival Failed" });
            }
        }
    };
    var getStatusIcon = function () {
        if (policyWorkspace !== null && policyWorkspace !== undefined) {
            if (policyWorkspace.ArchivalStatus === "Active" || policyWorkspace.ArchivalStatus === null || policyWorkspace.ArchivalStatus === undefined) {
                if (reportItem !== null &&
                    reportItem !== undefined &&
                    reportItem.DaysInactive !== null &&
                    reportItem.DaysInactive !== undefined &&
                    lifecycleReportContext !== null &&
                    lifecycleReportContext !== undefined &&
                    lifecycleReportContext.InactiveDays !== null &&
                    lifecycleReportContext.InactiveDays !== undefined &&
                    reportItem.DaysInactive >= lifecycleReportContext.InactiveDays) {
                    return "business-time";
                }
                else {
                    return "certificate";
                }
            }
            if (policyWorkspace.ArchivalStatus === "PendingApproval") {
                return "business-time";
            }
            if (policyWorkspace.ArchivalStatus === "Extended") {
                return "clock";
            }
            if (policyWorkspace.ArchivalStatus === "ReadyToArchive") {
                return "flag-checkered";
            }
            if (policyWorkspace.ArchivalStatus === "Processing") {
                return "rocket";
            }
            if (policyWorkspace.ArchivalStatus === "Done") {
                return "archive";
            }
            if (policyWorkspace.ArchivalStatus === "Failed") {
                return "exclamation-circle";
            }
            if (policyWorkspace.ArchivalStatus === "Deleted") {
                return "trash";
            }
        }
    };
    var getStatusClass = function () {
        var returnClass = "active";
        if (policyWorkspace !== null && policyWorkspace !== undefined && policyWorkspace.ArchivalStatus !== null && policyWorkspace.ArchivalStatus !== undefined) {
            if (policyWorkspace.ArchivalStatus.toLowerCase() === "active" && reportItem !== null && reportItem !== undefined) {
                if (reportItem.DaysInactive !== null &&
                    reportItem.DaysInactive !== undefined &&
                    lifecycleReportContext !== null &&
                    lifecycleReportContext !== undefined &&
                    lifecycleReportContext.InactiveDays !== null &&
                    lifecycleReportContext.InactiveDays !== undefined &&
                    reportItem.DaysInactive >= lifecycleReportContext.InactiveDays) {
                    returnClass = "inactive";
                }
            }
            else {
                returnClass = policyWorkspace.ArchivalStatus.toLowerCase();
            }
        }
        else {
            returnClass = "active";
        }
        return returnClass;
    };
    React.useEffect(function () {
        if (policyWorkspace !== null && policyWorkspace !== undefined) {
            if (policyWorkspace.ArchivalPolicyGuid !== null && policyWorkspace.ArchivalPolicyGuid !== undefined) {
                if (currentPolicy === null || currentPolicy === undefined || (currentPolicy !== null && currentPolicy.GUID !== policyWorkspace.ArchivalPolicyGuid)) {
                    dispatch(LifecyclePolicyCurrentStore.Clear());
                    dispatch(LifecyclePolicyCurrentStore.Load("Archival", policyWorkspace.ArchivalPolicyGuid));
                }
            }
        }
    }, [policyWorkspace]);
    return (React.createElement("div", { className: "lifecycle-policy-assignments", "data-pw": "lifecycle-policy-assignments" },
        React.createElement(Portlet, { className: "lifecycle" },
            React.createElement(PortletHeader, null,
                React.createElement("div", { className: "kt-portlet__head-label" },
                    React.createElement("h3", { className: "kt-portlet__head-title" },
                        React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.lifecycle_header", defaultMessage: "Lifecycle" })))),
            React.createElement(PortletBody, null, policyWorkspace === null || policyWorkspace === undefined || reportItem === null || reportItem === undefined ? (React.createElement(React.Fragment, null,
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 160 }),
                React.createElement(SkeletonLoaderFakeTextSmall, { bodyHeight: 20, marginTop: 15 }),
                minimalMode === null || minimalMode === undefined || (minimalMode !== undefined && minimalMode === false) ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 30 }) : null)) : (React.createElement("div", { className: "lifecycle-policies" },
                React.createElement("div", { className: "status", "data-pw": "ws-status" },
                    React.createElement("div", { className: "numbers" + (" status-" + getStatusClass()), "data-pw": "status" },
                        React.createElement("div", { className: "big-number" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("div", { className: "icon-square" }, isNewStatusLoading === true ? (React.createElement("div", { className: "loader" },
                                    React.createElement(AnimatedLoader, { size: "xsmall" }))) : (React.createElement("i", { className: "la la-" + getStatusIcon() })))),
                            React.createElement("div", { className: "stats", key: policyWorkspace.ArchivalStatus },
                                React.createElement("div", { className: "title" },
                                    React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.status", defaultMessage: "Status" })),
                                React.createElement("div", { className: "number" }, getStatusText()))),
                        getStatus() !== "Done" && getStatus() !== "Failed" ? (React.createElement(React.Fragment, null,
                            reportItem.Application === "Team" && (React.createElement("div", { className: "small-number" },
                                React.createElement("div", { className: "stats" },
                                    React.createElement("div", { className: "number" }, reportItem.TeamDaysInactive),
                                    React.createElement("div", { className: "title" },
                                        React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.days_no_activity_team", defaultMessage: "days without Team activity" }))))),
                            reportItem.Application === "Team" || reportItem.Application === "TeamSite" ? (React.createElement("div", { className: "small-number" },
                                React.createElement("div", { className: "stats" },
                                    React.createElement("div", { className: "number" }, reportItem.GroupConversationsDaysInactive),
                                    React.createElement("div", { className: "title" },
                                        React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.days_no_activity_group", defaultMessage: "days without Group activity" }))))) : null,
                            React.createElement("div", { className: "small-number" },
                                React.createElement("div", { className: "stats" },
                                    React.createElement("div", { className: "number" }, reportItem.SharePointDaysInactive),
                                    React.createElement("div", { className: "title" },
                                        React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.days_no_activity_sharepoint", defaultMessage: "days without SharePoint activity" })))))) : null),
                    minimalMode === null || minimalMode === undefined || (minimalMode !== undefined && minimalMode === false) ? (React.createElement(React.Fragment, null,
                        getStatus() !== "Done" && getStatus() !== "Failed" ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "last-activity-date", "data-pw": "last-activity-date" },
                                React.createElement("div", { className: "left-side" },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.last_activity", defaultMessage: "Last Activity" }))),
                                React.createElement("div", { className: "right-side" },
                                    React.createElement("span", null, moment
                                        .utc(reportItem.LastActivityDate)
                                        .local()
                                        .format("LL")))))) : null,
                        policyWorkspace.ArchivalStatus === "Extended" && policyWorkspace.ArchivalExtensionDate !== null && policyWorkspace.ArchivalExtensionDate !== undefined ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "last-activity-date", "data-pw": "last-activity-date" },
                                React.createElement("div", { className: "left-side" },
                                    React.createElement("span", null,
                                        React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.last_renewal_on", defaultMessage: "Last Renewal On" }))),
                                React.createElement("div", { className: "right-side" },
                                    React.createElement("span", null, moment
                                        .utc(policyWorkspace.ArchivalExtensionDate)
                                        .local()
                                        .format("LL")))))) : null,
                        policyWorkspace.ArchivalArchivedDate !== null && policyWorkspace.ArchivalArchivedDate !== undefined ? (React.createElement("div", { className: "last-activity-date", "data-pw": "last-activity-date" },
                            React.createElement("div", { className: "left-side" },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.archived", defaultMessage: "Archived" }))),
                            React.createElement("div", { className: "right-side" },
                                React.createElement("div", null,
                                    moment
                                        .utc(policyWorkspace.ArchivalArchivedDate)
                                        .local()
                                        .format("LLL"),
                                    (currentPolicy === null || currentPolicy === void 0 ? void 0 : currentPolicy.SecondStage_Enabled) ||
                                        (policyWorkspace.ArchivalSecondStageArchivedDate !== null && policyWorkspace.ArchivalSecondStageArchivedDate !== undefined)
                                        ? " (First Step)"
                                        : ""),
                                policyWorkspace.ArchivalSecondStageArchivedDate && (React.createElement("div", null,
                                    moment
                                        .utc(policyWorkspace.ArchivalSecondStageArchivedDate)
                                        .local()
                                        .format("LLL"),
                                    " ",
                                    "(Second Step)"))))) : null,
                        (policyWorkspace.ArchivalStatus === "Done" && (reportItem.ArchivalReason != null || reportItem.SecondStageArchivalReason != null)) ||
                            (policyWorkspace.ArchivalArchivedDate && (reportItem.ArchivalReason != null || reportItem.SecondStageArchivalReason != null)) ? (React.createElement("div", { className: "archival-action", "data-pw": "archival-action" },
                            React.createElement("div", { className: "left-side" },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.archival_action", defaultMessage: "Archival Action" }))),
                            React.createElement("div", { className: "right-side" },
                                React.createElement("div", null,
                                    React.createElement(LifecycleStrings, { type: "ArchivalReason", value: reportItem.ArchivalReason }),
                                    " ",
                                    (currentPolicy === null || currentPolicy === void 0 ? void 0 : currentPolicy.SecondStage_Enabled) ||
                                        (policyWorkspace.ArchivalSecondStageArchivedDate !== null && policyWorkspace.ArchivalSecondStageArchivedDate !== undefined)
                                        ? " (First Step)"
                                        : ""),
                                policyWorkspace.ArchivalSecondStageArchivedDate && (React.createElement("div", null,
                                    React.createElement(LifecycleStrings, { type: "ArchivalReason", value: reportItem.SecondStageArchivalReason }),
                                    " (Second Step)"))))) : null)) : null),
                minimalMode === null || minimalMode === undefined || (minimalMode !== undefined && minimalMode === false) ? React.createElement(PolicySelector, { siteId: siteId, Slot: Slot }) : null,
                (policyWorkspace.ArchivalStatus === "PendingApproval" && policyWorkspace.ArchivalNotificationFirstSent !== null) || policyWorkspace.ArchivalNotificationRetries !== null ? (React.createElement("div", { className: "pending-stats" },
                    policyWorkspace.ArchivalNotificationFirstSent !== null && policyWorkspace.ArchivalNotificationFirstSent !== undefined && (React.createElement("div", { className: "p-stat" },
                        React.createElement("div", { className: "num" },
                            React.createElement("i", { className: "fa fa-check" })),
                        React.createElement("div", { className: "text" },
                            React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.first_notification_sent", defaultMessage: "First notification sent" })))),
                    policyWorkspace.ArchivalNotificationRetries !== null && policyWorkspace.ArchivalNotificationRetries !== undefined && (React.createElement("div", { className: "p-stat" },
                        React.createElement("div", { className: "num" }, policyWorkspace.ArchivalNotificationRetries),
                        React.createElement("div", { className: "text" },
                            React.createElement(FormattedMessage, { id: "component.lifecycle.policy_assignments.reminders_sent", defaultMessage: "Reminders sent" })))))) : null,
                minimalMode === null || minimalMode === undefined || (minimalMode !== undefined && minimalMode === false) ? React.createElement(LifecycleApproval, null) : null))))));
});
export default LifecyclePolicyAssignments;
