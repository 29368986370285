import React from "react";
import { TooltipHost, DirectionalHint } from "@fluentui/react";
import "./overlay.scss";
var OverlayTooltip = function (_a) {
    var items = _a.items, overlayTitle = _a.overlayTitle;
    return (React.createElement("div", { className: "tooltip-body" },
        React.createElement("p", null,
            React.createElement("strong", null,
                overlayTitle,
                ":")),
        React.createElement("ul", null, items.map(function (item) {
            return React.createElement("li", null, item.message);
        }))));
};
export var ProfileOverlay = function (_a) {
    var items = _a.items, overlayTitle = _a.overlayTitle, icon = _a.icon;
    return (React.createElement(React.Fragment, null, items && items.length > 0 ? (React.createElement("div", { className: "overlay-body ".concat(icon.shape ? icon.shape : "").trim() },
        React.createElement(TooltipHost, { calloutProps: {
                gapSpace: 10,
                styles: {
                    calloutMain: { backgroundColor: "#171721" },
                    beakCurtain: { backgroundColor: "#171721", borderRadius: "6px" },
                    beak: { backgroundColor: "#171721" }
                }
            }, directionalHint: DirectionalHint.topCenter, id: "profile-overlay-tooltip", tooltipProps: {
                styles: { root: { backgroundColor: "#171721", borderRadius: "6px" }, content: { color: "white", backgroundColor: "#171721", padding: "0.5rem" } },
                onRenderContent: function () { return React.createElement(OverlayTooltip, { overlayTitle: overlayTitle, items: items }); }
            } },
            React.createElement("i", { style: { fontSize: icon.fontSize }, className: "".concat(icon.iconClassName) })))) : null));
};
