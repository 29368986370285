import { TemplateCurrentActionTypes } from "./types";
var initialState = {
    item: {}
};
export function templateCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TemplateCurrentActionTypes.Template_Current_Load: {
            //console.log(TemplateCurrentActionTypes.Template_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case TemplateCurrentActionTypes.Template_Current_Update: {
            var updatedTemplate = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTemplate
            };
        }
        case TemplateCurrentActionTypes.Template_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
