import { OrchestryExpandableButton } from "appv2/components/orchestry-expandable-button/orchestry-expandable-button.component";
import React from "react";
import clsx from "clsx";
import { RecommendationCardSource } from "../recommendation-card-source.component";
import "./recommendation-card-control-section.scss";
import { RecommendationsPriorityIndicator } from "./recommendation-priority-indicator.component";
/**
 * The section of the recommendation card that contains the indicators and expandable button.
 */
export var RecommendationCardControlSection = function (_a) {
    var _b = _a.recommendation.RecommendationRecord, Definition_Priority = _b.Definition_Priority, Visibility = _b.Visibility, Source = _b.Source, isExpanded = _a.isExpanded, isExpandable = _a.isExpandable;
    var priority = Definition_Priority;
    var visibilty = Visibility;
    return (React.createElement("div", { className: clsx("recommendation-card-control-section", { "is-not-expandable": !isExpandable }) },
        isExpandable && React.createElement(RecommendationCardSource, { source: Source }),
        React.createElement(RecommendationsPriorityIndicator, { priority: priority, visibility: visibilty }),
        isExpandable && React.createElement(OrchestryExpandableButton, { expanded: isExpanded })));
};
