var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-text-list.scss";
import { Form } from "react-bootstrap";
import generateGuid from "app/utils/generateGuid";
var FormTextList = React.memo(function (props) {
    var componentUniqueId = React.useState(generateGuid())[0];
    var _a = React.useState([]), choiceValues = _a[0], setChoiceValues = _a[1];
    var _b = React.useState(false), showChoiceValueSave = _b[0], setShowChoiceValueSave = _b[1];
    var _c = React.useState(false), lastKeyDownWasEnter = _c[0], setLastKeyDownWasEnter = _c[1];
    React.useEffect(function () {
        console.log("Choice Values", props.values, choiceValues);
        if (props.values !== undefined) {
            if (props.values !== null) {
                if (choiceValues.length === 0) {
                    setChoiceValues(props.values.split(props.splitCharacter));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values]);
    var updateChoiceValue = function (value, index) {
        var newValues = __spreadArray([], choiceValues, true);
        if (value.indexOf(props.splitCharacter) > -1) {
            value = value.replace(props.splitCharacter, "");
        }
        newValues[index] = value;
        setChoiceValues(newValues);
        props.onChange(newValues.join(props.splitCharacter));
    };
    var addChoiceValue = function () {
        var newValues = [];
        if (choiceValues !== undefined) {
            newValues = __spreadArray([], choiceValues, true);
        }
        newValues.push("");
        setChoiceValues(newValues);
        var waitCount = 0;
        var waitForNewFieldAdded = function (indexWaitingFor) {
            waitCount++;
            if (waitCount < 20) {
                setTimeout(function () {
                    var newInput = document.getElementById("choice-number-" + componentUniqueId + "-" + indexWaitingFor);
                    //console.log("newInput", "choice-number-"+ indexWaitingFor, newInput);
                    if (newInput === null) {
                        waitForNewFieldAdded(indexWaitingFor);
                    }
                    else {
                        newInput.focus();
                    }
                }, 200);
            }
        };
        waitForNewFieldAdded(newValues.length - 1);
    };
    var removeChoiceValue = function (value) {
        var newValues = [];
        if (choiceValues.length > 1) {
            newValues = choiceValues.filter(function (cv) { return cv !== value; });
        }
        setChoiceValues(newValues);
        props.onChange(newValues.join(props.splitCharacter));
    };
    var handleKeyDown = function (e, index) {
        var newInput = document.getElementById("choice-number-" + componentUniqueId + "-" + index);
        if (e.keyCode === 13) {
            updateChoiceValue(e.currentTarget.value, index);
            //setShowChoiceValueSave(false);
            setLastKeyDownWasEnter(true);
            if (newInput != null) {
                newInput.blur();
            }
            // console.log("Enter Clicked", currentResults[cursor]);
        }
        else {
            setLastKeyDownWasEnter(false);
        }
    };
    return (React.createElement("div", { className: "form-text-list" },
        React.createElement(Form.Group, { className: "form-group", controlId: "text-list-values-" + componentUniqueId },
            React.createElement("div", { className: "values" },
                choiceValues !== undefined &&
                    choiceValues.map(function (cv, index) { return (React.createElement("div", { className: "item", key: index },
                        React.createElement("div", { className: "icon" },
                            React.createElement("span", { className: "check" },
                                React.createElement("i", { className: props.itemsIcon })),
                            React.createElement("span", { className: "remove", onClick: function () {
                                    removeChoiceValue(cv);
                                } },
                                React.createElement("i", { className: "fa fa-trash" }))),
                        React.createElement("input", { type: "text", className: "" + (lastKeyDownWasEnter === true ? " enter-was-pressed" : ""), id: "choice-number-" + componentUniqueId + "-" + index, onFocus: function () {
                                setShowChoiceValueSave(true);
                                setLastKeyDownWasEnter(false);
                            }, onBlur: function () { return setShowChoiceValueSave(false); }, placeholder: props.addInputPlaceholderText, value: cv, onChange: function (event) {
                                updateChoiceValue(event.target.value, index);
                            }, onKeyDown: function (event) {
                                handleKeyDown(event, index);
                            } }))); }),
                choiceValues === undefined || choiceValues.length === 0 ? React.createElement("div", { className: "no-choices" }, props.noItemsAddedText) : null,
                React.createElement("div", { className: "add" },
                    React.createElement("div", { className: "button-custom b-grey", onClick: function () {
                            addChoiceValue();
                        } },
                        React.createElement("i", { className: "fa fa-plus" }),
                        React.createElement("span", null, props.addButtonText)),
                    React.createElement("div", { className: "button-custom b-green save-button" + (showChoiceValueSave ? " show-save" : ""), onClick: function (event) {
                            event.currentTarget.focus();
                        } },
                        React.createElement("span", null, props.saveButtonText)))))));
});
export default FormTextList;
