import DisableArea from "app/components/common/disable-area";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormNamingPolicy from "app/components/common/form-naming-policy";
import FormOfficeAssetPicker from "app/components/common/form-office-asset-picker";
import FormTextList from "app/components/common/form-text-list";
import FormToggleControl from "app/components/common/form-toggle-control";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import FormValidationContext, { FormValidator, FormValidatorRollup } from "app/components/common/form-validation";
import FormWebhook from "app/components/common/form-webhook";
import InfoToolTip from "app/components/common/infotooltip";
import PortletAlert from "app/components/common/portlet-alert";
import NamingOptionPreview from "app/components/lifecycle/workspaces/policies/cards/step-card-archive-actions/naming-option-preview";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./step-card-archive-actions.scss";
var StepCardArchiveActions = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var _a = React.useState(), currentTab = _a[0], setCurrentTab = _a[1];
    React.useEffect(function () {
        setCurrentTab(currentPolicy.Application === "Team" ? "team" : currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? "sharepoint" : "naming");
    }, [currentPolicy.Application, currentPolicy.Option_Content_Action]);
    return (React.createElement("div", { className: "step-card-actions policy-card", "data-pw": "step-card-actions" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement("div", { className: "action-row card-border" },
                React.createElement("div", { className: "left-nav" },
                    currentPolicy.Application === "Team" ? (React.createElement("div", { className: "nav-item" + (currentTab === "team" ? " selected" : ""), onClick: function () { return setCurrentTab("team"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png" })),
                        React.createElement("div", { className: "text", "data-pw": "team-options" }, "Teams Options"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "team" }))) : null,
                    currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" && (React.createElement("div", { className: "nav-item" + (currentTab === "sharepoint" ? " selected" : ""), onClick: function () { return setCurrentTab("sharepoint"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" })),
                        React.createElement("div", { className: "text", "data-pw": "sharepoint-options" }, "SharePoint Options"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "sharepoint" }))),
                    React.createElement("div", { className: "nav-item" + (currentTab === "naming" ? " selected" : ""), onClick: function () { return setCurrentTab("naming"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa fa-bold" })),
                        React.createElement("div", { className: "text", "data-pw": "naming-options" }, "Naming Options"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "naming" })),
                    React.createElement("div", { className: "nav-item" + (currentTab === "content" ? " selected" : ""), onClick: function () { return setCurrentTab("content"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa fa-book" })),
                        React.createElement("div", { className: "text", "data-pw": "content-actions" }, "Content Actions"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, RefreshProperty: currentTab, Category: "content" })),
                    currentPolicy.Application !== "CommunicationSite" && currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? (React.createElement("div", { className: "nav-item" + (currentTab === "group" ? " selected" : ""), onClick: function () { return setCurrentTab("group"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fa fa-user-friends" })),
                        React.createElement("div", { className: "text", "data-pw": "group-options" }, "Group Options"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "group" }))) : null,
                    React.createElement("div", { className: "nav-item" + (currentTab === "permissions" ? " selected" : ""), onClick: function () { return setCurrentTab("permissions"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "fas fa-id-card" })),
                        React.createElement("div", { className: "text", "data-pw": "security-options" }, "Security Options"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "permissions" })),
                    React.createElement("div", { className: "nav-item" + (currentTab === "integrations" ? " selected" : ""), onClick: function () { return setCurrentTab("integrations"); } },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "flaticon-layers" })),
                        React.createElement("div", { className: "text", "data-pw": "integrations" }, "Integrations"),
                        React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: props.StepKey, Category: "integrations" }))),
                React.createElement("div", { className: "right-content-area" },
                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "team" ? "block" : "none"
                        } },
                        React.createElement(TabTeamOptions, null)),
                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "sharepoint" ? "block" : "none"
                        } },
                        React.createElement(TabSharePointOptions, null)),
                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "naming" ? "block" : "none"
                        } },
                        React.createElement(TabNamingOptions, null)),
                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "content" ? "block" : "none"
                        } },
                        React.createElement(TabContentOptions, null)),
                    currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" && (React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "group" ? "block" : "none"
                        } },
                        React.createElement(TabGroupOptions, null))),
                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "permissions" ? "block" : "none"
                        } },
                        React.createElement(TabSecurityOptions, null)),
                    React.createElement("div", { className: "nav-body fadein-1s", style: {
                            display: currentTab === "integrations" ? "block" : "none"
                        } },
                        React.createElement(TabIntegrations, null)))),
            React.createElement(FormValidator, { UniqueIdentifier: "needed-since-no-validators-are-active-by-default", Validators: ["is-true"], Value: true }))));
});
export default StepCardArchiveActions;
var TabTeamOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (currentPolicy.Option_Team_General_SetTeamStatusToArchive === true || currentPolicy.Option_Team_Post_DeleteAllPosts === true) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Group_Delete_Group: false,
                Option_Team_General_DeleteTeamAndGroup: false
            }));
        }
    }, [currentPolicy.Option_Team_General_SetTeamStatusToArchive, currentPolicy.Option_Team_Post_DeleteAllPosts]);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Team Options"),
            currentPolicy.Option_Content_Action === "LeaveContentInPlace" && (React.createElement(React.Fragment, null,
                React.createElement("h6", null, "General"),
                React.createElement(Form.Group, { controlId: "set-team-status-to-archive", className: "form-group checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            "Set Team Status to Archive",
                            React.createElement(InfoToolTip, null, "Sets the status of the Team to archived in Microsoft 365 and Orchestry")), checked: currentPolicy.Option_Team_General_SetTeamStatusToArchive, onChange: function (event) {
                            return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                Option_Team_General_SetTeamStatusToArchive: event.target.checked
                            }));
                        } })))),
            React.createElement("hr", null),
            React.createElement("h6", null, "Post Settings"),
            React.createElement(Form.Group, { controlId: "export-channel-post-to-excel", className: "form-group checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Export Channel Posts to Excel",
                        React.createElement(InfoToolTip, null, "Exports the posts from all the channels into an Excel file")), checked: currentPolicy.Option_Team_Post_ExportChannelPostsToExcel, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Option_Team_Post_ExportChannelPostsToExcel: event.target.checked
                        }));
                    } })))));
});
var TabSharePointOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var _a = React.useState(currentPolicy.Option_SharePoint_HubSite_AssociatedHubSite != null ? true : false), associateHubSite = _a[0], setAssociateHubSite = _a[1];
    // React.useEffect(() => {
    //    if (currentPolicy.Option_SharePoint_HubSite_AssociatedHubSite !== null) {
    //       setAssociateHubSite(true);
    //    }
    // }, [currentPolicy.Option_SharePoint_HubSite_AssociatedHubSite])
    React.useEffect(function () {
        if (associateHubSite === false) {
            if (currentPolicy.Option_SharePoint_HubSite_AssociatedHubSite !== null) {
                dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                    Option_SharePoint_HubSite_AssociatedHubSite: null
                }));
            }
        }
    }, [associateHubSite]);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "SharePoint Options"),
            React.createElement("h6", null, "General"),
            React.createElement(React.Fragment, null,
                React.createElement(Form.Group, { controlId: "set-site-to-read-only", className: "checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            "Set Site to Read Only ",
                            React.createElement(InfoToolTip, null, "Set the SharePoint site and all content contained to read only")), checked: currentPolicy.Option_SharePoint_General_SetSiteToReadOnly, onChange: function (event) {
                            return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                Option_SharePoint_General_SetSiteToReadOnly: event.target.checked
                            }));
                        } }))),
            React.createElement(Form.Group, { controlId: "remove-sharepoint-site-from-default-serach-scope", className: "form-group checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Remove SharePoint Site from Search and Copilot processing for additional security",
                        React.createElement(InfoToolTip, null, "Removes the Site and all content from search and from access with Microsoft Copilot")), checked: currentPolicy.Option_SharePoint_General_RemoveSharePointSiteFromDefaultSearchScope, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Option_SharePoint_General_RemoveSharePointSiteFromDefaultSearchScope: event.target.checked
                        }));
                    } })),
            currentPolicy.Option_Content_Action === "LeaveContentInPlace" ? (React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                React.createElement("h6", null, "Hub Site Association"),
                React.createElement(Form.Group, { controlId: "associate-sharepoint-site-to-hub", className: "form-group checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            "Associate SharePoint Site to Hub",
                            React.createElement(InfoToolTip, null, "Associates the SharePoint site to a Hub Site")), checked: associateHubSite, onChange: function (event) { return setAssociateHubSite(event.target.checked); } }),
                    React.createElement(Collapse, { in: associateHubSite === true },
                        React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement(FormOfficeAssetPicker, { pickerType: "SharePointHubs", showSelectedText: false, value: currentPolicy.Option_SharePoint_HubSite_AssociatedHubSite, onChange: function (serializedItem) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_SharePoint_HubSite_AssociatedHubSite: serializedItem
                                        }));
                                    } })))),
                    React.createElement(FormValidator, { UniqueIdentifier: "associate-sharepoint-site-to-hub", FieldName: "Associated Hub Site", Validators: ["string-not-empty"], Category: "sharepoint", Enabled: associateHubSite === true, Value: currentPolicy.Option_SharePoint_HubSite_AssociatedHubSite })))) : null)));
});
var TabNamingOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? (React.createElement(React.Fragment, null,
                currentPolicy.Application === "Team" ? "Team/" : null,
                currentPolicy.Application !== "CommunicationSite" ? "Group/" : null,
                "Site Naming")) : (React.createElement(React.Fragment, null, "Destination Folder Naming"))),
            React.createElement(Form.Group, { controlId: "change-team-group-name", className: "form-group checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? (React.createElement(React.Fragment, null,
                        "Change ",
                        currentPolicy.Application === "Team" ? "Team/" : null,
                        currentPolicy.Application !== "CommunicationSite" ? "Group/" : null,
                        "Site Name ",
                        React.createElement(InfoToolTip, null, "Changes the name of the Team, Group and SharePoint Site"))) : (React.createElement(React.Fragment, null,
                        "Folder Name",
                        React.createElement(InfoToolTip, null, "Changes the name of the folder in the destination document library")))), checked: currentPolicy.Option_Naming_TeamGroup_Change, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Option_Naming_TeamGroup_Change: event.target.checked
                        }));
                    } })),
            React.createElement(Collapse, { in: currentPolicy.Option_Naming_TeamGroup_Change === true },
                React.createElement("div", null,
                    React.createElement("div", { className: "naming-preview-format" },
                        React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", title: currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? "Workspace's final title format" : "Archived Folder Name", message: React.createElement(NamingOptionPreview, { type: "name", currentPolicy: currentPolicy, title: currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? "Sample Web Name" : "Workspace Name" }) })),
                    React.createElement(Form.Group, { controlId: "add-teamgroup-prefix", className: "form-group checkbox-blue" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Add Prefix",
                                " ",
                                currentPolicy.Option_Naming_TeamGroup_PrefixAdd === false && currentPolicy.Option_Naming_TeamGroup_SuffixAdd === false && React.createElement("span", { className: "required-astrix" }, "*")), checked: currentPolicy.Option_Naming_TeamGroup_PrefixAdd, onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    Option_Naming_TeamGroup_PrefixAdd: event.target.checked
                                }));
                            } })),
                    React.createElement(Collapse, { in: currentPolicy.Option_Naming_TeamGroup_PrefixAdd === true },
                        React.createElement("div", { className: "naming-policy-selector" },
                            React.createElement(FormNamingPolicy, { placeholder: "-- Add Prefix --", value: currentPolicy.Option_Naming_TeamGroup_PrefixValues, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Naming_TeamGroup_PrefixValues: value
                                    }));
                                }, metadataTypes: ["Date-us-long", "Date-uk-long", "Date-sortfriendly-long", "Date-us-short", "Date-uk-short", "Date-sortfriendly-short"], validatorCategory: "naming" }))),
                    React.createElement(Form.Group, { controlId: "add-teamgroup-suffix", className: "form-group checkbox-blue" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Add Suffix",
                                " ",
                                currentPolicy.Option_Naming_TeamGroup_PrefixAdd === false && currentPolicy.Option_Naming_TeamGroup_SuffixAdd === false && React.createElement("span", { className: "required-astrix" }, "*")), checked: currentPolicy.Option_Naming_TeamGroup_SuffixAdd, onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    Option_Naming_TeamGroup_SuffixAdd: event.target.checked
                                }));
                            } })),
                    React.createElement(Collapse, { in: currentPolicy.Option_Naming_TeamGroup_SuffixAdd === true },
                        React.createElement("div", { className: "naming-policy-selector" },
                            React.createElement(FormNamingPolicy, { placeholder: "-- Add Suffix --", value: currentPolicy.Option_Naming_TeamGroup_SuffixValues, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Naming_TeamGroup_SuffixValues: value
                                    }));
                                }, metadataTypes: ["Date-us-long", "Date-uk-long", "Date-sortfriendly-long", "Date-us-short", "Date-uk-short", "Date-sortfriendly-short"], validatorCategory: "naming" }))))),
            React.createElement(FormValidator, { UniqueIdentifier: "change-team-group-name", ErrorMessage: currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary"
                    ? "Please configure at least one Team/Group/Site prefix or suffix"
                    : "Please configure at least one Folder prefix or suffix", Validators: ["is-false"], Category: "naming", Enabled: currentPolicy.Option_Naming_TeamGroup_Change === true, Value: currentPolicy.Option_Naming_TeamGroup_Change === true && currentPolicy.Option_Naming_TeamGroup_PrefixAdd === false && currentPolicy.Option_Naming_TeamGroup_SuffixAdd === false }),
            currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" && (React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                React.createElement("h6", null, "SharePoint Site Url"),
                React.createElement(Form.Group, { controlId: "change-site-name", className: "form-group checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            "Change Site Url",
                            React.createElement(InfoToolTip, null, "Changes the SharePoint URL of the SharePoint site")), checked: currentPolicy.Option_Naming_SharePointUrl_Change, onChange: function (event) {
                            return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                Option_Naming_SharePointUrl_Change: event.target.checked
                            }));
                        } })),
                React.createElement(Collapse, { in: currentPolicy.Option_Naming_SharePointUrl_Change === true },
                    React.createElement("div", null,
                        React.createElement("div", { className: "naming-preview-format" },
                            React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", title: "Workspace's final url format", message: React.createElement(NamingOptionPreview, { type: "url", currentPolicy: currentPolicy, title: "Sample Web Name" }) })),
                        React.createElement(Form.Group, { controlId: "add-sharepoint-url-prefix", className: "form-group checkbox-blue" },
                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                    "Add Prefix",
                                    " ",
                                    currentPolicy.Option_Naming_SharePointUrl_PrefixAdd === false && currentPolicy.Option_Naming_SharePointUrl_SuffixAdd === false && (React.createElement("span", { className: "required-astrix" }, "*"))), checked: currentPolicy.Option_Naming_SharePointUrl_PrefixAdd, onChange: function (event) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Naming_SharePointUrl_PrefixAdd: event.target.checked
                                    }));
                                } })),
                        React.createElement(Collapse, { in: currentPolicy.Option_Naming_SharePointUrl_PrefixAdd === true },
                            React.createElement("div", { className: "naming-policy-selector" },
                                React.createElement(FormNamingPolicy, { placeholder: "-- Add Prefix --", value: currentPolicy.Option_Naming_SharePointUrl_PrefixValues, type: "url", onChange: function (value) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Naming_SharePointUrl_PrefixValues: value
                                        }));
                                    }, metadataTypes: ["Date-us-long", "Date-uk-long", "Date-sortfriendly-long", "Date-us-short", "Date-uk-short", "Date-sortfriendly-short"], validatorCategory: "naming" }))),
                        React.createElement(Form.Group, { controlId: "add-sharepoint-url-suffix", className: "form-group checkbox-blue" },
                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                    "Add Suffix",
                                    " ",
                                    currentPolicy.Option_Naming_SharePointUrl_PrefixAdd === false && currentPolicy.Option_Naming_SharePointUrl_SuffixAdd === false && (React.createElement("span", { className: "required-astrix" }, "*"))), checked: currentPolicy.Option_Naming_SharePointUrl_SuffixAdd, onChange: function (event) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Naming_SharePointUrl_SuffixAdd: event.target.checked
                                    }));
                                } })),
                        React.createElement(Collapse, { in: currentPolicy.Option_Naming_SharePointUrl_SuffixAdd === true },
                            React.createElement("div", { className: "naming-policy-selector" },
                                React.createElement(FormNamingPolicy, { placeholder: "-- Add Suffix --", value: currentPolicy.Option_Naming_SharePointUrl_SuffixValues, type: "url", onChange: function (value) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Naming_SharePointUrl_SuffixValues: value
                                        }));
                                    }, metadataTypes: ["Date-us-long", "Date-uk-long", "Date-sortfriendly-long", "Date-us-short", "Date-uk-short", "Date-sortfriendly-short"], validatorCategory: "naming" }))))),
                React.createElement(FormValidator, { UniqueIdentifier: "change-site-name", ErrorMessage: "Please configure at least one Site Url prefix or suffix", Validators: ["is-false"], Category: "naming", Enabled: currentPolicy.Option_Naming_SharePointUrl_Change === true, Value: currentPolicy.Option_Naming_SharePointUrl_Change === true &&
                        currentPolicy.Option_Naming_SharePointUrl_PrefixAdd === false &&
                        currentPolicy.Option_Naming_SharePointUrl_SuffixAdd === false }))))));
});
var TabContentOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var scopeDropdownValues = React.useState([
        {
            id: "1",
            title: "Archive All Versions",
            value: "AllVersions"
        },
        {
            id: "2",
            title: "Archive Most Current Version",
            description: "All Previous versions will be removed",
            value: "MostCurrentVersion"
        },
        {
            id: "3",
            title: "Archive All Major Versions",
            description: "All Previous Minor Versions will be removed",
            value: "AllMajorVersions"
        }
    ])[0];
    React.useEffect(function () {
        if (currentPolicy.Option_Content_Action === "LeaveContentInPlace") {
            if (currentPolicy.Option_Content_Lists_Process === true) {
                dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                    Option_Content_Lists_Process: false
                }));
            }
        }
    }, [currentPolicy.Option_Content_Action]);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Content Actions"),
            React.createElement("h6", null, "Documents"),
            React.createElement(Form.Group, { controlId: "process-site-documents", className: "form-group checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Process Site Documents",
                        React.createElement(InfoToolTip, null, "Process the Document Libraries in the SharePoint Site")), checked: currentPolicy.Option_Content_Documents_Process, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Option_Content_Documents_Process: event.target.checked
                        }));
                    } })),
            React.createElement(Collapse, { in: currentPolicy.Option_Content_Documents_Process === true },
                React.createElement("div", null,
                    React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" },
                            "Scope ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", null,
                            React.createElement(FormDropdownSelect, { placeholder: "Select a scope", list: [
                                    {
                                        id: "1",
                                        title: "All Document Libraries",
                                        value: "AllDocumentLibraries"
                                    },
                                    {
                                        id: "2",
                                        title: "Choose Libraries",
                                        value: "ChooseLibraries"
                                    }
                                ], value: currentPolicy.Option_Content_Documents_Scope, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Content_Documents_Scope: value
                                    }));
                                } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "Option_Content_Documents_Scope", FieldName: "Process Site Documents Scope", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.Option_Content_Documents_Process === true, Value: currentPolicy.Option_Content_Documents_Scope }))),
                    currentPolicy.Option_Content_Documents_Scope === "ChooseLibraries" ? (React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "list-lib-selector" },
                                React.createElement(FormTextList, { onChange: function (values) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Content_Documents_Scope_ChosenLibs: values
                                        }));
                                    }, itemsIcon: "fa fa-folder", splitCharacter: "|", values: currentPolicy.Option_Content_Documents_Scope_ChosenLibs, addButtonText: "Add document library name", saveButtonText: "Save name", addInputPlaceholderText: "Enter library name", noItemsAddedText: "No document library names added" }))))) : null,
                    React.createElement("div", { className: "property-row" },
                        React.createElement("div", { className: "left-side" },
                            "Version ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", null,
                            React.createElement(FormDropdownSelect, { placeholder: "Select a version", list: scopeDropdownValues, value: currentPolicy.Option_Content_Documents_Versions, onChange: function (value) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Content_Documents_Versions: value
                                    }));
                                } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "Option_Content_Documents_Versions", FieldName: "Process Site Documents Version", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.Option_Content_Documents_Process === true, Value: currentPolicy.Option_Content_Documents_Versions }))))),
            React.createElement("hr", null),
            React.createElement(DisableArea, { isEnabled: currentPolicy.Option_Content_Action === "MoveContentToDifferentSite" || currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary", useOverlay: true },
                React.createElement(React.Fragment, null,
                    React.createElement("h6", null, "Lists"),
                    React.createElement(Form.Group, { controlId: "process-site-lists", className: "form-group checkbox-blue" },
                        React.createElement(Form.Check, { type: "checkbox", label: currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary" ? (React.createElement(React.Fragment, null,
                                "Export Lists to Excel",
                                React.createElement(InfoToolTip, null, "Process any Lists in the SharePoint site and export them to an Excel File"))) : (React.createElement(React.Fragment, null,
                                "Process Site Lists",
                                React.createElement(InfoToolTip, null, "Process any Lists in the SharePoint site"))), checked: currentPolicy.Option_Content_Lists_Process, onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    Option_Content_Lists_Process: event.target.checked
                                }));
                            } })),
                    React.createElement(Collapse, { in: currentPolicy.Option_Content_Lists_Process === true },
                        React.createElement("div", null,
                            React.createElement("div", { className: "property-row" },
                                React.createElement("div", { className: "left-side" },
                                    "Scope ",
                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                React.createElement("div", null,
                                    React.createElement(FormDropdownSelect, { placeholder: "Select a scope", list: [
                                            {
                                                id: "1",
                                                title: "All Lists",
                                                value: "AllLists"
                                            },
                                            {
                                                id: "2",
                                                title: "Choose Lists",
                                                value: "ChooseLists"
                                            }
                                        ], value: currentPolicy.Option_Content_Lists_Scope, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Option_Content_Lists_Scope: value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "Option_Content_Lists_Scope", FieldName: "Process List Scope", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.Option_Content_Lists_Process === true, Value: currentPolicy.Option_Content_Lists_Scope }))),
                            currentPolicy.Option_Content_Lists_Scope === "ChooseLists" ? (React.createElement("div", { className: "property-row" },
                                React.createElement("div", { className: "left-side" }),
                                React.createElement("div", null,
                                    React.createElement("div", { className: "list-lib-selector" },
                                        React.createElement(FormTextList, { onChange: function (values) {
                                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                    Option_Content_Lists_Scope_ChosenLists: values
                                                }));
                                            }, itemsIcon: "fa fa-list-alt", splitCharacter: "|", values: currentPolicy.Option_Content_Lists_Scope_ChosenLists, addButtonText: "Add list name", saveButtonText: "Save name", addInputPlaceholderText: "Enter list name", noItemsAddedText: "No list names added yet" }))))) : null)))),
            React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                React.createElement("h6", null, "Pages"),
                React.createElement(Form.Group, { controlId: "process-site-pages", className: "form-group checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? "Process Pages" : "Backup Pages to XML",
                            React.createElement(InfoToolTip, null, currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? "Process any pages in the Pages library" : "Backup Pages to a PnP export xml file")), checked: currentPolicy.Option_Content_Pages_Process, onChange: function (event) {
                            return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                Option_Content_Pages_Process: event.target.checked
                            }));
                        } })),
                currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" ? (React.createElement(Collapse, { in: currentPolicy.Option_Content_Pages_Process === true },
                    React.createElement("div", null,
                        React.createElement("div", { className: "property-row" },
                            React.createElement("div", { className: "left-side" },
                                "Version ",
                                React.createElement("span", { className: "required-astrix" }, "*")),
                            React.createElement("div", null,
                                React.createElement(FormDropdownSelect, { placeholder: "Select a scope", list: scopeDropdownValues, value: currentPolicy.Option_Content_Pages_Versions, onChange: function (value) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Content_Pages_Versions: value
                                        }));
                                    } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "Option_Content_Pages_Versions", FieldName: "Process Page Version", Validators: ["dropdown-has-value"], Category: "content", Enabled: currentPolicy.Option_Content_Pages_Process === true, Value: currentPolicy.Option_Content_Pages_Versions })))))) : null))));
});
var TabGroupOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    // React.useEffect(() => {
    //    if (currentPolicy.Option_Group_Planner_DeleteAnyPlannerPlans == true) {
    //       dispatch(
    //          LifecyclePolicyCurrentStore.UpdateProperties({
    //             Option_Group_Planner_MakePlannerPlansReadOnly: false
    //          })
    //       );
    //    }
    // }, [currentPolicy.Option_Group_Planner_DeleteAnyPlannerPlans]);
    // React.useEffect(() => {
    //    if (currentPolicy.Option_Group_Planner_MakePlannerPlansReadOnly == true) {
    //       dispatch(
    //          LifecyclePolicyCurrentStore.UpdateProperties({
    //             Option_Group_Planner_DeleteAnyPlannerPlans: false
    //          })
    //       );
    //    }
    // }, [currentPolicy.Option_Group_Planner_MakePlannerPlansReadOnly]);
    return (React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement("h4", null, "Group Options"),
            currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" && (React.createElement(React.Fragment, null,
                React.createElement("h6", null, "Exchange Settings"),
                React.createElement(Form.Group, { controlId: "remove-from-global-gal", className: "form-group checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            "Remove from Global Address List (GAL)",
                            React.createElement(InfoToolTip, null, "Specify if the Workspace when is still shown in the GAL")), checked: currentPolicy.Option_Group_Exchange_RemoveFromGlobalAccessList, onChange: function (event) {
                            return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                Option_Group_Exchange_RemoveFromGlobalAccessList: event.target.checked
                            }));
                        } })),
                React.createElement(Form.Group, { controlId: "remove-from-exhange-client", className: "form-group checkbox-blue" },
                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                            "Remove from Exchange Client",
                            React.createElement(InfoToolTip, null, "Remove the group in Outlook under the Groups section")), checked: currentPolicy.Option_Group_Exchange_RemoveFromExchangeClient, onChange: function (event) {
                            return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                Option_Group_Exchange_RemoveFromExchangeClient: event.target.checked
                            }));
                        } })))))));
});
var TabSecurityOptions = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (currentPolicy.Option_Permissions_Change_SetOwners === true && currentPolicy.Option_Permissions_RemoveAccess_ForAllOwners === false) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Permissions_RemoveAccess_ForAllOwners: true
            }));
        }
        if (currentPolicy.Option_Permissions_Change_SetOwners === false && currentPolicy.Option_Permissions_RemoveAccess_ForAllOwners === true) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Permissions_RemoveAccess_ForAllOwners: false
            }));
        }
        if (currentPolicy.Option_Permissions_Change_SetMembers === true && currentPolicy.Option_Permissions_RemoveAccess_ForAllMembers === false) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Permissions_RemoveAccess_ForAllMembers: true
            }));
        }
        if (currentPolicy.Option_Permissions_Change_SetVisitors === true && currentPolicy.Option_Permissions_RemoveAccess_ForAllGuests === false) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Permissions_RemoveAccess_ForAllGuests: true
            }));
        }
    }, [currentPolicy.Option_Permissions_Change_SetOwners, currentPolicy.Option_Permissions_Change_SetMembers, currentPolicy.Option_Permissions_Change_SetVisitors]);
    React.useEffect(function () {
        if (currentPolicy.Option_Permissions_RemoveAccess_ForAllOwners === true && currentPolicy.Option_Permissions_Change_SetOwners === false) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Permissions_Change_SetOwners: true
            }));
        }
    }, [currentPolicy.Option_Permissions_RemoveAccess_ForAllOwners]);
    React.useEffect(function () {
        if (currentPolicy.Option_Permissions_Inhert_Permissions_From_Parent === true && currentPolicy.Option_Content_Action !== "LeaveContentInPlace") {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                Option_Permissions_Change_SetOwners: false,
                Option_Permissions_RemoveAccess_ForAllOwners: false,
                Option_Permissions_Change_SetMembers: false,
                Option_Permissions_RemoveAccess_ForAllMembers: false,
                Option_Permissions_Change_SetVisitors: false,
                Option_Permissions_RemoveAccess_ForAllGuests: false
            }));
        }
    }, [currentPolicy.Option_Permissions_Inhert_Permissions_From_Parent]);
    var getLingo = function (lingo) {
        if (lingo === "Guest") {
            if (currentPolicy.Application === "CommunicationSite") {
                return "Visitor";
            }
        }
        if (lingo === "guest") {
            if (currentPolicy.Application === "CommunicationSite") {
                return "visitor";
            }
        }
        return lingo;
    };
    return (React.createElement(Row, { className: "permissions-tab-content" },
        React.createElement(Col, null,
            React.createElement("h4", null, "Permissions"),
            currentPolicy.Option_Content_Action !== "MoveContentOnlyToDifferentSiteLibrary" && (React.createElement(React.Fragment, null,
                currentPolicy.Option_Content_Action !== "LeaveContentInPlace" && (React.createElement(FormToggleControl, { label: "Inherit permissions from the parent archival site", border: true, checked: currentPolicy.Option_Permissions_Inhert_Permissions_From_Parent, handleChange: function (checked) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Option_Permissions_Inhert_Permissions_From_Parent: checked
                        }));
                    } })),
                React.createElement(DisableArea, { isDisabled: currentPolicy.Option_Content_Action !== "LeaveContentInPlace" && currentPolicy.Option_Permissions_Inhert_Permissions_From_Parent === true, useOverlay: true },
                    React.createElement(React.Fragment, null,
                        React.createElement("h6", null, "Remove Access"),
                        React.createElement(DisableArea, { isDisabled: currentPolicy.Option_Permissions_Change_SetOwners },
                            React.createElement(Form.Group, { controlId: "remove-access-for-all-owners", className: "form-group checkbox-blue" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Remove Access for All Owners", checked: currentPolicy.Option_Permissions_RemoveAccess_ForAllOwners, onChange: function (event) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Permissions_RemoveAccess_ForAllOwners: event.target.checked
                                        }));
                                    } }))),
                        React.createElement(DisableArea, { isDisabled: currentPolicy.Option_Permissions_Change_SetMembers },
                            React.createElement(Form.Group, { controlId: "remove-access-for-all-members", className: "form-group checkbox-blue" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Remove Access for All Members", checked: currentPolicy.Option_Permissions_RemoveAccess_ForAllMembers, onChange: function (event) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Permissions_RemoveAccess_ForAllMembers: event.target.checked
                                        }));
                                    } }))),
                        React.createElement(DisableArea, { isDisabled: currentPolicy.Option_Permissions_Change_SetVisitors },
                            React.createElement(Form.Group, { controlId: "remove-access-for-all-guests", className: "form-group checkbox-blue" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Remove Access for All " + getLingo("Guest") + "s", checked: currentPolicy.Option_Permissions_RemoveAccess_ForAllGuests, onChange: function (event) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Option_Permissions_RemoveAccess_ForAllGuests: event.target.checked
                                        }));
                                    } }))),
                        React.createElement("hr", null),
                        React.createElement("h6", null, "Change Access"),
                        React.createElement(Form.Group, { controlId: "set-team-owners", className: "form-group checkbox-blue" },
                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Set Owners To"), checked: currentPolicy.Option_Permissions_Change_SetOwners, onChange: function (event) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Permissions_Change_SetOwners: event.target.checked
                                    }));
                                } })),
                        React.createElement(Collapse, { in: currentPolicy.Option_Permissions_Change_SetOwners === true },
                            React.createElement("div", { className: "property-row permissions" },
                                React.createElement("div", null,
                                    React.createElement("div", { className: "user-picker" },
                                        React.createElement(FormUserGroupSelect, { defaultValue: currentPolicy.Option_Permissions_Change_SetOwnersTo === null ? undefined : currentPolicy.Option_Permissions_Change_SetOwnersTo, allowMultiple: true, returnType: "usersAndGroups", displayAddAsButton: true, onChange: function (groups) {
                                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                    Option_Permissions_Change_SetOwnersTo: groups
                                                }));
                                            } }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "Option_Permissions_Change_SetOwnersTo", ErrorMessage: "Select at least one user to change owners to under Actions > Security Options", Validators: ["string-array-has-items"], Category: "permissions", Enabled: currentPolicy.Option_Permissions_Change_SetOwners === true, Value: currentPolicy.Option_Permissions_Change_SetOwnersTo }))))),
                        React.createElement(Form.Group, { controlId: "set-team-members", className: "form-group checkbox-blue" },
                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Set Members To"), checked: currentPolicy.Option_Permissions_Change_SetMembers, onChange: function (event) {
                                    return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Permissions_Change_SetMembers: event.target.checked
                                    }));
                                } })),
                        React.createElement(Collapse, { in: currentPolicy.Option_Permissions_Change_SetMembers === true },
                            React.createElement("div", { className: "property-row permissions" },
                                React.createElement("div", null,
                                    React.createElement("div", { className: "user-picker" },
                                        React.createElement(FormUserGroupSelect, { defaultValue: currentPolicy.Option_Permissions_Change_SetMembersTo === null ? undefined : currentPolicy.Option_Permissions_Change_SetMembersTo, allowMultiple: true, returnType: "usersAndGroups", displayAddAsButton: true, onChange: function (groups) {
                                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                    Option_Permissions_Change_SetMembersTo: groups
                                                }));
                                            } }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "Option_Permissions_Change_SetMembersTo", ErrorMessage: "Select at least one user to change members to under Actions > Security Options", Validators: ["string-array-has-items"], Category: "permissions", Enabled: currentPolicy.Option_Permissions_Change_SetMembers === true, Value: currentPolicy.Option_Permissions_Change_SetMembersTo }))))),
                        React.createElement("hr", null))))),
            React.createElement("h6", null, "Permission Configuration"),
            React.createElement(Form.Group, { controlId: "extract-permission-configuration", className: "form-group checkbox-blue" },
                React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                        "Extract permission configuration",
                        React.createElement(InfoToolTip, null, "Extracts the current Group and SharePoint site permissions into an Excel file")), checked: currentPolicy.Option_Permissions_Config_Extract, onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Option_Permissions_Config_Extract: event.target.checked
                        }));
                    } })))));
});
var TabIntegrations = React.memo(function () {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "webhook-tab" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h4", null, "Integrations"),
                React.createElement("h6", null, "Webhook"),
                React.createElement(FormWebhook, { exampleJson: {
                        Secret: "",
                        WorkspaceName: "Example Name",
                        WorkspaceType: "Team|TeamSite|CommunicationSite",
                        SiteId: "00000000-0000-0000-0000-000000000000",
                        SiteUrl: "https://example.sharepoint.com/sites/example",
                        GroupId: "00000000-0000-0000-0000-000000000000",
                        LastActivityDate: "2000-01-01T01:01:01.0000000",
                        DaysInactive: "7",
                        ArchivalPolicyName: "Example Name",
                        ArchivalType: "LeaveContentInPlace|Delete|MoveContentToDifferentSite",
                        ArchivalUrl: "https://example.sharepoint.com/sites/archival-site-collection/example",
                        IsTeam: true,
                        TemplateName: "Template Name",
                        TemplateId: "00000000-0000-0000-0000-000000000000",
                        ArchivalIntegrityCheck: {
                            SiteId: "00000000-0000-0000-0000-000000000000",
                            Error: null,
                            ErrorStack: null,
                            PolicyName: "Example Name",
                            PolicyContentAction: "MoveContentOnlyToDifferentSiteLibrary",
                            Libraries: [
                                {
                                    LibraryTitle: "Example Name",
                                    SourceUrl: "/sites/source-site-collection/example",
                                    DestinationUrl: "/sites/archival-site-collection/example/shared documents/archival-subsite/documents",
                                    FilesCountInSource: 123,
                                    FilesCountInDestination: 124,
                                    FilesCountMissingFromDestination: 0,
                                    MissingFilesFromDestionation: ["/file-example-name.xlsx"],
                                    MissingFilesFromSource: []
                                }
                            ],
                            TotalSourceFiles: 123,
                            TotalDestFiles: 124,
                            TotalMissingFiles: 0
                        }
                    }, webhookSecret: currentPolicy.Integrations_WebhookSecret, webhookUrl: currentPolicy.Integrations_WebhookUrl, OnWebhookSecretChange: function (value) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Integrations_WebhookSecret: value
                        }));
                    }, OnWebhookUrlChange: function (value) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                            Integrations_WebhookUrl: value
                        }));
                    } })))));
});
