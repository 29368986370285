import { getIconClassName } from "@fluentui/react";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import axios from "axios";
import * as React from "react";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ColumnPopoutMenu from "../column-popout-menu";
import "./report-column-workspace-guest-membership-actions.scss";
var ResendModalMessage = function (_a) {
    var workspaceName = _a.workspaceName;
    var intl = useIntl();
    return (React.createElement("div", null, workspaceName ? (React.createElement(React.Fragment, null,
        React.createElement("p", null, intl.formatMessage({
            id: "actions.bulk.resend_invite_modal.body_with_workspace_name",
            defaultMessage: "Would you like to resend {wsCount, plural, one {the invite} other {the invites}} for:"
        }, { wsCount: workspaceName.length > 0 ? 2 : 1 })),
        React.createElement("p", null,
            React.createElement("strong", null, workspaceName)),
        " ")) : (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Are you sure you would like to resend the invitation?")))));
};
var RemoveGuestFromWorkspaceMessage = function (_a) {
    var workspaceName = _a.workspaceName;
    var intl = useIntl();
    return (React.createElement("div", null,
        React.createElement("p", null, intl.formatMessage({
            id: "actions.bulk.remove_guest_modal.body",
            defaultMessage: "Are you sure you'd like to remove {isplural, plural, one {this guest from} other {these guests from}}:"
        }, { isplural: 0 })),
        React.createElement("p", null,
            React.createElement("strong", null, workspaceName))));
};
var DeleteGuestMessage = function () {
    return (React.createElement("div", null,
        React.createElement("p", null, "Are you sure you'd like to remove this guest from Azure?")));
};
var ReportColumnGuestActions = function (props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var _b = React.useState([]), popoutItems = _b[0], setPopoutItems = _b[1];
    var workspaceReportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState; });
    var dispatch = useDispatch();
    var intl = useIntl();
    var getWorkspaceUrl = function () {
        var _a;
        if (props.Item.ExtendedProperties !== undefined &&
            props.Item.ExtendedProperties !== null &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== undefined &&
            props.Item.ExtendedProperties.CosmosWorkspaceDetails !== null) {
            return props.Item.ExtendedProperties.CosmosWorkspaceDetails.TeamUrl;
        }
        if (workspaceReportItem !== null) {
            if ((workspaceReportItem === null || workspaceReportItem === void 0 ? void 0 : workspaceReportItem.Application) === "Team") {
                return workspaceReportItem.TeamUrl;
            }
            else if ((workspaceReportItem === null || workspaceReportItem === void 0 ? void 0 : workspaceReportItem.Application) === "TeamSite") {
                return workspaceReportItem.SiteUrl;
            }
        }
        if (teamContext !== null) {
            return ("https://teams.microsoft.com/l/team/" + ((_a = teamContext.team) === null || _a === void 0 ? void 0 : _a.groupId));
        }
        return "https://account.activedirectory.windowsazure.com/?tenantid=".concat(orchestryContext.item.TenantGuid, "%26login_hint=").concat(props.Item.Mail);
    };
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        var tempItems = [];
        if (props.Mode === "GuestListing") {
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.view_in_azure",
                text: "View in Azure",
                icon: getIconClassName("AzureLogo"),
                link: { href: "https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/".concat(props.Item.UserId), title: "View ".concat(props.Item.DisplayName, " in Azure") }
            });
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.resend_invitation",
                text: "Resend Invitation",
                icon: "fa-regular fa-paper-plane",
                confirmation: {
                    title: intl.formatMessage({ id: "actions.bulk.resend_invite_modal.title", defaultMessage: "Resend Azure Guest Invitation" }),
                    message: React.createElement(ResendModalMessage, { workspaceName: props.Item.Title }),
                    confirmButtonText: "Resend Invite",
                    cancelButtonText: "Cancel"
                },
                action: function () { return actionResendInvitiation(props.Item.UserId, getWorkspaceUrl()); }
            });
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.delete_from_azure",
                text: "Delete from Azure",
                icon: "fa-regular fa-trash-can-xmark",
                action: function () { return actionDeleteGuest(props.Item.UserId); },
                confirmation: {
                    title: "Delete from Azure",
                    message: React.createElement(DeleteGuestMessage, null),
                    confirmButtonText: "Delete Guest",
                    cancelButtonText: "Cancel"
                }
            });
        }
        if (props.Mode === "WorkspaceDetailsGuestList" && (workspaceReportItem !== null || teamContext !== null)) {
            var groupId = workspaceReportItem !== null ? workspaceReportItem.GroupId : (_a = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _a === void 0 ? void 0 : _a.groupId;
            var title = workspaceReportItem !== null ? workspaceReportItem.Title : (_b = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _b === void 0 ? void 0 : _b.displayName;
            var email = props.Item.Mail !== undefined ? props.Item.Mail : ((_d = (_c = props.Item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.CosmosUserDetails) === null || _d === void 0 ? void 0 : _d.Mail) !== undefined ? (_f = (_e = props.Item.ExtendedProperties) === null || _e === void 0 ? void 0 : _e.CosmosUserDetails) === null || _f === void 0 ? void 0 : _f.Mail : null;
            var upn = props.Item.UserPrincipalName !== undefined
                ? props.Item.UserPrincipalName
                : ((_h = (_g = props.Item.ExtendedProperties) === null || _g === void 0 ? void 0 : _g.CosmosUserDetails) === null || _h === void 0 ? void 0 : _h.UserPrincipalName) !== undefined
                    ? (_k = (_j = props.Item.ExtendedProperties) === null || _j === void 0 ? void 0 : _j.CosmosUserDetails) === null || _k === void 0 ? void 0 : _k.UserPrincipalName
                    : null;
            var displayName = props.Item.DisplayName !== undefined
                ? props.Item.DisplayName
                : ((_m = (_l = props.Item.ExtendedProperties) === null || _l === void 0 ? void 0 : _l.CosmosUserDetails) === null || _m === void 0 ? void 0 : _m.DisplayName) !== undefined
                    ? (_p = (_o = props.Item.ExtendedProperties) === null || _o === void 0 ? void 0 : _o.CosmosUserDetails) === null || _p === void 0 ? void 0 : _p.DisplayName
                    : null;
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.remove_from_workspace",
                text: "Remove from Workspace",
                icon: "fa-regular fa-user-xmark",
                action: function () { return actionRemoveFromWorkspace(props.Item.UserId, groupId); },
                confirmation: {
                    title: intl.formatMessage({
                        id: "actions.bulk.remove_guest_modal.remove_guests_from_ws",
                        defaultMessage: "Remove {selectedCount, plural, one {Guest} other {Guests}} from Workspace"
                    }, { selectedCount: 1 }),
                    message: React.createElement(RemoveGuestFromWorkspaceMessage, { workspaceName: title }),
                    confirmButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.remove_guest_button", defaultMessage: "Remove Guest" }),
                    cancelButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.cancel_button", defaultMessage: "Cancel" })
                }
            });
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.resend_invitation",
                text: "Resend Invitation",
                icon: "fa-regular fa-paper-plane",
                action: function () { return actionResendInvitiation(props.Item.UserId, getWorkspaceUrl()); },
                confirmation: {
                    title: intl.formatMessage({ id: "actions.bulk.resend_invite_modal.title", defaultMessage: "Resend Azure Guest Invitation" }),
                    message: React.createElement(ResendModalMessage, { workspaceName: title }),
                    confirmButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.resend_button", defaultMessage: "Resend" }),
                    cancelButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.cancel_button", defaultMessage: "Cancel" })
                }
            });
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.email",
                text: "Email",
                icon: "fa-regular fa-envelope",
                disabled: email === null,
                link: { href: "".concat(email !== null ? "mailto:".concat(email) : ""), title: "".concat(displayName !== null ? "Email ".concat(displayName) : "No Email Found") }
            });
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.chat",
                text: "Chat",
                icon: "fa-regular fa-messages",
                disabled: upn === null,
                link: {
                    href: "".concat(upn !== null ? "https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent(upn)) : ""),
                    title: "".concat(displayName !== null ? "Chat with ".concat(displayName) : "User does not have valid Email")
                }
            });
        }
        if (props.Mode === "GuestWorkspaceMemberships") {
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.resend_invitation",
                text: "Resend Invitation",
                icon: "fa-regular fa-paper-plane",
                confirmation: {
                    title: intl.formatMessage({ id: "actions.bulk.resend_invite_modal.title", defaultMessage: "Resend Azure Guest Invitation" }),
                    message: React.createElement(ResendModalMessage, { workspaceName: ((_r = (_q = props.Item.ExtendedProperties) === null || _q === void 0 ? void 0 : _q.CosmosWorkspaceDetails) === null || _r === void 0 ? void 0 : _r.Title) || props.Item.Title }),
                    confirmButtonText: "Resend Invite",
                    cancelButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.cancel_button", defaultMessage: "Cancel" })
                },
                action: function () { var _a, _b; return actionResendInvitiation(((_b = (_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.GuestMembershipDetails) === null || _b === void 0 ? void 0 : _b.UserId) || props.Item.UserId, getWorkspaceUrl()); }
            });
            tempItems.push({
                id: props.Item.Id,
                translation_id: "actions.bulk.guests.remove_from_workspace",
                text: "Remove from Workspace",
                icon: "fa-regular fa-user-xmark",
                confirmation: {
                    title: intl.formatMessage({
                        id: "actions.bulk.remove_guest_modal.remove_guests_from_ws",
                        defaultMessage: "Remove {selectedCount, plural, one {Guest} other {Guests}} from Workspace"
                    }, { selectedCount: 1 }),
                    message: React.createElement(RemoveGuestFromWorkspaceMessage, { workspaceName: ((_t = (_s = props.Item.ExtendedProperties) === null || _s === void 0 ? void 0 : _s.CosmosWorkspaceDetails) === null || _t === void 0 ? void 0 : _t.Title) || props.Item.Title }),
                    confirmButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.remove_guest_button", defaultMessage: "Remove Guest" }),
                    cancelButtonText: intl.formatMessage({ id: "actions.bulk.remove_guest_modal.cancel_button", defaultMessage: "Cancel" })
                },
                action: function () {
                    var _a, _b, _c, _d;
                    return actionRemoveFromWorkspace(((_b = (_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.GuestMembershipDetails) === null || _b === void 0 ? void 0 : _b.UserId) || props.Item.UserId, ((_d = (_c = props.Item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.CosmosWorkspaceDetails) === null || _d === void 0 ? void 0 : _d.GroupId) || props.Item.GroupId);
                }
            });
        }
        setPopoutItems(tempItems);
    }, [props.Item, props.Mode]);
    var actionDeleteGuest = function (guestId) {
        if (!guestId) {
            console.log("missing parameter, guestId");
            return;
        }
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Guests/DeleteGuest?guestId=" + guestId;
        axiosConfig.responseType = "json";
        setLoading(true);
        axios(axiosConfig)
            .then(function (response) {
            if (response.status === 200) {
                if (props.cleanupAction !== null && props.cleanupAction !== undefined)
                    props.cleanupAction(true);
                setLoading(false);
                dispatch(LifecyclePolicyWorkspaceStore.DeleteGuestSuccess());
            }
        })
            .catch(function (response) {
            setLoading(false);
            console.error(response);
        });
    };
    var actionRemoveFromWorkspace = function (guestId, groupId) {
        if (!guestId || !groupId) {
            console.log("missing parameter, guestId: ".concat(guestId, " groupId ").concat(groupId));
            return;
        }
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Guests/RemoveGuestFromWorkspace?guestId=" + guestId + "&groupId=" + groupId;
        axiosConfig.responseType = "json";
        setLoading(true);
        axios(axiosConfig)
            .then(function (response) {
            if (response.status === 200) {
                if (props.cleanupAction !== null && props.cleanupAction !== undefined)
                    props.cleanupAction(true);
                setLoading(false);
                dispatch(LifecyclePolicyWorkspaceStore.RemoveFromWorkspaceSuccess());
            }
        })
            .catch(function (response) {
            setLoading(false);
            console.error(response);
        });
    };
    var actionResendInvitiation = function (guestId, url) {
        if (!guestId || !url) {
            console.log("missing parameter, guestId: ".concat(guestId, " or url: ").concat(url));
            return;
        }
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Guests/ResendInvite?guestId=" + guestId + "&inviteUrl=" + url;
        axiosConfig.responseType = "json";
        setLoading(true);
        axios(axiosConfig)
            .then(function (response) {
            if (response.status === 200) {
                setLoading(false);
                dispatch(LifecyclePolicyWorkspaceStore.ResendInviteSuccess());
            }
        })
            .catch(function (response) {
            var _a;
            if (((_a = response.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                setLoading(false);
                dispatch(LifecyclePolicyWorkspaceStore.ResendInviteFailure());
            }
            setLoading(false);
            console.error(response);
        });
    };
    return React.createElement("div", { className: "report-column-workspace-guest-membership-actions" }, loading ? React.createElement(Spinner, { animation: "border" }) : React.createElement(ColumnPopoutMenu, { items: popoutItems }));
};
export default ReportColumnGuestActions;
