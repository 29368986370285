import { Card, Grid, Group, Stack } from "@mantine/core";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { ActioningRecommendationsTile } from "./tiles/actioning-recommendation-tile.component";
import { MaintainingTemplatesTile } from "./tiles/maintaining-templates-tile.component";
import { ManagingGuestsTile } from "./tiles/managing-guests-tile.component";
import { ManagingWorkspacesTile } from "./tiles/managing-workspaces-tile.component";
import { ProvisioningNewWorkspacesTile } from "./tiles/provisioning-new-workspaces-tile.component";
export var SavingsSection = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isMobile = _a.isMobile, isLoading = _a.isLoading, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.YourSavings, _g = _f === void 0 ? {} : _f, TotalHours = _g.TotalHours;
    var message = intl.formatMessage({ id: "page.health_check.total_hours_saved_tile.title", defaultMessage: "Total Hours Saved: {count} Hours" }, { count: "".concat(TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString()) });
    var _h = message.split("".concat(TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString())), firstPart = _h[0], secondPart = _h[1];
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.TimeSavings, renderListOfAnnotations: true, sectionTitle: React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.your_savings_section.title", defaultMessage: "Your Savings with Orchestry" }) }) },
        React.createElement(Card, { mt: 8, w: "100%", p: "24px", style: { borderRadius: "8px", border: "1px solid var(--mantine-color-primary-7)" }, variant: "shadow" },
            React.createElement(Stack, { gap: 16 },
                React.createElement(Group, { gap: 0 },
                    React.createElement(OrchestryHighlight, { pl: 16, pr: 16, isLoading: isLoading, c: "text-primary.0", fz: "24", fw: 400, lh: "32px", highlight: ["".concat(TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString())], highlightStyles: {
                            padding: 0,
                            fontSize: "24px",
                            fontWeight: 700,
                            lineHeight: "32px",
                            backgroundColor: "var(--mantine-color-text-primary-0)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent"
                        } }, firstPart),
                    isMobile && React.createElement("br", null),
                    React.createElement(OrchestryHighlight, { pl: isMobile ? 16 : undefined, pr: 16, isLoading: isLoading, c: "text-primary.0", fz: "24", fw: 400, lh: "32px", highlight: ["".concat(TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString())], highlightStyles: {
                            padding: 0,
                            fontSize: "24px",
                            fontWeight: 700,
                            lineHeight: "32px",
                            backgroundColor: "var(--mantine-color-text-primary-0)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent"
                        } }, "".concat(TotalHours === null || TotalHours === void 0 ? void 0 : TotalHours.toLocaleString(), " ").concat(secondPart))),
                React.createElement(Grid, { gutter: 0, columns: 5 },
                    React.createElement(Grid.Col, { span: 1, miw: 213 },
                        React.createElement(ActioningRecommendationsTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: 213 },
                        React.createElement(MaintainingTemplatesTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: 213 },
                        React.createElement(ProvisioningNewWorkspacesTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: 213 },
                        React.createElement(ManagingGuestsTile, null)),
                    React.createElement(Grid.Col, { span: 1, miw: 213 },
                        React.createElement(ManagingWorkspacesTile, null)))))));
};
