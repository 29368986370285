import * as React from "react";
import moment from "moment";
var ColumnDynamicDate = React.memo(function (props) {
    var _a = React.useState(""), textValue = _a[0], setTextValue = _a[1];
    React.useEffect(function () {
        var _a;
        if (props.Item.ExtendedProperties !== undefined &&
            props.Item.ExtendedProperties !== null &&
            ((_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) !== undefined &&
            props.Item.ExtendedProperties.CosmosUserDetails[props.Col.ProperyName] !== undefined) {
            setTextValue(props.Item.ExtendedProperties.CosmosUserDetails[props.Col.ProperyName]);
        }
        else if (props.Item.ExtendedProperties !== undefined && props.Item.ExtendedProperties !== null && props.Item.ExtendedProperties[props.Col.ProperyName] !== undefined) {
            setTextValue(props.Item.ExtendedProperties[props.Col.ProperyName]);
        }
        else {
            if (props.Item[props.Col.ProperyName] !== undefined && props.Item[props.Col.ProperyName] !== null) {
                setTextValue(props.Item[props.Col.ProperyName]);
            }
            else {
                setTextValue("");
            }
        }
    }, [props.Item]);
    return (React.createElement("p", null, textValue !== null && textValue !== "" && moment.utc(textValue).isValid()
        ? moment
            .utc(textValue)
            .local()
            .format("MMM Do, YYYY")
        : ""));
});
export default ColumnDynamicDate;
