var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TenantConfigurationCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {},
    loadedOnce: false
};
export function tenantConfigurationCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load: {
            //console.log(TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, action)
            return {
                item: action.payload,
                loadedOnce: true
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Without_Server_Save: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Save_To_Server: {
            return {
                item: __assign({}, state.item),
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DocTempCats: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TempCats: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DirectoryConfig: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Silent_Update: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TeamConfig: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        case TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Config: {
            var updatedTenantConfiguration = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenantConfiguration,
                loadedOnce: state.loadedOnce
            };
        }
        default:
            return state;
    }
}
