import "@mantine/core/styles.css";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import { OrchestryCombobox } from "appv2/components/orchestry-combobox/orchestry-combobox.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryImageDropzone } from "appv2/components/orchestry-image-dropzone/orchestry-image-dropzone.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import { OrchestrySettingsPageControl } from "appv2/components/orchestry-settings-page-control/orchestry-settings-page-control.component";
import { OrchestryToggle } from "appv2/components/orchestry-toggle/orchestry-toggle.component";
import { ORCHESTRY_LOGO_IMAGE_MIME_TYPE, ORCHESTRY_LOGO_MAX_SIZE_BYTES } from "appv2/types/globals.types";
import React from "react";
import { mapFormGroupsStringToIds } from "../../../utils/control.utils";
import { AutomatedGenerationDescription, HealthCheckReportBrandingDescription, ReviewProcessDescription, ScheduleCadenceDescription, ViewersDescription } from "./health-checks-settings-control-descriptions.component";
import "./health-checks-settings-page.scss";
import { useHealthChecksSettingsPage } from "./use-health-checks-settings-page.hook";
export var HealthChecksSettingsPage = function () {
    var _a = useHealthChecksSettingsPage(), intl = _a.intl, form = _a.form, handleFormSubmit = _a.handleFormSubmit, handleImageDrop = _a.handleImageDrop, isReviewProcessSelfLed = _a.isReviewProcessSelfLed, isReviewProcessPartnerLed = _a.isReviewProcessPartnerLed, hasConnectedPartner = _a.hasConnectedPartner, partnerOrganizationName = _a.partnerOrganizationName, onBackButtonClicked = _a.onBackButtonClicked, shouldDisplayCadenceControl = _a.shouldDisplayCadenceControl, isPageLoading = _a.isPageLoading, isSaveDisabled = _a.isSaveDisabled, reviewProcessOptions = _a.reviewProcessOptions, onReviewProcessChange = _a.onReviewProcessChange;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "health-checks-settings-page" },
                React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
                    React.createElement(Subtitle, { title: "Health Checks", subtitle: "Settings" },
                        React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, intl.formatMessage({
                            id: "healthcheck.go_back_to_health_checks",
                            defaultMessage: "Go Back to Health Checks"
                        }))),
                    React.createElement(OrchestryPageHeader, null,
                        React.createElement(OrchestryPageTitle, { title: "Health Checks", subTitle: "Configure your Health Check settings including automatic generation, permissions and branding." }),
                        React.createElement(OrchestryActionButtons, null,
                            React.createElement(OrchestrySaveButton, { disabled: isSaveDisabled }))),
                    React.createElement(OrchestrySettingsPageControl, { title: "Automated Generation", description: AutomatedGenerationDescription() },
                        React.createElement(OrchestryToggle, { checked: form.getInputProps("GenerateAutomatically").value, onChange: function (value) { return form.setFieldValue("GenerateAutomatically", value); }, onLabel: "Enabled", offLabel: "Disabled", isLoading: isPageLoading })),
                    React.createElement(OrchestryDivider, null),
                    shouldDisplayCadenceControl && (React.createElement(React.Fragment, null,
                        React.createElement(OrchestrySettingsPageControl, { title: "Scheduled Cadence", description: ScheduleCadenceDescription() },
                            React.createElement("div", { className: "schedule-cadence" },
                                React.createElement(OrchestryCombobox, { placeholder: "", options: [{ value: "Monthly" }, { value: "Quarterly" }, { value: "SemiAnnually", label: "Semi Annually" }, { value: "Annually" }], iconClosed: ORCH_ICONS.chevronDown, iconOpened: ORCH_ICONS.chevronUp, defaultValue: "Monthly", variant: "selected-value", onChange: function (value) { return form.setFieldValue("ScheduleCadence", value); }, value: form.getInputProps("ScheduleCadence").value, isLoading: isPageLoading }))),
                        React.createElement(OrchestryDivider, null))),
                    React.createElement(OrchestrySettingsPageControl, { title: "Review Process", description: ReviewProcessDescription() },
                        React.createElement("div", { className: "review-process" },
                            React.createElement("div", { className: "review-process-dropdown" },
                                React.createElement(OrchestryCombobox, { placeholder: "", options: reviewProcessOptions, disabledOptions: hasConnectedPartner ? [] : [{ value: "PartnerLed" }], iconClosed: ORCH_ICONS.chevronDown, iconOpened: ORCH_ICONS.chevronUp, defaultValue: "Disabled", variant: "selected-value", onChange: onReviewProcessChange, value: form.getInputProps("ReviewProcess").value, isLoading: isPageLoading })),
                            React.createElement(FormUserGroupSelect, { isLoading: isPageLoading, hidden: !isReviewProcessSelfLed, defaultValue: undefined, allowMultiple: true, returnType: "users", label: "Reviewers:", value: form.getInputProps("Reviewers").value, onChange: function (groups) { return form.setFieldValue("Reviewers", mapFormGroupsStringToIds(groups)); }, excludeGuests: true, shouldUseDynamic: true, error: form.getInputProps("Reviewers").error }),
                            isReviewProcessPartnerLed && (React.createElement(OrchestryHighlight, { isLoading: isPageLoading, highlight: partnerOrganizationName !== null && partnerOrganizationName !== void 0 ? partnerOrganizationName : "", highlightStyles: { fontWeight: 500, backgroundColor: "var(--mantine-color-primary-9)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }, c: "gray.7", size: "12px", fw: 300, lh: "18px" }, "Connected with Partner ".concat(partnerOrganizationName))))),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: "Viewers", description: ViewersDescription() },
                        React.createElement(FormUserGroupSelect, { isLoading: isPageLoading, defaultValue: undefined, allowMultiple: true, returnType: "users", label: "Viewers:", value: form.getInputProps("Viewers").value, onChange: function (groups) { return form.setFieldValue("Viewers", mapFormGroupsStringToIds(groups)); }, excludeGuests: true, shouldUseDynamic: true, error: form.getInputProps("Viewers").error })),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: "Health Check Report Branding", description: HealthCheckReportBrandingDescription() },
                        React.createElement(OrchestryImageDropzone, { loading: isPageLoading, base64Value: form.getInputProps("HealthCheckReportBrandingImage").value, imageStyles: { width: "38px", height: "38px" }, onDrop: handleImageDrop, accept: ORCHESTRY_LOGO_IMAGE_MIME_TYPE, maxSize: ORCHESTRY_LOGO_MAX_SIZE_BYTES, onPreviewRemoveClick: function () { return form.setFieldValue("HealthCheckReportBrandingImage", ""); } })),
                    React.createElement(OrchestryDivider, null))))));
};
