import { AsyncActionTypes } from "./types";
var initialState = {
    items: []
};
export function asyncReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AsyncActionTypes.Async_Invalid: {
            //console.log("Invalidating", action);
            return {
                items: rh.mergeActionIntoItems(state, action)
            };
        }
        case AsyncActionTypes.Async_Start: {
            return {
                items: rh.mergeActionIntoItems(state, action)
            };
        }
        case AsyncActionTypes.Async_Succeed: {
            //console.log("Async Succeed!");
            return {
                items: rh.mergeActionIntoItems(state, action)
            };
        }
        case AsyncActionTypes.Async_Failed: {
            //console.log("Async Failed!");
            return {
                items: rh.mergeActionIntoItems(state, action)
            };
        }
        default:
            return state;
    }
}
var rh = /** @class */ (function () {
    function rh() {
    }
    rh.mergeActionIntoItems = function (state, action) {
        var tempAsyncItems = [];
        if (rh.hasMatchingItem(state, action)) {
            tempAsyncItems = state.items.map(function (itm) { return (itm.key === action.payload.key ? action.payload : itm); });
        }
        else {
            tempAsyncItems = [].concat(state.items);
            tempAsyncItems.push(action.payload);
        }
        return tempAsyncItems;
    };
    rh.hasMatchingItem = function (state, action) {
        return state.items.some(function (i) { return i.key === action.payload.key; });
    };
    return rh;
}());
