import { Card, Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
import { ToDatePill } from "../to-date-pill.component";
export var NotificationsTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, numberOfDaysInPeriod = _a.numberOfDaysInPeriod, _b = _a.data.MetricsData.OrchestryUsage.Notifications, NotificationsSentTotal = _b.NotificationsSentTotal, UsersContactedTotal = _b.UsersContactedTotal, PreviousValue = _b.PreviousValue, DeltaValue = _b.DeltaValue, DeltaPercentage = _b.DeltaPercentage, DataCoverageAvailable = _b.DataCoverageAvailable, TotalToDate = _b.TotalToDate;
    return (React.createElement(Card, { variant: "shadow-border", mih: "100%", p: 24, style: { overflow: "visible" } },
        React.createElement(Group, { gap: "8px" },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "xs", variant: "light-circle", colorVariant: "pink-7", iconClassName: ORCH_ICONS.notificationBox }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "14", fw: 500, lh: "20px" }, intl.formatMessage({ id: "page.health_check.notifications_tile.title", defaultMessage: "Notifications" }))),
        React.createElement(OrchestryText, { skeletonWidth: 30, isLoading: isLoading, c: "text-primary.0", fz: 24, fw: 500, lh: "32px", mt: 8 }, NotificationsSentTotal.toLocaleString()),
        React.createElement(OrchestryText, { skeletonWidth: 60, isLoading: isLoading, c: "text-secondary.0", fz: 14, fw: 300, lh: "20px", mt: -4 }, intl.formatMessage({ id: "page.health_check.notifications_tile.notifications_sent", defaultMessage: "Notifications Sent" })),
        React.createElement(OrchestryText, { skeletonWidth: 30, isLoading: isLoading, c: "text-primary.0", fz: 24, fw: 500, lh: "32px", mt: 8 }, UsersContactedTotal.toLocaleString()),
        React.createElement(OrchestryText, { skeletonWidth: 60, isLoading: isLoading, c: "text-secondary.0", fz: 14, fw: 300, lh: "20px", mt: -4 }, intl.formatMessage({ id: "page.health_check.notifications_tile.users_contacted", defaultMessage: "Users Contacted" })),
        React.createElement(Group, { p: 0, mt: 12 },
            React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, align: "Flex-start", lastPeriodValue: PreviousValue, deltaPercentage: DeltaPercentage, numberOfDaysInPeriod: numberOfDaysInPeriod, dataCoverageAvailable: DataCoverageAvailable, deltaValue: DeltaValue })),
        React.createElement(ToDatePill, { tileTooltipId: "page.health_check.notifications_tile.tooltip", tileTooltipDefaultMessage: "Both the delta value and total cumulative value (to date) reflect solely the notifications sent.", isLoading: isLoading, totalToDate: TotalToDate !== null && TotalToDate !== void 0 ? TotalToDate : 0, badgeColor: "var(--mantine-color-pink-7)", mt: 8 })));
};
