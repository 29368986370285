/**
 * Returns an object containing the count of selected filters for each group.
 *
 * @param filters An array of filter objects.
 */
export var getGroupCounts = function (filters) {
    return filters.reduce(function (acc, filter) {
        if (filter.group && filter.selected) {
            var group = filter.group;
            if (!acc[group]) {
                acc[group] = 0;
            }
            acc[group]++;
        }
        return acc;
    }, {});
};
