import { isEmail, useForm } from "@mantine/form";
import { useGetPartnerSettingsQuery, useUpdatePartnerSettingsMutation } from "appv2/api/partner-tools/partner-settings.api";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useEffect } from "react";
import { useHistory } from "react-router";
export var usePartnerSettingsPage = function () {
    var history = useHistory();
    var onBackButtonClicked = function () { return history.push("/partner-tools"); };
    var _a = useGetPartnerSettingsQuery(), partnerSettings = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, refetch = _a.refetch;
    var _b = useUpdatePartnerSettingsMutation(), updatePartnerSettings = _b[0], isUpdatePartnerSettingsSuccess = _b[1].isSuccess;
    useEffect(function () {
        if (partnerSettings) {
            form.setValues(partnerSettings);
            form.setInitialValues(partnerSettings);
        }
        // form is not a dependency because it is updated in the useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerSettings]);
    var form = useForm({
        initialValues: {
            OrganizationName: "",
            LicenseKey: "",
            PartnerSecret: "",
            SupportEmail: "",
            HubAdmins: "",
            Logo: ""
        },
        validate: {
            SupportEmail: function (value) { return (value ? isEmail("Invalid email address")(value) : null); }
        }
    });
    // Refetch the settings when the page is navigated away from and back to
    useEffect(function () {
        refetch();
    }, [refetch]);
    /**
     * Handles the image drop event. Saves the base64 encoded image to the form.
     *
     * @param files The dropped files. Only the first file is used
     */
    var handleImageDrop = function (files) {
        var reader = new FileReader();
        reader.onload = function () {
            var fileData = reader.result;
            form.setFieldValue("Logo", fileData);
        };
        reader.readAsDataURL(files[0]);
    };
    useEffect(function () {
        if (isUpdatePartnerSettingsSuccess) {
            OrchestrySuccessToast("Partner settings saved successfully");
        }
    }, [isUpdatePartnerSettingsSuccess]);
    var handleFormSubmit = function (values) {
        form.setInitialValues(values);
        updatePartnerSettings(values);
    };
    var isPageLoading = !partnerSettings || isLoading || isFetching || !form.values;
    var isSaveDisabled = isPageLoading || !form.isDirty();
    return {
        isSaveDisabled: isSaveDisabled,
        onBackButtonClicked: onBackButtonClicked,
        isPageLoading: isPageLoading,
        form: form,
        handleImageDrop: handleImageDrop,
        handleFormSubmit: handleFormSubmit
    };
};
