export var AsyncStatus;
(function (AsyncStatus) {
    AsyncStatus["Invalid"] = "Invalid";
    AsyncStatus["Started"] = "Started";
    AsyncStatus["Succeeded"] = "Succeeded";
    AsyncStatus["Failed"] = "Failed";
})(AsyncStatus || (AsyncStatus = {}));
export var AsyncCallType;
(function (AsyncCallType) {
    AsyncCallType["Get"] = "Get";
    AsyncCallType["GetCustom"] = "GetCustom";
    AsyncCallType["Post"] = "Post";
    AsyncCallType["Add"] = "Add";
    AsyncCallType["AddGet"] = "AddGet";
    AsyncCallType["Update"] = "Update";
    AsyncCallType["Delete"] = "Delete";
    AsyncCallType["Clear"] = "Clear";
    AsyncCallType["Search"] = "Search";
    AsyncCallType["List"] = "List";
    AsyncCallType["Refresh"] = "Refresh";
    AsyncCallType["None"] = "None";
})(AsyncCallType || (AsyncCallType = {}));
export var AsyncActionTypes;
(function (AsyncActionTypes) {
    AsyncActionTypes["Async_Unstart"] = "Async_Unstart";
    AsyncActionTypes["Async_Start"] = "Async_Start";
    AsyncActionTypes["Async_Succeed"] = "Async_Succeed";
    AsyncActionTypes["Async_Failed"] = "Async_Failed";
    AsyncActionTypes["Async_Invalid"] = "Async_Invalid";
})(AsyncActionTypes || (AsyncActionTypes = {}));
