var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./settings-sensitivity-labels.scss";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import "react-tagsinput/react-tagsinput.css";
import { SubtitleButton } from "app/components/common/subtitle";
import * as PageHeader from "app/components/common/page-header";
import SensitivityLabelsService from "app/services/sensitivity-labels";
import { isUndefined } from "lodash";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import SettingsSensitivityLabels from "app/components/settings/settings-classifications-and-labels/settings-sensitivity-labels";
import SettingsClassifications from "app/components/settings/settings-classifications-and-labels/settings-classifications";
var SettingsClassificationsAndLabels = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState({}), currentTenantConfigInternalState = _a[0], setCurrentTenantConfigInternalState = _a[1];
    var _b = React.useState(false), anyEdits = _b[0], setAnyEdits = _b[1];
    var _c = React.useState(false), pageLoaded = _c[0], setPageLoaded = _c[1];
    var _d = React.useState(false), formValid = _d[0], setFormValid = _d[1];
    var _e = React.useState(false), hasSubmitted = _e[0], setHasSubmitted = _e[1];
    var _f = React.useState(), isSensitivityLabelsEnabledOnTenant = _f[0], setIsSensitivityLabelsEnabledOnTenant = _f[1];
    React.useEffect(function () {
        // AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(() => {
        // })
        dispatch(TenantConfigurationCurrentStore.Load());
        SensitivityLabelsService.doesTenantHaveLabelsEnabled().then(function (isEnabled) {
            setIsSensitivityLabelsEnabledOnTenant(isEnabled);
        });
    }, []);
    React.useEffect(function () {
        if (currentTenantConfiguration !== isUndefined) {
            var currentTenantConfigCopy = __assign({}, currentTenantConfiguration);
            setCurrentTenantConfigInternalState(currentTenantConfigCopy);
        }
    }, [currentTenantConfiguration]);
    //after the current config object is updated, check to see if the save button should be highlighted.
    React.useEffect(function () {
        //console.log("checked box", currentTenantConfigInternalState.DirectoryImportNonOrchestrySites);
        //checkIfSaveable();
    }, [currentTenantConfigInternalState]);
    // // Checks for edits
    // const checkIfSaveable = () => {
    //    // if (currentTenantConfigInternalState.GUID !== undefined) {
    //    //    if (currentTenantConfigInternalState.GUID !== "") {
    //    //       // Used to detect if the page has been edited at least once.
    //    //       if (!(currentTenantConfiguration.SensitivityLabelConfigJson === null && toggledAtLeastOnce === false)) {
    //    //          if (JSON.stringify(currentTenantConfigInternalState) !== JSON.stringify(currentTenantConfiguration)) {
    //    //             if (anyEdits === false) {
    //                   setAnyEdits(true);
    //    //             }
    //    //          }
    //    //       }
    //    //    }
    //    // }
    // };
    // On save, save to server and set save button back to non-edited.
    var save = function () {
        console.log("Saved!");
        dispatch(TenantConfigurationCurrentStore.Update(__assign({}, currentTenantConfigInternalState)));
        setHasSubmitted(true);
        setAnyEdits(false);
    };
    return (React.createElement("div", { className: "settings-classifications-and-labels fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Classifications and Sensitivity Labels" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(React.Fragment, null,
                React.createElement(NavLink, { to: "/" },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                anyEdits === true && formValid === true ? (React.createElement("div", { onClick: function () { return save(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))),
        isSensitivityLabelsEnabledOnTenant === undefined ? (React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100, showHeader: false },
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 }),
            React.createElement("div", { style: { display: "flex", marginTop: "25px" } },
                React.createElement("div", { style: { width: "50px", marginRight: "20px" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25 })),
                React.createElement("div", { style: { width: "300px" } },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25, bodyWidth: 300 }))))) : isSensitivityLabelsEnabledOnTenant === true ? (React.createElement(SettingsSensitivityLabels, { doesTenantHaveLabelsEnabled: isSensitivityLabelsEnabledOnTenant, currentTenantConfigInternalState: currentTenantConfigInternalState, setCurrentTenantConfigInternalState: setCurrentTenantConfigInternalState, pageLoaded: pageLoaded, setPageLoaded: setPageLoaded, anyEdits: anyEdits, setAnyEdits: setAnyEdits, formValid: formValid, setFormValid: setFormValid, hasSubmitted: hasSubmitted, save: save })) : (React.createElement(SettingsClassifications, { currentTenantConfigInternalState: currentTenantConfigInternalState, setCurrentTenantConfigInternalState: setCurrentTenantConfigInternalState, pageLoaded: pageLoaded, setPageLoaded: setPageLoaded, anyEdits: anyEdits, setAnyEdits: setAnyEdits, formValid: formValid, setFormValid: setFormValid, hasSubmitted: hasSubmitted, save: save }))));
});
export default SettingsClassificationsAndLabels;
