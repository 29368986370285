import FormDropdownSelect from "app/components/common/form-dropdown-select";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import OrchestryService from "app/services/orchestry";
import * as TenantListStore from "app/store/tenants/list";
import axios from "axios";
import * as React from "react";
import Chart from "react-apexcharts";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./orchestry-admin-failure-stats.scss";
var OrchestryAdminFailureStats = React.memo(function () {
    var dispatch = useDispatch();
    var _a = React.useState(null), data = _a[0], setData = _a[1];
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _b = React.useState(null), servers = _b[0], setServers = _b[1];
    var _c = React.useState(document.defaultThrottleServer), selectedServer = _c[0], setSelectedServer = _c[1];
    var _d = React.useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = React.useState("US"), selectedRegion = _e[0], setSelectedRegion = _e[1];
    var _f = React.useState(null), selectedTenant = _f[0], setSelectedTenant = _f[1];
    var _g = React.useState(), activeRegions = _g[0], setActiveRegions = _g[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        getServers();
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    React.useEffect(function () {
        getData();
    }, [selectedRegion]);
    var getData = function () {
        // setData([]);
        setIsLoading(true);
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Jobs/GetFailedJobs?region=" + selectedRegion;
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            formatData(response.data);
            setIsLoading(false);
        })
            .catch(function (response) { });
    };
    function generateReadableHexColor() {
        var brightnessThreshold = 128; // Adjust this value for readability
        var letters = "0123456789ABCDEF";
        var color;
        do {
            color = "#";
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
        } while (calculateBrightness(color) > brightnessThreshold);
        return color;
    }
    function calculateBrightness(hexColor) {
        var r = parseInt(hexColor.substring(1, 3), 16);
        var g = parseInt(hexColor.substring(3, 5), 16);
        var b = parseInt(hexColor.substring(5, 7), 16);
        return (r * 299 + g * 587 + b * 114) / 1000;
    }
    var getMetrics = function (tenantGuids) {
        return tenantGuids;
    };
    var formatData = function (data) {
        var dataArray = [];
        var failedETLs = {
            id: "FailedETLS",
            name: "ETLs",
            color: generateReadableHexColor(),
            data: []
        };
        data.ETLFailed.forEach(function (element) {
            if (element !== undefined) {
                failedETLs.data.push({
                    x: new Date(element.Date).getTime(),
                    y: element.Count,
                    metrics: getMetrics(element.TenantGuids)
                });
            }
        });
        var failedProvisioning = {
            id: "FailedProvisioning",
            name: "Provisioning",
            color: generateReadableHexColor(),
            data: []
        };
        data.PublishingFailed.forEach(function (element) {
            if (element !== undefined) {
                failedProvisioning.data.push({
                    x: new Date(element.Date).getTime(),
                    y: element.Count,
                    metrics: getMetrics(element.TenantGuids)
                });
            }
        });
        var failedArchivals = {
            id: "FailedArchivals",
            name: "Archivals",
            color: generateReadableHexColor(),
            data: []
        };
        data.ArchivalFailed.forEach(function (element) {
            if (element !== undefined) {
                failedArchivals.data.push({
                    x: new Date(element.Date).getTime(),
                    y: element.Count,
                    metrics: getMetrics(element.TenantGuids)
                });
            }
        });
        var failedDataAnalysis = {
            id: "DataAnalysisFailed",
            name: "Data Analysis",
            color: generateReadableHexColor(),
            data: []
        };
        data.DataAnalysisFailed.forEach(function (element) {
            if (element !== undefined) {
                failedDataAnalysis.data.push({
                    x: new Date(element.Date).getTime(),
                    y: element.Count,
                    metrics: getMetrics(element.TenantGuids)
                });
            }
        });
        var failedPolicyEnforcement = {
            id: "PolicyEnforcementFailed",
            name: "Policy Enforcement",
            color: generateReadableHexColor(),
            data: []
        };
        data.PolicyEnforcementFailed.forEach(function (element) {
            if (element !== undefined) {
                failedPolicyEnforcement.data.push({
                    x: new Date(element.Date).getTime(),
                    y: element.Count,
                    metrics: getMetrics(element.TenantGuids)
                });
            }
        });
        dataArray.push(failedETLs);
        dataArray.push(failedProvisioning);
        dataArray.push(failedArchivals);
        dataArray.push(failedDataAnalysis);
        dataArray.push(failedPolicyEnforcement);
        setData(dataArray);
    };
    var getServers = function () {
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/Servers";
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            setServers(response.data);
            if (selectedServer === undefined || selectedServer === "") {
                setSelectedServer(response.data[0]);
                document.defaultThrottleServer = response.data[0];
            }
        })
            .catch(function (response) { });
    };
    var outputMetricTable = function (metrics) {
        var outputHtml = "";
        metrics.forEach(function (m) {
            var tenantName = m.GUID;
            if (tenants !== undefined && tenants.length > 0) {
                var tenant = tenants.find(function (t) { return t.GUID === m.GUID; });
                tenantName = (tenant !== undefined && tenant !== null ? tenant.DisplayName : m.GUID);
            }
            outputHtml += "<tr><td>" + tenantName + "</td><td style='padding-left:10px;'>" + m.Count + "<td></tr>";
        });
        return outputHtml;
    };
    var tooltip = function (_a) {
        var series = _a.series, seriesIndex = _a.seriesIndex, dataPointIndex = _a.dataPointIndex, w = _a.w;
        var returnHtml = "";
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        var series = w.globals.initialSeries[seriesIndex];
        returnHtml +=
            "<div class='custom-tooltip' style='padding:10px;'>" + "<b>" + series.name + "</b>: " + data.y + "<table style='font-size:10px;'>" + outputMetricTable(data.metrics) + "</table></div>";
        // console.log("seriesIndex", seriesIndex, series, dataPointIndex, w);
        // series.forEach((element: any, index: number) => {
        //    var data = w.globals.initialSeries[index].data[dataPointIndex];
        //    var series = w.globals.initialSeries[index];
        //    console.log("series", series);
        //    if (index == seriesIndex) {
        //       // console.log("data", w.globals.initialSeries[index]);
        //       returnHtml +=
        //          "<div class='custom-tooltip' style='padding:10px;'>" + "<b>" + series.name + "</b>: " + data.y + "<table style='font-size:10px;'>" + outputMetricTable(data.metrics) + "</table></div>";
        //       //}
        //    } else {
        //       returnHtml += "<div class='custom-tooltip' style='padding:10px;'><b>" + series.name + "</b>: " + data.y + "</div>";
        //    }
        // });
        return returnHtml;
    };
    return (React.createElement("div", { className: "orchestry-admin-failure-stats fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Job Failure Stats" }),
        React.createElement(PageHeader.SetRightContent, null),
        React.createElement(Portlet, { className: "portlet-filters" },
            React.createElement(PortletBody, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 2 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Region"),
                            activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) {
                                    setSelectedTenant(null);
                                    setSelectedRegion(value);
                                } }))))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12, className: "left-col" },
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null, data !== null ? (React.createElement("div", { className: "", style: { height: "400px" } },
                        React.createElement(Chart, { options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        enabled: true,
                                        easing: "easeinout",
                                        speed: 800,
                                        animateGradually: {
                                            enabled: true,
                                            delay: 150
                                        },
                                        dynamicAnimation: {
                                            enabled: true,
                                            speed: 350
                                        }
                                    }
                                },
                                theme: {
                                    palette: "palette2" // upto palette10
                                },
                                tooltip: {
                                    custom: tooltip
                                },
                                stroke: {
                                    width: 1
                                },
                                xaxis: {
                                    type: "datetime"
                                }
                            }, series: data, type: "line", height: "400" }))) : null))))));
});
export default OrchestryAdminFailureStats;
