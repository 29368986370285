import { LibraryTemplateListActionTypes } from "app/store/library-templates/list/types";
import { AsyncCallType } from "app/store/async/types";
import * as LibraryFeatureListTypes from "app/store/library-features/list";
import * as LibraryChannelListTypes from "app/store/library-channels/list";
export var libraryTemplateListActionMap = [
    {
        actionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/LibraryTemplates",
        responseDataType: {},
        invalidates: [LibraryFeatureListTypes.LibraryFeatureListActionTypes.LibraryFeature_List_Load, LibraryChannelListTypes.LibraryChannelListActionTypes.LibraryChannel_List_Load]
    },
    {
        actionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/LibraryTemplates",
        responseDataType: {},
        invalidates: [LibraryFeatureListTypes.LibraryFeatureListActionTypes.LibraryFeature_List_Load, LibraryChannelListTypes.LibraryChannelListActionTypes.LibraryChannel_List_Load]
    },
    {
        actionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/LibraryTemplates",
        responseDataType: {},
        invalidates: [LibraryFeatureListTypes.LibraryFeatureListActionTypes.LibraryFeature_List_Load, LibraryChannelListTypes.LibraryChannelListActionTypes.LibraryChannel_List_Load]
    },
    {
        actionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Load,
        callType: AsyncCallType.List,
        // stateLocationForArray: "libraryTemplateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/LibraryTemplates",
        responseDataType: []
    },
    {
        actionType: LibraryTemplateListActionTypes.LibraryTemplate_List_Refresh,
        callType: AsyncCallType.Refresh,
        // stateLocationForArray: "libraryTemplateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/LibraryTemplates/RequestAvailable",
        responseDataType: []
    },
    {
        actionType: LibraryTemplateListActionTypes.LibraryTemplate_List_LoadForTemplateCopy,
        callType: AsyncCallType.Refresh,
        keyType: "typeName",
        ApiUrl: "/api/LibraryTemplates/RequestAvailable",
        responseDataType: []
    }
];
