var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LifecyclePolicyListActionTypes } from "./types";
import mergeArrays from "app/utils/mergeArray";
var initialState = {
    items: [],
    policyType: null
};
var compare = function (a, b) {
    if (a.Name !== undefined && b.Name !== undefined && a.Name !== null && b.Name !== null) {
        if (a.Name < b.Name) {
            return -1;
        }
        if (a.Name > b.Name) {
            return 1;
        }
    }
    return 0;
};
export function lifecyclePoliciesListReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load: {
            var tempListToLoad = void 0;
            if (state.items.length === 1)
                tempListToLoad = mergeArrays(action.payload.items, state.items, "GUID");
            else
                tempListToLoad = mergeArrays(state.items, action.payload.items, "GUID");
            return {
                items: [].concat(tempListToLoad.sort(compare)),
                policyType: action.payload.policyType
            };
        }
        case LifecyclePolicyListActionTypes.LifecyclePolicy_List_Refresh: {
            var tempListToLoad = void 0;
            tempListToLoad = action.payload.items;
            // if(state.items.length === 1)
            //     tempListToLoad = mergeArrays(action.payload, state.items, "GUID");
            // else
            //     tempListToLoad = mergeArrays(state.items, action.payload, "GUID");
            return {
                items: [].concat(tempListToLoad.sort(compare)),
                policyType: action.payload.policyType
            };
        }
        case LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Delete: {
            return {
                items: state.items.filter(function (policy) { return policy.GUID !== action.payload.GUID; }),
                policyType: state.policyType
            };
        }
        case LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add: {
            var mergedNewArray = __spreadArray(__spreadArray([], state.items, true), [action.payload], false);
            mergedNewArray = mergedNewArray.sort(compare);
            return {
                items: mergedNewArray,
                policyType: state.policyType
            };
        }
        case LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update: {
            var mergedNewArray = void 0;
            // Not loaded yet.
            if (state.items.length === 0) {
                mergedNewArray = [action.payload];
            }
            // List is in memory
            else {
                mergedNewArray = state.items.map(function (tmplt) { return (tmplt.GUID === action.payload.GUID ? Object.assign({}, tmplt, action.payload) : tmplt); });
                mergedNewArray = mergedNewArray.sort(compare);
            }
            console.log("LifecyclePolicy_List_Item_Update > Merged Array", mergedNewArray);
            return {
                items: mergedNewArray,
                policyType: state.policyType
            };
        }
        default:
            return state;
    }
}
