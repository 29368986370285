import { Card, Container, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { DisconnectTenantButton } from "appv2/components/orchestry-disconnect-tenant-button/orchestry-disconnect-tenant-button.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { OrchestryCardTitle } from "../orchestry-card-title.component";
import { useTenantPage } from "../use-tenant-page.hook";
export var Configuration = function () {
    var _a = useTenantPage(), isPageLoading = _a.isPageLoading, tenantGuid = _a.tenantGuid, onDisconnectTenantClicked = _a.onDisconnectTenantClicked, onAddTenantManagersClicked = _a.onAddTenantManagersClicked, currentTenantGuid = _a.currentTenantGuid, currentTenantName = _a.currentTenantName, TenantName = _a.tenantDetails.TenantName;
    return (React.createElement(Container, { mih: "100%", p: 0 },
        React.createElement(Card, { variant: "default", p: 24 },
            React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Configuration", icon: ORCH_ICONS.settings04, colorVariant: "gray-7" }),
            React.createElement(Stack, { mt: 16, gap: 16 },
                React.createElement(OrchestryText, { c: "text-secondary.0", isLoading: isPageLoading, fz: "14px", fw: 400, lh: "20px" },
                    "Tenant Managers are able to:",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "1. Access specific tenants' details and analytics.",
                    React.createElement("br", null),
                    "2. Receive notifications for new draft Health Checks.",
                    React.createElement("br", null),
                    "3. Annotate and publish the Health Checks.",
                    React.createElement("br", null)),
                React.createElement(OrchestryButton, { isLoading: isPageLoading, disabled: isPageLoading, variant: "primary", onClick: onAddTenantManagersClicked }, "Configure Tenant Managers"))),
        React.createElement(DisconnectTenantButton, { isLoading: isPageLoading, userType: "partner", customerTenantGuid: tenantGuid, customerTenantName: TenantName, partnerHubTenantGuid: currentTenantGuid, partnerHubTenantName: currentTenantName !== null && currentTenantName !== void 0 ? currentTenantName : "", onDisconnectTenantClicked: onDisconnectTenantClicked, w: "100%", mt: 16 })));
};
