var WebSockets = /** @class */ (function () {
    function WebSockets() {
    }
    WebSockets.ws = null;
    WebSockets.createConnection = function () {
        //WebSockets.ws = new WebSocket("wss://" + window.location.hostname + "/ws");
        //var websocketHost = window.location.hostname.indexOf("orchestry.com") > -1 ? window.location.hostname : "localhost:44316";
        WebSockets.ws = new WebSocket("wss://" + window.location.hostname + "/api-ws/WebsocketDispatch");
        WebSockets.ws.binaryType = "blob";
        WebSockets.ws.onopen = function () {
            alert("About to send data");
            WebSockets.ws.send("Hello World"); // I WANT TO SEND THIS MESSAGE TO THE SERVER!!!!!!!!
            alert("Message sent!");
        };
        WebSockets.ws.onmessage = function (evt) {
            alert("About to receive data");
            var received_msg = evt.data;
            alert("Message received = " + received_msg);
        };
        WebSockets.ws.onclose = function () {
            // websocket is closed.
            alert("Connection is closed...");
        };
    };
    WebSockets.sendMessage = function (param1, param2) {
        //var txtBox = document.getElementById('messageTxt');
        //var txtBox2 = document.getElementById('messageTxt2');
        var arr = [param1, param2];
        //const array = new Float32Array(5);
        //for (var i = 0; i < array.length; ++i) {
        //    array[i] = i / 2;
        //}
        //console.log(array);
        WebSockets.ws.send(arr);
    };
    return WebSockets;
}());
export default WebSockets;
