import * as AsyncStore from "app/store/async";
import * as LibraryChannelListStore from "app/store/library-channels/list";
export var libraryChannelNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to librarychannels list...",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Title}</strong> in librarychannels list...",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Title}</strong> from librarychannels list...",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to librarychannels list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Add
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully updated in librarychannels list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Update
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully deleted from librarychannels list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to librarychannels list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Title}</strong> to librarychannels list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Title}</strong> from librarychannels list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Item_Delete
            },
            {
                Text: "Error when trying to load librarychannel list.",
                ActionType: LibraryChannelListStore.LibraryChannelListActionTypes.LibraryChannel_List_Load
            }
        ]
    }
];
