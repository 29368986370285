import axios from "axios";
var LibraryDocumentTemplateService = /** @class */ (function () {
    function LibraryDocumentTemplateService() {
    }
    LibraryDocumentTemplateService.uploadDocument = function (data, onUploadProgress) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LibraryDocumentTemplates/UploadDocumentTemplate";
            axiosConfig.responseType = "blob";
            axiosConfig.headers = {
                "Content-Type": "multipart/form-data"
            };
            axiosConfig.data = data;
            if (onUploadProgress !== undefined) {
                axiosConfig.onUploadProgress = onUploadProgress;
            }
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Document Template Upload" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LibraryDocumentTemplateService.getTemplateFromAzure = function (documentTemplateGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LibraryDocumentTemplates/GetDocumentTemplate?itemGuid=" + documentTemplateGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return LibraryDocumentTemplateService;
}());
export default LibraryDocumentTemplateService;
