import * as React from "react";
import "./request-my.scss";
import { useSelector, useDispatch } from "react-redux";
import * as RequestListStore from "app/store/requests/list";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import { RenderWithLinkIfCan } from "app/components/requests/request-details";
import moment from "moment";
import RequestStatus from "app/components/requests/request-status";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { FormattedMessage } from "react-intl";
var RequestMy = React.memo(function (props) {
    var _a, _b, _c, _d;
    var dispatch = useDispatch();
    var requests = useSelector(function (state) { return state.requestListState.userItems; });
    var requestLoading = useSelector(function (state) { return state.requestListState.loadingUserItems; });
    var inTeams = React.useState(document.getElementsByTagName("html")[0].classList.contains("in-teams"))[0];
    var isInStaicTeamsApp = React.useState(document.getElementsByTagName("html")[0].classList.contains("static-teams-app"))[0];
    React.useEffect(function () {
        //if(props.loadInitialData === undefined || (props.loadInitialData !== undefined && props.loadInitialData === true)){
        if (inTeams === false || (inTeams === true && isInStaicTeamsApp === false)) {
            dispatch(RequestListStore.Load_User_Page({
                items: [],
                view: "my-requests",
                paging: { CurrentPage: 1, ResultsPerPage: 50 }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var renderType = function (request) {
        if (request.RequestType === "SharePoint") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "Team") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "VivaEngageCommunity") {
            return (React.createElement("div", { className: "icon-viva-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconViva, alt: "Viva Engage Community", className: "fadein-500ms" }))));
        }
        return React.createElement(React.Fragment, null);
    };
    var getItems = function (status) {
        if (status === "pending") {
            return requests === null || requests === void 0 ? void 0 : requests.filter(function (r) {
                if (["Created", "ApprovalRequired", "Approved", "ReadyToProvision", "Provisioning"].some(function (sp) { return sp === r.Status; })) {
                    return true;
                }
                return false;
            });
        }
        else {
            return requests === null || requests === void 0 ? void 0 : requests.filter(function (r) {
                if (["Provisioned", "Failed", "Archived", "Rejected"].some(function (sp) { return sp === r.Status; })) {
                    return true;
                }
                return false;
            });
        }
    };
    var RenderItem = function (_a) {
        var r = _a.r, showLinks = _a.showLinks;
        var isPending = false;
        if (["Created", "ApprovalRequired", "Approved", "ReadyToProvision", "Provisioning"].some(function (sp) { return sp === r.Status; })) {
            isPending = true;
        }
        return (React.createElement("div", { className: "request-item" },
            React.createElement("div", { className: "icon" }, isPending === true || r.Status === "Rejected" || r.Status === "Failed" ? (React.createElement(OfficeProfilePicture, { base64image: r.Image, displayName: r.Name, type: "SharePoint", size: "sm" })) : (React.createElement(RenderWithLinkIfCan, { currentRequest: r, openInTeamsIfPossible: true },
                React.createElement(OfficeProfilePicture, { base64image: r.Image, displayName: r.Name, type: "SharePoint", size: "sm" })))),
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "content" }, isPending === true || r.Status === "Rejected" || r.Status === "Failed" ? (React.createElement(React.Fragment, null, r.Name)) : (React.createElement(RenderWithLinkIfCan, { currentRequest: r, openInTeamsIfPossible: true }, r.Name)))),
            React.createElement("div", { className: "template" },
                React.createElement("span", { className: "t-icon" }, renderType(r)),
                React.createElement("span", { className: "t-text" }, r.RequestType)),
            React.createElement("div", { className: "request-date" },
                React.createElement("span", null, moment
                    .utc(r.RequestedDate)
                    .local()
                    .format("Do MMM h:mm a"))),
            React.createElement("div", { className: "status" },
                React.createElement(RequestStatus, { request: r, style: "background" })),
            React.createElement("div", { className: "link" }, isPending === true || r.Status === "Rejected" || r.Status === "Failed" ? (React.createElement("span", { className: "request-status" },
                React.createElement(RequestStatus, { request: r }))) : (
            // <><AnimatedLoader size="xxsmall" />
            React.createElement(RenderWithLinkIfCan, { currentRequest: r, openInTeamsIfPossible: true },
                React.createElement("div", { className: "open" },
                    React.createElement(FormattedMessage, { id: "page.request_queue.open_workspace", defaultMessage: "Open Workspace" })))))));
    };
    return (React.createElement("div", { className: "request-my" },
        React.createElement("div", { className: "request-container" },
            React.createElement("div", { className: "request-title" },
                React.createElement("div", { className: "icon-title col-header" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.column_label.in_progress", defaultMessage: "In Progress" })),
                React.createElement("div", { className: "template col-header" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.column_label.template", defaultMessage: "Template" })),
                React.createElement("div", { className: "request-date col-header" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.column_label.request_date", defaultMessage: "Request date" })),
                React.createElement("div", { className: "status col-header" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.column_label.status", defaultMessage: "Status" })),
                React.createElement("div", { className: "link col-header" })),
            requestLoading !== undefined && requestLoading === true ? (React.createElement("div", { className: "loading-skeleton" },
                React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                    React.createElement("div", { className: "pic" },
                        React.createElement("div", { className: "box animated-wave" })),
                    React.createElement("div", { className: "title" },
                        React.createElement("div", { className: "title-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, (_a = getItems("pending")) === null || _a === void 0 ? void 0 :
                _a.map(function (r) { return (React.createElement(RenderItem, { r: r, showLinks: false })); }),
                ((_b = getItems("pending")) === null || _b === void 0 ? void 0 : _b.length) === 0 ? (React.createElement("div", { className: "no-results" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.no_results_in_progress", defaultMessage: "You do not have any requests that are in progress." }))) : null))),
        React.createElement("div", { className: "request-divider" }),
        React.createElement("div", { className: "request-container" },
            React.createElement("div", { className: "request-title" },
                React.createElement("div", { className: "icon-title col-header" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.previous_requests", defaultMessage: "Previous Requests" }))),
            requestLoading !== undefined && requestLoading === true ? (React.createElement("div", { className: "loading-skeleton" },
                React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                    React.createElement("div", { className: "pic" },
                        React.createElement("div", { className: "box animated-wave" })),
                    React.createElement("div", { className: "title" },
                        React.createElement("div", { className: "title-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, (_c = getItems("previous")) === null || _c === void 0 ? void 0 :
                _c.map(function (r) { return (React.createElement(RenderItem, { r: r, showLinks: true })); }),
                ((_d = getItems("previous")) === null || _d === void 0 ? void 0 : _d.length) === 0 ? (React.createElement("div", { className: "no-results" },
                    React.createElement(FormattedMessage, { id: "page.my_requests.no_results_previous_requests", defaultMessage: "You do not have any previous requests." }))) : null)))));
});
export default RequestMy;
