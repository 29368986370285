import { LifecyclePolicyWorkspaceCurrentActionTypes } from "app/store/lifecycle-policy-workspaces/current/types";
import { AsyncCallType } from "app/store/async/types";
export var lifecyclePolicyWorkspaceCurrentActionMap = [
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/?siteId={SiteId}",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_DirectoryItem,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/DirectoryItem?siteId={SiteId}",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Status,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/Status?siteId={SiteId}",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Stats,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/Statistics?siteId={SiteId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_History,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/History?siteId={SiteId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Logs,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/ArchivalLogs?siteId={SiteId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Members,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/Members?siteId={SiteId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Members_ByGroupId,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/Members?groupId={GroupId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_AvailablePolicies,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/AvailalblePolicies?siteId={SiteId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_DefaultPolicies,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/DefaultPolicies"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Links,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/Links?siteId={SiteId}"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_NotebookAndPlanner,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/Links?siteId={SiteId}&includeNoteAndPlan=true"
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_ReportItem,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/ReportItem?siteId={SiteId}",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_ArchivalPolicy,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/ArchivalPolicy?siteId={SiteId}",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicyWorkspaces",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_Properties,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicyWorkspaces",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_WorkflowSubmit,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/WorkflowUpdate",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_GuestWorkflowSubmit_Action,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/GuestWorkflowUpdate",
        responseDataType: {}
    },
    {
        actionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update_NextETLCrawl,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/LifecyclePolicyWorkspaces/UpdateNextETLCrawl",
        responseDataType: {}
    }
];
