import { Card, Group } from "@mantine/core";
import React from "react";
import { OrchestryAvatar } from "../orchestry-avatar/orchestry-avatar.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestryUserIdentity = function (_a) {
    var displayName = _a.displayName, userPhoto = _a.userPhoto;
    return (React.createElement(Card, { p: 6, radius: 8, withBorder: true, bg: "var(--mantine-color-white)", style: { border: "1px solid var(--border)" } },
        React.createElement(Group, { gap: 12 },
            React.createElement(OrchestryAvatar, { src: userPhoto, variant: "20px", userDisplayName: displayName }),
            React.createElement(OrchestryText, { c: "var(--mantine-color-text-tertiary-0)", size: "12px", fw: 400, lh: "18px" }, displayName))));
};
