import Store from "app/store";
/***
 * Handles the message events as they come in from the parent window.
 * Used for things like sharepoint webparts that have our react app embedded in them.
 *
 * @param event The message event that was received.
 */
export var handleMessageEvent = function (event) {
    if (event.data !== null && typeof event.data === "object") {
        switch (event.data.messageType) {
            case "workspaceInformation":
                Store.dispatch({
                    type: "Set_Workspace_Information_Web_Part_Props",
                    payload: event.data.webPartProps
                });
                break;
            case "directory":
                Store.dispatch({
                    type: "Set_Directory_Web_Part_Props",
                    payload: event.data.webPartProps
                });
                break;
            case "sharepointToken":
                // Handled in index.html to not have app "flash"
                // TODO: see if we can move it here.
                break;
            default:
                // console.log("Unknown message type received", event.data.messageType);
                // console.log("full event", event);
                break;
        }
    }
};
