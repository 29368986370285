import * as React from "react";
import "./form-office-asset-picker.scss";
import TeamsService from "app/services/teams";
import SharePointService from "app/services/sharepoint";
import { Modal } from "react-bootstrap";
import DynamicScrollbar from "../dynamic-scollbar";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { useSelector } from "react-redux";
import TeamLink from "../team-link";
import PortletAlert from "../portlet-alert";
var formOfficeAssetPickerSearchText = "";
var FormOfficeAssetPicker = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var _c = React.useState(), selectedItem = _c[0], setSelectedItem = _c[1];
    var _d = React.useState(), didAllReturnNone = _d[0], setDidAllReturnNone = _d[1];
    var pickerConfig = React.useState([
        {
            pickerType: "Teams",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for Teams")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change Team")),
            selectedText: "Selected Team to use as template",
            getAllMethod: SharePointService.GetAllSiteCollectionsTeam,
            getBySearchTerm: SharePointService.SearchForSiteCollectionByNameTeam,
            selectedItemIcon: iconTeams
        },
        {
            pickerType: "SharePoint",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for SharePoint Site")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change SharePoint Site")),
            selectedText: "Selected SharePoint to use as template",
            getAllMethod: SharePointService.GetAllSiteCollections,
            getBySearchTerm: SharePointService.SearchForSiteCollectionByName,
            selectedItemIcon: iconSP
        },
        {
            pickerType: "CommSharePoint",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for SharePoint Communication Site")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change SharePoint Communication Site")),
            selectedText: "Selected SharePoint to use as template",
            getAllMethod: SharePointService.GetAllSiteCollectionsComm,
            getBySearchTerm: SharePointService.SearchForSiteCollectionByNameComm,
            selectedItemIcon: iconSP
        },
        {
            pickerType: "TeamSiteSharePoint",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for SharePoint Team Site")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change SharePoint Team Site")),
            selectedText: "Selected SharePoint to use as template",
            getAllMethod: SharePointService.GetAllSiteCollectionsTeamSite,
            getBySearchTerm: SharePointService.SearchForSiteCollectionByNameTeamSite,
            selectedItemIcon: iconSP
        },
        {
            pickerType: "Planner",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for Planner live template")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change Planner live template")),
            selectedText: "Selected Planner to use as template",
            getAllMethod: TeamsService.GetAllTeams,
            getBySearchTerm: TeamsService.SearchForTeamByName,
            selectedItemIcon: iconSP
        },
        {
            pickerType: "AllSharePoint",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for a SharePoint Site")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change SharePoint Site")),
            selectedText: "Selected SharePoint site to use as template",
            getAllMethod: SharePointService.GetAllSiteCollectionsAll,
            getBySearchTerm: SharePointService.SearchForSiteCollectionByNameAll,
            selectedItemIcon: iconSP
        },
        {
            pickerType: "SharePointHubs",
            buttonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-paper-plane" }),
                " Browse for a Hub Site")),
            changeButtonText: (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-redo" }),
                " Change Hub Site")),
            selectedText: "Selected Hub site",
            getAllMethod: SharePointService.GetHubSiteCollections,
            getBySearchTerm: SharePointService.GetHubSiteCollectionsByName,
            selectedItemIcon: iconSP
        }
    ])[0];
    var _e = React.useState(), currentPickerConfig = _e[0], setCurrentPickerConfig = _e[1];
    var _f = React.useState(), currentResults = _f[0], setCurrentResults = _f[1];
    var _g = React.useState(""), searchText = _g[0], setSearchText = _g[1];
    React.useEffect(function () {
        if (props.pickerType !== undefined) {
            setCurrentPickerConfig(pickerConfig.find(function (pc) { return pc.pickerType === props.pickerType; }));
        }
        formOfficeAssetPickerSearchText = "";
    }, [props.pickerType]);
    React.useEffect(function () {
        if (currentPickerConfig !== undefined && showModal && (props.skipIntialListAll === undefined || (props.skipIntialListAll !== undefined && props.skipIntialListAll === false))) {
            loadAllData();
        }
        if (showModal === false) {
            setSearchText("");
            formOfficeAssetPickerSearchText = "";
        }
    }, [showModal, currentPickerConfig]);
    var loadAllData = function (searchTerm) {
        setLoading(true);
        if (currentPickerConfig !== undefined) {
            currentPickerConfig.getAllMethod().then(function (data) {
                if (searchText === "" || (searchTerm !== undefined && searchTerm === "")) {
                    var tempData = data;
                    if (currentPickerConfig.pickerType === "SharePoint" && props.sharePointTemplateType !== undefined) {
                        var SPTemplateType_1 = "";
                        if (props.sharePointTemplateType === "ModernGroup") {
                            SPTemplateType_1 = "GROUP#0";
                        }
                        if (props.sharePointTemplateType === "ModernCommunications") {
                            SPTemplateType_1 = "SITEPAGEPUBLISHING#0";
                        }
                        tempData = data.filter(function (o) { return o.Template === SPTemplateType_1; });
                    }
                    else {
                        tempData = data;
                    }
                    setLoading(false);
                    setCurrentResults(tempData);
                    setDidAllReturnNone(tempData.length === 0);
                    //console.log("all teams data", data);
                }
            });
        }
    };
    React.useEffect(function () {
        if (props.value !== undefined) {
            if (props.value !== null && props.value !== "") {
                if (selectedItem === undefined || (selectedItem !== undefined && props.value !== JSON.stringify(selectedItem))) {
                    var convertedValue = JSON.parse(props.value);
                    setSelectedItem(convertedValue);
                }
            }
            else {
                setSelectedItem(undefined);
            }
        }
    }, [props.value]);
    var toggleModal = function () {
        setShowModal(!showModal);
        //is now closed
        if (showModal === true) {
            setCurrentResults(undefined);
            setLoading(true);
            //console.log("closed");
        }
    };
    var searchInputChange = function (searchText) {
        setLoading(true);
        setSearchText(searchText);
        formOfficeAssetPickerSearchText = searchText;
        setTimeout(function () {
            if (searchText === formOfficeAssetPickerSearchText) {
                search(searchText);
            }
        }, 750);
    };
    var search = function (searchTerm) {
        if (currentPickerConfig !== undefined) {
            if (searchTerm !== "") {
                if (currentPickerConfig !== undefined) {
                    setLoading(true);
                    currentPickerConfig.getBySearchTerm(searchTerm).then(function (data) {
                        var tempData = data;
                        if (currentPickerConfig.pickerType === "SharePoint" && props.sharePointTemplateType !== undefined) {
                            var SPTemplateType_2 = "";
                            if (props.sharePointTemplateType === "ModernGroup") {
                                SPTemplateType_2 = "GROUP#0";
                            }
                            if (props.sharePointTemplateType === "ModernCommunications") {
                                SPTemplateType_2 = "SITEPAGEPUBLISHING#0";
                            }
                            tempData = data.filter(function (o) { return o.Template === SPTemplateType_2; });
                        }
                        else {
                            tempData = data;
                        }
                        setCurrentResults(tempData);
                        setLoading(false);
                    });
                }
            }
            else {
                //setLoading(true);
                loadAllData(searchTerm);
            }
        }
    };
    var getRelativeUrl = function (url) {
        var returnUrl = "";
        if (orchestryContext.TenantSharePointUrl !== undefined) {
            returnUrl = url.replace(orchestryContext.TenantSharePointUrl, "");
        }
        return returnUrl;
    };
    if (currentPickerConfig !== undefined) {
        return (React.createElement("div", { className: "form-office-asset-picker" },
            selectedItem !== undefined ? (React.createElement("div", { className: "selected-value" },
                props.showSelectedText !== undefined && props.showSelectedText === false ? "" : React.createElement("label", null, currentPickerConfig.selectedText),
                React.createElement("div", { className: "selected-cols" },
                    React.createElement("div", { className: "selected-item" },
                        React.createElement("div", { className: "selected-icon-type" },
                            React.createElement("img", { src: currentPickerConfig.selectedItemIcon, alt: currentPickerConfig.pickerType })),
                        props.pickerType !== undefined &&
                            (props.pickerType === "SharePoint" || props.pickerType === "AllSharePoint" || props.pickerType === "TeamSiteSharePoint" || props.pickerType === "CommSharePoint") ? (React.createElement("a", { href: selectedItem.URL, target: "_blank" },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement(OfficeProfilePicture, { displayName: selectedItem.DisplayName, size: "xs" }),
                                React.createElement("span", { className: "selected-title" }, selectedItem.DisplayName),
                                React.createElement("div", { className: "url" }, getRelativeUrl(selectedItem.URL))))) : (React.createElement(TeamLink, { teamGuid: selectedItem.GUID },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement(OfficeProfilePicture, { displayName: selectedItem.DisplayName, size: "xs" }),
                                React.createElement("span", { className: "selected-title" }, selectedItem.DisplayName))))),
                    props.onChange !== undefined ? (React.createElement("div", { className: "actions" },
                        React.createElement("div", { className: "edit btn btn-sm btn-clean btn-icon btn-icon-sm", "data-pw": "edit-button", onClick: function () { return toggleModal(); } },
                            React.createElement("i", { className: "fa fa-pen" })),
                        React.createElement("div", { className: "delete btn btn-sm btn-clean btn-icon btn-icon-sm", "data-pw": "delete-button", onClick: function () {
                                if (props.onChange !== undefined) {
                                    props.onChange("");
                                }
                            } },
                            React.createElement("i", { className: "fa fa-trash-can-xmark" })))) : null))) : (React.createElement("div", { className: "browser-button button-custom b-green", "data-pw": "browser-button", onClick: function () { return toggleModal(); } }, props.browseText !== undefined ? (React.createElement("span", null,
                React.createElement("i", { className: "fa fa-plus" }),
                " ",
                props.browseText,
                " ")) : (currentPickerConfig.buttonText))),
            React.createElement(Modal, { size: "sm", centered: true, show: showModal, onHide: function () { return toggleModal(); }, animation: true, className: "office-asset-modal", backdropClassName: "office-asset-modal-backdrop" },
                React.createElement("div", { className: "picker-content", "data-pw": "picker-content" },
                    React.createElement("div", { className: "picker-title" },
                        "Find Existing",
                        props.pickerType === "SharePoint" ? " SharePoint Site" : "",
                        props.pickerType === "Teams" ? " Team" : "",
                        props.pickerType === "SharePointHubs" ? " Hub Site" : "",
                        React.createElement("div", { className: "close-button", onClick: function () { return toggleModal(); } },
                            React.createElement("i", { className: "fa fa-times" }))),
                    props.pickerType === "SharePointHubs" ? (React.createElement("div", { style: { margin: "10px 25px -10px 25px" } },
                        React.createElement(PortletAlert, { message: "Only Hub Sites will be displayed", size: "small", icon: "info", type: "warning" }))) : null,
                    React.createElement("div", { className: "picker-search" },
                        React.createElement("input", { type: "text", onChange: function (event) {
                                searchInputChange(event.target.value);
                            }, placeholder: props.pickerType === "SharePointHubs" ? "Search for a Hub Site" : "" }),
                        React.createElement("div", { className: "icon-search" },
                            React.createElement("i", { className: "fa fa-search" }))),
                    React.createElement("div", { className: "picker-results", "data-pw": "picker-results" },
                        loading === true ? (React.createElement("div", { className: "loading" },
                            React.createElement(AnimatedLoader, { size: "small" }))) : null,
                        currentResults !== undefined && Array.isArray(currentResults) && currentResults.length > 0 && loading === false ? (React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 300 }, currentResults.map(function (result, rIndex) { return (React.createElement("div", { className: "picker-item" + (selectedItem !== undefined && result.GUID === selectedItem.GUID ? " selected" : ""), "data-pw": "picker-item", key: rIndex },
                            React.createElement(OfficeProfilePicture, { displayName: result.DisplayName, onClick: function () {
                                    if (props.onChange !== undefined) {
                                        props.onChange(JSON.stringify(result));
                                    }
                                    toggleModal();
                                } }),
                            React.createElement("span", { className: "asset-title", onClick: function () {
                                    if (props.onChange !== undefined) {
                                        props.onChange(JSON.stringify(result));
                                    }
                                    toggleModal();
                                } }, result.DisplayName),
                            props.pickerType !== undefined && props.pickerType === "SharePoint" ? (React.createElement("div", { className: "sharepoint-link" },
                                React.createElement("a", { href: result.URL, target: "_blank" }, getRelativeUrl(result.URL)))) : null,
                            result.Visibility !== undefined && result.Visibility === "Private" ? (React.createElement("span", { className: "locked" },
                                React.createElement("i", { className: "fa fa-lock" }))) : null,
                            React.createElement("div", { className: "checkbox" },
                                React.createElement("i", { className: "fa fa-check" })))); }))) : null,
                        (currentResults === undefined || (Array.isArray(currentResults) && currentResults.length === 0)) && loading === false ? (React.createElement("div", { className: "no-results" }, props.pickerType === "SharePointHubs" ? (React.createElement(React.Fragment, null, didAllReturnNone !== undefined && didAllReturnNone === true ? "You have no SharePoint sites registered as a Hub in your organization" : "No hub sites found")) : props.pickerType === "SharePoint" ? ("No sites found") : props.pickerType === "Teams" ? ("No teams found") : ("No Results"))) : null)))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
export default FormOfficeAssetPicker;
