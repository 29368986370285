var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ServiceAppPermissionConfiguration } from "app/store/tenants";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { generatePriorityMetricConfig, generateTopCategoryConfig, getFilterFromValue } from "./recommendations-page.utils";
import { useRecommendationsApi } from "./use-recommendations-api";
import { useRecommendationsFilters } from "./use-recommendations-filters.hook";
export var useRecommendationsPage = function () {
    var intl = useIntl();
    var _a = useState(RecommendationRecordVisibility.Visible), tab = _a[0], setTab = _a[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var history = useHistory();
    var _b = useRecommendationsFilters(), appliedFilters = _b.appliedFilters, visibilityFilter = _b.visibilityFilter, priorityMetricConfig = _b.priorityMetricConfig, topCategoryConfig = _b.topCategoryConfig, applySpotlightFilter = _b.applySpotlightFilter, applyFilterButtonFilters = _b.applyFilterButtonFilters, clearAllFilters = _b.clearAllFilters, removeFilter = _b.removeFilter, setPriorityMetricConfig = _b.setPriorityMetricConfig, setTopCategoryConfig = _b.setTopCategoryConfig, setVisibilityFilter = _b.setVisibilityFilter, appliedFiltersDependency = _b.appliedFiltersDependency;
    var _c = useRecommendationsApi(appliedFilters, visibilityFilter, appliedFiltersDependency), pagedRecommendations = _c.pagedRecommendations, recommendationCategories = _c.recommendationCategories, recommendationProducts = _c.recommendationProducts, recommendationRecordVisibilityCounts = _c.recommendationRecordVisibilityCounts, recommendationStats = _c.recommendationStats, togglePrioritySort = _c.togglePrioritySort, activePage = _c.activePage, setActivePage = _c.setActivePage, resultsPerPage = _c.resultsPerPage, setResultsPerPage = _c.setResultsPerPage, querySearchText = _c.querySearchText, highlightSearchWords = _c.highlightSearchWords, searchTextInputValue = _c.searchTextInputValue, onSearchBoxChange = _c.onSearchBoxChange, onSearchBoxKeyDown = _c.onSearchBoxKeyDown, onSearchBoxXClicked = _c.onSearchBoxXClicked, recommendationStatsSuccessful = _c.recommendationStatsSuccessful, pagedRecommendationsIsFetching = _c.pagedRecommendationsIsFetching, recommendationRecordVisibilityCountsIsLoading = _c.recommendationRecordVisibilityCountsIsLoading, recommendationStatsIsFetching = _c.recommendationStatsIsFetching, isPageLoading = _c.isPageLoading, pagedRecommendationsRefetch = _c.pagedRecommendationsRefetch, recommendationRecordVisibilityCountsRefetch = _c.recommendationRecordVisibilityCountsRefetch, recommendationStatsRefetch = _c.recommendationStatsRefetch, isError = _c.isError, pageOptions = _c.pageOptions;
    useEffect(function () {
        if (recommendationStats) {
            setPriorityMetricConfig(generatePriorityMetricConfig(recommendationStats, applySpotlightFilter));
            setTopCategoryConfig(generateTopCategoryConfig(recommendationStats, applySpotlightFilter));
        }
    }, [recommendationStatsSuccessful, recommendationStatsIsFetching, applySpotlightFilter, recommendationStats]);
    var updateVisibilityFilter = function (value) {
        clearAllFilters();
        setActivePage(1);
        setVisibilityFilter({ type: "selectable", value: value, group: "Visibility", selected: true });
    };
    var tabs = useMemo(function () {
        return [
            {
                text: "Open",
                value: RecommendationRecordVisibility.Visible,
                count: recommendationRecordVisibilityCounts === null || recommendationRecordVisibilityCounts === void 0 ? void 0 : recommendationRecordVisibilityCounts.Visible,
                onClick: function (value) { return updateVisibilityFilter(value); },
                tooltipText: "View a list of all Recommendations that are currently Open"
            },
            {
                text: "Resolved",
                value: RecommendationRecordVisibility.Resolved,
                count: recommendationRecordVisibilityCounts === null || recommendationRecordVisibilityCounts === void 0 ? void 0 : recommendationRecordVisibilityCounts.Resolved,
                onClick: function (value) { return updateVisibilityFilter(value); },
                tooltipText: "View a list of all Recommendations that are currently Resolved"
            },
            {
                text: "Snoozed",
                value: RecommendationRecordVisibility.Snoozed,
                count: recommendationRecordVisibilityCounts === null || recommendationRecordVisibilityCounts === void 0 ? void 0 : recommendationRecordVisibilityCounts.Snoozed,
                onClick: function (value) { return updateVisibilityFilter(value); },
                tooltipText: "View a list of all Recommendations that are currently Snoozed"
            }
        ];
    }, [recommendationRecordVisibilityCounts, updateVisibilityFilter]);
    var badgeOnClick = function (value) { return applySpotlightFilter({ filter: getFilterFromValue(value), source: "CardChip" }); };
    var navigateToResolutionsPage = function () { return history.push("/recommendations/resolutions"); };
    var onSettingsClicked = function () { return history.push("/settings/recommendations"); };
    var recommendationsEnabled = (orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadWriteAll &&
        orchestryContext.ServiceAppLastConsentedVersion &&
        orchestryContext.ServiceAppLastConsentedVersion >= 10027 &&
        orchestryContext.OrchestryAppPermissionVersion >= 10060) ||
        (orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadAll &&
            orchestryContext.ServiceAppReadAllLastConsentedVersion &&
            orchestryContext.ServiceAppReadAllLastConsentedVersion >= 10000 &&
            orchestryContext.OrchestryAppPermissionVersion >= 10060);
    var onRecommendationSuccessfullyAdded = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clearAllFilters();
                    setActivePage(1);
                    setVisibilityFilter({ type: "selectable", value: RecommendationRecordVisibility.Visible, group: "Visibility", selected: true });
                    return [4 /*yield*/, pagedRecommendationsRefetch()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, recommendationRecordVisibilityCountsRefetch()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, recommendationStatsRefetch()];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onAddClicked = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            openOrchestryModal("addCustomRecommendationModal", { intl: intl, onRecommendationSuccessfullyAdded: onRecommendationSuccessfullyAdded }, "xl");
            return [2 /*return*/];
        });
    }); };
    var onRecommendationSuccessfullyUpdated = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, recommendationRecordVisibilityCountsRefetch()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, recommendationStatsRefetch()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        intl: intl,
        pagedRecommendations: pagedRecommendations,
        recommendationCategories: recommendationCategories,
        recommendationProducts: recommendationProducts,
        recommendationRecordVisibilityCounts: recommendationRecordVisibilityCounts,
        priorityMetricConfig: priorityMetricConfig,
        topCategoryConfig: topCategoryConfig,
        removeFilter: removeFilter,
        appliedFilters: appliedFilters,
        recommendationStatsSuccessful: recommendationStatsSuccessful,
        updateVisibilityFilter: updateVisibilityFilter,
        visibilityFilter: visibilityFilter,
        clearAllFilters: clearAllFilters,
        applySpotlightFilter: applySpotlightFilter,
        applyFilterButtonFilters: applyFilterButtonFilters,
        isError: isError,
        pagedRecommendationsIsFetching: pagedRecommendationsIsFetching,
        recommendationRecordVisibilityCountsIsLoading: recommendationRecordVisibilityCountsIsLoading,
        shouldShowShareSuggestion: (visibilityFilter === null || visibilityFilter === void 0 ? void 0 : visibilityFilter.value) === RecommendationRecordVisibility.Visible,
        shouldShowViewHistory: (visibilityFilter === null || visibilityFilter === void 0 ? void 0 : visibilityFilter.value) === RecommendationRecordVisibility.Resolved,
        navigateToResolutionsPage: navigateToResolutionsPage,
        isTabsDeactivated: (visibilityFilter === null || visibilityFilter === void 0 ? void 0 : visibilityFilter.value) === RecommendationRecordVisibility.NA,
        recommendationStats: recommendationStats,
        shouldShowRecommendationsStats: !appliedFilters.some(function (filter) { return filter.selected; }) &&
            visibilityFilter.value === RecommendationRecordVisibility.Visible &&
            Boolean((pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items) && (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items.length) > 0),
        searchTextInputValue: searchTextInputValue,
        querySearchText: querySearchText,
        highlightSearchWords: highlightSearchWords,
        setActivePage: setActivePage,
        activePage: activePage,
        setResultsPerPage: setResultsPerPage,
        resultsPerPage: resultsPerPage,
        badgeOnClick: badgeOnClick,
        tab: tab,
        setTab: setTab,
        tabs: tabs,
        togglePrioritySort: togglePrioritySort,
        recommendationsEnabled: recommendationsEnabled,
        pageOptions: pageOptions,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        isPageLoading: isPageLoading,
        onSettingsClicked: onSettingsClicked,
        onAddClicked: onAddClicked,
        onRecommendationSuccessfullyUpdated: onRecommendationSuccessfullyUpdated
    };
};
