import { Grid } from "@mantine/core";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import React from "react";
import { App } from "./app.component";
import { Configuration } from "./configuration.component";
import { GeneralDetails } from "./general-details.component";
import { Hosting } from "./hosting.component";
import { SignUpFormDetails } from "./signup-form-details.component";
import { TenantInsights } from "./tenant-insights.component";
var DEFAULT_GRID_MIN_WIDTH = 515;
var DEFAULT_GRID_MIN_WIDTH_MOBILE = 320;
export var TenantDetails = function () {
    var isMobile = useIsMobile();
    var minWidth = isMobile ? DEFAULT_GRID_MIN_WIDTH_MOBILE : DEFAULT_GRID_MIN_WIDTH;
    return (React.createElement(Grid, { grow: true, columns: 2, gutter: "24px" },
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(GeneralDetails, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(TenantInsights, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(App, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(Hosting, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(SignUpFormDetails, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(Configuration, null))));
};
