import * as AsyncStore from "app/store/async";
import * as TenantCurrentStore from "app/store/tenants/current";
export var tenantCurrentNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Updating <strong>tenant</strong>",
                ActionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Update
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>Tenant </strong> successfully updated.",
                ActionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Update
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when updating <strong>tenant</strong>.",
                ActionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Update
            },
            {
                Text: "Error when trying to load tenant.",
                ActionType: TenantCurrentStore.TenantCurrentActionTypes.Tenant_Current_Load
            }
        ]
    }
];
