var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import OrchModal from "app/components/common/orch-modal";
import { SubtitleButton } from "app/components/common/subtitle";
import { Form } from "react-bootstrap";
import { AppType, PermissionType } from "app/services/permission-info";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { useOrchestryPermissionInfoEditorEditModal } from "./use-orchestry-permission-info-editor-edit-modal.hook";
import { PermissionInfoAction } from "../permission-info-action.type";
import "./orchestry-permission-info-editor-edit-modal.scss";
export var OrchestryPermissionEditorEditModal = function (_a) {
    var _b, _c, _d, _e, _f;
    var isEditPermissionInfoModalOpen = _a.isEditPermissionInfoModalOpen, setIsEditPermissionInfoModalOpen = _a.setIsEditPermissionInfoModalOpen, selectedPermissionInfoItem = _a.selectedPermissionInfoItem, permissionInfoAction = _a.permissionInfoAction, setSelectedPermissionInfoItem = _a.setSelectedPermissionInfoItem, permissionInfoItems = _a.permissionInfoItems, setPermissionInfoItems = _a.setPermissionInfoItems, setSelectedAppType = _a.setSelectedAppType;
    var _g = useOrchestryPermissionInfoEditorEditModal({
        setIsEditPermissionInfoModalOpen: setIsEditPermissionInfoModalOpen,
        selectedPermissionInfoItem: selectedPermissionInfoItem,
        permissionInfoItems: permissionInfoItems,
        setPermissionInfoItems: setPermissionInfoItems,
        permissionInfoAction: permissionInfoAction,
        setSelectedAppType: setSelectedAppType
    }), onSubmitClicked = _g.onSubmitClicked, onDeleteClicked = _g.onDeleteClicked, submitButtonTitle = _g.submitButtonTitle;
    return (React.createElement(OrchModal, { modalClassName: "orchestry-permission-info-editor-edit-modal", showModalOverride: isEditPermissionInfoModalOpen, showModalToggleOverride: setIsEditPermissionInfoModalOpen },
        React.createElement("a", { className: "guide", href: "https://orchestry.atlassian.net/wiki/spaces/ENGINE/pages/79429633/Permission+Info+Guide", target: "_blank", rel: "noopener noreferrer" }, "Link to Guide"),
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Name"),
            React.createElement(Form.Control, { placeholder: "Name (eg: Group.ReadWrite.All, User.Read, etc)", value: (_b = selectedPermissionInfoItem.Name) !== null && _b !== void 0 ? _b : "", onChange: function (event) {
                    setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { Name: event.target.value }));
                } })),
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "App Type"),
            React.createElement(FormDropdownSelect, { placeholder: "Select an app type", value: selectedPermissionInfoItem.AppType, list: [
                    {
                        id: "orchestry",
                        value: AppType.Orchestry,
                        title: "Orchestry"
                    },
                    {
                        id: "serviceapp",
                        value: AppType.ServiceApp,
                        title: "ServiceApp"
                    }
                ], onChange: function (value) { return setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { AppType: value })); } })),
        selectedPermissionInfoItem.AppType === AppType.ServiceApp && (React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Service App Permission Id"),
            React.createElement(Form.Control, { placeholder: "Please enter a Service App Permission Id if App Type is ServiceApp. Formatted like: 92ec8e0d-ba70-42bc-a79a-b8653dd18eab", value: (_c = selectedPermissionInfoItem.ServiceAppPermissionID) !== null && _c !== void 0 ? _c : "", onChange: function (event) {
                    setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { ServiceAppPermissionID: event.target.value }));
                } }))),
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Permission Type"),
            React.createElement(FormDropdownSelect, { placeholder: "Select a permission type", value: selectedPermissionInfoItem.PermissionType, list: [
                    {
                        id: "delegated",
                        value: PermissionType.Delegated,
                        title: "Delegated"
                    },
                    {
                        id: "application",
                        value: PermissionType.Application,
                        title: "Application"
                    }
                ], onChange: function (value) { return setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { PermissionType: value })); } })),
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Description"),
            React.createElement(Form.Control, { placeholder: "Please enter the description from azure (eg: Read and write all groups)", value: (_d = selectedPermissionInfoItem.Description) !== null && _d !== void 0 ? _d : "", onChange: function (event) {
                    setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { Description: event.target.value }));
                } })),
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Grouping"),
            React.createElement(Form.Control, { placeholder: "Please enter the group you want the permission to be in", value: (_e = selectedPermissionInfoItem.Grouping) !== null && _e !== void 0 ? _e : "", onChange: function (event) {
                    setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { Grouping: event.target.value }));
                } })),
        React.createElement(Form.Group, { className: "form-group" },
            React.createElement(Form.Label, null, "Orchestry Details"),
            React.createElement(Form.Control, { placeholder: "Please enter the details as to why/what we are using the permission for", value: (_f = selectedPermissionInfoItem.OrchestryDetails) !== null && _f !== void 0 ? _f : "", onChange: function (event) {
                    setSelectedPermissionInfoItem(__assign(__assign({}, selectedPermissionInfoItem), { OrchestryDetails: event.target.value }));
                } })),
        React.createElement("div", { className: "d-flex justify-content-between" },
            React.createElement(SubtitleButton, { title: submitButtonTitle, onClick: function () { return onSubmitClicked(); }, noMargin: true }),
            permissionInfoAction === PermissionInfoAction.Update && React.createElement(SubtitleButton, { title: "Delete", color: "orange", onClick: function () { return onDeleteClicked(); }, noMargin: true }))));
};
