import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import React from "react";
import { useHistory } from "react-router";
import { RecommendationResolutions } from "./recommendation-resolutions/recommendation-resolutions.component";
export var ResolutionsPage = function () {
    var history = useHistory();
    var onBackButtonClicked = function () { return history.push("/recommendations"); };
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(Subtitle, { title: "Recommendations", subtitle: "Resolutions" },
                        React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, "Go Back to Recommendations")),
                    React.createElement(RecommendationResolutions, null))))));
};
