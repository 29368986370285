var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./feature-list.scss";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as FeatureListStore from "app/store/features/list";
import * as TableList from "app/components/common/table-list";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
var FeatureList = React.memo(function () {
    var dispatch = useDispatch();
    var features = useSelector(function (state) { return state.featureListState.items; });
    console.log("Features", features);
    React.useEffect(function () {
        dispatch(FeatureListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onAssociatedAdd = function (feat, templateToAdd) {
        var tempArray = [templateToAdd];
        if (feat.AssociatedTemplates !== undefined) {
            if (feat.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), feat.AssociatedTemplates, true);
            }
        }
        dispatch(FeatureListStore.UpdateItem(Object.assign(feat, { AssociatedTemplates: tempArray })));
    };
    var onAssociatedRemove = function (feat, tbaToRemove) {
        var _a;
        dispatch(FeatureListStore.UpdateItem(Object.assign(feat, { AssociatedTemplates: (_a = feat.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; }) })));
    };
    return (React.createElement("div", { className: "features-lists" },
        React.createElement(PageHeader.SetLeftContent, null,
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a feature" })),
        React.createElement(Subtitle, { subtitle: "List" },
            React.createElement(NavLink, { exact: true, to: "/workspaces/features/new" },
                React.createElement(SubtitleButton, { title: "New Feature", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: features, asyncListLoadUID: FeatureListStore.FeatureListActionTypes.Feature_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Feature Name"),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Icon"),
                React.createElement(TableList.HeaderItem, { type: "centered", helpText: "" }, "Associated"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/workspaces/features/edit/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItemLineAwesomeIcon, { type: "centered", listDataProperyName: "Icon" }),
                React.createElement(TableList.ContentItemAssociatedTemplates, { onAssociatedAdd: function (item, tba) {
                        onAssociatedAdd(item, tba);
                    }, onAssociatedRemove: function (item, tba) {
                        onAssociatedRemove(item, tba);
                    } }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/features/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/features/edit/{GUID}"
                    onRemove: function (item) { return dispatch(FeatureListStore.DeleteItem(item)); }, deleteCheckType: "feature" })))));
});
export default FeatureList;
