import { Group } from "@mantine/core";
import { OrchestryLogo } from "appv2/components/orchestry-logo/orchestry-logo.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useIntl } from "react-intl";
export var HealthCheckFooter = function (_a) {
    var isLoading = _a.isLoading;
    var intl = useIntl();
    return (React.createElement("div", { id: "health-check-footer" },
        React.createElement(Group, { gap: 16, mt: 24, pt: 24, pb: 24, justify: "center", w: "100%" },
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", size: "16px", lh: "24px", fw: 400 }, intl.formatMessage({
                id: "brought_to_you_by",
                defaultMessage: "Brought to you by"
            })),
            React.createElement(OrchestryLogo, { isLoading: isLoading }))));
};
