import axios from "axios";
var LockCheckService = /** @class */ (function () {
    function LockCheckService() {
    }
    LockCheckService.LockAPIUrls = [
        {
            Type: "template",
            Url: "/api/Templates/IsLocked?guid=",
            DetailsUrl: "/workspaces/templates/edit/"
        },
        {
            Type: "request",
            DetailsUrl: "/requests/view/"
        }
    ];
    LockCheckService.GetCheckResponse = function (itemGuid, itemType) {
        return new Promise(function (resolve, reject) {
            var deleteCheckUrl = LockCheckService.LockAPIUrls.find(function (dau) { return dau.Type === itemType; });
            if (deleteCheckUrl !== undefined) {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = deleteCheckUrl.Url + itemGuid;
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    //console.log("Install Start Response" , response);
                    var lockCheck = response.data;
                    if (lockCheck.Locked === true && lockCheck.Items !== undefined) {
                        lockCheck.Items.forEach(function (item) {
                            var editUrl = LockCheckService.LockAPIUrls.find(function (dau) { return dau.Type === "request"; });
                            if (editUrl !== null) {
                                item.DetailsUrl = (editUrl === null || editUrl === void 0 ? void 0 : editUrl.DetailsUrl) + item.GUID;
                            }
                        });
                    }
                    resolve(lockCheck);
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
            else {
                reject("Lock Check Problem - Service cannot find type");
            }
        });
    };
    return LockCheckService;
}());
export default LockCheckService;
