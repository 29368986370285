var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimatedLoader from "app/components/common/animated-loader";
import Async from "app/components/common/async";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
import NoResults from "app/components/common/no-results";
import NoResultsAlternate from "app/components/common/no-results-alternate";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalItemDynamic from "app/components/common/principal-item-dynamic";
import PrincipalsList from "app/components/common/principals-list";
import ReadMore from "app/components/common/read-more";
import LifecycleStrings from "app/components/insights/common/lifecycle-strings";
import LifecycleViewMembers from "app/components/insights/common/report/report-view-members";
import LifecycleWorkspaceBulkActionModal from "app/components/insights/workspaces/report/workspace-bulk-action-modal";
import LifecycleWorkspaceBulkGuestActionModal from "app/components/insights/workspaces/report/workspace-bulk-guest-action-modal";
import GuestRequestWorkflowColumnAction from "app/components/lifecycle/guests/workflow/request/guest-request-workflow-column-actions";
import GuestReviewWorkflowColumnAction from "app/components/lifecycle/guests/workflow/review/guest-review-workflow-column-actions";
import * as AsyncTypes from "app/store/async";
import { AccessViolationMap, ReportDatasource } from "app/store/lifecycle-report";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { ReportSearchActionTypes } from "app/store/lifecycle-report/search/types";
import convertStringToSafePropertyName from "app/utils/convertStringToSafePropertyName";
import formatDataSize from "app/utils/formatDataSize";
import generateGuid from "app/utils/generateGuid";
import moment from "moment";
import * as React from "react";
import { useCustomEventListener } from "react-custom-events";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import ColumnDynamicDate from "./components/column-dynamic-date";
import ColumnText from "./components/column-text";
import ReportColumnDateAggregate from "./components/report-column-date-aggregate";
import ReportColumnDomainName from "./components/report-column-domain-name";
import ReportColumnGuestActions from "./components/report-column-guest-actions";
import ReportColumnGuestDaysInactive from "./components/report-column-guest-days-inactive";
import ReportColumnGuestDeletionRecommendationReason from "./components/report-column-guest-deletetion-recommendation-reason";
import ReportColumnGuestMembershipStatus from "./components/report-column-guest-membership-status";
import ReportColumnGuestMembershipStatusNewExisting from "./components/report-column-guest-membership-status-new-existing";
import ReportColumnGuestName from "./components/report-column-guest-name";
import ReportColumnGuestStatus from "./components/report-column-guest-status";
import ReportColumnGuestViolations from "./components/report-column-guest-violations";
import ReportColumnInsights from "./components/report-column-insights";
import ReportColumnNumber from "./components/report-column-number";
import ReportColumnNumberAggregate from "./components/report-column-number-aggregate";
import ReportColumnNumberWithLink from "./components/report-column-number-with-link";
import ReportColumnPercentage from "./components/report-column-percentage";
import ReportColumnPolicies from "./components/report-column-policies";
import ReportColumnSharingCapabilities from "./components/report-column-sharing-capabilities";
import ReportColumnTemplateType from "./components/report-column-template-type";
import ReportColumnWorkspaceDaysInactive from "./components/report-column-workspace-days-inactive";
import ReportColumnWorkspaceName from "./components/report-column-workspace-name";
import SingleUserById from "./components/single-user-by-id";
import "./report-list-table.scss";
var ReportListTable = React.memo(function (props) {
    var dispatch = useDispatch();
    var listUniqueId = React.useState(generateGuid())[0];
    var lifecycleReport = useSelector(function (state) { return state.lifecycleReportState[props.Slot]; });
    var lifecycleReportContext = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Context; });
    var lifecycleReportIsLoading = useSelector(function (state) { return state.lifecycleReportState[props.Slot].IsLoading; });
    var viewName = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Context.View; });
    var _a = React.useState(1000), scollAreaHeight = _a[0], setScrollAreaHeight = _a[1];
    var _b = React.useState(0), fixedScrollLeft = _b[0], setFixedScrollLeft = _b[1];
    var _c = React.useState(false), fixedMode = _c[0], setFixedMode = _c[1];
    var _d = React.useState([]), bulkEditSelected = _d[0], setBulkEditSelected = _d[1];
    var intl = useIntl();
    React.useEffect(function () {
        // initializeIcons();
    }, []);
    /**
     * Renders all the external links in a group for the first field that has external links
     * @param fieldName  The name of the field
     * @param values The values of the field
     */
    var renderExternalLinks = function (item) {
        var itemNames = lifecycleReport.Columns.filter(function (md) { return md.Type === "custom-link"; }).map(function (md) { return md.ProperyName; });
        var links = item.Metadata.filter(function (md) { return itemNames.some(function (itemName) { return itemName === md.Name; }); });
        return (React.createElement("div", { className: "links-group" }, links.map(function (link, index) {
            return (link.Values && (React.createElement("div", { className: "link" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLink }),
                React.createElement("a", { key: index, href: link.Values.Url, target: "_blank", rel: "noreferrer" }, link === null || link === void 0 ? void 0 : link.Name))));
        })));
    };
    /**
     * Returns true if the metadata link should be rendered for the given field name.
     * Renders the links only for the first field that has links because we ground the links under one field.
     *
     * @param fieldName The name of the field
     */
    var shouldRenderMetadataLink = function (fieldName) {
        var _a;
        var itemNames = lifecycleReport.Columns.filter(function (md) { return md.Type === "custom-link"; }).map(function (md) { return md.ProperyName; });
        var links = lifecycleReport.Items.flatMap(function (item) { return item.Metadata.filter(function (md) { return itemNames.some(function (itemName) { return itemName === md.Name; }); }); });
        if (!links || !(links.length > 0)) {
            return false;
        }
        /**
         * If the field name is not the same as the first link's field name, then we don't render the links
         * because we don't want to render the links for every field, only for the first one.
         */
        if (fieldName !== ((_a = links[0]) === null || _a === void 0 ? void 0 : _a.Name)) {
            return false;
        }
        return true;
    };
    var getMetadataValue = function (item, fieldName, renderType) {
        var re = React.createElement(React.Fragment, null);
        if (item.Metadata !== null) {
            var foundMetadata = item.Metadata.find(function (md) { return md.Name === fieldName; });
            if (foundMetadata !== undefined && foundMetadata !== null && foundMetadata.Values !== null) {
                if (renderType === "custom-choice-single") {
                    re = React.createElement(React.Fragment, null, foundMetadata.Values);
                }
                else if (renderType === "custom-choice-multiple") {
                    var values = foundMetadata.Values;
                    re = (React.createElement(React.Fragment, null, values.map(function (v, index) { return (React.createElement("div", { key: "cust-choice-mult" + index }, v)); })));
                }
                else if (renderType === "custom-date") {
                    re = React.createElement(React.Fragment, null, moment(foundMetadata.Values !== undefined ? foundMetadata.Values.replace(/\"/g, "") : foundMetadata.Values).format("MMM Do YYYY"));
                }
                else if (renderType === "custom-link") {
                    re = renderExternalLinks(item);
                }
                else if (renderType === "custom-text-single") {
                    re = React.createElement(React.Fragment, null, foundMetadata.Values);
                }
                else if (renderType === "custom-text-multiple") {
                    re = React.createElement(React.Fragment, null, foundMetadata.Values);
                }
                else if (renderType === "custom-managed-metadata-single") {
                    if (foundMetadata.Values !== undefined && foundMetadata.Values !== null && foundMetadata.Values) {
                        if (foundMetadata.Values.TermGuid !== undefined && foundMetadata.Values.TermGuid !== null) {
                            re = (React.createElement(ManagedMetadataValue, { node: {
                                    NodeType: "Term",
                                    Id: foundMetadata.Values.TermGuid
                                } }));
                        }
                    }
                }
                else if (renderType === "custom-managed-metadata-multiple") {
                    if (foundMetadata.Values !== undefined && foundMetadata.Values !== null && Array.isArray(foundMetadata.Values)) {
                        if (foundMetadata.Values.length > 0) {
                            re = (React.createElement("ul", { className: "managed-metadata-list" }, foundMetadata.Values.map(function (to, index) { return (React.createElement("li", { key: "found-meta-data-" + index },
                                React.createElement("div", { className: "managed-metadata-item", "data-term-guid": to.TermGuid },
                                    React.createElement("i", { className: "la la-tag" }),
                                    React.createElement("span", null, to.Label)))); })));
                        }
                    }
                }
            }
        }
        // console.log("getMetadataValue", re, item, fieldName, renderType);
        return re;
    };
    var updateScrollAreaHeight = function (height) {
        //const newHeight = height + 20;
        var newHeight = height;
        if (scollAreaHeight !== newHeight) {
            setScrollAreaHeight(newHeight);
        }
    };
    var sort = function (sortColumn) {
        var sortDirection = "";
        if (sortColumn !== lifecycleReport.Context.Sort) {
            sortDirection = "DESC";
        }
        else if (sortColumn === lifecycleReport.Context.Sort) {
            if (lifecycleReport.Context.SortDirection === "DESC") {
                sortDirection = "ASC";
            }
            else {
                sortDirection = "DESC";
            }
        }
        dispatch(LifecycleReportStore.UpdateContext({
            Sort: sortColumn,
            //@ts-ignore
            SortDirection: sortDirection
        }, props.Slot));
    };
    React.useEffect(function () {
        setTimeout(function () {
            var table = document.querySelector(".lifecycle-report-list-unique-id-" + listUniqueId + " table");
            if (table !== undefined && (table === null || table === void 0 ? void 0 : table.clientHeight) !== undefined) {
                updateScrollAreaHeight(table.clientHeight);
            }
        }, 100);
    }, [lifecycleReport.Items]);
    useCustomEventListener("update-insights-height", function () {
        setTimeout(function () {
            var table = document.querySelector(".lifecycle-report-list-unique-id-" + listUniqueId + " table");
            if (table !== undefined && (table === null || table === void 0 ? void 0 : table.clientHeight) !== undefined) {
                updateScrollAreaHeight(table.clientHeight);
            }
        }, 100);
    });
    var getDayDifference = function (day) {
        var a = moment(day);
        var b = moment();
        return b.diff(a, "days");
    };
    var getReportDateOpacity = function (day) {
        //console.log("day", day);
        var returnOpacity = 0;
        var diff = getDayDifference(day);
        //console.log("diff", diff);
        var minusFrom100 = diff * 3;
        //console.log("minusFrom100", minusFrom100);
        if (minusFrom100 < 100) {
            returnOpacity = (100 - minusFrom100) / 100;
        }
        //console.log("returnOpacity", returnOpacity);
        return returnOpacity;
    };
    var beGetCheckboxHeaderState = function () {
        var state = "unchecked";
        if (lifecycleReport.Items.length > 0 && bulkEditSelected.length === lifecycleReport.Items.length) {
            state = "checked";
        }
        else if (bulkEditSelected.length > 0) {
            state = "half-checked";
        }
        return state;
    };
    var beToggleCheckboxHeader = function () {
        if (beGetCheckboxHeaderState() === "half-checked" || beGetCheckboxHeaderState() === "checked") {
            setBulkEditSelected([]);
        }
        if (beGetCheckboxHeaderState() === "unchecked") {
            if (props.Datasource === ReportDatasource.CosmosDB) {
                setBulkEditSelected(lifecycleReport.Items.map(function (ri) { return ri.SiteId; }));
            }
            if (props.Datasource === ReportDatasource.GuestUser) {
                if (viewName === "guests-with-unique-domains")
                    setBulkEditSelected(lifecycleReport.Items.map(function (ri) { return ri.DomainId; }));
                else
                    setBulkEditSelected(lifecycleReport.Items.map(function (ri) { return ri.UserId; }));
            }
            if (props.Datasource === ReportDatasource.SQLServer && viewName === "guest-workspace-memberships") {
                if (lifecycleReport.Items.length > 0) {
                    setBulkEditSelected(lifecycleReport.Items.map(function (ri) { return (ri.GroupId !== null ? ri.GroupId : ""); }));
                }
            }
            if (props.Datasource === ReportDatasource.SQLServer && viewName !== "guest-workspace-memberships") {
                if (lifecycleReport.Items.length > 0) {
                    if (lifecycleReport.Items[0].UserId !== undefined) {
                        setBulkEditSelected(lifecycleReport.Items.map(function (ri) { return ri.UserId; }));
                    }
                }
            }
        }
    };
    var beGetCheckboxItemState = function (reportItem) {
        var state = "unchecked";
        var selectedIndex = "";
        if ((props.Datasource === ReportDatasource.SQLServer && viewName !== "guest-workspace-memberships") || props.Datasource === ReportDatasource.GuestUser) {
            if (viewName === "guests-with-unique-domains")
                selectedIndex = reportItem.DomainId;
            else
                selectedIndex = reportItem.UserId;
        }
        if (props.Datasource === ReportDatasource.SQLServer && reportItem.GroupId !== null && viewName === "guest-workspace-memberships") {
            selectedIndex = reportItem.GroupId;
        }
        if (selectedIndex === "") {
            if (reportItem.SiteId)
                selectedIndex = reportItem.SiteId;
            else
                selectedIndex = reportItem.id;
        }
        if (bulkEditSelected.some(function (bei) { return bei === selectedIndex; })) {
            state = "checked";
        }
        return state;
    };
    var beToggleCheckboxItem = function (reportItem) {
        var selectedIndex = "";
        if ((props.Datasource === ReportDatasource.SQLServer && viewName !== "guest-workspace-memberships") || props.Datasource === ReportDatasource.GuestUser) {
            if (viewName === "guests-with-unique-domains")
                selectedIndex = reportItem.DomainId;
            else
                selectedIndex = reportItem.UserId;
        }
        if (props.Datasource === ReportDatasource.SQLServer && viewName === "guest-workspace-memberships" && reportItem.GroupId !== null) {
            selectedIndex = reportItem.GroupId;
        }
        if (selectedIndex === "") {
            if (reportItem.SiteId && selectedIndex)
                selectedIndex = reportItem.SiteId;
            else
                selectedIndex = reportItem.id;
        }
        if (bulkEditSelected.some(function (bei) { return bei === selectedIndex; })) {
            setBulkEditSelected(bulkEditSelected.filter(function (bi) { return (bi === selectedIndex ? false : true); }));
        }
        else {
            setBulkEditSelected(__spreadArray(__spreadArray([], bulkEditSelected, true), [selectedIndex], false));
        }
    };
    React.useEffect(function () {
        setBulkEditSelected(bulkEditSelected.filter(function (bi) { return lifecycleReport.Items.some(function (i) { return i.SiteId === bi; }); }));
    }, [lifecycleReport.Items]);
    var GetSelectedWorkspaceUsers = function () {
        var refiner = lifecycleReport.Refiners.find(function (r) { return r.FieldName === "WorkspaceUsers"; });
        if (refiner !== undefined) {
            if (refiner.CurrentValue !== null && refiner.CurrentValue.length > 0) {
                var users = JSON.parse(refiner.CurrentValue);
                return users;
            }
        }
        return [];
    };
    var GetMatchingUsers = function (userIds, excludeUserIds1, excludeUserIds2) {
        if (excludeUserIds1 === void 0) { excludeUserIds1 = null; }
        if (excludeUserIds2 === void 0) { excludeUserIds2 = null; }
        var returnUsers = [];
        var refinerUsers = GetSelectedWorkspaceUsers();
        returnUsers = refinerUsers.filter(function (u) { return userIds.some(function (id) { return id === u.Id; }); });
        if (excludeUserIds1 !== undefined && excludeUserIds1 !== null && Array.isArray(excludeUserIds1)) {
            returnUsers = returnUsers.filter(function (u) { return !excludeUserIds1.some(function (id) { return id === u.Id; }); });
        }
        if (excludeUserIds2 !== undefined && excludeUserIds2 !== null && Array.isArray(excludeUserIds2)) {
            returnUsers = returnUsers.filter(function (u) { return !excludeUserIds2.some(function (id) { return id === u.Id; }); });
        }
        return returnUsers;
    };
    var isBulkEditEnabled = function () {
        if (props.BulkEditEnabled !== undefined) {
            return props.BulkEditEnabled;
        }
        else {
            return true;
        }
    };
    return (React.createElement("div", { className: "lifecycle-report-list-table " + ("lifecycle-report-list-unique-id-" + listUniqueId) + (fixedMode === true ? " fixed-first-column" : "") },
        React.createElement(Async, { uid: [ReportSearchActionTypes.Report_Load, ReportSearchActionTypes.Report_Load_WithContext, ReportSearchActionTypes.Report_Update_View], status: AsyncTypes.AsyncStatus.Started },
            React.createElement("div", { className: "loading-skeleton" },
                React.createElement("div", { className: "header" }),
                React.createElement("div", { className: "items" }, [1, 1, 1, 1, 1, 1, 1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: "header-items" + index },
                    React.createElement("div", { className: "pic" },
                        React.createElement("div", { className: "circle animated-wave" })),
                    React.createElement("div", { className: "title" },
                        React.createElement("div", { className: "title-box animated-wave" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box" })),
                    React.createElement("div", { className: "property" },
                        React.createElement("div", { className: "property-box" })))); })))),
        React.createElement(Async, { uid: [ReportSearchActionTypes.Report_Load, ReportSearchActionTypes.Report_Load_WithContext], status: AsyncTypes.AsyncStatus.Succeeded },
            props.ShowBusyAnimation ? (React.createElement(AnimatedLoader, { text: intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.processing", defaultMessage: "Processing" }), type: "squares", theme: "dark", size: "xxsmall" })) : null,
            React.createElement(Scrollbar, { noScrollY: true, style: { minHeight: scollAreaHeight + "px" }, onScroll: function (values) { return setFixedScrollLeft(values.scrollLeft); } },
                React.createElement("table", { onLoad: function (table) {
                        updateScrollAreaHeight(table.currentTarget.clientHeight);
                    }, className: lifecycleReportIsLoading === true ? " loading-from-server" : "" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            isBulkEditEnabled() && (React.createElement("td", { className: "column-bulk-edit" },
                                React.createElement("div", { className: "bulk-edit-header" },
                                    React.createElement("div", { className: "bulk-edit-checkbox " + beGetCheckboxHeaderState(), onClick: function () { return beToggleCheckboxHeader(); } },
                                        React.createElement("i", { className: "fa fa-check" }))),
                                React.createElement("div", { className: "bulk-edit-menu" + (bulkEditSelected.length === 0 ? " hide-menu" : "") },
                                    React.createElement("div", { className: "selected-count" },
                                        bulkEditSelected.length,
                                        " ",
                                        intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.selected", defaultMessage: "selected" })),
                                    React.createElement("div", { className: "buttons" }, props.Datasource === ReportDatasource.CosmosDB && viewName !== "guest-workspace-memberships" && viewName !== "workspaces-archival-failed" ? (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "group" },
                                            React.createElement("div", { className: "label" },
                                                React.createElement("i", { className: "fa-solid fa-box-archive" }),
                                                React.createElement("p", null, "Lifecycle Actions")),
                                            React.createElement(LifecycleWorkspaceBulkActionModal, { Type: "AssignPolicy", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                                React.createElement("div", { className: "button" },
                                                    React.createElement("i", { className: "fa-solid fa-paperclip" }),
                                                    "Assign Policy")),
                                            React.createElement(LifecycleWorkspaceBulkActionModal, { Type: "ExecutePolicy", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                                React.createElement("div", { className: "button" },
                                                    React.createElement("i", { className: "fa-regular fa-bolt" }),
                                                    "Force Policy"),
                                                " "),
                                            React.createElement(LifecycleWorkspaceBulkActionModal, { Type: "Reset", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                                React.createElement("div", { className: "button" },
                                                    React.createElement("i", { className: "fa-regular fa-arrow-rotate-left" }),
                                                    "Reset Status"),
                                                " "),
                                            React.createElement(LifecycleWorkspaceBulkActionModal, { Type: "Remediate", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                                React.createElement("div", { className: "button" },
                                                    React.createElement("i", { className: "fa-regular fa-wrench" }),
                                                    "Remediate"),
                                                " ")),
                                        React.createElement("div", { className: "group" },
                                            React.createElement("div", { className: "label" },
                                                React.createElement("i", { className: "fa-solid fa-user-lock" }),
                                                React.createElement("p", null, "Guest Actions")),
                                            React.createElement(LifecycleWorkspaceBulkGuestActionModal, { Type: "AssignPolicy", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                                React.createElement("div", { className: "button" },
                                                    React.createElement("i", { className: "fa-solid fa-paperclip" }),
                                                    "Assign Policy")),
                                            React.createElement(LifecycleWorkspaceBulkGuestActionModal, { Type: "ExecutePolicy", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                                React.createElement("div", { className: "button" },
                                                    React.createElement("i", { className: "fa-regular fa-bolt" }),
                                                    "Force Policy"),
                                                " ")))) : props.CustomBulkActions !== undefined && viewName !== "guests-with-unique-domains" && viewName !== "recently-deleted-guests" ? (props.CustomBulkActions.map(function (bulkMethod, index) { return React.createElement("span", { key: index }, bulkMethod(lifecycleReport, bulkEditSelected)); })) : viewName === "guests-with-unique-domains" || viewName === "recently-deleted-guests" ? (React.createElement("span", null, "No actions available")) : viewName === "workspaces-archival-failed" ? (React.createElement("div", { className: "group" },
                                        " ",
                                        React.createElement("div", { className: "label" },
                                            React.createElement("i", { className: "fa-solid fa-box-archive" }),
                                            React.createElement("p", null, "Lifecycle Actions")),
                                        React.createElement(LifecycleWorkspaceBulkActionModal, { Type: "Reset", PolicyGuids: bulkEditSelected, Slot: props.Slot },
                                            React.createElement("div", { className: "button" },
                                                React.createElement("i", { className: "fa-regular fa-arrow-rotate-left" }),
                                                "Reset Status"),
                                            " "))) : null)))),
                            React.createElement(React.Fragment, null, lifecycleReport.Columns.map(function (col, colIndex) {
                                return col.ProperyName !== "Privacy" && !col.ExportOnly && (col.Type !== "custom-link" || shouldRenderMetadataLink(col.ProperyName)) ? (React.createElement("td", { style: col.Type === "workspace-name" ? { left: fixedScrollLeft + "px" } : {}, className: "column-" + col.Type + " " + (col.SortEnabled === false ? " sort-disabled" : ""), key: "privacy-column-" + colIndex, "data-pw": "column-name" },
                                    React.createElement("div", { onClick: function () {
                                            if (col.SortEnabled === true && col.Type !== "custom-link") {
                                                sort(col.ProperyName);
                                            }
                                        } },
                                        col.Type !== "custom-link" && (React.createElement("span", null, col.TranslationId !== null && col.TranslationId !== "" ? intl.formatMessage({ id: col.TranslationId, defaultMessage: col.Title }) : col.Title)),
                                        col.Type === "custom-link" ? React.createElement("span", null, intl.formatMessage({ id: "workspaces.metadata.external_links", defaultMessage: "External Links" })) : null,
                                        col.SortEnabled === true && col.Type !== "custom-link" ? (React.createElement("div", { className: "sort" + (col.ProperyName === lifecycleReport.Context.Sort ? " current-sort" : ""), "data-pw": "sort" },
                                            React.createElement("div", { className: "asc" + (lifecycleReport.Context.SortDirection === "ASC" ? " current-direction" : ""), "data-pw": "column-asc" },
                                                React.createElement("i", { className: "fas fa-caret-up" })),
                                            React.createElement("div", { className: "desc" + (lifecycleReport.Context.SortDirection === "DESC" ? " current-direction" : ""), "data-pw": "column-desc" },
                                                React.createElement("i", { className: "fas fa-caret-down" })))) : null))) : null;
                            })))),
                    React.createElement("tbody", null,
                        lifecycleReport.Items.map(function (item, idIndex) {
                            var _a;
                            return (React.createElement("tr", { key: "report-items" + idIndex, className: item.ExtendedProperties !== null &&
                                    item.ExtendedProperties !== undefined &&
                                    item.ExtendedProperties.CosmosWorkspaceDetails !== null &&
                                    ((_a = item.ExtendedProperties.CosmosWorkspaceDetails) === null || _a === void 0 ? void 0 : _a.IsArchived) === true
                                    ? " archived-tr"
                                    : "" },
                                isBulkEditEnabled() && (React.createElement("td", { className: "column-bulk-edit" },
                                    React.createElement("div", { className: "bulk-edit-wrapper" },
                                        React.createElement("div", { className: "bulk-edit-checkbox " + beGetCheckboxItemState(item), onClick: function () { return beToggleCheckboxItem(item); } },
                                            React.createElement("i", { className: "fa fa-check" }))))),
                                lifecycleReport.Columns.map(function (col, colIndex) {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                                    return !col.ExportOnly && (col.Type !== "custom-link" || shouldRenderMetadataLink(col.ProperyName)) ? (React.createElement("td", { style: col.Type === "workspace-name" ? { left: fixedScrollLeft + "px" } : {}, key: "dir-item-" + idIndex + "-col-" + colIndex, className: "fadeinskel-1s column-type-" +
                                            convertStringToSafePropertyName(col.Type) +
                                            " col-".concat(col.ProperyName.toString()
                                                .replace(" ", "")
                                                .toLowerCase()) },
                                        React.createElement("div", null,
                                            col.Type === "report-insights" ? React.createElement(ReportColumnInsights, null) : null,
                                            col.Type === "workspace-name" ? React.createElement(ReportColumnWorkspaceName, { Item: item, goToLink: props.goToLink, inactiveDays: lifecycleReportContext.InactiveDays }) : null,
                                            col.Type === "guest-name" ? (React.createElement(ReportColumnGuestName, { Item: item, goToLink: props.goToLink, reportColumn: col, daysInactive: lifecycleReportContext.InactiveDays !== undefined ? lifecycleReportContext.InactiveDays : 90 })) : null,
                                            col.Type === "report-guest-days-inactive" ? React.createElement(ReportColumnGuestDaysInactive, { Col: col, Item: item }) : null,
                                            col.Type === "percentage" ? React.createElement(ReportColumnPercentage, { col: col, item: item }) : null,
                                            col.Type === "domain-name" ? React.createElement(ReportColumnDomainName, { Item: item, Col: col }) : null,
                                            col.Type === "guest-request-approver" ? (((_b = (_a = item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.GuestMembershipDetails) === null || _b === void 0 ? void 0 : _b.LastRequestApprovalByUserId) ? (React.createElement(PrincipalItemDynamic, { size: "xs", showBorder: false, principalIdOrEmail: (_c = item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.GuestMembershipDetails.LastRequestApprovalByUserId })) : ((_e = (_d = item.ExtendedProperties) === null || _d === void 0 ? void 0 : _d.CosmosUserDetails) === null || _e === void 0 ? void 0 : _e.CreatedByUserId) ? (React.createElement(PrincipalItemDynamic, { size: "xs", showBorder: false, principalIdOrEmail: (_g = (_f = item.ExtendedProperties) === null || _f === void 0 ? void 0 : _f.CosmosUserDetails) === null || _g === void 0 ? void 0 : _g.CreatedByUserId })) : null) : null,
                                            col.Type === "template-type" ? React.createElement(ReportColumnTemplateType, { Item: item }) : null,
                                            col.Type === "user" ? React.createElement(LifecycleViewMembers, { lifecycleReportItem: item }) : null,
                                            col.Type === "text" ? React.createElement(ColumnText, { Item: item, Col: col }) : null,
                                            col.Type === "text-wide" ? React.createElement(ColumnText, { Item: item, Col: col }) : null,
                                            col.Type === "number" ? React.createElement(ReportColumnNumber, { item: item, col: col }) : null,
                                            col.Type === "number-aggregate" ? React.createElement(ReportColumnNumberAggregate, { item: item, col: col }) : null,
                                            col.Type === "number-with-link" ? React.createElement(ReportColumnNumberWithLink, { item: item, col: col }) : null,
                                            col.Type === "guest-workspace-memberships-actions" && (React.createElement(ReportColumnGuestActions, { Item: item, Mode: "GuestWorkspaceMemberships", cleanupAction: function () {
                                                    dispatch(LifecycleReportStore.UpdateContext({
                                                        View: lifecycleReportContext.View,
                                                        DynamicValues: { guestId: item.UserId }
                                                    }, props.Slot));
                                                } })),
                                            col.Type === "date" ? (React.createElement("p", null, item[col.ProperyName] !== undefined && moment(item[col.ProperyName]).isValid()
                                                ? moment
                                                    .utc(item[col.ProperyName])
                                                    .local()
                                                    .format("MMM Do, YYYY")
                                                : "")) : null,
                                            col.Title === "Date Added" ? (React.createElement("p", null, ((_j = (_h = item.ExtendedProperties) === null || _h === void 0 ? void 0 : _h.GuestMembershipDetails) === null || _j === void 0 ? void 0 : _j.DateAdded) !== undefined && moment((_k = item.ExtendedProperties) === null || _k === void 0 ? void 0 : _k.GuestMembershipDetails.DateAdded).isValid()
                                                ? moment
                                                    .utc(item.ExtendedProperties.GuestMembershipDetails.DateAdded)
                                                    .local()
                                                    .format("MMM Do, YYYY")
                                                : "")) : null,
                                            col.Type === "date-left-aligned" ? (React.createElement("p", null, item[col.ProperyName] !== undefined && moment(item[col.ProperyName]).isValid()
                                                ? moment
                                                    .utc(item[col.ProperyName])
                                                    .local()
                                                    .format("MMM Do, YYYY")
                                                : "")) : null,
                                            col.Type === "date-dynamic" ? React.createElement(ColumnDynamicDate, { Item: item, Col: col }) : null,
                                            col.Type === "date-aggregate" ? React.createElement(ReportColumnDateAggregate, { item: item, col: col }) : null,
                                            col.Type === "description" ? (React.createElement("p", null,
                                                React.createElement(ReadMore, { lines: 3 }, item[col.ProperyName]))) : null,
                                            col.Type === "bold-text" ? (React.createElement("p", null,
                                                React.createElement("strong", null, item[col.ProperyName]))) : null,
                                            col.Type === "report-days-inactive" ? React.createElement(ReportColumnWorkspaceDaysInactive, { Item: item, Col: col }) : null,
                                            col.Type === "report-days-threshold" ? (React.createElement("div", { className: "days-threshold" }, item[col.ProperyName] !== undefined && item[col.ProperyName] !== null ? (React.createElement(React.Fragment, null, (_l = item[col.ProperyName]) === null || _l === void 0 ? void 0 :
                                                _l.toLocaleString().toString().replace("-", ""),
                                                ((_m = item[col.ProperyName]) === null || _m === void 0 ? void 0 : _m.toLocaleString().toString().indexOf("-")) > -1
                                                    ? " days until executed"
                                                    : " days past threshold")) : null)) : null,
                                            col.Type === "report-number" ? (React.createElement("div", { className: "report-number" },
                                                col.ExtraProperties !== undefined && col.ExtraProperties !== null && col.ExtraProperties.Icon !== undefined && col.ExtraProperties.Icon !== null && (React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: col.ExtraProperties.Icon }))),
                                                ((_o = item[col.ProperyName]) === null || _o === void 0 ? void 0 : _o.toLocaleString()) || "0")) : null,
                                            col.Type === "report-boolean" && col.ProperyName !== "SecondStage_Enabled" ? (React.createElement("div", { className: "report-boolean" },
                                                col.ExtraProperties !== undefined && col.ExtraProperties !== null && col.ExtraProperties.Icon !== undefined && col.ExtraProperties.Icon !== null && (React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: col.ExtraProperties.Icon }))),
                                                (item[col.ProperyName] ? "Yes" : "No") || "-")) : null,
                                            col.Type === "report-enabled-disabled" && col.ProperyName !== "SecondStage_Enabled" ? (React.createElement("div", { className: "report-enabled-disabled" },
                                                col.ExtraProperties !== undefined && col.ExtraProperties !== null && col.ExtraProperties.Icon !== undefined && col.ExtraProperties.Icon !== null && (React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: col.ExtraProperties.Icon }))),
                                                (item[col.ProperyName] && (item[col.ProperyName] === "Enabled" || item[col.ProperyName] === true) ? "Enabled" : "Disabled") || "-")) : null,
                                            col.Type === "report-sharing-capabilities" && col.ProperyName !== "SecondStage_Enabled" ? React.createElement(ReportColumnSharingCapabilities, { Item: item, Col: col }) : null,
                                            col.Type === "boolean" ? React.createElement("div", { className: "boolean" }, (item[col.ProperyName] ? "Yes" : "No") || "-") : null,
                                            col.ProperyName === "SecondStage_Enabled" ? (React.createElement("div", { className: "report-boolean" },
                                                col.ExtraProperties !== undefined && col.ExtraProperties !== null && col.ExtraProperties.Icon !== undefined && col.ExtraProperties.Icon !== null && (React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: col.ExtraProperties.Icon }))),
                                                (((_p = item.ExtendedProperties) === null || _p === void 0 ? void 0 : _p[col.ProperyName]) ? "Yes" : "No") || "-")) : null,
                                            col.Type === "report-user-count" ? (React.createElement(NavLink, { exact: true, to: "/lifecycle/listing/" + item.SiteId + "#users" },
                                                React.createElement("div", { className: "report-user-count" },
                                                    col.ExtraProperties !== undefined && col.ExtraProperties !== null && col.ExtraProperties.Icon !== undefined && col.ExtraProperties.Icon !== null && (React.createElement("span", { className: "icon" },
                                                        React.createElement("i", { className: col.ExtraProperties.Icon }))), (_q = item[col.ProperyName]) === null || _q === void 0 ? void 0 :
                                                    _q.toLocaleString()))) : null,
                                            col.Type === "report-users-owners-found" ? (React.createElement("div", { className: "report-user-found" }, GetMatchingUsers(item.UsersOwners).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: false, showBorder: false, size: "sm" })); }))) : null,
                                            col.Type === "report-users-members-found" ? (React.createElement("div", { className: "report-user-found" }, GetMatchingUsers(item.UsersMembers, item.UsersOwners, item.UsersGuests).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: false, showBorder: false, size: "sm" })); }))) : null,
                                            col.Type === "single-user-by-id" ? (React.createElement("div", { className: "single-user-by-id" },
                                                React.createElement(SingleUserById, { Item: item, Col: col }))) : null,
                                            col.Type === "report-users-guests-found" ? (React.createElement("div", { className: "report-user-found" }, GetMatchingUsers(item.UsersGuests).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: false, showBorder: false, size: "sm" })); }))) : null,
                                            col.Type === "report-usage" ? (React.createElement("div", { className: "report-usage" },
                                                React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: "fas fa-database" })),
                                                item[col.ProperyName] !== null ? formatDataSize(item[col.ProperyName]) : "NA")) : null,
                                            col.Type === "report-policies" ? React.createElement(ReportColumnPolicies, { Type: "ArchivalPolicy", Item: item, LifecycleReport: lifecycleReport }) : null,
                                            col.Type === "guest-policies" ? React.createElement(ReportColumnPolicies, { Type: "GuestPolicy", Item: item, LifecycleReport: lifecycleReport }) : null,
                                            col.Type === "report-date" ? (React.createElement("div", { className: "report-date" },
                                                React.createElement("div", { className: "the-date", style: {
                                                        backgroundColor: "rgba(210, 203, 255, " + getReportDateOpacity(item[col.ProperyName]) + ")"
                                                    } }, moment(item[col.ProperyName]).isValid()
                                                    ? moment
                                                        .utc(item[col.ProperyName])
                                                        .local()
                                                        .format("MMM Do, YYYY")
                                                    : ""))) : null,
                                            col.Type === "report-user-list" ? (React.createElement("div", { className: "report-user-list" },
                                                React.createElement(PrincipalsList, { siteUrl: item.SiteCollectionUrl, groupId: item.GroupId, userType: col.ExtraProperties.UserListType }))) : null,
                                            col.Type === "report-channels-private" ? (React.createElement("div", { className: "report-channels-private" },
                                                React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: "fas fa-lock" })), (_r = item.TeamChannelsPrivateCount) !== null && _r !== void 0 ? _r : 0)) : null,
                                            col.Type === "report-channels" ? (React.createElement("div", { className: "report-channels" }, item.TeamChannelsCount || "0")) : null,
                                            col.Type === "report-archival-reason" ? (React.createElement("div", { className: "report-archival-reason" },
                                                React.createElement(LifecycleStrings, { type: "ArchivalReason", value: item.ArchivalReason }))) : null,
                                            col.Type === "report-second-step-archival-reason" ? (React.createElement("div", { className: "report-archival-reason" },
                                                React.createElement(LifecycleStrings, { type: "ArchivalReason", value: item.SecondStageArchivalReason }))) : null,
                                            col.Type === "report-second-step-policy-archival-method" ? (React.createElement("div", { className: "report-second-step-policy-archival-method" },
                                                React.createElement(LifecycleStrings, { type: "SecondStepArchivalMethod", value: (_s = item.ExtendedProperties) === null || _s === void 0 ? void 0 : _s[col.ProperyName] }))) : null,
                                            col.Type === "report-days-until-second-step" ? React.createElement("div", { className: "report-days-until-second-step" }, (_t = item.ExtendedProperties) === null || _t === void 0 ? void 0 : _t[col.ProperyName]) : null,
                                            col.Type === "report-guest-status" ? (React.createElement(ReportColumnGuestStatus, { cosmosUser: item.ExtendedProperties !== undefined && item.ExtendedProperties !== null && ((_u = item.ExtendedProperties) === null || _u === void 0 ? void 0 : _u.CosmosUserDetails) !== undefined
                                                    ? (_v = item.ExtendedProperties) === null || _v === void 0 ? void 0 : _v.CosmosUserDetails
                                                    : item, daysInactive: lifecycleReportContext.InactiveDays !== undefined ? lifecycleReportContext.InactiveDays : 90 })) : null,
                                            col.Type === "custom-choice-single" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "custom-choice-multiple" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "custom-date" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "custom-link" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "custom-text-single" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "custom-text-multiple" ? (React.createElement(ReadMore, { lines: 3 }, getMetadataValue(item, col.ProperyName, col.Type))) : // <>{getMetadataValue(item, col.ProperyName, col.Type)}</>
                                                null,
                                            col.Type === "custom-managed-metadata-single" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "custom-managed-metadata-multiple" ? React.createElement(React.Fragment, null, getMetadataValue(item, col.ProperyName, col.Type)) : null,
                                            col.Type === "azure-actions" ? (React.createElement(ReportColumnGuestActions, { Item: item, Mode: "GuestListing", cleanupAction: function () {
                                                    dispatch(LifecycleReportStore.UpdateContext({ View: lifecycleReportContext.View }, props.Slot));
                                                } })) : null,
                                            col.Type === "guest-access-violations" ? (React.createElement("div", { className: "report-access-violations" },
                                                React.createElement("ul", null, (_x = (_w = item.ExtendedProperties) === null || _w === void 0 ? void 0 : _w.GuestMembershipDetails) === null || _x === void 0 ? void 0 : _x.AccessViolations.map(function (violation, index) { return (React.createElement("li", { key: "rpt-access-violations-" + index }, AccessViolationMap[violation])); })))) : null,
                                            col.Type === "workflow-review-actions" ? (React.createElement(React.Fragment, null,
                                                React.createElement(GuestReviewWorkflowColumnAction, { Item: item }))) : null,
                                            col.Type === "workflow-request-actions" ? (React.createElement(React.Fragment, null,
                                                React.createElement(GuestRequestWorkflowColumnAction, { Item: item }))) : null,
                                            col.ProperyName === "DaysInactive" && col.Type !== "report-guest-days-inactive" ? (React.createElement(React.Fragment, null,
                                                React.createElement("p", { className: "report-days-inactive" }, "".concat(((_z = (_y = item.ExtendedProperties) === null || _y === void 0 ? void 0 : _y.CosmosUserDetails) === null || _z === void 0 ? void 0 : _z.LastSignInDateTime)
                                                    ? moment().diff(moment(item.ExtendedProperties.CosmosUserDetails.LastSignInDateTime), "days")
                                                    : "")))) : null,
                                            col.Type === "report-guest-membership-status" ? React.createElement(ReportColumnGuestMembershipStatus, { Item: item }) : null,
                                            col.Type === "report-guest-violations" ? React.createElement(ReportColumnGuestViolations, { Item: item }) : null,
                                            col.Type === "sql-last-login" ? (React.createElement("p", null, ((_1 = (_0 = item.ExtendedProperties) === null || _0 === void 0 ? void 0 : _0.CosmosUserDetails) === null || _1 === void 0 ? void 0 : _1.LastSignInDateTime) !== undefined &&
                                                moment((_2 = item.ExtendedProperties) === null || _2 === void 0 ? void 0 : _2.CosmosUserDetails[col.ProperyName]).isValid()
                                                ? moment
                                                    .utc(item.ExtendedProperties.CosmosUserDetails[col.ProperyName])
                                                    .local()
                                                    .format("MMM Do, YYYY")
                                                : "")) : null,
                                            col.Type === "LastReviewWorkspaceSiteName" ? React.createElement("p", null, (_3 = item.ExtendedProperties) === null || _3 === void 0 ? void 0 : _3.LastReviewWorkspaceSiteName) : null,
                                            col.Type === "report-guest-deletion-recommendation" ? (React.createElement(ReportColumnGuestDeletionRecommendationReason, { Item: item, DaysInactive: lifecycleReportContext.InactiveDays !== undefined ? lifecycleReportContext.InactiveDays : 90 })) : null,
                                            col.Type === "report-guest-membership-status-new-existing" ? React.createElement(ReportColumnGuestMembershipStatusNewExisting, { Item: item }) : null,
                                            col.Type === "workspace-guest-membership-actions" ? (React.createElement(ReportColumnGuestActions, { Item: item, Mode: "WorkspaceDetailsGuestList", cleanupAction: function () {
                                                    var _a;
                                                    dispatch(LifecycleReportStore.UpdateContext({
                                                        View: lifecycleReportContext.View,
                                                        DynamicValues: { groupId: (_a = lifecycleReportContext.DynamicValues) === null || _a === void 0 ? void 0 : _a.groupId }
                                                    }, props.Slot));
                                                } })) : null))) : null;
                                })));
                        }),
                        lifecycleReport.Items.length === 0 ? (React.createElement("tr", { className: "no-results" + (props.NoResultAlternate ? " alt-padding" : "") },
                            React.createElement("td", { colSpan: 20 }, props.NoResultAlternate ? (React.createElement(NoResultsAlternate, { mainText: props.NoResultMainText, subText: props.NoResultSubText })) : (React.createElement(NoResults, { mainText: props.NoResultMainText, subText: props.NoResultSubText, faIcon: props.NoResultFaIcon }))))) : null))))));
});
export default ReportListTable;
