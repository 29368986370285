/**
 * Returns the initial values for the BaseTenantEntity fields for the mantine form.
 */
export var getBaseTenantEntityInitialValues = function () { return ({
    UniqueId: "",
    Id: 0,
    TenantGUID: "",
    Version: 0,
    CreatedDate: "",
    UpdatedDate: ""
}); };
