import FormDropdownSelect from "app/components/common/form-dropdown-select";
import OrchModal from "app/components/common/orch-modal";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TableList from "app/components/common/table-list";
import TenantDatabaseService from "app/services/tenant-database";
import formatDataSize from "app/utils/formatDataSize";
import * as React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./orchestry-tenant-databases-list.scss";
var OrchestryTenantDatabasesList = React.memo(function () {
    var dispatch = useDispatch();
    var _a = React.useState([]), tenantDatabases = _a[0], setTenantDatabases = _a[1];
    var _b = React.useState(true), loadingList = _b[0], setLoadingList = _b[1];
    React.useEffect(function () {
        refreshData();
    }, []);
    var refreshData = function () {
        setLoadingList(true);
        TenantDatabaseService.getTenantDatabases().then(function (databases) {
            setTenantDatabases(databases.items);
            setLoadingList(false);
        });
    };
    return (React.createElement("div", { className: "orchestry-tenants-database-list fadein-500ms tenant-list" },
        React.createElement(PageHeader.SetLeftContent, { title: "Orchestry Database Information", subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Enter tenant name" })),
        React.createElement(Portlet, { className: "current-status", fluidHeight: true },
            React.createElement(PortletBody, null, loadingList == true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(TableList.Context, { listItems: tenantDatabases, wrappedInPortlet: false },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(TableList.TableList, null,
                            React.createElement(TableList.HeaderItem, { DataProperyName: "DisplayName" }, "Display Name"),
                            React.createElement(TableList.HeaderItem, { DataProperyName: "HasPrivateCosmosDbConnectionString", type: "centered" }, "Pvt. Cosmos"),
                            React.createElement(TableList.HeaderItem, { DataProperyName: "HasPrivateAzureStorageConnectionString", type: "centered" }, "Pvt. Azure Storage"),
                            React.createElement(TableList.HeaderItem, { DataProperyName: "HasPrivateTenantDbConnectionString", type: "centered" }, "Pvt. Tenant Db"),
                            React.createElement(TableList.HeaderItem, { DataProperyName: "TenantDbSource", type: "centered" }, "Tenant Db Source"),
                            React.createElement(TableList.ContentItem, { listDataProperyName: "DisplayName" }),
                            React.createElement(TableList.ContentItemBooleanIcon, { listDataProperyName: "HasPrivateCosmosDbConnectionString" }),
                            React.createElement(TableList.ContentItemBooleanIcon, { listDataProperyName: "HasPrivateAzureStorageConnectionString" }),
                            React.createElement(TableList.ContentItemBooleanIcon, { listDataProperyName: "HasPrivateTenantDbConnectionString" }),
                            React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement(React.Fragment, null, item.MigrationStatus !== null &&
                                    item.MigrationStatus.status !== undefined &&
                                    item.MigrationStatus.status !== null &&
                                    item.MigrationStatus.status == "Processing" ? (React.createElement("div", { className: "pill-" + "migrating", style: { textAlign: "center" } }, "Migrating")) : (React.createElement("div", { className: "pill-" + item.TenantDbSource.toLowerCase(), style: { textAlign: "center" } }, item.TenantDbSource)))); } }),
                            React.createElement(TableList.ContentItemCustomRender, { className: "action-buttons", render: function (item) { return (React.createElement(OrchModal, { simpleButton: true, buttonContents: React.createElement(SubtitleButton, { variant: "primary", size: "sm", faIcon: "lightbulb-gear", altText: "Tenant Database Settings" }), size: "xl", headerText: "Database Info & Settings > " + (item !== null ? item.DisplayName : ""), modalClassName: "orchestry-tenants-database-list-info-modal", onClose: function () { return refreshData(); }, showCloseButton: true },
                                    React.createElement(OrchestryTenantDatabasesListDetails, { tenantDatabaseInfo: item }))); } }))))))))));
});
export default OrchestryTenantDatabasesList;
var OrchestryTenantDatabasesListDetails = React.memo(function (props) {
    var _a = React.useState(null), newDestinationSource = _a[0], setNewDestinationSource = _a[1];
    var _b = React.useState(null), jobId = _b[0], setJobId = _b[1];
    var _c = React.useState(null), jobStatus = _c[0], setJobStatus = _c[1];
    var _d = React.useState(null), tenantDetails = _d[0], setTenantDetails = _d[1];
    var _e = React.useState(false), tenantDetailsLoading = _e[0], setTenantDetailsLoading = _e[1];
    React.useEffect(function () {
        setTenantDetailsLoading(true);
        TenantDatabaseService.getTenantDatabaseDetails(props.tenantDatabaseInfo.TenantGuid).then(function (details) {
            setTenantDetails(details);
            setTenantDetailsLoading(false);
        });
    }, [props.tenantDatabaseInfo.TenantGuid]);
    React.useEffect(function () {
        setJobStatus(props.tenantDatabaseInfo.MigrationStatus);
        if (props.tenantDatabaseInfo.MigrationJobId !== undefined && props.tenantDatabaseInfo.MigrationJobId !== null) {
            setJobId(props.tenantDatabaseInfo.MigrationJobId);
        }
    }, [props.tenantDatabaseInfo.MigrationStatus, props.tenantDatabaseInfo.MigrationJobId]);
    var startMigration = function () {
        if (newDestinationSource != null) {
            TenantDatabaseService.startMigration(props.tenantDatabaseInfo, newDestinationSource).then(function (jobId) {
                setJobId(jobId);
            });
        }
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (jobId !== null && jobId !== undefined) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                if (jobId != null) {
                    TenantDatabaseService.getMigrationStatus(props.tenantDatabaseInfo.TenantGuid, jobId).then(function (data) {
                        if (data !== undefined && data !== null) {
                            var updates = data;
                            setJobStatus(updates);
                        }
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            erroredOut_1 = false;
                            runAgain();
                        }
                        else {
                            // setRemediateRestoreJobStatus("Done");
                            // setProcessing(false);
                            setTimeout(function () { }, 1000);
                        }
                    });
                }
                else {
                    runAgain();
                }
            }
        }
        if (jobId !== null && jobId !== undefined) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null && timerID !== undefined) {
                clearInterval(timerID);
            }
        };
    }, [jobId]);
    var getStatusNumberOrText = function () {
        if (jobStatus == null) {
            return "None";
        }
        else if (jobStatus.status !== undefined && jobStatus.status !== null) {
            if (jobStatus.status == "Processing" && jobStatus.percentComplete !== undefined && jobStatus.percentComplete !== null) {
                return Math.round(Number(jobStatus.percentComplete)) + "%";
            }
            return jobStatus.status;
        }
        return "None";
    };
    var getStatusPercent = function () {
        if (jobStatus == null) {
            return 0;
        }
        else if (jobStatus.status !== undefined && jobStatus.status !== null) {
            if (jobStatus.status == "Processing" && jobStatus.percentComplete !== undefined && jobStatus.percentComplete !== null) {
                return jobStatus.percentComplete;
            }
            if (jobStatus.status == "Complete") {
                return 100;
            }
        }
        return 0;
    };
    var getEstimatedSizeOfTable = function (tableName, rows) {
        var bytes = 0;
        if (tenantDetails !== undefined && tenantDetails !== null) {
            tenantDetails.TableRowSizes.forEach(function (tableRowSize) {
                if (tableRowSize.TableName == tableName) {
                    console.log("match - " + tableName + " - " + tableRowSize.TableName + " - " + tableRowSize.BytesPerRow);
                    bytes = tableRowSize.BytesPerRow * rows;
                }
                else {
                }
            });
        }
        return bytes;
    };
    var getEstimatedTotalSize = function () {
        var bytes = 0;
        if (tenantDetails != null && tenantDetails.TableRowCounts !== null) {
            Object.keys(tenantDetails.TableRowCounts).map(function (tableName) {
                bytes += getEstimatedSizeOfTable(tableName, tenantDetails.TableRowCounts[tableName]);
            });
        }
        return bytes;
    };
    return (React.createElement("div", null,
        React.createElement(Row, { className: "title-row" },
            React.createElement(Col, null,
                React.createElement("h5", null, "Estimated Total Data Usage for customer"))),
        React.createElement(Row, null,
            React.createElement(Col, null, tenantDetailsLoading == false && tenantDetails != null ? React.createElement("div", { className: "estimated-tenant-size" }, formatDataSize(getEstimatedTotalSize())) : React.createElement(SkeletonLoaderFakeBox, null))),
        React.createElement(Row, { className: "title-row" },
            React.createElement(Col, null,
                React.createElement("h5", null, "Tenant Database Row Counts"))),
        React.createElement(Row, null,
            React.createElement(Col, null, tenantDetailsLoading == false && tenantDetails != null ? (React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("tbody", null, Object.keys(tenantDetails.TableRowCounts).map(function (tableName) { return (React.createElement("tr", null,
                    React.createElement("td", null, tableName),
                    React.createElement("td", null, tenantDetails.TableRowCounts[tableName]),
                    React.createElement("td", null, formatDataSize(getEstimatedSizeOfTable(tableName, tenantDetails.TableRowCounts[tableName]))))); })))) : (React.createElement(SkeletonLoaderFakeBox, null)))),
        React.createElement(Row, { className: "title-row" },
            React.createElement(Col, null,
                React.createElement("h5", null, "Tenant Database Migration"))),
        jobStatus == null ? (React.createElement("div", { className: "migrate-tenantdb" },
            React.createElement(Row, { className: "subtitle-row" },
                React.createElement(Col, null,
                    React.createElement("strong", null, "Source")),
                React.createElement(Col, null),
                React.createElement(Col, null,
                    React.createElement("strong", null, "Destination")),
                React.createElement(Col, null)),
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "pill-" + props.tenantDatabaseInfo.TenantDbSource.toLowerCase() }, props.tenantDatabaseInfo.TenantDbSource)),
                React.createElement(Col, { className: "right-arrow" },
                    React.createElement("i", { className: "fa fa-arrow-right" })),
                React.createElement(Col, null,
                    React.createElement(FormDropdownSelect, { list: [
                            {
                                id: "45",
                                title: "-- please select --",
                                value: null
                            },
                            {
                                id: "0",
                                title: "Shared",
                                value: "Shared"
                            },
                            {
                                id: "1",
                                title: "Dedicated",
                                value: "Dedicated"
                            } //,
                            // {
                            //    id: "2",
                            //    title: "Private",
                            //    value: "Private"
                            // }
                        ].filter(function (l) { return l.value != props.tenantDatabaseInfo.TenantDbSource; }), value: newDestinationSource, onChange: function (value) {
                            setNewDestinationSource(value);
                        } })),
                React.createElement(Col, null,
                    React.createElement(SubtitleButton, { title: "Start Migration", disabled: newDestinationSource == null, onClick: function () { return startMigration(); } }))))) : (React.createElement("div", { className: "migrate-tenantdb-inprogress" },
            React.createElement("div", { className: "progress-number" },
                React.createElement("span", null, getStatusNumberOrText())),
            React.createElement("div", { className: "progress-bar-migration" }, getStatusPercent() == 0 ? React.createElement(React.Fragment, null) : React.createElement("div", { className: "progress-bar-migration-percent", style: { width: getStatusPercent() + "%" } }))))));
});
