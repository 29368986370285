import { getIconClassName } from "@uifabric/styling";
import DisableArea from "app/components/common/disable-area";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { Portlet, PortletBody } from "app/components/common/portlet";
import PrincipalItemDynamic from "app/components/common/principal-item-dynamic";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { generateReasons } from "app/components/insights/common/report/report-list-table/components/report-column-guest-deletetion-recommendation-reason/reasons";
import GuestStatusBox from "app/components/insights/guests/common/guest-status-box";
import GuestStatusIcon from "app/components/insights/guests/common/guest-status-icon";
import GuestStatusText from "app/components/insights/guests/common/guest-status-text";
import LifecycleDetailsTabLifecycle from "app/components/insights/guests/guest-details/tabs/guest-details-tab-lifecycle";
import LifecycleGuestDetailsTabOverview from "app/components/insights/guests/guest-details/tabs/guest-details-tab-overview";
import { GuestManagementUserStatus } from "app/store/lifecycle-guest";
import * as LifecycleGuestStore from "app/store/lifecycle-guest/current";
import TeamClientAppNavigation from "app/utils/teamClientAppNavigation";
import moment from "moment";
import * as React from "react";
import { Nav, Tab } from "react-bootstrap";
import { emitCustomEvent } from "react-custom-events";
import { useDispatch, useSelector } from "react-redux";
import GuestActionButtonDelete from "../../../common/guest-action-button-delete";
import "./lifecycle-guest-details-header.scss";
var LifecycleGuestDetailsHeader = React.memo(function (_a) {
    var guestId = _a.guestId, cosmosUser = _a.cosmosUser;
    var guestCosmosItem = useSelector(function (state) { return state.lifecycleGuestState.cosmosItem; });
    var guestInactivityDays = useSelector(function (state) { return state.lifecycleGuestState.daysInactive; });
    var itemsLoadedFromServer = useSelector(function (state) { return state.lifecycleGuestState.initialLoadDone; });
    var dispatch = useDispatch();
    var _b = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "overview"), hash = _b[0], setHash = _b[1];
    var historyLoaded = useSelector(function (state) { return state.lifecycleGuestState.historyInitialLoadDone; });
    var _c = React.useState(""), previousUserId = _c[0], setPreviousUserId = _c[1];
    var _d = React.useState(), guestProfileOverlay = _d[0], setguestProfileOverlay = _d[1];
    var tenantConfig = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var guestMemberships = useSelector(function (state) { return state.lifecycleReportState.GuestMembershipsSlot.Items; });
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
        setHash(newHash);
    };
    React.useEffect(function () {
        if (cosmosUser !== null && cosmosUser !== undefined && cosmosUser.UserId !== previousUserId) {
            setPreviousUserId(cosmosUser.UserId);
            dispatch(LifecycleGuestStore.LoadCosmosItemFromObject(cosmosUser));
            dispatch(LifecycleGuestStore.Load(guestId));
        }
        else if (guestId !== null && guestId !== previousUserId) {
            setPreviousUserId(guestId);
            dispatch(LifecycleGuestStore.LoadWithCosmosObject(guestId));
        }
        return function () {
            dispatch(LifecycleGuestStore.Clear());
        };
    }, [cosmosUser, guestId]);
    React.useEffect(function () {
        var _a;
        if (guestCosmosItem) {
            var reasons = generateReasons(guestCosmosItem, tenantConfig.LCDefaultGuestInactivityDays || 90, true);
            var overlayItems = (_a = reasons === null || reasons === void 0 ? void 0 : reasons.filter(function (reason) { return reason.Reason !== "GuestsNotAllowed"; }).map(function (reason) {
                if (reason.Reason === "BlockedDomain") {
                    return { message: reason.Title, order: 1 };
                }
                return { message: reason.Title, order: 0 };
            })) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { return b.order - a.order; });
            var overlayTitle = (reasons === null || reasons === void 0 ? void 0 : reasons.some(function (reason) { return reason.Reason === "BlockedDomain"; })) ? "Advisory" : "Warning";
            var iconName = (reasons === null || reasons === void 0 ? void 0 : reasons.some(function (reason) { return reason.Reason === "BlockedDomain"; })) ? "fa-light fa-triangle-exclamation" : "fa-light fa-circle-exclamation";
            setguestProfileOverlay({ icon: { fontSize: 24, iconClassName: iconName }, overlayTitle: overlayTitle, items: overlayItems });
        }
    }, [guestCosmosItem, tenantConfig.LCDefaultGuestInactivityDays]);
    // In An effort to reduce load times for this we are going to lazy load resources only when needed.
    React.useEffect(function () {
        // dispatch(LifecycleGuestStore.LoadHistory(guestId));
        if (hash === "overview") {
            //setTimeout(() => {
            emitCustomEvent("update-insights-height");
            //}, 200);
        }
        if (hash === "lifecycle") {
            if (historyLoaded === false) {
                dispatch(LifecycleGuestStore.LoadHistory(guestId, "all"));
            }
        }
    }, [hash]);
    React.useEffect(function () {
        window.scrollTo(0, 0);
        if (guestId !== undefined) {
        }
        return function () { };
    }, [guestId]);
    var refreshGuest = function (hideLoading) {
        dispatch(LifecycleGuestStore.Clear());
        dispatch(LifecycleGuestStore.LoadWithCosmosObject(guestId));
        dispatch(LifecycleGuestStore.LoadHistory(guestId, "all"));
        hideLoading();
    };
    function openInNewTab(url) {
        if (window !== null) {
            window.open(url, "_blank").focus();
        }
    }
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var hostClientType = teamContext === null || teamContext === void 0 ? void 0 : teamContext.app.host.clientType;
    return (React.createElement("div", null,
        React.createElement(Tab.Container, { id: "template-new-tabs", defaultActiveKey: hash },
            React.createElement(Portlet, { className: "lifecycle-guest-details-header", "data-pw": "guest-details-header" },
                React.createElement("div", { className: "overview-content" },
                    React.createElement("div", { className: "top-info" },
                        React.createElement("div", { className: "name-and-logo" },
                            React.createElement("div", { className: "logo", "data-pw": "logo" }, guestCosmosItem === null || guestId !== guestCosmosItem.UserId ? (React.createElement("div", { className: "skeleton-logo" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 64, bodyWidth: 64 }))) : (React.createElement(React.Fragment, null,
                                React.createElement(OfficeProfilePicture, { size: "lg", type: "User", displayName: guestCosmosItem.DisplayName, uid: guestCosmosItem.UserId, overlay: guestProfileOverlay })))),
                            React.createElement("div", { className: "top-stats" },
                                React.createElement("div", { className: "ts-left" },
                                    React.createElement("div", { className: "ws-title" }, guestCosmosItem === null || guestId !== guestCosmosItem.UserId ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 36, marginTop: 15, bodyWidth: 300 })) : (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "nested-title", "data-pw": "guest-title" }, guestCosmosItem.DisplayName),
                                        React.createElement("div", { className: "actions" },
                                            React.createElement("a", { target: "_blank", "data-pw": "email-action", rel: "noopener noreferrer", title: guestCosmosItem.Mail ? "mailto:".concat(guestCosmosItem.Mail) : undefined, href: guestCosmosItem.Mail ? "mailto:".concat(guestCosmosItem.Mail) : undefined },
                                                React.createElement("i", { className: "fa-light fa-envelope" })),
                                            React.createElement("a", { target: "_blank", "data-pw": "chat-action", rel: "noopener noreferrer", title: "Chat with ".concat(guestCosmosItem.DisplayName), onClick: function (event) {
                                                    return TeamClientAppNavigation.goToTeamsLink("https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent(guestCosmosItem.UserPrincipalName)), event, hostClientType);
                                                }, href: guestCosmosItem.UserPrincipalName ? "https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent(guestCosmosItem.UserPrincipalName)) : undefined },
                                                React.createElement("i", { className: "fa-light fa-comment" })))))),
                                    React.createElement("div", { className: "ws-description", "data-pw": "guest-description" }, guestCosmosItem === null || guestId !== guestCosmosItem.UserId ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 21, marginTop: 15, bodyWidth: 300 }) : guestCosmosItem === null || guestCosmosItem === void 0 ? void 0 : guestCosmosItem.Mail)))),
                        itemsLoadedFromServer === true && guestCosmosItem !== null && guestId === guestCosmosItem.UserId && guestInactivityDays !== null ? (React.createElement("div", { className: "activity" },
                            React.createElement(GuestStatusBox, { cosmosUser: guestCosmosItem, daysInactive: guestInactivityDays, useDefaultStyles: false },
                                React.createElement("div", { className: "left" },
                                    React.createElement(GuestStatusIcon, { cosmosUser: guestCosmosItem, daysInactive: guestInactivityDays, useDefaultStyles: false })),
                                React.createElement("div", { className: "stats" },
                                    React.createElement("ul", null,
                                        React.createElement("li", { className: "status-title", "data-pw": "status-title" },
                                            React.createElement(GuestStatusText, { cosmosUser: guestCosmosItem, daysInactive: guestInactivityDays })),
                                        React.createElement("li", { "data-pw": "days-inactive" }, guestCosmosItem !== null && guestCosmosItem.DaysInactive !== undefined && guestCosmosItem.LastLogin !== null ? (React.createElement(React.Fragment, null,
                                            "Days since sign in ",
                                            React.createElement("strong", null, guestCosmosItem.DaysInactive))) : null),
                                        React.createElement("li", { "data-pw": "groups-access" }, guestCosmosItem !== null ? (React.createElement(React.Fragment, null,
                                            "Groups with access ",
                                            React.createElement("strong", null, guestCosmosItem.Status === GuestManagementUserStatus.Deleted ? 0 : guestCosmosItem.GroupMembershipsCount))) : null)))))) : null),
                    React.createElement("div", { className: "metadata" }, guestCosmosItem === null || guestCosmosItem.UserId !== guestId ? (React.createElement("div", { className: "bottom-stats" },
                        React.createElement("div", { className: "stat" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 41, marginTop: 15 })),
                        React.createElement("div", { className: "stat" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 41, marginTop: 15 })),
                        React.createElement("div", { className: "stat" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 41, marginTop: 15 })),
                        React.createElement("div", { className: "stat" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 41, marginTop: 15 })))) : (React.createElement("div", { className: "bottom-stats" },
                        guestCosmosItem.Domain !== null ? (React.createElement("div", { className: "stat", "data-pw": "domain-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Domain"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" }, guestCosmosItem.Domain))) : null,
                        guestCosmosItem.CreatedDateTime !== null ? (React.createElement("div", { className: "stat", "data-pw": "date-created-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Date Created"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" }, moment
                                .utc(guestCosmosItem.CreatedDateTime)
                                .local()
                                .format("LL")))) : null,
                        guestCosmosItem.CreatedDateTime !== null ? (React.createElement("div", { className: "stat", "data-pw": "created-with-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Created With"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" }, guestCosmosItem.CreationSource))) : null,
                        guestCosmosItem.CreatedByUserId !== null ? (React.createElement("div", { className: "stat", "data-pw": "created-by-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Created By"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" },
                                React.createElement(PrincipalItemDynamic, { principalIdOrEmail: guestCosmosItem.CreatedByUserId, size: "sm", showBorder: false })))) : null,
                        React.createElement("div", { className: "stat", "data-pw": "last-login-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Last Login"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" }, guestCosmosItem.LastLogin !== null
                                ? moment
                                    .utc(guestCosmosItem.LastLogin)
                                    .local()
                                    .format("LL")
                                : null)),
                        guestCosmosItem.DeletedDateTime !== null ? (React.createElement("div", { className: "stat", "data-pw": "date-deleted-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Date Deleted"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" }, moment
                                .utc(guestCosmosItem.DeletedDateTime)
                                .local()
                                .format("LL")))) : null,
                        guestCosmosItem.CompanyName !== null ? (React.createElement("div", { className: "stat", "data-pw": "company-name-stat" },
                            React.createElement("div", { className: "stat-title", "data-pw": "stat-title" }, "Company Name"),
                            React.createElement("div", { className: "stat-content", "data-pw": "stat-content" }, guestCosmosItem.CompanyName))) : null)))),
                React.createElement("div", { className: "nav-bar-bottom" },
                    React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                        React.createElement(DisableArea, { isEnabled: itemsLoadedFromServer, useOverlay: true, cursorType: "loading" },
                            React.createElement(Nav, { as: "ul" },
                                React.createElement(Nav.Item, { as: "li", "data-pw": "overview-tab", onClick: function (event) {
                                        setHashAnchor("overview");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "overview" },
                                        React.createElement("span", null, "Overview"))),
                                React.createElement(Nav.Item, { as: "li", "data-pw": "lifecycle-tab", onClick: function (event) {
                                        setHashAnchor("lifecycle");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "lifecycle" },
                                        React.createElement("span", null, "Lifecycle")))))),
                    React.createElement("div", { className: "actions" },
                        React.createElement("ul", null,
                            guestCosmosItem !== null ? (guestCosmosItem.Status !== GuestManagementUserStatus.Deleted ? (React.createElement("div", { className: "azure", "data-pw": "view-in-azure", onClick: function () { return openInNewTab("https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/" + guestCosmosItem.UserId); } },
                                React.createElement("i", { className: getIconClassName("AzureLogo") }),
                                React.createElement("span", null, "View in Azure"))) : null) : (React.createElement("div", null,
                                React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 130, bodyHeight: 28 }))),
                            React.createElement(React.Fragment, null, guestCosmosItem !== null ? (guestCosmosItem.Status !== GuestManagementUserStatus.Deleted ? (React.createElement(GuestActionButtonDelete, { cosmosUser: guestCosmosItem, postActionEvent: function (hideLoading) { return refreshGuest(hideLoading); } },
                                React.createElement("i", { className: "fa-regular fa-trash-can-xmark" }),
                                React.createElement("span", null, "Delete from Azure"))) : null) : (React.createElement("div", null,
                                React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 130, bodyHeight: 28 })))))))),
            React.createElement(Tab.Content, null,
                React.createElement(Tab.Pane, { eventKey: "overview" }, guestId === (guestCosmosItem === null || guestCosmosItem === void 0 ? void 0 : guestCosmosItem.UserId) && guestCosmosItem !== null ? (React.createElement(LifecycleGuestDetailsTabOverview, { groupIds: guestCosmosItem.GroupMemberships, guestId: guestCosmosItem.UserId, currentHash: hash })) : (React.createElement(Portlet, null,
                    React.createElement(PortletBody, null,
                        React.createElement(SkeletonLoaderFakeBox, null))))),
                React.createElement(Tab.Pane, { eventKey: "lifecycle" }, guestCosmosItem !== null ? React.createElement(LifecycleDetailsTabLifecycle, { groupIds: guestCosmosItem.GroupMemberships, guestId: guestCosmosItem.UserId, currentHash: hash }) : null)))));
});
export default LifecycleGuestDetailsHeader;
