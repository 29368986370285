var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { OrchestryChip } from "../../orchestry-chip/orchestry-chip.component";
/**
 * To consider: OrchestryFilterItem is a pass-through component to OrchestryChip. Should we merge it with
 * the OrchestryChip component and handle the onClick event better?
 *
 * Let's leave this until a future change gives clarity on the best approach.
 */
export var OrchestryFilterItem = React.forwardRef(function (props, ref) {
    return (React.createElement("div", { ref: ref },
        React.createElement(OrchestryChip, __assign({ isLoading: props.isLoading, onClick: props.onClick ? function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.filterProps.value); } : undefined }, props.filterProps))));
});
