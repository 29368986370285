import React from "react";
import "./chrome-language.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import LanguageSelector from "_metronic/partials/layout/LanguageSelector";
import clsx from "clsx";
var ChromeLanguage = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    return (React.createElement("div", { className: clsx("chrome-language", { "in-sharepoint": isInSharepoint }) },
        React.createElement(OverlayTrigger, { placement: "bottom", overlay: React.createElement(Tooltip, { id: "quick-panel-tooltip" }, "Language") },
            React.createElement(LanguageSelector, { orchestryContext: orchestryContext }))));
});
export default ChromeLanguage;
