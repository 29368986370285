import * as React from "react";
import "./lifecycle-details-tab-lifecycle.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import LifecycleGuestDetailsHistory from "app/components/insights/guests/guest-details/components/lifecycle-guest-details-history";
var LifecycleDetailsTabLifecycle = React.memo(function (_a) {
    var guestId = _a.guestId;
    var dispatch = useDispatch();
    var history = useSelector(function (state) { return state.lifecycleGuestState.history; });
    return (React.createElement("div", { className: "lifecycle-details-tab-lifecycle", "data-pw": "guest-details-tab-lifecycle" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6, lg: 4 },
                React.createElement(LifecycleGuestDetailsHistory, { guestId: guestId, type: "creation", history: history.Creation, count: history.Counts.Creation })),
            React.createElement(Col, { md: 6, lg: 4 },
                React.createElement(LifecycleGuestDetailsHistory, { guestId: guestId, type: "access", history: history.Access, count: history.Counts.Access })),
            React.createElement(Col, { md: 6, lg: 4 },
                React.createElement(LifecycleGuestDetailsHistory, { guestId: guestId, type: "review", history: history.Review, count: history.Counts.Review })))));
});
export default LifecycleDetailsTabLifecycle;
