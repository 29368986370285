import { Card, Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-resolutions-number-stat-skeleton.scss";
export var RecommendationResolutionsNumberStatSkeleton = function () { return (React.createElement(Card, { className: "recommendation-resolutions-number-stat-skeleton" },
    React.createElement("div", { className: "top-section" },
        React.createElement(Skeleton, { circle: true, h: 32, w: 32 }),
        React.createElement(Skeleton, { w: 60, h: 12.5, radius: 17.5 })),
    React.createElement("div", { className: "middle-section" },
        React.createElement(Skeleton, { circle: true, h: 22, w: 22 })),
    React.createElement("div", { className: "bottom-section" },
        React.createElement(Skeleton, { h: 10.5, w: 59 }),
        React.createElement(Skeleton, { ml: 25.17, circle: true, w: 11.667, h: 11.667 }),
        React.createElement(Skeleton, { ml: 8.17, w: 15, h: 10.5 })))); };
