import * as React from "react";
import { Row, Col } from "react-bootstrap";
import LocalSession from "app/services/local-session";
import * as OrchestryContextStore from "app/store/orchestry-context/current";
import { useSelector, useDispatch } from "react-redux";
var InstallerStepConfirm = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    React.useEffect(function () {
        LocalSession.Remove("OrchestryContext");
        dispatch(OrchestryContextStore.Load());
    }, []);
    return (React.createElement("div", { className: "installer-step-confirm fadein-500ms" },
        React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
            React.createElement("div", { className: "kt-heading kt-heading--md" },
                React.createElement("h2", null, "All Done!")),
            React.createElement("div", { className: "kt-form__section kt-form__section--first" }, props.isUpgrade === false ? (React.createElement(Row, null,
                React.createElement(Col, { md: 12, lg: 6 },
                    React.createElement("div", { className: "install-team-message" }, "We have added the Orchestry App to your Teams instance. Simply refresh or restart Teams to see the App and get started with Orchestry!"),
                    React.createElement("div", { className: "screen" },
                        React.createElement("img", { src: "/media/orchestry-teams-app-screen-circle.png" }))),
                React.createElement(Col, { md: 12, lg: 6 },
                    React.createElement("div", { className: "kt-wizard-v2__review" },
                        React.createElement("div", { className: "kt-wizard-v2__review-item" },
                            React.createElement("div", { className: "kt-wizard-v2__review-title" }, "Install Checklist"),
                            React.createElement("div", { className: "kt-wizard-v2__review-content" },
                                React.createElement("ul", { className: "install-checklist" },
                                    React.createElement("li", null, "Granted consent for Orchestry to access your organization"),
                                    React.createElement("li", null, "Setup your organization in Orchestry"),
                                    React.createElement("li", null, "Installed the Orchestry site collection in your tenant"),
                                    currentTenant.SkipTeamsAppInstall !== undefined ? currentTenant.SkipTeamsAppInstall === false ? React.createElement("li", null, "Added Orchestry App to Microsoft Teams") : null : null))))))) : (React.createElement("div", null,
                React.createElement("div", { className: "kt-wizard-v2__review" },
                    React.createElement("div", { className: "kt-wizard-v2__review-item" },
                        React.createElement("div", { className: "kt-wizard-v2__review-content" },
                            React.createElement("div", { className: "" }, "Update complete!"))))))))));
});
export default InstallerStepConfirm;
