var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HealthCheckStatus } from "appv2/api/health-checks/health-check-status.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
export var useHealthChecksFilters = function () {
    var intl = useIntl();
    var HEALTH_CHECKS_FILTERS_LIST = useMemo(function () { return [
        {
            type: "selectable",
            title: intl.formatMessage({ id: "health_checks.status.draft", defaultMessage: "Ready for Review" }),
            value: HealthCheckStatus.Draft,
            group: intl.formatMessage({ id: "health_checks.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.pencilLine,
            color: "var(--mantine-color-orange-8)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "health_checks.status.published", defaultMessage: "Published" }),
            value: HealthCheckStatus.Published,
            group: intl.formatMessage({ id: "health_checks.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.checkCircle,
            color: "var(--mantine-color-success-8)"
        }
    ]; }, [intl]);
    /**
     * We have a table version because the processing status is rare and so we want to show it in the table
     * but not in the filter selector.
     */
    var HEALTH_CHECKS_TABLE_FILTERS_LIST = useMemo(function () { return [
        {
            type: "selectable",
            title: intl.formatMessage({ id: "health_checks.status.draft", defaultMessage: "Ready for Review" }),
            value: HealthCheckStatus.Draft,
            group: intl.formatMessage({ id: "health_checks.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.pencilLine,
            color: "var(--mantine-color-orange-8)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "health_checks.status.processing", defaultMessage: "Processing" }),
            value: HealthCheckStatus.Processing,
            group: intl.formatMessage({ id: "health_checks.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.loading02,
            color: "var(--mantine-color-purple-8)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "health_checks.status.published", defaultMessage: "Published" }),
            value: HealthCheckStatus.Published,
            group: intl.formatMessage({ id: "health_checks.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.checkCircle,
            color: "var(--mantine-color-success-8)"
        }
    ]; }, [intl]);
    var FILTER_GROUPS = [intl.formatMessage({ id: "health_checks.status", defaultMessage: "Status" })];
    var _a = useState(HEALTH_CHECKS_FILTERS_LIST), appliedFilters = _a[0], setAppliedFilters = _a[1];
    var clearAllFilters = function () {
        setAppliedFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
    };
    var removeFilter = function (filter) {
        var selectableFilter = filter;
        var newAppliedFilters = appliedFilters.map(function (appliedFilter) { var _a, _b; return ((_a = appliedFilter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = selectableFilter.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ? __assign(__assign({}, appliedFilter), { selected: false }) : appliedFilter; });
        setAppliedFilters(newAppliedFilters);
    };
    return {
        HEALTH_CHECKS_TABLE_FILTERS_LIST: HEALTH_CHECKS_TABLE_FILTERS_LIST,
        HEALTH_CHECKS_FILTERS_LIST: HEALTH_CHECKS_FILTERS_LIST,
        appliedFilters: appliedFilters,
        setAppliedFilters: setAppliedFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        FILTER_GROUPS: FILTER_GROUPS
    };
};
