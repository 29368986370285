import { OrchestryIconLabel } from "appv2/components/orchestry-icon-label/orchestry-icon-label.component";
import React from "react";
import { mapRecommendationFilterNameToIcon } from "../../recommendation.utils";
import { RecommendationPriority } from "appv2/api/recommendations/recommendation-priority.type";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { useIntl } from "react-intl";
import { mapRecommendationPriorityToColor } from "./recommendation-priority-indicator.utils";
export var RecommendationsPriorityIndicator = function (_a) {
    var priority = _a.priority, visibility = _a.visibility, isLoading = _a.isLoading;
    var intl = useIntl();
    var intlId = "recommendations.priority.".concat(priority === null || priority === void 0 ? void 0 : priority.toLowerCase());
    var textLabel = intl.formatMessage({ id: intlId, defaultMessage: priority });
    return (React.createElement(OrchestryIconLabel, { iconSize: "sm", iconVariant: "light-circle", iconColorVariant: mapRecommendationPriorityToColor[priority], iconClassName: mapRecommendationFilterNameToIcon(priority === null || priority === void 0 ? void 0 : priority.toLowerCase()), textSize: "xs", textColorVariant: priority, textLabel: textLabel, textFW: priority === RecommendationPriority.Urgent ? 700 : "initial", disabled: visibility === RecommendationRecordVisibility.Resolved, isLoading: isLoading }));
};
