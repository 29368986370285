import axios from "axios";
var LibraryService = /** @class */ (function () {
    function LibraryService() {
    }
    LibraryService.CopyTemplate = function (libTemplateGuid, newTemplateName) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LibraryTemplates/CopyToTenant?itemGuid=" + libTemplateGuid + "&newTemplateName=" + encodeURIComponent(newTemplateName);
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Install Start Response" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return LibraryService;
}());
export default LibraryService;
