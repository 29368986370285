import { useEffect, useState } from "react";
/**
 * Hook to determine if the page is loading.
 * Returns true if page has skeletons or if there has never been an active request.
  *Used for aggressive loading indicators for things like PDF generation.

 * Ideally it would count the number of active requests, but I found this to work inconsistently with when the override happens.
 */
export var useIsPageLoading = function () {
    var _a = useState(0), activeRequests = _a[0], setActiveRequests = _a[1];
    var _b = useState(false), hasMadeRequest = _b[0], setHasMadeRequest = _b[1];
    var _c = useState(false), hasSkeletons = _c[0], setHasSkeletons = _c[1];
    //I found this code to work inconsitently. Skeletons below seems to save us.
    // Save the original fetch function
    var originalFetch = window.fetch;
    window.fetch = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        setHasMadeRequest(true);
        setActiveRequests(function (prev) { return prev + 1; });
        var fetchPromise = originalFetch.apply(void 0, args);
        fetchPromise.finally(function () {
            setActiveRequests(function (prev) { return prev - 1; });
        });
        return fetchPromise;
    };
    // Save the original XMLHttpRequest.send function
    var originalXhrSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        setHasMadeRequest(true);
        setActiveRequests(function (prev) { return prev + 1; });
        this.addEventListener("loadend", function () {
            setActiveRequests(function (prev) { return prev - 1; });
        });
        return originalXhrSend.apply(this, args);
    };
    useEffect(function () {
        var checkForSkeletons = function () {
            var skeletons = document.querySelectorAll(".mantine-Skeleton-root");
            setHasSkeletons(skeletons.length > 0);
            if (skeletons.length > 0) {
                setHasMadeRequest(true);
            }
        };
        // Initial check
        checkForSkeletons();
        // Set up a MutationObserver to detect changes in the DOM
        var observer = new MutationObserver(checkForSkeletons);
        observer.observe(document.body, { childList: true, subtree: true });
        // Cleanup function to disconnect the observer
        return function () {
            observer.disconnect();
        };
    }, []);
    var isLoading = !hasMadeRequest || hasSkeletons || activeRequests > 0;
    return isLoading;
};
