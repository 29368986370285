import React from "react";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import moment from "moment";
import "./team-information-status.scss";
export var TeamInformationStatus = function (_a) {
    var _b;
    var classNames = _a.classNames;
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var teamInformationWebPartState = useSelector(function (state) { return state.teamInformationWebPartState; });
    var intl = useIntl();
    if (((_b = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _b === void 0 ? void 0 : _b.showStatus) === false) {
        return null;
    }
    var getStatusClass = function () {
        var returnClass = "active";
        if (teamInformation !== null && teamInformation.WorkspaceStatus !== null && teamInformation.WorkspaceStatus !== undefined) {
            if (teamInformation.WorkspaceStatus.toLowerCase() === "active") {
                if (teamInformation !== null &&
                    teamInformation.WorkspaceInactiveDays !== null &&
                    teamInformation.WorkspaceDaysForInactiveSetting !== undefined &&
                    teamInformation.WorkspaceInactiveDays >= teamInformation.WorkspaceDaysForInactiveSetting) {
                    returnClass = "inactive";
                }
            }
            else {
                returnClass = teamInformation.WorkspaceStatus.toLowerCase();
            }
        }
        else {
            returnClass = "active";
        }
        return returnClass;
    };
    var getStatusIcon = function () {
        if (teamInformation !== null && teamInformation !== undefined) {
            if (teamInformation.WorkspaceStatus === "Active" || teamInformation.WorkspaceStatus === null) {
                if (teamInformation !== null &&
                    teamInformation.WorkspaceInactiveDays !== null &&
                    teamInformation.WorkspaceDaysForInactiveSetting !== undefined &&
                    teamInformation.WorkspaceInactiveDays >= teamInformation.WorkspaceDaysForInactiveSetting) {
                    return "business-time";
                }
                else {
                    return "certificate";
                }
            }
            if (teamInformation.WorkspaceStatus === "PendingApproval") {
                return "business-time";
            }
            if (teamInformation.WorkspaceStatus === "Extended") {
                return "clock";
            }
            if (teamInformation.WorkspaceStatus === "ReadyToArchive") {
                return "flag-checkered";
            }
            if (teamInformation.WorkspaceStatus === "Processing") {
                return "rocket";
            }
            if (teamInformation.WorkspaceStatus === "Done") {
                return "archive";
            }
            if (teamInformation.WorkspaceStatus === "Failed") {
                return "exclamation-circle";
            }
            if (teamInformation.WorkspaceStatus === "Deleted") {
                return "trash";
            }
        }
    };
    var getStatusText = function () {
        if (teamInformation !== null) {
            if (teamInformation.WorkspaceStatus === "Active" || teamInformation.WorkspaceStatus === null) {
                if (teamInformation !== null &&
                    teamInformation.WorkspaceInactiveDays !== null &&
                    teamInformation.WorkspaceDaysForInactiveSetting !== undefined &&
                    teamInformation.WorkspaceInactiveDays >= teamInformation.WorkspaceDaysForInactiveSetting) {
                    return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.inactive", defaultMessage: "Inactive" });
                }
                else {
                    return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.active", defaultMessage: "Active" });
                }
            }
            if (teamInformation.WorkspaceStatus === "PendingApproval") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.pending", defaultMessage: "Pending Approval" });
            }
            if (teamInformation.WorkspaceStatus === "Extended") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.extended", defaultMessage: "Workspace Renewed" });
            }
            if (teamInformation.WorkspaceStatus === "ReadyToArchive") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.ready_to_archive", defaultMessage: "Ready to Archive" });
            }
            if (teamInformation.WorkspaceStatus === "Processing") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.processing", defaultMessage: "Processing Archival" });
            }
            if (teamInformation.WorkspaceStatus === "Done") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.archived", defaultMessage: "Archived" });
            }
            if (teamInformation.WorkspaceStatus === "Failed") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.failed", defaultMessage: "Archival Failed" });
            }
            if (teamInformation.WorkspaceStatus === "Deleted") {
                return intl.formatMessage({ id: "component.lifecycle.policy_assignments.title_label.deleted", defaultMessage: "Deleted" });
            }
        }
    };
    return (React.createElement("div", { className: "team-information-activity status-" + getStatusClass() + " " + classNames },
        React.createElement("div", { className: "activity-context" },
            React.createElement("div", { className: "icon" }, teamInformation === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, bodyWidth: 40, style: { marginRight: "15px" } })) : (React.createElement("div", { className: "icon-square" },
                React.createElement("i", { className: "fa fa-" + getStatusIcon() })))),
            React.createElement("div", { className: "activity-title" },
                React.createElement("div", { className: "title" },
                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.property.status", defaultMessage: "Status" })),
                React.createElement("div", { className: "status" }, teamInformation === null ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 24 }) : React.createElement(React.Fragment, null, getStatusText()))),
            React.createElement("div", { className: "activity-stats" },
                React.createElement("ul", null, teamInformation !== null && teamInformation.LastActivity !== null ? (React.createElement("li", null,
                    React.createElement("div", { className: "title" },
                        React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.property.last_activity", defaultMessage: "Last Activity" })),
                    React.createElement("div", { className: "stat-value" }, teamInformation === null ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 24 }) : React.createElement(React.Fragment, null, moment.utc(teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.LastActivity).format("LL"))))) : null)))));
};
