var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TenantCurrentActionTypes } from "./types";
import Store from "app/store";
export function Load(tenant) {
    if (tenant === undefined)
        tenant = {};
    //console.log("Load Current Template > ", tenant)
    return {
        type: TenantCurrentActionTypes.Tenant_Current_Load,
        payload: tenant
    };
}
export function LoadWithInfo(guid) {
    var tenant = {};
    if (guid !== undefined) {
        tenant.GUID = guid;
    }
    return {
        type: TenantCurrentActionTypes.Tenant_Current_Load_With_Info,
        payload: tenant
    };
}
export function Update(tenant) {
    return {
        type: TenantCurrentActionTypes.Tenant_Current_Update,
        payload: tenant
    };
}
export function UpdateInformation(TenantInformation) {
    var item = Store.getState().tenantCurrentState.item;
    var tenantInformation = item.TenantInformation;
    var tempPayload = __assign(__assign({}, item), { TenantInformation: __assign(__assign({}, tenantInformation), TenantInformation) });
    //const tempPayload = {...item, TenantInformation: Object.assign({}, tenantInformation, TenantInformation)}
    return {
        type: TenantCurrentActionTypes.Tenant_Current_Update,
        payload: tempPayload
    };
}
export function UpdateSilent(tenant) {
    var t = {};
    if (tenant !== undefined) {
        t = __assign({}, tenant);
    }
    else {
        var item = Store.getState().tenantCurrentState.item;
        t = __assign({}, item);
    }
    return {
        type: TenantCurrentActionTypes.Tenant_Current_Silent_Update,
        payload: t
    };
}
export function Clear() {
    return {
        type: TenantCurrentActionTypes.Tenant_Current_Clear
    };
}
export var tenantCurrentMiddlware = function (store, next, action) {
    next(action);
};
