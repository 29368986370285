import { Card, Group } from "@mantine/core";
import { OrchestryDonutChart } from "appv2/components/orchestry-donut-chart/orchestry-donut-chart.component";
import React from "react";
import { GrowthDonutChartTileLegend } from "./growth-donut-chart-tile-legend.component";
import "./growth-donut-chart-tile.scss";
import { useGrowthDonutChartTile } from "./use-growth-donut-chart-tile";
export var GrowthDonutChartTile = function () {
    var _a = useGrowthDonutChartTile(), intl = _a.intl, isMobile = _a.isMobile, total = _a.total, isLoading = _a.isLoading, teamsValues = _a.teamsValues, privateChannelsValues = _a.privateChannelsValues, teamSitesValues = _a.teamSitesValues, communicationSitesValues = _a.communicationSitesValues, classicSitesValues = _a.classicSitesValues, vivaEngageValues = _a.vivaEngageValues, oneDriveSpacesValues = _a.oneDriveSpacesValues, donutChartData = _a.donutChartData;
    return (React.createElement(Card, { p: 24, miw: isMobile ? undefined : 489, variant: "default", className: "health-check-growth-donut-chart-tile", style: { overflow: "visible" } },
        React.createElement(Group, { gap: 0, justify: "space-around" },
            React.createElement(OrchestryDonutChart, { formatNumberAndText: true, data: [
                    {
                        name: "Teams",
                        value: teamsValues !== null && teamsValues !== void 0 ? teamsValues : 0,
                        color: "#4E56BF"
                    },
                    {
                        name: "Private Channels",
                        value: privateChannelsValues !== null && privateChannelsValues !== void 0 ? privateChannelsValues : 0,
                        color: "#757DE0"
                    },
                    {
                        name: "Teams Sites",
                        value: teamSitesValues !== null && teamSitesValues !== void 0 ? teamSitesValues : 0,
                        color: "#037F83"
                    },
                    {
                        name: "Comm Sites",
                        value: communicationSitesValues !== null && communicationSitesValues !== void 0 ? communicationSitesValues : 0,
                        color: "#26B0BC"
                    },
                    {
                        name: "Classic Sites",
                        value: classicSitesValues !== null && classicSitesValues !== void 0 ? classicSitesValues : 0,
                        color: "#85BFC2"
                    },
                    {
                        name: "Viva Engage",
                        value: vivaEngageValues !== null && vivaEngageValues !== void 0 ? vivaEngageValues : 0,
                        color: "var(--mantine-color-blue-5)"
                    },
                    {
                        name: "OneDrive Spaces",
                        value: oneDriveSpacesValues !== null && oneDriveSpacesValues !== void 0 ? oneDriveSpacesValues : 0,
                        color: "#0364B8"
                    }
                ], w: 180, h: 180, thickness: 16, chartLabel: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.label", defaultMessage: "{count} Total" }, { count: total.toLocaleString() }), mx: "auto", lastPeriodData: donutChartData !== null && donutChartData !== void 0 ? donutChartData : [], isLoading: isLoading }),
            React.createElement(GrowthDonutChartTileLegend, null))));
};
