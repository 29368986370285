import { Card, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { CardKeyValue } from "../card-key-value.component";
import { OrchestryCardTitle } from "../orchestry-card-title.component";
import { useTenantPage } from "../use-tenant-page.hook";
export var App = function () {
    var _a;
    var _b = useTenantPage(), _c = _b.tenantDetails, Version = _c.Version, OrchestryAppVersion = _c.OrchestryAppVersion, ServiceAppVersion = _c.ServiceAppVersion, ServiceAppReadAllVersion = _c.ServiceAppReadAllVersion, isPageLoading = _b.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, mih: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "App", icon: ORCH_ICONS.zap, colorVariant: "primary-7" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Version", value: Version === null || Version === void 0 ? void 0 : Version.toString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Orchestry App Version", value: OrchestryAppVersion === null || OrchestryAppVersion === void 0 ? void 0 : OrchestryAppVersion.toString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Service App Version", value: (_a = ServiceAppVersion === null || ServiceAppVersion === void 0 ? void 0 : ServiceAppVersion.toString()) !== null && _a !== void 0 ? _a : ServiceAppReadAllVersion === null || ServiceAppReadAllVersion === void 0 ? void 0 : ServiceAppReadAllVersion.toString() }))));
};
