import axios from "axios";
var LifecycleService = /** @class */ (function () {
    function LifecycleService() {
    }
    LifecycleService.currentReportSearches = [];
    LifecycleService.LatestLogItem = null;
    LifecycleService.InstallStatusDelegateUsers = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/InstallStatusDelegateUsers";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleService.InstallStatusPermissions = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/InstallStatusPermissions";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleService.GetETLLogs = function (top) {
        if (top === void 0) { top = 10; }
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/ETLLogs?Top=".concat(top);
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleService.ETLPostInstallStatus = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/ETLPostInstallStatus";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleService.GetLatestCompleteETLLog = function () {
        return new Promise(function (resolve, reject) {
            if (LifecycleService.LatestLogItem != null) {
                resolve(LifecycleService.LatestLogItem);
            }
            else {
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/LifecycleSettings/ETLLatest";
                axiosConfig.responseType = "json";
                axios(axiosConfig)
                    .then(function (response) {
                    var items = response.data;
                    if (items.length > 0) {
                        LifecycleService.LatestLogItem = response.data[0];
                        resolve(response.data[0]);
                    }
                    else {
                        LifecycleService.LatestLogItem = null;
                        resolve(null);
                    }
                })
                    .catch(function (response) {
                    reject(response);
                });
            }
        });
    };
    // public static GetWorkspaceArchivalLogs = (siteId:string):Promise<ArchivalLogFile[]>  => {
    //    return new Promise((resolve, reject) => {
    //       // First let's configure the ajax request
    //       let axiosConfig:AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET"
    //       axiosConfig.url = "/api/LifecycleReports/ArchivalLogs?siteId=" + siteId;
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //             .then((response:AxiosResponse) => {
    //                   resolve(response.data as ArchivalLogFile[]);
    //             })
    //             .catch((response:any) => {
    //                   //console.log("Axios Response");
    //                   //reject(response);
    //             });
    //    });
    // }
    LifecycleService.GetWorkspaceArchivalLogFile = function (siteId, fileName) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/ArchivalLogFile?siteId=" + siteId + "&fileName=" + fileName;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetTenantsETLStatus = function (region) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/GetEtlStatusForAllTenants?region=" + region;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetTenantDetailedETLStatus = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/GetEtlStatusDetails?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetETLTimeline = function (region) {
        if (region === void 0) { region = "US"; }
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/GetETLTimeline?region=" + region;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.ForceETLForTenant = function (tenantGuid, forceMode) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/ForceETLForTenant?tenantGuid=" + tenantGuid + "&forceCrawlMode=" + forceMode;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.ClearDeltasForTenant = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/ClearDeltas?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.ForceDataAnalysis = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/ForceDataAnalysis?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetLifecycleQueue = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/LifecycleQueue";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetLifecycleDashboardData = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleDashboard";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkExecuteArchivalPolicies = function (siteIds) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkExecute";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(siteIds));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkAssignArchivalPolicies = function (policies) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkAssign";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(policies));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkResetWorkspaces = function (policies) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkReset";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(policies));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetBulkAssignStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkAssignStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.GetBulkResetStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkResetStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkRemediationInitialStatus = function (siteIds) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkWorkspaceRemediationInitialStatus";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(siteIds));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkRemediationRestorationStartJob = function (remediateStatusesToStart) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkWorkspaceRemediationStartRestoration";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(remediateStatusesToStart));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkGetRemediationRestorationJobStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkWorkspaceRemediationRestorationJobStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkRemediationPreviousArchivalDocumentStats = function (siteIds) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkWorkspaceRemediationPreviousArchivalDocumentStats";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(siteIds));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkRemediationExecuteArchivalStatusUpdate = function (siteIds) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkWorkspaceRemediationExecuteStatusUpdate";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(siteIds));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.BulkRemediationExecuteArchivalPolicyEnforcement = function (siteIds) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkWorkspaceRemediationExecutePolicyEnforcement";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(siteIds));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LifecycleService.SearchForNonActiveWorkspace = function (searchTerms) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (LifecycleService.currentReportSearches.length > 0) {
                LifecycleService.currentReportSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerms.length !== 0) {
                LifecycleService.currentReportSearches.push({
                    id: LifecycleService.currentReportSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/LifecycleSettings/SearchForWorkspaceToResetStatus?searchTerms=" + searchTerms;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    LifecycleService.ResetWorkspacePolicyToActive = function (siteId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/ResetToActive?siteId=" + siteId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return LifecycleService;
}());
export default LifecycleService;
