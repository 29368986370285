var _a;
import { Group, Stack } from "@mantine/core";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import { OrchestryBrandingLogo } from "appv2/components/orchestry-branding-logo/orchestry-branding-logo.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryTabs } from "appv2/components/orchestry-tabs/orchestry-tabs.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { HealthCheckStatusPill } from "../../health-checks/health-check-status-pill.component";
import { HealthCheckActivityTab } from "../tabs/health-check-activity-tab/health-check-activity-tab.component";
import { HealthCheckAdditionsTab } from "../tabs/health-check-additions-tab/health-check-additions-tab.component";
import { HealthCheckEditPill } from "./health-check-edit-pill.component";
import { HealthCheckFooter } from "./health-check-footer.component";
import { HealthCheckNotFoundPage } from "./health-check-not-found-page.component";
import "./health-check-page.scss";
import { HealthCheckPages } from "./health-check-pages.type";
import { useHealthCheckPage } from "./use-health-check-page.hook";
var TAB_TO_PAGE_MAPPING = (_a = {},
    _a[HealthCheckPages.Activity] = function () { return React.createElement(HealthCheckActivityTab, null); },
    _a[HealthCheckPages.Additions] = function () { return React.createElement(HealthCheckAdditionsTab, null); },
    _a);
export var HealthCheckPage = function () {
    var _a = useHealthCheckPage(), intl = _a.intl, pageTitle = _a.pageTitle, subtitle = _a.subtitle, onBackButtonClicked = _a.onBackButtonClicked, tabs = _a.tabs, tab = _a.tab, tabsOnChange = _a.tabsOnChange, onShareClicked = _a.onShareClicked, onDownloadClicked = _a.onDownloadClicked, onEditClicked = _a.onEditClicked, onPublishClicked = _a.onPublishClicked, startDate = _a.startDate, endDate = _a.endDate, tenantName = _a.tenantName, isLoading = _a.isLoading, title = _a.title, editButtonText = _a.editButtonText, shouldShowTabs = _a.shouldShowTabs, shouldShowActionButtons = _a.shouldShowActionButtons, isPublishButtonDisabled = _a.isPublishButtonDisabled, isPublishButtonLoading = _a.isPublishButtonLoading, isEditButtonDisabled = _a.isEditButtonDisabled, showPublishButton = _a.showPublishButton, disableShareButton = _a.disableShareButton, disableDownloadButton = _a.disableDownloadButton, downloadToolTipText = _a.downloadToolTipText, healthCheckBrandingImage = _a.healthCheckBrandingImage, partnerBrandingImage = _a.partnerBrandingImage, isLogo = _a.isLogo, shouldShowMutativeButtons = _a.shouldShowMutativeButtons, showHealthCheckNotFoundPage = _a.showHealthCheckNotFoundPage, userCanShare = _a.userCanShare, status = _a.status, isInEditMode = _a.isInEditMode, totalAnnotationCount = _a.totalAnnotationCount, isAnnotationLoading = _a.isAnnotationLoading, isGeneralAnnotationLoading = _a.isGeneralAnnotationLoading;
    if (showHealthCheckNotFoundPage) {
        return React.createElement(HealthCheckNotFoundPage, null);
    }
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "health-check-page" },
                React.createElement(Group, { justify: "space-between", align: isLogo ? "flex-start" : "flex-end" },
                    React.createElement(Stack, { gap: 4 },
                        React.createElement(Subtitle, { title: pageTitle, subtitle: subtitle, isLoading: isLoading },
                            React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, intl.formatMessage({
                                id: "healthcheck.go_back_to_health_checks",
                                defaultMessage: "Go Back to Health Checks"
                            }))),
                        React.createElement(OrchestryPageTitle, { isLoading: isLoading, title: title, titleSuffix: isInEditMode ? React.createElement(HealthCheckEditPill, { isLoading: isLoading }) : React.createElement(HealthCheckStatusPill, { status: status !== null && status !== void 0 ? status : "", isLoading: isLoading }) },
                            React.createElement(Group, { gap: 4 },
                                React.createElement(Group, { gap: 4 },
                                    React.createElement(OrchestryText, { skeletonWidth: 24, isLoading: isLoading, c: "text-primary.0", size: "16px", fw: 400, lh: "24px" }, intl.formatMessage({ id: "pages.health_check.for_prefix", defaultMessage: "For" })),
                                    React.createElement(OrchestryFontIcon, { isLoading: isLoading, iconClassName: ORCH_ICONS.building06, color: "var(--mantine-color-primary-7)", fontSize: 18, lineHeight: "18px" }),
                                    React.createElement(OrchestryText, { c: "var(--mantine-color-primary-7)", fw: "700", size: "16px", lh: "24px", isLoading: isLoading, skeletonWidth: 60 }, tenantName)),
                                React.createElement(OrchestryText, { skeletonWidth: 300, isLoading: isLoading, c: "text-primary.0", size: "16px", fw: 400, lh: "24px" }, intl.formatMessage({ id: "from_to", defaultMessage: "from {startDate} to {endDate}" }, { startDate: startDate, endDate: endDate }))))),
                    React.createElement(Stack, { gap: 14 },
                        React.createElement(Group, { gap: 20, justify: "flex-end" },
                            React.createElement(OrchestryBrandingLogo, { base64Value: partnerBrandingImage, imageAlt: "partner-logo" }),
                            React.createElement(OrchestryBrandingLogo, { base64Value: healthCheckBrandingImage, imageAlt: "customer-logo" })),
                        shouldShowActionButtons && (React.createElement(OrchestryActionButtons, null,
                            shouldShowMutativeButtons && userCanShare && (React.createElement(OrchestryButton, { disabled: disableShareButton, onClick: onShareClicked, isLoading: isLoading, skeletonHeight: 40, skeletonWidth: 100, iconType: "font", size: "md", iconLeft: ORCH_ICONS.share06, variant: "secondary-gray" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "controls.share", defaultMessage: "Share" }))),
                            React.createElement(OrchestryTooltip, { label: downloadToolTipText, multiline: true, w: 375 },
                                React.createElement(OrchestryButton, { disabled: disableDownloadButton, disabledStyle: disableDownloadButton, onClick: onDownloadClicked, isLoading: isLoading, skeletonHeight: 40, skeletonWidth: 100, iconType: "font", size: "md", iconLeft: ORCH_ICONS.download02, variant: "secondary-gray" }, intl.formatMessage({ id: "controls.download", defaultMessage: "Download" }))),
                            shouldShowMutativeButtons && (React.createElement(React.Fragment, null,
                                React.createElement(OrchestryButton, { disabled: isEditButtonDisabled, key: editButtonText, onClick: onEditClicked, isLoading: isLoading, skeletonHeight: 40, skeletonWidth: 100, iconType: "font", size: "md", iconLeft: ORCH_ICONS.edit02, variant: "secondary-color" }, editButtonText),
                                showPublishButton && (React.createElement(OrchestryButton, { disabled: isPublishButtonDisabled, onClick: onPublishClicked, isLoading: isPublishButtonLoading, skeletonHeight: 40, skeletonWidth: 100, iconType: "font", size: "md", iconLeft: ORCH_ICONS.upload03, variant: "primary" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "controls.publish", defaultMessage: "Publish" }))))))))),
                React.createElement(Group, { mt: 24, justify: shouldShowTabs ? "space-between" : "right" },
                    shouldShowTabs && React.createElement(OrchestryTabs, { tabCountVariant: "error-0", isLoading: isLoading || isGeneralAnnotationLoading, tabs: tabs, tab: tab, showCount: true, onTabChange: tabsOnChange }),
                    React.createElement(Group, { gap: 8 },
                        React.createElement(OrchestryFontIcon, { isLoading: isAnnotationLoading, iconClassName: ORCH_ICONS.messageNotificationCircle, color: "var(--mantine-color-error-7)", fontSize: 24, lineHeight: "24px" }),
                        React.createElement(OrchestryText, { skeletonWidth: 300, isLoading: isAnnotationLoading, c: "var(--mantine-color-error-7)", size: "14px", fw: 500, lh: "38px" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "pages.health_check.annotation_count", defaultMessage: "{count} Annotations in this Health Check" }, { count: totalAnnotationCount })))),
                TAB_TO_PAGE_MAPPING[tab !== null && tab !== void 0 ? tab : HealthCheckPages.Activity](),
                React.createElement(HealthCheckFooter, { isLoading: isLoading })))));
};
