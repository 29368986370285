import axios from "axios";
var GuestService = /** @class */ (function () {
    function GuestService() {
    }
    GuestService.currentReportSearches = [];
    GuestService.GetGuestsDashboardData = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/GuestsDashboard";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.ForceGuestReview = function (siteId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleGuest/ForceReview?siteId=".concat(siteId);
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.BulkSubmitGuestRequestApprovals = function (requestActions, groupId, approvalUserId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecycleGuest/BulkSubmitGuestRequestApprovals?groupId=".concat(groupId, "&approvalUserId=").concat(approvalUserId);
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(requestActions));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.GetSubmitGuestRequestApprovalsStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleGuest/BulkSubmitGuestRequestApprovalsStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.BulkSubmitGuestReviewApprovals = function (requestActions, groupId, approvalUserId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecycleGuest/BulkSubmitGuestReviewApprovals?groupId=".concat(groupId, "&approvalUserId=").concat(approvalUserId);
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(requestActions));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.GetSubmitGuestReviewApprovalsStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleGuest/BulkSubmitGuestReviewApprovalsStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.IsUserPolicyApprover = function (siteId, policyType) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleGuestPolicies/IsUserPolicyApprover?siteId=".concat(siteId, "&policyType=").concat(policyType);
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.BulkAssignGuestPolicies = function (policies) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkAssignGuestPolicies";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(policies));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) { });
        });
    };
    GuestService.BulkExecuteGuestPolicies = function (siteIds) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkExecuteGuestPolicies";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(siteIds));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) { });
        });
    };
    GuestService.GetBulkGuestAssignStatus = function (jobId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/BulkAssignStatus?JobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.GetGuestsForGroup = function (groupId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleGuest/GuestUsers?groupId=" + groupId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.GetBlockedDomains = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Guests/BlockedDomains";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    GuestService.AddGuestsInGroupWithRequestPolicy = function (payload) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/LifecycleGuest/AddGuestsInGroupWithRequestPolicy";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify(payload));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return GuestService;
}());
export default GuestService;
