import { RequestCurrentActionTypes } from "app/store/requests/current/types";
import { AsyncCallType } from "app/store/async/types";
import { RequestListActionTypes } from "app/store/requests/list/types";
export var requestCurrentActionMap = [
    {
        actionType: RequestCurrentActionTypes.Request_Current_Load,
        callType: AsyncCallType.Get,
        // stateLocationForArray: "requestListState.items",
        keyType: "guid",
        ApiUrl: "/api/Requests/?guid={key}",
        responseDataType: {}
    },
    {
        actionType: RequestCurrentActionTypes.Request_Current_Add,
        callType: AsyncCallType.AddGet,
        keyType: "guid",
        ApiUrl: "/api/Requests/AddGet",
        responseDataType: {},
        invalidates: [RequestListActionTypes.Request_List_Load]
    },
    {
        actionType: RequestCurrentActionTypes.Request_Current_Refresh,
        callType: AsyncCallType.Refresh,
        // stateLocationForArray: "requestListState.items",
        keyType: "guid",
        ApiUrl: "/api/Requests/?guid={key}",
        responseDataType: {}
    },
    {
        actionType: RequestCurrentActionTypes.Request_Current_Update_Status,
        callType: AsyncCallType.Post,
        keyType: "guid",
        ApiUrl: "/api/RequestsWorkflow/UpdateRequestStatus",
        responseDataType: {},
        invalidates: [RequestListActionTypes.Request_List_Load]
    }
];
