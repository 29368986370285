import axios from "axios";
var TenantDatabaseService = /** @class */ (function () {
    function TenantDatabaseService() {
    }
    TenantDatabaseService.getTenantDatabases = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantDatabases/Page";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantDatabaseService.getTenantDatabaseDetails = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantDatabases/TenantDbDetails?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantDatabaseService.startMigration = function (tenantDbInfo, destinationSource) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantDatabases/StartTenantDbMigration?tenantGuid=" + tenantDbInfo.TenantGuid + "&destinationSource=" + destinationSource;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    TenantDatabaseService.getMigrationStatus = function (tenantGuid, jobId) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/tenantDatabases/GetTenantDbMigrationStatus?tenantGuid=" + tenantGuid + "&jobId=" + jobId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return TenantDatabaseService;
}());
export default TenantDatabaseService;
