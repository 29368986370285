import { Skeleton } from "@mantine/core";
import React from "react";
export var OrchestryCircle = function (_a) {
    var width = _a.width, color = _a.color, isLoading = _a.isLoading;
    if (isLoading) {
        return React.createElement(Skeleton, { circle: true, width: width, height: width });
    }
    var style = {
        width: "".concat(width, "px"),
        height: "".concat(width, "px"),
        borderRadius: "50%",
        backgroundColor: color
    };
    return React.createElement("div", { style: style });
};
