var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./form-checkarray.scss";
export default function FormCheckArray(props) {
    var _a = React.useState(__spreadArray([], props.defaultItems.map(function (item) {
        var _a;
        var newItem = __assign({}, item);
        (_a = props.currentItems) === null || _a === void 0 ? void 0 : _a.map(function (currentItem) {
            if (item.id === currentItem.id) {
                newItem = __assign(__assign({}, newItem), { isChecked: currentItem.isChecked });
            }
        });
        return newItem;
    }), true) || []), arrayState = _a[0], setArrayState = _a[1];
    var handleChange = function (event) {
        event.preventDefault();
        var target = event.target;
        var newArrayState = arrayState.map(function (item) {
            var _a;
            if (item.id === ((_a = target.parentElement) === null || _a === void 0 ? void 0 : _a.id)) {
                return __assign(__assign({}, item), { isChecked: !item.isChecked });
            }
            else {
                return item;
            }
        });
        setArrayState(newArrayState);
        props.onChange(newArrayState);
    };
    return (React.createElement("div", { className: "check-container", "data-pw": "check-container", style: props.style }, arrayState.map(function (item, index) { return (React.createElement("div", { key: index, className: "array-item" },
        React.createElement("div", { className: "checkbox-container ".concat(item.isDefault ? "default" : "").trim(), id: item.id, title: "".concat(item.isDefault ? "Default: " : "", " ").concat(item.label).trim() },
            React.createElement("div", { className: "label" }, item.label),
            React.createElement("div", { id: item.id, className: "box ".concat(item.isChecked ? "checked" : "").trim() }, item.isChecked || item.isDefault ? React.createElement(FontAwesomeIcon, { className: "check-mark", icon: faCheck }) : null),
            React.createElement("div", { onClick: function (e) { return handleChange(e); }, className: "click-div" })))); })));
}
