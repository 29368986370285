import * as React from "react";
import { Form, Row, Col } from "react-bootstrap";
import InfoToolTip from "app/components/common/infotooltip";
import { useDispatch } from "react-redux";
import * as LibraryTemplateCurrentStore from "app/store/library-templates/current";
var TabSecurity = React.memo(function (props, state) {
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "security-tab" }, props.currentLibraryTemplate.TemplateType !== "SharePoint" ? (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h2", null, "Teams Group Permissions"))),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 6 },
                React.createElement("h3", null, "Teams Member Permissions"),
                React.createElement("div", null,
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox6", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow creating and updating channels",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowMemberCreatingAndUpdatingChannels, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowMemberCreatingAndUpdatingChannels: event.target.checked })); } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox21", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow members to create private channels",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowMemberCreatePrivateChannels, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowMemberCreatePrivateChannels: event.target.checked })); } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox7", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow members to delete and restore channels",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowMembersToDeleteAndRestoreChannels, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowMembersToDeleteAndRestoreChannels: event.target.checked })); } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox8", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow members to add and remove apps",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowMembersToAddAndRemoveApps, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowMembersToAddAndRemoveApps: event.target.checked })); } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox10", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow members to create, update, and remove tabs",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowMembersToCreateUpdateAndRemoveTabs, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowMembersToCreateUpdateAndRemoveTabs: event.target.checked })); } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox11", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow members to create, update, and remove connectors",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowMembersToCreateUpdateAndRemoveConnectors, onChange: function (event) {
                                return dispatch(LibraryTemplateCurrentStore.Update({ AllowMembersToCreateUpdateAndRemoveConnectors: event.target.checked }));
                            } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox12", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Give members the option to delete their messages",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.GiveMembersTheOptionToDeleteTheirMessages, onChange: function (event) {
                                return dispatch(LibraryTemplateCurrentStore.Update({ GiveMembersTheOptionToDeleteTheirMessages: event.target.checked }));
                            } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckboxEdit", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Give members the option to edit their messages",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.GiveMembersTheOptionToEditTheirMessages, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ GiveMembersTheOptionToEditTheirMessages: event.target.checked })); } })))),
            React.createElement(Col, { lg: 6, className: "tg-right" },
                React.createElement("h3", null, "Teams Guest Permissions"),
                React.createElement("div", null,
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox15", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow creating and updating channels",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowGuestCreatingAndUpdatingChannels, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowGuestCreatingAndUpdatingChannels: event.target.checked })); } })),
                    React.createElement(Form.Group, { controlId: "formBasicCheckbox16", className: "form-group checkbox-green" },
                        React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null,
                                "Allow guests to delete channels",
                                React.createElement(InfoToolTip, null)), defaultChecked: props.currentLibraryTemplate.AllowGuestsToDeleteChannels, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowGuestsToDeleteChannels: event.target.checked })); } }))))))) : null));
});
export default TabSecurity;
