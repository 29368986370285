import LocalSessionService from "app/services/local-session";

const localStorageLastLocationKey = "orchestry-last-location";

function acceptLocation(lastLocation) {
   if (
      lastLocation !== undefined &&
      lastLocation !== null &&
      lastLocation.pathname !== undefined &&
      lastLocation.pathname !== null &&
      //lastLocation.pathname !== "/" &&
      lastLocation.pathname.indexOf("auth") === -1 &&
      lastLocation.pathname !== "/logout"
   ) {
      return true;
   }

   return false;
}

export function saveLastLocation(lastLocation) {
   //console.log("Save Last Location", lastLocation);

   if (lastLocation !== undefined && lastLocation !== null) {
      if (acceptLocation(lastLocation)) {
         LocalSessionService.SetObject(localStorageLastLocationKey, lastLocation);
         //console.log("Set last Location")
      }
   }
}

export function forgotLastLocation() {
   LocalSessionService.Remove(localStorageLastLocationKey);
   //console.log("Remove last Location");
}

export function getLastLocation() {
   if (LocalSessionService.KeyExists(localStorageLastLocationKey)) {
      const _userLocation = LocalSessionService.GetObject(localStorageLastLocationKey);
      return _userLocation.pathname;
   } else {
      return "/";
   }
}
