var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LayoutSubheaderConfig } from "_metronic/layout/LayoutContext";
import EmptyCheck from "app/components/common/empty-check";
import * as React from "react";
import { Button } from "react-bootstrap";
import AnimatedLoader from "../animated-loader";
import "./subtitle.scss";
var Subtitle = React.memo(function (props) {
    if (props.title === undefined) {
        return (
        //@ts-ignore
        React.createElement(LayoutSubheaderConfig, { description: props.isLoading ? undefined : props.subtitle, useEffectItem: props.useEffectItem }, props.children));
    }
    else {
        return (
        //@ts-ignore
        React.createElement(LayoutSubheaderConfig, { newtitle: props.isLoading ? undefined : props.title, description: props.isLoading ? undefined : props.subtitle, useEffectItem: props.useEffectItem }, props.children));
    }
});
export default Subtitle;
export var SubtitleButton = React.memo(function (props) {
    var buttonProps = {
        active: props.active,
        block: props.block,
        variant: props.variant,
        size: props.size,
        type: props.type,
        href: props.href,
        disabled: props.disabled,
        onClick: props.onClick
    };
    var colorClass = "";
    if (props.color !== undefined) {
        switch (props.color) {
            case "orange": {
                colorClass += "color-orange";
                break;
            }
            case "brandblue": {
                colorClass += "color-brandblue";
                break;
            }
            case "outline-gray": {
                colorClass += "color-outline-gray";
                break;
            }
        }
    }
    return (React.createElement(Button, __assign({}, buttonProps, { className: "subtitle-button " + (props.noMargin !== undefined && props.noMargin === true ? "" : "kt-margin-l-10 ") + colorClass + (props.className !== undefined ? " " + props.className : ""), title: props.altText !== undefined ? props.altText : undefined }),
        React.createElement(EmptyCheck, { content: props.faIcon },
            React.createElement("i", { className: "fa fa-" + props.faIcon })),
        React.createElement(EmptyCheck, { content: props.laIcon },
            React.createElement("i", { className: "la la-" + props.laIcon })),
        React.createElement(EmptyCheck, { content: props.customIcon },
            React.createElement("i", { className: props.customIcon })),
        props.showLoader !== undefined && props.showLoader !== null && props.showLoader === true ? (React.createElement("div", { className: "animated-loader-shown" },
            React.createElement(AnimatedLoader, { size: "xxsmall" }))) : null,
        props.title !== undefined && props.title !== null && props.title.toLowerCase().indexOf("save") > -1 && props.variant !== undefined && props.variant === "light" ? (React.createElement(React.Fragment, null,
            React.createElement("i", { className: "fas fa-save", style: { fontSize: "20px !important", opacity: 0.5 } }),
            React.createElement(EmptyCheck, { content: props.title, returnContent: true }))) : props.title !== undefined && props.title !== null && props.title.toLowerCase().indexOf("save") > -1 && props.variant !== undefined && props.variant === "success" ? (React.createElement(React.Fragment, null,
            React.createElement("i", { className: "fas fa-save", style: { fontSize: "20px !important" } }),
            React.createElement("strong", { style: { fontWeight: 500 } },
                React.createElement(EmptyCheck, { content: props.title, returnContent: true })))) : (React.createElement(EmptyCheck, { content: props.title, returnContent: true })),
        props.children)
    // <div className={"subtitle-button btn btn-elevate kt-margin-l-10 " + colorClass}><EmptyCheck content={props.title} returnContent={true} /></div>
    // <button type="button" className="btn kt-subheader__btn-primary">
    //     Actions
    //     {/* <SortNum1Icon className="kt-svg-icon kt-svg-icon--sm" /> */}
    // </button>
    );
});
