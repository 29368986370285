import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { OrchestryNoRecordsFound } from "appv2/components/orchestry-no-records-found/orchestry-no-records-found.component";
import React from "react";
import { RecommendationListNoResult } from "./recommendations-no-results.component";
export var RecommendationsNoResults = function (_a) {
    var searchString = _a.searchString, currentTab = _a.currentTab;
    if (searchString) {
        return React.createElement(OrchestryNoRecordsFound, null);
    }
    switch (currentTab) {
        case RecommendationRecordVisibility.Visible:
            return (React.createElement(RecommendationListNoResult, { view: "open", header: "No Open Recommendations", icon: ORCH_ICONS.heartHexagon, body: "All caught up! You currently don't have any open recommendations. As you\r\n         progress, relevant suggestions will appear here.", suggestOnClickHandler: function () { return openOrchestryModal("recommendationShareSuggestionModal", {}, "md"); } }));
        case RecommendationRecordVisibility.Snoozed:
            return (React.createElement(RecommendationListNoResult, { view: "snoozed", header: "No Snoozed Recommendations", icon: ORCH_ICONS.clockSnooze, body: "Nothing on Hold! You haven't snoozed any recommendations yet. When you\r\n               do, they'll appear here for easy access." }));
        case RecommendationRecordVisibility.Resolved:
            return (React.createElement(RecommendationListNoResult, { view: "resolved", header: "No Resolved Recommendations", icon: ORCH_ICONS.checkVerified03, body: "Clean Slate! Once you start resolving recommendations, you'll see a list of\r\n               everything that is currently resolved." }));
        default:
            return React.createElement("div", null);
    }
};
