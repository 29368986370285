import * as React from "react";
//import './home-admin.scss';
import InstallerWizard, { InstallerMode } from "app/components/installer/installer-wizard";
import getParameterByName from "app/utils/getQueryStringParam";
var HomeInstaller = React.memo(function (props) {
    var _a = React.useState(false), ForceInstall = _a[0], setForceInstall = _a[1];
    React.useEffect(function () {
        var forceQS = getParameterByName("forced");
        if (forceQS !== undefined && forceQS !== null) {
            setForceInstall(true);
        }
    }, []);
    return (React.createElement("div", { className: "home-installer fadein-500ms", style: { padding: "40px" } },
        React.createElement(InstallerWizard, { force: ForceInstall, mode: InstallerMode.Install })));
});
export default HomeInstaller;
