import InstallerWizard from "app/components/installer/installer-wizard";
import { ServiceAppPermissionConfiguration } from "app/store/tenants";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./orchestry-check-upgrade.scss";
var OrchestryCheckUpgrade = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(props.forceOpen !== undefined ? props.forceOpen : false), showUpdateBox = _a[0], setShowUpgradeBox = _a[1];
    var _b = React.useState(props.forceUpdate !== undefined ? props.forceUpdate : false), forceUpdate = _b[0], setForceUpdate = _b[1];
    React.useEffect(function () {
        if (orchestryContext.IsCurrentUserAzureGlobalAdmin &&
            (orchestryContext.LastInstalledVersion === null ||
                Number(orchestryContext.LastInstalledVersion) < orchestryContext.OrchestryAppInstallMinUpdaterVersion ||
                orchestryContext.LastConsentedVersion === null ||
                Number(orchestryContext.LastConsentedVersion) < orchestryContext.OrchestryAppPermissionMinUpdaterVersion ||
                (orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadWriteAll &&
                    (orchestryContext.ServiceAppLastConsentedVersion === null || Number(orchestryContext.ServiceAppLastConsentedVersion) < orchestryContext.ServiceAppPermissionMinUpdaterVersion)) ||
                (orchestryContext.ServiceAppPermissionConfig == ServiceAppPermissionConfiguration.OrchestryReadAll &&
                    (orchestryContext.ServiceAppReadAllLastConsentedVersion === null ||
                        Number(orchestryContext.ServiceAppReadAllLastConsentedVersion) < orchestryContext.ServiceAppReadAllPermissionMinUpdaterVersion)))) {
            //if(orchestryContext.IsCurrentUserAzureGlobalAdmin && orchestryContext.IsUpToDate === false){
            setShowUpgradeBox(true);
        }
    }, [orchestryContext]);
    React.useEffect(function () {
        if (props.overrideShow !== undefined) {
            setShowUpgradeBox(props.overrideShow);
        }
    }, [props.overrideShow]);
    var onHide = function () {
        if (props.setOverrideShow !== undefined) {
            props.setOverrideShow(props.overrideShow === undefined ? !showUpdateBox : !props.overrideShow);
        }
        else {
            setShowUpgradeBox(!showUpdateBox);
        }
        if (props.onClose !== undefined) {
            props.onClose();
        }
    };
    if (showUpdateBox) {
        return (React.createElement("div", { className: "orchestry-check-upgrade" },
            React.createElement(Modal, { size: "lg", centered: true, show: showUpdateBox, onHide: function () { return onHide(); }, animation: true, className: "upload-modal documents-modal" },
                React.createElement(InstallerWizard, { mode: props.installMode, force: forceUpdate, onClose: function () { return onHide(); } }))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
export default OrchestryCheckUpgrade;
