import * as React from "react";
import "./library-template-tabs.scss";
import { Nav, Tab } from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";
import TabDetails from "app/components/orchestry-admin/library/library-templates/library-template-tabs/tabs/details";
import TabFeatures from "app/components/orchestry-admin/library/library-templates/library-template-tabs/tabs/features";
import TabChannels from "app/components/orchestry-admin/library/library-templates/library-template-tabs/tabs/channels";
import TabDocuments from "app/components/orchestry-admin/library/library-templates/library-template-tabs/tabs/documents";
import TabSecurity from "app/components/orchestry-admin/library/library-templates/library-template-tabs/tabs/security";
import { useSelector, useDispatch } from "react-redux";
import * as LibraryTemplateCurrentStore from "app/store/library-templates/current";
import * as LibraryTemplateListStore from "app/store/library-templates/list";
import generateGuid from "app/utils/generateGuid";
import Async from "app/components/common/async";
import * as AsyncTypes from "app/store/async";
import AnimatedLoader from "app/components/common/animated-loader";
import { SubtitleButton } from "app/components/common/subtitle";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import Store from "app/store";
import FormValidationContext from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
var LibraryTemplateTabs = React.memo(function (props) {
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "details"), hash = _b[0], setHash = _b[1];
    var dispatch = useDispatch();
    var _c = React.useState(props.guid), lookupGuid = _c[0], setLookupGuid = _c[1];
    var currentLibraryTemplate = useSelector(function (state) { return state.libraryTemplateCurrentState.item; });
    var listLibraryTemplates = useSelector(function (state) { return state.libraryTemplateListState.items; });
    var _d = React.useState(false), hasAdded = _d[0], setHasAdded = _d[1];
    var _e = React.useState(false), formValid = _e[0], setFormValid = _e[1];
    var _f = React.useState(false), hasSubmitted = _f[0], setHasSubmitted = _f[1];
    //const currentLibraryTemplate:LibraryTemplate = {};
    //console.log("LibraryTemplate - Tabs - New/End - currentLibraryTemplate" , currentLibraryTemplate);
    React.useEffect(function () {
        return function () {
            dispatch(LibraryTemplateCurrentStore.Clear());
        };
    }, []);
    React.useEffect(function () {
        // Clear the currentLibraryTemplate in the state with a new GUID.
        if (hasAdded === false) {
            dispatch(LibraryTemplateCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current librarytemplate when navigating to the new librarytemplate page.
            var guid = generateGuid();
            //@ts-ignore
            document.hasAdded = false;
            // Add a new librarytemplate with guid.
            dispatch(LibraryTemplateCurrentStore.Update({
                GUID: guid,
                AllowChannelsCustomization: true,
                AllowFeatureCustomization: true,
                AllowDocumentsCustomization: true,
                AllowTenantsToUseTemplate: true,
                Position: listLibraryTemplates.length + 1,
                AssociatedChannels: [],
                AssociatedFeatures: [],
                AssociatedDocumentTemplates: []
            }));
            setHasAdded(true);
        }
        else {
            setHasAdded(true);
            // Load the /edit/guid load.
            dispatch(LibraryTemplateCurrentStore.Load({ GUID: lookupGuid }));
        }
    }, [dispatch, lookupGuid, listLibraryTemplates]);
    React.useEffect(function () {
        if (window.location.hash !== "")
            setHash(window.location.hash);
    }, []);
    var clearCurrentLibraryTemplate = function () {
        dispatch(LibraryTemplateCurrentStore.Clear());
    };
    var saveAndAddCurrentLibraryTemplate = function () {
        if (formValid) {
            setHasSubmitted(true);
        }
    };
    React.useEffect(function () {
        if (hasSubmitted) {
            var asyncCallType = AsyncCallType.Update;
            //@ts-ignore
            if (lookupGuid === undefined && document.hasAdded === false) {
                // First, add the new librarytemplate to librarytemplate list
                dispatch(LibraryTemplateListStore.AddItem(currentLibraryTemplate));
                asyncCallType = AsyncCallType.Add;
            }
            else {
                dispatch(LibraryTemplateListStore.UpdateItem(currentLibraryTemplate));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentLibraryTemplate.GUID).then(function () {
                setBackToList(true);
                dispatch(LibraryTemplateCurrentStore.Clear());
            });
        }
    }, [hasSubmitted]);
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
    };
    // React.useEffect(()=>{
    //     try{
    //         saveDiv.current.onclick=saveAndAddCurrentLibraryTemplate
    //     }catch{}
    // })
    React.useEffect(function () {
        console.log("ImageJsonData", currentLibraryTemplate.ImageJsonData);
    }, [currentLibraryTemplate.ImageJsonData]);
    var EnsureTemplateCreatedBeforeImageUpload = React.useCallback(function () {
        return new Promise(function (resolve, reject) {
            //@ts-ignore
            if (lookupGuid === undefined && document.hasAdded === false) {
                console.log("EnsureTemplateCreatedBeforeImageUpload", lookupGuid, hasAdded);
                var state = Store.getState();
                var currentTemplateTemp_1 = state.libraryTemplateCurrentState.item;
                console.log("current feature", currentTemplateTemp_1);
                dispatch(LibraryTemplateListStore.AddItem(currentTemplateTemp_1));
                AsyncWait(AsyncCallType.Add, AsyncStatus.Succeeded, currentTemplateTemp_1.GUID).then(function () {
                    //@ts-ignore
                    document.hasAdded = true;
                    setLookupGuid(currentTemplateTemp_1.GUID);
                    //setTimeout(() => {
                    //@ts-ignore
                    resolve();
                    //}, 1000);
                });
            }
            else {
                //@ts-ignore
                resolve();
            }
        });
    }, [listLibraryTemplates, hasAdded]);
    return (React.createElement("div", { className: "library-template-tabs fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "LibraryTemplateValidation", IsSubmitted: hasSubmitted },
            backToList && React.createElement(Redirect, { to: "/orchestry-admin/library/templates" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Template Library", subTitle: props.guid ? "Edit" : "New", titleLink: "/orchestry-admin/library/templates" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/orchestry-admin/library/templates", onClick: function () { return clearCurrentLibraryTemplate(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement("div", { onClick: function () { return saveAndAddCurrentLibraryTemplate(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            React.createElement(Tab.Container, { id: "librarytemplate-new-tabs", defaultActiveKey: hash },
                React.createElement("div", { className: "kt-portlet" },
                    React.createElement("div", { className: "kt-portlet__body" },
                        React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                            React.createElement(Nav, { as: "ul" },
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("details"); } },
                                    React.createElement(Nav.Link, { eventKey: "details" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-clipboard-list" }),
                                            " Details"))),
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("features"); } },
                                    React.createElement(Nav.Link, { eventKey: "features" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-boxes" }),
                                            " Features"))),
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("documents"); } },
                                    React.createElement(Nav.Link, { eventKey: "documents" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-file" }),
                                            " Documents"))),
                                currentLibraryTemplate.TemplateType === undefined || currentLibraryTemplate.TemplateType === "Team" ? (React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("channels"); } },
                                    React.createElement(Nav.Link, { eventKey: "channels" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-tv" }),
                                            " Channels")))) : (React.createElement(React.Fragment, null)),
                                currentLibraryTemplate.TemplateType === "Team" ? (React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("security"); } },
                                    React.createElement(Nav.Link, { eventKey: "security" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-user-lock" }),
                                            " Security")))) : null)),
                        React.createElement(Async, { uid: currentLibraryTemplate.GUID, status: AsyncTypes.AsyncStatus.Started },
                            React.createElement(AnimatedLoader, { size: "small", theme: "light", text: "Loading..." })),
                        React.createElement(Async, { uid: currentLibraryTemplate.GUID, status: AsyncTypes.AsyncStatus.Succeeded }),
                        currentLibraryTemplate.GUID !== undefined ? (React.createElement(Tab.Content, null,
                            React.createElement(Tab.Pane, { eventKey: "details" },
                                React.createElement(TabDetails, { currentLibraryTemplate: currentLibraryTemplate, ensureTemplateCreated: EnsureTemplateCreatedBeforeImageUpload })),
                            React.createElement(Tab.Pane, { eventKey: "features" },
                                React.createElement(TabFeatures, { currentLibraryTemplate: currentLibraryTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "channels" },
                                React.createElement(TabChannels, { currentLibraryTemplate: currentLibraryTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "documents" },
                                React.createElement(TabDocuments, { currentLibraryTemplate: currentLibraryTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "security" },
                                React.createElement(TabSecurity, { currentLibraryTemplate: currentLibraryTemplate })))) : (React.createElement("div", { className: "loading" }))))))));
});
export default LibraryTemplateTabs;
