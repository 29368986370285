import * as React from "react";
import "./request-details-step-progress.scss";
import { Form, Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Portlet, PortletBody } from "app/components/common/portlet";
import AnimatedLoader from "app/components/common/animated-loader";
import UserItem from "app/components/common/user-item";
import { FormattedMessage } from "react-intl";
var RequestDetailsStepProgress = React.memo(function (props) {
    var _a;
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var getProgressStepClass = function (stepThatNeedsClass) {
        var _a, _b, _c;
        var returnClass = "";
        var archivedType = "";
        if ("Archived" === currentRequest.Status) {
            if ((_a = currentRequest.History) === null || _a === void 0 ? void 0 : _a.some(function (h) { return h.Action === "Reject"; })) {
                archivedType = "Rejected";
            }
            else if ((_b = currentRequest.History) === null || _b === void 0 ? void 0 : _b.some(function (h) { return h.Action === "Approve"; })) {
                archivedType = "Approved";
            }
            else if ((_c = currentRequest.History) === null || _c === void 0 ? void 0 : _c.some(function (h) { return h.Action === "ProvisioningFailed"; })) {
                archivedType = "Failed";
            }
        }
        if (stepThatNeedsClass === "ApprovalRequired") {
            if ("ApprovalRequired" === currentRequest.Status) {
                returnClass = "current";
            }
            else {
                returnClass = "done";
            }
        }
        if (stepThatNeedsClass === "Approved") {
            if ("Approved" === currentRequest.Status) {
                returnClass = "current";
            }
            else if ("Rejected" === currentRequest.Status || "Failed" === currentRequest.Status || archivedType === "Rejected") {
                returnClass = "hide";
            }
            else if (currentRequest.Status !== "ApprovalRequired") {
                returnClass = "done";
            }
        }
        if (stepThatNeedsClass === "Rejected") {
            if ("Rejected" === currentRequest.Status || archivedType === "Rejected") {
                returnClass = "done";
            }
            else {
                returnClass = "hide";
            }
        }
        if (stepThatNeedsClass === "ReadyToProvision") {
            if ("ReadyToProvision" === currentRequest.Status) {
                returnClass = "current";
            }
            else if ("Rejected" === currentRequest.Status || "Failed" === currentRequest.Status || archivedType === "Rejected" || archivedType === "Failed") {
                returnClass = "hide";
            }
            else if (["Archived", "Provisioned", "Provisioning"].some(function (sp) { return sp === currentRequest.Status; })) {
                returnClass = "done";
            }
        }
        if (stepThatNeedsClass === "Provisioning") {
            if ("Provisioning" === currentRequest.Status) {
                returnClass = "current";
            }
            else if ("Rejected" === currentRequest.Status || "Failed" === currentRequest.Status || archivedType === "Rejected" || archivedType === "Failed") {
                returnClass = "hide";
            }
            else if (["Archived", "Rejected", "Provisioned"].some(function (sp) { return sp === currentRequest.Status; })) {
                returnClass = "done";
            }
        }
        if (stepThatNeedsClass === "Provisioned") {
            if (["Archived", "Provisioned"].some(function (sp) { return sp === currentRequest.Status; })) {
                returnClass = "done";
            }
            else if ("Rejected" === currentRequest.Status || "Failed" === currentRequest.Status || archivedType === "Rejected" || archivedType === "Failed") {
                returnClass = "hide";
            }
        }
        if (stepThatNeedsClass === "Archived") {
            if ("Archived" === currentRequest.Status) {
                returnClass = "done";
            }
            else {
                returnClass = "hide";
            }
        }
        if (stepThatNeedsClass === "Failed") {
            if ("Failed" === currentRequest.Status || archivedType === "Failed") {
                returnClass = "done";
            }
            else {
                returnClass = "hide";
            }
        }
        return " " + returnClass;
    };
    var getStepApproversArray = function (stringArray) {
        if (stringArray !== undefined && stringArray !== null) {
            var approverArray = JSON.parse(stringArray);
            if (Array.isArray(approverArray)) {
                return approverArray;
            }
        }
        return [];
    };
    var StepNode = React.memo(function (_a) {
        var step = _a.step;
        var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
        return (React.createElement("div", { className: "wf-step" + (isOpen ? " open" : ""), onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement("div", { className: "expand-icon" },
                React.createElement("i", { className: "fa fa-caret-right" })),
            React.createElement(Form.Check, { type: "checkbox", disabled: true, defaultChecked: step.IsComplete, label: step.Name }),
            React.createElement(Collapse, { in: isOpen },
                React.createElement("div", { className: "people" }, getStepApproversArray(step.Approvers).map(function (approver, aIndex) { return (React.createElement("div", { className: "user", key: aIndex },
                    React.createElement(UserItem, { showBadge: false, size: "sm", user: approver, showBorder: false }))); })))));
    });
    return (React.createElement(Portlet, { className: "request-details-step-progress steps-progress" },
        React.createElement(PortletBody, null, currentTemplate === undefined ? (React.createElement(AnimatedLoader, { size: "small", text: "Loading.." })) : (
        // </Async>
        // <Async uid={currentRequest.Template?.GUID} callType={AsyncCallType.Get} status={AsyncStatus.Succeeded}>
        React.createElement("div", { className: "approval-steps-list", "data-pw": "approval-step-list" },
            React.createElement("div", { className: "status" },
                React.createElement("div", { className: "status-line done" }),
                React.createElement("div", { className: "status-text" },
                    React.createElement(FormattedMessage, { id: "page.request_details.step_progress.create_request", defaultMessage: "Create Request" }))),
            (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.RequireApprovalBeforeProvisioning) === true ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "status approval-required" + getProgressStepClass("ApprovalRequired") },
                    React.createElement("div", { className: "status-line" + getProgressStepClass("ApprovalRequired") }),
                    React.createElement("div", { className: "status-text" },
                        React.createElement(FormattedMessage, { id: "page.request_details.step_progress.pending_approval", defaultMessage: "Pending Approval" })),
                    React.createElement("div", { className: "workflow-approval-steps" },
                        React.createElement("div", { className: "workflow-name" }, (_a = currentRequest.ApprovalWorkflow) === null || _a === void 0 ? void 0 : _a.Name),
                        React.createElement(Form.Group, { controlId: "control-approval-steps", className: "form-group checkbox-green" }, currentRequest.ApprovalWorkflow !== undefined && currentRequest.ApprovalWorkflow !== null && currentRequest.ApprovalWorkflow.AssociatedSteps !== undefined ? (React.createElement(React.Fragment, null, currentRequest.ApprovalWorkflow.AssociatedSteps.map(function (step, checkIndex) { return (React.createElement(StepNode, { step: step, key: "step-" + checkIndex })); }))) : null))),
                React.createElement("div", { className: "status" + getProgressStepClass("Approved") },
                    React.createElement("div", { className: "status-line" + getProgressStepClass("Approved") }),
                    React.createElement("div", { className: "status-text" },
                        React.createElement(FormattedMessage, { id: "page.request_details.step_progress.approval_received", defaultMessage: "Approval(s) Received" }))),
                getProgressStepClass("Rejected") !== " hide" ? (React.createElement("div", { className: "status" + getProgressStepClass("Rejected") },
                    React.createElement("div", { className: "status-line" + getProgressStepClass("Rejected") }),
                    React.createElement("div", { className: "status-text", "data-pw": "reject-status" },
                        React.createElement(FormattedMessage, { id: "page.request_details.step_progress.rejection_received", defaultMessage: "Rejection Received" })))) : null)) : null,
            getProgressStepClass("ReadyToProvision") !== " hide" ? (React.createElement("div", { className: "status" + getProgressStepClass("ReadyToProvision") },
                React.createElement("div", { className: "status-line" + getProgressStepClass("ReadyToProvision") }),
                React.createElement("div", { className: "status-text" },
                    React.createElement(FormattedMessage, { id: "page.request_details.step_progress.ready_to_provision", defaultMessage: "Ready to Provision" })))) : null,
            getProgressStepClass("Provisioning") !== " hide" ? (React.createElement("div", { className: "status" + getProgressStepClass("Provisioning") },
                React.createElement("div", { className: "status-line" + getProgressStepClass("Provisioning") }),
                React.createElement("div", { className: "status-text" },
                    React.createElement(FormattedMessage, { id: "page.request_details.step_progress.provisioning_workspace", defaultMessage: "Provisioning Workspace" })))) : null,
            getProgressStepClass("Provisioned") !== " hide" ? (React.createElement("div", { className: "status" + getProgressStepClass("Provisioned") },
                React.createElement("div", { className: "status-line" + getProgressStepClass("Provisioned") }),
                React.createElement("div", { className: "status-text" },
                    React.createElement(FormattedMessage, { id: "page.request_details.step_progress.workspace_created", defaultMessage: "Workspace Created" })))) : null,
            getProgressStepClass("Failed") !== " hide" ? (React.createElement("div", { className: "status" },
                React.createElement("div", { className: "status-line" + getProgressStepClass("Failed") }),
                React.createElement("div", { className: "status-text" },
                    React.createElement(FormattedMessage, { id: "page.request_details.step_progress.provisioning_failed", defaultMessage: "Provisioning Failed" })))) : null,
            getProgressStepClass("Archived") !== " hide" ? (React.createElement("div", { className: "status" },
                React.createElement("div", { className: "status-line" + getProgressStepClass("Archived") }),
                React.createElement("div", { className: "status-text" },
                    React.createElement(FormattedMessage, { id: "page.request_details.step_progress.archived", defaultMessage: "Archived" })))) : null))
        // </Async>
        )));
});
export default RequestDetailsStepProgress;
