import { OrchestryContextCurrentActionTypes } from "./types";
export function Load(orchestryContext) {
    var wasFromLocalSession = false;
    if (orchestryContext === undefined) {
        //   if(LocalSessionService.KeyExists("OrchestryContext")){
        //       orchestryContext = LocalSessionService.GetObject("OrchestryContext") as OrchestryContext;
        //       wasFromLocalSession = true;
        //   } else {
        orchestryContext = {};
        //}
    }
    //
    if (wasFromLocalSession) {
        return {
            type: OrchestryContextCurrentActionTypes.OrchestryContext_Current_Load_From_LocalSession,
            payload: orchestryContext
        };
    }
    else {
        //console.log("Load Current Context > ", orchestryContext)
        return {
            type: OrchestryContextCurrentActionTypes.OrchestryContext_Current_Load,
            payload: orchestryContext
        };
    }
}
export function ShowLoader(show) {
    return {
        type: OrchestryContextCurrentActionTypes.OrchestryContext_Current_UpdateShowLoader,
        payload: show
    };
}
export function Refresh(orchestryContext) {
    if (orchestryContext === undefined) {
        orchestryContext = {};
    }
    return {
        type: OrchestryContextCurrentActionTypes.OrchestryContext_Current_Refresh,
        payload: orchestryContext
    };
}
