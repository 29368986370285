/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import UserService from "app/services/users";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import getEnvironmentName from "app/utils/environmentName";
import objectPath from "object-path";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleLeftIcon } from "../assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../assets/layout-svg-icons/Angle-double-right.svg";

class Brand extends React.Component {
   ktToggleRef = React.createRef();

   componentDidMount() {
      // eslint-disable-next-line no-undef
      new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
   }
   render() {
      function getLogoTag() {
         return getEnvironmentName();
      }

      return (
         <div className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`} id="kt_aside_brand">
            <div className="kt-aside__brand-logo">
               {this.props.isUser ? (
                  <Link to="">
                     <img alt="logo" src={this.props.headerLogo} />
                  </Link>
               ) : (
                  <img alt="logo" src={this.props.headerLogo} />
               )}
            </div>
            {getLogoTag() != null ? (
               <div className="logo-tag">
                  <div className="logo-tag-inside">{getLogoTag()}</div>
               </div>
            ) : null}

            {this.props.asideSelfMinimizeToggle && (
               <div className="kt-aside__brand-tools">
                  <button ref={this.ktToggleRef} className="kt-aside__brand-aside-toggler" id="kt_aside_toggler" data-pw="navigation-toggle">
                     <span>
                        <AngleDoubleLeftIcon />
                     </span>
                     <span>
                        <AngleDoubleRightIcon />
                     </span>
                  </button>
                  <BrandInitialize />
               </div>
            )}
         </div>
      );
   }
}

const mapStateToProps = store => {
   return {
      brandClasses: builder.selectors.getClasses(store, {
         path: "brand",
         toString: true
      }),
      asideSelfMinimizeToggle: objectPath.get(store.builder.layoutConfig, "aside.self.minimize.toggle"),
      isUser: store.orchestryContextCurrentState.item.CurrentUserRoles.includes(OrchRole.User), // A beacon user is not a normal user and does not have access to the home page.
      headerLogo: builder.selectors.getLogo(store),
      headerStickyLogo: builder.selectors.getStickyLogo(store),
      toggleOptions: {
         target: "body",
         targetState: "kt-aside--minimize",
         togglerState: "kt-aside__brand-aside-toggler--active"
      }
   };
};

export default connect(mapStateToProps)(Brand);

export function BrandInitialize() {
   const dispatch = useDispatch();
   const orchestryContext = useSelector(state => state.orchestryContextCurrentState.item);
   const [inTeams] = React.useState(document.getElementsByTagName("html")[0].classList.contains("in-teams"));

   React.useEffect(() => {
      if (orchestryContext != undefined && orchestryContext.HasInstalledOnce != undefined) {
         if (orchestryContext.MenuCollapsed === true || inTeams === true) {
            //console.log("query button", document.querySelector(".kt-aside__brand-aside-toggler"))
            let button = document.querySelector(".kt-aside__brand-aside-toggler");
            let toggler = new KTToggle(button, {
               target: "body",
               targetState: "kt-aside--minimize",
               togglerState: "kt-aside__brand-aside-toggler--active"
            });

            toggler.toggleOn();
         }
      }
   }, [orchestryContext]);

   React.useEffect(() => {
      let button = document.querySelector(".kt-aside__brand-aside-toggler");
      button.addEventListener("click", function(event) {
         //console.log(button.classList.contains("kt-aside__brand-aside-toggler--active"));
         //console.log("event", )
         UserService.setMenuCollapse(this.classList.contains("kt-aside__brand-aside-toggler--active")).then();
      });
   }, []);

   return null;
}
