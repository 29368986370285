var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-searchlist.scss";
import { Scrollbar } from "react-scrollbars-custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
var FormSearchList = function (props) {
    var _a = React.useState(false), listOpen = _a[0], setListOpen = _a[1];
    var _b = React.useState([]), list = _b[0], setList = _b[1];
    var _c = React.useState(""), searchTerm = _c[0], setSearchTerm = _c[1];
    // const [showItemsBeforeSearch, setShowItemsBeforeSearch] = React.useState(props.showItemsBeforeSearch ? props.showItemsBeforeSearch : true);
    // const showAddIcon:boolean = props.showAddIcon !== undefined ? props.showAddIcon : true;
    var scrollRef;
    var rootNode = React.useRef();
    var dropdownNode = React.useRef();
    var filterItems = function (value) {
        if (scrollRef !== undefined) {
            scrollRef.scrollTo(0, 0);
        }
        if (value.length > 0) {
            var foundOptions = props.items.filter(function (i) { return i.Title.toLowerCase().indexOf(value.toLowerCase()) > -1; });
            console.log("foundoptions", foundOptions);
            setList(__spreadArray([], foundOptions, true));
            setSearchTerm(value);
        }
        else {
            setList(__spreadArray([], props.items, true));
            setSearchTerm("");
        }
    };
    React.useEffect(function () {
        if (list.length === 0) {
            //console.log("List Length 0")
            setList(props.items);
        }
        else if (list.length !== props.items.length) {
            setList(props.items);
        }
        //console.log("formSearchItems", list, props.items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items]);
    var setScrollRef = function (ref) {
        if (ref !== null) {
            //console.log("setScrollRef > Ref", ref);
            scrollRef = ref;
        }
    };
    var clearSearch = function () {
        if (scrollRef !== undefined) {
            scrollRef.scrollTo(0, 0);
        }
        setList(__spreadArray([], props.items, true));
        setSearchTerm("");
    };
    var handleClick = function (e) {
        // let ddclick = false;
        // let rootclick = false;
        if (rootNode.current.contains(e.target)) {
            setListOpen(true);
            return;
            //   rootclick = true;
        }
        // if(dropdownNode !== undefined){
        //     if(dropdownNode.current.contains(e.target as HTMLInputElement)){
        //         console.log("dropdown click");
        //         ddclick = true;
        //         setTimeout(()=>{
        //             setListOpen(false);
        //             return;
        //         },100)
        //     }
        // }
        // if(rootclick === true && ddclick === false){
        //     return;
        // }
        //if(ddclick === false && rootclick === false) {
        // outside click
        setListOpen(false);
        //}
    };
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var selectItem = function (item) {
        props.onSelected(item.Object);
        setTimeout(function () {
            if (props.keepOpen !== undefined && props.keepOpen === false) {
                setListOpen(false);
            }
            console.log("Close after select");
        }, 25);
    };
    var highlightWords = function (words) {
        var capitalize = function (v) {
            return v.charAt(0).toUpperCase() + v.slice(1);
        };
        if (searchTerm === "")
            return words;
        words = words.replace(new RegExp(searchTerm.toLowerCase(), "g"), "<strong>" + searchTerm.toLowerCase() + "</strong>");
        words = words.replace(new RegExp(capitalize(searchTerm), "g"), "<strong>" + capitalize(searchTerm) + "</strong>");
        return words;
    };
    return (React.createElement("div", { className: "form-searchlist" + (listOpen ? " open" : ""), ref: rootNode },
        React.createElement("div", { className: "dd-wrapper" },
            React.createElement("div", { className: "searchbox" },
                React.createElement("input", { type: "text", placeholder: props.placeholder, value: searchTerm, onChange: function (input) {
                        filterItems(input.target.value);
                    } }),
                searchTerm.length > 0 ? (React.createElement("div", { className: "icon-delete" },
                    React.createElement(FontAwesomeIcon, { icon: faTimesCircle, onClick: function () {
                            clearSearch();
                        } }))) : (React.createElement("div", { className: "icon-search" },
                    React.createElement(FontAwesomeIcon, { icon: faSearch })))),
            listOpen && (React.createElement("div", { className: "dd-list", ref: dropdownNode },
                list.length > 6 ? (React.createElement(Scrollbar, { ref: function (ref) {
                        setScrollRef(ref);
                    } },
                    React.createElement("ul", { className: " list-unstyled" }, list.map(function (item) { return (React.createElement("li", { className: "dd-list-item", key: item.GUID, onClick: function () { return selectItem(item); } },
                        item.Icon !== undefined ? React.createElement(FontAwesomeIcon, { icon: item.Icon }) : "",
                        React.createElement("span", { dangerouslySetInnerHTML: { __html: highlightWords(item.Title) } }),
                        React.createElement("span", { className: "add-icon" },
                            React.createElement(FontAwesomeIcon, { icon: faPlusCircle })))); })))) : (React.createElement("ul", { className: " list-unstyled" }, list.map(function (item) { return (React.createElement("li", { className: "dd-list-item", key: item.GUID, onClick: function () { return selectItem(item); } },
                    item.Icon !== undefined ? React.createElement(FontAwesomeIcon, { icon: item.Icon }) : "",
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: highlightWords(item.Title) } }),
                    React.createElement("span", { className: "add-icon" },
                        React.createElement(FontAwesomeIcon, { icon: faPlusCircle })))); }))),
                list.length === 0 && (React.createElement("ul", { className: "list-unstyled" },
                    React.createElement("li", { className: "no-results" }, " -- No Results -- "))))))));
};
export default FormSearchList;
