export var TeamSpecializations = [
    {
        Title: "Standard Team",
        Description: "The standard team template",
        Value: null
    },
    {
        Title: "Class",
        Description: "Discussions, group projects, assignments",
        Value: "educationClass"
    },
    {
        Title: "Professional Learning Community (PLC)",
        Description: "Educator working group",
        Value: "educationProfessionalLearningCommunity"
    },
    {
        Title: "Staff",
        Description: "School administration and development",
        Value: "educationStaff"
    },
    {
        Title: "Other",
        Description: "Clubs, study groups, after school activities",
        Value: "educationStandard"
    }
];
