import React from "react";
import { RecommendationCardHistory } from "../recommendation-card-history/recommendation-card-history.component";
import { RecommendationCardActionButtonSection } from "../recommendation-card-action-buttons-section/recommendation-card-action-buttons-section.component";
import "./recommendation-card-expandable-section.scss";
export var RecommendationCardExpandableSection = function (_a) {
    var recommendation = _a.recommendation, isCardExpanded = _a.isCardExpanded;
    return (React.createElement("div", { className: "recommendation-card-expandable-section" },
        React.createElement(RecommendationCardHistory, { recommendation: recommendation, isCardExpanded: isCardExpanded }),
        React.createElement(RecommendationCardActionButtonSection, { recommendation: recommendation })));
};
