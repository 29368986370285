import { useGetHealthChecksQuery } from "appv2/api/health-checks/health-checks.api";
import { useGetMspTenantsQuery } from "appv2/api/partner-tools/partners.api";
import { useIntl } from "react-intl";
import { useHealthCheckRouteParams } from "../health-checks/use-health-check-route-params.hook";
export var usePartnerHealthCheckTeamsPage = function () {
    var _a, _b, _c, _d;
    var intl = useIntl();
    var _e = useHealthCheckRouteParams(), tenantGuid = _e.tenantGuid, healthCheckGuid = _e.healthCheckGuid;
    var _f = useGetHealthChecksQuery([
        "partner",
        tenantGuid,
        {
            queryParameters: {
                filter: "(UniqueId == \"".concat(healthCheckGuid, "\")"),
                orderBy: "",
                page: 1,
                pageSize: 1
            }
        }
    ]), pagedHealthChecks = _f.data, healthChecksIsLoading = _f.isLoading;
    var healthCheckName = (_b = (_a = pagedHealthChecks === null || pagedHealthChecks === void 0 ? void 0 : pagedHealthChecks.Items[0]) === null || _a === void 0 ? void 0 : _a.Name) !== null && _b !== void 0 ? _b : "";
    var _g = useGetMspTenantsQuery({
        queryParameters: {
            filter: "(TenantGuid == \"".concat(tenantGuid, "\")"),
            orderBy: "",
            page: 1,
            pageSize: 1
        }
    }), pagedMspTenants = _g.data, pagedMspTenantsIsLoading = _g.isLoading;
    var tenantName = (_d = (_c = pagedMspTenants === null || pagedMspTenants === void 0 ? void 0 : pagedMspTenants.Items[0]) === null || _c === void 0 ? void 0 : _c.DisplayName) !== null && _d !== void 0 ? _d : "";
    var isLoading = healthChecksIsLoading || pagedMspTenantsIsLoading;
    return {
        intl: intl,
        tenantGuid: tenantGuid,
        healthCheckGuid: healthCheckGuid,
        healthCheckName: healthCheckName,
        tenantName: tenantName,
        isLoading: isLoading
    };
};
