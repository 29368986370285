export var PermissionInfoAction;
(function (PermissionInfoAction) {
    /**
     * A PermissionInfo is being created.
     */
    PermissionInfoAction[PermissionInfoAction["Create"] = 0] = "Create";
    /**
     * A PermissionInfo is being updated.
     */
    PermissionInfoAction[PermissionInfoAction["Update"] = 1] = "Update";
})(PermissionInfoAction || (PermissionInfoAction = {}));
