import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import GroupsService from "app/services/groups";
import UsersService from "app/services/users";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import AnimatedLoader from "../animated-loader";
import { SubtitleButton } from "../subtitle";
import "./principal-search-modal.scss";
var principalSearchModalInputValue = "";
var PrincipalSearchModal = React.memo(function (props) {
    var intl = useIntl();
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var inputEl = React.useRef();
    var _b = React.useState([]), searchResults = _b[0], setSearchResults = _b[1];
    var _c = React.useState([]), suggestedUsers = _c[0], setSuggestedUsers = _c[1];
    var _d = React.useState(false), modalOpen = _d[0], setModalOpen = _d[1];
    var _e = React.useState(undefined), previousForceOpen = _e[0], setPreviousForceOpen = _e[1];
    var _f = React.useState(null), previousExcludeGuests = _f[0], setPreviousExcludeGuests = _f[1];
    //const [totalAjaxRequest, setTotalAjaxRequest] = React.useState(0);
    //const [totalAjaxRequestComplete, setTotalAjaxRequestComplete] = React.useState(0);
    //console.log("rerender");
    React.useEffect(function () {
        if (props.forceOpen !== undefined) {
            if (previousForceOpen === undefined && props.forceOpen === true) {
                setModalOpen(true);
            }
            if (previousForceOpen === false && props.forceOpen === true) {
                setModalOpen(true);
            }
        }
        setPreviousForceOpen(props.forceOpen);
    }, [props.forceOpen]);
    var _g = React.useState(-1), cursor = _g[0], setCursor = _g[1];
    // // On first run, get the suggested users
    React.useEffect(function () {
        setIsLoading(true);
        UsersService.getSuggestedPeople(5, props.excludeGuests !== undefined ? props.excludeGuests : false).then(function (resultData) {
            setIsLoading(false);
            setSuggestedUsers(resultData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Get the search results.
    var getResults = function (searchValue) {
        setTimeout(function () {
            //console.log("timeout", searchValue, inputEl.current.value, searchValue === inputEl.current.value)
            if (searchValue === inputEl.current.value) {
                //
                if (searchValue.length === 0) {
                    setIsLoading(false);
                    setSearchResults([]);
                    // This will cancel the last request.
                    if (props.mode === "usersAndGroups") {
                        UsersService.searchGraphUsersAndGroups(searchValue, 50, props.excludeGuests !== undefined ? props.excludeGuests : false).then(function (resultData) { });
                    }
                    else if (props.mode === "users") {
                        UsersService.searchGraphUsers(searchValue, 50, props.excludeGuests !== undefined ? props.excludeGuests : false, props.adminTenantGuid).then(function (resultData) { });
                    }
                    else if (props.mode === "groups") {
                        GroupsService.searchGraphGroups(searchValue, 50).then(function (resultData) { });
                    }
                }
                else {
                    if (props.mode === "usersAndGroups") {
                        UsersService.searchGraphUsersAndGroups(searchValue, 50, props.excludeGuests !== undefined ? props.excludeGuests : false).then(function (resultData) {
                            //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                            try {
                                if (searchValue === inputEl.current.value) {
                                    setIsLoading(false);
                                    setSearchResults(resultData);
                                }
                            }
                            catch (_a) { }
                        });
                    }
                    else if (props.mode === "users") {
                        UsersService.searchGraphUsers(searchValue, 50, props.excludeGuests !== undefined ? props.excludeGuests : false, props.adminTenantGuid).then(function (resultData) {
                            //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                            try {
                                if (searchValue === inputEl.current.value) {
                                    setIsLoading(false);
                                    setSearchResults(resultData);
                                }
                            }
                            catch (_a) { }
                        });
                    }
                    else if (props.mode === "groups") {
                        GroupsService.searchGraphGroups(searchValue, 50).then(function (resultData) {
                            //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                            try {
                                if (searchValue === inputEl.current.value) {
                                    setIsLoading(false);
                                    setSearchResults(resultData);
                                }
                            }
                            catch (_a) { }
                        });
                    }
                }
            }
        }, 1000);
    };
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        }
        else if (e.keyCode === 40 && cursor < currentResults.length - 1) {
            setCursor(cursor + 1);
        }
        else if (e.keyCode === 13) {
            // console.log("Enter Clicked", currentResults[cursor]);
            if (cursor !== -1) {
                clickUser(currentResults[cursor]);
            }
        }
    };
    var shouldShowSuggestions = function () {
        var shouldShow = true;
        if (props.showSuggested !== undefined) {
            return props.showSuggested;
        }
        if ((searchResults !== undefined && searchResults.length > 0) || props.mode === "groups") {
            shouldShow = false;
        }
        if (inputEl !== undefined) {
            if (inputEl.current !== undefined) {
                if (inputEl.current !== null) {
                    if (inputEl.current.value !== undefined) {
                        if (inputEl.current.value !== null) {
                            //console.log("inputEl", inputEl)
                            if (inputEl.current.value.length > 0) {
                                shouldShow = false;
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldShowNoResult = function () {
        var shouldShow = false;
        if (searchResults !== undefined) {
            if (searchResults.length === 0) {
                if (inputEl !== undefined) {
                    if (inputEl.current !== undefined) {
                        if (inputEl.current !== null) {
                            if (inputEl.current.value !== undefined) {
                                if (inputEl.current.value !== null) {
                                    if (inputEl.current.value.length > 0) {
                                        if (isLoading === false) {
                                            shouldShow = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldUseScrollbar = function () {
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (currentResults.length > 6) {
            return true;
        }
        return false;
    };
    var clickUser = function (user) {
        if (isPrincipalLocked(user)) {
            return;
        }
        if (isPrincipalSelected(user)) {
            props.onPrinciaplRemoved(user);
        }
        if (!isPrincipalSelected(user)) {
            props.onPrincipalSelected(user);
        }
        setCursor(-1);
    };
    var isPrincipalSelected = function (user) {
        var isSelected = false;
        if (props.selectedPrincipals !== undefined) {
            if (Array.isArray(props.selectedPrincipals)) {
                if (props.selectedPrincipals.length > 0) {
                    props.selectedPrincipals.forEach(function (sp) {
                        if (sp !== undefined && (sp.GUID !== undefined || sp.Id !== undefined)) {
                            if (sp.GUID === user.GUID || sp.Id === user.GUID) {
                                isSelected = true;
                            }
                        }
                    });
                }
            }
        }
        return isSelected;
    };
    var isPrincipalLocked = function (user) {
        var isLocked = false;
        if (props.selectedPrincipals !== undefined) {
            if (Array.isArray(props.selectedPrincipals)) {
                if (props.selectedPrincipals.length > 0) {
                    props.selectedPrincipals.forEach(function (sp) {
                        if (sp !== undefined && (sp.GUID !== undefined || sp.Id !== undefined)) {
                            if ((sp.GUID === user.GUID || sp.Id === user.GUID) && sp.Locked === true) {
                                isLocked = true;
                            }
                        }
                    });
                }
            }
        }
        return isLocked;
    };
    var onChildrenClicked = React.useCallback(function () { return (!props.isParentLoading ? setModalOpen(!modalOpen) : null); }, [modalOpen, props.isParentLoading]);
    React.useEffect(function () {
        if (props.excludeGuests !== null && props.excludeGuests !== undefined) {
            if (previousExcludeGuests !== props.excludeGuests) {
                setPreviousExcludeGuests(props.excludeGuests);
                setSearchResults([]);
            }
        }
    }, [props.excludeGuests]);
    var content = (React.createElement("div", { className: "people-content", "data-pw": "people-content" },
        React.createElement("div", { className: "people-header" },
            React.createElement("span", null, props.title),
            React.createElement("div", { className: "close", onClick: function () { return setModalOpen(!modalOpen); }, "data-pw": "close" },
                React.createElement("i", { className: "la la-remove" }))),
        React.createElement("div", { className: "people-search" },
            React.createElement("div", { className: "search-box" },
                React.createElement("input", { type: "text", placeholder: intl.formatMessage({ id: "component.principal_search_modal.search_names_or_roles", defaultMessage: "Search names or roles" }), onChange: function (event) {
                        setIsLoading(true);
                        getResults(event.target.value);
                    }, onKeyDown: function (event) {
                        handleKeyDown(event);
                    }, ref: inputEl }),
                React.createElement("div", { className: "icon" },
                    React.createElement("i", { className: "flaticon-search" })))),
        React.createElement("div", { className: "people-list" },
            React.createElement("ul", { className: "list-unstyled" },
                React.createElement(DynamicScrollbar, { shouldShow: shouldUseScrollbar(), maxHeight: 200 },
                    isLoading === false &&
                        searchResults !== undefined &&
                        searchResults.map(function (rs, index) {
                            var _a;
                            return (React.createElement("li", { className: (cursor === index ? " hover-selected" : "") + (isPrincipalSelected(rs) ? " selected" : "") + (isPrincipalLocked(rs) === true ? "  locked" : ""), key: index, onMouseEnter: function () {
                                    setCursor(-1);
                                }, onClick: function () {
                                    clickUser(rs);
                                } },
                                React.createElement("div", { className: "pic" },
                                    React.createElement(OfficeProfilePicture, { size: "sm", uid: rs.Id, displayName: rs.DisplayName, type: rs.Type })),
                                React.createElement("div", { className: "info" },
                                    React.createElement("div", { className: "name" },
                                        rs.DisplayName,
                                        rs.UserPrincipalName != null && rs.UserPrincipalName.indexOf("#EXT#") > -1 && (React.createElement("span", { className: "guest-note" },
                                            "(",
                                            React.createElement(FormattedMessage, { id: "component.principal_search_modal.guest_label", defaultMessage: "Guest" }),
                                            ")"))),
                                    React.createElement("div", { className: "job-title" }, rs.JobTitle)),
                                React.createElement("div", { className: "action" },
                                    React.createElement("div", { className: "add" },
                                        React.createElement("i", { className: "fa fa-plus" })),
                                    React.createElement("div", { className: "check" },
                                        React.createElement("i", { className: "fa fa-check" })),
                                    React.createElement("div", { className: "locked" },
                                        React.createElement("i", { className: "fa fa-lock" }))),
                                props.mode !== "users" ? (React.createElement("div", { className: "principal-type type-" + ((_a = rs.Type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) }, rs.Type === "User" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.user_label", defaultMessage: "User" }) },
                                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.user_acronym", defaultMessage: "U" }))) : rs.Type === "Group" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.group_label", defaultMessage: "Group" }) },
                                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.group_acronym", defaultMessage: "G" }))) : rs.Type === "SecurityGroup" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.security_label", defaultMessage: "Security Group" }) },
                                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.security_acronym", defaultMessage: "S" }))) : rs.Type === "DistributionGroup" ? (React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.distribution_label", defaultMessage: "Distribution List" }) },
                                    React.createElement(FormattedMessage, { id: "component.principal_search_modal.distribution_acronym", defaultMessage: "D" }))) : ("G"))) : null));
                        }),
                    shouldShowSuggestions() && suggestedUsers !== undefined ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "suggested-members" }, props.suggestedTitle !== undefined ? props.suggestedTitle : "Suggested Members"),
                        suggestedUsers.map(function (rs, index) { return (React.createElement("li", { className: (cursor === index ? " hover-selected" : undefined) + (isPrincipalSelected(rs) ? " selected" : "") + (isPrincipalLocked(rs) === true ? "  locked" : ""), key: index, onMouseEnter: function () {
                                setCursor(-1);
                            }, onClick: function () {
                                clickUser(rs);
                            } },
                            React.createElement("div", { className: "pic" },
                                React.createElement(OfficeProfilePicture, { size: "sm", uid: rs.Id, displayName: rs.DisplayName, type: rs.Type })),
                            React.createElement("div", { className: "info" },
                                React.createElement("div", { className: "name" }, rs.DisplayName),
                                React.createElement("div", { className: "job-title" }, rs.JobTitle)),
                            React.createElement("div", { className: "action" },
                                React.createElement("div", { className: "add" },
                                    React.createElement("i", { className: "fa fa-plus" })),
                                React.createElement("div", { className: "check" },
                                    React.createElement("i", { className: "fa fa-check" })),
                                React.createElement("div", { className: "locked" },
                                    React.createElement("i", { className: "fa fa-lock" }))))); }))) : null,
                    shouldShowNoResult() ? (React.createElement("li", { className: "no-results" },
                        React.createElement(FormattedMessage, { id: "general.label.no_results", defaultMessage: "No results" }))) : null,
                    isLoading ? (React.createElement("li", { className: "loader" },
                        React.createElement(AnimatedLoader, { size: "small", theme: "dark" }))) : null)))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { size: "sm", backdropClassName: "principal-modal-backdrop", show: modalOpen, onHide: function () { return setModalOpen(!modalOpen); }, animation: true, className: "principal-search-modal" },
            content,
            React.createElement(Modal.Footer, null,
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "general.label.done", defaultMessage: "Done" }), onClick: function () { return setModalOpen(!modalOpen); } }))),
        React.createElement("div", { className: "link", onClick: onChildrenClicked }, props.children))
    // <>
    // {width < 768 ?
    // <>
    //     <span onClick={()=>{setMobileOpen(!mobileOpen)}} ref={spanEl}>{props.children}</span>
    //     <>{mobileOpen ? content : null}</>
    // </>
    // :
    // <OverlayTrigger trigger={['click']} placement={"right-start"} overlay={popover} ref={overlayTriggerEl}>
    //     {props.children}
    // </OverlayTrigger>
    // }
    // </>
    );
    // return (
    //     <OverlayTrigger trigger={['click']} placement="right-start" overlay={popover} ref={overlayTriggerEl}>
    //         {props.children}
    //     </OverlayTrigger>
    // )
});
export default PrincipalSearchModal;
