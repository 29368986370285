import { Card, Group } from "@mantine/core";
import MICROSOFT_SVG_ICONS from "appv2/assets/icons/microsoft-svgs/microsoft-svg-icons";
import { OrchestryAreaChart } from "appv2/components/orchestry-area-chart/orchestry-area-chart.component";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { roundAndLocalizeNumber } from "appv2/utils/number.utils";
import { omitKeys } from "appv2/utils/object.utils";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var ExchangeTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.StorageAnalysis, _g = _f === void 0 ? {} : _f, _h = _g.Exchange, _j = _h === void 0 ? {} : _h, Total = _j.Total, PreviousValue = _j.PreviousValue, DeltaValue = _j.DeltaValue, DeltaPercentage = _j.DeltaPercentage, Data = _j.Data, YAxisRange = _j.YAxisRange, DataCoverageDaysCount = _j.DataCoverageDaysCount, DataCoverageAvailable = _j.DataCoverageAvailable;
    var dataWithoutDeltas = Data === null || Data === void 0 ? void 0 : Data.map(function (item) { return omitKeys(item, ["PreviousValue", "DeltaValue", "DeltaPercentage"]); });
    return (React.createElement(Card, { variant: "default", h: "100%", p: 24, miw: 260, style: { overflow: "visible" } },
        React.createElement(Group, { gap: "8px" },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "sm", variant: "light-circle", colorVariant: "blue-7", imgIconWidth: "16px", imgIconHeight: "16px", imgIconSrc: MICROSOFT_SVG_ICONS.Exchange }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "16", fw: 500, lh: "24px" }, intl.formatMessage({ id: "page.health_check.exchange_tile.title", defaultMessage: "Exchange" }))),
        React.createElement(OrchestryAreaChart, { isLoading: isLoading, curveType: "bump", yAxisRange: YAxisRange, height: "132px", data: dataWithoutDeltas !== null && dataWithoutDeltas !== void 0 ? dataWithoutDeltas : [], lastPeriodData: Data, series: [{ name: "StorageValue", color: "var(--mantine-color-blue-6)" }], dataKey: "date", miw: 175, mt: 8 }),
        React.createElement(Group, { mt: 8, justify: "space-between", align: "flex-end" },
            React.createElement(Group, { gap: "4px", align: "Flex-start" },
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "24", fw: 500, lh: "32px" }, roundAndLocalizeNumber(Total, 2)),
                React.createElement(OrchestryText, { skeletonWidth: 15, isLoading: isLoading, c: "text-primary.0", fz: "12", fw: 300, lh: "18px" }, "TB")),
            React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, align: "Flex-end", lastPeriodValue: PreviousValue, deltaValue: DeltaValue, deltaPercentage: DeltaPercentage, daysAgoCount: DataCoverageDaysCount, dataCoverageAvailable: DataCoverageAvailable }))));
};
