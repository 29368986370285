import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomeAdmin from "app/components/homes/home-admin";
import OrchestryCheckAdmin from "app/components/orchestry-context/orchestry-check-admin";
import OrchestryCheckInstall from "app/components/orchestry-context/orchestry-check-install";
import OrchestryCheckUpgrade from "app/components/orchestry-context/orchestry-check-upgrade";
import { useSelector } from "react-redux";
import DevHangfire from "app/components/dev/dev-hangfire";
import OrchestryLocalizationEditor from "app/components/orchestry-admin/orchestry-localization-editor";
import ToolPages from "app/pages/tool-pages";
import AdminPage from "./admin";
import DirectoryPages from "./directory-pages";
import ExternalApiPages from "./external-api-pages";
import GuestPages from "./guest-pages";
import GuestWorkflowPages from "./guest-workflow-pages";
import LifecyclePages from "./lifecycle-pages";
import LifecycleWorkflowPages from "./lifecycle-workflow-pages";
import OrchestryAdminPages from "./orchestry-admin-pages";
import RequestPages from "./request-pages";
import SettingPages from "./setting-pages";
import WorkspacePages from "./workspace-pages";
import ErrorBoundary from "app/components/common/error-boundary/Error";
import { OrchestryPermissionInfoEditor } from "app/components/orchestry-admin/orchestry-permission-info-editor";
import * as UserCurrentStore from "app/store/users/current";
import { useDispatch } from "react-redux";
import HealthCheckPages from "./health-check-pages";
import PartnerToolsPages from "./partner-tools-pages";
import { InstallerMode } from "app/components/installer/installer-wizard";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import RecommendationsPages from "./recommendations-pages";
// const AdminPage = React.lazy(() => import('./admin'));
// const DirectoryPages = React.lazy(() => import('./directory-pages'));
// const WorkspacePages = React.lazy(() => import('./workspace-pages'));
// const SettingPages = React.lazy(() => import('./setting-pages'));
// const ToolPages = React.lazy(() => import('./tool-pages'));
// const OrchestryAdminPages = React.lazy(() => import('./orchestry-admin-pages'));
// const DevHangfire = React.lazy(() => import('app/components/dev/dev-hangfire'));
//const GoogleMaterialPage = lazy(() => import("./admin"));
// const ReactBootstrapPage = lazy(() =>
//   //import("./workspace-request")
// );
export default function RootPage() {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var userCurrentState = useSelector(function (state) { return state.userCurrentState.item; });
    var dispatch = useDispatch();
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    React.useEffect(function () {
        // Load the current user
        dispatch(UserCurrentStore.Load());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(OrchestryCheckInstall, null,
        React.createElement(ErrorBoundary, { orchestryContext: orchestryContext, userCurrentState: userCurrentState },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/" }, currentUserIsBeaconUser ? (React.createElement(Redirect, { to: "/health-checks" })) : orchestryContext.Role === "Admin" ? (React.createElement(OrchestryCheckAdmin, null,
                    React.createElement(OrchestryCheckUpgrade, { installMode: InstallerMode.Update }),
                    React.createElement(HomeAdmin, null))) : null),
                React.createElement(Route, { path: "/requests", component: RequestPages }),
                React.createElement(Route, { path: "/policy-workflow", component: LifecycleWorkflowPages }),
                React.createElement(Route, { path: "/guest-workflow", component: GuestWorkflowPages }),
                React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/localization-editor", component: OrchestryLocalizationEditor }),
                React.createElement(Route, { exact: true, path: "/orchestry-admin/tool/permission-info-editor", component: OrchestryPermissionInfoEditor }),
                React.createElement(Route, { path: "/health-checks", component: HealthCheckPages }),
                React.createElement(Route, { path: "/partner-tools", component: PartnerToolsPages }),
                React.createElement(OrchestryCheckAdmin, null,
                    React.createElement(OrchestryCheckUpgrade, { installMode: InstallerMode.Update }),
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/admin", component: AdminPage }),
                        React.createElement(Route, { path: "/recommendations", component: RecommendationsPages }),
                        React.createElement(Route, { path: "/directory", component: DirectoryPages }),
                        React.createElement(Route, { path: "/lifecycle", component: LifecyclePages }),
                        React.createElement(Route, { path: "/guests", component: GuestPages }),
                        React.createElement(Route, { path: "/workspaces", component: WorkspacePages }),
                        React.createElement(Route, { path: "/tools", component: ToolPages }),
                        React.createElement(Route, { path: "/settings", component: SettingPages }),
                        React.createElement(Route, { path: "/orchestry-admin", component: OrchestryAdminPages }),
                        React.createElement(Route, { path: "/hangfire", component: DevHangfire }),
                        React.createElement(Route, { path: "/client-api", component: ExternalApiPages }))),
                React.createElement(Redirect, { to: "/error/error-v1" })))));
}
