import { AsyncCallType } from "app/store/async/types";
import * as ReportStoreTypes from "app/store/lifecycle-report/search/types";
export var lifecycleReportsActionMap = [
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Search,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/search",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Load_WithContext,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/StateWithContext",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Load_Minimal,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/stateminimal",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Load_Refiners,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/StateRefiners",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Load_Views,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/GetViews",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Load_Favs,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/StateFavs",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Load_RefinersAndFavs,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/StateRefinersAndFavs",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Update_View,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/UpdateView",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_GenerateFile,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/GenerateReport",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Refresh,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/update",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_RefreshSilent,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/update",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Update_Paging,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/update",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Update_Context,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/update",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Update_Refiners,
        callType: AsyncCallType.Post,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/update",
        responseDataType: {}
    },
    {
        actionType: ReportStoreTypes.ReportSearchActionTypes.Report_Update_FavsOnServer,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/lifecyclereports/UpdateFavs",
        responseDataType: {}
    }
];
