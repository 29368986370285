import * as React from "react";
import "./group-search-dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Popover, OverlayTrigger } from "react-bootstrap";
import GroupsService from "app/services/groups";
import GroupProfilePicture from "app/components/common/group-profile-picture";
import AnimatedLoader from "../animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import useWindowDimensions from "app/utils/windowDimentions";
var GroupSearchDropdown = React.memo(function (props) {
    var width = useWindowDimensions().width;
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var inputEl = React.useRef();
    //@ts-ignore
    var overlayTriggerEl = React.useRef();
    var spanEl = React.useRef();
    var popoverEl = React.useRef();
    var _b = React.useState([]), searchResults = _b[0], setSearchResults = _b[1];
    var suggestedUsers = React.useState([])[0];
    var _c = React.useState(false), mobileOpen = _c[0], setMobileOpen = _c[1];
    //const [totalAjaxRequest, setTotalAjaxRequest] = React.useState(0);
    //const [totalAjaxRequestComplete, setTotalAjaxRequestComplete] = React.useState(0);
    //console.log("rerender");
    var _d = React.useState(-1), cursor = _d[0], setCursor = _d[1];
    // Get the search results.
    var getResults = function (searchValue) {
        //setTimeout(() => {
        //console.log("timeout", searchValue, inputEl.current.value, searchValue === inputEl.current.value)
        if (searchValue === inputEl.current.value) {
            //
            if (searchValue.length === 0) {
                setIsLoading(false);
                setSearchResults([]);
                // This will cancel the last request.
                GroupsService.searchGraphGroups(searchValue, 50).then(function (resultData) { });
            }
            else {
                GroupsService.searchGraphGroups(searchValue, 50).then(function (resultData) {
                    //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                    try {
                        if (searchValue === inputEl.current.value) {
                            setIsLoading(false);
                            setSearchResults(resultData);
                        }
                    }
                    catch (_a) { }
                });
            }
        }
        //},500)
    };
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        }
        else if (e.keyCode === 40 && cursor < currentResults.length - 1) {
            setCursor(cursor + 1);
        }
        else if (e.keyCode === 13) {
            clickUser(currentResults[cursor]);
        }
    };
    var shouldShowNoResult = function () {
        var shouldShow = false;
        if (searchResults !== undefined) {
            if (searchResults.length === 0) {
                if (inputEl !== undefined) {
                    if (inputEl.current !== undefined) {
                        if (inputEl.current !== null) {
                            if (inputEl.current.value !== undefined) {
                                if (inputEl.current.value !== null) {
                                    if (inputEl.current.value.length > 0) {
                                        if (isLoading === false) {
                                            shouldShow = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldUseScrollbar = function () {
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (currentResults.length > 5) {
            return true;
        }
        return false;
    };
    var clickUser = function (group) {
        props.onGroupSelected(group);
        try {
            // @ts-ignore
            overlayTriggerEl.current.hide();
        }
        catch (_a) { }
        setCursor(-1);
        setSearchResults([]);
        inputEl.current.value = "";
    };
    var content = (React.createElement("div", { ref: popoverEl, className: "group-content" },
        React.createElement("div", { className: "people-header" },
            React.createElement("input", { type: "text", placeholder: "Search for group by name", onChange: function (event) {
                    setIsLoading(true);
                    getResults(event.target.value);
                }, onKeyDown: function (event) {
                    handleKeyDown(event);
                }, ref: inputEl }),
            React.createElement("div", { className: "icon" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faSearch }))),
        React.createElement("div", { className: "people-list" },
            React.createElement("ul", { className: "list-unstyled" },
                React.createElement(DynamicScrollbar, { shouldShow: shouldUseScrollbar(), maxHeight: 200 },
                    isLoading === false &&
                        searchResults !== undefined &&
                        searchResults.map(function (rs, index) { return (React.createElement("li", { className: cursor === index ? "selected" : undefined, key: index, onMouseEnter: function () {
                                setCursor(-1);
                            }, onClick: function () {
                                clickUser(rs);
                            } },
                            React.createElement("div", { className: "pic" },
                                React.createElement(GroupProfilePicture, { uid: rs.GUID, displayName: rs.DisplayName })),
                            React.createElement("div", { className: "name" },
                                rs.DisplayName,
                                rs.UserPrincipalName !== undefined && rs.UserPrincipalName.indexOf("#EXT#") > -1 && React.createElement("span", { className: "guest-note" }, "(Guest)")))); }),
                    shouldShowNoResult() ? React.createElement("li", { className: "no-results" }, "No results") : null,
                    isLoading ? (React.createElement("li", { className: "loader" },
                        React.createElement(AnimatedLoader, { size: "small", theme: "dark" }))) : null)))));
    var popover = (React.createElement(Popover, { id: "popover-basic", className: "group-picker" }, content));
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleClick = function (e) {
        var clickInside = false;
        // let rootclick = false;
        if (popoverEl.current !== undefined) {
            if (popoverEl.current !== null) {
                if (popoverEl.current.contains !== undefined) {
                    if (popoverEl.current.contains !== null) {
                        if (popoverEl.current.contains(e.target)) {
                            //console.log("click inside")
                            //setMobileOpen(false)
                            clickInside = true;
                        }
                    }
                }
            }
        }
        if (spanEl.current !== undefined) {
            if (spanEl.current !== null) {
                if (spanEl.current.contains !== undefined) {
                    if (spanEl.current.contains !== null) {
                        if (spanEl.current.contains(e.target)) {
                            //console.log("click inside")
                            //setMobileOpen(false)
                            clickInside = true;
                        }
                    }
                }
            }
        }
        if (clickInside === false) {
            try {
                // @ts-ignore
                overlayTriggerEl.current.hide();
            }
            catch (_a) { }
            // setMobileOpen(false)
        }
    };
    return (React.createElement(React.Fragment, null, width < 768 ? (React.createElement(React.Fragment, null,
        React.createElement("span", { onClick: function () {
                setMobileOpen(!mobileOpen);
            }, ref: spanEl }, props.children),
        React.createElement(React.Fragment, null, mobileOpen ? content : null))) : (
    //@ts-ignore
    React.createElement(OverlayTrigger, { trigger: ["click"], placement: "right-start", overlay: popover, ref: overlayTriggerEl }, props.children))));
});
export default GroupSearchDropdown;
