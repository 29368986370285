import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AuthStore from "app/store/auth";
import * as routerHelpers from "app/router/RouterHelpers";
import { popupCenter } from "app/utils/popupCenter";
import AnimatedLoader from "app/components/common/animated-loader";
var popupSignoutWindow = null;
var Logout = React.memo(function (props) {
    //const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    var dispatch = useDispatch();
    var auth = useSelector(function (state) { return state.authState; });
    var _a = useState(false), signoutWindowOpen = _a[0], setSignoutWindowOpen = _a[1];
    var _b = useState(false), signoutWindowDone = _b[0], setSignoutWindowDone = _b[1];
    // Listen for popup callback.
    React.useEffect(function () {
        // let urlPrefix = "https://localhost:44316";
        // if(window.location.href.indexOf("3000") === -1){
        //   urlPrefix = window.location.origin;
        // }
        var urlPrefix = window.location.origin;
        popupSignoutWindow = popupCenter(urlPrefix + "/Account/SignOut/", "Orchestry Logout", 500, 700);
        setSignoutWindowOpen(true);
        //@ts-ignore
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        //@ts-ignore
        eventer(messageEvent, 
        //@ts-ignore
        function (e) {
            if (e.data !== undefined && e.data.SignedOut !== undefined) {
                setSignoutWindowDone(true);
            }
        }, false);
    }, []);
    // Watch for Signout Close.
    React.useEffect(function () {
        if (signoutWindowOpen === true) {
            var loop = setInterval(function () {
                try {
                    console.log("popup closed", popupSignoutWindow);
                    if (popupSignoutWindow.closed) {
                        clearInterval(loop);
                        setSignoutWindowDone(true);
                        setSignoutWindowOpen(false);
                    }
                }
                catch (_a) { }
            }, 500);
        }
    }, [signoutWindowOpen]);
    var windowFocusListener = function () {
        //console.log("Focus Achived!");
        setSignoutWindowDone(true);
    };
    React.useEffect(function () {
        // add when mounted
        window.addEventListener("focus", windowFocusListener);
        // return function to be called when unmounted
        return function () {
            window.removeEventListener("focus", windowFocusListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        if (signoutWindowDone === true) {
            console.log("Logged Out");
            routerHelpers.forgotLastLocation();
            dispatch(AuthStore.Logout());
        }
    }, [signoutWindowDone]);
    return (React.createElement("div", { className: "logout", style: { marginTop: "50px" } },
        React.createElement(AnimatedLoader, { size: "large", text: "Waiting for logout from Microsoft account" })));
});
export default Logout;
