var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import RequestWizardPermissionsGuests from "./components/request-wizard-permissions-guests";
import RequestWizardPermissionsMembers from "./components/request-wizard-permissions-members";
import RequestWizardPermissionsOwners from "./components/request-wizard-permissions-owners";
import RequestWizardPermissionsPrivacy from "./components/request-wizard-permissions-privacy";
import "./step-permissions.scss";
var StepPermissions = React.memo(function (props) {
    var intl = useIntl();
    var rwc = React.useContext(RequestWizardContext);
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(false), shouldExcludeGuests = _a[0], setShouldExcludeGuests = _a[1];
    var _b = React.useState(false), shouldExcludeGuestsSensitivity = _b[0], setShouldExcludeGuestsFromSensitivity = _b[1];
    var _c = React.useState(false), shouldExcludeGuestsGuestPolicy = _c[0], setShouldExcludeGuestsFromGuestPolicy = _c[1];
    var _d = React.useState(false), sensitivityLabelsEnabled = _d[0], setSensitivityLabelsEnabled = _d[1];
    React.useEffect(function () {
        if (shouldExcludeGuestsSensitivity || shouldExcludeGuestsGuestPolicy) {
            setShouldExcludeGuests(true);
        }
        else {
            setShouldExcludeGuests(false);
        }
    }, [shouldExcludeGuestsSensitivity, shouldExcludeGuestsGuestPolicy]);
    // Using this effect to see if the continue button should be available.
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var isValid = true;
        var notValidText = [];
        notValidText.push("<ul class='continue-warning-list'>");
        if (rwc.currentRequest.Owners !== undefined) {
            if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.MinOwners) !== undefined && ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.MinOwners) > 0) {
                if (rwc.currentRequest.Owners.length < ((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.MinOwners)) {
                    isValid = false;
                    var liContent = intl.formatMessage({
                        id: "page.request_wizard.step_permissions.invalid_user_count",
                        defaultMessage: "Please select at least {minnumber} additional {users}"
                    }, {
                        minnumber: (_d = rwc.currentRequest.Template) === null || _d === void 0 ? void 0 : _d.MinOwners,
                        users: ((_e = rwc.currentRequest.Template) === null || _e === void 0 ? void 0 : _e.MinOwners) !== 1
                            ? intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_owners_label_plural", defaultMessage: "owners" })
                            : intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_owners_label_singular", defaultMessage: "owner" })
                    });
                    notValidText.push("<li>" + liContent + "</li>");
                }
            }
            else {
                if (rwc.currentRequest.Owners.length === 0) {
                    isValid = false;
                }
            }
        }
        else {
            if (currentUser !== undefined) {
                if (currentUser.Id !== undefined) {
                    if (rwc.hasRemovedSelfFromOwners === false) {
                        addOwner(currentUser);
                    }
                }
            }
        }
        // If min members is being used. Then validate it.
        if (((_f = rwc.currentRequest.Template) === null || _f === void 0 ? void 0 : _f.MinMembers) !== undefined && ((_g = rwc.currentRequest.Template) === null || _g === void 0 ? void 0 : _g.MinMembers) > 0) {
            if (rwc.currentRequest.Members === undefined || (rwc.currentRequest.Members !== undefined && rwc.currentRequest.Members.length < ((_h = rwc.currentRequest.Template) === null || _h === void 0 ? void 0 : _h.MinMembers))) {
                isValid = false;
                var liContent = intl.formatMessage({
                    id: "page.request_wizard.step_permissions.invalid_user_count",
                    defaultMessage: "Please select at least {minnumber} {members}"
                }, {
                    minnumber: (_j = rwc.currentRequest.Template) === null || _j === void 0 ? void 0 : _j.MinMembers,
                    members: ((_k = rwc.currentRequest.Template) === null || _k === void 0 ? void 0 : _k.MinMembers) !== 1
                        ? intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_members_label_plural", defaultMessage: "members" })
                        : intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_members_label_singular", defaultMessage: "member" })
                });
                notValidText.push("<li>" + liContent + "</li>");
            }
        }
        // if (rwc.currentRequest.Template?.PermissionType !== undefined) {
        if (rwc.currentRequest.PermissionType === undefined &&
            !(rwc.currentRequest.Template !== undefined && orchestryContext.TenantIsEducation === true && rwc.currentRequest.Template.TeamTemplateSpecialization === "educationClass")) {
            isValid = false;
            notValidText.push("<li>" +
                intl.formatMessage({ id: "page.request_wizard.step_permissions.select_permission_level", defaultMessage: "Please select a Permission level for the Workspace before continuing." }) +
                "</li>");
        }
        if (sensitivityLabelsEnabled === true && rwc.getSelectedSensitivityLabel() === null) {
            isValid = false;
            notValidText.push("<li>" +
                intl.formatMessage({ id: "page.request_wizard.step_permissions.select_sensitivity_level", defaultMessage: "Please select a Sensitivity level for the Workspace before continuing." }) +
                "</li>");
        }
        if (isValid === false) {
            notValidText.push("</ul>");
        }
        var currentCanProgress = props.step.canProgress;
        if (isValid && currentCanProgress === false) {
            rwc.setStepProgress(props.step.key, true);
        }
        if (!isValid) {
            if (props.step.disabledContinueButtonText !== notValidText.join("")) {
                rwc.setStepProgress(props.step.key, false, notValidText.join(""));
            }
            else {
                if (currentCanProgress === true) {
                    rwc.setStepProgress(props.step.key, false);
                }
            }
        }
    }, [rwc.currentRequest.Owners, rwc.currentRequest.Members, rwc.currentRequest.PermissionType, rwc.currentRequest.SensitivityLabel, rwc.currentRequest.Template, sensitivityLabelsEnabled]);
    var addOwner = function (user) {
        var tempArray = [user];
        if (rwc.currentRequest.Owners !== undefined) {
            if (rwc.currentRequest.Owners.length > 0) {
                if (rwc.currentRequest.Owners.some(function (u) { return u.UserPrincipalName === user.UserPrincipalName; })) {
                    tempArray = __spreadArray([], rwc.currentRequest.Owners, true);
                }
                else {
                    tempArray = __spreadArray(__spreadArray([], rwc.currentRequest.Owners, true), tempArray, true);
                }
            }
        }
        rwc.updateCurrentRequest({ Owners: tempArray });
    };
    return (React.createElement("div", { className: "step-permissions" },
        React.createElement("div", { className: "users" },
            React.createElement(Row, { className: "list-of-users" },
                React.createElement(Col, { md: 6, className: "left" },
                    React.createElement(RequestWizardPermissionsOwners, { ShouldExcludeGuests: shouldExcludeGuests })),
                React.createElement(Col, { md: 6, className: "right" },
                    React.createElement(RequestWizardPermissionsMembers, { ShouldExcludeGuests: shouldExcludeGuests }))),
            React.createElement(Row, { className: "list-of-users" },
                React.createElement(Col, { md: 6, className: "left" },
                    React.createElement(RequestWizardPermissionsGuests, { SetShouldExcludeGuests: setShouldExcludeGuestsFromGuestPolicy, DoesSensitivityLabelAllowGuests: !shouldExcludeGuestsSensitivity })))),
        React.createElement(Row, null,
            React.createElement(RequestWizardPermissionsPrivacy, { SetSensitivityLabelsEnabled: setSensitivityLabelsEnabled, SetShouldExcludeGuests: setShouldExcludeGuestsFromSensitivity }))));
});
export default StepPermissions;
