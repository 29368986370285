import axios from "axios";
var DatabaseBrowserService = /** @class */ (function () {
    function DatabaseBrowserService() {
    }
    DatabaseBrowserService.GetDatabases = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/database/DbTableStructures";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    DatabaseBrowserService.Query = function (source, query, tenantGuid, selectedTable) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/database/QueryDb";
            axiosConfig.responseType = "json";
            var bodyFormData = new FormData();
            bodyFormData.set("data", JSON.stringify({
                Source: source,
                Query: query,
                TenantGuid: tenantGuid,
                TableName: selectedTable
            }));
            axiosConfig.data = bodyFormData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return DatabaseBrowserService;
}());
export default DatabaseBrowserService;
