var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Group, Skeleton, Tabs } from "@mantine/core";
import React from "react";
import { OrchestryTooltip } from "../orchestry-tooltip/orchestry-tooltip.component";
import { OrchestryTabsInfo } from "./orchetry-tabs-info.component";
/**
 * A component that displays a set of tabs, and renders the content property of the selected tab.
 */
export var OrchestryTabs = function (_a) {
    var tab = _a.tab, variant = _a.variant, tabs = _a.tabs, isDeactivated = _a.isDeactivated, onTabChange = _a.onTabChange, showCount = _a.showCount, isLoading = _a.isLoading, tabCountVariant = _a.tabCountVariant, props = __rest(_a, ["tab", "variant", "tabs", "isDeactivated", "onTabChange", "showCount", "isLoading", "tabCountVariant"]);
    if (isLoading) {
        return (React.createElement(Group, { gap: 8 }, tabs.map(function (tab) { return (React.createElement(Skeleton, { key: tab.text, height: 34, width: 71, radius: 48 })); })));
    }
    return (React.createElement(Tabs, __assign({ value: tab, onChange: onTabChange, variant: variant !== null && variant !== void 0 ? variant : "pills" }, props),
        React.createElement(Tabs.List, { variant: variant !== null && variant !== void 0 ? variant : "pills" }, tabs.map(function (tabButton) {
            return tabButton.tooltipText ? (React.createElement(OrchestryTooltip, { key: tabButton.value, label: tabButton.tooltipText, withinPortal: false },
                React.createElement(Tabs.Tab, { onClick: function () { var _a; return (_a = tabButton.onClick) === null || _a === void 0 ? void 0 : _a.call(tabButton, tabButton.value); }, variant: variant !== null && variant !== void 0 ? variant : "pills", value: tabButton.value, "data-isdeactivated": isDeactivated !== null && isDeactivated !== void 0 ? isDeactivated : false },
                    tabButton.text,
                    React.createElement(OrchestryTabsInfo, { tabConfig: tabButton, showCount: showCount, tabCountVariant: tabCountVariant })))) : (React.createElement(Tabs.Tab, { key: tabButton.value, onClick: function () { var _a; return (_a = tabButton.onClick) === null || _a === void 0 ? void 0 : _a.call(tabButton, tabButton.value); }, variant: variant !== null && variant !== void 0 ? variant : "pills", value: tabButton.value, "data-isdeactivated": isDeactivated !== null && isDeactivated !== void 0 ? isDeactivated : false },
                tabButton.text,
                React.createElement(OrchestryTabsInfo, { tabConfig: tabButton, showCount: showCount, tabCountVariant: tabCountVariant })));
        }))));
};
