import * as React from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
var PageHeaderContext = React.createContext({});
export var Context = withRouter(function (props) {
    React.useEffect(function () {
        //console.log("Path Change");
        setRightContentChildren(undefined);
        setLeftContentChildren(undefined);
        setTitle(undefined);
        setSubTitle(undefined);
    }, [props.location.pathname]);
    var _a = React.useState(), rightContentChildren = _a[0], setRightContentChildren = _a[1];
    var _b = React.useState(), leftContentChildren = _b[0], setLeftContentChildren = _b[1];
    var _c = React.useState(), title = _c[0], setTitle = _c[1];
    var _d = React.useState(), titleLink = _d[0], setTitleLink = _d[1];
    var _e = React.useState(), subTitle = _e[0], setSubTitle = _e[1];
    var phc = {
        setRightContentChildren: setRightContentChildren,
        rightContentChildren: rightContentChildren,
        setLeftContentChildren: setLeftContentChildren,
        leftContentChildren: leftContentChildren,
        title: title,
        setTitle: setTitle,
        titleLink: titleLink,
        setTitleLink: setTitleLink,
        subTitle: subTitle,
        setSubTitle: setSubTitle
    };
    return (React.createElement(PageHeaderContext.Provider, { value: phc },
        React.createElement(React.Fragment, null, props.children)));
});
export var RenderRightContent = React.memo(function (props) {
    var stc = React.useContext(PageHeaderContext);
    //const headerChildren = findMultipleByObjectDisplayName(props.children, HeaderItem);
    return React.createElement(React.Fragment, null, stc.rightContentChildren !== undefined ? React.createElement("div", { className: "" }, stc.rightContentChildren) : null);
});
export var SetRightContent = React.memo(function (props) {
    var stc = React.useContext(PageHeaderContext);
    React.useEffect(function () {
        try {
            stc.setRightContentChildren(props.children);
        }
        catch (_a) { }
    });
    return React.createElement(React.Fragment, null);
});
SetRightContent.displayName = "SetRightContent";
export var RenderLeftContent = React.memo(function (props) {
    var stc = React.useContext(PageHeaderContext);
    //console.log("Render LEft", stc, props)
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "titles", "data-pw": "titles" },
            React.createElement(React.Fragment, null, stc.title !== undefined && stc.title !== null && stc.title !== "" ? (stc.titleLink !== undefined ? (React.createElement(NavLink, { to: stc.titleLink },
                React.createElement("h3", { className: "kt-subheader__title" }, stc.title))) : (React.createElement("h3", { className: "kt-subheader__title" }, stc.title))) : props.title !== undefined && props.title !== null && props.title !== "" && props.titleLink !== undefined ? (React.createElement(NavLink, { to: props.titleLink },
                React.createElement("h3", { className: "kt-subheader__title" }, props.title))) : (React.createElement("h3", { className: "kt-subheader__title" }, props.title))),
            stc.subTitle !== undefined && stc.subTitle !== null && stc.subTitle !== "" ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "kt-subheader__separator kt-subheader__separator--v" }),
                React.createElement("span", { className: "kt-subheader__desc", "data-pw": "subheader-desc" }, stc.subTitle))) : (React.createElement(React.Fragment, null, props.subTitle !== undefined && props.subTitle !== null && props.subTitle !== "" ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "kt-subheader__separator kt-subheader__separator--v" }),
                React.createElement("span", { className: "kt-subheader__desc", "data-pw": "subheader-desc" }, props.subTitle))) : null))),
        stc.leftContentChildren !== undefined ? React.createElement(React.Fragment, null, stc.leftContentChildren) : null));
});
export var SetLeftContent = React.memo(function (props) {
    var stc = React.useContext(PageHeaderContext);
    //console.log("set left", props)
    React.useEffect(function () {
        try {
            stc.setTitle(props.title);
            stc.setTitleLink(props.titleLink);
            stc.setSubTitle(props.subTitle);
            stc.setLeftContentChildren(props.children);
        }
        catch (_a) { }
    });
    return React.createElement(React.Fragment, null);
});
SetLeftContent.displayName = "SetLeftContent";
