import SettingsAccount from "app/components/settings/settings-account";
import SettingsBlockedWords from "app/components/settings/settings-blocked-words";
import SettingsCategories from "app/components/settings/settings-categories";
import SettingsClassificationsAndLabels from "app/components/settings/settings-classifications-and-labels";
import SettingsGroups from "app/components/settings/settings-groups";
import SettingsInstallation from "app/components/settings/settings-installation";
import SettingsLanguage from "app/components/settings/settings-language";
import SettingsLifecycle from "app/components/settings/settings-lifecycle";
import SettingsNotifications from "app/components/settings/settings-notifications";
import SettingsTeamsApp from "app/components/settings/settings-teams-app";
import { HealthChecksSettingsPage } from "appv2/pages/settings/health-checks/health-checks-settings-page.component";
import { PartnersSettingsPage } from "appv2/pages/settings/partners/partners-settings-page.component";
import { RecommendationSettingsPage } from "appv2/pages/settings/recommendations-settings/recommendations-settings-page.component";
import React from "react";
import { Route, Switch } from "react-router-dom";
export default function SettingPages() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/settings/blocked-words", component: SettingsBlockedWords }),
        React.createElement(Route, { exact: true, path: "/settings/categories", component: SettingsCategories }),
        React.createElement(Route, { exact: true, path: "/settings/groups", component: SettingsGroups }),
        React.createElement(Route, { exact: true, path: "/settings/language", component: SettingsLanguage }),
        React.createElement(Route, { exact: true, path: "/settings/account", component: SettingsAccount }),
        React.createElement(Route, { exact: true, path: "/settings/notifications", component: SettingsNotifications }),
        React.createElement(Route, { exact: true, path: "/settings/teams-app", component: SettingsTeamsApp }),
        React.createElement(Route, { exact: true, path: "/settings/installation", component: SettingsInstallation }),
        React.createElement(Route, { exact: true, path: "/settings/classifications-and-labels", component: SettingsClassificationsAndLabels }),
        React.createElement(Route, { exact: true, path: "/settings/lifecycle", component: SettingsLifecycle }),
        React.createElement(Route, { exact: true, path: "/settings/health-check", component: HealthChecksSettingsPage }),
        React.createElement(Route, { exact: true, path: "/settings/recommendations", component: RecommendationSettingsPage }),
        React.createElement(Route, { exact: true, path: "/settings/partners", component: PartnersSettingsPage })));
}
