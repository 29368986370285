var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrchestryFormErrorText } from "appv2/components/orchestry-form-error-text/orchestry-form-error-text.component";
import clsx from "clsx";
import * as React from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import PrincipalItem from "../principal-item";
import PrincipalItemDynamic from "../principal-item-dynamic";
import PrincipalSearchModal from "../principal-search-modal";
import { SkeletonLoaderFakeBox } from "../skeleton-loaders";
import "./form-user-group-select.scss";
var FormUserGroupSelect = React.memo(function (props) {
    var _a = React.useState([]), currentUsersGroups = _a[0], setCurrentUsersGroups = _a[1];
    var allowMultiple = React.useState(props.allowMultiple !== undefined ? props.allowMultiple : false)[0];
    var userListContainer = React.useRef();
    var _b = React.useState(undefined), forceOpen = _b[0], setForceOpen = _b[1];
    var _c = React.useState(undefined), previousForceOpen = _c[0], setPreviousForceOpen = _c[1];
    var intl = useIntl();
    React.useEffect(function () {
        if (props.forceOpen !== undefined) {
            if (previousForceOpen === undefined && props.forceOpen === true) {
                forcePopupToOpen();
            }
            if (previousForceOpen === false && props.forceOpen === true) {
                forcePopupToOpen();
            }
        }
        setPreviousForceOpen(props.forceOpen);
    }, [props.forceOpen]);
    // Set Default Values
    React.useEffect(function () {
        if (props.defaultValue !== undefined && props.defaultValue !== null) {
            if (props.defaultValue.length > 0) {
                var groupObjectsFromString = [];
                try {
                    groupObjectsFromString = JSON.parse(props.defaultValue);
                }
                catch (_a) { }
                //if(groupObjectsFromString.length > 0){
                setCurrentUsersGroups(groupObjectsFromString);
                //}
            }
        }
        //defaultValue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        //console.log("props.value changed", props.value);
        if (props.value !== undefined) {
            if (props.value !== null) {
                if (props.value.length > 0) {
                    var groupObjectsFromString = [];
                    try {
                        groupObjectsFromString = JSON.parse(props.value);
                    }
                    catch (_a) { }
                    //if(groupObjectsFromString.length > 0){
                    setCurrentUsersGroups(groupObjectsFromString);
                    //}
                }
            }
            else {
                setCurrentUsersGroups([]);
            }
        }
        //defaultValue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    var removeUserGroup = function (ug) {
        if (allowMultiple === false) {
            setCurrentUsersGroups([]);
        }
        else {
            setCurrentUsersGroups(currentUsersGroups.filter(function (g) { return (g.Id === ug.Id ? false : true); }));
        }
    };
    React.useEffect(function () {
        if (props.defaultValue !== undefined) {
            props.onChange(JSON.stringify(currentUsersGroups));
        }
        if (props.value !== JSON.stringify(currentUsersGroups)) {
            // console.log("Current User Change", props.value, JSON.stringify(currentUsersGroups))
            props.onChange(JSON.stringify(currentUsersGroups));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUsersGroups]);
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", userListBoxClickHandler);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", userListBoxClickHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var userListBoxClickHandler = function (e) {
        var controlWrapper = null;
        if (userListContainer.current !== undefined) {
            if (userListContainer.current !== null) {
                if (userListContainer.current === e.target) {
                    controlWrapper = e.target.closest(".form-user-group-select");
                }
                if (e.target !== null) {
                    if (userListContainer.current.contains(e.target)) {
                        if (userListContainer.current.querySelector(".no-results") !== null) {
                            controlWrapper = e.target.closest(".form-user-group-select");
                        }
                        if (userListContainer.current.querySelector(".inline-add-user") !== null) {
                            controlWrapper = e.target.closest(".form-user-group-select");
                        }
                    }
                }
            }
        }
        if (controlWrapper !== null) {
            console.log("parent found!");
            var openControl = controlWrapper.querySelector(".user-select-dropdown-wrapper .add");
            if (openControl !== null) {
                openControl.click();
            }
        }
    };
    var getAddTitle = function () {
        if (props.returnType === "usersAndGroups") {
            return "Users and Groups";
        }
        if (props.returnType === "users") {
            return "Users";
        }
        if (props.returnType === "groups") {
            return "Groups";
        }
    };
    var getOrTitle = function () {
        if (props.returnType === "usersAndGroups") {
            return "user or group";
        }
        if (props.returnType === "users") {
            return "user";
        }
        if (props.returnType === "groups") {
            return "group";
        }
    };
    var forcePopupToOpen = function () {
        setForceOpen(true);
        setTimeout(function () {
            setForceOpen(false);
        }, 500);
    };
    var disabled = (props.disabled !== undefined && props.disabled === true) || !!props.hidden;
    var legacyTitle = "Add " + getAddTitle();
    var localizedAddUsersTitle = intl.formatMessage({ id: "form_user_group_select.add_users", defaultMessage: "Add Users" });
    var title = getAddTitle() === "Users" ? localizedAddUsersTitle : legacyTitle;
    return (React.createElement("div", { className: clsx("form-user-group-select", { hidden: disabled }) },
        React.createElement(Form.Group, { className: "form-group", controlId: "control-approval-group" },
            props.label !== undefined ? React.createElement(Form.Label, null, props.label) : null,
            allowMultiple || currentUsersGroups.length === 0 ? (disabled ? (React.createElement(React.Fragment, null)) : (
            //  <span className="user-select-dropdown-wrapper">
            //  <UserSearchDropdown
            //      onUserSelected={(group:Group)=> {setCurrentUsersGroups([...currentUsersGroups, ...[group]])}}
            //      excludeGuests={props.excludeGuests}
            //      includeGroups={props.returnType === undefined ? true : false}
            //      suggestedTitle="Suggested Users">
            //          {props.displayAddAsButton !== undefined && props.displayAddAsButton ?
            //          <div className="add-button">
            //              <FontAwesomeIcon icon={Icons.faPlus} />
            //              <span>Add {props.returnType !== undefined && props.returnType === "user" ? "User": ""}{props.returnType !== undefined && props.returnType === "group" ? "Group": ""}{props.returnType === undefined ? "User or Group":""}</span>
            //          </div>
            //          :
            //          <div className="add">
            //              <FontAwesomeIcon icon={Icons.faPlusCircle} />
            //          </div>}
            //  </UserSearchDropdown></span>
            React.createElement("span", { className: "user-select-dropdown-wrapper" + (props.displayAddInline !== undefined && props.displayAddInline === true ? " inline-button" : "") },
                React.createElement(PrincipalSearchModal, { isParentLoading: props.isLoading, suggestedTitle: intl.formatMessage({ id: "form_user_group_select.suggested_users", defaultMessage: "Suggested users" }), mode: props.returnType, title: title, selectedPrincipals: currentUsersGroups, adminTenantGuid: props.adminTenantGuid, excludeGuests: props.excludeGuests, onPrincipalSelected: function (principal) {
                        setCurrentUsersGroups(__spreadArray(__spreadArray([], currentUsersGroups, true), [principal], false));
                    }, onPrinciaplRemoved: function (principal) {
                        removeUserGroup(principal);
                    }, forceOpen: forceOpen }, props.displayAddInline !== undefined && props.displayAddInline === true ? (React.createElement(React.Fragment, null)) : props.displayAddAsButton !== undefined && props.displayAddAsButton ? (React.createElement("div", { className: "add-action" },
                    React.createElement("div", { className: "add-button", "data-pw": "add-button" },
                        React.createElement(FontAwesomeIcon, { icon: Icons.faPlus }),
                        React.createElement("span", null,
                            "Add ",
                            getAddTitle())))) : (React.createElement("div", { className: "add-action" },
                    React.createElement("div", { className: "add" },
                        React.createElement(FontAwesomeIcon, { icon: Icons.faPlusCircle })))))))) : null,
            props.isLoading ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60 })) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "group-area form-control", ref: userListContainer },
                    currentUsersGroups.length > 0
                        ? currentUsersGroups.map(function (ug, gindex) {
                            return props.shouldUseDynamic ? (React.createElement(PrincipalItemDynamic, { principalIdOrEmail: ug.Id, key: ug.Id, showBorder: true, onRemove: function (principal) { return removeUserGroup(principal); } })) : (React.createElement(PrincipalItem, { principal: ug, key: ug.Id, showBadge: true, onRemove: function (principal) { return removeUserGroup(principal); } }));
                        }
                        // <span key={gindex}>
                        //    {(ug as User).UserPrincipalName === undefined || ((ug as User).Type !== undefined && (ug as User).Type === "Group") ?
                        //       props.disabled !== undefined && props.disabled === true ?
                        //          <GroupItem showBadge={true} group={ug} key={gindex} />
                        //          :
                        //          <GroupItem showBadge={true} group={ug} onRemove={(userGroupToRemove) => { removeUserGroup(userGroupToRemove) }} key={gindex} />
                        //       : (ug as User).UserPrincipalName !== undefined ?
                        //          props.disabled !== undefined && props.disabled === true ?
                        //             <UserItem showBadge={true} user={(ug as User)} key={gindex} />
                        //             :
                        //             <UserItem showBadge={true} user={(ug as User)} onRemove={(userGroupToRemove) => { removeUserGroup(userGroupToRemove) }} key={gindex} />
                        //          : null
                        //    }
                        // </span>
                        )
                        : null,
                    props.displayAddInline !== undefined && props.displayAddInline === true && (React.createElement("div", { className: "inline-add-user", onClick: function () { return forcePopupToOpen(); } },
                        React.createElement("div", { className: "inline-icon" },
                            React.createElement("i", { className: "fa fa-user" })),
                        React.createElement("div", { className: "inline-text" }, "Add User"))),
                    currentUsersGroups.length === 0 && (props.displayAddInline === undefined || (props.displayAddInline !== undefined && props.displayAddInline === false)) ? (React.createElement("div", { className: "no-results", style: { paddingBottom: "15px" } }, props.placeholder !== undefined
                        ? props.placeholder
                        : "No " + getOrTitle() + (allowMultiple ? "(s)" : "") + " selected. Click the + button above to add a " + getOrTitle() + ".")) : null),
                React.createElement(OrchestryFormErrorText, { error: props.error }))))));
});
export default FormUserGroupSelect;
