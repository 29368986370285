var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Portlet, PortletBody, PortletFooter, PortletHeader } from "app/components/common/portlet";
import TagsInput from "react-tagsinput";
import axios from "axios";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import OrchestryService from "app/services/orchestry";
var OrchestryAdminToolStressTest = React.memo(function () {
    var _a = React.useState({
        Region: "",
        NamePrefix: "",
        UrlPrefix: "",
        NumberOfTests: 1,
        Requests: []
    }), orchestryTestTask = _a[0], setOrchestryTestTask = _a[1];
    var _b = React.useState(false), runStarted = _b[0], setRunStarted = _b[1];
    var _c = React.useState([]), activeRegions = _c[0], setActiveRegions = _c[1];
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    var runTest = function () {
        var formData = new FormData();
        formData.append("task", JSON.stringify(orchestryTestTask));
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "POST";
        axiosConfig.url = "/api/OrchestryTests/StartStressTest";
        axiosConfig.responseType = "json";
        axiosConfig.data = formData;
        axios(axiosConfig)
            .then(function (response) {
            setRunStarted(true);
            setTimeout(function () {
                setRunStarted(false);
            }, 10000);
        })
            .catch(function (response) { });
    };
    return (React.createElement("div", { className: "home-admin fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6 },
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Run Duplication Tool" }),
                    React.createElement(PortletBody, null,
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                            React.createElement(Form.Label, null, "Name Prefix"),
                            React.createElement(Form.Control, { placeholder: "Orchestry Test - ", autoComplete: "off", onChange: function (event) { return setOrchestryTestTask(__assign(__assign({}, orchestryTestTask), { NamePrefix: event.currentTarget.value })); } })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                            React.createElement(Form.Label, null, "Url Prefix - Warning - There is no validation here, so if you rerun a test, make sure to change this."),
                            React.createElement(Form.Control, { placeholder: "url-prefix-", autoComplete: "off", onChange: function (event) { return setOrchestryTestTask(__assign(__assign({}, orchestryTestTask), { UrlPrefix: event.currentTarget.value })); } })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-position" },
                            React.createElement(Form.Label, null, "Interations"),
                            React.createElement(Form.Control, { type: "number", name: "points", step: "1", defaultValue: 1, onChange: function (event) { return setOrchestryTestTask(__assign(__assign({}, orchestryTestTask), { NumberOfTests: Number(event.currentTarget.value) })); } })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-region" },
                            React.createElement(Form.Label, null, "Requests Region"),
                            React.createElement(FormDropdownSelect, { list: activeRegions, value: orchestryTestTask.Region, onChange: function (value) { return setOrchestryTestTask(__assign(__assign({}, orchestryTestTask), { Region: value })); } })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "request-guids" },
                            React.createElement(Form.Label, null, "Request GUIDs - Can be from any tenant in the selected region"),
                            React.createElement(TagsInput, { value: orchestryTestTask.Requests, onChange: function (tags) { return setOrchestryTestTask(__assign(__assign({}, orchestryTestTask), { Requests: tags })); }, addKeys: [9, 13, 188], removeKeys: [8, 46] }))),
                    React.createElement(PortletFooter, null, runStarted === true ? (React.createElement(React.Fragment, null, "Run Started. Check out hangfire. You can run another test in 10 seconds..")) : (React.createElement("div", { className: "btn btn-primary", onClick: function () { return runTest(); } }, "Run Test"))))),
            React.createElement(Col, { md: 6 }))));
});
export default OrchestryAdminToolStressTest;
