var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
// isAuthorized : false
};
export function authReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.Auth_Login: {
            //console.log(AuthActionTypes.Auth_Load, action)
            return __assign({}, action.payload);
        }
        case AuthActionTypes.Auth_GetAccessToken: {
            var updatedAuth = Object.assign({}, state, action.payload);
            return __assign({}, updatedAuth);
        }
        case AuthActionTypes.Auth_IsAccessTokenValid: {
            var updatedAuth = Object.assign({}, state, action.payload);
            return __assign({}, updatedAuth);
        }
        case AuthActionTypes.Auth_Logout: {
            return initialState;
        }
        default:
            return state;
    }
}
