import { TenantLicenseStatus } from "appv2/api/partner-tools/tenant-license-status.type";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryBadge } from "../orchestry-badge/orchestry-badge.component";
export var OrchestryLicenseBadge = function (_a) {
    var tenantLicenseStatus = _a.tenantLicenseStatus;
    if (tenantLicenseStatus === TenantLicenseStatus.Orchestry) {
        return React.createElement(OrchestryBadge, { svgIcon: { iconNeutral: ORCH_SVG_ICONS.Logomark, iconSelected: ORCH_SVG_ICONS.Logomark }, title: "Orchestry", color: "var(--mantine-color-primary-8)" });
    }
    if (tenantLicenseStatus === TenantLicenseStatus.Beacon) {
        return (React.createElement(OrchestryBadge, { svgSize: "xs", svgIcon: { iconNeutral: ORCH_SVG_ICONS.BeaconLogomark, iconSelected: ORCH_SVG_ICONS.BeaconLogomark }, title: "Beacon", color: "var(--mantine-color-primary-8)" }));
    }
    if (tenantLicenseStatus === TenantLicenseStatus.OrchestryTrial) {
        return React.createElement(OrchestryBadge, { value: TenantLicenseStatus.OrchestryTrial, icon: ORCH_ICONS.hourglass03, title: "Trial" });
    }
    if (tenantLicenseStatus === TenantLicenseStatus.Unlicensed) {
        return React.createElement(OrchestryBadge, { value: TenantLicenseStatus.Unlicensed, icon: ORCH_ICONS.cloudOff, title: "Unlicensed" });
    }
    return null;
};
