import { Card, Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryAreaChart } from "appv2/components/orchestry-area-chart/orchestry-area-chart.component";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { omitKeys } from "appv2/utils/object.utils";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var GrowthChartTile = function () {
    var _a;
    var _b = useHealthCheckActivity(), isMobile = _b.isMobile, intl = _b.intl, isLoading = _b.isLoading, _c = _b.data, _d = _c === void 0 ? {} : _c, _e = _d.MetricsData, _f = _e === void 0 ? {} : _e, _g = _f.Workspaces, _h = _g === void 0 ? {} : _g, Growth = _h.Growth;
    var dataWithoutDeltas = (_a = Growth === null || Growth === void 0 ? void 0 : Growth.Data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return omitKeys(item, ["PreviousValue", "DeltaValue", "DeltaPercentage"]); });
    var showJumpInDataMessage = (Growth === null || Growth === void 0 ? void 0 : Growth.DataCoverageStartDate)
        ? new Date("2024-07-12") >= new Date(Growth === null || Growth === void 0 ? void 0 : Growth.DataCoverageStartDate) && new Date("2024-07-12") <= new Date(Growth === null || Growth === void 0 ? void 0 : Growth.DataCoverageEndDate)
        : false;
    return (React.createElement(Card, { className: "health-check-growth-chart-tile", h: 393, mih: 393, miw: isMobile ? undefined : "360px", style: { flex: 1 }, variant: "default", p: 24 },
        React.createElement(Group, { justify: "space-between", gap: "13px", grow: true, preventGrowOverflow: false, wrap: "nowrap" },
            React.createElement(Group, null,
                React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "md", variant: "double-circle", colorVariant: "success-7", iconClassName: ORCH_ICONS.activity, imgIconHeight: 20, imgIconWidth: 20 }),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "16", fw: 400, lh: "24px" }, intl.formatMessage({ id: "page.health_check.growth_chart_tile.label", defaultMessage: "Growth" }))),
            showJumpInDataMessage && (React.createElement(Group, { preventGrowOverflow: true, gap: "8px", wrap: "nowrap", flex: "0 0 50%", align: "flex-start" },
                React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-gray-6)", isLoading: isLoading, fontSize: 16, lineHeight: "16px", iconClassName: ORCH_ICONS.infoCircle }),
                React.createElement(OrchestryText, { isLoading: isLoading, fz: 12, c: "text-primary.0", lh: "16px" }, intl.formatMessage({
                    id: "page.health_check.growth_chart_tile.workspace_growth_anomaly_message",
                    defaultMessage: "A jump in data on July 12 is expected and corresponds with Orchestry now counting your OneDrive Workspaces"
                }))))),
        React.createElement(Group, { justify: "Space-between", align: "Flex-end", mt: 24 },
            React.createElement(Stack, { gap: "0px" },
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "24", fw: 500, lh: "32px" }, Growth === null || Growth === void 0 ? void 0 : Growth.Total.toLocaleString()),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-tertiary.0", fz: "14", fw: 400, lh: "20px" }, intl.formatMessage({ id: "page.health_check.growth_chart_tile.workspace_change", defaultMessage: "Workspace Change" }))),
            React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, lastPeriodValue: Growth === null || Growth === void 0 ? void 0 : Growth.PreviousValue, deltaValue: Growth === null || Growth === void 0 ? void 0 : Growth.DeltaValue, deltaPercentage: Growth === null || Growth === void 0 ? void 0 : Growth.DeltaPercentage, daysAgoCount: Growth === null || Growth === void 0 ? void 0 : Growth.DataCoverageDaysCount, dataCoverageAvailable: Growth === null || Growth === void 0 ? void 0 : Growth.DataCoverageAvailable })),
        React.createElement(OrchestryAreaChart, { isLoading: isLoading, curveType: "bump", yAxisRange: Growth === null || Growth === void 0 ? void 0 : Growth.YAxisRange, height: "200px", data: dataWithoutDeltas !== null && dataWithoutDeltas !== void 0 ? dataWithoutDeltas : [], lastPeriodData: Growth === null || Growth === void 0 ? void 0 : Growth.Data, series: [{ name: "Growth", color: "var(--mantine-color-success-7)" }], dataKey: "date", pt: 24, mt: 24 })));
};
