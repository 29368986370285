import { GetGuestStatusEnum } from "app/components/insights/guests/common/guest-status-box";
import { isArray } from "lodash";
import { GuestManagementAccessViolations } from "app/store/lifecycle-guest";
import moment from "moment";
export var generateReasons = function (item, DaysInactive, ShowGuestsNotEnabled) {
    if (item === undefined)
        return;
    var newReasons = [];
    // let pendingAcceptanceThreshold = moment().add(-90, "days");
    var itemStatus = GetGuestStatusEnum(item, DaysInactive);
    if (item.AccessViolations !== undefined &&
        item.AccessViolations !== null &&
        isArray(item.AccessViolations) &&
        item.AccessViolations.some(function (av) { return av === "BlockedDomain" || av === Number(GuestManagementAccessViolations.BlockedDomain.valueOf()); })) {
        newReasons.push({
            Reason: "BlockedDomain",
            Title: "Account is from a blocked domain",
            Color: "red"
        });
    }
    if (ShowGuestsNotEnabled &&
        item.AccessViolations !== undefined &&
        item.AccessViolations !== null &&
        isArray(item.AccessViolations) &&
        item.AccessViolations.some(function (av) { return av === "GuestsNotAllowed" || av === Number(GuestManagementAccessViolations.GuestsNotAllowed.valueOf()); })) {
        newReasons.push({
            Reason: "GuestsNotAllowed",
            Title: "Guests are not enabled for this workspace",
            Color: "red"
        });
    }
    if (item.GroupMembershipsCount === 0) {
        newReasons.push({
            Reason: "NoGroups",
            Title: "Account has no group access",
            Color: "yellow"
        });
    }
    if (itemStatus === "Inactive") {
        newReasons.push({
            Reason: "Inactive",
            Title: "Account inactive for over " + DaysInactive + " days",
            Color: "orange"
        });
    }
    // if (itemStatus === "PendingAcceptance" && props.Item.FirstPendingAcceptanceDate > inactiveDaysThreshold) {
    // Don't need to verify acceptance date here since it's in the cosmos query on the server.
    if (itemStatus === "PendingAcceptance" && item.FirstPendingAcceptanceDate != null && moment.utc().diff(moment.utc(item.FirstPendingAcceptanceDate), "days") >= 90) {
        newReasons.push({
            Reason: "PendingAcceptance",
            Title: "Invitation not redeemed for over 90 days",
            Color: "red"
        });
    }
    return newReasons;
};
