import { LifecycleGuestCurrentActionTypes } from "app/store/lifecycle-guest/current/types";
import { AsyncCallType } from "app/store/async/types";
export var lifecycleGuestCurrentActionMap = [
    {
        actionType: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecycleGuest?guestId={UserId}",
        responseDataType: {}
    },
    {
        actionType: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_With_CosmosItem,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecycleGuest?guestId={UserId}&includeCosmosObject=true",
        responseDataType: {}
    },
    {
        actionType: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_CosmosItem,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecycleGuest/CosmosItem?guestId={UserId}",
        responseDataType: {}
    },
    {
        actionType: LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_History,
        callType: AsyncCallType.GetCustom,
        keyType: "match",
        ApiUrl: "/api/LifecycleGuest/History?guestId={UserId}&take={Take}&skip={Skip}&historyType={HistoryType}"
    }
];
