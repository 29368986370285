import axios from "axios";
var TeamExplorerService = /** @class */ (function () {
    function TeamExplorerService() {
    }
    TeamExplorerService.GetNodeRoot = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/TeamExplorer/GetNodeRoot";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamExplorerService.GetNodeTeamChannels = function (teamId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/TeamExplorer/GetNodeTeamChannels?teamId=" + teamId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamExplorerService.GetNodeTeamChannelTabs = function (teamId, channelId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/TeamExplorer/GetNodeTeamChannelTabs?teamId=" + teamId + "&channelId=" + channelId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamExplorerService.GetNodeTeamChannelMessages = function (teamId, channelId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/TeamExplorer/GetNodeTeamChannelMessages?teamId=" + teamId + "&channelId=" + channelId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamExplorerService.GetNodeTeamUserOwners = function (teamId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/TeamExplorer/GetNodeTeamUserOwners?teamId=" + teamId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamExplorerService.GetNodeTeamUserMembers = function (teamId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/TeamExplorer/GetNodeTeamUserMembers?teamId=" + teamId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return TeamExplorerService;
}());
export default TeamExplorerService;
