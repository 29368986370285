import { Text, Timeline } from "@mantine/core";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import React from "react";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import "./timeline-expandable-button.scss";
export var TimelineExpandableButton = function (_a) {
    var isExpanded = _a.isExpanded, onClick = _a.onClick;
    return isExpanded ? (React.createElement(Timeline.Item, { className: "timeline-expandable-button", onClick: onClick, bullet: React.createElement(OrchestryFeaturedIcon, { iconClassName: ORCH_ICONS.arrowUp, variant: "light-circle", colorVariant: "gray-5", size: "sm" }) },
        React.createElement(Text, { c: "text-tertiary.0", size: "sm", className: "button-text" }, "View Less"))) : (React.createElement(Timeline.Item, { className: "timeline-expandable-button", onClick: onClick, bullet: React.createElement(OrchestryFeaturedIcon, { iconClassName: ORCH_ICONS.arrowDown, variant: "light-circle", colorVariant: "gray-5", size: "sm" }) },
        React.createElement(Text, { c: "text-tertiary.0", size: "sm", className: "button-text" }, "View More")));
};
