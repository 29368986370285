import { TemplateCurrentActionTypes } from "app/store/templates/current/types";
import { AsyncCallType } from "app/store/async/types";
export var templateCurrentActionMap = [
    {
        actionType: TemplateCurrentActionTypes.Template_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "templateListState.items",
        keyType: "guid",
        ApiUrl: "/api/Templates/?guid={key}",
        responseDataType: {}
    }
];
