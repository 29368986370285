import { Text, Timeline } from "@mantine/core";
import { TimelineExpandableButton } from "appv2/components/timeline/timeline-expandable-button.component";
import { TimelineShowAllButton } from "appv2/components/timeline/timeline-show-all-button.component";
import clsx from "clsx";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { RecommendationCardHistoryDefaultSkeleton } from "./recommendation-card-history-skeleton/recommendation-card-history-default-skeleton.component";
import { RecommendationCardHistoryExpandedSkeleton } from "./recommendation-card-history-skeleton/recommendation-card-history-expanded-skeleton.component";
import "./recommendation-card-history.scss";
import { RecommendationHistoryAvatar } from "./recommendation-history-metadata/recommendation-history-avatar.component";
import { RecommendationHistoryMetadata } from "./recommendation-history-metadata/recommendation-history-metadata.component";
import { useRecommendationCardHistory } from "./use-recommendation-card-history.hook";
/**
 * Renders the Recommendation Card History with a timeline.
 */
export var RecommendationCardHistory = function (_a) {
    var recommendation = _a.recommendation, isCardExpanded = _a.isCardExpanded;
    var _b = useRecommendationCardHistory({ recommendation: recommendation, isCardExpanded: isCardExpanded }), isExpanded = _b.isExpanded, onExpandedButtonClick = _b.onExpandedButtonClick, onShowAllButtonClick = _b.onShowAllButtonClick, renderShowAllButton = _b.renderShowAllButton, shouldRenderExpandableButton = _b.shouldRenderExpandableButton, shouldShowHistory = _b.shouldShowHistory, dataLength = _b.dataLength, getNextPage = _b.getNextPage, infiniteScrollItems = _b.infiniteScrollItems, showInfiniteScroll = _b.showInfiniteScroll, hasMoreDataForInfiniteScroll = _b.hasMoreDataForInfiniteScroll, showDefaultPageSizeSkeleton = _b.showDefaultPageSizeSkeleton, showExpandedPageSizeSkeleton = _b.showExpandedPageSizeSkeleton;
    if (!shouldShowHistory) {
        return null;
    }
    return (React.createElement(LazyMotion, { features: domAnimation },
        React.createElement("div", { className: "recommendation-card-history" },
            showDefaultPageSizeSkeleton && React.createElement(RecommendationCardHistoryDefaultSkeleton, null),
            showExpandedPageSizeSkeleton && React.createElement(RecommendationCardHistoryExpandedSkeleton, null),
            !showDefaultPageSizeSkeleton && !showExpandedPageSizeSkeleton && (React.createElement(AnimatePresence, null,
                React.createElement(m.div, { initial: { opacity: 0.8, translateY: -5 }, animate: { opacity: 1, translateY: 0 }, transition: { duration: 0.3 } },
                    React.createElement(Text, { c: "text-tertiary.0", size: "sm" }, "History"),
                    React.createElement(Timeline, { className: "timeline", bulletSize: 32, lineWidth: 2 },
                        React.createElement(InfiniteScroll, { scrollableTarget: "timeline-items", dataLength: dataLength, next: getNextPage, hasMore: hasMoreDataForInfiniteScroll, loader: React.createElement(React.Fragment, null) },
                            React.createElement("div", { className: clsx("timeline-items", { "expanded-timeline-items": isExpanded, "infinite-scroll-items": showInfiniteScroll }), id: "timeline-items" }, infiniteScrollItems.map(function (item, index) { return (React.createElement(Timeline.Item, { key: index, className: clsx("timeline-item", { "timeline-item-expandable": shouldRenderExpandableButton }), bullet: React.createElement(RecommendationHistoryAvatar, { recommendationHistory: item.RecommendationHistoryModel }) },
                                React.createElement(RecommendationHistoryMetadata, { recommendationHistory: item.RecommendationHistoryModel, index: index }))); })),
                            shouldRenderExpandableButton && React.createElement(TimelineExpandableButton, { isExpanded: isExpanded, onClick: onExpandedButtonClick }))),
                    renderShowAllButton && React.createElement(TimelineShowAllButton, { onClick: onShowAllButtonClick })))))));
};
