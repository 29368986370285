var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import FeaturesModal from "app/components/common/features-modal";
import * as LibraryTemplateCurrentStore from "app/store/library-templates/current";
import { useDispatch } from "react-redux";
import * as TableList from "app/components/common/table-list";
import generateGuid from "app/utils/generateGuid";
//class TabFeatures extends React.Component {
var TabFeatures = React.memo(function (props, state) {
    var dispatch = useDispatch();
    //console.log("Features tab > currentLibraryTemplate", props.currentLibraryTemplate)
    var toggleRequired = function (featureMap, checked) {
        featureMap.Required = checked;
        // Change this to use List_Item_Update and do the true/false logic here.
        if (props.currentLibraryTemplate.AssociatedFeatures !== undefined) {
            dispatch(LibraryTemplateCurrentStore.Update({ AssociatedFeatures: __spreadArray([], props.currentLibraryTemplate.AssociatedFeatures, true) }));
        }
    };
    var removeFeature = function (tbaToRemove) {
        var _a;
        dispatch(LibraryTemplateCurrentStore.Update({
            AssociatedFeatures: (_a = props.currentLibraryTemplate.AssociatedFeatures) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var addFeatures = function (feats) {
        //setAssociatedLibraryTemplates([...tempArray, ...currentFeature.AssociatedLibraryTemplates]);
        var tempArray = [];
        feats.forEach(function (f) {
            var newTBA = {
                GUID: generateGuid(),
                Required: true,
                Item: {
                    GUID: f.GUID,
                    Title: f.Title,
                    Description: f.Description,
                    Icon: f.Icon
                }
            };
            tempArray.push(newTBA);
        });
        if (props.currentLibraryTemplate.AssociatedFeatures !== undefined) {
            if (props.currentLibraryTemplate.AssociatedFeatures.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), props.currentLibraryTemplate.AssociatedFeatures, true);
            }
        }
        dispatch(LibraryTemplateCurrentStore.Update({ AssociatedFeatures: tempArray }));
    };
    return (
    // <Container fluid={true}>
    React.createElement(Row, null,
        React.createElement(Col, null,
            React.createElement(TableList.Context, { asyncListLoadUID: props.currentLibraryTemplate.GUID !== undefined ? props.currentLibraryTemplate.GUID : "", listItems: props.currentLibraryTemplate.AssociatedFeatures, showLoader: false, wrappedInPortlet: false },
                React.createElement(TableList.TableList, { tstyle: "border-bottom-on" },
                    React.createElement(TableList.HeaderItem, null),
                    React.createElement(TableList.HeaderItem, null, "Feature"),
                    React.createElement(TableList.HeaderItem, null, "Description"),
                    React.createElement(TableList.HeaderItem, { type: "centered", icon: faLock }, "Required"),
                    React.createElement(TableList.HeaderItem, { type: "actions" }),
                    React.createElement(TableList.ContentItemLineAwesomeIcon, { listDataProperyName: "Item.Icon" }),
                    React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Item.Title", isMobileTitle: true }),
                    React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Item.Description" }),
                    React.createElement(TableList.ContentItemToggle, { listDataProperyName: "Required", handleChange: function (item, checked) { return toggleRequired(item, checked); } }),
                    React.createElement(TableList.ContentItemActions
                    // editNavLinkTo= "/workspaces/features/edit/{Item.GUID}"
                    , { 
                        // editNavLinkTo= "/workspaces/features/edit/{Item.GUID}"
                        onRemove: function (item) { return removeFeature(item); } }))),
            React.createElement(FeaturesModal, { associatedFeatures: props.currentLibraryTemplate.AssociatedFeatures, libraryMode: true, handleSelected: function (feats) {
                    addFeatures(feats);
                } })))
    // </Container>
    );
});
export default TabFeatures;
