var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Collapse } from "@mantine/core";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { updateTid } from "appv2/api/recommendations/recommendations.slice";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFilterSelector } from "appv2/components/orchestry-filter-selector/orchestry-filter-selector.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { OrchestrySearchBox } from "appv2/components/orchestry-searchbox/orchestry-searchbox.component";
import { OrchestryTabs } from "appv2/components/orchestry-tabs/orchestry-tabs.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrchestryQueryContext } from "../../../../components/orchestry-query-context/orchestry-query-context.component";
import { RecommendationShareSuggestion } from "../recommendation-footer/recommendation-share-suggestion/recommendation-share-suggestion.component";
import { RecommendationHeaderSkeletons } from "../recommendation-header/recommendation-header-sekeletons/recommendation-header-skeletons.component";
import { RecommendationNumberStat } from "../recommendation-header/recommendation-number-stat.component";
import { RecommendationPriorityMetric } from "../recommendation-header/recommendation-priority-metric.component";
import { RecommendationTopCategoriesMetric } from "../recommendation-header/recommendation-top-categories-metric.component";
import { RecommendationsList } from "../recommendation-list/recommendations-list.component";
import { RecommendationTabsSkeletons } from "../recommendation-tabs-skeletons/recommendations-tabs-skeletons.component";
import { RecommendationViewHistoryButton } from "../recommendation-view-history-button.component";
import { RECOMMENDATIONS_FILTER_GROUPS } from "../recommendations-page.utils";
import "./recommendations-admin-page.scss";
import { useRecommendationsAdminPage } from "./use-recommendations-admin-page.hook";
export var RecommendationsPageAdmin = function () {
    var tid = useSelector(function (state) { return state.recommendationsPage.tid; });
    var dispatch = useDispatch();
    var _a = useState(tid), tenantSearchText = _a[0], settenantSearchText = _a[1];
    var _b = useRecommendationsAdminPage({ tid: tid }), pagedRecommendations = _b.pagedRecommendations, pagedRecommendationsIsFetching = _b.pagedRecommendationsIsFetching, recommendationRecordVisibilityCountsIsLoading = _b.recommendationRecordVisibilityCountsIsLoading, applyFilterButtonFilters = _b.applyFilterButtonFilters, clearAllFilters = _b.clearAllFilters, priorityMetricConfig = _b.priorityMetricConfig, topCategoryConfig = _b.topCategoryConfig, recommendationStatsSuccessful = _b.recommendationStatsSuccessful, removeFilter = _b.removeFilter, appliedFilters = _b.appliedFilters, shouldShowShareSuggestion = _b.shouldShowShareSuggestion, shouldShowViewHistory = _b.shouldShowViewHistory, navigateToResolutionsPage = _b.navigateToResolutionsPage, isTabsDeactivated = _b.isTabsDeactivated, shouldShowRecommendationsStats = _b.shouldShowRecommendationsStats, recommendationStats = _b.recommendationStats, activePage = _b.activePage, setActivePage = _b.setActivePage, resultsPerPage = _b.resultsPerPage, setResultsPerPage = _b.setResultsPerPage, querySearchText = _b.querySearchText, searchTextInputValue = _b.searchTextInputValue, badgeOnClick = _b.badgeOnClick, tab = _b.tab, setTab = _b.setTab, tabs = _b.tabs, togglePrioritySort = _b.togglePrioritySort, onSearchBoxChange = _b.onSearchBoxChange, onSearchBoxKeyDown = _b.onSearchBoxKeyDown, onSearchBoxXClicked = _b.onSearchBoxXClicked, isPageLoading = _b.isPageLoading, highlightSearchWords = _b.highlightSearchWords;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "recommendations-admin-page" },
                React.createElement("div", { className: "page-header" },
                    React.createElement(Subtitle, { title: "Tenant Recommendations Browser" }),
                    React.createElement("div", { className: "filter-row" },
                        React.createElement("div", { className: "left-side" }, recommendationRecordVisibilityCountsIsLoading ? (React.createElement(RecommendationTabsSkeletons, null)) : (React.createElement(OrchestryTabs, { showCount: true, tabs: tabs, tab: tab, onTabChange: setTab, isDeactivated: isTabsDeactivated }))),
                        React.createElement("div", { className: "right-side" },
                            React.createElement(OrchestrySearchBox, { styles: { wrapper: { width: "400px" } }, placeholder: "Tenant GUID", tooltipLabel: "Click to perform a search within your current results", radius: "6px", onXClicked: function () {
                                    dispatch(updateTid(undefined));
                                    settenantSearchText("");
                                    setActivePage(1);
                                }, onChange: function (e) {
                                    settenantSearchText(e);
                                    if (e.length === 0) {
                                        dispatch(updateTid(undefined));
                                    }
                                }, value: tenantSearchText, onKeyDown: function (e) {
                                    if (e.key === "Enter") {
                                        dispatch(updateTid(e.currentTarget.value));
                                        setActivePage(1);
                                    }
                                } }),
                            React.createElement(OrchestrySearchBox, { placeholder: "Search", radius: "6px", tooltipLabel: "Click to perform a search within your current results", onXClicked: onSearchBoxXClicked, onChange: onSearchBoxChange, value: searchTextInputValue, onKeyDown: onSearchBoxKeyDown }),
                            React.createElement(OrchestryTooltip, { label: "Click to apply filters to your current results" },
                                React.createElement(OrchestryFilterSelector, { popoverPosition: "bottom-end", buttonTitle: "Filters", filters: appliedFilters, onApply: applyFilterButtonFilters, onClear: clearAllFilters, buttonSize: "sm" })))),
                    tid && isPageLoading && tab === RecommendationRecordVisibility.Visible ? (React.createElement(RecommendationHeaderSkeletons, null)) : (recommendationStatsSuccessful && (React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: shouldShowRecommendationsStats },
                        React.createElement("div", { className: "stats-header" },
                            priorityMetricConfig && React.createElement(RecommendationPriorityMetric, __assign({}, priorityMetricConfig)),
                            React.createElement(RecommendationNumberStat, { count: recommendationStats === null || recommendationStats === void 0 ? void 0 : recommendationStats.RecommendationsResolvedThisMonthCount, footerLabel: "Last 30 days", percentChange: {
                                    iconIncrease: ORCH_ICONS.arrowUp,
                                    iconDecrease: ORCH_ICONS.arrowDown,
                                    value: recommendationStats === null || recommendationStats === void 0 ? void 0 : recommendationStats.RecommendationsResolvedMonthlyPercentIncrease
                                }, iconLabel: {
                                    iconClassName: ORCH_ICONS.checkDone02,
                                    iconVariant: "light-circle",
                                    iconSize: "sm",
                                    iconColorVariant: "pink-6",
                                    textColorVariant: "text-secondary",
                                    textLabel: "Resolutions",
                                    textFW: 400,
                                    textSize: "sm",
                                    colour: "text-secondary.0"
                                }, onClick: navigateToResolutionsPage }),
                            React.createElement(RecommendationNumberStat, { count: recommendationStats === null || recommendationStats === void 0 ? void 0 : recommendationStats.RecommendationsNewThisMonthCount, footerLabel: "Last 30 days", iconLabel: {
                                    iconClassName: ORCH_ICONS.star05,
                                    iconVariant: "light-circle",
                                    iconSize: "sm",
                                    iconColorVariant: "success-6",
                                    textColorVariant: "text-secondary",
                                    textLabel: "New",
                                    textFW: 400,
                                    textSize: "sm",
                                    colour: "text-secondary.0"
                                } }),
                            topCategoryConfig && React.createElement(RecommendationTopCategoriesMetric, __assign({}, topCategoryConfig))))))),
                React.createElement("div", { className: "query-context" },
                    React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: appliedFilters.some(function (filter) { return filter.selected; }) },
                        React.createElement(OrchestryQueryContext, { filterGroups: RECOMMENDATIONS_FILTER_GROUPS, appliedFilters: appliedFilters, totalResults: (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) || 0, removeFilter: removeFilter, clearAllFilters: clearAllFilters }))),
                React.createElement(RecommendationsList, { currentTab: tab, isLoading: pagedRecommendationsIsFetching, badgeOnClick: badgeOnClick, priorityOnClick: togglePrioritySort, recommendations: pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items, searchString: querySearchText, highlightSearchWords: highlightSearchWords }),
                shouldShowViewHistory && React.createElement(RecommendationViewHistoryButton, { onClick: navigateToResolutionsPage }),
                React.createElement(OrchestryPagination, { onResultsPerPageChange: function (value) {
                        setResultsPerPage(value);
                        localStorage.setItem("recommendations-resultsPerPage", value);
                    }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) || 0, pageTotal: (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.PageTotal) || 0 }),
                shouldShowShareSuggestion && React.createElement(RecommendationShareSuggestion, null)))));
};
