var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryButton } from "../orchestry-button/orchestry-button.component";
import { openOrchestryModal } from "../orchestry-modal/orchestry-modal-definitions";
export var DisconnectTenantButton = function (_a) {
    var userType = _a.userType, customerTenantGuid = _a.customerTenantGuid, customerTenantName = _a.customerTenantName, partnerHubTenantGuid = _a.partnerHubTenantGuid, partnerHubTenantName = _a.partnerHubTenantName, onDisconnectTenantClicked = _a.onDisconnectTenantClicked, props = __rest(_a, ["userType", "customerTenantGuid", "customerTenantName", "partnerHubTenantGuid", "partnerHubTenantName", "onDisconnectTenantClicked"]);
    var onDisconnectTenantClick = function () {
        openOrchestryModal("disconnectTenantModal", { userType: userType, customerTenantGuid: customerTenantGuid, customerTenantName: customerTenantName, partnerHubTenantGuid: partnerHubTenantGuid, partnerHubTenantName: partnerHubTenantName, onDisconnectTenantClicked: onDisconnectTenantClicked }, "md");
    };
    return (React.createElement(OrchestryButton, __assign({ onClick: function () { return onDisconnectTenantClick(); }, iconSize: "lg", lh: "18px", fw: 400, iconType: "font", variant: "white", iconLeft: ORCH_ICONS.unlink, iconColor: "var(--mantine-color-gray-6)", iconFontSize: "16px" }, props), "Disconnect"));
};
