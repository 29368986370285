import { RECOMMENDATIONS_FILTERS_LIST } from "./utils/recommendations-filter.utils";
/**
 * Maps a filter name to an icon
 * @param filterName The name of the filter to map to an icon
 */
export var mapRecommendationFilterNameToIcon = function (filterName) {
    var _a;
    if (!filterName) {
        return undefined;
    }
    var icon = (_a = RECOMMENDATIONS_FILTERS_LIST.filter(function (filter) { return filter.value === filterName; })[0]) === null || _a === void 0 ? void 0 : _a.icon;
    return icon;
};
/**
 * Maps a filter name to an icon
 * @param filterName The name of the filter to map to an icon
 */
export var mapRecommendationFilterNameToSVGIcon = function (filterName) {
    var _a;
    if (!filterName) {
        return undefined;
    }
    var svgIcon = (_a = RECOMMENDATIONS_FILTERS_LIST.filter(function (filter) { return filter.value === filterName; })[0]) === null || _a === void 0 ? void 0 : _a.svgIcon;
    return svgIcon;
};
