/**
 * The review process of the health check.
 */
export var HealthChecksSettingsReviewProcess;
(function (HealthChecksSettingsReviewProcess) {
    /**
     * The review process is disabled
     */
    HealthChecksSettingsReviewProcess["Disabled"] = "Disabled";
    /**
     * The review process is self-led
     */
    HealthChecksSettingsReviewProcess["SelfLed"] = "SelfLed";
    /**
     * The review process is partner-led
     */
    HealthChecksSettingsReviewProcess["PartnerLed"] = "PartnerLed";
})(HealthChecksSettingsReviewProcess || (HealthChecksSettingsReviewProcess = {}));
