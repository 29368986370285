import { GrowthBook } from "@growthbook/growthbook-react";
import { nanoid } from "@reduxjs/toolkit";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
export var useGrowthbook = function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var growthBookClientKey = orchestryContext.GrowthBookClientKey;
    var _a = React.useState(null), growthbook = _a[0], setGrowthbook = _a[1];
    var _b = React.useState(), featuresEndpoint = _b[0], setFeaturesEndpoint = _b[1];
    React.useEffect(function () {
        if (!growthBookClientKey) {
            return;
        }
        setFeaturesEndpoint("https://cdn.growthbook.io/api/features/" + growthBookClientKey);
        var growthbook = new GrowthBook({
            apiHost: "https://cdn.growthbook.io",
            clientKey: growthBookClientKey,
            attributes: {
                id: localStorage.getItem("growthbook_visitor_id") || nanoid()
            },
            trackingCallback: function (experiment, result) {
                console.log({
                    experimentId: experiment.key,
                    variationId: result.variationId
                });
            }
        });
        setGrowthbook(growthbook);
    }, [growthBookClientKey]);
    React.useEffect(function () {
        if (!growthbook || !featuresEndpoint) {
            return;
        }
        var growthbook_visitor_id = localStorage.getItem("growthbook_visitor_id");
        if (!growthbook_visitor_id) {
            growthbook_visitor_id = nanoid();
            localStorage.setItem("growthbook_visitor_id", growthbook_visitor_id);
            growthbook.setAttributes({ id: growthbook_visitor_id });
        }
        axios
            .get(featuresEndpoint)
            .then(function (res) { return res.data; })
            .then(function (data) {
            growthbook.setPayload(data.features);
        })
            .catch(function (error) { return console.error("Failed to fetch features:", error); });
    }, [featuresEndpoint, growthbook]);
    return growthbook;
};
