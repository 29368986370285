var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./form-imageuploadbox.scss";
import { useDropzone } from "react-dropzone";
var FormImageUploadBox = React.memo(function (props, state) {
    var _a;
    //class FormImageUploadBox extends React.Component<IProps> {
    var _b = React.useState(props.defaultBase64Image ? props.defaultBase64Image : ""), image = _b[0], setImage = _b[1];
    var _c = React.useState(props.allowedFiletype ? new RegExp("^image/" + props.allowedFiletype + ".*") : /^image\/(?!tiff?).*/), allowedFileType = _c[0], setAllowedFileType = _c[1];
    var _d = React.useState(false), imageChanged = _d[0], setImageChanged = _d[1];
    var _e = React.useState(""), imageFileName = _e[0], setImageFileName = _e[1];
    var clearSelectedImage = function () {
        setImage("");
        setImageChanged(true);
    };
    var onDrop = React.useCallback(function (acceptedFiles) {
        // Do something with the files
        //let readBytesCompleted = 0;
        acceptedFiles.forEach(function (acceptedFile) {
            // Ensure it's an image
            if (acceptedFile.type.match(allowedFileType)) {
                //console.log("it's an image!", acceptedFile);
                var reader = new FileReader();
                //reader.readAsDataURL(acceptedFile);
                reader.onload = function (event) {
                    var _a;
                    var image = new Image();
                    image.onload = function (imageEvent) {
                        var _a;
                        if (props.dimensionsExact !== null &&
                            props.dimensionsExact !== undefined &&
                            props.dimensionsExact === true &&
                            props.height !== null &&
                            props.height !== undefined &&
                            props.width !== null &&
                            props.width !== undefined &&
                            (image.height !== props.height || image.width !== props.width)) {
                            alert("Sorry! ".concat("'".concat(acceptedFile.name, "'") || "this", " is the wrong size.  Image must be ").concat(props.width, "px x ").concat(props.height, "px"));
                            return;
                        }
                        if ((props.dimensionsExact === null || props.dimensionsExact === undefined || props.dimensionsExact === false) &&
                            props.height !== null &&
                            props.height !== undefined &&
                            props.width !== null &&
                            props.width !== undefined &&
                            (image.height > props.height || image.width > props.width)) {
                            alert("Sorry! ".concat("'".concat(acceptedFile.name, "'") || "this", " is too big.  Image must be less than ").concat(props.width, "px x ").concat(props.height, "px"));
                            return;
                        }
                        // Resize the image
                        var canvas = document.createElement("canvas"), max_size = props.maxSize !== undefined ? props.maxSize : 600, width = image.width, height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        }
                        else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        (_a = canvas.getContext("2d")) === null || _a === void 0 ? void 0 : _a.drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL(props.allowedFiletype === "png" ? "image/png" : "image/jpeg");
                        setImage(String(dataUrl));
                        setImageFileName(acceptedFile.name);
                    };
                    image.src = String((_a = event.target) === null || _a === void 0 ? void 0 : _a.result);
                    setImageChanged(false);
                };
                reader.readAsDataURL(acceptedFile);
            }
            else {
                alert("Sorry! ".concat("'".concat(acceptedFile.type.split("/")[1], "'") || "this", " is not a compatible image format."));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        //console.log("Image Resized!", image, imageFileName);
        if (!props.disabled)
            props.onDrop(image, imageFileName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);
    var dzOptions = {
        onDrop: onDrop
    };
    //if(props.allowMultiple !== undefined)
    //    dzOptions.multiple = props.allowMultiple;
    // 5000000 = 5mb
    dzOptions.maxSize = 3000000;
    dzOptions.multiple = false;
    dzOptions.onDropRejected = function (event) {
        alert("File too big. Please upload an image smaller than 3mb");
    };
    var _f = useDropzone(dzOptions), getRootProps = _f.getRootProps, getInputProps = _f.getInputProps;
    return (React.createElement("div", __assign({ className: "form-imageuploadbox" }, getRootProps()),
        React.createElement("div", { "data-pw": "imageupload", className: "imageupload" + (image !== "" || props.defaultBase64Image !== "" ? " has-image" : "") }, imageChanged || (image === "" && props.defaultBase64Image === "") ? (React.createElement(React.Fragment, null,
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement("div", { className: "icon" },
                React.createElement("i", { className: "la la-upload" })),
            React.createElement("div", { className: "text" }, props.text))) : (
        // <div className="image-preview" style={{backgroundImage:"url('" + image + "')"}}></div>
        React.createElement("div", { className: "image-preview fadein-2s ".concat(props.allowedFiletype === "png" ? "is-png" : ""), style: props.disablePreview ? {} : { backgroundImage: "url('" + image !== "" ? image : props.defaultBase64Image + "')" } }, props.disablePreview ? (React.createElement(React.Fragment, null,
            React.createElement("div", null, imageFileName != "" ? imageFileName : props.fileName),
            !props.disabled ? (React.createElement("div", { className: "delete-icon", onClick: function () {
                    clearSelectedImage();
                } },
                React.createElement("i", { className: "la la-trash" }))) : null)) : (React.createElement(React.Fragment, null,
            React.createElement("img", { src: image !== "" ? image : props.defaultBase64Image, alt: "preview" }),
            props.allowedFiletype === "png" && (image.includes("png") || ((_a = props.defaultBase64Image) === null || _a === void 0 ? void 0 : _a.includes("png"))) ? React.createElement("div", { className: "checkerboard" }) : null,
            !props.disabled ? (React.createElement("div", { className: "delete-icon", onClick: function () {
                    clearSelectedImage();
                } },
                React.createElement("i", { className: "la la-trash" }))) : null)))))));
});
export default FormImageUploadBox;
