import * as React from "react";
// import './request-details.scss';
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import SharePointService from "app/services/sharepoint";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
var RequestDetailsTemplateSPConfig = React.memo(function (props) {
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var intl = useIntl();
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.template_config.sharepoint_configuration.title", defaultMessage: "SharePoint Configuration" }) }),
        React.createElement(PortletBody, null,
            React.createElement("h6", null,
                React.createElement(FormattedMessage, { id: "page.request_details.template_config.sharing_options", defaultMessage: "Sharing Options" })),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-6 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.external_sharing_options", defaultMessage: "External Sharing Options" })),
                React.createElement("div", { className: "col-6" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) === "" ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) !== undefined && SharePointService.settingsLabels.SharingCapability[currentTemplate.ConfigSPSharingCapability] ? (SharePointService.settingsLabels.SharingCapability[currentTemplate.ConfigSPSharingCapability].title) : ("Tenant Default")))),
            (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) !== "" && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) !== "Disabled" && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "form-group form-group-xs row" },
                    React.createElement("label", { className: "col-6 col-form-label" },
                        React.createElement(FormattedMessage, { id: "page.request_details.template_config.limit_sharing_by_domain", defaultMessage: "Limit sharing by domain" })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainRestrictionMode) === "" ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainRestrictionMode) !== undefined &&
                            SharePointService.settingsLabels.SharingDomainRestrictionMode[currentTemplate.ConfigSPSharingDomainRestrictionMode].title)))),
                (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainRestrictionMode) !== undefined &&
                    (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainRestrictionMode) !== "" &&
                    (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainRestrictionMode) !== "None" && (React.createElement("div", { className: "form-group form-group-xs row" },
                    React.createElement("label", { className: "col-6 col-form-label" },
                        React.createElement(FormattedMessage, { id: "page.request_details.template_config.domains", defaultMessage: "Domains" })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainList) === undefined || (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainList) === null ? "" : currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingDomainList)))))),
            (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingCapability) === "ExternalUserAndGuestSharing" && (React.createElement(React.Fragment, null,
                React.createElement("h6", { style: { marginTop: "20px" } },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.advanced_settings_anyone_links", defaultMessage: "Advanced settings for Anyone links" })),
                React.createElement("div", { className: "form-group form-group-xs row" },
                    React.createElement("label", { className: "col-6 col-form-label" },
                        React.createElement(FormattedMessage, { id: "page.request_details.template_config.expiration_anyone_links", defaultMessage: "Expiration of Anyone links" })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPAnonymousLinkExpirationInDays) === -1 ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.links_never_expire", defaultMessage: "Links never expire" })) : (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPAnonymousLinkExpirationInDays) === null ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : (React.createElement(FormattedMessage, { id: "page.request_details.template_config.links_must_expire", defaultMessage: "Links must expire within {days} many days", values: { days: currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPAnonymousLinkExpirationInDays } }))))))),
            React.createElement("h6", { style: { marginTop: "20px" } },
                React.createElement(FormattedMessage, { id: "page.request_details.template_config.link_options", defaultMessage: "Link Options" })),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-6 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.default_link_type", defaultMessage: "Default Link Type" })),
                React.createElement("div", { className: "col-6" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingLinkType) === "" ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPSharingLinkType) !== undefined && SharePointService.settingsLabels.DefaultSharingLinkType[currentTemplate.ConfigSPSharingLinkType].title)))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-6 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.default_link_permissions", defaultMessage: "Default Link Permissions" })),
                React.createElement("div", { className: "col-6" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPLinkPermission) === "" ? (React.createElement(FormattedMessage, { id: "page.request_details.template_config.tenant_default", defaultMessage: "Tenant Default" })) : ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ConfigSPLinkPermission) !== undefined && SharePointService.settingsLabels.DefaultLinkPermission[currentTemplate.ConfigSPLinkPermission].title)))))));
});
export default RequestDetailsTemplateSPConfig;
