import * as React from "react";
import "./report-details.scss";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import LifecycleDetailsTabOverview from "./tabs/lifecycle-details-tab-overview";
import LifecycleDetailsTabLifecycle from "./tabs/lifecycle-details-tab-lifecycle";
import LifecycleDetailsTabUsers from "./tabs/lifecycle-details-tab-users";
import * as PageHeader from "app/components/common/page-header";
import { InputGroup, Nav, Tab } from "react-bootstrap";
import InfoTooltip from "app/components/common/infotooltip";
import { Portlet, PortletBody } from "app/components/common/portlet";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import { LifecyclePolicyWorkspaceCurrentActionTypes } from "app/store/lifecycle-policy-workspaces/current";
import Store from "app/store";
import NoResults from "app/components/common/no-results";
import PortletAlert from "app/components/common/portlet-alert";
import * as MetadataListStore from "app/store/metadata/list";
import * as DirectoryStore from "app/store/directory/search";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import moment from "moment";
import { Form } from "react-bootstrap";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import AnimatedLoader from "app/components/common/animated-loader";
import { getReadableDate } from "app/utils/dateUtils";
import OrchModal from "app/components/common/orch-modal";
import LifecycleReportService from "app/services/lifecycle-report";
var parseSharepointAdminUrl = function (inputUrl) {
    var returnUrl = "";
    if (inputUrl) {
        var pos = inputUrl.indexOf(".sharepoint.com");
        returnUrl = inputUrl.substring(0, pos) + "-admin" + inputUrl.substring(pos);
    }
    return returnUrl;
};
var LifecycleDetails = React.memo(function (_a) {
    var siteId = _a.siteId, reportItem = _a.reportItem;
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentReportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var _b = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "overview"), hash = _b[0], setHash = _b[1];
    var _c = React.useState(false), updateStatusContinously = _c[0], setUpdateStatusContinously = _c[1];
    var statusUpdater = React.useRef(null);
    var _d = React.useState(""), lastStatus = _d[0], setLastStatus = _d[1];
    var _e = React.useState(null), lastStatusHistoryCount = _e[0], setLastStatusHistoryCount = _e[1];
    var _f = React.useState(false), errorLoading = _f[0], setErrorLoading = _f[1];
    var sharePointAdminUrl = parseSharepointAdminUrl(orchestryContext.TenantSharePointUrl);
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var isForceETLCrawlLoading = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.forceETLCrawlIsLoading; });
    var _g = React.useState(undefined), crawlHistory = _g[0], setCrawlHistory = _g[1];
    // In An effort to reduce load times for this we are going to lazy load resources only when needed.
    React.useEffect(function () {
        if (hash === "overview") {
        }
        if (hash === "lifecycle") {
            dispatch(LifecyclePolicyWorkspaceStore.LoadHistory(siteId));
        }
        if (hash === "users") {
            dispatch(LifecyclePolicyWorkspaceStore.LoadMembers(siteId));
        }
    }, [hash]);
    React.useEffect(function () {
        if ((policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.GUID) === "00000000-0000-0000-0000-000000000000") {
            setErrorLoading(true);
        }
        else if (errorLoading === true) {
            setErrorLoading(false);
        }
    }, [policyWorkspace]);
    //console.log("siteID", siteId);
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
        setHash(newHash);
    };
    var timer = function () {
        //console.log("Refresh Hash", currentReportItem);
    };
    var getForceCrawlTooltip = function () {
        var toolipText = "Mark this workspace to be crawled in the next nightly processing job.";
        if ((policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) === "Deleted" || (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) === "Done")
            toolipText = "This workspace cannot be forced to recrawl at this time.";
        if (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.EtlCrawlNext)
            toolipText = "This field has already been marked for crawl in the next nightly processing job.";
        return toolipText;
    };
    var forceCrawlIsDisabled = function () {
        if ((policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) === "Deleted" || (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) === "Done")
            return true;
        return false;
    };
    React.useEffect(function () {
        window.scrollTo(0, 0);
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var state = Store.getState();
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 5000);
            };
            if (state.lifecyclePolicyWorkspacesCurrentState.item !== null) {
                if (state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalForced === true &&
                    (state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus === "Active" || state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus === "Extended")) {
                    shouldRun = true;
                }
                if (state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Active" &&
                    state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Extended" &&
                    state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Done" &&
                    state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Deleted") {
                    shouldRun = true;
                }
                if (state.lifecyclePolicyWorkspacesCurrentState.item.SiteId !== siteId) {
                    shouldRun = false;
                }
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                dispatch(LifecyclePolicyWorkspaceStore.LoadStatus(siteId));
                AsyncWait(undefined, AsyncStatus.Succeeded, policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.GUID, null, LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Status).then(function () {
                    erroredOut_1 = false;
                    runAgain();
                });
                setTimeout(function () {
                    if (erroredOut_1) {
                        console.log("Status Update didn't complete... waited 30 seconds, but didn't get anything. Attempting again without reponse from last poll.");
                        // runAgain();
                    }
                }, 30000);
            }
            else {
                runAgain();
            }
        }
        watchForStatusUpdate();
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [siteId]);
    React.useEffect(function () {
        if (siteId !== undefined) {
            dispatch(LifecyclePolicyWorkspaceStore.Clear());
            dispatch(LifecyclePolicyWorkspaceStore.Load(siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadDirectoryItem(siteId));
            dispatch(TenantConfigurationCurrentStore.Load());
            dispatch(MetadataListStore.Load());
            dispatch(DirectoryStore.Load());
            if (reportItem !== undefined && reportItem.SiteId === siteId) {
                dispatch(LifecyclePolicyWorkspaceStore.UpdateReportItem(reportItem));
            }
            else {
                dispatch(LifecyclePolicyWorkspaceStore.LoadReportItem(siteId));
            }
        }
        return function () {
            dispatch(LifecyclePolicyWorkspaceStore.Clear());
            //    setUpdateStatusContinously(false);
        };
    }, [siteId]);
    React.useEffect(function () {
        if (policyWorkspace !== null) {
            if (policyWorkspace.ArchivalStatus !== lastStatus) {
                //console.log("STATUS CHANGED", "FROM", lastStatus, "TO", policyWorkspace.ArchivalStatus);
                if (lastStatus !== "") {
                    dispatch(LifecyclePolicyWorkspaceStore.Load(siteId));
                    if (reportItem !== undefined) {
                        //console.log("Update Report Item");
                        dispatch(LifecyclePolicyWorkspaceStore.LoadReportItem(siteId));
                    }
                }
                setLastStatus(policyWorkspace.ArchivalStatus);
            }
            if (policyWorkspace.HistoryCount !== lastStatusHistoryCount) {
                setLastStatusHistoryCount(policyWorkspace.HistoryCount);
                dispatch(LifecyclePolicyWorkspaceStore.LoadHistory(siteId));
            }
        }
    }, [policyWorkspace]);
    var showNoGroupMessage = function (currentReportItem) {
        var showMessage = false;
        if (currentReportItem !== null) {
            if (currentReportItem.SharePointTeamSiteHasNoGroup === true) {
                showMessage = true;
            }
            if (showMessage === true) {
                //if (currentPolicy !== null && (currentPolicy as LifecycleArchivalPolicy).Option_Content_Action === "Delete") {
                if ((currentReportItem.ArchivalStatus === "Deleted" || (currentReportItem.IsDeleted === true && currentReportItem.ArchivalStatus === "Done")) && currentReportItem.ArchivedDate !== null) {
                    if (moment(currentReportItem.ArchivedDate).add(2, "days") < moment().utc()) {
                        showMessage = false;
                    }
                }
                //}
            }
        }
        return showMessage;
    };
    var forceCrawlOnClick = function () {
        if ((policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) !== "Deleted" && (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) !== "Done" && !(policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.EtlCrawlNext)) {
            dispatch(LifecyclePolicyWorkspaceStore.UpdateNextETLCrawl(siteId));
        }
    };
    var loadCrawlingHistory = function (siteId) {
        setCrawlHistory(null);
        LifecycleReportService.getWorkspaceCrawlHistory(siteId).then(function (response) {
            setCrawlHistory(response);
        });
    };
    return (React.createElement("div", { className: "lifecycle-details" },
        React.createElement(Subtitle, { title: "Workspace Details", subtitle: currentReportItem === null ? undefined : React.createElement(React.Fragment, null, currentReportItem === null || currentReportItem === void 0 ? void 0 : currentReportItem.Title) }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/lifecycle/listing" },
                React.createElement(SubtitleButton, { title: "Back to Insights", variant: "secondary", faIcon: "arrow-left" }))),
        currentReportItem !== null && currentReportItem.DeletionBlockedByRetentionPolicy === true ? (React.createElement(PortletAlert, { type: "warning", size: "regular", icon: "alert", 
            // message={"asdfasdfasd sad"}
            title: "This Workspace has a compliance policy", message: "The compliance policy is set to block deletion and may impact the ability of Orchestry to function." })) : null,
        currentReportItem !== null && showNoGroupMessage(currentReportItem) ? (React.createElement(PortletAlert, { type: "warning", size: "regular", icon: "alert", title: (currentReportItem.TemplateType === "Team" ? "Team" : "Team Site") + " is missing an associated group", message: React.createElement("div", null,
                "This ",
                currentReportItem.TemplateType === "Team" ? "Team" : "Team Site",
                " is missing a connected group",
                React.createElement("div", null, "This typically happens because a group or team has been deleted and there is a delay until the underlying site is removed (usually 48 hours).")) })) : null,
        currentReportItem !== null && currentReportItem.SharePointLockNoAccess === true ? (React.createElement(PortletAlert, { type: "warning", size: "regular", icon: "alert", title: "SharePoint site has been locked with No Access", message: "This Site Collection has been locked with No Access and Orchestry cannot get all information contained within." })) : null,
        errorLoading === true ? (React.createElement(NoResults, { subText: "Cannot find the requested workspace" })) : (React.createElement(Tab.Container, { id: "template-new-tabs", defaultActiveKey: hash },
            React.createElement(Portlet, { className: "nav-portlet" },
                React.createElement(PortletBody, null,
                    React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                        React.createElement(Nav, { as: "ul" },
                            React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                    setHashAnchor("overview");
                                }, "data-pw": "overview-tab" },
                                React.createElement(Nav.Link, { eventKey: "overview" },
                                    React.createElement("span", null, "Overview"))),
                            React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                    setHashAnchor("lifecycle");
                                }, "data-pw": "lifecycle-tab" },
                                React.createElement(Nav.Link, { eventKey: "lifecycle" },
                                    React.createElement("span", null, "Lifecycle"))),
                            currentReportItem === null || (currentReportItem !== null && (currentReportItem.SharePointLockNoAccess === null || currentReportItem.SharePointLockNoAccess === false)) ? (React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                    setHashAnchor("users");
                                }, "data-pw": "users-tab" },
                                React.createElement(Nav.Link, { eventKey: "users" },
                                    React.createElement("span", null, "Users")))) : null,
                            React.createElement("span", { className: "last-crawl" },
                                React.createElement(InputGroup, { className: "last-crawled-inputgroup" }, currentReportItem !== null && currentReportItem.CrawledDate !== null ? (React.createElement(React.Fragment, null,
                                    React.createElement(Form.Control, { className: "last-crawled-textbox " + (forceCrawlIsDisabled() ? " disabled" : ""), disabled: true, value: "Last Crawled: " +
                                            (moment.utc(currentReportItem === null || currentReportItem === void 0 ? void 0 : currentReportItem.CrawledDate).add(1, "days") > moment.utc(new Date())
                                                ? getReadableDate(new Date())
                                                : getReadableDate(currentReportItem === null || currentReportItem === void 0 ? void 0 : currentReportItem.CrawledDate)), autoComplete: "off" }),
                                    React.createElement(InputGroup.Append, { className: (forceCrawlIsDisabled() ? " disabled" : "") + ((policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.EtlCrawlNext) ? " toggled" : "") },
                                        React.createElement(OrchModal, { size: "sm", modalClassName: "workspace-history-modal", headerText: "Crawling History", onOpen: function () { return loadCrawlingHistory(currentReportItem.SiteId); }, simpleButton: true, buttonContents: React.createElement(InfoTooltip, { content: "View crawling history", placement: "bottom" },
                                                React.createElement(InputGroup.Text, null,
                                                    React.createElement("i", { className: "fa-solid fa-chart-scatter" }))), children: React.createElement("div", { className: "history-portlet" }, crawlHistory == undefined || crawlHistory == null ? (React.createElement("div", { className: "history-loading" },
                                                React.createElement(AnimatedLoader, { size: "med" }))) : (crawlHistory.map(function (ch) { return (React.createElement("div", { className: "crawl-history" },
                                                React.createElement("div", { className: "crawl-date" }, moment(ch.CrawlDay).format("LL")),
                                                React.createElement("div", { className: "crawl-plans" }, ch.CrawlPlans.map(function (cp) { return (React.createElement("div", { className: "plan" }, cp)); })))); }))) }),
                                        React.createElement(InfoTooltip, { content: getForceCrawlTooltip(), placement: "bottom" },
                                            React.createElement(InputGroup.Text, { onClick: forceCrawlOnClick }, isForceETLCrawlLoading === true ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (React.createElement("i", { className: "fa-solid fa-rectangle-history-circle-plus " + ((policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.EtlCrawlNext) ? " toggled" : "") + (forceCrawlIsDisabled() ? " disabled" : "") }))))))) : (React.createElement(SkeletonLoaderFakeBox, { bodyWidth: "240px" })))))))),
            React.createElement(Tab.Content, null,
                React.createElement(Tab.Pane, { eventKey: "overview" }, hash === "overview" && React.createElement(LifecycleDetailsTabOverview, { siteId: siteId, currentHash: hash })),
                React.createElement(Tab.Pane, { eventKey: "lifecycle" }, hash === "lifecycle" ? React.createElement(LifecycleDetailsTabLifecycle, { siteId: siteId, currentHash: hash }) : null),
                React.createElement(Tab.Pane, { eventKey: "analytics" }, "Analytics"),
                React.createElement(Tab.Pane, { eventKey: "users" }, hash === "users" && React.createElement(LifecycleDetailsTabUsers, { siteId: siteId, groupId: currentReportItem === null || currentReportItem === void 0 ? void 0 : currentReportItem.GroupId })))))));
});
export default LifecycleDetails;
// function useTimeout(callback:()=>void, delay:number|null) {
//    const timeoutRef = React.useRef<number|null>(null);
//    const callbackRef = React.useRef(callback);
//    // Remember the latest callback:
//    //
//    // Without this, if you change the callback, when setTimeout kicks in, it
//    // will still call your old callback.
//    //
//    // If you add `callback` to useEffect's deps, it will work fine but the
//    // timeout will be reset.
//    React.useEffect(() => {
//      callbackRef.current = callback;
//    }, [callback]);
//    // Set up the timeout:
//    React.useEffect(() => {
//      if (typeof delay === 'number') {
//        timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
//        // Clear timeout if the components is unmounted or the delay changes:
//        return () => timeoutRef.current !== null ? window.clearTimeout(timeoutRef.current) : () => {};
//      }
//    }, [delay]);
//    // In case you want to manually clear the timeout from the consuming component...:
//    return timeoutRef;
//  }
