//// @ts-nocheck
import * as React from "react";
import "./associate-template.scss";
import * as TemplateListStore from "app/store/templates/list";
import * as LibraryTemplateListStore from "app/store/library-templates/list";
import { useSelector, useDispatch } from "react-redux";
import FormSearchList from "app/components/common/form-searchlist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import generateGuid from "app/utils/generateGuid";
var AssociateTemplateContext = React.createContext({});
var Search = function () {
    var ac = React.useContext(AssociateTemplateContext);
    var dispatch = useDispatch();
    var templates;
    //const _store = Store.getState();
    //templates = _store.libraryTemplateListState.items;
    if (ac.props.libraryMode !== undefined && ac.props.libraryMode === true) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        templates = useSelector(function (state) { return state.libraryTemplateListState.items; });
    }
    else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        templates = useSelector(function (state) { return state.templateListState.items; });
    }
    var formSearchItems = [];
    templates.forEach(function (tmplt) {
        var matchToExisting = false;
        ac.associatedTemplates.forEach(function (at) {
            var _a;
            if (((_a = at.Item) === null || _a === void 0 ? void 0 : _a.GUID) === tmplt.GUID) {
                matchToExisting = true;
                //console.log("Matched!");
            }
        });
        if (matchToExisting === false) {
            formSearchItems.push({
                GUID: tmplt.GUID ? tmplt.GUID : "",
                Title: tmplt.Title ? tmplt.Title : "",
                Value: tmplt.GUID ? tmplt.GUID : "",
                Object: tmplt
            });
        }
    });
    React.useEffect(function () {
        if (ac.props.libraryMode !== undefined && ac.props.libraryMode === true) {
            dispatch(LibraryTemplateListStore.Load());
        }
        else {
            dispatch(TemplateListStore.Load());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var addTBA = function (selectedItem) {
        var tba = {
            GUID: generateGuid(),
            Required: true,
            Item: {
                GUID: selectedItem.GUID,
                Title: selectedItem.Title,
                Description: selectedItem.Description,
                Icon: selectedItem.Icon
            }
        };
        ac.onAdd(tba);
        //console.log("selectedItem", selectedItem);
    };
    return (React.createElement(AssociateTemplateContext.Consumer, null, function (ac) { return (React.createElement("div", null,
        React.createElement(FormSearchList, { items: formSearchItems, onSelected: function (obj) {
                addTBA(obj);
            }, placeholder: "Search for templates to add" }))); }));
};
var Count = function () {
    var ac = React.useContext(AssociateTemplateContext);
    return React.createElement("div", null,
        "Already added (",
        ac.associatedTemplates.length,
        ")");
};
var SelectedItems = function () {
    var ac = React.useContext(AssociateTemplateContext);
    // const removeAssociation = (assGuid:any) => {
    //     console.log("Remove Ass > ", assGuid);
    // }
    return (React.createElement(React.Fragment, null, ac.associatedTemplates.length > 0 ? (React.createElement("ul", { className: "list-unstyled selected-items" }, ac.associatedTemplates.map(function (at, index) {
        var _a;
        return (React.createElement("li", { key: at.GUID },
            React.createElement("span", null, (_a = at.Item) === null || _a === void 0 ? void 0 : _a.Title),
            React.createElement(FontAwesomeIcon, { icon: faTimesCircle, onClick: function () {
                    ac.onRemove(at);
                } })));
    }))) : (React.createElement("div", { className: "no-templates" },
        React.createElement("div", { className: "help-text" }, "Search for templates to add here..."),
        React.createElement("div", { className: "mock-template" }),
        React.createElement("div", { className: "mock-template" })))));
};
var AssociateTemplate = function (props) {
    // console.log("props.associatedTemplates", props.associatedTemplates);
    var onAdd = function (tba) {
        props.onAssociatedAdd(tba);
    };
    var onRemove = function (tba) {
        props.onAssociatedRemove(tba);
    };
    var associatedContext = {
        props: props,
        associatedTemplates: props.associatedTemplates,
        onAdd: onAdd,
        onRemove: onRemove
    };
    return (React.createElement(AssociateTemplateContext.Provider, { value: associatedContext },
        React.createElement("div", { className: "associate-template" }, props.children)));
};
AssociateTemplate.Search = Search;
AssociateTemplate.Count = Count;
AssociateTemplate.SelectedItems = SelectedItems;
export default AssociateTemplate;
