import * as React from "react";
import "./animated-loader.scss";
var AnimatedLoader = React.memo(function (props) {
    var cTheme = "light";
    var cSize = "med";
    if (props.theme !== undefined)
        cTheme = props.theme;
    if (props.size !== undefined)
        cSize = props.size;
    if (props.type === undefined || props.type === "circle") {
        if (props.text !== undefined && props.text !== "") {
            return (React.createElement("div", { className: "animated-loader-wrapper fadein-500ms" },
                React.createElement("div", { className: "animated-loader" + (cTheme && " al-" + cTheme) + (cSize && " al-" + cSize) }),
                React.createElement("div", { className: "loader-text" }, props.text)));
        }
        else {
            return React.createElement("div", { className: "animated-loader" + (cTheme && " al-" + cTheme) + (cSize && " al-" + cSize) });
        }
    }
    else if (props.type !== undefined && props.type === "squares") {
        return (React.createElement("div", { className: "animated-loader-wrapper-square fadein-500ms" },
            React.createElement("div", { className: "loader-squares" },
                React.createElement("div", { className: "loader" + (cSize && " al-" + cSize) },
                    React.createElement("div", { className: "l_main" },
                        React.createElement("div", { className: "l_square" },
                            React.createElement("span", null),
                            React.createElement("span", null),
                            React.createElement("span", null)),
                        React.createElement("div", { className: "l_square" },
                            React.createElement("span", null),
                            React.createElement("span", null),
                            React.createElement("span", null)),
                        React.createElement("div", { className: "l_square" },
                            React.createElement("span", null),
                            React.createElement("span", null),
                            React.createElement("span", null)),
                        React.createElement("div", { className: "l_square" },
                            React.createElement("span", null),
                            React.createElement("span", null),
                            React.createElement("span", null)))),
                props.text !== undefined ? React.createElement("div", { className: "loader-text" }, props.text) : null)));
    }
    return null;
});
export default AnimatedLoader;
