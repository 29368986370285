import { Skeleton } from "@mantine/core";
import React from "react";
import "./recommendations-tabs-skeletons.scss";
export var RecommendationTabsSkeletons = function () { return (React.createElement("div", { className: "recommendation-tabs-skeletons" },
    React.createElement("div", { className: "tab" },
        React.createElement(Skeleton, { w: 30, h: 12.25, radius: 16 }),
        React.createElement(Skeleton, { circle: true, ml: 10, w: 23, h: 23 })),
    React.createElement("div", { className: "tab" },
        React.createElement(Skeleton, { w: 53, h: 12.25, radius: 16 }),
        React.createElement(Skeleton, { circle: true, ml: 9, w: 23, h: 23 })),
    React.createElement("div", { className: "tab" },
        React.createElement(Skeleton, { w: 53, h: 12.25, radius: 16 }),
        React.createElement(Skeleton, { circle: true, ml: 9, w: 23, h: 23 })))); };
