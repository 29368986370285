import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import React from "react";
import { useRecommendationCategory } from "../use-recommendation-category.hook";
import { ADD_RECOMMENDATIONS_FILTERS_LIST } from "../utils/recommendations-filter.utils";
export var RecommendationCardCategoryBadge = function (_a) {
    var categoryGuid = _a.categoryGuid, onClick = _a.onClick, isLoading = _a.isLoading;
    var category = useRecommendationCategory(categoryGuid).category;
    if (!(category === null || category === void 0 ? void 0 : category.name))
        return null;
    var filter = ADD_RECOMMENDATIONS_FILTERS_LIST.find(function (filter) { return filter.value === category.name.toLowerCase(); });
    return React.createElement(OrchestryBadge, { color: filter === null || filter === void 0 ? void 0 : filter.color, isLoading: isLoading, key: categoryGuid, title: category.name, value: category.name.toLowerCase(), icon: category.icon, onClick: onClick });
};
