var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./settings-blocked-words.scss";
import { NavLink } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import Async from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { SubtitleButton } from "app/components/common/subtitle";
import * as PageHeader from "app/components/common/page-header";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeTextSmall, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
var SettingsNamingConventions = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState({}), currentTenantConfigInternalState = _a[0], setCurrentTenantConfigInternalState = _a[1];
    var _b = React.useState(false), anyEdits = _b[0], setAnyEdits = _b[1];
    var _c = React.useState([]), prefixes = _c[0], setPrefixes = _c[1];
    var _d = React.useState([]), suffixes = _d[0], setSuffixes = _d[1];
    var _e = React.useState([]), blockedWords = _e[0], setBlockedWords = _e[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    // After load is complete, populate the copy of the server data in memory for editing.
    React.useEffect(function () {
        //console.log("setting state", currentTenantConfiguration);
        if (currentTenantConfiguration.GUID !== undefined) {
            if (currentTenantConfiguration.GUID !== "") {
                var currentTenantConfigCopy = __assign({}, currentTenantConfiguration);
                // if(currentTenantConfiguration.GroupNamingPrefixValue !== undefined && currentTenantConfiguration.GroupNamingPrefixValue !== ""){
                //    setPrefixes(JSON.parse(currentTenantConfiguration.GroupNamingPrefixValue));
                // }
                // if(currentTenantConfiguration.GroupNamingSuffixValue !== undefined && currentTenantConfiguration.GroupNamingSuffixValue !== ""){
                //    setSuffixes(JSON.parse(currentTenantConfiguration.GroupNamingSuffixValue));
                // }
                if (currentTenantConfiguration.CustomBlockedWordsList !== undefined && currentTenantConfiguration.CustomBlockedWordsList !== "") {
                    setBlockedWords(JSON.parse(currentTenantConfiguration.CustomBlockedWordsList));
                }
                setCurrentTenantConfigInternalState(currentTenantConfigCopy);
            }
        }
    }, [currentTenantConfiguration]);
    // Watch for change the prefix and suffix objects in memory and if change trigger their conversion and saving to current config object.
    React.useEffect(function () {
        updatePrefixSuffix();
    }, [prefixes, suffixes]);
    //Update the Prefix and Suffic string value from the memory objects after the memory objects are modified
    var updatePrefixSuffix = function () {
        //console.log("new updte object > before update", prefixes, suffixes);
        var newObject = {
            GroupNamingPrefixValue: JSON.stringify(prefixes),
            GroupNamingSuffixValue: JSON.stringify(suffixes)
        };
        //console.log("new updte object", newObject)
        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), newObject));
    };
    React.useEffect(function () {
        var stringBlockwords = JSON.stringify(blockedWords);
        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { CustomBlockedWordsList: stringBlockwords }));
    }, [blockedWords]);
    //after the current config object is updated, check to see if the save button should be highlighted.
    React.useEffect(function () {
        checkIfSaveable();
    }, [currentTenantConfigInternalState]);
    // Checks for edits
    var checkIfSaveable = function () {
        //console.log("currentTenantConfigInternalState",currentTenantConfigInternalState);
        if (currentTenantConfigInternalState.GUID !== undefined) {
            if (currentTenantConfigInternalState.GUID !== "") {
                //console.log(JSON.stringify(currentTenantConfigInternalState), "\n\n\n", JSON.stringify(currentTenantConfiguration));
                if (JSON.stringify(currentTenantConfigInternalState) !== JSON.stringify(currentTenantConfiguration)) {
                    //console.log("Does Match",false);
                    if (anyEdits === false) {
                        setAnyEdits(true);
                    }
                }
            }
        }
    };
    // On save, save to server and set save button back to non-edited.
    var save = function () {
        dispatch(TenantConfigurationCurrentStore.Update(__assign({}, currentTenantConfigInternalState)));
        setAnyEdits(false);
    };
    //
    // React.useEffect(()=>{
    //     try{
    //         saveDiv.current.onclick=save
    //     }catch{}
    // })
    return (React.createElement("div", { className: "settings-naming-conventions fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Settings", subTitle: "Edit" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/" },
                React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
            React.createElement("div", { onClick: function () { return save(); }, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" }))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Started },
            React.createElement(Row, null,
                React.createElement(Col, { md: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, outlineOnly: true }),
                        React.createElement(SkeletonLoaderFakeTextSmall, { marginTop: 10 }))))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Succeeded }, currentTenantConfigInternalState.GUID !== "" ? (React.createElement(React.Fragment, null,
            React.createElement(Container, { fluid: true },
                React.createElement(Row, { className: "" },
                    React.createElement(Col, { md: 6 },
                        React.createElement("div", { className: "kt-portlet" },
                            React.createElement("div", { className: "kt-portlet__head" },
                                React.createElement("div", { className: "kt-portlet__head-label" },
                                    React.createElement("h3", { className: "kt-portlet__head-title" }, "Blocked Words"))),
                            React.createElement("div", { className: "kt-portlet__body" },
                                React.createElement("div", { className: "blocked-words-form" },
                                    React.createElement("div", { className: "blocked-words" },
                                        React.createElement(TagsInput, { value: blockedWords !== null ? blockedWords : [], onChange: function (tags) { return setBlockedWords(tags); }, addKeys: [9, 13, 188], removeKeys: [8, 46] })),
                                    React.createElement("p", { className: "blocked-help" }, "Hit tab, comma, or return to add word. Hit backspace/delete to remove."))))))))) : null)));
});
export default SettingsNamingConventions;
// export const SettingsNamingConventionsPreview: React.FC<IPropsPreview> = React.memo(({currentTenantConfiguration, groupName, user}) => {
//    console.log("props.groupName", groupName);
//    const replacedGroupName:string | undefined =  groupName?.replace(/\W+/g, " ").split(' ').join(currentTenantConfiguration.GroupNamingDelimiter);
//    const prefixes = currentTenantConfiguration.GroupNamingPrefixValue !== undefined && currentTenantConfiguration.GroupNamingPrefixValue.length > 0 ? JSON.parse(currentTenantConfiguration.GroupNamingPrefixValue) as psfix[] : [] as psfix[];
//    const suffixes = currentTenantConfiguration.GroupNamingSuffixValue !== undefined && currentTenantConfiguration.GroupNamingSuffixValue.length > 0 ? JSON.parse(currentTenantConfiguration.GroupNamingSuffixValue) as psfix[] : [] as psfix[];
//    const replaceSpaces = (value:string|undefined) => {
//       return value?.split(' ').join(currentTenantConfiguration.GroupNamingDelimiter);
//    }
//    const metadataReplace = (type:string|undefined) => {
//       let returnValue = <><span className="chev">&lt;</span>{type}<span className="chev">&gt;</span></>;
//       console.log("user",user)
//       if(type !== undefined && type !== ""){
//             if(user !== undefined && user.GUID !== undefined){
//                switch(type) {
//                   case "Department":
//                         returnValue = <>{replaceSpaces(user.Department)}</>;
//                         break;
//                   case "Company":
//                         returnValue = <>{replaceSpaces(user.Company)}</>;
//                         break;
//                   case "Office":
//                         returnValue = <>{replaceSpaces(user.Office)}</>;
//                         break;
//                   case "StateOrProvince":
//                         returnValue = <>{replaceSpaces(user.StateOrProvince)}</>;
//                         break;
//                   case "CountryOrRegion":
//                         returnValue = <>{replaceSpaces(user.CountryOrRegion)}</>;
//                         break;
//                   case "JobTitle":
//                         returnValue = <>{replaceSpaces(user.JobTitle)}</>;
//                         break;
//                }
//             }
//       }
//       return returnValue;
//    }
//    return (
//       <span>
//             {currentTenantConfiguration.GroupNamingPrefixEnabled === true && prefixes !== null && prefixes.length > 0 ? prefixes.map((fix:psfix, fixIndex:number) => (
//                <span className="fix" key={fixIndex}>
//                   {fix.type === "metadata" && fix.value !== ""? metadataReplace(fix.value) : fix.value}
//                </span>
//             )):null}
//                {replacedGroupName === undefined?
//                <><span className="chev">&lt;</span>Group{currentTenantConfiguration.GroupNamingDelimiter !== undefined ? currentTenantConfiguration.GroupNamingDelimiter: " "}Name<span className="chev">&gt;</span></>
//                :
//                replacedGroupName}
//             {currentTenantConfiguration.GroupNamingSuffixEnabled === true && suffixes !== null && suffixes.length > 0 ? suffixes.map((fix:psfix, fixIndex:number) => (
//                <span className="fix" key={fixIndex}>
//                   {fix.type === "metadata" && fix.value !== ""? metadataReplace(fix.value) : fix.value}
//                </span>
//             )):null}
//       </span>
//    )
// });
// export const GetSettingsNamingConventionsPreview = (currentTenantConfiguration:TenantConfiguration, groupName:string, user:User) => {
//    let returnGroupName = "";
//    const replacedGroupName:string =  groupName?.replace(/\W+/g, " ").split(' ').join(currentTenantConfiguration.GroupNamingDelimiter);
//    const prefixes = currentTenantConfiguration.GroupNamingPrefixValue !== undefined && currentTenantConfiguration.GroupNamingPrefixValue.length > 0 ? JSON.parse(currentTenantConfiguration.GroupNamingPrefixValue) as psfix[] : [] as psfix[];
//    const suffixes = currentTenantConfiguration.GroupNamingSuffixValue !== undefined && currentTenantConfiguration.GroupNamingSuffixValue.length > 0 ? JSON.parse(currentTenantConfiguration.GroupNamingSuffixValue) as psfix[] : [] as psfix[];
//    const replaceSpaces = (value:string) => {
//       return value?.split(' ').join(currentTenantConfiguration.GroupNamingDelimiter);
//    }
//    const metadataReplace = (type:string|undefined) => {
//       let returnValue:string = "";
//       console.log("user",user)
//       if(type !== undefined && type !== ""){
//             if(user !== undefined && user.GUID !== undefined){
//                switch(type) {
//                   case "Department":
//                         returnValue = replaceSpaces(user.Department);
//                         break;
//                   case "Company":
//                         returnValue = replaceSpaces(user.Company);
//                         break;
//                   case "Office":
//                         returnValue = replaceSpaces(user.Office);
//                         break;
//                   case "StateOrProvince":
//                         returnValue = replaceSpaces(user.StateOrProvince);
//                         break;
//                   case "CountryOrRegion":
//                         returnValue = replaceSpaces(user.CountryOrRegion);
//                         break;
//                   case "JobTitle":
//                         returnValue = replaceSpaces(user.JobTitle);
//                         break;
//                }
//             }
//       }
//       return returnValue;
//    }
//    if(currentTenantConfiguration.GroupNamingConventionsEnabled){
//       if(currentTenantConfiguration.GroupNamingPrefixEnabled === true && prefixes !== null && prefixes.length > 0){
//             prefixes.forEach((fix:psfix, fixIndex:number) => {
//                if(fix.type === "metadata" && fix.value !== ""){
//                   returnGroupName += metadataReplace(fix.value);
//                } else {
//                   returnGroupName += fix.value
//                }
//             })
//       }
//       returnGroupName += replacedGroupName;
//       if(currentTenantConfiguration.GroupNamingSuffixEnabled === true && suffixes !== null && suffixes.length > 0){
//             suffixes.forEach((fix:psfix, fixIndex:number) => {
//                if(fix.type === "metadata" && fix.value !== ""){
//                   returnGroupName += metadataReplace(fix.value);
//                } else {
//                   returnGroupName += fix.value
//                }
//             })
//       }
//       return returnGroupName;
//    }
//    return groupName;
// }
