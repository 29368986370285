var _a, _b;
export var LifecycleGuestPolicyType;
(function (LifecycleGuestPolicyType) {
    LifecycleGuestPolicyType["Review"] = "Review";
    LifecycleGuestPolicyType["Request"] = "Request";
})(LifecycleGuestPolicyType || (LifecycleGuestPolicyType = {}));
export var ExecutionType;
(function (ExecutionType) {
    ExecutionType[ExecutionType["Inactivity"] = 0] = "Inactivity";
})(ExecutionType || (ExecutionType = {}));
export var ReviewType;
(function (ReviewType) {
    ReviewType[ReviewType["FixedInterval"] = 0] = "FixedInterval";
})(ReviewType || (ReviewType = {}));
export var ReviewRecurrence;
(function (ReviewRecurrence) {
    ReviewRecurrence[ReviewRecurrence["Monthly"] = 0] = "Monthly";
    ReviewRecurrence[ReviewRecurrence["Quarterly"] = 1] = "Quarterly";
    ReviewRecurrence[ReviewRecurrence["SemiAnnually"] = 2] = "SemiAnnually";
    ReviewRecurrence[ReviewRecurrence["Annually"] = 3] = "Annually";
})(ReviewRecurrence || (ReviewRecurrence = {}));
export var InactivityThresholdMap = (_a = {},
    _a[ReviewRecurrence.Monthly] = 30,
    _a[ReviewRecurrence.Quarterly] = 90,
    _a[ReviewRecurrence.SemiAnnually] = 180,
    _a[ReviewRecurrence.Annually] = 365,
    _a);
export var ReviewRecurrenceStringMap = (_b = {},
    _b[ReviewRecurrence.Monthly] = "30 days",
    _b[ReviewRecurrence.Quarterly] = "90 days",
    _b[ReviewRecurrence.SemiAnnually] = "180 days",
    _b[ReviewRecurrence.Annually] = "365 days",
    _b);
export var ExecutionApprovers;
(function (ExecutionApprovers) {
    ExecutionApprovers[ExecutionApprovers["WorkspaceOwners"] = 0] = "WorkspaceOwners";
    ExecutionApprovers[ExecutionApprovers["SpecificUsers"] = 1] = "SpecificUsers";
})(ExecutionApprovers || (ExecutionApprovers = {}));
export var GuestLifecycleTimeUnit;
(function (GuestLifecycleTimeUnit) {
    GuestLifecycleTimeUnit[GuestLifecycleTimeUnit["Days"] = 0] = "Days";
    GuestLifecycleTimeUnit[GuestLifecycleTimeUnit["Weeks"] = 1] = "Weeks";
    GuestLifecycleTimeUnit[GuestLifecycleTimeUnit["Months"] = 2] = "Months";
})(GuestLifecycleTimeUnit || (GuestLifecycleTimeUnit = {}));
export var ReviewNoActionType;
(function (ReviewNoActionType) {
    ReviewNoActionType[ReviewNoActionType["RemoveNonReviewed"] = 0] = "RemoveNonReviewed";
    ReviewNoActionType[ReviewNoActionType["LeaveNonReviewed"] = 1] = "LeaveNonReviewed";
})(ReviewNoActionType || (ReviewNoActionType = {}));
