/**
 * The comparison score to another entity (EG: a typical company of your size)
 */
export var ComparisonScore;
(function (ComparisonScore) {
    /**
     * The score is low
     */
    ComparisonScore["Low"] = "Low";
    /**
     * The score is average
     */
    ComparisonScore["Average"] = "Average";
    /**
     * The score is high
     */
    ComparisonScore["High"] = "High";
})(ComparisonScore || (ComparisonScore = {}));
