import { UsersListActionTypes } from "./types";
import { AsyncCallType } from "app/store/async/types";
export var usersListActionMap = [
    {
        actionType: UsersListActionTypes.Users_List_Load,
        callType: AsyncCallType.Post,
        keyType: "guid",
        ApiUrl: "/api/Users/List",
        responseDataType: {}
    },
    {
        actionType: UsersListActionTypes.Users_List_Refresh,
        callType: AsyncCallType.Post,
        keyType: "guid",
        ApiUrl: "/api/Users/List",
        responseDataType: {}
    },
    {
        actionType: UsersListActionTypes.Users_List_Page,
        callType: AsyncCallType.Post,
        keyType: "guid",
        ApiUrl: "/api/Users/List",
        responseDataType: {}
    }
];
