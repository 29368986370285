var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./orchestry-admin-tool-api-throttling-errors.scss";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Portlet, PortletBody } from "app/components/common/portlet";
import axios from "axios";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as TenantListStore from "app/store/tenants/list";
import FormButton from "app/components/common/form-button";
import Chart from "react-apexcharts";
var OrchestryAdminToolApiThrottlingErrors = React.memo(function () {
    var dispatch = useDispatch();
    var _a = React.useState(null), data = _a[0], setData = _a[1];
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _b = React.useState("All"), selectedTenant = _b[0], setSelectedTenant = _b[1];
    var _c = React.useState(null), servers = _c[0], setServers = _c[1];
    var _d = React.useState(document.defaultThrottleServer), selectedServer = _d[0], setSelectedServer = _d[1];
    var _e = React.useState(false), isLoading = _e[0], setIsLoading = _e[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        getData();
        getServers();
    }, []);
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        timerID = setInterval(function () {
            getData(180, selectedTenant);
        }, 10000);
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
        getData(180, selectedTenant);
    }, [selectedTenant, selectedServer]);
    var getData = function (top, tenantGuid) {
        if (top === void 0) { top = 180; }
        // setData([]);
        setIsLoading(true);
        if (tenantGuid === undefined) {
            tenantGuid = selectedTenant;
        }
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/ErrorTimeline" + (tenantGuid !== undefined ? "?tenantGuid=" + tenantGuid : "") + (selectedServer !== undefined ? "&serverUrl=" + selectedServer : "");
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            formatData("Data", response.data);
            // formatData("Data", testData as ThrottleSecond[]);
            // formatData("Wait", testData as ThrottleSecond[]);
            setIsLoading(false);
        })
            .catch(function (response) { });
    };
    var getServers = function () {
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Throttling/Servers";
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            setServers(response.data);
            if (selectedServer === undefined || selectedServer === "") {
                setSelectedServer(response.data[0]);
                document.defaultThrottleServer = response.data[0];
            }
        })
            .catch(function (response) { });
    };
    var formatData = function (type, newData) {
        var dataArray = [];
        var GraphThrottleCounts = {
            id: "Graph 429 Counts",
            name: "Graph 429 Counts",
            color: "#7E66B0",
            data: []
        };
        var SharePointThrottleCounts = {
            id: "SharePoint 429 Counts",
            name: "SharePoint 429 Counts",
            color: "#7E66B0",
            data: []
        };
        newData.forEach(function (element) {
            if (element !== undefined) {
                GraphThrottleCounts.data.push({ x: new Date(element.DateTimeKey).getTime(), y: element.Graph429Count, tenants: element.GraphTenants, type: "Graph" });
                SharePointThrottleCounts.data.push({ x: new Date(element.DateTimeKey).getTime(), y: element.SharePoint429Count, tenants: element.SharePointTenants, type: "SharePoint" });
            }
        });
        dataArray.push(GraphThrottleCounts);
        dataArray.push(SharePointThrottleCounts);
        console.log(type, dataArray);
        setData(dataArray);
    };
    var getTenantName = function (guid) {
        if (tenants !== undefined && tenants !== null) {
            var foundTenant = tenants.find(function (t) { return t.GUID === guid; });
            if (foundTenant !== undefined) {
                return foundTenant.DisplayName;
            }
        }
        return "";
    };
    return (React.createElement("div", { className: "orchestry-admin-tool-api-throttling-errors fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 5 },
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, "Select Tenant"),
                    tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, defaultValue: selectedTenant, list: __spreadArray(__spreadArray([], [{ id: "All", value: "All", title: "All" }], false), tenants
                            .filter(function (t) { return t.DisplayName !== undefined && t.DisplayName !== ""; })
                            .map(function (t) { return ({
                            id: t.GUID !== undefined ? t.GUID : "",
                            value: t.GUID !== undefined ? t.GUID : "",
                            title: t.DisplayName !== undefined ? t.DisplayName : ""
                        }); }), true), onChange: function (tenantGuid) {
                            setSelectedTenant(tenantGuid);
                        } })))),
            React.createElement(Col, { md: 5 },
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, "Select Server"),
                    servers === undefined || servers === null || servers.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedServer, list: servers.map(function (s) { return ({
                            id: s,
                            value: s,
                            title: s
                        }); }), onChange: function (serverUrl) {
                            setSelectedServer(serverUrl);
                            document.defaultThrottleServer = serverUrl;
                        } })))),
            React.createElement(Col, null,
                React.createElement("div", { className: "refresh-button", style: { marginTop: "25px" } }, isLoading === false ? React.createElement(FormButton, { onClick: function () { return getData(); }, text: "Refresh Data", theme: "blue" }) : React.createElement(SkeletonLoaderFakeBox, null)))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12, className: "left-col" },
                React.createElement("strong", null, "429 Error Metrics"),
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null, data !== null ? (React.createElement("div", { className: "", style: { height: "250px" } },
                        React.createElement(Chart, { options: {
                                chart: {
                                    id: "basic-bar",
                                    animations: {
                                        enabled: true,
                                        easing: "easeinout",
                                        speed: 800,
                                        animateGradually: {
                                            enabled: true,
                                            delay: 150
                                        },
                                        dynamicAnimation: {
                                            enabled: true,
                                            speed: 350
                                        }
                                    }
                                },
                                theme: {
                                    palette: "palette2" // upto palette10
                                },
                                tooltip: {
                                    x: {
                                        format: "mm:ss"
                                    },
                                    custom: function (_a) {
                                        var series = _a.series, seriesIndex = _a.seriesIndex, dataPointIndex = _a.dataPointIndex, w = _a.w;
                                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                        return ("<div style='padding:5px; font-size:11px;'>" +
                                            "<h5>" +
                                            data.type +
                                            "</h5>" +
                                            "<div><b>Count</b>: " +
                                            data.y +
                                            "</div>" +
                                            "<div><b>Tenants</b>: " +
                                            data.tenants.map(function (t) { return "<br />" + getTenantName(t); }).join("") +
                                            "</div>" +
                                            "</div>");
                                    }
                                },
                                stroke: {
                                    width: 1
                                },
                                xaxis: {
                                    type: "datetime"
                                }
                            }, series: data, type: "line", height: "250" }))) : null))))));
});
export default OrchestryAdminToolApiThrottlingErrors;
