import { Editor, Transforms, Element, Range } from "slate";
export var withInlines = function (editor) {
    var insertData = editor.insertData, insertText = editor.insertText, isInline = editor.isInline, normalizeNode = editor.normalizeNode;
    editor.isInline = function (element) {
        //@ts-ignore
        return element.type === "link" || isInline(element);
    };
    editor.insertText = function (text) {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        }
        else {
            insertText(text);
        }
    };
    editor.insertData = function (data) {
        var text = data.getData("text/plain");
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        }
        else {
            insertData(data);
        }
    };
    editor.normalizeNode = function (entry) {
        var node = entry[0], path = entry[1];
        //@ts-ignore
        if (node.type === "link") {
            //@ts-ignore
            if (node.children[0].text === "") {
                Transforms.removeNodes(editor, { at: path });
            }
        }
        return normalizeNode(entry);
    };
    return editor;
};
export var wrapLink = function (editor, url) {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }
    var selection = editor.selection;
    var isCollapsed = selection && Range.isCollapsed(selection);
    var link = {
        type: "link",
        url: url,
        children: isCollapsed ? [{ text: url }] : []
    };
    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    }
    else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
    }
};
export var isLinkActive = function (editor) {
    //@ts-ignore
    var link = Editor.nodes(editor, {
        match: function (n) {
            //@ts-ignore
            return !Editor.isEditor(n) && Element.isElement(n) && n.type === "link";
        }
    })[0];
    return !!link;
};
export var unwrapLink = function (editor) {
    Transforms.unwrapNodes(editor, {
        match: function (n) {
            //@ts-ignore
            return !Editor.isEditor(n) && Element.isElement(n) && n.type === "link";
        }
    });
};
function isUrl(str) {
    var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
        return true;
    }
    else {
        return false;
    }
}
export var toggleMark = function (editor, format) {
    var isActive = isMarkActive(editor, format);
    if (format === "h1") {
        Editor.removeMark(editor, "h2");
        Editor.removeMark(editor, "h3");
    }
    else if (format === "h2") {
        Editor.removeMark(editor, "h1");
        Editor.removeMark(editor, "h3");
    }
    else if (format === "h3") {
        Editor.removeMark(editor, "h1");
        Editor.removeMark(editor, "h2");
    }
    if (isActive) {
        Editor.removeMark(editor, format);
    }
    else {
        Editor.addMark(editor, format, true);
    }
};
export var isMarkActive = function (editor, format) {
    var marks = Editor.marks(editor) || "";
    return marks ? marks[format] === true : false;
};
