import axios from "axios";
import { injectIntl } from "react-intl";
import Store from "../store";
var LocalizationService = /** @class */ (function () {
    function LocalizationService() {
        this.static = function () { };
    }
    LocalizationService.currentLocale = null;
    LocalizationService.IntlShape = null;
    LocalizationService.AllLangauges = [
        {
            lang: "en",
            name: "English"
        },
        {
            lang: "fr",
            name: "French"
        },
        {
            lang: "no",
            name: "Norwegian"
        },
        {
            lang: "nl",
            name: "Dutch"
        },
        {
            lang: "de",
            name: "German"
        },
        {
            lang: "sv",
            name: "Swedish"
        },
        {
            lang: "es",
            name: "Spanish"
        },
        {
            lang: "pt",
            name: "Portuguese"
        }
    ];
    LocalizationService.getLocalizedLabelsFromServer = function (local) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Localization?culture=" + local;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LocalizationService.updateLocalizedLabelOnServer = function (item) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(item));
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "PATCH";
            axiosConfig.url = "/api/Localization";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LocalizationService.getLocalizedMessages = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Localization/Current";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LocalizationService.setUserCulture = function (local) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/users/SetCulture?culture=" + local;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                if (Store !== undefined) {
                    console.log("Store", Store);
                    //@ts-ignore
                    //Store.dispatch(OrchestryContextStore.Refresh({ Culture: local }));
                    document.location.reload();
                }
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LocalizationService.getLocalizationEditors = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Localization/EditorUsers";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LocalizationService.updateLocalizationEditor = function (user) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            user.LocalizationEditorLangsJson = JSON.stringify(user.LocalizationEditorLangs);
            formData.append("data", JSON.stringify(user));
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/Localization/EditorUsers";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LocalizationService.getOrchestryUsers = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Localization/EditorUsersSearch?term=" + searchTerm;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LocalizationService.getServers = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Localization/ServerItems";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LocalizationService.updateServer = function (item, action) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(item));
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/Localization/" + (action === "update" ? "ServerItemsUpdate" : "ServerItemsRemove");
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    LocalizationService.bustServerCache = function (item) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(item));
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/Localization/BustRemoteCache";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return LocalizationService;
}());
export { LocalizationService };
function Message(_a) {
    var intl = _a.intl;
    return intl.formatMessage({ id: "app.greeting" });
}
export default injectIntl(Message);
