import { LibraryChannelCurrentActionTypes } from "./types";
export function Load(libraryChannel) {
    if (libraryChannel === undefined)
        libraryChannel = {};
    //console.log("Load Current LibraryTemplate > ", library-template)
    return {
        type: LibraryChannelCurrentActionTypes.LibraryChannel_Current_Load,
        payload: libraryChannel
    };
}
export function Update(libraryChannel) {
    return {
        type: LibraryChannelCurrentActionTypes.LibraryChannel_Current_Update,
        payload: libraryChannel
    };
}
export function Clear() {
    return {
        type: LibraryChannelCurrentActionTypes.LibraryChannel_Current_Clear
    };
}
export var libraryChannelCurrentMiddlware = function (store, next, action) {
    next(action);
};
