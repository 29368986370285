var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import "./login.scss";
import { useSelector, useDispatch } from "react-redux";
import * as AuthStore from "app/store/auth";
import OrchestryLogo from "app/assets/img/logo-orchestry-large.png";
import { popupCenter } from "app/utils/popupCenter";
var Login = React.memo(function (props) {
    //const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    var dispatch = useDispatch();
    var auth = useSelector(function (state) { return state.authState; });
    var _a = useState({}), popupWindow = _a[0], setPopupWindow = _a[1];
    // let urlPrefix = "https://localhost:44316";
    // if(window.location.href.indexOf("3000") === -1){
    //   urlPrefix = window.location.origin;
    // }
    //let urlPrefix = "https://localhost:44316";
    //if(window.location.href.indexOf("3000") === -1){
    var urlPrefix = window.location.origin;
    //}
    var openPopupWindow = function () {
        popupCenter(urlPrefix + "/Account/SignIn/", "Orchestry Login", 500, 700);
        //setPopupWindow(window.open(urlPrefix + "/Account/SignIn/","", "width=500,height=700"));
    };
    // Listen for popup callback.
    React.useEffect(function () {
        //@ts-ignore
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        // Listen to message from child window
        //@ts-ignore
        eventer(messageEvent, 
        //@ts-ignore
        function (e) {
            //console.log('origin: ', e.origin)
            // Check if origin is proper
            //if( e.origin !== 'http://localhost' ){ return }
            //if(e.origin.indexOf("44316") > -1){
            //console.log('parent received message!: ', e.data);
            if (e.data !== undefined && e.data.AccessToken !== undefined) {
                console.log("LOGIN POPUP DONE");
                dispatch(AuthStore.Login(__assign(__assign({}, e.data), { isAuthorized: true })));
            }
            //}
        }, false);
    }, []);
    return (React.createElement("div", { className: "login" },
        React.createElement("div", { className: "orch-logo" },
            React.createElement("img", { className: "logo", src: OrchestryLogo, alt: "Orchestry" })),
        React.createElement("div", { className: "custom-button", "data-pw": "signin", onClick: function () { return openPopupWindow(); } }, "Sign In with Microsoft")));
});
export default Login;
