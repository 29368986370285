var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Text } from "@mantine/core";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import { RecommendationSource } from "appv2/api/recommendations/recommendation-source.type";
import { useUpdateRecommendationVisibilityMutation, useUpdateRecommendationVoteMutation } from "appv2/api/recommendations/recommendations.api";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { orchestryToast } from "appv2/components/orchestry-notifications/orchestry-toast-notification.component";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useURLParamDisplayMode } from "appv2/hooks/use-display-mode.hook";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { titleCase } from "title-case";
import { useRecommendationsPage } from "../../use-recommendations-page.hook";
var COMBOBOX_DATES = [
    { value: "7", label: "7 Days" },
    { value: "14", label: "14 Days" },
    { value: "30", label: "1 Month" },
    { value: "180", label: "6 Months" },
    { value: "365", label: "1 Year" },
    { value: "null", label: "Forever" }
];
/**
 * A helper hook for the RecommendationCardActionButtonsSection component.
 * @param recommendation The recommendation.
 */
export var useRecommendationCardActionButtonsSection = function (recommendation) {
    var _a, _b, _c;
    var intl = useIntl();
    var tenantGuid = useHealthCheckRouteParams().tenantGuid;
    var displayMode = useURLParamDisplayMode().displayMode;
    var _d = useUpdateRecommendationVisibilityMutation({ fixedCacheKey: "update-recommendation-visibility" }), updateRecommendationVisibility = _d[0], snoozeSuccess = _d[1].isSuccess;
    var onRecommendationSuccessfullyUpdated = useRecommendationsPage().onRecommendationSuccessfullyUpdated;
    var isResolved = recommendation.RecommendationRecord.Visibility === RecommendationRecordVisibility.Resolved;
    var isSnoozed = recommendation.RecommendationRecord.Visibility === RecommendationRecordVisibility.Snoozed;
    var showViewDetailsButton = !isResolved;
    var showResolveButton = !isResolved && !isSnoozed;
    var showSnoozeDropdown = !isResolved && !isSnoozed;
    var recommendationGuid = (_a = recommendation.RecommendationRecord) === null || _a === void 0 ? void 0 : _a.RecommendationGUID;
    var recommendationRecordGuid = recommendation.RecommendationRecord.UniqueId;
    var recommendationName = titleCase((_b = recommendation.RecommendationRecord.DisplayName) === null || _b === void 0 ? void 0 : _b.toLocaleLowerCase());
    var isCustom = recommendation.RecommendationRecord.Source === RecommendationSource.Custom;
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    var showInsightReportButonOnCard = !isCustom && ((_c = recommendation === null || recommendation === void 0 ? void 0 : recommendation.Recommendation) === null || _c === void 0 ? void 0 : _c.OrchestryReportLink) ? true : false;
    var reopenToolTip = isCustom
        ? "By clicking Reopen, the custom recommendation will be immediately reappear under Open."
        : "By clicking Reopen, the recommendation will be flagged for sync in the next processing cycle. If the recommendation is still relevant, it will then appear under Open.";
    var onOptionClick = function (e, date) { return __awaiter(void 0, void 0, void 0, function () {
        var snoozeDuration;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    snoozeDuration = ((_a = date.label) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "forever" ? null : Number(date.value);
                    return [4 /*yield*/, updateRecommendationVisibility({
                            RecommendationGUID: recommendationGuid,
                            Visibility: RecommendationRecordVisibility.Snoozed,
                            SnoozeDuration: snoozeDuration
                        })];
                case 1:
                    _c.sent();
                    orchestryToast(React.createElement(React.Fragment, null,
                        React.createElement(Text, { span: true, c: "text-primary.0", size: "sm" },
                            "Snoozed ", "".concat(((_b = date.label) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === "forever" ? "" : "for"),
                            " ",
                            React.createElement(Text, { span: true, fw: 700 }, date.label))), {
                        autoClose: 2500,
                        progressBarVariant: "success",
                        toastId: "recommendation-card-resolve-snooze-toast-".concat(recommendationGuid),
                        icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "success-6", iconClassName: ORCH_ICONS.clockSnooze })
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var onSectionClick = function (e) { return e.stopPropagation(); };
    var onReopenClick = function (recommendationGuid) {
        updateRecommendationVisibility({
            RecommendationGUID: recommendationGuid,
            Visibility: RecommendationRecordVisibility.Visible
        }).then(function () {
            if (isCustom) {
                OrchestrySuccessToast("Moved to Open");
            }
            else {
                orchestryToast(React.createElement(React.Fragment, null,
                    React.createElement(Text, { span: true, c: "text-primary.0", size: "sm" }, "Queued for pending resync")), {
                    autoClose: 2500,
                    progressBarVariant: "requeuetoast",
                    toastId: "recommendation-card-reopen-toast-".concat(recommendationGuid),
                    icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "blue-7", iconClassName: ORCH_ICONS.scan })
                });
            }
        });
    };
    var options = COMBOBOX_DATES.map(function (option) { return ({ value: option.value, label: option.label }); });
    var _e = useState(true), tooltipVisible = _e[0], setTooltipVisible = _e[1];
    var updateVote = useUpdateRecommendationVoteMutation()[0];
    var thumbOnClick = function (value) {
        updateVote({ RecommendationGUID: recommendationGuid, Vote: value }).then(function () {
            orchestryToast(React.createElement(React.Fragment, null,
                React.createElement(Text, { span: true, c: "text-primary.0", size: "sm" }, "Thank you for your feedback!")), {
                autoClose: 2500,
                progressBarVariant: "heartpink",
                toastId: "recommendation-card-reopen-toast-".concat(recommendationGuid),
                icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "pink-8", iconClassName: ORCH_ICONS.thumbsUp })
            });
        });
    };
    var onResolveClick = function () {
        if (isCustom) {
            updateRecommendationVisibility({
                RecommendationGUID: recommendation.RecommendationRecord.RecommendationGUID,
                Visibility: RecommendationRecordVisibility.Resolved
            }).then(function () {
                OrchestrySuccessToast("Moved to Resolved.");
            });
        }
        else {
            openOrchestryModal("resolveRecommendationModal", { recommendation: recommendation, intl: intl }, "lg");
        }
    };
    var onDeleteRecommendationClicked = useCallback(function () {
        openOrchestryModal("deleteCustomRecommendationModal", { uniqueId: recommendationRecordGuid, name: recommendationName, intl: intl, onRecommendationSuccessfullyUpdated: onRecommendationSuccessfullyUpdated, tenantGuid: tenantGuid }, "md");
    }, [recommendationRecordGuid, recommendationName, intl, onRecommendationSuccessfullyUpdated, tenantGuid]);
    var onEditRecommendationClicked = useCallback(function () {
        openOrchestryModal("updateCustomRecommendationModal", { uniqueId: recommendationRecordGuid, name: recommendationName, intl: intl, onRecommendationSuccessfullyUpdated: onRecommendationSuccessfullyUpdated, tenantGuid: tenantGuid }, "xl");
    }, [recommendationRecordGuid, recommendationName, intl, onRecommendationSuccessfullyUpdated, tenantGuid]);
    return {
        showViewDetailsButton: showViewDetailsButton,
        showResolveButton: showResolveButton,
        showSnoozeDropdown: showSnoozeDropdown,
        currentUserIsBeaconUser: currentUserIsBeaconUser,
        showInsightReportButonOnCard: showInsightReportButonOnCard,
        isSnoozed: isSnoozed,
        isResolved: isResolved,
        options: options,
        onSectionClick: onSectionClick,
        snoozeSuccess: snoozeSuccess,
        updateRecommendationVisibility: updateRecommendationVisibility,
        onReopenClick: onReopenClick,
        onOptionClick: onOptionClick,
        tooltipVisible: tooltipVisible,
        setTooltipVisible: setTooltipVisible,
        thumbOnClick: thumbOnClick,
        onDeleteRecommendationClicked: onDeleteRecommendationClicked,
        onEditRecommendationClicked: onEditRecommendationClicked,
        onResolveClick: onResolveClick,
        isCustom: isCustom,
        displayMode: displayMode,
        intl: intl,
        reopenToolTip: reopenToolTip
    };
};
