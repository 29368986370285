var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import AnimatedLoader from "app/components/common/animated-loader";
import DisableArea from "app/components/common/disable-area";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import AuthenticationService from "app/services/authentication";
import * as AuthStore from "app/store/auth";
import { ServiceAppPermissionConfiguration } from "app/store/tenants/types";
import { popupCenter } from "app/utils/popupCenter";
import * as React from "react";
import { useDispatch } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./app-consent.scss";
var AppConsent = React.memo(function (props) {
    var _a = React.useState(false), showIsNotAdmin = _a[0], setShowIsNotAdmin = _a[1];
    var _b = React.useState(), errorResponse = _b[0], setErrorResponse = _b[1];
    var _c = React.useState(), consentStatusPrimaryApp = _c[0], setConsentStatusPrimaryApp = _c[1];
    var _d = React.useState(), consentStatusServiceApp = _d[0], setConsentStatusServiceApp = _d[1];
    var _e = React.useState(false), primaryForcedAppDone = _e[0], setPimaryForcedAppDone = _e[1];
    var _f = React.useState(false), serviceForcedAppDone = _f[0], setServiceForcedAppDone = _f[1];
    var _g = React.useState(), serviceAppInstalledAndUpdated = _g[0], setServiceAppInstalled = _g[1];
    var _h = React.useState(false), serviceAppBeingInstalled = _h[0], setServiceAppBeingInstalled = _h[1];
    var _j = React.useState(0), secondsAfterNewAppAdded = _j[0], setSecondsAfterNewAppAdded = _j[1];
    var _k = React.useState(null), serviceAppIsConnecting = _k[0], setServiceAppIsConnecting = _k[1];
    var dispatch = useDispatch();
    var isForced = function (fType) {
        var forcedShow = props.forced !== undefined ? props.forced : false;
        if (forcedShow === true) {
            if (fType === "pApp" && primaryForcedAppDone === false) {
                console.log("isForced pApp", true);
                return true;
            }
            if (fType === "sApp" && serviceForcedAppDone === false) {
                return true;
            }
        }
        if (fType === "sApp" && serviceForcedAppDone === false && props.licenseChange !== undefined) {
            return true;
        }
        return false;
    };
    React.useEffect(function () {
        loadConsentStatus();
    }, []);
    var loadConsentStatus = function () {
        setConsentStatusPrimaryApp(undefined);
        setConsentStatusServiceApp(undefined);
        setServiceAppInstalled(undefined);
        AuthenticationService.getConsentStatus(props.serviceAppConfig).then(function (consentStatus) {
            setConsentStatusPrimaryApp(consentStatus);
            setConsentStatusServiceApp(consentStatus);
            setServiceAppInstalled(consentStatus.ServiceAppInstalledAndUptoDate);
        });
    };
    var reloadConsentStatus = function () {
        loadConsentStatus();
    };
    React.useEffect(function () {
        if (isForced("pApp") === false &&
            isForced("sApp") === false &&
            consentStatusPrimaryApp !== undefined &&
            consentStatusPrimaryApp.OrchestryAppLastConsentedVersion !== null &&
            consentStatusPrimaryApp.ServiceAppLastConsentedVersion !== null) {
            if (consentStatusPrimaryApp.OrchestryAppLastConsentedVersion === consentStatusPrimaryApp.OrchestryAppPermissionVersion &&
                consentStatusPrimaryApp.ServiceAppLastConsentedVersion === consentStatusPrimaryApp.ServiceAppPermissionVersion &&
                serviceAppIsConnecting === true) {
                if (props.onAllComplete !== undefined) {
                    props.onAllComplete();
                }
            }
        }
    }, [consentStatusPrimaryApp, serviceAppIsConnecting]);
    React.useEffect(function () {
        if (
        //isForced('pApp') === false &&
        consentStatusServiceApp !== undefined &&
            consentStatusServiceApp.ServiceAppLastConsentedVersion !== null) {
            if (consentStatusServiceApp.ServiceAppPermissionVersion === consentStatusServiceApp.ServiceAppLastConsentedVersion) {
                if (consentStatusServiceApp.ServiceAppInstalledAndUptoDate === true) {
                    if (serviceAppIsConnecting === null) {
                        setServiceAppIsConnecting(false);
                    }
                }
            }
        }
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 3000);
            };
            if (consentStatusServiceApp !== undefined && consentStatusServiceApp.ServiceAppLastConsentedVersion !== null) {
                if (consentStatusServiceApp.ServiceAppLastConsentedVersion === consentStatusServiceApp.ServiceAppPermissionVersion && serviceAppIsConnecting === false) {
                    shouldRun = true;
                }
            }
            if (shouldRun) {
                var erroredOut = true;
                AuthenticationService.isServiceAppPermissionsWorking(props.serviceAppConfig).then(function (consentStatus) {
                    if (consentStatus === true) {
                        setServiceAppIsConnecting(true);
                    }
                    else {
                        runAgain();
                    }
                });
            }
            else {
                runAgain();
            }
        }
        watchForStatusUpdate();
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [consentStatusServiceApp, serviceAppIsConnecting]);
    var installUpdateServiceApp = function () {
        setServiceAppBeingInstalled(true);
        var runCountdown = true;
        if (consentStatusPrimaryApp !== undefined && consentStatusPrimaryApp.ServiceAppInstalledVersion === null) {
            runCountdown = true;
        }
        AuthenticationService.installUpdateServiceApp(props.serviceAppConfig).then(function (serviceAppInstalledSuccessfully) {
            if (serviceAppInstalledSuccessfully) {
                setServiceAppInstalled(true);
                loadConsentStatus();
                if (runCountdown) {
                    countdown();
                }
            }
            setServiceAppBeingInstalled(false);
        });
    };
    React.useEffect(function () {
        if (
        //isForced('pApp') === false &&
        consentStatusPrimaryApp !== undefined &&
            consentStatusPrimaryApp.OrchestryAppLastConsentedVersion !== null) {
            if (consentStatusPrimaryApp.OrchestryAppLastConsentedVersion === consentStatusPrimaryApp.OrchestryAppPermissionVersion) {
                if (consentStatusPrimaryApp.ServiceAppInstalledAndUptoDate === false && serviceAppBeingInstalled === false) {
                    installUpdateServiceApp();
                }
            }
        }
    }, [consentStatusPrimaryApp]);
    var urlPrefix = window.location.origin;
    var openPrimaryAppPopupWindow = function () {
        popupCenter(urlPrefix + "/Account/GrantPermissionsStart", "Orchestry Login", 600, 700);
        //setPopupWindow(window.open(urlPrefix + "/Account/SignIn/","", "width=500,height=700"));
    };
    var openServiceAppPopupWindow = function () {
        popupCenter(urlPrefix +
            "/Account/ServiceAppGrantPermissionsStart" +
            (props.serviceAppConfig ? "?serviceAppConfig=" + props.serviceAppConfig : "") +
            (props.licenseChange ? "&licenseChange=" + props.licenseChange : ""), "Orchestry Login", 600, 700);
        //setPopupWindow(window.open(urlPrefix + "/Account/SignIn/","", "width=500,height=700"));
    };
    var countdown = function () {
        var waitASecond = function (seconds) {
            setSecondsAfterNewAppAdded(seconds);
            setTimeout(function () {
                if (seconds > 0) {
                    setSecondsAfterNewAppAdded(seconds - 1);
                    waitASecond(seconds - 1);
                }
            }, 1000);
        };
        waitASecond(60);
    };
    // Listen for popup callback.
    React.useEffect(function () {
        //@ts-ignore
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        //@ts-ignore
        eventer(messageEvent, function (e) {
            if (e.data !== undefined && e.data.error !== undefined) {
                console.log("Auth Data", e.data);
                if (e.data.error) {
                    setShowIsNotAdmin(true);
                    setErrorResponse(e.data);
                }
                else {
                    if (e.data.type === "primary-app") {
                        setPimaryForcedAppDone(true);
                        setConsentStatusPrimaryApp(undefined);
                        dispatch(AuthStore.Login(__assign(__assign({}, e.data.authToken), { isAuthorized: true })));
                        if (props.onPrimaryComplete !== undefined) {
                            props.onPrimaryComplete();
                        }
                    }
                    if (e.data.type === "service-app") {
                        // setServiceAppIsConnecting(false);
                        setServiceForcedAppDone(true);
                        setConsentStatusServiceApp(undefined);
                    }
                    loadConsentStatus();
                    // if (e.data.type === "delegate") {
                    //    setLifecycleDelegateForcedAppDone(true);
                    //    setConsentStatusLifecycleDelegateApp(undefined);
                    // }
                }
            }
        }, false);
        // if(iwc.force === false){
        //       if(currentTenant.CanSkipConsent){
        //          setShowConsentSuccess(true);
        //          iwc.setStepProgress(props.step.key, true);
        //       }
        // }
    }, []);
    var shouldShowAppUptoDateMessage = function () {
        if (consentStatusPrimaryApp !== undefined && consentStatusPrimaryApp.OrchestryAppLastConsentedVersion !== null) {
            if (consentStatusPrimaryApp.OrchestryAppPermissionVersion === consentStatusPrimaryApp.OrchestryAppLastConsentedVersion) {
                return true;
            }
        }
        return false;
    };
    var shouldShowAppUpgradeMessage = function () {
        if (consentStatusPrimaryApp !== undefined && consentStatusPrimaryApp.OrchestryAppLastConsentedVersion !== null) {
            if (consentStatusPrimaryApp.OrchestryAppPermissionVersion > consentStatusPrimaryApp.OrchestryAppLastConsentedVersion) {
                return true;
            }
        }
        return false;
    };
    var shouldShowServiceAppUptoDateMessage = function () {
        if (consentStatusServiceApp !== undefined && consentStatusServiceApp.ServiceAppLastConsentedVersion !== null) {
            if (consentStatusServiceApp.ServiceAppPermissionVersion === consentStatusServiceApp.ServiceAppLastConsentedVersion) {
                return true;
            }
        }
        return false;
    };
    var shouldShowServiceAppUpgradeMessage = function () {
        if (consentStatusServiceApp !== undefined && consentStatusServiceApp.ServiceAppLastConsentedVersion !== null) {
            if (consentStatusServiceApp.ServiceAppPermissionVersion > consentStatusServiceApp.ServiceAppLastConsentedVersion) {
                return true;
            }
        }
        return false;
    };
    var addDotsToVersion = function (version) {
        if (version !== undefined) {
            var splitVersion = ("" + version).split("");
            return splitVersion.join(".");
        }
        return "";
    };
    return (React.createElement("div", { className: "app-consent" },
        React.createElement("div", { className: "app-consent-instructions" },
            React.createElement("div", { className: "teams-app-install-choice" },
                React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." }),
                consentStatusPrimaryApp !== undefined && consentStatusPrimaryApp.IsCurrentUserGlobalAdmin === false ? (React.createElement("div", { className: "pim-options" },
                    React.createElement(PortletAlert, { size: "small", icon: "info", type: "success", message: React.createElement("div", null,
                            "If you have elevated your access using PIM",
                            React.createElement("strong", { className: "click-here-pim", onClick: function () { return reloadConsentStatus(); } }, "\u00A0click here"),
                            " ",
                            "to refresh credentials.") }))) : null,
                showIsNotAdmin === false ? (React.createElement("div", { className: "permission-options" },
                    React.createElement("div", { className: "permissions-area" },
                        React.createElement("div", { className: "permissions-icon" },
                            React.createElement("i", { className: "la la-server" }),
                            React.createElement("div", { className: "orch-logo" },
                                React.createElement("img", { src: "/black-logo.svg" }))),
                        React.createElement("div", { className: "permissions-content" },
                            React.createElement("h4", null, "Orchestry App Permissions"),
                            React.createElement("h5", { className: "required" }, "Required"),
                            React.createElement("div", { className: "content-wrap" },
                                React.createElement("div", { className: "text-and-button" },
                                    React.createElement("div", { className: "text-description" }, "This application allows Orchestry to connect to your Organization and is required for Orchestry's core functionality."),
                                    consentStatusPrimaryApp === undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 300, bodyHeight: 34 })) : (React.createElement(React.Fragment, null,
                                        isForced("pApp") === false && consentStatusPrimaryApp.OrchestryAppLastConsentedVersion !== null && (React.createElement("div", { className: "install-item" + (shouldShowAppUptoDateMessage() ? "" : " needs-update") },
                                            React.createElement("div", { className: "status-icon" + (shouldShowAppUptoDateMessage() ? " yes" : " no") }, shouldShowAppUptoDateMessage() ? React.createElement("i", { className: "fa fa-check" }) : React.createElement("i", { className: "fa fa-times" })),
                                            React.createElement("div", { className: "right" },
                                                React.createElement("div", { className: "title" }, shouldShowAppUptoDateMessage()
                                                    ? "You've consented to the latest version"
                                                    : shouldShowAppUpgradeMessage()
                                                        ? "Your primary consent is out of date"
                                                        : "It doesn't look like you've consented to Orchestry's primary app permissions yet"),
                                                shouldShowAppUptoDateMessage() ? (React.createElement("div", { className: "description" },
                                                    "primary-app-consent-version ",
                                                    React.createElement("span", { className: "version-highlight" }, addDotsToVersion(consentStatusPrimaryApp.OrchestryAppPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                                    React.createElement("div", { className: "description" },
                                                        "primary-app-consent-version",
                                                        " ",
                                                        React.createElement("span", { className: "version-highlight red" }, consentStatusPrimaryApp.OrchestryAppLastConsentedVersion !== null
                                                            ? addDotsToVersion(consentStatusPrimaryApp.OrchestryAppLastConsentedVersion)
                                                            : "0.0.0.0.0"),
                                                        " ",
                                                        "\u00BB ",
                                                        React.createElement("span", { className: "version-highlight green" }, addDotsToVersion(consentStatusPrimaryApp.OrchestryAppPermissionVersion)))))))),
                                        isForced("pApp") || !shouldShowAppUptoDateMessage() ? (React.createElement(DisableArea, { isEnabled: consentStatusPrimaryApp.IsCurrentUserGlobalAdmin === null || consentStatusPrimaryApp.IsCurrentUserGlobalAdmin === true, useOverlay: true },
                                            React.createElement("div", { className: "ms-login" },
                                                React.createElement("div", { className: "custom-button", onClick: function () { return openPrimaryAppPopupWindow(); } }, "Review Permissions and Grant Consent")))) : null)))))),
                    React.createElement("div", { className: "service-app-area" },
                        serviceAppBeingInstalled === true && (React.createElement("div", { className: "loader-guy" },
                            React.createElement(AnimatedLoader, { size: "med" }))),
                        React.createElement(DisableArea, { isEnabled: serviceAppInstalledAndUpdated, useOverlay: true },
                            React.createElement("div", { className: "permissions-area" },
                                React.createElement("div", { className: "permissions-icon" },
                                    React.createElement("i", { className: "la la-server" }),
                                    React.createElement("div", { className: "lifecycle" },
                                        React.createElement("i", { className: "fas fa-sync" }))),
                                React.createElement("div", { className: "permissions-content" },
                                    React.createElement("h4", null,
                                        "Orchestry Service App Permissions",
                                        " ",
                                        React.createElement("div", { className: "service-pill" }, props.serviceAppConfig == ServiceAppPermissionConfiguration.OrchestryReadWriteAll ? "Read & Write" : "Read-Only")),
                                    React.createElement("h5", { className: "required" }, "Required"),
                                    React.createElement("div", { className: "content-wrap" },
                                        React.createElement("div", { className: "text-and-button" },
                                            React.createElement("div", { className: "text-description" }, "This application is essential for Orchestry's service to connect with your tenant."),
                                            consentStatusServiceApp !== undefined && consentStatusServiceApp.ServiceAppLastConsentedVersion === null ? (React.createElement(PortletAlert, { type: "warning", size: "small", icon: "info", message: "Please note that this app was just installed and sometimes Azure AD can take up to 60 seconds to properly make it available for consent. If you see a message saying the app cannot be found please retry the process until consent works." })) : null,
                                            consentStatusServiceApp === undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 34 })) : (React.createElement(React.Fragment, null,
                                                isForced("sApp") === false && consentStatusServiceApp.ServiceAppLastConsentedVersion !== null && (React.createElement("div", { className: "install-item" + (serviceAppIsConnecting === false ? " connecting" : shouldShowServiceAppUptoDateMessage() ? "" : " needs-update") },
                                                    React.createElement("div", { className: "status-icon" + (shouldShowServiceAppUptoDateMessage() ? " yes" : " no") }, serviceAppIsConnecting === false ? (React.createElement(AnimatedLoader, { size: "small" })) : shouldShowServiceAppUptoDateMessage() ? (React.createElement("i", { className: "fa fa-check" })) : (React.createElement("i", { className: "fa fa-times" }))),
                                                    React.createElement("div", { className: "right" },
                                                        React.createElement("div", { className: "title" }, serviceAppIsConnecting === false
                                                            ? "Please wait while we verify that we can connect to the graph api."
                                                            : shouldShowServiceAppUptoDateMessage()
                                                                ? "You've consented to the latest version"
                                                                : shouldShowServiceAppUpgradeMessage()
                                                                    ? "Your service app consent is out of date"
                                                                    : "It doesn't look like you've consented to Orchestry's service app permissions yet"),
                                                        serviceAppIsConnecting === false ? (React.createElement("div", { className: "description" }, "This could take a minute or two.")) : shouldShowServiceAppUptoDateMessage() ? (React.createElement("div", { className: "description" },
                                                            "service-app-consent-version",
                                                            " ",
                                                            React.createElement("span", { className: "version-highlight" }, addDotsToVersion(consentStatusServiceApp.ServiceAppPermissionVersion)))) : (React.createElement(React.Fragment, null,
                                                            React.createElement("div", { className: "description" },
                                                                "service-app-consent-version",
                                                                " ",
                                                                React.createElement("span", { className: "version-highlight red" }, consentStatusServiceApp.ServiceAppLastConsentedVersion !== null
                                                                    ? addDotsToVersion(consentStatusServiceApp.ServiceAppLastConsentedVersion)
                                                                    : "0.0.0.0.0"),
                                                                " ",
                                                                "\u00BB ",
                                                                React.createElement("span", { className: "version-highlight green" }, addDotsToVersion(consentStatusServiceApp.ServiceAppPermissionVersion)))))))),
                                                isForced("sApp") || !shouldShowServiceAppUptoDateMessage() ? (React.createElement(React.Fragment, null,
                                                    secondsAfterNewAppAdded > 0 ? (React.createElement("div", { className: "waiting-20-seconds" },
                                                        "Please wait ",
                                                        React.createElement("strong", null, secondsAfterNewAppAdded),
                                                        " seconds to proceed")) : null,
                                                    React.createElement(DisableArea, { isEnabled: (consentStatusServiceApp.IsCurrentUserGlobalAdmin === null || consentStatusServiceApp.IsCurrentUserGlobalAdmin === true) &&
                                                            secondsAfterNewAppAdded === 0, useOverlay: true },
                                                        React.createElement("div", { className: "ms-login" },
                                                            React.createElement("div", { className: "custom-button", onClick: function () { return openServiceAppPopupWindow(); } }, "Review Permissions and Grant Consent"))))) : null)))))))))) : (React.createElement("div", { className: "kt-wizard-v2__form" },
                    React.createElement(PortletAlert, { type: "danger", icon: "alert", size: "regular", iconAlign: "top", title: "Sorry! Something went wrong.", message: React.createElement("div", null,
                            React.createElement("p", null,
                                "There was an issue when granting consent. We recommending waiting a few minutes before trying again.",
                                React.createElement("br", null),
                                React.createElement("br", null),
                                React.createElement("strong", null, errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.error),
                                React.createElement("br", null), errorResponse === null || errorResponse === void 0 ? void 0 :
                                errorResponse.errorDescription,
                                React.createElement("br", null),
                                (errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.errorUri) && (React.createElement("a", { href: errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.errorUri, target: "_blank" }, "Click here for more information")))) })))))));
});
export default AppConsent;
