var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BulkEditActionTypes } from "./types";
import { BulkEditCartTypes } from "app/store/bulk-edit";
//import mergeDeep from 'app/utils/mergeDeep';
import { merge, cloneDeep } from "lodash";
var initialState = {
    WorkspaceReporting: {
        Items: [],
        EditModeOn: false,
        Type: BulkEditCartTypes.WorkspaceReporting
    }
};
// const compare = ( a:Directory, b:Directory ) => {
//     if(a.Title !== undefined && b.Title !== undefined){
//         if ( a.Title.toLowerCase() < b.Title.toLowerCase() ){
//         return -1;
//         }
//         if ( a.Title.toLowerCase() > b.Title.toLowerCase() ){
//         return 1;
//         }
//     }
//     return 0;
// }
export function bulkEditReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BulkEditActionTypes.BulkEdit_Load: {
            //let newState = cloneDeep(state);
            //newState = merge(newState, action.payload, { IsLoading: false, } as DirectorySearchState);
            //console.log("Load Middle Ware", window.innerWidth, action.payload)
            var updatedRequest = merge(action.payload, {});
            //updatedRequest.IsFavsLoading = state.IsFavsLoading;
            //updatedRequest.IsRefinersLoading = state.IsRefinersLoading;
            // I know this is horrible, but I hate the flash of this changing on mobile screens
            // if(window.innerWidth < 769) {
            //    updatedRequest.Context.View = "Cards";
            //    updatedRequest.Paging.ResultsPerPage = 12;
            // }
            // updatedRequest.IsFavsLoading = state.IsFavsLoading;
            // updatedRequest.IsRefinersLoading = state.IsRefinersLoading;
            // updatedRequest.Favs = state.Favs;
            // updatedRequest.Refiners = state.Refiners;
            return updatedRequest;
        }
        case BulkEditActionTypes.BulkEdit_UpdateCart: {
            //const updatedRequest = merge(state, action.payload);
            var newState = cloneDeep(state);
            newState[action.payload.Type] = action.payload;
            // const updatedRequest = merge(state, action.payload);
            // updatedRequest.Items = action.payload.Items;
            // updatedRequest.Paging = action.payload.Paging;
            //const updatedRequest = Object.assign({}, state, action.payload);
            //return {...updatedRequest};
            return __assign({}, newState);
        }
        // case DirectorySearchActionTypes.Directory_Load_Refiners:{
        //    //const updatedRequest:DirectorySearchState = merge(state, { Refiners : action.payload.Refiners });
        //    state.Refiners = action.payload.Refiners;
        //    state.IsRefinersLoading = false;
        //    return state
        // }
        // case DirectorySearchActionTypes.Directory_Load_Favs:{
        //    //const updatedRequest:DirectorySearchState = merge(state, { Refiners : action.payload.Refiners });
        //    state.Favs = action.payload.Favs;
        //    state.IsFavsLoading = false;
        //    return state
        // }
        // case DirectorySearchActionTypes.Directory_Load_RefinersAndFavs:{
        //    //const updatedRequest:DirectorySearchState = merge(state, { Refiners : action.payload.Refiners });
        //    //let newState = cloneDeep(state);
        //    state.Favs = action.payload.Favs;
        //    state.Refiners = action.payload.Refiners;
        //    state.IsFavsLoading = false;
        //    state.IsRefinersLoading = false;
        //    return cloneDeep(state);
        // }
        // case DirectorySearchActionTypes.Directory_Search:{
        //    return {...state}
        // }
        // case DirectorySearchActionTypes.Directory_Update_Paging:{
        //    const updatedRequest:DirectorySearchState = merge(state, action.payload, { IsLoading: false});
        //    //updatedRequest.Paging.PrevPageUrls = action.payload.Paging.PrevPageUrls;
        //    updatedRequest.Items = action.payload.Items;
        //    return {...updatedRequest};
        // }
        // case DirectorySearchActionTypes.Directory_Update_Refiners:{
        //    //const updatedRequest = merge(state, action.payload);
        //    const updatedRequest = merge(state, action.payload, { IsLoading: false});
        //    updatedRequest.Items = action.payload.Items;
        //    updatedRequest.Paging = action.payload.Paging;
        //    //const updatedRequest = Object.assign({}, state, action.payload);
        //    //return {...updatedRequest};
        //    return {...updatedRequest};
        // }
        // case DirectorySearchActionTypes.Directory_Refresh:{
        //    //const updatedRequest = merge(state, action.payload);
        //    //const updatedRequest = merge(state, action.payload, { IsLoading: false});
        //    //const updatedRequest = cloneDeep(state);
        //    const updatedRequest = merge(state, { IsLoading: false});
        //    updatedRequest.Items = action.payload.Items;
        //    //const updatedRequest = Object.assign({}, state, action.payload);
        //    //return {...updatedRequest};
        //    return {...updatedRequest};
        // }
        // case DirectorySearchActionTypes.Directory_Update_Context:{
        //    const updatedRequest = merge(state, action.payload, { IsLoading: false});
        //    updatedRequest.Items = action.payload.Items;
        //    return {...updatedRequest};
        // }
        // case DirectorySearchActionTypes.Directory_Update_View:{
        //    const updatedRequest = merge(state, action.payload, { IsLoading: false});
        //    updatedRequest.Items = action.payload.Items;
        //    return {...updatedRequest};
        // }
        // case DirectorySearchActionTypes.Directory_Update_Favs:{
        //    //console.log("Favs", action.payload.Favs);
        //    const updatedRequest = state;
        //    updatedRequest.Favs = action.payload.Favs;
        //    return updatedRequest;
        // }
        // case DirectorySearchActionTypes.Directory_Update_IsLoading:{
        //    state.IsLoading = action.payload;
        //    return state;
        // }
        // case DirectorySearchActionTypes.Directory_Clear:{
        //    return { ...initialState }
        // }
        default:
            return state;
    }
}
