import * as React from "react";
import "./card-private-cosmos-db.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import CosmosDbSetup from "app/components/common/cosmosdb-setup";
var CardPrivateCosmosDb = React.memo(function (_a) {
    var _b = _a.ShowPortlet, ShowPortlet = _b === void 0 ? true : _b;
    return (React.createElement("div", { className: "card-private-cosmos-db" }, ShowPortlet ? (React.createElement(Portlet, null,
        React.createElement(PortletHeader, { title: "Cosmos DB Setup" }),
        React.createElement(PortletBody, null,
            React.createElement(CosmosDbSetup, { LoadTenantConfigStore: true })))) : (React.createElement(CosmosDbSetup, null))));
});
export default CardPrivateCosmosDb;
