var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./document-template-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as DocumentTemplateListStore from "app/store/document-templates/list";
import * as DocumentTemplateCurrentStore from "app/store/document-templates/current";
import generateGuid from "app/utils/generateGuid";
import { Form, Row, Col, Container, Modal } from "react-bootstrap";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import AssociateTemplate from "app/components/common/associate-template";
import FormFileUploadBox from "app/components/common/form-fileuploadbox";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import SettingsDocumentTemplateCategories from "app/components/settings/settings-document-template-categories";
import DocumentTemplateService from "app/services/document-template";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import Store from "app/store";
import DocumentTemplatePreview from "app/components/common/document-template-preview";
import { AsyncActionCreators } from "app/store/async/actions";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import AnimatedLoader from "app/components/common/animated-loader";
import SaveProgression from "app/components/common/save-progression";
var DocumentTemplateDetails = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(props.guid), lookupGuid = _a[0], setLookupGuid = _a[1];
    var currentDocumentTemplate = useSelector(function (state) { return state.documentTemplateCurrentState.item; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var _c = React.useState(), documentTemplateCategories = _c[0], setdocumentTemplateCategories = _c[1];
    var _d = React.useState(false), showModal = _d[0], setShowModal = _d[1];
    var _e = React.useState(), fileUploadPercentage = _e[0], setFileUploadPercentage = _e[1];
    var _f = React.useState(false), formValid = _f[0], setFormValid = _f[1];
    var _g = React.useState([
        {
            Title: "Save Document Template Information",
            Type: "item",
            Method: function () {
                return saveItemAsync();
            }
        }
    ]), saveJobs = _g[0], setSaveJobs = _g[1];
    var _h = React.useState(""), uploadFileName = _h[0], setUploadFileName = _h[1];
    React.useEffect(function () {
        if (currentTenantConfiguration.GUID !== undefined) {
            if (currentTenantConfiguration.GUID !== "") {
                if (currentTenantConfiguration.DocumentTemplateCategories !== undefined &&
                    currentTenantConfiguration.DocumentTemplateCategories !== null &&
                    currentTenantConfiguration.DocumentTemplateCategories !== "") {
                    var availableDocumentTemplateCats = JSON.parse(currentTenantConfiguration.DocumentTemplateCategories);
                    if (availableDocumentTemplateCats !== undefined && availableDocumentTemplateCats.length !== 0) {
                        var newOptionArray_1 = [];
                        availableDocumentTemplateCats.forEach(function (docTemplateCat, index) {
                            newOptionArray_1.push({
                                id: index + "",
                                title: docTemplateCat,
                                value: docTemplateCat
                            });
                        });
                        setdocumentTemplateCategories(newOptionArray_1);
                    }
                }
            }
        }
    }, [currentTenantConfiguration]);
    // On first load, Load the data from the server.
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
        //setSaveJobs([] as IJob[]);
    }, []);
    //console.log("DocumentTemplate - Details - currentDocumentTemplate" , currentDocumentTemplate);
    React.useEffect(function () {
        if (props.defaultAssociatedTemplate !== undefined) {
            if (currentDocumentTemplate !== undefined) {
                if (currentDocumentTemplate.GUID !== undefined) {
                    if (currentDocumentTemplate.GUID !== "") {
                        if (currentDocumentTemplate.AssociatedTemplates !== undefined) {
                            if (!currentDocumentTemplate.AssociatedTemplates.some(function (as) { var _a, _b; return ((_a = as.Item) === null || _a === void 0 ? void 0 : _a.GUID) === ((_b = props.defaultAssociatedTemplate) === null || _b === void 0 ? void 0 : _b.GUID); })) {
                                onAssociatedAdd({
                                    GUID: generateGuid(),
                                    Required: false,
                                    Item: props.defaultAssociatedTemplate
                                });
                            }
                        }
                    }
                }
            }
        }
    }, [currentDocumentTemplate]);
    // Should only be run once.
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (currentDocumentTemplate.GUID !== undefined) {
            dispatch(DocumentTemplateCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current template when navigating to the new template page.
            var guid = generateGuid();
            // Add a new template with guid.
            dispatch(DocumentTemplateCurrentStore.Update({ GUID: guid, AssociatedTemplates: [] }));
        }
        else {
            // Load the /edit/guid load.
            dispatch(DocumentTemplateCurrentStore.Load({ GUID: lookupGuid }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid]);
    var clearCurrentDocumentTemplate = function () {
        dispatch(DocumentTemplateCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(DocumentTemplateCurrentStore.Clear());
        };
    }, []);
    var redirect = function () {
        setBackToList(true);
        dispatch(DocumentTemplateCurrentStore.Clear());
    };
    var saveAndAddModifyDocumentTemplate = function () {
        var state = Store.getState();
        var currentDocTemp = state.documentTemplateCurrentState.item;
        if (lookupGuid === undefined) {
            // First, add the new template to template list
            dispatch(DocumentTemplateListStore.AddItem(currentDocTemp));
        }
        else {
            dispatch(DocumentTemplateListStore.UpdateItem(currentDocTemp));
        }
    };
    var saveItemAsync = function () {
        return new Promise(function (resolve, reject) {
            var state = Store.getState();
            var currentDocTemp = state.documentTemplateCurrentState.item;
            saveAndAddModifyDocumentTemplate();
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                asyncCallType = AsyncCallType.Add;
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentDocTemp.GUID).then(function () {
                resolve();
            });
            ///reject();
        });
    };
    var onAssociatedAdd = function (templateToAdd) {
        //console.log("ADD Ass Details > ", templateToAdd)
        var tempArray = [templateToAdd];
        //setAssociatedTemplates([...tempArray, ...currentDocumentTemplate.AssociatedTemplates]);
        if (currentDocumentTemplate.AssociatedTemplates !== undefined) {
            if (currentDocumentTemplate.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), currentDocumentTemplate.AssociatedTemplates, true);
            }
        }
        dispatch(DocumentTemplateCurrentStore.Update({ AssociatedTemplates: tempArray }));
    };
    var onAssociatedRemove = function (tbaToRemove) {
        var _a;
        dispatch(DocumentTemplateCurrentStore.Update({
            AssociatedTemplates: (_a = currentDocumentTemplate.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var toggleModal = function () {
        setShowModal(!showModal);
    };
    var onUploadProgress = function (progress) {
        setFileUploadPercentage((progress.loaded / progress.total) * 100 + "%");
        console.log("Progress", progress);
    };
    var uploadFileAsync = function (file, fileName) {
        return new Promise(function (resolve, reject) {
            var continueUpload = function () {
                var state = Store.getState();
                var currentDocTemp = state.documentTemplateCurrentState.item;
                if (file !== undefined && currentDocTemp.GUID !== undefined) {
                    var formData = new FormData();
                    formData.append("file", file);
                    formData.append("document-template-guid", currentDocTemp.GUID);
                    DocumentTemplateService.uploadDocument(formData, onUploadProgress).then(function () {
                        console.log("upload done");
                        setFileUploadPercentage(undefined);
                        var tempState = Store.getState();
                        var findAI = tempState.asyncState.items.find(function (i) { return i.key === currentDocumentTemplate.GUID; });
                        if (findAI !== undefined) {
                            console.log("Found Async Action", findAI);
                            dispatch(AsyncActionCreators.Invalid(findAI));
                        }
                        resolve();
                    });
                }
            };
            continueUpload();
        });
    };
    var addFile = function (file, fileName) {
        var tempArray = __spreadArray(__spreadArray([], saveJobs.filter(function (file) { return file.Type !== "file"; }), true), [
            {
                Title: "Upload " + fileName,
                Type: "file",
                Method: function () {
                    return uploadFileAsync(file, fileName);
                }
            }
        ], false);
        //@ts-ignore
        setSaveJobs(tempArray);
        setUploadFileName(fileName);
        var fileExtension = fileName !== undefined ? fileName.split(".").pop() : "";
        var state = Store.getState();
        var currentDocTemp = state.documentTemplateCurrentState.item;
        if (currentDocTemp.Title === undefined || (currentDocTemp.Title !== undefined && currentDocTemp.Title === null) || (currentDocTemp.Title !== undefined && currentDocTemp.Title === "")) {
            //@ts-ignore
            dispatch(DocumentTemplateCurrentStore.Update({ Title: fileName.replace("." + fileExtension, ""), Type: fileExtension }));
        }
        else {
            dispatch(DocumentTemplateCurrentStore.Update({ Type: fileExtension }));
        }
    };
    var onSharePointFileRemove = function () {
        if (currentDocumentTemplate !== undefined &&
            currentDocumentTemplate.SharePointListItemId !== undefined &&
            currentDocumentTemplate.SharePointListItemId !== null &&
            currentDocumentTemplate.SharePointListItemId !== "") {
            dispatch(DocumentTemplateCurrentStore.Update({ SharePointListItemId: undefined }));
        }
    };
    return (React.createElement("div", { className: "documentTemplate-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "DocumentTemplateValidation" },
            backToList && React.createElement(Redirect, { to: "/workspaces/document-templates" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Document Templates", subTitle: props.guid ? "Edit" : "New", titleLink: "/workspaces/document-templates" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/document-templates", onClick: function () { return clearCurrentDocumentTemplate(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement(SaveProgression, { OnComplete: function () { return redirect(); }, Jobs: saveJobs },
                    React.createElement("div", { style: { display: "inline-block" } },
                        React.createElement(SubtitleButton, { title: "Save", variant: "success" })))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            props.showButtonsAtBottom !== undefined && props.showButtonsAtBottom === true ? (React.createElement("div", { className: "float-actions" },
                props.onCancel !== undefined ? (React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary", onClick: function () {
                        if (props.onCancel !== undefined) {
                            props.onCancel();
                        }
                    } })) : null,
                formValid === true ? (React.createElement(SaveProgression, { OnComplete: function () {
                        if (props.onSave !== undefined) {
                            props.onSave();
                        }
                    }, Jobs: saveJobs },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null,
            currentDocumentTemplate.GUID !== undefined ? (React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                    React.createElement(Form.Label, null,
                                        "Name ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(Form.Control, { placeholder: "Enter Name", defaultValue: currentDocumentTemplate.Title, onChange: function (event) { return dispatch(DocumentTemplateCurrentStore.Update({ Title: event.target.value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "doc-template-name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-2"], Value: currentDocumentTemplate.Title })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                                    React.createElement(Form.Label, null,
                                        "Description ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(Form.Control, { as: "textarea", 
                                        // placeholder="Tell people the purpose of this documentTemplate"
                                        rows: 3, defaultValue: currentDocumentTemplate.Description, onChange: function (event) { return dispatch(DocumentTemplateCurrentStore.Update({ Description: event.target.value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "doc-template-description", Validators: ["string-not-empty", "string-is-more-than-3"], Value: currentDocumentTemplate.Description })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                                    React.createElement(Form.Label, null,
                                        "Category ",
                                        React.createElement("span", { className: "required-astrix" }, "*"),
                                        " ",
                                        React.createElement("div", { className: "add-document-category", onClick: function () {
                                                toggleModal();
                                            } },
                                            React.createElement("i", { className: "fa fa-plus-circle" }))),
                                    documentTemplateCategories !== undefined ? (React.createElement(FormDropdownSelect, { list: documentTemplateCategories, defaultValue: currentDocumentTemplate.Category, onChange: function (value) { return dispatch(DocumentTemplateCurrentStore.Update({ Category: value })); } })) : null,
                                    React.createElement(FormValidator, { UniqueIdentifier: "doc-template-categories", Validators: ["array-has-items"], ErrorMessage: "Please select a category", Value: currentDocumentTemplate.Category }),
                                    React.createElement(Modal, { centered: true, show: showModal, onHide: function () { return toggleModal(); }, animation: true, className: "document-category-modal" },
                                        React.createElement("div", { className: "close", onClick: function () { return toggleModal(); } },
                                            React.createElement("i", { className: "fa fa-times" })),
                                        React.createElement(SettingsDocumentTemplateCategories, { isModal: true })))),
                            React.createElement(Col, { md: 5, lg: 4, xl: 3, className: "right-col" },
                                React.createElement(React.Fragment, null, currentDocumentTemplate.SharePointListItemId !== undefined &&
                                    currentDocumentTemplate.SharePointListItemId !== null &&
                                    currentDocumentTemplate.SharePointListItemId !== "" ? (React.createElement("div", { className: "document-preview" },
                                    React.createElement(DocumentTemplatePreview, { ListId: currentDocumentTemplate.SharePointListItemId, OnRemove: function () {
                                            onSharePointFileRemove();
                                        }, showFileName: true }))) : (React.createElement(React.Fragment, null, uploadFileName !== "" ? (React.createElement("div", { className: "document-preview" },
                                    React.createElement(DocumentTemplatePreview, { fileName: uploadFileName, OnRemove: function () {
                                            setUploadFileName("");
                                        }, noPreviewText: "File preview will be available after saving", showFileName: true }))) : (React.createElement(FormFileUploadBox, { defaultBase64File: currentDocumentTemplate.File, uploadText: "Upload or drop a document here", enforceSafeFileName: true, onDrop: function (file, fileName) { return addFile(file, fileName); } }))))),
                                fileUploadPercentage !== undefined ? (React.createElement("div", { className: "progress" },
                                    React.createElement("div", { className: "progress-bar progress-bar-striped bg-success", style: { width: fileUploadPercentage } }))) : null,
                                React.createElement(FormValidator, { UniqueIdentifier: "doc-template-uploaded", Validators: ["string-not-empty"], ShowAsInfo: true, ShowFirst: true, ErrorMessage: "Please attach a document template", Value: currentDocumentTemplate.SharePointListItemId !== undefined && currentDocumentTemplate.SharePointListItemId !== null
                                        ? currentDocumentTemplate.SharePointListItemId
                                        : uploadFileName })))),
                    React.createElement("div", { className: "divider-line", style: { marginTop: "80px" } }),
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                React.createElement("h3", null, "Associated templates"),
                                React.createElement("div", null, currentDocumentTemplate.GUID !== undefined && (React.createElement(AssociateTemplate, { associatedTemplates: currentDocumentTemplate.AssociatedTemplates ? currentDocumentTemplate.AssociatedTemplates : [], onAssociatedAdd: function (ass) {
                                        onAssociatedAdd(ass);
                                    }, onAssociatedRemove: function (ass) {
                                        onAssociatedRemove(ass);
                                    } },
                                    React.createElement("div", { className: "at-search" },
                                        React.createElement(AssociateTemplate.Search, null)),
                                    React.createElement("div", { className: "at-count" },
                                        React.createElement(AssociateTemplate.Count, null)),
                                    React.createElement("div", { className: "at-items" },
                                        React.createElement(AssociateTemplate.SelectedItems, null))))))))))) : (React.createElement("div", { className: "loader" },
                React.createElement(AnimatedLoader, null))))));
});
export default DocumentTemplateDetails;
