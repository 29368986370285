import { Text } from "@mantine/core";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import React from "react";
import { RecommendationResolutionSkeletons } from "./recommendation-resolution-skeletons/recommendation-resolution-skeletons.component";
import { RecommendationResolution } from "./recommendation-resolution.component";
import { RecommendationsPageNoResolutions } from "./recommendation-resolutions-no-results.component";
import "./recommendation-resolutions.scss";
import { useRecommendationResolutions } from "./use-recommendation-resolutions.hook";
export var RecommendationResolutions = function (_a) {
    var _b, _c;
    var tid = _a.tid;
    var _d = useRecommendationResolutions(1, tid), data = _d.data, setResultsPerPage = _d.setResultsPerPage, resultsPerPage = _d.resultsPerPage, setActivePage = _d.setActivePage, activePage = _d.activePage, isFetching = _d.isFetching;
    if (((_b = data === null || data === void 0 ? void 0 : data.Items) === null || _b === void 0 ? void 0 : _b.length) === 0)
        return React.createElement(RecommendationsPageNoResolutions, null);
    return (React.createElement("div", { className: "recommendation-resolutions" },
        React.createElement(Text, { c: "text-tertiary.0", size: "md", fw: 500, className: "resolution-title" }, "Resolutions"),
        isFetching ? (React.createElement(RecommendationResolutionSkeletons, null)) : (React.createElement("div", { className: "items" }, (_c = data === null || data === void 0 ? void 0 : data.Items) === null || _c === void 0 ? void 0 : _c.map(function (item) { return (React.createElement(RecommendationResolution, { item: item })); }))),
        React.createElement(OrchestryPagination, { onResultsPerPageChange: function (value) {
                setResultsPerPage(value);
                localStorage.setItem("recommendations-resultsPerPage", value);
            }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: (data === null || data === void 0 ? void 0 : data.TotalCount) || 0, pageTotal: (data === null || data === void 0 ? void 0 : data.PageTotal) || 0 })));
};
