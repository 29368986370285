import AnimatedLoader from "app/components/common/animated-loader";
import React from "react";
import { Modal } from "react-bootstrap";
import "./team-information-custom-metadata.scss";
// import { Icon } from '@fluentui/react/lib/Icon';
// import { initializeIcons } from '@uifabric/icons';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import ManagedMetadataValue from "app/components/common/mananged-metadata-value";
import MetadataField from "app/components/common/metadata-field";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import DirectoryService from "app/services/directory";
import * as TeamInformationCurrentStore from "app/store/team-information/current";
import * as TenantConfigurationStore from "app/store/tenant-configuration/current";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
var TeamInformationCustomMetadata = React.memo(function (props) {
    var _a, _b;
    var dispatch = useDispatch();
    var intl = useIntl();
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var directoryMetadata = useSelector(function (state) { return state.teamInformationState.teamDirectoryMetadata; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var teamInformationWebPartState = useSelector(function (state) { return state.teamInformationWebPartState; });
    var _c = React.useState(false), showMetadataModal = _c[0], setshowMetadataModal = _c[1];
    var _d = React.useState(undefined), customMetadataValuesJson = _d[0], setCustomMetadataValuesJson = _d[1];
    var _e = React.useState(true), editShowInDirectory = _e[0], setEditShowInDirectory = _e[1];
    var _f = React.useState(false), updateItemLoading = _f[0], setUpdateItemLoading = _f[1];
    var _g = React.useState(true), isEditValid = _g[0], setIsEditValid = _g[1];
    if (currentTenantConfiguration === null || currentTenantConfiguration === undefined || Object.keys(currentTenantConfiguration).length === 0) {
        dispatch(TenantConfigurationStore.Load());
    }
    React.useEffect(function () {
        if (directoryMetadata !== null) {
            setEditShowInDirectory(directoryMetadata.directoryItem.IsVisibleInDirectory);
        }
    }, [directoryMetadata]);
    React.useEffect(function () {
        var _a;
        if (teamContext !== null && teamInformation !== null) {
            dispatch(TeamInformationCurrentStore.LoadDirectoryMetadata((_a = teamContext.team) === null || _a === void 0 ? void 0 : _a.groupId));
        }
    }, [teamContext, teamInformation]);
    var updateCustomMetadataValue = function (mdfv) {
        var currentValues = [mdfv];
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            var foundCurrent_1 = false;
            currentValues = valuesArray.map(function (fv) {
                if (fv.FieldGUID === mdfv.FieldGUID) {
                    foundCurrent_1 = true;
                    return mdfv;
                }
                else {
                    return fv;
                }
            });
            if (foundCurrent_1 === false) {
                currentValues.push(mdfv);
            }
            //console.log("does match for mapping", currentValues)
        }
        setCustomMetadataValuesJson(JSON.stringify(currentValues));
    };
    var getCurrentMetadataFieldValue = function (md) {
        var value = undefined;
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            valuesArray.forEach(function (mdfv, index) {
                if (mdfv.FieldGUID === md.GUID) {
                    value = mdfv.Values;
                }
                else if (mdfv.FieldTitle === md.Title) {
                    value = mdfv.Values;
                }
            });
        }
        if (value === undefined && directoryMetadata !== null) {
            directoryMetadata.directoryItem.Metadata.forEach(function (metadata, mdIndex) {
                if (metadata.SpColumnsName === md.SPFieldInternalName) {
                    if (metadata.Values !== undefined && metadata.Values !== null && metadata.Values !== "") {
                        if (md.FieldType === "Choice" && md.SingleMultiple === "Multiple") {
                            value = metadata.Values.replace(/\|/g, "\r\n");
                        }
                        else if (md.FieldType === "Date") {
                            value = moment(metadata.Values).format("YYYY-MM-DD");
                            console.log("multiple choice date", value);
                        }
                        else if (md.FieldType === "ManagedMetadata") {
                            var tempTermsArray_1 = [];
                            if (metadata.Values.length > 0 && metadata.Values.indexOf("{") > -1) {
                                var parsedValues = JSON.parse(metadata.Values);
                                console.log("getCurrentMetadataFieldValue() - parsedValues", parsedValues);
                                if (Array.isArray(parsedValues)) {
                                    parsedValues.forEach(function (t) {
                                        tempTermsArray_1.push({
                                            NodeType: "Term",
                                            Id: t.TermGuid !== undefined ? t.TermGuid : t.TermID
                                        });
                                    });
                                }
                                else {
                                    tempTermsArray_1.push({
                                        NodeType: "Term",
                                        Id: parsedValues.TermGuid !== undefined ? parsedValues.TermGuid : parsedValues.TermID
                                    });
                                }
                            }
                            value = JSON.stringify(tempTermsArray_1);
                            console.log("getCurrentMetadataFieldValue() - after - ManagedMetadata", value);
                        }
                        else {
                            value = metadata.Values;
                        }
                    }
                }
            });
        }
        // console.log("getCurrentMetadataFieldValue",md.Title, value);
        return value;
    };
    var UpdateToDirectory = function () {
        setUpdateItemLoading(true);
        if (directoryMetadata !== null && teamContext !== null && teamInformation !== null) {
            DirectoryService.updateSiteMetadataWorkspace(directoryMetadata.directoryItem.Name, directoryMetadata.directoryItem.SiteCollectionUrl, teamInformation.SiteId, editShowInDirectory === true ? "true" : "false", customMetadataValuesJson !== undefined ? customMetadataValuesJson : "", directoryMetadata.directoryItem.TemplateType === "Team" ? "Team" : "SharePoint").then(function (data) {
                var _a;
                setUpdateItemLoading(false);
                setshowMetadataModal(false);
                dispatch(TeamInformationCurrentStore.ClearDirectory());
                dispatch(TeamInformationCurrentStore.LoadDirectoryMetadata((_a = teamContext.team) === null || _a === void 0 ? void 0 : _a.groupId));
            });
        }
    };
    /**
     * Returns true if the metadata link should be rendered for the given field name.
     * Renders the links only for the first field that has links because we ground the links under one field.
     *
     * @param fieldName The name of the field
     */
    var shouldRenderMetadataLink = function (fieldName) {
        var _a;
        if (!((_a = directoryMetadata === null || directoryMetadata === void 0 ? void 0 : directoryMetadata.directoryItem) === null || _a === void 0 ? void 0 : _a.Metadata)) {
            return false;
        }
        var links = directoryMetadata.directoryItem.Metadata.filter(function (md) { return md.Type === "Link" && !!md.Values; });
        if (!links || !(links.length > 0)) {
            return false;
        }
        /**
         * If the field name is not the same as the first link's field name, then we don't render the links
         * because we don't want to render the links for every field, only for the first one.
         */
        if (fieldName !== links[0].SpColumnsName) {
            return false;
        }
        return true;
    };
    var shouldRenderMetadataItem = function (fieldName) {
        if (directoryMetadata !== null && directoryMetadata !== undefined) {
            var foundOrchMetadata = directoryMetadata.orchestryMetadata.find(function (md) { return md.SPFieldInternalName === fieldName; });
            if (foundOrchMetadata !== null && foundOrchMetadata !== undefined) {
                if (foundOrchMetadata.FieldType === "Link") {
                    return shouldRenderMetadataLink(fieldName);
                }
                if (foundOrchMetadata.ShowInTITab === null)
                    return true;
                return foundOrchMetadata.ShowInTITab;
            }
        }
        return true;
    };
    var getMetadataTitle = function (fieldName) {
        var re = React.createElement(React.Fragment, null);
        if (directoryMetadata !== null && directoryMetadata !== undefined) {
            var foundOrchMetadata = directoryMetadata.orchestryMetadata.find(function (md) { return md.SPFieldInternalName === fieldName; });
            if (foundOrchMetadata !== null && foundOrchMetadata !== undefined) {
                return React.createElement(React.Fragment, null, foundOrchMetadata.Title);
            }
            else {
            }
        }
        else {
        }
        // console.log("getMetadataValue", re, item, fieldName, renderType);
        return re;
    };
    /**
     * Renders all the external links in a group for the first field that has external links
     * @param fieldName  The name of the field
     * @param values The values of the field
     */
    var renderExternalLinks = function () {
        var _a;
        if (!((_a = directoryMetadata === null || directoryMetadata === void 0 ? void 0 : directoryMetadata.directoryItem) === null || _a === void 0 ? void 0 : _a.Metadata)) {
            return React.createElement(React.Fragment, null);
        }
        var links = directoryMetadata.directoryItem.Metadata.filter(function (md) { return md.Type === "Link" && !!md.Values; });
        return (React.createElement("div", { className: "links-group" }, links.map(function (link, index) {
            return link.Values && (React.createElement("div", { className: "link" },
                React.createElement(FontAwesomeIcon, { icon: Icons.faLink }),
                React.createElement("a", { key: index, href: link.Values, target: "_blank", rel: "noreferrer" }, link.DisplayName)));
        })));
    };
    var getMetadataValue = function (fieldName) {
        var re = React.createElement(React.Fragment, null);
        if (directoryMetadata !== null) {
            var foundDirMetadata = directoryMetadata.directoryItem.Metadata.find(function (md) { return md.SpColumnsName === fieldName; });
            var foundOrchMetadata = directoryMetadata.orchestryMetadata.find(function (md) { return md.SPFieldInternalName === fieldName; });
            if (foundDirMetadata !== null && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== null && foundOrchMetadata !== null) {
                if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "Choice" && foundOrchMetadata.SingleMultiple === "Single") {
                    re = React.createElement(React.Fragment, null, foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values);
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "Choice" && foundOrchMetadata.SingleMultiple === "Multiple") {
                    var values = foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values.split("|");
                    re = (React.createElement(React.Fragment, null, values === null || values === void 0 ? void 0 : values.map(function (v, index) { return (React.createElement("div", { key: index }, v)); })));
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "Date") {
                    if ((foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== undefined && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== null && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== "") {
                        re = React.createElement(React.Fragment, null, moment((foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== undefined ? foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values.replace(/"/g, "") : foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values).format("MMM Do YYYY"));
                    }
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "Link") {
                    re = renderExternalLinks();
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "Text" && foundOrchMetadata.SingleMultiple === "Single") {
                    re = React.createElement(React.Fragment, null, foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values);
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "Text" && foundOrchMetadata.SingleMultiple === "Multiple") {
                    re = React.createElement(React.Fragment, null, foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values);
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "ManagedMetadata" && foundOrchMetadata.SingleMultiple === "Single") {
                    if ((foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== undefined && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== null && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values.indexOf("{")) > -1) {
                        var tagObject = JSON.parse(foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values);
                        if (tagObject !== null && (tagObject.TermID !== undefined || tagObject.TermGuid !== undefined)) {
                            re = (React.createElement(ManagedMetadataValue, { node: {
                                    NodeType: "Term",
                                    Id: tagObject.TermID !== undefined ? tagObject.TermID : tagObject.TermGuid
                                } }));
                        }
                    }
                }
                else if ((foundOrchMetadata === null || foundOrchMetadata === void 0 ? void 0 : foundOrchMetadata.FieldType) === "ManagedMetadata" && foundOrchMetadata.SingleMultiple === "Multiple") {
                    if ((foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== undefined && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values) !== null && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values.length) > 0 && (foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values.indexOf("[")) > -1) {
                        var tagObjects = JSON.parse(foundDirMetadata === null || foundDirMetadata === void 0 ? void 0 : foundDirMetadata.Values);
                        if (Array.isArray(tagObjects) && tagObjects.length > 0) {
                            re = (React.createElement("ul", { className: "managed-metadata-list" }, tagObjects.map(function (to) { return (React.createElement("li", null,
                                React.createElement("div", { className: "managed-metadata-item", "data-term-guid": to.TermID },
                                    React.createElement("i", { className: "la la-tag" }),
                                    React.createElement("span", null, to.Label)))); })));
                        }
                    }
                }
            }
        }
        // console.log("getMetadataValue", re, item, fieldName, renderType);
        return re;
    };
    if (((_a = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _a === void 0 ? void 0 : _a.showMetadata) === false) {
        return null;
    }
    return (React.createElement("div", { className: "team-information-custom-metadata" },
        React.createElement("div", { className: "metadata-content" },
            React.createElement("div", { className: "metadata-title" },
                intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.metadata", defaultMessage: "Metadata" }),
                teamInformation !== null && teamInformation.IsCurrentUserTeamOwner === true && (currentTenantConfiguration === null || currentTenantConfiguration === void 0 ? void 0 : currentTenantConfiguration.AllowEditMetadata) === true ? (React.createElement("div", { className: "metadata-item-edit" },
                    React.createElement("i", { className: "far fa-edit", onClick: function () { return setshowMetadataModal(true); } }))) : null),
            React.createElement("div", { className: "metadata-container" }, directoryMetadata !== null && directoryMetadata.directoryItem.Metadata !== null
                ? // ? directoryMetadata.directoryItem.Metadata?.slice(0, 3).map((metadataItem, index) => {
                    (_b = directoryMetadata.directoryItem.Metadata) === null || _b === void 0 ? void 0 : _b.map(function (metadataItem, index) {
                        return shouldRenderMetadataItem(metadataItem.SpColumnsName) ? (React.createElement("div", { className: "metadata-item" },
                            React.createElement("div", { className: "metadata-item-title" }, (metadataItem === null || metadataItem === void 0 ? void 0 : metadataItem.Type) === "Link" ? (React.createElement("span", null, intl.formatMessage({ id: "workspaces.metadata.external_links", defaultMessage: "External Links" }))) : (React.createElement("span", null, getMetadataTitle(metadataItem.SpColumnsName)))),
                            React.createElement("div", { className: "metadata-item-value" }, getMetadataValue(metadataItem.SpColumnsName)))) : null;
                    })
                : null),
            directoryMetadata !== null && directoryMetadata.directoryItem.Metadata !== null ? null : (React.createElement("div", { className: "metadata-loader" },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginTop: 0 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginTop: 0 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, marginTop: 0 })))),
        showMetadataModal === true ? (React.createElement(Modal, { centered: true, show: showMetadataModal, onHide: function () { return setshowMetadataModal(!showMetadataModal); }, animation: true, className: "team-information-custom-metadata-edit-modal", 
            // size="sm"
            backdropClassName: "team-information-custom-metadata-edit-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null,
                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.title.update_metadata", defaultMessage: "Update Metadata" }))),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "content" },
                    React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                            setIsEditValid(isValid);
                        }, UniqueIdentifier: "UpdateDirectoryItemMetadata" },
                        React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 500 }, directoryMetadata === null || directoryMetadata === void 0 ? void 0 : directoryMetadata.orchestryMetadata.map(function (mdi, index) { return (React.createElement("div", { key: index, className: "metadata-item" },
                            React.createElement(MetadataField, { key: index, metaDataItem: mdi, onChange: function (mdfv) {
                                    updateCustomMetadataValue(mdfv);
                                }, defaultValue: getCurrentMetadataFieldValue(mdi) },
                                mdi.FieldType === "Link" && getCurrentMetadataFieldValue(mdi) !== undefined ? (React.createElement("div", null,
                                    React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + mdi.SPFieldInternalName, Validators: ["is-valid-url"], Value: getCurrentMetadataFieldValue(mdi), ShowFirst: true }))) : null,
                                mdi.RequiredInDetailsForm !== undefined && mdi.RequiredInDetailsForm === true ? (React.createElement("div", null,
                                    mdi.FieldType === "Date" || mdi.FieldType === "Text" || mdi.FieldType === "Link" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-not-empty"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                    mdi.FieldType === "Choice" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                    mdi.FieldType === "ManagedMetadata" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null)) : null))
                        // :null}</>
                        ); }))))),
            React.createElement(Modal.Footer, null, updateItemLoading === false ? (React.createElement("div", { className: "buttons" },
                React.createElement("div", { className: "cancel", onClick: function () { return setshowMetadataModal(!showMetadataModal); } },
                    React.createElement(FormattedMessage, { id: "general.label.cancel", defaultMessage: "Cancel" })),
                isEditValid === true ? (React.createElement("div", { className: "save", onClick: function () { return UpdateToDirectory(); } },
                    React.createElement("i", { className: "far fa-edit" }),
                    " ",
                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.button.update_item_in_directory", defaultMessage: "Update Item in Directory" }))) : (React.createElement("div", { className: "save no-valid" },
                    React.createElement("i", { className: "far fa-edit" }),
                    " ",
                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.button.update_item_in_directory", defaultMessage: "Update Item in Directory" }))))) : (React.createElement("div", { className: "loading" },
                React.createElement(AnimatedLoader, { size: "small" })))))) : null));
});
export default TeamInformationCustomMetadata;
