var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrchestryContextCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {},
    showLoaderOverride: false
};
export function orchestryContextCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case OrchestryContextCurrentActionTypes.OrchestryContext_Current_Load: {
            if (action.payload.DoesTenantHaveSubscription === true) {
                //LocalSessionService.SetObject("OrchestryContext", action.payload);
            }
            return {
                item: action.payload,
                showLoaderOverride: false
            };
        }
        case OrchestryContextCurrentActionTypes.OrchestryContext_Current_Refresh: {
            var updatedContext = Object.assign({}, state.item, action.payload);
            return {
                item: updatedContext,
                showLoaderOverride: false
            };
        }
        case OrchestryContextCurrentActionTypes.OrchestryContext_Current_Load_From_LocalSession: {
            return {
                item: action.payload,
                showLoaderOverride: false
            };
        }
        case OrchestryContextCurrentActionTypes.OrchestryContext_Current_UpdateShowLoader: {
            return {
                item: __assign({}, state.item),
                showLoaderOverride: action.payload
            };
        }
        default:
            return state;
    }
}
