import * as React from "react";
import { FontIcon } from "@fluentui/react";
import "./teams-status.scss";
var statusMap = [
    { key: "PresenceUnknown", colour: "white", title: "Presence Unknown", icon: "fa-regular fa-times" },
    { key: "Available", colour: "green", title: "Available", icon: "SkypeCircleCheck" },
    { key: "AvailableIdle", colour: "green", title: "Available", icon: "SkypeCircleCheck" },
    { key: "Away", colour: "yellow", title: "Away", icon: "SkypeCircleClock" },
    { key: "BeRightBack", colour: "yellow", title: "Be Right Back", icon: "SkypeCircleClock" },
    { key: "Busy", colour: "red", title: "Busy", icon: "CircleFill" },
    { key: "BusyIdle", colour: "red", title: "Busy", icon: "CircleFill" },
    { key: "DoNotDisturb", colour: "red", title: "Do Not Disturb", icon: "SkypeCircleMinus" },
    { key: "Offline", colour: "white", title: "Offline", icon: "fa-regular fa-times" },
    { key: "InACall", colour: "red", title: "In A Call", icon: "CircleFill" },
    { key: "InAConferenceCall", colour: "red", title: "In A Conference Call", icon: "CircleFill" },
    { key: "Inactive", colour: "yellow", title: "Inactive", icon: "SkypeCircleClock" },
    { key: "InAMeeting", colour: "red", title: "In A Meeting", icon: "CircleFill" },
    { key: "OffWork", colour: "purple", title: "Off Work", icon: "SkypeArrow" },
    { key: "OutOfOffice", colour: "purple", title: "Out Of Office", icon: "SkypeArrow" },
    { key: "Presenting", colour: "red", title: "Presenting", icon: "SkypeCircleMinus" },
    { key: "UrgentInterruptionsOnly", colour: "red", title: "Urgent Interruptions Only", icon: "SkypeCircleMinus" }
];
export var TeamsUserActivity = function (props) {
    var status = React.useState(statusMap.filter(function (s) { return s.key.toLowerCase() === props.status.toLowerCase(); })[0])[0];
    return (React.createElement("div", { title: status.title, className: "status-container ".concat(status.colour), "data-pw": "status" }, status.colour === "white" ? React.createElement("i", { className: status.icon }) : React.createElement(FontIcon, { iconName: status.icon })));
};
