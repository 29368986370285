import React from "react";
import { CSSTransition } from "react-transition-group";
import "./lifecycle-links.scss";
import SharePointLogo from "./logos/SharePoint.png";
import OutlookLogo from "./logos/Outlook.png";
import PlannerLogo from "./logos/Planner.png";
import OneNoteLogo from "./logos/OneNote.png";
import AzureLogo from "./logos/Azure.png";
import { Portlet } from "app/components/common/portlet";
import { useSelector } from "react-redux";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { getIconClassName } from "@uifabric/styling";
function nFormatter(num, digits) {
    var lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
var PaginationToolbar = function (_a) {
    var currentPage = _a.currentPage, totalResults = _a.totalResults, setCurrentPage = _a.setCurrentPage, setScrollDirection = _a.setScrollDirection;
    var isLastPage = function () {
        //console.log("Last Page Check", (Math.ceil((Number(props.totalResults) / props.currentResultsPerPage))))
        return currentPage + 1 === Math.ceil(Number(totalResults) / 5);
    };
    return (React.createElement("div", { className: "paging" },
        React.createElement("div", { className: "kt-pagination  kt-pagination--brand" },
            React.createElement("ul", { className: "kt-pagination__links" },
                currentPage > 0 ? (React.createElement("li", { className: "kt-pagination__link--prev", onClick: function () {
                        setCurrentPage(currentPage - 1);
                        setScrollDirection("to-the-left");
                    } },
                    React.createElement("a", null,
                        React.createElement("i", { className: "fa fa-angle-left kt-font-brand" })))) : (React.createElement("li", { className: "kt-pagination__link--prev disabled" },
                    React.createElement("a", null,
                        React.createElement("i", { className: "fa fa-angle-left kt-font-brand" })))),
                isLastPage() !== true ? (React.createElement("li", { className: "kt-pagination__link--next", onClick: function () {
                        setCurrentPage(currentPage + 1);
                        setScrollDirection("to-the-right");
                    } },
                    React.createElement("a", null,
                        React.createElement("i", { className: "fa fa-angle-right kt-font-brand" })))) : null))));
};
var SingleLinkItem = function (_a) {
    var text = _a.text, url = _a.url, count = _a.count, leftIcon = _a.leftIcon, leftIconBackgroundColour = _a.leftIconBackgroundColour, leftImage = _a.leftImage;
    return (React.createElement("div", { className: "single-link-item", "data-pw": "ws-link-item" },
        React.createElement("div", { className: "left-icon ".concat(leftIconBackgroundColour, " ") },
            leftIcon ? React.createElement("i", { className: leftIcon }) : null,
            !leftIcon && leftImage ? React.createElement("img", { src: leftImage }) : null),
        React.createElement("div", { className: "text", "data-pw": "text" },
            React.createElement("span", null,
                React.createElement("span", null, text),
                React.createElement("span", { className: "count-text" }, count !== null && count !== undefined ? " (".concat(nFormatter(count, 1), ")") : "")),
            React.createElement("i", { className: "fa fa-external-link", "data-pw": "external-link" }),
            React.createElement("a", { title: url, target: "_blank", rel: "noreferrer", className: "link", href: url }))));
};
var scrollClasses = {
    enter: "to-the-right",
    enterActive: "to-the-right-active",
    enterDone: "to-the-right-done",
    exit: "to-the-left",
    exitActive: "to-the-left-active",
    exitDone: "to-the-left-done"
};
var MultiItemLink = function (_a) {
    var _b, _c;
    var text = _a.text, urls = _a.urls, count = _a.count, leftIcon = _a.leftIcon, leftIconBackgroundColour = _a.leftIconBackgroundColour, leftImage = _a.leftImage;
    var _d = React.useState(false), containerOpen = _d[0], setContainerOpen = _d[1];
    var _e = React.useState(0), currentPage = _e[0], setcurrentPage = _e[1];
    var _f = React.useState(""), scrollDireciton = _f[0], setscrollDirection = _f[1];
    return (React.createElement(React.Fragment, null, urls.length > 1 ? (React.createElement("div", { className: "multi-link-item ".concat(containerOpen ? "is-open" : "is-closed", " light-").concat(leftIconBackgroundColour) },
        React.createElement("div", { onClick: function () { return setContainerOpen(function (value) { return !value; }); }, className: "link-item-body" },
            React.createElement("div", { className: "left-icon ".concat(leftIconBackgroundColour) },
                leftIcon ? React.createElement("i", { className: leftIcon }) : null,
                !leftIcon && leftImage ? React.createElement("img", { src: leftImage }) : null),
            React.createElement("div", { className: "body-text" },
                React.createElement("span", null,
                    React.createElement("span", null, text),
                    React.createElement("span", { className: "count-text" }, count !== null && count !== undefined ? " (".concat(nFormatter(count, 1), ")") : "")),
                React.createElement("i", { className: "fa fa-chevron-down" }))),
        React.createElement(CSSTransition, { in: containerOpen, classNames: "show", timeout: 0 },
            React.createElement(CSSTransition, { in: scrollDireciton === "to-the-right" ? true : false, classNames: scrollClasses, timeout: 500 },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "body-items ".concat(urls.length > 5 ? "more-than-5" : null).trim() },
                        urls.slice(currentPage * 5, currentPage * 5 + 5).map(function (url, index) {
                            return (React.createElement("div", { className: "item-container" },
                                React.createElement("div", { className: "item" },
                                    React.createElement("div", { className: "item-text" },
                                        React.createElement("div", { className: "link-text" },
                                            React.createElement("i", { className: "fa-solid fa-file-lines" }),
                                            React.createElement("div", null, url.Key)),
                                        React.createElement("a", { title: url.Value, target: "_blank", rel: "noopener noreferrer", href: url.Value })),
                                    React.createElement("i", { className: "fa fa-external-link" })),
                                React.createElement("hr", { className: "list-separator" })));
                        }),
                        urls.length > 5 ? (React.createElement("div", { className: "pagination" },
                            React.createElement(PaginationToolbar, { totalResults: urls.length, currentPage: currentPage, setCurrentPage: setcurrentPage, setScrollDirection: setscrollDirection }))) : null)))))) : (React.createElement(SingleLinkItem, { text: (_b = urls[0]) === null || _b === void 0 ? void 0 : _b.Key, url: (_c = urls[0]) === null || _c === void 0 ? void 0 : _c.Value, leftIcon: leftIcon, leftIconBackgroundColour: leftIconBackgroundColour, leftImage: leftImage }))));
};
export default function LifeCycleLinks() {
    var _a, _b;
    var linkData = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.links; });
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    return (React.createElement(Portlet, null,
        React.createElement("div", { className: "workspace-links-container", "data-pw": "workspace-links-container" },
            React.createElement("div", { className: "title", "data-pw": "title" }, "Workspace Links"),
            linkData !== undefined && linkData ? (React.createElement("div", { className: "links" },
                React.createElement(SingleLinkItem, { leftImage: SharePointLogo, url: (linkData === null || linkData === void 0 ? void 0 : linkData.SharePointUrl) || "", text: "SharePoint Site", leftIconBackgroundColour: "dark-green" }),
                React.createElement(SingleLinkItem, { leftIcon: "fa-solid fa-folder-bookmark", url: (linkData === null || linkData === void 0 ? void 0 : linkData.SharePointDocUrl) || "", text: "Documents", leftIconBackgroundColour: "dark-green", count: (linkData === null || linkData === void 0 ? void 0 : linkData.SharePointDocumentCount) || 0 }),
                (reportItem === null || reportItem === void 0 ? void 0 : reportItem.TemplateType) !== "Viva Engage Community" ? (React.createElement(React.Fragment, null,
                    React.createElement(SingleLinkItem, { leftImage: OutlookLogo, url: (linkData === null || linkData === void 0 ? void 0 : linkData.EmailUrl) || "", text: "Group Mailbox", leftIconBackgroundColour: "light-blue", count: (linkData === null || linkData === void 0 ? void 0 : linkData.EmailCount) || 0 }),
                    React.createElement(SingleLinkItem, { leftIcon: "fa-solid fa-calendar-days", url: (linkData === null || linkData === void 0 ? void 0 : linkData.CalendarUrl) || "", text: "Group Calendar", leftIconBackgroundColour: "light-blue", count: (linkData === null || linkData === void 0 ? void 0 : linkData.CalendarCount) || 0 }))) : null,
                (linkData === null || linkData === void 0 ? void 0 : linkData.TeamUrl) !== undefined && (linkData === null || linkData === void 0 ? void 0 : linkData.TeamUrl) ? (React.createElement(SingleLinkItem, { leftIcon: getIconClassName("TeamsLogo"), url: linkData.TeamUrl, text: "Team", leftIconBackgroundColour: "teams-purple" })) : (linkData === null || linkData === void 0 ? void 0 : linkData.TeamUrl) === null ? null : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })),
                (linkData === null || linkData === void 0 ? void 0 : linkData.TeamAdminCenterUrl) !== undefined && (linkData === null || linkData === void 0 ? void 0 : linkData.TeamAdminCenterUrl) ? (React.createElement(SingleLinkItem, { leftIcon: getIconClassName("AdminALogoInverse32"), url: linkData.TeamAdminCenterUrl, text: "Teams Admin Centre", leftIconBackgroundColour: "teams-purple" })) : (linkData === null || linkData === void 0 ? void 0 : linkData.TeamAdminCenterUrl) === null ? null : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })),
                (linkData === null || linkData === void 0 ? void 0 : linkData.AzureAdUrl) !== undefined && (linkData === null || linkData === void 0 ? void 0 : linkData.AzureAdUrl) ? (React.createElement(SingleLinkItem, { leftImage: AzureLogo, url: linkData.AzureAdUrl, text: "Azure AD Admin", leftIconBackgroundColour: "light-blue" })) : (linkData === null || linkData === void 0 ? void 0 : linkData.AzureAdUrl) === null ? null : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })),
                linkData.PlannerUrls !== undefined && linkData.PlannerUrls ? (linkData.PlannerUrls.length > 0 ? (React.createElement(MultiItemLink, { leftImage: PlannerLogo, leftIconBackgroundColour: "green", text: "Planner Plans", urls: (linkData === null || linkData === void 0 ? void 0 : linkData.PlannerUrls) || [], count: ((_a = linkData === null || linkData === void 0 ? void 0 : linkData.PlannerUrls) === null || _a === void 0 ? void 0 : _a.length) || 0 })) : null) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })),
                linkData.NotebookUrls !== undefined && linkData.NotebookUrls ? (linkData.NotebookUrls.length > 0 ? (React.createElement(MultiItemLink, { leftImage: OneNoteLogo, leftIconBackgroundColour: "purple", text: "Notebooks", urls: (linkData === null || linkData === void 0 ? void 0 : linkData.NotebookUrls) || [], count: ((_b = linkData === null || linkData === void 0 ? void 0 : linkData.NotebookUrls) === null || _b === void 0 ? void 0 : _b.length) || 0 })) : null) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 })))) : (React.createElement("div", { className: "skeletons" },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }),
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 44 }))))));
}
