import * as AsyncStore from "app/store/async";
import * as GuestPoliciesListStore from "app/store/lifecycle-guest-policies/list";
export var guestNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Name}</strong> to policies list...",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Name}</strong>",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Name}</strong>...",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Name}</strong> successfully added to policies list.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Add
            },
            {
                Text: "Successfully updated <strong>{Item.Name}</strong>.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Update
            },
            {
                Text: "Successfully deleted <strong>{Item.Name}</strong>.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Name}</strong> to policies list.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Add
            },
            {
                Text: "Error when updating policy.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Update
            },
            {
                Text: "Error when deleting policy.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Item_Delete
            },
            {
                Text: "Error when trying to load policies list.",
                ActionType: GuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Load
            }
        ]
    }
];
