var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./library-template-browser.scss";
import { Modal, Form, Row, Col, Carousel } from "react-bootstrap";
import { Portlet, PortletBody } from "app/components/common/portlet";
import FormSearchbox from "app/components/common/form-searchbox";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { useSelector, useDispatch } from "react-redux";
import * as LibraryTemplateListStore from "app/store/library-templates/list";
import * as TemplateListStore from "app/store/templates/list";
import Check from "app/utils/check";
import TemplateType from "app/components/common/template-type";
import UserProfilePicture from "app/components/common/user-profile-picture";
import LibraryService from "app/services/library";
import AnimatedLoader from "app/components/common/animated-loader";
import LineAwesomeIcon from "app/components/common/line-awesome-icon";
import OrchModal from "app/components/common/orch-modal";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
var LibraryTemplateBrowser = React.memo(function (props) {
    var _a, _b, _c, _d;
    //const [items,setItems] = React.useState(["1","2","4","5","6","7","8","9"]);
    var _e = React.useState(""), searchCurrentText = _e[0], setSearchCurrentText = _e[1];
    var _f = React.useState([]), searchTemplateTypes = _f[0], setSearchTemplateTypes = _f[1];
    var _g = React.useState(""), searchCurrentType = _g[0], setSearchCurrentType = _g[1];
    var _h = React.useState([]), searchCategories = _h[0], setCategroies = _h[1];
    var _j = React.useState(""), searchCurrentCategory = _j[0], setSearchCurrentCategory = _j[1];
    var _k = React.useState(false), showDetails = _k[0], setShowDetails = _k[1];
    var _l = React.useState({}), detailsItem = _l[0], setDetailsItem = _l[1];
    var _m = React.useState(0), detailsPicIndex = _m[0], setDetailsPicIndex = _m[1];
    var _o = React.useState(""), currentTemplateBeingCopied = _o[0], setCurrentTemplateBeingCopied = _o[1];
    var _p = React.useState([]), templates = _p[0], setTemplates = _p[1];
    var _q = React.useState(false), firstLoadComplete = _q[0], setFirstLoadComplete = _q[1];
    var _r = React.useState(false), showNewTemplateCopyNameModal = _r[0], setshowNewTemplateCopyNameModal = _r[1];
    var _s = React.useState(null), newTemplateCopyName = _s[0], setnewTemplateCopyName = _s[1];
    var _t = React.useState(undefined), selectedTemplateToCopy = _t[0], setselectedTemplateToCopy = _t[1];
    var _u = React.useState(false), formValid = _u[0], setformValid = _u[1];
    var _v = React.useState(false), copyingTemplate = _v[0], setcopyingTemplate = _v[1];
    var newTemplateCopyNameRef = React.useRef(null);
    var dispatch = useDispatch();
    var librarytemplates = useSelector(function (state) { return state.libraryTemplateListState.items; });
    var isLibraryLoading = useSelector(function (state) { return state.libraryTemplateListState.IsLoading; });
    //const currentLibraryTemplate: LibraryTemplate = useSelector((state: RootState) => state.libraryTemplateCurrentState.item);
    //console.log("LibraryTemplate LIst Component LibraryTemplates > ", librarytemplates);
    React.useEffect(function () {
        var newResults = __spreadArray([], librarytemplates, true);
        if (searchCurrentText !== "") {
            newResults = newResults.filter(function (r) { return (r.Title !== undefined ? r.Title.toLowerCase() : "").indexOf(searchCurrentText.toLowerCase()) > -1; });
        }
        if (searchCurrentType !== "" && searchCurrentType !== undefined && searchCurrentType !== "All") {
            newResults = newResults.filter(function (r) { return (r.TemplateType !== undefined ? r.TemplateType : "") === searchCurrentType; });
        }
        if (searchCurrentCategory !== "" && searchCurrentCategory !== undefined && searchCurrentCategory !== "All") {
            newResults = newResults.filter(function (r) { return (r.Category !== undefined ? r.Category : "") === searchCurrentCategory; });
        }
        setTemplates(newResults);
    }, [librarytemplates, searchCurrentText, searchCurrentType, searchCurrentCategory]);
    React.useEffect(function () {
        if (isLibraryLoading === false) {
            setFirstLoadComplete(true);
            setCurrentTemplateBeingCopied("");
        }
    }, [isLibraryLoading]);
    React.useEffect(function () {
        if (props.open === true) {
            dispatch(LibraryTemplateListStore.LoadForCopy());
        }
        else {
            setFirstLoadComplete(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);
    React.useEffect(function () {
        if (newTemplateCopyNameRef.current !== null) {
            newTemplateCopyNameRef.current.focus();
        }
    }, [showNewTemplateCopyNameModal]);
    React.useEffect(function () {
        if (librarytemplates.length > 0) {
            var types_1 = [{ id: "1", title: "All Templates", value: "All" }];
            var cats_1 = [{ id: "1", title: "All Categories", value: "All" }];
            librarytemplates.map(function (libTemplate) {
                if (!types_1.some(function (t) { return t.value === libTemplate.TemplateType; })) {
                    if (libTemplate.TemplateType !== undefined && libTemplate.TemplateType !== null) {
                        types_1.push({
                            id: (types_1.length + 1).toString(),
                            title: libTemplate.TemplateType.toString(),
                            value: libTemplate.TemplateType
                        });
                    }
                }
                if (!cats_1.some(function (c) { return c.value === libTemplate.Category; })) {
                    if (libTemplate.Category !== undefined && libTemplate.Category !== null && libTemplate.Category !== "") {
                        cats_1.push({
                            id: (cats_1.length + 1).toString(),
                            title: libTemplate.Category.toString(),
                            value: libTemplate.Category
                        });
                    }
                }
            });
            setSearchTemplateTypes(types_1);
            setCategroies(cats_1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [librarytemplates]);
    var getImageArray = function (libraryTemplate) {
        var guidArray = [];
        if (!Check.isStringUndefinedNullEmpty(libraryTemplate.ImageJsonData)) {
            //@ts-ignore
            guidArray = libraryTemplate.ImageJsonData.split("|");
        }
        return guidArray;
    };
    var getImageThumbnailUrl = function (itemGuid, imageGuid) {
        return "/api/LibraryTemplates/LoadImage?itemGuid=" + itemGuid + "&imageGuid=" + imageGuid + "&thumbnail=true";
    };
    var getImageUrl = function (itemGuid, imageGuid) {
        return "/api/LibraryTemplates/LoadImage?itemGuid=" + itemGuid + "&imageGuid=" + imageGuid + "&thumbnail=false";
    };
    var toggleDetails = function (libTemplate) {
        if (showDetails === false) {
            if (libTemplate !== undefined) {
                setDetailsItem(libTemplate);
            }
            setShowDetails(true);
        }
        else {
            setShowDetails(false);
        }
    };
    var copyToTenant = function (newTemplateName, libTemplate) {
        if ((libTemplate === null || libTemplate === void 0 ? void 0 : libTemplate.GUID) !== null && (libTemplate === null || libTemplate === void 0 ? void 0 : libTemplate.GUID) !== undefined && newTemplateName !== null && newTemplateName !== undefined) {
            setCurrentTemplateBeingCopied(libTemplate === null || libTemplate === void 0 ? void 0 : libTemplate.GUID);
            setcopyingTemplate(true);
            LibraryService.CopyTemplate(libTemplate === null || libTemplate === void 0 ? void 0 : libTemplate.GUID, newTemplateName).then(function (res) {
                dispatch(LibraryTemplateListStore.Refresh());
                dispatch(TemplateListStore.Refresh());
                AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Template_List_Refresh").then(function () {
                    dispatch(LibraryTemplateListStore.TemplateCopiedSuccess());
                    setshowNewTemplateCopyNameModal(function () { return false; });
                    setcopyingTemplate(function () { return false; });
                });
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Modal, { dialogClassName: "modal-90w", centered: true, show: props.open, onHide: function () { return props.toggleOpen(); }, animation: true, className: "library-template-browser-modal" },
                React.createElement(Modal.Header, null,
                    React.createElement(Modal.Title, { id: "modal-title" },
                        React.createElement("i", { className: "fa fa-book-open" }),
                        " Workspace Library")),
                React.createElement(Modal.Body, null,
                    React.createElement("div", { className: "search-interface" },
                        React.createElement("div", { className: "interface-top" },
                            React.createElement(Row, null,
                                React.createElement(Col, { lg: 3, md: 4, sm: 4 },
                                    React.createElement(FormSearchbox, { placeholder: "Search Workspaces", handler: function (event) {
                                            setSearchCurrentText(event.target.value);
                                        } })),
                                React.createElement(Col, { lg: 2, md: 3, sm: 4 },
                                    React.createElement(FormDropdownSelect, { list: searchTemplateTypes, onChange: function (value) {
                                            setSearchCurrentType(value);
                                        }, placeholder: "Template Type" })),
                                React.createElement(Col, { lg: 2, md: 3, sm: 4 },
                                    React.createElement(FormDropdownSelect, { list: searchCategories, onChange: function (value) {
                                            setSearchCurrentCategory(value);
                                        }, placeholder: "Category" })))),
                        React.createElement(Row, null, firstLoadComplete === false ? (React.createElement("div", { className: "loader" },
                            React.createElement(AnimatedLoader, null))) : (React.createElement(React.Fragment, null,
                            templates.map(function (libTemplate, libIndex) { return (React.createElement(Col, { xl: 3, lg: 4, md: 4, sm: 6, key: libIndex },
                                React.createElement(Portlet, { className: "current-status kt-portlet--height-fluid kt-widget19" },
                                    React.createElement("div", { className: "kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill" }, getImageArray(libTemplate).length === 1 ? (getImageArray(libTemplate).map(function (imageGuid, imageIndex) { return (React.createElement("div", { key: "preview-image-" + imageIndex, className: "preview-image", style: { minHeight: "200px", backgroundImage: "url('" + getImageThumbnailUrl(libTemplate.GUID, imageGuid) + "')" } })); })) : (React.createElement(Carousel, { interval: null }, getImageArray(libTemplate).map(function (imageGuid, imageIndex) { return (React.createElement(Carousel.Item, { key: imageIndex },
                                        React.createElement("div", { className: "kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides", style: { minHeight: "200px", backgroundImage: "url('" + getImageThumbnailUrl(libTemplate.GUID, imageGuid) + "')" } }))); })))),
                                    React.createElement(PortletBody, null,
                                        React.createElement("div", { className: "kt-widget19__wrapper" },
                                            React.createElement("div", { className: "kt-widget kt-widget--user-profile-2" },
                                                React.createElement("span", { className: "header-title" },
                                                    React.createElement("h3", null, libTemplate.Title)),
                                                React.createElement("div", { className: "kt-widget__body" },
                                                    detailsItem.Description !== undefined && detailsItem.Description !== null && detailsItem.Description !== "" ? (React.createElement("div", { className: "kt-widget__section" }, libTemplate.Description)) : null,
                                                    React.createElement("div", { className: "kt-widget__item" },
                                                        React.createElement("div", { className: "kt-widget__contact" },
                                                            React.createElement("span", { className: "kt-widget__label" }, "Template Type:"),
                                                            React.createElement("a", { href: "#", className: "kt-widget__data" },
                                                                React.createElement(TemplateType, { templateType: libTemplate.TemplateType }))),
                                                        React.createElement("div", { className: "kt-widget__contact" },
                                                            React.createElement("span", { className: "kt-widget__label" }, "Author:"),
                                                            React.createElement("a", { href: "#", className: "kt-widget__data" }, libTemplate.TenantTitle)))),
                                                React.createElement("div", { className: "kt-widget__footer" },
                                                    React.createElement("button", { type: "button", className: "btn btn-secondary btn-hover-brand", onClick: function () { return toggleDetails(libTemplate); } }, "Details"),
                                                    React.createElement("button", { type: "button", className: "btn btn-success button-with-icon", onClick: function () {
                                                            setselectedTemplateToCopy(libTemplate);
                                                            setshowNewTemplateCopyNameModal(true);
                                                        } },
                                                        React.createElement("i", { className: "fa fa-copy" }),
                                                        " Add to Orchestry")))))))); }),
                            templates.length === 0 ? React.createElement("div", { className: "no-results" }, "No Results") : null,
                            React.createElement(OrchModal, { size: "md", modalClassName: "new-template-copy-name-modal", modalBgClassName: "new-template-copy-name-modal-bg", headerContents: React.createElement(React.Fragment, null,
                                    React.createElement("h4", { className: "modal-title" }, "Enter New Template Name")), showModalOverride: showNewTemplateCopyNameModal, showCloseButton: true, showModalToggleOverride: function () { return setshowNewTemplateCopyNameModal(false); } },
                                React.createElement("div", { className: "new-template-copy-name-modal-contents" },
                                    React.createElement(FormValidationContext, { UniqueIdentifier: "new-template-name-form", OnChangeIsValid: function (isValid) { return setformValid(isValid); } },
                                        React.createElement("div", { className: "form-elements" },
                                            React.createElement("div", { className: "name-input" },
                                                React.createElement(Form.Control, { disabled: copyingTemplate, onChange: function (e) {
                                                        setnewTemplateCopyName(e.target.value);
                                                    }, 
                                                    //@ts-ignore
                                                    ref: newTemplateCopyNameRef, type: "text", placeholder: "Enter name here" })),
                                            copyingTemplate ? (React.createElement("button", { type: "button", className: "btn btn-success copying-button-with-loader" },
                                                React.createElement(AnimatedLoader, { size: "xsmall" }),
                                                " Add")) : (React.createElement("button", { type: "button", disabled: !formValid, className: "btn btn-success name-add-button", onClick: function () {
                                                    copyToTenant(newTemplateCopyName, selectedTemplateToCopy);
                                                } },
                                                React.createElement("i", { className: "fa fa-copy" }),
                                                " Add"))),
                                        React.createElement(FormValidator, { OnScreen: showNewTemplateCopyNameModal, ShowFirst: true, ShowAsInfo: true, FieldName: "Template Name", Enabled: showNewTemplateCopyNameModal, UniqueIdentifier: "new-template-name", Validators: ["string-is-more-than-2", "string-not-whitespace"], Value: newTemplateCopyName }))))))))))),
        React.createElement("div", null,
            React.createElement(Modal, { dialogClassName: "details-box-modal", centered: true, show: showDetails, animation: true, onHide: function () { return toggleDetails(); }, className: "details-modal" }, detailsItem !== undefined ? (React.createElement(Portlet, { className: "current-status kt-portlet--height-fluid kt-widget19" },
                React.createElement(Carousel, { interval: null, activeIndex: detailsPicIndex }, getImageArray(detailsItem).map(function (imageGuid) { return (React.createElement(Carousel.Item, null,
                    React.createElement("div", { className: "kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides", style: { minHeight: "400px", backgroundImage: "url('" + getImageUrl(detailsItem.GUID, imageGuid) + "')" } }))); })),
                getImageArray(detailsItem).length > 1 ? (React.createElement("div", { className: "thumbnails" }, getImageArray(detailsItem).map(function (imageGuid, indexIndex) { return (React.createElement("div", { className: "thumb", style: { backgroundImage: "url('" + getImageThumbnailUrl(detailsItem.GUID, imageGuid) + "')" }, onClick: function () { return setDetailsPicIndex(indexIndex); } })); }))) : null,
                React.createElement(PortletBody, null,
                    React.createElement("div", { className: "kt-widget19__wrapper" },
                        React.createElement("div", { className: "kt-widget kt-widget--user-profile-2" },
                            React.createElement("h3", null, detailsItem.Title),
                            React.createElement("div", { className: "kt-widget__body" },
                                detailsItem.Description !== undefined && detailsItem.Description !== null && detailsItem.Description !== "" ? (React.createElement("div", { className: "kt-widget__section" }, detailsItem.Description)) : null,
                                React.createElement(Row, { className: "properties" },
                                    React.createElement(Col, null,
                                        React.createElement("h4", null, "Template Type:")),
                                    React.createElement(Col, null,
                                        React.createElement(TemplateType, { templateType: detailsItem.TemplateType })),
                                    React.createElement(Col, null,
                                        React.createElement("h4", null, "Author:")),
                                    React.createElement(Col, null, detailsItem.TenantTitle)),
                                React.createElement("hr", null),
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement("h4", null, "Features"),
                                        React.createElement("div", { className: "features" }, (_a = detailsItem.AssociatedFeatures) === null || _a === void 0 ? void 0 :
                                            _a.map(function (assFeat) {
                                                var _a, _b, _c;
                                                return (React.createElement("div", { className: "feature-item" },
                                                    ((_a = assFeat === null || assFeat === void 0 ? void 0 : assFeat.Item) === null || _a === void 0 ? void 0 : _a.Icon) !== undefined ? React.createElement(LineAwesomeIcon, { iconString: (_b = assFeat === null || assFeat === void 0 ? void 0 : assFeat.Item) === null || _b === void 0 ? void 0 : _b.Icon }) : null,
                                                    React.createElement("div", { className: "feat-title" }, (_c = assFeat === null || assFeat === void 0 ? void 0 : assFeat.Item) === null || _c === void 0 ? void 0 : _c.Title)));
                                            }),
                                            ((_b = detailsItem.AssociatedFeatures) === null || _b === void 0 ? void 0 : _b.length) === 0 ? React.createElement("div", { className: "no-results" }, "-- no features in this template --") : null)),
                                    React.createElement(Col, null,
                                        React.createElement("h4", null, "Channels"),
                                        React.createElement("div", { className: "channels" }, (_c = detailsItem.AssociatedChannels) === null || _c === void 0 ? void 0 :
                                            _c.map(function (assChan) {
                                                var _a, _b;
                                                return (React.createElement("div", { className: "channel-item" },
                                                    React.createElement(UserProfilePicture, { displayName: (_a = assChan.Item) === null || _a === void 0 ? void 0 : _a.Title, size: "xs" }),
                                                    React.createElement("div", { className: "channel-title" }, (_b = assChan.Item) === null || _b === void 0 ? void 0 : _b.Title)));
                                            }),
                                            ((_d = detailsItem.AssociatedChannels) === null || _d === void 0 ? void 0 : _d.length) === 0 ? React.createElement("div", { className: "no-results" }, "-- no channels in this template --") : null)))),
                            React.createElement("div", { className: "kt-widget__footer" },
                                React.createElement("button", { type: "button", className: "btn btn-secondary btn-hover-brand", onClick: function () { return toggleDetails(detailsItem); } }, "Close"),
                                React.createElement("button", { type: "button", className: "btn btn-success", onClick: function () {
                                        setselectedTemplateToCopy(detailsItem);
                                        setshowNewTemplateCopyNameModal(true);
                                    } },
                                    React.createElement("i", { className: "fa fa-copy" }),
                                    " Add to Orchestry"))))))) : null))));
});
export default LibraryTemplateBrowser;
