import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useHealthCheckRouteParams } from "../../health-checks/use-health-check-route-params.hook";
import { useUserIsPartnerNotViewingPartnerLedTenant } from "../../health-checks/use-user-is-partner-viewing-self-led-tenant.hook";
/**
 * Hook to determine if the current user can edit the health check.
 */
export var useHealthCheckCanEdit = function () {
    var _a = useUserIsPartnerNotViewingPartnerLedTenant(), userIsPartnerViewingNotPartnerLedTenant = _a.userIsPartnerViewingNotPartnerLedTenant, isLoading = _a.isLoading;
    var userType = useHealthCheckRouteParams().userType;
    var userIsPartnerViewingCustomer = userType === "partner";
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var userCanEdit = currentUserHasRole(OrchRole.Admin) ||
        currentUserHasRole(OrchRole.BeaconAdmin) ||
        currentUserHasRole(OrchRole.HealthCheckReviewer) ||
        (userIsPartnerViewingCustomer && (currentUserHasRole(OrchRole.PartnerAdmin) || currentUserHasRole(OrchRole.PartnerHubAdmin) || currentUserHasRole(OrchRole.PartnerTenantManager)));
    return { userCanEdit: userCanEdit && !userIsPartnerViewingNotPartnerLedTenant, isLoading: isLoading };
};
