import * as React from "react";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import FormToggleControl from "app/components/common/form-toggle-control";
import { useDispatch, useSelector } from "react-redux";
import * as LibraryTemplateCurrentStore from "app/store/library-templates/current";
import { WorkspaceTypes } from "app/store/workspaces/types";
import { PermissionTypes } from "app/store/permissions/types";
import * as ApprovalWorkflowListStore from "app/store/approval-workflows/list";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import * as LibraryConfigurationCurrentStore from "app/store/library-configuration/current";
import FormImageUploadGallery from "app/components/common/form-image-upload-gallery";
import { FormValidator } from "app/components/common/form-validation";
var TabDetails = React.memo(function (props, state) {
    var _a = React.useState(), availableWorkflows = _a[0], setAvailableWorkflows = _a[1];
    var dispatch = useDispatch();
    var approvalWorkflows = useSelector(function (state) { return state.approvalWorkflowListState.items; });
    var currentLibraryConfiguration = useSelector(function (state) { return state.libraryConfigurationCurrentState.item; });
    var _b = React.useState(), templateCategories = _b[0], setTemplateCategories = _b[1];
    React.useEffect(function () {
        dispatch(LibraryConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (currentLibraryConfiguration.GUID !== undefined) {
            if (currentLibraryConfiguration.GUID !== "") {
                if (currentLibraryConfiguration.TemplateCategoriesJson !== undefined &&
                    currentLibraryConfiguration.TemplateCategoriesJson !== null &&
                    currentLibraryConfiguration.TemplateCategoriesJson !== "") {
                    var availableCats = JSON.parse(currentLibraryConfiguration.TemplateCategoriesJson);
                    if (availableCats !== undefined && availableCats.length !== 0) {
                        var newOptionArray_1 = [];
                        availableCats.forEach(function (cat, index) {
                            newOptionArray_1.push({
                                id: index + "",
                                title: cat,
                                value: cat
                            });
                        });
                        setTemplateCategories(newOptionArray_1);
                    }
                }
            }
        }
    }, [currentLibraryConfiguration]);
    React.useEffect(function () {
        dispatch(ApprovalWorkflowListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        var mappedApprovalWorkflows = approvalWorkflows.map(function (aw, index) {
            return ({
                id: index + "",
                title: aw.Name,
                description: aw.Description,
                value: aw.GUID
            });
        });
        setAvailableWorkflows(mappedApprovalWorkflows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalWorkflows]);
    React.useEffect(function () {
        console.log("availableWorkflows", availableWorkflows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableWorkflows]);
    // Setup the selection options for the dropdowns
    var workspaceType = React.useState(WorkspaceTypes.map(function (wt, index) { return ({
        id: index + "",
        title: wt.Title,
        description: wt.Description,
        value: wt.Value,
        image: wt.Image
    }); }))[0];
    var permissionType = React.useState(PermissionTypes.map(function (wt, index) { return ({
        id: index + "",
        title: wt.Title,
        description: wt.Description,
        value: wt.Value,
        icon: wt.Icon
    }); }))[0];
    return (
    // <Container fluid={true}>
    React.createElement(Row, null,
        React.createElement(Col, { md: 6, lg: 5, xl: 4 },
            React.createElement(Form.Group, { className: "form-group", controlId: "control-librarytemplate-name" },
                React.createElement(Form.Label, null,
                    "Name ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(Form.Control, { placeholder: "Template Name", defaultValue: props.currentLibraryTemplate.Title, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ Title: event.target.value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "name", Validators: ["string-not-empty", "string-is-more-than-2"], Value: props.currentLibraryTemplate.Title })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                React.createElement(Form.Label, null,
                    "Description ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(Form.Control, { as: "textarea", 
                    //placeholder="Tell people the purpose of this template"
                    rows: 3, defaultValue: props.currentLibraryTemplate.Description, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ Description: event.target.value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "template-description", Validators: ["string-not-empty", "string-is-more-than-3"], Value: props.currentLibraryTemplate.Description })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-author" },
                React.createElement(Form.Label, null,
                    "Author ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(Form.Control, { placeholder: "Template Author", defaultValue: props.currentLibraryTemplate.Author, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ Author: event.target.value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "template-author", Validators: ["string-not-empty", "string-is-more-than-2"], Value: props.currentLibraryTemplate.Author })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                React.createElement(Form.Label, null,
                    "Template Type ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(FormDropdownSelect, { list: workspaceType, defaultValue: props.currentLibraryTemplate.TemplateType, onChange: function (value) { return dispatch(LibraryTemplateCurrentStore.Update({ TemplateType: value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "template-type", Validators: ["dropdown-has-value"], Value: props.currentLibraryTemplate.TemplateType })),
            React.createElement(Collapse, { in: props.currentLibraryTemplate.TemplateType === "SharePoint", appear: true },
                React.createElement("div", null,
                    React.createElement(Form.Group, { className: "form-group", controlId: "control-sharepoint-type" },
                        React.createElement(Form.Label, null,
                            "SharePoint Template Type ",
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement(FormDropdownSelect, { list: [
                                {
                                    id: "1",
                                    title: "Modern Group",
                                    value: "ModernGroup"
                                },
                                {
                                    id: "2",
                                    title: "Modern Communication",
                                    value: "ModernCommunications"
                                }
                            ], defaultValue: props.currentLibraryTemplate.SharePointTemplateType, onChange: function (value) { return dispatch(LibraryTemplateCurrentStore.Update({ SharePointTemplateType: value })); } }),
                        props.currentLibraryTemplate.TemplateType === "SharePoint" ? (React.createElement(FormValidator, { UniqueIdentifier: "template-sharepoint-type", Validators: ["dropdown-has-value"], Value: props.currentLibraryTemplate.SharePointTemplateType })) : null))),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                React.createElement(Form.Label, null, "Library Category"),
                templateCategories !== undefined ? (React.createElement(FormDropdownSelect, { list: templateCategories, defaultValue: props.currentLibraryTemplate.Category, onChange: function (value) { return dispatch(LibraryTemplateCurrentStore.Update({ Category: value })); } })) : null),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-permission-type" },
                React.createElement(Form.Label, null,
                    "Default Permission Type ",
                    React.createElement("span", { className: "required-astrix" }, "*")),
                React.createElement(FormDropdownSelect, { list: permissionType, 
                    //defaultValue={props.currentLibraryTemplate.permissionType? props.currentLibraryTemplate.permissionType : LibraryTemplateTypes.LibraryTemplatePermissionType.Public}
                    defaultValue: props.currentLibraryTemplate.PermissionType, onChange: function (value) { return dispatch(LibraryTemplateCurrentStore.Update({ PermissionType: value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "template-permission-type", Validators: ["dropdown-has-value"], Value: props.currentLibraryTemplate.PermissionType })),
            React.createElement(Form.Group, { className: "form-group", controlId: "control-position" },
                React.createElement(Form.Label, null, "Position"),
                React.createElement(Form.Control, { type: "number", name: "points", step: "1", defaultValue: props.currentLibraryTemplate.Position, onChange: function (event) { return dispatch(LibraryTemplateCurrentStore.Update({ Position: +event.target.value })); } })),
            React.createElement(FormToggleControl, { checked: props.currentLibraryTemplate.AllowTenantsToUseTemplate, label: "Make this library template available for other tenants:", handleChange: function (checked) { return dispatch(LibraryTemplateCurrentStore.Update({ AllowTenantsToUseTemplate: checked })); } })),
        React.createElement(Col, { md: 6, lg: 7, xl: 8, className: "right-col" },
            React.createElement("div", { className: "preview-images" }, props.currentLibraryTemplate !== undefined && props.currentLibraryTemplate.GUID !== undefined ? (React.createElement(FormImageUploadGallery, { ItemGuid: props.currentLibraryTemplate.GUID, ImageGuids: props.currentLibraryTemplate.ImageJsonData, OnImageGuidsChange: function (guid) {
                    dispatch(LibraryTemplateCurrentStore.Update({ ImageJsonData: guid }));
                }, ApiUrlDelete: "/api/LibraryTemplates/DeleteImage", ApiUrlDownload: "/api/LibraryTemplates/DownloadImage", ApiUrlGet: "/api/LibraryTemplates/LoadImage", ApiUrlUpload: "/api/LibraryTemplates/UploadImage", BeforeUpload: props.ensureTemplateCreated })) : null),
            React.createElement(FormValidator, { UniqueIdentifier: "template-image", Validators: ["string-not-empty"], ErrorMessage: "Please attach an image of the template", ShowAsInfo: true, ShowFirst: true, Value: props.currentLibraryTemplate.ImageJsonData })))
    // </Container>
    );
});
export default TabDetails;
