import * as React from "react";
import * as PageHeader from "app/components/common/page-header";
import { SubtitleButton } from "app/components/common/subtitle";
import { OrchestryPermissionInfoEditorTable } from "./orchestry-permission-info-editor-table-body";
import { OrchestryPermissionInfoEditorFilter } from "./orchestry-permission-info-editor-filter";
import { useOrchestryPermissionInfoEditor } from "./use-orchestry-permission-info-editor.hook";
import { OrchestryPermissionEditorEditModal } from "./orchestry-permission-info-editor-edit-modal";
import "./orchestry-permission-info-editor.scss";
export var OrchestryPermissionInfoEditor = React.memo(function () {
    var _a = useOrchestryPermissionInfoEditor(), permissionInfoItems = _a.permissionInfoItems, setPermissionInfoItems = _a.setPermissionInfoItems, isEditPermissionInfoModalOpen = _a.isEditPermissionInfoModalOpen, setIsEditPermissionInfoModalOpen = _a.setIsEditPermissionInfoModalOpen, selectedPermissionInfoItem = _a.selectedPermissionInfoItem, setSelectedPermissionInfoItem = _a.setSelectedPermissionInfoItem, permissionInfoAction = _a.permissionInfoAction, isOrchestryInternalAdmin = _a.isOrchestryInternalAdmin, onAddPermissionInfoClicked = _a.onAddPermissionInfoClicked, onTableRowClicked = _a.onTableRowClicked, selectedAppType = _a.selectedAppType, setSelectedAppType = _a.setSelectedAppType;
    if (!isOrchestryInternalAdmin) {
        return null;
    }
    return (React.createElement("div", { className: "orchestry-permission-info-editor fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Permission Info", subTitle: "Editor" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(SubtitleButton, { title: "Add Permission Info", onClick: onAddPermissionInfoClicked })),
        React.createElement(OrchestryPermissionEditorEditModal, { isEditPermissionInfoModalOpen: isEditPermissionInfoModalOpen, selectedPermissionInfoItem: selectedPermissionInfoItem, permissionInfoAction: permissionInfoAction, permissionInfoItems: permissionInfoItems, setPermissionInfoItems: setPermissionInfoItems, setSelectedPermissionInfoItem: setSelectedPermissionInfoItem, setIsEditPermissionInfoModalOpen: setIsEditPermissionInfoModalOpen, setSelectedAppType: setSelectedAppType }),
        React.createElement(OrchestryPermissionInfoEditorFilter, { selectedAppType: selectedAppType, setSelectedAppType: setSelectedAppType }),
        React.createElement(OrchestryPermissionInfoEditorTable, { onTableRowClicked: onTableRowClicked, permissionInfoItems: permissionInfoItems, selectedAppType: selectedAppType })));
});
