export var TenantType;
(function (TenantType) {
    /**
     * Not Defined
     */
    TenantType[TenantType["NotDefined"] = 0] = "NotDefined";
    /**
     * Orchestry Internal
     */
    TenantType[TenantType["OrchestryInternal"] = 1] = "OrchestryInternal";
    /**
     * Orchestry Customer
     */
    TenantType[TenantType["OrchestryCustomer"] = 2] = "OrchestryCustomer";
    /**
     * Partner Hub
     */
    TenantType[TenantType["PartnerHub"] = 3] = "PartnerHub";
    /**
     * Partner Sandbox
     */
    TenantType[TenantType["PartnerSandbox"] = 4] = "PartnerSandbox";
    /**
     * Partner Customer
     */
    TenantType[TenantType["PartnerCustomer"] = 5] = "PartnerCustomer";
})(TenantType || (TenantType = {}));
export var TenantLicenseStatus;
(function (TenantLicenseStatus) {
    TenantLicenseStatus["Unlicensed"] = "Unlicensed";
    TenantLicenseStatus["Beacon"] = "Beacon";
    TenantLicenseStatus["OrchestryTrial"] = "OrchestryTrial";
    TenantLicenseStatus["Orchestry"] = "Orchestry";
})(TenantLicenseStatus || (TenantLicenseStatus = {}));
export var ServiceAppPermissionConfiguration;
(function (ServiceAppPermissionConfiguration) {
    ServiceAppPermissionConfiguration["OrchestryReadWriteAll"] = "OrchestryReadWriteAll";
    ServiceAppPermissionConfiguration["OrchestryReadAll"] = "OrchestryReadAll";
})(ServiceAppPermissionConfiguration || (ServiceAppPermissionConfiguration = {}));
