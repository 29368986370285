import { Card, Collapse } from "@mantine/core";
import "@mantine/core/styles.css";
import React from "react";
import { RecommendationCardControlSection } from "./recommendation-card-control-section/recommendation-card-control-section.component";
import { RecommendationCardDetailsSection } from "./recommendation-card-details-section.component";
import { RecommendationCardExpandableSection } from "./recommendation-card-expandable-section/recommendation-card-expandable-section.component";
import clsx from "clsx";
import "./recommendation-card.scss";
export var RecommendationCard = function (_a) {
    var recommendation = _a.recommendation, badgeOnClick = _a.badgeOnClick, highlightSearchWords = _a.highlightSearchWords, currentTab = _a.currentTab, isExpandable = _a.isExpandable;
    var _b = React.useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = React.useState(false), renderExpandableSection = _c[0], setrenderExpandableSection = _c[1];
    var onCardClick = function () {
        if (isExpandable) {
            setIsExpanded(!isExpanded);
        }
    };
    return (React.createElement(Card, { onMouseEnter: function () { return setrenderExpandableSection(true); }, onClick: onCardClick, className: clsx("recommendation-card", { "is-expandable": isExpandable }), radius: "md", "data-padding-size": "lg" },
        React.createElement("div", { className: "top-section" },
            React.createElement(RecommendationCardDetailsSection, { isExpandable: isExpandable, currentTab: currentTab, badgeOnClick: badgeOnClick, recommendation: recommendation, highlightSearchWords: highlightSearchWords }),
            React.createElement(RecommendationCardControlSection, { recommendation: recommendation, isExpanded: isExpanded, isExpandable: isExpandable })),
        React.createElement(Collapse, { in: isExpanded, transitionTimingFunction: "ease", transitionDuration: 300 }, renderExpandableSection && React.createElement(RecommendationCardExpandableSection, { recommendation: recommendation, isCardExpanded: isExpanded }))));
};
