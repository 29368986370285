import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { defaultResultOptions } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { usePartnerToolsApi } from "./use-partner-tools-api.hook";
import { usePartnerToolsFilters } from "./use-partner-tools-filters.hook";
import { usePartnerToolsTable } from "./use-partner-tools-table.hook";
export var usePartnerToolsPage = function () {
    var intl = useIntl();
    var history = useHistory();
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var shouldShowSettingsButton = currentUserHasRole(OrchRole.PartnerHubAdmin) || currentUserHasRole(OrchRole.PartnerAdmin);
    var _a = usePartnerToolsFilters(), licenseTypeFilters = _a.licenseTypeFilters, setLicenseTypeFilters = _a.setLicenseTypeFilters, clearAllFilters = _a.clearAllFilters, removeFilter = _a.removeFilter, appliedFilters = _a.appliedFilters, installDateFilter = _a.installDateFilter, setInstallDateFilter = _a.setInstallDateFilter, renewalDateFilter = _a.renewalDateFilter, setRenewalDateFilter = _a.setRenewalDateFilter, onApplyFilters = _a.onApplyFilters, insightFilters = _a.insightFilters;
    var _b = usePartnerToolsApi(appliedFilters), pagedMspTenants = _b.pagedMspTenants, totalMspTenantsCount = _b.totalMspTenantsCount, pageTotal = _b.pageTotal, isPageLoading = _b.isPageLoading, isTableLoading = _b.isTableLoading, sorting = _b.sorting, setSorting = _b.setSorting, activePage = _b.activePage, setActivePage = _b.setActivePage, resultsPerPage = _b.resultsPerPage, setResultsPerPage = _b.setResultsPerPage, searchTextInputValue = _b.searchTextInputValue, onSearchBoxChange = _b.onSearchBoxChange, onSearchBoxKeyDown = _b.onSearchBoxKeyDown, onSearchBoxXClicked = _b.onSearchBoxXClicked, highlightSearchWords = _b.highlightSearchWords;
    var table = usePartnerToolsTable(pagedMspTenants, sorting, setSorting, isPageLoading, isTableLoading, highlightSearchWords).table;
    var onSettingsButtonClicked = function () { return history.push("/partner-tools/settings"); };
    return {
        intl: intl,
        table: table,
        isPageLoading: isPageLoading,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        licenseTypeFilters: licenseTypeFilters,
        setLicenseTypeFilters: setLicenseTypeFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        totalMspTenantsCount: totalMspTenantsCount,
        pageTotal: pageTotal,
        searchTextInputValue: searchTextInputValue,
        pageOptions: defaultResultOptions,
        onSettingsButtonClicked: onSettingsButtonClicked,
        installDateFilter: installDateFilter,
        setInstallDateFilter: setInstallDateFilter,
        renewalDateFilter: renewalDateFilter,
        setRenewalDateFilter: setRenewalDateFilter,
        onApplyFilters: onApplyFilters,
        appliedFilters: appliedFilters,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        shouldShowSettingsButton: shouldShowSettingsButton,
        insightFilters: insightFilters
    };
};
