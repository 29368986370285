import { useCallback, useState } from "react";
/**
 * Hook to store a value and generate callbacks for setting the value to true or false.
 *
 * @param initialState - Initial value
 * @returns Array with the current value and an object containing the updater callbacks.
 */
export function useBoolean(initialState) {
    var _a = useState(initialState), value = _a[0], setValue = _a[1];
    var setTrue = useCallback(function () {
        setValue(true);
    }, []);
    var setFalse = useCallback(function () {
        setValue(false);
    }, []);
    var toggle = useCallback(function () {
        setValue(function (prevValue) { return !prevValue; });
    }, []);
    return [value, { setTrue: setTrue, setFalse: setFalse, toggle: toggle }];
}
