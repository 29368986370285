import { FormValidationContext } from "app/components/common/form-validation";
import { useGetPartnersSettingsQuery, useUpdatePartnersSettingsMutation } from "appv2/api/partners-settings/partners-settings.api";
import { PartnerSettingsConsentControl } from "appv2/pages/settings/partners/partner-settings-consent-control.component";
import { PartnerConnectionSecretDescription } from "appv2/pages/settings/partners/partners-settings-control-descriptions.component";
import { SecretInputControl } from "appv2/pages/settings/partners/secret-input-control.component";
import { usePartnersSettingsPage } from "appv2/pages/settings/partners/use-partners-settings-page.hook";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { InstallWizardContext } from "../..";
import "./partner-association.component.scss";
var InstallerStepPartnerAssociation = React.memo(function (props) {
    var _a, _b;
    var dispatch = useDispatch();
    var iwc = React.useContext(InstallWizardContext);
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _c = React.useState(false), isPageValid = _c[0], setIsPageValid = _c[1];
    var _d = React.useState(false), isSecretFound = _d[0], setIsSecretFound = _d[1];
    var _e = React.useState(false), associationLoading = _e[0], setAssociationLoading = _e[1];
    var _f = React.useState(null), previousPartnerFormData = _f[0], setPreviousPartnerFormData = _f[1];
    var _g = useUpdatePartnersSettingsMutation(), updatePartnerSettings = _g[0], isUpdatePartnerSettingsSuccess = _g[1].isSuccess;
    var _h = useGetPartnersSettingsQuery(), partnersSettings = _h.data, isPartnerSettingsLoading = _h.isLoading, isPartnerSettingsFetching = _h.isFetching, isPartnersSettingsSuccess = _h.isSuccess, refetchPartnerSettings = _h.refetch;
    var _j = usePartnersSettingsPage(), isSaveDisabled = _j.isSaveDisabled, isPageLoading = _j.isPageLoading, form = _j.form, handleFormSubmit = _j.handleFormSubmit, consentorDisplayName = _j.consentorDisplayName, consentorPhoto = _j.consentorPhoto, isVerifyPartnerSecretLoading = _j.isVerifyPartnerSecretLoading, onSecretInputControlBlur = _j.onSecretInputControlBlur, partnerTenantGuid = _j.partnerTenantGuid, partnerOrganizationName = _j.partnerOrganizationName, showEmail = _j.showEmail, onDisconnectTenantClicked = _j.onDisconnectTenantClicked;
    var secretValue = form.getInputProps("SecretKey").value;
    var isConsented = form.getInputProps("IsConsented").value;
    // Used to detect if the page is valid and update the step progress.
    // If there is no partner key, the page is valid.
    // If the partner key is found and the user has consented, the page is valid.
    React.useEffect(function () {
        var isDone = secretValue == null || secretValue == "" ? true : isSecretFound == true && isConsented == true;
        iwc.setStepProgress(props.step.key, isDone);
        if (isPageValid != isDone) {
            setIsPageValid(isDone);
        }
    }, [isSaveDisabled, secretValue, isSecretFound, isConsented]);
    // Since I don't have a save button on this component I need to detect when the page is valid, and the partner key is found and the user has consented.
    // That's what this does.
    // I still have problems where the page saves 2 times, but I think that's because of the way the form is set up.
    // I don't have time to fix that right now.
    React.useEffect(function () {
        // Use the string of the form values to detect if the form has changed.
        if (form.values.SecretKey != null &&
            form.values.SecretKey != "" &&
            form.values.IsConsented === true &&
            isPageValid == true &&
            isPageLoading === false &&
            isPartnerSettingsLoading == false &&
            isPartnerSettingsFetching === false) {
            updatePartnerSettings(form.values).then(function (updateData) {
                refetchPartnerSettings();
            });
        }
    }, [isPageValid]);
    React.useEffect(function () {
        var isLoading = isPageLoading || isPartnerSettingsFetching || isPartnerSettingsLoading;
        setTimeout(function () {
            if (isLoading === isPageLoading || isPartnerSettingsFetching || isPartnerSettingsLoading) {
                setAssociationLoading(isLoading);
            }
        }, 500);
    }, [isPageLoading, isPartnerSettingsFetching, isPartnerSettingsLoading]);
    return (React.createElement("div", { className: "installer-step-partner-association fadein-500ms" },
        React.createElement(FormValidationContext, { UniqueIdentifier: "InstallerStepPartnerAssociation", OnChangeIsValid: function (isValid) {
                setIsPageValid(isValid);
            } },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 12, lg: 10, xl: 8 },
                    React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
                        React.createElement("div", { className: "kt-heading kt-heading--md" },
                            React.createElement("h2", null, "Partner Association")),
                        React.createElement("div", { className: "description" },
                            "In the case of a partner-led installation, elect to associate your Orchestry installation to your partner, allowing them the ability to oversee your implementation.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "Note: This setting can also be enabled post-installation."),
                        React.createElement(Form.Group, { controlId: "control-partner-secret", className: "ob-form-group-top" },
                            React.createElement(Form.Label, null, "Partner Connection Secret:"),
                            React.createElement("div", { className: "partner-description" },
                                React.createElement(PartnerConnectionSecretDescription, null)),
                            React.createElement("div", { className: "partner-secret-control" },
                                React.createElement(SecretInputControl, { isLoading: associationLoading, value: (_a = form.getInputProps("SecretKey").value) !== null && _a !== void 0 ? _a : "", onChange: function (value) { return form.setFieldValue("SecretKey", value); }, onBlur: onSecretInputControlBlur, isError: secretValue == null || secretValue == "" ? false : form.getInputProps("SecretKey").error, isLookupLoading: isVerifyPartnerSecretLoading, tenantGuid: partnerTenantGuid, partnerOrganizationName: partnerOrganizationName, onSecretFoundChange: function (isSecretFound) { return setIsSecretFound(isSecretFound); } }))),
                        React.createElement(Collapse, { appear: true, in: secretValue !== null && secretValue !== "" },
                            React.createElement(Form.Group, { controlId: "control-partner-consent", className: "ob-form-group-bottom" },
                                React.createElement(Form.Label, null, "Consent"),
                                React.createElement("div", { className: "description" }, "Consent to your partner (identified above) having access to basic information about your tenant, including license status and aggregated Orchestry usage."),
                                React.createElement("div", { className: "partner-settings-consentcontrol" },
                                    React.createElement(PartnerSettingsConsentControl, { customerTenantName: (_b = currentTenant === null || currentTenant === void 0 ? void 0 : currentTenant.Name) !== null && _b !== void 0 ? _b : "", partnerHubTenantName: partnerOrganizationName !== null && partnerOrganizationName !== void 0 ? partnerOrganizationName : "", isLoading: associationLoading, consentedBy: consentorDisplayName, consentedDate: form.getInputProps("ConsentedDate").value, consentorPhoto: consentorPhoto !== null && consentorPhoto !== void 0 ? consentorPhoto : undefined, onClick: function () {
                                            form.setFieldValue("IsConsented", !form.getInputProps("IsConsented").value);
                                        }, isConsented: form.getInputProps("IsConsented").value, error: form.getInputProps("IsConsented").error, onDisconnectTenantClicked: onDisconnectTenantClicked }))))))))));
});
export default InstallerStepPartnerAssociation;
