import * as DocumentTemplateCurrentStore from "app/store/document-templates/current";
import { AsyncCallType } from "app/store/async/types";
export var documentTemplateCurrentActionMap = [
    {
        actionType: DocumentTemplateCurrentStore.DocumentTemplateCurrentActionTypes.DocumentTemplate_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "documentTemplateListState.items",
        keyType: "guid",
        ApiUrl: "/api/DocumentTemplates/?guid={key}",
        responseDataType: {}
    }
];
