export var popupCenter = function (url, title, w, h) {
    var left = window.screen.width / 2 - w / 2;
    var top = window.screen.height / 2 - h / 2;
    return window.open(url, title, "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
    // // Fixes dual-screen position                             Most browsers      Firefox
    // const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    // const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
    // //@ts-ignore
    // const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    // //@ts-ignore
    // const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
    // const systemZoom = width / window.screen.availWidth;
    // const left = (width - w) / 2 / systemZoom + dualScreenLeft
    // const top = (height - h) / 2 / systemZoom + dualScreenTop
    // const newWindow = window.open(url, title,
    //   `
    //   scrollbars=yes,
    //   width=${w / systemZoom},
    //   height=${h / systemZoom},
    //   top=${top},
    //   left=${left}
    //   `
    // )
    // //@ts-ignore
    // if (window.focus) newWindow.focus();
    // return newWindow;
};
