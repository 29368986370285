import { CopyButton } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryTextInput } from "../orchestry-text-input/orchestry-text-input.component";
import "./orchestry-readonly-copy-control.scss";
export var OrchestryReadOnlyCopyControl = function (_a) {
    var value = _a.value, isLoading = _a.isLoading;
    return (React.createElement("div", { className: "orchestry-read-only-copy-control" },
        React.createElement(CopyButton, { value: value }, function (_a) {
            var copied = _a.copied, copy = _a.copy;
            return copied ? (React.createElement(OrchestryTextInput, { style: { caretColor: "transparent" }, isLoading: isLoading, c: "text-tertiary.0", fw: "300", fz: "14px", lh: "20px", rightSection: React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.check, color: "var(--mantine-color-gray-9)", fontSize: "16px", lineHeight: "16px" }), onClick: copy, value: value, onChange: function () { } })) : (React.createElement(OrchestryTextInput, { style: { caretColor: "transparent" }, isLoading: isLoading, c: "text-tertiary.0", fw: "300", fz: "14px", lh: "20px", rightSection: React.createElement(OrchestryFontIcon, { onClick: copy, iconClassName: ORCH_ICONS.copy05, color: "var(--mantine-color-gray-9)", fontSize: "16px", lineHeight: "16px" }), onClick: copy, value: value, onChange: function () { } }));
        })));
};
