import { ReportAggregateType } from "app/store/lifecycle-report";
import * as React from "react";
var ReportColumnNumberAggregate = React.memo(function (props) {
    var _a;
    var propertyName = props.col.ProperyName;
    switch (props.col.AggregateType) {
        case ReportAggregateType.DISTINCT:
            {
                propertyName = propertyName + "Distinct";
            }
            break;
        case ReportAggregateType.COUNT:
            {
                propertyName = propertyName + "Count";
            }
            break;
        case ReportAggregateType.SUM:
            {
                propertyName = propertyName + "Sum";
            }
            break;
        case ReportAggregateType.AVG:
            {
                propertyName = propertyName + "Avg";
            }
            break;
        case ReportAggregateType.MAX:
            {
                propertyName = propertyName + "Max";
            }
            break;
        case ReportAggregateType.MIN:
            {
                propertyName = propertyName + "Min";
            }
            break;
    }
    //console.log("PropertyName is ", propertyName.toString());
    return React.createElement("p", null, (_a = props.item[propertyName]) === null || _a === void 0 ? void 0 : _a.toLocaleString());
});
export default ReportColumnNumberAggregate;
