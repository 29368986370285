import * as ChannelListStore from "app/store/channels/list";
import { AsyncCallType } from "app/store/async/types";
import * as TemplateListTypes from "app/store/templates/list";
export var channelListActionMap = [
    {
        actionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/channels",
        responseDataType: "",
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/channels",
        responseDataType: "",
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/channels",
        responseDataType: true,
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: ChannelListStore.ChannelListActionTypes.Channel_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "channelListState.items",
        keyType: "typeName",
        ApiUrl: "/api/channels",
        responseDataType: []
    }
];
