export var templateDefaults = {
    AllowChannelsCustomization: true,
    AllowFeatureCustomization: true,
    AllowDocumentsCustomization: true,
    AllowAllUsersToRequestTemplate: true,
    IsAvailableAsDirectoryFilter: false,
    ArtifactsUsingThisLibraryTemplate: 0,
    TemplateType: "Team",
    LiveTemplateEnabled: true,
    AssociatedDocumentTemplates: [],
    AssociatedChannels: [],
    AssociatedFeatures: [],
    AllowMemberCreatePrivateChannels: true,
    AllowMemberCreatingAndUpdatingChannels: true,
    AllowMembersToAddAndRemoveApps: true,
    AllowMembersToCreateUpdateAndRemoveConnectors: true,
    AllowMembersToCreateUpdateAndRemoveTabs: true,
    AllowMembersToDeleteAndRestoreChannels: true,
    // AllowMembersToUploadCustomApps:true,
    GiveMembersTheOptionToDeleteTheirMessages: true,
    GiveMembersTheOptionToEditTheirMessages: true,
    LiveTemplatePlannerCloneBuckets: true,
    LiveTemplatePlannerCloneTasks: true,
    LiveTemplatePlannerPrependWorkspaceName: false,
    LiveTemplateTeamCloneChannels: true,
    LiveTemplateTeamCloneTabs: true,
    LiveTemplateTeamCloneWikiTabs: false,
    LiveTemplateTeamCloneMembers: true,
    LiveTemplateTeamCloneSecuritySettings: true,
    LiveTemplateTeamCloneApps: true,
    LiveTemplateTeamCloneSharePointGroupSite: true,
    LiveTemplateSiteClonePages: true,
    LiveTemplateSiteCloneContentInLibs: true,
    SensitivityLabelType: "None",
    TemplateCardHideAdditionalFeatures: false,
    ConfigSPSharingCapability: "",
    ConfigSPSharingDomainRestrictionMode: "None",
    ConfigSPSharingDomainList: "",
    ConfigSPSharingLinkType: "",
    ConfigSPAnonymousLinkExpirationInDays: null,
    ConfigSPLinkPermission: "",
    MetadataCustomizedFieldsEnabled: false,
    TemplateMultiGeoDataLocationSource: "Default"
};
export var vivaTemplateDefaults = {
    AllowChannelsCustomization: false,
    AllowFeatureCustomization: false,
    AllowDocumentsCustomization: false,
    AllowAllUsersToRequestTemplate: true,
    IsAvailableAsDirectoryFilter: false,
    ArtifactsUsingThisLibraryTemplate: 0,
    TemplateType: "VivaEngageCommunity",
    LiveTemplateEnabled: false,
    AssociatedDocumentTemplates: [],
    AssociatedChannels: [],
    AssociatedFeatures: [],
    AllowMemberCreatePrivateChannels: false,
    AllowMemberCreatingAndUpdatingChannels: false,
    AllowMembersToAddAndRemoveApps: false,
    AllowMembersToCreateUpdateAndRemoveConnectors: false,
    AllowMembersToCreateUpdateAndRemoveTabs: false,
    AllowMembersToDeleteAndRestoreChannels: false,
    GiveMembersTheOptionToDeleteTheirMessages: false,
    GiveMembersTheOptionToEditTheirMessages: false,
    LiveTemplatePlannerCloneBuckets: false,
    LiveTemplatePlannerCloneTasks: false,
    LiveTemplatePlannerPrependWorkspaceName: false,
    LiveTemplateTeamCloneChannels: false,
    LiveTemplateTeamCloneTabs: false,
    LiveTemplateTeamCloneWikiTabs: false,
    LiveTemplateTeamCloneMembers: false,
    LiveTemplateTeamCloneSecuritySettings: false,
    LiveTemplateTeamCloneApps: false,
    LiveTemplateTeamCloneSharePointGroupSite: false,
    LiveTemplateSiteClonePages: false,
    LiveTemplateSiteCloneContentInLibs: false,
    SensitivityLabelType: "None",
    TemplateCardHideAdditionalFeatures: true,
    ConfigSPSharingCapability: "",
    ConfigSPSharingDomainRestrictionMode: "None",
    ConfigSPSharingDomainList: "",
    ConfigSPSharingLinkType: "",
    ConfigSPAnonymousLinkExpirationInDays: null,
    ConfigSPLinkPermission: "",
    MetadataCustomizedFieldsEnabled: false
};
