var TeamsDetectionService = /** @class */ (function () {
    function TeamsDetectionService() {
    }
    TeamsDetectionService.isInTeams = null;
    TeamsDetectionService.IsInTeamsApp = function () {
        if (TeamsDetectionService.isInTeams == null) {
            TeamsDetectionService.isInTeams = document.getElementsByTagName("html")[0].classList.contains("in-teams");
        }
        return TeamsDetectionService.isInTeams;
    };
    return TeamsDetectionService;
}());
export default TeamsDetectionService;
