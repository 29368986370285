// import { MiddlewareFunction} from 'app/store/middleware'
import { NamingPolicyCurrentActionTypes } from "./types";
export function Load(namingPolicy) {
    if (namingPolicy === undefined)
        namingPolicy = {};
    //console.log("Load Current NamingPolicy > ", namingPolicy)
    return {
        type: NamingPolicyCurrentActionTypes.NamingPolicy_Current_Load,
        payload: namingPolicy
    };
}
export function LoadFromServer(namingPolicy) {
    if (namingPolicy === undefined)
        namingPolicy = {};
    return {
        type: NamingPolicyCurrentActionTypes.NamingPolicy_Current_LoadFromServer,
        payload: namingPolicy
    };
}
export function Update(namingPolicy) {
    return {
        type: NamingPolicyCurrentActionTypes.NamingPolicy_Current_Update,
        payload: namingPolicy
    };
}
export function Clear() {
    return {
        type: NamingPolicyCurrentActionTypes.NamingPolicy_Current_Clear
    };
}
export var namingPolicyCurrentMiddlware = function (store, next, action) {
    if (action.type === NamingPolicyCurrentActionTypes.NamingPolicy_Current_Load) {
        // (action.payload as NamingPolicy).AssociatedFeatures?.forEach((feat:NamingPolicyBaseAssociation) => {
        // })
        // // action.payload.forEach((feat:Feature) => {
        // //     if(feat.icon !== undefined)
        // //         feat.icon = fontAwesomeHelper.getIconFromName(feat.icon?.iconName);
        // // });
        // console.log("Middleware", action.payload);
        next(action);
    }
    else {
        next(action);
    }
};
