import { GuestMembershipStatusMap } from "app/store/lifecycle-report";
import * as React from "react";
import "./report-column-guest-membership-status.scss";
import { useIntl } from "react-intl";
var ReportColumnGuestMembershipStatus = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var intl = useIntl();
    return (React.createElement("div", { className: "report-column-guest-membership-status", "data-pw": "guest-membership-status" },
        React.createElement("div", { className: "guest-".concat(props.Item.MembershipStatus !== undefined
                ? props.Item.MembershipStatus
                : ((_b = (_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.GuestMembershipDetails) === null || _b === void 0 ? void 0 : _b.MembershipStatus) !== undefined
                    ? (_d = (_c = props.Item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.GuestMembershipDetails) === null || _d === void 0 ? void 0 : _d.MembershipStatus
                    : "") }, props.Item.MembershipStatus !== undefined
            ? intl.formatMessage({ id: GuestMembershipStatusMap[props.Item.MembershipStatus] })
            : ((_f = (_e = props.Item.ExtendedProperties) === null || _e === void 0 ? void 0 : _e.GuestMembershipDetails) === null || _f === void 0 ? void 0 : _f.MembershipStatus) !== undefined
                ? intl.formatMessage({ id: GuestMembershipStatusMap[(_h = (_g = props.Item.ExtendedProperties) === null || _g === void 0 ? void 0 : _g.GuestMembershipDetails) === null || _h === void 0 ? void 0 : _h.MembershipStatus] })
                : null)));
});
export default ReportColumnGuestMembershipStatus;
