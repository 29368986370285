var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./lifecycle-stats.scss";
import { useSelector } from "react-redux";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { ResponsiveLine } from "@nivo/line";
import { Portlet } from "app/components/common/portlet";
import moment from "moment";
import { Col } from "react-bootstrap";
import formatDataSize from "app/utils/formatDataSize";
var LifecycleStats = React.memo(function (_a) {
    var property = _a.property, inCol = _a.inCol, _b = _a.colMd, colMd = _b === void 0 ? 4 : _b, _c = _a.colLg, colLg = _c === void 0 ? 4 : _c;
    var _d = React.useState(), chartData = _d[0], setChartData = _d[1];
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var reportStats = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.stats; });
    React.useEffect(function () {
        if (reportItem !== undefined && reportStats !== undefined) {
            setChartData(getStatsData());
        }
    }, [reportItem, reportStats]);
    var getStatsData = function () {
        var dataArray = [];
        var groupOwners = {
            id: "Owners",
            icon: "fa fa-users",
            color: "#BA7475",
            property: "GroupOwnersCount",
            data: []
        };
        var groupMembers = {
            id: "Members",
            icon: "fa fa-users",
            color: "#AC5F92",
            property: "GroupMembersCount",
            data: []
        };
        var groupGuests = {
            id: "Guests",
            icon: "fa fa-users",
            color: "#A064AE",
            property: "GroupGuestsCount",
            description: "with access enabled",
            data: []
        };
        var siteOwners = {
            id: "Owners",
            icon: "fa fa-users",
            color: "#BA7475",
            property: "SiteOwnersCount",
            data: []
        };
        var siteMembers = {
            id: "Members",
            icon: "fa fa-users",
            color: "#AC5F92",
            property: "SiteMembersCount",
            data: []
        };
        var siteGuests = {
            id: "Guests",
            icon: "fa fa-users",
            color: "#A064AE",
            property: "SiteGuestCount",
            data: []
        };
        var teamMessages = {
            id: "Teams Messages",
            icon: "far fa-comments",
            color: "#BAA8DC",
            property: "TeamMessagesCount",
            data: []
        };
        var groupConversations = {
            id: "Group Conversations",
            icon: "far fa-comments",
            color: "#8C8ACA",
            property: "GroupConversationCount",
            data: []
        };
        var groupEvents = {
            id: "Meetings",
            icon: "far fa-comments",
            color: "#8FAADF",
            property: "GroupEventsCount",
            data: []
        };
        var teamChannels = {
            id: "Channels",
            icon: "fas fa-stream",
            color: "#C7E8FD",
            property: "TeamChannelsCount",
            data: []
        };
        var teamTabs = {
            id: "Tabs",
            icon: "fas fa-window-minimize",
            color: "#76B3A8",
            property: "TeamTabsCount",
            data: []
        };
        var totalFileCount = {
            id: "Documents",
            icon: "far fa-folder-open",
            color: "#6C8D6E",
            property: "SharePointTotalFileCount",
            data: []
        };
        var manualFileCount = {
            id: "Documents",
            icon: "far fa-folder-open",
            color: "#6C8D6E",
            property: "SharePointManualFileCount",
            data: []
        };
        var storage = {
            id: "Storage",
            icon: "fa fa-database",
            color: "#CEDFA3",
            property: "SharePointSiteStorageUsed",
            data: []
        };
        var pageViews = {
            id: "Page Views",
            description: "last 30 days",
            icon: "fa fa-database",
            color: "#eff1b2",
            property: "SharePointPageViewCount",
            data: []
        };
        var pageVisits = {
            id: "Page Visits",
            description: "last 30 days",
            icon: "fa fa-database",
            color: "#EDDE88",
            property: "SharePointVisitedPageCount",
            data: []
        };
        var filesViewedEdited = {
            id: "Files Viewed/Edited",
            description: "last 30 days",
            icon: "fa fa-database",
            color: "#E3B581",
            property: "SharePointViewEditFileCount",
            data: []
        };
        function orderHistoryByDateDesc(a, b) {
            if (a.x < b.x) {
                return 1;
            }
            if (a.x > b.x) {
                return -1;
            }
            return 0;
        }
        var populateStat = function (dataGroup) {
            try {
                if (reportStats !== null && property === dataGroup.property) {
                    var statArray = reportStats[dataGroup.property];
                    if (Array.isArray(statArray)) {
                        statArray.forEach(function (s) {
                            dataGroup.data.push({ x: new Date(s.Date), y: s.Count });
                        });
                        dataGroup.data = dataGroup.data.sort(orderHistoryByDateDesc);
                    }
                }
            }
            catch (_a) { }
        };
        if (reportStats != null) {
            populateStat(groupOwners);
            populateStat(groupMembers);
            populateStat(groupGuests);
            populateStat(totalFileCount);
            populateStat(manualFileCount);
            populateStat(storage);
            if ((reportItem === null || reportItem === void 0 ? void 0 : reportItem.Application) !== "VivaEngageCommunity") {
                populateStat(pageViews);
                populateStat(pageVisits);
                populateStat(filesViewedEdited);
                populateStat(groupConversations);
                populateStat(groupEvents);
                populateStat(teamMessages);
                populateStat(teamChannels);
                populateStat(teamTabs);
                populateStat(siteOwners);
                populateStat(siteMembers);
                populateStat(siteGuests);
            }
        }
        // reportStats?.forEach((rs) => {
        //    var date = rs.CrawledDate;
        //    if (rs.SharePointPageViewCount !== null && property === "SharePointPageViewCount")
        //       pageViews.data.push({ x: new Date(date), y: rs.SharePointPageViewCount });
        //    if (rs.SharePointVisitedPageCount !== null && property === "SharePointVisitedPageCount")
        //       pageVisits.data.push({ x: new Date(date), y: rs.SharePointVisitedPageCount });
        //    if (rs.SharePointViewEditFileCount !== null && property === "SharePointViewEditFileCount")
        //       filesViewedEdited.data.push({ x: new Date(date), y: rs.SharePointViewEditFileCount });
        //    if (rs.GroupOwnersCount !== null && property === "GroupOwnersCount")
        //       groupOwners.data.push({ x: new Date(date), y: rs.GroupOwnersCount });
        //    if (rs.GroupMembersCount !== null && property === "GroupMembersCount")
        //       groupMembers.data.push({ x: new Date(date), y: rs.GroupMembersCount });
        //    if (rs.GroupGuestsCount !== null && property === "GroupGuestsCount")
        //       groupGuests.data.push({ x: new Date(date), y: rs.GroupGuestsCount });
        //    if (rs.GroupConversationCount !== null && property === "GroupConversationCount")
        //       groupConversations.data.push({ x: new Date(date), y: rs.GroupConversationCount });
        //    if (rs.GroupEventsCount !== null && property === "GroupEventsCount")
        //       groupEvents.data.push({ x: new Date(date), y: rs.GroupEventsCount });
        //    if (rs.TeamMessagesCount !== null && property === "TeamMessagesCount")
        //       teamMessages.data.push({ x: new Date(date), y: rs.TeamMessagesCount });
        //    if (rs.TeamChannelsCount !== null && property === "TeamChannelsCount")
        //       teamChannels.data.push({ x: new Date(date), y: rs.TeamChannelsCount });
        //    if (rs.TeamTabsCount !== null && property === "TeamTabsCount")
        //       teamTabs.data.push({ x: new Date(date), y: rs.TeamTabsCount });
        //    if (rs.SharePointTotalFileCount !== null && property === "SharePointTotalFileCount")
        //       totalFileCount.data.push({ x: new Date(date), y: rs.SharePointTotalFileCount });
        //    if (rs.SharePointSiteStorageUsed !== null && property === "SharePointSiteStorageUsed")
        //       storage.data.push({ x: new Date(date), y: rs.SharePointSiteStorageUsed });
        //    if (rs.SiteOwnersCount !== null && property === "SiteOwnersCount")
        //       siteOwners.data.push({ x: new Date(date), y: rs.SiteOwnersCount });
        //    if (rs.SiteMembersCount !== null && property === "SiteMembersCount")
        //       siteMembers.data.push({ x: new Date(date), y: rs.SiteMembersCount });
        //    if (rs.SiteGuestCount !== null && property === "SiteGuestCount")
        //       siteGuests.data.push({ x: new Date(date), y: rs.SiteGuestCount });
        // })
        if (groupOwners.data.length > 0) {
            if (groupOwners.data.length === 1) {
                groupOwners.data = __spreadArray(__spreadArray([], groupOwners.data, true), [
                    {
                        x: moment(groupOwners.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: groupOwners.data[0].y
                    }
                ], false);
            }
            dataArray.push(groupOwners);
        }
        if (groupMembers.data.length > 0) {
            if (groupMembers.data.length === 1) {
                groupMembers.data = __spreadArray(__spreadArray([], groupMembers.data, true), [
                    {
                        x: moment(groupMembers.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: groupMembers.data[0].y
                    }
                ], false);
            }
            dataArray.push(groupMembers);
        }
        if (groupGuests.data.length > 0) {
            if (groupGuests.data.length === 1) {
                groupGuests.data = __spreadArray(__spreadArray([], groupGuests.data, true), [
                    {
                        x: moment(groupGuests.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: groupGuests.data[0].y
                    }
                ], false);
            }
            dataArray.push(groupGuests);
        }
        if (groupConversations.data.length > 0) {
            if (groupConversations.data.length === 1) {
                groupConversations.data = __spreadArray(__spreadArray([], groupConversations.data, true), [
                    {
                        x: moment(groupConversations.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: groupConversations.data[0].y
                    }
                ], false);
            }
            dataArray.push(groupConversations);
        }
        if (groupEvents.data.length > 0) {
            if (groupEvents.data.length === 1) {
                groupEvents.data = __spreadArray(__spreadArray([], groupEvents.data, true), [
                    {
                        x: moment(groupEvents.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: groupEvents.data[0].y
                    }
                ], false);
            }
            dataArray.push(groupEvents);
        }
        if (teamMessages.data.length > 0) {
            if (teamMessages.data.length === 1) {
                teamMessages.data = __spreadArray(__spreadArray([], teamMessages.data, true), [
                    {
                        x: moment(teamMessages.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: teamMessages.data[0].y
                    }
                ], false);
            }
            dataArray.push(teamMessages);
        }
        if (teamChannels.data.length > 0) {
            if (teamChannels.data.length === 1) {
                teamChannels.data = __spreadArray(__spreadArray([], teamChannels.data, true), [
                    {
                        x: moment(teamChannels.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: teamChannels.data[0].y
                    }
                ], false);
            }
            dataArray.push(teamChannels);
        }
        if (teamTabs.data.length > 0) {
            if (teamTabs.data.length === 1) {
                teamTabs.data = __spreadArray(__spreadArray([], teamTabs.data, true), [
                    {
                        x: moment(teamTabs.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: teamTabs.data[0].y
                    }
                ], false);
            }
            dataArray.push(teamTabs);
        }
        if (siteOwners.data.length > 0) {
            if (siteOwners.data.length === 1) {
                siteOwners.data = __spreadArray(__spreadArray([], siteOwners.data, true), [
                    {
                        x: moment(siteOwners.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: siteOwners.data[0].y
                    }
                ], false);
            }
            dataArray.push(siteOwners);
        }
        if (siteMembers.data.length > 0) {
            if (siteMembers.data.length === 1) {
                siteMembers.data = __spreadArray(__spreadArray([], siteMembers.data, true), [
                    {
                        x: moment(siteMembers.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: siteMembers.data[0].y
                    }
                ], false);
            }
            dataArray.push(siteMembers);
        }
        if (siteGuests.data.length > 0) {
            if (siteGuests.data.length === 1) {
                siteGuests.data = __spreadArray(__spreadArray([], siteGuests.data, true), [
                    {
                        x: moment(siteGuests.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: siteGuests.data[0].y
                    }
                ], false);
            }
            dataArray.push(siteGuests);
        }
        if (totalFileCount.data.length > 0) {
            if (totalFileCount.data.length === 1) {
                totalFileCount.data = __spreadArray(__spreadArray([], totalFileCount.data, true), [
                    {
                        x: moment(totalFileCount.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: totalFileCount.data[0].y
                    }
                ], false);
            }
            dataArray.push(totalFileCount);
        }
        if (manualFileCount.data.length > 0) {
            if (manualFileCount.data.length === 1) {
                manualFileCount.data = __spreadArray(__spreadArray([], manualFileCount.data, true), [
                    {
                        x: moment(manualFileCount.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: manualFileCount.data[0].y
                    }
                ], false);
            }
            dataArray.push(manualFileCount);
        }
        if (storage.data.length > 0) {
            if (storage.data.length === 1) {
                storage.data = __spreadArray(__spreadArray([], storage.data, true), [
                    {
                        x: moment(storage.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: storage.data[0].y
                    }
                ], false);
            }
            dataArray.push(storage);
        }
        if (pageViews.data.length > 0) {
            if (pageViews.data.length === 1) {
                pageViews.data = __spreadArray(__spreadArray([], pageViews.data, true), [
                    {
                        x: moment(pageViews.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: pageViews.data[0].y
                    }
                ], false);
            }
            dataArray.push(pageViews);
        }
        if (pageVisits.data.length > 0) {
            if (pageVisits.data.length === 1) {
                pageVisits.data = __spreadArray(__spreadArray([], pageVisits.data, true), [
                    {
                        x: moment(pageVisits.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: pageVisits.data[0].y
                    }
                ], false);
            }
            dataArray.push(pageVisits);
        }
        if (filesViewedEdited.data.length > 0) {
            if (filesViewedEdited.data.length === 1) {
                filesViewedEdited.data = __spreadArray(__spreadArray([], filesViewedEdited.data, true), [
                    {
                        x: moment(filesViewedEdited.data[0].x)
                            .add(1, "day")
                            .toDate(),
                        y: filesViewedEdited.data[0].y
                    }
                ], false);
            }
            dataArray.push(filesViewedEdited);
        }
        return dataArray;
    };
    var GetLegendData = function (what) {
        return React.createElement("path", { d: "", fill: "#000000", style: { mixBlendMode: "multiply", pointerEvents: "none" }, opacity: 0.5 });
    };
    var GetMostResentStat = function () {
        if (reportStats !== undefined && Array.isArray(reportStats) && reportStats.length > 0) {
            return reportStats[reportStats.length - 1];
        }
        else {
            return null;
        }
    };
    var RootData = function () {
        if (getStatsData().length > 0) {
            return getStatsData()[0];
        }
        //@ts-ignore
        return null;
    };
    var GetLastCount = function () {
        var rootData = RootData();
        if (rootData !== null) {
            // console.log("RootData", rootData, rootData.data[rootData.data.length - 1].y);
            return rootData.data[0].y;
        }
        return 0;
    };
    var render = function () { return (React.createElement("div", { className: "lifecycle-stats", "data-pw": "lifecycle-stats" },
        React.createElement(Portlet, null, chartData === undefined ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "" },
                RootData() !== null ? (React.createElement("div", { className: "top-area" },
                    React.createElement("div", { className: "icon", "data-pw": "icon" },
                        React.createElement("i", { className: RootData().icon })),
                    React.createElement("div", { className: "right-side" },
                        React.createElement("div", { className: "count", "data-pw": "count" }, property === "SharePointSiteStorageUsed" ? formatDataSize(GetLastCount()) : GetLastCount()),
                        React.createElement("div", { className: "title", "data-pw": "title" }, RootData().id),
                        RootData().description !== undefined ? React.createElement("div", { className: "description", "data-pw": "description" }, RootData().description) : null))) : null,
                React.createElement("div", { className: "stats-box" }, getStatsData().length === 1 && GetLastCount() !== 0 ? (React.createElement(ResponsiveLine, { data: getStatsData(), sliceTooltip: function (_a) {
                        var slice = _a.slice;
                        return (React.createElement("div", { className: "single-stat-slice-tooltip" }, slice.points.map(function (point) { return (React.createElement("div", { className: "slice-point", key: point.id, style: { color: point.serieColor } },
                            React.createElement("div", { className: "title" },
                                React.createElement("span", null, point.serieId),
                                React.createElement("div", { className: "over-last" }, "Last 60 days")),
                            React.createElement("div", { className: "count" }, property === "SharePointSiteStorageUsed" ? formatDataSize(point.data.y) : point.data.y),
                            React.createElement("div", { className: "date" }, moment(point.data.x).format("LL")))); })));
                    }, margin: { top: 100, right: 0, bottom: 0, left: 0 }, xScale: {
                        type: "time",
                        format: "native",
                        //    useUTC: false,
                        precision: "day"
                    }, yScale: { type: "linear", min: 0, max: "auto", stacked: false, reverse: false }, yFormat: " >-.2f", curve: "monotoneX", theme: {
                        grid: {
                            line: {
                                stroke: "#F2F4F7",
                                strokeWidth: 0
                            }
                        },
                        legends: {
                            text: { fontFamily: '"TT Norms", Helvetica, sans-serif', fontWeight: 500 }
                        },
                        labels: {
                            text: { fontFamily: '"TT Norms", Helvetica, sans-serif', fontWeight: 500 }
                        }
                    }, axisTop: null, axisRight: null, axisBottom: null, axisLeft: null, enableGridX: false, colors: function (d) { return d.color; }, lineWidth: 2, enablePoints: false, pointSize: 10, pointColor: { theme: "background" }, pointBorderWidth: 2, pointBorderColor: { from: "serieColor" }, pointLabelYOffset: -12, enableArea: true, areaOpacity: 0.3, enableSlices: "x", layers: ["grid", "markers", "axes", "areas", "crosshair", "lines", "points", "slices", "mesh", "legends", GetLegendData] })) : (React.createElement("div", { className: "empty-chart" },
                    React.createElement("div", { className: "line", style: { backgroundColor: RootData().color } })))))))))); };
    return chartData === undefined ? (inCol !== undefined && inCol === true ? (React.createElement(Col, { lg: colLg, md: colMd },
        React.createElement(SkeletonLoaderPortlet, { bodyHeight: 175, showHeader: false },
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 })))) : (React.createElement(SkeletonLoaderPortlet, { bodyHeight: 175, showHeader: false },
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 })))) : getStatsData().length > 0 ? (inCol !== undefined && inCol === true ? (React.createElement(Col, { lg: colLg, md: colMd }, render())) : (render())) : null;
});
export default LifecycleStats;
