var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LifecycleGuestCurrentActionTypes } from "./types";
import { cloneDeep } from "lodash";
import { AsyncActionTypes } from "app/store/async/types";
var initialState = {
    sqlItem: null,
    graphItem: null,
    cosmosItem: null,
    daysInactive: 0,
    history: {
        Access: null,
        Creation: null,
        Review: null,
        Counts: {
            Access: null,
            Creation: null,
            Review: null
        }
    },
    historyInitialLoadDone: false,
    initialLoadDone: false
};
window.LifecycleGuestsCurrentReducerUpdating = false;
export function lifecycleGuestReducer(state, action) {
    //console.log("LifecycleGuestsCurrentReducerUpdating", window.LifecycleGuestsCurrentReducerUpdating);
    if (state === void 0) { state = initialState; }
    if (action.type === AsyncActionTypes.Async_Start || action.type === AsyncActionTypes.Async_Succeed) {
        return state;
    }
    var getState = function () {
        switch (action.type) {
            case LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load: {
                return __assign(__assign({}, state), { sqlItem: action.payload.sqlItem, graphItem: action.payload.graphItem, daysInactive: action.payload.daysInactive, initialLoadDone: true });
            }
            case LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_With_CosmosItem: {
                return __assign(__assign({}, state), { sqlItem: action.payload.sqlItem, graphItem: action.payload.graphItem, daysInactive: action.payload.daysInactive, cosmosItem: action.payload.cosmosItem, initialLoadDone: true });
            }
            case LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_CosmosItem: {
                return __assign(__assign({}, state), { cosmosItem: action.payload });
            }
            case LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_CosmosItem_From_Memory: {
                return __assign(__assign({}, state), { cosmosItem: __assign({}, action.payload) });
            }
            case LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Load_History: {
                //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
                var historyState = cloneDeep(state.history);
                if (action.payload.PagingType === "all") {
                    historyState.Counts = action.payload.Counts;
                }
                if (action.payload.PagingType === "all" || action.payload.PagingType === "creation") {
                    historyState.Creation = action.payload.Creation;
                }
                if (action.payload.PagingType === "all" || action.payload.PagingType === "access") {
                    historyState.Access = action.payload.Access;
                }
                if (action.payload.PagingType === "all" || action.payload.PagingType === "review") {
                    historyState.Review = action.payload.Review;
                }
                return __assign(__assign({}, state), { history: historyState, historyInitialLoadDone: true });
            }
            case LifecycleGuestCurrentActionTypes.LifecycleGuest_Current_Clear: {
                return __assign({}, cloneDeep(initialState));
            }
            default:
                return state;
        }
    };
    // const overrideStatusIfNeeded = (state:LifecycleGuestCurrentState, newStatus:string) => {
    // }
    var wait = function () {
        if (window.LifecycleGuestsCurrentReducerUpdating === false) {
            //console.log("wait done " + action.type)
            window.LifecycleGuestsCurrentReducerUpdating = true;
            var newstate = getState();
            window.LifecycleGuestsCurrentReducerUpdating = false;
            return newstate;
        }
        else {
            //console.log("waiting for " + action.type)
            setTimeout(function () {
                return wait();
            }, 50);
        }
    };
    return wait();
}
