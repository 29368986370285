var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AsyncWait } from "app/components/common/async";
import FormValidationContext from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import TabChannels from "app/components/workspaces/templates/template-tabs/tab-channels";
import TabConfiguration from "app/components/workspaces/templates/template-tabs/tab-configuration";
import TabDetails from "app/components/workspaces/templates/template-tabs/tab-details";
import TabDocuments from "app/components/workspaces/templates/template-tabs/tab-documents";
import TabFeatures from "app/components/workspaces/templates/template-tabs/tab-features";
import TabLiveTemplate from "app/components/workspaces/templates/template-tabs/tab-live-template";
import TabPolicies from "app/components/workspaces/templates/template-tabs/tab-policies";
import TabSecurity from "app/components/workspaces/templates/template-tabs/tab-security";
import TabWebhook from "app/components/workspaces/templates/template-tabs/tab-webhooks";
import LockCheckService from "app/services/lock-check";
import * as ApprovalWorkflowListStore from "app/store/approval-workflows/list";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as NamingPolicyListStore from "app/store/naming-policies/list";
import { MetadataConfigurationType } from "app/store/templates";
import * as TemplateCurrentStore from "app/store/templates/current";
import { templateDefaults, vivaTemplateDefaults } from "app/store/templates/current/constants";
import * as TemplateListStore from "app/store/templates/list";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import generateGuid from "app/utils/generateGuid";
import * as React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import "./template-tabs.scss";
var TemplateTabs = React.memo(function (props) {
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "details"), hash = _b[0], setHash = _b[1];
    var dispatch = useDispatch();
    var lookupGuid = props.guid;
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var listTemplates = useSelector(function (state) { return state.templateListState.items; });
    var _c = React.useState(false), formValid = _c[0], setFormValid = _c[1];
    var _d = React.useState(false), hasSubmitted = _d[0], setHasSubmitted = _d[1];
    var _e = React.useState(false), isLocked = _e[0], setIsLocked = _e[1];
    var _f = React.useState(), LockRequests = _f[0], setLockRequest = _f[1];
    var _g = React.useState(true), isLoading = _g[0], setIsLoading = _g[1];
    var _h = React.useState([]), errorCounts = _h[0], setErrorCounts = _h[1];
    var _j = React.useState(true), hasTabsChanged = _j[0], setHasTabsChanged = _j[1];
    //const currentTemplate:Template = {};
    //console.log("Template - Tabs - New/End - currentTemplate" , currentTemplate);
    React.useEffect(function () {
        if (currentTemplate.GUID !== undefined) {
            setIsLoading(false);
        }
    }, [currentTemplate]);
    React.useEffect(function () {
        dispatch(ApprovalWorkflowListStore.Load([]));
        dispatch(NamingPolicyListStore.Load([]));
        dispatch(TenantConfigurationCurrentStore.Load());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        dispatch(TemplateCurrentStore.Clear());
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current template when navigating to the new template page.
            var guid = generateGuid();
            // Add a new template with guid.
            dispatch(TemplateCurrentStore.Update(__assign({ GUID: guid, Position: listTemplates.length + 1 }, templateDefaults)));
        }
        else {
            LockCheckService.GetCheckResponse(lookupGuid, "template").then(function (lockCheck) {
                setIsLocked(lockCheck.Locked);
                setLockRequest(lockCheck.Items);
                // Load the /edit/guid load.
                dispatch(TemplateCurrentStore.Load({ GUID: lookupGuid }));
            });
        }
    }, [lookupGuid]);
    //new hook to watch for template type changes
    React.useEffect(function () {
        if (currentTemplate.TemplateType === "VivaEngageCommunity" && lookupGuid === undefined) {
            dispatch(TemplateCurrentStore.Update(__assign({}, vivaTemplateDefaults)));
        }
        else if (currentTemplate.TemplateType === "Team" && lookupGuid === undefined) {
            dispatch(TemplateCurrentStore.Update(__assign(__assign({}, templateDefaults), { TemplateType: "Team" })));
        }
        else if (currentTemplate.TemplateType === "SharePoint" && lookupGuid === undefined) {
            dispatch(TemplateCurrentStore.Update(__assign(__assign({}, templateDefaults), { TemplateType: "SharePoint" })));
        }
    }, [currentTemplate.TemplateType]);
    React.useEffect(function () {
        if (window.location.hash !== "")
            setHash(window.location.hash);
    }, []);
    var clearCurrentTemplate = function () {
        dispatch(TemplateCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(TemplateCurrentStore.Clear());
        };
    }, []);
    var saveAndAddCurrentTemplate = function () {
        if (formValid) {
            setHasSubmitted(true);
        }
    };
    React.useEffect(function () {
        if (hasSubmitted) {
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                // First, add the new template to template list
                dispatch(TemplateListStore.AddItem(currentTemplate));
                asyncCallType = AsyncCallType.Add;
            }
            else {
                if (currentTemplate.MetadataConfigurationType === MetadataConfigurationType.None || currentTemplate.MetadataConfigurationType === MetadataConfigurationType.Inherit) {
                    currentTemplate.MetadataCustomizedFieldsEnabled = false;
                    currentTemplate.MetadataFields = [];
                }
                dispatch(TemplateListStore.UpdateItem(currentTemplate));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentTemplate.GUID).then(function () {
                setBackToList(true);
                //dispatch(TemplateCurrentStore.Clear());
            });
        }
    }, [hasSubmitted]);
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
    };
    // React.useEffect(()=>{
    //     try{
    //         saveDiv.current.onclick=saveAndAddCurrentTemplate
    //     }catch{}
    // })
    var getErrorCount = function (tabName) {
        if (hasTabsChanged === true) {
            var tabErrors = errorCounts.find(function (ec) { return ec.tabName === tabName; });
            if (tabErrors !== undefined) {
                if (tabErrors.errorCount > 0) {
                    return (React.createElement("div", { className: "error-count", "data-pw": "error-count" },
                        React.createElement("i", { className: "fas fa-exclamation-triangle" }),
                        " ",
                        tabErrors.errorCount,
                        React.createElement("ul", { className: "errors" }, tabErrors.errors.map(function (element) { return (React.createElement("li", { className: "error", "data-pw": "error" }, element.textContent)); }))));
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
        // var errors = document.querySelectorAll("#template-new-tabs-tabpane-" + tabName + " .error-item");
        // return <div className="error-count">{errors.length}</div>;
        // console.log("tab click");
        // setTimeout(()=>{
        //    var currentTabPanes = Array.from(document.querySelectorAll('.tab-content .tab-pane'));
        //    if(currentTabPanes.length > 0){
        //       currentTabPanes.forEach((pane) => {
        //          var errors = Array.from(pane.querySelectorAll('.error-item'));
        //          var tab = document.getElementById(pane.id.replace("-tabpane-","-tab-"));
        //          if(tab !== undefined && tab !== null){
        //             var countDiv = Array.from(tab.querySelectorAll('.error-count'));
        //             if(errors.length > 0){
        //             } else {
        //                if(countDiv.length > 0){
        //                   countDiv.forEach((cd)=> {
        //                      tab?.removeChild(cd);
        //                   })
        //                }
        //             }
        //          }
        //       })
        //          // if(Array.from(document.querySelectorAll('.document-container.active')).length === 0){
        //          //    var stillExistingTab = document.getElementById(currentTabs[0].id.replace("-tabpane-","-tab-"))
        //          //    if(stillExistingTab != null){
        //          //       stillExistingTab.click();
        //          //    }
        //          // }
        //          //document-modal-tabs-tabpane-document-modal-tab-0
        //          //document-modal-tabs-tab-document-modal-tab-0
        //    }
        // },500)
    };
    React.useEffect(function () {
        setTimeout(function () {
            var currentTabPanes = Array.from(document.querySelectorAll(".tab-content .tab-pane"));
            var tempErrorCounts = errorCounts;
            if (currentTabPanes.length > 0) {
                currentTabPanes.forEach(function (pane) {
                    var errors = Array.from(pane.querySelectorAll(".error-item"));
                    var tabName = pane.id.replace("template-new-tabs-tabpane-", "");
                    //console.log("tab errors",tabName, errors);
                    var countObect = { tabName: tabName, errorCount: errors.length, errors: errors };
                    if (errorCounts.some(function (ec) { return ec.tabName === tabName; })) {
                        tempErrorCounts = tempErrorCounts.map(function (ec) { return (ec.tabName !== tabName ? ec : countObect); });
                    }
                    else {
                        tempErrorCounts.push(countObect);
                    }
                });
            }
            //console.log("Error Array", tempErrorCounts)
            setErrorCounts(tempErrorCounts);
        }, 100);
    }, [currentTemplate]);
    return (React.createElement("div", { className: "template-tabs fadein-500ms" + (isLocked ? " is-locked" : "") },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "TemplateValidation", IsSubmitted: hasSubmitted },
            backToList && React.createElement(Redirect, { to: "/workspaces/templates" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Templates", titleLink: "/workspaces/templates", subTitle: props.guid ? (currentTemplate.Title !== undefined ? currentTemplate.Title : "Edit") : currentTemplate.Title !== undefined ? currentTemplate.Title : "New" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/templates", onClick: function () { return clearCurrentTemplate(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement("div", { onClick: function () {
                        saveAndAddCurrentTemplate();
                    }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            isLocked ? (React.createElement(PortletAlert, { type: "warning", icon: "alert", title: "Template cannot be edited while a workspace request based on this template is in-progress.", message: React.createElement("div", { className: "lock-alert" },
                    React.createElement("p", null, "In-progress Requests using this template:"),
                    React.createElement("ul", null, LockRequests === null || LockRequests === void 0 ? void 0 : LockRequests.map(function (lr, lrIndex) { return (React.createElement("li", { key: lrIndex },
                        React.createElement(NavLink, { to: lr.DetailsUrl !== undefined ? lr.DetailsUrl : "/requests" }, lr.Title))); }))) })) : null,
            React.createElement(Tab.Container, { id: "template-new-tabs", defaultActiveKey: hash },
                React.createElement("div", { className: "kt-portlet" },
                    React.createElement("div", { className: "kt-portlet__body" },
                        React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                            React.createElement(Nav, { as: "ul", onClick: function () { return setHasTabsChanged(true); } },
                                React.createElement(Nav.Item, { as: "li", "data-pw": "details-tab", onClick: function (event) {
                                        setHashAnchor("details");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "details" },
                                        React.createElement("span", null, "Details"),
                                        getErrorCount("details"))),
                                currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Nav.Item, { as: "li", "data-pw": "live-template-tab", onClick: function (event) {
                                        setHashAnchor("live-template");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "live-template" },
                                        React.createElement("span", null, "Live Template"),
                                        getErrorCount("live-template")))) : null,
                                currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Nav.Item, { as: "li", "data-pw": "policies-tab", onClick: function (event) {
                                        setHashAnchor("policies");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "policies" },
                                        React.createElement("span", null, "Policies"),
                                        getErrorCount("policies")))) : null,
                                React.createElement(Nav.Item, { as: "li", "data-pw": "configuration-tab", onClick: function (event) {
                                        setHashAnchor("configuration");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "configuration" },
                                        React.createElement("span", null, "Configuration"),
                                        getErrorCount("configuration"))),
                                currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Nav.Item, { as: "li", "data-pw": "features-tab", onClick: function (event) {
                                        setHashAnchor("features");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "features" },
                                        React.createElement("span", null, "Features"),
                                        getErrorCount("features")))) : null,
                                currentTemplate.TemplateType === undefined || currentTemplate.TemplateType === "Team" ? (React.createElement(Nav.Item, { as: "li", "data-pw": "channels-tab", onClick: function (event) {
                                        setHashAnchor("channels");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "channels" },
                                        React.createElement("span", null, "Channels"),
                                        getErrorCount("channels")))) : (React.createElement(React.Fragment, null)),
                                currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Nav.Item, { as: "li", "data-pw": "documents-tab", onClick: function (event) {
                                        setHashAnchor("documents");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "documents" },
                                        React.createElement("span", null, "Documents"),
                                        getErrorCount("documents")))) : null,
                                React.createElement(Nav.Item, { as: "li", "data-pw": "security-tab", onClick: function (event) {
                                        setHashAnchor("security");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "security" },
                                        React.createElement("span", null, "Security"),
                                        getErrorCount("security"))),
                                React.createElement(Nav.Item, { as: "li", "data-pw": "integrations-tab", onClick: function (event) {
                                        setHashAnchor("integrations");
                                    } },
                                    React.createElement(Nav.Link, { eventKey: "integrations" },
                                        React.createElement("span", null, "Integrations"),
                                        getErrorCount("integrations"))))),
                        isLoading ? (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 6, lg: 5, xl: 5 },
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginBottom: 26 }),
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginBottom: 26 }),
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })),
                                React.createElement(Col, { md: 5, lg: 4, xl: 4, className: "right-col" },
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 300, marginBottom: 7 }),
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19 }))))) : null,
                        currentTemplate.GUID !== undefined ? (React.createElement(Tab.Content, null,
                            React.createElement(Tab.Pane, { eventKey: "details" },
                                React.createElement(TabDetails, { currentTemplate: currentTemplate, lookupGuid: lookupGuid })),
                            React.createElement(Tab.Pane, { eventKey: "live-template" },
                                React.createElement(TabLiveTemplate, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "configuration" },
                                React.createElement(TabConfiguration, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "policies" },
                                React.createElement(TabPolicies, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "features" },
                                React.createElement(TabFeatures, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "channels" },
                                React.createElement(TabChannels, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "documents" },
                                React.createElement(TabDocuments, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "security" },
                                React.createElement(TabSecurity, { currentTemplate: currentTemplate })),
                            React.createElement(Tab.Pane, { eventKey: "integrations" },
                                React.createElement(TabWebhook, { currentTemplate: currentTemplate })))) : (React.createElement("div", { className: "loading" }))))))));
});
export default TemplateTabs;
