import React from "react";
import { useIntl } from "react-intl";
import { OrchestryBadge } from "../orchestry-badge/orchestry-badge.component";
import { OrchestryPlusCircle } from "../orchestry-plus-circle/orchestry-plus-circle.component";
import { TabCount } from "../orchestry-tabs/orchestry-tabs-count.component";
export var OrchestryAnnotationButton = function (_a) {
    var annotationCount = _a.annotationCount, isLoading = _a.isLoading, onAddAnnotationClicked = _a.onAddAnnotationClicked;
    var intl = useIntl();
    return annotationCount ? (React.createElement(OrchestryBadge, { skeletonWidth: 150, value: "Annotations", isLoading: isLoading, size: "xxl", onClick: onAddAnnotationClicked, color: "var(--mantine-color-error-7)" },
        React.createElement(TabCount, { count: annotationCount, variant: "error-0" }),
        " ",
        annotationCount > 1 ? intl.formatMessage({ id: "controls.annotations", defaultMessage: "Annotations" }) : intl.formatMessage({ id: "controls.annotation", defaultMessage: "Annotation" }))) : (React.createElement(OrchestryBadge, { skeletonWidth: 150, value: "Annotations", isLoading: isLoading, size: "xxl", onClick: onAddAnnotationClicked, color: "var(--mantine-color-primary-8)" },
        React.createElement(OrchestryPlusCircle, null),
        " ",
        intl.formatMessage({ id: "controls.add_annotations", defaultMessage: "Add Annotations" })));
};
