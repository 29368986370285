import * as React from "react";
import "./naming-option-preview.scss";
import moment from "moment";
var SecondStageNamingOptionPreview = React.memo(function (_a) {
    var currentPolicy = _a.currentPolicy, type = _a.type, title = _a.title;
    var getPrefix = function (type) {
        return type === "name"
            ? currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixValues !== undefined &&
                currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixValues !== null &&
                currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixValues.length > 0
                ? JSON.parse(currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixValues)
                : []
            : [];
    };
    var getSuffix = function (type) {
        return type === "name"
            ? currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixValues !== undefined &&
                currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixValues !== null &&
                currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixValues.length > 0
                ? JSON.parse(currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixValues)
                : []
            : [];
    };
    var _b = React.useState(getPrefix(type)), prefixes = _b[0], setPrefixes = _b[1];
    var _c = React.useState(getSuffix(type)), suffixes = _c[0], setSuffixes = _c[1];
    React.useEffect(function () {
        setPrefixes(getPrefix(type));
        setSuffixes(getSuffix(type));
    }, [currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixValues, currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixValues]);
    var replaceSpaces = function (value, delimiter) {
        if (value !== undefined && value !== null) {
            if (delimiter !== undefined && delimiter !== null && delimiter !== "") {
                return value.split(" ").join(delimiter);
            }
            else {
                return value;
            }
        }
        else {
            return "";
        }
    };
    var metadataReplace = function (fixValue, delimiter, returnType, type) {
        var returnValue = fixValue !== undefined ? fixValue : "";
        if (fixValue !== undefined && fixValue !== "") {
            if (fixValue === "Date-us-long" || fixValue === "Date") {
                returnValue = replaceSpaces(moment().format("MM-DD-YYYY"), delimiter);
            }
            if (fixValue === "Date-uk-long") {
                returnValue = replaceSpaces(moment().format("DD-MM-YYYY"), delimiter);
            }
            if (fixValue === "Date-sortfriendly-long") {
                returnValue = replaceSpaces(moment().format("YYYY-MM-DD"), delimiter);
            }
            if (fixValue === "Date-us-short") {
                returnValue = replaceSpaces(moment().format("M-D-YY"), delimiter);
            }
            if (fixValue === "Date-uk-short") {
                returnValue = replaceSpaces(moment().format("D-M-YY"), delimiter);
            }
            if (fixValue === "Date-sortfriendly-short") {
                returnValue = replaceSpaces(moment().format("YY-M-D"), delimiter);
            }
            if (type === "url") {
                returnValue = returnValue.toLowerCase();
            }
            returnValue = returnType === "jsx" ? React.createElement(React.Fragment, null, returnValue) : returnValue;
        }
        return returnValue;
    };
    var finalValue = type === "name" ? (title !== undefined && title !== null ? title : "") : title !== undefined && title !== null ? replaceSpaces(title, "-") : "";
    // console.log("finalValue",finalValue);
    if (type === "name" && currentPolicy.SecondStage_Option_Naming_TeamGroup_Change) {
        return (React.createElement("span", { className: "naming-policy-preview" },
            type === "name" && currentPolicy.SecondStage_Option_Naming_TeamGroup_PrefixAdd === true && prefixes !== null && prefixes.length > 0
                ? prefixes.map(function (fix, fixIndex) { return (React.createElement("span", { className: "prefix fix", key: fixIndex }, fix.type === "metadata" && fix.value !== undefined && fix.value !== null && fix.value !== "" ? metadataReplace(fix.value, " ", "jsx", type) : replaceSpaces(fix.value, " "))); })
                : null,
            React.createElement(React.Fragment, null,
                React.createElement("span", { className: "final-value" }, type === "name" ? finalValue : finalValue.toLowerCase())),
            type === "name" && currentPolicy.SecondStage_Option_Naming_TeamGroup_SuffixAdd === true && suffixes !== null && suffixes.length > 0
                ? suffixes.map(function (fix, fixIndex) { return (React.createElement("span", { className: "suffix fix", key: fixIndex }, fix.type === "metadata" && fix.value !== undefined && fix.value !== null && fix.value !== "" ? metadataReplace(fix.value, " ", "jsx", type) : replaceSpaces(fix.value, " "))); })
                : null));
    }
    else {
        return (React.createElement("span", { className: "naming-option-preview" },
            React.createElement("span", { className: "final-value" }, type === "name" ? title : title !== undefined && title !== null ? title.toLowerCase() : "")));
    }
});
export default SecondStageNamingOptionPreview;
