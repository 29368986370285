import { em } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ORCHESTRY_MOBILE_BREAKPOINT } from "appv2/types/globals.types";
/**
 * Hook to determine if the current screen size is mobile.
 */
export var useIsMobile = function () {
    var isMobile = useMediaQuery("(max-width: ".concat(em(ORCHESTRY_MOBILE_BREAKPOINT), ")"));
    return isMobile;
};
