var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PermissionInfoService } from "app/services/permission-info";
import { OrchestryPermissionInfoEditorEditModalUtils } from "./orchestry-permission-info-editor-edit-modal.utils";
import { PermissionInfoAction } from "../permission-info-action.type";
export var useOrchestryPermissionInfoEditorEditModal = function (_a) {
    var setIsEditPermissionInfoModalOpen = _a.setIsEditPermissionInfoModalOpen, selectedPermissionInfoItem = _a.selectedPermissionInfoItem, permissionInfoItems = _a.permissionInfoItems, setPermissionInfoItems = _a.setPermissionInfoItems, permissionInfoAction = _a.permissionInfoAction, setSelectedAppType = _a.setSelectedAppType;
    var onSubmitClicked = function () {
        PermissionInfoService.upsertPermissionInfoItem(selectedPermissionInfoItem).then(function (response) {
            var upsertedItem = response.item;
            if (permissionInfoAction === PermissionInfoAction.Create) {
                setPermissionInfoItems(__spreadArray(__spreadArray([], permissionInfoItems, true), [upsertedItem], false));
            }
            else {
                var updatedPermissionInfoItems = permissionInfoItems.map(function (item) { return (item.id === selectedPermissionInfoItem.id ? __assign({}, upsertedItem) : __assign({}, item)); });
                setPermissionInfoItems(__spreadArray([], updatedPermissionInfoItems, true));
            }
            setIsEditPermissionInfoModalOpen(false);
            setSelectedAppType(selectedPermissionInfoItem.AppType);
        });
    };
    var onDeleteClicked = function () {
        PermissionInfoService.deletePermissionInfoItem(selectedPermissionInfoItem).then(function () {
            setPermissionInfoItems(permissionInfoItems.filter(function (i) { return i.id !== selectedPermissionInfoItem.id; }));
            setIsEditPermissionInfoModalOpen(false);
        });
    };
    return { onSubmitClicked: onSubmitClicked, onDeleteClicked: onDeleteClicked, submitButtonTitle: OrchestryPermissionInfoEditorEditModalUtils.getSubmitButtonTitle(permissionInfoAction) };
};
