import { Stack } from "@mantine/core";
import "@mantine/core/styles.css";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryReadOnlyCopyControl } from "appv2/components/orchestry-readonly-copy-control/orchestry-readonly-copy-control.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import { OrchestrySettingsPageControl } from "appv2/components/orchestry-settings-page-control/orchestry-settings-page-control.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { PartnerSettingsConsentControl } from "./partner-settings-consent-control.component";
import { ConsentDescription, GetInTouchDescription, PartnerConnectionSecretDescription } from "./partners-settings-control-descriptions.component";
import "./partners-settings-page.scss";
import { SecretInputControl } from "./secret-input-control.component";
import { usePartnersSettingsPage } from "./use-partners-settings-page.hook";
export var PartnersSettingsPage = function () {
    var _a;
    var _b = usePartnersSettingsPage(), isSaveDisabled = _b.isSaveDisabled, isPageLoading = _b.isPageLoading, form = _b.form, handleFormSubmit = _b.handleFormSubmit, consentorDisplayName = _b.consentorDisplayName, consentorPhoto = _b.consentorPhoto, isVerifyPartnerSecretLoading = _b.isVerifyPartnerSecretLoading, onSecretInputControlBlur = _b.onSecretInputControlBlur, partnerTenantGuid = _b.partnerTenantGuid, partnerOrganizationName = _b.partnerOrganizationName, showEmail = _b.showEmail, onDisconnectTenantClicked = _b.onDisconnectTenantClicked, currentTenantName = _b.currentTenantName;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "partners-settings-page" },
                React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
                    React.createElement(Subtitle, { title: "Settings" }),
                    React.createElement(OrchestryPageHeader, null,
                        React.createElement(OrchestryPageTitle, { title: "Partners", subTitle: "Configure your partner association and provide your consent to their oversight." }),
                        React.createElement(OrchestryActionButtons, null,
                            React.createElement(OrchestrySaveButton, { disabled: isSaveDisabled }))),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner Connection Secret", description: PartnerConnectionSecretDescription() },
                        React.createElement(Stack, { gap: 8 },
                            React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-primary.0", size: "14px", fw: 400, lh: "20px" }, "Secret:"),
                            React.createElement(SecretInputControl, { isLoading: isPageLoading, value: (_a = form.getInputProps("SecretKey").value) !== null && _a !== void 0 ? _a : "", onChange: function (value) { return form.setFieldValue("SecretKey", value); }, onBlur: onSecretInputControlBlur, isError: form.getInputProps("SecretKey").error, isLookupLoading: isVerifyPartnerSecretLoading, tenantGuid: partnerTenantGuid, partnerOrganizationName: partnerOrganizationName }))),
                    React.createElement(OrchestrySettingsPageControl, { title: "Consent", description: ConsentDescription() },
                        React.createElement(PartnerSettingsConsentControl, { customerTenantName: currentTenantName !== null && currentTenantName !== void 0 ? currentTenantName : "", partnerHubTenantName: partnerOrganizationName !== null && partnerOrganizationName !== void 0 ? partnerOrganizationName : "", isLoading: isPageLoading, consentedBy: consentorDisplayName, consentedDate: form.getInputProps("ConsentedDate").value, consentorPhoto: consentorPhoto !== null && consentorPhoto !== void 0 ? consentorPhoto : undefined, onClick: function () {
                                form.setFieldValue("IsConsented", !form.getInputProps("IsConsented").value);
                            }, isConsented: form.getInputProps("IsConsented").value, error: form.getInputProps("IsConsented").error, onDisconnectTenantClicked: onDisconnectTenantClicked })),
                    React.createElement(OrchestryDivider, null),
                    showEmail && (React.createElement(OrchestrySettingsPageControl, { title: "Get In Touch", description: GetInTouchDescription() },
                        React.createElement(OrchestryReadOnlyCopyControl, { isLoading: isPageLoading, value: form.getInputProps("SupportEmail").value }))))))));
};
