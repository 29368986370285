var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Highlight, Stack, Text } from "@mantine/core";
import "@mantine/core/styles.css";
import { Dropzone } from "@mantine/dropzone";
import "@mantine/dropzone/styles.css";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryButton } from "../orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryImageDropzoneRejectionToast } from "./orchestry-image-dropzone-rejection-toast.component";
import "./orchestry-image-dropzone.scss";
export var OrchestryImageDropzone = function (_a) {
    var loading = _a.loading, accept = _a.accept, maxSize = _a.maxSize, onDrop = _a.onDrop, onReject = _a.onReject, base64Value = _a.base64Value, imageStyles = _a.imageStyles, imageAlt = _a.imageAlt, onPreviewRemoveClick = _a.onPreviewRemoveClick, props = __rest(_a, ["loading", "accept", "maxSize", "onDrop", "onReject", "base64Value", "imageStyles", "imageAlt", "onPreviewRemoveClick"]);
    var onDropzoneReject = function (fileRejections) {
        var fileAndRejectionString = fileRejections.map(function (fileRejection) { return "".concat(fileRejection.file.name, " - ").concat(fileRejection.errors.map(function (error) { return error.message; }).join(", ")); }).join(", ");
        OrchestryImageDropzoneRejectionToast(fileAndRejectionString);
        onReject && onReject(fileRejections);
    };
    return (React.createElement("div", { className: "orchestry-image-dropzone" }, base64Value ? (React.createElement("div", { className: "preview" },
        React.createElement("img", { style: imageStyles, src: base64Value, alt: imageAlt }),
        React.createElement(OrchestryButton, { size: "sm", variant: "danger", iconType: "font", iconRight: ORCH_ICONS.trash02, onClick: onPreviewRemoveClick }, "Remove"))) : (React.createElement(Dropzone, __assign({ style: { "--dropzone-accept-bg": "var(--mantine-color-primary-0)", "--dropzone-reject-bg": "var(--mantine-color-error-0)" }, loading: loading, onDrop: onDrop, onReject: onDropzoneReject, maxSize: maxSize, accept: accept }, props),
        React.createElement(Stack, { align: "center", gap: "12px", style: { pointerEvents: "none" } },
            React.createElement(Dropzone.Accept, null,
                React.createElement(OrchestryFeaturedIcon, { size: "md", variant: "double-circle", colorVariant: "primary-9-primary-1", iconClassName: ORCH_ICONS.uploadCloud })),
            React.createElement(Dropzone.Reject, null,
                React.createElement(OrchestryFeaturedIcon, { size: "md", variant: "double-circle", colorVariant: "error-5", iconClassName: ORCH_ICONS.x })),
            React.createElement(Dropzone.Idle, null,
                React.createElement(OrchestryFeaturedIcon, { size: "md", variant: "double-circle", colorVariant: "gray-6-gray-1-gray-1", iconClassName: ORCH_ICONS.uploadCloud })),
            React.createElement("div", { className: "description" },
                React.createElement(Highlight, { highlight: "Click to upload Logo", highlightStyles: { fontWeight: 400, backgroundColor: "var(--mantine-color-primary-7)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }, c: "gray.7", size: "14px", fw: 300, lh: "20px" }, "Click to upload Logo or drag and drop"),
                React.createElement(Text, { c: "gray.7", size: "12px", fw: 300, lh: "18px" }, "SVG, PNG, JPG or GIF (max. 192x192px)")))))));
};
