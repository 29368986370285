import React from "react";
import "./team-information-vertical-section.scss";
import TeamInformationCustomMetadata from "../team-information-custom-metadata";
import { TeamInformationDescription } from "./team-information-description.component";
import { TeamInformationGuestAccessPill } from "./team-information-guest-access-pill.component";
import { TeamInformationLogo } from "./team-information-logo.component";
import { TeamInformationPrivacyPill } from "./team-information-privacy-pill.component";
import { TeamInformationStatus } from "./team-information-status.component";
import { TeamInformationTemplatePill } from "./team-information-template-pill.component";
import { TeamInformationTitle } from "./team-information-title.component";
import { TeamInformationWorkspaceDetails } from "./team-information-workspace-details.component";
import { useSelector } from "react-redux";
/**
 * Renders a vertical section of the team information header. Used in vertical sharepoint webparts with a narrow width.
 */
export var TeamInformationVerticalSection = function () {
    var _a, _b;
    var teamInformationWebPartState = useSelector(function (state) { return state.teamInformationWebPartState; });
    var showLogoGroup = ((_a = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _a === void 0 ? void 0 : _a.showLogo) || ((_b = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _b === void 0 ? void 0 : _b.showStatus);
    return (React.createElement("div", { className: "team-information-vertical-section" },
        showLogoGroup && (React.createElement("div", { className: "logo-group" },
            React.createElement("div", null,
                React.createElement(TeamInformationLogo, null)),
            React.createElement(TeamInformationStatus, null))),
        React.createElement("div", { className: "information-pane" },
            React.createElement("div", { className: "top" },
                React.createElement(TeamInformationTitle, null),
                React.createElement(TeamInformationDescription, null),
                React.createElement("div", { className: "pills" },
                    React.createElement(TeamInformationTemplatePill, null),
                    React.createElement(TeamInformationPrivacyPill, null),
                    React.createElement(TeamInformationGuestAccessPill, null))),
            React.createElement("div", { className: "bottom" },
                React.createElement(TeamInformationWorkspaceDetails, null),
                React.createElement(TeamInformationCustomMetadata, null)))));
};
