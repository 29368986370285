import React from "react";
import { Route, Switch } from "react-router-dom";
import WorkspaceListing from "app/components/insights/workspaces/report";
import LifecycleArchivalDetails from "app/components/lifecycle/workspaces/policies/lifecycle-archivals-details";
import LifecycleArchivalList from "app/components/lifecycle/workspaces/policies/lifecycle-archivals-list";
import LifecycleSettings from "app/components/lifecycle/workspaces/settings";
import LifecycleQueues from "app/components/insights/common/queue";
import LifecycleDashboard from "app/components/insights/workspaces/dashboard";
export default function LifecyclePages() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/lifecycle/dashboard", component: LifecycleDashboard }),
        React.createElement(Route, { exact: true, path: "/lifecycle/listing", component: WorkspaceListing }),
        React.createElement(Route, { exact: true, path: "/lifecycle/listing/:siteId/:param2?", component: WorkspaceListing }),
        React.createElement(Route, { exact: true, path: "/lifecycle/policies/archival", component: LifecycleArchivalList }),
        React.createElement(Route, { exact: true, path: "/lifecycle/policies/archival/details/", component: LifecycleArchivalDetails }),
        React.createElement(Route, { exact: true, path: "/lifecycle/policies/archival/details/:guid", component: LifecycleArchivalDetails }),
        React.createElement(Route, { exact: true, path: "/lifecycle/settings", component: LifecycleSettings }),
        React.createElement(Route, { exact: true, path: "/lifecycle/queue", component: LifecycleQueues })));
}
