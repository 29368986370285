import * as React from "react";
import "./naming-policy-preview.scss";
import getValueBetweenSquareBrackets from "app/utils/getValueBetweenSquareBrackets";
import getNextCharacter from "app/utils/getNextCharacter";
var NamingPolicyPreview = React.memo(function (_a) {
    // console.log("props.value", value);
    var currentNamingPolicy = _a.currentNamingPolicy, value = _a.value, user = _a.user, type = _a.type, customMetadataValues = _a.customMetadataValues, incrementCounterBy = _a.incrementCounterBy;
    var _b = React.useState(getPrefix(currentNamingPolicy, type)), prefixes = _b[0], setPrefixes = _b[1];
    var _c = React.useState(getSuffix(currentNamingPolicy, type)), suffixes = _c[0], setSuffixes = _c[1];
    React.useEffect(function () {
        setPrefixes(getPrefix(currentNamingPolicy, type));
        setSuffixes(getSuffix(currentNamingPolicy, type));
    }, [currentNamingPolicy.TitlePrefixValue, currentNamingPolicy.TitleSuffixValue, currentNamingPolicy.UrlPrefixValue, currentNamingPolicy.UrlSuffixValue]);
    var finalValue = type === "name" ? (value !== undefined && value !== null ? value : "") : value !== undefined && value !== null ? replaceSpaces(value, currentNamingPolicy.UrlDelimiter) : "";
    // console.log("finalValue", finalValue);
    if ((type === "name" && currentNamingPolicy.RenameWorkspaceTitle) || (type === "url" && currentNamingPolicy.RenameWorkspaceUrl)) {
        return (React.createElement("span", { className: "naming-policy-preview" },
            ((type === "name" && currentNamingPolicy.TitlePrefixEnabled === true) || (type === "url" && currentNamingPolicy.UrlPrefixEnabled)) && prefixes !== null && prefixes.length > 0
                ? prefixes.map(function (fix, fixIndex) { return (React.createElement("span", { className: "prefix fix", key: fixIndex }, fix.type === "metadata" && fix.value !== undefined && fix.value !== null && fix.value !== ""
                    ? metadataReplace(fix.value, user, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "jsx", type)
                    : fix.type === "orchestry-metadata" && fix.value !== undefined && fix.value !== null && fix.value !== ""
                        ? orchestryMetadataReplace(fix.value, customMetadataValues, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "jsx", type)
                        : fix.type === "counter"
                            ? incrementalCounterReplace(fix.value, currentNamingPolicy, "jsx", type, incrementCounterBy !== undefined ? incrementCounterBy : 1)
                            : replaceSpaces(fix.value, type === "url" ? currentNamingPolicy.UrlDelimiter : " "))); })
                : null,
            React.createElement(React.Fragment, null,
                React.createElement("span", { className: "final-value" }, type === "name" ? finalValue : finalValue.toLowerCase())),
            ((type === "name" && currentNamingPolicy.TitleSuffixEnabled === true) || (type === "url" && currentNamingPolicy.UrlSuffixEnabled)) && suffixes !== null && suffixes.length > 0
                ? suffixes.map(function (fix, fixIndex) { return (React.createElement("span", { className: "suffix fix", key: fixIndex }, fix.type === "metadata" && fix.value !== undefined && fix.value !== null && fix.value !== ""
                    ? metadataReplace(fix.value, user, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "jsx", type)
                    : fix.type === "orchestry-metadata" && fix.value !== undefined && fix.value !== null && fix.value !== ""
                        ? orchestryMetadataReplace(fix.value, customMetadataValues, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "jsx", type)
                        : fix.type === "counter"
                            ? incrementalCounterReplace(fix.value, currentNamingPolicy, "jsx", type, incrementCounterBy !== undefined ? incrementCounterBy : 1)
                            : replaceSpaces(fix.value, type === "url" ? currentNamingPolicy.UrlDelimiter : " "))); })
                : null));
    }
    else {
        return (React.createElement("span", { className: "naming-policy-preview" },
            React.createElement("span", { className: "final-value" }, type === "name" ? value : value !== undefined && value !== null ? value.toLowerCase() : "")));
    }
});
export default NamingPolicyPreview;
export var GetNamingPolicyPreview = function (currentNamingPolicy, value, user, type, customMetadataValues, incrementCounterBy) {
    if (incrementCounterBy === void 0) { incrementCounterBy = 1; }
    var finalValue = value;
    if (type === "url") {
        if (currentNamingPolicy.UrlDelimiter !== undefined && currentNamingPolicy.UrlDelimiter !== null) {
            finalValue = value === null || value === void 0 ? void 0 : value.replace(/\W+/g, " ").split(" ").join(currentNamingPolicy.UrlDelimiter);
        }
        else {
            finalValue = value === null || value === void 0 ? void 0 : value.replace(/\W+/g, " ").split(" ").join("-");
        }
    }
    var prefixes = getPrefix(currentNamingPolicy, type);
    var suffixes = getSuffix(currentNamingPolicy, type);
    var returnValue = "";
    if ((type === "name" && currentNamingPolicy.RenameWorkspaceTitle) || (type === "url" && currentNamingPolicy.RenameWorkspaceUrl)) {
        if (((type === "name" && currentNamingPolicy.TitlePrefixEnabled === true) || (type === "url" && currentNamingPolicy.UrlPrefixEnabled)) && prefixes !== null && prefixes.length > 0) {
            prefixes.forEach(function (fix, fixIndex) {
                if (fix.type === "metadata" && fix.value !== "") {
                    returnValue += metadataReplace(fix.value, user, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "string", type);
                }
                else if (fix.type === "orchestry-metadata" && fix.value !== "") {
                    returnValue += orchestryMetadataReplace(fix.value, customMetadataValues, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "string", type);
                }
                else if (fix.type === "counter" && fix.value !== "") {
                    returnValue += incrementalCounterReplace(fix.value, currentNamingPolicy, "string", type, incrementCounterBy);
                }
                else {
                    returnValue += replaceSpaces(fix.value, type === "url" ? currentNamingPolicy.UrlDelimiter : " ");
                }
            });
        }
        returnValue += finalValue;
        if (((type === "name" && currentNamingPolicy.TitleSuffixEnabled === true) || (type === "url" && currentNamingPolicy.UrlSuffixEnabled)) && suffixes !== null && suffixes.length > 0) {
            suffixes.forEach(function (fix, fixIndex) {
                if (fix.type === "metadata" && fix.value !== "") {
                    returnValue += metadataReplace(fix.value, user, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "string", type);
                }
                else if (fix.type === "orchestry-metadata" && fix.value !== "") {
                    returnValue += orchestryMetadataReplace(fix.value, customMetadataValues, type === "url" ? currentNamingPolicy.UrlDelimiter : " ", "string", type);
                }
                else if (fix.type === "counter" && fix.value !== "") {
                    returnValue += incrementalCounterReplace(fix.value, currentNamingPolicy, "string", type, incrementCounterBy);
                }
                else {
                    //returnValue += fix.value
                    returnValue += replaceSpaces(fix.value, type === "url" ? currentNamingPolicy.UrlDelimiter : " ");
                }
            });
        }
        if (type === "url" && returnValue !== undefined && returnValue !== null) {
            returnValue = returnValue.toLowerCase();
        }
        return returnValue;
    }
    return value;
};
var getPrefix = function (currentNamingPolicy, type) {
    return type === "name"
        ? currentNamingPolicy.TitlePrefixValue !== undefined && currentNamingPolicy.TitlePrefixValue !== null && currentNamingPolicy.TitlePrefixValue.length > 0
            ? JSON.parse(currentNamingPolicy.TitlePrefixValue)
            : []
        : currentNamingPolicy.UrlPrefixValue !== undefined && currentNamingPolicy.UrlPrefixValue !== null && currentNamingPolicy.UrlPrefixValue.length > 0
            ? JSON.parse(currentNamingPolicy.UrlPrefixValue)
            : [];
};
var getSuffix = function (currentNamingPolicy, type) {
    return type === "name"
        ? currentNamingPolicy.TitleSuffixValue !== undefined && currentNamingPolicy.TitleSuffixValue !== null && currentNamingPolicy.TitleSuffixValue.length > 0
            ? JSON.parse(currentNamingPolicy.TitleSuffixValue)
            : []
        : currentNamingPolicy.UrlSuffixValue !== undefined && currentNamingPolicy.UrlSuffixValue !== null && currentNamingPolicy.UrlSuffixValue.length > 0
            ? JSON.parse(currentNamingPolicy.UrlSuffixValue)
            : [];
};
var replaceSpaces = function (value, delimiter) {
    if (value !== undefined && value !== null) {
        if (delimiter !== undefined && delimiter !== null) {
            return value.split(" ").join(delimiter);
        }
        else {
            return value;
        }
    }
    else {
        return "";
    }
};
var orchestryMetadataReplace = function (customFieldGuid, customMetadataValues, delimiter, returnType, type) {
    var returnValue = customFieldGuid !== undefined ? customFieldGuid : "";
    if (customFieldGuid !== undefined && customFieldGuid !== "") {
        if (customMetadataValues !== undefined && customMetadataValues !== null && customMetadataValues.length !== 0) {
            var foundCustomValue_1 = "";
            customMetadataValues.forEach(function (md) {
                if (md.FieldGUID === customFieldGuid) {
                    if (md.FieldType === "Date") {
                        foundCustomValue_1 = md.Values;
                    }
                    if (md.FieldType === "Text") {
                        foundCustomValue_1 = md.Values;
                    }
                    if (md.FieldType === "Number") {
                        foundCustomValue_1 = md.Values;
                    }
                    if (md.FieldType === "ManagedMetadata") {
                        var metadataValue = "";
                        if (md.Values !== undefined) {
                            //console.log("MMD Value", md.Values.indexOf("["));
                            if (md.Values !== null && md.Values !== "" && md.Values.indexOf("[") === 0) {
                                var rawStartNodes = JSON.parse(md.Values);
                                if (Array.isArray(rawStartNodes)) {
                                    rawStartNodes.forEach(function (node) {
                                        metadataValue = node.Name;
                                    });
                                }
                            }
                        }
                        //console.log("Metadata Value", metadataValue);
                        if (metadataValue.indexOf("[") > -1) {
                            foundCustomValue_1 = getValueBetweenSquareBrackets(metadataValue);
                        }
                        else {
                            foundCustomValue_1 = metadataValue;
                        }
                        if (metadataValue === "" && md.Values !== null) {
                            foundCustomValue_1 = md.Values;
                        }
                    }
                    if (md.FieldType === "Choice") {
                        if (md.Values.indexOf("[") > -1) {
                            foundCustomValue_1 = getValueBetweenSquareBrackets(md.Values);
                        }
                        else {
                            foundCustomValue_1 = md.Values;
                        }
                    }
                    returnValue = replaceSpaces(foundCustomValue_1, delimiter);
                }
            });
            if (type === "url") {
                returnValue = returnValue.toLowerCase();
            }
            returnValue = returnType === "jsx" ? React.createElement(React.Fragment, null, returnValue) : returnValue;
        }
        else {
            if (type === "url") {
                returnValue = returnValue.toLowerCase();
            }
            returnValue = returnType === "jsx" ? React.createElement(React.Fragment, null, returnValue) : returnValue;
        }
    }
    return returnValue;
};
var metadataReplace = function (fixValue, user, delimiter, returnType, type) {
    var returnValue = fixValue !== undefined ? fixValue : "";
    if (fixValue !== undefined && fixValue !== "") {
        if (user !== undefined && user.GUID !== undefined) {
            if (fixValue === "Department") {
                returnValue = replaceSpaces(user.Department, delimiter);
            }
            if (fixValue === "Company") {
                returnValue = replaceSpaces(user.Company, delimiter);
            }
            if (fixValue === "Office") {
                returnValue = replaceSpaces(user.Office, delimiter);
            }
            if (fixValue === "StateOrProvince") {
                returnValue = replaceSpaces(user.StateOrProvince, delimiter);
            }
            if (fixValue === "CountryOrRegion") {
                returnValue = replaceSpaces(user.CountryOrRegion, delimiter);
            }
            if (fixValue === "JobTitle") {
                returnValue = replaceSpaces(user.JobTitle, delimiter);
            }
            if (type === "url") {
                returnValue = returnValue.toLowerCase();
            }
            returnValue = returnType === "jsx" ? React.createElement(React.Fragment, null, returnValue) : returnValue;
        }
        else {
            if (type === "url") {
                returnValue = returnValue.toLowerCase();
            }
            returnValue = returnType === "jsx" ? React.createElement(React.Fragment, null, returnValue) : returnValue;
        }
    }
    return returnValue;
};
var incrementalCounterReplace = function (counterType, currentNamingPolicy, returnType, type, incrementBy) {
    var _a;
    var returnValue = counterType !== undefined ? counterType : "";
    if (counterType !== undefined && counterType !== "") {
        var counterName_1 = getValueBetweenSquareBrackets(counterType);
        var counterValue = (_a = currentNamingPolicy.Counters) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.CounterName === counterName_1; });
        if (counterType.indexOf("numeric") > -1) {
            if (counterValue !== undefined) {
                var number = Number(counterValue.CounterValue);
                returnValue = (number + incrementBy).toString();
            }
            else {
                returnValue = "1";
            }
        }
        if (counterType.indexOf("numeric-1zero") > -1) {
            returnValue = returnValue.padStart(2, "0");
        }
        if (counterType.indexOf("numeric-2zero") > -1) {
            returnValue = returnValue.padStart(3, "0");
        }
        if (counterType.indexOf("alpha") > -1) {
            if (counterValue !== undefined) {
                returnValue = counterValue.CounterValue;
                for (var i = 0; i < incrementBy; i++) {
                    returnValue = getNextCharacter(returnValue);
                }
            }
            else {
                returnValue = "a";
            }
        }
        if (counterType.indexOf("alpha-lower") > -1) {
            returnValue = returnValue.toLowerCase();
        }
        if (counterType.indexOf("alpha-upper") > -1) {
            returnValue = returnValue.toUpperCase();
        }
    }
    if (type === "url") {
        returnValue = returnValue.toLowerCase();
    }
    returnValue = returnType === "jsx" ? React.createElement(React.Fragment, null, returnValue) : returnValue;
    return returnValue;
};
