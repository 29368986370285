var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// import * as React from 'react';
import React from "react";
import "./channels-modal.scss";
import { Modal, Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormSearchbox from "app/components/common/form-searchbox";
import RSC from "react-scrollbars-custom";
import { useSelector, useDispatch } from "react-redux";
import * as ChannelListStore from "app/store/channels/list";
import * as LibraryChannelListStore from "app/store/library-channels/list";
import highlightWords from "app/utils/highlightWords";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//class ChannelsModal extends React.Component<IProps, IState> {
var ChannelsModal = React.memo(function (props, state) {
    var dispatch = useDispatch();
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(), selectedChannel = _b[0], setSelectedChannel = _b[1];
    var _c = React.useState([]), list = _c[0], setList = _c[1];
    var _d = React.useState(""), searchTerm = _d[0], setSearchTerm = _d[1];
    var scrollRef;
    var availableChannels;
    if (props.libraryMode !== undefined && props.libraryMode === true) {
        availableChannels = useSelector(function (state) { return state.libraryChannelListState.items; }); //eslint-disable-line
    }
    else {
        availableChannels = useSelector(function (state) { return state.channelListState.items; }); //eslint-disable-line
    }
    React.useEffect(function () {
        if (props.libraryMode !== undefined && props.libraryMode === true) {
            dispatch(LibraryChannelListStore.Load());
        }
        else {
            dispatch(ChannelListStore.Load());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        if (list.length === 0) {
            //console.log("List Length 0")
            setList(availableChannels);
        }
        else if (list.map(function (l) { return l.GUID; }).join() !== availableChannels.map(function (l) { return l.GUID; }).join()) {
            //} else if (list.length !== availableChannels.length){
            setList(availableChannels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableChannels]);
    var filterItems = function (value) {
        if (scrollRef !== undefined) {
            scrollRef.scrollTo(0, 0);
        }
        if (value.length > 0) {
            var foundOptions = availableChannels.filter(function (i) { return i.Title !== undefined && i.Title.toLowerCase().indexOf(value.toLowerCase()) > -1; });
            //console.log("foundoptions", foundOptions);
            setList(__spreadArray([], foundOptions, true));
            setSearchTerm(value);
        }
        else {
            setList(__spreadArray([], availableChannels, true));
            setSearchTerm("");
        }
    };
    var toggleModal = function () {
        setShowModal(!showModal);
    };
    var setScrollRef = function (ref) {
        if (ref !== null) {
            //console.log("setScrollRef > Ref", ref);
            scrollRef = ref;
        }
    };
    var setSelected = function (channel) {
        setSelectedChannel(channel);
    };
    var isFeatEnabled = function (feat) {
        var matched = false;
        if (feat !== undefined) {
            if (props.associatedChannels !== undefined) {
                props.associatedChannels.forEach(function (af, index) {
                    var _a;
                    if (((_a = af.Item) === null || _a === void 0 ? void 0 : _a.GUID) === feat.GUID) {
                        matched = true;
                    }
                });
            }
        }
        return matched;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "button-custom b-orange", onClick: function () { return toggleModal(); } },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            React.createElement("span", null, "More Channels")),
        React.createElement(Modal, { size: "lg", centered: true, show: showModal, onHide: function () { return toggleModal(); }, animation: true, className: "channels-modal" },
            React.createElement(Container, { fluid: true },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 5, className: "col-left" },
                        React.createElement("h4", null, "Channel List"),
                        React.createElement(FormSearchbox, { placeholder: "Search", handler: function (input) {
                                filterItems(input.target.value);
                            } }),
                        React.createElement("div", { className: "channel-list" },
                            React.createElement(RSC, { ref: function (ref) {
                                    setScrollRef(ref);
                                } },
                                React.createElement("ul", { className: "list-unstyled" },
                                    list &&
                                        list.map(function (channel, index) { return (React.createElement("li", { key: index, onClick: function () { return setSelected(channel); }, className: "" + ((selectedChannel === null || selectedChannel === void 0 ? void 0 : selectedChannel.GUID) === channel.GUID ? "chan-selected" : "") + " " + (isFeatEnabled(channel) ? "chan-enabled" : "") },
                                            React.createElement("span", { className: "text", dangerouslySetInnerHTML: { __html: highlightWords(searchTerm, channel.Title !== undefined ? channel.Title : "") } }))); }),
                                    list.length === 0 && React.createElement("li", null, "No Results"))))),
                    React.createElement(Col, { sm: 7, className: "col-right" },
                        React.createElement("div", { className: "infobox" },
                            React.createElement("h5", null, selectedChannel === null || selectedChannel === void 0 ? void 0 : selectedChannel.Title),
                            React.createElement("p", null, selectedChannel === null || selectedChannel === void 0 ? void 0 : selectedChannel.Description)),
                        React.createElement("div", { className: "actions" },
                            React.createElement("div", { className: "button-custom b-grey cancel", onClick: function () { return toggleModal(); } },
                                React.createElement("span", null, "Cancel")),
                            isFeatEnabled(selectedChannel) ? (React.createElement("div", { className: "button-custom b-grey save disabled" },
                                React.createElement("span", null, "Add"))) : (React.createElement("div", { className: "button-custom b-orange save", onClick: function () {
                                    if (selectedChannel) {
                                        props.handleSelected(selectedChannel);
                                        toggleModal();
                                    }
                                } },
                                React.createElement("span", null, "Add"))))))))));
});
export default ChannelsModal;
