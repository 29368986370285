import { LifecyclePolicyCurrentActionTypes } from "./types";
var initialState = {
    item: {}
};
export function lifecyclePoliciesCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load: {
            //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Update: {
            var updatedLifecyclePolicy = Object.assign({}, state.item, action.payload);
            return {
                item: updatedLifecyclePolicy
            };
        }
        case LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
