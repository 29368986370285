import React from "react";
import "./team-information-user-summary.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var TeamInformationUserSummary = React.memo(function (props) {
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var intl = useIntl();
    var getTotal = function () {
        var total = 0;
        if (teamInformation !== undefined && teamInformation != null) {
            total += teamInformation.OwnerCount;
            total += teamInformation.MembersCount;
            total += teamInformation.GuestCount;
        }
        return total;
    };
    var getPercentage = function (userCount) {
        return Math.round((userCount / getTotal()) * 100);
    };
    var renderUserSummaryBar = function (userCount, title, className, fontIcon) {
        return (React.createElement("div", { className: "info-bar" + (" " + className) },
            React.createElement("div", { className: "icon" },
                React.createElement("i", { className: fontIcon })),
            React.createElement("div", { className: "right-side" },
                React.createElement("div", { className: "top" },
                    React.createElement("div", { className: "title" }, title),
                    React.createElement("div", { className: "percentage" },
                        getPercentage(userCount),
                        "%"),
                    React.createElement("div", { className: "count" }, userCount)),
                React.createElement("div", { className: "bottom" },
                    React.createElement("div", { className: "full-bar" },
                        React.createElement("div", { className: "percentage-bar", style: { width: getPercentage(userCount) + "%" } }))))));
    };
    return (React.createElement("div", { className: "team-information-user-summary" },
        React.createElement(Portlet, null,
            React.createElement(PortletHeader, { title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.user_summary", defaultMessage: "User Summary" }), toolbar: React.createElement("div", { className: "count-toolbar" }, teamInformation === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 31, bodyHeight: 24 })) : (React.createElement("div", { className: "full-count" },
                    React.createElement("span", { className: "number" }, getTotal())))) }),
            React.createElement(PortletBody, null,
                React.createElement("div", { className: "verticle-section-title" }, intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.user_summary", defaultMessage: "User Summary" })),
                teamInformation != null ? (React.createElement("div", { className: "summary-bars" },
                    renderUserSummaryBar(teamInformation.OwnerCount, intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.owners", defaultMessage: "Owners" }), "owners", "fa-solid fa-user-tie-hair"),
                    renderUserSummaryBar(teamInformation.MembersCount, intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.members", defaultMessage: "Members" }), "members", "fa-solid fa-user-hair-long"),
                    renderUserSummaryBar(teamInformation.GuestCount, intl.formatMessage({ id: "layout.teams_app.chrome.team_info.title.guests", defaultMessage: "Guests" }), "guests", "fa-solid fa-user-shield"))) : (React.createElement("div", { className: "summary-bars" },
                    React.createElement("div", { className: "info-bar" },
                        React.createElement("div", { className: "icon" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, bodyWidth: 40 })),
                        React.createElement("div", { className: "right-side" },
                            React.createElement("div", { className: "top" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 21, style: { width: "100%" }, marginTop: 3 })),
                            React.createElement("div", { className: "bottom" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 6 })))),
                    React.createElement("div", { className: "info-bar" },
                        React.createElement("div", { className: "icon" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, bodyWidth: 40 })),
                        React.createElement("div", { className: "right-side" },
                            React.createElement("div", { className: "top" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 21, style: { width: "100%" }, marginTop: 3 })),
                            React.createElement("div", { className: "bottom" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 6 })))),
                    React.createElement("div", { className: "info-bar" },
                        React.createElement("div", { className: "icon" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 40, bodyWidth: 40 })),
                        React.createElement("div", { className: "right-side" },
                            React.createElement("div", { className: "top" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 21, style: { width: "100%" }, marginTop: 3 })),
                            React.createElement("div", { className: "bottom" },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 6 }))))))))));
});
export default TeamInformationUserSummary;
