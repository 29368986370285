import { Button, Skeleton, Text, Textarea } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { orchestryToast } from "appv2/components/orchestry-notifications/orchestry-toast-notification.component";
import React from "react";
import { useRecommendationShareSuggestionModal } from "./recommendation-share-suggestion-modal.hook";
import "./recommendation-share-suggestion-modal.scss";
export var RecommendationShareSuggestionModal = function (_a) {
    var context = _a.context, id = _a.id;
    var _b = useRecommendationShareSuggestionModal(), suggestionText = _b.suggestionText, setSuggestionText = _b.setSuggestionText, isSuccess = _b.isSuccess, isLoading = _b.isLoading, onSubmitClick = _b.onSubmitClick;
    // If the suggestion was successfully created, close the modal after 3 seconds
    // The thank you message will be shown for 3 seconds
    React.useEffect(function () {
        if (isSuccess) {
            context.closeModal(id);
            orchestryToast(React.createElement(Text, { size: "sm", c: "text-primary.0" }, "Thank you for your suggestion!"), {
                toastId: "recommendation-share-suggestion-toast",
                autoClose: 2500,
                progressBarVariant: "heartpink",
                icon: React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", colorVariant: "pink-8", iconClassName: ORCH_ICONS.hearts })
            });
        }
    }, [isSuccess]);
    return (React.createElement("div", { className: "recommendation-share-suggestion-modal" },
        React.createElement(Text, { c: "text-primary.0", size: "lg" }, "Share your Suggestion"),
        React.createElement(Text, { size: "sm", c: "text-tertiary.0", className: "share-suggestion-description" }, "Tell us what you'd love to add"),
        React.createElement(Textarea, { value: suggestionText, onChange: function (value) {
                setSuggestionText(value.currentTarget.value);
            }, radius: "md", placeholder: "Enter your message...", className: "share-suggestion-textarea", autosize: true, minRows: 4, maxRows: 6, disabled: isLoading }),
        React.createElement("div", { className: "share-suggestion-buttons" },
            React.createElement(Button, { size: "md", variant: "secondary-gray", onClick: function () { return context.closeModal(id); } }, "Cancel"),
            isLoading ? (React.createElement(Skeleton, { height: 36, width: "100%" })) : (React.createElement(Button, { variant: "primary", size: "md", onClick: function () { return onSubmitClick(); }, disabled: suggestionText == "" }, "Submit")))));
};
