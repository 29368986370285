import { Highlight, Stack } from "@mantine/core";
import { dateHasPassed, getReadableDateFromNow } from "app/utils/dateUtils";
import { TenantLicenseStatus } from "appv2/api/partner-tools/tenant-license-status.type";
import { OrchestryAlertChip } from "appv2/components/orchestry-alert-chip/orchestry-alert-chip.component";
import { OrchestryLicenseBadge } from "appv2/components/orchestry-license-badge/orchestry-license-badge.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { TenantInsightsPills } from "./tenant/tenant-details/tenant-insights-pills.component";
export var PartnerToolsTableNameColumn = function (row, highlightSearchWords) {
    var trialExpires = row.getValue("TrialExpires");
    var renewalDate = row.getValue("RenewalDate_DateOnly");
    var license = row.getValue("LicenseType");
    var subText = "";
    if (license === TenantLicenseStatus.OrchestryTrial) {
        if (dateHasPassed(new Date(trialExpires))) {
            subText = "Trial expired ".concat(getReadableDateFromNow(new Date(trialExpires)));
        }
        else {
            subText = "Trial expires ".concat(getReadableDateFromNow(new Date(trialExpires)));
        }
    }
    else if (license === TenantLicenseStatus.Orchestry || license === TenantLicenseStatus.Beacon) {
        if (dateHasPassed(new Date(renewalDate))) {
            subText = "Renewal occurred ".concat(getReadableDateFromNow(new Date(renewalDate)));
        }
        else {
            subText = "Renewal occurs ".concat(getReadableDateFromNow(new Date(renewalDate)));
        }
    }
    return (React.createElement(Stack, { gap: 0 },
        React.createElement(Highlight, { c: "text-primary.0", size: "16px", fw: 500, lh: "20px", highlight: highlightSearchWords }, row.getValue("DisplayName")),
        React.createElement(OrchestryText, { c: "text-tertiary.0", size: "12px", fw: 300, lh: "18px" }, subText)));
};
export var PartnerToolsTableLicenseColumn = function (value) { return React.createElement(OrchestryLicenseBadge, { tenantLicenseStatus: value }); };
export var PartnerToolsTableActionsColumn = function (value) {
    if (value === undefined || value === null || value < 1)
        return null;
    return (React.createElement(OrchestryTooltip, { style: { position: "fixed" }, multiline: true, w: 300, label: "Actions reflect the number of customer Health Checks that are waiting for your review." },
        React.createElement(OrchestryAlertChip, { title: value.toString() })));
};
export var PartnerToolsTableInsightsColumn = function (filter) {
    return React.createElement(TenantInsightsPills, { insights: filter, isPageLoading: false, maxItems: 3 });
};
