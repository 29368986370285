import * as OrchestryContextCurrentStore from "app/store/orchestry-context/current";
import { AsyncCallType } from "app/store/async/types";
export var orchestryContextCurrentActionMap = [
    {
        actionType: OrchestryContextCurrentStore.OrchestryContextCurrentActionTypes.OrchestryContext_Current_Load,
        callType: AsyncCallType.Refresh,
        keyType: "typeName",
        ApiUrl: "/api/Orchestry/Context",
        responseDataType: {}
    }
];
