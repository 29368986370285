import * as React from "react";
import "./request-details-history.scss";
import { useSelector } from "react-redux";
import FontIcon from "app/components/common/font-icon";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import UserProfilePicture from "app/components/common/user-profile-picture";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
var RequestDetailsHistory = React.memo(function (props) {
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var intl = useIntl();
    var getWorkflowStepNameFromGuid = function (GUID) {
        var name = "";
        if (currentRequest.ApprovalWorkflow !== undefined && currentRequest.ApprovalWorkflow !== null && currentRequest.ApprovalWorkflow.AssociatedSteps !== undefined) {
            currentRequest.ApprovalWorkflow.AssociatedSteps.forEach(function (step) {
                if (step.GUID === GUID) {
                    if (step.Name !== undefined) {
                        name = step.Name;
                    }
                }
            });
        }
        return name;
    };
    return (React.createElement(Portlet, { className: "request-details-history history" },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.history.title", defaultMessage: "History" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "kt-widget4" }, currentRequest.History !== undefined &&
                currentRequest.History.map(function (hist, histIndex) { return (React.createElement("div", { className: "kt-widget4__item", key: histIndex },
                    React.createElement("div", { className: "kt-widget4__pic kt-widget4__pic--pic" },
                        React.createElement("div", { className: "user-pic" },
                            React.createElement("div", { className: "badge kt-badge kt-badge--rounded " +
                                    ("Create" === hist.Action ? "kt-badge--info" : "") +
                                    ("Approve" === hist.Action ? "kt-badge--success" : "") +
                                    ("Reject" === hist.Action ? "kt-badge--danger" : "") +
                                    ("ProvisioningStarted" === hist.Action ? "kt-badge--info" : "") +
                                    ("ProvisioningComplete" === hist.Action ? "kt-badge--success" : "") +
                                    ("Archive" === hist.Action ? "kt-badge--info" : "") +
                                    ("Edit" === hist.Action ? "kt-badge--info" : "") +
                                    ("Retry" === hist.Action ? "kt-badge--success" : "") +
                                    ("Reapprove" === hist.Action ? "kt-badge--success" : "") },
                                "Create" === hist.Action ? React.createElement(FontIcon, { iconString: "certificate" }) : null,
                                "Approve" === hist.Action ? React.createElement(FontIcon, { iconString: "thumbs-up" }) : null,
                                "Reject" === hist.Action ? React.createElement(FontIcon, { iconString: "thumbs-down" }) : null,
                                "ProvisioningStarted" === hist.Action ? React.createElement(FontIcon, { iconString: "play" }) : null,
                                "ProvisioningComplete" === hist.Action ? React.createElement(FontIcon, { iconString: "check" }) : null,
                                "Archive" === hist.Action ? React.createElement(FontIcon, { iconString: "archive" }) : null,
                                "Edit" === hist.Action ? React.createElement(FontIcon, { iconString: "pen" }) : null,
                                "Retry" === hist.Action ? React.createElement(FontIcon, { iconString: "sync" }) : null,
                                "Reapprove" === hist.Action ? React.createElement(FontIcon, { iconString: "sync" }) : null),
                            React.createElement(UserProfilePicture, { size: "sm", uid: hist.User.Id, displayName: hist.User.DisplayName }))),
                    React.createElement("div", { className: "kt-widget4__info" },
                        React.createElement("div", { className: "kt-widget4__username" },
                            React.createElement("span", { className: "user", style: { fontWeight: 600 } },
                                hist.User.DisplayName,
                                " "),
                            React.createElement("span", { className: "action" },
                                "Create" === hist.Action ? React.createElement(FormattedMessage, { id: "page.request_details.history.action_create", defaultMessage: "created the request" }) : null,
                                "Approve" === hist.Action ? (React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.history.action_approve", defaultMessage: "provided approval for {stepname} step", values: { stepname: React.createElement("strong", null, getWorkflowStepNameFromGuid(hist.WorkflowApprovalStepGuid)) } }))) : null,
                                "Reject" === hist.Action ? React.createElement(FormattedMessage, { id: "page.request_details.history.action_reject", defaultMessage: "rejected the request" }) : null,
                                "ProvisioningStarted" === hist.Action ? (React.createElement(FormattedMessage, { id: "page.request_details.history.action_started", defaultMessage: "started provisioning the workspace" })) : null,
                                "ProvisioningComplete" === hist.Action ? (React.createElement(FormattedMessage, { id: "page.request_details.history.action_completed", defaultMessage: "completed workspace provisioning" })) : null,
                                "Archive" === hist.Action ? React.createElement(FormattedMessage, { id: "page.request_details.history.action_archive", defaultMessage: "has archived the request" }) : null,
                                "Edit" === hist.Action ? React.createElement(FormattedMessage, { id: "page.request_details.history.action_edit", defaultMessage: "has edited the request" }) : null,
                                "Retry" === hist.Action ? React.createElement(FormattedMessage, { id: "page.request_details.history.action_retry", defaultMessage: "has retried to provision the request" }) : null,
                                "Reapprove" === hist.Action ? React.createElement(FormattedMessage, { id: "page.request_details.history.action_restart", defaultMessage: "has restarted approval process" }) : null),
                            hist.Comment !== undefined && hist.Comment !== null && hist.Comment !== "" ? React.createElement("div", { className: "feedback", dangerouslySetInnerHTML: { __html: hist.Comment } }) : null),
                        React.createElement("p", { className: "kt-widget4__text" }, moment
                            .utc(hist.Date)
                            .local()
                            .format("llll"))))); })))));
});
export default RequestDetailsHistory;
