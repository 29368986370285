import * as React from "react";
import "./notification-content.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import AnimatedLoader from "app/components/common/animated-loader";
var NotificationContent = React.memo(function (props) {
    return (React.createElement("div", { className: "notification-content" },
        props.mode !== undefined && props.mode === "loading" && (React.createElement("div", { className: "nc-loader" },
            React.createElement(AnimatedLoader, { size: "small" }))),
        props.mode !== undefined && props.mode === "success" && (React.createElement("div", { className: "nc-success" },
            React.createElement(FontAwesomeIcon, { icon: faCheck }))),
        props.mode !== undefined && props.mode === "error" && (React.createElement("div", { className: "nc-error" },
            React.createElement(FontAwesomeIcon, { icon: faExclamationCircle }))),
        React.createElement("div", { className: "nc-text", dangerouslySetInnerHTML: { __html: props.text } })));
});
export default NotificationContent;
