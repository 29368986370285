import { MRT_Localization_DE } from "mantine-react-table/locales/de";
import { MRT_Localization_EN } from "mantine-react-table/locales/en";
import { MRT_Localization_ES } from "mantine-react-table/locales/es";
import { MRT_Localization_FR } from "mantine-react-table/locales/fr";
import { MRT_Localization_NO } from "mantine-react-table/locales/no";
import { MRT_Localization_PT } from "mantine-react-table/locales/pt";
import { MRT_Localization_SV } from "mantine-react-table/locales/sv";
import { useSelector } from "react-redux";
export var useMapLocaleToMRTLocalization = function () {
    var locale = useSelector(function (_a) {
        var i18n = _a.i18n;
        return i18n.lang;
    });
    var mapping = {
        de: MRT_Localization_DE,
        en: MRT_Localization_EN,
        es: MRT_Localization_ES,
        fr: MRT_Localization_FR,
        no: MRT_Localization_NO,
        pt: MRT_Localization_PT,
        sv: MRT_Localization_SV
    };
    return mapping[locale];
};
