var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./approvals.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as FeatureCurrentStore from "app/store/features/current";
import { Row, Col, Container } from "react-bootstrap";
import FormGroupSelect from "app/components/common/form-group-select";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
var Approvals = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(""), currentGroups = _b[0], setCurrentGroups = _b[1];
    var saveDiv = React.useRef();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    var save = function () {
        var asyncCallType = AsyncCallType.Update;
        dispatch(TenantConfigurationCurrentStore.Update(__assign(__assign({}, currentTenantConfiguration), { DefaultTemplateApprovalGroup: currentGroups })));
        AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentTenantConfiguration.GUID).then(function () {
            setBackToList(true);
            dispatch(FeatureCurrentStore.Clear());
        });
    };
    React.useEffect(function () {
        try {
            saveDiv.current.onclick = save;
        }
        catch (_a) { }
    });
    return (React.createElement("div", { className: "workspace-approvals fadein-500ms" },
        backToList && React.createElement(Redirect, { to: "/workspaces" }),
        React.createElement(Subtitle, { title: "Workspaces", subtitle: "Approvals" },
            React.createElement(NavLink, { to: "/workspaces" },
                React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
            React.createElement("div", { ref: saveDiv, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" }))),
        React.createElement("div", { className: "kt-portlet" },
            React.createElement("div", { className: "kt-portlet__body" },
                React.createElement(Container, { fluid: true },
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 8, lg: 6, xl: 4 },
                            React.createElement(FormGroupSelect, { defaultValue: currentTenantConfiguration.DefaultTemplateApprovalGroup, allowMultiple: true, label: "Default Template Approval Group:", onChange: function (groups) {
                                    setCurrentGroups(groups);
                                } }))))))));
});
export default Approvals;
