import { getFilteredChartTooltipPayload } from "@mantine/charts";
import "@mantine/charts/styles.css";
import { Group, Paper, Text } from "@mantine/core";
import React from "react";
import { useIntl } from "react-intl";
import { getDeltaString, getPastDeltaString, getValue } from "./orchestry-chart-tooltip.utils";
export var OrchestryChartDeltaTooltip = function (_a) {
    var label = _a.label, payload = _a.payload, lastPeriodData = _a.lastPeriodData;
    var intl = useIntl();
    if (!payload)
        return null;
    return (React.createElement(Paper, { p: "8px 12px", miw: 120, withBorder: true, shadow: "md", radius: "md", style: { padding: 0 } }, getFilteredChartTooltipPayload(payload).map(function (item) { return (React.createElement("div", { key: "".concat(label, "-").concat(item.value), style: { display: "flex", flexDirection: "column", alignItems: "start" } },
        React.createElement("div", { style: { width: "100%" } },
            React.createElement(Text, { c: "text-primary.0", fz: 11 }, item.payload.Date)),
        React.createElement(Group, { gap: "8px" },
            React.createElement(Text, { c: "text-primary.0", fz: 14, fw: "bold" }, getValue(item, lastPeriodData !== null && lastPeriodData !== void 0 ? lastPeriodData : [], label)),
            !!lastPeriodData && React.createElement(React.Fragment, null,
                " ",
                getDeltaString(item, lastPeriodData, label))),
        !!lastPeriodData && (React.createElement("div", null,
            React.createElement(Text, { c: "text-primary.0", fz: 11 }, getPastDeltaString(item, lastPeriodData, label, intl)))))); })));
};
