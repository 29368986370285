import * as React from "react";
import { Form } from "react-bootstrap";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
var ReportFilterGuestStatus = React.memo(function (props) {
    var placeholderText = "-- Select a " + props.Refiner.Title + "--";
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        props.FilterGuestStatuses !== undefined ? (React.createElement(FormDropdownMultiSelect, { list: props.FilterGuestStatuses.map(function (fc, index) {
                return ({
                    id: fc.Key,
                    title: fc.Value,
                    value: fc.Key
                });
            }), key: props.Index, placeholder: placeholderText, values: props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue.length > 0
                ? props.Refiner.CurrentValue.split("|")
                    //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID == cv))
                    .map(function (cv, index) {
                    var _a, _b;
                    return ({
                        id: cv,
                        title: (_b = (_a = props.FilterGuestStatuses) === null || _a === void 0 ? void 0 : _a.find(function (fc) { return fc.Key === cv; })) === null || _b === void 0 ? void 0 : _b.Value,
                        value: cv
                    });
                })
                : [], onChange: function (values) { return props.FilterOnChange(props.Refiner, values.map(function (v) { return v.value; }).join("|")); } })) : null));
});
export default ReportFilterGuestStatus;
