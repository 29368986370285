var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./naming-policy-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as NamingPolicyListStore from "app/store/naming-policies/list";
import * as NamingPolicyCurrentStore from "app/store/naming-policies/current";
import generateGuid from "app/utils/generateGuid";
import { Row, Col, Form } from "react-bootstrap";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FontIcon from "app/components/common/font-icon";
import { SubtitleButton } from "app/components/common/subtitle";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { Collapse } from "react-bootstrap";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import DisableArea from "app/components/common/disable-area";
import FormToggle from "app/components/common/form-toggle";
import PortletAlert from "app/components/common/portlet-alert";
import NamingPolicyPreview from "app/components/workspaces/naming-policies/naming-policy-preview";
import * as MetadataListStore from "app/store/metadata/list";
import Async from "app/components/common/async";
import * as AsyncTypes from "app/store/async";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakePortletForm } from "app/components/common/skeleton-loaders";
import * as TableList from "app/components/common/table-list";
import getValueBetweenSquareBrackets from "app/utils/getValueBetweenSquareBrackets";
import InfoTooltip from "app/components/common/infotooltip";
import * as UserCurrentStore from "app/store/users/current";
var NamingPolicyDetails = React.memo(function (props) {
    var _a;
    var dispatch = useDispatch();
    var lookupGuid = props.guid;
    var currentNamingPolicy = useSelector(function (state) { return state.namingPolicyCurrentState.item; });
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var _c = React.useState(false), formValid = _c[0], setFormValid = _c[1];
    var metadata = useSelector(function (state) { return state.metadataListState.items; });
    React.useEffect(function () {
        if (currentUser === null || currentUser === undefined || (currentUser !== null && currentUser !== undefined && currentUser.Id === undefined)) {
            dispatch(UserCurrentStore.Load());
        }
    }, []);
    React.useEffect(function () {
        //console.log("currentNamingPolicy", currentNamingPolicy);
    }, [currentNamingPolicy]);
    // Should only be run once.
    React.useEffect(function () {
        dispatch(MetadataListStore.Load([]));
        // Clear the currentTemplate in the state with a new GUID.
        if (currentNamingPolicy.GUID !== null && currentNamingPolicy.GUID !== undefined) {
            dispatch(NamingPolicyCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === null || lookupGuid === undefined) {
            var guid = generateGuid();
            dispatch(NamingPolicyCurrentStore.Update({ GUID: guid, Counters: [], CharacterLimitEnabled: false }));
        }
        else {
            // Load the /edit/guid load.
            dispatch(NamingPolicyCurrentStore.Load({ GUID: lookupGuid }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid]);
    var clearCurrentNamingPolicy = function () {
        dispatch(NamingPolicyCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(NamingPolicyCurrentStore.Clear());
        };
    }, []);
    var saveAndAddModifyNamingPolicy = function () {
        if (formValid) {
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === null || lookupGuid === undefined) {
                // First, add the new template to template list
                asyncCallType = AsyncCallType.Add;
                dispatch(NamingPolicyListStore.AddItem(currentNamingPolicy));
            }
            else {
                dispatch(NamingPolicyListStore.UpdateItem(currentNamingPolicy));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentNamingPolicy.GUID).then(function () {
                setBackToList(true);
                dispatch(NamingPolicyCurrentStore.Clear());
            });
        }
    };
    var groupNameDelimiterOptions = [
        {
            id: "2",
            title: "-",
            description: "Dash",
            value: "-"
        },
        {
            id: "1",
            title: "--",
            description: "Long Dash",
            value: "--"
        },
        {
            id: "3",
            title: "_",
            description: "Underscore",
            value: "_"
        },
        {
            id: "4",
            title: "",
            description: "No Spaces",
            value: ""
        }
    ];
    var namingConventionTypes = [
        {
            id: "0",
            title: "Orchestry Metadata",
            value: "orchestry-metadata"
        },
        {
            id: "1",
            title: "User Attribute",
            value: "metadata"
        },
        {
            id: "2",
            title: "String",
            value: "string"
        },
        {
            id: "3",
            title: "Incremental Counter",
            value: "counter"
        }
    ];
    var metadataTypes = [
        {
            id: "1",
            title: "Department",
            value: "Department"
        },
        {
            id: "2",
            title: "Company",
            value: "Company"
        },
        {
            id: "3",
            title: "Office",
            value: "Office"
        },
        {
            id: "4",
            title: "StateOrProvince",
            value: "StateOrProvince"
        },
        {
            id: "5",
            title: "CountryOrRegion",
            value: "CountryOrRegion"
        },
        {
            id: "6",
            title: "JobTitle",
            value: "JobTitle"
        }
    ];
    var counterTypes = [
        {
            id: "0",
            title: "Numeric - 1",
            value: "numeric-0zero"
        },
        {
            id: "1",
            title: "Numeric Leading Zero - 01",
            value: "numeric-1zero"
        },
        {
            id: "2",
            title: "Numeric Leading Zero - 001",
            value: "numeric-2zero"
        },
        {
            id: "3",
            title: "Alphabetical Lower Case - a",
            value: "alpha-lower"
        },
        {
            id: "4",
            title: "Alphabetical Upper Case - A",
            value: "alpha-upper"
        }
    ];
    var update = function (pstype, psfix, isUrl, action) {
        var _a, _b, _c;
        var psFixArray = [];
        if (pstype === "p" && isUrl === false) {
            psFixArray =
                currentNamingPolicy.TitlePrefixValue !== undefined && currentNamingPolicy.TitlePrefixValue !== null && currentNamingPolicy.TitlePrefixValue !== ""
                    ? __spreadArray([], JSON.parse(currentNamingPolicy.TitlePrefixValue), true) : [];
        }
        if (pstype === "p" && isUrl === true) {
            psFixArray =
                currentNamingPolicy.UrlPrefixValue !== undefined && currentNamingPolicy.UrlPrefixValue !== null && currentNamingPolicy.UrlPrefixValue !== ""
                    ? __spreadArray([], JSON.parse(currentNamingPolicy.UrlPrefixValue), true) : [];
        }
        if (pstype === "s" && isUrl === false) {
            psFixArray =
                currentNamingPolicy.TitleSuffixValue !== undefined && currentNamingPolicy.TitleSuffixValue !== null && currentNamingPolicy.TitleSuffixValue !== ""
                    ? __spreadArray([], JSON.parse(currentNamingPolicy.TitleSuffixValue), true) : [];
        }
        if (pstype === "s" && isUrl === true) {
            psFixArray =
                currentNamingPolicy.UrlSuffixValue !== undefined && currentNamingPolicy.UrlSuffixValue !== null && currentNamingPolicy.UrlSuffixValue !== ""
                    ? __spreadArray([], JSON.parse(currentNamingPolicy.UrlSuffixValue), true) : [];
        }
        var jsonObjectBefore = JSON.stringify(psFixArray);
        if (action === "add") {
            var newFix = {
                GUID: generateGuid(),
                type: psfix.type,
                value: ""
            };
            psFixArray = __spreadArray(__spreadArray([], psFixArray, true), [newFix], false);
        }
        if (action === "update") {
            if (psfix.type === "counter") {
                if (psfix.value !== null && psfix.value !== undefined && psfix.value !== "" && psfix.value.indexOf("[") === -1) {
                    var counterType = psfix.value.indexOf("numeric") > -1 ? "Numeric" : psfix.value.indexOf("alpha") > -1 ? "Alpha" : undefined;
                    var counterName = "";
                    var nameCounter = 0;
                    var _loop_1 = function () {
                        nameCounter++;
                        var potentialNewName = counterType + " - " + nameCounter;
                        //console.log("currentNamingPolicy.Counters", currentNamingPolicy.Counters);
                        var exists = (_a = currentNamingPolicy.Counters) === null || _a === void 0 ? void 0 : _a.some(function (c) { return c.CounterName === potentialNewName; });
                        if (exists === false) {
                            counterName = potentialNewName;
                        }
                    };
                    while (counterName === "") {
                        _loop_1();
                    }
                    psfix.value = psfix.value + "[" + counterName + "]";
                }
            }
            psFixArray = psFixArray.map(function (ps) { return (ps.GUID === psfix.GUID ? (psfix.type !== ps.type ? __assign(__assign({}, psfix), { value: undefined }) : psfix) : ps); });
        }
        if (action === "remove") {
            psFixArray = psFixArray.filter(function (ps) { return (ps.GUID === psfix.GUID ? false : true); });
            //console.log("remove array", psFixArray);
        }
        var updateObject = {};
        var jsonObject = JSON.stringify(psFixArray);
        if (pstype === "p" && isUrl === false) {
            updateObject.TitlePrefixValue = jsonObject;
        }
        if (pstype === "s" && isUrl === false) {
            updateObject.TitleSuffixValue = jsonObject;
        }
        if (pstype === "p" && isUrl === true) {
            updateObject.UrlPrefixValue = jsonObject;
        }
        if (pstype === "s" && isUrl === true) {
            updateObject.UrlSuffixValue = jsonObject;
        }
        if (psfix.type === "counter") {
            if (psfix.value !== null && psfix.value !== undefined && psfix.value !== "" && psfix.value.indexOf("[") > -1 && (psfix.value.indexOf("numeric") > -1 || psfix.value.indexOf("alpha") > -1)) {
                var counterName_1 = getValueBetweenSquareBrackets(psfix.value);
                if (action === "add" || action === "update") {
                    if (((_b = currentNamingPolicy.Counters) === null || _b === void 0 ? void 0 : _b.some(function (c) { return c.CounterName === counterName_1; })) === false) {
                        updateObject.Counters = __spreadArray(__spreadArray([], currentNamingPolicy.Counters, true), [
                            { CounterName: counterName_1, CounterValue: psfix.value.indexOf("numeric") > -1 ? "0" : psfix.value.indexOf("lower") > -1 ? "a" : "A" }
                        ], false);
                    }
                }
                if (action === "remove") {
                    if (((_c = currentNamingPolicy.Counters) === null || _c === void 0 ? void 0 : _c.some(function (c) { return c.CounterName === counterName_1; })) === true) {
                        updateObject.Counters = currentNamingPolicy.Counters.filter(function (c) { return (c.CounterName === counterName_1 ? false : true); });
                    }
                }
            }
        }
        //console.log("ps fix array", psFixArray);
        if (jsonObjectBefore !== jsonObject) {
            dispatch(NamingPolicyCurrentStore.Update(updateObject));
        }
    };
    var toggleUrlEnabled = function (checked) {
        if (currentNamingPolicy.UrlDelimiter === undefined || currentNamingPolicy.UrlDelimiter === null) {
            dispatch(NamingPolicyCurrentStore.Update({
                RenameWorkspaceUrl: checked,
                UrlDelimiter: "-"
            }));
        }
        else {
            dispatch(NamingPolicyCurrentStore.Update({
                RenameWorkspaceUrl: checked
            }));
        }
    };
    var getOrchestryMetadataOption = function () {
        var returnOptions = [];
        metadata
            .filter(function (md) { return md.SingleMultiple === "Single" && md.RequiredInDetailsForm === true; })
            .forEach(function (md, index) {
            returnOptions.push({
                id: index + "",
                title: md.Title,
                value: md.GUID
            });
        });
        return returnOptions;
    };
    var getOrchestryMetadataNamesAsValues = function () {
        var values = [];
        metadata
            .filter(function (md) { return md.SingleMultiple === "Single" && md.RequiredInDetailsForm === true; })
            .forEach(function (md, index) {
            values.push({
                FieldGUID: md.GUID,
                FieldTitle: md.Title,
                Values: md.Title + " Value",
                FieldType: md.FieldType
            });
        });
        return values;
    };
    var fixEditor = function (enabled, jsonStringValue, fixType, isUrl) {
        return (React.createElement("div", { className: "fix-editor" + (enabled ? "" : " disabled"), style: { marginBottom: "50px" } },
            jsonStringValue !== undefined && jsonStringValue !== null && jsonStringValue !== ""
                ? __spreadArray([], JSON.parse(jsonStringValue), true).map(function (fix, fixIndex) { return (React.createElement(Row, { className: "fix-item editable-item", key: fixIndex },
                    React.createElement(Col, { sm: 6, className: "fix-left" },
                        React.createElement("div", { className: "count" }, fixIndex + 1),
                        React.createElement(FormDropdownSelect, { list: namingConventionTypes, value: fix.type, onChange: function (value) {
                                update(fixType, __assign(__assign({}, fix), { type: value }), isUrl, "update");
                            } })),
                    React.createElement(Col, { sm: 6, className: "fix-right" },
                        fix.type === "metadata" ? (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: metadataTypes, value: fix.value, showRedOutlineIfNothingSelected: true, onChange: function (value) {
                                    update(fixType, __assign(__assign({}, fix), { value: value }), isUrl, "update");
                                } }))) : fix.type === "orchestry-metadata" ? (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: getOrchestryMetadataOption(), value: fix.value, showRedOutlineIfNothingSelected: true, onChange: function (value) {
                                    update(fixType, __assign(__assign({}, fix), { value: value }), isUrl, "update");
                                } }))) : fix.type === "counter" ? (React.createElement(React.Fragment, null, fix.value === undefined || fix.value === "" || fix.value === null ? (React.createElement(FormDropdownSelect, { list: counterTypes, value: fix.value, showRedOutlineIfNothingSelected: true, onChange: function (value) {
                                update(fixType, __assign(__assign({}, fix), { value: value }), isUrl, "update");
                            } })) : (React.createElement(Form.Control, { value: getValueBetweenSquareBrackets(fix.value), disabled: true })))) : (React.createElement(React.Fragment, null,
                            React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "title-suffix-string-input-" + fixIndex, ClassNameNotValid: "red-outline" },
                                React.createElement(Form.Control, { type: "text", placeholder: "", value: fix.value, onChange: function (event) {
                                        update(fixType, __assign(__assign({}, fix), { value: event.target.value }), isUrl, "update");
                                    } })))),
                        React.createElement(FormValidator, { UniqueIdentifier: "title-suffix-dropdown-" + fixIndex, Enabled: fix.type !== "string", Validators: ["dropdown-has-value"], Value: fix.value, FieldName: "Metadata Type" }),
                        React.createElement(FormValidator, { UniqueIdentifier: "title-suffix-string-input-" + fixIndex, Enabled: fix.type === "string", Validators: ["string-not-empty"], Value: fix.value, FieldName: "String" }),
                        React.createElement("div", { className: "remove", onClick: function () {
                                update(fixType, fix, isUrl, "remove");
                            } },
                            React.createElement(FontIcon, { iconString: "times-circle" }))))); })
                : null,
            React.createElement(Row, { className: "fix-item new-mode" },
                React.createElement(Col, { sm: 6, className: "fix-left" },
                    React.createElement(FormDropdownSelect, { placeholder: "-- " + (fixType === "p" ? "Add Prefix" : "Add Suffix") + " --", list: namingConventionTypes, afterSelectReset: true, onChange: function (value) {
                            update(fixType, { GUID: "", type: value }, isUrl, "add");
                        } })),
                React.createElement(Col, { sm: 6, className: "fix-right" }))));
    };
    var updateCouterValue = function (counterName, counterValue) {
        var _a;
        var updateObject = {};
        updateObject.Counters = (_a = currentNamingPolicy.Counters) === null || _a === void 0 ? void 0 : _a.map(function (c) { return (c.CounterName !== counterName ? c : __assign(__assign({}, c), { CounterValue: counterValue })); });
        dispatch(NamingPolicyCurrentStore.Update(updateObject));
    };
    var getCounterType = function (counterName) {
        var foundType = "";
        function getTypeFromJson(jsonString) {
            if (jsonString !== undefined && jsonString !== null && jsonString !== "") {
                __spreadArray([], JSON.parse(jsonString), true).forEach(function (fix, fixIndex) {
                    if (fix.value !== undefined && fix.value !== null && fix.value !== "" && fix.value.indexOf("[") > -1) {
                        if (getValueBetweenSquareBrackets(fix.value) === counterName) {
                            foundType = fix.value.indexOf("numeric") > -1 ? "Numeric" : fix.value.indexOf("alpha") > -1 ? "Alpha" : "";
                        }
                    }
                });
            }
        }
        getTypeFromJson(currentNamingPolicy.TitlePrefixValue);
        getTypeFromJson(currentNamingPolicy.TitleSuffixValue);
        getTypeFromJson(currentNamingPolicy.UrlPrefixValue);
        getTypeFromJson(currentNamingPolicy.UrlSuffixValue);
        return foundType;
    };
    var getCounterFullType = function (counterName) {
        var foundType = "";
        function getTypeFromJson(jsonString) {
            if (jsonString !== undefined && jsonString !== null && jsonString !== "") {
                __spreadArray([], JSON.parse(jsonString), true).forEach(function (fix, fixIndex) {
                    if (fix.value !== undefined && fix.value !== null && fix.value !== "" && fix.value.indexOf("[") > -1) {
                        if (getValueBetweenSquareBrackets(fix.value) === counterName) {
                            foundType = fix.value.replace(/ *\[[^\]]*]/, "");
                        }
                    }
                });
            }
        }
        getTypeFromJson(currentNamingPolicy.TitlePrefixValue);
        getTypeFromJson(currentNamingPolicy.TitleSuffixValue);
        getTypeFromJson(currentNamingPolicy.UrlPrefixValue);
        getTypeFromJson(currentNamingPolicy.UrlSuffixValue);
        var foundCounterOption = counterTypes.find(function (c) { return c.value === foundType; });
        if (foundCounterOption !== undefined) {
            foundType = foundCounterOption.title;
        }
        return foundType;
    };
    React.useEffect(function () {
        if (currentNamingPolicy.TitleInputDisabled === true) {
            if (currentNamingPolicy.UrlInputDisabled === false) {
                dispatch(NamingPolicyCurrentStore.Update({ UrlInputDisabled: true }));
            }
        }
    }, [currentNamingPolicy.TitleInputDisabled]);
    return (React.createElement("div", { className: "naming-policy-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                //console.log("In OnChangeIsValid", isValid);
                setFormValid(isValid);
            }, UniqueIdentifier: "namingPolicyValidation" },
            backToList && React.createElement(Redirect, { to: "/workspaces/naming-policies" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Naming Policies", subTitle: props.guid ? "Edit" : "New", titleLink: "/workspaces/naming-policies" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/naming-policies", onClick: function () { return clearCurrentNamingPolicy(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement("div", { onClick: function () { return saveAndAddModifyNamingPolicy(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            currentNamingPolicy !== null && currentNamingPolicy !== undefined ? (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                        React.createElement(Portlet, { className: "current-status" },
                            React.createElement(PortletBody, null,
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-title" },
                                    React.createElement(Form.Label, null,
                                        "Policy Name ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(Form.Control, { placeholder: "Enter Policy Name", defaultValue: currentNamingPolicy.Title, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ Title: event.target.value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "policy-title", Validators: ["string-not-empty", "string-is-more-than-2"], Value: currentNamingPolicy.Title })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                                    React.createElement(Form.Label, null, "Policy Description (Optional)"),
                                    React.createElement(Form.Control, { as: "textarea", 
                                        //placeholder="Enter Workflow Description"
                                        rows: 3, defaultValue: currentNamingPolicy.Description, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ Description: event.target.value })); } })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-instructions" },
                                    React.createElement(Form.Label, null, "Requestor Instructions (Optional)"),
                                    React.createElement(Form.Control, { as: "textarea", 
                                        //placeholder="Enter Workflow Description"
                                        rows: 3, defaultValue: currentNamingPolicy.Instructions, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ Instructions: event.target.value })); } })))))),
                React.createElement(Row, null,
                    React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                        React.createElement(Portlet, { className: "character-limit" },
                            React.createElement(PortletHeader, { title: "Character Limit" }),
                            React.createElement(PortletBody, { className: "character-limit" },
                                React.createElement("div", { className: "character-limit-description" }, "If enabled, apply a maximum length to any new Workspace Name and URL, including any configured Prefix and/or Suffix."),
                                React.createElement(Form.Group, { className: "form-group check-group", controlId: "control-character-limit-toggle" },
                                    React.createElement(FormToggle, { handleChange: function (checked) {
                                            dispatch(NamingPolicyCurrentStore.Update({ CharacterLimitEnabled: checked }));
                                            if (!checked) {
                                                dispatch(NamingPolicyCurrentStore.Update({ CharacterLimit: undefined }));
                                            }
                                            else {
                                                dispatch(NamingPolicyCurrentStore.Update({ CharacterLimit: 36 }));
                                            }
                                        }, checked: currentNamingPolicy.CharacterLimitEnabled, defaultChecked: currentNamingPolicy.CharacterLimitEnabled }),
                                    React.createElement(Form.Label, null, "Character Limit")),
                                React.createElement(Collapse, { in: currentNamingPolicy.CharacterLimitEnabled },
                                    React.createElement("div", null,
                                        React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "character-limit" },
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-character-limit-number" },
                                                React.createElement("div", { className: "character-number-group" },
                                                    React.createElement(Form.Label, null, "Character Limit"),
                                                    React.createElement(InfoTooltip, { content: "Names will truncate in the teams app at around 36 characters. We recommend sticking to 36 or below for clarity." },
                                                        React.createElement("i", { className: "fa fa-info-circle" }))),
                                                React.createElement(Form.Control, { type: "number", value: (_a = currentNamingPolicy === null || currentNamingPolicy === void 0 ? void 0 : currentNamingPolicy.CharacterLimit) === null || _a === void 0 ? void 0 : _a.toString(), onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ CharacterLimit: Number(event.target.value) })); } }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "character-limit", Validators: ["number-not-empty", "number-greater-than-equalto-x"], Parameter: 3, Value: currentNamingPolicy === null || currentNamingPolicy === void 0 ? void 0 : currentNamingPolicy.CharacterLimit, Enabled: currentNamingPolicy === null || currentNamingPolicy === void 0 ? void 0 : currentNamingPolicy.CharacterLimitEnabled, ShowFirst: true, ErrorMessage: "Number must be greater than or equal to 3" }))))))))),
                React.createElement(Row, null,
                    React.createElement(Async, { uid: MetadataListStore.MetadataListActionTypes.Metadata_List_Load, status: AsyncTypes.AsyncStatus.Started },
                        React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                            React.createElement(Portlet, { className: "current-status" },
                                React.createElement(PortletHeader, { title: "Name Prefix and Suffix Configuration" }),
                                React.createElement(PortletBody, null,
                                    React.createElement(SkeletonLoaderFakeBox, null))))),
                    React.createElement(Async, { uid: MetadataListStore.MetadataListActionTypes.Metadata_List_Load, status: AsyncTypes.AsyncStatus.Succeeded },
                        React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                            React.createElement(Portlet, { className: "current-status" },
                                React.createElement(PortletHeader, { title: "Name Prefix and Suffix Configuration" }),
                                React.createElement(PortletBody, null,
                                    React.createElement(Form.Group, { controlId: "control-title-enabled", className: "form-group checkbox-green toggle" },
                                        React.createElement(FormToggle, { checked: currentNamingPolicy.RenameWorkspaceTitle, defaultChecked: false, 
                                            // handleChange={this.props.handleChange}
                                            handleChange: function (checked) { return dispatch(NamingPolicyCurrentStore.Update({ RenameWorkspaceTitle: checked })); } }),
                                        React.createElement(Form.Label, null, "Title Naming Conventions Enabled")),
                                    React.createElement(PortletAlert, { size: "regular", type: "warning", icon: "info", title: "Office 365 teams or sites created by users will follow the following name format:", message: React.createElement(NamingPolicyPreview, { currentNamingPolicy: currentNamingPolicy, type: "name", value: currentNamingPolicy.TitleInputDisabled === undefined || currentNamingPolicy.TitleInputDisabled === false ? "Team Or Site Name" : "", customMetadataValues: getOrchestryMetadataNamesAsValues() }) }),
                                    React.createElement(DisableArea, { isDisabled: currentNamingPolicy.RenameWorkspaceTitle === undefined || currentNamingPolicy.RenameWorkspaceTitle === false, useOverlay: true },
                                        React.createElement(Form.Group, { controlId: "control-title-prefix-enabled", className: "form-group checkbox-green title-check" },
                                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Add Prefix"), checked: currentNamingPolicy.TitlePrefixEnabled, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ TitlePrefixEnabled: event.target.checked })); } })),
                                        React.createElement(Collapse, { in: currentNamingPolicy.TitlePrefixEnabled, appear: true },
                                            React.createElement("div", null,
                                                React.createElement("div", { className: "divider-line" }),
                                                fixEditor(currentNamingPolicy.TitlePrefixEnabled, currentNamingPolicy.TitlePrefixValue, "p", false))),
                                        React.createElement(Form.Group, { controlId: "control-title-suffix-enabled", className: "form-group checkbox-green title-check" },
                                            React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Add Suffix"), checked: currentNamingPolicy.TitleSuffixEnabled, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ TitleSuffixEnabled: event.target.checked })); } })),
                                        React.createElement(Collapse, { in: currentNamingPolicy.TitleSuffixEnabled, appear: true },
                                            React.createElement("div", null,
                                                React.createElement("div", { className: "divider-line" }),
                                                fixEditor(currentNamingPolicy.TitleSuffixEnabled, currentNamingPolicy.TitleSuffixValue, "s", false))),
                                        React.createElement(Form.Group, { controlId: "control-title-enabled", className: "form-group checkbox-green toggle", style: { marginBottom: "20px" } },
                                            React.createElement(FormToggle, { checked: currentNamingPolicy.TitleInputDisabled, defaultChecked: false, 
                                                // handleChange={this.props.handleChange}
                                                handleChange: function (checked) { return dispatch(NamingPolicyCurrentStore.Update({ TitleInputDisabled: checked })); }, theme: "small-green" }),
                                            React.createElement(Form.Label, null, "Do not use Workspace name in title name"))))),
                            React.createElement(Portlet, { className: "current-status" },
                                React.createElement(PortletHeader, { title: "URL Prefix and Suffix Configuration" }),
                                React.createElement(PortletBody, null,
                                    React.createElement("div", { className: "" },
                                        React.createElement(Form.Group, { controlId: "control-url-enabled", className: "form-group checkbox-green toggle" },
                                            React.createElement(FormToggle, { checked: currentNamingPolicy.RenameWorkspaceUrl, defaultChecked: false, 
                                                //handleChange={(checked) => dispatch(NamingPolicyCurrentStore.Update({RenameWorkspaceUrl:checked}))}
                                                handleChange: function (checked) { return toggleUrlEnabled(checked); } }),
                                            React.createElement(Form.Label, null, "URL Conventions Enabled")),
                                        React.createElement(PortletAlert, { size: "regular", type: "warning", icon: "info", title: "Office 365 teams or sites created by users will follow the following url format:", message: React.createElement(NamingPolicyPreview, { currentNamingPolicy: currentNamingPolicy, type: "url", value: currentNamingPolicy.UrlInputDisabled === undefined || currentNamingPolicy.UrlInputDisabled === false ? "Team Or Site Name" : "", customMetadataValues: getOrchestryMetadataNamesAsValues() }) }),
                                        React.createElement(DisableArea, { isDisabled: currentNamingPolicy.RenameWorkspaceUrl === undefined || currentNamingPolicy.RenameWorkspaceUrl === false, useOverlay: true },
                                            React.createElement(Row, { className: "delimiter-options" },
                                                React.createElement(Col, { md: 6 },
                                                    React.createElement(Form.Group, { className: "form-group", controlId: "control-delimeter" },
                                                        React.createElement(Form.Label, null, "Url Delimiter"),
                                                        React.createElement(FormDropdownSelect, { list: groupNameDelimiterOptions, onChange: function (value) { return dispatch(NamingPolicyCurrentStore.Update({ UrlDelimiter: value })); }, 
                                                            //defaultValue={currentNamingPolicy.UrlDelimiter}
                                                            value: currentNamingPolicy.UrlDelimiter })))),
                                            React.createElement("div", { className: "add-checkbox-row" },
                                                React.createElement(Form.Group, { controlId: "control-url-prefix-enabled", className: "form-group checkbox-green title-check" },
                                                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Add Prefix"), checked: currentNamingPolicy.UrlPrefixEnabled, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ UrlPrefixEnabled: event.target.checked })); } })),
                                                React.createElement("div", { className: "info-right" }, currentNamingPolicy.UrlPrefixValue !== null && currentNamingPolicy.UrlPrefixValue !== undefined &&
                                                    __spreadArray([], JSON.parse(currentNamingPolicy.UrlPrefixValue), true).some(function (fix) { return fix.type === "string"; }) ? (React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", message: "Please note that any special characters will be stripped out due to not being supported for URL's" })) : null)),
                                            React.createElement(Collapse, { in: currentNamingPolicy.UrlPrefixEnabled, appear: true },
                                                React.createElement("div", null,
                                                    React.createElement("div", { className: "divider-line" }),
                                                    fixEditor(currentNamingPolicy.UrlPrefixEnabled, currentNamingPolicy.UrlPrefixValue, "p", true))),
                                            React.createElement("div", { className: "add-checkbox-row" },
                                                React.createElement(Form.Group, { controlId: "control-url-suffix-enabled", className: "form-group checkbox-green title-check" },
                                                    React.createElement(Form.Check, { type: "checkbox", label: React.createElement(React.Fragment, null, "Add Suffix"), checked: currentNamingPolicy.UrlSuffixEnabled, onChange: function (event) { return dispatch(NamingPolicyCurrentStore.Update({ UrlSuffixEnabled: event.target.checked })); } })),
                                                React.createElement("div", { className: "info-right" }, currentNamingPolicy.UrlSuffixValue !== null && currentNamingPolicy.UrlSuffixValue !== undefined &&
                                                    __spreadArray([], JSON.parse(currentNamingPolicy.UrlSuffixValue), true).some(function (fix) { return fix.type === "string"; }) ? (React.createElement(PortletAlert, { size: "small", type: "warning", icon: "info", message: "Please note that any special characters will be stripped out due to not being supported for URL's" })) : null)),
                                            React.createElement(Collapse, { in: currentNamingPolicy.UrlSuffixEnabled, appear: true },
                                                React.createElement("div", null,
                                                    React.createElement("div", { className: "divider-line" }),
                                                    fixEditor(currentNamingPolicy.UrlSuffixEnabled, currentNamingPolicy.UrlSuffixValue, "s", true))),
                                            React.createElement(DisableArea, { isDisabled: currentNamingPolicy.TitleInputDisabled, useOverlay: true },
                                                React.createElement(Form.Group, { controlId: "control-title-enabled", className: "form-group checkbox-green toggle", style: { marginBottom: "0" } },
                                                    React.createElement(FormToggle, { checked: currentNamingPolicy.UrlInputDisabled, defaultChecked: false, 
                                                        // handleChange={this.props.handleChange}
                                                        handleChange: function (checked) { return dispatch(NamingPolicyCurrentStore.Update({ UrlInputDisabled: checked })); }, theme: "small-green" }),
                                                    React.createElement(Form.Label, null, "Do not use Workspace name in url"))))))))),
                    React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                        React.createElement(Portlet, { className: "counter-options" },
                            React.createElement(PortletHeader, { title: "Counter Options" }),
                            React.createElement(PortletBody, null,
                                React.createElement("div", { className: "counter-description" }, "Counters used in this naming policy."),
                                React.createElement(TableList.Context, { listItems: currentNamingPolicy.Counters, showLoader: false, wrappedInPortlet: false },
                                    React.createElement(TableList.TableList, null,
                                        React.createElement(TableList.HeaderItem, null, "Counter Name"),
                                        React.createElement(TableList.HeaderItem, null, "Counter Value"),
                                        React.createElement(TableList.ContentItemCustomRender, { render: function (counter) { return (React.createElement("div", { className: "counter-title-col" },
                                                React.createElement("div", { className: "name" }, counter.CounterName),
                                                React.createElement("div", { className: "description" }, getCounterFullType(counter.CounterName)))); } }),
                                        React.createElement(TableList.ContentItemCustomRender, { render: function (counter) { return (React.createElement("div", { className: "counter-value-editor" },
                                                React.createElement(Form.Control, { value: counter.CounterValue, onChange: function (event) { return updateCouterValue(counter.CounterName, event.target.value); } }),
                                                React.createElement("div", null, getCounterType(counter.CounterName) === "Numeric" ? "Must be a number" : "Must be a letter"))); } }))),
                                React.createElement("div", { className: "counter-alert" },
                                    React.createElement(PortletAlert, { icon: "info", type: "warning", message: React.createElement("div", null, "Please note that changing the default counter values could cause conflicts with Names and URL's. Please ensure that the current value does not conflict with existing values.") }))))),
                    React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                        React.createElement(Portlet, { className: "current-status" },
                            React.createElement(PortletHeader, { title: "Naming Policy Template Associations" }),
                            React.createElement(PortletBody, null,
                                React.createElement("ul", { style: { paddingLeft: "20px", marginBottom: "0" } }, currentNamingPolicy.GUID !== null && currentNamingPolicy.GUID !== undefined && currentNamingPolicy.AssociatedTemplates !== null && currentNamingPolicy.AssociatedTemplates !== undefined && currentNamingPolicy.AssociatedTemplates.length > 0 ? (currentNamingPolicy.AssociatedTemplates.map(function (assTemplate) { var _a; return React.createElement("li", { className: "associated-template" }, (_a = assTemplate.Item) === null || _a === void 0 ? void 0 : _a.Title); })) : (React.createElement("li", null,
                                    React.createElement("strong", null, "No templates associated"),
                                    React.createElement("p", null, "Enable the policy on a template in the template configuration")))))))))) : (React.createElement(Row, null,
                React.createElement(Col, { xl: 8, lg: 10, md: 12 },
                    React.createElement(SkeletonLoaderFakePortletForm, null)))))));
});
export default NamingPolicyDetails;
