import * as React from "react";
import { getDaysInactiveClass } from "../report-column-workspace-name";
var ReportColumnWorkspaceDaysInactive = React.memo(function (props) {
    var _a;
    var _b = React.useState(null), reportItem = _b[0], setReportItem = _b[1];
    React.useEffect(function () {
        var _a, _b;
        if (props.Item.ExtendedProperties !== undefined && props.Item.ExtendedProperties !== null && ((_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosWorkspaceDetails) !== undefined) {
            setReportItem((_b = props.Item.ExtendedProperties) === null || _b === void 0 ? void 0 : _b.CosmosWorkspaceDetail);
        }
        else {
            setReportItem(props.Item);
        }
    }, [props.Item]);
    // <div className={"days-inactive" + (item.DaysInactive !== undefined && item.DaysInactive !== null ? getDaysInactiveClass(item.DaysInactive) : "")}>
    //    {item.ExtendedProperties?.CosmosWorkspaceDetails?.IsArchived === false ? (item[col.ProperyName] as number)?.toLocaleString() : "NA"}
    // </div>;
    return reportItem !== null ? (React.createElement("div", { className: "days-inactive" + (reportItem.DaysInactive !== undefined && reportItem.DaysInactive !== null ? getDaysInactiveClass(reportItem.DaysInactive) : "") }, reportItem.IsArchived === false ? (_a = reportItem[props.Col.ProperyName]) === null || _a === void 0 ? void 0 : _a.toLocaleString() : "NA")) : null;
});
export default ReportColumnWorkspaceDaysInactive;
