var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import iconViva from "app/assets/img/icon-viva-engage.svg";
import NoResults from "app/components/common/no-results";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import "app/components/directory/directory.scss";
import * as DirectoryStore from "app/store/directory/search";
import clsx from "clsx";
import * as React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Modal } from "react-bootstrap";
import { Flipped, Flipper } from "react-flip-toolkit";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./directory-favorites.scss";
var DirectoryFavorites = React.memo(function (props) {
    var _a, _b;
    var intl = useIntl();
    var dispatch = useDispatch();
    var directoryFavs = useSelector(function (state) { return state.directorySearchState.Favs; });
    var isFavsLoading = useSelector(function (state) { return state.directorySearchState.IsFavsLoading; });
    var teamDirectoryWebPartState = useSelector(function (state) { return state.teamDirectoryWebPartState; });
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var showFavs = ((_a = teamDirectoryWebPartState === null || teamDirectoryWebPartState === void 0 ? void 0 : teamDirectoryWebPartState.directoryProps) === null || _a === void 0 ? void 0 : _a.showFavorites) !== false;
    var showDirectory = ((_b = teamDirectoryWebPartState === null || teamDirectoryWebPartState === void 0 ? void 0 : teamDirectoryWebPartState.directoryProps) === null || _b === void 0 ? void 0 : _b.showDirectory) !== false;
    var showOnlyFavs = isInSharepoint && showFavs && !showDirectory;
    React.useEffect(function () {
        document.addEventListener("mousedown", handleFavMenuClose);
        return function () {
            document.removeEventListener("mousedown", handleFavMenuClose);
        };
    }, []);
    var handleFavMenuClose = function (e) {
        if (e.target !== null) {
            //console.log("current target",e.target )
            var isInFavMenuClick = checkParentHasClass(e.target, 6);
            if (isInFavMenuClick === null) {
                var favItems = document.querySelectorAll(".fav-menu");
                favItems.forEach(function (element) {
                    if (element.classList.contains("menu-open")) {
                        element.classList.remove("menu-open");
                    }
                });
            }
        }
    };
    var checkParentHasClass = function (element, depth) {
        //console.log("Click Element", element.classList);
        if (depth !== 0) {
            depth = depth - 1;
            if (element === undefined) {
                return null;
            }
            else if (element.classList === undefined || element.classList === null) {
                return null;
            }
            else if (element.classList.contains("fav-menu")) {
                return element;
            }
            else if (element.parentElement === null) {
                return null;
            }
            else {
                return checkParentHasClass(element.parentElement, depth);
            }
        }
        return null;
    };
    var toggleFavMenu = function (e) {
        var parentElement = e.currentTarget.parentElement;
        if (parentElement !== null) {
            if (parentElement.classList.contains("menu-open")) {
                parentElement.classList.remove("menu-open");
            }
            else {
                parentElement.classList.add("menu-open");
            }
        }
        var favItems = document.querySelectorAll(".fav-menu");
        favItems.forEach(function (element) {
            if (element !== parentElement && element.classList.contains("menu-open")) {
                element.classList.remove("menu-open");
            }
        });
    };
    var removeItem = function (item, e) {
        var tempArray = directoryFavs.filter(function (i) { return i.SiteId !== item.SiteId; });
        dispatch(DirectoryStore.UpdateFavs(__spreadArray([], tempArray, true)));
        if (e.currentTarget !== null && e.currentTarget.parentElement !== null && e.currentTarget.parentElement.parentElement !== null) {
            e.currentTarget.parentElement.parentElement.classList.remove("menu-open");
        }
    };
    var moveItem = function (item, direction, e) {
        var currentIndex = -1;
        directoryFavs.forEach(function (loopItem, index) {
            if (item.SiteId === loopItem.SiteId) {
                currentIndex = index;
            }
        });
        var tempArray = __spreadArray([], directoryFavs.filter(function (i) { return i.SiteId !== item.SiteId; }), true);
        //let tempArray = [...directoryFavs];
        if (currentIndex > -1) {
            var newIndex = direction === "u" ? currentIndex - 1 : currentIndex + 1;
            tempArray.splice(newIndex, 0, item);
        }
        //tempArray = array_move(tempArray, index, direction === "u" ? (index + 1): (index - 1));
        dispatch(DirectoryStore.UpdateFavs(tempArray));
        if (e.currentTarget !== null && e.currentTarget.parentElement !== null && e.currentTarget.parentElement.parentElement !== null) {
            e.currentTarget.parentElement.parentElement.classList.remove("menu-open");
        }
    };
    return (React.createElement("div", { className: "directory-favorites", "data-pw": "directory-favorites" },
        React.createElement("div", { className: clsx("fav-title-area", { "mobile-open": showOnlyFavs || props.mobileOpen }, { "fav-only-title-area": showOnlyFavs }) },
            React.createElement("h3", null,
                React.createElement(FormattedMessage, { id: "component.directory.favs.favorites", defaultMessage: "Favorites" })),
            React.createElement(DirectoryFavoritesRearrange, null,
                React.createElement("div", { className: "custom-button" },
                    React.createElement(FormattedMessage, { id: "component.directory.favs.rearrange", defaultMessage: "Re-arrange" }))),
            !showOnlyFavs && (React.createElement("div", { className: "fav-mobile-button", onClick: function () { return props.updateMobileOpen(); } },
                React.createElement("i", { className: "fas fa-bars" }),
                React.createElement("i", { className: "la la-star star-shadow" }),
                React.createElement("i", { className: "la la-star" })))),
        React.createElement("div", { className: clsx("fav-wrapper", { "mobile-open": showOnlyFavs || props.mobileOpen }, { "fav-only-wrapper": showOnlyFavs }) },
            React.createElement("div", { className: "fav-wrapper-content" },
                isFavsLoading === true ? (React.createElement("div", { className: "loading-skeleton", "data-pw": "loading-skeleton" },
                    React.createElement("div", { className: "header" }),
                    React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                        React.createElement("div", { className: "pic" },
                            React.createElement("div", { className: "circle animated-wave" })),
                        React.createElement("div", { className: "title" },
                            React.createElement("div", { className: "title-box animated-wave" })))); })))) : null,
                isFavsLoading === false ? (React.createElement(React.Fragment, null,
                    React.createElement(Flipper, { flipKey: directoryFavs.map(function (f) { return f.SiteId; }).join("|") }, directoryFavs.map(function (item, favIndex) { return (React.createElement(React.Fragment, null,
                        React.createElement(Flipped, { key: item.SiteId, flipId: item.SiteId },
                            React.createElement("div", { className: "fav-item", "data-pw": "fav-item" },
                                React.createElement("div", { className: "fav-content" },
                                    React.createElement("a", { className: "fav-icon", "data-pw": "fav-icon", onClick: function (event) { return props.goToTeamsLink(event, props.getDirectoryItemUrl(item)); }, href: props.getDirectoryItemUrl(item), target: "_blank" },
                                        React.createElement(OfficeProfilePicture, { iconColor: "gray", size: "sm", type: item.TemplateType !== undefined && item.TemplateType !== null ? (item.TemplateType.indexOf("SharePoint") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                                    React.createElement("div", { className: "fav-details" },
                                        React.createElement("a", { className: "fav-title", "data-pw": "fav-title", onClick: function (event) { return props.goToTeamsLink(event, props.getDirectoryItemUrl(item)); }, href: props.getDirectoryItemUrl(item), target: "_blank" }, item.Name),
                                        React.createElement("div", { className: "fav-type" },
                                            React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: "cat-icon", "data-pw": "fav-type-icon" }, item.TemplateType === "SharePoint Communications" || item.TemplateType === "SharePoint Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" })) : item.TemplateType === "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png" })) : (React.createElement("img", { src: iconViva }))),
                                                React.createElement("div", { className: "cat-title", "data-pw": "fav-type-title" }, item.TemplateType === "Team" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.ms_teams", defaultMessage: "Microsoft Team" }))) : item.TemplateType === "SharePoint Team" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.team_site", defaultMessage: "Team Site" }))) : item.TemplateType === "SharePoint Communications" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.comm_site", defaultMessage: "Communication Site" }))) : item.TemplateType === "Viva Engage Community" ? (React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.viva_engage_community", defaultMessage: "Viva Engage Community" }))) : null)))),
                                    React.createElement("div", { className: "fav-menu" },
                                        React.createElement("div", { "data-pw": "fav-menu", className: "dots-button", onClick: function (e) { return toggleFavMenu(e); } },
                                            React.createElement("span", null, "...")),
                                        React.createElement("div", { className: "fav-menu-dd" },
                                            React.createElement("div", { className: "fav-menu-item", onClick: function (e) { return removeItem(item, e); } },
                                                React.createElement("i", { className: "fa fa-times" }),
                                                React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.remove", defaultMessage: "Remove" }))),
                                            favIndex !== 0 ? (React.createElement("div", { className: "fav-menu-item", onClick: function (e) { return moveItem(item, "u", e); } },
                                                React.createElement("i", { className: "fa fa-arrow-up" }),
                                                React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.move_up", defaultMessage: "Move Up" })))) : null,
                                            favIndex !== directoryFavs.length - 1 ? (React.createElement("div", { className: "fav-menu-item", onClick: function (e) { return moveItem(item, "d", e); } },
                                                React.createElement("i", { className: "fa fa-arrow-down" }),
                                                React.createElement("span", null,
                                                    React.createElement(FormattedMessage, { id: "component.directory.favs.move_down", defaultMessage: "Move Down" })))) : null))))))); })),
                    directoryFavs.length === 0 ? (React.createElement("div", { className: "no-results" },
                        React.createElement(NoResults, { subText: intl.formatMessage({ id: "component.directory.favs.you_do_not_have_favs", defaultMessage: "You have not added any favourites yet" }), faIcon: "star" }))) : null)) : null))));
});
export default DirectoryFavorites;
export var DirectoryFavoritesRearrange = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var directoryFavs = useSelector(function (state) { return state.directorySearchState.Favs; });
    var moveItemDragEnd = function (result, provided) {
        //@ts-ignore
        var reorder = function (list, startIndex, endIndex) {
            var result = Array.from(list);
            var removed = result.splice(startIndex, 1)[0];
            result.splice(endIndex, 0, removed);
            return result;
        };
        var quotes = reorder(directoryFavs, result.source.index, 
        //@ts-ignore
        result.destination.index);
        dispatch(DirectoryStore.UpdateFavs(
        //@ts-ignore
        quotes));
    };
    return (React.createElement("div", { className: "directory-favorites-rearrange" },
        React.createElement("div", { className: "rearrange-button", "data-pw": "rearrange-button", onClick: function () {
                setShowModal(!showModal);
            } }, props.children),
        React.createElement(Modal, { size: "sm", centered: true, show: showModal, onHide: function () { return setShowModal(!showModal); }, animation: true, className: "directory-modal directory-favorites", backdropClassName: "directory-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null,
                    React.createElement(FormattedMessage, { id: "component.directory.favs.rearrange_your_favs", defaultMessage: "Re-arrange your favourites" }))),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "fav-wrapper" },
                    React.createElement("div", { className: "fav-wrapper-content" },
                        React.createElement(DragDropContext, { onDragEnd: moveItemDragEnd },
                            React.createElement(Droppable, { droppableId: "fav-list" }, function (provided) { return (React.createElement("div", __assign({ ref: provided.innerRef }, provided.droppableProps),
                                directoryFavs.map(function (item, favIndex) { return (React.createElement(React.Fragment, null,
                                    React.createElement(Draggable, { draggableId: item.SiteId, index: favIndex, key: item.SiteId }, function (provided) { return (React.createElement("div", __assign({ className: "item-drag-wrapper", ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps),
                                        React.createElement("div", { className: "fav-item" },
                                            React.createElement("div", { className: "fav-drag-and-drop" },
                                                React.createElement("div", { className: "grip" },
                                                    React.createElement("div", { className: "grip-dot" }),
                                                    React.createElement("div", { className: "grip-dot" }),
                                                    React.createElement("div", { className: "grip-dot" }),
                                                    React.createElement("div", { className: "grip-dot" }),
                                                    React.createElement("div", { className: "grip-dot" }),
                                                    React.createElement("div", { className: "grip-dot" }))),
                                            React.createElement("div", { className: "fav-content" },
                                                React.createElement("div", { className: "fav-icon" },
                                                    React.createElement(OfficeProfilePicture, { iconColor: "gray", size: "sm", type: item.TemplateType !== undefined && item.TemplateType !== null
                                                            ? item.TemplateType.indexOf("SharePoint") > -1
                                                                ? "SharePoint"
                                                                : "Teams"
                                                            : "Teams", base64image: item.Base64Logo })),
                                                React.createElement("div", { className: "fav-details" },
                                                    React.createElement("div", { className: "fav-title" }, item.Name),
                                                    React.createElement("div", { className: "fav-type" },
                                                        React.createElement(React.Fragment, null,
                                                            React.createElement("div", { className: "cat-icon" }, item.TemplateType === "SharePoint Communications" || item.TemplateType === "SharePoint Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png" })) : item.TemplateType === "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png" })) : (React.createElement("img", { src: iconViva }))),
                                                            React.createElement("div", { className: "cat-title" }, item.TemplateType === "Team" ? (React.createElement("span", null,
                                                                React.createElement(FormattedMessage, { id: "component.directory.favs.ms_teams", defaultMessage: "Microsoft Team" }))) : item.TemplateType === "SharePoint Team" ? (React.createElement("span", null,
                                                                React.createElement(FormattedMessage, { id: "component.directory.favs.team_site", defaultMessage: "Team Site" }))) : item.TemplateType === "SharePoint Communications" ? (React.createElement("span", null,
                                                                React.createElement(FormattedMessage, { id: "component.directory.favs.comm_site", defaultMessage: "Communication Site" }))) : item.TemplateType === "Viva Engage Community" ? (React.createElement("span", null,
                                                                React.createElement(FormattedMessage, { id: "component.directory.favs.viva_engage_community", defaultMessage: "Viva Engage Community" }))) : null)))))))); }))); }),
                                provided.placeholder)); })),
                        directoryFavs.length === 0 ? (React.createElement("div", { className: "no-results" },
                            React.createElement(NoResults, { subText: intl.formatMessage({ id: "component.directory.favs.you_do_not_have_favs", defaultMessage: "You have not added any favourites yet" }), faIcon: "star" }))) : null))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: function () {
                        setShowModal(!showModal);
                    } },
                    React.createElement(FormattedMessage, { id: "component.directory.favs.close", defaultMessage: "Close" }))))));
});
