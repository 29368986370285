import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { orchestryModalDefinitions } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import GrowthbookWrapper from "GrowthBookWrapper";
import React from "react";
import { Provider } from "react-redux";
import { orchestryTheme, resolver } from "../src/mantineTheme.config";
var App = React.memo(function (_a) {
    var store = _a.store, basename = _a.basename;
    return (React.createElement(React.Fragment, null,
        React.createElement(Provider, { store: store },
            React.createElement(MantineProvider, { cssVariablesResolver: resolver, theme: orchestryTheme },
                React.createElement(ModalsProvider, { modals: orchestryModalDefinitions, modalProps: { variant: "orchestry", centered: true, withCloseButton: false } },
                    React.createElement(GrowthbookWrapper, { basename: basename }))))));
});
export default App;
