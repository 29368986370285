var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TeamInformationCurrentActionTypes } from "./types";
import { cloneDeep } from "lodash";
import { AsyncActionTypes } from "app/store/async/types";
var initialState = {
    teamInformation: null,
    teamContext: null,
    teamLinks: null,
    teamDirectoryMetadata: null,
    teamTabConfiguration: {
        showSiteInfo: true,
        showTeamMembers: true,
        showTeamOwners: true
    },
    teamTabState: {
        currentTabKey: "details",
        forceAddGuestOpen: false
    }
};
window.TeamInformationCurrentReducerUpdating = false;
export function teamInformationReducer(state, action) {
    //console.log("LifecycleGuestsCurrentReducerUpdating", window.LifecycleGuestsCurrentReducerUpdating);
    if (state === void 0) { state = initialState; }
    if (action.type === AsyncActionTypes.Async_Start || action.type === AsyncActionTypes.Async_Succeed) {
        return state;
    }
    var getState = function () {
        switch (action.type) {
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamContext: {
                return __assign(__assign({}, state), { teamContext: action.payload });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamTabConfiguration: {
                return __assign(__assign({}, state), { teamTabConfiguration: __assign({}, action.payload) });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamInformation: {
                return __assign(__assign({}, state), { teamInformation: action.payload });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Update_TeamInformation: {
                return __assign(__assign({}, state), { teamInformation: __assign(__assign({}, state.teamInformation), action.payload) });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamLinks: {
                if (state.teamLinks == null) {
                    return __assign(__assign({}, state), { teamLinks: Object.assign({}, action.payload) });
                }
                else {
                    return __assign(__assign({}, state), { teamLinks: Object.assign({}, state.teamLinks, action.payload) });
                }
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Load_TeamLinks_NotebookAndPlanner: {
                if (state.teamLinks == null) {
                    return __assign(__assign({}, state), { teamLinks: Object.assign({}, action.payload) });
                }
                else {
                    return __assign(__assign({}, state), { teamLinks: Object.assign({}, state.teamLinks, action.payload) });
                }
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Load_DirectoryMetadata: {
                return __assign(__assign({}, state), { teamDirectoryMetadata: __assign({}, action.payload) });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Clear_DirectoryMetadata: {
                return __assign(__assign({}, cloneDeep(state)), { teamDirectoryMetadata: null });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Update_TeamState: {
                return __assign(__assign({}, cloneDeep(state)), { teamTabState: __assign(__assign({}, state.teamTabState), action.payload) });
            }
            case TeamInformationCurrentActionTypes.TeamInformation_Current_Clear: {
                return __assign({}, cloneDeep(initialState));
            }
            default:
                return state;
        }
    };
    // const overrideStatusIfNeeded = (state:LifecycleGuestCurrentState, newStatus:string) => {
    // }
    var wait = function () {
        if (window.TeamInformationCurrentReducerUpdating === false) {
            //console.log("wait done " + action.type)
            window.TeamInformationCurrentReducerUpdating = true;
            var newstate = getState();
            window.TeamInformationCurrentReducerUpdating = false;
            return newstate;
        }
        else {
            //console.log("waiting for " + action.type)
            setTimeout(function () {
                return wait();
            }, 50);
        }
    };
    return wait();
}
