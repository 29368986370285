import * as React from "react";
import "./dashboard.scss";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import Subtitle from "app/components/common/subtitle";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import moment from "moment";
import AnimatedLoader from "app/components/common/animated-loader";
import LifecycleService from "app/services/lifecycle";
import { ReportDatasource, ReportSearchSlotName } from "app/store/lifecycle-report";
import { getIconClassName } from "@uifabric/styling";
import ReactApexChart from "react-apexcharts";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeTextSmall } from "app/components/common/skeleton-loaders";
import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";
import ReportInsightsButton from "../../common/report/report-insights-button";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import { Container } from "react-bootstrap";
var LifecycleDashboard = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var _y = React.useState(undefined), lastCrawlLog = _y[0], setLastCrawlLog = _y[1];
    var _z = React.useState(null), stats = _z[0], setStats = _z[1];
    var _0 = React.useState(false), reportFileLoading = _0[0], setReportFileLoading = _0[1];
    var _1 = React.useState("month"), dateFilterOverview = _1[0], setDateFilterOverview = _1[1];
    var _2 = React.useState(null), insights = _2[0], setInsights = _2[1];
    // const [dateFilterOverviewChart, setDateFilterOverviewChart] = React.useState("month");
    var inTeams = React.useState(document.getElementsByTagName("html")[0].classList.contains("in-teams"))[0];
    var _3 = React.useState(undefined), etlPostInstallStatus = _3[0], setEtlPostInstallStatus = _3[1];
    var dispatch = useDispatch();
    var history = useHistory();
    React.useEffect(function () {
        var contentwrapper = document.getElementById("kt_content");
        if (contentwrapper !== null) {
            if (contentwrapper.classList.contains("lifecycle-dashboard-content-wrapper") === false) {
                contentwrapper.classList.add("lifecycle-dashboard-content-wrapper");
            }
        }
        return function () {
            var contentwrapper = document.getElementById("kt_content");
            if (contentwrapper !== null) {
                if (contentwrapper.classList.contains("lifecycle-dashboard-content-wrapper") === true) {
                    contentwrapper.classList.remove("lifecycle-dashboard-content-wrapper");
                }
            }
        };
    }, []);
    React.useEffect(function () {
        LifecycleService.ETLPostInstallStatus().then(function (postStatus) {
            setEtlPostInstallStatus(postStatus);
        });
        LifecycleService.GetLifecycleDashboardData().then(function (data) {
            setStats(data);
        });
    }, []);
    // initializeIcons();
    React.useEffect(function () { }, []);
    // function relDiff(a:number, b:number) {
    //    return  100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
    // }
    function relDiff(a, b) {
        var percent;
        if (b !== 0) {
            if (a !== 0) {
                percent = ((b - a) / a) * 100;
            }
            else {
                percent = b * 100;
            }
        }
        else {
            percent = -a * 100;
        }
        return Math.floor(percent);
    }
    //    function relDiff(oldNumber:number, newNumber:number){
    //       var decreaseValue = oldNumber - newNumber;
    //       return (decreaseValue / oldNumber) * 100;
    //   }
    var getIcon = function (a) {
        if (a > 0) {
            return React.createElement("i", { className: "fa fa-arrow-up" });
        }
        if (a === 0) {
            return React.createElement("i", { className: "fa fa-arrow-right" });
        }
        if (a < 0) {
            return React.createElement("i", { className: "fa fa-arrow-down" });
        }
    };
    var formatMonthlyData = function (stats) {
        // console.log("formatMonthlyData", stats);
        // stats.forEach(s => {
        //    s.UnitDateTime = moment(s.UnitDateTime).format("MMM D");
        // })
        return stats;
    };
    var formatWeeklyData = function (stats) {
        // stats.forEach(s => {
        //    s.UnitDateTime = moment(s.UnitDateTime).format("MMM D");
        // })
        return stats;
    };
    var formatQuarterlyData = function (stats) {
        // stats.forEach(s => {
        //    s.UnitDateTime = moment(s.UnitDateTime).format("MMM D");
        //    // s.UnitDateTime = moment(s.UnitDateTime).add(-30, "days").format("MMM D") + " - " + moment(s.UnitDateTime).format("MMM D");
        // })
        return stats;
    };
    var formatYearlyData = function (stats) {
        // stats.forEach(s => {
        //    // s.UnitDateTime = moment(s.UnitDateTime).format("MMM YY");
        //    s.UnitDateTime = moment(s.UnitDateTime).format("MMM D");
        //    //s.UnitDateTime = moment(s.UnitDateTime).add(-1, "month").format("MMM YY") + " - " + moment(s.UnitDateTime).format("MMM YY");
        // })
        return stats;
    };
    var formatTickData = function (tickValue) {
        if (dateFilterOverview === "month") {
            return moment(tickValue).format("MMM D");
        }
        if (dateFilterOverview === "week") {
            return moment(tickValue).format("MMM D");
        }
        if (dateFilterOverview === "quarter") {
            return (moment(tickValue)
                .add(-30, "days")
                .format("MMM D") +
                " - " +
                moment(tickValue).format("MMM D"));
        }
        return moment(tickValue).format("MMM YY");
    };
    var downloadReport = function () {
        //dispatch(LifecycleReportStore.GenerateFile());
        setReportFileLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.set("data", JSON.stringify(stats));
        axios({
            url: "/api/LifecycleDashboard/GenerateReport",
            method: "POST",
            data: bodyFormData,
            responseType: "blob" // important
        }).then(function (response) {
            var url = window.URL.createObjectURL(new Blob([response.data], { type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Orchestry Report - Lifecycle Dashboard.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
            setReportFileLoading(false);
        });
    };
    var getChange = function (timeType) {
        var change = 0;
        if (dateFilterOverview === "month") {
            if (timeType === "created") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_MonthDifference_Created;
            }
            if (timeType === "renewed") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_MonthDifference_Renewed;
            }
            if (timeType === "archived") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_MonthDifference_Archived;
            }
        }
        else if (dateFilterOverview === "quarter") {
            if (timeType === "created") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_QuarterDifference_Created;
            }
            if (timeType === "renewed") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_QuarterDifference_Renewed;
            }
            if (timeType === "archived") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_QuarterDifference_Archived;
            }
        }
        else if (dateFilterOverview === "week") {
            if (timeType === "created") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_WeekDifference_Created;
            }
            if (timeType === "renewed") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_WeekDifference_Renewed;
            }
            if (timeType === "archived") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_WeekDifference_Archived;
            }
        }
        else if (dateFilterOverview === "year") {
            if (timeType === "created") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_YearDifference_Created;
            }
            if (timeType === "renewed") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_YearDifference_Renewed;
            }
            if (timeType === "archived") {
                change = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_YearDifference_Archived;
            }
        }
        return change;
    };
    var getChangeDirection = function (timeType) {
        var change = getChange(timeType);
        var direction = "none";
        if (change > 0) {
            direction = "up";
        }
        if (change < 0) {
            direction = "down";
        }
        if (change === 0) {
            direction = "none";
        }
        return direction;
    };
    var getColor = function (bar) {
        var colors = { CreatedCount: "#648021bf", ExtendedCount: "#4b8a85", ArchivedCount: "#d8cc7c" };
        return colors[bar.id];
    };
    var getLogoForWorkspace = function (siteId) {
        var returnImage = React.createElement("i", { className: "fa fa-briefcase" });
        if (stats !== null) {
            var foundImage = stats.Workspaces_RecentlyLogos.find(function (rl) { return rl.SiteId === siteId; });
            if (foundImage !== undefined && foundImage.Base64Logo) {
                returnImage = React.createElement("img", { src: foundImage.Base64Logo });
            }
        }
        return returnImage;
    };
    React.useEffect(function () {
        if (stats !== null && insights === null) {
            var insights_1 = [];
            if ((stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_HasGuests) !== null && (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_HasGuests) !== 0) {
                var insight = {
                    Title: (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_HasGuests) + " workspaces with guests",
                    Description: (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_HasGuests) + " workspaces with guests",
                    Link: "workspaces-with-guests",
                    Current: false
                };
                insights_1.push(insight);
            }
            if ((stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TestInName) !== null && (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TestInName) !== 0) {
                var insight = {
                    Title: (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TestInName) + " workspaces with test in the name",
                    Description: "You have " + (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TestInName) + " workspaces with test in the name. Review these workspaces",
                    Link: "test-or-temporary-workspaces",
                    Current: false
                };
                insights_1.push(insight);
            }
            if ((stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TwoOwnersOrLess) !== null && (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TwoOwnersOrLess) !== 0) {
                var insight = {
                    Title: (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TwoOwnersOrLess) + " workspaces less than two owners",
                    Description: "You have " + (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TwoOwnersOrLess) + " workspaces with two owners or less",
                    Link: "",
                    Current: false
                };
                insights_1.push(insight);
            }
            if ((stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_OneChannel) !== null && (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_OneChannel) !== 0) {
                var insight = {
                    Title: (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_OneChannel) + " teams with only one channel",
                    Description: "You have " + (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_OneChannel) + " teams only one channel",
                    Link: "general-channel-only",
                    Current: false
                };
                insights_1.push(insight);
            }
            if (insights_1.length > 0) {
                insights_1[0].Current = true;
            }
            setInsights(insights_1);
        }
    }, [stats]);
    var getThisStats = function () {
        if (dateFilterOverview === "month") {
            return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_ThisMonth;
        }
        if (dateFilterOverview === "week") {
            return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_ThisWeek;
        }
        if (dateFilterOverview === "quarter") {
            return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_ThisQuarter;
        }
        return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_ThisYear;
    };
    var getLastStats = function () {
        if (dateFilterOverview === "month") {
            return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_LastMonth;
        }
        if (dateFilterOverview === "week") {
            return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_LastWeek;
        }
        if (dateFilterOverview === "quarter") {
            return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_LastQuarter;
        }
        return stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_LastYear;
    };
    var getHistoricalData = function () {
        return dateFilterOverview === "month"
            ? formatMonthlyData(stats === null || stats === void 0 ? void 0 : stats.ThisMonth)
            : dateFilterOverview === "week"
                ? formatWeeklyData(stats === null || stats === void 0 ? void 0 : stats.ThisWeek)
                : dateFilterOverview === "quarter"
                    ? formatQuarterlyData(stats === null || stats === void 0 ? void 0 : stats.ThisQuarter)
                    : formatYearlyData(stats === null || stats === void 0 ? void 0 : stats.ThisYear);
    };
    var hasHisoricalData = function () {
        var data = getHistoricalData();
        var hasData = false;
        data.forEach(function (item) {
            if (item.ArchivedCount > 0 || item.CreatedCount > 0 || item.ExtendedCount > 0) {
                hasData = true;
            }
        });
        return hasData;
    };
    return (React.createElement("div", { className: "lifecycle-dashboard" },
        React.createElement(Subtitle, { title: "Workspace Dashboard" },
            React.createElement("div", { className: "lifecycle-report-filters-nav-buttons" },
                React.createElement("div", { className: "lifecycle-dashboard-export-report", "data-pw": "export-button" },
                    React.createElement("div", { className: "export-button button-custom btn-secondary", onClick: function () { return downloadReport(); } },
                        reportFileLoading === true ? (React.createElement("span", { className: "loading" },
                            React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement("span", { className: "icon" },
                            React.createElement("i", { className: getIconClassName("ExcelDocument") }))),
                        React.createElement("span", { className: "text" }, "Export"))),
                React.createElement(ReportInsightsButton, { Datasource: ReportDatasource.CosmosDB, NamePrefix: "Workspace", HideBoldSelected: true, OnSelected: function (value) {
                        history.push("/lifecycle/listing/insight/" + value);
                    }, Slot: ReportSearchSlotName.WorkspaceInsightsSlot, CurrentView: "all-workspaces" }))),
        etlPostInstallStatus !== undefined && etlPostInstallStatus.PrivateServer === true && etlPostInstallStatus.CanConnectToPrivateServer === false ? (React.createElement("div", { className: "crawl-still-in-progress fadein-1s" },
            React.createElement("i", { className: "flaticon-interface-4" }),
            React.createElement("div", { className: "title" }, "Sorry, we can't connect to your private Cosmos db instance"),
            React.createElement("div", { className: "desc" }, "We are getting an error when trying to connect to you database."))) : etlPostInstallStatus !== undefined && etlPostInstallStatus.FirstCrawlDone === false ? (React.createElement("div", { className: "crawl-still-in-progress fadein-1s" },
            React.createElement("i", { className: "flaticon-interface-4" }),
            React.createElement("div", { className: "title" }, "Sorry, we are still collecting information about your workspaces"),
            React.createElement("div", { className: "desc" }, "This can take anywhere from a couple minutes to a couple of hours depending on how many workspaces you have."))) : null,
        stats === null ? (React.createElement(Container, { fluid: true },
            React.createElement(Row, null,
                React.createElement(Col, { md: 3 },
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 104 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 104, marginTop: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 104, marginTop: 20 }),
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 104, marginTop: 20 })),
                React.createElement(Col, null,
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 228 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 228 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 228 }))),
                    React.createElement(Row, { style: { marginTop: "20px" } },
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 228 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 228 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 228 }))))),
            React.createElement(Row, { style: { marginTop: "30px" } },
                React.createElement(Col, null,
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeTextSmall, { bodyHeight: 30 }))),
                    React.createElement(Row, { style: { marginTop: "20px" } },
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 122 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 122 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 122 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 122 })),
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 122 }))))),
            React.createElement(Row, { style: { marginTop: "30px" } },
                React.createElement(Col, null,
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(SkeletonLoaderFakeTextSmall, { bodyHeight: 30 }))),
                    React.createElement(Row, { style: { marginTop: "20px" } },
                        React.createElement(Col, { md: 4 },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 146 })),
                        React.createElement(Col, { md: 4 },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 146 })),
                        React.createElement(Col, { md: 4 },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 146 }))))))) : (React.createElement("div", { className: "new-dashboard" },
            React.createElement("div", { className: "top-stats-row" },
                React.createElement(Container, { fluid: true },
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 3 },
                            React.createElement("div", { className: "color-stat-box teams", "data-pw": "teams-tile" },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-48.png" })),
                                React.createElement("div", { className: "content" },
                                    React.createElement("div", { className: "count", "data-pw": "count" }, (_a = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Teams) === null || _a === void 0 ? void 0 : _a.toLocaleString()),
                                    React.createElement("div", { className: "title", "data-pw": "title" }, "Teams"))),
                            React.createElement("div", { className: "color-stat-box team-sites", "data-pw": "team-sites-tile" },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-48.png" })),
                                React.createElement("div", { className: "content" },
                                    React.createElement("div", { className: "count", "data-pw": "count" }, (_b = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TeamSites) === null || _b === void 0 ? void 0 : _b.toLocaleString()),
                                    React.createElement("div", { className: "title", "data-pw": "title" }, "Team Sites"))),
                            React.createElement("div", { className: "color-stat-box comm-sites", "data-pw": "comm-sites-tile" },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-48.png" })),
                                React.createElement("div", { className: "content" },
                                    React.createElement("div", { className: "count", "data-pw": "count" }, (_c = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_CommunicationSites) === null || _c === void 0 ? void 0 : _c.toLocaleString()),
                                    React.createElement("div", { className: "title", "data-pw": "title" }, "Communication Sites"))),
                            React.createElement("div", { className: "color-stat-box viva-communities", "data-pw": "viva-communities-tile" },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("img", { src: iconViva })),
                                React.createElement("div", { className: "content" },
                                    React.createElement("div", { className: "count", "data-pw": "count" }, (_d = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_VivaEngageCommunities) === null || _d === void 0 ? void 0 : _d.toLocaleString()),
                                    React.createElement("div", { className: "title", "data-pw": "title" }, "Viva Engage Communities")))),
                        React.createElement(Col, null,
                            React.createElement(Row, { className: "top-half" },
                                React.createElement(Col, { md: 4 },
                                    React.createElement("div", { className: "privacy-box", "data-pw": "privacy-tile" },
                                        React.createElement("div", { className: "title", "data-pw": "privacy-title" }, "Privacy"),
                                        React.createElement("div", { className: "sides" },
                                            React.createElement("div", { className: "left" },
                                                React.createElement("div", { className: "icon" },
                                                    React.createElement("i", { className: "fa fa-user" })),
                                                React.createElement("div", { className: "title", "data-pw": "public-title" }, "Public"),
                                                React.createElement("div", { className: "count", "data-pw": "public-count" }, (_e = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Public) === null || _e === void 0 ? void 0 : _e.toLocaleString())),
                                            React.createElement("div", { className: "right" },
                                                React.createElement("div", { className: "icon" },
                                                    React.createElement("i", { className: "fa fa-lock" })),
                                                React.createElement("div", { className: "title", "data-pw": "private-title" }, "Private"),
                                                React.createElement("div", { className: "count", "data-pw": "private-count" }, (_f = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Private) === null || _f === void 0 ? void 0 : _f.toLocaleString()))))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(NavLink, { to: "/lifecycle/listing/insight/all-workspaces" },
                                        React.createElement("div", { className: "workspaces-box", "data-pw": "workspaces-tile" },
                                            React.createElement("div", { className: "link" },
                                                React.createElement("i", { className: "la la-external-link-alt" })),
                                            React.createElement("div", { className: "title", "data-pw": "title" }, "Workspaces"),
                                            React.createElement("div", { className: "count", "data-pw": "count" }, (_g = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_All) === null || _g === void 0 ? void 0 : _g.toLocaleString()),
                                            React.createElement("div", { className: "percent", "data-pw": "percent" },
                                                Math.round(((stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Active_LessThanDaysConfigured) / (stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_All)) * 100),
                                                "% Active"),
                                            React.createElement("div", { className: "last-days", "data-pw": "last-days" },
                                                "Last ", stats === null || stats === void 0 ? void 0 :
                                                stats.DaysInactiveTenantSetting,
                                                " days")))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(NavLink, { to: "/lifecycle/listing/insight/inactive-workspaces" },
                                        React.createElement("div", { className: "inactive-workspaces-box", "data-pw": "inactive-workspace-tile" },
                                            React.createElement("div", { className: "link" },
                                                React.createElement("i", { className: "la la-external-link-alt" })),
                                            React.createElement("div", { className: "title", "data-pw": "title" }, "Inactive Workspaces"),
                                            React.createElement("div", { className: "count", "data-pw": "count" }, (_h = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_InactiveDaysOrMore) === null || _h === void 0 ? void 0 : _h.toLocaleString()),
                                            React.createElement("div", { className: "text", "data-pw": "text" },
                                                "haven't been used in more than ", stats === null || stats === void 0 ? void 0 :
                                                stats.DaysInactiveTenantSetting,
                                                " days"))))),
                            React.createElement(Row, { className: "bottom-half" },
                                React.createElement(Col, { md: 4 },
                                    React.createElement("div", { className: "count-title-description-box alt-style", "data-pw": "single-user-tile" },
                                        React.createElement("div", { className: "title", "data-pw": "title" }, "Workspaces"),
                                        React.createElement("div", { className: "count", "data-pw": "count" }, (_j = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_TwoOwnersOrLess) === null || _j === void 0 ? void 0 : _j.toLocaleString()),
                                        React.createElement("div", { className: "text", "data-pw": "text" }, "With a single owner or member"))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(NavLink, { to: "/lifecycle/listing/insight/general-channel-only" },
                                        React.createElement("div", { className: "count-title-description-box alt-style", "data-pw": "single-channel-tile" },
                                            React.createElement("div", { className: "link" },
                                                React.createElement("i", { className: "la la-external-link-alt" })),
                                            React.createElement("div", { className: "title", "data-pw": "title" }, "Teams"),
                                            React.createElement("div", { className: "count", "data-pw": "count" }, (_k = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_OneChannel) === null || _k === void 0 ? void 0 : _k.toLocaleString()),
                                            React.createElement("div", { className: "text", "data-pw": "text" }, "With a single channel")))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(NavLink, { to: "/lifecycle/listing/insight/workspaces-with-guests" },
                                        React.createElement("div", { className: "count-title-description-box alt-style", "data-pw": "guest-access-tile" },
                                            React.createElement("div", { className: "link" },
                                                React.createElement("i", { className: "la la-external-link-alt" })),
                                            React.createElement("div", { className: "title", "data-pw": "title" }, "Workspaces"),
                                            React.createElement("div", { className: "count", "data-pw": "count" }, (_l = stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_HasGuests) === null || _l === void 0 ? void 0 : _l.toLocaleString()),
                                            React.createElement("div", { className: "text", "data-pw": "text" }, "With Guest Access enabled"))))))))),
            React.createElement("div", { className: "title-row" },
                React.createElement("div", { className: "left" }, "Current Lifecycle Status")),
            React.createElement("div", { className: "status-row", "data-pw": "lifecycle-status" },
                React.createElement(NavLink, { to: "/lifecycle/listing/insight/active-workspaces" },
                    React.createElement("div", { className: "status-box color-active", "data-pw": "active-tile" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_m = stats.WorkspaceCount_Active_LessThanDaysConfigured) === null || _m === void 0 ? void 0 : _m.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, "Active"))),
                React.createElement(NavLink, { to: "/lifecycle/listing/insight/inactive-workspaces" },
                    React.createElement("div", { className: "status-box color-inactive", "data-pw": "inactive-tile" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_o = stats.WorkspaceCount_Active_MoreThanDaysConfigured) === null || _o === void 0 ? void 0 : _o.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, "Inactive"))),
                React.createElement(NavLink, { to: "/lifecycle/listing/insight/pending-approval" },
                    React.createElement("div", { className: "status-box color-pending", "data-pw": "pending-tile" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_p = stats.WorkspaceCount_PendingArroval) === null || _p === void 0 ? void 0 : _p.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, "Pending Approval"))),
                React.createElement(NavLink, { to: "/lifecycle/listing/insight/renewed" },
                    React.createElement("div", { className: "status-box color-renewed", "data-pw": "renewed-tile" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_q = stats.WorkspaceCount_Extended) === null || _q === void 0 ? void 0 : _q.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, "Renewed"))),
                React.createElement(NavLink, { to: "/lifecycle/listing/insight/recently-archived" },
                    React.createElement("div", { className: "status-box color-archived", "data-pw": "archived-tile" },
                        React.createElement("div", { className: "link" },
                            React.createElement("i", { className: "la la-external-link-alt" })),
                        React.createElement("div", { className: "count", "data-pw": "count" }, (_r = stats.WorkspaceCount_Archived) === null || _r === void 0 ? void 0 : _r.toLocaleString()),
                        React.createElement("div", { className: "text", "data-pw": "text" }, "Archived")))),
            React.createElement("div", { className: "title-row" },
                React.createElement("div", { className: "left" }, "Workspace Overview"),
                React.createElement("div", { className: "right" },
                    React.createElement(FormDropdownSelect, { list: [
                            {
                                id: "week",
                                title: "Last 7 Days",
                                value: "week"
                            },
                            {
                                id: "month",
                                title: "Last 28 days",
                                value: "month"
                            },
                            {
                                id: "quarter",
                                title: "Last 90 days",
                                value: "quarter"
                            },
                            {
                                id: "year",
                                title: "Last 365 days",
                                value: "year"
                            }
                        ], value: dateFilterOverview, onChange: function (value) { return setDateFilterOverview(value); } }))),
            React.createElement("div", { className: "workspace-overview-row", "data-pw": "workspace-overview" },
                React.createElement("div", { className: "overview-box created " + getChangeDirection("created"), "data-pw": "created-tile" },
                    getLastStats().CreatedCount !== 0 && (React.createElement("div", { className: "change-stat" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "down fa fa-arrow-down" }),
                            React.createElement("i", { className: "up fa fa-arrow-up" })),
                        React.createElement("div", { className: "count" },
                            React.createElement("span", { className: "up" }, "+"),
                            getChange("created"),
                            "%"))),
                    React.createElement("div", { className: "top-row" },
                        getLastStats().CreatedCount !== 0 && (React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "down fa fa-arrow-alt-circle-down" }),
                            React.createElement("i", { className: "none fa fa-arrow-alt-circle-right" }),
                            React.createElement("i", { className: "up fa fa-arrow-alt-circle-up" }))),
                        React.createElement("div", { className: "count" }, (_s = getThisStats().CreatedCount) === null || _s === void 0 ? void 0 : _s.toLocaleString())),
                    React.createElement("div", { className: "text", "data-pw": "text" }, "Created"),
                    React.createElement("div", { className: "sub-text" }, (_t = getLastStats().CreatedCount) === null || _t === void 0 ? void 0 :
                        _t.toLocaleString(),
                        " previous period")),
                React.createElement("div", { className: "overview-box renewed " + getChangeDirection("renewed"), "data-pw": "renewed-tile" },
                    getLastStats().ExtendedCount !== 0 && (React.createElement("div", { className: "change-stat" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "down fa fa-arrow-down" }),
                            React.createElement("i", { className: "up fa fa-arrow-up" })),
                        React.createElement("div", { className: "count" },
                            React.createElement("span", { className: "up" }, "+"),
                            getChange("renewed"),
                            "%"))),
                    React.createElement("div", { className: "top-row" },
                        getLastStats().ExtendedCount !== 0 && (React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "down fa fa-arrow-alt-circle-down" }),
                            React.createElement("i", { className: "none fa fa-arrow-alt-circle-right" }),
                            React.createElement("i", { className: "up fa fa-arrow-alt-circle-up" }))),
                        React.createElement("div", { className: "count" }, (_u = getThisStats().ExtendedCount) === null || _u === void 0 ? void 0 : _u.toLocaleString())),
                    React.createElement("div", { className: "text", "data-pw": "text" }, "Renewed"),
                    React.createElement("div", { className: "sub-text" }, (_v = getLastStats().ExtendedCount) === null || _v === void 0 ? void 0 :
                        _v.toLocaleString(),
                        " previous period")),
                React.createElement("div", { className: "overview-box archived " + getChangeDirection("archived"), "data-pw": "archived-tile" },
                    getLastStats().ArchivedCount !== 0 && (React.createElement("div", { className: "change-stat" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "down fa fa-arrow-down" }),
                            React.createElement("i", { className: "up fa fa-arrow-up" })),
                        React.createElement("div", { className: "count" },
                            React.createElement("span", { className: "up" }, "+"),
                            getChange("archived"),
                            "%"))),
                    React.createElement("div", { className: "top-row" },
                        getLastStats().ArchivedCount !== 0 && (React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "down fa fa-arrow-alt-circle-down" }),
                            React.createElement("i", { className: "none fa fa-arrow-alt-circle-right" }),
                            React.createElement("i", { className: "up fa fa-arrow-alt-circle-up" }))),
                        React.createElement("div", { className: "count" }, (_w = getThisStats().ArchivedCount) === null || _w === void 0 ? void 0 : _w.toLocaleString())),
                    React.createElement("div", { className: "text", "data-pw": "text" }, "Archived"),
                    React.createElement("div", { className: "sub-text" }, (_x = getLastStats().ArchivedCount) === null || _x === void 0 ? void 0 :
                        _x.toLocaleString(),
                        " previous period"))),
            React.createElement(Row, { className: "charts-row", "data-pw": "charts-row" },
                React.createElement(Col, { md: 4 },
                    React.createElement("div", { className: "pie-chart", "data-pw": "pie-chart" },
                        React.createElement("div", { className: "title", "data-pw": "title" }, "Activity Breakdown"),
                        React.createElement("div", { className: "chart", "data-pw": "chart" },
                            React.createElement(ReactApexChart, { height: 325, options: {
                                    colors: ["#78CA92", "#E9EB69", "#DB674D"],
                                    plotOptions: {
                                        pie: {
                                            donut: {
                                                size: "55%"
                                            }
                                        }
                                    },
                                    chart: {
                                        type: "donut"
                                    },
                                    stroke: {
                                        colors: ["#000"],
                                        width: 0
                                    },
                                    legend: {
                                        position: "bottom",
                                        fontSize: "13px",
                                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                                        fontWeight: 500,
                                        horizontalAlign: "left",
                                        offsetX: 0,
                                        offsetY: 20,
                                        width: 300,
                                        height: 100,
                                        markers: {
                                            width: 12,
                                            height: 12,
                                            strokeWidth: 0,
                                            // strokeColor: '#fff',
                                            // fillColors: undefined,
                                            radius: 2,
                                            // customHTML: undefined,
                                            // onClick: undefined,
                                            offsetX: -5
                                            // offsetY: 0
                                        },
                                        itemMargin: {
                                        // horizontal: 0,
                                        // vertical: 8
                                        }
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    grid: {
                                        padding: {
                                            bottom: 0,
                                            top: 0,
                                            left: -20,
                                            right: -20
                                        }
                                    },
                                    tooltip: {
                                        y: {
                                            formatter: function (value, series) {
                                                return value === null || value === void 0 ? void 0 : value.toLocaleString();
                                            }
                                        }
                                    },
                                    labels: ["Active in last 30 days", "Active in last 90 days", "Inactive for more than 90 days"]
                                    // responsive: [{
                                    //    breakpoint: 0,
                                    //    options: {
                                    //       chart: {
                                    //          width: 200
                                    //       },
                                    //       legend: {
                                    //          position: 'right'
                                    //       }
                                    //    }
                                    // }]
                                }, series: [stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Active_Last30Days, stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Active_30DaysTo90Days, stats === null || stats === void 0 ? void 0 : stats.WorkspaceCount_Active_90DaysAndBeyond], type: "donut" })))),
                React.createElement(Col, { md: 8 },
                    React.createElement("div", { className: "bar-chart", "data-pw": "bar-chart" },
                        React.createElement("div", { className: "title", "data-pw": "title" }, "Historical Workspace Activity"),
                        React.createElement("div", { className: "chart", style: { height: "320px" }, "data-pw": "chart" },
                            React.createElement(ResponsiveBar, { data: getHistoricalData(), tooltip: function (input) {
                                    var _a;
                                    // console.log(input)
                                    return (React.createElement("div", { className: "orchestry-lifecycle-dashboard-bar-chart-hover" },
                                        React.createElement("span", { className: "color-icon", style: { backgroundColor: input.color } }),
                                        React.createElement("span", { className: "title" }, input.id === "ArchivedCount" ? "Archived" : input.id === "CreatedCount" ? "Created" : "Renewed"),
                                        React.createElement("span", { className: "value" }, (_a = input.value) === null || _a === void 0 ? void 0 : _a.toLocaleString())));
                                }, keys: ["CreatedCount", "ExtendedCount", "ArchivedCount"], indexBy: "UnitDateTime", margin: { top: 40, right: 30, bottom: 90, left: 40 }, padding: 0.5, innerPadding: 1, groupMode: "grouped", valueScale: hasHisoricalData() === true ? { type: "linear" } : { type: "linear", min: 0, max: 10 }, indexScale: { type: "band", round: true }, 
                                // valueFormat={{ format: '', enabled: false }}
                                colors: getColor, defs: [
                                    {
                                        id: "dots",
                                        type: "patternDots",
                                        background: "inherit",
                                        color: "#38bcb2",
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: "lines",
                                        type: "patternLines",
                                        background: "inherit",
                                        color: "#eed312",
                                        rotation: -25,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ], borderRadius: 2, borderColor: { from: "color", modifiers: [["darker", 1.6]] }, axisTop: null, axisRight: null, axisBottom: {
                                    tickSize: 0,
                                    tickPadding: dateFilterOverview === "year" ? 10 : 20,
                                    // tickRotation: 45,
                                    tickRotation: dateFilterOverview === "year" ? 35 : 0,
                                    format: function (item) { return formatTickData(item); }
                                    // tickValues:6
                                }, axisLeft: {
                                    tickSize: 0,
                                    tickValues: 4,
                                    tickPadding: 20
                                }, enableLabel: false, labelSkipWidth: 12, labelSkipHeight: 12, labelTextColor: { from: "color", modifiers: [["darker", 1.6]] }, gridYValues: 4, theme: {
                                    legends: {
                                        text: { fontFamily: '"TT Norms", Helvetica, sans-serif', fontWeight: 500, fontSize: "13px" }
                                    },
                                    labels: {
                                        text: { fontFamily: '"TT Norms", Helvetica, sans-serif', fontWeight: 500 }
                                    },
                                    axis: {
                                        ticks: {
                                            text: {
                                                fontFamily: '"TT Norms", Helvetica, sans-serif',
                                                fontWeight: 500,
                                                fill: "#9EACB2"
                                            }
                                        }
                                    }
                                }, layers: ["grid", "axes", "bars", "markers", "legends"], legends: [
                                    {
                                        dataFrom: "keys",
                                        anchor: "bottom",
                                        direction: "row",
                                        data: [
                                            {
                                                id: "CreatedCount",
                                                label: "Created",
                                                color: "#648021bf"
                                            },
                                            {
                                                id: "RenewedCount",
                                                label: "Renewed",
                                                color: "#4b8a85"
                                            },
                                            {
                                                id: "ArchivedCount",
                                                label: "Archived",
                                                color: "#d8cc7c"
                                            }
                                        ],
                                        justify: false,
                                        translateX: 0,
                                        translateY: 85,
                                        itemsSpacing: 25,
                                        itemWidth: 120,
                                        itemHeight: 12,
                                        itemDirection: "left-to-right",
                                        itemOpacity: 0.85,
                                        symbolSize: 12,
                                        effects: [
                                            {
                                                on: "hover",
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ] }))))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 4 },
                    React.createElement("div", { className: "workspace-list", "data-pw": "created-list" },
                        React.createElement("div", { className: "title", "data-pw": "title" }, "Created"),
                        React.createElement("ul", null, stats.Workspaces_RecentlyCreated.map(function (item) { return (React.createElement("li", null,
                            React.createElement("div", { className: "icon" },
                                React.createElement("div", { className: "round-icon" }, getLogoForWorkspace(item.SiteId))),
                            React.createElement("div", { className: "content" },
                                React.createElement(NavLink, { to: "/lifecycle/listing/" + item.SiteId, className: "title" }, item.Title),
                                React.createElement(NavLink, { to: item.SiteUrl, target: "_blank", className: "url" }, item.SiteUrl)),
                            React.createElement("div", { className: "link-icon" },
                                React.createElement(NavLink, { to: "/lifecycle/listing/" + item.SiteId, className: "link" },
                                    React.createElement("i", { className: "la la-external-link-alt" }))))); })))),
                React.createElement(Col, { md: 4 },
                    React.createElement("div", { className: "workspace-list", "data-pw": "renewed-list" },
                        React.createElement("div", { className: "title", "data-pw": "title" }, "Renewed"),
                        React.createElement("ul", null, stats.Workspaces_RecentlyExtended.map(function (item) { return (React.createElement("li", null,
                            React.createElement("div", { className: "icon" },
                                React.createElement("div", { className: "round-icon" }, getLogoForWorkspace(item.SiteId))),
                            React.createElement("div", { className: "content" },
                                React.createElement(NavLink, { to: "/lifecycle/listing/" + item.SiteId, className: "title" }, item.Title),
                                React.createElement(NavLink, { to: item.SiteUrl, target: "_blank", className: "url" }, item.SiteUrl)),
                            React.createElement("div", { className: "link-icon" },
                                React.createElement(NavLink, { to: "/lifecycle/listing/" + item.SiteId, className: "link" },
                                    React.createElement("i", { className: "la la-external-link-alt" }))))); })))),
                React.createElement(Col, { md: 4 },
                    React.createElement("div", { className: "workspace-list", "data-pw": "archived-list" },
                        React.createElement("div", { className: "title", "data-pw": "title" }, "Archived"),
                        React.createElement("ul", null, stats.Workspaces_RecentlyArchived.map(function (item) { return (React.createElement("li", null,
                            React.createElement("div", { className: "icon" },
                                React.createElement("div", { className: "round-icon" }, getLogoForWorkspace(item.SiteId))),
                            React.createElement("div", { className: "content" },
                                React.createElement(NavLink, { to: "/lifecycle/listing/" + item.SiteId, className: "title" }, item.Title),
                                React.createElement(NavLink, { to: item.SiteUrl, target: "_blank", className: "url" }, item.SiteUrl)),
                            React.createElement("div", { className: "link-icon" },
                                React.createElement(NavLink, { to: "/lifecycle/listing/" + item.SiteId, className: "link" },
                                    React.createElement("i", { className: "la la-external-link-alt" }))))); })))))))));
});
export default LifecycleDashboard;
