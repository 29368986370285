import axios from 'axios';
var OrchestryService = /** @class */ (function () {
    function OrchestryService() {
    }
    OrchestryService.GetActiveRegions = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/orchestry/activeregions";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    OrchestryService.GetActiveRegionsAsDropdownOptions = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/orchestry/activeregions";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                var counter = 0;
                var options = response.data.map(function (region) { return ({
                    id: (counter++).toString(),
                    title: region === "US" ?
                        "North America" :
                        region === "AU" ?
                            "Asia Pacific" :
                            region === "EU" ?
                                "Europe" :
                                "",
                    value: region
                }); });
                resolve(options);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return OrchestryService;
}());
export default OrchestryService;
