var _a;
import { useGetMspTenantAnalyticsQuery, useGetMspTenantDetailsQuery, useGetMspTenantsQuery } from "appv2/api/partner-tools/partners.api";
import { TenantLicenseStatus } from "appv2/api/partner-tools/tenant-license-status.type";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { PartnerTenantsHealthChecks } from "appv2/pages/beacon/health-checks/partner-tenants-health-checks.component";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TenantAnalytics } from "./tenant-analytics/tenant-analytics.component";
import { TenantDetails } from "./tenant-details/tenant-details.component";
import { TenantPages } from "./tenant-page.types";
var TAB_TO_PAGE_MAPPING = (_a = {},
    _a[TenantPages.Details] = function () { return React.createElement(TenantDetails, null); },
    _a[TenantPages.Analytics] = function () { return React.createElement(TenantAnalytics, null); },
    _a[TenantPages.HealthChecks] = function () { return React.createElement(PartnerTenantsHealthChecks, null); },
    _a);
/**
 * Helper hook for the Tenant page
 * @param skipApiCalls because this is used in a shared component, we need a way to skip the api calls
 * if it is a customer using the shared component. but we can't determine it in this hook because there
 * is an internal user.
 */
export var useTenantPage = function (skipApiCalls) {
    var _a;
    var intl = useIntl();
    var history = useHistory();
    var location = useLocation();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenantGuid = orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.TenantGuid;
    var currentTenantName = orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.TenantName;
    var tabs = [
        {
            text: "Details",
            value: TenantPages.Details
        },
        {
            text: "Analytics",
            value: TenantPages.Analytics
        },
        {
            text: "Health Checks",
            value: TenantPages.HealthChecks
        }
    ];
    var _b = useState(TenantPages.Details), tab = _b[0], setTab = _b[1];
    useEffect(function () {
        var query = new URLSearchParams(location.search);
        var tabParam = query.get("tab");
        if (tabParam) {
            setTab(tabParam);
        }
    }, [location.search]);
    var tenantGuid = useHealthCheckRouteParams().tenantGuid;
    var tabsOnChange = function (value) {
        var newTab = value !== null && value !== void 0 ? value : TenantPages.Details;
        setTab(newTab);
        history.push({
            search: "?tab=".concat(newTab)
        });
    };
    var query = new URLSearchParams(location.search);
    var guidParam = query.get("GUID");
    var userType = tenantGuid ? "partner" : "internal";
    var tenantId = (_a = tenantGuid !== null && tenantGuid !== void 0 ? tenantGuid : guidParam) !== null && _a !== void 0 ? _a : "";
    var _c = useGetMspTenantAnalyticsQuery([tenantId, userType], { skip: skipApiCalls }), tenantAnalytics = _c.data, tenantAnalyticsIsLoading = _c.isLoading;
    var _d = useGetMspTenantDetailsQuery(tenantId, { skip: skipApiCalls }), tenantDetails = _d.data, tenantDetailsIsLoading = _d.isLoading;
    var _e = useGetMspTenantsQuery({
        queryParameters: {
            filter: "(TenantGuid == \"".concat(tenantGuid, "\")"),
            orderBy: "",
            page: 1,
            pageSize: 1
        }
    }, { skip: skipApiCalls }), mspTenant = _e.data, mspTenantIsLoading = _e.isLoading;
    var tenantHasBeaconLicense = (mspTenant === null || mspTenant === void 0 ? void 0 : mspTenant.Items[0].LicenseType) === TenantLicenseStatus.Beacon;
    var isPageLoading = tenantAnalyticsIsLoading || tenantDetailsIsLoading || mspTenantIsLoading;
    var PageComponent = TAB_TO_PAGE_MAPPING[tab !== null && tab !== void 0 ? tab : TenantPages.Analytics];
    var onDisconnectTenantClicked = function () {
        history.push("/partner-tools");
    };
    var onBackButtonClicked = function () { return history.push("/partner-tools"); };
    var onAddTenantManagersClicked = useCallback(function () {
        openOrchestryModal("addTenantManagersModal", { tenantGuid: tenantId, intl: intl }, "lg");
    }, [tenantId, intl]);
    return {
        intl: intl,
        tabs: tabs,
        tab: tab,
        setTab: setTab,
        tabsOnChange: tabsOnChange,
        tenantAnalytics: tenantAnalytics !== null && tenantAnalytics !== void 0 ? tenantAnalytics : {},
        tenantDetails: tenantDetails !== null && tenantDetails !== void 0 ? tenantDetails : {},
        isPageLoading: isPageLoading,
        tenantGuid: tenantId,
        onDisconnectTenantClicked: onDisconnectTenantClicked,
        PageComponent: PageComponent,
        onBackButtonClicked: onBackButtonClicked,
        onAddTenantManagersClicked: onAddTenantManagersClicked,
        currentTenantGuid: currentTenantGuid,
        currentTenantName: currentTenantName,
        tenantHasBeaconLicense: tenantHasBeaconLicense
    };
};
