import * as React from "react";
import "./report-column-guest-name.scss";
import { NavLink } from "react-router-dom";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import ReportColumnGuestStatus from "../report-column-guest-status";
var ReportColumnGuestName = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = React.useState(null), cosmosUser = _g[0], setCosmosUser = _g[1];
    React.useEffect(function () {
        var _a, _b;
        if (props.Item.ExtendedProperties !== undefined && props.Item.ExtendedProperties !== null && ((_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) !== undefined) {
            setCosmosUser((_b = props.Item.ExtendedProperties) === null || _b === void 0 ? void 0 : _b.CosmosUserDetails);
        }
        else {
            setCosmosUser(props.Item);
        }
    }, [props.Item]);
    var displayName = ((_b = (_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) === null || _b === void 0 ? void 0 : _b.DisplayName) || props.Item.DisplayName;
    var userId = ((_d = (_c = props.Item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.CosmosUserDetails) === null || _d === void 0 ? void 0 : _d.UserId) || props.Item.UserId;
    var mail = ((_f = (_e = props.Item.ExtendedProperties) === null || _e === void 0 ? void 0 : _e.CosmosUserDetails) === null || _f === void 0 ? void 0 : _f.Mail) || props.Item.Mail;
    var hideLinks = props.hideLinks ||
        (props.reportColumn.ExtraProperties !== undefined &&
            props.reportColumn.ExtraProperties !== null &&
            props.reportColumn.ExtraProperties.DisableLink !== undefined &&
            props.reportColumn.ExtraProperties.DisableLink === "true");
    return (React.createElement("div", { className: "report-column-guest-name" },
        hideLinks === true ? (React.createElement("div", { className: "profile-icon" },
            React.createElement(OfficeProfilePicture, { size: "sm", displayName: displayName, type: "User" }))) : (React.createElement(NavLink, { className: "profile-icon", exact: true, to: "/guests/listing/" + userId },
            React.createElement(OfficeProfilePicture, { size: "sm", displayName: displayName, type: "User" }))),
        React.createElement("div", { className: "name-wrapper" },
            hideLinks === true ? (React.createElement("div", { className: "guest-title" },
                React.createElement("div", { className: "title-area no-link" }, displayName))) : (React.createElement(NavLink, { exact: true, to: "/guests/listing/" + userId, className: "guest-title", "data-pw": "guest-title" },
                React.createElement("div", { className: "title-area" }, displayName))),
            React.createElement("div", { className: "mail", "data-pw": "guest-mail" },
                React.createElement("i", { className: "fa-solid fa-envelope" }),
                React.createElement("span", null, mail)),
            !props.hideStatus && (React.createElement("div", { className: "status", "data-pw": "guest-status" }, cosmosUser !== null ? React.createElement(ReportColumnGuestStatus, { cosmosUser: cosmosUser, daysInactive: props.daysInactive }) : null)))));
});
export default ReportColumnGuestName;
