import * as LibraryConfigurationCurrentStore from "app/store/library-configuration/current";
import { AsyncCallType } from "app/store/async/types";
export var libraryConfigurationCurrentActionMap = [
    {
        actionType: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Load,
        callType: AsyncCallType.Get,
        keyType: "typeName",
        ApiUrl: "/api/LibraryConfiguration",
        responseDataType: {}
    },
    {
        actionType: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Update,
        callType: AsyncCallType.Update,
        keyType: "typeName",
        ApiUrl: "/api/LibraryConfiguration",
        responseDataType: {} //,
        // invalidates: [
        //     FeatureListTypes.FeatureListActionTypes.Feature_List_Load,
        //     DocumentTemplateListTypes.DocumentTemplateListActionTypes.DocumentTemplate_List_Load,
        //     ChannelListTypes.ChannelListActionTypes.Channel_List_Load
        // ]
    }
];
