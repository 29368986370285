import { LibraryConfigurationCurrentActionTypes } from "./types";
export function Load(libraryConfiguration) {
    if (libraryConfiguration === undefined)
        libraryConfiguration = {};
    console.log("Load Current Library Config > ", libraryConfiguration);
    return {
        type: LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Load,
        payload: libraryConfiguration
    };
}
export function Update(libraryConfiguration) {
    return {
        type: LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Update,
        payload: libraryConfiguration
    };
}
export var libraryConfigurationCurrentMiddlware = function (store, next, action) {
    next(action);
};
