import { Card, Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import { OrchestryWorkspaceIcon } from "appv2/components/orchestry-workspace-icon/orchestry-workspace-icon.component";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var ConversationsTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, _b = _a.data.MetricsData.Leaderboard.Conversations, Total = _b.Total, WorkspaceName = _b.WorkspaceName, WorkspaceIcon = _b.WorkspaceIcon;
    return (React.createElement(OrchestryTooltip, { color: "var(--mantine-color-white)", style: { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }, p: 12, multiline: true, w: 300, label: React.createElement(Stack, { gap: 8 },
            React.createElement(Group, { gap: "8px", align: "flex-start" },
                React.createElement(OrchestryFontIcon, { isLoading: isLoading, fontSize: 24, lineHeight: "24px", color: "var(--mantine-color-gray-7)", iconClassName: ORCH_ICONS.messageCircle01 }),
                React.createElement(Stack, { gap: 0 },
                    React.createElement(OrchestryText, { pt: 2, isLoading: isLoading, c: "var(--mantine-color-gray-7)", fz: "16", fw: 500, lh: "18px" }, intl.formatMessage({ id: "page.health_check.conversations_tile.title", defaultMessage: "Conversations" })))),
            !!Total && (React.createElement(Group, { p: 4, mt: 4, align: "top", gap: 8, wrap: "nowrap" },
                React.createElement(OrchestryWorkspaceIcon, { isLoading: isLoading, icon: WorkspaceIcon, workspaceName: WorkspaceName, variant: "16px" }),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-primary-9)", fz: "14", fw: 700, lh: "20px" }, WorkspaceName))),
            React.createElement(OrchestryText, { c: "var(--mantine-color-gray-7)", fz: "14", lh: "20px" }, intl.formatMessage({
                id: "page.health_check.conversations_tile.tooltip.description",
                defaultMessage: "The workspace with the highest volume of Teams conversations during this period."
            }))) },
        React.createElement(Card, { h: "100%", variant: "default", p: 24 },
            React.createElement(Group, { justify: "space-between" },
                React.createElement(Group, { gap: "8px" },
                    React.createElement(OrchestryFontIcon, { isLoading: isLoading, fontSize: 24, lineHeight: "24px", color: "var(--mantine-color-gray-7)", iconClassName: ORCH_ICONS.messageCircle01 }),
                    React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "16", fw: 500, lh: "24px" }, intl.formatMessage({ id: "page.health_check.conversations_tile.title", defaultMessage: "Conversations" }))),
                React.createElement(OrchestryFontIcon, { isLoading: isLoading, fontSize: 24, lineHeight: "24px", color: "var(--mantine-color-brown-6)", iconClassName: ORCH_ICONS.award05 })),
            Total ? (React.createElement(React.Fragment, null,
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "24", fw: 500, lh: "32px", mt: 16 }, Total.toLocaleString()),
                React.createElement(Group, { p: 4, mt: 4, gap: 8, wrap: "nowrap", align: "top" },
                    React.createElement(OrchestryWorkspaceIcon, { isLoading: isLoading, icon: WorkspaceIcon, workspaceName: WorkspaceName, variant: "16px" }),
                    React.createElement(OrchestryText, { overflowEllipsis: true, isLoading: isLoading, c: "var(--mantine-color-primary-9)", fz: "14", fw: 700, lh: "20px" }, WorkspaceName)))) : (React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "14", lh: "20px", mt: 20 }, intl.formatMessage({ id: "page.health_check.leaderboard_section.no_activity", defaultMessage: "No Activity" }))))));
};
