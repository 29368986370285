import React from "react";
import { GuestManagementUserStatus } from "app/store/lifecycle-guest";
import moment from "moment";
import "./guest-status-icon.scss";
var GuestStatusIcon = React.memo(function (props) {
    var status = GetGuestStatusEnum(props.cosmosUser, props.daysInactive);
    var getClassName = function () {
        return status.toLowerCase();
    };
    var getIcon = function () {
        var iconClassName = "";
        if (props.cosmosUser.Status === GuestManagementUserStatus.Active) {
            iconClassName = "certificate";
        }
        if (props.cosmosUser.Status === GuestManagementUserStatus.Inactive) {
            iconClassName = "business-time";
        }
        if (props.cosmosUser.Status === GuestManagementUserStatus.PendingApproval) {
            iconClassName = "hourglass-clock";
        }
        if (props.cosmosUser.Status === GuestManagementUserStatus.PendingAcceptance) {
            iconClassName = "user-clock";
        }
        if (props.cosmosUser.Status === GuestManagementUserStatus.Deleted) {
            iconClassName = "trash";
        }
        return React.createElement("i", { className: "fa " + "fa-" + iconClassName, "data-pw": "guest-status-icon" });
    };
    return React.createElement("div", { className: "guest-status-icon " + (props.useDefaultStyles === true ? "guest-status-icon-default-styles " + "guest-status-" + getClassName() : "") }, getIcon());
});
export default GuestStatusIcon;
export var GetGuestStatusEnum = function (cosmosUser, daysInactive) {
    var returnStatus = GuestManagementUserStatus.Active;
    if (cosmosUser.Status === GuestManagementUserStatus.Active) {
        //let daysInactiveDate = moment.utc().add(daysInactive * -1, "day").date;
        if (cosmosUser.LastActivity === null) {
            if (cosmosUser.CreatedDateTime !== null) {
                if (moment.utc(cosmosUser.CreatedDateTime).diff(moment.utc(), "day") <= daysInactive * -1) {
                    returnStatus = GuestManagementUserStatus.Inactive;
                }
            }
            else {
                returnStatus = GuestManagementUserStatus.Inactive;
            }
        }
        else if (moment.utc(cosmosUser.LastActivity).diff(moment(), "day") < daysInactive * -1) {
            returnStatus = GuestManagementUserStatus.Inactive;
        }
    }
    else if (cosmosUser.Status !== null) {
        returnStatus = cosmosUser.Status;
    }
    return returnStatus;
};
