import { Group, Stack } from "@mantine/core";
import React from "react";
import { OrchestryAnnotationButton } from "./orchestry-annotation-button.component";
import { OrchestryAnnotations } from "./orchestry-annotations.component";
import { useOrchestryAnnotationSection } from "./use-orchestry-annotation-section.hook";
export var OrchestryAnnotationSection = function (_a) {
    var renderListOfAnnotations = _a.renderListOfAnnotations, sectionTitle = _a.sectionTitle, children = _a.children, gap = _a.gap, annotationCategory = _a.annotationCategory, associatedGUID = _a.associatedGUID;
    var _b = useOrchestryAnnotationSection(annotationCategory, renderListOfAnnotations, associatedGUID), intl = _b.intl, annotationCount = _b.annotationCount, isLoading = _b.isLoading, shouldRenderAddAnnotationButton = _b.shouldRenderAddAnnotationButton, onAddAnnotationClicked = _b.onAddAnnotationClicked, shouldRenderListOfAnnotations = _b.shouldRenderListOfAnnotations, id = _b.id;
    return (
    //Note: mantine Grid and some other components may not respect the gap prop so I add margin to those components directly :/
    //I also add pt={4} to account for the absolute position of the annotation number badge so that it does not get cut off on pdf page breaks.
    React.createElement(Stack, { gap: 8 },
        React.createElement("div", { id: id },
            React.createElement(Stack, { gap: gap !== null && gap !== void 0 ? gap : 8 },
                React.createElement(Group, { justify: "space-between", pt: 4 },
                    sectionTitle,
                    React.createElement("div", { style: { transition: "0.5s all", opacity: shouldRenderAddAnnotationButton ? 1 : 0 } },
                        React.createElement(OrchestryAnnotationButton, { annotationCount: annotationCount, isLoading: isLoading, onAddAnnotationClicked: onAddAnnotationClicked }))),
                children)),
        shouldRenderListOfAnnotations && React.createElement(OrchestryAnnotations, { renderListLabelWithAnnotations: true, annotationCategory: annotationCategory })));
};
