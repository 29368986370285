import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
import { useUserIsPartnerNotViewingPartnerLedTenant } from "./use-user-is-partner-viewing-self-led-tenant.hook";
/**
 * Hook to determine if the current user can delete the health check.
 */
export var useUserCanDeleteHealthCheck = function () {
    var _a = useUserIsPartnerNotViewingPartnerLedTenant(), userIsPartnerViewingNotPartnerLedTenant = _a.userIsPartnerViewingNotPartnerLedTenant, isLoading = _a.isLoading;
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var userType = useHealthCheckRouteParams().userType;
    var userIsPartnerViewingCustomer = userType === "partner";
    var userCanDelete = currentUserHasRole(OrchRole.Admin) ||
        currentUserHasRole(OrchRole.BeaconAdmin) ||
        currentUserHasRole(OrchRole.HealthCheckReviewer) ||
        (userIsPartnerViewingCustomer && (currentUserHasRole(OrchRole.PartnerAdmin) || currentUserHasRole(OrchRole.PartnerHubAdmin) || currentUserHasRole(OrchRole.PartnerTenantManager)));
    return {
        userCanDeleteHealthCheck: userCanDelete && !userIsPartnerViewingNotPartnerLedTenant,
        isLoading: isLoading
    };
};
