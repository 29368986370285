var _a;
import { createSlice } from "@reduxjs/toolkit";
import { recommendationsApi } from "./recommendations.api";
var initialState = {
    updateVisibilityRecommendationGuid: ""
};
//recommendations rtk slice
export var recommendationsSlice = createSlice({
    name: "recommendationsPage",
    initialState: initialState,
    reducers: {
        clearUpdateVisibilityRecommendationGuid: function (state) {
            state.updateVisibilityRecommendationGuid = "";
        },
        updateTid: function (state, action) {
            state.tid = action.payload;
        }
    },
    extraReducers: function (builder) {
        builder.addMatcher(recommendationsApi.endpoints.updateRecommendationVisibility.matchPending, function (state, _a) {
            var meta = _a.meta;
            state.updateVisibilityRecommendationGuid = meta.arg.originalArgs.RecommendationGUID;
        });
    }
});
export var clearUpdateVisibilityRecommendationGuid = (_a = recommendationsSlice.actions, _a.clearUpdateVisibilityRecommendationGuid), updateTid = _a.updateTid;
export default recommendationsSlice.reducer;
