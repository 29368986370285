// import { MiddlewareFunction} from 'app/store/middleware'
import { LibraryTemplateCurrentActionTypes } from "./types";
export function Load(libraryTemplate) {
    if (libraryTemplate === undefined)
        libraryTemplate = {};
    //console.log("Load Current LibraryTemplate > ", libraryTemplate)
    return {
        type: LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load,
        payload: libraryTemplate
    };
}
export function Update(libraryTemplate) {
    return {
        type: LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Update,
        payload: libraryTemplate
    };
}
export function Clear() {
    return {
        type: LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Clear
    };
}
export var libraryTemplateCurrentMiddlware = function (store, next, action) {
    if (action.type === LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load) {
        // (action.payload as LibraryTemplate).AssociatedFeatures?.forEach((feat:LibraryTemplateBaseAssociation) => {
        // })
        // // action.payload.forEach((feat:Feature) => {
        // //     if(feat.icon !== undefined)
        // //         feat.icon = fontAwesomeHelper.getIconFromName(feat.icon?.iconName);
        // // });
        // console.log("Middleware", action.payload);
        next(action);
    }
    else {
        next(action);
    }
};
