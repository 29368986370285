import React from "react";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { Row, Col } from "react-bootstrap";
import TeamInformationCustomMetadata from "../team-information-custom-metadata";
import { TeamInformationStatus } from "./team-information-status.component";
import { TeamInformationLogo } from "./team-information-logo.component";
import { TeamInformationGuestAccessPill } from "./team-information-guest-access-pill.component";
import { TeamInformationPrivacyPill } from "./team-information-privacy-pill.component";
import { TeamInformationTemplatePill } from "./team-information-template-pill.component";
import { TeamInformationWorkspaceDetails } from "./team-information-workspace-details.component";
import { TeamInformationTitle } from "./team-information-title.component";
import { TeamInformationDescription } from "./team-information-description.component";
import "./team-information-standard-section.scss";
/**
 * Renders a standard section of the team information header. Used in normal teams and sharepoint webparts.
 */
export var TeamInformationStandardSection = function () { return (React.createElement("div", { className: "team-information-standard-section" },
    React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "top-info" },
                React.createElement("div", { className: "name-and-logo" },
                    React.createElement(TeamInformationLogo, null),
                    React.createElement("div", { className: "top-stats" },
                        React.createElement("div", { className: "ts-left" },
                            React.createElement(TeamInformationTitle, null),
                            React.createElement(TeamInformationDescription, null),
                            React.createElement("div", { className: "ws-property-bar" },
                                React.createElement(TeamInformationTemplatePill, null),
                                React.createElement(TeamInformationPrivacyPill, null),
                                React.createElement(TeamInformationGuestAccessPill, null))))),
                React.createElement(TeamInformationStatus, null))),
        React.createElement(PortletBody, { className: "bottom-portlet-body" },
            React.createElement("div", { className: "bottom-stats-area" },
                React.createElement(Row, null,
                    React.createElement(Col, { className: "bottom-stats-col" },
                        React.createElement(TeamInformationWorkspaceDetails, null)),
                    React.createElement(Col, { className: "bottom-stats-col" }, React.createElement(TeamInformationCustomMetadata, null)))))))); };
