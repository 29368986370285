export var HealthCheckPages;
(function (HealthCheckPages) {
    /**
     * Shows a health check activity report for the client.
     */
    HealthCheckPages["Activity"] = "Activity";
    /**
     * Shows a page that allows annotations and tailored recommendations.
     */
    HealthCheckPages["Additions"] = "Additions";
})(HealthCheckPages || (HealthCheckPages = {}));
