export var TenantConfigurationCurrentActionTypes;
(function (TenantConfigurationCurrentActionTypes) {
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Load"] = "TenantConfiguration_Current_Load";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update"] = "TenantConfiguration_Current_Update";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_Without_Server_Save"] = "TenantConfiguration_Current_Update_Without_Server_Save";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_Save_To_Server"] = "TenantConfiguration_Current_Update_Save_To_Server";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_DocTempCats"] = "TenantConfiguration_Current_Update_DocTempCats";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_TempCats"] = "TenantConfiguration_Current_Update_TempCats";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_DirectoryConfig"] = "TenantConfiguration_Current_Update_DirectoryConfig";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_TeamConfig"] = "TenantConfiguration_Current_Update_TeamConfig";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Update_Config"] = "TenantConfiguration_Current_Update_TeamConfig";
    TenantConfigurationCurrentActionTypes["TenantConfiguration_Current_Silent_Update"] = "TenantConfiguration_Current_Silent_Update";
})(TenantConfigurationCurrentActionTypes || (TenantConfigurationCurrentActionTypes = {}));
