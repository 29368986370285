var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SubtitleButton } from "app/components/common/subtitle";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import OrchModal from "app/components/common/orch-modal";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import { ReportSearchSlotName, ReportDatasource } from "app/store/lifecycle-report";
import InsightsReport from "app/components/insights/common/report";
import "./guest-request-workflow-insight-report.scss";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import InfoTooltip from "app/components/common/infotooltip";
import GuestService from "app/services/guests";
import * as UserCurrentStore from "app/store/users/current";
import { useIntl } from "react-intl";
export var GuestRequestApprovalContext = React.createContext({});
var GuestRequestWorkflowInsightReport = React.memo(function (props) {
    // The content of the current wizard.
    var dispatch = useDispatch();
    var _a = React.useState([]), requestActions = _a[0], setRequestActions = _a[1];
    var _b = React.useState(""), bulkComment = _b[0], setBulkComment = _b[1];
    var _c = React.useState(false), processing = _c[0], setProcessing = _c[1];
    var _d = React.useState(false), showComplete = _d[0], setShowComplete = _d[1];
    var _e = React.useState({ valid: false, reason: "" }), selectionsValid = _e[0], setselectionsValid = _e[1];
    var _f = React.useState(null), bulkJobId = _f[0], setBulkJobId = _f[1];
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var reportLoading = useSelector(function (state) { return state.lifecycleReportState[ReportSearchSlotName.GuestInsightsSlot].IsLoading; });
    var guestCount = useSelector(function (state) { return state.lifecycleReportState[ReportSearchSlotName.GuestInsightsSlot].ItemCount; });
    var intl = useIntl();
    React.useEffect(function () {
        if (currentUser === undefined || (currentUser !== undefined && currentUser.Id === undefined)) {
            dispatch(UserCurrentStore.Load());
        }
    }, []);
    var addOrUpdateAction = function (action) {
        var tempActions = __spreadArray([], requestActions, true);
        if (tempActions.some(function (ta) { return ta.userId === action.userId; })) {
            tempActions = tempActions.map(function (ra) { return (ra.userId === action.userId ? action : ra); });
        }
        else {
            tempActions.push(action);
        }
        setRequestActions(tempActions);
    };
    var guestRequestApprovalContext = {
        requestActions: requestActions,
        setRequestActions: setRequestActions,
        addOrUpdateAction: addOrUpdateAction
    };
    var navigateToDetails = function (reportItem) {
        dispatch(LifecyclePolicyWorkspaceStore.UpdateReportItem(reportItem));
    };
    var customHeader = function (insightContext) {
        return (React.createElement("div", { className: "custom-header" },
            React.createElement("div", { className: "title-left" }, insightContext.IsLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "top" },
                    React.createElement("div", { className: "title" }, intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.guests_awaiting_approval", defaultMessage: "Guests Awaiting Approval" })),
                    React.createElement("div", { className: "count" }, insightContext.Items.length)))))));
    };
    //validation
    React.useEffect(function () {
        var isValid = true;
        var reason = "";
        requestActions.forEach(function (a) {
            if (a.action === "Reject" && (a.comment === null || a.comment === "")) {
                isValid = false;
                reason = intl.formatMessage({ id: "actions.bulk.guests.reject_reason_for_all", defaultMessage: "Please make sure all rejections have a comment" });
            }
        });
        if (!reportLoading && requestActions.length !== guestCount) {
            isValid = false;
            reason = intl.formatMessage({ id: "actions.bulk.guests.input_for_all", defaultMessage: "Please provide input for all Guests" });
        }
        setselectionsValid({ valid: isValid, reason: reason });
    }, [JSON.stringify(requestActions), reportLoading]);
    var customFooter = function (insightContext) {
        return insightContext.Items.length > 0 ? (React.createElement("div", { className: "custom-footer" },
            selectionsValid.valid === false ? (React.createElement(InfoTooltip, { content: React.createElement(React.Fragment, null, selectionsValid.reason) },
                React.createElement("i", { className: "fa-sharp fa-solid fa-circle-info" }))) : null,
            processing !== true ? (React.createElement(SubtitleButton, { disabled: !selectionsValid.valid, onClick: function () { return onClickSubmitActions(); }, title: intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.submit_button", defaultMessage: "Submit" }), faIcon: "check-double", color: "brandblue" })) : null)) : (React.createElement(React.Fragment, null));
    };
    var bulkApply = function (userIds, action, comment) {
        if (comment === void 0) { comment = null; }
        var tempRequestActions = __spreadArray([], requestActions, true);
        userIds.forEach(function (uid) {
            if (tempRequestActions.some(function (a) { return a.userId === uid; })) {
                tempRequestActions = tempRequestActions.map(function (ta) { return (ta.userId === uid ? __assign(__assign({}, ta), { action: action, comment: comment === null ? ta.comment : comment }) : ta); });
            }
            else {
                tempRequestActions.push({
                    userId: uid,
                    action: action,
                    comment: comment
                });
            }
        });
        setRequestActions(tempRequestActions);
    };
    var customBulkActionApprove = function (context, selectedIds) {
        return (React.createElement("div", { className: "group" },
            React.createElement("div", { className: "button", onClick: function () { return bulkApply(selectedIds, "Approve"); } },
                React.createElement("i", { className: "fa fa-circle-check" }),
                intl.formatMessage({ id: "actions.bulk.guests.approve", defaultMessage: "Approve" }))));
    };
    var customBulkActionReject = function (context, selectedIds) {
        return (React.createElement(OrchModal, { simpleButton: true, size: "sm", headerText: intl.formatMessage({ id: "actions.bulk.guests.reject_reason", defaultMessage: "Please enter a comment about why you are rejecting" }), onOpen: function () { return setBulkComment(""); }, footerCloseText: "Cancel", footerPrimaryText: "Apply", footerPrimaryFunction: function () {
                bulkApply(selectedIds, "Reject", bulkComment);
            }, buttonContents: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "group" },
                    React.createElement("div", { className: "button" },
                        React.createElement("i", { className: "fa fa-circle-xmark" }),
                        intl.formatMessage({ id: "actions.bulk.guests.reject", defaultMessage: "Reject" })))) },
            React.createElement(Form.Control, { as: "textarea", value: bulkComment, onChange: function (event) { return setBulkComment(event.currentTarget.value); } })));
    };
    var onClickSubmitActions = function () {
        setProcessing(true);
        setShowComplete(false);
        GuestService.BulkSubmitGuestRequestApprovals(requestActions, props.groupId, currentUser === null || currentUser === void 0 ? void 0 : currentUser.GUID).then(function (jobIdData) {
            setBulkJobId(jobIdData.jobId);
        });
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (bulkJobId !== null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                GuestService.GetSubmitGuestRequestApprovalsStatus(bulkJobId).then(function (data) {
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_1 = false;
                        runAgain();
                    }
                    else {
                        AsyncWait(undefined, AsyncStatus.Succeeded, null, null, "Report_Refresh").then(function (res) {
                            setProcessing(false);
                            setShowComplete(true);
                            guestRequestApprovalContext.setRequestActions([]);
                        });
                        setTimeout(function () {
                            dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestInsightsSlot));
                        }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (bulkJobId !== null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [bulkJobId]);
    return (React.createElement("div", { className: "guest-request-workflow-insight-report" },
        React.createElement(GuestRequestApprovalContext.Provider, { value: guestRequestApprovalContext },
            React.createElement(InsightsReport, { NavigateToDetails: navigateToDetails, ActionParam: undefined, FilterParam: "workflow-guest-requests", Datasource: ReportDatasource.SQLServer, BasePath: "guests", SubPath: "listing", ResultName: "guest", NamePrefix: "", ShowArchived: false, ShowExport: false, ShowFiltersAndSearch: false, ShowDeleted: false, Slot: ReportSearchSlotName.GuestInsightsSlot, InitialDynamicValues: { groupId: props.groupId }, ShowPagingOnlyIfXAmountOfItems: 45, BulkEditEnabled: true, ResultsDisabled: processing, CustomHeader: function (context) { return customHeader(context); }, CustomFooter: function (context) { return customFooter(context); }, CustomBulkActions: [customBulkActionApprove, customBulkActionReject], NoResultMainText: intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.pending_approvals_complete", defaultMessage: "Pending Approvals Complete" }), NoResultSubText: intl.formatMessage({ id: "layout.teams_app.tabs.guest_approval.pending_approvals_complete_subtext", defaultMessage: "There are no pending approvals requiring your attention for this workspace" }), NoResultFaIcon: "paper-plane", NoResultAlternate: true, ShowBusyAnimation: processing }))));
});
export default GuestRequestWorkflowInsightReport;
