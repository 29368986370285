var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./directory-add-exists.scss";
import "app/components/directory/directory.scss";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import DirectoryService from "app/services/directory";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import AnimatedLoader from "app/components/common/animated-loader";
import * as MetadataListStore from "app/store/metadata/list";
import MetadataField from "app/components/common/metadata-field";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import NoResults from "app/components/common/no-results";
import stripHtmlTags from "app/utils/stripHtmlTags";
var directoryAddExistingSearch = 0;
var DirectoryAddExists = React.memo(function (props) {
    var dispatch = useDispatch();
    var listMetadata = useSelector(function (state) { return state.metadataListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState([]), searchResults = _a[0], setSearchResults = _a[1];
    var _b = React.useState(false), showSearchModal = _b[0], setShowSearchModal = _b[1];
    var _c = React.useState(false), showAddModal = _c[0], setShowAddModal = _c[1];
    var _d = React.useState(false), loadingResults = _d[0], setLoadingResults = _d[1];
    var _e = React.useState(undefined), customMetadataValuesJson = _e[0], setCustomMetadataValuesJson = _e[1];
    var _f = React.useState(undefined), addCurrentItem = _f[0], setAddCurrentItem = _f[1];
    var _g = React.useState([]), addedSiteIds = _g[0], setAddedSiteIds = _g[1];
    var _h = React.useState(false), addingNewSiteLoading = _h[0], setAddingNewSiteLoading = _h[1];
    var _j = React.useState(false), searchedOnce = _j[0], setSearchedOnce = _j[1];
    var _k = React.useState(""), lastSearchTerms = _k[0], setLastSearchTerms = _k[1];
    var _l = React.useState(false), addFormValid = _l[0], setAddFormValid = _l[1];
    React.useEffect(function () {
        dispatch(MetadataListStore.Load([]));
    }, []);
    var searchDirectory = function (searchTerms) {
        setLastSearchTerms(searchTerms);
        if (searchTerms.length === 0) {
            setLoadingResults(false);
            setSearchResults([]);
        }
        else {
            setLoadingResults(true);
            setSearchResults([]);
            var newState_1 = directoryAddExistingSearch + 1;
            directoryAddExistingSearch = newState_1;
            setTimeout(function () {
                if (newState_1 === directoryAddExistingSearch) {
                    DirectoryService.searchExistingSites(searchTerms).then(function (data) {
                        setSearchedOnce(true);
                        console.log("return data", data);
                        setSearchResults(data);
                        if (newState_1 === directoryAddExistingSearch) {
                            setLoadingResults(false);
                        }
                    });
                }
            }, 300);
        }
    };
    var getDirectoryItemUrl = function (item) {
        if (item.TemplateType === "Viva Engage Community" && item.VivaEngageCommunityUrl !== undefined && item.VivaEngageCommunityUrl !== null && item.VivaEngageCommunityUrl !== "") {
            return item.VivaEngageCommunityUrl;
        }
        else if (item.TeamUrl !== undefined && item.TeamUrl !== null && item.TeamUrl !== "") {
            return stripHtmlTags(item.TeamUrl);
        }
        else {
            return item.SiteCollectionUrl;
        }
    };
    var getRelativeUrl = function (url) {
        var returnUrl = "";
        if (orchestryContext.TenantSharePointUrl !== undefined) {
            returnUrl = url.replace(orchestryContext.TenantSharePointUrl, "");
        }
        return returnUrl;
    };
    var addSiteToDirectory = function (item) {
        setAddCurrentItem(item);
        setShowAddModal(true);
    };
    var updateCustomMetadataValue = function (mdfv) {
        var currentValues = [mdfv];
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            var foundCurrent_1 = false;
            currentValues = valuesArray.map(function (fv) {
                if (fv.FieldGUID === mdfv.FieldGUID) {
                    foundCurrent_1 = true;
                    return mdfv;
                }
                else {
                    return fv;
                }
            });
            if (foundCurrent_1 === false) {
                currentValues.push(mdfv);
            }
            //console.log("does match for mapping", currentValues)
        }
        setCustomMetadataValuesJson(JSON.stringify(currentValues));
    };
    var getCurrentMetadataFieldValue = function (md) {
        var value = undefined;
        if (customMetadataValuesJson !== undefined) {
            //console.log("rwc.currentRequest.CustomMetadataValuesJson", rwc.currentRequest.CustomMetadataValuesJson)
            var valuesArray = JSON.parse(customMetadataValuesJson);
            valuesArray.forEach(function (mdfv, index) {
                if (mdfv.FieldGUID === md.GUID) {
                    value = mdfv.Values;
                }
                else if (mdfv.FieldTitle === md.Title) {
                    value = mdfv.Values;
                }
            });
        }
        if (value !== undefined) {
            //console.log(value);
        }
        return value;
    };
    var addToDirectoryFinal = function () {
        if (addCurrentItem !== undefined) {
            //    toast(<NotificationContent text={"Adding " + addCurrentItem.Name + " to the site directory. This could take a few minutes."} mode="loading" />, {
            //       toastId:addCurrentItem.SiteId,
            //       autoClose: false,
            //       type: toast.TYPE.DEFAULT
            //   })
            setAddingNewSiteLoading(true);
            DirectoryService.addExistingSiteToDirectory(addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.Name, addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.SiteCollectionUrl, addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.SiteId, customMetadataValuesJson !== undefined ? customMetadataValuesJson : "").then(function (data) {
                //    toast.update(addCurrentItem.SiteId, {
                //       render: <NotificationContent text={"Added " + addCurrentItem.Name + " to the site directory!"} mode="success" />,
                //       type: toast.TYPE.DEFAULT,
                //       autoClose: 2500
                //   });
                var tempItems = __spreadArray([], addedSiteIds, true);
                tempItems.push(addCurrentItem.SiteId);
                setAddedSiteIds(tempItems);
                setAddingNewSiteLoading(false);
                setAddCurrentItem(undefined);
                setCustomMetadataValuesJson(undefined);
                setShowAddModal(false);
            });
        }
        console.log("item", addCurrentItem);
        console.log("metadata", customMetadataValuesJson);
    };
    return (React.createElement("div", { className: "directory-add-exists" },
        orchestryContext.Role === "Admin" ? (React.createElement("div", { className: "add-existing-button", onClick: function () {
                setShowSearchModal(!showSearchModal);
            } }, props.children)) : null,
        React.createElement(Modal, { centered: true, show: showSearchModal, onHide: function () { return setShowSearchModal(!showSearchModal); }, animation: true, className: "directory-modal directory-add-existing-modal", backdropClassName: "directory-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Search for existing Team or Site to add to directory")),
            React.createElement("div", { className: "top-section" },
                React.createElement("div", { className: "search" },
                    React.createElement("div", { className: "search-box" },
                        React.createElement("input", { placeholder: "Search for teams or sites", onChange: function (e) { return searchDirectory(e.currentTarget.value); } }),
                        loadingResults === true ? (React.createElement("div", { className: "loading" },
                            React.createElement(AnimatedLoader, { size: "xxsmall" }))) : null,
                        React.createElement("i", { className: "la la-search" })))),
            React.createElement("div", { className: "bottom-section" },
                searchedOnce === true && loadingResults === false && searchResults.length === 0 && lastSearchTerms.length !== 0 ? (React.createElement("div", { className: "no-results" },
                    React.createElement(NoResults, { subText: "We couldn't find any teams or sites matching '" + lastSearchTerms + "' that are not already in the directory." }))) : null,
                React.createElement("div", { className: "item-results" }, searchResults.map(function (sr) { return (React.createElement("div", { className: "item" + (addedSiteIds.includes(sr.SiteId) === true || (sr.ExistsAlready !== undefined && sr.ExistsAlready === true) ? " already-added" : "") },
                    React.createElement("div", { className: "profile-icon" },
                        React.createElement(OfficeProfilePicture, { size: "sm", uid: sr.TemplateType.indexOf("Communication") > -1 ? sr.SiteCollectionUrl : undefined, type: sr.TemplateType.indexOf("SharePoint") > -1 ? "SharePoint" : "Teams", base64image: sr.Base64Logo })),
                    React.createElement("div", { className: "title" },
                        React.createElement("div", { className: "name" }, sr.Name),
                        React.createElement("a", { className: "url", href: getDirectoryItemUrl(sr), target: "_blank" }, sr.TeamUrl !== undefined && sr.TeamUrl !== null && sr.TeamUrl !== "" ? "Open in Teams" : getRelativeUrl(sr.SiteCollectionUrl))),
                    React.createElement("div", { className: "description" }, sr.Description),
                    React.createElement("div", { className: "type" }, sr.TemplateType.indexOf("SharePoint") > -1 ? (React.createElement("img", { src: "/media/orchestry/icon-sharepoint.svg" })) : sr.TemplateType.indexOf("Team") > -1 ? (React.createElement("img", { src: "/media/orchestry/icon-teams.svg" })) : (React.createElement("img", { src: iconViva }))),
                    React.createElement("div", { className: "actions" }, addedSiteIds.includes(sr.SiteId) === true || (sr.ExistsAlready !== undefined && sr.ExistsAlready === true) ? (React.createElement("div", { className: "check", title: "already added" },
                        React.createElement("i", { className: "fa fa-check" }))) : (React.createElement("div", { className: "add", onClick: function (e) { return addSiteToDirectory(sr); } },
                        React.createElement("i", { className: "fa fa-plus" })))))); })))),
        React.createElement(Modal, { centered: true, show: showAddModal, onHide: function () { return setShowAddModal(!showAddModal); }, animation: true, className: "directory-add-form-modal", backdropClassName: "directory-add-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Add existing Team or Site")),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "content" }, addCurrentItem !== undefined ? (React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                        setAddFormValid(isValid);
                    }, UniqueIdentifier: "AddExsitingTeam" },
                    React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 500 },
                        React.createElement("div", { className: "logo" },
                            React.createElement(OfficeProfilePicture, { size: "lg", uid: addCurrentItem.TemplateType.indexOf("Communication") > -1 ? addCurrentItem.SiteCollectionUrl : undefined, type: addCurrentItem.TemplateType.indexOf("SharePoint") > -1 ? "SharePoint" : "Teams", base64image: addCurrentItem.Base64Logo })),
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Team/Site Name"),
                            React.createElement(Form.Control, { value: addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.Name, disabled: true })),
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Team/Site Description"),
                            React.createElement(Form.Control, { value: addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.Name, disabled: true, as: "textarea", rows: 2 })),
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Site Url"),
                            React.createElement(Form.Control, { value: addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.SiteCollectionUrl, disabled: true })),
                        (addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.TeamUrl) !== undefined && (addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.TeamUrl) !== null && (addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.TeamUrl) !== "" ? (React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Team Url"),
                            React.createElement(Form.Control, { value: stripHtmlTags(addCurrentItem === null || addCurrentItem === void 0 ? void 0 : addCurrentItem.TeamUrl), disabled: true }))) : null,
                        listMetadata.map(function (mdi, index) { return (
                        //<>{mdi.ShowInDetailsForm === true ?
                        React.createElement("div", { key: index },
                            React.createElement(MetadataField, { key: index, metaDataItem: mdi, onChange: function (mdfv) {
                                    updateCustomMetadataValue(mdfv);
                                }, defaultValue: getCurrentMetadataFieldValue(mdi) },
                                mdi.FieldType === "Link" && getCurrentMetadataFieldValue(mdi) !== undefined ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-url-" + mdi.SPFieldInternalName, Validators: ["is-valid-url"], Value: getCurrentMetadataFieldValue(mdi), ShowFirst: true })) : null,
                                mdi.RequiredInDetailsForm !== undefined && mdi.RequiredInDetailsForm === true ? (React.createElement("div", null,
                                    mdi.FieldType === "Date" || mdi.FieldType === "Text" || mdi.FieldType === "Link" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-not-empty"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                    mdi.FieldType === "Choice" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                    mdi.FieldType === "ManagedMetadata" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null)) : null))
                        //:null}</>
                        ); })))) : null)),
            React.createElement(Modal.Footer, null, addingNewSiteLoading === false ? (React.createElement("div", { className: "buttons" },
                React.createElement("div", { className: "cancel", onClick: function () { return setShowAddModal(!showAddModal); } }, "Cancel"),
                addFormValid === true ? (React.createElement("div", { className: "save", onClick: function () { return addToDirectoryFinal(); } },
                    React.createElement("i", { className: "fa fa-plus" }),
                    " Add to Directory!")) : (React.createElement("div", { className: "save no-valid" },
                    React.createElement("i", { className: "fa fa-plus" }),
                    " Add to Directory!")))) : (React.createElement("div", { className: "loading" },
                React.createElement(AnimatedLoader, { size: "small" })))))));
});
export default DirectoryAddExists;
