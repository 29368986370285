var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { Form, Col, Row } from "react-bootstrap";
import ChannelsModal from "app/components/common/channels-modal";
import * as TemplateCurrentStore from "app/store/templates/current";
import { useDispatch } from "react-redux";
import * as TableList from "app/components/common/table-list";
import generateGuid from "app/utils/generateGuid";
import FormToggleControl from "app/components/common/form-toggle-control";
var TabChannels = React.memo(function (props, state) {
    var dispatch = useDispatch();
    //console.log("Channels tab > currentTemplate", props.currentTemplate)
    var toggleRequired = function (channelMap, checked) {
        channelMap.Required = checked;
        // Change this to use List_Item_Update and do the true/false logic here.
        if (props.currentTemplate.AssociatedChannels !== undefined) {
            dispatch(TemplateCurrentStore.Update({
                AssociatedChannels: __spreadArray([], props.currentTemplate.AssociatedChannels, true)
            }));
        }
    };
    var removeFeature = function (tbaToRemove) {
        var _a;
        dispatch(TemplateCurrentStore.Update({
            AssociatedChannels: (_a = props.currentTemplate.AssociatedChannels) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var addChannel = function (chan) {
        //setAssociatedTemplates([...tempArray, ...currentFeature.AssociatedTemplates]);
        var newTBA = {
            GUID: generateGuid(),
            Required: true,
            Item: {
                GUID: chan.GUID,
                Title: chan.Title,
                Description: chan.Description
            }
        };
        var tempArray = [newTBA];
        if (props.currentTemplate.AssociatedChannels !== undefined) {
            if (props.currentTemplate.AssociatedChannels.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), props.currentTemplate.AssociatedChannels, true);
            }
        }
        dispatch(TemplateCurrentStore.Update({ AssociatedChannels: tempArray }));
    };
    return (
    // <Container fluid={true}>
    React.createElement(Row, null,
        React.createElement(Col, { "data-pw": "channels-tab-content" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 6 },
                    React.createElement(Form.Group, { controlId: "AllowChannelsCustomization", className: "form-group standalone-checkbox" },
                        React.createElement(FormToggleControl, { checked: props.currentTemplate.AllowChannelsCustomization, label: "Allow users to customize channels for this template", handleChange: function (checked) {
                                return dispatch(TemplateCurrentStore.Update({
                                    AllowChannelsCustomization: checked
                                }));
                            } })))),
            React.createElement(TableList.Context, { asyncListLoadUID: props.currentTemplate.GUID !== undefined ? props.currentTemplate.GUID : "", listItems: props.currentTemplate.AssociatedChannels ? props.currentTemplate.AssociatedChannels : [], showLoader: false, wrappedInPortlet: false },
                React.createElement(TableList.TableList, { tstyle: "border-bottom-on" },
                    React.createElement(TableList.HeaderItem, null, "Channel Name"),
                    React.createElement(TableList.HeaderItem, null, "Channel's purpose"),
                    React.createElement(TableList.HeaderItem, { type: "centered" }, "Required"),
                    React.createElement(TableList.HeaderItem, { type: "actions" }),
                    React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Item.Title", isMobileTitle: true }),
                    React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Item.Description" }),
                    React.createElement(TableList.ContentItemToggle, { listDataProperyName: "Required", handleChange: function (item, checked) { return toggleRequired(item, checked); } }),
                    React.createElement(TableList.ContentItemActions
                    // editNavLinkTo="/workspaces/channels/edit/{Item.GUID}"
                    , { 
                        // editNavLinkTo="/workspaces/channels/edit/{Item.GUID}"
                        onRemove: function (item) { return removeFeature(item); } }))),
            React.createElement(ChannelsModal, { associatedChannels: props.currentTemplate.AssociatedChannels, handleSelected: function (chan) {
                    addChannel(chan);
                } })))
    // </Container>
    );
});
export default TabChannels;
