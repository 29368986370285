var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import vivaIcon from "app/assets/img/icon-viva-engage.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import { AsyncWait } from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggle from "app/components/common/form-toggle";
import InfoTooltip from "app/components/common/infotooltip";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import OrchModal from "app/components/common/orch-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import LifecycleService from "app/services/lifecycle";
import LifecycleReportService from "app/services/lifecycle-report";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as LifecyclePoliciesListStore from "app/store/lifecycle-policies/list";
import { LifecyclePolicyListActionTypes } from "app/store/lifecycle-policies/list";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./report-bulk-action-modal.scss";
import LifecycleWorkspaceBulkActionRemediateModal from "./workspace-bulk-action-remediate-modal";
import LifecycleWorkspaceBulkResetModal from "./workspace-bulk-reset-modal";
var LifecycleWorkspaceBulkActionModal = React.memo(function (props) {
    var dispatch = useDispatch();
    var reportItems = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Items; });
    var lifecycleArchivalPolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var _a = React.useState(null), defaultPolicies = _a[0], setDefaultPolicies = _a[1];
    var _b = React.useState([]), currentPolicies = _b[0], setCurrentPolicies = _b[1];
    var _c = React.useState(false), modalOpen = _c[0], setModalOpen = _c[1];
    var _d = React.useState(false), archivalPoliciesLoaded = _d[0], setArchivalPoliciesLoaded = _d[1];
    var _e = React.useState(false), processing = _e[0], setProcessing = _e[1];
    var _f = React.useState([]), clonePreviewItems = _f[0], setClonePreviewItems = _f[1];
    var _g = React.useState(false), showComplete = _g[0], setShowComplete = _g[1];
    var _h = React.useState(null), bulkAssignJobId = _h[0], setBulkAssignJobId = _h[1];
    React.useEffect(function () {
        if (modalOpen === true && processing === false) {
            setCurrentPolicies([]);
            setShowComplete(false);
            LifecycleReportService.getDefaultPolicies().then(function (policies) {
                setDefaultPolicies(policies.defaultArchivalPolicies);
            });
        }
        if (props.Type === "AssignPolicy" && modalOpen === true) {
            setCurrentPolicies(reportItems
                .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
                .map(function (ri) { return (__assign({}, ri
            // SiteId: ri.SiteId,
            // ArchivalPolicyGuid: ri.ArchivalPolicyGuid,
            // IsProtected: ri.IsProtected
            )); }));
            dispatch(LifecyclePoliciesListStore.Load("Archival"));
            AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, undefined, null, LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load).then(function () {
                setTimeout(function () { return setArchivalPoliciesLoaded(true); }, 500);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen]);
    var canForcePolicy = function (reportItem) {
        var canForce = true;
        if (reportItem.IsArchived || reportItem.ArchivalStatus === "Done") {
            canForce = false;
        }
        if (reportItem.IsDeleted || reportItem.ArchivalStatus === "Deleted") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Failed") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "PendingApproval") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Processing") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "ReadyToArchive") {
            canForce = false;
        }
        if (reportItem.ArchivalStatus === "Extended") {
            canForce = false;
        }
        if (reportItem.IsProtected === true) {
            canForce = false;
        }
        // if (reportItem.SharePointTeamSiteHasNoGroup == true) {
        //    canForce = false;
        // }
        //DeletionBlockedByRetentionPolicy
        var policy = getPolicyForWorkspace(reportItem);
        if (policy === null) {
            canForce = false;
        }
        return canForce;
    };
    var getExecuteAction = function (reportItem) {
        var title = "";
        var desc = "";
        var canDo = true;
        title = "Force Archival";
        if (reportItem.IsArchived || reportItem.ArchivalStatus === "Done") {
            // title = "Nothing";
            title = "Already Archived";
            canDo = false;
        }
        if (reportItem.IsDeleted || reportItem.ArchivalStatus === "Deleted") {
            // title = "Nothing";
            title = "Deleted";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Failed") {
            // title = "Nothing";
            title = "Previous Execution Failed";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "PendingApproval") {
            // title = "Nothing";
            title = "Archival already in progress - waiting for approval";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Processing") {
            // title = "Nothing";
            title = "Already running archival";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "ReadyToArchive") {
            // title = "Nothing";
            title = "Already in queue for archival";
            canDo = false;
        }
        if (reportItem.ArchivalStatus === "Extended") {
            // title = "Nothing";
            title = "Workspace Renewed";
            canDo = false;
        }
        if (reportItem.IsProtected === true) {
            // title = "Nothing";
            title = "Workpspace is protected from Archival";
            canDo = false;
        }
        // if (reportItem.SharePointTeamSiteHasNoGroup == true) {
        //    // title = "Nothing";
        //    title = "Workpspace group is missing from Team Site. This is usually because of a pending deletion.";
        //    canDo = false;
        // }
        var policy = getPolicyForWorkspace(reportItem);
        if (policy === null) {
            // title = "Nothing";
            title = "There is no policy assigned";
            canDo = false;
        }
        if (policy !== null) {
            return (React.createElement("div", { className: "action" },
                React.createElement("div", { className: "a-left" },
                    React.createElement("i", { className: "far fa-" + (canDo === true ? "check-circle" : "circle") })),
                React.createElement("div", { className: "a-right" },
                    React.createElement("div", { className: "a-title" }, title),
                    desc !== "" ? React.createElement("div", { className: "a-description" }, desc) : null)));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    var getDefaultPolicy = function (reportItem) {
        var foundDefaultWorkspace = defaultPolicies === null || defaultPolicies === void 0 ? void 0 : defaultPolicies.find(function (ap) { return ap.Application === reportItem.Application || (ap.Application === "TeamSite" && reportItem.Application === "VivaEngageCommunity"); });
        return foundDefaultWorkspace !== undefined ? foundDefaultWorkspace : null;
    };
    var getAssignablePolicies = function (reportItem) {
        var policies = [];
        var defaultPolicy = getDefaultPolicy(reportItem);
        if (defaultPolicy !== null && defaultPolicy !== undefined) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (lifecycleArchivalPolicies !== null) {
            policies = __spreadArray(__spreadArray([], policies, true), lifecycleArchivalPolicies
                .filter(function (ap) { return ap.Application === reportItem.Application || (ap.Application === "TeamSite" && reportItem.Application === "VivaEngageCommunity"); })
                .map(function (ap) { return ({
                id: ap.GUID,
                value: ap.GUID,
                title: ap.Name,
                description: ap.Description
            }); }), true);
        }
        return policies;
    };
    var undoAssignedPolicy = function (siteId) {
        setCurrentPolicies(__spreadArray([], currentPolicies.map(function (cp) {
            if (cp.SiteId !== siteId) {
                return cp;
            }
            else {
                var foundRi = reportItems.find(function (ri) { return ri.SiteId === siteId; });
                if (foundRi !== undefined) {
                    return __assign({}, foundRi);
                }
                else {
                    return cp;
                }
            }
        }), true));
    };
    var updateAssignedPolicies = function (item, ap) {
        setCurrentPolicies(__spreadArray([], currentPolicies.map(function (cp) { return (cp.SiteId === item.SiteId ? __assign(__assign({}, item), { ArchivalPolicyGuid: ap }) : cp); }), true));
    };
    var updateProtected = function (item, isProtected) {
        setCurrentPolicies(__spreadArray([], currentPolicies.map(function (cp) { return (cp.SiteId === item.SiteId ? __assign(__assign({}, item), { IsProtected: isProtected }) : cp); }), true));
    };
    var getPolicyForWorkspace = function (reportItem) {
        var policyName = null;
        if (reportItem.ArchivalPolicyName !== null) {
            policyName = reportItem.ArchivalPolicyName;
        }
        else {
            var foundDefaultPolicy = getDefaultPolicy(reportItem);
            if (foundDefaultPolicy !== null) {
                policyName = foundDefaultPolicy.Name;
            }
        }
        return policyName;
    };
    var getCountOfExecutablePolicies = function () {
        var count = 0;
        reportItems
            .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
            .forEach(function (item) {
            if (canForcePolicy(item)) {
                count++;
            }
        });
        return count;
    };
    var getExecutablePoliciesSiteIds = function () {
        var ids = [];
        reportItems
            .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
            .forEach(function (item) {
            if (canForcePolicy(item)) {
                ids.push(item.SiteId);
            }
        });
        return ids;
    };
    // const setCloneItemPreviews = () => {
    //    setClonePreviewItems();
    // }
    var getDropdownPolicyValue = function (item) {
        return item.ArchivalPolicyGuid;
    };
    var cloneSelectionToAllWorkspaces = function (item) {
        //let newPolicyGuid = item.ArchivalPolicyGuid;
        // const foundAssignmentForSourceItem = assignedPolicies.find(ap => ap.workspaceGuid === item.SiteId);
        // if (foundAssignmentForSourceItem !== undefined) {
        //    newPolicyGuid = foundAssignmentForSourceItem.archivalPolicyGuid;
        // }
        // let matchingReportItem = reportItems.find(ri => ri.SiteId === item.SiteId);
        // if (matchingReportItem !== undefined) {
        //    var reportItemsToUpdate: ReportItem[] = reportItems.filter(ri => ri.Application === (matchingReportItem as ReportItem).Application && props.PolicyGuids?.some(pg => pg === ri.SiteId) && ri.SiteId !== (matchingReportItem as ReportItem).SiteId);
        //    let tempAssignments = [...currentPolicies];
        setCurrentPolicies(currentPolicies.map(function (cp) {
            if (cp.Application === item.Application && cp.SiteId !== item.SiteId && isWorkspaceEligibleForPolicyReAssignment(cp)) {
                return __assign(__assign({}, cp), { ArchivalPolicyGuid: item.ArchivalPolicyGuid, IsProtected: item.IsProtected });
            }
            return cp;
        }));
        // reportItemsToUpdate.forEach(ri => {
        //    console.log("Site Id To Clone To", ri.SiteId);
        //    if (isWorkspaceEligibleForPolicyReAssignment(ri)) {
        //       // if (tempAssignments.some(ap => ap.workspaceGuid === ri.SiteId)) {
        //       tempAssignments = [
        //          ...tempAssignments.filter(ap => ap.SiteId !== ri.SiteId),
        //          {
        //             ...ri,
        //             ArchivalPolicyGuid: item.ArchivalPolicyGuid,
        //             IsProtected: item.IsProtected
        //          }
        //       ];
        //       // } else {
        //       //    tempAssignments = [...tempAssignments, { archivalPolicyGuid: newPolicyGuid, workspaceGuid: ri.SiteId }];
        //       // }
        //    }
        // });
        //setCurrentPolicies(tempAssignments);
        //}
        setClonePreviewItems([]);
    };
    var getNewPolicyAssignments = function () {
        var tempAssignments = [];
        currentPolicies.forEach(function (cp) {
            if (isPolicyOrProtectedNew(cp)) {
                tempAssignments.push(cp);
            }
        });
        return tempAssignments;
    };
    var getNewProtectedAssignments = function () {
        var tempAssignments = [];
        currentPolicies.forEach(function (cp) {
            var foundReportItem = reportItems.find(function (ri) { return ri.SiteId === cp.SiteId; });
            if (foundReportItem !== undefined && foundReportItem.IsProtected !== cp.IsProtected) {
                tempAssignments.push(cp);
            }
        });
        return tempAssignments;
    };
    var isPolicyOrProtectedNew = function (item) {
        var foundReportItem = reportItems.find(function (ri) { return ri.SiteId === item.SiteId; });
        if (foundReportItem !== undefined) {
            return item.ArchivalPolicyGuid !== foundReportItem.ArchivalPolicyGuid || item.IsProtected !== foundReportItem.IsProtected;
        }
        return false;
    };
    var isWorkspaceEligibleForPolicyReAssignment = function (reportItem) {
        var isEligible = true;
        if (reportItem.ArchivalStatus !== "Active") {
            isEligible = false;
        }
        // if (reportItem.IsProtected === true) {
        //    isEligible = false;
        // }
        if (reportItem.IsArchived === true) {
            isEligible = false;
        }
        if (reportItem.IsDeleted === true) {
            isEligible = false;
        }
        // if (reportItem.SharePointTeamSiteHasNoGroup == true) {
        //    isEligible = false;
        // }
        return isEligible;
    };
    var isWorkspaceEligibleForPolicyReAssignmentForRemediation = function (reportItem) {
        var isEligible = false;
        if (reportItem.ArchivalStatus === "Done") {
            isEligible = true;
        }
        // if (reportItem.IsArchived === true) {
        //    isEligible = true;
        // }
        // if (reportItem.IsDeleted === true) {
        //    isEligible = true;
        // }
        // if (reportItem.SharePointTeamSiteHasNoGroup === true) {
        //    isEligible = true;
        // }
        return isEligible;
    };
    var getWorkspaceNoEligibleReason = function (reportItem) {
        var isEligible = "";
        if (reportItem.ArchivalStatus !== "Active") {
            if (reportItem.ArchivalStatus === "PendingApproval" || reportItem.ArchivalStatus === "Extended") {
                isEligible = "Workspace is not eligible for policy assignment due to its status. Please change or reset the Workspace status to assign a policy";
            }
            else {
                isEligible = "Workspace is not active and therefore not eligible for policy assignment";
            }
        }
        // if (reportItem.IsProtected === true) {
        //    isEligible = "Workspace is protected from archival and therefore not eligible for policy assignment";
        // }
        if (reportItem.IsArchived === true) {
            isEligible = "Workspace is already archived and therefore not eligible for policy assignment";
        }
        if (reportItem.IsDeleted === true) {
            isEligible = "Workspace is deleted and therefore not eligible for policy assignment";
        }
        // if (reportItem.SharePointTeamSiteHasNoGroup == true) {
        //    isEligible = "The group associated with the site is missing. This is usually because the group was deleted, but Microsoft hasn't deleted the site yet. This usually takes 48 hours.";
        // }
        return isEligible;
    };
    var onClickExecutePolicies = function () {
        setProcessing(true);
        LifecycleService.BulkExecuteArchivalPolicies(getExecutablePoliciesSiteIds()).then(function () {
            setProcessing(false);
            setShowComplete(true);
            dispatch(LifecycleReportStore.Refresh(props.Slot));
        });
    };
    var onClickAssignPolicies = function () {
        setProcessing(true);
        LifecycleService.BulkAssignArchivalPolicies(getNewPolicyAssignments()).then(function (jobIdData) {
            setBulkAssignJobId(jobIdData.jobId);
            // setProcessing(false);
            // setShowComplete(true);
            // setTimeout(()=>{
            //    dispatch(LifecycleReportStore.Refresh());
            // }, 1000);
        });
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (bulkAssignJobId !== null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                LifecycleService.GetBulkAssignStatus(bulkAssignJobId).then(function (data) {
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_1 = false;
                        runAgain();
                    }
                    else {
                        setProcessing(false);
                        setShowComplete(true);
                        setTimeout(function () {
                            dispatch(LifecycleReportStore.Refresh(props.Slot));
                        }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (bulkAssignJobId !== null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [bulkAssignJobId]);
    return (React.createElement("div", { className: "lifecycle-report-bulk-action-modal" }, props.Type === "Remediate" ? (React.createElement(LifecycleWorkspaceBulkActionRemediateModal, { SiteReportItemIds: props.PolicyGuids, Slot: props.Slot }, props.children)) : props.Type === "Reset" ? (React.createElement(LifecycleWorkspaceBulkResetModal, { SiteReportItemIds: props.PolicyGuids, Slot: props.Slot }, props.children)) : (React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null, props.children), simpleButton: true, headerText: props.Type === "AssignPolicy" ? "Assign policies to workspaces" : "Execute policies on workspaces", 
        // footerPrimaryText={props.Type === "AssignPolicy" ? "Assign Policies" : "Execute Policies"}
        size: "xl", modalClassName: "lifecycle-report-bulk-action-modal", showModalOverride: modalOpen, showModalToggleOverride: function (shouldShow) { return setModalOpen(shouldShow); }, showCloseButton: processing === true ? false : true, footerCloseText: showComplete === true ? "Close" : processing === true ? undefined : "Cancel", footerContents: props.Type === "ExecutePolicy" ? (React.createElement(React.Fragment, null, showComplete === true ? null : processing === false ? (React.createElement(Button, { variant: "primary", disabled: getCountOfExecutablePolicies() === 0, onClick: function () { return onClickExecutePolicies(); } },
            "Execute Policy On These ",
            getCountOfExecutablePolicies(),
            " Workspaces")) : (React.createElement(Button, { variant: "secondary" }, "Executing... Please wait...")))) : (React.createElement(React.Fragment, null, showComplete === true ? null : processing === false ? (React.createElement(Button, { variant: "primary", disabled: getNewPolicyAssignments().length === 0, onClick: function () { return onClickAssignPolicies(); } },
            "Assign Policies To ",
            getNewPolicyAssignments().length,
            " Workspaces")) : (React.createElement(Button, { variant: "secondary" }, "Assigning... Please wait...")))) }, props.Type === "ExecutePolicy" ? (defaultPolicies === null || defaultPolicies === undefined ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })) : showComplete === true ? (React.createElement("div", { className: "completed" },
        React.createElement("div", { className: "completed-box" },
            React.createElement("i", { className: "flaticon-list" }),
            React.createElement("div", { className: "done-text" }, "Done - Policies executed on workspaces!"),
            React.createElement(NavLink, { exact: true, to: "/lifecycle/queue" }, "Check out the status of these executions in the Lifecycle Queue")))) : processing === true ? (React.createElement("div", { className: "processing-card" },
        React.createElement(AnimatedLoader, { text: "Executing policies", type: "squares", size: "xxsmall" }))) : (React.createElement("table", null,
        React.createElement("tr", null,
            React.createElement("th", null, "Workspace"),
            React.createElement("th", null, "Renewal / Archival Policy"),
            React.createElement("th", null, "Archival Action")),
        reportItems
            .filter(function (ri) { var _a; return (_a = props.PolicyGuids) === null || _a === void 0 ? void 0 : _a.some(function (pg) { return pg === ri.SiteId; }); })
            .map(function (item) { return (React.createElement("tr", { className: (getPolicyForWorkspace(item) === null ? "no-policy" : "") + (canForcePolicy(item) ? "" : " cant-force") },
            React.createElement("td", null,
                React.createElement("div", { className: "title-area" },
                    React.createElement("div", { className: "ta-icon" },
                        React.createElement(OfficeProfilePicture, { size: "xs", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                    React.createElement("div", { className: "m365-icon" }, item.Application === "VivaEngageCommunity" ? (React.createElement("img", { src: vivaIcon, title: "Viva Engage Community" })) : item.Application !== "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png", title: item.Application })) : (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png", title: item.Application }))),
                    React.createElement("div", { className: "ta-title" }, item.Title))),
            React.createElement("td", null,
                React.createElement("div", null, getPolicyForWorkspace(item) !== null ? getPolicyForWorkspace(item) : "No Policy")),
            React.createElement("td", null, getExecuteAction(item)))); })))) : defaultPolicies === null || archivalPoliciesLoaded === false ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })) : showComplete === true ? (React.createElement("div", { className: "completed" },
        React.createElement("div", { className: "completed-box" },
            React.createElement("i", { className: "flaticon-list" }),
            React.createElement("div", { className: "done-text" }, "Done - Policies assigned to workspaces!")))) : processing === true ? (React.createElement("div", { className: "processing-card" },
        React.createElement(AnimatedLoader, { text: "Assigning policies to workspaces", type: "squares", size: "xxsmall" }))) : (React.createElement("table", null,
        React.createElement("tr", null,
            React.createElement("th", null, "Workspace"),
            React.createElement("th", null, "Renewal / Archival Policy"),
            React.createElement("th", { className: "th-protected" },
                React.createElement("i", { className: "fa fa-shield-alt" }),
                React.createElement(InfoTooltip, { title: "Excluded from Archivals" })),
            React.createElement("th", { className: "th-actions" })),
        currentPolicies.map(function (item) {
            var _a;
            return (React.createElement("tr", { className: isPolicyOrProtectedNew(item) ? " item-to-be-updated" : "" + (isWorkspaceEligibleForPolicyReAssignment(item) === true ? "" : " workspace-ineligible") },
                React.createElement("td", null,
                    React.createElement("div", { className: "title-area" },
                        React.createElement("div", { className: "ta-icon" },
                            React.createElement(OfficeProfilePicture, { size: "xs", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                        React.createElement("div", { className: "m365-icon" }, item.Application === "CommunicationSite" || item.Application === "TeamSite" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png", title: item.Application })) : item.Application === "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png", title: item.Application })) : (React.createElement("img", { src: vivaIcon, title: item.Application }))),
                        React.createElement("div", { className: "ta-title" }, item.Title))),
                React.createElement("td", null,
                    React.createElement("div", { className: "dropdowns" + (clonePreviewItems.some(function (pi) { return item.SiteId === pi; }) ? " clone-preview" : "") }, isWorkspaceEligibleForPolicyReAssignment(item) && getAssignablePolicies(item).length > 1 ? (React.createElement(React.Fragment, null, item.IsProtected ? (React.createElement("div", { className: "form-control no-selection-allowed excluded-from-archival" },
                        React.createElement("div", { className: "no-dd-title" }, "-- Excluded from archival --"))) : (React.createElement(FormDropdownSelect, { list: getAssignablePolicies(item), disabled: getAssignablePolicies(item).length < 2 || getDropdownPolicyValue(item) === "protected", value: getDropdownPolicyValue(item), onChange: function (value) {
                            updateAssignedPolicies(item, value);
                        } })))) : (React.createElement("div", { className: "form-control no-selection-allowed" },
                        React.createElement("div", { className: "no-dd-title" }, (_a = getAssignablePolicies(item).find(function (ap) { return ap.value === getDropdownPolicyValue(item); })) === null || _a === void 0 ? void 0 : _a.title),
                        React.createElement("div", { className: "no-dd-info" },
                            React.createElement(InfoTooltip, { title: isWorkspaceEligibleForPolicyReAssignment(item) === false
                                    ? getWorkspaceNoEligibleReason(item)
                                    : getAssignablePolicies(item).length < 2
                                        ? "There are no policies available"
                                        : "" })))))),
                React.createElement("td", { className: "toggle-protection" + (clonePreviewItems.some(function (pi) { return item.SiteId === pi; }) ? " clone-preview" : "") },
                    React.createElement(DisableArea, { isEnabled: isWorkspaceEligibleForPolicyReAssignment(item), useOverlay: true },
                        React.createElement(FormToggle, { checked: item.IsProtected, handleChange: function (checked) { return updateProtected(item, checked); } }))),
                React.createElement("td", null,
                    React.createElement("div", { className: "actions" },
                        React.createElement("div", { className: "clone", onClick: function () { return cloneSelectionToAllWorkspaces(item); }, onMouseEnter: function () {
                                return getAssignablePolicies(item).length > 1 && isWorkspaceEligibleForPolicyReAssignment(item)
                                    ? setClonePreviewItems(reportItems
                                        .filter(function (ri) { return isWorkspaceEligibleForPolicyReAssignment(ri) === true && ri.Application === item.Application && ri.SiteId !== item.SiteId; })
                                        .map(function (ri) { return ri.SiteId; }))
                                    : null;
                            }, onMouseLeave: function () { return setClonePreviewItems([]); } }, isWorkspaceEligibleForPolicyReAssignment(item) === false ? (React.createElement("div", { className: "btn disabled" },
                            React.createElement("i", { className: "fa fa-clone" }))) : (React.createElement(InfoTooltip, { placement: "right", delayHide: 0, content: "Clone this policy to all workspaces of the same application type (" + item.Application + ")." },
                            React.createElement("div", { className: "btn" },
                                React.createElement("i", { className: "fa fa-clone" }))))),
                        React.createElement("div", { className: "undo", onClick: function () { return undoAssignedPolicy(item.SiteId); } }, isPolicyOrProtectedNew(item) === false || isWorkspaceEligibleForPolicyReAssignment(item) === false ? (React.createElement("div", { className: "btn disabled" },
                            React.createElement("i", { className: "fa fa-undo-alt" }))) : (React.createElement(InfoTooltip, { placement: "right", delayHide: 0, content: "Undo policy assignment change" },
                            React.createElement("div", { className: "btn" },
                                React.createElement("i", { className: "fa fa-undo-alt" })))))))));
        })))))));
});
export default LifecycleWorkspaceBulkActionModal;
