import { useGetHealthChecksQuery } from "appv2/api/health-checks/health-checks.api";
import { defaultResultOptions } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { usePageSearch } from "appv2/hooks/use-page-search.hook";
import { generateFilterExpression, getOrderByExpression } from "appv2/utils/paging.utils";
import { useEffect, useMemo, useState } from "react";
import { useHealthCheckCanEdit } from "../health-check/health-check/use-health-check-can-edit.hook";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
export var useTenantHealthChecksApi = function (appliedFilters) {
    var _a;
    var _b = useState(1), activePage = _b[0], setActivePage = _b[1];
    var _c = useState(function () { var _a; return (_a = localStorage.getItem("health-checks-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"; }), resultsPerPage = _c[0], setResultsPerPage = _c[1];
    var filterExpression = useMemo(function () { return generateFilterExpression(appliedFilters, "Status"); }, [appliedFilters]);
    var _d = useState([{ id: "GeneratedDate", desc: true }]), sorting = _d[0], setSorting = _d[1];
    var _e = usePageSearch(setActivePage), querySearchText = _e.querySearchText, highlightSearchWords = _e.highlightSearchWords, searchTextInputValue = _e.searchTextInputValue, onSearchBoxChange = _e.onSearchBoxChange, onSearchBoxKeyDown = _e.onSearchBoxKeyDown, onSearchBoxXClicked = _e.onSearchBoxXClicked;
    var _f = useHealthCheckRouteParams(), tenantGuid = _f.tenantGuid, userType = _f.userType;
    var _g = useHealthCheckCanEdit(), userCanEdit = _g.userCanEdit, isUserCanEditLoading = _g.isLoading;
    var _h = useGetHealthChecksQuery([
        userType,
        tenantGuid,
        {
            queryParameters: {
                filter: filterExpression,
                orderBy: getOrderByExpression(sorting),
                page: activePage,
                pageSize: Number(resultsPerPage)
            },
            searchText: querySearchText
        }
    ]), pagedHealthChecks = _h.data, pagedHealthChecksIsFetching = _h.isFetching, pagedHealthChecksIsLoading = _h.isLoading, pagedHealthChecksRefetch = _h.refetch;
    var _j = useGetHealthChecksQuery([
        userType,
        tenantGuid,
        {
            queryParameters: {
                filter: "(Status == \"Draft\")",
                orderBy: "",
                page: 1,
                pageSize: 1
            }
        }
    ]), pagedNumHealthChecksInDraftState = _j.data, pagedNumHealthChecksInDraftStateIsFetching = _j.isFetching, pagedNumHealthChecksInDraftStateIsLoading = _j.isLoading, pagedNumHealthChecksInDraftRefetch = _j.refetch;
    // Refetch the health checks settings when the page is navigated away from and back to
    useEffect(function () {
        pagedNumHealthChecksInDraftRefetch();
        pagedHealthChecksRefetch();
    }, [pagedNumHealthChecksInDraftRefetch, pagedHealthChecksRefetch]);
    var totalHealthChecksCount = pagedHealthChecks === null || pagedHealthChecks === void 0 ? void 0 : pagedHealthChecks.TotalCount;
    var pageTotal = pagedHealthChecks === null || pagedHealthChecks === void 0 ? void 0 : pagedHealthChecks.PageTotal;
    var pageOptions = defaultResultOptions;
    var isAlertLoading = pagedNumHealthChecksInDraftStateIsLoading || pagedNumHealthChecksInDraftStateIsFetching;
    var isPageLoading = pagedHealthChecksIsLoading || pagedNumHealthChecksInDraftStateIsLoading || isUserCanEditLoading;
    var isTableLoading = pagedHealthChecksIsFetching || pagedNumHealthChecksInDraftStateIsFetching;
    var healthChecksInDraftStateCount = (_a = pagedNumHealthChecksInDraftState === null || pagedNumHealthChecksInDraftState === void 0 ? void 0 : pagedNumHealthChecksInDraftState.TotalCount) !== null && _a !== void 0 ? _a : 0;
    var showAlert = healthChecksInDraftStateCount > 0 && userCanEdit;
    return {
        pagedHealthChecks: pagedHealthChecks,
        pagedNumHealthChecksInDraftState: pagedNumHealthChecksInDraftState,
        totalHealthChecksCount: totalHealthChecksCount,
        pageTotal: pageTotal,
        pageOptions: pageOptions,
        isAlertLoading: isAlertLoading,
        isPageLoading: isPageLoading,
        isTableLoading: isTableLoading,
        healthChecksInDraftStateCount: healthChecksInDraftStateCount,
        showAlert: showAlert,
        sorting: sorting,
        setSorting: setSorting,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        highlightSearchWords: highlightSearchWords,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked
    };
};
