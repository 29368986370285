import * as React from "react";
import { Row, Col } from "react-bootstrap";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import * as TenantCurrentStore from "app/store/tenants/current";
import { useSelector, useDispatch } from "react-redux";
import { InstallWizardContext } from "../..";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import AnimatedLoader from "app/components/common/animated-loader";
import OrchestryService from "app/services/orchestry";
import PortletAlert from "app/components/common/portlet-alert";
var InstallerStepRegion = React.memo(function (props) {
    var dispatch = useDispatch();
    var iwc = React.useContext(InstallWizardContext);
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState(currentTenant.Region), startRegion = _a[0], setStartRegion = _a[1];
    var _b = React.useState(false), isUpdatingRegion = _b[0], setIsUpdatingRegion = _b[1];
    var _c = React.useState([]), activeRegions = _c[0], setActiveRegions = _c[1];
    React.useEffect(function () {
        iwc.setStepProgress(props.step.key, true);
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    React.useEffect(function () {
        if (startRegion !== currentTenant.Region) {
            setIsUpdatingRegion(true);
            iwc.setStepProgress(props.step.key, false);
            dispatch(TenantCurrentStore.UpdateSilent());
            AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Tenant_Current_Silent_Update").then(function () {
                setStartRegion(currentTenant.Region);
                setIsUpdatingRegion(false);
                iwc.setStepProgress(props.step.key, true);
                //console.log("metadata loaded");
            });
        }
    }, [currentTenant.Region]);
    return (React.createElement("div", { className: "installer-step-region fadein-500ms" },
        React.createElement(Row, null,
            React.createElement(Col, { style: { fontSize: "14px" } },
                React.createElement("h2", { className: "ob", style: { marginBottom: "25px" } }, "Which Orchestry region would you like to use?"),
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 12, md: 6, className: "dropdown-wrapper" },
                        currentTenant === undefined || activeRegions.length === 0 ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : currentTenant.LastInstalledVersion !== null ? (React.createElement(React.Fragment, null,
                            React.createElement(FormDropdownSelect, { list: activeRegions, disabled: true, defaultValue: currentTenant.Region, onChange: function (value) { return dispatch(TenantCurrentStore.Update({ Region: value })); } }),
                            React.createElement("div", { style: { marginTop: "15px" } },
                                React.createElement(PortletAlert, { type: "warning", size: "small", icon: "info", title: "You cannot change your region", message: "Your region was selected during a previous install and cannot be changed. Please contact Orchestry support to change your region." })))) : (React.createElement(FormDropdownSelect, { list: activeRegions, disabled: false, defaultValue: currentTenant.Region, onChange: function (value) { return dispatch(TenantCurrentStore.Update({ Region: value })); } })),
                        isUpdatingRegion === true ? (React.createElement("div", { className: "waiting-animation" },
                            React.createElement(AnimatedLoader, { size: "xxsmall" }))) : null)),
                React.createElement("p", { style: { marginTop: "20px" } }, "Consider the following when selecting a region:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "Select an Orchestry region closest to your Azure AD and Microsoft 365 data locations for lowest latency"),
                    React.createElement("li", null, "You can find your Azure AD location in the Azure Portal @ Azure AD > Properties > Location"),
                    React.createElement("li", null, "You can find your Microsoft 365 data location in the Office Admin Portal @ Settings > Org Settings > Organization Profile > Data Location"))))));
});
export default InstallerStepRegion;
