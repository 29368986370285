var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TenantCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {
        DisplayName: "",
        IsSubscribed: false,
        OwnerUserGuid: "",
        LifecycleEnabled: true,
        TenantInformation: {
            SignupMailingList: true
        }
    }
};
export function tenantCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TenantCurrentActionTypes.Tenant_Current_Load: {
            console.log(TenantCurrentActionTypes.Tenant_Current_Load, action);
            return {
                item: action.payload
            };
        }
        case TenantCurrentActionTypes.Tenant_Current_Load_With_Info: {
            console.log(TenantCurrentActionTypes.Tenant_Current_Load_With_Info, action);
            return {
                item: action.payload
            };
        }
        case TenantCurrentActionTypes.Tenant_Current_Update: {
            // let associatedItemsUpdated = false;
            var updatedTenant = Object.assign({}, state.item, action.payload);
            // if(state.item.AssociatedTemplates.length !== updatedTenant.AssociatedTemplates.length)
            // {
            //     console.log("Array Changed");
            //     associatedItemsUpdated = true;
            // }
            //console.log(TenantCurrentActionTypes.Tenant_Current_Update, updatedTenant)
            return {
                item: updatedTenant
            };
        }
        case TenantCurrentActionTypes.Tenant_Current_Silent_Update: {
            var updatedTenant = Object.assign({}, state.item, action.payload);
            return {
                item: updatedTenant
            };
        }
        case TenantCurrentActionTypes.Tenant_Current_Clear: {
            return {
                item: __assign({}, initialState.item)
            };
        }
        default:
            return state;
    }
}
