import { useGetMspTenantsQuery } from "appv2/api/partner-tools/partners.api";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
/**
 * Hook to get the tenant license type.
 */
export var useGetTenantLicense = function () {
    var _a = useHealthCheckRouteParams(), userType = _a.userType, tenantGuid = _a.tenantGuid;
    var _b = useGetMspTenantsQuery({
        queryParameters: {
            filter: "(TenantGuid == \"".concat(tenantGuid, "\")"),
            orderBy: "",
            page: 1,
            pageSize: 1
        }
    }, { skip: userType !== "partner" }), data = _b.data, isLoading = _b.isLoading;
    var tenantLicense = data === null || data === void 0 ? void 0 : data.Items[0].LicenseType;
    return { tenantLicense: tenantLicense, isLoading: isLoading };
};
