import * as React from "react";
import { useSelector } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import GroupItems from "app/components/common/group-items";
import { FormattedMessage, useIntl } from "react-intl";
var RequestDetailsTemplatePermissionsConfig = React.memo(function (props) {
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var intl = useIntl();
    return (React.createElement(Portlet, { fluidHeight: true },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.template_config.permission_configuration.title", defaultMessage: "Permissions Configuration" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.allow_all_users_to_request", defaultMessage: "Allow all users to request template" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.AllowAllUsersToRequestTemplate) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-9 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.limit_template_to_specific_ad", defaultMessage: "Limit template to specific Active Directory Groups" })),
                React.createElement("div", { className: "col-3" },
                    React.createElement("span", { className: "form-control-plaintext kt-font-bolder" }, (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.LimitTemplateToSpecificActiveDirectoryGroups) ? (React.createElement(FormattedMessage, { id: "general.label.true", defaultMessage: "True" })) : (React.createElement(FormattedMessage, { id: "general.label.false", defaultMessage: "False" }))))),
            React.createElement("div", { className: "form-group form-group-xs row" },
                React.createElement("label", { className: "col-12 col-form-label" },
                    React.createElement(FormattedMessage, { id: "page.request_details.template_config.template_active_directory", defaultMessage: "Template Active Directory Group(s)" })),
                React.createElement("div", { className: "col-12" },
                    React.createElement("span", { className: "form-control-plaintext" },
                        React.createElement(GroupItems, { groupsJsonString: currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateActiveDirectoryGroup }),
                        (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateActiveDirectoryGroup) === null || (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateActiveDirectoryGroup) === "[]" ? (React.createElement("div", { className: "no-results" },
                            "-- ",
                            React.createElement(FormattedMessage, { id: "page.request_details.template_config.no_group_selected", defaultMessage: "no groups selected" }),
                            " --")) : null))))));
});
export default RequestDetailsTemplatePermissionsConfig;
