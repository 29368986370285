var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import AnimatedLoader from "app/components/common/animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import FormToggleControl from "app/components/common/form-toggle-control";
import generateGuid from "app/utils/generateGuid";
import axios from "axios";
import * as React from "react";
import { Collapse, Form } from "react-bootstrap";
import PortletAlert from "../portlet-alert";
import "./form-webhook.scss";
var FormWebhook = React.memo(function (props) {
    var _a = React.useState("none"), testStatus = _a[0], setTestStatus = _a[1];
    var _b = React.useState(), testResponse = _b[0], settestResponse = _b[1];
    var _c = React.useState(), exampleJson = _c[0], setExampleJson = _c[1];
    var _d = React.useState(false), clientSecretCopied = _d[0], setClientSecretCopied = _d[1];
    var _e = React.useState(false), webhookExampleCopied = _e[0], setWebhookCopiedCopied = _e[1];
    React.useEffect(function () {
        var exampleJsonNew = __assign({}, props.exampleJson);
        if (props.webhookSecret !== null && props.webhookSecret !== "") {
            exampleJsonNew["Secret"] = props.webhookSecret;
        }
        setExampleJson(exampleJsonNew);
        // if(props.currentTemplate.WebhookSecret !== null && props.currentTemplate.WebhookSecret !== "") {
        //    setUseClientSecret(true);
        // }
    }, [props.webhookSecret]);
    function syntaxHighlight(json) {
        if (typeof json !== "string") {
            json = JSON.stringify(json, undefined, 2);
        }
        json = json
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
        json = json.replace(/(?:\r\n|\r|\n)/g, "<br>");
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = "number";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "key";
                }
                else {
                    cls = "string";
                }
            }
            else if (/true|false/.test(match)) {
                cls = "boolean";
            }
            else if (/null/.test(match)) {
                cls = "null";
            }
            return '<span class="' + cls + '">' + match + "</span>";
        });
    }
    function copyStringToClipboard(str) {
        // Create new element
        var el = document.createElement("textarea");
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute("readonly", "");
        //@ts-ignore
        el.style = { position: "absolute", left: "-9999px" };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand("copy");
        // Remove temporary element
        document.body.removeChild(el);
    }
    var copyExampleToClipboard = function () {
        copyStringToClipboard(JSON.stringify(exampleJson, undefined, 2));
        setWebhookCopiedCopied(true);
        setTimeout(function () {
            setWebhookCopiedCopied(false);
        }, 3000);
    };
    var copySecretToClipboard = function () {
        copyStringToClipboard(props.webhookSecret !== null ? props.webhookSecret : "");
        setClientSecretCopied(true);
        setTimeout(function () {
            setClientSecretCopied(false);
        }, 3000);
    };
    var pingWebhookUrl = function () {
        //props.currentTemplate.WebhookUrl
        setTestStatus("waiting");
        settestResponse(undefined);
        var axiosConfig = {};
        axiosConfig.method = "POST";
        axiosConfig.url = "/api/templates/TestWebhookUrl";
        axiosConfig.responseType = "json";
        var bodyFormData = new FormData();
        bodyFormData.set("data", JSON.stringify(exampleJson));
        bodyFormData.set("webhookUrl", props.webhookUrl);
        axiosConfig.data = bodyFormData;
        axios(axiosConfig)
            .then(function (response) {
            var responseData = response.data;
            if (responseData.urlWorked) {
                setTestStatus("success");
                settestResponse(responseData);
            }
            else {
                setTestStatus("failed");
                settestResponse(responseData);
            }
        })
            .catch(function (response) {
            setTestStatus("failed");
            var responseData = response.data;
            settestResponse(responseData);
        });
    };
    var toggleClientSecret = function (checked) {
        if (checked) {
            props.OnWebhookSecretChange(generateGuid());
            // dispatch(TemplateCurrentStore.Update({ WebhookSecret: generateGuid() }));
        }
        else {
            props.OnWebhookSecretChange("");
            //dispatch(TemplateCurrentStore.Update({ WebhookSecret: "" }));
        }
    };
    return (React.createElement("div", { className: "form-webhook" },
        React.createElement("div", { className: "webhook-area", "data-pw": "webhook-area" },
            React.createElement("div", { className: "sub-title" }, "Webhook Url"),
            React.createElement("div", { className: "url-input" },
                React.createElement(Form.Control, { autoComplete: "off", defaultValue: props.webhookUrl === null ? undefined : props.webhookUrl, onKeyUp: function () { return setTestStatus("none"); }, onChange: function (event) { return props.OnWebhookUrlChange(event.target.value); } }),
                React.createElement("button", { type: "button", onClick: function () { return pingWebhookUrl(); } },
                    testStatus === "none" ? (React.createElement("i", { className: "la la-flask" })) : testStatus === "waiting" ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : testStatus === "success" ? (React.createElement("i", { className: "la la-check" })) : (React.createElement("i", { className: "la la-times" })),
                    testStatus === "none" ? React.createElement("span", null, "Test Url") : testStatus === "waiting" ? React.createElement("span", null, "Test Url") : testStatus === "success" ? React.createElement("span", null, "Success") : React.createElement("span", null, "Failed"))),
            testStatus !== "none" && (testResponse === null || testResponse === void 0 ? void 0 : testResponse.urlWorked) === false ? (React.createElement("div", { className: "test-response" },
                React.createElement(PortletAlert, { title: "Test Result:", type: "danger", message: React.createElement("div", null,
                        React.createElement("p", null, testResponse === null || testResponse === void 0 ? void 0 : testResponse.statusCode),
                        React.createElement("p", null, testResponse === null || testResponse === void 0 ? void 0 : testResponse.code),
                        React.createElement("p", null, testResponse === null || testResponse === void 0 ? void 0 : testResponse.message)) }))) : null),
        React.createElement("div", { className: "client-secret-area", "data-pw": "client-secret-area" },
            React.createElement("div", { className: "sub-title" }, "Client Secret"),
            React.createElement("div", null,
                React.createElement(FormToggleControl, { checked: props.webhookSecret !== null && props.webhookSecret !== "" ? true : false, label: "Send a client secret with webhook request.", handleChange: function (checked) { return toggleClientSecret(checked); } })),
            React.createElement(Collapse, { in: props.webhookSecret !== null && props.webhookSecret !== "", appear: true },
                React.createElement("div", { className: "client-secret-enabled" },
                    React.createElement("p", null, "The client secret will be added to the JSON body and as a header named \"secret\""),
                    React.createElement("button", { type: "button", onClick: copySecretToClipboard, className: "clipboard-button" }, clientSecretCopied === false ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "far fa-clipboard" }),
                        " Copy to clipboard")) : (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "fa fa-check" }),
                        " Copied!"))),
                    React.createElement(Form.Control, { autoComplete: "off", disabled: true, value: props.webhookSecret === null ? undefined : props.webhookSecret })))),
        React.createElement("div", { className: "wh-example-wrapper", "data-pw": "wh-example" },
            React.createElement("div", { className: "sub-title" }, "Webhook Request Example"),
            exampleJson !== undefined && exampleJson !== null ? (React.createElement(React.Fragment, null,
                React.createElement("button", { type: "button", onClick: copyExampleToClipboard, className: "clipboard-button" }, webhookExampleCopied === false ? (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "far fa-clipboard" }),
                    " Copy to clipboard")) : (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fa fa-check" }),
                    " Copied!"))),
                React.createElement("pre", { className: "webhook-example" },
                    React.createElement(DynamicScrollbar, { maxHeight: 200, shouldShow: true },
                        React.createElement("code", { dangerouslySetInnerHTML: { __html: syntaxHighlight(exampleJson) } }))))) : null)));
});
export default FormWebhook;
