import { Overlay, Stack } from "@mantine/core";
import { InstallerMode } from "app/components/installer/installer-wizard";
import OrchestryCheckUpgrade from "app/components/orchestry-context/orchestry-check-upgrade";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import React from "react";
import { useIntl } from "react-intl";
import { OrchestryButton } from "../orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestryUnlockFullAccessOverlay = function (_a) {
    var showIcon = _a.showIcon, isLoading = _a.isLoading, blur = _a.blur;
    var intl = useIntl();
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var showButton = currentUserHasRole(OrchRole.BeaconAdmin);
    var _b = React.useState(false), showUpgradeDowngradeModal = _b[0], setShowUpgradeDowngradeModal = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(OrchestryCheckUpgrade, { installMode: InstallerMode.LicenseUpgradeOrchestryTrial, setOverrideShow: setShowUpgradeDowngradeModal, overrideShow: showUpgradeDowngradeModal }),
        React.createElement(Overlay, { h: "100%", color: "#FFF", backgroundOpacity: 0.35, blur: blur !== null && blur !== void 0 ? blur : 5 },
            React.createElement(Stack, { h: "100%", m: 0, p: 0, gap: 0, align: "center", justify: "center" },
                showIcon && React.createElement(OrchestryFeaturedIcon, { iconClassName: ORCH_ICONS.info, size: "lg", isLoading: isLoading, variant: "double-circle", colorVariant: "primary-9" }),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "16", fw: 500, lh: "24px", mt: showIcon ? 16 : 0 }, intl.formatMessage({ id: "page.health_check.your_potential_savings_tile.unlock_full_access", defaultMessage: "Unlock Full Access" })),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "14", fw: 400, lh: "20px", mt: 4 }, intl.formatMessage({ id: "page.health_check.your_potential_savings_tile.description", defaultMessage: "This section is available with the full Orchestry plan." })),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "14", fw: 500, lh: "20px" }, intl.formatMessage({ id: "page.health_check.your_potential_savings_tile.upgrade_message", defaultMessage: "Upgrade to gain full access." })),
                showButton && (React.createElement(OrchestryButton, { isLoading: isLoading, skeletonHeight: 40, skeletonWidth: 120, mt: 24, variant: "primary", size: "md", iconSize: "sm", iconType: "font", iconLeft: ORCH_ICONS.crown, onClick: function () { return setShowUpgradeDowngradeModal(true); } }, intl.formatMessage({ id: "page.health_check.your_potential_savings_tile.unlock_full_access", defaultMessage: "Unlock Full Access" })))))));
};
