// Matches enum in backend.
// See comments in Orchestry.Data\OrchestryDb\Entities\Enums\TenantInsight.cs
export var TenantInsight;
(function (TenantInsight) {
    TenantInsight["Trial"] = "Trial";
    TenantInsight["AtRisk"] = "AtRisk";
    TenantInsight["UpdateRequired"] = "UpdateRequired";
    TenantInsight["NoMetadata"] = "NoMetadata";
    TenantInsight["NoTemplates"] = "NoTemplates";
    TenantInsight["NoNamingConventions"] = "NoNamingConventions";
    TenantInsight["NoGuestRequests"] = "NoGuestRequests";
    TenantInsight["NeverProvisioning"] = "NeverProvisioning";
    TenantInsight["NoLifecycle"] = "NoLifecycle";
    TenantInsight["NeverArchived"] = "NeverArchived";
    TenantInsight["NoTeamInformationTab"] = "NoTeamInformationTab";
    TenantInsight["InactiveAdmins"] = "InactiveAdmins";
    TenantInsight["InactiveUsers"] = "InactiveUsers";
    TenantInsight["HighStorage"] = "HighStorage";
    TenantInsight["ManyRecommendations"] = "ManyRecommendations";
    TenantInsight["Disabled"] = "Disabled";
    TenantInsight["SelfLed"] = "SelfLed";
    TenantInsight["PartnerLed"] = "PartnerLed";
    TenantInsight["NoGuestReviews"] = "NoGuestReviews";
    TenantInsight["NewCustomer"] = "NewCustomer";
    TenantInsight["NoTeamsApp"] = "NoTeamsApp";
})(TenantInsight || (TenantInsight = {}));
