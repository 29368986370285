/**
 * Get the first two letters of a string and returns them in uppercase
 * @param theString The string to get the first two letters from
 */
var getStringTwoLetterAcronym = function (theString) {
    var _a;
    //TECH DEBT: use !!theString and write unit tests
    if (theString !== null && theString !== "" && theString !== undefined) {
        if (theString.split(/\s/).length === 1) {
            return theString.substr(0, 2).toUpperCase();
        }
        else {
            return (_a = theString
                .match(/\b(\w)/g)) === null || _a === void 0 ? void 0 : _a.join("").toUpperCase().substr(0, 2);
        }
    }
};
export default getStringTwoLetterAcronym;
