var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { Form, Col, Row } from "react-bootstrap";
import DocumentsModal from "app/components/common/documents-modal";
import * as TemplateCurrentStore from "app/store/templates/current";
import { useDispatch } from "react-redux";
import * as TableList from "app/components/common/table-list";
import generateGuid from "app/utils/generateGuid";
import FormToggleControl from "app/components/common/form-toggle-control";
var TabDocuments = React.memo(function (props, state) {
    var dispatch = useDispatch();
    //console.log("DocumentTemplates tab > currentTemplate", props.currentTemplate)
    var toggleRequired = function (documentTemplateMap, checked) {
        documentTemplateMap.Required = checked;
        // Change this to use List_Item_Update and do the true/false logic here.
        if (props.currentTemplate.AssociatedDocumentTemplates !== undefined) {
            dispatch(TemplateCurrentStore.Update({
                AssociatedDocumentTemplates: __spreadArray([], props.currentTemplate.AssociatedDocumentTemplates, true)
            }));
        }
    };
    var removeFeature = function (tbaToRemove) {
        var _a;
        dispatch(TemplateCurrentStore.Update({
            AssociatedDocumentTemplates: (_a = props.currentTemplate.AssociatedDocumentTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var addDocumentTemplates = function (docTemps) {
        //setAssociatedTemplates([...tempArray, ...currentFeature.AssociatedTemplates]);
        var tempArray = [];
        docTemps.forEach(function (dt) {
            var newTBA = {
                GUID: generateGuid(),
                Required: true,
                Item: {
                    GUID: dt.GUID,
                    Title: dt.Title,
                    Description: dt.Description,
                    Type: dt.Type,
                    Category: dt.Category
                }
            };
            tempArray.push(newTBA);
        });
        if (props.currentTemplate.AssociatedDocumentTemplates !== undefined) {
            if (props.currentTemplate.AssociatedDocumentTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), props.currentTemplate.AssociatedDocumentTemplates, true);
            }
        }
        var compare = function (a, b) {
            if (a.Item !== undefined && b.Item !== undefined) {
                if (a.Item.Title !== undefined && b.Item.Title !== undefined) {
                    if (a.Item.Title < b.Item.Title) {
                        return -1;
                    }
                    if (a.Item.Title > b.Item.Title) {
                        return 1;
                    }
                }
            }
            return 0;
        };
        tempArray.sort(compare);
        dispatch(TemplateCurrentStore.Update({ AssociatedDocumentTemplates: tempArray }));
    };
    return (
    // <Container fluid={true}>
    React.createElement(Row, null,
        React.createElement(Col, { "data-pw": "documents-tab-content" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 6 },
                    React.createElement("div", null,
                        React.createElement(Form.Group, { controlId: "AllowDocumentsCustomization", className: "form-group standalone-checkbox", style: { marginBottom: "5px" } },
                            React.createElement(FormToggleControl, { checked: props.currentTemplate.AllowDocumentsCustomization, label: "Allow users to customize document templates for this template", handleChange: function (checked) {
                                    return dispatch(TemplateCurrentStore.Update({
                                        AllowDocumentsCustomization: checked
                                    }));
                                } }))),
                    React.createElement("div", null,
                        React.createElement(Form.Group, { controlId: "RemoveBlankDocumentsUnderNew", className: "form-group standalone-checkbox" },
                            React.createElement(FormToggleControl, { checked: props.currentTemplate.RemoveBlankDocumentsUnderNew, label: "Overwrite existing Document Templates (This includes new office documents options)", handleChange: function (checked) {
                                    return dispatch(TemplateCurrentStore.Update({
                                        RemoveBlankDocumentsUnderNew: checked
                                    }));
                                } }))))),
            React.createElement(TableList.Context, { asyncListLoadUID: props.currentTemplate.GUID !== undefined ? props.currentTemplate.GUID : "", listItems: props.currentTemplate.AssociatedDocumentTemplates ? props.currentTemplate.AssociatedDocumentTemplates : [], showLoader: false, wrappedInPortlet: false },
                React.createElement(TableList.TableList, { tstyle: "border-bottom-on" },
                    React.createElement(TableList.HeaderItem, null, "Type"),
                    React.createElement(TableList.HeaderItem, null, "Title"),
                    React.createElement(TableList.HeaderItem, null, "Description"),
                    React.createElement(TableList.HeaderItem, null, "Category"),
                    React.createElement(TableList.HeaderItem, { type: "centered" }, "Required"),
                    React.createElement(TableList.HeaderItem, { type: "actions" }),
                    React.createElement(TableList.ContentItemDocumentFileType, { listDataProperyName: "Item.Type" }),
                    React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Item.Title", isMobileTitle: true }),
                    React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Item.Description" }),
                    React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Item.Category" }),
                    React.createElement(TableList.ContentItemToggle, { listDataProperyName: "Required", handleChange: function (item, checked) { return toggleRequired(item, checked); } }),
                    React.createElement(TableList.ContentItemActions
                    //   editNavLinkTo= "/workspaces/document-templates/edit/{Item.GUID}"
                    , { 
                        //   editNavLinkTo= "/workspaces/document-templates/edit/{Item.GUID}"
                        onRemove: function (item) { return removeFeature(item); } }))),
            React.createElement(DocumentsModal, { currentTemplate: props.currentTemplate, associatedDocumentTemplates: props.currentTemplate.AssociatedDocumentTemplates, handleSelected: function (docTemps) {
                    addDocumentTemplates(docTemps);
                } })))
    // </Container>
    );
});
export default TabDocuments;
