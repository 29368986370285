import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import React from "react";
import { useSelector } from "react-redux";
import "./team-information-pill.scss";
export var TeamInformationTemplatePill = function () {
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    if (teamInformation === null) {
        return React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 0, bodyWidth: 60, style: { marginRight: "10px" } });
    }
    var showTemplatePill = teamInformation.TemplateName !== null && teamInformation.TemplateName !== "";
    if (!showTemplatePill) {
        return null;
    }
    return (React.createElement("div", { className: "template team-information-pill" },
        React.createElement("div", { className: "nested-property" }, teamInformation.TemplateName)));
};
