var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./card-inactivity-settings.scss";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import "react-tagsinput/react-tagsinput.css";
import { PortletBody, Portlet, PortletHeader } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
var CardInactivitySettings = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    // const [currentTenantConfigInternalState, setCurrentTenantConfigInternalState] = React.useState({} as TenantConfiguration);
    var _a = React.useState(false), tenantConfigLoaded = _a[0], setTenantConfigLoaded = _a[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(function () {
            //console.log("tenant config loaded");
            setTenantConfigLoaded(true);
        });
    }, []);
    var updateCount = function (days) {
        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), { LifecycleDaysInactivity: days })));
    };
    return (React.createElement("div", { className: "card-inactivity-settings fadein-500ms", "data-pw": "inactivity-reporting" }, tenantConfigLoaded === true ? (React.createElement(React.Fragment, null, currentTenantConfiguration.GUID !== "" ? (React.createElement(Portlet, { className: "side-by-side-portlets" },
        React.createElement(PortletHeader, { title: "Lifecycle Inactivity Reporting" }),
        React.createElement(PortletBody, null,
            React.createElement(Form.Group, { className: "form-group", "data-pw": "form-group" },
                React.createElement(Form.Label, null, "Number of days for a workspace to show as inactive"),
                React.createElement(Form.Control, { type: "number", placeholder: "Number of..", value: currentTenantConfiguration.LifecycleDaysInactivity != null ? currentTenantConfiguration.LifecycleDaysInactivity.toString() : "90", onChange: function (event) { return updateCount(Number(event.target.value)); } }))))) : null)) : (React.createElement(SkeletonLoaderPortlet, { bodyHeight: 250 },
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 })))));
});
export default CardInactivitySettings;
