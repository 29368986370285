var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useForm } from "@mantine/form";
import { useGetRecommendationCategoriesQuery, useGetRecommendationProductsQuery, useGetRecommendationsQuery, useUpdateRecommendationMutation } from "appv2/api/recommendations/recommendations.api";
import { getFilterGroups, groupFilters } from "appv2/components/orchestry-filter-selector/orchestry-filter-selector.utils";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useEffect, useMemo, useState } from "react";
import { ADD_RECOMMENDATIONS_FILTERS_LIST } from "../../../utils/recommendations-filter.utils";
//To Consider: If this and the other hook keep growing with common changes, consider refactoring them.
/**
 * Helper hook for the Update Custom Recommendation Modal.
 *
 * @param context The modals context
 * @param id The id of the modal
 * @param onRecommendationSuccessfullyUpdate Callback function that is called when a recommendation is successfully updated.
 * @param uniqueId The unique id of the recommendation record to update
 * @param tenantGuid The tenant guid
 */
export var useUpdateCustomRecommendationModal = function (context, id, onRecommendationSuccessfullyUpdated, uniqueId, tenantGuid) {
    var _a;
    var recommendationCategories = useGetRecommendationCategoriesQuery().data;
    var recommendationProducts = useGetRecommendationProductsQuery().data;
    var _b = useGetRecommendationsQuery({
        queryParameters: {
            filter: "UniqueID == \"".concat(uniqueId, "\""),
            page: 1,
            pageSize: 1
        }
    }), recommendation = _b.data, isFetching = _b.isFetching, pagedRecommendationsIsLoading = _b.isLoading;
    var recommendationItem = (_a = recommendation === null || recommendation === void 0 ? void 0 : recommendation.Items) === null || _a === void 0 ? void 0 : _a[0];
    useEffect(function () {
        if (recommendationItem) {
            var recommendationValues_1 = {
                UniqueId: uniqueId,
                DisplayName: recommendationItem.RecommendationRecord.DisplayName,
                Description: recommendationItem.RecommendationRecord.Description,
                RecommendationAction: recommendationItem.RecommendationRecord.RecommendationAction,
                Priority: recommendationItem.RecommendationRecord.Definition_Priority,
                Categories: recommendationItem.RecommendationRecord.Definition_Categories,
                Products: recommendationItem.RecommendationRecord.Definition_Products
            };
            form.setValues(recommendationValues_1);
            form.setInitialValues(recommendationValues_1);
            // Set applied filters based on the recommendation values
            var categories = recommendationValues_1.Categories.split("|");
            var products = recommendationValues_1.Products.split("|");
            var categoryValues_1 = categories.map(function (category) { var _a; return (_a = recommendationCategories === null || recommendationCategories === void 0 ? void 0 : recommendationCategories.find(function (cat) { return cat.Guid === category; })) === null || _a === void 0 ? void 0 : _a.Name; });
            var productValues_1 = products.map(function (product) { var _a; return (_a = recommendationProducts === null || recommendationProducts === void 0 ? void 0 : recommendationProducts.find(function (prod) { return prod.Guid === product; })) === null || _a === void 0 ? void 0 : _a.Name; });
            var filters = appliedFilters.map(function (filter) {
                var _a, _b;
                if (filter.group === "Priority") {
                    return __assign(__assign({}, filter), { selected: ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = recommendationValues_1.Priority) === null || _b === void 0 ? void 0 : _b.toLowerCase()) });
                }
                else if (filter.group === "Category") {
                    return __assign(__assign({}, filter), { selected: categoryValues_1.some(function (categoryValue) { var _a; return (categoryValue === null || categoryValue === void 0 ? void 0 : categoryValue.toLowerCase()) === ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }) });
                }
                else if (filter.group === "Product") {
                    return __assign(__assign({}, filter), { selected: productValues_1.some(function (productValue) { var _a; return (productValue === null || productValue === void 0 ? void 0 : productValue.toLowerCase()) === ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }) });
                }
                return filter;
            });
            setAppliedFilters(filters);
        }
        // form is not a dependency because it is updated in the useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendationItem]);
    var _c = useState(ADD_RECOMMENDATIONS_FILTERS_LIST), appliedFilters = _c[0], setAppliedFilters = _c[1];
    var filterGroups = getFilterGroups(appliedFilters);
    var updateFilterState = function (value) {
        var _a;
        var updatedFilters = appliedFilters.map(function (filter) {
            if (filter.value === value) {
                return __assign(__assign({}, filter), { selected: !filter.selected });
            }
            return filter;
        });
        // Set selected to false for all other priority filters
        appliedFilters.forEach(function (filter) {
            if (filter.value === value && filter.group === "Priority") {
                filter.selected = true;
                appliedFilters.forEach(function (otherFilter) {
                    if (otherFilter.group === "Priority" && otherFilter.value !== value) {
                        otherFilter.selected = false;
                    }
                });
            }
        });
        setAppliedFilters(updatedFilters);
        // Update form values by finding Guids for Categories and Products and joining them with a pipe
        var appliedFiltersGrouped = groupFilters(updatedFilters);
        var PriorityFilters = appliedFiltersGrouped.Priority;
        var priority = (_a = PriorityFilters.find(function (filter) { return filter.selected; })) === null || _a === void 0 ? void 0 : _a.value;
        form.setFieldValue("Priority", priority);
        var CategoriesFilters = appliedFiltersGrouped.Category;
        var categoryGuids = CategoriesFilters.map(function (filter) { var _a; return (_a = recommendationCategories === null || recommendationCategories === void 0 ? void 0 : recommendationCategories.find(function (category) { var _a; return filter.selected && category.Name.toLowerCase() === ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })) === null || _a === void 0 ? void 0 : _a.Guid; })
            .filter(Boolean) // Remove undefined values
            .join("|");
        form.setFieldValue("Categories", categoryGuids);
        var ProductsFilters = appliedFiltersGrouped.Product;
        var productGuids = ProductsFilters.map(function (filter) { var _a; return (_a = recommendationProducts === null || recommendationProducts === void 0 ? void 0 : recommendationProducts.find(function (product) { var _a; return filter.selected && product.Name.toLowerCase() === ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })) === null || _a === void 0 ? void 0 : _a.Guid; })
            .filter(Boolean) // Remove undefined values
            .join("|");
        form.setFieldValue("Products", productGuids);
    };
    var _d = useUpdateRecommendationMutation(), updateRecommendation = _d[0], _e = _d[1], isLoading = _e.isLoading, isSuccess = _e.isSuccess;
    var form = useForm({
        initialValues: {
            UniqueId: uniqueId,
            DisplayName: "",
            Description: "",
            RecommendationAction: "",
            Priority: undefined,
            Categories: "",
            Products: "",
            TenantGuid: tenantGuid
        },
        validate: {
            DisplayName: function (DisplayName) { return (DisplayName ? null : "Name is required for recommendations."); },
            Description: function (Description) { return (Description ? null : "Description is required for recommendations."); },
            RecommendationAction: function (RecommendationAction) { return (RecommendationAction ? null : "Recommended Action is required for recommendations."); },
            Priority: function (Priority) { return (Priority ? null : "Priority is required for recommendations."); },
            Categories: function (Categories) { return (Categories ? null : "Categories are required for recommendations."); },
            Products: function (Products) { return (Products ? null : "Products are required for recommendations."); }
        }
    });
    var handleFormSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateRecommendation({ userType: tenantGuid ? "partner" : "customer", updateRecommendationRecordCommand: values })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var displayName = form.getValues().DisplayName;
    var resetFilters = function () {
        setAppliedFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
    };
    useEffect(function () {
        var handleSuccess = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isSuccess) return [3 /*break*/, 2];
                        resetFilters();
                        context.closeModal(id);
                        return [4 /*yield*/, onRecommendationSuccessfullyUpdated()];
                    case 1:
                        _a.sent();
                        OrchestrySuccessToast("Recommendation ".concat(displayName, " updated successfully."));
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        handleSuccess();
    }, [isSuccess, context, id, displayName, onRecommendationSuccessfullyUpdated]);
    var priorityFormError = form.getInputProps("Priority").error;
    var categoryFormError = form.getInputProps("Categories").error;
    var productFormError = form.getInputProps("Products").error;
    var filterGroupErrors = useMemo(function () { return ({
        Priority: priorityFormError,
        Category: categoryFormError,
        Product: productFormError
    }); }, [priorityFormError, categoryFormError, productFormError]);
    var isPageLoading = !recommendationItem || pagedRecommendationsIsLoading || isLoading || isFetching || !form.values;
    return {
        form: form,
        appliedFilters: appliedFilters,
        filterGroups: filterGroups,
        updateFilterState: updateFilterState,
        handleFormSubmit: handleFormSubmit,
        isLoading: isPageLoading,
        filterGroupErrors: filterGroupErrors
    };
};
