import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFilterItem } from "appv2/components/orchestry-filter-selector/orchestry-filter-items/orchestry-filter-item.component";
import React from "react";
import { useIntl } from "react-intl";
export var HealthCheckEditPill = function (_a) {
    var isLoading = _a.isLoading;
    var intl = useIntl();
    return (React.createElement(OrchestryFilterItem, { isLoading: isLoading, filterProps: {
            type: "selectable",
            title: intl.formatMessage({ id: "health_checks.status.editing", defaultMessage: "Editing" }),
            value: "Editing",
            icon: ORCH_ICONS.edit03,
            color: "var(--mantine-color-purple-8)",
            isStatic: true,
            selected: true
        } }));
};
