import * as React from "react";
import "./step-card-notification-format.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import FormSelectBox from "app/components/common/form-select-box";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
var StepCardNotificationFormat = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        props.OnProceedChange(true);
    }, []);
    return (React.createElement("div", { className: "step-card-notification-format policy-card", "data-pw": "step-card-notification" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("div", { className: "middle card-border" },
                    React.createElement("h4", null, "Notification Format"),
                    React.createElement("p", { className: "text" }, "Choose the format that you wish to send notifications by. You can choose multiple formats and Orchestry will ensure they are all kept in sync"),
                    React.createElement("div", { className: "notification-select" },
                        React.createElement(FormSelectBox, { style: "check-list", allowMultiple: true, list: [
                                {
                                    value: "email",
                                    title: "Email",
                                    icon: "la la-envelope-open-text"
                                },
                                {
                                    value: "teams-alert",
                                    title: "Teams Notification",
                                    icon: "la la-bell"
                                }
                                // {
                                //    value: "team-information-tab",
                                //    title: "Team Information Tab",
                                //    icon: "la la-poll-h"
                                // },
                                // {
                                //    value: "bot",
                                //    title: "Bot",
                                //    icon: "la la-robot"
                                // },
                                // {
                                //    value: "web-part",
                                //    title: "Web Part",
                                //    icon: "la la-digital-tachograph"
                                // }
                            ], value: currentPolicy.Notification_Formats, onChange: function (value) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    Notification_Formats: value
                                }));
                            } })))))));
});
export default StepCardNotificationFormat;
