var ORCH_ICONS = {
    "activity": "orchicon-activity",
    "alertCircle": "orchicon-alert-circle",
    "alertOctagon": "orchicon-alert-octagon",
    "alertTriangle": "orchicon-alert-triangle",
    "archive": "orchicon-archive",
    "arrowCircleBrokenUp": "orchicon-arrow-circle-broken-up",
    "arrowDown": "orchicon-arrow-down",
    "arrowRight": "orchicon-arrow-right",
    "arrowUp": "orchicon-arrow-up",
    "atSign": "orchicon-at-sign",
    "award03Light": "orchicon-award-03-light",
    "award03": "orchicon-award-03",
    "award05": "orchicon-award-05",
    "barChart07": "orchicon-bar-chart-07",
    "bookmark": "orchicon-bookmark",
    "box": "orchicon-box",
    "building06": "orchicon-building-06",
    "calendarAlert": "orchicon-calendar-alert",
    "calendarCheck02": "orchicon-calendar-check-02",
    "calendarHeart01": "orchicon-calendar-heart-01",
    "calendar": "orchicon-calendar",
    "checkCircleBroken": "orchicon-check-circle-broken",
    "checkCircle": "orchicon-check-circle",
    "checkDone02": "orchicon-check-done-02",
    "checkHeart": "orchicon-check-heart",
    "checkSquare": "orchicon-check-square",
    "checkVerified01": "orchicon-check-verified-01",
    "checkVerified02": "orchicon-check-verified-02",
    "checkVerified03": "orchicon-check-verified-03",
    "check": "orchicon-check",
    "chevronDown": "orchicon-chevron-down",
    "chevronLeftPagination": "orchicon-chevron-left-pagination",
    "chevronLeft": "orchicon-chevron-left",
    "chevronRightPagination": "orchicon-chevron-right-pagination",
    "chevronUp": "orchicon-chevron-up",
    "chevronsUpDown": "orchicon-chevrons-up-down",
    "clockSnooze": "orchicon-clock-snooze",
    "cloudOff": "orchicon-cloud-off",
    "codeBrowser": "orchicon-code-browser",
    "codesandbox": "orchicon-codesandbox",
    "confetti": "orchicon-confetti",
    "copy05": "orchicon-copy-05",
    "crown": "orchicon-crown",
    "currencyDollar": "orchicon-currency-dollar",
    "data": "orchicon-data",
    "database01": "orchicon-database-01",
    "database03": "orchicon-database-03",
    "database": "orchicon-database",
    "download02": "orchicon-download-02",
    "edit02": "orchicon-edit-02",
    "edit03": "orchicon-edit-03",
    "externalLink": "orchicon-external-link",
    "eyeOff": "orchicon-eye-off",
    "eye": "orchicon-eye",
    "fileAttachment04": "orchicon-file-attachment-04",
    "fileCheck02": "orchicon-file-check-02",
    "fileHeart02": "orchicon-file-heart-02",
    "filterFunnel02": "orchicon-filter-funnel-02",
    "flipBackward": "orchicon-flip-backward",
    "folderOpen": "orchicon-folder-open",
    "folder": "orchicon-folder",
    "gitBranch01": "orchicon-git-branch-01",
    "heartHexagon": "orchicon-heart-hexagon",
    "hearts": "orchicon-hearts",
    "helpCircle": "orchicon-help-circle",
    "helpHexagon": "orchicon-help-hexagon",
    "hourglass03": "orchicon-hourglass-03",
    "Icon": "orchicon-Icon",
    "infoCircle": "orchicon-info-circle",
    "infoSquare": "orchicon-info-square",
    "info": "orchicon-info",
    "key02": "orchicon-key-02",
    "layout": "orchicon-layout",
    "lifeBuoy01": "orchicon-life-buoy-01",
    "lightbulb05": "orchicon-lightbulb-05",
    "lightning01": "orchicon-lightning-01",
    "lightning02": "orchicon-lightning-02",
    "lineChartUp01": "orchicon-line-chart-up-01",
    "link2Off": "orchicon-link-2-off",
    "link": "orchicon-link",
    "listChecks": "orchicon-list-checks",
    "list": "orchicon-list",
    "loading02": "orchicon-loading-02",
    "lock01": "orchicon-lock-01",
    "Logomark": "orchicon-Logomark",
    "messageChatCircle": "orchicon-message-chat-circle",
    "messageChatSquare": "orchicon-message-chat-square",
    "messageCircle01": "orchicon-message-circle-01",
    "messageNotificationCircle": "orchicon-message-notification-circle",
    "messagePlusCircle": "orchicon-message-plus-circle",
    "messageSquare02": "orchicon-message-square-02",
    "messageTextCircle01": "orchicon-message-text-circle-01",
    "moreHorizontal": "orchicon-more-horizontal",
    "notificationBox": "orchicon-notification-box",
    "packagePlus": "orchicon-package-plus",
    "pencilLine": "orchicon-pencil-line",
    "percent": "orchicon-percent",
    "perspective02": "orchicon-perspective-02",
    "playSquare": "orchicon-play-square",
    "plusCircle": "orchicon-plus-circle",
    "plus": "orchicon-plus",
    "refreshCw04": "orchicon-refresh-cw-04",
    "repeat04": "orchicon-repeat-04",
    "reviewProcess": "orchicon-review-process",
    "rocket02": "orchicon-rocket-02",
    "save02": "orchicon-save-02",
    "scales01": "orchicon-scales-01",
    "scan": "orchicon-scan",
    "searchMd": "orchicon-search-md",
    "search": "orchicon-search",
    "send01": "orchicon-send-01",
    "settings04": "orchicon-settings-04",
    "settings2": "orchicon-settings-2",
    "settings": "orchicon-settings",
    "share06": "orchicon-share-06",
    "shieldCheck": "orchicon-shield-check",
    "sortDesc": "orchicon-sort-desc",
    "sprawl": "orchicon-sprawl",
    "star01": "orchicon-star-01",
    "star02": "orchicon-star-02",
    "star05": "orchicon-star-05",
    "table": "orchicon-table",
    "tag01": "orchicon-tag-01",
    "tag03": "orchicon-tag-03",
    "thumbsDown": "orchicon-thumbs-down",
    "thumbsUp": "orchicon-thumbs-up",
    "Tilde": "orchicon-Tilde",
    "tool01": "orchicon-tool-01",
    "tool02": "orchicon-tool-02",
    "trash01": "orchicon-trash-01",
    "trash02": "orchicon-trash-02",
    "trophy01": "orchicon-trophy-01",
    "underline01": "orchicon-underline-01",
    "unlink": "orchicon-unlink",
    "upload02": "orchicon-upload-02",
    "upload03": "orchicon-upload-03",
    "uploadCloud": "orchicon-upload-cloud",
    "user03": "orchicon-user-03",
    "userCheck01": "orchicon-user-check-01",
    "userLeft01": "orchicon-user-left-01",
    "userPlus01": "orchicon-user-plus-01",
    "users01": "orchicon-users-01",
    "usersCheck": "orchicon-users-check",
    "usersEdit": "orchicon-users-edit",
    "usersPlus": "orchicon-users-plus",
    "xCircle": "orchicon-x-circle",
    "xSquare": "orchicon-x-square",
    "x": "orchicon-x",
    "zap": "orchicon-zap",
};
export default ORCH_ICONS;
