import * as React from "react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { useSlate } from "slate-react";
import { isMarkActive, toggleMark } from "../helpers";
import "./toolbar.scss";
//button components and toggle logic
export var StylingButton = function (_a) {
    var _b;
    var format = _a.format, icon = _a.icon, style = _a.style;
    var editor = useSlate();
    (_b = document.getElementById("editable")) === null || _b === void 0 ? void 0 : _b.focus();
    return React.createElement(FontIcon, { style: style, title: icon, iconName: icon, className: "".concat(isMarkActive(editor, format) ? "active" : ""), onClick: function () { return toggleMark(editor, format); } });
};
