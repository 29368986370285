import { Group, Stack, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import React from "react";
import { useDisconnectTenantModal } from "./use-disconnect-tenant-modal.hook";
export var DisconnectTenantModal = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, userType = _b.userType, customerTenantGuid = _b.customerTenantGuid, customerTenantName = _b.customerTenantName, partnerHubTenantGuid = _b.partnerHubTenantGuid, partnerHubTenantName = _b.partnerHubTenantName, onDisconnectTenantClicked = _b.onDisconnectTenantClicked;
    var _c = useDisconnectTenantModal({
        userType: userType,
        customerTenantGuid: customerTenantGuid,
        customerTenantName: customerTenantName,
        partnerHubTenantGuid: partnerHubTenantGuid,
        partnerHubTenantName: partnerHubTenantName,
        onDisconnectTenantClicked: onDisconnectTenantClicked
    }), isSuccess = _c.isSuccess, isLoading = _c.isLoading, onDisconnectClicked = _c.onDisconnectClicked;
    React.useEffect(function () {
        if (isSuccess) {
            context.closeModal(id);
            OrchestrySuccessToast("Tenant ".concat(customerTenantName, " disconnected from partner hub tenant ").concat(partnerHubTenantName, " successfully."));
        }
    }, [isSuccess, context, id, customerTenantName, partnerHubTenantName]);
    return (React.createElement(Stack, { gap: 24, ff: "var(--mantine-font-family)" },
        React.createElement(Group, { gap: 16 },
            React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", size: "lg", colorVariant: "error-7", iconClassName: ORCH_ICONS.trash01 }),
            React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, "Disconnect Tenants")),
        React.createElement(Text, { c: "text-secondary.0", fz: "14px", fw: 400, lh: "20px" },
            "Are you sure you want to disconnect customer tenant ",
            customerTenantName,
            " from the partner hub tenant ",
            partnerHubTenantName,
            "?"),
        React.createElement(Group, { gap: 12, justify: "right" },
            React.createElement(OrchestryButton, { disabled: isLoading, variant: "white", size: "sm", onClick: function () { return context.closeModal(id); } }, "Cancel"),
            React.createElement(OrchestryButton, { disabled: isLoading, variant: "danger", size: "sm", onClick: function () { return onDisconnectClicked(); } }, "Disconnect"))));
};
