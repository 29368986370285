import { Card, Group, Stack, Text } from "@mantine/core";
import { convertUtcDatetimeToLocal, getReadableDate } from "app/utils/dateUtils";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import { DisconnectTenantButton } from "appv2/components/orchestry-disconnect-tenant-button/orchestry-disconnect-tenant-button.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryUserIdentity } from "appv2/components/orchestry-user-identity/orchestry-user-identity.component";
import React from "react";
export var PartnersSettingsConsentedControl = function (_a) {
    var consentedBy = _a.consentedBy, consentedDate = _a.consentedDate, isLoading = _a.isLoading, consentorPhoto = _a.consentorPhoto, onDisconnectTenantClicked = _a.onDisconnectTenantClicked, customerTenantName = _a.customerTenantName, partnerHubTenantName = _a.partnerHubTenantName;
    return (React.createElement(Stack, { gap: 8 },
        React.createElement(OrchestryText, { isLoading: isLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, "Consented by:"),
        React.createElement(Card, { p: 16, withBorder: true, radius: 8, style: { border: "1px solid var(--border)" } },
            React.createElement(Group, { justify: "space-between" },
                React.createElement(Group, { gap: 8 },
                    React.createElement(OrchestryUserIdentity, { userPhoto: consentorPhoto, displayName: consentedBy }),
                    React.createElement(OrchestryBadge, { color: "var(--mantine-color-primary-9)" },
                        React.createElement(Text, { fz: "12px", fw: 400, lh: "18px" }, getReadableDate(convertUtcDatetimeToLocal(consentedDate), "en")))),
                React.createElement(DisconnectTenantButton, { userType: "customer", customerTenantName: customerTenantName, partnerHubTenantName: partnerHubTenantName, onDisconnectTenantClicked: onDisconnectTenantClicked })))));
};
