import * as React from "react";
import "./document-icon.scss";
import IconXlsx from "app/assets/img/office-icons/xls.png";
import IconDocx from "app/assets/img/office-icons/docx.png";
import IconPptx from "app/assets/img/office-icons/pptx.png";
var DocumentIcon = React.memo(function (props) {
    //const tlc = React.useContext(TabletListContext) as ITableListContext;
    var documentIcons = [
        {
            types: "xlsx|xls",
            path: IconXlsx,
            mediaType: "image",
            docName: "Excel"
        },
        {
            types: "docx|doc|txt",
            path: IconDocx,
            mediaType: "image",
            docName: "Word"
        },
        {
            types: "pptx|ppt",
            path: IconPptx,
            mediaType: "image",
            docName: "PowerPoint"
        },
        {
            types: "pdf",
            path: "/media/files/pdf.svg",
            mediaType: "image",
            docName: "PDF"
        },
        {
            types: "default",
            path: "fa fa-file",
            mediaType: "icon",
            docName: "File"
        },
        {
            types: "html",
            path: "/media/files/html.svg",
            mediaType: "image",
            docName: "Html"
        },
        {
            types: "js",
            path: "/media/files/javascript.svg",
            mediaType: "image",
            docName: "JavaScript"
        },
        {
            types: "jpg",
            path: "/media/files/jpg.svg",
            mediaType: "image",
            docName: "JPEG"
        },
        {
            types: "xml",
            path: "/media/files/xml.svg",
            mediaType: "image",
            docName: "XML"
        },
        {
            types: "zip",
            path: "/media/files/zip.svg",
            mediaType: "image",
            docName: "Zip"
        },
        {
            types: "mp4",
            path: "/media/files/mp4.svg",
            mediaType: "image",
            docName: "MP4"
        }
    ];
    var getIconImg = function () {
        var foundPath;
        var extractedExt = "";
        if (props.docName !== undefined) {
            extractedExt = props.docName.substr(props.docName.lastIndexOf(".") + 1);
            //console.log("Icon Extract Extension", extractedExt, props.docName)
        }
        var label = React.createElement("span", null);
        var foundMatch = false;
        documentIcons.forEach(function (icon) {
            var exts = icon.types.split("|");
            exts.forEach(function (ext) {
                if (ext === props.docType || ext === extractedExt) {
                    foundMatch = true;
                    if (props.appendDocumentType !== undefined && props.appendDocumentType === true) {
                        label = React.createElement("span", null, icon.docName);
                    }
                    if (icon.mediaType === "image") {
                        foundPath = (React.createElement("div", null,
                            React.createElement("div", { className: "doc-icon type-image" + (props.size !== undefined ? " size-" + props.size : " size-22") },
                                React.createElement("img", { src: icon.path, alt: props.docType + " " + props.docName })),
                            label));
                    }
                    else {
                        foundPath = (React.createElement("div", null,
                            React.createElement("div", { className: "doc-icon type-icon" + (props.size !== undefined ? " size-" + props.size : " size-22") },
                                React.createElement("i", { className: icon.path })),
                            label));
                    }
                }
            });
        });
        if (foundMatch === false) {
            var item = documentIcons.find(function (di) { return di.types === "default"; });
            if (item !== undefined) {
                if (props.appendDocumentType !== undefined && props.appendDocumentType === true) {
                    label = (React.createElement("span", null,
                        extractedExt.toUpperCase(),
                        " ",
                        item.docName));
                }
                foundPath = (React.createElement("div", null,
                    React.createElement("div", { className: "doc-icon type-icon" },
                        React.createElement("i", { className: item.path })),
                    label));
            }
        }
        return foundPath;
    };
    return React.createElement(React.Fragment, null, getIconImg());
});
export default DocumentIcon;
