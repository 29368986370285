/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as EqualizerIcon } from "_metronic/layout/assets/layout-svg-icons/Equalizer.svg";
import { ReactComponent as Commode } from "_metronic/layout/assets/layout-svg-icons/Commode2.svg";
import { ReactComponent as MailAttachmentIcon } from "_metronic/layout/assets/layout-svg-icons/Mail-attachment.svg";
import { ReactComponent as ThunderMove } from "_metronic/layout/assets/layout-svg-icons/Thunder-move.svg";
import { ReactComponent as Sending } from "_metronic/layout/assets/layout-svg-icons/Sending.svg";
import { NavLink, useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class QuickActionsPanel extends React.Component {
   render() {
      const { icon, useSVG, bgImage, iconType, gridNavSkin } = this.props;
      return (
         <Dropdown className="kt-header__topbar-item" drop="down" alignRight id="queues-dropdown">
            <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-quick-actions-panel-toggle">
               <OverlayTrigger
                  placement="bottom"
                  overlay={
                     <Tooltip id="quick-panel-tooltip" style={{ marginTop: "-9px" }}>
                        Queues
                     </Tooltip>
                  }>
                  <span
                     className={clsx(`kt-header__topbar-icon`, {
                        "kt-header__topbar-icon--warning": iconType === "warning"
                     })}>
                     {!useSVG && <i className={icon} />}

                     {useSVG && (
                        <span
                           className={clsx("kt-svg-icon", {
                              "kt-svg-icon--warning": iconType === "warning"
                           })}>
                           <ThunderMove name="Queues" className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--md" />
                        </span>
                     )}
                  </span>
               </OverlayTrigger>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
               <form className="flex-column p-0">
                  {/* begin: Head */}
                  <div className={`kt-head kt-head--skin-${{ gridNavSkin }}`} style={{ backgroundImage: `url(${bgImage})` }}>
                     <h3 className="kt-head__title text-white">
                        Orchestry Queues
                        {/* <span className="kt-space-15" />
                <button
                  type="button"
                  className="btn btn-success btn-sm btn-bold btn-font-md"
                >
                  23 tasks pending
                </button> */}
                     </h3>
                  </div>
                  {/* end: Head */}

                  <div className={`kt-grid-nav kt-grid-nav--skin-${gridNavSkin}`}>
                     <div className="kt-grid-nav__row">
                        {/* <a className="kt-grid-nav__item"> */}
                        <NavLink className="kt-grid-nav__item" exact to="/requests/queue">
                           <Sending className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg" />
                           <span className="kt-grid-nav__title">Requests</span>
                           <span className="kt-grid-nav__desc">Queue</span>
                        </NavLink>

                        {/* <a className="kt-grid-nav__item"> */}
                        <NavLink exact to="/lifecycle/queue" className="kt-grid-nav__item">
                           <div className="d-flex align-items-center flex-column">
                              <span className="kt-grid-nav__icon">
                                 <Commode className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg" />
                              </span>
                              <span className="kt-grid-nav__title">Archivals</span>
                              <span className="kt-grid-nav__desc">Queue</span>
                           </div>
                        </NavLink>
                        {/* </a> */}
                        {/* </div> */}

                        {/* <div className="kt-grid-nav__row">
                <a className="kt-grid-nav__item">
                  <div className="d-flex align-items-center flex-column">
                    <span className="kt-grid-nav__icon">
                      <BoxNum2Icon className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg" />
                    </span>
                    <span className="kt-grid-nav__title">Projects</span>
                    <span className="kt-grid-nav__desc">Pending Tasks</span>
                  </div>
                </a>

                <a className="kt-grid-nav__item">
                  <div className="d-flex align-items-center flex-column">
                    <span className="kt-grid-nav__icon">
                      <GroupIcon className="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg" />
                    </span>
                    <span className="kt-grid-nav__title">Customers</span>
                    <span className="kt-grid-nav__desc">Latest cases</span>
                  </div>
                </a> */}
                     </div>
                  </div>
               </form>
            </Dropdown.Menu>
         </Dropdown>
      );
   }
}
