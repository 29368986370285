var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PermIconPublic from "app/assets/img/permissions-icon-org.svg";
import PermIconPrivate from "app/assets/img/permissions-icon-private.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import FormToggle from "app/components/common/form-toggle";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SHAREPOINT_MULTIGEO_CODES } from "../../../3_details/sharepoint-geocodes";
var RequestWizardPermissionsPrivacy = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var rwc = React.useContext(RequestWizardContext);
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var _h = React.useState(false), sensitivityLabelsEnabled = _h[0], setSensitivityLabelsEnabled = _h[1];
    var _j = React.useState(), sensitivityPermissionOverride = _j[0], setSensitivityPermissionOverride = _j[1];
    var _k = React.useState(false), classificationLabelsEnabled = _k[0], setClassificationLabelsEnabled = _k[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _l = React.useState(false), shouldExcludeGuests = _l[0], setShouldExcludeGuests = _l[1];
    var _m = React.useState(false), shouldExcludeGuestsPrevious = _m[0], setShouldExcludeGuestsPrevious = _m[1];
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    React.useEffect(function () {
        props.SetShouldExcludeGuests(shouldExcludeGuests);
    }, [shouldExcludeGuests]);
    React.useEffect(function () {
        props.SetSensitivityLabelsEnabled(sensitivityLabelsEnabled);
    }, [sensitivityLabelsEnabled]);
    var removeMember = function (user) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.Members) === null || _a === void 0 ? void 0 : _a.filter(function (member) { return member.UserPrincipalName !== user.UserPrincipalName; });
        if (tempArray === undefined) {
            tempArray = [];
        }
        rwc.updateCurrentRequest({ Members: __spreadArray([], tempArray, true) });
    };
    var removeOwner = function (user) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.Owners) === null || _a === void 0 ? void 0 : _a.filter(function (owner) { return owner.UserPrincipalName !== user.UserPrincipalName; });
        if (tempArray === undefined) {
            tempArray = [];
        }
        if (currentUser !== undefined) {
            if (currentUser.Id !== undefined) {
                if (currentUser.Id === user.Id) {
                    rwc.setHasRemovedSelfFromOwners(true);
                }
            }
        }
        rwc.updateCurrentRequest({ Owners: __spreadArray([], tempArray, true) });
    };
    var getPermissionsValue = function () {
        var _a, _b, _c;
        var currentValue = "";
        if (rwc.currentRequest.PermissionType !== undefined) {
            currentValue = rwc.currentRequest.PermissionType;
        }
        else {
            if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.PermissionType) !== undefined && ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.PermissionType) !== "Choice") {
                currentValue = (_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.PermissionType;
            }
        }
        return currentValue;
    };
    var setPermission = function (value) {
        rwc.updateCurrentRequest({ PermissionType: value });
    };
    var getSensitivityLabelTenantConfig = function (labelId) {
        var foundConfig = undefined;
        if (rwc.sensitivityConfig !== undefined) {
            foundConfig = rwc.sensitivityConfig.LabelConfigs.find(function (lc) { return lc.Id === labelId; });
        }
        return foundConfig;
    };
    React.useEffect(function () {
        var _a;
        if (rwc.sensitivityConfig !== undefined && rwc.sensitivityConfig.IsEnabled === true && rwc.sensitivityLabels !== undefined && rwc.sensitivityLabels.length > 0) {
            setSensitivityLabelsEnabled(true);
            if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.SensitivityLabelType) === "Enforced") {
                if (rwc.sensitivityLabels.length === 1) {
                    setSelectedSensitivityLabel(rwc.sensitivityLabels[0]);
                }
            }
            else if (rwc.getSelectedSensitivityLabel() !== null) {
                setSelectedSensitivityLabel(rwc.getSelectedSensitivityLabel());
            }
        }
        else {
            setSelectedSensitivityLabel(null);
        }
    }, [rwc.sensitivityLabelsLoading]);
    React.useEffect(function () {
        var _a;
        if (rwc.classificationConfig !== undefined && rwc.classificationConfig.IsEnabled === true && rwc.classificationLabels !== undefined && rwc.classificationLabels.length > 0) {
            setClassificationLabelsEnabled(true);
            if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.ClassificationType) === "Enforced") {
                if (rwc.classificationLabels.length === 1) {
                    setSelectedClassificationLabel(rwc.classificationLabels[0].Key);
                }
            }
            else if (rwc.currentRequest.Classification !== null && rwc.currentRequest.Classification !== undefined) {
                setSelectedClassificationLabel(rwc.currentRequest.Classification);
            }
        }
        else {
            setSelectedClassificationLabel(null);
        }
    }, [rwc.classificationLabelsLoading]);
    var setSelectedClassificationLabel = function (labelKey) {
        if (labelKey !== null) {
            if (rwc.currentRequest.Classification === null || rwc.currentRequest.Classification !== labelKey) {
                rwc.updateCurrentRequest({ Classification: labelKey });
            }
        }
        else {
            rwc.updateCurrentRequest({ Classification: null });
        }
    };
    var setSelectedSensitivityLabel = function (label) {
        if (label !== null) {
            var labelConfig = getSensitivityLabelTenantConfig(label.Id);
            var currentSelectedLabel = rwc.getSelectedSensitivityLabel();
            if ((labelConfig === null || labelConfig === void 0 ? void 0 : labelConfig.PermissionOverride) !== null) {
                setSensitivityPermissionOverride(labelConfig === null || labelConfig === void 0 ? void 0 : labelConfig.PermissionOverride);
            }
            else {
                setSensitivityPermissionOverride(undefined);
            }
            if (currentSelectedLabel === null || currentSelectedLabel.Id !== label.Id) {
                rwc.updateCurrentRequest({ SensitivityLabel: JSON.stringify(__assign({}, label)) });
            }
        }
        else {
            rwc.updateCurrentRequest({ SensitivityLabel: null });
            setSensitivityPermissionOverride(undefined);
        }
    };
    React.useEffect(function () {
        var _a, _b;
        if (sensitivityPermissionOverride === undefined) {
            if (rwc.currentRequest.Template !== undefined && ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.PermissionType) !== undefined) {
                if (((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.PermissionType) !== "Choice") {
                    setPermission(rwc.currentRequest.Template.PermissionType);
                }
            }
        }
        else {
            setPermission(sensitivityPermissionOverride);
        }
    }, [sensitivityPermissionOverride]);
    React.useEffect(function () {
        var _a;
        var excludeGuests = false;
        // Sensitivty Label Enabled - Trumps Guest Policy and GAC
        if (sensitivityLabelsEnabled) {
            if ((rwc === null || rwc === void 0 ? void 0 : rwc.sensitivityLabels) !== undefined && (rwc.currentRequest.SensitivityLabel === undefined || rwc.currentRequest.SensitivityLabel === null)) {
                excludeGuests = rwc.sensitivityLabels.some(function (sl) { var _a, _b; return ((_a = sl.Config) === null || _a === void 0 ? void 0 : _a.GroupUserAccessOverride) === false || ((_b = sl.Config) === null || _b === void 0 ? void 0 : _b.GroupUserAccessOverride) === null; });
            }
            if (rwc.currentRequest.SensitivityLabel !== undefined && rwc.currentRequest.SensitivityLabel !== null) {
                var senLabel = JSON.parse(rwc.currentRequest.SensitivityLabel);
                if (senLabel.Config !== undefined &&
                    senLabel.Config.GroupUserAccessOverride !== undefined &&
                    (senLabel.Config.GroupUserAccessOverride === false || senLabel.Config.GroupUserAccessOverride === null)) {
                    excludeGuests = true;
                }
            }
            // Sensitivity Label Disabled
        }
        else {
            // Guest Policies Enabled - Trumps GAC
            if (rwc !== null &&
                rwc !== undefined &&
                rwc.currentGuestRequestPolicy !== null &&
                rwc.currentGuestRequestPolicy !== undefined &&
                rwc.currentGuestRequestPolicy.GUID !== undefined &&
                rwc.currentGuestRequestPolicy.GUID !== null) {
                //excludeGuests = true;
            }
            else {
                // Guest Policies & Sensitivity Lables Disabled - Use GAC
                if (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.ConfigGroupAllowToAddGuests) === false) {
                    excludeGuests = true;
                }
            }
        }
        console.log("  useEffecty excludeGuests", excludeGuests);
        setShouldExcludeGuests(excludeGuests);
    }, [rwc.currentRequest.Template, rwc.currentRequest.SensitivityLabel, sensitivityLabelsEnabled]);
    React.useEffect(function () {
        if (shouldExcludeGuests !== shouldExcludeGuestsPrevious) {
            setShouldExcludeGuestsPrevious(shouldExcludeGuests);
            if (shouldExcludeGuests) {
                var ownersToRemove = [];
                var membersToRemove = [];
                if (rwc.currentRequest.Owners !== undefined) {
                    if (rwc.currentRequest.Owners.length > 0) {
                        ownersToRemove = rwc.currentRequest.Owners.filter(function (u) { return u.UserPrincipalName.indexOf("#EXT#") > -1; });
                        //tempArray = [...rwc.currentRequest.Owners];
                        //rwc.updateCurrentRequest({ Owners: tempArray });
                    }
                }
                if (rwc.currentRequest.Members !== undefined) {
                    if (rwc.currentRequest.Members.length > 0) {
                        membersToRemove = rwc.currentRequest.Members.filter(function (u) { return u.UserPrincipalName.indexOf("#EXT#") > -1; });
                        //tempArray = [...rwc.currentRequest.Owners];
                        //rwc.updateCurrentRequest({ Owners: tempArray });
                    }
                }
                var removeHtml = "";
                if (ownersToRemove.length > 0) {
                    removeHtml += "<div>Removed " + ownersToRemove.length + " guest owner" + (ownersToRemove.length > 1 ? "s" : "") + "</div>";
                    ownersToRemove.forEach(function (o) {
                        removeOwner(o);
                    });
                }
                if (membersToRemove.length > 0) {
                    removeHtml += "<div>Removed " + ownersToRemove.length + " guest member" + (ownersToRemove.length > 1 ? "s" : "") + "</div>";
                    membersToRemove.forEach(function (m) {
                        removeMember(m);
                    });
                }
                rwc.setGuestStorage([]);
                rwc.updateCurrentRequest({ Guests: [] });
                // if (removeHtml !== "") {
                //    toast(<NotificationContent text={"<span style='font-weight:500;'>Removed 3 guests from this workspace request</span>"} mode="success" />, {
                //       toastId: rwc.currentRequest.GUID,
                //       autoClose: 3000,
                //       type: toast.TYPE.DEFAULT
                //    });
                // }
            }
        }
    }, [shouldExcludeGuests]);
    var isMultiGeo = currentTenantConfiguration.IsMultiGeoEnabled && rwc.currentRequest.RequestMultiGeoDataLocation !== currentTenantConfiguration.DefaultGeo;
    return (React.createElement(React.Fragment, null,
        sensitivityLabelsEnabled ? (React.createElement(Col, { md: 4 },
            React.createElement("div", { className: "sensitivity-labels" },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement("div", { className: "title-add" },
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.sensitivity_label", defaultMessage: "Sensitivity" }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { className: "left" }, rwc.sensitivityLabelsLoading === false ? (React.createElement("div", { className: "permission-radios" }, rwc.sensitivityLabels !== undefined &&
                        rwc.sensitivityLabels.map(function (sl) {
                            var _a;
                            return sl.Config !== undefined ? (React.createElement("div", { className: "radio-item" + (((_a = rwc.getSelectedSensitivityLabel()) === null || _a === void 0 ? void 0 : _a.Id) === sl.Id ? " selected" : ""), onClick: function () { return setSelectedSensitivityLabel(sl); } },
                                React.createElement("div", { className: "icon" }, sl.Config.Icon !== undefined && sl.Config.Icon !== null ? React.createElement("i", { className: sl.Config.Icon }) : React.createElement("i", { className: "la la-tag" })),
                                React.createElement("div", { className: "text" },
                                    React.createElement("h6", null, sl.Name),
                                    React.createElement("p", null, sl.Description)),
                                React.createElement("div", { className: "checkbox" },
                                    React.createElement("i", { className: "fa fa-check" })))) : null;
                        }))) : (React.createElement(AnimatedLoader, { size: "med" }))))))) : null,
        classificationLabelsEnabled ? (React.createElement(Col, { md: 4 },
            React.createElement("div", { className: "sensitivity-labels" },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement("div", { className: "title-add" },
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.classification_label", defaultMessage: "Classification" }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { className: "left" }, rwc.classificationLabelsLoading === false ? (React.createElement("div", { className: "permission-radios" }, rwc.classificationLabels !== undefined &&
                        rwc.classificationLabels.map(function (cl) { return (React.createElement("div", { className: "radio-item" + (rwc.currentRequest.Classification === cl.Key ? " selected" : ""), onClick: function () { return setSelectedClassificationLabel(cl.Key); } },
                            React.createElement("div", { className: "icon" }, cl.Icon !== undefined && cl.Icon !== null ? React.createElement("i", { className: cl.Icon }) : React.createElement("i", { className: "la la-tag" })),
                            React.createElement("div", { className: "text" },
                                React.createElement("h6", null, cl.Key),
                                React.createElement("p", null, cl.Description)),
                            React.createElement("div", { className: "checkbox" },
                                React.createElement("i", { className: "fa fa-check" })))); }))) : (React.createElement(AnimatedLoader, { size: "med" }))))))) : null,
        rwc.currentRequest.Template !== undefined && orchestryContext.TenantIsEducation === true && rwc.currentRequest.Template.TeamTemplateSpecialization === "educationClass" ? null : rwc // Do nothing since the permissions for a class are hardset and not selectable.
            .currentRequest.Template !== undefined && ((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateSiteCloneSecurity) === false ? (React.createElement(Col, { md: 4 },
            React.createElement("div", { className: "permissions" },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement("div", { className: "title-add" },
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.permissions_label", defaultMessage: "Permissions" }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { className: "left" },
                        React.createElement("div", { className: "permission-radios", "data-pw": "permissions" },
                            ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.PermissionType) !== undefined &&
                                ((sensitivityPermissionOverride === undefined && (((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.PermissionType) === "Choice" || ((_d = rwc.currentRequest.Template) === null || _d === void 0 ? void 0 : _d.PermissionType) === "Private")) ||
                                    (sensitivityPermissionOverride !== undefined && sensitivityPermissionOverride === "Private")) ? (React.createElement("div", { className: "radio-item" + (getPermissionsValue() === "Private" ? " selected" : ""), "data-pw": "private-permission", onClick: function () { return setPermission("Private"); } },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("img", { src: PermIconPrivate })),
                                React.createElement("div", { className: "text" },
                                    React.createElement("h6", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.private_label", defaultMessage: "Private" })),
                                    React.createElement("p", null, rwc.currentRequest.RequestType === "Team" ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.private_team", defaultMessage: "Only members have access" })) : (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.private_site", defaultMessage: "Only members can access the site" })))),
                                React.createElement("div", { className: "checkbox" },
                                    React.createElement("i", { className: "fa fa-check" })))) : null,
                            ((_e = rwc.currentRequest.Template) === null || _e === void 0 ? void 0 : _e.PermissionType) !== undefined &&
                                ((sensitivityPermissionOverride === undefined && (((_f = rwc.currentRequest.Template) === null || _f === void 0 ? void 0 : _f.PermissionType) === "Choice" || ((_g = rwc.currentRequest.Template) === null || _g === void 0 ? void 0 : _g.PermissionType) === "Public")) ||
                                    (sensitivityPermissionOverride !== undefined && sensitivityPermissionOverride === "Public")) ? (React.createElement("div", { className: "radio-item" + (getPermissionsValue() === "Public" ? " selected" : ""), "data-pw": "public-permission", onClick: function () { return setPermission("Public"); } },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("img", { src: PermIconPublic })),
                                React.createElement("div", { className: "text" },
                                    React.createElement("h6", null,
                                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.public_label", defaultMessage: "Public" })),
                                    React.createElement("p", null, rwc.currentRequest.RequestType === "Team" ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.public_everyone", defaultMessage: "Everyone in my organization has access to it" })) : (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.public_anyone", defaultMessage: "Anyone in the organization can access this site" })))),
                                React.createElement("div", { className: "checkbox" },
                                    React.createElement("i", { className: "fa fa-check" })))) : null),
                        !isMultiGeo &&
                            rwc.currentTenantConfiguration.DirectoryImportAllowUserSelectionToAdd === true &&
                            ((rwc.currentTenantConfiguration.DirectoryImportPrivateVisible === true && getPermissionsValue() === "Private") ||
                                (rwc.currentTenantConfiguration.DirectoryImportPublicVisible === true && getPermissionsValue() === "Public")) ? (React.createElement("div", { className: "show-in-dir" },
                            React.createElement("div", { className: "toggle" },
                                React.createElement(FormToggle, { checked: rwc.currentRequest.ShowInDirectory !== undefined ? rwc.currentRequest.ShowInDirectory : true, handleChange: function (checked) { return rwc.updateCurrentRequest({ ShowInDirectory: checked }); }, theme: "small-green", disabled: isMultiGeo })),
                            React.createElement("span", { className: "title ".concat(isMultiGeo ? "multi" : "").trim() }, isMultiGeo ? (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.multigeo_show_in_directory", defaultMessage: "This workspace will be provisioned to a geographical subdomain that is not the default for this tenant. This workspace will not be available in Orchestry's directory or reporting." })) : (React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.show_in_directory", defaultMessage: "Show in directory" }))))) : null))))) : null,
        rwc.currentRequest.RequestMultiGeoDataLocation !== undefined && currentTenantConfiguration.IsMultiGeoEnabled ? (React.createElement(Col, { md: 4 },
            React.createElement("div", { className: "multigeo-labels" },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement("div", { className: "title-add" },
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.geography_label", defaultMessage: "Geography" }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { className: "left" },
                        React.createElement("div", { className: "permission-radios" },
                            React.createElement("div", { className: "radio-item selected" },
                                React.createElement("div", { className: "icon" },
                                    React.createElement("i", { className: "las la-globe" })),
                                React.createElement("div", { className: "text" },
                                    React.createElement("h6", null, rwc.currentRequest.RequestMultiGeoDataLocation),
                                    React.createElement("p", null, SHAREPOINT_MULTIGEO_CODES[rwc.currentRequest.RequestMultiGeoDataLocation])),
                                React.createElement("div", { className: "checkbox" },
                                    React.createElement("i", { className: "fa fa-check" }))))))))) : null));
});
export default RequestWizardPermissionsPrivacy;
