import React from "react";
import { Stack } from "@mantine/core";
import { RecommendationCardSkeletons } from "appv2/pages/beacon/recommendations/recommendation-card/recommendation-card-skeleton/recommendation-card-skeletons.component";
import { HealthCheckRecommendationCard } from "./health-check-recommendation-card.component";
export var HealthCheckRecommendationsList = function (_a) {
    var recommendations = _a.recommendations, isLoading = _a.isLoading;
    if (isLoading) {
        return React.createElement(RecommendationCardSkeletons, null);
    }
    if (!recommendations || recommendations.length === 0) {
        return null;
    }
    return (React.createElement(Stack, { gap: 8 }, recommendations === null || recommendations === void 0 ? void 0 : recommendations.map(function (recommendation) { return (React.createElement(HealthCheckRecommendationCard, { key: recommendation.RecommendationRecord.Id, recommendation: recommendation })); })));
};
