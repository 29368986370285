var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ReportSearchActionTypes, ReportSearchSlotName } from "./types";
//import mergeDeep from 'app/utils/mergeDeep';
import { merge, cloneDeep } from "lodash";
var initialStateSingleSlot = {
    Slot: ReportSearchSlotName.WorkspaceInsightsSlot,
    IsLoading: true,
    IsChangingView: true,
    IsFavsLoading: true,
    IsRefinersLoading: true,
    Items: [],
    ItemCount: 0,
    Favs: [],
    Context: {
        View: "",
        SearchTerm: "",
        Sort: "Created",
        SortDirection: "DESC"
    },
    Refiners: [],
    Paging: {
        CurrentPage: 0,
        ResultsPerPage: 50,
        TotalResults: 0
    },
    Columns: [],
    Views: [],
    DefaultArchivalPolicies: [],
    DefaultGuestRequestPolicy: null,
    DefaultGuestReviewPolicy: null
};
var initialState = {
    WorkspaceInsightsSlot: __assign(__assign({}, cloneDeep(initialStateSingleSlot)), { Slot: ReportSearchSlotName.WorkspaceInsightsSlot }),
    WorkspaceMembershipsSlot: __assign(__assign({}, cloneDeep(initialStateSingleSlot)), { Slot: ReportSearchSlotName.WorkspaceMembershipsSlot }),
    GuestInsightsSlot: __assign(__assign({}, cloneDeep(initialStateSingleSlot)), { Slot: ReportSearchSlotName.GuestInsightsSlot }),
    GuestMembershipsSlot: __assign(__assign({}, cloneDeep(initialStateSingleSlot)), { Slot: ReportSearchSlotName.GuestMembershipsSlot })
};
// const compare = ( a:Report, b:Report ) => {
//     if(a.Title !== undefined && b.Title !== undefined){
//         if ( a.Title.toLowerCase() < b.Title.toLowerCase() ){
//         return -1;
//         }
//         if ( a.Title.toLowerCase() > b.Title.toLowerCase() ){
//         return 1;
//         }
//     }
//     return 0;
// }
export function lifecycleReportReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ReportSearchActionTypes.Report_Load || ReportSearchActionTypes.Report_Load_Minimal: {
            var updatedRequest = merge(action.payload, { IsLoading: false });
            var slotName = action.payload.Slot;
            updatedRequest.IsFavsLoading = state[slotName].IsFavsLoading;
            updatedRequest.IsRefinersLoading = state[slotName].IsRefinersLoading;
            updatedRequest.Favs = state[slotName].Favs;
            updatedRequest.Refiners = state[slotName].Refiners;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Load_WithContext: {
            //console.log("Report_Load_WithContext, action.payload: ", action.payload);
            var updatedRequest = merge(action.payload, { IsLoading: false });
            var slotName = action.payload.Slot;
            updatedRequest.IsFavsLoading = state[slotName].IsFavsLoading;
            updatedRequest.IsRefinersLoading = false; //state.IsRefinersLoading;
            updatedRequest.Favs = state[slotName].Favs;
            updatedRequest.Refiners = action.payload.Refiners;
            updatedRequest.IsChangingView = false;
            //console.log("Report_Load_WithContext2, updatedRequest: ", updatedRequest);
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Load_Refiners: {
            //const updatedRequest:ReportSearchState = merge(state, { Refiners : action.payload.Refiners });
            var slotName = action.payload.Slot;
            var returnState = cloneDeep(state);
            var updatedRequest = __assign({}, returnState[slotName]);
            updatedRequest.Refiners = action.payload.Refiners;
            updatedRequest.IsRefinersLoading = false;
            updatedRequest.IsChangingView = false;
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Search: {
            return __assign({}, state);
        }
        case ReportSearchActionTypes.Report_Update_Paging: {
            //const updatedRequest: ReportSearchSlotState = merge(state, action.payload, { IsLoading: false });
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], action.payload, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return __assign({}, returnState);
        }
        case ReportSearchActionTypes.Report_Update_Refiners: {
            //const updatedRequest = merge(state, action.payload, { IsLoading: false });
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], action.payload, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            updatedRequest.Paging = action.payload.Paging;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Load_Views: {
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], action.payload, { IsLoading: false });
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Refresh: {
            //const updatedRequest = merge(state, { IsLoading: false });
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            updatedRequest.ItemCount = action.payload.ItemCount;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_RefreshSilent: {
            //const updatedRequest = merge(state, { IsLoading: false });
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            updatedRequest.ItemCount = action.payload.ItemCount;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Update_Context: {
            // const updatedRequest = merge(state, action.payload, { IsLoading: false });
            // updatedRequest.Items = action.payload.Items;
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], action.payload, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Update_View: {
            //const updatedRequest = merge(state, action.payload, { IsLoading: false });
            var slotName = action.payload.Slot;
            var updatedRequest = merge(state[slotName], action.payload, { IsLoading: false });
            updatedRequest.Columns = action.payload.Columns;
            updatedRequest.Items = action.payload.Items;
            updatedRequest.Context.Sort = action.payload.Context.Sort;
            updatedRequest.Context.SortDirection = action.payload.Context.SortDirection;
            updatedRequest.IsChangingView = false;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Update_IsLoading: {
            var slotName = action.payload.SlotName;
            var updatedRequest = merge(state[slotName], { IsLoading: action.payload.Property });
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Update_IsChangingView: {
            var slotName = action.payload.SlotName;
            var updatedRequest = merge(state[slotName], { IsChangingView: action.payload.Property });
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign({}, updatedRequest);
            return returnState;
        }
        case ReportSearchActionTypes.Report_Clear: {
            var slotName = action.payload.SlotName;
            var returnState = cloneDeep(state);
            returnState[slotName] = __assign(__assign({}, cloneDeep(initialStateSingleSlot)), { Slot: slotName });
            return returnState;
            //return { ...initialState };
        }
        default:
            return state;
    }
}
