import { Card, Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-new-number-stat-skeleton.scss";
export var RecommendationNewNumberStatSkeleton = function () { return (React.createElement(Card, { className: "recommendation-new-number-stat-skeleton" },
    React.createElement("div", { className: "top-section" },
        React.createElement(Skeleton, { circle: true, h: 32, w: 32 }),
        React.createElement(Skeleton, { w: 19, h: 12.5, radius: 17.5 })),
    React.createElement("div", { className: "middle-section" },
        React.createElement(Skeleton, { circle: true, h: 22, w: 22 })),
    React.createElement("div", { className: "bottom-section" },
        React.createElement(Skeleton, { h: 10.5, w: 103 })))); };
