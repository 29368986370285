import axios from "axios";
var TemplatesService = /** @class */ (function () {
    function TemplatesService() {
    }
    TemplatesService.GetDirectoryFilterValues = function (filterOn) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Templates/DirectoryFilterOptions" + (filterOn !== undefined && filterOn !== null ? "?filterOn=" + (filterOn === true ? "true" : "false") : "");
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return TemplatesService;
}());
export default TemplatesService;
