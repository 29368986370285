import { OrchestryDataTableDateColumn } from "appv2/components/orchestry-data-table/orchestry-data-table-date-column.component";
import { useCustomMantineReactTable } from "appv2/components/orchestry-data-table/use-custom-mantine-react-table.hook";
import { useMapLocaleToMRTLocalization } from "appv2/components/orchestry-data-table/use-map-local-to-MRT-localization";
import { OrchestryNoRecordsFound } from "appv2/components/orchestry-no-records-found/orchestry-no-records-found.component";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { PartnerToolsTableActionsColumn, PartnerToolsTableInsightsColumn, PartnerToolsTableLicenseColumn, PartnerToolsTableNameColumn } from "./partner-tools-table-columns.component";
export var usePartnerToolsTable = function (pagedMspTenants, sorting, setSorting, isPageLoading, isTableLoading, highlightSearchWords) {
    var _a;
    var history = useHistory();
    var isMobile = useIsMobile();
    var onTenantClicked = function (tenantGuid) { return history.push("/partner-tools/tenants/view/".concat(tenantGuid)); };
    var localization = useMapLocaleToMRTLocalization();
    useEffect(function () {
        if (isMobile) {
            setColumnVisibility({
                DisplayName: true,
                LicenseType: true,
                Actions: false,
                InstallDate_DateOnly: false,
                RenewalDate_DateOnly: false,
                Insights: false,
                TrialExpires: false,
                TenantGuid: false
            });
        }
        else {
            setColumnVisibility({
                TrialExpires: false,
                TenantGuid: false
            });
        }
    }, [isMobile]);
    var _b = useState({
        TrialExpires: false,
        GUID: false
    }), columnVisibility = _b[0], setColumnVisibility = _b[1];
    var onColumnVisibilityChange = function (newVisibility) {
        setColumnVisibility(newVisibility);
    };
    var onSortingChange = function (updaterOrValue) {
        setSorting(updaterOrValue);
    };
    var columns = useMemo(function () { return [
        {
            accessorKey: "DisplayName",
            header: "Tenant",
            enableSorting: true,
            Cell: function (_a) {
                var row = _a.row;
                return PartnerToolsTableNameColumn(row, highlightSearchWords);
            },
            grow: true,
            size: isMobile ? 160 : undefined
        },
        {
            accessorKey: "LicenseType",
            header: "License",
            enableSorting: true,
            Cell: function (_a) {
                var cell = _a.cell;
                return PartnerToolsTableLicenseColumn(cell.getValue());
            },
            size: 120,
            grow: false
        },
        {
            accessorKey: "Actions",
            header: "Actions",
            enableSorting: true,
            Cell: function (_a) {
                var cell = _a.cell;
                return PartnerToolsTableActionsColumn(cell.getValue());
            },
            grow: false,
            size: 100
        },
        {
            accessorKey: "RenewalDate_DateOnly",
            header: "Renewal Date",
            enableSorting: true,
            Cell: function (_a) {
                var cell = _a.cell;
                return OrchestryDataTableDateColumn(cell.getValue());
            },
            grow: false,
            size: 150
        },
        {
            accessorKey: "Insights",
            header: "Insights",
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return PartnerToolsTableInsightsColumn(cell.getValue());
            },
            grow: false,
            size: 580,
            minSize: 580
        },
        {
            accessorKey: "TrialExpires",
            header: ""
        },
        {
            accessorKey: "TenantGuid",
            header: ""
        }
    ]; }, [isMobile, highlightSearchWords]);
    var table = useCustomMantineReactTable({
        layoutMode: "grid",
        onSortingChange: onSortingChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        initialState: {
            sorting: sorting,
            columnVisibility: columnVisibility,
            isLoading: true
        },
        mantineLoadingOverlayProps: {
            visible: false
        },
        state: { sorting: sorting, columnVisibility: columnVisibility, isLoading: isTableLoading },
        columns: columns,
        data: (_a = pagedMspTenants === null || pagedMspTenants === void 0 ? void 0 : pagedMspTenants.Items) !== null && _a !== void 0 ? _a : [],
        renderEmptyRowsFallback: OrchestryNoRecordsFound,
        mantineTableBodyRowProps: function (_a) {
            var row = _a.row;
            return ({
                onClick: function () { return onTenantClicked(row.getValue("TenantGuid")); },
                style: { cursor: "pointer" }
            });
        },
        //These were causing a bug with the partner tools table using mantineTableBodyRowProps.
        //But removing the virtualization, the tables still seem pretty fast.
        //Only needed if a table has well over 100 rows, which is probably unlikely for partner tools tenants.
        //enableRowVirtualization: true,
        //rowVirtualizerOptions: { overscan: 12 },
        mantineTableContainerProps: {
            style: { maxHeight: "610px" }
        },
        localization: localization
    }, isPageLoading ? undefined : (pagedMspTenants === null || pagedMspTenants === void 0 ? void 0 : pagedMspTenants.Items.length) === 0);
    return { table: table };
};
