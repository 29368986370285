import { NamingPolicyListActionTypes } from "app/store/naming-policies/list/types";
import { AsyncCallType } from "app/store/async/types";
import * as RequestListTypes from "app/store/requests/list";
export var namingPolicyListActionMap = [
    {
        actionType: NamingPolicyListActionTypes.NamingPolicy_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/NamingPolicies",
        responseDataType: {},
        invalidates: [
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: NamingPolicyListActionTypes.NamingPolicy_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/NamingPolicies",
        responseDataType: {},
        invalidates: [
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: NamingPolicyListActionTypes.NamingPolicy_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/NamingPolicies",
        responseDataType: {},
        invalidates: [
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: NamingPolicyListActionTypes.NamingPolicy_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "namingPolicyListState.items",
        keyType: "typeName",
        ApiUrl: "/api/NamingPolicies",
        responseDataType: []
    }
];
