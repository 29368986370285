import * as React from "react";
import "./step-card-notification-format.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import FormSelectBox from "app/components/common/form-select-box";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current/";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
var StepCardNotificationFormat = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        props.OnProceedChange(true);
    }, []);
    return (React.createElement("div", { className: "step-card-notification-format policy-card", "data-pw": "step-card-notification" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "middle card-border" },
                        React.createElement("h4", null, "Notification Format"),
                        React.createElement("p", { className: "text" }, "Choose the format that you wish to send notifications by. You can choose multiple formats and Orchestry will ensure they are all kept in sync"),
                        React.createElement("div", { className: "notification-select" },
                            React.createElement(FormSelectBox, { style: "check-list", allowMultiple: true, list: [
                                    {
                                        value: "email",
                                        title: "Email",
                                        icon: "la la-envelope-open-text"
                                    },
                                    {
                                        value: "teams-alert",
                                        title: "Teams Notification",
                                        icon: "la la-bell"
                                    }
                                ], defaultValue: currentPolicy.Notification_Formats, value: currentPolicy.Notification_Formats, onChange: function (value) {
                                    return dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({
                                        Notification_Formats: value
                                    }));
                                } }),
                            React.createElement(FormValidator, { OnScreen: props.IsCurrent, UniqueIdentifier: "stepcardexecutionpolicy-notification", Validators: ["string-not-empty"], Value: currentPolicy.Notification_Formats }))))))));
});
export default StepCardNotificationFormat;
