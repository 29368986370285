var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./step-card-select-action.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import FormSelectBox from "app/components/common/form-select-box";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import FormOfficeAssetPicker from "app/components/common/form-office-asset-picker";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import SharePointService from "app/services/sharepoint";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import FormToggleControl from "app/components/common/form-toggle-control";
import DisableArea from "app/components/common/disable-area";
import InfoTooltip from "app/components/common/infotooltip";
var StepCardSelectAction = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var _a = React.useState(""), selected = _a[0], setSelected = _a[1];
    var _b = React.useState(null), selectedSiteDocumentLibs = _b[0], setSelectedSiteDocumentLibs = _b[1];
    var _c = React.useState(false), changedFromMoveTo = _c[0], setchangedFromMoveTo = _c[1];
    var _d = React.useState(currentPolicy.Option_Content_Action), initialContentAction = _d[0], setinitialContentAction = _d[1];
    var initialSecondStageEnabled = React.useState(currentPolicy.SecondStage_Enabled)[0];
    React.useEffect(function () {
        if (props.SetHidden !== undefined) {
            if (currentPolicy.Option_Content_Action === "Delete") {
                props.SetHidden(true, "actions");
            }
            else {
                props.SetHidden(false, "actions");
            }
        }
        if (currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary") {
        }
    }, [currentPolicy.Option_Content_Action]);
    React.useEffect(function () {
        if (currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary") {
            if (currentPolicy.Option_Content_Archival_Location !== null && currentPolicy.Option_Content_Archival_Location !== "") {
                var spSite = JSON.parse(currentPolicy.Option_Content_Archival_Location);
                SharePointService.GetAllListsFromWeb(spSite.URL).then(function (lists) {
                    setSelectedSiteDocumentLibs(lists.filter(function (l) {
                        return l.BaseTemplate === 101 &&
                            l.ServerRelativeUrl.indexOf("/Style Library") === -1 &&
                            l.ServerRelativeUrl.indexOf("/SiteAssets") === -1 &&
                            l.ServerRelativeUrl.indexOf("/FormServerTemplates") === -1;
                    }));
                });
            }
        }
    }, [currentPolicy.Option_Content_Archival_Location]);
    var updateToggle = function (value) {
        // Validation
        if (currentPolicy.Option_Content_Action === "LeaveContentInPlace" || currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary") {
            value = value;
        }
        else {
            value = false;
        }
        dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
            Option_Content_Archival_Allow_Delete_Alternative: value
        }));
    };
    var AllowDeleteAlternativeOptionIsValid = function () {
        if (currentPolicy.Option_Content_Action === "LeaveContentInPlace" || currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary") {
            return true;
        }
        else {
            return false;
        }
    };
    React.useEffect(function () {
        if (!initialSecondStageEnabled) {
            dispatch(LifecyclePolicyCurrentStore.UpdateProperties(__assign({}, LifecyclePolicyCurrentStore.secondStageDefaults)));
        }
    }, [currentPolicy.SecondStage_Enabled]);
    return (React.createElement("div", { className: "step-card-select-template policy-card", "data-pw": "step-card-select-template" },
        changedFromMoveTo && (React.createElement("div", { role: "alert", className: "customized-alert alert alert-solid-warning alert-bold" },
            React.createElement("div", { className: "alert-icon" },
                React.createElement("i", { className: "fa-regular fa-triangle-exclamation" })),
            React.createElement("div", { className: "alert-text" }, "Move to SharePoint Subsite is being discontinued. After saving this policy you will not be able to select this option again."))),
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "card-border" },
                        React.createElement("h4", null, "Select an archival method"),
                        React.createElement("div", { className: "template-selectors" },
                            React.createElement(FormSelectBox, { list: [
                                    {
                                        value: "LeaveContentInPlace",
                                        title: "Archive In Place",
                                        icon: "fa-regular fa-box-archive",
                                        subtitle: "Perform actions on the current Site, Team or Group without moving the content."
                                    },
                                    {
                                        value: "MoveContentOnlyToDifferentSiteLibrary",
                                        title: "Move to SharePoint Archive",
                                        icon: "fa-regular fa-boxes-packing",
                                        subtitle: "Copy the desired contents of the current Site, Team or Group to a document library within an archival site - then delete the original underlying Team, Site or Group."
                                    },
                                    initialContentAction === "MoveContentToDifferentSite"
                                        ? {
                                            value: "MoveContentToDifferentSite",
                                            title: "Move to SharePoint Subsite",
                                            tooltip: {
                                                content: "Microsoft is gradually moving away from subsite architecture to a flatter architecture, and has begun disabling the creation of subsites in some tenants. We recommend moving to a more future-proof solution like the Move to SharePoint Archive method.",
                                                color: "rgba(255, 0, 0, 0.5)",
                                                icon: "fa-regular fa-hexagon-exclamation"
                                            },
                                            icon: "fa-regular fa-boxes-stacked",
                                            subtitle: "Copy the desired contents of the current Site, Team or Group to a new subsite within an archival site - then delete the original underlying Team, Site or Group."
                                        }
                                        : { title: "", value: "" },
                                    {
                                        value: "Delete",
                                        title: "Delete",
                                        icon: "fa-regular fa-trash",
                                        subtitle: "Delete the current Site, Team or Group."
                                    }
                                ].filter(function (item) { return item.title !== ""; }), value: currentPolicy.Option_Content_Action, onChange: function (value) {
                                    if (!AllowDeleteAlternativeOptionIsValid())
                                        currentPolicy.Option_Content_Archival_Allow_Delete_Alternative = false;
                                    if (currentPolicy.Option_Content_Action === "MoveContentToDifferentSite" && value !== "MoveContentToDifferentSite")
                                        setchangedFromMoveTo(function () { return true; });
                                    if (value === "MoveContentToDifferentSite" && changedFromMoveTo)
                                        setchangedFromMoveTo(false);
                                    dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                        Option_Content_Action: value,
                                        SecondStage_Enabled: value !== "LeaveContentInPlace" ? false : currentPolicy.SecondStage_Enabled,
                                        Option_Content_Archival_Allow_Delete_Alternative: currentPolicy.Option_Content_Archival_Allow_Delete_Alternative
                                    }));
                                } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "action-selected", ErrorMessage: "Please select a content option", Validators: ["string-not-empty"], Value: currentPolicy.Option_Content_Action }),
                            React.createElement(Collapse, { in: currentPolicy.Option_Content_Action === "MoveContentToDifferentSite" },
                                React.createElement("div", null,
                                    React.createElement("hr", null),
                                    React.createElement("h6", null,
                                        "Archival Location ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement("p", { className: "text" }, "Choose the Site Collection that will be used for any moved content in this SharePoint site."),
                                    React.createElement(FormOfficeAssetPicker, { pickerType: "SharePoint", showSelectedText: false, value: currentPolicy.Option_Content_Archival_Location, onChange: function (serializedItem) {
                                            setSelectedSiteDocumentLibs(null);
                                            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                Option_Content_Archival_Location: serializedItem,
                                                Option_Content_Archival_Location_Library: null
                                            }));
                                        } }))),
                            React.createElement(Collapse, { in: currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary", "data-pw": "archival-location" },
                                React.createElement("div", null,
                                    React.createElement("hr", null),
                                    React.createElement("h6", null,
                                        "Archival Location ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement("p", { className: "text" }, "Choose the Site and Document library that will be used for any moved content."),
                                    React.createElement(FormOfficeAssetPicker, { pickerType: "SharePoint", showSelectedText: false, value: currentPolicy.Option_Content_Archival_Location, onChange: function (serializedItem) {
                                            setSelectedSiteDocumentLibs(null);
                                            dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                Option_Content_Archival_Location: serializedItem,
                                                Option_Content_Archival_Location_Library: null
                                            }));
                                        } }),
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 5 },
                                            React.createElement(Form.Group, { className: "form-group document-lib-picker", "data-pw": "document-library" },
                                                React.createElement(Form.Label, null, "Document Library"),
                                                currentPolicy.Option_Content_Archival_Location === null || currentPolicy.Option_Content_Archival_Location === "" ? (React.createElement(Form.Control, { disabled: true, placeholder: "Please select a SharePoint site above first..." })) : selectedSiteDocumentLibs === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { list: selectedSiteDocumentLibs.map(function (l) { return ({
                                                        id: l.Id,
                                                        value: l.Id,
                                                        title: l.Title,
                                                        description: l.ServerRelativeUrl
                                                    }); }), value: currentPolicy.Option_Content_Archival_Location_Library, onChange: function (selected) {
                                                        dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                                            Option_Content_Archival_Location_Library: selected
                                                        }));
                                                    } }))))))),
                            React.createElement(FormValidator, { UniqueIdentifier: "move-content-to-different-site", ErrorMessage: "Select an Archival Location under Actions > Content Actions", Validators: ["string-not-empty"], Enabled: currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary" || currentPolicy.Option_Content_Action === "MoveContentToDifferentSite", Value: currentPolicy.Option_Content_Archival_Location }),
                            React.createElement(FormValidator, { UniqueIdentifier: "move-content-to-different-site-doc-lib", ErrorMessage: "Select an Archival Location Document Library under Actions > Content Actions", Validators: ["string-not-empty"], Enabled: currentPolicy.Option_Content_Action === "MoveContentOnlyToDifferentSiteLibrary", Value: currentPolicy.Option_Content_Archival_Location_Library }),
                            React.createElement("hr", null),
                            React.createElement("h6", null,
                                "Inactivity Threshold ",
                                React.createElement("span", { className: "required-astrix" }, "*"),
                                " ",
                                React.createElement(InfoTooltip, null, "The inactivity threshold identifies the number of days of inactivity after which the policy should execute. This value is not related to the overall inactivity reporting threshold set in Lifecycle Settings."),
                                " "),
                            React.createElement(Row, { "data-pw": "inactivity-threshold" },
                                React.createElement(Col, { md: 5 },
                                    React.createElement(FormDropdownSelect, { list: [
                                            { id: "5", value: 5, title: "5 Days" },
                                            { id: "15", value: 15, title: "15 Days" },
                                            { id: "30", value: 30, title: "30 Days" },
                                            { id: "45", value: 45, title: "45 Days" },
                                            { id: "60", value: 60, title: "60 Days" },
                                            { id: "90", value: 90, title: "90 Days" },
                                            {
                                                id: "120",
                                                value: 120,
                                                title: "120 Days"
                                            },
                                            {
                                                id: "150",
                                                value: 150,
                                                title: "150 Days"
                                            },
                                            {
                                                id: "180",
                                                value: 180,
                                                title: "180 Days"
                                            },
                                            {
                                                id: "270",
                                                value: 270,
                                                title: "270 Days"
                                            },
                                            { id: "365", value: 365, title: "365 Days" },
                                            { id: "730", value: 730, title: "730 Days (2 Years)" },
                                            {
                                                id: "1095",
                                                value: 1095,
                                                title: "1095 Days (3 Years)"
                                            }
                                        ], value: currentPolicy.InactivityDaysThreshold, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ InactivityDaysThreshold: value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "days-selected", ErrorMessage: "Please select the an inactivity threshold", Validators: ["is-true"], Value: currentPolicy.InactivityDaysThreshold !== null }))),
                            React.createElement("hr", null),
                            React.createElement(DisableArea, { isEnabled: AllowDeleteAlternativeOptionIsValid(), useOverlay: true },
                                React.createElement("div", { id: "ws_deletion", "data-pw": "workspace-deletion" },
                                    React.createElement("h6", null, "Workspace Deletion"),
                                    React.createElement(FormToggleControl, { checked: currentPolicy.Option_Content_Archival_Allow_Delete_Alternative && AllowDeleteAlternativeOptionIsValid(), label: "In addition to the selected Archival Method, allow an approver to alternatively select to Delete the Workspace, granting the approver a total of three options: Extend, Archive, or Delete.", handleChange: function (checked) { return updateToggle(checked); } }))))))),
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement(DisableArea, { useOverlay: true, isDisabled: currentPolicy.Option_Content_Action !== "LeaveContentInPlace" },
                        React.createElement("div", { className: "second-step card-border", "data-pw": "second-step" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h4", null, "Add second step"),
                                React.createElement("h6", null,
                                    React.createElement(InfoTooltip, null, "When a second step is enabled on an existing policy with archivals already underway, the second step will take effect after the additional interval following the successful, now \"first step\", archival. This option will be enforced the same way whether the first step has concluded itself yet or not."))),
                            React.createElement(FormToggleControl, { checked: currentPolicy.SecondStage_Enabled, label: "If Archive in Place is selected as the archival method, a second step can be added to new and existing archivals, automating a subsequent series of actions to take place after a configurable interval of time.", handleChange: function (checked) {
                                    dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ SecondStage_Enabled: checked }));
                                } }))))))));
});
export default StepCardSelectAction;
