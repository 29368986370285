import * as React from "react";
import "./report.scss";
import { useSelector, useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import ReportListTable from "app/components/insights/common/report/report-list-table";
import ReportFilters from "app/components/insights/common/report/report-filters";
import ReportPaging from "app/components/insights/common/report/report-paging";
import useWindowDimensions from "app/utils/windowDimentions";
import { ReportDatasource } from "app/store/lifecycle-report";
import LifecycleService from "app/services/lifecycle";
import DisableArea from "app/components/common/disable-area";
import { useHistory } from "react-router-dom";
/** Insights Report Component
 * @param {string} mode - Sets the mode of the report - currently only supports tab.
 * @param {function(ReportItem)} NavigateToDetails - Function to navigate to the details page. Takes in the relevant report item and creates a navigation to its details page.
 * @param {ReportSearchSlotName} Slot - State slot to store the report data.
 * @param {string} ActionParam - Action parameter to pass to the report.
 * @param {string} FilterParam - Name of the report view.
 * @param {string} BasePath - Base path to the report.
 * @param {string} SubPath - Sub path to the report.
 * @param {ReportDatasource} Datasource - Datasource to use for the report.
 * @param {string} DefaultViewOverride - Override the default view for the report.
 * @param {string} ResultName - Name of the result to use in the report.
 * @param {boolean} ShowArchived - Whether to show the Show Archived button.
 * @param {boolean} ShowExport - Whether to show the export button.
 * @param {boolean} ShowDeleted - Whether to show the Show Deleted button.
 * @param {boolean} ShowFiltersAndSearch - Whether to show the filters and search.
 * @param {DynamicValue} InitialDynamicValues - Initial dynamic values to filter the report by.
 */
var InsightsReport = function (props) {
    var width = useWindowDimensions().width;
    var currentView = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Context.View; });
    var showFavs = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Context.ShowFavs; });
    var dispatch = useDispatch();
    var lifecycleReport = useSelector(function (state) { return state.lifecycleReportState[props.Slot]; });
    var totalResults = useSelector(function (state) { return state.lifecycleReportState[props.Slot].ItemCount; });
    var _a = React.useState(false), loadOnce = _a[0], setLoadOnce = _a[1];
    var _b = React.useState(undefined), etlPostInstallStatus = _b[0], setEtlPostInstallStatus = _b[1];
    var _c = React.useState(false), showInsightModal = _c[0], setShowInsightModal = _c[1];
    var history = useHistory();
    React.useEffect(function () {
        //dispatch(LifecycleReportStore.Clear());
        LifecycleService.ETLPostInstallStatus().then(function (postStatus) {
            setEtlPostInstallStatus(postStatus);
        });
        return function () {
            dispatch(LifecycleReportStore.Clear(props.Slot));
            //console.log("Changed");
        };
    }, []);
    React.useEffect(function () {
        if (props.OnTotalResultsChanged !== undefined && totalResults !== undefined && totalResults !== null) {
            props.OnTotalResultsChanged(totalResults);
        }
    }, [lifecycleReport.Items]);
    React.useEffect(function () {
        if (props.ActionParam != null && props.ActionParam !== undefined && props.ActionParam === "insight" && props.FilterParam !== undefined && props.FilterParam !== null) {
            dispatch(LifecycleReportStore.LoadByState({
                Context: {
                    View: decodeURIComponent(props.FilterParam)
                }
            }, props.Slot));
        }
        else {
            // This logic will determine the default report to be loaded per datasource
            var reportView = "";
            switch (props.Datasource) {
                case ReportDatasource.GuestUser:
                    reportView = props.FilterParam !== undefined && props.FilterParam !== null ? props.FilterParam : "all-guests";
                    dispatch(LifecycleReportStore.LoadByState({
                        Context: {
                            View: reportView,
                            DynamicValues: props.InitialDynamicValues
                        }
                    }, props.Slot));
                    break;
                case ReportDatasource.SQLServer:
                    reportView = props.FilterParam !== undefined && props.FilterParam !== null ? props.FilterParam : "sql-server-report-example";
                    dispatch(LifecycleReportStore.LoadByState({
                        Context: {
                            View: reportView,
                            DynamicValues: props.InitialDynamicValues
                        }
                    }, props.Slot));
                    break;
                case ReportDatasource.Domain:
                    reportView = props.FilterParam !== undefined && props.FilterParam !== null ? props.FilterParam : "all-domains";
                    dispatch(LifecycleReportStore.LoadByState({
                        Context: {
                            View: reportView
                        }
                    }, props.Slot));
                    break;
                case ReportDatasource.Company:
                    reportView = props.FilterParam !== undefined && props.FilterParam !== null ? props.FilterParam : "all-companies";
                    dispatch(LifecycleReportStore.LoadByState({
                        Context: {
                            View: reportView
                        }
                    }, props.Slot));
                    break;
                case ReportDatasource.ExternalLink:
                    reportView = props.FilterParam !== undefined && props.FilterParam !== null ? props.FilterParam : "all-external-links";
                    dispatch(LifecycleReportStore.LoadByState({
                        Context: {
                            View: reportView
                        }
                    }, props.Slot));
                    break;
                case ReportDatasource.CosmosDB:
                default:
                    reportView = props.FilterParam !== undefined && props.FilterParam !== null ? props.FilterParam : "all-workspaces";
                    dispatch(LifecycleReportStore.LoadByState({
                        Context: {
                            View: reportView,
                            DynamicValues: props.InitialDynamicValues
                        }
                    }, props.Slot));
                    break;
            }
        }
    }, []);
    var onInsightChangeSelected = function (view) {
        setShowInsightModal(false);
        history.push("/" + props.BasePath + "/" + props.SubPath + "/insight/" + encodeURIComponent(view));
        dispatch(LifecycleReportStore.LoadByState({
            Context: {
                View: view,
                DynamicValues: props.InitialDynamicValues
            }
        }, props.Slot));
    };
    var toggleInsightsModal = function (showModal) {
        setShowInsightModal(showModal);
    };
    var goToLink = function (reportItem) {
        props.NavigateToDetails(reportItem);
    };
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("scroll", handleScroll);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleScroll = function (e) {
        if (width < 769) {
            var scrolledAmount_1 = document.querySelector("html");
            var startScroll_1 = scrolledAmount_1 === null || scrolledAmount_1 === void 0 ? void 0 : scrolledAmount_1.scrollTop;
            setTimeout(function () {
                if ((scrolledAmount_1 === null || scrolledAmount_1 === void 0 ? void 0 : scrolledAmount_1.scrollTop) !== undefined && startScroll_1 === (scrolledAmount_1 === null || scrolledAmount_1 === void 0 ? void 0 : scrolledAmount_1.scrollTop)) {
                    var header = document.querySelector(".team-lifecycleReport > header");
                    if (header !== undefined && header !== null) {
                        var lifecycleReportFilters = document.querySelector(".lifecycle-report-filters");
                        var lifecycleReportFavorites = document.querySelector(".lifecycle-report-favorites");
                        // const lifecycleReportListCards = document.querySelector(".lifecycle-report-list-cards");
                        if (scrolledAmount_1.scrollTop > header.clientHeight) {
                            if (!(lifecycleReportFilters === null || lifecycleReportFilters === void 0 ? void 0 : lifecycleReportFilters.classList.contains("fixed"))) {
                                lifecycleReportFilters === null || lifecycleReportFilters === void 0 ? void 0 : lifecycleReportFilters.classList.add("fixed");
                                lifecycleReportFavorites === null || lifecycleReportFavorites === void 0 ? void 0 : lifecycleReportFavorites.classList.add("fixed");
                            }
                        }
                        else {
                            if (lifecycleReportFilters === null || lifecycleReportFilters === void 0 ? void 0 : lifecycleReportFilters.classList.contains("fixed")) {
                                lifecycleReportFilters === null || lifecycleReportFilters === void 0 ? void 0 : lifecycleReportFilters.classList.remove("fixed");
                                lifecycleReportFavorites === null || lifecycleReportFavorites === void 0 ? void 0 : lifecycleReportFavorites.classList.remove("fixed");
                            }
                        }
                    }
                    //console.log("Scrolled!",scrolledAmount?.scrollTop);
                }
            }, 20);
        }
    };
    var isDataNotReadyTypeGuests = function () {
        return props.DataNotReadyYetType !== undefined && props.DataNotReadyYetType === "Guests";
    };
    return (React.createElement("div", { className: "lifecycle-report" + (lifecycleReport.IsLoading === false && lifecycleReport.Items.length === 0 ? " no-results" : "") },
        React.createElement("div", { className: "lifecycle-report-wrapper" },
            React.createElement("div", { className: "lifecycle-report-cols" },
                React.createElement("div", { className: "first-column" },
                    etlPostInstallStatus !== undefined && etlPostInstallStatus.PrivateServer === true && etlPostInstallStatus.CanConnectToPrivateServer === false ? (React.createElement("div", { className: "crawl-still-in-progress fadein-1s" },
                        React.createElement("i", { className: "flaticon-interface-4" }),
                        React.createElement("div", { className: "title" }, "Sorry, we can't connect to your private Cosmos db instance"),
                        React.createElement("div", { className: "desc" }, "We are getting an error when trying to connect to you database."))) : etlPostInstallStatus !== undefined &&
                        ((isDataNotReadyTypeGuests() === false && etlPostInstallStatus.FirstCrawlDone === false) ||
                            (isDataNotReadyTypeGuests() === true && etlPostInstallStatus.LastCrawlDidGuests === false)) ? (React.createElement("div", { className: "crawl-still-in-progress fadein-1s" },
                        React.createElement("i", { className: "flaticon-interface-4" }),
                        React.createElement("div", { className: "title" }, isDataNotReadyTypeGuests() ? React.createElement(React.Fragment, null, "Sorry, we are still collecting information about your guests") : React.createElement(React.Fragment, null, "Sorry, we are still collecting information about your workspaces")),
                        React.createElement("div", { className: "desc" }, isDataNotReadyTypeGuests() ? (React.createElement(React.Fragment, null, "The first scan can take between 24 and 48 hours.")) : (React.createElement(React.Fragment, null, "This can take anywhere from a couple minutes to a couple of hours depending on how many workspaces you have."))))) : null,
                    props.ShowFiltersAndSearch === false ? null : (React.createElement(ReportFilters, { IsTabMode: props.mode !== undefined && props.mode === "tab" ? true : false, Datasource: props.Datasource, ShowArchived: props.ShowArchived, ShowDeleted: props.ShowDeleted, ShowExport: props.ShowExport, NamePrefix: props.NamePrefix, ResultName: props.ResultName, BasePath: props.BasePath, SubPath: props.SubPath, Slot: props.Slot, ShowModal: showInsightModal, onInsightSelected: onInsightChangeSelected, toggleInsightsModal: toggleInsightsModal })),
                    props.CustomHeader !== undefined && React.createElement(React.Fragment, null, props.CustomHeader(lifecycleReport)),
                    React.createElement(DisableArea, { useOverlay: true, isDisabled: props.ResultsDisabled === undefined ? false : props.ResultsDisabled },
                        React.createElement(ReportListTable, { goToLink: goToLink, Datasource: props.Datasource, Slot: props.Slot, BulkEditEnabled: props.BulkEditEnabled, CustomBulkActions: props.CustomBulkActions, NoResultMainText: props.NoResultMainText, NoResultSubText: props.NoResultSubText, NoResultFaIcon: props.NoResultFaIcon, NoResultAlternate: props.NoResultAlternate, ShowBusyAnimation: props.ShowBusyAnimation })),
                    props.ShowPagingOnlyIfXAmountOfItems !== undefined &&
                        ((lifecycleReport.Items.length !== 0 && lifecycleReport.Items.length < props.ShowPagingOnlyIfXAmountOfItems) ||
                            lifecycleReport.IsLoading === true ||
                            lifecycleReport.Items.length === 0) ? null : (React.createElement(ReportPaging, { ResultName: props.ResultName, Slot: props.Slot })),
                    props.CustomFooter !== undefined && React.createElement(React.Fragment, null, props.CustomFooter(lifecycleReport)))))));
};
export default InsightsReport;
