var MICROSOFT_SVG_ICONS = {
    AzureAdEntraGray: require("./Azure-Ad-Entra-gray.svg").default,
    AzureAdEntra: require("./Azure-Ad-Entra.svg").default,
    CopilotGray: require("./Copilot-gray.svg").default,
    Copilot: require("./Copilot.svg").default,
    DefenderGray: require("./Defender-gray.svg").default,
    Defender: require("./Defender.svg").default,
    Exchange: require("./Exchange.svg").default,
    Microsoft365: require("./Microsoft 365.svg").default,
    OneDriveGray: require("./OneDrive-gray.svg").default,
    OneDrive: require("./OneDrive.svg").default,
    SharepointGray: require("./Sharepoint-gray.svg").default,
    Sharepoint: require("./Sharepoint.svg").default,
    TeamsGray: require("./Teams-gray.svg").default,
    Teams: require("./Teams.svg").default,
    VivaConnectionsGray: require("./Viva Connections-gray.svg").default,
    VivaConnections: require("./Viva Connections.svg").default,
    VivaEngageGray: require("./Viva Engage-gray.svg").default,
    VivaEngage: require("./Viva Engage.svg").default,
};
export default MICROSOFT_SVG_ICONS;
