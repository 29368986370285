import { AsyncWait } from "app/components/common/async";
import FormToggleControl from "app/components/common/form-toggle-control";
import OrchestryService from "app/services/orchestry";
import TenantService from "app/services/tenant";
import { AsyncStatus } from "app/store/async";
import { TenantLicenseStatus, TenantType } from "app/store/tenants";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as TenantListStore from "app/store/tenants/list";
import { TenantListActionTypes } from "app/store/tenants/list/types";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./feature-flags.component.scss";
var OrchestryTenantsDetailsFeatureFlags = React.memo(function () {
    var tenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState([]), activeRegions = _a[0], setActiveRegions = _a[1];
    var dispatch = useDispatch();
    var _b = React.useState(null), accessCodeTenant = _b[0], setAccessCodeTenant = _b[1];
    var tenantTypes = [
        { id: "NotDefined", title: "Not Defined", value: TenantType.NotDefined },
        { id: "OrchestryInternal", title: "Orchestry Internal", value: TenantType.OrchestryInternal },
        { id: "OrchestryCustomer", title: "Orchestry Customer", value: TenantType.OrchestryCustomer },
        { id: "PartnerHub", title: "Partner Hub", value: TenantType.PartnerHub },
        { id: "PartnerSandbox", title: "Partner Sandbox", value: TenantType.PartnerSandbox },
        { id: "PartnerCustomer", title: "Partner Customer", value: TenantType.PartnerCustomer }
    ];
    var licenseStatusTypes = [
        { id: "Unlicensed", title: "Unlicensed", value: TenantLicenseStatus.Unlicensed },
        { id: "PartnerHub", title: "Orchestry Trial", value: TenantLicenseStatus.OrchestryTrial },
        { id: "OrchestryCustomer", title: "Orchestry", value: TenantLicenseStatus.Orchestry },
        { id: "OrchestryInternal", title: "Beacon", value: TenantLicenseStatus.Beacon }
    ];
    var unarchiveTenant = function () {
        TenantService.unarchiveTenant(tenant.GUID).then(function () {
            dispatch(TenantCurrentStore.Clear());
            dispatch(TenantCurrentStore.LoadWithInfo(tenant.GUID));
        });
    };
    var archiveTenant = function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, TenantListActionTypes.Tenant_List_Item_Delete).then(function () {
            dispatch(TenantCurrentStore.Clear());
            dispatch(TenantCurrentStore.LoadWithInfo(tenant.GUID));
        });
        dispatch(TenantListStore.DeleteItem(tenant));
    };
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
        return function () {
            dispatch(TenantCurrentStore.Clear());
        };
    }, []);
    return (React.createElement(Container, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                    React.createElement(Form.Label, null, "ETL Enabled"),
                    React.createElement(FormToggleControl, { checked: tenant.EtlAndDataAnalysisEnabled, label: "Is ETL enabled?", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ EtlAndDataAnalysisEnabled: checked })); } })),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                    React.createElement(Form.Label, null, "Lifecycle Enabled"),
                    React.createElement("p", null, "*Note: this will not automatically start the ETL if enabled."),
                    React.createElement(FormToggleControl, { checked: tenant.LifecycleEnabled, label: "Is lifecycle enabled?", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ LifecycleEnabled: checked })); } })),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                    React.createElement(Form.Label, null, "Guest Management Enabled"),
                    React.createElement(FormToggleControl, { checked: tenant.GuestManagementEnabled, label: "Is guest management enabled?", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ GuestManagementEnabled: checked })); } })),
                React.createElement(Form.Group, { controlId: "control-type" },
                    React.createElement(Form.Label, null, "Pendo Enabled"),
                    React.createElement(FormToggleControl, { checked: tenant.IsPendoEnabled, label: "Is pendo enabled?", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ IsPendoEnabled: checked })); } })),
                React.createElement(Form.Group, { controlId: "control-type" },
                    React.createElement(Form.Label, null, "Log Un-Obfuscation"),
                    React.createElement(FormToggleControl, { checked: tenant.AreLogsUnObfuscated, label: "Un-Obfuscate Provisioning / Archival Hangfire Logs?", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ AreLogsUnObfuscated: checked })); } })),
                React.createElement(Form.Group, { controlId: "control-type" },
                    React.createElement(Form.Label, null, "Full Orchestry Upgrade Enabled"),
                    React.createElement(FormToggleControl, { checked: tenant.CanUpgradeToFullOrchestry, label: "Can a Beacon customer upgrade to the full version (not trial) on their own from their installation page?", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ CanUpgradeToFullOrchestry: checked })); } }))))));
});
export default OrchestryTenantsDetailsFeatureFlags;
