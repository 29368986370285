import * as React from "react";
import "./report-details.scss";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import NoResults from "app/components/common/no-results";
import LifecycleGuestDetailsHeader from "./components/lifecycle-guest-details-header";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
var GuestDetails = React.memo(function (_a) {
    var guestId = _a.guestId, cosmosUser = _a.cosmosUser;
    var dispatch = useDispatch();
    var cosmosItem = useSelector(function (state) { return state.lifecycleGuestState.cosmosItem; });
    var _b = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "overview"), hash = _b[0], setHash = _b[1];
    var _c = React.useState(false), errorLoading = _c[0], setErrorLoading = _c[1];
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    return (React.createElement("div", { className: "lifecycle-details" },
        React.createElement(Subtitle, { title: "Guest Management", subtitle: cosmosItem === null ? undefined : React.createElement(React.Fragment, null, cosmosItem === null || cosmosItem === void 0 ? void 0 : cosmosItem.DisplayName) }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/guests/listing" },
                React.createElement(SubtitleButton, { title: "Back to Insights", variant: "secondary", faIcon: "arrow-left" }))),
        errorLoading ? null : (React.createElement("div", { className: "loading-skeleton" },
            React.createElement("div", { className: "header" }),
            React.createElement("div", { className: "items" }, [1, 1, 1, 1, 1, 1, 1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: "header-skeleton-" + index },
                React.createElement("div", { className: "pic" },
                    React.createElement("div", { className: "circle animated-wave" })),
                React.createElement("div", { className: "title" },
                    React.createElement("div", { className: "title-box animated-wave" })),
                React.createElement("div", { className: "property" },
                    React.createElement("div", { className: "property-box" })),
                React.createElement("div", { className: "property" },
                    React.createElement("div", { className: "property-box" })),
                React.createElement("div", { className: "property" },
                    React.createElement("div", { className: "property-box" })))); })))),
        errorLoading === true ? React.createElement(NoResults, { subText: "Cannot find the requested user" }) : React.createElement(LifecycleGuestDetailsHeader, { guestId: guestId, cosmosUser: cosmosUser })));
});
export default GuestDetails;
var useHash = function () {
    var _a = React.useState(function () { return window.location.hash; }), hash = _a[0], setHash = _a[1];
    var hashChangeHandler = React.useCallback(function () {
        setHash(window.location.hash);
    }, []);
    React.useEffect(function () {
        window.addEventListener("hashchange", hashChangeHandler);
        return function () {
            window.removeEventListener("hashchange", hashChangeHandler);
        };
    }, []);
    var updateHash = React.useCallback(function (newHash) {
        if (newHash !== hash)
            window.location.hash = newHash;
    }, [hash]);
    return [hash, updateHash];
};
