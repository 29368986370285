import React from "react";
var findByObjectDisplayName = function (children, component) {
    var result = [];
    //console.log("functionName", functionName)
    /* This is the array of result since Article can have multiple times the same sub-component */
    //const componentPrototype = component.__proto__;
    var type = [component.displayName];
    //console.log("component", [type, component, children])
    //console.log("component type", type)
    //console.log("component Prototype", componentPrototype.prototype)
    /* We can store the actual name of the component through the displayName or name property of our sub-component */
    React.Children.forEach(children, function (child) {
        // console.log("child", child && child.type && (child.type.displayName || child.type.name));
        // console.log("component", component);
        //console.log("child", child)
        //console.log("child.constructor.name", child.constructor.name)
        //console.log("child._descriptor.type.displayName", child._descriptor.type.displayName)
        var childType = child && child.type && child.type.displayName;
        //console.log("parent - ", component)
        //const childPrototype = child.__proto__;
        //console.log("childType - ", childType)
        if (type.includes(childType)) {
            result.push(child);
        }
    });
    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return result[0];
};
export var findMultipleByObjectDisplayName = function (children, component) {
    var result = [];
    //console.log("functionName", functionName)
    /* This is the array of result since Article can have multiple times the same sub-component */
    //const componentPrototype = component.__proto__;
    var type = [component.displayName];
    //console.log("component", [type, component, children])
    //console.log("component type", type)
    //console.log("component Prototype", componentPrototype.prototype)
    /* We can store the actual name of the component through the displayName or name property of our sub-component */
    React.Children.forEach(children, function (child) {
        // console.log("child", child && child.type && (child.type.displayName || child.type.name));
        // console.log("component", component);
        //console.log("child", child)
        //console.log("child.constructor.name", child.constructor.name)
        //console.log("child._descriptor.type.displayName", child._descriptor.type.displayName)
        var childType = child && child.type && child.type.displayName;
        //console.log("parent - ", component)
        //const childPrototype = child.__proto__;
        //console.log("childType - ", childType)
        if (type.includes(childType)) {
            result.push(child);
        }
    });
    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return result;
};
export default findByObjectDisplayName;
