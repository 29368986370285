import { LifecyclePolicyCurrentActionTypes } from "app/store/lifecycle-policies/current/types";
import { AsyncCallType } from "app/store/async/types";
export var lifecyclePolicyCurrentActionMap = [
    {
        actionType: LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "lifecyclePoliciesListState.items",
        keyType: "match",
        ApiUrl: "/api/LifecyclePolicies/?guid={GUID}&policyType={PolicyType}",
        responseDataType: {}
    }
];
