var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useGetRecommendationCategoriesQuery, useGetRecommendationProductsQuery } from "appv2/api/recommendations/recommendations.api";
import { defaultResultOptions } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { usePageSearch } from "appv2/hooks/use-page-search.hook";
import { useQueryParameterSort } from "appv2/hooks/use-query-parameter-sort.hook";
import { useEffect, useMemo, useState } from "react";
import { generateFilterExpression } from "./recommendations-page.utils";
var RECOMMENDATIONS_DEFAULT_PRIORITY_SORT = "asc";
/**
 * MS Teams uses a DynamicScrollbar which messes up the Virtuoso list of recommendations.
 * So in Teams we do not use the Virtuoso list and instead use a static size of 6 to page by.
 */
var TEAMS_PAGE_SIZE = "6";
export var useRecommendationsBaseApi = function (appliedFilters, visibilityFilter, appliedFiltersDependency) {
    var _a = useState(1), activePage = _a[0], setActivePage = _a[1];
    var _b = useState(function () { var _a; return (_a = localStorage.getItem("recommendations-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"; }), resultsPerPage = _b[0], setResultsPerPage = _b[1];
    var filterExpression = useMemo(function () { return generateFilterExpression(__spreadArray(__spreadArray([], appliedFilters, true), [visibilityFilter], false)); }, [appliedFiltersDependency]);
    var _c = useQueryParameterSort({ defaultSort: RECOMMENDATIONS_DEFAULT_PRIORITY_SORT }), prioritySort = _c.prioritySort, togglePrioritySort = _c.togglePrioritySort;
    var _d = usePageSearch(setActivePage), querySearchText = _d.querySearchText, highlightSearchWords = _d.highlightSearchWords, searchTextInputValue = _d.searchTextInputValue, onSearchBoxChange = _d.onSearchBoxChange, onSearchBoxKeyDown = _d.onSearchBoxKeyDown, onSearchBoxXClicked = _d.onSearchBoxXClicked;
    var isInTeams = useState(document.OrchestryIsInTeams)[0];
    var pageOptions = isInTeams ? [{ value: "6" }] : defaultResultOptions;
    var _e = useGetRecommendationCategoriesQuery(), recommendationCategories = _e.data, recommendationCategoriesError = _e.error;
    var _f = useGetRecommendationProductsQuery(), recommendationProducts = _f.data, recommendationProductsError = _f.error;
    var isError = recommendationCategoriesError || recommendationProductsError;
    useEffect(function () {
        if (isInTeams) {
            setResultsPerPage(TEAMS_PAGE_SIZE);
        }
    }, [isInTeams]);
    return {
        recommendationCategories: recommendationCategories,
        recommendationProducts: recommendationProducts,
        togglePrioritySort: togglePrioritySort,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        querySearchText: querySearchText,
        highlightSearchWords: highlightSearchWords,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        isError: isError,
        pageOptions: pageOptions,
        filterExpression: filterExpression,
        prioritySort: prioritySort
    };
};
