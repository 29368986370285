import * as FeatureCurrentStore from "app/store/features/current";
import { AsyncCallType } from "app/store/async/types";
export var featureCurrentActionMap = [
    {
        actionType: FeatureCurrentStore.FeatureCurrentActionTypes.Feature_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "featureListState.items",
        keyType: "guid",
        ApiUrl: "/api/Features/?guid={key}",
        responseDataType: {}
    }
];
