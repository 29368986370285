import * as AsyncStore from "app/store/async";
import * as DocumentTemplateListStore from "app/store/document-templates/list";
export var documentTemplateNotificationConfigMap = [
    {
        AsyncType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Title}</strong> to document templates list...",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Title}</strong> in document templates list...",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Title}</strong> from document templates list...",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Delete
            }
        ]
    },
    {
        AsyncType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Title}</strong> successfully added to document templates list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Add
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully updated in document templates list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Update
            },
            {
                Text: "<strong>{Item.Title}</strong> successfully deleted from document templates list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Delete
            }
        ]
    },
    {
        AsyncType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Title}</strong> to document templates list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Title}</strong> to document templates list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Title}</strong> from document templates list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Item_Delete
            },
            {
                Text: "Error when trying to load document template list.",
                ActionType: DocumentTemplateListStore.DocumentTemplateListActionTypes.DocumentTemplate_List_Load
            }
        ]
    }
];
