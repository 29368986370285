import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { UnstyledButton } from "@mantine/core";
import "./orchestry-ellipses-button.scss";
export var OrchestryEllipsesButton = forwardRef(function (_a, ref) {
    var ariaLabel = _a.ariaLabel, onClick = _a.onClick, className = _a.className;
    return (React.createElement(UnstyledButton, { className: clsx("orchestry-ellipses-button", className), tabIndex: 0, "aria-label": ariaLabel, onClick: function (event) {
            onClick();
            event.stopPropagation();
        }, onKeyDown: function (event) {
            if (event.key === "Enter") {
                onClick();
                event.stopPropagation();
            }
        } },
        React.createElement(OrchestryFontIcon, { onClick: function () {
                onClick();
            }, variant: "hover", lineHeight: "24px", fontSize: "24px", color: "var(--mantine-color-gray-6)", iconClassName: ORCH_ICONS.moreHorizontal, ref: ref })));
});
