import { ApprovalWorkflowCurrentActionTypes } from "app/store/approval-workflows/current/types";
import { AsyncCallType } from "app/store/async/types";
export var approvalWorkflowCurrentActionMap = [
    {
        actionType: ApprovalWorkflowCurrentActionTypes.ApprovalWorkflow_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "approvalWorkflowListState.items",
        keyType: "guid",
        ApiUrl: "/api/ApprovalWorkflows/?guid={key}",
        responseDataType: {}
    }
];
