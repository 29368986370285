import { LibraryTemplateCurrentActionTypes } from "./types";
var initialState = {
    item: {}
};
export function libraryTemplateCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load: {
            console.log("Lib TEmplate REDUCER", LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load, action);
            return {
                item: action.payload
            };
        }
        case LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Update: {
            var updatedLibraryTemplate = Object.assign({}, state.item, action.payload);
            return {
                //item:state.item
                item: updatedLibraryTemplate
            };
        }
        case LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
