var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./lifecycle-logs.scss";
import { useSelector, useDispatch } from "react-redux";
import LifecycleService from "app/services/lifecycle";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import * as TableList from "app/components/common/table-list";
import formatDataSize from "app/utils/formatDataSize";
import ProgressReport from "app/components/common/progress-report";
import OrchModal from "app/components/common/orch-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
var LifecycleLogs = React.memo(function (_a) {
    var siteId = _a.siteId;
    var dispatch = useDispatch();
    // const [files, setFiles] = React.useState<undefined | ArchivalLogFile[]>();
    var _b = React.useState(), log = _b[0], setLog = _b[1];
    var _c = React.useState(), logFileLoaded = _c[0], setLogFileLoaded = _c[1];
    //const [histories, setHistories] = React.useState<WorkspaceHistories>();
    var files = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.logs; });
    React.useEffect(function () {
        dispatch(LifecyclePolicyWorkspaceStore.LoadLogs(siteId));
    }, []);
    var getLogFile = function (file) {
        if (file.Name !== logFileLoaded) {
            setLogFileLoaded(file.Name);
            setLog(undefined);
            LifecycleService.GetWorkspaceArchivalLogFile(siteId, file.Name).then(function (logs) {
                logs = logs.map(function (l) {
                    var status = "";
                    if (l.Status === 0) {
                        status = "Start";
                    }
                    if (l.Status === 1) {
                        status = "Complete";
                    }
                    if (l.Status === 2) {
                        status = "Message";
                    }
                    if (l.Status === 3) {
                        status = "Warning";
                    }
                    if (l.Status === 4) {
                        status = "Error";
                    }
                    return __assign(__assign({}, l), { StatusType: status });
                });
                setLog(logs);
            });
        }
    };
    return (React.createElement(Portlet, { className: "lifecycle-logs", "data-pw": "lifecycle-logs" },
        React.createElement(PortletHeader, { title: "Archival Logs" }),
        React.createElement(PortletBody, null, files === null ? (React.createElement(React.Fragment, null,
            React.createElement(SkeletonLoaderFakeBox, { marginTop: 10 }),
            React.createElement(SkeletonLoaderFakeBox, { marginTop: 10 }),
            React.createElement(SkeletonLoaderFakeBox, { marginTop: 10 }))) : (React.createElement(TableList.Context, { listItems: files, wrappedInPortlet: false, showLoader: false },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Log Name"),
                React.createElement(TableList.HeaderItem, null, "Size"),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", { onClick: function () { return getLogFile(item); } },
                        React.createElement(OrchModal, { buttonContents: React.createElement("div", { className: "log-name" }, item.Name.replace(".json", "")), simpleButton: true, headerText: item.Name.replace(".json", ""), size: "xl", showCloseButton: true }, log === undefined ? (React.createElement(React.Fragment, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 200 }))) : (React.createElement(ProgressReport, { logs: log }))))); }, isMobileTitle: true }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return React.createElement(React.Fragment, null, formatDataSize(item.ContentLength)); }, className: "td-type" })))))));
});
export default LifecycleLogs;
