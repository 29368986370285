var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LifecyclePolicyListActionTypes } from "./types";
import Store from "app/store";
import { cloneDeep } from "lodash";
export function Load(policyType, policys) {
    if (policys === undefined)
        policys = [];
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load,
        payload: {
            items: __spreadArray([], policys, true),
            policyType: policyType
        }
    };
}
export function Refresh(policyType) {
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Refresh,
        payload: {
            items: [],
            policyType: policyType
        }
    };
}
export function AddItem(policy) {
    console.log("Policy AddItem", policy);
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add,
        payload: __assign({}, policy)
    };
}
export function AddCurrentItem() {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecyclePoliciesCurrentState.item);
    console.log("Policy AddCurrentItem", policyState);
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Add,
        payload: __assign({}, policyState)
    };
}
export function UpdateItem(policy) {
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update,
        payload: __assign({}, policy)
    };
}
export function UpdateCurrentItem() {
    var state = Store.getState();
    var policyState = cloneDeep(state.lifecyclePoliciesCurrentState.item);
    console.log("Policy UpdateCurrentItem", policyState);
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Update,
        payload: __assign({}, policyState)
    };
}
export function DeleteItem(policy) {
    return {
        type: LifecyclePolicyListActionTypes.LifecyclePolicy_List_Item_Delete,
        payload: __assign({}, policy)
    };
}
export var policyListMiddlware = function (store, next, action) {
    // if(action.type === LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load){
    //     action.payload.forEach((temp:LifecyclePolicy) => {
    //         if(temp.AssociatedFeatures){
    //             temp.AssociatedFeatures.forEach((feat:Feature) => {
    //                 if(feat.Icon !== undefined)
    //                     feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //             });
    //         }
    //     });
    //     next(action);
    // }
    // else {
    next(action);
    //}
};
