import axios from "axios";
var UsersService = /** @class */ (function () {
    function UsersService() {
    }
    UsersService.currentGraphUsersSearches = [];
    UsersService.userPhotoResolveBatches = [];
    UsersService.userPresenceResolveBatches = [];
    UsersService.userResolveBatches = [];
    UsersService.cachedUsers = [];
    UsersService.searchGraphUsers = function (searchTerm, size, excludeGuests, adminTenantGuid) {
        if (excludeGuests === void 0) { excludeGuests = false; }
        if (adminTenantGuid === void 0) { adminTenantGuid = undefined; }
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (UsersService.currentGraphUsersSearches.length > 0) {
                UsersService.currentGraphUsersSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                UsersService.currentGraphUsersSearches.push({
                    id: UsersService.currentGraphUsersSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                var excludeGuestsQS = excludeGuests ? "&excludeGuests=true" : "";
                var tenantGuidQS = adminTenantGuid !== undefined ? "&tenantGuid=" + adminTenantGuid : "";
                var urlPart = adminTenantGuid !== undefined ? "UserSearchAdmin" : "UserSearch";
                searchTerm = searchTerm.replace("&", "%26");
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/users/" + urlPart + "?queryText=" + searchTerm + (size !== undefined ? "&size=" + size : "") + excludeGuestsQS + tenantGuidQS;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    UsersService.searchGraphUsersAndGroups = function (searchTerm, size, excludeGuests) {
        if (excludeGuests === void 0) { excludeGuests = false; }
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (UsersService.currentGraphUsersSearches.length > 0) {
                UsersService.currentGraphUsersSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                UsersService.currentGraphUsersSearches.push({
                    id: UsersService.currentGraphUsersSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                var excludeGuestsQS = excludeGuests ? "&excludeGuests=true" : "";
                searchTerm = searchTerm.replace("&", "%26");
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/users/UserGroupSearch?queryText=" + searchTerm + (size !== undefined ? "&size=" + size : "") + excludeGuestsQS;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data.sort(UsersService.compareUserGroupNames));
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    UsersService.compareUserGroupNames = function (a, b) {
        if (a.DisplayName !== undefined && b.DisplayName !== undefined) {
            if (a.DisplayName < b.DisplayName) {
                return -1;
            }
            if (a.DisplayName > b.DisplayName) {
                return 1;
            }
        }
        return 0;
    };
    UsersService.getSuggestedPeople = function (size, excludeGuests) {
        if (excludeGuests === void 0) { excludeGuests = false; }
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var excludeGuestsQS = excludeGuests ? "&excludeGuests=true" : "";
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/users/PeopleSuggestions" + (size !== undefined ? "?size=" + size : "") + excludeGuestsQS;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    UsersService.setMenuCollapse = function (collapsed) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/users/SetMenuCollapse?collapsed=" + (collapsed === true ? "true" : "false");
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    UsersService.getUserBase64Photo = function (uid) {
        return new Promise(function (resolve, reject) {
            if (UsersService.userPhotoResolveBatches.length === 0) {
                UsersService.userPhotoResolveBatches.push({
                    uids: [
                        {
                            uid: uid,
                            resolve: resolve
                        }
                    ]
                });
            }
            else {
                UsersService.userPhotoResolveBatches[UsersService.userPhotoResolveBatches.length - 1].uids.push({
                    uid: uid,
                    resolve: resolve
                });
            }
            var arraySizeBefore = UsersService.userPhotoResolveBatches[UsersService.userPhotoResolveBatches.length - 1].uids.length;
            // First let's configure the ajax request
            var axiosConfig = {};
            //axiosConfig.method = "GET"
            axiosConfig.method = "POST";
            // Set the API URL
            //axiosConfig.url = "/api/users/GetUserBase64Photo?uid=" + uid;
            axiosConfig.url = "/api/users/GetUserBase64Photos";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            setTimeout(function () {
                var arraySizeAfter = UsersService.userPhotoResolveBatches[UsersService.userPhotoResolveBatches.length - 1].uids.length;
                var currentBatchArray = UsersService.userPhotoResolveBatches.length - 1;
                //console.log("array size before", arraySizeBefore, "array size after", arraySizeAfter);
                if (arraySizeBefore === arraySizeAfter) {
                    // Start a new array for user profile pic requests.
                    UsersService.userPhotoResolveBatches.push({
                        uids: []
                    });
                    //console.log("QUERY BATCH NOW > START");
                    var guidArray = UsersService.userPhotoResolveBatches[currentBatchArray].uids.map(function (uid) { return uid.uid; });
                    var formData = new FormData();
                    formData.append("users-json-array", JSON.stringify(guidArray));
                    axiosConfig.data = formData;
                    //console.log("QUERY BATCH NOW > UIDS ARRAY > " + guidArray);
                    axios(axiosConfig)
                        .then(function (response) {
                        //console.log("QUERY BATCH NOW > END");
                        //console.log("Image Response", response)
                        UsersService.userPhotoResolveBatches[currentBatchArray].uids.forEach(function (user) {
                            //console.log("UserData", response.data[user.uid]);
                            user.resolve(response.data[user.uid]);
                        });
                        //if(Array.isArray(response.data)){
                        //}
                        //resolve(response.data as string);
                    })
                        .catch(function (response) {
                        //console.log("Axios Response");
                        reject(response);
                    });
                }
            }, 50);
        });
    };
    UsersService.getUserByID = function (uid) {
        return new Promise(function (resolve, reject) {
            uid = uid.trim();
            var foundUser = UsersService.cachedUsers.find(function (um) { return um.UserId === uid; });
            if (foundUser !== undefined) {
                resolve(foundUser.User);
            }
            else {
                if (UsersService.userResolveBatches.length === 0) {
                    UsersService.userResolveBatches.push({
                        uids: [
                            {
                                uid: uid,
                                resolve: resolve
                            }
                        ]
                    });
                }
                else {
                    UsersService.userResolveBatches[UsersService.userResolveBatches.length - 1].uids.push({
                        uid: uid,
                        resolve: resolve
                    });
                }
                var arraySizeBefore_1 = UsersService.userResolveBatches[UsersService.userResolveBatches.length - 1].uids.length;
                // First let's configure the ajax request
                var axiosConfig_1 = {};
                //axiosConfig.method = "GET"
                axiosConfig_1.method = "POST";
                // Set the API URL
                //axiosConfig.url = "/api/users/GetUserBase64Photo?uid=" + uid;
                axiosConfig_1.url = "/api/users/GetUsers";
                // Set Reponse Type
                axiosConfig_1.responseType = "json";
                setTimeout(function () {
                    var arraySizeAfter = UsersService.userResolveBatches[UsersService.userResolveBatches.length - 1].uids.length;
                    var currentBatchArray = UsersService.userResolveBatches.length - 1;
                    //console.log("array size before", arraySizeBefore, "array size after", arraySizeAfter);
                    if (arraySizeBefore_1 === arraySizeAfter) {
                        // Start a new array for user profile pic requests.
                        UsersService.userResolveBatches.push({
                            uids: []
                        });
                        //console.log("QUERY BATCH NOW > START");
                        var guidArray = UsersService.userResolveBatches[currentBatchArray].uids.map(function (uid) { return uid.uid; });
                        var formData = new FormData();
                        formData.append("users-json-array", JSON.stringify(guidArray));
                        axiosConfig_1.data = formData;
                        //console.log("QUERY BATCH NOW > UIDS ARRAY > " + guidArray);
                        axios(axiosConfig_1)
                            .then(function (response) {
                            //console.log("QUERY BATCH NOW > END");
                            // console.log("Response", response);
                            // Try to cache the users
                            for (var propertyName in response.data) {
                                if (!UsersService.cachedUsers.some(function (u) { return u.UserId === propertyName; })) {
                                    //console.log("Cached", propertyName, response.data[propertyName]);
                                    var user = response.data[propertyName];
                                    UsersService.cachedUsers.push({
                                        UserId: propertyName,
                                        User: user
                                    });
                                }
                            }
                            UsersService.userResolveBatches[currentBatchArray].uids.forEach(function (user) {
                                //console.log("UserData", response.data[user.uid]);
                                user.resolve(response.data[user.uid]);
                            });
                            //if(Array.isArray(response.data)){
                            //}
                            //resolve(response.data as string);
                        })
                            .catch(function (response) {
                            //console.log("Axios Response", response);
                            reject(response);
                        });
                    }
                }, 50);
            }
        });
    };
    //   public static getUserByID = (uid:string):Promise<User> => {
    //        return new Promise((resolve, reject) => {
    //             // First let's configure the ajax request
    //             let axiosConfig:AxiosRequestConfig = {} as AxiosRequestConfig;
    //             axiosConfig.method = "GET"
    //             // Set the API URL
    //             axiosConfig.url = "/api/users/GetUser?uid=" + uid;
    //             // Set Reponse Type
    //             axiosConfig.responseType = "json";
    //             axios(axiosConfig)
    //                  .then((response:AxiosResponse) => {
    //                       resolve(response.data as User);
    //                  })
    //                  .catch((response:any) => {
    //                       //console.log("Axios Response");
    //                       reject(response);
    //                  });
    //        });
    //   }
    // public static getUserPresenceByID = (uid: string) => {
    //    return new Promise((resolve, reject) => {
    //       // First let's configure the ajax request
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       // Set the API URL
    //       axiosConfig.url = "/api/users/GetUserPresence?uid=" + uid;
    //       // Set Reponse Type
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data as User);
    //          })
    //          .catch((response: any) => {
    //             //console.log("Axios Response");
    //             reject(response);
    //          });
    //    });
    // };
    UsersService.getUserPresenceByID = function (uid) {
        return new Promise(function (resolve, reject) {
            if (UsersService.userPresenceResolveBatches.length === 0) {
                UsersService.userPresenceResolveBatches.push({
                    uids: [
                        {
                            uid: uid,
                            resolve: resolve
                        }
                    ]
                });
            }
            else {
                UsersService.userPresenceResolveBatches[UsersService.userPresenceResolveBatches.length - 1].uids.push({
                    uid: uid,
                    resolve: resolve
                });
            }
            var arraySizeBefore = UsersService.userPresenceResolveBatches[UsersService.userPresenceResolveBatches.length - 1].uids.length;
            // First let's configure the ajax request
            var axiosConfig = {};
            //axiosConfig.method = "GET"
            axiosConfig.method = "POST";
            // Set the API URL
            //axiosConfig.url = "/api/users/GetUserBase64Photo?uid=" + uid;
            axiosConfig.url = "/api/users/GetUserPresences";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            setTimeout(function () {
                var arraySizeAfter = UsersService.userPresenceResolveBatches[UsersService.userPresenceResolveBatches.length - 1].uids.length;
                var currentBatchArray = UsersService.userPresenceResolveBatches.length - 1;
                //console.log("array size before", arraySizeBefore, "array size after", arraySizeAfter);
                if (arraySizeBefore === arraySizeAfter) {
                    // Start a new array for user profile pic requests.
                    UsersService.userPresenceResolveBatches.push({
                        uids: []
                    });
                    //console.log("QUERY BATCH NOW > START");
                    var guidArray = UsersService.userPresenceResolveBatches[currentBatchArray].uids.map(function (uid) { return uid.uid; });
                    var formData = new FormData();
                    formData.append("users-json-array", JSON.stringify(guidArray));
                    axiosConfig.data = formData;
                    //console.log("QUERY BATCH NOW > UIDS ARRAY > " + guidArray);
                    axios(axiosConfig)
                        .then(function (response) {
                        //console.log("QUERY BATCH NOW > END");
                        //console.log("Image Response", response)
                        UsersService.userPresenceResolveBatches[currentBatchArray].uids.forEach(function (user) {
                            //console.log("UserData", response.data[user.uid]);
                            user.resolve(response.data[user.uid]);
                        });
                        //if(Array.isArray(response.data)){
                        //}
                        //resolve(response.data as string);
                    })
                        .catch(function (response) {
                        //console.log("Axios Response");
                        reject(response);
                    });
                }
            }, 100);
        });
    };
    UsersService.getCurrentUser = function (uid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/users/GetCurrentUser";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    UsersService.IsCurrentUserOnAnyWorkflowsAsApprover = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/orchestry/IsUserOnAnyWorkflowsAsApprover";
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    UsersService.getAllUsers = function (siteUrl, userType, groupId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/users/GetUsers?siteUrl=" + siteUrl + "&userType=" + userType + (groupId !== null ? "&groupId=" + groupId : "");
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data.users);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    return UsersService;
}());
export default UsersService;
