import { useGetHealthCheckRecommendationsQuery } from "appv2/api/recommendations/recommendations.api";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
/**
 * Hook to get the custom recommendations for the health check.
 */
export var useGetHealthCheckCustomRecommendations = function () {
    var _a;
    var _b = useHealthCheckRouteParams(), _c = _b.healthCheckGuid, healthCheckGuid = _c === void 0 ? "" : _c, tenantGuid = _b.tenantGuid, userType = _b.userType;
    var _d = useGetHealthCheckRecommendationsQuery([
        userType,
        {
            queryParameters: {
                page: 1,
                pageSize: 1000
            },
            healthCheckGuid: healthCheckGuid
        },
        tenantGuid
    ]), pagedRecommendations = _d.data, pagedRecommendationsRefetch = _d.refetch, pagedRecommendationsIsFetching = _d.isFetching, pagedRecommendationsIsLoading = _d.isLoading;
    return {
        pagedRecommendations: pagedRecommendations,
        pagedRecommendationsRefetch: pagedRecommendationsRefetch,
        pagedRecommendationsIsFetching: pagedRecommendationsIsFetching,
        pagedRecommendationsIsLoading: pagedRecommendationsIsLoading,
        totalPagedRecommendationsCount: (_a = pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) !== null && _a !== void 0 ? _a : 0
    };
};
