var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import { InstallWizardContext, InstallerMode } from "app/components/installer/installer-wizard";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { TenantLicenseStatus } from "app/store/tenants";
import * as React from "react";
import { useSelector } from "react-redux";
import "./configure.scss";
var InstallerStepConfigure = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var iwc = React.useContext(InstallWizardContext);
    var _h = React.useState(), userGroupAdmins = _h[0], setUserGroupAdmins = _h[1];
    var _j = React.useState(), userWorkflows = _j[0], setUserWorkflows = _j[1];
    var _k = React.useState(), userNotifications = _k[0], setUserNotifications = _k[1];
    var _l = React.useState(false), directoryImportAllSites = _l[0], setDirectoryImportAllSites = _l[1];
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    React.useEffect(function () {
        var _a, _b, _c, _d;
        //if(currentTenantConfiguration !== undefined && currentTenantConfiguration.GUID === undefined){
        //iwc.dispatch(TenantConfigurationCurrentStore.Load());
        //}
        setUserGroupAdmins((_a = iwc.currentTenantConfiguration) === null || _a === void 0 ? void 0 : _a.DefaultTemplateApprovalGroup);
        setUserWorkflows((_b = iwc.currentTenantConfiguration) === null || _b === void 0 ? void 0 : _b.AdminstratorAccessActiveDirectoryGroup);
        setUserNotifications((_c = iwc.currentTenantConfiguration) === null || _c === void 0 ? void 0 : _c.NotificationReturnAccount);
        setDirectoryImportAllSites((_d = iwc.currentTenantConfiguration) === null || _d === void 0 ? void 0 : _d.DirectoryImportNonOrchestrySites);
    }, []);
    React.useEffect(function () {
        var _a, _b, _c;
        var isComplete = true;
        if (shouldShowApprovalUsersSelector() == true &&
            (((_a = iwc.currentTenantConfiguration) === null || _a === void 0 ? void 0 : _a.DefaultTemplateApprovalGroup) === undefined ||
                iwc.currentTenantConfiguration.DefaultTemplateApprovalGroup === "[]" ||
                iwc.currentTenantConfiguration.DefaultTemplateApprovalGroup === "")) {
            isComplete = false;
        }
        if (((_b = iwc.currentTenantConfiguration) === null || _b === void 0 ? void 0 : _b.AdminstratorAccessActiveDirectoryGroup) === undefined ||
            iwc.currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup === "[]" ||
            iwc.currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup === "") {
            isComplete = false;
        }
        if (((_c = iwc.currentTenantConfiguration) === null || _c === void 0 ? void 0 : _c.NotificationReturnAccount) === undefined ||
            iwc.currentTenantConfiguration.NotificationReturnAccount === "[]" ||
            iwc.currentTenantConfiguration.NotificationReturnAccount === "") {
            isComplete = false;
        }
        iwc.setStepProgress(props.step.key, isComplete);
    }, [
        (_a = iwc.currentTenantConfiguration) === null || _a === void 0 ? void 0 : _a.DefaultTemplateApprovalGroup,
        (_b = iwc.currentTenantConfiguration) === null || _b === void 0 ? void 0 : _b.AdminstratorAccessActiveDirectoryGroup,
        (_c = iwc.currentTenantConfiguration) === null || _c === void 0 ? void 0 : _c.NotificationReturnAccount
    ]);
    React.useEffect(function () {
        iwc.dispatch(TenantConfigurationCurrentStore.UpdateSilent(__assign(__assign({}, iwc.currentTenantConfiguration), { AdminstratorAccessActiveDirectoryGroup: userGroupAdmins, DefaultTemplateApprovalGroup: userWorkflows, NotificationReturnAccount: userNotifications, DirectoryImportNonOrchestrySites: directoryImportAllSites })));
    }, [userGroupAdmins, userWorkflows, userNotifications]);
    // React.useEffect(()=>{
    //     console.log("change", currentTenantConfiguration);
    //     iwc.setTenantConfig(currentTenantConfiguration);
    // }, [currentTenantConfiguration])
    // React.useEffect(()=>{
    //     if(props.step.canProgress){
    //         if(iwc.tenantConfig?.GUID !== undefined){
    //             if(currentTenantConfiguration.DefaultTemplateApprovalGroup !== iwc.tenantConfig.DefaultTemplateApprovalGroup || currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup !== iwc.tenantConfig.AdminstratorAccessActiveDirectoryGroup)
    //                 dispatch(TenantConfigurationCurrentStore.Update({...iwc.tenantConfig}));
    //         }
    //     }
    // },[props.step.canProgress])
    var shouldShowApprovalUsersSelector = function () {
        if ((currentTenant === null || currentTenant === void 0 ? void 0 : currentTenant.LicenseStatus) == TenantLicenseStatus.Beacon && iwc.mode == InstallerMode.Install) {
            return false;
        }
        if ((currentTenant === null || currentTenant === void 0 ? void 0 : currentTenant.LicenseStatus) != TenantLicenseStatus.Beacon && iwc.mode == InstallerMode.LicenseDowngradeBeacon) {
            return false;
        }
        return true;
    };
    return (React.createElement("div", { className: "installer-step-configure fadein-500ms" },
        React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
            React.createElement("div", { className: "kt-heading kt-heading--md" },
                React.createElement("h2", null, "Configure")),
            React.createElement("div", { className: "kt-form__section kt-form__section--first" }, ((_d = iwc.currentTenantConfiguration) === null || _d === void 0 ? void 0 : _d.GUID) !== undefined ? (React.createElement("div", { className: "kt-wizard-v2__form" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col" },
                        React.createElement("div", { className: "form-group ob-form-group" },
                            React.createElement(FormUserGroupSelect, { defaultValue: (_e = iwc.currentTenantConfiguration) === null || _e === void 0 ? void 0 : _e.AdminstratorAccessActiveDirectoryGroup, onChange: function (value) {
                                    if (value !== undefined && value !== null) {
                                        setUserGroupAdmins(value);
                                    }
                                }, returnType: "usersAndGroups", 
                                // onChange={(value)=>{ if(value !== undefined && value !== null) { console.log("value", value); iwc.dispatch(TenantConfigurationCurrentStore.UpdateSilent({...iwc.currentTenantConfiguration, AdminstratorAccessActiveDirectoryGroup:value}))}}}
                                allowMultiple: true, displayAddAsButton: true, label: "Please select users and groups that will contain all users that have administrator access to Orchestry" })))),
                shouldShowApprovalUsersSelector() ? (React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col" },
                        React.createElement("div", { className: "form-group ob-form-group" },
                            React.createElement(FormUserGroupSelect, { defaultValue: (_f = iwc.currentTenantConfiguration) === null || _f === void 0 ? void 0 : _f.DefaultTemplateApprovalGroup, onChange: function (value) {
                                    if (value !== undefined && value !== null) {
                                        setUserWorkflows(value);
                                    }
                                }, 
                                //onChange={(value)=>{ if(value !== undefined && value !== null) { iwc.dispatch(TenantConfigurationCurrentStore.UpdateSilent({...iwc.currentTenantConfiguration, DefaultTemplateApprovalGroup:value}))}}}
                                allowMultiple: true, returnType: "users", displayAddAsButton: true, label: "Please add users for the default workflow approval. The people selected here will be responsible for approving teams if workflow approval is enabled on a template." }))))) : null,
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col" },
                        React.createElement("div", { className: "form-group ob-form-group" },
                            React.createElement(FormUserGroupSelect, { defaultValue: (_g = iwc.currentTenantConfiguration) === null || _g === void 0 ? void 0 : _g.NotificationReturnAccount, onChange: function (value) {
                                    if (value !== undefined && value !== null) {
                                        setUserNotifications(value);
                                    }
                                }, 
                                //onChange={(value)=>{ if(value !== undefined && value !== null) { iwc.dispatch(TenantConfigurationCurrentStore.UpdateSilent({...iwc.currentTenantConfiguration, DefaultTemplateApprovalGroup:value}))}}}
                                allowMultiple: false, returnType: "users", displayAddAsButton: true, label: "Please select the user for email notifications from Orchestry. Notifications will be sent from this email address. Make sure that this is an account that you regularly monitor." })))))) : null))));
});
export default InstallerStepConfigure;
