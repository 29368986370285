import React from "react";
import "./view-template.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { Modal } from "react-bootstrap";
import AnimatedLoader from "app/components/common/animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import FeatureFilesService from "app/services/feature-files";
import { Controlled as CodeMirror } from "react-codemirror2";
require("codemirror/mode/xml/xml");
var ViewTemplate = React.memo(function (props) {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(), files = _b[0], setFiles = _b[1];
    var _c = React.useState(), selectedFile = _c[0], setSelectedFile = _c[1];
    var _d = React.useState(), textFileContent = _d[0], setTextFileContent = _d[1];
    var _e = React.useState(), textFileContentTempSave = _e[0], setTextFileContentTempSave = _e[1];
    var _f = React.useState(), imgFileContent = _f[0], setImgFileContent = _f[1];
    var _g = React.useState(false), previewLoading = _g[0], setPreviewLoading = _g[1];
    var _h = React.useState(false), filesLoading = _h[0], setFilesLoading = _h[1];
    var _j = React.useState(false), textPreviewEditMode = _j[0], setTextPreviewEditMode = _j[1];
    React.useEffect(function () {
        if (showModal === true) {
            setFilesLoading(true);
            FeatureFilesService.GetTemplateFileContents(props.fileName).then(function (data) {
                var root = {
                    Name: props.fileName,
                    FullName: props.fileName,
                    Children: []
                };
                data.forEach(function (di) {
                    var splitItems = di.FullName.split("/");
                    var perviousObject = root;
                    splitItems.forEach(function (so, soIndex) {
                        if (splitItems.length === soIndex + 1) {
                            perviousObject.Children.push({
                                Name: so,
                                FullName: di.FullName
                            });
                        }
                        else {
                            var folder_1 = null;
                            perviousObject.Children.forEach(function (fo) {
                                if (fo.Name === so) {
                                    folder_1 = fo;
                                }
                            });
                            if (folder_1 === null) {
                                folder_1 = {
                                    Name: so,
                                    FullName: di.FullName,
                                    Children: []
                                };
                                perviousObject.Children.push(folder_1);
                            }
                            perviousObject = folder_1;
                        }
                    });
                });
                setFiles(root);
                setFilesLoading(false);
                //console.log("PNP File contents",data ,root);
            });
        }
    }, [showModal]);
    var RecursiveFoldersAndFiles = function (item) {
        // console.log("Recursive WOrking?", item);
        if (item !== undefined) {
            if (item.Children === undefined) {
                return (React.createElement("div", { className: "file" },
                    React.createElement("div", { className: "file-name", onClick: function () {
                            setSelectedFile(item.FullName);
                        } },
                        React.createElement("div", { className: "icon" }, getIcon(item.Name)),
                        React.createElement("div", { className: "title" }, item.Name))));
            }
            else {
                return (React.createElement("div", { className: "folder" },
                    React.createElement("div", { className: "folder-name" },
                        React.createElement("div", { className: "icon" }, props.fileName === item.Name ? React.createElement("i", { className: "fas fa-archive" }) : React.createElement("i", { className: "fas fa-folder-open" })),
                        React.createElement("div", { className: "title" }, item.Name)),
                    React.createElement("div", { className: "folder-children" }, item.Children.map(function (child, childIndex) { return (React.createElement(React.Fragment, null, RecursiveFoldersAndFiles(child))); }))));
            }
        }
        return React.createElement(React.Fragment, null);
    };
    var getIcon = function (fileName) {
        if (fileName.indexOf(".xml") > -1) {
            return React.createElement("i", { className: "far fa-file-code" });
        }
        if (fileName.indexOf(".resx") > -1) {
            return React.createElement("i", { className: "far fa-file-alt" });
        }
        if (fileName.indexOf(".jpg") > -1 || fileName.indexOf(".pnp") > -1) {
            return React.createElement("i", { className: "far fa-file-image" });
        }
        return React.createElement("i", { className: "far fa-file" });
    };
    React.useEffect(function () {
        console.log("Selected Changed!", selectedFile);
        setTextFileContent(undefined);
        setImgFileContent(undefined);
        if (selectedFile !== undefined) {
            setPreviewLoading(true);
            if (selectedFile.indexOf(".jpg") > -1 || selectedFile.indexOf(".jpeg") > -1 || selectedFile.indexOf(".bmp") > -1 || selectedFile.indexOf(".gif") > -1 || selectedFile.indexOf(".png") > -1) {
                setImgFileContent("/api/FeatureFiles/GetPNPContentsFile?pnpFileName=" + props.fileName + "&fileName=" + selectedFile);
            }
            else {
                FeatureFilesService.GetFileInsidePNPFile(props.fileName, selectedFile).then(function (data) {
                    setTextFileContent(data);
                    setPreviewLoading(false);
                });
            }
        }
    }, [selectedFile]);
    // React.useEffect(()=>{
    //    if(textPreviewEditMode === true){
    //       setTextFileContentTempSave(textFileContent);
    //    }
    // },[textPreviewEditMode])
    var cancelEditMode = function () {
        if (hasEdited()) {
        }
        else {
            setTextPreviewEditMode(false);
            setTextFileContentTempSave(undefined);
        }
    };
    var enableEditMode = function () {
        setTextPreviewEditMode(true);
        setTextFileContentTempSave(textFileContent);
    };
    var hasEdited = function () {
        console.log("has edited", textFileContent !== textFileContentTempSave);
        return textFileContent !== textFileContentTempSave;
    };
    return (React.createElement("div", { className: "view-template" },
        React.createElement("div", { className: "view-template-button", onClick: function () { return setShowModal(true); } }, props.children),
        React.createElement(Modal, { centered: true, show: showModal, size: "xl", onHide: function () { return setShowModal(!showModal); }, animation: true, className: "view-template-modal", backdropClassName: "view-template-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "PNP Template Contents")),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "file-browser" },
                    React.createElement("div", { className: "files" },
                        React.createElement("div", { className: "actions" },
                            React.createElement("div", { className: "mode-title" }, "View Mode"),
                            React.createElement("div", { className: "view-modes" },
                                React.createElement("div", { className: "mode selected" }, "PnP Manifest"),
                                React.createElement("div", { className: "mode" }, "Zip Contents"))),
                        React.createElement(DynamicScrollbar, { maxHeight: 550, shouldShow: true },
                            React.createElement("div", { className: "file-list" }, filesLoading === true ? (React.createElement("div", { className: "mock-files" },
                                React.createElement(AnimatedLoader, { size: "small" }))) : files !== undefined ? (React.createElement(React.Fragment, null, RecursiveFoldersAndFiles(files))) : null))),
                    React.createElement("div", { className: "preview" },
                        React.createElement("div", { className: "preview-title" },
                            React.createElement("div", { className: "left" }, selectedFile),
                            textFileContent !== undefined ? (React.createElement("div", { className: "right", style: { display: "none" } }, textPreviewEditMode === false ? (React.createElement("div", { className: "action-button edit", onClick: function () { return enableEditMode(); } },
                                React.createElement("i", { className: "far fa-edit" }),
                                " Edit")) : (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "action-button cancel", onClick: function () { return cancelEditMode(); } }, "Cancel"),
                                hasEdited() === true ? (React.createElement("div", { className: "action-button save" },
                                    React.createElement("i", { className: "far fa-save" }),
                                    " Save")) : (React.createElement("div", { className: "action-button save" },
                                    React.createElement("i", { className: "far fa-save" }),
                                    " Save")))))) : null),
                        React.createElement("div", { className: "preview-context" + (previewLoading === true ? " preview-loading" : "") },
                            previewLoading === true ? (React.createElement("div", { className: "loading" },
                                React.createElement(AnimatedLoader, { size: "med" }))) : selectedFile === undefined ? (React.createElement("div", { className: "select-file-on-left" })) : null,
                            textFileContent !== undefined ? (React.createElement("div", { className: "text-file" + (textPreviewEditMode === true ? " edit-mode" : " display-mode") }, textPreviewEditMode === false ? (React.createElement("div", { className: "code-wrap", suppressContentEditableWarning: true },
                                React.createElement(CodeMirror, { value: textFileContent, options: {
                                        mode: "xml",
                                        // theme: 'material',
                                        lineNumbers: true
                                        // scrollbarStyle:null
                                    }, onBeforeChange: function (editor, data, value) {
                                        // this.setState({value});
                                        // setTextFileContentTempSave(value);
                                    }, onChange: function (editor, data, value) {
                                        console.log(editor, data, value);
                                    } }))) : (React.createElement("div", { className: "code-mirror-wrapper" }, textFileContentTempSave !== undefined ? (React.createElement(React.Fragment, null, textFileContentTempSave)) : // <CodeMirror
                                //    value={textFileContentTempSave}
                                //    options={{
                                //       mode: 'xml',
                                //       // theme: 'material',
                                //       lineNumbers: true,
                                //       scrollbarStyle:null
                                //    }}
                                //    onBeforeChange={(editor, data, value) => {
                                //       // this.setState({value});
                                //       setTextFileContentTempSave(value);
                                //    }}
                                //    // onChange={(editor, data, value) => {
                                //    //    console.log(editor, data, value);
                                //    // }}
                                // />
                                null)))) : null,
                            imgFileContent !== undefined ? (React.createElement("div", { className: "img-file" },
                                React.createElement("img", { src: imgFileContent, onLoad: function () { return setPreviewLoading(false); } }))) : null)))))));
});
export default ViewTemplate;
