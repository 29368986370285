import React from "react";
import { LifecycleGuestPolicyType, ReviewRecurrenceStringMap, ExecutionApprovers } from "app/store/lifecycle-guest-policies";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import * as TableList from "app/components/common/table-list";
import * as LifecycleGuestPoliciesListStore from "app/store/lifecycle-guest-policies/list";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current";
import { SubtitleButton } from "app/components/common/subtitle";
import "./lifecycle-guests-list.scss";
export var GuestPoliciesList = function () {
    var dispatch = useDispatch();
    var lifecycleGuestPolicies = useSelector(function (state) { return state.lifecycleGuestPoliciesListState.items; });
    React.useEffect(function () {
        dispatch(LifecycleGuestPoliciesListStore.Load());
    }, []);
    React.useEffect(function () {
        dispatch(LifecycleGuestPolicyCurrentStore.Clear());
    }, [lifecycleGuestPolicies]);
    return (React.createElement("div", { className: "lifecycle-guests-list" },
        React.createElement(PageHeader.SetLeftContent, { title: " Guest Review / Request Policies", subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a guest policy" })),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { exact: true, to: "/guests/policies/new/review" },
                React.createElement(SubtitleButton, { title: "New Guest Review Policy", faIcon: "plus", color: "orange" })),
            React.createElement(NavLink, { exact: true, to: "/guests/policies/new/request" },
                React.createElement(SubtitleButton, { title: "New Guest Request Policy", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: lifecycleGuestPolicies, asyncListLoadUID: LifecycleGuestPoliciesListStore.LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Policy Name"),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, null, "Type"),
                React.createElement(TableList.HeaderItem, null, "Policy Execution"),
                React.createElement(TableList.HeaderItem, { type: "actions", mobileTitle: "Actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Name", isMobileTitle: true, link: "/guests/policies/edit/{Type}/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) {
                        return item.Type === LifecycleGuestPolicyType.Review ? (React.createElement("span", { className: "type-item", "data-pw": "guest-review-type" },
                            React.createElement("i", { className: "fa-light fa-user-check" }),
                            React.createElement("p", { className: "text" }, "Guest Review"))) : item.Type === LifecycleGuestPolicyType.Request ? (React.createElement("span", { className: "type-item", "data-pw": "guest-request-type" },
                            React.createElement("i", { className: "fa-light fa-user-plus" }),
                            React.createElement("p", { className: "text" }, "Guest Request"))) : null;
                    } }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) {
                        return item.Type === "Review" && item.GuestReviewRecurrence !== undefined ? (React.createElement("span", { className: "executiontype-item", "data-pw": "policy-execution" },
                            React.createElement("i", { className: "fa-light fa-calendar-clock" }),
                            React.createElement("p", { className: "text" },
                                "Fixed Interval (",
                                ReviewRecurrenceStringMap[item.GuestReviewRecurrence],
                                ")"))) : item.Type === "Request" ? (React.createElement("span", { className: "executiontype-item", "data-pw": "policy-execution" },
                            item.AllowGuestAccessForWorkspaces === false ? (React.createElement(React.Fragment, null,
                                React.createElement("i", { className: "fa-light fa-ban" }),
                                " ",
                                React.createElement("p", { className: "text" }, "N/A"))) : null,
                            item.AllowGuestAccessForWorkspaces === true && item.RequestApprovers === ExecutionApprovers.WorkspaceOwners ? (React.createElement(React.Fragment, null,
                                React.createElement("i", { className: "fa-light fa-handshake" }),
                                " ",
                                React.createElement("p", { className: "text" }, "Delegated"))) : null,
                            item.AllowGuestAccessForWorkspaces === true && item.RequestApprovers === ExecutionApprovers.SpecificUsers ? (React.createElement(React.Fragment, null,
                                React.createElement("i", { className: "fa-light fa-circle-check" }),
                                " ",
                                React.createElement("p", { className: "text" }, "Approve Execution"))) : null)) : null;
                    } }),
                React.createElement(TableList.ContentItemActions, { onRemove: function (item) { return dispatch(LifecycleGuestPoliciesListStore.DeleteItem(item)); }, deleteCheckItemNameProperty: "Name", deleteCheckType: "lifecycle-guest-policy" })))));
};
export default GuestPoliciesList;
