import axios from "axios";
var TeamsService = /** @class */ (function () {
    function TeamsService() {
    }
    TeamsService.currentTeamsExactNameSearches = [];
    TeamsService.SearchForTeamWithExactSameName = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (TeamsService.currentTeamsExactNameSearches.length > 0) {
                TeamsService.currentTeamsExactNameSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                TeamsService.currentTeamsExactNameSearches.push({
                    id: TeamsService.currentTeamsExactNameSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/Teams/SearchForTeamWithExactSameName?teamName=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    TeamsService.currentTeamsSimularNameSearches = [];
    TeamsService.SearchForTeamWithSimularName = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (TeamsService.currentTeamsSimularNameSearches.length > 0) {
                TeamsService.currentTeamsSimularNameSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                TeamsService.currentTeamsSimularNameSearches.push({
                    id: TeamsService.currentTeamsSimularNameSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/Teams/SearchForTeamWithSimularName?teamName=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    TeamsService.currentTeamsByNameSearches = [];
    TeamsService.SearchForTeamByName = function (searchTerm) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (TeamsService.currentTeamsByNameSearches.length > 0) {
                TeamsService.currentTeamsByNameSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerm.length !== 0) {
                TeamsService.currentTeamsByNameSearches.push({
                    id: TeamsService.currentTeamsByNameSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                //source.cancel('Operation canceled by the user.');
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/Teams/SearchForTeamByName?searchTerm=" + searchTerm;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    TeamsService.GetTeamUrl = function (teamGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/GetTeamUrl?teamGuid=" + teamGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.GetTeamSPUrl = function (teamGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/GetTeamSPUrl?teamGuid=" + teamGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.GetTeamChannels = function (teamGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/GetTeamChannels?teamGuid=" + teamGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.OpenTeamUrl = function (teamGuid) {
        TeamsService.GetTeamUrl(teamGuid).then(function (url) {
            if (url !== "") {
                window.open(url);
            }
        });
    };
    TeamsService.GetAllTeams = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/GetAllTeams";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.GetTeamSpecialization = function (teamId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/GetTeamSpecialization?teamId=" + teamId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.GetAllTeamsList = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/GetAllTeamsList";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.getBase64Photo = function (uid) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/teams/GetBase64Logo?uid=" + uid;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    TeamsService.GetTeamLogosInBatch = function (data) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/Teams/GetTeamLogos";
            axiosConfig.responseType = "json";
            axiosConfig.data = data;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    TeamsService.GetTeamInfo = function (teamGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Teams/TeamInformation?teamGuid=" + teamGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    return TeamsService;
}());
export default TeamsService;
