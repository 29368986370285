import axios from "axios";
var DirectoryService = /** @class */ (function () {
    function DirectoryService() {
    }
    DirectoryService.currentDirectorySearches = [];
    DirectoryService.currentExistingSiteSearches = [];
    DirectoryService.searchDirectory = function (searchTerms) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (DirectoryService.currentDirectorySearches.length > 0) {
                DirectoryService.currentDirectorySearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerms.length !== 0) {
                DirectoryService.currentDirectorySearches.push({
                    id: DirectoryService.currentDirectorySearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/directory/Search?searchTerms=" + searchTerms;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    DirectoryService.searchExistingSites = function (searchTerms) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (DirectoryService.currentExistingSiteSearches.length > 0) {
                DirectoryService.currentExistingSiteSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerms.length !== 0) {
                DirectoryService.currentExistingSiteSearches.push({
                    id: DirectoryService.currentExistingSiteSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/directory/SearchForExistingSite?searchTerms=" + searchTerms;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    DirectoryService.addExistingSiteToDirectory = function (siteName, siteUrl, siteId, metadataJson) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("siteUrl", siteUrl);
            formData.append("siteName", siteName);
            formData.append("siteId", siteId);
            formData.append("metadataJson", metadataJson);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/directory/AddSiteToDirectory";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    DirectoryService.updateSiteMetadataWorkspace = function (siteName, siteUrl, siteId, showInDirectory, metadataJson, type, groupId) {
        if (groupId === void 0) { groupId = ""; }
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("siteUrl", siteUrl);
            formData.append("siteName", siteName);
            formData.append("siteId", siteId);
            formData.append("showInDirectory", showInDirectory);
            formData.append("requestType", type);
            formData.append("metadataJson", metadataJson);
            formData.append("groupId", groupId);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/directory/UpdateSiteMetadataWorkspace";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    DirectoryService.updateSiteMetadata = function (siteName, siteUrl, siteId, showInDirectory, metadataJson, type) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("siteUrl", siteUrl);
            formData.append("siteName", siteName);
            formData.append("siteId", siteId);
            formData.append("showInDirectory", showInDirectory);
            formData.append("requestType", type);
            formData.append("metadataJson", metadataJson);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/directory/UpdateSiteMetadata";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    DirectoryService.syncronizeItem = function (siteUrl, siteId) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("siteUrl", siteUrl);
            formData.append("siteId", siteId);
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = "/api/directory/SyncronizeItem";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    DirectoryService.startImportAll = function (importAllSites, importPrivateVisible, importPublicVisible) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/directory/ImportAllSitesWithProcessReport?importAllSites=" + importAllSites + "&importPrivate=" + importPrivateVisible + "&importPublic=" + importPublicVisible;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    DirectoryService.CancelImport = function () {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/directory/CancelImport";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                //reject(response);
            });
        });
    };
    DirectoryService.getMembersForDirectoryItem = function (siteUrl) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/directory/GetMembersForDirectoryItem?siteUrl=" + siteUrl;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    return DirectoryService;
}());
export default DirectoryService;
