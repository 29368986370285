import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
export var WorkspaceTypes = [
    {
        Title: "Microsoft Teams Workspace",
        Description: "",
        Value: "Team",
        Image: iconTeams
    },
    {
        Title: "SharePoint Online Workspace",
        Description: "",
        Value: "SharePoint",
        Image: iconSP
    },
    {
        Title: "Viva Engage Community",
        Description: "",
        Value: "VivaEngageCommunity",
        Image: iconViva
    }
];
