import AnimatedLoader from "app/components/common/animated-loader";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import OrchestryService from "app/services/orchestry";
import * as TenantCurrentStore from "app/store/tenants/current";
import moment from "moment";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./configuration.component.scss";
var OrchestryTenantsDetailsConfiguration = React.memo(function () {
    var tenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState([]), activeRegions = _a[0], setActiveRegions = _a[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
        return function () {
            dispatch(TenantCurrentStore.Clear());
        };
    }, []);
    return (React.createElement(Container, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                React.createElement(Form.Group, { controlId: "control-region" },
                    React.createElement(Form.Label, null, "Region"),
                    React.createElement("p", null, "Warning. Changing this is a complicated endevor. If you change this to a new region all the tenants current templates, requests and everything will vanish."),
                    React.createElement("p", null, "It will also fire off a part of the installer to seed the tenant with a default approval workflow and the starter templates in the new region. So when you click save - be patient."),
                    React.createElement("p", null, "Also, most users won't switch regions until their next login, usually 24 hours later."),
                    tenant === undefined || activeRegions.length === 0 ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: tenant.Region, onChange: function (value) { return dispatch(TenantCurrentStore.Update({ Region: value })); } }))),
                React.createElement(Form.Group, { controlId: "etl-starttime-offset" },
                    React.createElement(Form.Label, null, "ETL Start Time Offset"),
                    React.createElement("p", null,
                        "Number of minutes from Midnight (Local to each region) to offset ETL start.",
                        React.createElement("br", null)),
                    React.createElement("div", { className: "etl-start-time" },
                        React.createElement(Form.Control, { type: "number", className: "etl-start-number", min: "0", max: "1439", onChange: function (event) {
                                return dispatch(TenantCurrentStore.Update({
                                    PreferredEtlStartTimeOffset: event.target.value === "0" ? null : Number(event.target.value)
                                }));
                            }, value: (tenant.PreferredEtlStartTimeOffset === null || tenant.PreferredEtlStartTimeOffset === undefined ? 0 : tenant.PreferredEtlStartTimeOffset).toString() }),
                        React.createElement("div", { className: "etl-date-example" }, moment(new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(), "DD-MM-YYYY")
                            .add(tenant.PreferredEtlStartTimeOffset === null ? 0 : tenant.PreferredEtlStartTimeOffset, "minutes")
                            .format("h:mm a"))))))));
});
export default OrchestryTenantsDetailsConfiguration;
