import { Skeleton } from "@mantine/core";
import React from "react";
import { OrchestryAvatar } from "../orchestry-avatar/orchestry-avatar.component";
import { OrchestrySVGIcon } from "../orchestry-svg-icon/orchestry-svg-icon.component";
export var OrchestryWorkspaceIcon = function (_a) {
    var icon = _a.icon, workspaceName = _a.workspaceName, variant = _a.variant, isLoading = _a.isLoading;
    var isSquare = !icon;
    if (isLoading) {
        return React.createElement(Skeleton, { circle: !isSquare, width: 16, height: 16 });
    }
    return icon ? React.createElement(OrchestrySVGIcon, { isLoading: isLoading, iconNeutral: icon, size: "sm" }) : React.createElement(OrchestryAvatar, { src: icon, isSquare: !icon, variant: variant, userDisplayName: workspaceName });
};
