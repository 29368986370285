import * as React from "react";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import "./blocked-domains.scss";
import PortletAlert from "app/components/common/portlet-alert";
export var BlockedDomains = function (_a) {
    var blockedDomains = _a.blockedDomains;
    var dispatch = useDispatch();
    var _b = React.useState(), blockedDomainsText = _b[0], setblockedDomainsText = _b[1];
    var debouncedUpdate = React.useCallback(debounce(function (value) { return dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave({ LCGuestBlockedDomains: value })); }, 300), []);
    var handleChange = function (e) {
        setblockedDomainsText(e.target.value);
        debouncedUpdate(e.target.value);
    };
    React.useEffect(function () {
        setblockedDomainsText(blockedDomains);
    }, [blockedDomains]);
    return (React.createElement("div", null,
        React.createElement("p", { "data-pw": "description" }, "Choose the domains for which you want to apply consistent rules, including:"),
        React.createElement(PortletAlert, { size: "small", type: "warning", icon: "alert", message: React.createElement(React.Fragment, null,
                "Currently, domains blocked via Azure AD",
                " ",
                React.createElement("a", { href: "https://portal.azure.com/#view/Microsoft_AAD_IAM/AllowlistPolicyBlade", target: "_blank" }, "external collaboration settings"),
                " ",
                "rules must also be listed within Orchestry to be respected.") }),
        React.createElement(Row, { "data-pw": "automatically-reject" },
            React.createElement(Col, { lg: 6 },
                React.createElement("strong", null, "Automatically Reject:"),
                React.createElement("p", null, "Any requests for guests in these domains will be automatically rejected as part of provisioning, and will also prevent these guests from being reviewed in the future."),
                React.createElement("p", null, "Note: Blocked Domains will only be enforced during provisioning if a Guest Request policy is used.")),
            React.createElement(Col, { lg: 6 }, blockedDomainsText !== undefined ? (React.createElement(Form.Control, { as: "textarea", rows: 6, className: "blocked-domains-area", value: blockedDomainsText, onChange: handleChange })) : (React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 135 }))))));
};
