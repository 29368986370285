var LocalSessionService = /** @class */ (function () {
    function LocalSessionService() {
    }
    LocalSessionService.sessionIdentifier = "orchestry_";
    LocalSessionService.KeyExists = function (key) {
        if (typeof sessionStorage[LocalSessionService.sessionIdentifier + key] !== "undefined") {
            return true;
        }
        else {
            return false;
        }
    };
    LocalSessionService.Set = function (key, value) {
        sessionStorage[LocalSessionService.sessionIdentifier + key] = value;
    };
    LocalSessionService.Get = function (key) {
        return sessionStorage[LocalSessionService.sessionIdentifier + key];
    };
    LocalSessionService.GetBoolean = function (key) {
        var stringBool = sessionStorage[LocalSessionService.sessionIdentifier + key];
        if (stringBool === "True" || stringBool === "true") {
            return true;
        }
        else {
            return false;
        }
    };
    LocalSessionService.GetObject = function (key) {
        return JSON.parse(sessionStorage[LocalSessionService.sessionIdentifier + key]);
    };
    LocalSessionService.SetObject = function (key, objectToString) {
        sessionStorage[LocalSessionService.sessionIdentifier + key] = JSON.stringify(objectToString);
    };
    LocalSessionService.GetDate = function (key) {
        return new Date(sessionStorage[LocalSessionService.sessionIdentifier + key]);
    };
    LocalSessionService.SetDate = function (key, date) {
        sessionStorage[LocalSessionService.sessionIdentifier + key] = date;
    };
    LocalSessionService.ClearObjectsOnRefresh = function () {
        var _a;
        if (LocalSessionService.KeyExists("LastPage")) {
            if (LocalSessionService.Get("LastPage") === window.location.href) {
                var itemsToRemove = [];
                for (var i = 0; i < sessionStorage.length; i++) {
                    if (((_a = sessionStorage.key(i)) === null || _a === void 0 ? void 0 : _a.indexOf(LocalSessionService.sessionIdentifier)) === 0 && sessionStorage.key(i) !== LocalSessionService.sessionIdentifier + "LastPage") {
                        //@ts-ignore
                        itemsToRemove.push(sessionStorage.key(i));
                    }
                }
                for (var ii = 0; ii < itemsToRemove.length; ii++) {
                    var removeValue = "";
                    if (itemsToRemove[ii] !== undefined) {
                        if (itemsToRemove[ii] !== null) {
                            // @ts-ignore
                            removeValue = itemsToRemove[ii];
                        }
                    }
                    sessionStorage.removeItem(removeValue);
                }
            }
        }
        LocalSessionService.Set("LastPage", window.location.href);
    };
    LocalSessionService.Remove = function (key) {
        sessionStorage.removeItem(LocalSessionService.sessionIdentifier + key);
    };
    LocalSessionService.RemoveMatchingObjects = function (key) {
        var _a;
        var itemsToRemove = [];
        for (var i = 0; i < sessionStorage.length; i++) {
            if (((_a = sessionStorage.key(i)) === null || _a === void 0 ? void 0 : _a.indexOf(LocalSessionService.sessionIdentifier + key)) === 0) {
                //@ts-ignore
                itemsToRemove.push(sessionStorage.key(i));
            }
        }
        for (var ii = 0; ii < itemsToRemove.length; ii++) {
            var removeValue = "";
            if (itemsToRemove[ii] !== undefined) {
                if (itemsToRemove[ii] !== null) {
                    // @ts-ignore
                    removeValue = itemsToRemove[ii];
                }
            }
            sessionStorage.removeItem(removeValue);
        }
    };
    return LocalSessionService;
}());
export default LocalSessionService;
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
    }
    LocalStorageService.storageIdentifier = "orchestry_";
    LocalStorageService.KeyExists = function (key) {
        if (typeof localStorage[LocalStorageService.storageIdentifier + key] !== "undefined") {
            return true;
        }
        else {
            return false;
        }
    };
    LocalStorageService.Set = function (key, value) {
        localStorage[LocalStorageService.storageIdentifier + key] = value;
    };
    LocalStorageService.Get = function (key) {
        return localStorage[LocalStorageService.storageIdentifier + key];
    };
    LocalStorageService.GetBoolean = function (key) {
        var stringBool = localStorage[LocalStorageService.storageIdentifier + key];
        if (stringBool === "True" || stringBool === "true") {
            return true;
        }
        else {
            return false;
        }
    };
    LocalStorageService.GetObject = function (key) {
        return JSON.parse(localStorage[LocalStorageService.storageIdentifier + key]);
    };
    LocalStorageService.SetObject = function (key, objectToString) {
        localStorage[LocalStorageService.storageIdentifier + key] = JSON.stringify(objectToString);
    };
    LocalStorageService.GetDate = function (key) {
        return new Date(localStorage[LocalStorageService.storageIdentifier + key]);
    };
    LocalStorageService.SetDate = function (key, date) {
        localStorage[LocalStorageService.storageIdentifier + key] = date;
    };
    LocalStorageService.ClearObjectsOnRefresh = function () {
        var _a;
        if (LocalStorageService.KeyExists("LastPage")) {
            if (LocalStorageService.Get("LastPage") === window.location.href) {
                var itemsToRemove = [];
                for (var i = 0; i < localStorage.length; i++) {
                    if (((_a = localStorage.key(i)) === null || _a === void 0 ? void 0 : _a.indexOf(LocalStorageService.storageIdentifier)) === 0 && localStorage.key(i) !== LocalStorageService.storageIdentifier + "LastPage") {
                        //@ts-ignore
                        itemsToRemove.push(localStorage.key(i));
                    }
                }
                for (var ii = 0; ii < itemsToRemove.length; ii++) {
                    var removeValue = "";
                    if (itemsToRemove[ii] !== undefined) {
                        if (itemsToRemove[ii] !== null) {
                            // @ts-ignore
                            removeValue = itemsToRemove[ii];
                        }
                    }
                    localStorage.removeItem(removeValue);
                }
            }
        }
        LocalStorageService.Set("LastPage", window.location.href);
    };
    LocalStorageService.Remove = function (key) {
        localStorage.removeItem(LocalStorageService.storageIdentifier + key);
    };
    return LocalStorageService;
}());
export { LocalStorageService };
var LocalExpiringStorageService = /** @class */ (function () {
    function LocalExpiringStorageService() {
    }
    LocalExpiringStorageService.storageIdentifier = "orchestry_";
    /*  removeStorage: removes a key from localStorage and its sibling expiracy key
     params:
         key <string>     : localStorage key to remove
     returns:
         <boolean> : telling if operation succeeded
  */
    LocalExpiringStorageService.removeStorage = function (key) {
        try {
            localStorage.setItem(LocalExpiringStorageService.storageIdentifier + key, "");
            localStorage.setItem(LocalExpiringStorageService.storageIdentifier + key + "_expiresIn", "");
        }
        catch (e) {
            console.log("removeStorage: Error removing key [" + LocalExpiringStorageService.storageIdentifier + key + "] from localStorage: " + JSON.stringify(e));
            return false;
        }
        return true;
    };
    /*  getStorage: retrieves a key from localStorage previously set with setStorage().
       params:
           key <string> : localStorage key
       returns:
           <string> : value of localStorage key
           null : in case of expired key or failure
    */
    LocalExpiringStorageService.getStorage = function (key) {
        var now = Date.now(); //epoch time, lets deal only with integer
        // set expiration for storage
        var expiresIn = localStorage.getItem(LocalExpiringStorageService.storageIdentifier + key + "_expiresIn");
        if (expiresIn === undefined || expiresIn === null) {
            //@ts-ignore
            expiresIn = 0;
        }
        //@ts-ignore
        //expiresIn = Math.abs(expiresIn);
        expiresIn = new Date(expiresIn);
        //@ts-ignore
        if (expiresIn < now) {
            // Expired
            //@ts-ignore
            LocalExpiringStorageService.removeStorage(LocalExpiringStorageService.storageIdentifier + key);
            return null;
        }
        else {
            try {
                var value = localStorage.getItem(LocalExpiringStorageService.storageIdentifier + key);
                return value;
            }
            catch (e) {
                console.log("getStorage: Error reading key [" + LocalExpiringStorageService.storageIdentifier + key + "] from localStorage: " + JSON.stringify(e));
                return null;
            }
        }
    };
    /*  setStorage: writes a key into localStorage setting a expire time
       params:
           key <string>     : localStorage key
           value <string>   : localStorage value
           expires <number> : number of seconds from now to expire the key
       returns:
           <boolean> : telling if operation succeeded
    */
    //@ts-ignore
    LocalExpiringStorageService.setStorage = function (key, value, expires) {
        // if (expires === undefined || expires === null) {
        //     expires = 24 * 60 * 60; // default: seconds for 1 day
        // }
        // const now = Date.now(); //millisecs since epoch time, lets deal only with integer
        // const schedule = now + expires * 1000;
        try {
            localStorage.setItem(LocalExpiringStorageService.storageIdentifier + key, value);
            //@ts-ignore
            localStorage.setItem(LocalExpiringStorageService.storageIdentifier + key + "_expiresIn", expires);
        }
        catch (e) {
            console.log("setStorage: Error setting key [" + LocalExpiringStorageService.storageIdentifier + key + "] in localStorage: " + JSON.stringify(e));
            return false;
        }
        return true;
    };
    return LocalExpiringStorageService;
}());
export { LocalExpiringStorageService };
