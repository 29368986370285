import axios from "axios";
var LifecycleReportService = /** @class */ (function () {
    function LifecycleReportService() {
    }
    LifecycleReportService.currentReportSearches = [];
    LifecycleReportService.currentExistingSiteSearches = [];
    LifecycleReportService.searchReports = function (searchTerms, currentView) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (LifecycleReportService.currentReportSearches.length > 0) {
                LifecycleReportService.currentReportSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerms.length !== 0) {
                LifecycleReportService.currentReportSearches.push({
                    id: LifecycleReportService.currentReportSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/LifeCycleReports/Search?searchTerms=" + searchTerms + "&currentView=" + currentView;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    LifecycleReportService.searchReportOnSpecificTenant = function (tenantGuid, searchTerms) {
        return new Promise(function (resolve, reject) {
            var CancelToken = axios.CancelToken;
            var source = CancelToken.source();
            //console.log("This search id" , UsersService.currentSearches.length + 1)
            if (LifecycleReportService.currentReportSearches.length > 0) {
                LifecycleReportService.currentReportSearches.forEach(function (us) {
                    if (us.cancelled === false) {
                        us.cancenToken.cancel("Already running newer search");
                        us.cancelled = true;
                    }
                });
            }
            if (searchTerms.length !== 0) {
                LifecycleReportService.currentReportSearches.push({
                    id: LifecycleReportService.currentReportSearches.length + 1,
                    cancenToken: source,
                    cancelled: false
                });
                // First let's configure the ajax request
                var axiosConfig = {};
                axiosConfig.method = "GET";
                axiosConfig.url = "/api/LifeCycleReports/SearchTenants?searchTerms=" + searchTerms + "&tenant=" + tenantGuid;
                axiosConfig.responseType = "json";
                axiosConfig.cancelToken = source.token;
                axios(axiosConfig)
                    .then(function (response) {
                    resolve(response.data);
                })
                    .catch(function (response) {
                    //console.log("Axios Response");
                    //reject(response);
                });
            }
        });
    };
    LifecycleReportService.getMembersForReportItem = function (siteId, includeStatusAndInfo) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/LifecycleReports/GetMembersForReportItem?siteId=" + siteId + (includeStatusAndInfo !== undefined && includeStatusAndInfo === true ? "&includeStatusAndInfo=true" : "");
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    LifecycleReportService.getReportItem = function (siteId) {
        return new Promise(function (resolve, reject) {
            // First let's configure the ajax request
            var axiosConfig = {};
            axiosConfig.method = "GET";
            // Set the API URL
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/ReportItem?siteId=" + siteId;
            // Set Reponse Type
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                //console.log("Axios Response");
                reject(response);
            });
        });
    };
    // public static getWorkspaceStats = (siteId: string): Promise<ReportDayStats[]> => {
    //    return new Promise((resolve, reject) => {
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       axiosConfig.url = "/api/LifecycleReports/DetailsStats?siteId=" + siteId;
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data);
    //          })
    //          .catch((response: any) => {
    //             reject(response);
    //          });
    //    })
    // }
    // public static getWorkspaceLinks = (siteId: string, siteUrl: string, groupId: string | null): Promise<ReportLinks> => {
    //    return new Promise((resolve, reject) => {
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       axiosConfig.url = "/api/LifecycleReports/DetailsLinks?siteId=" + siteId + "&siteUrl=" + siteUrl + (groupId !== null ? "&groupId=" + groupId : "");
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data);
    //          })
    //          .catch((response: any) => {
    //             reject(response);
    //          });
    //    })
    // }
    LifecycleReportService.getTimeline = function (siteId, siteUrl, groupId, skip) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleReports/Timeline?siteId=" + siteId + "&siteUrl=" + siteUrl + (groupId !== null ? "&groupId=" + groupId : "") + (skip !== null ? "&skip=" + skip : "");
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    // public static getAssignablePolicies = (siteId: string): Promise<AssignablePolicies> => {
    //    return new Promise((resolve, reject) => {
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       axiosConfig.url = "/api/LifecycleReports/GetAvailalblePoliciesForWorkspace?siteId=" + siteId;
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data);
    //          })
    //          .catch((response: any) => {
    //             reject(response);
    //          });
    //    })
    // }
    LifecycleReportService.getDefaultPolicies = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/DefaultPolicies";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.getDefaultPoliciesForTenant = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleReports/GetDefaultPoliciesForTenant?tenant=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data.defaultArchivalPolicies);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    // public static setAssignablePolicy = (
    //    siteId: string,
    //    application:"Team"|"TeamSite"|"CommunicationSite",
    //    policyType:"Archival",
    //    setType:"Assigned"|"Inherited"|"ProtectedFromArchival",
    //    policyGuid:string|null,
    //    policyDays:number|null
    // ): Promise<boolean> => {
    //    return new Promise((resolve, reject) => {
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       axiosConfig.url = "/api/LifecyclePolicyWorkspaces/SetPolicyForWorkspace?siteId=" + siteId + "&application=" + application + "&policyType=" + policyType + "&setType=" + setType + "&policyGuid=" + policyGuid + "&policyDays=" + policyDays;
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data);
    //          })
    //          .catch((response: any) => {
    //             reject(response);
    //          });
    //    })
    // }
    // public static getHistories = (siteId: string): Promise<WorkspaceHistories> => {
    //    return new Promise((resolve, reject) => {
    //       let axiosConfig: AxiosRequestConfig = {} as AxiosRequestConfig;
    //       axiosConfig.method = "GET";
    //       axiosConfig.url = "/api/LifecycleReports/History?siteId=" + siteId;
    //       axiosConfig.responseType = "json";
    //       axios(axiosConfig)
    //          .then((response: AxiosResponse) => {
    //             resolve(response.data);
    //          })
    //          .catch((response: any) => {
    //             reject(response);
    //          });
    //    })
    // }
    LifecycleReportService.overrideLastActiveDate = function (siteId, tenantGuid, overrideDate) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/OverrideLastActiveDateAndStartLifecycleEnforcement?siteId=" + siteId + "&tenantGuid=" + tenantGuid + "&overrideDate=" + overrideDate;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.forceArchival = function (siteId) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/ForcePolicyArchival?siteId=" + siteId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.startETLForOneSite = function (siteId, tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/StartETLForOneSite?siteId=" + siteId + "&tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.getOverrideDateForArchivals = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/GetTestOverrideArchivalDate?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data.overrideDate);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.getTenantsWithOverrideDate = function (region) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/GetTenantGuidsWithLifecycleOverrideSet?region=" + region;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.setOverrideDateForArchivals = function (date, tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/SetTestOverrideArchivalDate?date=" + date + "&tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.runNightlightPolicyEnforcement = function (tenantGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecycleSettings/RunNightlightPolicyEnforcement?tenantGuid=" + tenantGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    LifecycleReportService.getWorkspaceCrawlHistory = function (siteId) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/LifecyclePolicyWorkspaces/WorkspaceCrawlingHistory?siteId=" + siteId;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return LifecycleReportService;
}());
export default LifecycleReportService;
