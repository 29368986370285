import { TemplateListActionTypes } from "app/store/templates/list/types";
import { AsyncCallType } from "app/store/async/types";
import * as FeatureListTypes from "app/store/features/list";
import * as DocumentTemplateListTypes from "app/store/document-templates/list";
import * as ChannelListTypes from "app/store/channels/list";
import * as RequestListTypes from "app/store/requests/list";
export var templateListActionMap = [
    {
        actionType: TemplateListActionTypes.Template_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/Templates",
        responseDataType: {},
        invalidates: [
            FeatureListTypes.FeatureListActionTypes.Feature_List_Load,
            DocumentTemplateListTypes.DocumentTemplateListActionTypes.DocumentTemplate_List_Load,
            ChannelListTypes.ChannelListActionTypes.Channel_List_Load,
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: TemplateListActionTypes.Template_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/Templates",
        responseDataType: {},
        invalidates: [
            FeatureListTypes.FeatureListActionTypes.Feature_List_Load,
            DocumentTemplateListTypes.DocumentTemplateListActionTypes.DocumentTemplate_List_Load,
            ChannelListTypes.ChannelListActionTypes.Channel_List_Load,
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: TemplateListActionTypes.Template_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/Templates",
        responseDataType: {},
        invalidates: [
            FeatureListTypes.FeatureListActionTypes.Feature_List_Load,
            DocumentTemplateListTypes.DocumentTemplateListActionTypes.DocumentTemplate_List_Load,
            ChannelListTypes.ChannelListActionTypes.Channel_List_Load,
            RequestListTypes.RequestListActionTypes.Request_List_Load
            // RequestCurrentTypes.RequestCurrentActionTypes.Request_Current_Load
        ]
    },
    {
        actionType: TemplateListActionTypes.Template_List_Load,
        callType: AsyncCallType.List,
        // stateLocationForArray: "templateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Templates",
        responseDataType: []
    },
    {
        actionType: TemplateListActionTypes.Template_List_Refresh,
        callType: AsyncCallType.Refresh,
        // stateLocationForArray: "templateListState.items",
        keyType: "typeName",
        ApiUrl: "/api/Templates",
        responseDataType: []
    },
    {
        actionType: TemplateListActionTypes.Template_List_LoadForRequest,
        callType: AsyncCallType.List,
        keyType: "typeName",
        ApiUrl: "/api/Templates/RequestAvailable",
        responseDataType: []
    }
];
