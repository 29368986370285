var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import AppConsent from "app/components/common/app-consent";
import { AsyncWait } from "app/components/common/async";
import { InstallWizardContext } from "app/components/installer/installer-wizard";
import { AsyncStatus } from "app/store/async";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as UserCurrentStore from "app/store/users/current";
import { UserCurrentActionTypes } from "app/store/users/current/types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./login-permissions.scss";
var InstallerStepLoginPermissions = React.memo(function (props) {
    var iwc = React.useContext(InstallWizardContext);
    var dispatch = useDispatch();
    var auth = useSelector(function (state) { return state.authState; });
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var _a = React.useState(false), showIsNotAdmin = _a[0], setShowIsNotAdmin = _a[1];
    var _b = React.useState(props.step.canProgress), showConsentSuccess = _b[0], setShowConsentSuccess = _b[1];
    // Listen for popup callback.
    // React.useEffect(() => {
    //    // var eventMethod: any = window.addEventListener ? "addEventListener" : "attachEvent";
    //    // var eventer = window[eventMethod];
    //    // var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    //    // //@ts-ignore
    //    // eventer(messageEvent, function (e) {
    //    //    //console.log('origin: ', e.origin)
    //    //    console.log('parent received message!: ', e.data);
    //    //    if (e.data !== undefined && e.data.error !== undefined) {
    //    //       setShowIsNotAdmin(true);
    //    //    }
    //    //    if (e.data !== undefined && e.data.AccessToken !== undefined) {
    //    //       setLoadingAfterConsent(true);
    //    //       dispatch(AuthStore.Login({ ...e.data, isAuthorized: true }))
    //    //       dispatch(UserCurrentStore.Load())
    //    //       AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, UserCurrentActionTypes.User_Current_Load).then(() => {
    //    //          setShowConsentSuccess(true);
    //    //          setLoadingAfterConsent(false);
    //    //          iwc.setStepProgress(props.step.key, true);
    //    //       })
    //    //    }
    //    // }, false);
    //    if (iwc.force === false) {
    //       if (currentTenant.CanSkipConsent) {
    //          setShowConsentSuccess(true);
    //          iwc.setStepProgress(props.step.key, true);
    //       }
    //    }
    // }, [])
    var primaryConsentComplete = function () {
        // This is now using the service app perimissions.
        // dispatch(UserCurrentStore.Load())
    };
    var allConsentComplete = function () {
        if (currentUser === undefined || (currentUser !== undefined && currentUser.GUID === undefined)) {
            dispatch(UserCurrentStore.Load());
            AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, UserCurrentActionTypes.User_Current_Load).then(function () {
                setShowConsentSuccess(true);
                iwc.setStepProgress(props.step.key, true);
            });
        }
        else {
            setShowConsentSuccess(true);
            iwc.setStepProgress(props.step.key, true);
        }
    };
    React.useEffect(function () {
        var _a, _b;
        if (((_a = iwc.currentTenantConfiguration) === null || _a === void 0 ? void 0 : _a.DefaultTemplateApprovalGroup) === "" || ((_b = iwc.currentTenantConfiguration) === null || _b === void 0 ? void 0 : _b.DefaultTemplateApprovalGroup) === "[]") {
            if (currentUser !== undefined && currentUser.GUID !== undefined) {
                var stringCurrentUser = "[" + JSON.stringify(currentUser) + "]";
                iwc.dispatch(TenantConfigurationCurrentStore.UpdateSilent(__assign(__assign({}, iwc.currentTenantConfiguration), { AdminstratorAccessActiveDirectoryGroup: stringCurrentUser, DefaultTemplateApprovalGroup: stringCurrentUser, NotificationReturnAccount: stringCurrentUser })));
            }
        }
    }, [currentUser]);
    return (React.createElement("div", { className: "installer-step-login-permissions fadein-500ms" },
        React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
            React.createElement("div", { className: "kt-heading kt-heading--md" },
                React.createElement("h2", null, "Login and grant consent for Orchestry")),
            React.createElement("div", { className: "kt-form__section kt-form__section--first" },
                React.createElement("div", { className: "kt-wizard-v2__form" },
                    React.createElement(AppConsent, { forced: props.step.canProgress === true ? false : iwc.force, onPrimaryComplete: function () { return primaryConsentComplete(); }, onAllComplete: function () { return allConsentComplete(); }, serviceAppConfig: iwc.serviceAppConfig, licenseChange: iwc.licenseChange === null ? undefined : iwc.licenseChange }))))));
});
export default InstallerStepLoginPermissions;
