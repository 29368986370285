export var TemplateListActionTypes;
(function (TemplateListActionTypes) {
    TemplateListActionTypes["Template_List_Load"] = "Template_List_Load";
    TemplateListActionTypes["Template_List_Refresh"] = "Template_List_Refresh";
    TemplateListActionTypes["Template_List_LoadForRequest"] = "Template_List_LoadForRequest";
    TemplateListActionTypes["Template_List_Clear"] = "Template_List_Clear";
    TemplateListActionTypes["Template_List_Item_Add"] = "Template_List_Item_Add";
    TemplateListActionTypes["Template_List_Item_Delete"] = "Template_List_Item_Delete";
    TemplateListActionTypes["Template_List_Item_Update"] = "Template_List_Item_Update";
    TemplateListActionTypes["Template_List_Item_Can_Delete"] = "Template_List_Item_Can_Delete";
    TemplateListActionTypes["Template_List_SetIsLoaded"] = "Template_List_SetIsLoaded";
})(TemplateListActionTypes || (TemplateListActionTypes = {}));
