var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./form-footeruploadbox.scss";
import { useDropzone } from "react-dropzone";
var FooterImageUploadBox = React.memo(function (props, state) {
    //class FormImageUploadBox extends React.Component<IProps> {
    var _a = React.useState(props.defaultBase64Image ? props.defaultBase64Image : ""), image = _a[0], setImage = _a[1];
    var onDrop = React.useCallback(function (acceptedFiles) {
        // Do something with the files
        //console.log(acceptedFiles);
        //let readBytesCompleted = 0;
        acceptedFiles.forEach(function (acceptedFile) {
            // Ensure it's an image
            if (acceptedFile.type.match(/^image\/(?!tiff?).*/)) {
                var reader = new FileReader();
                reader.onload = function (event) {
                    var _a;
                    var image = new Image();
                    image.onload = function (imageEvent) {
                        var _a;
                        // Resize the image
                        var max_width = 300;
                        var max_height = 124;
                        var canvas = document.createElement("canvas"), width = image.width, height = image.height;
                        if (width > height) {
                            if (width > max_width) {
                                height *= max_width / width;
                                width = max_width;
                            }
                        }
                        else if (height > max_height) {
                            width *= max_height / height;
                            height = max_height;
                        }
                        canvas.width = width;
                        canvas.height = height;
                        (_a = canvas.getContext("2d")) === null || _a === void 0 ? void 0 : _a.drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL("image/png");
                        //console.log("resized image", dataUrl);
                        setImage(String(dataUrl));
                    };
                    //console.log("original image", String(event.target?.result));
                    image.src = String((_a = event.target) === null || _a === void 0 ? void 0 : _a.result);
                };
                reader.readAsDataURL(acceptedFile);
            }
            else {
                alert("Sorry! ".concat("'.".concat(acceptedFile.type.split("/")[1], "'") || "this", " is not a compatible image format."));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        //console.log("Image Resized!", image);
        props.onDrop(image);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);
    var dzOptions = {
        onDrop: onDrop
    };
    //if(props.allowMultiple !== undefined)
    //    dzOptions.multiple = props.allowMultiple;
    // 5000000 = 5mb
    dzOptions.maxSize = 3000000;
    dzOptions.multiple = false;
    dzOptions.onDropRejected = function (event) {
        alert("File too big. Please upload an image smaller than 3mb");
    };
    var _b = useDropzone(dzOptions), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps;
    //const {getRootProps, getInputProps} = useDropzone();
    var getLink = function () {
        var _a;
        var linkInput = window.prompt("Enter Image Link");
        if (!(linkInput === null || linkInput === void 0 ? void 0 : linkInput.match(/https?:\/\//))) {
            window.alert("Please include a valid protocol http(s)://");
            return;
        }
        (_a = props.setImageLink) === null || _a === void 0 ? void 0 : _a.call(props, linkInput);
    };
    return (React.createElement("div", __assign({ className: "form-footer-imageuploadbox ".concat(image !== "" ? "has-image" : "", " ").concat(props.imageLink !== "" ? "has-link" : "", " ").concat(props.layout === "image" ? "no-text" : "") }, getRootProps()), image === "" ? (React.createElement(React.Fragment, null,
        React.createElement("input", __assign({}, getInputProps())),
        React.createElement("div", { className: "icon" },
            React.createElement("i", { className: "la la-upload" })),
        React.createElement("p", { style: {
                padding: "0 5px",
                fontSize: 12,
                textAlign: "center"
            } }, "Upload or Drag Image Here"))) : (
    // <div className="image-preview" style={{backgroundImage:"url('" + image + "')"}}></div>
    React.createElement("div", { className: "image-preview fadein-2s" },
        React.createElement("img", { title: "".concat(props.imageLink !== "" ? props.imageLink : ""), src: image, alt: "preview" }),
        React.createElement("div", { className: "delete-icon", onClick: function () {
                var _a;
                setImage("");
                (_a = props.setImageLink) === null || _a === void 0 ? void 0 : _a.call(props, "");
            } },
            React.createElement("i", { className: "la la-trash" })),
        props.imageLink === "" ? (React.createElement("div", { title: "Add Image Link", className: "link-icon", onClick: function () { return getLink(); } },
            React.createElement("i", { className: "la la-link" }))) : (React.createElement("div", { title: "Remove Link", className: "link-icon", onClick: function () { var _a; return (_a = props.setImageLink) === null || _a === void 0 ? void 0 : _a.call(props, ""); } },
            React.createElement("i", { className: "la la-unlink" })))))));
});
export default FooterImageUploadBox;
