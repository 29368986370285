import { DocumentTemplateCurrentActionTypes } from "./types";
export function Load(documentTemplate) {
    if (documentTemplate === undefined)
        documentTemplate = {};
    //console.log("Load Current Template > ", template)
    return {
        type: DocumentTemplateCurrentActionTypes.DocumentTemplate_Current_Load,
        payload: documentTemplate
    };
}
export function Update(documentTemplate) {
    return {
        type: DocumentTemplateCurrentActionTypes.DocumentTemplate_Current_Update,
        payload: documentTemplate
    };
}
export function Clear() {
    return {
        type: DocumentTemplateCurrentActionTypes.DocumentTemplate_Current_Clear
    };
}
export var documentTemplateCurrentMiddlware = function (store, next, action) {
    next(action);
};
