import * as React from "react";
import "./lifecycle-report.scss";
import InsightsReport from "app/components/insights/common/report";
import ReportDetails from "app/components/insights/workspaces/report-details";
import Subtitle from "app/components/common/subtitle";
import { useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { ReportDatasource, ReportSearchSlotName } from "app/store/lifecycle-report";
var WorkspaceListing = React.memo(function (props) {
    //const addDiv = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    var _a = React.useState(), currentSiteId = _a[0], setCurrentSiteId = _a[1];
    var _b = React.useState(), currentReportItem = _b[0], setCurrentReportItem = _b[1];
    var dispatch = useDispatch();
    //let history = useHistory();
    React.useEffect(function () {
        //alert("site id " + props.match.params.siteId);
        if (props.match.params.siteId !== "insight") {
            setCurrentSiteId(props.match.params.siteId);
        }
    }, [props.match.params.siteId]);
    var navigateToDetails = function (reportItem) {
        setCurrentReportItem(reportItem);
        //console.log("Navigate to ", reportItem);
        // props.history.replace({ pathname: `/lifecycle/listing/${reportItem.SiteId}`})
    };
    React.useEffect(function () {
        dispatch(LifecycleReportStore.Clear(ReportSearchSlotName.GuestMembershipsSlot));
        dispatch(LifecycleReportStore.Clear(ReportSearchSlotName.GuestInsightsSlot));
    }, []);
    return (React.createElement("div", { className: "lifecycle-listing" },
        React.createElement(Subtitle, { title: "Lifecycle", subtitle: React.createElement(React.Fragment, null, "Workspace Listings") }),
        React.createElement("div", { style: currentSiteId !== undefined ? { overflow: "hidden", height: 0 } : {} },
            React.createElement(InsightsReport, { NavigateToDetails: navigateToDetails, ActionParam: props.match.params.siteId, FilterParam: props.match.params.param2, Datasource: ReportDatasource.CosmosDB, BasePath: "lifecycle", SubPath: "listing", NamePrefix: "Workspace", ResultName: "workspace", ShowArchived: true, ShowExport: true, ShowDeleted: false, Slot: ReportSearchSlotName.WorkspaceInsightsSlot, NoResultSubText: "Current criteria have no matches" })),
        currentSiteId !== undefined && React.createElement(ReportDetails, { siteId: currentSiteId, reportItem: currentReportItem })));
});
export default WorkspaceListing;
