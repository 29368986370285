import React from "react";
import * as PageHeader from "app/components/common/page-header";
import { SubtitleButton } from "app/components/common/subtitle";
import FormValidationContext, { FormValidatorRollup } from "app/components/common/form-validation";
import { useSelector, useDispatch } from "react-redux";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as LifecycleGuestPoliciesListStore from "app/store/lifecycle-guest-policies/list";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
import "./lifecycle-guest-settings.scss";
import { GuestRequestCard } from "./cards/card-guest-request";
import { GuestReviewCard } from "./cards/card-guest-review";
import { BlockedDomains } from "./cards/card-blocked-domains";
import { DefaultInactivity } from "./cards/card-default-inactivity";
import CardNightlyGuestDataExport from "./cards/card-nightly-guest-data-export";
export var GuestSettings = React.memo(function () {
    var dispatch = useDispatch();
    var _a = React.useState(false), isValid = _a[0], setIsValid = _a[1];
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var lifecycleGuestPolicies = useSelector(function (state) { return state.lifecycleGuestPoliciesListState.items; });
    var _b = React.useState("guest-request"), hash = _b[0], setHash = _b[1];
    var save = function () {
        dispatch(TenantConfigurationCurrentStore.UpdateSaveToServer());
    };
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
    };
    React.useEffect(function () {
        if (window.location.hash !== "")
            setHash(window.location.hash);
    }, []);
    var getRequestTemplateOptions = function () {
        return (lifecycleGuestPolicies
            .filter(function (policy) { return policy.Type === LifecycleGuestPolicyType.Request; })
            .map(function (policy, index) {
            return { title: policy.Name, value: policy.GUID, id: index + "" };
        }) || []);
    };
    var getReviewTemplateOptions = function () {
        return (lifecycleGuestPolicies
            .filter(function (policy) { return policy.Type === LifecycleGuestPolicyType.Review; })
            .map(function (policy, index) {
            return { title: policy.Name, value: policy.GUID, id: index + "" };
        }) || []);
    };
    React.useEffect(function () {
        dispatch(LifecycleGuestPoliciesListStore.Load());
        dispatch(TenantConfigurationCurrentStore.Load());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: "lifecycle-guest-settings fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Guest Management Settings" }),
        React.createElement(PageHeader.SetRightContent, null, isValid === true ? (React.createElement("div", { style: { display: "inline-block" } },
            React.createElement(SubtitleButton, { title: "Save", variant: "success", onClick: function () { return save(); } }))) : (React.createElement("div", { style: { display: "inline-block" } },
            React.createElement(SubtitleButton, { title: "Save", variant: "secondary" })))),
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setIsValid(isValid);
            }, UniqueIdentifier: "lifecycle-guest-settings" },
            React.createElement(Row, null,
                React.createElement(Col, { md: 6 },
                    React.createElement(Tab.Container, { defaultActiveKey: hash, id: "lifecycle-guest-tabs" },
                        React.createElement(Portlet, { "data-pw": "default-guest-settings" },
                            React.createElement(PortletHeader, { title: "Default Guest Settings" }),
                            React.createElement(PortletBody, null,
                                React.createElement("p", { "data-pw": "settings-description" }, "You can specify the default Guest settings for Groups without an attached policy (or groups created outside of Orchestry)."),
                                React.createElement(PortletAlert, { size: "small", type: "warning", icon: "alert", message: "Any assignment of policies will not be reflected in the workspace reports until after tonight's crawl." }),
                                React.createElement("h4", null, "Default Guest Policy Settings"),
                                React.createElement("div", { className: "nav nav-tabs nav-tabs-line ", "data-pw": "nav-tabs" },
                                    React.createElement(Nav, { as: "ul" }, lifecycleGuestPolicies === undefined || currentTenantConfiguration === undefined ? (React.createElement("div", { className: "skeleton-nav-item", style: { marginBottom: "15px" } },
                                        React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 20 }))) : (React.createElement(React.Fragment, null,
                                        React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                                setHashAnchor("guest-request");
                                            }, key: "guest-request", "data-pw": "guest-request-tab" },
                                            React.createElement(Nav.Link, { eventKey: "guest-request" },
                                                React.createElement("span", null, "Guest Request"),
                                                React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: "lifecycle-guest-settings", Category: "guest-request" }))),
                                        React.createElement(Nav.Item, { as: "li", onClick: function (event) {
                                                setHashAnchor("guest-review");
                                            }, key: "guest-review", "data-pw": "guest-review-tab" },
                                            React.createElement(Nav.Link, { eventKey: "guest-review" },
                                                React.createElement("span", null, "Guest Review"),
                                                React.createElement(FormValidatorRollup, { ContextUniqueIdentifier: "lifecycle-guest-settings", Category: "guest-review" }))))))),
                                lifecycleGuestPolicies === undefined ||
                                    currentTenantConfiguration.LCGuestRequestDefaultEnabled === undefined ||
                                    currentTenantConfiguration.LCGuestReviewDefaultEnabled === undefined ? (React.createElement(Row, null,
                                    React.createElement(Col, { md: 12 },
                                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 100 })))) : (React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "guest-request", key: "guest-request", "data-pw": "guest-request-content" },
                                        React.createElement(GuestRequestCard, { defaultTemplate: currentTenantConfiguration.LCGuestRequestDefaultTemplateGUID, requestTemplateList: getRequestTemplateOptions(), dropdownEnabled: currentTenantConfiguration.LCGuestRequestDefaultEnabled })),
                                    React.createElement(Tab.Pane, { eventKey: "guest-review", key: "guest-review", "data-pw": "guest-review-content" },
                                        React.createElement(GuestReviewCard, { defaultTemplate: currentTenantConfiguration.LCGuestReviewDefaultTemplateGUID, requestTemplateList: getReviewTemplateOptions(), dropdownEnabled: currentTenantConfiguration.LCGuestReviewDefaultEnabled }))))))),
                    React.createElement(CardNightlyGuestDataExport, null)),
                React.createElement(Col, null,
                    React.createElement(Portlet, { "data-pw": "blocked-domains" },
                        React.createElement(PortletHeader, { title: "Blocked Domains" }),
                        React.createElement(PortletBody, null,
                            React.createElement(BlockedDomains, { blockedDomains: currentTenantConfiguration.LCGuestBlockedDomains }))),
                    React.createElement(Portlet, { "data-pw": "guest-inactivity" },
                        React.createElement(PortletHeader, { title: "Guest Inactivity" }),
                        React.createElement(PortletBody, null,
                            React.createElement(DefaultInactivity, { inactivity: currentTenantConfiguration.LCDefaultGuestInactivityDays }))))))));
});
export default GuestSettings;
