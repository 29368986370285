import { Card, Group } from "@mantine/core";
import { ComparisonScore as ComparisonScoreEnum } from "appv2/api/health-checks/comparison-score.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryLineChart } from "appv2/components/orchestry-line-chart/orchestry-line-chart.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { omitKeys } from "appv2/utils/object.utils";
import { toDetailedPercentageLocaleString } from "appv2/utils/string.utils";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
import { mapComparisonScoreToIntlId } from "./benchmarks-section-tiles.utils";
export var MicrosoftSecureScoreTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, isMobile = _a.isMobile, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.Benchmarks, _g = _f === void 0 ? {} : _f, _h = _g.MicrosoftSecureScore, _j = _h === void 0 ? {} : _h, Total = _j.Total, PreviousValue = _j.PreviousValue, DeltaPercentage = _j.DeltaPercentage, Data = _j.Data, YAxisRange = _j.YAxisRange, ComparisonScore = _j.ComparisonScore, TypicalOrganizationsComparisonValue = _j.TypicalOrganizationsComparisonValue, DataCoverageDaysCount = _j.DataCoverageDaysCount, DataCoverageAvailable = _j.DataCoverageAvailable;
    var dataWithoutDeltas = Data === null || Data === void 0 ? void 0 : Data.map(function (item) { return omitKeys(item, ["PreviousValue", "DeltaValue", "DeltaPercentage"]); });
    return (React.createElement(Card, { variant: "default", h: "100%", p: 24, miw: "254px" },
        React.createElement(Group, { gap: "8px" },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "sm", variant: "light-circle", colorVariant: "blue-7", iconClassName: ORCH_ICONS.shieldCheck }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "16", fw: 500, lh: "28px" }, intl.formatMessage({ id: "page.health_check.microsoft_secure_score_tile.label", defaultMessage: "Microsoft Secure Score" }))),
        React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "36", fw: 500, lh: "44px", mt: 24, mb: 8 },
            toDetailedPercentageLocaleString(Total),
            "%"),
        React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, align: "Flex-start", lastPeriodValue: PreviousValue, deltaPercentage: DeltaPercentage, daysAgoCount: DataCoverageDaysCount, dataCoverageAvailable: DataCoverageAvailable }),
        React.createElement(OrchestryLineChart, { mt: 24, isLoading: isLoading, curveType: "linear", yAxisRange: YAxisRange, height: "110px", data: dataWithoutDeltas !== null && dataWithoutDeltas !== void 0 ? dataWithoutDeltas : [], lastPeriodData: Data, series: [{ name: "MeasureValue", color: "var(--mantine-color-blue-6)" }], dataKey: "date", miw: 175 }),
        React.createElement(Group, { mt: 24, mb: 8, gap: "8px", align: "Flex-start", wrap: "nowrap" },
            React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-gray-6)", isLoading: isLoading, fontSize: 16, lineHeight: "16px", iconClassName: ORCH_ICONS.infoCircle }),
            React.createElement(OrchestryText, { isLoading: isLoading, fz: 12, c: "text-primary.0", lh: "18px" }, intl.formatMessage({
                id: mapComparisonScoreToIntlId("page.health_check.microsoft_secure_score_tile.comparison_score_", ComparisonScore !== null && ComparisonScore !== void 0 ? ComparisonScore : ComparisonScoreEnum.Low),
                defaultMessage: "Compare your benchmark against a company of your size"
            }))),
        React.createElement(Group, { gap: 4 },
            React.createElement(OrchestryFontIcon, { isLoading: isLoading, iconClassName: ORCH_ICONS.building06, color: "var(--mantine-color-gray-6)", fontSize: "12px", lineHeight: "12px" }),
            React.createElement(OrchestryHighlight, { isLoading: isLoading, fz: 12, c: "text-primary.0", lh: "18px", fw: 200, highlight: "(".concat(toDetailedPercentageLocaleString(TypicalOrganizationsComparisonValue), "%)"), highlightStyles: { fontWeight: 600, backgroundColor: "var(--mantine-color-text-primary-0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" } }, intl.formatMessage({
                id: "page.health_check.benchmark.threshold_label",
                defaultMessage: "Typical organizations of your size {count}"
            }, { count: "(".concat(toDetailedPercentageLocaleString(TypicalOrganizationsComparisonValue), "%)") })))));
};
