import React from "react";
import { GuestManagementUserStatus } from "app/store/lifecycle-guest";
import { GetGuestStatusEnum } from "../guest-status-box";
import { useIntl } from "react-intl";
var GuestStatusText = React.memo(function (props) {
    var status = GetGuestStatusEnum(props.cosmosUser, props.daysInactive);
    var intl = useIntl();
    var getStatus = function () {
        if (status === GuestManagementUserStatus.Active) {
            return intl.formatMessage({ id: "insights.guests.status.active", defaultMessage: "Active" });
        }
        if (status === GuestManagementUserStatus.Inactive) {
            return intl.formatMessage({ id: "insights.guests.status.inactive", defaultMessage: "Inactive" });
        }
        if (status === GuestManagementUserStatus.PendingAcceptance) {
            return intl.formatMessage({ id: "insights.guests.status.not_redeemed", defaultMessage: "Not Redeemed" });
        }
        if (status === GuestManagementUserStatus.PendingApproval) {
            return intl.formatMessage({ id: "insights.guests.status.pending_approval", defaultMessage: "Pending Approval" });
        }
        if (status === GuestManagementUserStatus.Deleted) {
            return intl.formatMessage({ id: "insights.guests.status.deleted", defaultMessage: "Deleted" });
        }
    };
    return React.createElement("span", { className: "guest-status-text" }, getStatus());
});
export default GuestStatusText;
