var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import SharePointService from "app/services/sharepoint";
import * as React from "react";
import { Collapse, Modal } from "react-bootstrap";
import AnimatedLoader from "../animated-loader";
import DynamicScrollbar from "../dynamic-scollbar";
import "./mananged-metadata-picker.scss";
import highlightWords from "app/utils/highlightWords";
var ManagedMetadataPicker = React.memo(function (props) {
    var view = React.useState(props.view !== undefined ? props.view : "input-picker")[0];
    var _a = React.useState(false), loadingTerms = _a[0], setLoadingTerms = _a[1];
    var _b = React.useState(undefined), metadataTerms = _b[0], setMetadataTerms = _b[1];
    var _c = React.useState([]), selectedNodes = _c[0], setSelectedNodes = _c[1];
    var _d = React.useState(props.mmdJsonValue !== undefined && props.mmdJsonValue !== null ? props.mmdJsonValue : ""), lastOnChangeJsonString = _d[0], setLastOnChangeJsonString = _d[1];
    var _e = React.useState(false), dropdownOpen = _e[0], setDropdownOpen = _e[1];
    var _f = React.useState(false), doneIntialLoad = _f[0], setDoneInitialLoad = _f[1];
    var startNode = React.useState(props.startNodeJson !== undefined && props.startNodeJson !== null && props.startNodeJson.indexOf("[") > -1 ? JSON.parse(props.startNodeJson)[0] : undefined)[0];
    var _g = React.useState(false), showPickerModal = _g[0], setShowPickerModal = _g[1];
    var allowNonAvailableTagging = React.useState(props.allowNonAvailableToBeTagged !== undefined ? props.allowNonAvailableToBeTagged : true)[0];
    var setSelectedNodesFromJson = function () {
        //console.log("set selected nodes", props.mmdJsonValue);
        if (props.mmdJsonValue !== undefined) {
            if (props.mmdJsonValue !== null && props.mmdJsonValue !== "") {
                var rawStartNodes = JSON.parse(props.mmdJsonValue);
                var tempSelectedNodes_1 = [];
                if (Array.isArray(rawStartNodes)) {
                    rawStartNodes.forEach(function (node) {
                        var foundNode = findNodeInTree(node.Id);
                        if (foundNode !== null) {
                            tempSelectedNodes_1.push(foundNode);
                        }
                    });
                    setSelectedNodes(tempSelectedNodes_1);
                }
            }
            else {
                setSelectedNodes([]);
            }
        }
    };
    React.useEffect(function () {
        //console.log("metadata nodes loaded", metadataTerms === undefined, props.mmdJsonValue, doneIntialLoad);
        if (metadataTerms !== undefined) {
            if (props.mmdJsonValue !== undefined && doneIntialLoad === false) {
                //try {
                setSelectedNodesFromJson();
                //} catch {}
            }
            setDoneInitialLoad(true);
        }
    }, [metadataTerms]);
    React.useEffect(function () {
        if (props.mmdJsonValue !== undefined && doneIntialLoad === true) {
            if (lastOnChangeJsonString !== props.mmdJsonValue) {
                setSelectedNodesFromJson();
            }
        }
    }, [props.mmdJsonValue]);
    React.useEffect(function () {
        if (doneIntialLoad === true) {
            var tempNodeWithoutChildren_1 = [];
            if (selectedNodes !== undefined && selectedNodes !== null) {
                selectedNodes.forEach(function (node) {
                    tempNodeWithoutChildren_1.push(__assign(__assign({}, node), { Terms: [] }));
                });
            }
            var jsonReturn = JSON.stringify(tempNodeWithoutChildren_1);
            if (lastOnChangeJsonString !== jsonReturn) {
                setLastOnChangeJsonString(jsonReturn);
                props.onChange(jsonReturn);
            }
        }
    }, [selectedNodes]);
    React.useEffect(function () {
        setLoadingTerms(true);
        if (startNode !== undefined) {
            if (startNode.NodeType === "TermSet") {
                SharePointService.GetTaxonomyTree(startNode.Id).then(function (treeData) {
                    setLoadingTerms(false);
                    setMetadataTerms(treeData);
                });
            }
            if (startNode.NodeType === "Term") {
                SharePointService.GetTaxonomyTree(startNode.TermSetId, startNode.Id).then(function (treeData) {
                    setLoadingTerms(false);
                    setMetadataTerms(treeData);
                });
            }
        }
        else {
            SharePointService.GetTaxonomyTree().then(function (treeData) {
                setLoadingTerms(false);
                setMetadataTerms(treeData);
            });
        }
    }, []);
    var MetadataTreeType = [
        {
            Type: "TermGroup",
            FaIcon: "la-folder"
        },
        {
            Type: "TermSet",
            FaIcon: "la-tags"
        },
        {
            Type: "Term",
            FaIcon: "la-tag"
        }
    ];
    var toggleSelectedNode = function (node) {
        if (node !== undefined && node.NodeType !== undefined) {
            if (node.NodeType !== "TermGroup") {
                if (allowNonAvailableTagging === true || (allowNonAvailableTagging === false && node.IsAvailableForTagging === true)) {
                    if (props.allowMultiple === true) {
                        if (selectedNodes.some(function (n) { return n.Name === node.Name; })) {
                            var newArray = selectedNodes.filter(function (n) { return (n.Name === node.Name ? false : true); });
                            setSelectedNodes(newArray);
                        }
                        else {
                            setSelectedNodes(__spreadArray(__spreadArray([], selectedNodes, true), [node], false));
                        }
                    }
                    else {
                        if (selectedNodes.some(function (n) { return n.Name === node.Name; })) {
                            var newArray = selectedNodes.filter(function (n) { return (n.Name === node.Name ? false : true); });
                            setSelectedNodes([]);
                        }
                        else {
                            setSelectedNodes([node]);
                        }
                    }
                }
            }
        }
    };
    var getNodeIcon = function (node) {
        var matchType = MetadataTreeType.filter(function (tt) { return tt.Type === node.NodeType; })[0];
        return React.createElement("i", { className: "la " + (matchType === null || matchType === void 0 ? void 0 : matchType.FaIcon) });
    };
    var findNodeInTree = function (nodeId) {
        var resultsArray = [];
        if (metadataTerms !== undefined && metadataTerms.length > 0) {
            var recursiveTermSearch_1 = function (term) {
                var termMatch = false;
                if (term.Id !== undefined && term.Id !== null && term.Id !== "") {
                    if (term.Id.toLowerCase() === nodeId) {
                        termMatch = true;
                    }
                }
                if (termMatch) {
                    resultsArray.push(term);
                }
                term.Terms.forEach(function (subTerm) {
                    recursiveTermSearch_1(subTerm);
                });
            };
            metadataTerms.forEach(function (termGroup) {
                termGroup.TermSets.forEach(function (termSet) {
                    recursiveTermSearch_1(termSet);
                });
            });
        }
        if (resultsArray.length > 0) {
            return resultsArray[0];
        }
        else {
            return null;
        }
    };
    var aboveOrIsStartingNode = function (node) {
        var isAboveOrIs = false;
        if (startNode !== undefined) {
            // All groups are to be hidden when a starting node is specified
            if (node.NodeType === "TermGroup") {
                isAboveOrIs = true;
            }
            if (node.NodeType === "TermSet" && (startNode.NodeType === "TermSet" || startNode.NodeType === "Term")) {
                isAboveOrIs = true;
            }
            if (node.NodeType === "Term" && startNode.NodeType === "Term") {
                var checkChildrenIfMatch_1 = function (checkTerm) {
                    var checkTermIsAboveOrIs = false;
                    if (checkTerm.Id === startNode.Id) {
                        checkTermIsAboveOrIs = true;
                    }
                    else {
                        if (checkTerm.Terms !== undefined && checkTerm.Terms !== null && checkTerm.Terms.length > 0) {
                            checkTerm.Terms.forEach(function (ct) {
                                if (checkChildrenIfMatch_1(ct)) {
                                    checkTermIsAboveOrIs = true;
                                }
                            });
                        }
                    }
                    return checkTermIsAboveOrIs;
                };
                isAboveOrIs = checkChildrenIfMatch_1(node);
            }
        }
        return isAboveOrIs;
    };
    var isNodeAvailableForTagging = function (node) {
        // if(startNode !== undefined && node.Id === startNode.Id){
        //    return false;
        // }
        if (aboveOrIsStartingNode(node)) {
            return false;
        }
        if (node.NodeType !== "TermGroup") {
            if (allowNonAvailableTagging === true || (allowNonAvailableTagging === false && node.IsAvailableForTagging === true)) {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "mananged-metadata-picker" + (dropdownOpen === true ? " dropdown-open" : "") }, loadingTerms === true ? (React.createElement("div", { className: "tree-loader form-control" },
        React.createElement(AnimatedLoader, { size: "xxsmall" }))) : view === "input-picker" ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "input-picker" },
            metadataTerms !== undefined ? (React.createElement(ManagedMMDPickerInput, { getNodeIcon: getNodeIcon, selectedNodes: selectedNodes, termGroups: metadataTerms, placeholder: props.placeholder, toggleSelectedNode: toggleSelectedNode, allowMultiple: props.allowMultiple, allowTextEntry: props.allowTextEntry, setDropdownOpen: setDropdownOpen, isNodeAvailableForTagging: isNodeAvailableForTagging })) : null,
            React.createElement("div", { className: "launch-tree-modal", onClick: function () { return setShowPickerModal(!showPickerModal); } },
                React.createElement("i", { className: "la la-tags" }))),
        React.createElement(Modal, { centered: true, show: showPickerModal, onHide: function () { return setShowPickerModal(!showPickerModal); }, animation: true, className: "tax-tree-modal", backdropClassName: "tax-tree-modal-bg" },
            React.createElement("div", { className: "mananged-metadata-picker" },
                React.createElement("div", { className: "picker-tree" },
                    metadataTerms !== undefined ? (React.createElement(ManagedMMDPickerInput, { getNodeIcon: getNodeIcon, selectedNodes: selectedNodes, termGroups: metadataTerms, placeholder: props.placeholder, toggleSelectedNode: toggleSelectedNode, allowMultiple: props.allowMultiple, allowTextEntry: props.allowTextEntry, setDropdownOpen: setDropdownOpen, isNodeAvailableForTagging: isNodeAvailableForTagging })) : null,
                    React.createElement("div", { className: "node-tree" },
                        React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 400 },
                            React.createElement("div", { className: "root-node" }, metadataTerms !== undefined
                                ? metadataTerms.map(function (termGroup) { return (React.createElement(ManagedMetadataTreeNode, { node: termGroup, treeTypes: MetadataTreeType, toggleSelectedNode: toggleSelectedNode, selectedNodes: selectedNodes, startNode: startNode, aboveOrIsStartingNode: aboveOrIsStartingNode, isNodeAvailableForTagging: isNodeAvailableForTagging })); })
                                : null)))),
                React.createElement("div", { className: "button-actions" },
                    React.createElement("div", { className: "done-button", onClick: function () { return setShowPickerModal(!showPickerModal); } }, "Done")))))) : view === "tree-picker" ? (React.createElement("div", { className: "picker-tree" },
        metadataTerms !== undefined ? (React.createElement(ManagedMMDPickerInput, { getNodeIcon: getNodeIcon, selectedNodes: selectedNodes, termGroups: metadataTerms, placeholder: props.placeholder, toggleSelectedNode: toggleSelectedNode, allowMultiple: props.allowMultiple, allowTextEntry: props.allowTextEntry, setDropdownOpen: setDropdownOpen, isNodeAvailableForTagging: isNodeAvailableForTagging })) : null,
        React.createElement("div", { className: "node-tree" },
            React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 400 },
                React.createElement("div", { className: "root-node" }, metadataTerms !== undefined
                    ? metadataTerms.map(function (termGroup) { return (React.createElement(ManagedMetadataTreeNode, { node: termGroup, treeTypes: MetadataTreeType, toggleSelectedNode: toggleSelectedNode, selectedNodes: selectedNodes, startNode: startNode, aboveOrIsStartingNode: aboveOrIsStartingNode, isNodeAvailableForTagging: isNodeAvailableForTagging })); })
                    : null))))) : null));
});
export default ManagedMetadataPicker;
var ManagedMetadataTreeNode = React.memo(function (props) {
    var isNodeSelected = function () {
        return props.selectedNodes.some(function (n) { return n.Id === props.node.Id; });
    };
    // const isStartOpen = (): boolean => {
    //    return props.selectedNodes.some((n: TermSet | Term) => n.Id === props.node.Id));
    // }
    var _a = React.useState(isNodeSelected() || props.aboveOrIsStartingNode(props.node)), isOpen = _a[0], setIsOpen = _a[1];
    var _b = React.useState([]), children = _b[0], setChildren = _b[1];
    var ToggleNode = function (element) {
        setIsOpen(!isOpen);
    };
    React.useEffect(function () {
        if (props.node.NodeType === "TermGroup") {
            setIsOpen(true);
        }
        if (props.node.NodeType === "TermGroup" && props.node.TermSets.length > 0) {
            setChildren(props.node.TermSets);
        }
        if ((props.node.NodeType === "TermSet" || props.node.NodeType === "Term") && props.node.Terms.length > 0) {
            setChildren(props.node.Terms);
        }
    }, [props.node]);
    // if(props.node !== undefined && props.node !== null){
    var matchType = props.treeTypes.filter(function (tt) { return tt.Type === props.node.NodeType; })[0];
    var isStartingNode = function (node) {
        return false;
    };
    var hasSelectedChild = function (node) {
        var hasSelectedChild = false;
        if (props.selectedNodes !== undefined && props.selectedNodes.length > 0) {
            var checkChildrenIfMatch_2 = function (checkNode) {
                var checkNodeHasSelected = false;
                if (checkNode.NodeType === "TermGroup") {
                    if (checkNode.TermSets !== undefined && checkNode.TermSets !== null && Array.isArray(checkNode.TermSets)) {
                        checkNode.TermSets.forEach(function (ts) {
                            if (props.selectedNodes.some(function (n) { return n.Id === ts.Id; })) {
                                checkNodeHasSelected = true;
                            }
                            if (checkChildrenIfMatch_2(ts)) {
                                checkNodeHasSelected = true;
                            }
                        });
                    }
                }
                if (checkNode.NodeType === "TermSet" || checkNode.NodeType === "Term") {
                    if (checkNode.Terms !== undefined && checkNode.Terms !== null && Array.isArray(checkNode.Terms) && checkNode.Terms.length > 0) {
                        checkNode.Terms.forEach(function (term) {
                            if (props.selectedNodes.some(function (n) { return n.Id === term.Id; })) {
                                checkNodeHasSelected = true;
                            }
                            if (checkChildrenIfMatch_2(term)) {
                                checkNodeHasSelected = true;
                            }
                        });
                    }
                }
                return checkNodeHasSelected;
            };
            hasSelectedChild = checkChildrenIfMatch_2(node);
            //}
        }
        return hasSelectedChild;
    };
    var getNodeName = function (node) {
        if (node != null) {
            if (props.node.Name !== undefined) {
                try {
                    return decodeURIComponent(props.node.Name);
                }
                catch (_a) {
                    try {
                        return props.node.Name;
                    }
                    catch (_b) {
                        // suppress error
                    }
                }
            }
        }
        return "- name missing -";
    };
    return (React.createElement("div", { className: "node-wrapper" + (isOpen ? " open" : "") + (props.aboveOrIsStartingNode(props.node) === true ? " above-or-is-starting-node" : "") },
        React.createElement("div", { className: "node" +
                (matchType !== undefined && matchType.Type !== undefined ? " node-" + (matchType === null || matchType === void 0 ? void 0 : matchType.Type.toLowerCase()) : "") +
                (isNodeSelected() ? " selected" : "") +
                (hasSelectedChild(props.node) ? " child-selected" : "") +
                (props.isNodeAvailableForTagging(props.node) === false ? " not-available" : "") },
            children !== undefined && children.length > 0 ? (React.createElement("div", { className: "node-expand-icon", onClick: function (event) { return ToggleNode(event.currentTarget); } },
                React.createElement("i", { className: "fa fa-caret-right" }))) : null,
            React.createElement("div", { className: "node-icon-title" },
                React.createElement("div", { className: "node-icon-image", onClick: function () {
                        props.toggleSelectedNode(props.node);
                    } }, (matchType === null || matchType === void 0 ? void 0 : matchType.FaIcon) ? React.createElement("i", { className: "la " + (matchType === null || matchType === void 0 ? void 0 : matchType.FaIcon) }) : null),
                React.createElement("div", { className: "node-title", onClick: function () {
                        props.toggleSelectedNode(props.node);
                    } }, getNodeName(props.node)))),
        React.createElement(React.Fragment, null, children !== undefined ? (
        //@ts-ignore
        React.createElement(Collapse, { in: isOpen },
            React.createElement("ul", { className: "list-unstyled" }, children.map(function (node, nindex) { return (React.createElement("li", { key: nindex },
                React.createElement(ManagedMetadataTreeNode, { node: node, treeTypes: props.treeTypes, toggleSelectedNode: props.toggleSelectedNode, selectedNodes: props.selectedNodes, startNode: props.startNode, aboveOrIsStartingNode: props.aboveOrIsStartingNode, isNodeAvailableForTagging: props.isNodeAvailableForTagging }))); })))) : null)));
});
var ManagedMMDPickerInput = React.memo(function (props) {
    // const [isLoading, setIsLoading] = React.useState(false);
    var inputEl = React.useRef();
    // const overlayTriggerEl = React.useRef() as React.MutableRefObject<OverlayTrigger>;
    var spanEl = React.useRef();
    var popoverEl = React.useRef();
    var selectedItemsEl = React.useRef();
    var _a = React.useState([]), searchResults = _a[0], setSearchResults = _a[1];
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = React.useState(-1), cursor = _c[0], setCursor = _c[1];
    React.useEffect(function () {
        props.setDropdownOpen(searchResults.length !== 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResults]);
    // Get the search results.
    var getResults = function (searchValue) {
        if (searchValue === inputEl.current.value) {
            //
            if (searchValue.length === 0) {
                //setIsLoading(false);
                setSearchResults([]);
            }
            else {
                if (props.termGroups !== undefined && props.termGroups.length > 0) {
                    var resultsArray_1 = [];
                    var recursiveTermSearch_2 = function (term) {
                        var termMatch = false;
                        if (term.Name !== undefined && term.Name !== null && term.Name !== "") {
                            if (term.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
                                termMatch = true;
                            }
                        }
                        if (term.Description !== undefined && term.Description !== null && term.Description !== "") {
                            if (term.Description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
                                termMatch = true;
                            }
                        }
                        if (termMatch) {
                            resultsArray_1.push(term);
                        }
                        term.Terms.forEach(function (subTerm) {
                            recursiveTermSearch_2(subTerm);
                        });
                    };
                    props.termGroups.forEach(function (termGroup) {
                        termGroup.TermSets.forEach(function (termSet) {
                            recursiveTermSearch_2(termSet);
                        });
                    });
                    //setIsLoading(false);
                    setSearchResults(resultsArray_1.filter(function (t) {
                        if (props.selectedNodes.some(function (sn) { return sn.Id === t.Id; })) {
                            return false;
                        }
                        else if (props.isNodeAvailableForTagging(t) === false) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }));
                }
            }
        }
    };
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        var currentResults = searchResults;
        if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        }
        else if (e.keyCode === 40 && cursor < currentResults.length - 1) {
            setCursor(cursor + 1);
        }
        else if (e.keyCode === 13) {
            clickTerm(currentResults[cursor]);
        }
        else if (e.keyCode === 8 && inputEl.current.value.length === 0 && props.selectedNodes.length > 0) {
            props.toggleSelectedNode(props.selectedNodes[props.selectedNodes.length - 1]);
        }
    };
    var shouldShowNoResult = function () {
        var shouldShow = false;
        if (searchResults !== undefined) {
            if (searchResults.length === 0) {
                if (inputEl !== undefined) {
                    if (inputEl.current !== undefined) {
                        if (inputEl.current !== null) {
                            if (inputEl.current.value !== undefined) {
                                if (inputEl.current.value !== null) {
                                    if (inputEl.current.value.length > 0) {
                                        // if(isLoading === false){
                                        shouldShow = true;
                                        // }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldUseScrollbar = function () {
        var currentResults = searchResults;
        if (currentResults.length > 5) {
            return true;
        }
        return false;
    };
    var clickTerm = function (term) {
        props.toggleSelectedNode(term);
        clearCurrentSearch();
        setTimeout(function () {
            if (inputEl.current !== undefined && inputEl.current !== null) {
                inputEl.current.focus();
            }
        }, 50);
    };
    var clearCurrentSearch = function () {
        setCursor(-1);
        setSearchResults([]);
        if (inputEl !== undefined && inputEl.current !== undefined && inputEl.current !== null) {
            inputEl.current.value = "";
        }
    };
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleClick = function (e) {
        var setHidden = false;
        if (popoverEl.current !== undefined) {
            if (popoverEl.current !== null) {
                if (popoverEl.current.contains !== undefined) {
                    if (popoverEl.current.contains !== null) {
                        if (popoverEl.current.contains(e.target)) {
                        }
                        else {
                            try {
                                setHidden = true;
                            }
                            catch (_a) { }
                        }
                    }
                }
            }
        }
        if (spanEl.current !== undefined) {
            if (spanEl.current !== null) {
                if (spanEl.current.contains !== undefined) {
                    if (spanEl.current.contains !== null) {
                        if (spanEl.current.contains(e.target)) {
                            setHidden = false;
                        }
                        else {
                            try {
                            }
                            catch (_b) { }
                        }
                    }
                }
            }
        }
        if (selectedItemsEl.current !== undefined) {
            if (selectedItemsEl.current !== null) {
                if (selectedItemsEl.current === e.target) {
                    if (props.allowTextEntry === true && (props.allowMultiple === true || (props.allowMultiple === false && props.selectedNodes.length === 0))) {
                        setTimeout(function () {
                            if (inputEl.current !== undefined && inputEl.current !== null) {
                                inputEl.current.focus();
                            }
                        }, 50);
                        //inputEl.current.focus();
                    }
                }
                // if(selectedItemsEl.current.contains !== undefined) {
                //    if(selectedItemsEl.current.contains !== null) {
                //       if (spanEl.current.contains(e.target as HTMLInputElement)) {
                //          setHidden = false;
                //       } else {
                //          try{
                //          } catch {}
                //       }
                //    }
                // }
            }
        }
    };
    return (React.createElement("div", { ref: popoverEl, className: "picker-input-box" },
        React.createElement("div", { className: "picker-input-wrapper" },
            React.createElement("div", { className: "selected-items" + (props.allowTextEntry === true && (props.allowMultiple === true || (props.allowMultiple === false && props.selectedNodes.length === 0)) ? " can-add-more" : ""), ref: selectedItemsEl },
                props.selectedNodes.map(function (node) { return (React.createElement("div", { className: "term" },
                    React.createElement("div", { className: "node-icon" }, props.getNodeIcon(node)),
                    React.createElement("div", { className: "node-name" }, node.Name),
                    React.createElement("div", { className: "node-remove", onClick: function () { return props.toggleSelectedNode(node); } },
                        React.createElement("i", { className: "fa fa-times" })))); }),
                React.createElement("div", { className: "picker-input" }, props.allowTextEntry === true && (props.allowMultiple === true || (props.allowMultiple === false && props.selectedNodes.length === 0)) ? (React.createElement("input", { type: "text", placeholder: props.placeholder === undefined ? "Type term to tag" : props.placeholder, onChange: function (event) {
                        getResults(event.target.value);
                    }, onBlur: function () {
                        setTimeout(clearCurrentSearch, 500);
                    }, onKeyDown: function (event) {
                        handleKeyDown(event);
                    }, ref: inputEl })) : null))),
        React.createElement("div", { className: "picker-input-search" },
            React.createElement("ul", { className: "list-unstyled" + ((searchResults !== undefined && searchResults.length > 0) || shouldShowNoResult() === true ? "" : " results-hidden") },
                React.createElement(DynamicScrollbar, { shouldShow: shouldUseScrollbar(), maxHeight: 200 },
                    searchResults !== undefined &&
                        searchResults.map(function (term, index) { return (React.createElement("li", { className: cursor === index ? "selected" : undefined, key: index, onMouseEnter: function () {
                                setCursor(-1);
                            }, onClick: function () {
                                clickTerm(term);
                            } },
                            React.createElement("div", { className: "node-icon" }, props.getNodeIcon(term)),
                            React.createElement("div", { className: "details" },
                                React.createElement("div", { className: "name", dangerouslySetInnerHTML: { __html: highlightWords(inputEl.current.value, term.Name) } }),
                                React.createElement("div", { className: "description" }, term.Description),
                                term.NodeType === "Term" ? (React.createElement("div", { className: "parent" },
                                    "in ",
                                    React.createElement("span", null, term.ParentName))) : null))); }),
                    shouldShowNoResult() ? React.createElement("li", { className: "no-results" }, "No results") : null)))));
});
