var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { NamingPolicyListActionTypes } from "./types";
export function Load(namingPolicys) {
    if (namingPolicys === undefined)
        namingPolicys = [];
    return {
        type: NamingPolicyListActionTypes.NamingPolicy_List_Load,
        payload: __spreadArray([], namingPolicys, true)
    };
}
export function AddItem(namingPolicy) {
    return {
        type: NamingPolicyListActionTypes.NamingPolicy_List_Item_Add,
        payload: __assign({}, namingPolicy)
    };
}
export function UpdateItem(namingPolicy) {
    return {
        type: NamingPolicyListActionTypes.NamingPolicy_List_Item_Update,
        payload: __assign({}, namingPolicy)
    };
}
export function DeleteItem(namingPolicy) {
    return {
        type: NamingPolicyListActionTypes.NamingPolicy_List_Item_Delete,
        payload: __assign({}, namingPolicy)
    };
}
export var namingPolicyListMiddlware = function (store, next, action) {
    // if(action.type === NamingPolicyListActionTypes.NamingPolicy_List_Load){
    //     action.payload.forEach((temp:NamingPolicy) => {
    //         if(temp.AssociatedFeatures){
    //             temp.AssociatedFeatures.forEach((feat:Feature) => {
    //                 if(feat.Icon !== undefined)
    //                     feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //             });
    //         }
    //     });
    //     next(action);
    // }
    // else {
    next(action);
    // }
};
