var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import MembersIcon from "app/assets/img/members-icon.svg";
import PortletAlert from "app/components/common/portlet-alert";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalSearchModal from "app/components/common/principal-search-modal";
import { RequestWizardContext } from "app/components/requests/request-wizard";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
var RequestWizardPermissionsMembers = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var intl = useIntl();
    var rwc = React.useContext(RequestWizardContext);
    var memberContainerElement = React.useRef();
    var addMember = function (user) {
        var tempArray = [user];
        if (rwc.currentRequest.Members !== undefined) {
            if (rwc.currentRequest.Members.length > 0) {
                if (rwc.currentRequest.Members.some(function (u) { return u.UserPrincipalName === user.UserPrincipalName; })) {
                    tempArray = __spreadArray([], rwc.currentRequest.Members, true);
                }
                else {
                    tempArray = __spreadArray(__spreadArray([], rwc.currentRequest.Members, true), tempArray, true);
                }
            }
        }
        rwc.updateCurrentRequest({ Members: tempArray });
    };
    var removeMember = function (user) {
        var _a;
        var tempArray = (_a = rwc.currentRequest.Members) === null || _a === void 0 ? void 0 : _a.filter(function (member) { return member.UserPrincipalName !== user.UserPrincipalName; });
        if (tempArray === undefined) {
            tempArray = [];
        }
        rwc.updateCurrentRequest({ Members: __spreadArray([], tempArray, true) });
    };
    var getMembers = function (lockedUsers) {
        var _a;
        var members = [];
        if ((((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.LiveTemplateTeamCloneMembers) === true &&
            (rwc.currentRequest.RequestType === "Team" || (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernGroup"))) ||
            (rwc.currentRequest.RequestType === "SharePoint" && rwc.currentRequest.SharePointTemplateType === "ModernCommunications")) {
            if (rwc.liveMembers !== undefined) {
                var tempMembers = rwc.liveMembers.filter(function (u) { return !members.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
                tempMembers = tempMembers.map(function (to) {
                    to.Locked = true;
                    return to;
                });
                members = __spreadArray(__spreadArray([], members, true), tempMembers, true);
            }
        }
        if (rwc.currentRequest.Members !== undefined) {
            var tempMembers = rwc.currentRequest.Members.filter(function (u) { return !members.some(function (us) { return us.UserPrincipalName === u.UserPrincipalName; }); });
            tempMembers = tempMembers.map(function (to) {
                to.Locked = false;
                return to;
            });
            members = __spreadArray(__spreadArray([], members, true), tempMembers, true);
        }
        if (lockedUsers !== undefined) {
            members = members.filter(function (u) { return (u.Locked !== undefined && u.Locked === lockedUsers ? true : false); });
        }
        return members;
    };
    React.useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", userListBoxClickHandler);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", userListBoxClickHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var userListBoxClickHandler = function (e) {
        if (memberContainerElement.current !== undefined) {
            if (memberContainerElement.current !== null) {
                if (memberContainerElement.current === e.target) {
                    var addButton = document.querySelector(".step-permissions .users .right .link");
                    if (addButton !== null) {
                        addButton.click();
                    }
                }
            }
        }
    };
    var clickAddButton = function (side) {
        var addButton = document.querySelector(".step-permissions .users ." + side + " .link");
        if (addButton !== null) {
            addButton.click();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "adder-top" },
            React.createElement("div", { className: "title-add" },
                React.createElement("span", { className: "title" },
                    React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.members", defaultMessage: "Members" }),
                    " ",
                    React.createElement("span", { className: "count" },
                        "(",
                        React.createElement("span", { className: "num-pad" }, getMembers().length),
                        ")")),
                React.createElement(PrincipalSearchModal, { suggestedTitle: intl.formatMessage({
                        id: "page.request_wizard.step_permissions.suggested_members",
                        defaultMessage: "Suggested Members"
                    }), title: intl.formatMessage({
                        id: "page.request_wizard.step_permissions.add_members",
                        defaultMessage: "Add Members"
                    }), mode: "usersAndGroups", selectedPrincipals: getMembers(), excludeGuests: props.ShouldExcludeGuests, onPrincipalSelected: function (principal) {
                        addMember(principal);
                    }, onPrinciaplRemoved: function (principal) {
                        removeMember(principal);
                    } },
                    React.createElement("div", { className: "add-action", "data-pw": "add-members" },
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "la la-plus" })),
                        React.createElement("div", { className: "text" },
                            React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.add_members", defaultMessage: "Add Members" }))))),
            React.createElement("div", { className: "info" },
                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.members_who_can_see_and_participate", defaultMessage: "Members who can see and participate" }))),
        React.createElement("div", { className: "user-list " +
                (((_a = rwc.currentRequest.Template) === null || _a === void 0 ? void 0 : _a.MinMembers) !== undefined && ((_b = rwc.currentRequest.Template) === null || _b === void 0 ? void 0 : _b.MinMembers) > 0
                    ? rwc.currentRequest.Members === undefined || (rwc.currentRequest.Members !== undefined && rwc.currentRequest.Members.length < ((_c = rwc.currentRequest.Template) === null || _c === void 0 ? void 0 : _c.MinMembers))
                        ? "min-warning"
                        : "min-success"
                    : ""), ref: memberContainerElement },
            ((_d = rwc.currentRequest.Template) === null || _d === void 0 ? void 0 : _d.MinMembers) !== undefined && ((_e = rwc.currentRequest.Template) === null || _e === void 0 ? void 0 : _e.MinMembers) > 0 ? (rwc.currentRequest.Members !== undefined && rwc.currentRequest.Members.length >= ((_f = rwc.currentRequest.Template) === null || _f === void 0 ? void 0 : _f.MinMembers) ? (React.createElement(PortletAlert, { type: "success", icon: "check", size: "small", message: intl.formatMessage({
                    id: "page.request_wizard.step_permissions.valid_user_count",
                    defaultMessage: "You have selected at least {minnumber} additional {users}"
                }, {
                    minnumber: (_g = rwc.currentRequest.Template) === null || _g === void 0 ? void 0 : _g.MinMembers,
                    users: ((_h = rwc.currentRequest.Template) === null || _h === void 0 ? void 0 : _h.MinMembers) !== 1
                        ? intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_members_label_plural", defaultMessage: "members" })
                        : intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_members_label_singular", defaultMessage: "member" })
                }) })) : (React.createElement(PortletAlert, { type: "danger", icon: "info", size: "small", message: intl.formatMessage({
                    id: "page.request_wizard.step_permissions.invalid_user_count",
                    defaultMessage: "Please select at least {minnumber} additional {users}"
                }, {
                    minnumber: (_j = rwc.currentRequest.Template) === null || _j === void 0 ? void 0 : _j.MinMembers,
                    users: ((_k = rwc.currentRequest.Template) === null || _k === void 0 ? void 0 : _k.MinMembers) !== 1
                        ? intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_members_label_plural", defaultMessage: "members" })
                        : intl.formatMessage({ id: "page.request_wizard.step_permissions.invalid_members_label_singular", defaultMessage: "member" })
                }) }))) : null,
            ((_l = rwc.currentRequest.Template) === null || _l === void 0 ? void 0 : _l.MinMembers) !== undefined && ((_m = rwc.currentRequest.Template) === null || _m === void 0 ? void 0 : _m.MinMembers) > 0 ? (rwc.currentRequest.Members !== undefined && rwc.currentRequest.Members.length < ((_o = rwc.currentRequest.Template) === null || _o === void 0 ? void 0 : _o.MinMembers) ? (__spreadArray([], Array(((_p = rwc.currentRequest.Template) === null || _p === void 0 ? void 0 : _p.MinMembers) - rwc.currentRequest.Members.length), true).map(function (x, i) { return (React.createElement("div", { className: "principal-item holder", onClick: function () { return clickAddButton("right"); } },
                React.createElement("div", { className: "pic" },
                    React.createElement("div", { className: "profile-picture two-letter size-xs" })),
                React.createElement("span", null, "\u00A0"))); })) : rwc.currentRequest.Members === undefined ? (__spreadArray([], Array((_q = rwc.currentRequest.Template) === null || _q === void 0 ? void 0 : _q.MinMembers), true).map(function (x, i) { return (React.createElement("div", { className: "principal-item holder", onClick: function () { return clickAddButton("right"); } },
                React.createElement("div", { className: "pic" },
                    React.createElement("div", { className: "profile-picture two-letter size-xs" })),
                React.createElement("span", null, "\u00A0"))); })) : null) : getMembers().length === 0 ? (React.createElement("div", { className: "no-results" },
                React.createElement("div", { className: "no-icon-text" },
                    React.createElement("img", { src: MembersIcon }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.no_members_yet", defaultMessage: "No members yet" }))))) : null,
            getMembers(false).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeMember(principal); } })); }),
            getMembers(true).length > 0 ? (React.createElement("div", { className: "default-users-divider" },
                React.createElement(FormattedMessage, { id: "page.request_wizard.step_permissions.default_members", defaultMessage: "Default Members" }))) : null,
            getMembers(true).map(function (user) { return (React.createElement(PrincipalItem, { principal: user, key: user.Id, showBadge: true, onRemove: function (principal) { return removeMember(principal); } })); }))));
});
export default RequestWizardPermissionsMembers;
