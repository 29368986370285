var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { parseThemeColor, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { OrchestryPieChartLegend } from "./orchestry-pie-chart-legend.component";
import "./orchestry-pie-chart.scss";
/**
 * Defines the size variants for the Orchestry pie chart component.
 */
var sizeVariants = {
    xxs: 105
};
/** A pie chart component that uses ApexCharts to render a donut chart with labels and a legend. */
export var OrchestryPieChart = function (props) {
    var _a = useState(props.value), selectedLabel = _a[0], setselectedLabel = _a[1];
    var theme = useMantineTheme();
    var convertedColours = React.useMemo(function () { return props.colours.map(function (colour) { return parseThemeColor({ color: colour, theme: theme }).value; }); }, __spreadArray([], props.colours, true));
    var handleLegendItemClick = function (value) {
        if (props.onClick) {
            if ((selectedLabel === null || selectedLabel === void 0 ? void 0 : selectedLabel.toLowerCase()) === (value === null || value === void 0 ? void 0 : value.toLowerCase())) {
                props.onClick(undefined);
                setselectedLabel(undefined);
            }
            else {
                props.onClick(value.toLowerCase());
                setselectedLabel(value.toLowerCase());
            }
        }
    };
    useEffect(function () {
        setselectedLabel(props.value);
    }, [props.value]);
    return (React.createElement("div", { className: "orchestry-pie-chart-container" },
        React.createElement(ReactApexChart, { width: props.size ? sizeVariants[props.size] : sizeVariants["xxs"], height: props.size ? sizeVariants[props.size] : sizeVariants["xxs"], series: props.series, type: "donut", options: {
                dataLabels: { enabled: false },
                tooltip: { enabled: false, onDatasetHover: { highlightDataSeries: false }, marker: { show: false } },
                legend: { show: false },
                labels: props.labels,
                chart: { type: "donut" },
                stroke: { width: 0, colors: ["#FFFFFF"] },
                colors: convertedColours,
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                states: {
                    hover: {
                        filter: {
                            type: "none"
                        }
                    },
                    active: {
                        filter: {
                            type: "none"
                        }
                    }
                }
            } }),
        props.legend && props.legend.items.length > 0 && React.createElement(OrchestryPieChartLegend, { legendItems: props.legend.items, onClick: handleLegendItemClick, selectedLabel: selectedLabel })));
};
