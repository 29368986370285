var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { UsersListActionTypes, UserListMode } from "./types";
export var initialPageContext = {
    Users: [],
    TotalCount: null,
    PageSize: 10,
    Mode: UserListMode.Owners,
    FirstLoadDone: false,
    IncludeGuestsInMembers: false,
    SiteId: null,
    GroupId: null,
    NextPageQueryOptions: null,
    IsLoading: false
};
var initialState = {
    guests: __assign(__assign({}, initialPageContext), { Mode: UserListMode.Guests }),
    members: __assign(__assign({}, initialPageContext), { Mode: UserListMode.Members }),
    owners: __assign(__assign({}, initialPageContext), { Mode: UserListMode.Owners })
};
export function usersListReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var returnState = __assign({}, state);
    var userTypeKey = "";
    switch (action.type) {
        case UsersListActionTypes.Users_List_Load: {
            if (action.payload.Mode === UserListMode.Owners) {
                userTypeKey = "owners";
            }
            if (action.payload.Mode === UserListMode.Members) {
                userTypeKey = "members";
            }
            if (action.payload.Mode === UserListMode.Guests) {
                userTypeKey = "guests";
            }
            var userKeys_1 = state[userTypeKey].Users.map(function (u) { return u.Id; });
            returnState[userTypeKey] = __assign({}, action.payload);
            // Sometimes the webparts calls this action multiple times because of how it sends post messages,
            // so we need to make sure we don't add the same users multiple times
            returnState[userTypeKey].Users = __spreadArray(__spreadArray([], state[userTypeKey].Users, true), action.payload.Users.filter(function (u) { return !userKeys_1.includes(u.Id); }), true);
            return returnState;
        }
        case UsersListActionTypes.Users_List_Page: {
            if (action.payload.Mode === UserListMode.Owners) {
                userTypeKey = "owners";
            }
            if (action.payload.Mode === UserListMode.Members) {
                userTypeKey = "members";
            }
            if (action.payload.Mode === UserListMode.Guests) {
                userTypeKey = "guests";
            }
            returnState[userTypeKey] = __assign({}, action.payload);
            returnState[userTypeKey].Users = __spreadArray(__spreadArray([], state[userTypeKey].Users, true), action.payload.Users, true);
            return returnState;
        }
        case UsersListActionTypes.Users_List_Clear: {
            if (action.payload === UserListMode.Owners) {
                userTypeKey = "owners";
            }
            if (action.payload === UserListMode.Members) {
                userTypeKey = "members";
            }
            if (action.payload === UserListMode.Guests) {
                userTypeKey = "guests";
            }
            returnState[userTypeKey] = __assign(__assign({}, initialPageContext), { Mode: action.payload });
            return __assign({}, returnState);
        }
        case UsersListActionTypes.Users_List_IsLoading: {
            if (action.payload.Mode === UserListMode.Owners) {
                userTypeKey = "owners";
            }
            if (action.payload.Mode === UserListMode.Members) {
                userTypeKey = "members";
            }
            if (action.payload.Mode === UserListMode.Guests) {
                userTypeKey = "guests";
            }
            returnState[userTypeKey].IsLoading = action.payload.IsLoading;
            return __assign({}, returnState);
        }
        default: {
            return state;
        }
    }
}
