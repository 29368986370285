export var RequestListActionTypes;
(function (RequestListActionTypes) {
    RequestListActionTypes["Request_List_Load"] = "Request_List_Load";
    RequestListActionTypes["Request_List_Load_Page"] = "Request_List_Load_Page";
    RequestListActionTypes["Request_List_Load_User_Page"] = "Request_List_Load_User_Page";
    RequestListActionTypes["Request_List_Update_Is_User_Loading"] = "Request_List_Update_Is_User_Loading";
    RequestListActionTypes["Request_List_Search"] = "Request_List_Search";
    RequestListActionTypes["Request_List_Item_Add"] = "Request_List_Item_Add";
    RequestListActionTypes["Request_List_Item_Delete"] = "Request_List_Item_Delete";
    RequestListActionTypes["Request_List_Item_Update"] = "Request_List_Item_Update";
})(RequestListActionTypes || (RequestListActionTypes = {}));
