import { LibraryDocumentTemplateCurrentActionTypes } from "./types";
//import mergeArrays from 'app/utils/mergeArray';
var initialState = {
    item: {}
};
export function libraryDocumentTemplateCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LibraryDocumentTemplateCurrentActionTypes.LibraryDocumentTemplate_Current_Load: {
            //console.log(LibraryDocumentTemplateCurrentActionTypes.LibraryDocumentTemplate_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case LibraryDocumentTemplateCurrentActionTypes.LibraryDocumentTemplate_Current_Update: {
            // let associatedItemsUpdated = false;
            var updatedLibraryDocumentTemplate = Object.assign({}, state.item, action.payload);
            // if(state.item.AssociatedTemplates.length !== updatedLibraryDocumentTemplate.AssociatedTemplates.length)
            // {
            //     console.log("Array Changed");
            //     associatedItemsUpdated = true;
            // }
            //console.log(LibraryDocumentTemplateCurrentActionTypes.LibraryDocumentTemplate_Current_Update, updatedLibraryDocumentTemplate)
            return {
                item: updatedLibraryDocumentTemplate
            };
        }
        case LibraryDocumentTemplateCurrentActionTypes.LibraryDocumentTemplate_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
