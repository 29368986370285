var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Group, Text } from "@mantine/core";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import { OrchestryChip } from "appv2/components/orchestry-chip/orchestry-chip.component";
import React from "react";
import { useIntl } from "react-intl";
import { getGroupCounts } from "./orchestry-query-context-utils";
import "./orchestry-query-context.scss";
/**
 * Represents the bar that displays all the currently applied filters on the page
 */
export var OrchestryQueryContext = function (_a) {
    var appliedFilters = _a.appliedFilters, filterGroups = _a.filterGroups, totalResults = _a.totalResults, removeFilter = _a.removeFilter, clearAllFilters = _a.clearAllFilters, filterGroupTitles = _a.filterGroupTitles;
    var groupCounts = getGroupCounts(appliedFilters);
    var intl = useIntl();
    return (React.createElement("div", { className: "orchestry-query-context" },
        React.createElement(Group, { justify: "space-between" },
            React.createElement("div", { className: "query-context-applied-filters-container" },
                filterGroups.map(function (group, index) {
                    var _a;
                    return groupCounts[group] && groupCounts[group] > 0 ? (React.createElement(React.Fragment, { key: group },
                        React.createElement(Text, { className: "category-label", span: true, c: "text-secondary.0", size: "sm", fw: 700 }, (_a = filterGroupTitles === null || filterGroupTitles === void 0 ? void 0 : filterGroupTitles[index]) !== null && _a !== void 0 ? _a : group,
                            ":\u00A0"),
                        appliedFilters
                            .filter(function (filter) { return filter.group === group && filter.selected; })
                            .map(function (filter) {
                            var value = filter.type === "input" && filter.inputFilterType === "date-range" ? filter.title : filter.value;
                            return React.createElement(OrchestryChip, __assign({ size: "lg", key: filter.title }, filter, { value: value, onClick: function () { return removeFilter(filter); } }));
                        }))) : null;
                }),
                appliedFilters.length > 0 ? (React.createElement(OrchestryBadge, { size: "lg", title: intl.formatMessage({ id: "controls.clear_all", defaultMessage: "Clear All" }), value: "clearall", onClick: clearAllFilters })) : null),
            React.createElement(Text, { className: "results", c: "text-tertiary.0", size: "sm", fw: 500 }, intl.formatMessage({ id: "controls.query_context.total_results", defaultMessage: "{count} Results" }, { count: totalResults })))));
};
