import * as React from "react";
import "./lifecycle-details-tab-overview.scss";
import { useSelector, useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { ReportSearchSlotName } from "app/store/lifecycle-report";
import LifecycleStats from "app/components/insights/workspaces/report-details/components/lifecycle-stats";
import { Row, Col } from "react-bootstrap";
import LifecyclePolicyAssignments from "app/components/insights/workspaces/report-details/components/lifecycle-policy-assignments";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import LifecycleWorkspaceDetails from "app/components/insights/workspaces/report-details/components/lifecycle-workspace-details";
import LifecycleLinks from "app/components/insights/workspaces/report-details/components/lifecycle-links";
var LifecycleDetailsTabOverview = React.memo(function (_a) {
    var siteId = _a.siteId;
    var dispatch = useDispatch();
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var _b = React.useState(null), mostRecentStat = _b[0], setMostRecentStat = _b[1];
    var reportStats = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.stats; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var _c = React.useState(""), previousStatus = _c[0], setPreviousStats = _c[1];
    //console.log("reportStats in LifecycleDetailsTabOverview: ", reportStats);
    React.useEffect(function () {
        dispatch(LifecyclePolicyWorkspaceStore.LoadLinks(siteId));
        dispatch(LifecyclePolicyWorkspaceStore.LoadNotebookAndPlanner(siteId));
        dispatch(LifecyclePolicyWorkspaceStore.LoadStatus(siteId));
        dispatch(LifecyclePolicyWorkspaceStore.LoadStats(siteId));
    }, []);
    React.useEffect(function () {
        if (reportStats !== null && mostRecentStat === null) {
            setMostRecentStat(GetMostResentStat());
        }
    }, [reportStats]);
    var GetMostResentStat = function () {
        if (reportStats !== undefined && Array.isArray(reportStats) && reportStats.length > 0) {
            return reportStats[reportStats.length - 1];
        }
        else {
            return null;
        }
    };
    React.useEffect(function () {
        if (policyWorkspace !== null && policyWorkspace.ArchivalStatus !== null) {
            if (policyWorkspace.ArchivalStatus !== previousStatus) {
                if (policyWorkspace.ArchivalStatus === "Done") {
                    dispatch(LifecyclePolicyWorkspaceStore.LoadLinks(siteId));
                    dispatch(LifecyclePolicyWorkspaceStore.LoadNotebookAndPlanner(siteId));
                    dispatch(LifecyclePolicyWorkspaceStore.LoadStatus(siteId));
                }
                dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.WorkspaceInsightsSlot));
                setPreviousStats(policyWorkspace.ArchivalStatus);
            }
        }
    }, [policyWorkspace]);
    return (React.createElement("div", { className: "lifecycle-details-tab-overview" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 8 },
                React.createElement(LifecycleWorkspaceDetails, null),
                reportItem === undefined || reportItem === null || (reportItem === null || reportItem === void 0 ? void 0 : reportItem.ArchivalReason) === null ? (React.createElement(React.Fragment, null,
                    (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SharePointLockNoAccess) !== true ? ((reportItem === null || reportItem === void 0 ? void 0 : reportItem.Application) !== "CommunicationSite" ? (React.createElement(Row, null,
                        React.createElement(LifecycleStats, { property: "GroupOwnersCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "GroupMembersCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "GroupGuestsCount", inCol: true }))) : (React.createElement(Row, null,
                        React.createElement(LifecycleStats, { property: "SiteOwnersCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "SiteMembersCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "SiteGuestCount", inCol: true })))) : null,
                    (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Application) === "Team" && (React.createElement(Row, null, (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Application) === "Team" && (React.createElement(React.Fragment, null,
                        React.createElement(LifecycleStats, { property: "TeamChannelsCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "TeamTabsCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "GroupConversationCount", inCol: true }))))),
                    React.createElement(Row, null,
                        React.createElement(LifecycleStats, { property: "SharePointPageViewCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "SharePointVisitedPageCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "SharePointViewEditFileCount", inCol: true })),
                    React.createElement(Row, null,
                        React.createElement(LifecycleStats, { property: "SharePointManualFileCount", inCol: true }),
                        React.createElement(LifecycleStats, { property: "SharePointSiteStorageUsed", inCol: true }),
                        React.createElement(LifecycleStats, { property: "GroupEventsCount", inCol: true })),
                    (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Application) === "TeamSite" && (React.createElement(Row, null,
                        React.createElement(LifecycleStats, { property: "GroupConversationCount", inCol: true }))))) : null),
            React.createElement(Col, { md: 4 },
                React.createElement(LifecyclePolicyAssignments, { siteId: siteId, Slot: ReportSearchSlotName.WorkspaceInsightsSlot }),
                React.createElement(LifecycleLinks, null)))));
});
export default LifecycleDetailsTabOverview;
