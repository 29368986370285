import * as microsoftTeams from "@microsoft/teams-js";
import AnimatedLoader from "app/components/common/animated-loader";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { TeamsPresence } from "app/components/common/teams-presence";
import * as UsersListStore from "app/store/users/list";
import { UserListMode } from "app/store/users/list";
import TeamClientAppNavigation from "app/utils/teamClientAppNavigation";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./user-list-webpart.scss";
/**
 * The user list, but shown in the team information page sharepoint webpart.
 */
export var UserListWebpart = React.memo(function (props) {
    var usersPageContext = useSelector(function (state) { return state.usersListState[props.mode === UserListMode.Guests ? "guests" : props.mode === UserListMode.Members ? "members" : "owners"]; });
    var isLoading = useSelector(function (state) { return state.usersListState[props.mode === UserListMode.Guests ? "guests" : props.mode === UserListMode.Members ? "members" : "owners"].IsLoading; });
    var dispatch = useDispatch();
    var intl = useIntl();
    var _a = React.useState(true), minimized = _a[0], setMinimized = _a[1];
    var _b = React.useState(90), maxHeight = _b[0], setMaxHeight = _b[1];
    var userList = React.useRef();
    var _c = React.useState(false), needToShowMoreOverride = _c[0], setNeedToShowMoreOverride = _c[1];
    // Treat the host client type as teams web to make teams links work.
    var hostClientType = microsoftTeams.HostClientType.web;
    var getModeTitle = function () {
        return props.mode === UserListMode.Guests
            ? "Guests"
            : props.mode === UserListMode.Members
                ? intl.formatMessage({ id: "general.members", defaultMessage: "Members" })
                : intl.formatMessage({ id: "general.owners", defaultMessage: "Owners" });
    };
    React.useEffect(function () {
        if (minimized) {
            setMaxHeight(90);
        }
        else {
            setMaxHeight(userList.current.clientHeight);
        }
    }, [minimized, usersPageContext.Users]);
    React.useEffect(function () {
        dispatch(UsersListStore.Clear(props.mode));
        dispatch(UsersListStore.Load(props.mode, props.siteId !== undefined ? props.siteId : null, props.groupId !== undefined ? props.groupId : null));
        return function () {
            dispatch(UsersListStore.Clear(props.mode));
        };
    }, [props.mode, props.groupId, props.siteId]);
    var pageNext = function () {
        if (usersPageContext !== null && usersPageContext.Mode !== null) {
            if (minimized === true) {
                setMinimized(false);
            }
            else {
                dispatch(UsersListStore.IsLoading(usersPageContext.Mode, true));
                dispatch(UsersListStore.Page(usersPageContext));
            }
        }
    };
    React.useEffect(function () {
        var needsExpansion = false;
        if (userList !== null && userList.current !== null && userList.current !== undefined && userList.current.clientWidth !== undefined && userList.current.clientWidth !== null) {
            if (minimized === true) {
                if (userList.current.clientWidth > 1701) {
                    if (usersPageContext.Users.length > 5) {
                        needsExpansion = true;
                    }
                }
                if (userList.current.clientWidth > 1301 && userList.current.clientWidth <= 1700) {
                    if (usersPageContext.Users.length > 4) {
                        needsExpansion = true;
                    }
                }
                if (userList.current.clientWidth > 901 && userList.current.clientWidth <= 1300) {
                    if (usersPageContext.Users.length > 3) {
                        needsExpansion = true;
                    }
                }
                if (userList.current.clientWidth <= 900) {
                    if (usersPageContext.Users.length > 1) {
                        needsExpansion = true;
                    }
                }
            }
        }
        setNeedToShowMoreOverride(needsExpansion);
    }, [minimized, usersPageContext.Users]);
    var RenderListOfUsers = function () {
        return (React.createElement("div", { className: "list-of-members" },
            props.showTitle === undefined || (props.showTitle !== undefined && props.showTitle === true) ? (React.createElement("div", { className: "title" },
                React.createElement("h2", null,
                    getModeTitle(),
                    " (",
                    usersPageContext.TotalCount,
                    ")"))) : null,
            usersPageContext.Users.length > 0 ? (React.createElement("div", null,
                React.createElement("div", { className: "user-list-wrapper", style: { maxHeight: maxHeight + "px" } },
                    React.createElement("div", { className: "list", ref: userList }, usersPageContext.Users.map(function (user) { return (React.createElement("div", { className: "user-container" },
                        React.createElement("div", { className: "profile-image" },
                            React.createElement(OfficeProfilePicture, { uid: user.userPrincipalName, displayName: user.displayName, size: "md", type: "User" }),
                            user.userType !== "ServicePrincipal" ? (React.createElement("div", { className: "graph-presence" },
                                React.createElement(TeamsPresence, { userId: user.id }))) : null),
                        React.createElement("div", { className: "right-side" },
                            React.createElement("div", { className: "info" },
                                React.createElement("div", { className: "info-container" },
                                    React.createElement("div", { className: "display-name", title: user.displayName },
                                        React.createElement("div", { className: "ellipsis" }, user.displayName)),
                                    React.createElement("div", { className: "job-title" },
                                        React.createElement("div", { className: "ellipsis" }, user.jobTitle)),
                                    React.createElement("div", { className: "department" },
                                        React.createElement("div", { className: "ellipsis" }, user.department)))),
                            user.userType !== "ServicePrincipal" ? (React.createElement("div", { className: "actions" },
                                React.createElement("div", { className: "action" },
                                    React.createElement("a", { title: user.mail ? "mailto:".concat(user.mail) : undefined, href: user.mail ? "mailto:".concat(user.mail) : undefined },
                                        React.createElement("span", { className: "icon" },
                                            React.createElement("i", { className: "fa-light fa-envelope" })))),
                                React.createElement("div", { className: "action" },
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", title: "".concat(intl.formatMessage({ id: "component.user_list.chat_with.title", defaultMessage: "Chat with" }), " ").concat(user.displayName), onClick: function (event) {
                                            return user.userPrincipalName !== undefined && user.userPrincipalName !== null
                                                ? TeamClientAppNavigation.goToTeamsLink("https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent(user.userPrincipalName)), event, hostClientType)
                                                : null;
                                        }, href: user.userPrincipalName ? "https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent(user.userPrincipalName)) : undefined },
                                        React.createElement("span", { className: "icon" },
                                            React.createElement("i", { className: "fa-light fa-comment" })))))) : null))); }))),
                (usersPageContext.TotalCount !== null && usersPageContext.TotalCount > usersPageContext.Users.length && usersPageContext.NextPageQueryOptions !== null) || needToShowMoreOverride ? (React.createElement("div", { className: "see-more-container open" },
                    React.createElement("div", { className: "see-more" },
                        React.createElement("span", { onClick: function () {
                                pageNext();
                            }, className: "see-more-text" },
                            React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.load_more", defaultMessage: "Load More" }),
                            isLoading === true ? React.createElement(AnimatedLoader, { size: "xxsmall" }) : React.createElement("i", { style: { transform: "rotate(0deg)" }, className: "fa-regular fa-chevron-down" })),
                        React.createElement("span", { className: "see-more-line" },
                            React.createElement("hr", null))))) : null)) : null,
            usersPageContext.Users.length === 0 ? (React.createElement("div", { className: "no-users" },
                React.createElement(FormattedMessage, { id: "component.directory.view_members.there_are_no_types", defaultMessage: "There are no {type}", values: { type: getModeTitle() } }))) : null));
    };
    return (React.createElement("div", { className: "user-list-component-webpart" }, usersPageContext.FirstLoadDone === false ? (React.createElement("div", null,
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 33, marginTop: 15 }),
        React.createElement("div", { className: "list", style: { marginTop: "20px", marginBottom: "0px" } },
            React.createElement("div", { className: "user-container" },
                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 86 }))))) : (React.createElement(React.Fragment, null, RenderListOfUsers()))));
});
