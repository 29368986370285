import * as MetadataListStore from "app/store/metadata/list";
import { AsyncCallType } from "app/store/async/types";
import * as TemplateListTypes from "app/store/templates/list";
export var metadataListActionMap = [
    {
        actionType: MetadataListStore.MetadataListActionTypes.Metadata_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/metadata",
        responseDataType: "",
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: MetadataListStore.MetadataListActionTypes.Metadata_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/metadata",
        responseDataType: "",
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: MetadataListStore.MetadataListActionTypes.Metadata_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/metadata",
        responseDataType: true,
        invalidates: [TemplateListTypes.TemplateListActionTypes.Template_List_Load]
    },
    {
        actionType: MetadataListStore.MetadataListActionTypes.Metadata_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "metadataListState.items",
        keyType: "typeName",
        ApiUrl: "/api/metadata",
        responseDataType: []
    }
];
