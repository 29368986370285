import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import clsx from "clsx";
import React from "react";
import { OrchestryTooltip } from "../orchestry-tooltip/orchestry-tooltip.component";
import "./orchestry-expandable-button.scss";
var EXPANDED_LABEL = "Click to collapse";
var COLLAPSED_LABEL = "Click to expand and see more details";
export var OrchestryExpandableButton = function (_a) {
    var _b;
    var onClick = _a.onClick, expanded = _a.expanded;
    return (React.createElement(OrchestryTooltip, { withinPortal: false, label: expanded ? EXPANDED_LABEL : COLLAPSED_LABEL },
        React.createElement("i", { onClick: onClick, className: clsx("orchestry-expandable-button", (_b = {}, _b[ORCH_ICONS.chevronUp] = expanded, _b[ORCH_ICONS.chevronDown] = !expanded, _b)) })));
};
