var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { useIntl } from "react-intl";
import "./form-validation.scss";
//@ts-ignore
import { isWebUri } from "valid-url";
var FVContext = React.createContext({});
export var FormValidationContext = React.memo(function (props) {
    var _a = React.useState([]), HasFocused = _a[0], setHasFocused = _a[1];
    var _b = React.useState([]), HasBlurred = _b[0], setHasBlurred = _b[1];
    var intl = useIntl();
    var validators = [
        {
            Type: "array-must-have-at-least-2",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.array-must-have-at-least-2", defaultMessage: "[fieldName] requires at least 2 selections" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length < 2) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-not-empty",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_not_empty", defaultMessage: "[fieldName] cannot be empty" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value === "") {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-not-whitespace",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_not_whitespace", defaultMessage: "[fieldName] cannot be whitespace" }),
            IsValid: function (value) {
                if ((value === null || value === void 0 ? void 0 : value.trim()) === "" && value.length > 0) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "number-not-empty",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_not_empty", defaultMessage: "[fieldName] cannot be empty" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-is-more-than-2",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_longer_than_2_characters", defaultMessage: "[fieldName] must be longer than 2 character" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value === "") {
                    return false;
                }
                if (value.length < 3) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-is-more-than-1",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_longer_than_1_characters", defaultMessage: "[fieldName] must be longer than 1 character" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value === "") {
                    return false;
                }
                if (value.length < 2) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-is-more-than-3",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_longer_than_3_characters", defaultMessage: "[fieldName] must be longer than 3 characters" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value === "") {
                    return false;
                }
                if (value.length < 4) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-is-less-than-51",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_cannot_be_longer_than_50_characters", defaultMessage: "[fieldName] cannot be longer than 50 character" }),
            IsValid: function (value) {
                if (value !== undefined && value.length > 50) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "no-illegal-characters",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_cannot_contain_extra_characters", defaultMessage: "[fieldName] cannot contain extra characters such as" }) +
                " !@#$%^&*()_+=[]{};':|,<>?]/",
            IsValid: function (value) {
                var format = /[!@#$%^&*()_+=\[\]{};':"\\|,<>\/?]/;
                if (value !== undefined && format.test(value)) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "no-illegal-characters-less-strict",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_cannot_contain_extra_characters_less_strict", defaultMessage: "[fieldName] cannot contain extra characters such as" }) +
                " !@#$%^&*()_+=[]{};':|,<>?]/",
            IsValid: function (value) {
                var format = /[!@#$%^&*()_+=\[\]{};':"\\|,<>\/?]/;
                if (value !== undefined && format.test(value)) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "dropdown-has-value",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.must_select_fieldname", defaultMessage: "Must select a [fieldName]" }),
            IsValid: function (value) {
                //console.log("dropdown", value);
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value === "") {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "array-has-items",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.must_have_at_least_one_item", defaultMessage: "Must have at least one item" }),
            IsValid: function (value) {
                //console.log("dropdown", value);
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length === 0) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-array-has-items",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.must_have_at_least_one_item_string_array", defaultMessage: "Must have at least one item" }),
            IsValid: function (value) {
                //console.log("dropdown", value);
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length === 0) {
                    return false;
                }
                if (value.indexOf("[") === -1) {
                    //console.log("string-array-has-items - value.indexOf(\"[\") === -1")
                    return false;
                }
                var tempArray = JSON.parse(value);
                if (!Array.isArray(tempArray)) {
                    //console.log("string-array-has-items - !Array.isArray(tempArray)")
                    return false;
                }
                if (tempArray.length === 0) {
                    //console.log("string-array-has-items - tempArray.length === 0")
                    return false;
                }
                return true;
            }
        },
        {
            Type: "is-true",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_has_to_be_true", defaultMessage: "[fieldName] has to be true" }),
            IsValid: function (value) {
                //console.log("Is True", value);
                if (value !== undefined && value === true) {
                    return true;
                }
                return false;
            }
        },
        {
            Type: "is-false",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_has_to_be_false", defaultMessage: "[fieldName] has to be false" }),
            IsValid: function (value) {
                //console.log("Is True", value);
                if (value !== undefined && value === false) {
                    return true;
                }
                return false;
            }
        },
        {
            Type: "is-valid-url",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_url_valid", defaultMessage: "[fieldName] must be a valid URL (starting with http/https)" }),
            IsValid: function (value) {
                // !value so that if you type a URL and then delete, it's not considered invalid still.
                //  If you want to make it required, use the string-not-empty validator.
                if (!value || isWebUri(value))
                    return true;
                return false;
            }
        },
        {
            Type: "is-valid-email",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_email_valid", defaultMessage: "[fieldName] must be a valid email address" }),
            IsValid: function (value) {
                var validateEmail = function (email) {
                    if (email !== undefined && email !== null && email !== "") {
                        var re = /\S+@\S+\.\S+/;
                        return re.test(email);
                    }
                    else {
                        return false;
                    }
                };
                if (validateEmail(value))
                    return true;
                return false;
            }
        },
        {
            Type: "default",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.validation_not_found", defaultMessage: "" }),
            IsValid: function (value) {
                return false;
            }
        },
        {
            Type: "markdown-editor-min-characters-3",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_longer_than_3_characters", defaultMessage: "[fieldName] must be longer than 3 characters" }),
            IsValid: function (value) {
                if (!(value === null || value === void 0 ? void 0 : value.startsWith("[{")))
                    return value === null || value === void 0 ? void 0 : value.length;
                var charCount = 0;
                var values = (value === null || value === void 0 ? void 0 : value.startsWith("[{")) ? JSON.parse(value) : [];
                values === null || values === void 0 ? void 0 : values.forEach(function (el) {
                    //@ts-ignore
                    el.children.forEach(function (child) {
                        if (child.type === "link") {
                            charCount += child.children[0].text.trim().length;
                        }
                        else {
                            charCount += child.text.trim().length;
                        }
                    });
                });
                if (charCount >= 3)
                    return true;
                return false;
            }
        },
        {
            Type: "string-is-less-than-x",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_longer_than_2_characters", defaultMessage: "[fieldName] have fewer characters" }),
            IsValid: function (value, parameter) {
                if ((value || value === "") && (value === null || value === void 0 ? void 0 : value.length) <= Number(parameter))
                    return true;
                return false;
            }
        },
        {
            Type: "does-not-end-in-full-stop",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_not_end_in_full_stop", defaultMessage: "[fieldName] can't end in . (full stop)" }),
            IsValid: function (value, parameter) {
                if (!/.*\.$/.test(value))
                    return true;
                return false;
            }
        },
        {
            Type: "number-greater-than-equalto-x",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_longer_than_2_characters", defaultMessage: "[fieldName] have fewer characters" }),
            IsValid: function (value, parameter) {
                return Number(value) >= Number(parameter);
            }
        },
        {
            Type: "string-is-less-than-30",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_fewer_than_30_characters", defaultMessage: "[fieldName] must be fewer than 30 characters" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length > 30) {
                    return false;
                }
                return true;
            }
        },
        {
            Type: "string-is-less-than-80",
            DefaultErrorMessage: intl.formatMessage({ id: "component.form-validation.fieldname_must_be_fewer_than_80_characters", defaultMessage: "[fieldName] must be fewer than 80 characters" }),
            IsValid: function (value) {
                if (value === undefined) {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length > 80) {
                    return false;
                }
                return true;
            }
        }
    ];
    //const [registeredValidators, setRegisteredValidators] = React.useState([] as Validator[]);
    //@ts-ignore
    var documentVarName = "registeredValidators" + props.UniqueIdentifier;
    var documentVarEventsName = "registeredValidatorsEvents" + props.UniqueIdentifier;
    //@ts-ignore
    if (document[documentVarName] === undefined) {
        //@ts-ignore
        document["registeredValidators" + props.UniqueIdentifier] = [];
    }
    //@ts-ignore
    React.useEffect(function () {
        //console.log("Set Validation to Empty")
        //@ts-ignore
        if (document[documentVarName] === undefined) {
            //@ts-ignore
            document["registeredValidators" + props.UniqueIdentifier] = [];
        }
    }, []);
    //document["registeredValidators" + props.UniqueIdentifier] = [] as Validator[];
    //@ts-ignore
    //console.log("registeredValidators", documentVarName, document[documentVarName]);
    //const [registeredValidators2, setRegisteredValidators2] = React.useState();
    var _c = React.useState(false), isContextValid = _c[0], setIsContextValid = _c[1];
    // React.useEffect(()=>{
    //     setTimeout(()=>{
    //         //props.OnChangeIsValid(isContextValid);
    //     },1000);
    // },[]);
    var GetValidator = function (validatorType) {
        var valType = validators.find(function (v) { return v.Type === "default"; });
        var tryValType = validators.find(function (v) { return v.Type === validatorType; });
        if (tryValType !== undefined && tryValType !== null) {
            valType = tryValType;
        }
        return valType;
    };
    var GetValidatorFunction = function (validatorType) {
        return GetValidator(validatorType).IsValid;
    };
    var GetDefaultValidatorMessage = function (validatorType, fieldName, validateError) {
        var message = GetValidator(validatorType).DefaultErrorMessage;
        if (fieldName !== undefined) {
            message = message.replace("[fieldName]", fieldName);
        }
        else {
            message = message.replace("[fieldName]", "Value");
        }
        return message;
    };
    var IsValidatorValid = function (validatorProps) {
        //Skip validation is already submitted.
        if ((props.IsSubmitted !== undefined && props.IsSubmitted === true) || (validatorProps.Enabled !== undefined && validatorProps.Enabled === false)) {
            //console.log("Skip Validator?")
            return null;
        }
        var validorErrorElements = [];
        var validorErrorMessages = [];
        validatorProps.Validators.forEach(function (validator, index) {
            var isValid = false;
            isValid = GetValidatorFunction(validator)(validatorProps.Value, validatorProps.Parameter);
            if (isValid === false) {
                var errorMessage = GetDefaultValidatorMessage(validator, validatorProps.FieldName, validatorProps.ErrorMessages);
                validorErrorElements.push(React.createElement("div", { key: index, className: "error-item", "data-pw": "error-item" }, errorMessage));
                validorErrorMessages.push(errorMessage);
            }
        });
        var returnHtml = null;
        if (validorErrorElements.length > 0) {
            if (validatorProps.ErrorMessage !== undefined && validatorProps.ErrorMessage !== null && validatorProps.ErrorMessage !== "") {
                returnHtml = (React.createElement("div", { className: "validation-errors" },
                    React.createElement("div", { className: "validation-box" },
                        React.createElement("div", { className: "arrow" }),
                        React.createElement("div", { className: "items" },
                            React.createElement("div", { className: "error-item", "data-pw": "error-item" }, validatorProps.ErrorMessage)))));
                validorErrorMessages = [validatorProps.ErrorMessage];
            }
            else {
                returnHtml = (React.createElement("div", { className: "validation-errors" },
                    React.createElement("div", { className: "validation-box" },
                        React.createElement("div", { className: "arrow" }),
                        React.createElement("div", { className: "items" }, validorErrorElements))));
            }
        }
        AddUpdateRegisteredValidator(validatorProps.UniqueIdentifier, validorErrorElements.length > 0 ? false : true, validorErrorMessages, validatorProps.Category !== undefined ? validatorProps.Category : null);
        return returnHtml;
    };
    var AddUpdateRegisteredValidator = function (uniqueIdent, isValid, validorErrorMessages, category) {
        //@ts-ignore
        // console.log("FIRST > setRegisteredValidators", document[documentVarName], uniqueIdent, isValid);
        // let tempArray = [] as Validator[];
        var isTempValid = true;
        //@ts-ignore
        //if(document[documentVarName].length > 0){
        // //@ts-ignore
        //     tempArray = document[documentVarName].filter(rv => rv.UniqueIdentifier !== uniqueIdent);
        //}
        //@ts-ignore
        if (document[documentVarName].length > 0) {
            //@ts-ignore
            document[documentVarName] = document[documentVarName].filter(function (rv) { return rv.UniqueIdentifier !== uniqueIdent; });
        }
        //@ts-ignore
        document[documentVarName].push({ UniqueIdentifier: uniqueIdent, IsValid: isValid, Errors: validorErrorMessages, Category: category });
        //@ts-ignore
        //console.log("setRegisteredValidators", document[documentVarName]);
        //setRegisteredValidators(tempArray);
        //@ts-ignore
        document[documentVarName].forEach(function (rv) {
            if (rv.IsValid === false) {
                isTempValid = false;
            }
        });
        if (props.IsSubmitted === undefined || (props.IsSubmitted !== undefined && props.IsSubmitted !== true)) {
            //if(isContextValid !== isTempValid){
            setIsContextValid(isTempValid);
            props.OnChangeIsValid(isTempValid);
            //}
        }
        //@ts-ignore
        emitCustomEvent(fvc.rootProps.UniqueIdentifier + "RollupCount", document[documentVarName]);
    };
    var TryRemoveRegisteredValidator = function (uniqueIdent) {
        //@ts-ignore
        //console.log("try to remove", uniqueIdent, document[documentVarName].length);
        //@ts-ignore
        // if (document[documentVarName].length > 0) {
        //    //@ts-ignore
        //    document[documentVarName] = document[documentVarName].filter(rv => rv.UniqueIdentifier !== uniqueIdent)
        // }
        //@ts-ignore
        //console.log("try to remove 2", uniqueIdent, document[documentVarName].length);
        var continueOnward = function () {
            var isTempValid = true;
            //@ts-ignore
            document[documentVarName].forEach(function (rv) {
                if (rv.IsValid === false) {
                    isTempValid = false;
                }
            });
            //@ts-ignore
            //console.log("try to remove 3", isTempValid);
            if (props.IsSubmitted === undefined || (props.IsSubmitted !== undefined && props.IsSubmitted !== true)) {
                //@ts-ignore
                //console.log("try to remove 4", isTempValid);
                //if(isContextValid !== isTempValid){
                setIsContextValid(isTempValid);
                props.OnChangeIsValid(isTempValid);
                //}
            }
            //@ts-ignore
            emitCustomEvent(fvc.rootProps.UniqueIdentifier + "RollupCount", document[documentVarName]);
        };
        var waitForValidationToBeRemoved = function () {
            setTimeout(function () {
                //@ts-ignore
                if (document[documentVarName].some(function (rv) { return rv.UniqueIdentifier === uniqueIdent; })) {
                    //@ts-ignore
                    document[documentVarName] = document[documentVarName].filter(function (rv) { return rv.UniqueIdentifier !== uniqueIdent; });
                }
                //@ts-ignore
                if (document[documentVarName].some(function (rv) { return rv.UniqueIdentifier === uniqueIdent; })) {
                    waitForValidationToBeRemoved();
                }
                else {
                    continueOnward();
                }
            }, 200);
        };
        waitForValidationToBeRemoved();
    };
    var RemoveFocusItem = function (uid) {
        if (HasFocused.includes(uid)) {
            setHasFocused(HasFocused.filter(function (b) { return (b !== uid ? true : false); }));
        }
        //console.log("Validation Blur > " + uid);
    };
    var RemoveBlurItem = function (uid) {
        if (HasBlurred.includes(uid)) {
            setHasBlurred(HasBlurred.filter(function (b) { return (b !== uid ? true : false); }));
        }
    };
    var AddFocusItem = function (item) {
        if (!HasFocused.includes(item)) {
            //console.log("Focused Items", item);
            HasFocused.push(item);
            setHasFocused(__spreadArray([], HasFocused, true));
        }
        RemoveBlurItem(item);
    };
    var AddBlurItem = function (uid) {
        if (!HasBlurred.includes(uid)) {
            setHasBlurred(__spreadArray(__spreadArray([], HasBlurred, true), [uid], false));
        }
        RemoveFocusItem(uid);
    };
    var fvc = {
        rootProps: props,
        IsContextValid: isContextValid,
        IsValidatorValid: IsValidatorValid,
        AddUpdateRegisteredValidator: AddUpdateRegisteredValidator,
        TryRemoveRegisteredValidator: TryRemoveRegisteredValidator,
        AddFocusItem: AddFocusItem,
        AddBlurItem: AddBlurItem,
        RemoveBlurItem: RemoveBlurItem,
        HasFocused: HasFocused,
        HasBlurred: HasBlurred,
        //@ts-ignore
        RegisteredValidators: document[documentVarName]
    };
    React.useEffect(function () {
        //@ts-ignore
        if (document[documentVarName].length === 0) {
            //console.log("vcalidation Context Effect")
            props.OnChangeIsValid(true);
        }
        // //@ts-ignore
        // document["triggerValidation"] = (uniqueIdentifier: string) => {
        //    let documentVarNamer = "registeredValidators" + uniqueIdentifier;
        //    //@ts-ignore
        //    emitCustomEvent(uniqueIdentifier + "RollupCount", document[documentVarNamer] as Validator[]);
        // };
    }, []);
    return (React.createElement(FVContext.Provider, { value: fvc },
        React.createElement("div", { className: "form-validation" }, props.children)));
});
export default FormValidationContext;
export var FormValidator = React.memo(function (props) {
    var fvc = React.useContext(FVContext);
    var _a = React.useState(null), isValidWithElements = _a[0], setIsValidWithElements = _a[1];
    var _b = React.useState(false), startedEmpty = _b[0], setStartedEmpty = _b[1];
    var _c = React.useState(false), hasBeenValidYet = _c[0], setHasBeenValidYet = _c[1];
    var _d = React.useState(false), hasBeenFocused = _d[0], setHasBeenFocused = _d[1];
    var _e = React.useState(false), hasFocus = _e[0], setHasFocus = _e[1];
    var _f = React.useState(false), isBlurred = _f[0], setIsBlurred = _f[1];
    React.useEffect(function () {
        //emitCustomEvent(fvc.rootProps.UniqueIdentifier + "RollupCount", fvc.RegisteredValidators);
        //emitCustomEvent(fvc.rootProps.UniqueIdentifier + "RollupCount");
        //console.log("Validator Included", props.UniqueIdentifier);
        //return () => emitCustomEvent(fvc.rootProps.UniqueIdentifier + "RollupCount", fvc.RegisteredValidators);
    }, []);
    //console.log("Form Validation", props.UniqueIdentifier, props.Value)
    //const isValidWithElements = null;
    React.useEffect(function () {
        //console.log("Form Validation", props.UniqueIdentifier, props.Value)
        if (props.Enabled === false) {
            fvc.TryRemoveRegisteredValidator(props.UniqueIdentifier);
            setIsValidWithElements(fvc.IsValidatorValid(props));
        }
        else {
            setIsValidWithElements(fvc.IsValidatorValid(props));
        }
    }, [props.Value, props.Enabled, props.OnScreen]);
    React.useEffect(function () {
        if (props.Value === undefined || props.Value === null || props.Value === "" || props.Value.length === 0 || typeof props.Value === "boolean") {
            setStartedEmpty(true);
        }
        if (props.ShowFirst !== undefined && props.ShowFirst === true) {
            setHasBeenValidYet(true);
        }
        //if(props.Value !== undefined && props.Value !== null && props.Value.length > 0){
        if (props.Value !== undefined && props.Value !== null && props.Value.length > 0) {
            setHasBeenValidYet(true);
        }
        return function () {
            //console.log("Validator Unmounted?", props.UniqueIdentifier);
            fvc.TryRemoveRegisteredValidator(props.UniqueIdentifier);
        };
    }, []);
    React.useEffect(function () {
        //if(startedEmpty === true && hasBeenValidYet === false){
        //if(startedEmpty === true && (props.Value !== undefined && props.Value !== null && props.Value.length > 0)){
        if (startedEmpty === true && props.Value !== undefined && props.Value !== null) {
            if (isValidWithElements === null) {
                setHasBeenValidYet(true);
            }
        }
    }, [props.Value, isValidWithElements]);
    React.useEffect(function () {
        if (fvc.HasFocused.includes(props.UniqueIdentifier)) {
            if (hasFocus === false) {
                setHasFocus(true);
            }
            // This gets set once and stays that way.
            if (hasBeenFocused === false) {
                setHasBeenFocused(true);
            }
        }
        else {
            if (hasFocus === true) {
                setHasFocus(false);
            }
        }
    }, [fvc.HasFocused]);
    React.useEffect(function () {
        if (fvc.HasBlurred.includes(props.UniqueIdentifier)) {
            if (isBlurred === false) {
                setIsBlurred(true);
            }
        }
        else {
            if (isBlurred === true) {
                setIsBlurred(false);
            }
        }
    }, [fvc.HasBlurred]);
    React.useEffect(function () {
        if (isBlurred === true) {
            //console.log("Field Blurred > " + props.UniqueIdentifier);
            setHasBeenValidYet(true);
            //@ts-ignore
            setIsValidWithElements(fvc.IsValidatorValid(props));
        }
    }, [isBlurred]);
    React.useEffect(function () {
        if (hasFocus === true) {
            //console.log("Field Focused > " + props.UniqueIdentifier);
        }
    }, [hasFocus]);
    if (props.Hide !== undefined && props.Hide === false) {
        return React.createElement(React.Fragment, null);
    }
    else {
        return (React.createElement("div", { className: "validator-collapse" + (props.Hide !== undefined && props.Hide === true ? " hide-validator" : "") },
            React.createElement(Collapse, { in: (isValidWithElements !== null && hasBeenValidYet !== false) || (props.ShowOnFocus !== undefined && props.ShowOnFocus === true && hasBeenFocused === true) },
                React.createElement("div", { className: props.ShowAsInfo !== undefined && props.ShowAsInfo === true ? " info" : "" }, isValidWithElements))));
    }
});
export var FormValidatorWrappingElement = React.memo(function (props) {
    var fvc = React.useContext(FVContext);
    var isValid = true;
    if (fvc.RegisteredValidators !== undefined) {
        if (fvc.RegisteredValidators.length > 0) {
            fvc.RegisteredValidators.forEach(function (rv) {
                if (rv.UniqueIdentifier === props.UniqueIdentifier) {
                    if (rv.IsValid === false) {
                        isValid = false;
                    }
                }
            });
        }
    }
    else {
        isValid = false;
    }
    var wrapperClassName = "";
    if (isValid) {
        if (props.ClassNameValid !== undefined) {
            wrapperClassName = " " + props.ClassNameValid;
        }
        else {
            wrapperClassName = " validator-is-valid";
        }
    }
    else {
        if (props.ClassNameNotValid !== undefined) {
            wrapperClassName = " " + props.ClassNameNotValid;
        }
        else {
            wrapperClassName = " validator-is-not-valid";
        }
    }
    return (React.createElement("div", { className: "validator-wrapper " + wrapperClassName, onBlur: function () { return fvc.AddBlurItem(props.UniqueIdentifier); }, onFocus: function () {
            fvc.AddFocusItem(props.UniqueIdentifier);
        } }, props.children));
});
export var FormValidatorShowHide = function (props) {
    var fvc = React.useContext(FVContext);
    //consoconsole.log("validatorShowHide", props.OnValidShow, props.IsValid);
    // React.useEffect(()=>{
    // },[fvc]);
    return (React.createElement(React.Fragment, null,
        props.OnValidShow === true && (fvc.IsContextValid === true || props.IsValid === true) ? React.createElement(React.Fragment, null, props.children) : null,
        props.OnValidShow === false && (fvc.IsContextValid === false || props.IsValid === false) ? React.createElement(React.Fragment, null, props.children) : null));
};
export var FormValidatorFocus = function (props) {
    var fvc = React.useContext(FVContext);
    return (React.createElement("div", { onFocus: function () {
            fvc.AddFocusItem(props.UniqueIdentifier);
        } }, props.children));
};
export var FormValidatorRollup = function (props) {
    var validators = document["registeredValidators" + props.ContextUniqueIdentifier];
    var _a = React.useState([]), errors = _a[0], setErrors = _a[1];
    useCustomEventListener(props.ContextUniqueIdentifier + "RollupCount", function (validators) {
        //    console.log("Validator Loaded Event", validators);
        //    if(validators === undefined) {
        //const validators2 = (document as IIndexable)["registeredValidators" + props.ContextUniqueIdentifier] as Validator[];
        //    }
        //if(validators2 !== undefined && validators2 !== null){
        //setTimeout(()=>{
        getErrors();
        //},500);
        //}
    });
    React.useEffect(function () {
        //console.log("Form Validation Rollup Fired");
        if (validators !== undefined && validators !== null) {
            getErrors();
        }
    }, [validators]);
    React.useEffect(function () {
        //console.log("Form Validation Rollup Fired");
        if (validators !== undefined && validators !== null) {
            getErrors();
        }
    }, [props.RefreshProperty]);
    var getErrors = function (validators) {
        var tempErrors = [];
        if (validators === undefined) {
            validators = document["registeredValidators" + props.ContextUniqueIdentifier];
        }
        validators.forEach(function (validator) {
            if (props.Category === undefined || (props.Category !== undefined && validator.Category !== null && props.Category === validator.Category)) {
                if (validator.IsValid === false) {
                    tempErrors = __spreadArray(__spreadArray([], tempErrors, true), validator.Errors, true);
                }
            }
        });
        setErrors(tempErrors);
    };
    return validators && errors.length > 0 ? (React.createElement("div", { className: "form-validator-rollup" + (props.HoverPlacement !== undefined ? " " + props.HoverPlacement : " top") },
        React.createElement("i", { className: "fas fa-exclamation-triangle" }),
        React.createElement("span", null, errors.length),
        React.createElement("ul", { className: "errors" }, errors.map(function (error, index) { return (React.createElement("li", { className: "error", key: "error-key-" + index }, error)); })))) : null;
};
