import AnimatedLoader from "app/components/common/animated-loader";
import AzureStorageSetup from "app/components/common/azure-storage-setup";
import CosmosDbSetup from "app/components/common/cosmosdb-setup";
import FormToggleControl from "app/components/common/form-toggle-control";
import PortletAlert from "app/components/common/portlet-alert";
import ProgressReport from "app/components/common/progress-report";
import TenantDbSetup from "app/components/common/tenantdb-setup";
import { InstallWizardContext } from "app/components/installer/installer-wizard";
import InstallService from "app/services/install";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
var InstallerStepInstall = React.memo(function (props) {
    var iwc = React.useContext(InstallWizardContext);
    var _a = React.useState(false), jobComplete = _a[0], setJobComplete = _a[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _b = React.useState(false), jobStartClick = _b[0], setJobStartClick = _b[1];
    var _c = React.useState(), jobId = _c[0], setJobId = _c[1];
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var dispatch = useDispatch();
    //const [installDone, setInstallDone] = React.useState(false);
    var startInstall = function () {
        setJobStartClick(true);
        InstallService.start(iwc.accessCode).then(function (data) {
            setJobId(data);
            console.log("Start Install Data", data);
        });
    };
    var jobDone = function () {
        setJobId(undefined);
        //setInstallDone(true);
        iwc.setStepProgress(props.step.key, true);
    };
    React.useEffect(function () {
        // Check to see if granting can be skipped.
        if (iwc.force === false) {
            // TenantService.isInstalledLatestVersion().then((canSkip)=>{
            //     if(canSkip){
            //         setJobId(undefined);
            //         iwc.setStepProgress(props.step.key, true);
            //     }
            // })
            if (orchestryContext.LastInstalledVersion !== null && Number(orchestryContext.LastInstalledVersion) >= orchestryContext.OrchestryAppInstallVersion) {
                //if(currentTenant.CanSkipInstall){
                setJobId(undefined);
                iwc.setStepProgress(props.step.key, true);
            }
        }
    }, []);
    React.useEffect(function () {
        dispatch(TenantCurrentStore.UpdateSilent());
    }, [currentTenant.SkipTeamsAppInstall]);
    return (React.createElement("div", { className: "installer-step-install fadein-500ms" },
        React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
            React.createElement("div", { className: "kt-heading kt-heading--md" },
                React.createElement("h2", null, props.isUpgrade ? "Update" : "Install")),
            React.createElement("div", { className: "kt-form__section kt-form__section--first" },
                React.createElement("div", { className: "kt-wizard-v2__form" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col" },
                            React.createElement("div", { className: "form-group" },
                                jobId !== undefined && jobId !== null && jobId !== "" ? (React.createElement(ProgressReport, { jobId: jobId, onComplete: function () { return jobDone(); }, turnOffAfter: true, showBeforeJobId: true, hideProgressLog: false })) : null,
                                props.step.canProgress === false ? (jobId === undefined ? (React.createElement(React.Fragment, null,
                                    React.createElement("p", null, "Please note that this process could take up to 15 minutes. Please be patient and do not leave the page."),
                                    React.createElement(FormToggleControl, { checked: !currentTenant.SkipTeamsAppInstall, label: "Install Orchestry Teams App", handleChange: function (checked) { return dispatch(TenantCurrentStore.Update({ SkipTeamsAppInstall: !checked })); } }),
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "cosmos-db-setup" },
                                            React.createElement("div", { className: "cosmos-title" }, "Lifecycle Cosmos Db Setup"),
                                            React.createElement(CosmosDbSetup, null)),
                                        React.createElement("div", { className: "cosmos-db-setup" },
                                            React.createElement("div", { className: "cosmos-title" }, "Lifecycle Azure Table Storage Setup"),
                                            React.createElement(AzureStorageSetup, null)),
                                        React.createElement("div", { className: "cosmos-db-setup" },
                                            React.createElement("div", { className: "cosmos-title" }, "Tenant Sql Db Setup"),
                                            React.createElement(TenantDbSetup, null))),
                                    jobStartClick === false ? (React.createElement("div", { className: "btn btn-success", onClick: function () {
                                            startInstall();
                                        } },
                                        "Start Orchestry ",
                                        props.isUpgrade ? "Update" : "Install")) : (React.createElement(AnimatedLoader, { size: "xsmall" })))) : null) : (React.createElement("div", { className: "install-complete" },
                                    React.createElement(Row, null,
                                        React.createElement(Col, null,
                                            React.createElement(PortletAlert, { type: "success", icon: "check", size: "regular", title: "Perfect, " + (props.isUpgrade ? "update" : "install") + " is complete!", message: "You may now proceed to the next step!" })))))))))))));
});
export default InstallerStepInstall;
