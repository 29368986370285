import "@mantine/core/styles.css";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryTabs } from "appv2/components/orchestry-tabs/orchestry-tabs.component";
import { useTenantHealthChecksPage } from "appv2/pages/beacon/health-checks/use-tenant-health-checks-page.hook";
import React from "react";
import "./tenant-page.scss";
import { useTenantPage } from "./use-tenant-page.hook";
export var TenantPage = function () {
    var _a = useTenantPage(), intl = _a.intl, tabs = _a.tabs, tab = _a.tab, tabsOnChange = _a.tabsOnChange, PageComponent = _a.PageComponent, onBackButtonClicked = _a.onBackButtonClicked, tenantDetails = _a.tenantDetails, isPageLoading = _a.isPageLoading;
    var _b = useTenantHealthChecksPage(tab), canGenerateHealthCheck = _b.canGenerateHealthCheck, onNewClicked = _b.onNewClicked;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement(Subtitle, { title: "Customer Tenants", subtitle: tenantDetails.TenantName },
                React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, "Go Back to Customer Tenants")),
            React.createElement("div", { className: "tenant-page" },
                React.createElement(OrchestryPageHeader, null,
                    React.createElement(OrchestryPageTitle, { isLoading: isPageLoading, title: tenantDetails.TenantName, subTitle: "Tenant Details", titleIcon: ORCH_ICONS.building06, titleIconColor: "var(--mantine-color-primary-7)" }),
                    React.createElement(OrchestryActionButtons, null, canGenerateHealthCheck && (React.createElement(OrchestryButton, { onClick: onNewClicked, isLoading: isPageLoading, skeletonHeight: 34, skeletonWidth: 80, iconType: "font", size: "md", iconLeft: ORCH_ICONS.plusCircle, variant: "secondary-gray" }, intl.formatMessage({ id: "controls.new", defaultMessage: "New" }))))),
                React.createElement(OrchestryTabs, { tabs: tabs, tab: tab, onTabChange: tabsOnChange, mb: 24 }),
                React.createElement(PageComponent, null)))));
};
