import * as React from "react";
import "./card-private-tenant-db.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import TenantDbSetup from "app/components/common/tenantdb-setup";
var CardPrivateTenantDb = React.memo(function (_a) {
    var _b = _a.ShowPortlet, ShowPortlet = _b === void 0 ? true : _b;
    return (React.createElement("div", { className: "card-private-cosmos-db" }, ShowPortlet ? (React.createElement(Portlet, null,
        React.createElement(PortletHeader, { title: "Sql Tenant DB Setup" }),
        React.createElement(PortletBody, null,
            React.createElement(TenantDbSetup, { LoadTenantConfigStore: true })))) : (React.createElement(TenantDbSetup, null))));
});
export default CardPrivateTenantDb;
