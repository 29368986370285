import * as React from "react";
import { StylingButton } from "./StyleButton";
import { AddLinkButton, RemoveLinkButton } from "./LinkButtons";
import "./toolbar.scss";
export var Toolbar = function (_a) {
    var layout = _a.layout, enabledStyles = _a.enabledStyles;
    return (React.createElement("div", { className: "toolbar" },
        React.createElement("div", { className: "textMarkupContainer ".concat(layout === "text" ? "no-image" : "", " ").concat(layout === "image" || layout === "default" ? "hidden" : "") }, enabledStyles.all ? Object.keys(StyleButtons).map(function (style) { return StyleButtons[style]; }) : Object.keys(enabledStyles).map(function (style) { return (enabledStyles[style] ? StyleButtons[style] : null); }))));
};
//container for all the style button components
var StyleButtons = {
    bold: React.createElement(StylingButton, { style: { fontSize: 11 }, format: "bold", icon: "Bold" }),
    italic: React.createElement(StylingButton, { style: { fontSize: 11 }, format: "italic", icon: "Italic" }),
    underline: React.createElement(StylingButton, { style: { fontSize: 11 }, format: "underline", icon: "Underline" }),
    h1: React.createElement(StylingButton, { style: { fontSize: 13 }, format: "h1", icon: "Header1" }),
    h2: React.createElement(StylingButton, { style: { fontSize: 13 }, format: "h2", icon: "Header2" }),
    h3: React.createElement(StylingButton, { style: { fontSize: 13 }, format: "h3", icon: "Header3" }),
    addLink: React.createElement(AddLinkButton, { icon: "AddLink" }),
    removeLink: React.createElement(RemoveLinkButton, { icon: "RemoveLink" })
};
export default Toolbar;
