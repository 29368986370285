import { useGetMspTenantsQuery } from "appv2/api/partner-tools/partners.api";
import { usePageSearch } from "appv2/hooks/use-page-search.hook";
import { generateGroupedFilterExpression, getOrderByExpression } from "appv2/utils/paging.utils";
import { useEffect, useState } from "react";
export var usePartnerToolsApi = function (appliedFilters) {
    var _a = useState(1), activePage = _a[0], setActivePage = _a[1];
    var _b = useState(function () { var _a; return (_a = localStorage.getItem("partner-tools-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"; }), resultsPerPage = _b[0], setResultsPerPage = _b[1];
    var _c = usePageSearch(setActivePage), querySearchText = _c.querySearchText, highlightSearchWords = _c.highlightSearchWords, searchTextInputValue = _c.searchTextInputValue, onSearchBoxChange = _c.onSearchBoxChange, onSearchBoxKeyDown = _c.onSearchBoxKeyDown, onSearchBoxXClicked = _c.onSearchBoxXClicked;
    var combinedFilters = generateGroupedFilterExpression(appliedFilters);
    var _d = useState([
        { id: "Actions", desc: true },
        { id: "DisplayName", desc: false }
    ]), sorting = _d[0], setSorting = _d[1];
    var _e = useGetMspTenantsQuery({
        queryParameters: {
            filter: combinedFilters,
            orderBy: getOrderByExpression(sorting),
            page: activePage,
            pageSize: Number(resultsPerPage)
        },
        searchText: querySearchText
    }), pagedMspTenants = _e.data, pagedMspTenantsIsFetching = _e.isFetching, pagedMspTenantsIsLoading = _e.isLoading, pagedMspTenantsRefetch = _e.refetch;
    // Refetch the tenants when the page is navigated away from and back to
    useEffect(function () {
        pagedMspTenantsRefetch();
    }, [pagedMspTenantsRefetch]);
    var totalMspTenantsCount = pagedMspTenants === null || pagedMspTenants === void 0 ? void 0 : pagedMspTenants.TotalCount;
    var pageTotal = pagedMspTenants === null || pagedMspTenants === void 0 ? void 0 : pagedMspTenants.PageTotal;
    var isPageLoading = pagedMspTenantsIsLoading;
    var isTableLoading = pagedMspTenantsIsFetching;
    return {
        pagedMspTenants: pagedMspTenants,
        totalMspTenantsCount: totalMspTenantsCount,
        pageTotal: pageTotal,
        isPageLoading: isPageLoading,
        isTableLoading: isTableLoading,
        sorting: sorting,
        setSorting: setSorting,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        searchTextInputValue: searchTextInputValue,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        highlightSearchWords: highlightSearchWords
    };
};
