export var TenantListActionTypes;
(function (TenantListActionTypes) {
    TenantListActionTypes["Tenant_List_Load"] = "Tenant_List_Load";
    TenantListActionTypes["Tenant_List_Load_Page"] = "Tenant_List_Load_Page";
    TenantListActionTypes["Tenant_List_Refresh"] = "Tenant_List_Refresh";
    TenantListActionTypes["Tenant_List_LoadForRequest"] = "Tenant_List_LoadForRequest";
    TenantListActionTypes["Tenant_List_Item_Add"] = "Tenant_List_Item_Add";
    TenantListActionTypes["Tenant_List_Item_Delete"] = "Tenant_List_Item_Delete";
    TenantListActionTypes["Tenant_List_Item_Update"] = "Tenant_List_Item_Update";
    TenantListActionTypes["Tenant_List_Item_Can_Delete"] = "Tenant_List_Item_Can_Delete";
})(TenantListActionTypes || (TenantListActionTypes = {}));
