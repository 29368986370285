import { toAbsoluteUrl } from "_metronic";
import { TenantLicenseStatus } from "app/store/tenants";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import "./orchestry-check-subscribed.scss";
var OrchestryCheckSubscribed = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var isInTrial = function () {
        var trial = false;
        if (orchestryContext.DoesTenantHaveSubscription === false) {
            if (orchestryContext.TrialExpires !== undefined && orchestryContext.TrialExpires !== null && orchestryContext.LicenseStatus == TenantLicenseStatus.OrchestryTrial) {
                var expiresDatetime = moment.utc(orchestryContext.TrialExpires).local();
                if (expiresDatetime.isValid()) {
                    if (expiresDatetime.isAfter(moment.now())) {
                        trial = true;
                    }
                }
            }
        }
        return trial;
    };
    var IsInTrial = isInTrial();
    if (orchestryContext.DoesTenantHaveSubscription || IsInTrial) {
        return React.createElement(React.Fragment, null, props.children);
    }
    else {
        return (React.createElement("div", { className: "not-subscribed" },
            React.createElement("img", { src: toAbsoluteUrl("/logo192.png"), alt: "orchestyr logo" }),
            React.createElement("div", { className: "title" }, "We cannot find your organization's subscription in our records!"),
            React.createElement("div", { className: "sub-title" }, "If you are subscribed and you're seeing this message please contact Orchestry customer support."),
            React.createElement("p", null,
                "If you are not subscribed, please proceed to ",
                React.createElement("a", { href: "https://www.orchestry.com" }, "Orchestry.com"))));
    }
});
export default OrchestryCheckSubscribed;
