import * as React from "react";
import * as AsyncStore from "app/store/async";
import { Generic_Toast_Notification } from "app/store/notifications/types";
import { toast } from "react-toastify";
import NotificationContent from "app/components/common/notification-content";
import { notificationConfigMap } from "./config";
export var notificationToastMiddlware = function (store, next, action) {
    //console.log("action", action);
    var toastText = "";
    var replaceToken = function (words) {
        if (words.indexOf(".Title") > -1) {
            var itemTitle = action.payload.itemPayload.Title;
            return words.replace("{Item.Title}", itemTitle);
        }
        if (words.indexOf(".Name") > -1) {
            var itemName = action.payload.itemPayload.Name;
            return words.replace("{Item.Name}", itemName);
        }
        return words;
    };
    notificationConfigMap.forEach(function (ncm) {
        // ## ASYNC START
        if (action.type === AsyncStore.AsyncActionTypes.Async_Start && ncm.ActionType === AsyncStore.AsyncActionTypes.Async_Start) {
            ncm.Actions.forEach(function (ncmAction) {
                if (action.payload.actionType === ncmAction.ActionType) {
                    toastText = ncmAction.Text;
                }
            });
            if (toastText.length > 0) {
                toast(React.createElement(NotificationContent, { text: replaceToken(toastText), mode: "loading" }), {
                    toastId: action.payload.key,
                    autoClose: false,
                    type: toast.TYPE.DEFAULT
                });
            }
        }
        // ## ASYNC SUCCEED
        if (action.type === AsyncStore.AsyncActionTypes.Async_Succeed && ncm.ActionType === AsyncStore.AsyncActionTypes.Async_Succeed) {
            ncm.Actions.forEach(function (ncmAction) {
                if (action.payload.actionType === ncmAction.ActionType) {
                    toastText = ncmAction.Text;
                }
            });
            if (toastText.length > 0) {
                console.log("Notification Match", toastText);
                toast.update(action.payload.key, {
                    render: React.createElement(NotificationContent, { text: replaceToken(toastText), mode: "success" }),
                    type: toast.TYPE.DEFAULT,
                    autoClose: 2500
                });
            }
        }
        // ## ASYNC ERROR
        if (action.type === AsyncStore.AsyncActionTypes.Async_Failed && ncm.ActionType === AsyncStore.AsyncActionTypes.Async_Failed) {
            ncm.Actions.forEach(function (ncmAction) {
                if (action.payload.actionType === ncmAction.ActionType) {
                    toastText = ncmAction.Text;
                }
            });
            if (toastText.length > 0) {
                if (action.payload.actionType.indexOf("List_Load") < 0) {
                    toast.update(action.payload.key, {
                        render: React.createElement(NotificationContent, { text: replaceToken(toastText), mode: "error" }),
                        type: toast.TYPE.DEFAULT,
                        autoClose: 2500
                    });
                }
                else {
                    toast(React.createElement(NotificationContent, { text: replaceToken(toastText), mode: "error" }), {
                        toastId: action.payload.key,
                        autoClose: false,
                        type: toast.TYPE.DEFAULT
                    });
                }
            }
        }
        //generic success notification
        if (action.type === Generic_Toast_Notification.Generic_Success && ncm.ActionType === Generic_Toast_Notification.Generic_Success) {
            ncm.Actions.forEach(function (ncmAction) {
                if (action.payload.actionType === ncmAction.ActionType) {
                    toastText = ncmAction.Text;
                }
            });
            if (toastText.length > 0) {
                toast(React.createElement(NotificationContent, { text: replaceToken(toastText), mode: "success" }), {
                    toastId: action.payload.key,
                    autoClose: 3500,
                    type: toast.TYPE.DEFAULT
                });
            }
        }
        //generic failure notification
        if (action.type === Generic_Toast_Notification.Generic_Failure && ncm.ActionType === Generic_Toast_Notification.Generic_Failure) {
            ncm.Actions.forEach(function (ncmAction) {
                if (action.payload.actionType === ncmAction.ActionType) {
                    toastText = ncmAction.Text;
                }
            });
            if (toastText.length > 0) {
                toast(React.createElement(NotificationContent, { text: replaceToken(toastText), mode: "error" }), {
                    toastId: action.payload.key,
                    autoClose: 7500,
                    type: toast.TYPE.ERROR
                });
            }
        }
    });
    // // ## ASYNC START
    // if(action.type === AsyncStore.AsyncActionTypes.Async_Start){
    //     let itemTitle = action.payload.itemPayload.Title;
    //     // ## ASYNC UPDATE
    //     // Feature Item Add
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Add)
    //         toastText = "Adding <strong>" + itemTitle + "</strong> to features list...";
    //     // Feature Item Update
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Update)
    //         toastText = "Updating <strong>" + itemTitle + "</strong> in features list...";
    //     // Feature Item Delete
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Delete)
    //         toastText = "Deleting <strong>" + itemTitle + "</strong> from features list...";
    //     // Template Item Add
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Add)
    //         toastText = "Adding <strong>" + itemTitle + "</strong> to templates list...";
    //     // Template Item Update
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Update)
    //         toastText = "Updating <strong>" + itemTitle + "</strong> in templates list...";
    //     // Template Item Delete
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Delete)
    //         toastText = "Deleting <strong>" + itemTitle + "</strong> from templates list...";
    //     // Channel Item Add
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add)
    //     toastText = "Adding <strong>" + itemTitle + "</strong> to channels list...";
    //     // Channel Item Update
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update)
    //     toastText = "Updating <strong>" + itemTitle + "</strong> in channels list...";
    //     // Channel Item Delete
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete)
    //     toastText = "Deleting <strong>" + itemTitle + "</strong> from channels list...";
    //     if(toastText.length > 0){
    //         toast(<NotificationContent text={toastText} mode="loading" />, {
    //             toastId:action.payload.key,
    //             autoClose: false,
    //             type: toast.TYPE.DEFAULT
    //         })
    //     }
    // }
    // // ## ASYNC SUCCEED
    // if(action.type === AsyncStore.AsyncActionTypes.Async_Succeed){
    //     let itemTitle = action.payload.itemPayload.Title;
    //     // Feature Item Add
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Add)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully added to features list.";
    //     // Feature Item Update
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Update)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully updated in features list.";
    //     // Feature Item Delete
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Delete)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully deleted from features list.";
    //     // Template Item Add
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Add)
    //     toastText = "<strong>" + itemTitle + "</strong> successfully added to templates list.";
    //     // Template Item Update
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Update)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully updated in templates list.";
    //     // Template Item Delete
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Delete)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully deleted from templates list.";
    //     // Channel Item Add
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add)
    //     toastText = "<strong>" + itemTitle + "</strong> successfully added to channels list.";
    //     // Channel Item Update
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully updated in channels list.";
    //     // Channel Item Delete
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete)
    //         toastText = "<strong>" + itemTitle + "</strong> successfully deleted from channels list.";
    //     if(toastText.length > 0){
    //         toast.update(action.payload.key, {
    //             render: <NotificationContent text={toastText} mode="success" />,
    //             type: toast.TYPE.DEFAULT,
    //             autoClose: 5000
    //         });
    //     }
    // }
    // // ## ASYNC ERROR
    // if(action.type === AsyncStore.AsyncActionTypes.Async_Failed){
    //     let itemTitle = action.payload.itemPayload.Title;
    //     // Feature Item Add
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Add)
    //         toastText = "Error when adding <strong>" + itemTitle + "</strong> to features list.";
    //     // Feature Item Update
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Update)
    //         toastText = "Error when updating <strong>" + itemTitle + "</strong> to features list.";
    //     // Feature Item Delete
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Item_Delete)
    //         toastText = "Error when deleting <strong>" + itemTitle + "</strong> from features list.";
    //     // Template Item Add
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Add)
    //         toastText = "Error when adding <strong>" + itemTitle + "</strong> to templates list.";
    //     // Template Item Update
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Update)
    //         toastText = "Error when updating <strong>" + itemTitle + "</strong> to templates list.";
    //     // Template Item Delete
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Item_Delete)
    //         toastText = "Error when deleting <strong>" + itemTitle + "</strong> from templates list.";
    //     // Channel Item Add
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Add)
    //         toastText = "Error when adding <strong>" + itemTitle + "</strong> to channels list.";
    //     // Channel Item Update
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Update)
    //         toastText = "Error when updating <strong>" + itemTitle + "</strong> to channels list.";
    //     // Channel Item Delete
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Item_Delete)
    //         toastText = "Error when deleting <strong>" + itemTitle + "</strong> from channels list.";
    //     if(toastText.length > 0){
    //         toast.update(action.payload.key, {
    //             render: <NotificationContent text={toastText} mode="error" />,
    //             type: toast.TYPE.DEFAULT,
    //             autoClose: 3000
    //         });
    //     }
    // }
    // // THIS IS FOR FAILED ITEMS THAT ARE ITEMS, BUT FOR LOADING ACTIONS
    // if(action.type === AsyncStore.AsyncActionTypes.Async_Failed){
    //     if(action.payload.actionType === TemplateListStore.TemplateListActionTypes.Template_List_Load)
    //     toastText = "Error when trying to load template list.";
    //     if(action.payload.actionType === FeatureListStore.FeatureListActionTypes.Feature_List_Load)
    //     toastText = "Error when trying to load features list.";
    //     if(action.payload.actionType === ChannelListStore.ChannelListActionTypes.Channel_List_Load)
    //     toastText = "Error when trying to load channels list.";
    //     if(toastText.length > 0){
    //         toast(<NotificationContent text={toastText} mode="error" />, {
    //             toastId:action.payload.key,
    //             autoClose: false,
    //             type: toast.TYPE.DEFAULT
    //         })
    //     }
    // }
    next(action);
};
