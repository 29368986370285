export var determinePadding = function (shouldRenderFontIcon, shouldRenderSVGIcon, shouldRenderClose) {
    if ((shouldRenderFontIcon || shouldRenderSVGIcon) && shouldRenderClose) {
        return "icon-and-close";
    }
    else if (shouldRenderFontIcon || (shouldRenderSVGIcon && !shouldRenderClose)) {
        return "icon-left";
    }
    else {
        return "no-icon";
    }
};
