var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import "./form-toggle.scss";
import generateGuid from "app/utils/generateGuid";
// interface IState {
//     checked?: boolean;
// }
var FormToggle = /** @class */ (function (_super) {
    __extends(FormToggle, _super);
    function FormToggle(props) {
        var _this = _super.call(this, props) || this;
        _this.handleKeydown = function (event) {
            var _a;
            var isInDD = false;
            if (_this.checkDiv.current === event.target) {
                isInDD = true;
            }
            else if (event.target !== null && ((_a = _this.checkDiv.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                isInDD = true;
            }
            //console.log(isInDD, "Enter Clicked");
            // Enter
            if (isInDD && event.keyCode === 13) {
                //@ts-ignore
                _this.props.handleChange(!_this.checkInput.current.checked);
            }
        };
        _this.checkDiv = React.createRef();
        _this.checkInput = React.createRef();
        return _this;
    }
    // public state: IState = {
    //     checked: this.props.checked,
    // };
    // handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     //if(event.target.checked != null) {
    //         console.log("clicked")
    //         this.setState({ checked: event.target.checked })
    //     //}
    // }
    FormToggle.prototype.componentWillUnmount = function () {
        document.removeEventListener("keydown", this.handleKeydown);
    };
    FormToggle.prototype.componentDidMount = function () {
        document.addEventListener("keydown", this.handleKeydown);
    };
    FormToggle.prototype.render = function () {
        var _this = this;
        var guid = generateGuid();
        return (React.createElement("div", { ref: this.checkDiv, tabIndex: 0, className: "form-toggle theme-" + (this.props.theme === undefined ? "normal" : this.props.theme) },
            React.createElement("input", { type: "checkbox", id: "slider-" + guid, ref: this.checkInput, disabled: this.props.disabled !== undefined && this.props.disabled === true ? true : false, checked: this.props.checked, defaultChecked: this.props.defaultChecked, onChange: function (event) { return _this.props.handleChange(event.target.checked); } }),
            React.createElement("label", { htmlFor: "slider-" + guid, className: "slider round", "data-pw": "form-toggle" },
                React.createElement("div", { className: "checkbox-true" }, "Yes"),
                React.createElement("div", { className: "checkbox-false" }, "No"))));
    };
    return FormToggle;
}(React.Component));
export default FormToggle;
