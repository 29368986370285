import axios from "axios";
var FilesService = /** @class */ (function () {
    function FilesService() {
    }
    FilesService.uploadDocument = function (data, relativeUrl, onUploadProgress) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = relativeUrl;
            axiosConfig.responseType = "blob";
            axiosConfig.headers = {
                "Content-Type": "multipart/form-data"
            };
            axiosConfig.data = data;
            if (onUploadProgress !== undefined) {
                axiosConfig.onUploadProgress = onUploadProgress;
            }
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Document Template Upload" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    FilesService.copyAzureDocument = function (data, relativeUrl) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = relativeUrl;
            axiosConfig.data = data;
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Document Template Upload" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    FilesService.deleteDocument = function (data, relativeUrl) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "POST";
            axiosConfig.url = relativeUrl;
            axiosConfig.data = data;
            axios(axiosConfig)
                .then(function (response) {
                //console.log("Document Template Upload" , response);
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return FilesService;
}());
export default FilesService;
