import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useHealthCheckRouteParams } from "../use-health-check-route-params.hook";
import { useUserIsPartnerNotViewingPartnerLedTenant } from "../use-user-is-partner-viewing-self-led-tenant.hook";
/**
 * Hook to determine if the current user can create a health check.
 */
export var useUserCanCreateHealthCheck = function () {
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var _a = useUserIsPartnerNotViewingPartnerLedTenant(), userIsPartnerViewingNotPartnerLedTenant = _a.userIsPartnerViewingNotPartnerLedTenant, isLoading = _a.isLoading;
    var userType = useHealthCheckRouteParams().userType;
    var userIsPartnerViewingCustomer = userType === "partner";
    var canGenerateHealthCheck = currentUserHasRole(OrchRole.Admin) ||
        currentUserHasRole(OrchRole.BeaconAdmin) ||
        (userIsPartnerViewingCustomer && (currentUserHasRole(OrchRole.PartnerTenantManager) || currentUserHasRole(OrchRole.PartnerHubAdmin)));
    return { canGenerateHealthCheck: canGenerateHealthCheck && !userIsPartnerViewingNotPartnerLedTenant, isLoading: isLoading };
};
