/**
 * Healthcheck Report Generation Method
 */
export var HealthCheckGenerationMethod;
(function (HealthCheckGenerationMethod) {
    /**
     * Manual
     */
    HealthCheckGenerationMethod["Manual"] = "Manual";
    /**
     * Scheduled
     */
    HealthCheckGenerationMethod["Scheduled"] = "Scheduled";
})(HealthCheckGenerationMethod || (HealthCheckGenerationMethod = {}));
