import * as React from "react";
import { Form } from "react-bootstrap";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
var ReportFilterChioce = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group checkbox-green", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        React.createElement(FormDropdownMultiSelect, { list: props.Refiner.ChoiceValues.split(/\r?\n/).map(function (choiceValue, cvIndex) {
                return ({
                    id: choiceValue,
                    title: choiceValue,
                    value: choiceValue
                });
            }), placeholder: "-- Select a " + props.Refiner.Title + " --", values: props.Refiner.CurrentValue !== null && props.Refiner.CurrentValue.length > 0
                ? props.Refiner.CurrentValue.split("|")
                    //.filter((cv:string) => filterTemplates.some((ft) => ft.GUID == cv))
                    .map(function (cv, index) {
                    return ({
                        id: cv,
                        title: cv,
                        value: cv
                    });
                })
                : [], onChange: function (values) { return props.FilterOnChange(props.Refiner, values.map(function (v) { return v.value; }).join("|")); } })));
});
export default ReportFilterChioce;
