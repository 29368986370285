var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import { isFragment } from "react-is";
export var PortletHeaderIcon = forwardRef(function (_a, ref) {
    var className = _a.className;
    return (React.createElement("span", { ref: ref, className: clsx("kt-portlet__head-icon", className) }));
});
export var PortletHeaderTitle = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    React.createElement("h3", __assign({}, props, { ref: ref, className: clsx("kt-portlet__head-title", className) })));
});
export var PortletHeaderToolbar = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("div", __assign({}, props, { ref: ref, className: clsx("kt-portlet__head-toolbar", className) })));
});
export var PortletHeader = forwardRef(function (_a, ref) {
    var icon = _a.icon, title = _a.title, toolbar = _a.toolbar, className = _a.className, _b = _a.sticky, sticky = _b === void 0 ? false : _b, labelRef = _a.labelRef, props = __rest(_a, ["icon", "title", "toolbar", "className", "sticky", "labelRef"]);
    var _c = useState(0), top = _c[0], setTop = _c[1];
    var _d = useState(0), windowHeight = _d[0], setWindowHeight = _d[1];
    useEffect(function () {
        handleResize();
        function handleResize() {
            setWindowHeight(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    });
    useEffect(function () {
        // Skip if sticky is disabled or on initial render when we don't know about window height.
        if (!sticky || windowHeight === 0) {
            return;
        }
        var headerElement = document.querySelector(".kt-header");
        var subheaderElement = document.querySelector(".kt-subheader");
        var headerMobileElement = document.querySelector(".kt-header-mobile");
        var nextMarginTop = 0;
        // mobile header
        if (headerElement !== null && headerMobileElement !== null && window.getComputedStyle(headerElement).height === "0px") {
            nextMarginTop = headerMobileElement.offsetHeight;
        }
        else {
            // desktop header
            if (document.body.classList.contains("kt-header--minimize-topbar")) {
                // hardcoded minimized header height
                nextMarginTop = 60;
            }
            else {
                // normal fixed header
                if (headerElement !== null && document.body.classList.contains("kt-header--fixed")) {
                    nextMarginTop += headerElement.offsetHeight;
                }
                if (subheaderElement !== null && document.body.classList.contains("kt-subheader--fixed")) {
                    nextMarginTop += subheaderElement.offsetHeight;
                }
            }
        }
        setTop(nextMarginTop);
    }, [sticky, windowHeight]);
    return (React.createElement("div", { ref: ref, className: "kt-portlet__head", style: !sticky
            ? undefined
            : { top: top, position: "sticky", backgroundColor: "#fff" } },
        props.children,
        title !== null && (React.createElement("div", { ref: labelRef, className: clsx("kt-portlet__head-label", className) },
            icon !== undefined ?
                React.createElement("i", { className: icon, style: { marginRight: "10px" } })
                : null,
            typeof title === "string" || isFragment(title) ? (React.createElement(PortletHeaderTitle, null, title)) : (title))),
        toolbar));
});
export var PortletBody = forwardRef(function (_a, ref) {
    var fit = _a.fit, fluid = _a.fluid, className = _a.className, props = __rest(_a, ["fit", "fluid", "className"]);
    return (React.createElement("div", __assign({}, props, { ref: ref, className: clsx("kt-portlet__body", {
            "kt-portlet__body--fit": fit,
            "kt-portlet__body--fluid": fluid
        }, className) })));
});
export var PortletFooter = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("div", __assign({}, props, { ref: ref, className: clsx("kt-portlet__foot", className) })));
});
export var Portlet = forwardRef(function (_a, ref) {
    var fluidHeight = _a.fluidHeight, className = _a.className, props = __rest(_a, ["fluidHeight", "className"]);
    return (React.createElement("div", __assign({}, props, { ref: ref, className: clsx("kt-portlet", { "kt-portlet--height-fluid": fluidHeight }, className) })));
});
// Set display names for debugging.
if (process.env.NODE_ENV !== "production") {
    Portlet.displayName = "Portlet";
    PortletHeader.displayName = "PortletHeader";
    PortletHeaderIcon.displayName = "PortletHeaderIcon";
    PortletHeaderTitle.displayName = "PortletHeaderTitle";
    PortletHeaderToolbar.displayName = "PortletHeaderToolbar";
    PortletBody.displayName = "PortletBody";
    PortletFooter.displayName = "PortletFooter";
}
