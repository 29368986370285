import { AsyncCallType } from "app/store/async/types";
import { LibraryTemplateListActionTypes } from "app/store/library-templates/list/types";
import { LibraryFeatureListActionTypes } from "app/store/library-features/list/types";
import { LibraryTemplateCurrentActionTypes } from "app/store/library-templates/current";
export var libraryFeatureListActionMap = [
    {
        actionType: LibraryFeatureListActionTypes.LibraryFeature_List_Item_Delete,
        callType: AsyncCallType.Delete,
        keyType: "guid",
        ApiUrl: "/api/LibraryFeatures",
        responseDataType: "",
        invalidates: [LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryFeatureListActionTypes.LibraryFeature_List_Item_Add,
        callType: AsyncCallType.Add,
        keyType: "guid",
        ApiUrl: "/api/LibraryFeatures",
        responseDataType: "",
        invalidates: [LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryFeatureListActionTypes.LibraryFeature_List_Item_Update,
        callType: AsyncCallType.Update,
        keyType: "guid",
        ApiUrl: "/api/LibraryFeatures",
        responseDataType: true,
        invalidates: [LibraryTemplateListActionTypes.LibraryTemplate_List_Load, LibraryTemplateCurrentActionTypes.LibraryTemplate_Current_Load]
    },
    {
        actionType: LibraryFeatureListActionTypes.LibraryFeature_List_Load,
        callType: AsyncCallType.List,
        stateLocationForArray: "libraryFeatureListState.items",
        keyType: "typeName",
        ApiUrl: "/api/LibraryFeatures",
        responseDataType: []
    }
];
