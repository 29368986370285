var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AsyncWait } from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import FormToggleControl from "app/components/common/form-toggle-control";
import LifecycleReportService from "app/services/lifecycle-report";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as LifecyclePoliciesListStore from "app/store/lifecycle-policies/list";
import { LifecyclePolicyListActionTypes } from "app/store/lifecycle-policies/list";
import * as TemplateCurrentStore from "app/store/templates/current";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./tab-policies.scss";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
import * as LifecycleGuestPoliciesListStore from "app/store/lifecycle-guest-policies/list";
import { LifecycleGuestPolicyListActionTypes } from "app/store/lifecycle-guest-policies/list";
import { useDispatch, useSelector } from "react-redux";
require("codemirror/mode/javascript/javascript");
var TabPolicies = React.memo(function (props, state) {
    var dispatch = useDispatch();
    var _a = React.useState(null), defaultPolicies = _a[0], setDefaultPolicies = _a[1];
    var _b = React.useState(false), archivalPoliciesLoaded = _b[0], setArchivalPoliciesLoaded = _b[1];
    var _c = React.useState(false), guestPoliciesLoaded = _c[0], setGuestPoliciesLoaded = _c[1];
    var currentTenantConfig = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var lifecycleArchivalPolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var lifecycleGuestPolicies = useSelector(function (state) { return state.lifecycleGuestPoliciesListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    React.useEffect(function () {
        dispatch(LifecycleGuestPoliciesListStore.Load());
        dispatch(LifecyclePoliciesListStore.Load("Archival"));
        AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, undefined, null, LifecyclePolicyListActionTypes.LifecyclePolicy_List_Load).then(function () {
            setTimeout(function () { return setArchivalPoliciesLoaded(true); }, 500);
        });
        AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, undefined, null, LifecycleGuestPolicyListActionTypes.LifecycleGuestPolicy_List_Load).then(function () {
            setTimeout(function () { return setGuestPoliciesLoaded(true); }, 500);
        });
        LifecycleReportService.getDefaultPolicies().then(function (policies) {
            setDefaultPolicies(policies.defaultArchivalPolicies);
        });
    }, []);
    var getApplication = function () {
        var application = null;
        if (props.currentTemplate !== undefined && props.currentTemplate.TemplateType !== undefined) {
            if (props.currentTemplate.TemplateType === "Team") {
                application = "Team";
            }
            if (props.currentTemplate.TemplateType === "SharePoint") {
                if (props.currentTemplate.SharePointTemplateType === "ModernGroup") {
                    application = "TeamSite";
                }
                else if (props.currentTemplate.SharePointTemplateType === "ModernCommunications") {
                    application = "CommunicationSite";
                }
            }
        }
        return application;
    };
    var getDefaultPolicy = function () {
        var foundDefaultWorkspace = defaultPolicies === null || defaultPolicies === void 0 ? void 0 : defaultPolicies.find(function (ap) { return ap.Application === getApplication(); });
        //console.log("getDefaultPolicy", foundDefaultWorkspace, defaultPolicies);
        return foundDefaultWorkspace !== undefined ? foundDefaultWorkspace : null;
    };
    var getRequestTemplateOptions = function () {
        var options = [];
        options.push({
            id: "None",
            value: null,
            title: "None - No policy assigned",
            description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
        });
        options = __spreadArray(__spreadArray([], options, true), (lifecycleGuestPolicies
            .filter(function (policy) { return policy.Type === LifecycleGuestPolicyType.Request; })
            .map(function (policy, index) {
            return { title: policy.Name, value: policy.GUID, id: index.toString() };
        }) || []), true);
        return options;
    };
    var getReviewTemplateOptions = function () {
        var options = [];
        options.push({
            id: "None",
            value: null,
            title: "None - No policy assigned",
            description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
        });
        options = __spreadArray(__spreadArray([], options, true), (lifecycleGuestPolicies
            .filter(function (policy) { return policy.Type === LifecycleGuestPolicyType.Review; })
            .map(function (policy, index) {
            return { title: policy.Name, value: policy.GUID, id: index.toString() };
        }) || []), true);
        return options;
    };
    var getAssignablePolicies = function () {
        var policies = [];
        var defaultPolicy = getDefaultPolicy();
        if (defaultPolicy !== null && defaultPolicy !== undefined) {
            policies.push({
                id: "inherit",
                value: null,
                title: "Inherit Default Policy [" + defaultPolicy.Name + "]",
                description: "Do not assign this Workspace a policy and use the configured default policy."
            });
        }
        else {
            policies.push({
                id: "None",
                value: null,
                title: "None - No policy assigned",
                description: "Do not assign this Workspace a policy and allow the use of a default policy if and when configured."
            });
        }
        if (lifecycleArchivalPolicies !== null) {
            policies = __spreadArray(__spreadArray([], policies, true), lifecycleArchivalPolicies
                .filter(function (ap) { return ap.Application === getApplication(); })
                .map(function (ap) { return ({
                id: ap.GUID,
                value: ap.GUID,
                title: ap.Name,
                description: ap.Description
            }); }), true);
        }
        return policies;
    };
    var getAssignedPolicy = function () {
        if (lifecycleArchivalPolicies !== null && lifecycleArchivalPolicies.length > 0 && props.currentTemplate !== undefined && props.currentTemplate.LifecycleArchivePolicyGuid !== null) {
            var foundPolicy = lifecycleArchivalPolicies.find(function (ap) { return ap.GUID === props.currentTemplate.LifecycleArchivePolicyGuid; });
            return foundPolicy !== undefined ? foundPolicy : null;
        }
        return null;
    };
    var isWorkspaceAGroup = function () {
        if (props.currentTemplate !== undefined && props.currentTemplate.TemplateType !== undefined) {
            if (props.currentTemplate.TemplateType === "Team") {
                return true;
            }
            if (props.currentTemplate.TemplateType === "SharePoint") {
                if (props.currentTemplate.SharePointTemplateType === "ModernGroup") {
                    return true;
                }
            }
        }
        return false;
    };
    var arePoliciesDisabled = currentTenantConfig.IsMultiGeoEnabled &&
        props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced" &&
        currentTenantConfig.DefaultGeo !== props.currentTemplate.TemplateMultiGeoDataLocations;
    return (React.createElement("div", { className: "template-tab-policies", "data-pw": "policies-tab" },
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, sm: 8, md: 6, lg: 5, xl: 5, "data-pw": "workspace-lifecycle" },
                React.createElement(DisableArea, { isDisabled: currentTenantConfig.IsMultiGeoEnabled && props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced" },
                    React.createElement("h2", null, "Workspace Lifecycle"),
                    React.createElement("div", { className: "content-well" },
                        React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Policies to govern the long term management of workspaces"),
                        React.createElement(DisableArea, { isDisabled: props.currentTemplate.LifecycleArchivalIsProtectedFromArchivals, useOverlay: true },
                            React.createElement(Form.Group, { className: "form-group", controlId: "control-select-mode", "data-pw": "form-group" },
                                React.createElement(Form.Label, null, "Renewal / Archival Policy"),
                                archivalPoliciesLoaded === false || defaultPolicies === null ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: getAssignablePolicies(), defaultValue: props.currentTemplate.LifecycleArchivePolicyGuid, value: props.currentTemplate.LifecycleArchivePolicyGuid, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ LifecycleArchivePolicyGuid: value })); }, disabled: arePoliciesDisabled })))),
                        React.createElement(Form.Group, { controlId: "control-exclude-protect-from-archival", className: "form-group exclude-group" },
                            React.createElement(FormToggleControl, { checked: props.currentTemplate.LifecycleArchivalIsProtectedFromArchivals, border: true, label: "Exclude Workspace from Archivals", handleChange: function (checked) {
                                    dispatch(TemplateCurrentStore.Update({ LifecycleArchivalIsProtectedFromArchivals: checked }));
                                } }))))),
            React.createElement(Col, { "data-pw": "guest-management" },
                React.createElement(DisableArea, { isDisabled: currentTenantConfig.IsMultiGeoEnabled && props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced" }, orchestryContext.GuestManagementEnabled ? (React.createElement(DisableArea, { isDisabled: !isWorkspaceAGroup(), useOverlay: true },
                    React.createElement("h2", null, "Guest Management"),
                    React.createElement("div", { className: "content-well" },
                        React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Policies to govern the creation of new guests and the long term management of guests"),
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-select-guest-request-policy", "data-pw": "guest-request-policy" },
                            React.createElement(Form.Label, null, "Guest Request Policy"),
                            guestPoliciesLoaded === false || defaultPolicies === null ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: getRequestTemplateOptions(), defaultValue: props.currentTemplate.GuestRequestPolicyGuid, value: props.currentTemplate.GuestRequestPolicyGuid, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ GuestRequestPolicyGuid: value })); }, disabled: arePoliciesDisabled }))),
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-select-guest-review-policy", "data-pw": "guest-review-policy" },
                            React.createElement(Form.Label, null, "Guest Review Policy"),
                            guestPoliciesLoaded === false || defaultPolicies === null ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: getReviewTemplateOptions(), defaultValue: props.currentTemplate.GuestReviewPolicyGuid, value: props.currentTemplate.GuestReviewPolicyGuid, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ GuestReviewPolicyGuid: value })); }, disabled: arePoliciesDisabled })))))) : null)))));
});
export default TabPolicies;
