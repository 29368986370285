import React from "react";
import WebSockets from "app/services/websockets";
var OrchestryWebSockets = React.memo(function (props) {
    React.useEffect(function () {
        WebSockets.createConnection();
    }, []);
    // const [wsStatus, setWsStatus] = React.useState("connecting");
    // const [webSocket, setWebSocket] = React.useState<WebSocket>();
    // //const [webSocket, setWebSocket] = React.useState(new WebSocket("wss://" + window.location.hostname + "/api-webhooks/Websockets"));
    // const [inputValue, setInputValue] = React.useState("");
    // React.useEffect(()=>{
    //    setWebSocket(new WebSocket("wss://localhost:44316/api-ws/WebsocketDispatch"));
    //    setWebSocket(new WebSocket("wss://" + window.location.hostname + "/api-ws/WebsocketDispatch"))
    //    //setWebSocket(new WebSocket("ws://localhost:3000/api-webhooks/Websockets"))
    //    if(webSocket != undefined){
    //       webSocket.onopen = function () {
    //          setWsStatus("connected");
    //       };
    //       webSocket.onmessage = function (evt) {
    //          setWsStatus(evt.data);
    //       };
    //       webSocket.onerror = function (evt) {
    //          setWsStatus("error");
    //       };
    //       webSocket.onclose = function () {
    //          setWsStatus("disconnected");
    //       };
    //    }
    // },[])
    //    const clickButton = () => {
    //       if(webSocket != undefined){
    //          if (webSocket.readyState == WebSocket.OPEN) {
    //          webSocket.send(inputValue);
    //       }
    //       else {
    //          setWsStatus("Connection is closed");
    //       }
    //    }
    //   }
    //   const disconnect = () => {
    //       if(webSocket != undefined){
    //          webSocket.close();
    //       }
    //   };
    var sendMessage = function () {
        WebSockets.sendMessage("what", "next");
    };
    return (React.createElement("div", null,
        React.createElement("div", { onClick: function () { return sendMessage(); } }, "Send Message")));
});
export default OrchestryWebSockets;
// $().ready(function () {
//    $("#btnConnect").click(function () {
//        $("#spanStatus").text("connecting");
//        ws = new WebSocket("ws://" + window.location.hostname +
//            "/Mvc4WSChat/api/WSChat");
//        ws.onopen = function () {
//            $("#spanStatus").text("connected");
//        };
//        ws.onmessage = function (evt) {
//            $("#spanStatus").text(evt.data);
//        };
//        ws.onerror = function (evt) {
//            $("#spanStatus").text(evt.message);
//        };
//        ws.onclose = function () {
//            $("#spanStatus").text("disconnected");
//        };
//    });
//    $("#btnSend").click(function () {
//        if (ws.readyState == WebSocket.OPEN) {
//            ws.send($("#textInput").val());
//        }
//        else {
//            $("#spanStatus").text("Connection is closed");
//        }
//    });
//    $("#btnDisconnect").click(function () {
//        ws.close();
//    });
// });
