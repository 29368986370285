import * as React from "react";
import { ReportAggregateType } from "app/store/lifecycle-report";
import moment from "moment";
var ReportColumnDateAggregate = React.memo(function (props) {
    var propertyName = props.col.ProperyName;
    switch (props.col.AggregateType) {
        case ReportAggregateType.DISTINCT:
            {
                propertyName = propertyName + "Distinct";
            }
            break;
        case ReportAggregateType.COUNT:
            {
                propertyName = propertyName + "Count";
            }
            break;
        case ReportAggregateType.SUM:
            {
                propertyName = propertyName + "Sum";
            }
            break;
        case ReportAggregateType.AVG:
            {
                propertyName = propertyName + "Avg";
            }
            break;
        case ReportAggregateType.MAX:
            {
                propertyName = propertyName + "Max";
            }
            break;
        case ReportAggregateType.MIN:
            {
                propertyName = propertyName + "Min";
            }
            break;
    }
    //@ts-ignore
    var columnDate = moment(props.item[propertyName]).isValid()
        ? moment
            .utc(props.item[propertyName])
            .local()
            .format("MMM Do, YYYY")
        : "";
    return React.createElement(React.Fragment, null, columnDate);
});
export default ReportColumnDateAggregate;
