import * as LibraryDocumentTemplateCurrentStore from "app/store/library-document-templates/current";
import { AsyncCallType } from "app/store/async/types";
export var libraryDocumentTemplateCurrentActionMap = [
    {
        actionType: LibraryDocumentTemplateCurrentStore.LibraryDocumentTemplateCurrentActionTypes.LibraryDocumentTemplate_Current_Load,
        callType: AsyncCallType.Get,
        stateLocationForArray: "libraryDocumentTemplateListState.items",
        keyType: "guid",
        ApiUrl: "/api/LibraryDocumentTemplates/?guid={key}",
        responseDataType: {}
    }
];
