import moment from "moment";
/**
 * Converts the UTC datetime to local time.
 * @param datetime The datetime to convert from UTC to local time.
 */
export var convertUtcDatetimeToLocal = function (datetime) {
    return moment.utc(datetime).local();
};
/**
 * Transforms the date into a readable format like 09 Sept 2023.
 *
 * @param date The date to transform into a readable string.
 */
export var getReadableDate = function (date, lang) {
    if (lang === void 0) { lang = "en"; }
    return moment(date)
        .lang(lang)
        .format("MMM DD, yyyy");
};
/**
 * Transforms the dates into a readable date range like 09 Sept 2023 - 10 Sept 2023.
 *
 * @param startDate The start date of the date range.
 * @param endDate The end date of the date range.
 */
export var getReadableDateRange = function (startDate, endDate) { return "".concat(moment(startDate).format("MMM DD, yyyy"), " - ").concat(moment(endDate).format("MMM DD, yyyy")); };
/**
 * Transforms the datetime into a readable format like 09 Sept 2023 08:06 am.
 *
 * @param date The date to transform into a readable datetime string.
 */
export var getReadableDateTime = function (date) { return moment(date).format("MMM DD, yyyy hh:mm a"); };
/**
 * Gets the number of days since the date.
 * It doesn't matter if date is in future or past. Do not use toNow() for old dates.
 * https://github.com/moment/moment/issues/2751
 *
 * @param date The date to transform into a readable datetime string.
 */
export var getReadableDateFromNow = function (date) { return moment(date).fromNow(); };
/**
 * Determines if the date has passed.
 *
 * @param date The date to check.
 */
export var dateHasPassed = function (date) { return moment(date).isBefore(moment()); };
/**
 * Gets the date string from the date.
 *
 * @param date The date to get the string from.
 */
export var getDateString = function (date) { return (date ? date.toISOString().split("T")[0] : ""); };
/**
 * Gets the yesterdays date in ISO format.
 */
export var getYesterdaysIsoDatetime = function () {
    return moment()
        .startOf("day")
        .subtract(1, "seconds")
        .utcOffset(0, true)
        .toISOString();
};
/**
 * Gets the number of days between two dates.
 *
 * @param startDate The start date.
 * @param endDate The end date.
 * @returns The number of days between the two dates.
 */
export var getNumberOfDaysBetween = function (startDate, endDate) {
    if (!startDate || !endDate)
        return 0;
    var start = moment(startDate);
    var end = moment(endDate);
    return end.diff(start, "days");
};
