var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Group } from "@mantine/core";
import "@mantine/core/styles.css";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryImageDropzone } from "appv2/components/orchestry-image-dropzone/orchestry-image-dropzone.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryReadOnlyCopyControl } from "appv2/components/orchestry-readonly-copy-control/orchestry-readonly-copy-control.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import { OrchestrySettingsPageControl } from "appv2/components/orchestry-settings-page-control/orchestry-settings-page-control.component";
import { OrchestryTextInput } from "appv2/components/orchestry-text-input/orchestry-text-input.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { ORCHESTRY_LOGO_IMAGE_MIME_TYPE, ORCHESTRY_LOGO_MAX_SIZE_BYTES } from "appv2/types/globals.types";
import { mapFormGroupsStringToIds } from "appv2/utils/control.utils";
import React from "react";
import { PartnerConnectionSecretDescription, PartnerHubAdminsDescription, PartnerLicenseKeyDescription, PartnerOrganizationEmailDescription, PartnerOrganizationLogoDescription, PartnerOrganizationNameDescription } from "./partner-settings-control-descriptions.component";
import "./partner-settings-page.scss";
import { usePartnerSettingsPage } from "./use-partner-settings-page.hook";
export var PartnerSettingsPage = function () {
    var _a = usePartnerSettingsPage(), isSaveDisabled = _a.isSaveDisabled, onBackButtonClicked = _a.onBackButtonClicked, isPageLoading = _a.isPageLoading, form = _a.form, handleImageDrop = _a.handleImageDrop, handleFormSubmit = _a.handleFormSubmit;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "partner-settings-page" },
                React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
                    React.createElement(Subtitle, { title: "Partner Tools", subtitle: "Partner Settings" },
                        React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, "Go Back to Partner Tools")),
                    React.createElement(OrchestryPageHeader, null,
                        React.createElement(OrchestryPageTitle, { title: "Settings", subTitle: "Configure your Partner settings including organization details, admins and your logo." }),
                        React.createElement(OrchestryActionButtons, null,
                            React.createElement(OrchestrySaveButton, { disabled: isSaveDisabled }))),
                    React.createElement(Group, { mb: 32, gap: 8 },
                        React.createElement(OrchestryFeaturedIcon, { colorVariant: "primary-6", variant: "light-circle", iconClassName: ORCH_ICONS.key02, size: "md" }),
                        React.createElement(OrchestryText, { c: "text-primary.0", size: "20px", fw: 500, lh: "24px" }, "Our Keys")),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner License Key", description: PartnerLicenseKeyDescription() },
                        React.createElement(OrchestryReadOnlyCopyControl, { isLoading: isPageLoading, value: form.getInputProps("LicenseKey").value })),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner Connection Secret", description: PartnerConnectionSecretDescription() },
                        React.createElement(OrchestryReadOnlyCopyControl, { isLoading: isPageLoading, value: form.getInputProps("PartnerSecret").value })),
                    React.createElement(Group, { mb: 32, mt: 36, gap: 8 },
                        React.createElement(OrchestryFeaturedIcon, { colorVariant: "primary-6", variant: "light-circle", iconClassName: ORCH_ICONS.list, size: "md" }),
                        React.createElement(OrchestryText, { c: "text-primary.0", size: "20px", fw: 500, lh: "24px" }, "Organization Details")),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner Organization Name", description: PartnerOrganizationNameDescription() },
                        React.createElement(OrchestryTextInput, { isLoading: isPageLoading, onChange: function (e) {
                                form.setFieldValue("OrganizationName", e.currentTarget.value);
                            }, value: form.getInputProps("OrganizationName").value, placeholder: "Enter Partner Organization Name", c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("OrganizationName").error })),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner Organization Support Email", description: PartnerOrganizationEmailDescription() },
                        React.createElement(OrchestryTextInput, __assign({ isLoading: isPageLoading, value: form.getInputProps("SupportEmail").value, placeholder: "Enter Partner Organization Support Email", c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("SupportEmail").error }, form.getInputProps("SupportEmail")))),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner Hub Admins", description: PartnerHubAdminsDescription() },
                        React.createElement(FormUserGroupSelect, { isLoading: isPageLoading, defaultValue: undefined, allowMultiple: true, returnType: "users", label: "Partner Hub Admins:", value: form.getInputProps("HubAdmins").value, onChange: function (groups) { return form.setFieldValue("HubAdmins", mapFormGroupsStringToIds(groups)); }, excludeGuests: true, shouldUseDynamic: true, error: form.getInputProps("HubAdmins").error })),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: "Partner Organization Logo", description: PartnerOrganizationLogoDescription() },
                        React.createElement(OrchestryImageDropzone, { loading: isPageLoading, base64Value: form.getInputProps("Logo").value, imageStyles: { width: "38px", height: "38px" }, onDrop: handleImageDrop, accept: ORCHESTRY_LOGO_IMAGE_MIME_TYPE, maxSize: ORCHESTRY_LOGO_MAX_SIZE_BYTES, onPreviewRemoveClick: function () { return form.setFieldValue("Logo", ""); } })))))));
};
