import * as React from "react";
import "./form-button.scss";
var FormButton = React.memo(function (props) {
    return (React.createElement("div", { className: "form-button" +
            (props.theme !== undefined ? " theme-" + props.theme : " theme-white") +
            (props.onClick !== undefined ? "" : " not-clickable") +
            (props.size !== undefined ? " size-" + props.size : " size-md"), onClick: props.onClick },
        props.icon !== undefined ? (React.createElement("div", { className: "icon" },
            React.createElement("i", { className: props.icon }))) : null,
        React.createElement("div", { className: "text" }, props.text),
        props.children));
});
export default FormButton;
export var FormButtons = React.memo(function (props) {
    return React.createElement("div", { className: "form-buttons" + (props.style !== undefined ? " style-" + props.style : " style-centered") }, props.children);
});
