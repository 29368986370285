var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import "./documents-modal.scss";
import { Modal, Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RSC from "react-scrollbars-custom";
import { useSelector, useDispatch } from "react-redux";
import * as DocumentTemplateListStore from "app/store/document-templates/list";
import * as LibraryDocumentTemplateListStore from "app/store/library-document-templates/list";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import DocumentTemplatePreview from "app/components/common/document-template-preview";
import DocumentTemplateDetails from "app/components/workspaces/document-templates/document-template-details";
import FormSearchbox from "app/components/common/form-searchbox";
import AnimatedLoader from "../animated-loader";
import NoResults from "app/components/common/no-results";
import { useIntl, FormattedMessage } from "react-intl";
//class DocumentsModal extends React.Component<IProps,IState> {
var DocumentsModal = React.memo(function (props, state) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(false), showUploadModal = _b[0], setShowUploadModal = _b[1];
    var _c = React.useState(props.availableDocumentTemplates !== undefined && props.availableDocumentTemplates.length > 0 ? "document-modal-tab-suggested" : "document-modal-tab-all-templates"), currentTab = _c[0], setCurrentTab = _c[1];
    var _d = React.useState(""), currentTabName = _d[0], setCurrentTabName = _d[1];
    var _e = React.useState([]), selectedItems = _e[0], setSelectedItems = _e[1];
    var _f = React.useState([]), categories = _f[0], setCategories = _f[1];
    var _g = React.useState([]), currentItems = _g[0], setCurrentItems = _g[1];
    var _h = React.useState(""), searchTerm = _h[0], setSearchTerm = _h[1];
    var _j = React.useState(props.showSuggestions !== undefined && props.showSuggestions === true ? "suggested" : "all"), currentMode = _j[0], setCurrentMode = _j[1];
    var _k = React.useState(true), isLoading = _k[0], setIsLoading = _k[1];
    var allDocumenTemplates = [];
    var allDocumenTemplates_IsLoading = true;
    if (props.isLibrary !== undefined && props.isLibrary === true) {
        allDocumenTemplates = useSelector(function (state) { return state.libraryDocumentTemplateListState.items; }); //eslint-disable-line
        allDocumenTemplates_IsLoading = useSelector(function (state) { return state.libraryDocumentTemplateListState.isLoading; }); //eslint-disable-line
    }
    else {
        allDocumenTemplates = useSelector(function (state) { return state.documentTemplateListState.items; }); //eslint-disable-line
        allDocumenTemplates_IsLoading = useSelector(function (state) { return state.documentTemplateListState.isLoading; }); //eslint-disable-line
    }
    React.useEffect(function () {
        if (props.isLibrary !== undefined && props.isLibrary === true) {
            dispatch(LibraryDocumentTemplateListStore.Load());
        }
        else {
            dispatch(DocumentTemplateListStore.Load());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        var items = [];
        if (currentMode === "suggested") {
            if (props.availableDocumentTemplates !== undefined) {
                items = props.availableDocumentTemplates;
                setIsLoading(false);
            }
        }
        else {
            items = allDocumenTemplates;
        }
        setCurrentItems(items);
    }, [props.availableDocumentTemplates, allDocumenTemplates, currentMode]);
    React.useEffect(function () {
        if (currentMode === "suggested") {
            if (props.availableDocumentTemplates !== undefined) {
                // Do nothing
            }
        }
        else {
            setIsLoading(allDocumenTemplates_IsLoading !== undefined ? allDocumenTemplates_IsLoading : true);
        }
    }, [allDocumenTemplates_IsLoading]);
    React.useEffect(function () {
        var items = [];
        if (currentMode === "suggested") {
            //console.log("props.availableDocumentTemplates", props.availableDocumentTemplates)
            if (props.availableDocumentTemplates !== undefined) {
                items = props.availableDocumentTemplates;
            }
        }
        else {
            items = allDocumenTemplates;
        }
        if (searchTerm !== undefined && searchTerm !== "") {
            //@ts-ignore
            items = items.filter(function (i) {
                var _a, _b, _c;
                var match = false;
                if (i.Title !== undefined && i.Title !== null && ((_a = i.Title) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(searchTerm.toLowerCase())) > -1) {
                    match = true;
                }
                if (i.Description !== undefined && i.Description !== null && ((_b = i.Description) === null || _b === void 0 ? void 0 : _b.toLowerCase().indexOf(searchTerm.toLowerCase())) > -1) {
                    match = true;
                }
                if (i.FileName !== undefined && i.FileName !== null && ((_c = i.FileName) === null || _c === void 0 ? void 0 : _c.toLowerCase().indexOf(searchTerm.toLowerCase())) > -1) {
                    match = true;
                }
                return match;
            });
        }
        setCurrentItems(items);
        //setTimeout(()=>{
        var currentTabs = Array.from(document.getElementsByClassName("document-container"));
        if (currentTabs.length > 0) {
            if (Array.from(document.querySelectorAll(".document-container.active")).length === 0) {
                var stillExistingTab = document.getElementById(currentTabs[0].id.replace("-tabpane-", "-tab-"));
                if (stillExistingTab !== null) {
                    stillExistingTab.click();
                }
            }
            //document-modal-tabs-tabpane-document-modal-tab-0
            //document-modal-tabs-tab-document-modal-tab-0
        }
        //},200)
    }, [searchTerm]);
    var toggleModal = function () {
        setShowModal(!showModal);
        //is now closed
        if (showModal === true) {
            setTimeout(function () {
                setSelectedItems([]);
            }, 500);
            //console.log("closed");
        }
    };
    var toggleUploadModal = function () {
        setShowUploadModal(!showUploadModal);
        //is now closed
        if (showUploadModal === true) {
            // setTimeout(
            //     () => { setSelectedItems([] as DocumentTemplate[]) },
            //     500
            // );
            //console.log("closed");
        }
    };
    var toggleSelectedItem = function (documentTemp) {
        if (isAlreadyAssociatedToTemplate(documentTemp) === false) {
            var queryItems = selectedItems.filter(function (o) {
                var match = false;
                if (o.GUID === documentTemp.GUID) {
                    match = true;
                }
                return match;
            });
            if (queryItems.length === 0) {
                setSelectedItems(__spreadArray(__spreadArray([], selectedItems, true), [documentTemp], false));
            }
            else {
                setSelectedItems(selectedItems.filter(function (o) {
                    var match = true;
                    if (o.GUID === documentTemp.GUID) {
                        match = false;
                    }
                    return match;
                }));
            }
        }
    };
    var changeCurrentTab = function (currentTab, catName) {
        setCurrentTab(currentTab);
        setCurrentTabName(catName);
    };
    var isSelected = function (documentTemp) {
        var queryItems = selectedItems.filter(function (o) {
            var match = false;
            if (o.GUID === documentTemp.GUID) {
                match = true;
            }
            return match;
        });
        if (queryItems.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    var isAlreadyAssociatedToTemplate = function (documentTemp) {
        var matched = false;
        if (props.associatedDocumentTemplates !== undefined) {
            props.associatedDocumentTemplates.forEach(function (assDocTemp) {
                var _a;
                if (documentTemp.GUID === ((_a = assDocTemp.Item) === null || _a === void 0 ? void 0 : _a.GUID)) {
                    matched = true;
                }
            });
        }
        return matched;
    };
    var toggleAllItems = function (event) {
        var currentDocumentTemplates = [];
        if (currentTab === "document-modal-tab-suggested") {
            if (props.availableDocumentTemplates !== undefined) {
                currentDocumentTemplates = props.availableDocumentTemplates;
            }
        }
        else {
            currentDocumentTemplates = allDocumenTemplates;
        }
        if (event.target.checked) {
            setSelectedItems(
            //@ts-ignore
            currentDocumentTemplates.filter(function (o) {
                if (props.isLibrary !== undefined && props.isLibrary === true) {
                    if (o.AzureBlobFile === undefined ||
                        (o.AzureBlobFile !== undefined && o.AzureBlobFile === "") ||
                        isAlreadyAssociatedToTemplate(o)) {
                        return false;
                    }
                }
                else {
                    if (o.SharePointListItemId === undefined ||
                        (o.SharePointListItemId !== undefined && o.SharePointListItemId === "") ||
                        isAlreadyAssociatedToTemplate(o)) {
                        return false;
                    }
                }
                return true;
            }));
            //setSelectedItems([...availableDocTemps]);
        }
        else {
            setSelectedItems([]);
        }
    };
    var countSelectedItems = function () {
        var count = 0;
        if (selectedItems.length > 0) {
            count = selectedItems.length;
        }
        return count;
    };
    var shouldAllBeChecked = function () {
        var currentDocumentTemplates = [];
        if (currentTab === "document-modal-tab-suggested") {
            if (props.availableDocumentTemplates !== undefined) {
                currentDocumentTemplates = props.availableDocumentTemplates;
            }
        }
        else {
            currentDocumentTemplates = allDocumenTemplates;
        }
        //@ts-ignore
        var allNumber = currentDocumentTemplates.filter(function (o) {
            if (isAlreadyAssociatedToTemplate(o)) {
                return false;
            }
            return true;
        }).length;
        if (allNumber === selectedItems.length) {
            return true;
        }
        else {
            return false;
        }
    };
    var toggleAllTabItems = function (event) {
        var currentDocumentTemplates = [];
        currentDocumentTemplates = getDocumentObjectsByCategory(currentTabName);
        if (event.target.checked) {
            var currentlySelectedItem = selectedItems.filter(function (si) { return si.Category !== currentTabName; });
            //@ts-ignore
            var newlySelectedItems = currentDocumentTemplates.filter(function (o) {
                if (props.isLibrary !== undefined && props.isLibrary === true) {
                    if (o.AzureBlobFile === undefined ||
                        (o.AzureBlobFile !== undefined && o.AzureBlobFile === "") ||
                        isAlreadyAssociatedToTemplate(o)) {
                        return false;
                    }
                }
                else {
                    if (o.SharePointListItemId === undefined || (o.SharePointListItemId !== undefined && o.SharePointListItemId === "") || isAlreadyAssociatedToTemplate(o)) {
                        return false;
                    }
                }
                return true;
            });
            setSelectedItems(__spreadArray(__spreadArray([], currentlySelectedItem, true), newlySelectedItems, true));
        }
        else {
            //@ts-ignore
            var currentlySelectedItem = selectedItems.filter(function (si) { return si.Category !== currentTabName; });
            setSelectedItems(__spreadArray([], currentlySelectedItem, true));
        }
    };
    var shouldAllBeCheckedForTab = function () {
        var currentDocumentTemplates = [];
        currentDocumentTemplates = getDocumentObjectsByCategory(currentTabName);
        //@ts-ignore
        var allNumber = currentDocumentTemplates.filter(function (o) {
            if (isAlreadyAssociatedToTemplate(o)) {
                return false;
            }
            return true;
        }).length;
        if (allNumber === selectedItems.filter(function (si) { return si.Category === currentTabName; }).length) {
            return true;
        }
        else {
            return false;
        }
    };
    var getDocumentObjectsByCategory = function (cat) {
        return currentItems === null || currentItems === void 0 ? void 0 : currentItems.filter(function (document, index) {
            if (document.Category === cat &&
                ((document.SharePointListItemId !== undefined && document.SharePointListItemId !== null && document.SharePointListItemId !== "") ||
                    //@ts-ignore
                    (document.AzureBlobFile !== undefined && document.AzureBlobFile !== null && document.AzureBlobFile !== ""))) {
                return true;
            }
            else {
                return false;
            }
        });
    };
    var getDocumentsByCategory = function (category) {
        var getDocsPerCat = function (cat) {
            var lis = [];
            var listMatch = false;
            currentItems === null || currentItems === void 0 ? void 0 : currentItems.forEach(function (document, index) {
                if (document.Category === cat &&
                    ((document.SharePointListItemId !== undefined && document.SharePointListItemId !== null && document.SharePointListItemId !== "") ||
                        //@ts-ignore
                        (document.AzureBlobFile !== undefined && document.AzureBlobFile !== null && document.AzureBlobFile !== ""))) {
                    var LI = (React.createElement("li", { key: index, className: (isSelected(document) === true ? " selected" : "") + (isAlreadyAssociatedToTemplate(document) === true ? " is-already-ass" : ""), onClick: function () { return toggleSelectedItem(document); } }, props.isLibrary !== undefined && props.isLibrary === true ? (React.createElement(React.Fragment, null,
                        React.createElement(DocumentTemplatePreview, { title: document.Title, fileName: document.AzureBlobFile, showFileName: true, linksEnabled: false, showFileType: true }))) : (React.createElement(React.Fragment, null,
                        React.createElement(DocumentTemplatePreview, { ListId: document.SharePointListItemId, title: document.Title, fileType: document.Type, showFileName: true, linksEnabled: false, showFileType: true })))));
                    lis.push(LI);
                    listMatch = true;
                }
            });
            if (listMatch === false) {
                return (React.createElement("div", { className: "no-documents" },
                    React.createElement(NoResults, { subText: intl.formatMessage({ id: "component.document-select-modal.no_templates", defaultMessage: "No Templates available for this category" }) })));
            }
            return React.createElement("ul", { className: "list-unstyled" }, lis);
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "document-wrapper" }, getDocsPerCat(category))));
    };
    React.useEffect(function () {
        //let tempCats = [...categories];
        var tempCats = [];
        currentItems.forEach(function (doc) {
            if (doc.Category !== undefined) {
                if (!tempCats.includes(doc.Category)) {
                    tempCats.push(doc.Category);
                }
            }
        });
        setCategories(tempCats);
        if (tempCats.length > 0) {
            setCurrentTabName(tempCats[0]);
        }
    }, [currentItems]);
    //console.log("state", this.state)
    return (React.createElement(React.Fragment, null,
        props.children === undefined || props.children === null ? (React.createElement("div", { className: "button-custom b-orange", onClick: function () { return toggleModal(); } },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "component.document-select-modal.add_more_documents", defaultMessage: "Add More Documents" })))) : (React.createElement("span", { onClick: function () { return toggleModal(); } }, props.children)),
        React.createElement(Modal, { size: "lg", centered: true, show: showModal, onHide: function () { return toggleModal(); }, animation: true, className: "documents-modal" },
            React.createElement(Tab.Container, { id: "document-modal-tabs", defaultActiveKey: "document-modal-tab-0" },
                React.createElement(Container, { fluid: true },
                    React.createElement(Row, { className: "row-top" },
                        React.createElement(Col, null, currentMode === "suggested" ? (React.createElement("h4", null,
                            React.createElement(FormattedMessage, { id: "component.document-select-modal.suggested_documents", defaultMessage: "Suggested Documents for this Workspace" }))) : (React.createElement("h4", null,
                            React.createElement(FormattedMessage, { id: "component.document-select-modal.all_templates", defaultMessage: "All Templates" })))),
                        React.createElement(Col, null,
                            (props.showUpload === undefined || props.showUpload !== false) && props.isLibrary === undefined ? (React.createElement("div", { className: "upload-new", onClick: function () {
                                    toggleUploadModal();
                                } },
                                React.createElement(FontAwesomeIcon, { icon: faUpload }),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "component.document-select-modal.upload_new", defaultMessage: "Upload New" })))) : (React.createElement(React.Fragment, null)),
                            props.showSuggestions !== undefined && props.showSuggestions === true ? (React.createElement("div", { className: "actions" },
                                React.createElement("div", { className: "search" },
                                    React.createElement(FormSearchbox, { placeholder: intl.formatMessage({ id: "component.document-select-modal.search", defaultMessage: "Search" }), handler: function (event) {
                                            setSearchTerm(event.currentTarget.value);
                                        }, useAltStyle: true })))) : null)),
                    React.createElement(Row, { className: "row-navigation" },
                        React.createElement(Col, null,
                            React.createElement("div", { className: "tabbed-navigation" },
                                React.createElement(Nav, { as: "ul" }, categories.length > 0 ? (categories.map(function (cat, index) { return (React.createElement(Nav.Item, { as: "li", key: "tab-nav-" + index },
                                    React.createElement(Nav.Link, { eventKey: "document-modal-tab-" + index, onClick: function () { return changeCurrentTab("document-modal-tab-" + index, cat); } },
                                        React.createElement("span", null, cat)))); })) : (React.createElement(React.Fragment, null)))))),
                    React.createElement(Row, { className: "row-middle" }, isLoading === true ? (React.createElement("div", { className: "loader" },
                        React.createElement(AnimatedLoader, null))) : (React.createElement(Col, null,
                        React.createElement(Tab.Content, null, categories.length > 0 ? (categories.map(function (cat, index) { return (React.createElement(Tab.Pane, { eventKey: "document-modal-tab-" + index, key: "document-modal-tab-" + index, className: "document-container" },
                            React.createElement("div", { className: "document-list" },
                                React.createElement(RSC, null, getDocumentsByCategory(cat))))); })) : (React.createElement(React.Fragment, null))),
                        categories.length === 0 ? (React.createElement("div", { className: "no-categories" },
                            React.createElement(NoResults, null))) : null))),
                    React.createElement(Row, { className: "row-bottom" },
                        React.createElement(Col, { xs: 8, className: "checkbox-col" },
                            React.createElement("div", { className: "select-infobox" },
                                React.createElement("label", { className: "white-checkbox-dash" },
                                    React.createElement("input", { type: "checkbox", id: "all-tab", name: "all-tab", checked: shouldAllBeCheckedForTab(), onChange: function (event) { return toggleAllTabItems(event); } }),
                                    React.createElement("span", { className: "box" }),
                                    React.createElement("span", null, intl.formatMessage({ id: "component.document-select-modal.select_all_templates", defaultMessage: "Select all {currentTabName} templates" }, { currentTabName: currentTabName })))),
                            React.createElement("div", { className: "select-infobox" },
                                React.createElement("label", { className: "white-checkbox-dash" },
                                    React.createElement("input", { type: "checkbox", id: "all", name: "all", checked: shouldAllBeChecked(), onChange: function (event) { return toggleAllItems(event); } }),
                                    React.createElement("span", { className: "box" }),
                                    React.createElement("span", null, intl.formatMessage({ id: "component.document-select-modal.select_all_templates", defaultMessage: "Select all available templates" }))))),
                        React.createElement(Col, { xs: 4, className: "text-right" },
                            React.createElement("div", { className: "actions" },
                                React.createElement("div", { className: "cancel", onClick: function () { return toggleModal(); } },
                                    React.createElement("span", null, intl.formatMessage({ id: "component.document-select-modal.cancel", defaultMessage: "Cancel" }))),
                                selectedItems.length === 0 ? (React.createElement("div", { className: "save disabled" },
                                    React.createElement("i", { className: "la la-plus" }),
                                    React.createElement("span", null,
                                        intl.formatMessage({ id: "component.document-select-modal.add_template", defaultMessage: "Add Template" }),
                                        " (",
                                        React.createElement("span", { className: "count" }, countSelectedItems()),
                                        ")"))) : (React.createElement("div", { className: "save", onClick: function () {
                                        props.handleSelected(selectedItems);
                                        toggleModal();
                                    } },
                                    React.createElement("i", { className: "la la-plus" }),
                                    React.createElement("span", null,
                                        intl.formatMessage({ id: "component.document-select-modal.add_template", defaultMessage: "Add Template" }),
                                        countSelectedItems() > 1 ? "s " : " ",
                                        "(",
                                        React.createElement("span", { className: "count" }, countSelectedItems()),
                                        ")"))))))))),
        React.createElement(Modal, { size: "lg", centered: true, show: showUploadModal, onHide: function () { return toggleUploadModal(); }, animation: true, className: "upload-modal documents-modal" },
            React.createElement(Container, { fluid: true },
                React.createElement(Row, { className: "row-top" },
                    React.createElement(Col, null,
                        React.createElement("h4", null, intl.formatMessage({ id: "component.document-select-modal.new_document_template", defaultMessage: "New Document Template" })))),
                React.createElement(Row, { className: "" },
                    React.createElement(Col, null,
                        React.createElement("div", { style: { minHeight: "700px" } },
                            React.createElement(DocumentTemplateDetails, { defaultAssociatedTemplate: props.currentTemplate !== undefined ? { Title: props.currentTemplate.Title, GUID: props.currentTemplate.GUID } : undefined, showButtonsAtBottom: true, onCancel: function () {
                                    toggleUploadModal();
                                }, onSave: function () {
                                    if (props.isLibrary !== undefined && props.isLibrary === true) {
                                        dispatch(DocumentTemplateListStore.Refresh());
                                    }
                                    else {
                                        dispatch(DocumentTemplateListStore.Refresh());
                                    }
                                    toggleUploadModal();
                                } }))))))));
});
export default DocumentsModal;
