var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import AnimatedLoader from "app/components/common/animated-loader";
import Async from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormToggleControl from "app/components/common/form-toggle-control";
import { FormValidator } from "app/components/common/form-validation";
import InfoTooltip from "app/components/common/infotooltip";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import SensitivityLabelsService from "app/services/sensitivity-labels";
import SharePointService from "app/services/sharepoint";
import TeamsService from "app/services/teams";
import * as AsyncTypes from "app/store/async";
import * as MetadataListStore from "app/store/metadata/list";
import { PermissionTypes } from "app/store/permissions/types";
import * as TemplateCurrentStore from "app/store/templates/current";
import { MetadataConfigurationType } from "app/store/templates/types";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./tab-configuration.scss";
var enforcedGeoLiveTemplateSettings = {
    LiveTemplateEnabled: false,
    LiveTemplateSiteUrl: null,
    LiveTemplateTeamID: null,
    LiveTemplatePlannerCloneBuckets: false,
    LiveTemplatePlannerCloneTasks: false,
    LiveTemplatePlannerPrependWorkspaceName: false,
    LiveTemplateTeamCloneChannels: false,
    LiveTemplateTeamCloneTabs: false,
    LiveTemplateTeamCloneMembers: false,
    LiveTemplateTeamCloneSecuritySettings: false,
    LiveTemplateTeamCloneApps: false,
    LiveTemplateTeamClonePlannerBuckets: false,
    LiveTemplateTeamClonePlannerTasks: false,
    LiveTemplateTeamCloneSharePointGroupSite: false,
    LiveTemplateSiteClonePages: false,
    LiveTemplateSiteCloneContentInLibs: false,
    LiveTemplateSiteCloneLists: false,
    LiveTemplateTeamClonePlanners: false,
    SensitivityLabelsConfigJson: "[]",
    SensitivityLabelType: "None"
};
var TabConfiguration = React.memo(function (props, state) {
    var _a, _b, _c;
    var dispatch = useDispatch();
    var _d = React.useState(), availableWorkflows = _d[0], setAvailableWorkflows = _d[1];
    var _e = React.useState(), availablePolicies = _e[0], setAvailablePolicies = _e[1];
    var _f = React.useState(), isClassificationsEnabledOnTenant = _f[0], setIsClassificationsEnabledOnTenant = _f[1];
    var _g = React.useState(), classificationLabels = _g[0], setClassificationLabels = _g[1];
    var _h = React.useState(true), classificationOrSensitivityLabelsLoading = _h[0], setClassificationOrSensitivityLabelsLoading = _h[1];
    var _j = React.useState(), isSensitivityLabelsEnabledOnTenant = _j[0], setIsSensitivityLabelsEnabledOnTenant = _j[1];
    var _k = React.useState([]), sensitivityLabelConfigs = _k[0], setSensitivityLabelConfigs = _k[1];
    var _l = React.useState(), sensitivityLabels = _l[0], setSensitivityLabels = _l[1];
    var _m = React.useState(false), isHideDisabled = _m[0], setisHideDisabled = _m[1];
    var _o = React.useState(), defaultTenantSettings = _o[0], setDefaultTenantSettings = _o[1];
    var approvalWorkflows = useSelector(function (state) { return state.approvalWorkflowListState.items; });
    var namingPolicies = useSelector(function (state) { return state.namingPolicyListState.items; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var metadata = useSelector(function (state) { return state.metadataListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _p = React.useState(false), loadingPermissions = _p[0], setLoadingPermissions = _p[1];
    React.useEffect(function () {
        var mappedApprovalWorkflows = approvalWorkflows.map(function (aw, index) {
            return ({
                id: index + "",
                title: aw.Name,
                description: aw.Description,
                value: aw.GUID
            });
        });
        setAvailableWorkflows(mappedApprovalWorkflows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalWorkflows]);
    React.useEffect(function () {
        var mappedNamingPolicies = namingPolicies.map(function (np, index) {
            return ({
                id: index + "",
                title: np.Title,
                description: np.Description,
                value: np.GUID
            });
        });
        setAvailablePolicies(mappedNamingPolicies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [namingPolicies]);
    var getIcon = function (key, configs) {
        var returnIcon = "";
        var foundConfig = configs.find(function (clc) { return clc.Key === key; });
        if (foundConfig !== null && foundConfig !== undefined) {
            returnIcon = foundConfig.Icon !== null && foundConfig.Icon !== undefined ? foundConfig.Icon : "";
        }
        //console.log("Get Icon", key, returnIcon, configs);
        return returnIcon;
    };
    var WorkspaceHasGuestPolicy = function () {
        if (props.currentTemplate.TemplateType === "Team" || (props.currentTemplate.TemplateType === "SharePoint" && props.currentTemplate.SharePointTemplateType === "ModernGroup")) {
            if (props.currentTemplate.GuestRequestPolicyGuid) {
                return true;
            }
        }
        return false;
    };
    var GuestAccessIsGovernedBySensitivityLabel = function () {
        if (isSensitivityLabelsEnabledOnTenant && props.currentTemplate.SensitivityLabelType === "Enforced" && getSensitivityLabelsTenantConfigValues().some(function (l) { return l.GroupUserAccessOverride !== null; })) {
            return true;
        }
        return false;
    };
    React.useEffect(function () {
        if (classificationOrSensitivityLabelsLoading === true) {
            SensitivityLabelsService.doesTenantHaveLabelsEnabled().then(function (isEnabled) {
                if (isEnabled) {
                    setIsClassificationsEnabledOnTenant(false);
                    if (currentTenantConfiguration.SensitivityLabelConfigJson !== null &&
                        currentTenantConfiguration.SensitivityLabelConfigJson !== undefined &&
                        currentTenantConfiguration.SensitivityLabelConfigJson !== "") {
                        var sensitivityLabelConfig = JSON.parse(currentTenantConfiguration.SensitivityLabelConfigJson);
                        setIsSensitivityLabelsEnabledOnTenant(sensitivityLabelConfig.IsEnabled);
                        if (sensitivityLabelConfig.IsEnabled === true) {
                            setSensitivityLabelConfigs(sensitivityLabelConfig.LabelConfigs.filter(function (l) { return l.Enabled === true; }));
                        }
                    }
                    else {
                        setIsSensitivityLabelsEnabledOnTenant(false);
                    }
                    setClassificationOrSensitivityLabelsLoading(false);
                }
                else {
                    setIsSensitivityLabelsEnabledOnTenant(false);
                    if (currentTenantConfiguration.ClassificationConfigJson !== null &&
                        currentTenantConfiguration.ClassificationConfigJson !== undefined &&
                        currentTenantConfiguration.ClassificationConfigJson !== "") {
                        var classificationConfig = JSON.parse(currentTenantConfiguration.ClassificationConfigJson);
                        setIsClassificationsEnabledOnTenant(classificationConfig.IsEnabled);
                        if (classificationConfig.IsEnabled === true) {
                            setClassificationLabels(classificationConfig.ClassifiedConfigs.filter(function (l) { return l.IsEnabled === true; }));
                            // GroupSettingsService.getGlobalSettings().then(() => {
                            //    const classifications = GroupSettingsService.getClassificationListTableData().map(
                            //       (td) => ({
                            //          ...td,
                            //          icon: getIcon(td.name, classificationConfig.ClassifiedConfigs)
                            //       })
                            //    );
                            //    console.log("classifications", classifications);
                            //    setClassificationLabels(classifications);
                            setClassificationOrSensitivityLabelsLoading(false);
                            // })
                        }
                        else {
                            setClassificationOrSensitivityLabelsLoading(false);
                        }
                    }
                    else {
                        setIsClassificationsEnabledOnTenant(false);
                        setClassificationOrSensitivityLabelsLoading(false);
                    }
                }
            });
        }
    }, [currentTenantConfiguration]);
    React.useEffect(function () {
        if (props.currentTemplate.SensitivityLabelType !== "None") {
            SensitivityLabelsService.getOrchestryAllowedLabels().then(function (orgLabels) {
                setSensitivityLabels(orgLabels);
            });
        }
    }, [props.currentTemplate.SensitivityLabelType]);
    React.useEffect(function () {
        if (isSensitivityLabelsEnabledOnTenant !== undefined && isSensitivityLabelsEnabledOnTenant === true && sensitivityLabels !== undefined && sensitivityLabelConfigs !== undefined) {
            if (props.currentTemplate.SensitivityLabelType !== undefined) {
                if (props.currentTemplate.SensitivityLabelType === "Enforced") {
                    var currentValues_1 = getSensitivityLabelsConfigValues();
                    if (currentValues_1.length === 1) {
                        var foundTenantConfig = sensitivityLabelConfigs.find(function (tenantConfig) { return tenantConfig.Id === currentValues_1[0].Id; });
                        if (foundTenantConfig !== undefined) {
                            var updateTemplate = {};
                            if (foundTenantConfig.PermissionOverride !== null && foundTenantConfig.PermissionOverride !== undefined) {
                                if (props.currentTemplate.PermissionType !== foundTenantConfig.PermissionOverride) {
                                    updateTemplate = __assign(__assign({}, updateTemplate), { PermissionType: foundTenantConfig.PermissionOverride });
                                    //dispatch(TemplateCurrentStore.Update({ PermissionType: foundTenantConfig.PermissionOverride }))
                                }
                            }
                            if (foundTenantConfig.GroupUserAccessOverride !== null && foundTenantConfig.GroupUserAccessOverride !== undefined) {
                                if (props.currentTemplate.ConfigGroupAllowToAddGuests !== foundTenantConfig.GroupUserAccessOverride) {
                                    updateTemplate = __assign(__assign({}, updateTemplate), { ConfigGroupAllowToAddGuests: foundTenantConfig.GroupUserAccessOverride });
                                    //dispatch(TemplateCurrentStore.Update({ PermissionType: foundTenantConfig.PermissionOverride }))
                                }
                            }
                            if (foundTenantConfig.SPSharingCapabilityOverride !== null && foundTenantConfig.SPSharingCapabilityOverride !== undefined) {
                                if (props.currentTemplate.ConfigSPSharingCapability !== foundTenantConfig.SPSharingCapabilityOverride) {
                                    updateTemplate = __assign(__assign({}, updateTemplate), { ConfigSPSharingCapability: foundTenantConfig.SPSharingCapabilityOverride });
                                    //dispatch(TemplateCurrentStore.Update({ PermissionType: foundTenantConfig.PermissionOverride }))
                                }
                            }
                            if (Object.keys(updateTemplate).length > 0) {
                                dispatch(TemplateCurrentStore.Update(updateTemplate));
                            }
                        }
                    }
                }
            }
        }
    }, [props.currentTemplate.SensitivityLabelType, props.currentTemplate.SensitivityLabelsConfigJson, sensitivityLabels, sensitivityLabelConfigs]);
    var getNamingPolicyWarning = function () {
        var currentNamingPolicy = namingPolicies.find(function (np) { return np.GUID === props.currentTemplate.NamingPolicyGUID; });
        if (currentNamingPolicy) {
            if (currentNamingPolicy.RenameWorkspaceUrl && !currentNamingPolicy.RenameWorkspaceTitle) {
                return React.createElement("div", null, "Viva Engage Communities do not support URL naming policies. This policy will not be applied.");
            }
            else {
                return (React.createElement("div", null,
                    "This policy has both Title and URL naming policies enabled.",
                    React.createElement("br", null),
                    "Viva Engage Communities do not support URL naming policies, the URL portion of this naming policy will not be applied."));
            }
        }
    };
    var isPermissionLockedDueToLabel = function () {
        if (isSensitivityLabelsEnabledOnTenant !== null &&
            isSensitivityLabelsEnabledOnTenant !== undefined &&
            isSensitivityLabelsEnabledOnTenant === true &&
            sensitivityLabels !== undefined &&
            sensitivityLabelConfigs !== undefined) {
            if (props.currentTemplate.SensitivityLabelType !== undefined) {
                if (props.currentTemplate.SensitivityLabelType === "Enforced") {
                    var currentValues_2 = getSensitivityLabelsConfigValues();
                    if (currentValues_2.length === 1) {
                        var foundTenantConfig = sensitivityLabelConfigs.find(function (tenantConfig) { return tenantConfig.Id === currentValues_2[0].Id; });
                        if (foundTenantConfig !== undefined) {
                            if (foundTenantConfig.PermissionOverride !== null && foundTenantConfig.PermissionOverride !== undefined) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    };
    var isGuestAccessLockedDueToLabel = function () {
        if (isSensitivityLabelsEnabledOnTenant !== undefined && isSensitivityLabelsEnabledOnTenant === true && sensitivityLabels !== undefined && sensitivityLabelConfigs !== undefined) {
            if (props.currentTemplate.SensitivityLabelType !== undefined) {
                if (props.currentTemplate.SensitivityLabelType === "Enforced") {
                    var currentValues_3 = getSensitivityLabelsConfigValues();
                    if (currentValues_3.length === 1) {
                        var foundTenantConfig = sensitivityLabelConfigs.find(function (tenantConfig) { return tenantConfig.Id === currentValues_3[0].Id; });
                        if (foundTenantConfig !== undefined) {
                            if (foundTenantConfig.GroupUserAccessOverride !== null && foundTenantConfig.GroupUserAccessOverride !== undefined) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    };
    var isSharePointSharingLockedDueToLabel = function () {
        if (isSensitivityLabelsEnabledOnTenant !== undefined && isSensitivityLabelsEnabledOnTenant === true && sensitivityLabels !== undefined && sensitivityLabelConfigs !== undefined) {
            if (props.currentTemplate.SensitivityLabelType !== undefined) {
                if (props.currentTemplate.SensitivityLabelType === "Enforced") {
                    var currentValues_4 = getSensitivityLabelsConfigValues();
                    if (currentValues_4.length === 1) {
                        var foundTenantConfig = sensitivityLabelConfigs.find(function (tenantConfig) { return tenantConfig.Id === currentValues_4[0].Id; });
                        if (foundTenantConfig !== undefined) {
                            if (foundTenantConfig.SPSharingCapabilityOverride !== null) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    };
    var generateMetadataDropdownOptions = React.useMemo(function () {
        return [
            { id: MetadataConfigurationType.Inherit.toString(), title: "Inherit (All Enabled Metadata)", value: MetadataConfigurationType.Inherit },
            { id: MetadataConfigurationType.Subset.toString(), title: "Subset of Enabled Metadata", value: MetadataConfigurationType.Subset },
            {
                id: MetadataConfigurationType.None.toString(),
                title: "Hide All Metadata",
                value: MetadataConfigurationType.None,
                disabled: isHideDisabled,
                description: isHideDisabled ? "There are Metadata Fields Required by Naming Policy" : undefined
            }
        ];
    }, [isHideDisabled]);
    var setSelectedMetadataDropdownOption = function () {
        if (props.currentTemplate.NamingPolicyEnabled && props.currentTemplate.MetadataConfigurationType === MetadataConfigurationType.None && isHideDisabled) {
            dispatch(TemplateCurrentStore.Update({ MetadataConfigurationType: MetadataConfigurationType.Inherit, MetadataCustomizedFieldsEnabled: false }));
        }
    };
    var _q = React.useState(PermissionTypes.filter(function (wt, index) {
        if (props.currentTemplate.TemplateType === "SharePoint") {
            if (wt.Value !== "Organization") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }).map(function (wt, index) { return ({
        id: index + "",
        title: wt.Title,
        description: wt.Description,
        value: wt.Value,
        icon: wt.Icon
    }); })), permissionType = _q[0], setPermissionType = _q[1];
    var getSensitivityLabelsConfigValues = function () {
        if (props.currentTemplate.SensitivityLabelsConfigJson !== null &&
            props.currentTemplate.SensitivityLabelsConfigJson !== undefined &&
            props.currentTemplate.SensitivityLabelsConfigJson.indexOf("[") > -1) {
            var labelIdArray_1 = JSON.parse(props.currentTemplate.SensitivityLabelsConfigJson);
            if (sensitivityLabels !== null && sensitivityLabels !== undefined) {
                return sensitivityLabels === null || sensitivityLabels === void 0 ? void 0 : sensitivityLabels.filter(function (sl) {
                    return labelIdArray_1.some(function (lia) { return lia === sl.Id; });
                });
            }
        }
        return [];
    };
    var getSensitivityLabelsTenantConfigValues = function () {
        if (props.currentTemplate.SensitivityLabelsConfigJson !== null &&
            props.currentTemplate.SensitivityLabelsConfigJson !== undefined &&
            props.currentTemplate.SensitivityLabelsConfigJson.indexOf("[") > -1) {
            var labelIdArray_2 = JSON.parse(props.currentTemplate.SensitivityLabelsConfigJson);
            if (sensitivityLabelConfigs !== null && sensitivityLabelConfigs !== undefined) {
                return sensitivityLabelConfigs === null || sensitivityLabelConfigs === void 0 ? void 0 : sensitivityLabelConfigs.filter(function (sl) {
                    return labelIdArray_2.some(function (lia) { return lia === sl.Id; });
                });
            }
        }
        return [];
    };
    var mapSensitivityLabelsToDDOptions = function (labels) {
        return labels.map(function (sl) {
            return {
                id: sl.Id,
                title: sl.Name,
                description: sl.Description,
                value: sl.Id,
                customIcon: sl.Icon,
                object: sl
            };
        });
    };
    var mapDDOptionsToSensitivityLabels = function (options) {
        return options.map(function (o) { return o.object; });
    };
    var updateTemplateSensitivityLabels = function (labels) {
        dispatch(TemplateCurrentStore.Update({
            SensitivityLabelsConfigJson: JSON.stringify(labels.map(function (l) { return l.Id; }))
        }));
    };
    var getClassificationConfigValues = function () {
        if (props.currentTemplate.ClassificationsConfigJson !== null &&
            props.currentTemplate.ClassificationsConfigJson !== undefined &&
            props.currentTemplate.ClassificationsConfigJson.indexOf("[") > -1) {
            var labelIdArray_3 = JSON.parse(props.currentTemplate.ClassificationsConfigJson);
            if (classificationLabels !== null && classificationLabels !== undefined) {
                return classificationLabels === null || classificationLabels === void 0 ? void 0 : classificationLabels.filter(function (cc) {
                    return labelIdArray_3.some(function (ccName) { return ccName === cc.Key; });
                });
            }
        }
        return [];
    };
    var mapClassificationToDDOptions = function (classifications) {
        return classifications.map(function (cc, ccIndex) {
            return {
                id: cc.Key,
                title: cc.Key,
                description: cc.Description,
                value: cc.Key,
                customIcon: cc.Icon,
                object: cc
            };
        });
    };
    var mapDDOptionsToClassification = function (options) {
        return options.map(function (o) { return o.object; });
    };
    var updateTemplateClassifications = function (classifications) {
        dispatch(TemplateCurrentStore.Update({
            ClassificationsConfigJson: JSON.stringify(classifications.map(function (cc) { return cc.Key; }))
        }));
    };
    React.useEffect(function () {
        dispatch(MetadataListStore.Load([]));
    }, [props.currentTemplate.MetadataCustomizedFieldsEnabled, props.currentTemplate.MetadataConfigurationType]);
    // React.useEffect(() => {
    //    setisHideDisabled(getOrchestryMetadataOption().some(item => item.locked));
    // }, [metadata.length]);
    React.useEffect(function () {
        setisHideDisabled(getOrchestryMetadataOption().some(function (item) { return item.locked; }));
        updatePreselectedMetadata();
    }, [metadata, props.currentTemplate.NamingPolicyGUID, props.currentTemplate.NamingPolicyEnabled]);
    React.useEffect(function () { return setSelectedMetadataDropdownOption(); }, [isHideDisabled]);
    var updatePreselectedMetadata = function () {
        if (metadata !== null && metadata !== undefined && metadata.length > 0) {
            var options = getOrchestryMetadataOption();
            if (props.currentTemplate.MetadataCustomizedFieldsEnabled !== null &&
                props.currentTemplate.MetadataCustomizedFieldsEnabled !== undefined &&
                props.currentTemplate.MetadataCustomizedFieldsEnabled === true) {
                var currentMetadata_1 = props.currentTemplate.MetadataFields !== null && props.currentTemplate.MetadataFields !== undefined ? props.currentTemplate.MetadataFields : [];
                options.forEach(function (o) {
                    if (o.locked === true) {
                        if (currentMetadata_1.some(function (cm) { return cm === o.value; }) === false) {
                            currentMetadata_1.push(o.value);
                        }
                    }
                });
                dispatch(TemplateCurrentStore.Update({ MetadataFields: currentMetadata_1 }));
            }
        }
    };
    React.useEffect(function () {
        if (props.currentTemplate.ConfigSPSharingCapability !== null &&
            props.currentTemplate.ConfigSPSharingCapability !== undefined &&
            props.currentTemplate.ConfigSPSharingCapability !== "ExternalUserAndGuestSharing") {
            if (props.currentTemplate.ConfigSPSharingLinkType !== null &&
                props.currentTemplate.ConfigSPSharingLinkType !== undefined &&
                props.currentTemplate.ConfigSPSharingLinkType === "AnonymousAccess") {
                dispatch(TemplateCurrentStore.Update({ ConfigSPSharingLinkType: "" }));
            }
        }
    }, [props.currentTemplate.ConfigSPSharingCapability]);
    var getOrchestryMetadataOption = function () {
        var returnOptions = [];
        var checkMetadataFieldLocked = function (fieldGuid) {
            var isLocked = false;
            if (props.currentTemplate.NamingPolicyEnabled === true && props.currentTemplate.NamingPolicyGUID !== null && props.currentTemplate.NamingPolicyGUID !== undefined) {
                var namingPolicy = namingPolicies.find(function (np) { return np.GUID === props.currentTemplate.NamingPolicyGUID; });
                if (namingPolicy !== null && namingPolicy !== undefined) {
                    var isMetadataItemInNamingPolicy = function (jsonString) {
                        if (jsonString !== undefined && jsonString !== null && jsonString !== "") {
                            __spreadArray([], JSON.parse(jsonString), true).forEach(function (fix, fixIndex) {
                                if (fix.value !== undefined && fix.value !== null && fix.value !== "") {
                                    if (fix.type === "orchestry-metadata" && fix.value === fieldGuid) {
                                        isLocked = true;
                                    }
                                }
                            });
                        }
                    };
                    if (namingPolicy.RenameWorkspaceTitle === true) {
                        if (namingPolicy.TitlePrefixEnabled === true)
                            isMetadataItemInNamingPolicy(namingPolicy.TitlePrefixValue);
                        if (namingPolicy.TitleSuffixEnabled === true)
                            isMetadataItemInNamingPolicy(namingPolicy.TitleSuffixValue);
                    }
                    if (namingPolicy.RenameWorkspaceUrl === true) {
                        if (namingPolicy.UrlPrefixEnabled === true)
                            isMetadataItemInNamingPolicy(namingPolicy.UrlPrefixValue);
                        if (namingPolicy.UrlSuffixEnabled === true)
                            isMetadataItemInNamingPolicy(namingPolicy.UrlSuffixValue);
                    }
                }
            }
            return isLocked;
        };
        metadata.forEach(function (md, index) {
            returnOptions.push({
                id: index + "",
                title: md.Title,
                value: md.GUID,
                locked: checkMetadataFieldLocked(md.GUID),
                description: checkMetadataFieldLocked(md.GUID) ? "Required in Naming Policy" : undefined
            });
        });
        return returnOptions;
    };
    var getCustomizedMetadataFieldsAsOptions = function () {
        return props.currentTemplate.MetadataFields !== null && props.currentTemplate.MetadataFields !== undefined
            ? props.currentTemplate.MetadataFields.map(function (cv, index) {
                return getOrchestryMetadataOption().some(function (md) { return md.value === cv; }) ? getOrchestryMetadataOption().find(function (md) { return md.value === cv; }) : { id: cv, title: cv, value: cv };
            })
            : [];
    };
    var getAvailableGeoOptions = function () {
        var _a;
        return (_a = currentTenantConfiguration === null || currentTenantConfiguration === void 0 ? void 0 : currentTenantConfiguration.AvailableGeos) === null || _a === void 0 ? void 0 : _a.map(function (geo) {
            return {
                id: geo,
                title: geo,
                value: geo
            };
        });
    };
    React.useEffect(function () {
        if (orchestryContext.TenantIsEducation === true) {
            if (props.currentTemplate.TemplateType === "Team" && props.currentTemplate.LiveTemplateEnabled === true) {
                if (props.currentTemplate.LiveTemplateTeamID !== undefined && props.currentTemplate.LiveTemplateTeamID !== null && props.currentTemplate.LiveTemplateTeamID !== "") {
                    setLoadingPermissions(true);
                    TeamsService.GetTeamSpecialization(JSON.parse(props.currentTemplate.LiveTemplateTeamID).GUID).then(function (specialization) {
                        dispatch(TemplateCurrentStore.Update({ TeamTemplateSpecialization: specialization }));
                        setLoadingPermissions(false);
                    });
                }
                else {
                    dispatch(TemplateCurrentStore.Update({ TeamTemplateSpecialization: null }));
                }
            }
        }
    }, [props.currentTemplate.LiveTemplateEnabled, props.currentTemplate.TemplateType, props.currentTemplate.LiveTemplateTeamID]);
    return (React.createElement("div", { className: "template-tab-configuration", "data-pw": "configuration-tab" },
        React.createElement(Row, { className: "extra-margin-beteen-md-6" },
            React.createElement(Col, { md: 6 },
                React.createElement("h2", null, "Access and Permissions"),
                React.createElement("div", { className: "content-well", "data-pw": "access-permission" },
                    React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Configuration governing the workspace privacy and minimum Owners and Members"),
                    classificationOrSensitivityLabelsLoading === true ? (React.createElement("div", { style: { marginBottom: "26px" } },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginTop: 6 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginTop: 26 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginTop: 6 }))) : (React.createElement(React.Fragment, null,
                        isSensitivityLabelsEnabledOnTenant !== null && isSensitivityLabelsEnabledOnTenant !== undefined && isSensitivityLabelsEnabledOnTenant !== false && (React.createElement(DisableArea, { isDisabled: currentTenantConfiguration.IsMultiGeoEnabled && props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced" },
                            React.createElement(Form.Group, { controlId: "sensitivity-labels" },
                                React.createElement(Form.Label, null, "Sensitivity Labels"),
                                React.createElement(FormDropdownSelect, { disabled: !isSensitivityLabelsEnabledOnTenant || (currentTenantConfiguration.IsMultiGeoEnabled && props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced"), list: [
                                        {
                                            id: "none",
                                            value: "None",
                                            title: "None"
                                        },
                                        {
                                            id: "enforced",
                                            value: "Enforced",
                                            title: "Enforced",
                                            description: "Apply one specifed label to all workspaces created from this template."
                                        },
                                        {
                                            id: "choice",
                                            value: "Choice",
                                            title: "User Choice",
                                            description: "Allow the user to select a label based on your organization's label policies"
                                        }
                                    ], value: props.currentTemplate.SensitivityLabelType, defaultValue: props.currentTemplate.SensitivityLabelType, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ SensitivityLabelType: value })); } })),
                            props.currentTemplate.SensitivityLabelType === "Enforced" ? (React.createElement(Form.Group, { controlId: "sensitivity-labels", style: { marginTop: "-10px" } },
                                React.createElement(React.Fragment, null, sensitivityLabels === null || sensitivityLabels === undefined ? (React.createElement("div", { style: { marginBottom: "10px" } },
                                    React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement(React.Fragment, null,
                                    React.createElement(Form.Label, null, "Enforced Sensitivity Label"),
                                    React.createElement("div", { className: "label-description" }, "The user must have access to the label - if not, adding the label will fail."),
                                    React.createElement(FormDropdownSelect, { list: mapSensitivityLabelsToDDOptions(sensitivityLabels), defaultValue: getSensitivityLabelsConfigValues().length > 0 ? getSensitivityLabelsConfigValues()[0].Id : undefined, onChange: function (label) { return updateTemplateSensitivityLabels(sensitivityLabels.filter(function (sl) { return sl.Id === label.Id; })); }, value: getSensitivityLabelsConfigValues().length > 0 ? getSensitivityLabelsConfigValues()[0].Id : undefined }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "selected-enforced-sensitivity-label", ShowFirst: false, ShowAsInfo: true, Validators: ["array-has-items"], FieldName: "Enforce Sensitivity Label", Value: getSensitivityLabelsConfigValues() })))))) : null,
                            props.currentTemplate.SensitivityLabelType === "Choice" ? (React.createElement(Form.Group, { controlId: "sensitivity-labels", style: { marginTop: "-10px" } }, sensitivityLabels === null || sensitivityLabels === undefined ? (React.createElement("div", { style: { marginBottom: "10px" } },
                                React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement(React.Fragment, null,
                                React.createElement(Form.Label, null, "Users Choice Labels"),
                                React.createElement("div", { className: "label-description" }, "The user must have access to the labels - if not, adding the label will fail."),
                                React.createElement(FormDropdownMultiSelect, { placeholder: "-- Please select at least one label --", list: mapSensitivityLabelsToDDOptions(sensitivityLabels), values: mapSensitivityLabelsToDDOptions(getSensitivityLabelsConfigValues()), onChange: function (values) { return updateTemplateSensitivityLabels(mapDDOptionsToSensitivityLabels(values)); } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "selected-user-choice-sensitivity-labels", ShowFirst: false, ShowAsInfo: true, Validators: ["array-has-items"], FieldName: "Choice Sensitivity Labels", Value: getSensitivityLabelsConfigValues() }))))) : null)),
                        isClassificationsEnabledOnTenant !== null && isClassificationsEnabledOnTenant !== undefined && isClassificationsEnabledOnTenant !== false && (React.createElement(React.Fragment, null,
                            React.createElement(Form.Group, { controlId: "classifications-labels" },
                                React.createElement(Form.Label, null, "Classifications"),
                                React.createElement(FormDropdownSelect, { disabled: !isClassificationsEnabledOnTenant, list: [
                                        {
                                            id: "none",
                                            value: "None",
                                            title: "None"
                                        },
                                        {
                                            id: "enforced",
                                            value: "Enforced",
                                            title: "Enforced",
                                            description: "Apply one specifed classfication to all workspaces created from this template."
                                        },
                                        {
                                            id: "choice",
                                            value: "Choice",
                                            title: "User Choice",
                                            description: "Allow the user to select a classfication"
                                        }
                                    ], defaultValue: props.currentTemplate.ClassificationType, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ClassificationType: value })); } })),
                            props.currentTemplate.ClassificationType === "Enforced" ? (React.createElement(Form.Group, { controlId: "classification-labels", style: { marginTop: "-10px" } },
                                React.createElement(React.Fragment, null, classificationLabels === null || classificationLabels === undefined ? (React.createElement("div", { style: { marginBottom: "10px" } },
                                    React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement(React.Fragment, null,
                                    React.createElement(Form.Label, null, "Enforced Classification"),
                                    React.createElement(FormDropdownSelect, { list: mapClassificationToDDOptions(classificationLabels), defaultValue: getClassificationConfigValues().length > 0 ? getClassificationConfigValues()[0].Key : undefined, onChange: function (sl) { return updateTemplateClassifications(classificationLabels.filter(function (cl) { return cl.Key === sl.Key; })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "selected-enforced-classfication-label", ShowFirst: false, ShowAsInfo: true, Validators: ["array-has-items"], FieldName: "Enforce Classifications", Value: getClassificationConfigValues() })))))) : null,
                            props.currentTemplate.ClassificationType === "Choice" ? (React.createElement(Form.Group, { controlId: "classification-labels", style: { marginTop: "-10px" } }, classificationLabels === null || classificationLabels === undefined ? (React.createElement("div", { style: { marginBottom: "10px" } },
                                React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement(React.Fragment, null,
                                React.createElement(Form.Label, null, "Users Choice Classifications"),
                                React.createElement(FormDropdownMultiSelect, { placeholder: "-- Please select at least one classification --", list: mapClassificationToDDOptions(classificationLabels), values: mapClassificationToDDOptions(getClassificationConfigValues()), onChange: function (values) { return updateTemplateClassifications(mapDDOptionsToClassification(values)); } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "selected-user-choice-classfication-labels", ShowFirst: false, ShowAsInfo: true, Validators: ["array-has-items"], FieldName: "Choice Classifications", Value: getClassificationConfigValues() }))))) : null)))),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(Form.Group, { className: "default-permissions-form-group", controlId: "control-permission-type", "data-pw": "default-permission" },
                                React.createElement(Form.Label, null,
                                    "Default Permission Type ",
                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                loadingPermissions === true ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })) : props.currentTemplate.TeamTemplateSpecialization === "educationClass" ? (React.createElement(Form.Control, { disabled: true, value: "Hidden Membership (Locked for Class specialization)" })) : (React.createElement(FormDropdownSelect, { list: permissionType, disabled: isPermissionLockedDueToLabel(), 
                                    //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                    value: props.currentTemplate.PermissionType, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ PermissionType: value })); } })),
                                isSensitivityLabelsEnabledOnTenant ? (props.currentTemplate.SensitivityLabelType === "Choice" && getSensitivityLabelsConfigValues().some(function (l) { return l.PermissionOverride !== null; }) ? (React.createElement("div", { style: { marginTop: "15px" } },
                                    React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", title: "Possible permission override scenario", message: "One or more of the sensitivity labels selected above can override this setting in the user request process." }))) : props.currentTemplate.SensitivityLabelType === "Enforced" && getSensitivityLabelsConfigValues().some(function (l) { return l.PermissionOverride !== null; }) ? (React.createElement("div", { style: { marginTop: "15px" } },
                                    React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", title: "Permission overidden", message: "The sensitivity label selected above overrides this setting" }))) : null) : null,
                                React.createElement(FormValidator, { UniqueIdentifier: "permission-type", FieldName: "Permission Type", Enabled: props.currentTemplate.TeamTemplateSpecialization !== "educationClass", Validators: ["dropdown-has-value"], Value: props.currentTemplate.PermissionType }))),
                        React.createElement(Col, null)),
                    React.createElement(Form.Group, { controlId: "control-minumn-users", className: "min-users-control" },
                        React.createElement("div", { className: "min-users-left", "data-pw": "min-owners" },
                            React.createElement(Form.Label, null, "Minimum number of Owners"),
                            React.createElement(FormDropdownSelect, { list: [
                                    {
                                        id: "0",
                                        title: "Not Enforced",
                                        value: 0
                                    },
                                    {
                                        id: "2",
                                        title: "2",
                                        value: 2
                                    },
                                    {
                                        id: "3",
                                        title: "3",
                                        value: 3
                                    },
                                    {
                                        id: "4",
                                        title: "4",
                                        value: 4
                                    },
                                    {
                                        id: "5",
                                        title: "5",
                                        value: 5
                                    },
                                    {
                                        id: "6",
                                        title: "6",
                                        value: 6
                                    },
                                    {
                                        id: "7",
                                        title: "7",
                                        value: 7
                                    },
                                    {
                                        id: "8",
                                        title: "8",
                                        value: 8
                                    }
                                ], defaultValue: props.currentTemplate.MinOwners, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ MinOwners: Number(value) })); } })),
                        React.createElement("div", { className: "min-users-right", "data-pw": "min-members" },
                            React.createElement(Form.Label, null, "Minimum number of Members"),
                            React.createElement(FormDropdownSelect, { list: [
                                    {
                                        id: "0",
                                        title: "Not Enforced",
                                        value: 0
                                    },
                                    {
                                        id: "1",
                                        title: "1",
                                        value: 1
                                    },
                                    {
                                        id: "2",
                                        title: "2",
                                        value: 2
                                    },
                                    {
                                        id: "3",
                                        title: "3",
                                        value: 3
                                    },
                                    {
                                        id: "4",
                                        title: "4",
                                        value: 4
                                    },
                                    {
                                        id: "5",
                                        title: "5",
                                        value: 5
                                    },
                                    {
                                        id: "6",
                                        title: "6",
                                        value: 6
                                    },
                                    {
                                        id: "7",
                                        title: "7",
                                        value: 7
                                    },
                                    {
                                        id: "8",
                                        title: "8",
                                        value: 8
                                    }
                                ], 
                                //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                defaultValue: props.currentTemplate.MinMembers, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ MinMembers: Number(value) })); } })))),
                React.createElement("h2", null, "Approval Workflow"),
                React.createElement("div", { className: "content-well", "data-pw": "approval-workflow" },
                    React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Configuration governing whether an approval is required prior to provisioning a request"),
                    React.createElement(FormToggleControl, { checked: props.currentTemplate.RequireApprovalBeforeProvisioning, label: "Require Approval Before Provisioning:", handleChange: function (checked) { return dispatch(TemplateCurrentStore.Update({ RequireApprovalBeforeProvisioning: checked })); } }),
                    React.createElement(Collapse, { in: props.currentTemplate.RequireApprovalBeforeProvisioning, appear: true },
                        React.createElement("div", null, availableWorkflows !== undefined && availableWorkflows.length > 0 ? (React.createElement(Form.Group, { className: "form-group", controlId: "control-approval-workflow" },
                            React.createElement(Form.Label, null, "Selected Workflow"),
                            React.createElement(FormDropdownSelect, { list: availableWorkflows, defaultValue: props.currentTemplate.ApprovalWorflowGUID, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ApprovalWorflowGUID: value })); } }),
                            props.currentTemplate.RequireApprovalBeforeProvisioning === true ? (React.createElement(FormValidator, { UniqueIdentifier: "selected-workflow", ShowFirst: true, ShowAsInfo: true, Validators: ["string-not-empty"], FieldName: "Approval Workflow", Value: props.currentTemplate.ApprovalWorflowGUID })) : null)) : null))),
                React.createElement("h2", null, "Naming Policy"),
                React.createElement("div", { className: "content-well", "data-pw": "naming-policy" },
                    React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Configuration governing whether workspaces adhere by a defined naming policy"),
                    React.createElement(FormToggleControl, { checked: props.currentTemplate.NamingPolicyEnabled, label: "Use a Naming/Url Policy", handleChange: function (checked) {
                            dispatch(TemplateCurrentStore.Update({ NamingPolicyEnabled: checked }));
                            if (!checked)
                                dispatch(TemplateCurrentStore.Update({ NamingPolicyGUID: undefined }));
                        } }),
                    React.createElement(Collapse, { in: props.currentTemplate.NamingPolicyEnabled, appear: true },
                        React.createElement("div", null, availablePolicies !== undefined && availablePolicies.length > 0 ? (React.createElement(Form.Group, { className: "form-group", controlId: "control-approval-workflow", style: { marginTop: "-10px" } },
                            React.createElement(Form.Label, null, "Naming Policy"),
                            React.createElement(FormDropdownSelect, { list: availablePolicies, defaultValue: props.currentTemplate.NamingPolicyGUID, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ NamingPolicyGUID: value })); } }),
                            React.createElement(Collapse, { in: props.currentTemplate.NamingPolicyEnabled === true &&
                                    props.currentTemplate.TemplateType === "VivaEngageCommunity" &&
                                    namingPolicies.some(function (p) { return p.GUID === props.currentTemplate.NamingPolicyGUID && (p.UrlPrefixEnabled || p.UrlSuffixEnabled); }), appear: true },
                                React.createElement("div", { className: "viva-naming-warning" },
                                    React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", message: getNamingPolicyWarning() }))),
                            props.currentTemplate.NamingPolicyEnabled === true ? (React.createElement(FormValidator, { UniqueIdentifier: "selected-naming-policy", ShowFirst: true, ShowAsInfo: true, Validators: ["string-not-empty"], FieldName: "Naming policy", Value: props.currentTemplate.NamingPolicyGUID })) : null)) : null))),
                React.createElement("h2", null, "Metadata Fields"),
                React.createElement("div", { className: "content-well", "data-pw": "metadata-fields" },
                    React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Configuration governing whether workspace requests surface defined metadata"),
                    React.createElement("div", { className: "metadata-config-dropdown", "data-pw": "metadata-config-dropdown" },
                        React.createElement(Async, { uid: MetadataListStore.MetadataListActionTypes.Metadata_List_Load, status: AsyncTypes.AsyncStatus.Started },
                            React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 38 })),
                        React.createElement(Async, { uid: MetadataListStore.MetadataListActionTypes.Metadata_List_Load, status: AsyncTypes.AsyncStatus.Succeeded },
                            React.createElement(FormDropdownSelect, { list: generateMetadataDropdownOptions, onChange: function (value) {
                                    var MetadataCustomizedFieldsEnabled = value === MetadataConfigurationType.Subset;
                                    dispatch(TemplateCurrentStore.Update({ MetadataConfigurationType: value, MetadataCustomizedFieldsEnabled: MetadataCustomizedFieldsEnabled }));
                                }, value: props.currentTemplate.MetadataConfigurationType }))),
                    React.createElement(Collapse, { in: props.currentTemplate.MetadataCustomizedFieldsEnabled && props.currentTemplate.MetadataConfigurationType === MetadataConfigurationType.Subset, appear: true },
                        React.createElement("div", null,
                            React.createElement(Async, { uid: MetadataListStore.MetadataListActionTypes.Metadata_List_Load, status: AsyncTypes.AsyncStatus.Started },
                                React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 100, bodyHeight: 38 })),
                            React.createElement(Async, { uid: MetadataListStore.MetadataListActionTypes.Metadata_List_Load, status: AsyncTypes.AsyncStatus.Succeeded },
                                React.createElement(Form.Group, { className: "form-group" },
                                    React.createElement(FormDropdownMultiSelect, { list: getOrchestryMetadataOption(), values: getCustomizedMetadataFieldsAsOptions(), onChange: function (values) { return dispatch(TemplateCurrentStore.Update({ MetadataFields: values.map(function (v) { return v.value; }) })); } }))))))),
            React.createElement(Col, { md: 6 },
                !(props.currentTemplate.TemplateType === "SharePoint" && props.currentTemplate.SharePointTemplateType === "ModernCommunications") && (React.createElement(React.Fragment, null,
                    React.createElement("h2", null, "Group Configuration Options"),
                    React.createElement("div", { className: "content-well", "data-pw": "group-configuration-options" },
                        React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Configuration governing the Microsoft 365 group behaviour"),
                        props.currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement(Form.Group, { controlId: "checkbox-allow-guests", "data-pw": "allow-guests" },
                                    React.createElement(Form.Label, null,
                                        "Guest Access Enabled \u00A0\u00A0",
                                        React.createElement(InfoTooltip, null, "When sensitivity labels are not used, this setting can prevent guests from being added to a particular Group.")),
                                    GuestAccessIsGovernedBySensitivityLabel() ? (React.createElement(Form.Control, { disabled: true, value: "Governed by Sensitivity Label" })) : WorkspaceHasGuestPolicy() ? (React.createElement(Form.Control, { disabled: true, value: "Governed by Guest Policy" })) : (React.createElement(FormDropdownSelect, { list: [
                                            {
                                                id: "0",
                                                title: "Tenant Default",
                                                value: null
                                            },
                                            {
                                                id: "1",
                                                title: "Yes",
                                                value: true
                                            },
                                            {
                                                id: "2",
                                                title: "No",
                                                value: false
                                            }
                                        ], disabled: isGuestAccessLockedDueToLabel() || WorkspaceHasGuestPolicy(), defaultValue: props.currentTemplate.ConfigGroupAllowToAddGuests, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigGroupAllowToAddGuests: value })); } })),
                                    isSensitivityLabelsEnabledOnTenant &&
                                        props.currentTemplate.SensitivityLabelType === "Choice" &&
                                        getSensitivityLabelsTenantConfigValues().some(function (l) { return l.GroupUserAccessOverride !== null; }) ? (React.createElement("div", { style: { marginTop: "15px" } },
                                        React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", title: "Possible guest access override scenario", message: "One or more of the sensitivity labels selected above can override this setting in the user request process." }))) : null)),
                            React.createElement(Col, null,
                                React.createElement(Form.Group, { controlId: "checkbox-gal", "data-pw": "hide-organization-address" },
                                    React.createElement(Form.Label, null,
                                        React.createElement(React.Fragment, null,
                                            "Hide from my organizations global address list",
                                            " ",
                                            React.createElement(InfoTooltip, null, "Specify whether the Workspace mailbox is hidden from all users in the Exchange Global Address List (GAL)."))),
                                    React.createElement(FormDropdownSelect, { list: [
                                            {
                                                id: "0",
                                                title: "Tenant Default",
                                                value: null
                                            },
                                            {
                                                id: "1",
                                                title: "Yes",
                                                value: true
                                            },
                                            {
                                                id: "2",
                                                title: "No",
                                                value: false
                                            }
                                        ], 
                                        //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                        defaultValue: props.currentTemplate.ConfigGroupHideFromAddressLists, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigGroupHideFromAddressLists: value })); } }))))) : null,
                        React.createElement(Row, null,
                            props.currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Col, null,
                                React.createElement(Form.Group, { className: "form-group", controlId: "checkbox-outlook", "data-pw": "hide-exchange-client" },
                                    React.createElement(Form.Label, null,
                                        React.createElement(React.Fragment, null,
                                            "Hide from Exchange Client ",
                                            React.createElement(InfoTooltip, null, "Specify whether the Workspace is hidden from all users in the Groups section in Outlook."))),
                                    React.createElement(FormDropdownSelect, { list: [
                                            {
                                                id: "0",
                                                title: "Tenant Default",
                                                value: null
                                            },
                                            {
                                                id: "1",
                                                title: "Yes",
                                                value: true
                                            },
                                            {
                                                id: "2",
                                                title: "No",
                                                value: false
                                            }
                                        ], 
                                        //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                        defaultValue: props.currentTemplate.ConfigGroupHideFromOutlookClients, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigGroupHideFromOutlookClients: value })); } })))) : null,
                            React.createElement(Col, null, props.currentTemplate.TeamTemplateSpecialization === undefined || props.currentTemplate.TeamTemplateSpecialization === null ? (React.createElement(Form.Group, { controlId: "checkbox-email-disable", "data-pw": "disable-group-welcome" },
                                React.createElement(Form.Label, null,
                                    React.createElement(React.Fragment, null,
                                        "Disable Group Welcome Email",
                                        " ",
                                        React.createElement(InfoTooltip, null, "Disable sending system-generated welcome messages to users who are added as members to the Microsoft 365 Group"))),
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "0",
                                            title: "Tenant Default",
                                            value: null
                                        },
                                        {
                                            id: "1",
                                            title: "Yes",
                                            value: true
                                        },
                                        {
                                            id: "2",
                                            title: "No",
                                            value: false
                                        }
                                    ], 
                                    //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                    defaultValue: props.currentTemplate.ConfigGroupDisableWelcomeEmail, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigGroupDisableWelcomeEmail: value })); } }))) : null)),
                        props.currentTemplate.TemplateType !== "VivaEngageCommunity" ? (React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement(Form.Group, { className: "form-group", controlId: "checkbox-external-email", "data-pw": "allow-external-senders" },
                                    React.createElement(Form.Label, null,
                                        React.createElement(React.Fragment, null,
                                            "Allow external senders to email this group",
                                            " ",
                                            React.createElement(InfoTooltip, { delayHide: 1500 }, "Specify whether the new group mailbox can receive email from senders outside your organization"))),
                                    React.createElement(FormDropdownSelect, { list: [
                                            {
                                                id: "0",
                                                title: "Tenant Default",
                                                value: null
                                            },
                                            {
                                                id: "1",
                                                title: "Yes",
                                                value: true
                                            },
                                            {
                                                id: "2",
                                                title: "No",
                                                value: false
                                            }
                                        ], 
                                        //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                        defaultValue: props.currentTemplate.ConfigGroupAllowExternalSenders, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigGroupAllowExternalSenders: value })); } }))),
                            React.createElement(Col, null,
                                React.createElement(Form.Group, { controlId: "checkbox-subscribe-all-emails-events", "data-pw": "subscribe-group-emails" },
                                    React.createElement(Form.Label, null,
                                        React.createElement(React.Fragment, null,
                                            "Subscribe All Members to Group Emails and Events",
                                            " ",
                                            React.createElement(InfoTooltip, { delayHide: 1500 }, "When enabled, group members will get a copy of group emails and meeting invitations sent to their Outlook Inbox. They can read and delete the copy without impacting the original that resides in the Group inbox. When disabled, group members will need to go to the group to see this activity."))),
                                    React.createElement(FormDropdownSelect, { list: [
                                            {
                                                id: "0",
                                                title: "Tenant Default",
                                                value: null
                                            },
                                            {
                                                id: "1",
                                                title: "Yes",
                                                value: true
                                            },
                                            {
                                                id: "2",
                                                title: "No",
                                                value: false
                                            }
                                        ], defaultValue: props.currentTemplate.ConfigGroupSubscribeAllMembersEmailsEvents, onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigGroupSubscribeAllMembersEmailsEvents: value })); } }))))) : null))),
                React.createElement("h2", null, "SharePoint Configuration Options"),
                React.createElement("div", { className: "content-well", "data-pw": "sharepoint-configuration-options" },
                    React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Configuration governing the SharePoint site behaviour"),
                    React.createElement("h3", null, "Sharing Options"),
                    React.createElement(Form.Group, { controlId: "select-sharing", "data-pw": "external-sharing" },
                        React.createElement(Form.Label, null,
                            "External Sharing Options",
                            React.createElement("a", { href: "https://docs.microsoft.com/en-US/sharepoint/change-external-sharing-site", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "fa fa-external-link-alt" }))),
                        React.createElement(FormDropdownSelect, { list: [
                                {
                                    id: "0",
                                    value: "Same",
                                    title: "Tenant Default"
                                },
                                {
                                    id: "1",
                                    value: "ExternalUserAndGuestSharing",
                                    title: SharePointService.settingsLabels.SharingCapability.ExternalUserAndGuestSharing.title,
                                    description: SharePointService.settingsLabels.SharingCapability.ExternalUserAndGuestSharing.description
                                },
                                {
                                    id: "3",
                                    value: "ExternalUserSharingOnly",
                                    title: SharePointService.settingsLabels.SharingCapability.ExternalUserSharingOnly.title,
                                    description: SharePointService.settingsLabels.SharingCapability.ExternalUserSharingOnly.description
                                },
                                {
                                    id: "2",
                                    value: "ExistingExternalUserSharingOnly",
                                    title: SharePointService.settingsLabels.SharingCapability.ExistingExternalUserSharingOnly.title,
                                    description: SharePointService.settingsLabels.SharingCapability.ExistingExternalUserSharingOnly.description
                                },
                                {
                                    id: "4",
                                    value: "Disabled",
                                    title: SharePointService.settingsLabels.SharingCapability.Disabled.title,
                                    description: SharePointService.settingsLabels.SharingCapability.Disabled.description
                                }
                            ], disabled: isSharePointSharingLockedDueToLabel(), value: props.currentTemplate.ConfigSPSharingCapability !== "" ? props.currentTemplate.ConfigSPSharingCapability : "Same", onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigSPSharingCapability: value === "Same" ? "" : value })); } }),
                        isSensitivityLabelsEnabledOnTenant ? (props.currentTemplate.SensitivityLabelType === "Choice" && getSensitivityLabelsTenantConfigValues().some(function (l) { return l.SPSharingCapabilityOverride !== null; }) ? (React.createElement("div", { style: { marginTop: "15px" } },
                            React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", title: "Possible sharing override scenario", message: "One or more of the sensitivity labels selected above can override this setting in the user request process." }))) : props.currentTemplate.SensitivityLabelType === "Enforced" && getSensitivityLabelsTenantConfigValues().some(function (l) { return l.SPSharingCapabilityOverride !== null; }) ? (React.createElement("div", { style: { marginTop: "15px" } },
                            React.createElement(PortletAlert, { type: "warning", icon: "info", size: "small", title: "Sharing overidden", message: "The sensitivity label selected above overrides this setting" }))) : null) : null),
                    React.createElement(Collapse, { in: props.currentTemplate.ConfigSPSharingCapability !== undefined &&
                            props.currentTemplate.ConfigSPSharingCapability !== "" &&
                            props.currentTemplate.ConfigSPSharingCapability !== "Disabled", appear: true },
                        React.createElement("div", null,
                            React.createElement(Form.Group, { controlId: "select-sharing" },
                                React.createElement(Form.Label, null,
                                    "Limit sharing by domain",
                                    React.createElement("a", { href: "https://docs.microsoft.com/en-us/sharepoint/restricted-domains-sharing", target: "_blank", rel: "noopener noreferrer" },
                                        React.createElement("i", { className: "fa fa-external-link-alt" }))),
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "1",
                                            value: "None",
                                            title: SharePointService.settingsLabels.SharingDomainRestrictionMode.None.title
                                        },
                                        {
                                            id: "2",
                                            value: "AllowList",
                                            title: SharePointService.settingsLabels.SharingDomainRestrictionMode.AllowList.title
                                        },
                                        {
                                            id: "3",
                                            value: "BlockList",
                                            title: SharePointService.settingsLabels.SharingDomainRestrictionMode.BlockList.title
                                        }
                                    ], defaultValue: props.currentTemplate.ConfigSPSharingDomainRestrictionMode !== undefined && props.currentTemplate.ConfigSPSharingDomainRestrictionMode !== ""
                                        ? props.currentTemplate.ConfigSPSharingDomainRestrictionMode
                                        : "None", onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigSPSharingDomainRestrictionMode: value })); } })),
                            React.createElement(Collapse, { in: props.currentTemplate.ConfigSPSharingDomainRestrictionMode !== null &&
                                    props.currentTemplate.ConfigSPSharingDomainRestrictionMode !== undefined &&
                                    props.currentTemplate.ConfigSPSharingDomainRestrictionMode !== "" &&
                                    props.currentTemplate.ConfigSPSharingDomainRestrictionMode !== "None", appear: true },
                                React.createElement("div", null,
                                    React.createElement(Form.Group, { controlId: "control-domain-list" },
                                        React.createElement(Form.Label, null, "Enter each domain on a new line"),
                                        React.createElement(Form.Control, { as: "textarea", placeholder: "contoso.com", rows: 3, defaultValue: props.currentTemplate.ConfigSPSharingDomainList || "", onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ ConfigSPSharingDomainList: event.currentTarget.value })); } })))))),
                    React.createElement(Collapse, { in: props.currentTemplate.ConfigSPSharingCapability !== undefined && props.currentTemplate.ConfigSPSharingCapability === "ExternalUserAndGuestSharing", appear: true },
                        React.createElement("div", null,
                            React.createElement("h6", null, "Advanced settings for Anyone links"),
                            React.createElement(Form.Group, { controlId: "select-link-anyone-links" },
                                React.createElement(Form.Label, null, "Expiration of Anyone links"),
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "1",
                                            value: "Same",
                                            title: "Tenant Default"
                                            //title: SharePointService.settingsLabels.Same.title + " (" + (defaultTenantSettings.RequireAnonymousLinksExpireInDays === -1 ? "Never":"Expires in " + defaultTenantSettings.RequireAnonymousLinksExpireInDays + " days" ) + ")"
                                        },
                                        {
                                            id: "2",
                                            value: "Never",
                                            title: "These links never expire"
                                        },
                                        {
                                            id: "3",
                                            value: "Expire",
                                            title: "These links must expire within this many days"
                                        }
                                    ], value: props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays === -1 ? "Never" : props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays === null ? "Same" : "Expire", onChange: function (value) {
                                        return dispatch(TemplateCurrentStore.Update({
                                            ConfigSPAnonymousLinkExpirationInDays: value === "Same"
                                                ? null
                                                : value === "Never"
                                                    ? -1
                                                    : value === "Expire" &&
                                                        props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== null &&
                                                        props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== -1
                                                        ? props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays
                                                        : 1
                                        }));
                                    } })),
                            React.createElement(Collapse, { in: props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== undefined &&
                                    props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== null &&
                                    props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== -1, appear: true },
                                React.createElement(Form.Group, { controlId: "control-position" },
                                    React.createElement(Form.Label, null, "Links expire in how many days?"),
                                    React.createElement(Form.Control, { type: "number", name: "points", step: "1", value: (props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== undefined && props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays !== null
                                            ? props.currentTemplate.ConfigSPAnonymousLinkExpirationInDays
                                            : 1).toString(), onChange: function (event) {
                                            return dispatch(TemplateCurrentStore.Update({ ConfigSPAnonymousLinkExpirationInDays: Number(event.currentTarget.value) }));
                                        } }))))),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(Form.Group, { controlId: "select-link-access", "data-pw": "default-link-type" },
                                React.createElement(Form.Label, null,
                                    "Default Link Type",
                                    React.createElement("a", { href: "https://docs.microsoft.com/en-us/sharepoint/change-default-sharing-link", target: "_blank", rel: "noopener noreferrer" },
                                        React.createElement("i", { className: "fa fa-external-link-alt" }))),
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "1",
                                            value: "Same",
                                            //title: SharePointService.settingsLabels.Same.title + " (" + SharePointService.settingsLabels.DefaultSharingLinkType[defaultTenantSettings.DefaultSharingLinkType].title + ")"
                                            title: "Tenant Default"
                                        },
                                        {
                                            id: "2",
                                            value: "None",
                                            title: SharePointService.settingsLabels.DefaultSharingLinkType.None.title
                                        },
                                        {
                                            id: "3",
                                            value: "Direct",
                                            title: SharePointService.settingsLabels.DefaultSharingLinkType.Direct.title
                                        },
                                        {
                                            id: "4",
                                            value: "Internal",
                                            title: SharePointService.settingsLabels.DefaultSharingLinkType.Internal.title
                                        },
                                        {
                                            id: "5",
                                            value: "AnonymousAccess",
                                            title: SharePointService.settingsLabels.DefaultSharingLinkType.AnonymousAccess.title
                                        }
                                    ].filter(function (item) {
                                        if (item.value === "AnonymousAccess") {
                                            if (props.currentTemplate.ConfigSPSharingCapability !== "ExternalUserAndGuestSharing") {
                                                return false;
                                            }
                                            else {
                                                return true;
                                            }
                                        }
                                        return true;
                                    }), value: props.currentTemplate.ConfigSPSharingLinkType !== undefined &&
                                        props.currentTemplate.ConfigSPSharingLinkType !== null &&
                                        props.currentTemplate.ConfigSPSharingLinkType !== ""
                                        ? props.currentTemplate.ConfigSPSharingLinkType
                                        : "Same", onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigSPSharingLinkType: value === "Same" ? "" : value })); } }))),
                        React.createElement(Col, null,
                            React.createElement(Form.Group, { controlId: "select-link-access", "data-pw": "default-link-permissions" },
                                React.createElement(Form.Label, null, "Default Link Permissions"),
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "0",
                                            value: "Same",
                                            title: "Tenant Default"
                                            //title: SharePointService.settingsLabels.Same.title + (defaultTenantSettings.DefaultLinkPermission !== "None" ? " (" + SharePointService.settingsLabels.DefaultLinkPermission[defaultTenantSettings.DefaultLinkPermission].title + ")":""),
                                        },
                                        {
                                            id: "1",
                                            value: "View",
                                            title: SharePointService.settingsLabels.DefaultLinkPermission.View.title
                                        },
                                        {
                                            id: "2",
                                            value: "Edit",
                                            title: SharePointService.settingsLabels.DefaultLinkPermission.Edit.title
                                        }
                                    ], 
                                    // defaultValue={}
                                    defaultValue: props.currentTemplate.ConfigSPLinkPermission !== undefined && props.currentTemplate.ConfigSPLinkPermission !== ""
                                        ? props.currentTemplate.ConfigSPLinkPermission
                                        : "Same", onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ ConfigSPLinkPermission: value === "Same" ? "" : value })); } }))))),
                React.createElement(Collapse, { in: currentTenantConfiguration.IsMultiGeoEnabled },
                    React.createElement("div", { className: "content-well", "data-pw": "multigeo-fields" },
                        React.createElement("h2", null, "Data Storage"),
                        React.createElement("div", { className: "subheader", "data-pw": "subheader" }, "Your tenant is enabled for Multi-Geo and the template can be configured to a PDL of your preference."),
                        React.createElement(DisableArea, { isDisabled: props.currentTemplate.TemplateType === "VivaEngageCommunity", disabledText: "Multi Geo Configuration for Viva Engage is currently not supported" },
                            React.createElement(Form.Group, { controlId: "multigeo-source" },
                                React.createElement(Form.Label, null, "Location Application"),
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "Default",
                                            value: "Default",
                                            title: "Use Tenant Default PDL (".concat(currentTenantConfiguration.DefaultGeo, ")")
                                        },
                                        {
                                            id: "Enforced",
                                            value: "Enforced",
                                            title: "Enforced",
                                            description: "Enforce a single PDL for all template users."
                                        }
                                    ], disabled: props.currentTemplate.TemplateType === "VivaEngageCommunity", value: props.currentTemplate.TemplateMultiGeoDataLocationSource || "Default", onChange: function (value) { return dispatch(TemplateCurrentStore.Update({ TemplateMultiGeoDataLocationSource: value, TemplateMultiGeoDataLocations: undefined })); } }))),
                        props.currentTemplate.TemplateMultiGeoDataLocationSource === "RequestorsChoice" ? (React.createElement(React.Fragment, null,
                            React.createElement(Form.Group, { controlId: "multigeo-geographies" },
                                React.createElement(Form.Label, null, "Available Geographies"),
                                React.createElement(FormDropdownMultiSelect, { list: getAvailableGeoOptions() || [], values: ((_a = getAvailableGeoOptions()) === null || _a === void 0 ? void 0 : _a.filter(function (option) {
                                        var _a;
                                        return (_a = props.currentTemplate.TemplateMultiGeoDataLocations) === null || _a === void 0 ? void 0 : _a.split(",").map(function (s) { return s.trim(); }).includes(option.id);
                                    })) || [], onChange: function (value) {
                                        return dispatch(TemplateCurrentStore.Update({ TemplateMultiGeoDataLocations: value.reduce(function (values, val) { return __spreadArray(__spreadArray([], values, true), [val.id], false); }, []).join(",") }));
                                    } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "multigeo-geographies", ShowFirst: false, ShowAsInfo: true, Validators: ["array-must-have-at-least-2"], FieldName: "Available Geographies", Value: (_b = props.currentTemplate.TemplateMultiGeoDataLocations) === null || _b === void 0 ? void 0 : _b.split(",") })))) : null,
                        props.currentTemplate.TemplateMultiGeoDataLocationSource === "Enforced" ? (React.createElement(React.Fragment, null,
                            React.createElement(Form.Group, { controlId: "multigeo-geographies" },
                                React.createElement(Form.Label, null,
                                    "Enforced Geography \u00A0\u00A0",
                                    props.currentTemplate.TemplateMultiGeoDataLocations !== currentTenantConfiguration.DefaultGeo && (React.createElement(InfoTooltip, null, "Enforcing a geography will disable Live Templating, Sensitivity labels, provisioning of the Orchestry Team Information Tab as well as clear all lifecycle policies associated with this template."))),
                                React.createElement(FormDropdownSelect, { list: ((_c = getAvailableGeoOptions()) === null || _c === void 0 ? void 0 : _c.filter(function (selection) { return selection.value !== currentTenantConfiguration.DefaultGeo; })) || [], defaultValue: props.currentTemplate.TemplateMultiGeoDataLocations, onChange: function (value) {
                                        return dispatch(TemplateCurrentStore.Update(__assign({ TemplateMultiGeoDataLocations: value, GuestRequestPolicyGuid: null, GuestReviewPolicyGuid: null, LifecycleArchivePolicyGuid: null, ProvisionInformationWebpart: false, ProvisionInfromationTab: false }, enforcedGeoLiveTemplateSettings)));
                                    } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "multigeo-enforced-geography", ShowFirst: false, ShowAsInfo: true, Validators: ["string-not-empty"], FieldName: "Enforced Geography", Value: props.currentTemplate.TemplateMultiGeoDataLocations })))) : null))),
            React.createElement(Col, { md: 6 },
                React.createElement(Row, null,
                    React.createElement(Col, null))))));
});
export default TabConfiguration;
