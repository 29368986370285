import { TenantLicenseStatus } from "app/store/tenants";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { useGetHealthCheckQuery } from "appv2/api/health-checks/health-checks.api";
import { useOrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/use-orchestry-annotation-section.hook";
import { useHealthCheckRouteParams } from "../../health-checks/use-health-check-route-params.hook";
export var useHealthCheckAnnotationCounts = function () {
    var _a;
    var _b = useHealthCheckRouteParams(), _c = _b.healthCheckGuid, healthCheckGuid = _c === void 0 ? "" : _c, tenantGuid = _b.tenantGuid, userType = _b.userType;
    var _d = useGetHealthCheckQuery([userType, healthCheckGuid, tenantGuid]), data = _d.data, isHealthCheckLoading = _d.isLoading;
    var hasOrchestryUsage = !!((_a = data === null || data === void 0 ? void 0 : data.MetricsData) === null || _a === void 0 ? void 0 : _a.OrchestryUsage) && (data === null || data === void 0 ? void 0 : data.ReportTenantLicenseStatus) !== TenantLicenseStatus.Beacon;
    var _e = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.General, false, undefined), generalAnnotationCount = _e.annotationCount, isGeneralAnnotationLoading = _e.isLoading;
    var _f = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.OrchestryUsage, false, undefined), orchestryUsageAnnotationCount = _f.annotationCount, isOrchestryUsageAnnotationLoading = _f.isLoading;
    var _g = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.Workspaces, false, undefined), workspacesAnnotationCount = _g.annotationCount, isWorkspacesAnnotationLoading = _g.isLoading;
    var _h = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.Benchmarks, false, undefined), benchmarksAnnotationCount = _h.annotationCount, isBenchmarksAnnotationLoading = _h.isLoading;
    var _j = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.SharingLinksCreated, false, undefined), sharingLinksCreatedAnnotationCount = _j.annotationCount, isSharingLinksCreatedAnnotationLoading = _j.isLoading;
    var _k = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.StorageAnalysis, false, undefined), storageAnalysisAnnotationCount = _k.annotationCount, isStorageAnalysisAnnotationLoading = _k.isLoading;
    var _l = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.TeamsEngagementThisMonth, false, undefined), teamsEngagementThisMonthAnnotationCount = _l.annotationCount, isTeamsEngagementThisMonthAnnotationLoading = _l.isLoading;
    var _m = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.Leaderboard, false, undefined), leaderboardAnnotationCount = _m.annotationCount, isLeaderboardAnnotationLoading = _m.isLoading;
    var _o = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.Recommendations, false, undefined), recommendationsAnnotationCount = _o.annotationCount, isRecommendationsAnnotationLoading = _o.isLoading;
    var _p = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.TimeSavings, false, undefined), timeSavingsAnnotationCount = _p.annotationCount, isTimeSavingsAnnotationLoading = _p.isLoading;
    var isLoading = isGeneralAnnotationLoading ||
        isOrchestryUsageAnnotationLoading ||
        isWorkspacesAnnotationLoading ||
        isBenchmarksAnnotationLoading ||
        isSharingLinksCreatedAnnotationLoading ||
        isStorageAnalysisAnnotationLoading ||
        isTeamsEngagementThisMonthAnnotationLoading ||
        isLeaderboardAnnotationLoading ||
        isRecommendationsAnnotationLoading ||
        isTimeSavingsAnnotationLoading ||
        isHealthCheckLoading;
    var totalAnnotationCount = generalAnnotationCount +
        workspacesAnnotationCount +
        benchmarksAnnotationCount +
        sharingLinksCreatedAnnotationCount +
        storageAnalysisAnnotationCount +
        teamsEngagementThisMonthAnnotationCount +
        leaderboardAnnotationCount +
        recommendationsAnnotationCount +
        timeSavingsAnnotationCount;
    if (hasOrchestryUsage) {
        totalAnnotationCount += orchestryUsageAnnotationCount;
    }
    return {
        generalAnnotationCount: generalAnnotationCount,
        totalAnnotationCount: totalAnnotationCount,
        isLoading: isLoading,
        isGeneralAnnotationLoading: isGeneralAnnotationLoading
    };
};
