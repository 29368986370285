import React from "react";
import { Route, Switch } from "react-router-dom";
import GuestDashboard from "app/components/insights/guests/dashboard";
import GuestInsights from "app/components/insights/guests/guest-report";
import GuestPoliciesList from "app/components/lifecycle/guests/policies/lifecycle-guests-list";
import GuestSettings from "app/components/lifecycle/guests/guest-settings";
import GuestRequestDetails from "app/components/lifecycle/guests/policies/requests";
import GuestReviewDetails from "app/components/lifecycle/guests/policies/reviews";
import GuestListing from "app/components/insights/guests/guest-report";
export default function GuestPages() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/guests/dashboard", component: GuestDashboard }),
        React.createElement(Route, { exact: true, path: "/guests/listing", component: GuestInsights }),
        React.createElement(Route, { exact: true, path: "/guests/listing/insight/:param2?", component: GuestListing }),
        React.createElement(Route, { exact: true, path: "/guests/listing/:guestId/:param2?", component: GuestListing }),
        React.createElement(Route, { exact: true, path: "/guests/policies", component: GuestPoliciesList }),
        React.createElement(Route, { exact: true, path: "/guests/policies/new/request", component: GuestRequestDetails }),
        React.createElement(Route, { exact: true, path: "/guests/policies/edit/Request/:guid", component: GuestRequestDetails }),
        React.createElement(Route, { exact: true, path: "/guests/policies/new/review", component: GuestReviewDetails }),
        React.createElement(Route, { exact: true, path: "/guests/policies/edit/Review/:guid", component: GuestReviewDetails }),
        React.createElement(Route, { exact: true, path: "/guests/settings", component: GuestSettings })));
}
