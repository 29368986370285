import { Table } from "@mantine/core";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { HealthCheckRecommendationRow } from "./health-check-recommendation-row.component";
import { MobileHealthCheckRecommendationRow } from "./mobile-health-check-recommendation-row.component";
export var HealthCheckRecommendations = function () {
    var _a = useHealthCheckActivity(), isLoading = _a.isLoading, isMobile = _a.isMobile, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.Recommendations, _g = _f === void 0 ? {} : _f, TopRecommendations = _g.TopRecommendations;
    if (!TopRecommendations)
        return null;
    return (React.createElement(Table, null,
        React.createElement(Table.Tbody, null, TopRecommendations.map(function (recommendation) {
            return isMobile ? (React.createElement(MobileHealthCheckRecommendationRow, { key: recommendation.RecommendationRecord.RecommendationGUID, recommendation: recommendation, isLoading: isLoading })) : (React.createElement(HealthCheckRecommendationRow, { key: recommendation.RecommendationRecord.RecommendationGUID, recommendation: recommendation, isLoading: isLoading }));
        }))));
};
