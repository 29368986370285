import { MetadataCurrentActionTypes } from "./types";
export function Load(metadata) {
    if (metadata === undefined)
        metadata = {};
    //console.log("Load Current Template > ", template)
    return {
        type: MetadataCurrentActionTypes.Metadata_Current_Load,
        payload: metadata
    };
}
export function Update(metadata) {
    return {
        type: MetadataCurrentActionTypes.Metadata_Current_Update,
        payload: metadata
    };
}
export function Clear() {
    return {
        type: MetadataCurrentActionTypes.Metadata_Current_Clear
    };
}
export var metadataCurrentMiddlware = function (store, next, action) {
    next(action);
};
