import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-guest-policies/current/";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
var StepCardName = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "step-card-name policy-card", "data-pw": "step-card-name" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                props.OnProceedChange(isValid);
            }, UniqueIdentifier: props.StepKey },
            React.createElement(Row, null,
                React.createElement(Col, { className: "" },
                    React.createElement("div", { className: "left-side card-border" },
                        React.createElement("h4", null, "Name"),
                        React.createElement("p", { className: "text" }, "A Guest Request Policy can be used by Team Owners and Administrators to ensure additional information about Guest users is captured and that they undergo a review process before they are added to Workspaces."),
                        React.createElement(Form.Group, { className: "form-group", controlId: "policy-name" },
                            React.createElement(Form.Label, null,
                                "Name ",
                                React.createElement("span", { className: "required-astrix" }, "*")),
                            React.createElement(Form.Control, { placeholder: "Enter name", "data-pw": "name-input", autoComplete: "off", defaultValue: currentPolicy.Name != null ? currentPolicy.Name : "", value: currentPolicy.Name != null ? currentPolicy.Name : "", onChange: function (event) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Name: event.target.value })); } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "stepcardname-name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-3"], Value: currentPolicy.Name })),
                        React.createElement(Form.Group, { className: "form-group", controlId: "description" },
                            React.createElement(Form.Label, null,
                                "Description ",
                                React.createElement("span", { className: "required-astrix" }, "*")),
                            React.createElement(Form.Control, { as: "textarea", rows: 3, placeholder: "Enter description", "data-pw": "description-input", autoComplete: "off", value: currentPolicy.Description != null ? currentPolicy.Description : "", defaultValue: currentPolicy.Description != null ? currentPolicy.Description : "", onChange: function (event) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Description: event.target.value })); } }),
                            React.createElement(FormValidator, { UniqueIdentifier: "stepcardname-description", FieldName: "Description", Validators: ["string-not-empty", "string-is-more-than-3"], Value: currentPolicy.Description }))))))));
});
export default StepCardName;
