import * as React from "react";
import "./template-list.scss";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as TemplateListStore from "app/store/templates/list";
import * as TableList from "app/components/common/table-list";
import { SubtitleButton } from "app/components/common/subtitle";
import LibraryTemplateBrowser from "app/components/workspace-library/library-template-browser";
import textEllipsis from "app/utils/textEllipsis";
var TemplateList = React.memo(function () {
    var dispatch = useDispatch();
    var templates = useSelector(function (state) { return state.templateListState.items; });
    var libraryDiv = React.useRef();
    var _a = React.useState(false), libOpen = _a[0], setLibOpen = _a[1];
    //console.log("Template LIst Component Templates > ", templates);
    React.useEffect(function () {
        dispatch(TemplateListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var renderType = function (template) {
        //console.log("renderType", template.TemplateType);
        if (template.TemplateType === "SharePoint" && template.SharePointTemplateType === "ModernCommunications") {
            return (React.createElement("div", { className: "wp-type", "data-pw": "communication-site-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint Communication Site", className: "fadein-500ms" })),
                React.createElement("span", null, "SharePoint Communication Site")));
        }
        if (template.TemplateType === "SharePoint" && template.SharePointTemplateType === "ModernGroup") {
            return (React.createElement("div", { className: "wp-type", "data-pw": "team-site-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint Team Site", className: "fadein-500ms" })),
                React.createElement("span", null, "SharePoint Team Site")));
        }
        if (template.TemplateType === "Team") {
            return (React.createElement("div", { className: "wp-type", "data-pw": "team-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" })),
                React.createElement("span", null, "Microsoft Team")));
        }
        if (template.TemplateType === "VivaEngageCommunity") {
            return (React.createElement("div", { className: "wp-type", "data-pw": "viva-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconViva, alt: "Viva Engage", className: "fadein-500ms" })),
                React.createElement("span", null, "Viva Engage Community")));
        }
        return React.createElement(React.Fragment, null);
    };
    var toggleAvailability = function (template, checked) {
        // Change this to use List_Item_Update and do the true/false logic here.
        //dispatch(TemplateListStore.UpdateItem(currentTemplate));
        template = Object.assign(template, { Available: checked });
        dispatch({ type: TemplateListStore.TemplateListActionTypes.Template_List_Item_Update, payload: template });
    };
    React.useEffect(function () {
        try {
            libraryDiv.current.onclick = toggleLibOpen;
        }
        catch (_a) { }
    });
    var toggleLibOpen = function () {
        setLibOpen(!libOpen);
    };
    var renderProvisionCount = function (template) {
        var currentCount = template.ArtifactsUsingThisTemplate;
        if (currentCount === null || currentCount === undefined) {
            currentCount = 0;
        }
        return currentCount;
    };
    return (React.createElement("div", { className: "template-list fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Templates", subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a template" })),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement("div", { ref: libraryDiv, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Orchestry Template Library", faIcon: "book-open", variant: "success" })),
            React.createElement(NavLink, { exact: true, to: "/workspaces/templates/new" },
                React.createElement(SubtitleButton, { title: "New Template", faIcon: "plus", color: "orange" }))),
        React.createElement(LibraryTemplateBrowser, { open: libOpen, toggleOpen: function () {
                toggleLibOpen();
            } }),
        React.createElement(TableList.Context, { listItems: templates, asyncListLoadUID: TemplateListStore.TemplateListActionTypes.Template_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Template Name"),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, null, "Type"),
                React.createElement(TableList.HeaderItem, null, "Lifecycle Policies"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Position"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "# Created"),
                React.createElement(TableList.HeaderItem, { type: "centered" }, "Available"),
                React.createElement(TableList.HeaderItem, { type: "actions", mobileTitle: "Actions" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title", isMobileTitle: true, link: "/workspaces/templates/edit/{GUID}" }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) {
                        var description = item.Description;
                        if (description === null || description === void 0 ? void 0 : description.startsWith("[{")) {
                            var descriptionArray = JSON.parse(description);
                            description = descriptionArray.reduce(function (descString, element) {
                                //@ts-ignore
                                element.children.forEach(function (child) {
                                    descString += child.text;
                                });
                                return descString;
                            }, "");
                        }
                        return React.createElement(React.Fragment, null, textEllipsis(description, 100));
                    } }),
                React.createElement(TableList.ContentItemCustomRender, { render: renderType, className: "td-type" }),
                React.createElement(TableList.ContentItem, { listDataProperyName: "LifecycleArchivePolicyName" }),
                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "Position" }),
                React.createElement(TableList.ContentItemCustomRender, { render: renderProvisionCount, className: "td-type text-center" }),
                React.createElement(TableList.ContentItemToggle, { listDataProperyName: "Available", handleChange: function (item, checked) { return toggleAvailability(item, checked); } }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/templates/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/templates/edit/{GUID}"
                    onRemove: function (item) { return dispatch(TemplateListStore.DeleteItem(item)); }, deleteCheckType: "template" })))));
});
export default TemplateList;
