import React from "react";
import "./guest-action-button-delete.scss";
import OrchModal from "app/components/common/orch-modal";
import { SubtitleButton } from "app/components/common/subtitle";
import axios from "axios";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var GuestActionButtonDelete = React.memo(function (props) {
    var _a = React.useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var deleteUser = function () {
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Guests/DeleteGuest?guestId=" + props.cosmosUser.UserId;
        axiosConfig.responseType = "json";
        setLoading(true);
        axios(axiosConfig)
            .then(function () {
            if (props.postActionEvent !== undefined) {
                props.postActionEvent(function () {
                    setLoading(false);
                    setModalOpen(false);
                });
            }
            else {
                setLoading(false);
                setModalOpen(false);
            }
        })
            .catch(function (response) { });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "guest-action-button-delete", onClick: function () { return setModalOpen(!modalOpen); }, "data-pw": "guest-action-button-delete" }, props.children),
        React.createElement(OrchModal, { showModalOverride: modalOpen, showModalToggleOverride: setModalOpen, size: "sm", showCloseButton: true, headerText: "Guest Delete Confirmation", footerContents: React.createElement("div", { className: "button-row" }, loading === true ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, bodyWidth: 150 })) : (React.createElement(React.Fragment, null,
                React.createElement(SubtitleButton, { title: "Cancel", color: "outline-gray", onClick: function () { return setModalOpen(!modalOpen); }, noMargin: true }),
                React.createElement(SubtitleButton, { title: "Delete Guest", onClick: function () { return deleteUser(); }, color: "brandblue", noMargin: false })))) },
            React.createElement("div", null, "Are you sure you want to delete this guest?"))));
});
export default GuestActionButtonDelete;
