import UsersService from "app/services/users";
import { useEffect, useState } from "react";
/**
 * Gets a user by their ID from the UsersService API.
 *
 * @param userId The user ID to get the user for
 */
export var useGetUser = function (userId) {
    var _a = useState(null), user = _a[0], setUser = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    useEffect(function () {
        if (userId) {
            setIsLoading(true);
            UsersService.getUserByID(userId).then(function (fetchedUser) {
                setIsLoading(false);
                setUser(fetchedUser);
            });
        }
        else {
            setUser(null);
            setIsLoading(false);
        }
    }, [userId]);
    return { user: user, isLoading: isLoading };
};
