import * as React from "react";
import { Redirect } from "react-router-dom";
import StepCardName from "./cards/1-card-name";
import StepCardGuestRequest from "./cards/2-card-guest-request";
import StepCardPolicyExecution from "./cards/3-card-policy-execution";
import StepCardNotificationFormat from "./cards/4-card-notification-format";
import LifecycleWizard from "app/components/insights/common/lifecycle-wizard";
import LifecycleTabs from "app/components/insights/common/lifecycle-tabs";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { useDispatch, useSelector } from "react-redux";
import Store from "app/store";
import * as LifecycleGuestPolicyCurrentStore from "app/store/lifecycle-guest-policies/current";
import * as LifecycleGuestPolicyListStore from "app/store/lifecycle-guest-policies/list";
import { LifecycleGuestPolicyType } from "app/store/lifecycle-guest-policies";
var defaultWizardSteps = [
    {
        title: "Name",
        key: "name",
        card: StepCardName,
        complete: false,
        current: true,
        hidden: false
    },
    {
        title: "Guest Request",
        key: "guestrequest",
        card: StepCardGuestRequest,
        current: false,
        complete: false,
        hidden: false
    },
    {
        title: "Policy Execution",
        key: "policyexecution",
        card: StepCardPolicyExecution,
        current: false,
        complete: false,
        hidden: false
    },
    {
        title: "Notification Format",
        key: "notificationformat",
        card: StepCardNotificationFormat,
        current: false,
        complete: false,
        hidden: false
    }
];
var defaultTabs = [
    {
        title: "Name",
        key: "name",
        card: StepCardName,
        valid: true,
        hidden: false
    },
    {
        title: "Guest Request",
        key: "guestrequest",
        card: StepCardGuestRequest,
        valid: true,
        hidden: false
    },
    {
        title: "Policy Execution",
        key: "policyexecution",
        card: StepCardPolicyExecution,
        valid: true,
        hidden: false
    },
    {
        title: "Notification Format",
        key: "notificationformat",
        card: StepCardNotificationFormat,
        valid: true,
        hidden: false
    }
];
var GuestRequestDetails = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecycleGuestPoliciesCurrentState.item; });
    var _a = React.useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var dispatch = useDispatch();
    var _c = React.useState(defaultWizardSteps), wizardSteps = _c[0], setWizardSteps = _c[1];
    var _d = React.useState(defaultTabs), tabs = _d[0], setTabs = _d[1];
    React.useEffect(function () {
        if (props.match.params.guid === undefined || props.match.params.guid === null) {
            dispatch(LifecycleGuestPolicyCurrentStore.Clear());
            dispatch(LifecycleGuestPolicyCurrentStore.Update(LifecycleGuestPolicyCurrentStore.createEmptyPolicy(LifecycleGuestPolicyType.Request)));
        }
        else if (props.match.params.guid) {
            dispatch(LifecycleGuestPolicyCurrentStore.Load(LifecycleGuestPolicyType.Request, props.match.params.guid));
        }
    }, [props.match.params.guid]);
    React.useEffect(function () {
        // console.log("currentPolicy", currentPolicy)
        if (currentPolicy.GUID !== undefined) {
            setIsLoading(false);
        }
    }, [currentPolicy]);
    //prepend image data string
    React.useEffect(function () {
        if (currentPolicy.GuestDefaultProfileImage && !currentPolicy.GuestDefaultProfileImage.includes("data:")) {
            dispatch(LifecycleGuestPolicyCurrentStore.UpdateProperties({ GuestDefaultProfileImage: "data:image/png;base64," + currentPolicy.GuestDefaultProfileImage }));
        }
    }, [currentPolicy.GuestDefaultProfileImage]);
    var save = function () {
        var asyncCallType = AsyncCallType.Update;
        if (props.match.params.guid === undefined || props.match.params.guid.indexOf("clone") > -1) {
            // First, add the new template to template list
            asyncCallType = AsyncCallType.Add;
            dispatch(LifecycleGuestPolicyListStore.AddCurrentItem());
        }
        else {
            dispatch(LifecycleGuestPolicyListStore.UpdateCurrentItem());
        }
        var state = Store.getState();
        var thePolicy = state.lifecycleGuestPoliciesCurrentState.item;
        if (thePolicy.GUID != null) {
            console.log("Setup Wait", thePolicy);
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, thePolicy.GUID).then(function () {
                console.log("Save - Create/Update for policy complete");
                setBackToList(true);
                dispatch(LifecycleGuestPolicyCurrentStore.Clear());
            });
        }
    };
    var clearCurrentTemplate = function () {
        dispatch(LifecycleGuestPolicyCurrentStore.Clear());
    };
    var _e = React.useState({
        title: "Policies",
        subTitle: "Create Guest Request Policy",
        buttonCancelText: "Cancel",
        buttonFinishText: "Finish",
        buttonPreviousText: "Previous",
        buttonNextText: "Save and continue to next step",
        navTitle: "Back to Guest Policies",
        navRouteString: "/guests/policies",
        onSave: save
        //onStepsUpdated: updateStepsInConfig
    }), wizardConfig = _e[0], setWizardConfig = _e[1];
    var _f = React.useState({
        title: "Guest Request Policy",
        titleLink: "/guests/policies/",
        buttonCancelText: "Cancel",
        buttonSaveText: "Save",
        onSave: save,
        onClear: clearCurrentTemplate
        //onStepsUpdated: updateStepsInConfig
    }), tabsConfig = _f[0], setTabsConfig = _f[1];
    return backToList ? (React.createElement(Redirect, { to: "/guests/policies" })) : (React.createElement(React.Fragment, null, props.match.params.guid === undefined ? (React.createElement(LifecycleWizard, { config: wizardConfig, stepss: wizardSteps })) : props.match.params.guid.indexOf("clone") > -1 ? (React.createElement(LifecycleWizard, { config: wizardConfig, stepss: wizardSteps })) : (React.createElement(React.Fragment, null,
        React.createElement(LifecycleTabs, { isLoading: isLoading, config: tabsConfig, tabs: tabs })))));
});
export default GuestRequestDetails;
