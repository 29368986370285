var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LibraryChannelListActionTypes } from "./types";
export function Load(LibraryChannels) {
    if (LibraryChannels === undefined)
        LibraryChannels = [];
    return {
        type: LibraryChannelListActionTypes.LibraryChannel_List_Load,
        payload: __spreadArray([], LibraryChannels, true)
    };
}
export function AddItem(LibraryChannel) {
    return {
        type: LibraryChannelListActionTypes.LibraryChannel_List_Item_Add,
        payload: __assign({}, LibraryChannel)
    };
}
export function UpdateItem(LibraryChannel) {
    return {
        type: LibraryChannelListActionTypes.LibraryChannel_List_Item_Update,
        payload: __assign({}, LibraryChannel)
    };
}
export function DeleteItem(LibraryChannel) {
    return {
        type: LibraryChannelListActionTypes.LibraryChannel_List_Item_Delete,
        payload: __assign({}, LibraryChannel)
    };
}
export var libraryChannelListMiddlware = function (store, next, action) {
    next(action);
};
