import * as React from "react";
import "./document-template-preview.scss";
import DocumentTemplateService from "app/services/document-template";
import AnimatedLoader from "../animated-loader";
import DocumentIcon from "../document-icon";
import LocalSessionService from "app/services/local-session";
import { useIntl } from "react-intl";
var DocumentTemplatePreview = React.memo(function (props) {
    var intl = useIntl();
    var _a = React.useState(), documentTemplateSharePointListItem = _a[0], setDocumentTemplateSharePointListItem = _a[1];
    var GetTemplateFromSharePoint = function () {
        if (props.ListId !== undefined && props.ListId !== null && props.ListId !== "") {
            if (LocalSessionService.KeyExists("cached-doc-temp-sp-item-" + props.ListId)) {
                setDocumentTemplateSharePointListItem(LocalSessionService.GetObject("cached-doc-temp-sp-item-" + props.ListId));
            }
            else {
                DocumentTemplateService.getTemplateFromSharePoint(props.ListId).then(function (data) {
                    if (data.Status === 0) {
                        setDocumentTemplateSharePointListItem(data.Payload);
                    }
                    LocalSessionService.SetObject("cached-doc-temp-sp-item-" + props.ListId, data.Payload);
                    console.log("Doc Template SP Data", data);
                });
            }
        }
    };
    React.useEffect(function () {
        GetTemplateFromSharePoint();
    }, [props.ListId]);
    if (props.title !== undefined && props.fileType !== undefined) {
        return (React.createElement("div", { className: "document-template-preview file-preview" },
            documentTemplateSharePointListItem === undefined ? (React.createElement("div", { className: "loader preview" },
                React.createElement(AnimatedLoader, { size: "med", theme: "dark", text: "" }))) : (React.createElement(React.Fragment, null, documentTemplateSharePointListItem.FieldValues.thumbnailBase64 !== undefined && documentTemplateSharePointListItem.FieldValues.thumbnailBase64 !== null ? (React.createElement("div", { className: "thumbnail preview" },
                props.linksEnabled === undefined || (props.linksEnabled !== undefined && props.linksEnabled === false) ? (React.createElement("img", { src: documentTemplateSharePointListItem.FieldValues.thumbnailBase64, alt: documentTemplateSharePointListItem.FieldValues.FileRef })) : (React.createElement("a", { href: documentTemplateSharePointListItem.FieldValues.AbsoluteFileRef, target: "_blank" },
                    React.createElement("img", { src: documentTemplateSharePointListItem.FieldValues.thumbnailBase64, alt: documentTemplateSharePointListItem.FieldValues.FileRef }))),
                React.createElement("div", { className: "remove-button-wrapper" }, props.OnRemove !== undefined ? (React.createElement("div", { className: "remove-button", onClick: function () {
                        //@ts-ignore
                        props.OnRemove();
                    } },
                    React.createElement("i", { className: "fa fa-trash" }))) : null))) : (React.createElement("div", { className: "no-thumbnail" },
                React.createElement("div", { className: "large-preview-icon preview" },
                    props.linksEnabled !== undefined && props.linksEnabled === false ? (React.createElement(DocumentIcon, { docName: documentTemplateSharePointListItem.FieldValues.FileRef, appendDocumentType: false })) : (React.createElement("a", { href: documentTemplateSharePointListItem.FieldValues.AbsoluteFileRef, target: "_blank" },
                        React.createElement(DocumentIcon, { docName: documentTemplateSharePointListItem.FieldValues.FileRef, appendDocumentType: false }))),
                    React.createElement("div", { className: "no-preview" }, props.noPreviewText !== undefined
                        ? props.noPreviewText
                        : intl.formatMessage({ id: "component.document-select-modal.preview.no_preview_text", defaultMessage: "No preview available" })),
                    React.createElement("div", { className: "remove-button-wrapper" }, props.OnRemove !== undefined ? (React.createElement("div", { className: "remove-button", onClick: function () {
                            //@ts-ignore
                            props.OnRemove();
                        } },
                        React.createElement("i", { className: "fa fa-trash" }))) : null)))))),
            props.showFileName !== undefined && props.showFileName === true ? (React.createElement("div", { className: "file-name" }, props.title !== undefined ? props.title : documentTemplateSharePointListItem !== undefined ? documentTemplateSharePointListItem.FieldValues.FileLeafRef : "")) : null,
            (props.showFileType === undefined || (props.showFileType !== undefined && props.showFileType === true)) && props.fileType !== undefined && props.fileType !== null ? (React.createElement("div", { className: "file-type-icon-width-text" },
                React.createElement(DocumentIcon, { docName: props.fileType, appendDocumentType: true }))) : null));
    }
    else if (props.fileName !== undefined) {
        return (React.createElement("div", { className: "document-template-preview file-preview with-file-name" },
            React.createElement("div", { className: "no-thumbnail" },
                React.createElement("div", { className: "large-preview-icon preview" },
                    React.createElement(DocumentIcon, { docName: props.fileName, appendDocumentType: false }),
                    React.createElement("div", { className: "no-preview" }, props.noPreviewText !== undefined
                        ? props.noPreviewText
                        : intl.formatMessage({ id: "component.document-select-modal.preview.no_preview_text", defaultMessage: "No preview available" })),
                    props.azureLink !== undefined ? (React.createElement("a", { href: props.azureLink, className: "download-button" },
                        React.createElement("i", { className: "fa fa-download" }))) : null,
                    React.createElement("div", { className: "remove-button-wrapper" }, props.OnRemove !== undefined ? (React.createElement("div", { className: "remove-button", onClick: function () {
                            //@ts-ignore
                            props.OnRemove();
                        } },
                        React.createElement("i", { className: "fa fa-trash" }))) : null)),
                props.showFileName !== undefined && props.showFileName === true ? React.createElement("div", { className: "file-name" }, props.fileName) : null,
                props.showFileType === undefined || (props.showFileType !== undefined && props.showFileType === true) ? (React.createElement("div", { className: "file-type-icon-width-text" },
                    React.createElement(DocumentIcon, { docName: props.fileName, appendDocumentType: true }))) : null)));
    }
    else if (documentTemplateSharePointListItem !== undefined) {
        return (React.createElement("div", { className: "document-template-preview file-preview" }, documentTemplateSharePointListItem.FieldValues.thumbnailBase64 !== undefined && documentTemplateSharePointListItem.FieldValues.thumbnailBase64 !== null ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "thumbnail preview" },
                props.linksEnabled === undefined || (props.linksEnabled !== undefined && props.linksEnabled === false) ? (React.createElement("img", { src: documentTemplateSharePointListItem.FieldValues.thumbnailBase64, alt: documentTemplateSharePointListItem.FieldValues.FileRef })) : (React.createElement("a", { href: documentTemplateSharePointListItem.FieldValues.AbsoluteFileRef, target: "_blank" },
                    React.createElement("img", { src: documentTemplateSharePointListItem.FieldValues.thumbnailBase64, alt: documentTemplateSharePointListItem.FieldValues.FileRef }))),
                React.createElement("div", { className: "remove-button-wrapper" }, props.OnRemove !== undefined ? (React.createElement("div", { className: "remove-button", onClick: function () {
                        //@ts-ignore
                        props.OnRemove();
                    } },
                    React.createElement("i", { className: "fa fa-trash" }))) : null)),
            props.showFileName !== undefined && props.showFileName === true ? (React.createElement("div", { className: "file-name" }, props.title !== undefined ? props.title : documentTemplateSharePointListItem.FieldValues.FileLeafRef)) : null,
            props.showFileType === undefined || (props.showFileType !== undefined && props.showFileType === true) ? (React.createElement("div", { className: "file-type-icon-width-text" },
                React.createElement(DocumentIcon, { docName: documentTemplateSharePointListItem.FieldValues.FileRef, appendDocumentType: true }))) : null)) : (React.createElement("div", { className: "no-thumbnail" },
            React.createElement("div", { className: "large-preview-icon preview" },
                props.linksEnabled !== undefined && props.linksEnabled === false ? (React.createElement(DocumentIcon, { docName: documentTemplateSharePointListItem.FieldValues.FileRef, appendDocumentType: false })) : (React.createElement("a", { href: documentTemplateSharePointListItem.FieldValues.AbsoluteFileRef, target: "_blank" },
                    React.createElement(DocumentIcon, { docName: documentTemplateSharePointListItem.FieldValues.FileRef, appendDocumentType: false }))),
                React.createElement("div", { className: "no-preview" }, props.noPreviewText !== undefined
                    ? props.noPreviewText
                    : intl.formatMessage({ id: "component.document-select-modal.preview.no_preview_text", defaultMessage: "No preview available" })),
                React.createElement("div", { className: "remove-button-wrapper" }, props.OnRemove !== undefined ? (React.createElement("div", { className: "remove-button", onClick: function () {
                        //@ts-ignore
                        props.OnRemove();
                    } },
                    React.createElement("i", { className: "fa fa-trash" }))) : null)),
            props.showFileName !== undefined && props.showFileName === true ? (React.createElement("div", { className: "file-name" }, props.title !== undefined ? props.title : documentTemplateSharePointListItem.FieldValues.FileLeafRef)) : null,
            props.showFileType === undefined || (props.showFileType !== undefined && props.showFileType === true) ? (React.createElement("div", { className: "file-type-icon-width-text" },
                React.createElement(DocumentIcon, { docName: documentTemplateSharePointListItem.FieldValues.FileRef, appendDocumentType: true }))) : null))));
    }
    else {
        return (React.createElement("div", { className: "document-template-preview file-preview" },
            React.createElement("div", { className: "loader preview" },
                React.createElement(AnimatedLoader, { size: "xsmall", text: "" }))));
    }
});
export default DocumentTemplatePreview;
