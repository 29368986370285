import { Group, Stack } from "@mantine/core";
import React from "react";
import { OrchestryAnnotation } from "../orchestry-annotation/orchestry-annotation.component";
import { OrchestryCircle } from "../orchestry-circle/orchestry-circle.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import { useOrchestryAnnotationSection } from "./use-orchestry-annotation-section.hook";
export var OrchestryAnnotations = function (_a) {
    var annotationCategory = _a.annotationCategory, renderListLabelWithAnnotations = _a.renderListLabelWithAnnotations, margin = _a.margin, associatedGUID = _a.associatedGUID;
    var _b = useOrchestryAnnotationSection(annotationCategory, true, associatedGUID), intl = _b.intl, annotations = _b.annotations, isLoading = _b.isLoading, onDeleteAnnotationClicked = _b.onDeleteAnnotationClicked, shouldShowMutativeButtons = _b.shouldShowMutativeButtons;
    return (React.createElement(Stack, { gap: 24, m: margin !== null && margin !== void 0 ? margin : 24 }, annotations === null || annotations === void 0 ? void 0 : annotations.map(function (annotation, index) {
        var _a, _b, _c, _d, _e;
        return (React.createElement("div", { key: annotation.UniqueId },
            index === 0 && renderListLabelWithAnnotations && (React.createElement(Group, { mb: 24 },
                React.createElement(OrchestryCircle, { isLoading: isLoading, width: 14, color: "var(--mantine-color-error-5)" }),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-text-primary-0)", size: "14px", fw: 500, lh: "20px" }, intl.formatMessage({
                    id: "annotations_list.label",
                    defaultMessage: "Annotations:"
                })))),
            React.createElement(OrchestryAnnotation, { body: annotation.Body, uniqueId: (_a = annotation.UniqueId) !== null && _a !== void 0 ? _a : "", onDeleteClicked: shouldShowMutativeButtons ? onDeleteAnnotationClicked : undefined, userId: (_b = annotation.AuthorDisplayName) !== null && _b !== void 0 ? _b : "", annotationDate: (_c = annotation.UpdatedDate) !== null && _c !== void 0 ? _c : "", authorDisplayName: (_d = annotation.AuthorDisplayName) !== null && _d !== void 0 ? _d : "", authorBase64Photo: (_e = annotation.AuthorBase64Photo) !== null && _e !== void 0 ? _e : "" },
                React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-text-secondary-0)", size: "12px", fw: 400, lh: "18px" }, annotation.Body))));
    })));
};
