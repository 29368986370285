var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AsyncWait } from "app/components/common/async";
import FormButton, { FormButtons } from "app/components/common/form-button";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import OrchModal from "app/components/common/orch-modal";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { TeamsUserActivity } from "app/components/common/teams-status";
import InsightsReport from "app/components/insights/common/report";
import UsersGuestsService from "app/services/users-guests";
import { AsyncStatus } from "app/store/async";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { ReportDatasource, ReportSearchSlotName } from "app/store/lifecycle-report";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import TeamClientAppNavigation from "app/utils/teamClientAppNavigation";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import "./lifecycle-details-users.scss";
var ResendModalMessage = function (_a) {
    var workspaceName = _a.workspaceName, plural = _a.plural;
    return (React.createElement("div", null, workspaceName ? (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "Would you like to resend the invite", "".concat(plural ? "s" : ""),
            " for:"),
        React.createElement("p", null,
            React.createElement("strong", null, workspaceName)),
        " ")) : (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Are you sure you would like to resend the invitation?")))));
};
var RemoveGuestFromWorkspaceMessage = function (_a) {
    var workspaceName = _a.workspaceName, plural = _a.plural;
    return (React.createElement("div", null,
        React.createElement("p", null,
            "Are you sure you'd like to remove ", "".concat(plural ? "these guests from" : "this guest from"),
            ":"),
        React.createElement("p", null,
            React.createElement("strong", null, workspaceName))));
};
var listScrollAnimationNames = {
    enter: "to-the-right-enter",
    enterActive: "to-the-right-enter-active",
    enterDone: "to-the-right-enter-done",
    exit: "to-the-left-enter",
    exitActive: "to-the-left-enter-active",
    exitDone: "to-the-left-enter-done"
};
var PaginationToolbar = function (_a) {
    var currentPage = _a.currentPage, totalResults = _a.totalResults, setCurrentPage = _a.setCurrentPage, setScrollDirection = _a.setScrollDirection;
    var isLastPage = function () {
        //console.log("Last Page Check", (Math.ceil((Number(props.totalResults) / props.currentResultsPerPage))))
        return currentPage + 1 === Math.ceil(Number(totalResults) / 20);
    };
    var renderPageNumber = function (math) {
        var pageNumber = currentPage + math;
        var isUnderTotal = pageNumber < Math.ceil(Number(totalResults) / 20);
        //console.log("Total Results", "Page Number > " + pageNumber, isUnderTotal);
        if (pageNumber > -1 && isUnderTotal) {
            return (React.createElement("li", { key: math, onClick: function () {
                    setCurrentPage(pageNumber);
                    math < 0 ? setScrollDirection("to-the-left") : setScrollDirection("to-the-right");
                } },
                React.createElement("a", null, pageNumber + 1)));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement("div", { className: "paging" },
        React.createElement("div", { className: "kt-pagination  kt-pagination--brand" },
            React.createElement("ul", { className: "kt-pagination__links" },
                currentPage > 0 ? (React.createElement("li", { className: "kt-pagination__link--next", onClick: function () {
                        setCurrentPage(currentPage - 1);
                        setScrollDirection("to-the-left");
                    } },
                    React.createElement("i", { className: "fa fa-angle-left" }))) : (React.createElement("li", { className: "kt-pagination__link--next", style: { opacity: "0.3" } },
                    React.createElement("i", { className: "fa fa-angle-left" }))),
                renderPageNumber(-2),
                renderPageNumber(-1),
                React.createElement("li", { className: "kt-pagination__link--active" }, currentPage + 1),
                renderPageNumber(1),
                renderPageNumber(2),
                isLastPage() !== true ? (React.createElement("li", { className: "kt-pagination__link--prev", onClick: function () {
                        setCurrentPage(currentPage + 1);
                        setScrollDirection("to-the-right");
                    } },
                    React.createElement("i", { className: "fa fa-angle-right" }))) : null))));
};
var LifecycleDetailsTabUsers = React.memo(function (props) {
    var dispatch = useDispatch();
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var _a = React.useState(null), groupId = _a[0], setGroupId = _a[1];
    var allMembers = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.members; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _b = React.useState(false), processing = _b[0], setProcessing = _b[1];
    var _c = React.useState(null), bulkJob = _c[0], setBulkJob = _c[1];
    var _d = React.useState(false), showComplete = _d[0], setShowComplete = _d[1];
    var _e = React.useState(), owners = _e[0], setOwners = _e[1];
    var _f = React.useState(), members = _f[0], setMembers = _f[1];
    var _g = React.useState(), visitors = _g[0], setVisitors = _g[1];
    var _h = React.useState(0), currentOwnersPage = _h[0], setcurrentOwnersPage = _h[1];
    var _j = React.useState(""), ownersScrollDireciton = _j[0], setownersScrollDirection = _j[1];
    var _k = React.useState(0), currentMembersPage = _k[0], setcurrentMembersPage = _k[1];
    var _l = React.useState(""), membersScrollDireciton = _l[0], setmembersScrollDirection = _l[1];
    var _m = React.useState(false), showPaginationBar = _m[0], setshowPaginationBar = _m[1];
    var _o = React.useState({ Owners: false, Members: false, Visitors: false }), userExpandStatus = _o[0], setuserExpandStatus = _o[1];
    var intl = useIntl();
    var listRef = React.useRef(null);
    React.useEffect(function () {
        if (allMembers !== null) {
            setOwners(allMembers.Owners);
            setMembers(allMembers.Members);
            setVisitors(allMembers.Visitors);
        }
    }, [allMembers]);
    var listScroller = function (type) {
        if (type === "Owners") {
            if (ownersScrollDireciton === "to-the-right")
                return true;
            return false;
        }
        if (type === "Members") {
            if (membersScrollDireciton === "to-the-right")
                return true;
            return false;
        }
        return false;
    };
    var calculateShowAdditionalTitle = function (type, isExpanded) {
        var verb = isExpanded ? "hide" : "show";
        var verbPascalCase = isExpanded ? "Hide" : "Show";
        var verbTitle = intl.formatMessage({ id: "component.lifecycle_details_tab_users.".concat(verb, "_additional"), defaultMessage: "".concat(verbPascalCase, " Additional") });
        var listTypeTitle = intl.formatMessage({ id: "component.directory.view_members.".concat(type.toLowerCase()), defaultMessage: type });
        return "".concat(verbTitle, " ").concat(listTypeTitle);
    };
    var checkParentHasClass = function (element, depth) {
        //console.log("Click Element", element.classList);
        if (depth !== 0) {
            depth = depth - 1;
            if (element === undefined) {
                return null;
            }
            else if (element.classList === undefined || element.classList === null) {
                return null;
            }
            else if (element.classList.contains("admin-menu")) {
                return element;
            }
            else if (element.parentElement === null) {
                return null;
            }
            else {
                return checkParentHasClass(element.parentElement, depth);
            }
        }
        return null;
    };
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var hostClientType = teamContext === null || teamContext === void 0 ? void 0 : teamContext.app.host.clientType;
    var clickAllButton = React.useCallback(function (users, actionType, event) {
        if (actionType === "email") {
            var link = "mailto:";
            link += users.map(function (u) { var _a; return (_a = u.GraphUserObject) === null || _a === void 0 ? void 0 : _a.mail; }).join(";");
            window.location.href = link;
        }
        if (actionType === "chat") {
            var link = "https://teams.microsoft.com/l/chat/0/0?users=";
            link += users.map(function (u) { return encodeURIComponent(u.UserPrincipalName); }).join(",");
            TeamClientAppNavigation.goToTeamsLink(link, event, hostClientType);
        }
    }, [hostClientType]);
    var stripAfterAt = function (value) {
        if (value === undefined) {
            return "";
        }
        var indexAt = value.indexOf("@");
        if (indexAt > -1) {
            return value.substring(0, indexAt);
        }
        return value;
    };
    var RenderListOfUsers = function (type) {
        var users = [];
        if (type === "Owners" && owners !== undefined) {
            users = owners;
        }
        if (type === "Members" && members !== undefined) {
            users = members;
        }
        if (type === "Visitors" && visitors !== undefined) {
            users = visitors;
        }
        return (React.createElement("div", { className: "list-of-members", "data-pw": "list-of-members" },
            React.createElement("div", { className: "title" },
                React.createElement("h2", null,
                    type !== "Visitors" ? type : reportItem.GroupId !== null ? "Guests" : type,
                    React.createElement("span", { className: "count" },
                        React.createElement("span", { className: "number", "data-pw": "count" }, users.length))),
                users.length > 0 && (React.createElement(FormButtons, { style: "left-with-margin-bottom" },
                    React.createElement(FormButton, { icon: "la la-envelope", text: intl.formatMessage({ id: "component.lifecycle_details_tab_users.email_all.title", defaultMessage: "Email All" }), size: "sm", onClick: function (event) { return clickAllButton(users, "email", event); } }),
                    React.createElement(FormButton, { icon: "la la-comment", text: intl.formatMessage({ id: "component.lifecycle_details_tab_users.chat_all.title", defaultMessage: "Chat All" }), size: "sm", onClick: function (event) { return clickAllButton(users, "chat", event); } })))),
            users.length > 0 ? (React.createElement("div", null,
                React.createElement(CSSTransition, { in: userExpandStatus[type], classNames: "show", timeout: 500, onExited: function () {
                        setshowPaginationBar(false);
                        if (type === "Owners") {
                            setownersScrollDirection("to-the-left");
                            setcurrentOwnersPage(0);
                        }
                        if (type === "Members") {
                            setTimeout(function () {
                                setmembersScrollDirection("to-the-left");
                                setcurrentMembersPage(0);
                            }, 700);
                        }
                    }, onEntered: function () { return setshowPaginationBar(true); } },
                    React.createElement(CSSTransition, { in: listScroller(type), classNames: listScrollAnimationNames, timeout: 450 },
                        React.createElement("div", { className: "list ".concat(users.length > 20 ? "more-than-20" : "").concat(users.length <= 5 ? "five-or-less" : "").trim() }, users
                            .slice(type === "Owners" ? currentOwnersPage * 20 : currentMembersPage * 20, type === "Owners" ? currentOwnersPage * 20 + 20 : currentMembersPage * 20 + 20)
                            .map(function (user, rIndex) {
                            var _a, _b, _c, _d, _e;
                            return (React.createElement("div", { className: "user-container", key: rIndex, "data-pw": "user-container" },
                                React.createElement("div", { className: "profile-image", "data-pw": "profile-image" },
                                    React.createElement(OfficeProfilePicture, { uid: user.UserPrincipalName, displayName: user.DisplayName, size: "md", type: user.Type }),
                                    React.createElement("div", { className: "graph-presence" },
                                        React.createElement(TeamsUserActivity, { status: ((_a = user.GraphPresence) === null || _a === void 0 ? void 0 : _a.activity) || "PresenceUnknown" }))),
                                React.createElement("div", { className: "right-side" },
                                    React.createElement("div", { className: "info" },
                                        React.createElement("div", { className: "info-container" },
                                            React.createElement("div", { className: "display-name", title: user.DisplayName, "data-pw": "display-name" },
                                                React.createElement("div", { className: "ellipsis" }, user.DisplayName)),
                                            React.createElement("div", { className: "job-title" },
                                                React.createElement("div", { className: "ellipsis" }, user.JobTitle)),
                                            React.createElement("div", { className: "department" },
                                                React.createElement("div", { className: "ellipsis" }, user.Department)))),
                                    React.createElement("div", { className: "actions" },
                                        React.createElement("div", { className: "action", "data-pw": "email-action" },
                                            React.createElement("a", { 
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                title: ((_b = user.GraphUserObject) === null || _b === void 0 ? void 0 : _b.mail) ? "mailto:".concat(user.GraphUserObject.mail) : undefined, href: ((_c = user.GraphUserObject) === null || _c === void 0 ? void 0 : _c.mail) ? "mailto:".concat(user.GraphUserObject.mail) : undefined },
                                                React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: "fa-light fa-envelope" })))),
                                        React.createElement("div", { className: "action", "data-pw": "chat-action" },
                                            React.createElement("a", { target: "_blank", rel: "noopener noreferrer", title: "Chat with ".concat(user.DisplayName), onClick: function (event) {
                                                    var _a, _b, _c;
                                                    return ((_a = user.GraphUserObject) === null || _a === void 0 ? void 0 : _a.userPrincipalName) !== null && ((_b = user.GraphUserObject) === null || _b === void 0 ? void 0 : _b.userPrincipalName) !== undefined
                                                        ? TeamClientAppNavigation.goToTeamsLink("https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent((_c = user.GraphUserObject) === null || _c === void 0 ? void 0 : _c.userPrincipalName)), event, hostClientType)
                                                        : null;
                                                }, href: ((_d = user.GraphUserObject) === null || _d === void 0 ? void 0 : _d.userPrincipalName)
                                                    ? "https://teams.microsoft.com/l/chat/0/0?users=".concat(encodeURIComponent((_e = user.GraphUserObject) === null || _e === void 0 ? void 0 : _e.userPrincipalName))
                                                    : undefined },
                                                React.createElement("span", { className: "icon" },
                                                    React.createElement("i", { className: "fa-light fa-comment" }))))))));
                        })))),
                users.length > 20 && showPaginationBar ? (React.createElement(PaginationToolbar, { setCurrentPage: type === "Owners" ? setcurrentOwnersPage : setcurrentMembersPage, currentPage: type === "Owners" ? currentOwnersPage : currentMembersPage, setScrollDirection: type === "Owners" ? setownersScrollDirection : setmembersScrollDirection, totalResults: users.length })) : null)) : null,
            users.length > 5 ? (React.createElement("div", { className: "see-more-container ".concat(userExpandStatus[type] === true ? "open" : "") },
                React.createElement("div", { className: "see-more" },
                    React.createElement("span", { onClick: function () {
                            setuserExpandStatus(function (expandStatus) {
                                var _a;
                                return __assign(__assign({}, expandStatus), (_a = {}, _a[type] = !expandStatus[type], _a));
                            });
                        }, className: "see-more-text", "data-pw": "see-more-text" },
                        calculateShowAdditionalTitle(type, userExpandStatus[type]),
                        React.createElement("i", { style: { transform: "rotate(".concat(userExpandStatus[type] === false ? "0" : "180", "deg)") }, className: "fa-regular fa-chevron-down" })),
                    React.createElement("span", { className: "see-more-line" },
                        React.createElement("hr", null))))) : null,
            users.length === 0 ? (React.createElement("div", { className: "no-users", "data-pw": "no-users" },
                "There are no ",
                type !== "Visitors" ? type : reportItem.GroupId !== null ? "Guests" : type)) : null));
    };
    var navigateToDetails = function (reportItem) {
        if (reportItem !== null) {
            dispatch(LifecyclePolicyWorkspaceStore.UpdateReportItem(reportItem));
        }
    };
    var customHeader = function (insightContext) {
        return (React.createElement("div", { className: "custom-header" },
            React.createElement("div", { className: "title-left" }, insightContext.IsLoading === true ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "top" },
                    React.createElement("h2", null,
                        "Guests",
                        React.createElement("span", { className: "count" },
                            React.createElement("span", { className: "number", "data-pw": "count" }, insightContext.Items.length)))),
                insightContext.ItemCount === 0 ? (React.createElement("div", { className: "no-users", "data-pw": "no-guests" }, "There are no Guests")) : null)))));
    };
    var bulkApply = function (guestDetails, action, workspaceUrl, groupId, siteId) {
        if (guestDetails.length > 0 && workspaceUrl && action === "Resend") {
            UsersGuestsService.BulkResendGuestInvites({ guestDetails: guestDetails, inviteUrl: workspaceUrl }).then(function (value) {
                setBulkJob({ bulkJobId: value.jobId, jobType: "Resend" });
                setProcessing(true);
            });
        }
        if (guestDetails.length > 0 && groupId && siteId && action === "Remove") {
            UsersGuestsService.BulkRemoveGuestsFromWorkspace({ guestDetails: guestDetails, groupId: groupId, siteId: siteId }).then(function (value) {
                setBulkJob({ bulkJobId: value.jobId, jobType: "Remove" });
                setProcessing(true);
            });
        }
    };
    var customBulkActionResend = function (context, selectedIds) {
        var inviteGuestDetails = context.Items.map(function (item) {
            var _a, _b, _c, _d;
            if (((_b = (_a = item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) === null || _b === void 0 ? void 0 : _b.Mail) !== undefined) {
                return { id: item.UserId, email: (_d = (_c = item.ExtendedProperties) === null || _c === void 0 ? void 0 : _c.CosmosUserDetails) === null || _d === void 0 ? void 0 : _d.Mail };
            }
            return {};
        }).filter(function (guest) { return guest.email && guest.id !== undefined && selectedIds.includes(guest.id); });
        return (React.createElement(OrchModal, { modalClassName: "bulk-resend-invite-guest-modal", simpleButton: true, size: "sm", headerText: intl.formatMessage({
                id: "actions.bulk.resend_invite_modal.title",
                defaultMessage: "Resend Azure Guest Invitation"
            }), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa fa-paper-plane" }), "Resend Invite".concat(selectedIds.length > 1 ? "s" : ""))), footerPrimaryText: "".concat(selectedIds.length > 1 ? "Resend Invites" : "Resend Invite"), footerPrimaryFunction: function () { return bulkApply(inviteGuestDetails, "Resend", (reportItem === null || reportItem === void 0 ? void 0 : reportItem.TeamUrl) || ""); } },
            React.createElement(ResendModalMessage, { workspaceName: (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Title) || "", plural: selectedIds.length > 1 ? true : false })));
    };
    var customBulkActionRemove = function (context, selectedIds) {
        var removeGuestDetails = selectedIds.map(function (id) {
            return { id: id, email: "" };
        });
        return (React.createElement(OrchModal, { modalClassName: "bulk-remove-guest-modal", simpleButton: true, size: "sm", headerText: intl.formatMessage({
                id: "actions.bulk.remove_guest_modal.title",
                defaultMessage: "Remove Guests from Workspace"
            }), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa-regular fa-user-xmark" }), "Remove Guest".concat(selectedIds.length > 1 ? "s" : "", " from Workspace"))), footerPrimaryText: "".concat(selectedIds.length > 1 ? "Remove Guests" : "Remove Guest"), footerPrimaryFunction: function () { return bulkApply(removeGuestDetails, "Remove", undefined, reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupId, reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId); } },
            React.createElement("div", { className: "column-popout-confirmation-dialog" },
                React.createElement("div", { className: "message" },
                    React.createElement(RemoveGuestFromWorkspaceMessage, { workspaceName: (reportItem === null || reportItem === void 0 ? void 0 : reportItem.Title) || "", plural: selectedIds.length > 1 ? true : false })))));
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.jobType) === "Remove") {
                    UsersGuestsService.BulkRemoveGuestsFromWorkspaceStatus(bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId).then(function (data) {
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            erroredOut_1 = false;
                            runAgain();
                        }
                        else {
                            AsyncWait(undefined, AsyncStatus.Succeeded, null, null, "Report_Refresh").then(function (res) {
                                setProcessing(false);
                                setShowComplete(true);
                                dispatch(LifecyclePolicyWorkspaceStore.RemoveFromWorkspaceSuccess());
                            });
                            setTimeout(function () {
                                dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestInsightsSlot));
                            }, 1000);
                        }
                    });
                }
                if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.jobType) === "Resend") {
                    UsersGuestsService.BulkResendInviteStatus(bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId).then(function (data) {
                        if (data.status !== "Completed" && data.status !== "Failed") {
                            erroredOut_1 = false;
                            runAgain();
                        }
                        else {
                            setProcessing(false);
                            dispatch(LifecyclePolicyWorkspaceStore.ResendInviteSuccess());
                        }
                    });
                }
            }
            else {
                runAgain();
            }
        }
        if ((bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId) !== null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [bulkJob === null || bulkJob === void 0 ? void 0 : bulkJob.bulkJobId]);
    return (React.createElement("div", { className: "lifecycle-details-users", "data-pw": "lifecycle-details-users" },
        React.createElement(Portlet, null,
            React.createElement(PortletBody, null, allMembers !== null && allMembers.HasUsers === false ? (React.createElement("div", { className: "already-archived", "data-pw": "already-archived" },
                React.createElement("div", null,
                    React.createElement("i", { className: "la la-users" })),
                React.createElement("div", null, "This workspace has been archived and therefore we cannot retrieve a list of users."))) : reportItem !== null && allMembers !== null ? (React.createElement("div", { className: "main-container" },
                React.createElement(Row, { "data-pw": "users-tab-owners" },
                    React.createElement(Col, null, RenderListOfUsers("Owners"))),
                React.createElement(Row, { "data-pw": "users-tab-members" },
                    React.createElement(Col, null, RenderListOfUsers("Members"))),
                React.createElement(Row, { className: "insights-report", "data-pw": "insights-report" },
                    React.createElement(Col, null, orchestryContext.GuestManagementEnabled && reportItem.Application !== "VivaEngageCommunity" ? (React.createElement("div", { className: "list-of-members" },
                        React.createElement(InsightsReport, { NavigateToDetails: navigateToDetails, ActionParam: undefined, FilterParam: "workspace-guest-memberships-with-links", Datasource: ReportDatasource.SQLServer, BasePath: "guests", SubPath: "listing", NamePrefix: "", ResultName: "guest", ShowPagingOnlyIfXAmountOfItems: 50, NoResultMainText: "No guests", NoResultSubText: "There are no guests in this workspace", ShowArchived: false, ShowExport: false, ShowFiltersAndSearch: false, ShowDeleted: false, ResultsDisabled: processing, ShowBusyAnimation: processing, InitialDynamicValues: { groupId: reportItem.GroupId }, CustomBulkActions: [customBulkActionResend, customBulkActionRemove], Slot: ReportSearchSlotName.GuestInsightsSlot, CustomHeader: function (context) { return customHeader(context); } }))) : (React.createElement("div", { className: "list-of-guests-container" }, RenderListOfUsers("Visitors"))))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 33, marginTop: 15 }),
                    React.createElement("div", { className: "list", style: { marginTop: "20px", marginBottom: "0px" } },
                        React.createElement("div", { className: "user-container" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 86 })))),
                React.createElement("div", null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 33, marginTop: 15 }),
                    React.createElement("div", { className: "list", style: { marginTop: "20px", marginBottom: "0px" } },
                        React.createElement("div", { className: "user-container" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 86 })))),
                React.createElement("div", null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 33, marginTop: 15 }),
                    React.createElement("div", { className: "list", style: { marginTop: "20px", marginBottom: "0px" } },
                        React.createElement("div", { className: "user-container" },
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 86 }))))))))));
});
export default LifecycleDetailsTabUsers;
