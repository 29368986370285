import { Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-card-history-expanded-skeleton.scss";
import { RecommendationCardHistoryTimelineItemSkeleton } from "./recommendation-card-history-timeline-item-skeleton.component";
export var RecommendationCardHistoryExpandedSkeleton = function () { return (React.createElement("div", { className: "recommendation-card-history-expanded-skeleton" },
    React.createElement(Skeleton, { width: 40, height: 12.25, radius: 17.5 }),
    React.createElement("div", { className: "timeline-item-skeleton-container" },
        React.createElement(RecommendationCardHistoryTimelineItemSkeleton, null),
        React.createElement(RecommendationCardHistoryTimelineItemSkeleton, null),
        React.createElement(RecommendationCardHistoryTimelineItemSkeleton, null)))); };
