var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { useOrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/use-orchestry-annotation-section.hook";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { useURLParamDisplayMode } from "appv2/hooks/use-display-mode.hook";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useGetHealthCheckCustomRecommendations } from "./use-get-health-check-custom-recommendations.hook";
export var useHealthCheckAdditions = function () {
    var intl = useIntl();
    var _a = useHealthCheckRouteParams(), _b = _a.healthCheckGuid, healthCheckGuid = _b === void 0 ? "" : _b, tenantGuid = _a.tenantGuid;
    var displayMode = useURLParamDisplayMode().displayMode;
    var shouldShowMutativeButtons = displayMode === "edit"; //Used to show/hide the button with edit mode
    var shouldRenderButtons = displayMode !== "pdf"; //Used to hide the buttons in pdf mode so the padding looks better
    var _c = useGetHealthCheckCustomRecommendations(), pagedRecommendations = _c.pagedRecommendations, pagedRecommendationsRefetch = _c.pagedRecommendationsRefetch, pagedRecommendationsIsFetching = _c.pagedRecommendationsIsFetching, pagedRecommendationsIsLoading = _c.pagedRecommendationsIsLoading, totalPagedRecommendationsCount = _c.totalPagedRecommendationsCount;
    var _d = useOrchestryAnnotationSection(HealthCheckAnnotationCategory.General, true, undefined), annotations = _d.annotations, isAnnotationsLoading = _d.isLoading;
    var onRecommendationSuccessfullyAdded = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pagedRecommendationsRefetch()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onAddCustomRecommendationClicked = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            openOrchestryModal("addCustomRecommendationModal", { intl: intl, healthCheckGuid: healthCheckGuid, onRecommendationSuccessfullyAdded: onRecommendationSuccessfullyAdded, tenantGuid: tenantGuid }, "xl");
            return [2 /*return*/];
        });
    }); };
    var onAddAnnotationClicked = useCallback(function () {
        openOrchestryModal("addAnnotationModal", {
            healthCheckId: healthCheckGuid,
            annotationCategory: HealthCheckAnnotationCategory.General,
            associatedGUID: undefined,
            intl: intl,
            tenantGuid: tenantGuid,
            onDeleteAnnotationClicked: undefined,
            hideAnnotationList: true,
            shouldShowMutativeButtons: shouldShowMutativeButtons
        }, "lg");
    }, [healthCheckGuid, intl, tenantGuid, shouldShowMutativeButtons]);
    var isCustomRecommendationListLoading = pagedRecommendationsIsFetching || pagedRecommendationsIsLoading;
    var shouldRenderAnnotations = displayMode === "edit" || !!(annotations === null || annotations === void 0 ? void 0 : annotations.length);
    var shouldRenderRecommendations = displayMode === "edit" || !!totalPagedRecommendationsCount;
    var shouldRenderAdditionsTitle = displayMode === "pdf";
    var additionsSectionsGap = displayMode === "pdf" ? 48 : 24;
    return {
        intl: intl,
        pagedRecommendations: pagedRecommendations,
        onAddAnnotationClicked: onAddAnnotationClicked,
        onAddCustomRecommendationClicked: onAddCustomRecommendationClicked,
        isCustomRecommendationListLoading: isCustomRecommendationListLoading,
        isAnnotationsLoading: isAnnotationsLoading,
        shouldShowMutativeButtons: shouldShowMutativeButtons,
        shouldRenderButtons: shouldRenderButtons,
        shouldRenderAnnotations: shouldRenderAnnotations,
        shouldRenderRecommendations: shouldRenderRecommendations,
        shouldRenderAdditionsTitle: shouldRenderAdditionsTitle,
        additionsSectionsGap: additionsSectionsGap,
        healthCheckGuid: healthCheckGuid
    };
};
