import { Group, Skeleton, Text } from "@mantine/core";
import React from "react";
/**
 * Key value pair rendered in a card in partner tools tenant pages.
 */
export var CardKeyValue = function (_a) {
    var icon = _a.icon, keyString = _a.keyString, value = _a.value, suffix = _a.suffix, isLoading = _a.isLoading;
    var isValueString = typeof value === "string";
    if (isLoading) {
        return (React.createElement(Group, { gap: 0, mb: 10 },
            icon ? React.createElement(Skeleton, { height: 20, width: 20, radius: "md" }) : null,
            React.createElement(Skeleton, { ml: 8, height: 20, width: 420, radius: "md" })));
    }
    return (React.createElement(Group, { gap: 0 },
        React.createElement("i", { className: icon, style: { fontSize: "20px", marginRight: "8px", color: "var(--mantine-color-gray-7)" } }),
        React.createElement(Text, { c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" },
            keyString,
            ":"),
        isValueString ? (React.createElement(Text, { c: "text-secondary.0", ml: 8, size: "16px", fw: 700, lh: "24px" }, value)) : (value),
        React.createElement(Text, { c: "text-secondary.0", ml: 4, size: "14px", fw: 400, lh: "20px" }, suffix)));
};
