/**
 * Rounds and localizes a number
 *
 * @param value The number to round and localize
 * @param decimalPlaces The number of decimal places to round to
 */
export var roundAndLocalizeNumber = function (value, decimalPlaces) {
    if (decimalPlaces === void 0) { decimalPlaces = 0; }
    if (value !== undefined) {
        return Number(value.toFixed(decimalPlaces)).toLocaleString();
    }
    else {
        return value;
    }
};
