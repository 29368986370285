import * as React from "react";
import "./step-card-policy-execution.scss";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import FormSelectBox from "app/components/common/form-select-box";
import * as LifecyclePolicyCurrentStore from "app/store/lifecycle-policies/current";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import FormWebhook from "app/components/common/form-webhook";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import { debounce } from "lodash";
var StepCardPolicyExecution = React.memo(function (props) {
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    // React.useEffect(() => {
    //    if (currentPolicy.Execution_Type === null) {
    //       dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Type: "ExecuteAutomatically" }))
    //       //props.OnProceedChange(true);
    //    }
    //    //       dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Type: "ApproveExecution" }))
    // }, [currentPolicy])
    return (React.createElement("div", { className: "step-card-policy-execution policy-card", "data-pw": "step-card-policy-execution" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                        props.OnProceedChange(isValid);
                    }, UniqueIdentifier: props.StepKey },
                    React.createElement("div", { className: "middle card-border", "data-pw": "first-step-policy-execution" },
                        React.createElement("h4", null, "Policy Execution"),
                        React.createElement(Form.Group, { className: "execution-selection", "data-pw": "execution-selection", controlId: "name" },
                            React.createElement(FormSelectBox, { list: [
                                    {
                                        value: "ExecuteAutomatically",
                                        title: "No Notification",
                                        subtitle: "Policy will execute automatically without the need for any intervention. No notifications will be sent.",
                                        iconJsx: (React.createElement("div", { className: "custom-icon-execute-workspaces" },
                                            React.createElement("i", { className: "fa-regular fa-bell-slash" })))
                                    },
                                    {
                                        value: "NotificationExecution",
                                        title: "Notification",
                                        subtitle: "Policy will execute automatically without the need for any intervention. An informational notification will be sent.",
                                        iconJsx: (React.createElement("div", { className: "custom-icon-execute-workspaces" },
                                            React.createElement("i", { className: "fa-regular fa-bell-on" })))
                                    },
                                    {
                                        value: "ApproveExecution",
                                        title: "Approval",
                                        subtitle: "A Policy Approval will be required, and will allow the approver to either accept the archival or request an extension of the workspace.",
                                        iconJsx: (React.createElement("div", { className: "custom-icon-check-workspaces" },
                                            React.createElement("i", { className: "fa-regular fa-badge-check" })))
                                    }
                                ], value: currentPolicy.Execution_Type, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Type: value })); } })),
                        React.createElement(Collapse, { in: currentPolicy.Execution_Type === "ApproveExecution" || currentPolicy.Execution_Type === "NotificationExecution" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "card-info fadein-1s" },
                                    React.createElement("div", { className: "content approvers", "data-pw": "policy-stakeholders" },
                                        React.createElement(PolicyExecutionApproval, null))),
                                React.createElement(Collapse, { in: currentPolicy.Execution_Type === "ApproveExecution" },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: "card-info fadein-1s", style: { marginTop: "40px" } },
                                            React.createElement("div", { className: "content approvers", "data-pw": "workspace-renewals" },
                                                React.createElement(PolicyExecutionExtension, null))),
                                        React.createElement("div", { className: "card-info fadein-1s", style: { marginTop: "40px" } },
                                            React.createElement("div", { className: "content approvers", "data-pw": "no-action-taken" },
                                                React.createElement(PolicyExecutionIfNoActionTaken, null)))))))))))));
});
export default StepCardPolicyExecution;
var PolicyExecutionApproval = React.memo(function () {
    var _a, _b;
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var debouncedDispatch = React.useCallback(debounce(dispatch, 500), []);
    React.useEffect(function () {
        //let updates: any = {};
        // if (currentPolicy.Execution_Approvers_ApprovalType === null) {
        //    updates.Execution_Approvers_ApprovalType = "WorkspaceOwners";
        //    //dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Approvers_ApprovalType: "WorkspaceOwners" }))
        // }
        // if (currentPolicy.Execution_Approvers_Approval_NotificationText === null) {
        //    updates.Execution_Approvers_Approval_NotificationText = "This Workspace has been classified as inactive and has been marked for archival. Please review the associated Workspace and choose from one of the options below.";
        //    //dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Approvers_Approval_NotificationText: "This Workspace has been classified as inactive and has been marked for archival. Please review the associated Workspace and choose from one of the options below." }))
        // }
        // if (currentPolicy.Execution_RetryCadence_NotifyEvery === null) {
        //    updates.Execution_RetryCadence_NotifyEvery = 1;
        //    // dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_RetryCadence_NotifyEvery: 1 }))
        // }
        // if (currentPolicy.Execution_RetryCadence_NotifyEveryUnit === null) {
        //    updates.Execution_RetryCadence_NotifyEveryUnit = "Days";
        //    // dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_RetryCadence_NotifyEveryUnit: "Days" }))
        // }
        // if (currentPolicy.Execution_RetryCadence_NotifyAmountOfTimes === null) {
        //    updates.Execution_RetryCadence_NotifyAmountOfTimes = 3;
        //    // dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_RetryCadence_NotifyAmountOfTimes: 5 }))
        // }
        // if (Object.keys(updates).length > 0) {
        //    dispatch(LifecyclePolicyCurrentStore.UpdateProperties(updates));
        // }
    }, [currentPolicy]);
    var getSafeTemplateText = function () {
        if (currentPolicy.Execution_Type === "ApproveExecution" && currentPolicy.Execution_Approvers_Approval_NotificationText) {
            if (currentPolicy.Execution_Approvers_Approval_NotificationText.includes("\\r\\n")) {
                return JSON.stringify(currentPolicy.Execution_Approvers_Approval_NotificationText.split("\\r\\n").map(function (item) {
                    return {
                        type: "text",
                        children: [{ text: item.trim() }]
                    };
                }));
            }
            else if (currentPolicy.Execution_Approvers_Approval_NotificationText.includes("\r\n")) {
                return JSON.stringify(currentPolicy.Execution_Approvers_Approval_NotificationText.split("\r\n").map(function (item) {
                    return {
                        type: "text",
                        children: [{ text: item.trim() }]
                    };
                }));
            }
        }
        if (currentPolicy.Execution_Type === "NotificationExecution" && currentPolicy.Execution_Approvers_Notification_NotificationText) {
            if (currentPolicy.Execution_Approvers_Notification_NotificationText.includes("\\r\\n")) {
                return JSON.stringify(currentPolicy.Execution_Approvers_Notification_NotificationText.split("\\r\\n").map(function (item) {
                    return {
                        type: "text",
                        children: [{ text: item.trim() }]
                    };
                }));
            }
            else if (currentPolicy.Execution_Approvers_Notification_NotificationText.includes("\r\n")) {
                return JSON.stringify(currentPolicy.Execution_Approvers_Notification_NotificationText.split("\r\n").map(function (item) {
                    return {
                        type: "text",
                        children: [{ text: item.trim() }]
                    };
                }));
            }
        }
        return currentPolicy.Execution_Type === "ApproveExecution" ? currentPolicy.Execution_Approvers_Approval_NotificationText : currentPolicy.Execution_Approvers_Notification_NotificationText;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null, "Policy Stakeholders"),
        React.createElement("p", null, currentPolicy.Execution_Type === "ApproveExecution"
            ? "Policy will not execute automatically. Any users that have this policy escalated to them will be able to trigger the policy manually."
            : "Identify the stakeholders that you wish to notify and involve (if applicable) as part of this policy execution method."),
        React.createElement("div", { className: "vertical-property-value", "data-pw": "stakeholder-value" },
            React.createElement("div", { className: "label" },
                "Stakeholders ",
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement("div", null,
                React.createElement(FormDropdownSelect, { list: [
                        {
                            id: "1",
                            title: "Workspace Owners",
                            value: "WorkspaceOwners"
                        },
                        {
                            id: "2",
                            title: "Workspace Members",
                            value: "WorkspaceMembers"
                        },
                        {
                            id: "3",
                            title: "Specific Users",
                            value: "SpecificUsers"
                        }
                    ], value: currentPolicy.Execution_Approvers_ApprovalType, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Approvers_ApprovalType: value })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "Execution_Type", 
                    //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                    FieldName: "Approvers", Validators: ["dropdown-has-value"], Enabled: currentPolicy.Execution_Type === "ApproveExecution", Value: currentPolicy.Execution_Approvers_ApprovalType }))),
        React.createElement(Collapse, { in: currentPolicy.Execution_Approvers_ApprovalType === "SpecificUsers" },
            React.createElement("div", { className: "approvers-specific-users" },
                React.createElement(FormUserGroupSelect, { defaultValue: currentPolicy.Execution_Approvers_Approval_SpecificUsers === null ? undefined : currentPolicy.Execution_Approvers_Approval_SpecificUsers, allowMultiple: true, returnType: "usersAndGroups", displayAddAsButton: true, onChange: function (groups) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Approvers_Approval_SpecificUsers: groups })); } }),
                React.createElement(FormValidator, { UniqueIdentifier: "Execution_Approvers_Approval_SpecificUsers", 
                    //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                    FieldName: "Specific Approval Users", Validators: ["string-array-has-items"], Enabled: currentPolicy.Execution_Type === "ApproveExecution" && currentPolicy.Execution_Approvers_ApprovalType === "SpecificUsers", Value: currentPolicy.Execution_Approvers_Approval_SpecificUsers }))),
        React.createElement("div", { className: "sub-title" }, "Notification Text"),
        currentPolicy.Execution_Type === "ApproveExecution" ? (React.createElement(MarkdownEditor, { enabledStyles: { all: true }, layout: "text", templateText: getSafeTemplateText(), onChange: function (body) {
                debouncedDispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Approvers_Approval_NotificationText: body }));
            }, initialTextString: "This Workspace has been classified as inactive and has been marked for archival. \r\n            Please review the associated Workspace and choose from one of the options below." })) : null,
        currentPolicy.Execution_Type === "NotificationExecution" ? (React.createElement(MarkdownEditor, { enabledStyles: { all: true }, layout: "text", templateText: getSafeTemplateText(), onChange: function (body) {
                debouncedDispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_Approvers_Notification_NotificationText: body }));
            }, initialTextString: "This notification is informational only and is intended to inform you that the attached workspace has been archived." })) : null,
        React.createElement(Collapse, { in: currentPolicy.Execution_Type === "ApproveExecution" },
            React.createElement("div", null,
                React.createElement("hr", null),
                React.createElement("h6", null, "Notification Cadence"),
                React.createElement("div", { className: "vertical-property-value notification-unit" },
                    React.createElement("div", { className: "label" },
                        "Send Notification every ",
                        React.createElement("span", { className: "required-astrix" }, "*")),
                    React.createElement("div", null,
                        React.createElement(Row, null,
                            React.createElement(Col, { sm: 6 },
                                React.createElement(Form.Control, { type: "number", placeholder: "Number of..", value: currentPolicy.Execution_RetryCadence_NotifyEvery === null ? undefined : (_a = currentPolicy.Execution_RetryCadence_NotifyEvery) === null || _a === void 0 ? void 0 : _a.toString(), onChange: function (event) {
                                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                            Execution_RetryCadence_NotifyEvery: event.target.value !== "" && event.target.value !== "0" ? Number(event.target.value) : null
                                        }));
                                    } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "Notification_every_number", 
                                    //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                                    FieldName: "Send notification every", Validators: ["string-not-empty"], ShowFirst: true, Enabled: currentPolicy.Execution_Type === "ApproveExecution", Value: currentPolicy.Execution_RetryCadence_NotifyEvery })),
                            React.createElement(Col, { sm: 6 },
                                React.createElement(FormDropdownSelect, { list: [
                                        {
                                            id: "2",
                                            title: "Days",
                                            value: "Days"
                                        },
                                        {
                                            id: "3",
                                            title: "Weeks",
                                            value: "Weeks"
                                        },
                                        {
                                            id: "4",
                                            title: "Months",
                                            value: "Months"
                                        }
                                    ], value: currentPolicy.Execution_RetryCadence_NotifyEveryUnit, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ Execution_RetryCadence_NotifyEveryUnit: value })); } }),
                                React.createElement(FormValidator, { UniqueIdentifier: "Notification_every_unit", ShowFirst: true, 
                                    //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                                    FieldName: "Send notification unit", Validators: ["string-not-empty"], Enabled: currentPolicy.Execution_Type === "ApproveExecution", Value: currentPolicy.Execution_RetryCadence_NotifyEveryUnit }))))),
                React.createElement("div", { className: "vertical-property-value notification-unit", style: { paddingTop: "15px" } },
                    React.createElement("div", { className: "try-for-amount-of-times" },
                        React.createElement("span", null, "Try for"),
                        React.createElement(Form.Control, { type: "number", placeholder: "Enter number", value: currentPolicy.Execution_RetryCadence_NotifyAmountOfTimes === null ? undefined : (_b = currentPolicy.Execution_RetryCadence_NotifyAmountOfTimes) === null || _b === void 0 ? void 0 : _b.toString(), onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    Execution_RetryCadence_NotifyAmountOfTimes: event.target.value !== "" && event.target.value !== "0" ? Number(event.target.value) : null
                                }));
                            } }),
                        React.createElement("span", null,
                            "amount of times ",
                            React.createElement("span", { className: "required-astrix" }, "*"))),
                    React.createElement(FormValidator, { UniqueIdentifier: "Notification_every_amount_of_time", ShowFirst: true, Enabled: currentPolicy.Execution_Type === "ApproveExecution", 
                        //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                        FieldName: "Amount of notification retries", Validators: ["string-not-empty"], Value: currentPolicy.Execution_RetryCadence_NotifyAmountOfTimes }))))));
});
var PolicyExecutionExtension = React.memo(function () {
    var _a, _b;
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    // React.useEffect(() => {
    //    if (currentPolicy.WorkspaceExtension_ExtensionType === null) {
    //       dispatch(LifecyclePolicyCurrentStore.UpdateProperties({  }))
    //    }
    // }, [currentPolicy]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null, "Workspace Renewals"),
        React.createElement("p", null, "Workspace renewals allow you to empower your approvers to renew their Workspace manually so that they are not archived"),
        React.createElement("div", { className: "", style: { marginBottom: "30px" } },
            React.createElement(FormSelectBox, { list: [
                    {
                        value: "Inactivity",
                        title: "Inactivity (Dynamic)",
                        description: (React.createElement("div", null,
                            "The renewal will only retrigger if/when the Workspace again breaches the inactivity threshold set in this policy",
                            " ",
                            React.createElement("strong", null,
                                "(",
                                currentPolicy.InactivityDaysThreshold === null ? undefined : (_a = currentPolicy === null || currentPolicy === void 0 ? void 0 : currentPolicy.InactivityDaysThreshold) === null || _a === void 0 ? void 0 : _a.toString(),
                                " days)"),
                            ". For example, a Workspace renewed will only re-send the archival request again if it again lapses into inactivity (based on your policy threshold). This should be your default option so that your approvers are only contacted when the Workspace is inactive.")),
                        icon: "fa fa-chart-area"
                    },
                    {
                        value: "ExtensionDays",
                        title: "Extension Days (Static)",
                        description: "The renewal will retrigger once the static number of extension days are breached (see below). For example, a workspace extended for a static 90 days will re-send the archival request again after 90 days have passed, regardless of activity in the Workspace. This option is best if you want to set up a cadence to contact approvers regardless of the inactivity of the Workspace.",
                        icon: "fa fa-calendar-week"
                    }
                ], value: currentPolicy.WorkspaceExtension_ExtensionType, onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ WorkspaceExtension_ExtensionType: value })); } })),
        React.createElement("div", { className: "vertical-property-value" },
            currentPolicy.WorkspaceExtension_ExtensionType === "ExtensionDays" && (React.createElement("div", { className: "label" },
                "Days Workspace can be extended for ",
                React.createElement("span", { className: "required-astrix" }, "*"))),
            React.createElement(Collapse, { in: currentPolicy.WorkspaceExtension_ExtensionType !== null },
                React.createElement("div", null,
                    currentPolicy.WorkspaceExtension_ExtensionType === "ExtensionDays" ? (React.createElement(React.Fragment, null,
                        React.createElement(Form.Control, { type: "number", placeholder: "Number of days..", value: currentPolicy.WorkspaceExtension_NumberOfDaysExtended === null ? undefined : (_b = currentPolicy === null || currentPolicy === void 0 ? void 0 : currentPolicy.WorkspaceExtension_NumberOfDaysExtended) === null || _b === void 0 ? void 0 : _b.toString(), onChange: function (event) {
                                return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({
                                    WorkspaceExtension_NumberOfDaysExtended: event.target.value !== "" && event.target.value !== "0" ? Number(event.target.value) : null
                                }));
                            } }))) : null,
                    React.createElement(FormValidator, { UniqueIdentifier: "extension_days_number", 
                        //ErrorMessage="Select at least one user to change visitors to under Actions > Security Options"
                        FieldName: "Days Workspaces can be renewed for", Validators: ["string-not-empty"], Enabled: currentPolicy.Execution_Type === "ApproveExecution" && currentPolicy.WorkspaceExtension_ExtensionType === "ExtensionDays", Value: currentPolicy.WorkspaceExtension_NumberOfDaysExtended }))))));
});
var PolicyExecutionIfNoActionTaken = React.memo(function () {
    var _a, _b;
    var currentPolicy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var dispatch = useDispatch();
    var CheckBoxBorder = function (isChecked, context) {
        return (React.createElement("div", { className: "form-select-box style-normal2" },
            React.createElement("div", { className: "select-box-item" + (isChecked ? " selected" : "") },
                React.createElement("div", { className: "select-box-checkbox" },
                    React.createElement("i", { className: "fa fa-check" })),
                React.createElement("div", { className: "select-box-wrapper" }, context))));
    };
    return (React.createElement("div", { className: "no-action-taken" },
        React.createElement("h4", null, "If No Action Taken"),
        CheckBoxBorder(currentPolicy.NoActionTaken_EscalateTo !== null && currentPolicy.NoActionTaken_EscalateTo !== "[]" && currentPolicy.NoActionTaken_EscalateTo !== "", React.createElement("div", { className: "vertical-property-value" },
            React.createElement("div", { className: "label" }, "Escalate to these users for manual intervention"),
            React.createElement("div", { className: "" },
                React.createElement("div", { className: "user-picker" },
                    React.createElement(FormUserGroupSelect, { defaultValue: currentPolicy.NoActionTaken_EscalateTo === null ? undefined : currentPolicy.NoActionTaken_EscalateTo, allowMultiple: true, returnType: "usersAndGroups", displayAddAsButton: true, onChange: function (groups) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ NoActionTaken_EscalateTo: groups })); } }))))),
        CheckBoxBorder(currentPolicy.NoActionTaken_AfterNumber !== null && currentPolicy.NoActionTaken_AfterUnit !== null, React.createElement("div", { className: "property-row perform-archival-action" },
            React.createElement("div", { className: "after" }, "After"),
            React.createElement("div", { className: "number" },
                React.createElement(Form.Control, { type: "number", placeholder: "quantity", value: currentPolicy.NoActionTaken_AfterNumber === null ? undefined : (_a = currentPolicy === null || currentPolicy === void 0 ? void 0 : currentPolicy.NoActionTaken_AfterNumber) === null || _a === void 0 ? void 0 : _a.toString(), onChange: function (event) {
                        return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ NoActionTaken_AfterNumber: event.target.value !== "" && event.target.value !== "0" ? Number(event.target.value) : null }));
                    } })),
            React.createElement("div", { className: "units" },
                React.createElement(FormDropdownSelect, { list: [
                        {
                            id: "2",
                            title: "Days",
                            value: "Days"
                        },
                        {
                            id: "3",
                            title: "Weeks",
                            value: "Weeks"
                        },
                        {
                            id: "4",
                            title: "Months",
                            value: "Months"
                        }
                    ], value: currentPolicy.NoActionTaken_AfterUnit, placeholder: "units", onChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ NoActionTaken_AfterUnit: value })); } })),
            React.createElement("div", { className: "perform" }, "following the final notification, execute the associated Archival Actions"))),
        CheckBoxBorder(currentPolicy.NoActionTaken_WebhookUrl !== null && ((_b = currentPolicy === null || currentPolicy === void 0 ? void 0 : currentPolicy.NoActionTaken_WebhookUrl) === null || _b === void 0 ? void 0 : _b.indexOf("http")) > -1, React.createElement(React.Fragment, null,
            React.createElement("h6", null, "Call Webhook"),
            React.createElement(FormWebhook, { exampleJson: {
                    Secret: "",
                    WorkspaceName: "Example Name",
                    WorkspaceType: "Team|TeamSite|CommunicationSite",
                    SiteId: "00000000-0000-0000-0000-000000000000",
                    SiteUrl: "https://example.sharepoint.com/sites/example",
                    GroupId: "00000000-0000-0000-0000-000000000000",
                    LastActivityDate: "2000-01-01T01:01:01.0000000",
                    DaysInactive: "7",
                    ArchivalPolicyName: "Example Name",
                    ArchivalType: "LeaveContentInPlace|Delete|MoveContentToDifferentSite",
                    ArchivalUrl: "https://example.sharepoint.com/sites/archival-site-collection/example",
                    IsTeam: true,
                    TemplateName: "Template Name",
                    TemplateId: "00000000-0000-0000-0000-000000000000"
                }, webhookSecret: currentPolicy.NoActionTaken_WebhookSecret, webhookUrl: currentPolicy.NoActionTaken_WebhookUrl, OnWebhookSecretChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ NoActionTaken_WebhookSecret: value })); }, OnWebhookUrlChange: function (value) { return dispatch(LifecyclePolicyCurrentStore.UpdateProperties({ NoActionTaken_WebhookUrl: value })); } })))));
});
