import { useGetAnnotationsQuery } from "appv2/api/health-checks/health-checks.api";
import { useURLParamDisplayMode } from "appv2/hooks/use-display-mode.hook";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { openOrchestryModal } from "../orchestry-modal/orchestry-modal-definitions";
// Note: this is specific to healthchecks right now and we would have to refactor to genericize it
export var useOrchestryAnnotationSection = function (annotationCategory, renderListOfAnnotations, associatedGUID) {
    var _a;
    var intl = useIntl();
    var displayMode = useURLParamDisplayMode().displayMode;
    var shouldShowMutativeButtons = displayMode === "edit";
    var _b = useHealthCheckRouteParams(), tenantGuid = _b.tenantGuid, userType = _b.userType, healthCheckGuid = _b.healthCheckGuid;
    var _c = useGetAnnotationsQuery([
        userType,
        healthCheckGuid !== null && healthCheckGuid !== void 0 ? healthCheckGuid : "",
        annotationCategory,
        tenantGuid,
        associatedGUID
    ]), annotations = _c.data, annotationsIsFetching = _c.isFetching, annotationsIsLoading = _c.isLoading;
    var isLoading = annotationsIsFetching || annotationsIsLoading;
    var annotationCount = (_a = annotations === null || annotations === void 0 ? void 0 : annotations.length) !== null && _a !== void 0 ? _a : 0;
    var shouldRenderAddAnnotationButton = displayMode === "edit" || annotationCount > 0;
    var onDeleteAnnotationClicked = useCallback(function (uniqueId, body) {
        openOrchestryModal("deleteAnnotationModal", {
            uniqueId: uniqueId,
            body: body,
            intl: intl,
            annotationCategory: annotationCategory,
            tenantGuid: tenantGuid,
            associatedGUID: associatedGUID,
            healthCheckGuid: healthCheckGuid
        }, "md");
    }, [annotationCategory, associatedGUID, intl, tenantGuid, healthCheckGuid]);
    var onAddAnnotationClick = useCallback(function () {
        openOrchestryModal("addAnnotationModal", { healthCheckId: healthCheckGuid, annotationCategory: annotationCategory, associatedGUID: associatedGUID, intl: intl, tenantGuid: tenantGuid, onDeleteAnnotationClicked: onDeleteAnnotationClicked, shouldShowMutativeButtons: shouldShowMutativeButtons }, "lg");
    }, [healthCheckGuid, intl, annotationCategory, associatedGUID, tenantGuid, onDeleteAnnotationClicked, shouldShowMutativeButtons]);
    var onAddAnnotationClicked = shouldRenderAddAnnotationButton ? onAddAnnotationClick : undefined;
    var shouldRenderListOfAnnotations = renderListOfAnnotations && displayMode === "pdf" && annotationCount > 0;
    var id = "orchestry-annotation-section-".concat(annotationCategory);
    return {
        intl: intl,
        annotations: annotations,
        annotationCount: annotationCount,
        isLoading: isLoading,
        shouldRenderAddAnnotationButton: shouldRenderAddAnnotationButton,
        onAddAnnotationClicked: onAddAnnotationClicked,
        shouldRenderListOfAnnotations: shouldRenderListOfAnnotations,
        id: id,
        healthCheckGuid: healthCheckGuid,
        onDeleteAnnotationClicked: onDeleteAnnotationClicked,
        shouldShowMutativeButtons: shouldShowMutativeButtons
    };
};
