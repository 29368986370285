import { Grid } from "@mantine/core";
import "@mantine/core/styles.css";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import React from "react";
import { Archival } from "./archival/archival.component";
import { Guests } from "./guests/guests.component";
import { Provisioning } from "./provisioning/provisioning.component";
import { Recommendations } from "./recommendations/recommendations.component";
import { TenantMetrics } from "./tenant-metrics/tenant-metrics.component";
import { TenantUtilization } from "./tenant-utilization/tenant-utilization.component";
var DEFAULT_GRID_MIN_WIDTH = 580;
var DEFAULT_GRID_MIN_WIDTH_MOBILE = 320;
export var TenantAnalytics = function () {
    var isMobile = useIsMobile();
    var minWidth = isMobile ? DEFAULT_GRID_MIN_WIDTH_MOBILE : DEFAULT_GRID_MIN_WIDTH;
    return (React.createElement(Grid, { grow: true, columns: 2, gutter: "24px" },
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(TenantMetrics, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth, h: "100%" },
            React.createElement(TenantUtilization, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(Provisioning, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(Guests, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(Archival, null)),
        React.createElement(Grid.Col, { span: 1, miw: minWidth },
            React.createElement(Recommendations, null))));
};
