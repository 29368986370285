import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fontAwesomeHelper from "app/utils/fontAwesomeHelper";
var FontIcon = React.memo(function (_a) {
    var iconString = _a.iconString, iconDef = _a.iconDef, children = _a.children, color = _a.color;
    var theIcon = null;
    if (iconString) {
        theIcon = fontAwesomeHelper.getIconFromName(iconString);
    }
    if (iconDef) {
        theIcon = iconDef;
    }
    if (theIcon != null) {
        return React.createElement(FontAwesomeIcon, { icon: theIcon, style: color !== undefined ? { color: color } : {} });
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
export default FontIcon;
