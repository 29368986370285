/**
 * The Visibility of the Recommendation Record
 * Visibility is different from status in that if a recommendation is open| it can be in many different visibility modes.
 */
export var RecommendationRecordVisibility;
(function (RecommendationRecordVisibility) {
    /**
     * Not Applicable. Used if the record visibility is not set.
     */
    RecommendationRecordVisibility["NA"] = "NA";
    /**
     * The record is visible to the user.
     */
    RecommendationRecordVisibility["Visible"] = "Visible";
    /**
     * The record is pending.
     */
    RecommendationRecordVisibility["Pending"] = "Pending";
    /**
     * The record is snoozed.
     */
    RecommendationRecordVisibility["Snoozed"] = "Snoozed";
    /**
     * The record is resolved.
     */
    RecommendationRecordVisibility["Resolved"] = "Resolved";
})(RecommendationRecordVisibility || (RecommendationRecordVisibility = {}));
