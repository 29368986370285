import { Card, Center, Grid, Stack } from "@mantine/core";
import { OrchestryColorLabel } from "appv2/components/orchestry-color-label/orchestry-color-label.component";
import { OrchestryDonutChart } from "appv2/components/orchestry-donut-chart/orchestry-donut-chart.component";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import React from "react";
import { useTenantPage } from "../../use-tenant-page.hook";
import "./tenant-utilization.scss";
export var TenantUtilization = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Utilization, _c = _b === void 0 ? {} : _b, TotalUsedPercentage = _c.TotalUsedPercentage, IsProvisioningUtilized = _c.IsProvisioningUtilized, IsArchivalUtilized = _c.IsArchivalUtilized, IsGuestRequestUtilized = _c.IsGuestRequestUtilized, IsGuestReviewUtilized = _c.IsGuestReviewUtilized, IsRecommendationsUtilized = _c.IsRecommendationsUtilized, IsHealthChecksUtilized = _c.IsHealthChecksUtilized, isPageLoading = _a.isPageLoading, tenantHasBeaconLicense = _a.tenantHasBeaconLicense;
    var provisioningColor = IsProvisioningUtilized ? "var(--mantine-color-success-4)" : "var(--border)";
    var archivalColor = IsArchivalUtilized ? "var(--mantine-color-pink-5)" : "var(--border)";
    var guestRequestColor = IsGuestRequestUtilized ? "var(--mantine-color-orange-3)" : "var(--border)";
    var guestReviewColor = IsGuestReviewUtilized ? "var(--mantine-color-blue-5)" : "var(--border)";
    var recommendationsColor = IsRecommendationsUtilized ? "var(--mantine-color-purple-4)" : "var(--border)";
    var healthChecksColor = IsHealthChecksUtilized ? "var(--mantine-color-primary-4)" : "var(--border)";
    var isMobile = useIsMobile();
    var data = tenantHasBeaconLicense
        ? [
            {
                name: "Recommendations",
                value: 50,
                color: recommendationsColor
            },
            {
                name: "Health Checks",
                value: 50,
                color: healthChecksColor
            }
        ]
        : [
            {
                name: "Provisioning",
                value: 16.66,
                color: provisioningColor
            },
            {
                name: "Archival",
                value: 16.66,
                color: archivalColor
            },
            {
                name: "Guest Request",
                value: 16.66,
                color: guestRequestColor
            },
            {
                name: "Guest Review",
                value: 16.66,
                color: guestReviewColor
            },
            {
                name: "Recommendations",
                value: 16.66,
                color: recommendationsColor
            },
            {
                name: "Health Checks",
                value: 16.66,
                color: healthChecksColor
            }
        ];
    return (React.createElement("div", { className: "tenant-utilization" },
        React.createElement(Card, { variant: "default", p: 0, h: "100%", mih: 258, pb: isMobile ? 24 : undefined },
            React.createElement(Grid, { grow: true, columns: 2, justify: "Space-between", align: "center" },
                React.createElement(Grid.Col, { span: 1, miw: 290 },
                    React.createElement(Center, null,
                        React.createElement("div", { className: "donut-container" },
                            React.createElement(OrchestryDonutChart, { formatNumberAndText: true, data: data, chartLabel: "".concat(TotalUsedPercentage, "% Utilization"), startAngle: 180, endAngle: 0, strokeWidth: 0, withTooltip: false, size: 248, thickness: 12, isLoading: isPageLoading })))),
                React.createElement(Grid.Col, { span: 1 },
                    React.createElement(Center, { m: 0, p: 0 },
                        React.createElement(Stack, { gap: 16, maw: "fit-content", miw: 151, p: 0 },
                            !tenantHasBeaconLicense && (React.createElement(React.Fragment, null,
                                React.createElement(OrchestryColorLabel, { isLoading: isPageLoading, color: provisioningColor, label: "Provisioning" }),
                                React.createElement(OrchestryColorLabel, { isLoading: isPageLoading, color: archivalColor, label: "Archival" }),
                                React.createElement(OrchestryColorLabel, { isLoading: isPageLoading, color: guestRequestColor, label: "Guest Request" }),
                                React.createElement(OrchestryColorLabel, { isLoading: isPageLoading, color: guestReviewColor, label: "Guest Review" }))),
                            React.createElement(OrchestryColorLabel, { isLoading: isPageLoading, color: recommendationsColor, label: "Recommendations" }),
                            React.createElement(OrchestryColorLabel, { isLoading: isPageLoading, color: healthChecksColor, label: "Health Checks" }))))))));
};
