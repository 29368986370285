import { Group, Stack } from "@mantine/core";
import MICROSOFT_SVG_ICONS from "appv2/assets/icons/microsoft-svgs/microsoft-svg-icons";
import { OrchestryColorLabel } from "appv2/components/orchestry-color-label/orchestry-color-label.component";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestrySVGIcon } from "appv2/components/orchestry-svg-icon/orchestry-svg-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useGrowthDonutChartTile } from "./use-growth-donut-chart-tile";
export var GrowthDonutChartTileLegendOtherSection = function () {
    var _a = useGrowthDonutChartTile(), intl = _a.intl, isLoading = _a.isLoading, vivaEngageData = _a.vivaEngageData, oneDriveSpacesData = _a.oneDriveSpacesData;
    return (React.createElement(Stack, { gap: 4 },
        React.createElement(Group, { gap: 8 },
            React.createElement(Group, { gap: 4 },
                React.createElement(OrchestrySVGIcon, { isLoading: isLoading, iconNeutral: MICROSOFT_SVG_ICONS.OneDrive, size: "md" }),
                React.createElement(OrchestrySVGIcon, { isLoading: isLoading, iconNeutral: MICROSOFT_SVG_ICONS.VivaEngage, size: "md" })),
            React.createElement(OrchestryText, { isLoading: isLoading, fw: 500, c: "text-secondary.0", fz: "14", lh: "20px" }, intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.other_section.title", defaultMessage: "Other" }))),
        React.createElement(OrchestryDivider, { margin: "0" }),
        React.createElement(Stack, { gap: 0 },
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "var(--mantine-color-blue-5)", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.viva_engage", defaultMessage: "Viva Engage" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: vivaEngageData === null || vivaEngageData === void 0 ? void 0 : vivaEngageData.Total, deltaPercentage: vivaEngageData === null || vivaEngageData === void 0 ? void 0 : vivaEngageData.DeltaPercentage, deltaValue: vivaEngageData === null || vivaEngageData === void 0 ? void 0 : vivaEngageData.DeltaValue })),
            React.createElement(Group, { gap: 16, justify: "space-between" },
                React.createElement(OrchestryColorLabel, { isLoading: isLoading, color: "#0364B8", label: intl.formatMessage({ id: "page.health_check.growth_donut_chart_tile.onedrive_spaces", defaultMessage: "OneDrive Spaces" }) }),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, value: oneDriveSpacesData === null || oneDriveSpacesData === void 0 ? void 0 : oneDriveSpacesData.Total, deltaPercentage: oneDriveSpacesData === null || oneDriveSpacesData === void 0 ? void 0 : oneDriveSpacesData.DeltaPercentage, deltaValue: oneDriveSpacesData === null || oneDriveSpacesData === void 0 ? void 0 : oneDriveSpacesData.DeltaValue })))));
};
