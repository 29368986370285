var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import "./form-dropdown-multi-select.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-bootstrap";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
var FormDropdownMultiSelect = React.memo(function (props) {
    var scrollRef = React.useRef({});
    var dropdownDiv = React.useRef();
    var _a = React.useState(false), listOpen = _a[0], setListOpen = _a[1];
    // const [selectedItems, setSelectedItems] = React.useState<Option[]>([]);
    var _b = React.useState(""), searchTerm = _b[0], setSearchTerm = _b[1];
    React.useEffect(function () {
        document.addEventListener("click", handleClickOutside, true);
        return function () {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    // React.useEffect(()=>{
    //    if(props.values !== undefined){
    //       let tempSelectedItems = [];
    //       props.list.forEach((item:Option,index:number) => {
    //          if(props.values.some((o) => o.id === item.id)){
    //             tempSelectedItems.push(item);
    //          }
    //       });
    //       setSelectedItems(tempSelectedItems);
    //    }
    // },[props.values]);
    var handleClickOutside = function (event) {
        var element = event.target;
        if (!dropdownDiv || !dropdownDiv.current.contains(element)) {
            setListOpen(false);
        }
    };
    var toggleList = function () {
        if (props.disabled === undefined || (props.disabled !== undefined && props.disabled === false)) {
            setListOpen(!listOpen);
        }
    };
    React.useEffect(function () {
        // console.log("values changed");
    }, [props.values]);
    var toggleSelected = function (item) {
        if (item.locked === undefined || (item.locked !== undefined && item.locked === false)) {
            var tempSelectedItems = __spreadArray([], props.values, true);
            if (tempSelectedItems.some(function (o) { return o.id === item.id; })) {
                tempSelectedItems = tempSelectedItems.filter(function (o) { return o.id !== item.id; });
            }
            else {
                tempSelectedItems = __spreadArray(__spreadArray([], tempSelectedItems, true), [item], false);
            }
            props.onChange(tempSelectedItems);
        }
    };
    var shouldShowRedline = function () {
        if (props.values.length === 0) {
            if (props.showRedOutlineIfNothingSelected !== undefined) {
                if (props.showRedOutlineIfNothingSelected === true) {
                    return true;
                }
            }
        }
        return false;
    };
    return (React.createElement("div", { ref: dropdownDiv, tabIndex: 0, className: "form-dropdown-multi dd-wrapper" +
            (listOpen ? " open" : " closed") +
            (shouldShowRedline() ? " has-error" : "") +
            (props.disabled !== undefined && props.disabled === true ? " disabled" : "") },
        React.createElement("div", { className: "form-control dd-header", onClick: function () { return toggleList(); }, "data-pw": "dd-header" },
            React.createElement("span", { className: "dd-header-title selected-items" },
                props.values.map(function (o, i) { return (React.createElement("span", { key: "form-control-dd-header-" + i }, o.title)); }),
                props.values.length === 0 ? (props.placeholder !== undefined ? (React.createElement("span", { className: "placeholder-text" }, props.placeholder)) : (React.createElement("span", { className: "placeholder-text" }, "-- Please select at least one item --"))) : null),
            React.createElement("span", { className: "arrow" }, listOpen ? React.createElement(FontAwesomeIcon, { icon: faChevronUp }) : React.createElement(FontAwesomeIcon, { icon: faChevronDown }))),
        props.waitToRenderDDItemsUntilOpen === undefined ||
            (props.waitToRenderDDItemsUntilOpen !== undefined && props.waitToRenderDDItemsUntilOpen === false) ||
            (props.waitToRenderDDItemsUntilOpen !== undefined && props.waitToRenderDDItemsUntilOpen === true && listOpen === true) ? (React.createElement(Collapse, { in: listOpen, appear: true, timeout: 1000 },
            React.createElement("div", { className: "dd-list " + (props.theme !== undefined ? props.theme : "") },
                React.createElement(DynamicScrollbar, { shouldShow: props.list.length > 6, maxHeight: 300 },
                    React.createElement("ul", { className: " list-unstyled" }, props.list.map(function (item) { return (React.createElement("li", { className: "dd-list-item " +
                            (props.values.some(function (si) { return si.id === item.id; }) ? "dd-selected" : "") +
                            (item.image !== undefined || item.icon !== undefined || item.laIcon !== undefined || (item.customIcon !== undefined && item.customIcon !== null)
                                ? " has-image-or-icon"
                                : ""), key: item.id, "data-pw": "dd-list-item", onClick: function () { return toggleSelected(item); } },
                        item.locked !== undefined && item.locked === true ? (React.createElement("div", { className: "dd-checkbox locked" },
                            React.createElement("i", { className: "fa fa-lock" }))) : (React.createElement("div", { className: "dd-checkbox" })),
                        item.customIcon !== undefined && item.customIcon !== null ? React.createElement("i", { className: "custom-icon " + item.customIcon }) : null,
                        item.title,
                        item.description !== undefined && item.description !== null && item.description !== "" ? React.createElement("span", { className: "description" }, item.description) : null)); }))),
                props.list.length === 0 ? (React.createElement("ul", { className: "list-unstyled" },
                    React.createElement("li", { className: "no-results" }, "-- No Results -- "))) : (React.createElement(React.Fragment, null))))) : null));
});
export default FormDropdownMultiSelect;
