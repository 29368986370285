import { Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-card-history-timeline-item-skeleton.scss";
export var RecommendationCardHistoryTimelineItemSkeleton = function () { return (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "recommendation-card-history-timeline-item-skeleton" },
        React.createElement(Skeleton, { circle: true, height: 32 }),
        React.createElement(Skeleton, { width: 71, height: 12, ml: 16, radius: 17.5 }),
        React.createElement(Skeleton, { width: 240, height: 12, ml: 10, radius: 17.5 })),
    React.createElement("div", { className: "recommendation-card-history-timeline-item-skeleton" },
        React.createElement(Skeleton, { circle: true, height: 32 }),
        React.createElement(Skeleton, { width: 71, height: 12, ml: 16, radius: 17.5 }),
        React.createElement(Skeleton, { width: 240, height: 12, ml: 10, radius: 17.5 })))); };
