var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import AnimatedLoader from "app/components/common/animated-loader";
import FormToggleControl from "app/components/common/form-toggle-control";
import PortletAlert from "app/components/common/portlet-alert";
import * as TemplateCurrentStore from "app/store/templates/current";
import generateGuid from "app/utils/generateGuid";
import axios from "axios";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import * as React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./tab-webhooks.scss";
require("codemirror/mode/javascript/javascript");
var TabWebhook = React.memo(function (props, state) {
    var dispatch = useDispatch();
    var _a = React.useState("none"), testStatus = _a[0], setTestStatus = _a[1];
    var _b = React.useState(), testResponse = _b[0], settestResponse = _b[1];
    var _c = React.useState(), exampleJson = _c[0], setExampleJson = _c[1];
    var _d = React.useState(false), clientSecretCopied = _d[0], setClientSecretCopied = _d[1];
    var _e = React.useState(false), webhookExampleCopied = _e[0], setWebhookCopiedCopied = _e[1];
    React.useEffect(function () {
        var exampleJsonNew = {};
        if (props.currentTemplate.WebhookSecret !== null && props.currentTemplate.WebhookSecret !== "") {
            exampleJsonNew["Secret"] = props.currentTemplate.WebhookSecret;
        }
        exampleJsonNew = __assign(__assign({}, exampleJsonNew), {
            GroupId: "6af058ac-0139-4a19-9270-7ee0d12d5c8e",
            TeamId: "6af058ac-0139-4a19-9270-7ee0d12d5c8e",
            IsTeam: true,
            Url: "https://example.sharepoint.com/sites/example_team",
            Type: "Team",
            Permission: "Public",
            Name: "Example Team",
            Description: "Example Team requested by Sarah Wilson on DATE 12:59 PM",
            TemplateId: "94f555a2-87ad-44bd-9f90-04e3771388fb",
            TemplateName: "Team",
            TemplateType: "Team",
            TemplateSharePointType: "ModernGroup",
            RequestCustomMetadata: [
                {
                    FieldGUID: "0081b232-eeb0-4a6b-4fbe-5e694d58a420",
                    FieldTitle: "Text Multiline",
                    FieldType: "Text",
                    Values: "asdfsadf"
                }
            ],
            RequestUserPrincipalName: "admin@orchestrydev.onmicrosoft.com",
            RequestUserGraphId: "2a1f2bdd-bebd-4814-b458-3e51f25572ea",
            Owners: [
                {
                    JobTitle: "CTO",
                    Department: "Finance",
                    Company: "Orchestry",
                    Office: "Toronto Office",
                    StateOrProvince: "",
                    CountryOrRegion: "Canada",
                    GivenName: "Sarah Wilson",
                    Surname: "",
                    Locked: "True",
                    DisplayName: "Sarah Wilson",
                    UserPrincipalName: "admin@examplesite.onmicrosoft.com",
                    UserId: "2a1f2bdd-bebd-4814-b458-3e51f25572ea",
                    GUID: "2a1f2bdd-bebd-4814-b458-3e51f25572ea",
                    Type: "User",
                    Visibility: "",
                    GraphUserObject: null,
                    GraphGroupObject: null,
                    GraphPresence: null,
                    Id: "843e67d9-5c67-4f22-9d05-9c67e13bd77d",
                    PrincipalId: "843e67d9-5c67-4f22-9d05-9c67e13bd77d"
                }
            ],
            Members: [
                {
                    JobTitle: "Marketing Assistant",
                    Department: "",
                    Company: "Orchestry",
                    Office: "",
                    StateOrProvince: "",
                    CountryOrRegion: "",
                    GivenName: "Alex",
                    Surname: "Wilber",
                    Locked: "False",
                    DisplayName: "Alex Wilber",
                    UserPrincipalName: "AlexW@examplesite.onmicrosoft.com",
                    UserId: "aa1ff3b9-4191-4516-bada-4bafcdd47b3c",
                    GUID: "aa1ff3b9-4191-4516-bada-4bafcdd47b3c",
                    Type: "User",
                    Visibility: "",
                    GraphUserObject: null,
                    GraphGroupObject: null,
                    GraphPresence: null,
                    Id: "843e67d9-5c67-4f22-9d05-9c67e13bd77d",
                    PrincipalId: "843e67d9-5c67-4f22-9d05-9c67e13bd77d"
                }
            ],
            Channels: [
                {
                    Id: "19:7dc9fd97e59e431ab10d1a7e9569ff17@thread.tacv2",
                    DisplayName: "General",
                    Description: "Description Test",
                    Tabs: [
                        {
                            Id: "15e69c1b-df79-44bf-b4e1-e05295023d3e",
                            DisplayName: "Wiki",
                            WebUrl: "https://teams.microsoft.com/l/channel/example-url"
                        }
                    ]
                }
            ],
            Plans: [
                {
                    Id: "hR5__MGrjEKymCwrXLNsOn0AGAnp",
                    Title: "Example Plan"
                }
            ]
        });
        setExampleJson(exampleJsonNew);
        // if(props.currentTemplate.WebhookSecret !== null && props.currentTemplate.WebhookSecret !== "") {
        //    setUseClientSecret(true);
        // }
    }, [props.currentTemplate.WebhookSecret]);
    function syntaxHighlight(json) {
        if (typeof json !== "string") {
            json = JSON.stringify(json, undefined, 2);
        }
        json = json
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
        json = json.replace(/(?:\r\n|\r|\n)/g, "<br>");
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = "number";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "key";
                }
                else {
                    cls = "string";
                }
            }
            else if (/true|false/.test(match)) {
                cls = "boolean";
            }
            else if (/null/.test(match)) {
                cls = "null";
            }
            return '<span class="' + cls + '">' + match + "</span>";
        });
    }
    function copyStringToClipboard(str) {
        // Create new element
        var el = document.createElement("textarea");
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute("readonly", "");
        //@ts-ignore
        el.style = { position: "absolute", left: "-9999px" };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand("copy");
        // Remove temporary element
        document.body.removeChild(el);
    }
    var copyExampleToClipboard = function () {
        copyStringToClipboard(JSON.stringify(exampleJson, undefined, 2));
        setWebhookCopiedCopied(true);
        setTimeout(function () {
            setWebhookCopiedCopied(false);
        }, 3000);
    };
    var copySecretToClipboard = function () {
        copyStringToClipboard(props.currentTemplate.WebhookSecret !== undefined ? props.currentTemplate.WebhookSecret : "");
        setClientSecretCopied(true);
        setTimeout(function () {
            setClientSecretCopied(false);
        }, 3000);
    };
    var pingWebhookUrl = function () {
        //props.currentTemplate.WebhookUrl
        setTestStatus("waiting");
        settestResponse(undefined);
        var axiosConfig = {};
        axiosConfig.method = "POST";
        axiosConfig.url = "/api/templates/TestWebhookUrl";
        axiosConfig.responseType = "json";
        var bodyFormData = new FormData();
        bodyFormData.set("data", JSON.stringify(exampleJson));
        bodyFormData.set("webhookUrl", props.currentTemplate.WebhookUrl);
        axiosConfig.data = bodyFormData;
        axios(axiosConfig)
            .then(function (response) {
            var responseData = response.data;
            if (responseData.urlWorked) {
                setTestStatus("success");
                settestResponse(responseData);
            }
            else {
                setTestStatus("failed");
                settestResponse(responseData);
            }
        })
            .catch(function (response) {
            setTestStatus("failed");
            var responseData = response.data;
            settestResponse(responseData);
        });
    };
    var toggleClientSecret = function (checked) {
        if (checked) {
            dispatch(TemplateCurrentStore.Update({ WebhookSecret: generateGuid() }));
        }
        else {
            dispatch(TemplateCurrentStore.Update({ WebhookSecret: "" }));
        }
    };
    return (React.createElement("div", { className: "webhook-tab", "data-pw": "integrations-tab-content" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("div", { className: "webhook-area", "data-pw": "webhook-area" },
                    React.createElement("h3", null, "Webhook Url"),
                    React.createElement("div", { className: "url-input" },
                        React.createElement(Form.Control, { autoComplete: "off", defaultValue: props.currentTemplate.WebhookUrl, onKeyUp: function () { return setTestStatus("none"); }, onChange: function (event) { return dispatch(TemplateCurrentStore.Update({ WebhookUrl: event.target.value })); } }),
                        React.createElement("button", { type: "button", onClick: function () { return pingWebhookUrl(); } },
                            testStatus === "none" ? (React.createElement("i", { className: "la la-flask" })) : testStatus === "waiting" ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : testStatus === "success" ? (React.createElement("i", { className: "la la-check" })) : (React.createElement("i", { className: "la la-times" })),
                            testStatus === "none" ? React.createElement("span", null, "Test Url") : testStatus === "waiting" ? React.createElement("span", null, "Test Url") : testStatus === "success" ? React.createElement("span", null, "Success") : React.createElement("span", null, "Failed"))),
                    testStatus !== "none" && (testResponse === null || testResponse === void 0 ? void 0 : testResponse.urlWorked) === false ? (React.createElement("div", { className: "test-response" },
                        React.createElement(PortletAlert, { title: "Test Result:", type: "danger", message: React.createElement("div", null,
                                React.createElement("p", null, testResponse === null || testResponse === void 0 ? void 0 : testResponse.statusCode),
                                React.createElement("p", null, testResponse === null || testResponse === void 0 ? void 0 : testResponse.code),
                                React.createElement("p", null, testResponse === null || testResponse === void 0 ? void 0 : testResponse.message)) }))) : null,
                    React.createElement("p", null, "After a team or site has been successfully provisioned we will send a POST request to the Webhook URL provided above. The request will have a 30 second timeout.")),
                React.createElement("div", { className: "client-secret-area", "data-pw": "client-secret-area" },
                    React.createElement("h3", null, "Client Secret"),
                    React.createElement("div", null,
                        React.createElement(FormToggleControl, { checked: props.currentTemplate.WebhookSecret !== null && props.currentTemplate.WebhookSecret !== "" ? true : false, label: "Send a client secret with webhook request.", handleChange: function (checked) { return toggleClientSecret(checked); } })),
                    React.createElement(Collapse, { in: props.currentTemplate.WebhookSecret !== null && props.currentTemplate.WebhookSecret !== "", appear: true },
                        React.createElement("div", { className: "client-secret-enabled" },
                            React.createElement("p", null, "The client secret will be added to the JSON body and as a header named \"secret\""),
                            React.createElement("button", { type: "button", onClick: copySecretToClipboard, className: "clipboard-button" }, clientSecretCopied === false ? (React.createElement(React.Fragment, null,
                                React.createElement("i", { className: "far fa-clipboard" }),
                                " Copy to clipboard")) : (React.createElement(React.Fragment, null,
                                React.createElement("i", { className: "fa fa-check" }),
                                " Copied!"))),
                            React.createElement(Form.Control, { autoComplete: "off", disabled: true, value: props.currentTemplate.WebhookSecret })))))),
        React.createElement("div", { style: { marginTop: "30px" }, "data-pw": "webhook-request-example" },
            React.createElement("h3", { className: "" }, "Webhook Request Example"),
            exampleJson !== undefined && exampleJson !== null ? (React.createElement(React.Fragment, null,
                React.createElement("button", { type: "button", onClick: copyExampleToClipboard, className: "clipboard-button" }, webhookExampleCopied === false ? (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "far fa-clipboard" }),
                    " Copy to clipboard")) : (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fa fa-check" }),
                    " Copied!"))),
                React.createElement("pre", { className: "webhook-example" },
                    React.createElement("code", { dangerouslySetInnerHTML: { __html: syntaxHighlight(exampleJson) } })))) : null)));
});
export default TabWebhook;
