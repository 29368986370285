var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import GuestService from "app/services/guests";
import UsersGuestsService from "app/services/users-guests";
import * as UserCurrentStore from "app/store/users/current";
import highlightWords from "app/utils/highlightWords";
import * as React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import AnimatedLoader from "../animated-loader";
import DisableArea from "../disable-area";
import FormValidationContext, { FormValidator } from "../form-validation";
import InfoTooltip from "../infotooltip";
import OrchModal from "../orch-modal";
import PortletAlert from "../portlet-alert";
import PrincipalItem from "../principal-item";
import { SkeletonLoaderFakeBox } from "../skeleton-loaders";
import { SubtitleButton } from "../subtitle";
import "./principal-guest-picker-modal.scss";
//@ts-ignore
document.lastJustification = "";
var PrincipalGuestsPickerModal = React.memo(function (props) {
    var dispatch = useDispatch();
    var intl = useIntl();
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var inputEl = React.useRef();
    var _b = React.useState([]), searchResults = _b[0], setSearchResults = _b[1];
    var _c = React.useState([]), suggestedUsers = _c[0], setSuggestedUsers = _c[1];
    var _d = React.useState(false), modalOpen = _d[0], setModalOpen = _d[1];
    var _e = React.useState(undefined), previousForceOpen = _e[0], setPreviousForceOpen = _e[1];
    var _f = React.useState("search"), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = React.useState(null), editingGuest = _g[0], setEditingGuest = _g[1];
    var _h = React.useState(false), showUserUpdatingMessage = _h[0], setShowUserUpdatingMessage = _h[1];
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var _j = React.useState([]), blockedDomains = _j[0], setBlockedDomains = _j[1];
    var _k = React.useState(null), searchInputText = _k[0], setSearchInputText = _k[1];
    var _l = React.useState(null), errorMessage = _l[0], setErrorMessage = _l[1];
    var _m = React.useState(false), queueVisible = _m[0], setqueueVisible = _m[1];
    var _o = React.useState([]), newGuestQueue = _o[0], setnewGuestQueue = _o[1];
    var _p = React.useState(false), newGuestCloseConfirmation = _p[0], setnewGuestCloseConfirmation = _p[1];
    var _q = React.useState(false), showGuestAddCloseConfirmation = _q[0], setshowGuestAddCloseConfirmation = _q[1];
    React.useEffect(function () {
        if (currentUser === undefined || (currentUser !== undefined && currentUser.Id === undefined)) {
            dispatch(UserCurrentStore.Load());
        }
    }, []);
    React.useEffect(function () {
        GuestService.GetBlockedDomains().then(function (domains) {
            setBlockedDomains(domains);
        });
    }, []);
    React.useEffect(function () {
        if (props.forceOpen !== null && props.forceOpen !== undefined) {
            if (previousForceOpen === undefined && props.forceOpen === true) {
                setModalOpen(true);
            }
            if (previousForceOpen === false && props.forceOpen === true) {
                setModalOpen(true);
            }
        }
        setPreviousForceOpen(props.forceOpen);
    }, [props.forceOpen]);
    var _r = React.useState(-1), cursor = _r[0], setCursor = _r[1];
    // // On first run, get the suggested users
    React.useEffect(function () {
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var searchBoxOnChange = function (searchTerm) {
        setSearchInputText(searchTerm);
        setIsLoading(true);
        getResults(searchTerm);
    };
    // Get the search results.
    var getResults = function (searchValue) {
        setTimeout(function () {
            var _a;
            //console.log("timeout", searchValue, inputEl.current.value, searchValue === inputEl.current.value)
            if (searchValue === ((_a = inputEl === null || inputEl === void 0 ? void 0 : inputEl.current) === null || _a === void 0 ? void 0 : _a.value)) {
                //
                if (searchValue.length === 0) {
                    setIsLoading(false);
                    setSearchResults([]);
                    UsersGuestsService.searchGraphGuests(searchValue, 50).then(function (resultData) { });
                }
                else {
                    UsersGuestsService.searchGraphGuests(searchValue, 50).then(function (resultData) {
                        //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                        try {
                            if (searchValue === inputEl.current.value) {
                                setIsLoading(false);
                                setSearchResults(resultData);
                            }
                        }
                        catch (_a) { }
                    });
                }
            }
        }, 1000);
    };
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        }
        else if (e.keyCode === 40 && cursor < currentResults.length - 1) {
            setCursor(cursor + 1);
        }
        else if (e.keyCode === 13) {
            // console.log("Enter Clicked", currentResults[cursor]);
            if (cursor !== -1) {
                toggleUser(currentResults[cursor]);
            }
        }
    };
    var shouldShowNoResult = function () {
        var shouldShow = false;
        if (searchResults !== undefined) {
            if (searchResults.length === 0) {
                if (inputEl !== undefined) {
                    if (inputEl.current !== undefined) {
                        if (inputEl.current !== null) {
                            if (inputEl.current.value !== undefined) {
                                if (inputEl.current.value !== null) {
                                    if (inputEl.current.value.length > 0) {
                                        if (isLoading === false) {
                                            shouldShow = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return shouldShow;
    };
    var shouldUseScrollbar = function () {
        var currentResults = searchResults.length === 0 ? suggestedUsers : searchResults;
        if (currentResults.length > 6) {
            return true;
        }
        return false;
    };
    var toggleUser = function (user) {
        if (doesUserHaveBlockedDomain(user) === false && isPrincipalLocked(user) === false) {
            if (isPrincipalSelected(user)) {
                props.onGuestRemoved(user);
                setnewGuestQueue(function (newGuestQueue) { return newGuestQueue.filter(function (queuedGuest) { return queuedGuest.id !== user.id; }); });
            }
            else {
                var propertiesToCheck = ["givenName", "surname", "companyName", "jobTitle", "city", "department", "state", "country", "mobilePhone"];
                var propertiesNeededToBeAdded_1 = [];
                var showField_1 = function (fieldName) {
                    var policyConfig = props.guestRequestPolicy.GuestDetailOptions;
                    if (policyConfig !== undefined && policyConfig !== null && Array.isArray(policyConfig)) {
                        if (policyConfig.some(function (pc) { return pc.id === fieldName && (pc.isChecked === true || pc.isDefault === true); })) {
                            return true;
                        }
                    }
                    return false;
                };
                propertiesToCheck.forEach(function (property) {
                    if (showField_1(property) === true && (user[property] === null || user[property] === undefined || user[property] === "")) {
                        propertiesNeededToBeAdded_1.push(property);
                    }
                });
                // if (props.guestRequestPolicy.RequireGuestJustification === true) {
                //    propertiesNeededToBeAdded.push("justification");
                // }
                if (propertiesNeededToBeAdded_1.length > 0 || props.guestRequestPolicy.RequireGuestJustification === true) {
                    //console.log("Existing user editing", propertiesNeededToBeAdded);
                    openEditExistingPage(user, propertiesNeededToBeAdded_1);
                }
                else {
                    props.onGuestSelected(__assign(__assign({}, user), { orchestryProperties: { isExistingUser: true, existingUserFieldsUpdated: [], justification: "" } }));
                    setnewGuestQueue(function (guestQueue) { return __spreadArray(__spreadArray([], guestQueue, true), [user], false); });
                }
            }
            setCursor(-1);
        }
    };
    var isPrincipalSelected = function (user) {
        var isSelected = false;
        if (props.selectedGuests !== undefined) {
            if (Array.isArray(props.selectedGuests)) {
                if (props.selectedGuests.length > 0) {
                    props.selectedGuests.forEach(function (sp) {
                        if (sp !== undefined && sp.id !== undefined) {
                            if (sp.id === user.id) {
                                isSelected = true;
                            }
                        }
                    });
                }
            }
        }
        return isSelected;
    };
    var openAddNewPage = function () {
        setEditingGuest({ givenName: "", surname: "", orchestryProperties: { isExistingUser: false, justification: "" } });
        setCurrentPage("add-new");
    };
    var openEditExistingPage = function (user, propertiesToCheck) {
        var justification = user.orchestryProperties != null && user.orchestryProperties.justification !== undefined && user.orchestryProperties.justification !== null ? user.orchestryProperties.justification : "";
        setEditingGuest(__assign(__assign({}, user), { orchestryProperties: { isExistingUser: true, existingUserFieldsUpdated: propertiesToCheck, justification: justification } }));
        setCurrentPage("edit-existing");
    };
    var cancelToSearchPage = function () {
        setCurrentPage("search");
    };
    var getPolicyNamingDisplayName = function (guestUser) {
        var returnString = "";
        if (props.guestRequestPolicy.GuestNameConfiguration !== undefined && props.guestRequestPolicy.GuestNameConfiguration !== null) {
            props.guestRequestPolicy.GuestNameConfiguration.forEach(function (nc) {
                if (nc.type === "guest-detail" && nc.value !== undefined && nc.value !== null) {
                    if (guestUser[nc.value] !== undefined && guestUser[nc.value] !== null) {
                        var toAdd = guestUser[nc.value];
                        toAdd = (toAdd || []).includes(";new") ? toAdd.split(";")[0] : toAdd;
                        returnString += toAdd;
                    }
                }
                if (nc.type === "string" && nc.value !== undefined && nc.value !== null) {
                    returnString += nc.value;
                }
            });
        }
        return returnString;
    };
    var getErrorMessage = function (error) {
        if (error === "DomainNotAllowed") {
            return "There was a problem creating this user because the domain is not allowed.";
        }
        if (error === "UpdatingUserProperties") {
            return "There was problem when updating this user";
        }
        return "There was a error when creating and/or updating this user";
    };
    var errorModalToggle = function (shouldShow) {
        if (shouldShow === false && errorMessage !== null) {
            errorMessage.OnErrorClose();
            setErrorMessage(null);
        }
    };
    var doneNewUserSaveAndRedirectToSearchPage = function () {
        if (editingGuest !== null) {
            if (editingGuest !== null && editingGuest.orchestryProperties !== null && editingGuest.orchestryProperties.justification !== null) {
                //@ts-ignore
                document.lastJustification = editingGuest.orchestryProperties.justification;
            }
            var displayName = editingGuest.givenName + " " + editingGuest.surname;
            var base64ProfilePic = null;
            if (props.guestRequestPolicy.GuestNamingConventionEnabled === true) {
                displayName = getPolicyNamingDisplayName(editingGuest);
            }
            if (props.guestRequestPolicy.GuestDefaultProfileImage !== undefined && props.guestRequestPolicy.GuestDefaultProfileImage !== null) {
                base64ProfilePic = props.guestRequestPolicy.GuestDefaultProfileImage;
            }
            var cleanCompany = editingGuest.companyName;
            if (cleanCompany !== undefined && (cleanCompany || []).includes(";new")) {
                cleanCompany = editingGuest.companyName.split(";")[0];
                UsersGuestsService.addCompany(cleanCompany).catch(function (e) {
                    console.error(e);
                });
            }
            var updatedEditingGuest_1 = __assign(__assign({}, editingGuest), { displayName: displayName, companyName: cleanCompany });
            setShowUserUpdatingMessage(true);
            // We want to add a guest upon successful graph calls. So don't add here.
            //props.onGuestSelected({ ...updatedEditingGuest });
            // Add guest creation code here => Ajax request.
            UsersGuestsService.addNewGuest({ User: updatedEditingGuest_1, ExistingUserFieldsUpdated: null, Base64ProfilePic: base64ProfilePic }, props.editingUserId).then(function (guestResponse) {
                if (guestResponse !== null && guestResponse.Error === null && guestResponse.GuestUser !== null) {
                    props.onGuestSelected(__assign(__assign({}, updatedEditingGuest_1), { id: guestResponse.GuestUser.id }));
                    setnewGuestQueue(function (guestQueue) { return __spreadArray(__spreadArray([], guestQueue, true), [__assign(__assign({}, updatedEditingGuest_1), { id: guestResponse.GuestUser.id })], false); });
                    setSearchInputText(null);
                    setSearchResults(__spreadArray([], searchResults.map(function (r) { return (r.id === updatedEditingGuest_1.id ? updatedEditingGuest_1 : r); }), true));
                    setEditingGuest(null);
                    setCurrentPage("search");
                    setShowUserUpdatingMessage(false);
                }
                else {
                    if (guestResponse.Error !== null) {
                        setErrorMessage({
                            ErrorMessage: getErrorMessage(guestResponse.Error),
                            OnErrorClose: function () {
                                setSearchInputText(null);
                                setEditingGuest(null);
                                setCurrentPage("search");
                                setShowUserUpdatingMessage(false);
                            }
                        });
                    }
                }
            });
        }
    };
    var doneEditExistingUserSaveAndRedirectToSearchPage = function () {
        if (editingGuest !== null) {
            if (editingGuest !== null && editingGuest.orchestryProperties !== null && editingGuest.orchestryProperties.justification !== null) {
                //@ts-ignore
                document.lastJustification = editingGuest.orchestryProperties.justification;
            }
            var updatedEditingGuest_2 = __assign(__assign({}, editingGuest), { displayName: editingGuest.givenName + " " + editingGuest.surname });
            // We want to add a guest upon successful graph calls. So don't add here.
            //props.onGuestSelected({ ...updatedEditingGuest });
            //setSearchResults([...searchResults.map(r => (r.id === updatedEditingGuest.id ? updatedEditingGuest : r))]);
            // Only update the guest properties IF the there are properties to update.
            if (updatedEditingGuest_2 !== null &&
                updatedEditingGuest_2.orchestryProperties !== undefined &&
                updatedEditingGuest_2.orchestryProperties !== null &&
                Array.isArray(updatedEditingGuest_2.orchestryProperties.existingUserFieldsUpdated) &&
                updatedEditingGuest_2.orchestryProperties.existingUserFieldsUpdated.length > 0) {
                setShowUserUpdatingMessage(true);
                UsersGuestsService.updateGuest({ User: updatedEditingGuest_2, ExistingUserFieldsUpdated: updatedEditingGuest_2.orchestryProperties.existingUserFieldsUpdated, Base64ProfilePic: null }).then(function (guestResponse) {
                    if (guestResponse !== null && guestResponse.Error === null) {
                        setSearchResults(__spreadArray([], searchResults.map(function (r) { return (r.id === updatedEditingGuest_2.id ? updatedEditingGuest_2 : r); }), true));
                        props.onGuestSelected(__assign({}, updatedEditingGuest_2));
                        setnewGuestQueue(function (guestQueue) { return __spreadArray(__spreadArray([], guestQueue, true), [__assign(__assign({}, updatedEditingGuest_2), { id: guestResponse.GuestUser.id })], false); });
                        setEditingGuest(null);
                        setCurrentPage("search");
                        setShowUserUpdatingMessage(false);
                    }
                    else {
                        if (guestResponse.Error !== null) {
                            setErrorMessage({
                                ErrorMessage: getErrorMessage(guestResponse.Error),
                                OnErrorClose: function () {
                                    setSearchInputText(null);
                                    setEditingGuest(null);
                                    setCurrentPage("search");
                                    setShowUserUpdatingMessage(false);
                                }
                            });
                        }
                    }
                });
            }
            else {
                setnewGuestQueue(function (guestQueue) { return __spreadArray(__spreadArray([], guestQueue, true), [__assign(__assign({}, updatedEditingGuest_2), { id: updatedEditingGuest_2.id })], false); });
                setEditingGuest(null);
                setSearchResults(__spreadArray([], searchResults.map(function (r) { return (r.id === updatedEditingGuest_2.id ? updatedEditingGuest_2 : r); }), true));
                props.onGuestSelected(__assign({}, updatedEditingGuest_2));
                setCurrentPage("search");
            }
        }
    };
    var updateEditingUser = function (updates) {
        setEditingGuest(__assign(__assign({}, editingGuest), updates));
    };
    var searchForUser = function (searchTerm) {
        setSearchResults([]);
        setCurrentPage("search");
        setTimeout(function () {
            inputEl.current.value = searchTerm;
            searchBoxOnChange(searchTerm);
        }, 100);
        //getResults(searchTerm);
    };
    var doesUserHaveBlockedDomain = function (user) {
        if (user.mail !== undefined && user.mail !== null) {
            if (user.mail.indexOf("@") > -1) {
                var domain_1 = user.mail.split("@")[1];
                if (domain_1 !== null && domain_1 !== "") {
                    if (blockedDomains.some(function (bd) { return bd === domain_1.toLowerCase(); })) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    var isPrincipalLocked = function (user) {
        var isLocked = false;
        if (props.selectedGuests !== undefined) {
            if (Array.isArray(props.selectedGuests)) {
                if (props.selectedGuests.length > 0) {
                    props.selectedGuests.forEach(function (sp) {
                        if (sp !== undefined && sp.id !== undefined) {
                            if (sp.id === user.id) {
                                if (sp.orchestryProperties !== undefined) {
                                    if (sp.orchestryProperties.isLocked !== undefined) {
                                        if (sp.orchestryProperties.isLocked === true) {
                                            isLocked = true;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }
        //console.log("is user locked", user.id, user);
        return isLocked;
    };
    React.useEffect(function () {
        if (newGuestCloseConfirmation) {
            handlePickerClose();
        }
    }, [newGuestCloseConfirmation]);
    var handlePickerClose = function () {
        if (props.onClosed)
            props.onClosed();
        if (newGuestQueue.length > 0 && newGuestCloseConfirmation === false) {
            setshowGuestAddCloseConfirmation(true);
        }
        else if (newGuestQueue.length > 0 && newGuestCloseConfirmation === true) {
            newGuestQueue.forEach(function (newGuest) { return props.onGuestRemoved(newGuest); });
            if (props.onPickerClosed)
                props.onPickerClosed();
            setModalOpen(false);
            setshowGuestAddCloseConfirmation(false);
            setnewGuestQueue([]);
            setnewGuestCloseConfirmation(false);
            setSearchInputText(null);
            setSearchResults([]);
        }
        else {
            setModalOpen(false);
            setSearchInputText(null);
            setSearchResults([]);
        }
    };
    var SearchPage = (React.createElement("div", null,
        React.createElement("div", { className: "people-content" },
            React.createElement("div", { className: "people-header" },
                React.createElement("span", null, intl.formatMessage({ id: "page.request_wizard.step_permissions.add_guests", defaultMessage: "Add Guests" })),
                React.createElement("div", { className: "close", onClick: function () {
                        handlePickerClose();
                    } },
                    React.createElement("i", { className: "la la-remove" }))),
            React.createElement("div", { className: "people-search" },
                React.createElement("div", { className: "search-box" },
                    React.createElement("input", { type: "text", placeholder: intl.formatMessage({ id: "component.principal_search_modal.search_names_or_roles_guests", defaultMessage: "Search guest names" }), onChange: function (event) {
                            searchBoxOnChange(event.target.value);
                        }, onKeyDown: function (event) {
                            handleKeyDown(event);
                        }, ref: inputEl }),
                    React.createElement("div", { className: "icon" },
                        React.createElement("i", { className: "flaticon-search" })))),
            React.createElement("div", { className: "people-list" },
                React.createElement("ul", { className: "list-unstyled" },
                    React.createElement(DynamicScrollbar, { shouldShow: shouldUseScrollbar(), maxHeight: 200 },
                        isLoading === false &&
                            searchResults !== undefined &&
                            searchResults.map(function (rs, index) { return (React.createElement("li", { className: (cursor === index ? " hover-selected" : "") +
                                    (isPrincipalSelected(rs) ? " selected" : "") +
                                    (doesUserHaveBlockedDomain(rs) === true ? " blocked-domain-true" : "") +
                                    (isPrincipalLocked(rs) === true ? "  locked" : ""), key: index, onMouseEnter: function () {
                                    setCursor(-1);
                                }, onClick: function () {
                                    toggleUser(rs);
                                } },
                                React.createElement("div", { className: "pic" },
                                    React.createElement(OfficeProfilePicture, { size: "sm", uid: rs.id, displayName: rs.displayName, type: "User" })),
                                React.createElement("div", { className: "info" },
                                    React.createElement("div", { className: "name" }, rs.displayName),
                                    React.createElement("div", { className: "job-title" }, rs.mail)),
                                React.createElement("div", { className: "action" },
                                    React.createElement("div", { className: "add" },
                                        React.createElement("i", { className: "fa fa-plus" })),
                                    React.createElement("div", { className: "check" },
                                        React.createElement("i", { className: "fa fa-check" })),
                                    React.createElement("div", { className: "locked" },
                                        React.createElement("i", { className: "fa fa-lock" })),
                                    React.createElement("div", { className: "blocked-domain" },
                                        React.createElement(InfoTooltip, { content: React.createElement(React.Fragment, null, intl.formatMessage({
                                                id: "component.principal_guest_picker.blocked_domain_tooltip",
                                                defaultMessage: "Based on current rules, this user belongs to a blocked domain and cannot be requested."
                                            })) }, intl.formatMessage({ id: "component.principal_guest_picker.blocked_domain_title", defaultMessage: "Blocked Domain" })))),
                                React.createElement("div", { className: "principal-type type-u" },
                                    React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.user_label", defaultMessage: "User" }) },
                                        React.createElement(FormattedMessage, { id: "component.principal_search_modal.user_acronym", defaultMessage: "U" }))))); }),
                        shouldShowNoResult() ? (React.createElement("li", { className: "no-results" },
                            React.createElement(FormattedMessage, { id: "general.label.no_results", defaultMessage: "No results" }))) : null,
                        isLoading ? (React.createElement("li", { className: "loader" },
                            React.createElement("div", null,
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35, bodyWidth: 350 })))) : null))),
            newGuestQueue && newGuestQueue.length > 0 ? (React.createElement("div", { className: "people-queue ".concat(queueVisible ? "open" : "closed") },
                React.createElement("div", { onClick: function () { return setqueueVisible(!queueVisible); }, className: "people-queue-banner" },
                    React.createElement("div", { className: "queue-info" },
                        React.createElement("span", null,
                            React.createElement("span", { className: "queue-info-text" }, intl.formatMessage({ id: "component.principal_search_modal.queue_label", defaultMessage: "Guests Selected" })),
                            React.createElement("span", { className: "queue-info-count" },
                                "(",
                                newGuestQueue.length,
                                ")"))),
                    React.createElement("i", { className: "".concat(queueVisible ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down") })),
                React.createElement("div", { className: "people-queue-container ".concat(newGuestQueue.length >= 2 ? "two-or-more" : "", " ").concat(queueVisible ? "visible" : "hidden") },
                    React.createElement("ul", null,
                        React.createElement(DynamicScrollbar, { shouldShow: queueVisible, maxHeight: 120 }, newGuestQueue.map(function (guest) {
                            return (React.createElement("li", null,
                                React.createElement("div", { className: "pic" },
                                    React.createElement(OfficeProfilePicture, { size: "sm", uid: guest.id, displayName: guest.displayName, type: "User" })),
                                React.createElement("div", { className: "info" },
                                    React.createElement("div", { className: "name" }, guest.displayName),
                                    React.createElement("div", { className: "job-title" }, guest.mail)),
                                React.createElement("div", { className: "principal-type type-u" },
                                    React.createElement("span", { title: intl.formatMessage({ id: "component.principal_search_modal.user_label", defaultMessage: "User" }) },
                                        React.createElement(FormattedMessage, { id: "component.principal_search_modal.user_acronym", defaultMessage: "U" }))),
                                React.createElement("div", { onClick: function () {
                                        //guard clause to make sure you can't delete existing guests using the queue
                                        if (newGuestQueue.filter(function (queuedGuest) { return queuedGuest.id === guest.id; }).length > 0)
                                            props.onGuestRemoved(guest);
                                        setnewGuestQueue(function (newGuestQueue) { return newGuestQueue.filter(function (queuedGuest) { return guest.id !== queuedGuest.id; }); });
                                    }, className: "remove" },
                                    React.createElement("i", { className: "fa-solid fa-minus" }))));
                        })))))) : null),
        React.createElement(Modal.Footer, { className: "principal-modal-guests-footer" },
            React.createElement("div", { className: "left" },
                React.createElement(SubtitleButton, { faIcon: "plus", color: "outline-gray", title: intl.formatMessage({ id: "page.request_wizard.step_permissions.add_new_guest", defaultMessage: "Add new guest" }), onClick: function () { return openAddNewPage(); } })),
            React.createElement("div", { className: "right" },
                React.createElement(SubtitleButton, { color: "brandblue", title: props.doneButtonText !== undefined ? props.doneButtonText : intl.formatMessage({ id: "general.label.done", defaultMessage: "Done" }), onClick: function () {
                        props.onPickerSubmit();
                        setnewGuestQueue([]);
                        setSearchResults([]);
                        setSearchInputText(null);
                        setModalOpen(false);
                    } }))),
        React.createElement(Modal, { size: "sm", backdropClassName: "guest-picker-confirmation-modal-bg", show: showGuestAddCloseConfirmation, onHide: function () { }, animation: true, className: "guest-picker-confirmation-modal" },
            React.createElement(Modal.Header, null,
                React.createElement("span", null, intl.formatMessage({ id: "component.guest_picker_confirmation_modal.header", defaultMessage: "Cancel this Request" }))),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "new-guest-close-body" },
                    React.createElement("p", { className: "text", style: { marginBottom: "0" } }, intl.formatMessage({
                        id: "component.guest_picker_confirmation_modal.body",
                        defaultMessage: "Request for {newGuestCountBold} {newGuestCount, plural, one {guest} other {guests}} has not been submitted."
                    }, { newGuestCount: newGuestQueue.length, newGuestCountBold: React.createElement("strong", null, newGuestQueue.length) })))),
            React.createElement(Modal.Footer, null,
                React.createElement("div", { className: "new-guest-close-footer" },
                    React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "component.guest_picker_confirmation_modal.no_button", defaultMessage: "Cancel" }), onClick: function () { return setshowGuestAddCloseConfirmation(false); }, noMargin: true, color: "outline-gray" }),
                    React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "component.guest_picker_confirmation_modal.yes_button", defaultMessage: "Confirm" }), color: "brandblue", onClick: function () {
                            setnewGuestCloseConfirmation(true);
                        } }))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { size: "sm", backdropClassName: "principal-guest-picker-modal-backdrop", show: modalOpen, onHide: function () { }, animation: true, className: "principal-guest-picker-modal" }, currentPage === "search" ? (React.createElement("div", { className: "page-search" }, SearchPage)) : currentPage === "add-new" ? (React.createElement("div", { className: "page-add-new" }, editingGuest !== null ? (React.createElement(PrincipalGuestsPickerModalAddNewGuestPage, { getNamingPolicyDisplayName: getPolicyNamingDisplayName, cancelClick: function () { return cancelToSearchPage(); }, submitClick: function () { return doneNewUserSaveAndRedirectToSearchPage(); }, searchForClick: searchForUser, searchText: searchInputText, updateGuestUser: updateEditingUser, editingGuest: editingGuest, guestRequestPolicy: props.guestRequestPolicy, showUpdatingScreen: showUserUpdatingMessage, blockedDomains: blockedDomains })) : (React.createElement(SkeletonLoaderFakeBox, null)))) : currentPage === "edit-existing" ? (React.createElement("div", { className: "page-edit-existing" }, editingGuest !== null ? (React.createElement(PrincipalGuestsPickerModalEditExistingGuestPage, { cancelClick: function () { return cancelToSearchPage(); }, submitClick: function () { return doneEditExistingUserSaveAndRedirectToSearchPage(); }, updateGuestUser: updateEditingUser, editingGuest: editingGuest, guestRequestPolicy: props.guestRequestPolicy, showUpdatingScreen: showUserUpdatingMessage })) : (React.createElement(SkeletonLoaderFakeBox, null)))) : null),
        React.createElement(OrchModal, { showModalOverride: errorMessage !== null, headerText: "Error", footerContents: React.createElement("div", { style: { textAlign: "right" } },
                React.createElement(SubtitleButton, { title: "Close", onClick: function () { return errorModalToggle(false); }, noMargin: true })), showModalToggleOverride: errorModalToggle, modalBgClassName: "guest-picker-error-modal-bg", modalClassName: "guest-picker-error-modal", size: "sm" },
            React.createElement(Modal.Body, null, errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.ErrorMessage)),
        React.createElement("div", { className: "link", onClick: function () { return setModalOpen(!modalOpen); } }, props.children)));
});
export default PrincipalGuestsPickerModal;
var PrincipalGuestsPickerModalEditExistingGuestPage = function (props) {
    var _a;
    var intl = useIntl();
    var _b = React.useState(false), isPageValid = _b[0], setIsPageValid = _b[1];
    return (React.createElement("div", { className: props.showUpdatingScreen === true ? "processing-guest" : "" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setIsPageValid(isValid);
            }, UniqueIdentifier: "GuestPickerEditExistingGuest" },
            React.createElement("div", { className: "people-content" },
                React.createElement("div", { className: "people-header" },
                    React.createElement("span", null, intl.formatMessage({ id: "component.principal_guest_picker_modal.guest_details.title", defaultMessage: "Guest Details" })),
                    React.createElement("div", { className: "close", onClick: function () { return props.cancelClick(); } },
                        React.createElement("i", { className: "la la-remove" }))),
                React.createElement("div", { className: "people-updating-server" },
                    React.createElement(AnimatedLoader, { text: intl.formatMessage({ id: "component.principal_guest_picker_modal.updating_guest", defaultMessage: "Updating Guest" }), type: "squares", size: "xxsmall" })),
                React.createElement("div", { className: "people-description" }, intl.formatMessage({
                    id: "component.principal_guest_picker_modal.edit_guest_description",
                    defaultMessage: "This workspace type requires that guests be approved before being added to the workspace. Once the request is submitted, a notification will be sent to the policy approver."
                })),
                React.createElement("div", { className: "people-form" },
                    React.createElement(Form.Group, { className: "form-group", controlId: "guest-email" },
                        React.createElement(Form.Label, null,
                            intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.email", defaultMessage: "Email" }),
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", { className: "email-box" },
                            React.createElement("input", { type: "email", className: "form-control", disabled: true, value: (_a = props.editingGuest) === null || _a === void 0 ? void 0 : _a.mail }))),
                    React.createElement("div", { style: { userSelect: "none" } },
                        React.createElement(PrincipalGuestsPickerModalFormFields, { isDisabled: false, editingGuest: props.editingGuest, updateGuestUser: props.updateGuestUser, guestRequestPolicy: props.guestRequestPolicy })))),
            React.createElement(Modal.Footer, { className: "principal-modal-guests-footer" },
                React.createElement("div", { className: "left" },
                    React.createElement(SubtitleButton, { color: "outline-gray", title: intl.formatMessage({ id: "general.label.cancel", defaultMessage: "Cancel" }), onClick: function () { return props.cancelClick(); } })),
                React.createElement("div", { className: "right" },
                    React.createElement(SubtitleButton, { disabled: !isPageValid, color: "brandblue", title: intl.formatMessage({ id: "general.label.confirm_and_add", defaultMessage: "Confirm & Save" }), onClick: function () { return props.submitClick(); } }))))));
};
var PrincipalGuestsPickerModalAddNewGuestPage = function (props) {
    var _a, _b, _c, _d;
    var intl = useIntl();
    var _e = React.useState(false), isPageValid = _e[0], setIsPageValid = _e[1];
    var emailEl = React.useRef();
    var _f = React.useState(false), isEmailLoading = _f[0], setIsEmailLoading = _f[1];
    var _g = React.useState(), alreadyRegisteredGuest = _g[0], setAlreadyRegisteredGuest = _g[1];
    React.useEffect(function () {
        setIsEmailLoading(false);
        if (props.searchText !== null && validateEmail(props.searchText)) {
            getEmailLookupResults(props.searchText);
            props.updateGuestUser({ mail: props.searchText });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getEmailLookupResults = function (searchValue) {
        setIsEmailLoading(true);
        setAlreadyRegisteredGuest(undefined);
        props.updateGuestUser({ mail: searchValue });
        setTimeout(function () {
            //console.log("timeout", searchValue, inputEl.current.value, searchValue === inputEl.current.value)
            if (emailEl.current !== undefined && emailEl.current !== null && searchValue === emailEl.current.value) {
                //
                if (searchValue.length === 0) {
                    setIsEmailLoading(false);
                    setAlreadyRegisteredGuest(undefined);
                    UsersGuestsService.getGuestByMail(searchValue).then(function (resultData) { });
                }
                else {
                    UsersGuestsService.getGuestByMail(searchValue).then(function (resultData) {
                        //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                        try {
                            if (searchValue === emailEl.current.value) {
                                setIsEmailLoading(false);
                                //console.log("Guest Come Back", resultData);
                                setAlreadyRegisteredGuest(resultData);
                            }
                        }
                        catch (_a) { }
                    });
                }
            }
        }, 1000);
    };
    var isEmailUsingBlockedDomain = function () {
        if (props.editingGuest.mail !== undefined && props.editingGuest.mail !== null) {
            if (props.editingGuest.mail.indexOf("@") > -1) {
                var domain_2 = props.editingGuest.mail.split("@")[1];
                if (domain_2 !== null && domain_2 !== "") {
                    if (props.blockedDomains.some(function (bd) { return bd === domain_2.toLowerCase(); })) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    var isFormDisabled = function () {
        var _a;
        return alreadyRegisteredGuest === undefined || alreadyRegisteredGuest !== null || isEmailUsingBlockedDomain() === true || validateEmail((_a = props.editingGuest) === null || _a === void 0 ? void 0 : _a.mail) === false;
    };
    var validateEmail = function (email) {
        if (email !== undefined && email !== null && email !== "") {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
        else {
            return false;
        }
    };
    return (React.createElement("div", { className: props.showUpdatingScreen === true ? "processing-guest" : "" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setIsPageValid(isValid);
            }, UniqueIdentifier: "GuestPickerAddNewGuest" },
            React.createElement("div", { className: "people-content" },
                React.createElement("div", { className: "people-header" },
                    React.createElement("span", null, intl.formatMessage({ id: "component.principal_guest_picker_modal.new_guest_title", defaultMessage: "New Guest" })),
                    React.createElement("div", { className: "close", onClick: function () { return props.cancelClick(); } },
                        React.createElement("i", { className: "la la-remove" }))),
                React.createElement("div", { className: "people-updating-server" },
                    React.createElement(AnimatedLoader, { text: intl.formatMessage({ id: "component.principal_guest_picker_modal.creating_guest", defaultMessage: "Creating Guest" }), type: "squares", size: "xxsmall" })),
                React.createElement("div", { className: "people-description" }, intl.formatMessage({
                    id: "component.principal_guest_picker_modal.new_guest_description",
                    defaultMessage: "This workspace type requires that new guests be approved before being added to the workspace. Once the profile is created and the request submitted, a notification will be sent to the policy approver."
                })),
                React.createElement("div", { className: "people-form" },
                    React.createElement(Form.Group, { className: "form-group", controlId: "guest-email" },
                        React.createElement(Form.Label, null,
                            intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.email", defaultMessage: "Email" }),
                            React.createElement("span", { className: "required-astrix" }, "*")),
                        React.createElement("div", { className: "email-box" },
                            React.createElement("input", { type: "email", className: "form-control", ref: emailEl, placeholder: "e.g. mikeboon@gmail.com", value: (_a = props.editingGuest) === null || _a === void 0 ? void 0 : _a.mail, onChange: function (event) { return getEmailLookupResults(event.target.value); } }),
                            React.createElement("div", { className: "status" }, isEmailLoading === true ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : alreadyRegisteredGuest === undefined ? null : alreadyRegisteredGuest !== null ||
                                isEmailUsingBlockedDomain() === true ||
                                validateEmail((_b = props.editingGuest) === null || _b === void 0 ? void 0 : _b.mail) === false ? (React.createElement("div", { className: "guest-icon" },
                                React.createElement("i", { className: "fa fa-ban" }))) : alreadyRegisteredGuest === null ? (React.createElement("div", { className: "guest-icon" },
                                React.createElement("i", { className: "fa fa-check" }))) : null))),
                    isEmailUsingBlockedDomain() === true ? (React.createElement(PortletAlert, { message: React.createElement(React.Fragment, null, intl.formatMessage({
                            id: "component.principal_guest_picker_modal.warning_user_blocked_domain",
                            defaultMessage: "Based on current rules, this user belongs to a blocked domain and cannot be requested."
                        })), outline: false, type: "danger", icon: "ban", size: "small" })) : alreadyRegisteredGuest !== undefined && alreadyRegisteredGuest !== null ? (React.createElement(PortletAlert, { message: React.createElement(React.Fragment, null,
                            intl.formatMessage({ id: "component.principal_guest_picker_modal.warning_existing_match_found", defaultMessage: "Existing match found" }),
                            " (",
                            React.createElement("span", { className: "search-link", onClick: function () { return props.searchForClick(alreadyRegisteredGuest.displayName); } }, alreadyRegisteredGuest.displayName),
                            ")"), outline: false, type: "danger", icon: "ban", size: "small" })) : null,
                    alreadyRegisteredGuest !== undefined && validateEmail((_c = props.editingGuest) === null || _c === void 0 ? void 0 : _c.mail) === false ? (React.createElement(PortletAlert, { message: React.createElement(React.Fragment, null, intl.formatMessage({
                            id: "component.principal_guest_picker_modal.warning_properly_formatted_email",
                            defaultMessage: "Please enter a properly formatted email address."
                        })), outline: false, type: "warning", icon: "alert", size: "small" })) : null,
                    React.createElement(FormValidator, { UniqueIdentifier: "email", FieldName: "Email", Validators: ["is-true"], Hide: true, ErrorMessage: intl.formatMessage({ id: "component.principal_guest_picker_modal.warning_email_must_be_valid", defaultMessage: "Email must be valid and not already registered" }), Value: alreadyRegisteredGuest === null && isEmailUsingBlockedDomain() === false && validateEmail((_d = props.editingGuest) === null || _d === void 0 ? void 0 : _d.mail) }),
                    React.createElement(DisableArea, { useOverlay: true, isDisabled: isFormDisabled() },
                        React.createElement("div", { style: { userSelect: "none" } },
                            React.createElement(PrincipalGuestsPickerModalFormFields, { isDisabled: isFormDisabled(), namingPolicyPreview: props.guestRequestPolicy.GuestNamingConventionEnabled === true ? props.getNamingPolicyDisplayName(props.editingGuest) : undefined, policyProfilePictureBase64: props.guestRequestPolicy.GuestDefaultProfileImage !== undefined && props.guestRequestPolicy.GuestDefaultProfileImage !== null
                                    ? "data:image/jpeg;base64," + props.guestRequestPolicy.GuestDefaultProfileImage
                                    : undefined, editingGuest: props.editingGuest, updateGuestUser: props.updateGuestUser, guestRequestPolicy: props.guestRequestPolicy }))))),
            React.createElement(Modal.Footer, { className: "principal-modal-guests-footer" },
                React.createElement("div", { className: "left" },
                    React.createElement(SubtitleButton, { color: "outline-gray", title: intl.formatMessage({ id: "general.label.cancel", defaultMessage: "Cancel" }), onClick: function () { return props.cancelClick(); } })),
                React.createElement("div", { className: "right" },
                    React.createElement(SubtitleButton, { disabled: !isPageValid, color: "brandblue", title: intl.formatMessage({ id: "general.label.confirm_and_add", defaultMessage: "Confirm & Add" }), onClick: function () { return props.submitClick(); } }))))));
};
var PrincipalGuestsPickerModalFormFields = function (props) {
    var _a, _b, _c, _d;
    var intl = useIntl();
    var showField = function (fieldName) {
        var policyConfig = props.guestRequestPolicy.GuestDetailOptions;
        if (policyConfig !== undefined && policyConfig !== null && Array.isArray(policyConfig)) {
            if (Array.isArray(props.editingGuest.orchestryProperties.existingUserFieldsUpdated)) {
                if (policyConfig.some(function (pc) { return pc.id === fieldName && (pc.isChecked === true || pc.isDefault === true); }) &&
                    props.editingGuest.orchestryProperties.existingUserFieldsUpdated.some(function (f) { return f === fieldName; }) === true) {
                    return true;
                }
            }
            else {
                if (policyConfig.some(function (pc) { return pc.id === fieldName && (pc.isChecked === true || pc.isDefault === true); })) {
                    return true;
                }
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "form-fields" },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(Form.Group, { className: "form-group", controlId: "guest-first-name" },
                    React.createElement(Form.Label, null,
                        intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.first_name", defaultMessage: "First Name" }),
                        React.createElement("span", { className: "required-astrix" }, "*")),
                    React.createElement(Form.Control, { type: "text", disabled: showField("givenName") === false || props.isDisabled, placeholder: "", value: (_a = props.editingGuest) === null || _a === void 0 ? void 0 : _a.givenName, onChange: function (event) { return props.updateGuestUser({ givenName: event.target.value }); } })),
                React.createElement(FormValidator, { UniqueIdentifier: "first-name", Enabled: showField("givenName") === true, FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.first_name", defaultMessage: "First Name" }), Validators: ["string-not-empty"], Value: (_b = props.editingGuest) === null || _b === void 0 ? void 0 : _b.givenName })),
            React.createElement(Col, null,
                React.createElement(Form.Group, { className: "form-group", controlId: "guest-last-name" },
                    React.createElement(Form.Label, null,
                        intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.last_name", defaultMessage: "Last Name" }),
                        React.createElement("span", { className: "required-astrix" }, "*")),
                    React.createElement(Form.Control, { type: "text", disabled: showField("surname") === false || props.isDisabled, placeholder: "", value: (_c = props.editingGuest) === null || _c === void 0 ? void 0 : _c.surname, onChange: function (event) { return props.updateGuestUser({ surname: event.target.value }); } })),
                React.createElement(FormValidator, { UniqueIdentifier: "last-name", Enabled: showField("surname") === true, FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.last_name", defaultMessage: "Last Name" }), Validators: ["string-not-empty"], Value: (_d = props.editingGuest) === null || _d === void 0 ? void 0 : _d.surname }))),
        showField("companyName") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-company" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.company", defaultMessage: "Company" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(PrincipalGuestsPickerModalFormFieldCompany, { canAddCustom: true, maxNumberOfValues: 1, disabled: props.isDisabled, values: props.editingGuest.companyName != null && props.editingGuest.companyName !== "" ? [props.editingGuest.companyName] : [], setValues: function (values) { return props.updateGuestUser({ companyName: values.length > 0 ? values[0] : null }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "companyName", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.company", defaultMessage: "Company" }), Validators: ["string-not-empty"], Value: props.editingGuest.companyName, ErrorMessage: intl.formatMessage({
                    id: "component.principal_guest_picker_modal.warning_company_must_be_selected",
                    defaultMessage: "Company must be selected from results, or added."
                }) }))),
        showField("jobTitle") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-jobTitle" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.job_title", defaultMessage: "Job Title" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(Form.Control, { type: "text", placeholder: "Select", disabled: props.isDisabled, value: props.editingGuest.jobTitle, onChange: function (event) { return props.updateGuestUser({ jobTitle: event.target.value }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "job-title", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.job_title", defaultMessage: "Job Title" }), Validators: ["string-not-empty"], Value: props.editingGuest.jobTitle }))),
        showField("city") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-city" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.city", defaultMessage: "City" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(Form.Control, { type: "text", disabled: props.isDisabled, value: props.editingGuest.city, onChange: function (event) { return props.updateGuestUser({ city: event.target.value }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "city", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.city", defaultMessage: "City" }), Validators: ["string-not-empty"], Value: props.editingGuest.city }))),
        showField("department") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-department" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.department", defaultMessage: "Department" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(Form.Control, { type: "text", disabled: props.isDisabled, value: props.editingGuest.department, onChange: function (event) { return props.updateGuestUser({ department: event.target.value }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "department", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.department", defaultMessage: "Department" }), Validators: ["string-not-empty"], Value: props.editingGuest.department }))),
        showField("state") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-stateOrProvince" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.state", defaultMessage: "State or Province" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(Form.Control, { type: "text", disabled: props.isDisabled, value: props.editingGuest.state, onChange: function (event) { return props.updateGuestUser({ state: event.target.value }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "state", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.state", defaultMessage: "State or Province" }), Validators: ["string-not-empty"], Value: props.editingGuest.state }))),
        showField("country") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-country" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.country", defaultMessage: "Country" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(Form.Control, { type: "text", disabled: props.isDisabled, value: props.editingGuest.country, onChange: function (event) { return props.updateGuestUser({ country: event.target.value }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "country", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.country", defaultMessage: "Country" }), Validators: ["string-not-empty"], Value: props.editingGuest.country }))),
        showField("mobilePhone") === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-mobilePhone" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.mobile_phone", defaultMessage: "Mobile Phone" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(PhoneInput, { country: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.mobile_phone_format", defaultMessage: "us" }), value: props.editingGuest.mobilePhone, disabled: props.isDisabled, onChange: function (phone) { return props.updateGuestUser({ mobilePhone: phone }); } }),
            React.createElement(FormValidator, { UniqueIdentifier: "mobilePhone", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.mobile_phone", defaultMessage: "Mobile Phone" }), Validators: ["string-not-empty"], Value: props.editingGuest.mobilePhone }))),
        props.guestRequestPolicy.RequireGuestJustification === true && (React.createElement(Form.Group, { className: "form-group", controlId: "guest-justification" },
            React.createElement(Form.Label, null,
                intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.justification", defaultMessage: "Justification" }),
                React.createElement("span", { className: "required-astrix" }, "*")),
            React.createElement(Form.Control, { type: "text", as: "textarea", disabled: props.isDisabled, placeholder: "", value: props.editingGuest.orchestryProperties.justification, onChange: function (event) {
                    return props.updateGuestUser({ orchestryProperties: __assign(__assign({}, props.editingGuest.orchestryProperties), { justification: event.target.value }) });
                } }),
            React.createElement("div", { className: "previous-justification", 
                //@ts-ignore
                onClick: function () { return props.updateGuestUser({ orchestryProperties: __assign(__assign({}, props.editingGuest.orchestryProperties), { justification: document.lastJustification }) }); } }, intl.formatMessage({ id: "component.principal_guest_picker_modal.button.use_previous_justification", defaultMessage: "Use previous justification" })),
            React.createElement(FormValidator, { UniqueIdentifier: "justification", FieldName: intl.formatMessage({ id: "component.principal_guest_picker_modal.property_title.justification", defaultMessage: "Justification" }), Validators: ["string-not-empty"], Value: props.editingGuest.orchestryProperties.justification }))),
        props.namingPolicyPreview !== undefined ? (React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("div", { className: "display-name-preview" },
                    React.createElement("div", { className: "description" }, intl.formatMessage({ id: "component.principal_guest_picker_modal.naming_policy.altered_text", defaultMessage: "This guest's display name will be altered as follows:" })),
                    React.createElement("div", { className: "preview" },
                        React.createElement(PrincipalItem, { size: "sm", principal: { DisplayName: props.namingPolicyPreview }, base64image: props.policyProfilePictureBase64 })))))) : null));
};
var PrincipalGuestsPickerModalFormFieldCompany = function (props) {
    var _a = React.useState(""), currentSearchTerm = _a[0], setCurrentSearchTerms = _a[1];
    var _b = React.useState(false), isSearchSuggestionsLoading = _b[0], setIsSearchSuggestionsLoading = _b[1];
    var searchInputEl = React.useRef();
    var _c = React.useState([]), searchSuggestionResults = _c[0], setSearchSuggestionResults = _c[1];
    var _d = React.useState(-1), searchSuggestCursor = _d[0], setSearchSuggestCursor = _d[1];
    var _e = React.useState(false), searchOpen = _e[0], setSearchOpen = _e[1];
    var _f = React.useState(false), searchSuggestionOpen = _f[0], setSearchSuggestionOpen = _f[1];
    var searchContainerElement = React.useRef();
    var intl = useIntl();
    var getSearchSuggestionResults = function (searchValue) {
        setSearchSuggestCursor(-1);
        setCurrentSearchTerms(searchValue);
        setSearchSuggestionOpen(false);
        setTimeout(function () {
            if (searchValue === searchInputEl.current.value) {
                if (searchValue.length < 2) {
                    setSearchSuggestionOpen(false);
                    setIsSearchSuggestionsLoading(false);
                    setSearchSuggestionResults([]);
                    // This will cancel the last request.
                    UsersGuestsService.searchCompanies(searchValue).then(function (resultData) { });
                }
                else {
                    setSearchSuggestionOpen(true);
                    setIsSearchSuggestionsLoading(true);
                    UsersGuestsService.searchCompanies(searchValue).then(function (resultData) {
                        //console.log("searchValue, inputEl.current.value", searchValue, inputEl.current.value)
                        try {
                            if (searchValue === searchInputEl.current.value) {
                                setSearchSuggestionResults(resultData);
                                setIsSearchSuggestionsLoading(false);
                            }
                        }
                        catch (_a) { }
                    });
                }
            }
        }, 150);
    };
    React.useEffect(function () {
        // add when mounted
        // document.addEventListener("mousedown", searchSuggestionLookForCloseClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", searchSuggestionLookForCloseClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleSearchSuggestKeyDown = function (e) {
        var _a;
        if (e.keyCode === 38 && searchSuggestCursor === 0) {
            setSearchSuggestCursor(-1);
        }
        else if (e.keyCode === 38 && searchSuggestCursor > 0) {
            setSearchSuggestCursor(searchSuggestCursor - 1);
        }
        else if (e.keyCode === 40 && searchSuggestCursor < searchSuggestionResults.length) {
            setSearchSuggestCursor(searchSuggestCursor + 1);
        }
        else if (e.keyCode === 13) {
            if (searchSuggestCursor > -1) {
                var link = document.getElementById("suggest-link-" + (searchSuggestCursor + 1));
                if (link !== null) {
                    //console.log("LinkURL: " + linkUrl);
                    var name = (_a = link.attributes.getNamedItem("data-name")) === null || _a === void 0 ? void 0 : _a.value;
                    //window.history.replaceState(null, "Orchestry", linkUrl as string);
                    if (name !== undefined)
                        addResultToValues(name);
                }
            }
            else {
                setSearchSuggestionOpen(false);
                // dispatch(
                //    LifecycleReportStore.UpdateContext(
                //       {
                //          SearchTerm: searchInputEl.current.value
                //       },
                //       props.Slot
                //    )
                // );
            }
            //clickSuggestion(searchSuggestionResults[searchSuggestCursor]);
        }
    };
    var searchSuggestionLookForCloseClick = function (e) {
        var setHidden = false;
        if (searchContainerElement.current !== undefined) {
            if (searchContainerElement.current !== null) {
                if (searchContainerElement.current.contains !== undefined) {
                    if (searchContainerElement.current.contains !== null) {
                        if (searchContainerElement.current.contains(e.target)) {
                        }
                        else {
                            try {
                                setHidden = true;
                            }
                            catch (_a) { }
                        }
                    }
                }
            }
        }
        if (setHidden === true && !isSearchSuggestionsLoading) {
            // @ts-ignore
            setSearchOpen(false);
            setSearchSuggestionOpen(false);
        }
    };
    var clearSearch = function () {
        setSearchSuggestionOpen(false);
        setIsSearchSuggestionsLoading(false);
        setSearchSuggestionResults([]);
        // if (searchInputEl.current !== null) {
        //    searchInputEl.current.value = "";
        // }
        // if (lifecycleReport.Context.SearchTerm?.length !== 0) {
        //    dispatch(
        //       LifecycleReportStore.UpdateContext(
        //          {
        //             SearchTerm: ""
        //          },
        //          props.Slot
        //       )
        //    );
        // }
    };
    var addResultToValues = function (value, isNew) {
        clearSearch();
        if (isNew) {
            value += ";new";
        }
        if (props.values.some(function (v) { return v.toLowerCase() === value.toLowerCase(); })) {
            // Do nothing.
        }
        else {
            if (props.maxNumberOfValues === 1) {
                props.setValues([value]);
            }
            else {
                if (props.maxNumberOfValues < props.values.length) {
                    props.setValues(__spreadArray(__spreadArray([], props.values, true), [value], false));
                }
            }
        }
    };
    var removeResultFromValues = function (value) {
        props.setValues(__spreadArray([], props.values.filter(function (v) { return (v === null || v === void 0 ? void 0 : v.toLowerCase()) !== (value === null || value === void 0 ? void 0 : value.toLowerCase()); }), true));
    };
    return (React.createElement("div", { className: "company-search" +
            (searchOpen === true ? " search-open" : "") +
            (searchInputEl !== undefined && searchInputEl.current !== undefined && searchInputEl.current.value.length > 0 ? " has-term" : ""), ref: searchContainerElement },
        React.createElement("div", { className: "search-box" },
            React.createElement("div", { className: "search-box-border " + (props.disabled === true ? "disabled" : "") },
                React.createElement("div", { className: "search-box-input " + (props.disabled === true ? "disabled" : ""), onBlur: function () {
                        setTimeout(function () {
                            clearSearch();
                        }, 300);
                    } },
                    props.values.map(function (v) { return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "tag" },
                            React.createElement("span", null, v.includes(";new") ? v.split(";new")[0] : v),
                            React.createElement("div", { className: "remove", onClick: function () {
                                    removeResultFromValues(v);
                                } },
                                React.createElement("i", { className: "fa fa-times" }))))); }),
                    React.createElement("input", { className: props.values.length >= props.maxNumberOfValues ? "hide-input" : "", placeholder: intl.formatMessage({ id: "component.principal_guest_picker_modal.company_picker.input_placeholder", defaultMessage: "Enter company name" }), onFocus: function () { return setSearchOpen(true); }, onChange: function (event) {
                            getSearchSuggestionResults(event.target.value);
                        }, onBlur: function () {
                            if (!isSearchSuggestionsLoading && searchOpen === true && props.values.length > 1)
                                setSearchOpen(false);
                        }, onKeyDown: function (event) {
                            if (event.keyCode === 9)
                                event.preventDefault();
                            handleSearchSuggestKeyDown(event);
                        }, ref: searchInputEl, disabled: props.disabled }),
                    isSearchSuggestionsLoading === true ? (React.createElement("div", { className: "loader" },
                        React.createElement(AnimatedLoader, { size: "xxsmall", theme: "dark" }))) : null))),
        React.createElement("div", { className: "search-suggestion-list" + (isSearchSuggestionsLoading === false && searchSuggestionOpen === true && searchOpen === true ? " suggestions-open" : " suggestions-closed") },
            React.createElement("div", null,
                searchSuggestionResults.map(function (companyName, index) { return (
                // <li className={(cursor === index ? ' hover-selected' : "") + (isPrincipalSelected(rs) ? " selected":"")+ (isPrincipalLocked(rs) === true ? "  locked":"")} key={index} onMouseEnter={()=> { setCursor(-1) }} onClick={()=> {clickUser(rs)}}>
                React.createElement("div", { onClick: function () {
                        addResultToValues(companyName);
                        //console.log(companyName);
                        return false;
                    }, "data-name": companyName, id: "suggest-link-" + (index + 1), key: index },
                    React.createElement("div", { className: "sug-item" + (searchSuggestCursor === index ? " selected" : "") },
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "name", dangerouslySetInnerHTML: {
                                    __html: highlightWords(searchInputEl.current.value, companyName)
                                } }))))); }),
                searchInputEl !== undefined &&
                    isSearchSuggestionsLoading === false &&
                    searchInputEl !== null &&
                    searchInputEl.current !== undefined &&
                    searchInputEl.current !== null &&
                    Array.isArray(searchSuggestionResults) &&
                    searchSuggestionResults.filter(function (v) { return v !== null; }).some(function (v) { var _a; return (v === null || v === void 0 ? void 0 : v.toLowerCase()) === ((_a = searchInputEl.current.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }) === false && (React.createElement("div", { onClick: function () {
                        addResultToValues(searchInputEl.current.value, true);
                        //console.log(companyName);
                        return false;
                    }, "data-name": searchInputEl.current.value, id: "suggest-link-" + (Array.isArray(searchSuggestionResults) ? searchSuggestionResults.length + 1 : 1), key: searchSuggestionResults.length },
                    React.createElement("div", { className: "sug-item" + (searchSuggestCursor === (Array.isArray(searchSuggestionResults) ? searchSuggestionResults.length : 1) ? " selected" : "") },
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "name" },
                                intl.formatMessage({ id: "component.principal_guest_picker_modal.company_picker.button.add", defaultMessage: "Add" }),
                                React.createElement("span", { className: "new-value" }, searchInputEl.current.value))))))))));
};
