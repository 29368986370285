export var LibraryTemplateListActionTypes;
(function (LibraryTemplateListActionTypes) {
    LibraryTemplateListActionTypes["LibraryTemplate_List_Load"] = "LibraryTemplate_List_Load";
    LibraryTemplateListActionTypes["LibraryTemplate_List_Refresh"] = "LibraryTemplate_List_Refresh";
    LibraryTemplateListActionTypes["LibraryTemplate_List_LoadForTemplateCopy"] = "LibraryTemplate_List_LoadForTemplateCopy";
    LibraryTemplateListActionTypes["LibraryTemplate_List_Item_Add"] = "LibraryTemplate_List_Item_Add";
    LibraryTemplateListActionTypes["LibraryTemplate_List_Item_Delete"] = "LibraryTemplate_List_Item_Delete";
    LibraryTemplateListActionTypes["LibraryTemplate_List_Item_Update"] = "LibraryTemplate_List_Item_Update";
    LibraryTemplateListActionTypes["LibraryTemplate_List_Update_Is_Loading"] = "LibraryTemplate_List_Update_Is_Loading";
    LibraryTemplateListActionTypes["Template_Copied_Success"] = "Template_Copied_Success";
})(LibraryTemplateListActionTypes || (LibraryTemplateListActionTypes = {}));
