var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./office-profile-picture.scss";
import UsersService from "app/services/users";
import GroupsService from "app/services/groups";
import TeamsService from "app/services/teams";
import SharePointService from "app/services/sharepoint";
import getStringTwoLetterAcronym from "app/utils/getStringTwoLetterAcronym";
import FontIcon from "app/components/common/font-icon";
import AnimatedLoader from "app/components/common/animated-loader";
import { getRandomInt } from "app/utils/randomGen";
import orchLogo from "app/assets/img/icon-orchestry-xsmall.png";
import { useDispatch, useSelector } from "react-redux";
import * as ProfileListStore from "app/store/profile-images/list";
import { ProfileOverlay } from "./overlay/Overlay";
var OfficeProfilePicture = React.memo(function (props) {
    var colors = ["green", "red", "orange", "blue", "purple", "yellow", "gray"];
    var orchestryUserIds = React.useState([
        "95323290-fd89-4b03-9ac7-e5a563c8ecc1",
        "57d856dc-04b0-4737-b18d-ff089c99732b",
        "2c52f9db-a29f-4394-9b45-2867b387b48e",
        "6b88b0b8-c153-41e8-8aee-cac4c76740d3",
        "9e0d10ea-21a3-4a6c-af10-38b5169a5ee0",
        "1dfeb9c8-dc8f-477b-b1b5-92a7ce794732",
        "00000000-0000-0000-0000-000000000000"
    ])[0];
    var _a = React.useState(props.base64image !== undefined && props.base64image !== null && props.base64image !== "" ? props.base64image : ""), base64Image = _a[0], setBase64Image = _a[1];
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    var Size = React.useState(props.size === undefined ? "xs" : props.size)[0];
    var _c = React.useState(props.iconColor === undefined ? colors[getRandomInt(0, 5)] : props.iconColor), color = _c[0], setColor = _c[1];
    var _d = React.useState(props.type !== undefined && props.type !== null ? props.type : "User"), type = _d[0], setType = _d[1];
    var profileImages = useSelector(function (state) { return state.profileImageListState.items; });
    var dispatch = useDispatch();
    React.useEffect(function () {
        setType(props.type !== undefined && props.type !== null ? props.type : "User");
    }, [props.type]);
    React.useEffect(function () {
        setBase64Image(props.base64image !== undefined && props.base64image !== null && props.base64image !== "" ? props.base64image : "");
    }, [props.base64image]);
    //setColor(colors[getRandomInt(0,5)]);
    // let placeHolderClasses = "profile-picture-placeholder";
    // if(props.size !== undefined){
    //     placeHolderClasses = "kt-media kt-media--circle";
    //     placeHolderClasses += " kt-media--" + props.size;
    //     placeHolderClasses += " " + colors[getRandomInt(0,5)];
    // }
    // On first run, get the suggested users
    React.useEffect(function () {
        var _a, _b;
        //console.log("UID", props.uid);
        // let sessionKey = "office_profile_pic_" + props.uid;
        // let sessionKeyColor = "office_profile_pic_color_" + props.uid;
        setBase64Image("");
        setLoading(true);
        setBase64Image(props.base64image !== undefined && props.base64image !== null && props.base64image !== "" ? props.base64image : "");
        if (props.uid !== undefined) {
            if (orchestryUserIds.some(function (uid) { return uid === props.uid; })) {
                setLoading(false);
                setBase64Image(orchLogo);
            }
            else if (profileImages.some(function (ui) { return ui.id === props.uid && ui.base64image !== null; })) {
                setLoading(false);
                setBase64Image((_a = profileImages.find(function (ui) { return ui.id === props.uid; })) === null || _a === void 0 ? void 0 : _a.base64image);
            }
            else if (props.base64image !== undefined && props.base64image !== null && props.base64image !== "") {
                setLoading(false);
                setBase64Image(base64Image);
            }
            else if (props.type === undefined || (props.type !== undefined && props.type === "User")) {
                UsersService.getUserBase64Photo(props.uid).then(function (resultData) {
                    setLoading(false);
                    setBase64Image(resultData);
                    dispatch(ProfileListStore.AddUpdateItem({
                        id: props.uid,
                        base64image: resultData,
                        base64image48: null,
                        color: null
                    }));
                    // LocalSessionService.Set(sessionKey, resultData as string);
                });
            }
            else if (props.type === "Group" || props.type === "SecurityGroup" || props.type === "DistributionGroup") {
                GroupsService.getGroupBase64Photo(props.uid).then(function (resultData) {
                    setLoading(false);
                    setBase64Image(resultData);
                    dispatch(ProfileListStore.AddUpdateItem({
                        id: props.uid,
                        base64image: resultData,
                        base64image48: null,
                        color: null
                    }));
                    // LocalSessionService.Set(sessionKey, resultData as string);
                });
            }
            else if (props.type === "Teams") {
                TeamsService.getBase64Photo(props.uid).then(function (resultData) {
                    setLoading(false);
                    setBase64Image(resultData);
                    dispatch(ProfileListStore.AddUpdateItem({
                        id: props.uid,
                        base64image: resultData,
                        base64image48: null,
                        color: null
                    }));
                    // LocalSessionService.Set(sessionKey, resultData as string);
                });
            }
            else if (props.type === "SharePoint") {
                SharePointService.getBase64Photo(props.uid).then(function (resultData) {
                    setLoading(false);
                    setBase64Image(resultData);
                    dispatch(ProfileListStore.AddUpdateItem({
                        id: props.uid,
                        base64image: resultData,
                        base64image48: null,
                        color: null
                    }));
                    // LocalSessionService.Set(sessionKey, resultData as string);
                });
            }
            // Colors
            if (profileImages.some(function (ui) { return ui.id === props.uid && ui.color !== null; })) {
                setColor((_b = profileImages.find(function (ui) { return ui.id === props.uid; })) === null || _b === void 0 ? void 0 : _b.color);
            }
            else {
                var foundUpdateItem = profileImages.find(function (ui) { return ui.id === props.uid; });
                if (foundUpdateItem === undefined) {
                    foundUpdateItem = {
                        id: props.uid,
                        base64image: null,
                        base64image48: null,
                        color: color
                    };
                }
                else {
                    foundUpdateItem = __assign(__assign({}, foundUpdateItem), { color: color });
                }
                dispatch(ProfileListStore.AddUpdateItem(foundUpdateItem));
                // LocalSessionService.Set(sessionKeyColor, color as string);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uid, props.type, props.base64image]);
    if (props.fontIconOverride !== undefined) {
        return (React.createElement("div", { className: "profile-picture icon size-" + Size + " color-" + color, onClick: function () {
                if (props.onClick !== undefined) {
                    props.onClick();
                }
            }, "data-uid": props.uid },
            React.createElement("i", { className: props.fontIconOverride }),
            props.overlay ? React.createElement(ProfileOverlay, __assign({}, props.overlay)) : null));
    }
    else if (loading && props.uid !== undefined) {
        return (React.createElement("div", { className: "profile-picture loader size-" + Size, onClick: function () {
                if (props.onClick !== undefined) {
                    props.onClick();
                }
            }, "data-uid": props.uid },
            React.createElement(AnimatedLoader, { size: Size === "xs" ? "xsmall" : Size === "sm" ? "small" : Size === "md" ? "med" : Size === "lg" ? "large" : "xsmall", theme: "dark" })));
    }
    else if (base64Image !== "") {
        return (React.createElement("div", { className: "profile-picture base-64 size-" + Size + (orchestryUserIds.some(function (uid) { return uid === props.uid; }) ? " orch-logo" : ""), onClick: function () {
                if (props.onClick !== undefined) {
                    props.onClick();
                }
            }, "data-uid": props.uid },
            React.createElement("div", { className: "img", style: { backgroundImage: "url('" + base64Image + "')" } }),
            React.createElement("img", { src: base64Image, alt: "" }),
            props.overlay ? React.createElement(ProfileOverlay, __assign({}, props.overlay)) : null));
    }
    else if (props.displayName !== undefined) {
        return (React.createElement("div", { className: "profile-picture two-letter size-" + Size + " color-" + color, onClick: function () {
                if (props.onClick !== undefined) {
                    props.onClick();
                }
            }, "data-uid": props.uid },
            React.createElement("span", null, getStringTwoLetterAcronym(props.displayName)),
            props.overlay ? React.createElement(ProfileOverlay, __assign({}, props.overlay)) : null));
    }
    else {
        return (React.createElement("div", { className: "profile-picture icon size-" + Size + " color-" + color, onClick: function () {
                if (props.onClick !== undefined) {
                    props.onClick();
                }
            }, "data-uid": props.uid },
            type === "User" ? React.createElement("i", { className: "la la-user" }) : null,
            type === "SharePoint" ? React.createElement(FontIcon, { iconString: "briefcase" }) : null,
            type === "Teams" ? React.createElement(FontIcon, { iconString: "users" }) : null,
            type === "Group" || type === "SecurityGroup" || type === "DistributionGroup" ? React.createElement(FontIcon, { iconString: "users" }) : null,
            props.overlay ? React.createElement(ProfileOverlay, __assign({}, props.overlay)) : null));
    }
});
export default OfficeProfilePicture;
