var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Async, { AsyncWait } from "app/components/common/async";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as FeatureCurrentStore from "app/store/features/current";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import "./settings-account.scss";
var Approvals = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(false), backToList = _a[0], setBackToList = _a[1];
    var _b = React.useState(""), currentAdminGroups = _b[0], setCurrentAdminGroups = _b[1];
    var _c = React.useState(""), currentApprovalGroups = _c[0], setCurrentApprovalGroups = _c[1];
    var _d = React.useState(""), currentWorkspaceRequestGroup = _d[0], setCurrentWorkspaceRequestGroup = _d[1];
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    var save = function () {
        var asyncCallType = AsyncCallType.Update;
        dispatch(TenantConfigurationCurrentStore.Update(__assign(__assign({}, currentTenantConfiguration), { AdminstratorAccessActiveDirectoryGroup: currentAdminGroups, DefaultTemplateApprovalGroup: currentApprovalGroups, AllowRequestsActiveDirectoryGroup: currentWorkspaceRequestGroup })));
        AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentTenantConfiguration.GUID).then(function () {
            setBackToList(true);
            dispatch(FeatureCurrentStore.Clear());
        });
    };
    return (React.createElement("div", { className: "settings-account fadein-500ms" },
        backToList && React.createElement(Redirect, { to: "/settings" }),
        React.createElement(PageHeader.SetLeftContent, { title: "Settings", subTitle: "Edit" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/" },
                React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
            React.createElement("div", { onClick: function () { return save(); }, style: { display: "inline-block" } },
                React.createElement(SubtitleButton, { title: "Save", variant: "success" }))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Started },
            React.createElement(Row, null,
                React.createElement(Col, { md: 8, lg: 6, xl: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 140, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 7 })),
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 140, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 7 })),
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 140, showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 60, marginTop: 7 }))))),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Succeeded },
            React.createElement(Row, null,
                React.createElement(Col, { md: 8, lg: 6, xl: 6 },
                    React.createElement(Portlet, null,
                        React.createElement(PortletBody, null, currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup !== undefined ? (React.createElement(FormUserGroupSelect, { allowMultiple: true, returnType: "usersAndGroups", defaultValue: currentTenantConfiguration.AdminstratorAccessActiveDirectoryGroup, label: "Administrator Access Users and Groups:", onChange: function (groups) {
                                setCurrentAdminGroups(groups);
                            } })) : null)),
                    !currentUserIsBeaconUser && (React.createElement(React.Fragment, null,
                        React.createElement(Portlet, null,
                            React.createElement(PortletBody, null, currentTenantConfiguration.DefaultTemplateApprovalGroup !== undefined ? (React.createElement(FormUserGroupSelect, { defaultValue: currentTenantConfiguration.DefaultTemplateApprovalGroup, allowMultiple: true, returnType: "users", label: "Default Template Approval Users:", onChange: function (groups) {
                                    setCurrentApprovalGroups(groups);
                                } })) : null)),
                        React.createElement(Portlet, null,
                            React.createElement(PortletBody, null, currentTenantConfiguration.AllowRequestsActiveDirectoryGroup !== undefined ? (React.createElement(FormUserGroupSelect, { defaultValue: currentTenantConfiguration.AllowRequestsActiveDirectoryGroup, allowMultiple: true, returnType: "groups", label: "Limit who can request Workspaces:", onChange: function (groups) {
                                    setCurrentWorkspaceRequestGroup(groups);
                                } })) : null)))))))));
});
export default Approvals;
