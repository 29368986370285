var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import OrchestryLogo from "app/assets/img/logo-orchestry-small.png";
import AnimatedLoader from "app/components/common/animated-loader";
import Async from "app/components/common/async";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import InstallerStepUpdate from "app/components/installer/installer-wizard/steps/1_update";
import InstallerStepWelcome from "app/components/installer/installer-wizard/steps/1_welcome";
import InstallerStepTerms from "app/components/installer/installer-wizard/steps/2_terms";
import InstallerStepInformation from "app/components/installer/installer-wizard/steps/3_information";
import InstallerStepRegion from "app/components/installer/installer-wizard/steps/5_region-selection";
import InstallerStepLoginPermissions from "app/components/installer/installer-wizard/steps/6_login-permissions";
import InstallerStepConfigure from "app/components/installer/installer-wizard/steps/7_configure";
import InstallerStepInstall from "app/components/installer/installer-wizard/steps/8_install";
import InstallerStepConfirm from "app/components/installer/installer-wizard/steps/9_confirm";
import InstallerStepLifecycle from "app/components/installer/installer-wizard/steps/ARC_lifecycle";
import TenantService from "app/services/tenant";
import * as AsyncTypes from "app/store/async";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { emitCustomEvent } from "react-custom-events";
import { useDispatch, useSelector } from "react-redux";
import "./installer-wizard.scss";
import InstallerStepLicenseKey from "./steps/0_access-code/access-code.component";
import InstallerStepUpgradeDowngrade from "./steps/1_upgrade-downgrade";
import InstallerStepPartnerAssociation from "./steps/4_parter-association/partner-association.component";
export var InstallerMode;
(function (InstallerMode) {
    InstallerMode["Install"] = "Install";
    InstallerMode["Update"] = "Update";
    InstallerMode["LicenseUpgradeOrchestryTrial"] = "LicenseUpgradeOrchestryTrial";
    InstallerMode["LicenseUpgradeOrchestryFull"] = "LicenseUpgradeOrchestryFull";
    InstallerMode["LicenseDowngradeBeacon"] = "LicenseDowngradeBeacon";
})(InstallerMode || (InstallerMode = {}));
export var InstallWizardContext = React.createContext({});
var InstallerWizard = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _a = React.useState(false), hasAgreedToTerms = _a[0], setHasAgreedToTerms = _a[1];
    var _b = React.useState(true), isUserGlobalAdmin = _b[0], setIsUserGlobalAdmin = _b[1];
    var _c = React.useState(false), termsReachedBottom = _c[0], setTermsReachedBottom = _c[1];
    var _d = React.useState(""), accessCode = _d[0], setAccessCode = _d[1];
    var _e = React.useState(false), tenantCacheCleared = _e[0], setTenantCacheCleared = _e[1];
    var _f = React.useState(null), serviceAppConfig = _f[0], setServiceAppConfig = _f[1];
    var _g = React.useState(null), licenseChange = _g[0], setLicenseChange = _g[1];
    React.useEffect(function () {
        // if(orchestryContext !== undefined && orchestryContext.TenantGuid !== undefined){
        //     dispatch(TenantCurrentStore.LoadWithInfo(orchestryContext.TenantGuid));
        //     dispatch(TenantConfigurationCurrentStore.Load());
        // } else {
        //     dispatch(TenantCurrentStore.LoadWithInfo(""));
        // }
        dispatch(TenantConfigurationCurrentStore.Load());
        dispatch(TenantCurrentStore.LoadWithInfo(""));
        //   InstallService.isUserGlobalAdmin().then((isGlobalAdmin:boolean) => {
        //    setIsUserGlobalAdmin(isGlobalAdmin);
        //   })
    }, []);
    React.useEffect(function () {
        if (originalSteps.some(function (s) { return s.key === "service"; })) {
            iwc.setStepProgress("service", hasAgreedToTerms);
        }
    }, [hasAgreedToTerms]);
    var originalSteps = props.mode === InstallerMode.Install
        ? [
            {
                title: "License Key",
                subtitle: "",
                key: "access-code",
                canProgress: false,
                current: true,
                component: InstallerStepLicenseKey,
                icon: "fa-regular fa-key"
            },
            {
                title: "Welcome",
                subtitle: "",
                key: "welcome",
                canProgress: false,
                current: false,
                component: InstallerStepWelcome,
                icon: "fa-regular fa-square-check"
            },
            {
                title: "Terms of Service",
                subtitle: "",
                key: "service",
                canProgress: false,
                current: false,
                component: InstallerStepTerms,
                icon: "fa-regular fa-memo-circle-check"
            },
            {
                title: "About You",
                subtitle: "",
                key: "information",
                canProgress: false,
                current: false,
                component: InstallerStepInformation,
                icon: "fa-regular fa-image-user"
            },
            {
                title: "Partner Association",
                subtitle: "",
                key: "partner",
                canProgress: false,
                current: false,
                component: InstallerStepPartnerAssociation,
                icon: "fa-regular fa-users"
            },
            {
                title: "Region",
                subtitle: "",
                key: "region-selection",
                canProgress: false,
                current: false,
                component: InstallerStepRegion,
                icon: "fa-regular fa-location-dot"
            },
            {
                title: "Application Permissions",
                //subtitle: "Grant our software access to your organization's Office 365",
                key: "login-permissions",
                canProgress: false,
                current: false,
                component: InstallerStepLoginPermissions,
                icon: "fa-regular fa-lock"
            },
            //   {
            //      title: "Functionality",
            //      subtitle: "",
            //      key: "lifecycle",
            //      canProgress: false,
            //      current: false,
            //      component: InstallerStepLifecycle,
            //      icon: "flaticon-infinity"
            //   },
            {
                title: "Configure",
                //subtitle: "Provide information to setup Orchestry",
                key: "configure",
                canProgress: false,
                current: false,
                component: InstallerStepConfigure,
                icon: "fa-regular fa-gear"
            },
            {
                title: "Install",
                //subtitle: "Setup SharePoint and MS Teams to work with Orchestry",
                key: "install",
                canProgress: false,
                current: false,
                component: InstallerStepInstall,
                icon: "fa-sharp fa-regular fa-circle-bolt"
            },
            {
                title: "Confirm",
                //subtitle: "Make sure everything is installed and you're good to go!",
                key: "confirm",
                canProgress: false,
                current: false,
                component: InstallerStepConfirm,
                icon: "fa-regular fa-thumbs-up"
            }
        ]
        : props.mode === InstallerMode.Update
            ? [
                {
                    title: "Update required",
                    //subtitle: "Please update Orchestry",
                    key: "upgrade-message",
                    canProgress: true,
                    current: true,
                    component: InstallerStepUpdate,
                    icon: "fa-regular fa-circle-arrow-up"
                },
                {
                    title: "Grant updated permissions",
                    //subtitle: "Grant Orchestry updated consent",
                    key: "login-permissions",
                    canProgress: false,
                    current: false,
                    component: InstallerStepLoginPermissions,
                    icon: "fa-regular fa-lock"
                },
                {
                    title: "Update",
                    //subtitle: "Updated Orchestry to the latest version",
                    key: "install",
                    canProgress: false,
                    current: false,
                    component: InstallerStepInstall,
                    icon: "fa-sharp fa-regular fa-circle-bolt"
                },
                {
                    title: "Confirm",
                    //subtitle: "Make sure everything is installed and you're good to go!",
                    key: "confirm",
                    canProgress: false,
                    current: false,
                    component: InstallerStepConfirm,
                    icon: "fa-regular fa-thumbs-up"
                }
            ]
            : props.mode === InstallerMode.LicenseDowngradeBeacon || props.mode === InstallerMode.LicenseUpgradeOrchestryTrial || props.mode === InstallerMode.LicenseUpgradeOrchestryFull
                ? [
                    {
                        title: props.mode === InstallerMode.LicenseUpgradeOrchestryTrial || props.mode === InstallerMode.LicenseUpgradeOrchestryFull ? "Upgrade to Orchestry" : "Downgrade to Beacon",
                        //subtitle: "Please update Orchestry",
                        key: "upgrade-downgrade-message",
                        canProgress: true,
                        current: true,
                        component: InstallerStepUpgradeDowngrade,
                        icon: props.mode === InstallerMode.LicenseUpgradeOrchestryTrial || props.mode === InstallerMode.LicenseUpgradeOrchestryFull
                            ? "fa-regular fa-circle-arrow-up"
                            : "fa-regular fa-circle-arrow-down"
                    },
                    {
                        title: "Grant permissions",
                        //subtitle: "Grant Orchestry updated consent",
                        key: "login-permissions",
                        canProgress: false,
                        current: false,
                        component: InstallerStepLoginPermissions,
                        icon: "fa-regular fa-lock"
                    },
                    {
                        title: "Confirm",
                        //subtitle: "Make sure everything is installed and you're good to go!",
                        key: "confirm",
                        canProgress: false,
                        current: false,
                        component: InstallerStepConfirm,
                        icon: "fa-regular fa-thumbs-up"
                    }
                ]
                : [];
    var _h = React.useState({}), tenantConfig = _h[0], setTenantConfig = _h[1];
    //const[grantedConsent,setGrantedConsent] = React.useState(false);
    var _j = React.useState({
        DisplayName: ""
    }), tenantInformation = _j[0], setTenantInformation = _j[1];
    var _k = React.useState(originalSteps), steps = _k[0], setSteps = _k[1];
    React.useEffect(function () {
        if (props.mode === InstallerMode.Update) {
            if (!steps.some(function (s) { return s.key === "lifecycle"; })) {
                if (currentTenant !== undefined && currentTenant !== null) {
                    if (currentTenant.LastInstalledVersion !== null) {
                        if (currentTenant.LastInstalledVersion !== undefined && currentTenant.LastInstalledVersion !== null && Number(currentTenant.LastInstalledVersion) < 10100) {
                            var newSteps = __spreadArray([], steps, true);
                            newSteps.splice(2, 0, {
                                title: "Lifecycle",
                                subtitle: "",
                                key: "lifecycle",
                                canProgress: false,
                                current: false,
                                component: InstallerStepLifecycle,
                                icon: "flaticon-infinity"
                            });
                            setSteps(__spreadArray([], newSteps, true));
                            dispatch(TenantCurrentStore.Update({ LifecycleEnabled: true }));
                            console.log("new steps", newSteps);
                        }
                    }
                }
            }
        }
    }, [currentTenant]);
    var setStepProgress = function (stepKey, canProgress) {
        var newSteps = [];
        steps.forEach(function (step) {
            if (step.key === stepKey) {
                newSteps.push(__assign(__assign({}, step), { canProgress: canProgress }));
            }
            else {
                newSteps.push(__assign({}, step));
            }
        });
        setSteps(newSteps);
    };
    var setMultipleStepProgress = function (stepKeys, canProgress) {
        var newSteps = [];
        steps.forEach(function (step) {
            if (stepKeys.some(function (sk) { return sk === step.key; })) {
                newSteps.push(__assign(__assign({}, step), { canProgress: canProgress }));
            }
            else {
                newSteps.push(__assign({}, step));
            }
        });
        setSteps(newSteps);
    };
    React.useEffect(function () {
        if (currentTenant !== undefined && currentTenant.GUID !== undefined) {
            if (currentTenant.TenantInformation === null) {
                dispatch(TenantCurrentStore.UpdateInformation({ SignupMailingList: true }));
            }
        }
    }, [currentTenant]);
    var setCurrentStep = function (stepKey) {
        var newSteps = [];
        steps.forEach(function (step) {
            var didMatch = false;
            if (stepKey !== undefined) {
                if (step.key === stepKey)
                    didMatch = true;
            }
            if (didMatch) {
                newSteps.push(__assign(__assign({}, step), { current: true }));
            }
            else {
                newSteps.push(__assign(__assign({}, step), { current: false }));
            }
        });
        setSteps(newSteps);
    };
    var getCurrentStep = function () {
        var foundStep = steps.find(function (e) { return e.current === true; });
        return foundStep !== undefined ? foundStep : {};
    };
    var gotoPrevStep = function () {
        var prevStep = steps[getIndexOfCurrentStep() - 1];
        setCurrentStep(prevStep.key);
        window.scrollTo(0, 0);
    };
    var gotoNextStep = function () {
        // Send data on step 3.
        if (getCurrentStep().key === "information" || getCurrentStep().key === "access-code") {
            dispatch(TenantCurrentStore.UpdateSilent());
        }
        // if(nextStep.key === "configure"){
        //     //updateTenantInformation();
        // }
        var nextStep = steps[getIndexOfCurrentStep() + 1];
        setCurrentStep(nextStep.key);
        window.scrollTo(0, 0);
    };
    var getIndexOfCurrentStep = function () {
        var currentStepKey = getCurrentStep().key;
        var foundIndex = 0;
        steps.forEach(function (stp, stpIndex) {
            if (stp.key === currentStepKey) {
                foundIndex = stpIndex;
            }
        });
        return foundIndex;
    };
    var iwc = {
        rootProps: props,
        steps: steps,
        setStepProgress: setStepProgress,
        setCurrentStep: setCurrentStep,
        getCurrentStep: getCurrentStep,
        gotoPrevStep: gotoPrevStep,
        gotoNextStep: gotoNextStep,
        tenantConfig: tenantConfig,
        setTenantConfig: setTenantConfig,
        tenantInformation: tenantInformation,
        setTenantInformation: setTenantInformation,
        currentTenantConfiguration: currentTenantConfiguration,
        dispatch: dispatch,
        force: props.force === undefined ? false : props.force,
        hasAgreedToTerms: hasAgreedToTerms,
        setHasAgreedToTerms: setHasAgreedToTerms,
        onClose: props.onClose,
        setTermsReachedBottom: setTermsReachedBottom,
        termsReachedBottom: termsReachedBottom,
        setMultipleStepProgress: setMultipleStepProgress,
        accessCode: accessCode,
        setAccessCode: setAccessCode,
        serviceAppConfig: serviceAppConfig,
        setServiceAppConfig: setServiceAppConfig,
        mode: props.mode,
        licenseChange: licenseChange,
        setLicenseChange: setLicenseChange
        //setGrantedConsent:setGrantedConsent,
        //grantedConsent:grantedConsent
    };
    React.useEffect(function () {
        var currentStep = getCurrentStep();
        if (currentStep !== null && currentStep.key === "confirm") {
            TenantService.clearTenantCache().then(function () {
                setTenantCacheCleared(true);
            });
        }
    }, [steps]);
    return (React.createElement("div", { className: "installer-wizard kt-portlet" },
        React.createElement(InstallWizardContext.Provider, { value: iwc },
            React.createElement("div", { className: "kt-portlet__head" },
                React.createElement("div", { className: "kt-portlet__head-label" },
                    React.createElement("h3", { className: "kt-portlet__head-title" },
                        React.createElement("div", { className: "orch-logo" },
                            React.createElement("img", { className: "logo", src: OrchestryLogo, alt: "Orchestry" })),
                        " ",
                        "Orchestry ",
                        props.mode == InstallerMode.Update ? "Update" : "Install"))),
            React.createElement("div", { className: "kt-portlet__body kt-portlet__body--fit" }, isUserGlobalAdmin === undefined ? (React.createElement("div", { className: "admin-check-loading" },
                React.createElement(AnimatedLoader, { size: "xsmall" }))) : (React.createElement(React.Fragment, null, isUserGlobalAdmin === true ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "kt-grid  kt-wizard-v2 kt-wizard-v2--white", id: "kt_wizard_v2", "data-ktwizard-state": "first" },
                    React.createElement("div", { className: "kt-grid__item kt-wizard-v2__aside" },
                        React.createElement("div", { className: "kt-wizard-v2__nav" },
                            React.createElement("div", { className: "kt-wizard-v2__nav-items kt-wizard-v2__nav-items--clickable" }, steps.map(function (daStep, stepIndex) { return (React.createElement("div", { key: stepIndex, onClick: function () {
                                    if (daStep.canProgress) {
                                        setCurrentStep(daStep.key);
                                    }
                                }, className: "kt-wizard-v2__nav-item", "data-ktwizard-type": "step", "data-ktwizard-state": (daStep.key === getCurrentStep().key ? "current" : "") +
                                    (stepIndex < getIndexOfCurrentStep() ? "done" : "") +
                                    (stepIndex > getIndexOfCurrentStep() ? "pending" : "") },
                                React.createElement("div", { className: "kt-wizard-v2__nav-body" },
                                    React.createElement("div", { className: "kt-wizard-v2__nav-icon" },
                                        React.createElement("i", { className: daStep.icon })),
                                    React.createElement("div", { className: "kt-wizard-v2__nav-label" },
                                        React.createElement("div", { className: "kt-wizard-v2__nav-label-title" }, daStep.title),
                                        React.createElement("div", { className: "kt-wizard-v2__nav-label-desc" }, daStep.subtitle))))); })))),
                    React.createElement("div", { className: "kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper" },
                        React.createElement("form", { className: "kt-form", id: "kt_form" },
                            steps.map(function (daStep, daIndex) { return (React.createElement("div", { key: daIndex, className: "step-container" + (daStep.key === getCurrentStep().key ? " current" : "") + (daIndex < getIndexOfCurrentStep() ? " done" : "") }, daStep.key === getCurrentStep().key ? React.createElement(daStep.component, { step: daStep, key: daIndex, isUpgrade: props.mode !== InstallerMode.Install }) : React.createElement(React.Fragment, null))); }),
                            React.createElement("div", { className: "action-wrapper" },
                                React.createElement("div", { className: "kt-form__actions" },
                                    getIndexOfCurrentStep() !== 0 && steps.length !== getIndexOfCurrentStep() + 1 ? (React.createElement("div", { onClick: function () {
                                            gotoPrevStep();
                                        }, className: "btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" }, "Previous")) : (React.createElement("div", null)),
                                    React.createElement("button", { className: "btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u", "data-ktwizard-type": "action-submit" }, "Submit"),
                                    steps.length !== getIndexOfCurrentStep() + 1 ? (React.createElement(React.Fragment, null, getCurrentStep().canProgress === true ? (React.createElement("div", { onClick: function () {
                                            gotoNextStep();
                                        }, className: "btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" }, "Next Step")) : (React.createElement("div", { className: "btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" }, "Next Step")))) : (React.createElement(React.Fragment, null, props.mode === InstallerMode.Install ? (React.createElement(React.Fragment, null,
                                        React.createElement(Async, { uid: "OrchestryContext_Current_Load", status: AsyncTypes.AsyncStatus.Started, callType: AsyncTypes.AsyncCallType.Refresh },
                                            React.createElement(AnimatedLoader, { size: "small", theme: "dark" })),
                                        React.createElement(Async, { uid: "OrchestryContext_Current_Load", status: AsyncTypes.AsyncStatus.Succeeded, callType: AsyncTypes.AsyncCallType.Refresh },
                                            React.createElement("a", { href: "/" },
                                                React.createElement("div", { className: "btn btn-success use-orch-button" }, "Use Orchestry Now!"))))) : tenantCacheCleared === true ? (React.createElement("button", { type: "button", className: "subtitle-button color-orange btn btn-primary", onClick: function () {
                                            //@ts-ignore
                                            emitCustomEvent("orchestry-update-complete");
                                            if (iwc.onClose !== undefined) {
                                                iwc.onClose(true);
                                            }
                                        } }, "Close Window")) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38, bodyWidth: 100 }))))))))))) : (React.createElement("div", { className: "not-global-admin" },
                React.createElement(PortletAlert, { type: "danger", icon: "alert", title: "Global Administrator Required", message: "Sorry. Only a global administrator from your organization can install or upgrade Orchestry", size: "large" })))))))));
});
export default InstallerWizard;
