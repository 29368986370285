import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import React from "react";
import { useSelector } from "react-redux";
import "./team-information-logo.scss";
export var TeamInformationLogo = function () {
    var _a, _b;
    var teamContext = useSelector(function (state) { return state.teamInformationState.teamContext; });
    var teamInformationWebPartState = useSelector(function (state) { return state.teamInformationWebPartState; });
    if (((_a = teamInformationWebPartState === null || teamInformationWebPartState === void 0 ? void 0 : teamInformationWebPartState.workspaceInformationProps) === null || _a === void 0 ? void 0 : _a.showLogo) === false) {
        return null;
    }
    return (React.createElement("div", { className: "team-information-logo" }, teamContext === null ? (React.createElement("div", { className: "skeleton-logo" },
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 64, bodyWidth: 64 }))) : (React.createElement(React.Fragment, null,
        React.createElement(OfficeProfilePicture, { size: "lg", type: "Group", uid: (_b = teamContext === null || teamContext === void 0 ? void 0 : teamContext.team) === null || _b === void 0 ? void 0 : _b.groupId })))));
};
