var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import "./skeleton-loaders.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { Col, Row } from "react-bootstrap";
export var SkeletonLoaderFakeBox = React.memo(function (props) {
    return (React.createElement("div", { className: "skeleton-loader-common", style: __assign(__assign({}, props.style), { minWidth: typeof props.bodyWidth === "string" ? props.bodyWidth : "inherit" }) },
        React.createElement("div", { className: "fake-box skeleton-loader-animated-wave" + (props.outlineOnly !== undefined ? " outline-only" : ""), style: {
                minWidth: typeof props.bodyWidth === "number" ? (props.bodyWidth !== undefined ? props.bodyWidth : 0) + "px" : "inherit",
                minHeight: (props.bodyHeight !== undefined ? props.bodyHeight : 38) + "px",
                marginTop: (props.marginTop !== undefined ? props.marginTop : 0) + "px",
                marginBottom: (props.marginBottom !== undefined ? props.marginBottom : 0) + "px"
            } })));
});
export var SkeletonLoaderFakePortletForm = React.memo(function (props) {
    var fakeForms = (React.createElement(React.Fragment, null,
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginBottom: 26 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39, marginBottom: 26 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 19, marginBottom: 7 }),
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 39 })));
    return (React.createElement(Portlet, null,
        props.showTitle !== undefined && props.showTitle === true ? React.createElement(PortletHeader, null) : null,
        React.createElement(PortletBody, null, props.twoCols !== undefined && props.twoCols === true ? (React.createElement(Row, null,
            React.createElement(Col, { md: 6 }, fakeForms),
            React.createElement(Col, { md: 6 }, fakeForms))) : (fakeForms))));
});
export var SkeletonFakeTableRow = React.memo(function (props) {
    return (React.createElement(React.Fragment, null, props.repeatCols === undefined ? (React.createElement("div", { className: "skeleton-loader-common fake-table-row" }, props.children)) : (__spreadArray([], Array(props.repeatCols), true).map(function (e, i) { return React.createElement("div", { className: "skeleton-loader-common fake-table-row", key: "skeleton-fake-table-row-" + i }, props.children); }))));
});
export var SkeletonFakeTableCol = React.memo(function (props) {
    return (React.createElement("div", { className: "skeleton-loader-common fake-table-col skeleton-loader-animated-wave", style: { width: props.widthPercent + "%", flex: props.widthPercent === undefined ? 1 : undefined } }, props.children));
});
export var SkeletonLoaderFakeTextSmall = React.memo(function (props) {
    return (React.createElement("div", { className: "skeleton-loader-common" },
        React.createElement("div", { className: "fake-text-small skeleton-loader-animated-wave", style: { minHeight: props.bodyHeight + "px", marginTop: props.marginTop + "px" } })));
});
export var SkeletonLoaderFakeUserItem = React.memo(function (props) {
    return (React.createElement("div", { className: "skeleton-loader-common" },
        React.createElement("div", { className: "fake-user-item", style: { marginTop: props.marginTop + "px" } },
            React.createElement("div", { className: "pic" },
                React.createElement("div", { className: "circle skeleton-loader-animated-wave" })),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: "title" },
                    React.createElement("div", { className: "title-box skeleton-loader-animated-wave" })),
                React.createElement("div", { className: "property" },
                    React.createElement("div", { className: "property-box" })),
                React.createElement("div", { className: "property" },
                    React.createElement("div", { className: "property-box" }))))));
});
export var SkeletonLoaderPortlet = React.memo(function (props) {
    return (React.createElement("div", { className: "skeleton-loader-portlet skeleton-loader-common" },
        React.createElement(Portlet, null,
            props.showHeader !== undefined && props.showHeader === false ? null : (React.createElement(PortletHeader, null,
                React.createElement("div", { className: "kt-portlet__head-label" },
                    React.createElement("div", { className: "fake-text-h3 skeleton-loader-animated-wave" })))),
            React.createElement(PortletBody, { style: props.bodyHeight !== undefined ? { minHeight: props.bodyHeight + "px" } : {} }, props.children))));
});
