/**
 * The schedule cadence of the health check.
 */
export var HealthChecksSettingsScheduleCadence;
(function (HealthChecksSettingsScheduleCadence) {
    /**
     * The health check is ran monthly.
     */
    HealthChecksSettingsScheduleCadence["Monthly"] = "Monthly";
    /**
     * The health check is ran quarterly.
     */
    HealthChecksSettingsScheduleCadence["Quarterly"] = "Quarterly";
    /**
     * The health check is ran semi-annually.
     */
    HealthChecksSettingsScheduleCadence["SemiAnnually"] = "SemiAnnually";
    /**
     * The health check is ran yearly.
     */
    HealthChecksSettingsScheduleCadence["Annually"] = "Annually";
})(HealthChecksSettingsScheduleCadence || (HealthChecksSettingsScheduleCadence = {}));
