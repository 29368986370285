import * as React from "react";
import "./report-paging.scss";
import { useSelector, useDispatch } from "react-redux";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { Form } from "react-bootstrap";
var ReportPaging = React.memo(function (props) {
    var dispatch = useDispatch();
    var lifecycleReport = useSelector(function (state) { return state.lifecycleReportState[props.Slot]; });
    var _a = useSelector(function (state) { return state.lifecycleReportState[props.Slot]; }), Paging = _a.Paging, ItemCount = _a.ItemCount;
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    React.useEffect(function () {
        setLoading(false);
        // console.log("Paging Loading Complete");
    }, [lifecycleReport]);
    React.useEffect(function () {
        window.scrollTo(0, 0);
    }, [Paging.CurrentPage]);
    var prevClick = function () {
        if (loading === false) {
            setLoading(true);
            // const newCurrentPageUrl = lifecycleReport.Paging.PrevPageUrls[lifecycleReport.Paging.PrevPageUrls.length - 1];
            var newCurrentPage = lifecycleReport.Paging.CurrentPage !== undefined ? lifecycleReport.Paging.CurrentPage - 1 : 1;
            // console.log("newCurrentPage",newCurrentPage);
            // const newPrevPageUrls = lifecycleReport.Paging.PrevPageUrls;
            // var remove = newPrevPageUrls.pop();
            // console.log("new Previous page urls", newPrevPageUrls);
            // //const newPrevPageUrls:string[] = [];
            // const newNextPageUrl = lifecycleReport.Paging.CurrentPageUrl;
            dispatch(LifecycleReportStore.UpdatePaging({
                CurrentPageUrl: "",
                NextPageUrl: "",
                PrevPageUrls: [],
                // CurrentPageUrl: newCurrentPageUrl,
                CurrentPage: newCurrentPage,
                // //@ts-ignore
                // PrevPageUrls : newPrevPageUrls,
                // NextPageUrl : newNextPageUrl,
                ResultsPerPage: lifecycleReport.Paging.ResultsPerPage
            }, props.Slot));
        }
    };
    var nextClick = function () {
        if (loading === false) {
            setLoading(true);
            // const newCurrentPageUrl = lifecycleReport.Paging.NextPageUrl;
            var newCurrentPage = lifecycleReport.Paging.CurrentPage !== undefined ? lifecycleReport.Paging.CurrentPage + 1 : 1;
            // const newPrevPageUrls = lifecycleReport.Paging.PrevPageUrls !== undefined ? [ ...lifecycleReport.Paging.PrevPageUrls, lifecycleReport.Paging.CurrentPageUrl] : [lifecycleReport.Paging.CurrentPageUrl];
            // const newNextPageUrl = "loading";
            dispatch(LifecycleReportStore.UpdatePaging({
                CurrentPageUrl: "",
                NextPageUrl: "",
                PrevPageUrls: [],
                // CurrentPageUrl: newCurrentPageUrl,
                CurrentPage: newCurrentPage,
                //@ts-ignore
                // PrevPageUrls : newPrevPageUrls,
                // NextPageUrl : newNextPageUrl,
                ResultsPerPage: lifecycleReport.Paging.ResultsPerPage
            }, props.Slot));
        }
    };
    var numberClick = function (pageNumberIndex) {
        // console.log("paging", lifecycleReport.Paging.CurrentPage, pageNumberIndex);
        // if (lifecycleReport.Paging.CurrentPage !== pageNumberIndex) {
        if (loading === false) {
            setLoading(true);
            //@ts-ignore
            // const newCurrentPageUrl = lifecycleReport.Paging.PrevPageUrls[pageNumberIndex];
            var newCurrentPage = pageNumberIndex + 1;
            //@ts-ignore
            // const newPrevPageUrls = lifecycleReport.Paging.PrevPageUrls.filter((pp:string,index:number)=> {
            //    if(index < pageNumberIndex){
            //       return true;
            //    }
            //    return false;
            // })
            // const newNextPageUrl = "loading";
            dispatch(LifecycleReportStore.UpdatePaging({
                CurrentPageUrl: "",
                NextPageUrl: "",
                PrevPageUrls: [],
                // CurrentPageUrl: newCurrentPageUrl,
                CurrentPage: newCurrentPage,
                //@ts-ignore
                // PrevPageUrls : newPrevPageUrls,
                // NextPageUrl : newNextPageUrl,
                ResultsPerPage: lifecycleReport.Paging.ResultsPerPage
            }, props.Slot));
        }
        // }
    };
    var getResultName = function () {
        var view = lifecycleReport.Views.find(function (v) { return v.Name === lifecycleReport.Context.View; });
        if (view && view.ResultName) {
            return view.ResultName;
        }
        else {
            return props.ResultName;
        }
    };
    var changePageSize = function (newSize) {
        dispatch(LifecycleReportStore.UpdatePaging({
            CurrentPage: 1,
            CurrentPageUrl: "",
            NextPageUrl: "",
            PrevPageUrls: [],
            TotalResults: 0,
            ResultsPerPage: Number(newSize)
        }, props.Slot));
    };
    return (React.createElement("div", { className: "lifecycle-report-paging" + (loading === true ? " dir-loading" : ""), "data-pw": "lifecycle-report-paging" },
        React.createElement("div", { className: "left" },
            React.createElement("div", { className: "pages-per-result" },
                React.createElement("span", null, "Records per page"),
                React.createElement(Form.Control, { as: "select", defaultValue: Paging.ResultsPerPage, onChange: function (e) {
                        changePageSize(e.currentTarget.value);
                    } },
                    React.createElement("option", { value: 10 }, "10"),
                    React.createElement("option", { value: 20 }, "20"),
                    React.createElement("option", { value: 30 }, "30"),
                    React.createElement("option", { value: 50 }, "50"),
                    React.createElement("option", { value: 100 }, "100"),
                    React.createElement("option", { value: 200 }, "200")))),
        React.createElement("div", { className: "middle" }, ItemCount !== null ? (React.createElement("div", { className: "total-count", "data-pw": "total-count" },
            ItemCount.toLocaleString(),
            " ",
            getResultName(),
            "(s)")) : null),
        React.createElement("div", { className: "right" },
            React.createElement("div", { className: "button-wrapper bw-prev " + (Paging.CurrentPage && Paging.CurrentPage > 1 ? "shown" : "hidden") },
                React.createElement("div", { className: "button-prev", onClick: function () { return prevClick(); } }, "Previous")),
            React.createElement("div", { className: "buttons-paging" },
                (function () {
                    var returnJSX = [];
                    var _loop_1 = function (i) {
                        if (i + 1 !== Paging.CurrentPage && i > Paging.CurrentPage - 4) {
                            returnJSX.push(React.createElement("span", { key: i },
                                React.createElement("div", { className: "page-number", onClick: function () { return numberClick(i); } }, i + 1)));
                        }
                    };
                    for (var i = 0; i < Paging.CurrentPage; i++) {
                        _loop_1(i);
                    }
                    return returnJSX;
                })(),
                Paging.CurrentPage !== 0 ? (React.createElement("span", null,
                    React.createElement("div", { className: "page-number current-number" + (Paging.CurrentPage === 1 ? " only-one" : "") }, Paging.CurrentPage))) : null,
                (function () {
                    var returnJSX = [];
                    var _loop_2 = function (i) {
                        if (i + 1 !== Paging.CurrentPage && ItemCount > i * Paging.ResultsPerPage) {
                            returnJSX.push(React.createElement("span", { key: i },
                                React.createElement("div", { className: "page-number", onClick: function () { return numberClick(i); } }, i + 1)));
                        }
                    };
                    for (var i = Paging.CurrentPage; i < Paging.CurrentPage + 2; i++) {
                        _loop_2(i);
                    }
                    return returnJSX;
                })()),
            React.createElement("div", { className: "button-wrapper bw-next " + (loading === true ? "loading" : ItemCount > Paging.CurrentPage * Paging.ResultsPerPage ? "shown" : "hidden") },
                React.createElement("div", { className: "button-next ", onClick: function () { return nextClick(); } }, "Next")))));
});
export default ReportPaging;
