var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LifecycleGuestPolicyCurrentActionTypes } from "./types";
var initialState = {
    item: {}
};
export function lifecycleGuestPoliciesCurrentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Load: {
            //console.log(LifecyclePolicyCurrentActionTypes.LifecyclePolicy_Current_Load, action)
            return {
                item: action.payload
            };
        }
        case LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Update: {
            return {
                item: __assign(__assign({}, state.item), action.payload)
            };
        }
        case LifecycleGuestPolicyCurrentActionTypes.LifecycleGuestPolicy_Current_Clear: {
            return {
                item: {}
            };
        }
        default:
            return state;
    }
}
