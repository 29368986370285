import * as React from "react";
import GroupItem from "app/components/common/group-item";
var GroupItems = React.memo(function (_a) {
    var groupsJsonString = _a.groupsJsonString;
    var groupArray = [];
    if (groupsJsonString !== undefined) {
        var tempArray = JSON.parse(groupsJsonString);
        if (Array.isArray(tempArray)) {
            groupArray = tempArray;
        }
    }
    return (React.createElement("div", { className: "group-items" }, groupArray !== undefined ? (React.createElement(React.Fragment, null, groupArray.map(function (g, gIndex) { return (React.createElement(GroupItem, { group: g, key: gIndex })); }))) : null));
});
export default GroupItems;
