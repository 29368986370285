import * as React from "react";
import "./guest-details-tab-overview.scss";
import { useSelector, useDispatch } from "react-redux";
import { ReportSearchSlotName, ReportDatasource } from "app/store/lifecycle-report";
import InsightsReport from "app/components/insights/common/report";
import { Portlet, PortletBody } from "app/components/common/portlet";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import { GuestManagementUserStatus } from "app/store/lifecycle-guest";
import OrchModal from "app/components/common/orch-modal";
import UsersGuestsService from "app/services/users-guests";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import { useIntl } from "react-intl";
var RemoveGuestFromWorkspacesMessage = function (_a) {
    var plural = _a.plural, workspaceDetails = _a.workspaceDetails;
    var intl = useIntl();
    return (React.createElement("div", null,
        React.createElement("p", null, intl.formatMessage({
            id: "actions.bulk.remove_guest_modal.body",
            defaultMessage: "Are you sure you'd like to remove {isplural, plural, one {this guest from} other {these guests from}}:"
        }, { isplural: plural ? 2 : 0 })),
        workspaceDetails.length > 1 ? (React.createElement("ul", null, workspaceDetails.map(function (detail) { return (React.createElement("li", null,
            React.createElement("strong", null, detail.siteName))); }))) : (React.createElement("strong", null, workspaceDetails[0].siteName))));
};
var LifecycleGuestDetailsTabOverview = React.memo(function (_a) {
    var guestId = _a.guestId, groupIds = _a.groupIds;
    var dispatch = useDispatch();
    var _b = React.useState(), currentReportItem = _b[0], setCurrentReportItem = _b[1];
    var guestCosmosItem = useSelector(function (state) { return state.lifecycleGuestState.cosmosItem; });
    var _c = React.useState(true), showReport = _c[0], setShowReport = _c[1];
    var _d = React.useState(false), processing = _d[0], setProcessing = _d[1];
    var _e = React.useState(null), bulkJobId = _e[0], setBulkJobId = _e[1];
    var navigateToDetails = function (reportItem) {
        setCurrentReportItem(reportItem);
    };
    var bulkApply = function (siteDetails, action) {
        var _a;
        if (siteDetails.length > 0 && action === "Remove") {
            if (siteDetails.length === 1) {
                setProcessing(true);
                UsersGuestsService.RemoveGuestFromWorkspace(siteDetails[0].guestId, (_a = siteDetails[0].groupId) !== null && _a !== void 0 ? _a : "").then(function (value) {
                    setProcessing(false);
                    dispatch(LifecycleReportStore.RemoveGuestFromWorkspacesSuccess());
                    dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestInsightsSlot));
                });
            }
            else {
                UsersGuestsService.BulkRemoveWorkspacesFromGuest({ siteDetails: siteDetails }).then(function (value) {
                    setBulkJobId(value.jobId);
                    setProcessing(true);
                });
            }
        }
    };
    var customBulkActionRemoveFromWorkspaces = function (context, selectedIds) {
        var removeWorkspaceDetails = selectedIds.map(function (id) {
            var _a;
            var workspaceDetails = context.Items.filter(function (item) { return item.GroupId === id; })[0];
            return { groupId: id, guestId: workspaceDetails === null || workspaceDetails === void 0 ? void 0 : workspaceDetails.UserId, siteName: (_a = workspaceDetails === null || workspaceDetails === void 0 ? void 0 : workspaceDetails.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosWorkspaceDetails.Title };
        });
        return (React.createElement(OrchModal, { modalClassName: "bulk-remove-workspaces-from-guest-modal", simpleButton: true, size: "sm", headerText: "Remove Guest from Workspace".concat(selectedIds.length > 1 ? "s" : ""), buttonContents: React.createElement("div", { className: "group" },
                React.createElement("div", { className: "button" },
                    React.createElement("i", { className: "fa-regular fa-user-xmark" }), "Remove Guest from Workspace".concat(selectedIds.length > 1 ? "s" : ""))), footerPrimaryText: "".concat(selectedIds.length > 1 ? "Remove from Workspaces" : "Remove from Workspace"), footerPrimaryFunction: function () {
                bulkApply(removeWorkspaceDetails, "Remove");
            } },
            React.createElement("div", { className: "column-popout-confirmation-dialog" },
                React.createElement("div", { className: "message" },
                    React.createElement(RemoveGuestFromWorkspacesMessage, { plural: selectedIds.length > 1, workspaceDetails: removeWorkspaceDetails })))));
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (bulkJobId != null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                UsersGuestsService.BulkRemoveWorkspacesFromGuestStatus(bulkJobId).then(function (data) {
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_1 = false;
                        runAgain();
                    }
                    else {
                        AsyncWait(undefined, AsyncStatus.Succeeded, null, null, "Report_Refresh").then(function (res) {
                            setProcessing(false);
                            dispatch(LifecycleReportStore.RemoveGuestFromWorkspacesSuccess());
                        });
                        setTimeout(function () {
                            dispatch(LifecycleReportStore.Refresh(ReportSearchSlotName.GuestMembershipsSlot));
                        }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (bulkJobId != null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID != null) {
                clearInterval(timerID);
            }
        };
    }, [bulkJobId]);
    React.useEffect(function () {
        if (guestCosmosItem != null) {
            if (guestCosmosItem.Status === GuestManagementUserStatus.Deleted) {
                if (showReport === true)
                    setShowReport(false);
            }
            else {
                if (showReport === false)
                    setShowReport(true);
            }
        }
    }, [guestCosmosItem]);
    return (React.createElement("div", { className: "lifecycle-guest-details-tab-overview", "data-pw": "guest-details-tab-overview" },
        React.createElement("div", { className: showReport === true ? "show-report" : "hide-report" },
            React.createElement(InsightsReport, { NavigateToDetails: navigateToDetails, ActionParam: guestId, FilterParam: "guest-workspace-memberships", Datasource: ReportDatasource.SQLServer, BasePath: "guests", SubPath: "listing", ResultName: "workspaces", NamePrefix: "", Slot: ReportSearchSlotName.GuestMembershipsSlot, ShowArchived: false, ShowExport: false, ShowFiltersAndSearch: false, ShowDeleted: false, ShowPagingOnlyIfXAmountOfItems: 50, NoResultFaIcon: "users", NoResultMainText: "No Guest Memberships To Display", NoResultSubText: "This guest does not have any pending or active memberships", InitialDynamicValues: { guestId: guestId }, ResultsDisabled: processing, CustomBulkActions: [customBulkActionRemoveFromWorkspaces] })),
        React.createElement("div", { className: showReport === false ? "show-report" : "hide-report" },
            React.createElement(Portlet, null,
                React.createElement(PortletBody, null, "This user has been deleted from Azure AD and has no active Microsoft365 Group memberships.")))));
});
export default LifecycleGuestDetailsTabOverview;
