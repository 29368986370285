/**
 * Collapses all whitespace in a string to a single space.
 * EG: "  foo   bar    baz  " -> "foo bar baz"
 *
 * @param str The string to collapse whitespace on.
 */
export var collapseWhitespace = function (str) { return str.replace(/\s+/g, " ").trim(); };
/**
 * Converts a string to a number by removing all non-numeric characters.
 *
 * @param input The string to convert to a number
 */
export var toNumber = function (input) {
    if (typeof input === "number")
        return input;
    var numericString = input.replace(/\D/g, "");
    return Number(numericString);
};
/**
 * Converts a number to a percentage string.
 *
 * @param value The value to convert to a percentage string.
 */
export var toDetailedPercentageLocaleString = function (value) { return value === null || value === void 0 ? void 0 : value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); };
/**
 * Converts a number to a simple percentage string.
 *
 * @param value The value to convert to a simple percentage string.
 */
export var toSimplePercentageLocaleString = function (value) { return value === null || value === void 0 ? void 0 : value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }); };
