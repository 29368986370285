import * as React from "react";
import "./workspace-bulk-reset-modal.scss";
import { useSelector, useDispatch } from "react-redux";
import OrchModal from "app/components/common/orch-modal";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import vivaIcon from "app/assets/img/icon-viva-engage.svg";
import InfoTooltip from "app/components/common/infotooltip";
import { checkValidArchivalStatus } from "./utils";
import LifecycleService from "app/services/lifecycle";
import * as LifecycleReportStore from "app/store/lifecycle-report/search";
import * as LifecyclePoliciesListStore from "app/store/lifecycle-policies/list";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AsyncStatus } from "app/store/async";
import { AsyncWait } from "app/components/common/async";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var LifecycleWorkspaceBulkResetModal = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(false), processing = _a[0], setProcessing = _a[1];
    var _b = React.useState(null), bulkResetJobId = _b[0], setBulkResetJobId = _b[1];
    var reportItems = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Items; });
    var viewName = useSelector(function (state) { return state.lifecycleReportState[props.Slot].Context.View; });
    var _c = React.useState(false), modalOpen = _c[0], setModalOpen = _c[1];
    var _d = React.useState(false), showComplete = _d[0], setShowComplete = _d[1];
    var archivalPolicies = useSelector(function (state) { return state.lifecyclePoliciesListState.items; });
    var _e = React.useState(false), archivalPoliciesLoaded = _e[0], setarchivalPoliciesLoaded = _e[1];
    var resettableWorkspaces = React.useMemo(function () {
        return reportItems.filter(function (item) { var _a; return ((_a = props.SiteReportItemIds) === null || _a === void 0 ? void 0 : _a.includes(item.SiteId)) && checkValidArchivalStatus(item.ArchivalStatus); });
    }, [props.SiteReportItemIds]);
    React.useEffect(function () {
        if (modalOpen === true) {
            setProcessing(false);
            setShowComplete(false);
        }
        if (archivalPolicies !== null && archivalPolicies !== undefined && archivalPolicies.length === 0 && modalOpen)
            dispatch(LifecyclePoliciesListStore.Load("Archival"));
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "LifecyclePolicy_List_Load").then(function () {
            setarchivalPoliciesLoaded(true);
        });
    }, [modalOpen]);
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 2000);
            };
            if (bulkResetJobId !== null) {
                shouldRun = true;
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                LifecycleService.GetBulkResetStatus(bulkResetJobId).then(function (data) {
                    if (data.status !== "Completed" && data.status !== "Failed") {
                        erroredOut_1 = false;
                        runAgain();
                    }
                    else {
                        setProcessing(false);
                        setModalOpen(false);
                        setShowComplete(true);
                        setTimeout(function () {
                            dispatch(LifecycleReportStore.Refresh(props.Slot));
                        }, 1000);
                    }
                });
            }
            else {
                runAgain();
            }
        }
        if (bulkResetJobId !== null) {
            watchForStatusUpdate();
        }
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [bulkResetJobId]);
    var onClickResetPolicies = function () {
        var policiesToReset = resettableWorkspaces.map(function (item) {
            return { ArchivalPolicyGuid: item.ArchivalPolicyGuid, SiteId: item.SiteId, IsProtected: item.IsProtected };
        });
        setProcessing(true);
        LifecycleService.BulkResetWorkspaces(policiesToReset).then(function (jobIdData) {
            setBulkResetJobId(jobIdData.jobId);
        });
    };
    return (React.createElement(OrchModal, { buttonContents: React.createElement(React.Fragment, null, props.children), simpleButton: true, headerText: "Reset Workspace(s)", size: "lg", modalClassName: "workspace-bulk-reset-modal", showModalOverride: modalOpen, showModalToggleOverride: function (shouldShow) { return setModalOpen(shouldShow); }, showCloseButton: processing === true ? false : true, footerCloseText: showComplete === true ? "Close" : processing === true ? undefined : "Cancel", footerContents: React.createElement(React.Fragment, null, showComplete === true ? null : processing === false ? (React.createElement(Button, { variant: "primary", disabled: resettableWorkspaces.length === 0, onClick: function () { return onClickResetPolicies(); } }, "Reset ".concat(resettableWorkspaces.length, " Workspace").concat(resettableWorkspaces.length > 1 ? "s" : ""))) : (React.createElement(Button, { variant: "secondary" }, "Resetting... Please wait..."))) }, React.createElement("table", null,
        React.createElement("thead", null,
            React.createElement("th", null, "Workspace Name"),
            React.createElement("th", null, "Current Archival Status"),
            React.createElement("th", null, "New Archival Status")),
        React.createElement("tbody", null, reportItems
            .filter(function (item) { var _a; return (_a = props.SiteReportItemIds) === null || _a === void 0 ? void 0 : _a.includes(item.SiteId); })
            .map(function (item) {
            var _a;
            return (React.createElement("tr", null,
                archivalPoliciesLoaded ? (React.createElement("td", null,
                    React.createElement("div", { className: "title-area" },
                        React.createElement("div", { className: "ta-icon" },
                            React.createElement(OfficeProfilePicture, { size: "xs", type: item.Application !== undefined && item.Application !== null ? (item.Application.indexOf("Site") > -1 ? "SharePoint" : "Teams") : "Teams", base64image: item.Base64Logo })),
                        React.createElement("div", { className: "m365-icon" }, item.Application === "VivaEngageCommunity" ? (React.createElement("img", { src: vivaIcon, title: "Viva Engage Community" })) : item.Application !== "Team" ? (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-sharepoint-24.png", title: item.Application })) : (React.createElement("img", { src: "/media/orchestry/svg-imgs/icon-teams-24.png", title: item.Application }))),
                        React.createElement("div", { title: item.Title, className: "ta-title" }, item.Title)))) : (React.createElement("td", null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }))),
                archivalPoliciesLoaded ? (React.createElement("td", null, item.ArchivalStatus)) : (React.createElement("td", null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }))),
                archivalPoliciesLoaded ? (React.createElement("td", null, checkValidArchivalStatus(item.ArchivalStatus) ? (determineNewArchivalState(item.ArchivalStatus, item.IsArchived, (_a = archivalPolicies.filter(function (policy) { return policy.GUID === item.ArchivalPolicyGuid; })[0]) === null || _a === void 0 ? void 0 : _a.SecondStage_Enabled)) : (React.createElement("div", null,
                    "No Change\u00A0\u00A0",
                    item.IsProtected ? React.createElement(Protected, null) : React.createElement(InvalidArchivalStatus, null))))) : (React.createElement("td", null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 })))));
        })))));
});
function determineNewArchivalState(archivalStatus, archivedState, secondStage) {
    if (secondStage === void 0) { secondStage = false; }
    var statusText = "";
    switch (archivalStatus) {
        case "Done":
            statusText = "Active";
            break;
        case "Extended":
            statusText = "Active";
            break;
        case "Failed":
            statusText = archivedState && secondStage ? "Done" : "Active";
            break;
        case "PendingApproval":
            statusText = "Active";
            break;
        default:
            statusText = "Active";
    }
    return (React.createElement("div", null,
        statusText,
        archivedState && secondStage && archivalStatus === "Failed" ? (React.createElement(React.Fragment, null,
            "\u00A0\u00A0",
            React.createElement(InfoTooltip, null,
                React.createElement("p", null, "Resetting a failed Second Step Archival will result in the workspace being set to be re-eligible for the Second Step."),
                React.createElement("p", null, "To completely reset the workspace back to Active, a second reset must be performed.")))) : null));
}
function Protected() {
    return (React.createElement(OverlayTrigger, { overlay: React.createElement(Tooltip, { id: "bulk-reset-protected-tooltip" },
            React.createElement("div", { className: "overlay-paragraph orchestry-tooltip" },
                React.createElement("p", null, "Workspace is protected, status cannot be reset."))) },
        React.createElement("i", { className: "fa fa-shield-alt" })));
}
function InvalidArchivalStatus() {
    return (React.createElement(InfoTooltip, null,
        React.createElement("div", null,
            React.createElement("p", null, "Only Workspace(s) with the following Archival Statuses can be reset:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Extended"),
                React.createElement("li", null, "Done"),
                React.createElement("li", null, "Failed"),
                React.createElement("li", null, "Pending Approval")))));
}
export default LifecycleWorkspaceBulkResetModal;
