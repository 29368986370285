import { Skeleton } from "@mantine/core";
import { OrchestryListItem } from "appv2/components/orchestry-list-item/orchestry-list-item.component";
import React from "react";
import "./recommendation-resolution-skeleton.scss";
export var RecommendationResolutionSkeleton = function () { return (React.createElement("div", { className: "recommendation-resolution-skeleton" },
    React.createElement(OrchestryListItem, null,
        React.createElement("div", { className: "left-section" },
            React.createElement(Skeleton, { circle: true, height: 32 }),
            React.createElement("div", { className: "metadata" },
                React.createElement(Skeleton, { height: 12.25, width: 209, radius: 17.5 }),
                React.createElement("div", { className: "metadata-footer" },
                    React.createElement(Skeleton, { height: 24, width: 130, radius: 16 }),
                    React.createElement(Skeleton, { ml: 8, height: 12.25, width: 678, radius: 17.5 })))),
        React.createElement(Skeleton, { circle: true, height: 28, mr: 32 })))); };
