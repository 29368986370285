/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import axios from "axios";
import Store from "app/store";
import App from "./App";
import AuthenticationService from "app/services/authentication";
import { handleMessageEvent } from "app/utils/messageHandlers";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss"; // Standard version
import "../src/appv2/assets/orchestry-icon-font/orchestry-icon-font.css";
// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// ORIGINAL ORCHESTRY SCSS
import "app/assets/common/common.scss";
import "app/assets/common/buttons.scss";
import "app/assets/common/checkboxes.scss";
import "app/assets/common/icons.scss";
import "app/assets/common/tables.scss";
import "app/assets/common/effects.scss";
import "app/assets/common/react-scrollbar.scss";
import "app/assets/common/tooltips.scss";
import "app/assets/common/metronic-overrides.scss";
import "app/assets/common/teams-tabs-common.scss";
import "app/assets/common/orchestry-brand.scss";
import "app/assets/common/is-in-teams-overrides.scss";
import "../src/appv2/assets/orchestry-icon-font/orchestry-icon-font.css";
import "app/services/app-insights";
//new react 18 mounting
var container = document.getElementById("root");
//@ts-ignore
var root = createRoot(container); // createRoot(container!) if you use TypeScript
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
var PUBLIC_URL = process.env.PUBLIC_URL;
console.log("process.env", process.env);
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ mockAxios(axios);
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
AuthenticationService.setupAxiosAuthInterceptors(axios, Store);
/**
 * Listen for post messages from a parent window
 */
window.addEventListener("message", handleMessageEvent);
root.render(React.createElement(App, { store: Store, 
    //persistor={persistor}
    basename: PUBLIC_URL }));
