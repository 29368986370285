var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./form-picklist.scss";
import "react-tagsinput/react-tagsinput.css";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
var Picklist = React.memo(function (props) {
    var _a = React.useState(""), currentItem = _a[0], setCurrentItem = _a[1];
    var getItems = function (picked) {
        return props.data.filter(function (d) { return d.Picked === picked; });
    };
    var getButtonState = function (type) {
        if (currentItem === "") {
            return "disabled";
        }
        else {
            var currentItemFound_1 = props.data.find(function (d) { return d.Identifier === currentItem; });
            if (currentItemFound_1 !== undefined && currentItemFound_1 !== null) {
                if (type === "move-left" && currentItemFound_1.Picked === true) {
                    return "disabled";
                }
                if (type === "move-right" && currentItemFound_1.Picked === false) {
                    return "disabled";
                }
                if (type === "move-up") {
                    var colItems = getItems(currentItemFound_1.Picked);
                    var isTop_1 = false;
                    colItems.forEach(function (ci, index) {
                        if (ci.Identifier === currentItemFound_1.Identifier) {
                            console.log("matched up move", index);
                            if (index === 0) {
                                isTop_1 = true;
                            }
                        }
                    });
                    if (isTop_1)
                        return "disabled";
                }
                if (type === "move-down") {
                    var colItems_1 = getItems(currentItemFound_1.Picked);
                    var isBot_1 = false;
                    colItems_1.forEach(function (ci, index) {
                        if (ci.Identifier === currentItemFound_1.Identifier) {
                            if (index === colItems_1.length - 1) {
                                isBot_1 = true;
                            }
                        }
                    });
                    if (isBot_1)
                        return "disabled";
                }
            }
        }
        return "";
    };
    var moveItem = function (type) {
        if (getButtonState(type) !== "disabled") {
            var currentItemFound = props.data.find(function (d) { return d.Identifier === currentItem; });
            if (currentItemFound !== undefined && currentItemFound !== null) {
                var leftSide = getItems(true);
                var rightSide = getItems(false);
                if (type === "move-left") {
                    rightSide = rightSide.filter(function (rs) { return rs.Identifier !== currentItem; });
                    leftSide.push(__assign(__assign({}, currentItemFound), { Picked: true }));
                }
                if (type === "move-right") {
                    leftSide = leftSide.filter(function (rs) { return rs.Identifier !== currentItem; });
                    rightSide.push(__assign(__assign({}, currentItemFound), { Picked: false }));
                }
                if (type === "move-up") {
                    if (currentItemFound.Picked) {
                        var currentIndex = leftSide.findIndex(function (i) { return i.Identifier === currentItem; });
                        leftSide.splice(currentIndex, 1);
                        leftSide.splice(currentIndex - 1, 0, currentItemFound);
                    }
                    if (!currentItemFound.Picked) {
                        var currentIndex = rightSide.findIndex(function (i) { return i.Identifier === currentItem; });
                        rightSide.splice(currentIndex, 1);
                        rightSide.splice(currentIndex - 1, 0, currentItemFound);
                    }
                }
                if (type === "move-down") {
                    if (currentItemFound.Picked) {
                        var currentIndex = leftSide.findIndex(function (i) { return i.Identifier === currentItem; });
                        leftSide.splice(currentIndex, 1);
                        leftSide.splice(currentIndex + 1, 0, currentItemFound);
                    }
                    if (!currentItemFound.Picked) {
                        var currentIndex = rightSide.findIndex(function (i) { return i.Identifier === currentItem; });
                        rightSide.splice(currentIndex, 1);
                        rightSide.splice(currentIndex + 1, 0, currentItemFound);
                    }
                }
                props.onChange(__spreadArray(__spreadArray([], leftSide, true), rightSide, true));
            }
        }
    };
    var doubleClick = function (pi) {
        console.log("Double Click");
        if (pi.Picked) {
            moveItem("move-right");
        }
        else {
            moveItem("move-left");
        }
    };
    var onItemClick = function (pi, evt) {
        if (pi.Identifier === currentItem) {
            doubleClick(pi);
        }
        else {
            setCurrentItem(pi.Identifier);
        }
        evt.stopPropagation();
    };
    React.useEffect(function () {
        if (props.onItemSelected !== undefined) {
            props.onItemSelected(currentItem);
        }
    }, [currentItem]);
    return (React.createElement("div", { className: "form-picklist", "data-pw": "form-picklist" },
        React.createElement("div", { className: "left list-col" },
            React.createElement("div", { className: "title shown" },
                React.createElement("span", null, props.leftTitle !== undefined ? props.leftTitle : "Shown")),
            React.createElement("div", { className: "content", onClick: function () { return setCurrentItem(""); } },
                React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: props.maxHeight !== undefined ? props.maxHeight : 300 }, getItems(true).map(function (pi, index) { return (React.createElement("div", { key: "form-picklist-" + index, className: "pick-item" + (pi.Identifier === currentItem ? " selected" : ""), onClick: function (evt) {
                        onItemClick(pi, evt);
                    } }, pi.Name)); })))),
        React.createElement("div", { className: "commands" },
            React.createElement("div", { className: "move-left " + getButtonState("move-left"), onClick: function () { return moveItem("move-left"); } },
                React.createElement("i", { className: "fas fa-arrow-left" })),
            React.createElement("div", { className: "move-right " + getButtonState("move-right"), onClick: function () { return moveItem("move-right"); } },
                React.createElement("i", { className: "fas fa-arrow-right" })),
            props.disableSort !== undefined && props.disableSort === true ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "move-up " + getButtonState("move-up"), onClick: function () { return moveItem("move-up"); } },
                    React.createElement("i", { className: "fas fa-arrow-up" })),
                React.createElement("div", { className: "move-down " + getButtonState("move-down"), onClick: function () { return moveItem("move-down"); } },
                    React.createElement("i", { className: "fas fa-arrow-down" }))))),
        React.createElement("div", { className: "right list-col" },
            React.createElement("div", { className: "title hidden" },
                React.createElement("span", null, props.rightTitle !== undefined ? props.rightTitle : "Hidden")),
            React.createElement("div", { className: "content", onClick: function () { return setCurrentItem(""); } },
                React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: props.maxHeight !== undefined ? props.maxHeight : 300 }, getItems(false).map(function (pi, index) { return (React.createElement("div", { key: "form-picklist-right-" + index, className: "pick-item" + (pi.Identifier === currentItem ? " selected" : ""), onClick: function (evt) {
                        onItemClick(pi, evt);
                    } }, pi.Name)); }))))));
});
export default Picklist;
