var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./guest-review-workflow-column-actions.scss";
import { GuestReviewApprovalContext } from "app/components/lifecycle/guests/workflow/review/guest-review-workflow-insight-report";
import { SubtitleButton } from "app/components/common/subtitle";
import InfoTooltip from "app/components/common/infotooltip";
import OrchModal from "app/components/common/orch-modal";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
var GuestReviewWorkflowColumnAction = function (props) {
    var _a, _b;
    var guestReviewContext = React.useContext(GuestReviewApprovalContext);
    var _c = React.useState(null), currentAction = _c[0], setCurrentAction = _c[1];
    var _d = React.useState(false), showCommentBox = _d[0], setShowCommentBox = _d[1];
    var intl = useIntl();
    React.useEffect(function () {
        console.log("Review actions updated", guestReviewContext.requestActions);
        if (guestReviewContext.requestActions !== undefined && guestReviewContext.requestActions.some(function (rq) { return rq.userId === props.Item.UserId; })) {
            setCurrentAction(guestReviewContext.requestActions.find(function (rq) { return rq.userId === props.Item.UserId; }));
            console.log(currentAction);
        }
        else {
            setCurrentAction(null);
        }
    }, [guestReviewContext.requestActions]);
    var setActionAction = function (action) {
        var tempAction = {
            userId: props.Item.UserId,
            action: action,
            comment: null
        };
        if (currentAction !== null) {
            tempAction = __assign(__assign({}, currentAction), { action: action });
        }
        guestReviewContext.addOrUpdateAction(tempAction);
    };
    var setActionComment = function (comment, action) {
        if (action === void 0) { action = null; }
        var tempAction = {
            userId: props.Item.UserId,
            action: action,
            comment: comment
        };
        if (currentAction !== null) {
            tempAction = __assign(__assign({}, currentAction), { comment: comment, action: action !== null ? action : currentAction.action });
        }
        guestReviewContext.addOrUpdateAction(tempAction);
    };
    var hasEmptyComment = function () {
        return currentAction === null || (currentAction !== null && (currentAction.comment === null || currentAction.comment === ""));
    };
    var isRemoved = function () {
        return currentAction !== null && currentAction.action !== null && currentAction.action === "Remove";
    };
    var removeButtonClick = function () {
        setActionAction("Remove");
        if (hasEmptyComment()) {
            setShowCommentBox(true);
        }
    };
    return (React.createElement("div", { className: "guest-review-workflow-column-action" },
        React.createElement("div", { className: "approve" }, currentAction !== null && currentAction.action === "Renew" ? (React.createElement(SubtitleButton, { noMargin: true, variant: "success", title: "Renew", faIcon: "circle-check" })) : (React.createElement("button", { title: "Renew", type: "button", className: "subtitle-button color-outline-green btn", onClick: function () { return setActionAction("Renew"); } },
            React.createElement("i", { className: "fa-light fa-circle-check", "aria-hidden": "true" }),
            intl.formatMessage({ id: "actions.bulk.lifecycle.renew", defaultMessage: "Renew" })))),
        React.createElement("div", { className: "reject" }, currentAction !== null && currentAction.action === "Remove" ? (React.createElement(SubtitleButton, { noMargin: false, variant: "danger", title: "Remove", faIcon: "circle-xmark" })) : (React.createElement("button", { title: "Remove", type: "button", className: "subtitle-button color-outline-red btn kt-margin-l-10", onClick: function () { return removeButtonClick(); } },
            React.createElement("i", { className: "fa-light fa-circle-xmark", "aria-hidden": "true" }),
            intl.formatMessage({ id: "actions.bulk.lifecycle.remove", defaultMessage: "Remove" }))
        // <SubtitleButton noMargin={false} color="outline-gray" title="Reject" faIcon="circle-xmark" onClick={() => setActionAction("Reject")} />
        )),
        React.createElement("div", { className: "comment" + (isRemoved() && hasEmptyComment() === true ? " required-comment" : "") },
            React.createElement(OrchModal, { simpleButton: true, size: "md", headerContents: React.createElement("h5", null,
                    "Please enter a comment about ", (_b = (_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) === null || _b === void 0 ? void 0 :
                    _b.DisplayName), showCloseButton: false, showModalOverride: showCommentBox, showModalToggleOverride: setShowCommentBox, footerContents: React.createElement(React.Fragment, null,
                    React.createElement(SubtitleButton, { title: "Close", onClick: function () { return setShowCommentBox(false); } })), 
                // footerPrimaryText={"Close"}
                // footerPrimaryFunction={() => setShowCommentBox(false)}
                buttonContents: React.createElement(React.Fragment, null, currentAction !== null && currentAction.comment !== null && currentAction.comment !== "" ? (React.createElement("i", { className: "fa-regular fa-message-check" })) : isRemoved() && hasEmptyComment() ? (React.createElement(InfoTooltip, { content: React.createElement(React.Fragment, null, intl.formatMessage({ id: "layout.teams_app.tabs.guest_review.comment_required", defaultMessage: "A comment is required for a removal" })) },
                    React.createElement("i", { className: "fa-regular fa-message-plus" }))) : (React.createElement("i", { className: "fa-regular fa-message-plus" }))) },
                React.createElement(Form.Control, { as: "textarea", value: currentAction !== null && currentAction.comment !== null ? currentAction.comment : "", onChange: function (event) { return setActionComment(event.currentTarget.value); } })))));
};
export default GuestReviewWorkflowColumnAction;
