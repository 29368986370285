import { Card, Group, Stack } from "@mantine/core";
import { convertUtcDatetimeToLocal, getReadableDate, getReadableDateFromNow } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryLicenseBadge } from "appv2/components/orchestry-license-badge/orchestry-license-badge.component";
import React from "react";
import { CardKeyValue } from "../card-key-value.component";
import { OrchestryCardTitle } from "../orchestry-card-title.component";
import { useTenantPage } from "../use-tenant-page.hook";
export var GeneralDetails = function () {
    var _a = useTenantPage(), _b = _a.tenantDetails, TenantName = _b.TenantName, Seats = _b.Seats, InstallDate = _b.InstallDate, RenewalDate = _b.RenewalDate, LicenseType = _b.LicenseType, isPageLoading = _a.isPageLoading;
    var convertedDate = convertUtcDatetimeToLocal(RenewalDate);
    var readableRenewalDate = RenewalDate ? "".concat(getReadableDate(convertedDate), " (").concat(getReadableDateFromNow(convertedDate.toISOString()), ")") : "No Renewal Date";
    return (React.createElement(Card, { variant: "default", p: 24, mih: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "General Details", icon: ORCH_ICONS.infoSquare, colorVariant: "success-7" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Tenant Name", value: TenantName }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Seats", value: Seats === null || Seats === void 0 ? void 0 : Seats.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "License Type", value: React.createElement(Group, { ml: 8 },
                    React.createElement(OrchestryLicenseBadge, { tenantLicenseStatus: LicenseType })) }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Install Date", value: getReadableDate(convertUtcDatetimeToLocal(InstallDate)) }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, keyString: "Renewal Date", value: readableRenewalDate }))));
};
