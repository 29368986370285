var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
import "./orchestry-admin-reports.scss";
import * as React from "react";
import * as TableList from "app/components/common/table-list";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
var OrchestryAdminReports = React.memo(function () {
    var _a = React.useState(null), reportItems = _a[0], setReportItems = _a[1];
    // const [sortBy, setSortBy] = React.useState("TenantDisplayName");
    // const [sortOrder, setSortOrder] = React.useState("descending");
    var _b = React.useState({
        SortBy: "TenantDisplayName",
        SortOrder: "descending"
    }), tableSort = _b[0], setTableSort = _b[1];
    React.useEffect(function () {
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/tenants/ChurnReport";
        // axiosConfig.url = "/api/Throttling/?tenantGuid=";
        axiosConfig.responseType = "json";
        axios(axiosConfig)
            .then(function (response) {
            setReportItems(response.data.sort(compare));
            //response.data response.data
        })
            .catch(function (response) { });
    }, []);
    function compare(a, b) {
        // console.log("table sorted");
        var direction = "ascending";
        if (tableSort.SortOrder !== undefined && tableSort.SortOrder !== null) {
            direction = tableSort.SortOrder;
        }
        if (a[tableSort.SortBy] < b[tableSort.SortBy]) {
            return direction === "ascending" ? -1 : 1;
        }
        if (a[tableSort.SortBy] > b[tableSort.SortBy]) {
            return direction === "ascending" ? 1 : -1;
        }
        return 0;
    }
    React.useEffect(function () {
        // console.log("table sort RAN");
        if (reportItems !== null) {
            //console.log(sortBy, sortOrder);
            setReportItems(__spreadArray([], reportItems.sort(compare), true));
        }
    }, [tableSort]);
    var ReloadData = function (columnName) {
        console.log("Reload Data", columnName);
        var newSort = __assign(__assign({}, tableSort), { SortBy: columnName });
        // If column name hasn't changed, flip ascending/descending variable
        if (columnName === tableSort.SortBy) {
            // setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
            newSort.SortOrder = newSort.SortOrder === "ascending" ? "descending" : "ascending";
        }
        else {
            newSort.SortOrder = "descending";
        }
        // Update column name
        setTableSort(newSort);
    };
    return (React.createElement("div", { className: "orchestry-admin-reports fadein-500ms" }, reportItems !== null ? (React.createElement(TableList.Context, { listItems: reportItems, wrappedInPortlet: false, showLoader: false, sortOrder: tableSort.SortOrder, sortBy: tableSort.SortBy },
        React.createElement(TableList.TableList, null,
            React.createElement(TableList.HeaderItem, { DataProperyName: "TenantGuid", sortable: true, ReloadData: ReloadData }, "Guid"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "TenantDisplayName", sortable: true, ReloadData: ReloadData }, "Name"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "InstallDate", sortable: true, ReloadData: ReloadData }, "Install Date"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "LifecycleEnabled", sortable: true, ReloadData: ReloadData }, "Lifecycle Enabled"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "LastGlobalAdminLogin", sortable: true, ReloadData: ReloadData }, "Last Global Admin Login"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "LastProvisioningJob", sortable: true, ReloadData: ReloadData }, "Last Provisioning Job"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "LastArchivalJob", sortable: true, ReloadData: ReloadData }, "Last Archival Job"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "ProvisionedLast12Months", sortable: true, ReloadData: ReloadData }, "Pro - 12 Months"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "ProvisionedLast6Months", sortable: true, ReloadData: ReloadData }, "Pro - 6 Months"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "ProvisionedLast3Months", sortable: true, ReloadData: ReloadData }, "Pro - 3 Months"),
            React.createElement(TableList.HeaderItem, { DataProperyName: "ProvisionedLast1Months", sortable: true, ReloadData: ReloadData }, "Pro - 1 Months"),
            React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "TenantGuid" }),
            React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "TenantDisplayName" }),
            React.createElement(TableList.ContentItemDate, { datetimeFormat: "LL", listDataProperyName: "InstallDate" }),
            React.createElement(TableList.ContentItemBooleanIcon, { listDataProperyName: "LifecycleEnabled" }),
            React.createElement(TableList.ContentItemDate, { datetimeFormat: "LL", listDataProperyName: "LastGlobalAdminLogin" }),
            React.createElement(TableList.ContentItemDate, { datetimeFormat: "LL", listDataProperyName: "LastProvisioningJob" }),
            React.createElement(TableList.ContentItemDate, { datetimeFormat: "LL", listDataProperyName: "LastArchivalJob" }),
            React.createElement(TableList.ContentItem, { listDataProperyName: "ProvisionedLast12Months" }),
            React.createElement(TableList.ContentItem, { listDataProperyName: "ProvisionedLast6Months" }),
            React.createElement(TableList.ContentItem, { listDataProperyName: "ProvisionedLast3Months" }),
            React.createElement(TableList.ContentItem, { listDataProperyName: "ProvisionedLast1Months" })))) : (React.createElement(SkeletonLoaderFakeBox, null))));
});
export default OrchestryAdminReports;
