export var LifecyclePolicyWorkspaceCurrentActionTypes;
(function (LifecyclePolicyWorkspaceCurrentActionTypes) {
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load"] = "LifecyclePolicyWorkspace_Current_Load";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_Status"] = "LifecyclePolicyWorkspace_Current_Load_Status";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_Stats"] = "LifecyclePolicyWorkspace_Current_Load_Stats";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_History"] = "LifecyclePolicyWorkspace_Current_Load_History";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_Links"] = "LifecyclePolicyWorkspace_Current_Load_Links";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_NotebookAndPlanner"] = "LifecyclePolicyWorkspace_Current_Load_NotebookAndPlanner";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_Logs"] = "LifecyclePolicyWorkspace_Current_Load_Logs";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_Members"] = "LifecyclePolicyWorkspace_Current_Load_Members";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_Members_ByGroupId"] = "LifecyclePolicyWorkspace_Current_Load_Members_ByGroupId";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_AvailablePolicies"] = "LifecyclePolicyWorkspace_Current_Load_AvailablePolicies";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_DefaultPolicies"] = "LifecyclePolicyWorkspace_Current_Load_DefaultPolicies";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_ReportItem"] = "LifecyclePolicyWorkspace_Current_Load_ReportItem";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_ArchivalPolicy"] = "LifecyclePolicyWorkspace_Current_Load_ArchivalPolicy";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Clear"] = "LifecyclePolicyWorkspace_Current_Clear";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Update"] = "LifecyclePolicyWorkspace_Current_Update";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Update_Properties"] = "LifecyclePolicyWorkspace_Current_Update_Properties";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Update_Properties_NoServer"] = "LifecyclePolicyWorkspace_Current_Update_Properties_NoServer";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Update_ReportItem"] = "LifecyclePolicyWorkspace_Current_Update_ReportItem";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_WorkflowSubmit"] = "LifecyclePolicyWorkspace_Current_WorkflowSubmit";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Set_Status_Override"] = "LifecyclePolicyWorkspace_Current_Set_Status_Override";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Set_LoadingNewStatus"] = "LifecyclePolicyWorkspace_Current_Set_LoadingNewStatus";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Load_DirectoryItem"] = "LifecyclePolicyWorkspace_Current_Load_DirectoryItem";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Clear_DirectoryItem"] = "LifecyclePolicyWorkspace_Current_Clear_DirectoryItem";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_GuestWorkflowSubmit_Action"] = "LifecyclePolicyWorkspace_Current_GuestWorkflowSubmit_Action";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_GuestReviewForced_Success"] = "LifecyclePolicyWorkspace_Current_GuestReviewForced_Success";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_DeleteGuest_Success"] = "LifecyclePolicyWorkspace_Current_DeleteGuest_Success";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_RemoveGuestFromWorkspace_Success"] = "LifecyclePolicyWorkspace_Current_RemoveGuestFromWorkspace_Success";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_ResendInvite_Success"] = "LifecyclePolicyWorkspace_Current_ResendInvite_Success";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_ResendInvite_Failure_DeletedUser"] = "LifecyclePolicyWorkspace_Current_ResendInvite_DeletedUser";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Update_NextETLCrawl"] = "LifecyclePolicyWorkspace_Current_Update_NextETLCrawl";
    LifecyclePolicyWorkspaceCurrentActionTypes["LifecyclePolicyWorkspace_Current_Update_IsLoading"] = "LifecyclePolicyWorkspace_Current_Update_IsLoading";
})(LifecyclePolicyWorkspaceCurrentActionTypes || (LifecyclePolicyWorkspaceCurrentActionTypes = {}));
