import { useCreateRecommendationSuggestionMutation } from "appv2/api/recommendations/recommendations.api";
import React from "react";
// The hook for the RecommendationShareSuggestionModal component
export var useRecommendationShareSuggestionModal = function () {
    var _a = useCreateRecommendationSuggestionMutation(), createSuggestion = _a[0], _b = _a[1], isLoading = _b.isLoading, isError = _b.isError, isSuccess = _b.isSuccess, data = _b.data, error = _b.error;
    var _c = React.useState(""), suggestionText = _c[0], setSuggestionText = _c[1];
    var _d = React.useState(false), isComplete = _d[0], setIsComplete = _d[1];
    // On click of the submit button
    var onSubmitClick = function () {
        createSuggestion({
            Suggestion: suggestionText
        });
    };
    // If the modal is open, reset the suggestion text and isComplete
    React.useEffect(function () {
        setSuggestionText("");
        setIsComplete(false);
    }, []);
    return {
        onSubmitClick: onSubmitClick,
        isComplete: isComplete,
        isLoading: isLoading,
        isError: isError,
        isSuccess: isSuccess,
        data: data,
        error: error,
        suggestionText: suggestionText,
        setSuggestionText: setSuggestionText
    };
};
