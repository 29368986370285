import DisableArea from "app/components/common/disable-area";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import NotificationContent from "app/components/common/notification-content";
import OrchModal from "app/components/common/orch-modal";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TableList from "app/components/common/table-list";
import CrawlerService from "app/services/crawler";
import LifecycleService from "app/services/lifecycle";
import OrchestryService from "app/services/orchestry";
import TenantDatabaseService from "app/services/tenant-database";
import formatDataSize from "app/utils/formatDataSize";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/fold/markdown-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import moment from "moment";
import * as React from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Row, Table } from "react-bootstrap";
import { Controlled as CodeMirror } from "react-codemirror2";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import OrchestryAdminEtlCrawlStatsWorkspaces from "./orchestry-admin-etl-crawl-stats-workspaces";
import "./orchestry-admin-etl-crawl-stats.scss";
require("codemirror/mode/javascript/javascript");
var OrchestryAdminETLStats = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(), activeRegions = _a[0], setActiveRegions = _a[1];
    var _b = React.useState(null), selectedRegion = _b[0], setSelectedRegion = _b[1];
    var _c = React.useState(), stats = _c[0], setStats = _c[1];
    var _d = React.useState(false), showDetailsModal = _d[0], setShowDetailsModal = _d[1];
    var _e = React.useState(null), selectTenantForDetails = _e[0], setSelectTenantForDetails = _e[1];
    var _f = React.useState(null), detailedTenantStats = _f[0], setDetailedTenantStats = _f[1];
    React.useEffect(function () {
        setDetailedTenantStats(null);
        if (selectTenantForDetails !== null) {
            LifecycleService.GetTenantDetailedETLStatus(selectTenantForDetails.TenantGuid).then(function (stats) {
                setDetailedTenantStats(stats);
            });
        }
    }, [selectTenantForDetails]);
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    React.useEffect(function () {
        if (activeRegions !== undefined) {
            setSelectedRegion(activeRegions[0].value);
        }
    }, [activeRegions]);
    React.useEffect(function () {
        if (selectedRegion !== null) {
            LifecycleService.GetTenantsETLStatus(selectedRegion).then(function (stats) {
                setStats(stats);
            });
        }
    }, [selectedRegion]);
    var refreshList = function () {
        setStats(undefined);
        if (selectedRegion !== null) {
            LifecycleService.GetTenantsETLStatus(selectedRegion).then(function (stats) {
                setStats(stats);
            });
        }
    };
    var startETLForTenant = function (tenantGuid, forceCrawlMode) {
        toast(React.createElement(NotificationContent, { text: "<span style='font-weight:500;'>Forcing ETL</span>", mode: "loading" }), {
            toastId: "etl-force-" + tenantGuid,
            autoClose: false,
            type: toast.TYPE.DEFAULT
        });
        LifecycleService.ForceETLForTenant(tenantGuid, forceCrawlMode).then(function () {
            setTimeout(function () {
                toast.update("etl-force-" + tenantGuid, {
                    render: React.createElement(NotificationContent, { text: "ETL has begun", mode: "success" }),
                    type: toast.TYPE.DEFAULT,
                    autoClose: 10000
                });
            }, 1000);
        });
    };
    var clearDeltasForTenant = function (tenantGuid) {
        toast(React.createElement(NotificationContent, { text: "<span style='font-weight:500;'>Clearing Delta Tokens</span>", mode: "loading" }), {
            toastId: "delta-clear" + tenantGuid,
            autoClose: false,
            type: toast.TYPE.DEFAULT
        });
        LifecycleService.ClearDeltasForTenant(tenantGuid).then(function () {
            setTimeout(function () {
                toast.update("delta-clear" + tenantGuid, {
                    render: React.createElement(NotificationContent, { text: "Tokens cleared", mode: "success" }),
                    type: toast.TYPE.DEFAULT,
                    autoClose: 10000
                });
            }, 1000);
        });
    };
    var forceDataAnalysis = function (tenantGuid) {
        toast(React.createElement(NotificationContent, { text: "<span style='font-weight:500;'>Forcing Data Analysis</span>", mode: "loading" }), {
            toastId: "force-data-analysis" + tenantGuid,
            autoClose: false,
            type: toast.TYPE.DEFAULT
        });
        LifecycleService.ForceDataAnalysis(tenantGuid).then(function () {
            setTimeout(function () {
                toast.update("force-data-analysis" + tenantGuid, {
                    render: React.createElement(NotificationContent, { text: "Data Analysis has begun", mode: "success" }),
                    type: toast.TYPE.DEFAULT,
                    autoClose: 10000
                });
            }, 1000);
        });
    };
    return (React.createElement("div", { className: "orchestry-admin-etl-crawl-stats fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Crawler", subTitle: "Tenants" },
            React.createElement(TableList.SearchBox, { placeholder: "Enter tenant name" })),
        React.createElement(OrchModal, { showModalOverride: showDetailsModal, showModalToggleOverride: setShowDetailsModal, simpleButton: true, size: "xl", headerText: "ETL Details > " + (selectTenantForDetails !== null ? selectTenantForDetails.TenantDisplayName : ""), modalClassName: "etl-details-modal", showCloseButton: true }, selectTenantForDetails !== null && detailedTenantStats !== null ? (React.createElement(OrchestryAdminETLDetailedStats, { RefreshList: function () { return refreshList(); }, Stats: detailedTenantStats, Tenant: selectTenantForDetails })) : (React.createElement(SkeletonLoaderFakeBox, null))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "region-select" }, activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) { return setSelectedRegion(value); } }))),
                        stats === undefined ? (React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 17 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement(SkeletonFakeTableCol, null,
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement(SkeletonFakeTableCol, { widthPercent: 20 },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })))) : (React.createElement(TableList.Context, { listItems: stats, showLoader: false, wrappedInPortlet: false },
                            React.createElement(TableList.TableList, null,
                                React.createElement(TableList.HeaderItem, { className: "tenant-name" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Tenant Name"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Last Complete"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Time To Complete"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Number Of Items"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "In Progress"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Has Error"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Crawler Actions"))),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement("div", null,
                                        React.createElement("span", null, "Sensitivity Actions"))),
                                React.createElement(TableList.ContentItemCustomRender, { isMobileTitle: true, render: function (item) { return (React.createElement("div", { onClick: function () {
                                            setSelectTenantForDetails(item);
                                            setShowDetailsModal(true);
                                        } },
                                        React.createElement("div", { className: "tenant-title" }, item.TenantDisplayName),
                                        React.createElement("div", { className: "tenant-guid" }, item.TenantGuid))); } }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "etl-last-complete" }, item.LastETLComplete !== null ? moment(item.LastETLComplete).format("LL") : null))); } }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "LastETLTimeToComplete" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "LastCrawlNumberOfItems" }),
                                React.createElement(TableList.ContentItemBooleanIcon, { listDataProperyName: "ETLInProgress" }),
                                React.createElement(TableList.ContentItemBooleanIcon, { listDataProperyName: "LastestCrawlHasError" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", null,
                                        React.createElement("div", { className: "action-buttons-crawler pull-right", style: { display: "flex", width: "100%", justifyContent: "flex-end" } },
                                            React.createElement("div", null,
                                                React.createElement(SubtitleButton, { onClick: function () { return startETLForTenant(item.TenantGuid, "Full"); }, altText: "Force Full Crawl", faIcon: "circle", size: "sm" })),
                                            React.createElement("div", null,
                                                React.createElement(SubtitleButton, { onClick: function () { return startETLForTenant(item.TenantGuid, "Partial"); }, altText: "Force Partial Crawl", faIcon: "circle-half", size: "sm" })),
                                            React.createElement("div", null,
                                                React.createElement(SubtitleButton, { onClick: function () { return clearDeltasForTenant(item.TenantGuid); }, altText: "Clear Deltas", variant: "success", faIcon: "eraser", size: "sm" })),
                                            React.createElement("div", null,
                                                React.createElement(SubtitleButton, { onClick: function () { return forceDataAnalysis(item.TenantGuid); }, altText: "Force Data Analysis", color: "orange", faIcon: "magnifying-glass-chart", size: "sm" }))))); } }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement(DisableArea, { useOverlay: true, isEnabled: orchestryContext.ExtendedProperties["SensitiveInformationAccess"] !== undefined && orchestryContext.ExtendedProperties["SensitiveInformationAccess"] == true },
                                        React.createElement("div", { className: "action-buttons-crawler pull-right", style: { display: "flex", width: "100%", justifyContent: "flex-end" } },
                                            React.createElement("div", null,
                                                React.createElement(OrchModal, { simpleButton: true, buttonContents: React.createElement(SubtitleButton, { variant: "success", size: "sm", faIcon: "file-magnifying-glass", altText: "View Workspace Stats" }), size: "xl", headerText: "Workspace Stats for " + (item !== null ? item.TenantDisplayName : ""), modalClassName: "etl-crawler-etl-stats-modal", showCloseButton: true },
                                                    React.createElement(OrchestryAdminEtlCrawlStatsWorkspaces, { Tenant: item }))),
                                            React.createElement("div", null,
                                                React.createElement(OrchModal, { simpleButton: true, buttonContents: React.createElement(SubtitleButton, { variant: "success", size: "sm", faIcon: "file-excel-o", altText: "Nightly CSV files" }), size: "xl", headerText: "ETL Nightly CSV files > " + (item !== null ? item.TenantDisplayName : ""), modalClassName: "etl-cachedfiles-modal", showCloseButton: true },
                                                    React.createElement(OrchestryAdminETLCachedFiles, { Tenant: item, CacheType: "csv" }))),
                                            React.createElement("div", null,
                                                React.createElement(OrchModal, { simpleButton: true, buttonContents: React.createElement(SubtitleButton, { variant: "success", size: "sm", faIcon: "file-chart-column", altText: "Cached Graph Reports" }), size: "xl", headerText: "ETL Cached GraphReports > " + (item !== null ? item.TenantDisplayName : ""), modalClassName: "etl-cachedfiles-modal", showCloseButton: true },
                                                    React.createElement(OrchestryAdminETLCachedFiles, { Tenant: item, CacheType: "reports" }))),
                                            React.createElement("div", null,
                                                React.createElement(OrchModal, { simpleButton: true, buttonContents: React.createElement(SubtitleButton, { variant: "success", size: "sm", faIcon: "list-timeline", altText: "Cached AuditLog Files" }), size: "xl", headerText: "ETL Cached AuditLog Change Files > " + (item !== null ? item.TenantDisplayName : ""), modalClassName: "etl-cachedfiles-modal", showCloseButton: true },
                                                    React.createElement(OrchestryAdminETLCachedFiles, { Tenant: item, CacheType: "log" }))),
                                            React.createElement("div", null,
                                                React.createElement(OrchModal, { simpleButton: true, buttonContents: React.createElement(SubtitleButton, { variant: "success", size: "sm", faIcon: "database", altText: "Database Information" }), size: "xl", headerText: "Tenant Db Details > " + (item !== null ? item.TenantDisplayName : ""), modalClassName: "database-details-modal", showCloseButton: true },
                                                    React.createElement(OrchestryAdminDatabaseDetails, { TenantGuid: item.TenantGuid })))))); } }))))))))));
});
export default OrchestryAdminETLStats;
var OrchestryAdminDatabaseDetails = React.memo(function (props) {
    var _a = React.useState(null), tenantDetails = _a[0], setTenantDetails = _a[1];
    var _b = React.useState(false), tenantDetailsLoading = _b[0], setTenantDetailsLoading = _b[1];
    React.useEffect(function () {
        setTenantDetailsLoading(true);
        TenantDatabaseService.getTenantDatabaseDetails(props.TenantGuid).then(function (details) {
            setTenantDetails(details);
            setTenantDetailsLoading(false);
        });
    }, [props.TenantGuid]);
    return (React.createElement(React.Fragment, null, tenantDetailsLoading == false && tenantDetails != null ? (React.createElement(Table, { striped: true, bordered: true, hover: true },
        React.createElement("tbody", null, Object.keys(tenantDetails.TableRowCounts).map(function (tableName) { return (React.createElement("tr", null,
            React.createElement("td", null, tableName),
            React.createElement("td", null, tenantDetails.TableRowCounts[tableName]))); })))) : (React.createElement(SkeletonLoaderFakeBox, null))));
});
var OrchestryAdminETLDetailedStats = React.memo(function (props) {
    var _a = React.useState(), overviewChatData = _a[0], setOverviewChartData = _a[1];
    var _b = React.useState(false), EtlManuallyStarted = _b[0], setETLManuallyStarted = _b[1];
    var _c = React.useState(false), clearingDeltas = _c[0], setClearingDeltas = _c[1];
    var _d = React.useState(false), startingETL = _d[0], setStartingETL = _d[1];
    var _e = React.useState(false), startingDataAnalysis = _e[0], setStartingDataAnalysis = _e[1];
    var startETLForTenant = function (tenantGuid, forceCrawlMode) {
        setETLManuallyStarted(true);
        setStartingETL(true);
        LifecycleService.ForceETLForTenant(tenantGuid, forceCrawlMode).then(function () {
            setTimeout(function () {
                props.RefreshList();
                setStartingETL(false);
            }, 1000);
        });
    };
    var clearDeltasForTenant = function (tenantGuid) {
        setClearingDeltas(true);
        LifecycleService.ClearDeltasForTenant(tenantGuid).then(function () {
            setTimeout(function () {
                setClearingDeltas(false);
            }, 1000);
        });
    };
    var getOverviewData = function () {
        var returnArray = [];
        var totalUsersCrawled = {
            name: "Total Users",
            type: "column",
            data: []
        };
        var totalWorkspaceCrawled = {
            name: "Total Workspaces",
            type: "column",
            data: []
        };
        var totalMinutesCrawled = {
            name: "Minutes to complete",
            type: "line",
            data: []
        };
        props.Stats.Days.forEach(function (day) {
            totalMinutesCrawled.data.push(Math.round(day.MinutesToComplete * 100) / 100);
            if (day.UserETLStats !== null) {
                totalUsersCrawled.data.push(day.UserETLStats.UsersAll === null ? 0 : day.UserETLStats.UsersAll);
            }
            else {
                totalUsersCrawled.data.push(0);
            }
            if (day.CrawlHistoryItem !== null) {
                totalWorkspaceCrawled.data.push(day.CrawlHistoryItem.WorkspaceCount === null ? 0 : day.CrawlHistoryItem.WorkspaceCount);
            }
            else {
                totalWorkspaceCrawled.data.push(0);
            }
        });
        totalMinutesCrawled.data = totalMinutesCrawled.data.reverse();
        totalUsersCrawled.data = totalUsersCrawled.data.reverse();
        totalWorkspaceCrawled.data = totalWorkspaceCrawled.data.reverse();
        returnArray.push(totalUsersCrawled);
        returnArray.push(totalWorkspaceCrawled);
        returnArray.push(totalMinutesCrawled);
        console.log("getOverviewData", returnArray);
        return returnArray;
    };
    var getOverviewCategories = function () {
        return props.Stats.Days.map(function (s) { return s.Day; }).reverse();
    };
    var getActionBreakdown = function () {
        var returnArray = [];
        var Forced = {
            name: "Forced Full Crawl",
            data: []
        };
        var NeverCrawledBefore = {
            name: "Never Crawled Before",
            data: []
        };
        var RecrawlingSameDay = {
            name: "Recrawling Same Day",
            data: []
        };
        var LessThan90Days = {
            name: "Periodic Full - Less Than 90 Days",
            data: []
        };
        var MoreThan90LessThan180Days = {
            name: "Periodic Full - More Than 90 Less Than 180 Days",
            data: []
        };
        var MoreThanThan180Days = {
            name: "Periodic Full - More Than 180 Days",
            data: []
        };
        var AuditLogChanges = {
            name: "Audi Log",
            data: []
        };
        var PropertyComparisonChanges = {
            name: "Property Comparison",
            data: []
        };
        var UpdatedUserMembership = {
            name: "Updated Memberships",
            data: []
        };
        var PolicyThresholdBreached = {
            name: "Policy Threshold Breached",
            data: []
        };
        var Skipped = {
            name: "Skipped - No Crawl Plan",
            data: []
        };
        var SkippedLessThan90Days = {
            name: "Skipped Less Than 90 Days",
            data: []
        };
        var SkippedMoreThan90LessThan180Days = {
            name: "Skipped More Than 90 Less Than 180 Days",
            data: []
        };
        var SkippedMoreThanThan180Days = {
            name: "Skipped More Than 180 Days",
            data: []
        };
        var SkipExcludeCommSites = {
            name: "Excluded Comm Sites",
            data: []
        };
        var SkipExcludeTeams = {
            name: "Excluded Teams",
            data: []
        };
        var SkipExcludeTeamSites = {
            name: "Excluded Team Sites",
            data: []
        };
        var SkipExcludeOutlookGroups = {
            name: "Excluded Outlook Groups",
            data: []
        };
        props.Stats.Days.forEach(function (day) {
            if (day.WorkspaceETLStats !== null && day.WorkspaceETLStats.DeduceStats !== null) {
                // console.log("day.WorkspaceETLStats not null", day.WorkspaceETLStats);
                Forced.data.push(day.WorkspaceETLStats.DeduceStats.Forced);
                NeverCrawledBefore.data.push(day.WorkspaceETLStats.DeduceStats.NeverCrawledBefore);
                RecrawlingSameDay.data.push(day.WorkspaceETLStats.DeduceStats.RecrawlingSameDay);
                LessThan90Days.data.push(day.WorkspaceETLStats.DeduceStats.LessThan90Days);
                MoreThan90LessThan180Days.data.push(day.WorkspaceETLStats.DeduceStats.MoreThan90LessThan180Days);
                MoreThanThan180Days.data.push(day.WorkspaceETLStats.DeduceStats.MoreThanThan180Days);
                PolicyThresholdBreached.data.push(day.WorkspaceETLStats.DeduceStats.PolicyThresholdBreached);
                SkippedLessThan90Days.data.push(day.WorkspaceETLStats.DeduceStats.SkippedLessThan90Days);
                SkippedMoreThan90LessThan180Days.data.push(day.WorkspaceETLStats.DeduceStats.SkippedMoreThan90LessThan180Days);
                SkippedMoreThanThan180Days.data.push(day.WorkspaceETLStats.DeduceStats.SkippedMoreThanThan180Days);
                Skipped.data.push(day.WorkspaceETLStats.DeduceStats.Skipped);
                SkipExcludeCommSites.data.push(day.WorkspaceETLStats.DeduceStats.SkipExcludeCommSites);
                SkipExcludeOutlookGroups.data.push(day.WorkspaceETLStats.DeduceStats.SkipExcludeOutlookGroups);
                SkipExcludeTeamSites.data.push(day.WorkspaceETLStats.DeduceStats.SkipExcludeTeamSites);
                AuditLogChanges.data.push(day.WorkspaceETLStats.DeduceStats.AuditLog);
                PropertyComparisonChanges.data.push(day.WorkspaceETLStats.DeduceStats.PropertyComparison);
                UpdatedUserMembership.data.push(day.WorkspaceETLStats.DeduceStats.UpdatedUserMembership);
            }
            else {
                Forced.data.push(0);
                NeverCrawledBefore.data.push(0);
                RecrawlingSameDay.data.push(0);
                LessThan90Days.data.push(0);
                MoreThan90LessThan180Days.data.push(0);
                MoreThanThan180Days.data.push(0);
                PolicyThresholdBreached.data.push(0);
                SkippedLessThan90Days.data.push(0);
                SkippedMoreThan90LessThan180Days.data.push(0);
                SkippedMoreThanThan180Days.data.push(0);
                Skipped.data.push(0);
                SkipExcludeCommSites.data.push(0);
                SkipExcludeOutlookGroups.data.push(0);
                SkipExcludeTeamSites.data.push(0);
                SkipExcludeTeams.data.push(0);
                AuditLogChanges.data.push(0);
                PropertyComparisonChanges.data.push(0);
                UpdatedUserMembership.data.push(0);
            }
        });
        Forced.data = Forced.data.reverse();
        NeverCrawledBefore.data = NeverCrawledBefore.data.reverse();
        RecrawlingSameDay.data = RecrawlingSameDay.data.reverse();
        LessThan90Days.data = LessThan90Days.data.reverse();
        MoreThan90LessThan180Days.data = MoreThan90LessThan180Days.data.reverse();
        MoreThanThan180Days.data = MoreThanThan180Days.data.reverse();
        PolicyThresholdBreached.data = PolicyThresholdBreached.data.reverse();
        SkippedLessThan90Days.data = SkippedLessThan90Days.data.reverse();
        SkippedMoreThan90LessThan180Days.data = SkippedMoreThan90LessThan180Days.data.reverse();
        SkippedMoreThanThan180Days.data = SkippedMoreThanThan180Days.data.reverse();
        SkipExcludeCommSites.data = SkipExcludeCommSites.data.reverse();
        SkipExcludeOutlookGroups.data = SkipExcludeOutlookGroups.data.reverse();
        SkipExcludeTeamSites.data = SkipExcludeTeamSites.data.reverse();
        SkipExcludeTeams.data = SkipExcludeTeams.data.reverse();
        Skipped.data = Skipped.data.reverse();
        AuditLogChanges.data = AuditLogChanges.data.reverse();
        PropertyComparisonChanges.data = PropertyComparisonChanges.data.reverse();
        UpdatedUserMembership.data = UpdatedUserMembership.data.reverse();
        returnArray.push(Forced);
        returnArray.push(NeverCrawledBefore);
        // returnArray.push(RecrawlingSameDay);
        returnArray.push(LessThan90Days);
        returnArray.push(MoreThan90LessThan180Days);
        returnArray.push(MoreThanThan180Days);
        returnArray.push(PolicyThresholdBreached);
        returnArray.push(AuditLogChanges);
        returnArray.push(PropertyComparisonChanges);
        returnArray.push(UpdatedUserMembership);
        // returnArray.push(SkippedLessThan90Days);
        // returnArray.push(SkippedMoreThan90LessThan180Days);
        // returnArray.push(SkippedMoreThanThan180Days);
        returnArray.push(SkipExcludeCommSites);
        returnArray.push(SkipExcludeOutlookGroups);
        returnArray.push(SkipExcludeTeamSites);
        returnArray.push(SkipExcludeTeams);
        // returnArray.push(Skipped);
        console.log("getActionBreakdown array", returnArray);
        return returnArray;
    };
    var getCrawlPlanBreakdown = function () {
        var returnArray = [];
        var CrawlPlanFull = {
            name: "Full",
            group: "total",
            data: []
        };
        var CrawlPlanPartial = {
            name: "Partial",
            group: "total",
            data: []
        };
        var CrawlPlanGroupMembership = {
            name: "Group Membership",
            group: "details",
            data: []
        };
        var CrawlPlanGroupPlanner = {
            name: "Group Planner",
            group: "details",
            data: []
        };
        var CrawlPlanGroupSensitivityLabel = {
            name: "Group Sensitivity Label",
            group: "details",
            data: []
        };
        var CrawlPlanSharePointAdminProperties = {
            name: "SharePoint Admin Properties",
            group: "details",
            data: []
        };
        var CrawlPlanSharePointFilesAndFolders = {
            name: "SharePoint Files And Folders",
            group: "details",
            data: []
        };
        var CrawlPlanSharePointSitePermissions = {
            name: "SharePoint Site Permissions",
            group: "details",
            data: []
        };
        var CrawlPlanSharePointMetrics = {
            name: "SharePoint Metrics",
            group: "details",
            data: []
        };
        var CrawlPlanSharePointSiteProperties = {
            name: "SharePoint Site Properties",
            group: "details",
            data: []
        };
        var CrawlPlanTeam = {
            name: "Team",
            group: "details",
            data: []
        };
        var CrawlPlanTeamApps = {
            name: "Team Apps",
            group: "details",
            data: []
        };
        var CrawlPlanTeamChannelMemberships = {
            name: "Team Channel Memberships",
            group: "details",
            data: []
        };
        var CrawlPlanTeamChannelMessages = {
            name: "Team Channel Messages",
            group: "details",
            data: []
        };
        var CrawlPlanTeamChannels = {
            name: "Team Channels",
            group: "details",
            data: []
        };
        var CrawlPlanTeamTabs = {
            name: "Team Tabs",
            group: "details",
            data: []
        };
        props.Stats.Days.forEach(function (day) {
            if (day.WorkspaceETLStats !== null && day.WorkspaceETLStats.DeduceStats !== null) {
                CrawlPlanFull.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanFull);
                CrawlPlanPartial.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanPartial);
                CrawlPlanGroupMembership.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanGroupMembership);
                CrawlPlanGroupPlanner.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanGroupPlanner);
                CrawlPlanGroupSensitivityLabel.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanGroupSensitivityLabel);
                CrawlPlanSharePointAdminProperties.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanSharePointAdminProperties);
                CrawlPlanSharePointFilesAndFolders.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanSharePointFilesAndFolders);
                CrawlPlanSharePointSitePermissions.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanSharePointSitePermissions);
                CrawlPlanSharePointMetrics.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanSharePointMetrics);
                CrawlPlanSharePointSiteProperties.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanSharePointSiteProperties);
                CrawlPlanTeam.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanTeam);
                CrawlPlanTeamApps.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanTeamApps);
                CrawlPlanTeamChannelMemberships.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanTeamChannelMemberships);
                CrawlPlanTeamChannelMessages.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanTeamChannelMessages);
                CrawlPlanTeamChannels.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanTeamChannels);
                CrawlPlanTeamTabs.data.push(day.WorkspaceETLStats.DeduceStats.CrawlPlanTeamTabs);
            }
            else {
                CrawlPlanFull.data.push(0);
                CrawlPlanPartial.data.push(0);
                CrawlPlanGroupMembership.data.push(0);
                CrawlPlanGroupPlanner.data.push(0);
                CrawlPlanGroupSensitivityLabel.data.push(0);
                CrawlPlanSharePointAdminProperties.data.push(0);
                CrawlPlanSharePointFilesAndFolders.data.push(0);
                CrawlPlanSharePointSitePermissions.data.push(0);
                CrawlPlanSharePointMetrics.data.push(0);
                CrawlPlanSharePointSiteProperties.data.push(0);
                CrawlPlanTeam.data.push(0);
                CrawlPlanTeamApps.data.push(0);
                CrawlPlanTeamChannelMemberships.data.push(0);
                CrawlPlanTeamChannelMessages.data.push(0);
                CrawlPlanTeamChannels.data.push(0);
                CrawlPlanTeamTabs.data.push(0);
            }
        });
        CrawlPlanFull.data = CrawlPlanFull.data.reverse();
        CrawlPlanPartial.data = CrawlPlanPartial.data.reverse();
        CrawlPlanGroupMembership.data = CrawlPlanGroupMembership.data.reverse();
        CrawlPlanGroupPlanner.data = CrawlPlanGroupPlanner.data.reverse();
        CrawlPlanGroupSensitivityLabel.data = CrawlPlanGroupSensitivityLabel.data.reverse();
        CrawlPlanSharePointAdminProperties.data = CrawlPlanSharePointAdminProperties.data.reverse();
        CrawlPlanSharePointFilesAndFolders.data = CrawlPlanSharePointFilesAndFolders.data.reverse();
        CrawlPlanSharePointSitePermissions.data = CrawlPlanSharePointSitePermissions.data.reverse();
        CrawlPlanSharePointSiteProperties.data = CrawlPlanSharePointSiteProperties.data.reverse();
        CrawlPlanSharePointMetrics.data = CrawlPlanSharePointMetrics.data.reverse();
        CrawlPlanTeam.data = CrawlPlanTeam.data.reverse();
        CrawlPlanTeamApps.data = CrawlPlanTeamApps.data.reverse();
        CrawlPlanTeamChannelMemberships.data = CrawlPlanTeamChannelMemberships.data.reverse();
        CrawlPlanTeamChannelMessages.data = CrawlPlanTeamChannelMessages.data.reverse();
        CrawlPlanTeamChannels.data = CrawlPlanTeamChannels.data.reverse();
        CrawlPlanTeamTabs.data = CrawlPlanTeamTabs.data.reverse();
        returnArray.push(CrawlPlanFull);
        // returnArray.push(CrawlPlanPartial);
        returnArray.push(CrawlPlanGroupMembership);
        returnArray.push(CrawlPlanGroupPlanner);
        returnArray.push(CrawlPlanGroupSensitivityLabel);
        returnArray.push(CrawlPlanSharePointAdminProperties);
        returnArray.push(CrawlPlanSharePointFilesAndFolders);
        returnArray.push(CrawlPlanSharePointSitePermissions);
        returnArray.push(CrawlPlanSharePointMetrics);
        returnArray.push(CrawlPlanSharePointSiteProperties);
        returnArray.push(CrawlPlanTeam);
        returnArray.push(CrawlPlanTeamApps);
        returnArray.push(CrawlPlanTeamChannelMemberships);
        returnArray.push(CrawlPlanTeamChannelMessages);
        returnArray.push(CrawlPlanTeamChannels);
        returnArray.push(CrawlPlanTeamTabs);
        console.log("getCrawlPlanBreakdown array", returnArray);
        return returnArray;
    };
    var getUserBreakdown = function () {
        var returnArray = [];
        var UsersUpdated = {
            name: "Users Updated",
            type: "column",
            data: []
        };
        var UsersAll = {
            name: "Users All",
            type: "column",
            data: []
        };
        var GroupUpdated = {
            name: "Group Updated",
            type: "column",
            data: []
        };
        var GroupMembershipsUpdated = {
            name: "Group Memberships Updated",
            type: "column",
            data: []
        };
        var CosmosItemsUpdated = {
            name: "Cosmos Items Updated",
            type: "column",
            data: []
        };
        props.Stats.Days.forEach(function (day) {
            if (day.UserETLStats !== null) {
                UsersUpdated.data.push(day.UserETLStats.UsersUpdated);
                UsersAll.data.push(day.UserETLStats.UsersAll);
                GroupUpdated.data.push(day.UserETLStats.GroupUpdated);
                GroupMembershipsUpdated.data.push(day.UserETLStats.GroupMembershipsUpdated);
                CosmosItemsUpdated.data.push(day.UserETLStats.CosmosItemsUpdated);
            }
            else {
                UsersUpdated.data.push(0);
                UsersAll.data.push(0);
                GroupUpdated.data.push(0);
                GroupMembershipsUpdated.data.push(0);
                CosmosItemsUpdated.data.push(0);
            }
        });
        UsersUpdated.data = UsersUpdated.data.reverse();
        UsersAll.data = UsersAll.data.reverse();
        GroupUpdated.data = GroupUpdated.data.reverse();
        GroupMembershipsUpdated.data = GroupMembershipsUpdated.data.reverse();
        CosmosItemsUpdated.data = CosmosItemsUpdated.data.reverse();
        returnArray.push(UsersUpdated);
        returnArray.push(UsersAll);
        returnArray.push(GroupUpdated);
        returnArray.push(GroupMembershipsUpdated);
        returnArray.push(CosmosItemsUpdated);
        console.log("getUserBreakdown array", returnArray);
        return returnArray;
    };
    var getStopwatchBreakdown = function () {
        var returnArray = [];
        var UserCrawl = { name: "UserCrawl", data: [] };
        var UserMembershipUpdates = { name: "UserMembershipUpdates", data: [] };
        var UserSqlSync = { name: "UserSqlSync", data: [] };
        var UserCosmosSync = { name: "UserCosmosSync", data: [] };
        var WorkspacePopulate = { name: "WorkspacePopulate", data: [] };
        var WorkspaceAtsSync = { name: "WorkspaceAtsSync", data: [] };
        var WorkspaceSqlSync = { name: "WorkspaceSqlSync", data: [] };
        var WorkspaceExportCSV = { name: "WorkspaceExportCSV", data: [] };
        var WorkspaceGraphGroups = { name: "WorkspaceGraphGroups", data: [] };
        var WorkspaceGraphCrawlHigh = { name: "WorkspaceGraphCrawlHigh", data: [] };
        var WorkspaceGraphCrawlLow = { name: "WorkspaceGraphCrawlLow", data: [] };
        var WorkspaceSharePointHigh = { name: "WorkspaceSharePointHigh", data: [] };
        var WorkspaceSharePointLow = { name: "WorkspaceSharePointLow", data: [] };
        var WorkspaceDirectorySync = { name: "WorkspaceDirectorySync", data: [] };
        var WorkspaceCosmosSync = { name: "WorkspaceCosmosSync", data: [] };
        var WorkspaceReportSync = { name: "WorkspaceReportSync", data: [] };
        var WorkspaceCsomAdminProperties = { name: "WorkspaceCsomAdminProperties", data: [] };
        var WorkspaceCsomWebProperties = { name: "WorkspaceCsomWebProperties", data: [] };
        var WorkspaceDeducePriority = { name: "WorkspaceDeducePriority", data: [] };
        var WorkspaceUpdatePolicyWorkspaceItems = { name: "WorkspaceUpdatePolicyWorkspaceItems", data: [] };
        var WorkspaceSaveAllItems = { name: "WorkspaceSaveAllItems", data: [] };
        var WorkspaceCatchDeletedWorkspaces = { name: "WorkspaceCatchDeletedWorkspaces", data: [] };
        var WorkspaceCompileTeamLastActivity = { name: "WorkspaceCompileTeamLastActivity", data: [] };
        var WorkspacePolicySync = { name: "WorkspacePolicySync", data: [] };
        var WorkspaceAuditLog = { name: "WorkspaceAuditLog", data: [] };
        function roundToTwo(num) {
            var m = Number((Math.abs(num) * 100).toPrecision(15));
            var rounded = (Math.round(m) / 100) * Math.sign(num);
            // console.log("Round", num, rounded);
            return rounded;
        }
        props.Stats.Days.forEach(function (day) {
            if (day.StopwatchStats !== null) {
                UserCrawl.data.push(day.StopwatchStats.UserCrawl !== null ? roundToTwo(day.StopwatchStats.UserCrawl) : 0);
                UserMembershipUpdates.data.push(day.StopwatchStats.UserMembershipUpdates !== null ? roundToTwo(day.StopwatchStats.UserMembershipUpdates) : 0);
                UserSqlSync.data.push(day.StopwatchStats.UserSqlSync !== null ? roundToTwo(day.StopwatchStats.UserSqlSync) : 0);
                UserCosmosSync.data.push(day.StopwatchStats.UserCosmosSync !== null ? roundToTwo(day.StopwatchStats.UserCosmosSync) : 0);
                WorkspacePopulate.data.push(day.StopwatchStats.WorkspacePopulate !== null ? roundToTwo(day.StopwatchStats.WorkspacePopulate) : 0);
                WorkspaceAtsSync.data.push(day.StopwatchStats.WorkspaceAtsSync !== null ? roundToTwo(day.StopwatchStats.WorkspaceAtsSync) : 0);
                WorkspaceSqlSync.data.push(day.StopwatchStats.WorkspaceSqlSync !== null ? roundToTwo(day.StopwatchStats.WorkspaceSqlSync) : 0);
                WorkspaceExportCSV.data.push(day.StopwatchStats.WorkspaceExportCSV !== null ? roundToTwo(day.StopwatchStats.WorkspaceExportCSV) : 0);
                WorkspaceGraphGroups.data.push(day.StopwatchStats.WorkspaceGraphGroups !== null ? roundToTwo(day.StopwatchStats.WorkspaceGraphGroups) : 0);
                WorkspaceGraphCrawlHigh.data.push(day.StopwatchStats.WorkspaceGraphCrawlHigh !== null ? roundToTwo(day.StopwatchStats.WorkspaceGraphCrawlHigh) : 0);
                WorkspaceGraphCrawlLow.data.push(day.StopwatchStats.WorkspaceGraphCrawlLow !== null ? roundToTwo(day.StopwatchStats.WorkspaceGraphCrawlLow) : 0);
                WorkspaceSharePointHigh.data.push(day.StopwatchStats.WorkspaceSharePointHigh !== null ? roundToTwo(day.StopwatchStats.WorkspaceSharePointHigh) : 0);
                WorkspaceSharePointLow.data.push(day.StopwatchStats.WorkspaceSharePointLow !== null ? roundToTwo(day.StopwatchStats.WorkspaceSharePointLow) : 0);
                WorkspaceDirectorySync.data.push(day.StopwatchStats.WorkspaceDirectorySync !== null ? roundToTwo(day.StopwatchStats.WorkspaceDirectorySync) : 0);
                WorkspaceCosmosSync.data.push(day.StopwatchStats.WorkspaceCosmosSync !== null ? roundToTwo(day.StopwatchStats.WorkspaceCosmosSync) : 0);
                WorkspaceReportSync.data.push(day.StopwatchStats.WorkspaceReportSync !== null ? roundToTwo(day.StopwatchStats.WorkspaceReportSync) : 0);
                WorkspaceCsomAdminProperties.data.push(day.StopwatchStats.WorkspaceCsomAdminProperties !== null ? roundToTwo(day.StopwatchStats.WorkspaceCsomAdminProperties) : 0);
                WorkspaceCsomWebProperties.data.push(day.StopwatchStats.WorkspaceCsomWebProperties !== null ? roundToTwo(day.StopwatchStats.WorkspaceCsomWebProperties) : 0);
                WorkspaceDeducePriority.data.push(day.StopwatchStats.WorkspaceDeducePriority !== null ? roundToTwo(day.StopwatchStats.WorkspaceDeducePriority) : 0);
                WorkspaceUpdatePolicyWorkspaceItems.data.push(day.StopwatchStats.WorkspaceUpdatePolicyWorkspaceItems !== null ? roundToTwo(day.StopwatchStats.WorkspaceUpdatePolicyWorkspaceItems) : 0);
                WorkspaceSaveAllItems.data.push(day.StopwatchStats.WorkspaceSaveAllItems !== null ? roundToTwo(day.StopwatchStats.WorkspaceSaveAllItems) : 0);
                WorkspaceCatchDeletedWorkspaces.data.push(day.StopwatchStats.WorkspaceCatchDeletedWorkspaces !== null ? roundToTwo(day.StopwatchStats.WorkspaceCatchDeletedWorkspaces) : 0);
                WorkspaceCompileTeamLastActivity.data.push(day.StopwatchStats.WorkspaceCompileTeamLastActivity !== null ? roundToTwo(day.StopwatchStats.WorkspaceCompileTeamLastActivity) : 0);
                WorkspacePolicySync.data.push(day.StopwatchStats.WorkspacePolicySync !== null ? roundToTwo(day.StopwatchStats.WorkspacePolicySync) : 0);
                WorkspaceAuditLog.data.push(day.StopwatchStats.WorkspaceAuditLog !== null ? roundToTwo(day.StopwatchStats.WorkspaceAuditLog) : 0);
            }
            else {
                UserCrawl.data.push(0);
                UserMembershipUpdates.data.push(0);
                UserSqlSync.data.push(0);
                UserCosmosSync.data.push(0);
                WorkspacePopulate.data.push(0);
                WorkspaceAtsSync.data.push(0);
                WorkspaceSqlSync.data.push(0);
                WorkspaceExportCSV.data.push(0);
                WorkspaceGraphGroups.data.push(0);
                WorkspaceGraphCrawlHigh.data.push(0);
                WorkspaceGraphCrawlLow.data.push(0);
                WorkspaceSharePointHigh.data.push(0);
                WorkspaceDirectorySync.data.push(0);
                WorkspaceCosmosSync.data.push(0);
                WorkspaceReportSync.data.push(0);
                WorkspaceCsomAdminProperties.data.push(0);
                WorkspaceCsomWebProperties.data.push(0);
                WorkspaceDeducePriority.data.push(0);
                WorkspaceUpdatePolicyWorkspaceItems.data.push(0);
                WorkspaceSaveAllItems.data.push(0);
                WorkspaceCatchDeletedWorkspaces.data.push(0);
                WorkspaceCompileTeamLastActivity.data.push(0);
                WorkspacePolicySync.data.push(0);
                WorkspaceAuditLog.data.push(0);
            }
        });
        UserCrawl.data = UserCrawl.data.reverse();
        UserMembershipUpdates.data = UserMembershipUpdates.data.reverse();
        UserSqlSync.data = UserSqlSync.data.reverse();
        UserCosmosSync.data = UserCosmosSync.data.reverse();
        WorkspacePopulate.data = WorkspacePopulate.data.reverse();
        WorkspaceAtsSync.data = WorkspaceAtsSync.data.reverse();
        WorkspaceSqlSync.data = WorkspaceSqlSync.data.reverse();
        WorkspaceExportCSV.data = WorkspaceExportCSV.data.reverse();
        WorkspaceGraphGroups.data = WorkspaceGraphGroups.data.reverse();
        WorkspaceGraphCrawlHigh.data = WorkspaceGraphCrawlHigh.data.reverse();
        WorkspaceGraphCrawlLow.data = WorkspaceGraphCrawlLow.data.reverse();
        WorkspaceSharePointHigh.data = WorkspaceSharePointHigh.data.reverse();
        WorkspaceSharePointLow.data = WorkspaceSharePointLow.data.reverse();
        WorkspaceDirectorySync.data = WorkspaceDirectorySync.data.reverse();
        WorkspaceCosmosSync.data = WorkspaceCosmosSync.data.reverse();
        WorkspaceReportSync.data = WorkspaceReportSync.data.reverse();
        WorkspaceCsomAdminProperties.data = WorkspaceCsomAdminProperties.data.reverse();
        WorkspaceCsomWebProperties.data = WorkspaceCsomWebProperties.data.reverse();
        WorkspaceDeducePriority.data = WorkspaceDeducePriority.data.reverse();
        WorkspaceUpdatePolicyWorkspaceItems.data = WorkspaceUpdatePolicyWorkspaceItems.data.reverse();
        WorkspaceSaveAllItems.data = WorkspaceSaveAllItems.data.reverse();
        WorkspaceCatchDeletedWorkspaces.data = WorkspaceCatchDeletedWorkspaces.data.reverse();
        WorkspaceCompileTeamLastActivity.data = WorkspaceCompileTeamLastActivity.data.reverse();
        WorkspacePolicySync.data = WorkspacePolicySync.data.reverse();
        WorkspaceAuditLog.data = WorkspaceAuditLog.data.reverse();
        returnArray.push(UserCrawl);
        returnArray.push(UserMembershipUpdates);
        returnArray.push(UserSqlSync);
        returnArray.push(UserCosmosSync);
        returnArray.push(WorkspacePopulate);
        returnArray.push(WorkspaceAtsSync);
        returnArray.push(WorkspaceSqlSync);
        returnArray.push(WorkspaceExportCSV);
        returnArray.push(WorkspaceGraphGroups);
        returnArray.push(WorkspaceGraphCrawlHigh);
        // returnArray.push(WorkspaceGraphCrawlLow);
        returnArray.push(WorkspaceSharePointHigh);
        returnArray.push(WorkspaceSharePointLow);
        returnArray.push(WorkspaceDirectorySync);
        returnArray.push(WorkspaceCosmosSync);
        returnArray.push(WorkspaceReportSync);
        returnArray.push(WorkspaceCsomAdminProperties);
        returnArray.push(WorkspaceCsomWebProperties);
        returnArray.push(WorkspaceDeducePriority);
        returnArray.push(WorkspaceUpdatePolicyWorkspaceItems);
        returnArray.push(WorkspaceSaveAllItems);
        returnArray.push(WorkspaceCatchDeletedWorkspaces);
        returnArray.push(WorkspaceCompileTeamLastActivity);
        returnArray.push(WorkspacePolicySync);
        returnArray.push(WorkspaceAuditLog);
        console.log("getStopwatchBreakdown array", returnArray);
        return returnArray;
    };
    var forceDataAnalysis = function (tenantGuid) {
        setStartingDataAnalysis(true);
        LifecycleService.ForceDataAnalysis(tenantGuid).then(function () {
            setTimeout(function () {
                setStartingDataAnalysis(false);
            }, 1000);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("div", { className: "action-buttons", style: { display: "flex" } },
                    startingETL == true ? (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            React.createElement(SubtitleButton, { disabled: true, variant: "outline-primary", title: "Force Full Crawl", size: "sm", faIcon: "circle" })),
                        React.createElement("div", null,
                            React.createElement(SubtitleButton, { disabled: true, variant: "outline-primary", title: "Force Incremental Crawl", altText: "Uses Audit Log for change events", size: "sm", faIcon: "circle-half" })))) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { onClick: function () { return startETLForTenant(props.Tenant.TenantGuid, "Full"); } },
                            React.createElement(SubtitleButton, { title: "Force Full Crawl", size: "sm", faIcon: "circle", noMargin: true })),
                        React.createElement("div", { onClick: function () { return startETLForTenant(props.Tenant.TenantGuid, "Partial"); } },
                            React.createElement(SubtitleButton, { title: "Force Incremental", altText: "Uses Audit Log for change events Crawl", size: "sm", faIcon: "circle-half" })))),
                    clearingDeltas === false ? (React.createElement("div", { onClick: function () { return clearDeltasForTenant(props.Tenant.TenantGuid); } },
                        React.createElement(SubtitleButton, { variant: "success", title: "Clear Deltas", size: "sm", faIcon: "eraser" }))) : (React.createElement("div", null,
                        React.createElement(SubtitleButton, { disabled: true, variant: "outline-success", title: "Clear Deltas", size: "sm", faIcon: "eraser" }))),
                    startingDataAnalysis === false ? (React.createElement("div", { onClick: function () { return forceDataAnalysis(props.Tenant.TenantGuid); } },
                        React.createElement(SubtitleButton, { color: "orange", title: "Force Data Analysis", size: "sm", faIcon: "magnifying-glass-chart" }))) : (React.createElement("div", null,
                        React.createElement(SubtitleButton, { disabled: true, variant: "outline-success", title: "Force Data Analysis", size: "sm", faIcon: "magnifying-glass-chart" })))))),
        React.createElement("hr", null),
        React.createElement("div", null,
            React.createElement(ReactApexChart, { options: {
                    chart: {
                        height: 350,
                        type: "line",
                        stacked: false,
                        fontFamily: '"TT Norms", Helvetica, sans-serif'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [1, 1, 4]
                    },
                    title: {
                        text: "ETL Overview",
                        align: "left",
                        style: {
                            fontWeight: 500,
                            fontSize: "16px"
                        }
                        // offsetX: 110
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
                        type: "datetime",
                        categories: getOverviewCategories()
                    },
                    yaxis: [
                        {
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: "#008FFB"
                            },
                            labels: {
                                style: {
                                    colors: "#008FFB"
                                }
                            },
                            title: {
                                text: "Total Users",
                                style: {
                                    color: "#008FFB"
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        {
                            seriesName: "Income",
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: "#00E396"
                            },
                            labels: {
                                style: {
                                    colors: "#00E396"
                                }
                            },
                            title: {
                                text: "Total Workspaces",
                                style: {
                                    color: "#00E396"
                                }
                            }
                        },
                        {
                            seriesName: "Revenue",
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: "#FEB019"
                            },
                            labels: {
                                style: {
                                    colors: "#FEB019"
                                }
                            },
                            title: {
                                text: "Crawl time in minutes",
                                style: {
                                    color: "#FEB019"
                                }
                            }
                        }
                    ],
                    tooltip: {
                    // fixed: {
                    //    enabled: true,
                    //    position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
                    //    offsetY: 30,
                    //    offsetX: 60
                    // }
                    },
                    legend: {
                        horizontalAlign: "left",
                        offsetX: 40
                    }
                }, series: getOverviewData(), type: "line", height: 350 })),
        React.createElement("hr", null),
        React.createElement("div", null,
            React.createElement(ReactApexChart, { height: 600, options: {
                    colors: ["#fe0000", "#cc0098", "#990099", "#0250d4", "#0151d4", "#0ab4c3", "#009900", "#66cc00", "#ffff00", "#ffcc00", "#fe9900", "#ff6600"],
                    chart: {
                        type: "bar",
                        height: 600,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        },
                        fontFamily: '"TT Norms", Helvetica, sans-serif'
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: "10px",
                            fontFamily: '"TT Norms", Helvetica, sans-serif',
                            fontWeight: "500"
                        }
                    },
                    title: {
                        text: "Workspace Crawl Actions",
                        align: "left",
                        style: {
                            fontWeight: 500,
                            fontSize: "16px"
                        }
                        // offsetX: 110
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: "bottom",
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 0,
                            colors: {
                            // backgroundBarColors: ["#000000", "blue"]
                            },
                            dataLabels: {
                                orientation: "vertical"
                            }
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        categories: getOverviewCategories()
                    },
                    legend: {
                        position: "right",
                        offsetY: 40,
                        fontSize: "13px",
                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                        fontWeight: 500
                    },
                    fill: {
                        opacity: 1
                    }
                }, series: getActionBreakdown(), type: "bar" })),
        React.createElement("hr", null),
        React.createElement("div", null,
            React.createElement(ReactApexChart, { height: 600, options: {
                    // colors: ["#EB5353", "#F9D923", "#36AE7C", "#FFCD38", "#DEA057", "#34b550", "#FF8D29", "#73ccd9", "#73ccd9", "#73ccd9", "#73ccd9", "#8bc7cd", "#8bc7cd", "#8bc7cd", "#8bc7cd"],
                    colors: ["#fe0000", "#cc0098", "#990099", "#0250d4", "#0151d4", "#0ab4c3", "#009900", "#66cc00", "#ffff00", "#ffcc00", "#fe9900", "#ff6600"],
                    chart: {
                        type: "bar",
                        height: 600,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        },
                        fontFamily: '"TT Norms", Helvetica, sans-serif'
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: "10px",
                            fontFamily: '"TT Norms", Helvetica, sans-serif',
                            fontWeight: "500"
                        }
                    },
                    title: {
                        text: "Workspace Crawl Plans",
                        align: "left",
                        style: {
                            fontWeight: 500,
                            fontSize: "16px"
                        }
                        // offsetX: 110
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: "bottom",
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 0,
                            colors: {
                            // backgroundBarColors: ["#000000", "blue"]
                            },
                            dataLabels: {
                                orientation: "vertical"
                            }
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        categories: getOverviewCategories()
                    },
                    legend: {
                        position: "right",
                        offsetY: 40,
                        fontSize: "13px",
                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                        fontWeight: 500
                    },
                    fill: {
                        opacity: 1
                    }
                }, series: getCrawlPlanBreakdown(), type: "bar" })),
        React.createElement("hr", null),
        React.createElement("div", null,
            React.createElement(ReactApexChart, { options: {
                    colors: ["#fd0100", "#eede04", "#f76915", "#a0d636", "#2fa236", "#333ed4"],
                    chart: {
                        type: "bar",
                        stacked: false,
                        height: 350,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        },
                        fontFamily: '"TT Norms", Helvetica, sans-serif'
                    },
                    title: {
                        text: "User Crawl Statistics",
                        align: "left",
                        style: {
                            fontWeight: 500,
                            fontSize: "16px"
                        }
                        // offsetX: 110
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: "10px",
                            fontFamily: '"TT Norms", Helvetica, sans-serif',
                            fontWeight: "500"
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: "bottom",
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 0,
                            colors: {
                            // backgroundBarColors: ["#000000", "blue"]
                            },
                            dataLabels: {
                                hideOverflowingLabels: true,
                                orientation: "vertical"
                            }
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        categories: getOverviewCategories()
                    },
                    legend: {
                        position: "right",
                        offsetY: 40,
                        fontSize: "13px",
                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                        fontWeight: 500
                    },
                    yaxis: [
                        {
                            axisTicks: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: "#fd0100"
                                }
                            },
                            title: {
                                text: "Users Updated",
                                style: {
                                    color: "#fd0100"
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        {
                            seriesName: "Income",
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: "#eede04"
                                }
                            },
                            title: {
                                text: "All Users",
                                style: {
                                    color: "#eede04"
                                }
                            }
                        },
                        {
                            seriesName: "Revenue",
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: "#f76915"
                                }
                            },
                            title: {
                                text: "Groups Updated",
                                style: {
                                    color: "#f76915"
                                }
                            }
                        },
                        {
                            seriesName: "Revenue",
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: "#a0d636"
                                }
                            },
                            title: {
                                text: "Group Memberships Updated",
                                style: {
                                    color: "#a0d636"
                                }
                            }
                        },
                        {
                            seriesName: "Revenue",
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: "#2fa236"
                                }
                            },
                            title: {
                                text: "Cosmos Items Updated",
                                style: {
                                    color: "#2fa236"
                                }
                            }
                        }
                    ],
                    fill: {
                        opacity: 1
                    }
                }, series: getUserBreakdown(), type: "bar", height: 350 })),
        React.createElement("hr", null),
        React.createElement("div", null,
            React.createElement(ReactApexChart, { options: {
                    // colors: ["#EB5353", "#F9D923", "#36AE7C", "#FFCD38", "#DEA057", "#FF4949", "#FF8D29", "#2F8F9D", "#3BACB6", "#82DBD8", "#B3E8E5"],
                    chart: {
                        type: "bar",
                        height: 600,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        },
                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                        animations: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: "10px",
                            fontFamily: '"TT Norms", Helvetica, sans-serif',
                            fontWeight: "500"
                        }
                    },
                    title: {
                        text: "Stopwatch",
                        align: "left",
                        style: {
                            fontWeight: 500,
                            fontSize: "16px"
                        }
                        // offsetX: 110
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: "bottom",
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 0,
                            colors: {
                            // backgroundBarColors: ["#000000", "blue"]
                            },
                            dataLabels: {
                                orientation: "vertical"
                            }
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        categories: getOverviewCategories()
                    },
                    legend: {
                        position: "right",
                        offsetY: 40,
                        fontSize: "13px",
                        fontFamily: '"TT Norms", Helvetica, sans-serif',
                        fontWeight: 500
                    },
                    fill: {
                        opacity: 1
                    }
                }, series: getStopwatchBreakdown(), type: "bar", height: 600 })),
        React.createElement("div", null,
            React.createElement(ReactApexChart, { options: {
                    chart: {
                        height: 350,
                        type: "line",
                        zoom: {
                            enabled: false
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: "straight",
                        width: 1
                    },
                    // title: {
                    //    text: "S",
                    //    align: "left"
                    // },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"],
                            opacity: 0.5
                        }
                    },
                    xaxis: {
                        categories: getOverviewCategories()
                    }
                }, series: getStopwatchBreakdown(), type: "line", height: 350 }))));
});
var OrchestryAdminETLCachedFiles = React.memo(function (props) {
    var _a = React.useState(), files = _a[0], setFiles = _a[1];
    var _b = React.useState(true), filesLoading = _b[0], setFilesLoading = _b[1];
    var _c = React.useState(null), file = _c[0], setFile = _c[1];
    var _d = React.useState(null), fileContents = _d[0], setFileContents = _d[1];
    var _e = React.useState(true), fileLoading = _e[0], setFileLoading = _e[1];
    React.useEffect(function () {
        setFilesLoading(true);
        CrawlerService.getETLCachedFiles(props.Tenant.TenantGuid, props.CacheType).then(function (data) {
            setFiles(data);
            setFilesLoading(false);
        });
    }, [props.CacheType]);
    var getFile = function (fileToLoad) {
        if (file == null || (file != null && file.Name !== fileToLoad.Name)) {
            setFile(fileToLoad);
            setFileContents(null);
            setFileLoading(true);
            CrawlerService.getETLCachedFile(props.Tenant.TenantGuid, fileToLoad.Name, props.CacheType).then(function (fileContents) {
                console.log("fileContents", fileContents);
                setFileContents(fileContents);
            });
        }
    };
    var getAuditCounts = function (whatCount) {
        var count = 0;
        if (fileContents != null) {
            if (whatCount == "teamMessages") {
                fileContents.TeamOrGroupUpdates.forEach(function (tu) {
                    tu.ChannelUpdates.forEach(function (cu) {
                        count += cu.PostChangeCount;
                        count += cu.ReplyChangeCount;
                    });
                });
            }
            if (whatCount == "channels") {
                fileContents.TeamOrGroupUpdates.forEach(function (tu) {
                    try {
                        count += tu.ChannelUpdates.length;
                    }
                    catch (_a) { }
                });
            }
            if (whatCount == "files") {
                fileContents.SharePointUpdates.forEach(function (su) {
                    try {
                        count += su.ObjectUpdates.length;
                    }
                    catch (_a) { }
                });
            }
        }
        return count;
    };
    return (React.createElement("div", { className: "orchestry-admin-etl-cached-files" }, filesLoading === true || files == null ? (React.createElement(React.Fragment, null,
        React.createElement(SkeletonLoaderFakeBox, { marginTop: 10 }),
        React.createElement(SkeletonLoaderFakeBox, { marginTop: 10 }),
        React.createElement(SkeletonLoaderFakeBox, { marginTop: 10 }))) : (React.createElement(TableList.Context, { listItems: files, wrappedInPortlet: false, showLoader: false },
        React.createElement(TableList.TableList, null,
            React.createElement(TableList.HeaderItem, null, "File Name"),
            React.createElement(TableList.HeaderItem, null, "Size"),
            React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "filename", onClick: function () { return getFile(item); } },
                        React.createElement(OrchModal, { buttonContents: React.createElement("div", { className: "log-name" }, item.Name.replace(".json", "")), simpleButton: true, headerText: item.Name.replace(".json", ""), size: "xl", modalClassName: "orchestry-crawler-cached-file-modal", modalBgClassName: "orchestry-crawler-cached-file-modal-bg", showCloseButton: true }, fileContents == null ? (React.createElement(React.Fragment, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 200 }))) : (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "stats" }, props.CacheType == "log" ? (React.createElement("ul", null,
                                React.createElement("li", null,
                                    "Sites: ",
                                    React.createElement("strong", null, fileContents.SharePointUpdates.length)),
                                React.createElement("li", null,
                                    "Teams: ",
                                    React.createElement("strong", null, fileContents.TeamOrGroupUpdates.length)),
                                React.createElement("li", null,
                                    "Channels: ",
                                    React.createElement("strong", null, getAuditCounts("channels"))),
                                React.createElement("li", null,
                                    "Messages: ",
                                    React.createElement("strong", null, getAuditCounts("teamMessages"))),
                                React.createElement("li", null,
                                    "Files: ",
                                    React.createElement("strong", null, getAuditCounts("files"))))) : props.CacheType == "reports" ? (React.createElement("ul", null,
                                React.createElement("li", null,
                                    "Records: ",
                                    React.createElement("strong", null, fileContents.length)))) : (React.createElement("ul", null,
                                React.createElement("li", null,
                                    "Characters: ",
                                    React.createElement("strong", null, fileContents.length))))),
                            props.CacheType != "csv" ? (React.createElement(CodeMirror, { value: JSON.stringify(fileContents, null, 2), options: {
                                    mode: "javascript",
                                    foldGutter: true,
                                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                                    // theme: 'material',
                                    lineNumbers: true,
                                    lineWrapping: true
                                    // scrollbarStyle:null
                                }, onBeforeChange: function (editor, data, value) {
                                    // this.setState({value});
                                    // setTextFileContentTempSave(value);
                                }, onChange: function (editor, data, value) {
                                    console.log(editor, data, value);
                                } })) : (React.createElement(CodeMirror, { value: fileContents, options: {
                                    mode: "mathematica",
                                    foldGutter: true,
                                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                                    // theme: 'material',
                                    lineNumbers: true,
                                    lineWrapping: false
                                    // scrollbarStyle:null
                                }, onBeforeChange: function (editor, data, value) {
                                    // this.setState({value});
                                    // setTextFileContentTempSave(value);
                                }, onChange: function (editor, data, value) {
                                    console.log(editor, data, value);
                                } })))))))); }, isMobileTitle: true }),
            React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return React.createElement(React.Fragment, null, formatDataSize(item.ContentLength)); }, className: "td-type" }))))));
});
