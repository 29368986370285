import { getIconClassName } from "@fluentui/react";
import VivaLogo from "app/assets/img/icon-viva-engage.svg";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./team-information-workspace-details.scss";
export var TeamInformationWorkspaceDetails = function () {
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    return (React.createElement("div", { className: "team-information-workspace-details" },
        React.createElement("div", { className: "bottom-stats-title" },
            React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.title.workspace_details", defaultMessage: "Workspace Details" })),
        React.createElement("div", { className: "bottom-stats" },
            teamInformation !== null ? (teamInformation.Created !== null ? (React.createElement("div", { className: "stat" },
                React.createElement("div", { className: "stat-title" },
                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.created", defaultMessage: "Created" })),
                React.createElement("div", { className: "stat-content" }, moment.utc(teamInformation === null || teamInformation === void 0 ? void 0 : teamInformation.Created).format("LL")))) : null) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })),
            teamInformation !== null ? (teamInformation.CreatedWith !== null && teamInformation.CreatedWith !== "" ? (React.createElement("div", { className: "stat" },
                React.createElement("div", { className: "stat-title" },
                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.created_with", defaultMessage: "Created With" })),
                React.createElement("div", { className: "stat-content" }, teamInformation.CreatedWith === "AzurePortal" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "created-with-icon" },
                        React.createElement("i", { className: getIconClassName("AzureLogo") })),
                    React.createElement("div", { className: "created-with-text" },
                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.azure_ad", defaultMessage: "Azure AD Portal" })))) : teamInformation.CreatedWith === "SharePoint" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "created-with-icon" },
                        React.createElement("i", { className: getIconClassName("SharepointLogo") })),
                    React.createElement("div", { className: "created-with-text" },
                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.sharepoint", defaultMessage: "SharePoint" })))) : teamInformation.CreatedWith === "ExchangeAdminCenter" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "created-with-icon" },
                        React.createElement("i", { className: getIconClassName("ExchangeLogo") })),
                    React.createElement("div", { className: "created-with-text" },
                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.exchange_admin", defaultMessage: "Exchange Admin Center" })))) : teamInformation.CreatedWith === "TeamsApp" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "created-with-icon" },
                        React.createElement("i", { className: getIconClassName("TeamsLogo") })),
                    React.createElement("div", { className: "created-with-text" },
                        React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.microsoft_teams", defaultMessage: "Microsoft Teams" })))) : teamInformation.CreatedWith === "Orchestry" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "created-with-icon" },
                        React.createElement("img", { src: "/favicon-16x16.png" })),
                    React.createElement("div", { className: "created-with-text" }, "Orchestry"))) : teamInformation.CreatedWith === "VivaEngage" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "created-with-icon" },
                        React.createElement("img", { src: VivaLogo })),
                    React.createElement("div", { className: "created-with-text" }, "Viva Engage"))) : null))) : null) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })),
            teamInformation !== null ? (teamInformation.SensitivityLabel !== null ? (React.createElement("div", { className: "stat" },
                React.createElement("div", { className: "stat-title" },
                    React.createElement(FormattedMessage, { id: "page.archival_approval.stat_label.sensitivity_label", defaultMessage: "Sensitivity Label" })),
                React.createElement("div", { className: "stat-content" }, teamInformation.SensitivityLabel))) : null) : null)));
};
