import * as AsyncStore from "app/store/async";
import * as LibraryConfigurationCurrentStore from "app/store/library-configuration/current";
export var libraryConfigurationNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Updating <strong>library configuration</strong>",
                ActionType: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Update
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>Library configuration</strong> successfully updated.",
                ActionType: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Update
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when updating <strong>library configuration</strong>.",
                ActionType: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Update
            },
            {
                Text: "Error when trying to load library configuration.",
                ActionType: LibraryConfigurationCurrentStore.LibraryConfigurationCurrentActionTypes.LibraryConfiguration_Current_Load
            }
        ]
    }
];
