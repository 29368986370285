var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DirectorySearchActionTypes } from "./types";
//import mergeDeep from 'app/utils/mergeDeep';
import { merge, cloneDeep } from "lodash";
var initialState = {
    IsLoading: true,
    IsFavsLoading: true,
    IsRefinersLoading: true,
    Items: [],
    Favs: [],
    Context: {
        View: "List",
        SearchTerm: "",
        Sort: "Created",
        SortDirection: "desc"
    },
    Refiners: [],
    Paging: {
        CurrentPage: 0,
        ResultsPerPage: 10,
        TotalResults: 0
    },
    Columns: []
};
// const compare = ( a:Directory, b:Directory ) => {
//     if(a.Title !== undefined && b.Title !== undefined){
//         if ( a.Title.toLowerCase() < b.Title.toLowerCase() ){
//         return -1;
//         }
//         if ( a.Title.toLowerCase() > b.Title.toLowerCase() ){
//         return 1;
//         }
//     }
//     return 0;
// }
export function directorySearchReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DirectorySearchActionTypes.Directory_Load: {
            //let newState = cloneDeep(state);
            //newState = merge(newState, action.payload, { IsLoading: false, } as DirectorySearchState);
            //console.log("Load Middle Ware", window.innerWidth, action.payload)
            var updatedRequest = merge(action.payload, { IsLoading: false });
            //updatedRequest.IsFavsLoading = state.IsFavsLoading;
            //updatedRequest.IsRefinersLoading = state.IsRefinersLoading;
            // I know this is horrible, but I hate the flash of this changing on mobile screens
            if (window.innerWidth < 769) {
                updatedRequest.Context.View = "Cards";
                updatedRequest.Paging.ResultsPerPage = 12;
            }
            updatedRequest.IsFavsLoading = state.IsFavsLoading;
            updatedRequest.IsRefinersLoading = state.IsRefinersLoading;
            updatedRequest.Favs = state.Favs;
            updatedRequest.Refiners = state.Refiners;
            updatedRequest.Context.ShowFavs = action.payload.Context.ShowFavs;
            return updatedRequest;
        }
        case DirectorySearchActionTypes.Directory_Load_Refiners: {
            //const updatedRequest:DirectorySearchState = merge(state, { Refiners : action.payload.Refiners });
            state.Refiners = action.payload.Refiners;
            state.IsRefinersLoading = false;
            return state;
        }
        case DirectorySearchActionTypes.Directory_Load_Favs: {
            //const updatedRequest:DirectorySearchState = merge(state, { Refiners : action.payload.Refiners });
            state.Favs = action.payload.Favs;
            state.IsFavsLoading = false;
            return state;
        }
        case DirectorySearchActionTypes.Directory_Load_RefinersAndFavs: {
            //const updatedRequest:DirectorySearchState = merge(state, { Refiners : action.payload.Refiners });
            //let newState = cloneDeep(state);
            state.Favs = action.payload.Favs;
            state.Refiners = action.payload.Refiners;
            state.IsFavsLoading = false;
            state.IsRefinersLoading = false;
            return cloneDeep(state);
        }
        case DirectorySearchActionTypes.Directory_Search: {
            return __assign({}, state);
        }
        case DirectorySearchActionTypes.Directory_Update_Paging: {
            var updatedRequest = merge(state, action.payload, { IsLoading: false });
            //updatedRequest.Paging.PrevPageUrls = action.payload.Paging.PrevPageUrls;
            updatedRequest.Items = action.payload.Items;
            return __assign({}, updatedRequest);
        }
        case DirectorySearchActionTypes.Directory_Update_Refiners: {
            //const updatedRequest = merge(state, action.payload);
            var updatedRequest = merge(state, action.payload, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            updatedRequest.Paging = action.payload.Paging;
            //const updatedRequest = Object.assign({}, state, action.payload);
            //return {...updatedRequest};
            return __assign({}, updatedRequest);
        }
        case DirectorySearchActionTypes.Directory_Refresh: {
            //const updatedRequest = merge(state, action.payload);
            //const updatedRequest = merge(state, action.payload, { IsLoading: false});
            //const updatedRequest = cloneDeep(state);
            var updatedRequest = merge(state, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            //const updatedRequest = Object.assign({}, state, action.payload);
            //return {...updatedRequest};
            return __assign({}, updatedRequest);
        }
        case DirectorySearchActionTypes.Directory_Update_Context: {
            var updatedRequest = merge(state, action.payload, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            return __assign({}, updatedRequest);
        }
        case DirectorySearchActionTypes.Directory_Update_View: {
            var updatedRequest = merge(state, action.payload, { IsLoading: false });
            updatedRequest.Items = action.payload.Items;
            return __assign({}, updatedRequest);
        }
        case DirectorySearchActionTypes.Directory_Update_Favs: {
            //console.log("Favs", action.payload.Favs);
            var updatedRequest = state;
            updatedRequest.Favs = action.payload.Favs;
            return updatedRequest;
        }
        case DirectorySearchActionTypes.Directory_Update_IsLoading: {
            state.IsLoading = action.payload;
            return state;
        }
        case DirectorySearchActionTypes.Directory_Clear: {
            return __assign({}, initialState);
        }
        default:
            return state;
    }
}
