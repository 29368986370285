import * as React from "react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { wrapLink, unwrapLink, isLinkActive } from "../helpers";
import { useSlate } from "slate-react";
import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";
import { Button } from "react-bootstrap/";
import "./toolbar.scss";
import OrchModal from "../../orch-modal";
var LinkOverlay = function (_a) {
    var setinputDirty = _a.setinputDirty, inputDirty = _a.inputDirty, inputRef = _a.inputRef, setshowOverlay = _a.setshowOverlay;
    var editor = useSlate();
    var _b = React.useState("https://"), linkValue = _b[0], setlinkValue = _b[1];
    var handleSubmit = function () {
        //@ts-ignore
        insertLink(editor, linkValue);
        setshowOverlay(false);
        setinputDirty(false);
    };
    React.useEffect(function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, []);
    return (React.createElement("div", { className: "link-input" },
        React.createElement("input", { ref: inputRef, onKeyDown: function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSubmit();
                }
                if (e.keyCode === 27) {
                    e.preventDefault();
                    if (!linkValue || linkValue === "" || linkValue === "https://")
                        setinputDirty(false);
                }
            }, onBlur: function () {
                if (!linkValue || linkValue === "" || linkValue === "https://")
                    setinputDirty(false);
            }, value: linkValue, onChange: function (e) {
                setlinkValue(e.target.value);
                setinputDirty(true);
            }, placeholder: "Enter Link" }),
        React.createElement(Button, { onClick: handleSubmit, variant: "primary" }, "Add")));
};
export var AddLinkButton = function (_a) {
    var icon = _a.icon;
    var _b = React.useState(false), showOverlay = _b[0], setshowOverlay = _b[1];
    var _c = React.useState(false), inputDirty = _c[0], setinputDirty = _c[1];
    var _d = React.useState(false), showLinkCancelModal = _d[0], setshowLinkCancelModal = _d[1];
    var inputRef = React.useRef();
    return (React.createElement("div", null,
        React.createElement(FontIcon, { id: "add-link", iconName: icon, title: "Add Link", onClick: function () {
                var _a;
                setshowOverlay(function (value) { return !value; });
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            } }),
        showOverlay && (React.createElement(Callout, { className: "form-markdown-add-link-callout", onDismiss: function (e) {
                if (!inputDirty)
                    setshowOverlay(false);
                else
                    setshowLinkCancelModal(true);
            }, directionalHint: DirectionalHint.topCenter, target: "#add-link" },
            React.createElement(LinkOverlay, { inputDirty: true, setshowOverlay: setshowOverlay, inputRef: inputRef, setinputDirty: setinputDirty }))),
        React.createElement(OrchModal, { showModalOverride: showLinkCancelModal, size: "sm", footerPrimaryText: "Yes", footerCloseText: "No", showModalToggleOverride: setshowLinkCancelModal, footerPrimaryFunction: function () {
                setshowLinkCancelModal(false);
                setshowOverlay(false);
                setinputDirty(false);
            }, children: React.createElement("div", null, "Close without adding link?") })));
};
export var RemoveLinkButton = function (_a) {
    var icon = _a.icon;
    var editor = useSlate();
    return (React.createElement(FontIcon, { iconName: icon, title: "Remove Link", onClick: function (e) {
            var _a;
            //@ts-ignore
            if (isLinkActive(editor)) {
                //@ts-ignore
                unwrapLink(editor);
                (_a = document.getElementById("editable")) === null || _a === void 0 ? void 0 : _a.focus();
            }
        } }));
};
var insertLink = function (editor, url) {
    if (editor.selection) {
        wrapLink(editor, url);
    }
};
