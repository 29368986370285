import DisableArea from "app/components/common/disable-area";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PortletAlert from "app/components/common/portlet-alert";
import PrincipalItem from "app/components/common/principal-item";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import LifecycleService from "app/services/lifecycle";
import { popupCenter } from "app/utils/popupCenter";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./settings-lifecycle.scss";
var SettingsLifecycle = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var _a = React.useState(false), showIsNotAdmin = _a[0], setShowIsNotAdmin = _a[1];
    var _b = React.useState(), delegateUsers = _b[0], setDelegateUsers = _b[1];
    var _c = React.useState(), permissionsStatus = _c[0], setPermissionsStatus = _c[1];
    React.useEffect(function () {
        loadDelegates();
        loadPermissionStatus();
    }, []);
    var loadDelegates = function () {
        LifecycleService.InstallStatusDelegateUsers().then(function (users) {
            setDelegateUsers(users);
        });
    };
    var loadPermissionStatus = function () {
        LifecycleService.InstallStatusPermissions().then(function (permissions) {
            setPermissionsStatus(permissions);
        });
    };
    var urlPrefix = window.location.origin;
    var openAppPopupWindow = function () {
        popupCenter(urlPrefix + "/Account/ServiceAppGrantPermissionsStart/", "Orchestry Login", 600, 700);
        //setPopupWindow(window.open(urlPrefix + "/Account/SignIn/","", "width=500,height=700"));
    };
    var openDelegatePopupWindow = function () {
        popupCenter(urlPrefix + "/Account/ServiceAppSignIn/", "Orchestry Login", 600, 700);
        //setPopupWindow(window.open(urlPrefix + "/Account/SignIn/","", "width=500,height=700"));
    };
    // Listen for popup callback.
    React.useEffect(function () {
        //@ts-ignore
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        //@ts-ignore
        eventer(messageEvent, 
        //@ts-ignore
        function (e) {
            //console.log('origin: ', e.origin)
            //console.log('parent received message!: ', e.data);
            if (e.data !== undefined && e.data.error !== undefined) {
                if (e.data.error === true) {
                    setShowIsNotAdmin(true);
                }
                else {
                    if (e.data.type === "app-only") {
                        console.log("APP ONLY!");
                        // setLoadingAfterConsent(true);
                        // setShowConsentSuccess(true);
                        setPermissionsStatus(undefined);
                        loadPermissionStatus();
                        // alert("worked!");
                    }
                    if (e.data.type === "delegate") {
                        setDelegateUsers(undefined);
                        loadDelegates();
                        // setCurrentUserDelegate(true);
                    }
                }
            }
        }, false);
    }, []);
    var shouldShowUptoDateMessage = function () {
        if (permissionsStatus !== undefined && permissionsStatus.ServiceAppPermissionVersion !== undefined && permissionsStatus.ServiceAppLastConsentedVersion !== undefined) {
            if (permissionsStatus.ServiceAppPermissionVersion === permissionsStatus.ServiceAppLastConsentedVersion) {
                return true;
            }
        }
        return false;
    };
    var shouldShowUpgradeMessage = function () {
        if (permissionsStatus !== undefined && permissionsStatus.ServiceAppPermissionVersion !== undefined && permissionsStatus.ServiceAppLastConsentedVersion !== undefined) {
            if (permissionsStatus.ServiceAppPermissionVersion > permissionsStatus.ServiceAppLastConsentedVersion) {
                return true;
            }
        }
        return false;
    };
    var addDotsToVersion = function (version) {
        if (version !== undefined) {
            var splitVersion = ("" + version).split("");
            return splitVersion.join(".");
        }
        return "";
    };
    return (React.createElement("div", { className: "settings-lifecycle fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Settings" }),
        React.createElement(PageHeader.SetRightContent, null),
        React.createElement(Row, null,
            React.createElement(Col, { md: 9 },
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Lifecycle Tenant Consent" }),
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "lifecycle-install-instructions" },
                            React.createElement("div", { className: "teams-app-install-choice" },
                                !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                                React.createElement("div", { className: "permission-options" },
                                    React.createElement("div", { className: "permissions-area" },
                                        React.createElement("div", { className: "permissions-icon" },
                                            React.createElement("i", { className: "la la-server" })),
                                        React.createElement("div", { className: "permissions-content" },
                                            React.createElement("h4", null, "Azure AD App Permissions"),
                                            React.createElement("h5", { className: "required" }, "Required"),
                                            permissionsStatus === undefined ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: "text-description" },
                                                    "Orchestry uses",
                                                    " ",
                                                    React.createElement("a", { href: "https://docs.microsoft.com/en-us/graph/security-authorization", target: "_blank", rel: "noopener noreferrer" }, "App-Only"),
                                                    " ",
                                                    "permissions to gather information about your tenant's workspaces."),
                                                React.createElement("div", { className: "install-item" + (shouldShowUptoDateMessage() ? "" : " needs-update") },
                                                    React.createElement("div", { className: "status-icon" + (shouldShowUptoDateMessage() ? " yes" : " no") }, shouldShowUptoDateMessage() ? React.createElement("i", { className: "la la-check" }) : React.createElement("i", { className: "la la-times" })),
                                                    React.createElement("div", { className: "right" },
                                                        React.createElement("div", { className: "title" }, shouldShowUptoDateMessage()
                                                            ? "You've consented to the latest version"
                                                            : shouldShowUpgradeMessage()
                                                                ? "Your Lifecycle consent is out of date"
                                                                : "It doesn't look like you've consented to Orchestry's Lifecycle permissions yet"),
                                                        shouldShowUptoDateMessage() ? (React.createElement("div", { className: "description" }, "lifecycle-consent-version " + addDotsToVersion(permissionsStatus.ServiceAppPermissionVersion))) : (React.createElement(React.Fragment, null,
                                                            React.createElement("div", { className: "description" },
                                                                "lifecycle-consent-version",
                                                                " ",
                                                                React.createElement("span", { className: "version-highlight" }, permissionsStatus.ServiceAppLastConsentedVersion !== null ? addDotsToVersion(permissionsStatus.ServiceAppLastConsentedVersion) : "0.0.0"),
                                                                " ",
                                                                "\u00BB ",
                                                                React.createElement("span", { className: "version-highlight" }, addDotsToVersion(permissionsStatus.ServiceAppPermissionVersion))))))),
                                                !shouldShowUptoDateMessage() && (React.createElement(DisableArea, { isEnabled: orchestryContext.IsCurrentUserAzureGlobalAdmin, useOverlay: true },
                                                    React.createElement("div", { className: "ms-login" },
                                                        React.createElement("div", { className: "custom-button", onClick: function () { return openAppPopupWindow(); } }, "Review Permissions and Grant Consent")))))))),
                                    React.createElement("div", { className: "permissions-area" },
                                        React.createElement("div", { className: "permissions-icon" },
                                            React.createElement("i", { className: "la la-users-cog" })),
                                        React.createElement("div", { className: "permissions-content" },
                                            React.createElement("h4", null, "Delegate Permissions"),
                                            React.createElement("h5", null, "Optional"),
                                            delegateUsers === undefined ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement("div", { className: "content-wrap" },
                                                React.createElement("div", { className: "text-and-button" },
                                                    React.createElement("div", { className: "text-description" }, "Orchestry will use Delegate permissions to login as a user to gather information about your tenant's workspaces. Delegate permissions unlock additional information via the Microsoft Graph that are not available using App-Only access."),
                                                    currentUser !== undefined && delegateUsers.some(function (du) { return du.Id === currentUser.Id; }) ? (React.createElement("div", { className: "completed-task" },
                                                        React.createElement("i", { className: "las la-user-check" }),
                                                        " Delegate Sign-in Complete")) : (React.createElement(DisableArea, { isEnabled: orchestryContext.IsCurrentUserAzureGlobalAdmin, useOverlay: true },
                                                        React.createElement("div", { className: "ms-login" },
                                                            React.createElement("div", { className: "custom-button", onClick: function () { return openDelegatePopupWindow(); } }, "Sign-in Delegate Account"))))),
                                                React.createElement("div", { className: "permissions-current-delegates" },
                                                    React.createElement("div", { className: "delegates-title" }, "Signed-in Delegates"),
                                                    React.createElement("div", { className: "form-user-group-select" },
                                                        React.createElement("div", { className: "group-area form-control" }, delegateUsers.length > 0 ? (delegateUsers.map(function (user) { return React.createElement(PrincipalItem, { principal: user, showBadge: true }); })) : (React.createElement("div", { className: "no-delegates" }, "There are no signed-in delegates for this tenant")))))))))))))))),
        ":",
        React.createElement(Row, null,
            React.createElement(Col, { md: 6 }))));
});
export default SettingsLifecycle;
