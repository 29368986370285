var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./library-feature-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as LibraryFeatureListStore from "app/store/library-features/list";
import * as LibraryFeatureCurrentStore from "app/store/library-features/current";
import generateGuid from "app/utils/generateGuid";
import { Row, Col, Form, Container, Nav, Tab, Modal } from "react-bootstrap";
import AssociateTemplate from "app/components/common/associate-template";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { SubtitleButton } from "app/components/common/subtitle";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import * as LibraryConfigurationCurrentStore from "app/store/library-configuration/current";
import Store from "app/store";
import FormFileUploadBox from "app/components/common/form-fileuploadbox";
import FileService from "app/services/files";
import { AsyncActionCreators } from "app/store/async/actions";
import FormImageUploadGallery from "app/components/common/form-image-upload-gallery";
import { Portlet, PortletBody } from "app/components/common/portlet";
import FormValidationContext from "app/components/common/form-validation";
import { FormValidator } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import lineAwesomeHelper from "app/utils/lineAwesomeHelper";
import DocumentTemplatePreview from "app/components/common/document-template-preview";
import FeatureFiles from "app/components/tools/feature-files";
import SaveProgression from "app/components/common/save-progression";
import AnimatedLoader from "app/components/common/animated-loader";
var LibraryFeatureDetails = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(props.guid), lookupGuid = _a[0], setLookupGuid = _a[1];
    var currentLibraryFeature = useSelector(function (state) { return state.libraryFeatureCurrentState.item; });
    var listLibraryFeatures = useSelector(function (state) { return state.libraryFeatureListState.items; });
    var _b = React.useState(false), backToList = _b[0], setBackToList = _b[1];
    var _c = React.useState(window.location.hash !== "" ? window.location.hash.replace("#", "") : "details"), hash = _c[0], setHash = _c[1];
    var _d = React.useState(), fileUploadPercentage = _d[0], setFileUploadPercentage = _d[1];
    var _e = React.useState(false), hasAdded = _e[0], setHasAdded = _e[1];
    var _f = React.useState(false), formValid = _f[0], setFormValid = _f[1];
    var iconOptions = React.useState(lineAwesomeHelper.getIconOptionsForDropDown())[0];
    //const [hasAdded, setHasAdded] = React.useState(false);
    var _g = React.useState(false), showModal = _g[0], setShowModal = _g[1];
    var _h = React.useState([
        {
            Title: "Save Feature Information",
            Type: "item",
            Method: function () {
                return saveItemAsync();
            }
        }
    ]), saveJobs = _h[0], setSaveJobs = _h[1];
    var _j = React.useState(""), uploadFileName = _j[0], setUploadFileName = _j[1];
    var currentLibraryConfiguration = useSelector(function (state) { return state.libraryConfigurationCurrentState.item; });
    var _k = React.useState(), featureCategories = _k[0], setFeatureCategories = _k[1];
    React.useEffect(function () {
        dispatch(LibraryConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (currentLibraryConfiguration.GUID !== undefined) {
            if (currentLibraryConfiguration.GUID !== "") {
                if (currentLibraryConfiguration.FeatureCategoriesJson !== undefined &&
                    currentLibraryConfiguration.FeatureCategoriesJson !== null &&
                    currentLibraryConfiguration.FeatureCategoriesJson !== "") {
                    var availableCats = JSON.parse(currentLibraryConfiguration.FeatureCategoriesJson);
                    if (availableCats !== undefined && availableCats.length !== 0) {
                        var newOptionArray_1 = [];
                        availableCats.forEach(function (cat, index) {
                            newOptionArray_1.push({
                                id: index + "",
                                title: cat,
                                value: cat
                            });
                        });
                        setFeatureCategories(newOptionArray_1);
                    }
                }
            }
        }
    }, [currentLibraryConfiguration]);
    // Should only be run once.
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (hasAdded === false) {
            if (currentLibraryFeature.GUID !== undefined) {
                dispatch(LibraryFeatureCurrentStore.Clear());
            }
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            //Clear the current template when navigating to the new template page.
            var guid = generateGuid();
            // Add a new template with guid.
            dispatch(LibraryFeatureCurrentStore.Update({ GUID: guid, AssociatedTemplates: [] }));
            setHasAdded(true);
        }
        else {
            //setHasAdded(true);
            // Load the /edit/guid load.
            dispatch(LibraryFeatureCurrentStore.Load({ GUID: lookupGuid }));
            setHasAdded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid, listLibraryFeatures]);
    var clearCurrentLibraryFeature = function () {
        dispatch(LibraryFeatureCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(LibraryFeatureCurrentStore.Clear());
        };
    }, []);
    var saveAndAddModifyLibraryFeature = function (forceUpdate) {
        //if(formValid){
        //let asyncCallType:AsyncCallType = AsyncCallType.Update;
        var state = Store.getState();
        var currentLibraryFeature = state.libraryFeatureCurrentState.item;
        if (lookupGuid === undefined || (forceUpdate !== undefined && forceUpdate === false)) {
            // First, add the new template to template list
            //asyncCallType = AsyncCallType.Add;
            dispatch(LibraryFeatureListStore.AddItem(currentLibraryFeature));
            //setHasAdded(true);
        }
        else {
            dispatch(LibraryFeatureListStore.UpdateItem(currentLibraryFeature));
        }
        // console.log("Send back to list 1",asyncCallType);
        // AsyncWait(undefined, AsyncStatus.Succeeded, currentLibraryFeature.GUID).then(()=>{
        //     console.log("Send back to list 2");
        //     setBackToList(true);
        //     dispatch(LibraryFeatureCurrentStore.Clear());
        // })
        //}
    };
    var saveItemAsync = function () {
        return new Promise(function (resolve, reject) {
            var state = Store.getState();
            var currentLibraryFeature = state.libraryFeatureCurrentState.item;
            dispatch(LibraryFeatureListStore.UpdateItem(currentLibraryFeature));
            var asyncCallType = AsyncCallType.Update;
            //  if(lookupGuid === undefined){
            //      asyncCallType = AsyncCallType.Add;
            //  }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentLibraryFeature.GUID).then(function () {
                //@ts-ignore
                resolve("");
            });
            ///reject();
        });
    };
    var onAssociatedAdd = function (templateToAdd) {
        //console.log("ADD Ass Details > ", templateToAdd)
        var tempArray = [templateToAdd];
        //setAssociatedTemplates([...tempArray, ...currentLibraryFeature.AssociatedTemplates]);
        if (currentLibraryFeature.AssociatedTemplates !== undefined) {
            if (currentLibraryFeature.AssociatedTemplates.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), currentLibraryFeature.AssociatedTemplates, true);
            }
        }
        dispatch(LibraryFeatureCurrentStore.Update({ AssociatedTemplates: tempArray }));
    };
    var onAssociatedRemove = function (tbaToRemove) {
        var _a;
        dispatch(LibraryFeatureCurrentStore.Update({
            AssociatedTemplates: (_a = currentLibraryFeature.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    var setHashAnchor = function (newHash) {
        window.location.hash = newHash;
    };
    var onUploadProgress = function (progress) {
        setFileUploadPercentage((progress.loaded / progress.total) * 100 + "%");
        console.log("Progress", progress);
    };
    var uploadFileAsync = function (file, fileName) {
        return new Promise(function (resolve, reject) {
            var continueUpload = function () {
                var state = Store.getState();
                var currentLibraryFeature = state.libraryFeatureCurrentState.item;
                if (file !== undefined && currentLibraryFeature.GUID !== undefined) {
                    var formData = new FormData();
                    formData.append("file", file);
                    formData.append("feature-guid", currentLibraryFeature.GUID);
                    FileService.uploadDocument(formData, "/api/libraryfeatures/UploadPNPFile", onUploadProgress).then(function () {
                        console.log("upload done");
                        setFileUploadPercentage(undefined);
                        var tempState = Store.getState();
                        var findAI = tempState.asyncState.items.find(function (i) { return i.key === currentLibraryFeature.GUID; });
                        if (findAI !== undefined) {
                            console.log("Found Async Action", findAI);
                            dispatch(AsyncActionCreators.Invalid(findAI));
                        }
                    });
                }
            };
            continueUpload();
        });
    };
    var addFile = function (file, fileName) {
        var tempArray = __spreadArray(__spreadArray([], saveJobs.filter(function (file) { return file.Type !== "file"; }), true), [
            {
                Title: "Upload " + fileName,
                Type: "file",
                Method: function () {
                    return uploadFileAsync(file, fileName);
                }
            }
        ], false);
        //@ts-ignore
        setSaveJobs(tempArray);
        setUploadFileName(fileName);
        var fileExtension = fileName !== undefined ? fileName.split(".").pop() : "";
        var state = Store.getState();
        var currentLibraryFeature = state.libraryFeatureCurrentState.item;
        // if (currentLibraryFeature.Title != undefined && currentLibraryFeature.Title !== "") {
        //@ts-ignore
        dispatch(LibraryFeatureCurrentStore.Update({ Title: fileName.replace("." + fileExtension, ""), Type: fileExtension }));
        // } else {
        //  dispatch(FeatureCurrentStore.Update({Type:fileExtension}));
        // }
    };
    var onFileRemove = function () {
        if (currentLibraryFeature !== undefined && currentLibraryFeature.ConfigFile !== undefined && currentLibraryFeature.ConfigFile !== null && currentLibraryFeature.ConfigFile !== "") {
            dispatch(LibraryFeatureCurrentStore.Update({ ConfigFile: undefined }));
        }
    };
    var EnsureFeatureCreatedBeforeImageUpload = function () {
        return new Promise(function (resolve, reject) {
            var state = Store.getState();
            var currentFeatTemp = state.libraryFeatureCurrentState.item;
            if (lookupGuid === undefined) {
                //console.log("current feature lookupGuid", lookupGuid);
                dispatch(LibraryFeatureListStore.AddItem(currentFeatTemp));
                AsyncWait(AsyncCallType.Add, AsyncStatus.Succeeded, currentFeatTemp.GUID).then(function () {
                    //setHasAdded(true);
                    setLookupGuid(currentFeatTemp.GUID);
                    //@ts-ignore
                    resolve("");
                });
            }
            else {
                //@ts-ignore
                resolve("");
            }
        });
    };
    var redirect = function () {
        setBackToList(true);
        dispatch(LibraryFeatureCurrentStore.Clear());
    };
    var onExtractedSelected = function (fileName) {
        var tempArray = __spreadArray(__spreadArray([], saveJobs.filter(function (file) { return file.Type !== "file"; }), true), [
            {
                Title: "Copy extracted file to feature > " + fileName,
                Type: "file",
                Method: function () {
                    return copyAzureFileAsync(fileName);
                }
            }
        ], false);
        //@ts-ignore
        setSaveJobs(tempArray);
        setUploadFileName(fileName);
        setShowModal(false);
        var state = Store.getState();
        var fileExtension = fileName !== undefined ? fileName.split(".").pop() : "";
        var currentLibraryFeature = state.libraryFeatureCurrentState.item;
        if (currentLibraryFeature.Title === undefined ||
            (currentLibraryFeature.Title !== undefined && currentLibraryFeature.Title === null) ||
            (currentLibraryFeature.Title !== undefined && currentLibraryFeature.Title === "")) {
            //@ts-ignore
            dispatch(LibraryFeatureCurrentStore.Update({ Title: fileName.replace("." + fileExtension, ""), Type: fileExtension }));
        }
        else {
            //  dispatch(FeatureCurrentStore.Update({Type:fileExtension}));
        }
    };
    var copyAzureFileAsync = function (fileName) {
        return new Promise(function (resolve, reject) {
            var continueCopyExtracted = function () {
                var state = Store.getState();
                var currentLibraryFeature = state.libraryFeatureCurrentState.item;
                if (fileName !== undefined && currentLibraryFeature.GUID !== undefined) {
                    var formData = new FormData();
                    formData.append("fileName", fileName);
                    formData.append("feature-guid", currentLibraryFeature.GUID);
                    FileService.copyAzureDocument(formData, "/api/LibraryFeatures/CopyAzureFile").then(function () {
                        var tempState = Store.getState();
                        var findAI = tempState.asyncState.items.find(function (i) { return i.key === currentLibraryFeature.GUID; });
                        if (findAI !== undefined) {
                            console.log("Found Async Action", findAI);
                            dispatch(AsyncActionCreators.Invalid(findAI));
                        }
                        resolve();
                    });
                }
            };
            continueCopyExtracted();
        });
    };
    return (React.createElement("div", { className: "libraryfeature-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "LibraryFeatureValidation" },
            backToList && React.createElement(Redirect, { to: "/orchestry-admin/library/features" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Feature Library", subTitle: props.guid ? "Edit" : "New", titleLink: "/orchestry-admin/library/features" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/orchestry-admin/library/features", onClick: function () { return clearCurrentLibraryFeature(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement(SaveProgression, { OnComplete: function () { return redirect(); }, Jobs: saveJobs },
                    React.createElement("div", { style: { display: "inline-block" } },
                        React.createElement(SubtitleButton, { title: "Save", variant: "success" })))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            currentLibraryFeature.GUID !== undefined ? (React.createElement(Tab.Container, { id: "libraryfeature-tabs", defaultActiveKey: hash },
                React.createElement(Portlet, null,
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "nav nav-tabs nav-tabs-line " },
                            React.createElement(Nav, { as: "ul" },
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("details"); } },
                                    React.createElement(Nav.Link, { eventKey: "details" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-clipboard-list" }),
                                            " Details"))),
                                React.createElement(Nav.Item, { as: "li", onClick: function () { return setHashAnchor("configuration"); } },
                                    React.createElement(Nav.Link, { eventKey: "configuration" },
                                        React.createElement("span", null,
                                            React.createElement("i", { className: "fa fa-cog" }),
                                            " Configuration"))))),
                        React.createElement(Tab.Content, null,
                            React.createElement(Tab.Pane, { eventKey: "details" },
                                React.createElement(Container, { fluid: true },
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                                                React.createElement(Form.Label, null,
                                                    "Name ",
                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                React.createElement(Form.Control, { placeholder: "Enter Name", defaultValue: currentLibraryFeature.Title, onChange: function (event) { return dispatch(LibraryFeatureCurrentStore.Update({ Title: event.target.value })); } }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "name", Validators: ["string-not-empty", "string-is-more-than-2"], Value: currentLibraryFeature.Title })),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-description" },
                                                React.createElement(Form.Label, null, "Description (Optional)"),
                                                React.createElement(Form.Control, { as: "textarea", placeholder: "Tell people the purpose of this feature", rows: 3, defaultValue: currentLibraryFeature.Description, onChange: function (event) { return dispatch(LibraryFeatureCurrentStore.Update({ Description: event.target.value })); } })),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-icons" },
                                                React.createElement(Form.Label, null,
                                                    "Icons ",
                                                    React.createElement("span", { className: "required-astrix" }, "*")),
                                                React.createElement(FormDropdownSelect, { list: iconOptions, theme: "dark-icons", placeholder: "Please select icon", enableSearch: true, defaultValue: currentLibraryFeature.Icon, waitToRenderDDItemsUntilOpen: true, onChange: function (value) { return dispatch(LibraryFeatureCurrentStore.Update({ Icon: value })); } }),
                                                React.createElement(FormValidator, { UniqueIdentifier: "icon", Validators: ["dropdown-has-value"], Value: currentLibraryFeature.Icon })),
                                            React.createElement(Form.Group, { className: "form-group", controlId: "control-type" },
                                                React.createElement(Form.Label, null, "Library Category"),
                                                featureCategories !== undefined ? (React.createElement(FormDropdownSelect, { list: featureCategories, defaultValue: currentLibraryFeature.Category, onChange: function (value) { return dispatch(LibraryFeatureCurrentStore.Update({ Category: value })); } })) : null),
                                            React.createElement("div", { className: "divider-line", style: { marginTop: "80px" } }),
                                            React.createElement("h3", null, "Associated templates"),
                                            React.createElement("div", null, currentLibraryFeature.GUID !== undefined && (React.createElement(AssociateTemplate, { associatedTemplates: currentLibraryFeature.AssociatedTemplates ? currentLibraryFeature.AssociatedTemplates : [], onAssociatedAdd: function (ass) {
                                                    onAssociatedAdd(ass);
                                                }, onAssociatedRemove: function (ass) {
                                                    onAssociatedRemove(ass);
                                                }, libraryMode: true },
                                                React.createElement("div", { className: "at-search" },
                                                    React.createElement(AssociateTemplate.Search, null)),
                                                React.createElement("div", { className: "at-count" },
                                                    React.createElement(AssociateTemplate.Count, null)),
                                                React.createElement("div", { className: "at-items" },
                                                    React.createElement(AssociateTemplate.SelectedItems, null)))))),
                                        React.createElement(Col, { md: 6, lg: 7, xl: 8, className: "right-col" },
                                            React.createElement("div", { className: "preview-images" }, currentLibraryFeature !== undefined &&
                                                currentLibraryFeature.GUID !== undefined &&
                                                currentLibraryFeature.Title !== undefined &&
                                                currentLibraryFeature.Title !== null &&
                                                currentLibraryFeature.Title !== "" ? (React.createElement(FormImageUploadGallery, { ImageGuids: currentLibraryFeature.ImageJsonData, OnImageGuidsChange: function (guid) {
                                                    dispatch(LibraryFeatureCurrentStore.Update({ ImageJsonData: guid }));
                                                }, ApiUrlDelete: "/api/libraryfeatures/DeleteImage", ApiUrlDownload: "/api/libraryfeatures/DownloadImage", ApiUrlGet: "/api/libraryfeatures/LoadImage", ApiUrlUpload: "/api/libraryfeatures/UploadImage", ItemGuid: currentLibraryFeature.GUID, BeforeUpload: EnsureFeatureCreatedBeforeImageUpload })) : null),
                                            currentLibraryFeature !== undefined &&
                                                currentLibraryFeature.GUID !== undefined &&
                                                currentLibraryFeature.Title !== undefined &&
                                                currentLibraryFeature.Title !== null &&
                                                currentLibraryFeature.Title !== "" ? (React.createElement(FormValidator, { UniqueIdentifier: "images", ShowFirst: true, ShowAsInfo: true, Validators: ["string-not-empty"], ErrorMessage: "Attach at least one image", Value: currentLibraryFeature.ImageJsonData })) : null)))),
                            React.createElement(Tab.Pane, { eventKey: "configuration" },
                                React.createElement(Row, null,
                                    React.createElement(Col, { md: 8, lg: 7, xl: 6 },
                                        React.createElement(Form.Group, { controlId: "config-type", className: "form-group radio-green" },
                                            React.createElement(Form.Label, null, "Please upload a PnP (Patterns and Practices) .xml/.pnp file or extract a template to use."),
                                            React.createElement(React.Fragment, null, currentLibraryFeature.ConfigFile !== undefined && currentLibraryFeature.ConfigFile !== null && currentLibraryFeature.ConfigFile !== "" ? (React.createElement("div", { className: "document-preview" },
                                                React.createElement(DocumentTemplatePreview, { fileName: currentLibraryFeature.ConfigFile, azureLink: "/api/libraryfeatures/Download?FeatureGuid=" + currentLibraryFeature.GUID.toString(), OnRemove: function () {
                                                        onFileRemove();
                                                    }, showFileName: true }))) : (React.createElement(React.Fragment, null, uploadFileName !== "" ? (React.createElement("div", { className: "document-preview" },
                                                React.createElement(DocumentTemplatePreview, { fileName: uploadFileName, OnRemove: function () {
                                                        setUploadFileName("");
                                                    }, noPreviewText: "Please save the feature to upload this document", showFileName: true }))) : (React.createElement("div", { className: "upload-area" },
                                                React.createElement("div", { className: "left" },
                                                    React.createElement(FormFileUploadBox, { defaultBase64File: currentLibraryFeature.File, uploadText: "Upload or drop a document here", enforceSafeFileName: true, onDrop: function (file, fileName) { return addFile(file, fileName); } })),
                                                React.createElement("div", { className: "or" }, "Or"),
                                                React.createElement("div", { className: "right" },
                                                    React.createElement("div", { className: "select-extracted-file", onClick: function () { return setShowModal(!showModal); } },
                                                        React.createElement("div", { className: "icon" },
                                                            React.createElement("i", { className: "las la-box-open" })),
                                                        React.createElement("div", { className: "text" }, "Use extracted template"))),
                                                React.createElement(Modal, { centered: true, show: showModal, onHide: function () { return setShowModal(!showModal); }, animation: true, className: "feature-file-modal", backdropClassName: "feature-file-modal-backdrop" },
                                                    React.createElement(FeatureFiles, { onSelect: function (filename) { return onExtractedSelected(filename); } }))))))),
                                            fileUploadPercentage !== undefined ? (React.createElement("div", { className: "progress" },
                                                React.createElement("div", { className: "progress-bar progress-bar-striped bg-success", style: { width: fileUploadPercentage } }))) : null))))))))) : (React.createElement("div", { className: "loader" },
                React.createElement(AnimatedLoader, null))))));
});
export default LibraryFeatureDetails;
