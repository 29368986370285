import axios from "axios";
var RequestService = /** @class */ (function () {
    function RequestService() {
    }
    RequestService.GetJobId = function (requestGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/RequestsWorkflow/GetJobId?requestGuid=" + requestGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    RequestService.GetRequestUrl = function (requestGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/RequestsWorkflow/GetRequestUrl?requestGuid=" + requestGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    RequestService.GetLiveTemplateUsers = function (templateGuid) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/RequestsWorkflow/GetLiveTemplateUsers?templateGuid=" + templateGuid;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    RequestService.GetTenantStats = function (region) {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/Requests/GetTenantStats?region=" + region;
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return RequestService;
}());
export default RequestService;
