import * as AsyncStore from "app/store/async";
import * as ApprovalWorkflowListStore from "app/store/approval-workflows/list";
export var approvalWorkflowNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Adding <strong>{Item.Name}</strong> to approval workflows list...",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Add
            },
            {
                Text: "Updating <strong>{Item.Name}</strong> in approval workflows list...",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Update
            },
            {
                Text: "Deleting <strong>{Item.Name}</strong> from approval workflows list...",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "<strong>{Item.Name}</strong> successfully added to approval workflows list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Add
            },
            {
                Text: "<strong>{Item.Name}</strong> successfully updated in approval workflows list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Update
            },
            {
                Text: "<strong>{Item.Name}</strong> successfully deleted from approval workflows list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Delete
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when adding <strong>{Item.Name}</strong> to approval workflows list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Add
            },
            {
                Text: "Error when updating <strong>{Item.Name}</strong> to approval workflows list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Update
            },
            {
                Text: "Error when deleting <strong>{Item.Name}</strong> from approval workflows list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Item_Delete
            },
            {
                Text: "Error when trying to load approvalWorkflow list.",
                ActionType: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Load
            }
        ]
    }
];
