import * as React from "react";
import "./approval-workflow-list.scss";
import { NavLink } from "react-router-dom";
import * as PageHeader from "app/components/common/page-header";
import { useSelector, useDispatch } from "react-redux";
import * as ApprovalWorkflowListStore from "app/store/approval-workflows/list";
import * as TableList from "app/components/common/table-list";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import InfoTooltip from "app/components/common/infotooltip";
var ApprovalWorkflowList = React.memo(function () {
    var dispatch = useDispatch();
    var approvalWorkflows = useSelector(function (state) { return state.approvalWorkflowListState.items; });
    console.log("ApprovalWorkflow", approvalWorkflows);
    React.useEffect(function () {
        dispatch(ApprovalWorkflowListStore.Clear());
        dispatch(ApprovalWorkflowListStore.Load([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var IsApprovalLocked = function (aw) {
        if (aw.IsTenantDefault) {
            return (React.createElement(InfoTooltip, { content: "This approval workflow is the default workflow for your organization and cannot be edited or removed", placement: "bottom" },
                React.createElement("i", { className: "fa fa-lock" })));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement("div", { className: "approval-workflow-list" },
        React.createElement(PageHeader.SetLeftContent, { subTitle: "List" },
            React.createElement(TableList.SearchBox, { placeholder: "Quickly find a approval workflows" })),
        React.createElement(Subtitle, { subtitle: "List" },
            React.createElement(NavLink, { exact: true, to: "/workspaces/approval-workflows/new" },
                React.createElement(SubtitleButton, { title: "New Approval Workflow", faIcon: "plus", color: "orange" }))),
        React.createElement(TableList.Context, { listItems: approvalWorkflows, asyncListLoadUID: ApprovalWorkflowListStore.ApprovalWorkflowListActionTypes.ApprovalWorkflow_List_Load },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null),
                React.createElement(TableList.HeaderItem, null, "Name"),
                React.createElement(TableList.HeaderItem, null, "Description"),
                React.createElement(TableList.HeaderItem, { type: "actions" }),
                React.createElement(TableList.ContentItemCustomRender, { render: IsApprovalLocked, className: "td-lock-icon", link: "/workspaces/approval-workflows/edit/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Name", isMobileTitle: true, link: "/workspaces/approval-workflows/edit/{GUID}" }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "Description" }),
                React.createElement(TableList.ContentItemActions
                // editNavLinkTo= "/workspaces/approval-workflows/edit/{GUID}"
                , { 
                    // editNavLinkTo= "/workspaces/approval-workflows/edit/{GUID}"
                    onRemove: function (item) { return dispatch(ApprovalWorkflowListStore.DeleteItem(item)); }, conditionalHideRemove: "IsTenantDefault", conditionalEditAsView: "IsTenantDefault", deleteCheckType: "approval-workflow" })))));
});
export default ApprovalWorkflowList;
