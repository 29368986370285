var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { DirectionalHint, Callout } from "@fluentui/react";
import _uniqueId from "lodash/uniqueId";
import "./column-popout-menu.scss";
import OrchModal from "app/components/common/orch-modal";
import { useIntl } from "react-intl";
var itemActionHandler = function (action) {
    if (action)
        action();
};
var borderRad = "6px";
export var ColumnPopoutMenu = function (_a) {
    var items = _a.items;
    var _b = React.useState(false), menuVisible = _b[0], setmenuVisible = _b[1];
    var buttonId = React.useState(_uniqueId("menubutton-"))[0];
    var _c = React.useState(), confirmationModalConfig = _c[0], setconfirmationModalConfig = _c[1];
    var intl = useIntl();
    return (React.createElement("div", { className: "column-popout-menu-area" },
        React.createElement("div", { onClick: function () { return setmenuVisible(function (v) { return !v; }); }, id: buttonId, className: "popout-button" },
            React.createElement("i", { className: "fa-solid fa-ellipsis" })),
        menuVisible && (React.createElement(Callout, { styles: { beakCurtain: { borderRadius: borderRad }, calloutMain: { borderRadius: borderRad }, root: { borderRadius: borderRad } }, target: "#".concat(buttonId), className: "column-popout-menu-wrapper", directionalHint: DirectionalHint.topAutoEdge, onDismiss: function () { return setmenuVisible(false); } },
            React.createElement("div", { className: "list-item-container" }, items.map(function (item) {
                return (React.createElement("div", { onClick: function () {
                        if (item.confirmation) {
                            setconfirmationModalConfig(__assign(__assign({}, item.confirmation), { visible: true, confirmAction: item.action }));
                        }
                        else {
                            item.action && !item.disabled && itemActionHandler(item.action);
                        }
                    }, className: "list-item ".concat(item.disabled ? "disabled" : "").trim() },
                    React.createElement("div", { className: "icon" }, item.icon && React.createElement("i", { className: item.icon })),
                    React.createElement("div", { className: "text" }, item.link ? (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", title: item.link.title, href: item.link.href }, intl.formatMessage({ id: item.translation_id, defaultMessage: item.text }))) : (React.createElement("span", null, intl.formatMessage({ id: item.translation_id, defaultMessage: item.text }))))));
            })))),
        (confirmationModalConfig === null || confirmationModalConfig === void 0 ? void 0 : confirmationModalConfig.visible) ? (React.createElement(OrchModal, { modalClassName: "guest-overview-action-popout-modal", headerText: confirmationModalConfig.title, showModalOverride: confirmationModalConfig === null || confirmationModalConfig === void 0 ? void 0 : confirmationModalConfig.visible, size: "sm", simpleButton: true, footerPrimaryText: confirmationModalConfig.confirmButtonText, footerPrimaryFunction: function () { return itemActionHandler(confirmationModalConfig.confirmAction); }, showModalToggleOverride: function () { return setconfirmationModalConfig(__assign(__assign({}, confirmationModalConfig), { visible: false })); } },
            React.createElement("div", { className: "column-popout-confirmation-dialog" },
                React.createElement("div", { className: "message" }, confirmationModalConfig.message)))) : null));
};
export default ColumnPopoutMenu;
