//copied from https://learn.microsoft.com/en-us/microsoft-365/enterprise/microsoft-365-multi-geo?view=o365-worldwide
export var SHAREPOINT_MULTIGEO_CODES = {
    APC: "Macro Region Geography 2 - Asia-Pacific",
    AUS: "Australia",
    BRA: "Brazil",
    CAN: "Canada",
    EUR: "Macro Region Geography 1 - EMEA",
    FRA: "France",
    DEU: "Germany",
    IND: "India",
    ISR: "Israel",
    ITA: "Italy",
    JPN: "Japan",
    KOR: "Korea",
    NAM: "Macro Region Geography 3 - Americas",
    NOR: "Norway",
    POL: "Poland",
    QAT: "Qatar",
    ZAF: "South Africa",
    SWE: "Sweden",
    CHE: "Switzerland",
    ARE: "United Arab Emirates",
    GBR: "United Kingdom"
};
