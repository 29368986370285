var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./save-progression.scss";
import { Collapse } from "react-bootstrap";
import AnimatedLoader from "../animated-loader";
import OrchModal from "../orch-modal";
var SaveProgression = React.memo(function (props) {
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = React.useState([]), indexesDone = _b[0], setIndexesDone = _b[1];
    var _c = React.useState(-1), currentIndex = _c[0], setCurrentIndex = _c[1];
    var _d = React.useState(false), showConfirmationmodal = _d[0], setshowConfirmationmodal = _d[1];
    React.useEffect(function () {
        if (isOpen && props.confirmation) {
            setCurrentIndex(0);
            //processJob(0);
        }
        else if (isOpen && props.confirmation === undefined) {
            setCurrentIndex(0);
        }
    }, [isOpen]);
    var processJob = function (index) {
        if (props.Jobs[index] !== undefined) {
            var currentJob = props.Jobs[index];
            currentJob.Method().then(function () {
                var tempArray = __spreadArray([], indexesDone, true);
                tempArray.push(index);
                //console.log("Done currentJob", currentJob.Type, tempArray);
                setIndexesDone(tempArray);
                setCurrentIndex(index + 1);
            });
        }
        else {
            setTimeout(function () {
                props.OnComplete();
                setCurrentIndex(-1);
            }, 1000);
            //console.log("Sav Jobs Done", indexesDone.length, props.Jobs.length);
        }
    };
    React.useEffect(function () {
        if (currentIndex > -1) {
            //console.log("Current Index Update", currentIndex);
            processJob(currentIndex);
        }
    }, [currentIndex]);
    return (React.createElement("div", { className: "save-progression" },
        React.createElement("div", { className: "children", onClick: function () {
                if (props.confirmation)
                    setshowConfirmationmodal(true);
                if (props.confirmation === undefined)
                    setIsOpen(true);
            } }, props.children),
        props.confirmation ? (React.createElement(OrchModal, { headerText: props.confirmation.confirmationTitle, showModalToggleOverride: setshowConfirmationmodal, showModalOverride: showConfirmationmodal, modalClassName: "save-progression-confirmation-modal", footerCloseText: props.confirmation.cancelButtonText, footerPrimaryText: props.confirmation.confirmButtonText, footerPrimaryFunction: function () {
                setIsOpen(true);
            }, size: "md" }, props.confirmation.confirmationMessage)) : null,
        React.createElement(Collapse, { in: isOpen },
            React.createElement("div", { className: "dropdown" },
                React.createElement("ul", null, props.Jobs.map(function (j, jindex) { return (React.createElement("li", { key: jindex },
                    React.createElement("div", { className: "icon" }, indexesDone.some(function (i) { return i === jindex; }) ? (React.createElement("i", { className: "fa fa-check" })) : (React.createElement(React.Fragment, null, currentIndex === jindex ? React.createElement(AnimatedLoader, { theme: "dark", size: "xxsmall" }) : React.createElement("div", { className: "dash" }, "-")))),
                    React.createElement("div", { className: "text" }, j.Title))); }))))));
});
export default SaveProgression;
