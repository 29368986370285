import React, { forwardRef } from "react";
import { OrchestrySVGIcon } from "../orchestry-svg-icon/orchestry-svg-icon.component";
/**
 * A component that displays a badge either with an icon and a title or with an SVG icon.
 * Different than a Chip because the badge is always selected.
 */
export var OrchestrySVGBadge = forwardRef(function (_a, ref) {
    var onClick = _a.onClick, svgIcon = _a.svgIcon, value = _a.value;
    var onBadgeClicked = function () { return onClick && onClick(value); };
    return React.createElement(OrchestrySVGIcon, { ref: ref, "data-variant": value, size: "md", onClick: onBadgeClicked, iconNeutral: svgIcon.iconNeutral, iconSelected: svgIcon.iconSelected, selected: true });
});
