import * as AsyncStore from "app/store/async";
import { LifecyclePolicyWorkspaceCurrentActionTypes } from "app/store/lifecycle-policy-workspaces/current/types"; //changed here to avoid circular reference in notifications store
import { Generic_Toast_Notification } from "app/store/notifications/types";
export var lifecyclePolicyWorkspacesNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Start,
        Actions: [
            {
                Text: "Updating workspace policy...",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Succeed,
        Actions: [
            {
                Text: "Successfully updated workspace policy.",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update
            }
        ]
    },
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when updating workspace policy.",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Update
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Forced Guest Review successfully started for Workspace.",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_GuestReviewForced_Success
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Guest has been successfully deleted from Azure.",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_DeleteGuest_Success
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Workspace invite(s) successfully re-sent.",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_ResendInvite_Success
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Failure,
        Actions: [
            {
                Text: "Invitation Resend Failed. User not found or has been deleted.",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_ResendInvite_Failure_DeletedUser
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Guest(s) Successfully Removed from Workspace",
                ActionType: LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_RemoveGuestFromWorkspace_Success
            }
        ]
    }
];
