var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UsersListActionTypes } from "./types";
import { initialPageContext } from "./reducers";
export function Load(listMode, siteId, groupId) {
    if (siteId === void 0) { siteId = null; }
    if (groupId === void 0) { groupId = null; }
    return {
        type: UsersListActionTypes.Users_List_Load,
        payload: __assign(__assign({}, initialPageContext), { Mode: listMode, SiteId: siteId, GroupId: groupId })
    };
}
export function Refresh(pageContext) {
    return {
        type: UsersListActionTypes.Users_List_Refresh,
        payload: pageContext
    };
}
export function Page(pageContext) {
    return {
        type: UsersListActionTypes.Users_List_Page,
        payload: __assign(__assign({}, pageContext), { Users: [] })
    };
}
export function Clear(listMode) {
    return {
        type: UsersListActionTypes.Users_List_Clear,
        payload: listMode
    };
}
export function IsLoading(listMode, isLoading) {
    var returnIsLoading = {
        IsLoading: isLoading,
        Mode: listMode
    };
    return {
        type: UsersListActionTypes.Users_List_IsLoading,
        payload: returnIsLoading
    };
}
