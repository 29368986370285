import { ReportSearchActionTypes } from "app/store/lifecycle-report/search/types"; //changed here to avoid circular reference in notifications store
import { Generic_Toast_Notification } from "app/store/notifications/types";
export var lifecycleReportNotificationConfigMap = [
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Guest(s) have been successfully deleted from Azure.",
                ActionType: ReportSearchActionTypes.Report_Bulk_DeleteGuestFromAzure_Success
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "Azure invite(s) successfully re-sent.",
                ActionType: ReportSearchActionTypes.Report_Bulk_ResendInvite_Success
            }
        ]
    },
    {
        ActionType: Generic_Toast_Notification.Generic_Success,
        Actions: [
            {
                Text: "User has successfully been removed from Workspace(s)",
                ActionType: ReportSearchActionTypes.Report_Bulk_RemoveGuestFromWorkspaces_Success
            }
        ]
    }
];
