import { Container, Group } from "@mantine/core";
import { convertUtcDatetimeToLocal, getReadableDate, getReadableDateFromNow } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useTenantPage } from "../../use-tenant-page.hook";
export var TenantMetricsLastAdminLogin = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Metrics, _c = _b === void 0 ? {} : _b, LastAdminLoginDate = _c.LastAdminLoginDate, isPageLoading = _a.isPageLoading;
    return (React.createElement(Container, { p: 0, miw: 247 },
        React.createElement(React.Fragment, null,
            React.createElement(Group, { gap: 8, align: "center" },
                React.createElement(OrchestryFeaturedIcon, { isLoading: isPageLoading, iconClassName: ORCH_ICONS.calendar, variant: "light-circle", colorVariant: "success-7", size: "sm" }),
                React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "14px" }, "Last Admin Log In Date")),
            React.createElement(Group, { mt: 8, gap: 4 },
                React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-secondary.0", size: "16px", fw: 700, lh: "24px" }, getReadableDate != null && LastAdminLoginDate !== null ? getReadableDate(convertUtcDatetimeToLocal(LastAdminLoginDate !== null && LastAdminLoginDate !== void 0 ? LastAdminLoginDate : "")) : "Never"),
                React.createElement(OrchestryText, { isLoading: isPageLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, LastAdminLoginDate != null ? "(" + getReadableDateFromNow(convertUtcDatetimeToLocal(LastAdminLoginDate)) + ")" : "")))));
};
