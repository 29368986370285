var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useSelected } from "slate-react";
var LinkComponent = function (_a) {
    var attributes = _a.attributes, children = _a.children, element = _a.element;
    var selected = useSelected();
    return (React.createElement("a", __assign({}, attributes, { rel: "noopener noreferrer", target: "_blank", href: element.url, className: "".concat(selected ? "linkSelected" : ""), title: element.url }), children));
};
export default LinkComponent;
