import * as React from "react";
import { Form } from "react-bootstrap";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
var ReportFilterWorkspaceUsers = React.memo(function (props) {
    return (React.createElement(Form.Group, { className: "form-group", key: props.Index },
        React.createElement(Form.Label, null, props.Refiner.Title),
        React.createElement(FormUserGroupSelect, { value: props.Refiner.CurrentValue, 
            // onChange={(value) => { if(value !== refiner.CurrentValue) { updateRefiner(refiner, value); } }}
            onChange: function (value) {
                //console.log("User CHange", value);
                var newValue = value;
                if (value === "[]") {
                    newValue = null;
                }
                if (newValue !== props.Refiner.CurrentValue) {
                    props.FilterOnChange(props.Refiner, newValue);
                }
            }, returnType: "users", 
            // onChange={(value)=>{ if(value !== undefined && value !== null) { console.log("value", value); iwc.dispatch(TenantConfigurationCurrentStore.UpdateSilent({...iwc.currentTenantConfiguration, AdminstratorAccessActiveDirectoryGroup:value}))}}}
            allowMultiple: true, displayAddInline: true, forceOpen: props.ForcePeoplePickerOpen })));
});
export default ReportFilterWorkspaceUsers;
