var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import MICROSOFT_SVG_ICONS from "appv2/assets/icons/microsoft-svgs/microsoft-svg-icons";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
// TECHDEBT: Add colors like HEALTH_CHECKS_FILTERS_LIST so we can remove tech debt variants and simplify the code.
export var ADD_RECOMMENDATIONS_FILTERS_LIST = [
    { type: "selectable", title: "Urgent", value: "urgent", group: "Priority", selected: false, icon: ORCH_ICONS.lightning01, color: "var(--mantine-color-error-8)" },
    { type: "selectable", title: "Important", value: "important", group: "Priority", selected: false, icon: ORCH_ICONS.alertOctagon, color: "var(--mantine-color-warning-8)" },
    { type: "selectable", title: "Best Practice", value: "bestpractice", group: "Priority", selected: false, icon: ORCH_ICONS.star01, color: "var(--mantine-color-blue-7)" },
    { type: "selectable", title: "Security", value: "security", group: "Category", icon: ORCH_ICONS.lock01, selected: false, color: "var(--mantine-color-pink-8)" },
    { type: "selectable", title: "Guests", value: "guests", group: "Category", icon: ORCH_ICONS.user03, selected: false, color: "var(--mantine-color-blue-8)" },
    { type: "selectable", title: "Governance", value: "governance", group: "Category", icon: ORCH_ICONS.shieldCheck, selected: false, color: "var(--mantine-color-brown-8)" },
    { type: "selectable", title: "Storage", value: "storage", group: "Category", icon: ORCH_ICONS.database01, selected: false, color: "var(--mantine-color-success-8)" },
    { type: "selectable", title: "Oversharing", value: "oversharing", group: "Category", icon: ORCH_ICONS.link2Off, selected: false, color: "var(--mantine-color-error-7)" },
    { type: "selectable", title: "Architecture", value: "architecture", group: "Category", icon: ORCH_ICONS.gitBranch01, selected: false, color: "var(--mantine-color-purple-8)" },
    {
        type: "selectable",
        title: "Copilot",
        value: "copilot",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.CopilotGray, iconSelected: MICROSOFT_SVG_ICONS.Copilot },
        selected: false
    },
    {
        type: "selectable",
        title: "Communications",
        value: "communications",
        group: "Category",
        icon: ORCH_ICONS.messageSquare02,
        selected: false,
        color: "var(--mantine-color-indigo-8)"
    },
    { type: "selectable", title: "Adoption", value: "adoption", group: "Category", icon: ORCH_ICONS.checkHeart, selected: false, color: "var(--mantine-color-orange-8)" },
    { type: "selectable", title: "Cost Savings", value: "cost savings", group: "Category", icon: ORCH_ICONS.currencyDollar, selected: false, color: "var(--mantine-color-magenta-9)" },
    // { type: "selectable", title: "Licensing", value: "licensing", group: "Category", icon: ORCH_ICONS.award03, selected: false, color: "var(--mantine-color-primary-8)" }, commented out until phase 2
    { type: "selectable", title: "Sprawl", value: "sprawl", group: "Category", icon: ORCH_ICONS.sprawl, selected: false, color: "var(--mantine-color-primary-8)" },
    {
        type: "selectable",
        title: "Azure AD (Entra)",
        value: "entra",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.AzureAdEntraGray, iconSelected: MICROSOFT_SVG_ICONS.AzureAdEntra },
        selected: false,
        color: "var(--azure-ad-entra-text)"
    },
    {
        type: "selectable",
        title: "Defender",
        value: "defender",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.DefenderGray, iconSelected: MICROSOFT_SVG_ICONS.Defender },
        selected: false,
        color: "var(--defender-text)"
    },
    {
        type: "selectable",
        title: "SharePoint",
        value: "sharepoint",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.SharepointGray, iconSelected: MICROSOFT_SVG_ICONS.Sharepoint },
        selected: false,
        color: "var(--sharepoint-text)"
    },
    {
        type: "selectable",
        title: "OneDrive",
        value: "onedrive",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.OneDriveGray, iconSelected: MICROSOFT_SVG_ICONS.OneDrive },
        selected: false,
        color: "var(--onedrive-text)"
    },
    {
        type: "selectable",
        title: "Teams",
        value: "teams",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.TeamsGray, iconSelected: MICROSOFT_SVG_ICONS.Teams },
        selected: false,
        color: "var(--teams-text)"
    },
    {
        type: "selectable",
        title: "Viva Connections",
        value: "viva connections",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.VivaConnectionsGray, iconSelected: MICROSOFT_SVG_ICONS.VivaConnections },
        selected: false,
        color: "var(--viva-connections-text)"
    },
    {
        type: "selectable",
        title: "Viva Engage",
        value: "viva engage",
        group: "Product",
        svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.VivaEngageGray, iconSelected: MICROSOFT_SVG_ICONS.VivaEngage },
        selected: false,
        color: "var(--viva-engage-text)"
    }
];
export var RECOMMENDATIONS_FILTERS_LIST = __spreadArray(__spreadArray([], ADD_RECOMMENDATIONS_FILTERS_LIST, true), [
    {
        type: "selectable",
        title: "Orchestry",
        value: "orchestry",
        group: "Source",
        svgIcon: { iconSelected: ORCH_SVG_ICONS.LogoMarkColorSmall },
        selected: false
    },
    { type: "selectable", title: "Custom", value: "custom", group: "Source", icon: ORCH_ICONS.building06, selected: false, color: "var(--mantine-color-primary-9)" }
], false);
