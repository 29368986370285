import { Card, Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-top-categories-skeleton.scss";
export var RecommendationTopCategoriesSkeleton = function () { return (React.createElement(Card, { className: "recommendation-top-categories-skeleton" },
    React.createElement(Skeleton, { w: 60, h: 12.5, radius: 17.5 }),
    React.createElement("div", { className: "sections" },
        React.createElement("div", { className: "left-section" },
            React.createElement("div", { className: "group" },
                React.createElement("div", { className: "category" },
                    React.createElement(Skeleton, { circle: true, h: 16, w: 16 }),
                    React.createElement(Skeleton, { ml: 8, w: 25, h: 10.5, radius: 15 })),
                React.createElement(Skeleton, { ml: 8, w: 8, h: 10.5, radius: 15 })),
            React.createElement("div", { className: "group" },
                React.createElement("div", { className: "category" },
                    React.createElement(Skeleton, { circle: true, h: 16, w: 16 }),
                    React.createElement(Skeleton, { ml: 8, w: 44, h: 10.5, radius: 15 })),
                React.createElement(Skeleton, { ml: 8, w: 8, h: 10.5, radius: 15 })),
            React.createElement("div", { className: "group" },
                React.createElement("div", { className: "category" },
                    React.createElement(Skeleton, { circle: true, h: 16, w: 16 }),
                    React.createElement(Skeleton, { ml: 8, w: 28, h: 10.5, radius: 15 })),
                React.createElement(Skeleton, { ml: 8, w: 8, h: 10.5, radius: 15 }))),
        React.createElement("div", { className: "right-section" },
            React.createElement("div", { className: "group" },
                React.createElement("div", { className: "category" },
                    React.createElement(Skeleton, { circle: true, h: 16, w: 16 }),
                    React.createElement(Skeleton, { ml: 8, w: 39, h: 10.5, radius: 15 })),
                React.createElement(Skeleton, { ml: 8, w: 8, h: 10.5, radius: 15 })),
            React.createElement("div", { className: "group" },
                React.createElement("div", { className: "category" },
                    React.createElement(Skeleton, { circle: true, h: 16, w: 16 }),
                    React.createElement(Skeleton, { ml: 8, w: 37, h: 10.5, radius: 15 })),
                React.createElement(Skeleton, { ml: 8, w: 8, h: 10.5, radius: 15 })),
            React.createElement("div", { className: "group" },
                React.createElement("div", { className: "category" },
                    React.createElement(Skeleton, { circle: true, h: 16, w: 16 }),
                    React.createElement(Skeleton, { ml: 8, w: 83, h: 10.5, radius: 15 })),
                React.createElement(Skeleton, { ml: 8, w: 8, h: 10.5, radius: 15 })))))); };
