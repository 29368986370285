import { Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import React, { useState } from "react";
import "./recommendation-share-suggestion.scss";
export var RecommendationShareSuggestion = function (props) {
    var _a = useState(false), modalOpen = _a[0], setModalOpen = _a[1];
    return (React.createElement("div", { className: "recommendation-share-suggestion" },
        React.createElement("div", { className: "share-text" },
            React.createElement("i", { className: ORCH_ICONS.lightbulb05 }),
            React.createElement(Text, { size: "sm", c: "text-secondary.0", fw: 400, span: true }, "Got a Recommendation?"),
            React.createElement(Text, { size: "sm", c: "text-tertiary.0", fw: 300, span: true }, "Tell us what you'd love to add."),
            React.createElement(Text, { size: "sm", c: "text-secondary.0", fw: 400, span: true, className: "share-link", onClick: function () { return openOrchestryModal("recommendationShareSuggestionModal", {}, "md"); } }, "Share your suggestion"))));
};
