import React from "react";

export default class MenuItemSeparator extends React.Component {
   render() {
      return (
         <li style={{ borderColor: "#6AB6B7", width: "90%", marginLeft: "auto", marginRight: "auto", marginBottom: 10, marginTop: 10 }} className="kt-menu__separator">
            <span style={{ paddingLeft: 10, paddingRight: 10 }} />
         </li>
      );
   }
}
