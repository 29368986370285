import * as React from "react";
import PrincipalItemDynamic from "app/components/common/principal-item-dynamic";
var SingleUserById = React.memo(function (props) {
    var _a = React.useState(""), userId = _a[0], setUserId = _a[1];
    React.useEffect(function () {
        var _a;
        if (props.Item.ExtendedProperties !== undefined &&
            props.Item.ExtendedProperties !== null &&
            ((_a = props.Item.ExtendedProperties) === null || _a === void 0 ? void 0 : _a.CosmosUserDetails) !== undefined &&
            props.Item.ExtendedProperties.CosmosUserDetails[props.Col.ProperyName] !== undefined) {
            setUserId(props.Item.ExtendedProperties.CosmosUserDetails[props.Col.ProperyName]);
        }
        else if (props.Item.ExtendedProperties !== undefined && props.Item.ExtendedProperties !== null && props.Item.ExtendedProperties[props.Col.ProperyName] !== undefined) {
            setUserId(props.Item.ExtendedProperties[props.Col.ProperyName]);
        }
        else {
            if (props.Item[props.Col.ProperyName] !== undefined && props.Item[props.Col.ProperyName] !== null) {
                setUserId(props.Item[props.Col.ProperyName]);
            }
            else {
                setUserId("");
            }
        }
    }, [props.Item]);
    return userId !== null && userId !== "" ? React.createElement(PrincipalItemDynamic, { principalIdOrEmail: userId, key: userId, showBorder: false, size: "sm" }) : null;
});
export default SingleUserById;
