import { Collapse, Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryAlertWrapper } from "appv2/components/orchestry-alert/orchestry-alert-wrapper.component";
import { OrchestryDataTable } from "appv2/components/orchestry-data-table/orchestry-data-table.component";
import { OrchestryFilterSelector } from "appv2/components/orchestry-filter-selector/orchestry-filter-selector.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { OrchestrySearchBox } from "appv2/components/orchestry-searchbox/orchestry-searchbox.component";
import React from "react";
import { OrchestryQueryContext } from "../../../components/orchestry-query-context/orchestry-query-context.component";
import "./health-checks-page.scss";
export var HealthChecksPage = function (_a) {
    var isPageLoading = _a.isPageLoading, isAlertLoading = _a.isAlertLoading, showAlert = _a.showAlert, healthChecksInDraftStateCount = _a.healthChecksInDraftStateCount, searchTextInputValue = _a.searchTextInputValue, onSearchBoxXClicked = _a.onSearchBoxXClicked, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, appliedFilters = _a.appliedFilters, setAppliedFilters = _a.setAppliedFilters, removeFilter = _a.removeFilter, clearAllFilters = _a.clearAllFilters, table = _a.table, totalHealthChecksCount = _a.totalHealthChecksCount, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, activePage = _a.activePage, setActivePage = _a.setActivePage, pageTotal = _a.pageTotal, pageOptions = _a.pageOptions, intl = _a.intl, isMobile = _a.isMobile, FILTER_GROUPS = _a.FILTER_GROUPS;
    return (React.createElement("div", { className: "health-checks-page" },
        React.createElement(Stack, { gap: 8 },
            showAlert && (React.createElement(OrchestryAlertWrapper, { variant: "primary", isMobile: isMobile, isLoading: isAlertLoading, title: intl.formatMessage({ id: "page.health_checks.alert.title", defaultMessage: "Unpublished Health Checks" }), message: intl.formatMessage({ id: "page.health_checks.alert.description", defaultMessage: "You have {count} Health Check(s) currently in Draft that have not been Published to others." }, { count: healthChecksInDraftStateCount.toLocaleString() }), icon: React.createElement(OrchestryFontIcon, { isLoading: isAlertLoading, iconClassName: ORCH_ICONS.alertCircle, color: "var(--mantine-color-primary-8)", fontSize: 24, lineHeight: "24px" }) })),
            React.createElement(Group, { gap: 8, justify: "right" },
                React.createElement(OrchestrySearchBox, { skeletonWidth: 320, isLoading: isPageLoading, placeholder: intl.formatMessage({ id: "page.health_checks.search_placeholder", defaultMessage: "Search for a Health Check" }), tooltipLabel: intl.formatMessage({ id: "page.health_checks.search_tooltip", defaultMessage: "Click to perform a search within your health checks" }), onXClicked: onSearchBoxXClicked, onChange: onSearchBoxChange, value: searchTextInputValue, onKeyDown: onSearchBoxKeyDown, radius: "6px" }),
                React.createElement(OrchestryFilterSelector, { isLoading: isPageLoading, popoverPosition: "bottom-end", buttonTitle: intl.formatMessage({ id: "controls.filters", defaultMessage: "Filters" }), filters: appliedFilters, onApply: setAppliedFilters, onClear: clearAllFilters, buttonSize: "sm" })),
            React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: appliedFilters.some(function (filter) { return filter.selected; }) },
                React.createElement(OrchestryQueryContext, { filterGroups: FILTER_GROUPS, appliedFilters: appliedFilters, totalResults: totalHealthChecksCount !== null && totalHealthChecksCount !== void 0 ? totalHealthChecksCount : 0, removeFilter: removeFilter, clearAllFilters: clearAllFilters })),
            React.createElement(Stack, { gap: 0 },
                React.createElement(OrchestryDataTable, { table: table }),
                React.createElement(OrchestryPagination, { variant: "table", onResultsPerPageChange: function (value) {
                        setResultsPerPage(value);
                        localStorage.setItem("health-checks-resultsPerPage", value);
                    }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: totalHealthChecksCount !== null && totalHealthChecksCount !== void 0 ? totalHealthChecksCount : 0, pageTotal: pageTotal !== null && pageTotal !== void 0 ? pageTotal : 0, resultOptions: pageOptions })))));
};
