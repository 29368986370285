var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./approval-workflows-details.scss";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as ApprovalWorkflowListStore from "app/store/approval-workflows/list";
import * as ApprovalWorkflowCurrentStore from "app/store/approval-workflows/current";
import generateGuid from "app/utils/generateGuid";
import { Row, Col, Form, Container, Collapse } from "react-bootstrap";
import { SubtitleButton } from "app/components/common/subtitle";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import FormValidationContext, { FormValidator, FormValidatorFocus } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import { SkeletonLoaderFakePortletForm } from "app/components/common/skeleton-loaders";
var ApprovalWorkflowDetails = React.memo(function (props) {
    var _a, _b;
    var dispatch = useDispatch();
    var lookupGuid = props.guid;
    var currentApprovalWorkflow = useSelector(function (state) { return state.approvalWorkflowCurrentState.item; });
    //const listApprovalWorkflow:ApprovalWorkflowTypes.ApprovalWorkflow[] = useSelector((state: RootState) => state.approvalWorkflowListState.items);
    //const [choiceValues, setChoiceValues] = React.useState();
    var _c = React.useState(false), backToList = _c[0], setBackToList = _c[1];
    var _d = React.useState(0), openStep = _d[0], setOpenStep = _d[1];
    var _e = React.useState(false), formValid = _e[0], setFormValid = _e[1];
    //console.log("ApprovalWorkflow - Details - currentApprovalWorkflow" , currentApprovalWorkflow);
    // Should only be run once.
    React.useEffect(function () {
        // Clear the currentTemplate in the state with a new GUID.
        if (currentApprovalWorkflow.GUID !== undefined) {
            dispatch(ApprovalWorkflowCurrentStore.Clear());
        }
        //console.log("guid", props.guid);
        if (lookupGuid === undefined) {
            var guid = generateGuid();
            dispatch(ApprovalWorkflowCurrentStore.Update({
                GUID: guid,
                IsTenantDefault: false,
                AssociatedSteps: [
                    {
                        GUID: generateGuid(),
                        Position: 1
                    }
                ]
            }));
            setOpenStep(1);
        }
        else {
            // Load the /edit/guid load.
            dispatch(ApprovalWorkflowCurrentStore.Load({ GUID: lookupGuid }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lookupGuid]);
    var clearCurrentApprovalWorkflow = function () {
        dispatch(ApprovalWorkflowCurrentStore.Clear());
    };
    React.useEffect(function () {
        return function () {
            dispatch(ApprovalWorkflowCurrentStore.Clear());
        };
    }, []);
    var saveAndAddModifyApprovalWorkflow = function () {
        if (formValid) {
            var asyncCallType = AsyncCallType.Update;
            if (lookupGuid === undefined) {
                // First, add the new template to template list
                asyncCallType = AsyncCallType.Add;
                dispatch(ApprovalWorkflowListStore.AddItem(currentApprovalWorkflow));
            }
            else {
                dispatch(ApprovalWorkflowListStore.UpdateItem(currentApprovalWorkflow));
            }
            AsyncWait(asyncCallType, AsyncStatus.Succeeded, currentApprovalWorkflow.GUID).then(function () {
                setBackToList(true);
                dispatch(ApprovalWorkflowCurrentStore.Clear());
            });
        }
    };
    // If the choices come back filled, then update them.
    React.useEffect(function () {
        // console.log("Choice Values", currentApprovalWorkflow.Values, choiceValues);
        // if(currentApprovalWorkflow.Values !== undefined){
        //     if(currentApprovalWorkflow.Values !== null){
        //         if(choiceValues === undefined) {
        //             setChoiceValues(currentApprovalWorkflow.Values.split("\r\n"));
        //         }
        //     }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApprovalWorkflow]);
    var addStep = function () {
        var _a;
        var newStep = {
            GUID: generateGuid(),
            Position: currentApprovalWorkflow.AssociatedSteps !== undefined ? ((_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.length) + 1 : 1
        };
        var tempArray = [newStep];
        //setAssociatedTemplates([...tempArray, ...currentChannel.AssociatedTemplates]);
        if (currentApprovalWorkflow.AssociatedSteps !== undefined) {
            if (currentApprovalWorkflow.AssociatedSteps.length > 0) {
                tempArray = __spreadArray(__spreadArray([], tempArray, true), currentApprovalWorkflow.AssociatedSteps, true);
            }
        }
        dispatch(ApprovalWorkflowCurrentStore.Update({ AssociatedSteps: tempArray }));
        if (newStep.Position !== undefined) {
            setOpenStep(newStep.Position);
        }
    };
    var updateStepName = function (step, value) {
        var _a;
        var newStep = __assign(__assign({}, step), { Name: value });
        dispatch(ApprovalWorkflowCurrentStore.Update({
            AssociatedSteps: (_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.map(function (as) { return (as.GUID === newStep.GUID ? newStep : as); })
        }));
    };
    var updateStepMessage = function (step, value) {
        var _a;
        var newStep = __assign(__assign({}, step), { Message: value });
        dispatch(ApprovalWorkflowCurrentStore.Update({
            AssociatedSteps: (_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.map(function (as) { return (as.GUID === newStep.GUID ? newStep : as); })
        }));
    };
    var updateStepApprovers = function (step, value) {
        var _a;
        var newStep = __assign(__assign({}, step), { Approvers: value });
        dispatch(ApprovalWorkflowCurrentStore.Update({
            AssociatedSteps: (_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.map(function (as) { return (as.GUID === newStep.GUID ? newStep : as); })
        }));
    };
    var onAssociatedRemove = function (tbaToRemove) {
        var _a;
        dispatch(ApprovalWorkflowCurrentStore.Update({
            AssociatedTemplates: (_a = currentApprovalWorkflow.AssociatedTemplates) === null || _a === void 0 ? void 0 : _a.filter(function (tba) { return tba.GUID !== tbaToRemove.GUID; })
        }));
    };
    //const toggleAccordian = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    var toggleAccordian = function (step) {
        console.log("open this step > ", step.Position);
        if (step.Position !== undefined) {
            if (step.Position === openStep) {
                setOpenStep(0);
            }
            else {
                setOpenStep(step.Position);
            }
        }
        //console.log("accordian toggle",event.currentTarget);
        //event.currentTarget
        // const toggle = (event:React.MouseEvent<HTMLDivElement|any, MouseEvent>) => {
        //     let cardTitle = event.currentTarget.querySelector(".card-title");
        //     let cardBody = event.currentTarget.parentNode?.querySelector(".card-body");
        //     if(cardTitle !== null){
        //         if(cardTitle.classList.contains("collapsed")){
        //             cardTitle.classList.remove("collapsed");
        //         } else {
        //             cardTitle.classList.add("collapsed");
        //         }
        //     }
        //     if(cardBody !== undefined && cardBody !== null){
        //         if(cardBody.classList.contains("collapse")){
        //             cardBody.classList.remove("collapse");
        //         } else {
        //             cardBody.classList.add("collapse");
        //         }
        //     }
        // }
        // toggle(event);
    };
    var removeStep = function (step) {
        var _a;
        var deletingStepPosition = step.Position;
        console.log("Remove Step", deletingStepPosition);
        var tempSteps = [];
        (_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.forEach(function (mapStep) {
            if (mapStep.GUID !== step.GUID) {
                //@ts-ignore
                if (mapStep.Position > deletingStepPosition) {
                    //@ts-ignore
                    mapStep.Position = mapStep.Position - 1;
                }
                tempSteps.push(mapStep);
            }
        });
        console.log("tempSteps", tempSteps);
        if (tempSteps !== undefined) {
            dispatch(ApprovalWorkflowCurrentStore.Update({
                //@ts-ignore
                AssociatedSteps: tempSteps
            }));
        }
    };
    var preventDefault = function (event, method) {
        //console.log("Revent Default")
        event.stopPropagation();
        if (method !== undefined) {
            method(event);
        }
    };
    var sortStep = function (direction, step) {
        var _a, _b;
        if (step.Position !== undefined) {
            //@ts-ignore
            var adjacentStepNumber_1 = direction === "up" ? step.Position - 1 : step.Position + 1;
            var adjacentStep_1 = (_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.find(function (as) { return as.Position === adjacentStepNumber_1; });
            var currentStepNumber_1 = step.Position;
            var tempSteps = (_b = currentApprovalWorkflow.AssociatedSteps) === null || _b === void 0 ? void 0 : _b.map(function (mapStep) {
                if (mapStep.GUID === (adjacentStep_1 === null || adjacentStep_1 === void 0 ? void 0 : adjacentStep_1.GUID)) {
                    return __assign(__assign({}, mapStep), { Position: currentStepNumber_1 });
                }
                else if (mapStep.GUID === (step === null || step === void 0 ? void 0 : step.GUID)) {
                    return __assign(__assign({}, mapStep), { Position: adjacentStepNumber_1 });
                }
                else {
                    return mapStep;
                }
            });
            if (openStep !== 0) {
                if (direction === "up") {
                    setOpenStep(openStep - 1);
                }
                else {
                    setOpenStep(openStep + 1);
                }
            }
            //@ts-ignore
            console.log("newly ordered items", tempSteps);
            dispatch(ApprovalWorkflowCurrentStore.Update({
                AssociatedSteps: tempSteps
            }));
        }
    };
    // used in a .sort(sortCompare) for an array
    var sortCompare = function (a, b) {
        if (a.Position !== undefined && b.Position !== undefined) {
            if (a.Position < b.Position) {
                return -1;
            }
            if (a.Position > b.Position) {
                return 1;
            }
        }
        return 0;
    };
    // const handleClickOutside = (event:any) => {
    //     console.log("handleClickOutside", event.toElement);
    //     // const domNode = ReactDOM.findDOMNode(this);
    //     // var element = event.target as HTMLElement;
    //     // if (!domNode || !domNode.contains(element)) {
    //     //     this.clearSearch();
    //     //     this.setState({
    //     //         listOpen: false
    //     //     })
    //     // }
    // }
    // React.useEffect(() => {
    //     document.addEventListener('click', handleClickOutside, true);
    // },[]);
    return (React.createElement("div", { className: "approval-workflows-details fadein-500ms" },
        React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "approvalWorkflowValidation" },
            backToList && React.createElement(Redirect, { to: "/workspaces/approval-workflows" }),
            React.createElement(PageHeader.SetLeftContent, { title: "Approval Workflows", subTitle: props.guid ? "Edit" : "New", titleLink: "/workspaces/approval-workflows" }),
            React.createElement(PageHeader.SetRightContent, null,
                React.createElement(NavLink, { to: "/workspaces/approval-workflows", onClick: function () { return clearCurrentApprovalWorkflow(); } },
                    React.createElement(SubtitleButton, { title: "Cancel", variant: "secondary" })),
                formValid !== undefined && currentApprovalWorkflow.IsTenantDefault === false ? (React.createElement(React.Fragment, null, formValid === true ? (React.createElement("div", { onClick: function () { return saveAndAddModifyApprovalWorkflow(); }, style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "success" }))) : (React.createElement("div", { style: { display: "inline-block" } },
                    React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))))) : null),
            currentApprovalWorkflow.GUID !== undefined ? (React.createElement(Container, { fluid: true },
                currentApprovalWorkflow.IsTenantDefault === true ? (React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(Portlet, { className: "current-status" },
                            React.createElement(PortletBody, null,
                                React.createElement("div", { className: "alert alert-solid-warning alert-bold", role: "alert", style: { marginBottom: "0" } },
                                    React.createElement("div", { className: "alert-icon" },
                                        React.createElement("i", { className: "flaticon-warning" })),
                                    React.createElement("div", { className: "alert-text" }, "This is the default approval workflow for your organization. It cannot be edited or removed."))))))) : null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                        React.createElement(Portlet, { className: "current-status" },
                            React.createElement(PortletBody, null,
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-title" },
                                    React.createElement(Form.Label, null,
                                        "Name ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(Form.Control, { placeholder: "Enter Name", disabled: currentApprovalWorkflow.IsTenantDefault, defaultValue: currentApprovalWorkflow.Name, onChange: function (event) { return dispatch(ApprovalWorkflowCurrentStore.Update({ Name: event.target.value })); } }),
                                    React.createElement(FormValidator, { UniqueIdentifier: "approval-name", FieldName: "Name", Validators: ["string-not-empty", "string-is-more-than-2"], Value: currentApprovalWorkflow.Name })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-instructions" },
                                    React.createElement(Form.Label, null, "Description (Optional)"),
                                    React.createElement(Form.Control, { as: "textarea", 
                                        //placeholder="Enter Workflow Description"
                                        disabled: currentApprovalWorkflow.IsTenantDefault, rows: 3, defaultValue: currentApprovalWorkflow.Description, onChange: function (event) { return dispatch(ApprovalWorkflowCurrentStore.Update({ Description: event.target.value })); } })))),
                        React.createElement(Portlet, { className: "current-status" },
                            React.createElement(PortletHeader, { title: "Workflow Template Associations" }),
                            React.createElement(PortletBody, null,
                                React.createElement("ul", { style: { paddingLeft: "20px", marginBottom: "0" } }, currentApprovalWorkflow.GUID !== undefined && currentApprovalWorkflow.AssociatedTemplates !== undefined && currentApprovalWorkflow.AssociatedTemplates.length > 0 ? (currentApprovalWorkflow.AssociatedTemplates.map(function (assTemplate) { var _a; return React.createElement("li", { className: "associated-template" }, (_a = assTemplate.Item) === null || _a === void 0 ? void 0 : _a.Title); })) : (React.createElement("li", null,
                                    React.createElement("strong", null, "No templates associated"),
                                    React.createElement("p", null, "To associate a template with a workflow - Enabled the workflow from a template's configuration page."))))))),
                    React.createElement(Col, { md: 6, lg: 6, xl: 6 },
                        React.createElement(Portlet, { className: "wf-steps" },
                            React.createElement(PortletHeader, { title: "Workflow Steps" }),
                            React.createElement(PortletBody, null,
                                React.createElement("div", { className: "steps" },
                                    React.createElement("div", { className: "accordion accordion-solid accordion-toggle-arrow" }, (_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 :
                                        _a.sort(sortCompare).map(function (step, stepIndex) {
                                            var _a;
                                            return (React.createElement("div", { className: "card", key: stepIndex },
                                                React.createElement("div", { className: "card-header", onClick: function () {
                                                        toggleAccordian(step);
                                                    } },
                                                    React.createElement("div", { className: "card-title" + (step.Position !== openStep ? " collapsed" : "") },
                                                        React.createElement("div", { className: "badge" }, step.Position),
                                                        React.createElement("span", { className: "step-name" }, step.Name),
                                                        React.createElement(FormValidatorFocus, { UniqueIdentifier: "approval-step-name-" + step.GUID },
                                                            React.createElement(FormValidatorFocus, { UniqueIdentifier: "approval-step-approvers-" + step.GUID },
                                                                React.createElement(Form.Control, { className: "step-name-input", disabled: step.Position !== openStep ? true : currentApprovalWorkflow.IsTenantDefault, placeholder: "Enter step name", 
                                                                    //@ts-ignore
                                                                    onClick: function (event) { return preventDefault(event); }, 
                                                                    //defaultValue={currentApprovalWorkflow.Name}
                                                                    value: step.Name, onChange: function (event) { return updateStepName(step, event.target.value); } }))),
                                                        currentApprovalWorkflow.IsTenantDefault === false ? (React.createElement("div", { className: "remove", onClick: function () {
                                                                removeStep(step);
                                                            } },
                                                            React.createElement("i", { className: "fa fa-trash-alt" }))) : null,
                                                        React.createElement("div", { className: "change-position" },
                                                            step.Position !== 1 ? (React.createElement("div", { className: "sort-up", onClick: function (event) {
                                                                    preventDefault(event);
                                                                    sortStep("up", step);
                                                                } },
                                                                React.createElement("i", { className: "fa fa-sort-up" }))) : null,
                                                            currentApprovalWorkflow.AssociatedSteps !== undefined &&
                                                                step.Position !== undefined &&
                                                                step.Position < ((_a = currentApprovalWorkflow.AssociatedSteps) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement("div", { className: "sort-down", onClick: function (event) {
                                                                    preventDefault(event);
                                                                    sortStep("down", step);
                                                                } },
                                                                React.createElement("i", { className: "fa fa-sort-down" }))) : null)),
                                                    React.createElement(FormValidator, { UniqueIdentifier: "approval-step-name-" + step.GUID, ShowOnFocus: true, Validators: ["string-not-empty", "string-is-more-than-2"], Value: step.Name })),
                                                React.createElement(Collapse, { in: step.Position === openStep, appear: true },
                                                    React.createElement("div", { className: "" },
                                                        React.createElement("div", { className: "card-body" + (step.Position !== openStep ? " " : "") },
                                                            React.createElement("div", { className: "approvers" },
                                                                React.createElement(FormUserGroupSelect, { label: React.createElement("span", null,
                                                                        "Approver(s) ",
                                                                        React.createElement("span", { className: "required-astrix" }, "*")), allowMultiple: true, returnType: "usersAndGroups", excludeGuests: true, disabled: currentApprovalWorkflow.IsTenantDefault, defaultValue: step.Approvers, onChange: function (userGroupList) {
                                                                        updateStepApprovers(step, userGroupList);
                                                                    } }),
                                                                React.createElement(FormValidator, { UniqueIdentifier: "approval-step-approvers-" + step.GUID, Validators: ["string-is-more-than-3"], ShowOnFocus: true, ErrorMessage: "You must include at least one approver", Value: step.Approvers })),
                                                            React.createElement("div", { className: "message" },
                                                                React.createElement(Form.Group, { className: "form-group", controlId: "control-instructions" },
                                                                    React.createElement(Form.Label, null, "Message included with approval request emails (Optional)"),
                                                                    React.createElement(Form.Control, { as: "textarea", disabled: currentApprovalWorkflow.IsTenantDefault, placeholder: "Message Text", rows: 3, defaultValue: step.Message, onChange: function (event) {
                                                                            updateStepMessage(step, event.target.value);
                                                                        } }))))))));
                                        }),
                                        currentApprovalWorkflow.AssociatedSteps === undefined || ((_b = currentApprovalWorkflow.AssociatedSteps) === null || _b === void 0 ? void 0 : _b.length) === 0 ? (React.createElement("div", { className: "no-results" },
                                            "No steps yet.. ",
                                            React.createElement("span", { className: "required-astrix" }, "*"))) : null,
                                        React.createElement(FormValidator, { UniqueIdentifier: "approval-steps", Validators: ["array-has-items"], Value: currentApprovalWorkflow.AssociatedSteps })),
                                    currentApprovalWorkflow.IsTenantDefault === false ? (React.createElement("div", { className: "step-actions" },
                                        React.createElement("div", { className: "add add-bottom-button" },
                                            React.createElement("button", { className: "btn btn-success", onClick: function () {
                                                    addStep();
                                                } },
                                                React.createElement("i", { className: "fa fa-plus" }),
                                                React.createElement("span", null, "Add Step"))),
                                        openStep !== 0 ? (React.createElement("div", { className: "save add-bottom-button" },
                                            React.createElement("button", { className: "btn btn-primary", onClick: function () {
                                                    setOpenStep(0);
                                                } },
                                                React.createElement("i", { className: "fa fa-save" }),
                                                React.createElement("span", null, "Save Step")))) : null)) : null))))))) : (React.createElement(SkeletonLoaderFakePortletForm, { twoCols: true })))));
});
export default ApprovalWorkflowDetails;
