/**
 * Convert a font size to a number.
 *
 * @param size The font size to convert to a number.
 */
export var convertFontSizeToNumber = function (size) {
    if (size === undefined) {
        return 0;
    }
    var sizeIsNumber = typeof size === "number";
    if (sizeIsNumber) {
        return size;
    }
    var sizeIsString = typeof size === "string";
    if (sizeIsString) {
        var convertedsize = size.toLowerCase();
        var convertedsizeContainsPx = sizeIsString && convertedsize.includes("px");
        if (convertedsizeContainsPx) {
            var convertedsizeWithoutPx = convertedsize.replace("px", "");
            return parseInt(convertedsizeWithoutPx);
        }
        else {
            if (convertedsize === "xs")
                return 11;
            if (convertedsize === "sm")
                return 13;
            if (convertedsize === "md")
                return 15;
            if (convertedsize === "lg")
                return 17;
            if (convertedsize === "xl")
                return 18;
            if (convertedsize === "xxl")
                return 29;
            // Should never happen, but if it does, please support your additional type here.
            return parseInt(convertedsize);
        }
    }
    else {
        return 0; //Unsupported
    }
};
/**
 * Calculate the line height in pixels. Assumes line height is NOT a percentage, or ratio, or other non-pixel value.
 *
 * @param lineHeight The line height value.
 */
export var calculateLineHeightPixels = function (lineHeight) {
    if (typeof lineHeight === "number") {
        return lineHeight;
    }
    else if (typeof lineHeight === "string" && !lineHeight.includes("px")) {
        return parseInt(lineHeight);
    }
    else if (typeof lineHeight === "string" && lineHeight.includes("px")) {
        var lineHeightWithoutPx = lineHeight.replace("px", "");
        return parseInt(lineHeightWithoutPx);
    }
    else {
        return 12; // default
    }
};
