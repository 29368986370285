var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, Text } from "@mantine/core";
import { OrchestryIconLabel } from "appv2/components/orchestry-icon-label/orchestry-icon-label.component";
import React, { useEffect, useState } from "react";
import "./recommendation-top-categories-metric.scss";
var MAX_NUMBER_OF_CATEGORIES = 6;
export var RecommendationTopCategoriesMetric = function (props) {
    var _a;
    var _b = useState(props.value), selectedLabel = _b[0], setselectedLabel = _b[1];
    useEffect(function () {
        setselectedLabel(props.value);
    }, [props.value]);
    return (React.createElement(Card, { className: "recommendation-top-categories-metric-card", variant: selectedLabel ? "stats-card-selected" : "stats-card-hover" },
        React.createElement(Text, { fw: 500, c: "text-primary.0", size: "sm" }, "Top Categories"),
        React.createElement("div", { className: "top-categories-list" }, (_a = props.categoryItems) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { return a.value.localeCompare(b.value); }).sort(function (a, b) { return (b.count || 0) - (a.count || 0); }).slice(0, MAX_NUMBER_OF_CATEGORIES).map(function (item) {
            var _a;
            return (React.createElement("div", { key: item.value, onClick: function () {
                    var _a;
                    if (((_a = item.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== (selectedLabel === null || selectedLabel === void 0 ? void 0 : selectedLabel.toLowerCase())) {
                        setselectedLabel(item.value.toLowerCase());
                        props.onClick(item.value.toLowerCase());
                    }
                    else {
                        setselectedLabel(undefined);
                        props.onClick(undefined);
                    }
                }, className: "category-list-item" },
                React.createElement(OrchestryIconLabel, __assign({}, item.iconProps, { textFW: (selectedLabel === null || selectedLabel === void 0 ? void 0 : selectedLabel.toLowerCase()) === ((_a = item.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ? 700 : "initial" })),
                React.createElement(Text, { size: "xs", fw: 400, c: "text-secondary.0" }, item.count || "-")));
        }))));
};
