var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TenantConfigurationCurrentActionTypes } from "./types";
import Store from "app/store";
export function Load(tenantConfiguration) {
    if (tenantConfiguration === undefined)
        tenantConfiguration = {};
    //console.log("Load Current Template > ", tenantConfiguration)
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load,
        payload: tenantConfiguration
    };
}
export function Update(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update,
        payload: tenantConfiguration
    };
}
export function UpdateWithoutServerSave(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Without_Server_Save,
        payload: tenantConfiguration
    };
}
export function UpdateSaveToServer() {
    var state = Store.getState();
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Save_To_Server,
        payload: __assign({}, state.tenantConfigurationCurrentState.item)
    };
}
export function UpdateDocTemplates(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DocTempCats,
        payload: tenantConfiguration
    };
}
export function UpdateTemplatesCats(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TempCats,
        payload: tenantConfiguration
    };
}
export function UpdateDirectoryCats(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_DirectoryConfig,
        payload: tenantConfiguration
    };
}
export function UpdateTeamConfig(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_TeamConfig,
        payload: tenantConfiguration
    };
}
export function UpdateConfig(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Update_Config,
        payload: tenantConfiguration
    };
}
export function UpdateSilent(tenantConfiguration) {
    return {
        type: TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Silent_Update,
        payload: tenantConfiguration
    };
}
export var tenantConfigurationCurrentMiddlware = function (store, next, action) {
    next(action);
};
