import * as React from "react";
import "./form-toggle-control.scss";
import FormToggle from "app/components/common/form-toggle";
var FormToggleControl = React.memo(function (props) {
    //console.log("FormToggleControl props.checked",props.checked)
    //console.log("FormToggleControl props.defaultChecked", props.defaultChecked );
    // class FormToggleControl extends React.Component<IProps> {
    // public static defaultProps: Partial<IProps> = {
    //     checked: false
    // };
    // public state: IState = {
    //     checked: this.props.checked,
    // };
    // handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     //if(event.target.checked !== null) {
    //         this.setState({ checked: event.target.checked })
    //     //}
    // }
    //public render() {
    return (React.createElement("div", { className: "form-orch-toggle form-group" +
            (props.border !== undefined && props.border === false ? " no-border" : "") +
            (props.strongText !== undefined && props.strongText === true ? " strong-text" : "") +
            (props.marginBottom !== undefined && props.marginBottom === false ? " no-margin-bottom" : ""), "data-pw": "form-toggle" },
        React.createElement("label", { className: "form-control form-toggle-control" },
            React.createElement(FormToggle, { checked: props.checked, 
                /*defaultChecked={props.checked}*/
                disabled: props.disabled, 
                // handleChange={this.props.handleChange}
                handleChange: function (checked) { return props.handleChange(checked); } }),
            React.createElement("span", null, props.label))));
    //}
});
export default FormToggleControl;
