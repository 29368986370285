/**
 * The App Type the web part is running im.
 * This determines which APIs we call to get the team information.
 */
export var MicrosoftAppType;
(function (MicrosoftAppType) {
    MicrosoftAppType["Teams"] = "Teams";
    MicrosoftAppType["SharePoint"] = "SharePoint";
})(MicrosoftAppType || (MicrosoftAppType = {}));
export var TeamInformationCurrentActionTypes;
(function (TeamInformationCurrentActionTypes) {
    TeamInformationCurrentActionTypes["TeamInformation_Current_Load_TeamContext"] = "TeamInformation_Current_Load_TeamContext";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Load_TeamTabConfiguration"] = "TeamInformation_Current_Load_TeamTabConfiguration";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Load_TeamInformation"] = "TeamInformation_Current_Load_TeamInformation";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Update_TeamInformation"] = "TeamInformation_Current_Update_TeamInformation";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Load_TeamLinks"] = "TeamInformation_Current_Load_TeamLinks";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Load_TeamLinks_NotebookAndPlanner"] = "TeamInformation_Current_Load_TeamLinks_NotebookAndPlanner";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Load_DirectoryMetadata"] = "TeamInformation_Current_Load_DirectoryMetadata";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Update_TeamState"] = "TeamInformation_Current_Update_TeamState";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Clear_DirectoryMetadata"] = "TeamInformation_Current_Clear_DirectoryMetadata";
    TeamInformationCurrentActionTypes["TeamInformation_Current_Clear"] = "TeamInformation_Current_Clear";
})(TeamInformationCurrentActionTypes || (TeamInformationCurrentActionTypes = {}));
