import { useSelector } from "react-redux";
/**
 * Returns true if the current team has guest access enabled.
 * In some cases, our guest policy is serving the purpose of adding guests in lieu of M365's.
 * This is checking ours and not the group setting in graph.
 */
export var useGuestAccess = function () {
    var teamInformation = useSelector(function (state) { return state.teamInformationState.teamInformation; });
    var isGuestAccessEnabled = function () {
        if (teamInformation != null) {
            if (teamInformation.GuestAccess === true) {
                if (teamInformation.GuestRequestPolicy != null) {
                    if (teamInformation.GuestRequestPolicy.AllowGuestAccessForWorkspaces != null) {
                        return teamInformation.GuestRequestPolicy.AllowGuestAccessForWorkspaces;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
            else {
                return false;
            }
        }
        return true;
    };
    return isGuestAccessEnabled();
};
