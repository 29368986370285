import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import RequestWizard from "app/components/requests/request-wizard";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./chrome-request-workspace.scss";
var ChromeRequestWorkspace = React.memo(function (props) {
    var _a = React.useState(0), notificationCount = _a[0], setNotificationCount = _a[1];
    var _b = React.useState(false), requestModalOpen = _b[0], setRequestModalOpen = _b[1];
    var requests = useSelector(function (state) { return state.requestListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    var showRequestWorkspace = orchestryContext.CanCurrentUserAccessRequests === true && !currentUserIsBeaconUser;
    React.useEffect(function () {
        if (requests.length > 0) {
            var pendingRequests = requests.filter(function (r) { return ["Created", "ApprovalRequired", "Approved", "ReadyToProvision", "Provisioning"].some(function (sp) { return sp === r.Status; }); });
            setNotificationCount(pendingRequests.length);
        }
    }, [requests]);
    var openRequestWorkspace = function () {
        if (props.allTabs.some(function (t) { return t.Identifier === "requests" && t.Picked === true; })) {
            props.setTab("requests");
        }
        else {
            setRequestModalOpen(true);
        }
    };
    return showRequestWorkspace ? (React.createElement("div", { className: "chrome-request-workspace" + (props.currentTab === "requests" ? " hide-button" : "") },
        React.createElement("div", { className: "request-button", onClick: function () { return openRequestWorkspace(); } },
            React.createElement("span", null,
                React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.request_workspace.label_title", defaultMessage: "Request Workspace" }))),
        requestModalOpen ? (React.createElement("div", { className: "workspace-request-fixed-modal" + (requestModalOpen ? " open" : "") },
            React.createElement("div", { className: "close-button", onClick: function () { return setRequestModalOpen(false); } },
                React.createElement("i", { className: "la la-times" })),
            React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: props.scollAreaHeight },
                React.createElement(RequestWizard, { addPadding: true })))) : null)) : null;
});
export default ChromeRequestWorkspace;
