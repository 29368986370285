var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrchestryFilterItems } from "appv2/components/orchestry-filter-selector/orchestry-filter-items/orchestry-filter-items.component";
import React from "react";
import { TENANT_INSIGHTS_FILTERS_LIST } from "./use-tenant-insight-filters.hook";
export var TenantInsightsPills = function (_a) {
    var insights = _a.insights, isPageLoading = _a.isPageLoading, maxItems = _a.maxItems;
    var shouldHideToolTip = !!maxItems;
    var filterList = shouldHideToolTip ? TENANT_INSIGHTS_FILTERS_LIST.map(function (filter) { return (__assign(__assign({}, filter), { tooltip: undefined })); }) : TENANT_INSIGHTS_FILTERS_LIST;
    var filters = insights ? insights.map(function (insight) { return filterList.find(function (filter) { return filter.value === insight; }); }).filter(Boolean) : [];
    var staticSelectedFilters = filters.map(function (filter) { return (__assign(__assign({}, filter), { selected: true })); });
    return React.createElement(OrchestryFilterItems, { isLoading: isPageLoading, filters: staticSelectedFilters, groups: [], maxItems: maxItems });
};
