import { asyncMiddlware } from "app/store/async";
import { featureListMiddlware } from "app/store/features/list";
import { featureCurrentMiddlware } from "app/store/features/current";
import { channelListMiddlware } from "app/store/channels/list";
import { channelCurrentMiddlware } from "app/store/channels/current";
import { metadataListMiddlware } from "app/store/metadata/list";
import { metadataCurrentMiddlware } from "app/store/metadata/current";
import { requestListMiddlware } from "app/store/requests/list";
import { userCurrentMiddlware } from "app/store/users/current";
import { notificationToastMiddlware } from "app/store/notifications";
import { authMiddlware } from "app/store/auth";
import { directorySearchMiddlware } from "app/store/directory/search";
import { guestPolicyCurrentMiddlware } from "app/store/lifecycle-guest-policies/current";
import { guestPolicyListMiddlware } from "app/store/lifecycle-guest-policies/list";
var dispatchMethods = [];
var Middleware = /** @class */ (function () {
    function Middleware() {
    }
    // Adds method interceptors into the dispatchArray to be looped through when a redux dispatch is called.
    Middleware.Register = function (middlewareMethods) {
        if (middlewareMethods !== undefined) {
            dispatchMethods = dispatchMethods.concat(middlewareMethods);
        }
    };
    // On Every redux dispatch, intercept the call and run through an array of methods to determine what to do next.
    Middleware.Dispatch = function (store) { return function (next) { return function (action) {
        //const { templateState } = store.getState();
        var dispatchMethodsCount = dispatchMethods.length;
        var dispatchMethodsComplete = 0;
        // Each method should run this function at the end.
        // If it's not run then next(action will never be run). This is useful if an ajax query ends.
        var tempAction = action;
        var completed = function (completedAction) {
            if (completedAction !== undefined) {
                tempAction = completedAction;
            }
            dispatchMethodsComplete++;
            if (dispatchMethodsCount === dispatchMethodsComplete) {
                //  console.log("Done with middleware - " + action.type);
                return next(tempAction);
            }
        };
        // Loop through each middleware interceptor.
        dispatchMethods.forEach(function (method) {
            method(store, completed, action);
        });
        // This has been moved to the complete method above.
        //return next(action);
    }; }; };
    return Middleware;
}());
export { Middleware };
Middleware.Register([
    authMiddlware,
    featureListMiddlware,
    featureCurrentMiddlware,
    channelListMiddlware,
    channelCurrentMiddlware,
    metadataListMiddlware,
    metadataCurrentMiddlware,
    requestListMiddlware,
    userCurrentMiddlware,
    notificationToastMiddlware,
    directorySearchMiddlware,
    guestPolicyListMiddlware,
    asyncMiddlware,
    guestPolicyCurrentMiddlware
]);
