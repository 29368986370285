import * as React from "react";
import "./orchestry-admin-tool-lifecycle-testing.scss";
import { Row, Col, Form } from "react-bootstrap";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { useDispatch, useSelector } from "react-redux";
import * as TenantListStore from "app/store/tenants/list";
import LifecycleReportService from "app/services/lifecycle-report";
import * as TableList from "app/components/common/table-list";
import moment from "moment";
import * as PageHeader from "app/components/common/page-header";
import { toast } from "react-toastify";
import NotificationContent from "app/components/common/notification-content";
import FormButton from "app/components/common/form-button";
import DisableArea from "app/components/common/disable-area";
import OrchestryService from "app/services/orchestry";
import AnimatedLoader from "app/components/common/animated-loader";
var OrchestryAdminToolLifecycleTesting = React.memo(function () {
    var dispatch = useDispatch();
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _a = React.useState(), selectedTenant = _a[0], setSelectedTenant = _a[1];
    var _b = React.useState(undefined), lifecycleSearchTerm = _b[0], setLifecycleSearchTerm = _b[1];
    var _c = React.useState(false), searchLoading = _c[0], setSearchLoading = _c[1];
    var _d = React.useState(), reportItems = _d[0], setReportItems = _d[1];
    //const [overrideSiteId, setOverrideSiteId] = React.useState<undefined | string>();
    var _e = React.useState(), overrideLastActivityDate = _e[0], setOverrideLastActivityDate = _e[1];
    var _f = React.useState(), defaultPolicies = _f[0], setDefaultPolicies = _f[1];
    var _g = React.useState(), tenantOverrideDate = _g[0], setTenantOverrideDate = _g[1];
    var _h = React.useState("US"), selectedRegion = _h[0], setSelectedRegion = _h[1];
    var _j = React.useState(), activeRegions = _j[0], setActiveRegions = _j[1];
    var _k = React.useState(null), tenantGuidsWithOverrides = _k[0], setTenantGuidsWithOverrides = _k[1];
    var _l = React.useState(false), overrideDateLoading = _l[0], setOverrideDateLoading = _l[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        updateTenantsWithOverrides();
    }, [selectedRegion]);
    var updateTenantsWithOverrides = function () {
        LifecycleReportService.getTenantsWithOverrideDate(selectedRegion).then(function (tenantGuids) {
            setTenantGuidsWithOverrides(tenantGuids);
        });
    };
    var setTenantOverrideDateMethod = function (date) {
        if (date !== tenantOverrideDate) {
            setTenantOverrideDate(date);
            if (selectedTenant !== null && selectedTenant !== undefined)
                LifecycleReportService.setOverrideDateForArchivals(date === "" ? null : date, selectedTenant).then(function () {
                    updateTenantsWithOverrides();
                });
        }
    };
    React.useEffect(function () {
        if (selectedTenant !== null && selectedTenant !== undefined) {
            setTenantOverrideDate(undefined);
            setDefaultPolicies(undefined);
            setOverrideDateLoading(true);
            LifecycleReportService.getDefaultPoliciesForTenant(selectedTenant).then(function (defaultPols) {
                setDefaultPolicies(defaultPols);
            });
            LifecycleReportService.getOverrideDateForArchivals(selectedTenant).then(function (date) {
                setOverrideDateLoading(false);
                setTenantOverrideDate(date);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant]);
    React.useEffect(function () {
        loadSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant, lifecycleSearchTerm]);
    var loadSearchResults = function () {
        if (selectedTenant !== undefined && lifecycleSearchTerm !== undefined) {
            setSearchLoading(true);
            LifecycleReportService.searchReportOnSpecificTenant(selectedTenant, lifecycleSearchTerm).then(function (items) {
                setReportItems(items);
                setSearchLoading(false);
            });
        }
    };
    var startETLForOneSite = function (item) {
        if (selectedTenant !== undefined && item !== undefined) {
            toast(React.createElement(NotificationContent, { text: "<span style='font-weight:500;'>Enqueuing Lifecycle ETL for Workspace <strong style='color:green;'>" + item.Title + "</strong> for yesterdays date.</span>", mode: "loading" }), {
                toastId: item.SiteId,
                autoClose: false,
                type: toast.TYPE.DEFAULT
            });
            LifecycleReportService.startETLForOneSite(item.SiteId, selectedTenant).then(function (items) {
                toast.update(item.SiteId, {
                    render: React.createElement(NotificationContent, { text: "Lifecycle ETL queued and should be running shortly. Visit <a href='/hangfire' target='_blank'>Hangfire</a> to view the status", mode: "success" }),
                    type: toast.TYPE.DEFAULT,
                    autoClose: 10000
                });
            });
        }
    };
    var getDaysInactive = function (item) {
        var now = moment(new Date()); //todays date
        var end = moment(overrideLastActivityDate !== undefined && overrideLastActivityDate !== null
            ? moment(overrideLastActivityDate)
            : item.LastActivityDate !== undefined && item.LastActivityDate !== null
                ? moment(item.LastActivityDate, "YYYY-MM-DD")
                : ""); // another date
        var duration = moment.duration(now.diff(end));
        var days = duration.asDays();
        return Math.floor(days);
    };
    var getMinDaysForApprovalReminder = function (item) {
        var daysThreshold = getDaysThreshold(item);
        var now = moment(new Date()); //todays date
        var end = moment(new Date()).add(daysThreshold, "days");
        if (item.ArchivalPolicy !== undefined) {
            var amount = item.ArchivalPolicy.Execution_RetryCadence_NotifyEvery !== null ? item.ArchivalPolicy.Execution_RetryCadence_NotifyEvery : 0;
            if (item.ArchivalPolicy.Execution_RetryCadence_NotifyEveryUnit === "Days") {
                end.add(amount, "days");
            }
            if (item.ArchivalPolicy.Execution_RetryCadence_NotifyEveryUnit === "Hours") {
                end.add(amount, "hours");
            }
            if (item.ArchivalPolicy.Execution_RetryCadence_NotifyEveryUnit === "Months") {
                end.add(amount, "months");
            }
            if (item.ArchivalPolicy.Execution_RetryCadence_NotifyEveryUnit === "Weeks") {
                end.add(amount, "weeks");
            }
        }
        var duration = moment.duration(end.diff(now));
        var days = duration.asDays();
        return Math.floor(days);
    };
    var getMinDaysForNoActionTaken = function (item) {
        var daysThreshold = getDaysThreshold(item);
        var now = moment(new Date()); //todays date
        var end = moment(new Date()).add(daysThreshold, "days");
        if (item.ArchivalPolicy !== undefined) {
            var amount = item.ArchivalPolicy.NoActionTaken_AfterNumber !== null ? item.ArchivalPolicy.NoActionTaken_AfterNumber : 0;
            if (item.ArchivalPolicy.NoActionTaken_AfterUnit === "Days") {
                end.add(amount, "days");
            }
            if (item.ArchivalPolicy.NoActionTaken_AfterUnit === "Hours") {
                end.add(amount, "hours");
            }
            if (item.ArchivalPolicy.NoActionTaken_AfterUnit === "Months") {
                end.add(amount, "months");
            }
            if (item.ArchivalPolicy.NoActionTaken_AfterUnit === "Weeks") {
                end.add(amount, "weeks");
            }
        }
        var duration = moment.duration(end.diff(now));
        var days = duration.asDays();
        return Math.round(days);
    };
    var enqueTest = function (item) {
        toast(React.createElement(NotificationContent, { text: "<span style='font-weight:500;'>Overriding Last Activity Date on Workspace <strong style='color:green;'>" + item.Title + "</strong> and enqueuing Lifecycle Policy Enforcement.</span>", mode: "loading" }), {
            toastId: item.SiteId,
            autoClose: false,
            type: toast.TYPE.DEFAULT
        });
        var overrideDate = overrideLastActivityDate !== undefined ? overrideLastActivityDate : item.LastActivityDate;
        LifecycleReportService.overrideLastActiveDate(item.SiteId, selectedTenant, overrideDate).then(function (worked) {
            loadSearchResults();
            toast.update(item.SiteId, {
                render: React.createElement(NotificationContent, { text: "Lifecycle Policy Enforcement is now running. Visit <a href='/hangfire-us' target='_blank'>Hangfire</a> to view the status", mode: "success" }),
                type: toast.TYPE.DEFAULT,
                autoClose: 10000
            });
        });
    };
    var getDaysThreshold = function (item) {
        var daysThreshold = null;
        if (item.ArchivalPolicyDays === null) {
            if (getPolicy(item) !== null) {
                daysThreshold = getPolicy(item).InactivityDaysThreshold;
            }
        }
        else {
            daysThreshold = item.ArchivalPolicyDays;
        }
        return daysThreshold;
    };
    var getPolicy = function (item) {
        if (item.ArchivalPolicy !== null) {
            return item.ArchivalPolicy;
        }
        if (defaultPolicies !== undefined) {
            var application_1 = item.TemplateType === "Team" ? "Team" : item.TemplateType === "SharePoint Team" ? "TeamSite" : "CommunicationSite";
            var foundDefault = defaultPolicies.find(function (dp) { return dp.Application === application_1; });
            if (foundDefault !== undefined) {
                return foundDefault;
            }
        }
        return null;
    };
    var runPolicyEnforcement = function () {
        if (selectedTenant !== undefined)
            LifecycleReportService.runNightlightPolicyEnforcement(selectedTenant).then();
    };
    var getTenantName = function (tenGuid) {
        var tenant = tenants.find(function (t) { return t.GUID === tenGuid; });
        if (tenant !== undefined && tenant.DisplayName !== undefined) {
            return tenant.DisplayName;
        }
        return tenGuid;
    };
    return (React.createElement("div", { className: "lifecycle-testing fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Lifecycle Tools", subTitle: "Run ETL on single workspaces / Turn on mock date for lifecycle" }),
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, "Select Tenant"),
                    tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedTenant, list: tenants
                            .filter(function (t) { return t.DisplayName !== null && t.DisplayName !== undefined && t.DisplayName !== ""; })
                            .map(function (t) { return ({
                            id: t.GUID !== null && t.GUID !== undefined ? t.GUID : "",
                            value: t.GUID !== null && t.GUID !== undefined ? t.GUID : "",
                            title: t.DisplayName !== null && t.DisplayName !== undefined ? t.DisplayName : ""
                        }); }), onChange: function (tenantGuid) {
                            setSelectedTenant(tenantGuid);
                        } }))),
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Tenant Lifecycle Date Override" }),
                    React.createElement(PortletBody, null,
                        React.createElement(DisableArea, { isDisabled: selectedTenant === null || selectedTenant === undefined, useOverlay: true, disabledText: "Please select a tenant" }, (tenantOverrideDate === null || tenantOverrideDate === undefined) && overrideDateLoading ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement("div", { className: "tenant-overrides" },
                            React.createElement(Form.Group, { className: "form-group date-picker" },
                                React.createElement(Form.Label, null, "Archival Job Today Date Override (UTC)"),
                                React.createElement(Form.Control, { type: "datetime-local", placeholder: "No Override", value: tenantOverrideDate !== null ? moment(tenantOverrideDate).format("YYYY-MM-DDTHH:mm") : "", onChange: function (event) { return setTenantOverrideDateMethod(event.target.value); } }),
                                React.createElement("div", { className: "clear-date", onClick: function () { return setTenantOverrideDateMethod(""); } }, "Clear Date"),
                                overrideDateLoading === true ? (React.createElement("div", { className: "date-loading" },
                                    React.createElement(AnimatedLoader, { size: "xxsmall" }))) : null),
                            React.createElement("div", { className: "start-nightly-policy-enforcement" },
                                React.createElement(FormButton, { icon: "fa fa-jedi", text: "Run Nightly Policy Enforcement", size: "md", theme: "blue", onClick: function () { return runPolicyEnforcement(); } }))))))),
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Run ETL on Single Workspace" }),
                    React.createElement(PortletBody, null,
                        React.createElement(DisableArea, { isDisabled: selectedTenant === null || selectedTenant === undefined, useOverlay: true, disabledText: "Please select a tenant" },
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Label, null, "Search for Workspaces by name"),
                                React.createElement(Form.Control, { placeholder: "Workspace Name", autoComplete: "off", defaultValue: lifecycleSearchTerm, onChange: function (event) { return setLifecycleSearchTerm(event.target.value); } }))),
                        (reportItems !== null && reportItems !== undefined) || searchLoading === true ? (searchLoading === true ? (React.createElement(SkeletonLoaderPortlet, { showHeader: false },
                            React.createElement(SkeletonFakeTableRow, { repeatCols: 4 },
                                React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                                React.createElement(SkeletonFakeTableCol, { widthPercent: 7 },
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                                React.createElement(SkeletonFakeTableCol, { widthPercent: 17 },
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                                React.createElement(SkeletonFakeTableCol, null,
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                                React.createElement(SkeletonFakeTableCol, { widthPercent: 20 },
                                    React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 }))))) : (React.createElement(TableList.Context, { showLoader: false, listItems: reportItems, wrappedInPortlet: false },
                            React.createElement(TableList.TableList, null,
                                React.createElement(TableList.HeaderItem, null, "Name"),
                                React.createElement(TableList.HeaderItem, { type: "centered" }, "Days Inactive"),
                                React.createElement(TableList.HeaderItem, null, "Renewal / Archival Policy Name"),
                                React.createElement(TableList.HeaderItem, { type: "centered" }, "Days Triggered"),
                                React.createElement(TableList.HeaderItem, { type: "centered" }, "Actions"),
                                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Title" }),
                                React.createElement(TableList.ContentItem, { type: "centered", listDataProperyName: "DaysInactive" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) {
                                        var _a;
                                        return (React.createElement(React.Fragment, null, item.ArchivalPolicyName !== null ? (React.createElement("span", null, item.ArchivalPolicyName)) : getPolicy(item) === null ? (React.createElement("span", null, "-----")) : (React.createElement("span", null, (_a = getPolicy(item)) === null || _a === void 0 ? void 0 : _a.Name))));
                                    }, className: "td-archival-policy" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return React.createElement(React.Fragment, null, getDaysThreshold(item) === null ? React.createElement("span", null, "-----") : React.createElement("span", null, getDaysThreshold(item))); }, className: "td-archival-days text-center" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return (React.createElement("div", null,
                                        React.createElement("div", { className: "button-custom b-green", onClick: function () { return startETLForOneSite(item); }, title: "Run ETL for Workspace" },
                                            React.createElement("i", { className: "la la-binoculars" })))); }, className: "td-actions text-center" }))))) : null))),
            React.createElement(Col, null,
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Tenants with override date set" }),
                    React.createElement(PortletBody, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Region"),
                            activeRegions === null || activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) { return setSelectedRegion(value); } }))),
                        tenantGuidsWithOverrides === null || tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement("div", null, tenantGuidsWithOverrides.map(function (tg) { return (React.createElement("div", { className: "tenant-with-override", title: "Load Tenant", onClick: function () { return setSelectedTenant(tg); } },
                            React.createElement("div", { className: "load" },
                                React.createElement("i", { className: "far fa-arrow-alt-circle-left" })),
                            React.createElement("div", { className: "ten" }, getTenantName(tg)))); })))))))));
});
export default OrchestryAdminToolLifecycleTesting;
