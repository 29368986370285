import axios from "axios";
/**
 * The Microsoft Graph Permission Type
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/permissions-consent-overview
 * https://learn.microsoft.com/en-us/graph/api/resources/enums?view=graph-rest-1.0
 *
 * Application permissions, sometimes called app roles are used in the app-only access scenario, without a signed-in user present.
 * The application will be able to access any data that the permission is associated with.
 *
 * Delegated permissions are used in the delegated access scenario.
 * They're permissions that allow the application to act on a user's behalf.
 * The application will never be able to access anything the signed in user themselves couldn't access.
 */
export var PermissionType;
(function (PermissionType) {
    /**
     * Application permissions, sometimes called app roles are used in the app-only access scenario, without a signed-in user present.
     * The application will be able to access any data that the permission is associated with.
     */
    PermissionType["Application"] = "application";
    /**
     * Delegated permissions are used in the delegated access scenario.
     * They're permissions that allow the application to act on a user's behalf.
     * The application will never be able to access anything the signed in user themselves couldn't access.
     */
    PermissionType["Delegated"] = "delegated";
    /**
     * Delegated permissions that can be consented to by the user.
     * Not used by us right now.
     */
    PermissionType["DelegatedUserConsentable"] = "delegatedUserConsentable";
})(PermissionType || (PermissionType = {}));
/**
 * The app type. Used to determine which installer it is being used for.
 */
export var AppType;
(function (AppType) {
    /**
     * The Orchestry main app.
     */
    AppType["Orchestry"] = "Orchestry";
    /**
     * A service app.
     */
    AppType["ServiceApp"] = "ServiceApp";
})(AppType || (AppType = {}));
var PermissionInfoService = /** @class */ (function () {
    function PermissionInfoService() {
    }
    PermissionInfoService.getPermissionInfoItems = function () {
        return new Promise(function (resolve, reject) {
            var axiosConfig = {};
            axiosConfig.method = "GET";
            axiosConfig.url = "/api/PermissionInfo";
            axiosConfig.responseType = "json";
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    PermissionInfoService.upsertPermissionInfoItem = function (item) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(item));
            var axiosConfig = {};
            axiosConfig.method = "PATCH";
            axiosConfig.url = "/api/PermissionInfo";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    PermissionInfoService.deletePermissionInfoItem = function (item) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("data", JSON.stringify(item));
            var axiosConfig = {};
            axiosConfig.method = "DELETE";
            axiosConfig.url = "/api/PermissionInfo";
            axiosConfig.responseType = "json";
            axiosConfig.data = formData;
            axios(axiosConfig)
                .then(function (response) {
                resolve(response.data);
            })
                .catch(function (response) {
                reject(response);
            });
        });
    };
    return PermissionInfoService;
}());
export { PermissionInfoService };
