var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { RequestListActionTypes } from "./types";
import generateGuid from "app/utils/generateGuid";
import Store from "app/store";
export function Load(requests) {
    if (requests === undefined)
        requests = [];
    return {
        type: RequestListActionTypes.Request_List_Load,
        payload: __spreadArray([], requests, true)
    };
}
export function Load_Page(state) {
    if (state === undefined)
        state = {};
    return {
        type: RequestListActionTypes.Request_List_Load_Page,
        payload: state
    };
}
export function Load_User_Page(state) {
    Store.dispatch(Update_IsUserLoading(true));
    return {
        type: RequestListActionTypes.Request_List_Load_User_Page,
        payload: state
    };
}
export function Update_IsUserLoading(isLoading) {
    return {
        type: RequestListActionTypes.Request_List_Update_Is_User_Loading,
        payload: isLoading
    };
}
export function AddItem(request) {
    if (request.Template !== undefined) {
        if (request.Template.GUID !== undefined) {
            var templateGuid = request.Template.GUID;
            request.Template = { GUID: templateGuid };
        }
    }
    if (request.GUID === undefined || (request.GUID !== undefined && (request.GUID === null || request.GUID === ""))) {
        request["GUID"] = generateGuid();
    }
    return {
        type: RequestListActionTypes.Request_List_Item_Add,
        payload: __assign({}, request)
    };
}
export function UpdateItem(request) {
    return {
        type: RequestListActionTypes.Request_List_Item_Update,
        payload: __assign({}, request)
    };
}
export function DeleteItem(request) {
    return {
        type: RequestListActionTypes.Request_List_Item_Delete,
        payload: __assign({}, request)
    };
}
export var requestListMiddlware = function (store, next, action) {
    // if(action.type === RequestListActionTypes.Request_List_Load){
    //     action.payload.forEach((temp:Request) => {
    //         if(temp.AssociatedFeatures){
    //             temp.AssociatedFeatures.forEach((feat:Feature) => {
    //                 if(feat.Icon !== undefined)
    //                     feat.Icon = fontAwesomeHelper.getIconFromName(feat.Icon?.iconName);
    //             });
    //         }
    //     });
    //     next(action);
    // }
    // else {
    next(action);
    //}
};
