var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useForm } from "@mantine/form";
import { TenantLicenseStatus } from "app/store/tenants";
import { getYesterdaysIsoDatetime } from "app/utils/dateUtils";
import { HealthCheckGenerationMethod } from "appv2/api/health-checks/health-check-generation-method.type";
import { HealthCheckStatus } from "appv2/api/health-checks/health-check-status.type";
import { HealthCheckType } from "appv2/api/health-checks/health-check-type.type";
import { useGenerateHealthCheckMutation } from "appv2/api/health-checks/health-checks.api";
import { getBaseTenantEntityInitialValues } from "appv2/api/types/base-tenant-entity.type";
import { OrchestryProcessingToast } from "appv2/components/orchestry-processing-toast/orchestry-processing-toast.component";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import { useTenantPage } from "appv2/pages/partner-tools/tenant/use-tenant-page.hook";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useHealthCheckRouteParams } from "../use-health-check-route-params.hook";
import { mapHealthCheckStartDateToIsoString } from "./generate-new-health-check-page.utils";
import { HealthCheckStartDate } from "./health-check-start-date.type";
import { useUserCanCreateHealthCheck } from "./use-user-can-create-health-check.hook";
export var useGenerateNewHealthCheckPage = function () {
    var _a = useUserCanCreateHealthCheck(), canGenerateHealthCheck = _a.canGenerateHealthCheck, isUserCanCreateHealthCheckLoading = _a.isLoading;
    var _b = useHealthCheckRouteParams(), userType = _b.userType, tenantGuid = _b.tenantGuid;
    var history = useHistory();
    var isMobile = useIsMobile();
    var _c = useState(HealthCheckStartDate["30DaysAgo"]), startDate = _c[0], setStartDate = _c[1];
    var intl = useIntl();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var installationDate = orchestryContext.InstallDate;
    var _d = useState([null, null]), customDateRange = _d[0], setCustomDateRange = _d[1];
    var nameRequiredLocalized = intl.formatMessage({ id: "page.generate_new_health_check.name.control.required", defaultMessage: "Name is required" });
    var _e = useGenerateHealthCheckMutation(), generateHealthCheck = _e[0], _f = _e[1], isGenerateHealthCheckSucccess = _f.isSuccess, isGenerateHealthCheckLoading = _f.isLoading;
    var form = useForm({
        initialValues: __assign({ PartnerGUID: "", Name: "", Type: HealthCheckType.Tenant, StartDate: mapHealthCheckStartDateToIsoString(HealthCheckStartDate["30DaysAgo"]), EndDate: getYesterdaysIsoDatetime(), GeneratedDate: "", GenerationMethod: HealthCheckGenerationMethod.Manual, GeneratedBy: "", Status: HealthCheckStatus.Draft, SharedWith: "", MetricsData: {}, HealthCheckReportBrandingImage: "", PartnerBrandingImage: "", ClientDownloadLink: "", PartnerDownloadLink: "", TenantName: "", ReportTenantLicenseStatus: TenantLicenseStatus.Unlicensed }, getBaseTenantEntityInitialValues()),
        validate: {
            Name: function (value) { return (!value ? nameRequiredLocalized : null); },
            StartDate: function (value) {
                if (!value) {
                    if (startDate === HealthCheckStartDate.Custom && !customDateRange[0]) {
                        return intl.formatMessage({ id: "page.generate_new_health_check.start_date.dropdown.custom.required", defaultMessage: "Custom Date Range is required when custom date is selected" });
                    }
                }
                else {
                    return null;
                }
            }
        }
    });
    var isPageLoading = isGenerateHealthCheckLoading || isUserCanCreateHealthCheckLoading || !form.values;
    var handleFormSubmit = function (values) {
        form.setInitialValues(values);
        generateHealthCheck({ userType: userType, healthCheck: __assign(__assign({}, values), { TenantGUID: tenantGuid }) });
    };
    var onStartDateChange = function (value) {
        setStartDate(value);
        if (value !== HealthCheckStartDate.Custom) {
            var startDateIsoString = mapHealthCheckStartDateToIsoString(value);
            form.setFieldValue("StartDate", startDateIsoString);
            setCustomDateRange([null, null]);
            form.setFieldValue("EndDate", getYesterdaysIsoDatetime());
        }
        else {
            form.setFieldValue("StartDate", "");
            form.setFieldValue("EndDate", "");
        }
    };
    var onDateRangeChange = function (value) {
        var dateRange = value;
        setCustomDateRange(dateRange);
        form.setFieldValue("StartDate", dateRange[0]
            ? moment(dateRange[0])
                .startOf("day")
                .utcOffset(0, true)
                .toISOString()
            : "");
        form.setFieldValue("EndDate", dateRange[1]
            ? moment(dateRange[1])
                .add(1, "day")
                .startOf("day")
                .subtract(1, "seconds")
                .utcOffset(0, true)
                .toISOString()
            : "");
    };
    var isGenerateDisabled = isPageLoading || !form.isDirty();
    var TenantName = useTenantPage(userType !== "partner").tenantDetails.TenantName;
    var pageTitle = userType === "partner" ? "Customer Tenants" : "Health Checks";
    var pageSubTitle = userType === "partner" ? TenantName : undefined;
    var onCancelButtonClicked = function () {
        if (userType === "partner") {
            history.push("/partner-tools/tenants/view/".concat(tenantGuid, "?tab=HealthChecks"));
        }
        else {
            history.push("/health-checks");
        }
    };
    var onBackButtonClicked = function () {
        if (userType === "partner") {
            history.push("/partner-tools/tenants/view/".concat(tenantGuid, "?tab=HealthChecks"));
        }
        else {
            history.push("/health-checks");
        }
    };
    useEffect(function () {
        if (isGenerateHealthCheckSucccess) {
            if (userType === "partner") {
                history.push("/partner-tools/tenants/view/".concat(tenantGuid, "?tab=HealthChecks"));
            }
            else {
                history.push("/health-checks");
            }
            OrchestryProcessingToast(intl.formatMessage({ id: "page.health_check.queue_processing_toast", defaultMessage: "Queued for processing" }));
        }
    }, [history, intl, isGenerateHealthCheckSucccess, userType]);
    return {
        intl: intl,
        pageTitle: pageTitle,
        pageSubTitle: pageSubTitle,
        onBackButtonClicked: onBackButtonClicked,
        form: form,
        isGenerateDisabled: isGenerateDisabled,
        isPageLoading: isPageLoading,
        onCancelButtonClicked: onCancelButtonClicked,
        handleFormSubmit: handleFormSubmit,
        isMobile: isMobile,
        startDate: startDate,
        onStartDateChange: onStartDateChange,
        customDateRange: customDateRange,
        setCustomDateRange: setCustomDateRange,
        onDateRangeChange: onDateRangeChange,
        canGenerateHealthCheck: canGenerateHealthCheck,
        installationDate: installationDate,
        TenantName: TenantName
    };
};
