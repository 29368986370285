import * as React from "react";
// import "./orchestry-admin-tool-cosmos-throttling.scss";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { Portlet, PortletBody } from "app/components/common/portlet";
import PrincipalItem from "app/components/common/principal-item";
import PrincipalSearchModal from "app/components/common/principal-search-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TenantListStore from "app/store/tenants/list";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
var OrchestryAdminToolBulkGuestProvisioning = React.memo(function () {
    var dispatch = useDispatch();
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _a = React.useState(), selectedTenant = _a[0], setSelectedTenant = _a[1];
    var _b = React.useState(), selectedUser = _b[0], setSelectedUser = _b[1];
    var _c = React.useState(null), numberOfGuest = _c[0], setNumberOfGuests = _c[1];
    var _d = React.useState(null), submittingDone = _d[0], setSubmittingDone = _d[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
    }, []);
    React.useEffect(function () {
        setSelectedUser(undefined);
    }, [selectedTenant]);
    var queueBulkOperation = function () {
        setSubmittingDone(false);
        var formData = new FormData();
        // First let's configure the ajax request
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/Maintenance/ProvisionBulkGuests?tenantGuid=" + selectedTenant + "&creatorUserId=" + selectedUser[0].Id + "&numberOfGuests=" + numberOfGuest;
        axiosConfig.responseType = "json";
        axiosConfig.data = formData;
        axios(axiosConfig)
            .then(function (response) {
            setSubmittingDone(true);
        })
            .catch(function (response) { });
    };
    return (React.createElement("div", { className: "home-admin fadein-500ms" },
        React.createElement(Portlet, null,
            React.createElement(PortletBody, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Select Tenant"),
                            tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, defaultValue: selectedTenant, list: tenants
                                    .filter(function (t) { return t.DisplayName !== undefined && t.DisplayName !== ""; })
                                    .map(function (t) { return ({
                                    id: t.GUID !== undefined ? t.GUID : "",
                                    value: t.GUID !== undefined ? t.GUID : "",
                                    title: t.DisplayName !== undefined ? t.DisplayName : ""
                                }); }), onChange: function (tenantGuid) {
                                    setSelectedTenant(tenantGuid);
                                } })))),
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Guest Creator"),
                            selectedTenant !== undefined ? (selectedUser === undefined || (selectedUser !== undefined && selectedUser.length === 0) ? (React.createElement(PrincipalSearchModal, { mode: "users", title: "Find Owners", showSuggested: false, selectedPrincipals: selectedUser, adminTenantGuid: selectedTenant, onPrincipalSelected: function (principal) {
                                    setSelectedUser([principal]);
                                }, onPrinciaplRemoved: function (principal) {
                                    setSelectedUser([]);
                                } },
                                React.createElement(SubtitleButton, { noMargin: true, title: "Select Owner" }))) : (React.createElement("div", null,
                                React.createElement(PrincipalItem, { principal: selectedUser[0], key: selectedUser[0].Id, showBadge: true, onRemove: function (principal) { return setSelectedUser([]); } })))) : (React.createElement(Form.Control, { placeholder: "Select tenant first", disabled: true }))))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Number of Guests:"),
                            React.createElement(Form.Control, { type: "number", onChange: function (event) { return setNumberOfGuests(Number(event.target.value)); } })))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 4 }, submittingDone === false ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : submittingDone === true ? (React.createElement("div", null, "Submitted - Go checkout Hangfire to see the job!")) : (React.createElement(SubtitleButton, { noMargin: true, title: "Queue Bulk Job in Hangfire", disabled: !(selectedUser !== undefined && selectedUser.length !== 0 && numberOfGuest !== null), onClick: function () { return queueBulkOperation(); } }))))))));
});
export default OrchestryAdminToolBulkGuestProvisioning;
