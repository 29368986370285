import { Grid, Group } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import { HealthCheckAnnotationCategory } from "appv2/api/health-checks/health-check-annotation-category.type";
import { OrchestryAnnotationSection } from "appv2/components/orchestry-annotation-section/orchestry-annotation-section.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHealthCheckActivity } from "../../use-health-check-activity.hook";
import { SectionTitle } from "../section-title.component";
import { ConversationsTile } from "./tiles/conversations-tile.component";
import { EditsTile } from "./tiles/edits-tile.component";
import { FilesTile } from "./tiles/files-tile.component";
import { GrowthTile } from "./tiles/growth-tile.component";
import { GuestsTile } from "./tiles/guests-tile.component";
import { MembersTile } from "./tiles/members-tile.component";
import { StorageTile } from "./tiles/storage-tile.component";
import { ViewsTile } from "./tiles/views-tile.component";
export var LeaderboardSection = function () {
    var _a = useHealthCheckActivity(), EndDate = _a.data.EndDate, isLoading = _a.isLoading, isMobile = _a.isMobile, intl = _a.intl;
    return (React.createElement(OrchestryAnnotationSection, { annotationCategory: HealthCheckAnnotationCategory.Leaderboard, renderListOfAnnotations: true, sectionTitle: React.createElement(Group, { gap: 8 },
            React.createElement(SectionTitle, { isLoading: isLoading, title: intl.formatMessage({ id: "page.health_check.leaderboard_section.title", defaultMessage: "Leaderboard" }) }),
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", size: "16px", lh: "24px", fw: 300 }, intl.formatMessage({ id: "as_of_date", defaultMessage: "As of {date}" }, { date: getReadableDate(EndDate) }))) },
        React.createElement(Grid, { mt: 8, grow: isMobile, gutter: "16px" },
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(StorageTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(GrowthTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(FilesTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(ConversationsTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(ViewsTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(EditsTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(MembersTile, null)),
            React.createElement(Grid.Col, { miw: 270, span: 3 },
                React.createElement(GuestsTile, null)))));
};
