import * as React from "react";
import "./directory-update-item.scss";
import "app/components/directory/directory.scss";
import { useSelector, useDispatch } from "react-redux";
import * as DirectoryStore from "app/store/directory/search";
import { Modal, Form } from "react-bootstrap";
import DirectoryService from "app/services/directory";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import AnimatedLoader from "app/components/common/animated-loader";
import MetadataField from "app/components/common/metadata-field";
import FormValidationContext, { FormValidator } from "app/components/common/form-validation";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import { toast } from "react-toastify";
import NotificationContent from "app/components/common/notification-content";
import FormToggle from "app/components/common/form-toggle";
import moment from "moment";
import stripHtmlTags from "app/utils/stripHtmlTags";
var DirectoryUpdateItem = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var dispatch = useDispatch();
    var directory = useSelector(function (state) { return state.directorySearchState; });
    var listMetadata = useSelector(function (state) { return state.metadataListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _h = React.useState(undefined), customMetadataValuesJson = _h[0], setCustomMetadataValuesJson = _h[1];
    var _j = React.useState(false), updateItemLoading = _j[0], setUpdateItemLoading = _j[1];
    var _k = React.useState(false), showEditItemModal = _k[0], setShowEditItemModal = _k[1];
    var _l = React.useState(true), isEditValid = _l[0], setIsEditValid = _l[1];
    var _m = React.useState(true), editShowInDirectory = _m[0], setEditShowInDirectory = _m[1];
    React.useEffect(function () {
        if (props.directoryItem !== null) {
            setEditShowInDirectory(props.directoryItem.IsVisibleInDirectory);
        }
    }, [props.directoryItem]);
    var getRelativeUrl = function (url) {
        var returnUrl = "";
        if (orchestryContext.TenantSharePointUrl !== undefined) {
            returnUrl = url.replace(orchestryContext.TenantSharePointUrl, "");
        }
        return returnUrl;
    };
    var addSiteToDirectory = function (item) {
        //setAddCurrentItem(item);
        //setShowAddModal(true);
    };
    var updateCustomMetadataValue = function (mdfv) {
        var currentValues = [mdfv];
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            var foundCurrent_1 = false;
            currentValues = valuesArray.map(function (fv) {
                if (fv.FieldGUID === mdfv.FieldGUID) {
                    foundCurrent_1 = true;
                    return mdfv;
                }
                else {
                    return fv;
                }
            });
            if (foundCurrent_1 === false) {
                currentValues.push(mdfv);
            }
            //console.log("does match for mapping", currentValues)
        }
        setCustomMetadataValuesJson(JSON.stringify(currentValues));
    };
    var getCurrentMetadataFieldValue = function (md) {
        var value = undefined;
        if (customMetadataValuesJson !== undefined) {
            var valuesArray = JSON.parse(customMetadataValuesJson);
            valuesArray.forEach(function (mdfv, index) {
                if (mdfv.FieldGUID === md.GUID) {
                    value = mdfv.Values;
                }
                else if (mdfv.FieldTitle === md.Title) {
                    value = mdfv.Values;
                }
            });
        }
        if (value === undefined) {
            props.directoryItem.Metadata.forEach(function (metadata, mdIndex) {
                if (metadata.SpColumnsName === md.SPFieldInternalName) {
                    if (metadata.Values !== undefined && metadata.Values !== null && metadata.Values !== "") {
                        if (md.FieldType === "Choice" && md.SingleMultiple === "Multiple") {
                            value = metadata.Values.replace(/\|/g, "\r\n");
                        }
                        else if (md.FieldType === "Date") {
                            value = moment(metadata.Values).format("YYYY-MM-DD");
                            console.log("multiple choice date", value);
                        }
                        else if (md.FieldType === "ManagedMetadata") {
                            var tempTermsArray_1 = [];
                            if (metadata.Values.length > 0 && metadata.Values.indexOf("{") > -1) {
                                var parsedValues = JSON.parse(metadata.Values);
                                console.log("getCurrentMetadataFieldValue() - parsedValues", parsedValues);
                                if (Array.isArray(parsedValues)) {
                                    parsedValues.forEach(function (t) {
                                        tempTermsArray_1.push({
                                            NodeType: "Term",
                                            Id: t.TermGuid !== undefined ? t.TermGuid : t.TermID
                                        });
                                    });
                                }
                                else {
                                    tempTermsArray_1.push({
                                        NodeType: "Term",
                                        Id: parsedValues.TermGuid !== undefined ? parsedValues.TermGuid : parsedValues.TermID
                                    });
                                }
                            }
                            value = JSON.stringify(tempTermsArray_1);
                            console.log("getCurrentMetadataFieldValue() - after - ManagedMetadata", value);
                        }
                        else {
                            value = metadata.Values;
                        }
                    }
                }
            });
        }
        // console.log("getCurrentMetadataFieldValue",md.Title, value);
        return value;
    };
    var UpdateToDirectory = function () {
        //    toast(<NotificationContent text={"Adding " + addCurrentItem.Name + " to the site directory. This could take a few minutes."} mode="loading" />, {
        //       toastId:addCurrentItem.SiteId,
        //       autoClose: false,
        //       type: toast.TYPE.DEFAULT
        //   })
        setUpdateItemLoading(true);
        DirectoryService.updateSiteMetadata(props.directoryItem.Name, props.directoryItem.SiteCollectionUrl, props.directoryItem.SiteId, editShowInDirectory === true ? "true" : "false", customMetadataValuesJson !== undefined ? customMetadataValuesJson : "", props.directoryItem.TemplateType === "Team" ? "Team" : "SharePoint").then(function (data) {
            // setTimeout(()=>{
            dispatch(DirectoryStore.Refresh());
            // },1000);
            //    toast.update(addCurrentItem.SiteId, {
            //       render: <NotificationContent text={"Added " + addCurrentItem.Name + " to the site directory!"} mode="success" />,
            //       type: toast.TYPE.DEFAULT,
            //       autoClose: 2500
            //   });
            setUpdateItemLoading(false);
            setShowEditItemModal(false);
        });
    };
    var SyncronizeItem = function () {
        closeAddAdminMenus();
        toast(React.createElement(NotificationContent, { text: "Synchronizing " + props.directoryItem.Name + " with the Orchestry Directory. This could take a few minutes.", mode: "loading" }), {
            toastId: props.directoryItem.SiteId,
            autoClose: false,
            type: toast.TYPE.DEFAULT
        });
        DirectoryService.syncronizeItem(props.directoryItem.SiteCollectionUrl, props.directoryItem.SiteId).then(function (data) {
            toast.update(props.directoryItem.SiteId, {
                render: React.createElement(NotificationContent, { text: "Synchronization of " + props.directoryItem.Name + " complete!", mode: "success" }),
                type: toast.TYPE.DEFAULT,
                autoClose: 2500
            });
            dispatch(DirectoryStore.Refresh());
        });
    };
    React.useEffect(function () {
        document.addEventListener("mousedown", handleEditMenuClose);
        return function () {
            document.removeEventListener("mousedown", handleEditMenuClose);
        };
    }, []);
    var handleEditMenuClose = function (e) {
        if (e.target !== null) {
            //console.log("current target",e.target )
            var isInFavMenuClick = checkParentHasClass(e.target, 6);
            if (isInFavMenuClick === null) {
                var favItems = document.querySelectorAll(".admin-menu");
                favItems.forEach(function (element) {
                    if (element.classList.contains("menu-open")) {
                        element.classList.remove("menu-open");
                    }
                });
            }
        }
    };
    var checkParentHasClass = function (element, depth) {
        //console.log("Click Element", element.classList);
        if (depth !== 0) {
            depth = depth - 1;
            if (element === undefined) {
                return null;
            }
            else if (element.classList === undefined || element.classList === null) {
                return null;
            }
            else if (element.classList.contains("admin-menu")) {
                return element;
            }
            else if (element.parentElement === null) {
                return null;
            }
            else {
                return checkParentHasClass(element.parentElement, depth);
            }
        }
        return null;
    };
    var toggleAdminMenu = function (e) {
        var parentElement = e.currentTarget.parentElement;
        if (parentElement !== null) {
            if (parentElement.classList.contains("menu-open")) {
                parentElement.classList.remove("menu-open");
            }
            else {
                parentElement.classList.add("menu-open");
            }
        }
        var favItems = document.querySelectorAll(".admin-menu");
        favItems.forEach(function (element) {
            if (element !== parentElement && element.classList.contains("menu-open")) {
                element.classList.remove("menu-open");
            }
        });
    };
    var closeAddAdminMenus = function () {
        var favItems = document.querySelectorAll(".admin-menu");
        favItems.forEach(function (element) {
            if (element.classList.contains("menu-open")) {
                element.classList.remove("menu-open");
            }
        });
    };
    return (React.createElement("div", { className: "directory-update-item" },
        orchestryContext.Role === "Admin" ? (React.createElement("div", { "data-pw": "admin-menu", className: "admin-menu" + (props.openBelow !== undefined && props.openBelow === true ? " open-below" : "") },
            React.createElement("div", { className: "admin-icon", onClick: function (e) {
                    toggleAdminMenu(e);
                } }, "..."),
            React.createElement("div", { className: "admin-menu-dd" },
                React.createElement("div", { className: "admin-menu-item", onClick: function (e) {
                        setShowEditItemModal(true);
                        closeAddAdminMenus();
                    } },
                    React.createElement("i", { className: "far fa-edit" }),
                    React.createElement("span", null, "Edit Item Metadata")),
                React.createElement("div", { className: "admin-menu-item", onClick: function (e) {
                        SyncronizeItem();
                    } },
                    React.createElement("i", { className: "fas fa-sync" }),
                    React.createElement("span", null, "Synchronize Item"))))) : null,
        showEditItemModal === true ? (React.createElement(Modal, { centered: true, show: showEditItemModal, onHide: function () { return setShowEditItemModal(!showEditItemModal); }, animation: true, className: "directory-edit-item-modal directory-modal", backdropClassName: "directory-edit-item-backdrop directory-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Update directory item metadata")),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "content" },
                    React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                            setIsEditValid(isValid);
                        }, UniqueIdentifier: "UpdateDirectoryItemMetadata" },
                        React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 500 },
                            React.createElement("div", { className: "logo" },
                                React.createElement(OfficeProfilePicture, { size: "lg", uid: props.directoryItem.TemplateType.indexOf("Communication") > -1 ? props.directoryItem.SiteCollectionUrl : undefined, type: props.directoryItem.TemplateType.indexOf("SharePoint") > -1 ? "SharePoint" : "Teams", base64image: props.directoryItem.Base64Logo })),
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Label, null, props.directoryItem.TemplateType.indexOf("Viva") > -1 ? "Workspace Name" : "Team/Site Name"),
                                React.createElement(Form.Control, { value: (_a = props.directoryItem) === null || _a === void 0 ? void 0 : _a.Name, disabled: true })),
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Label, null, props.directoryItem.TemplateType.indexOf("Viva") > -1 ? "Workspace Description" : "Team/Site Description"),
                                React.createElement(Form.Control, { value: (_b = props.directoryItem) === null || _b === void 0 ? void 0 : _b.Description, disabled: true, as: "textarea", rows: 2 })),
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Label, null, "Site Url"),
                                React.createElement(Form.Control, { value: (_c = props.directoryItem) === null || _c === void 0 ? void 0 : _c.SiteCollectionUrl, disabled: true })),
                            ((_d = props.directoryItem) === null || _d === void 0 ? void 0 : _d.TeamUrl) !== undefined &&
                                ((_e = props.directoryItem) === null || _e === void 0 ? void 0 : _e.TeamUrl) !== null &&
                                ((_f = props.directoryItem) === null || _f === void 0 ? void 0 : _f.TeamUrl) !== "" &&
                                props.directoryItem.TemplateType.indexOf("Viva") === -1 ? (React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Label, null, "Team Url"),
                                React.createElement(Form.Control, { value: stripHtmlTags((_g = props.directoryItem) === null || _g === void 0 ? void 0 : _g.TeamUrl), disabled: true }))) : null,
                            React.createElement(Form.Group, { className: "form-group" },
                                React.createElement(Form.Label, null, "Show In Directory"),
                                React.createElement("div", null,
                                    React.createElement(FormToggle, { checked: editShowInDirectory, handleChange: function (checked) { return setEditShowInDirectory(checked); } }))),
                            listMetadata.map(function (mdi, index) { return (
                            // <>{mdi.ShowInDetailsForm === true ?
                            React.createElement("div", { key: index },
                                React.createElement(MetadataField, { key: index, metaDataItem: mdi, onChange: function (mdfv) {
                                        updateCustomMetadataValue(mdfv);
                                    }, defaultValue: getCurrentMetadataFieldValue(mdi) },
                                    mdi.FieldType === "Link" && getCurrentMetadataFieldValue(mdi) !== undefined ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-url-" + mdi.SPFieldInternalName, Validators: ["is-valid-url"], Value: getCurrentMetadataFieldValue(mdi), ShowFirst: true })) : null,
                                    mdi.RequiredInDetailsForm !== undefined && mdi.RequiredInDetailsForm === true ? (React.createElement("div", null,
                                        mdi.FieldType === "Date" || mdi.FieldType === "Text" || mdi.FieldType === "Link" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-not-empty"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                        mdi.FieldType === "Choice" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null,
                                        mdi.FieldType === "ManagedMetadata" ? (React.createElement(FormValidator, { UniqueIdentifier: "metadata-validator-" + index, Validators: ["string-array-has-items"], Value: getCurrentMetadataFieldValue(mdi) })) : null)) : null))
                            // :null}</>
                            ); }))))),
            React.createElement(Modal.Footer, null, updateItemLoading === false ? (React.createElement("div", { className: "buttons" },
                React.createElement("div", { className: "cancel", onClick: function () { return setShowEditItemModal(!showEditItemModal); } }, "Cancel"),
                isEditValid === true ? (React.createElement("div", { className: "save", onClick: function () { return UpdateToDirectory(); } },
                    React.createElement("i", { className: "far fa-edit" }),
                    " Update Item in Directory")) : (React.createElement("div", { className: "save no-valid" },
                    React.createElement("i", { className: "far fa-edit" }),
                    " Update Item in Directory")))) : (React.createElement("div", { className: "loading" },
                React.createElement(AnimatedLoader, { size: "small" })))))) : null));
});
export default DirectoryUpdateItem;
