import * as React from "react";
import "./infotooltip.scss";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
var InfoTooltip = React.memo(function (props) { return (React.createElement(React.Fragment, null, props.disabled !== undefined && props.disabled === true ? (React.createElement(React.Fragment, null, props.children)) : (React.createElement(OverlayTrigger, { placement: props.placement !== undefined ? props.placement : "top", delay: {
        show: props.delayShow !== undefined ? props.delayShow : 250,
        hide: props.delayHide !== undefined ? props.delayHide : 400
    }, overlay: 
    // <Tooltip id="overlay-example" {...props}>
    //     My Tooltip
    // </Tooltip>
    // <Popover id="popover-basic" className={props.theme !== undefined ? props.theme : "" }>
    React.createElement(Popover, { id: "popover-basic", className: "orchestry-tooltip popover-basic " + (props.theme !== undefined ? props.theme : "") },
        props.title !== undefined ? React.createElement(Popover.Title, { as: "h3" }, props.title) : null,
        props.content !== undefined || props.children !== undefined ? (React.createElement(Popover.Content, null, props.content !== undefined ? React.createElement("span", null, props.content) : React.createElement(React.Fragment, null, props.children))) : null) }, props.content !== undefined ? (React.createElement("span", { className: "info-tooltip-wrapper" }, props.children)) : (React.createElement("div", { className: "info-circle" + (props.type !== undefined && props.type === "question" ? " question" : " info") }, props.type !== undefined && props.type === "question" ? (
// <FontAwesomeIcon icon={faQuestion} />
React.createElement(FontAwesomeIcon, { icon: faQuestionCircle })) : (
// <FontAwesomeIcon icon={faInfo} />
React.createElement(FontAwesomeIcon, { icon: faInfoCircle })))))))); });
export default InfoTooltip;
