var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./settings-document-template-categories.scss";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import Async from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import AnimatedLoader from "app/components/common/animated-loader";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as PageHeader from "app/components/common/page-header";
var SettingsDocumentTemplateCategories = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState({}), currentTenantConfigInternalState = _a[0], setCurrentTenantConfigInternalState = _a[1];
    var _b = React.useState([]), docTempCategories = _b[0], setDocTempCategories = _b[1];
    var _c = React.useState(false), anyEdits = _c[0], setAnyEdits = _c[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    // After load is complete, populate the copy of the server data in memory for editing.
    React.useEffect(function () {
        //console.log("setting state", currentTenantConfiguration);
        if (currentTenantConfiguration.GUID !== undefined) {
            if (currentTenantConfiguration.GUID !== "") {
                var currentTenantConfigCopy = __assign({}, currentTenantConfiguration);
                if (currentTenantConfiguration.DocumentTemplateCategories !== undefined && currentTenantConfiguration.DocumentTemplateCategories !== "") {
                    setDocTempCategories(JSON.parse(currentTenantConfiguration.DocumentTemplateCategories));
                }
                setCurrentTenantConfigInternalState(currentTenantConfigCopy);
            }
        }
    }, [currentTenantConfiguration]);
    React.useEffect(function () {
        var stringBlockwords = JSON.stringify(docTempCategories);
        setCurrentTenantConfigInternalState(__assign(__assign({}, currentTenantConfigInternalState), { DocumentTemplateCategories: stringBlockwords }));
    }, [docTempCategories]);
    //after the current config object is updated, check to see if the save button should be highlighted.
    React.useEffect(function () {
        checkIfSaveable();
    }, [currentTenantConfigInternalState]);
    // Checks for edits
    var checkIfSaveable = function () {
        //console.log("currentTenantConfigInternalState",currentTenantConfigInternalState);
        if (currentTenantConfigInternalState.GUID !== undefined) {
            if (currentTenantConfigInternalState.GUID !== "") {
                //console.log(JSON.stringify(currentTenantConfigInternalState), "\n\n\n", JSON.stringify(currentTenantConfiguration));
                if (JSON.stringify(currentTenantConfigInternalState) !== JSON.stringify(currentTenantConfiguration)) {
                    //console.log("Does Match",false);
                    if (anyEdits === false) {
                        setAnyEdits(true);
                        save();
                    }
                }
            }
        }
    };
    // On save, save to server and set save button back to non-edited.
    var save = function () {
        dispatch(TenantConfigurationCurrentStore.UpdateDocTemplates(__assign({}, currentTenantConfigInternalState)));
        setAnyEdits(false);
    };
    //
    // React.useEffect(()=>{
    //     try{
    //         saveDiv.current.onclick=save
    //     }catch{}
    // })
    return (React.createElement("div", { className: "document-template-categories fadein-500ms" + (props.isModal !== undefined && props.isModal === true ? " is-modal" : "") },
        props.isModal === undefined ? (
        // <Subtitle title="Settings" subtitle="Document Template Categories">
        //     <NavLink to={"/settings"}><SubtitleButton title="Cancel" variant="light" /></NavLink>
        //     <div ref={saveDiv} style={{display:"inline-block"}}><SubtitleButton title="Save" variant="success" /></div>
        // </Subtitle>
        React.createElement(React.Fragment, null,
            React.createElement(PageHeader.SetLeftContent, { title: "Settings" }),
            React.createElement(PageHeader.SetRightContent, null))) : null,
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Started },
            React.createElement(AnimatedLoader, { text: "Loading..." })),
        React.createElement(Async, { uid: TenantConfigurationCurrentStore.TenantConfigurationCurrentActionTypes.TenantConfiguration_Current_Load, status: AsyncStatus.Succeeded }, currentTenantConfigInternalState.GUID !== "" ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "kt-portlet" },
                React.createElement("div", { className: "kt-portlet__body" },
                    React.createElement(Container, { fluid: true },
                        React.createElement(Row, { className: "" },
                            React.createElement(Col, { md: props.isModal !== undefined && props.isModal === true ? 12 : 6 },
                                React.createElement("div", { className: "blocked-words-form" },
                                    React.createElement(Form.Label, null, "Document Template Categories"),
                                    React.createElement("div", { className: "blocked-words" },
                                        React.createElement(TagsInput, { value: docTempCategories !== null ? docTempCategories : [], onChange: function (tags) { return setDocTempCategories(tags); }, addKeys: [9, 13, 188], removeKeys: [8, 46] })),
                                    React.createElement("p", { className: "blocked-help" }, "Hit tab, comma, or return to add category. Hit backspace/delete to remove."))))))))) : null)));
});
export default SettingsDocumentTemplateCategories;
