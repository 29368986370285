import * as AsyncStore from "app/store/async";
import * as OrchestryContextCurrentStore from "app/store/orchestry-context/current";
export var orchestryContextNotificationConfigMap = [
    {
        ActionType: AsyncStore.AsyncActionTypes.Async_Failed,
        Actions: [
            {
                Text: "Error when trying to load tenant configuration.",
                ActionType: OrchestryContextCurrentStore.OrchestryContextCurrentActionTypes.OrchestryContext_Current_Load
            }
        ]
    }
];
