import { Card, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { OrchestryCardTitle } from "../orchestry-card-title.component";
import { useTenantPage } from "../use-tenant-page.hook";
import { TenantInsightsPills } from "./tenant-insights-pills.component";
export var TenantInsights = function () {
    var _a = useTenantPage(), Insights = _a.tenantDetails.Insights, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, mih: "100%", style: { overflow: "visible" } },
        React.createElement(Stack, { gap: 16 },
            React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Tenant Insights", icon: ORCH_ICONS.lightbulb05, colorVariant: "gray-7" }),
            React.createElement(TenantInsightsPills, { insights: Insights, isPageLoading: isPageLoading }))));
};
