import { Card, Skeleton } from "@mantine/core";
import React from "react";
import "./recommendation-priority-metric-skeleton.scss";
export var RecommendationPriorityMetricSkeleton = function () { return (React.createElement(Card, { className: "recommendation-priority-metric-skeleton" },
    React.createElement("div", { className: "left-section" },
        React.createElement(Skeleton, { w: 48, h: 12.5, radius: 17.5, mr: 13 }),
        React.createElement("div", { className: "circles" },
            React.createElement(Skeleton, { mt: 23.5, circle: true, h: 58, w: 58 }),
            React.createElement("div", { className: "white-circle" }))),
    React.createElement("div", { className: "right-section" },
        React.createElement("div", { className: "group" },
            React.createElement(Skeleton, { circle: true, h: 21.3, w: 21.3 }),
            React.createElement(Skeleton, { w: 48, h: 12.5, radius: 17.5 })),
        React.createElement("div", { className: "group" },
            React.createElement(Skeleton, { circle: true, h: 21.3, w: 21.3 }),
            React.createElement(Skeleton, { w: 46, h: 12.5, radius: 17.5 })),
        React.createElement("div", { className: "group" },
            React.createElement(Skeleton, { circle: true, h: 21.3, w: 21.3 }),
            React.createElement(Skeleton, { w: 76, h: 12.5, radius: 17.5 }))))); };
