import { Card, Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryDeltaPeriod } from "appv2/components/orchestry-delta-period/orchestry-delta-period.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
export var ChatMessagesTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, numberOfDaysInPeriod = _a.numberOfDaysInPeriod, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.TeamsEngagement, _g = _f === void 0 ? {} : _f, _h = _g.ChatMessages, _j = _h === void 0 ? {} : _h, Total = _j.Total, PreviousValue = _j.PreviousValue, DeltaValue = _j.DeltaValue, DeltaPercentage = _j.DeltaPercentage, DataCoverageAvailable = _j.DataCoverageAvailable;
    return (React.createElement(Card, { variant: "default", h: "100%", p: 16, style: { overflow: "visible" } },
        React.createElement(Group, { gap: "16px", align: "flex-start" },
            React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "sm", variant: "light-circle", colorVariant: "pink-8", iconClassName: ORCH_ICONS.messageChatSquare }),
            React.createElement(Stack, { gap: "0px" },
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "30", fw: 500, lh: "38px" }, Total === null || Total === void 0 ? void 0 : Total.toLocaleString()),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-tertiary.0", fz: "12", fw: 400, lh: "18px", mb: 8 }, intl.formatMessage({ id: "page.health_check.chat_messages_tile.title", defaultMessage: "Chat Messages" })),
                React.createElement(OrchestryDeltaPeriod, { isLoading: isLoading, align: "Flex-start", lastPeriodValue: PreviousValue, deltaValue: DeltaValue, deltaPercentage: DeltaPercentage, dataCoverageAvailable: DataCoverageAvailable, numberOfDaysInPeriod: numberOfDaysInPeriod })))));
};
