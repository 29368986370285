var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./card-crawl-settings.scss";
import { useSelector, useDispatch } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { Form } from "react-bootstrap";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import { SkeletonLoaderFakeBox, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import FormDropdownMultiSelect from "app/components/common/form-dropdown-multi-select";
var CardCrawlSettings = React.memo(function (props) {
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    // const [currentTenantConfigInternalState, setCurrentTenantConfigInternalState] = React.useState({} as TenantConfiguration);
    var _a = React.useState(false), tenantConfigLoaded = _a[0], setTenantConfigLoaded = _a[1];
    // On first load, Load the data from the server.
    React.useEffect(function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(function () {
            setTenantConfigLoaded(true);
        });
    }, []);
    React.useEffect(function () {
        if (currentTenantConfiguration !== undefined && currentTenantConfiguration !== null && tenantConfigLoaded === true) {
            if (currentTenantConfiguration.LifecycleCrawlSettings === undefined ||
                (currentTenantConfiguration.LifecycleCrawlSettings !== undefined && currentTenantConfiguration.LifecycleCrawlSettings === null)) {
                updateCrawlSettings(getAllCrawlSettingOptions());
            }
        }
    }, [currentTenantConfiguration]);
    var updateCrawlSettings = function (options) {
        dispatch(TenantConfigurationCurrentStore.UpdateWithoutServerSave(__assign(__assign({}, currentTenantConfiguration), { LifecycleCrawlSettings: options.map(function (o) { return o.value; }) })));
    };
    var getAllCrawlSettingOptions = function () {
        return [
            {
                id: "Teams",
                title: "Teams",
                value: "Teams"
            },
            {
                id: "TeamSites",
                title: "Team Sites",
                value: "TeamSites"
            },
            {
                id: "CommunicationSites",
                title: "Communication Sites",
                value: "CommunicationSites"
            },
            {
                id: "OutlookGroups",
                title: "Outlook Groups",
                value: "OutlookGroups"
            }
        ];
    };
    return (React.createElement("div", { className: "card-etl-crawl-settings fadein-500ms", "data-pw": "crawl-settings" }, tenantConfigLoaded === true ? (React.createElement(React.Fragment, null, currentTenantConfiguration.GUID !== "" ? (React.createElement(Portlet, null,
        React.createElement(PortletHeader, { title: "Crawl Settings" }),
        React.createElement(PortletBody, null,
            React.createElement(Form.Group, { className: "form-group", "data-pw": "form-group" },
                React.createElement(Form.Label, null, "Select the type of workspaces to include in the crawl"),
                React.createElement(FormDropdownMultiSelect, { list: getAllCrawlSettingOptions(), onChange: function (chosenValues) {
                        updateCrawlSettings(chosenValues);
                    }, values: currentTenantConfiguration.LifecycleCrawlSettings === undefined ||
                        (currentTenantConfiguration.LifecycleCrawlSettings !== undefined && currentTenantConfiguration.LifecycleCrawlSettings === null)
                        ? getAllCrawlSettingOptions()
                        : currentTenantConfiguration.LifecycleCrawlSettings.map(function (value) { return ({
                            id: value,
                            title: value,
                            value: value
                        }); }) }))))) : null)) : (React.createElement(SkeletonLoaderPortlet, { bodyHeight: 250 },
        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 5 })))));
    // return (
    //    <div className="card-etl-crawl-settings">
    //       <Portlet>
    //          <PortletHeader title="Crawl Settings" />
    //          <PortletBody></PortletBody>
    //       </Portlet>
    //    </div>
    // );
});
export default CardCrawlSettings;
