var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import TeamLink from "app/components/common/team-link";
import { RenderWithLinkIfCan } from "app/components/requests/request-details";
import GroupSettingsService from "app/services/group-settings";
import SensitivityLabelsService from "app/services/sensitivity-labels";
import SharePointService from "app/services/sharepoint";
import { PermissionTypes } from "app/store/permissions/types";
import * as RequestCurrentStore from "app/store/requests/current";
import { TeamSpecializations } from "app/store/team-specializations/types";
import getStringTwoLetterAcronym from "app/utils/getStringTwoLetterAcronym";
import moment from "moment";
import React from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SHAREPOINT_MULTIGEO_CODES } from "../../request-wizard/steps/3_details/sharepoint-geocodes";
import "./request-details-header.scss";
import { getWorkspaceTypeName } from "./request-details-header.utils";
var workspaceTypeTranslationMap = {};
var urlTypingTimer;
var RequestDetailsHeader = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var intl = useIntl();
    var dispatch = useDispatch();
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var _l = React.useState(false), siteUrlIsValid = _l[0], setSiteUrlIsValid = _l[1];
    var _m = React.useState(false), siteUrlIsLoading = _m[0], setSiteUrlIsLoading = _m[1];
    var _o = React.useState(""), siteUrlFullUrl = _o[0], setSiteUrlFullUrl = _o[1];
    var _p = React.useState(false), sensitivityLabelsEnabled = _p[0], setSensitivityLabelsEnabled = _p[1];
    var _q = React.useState(), sensitivityPermissionOverride = _q[0], setSensitivityPermissionOverride = _q[1];
    var _r = React.useState(), sensitivityLabels = _r[0], setSensitivityLabels = _r[1];
    var _s = React.useState(), sensitivityConfig = _s[0], setSensitivityConfig = _s[1];
    var _t = React.useState(false), sensitivityLabelsLoading = _t[0], setSensitivityLabelsLoading = _t[1];
    var _u = React.useState(), classificationLabels = _u[0], setClassificationLabels = _u[1];
    var _v = React.useState(), classificationTenantConfig = _v[0], setClassificationTenantConfig = _v[1];
    var _w = React.useState(), classificationTemplateConfig = _w[0], setClassificationTemplateConfig = _w[1];
    var _x = React.useState(false), classificationLabelsLoading = _x[0], setClassificationLabelsLoading = _x[1];
    var _y = React.useState("None"), labelMode = _y[0], setLabelMode = _y[1];
    var _z = React.useState(PermissionTypes.filter(function (wt, index) {
        if (wt.Value === "Choice") {
            return false;
        }
        if (currentRequest.RequestType === "SharePoint") {
            if (wt.Value !== "Organization") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }).map(function (wt, index) { return ({
        id: index + "",
        title: wt.Title,
        description: wt.Description,
        value: wt.Value,
        icon: wt.Icon
    }); })), permissionType = _z[0], setPermissionType = _z[1];
    React.useEffect(function () {
        if (currentRequest.SiteURL && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateType)) {
            setSiteUrlIsLoading(true);
            setSiteUrlIsValid(false);
            runUrlValidators();
        }
    }, [currentRequest.SiteURL, currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateType]);
    var runUrlValidators = function () {
        clearTimeout(urlTypingTimer);
        urlTypingTimer = setTimeout(function () {
            if (currentRequest.SiteURL !== undefined) {
                var isCommSite = (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateType) === "SharePoint" && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SharePointTemplateType) === "ModernCommunications";
                SharePointService.DoesSiteCollectionExistWithUrlReturned(currentRequest.SiteURL, isCommSite).then(function (results) {
                    if (results.Cancel === undefined) {
                        setSiteUrlIsValid(!results.DoesSiteExist);
                        setSiteUrlFullUrl(results.SiteUrl);
                        setSiteUrlIsLoading(false);
                    }
                });
            }
        }, 300);
    };
    var renderType = function (request) {
        if (request.RequestType === "SharePoint") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "Team") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "VivaEngageCommunity") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconViva, alt: "Viva Engage Community", className: "fadein-500ms" }))));
        }
        return React.createElement(React.Fragment, null);
    };
    var urlOnChangeNoSpaces = function (value) {
        dispatch(RequestCurrentStore.Update({ SiteURL: value.replace(/[^a-zA-Z0-9_-]+/g, "") }));
    };
    var getSensitivityLabel = function () {
        var selectedLabel = null;
        if (currentRequest.SensitivityLabel !== undefined && currentRequest.SensitivityLabel !== null && currentRequest.SensitivityLabel.indexOf("{") > -1) {
            selectedLabel = JSON.parse(currentRequest.SensitivityLabel);
        }
        else {
        }
        return selectedLabel;
    };
    var getSensitivityLabelTenantConfig = function (labelId) {
        var foundConfig = undefined;
        if (sensitivityConfig !== undefined) {
            foundConfig = sensitivityConfig.LabelConfigs.find(function (lc) { return lc.Id === labelId; });
        }
        return foundConfig;
    };
    var setSelectedSensitivityLabel = function (label) {
        if (label !== null) {
            var labelConfig = getSensitivityLabelTenantConfig(label.Id);
            var currentSelectedLabel = getSensitivityLabel();
            if ((labelConfig === null || labelConfig === void 0 ? void 0 : labelConfig.PermissionOverride) !== null) {
                setSensitivityPermissionOverride(labelConfig === null || labelConfig === void 0 ? void 0 : labelConfig.PermissionOverride);
            }
            else {
                setSensitivityPermissionOverride(undefined);
            }
            if (currentSelectedLabel === null || currentSelectedLabel.Id !== label.Id) {
                dispatch(RequestCurrentStore.Update({ SensitivityLabel: JSON.stringify(__assign({}, label)) }));
            }
        }
        else {
            dispatch(RequestCurrentStore.Update({ SensitivityLabel: null }));
            setSensitivityPermissionOverride(undefined);
        }
    };
    var getClassificationLabelObject = function () {
        var selectedLabel = undefined;
        if (currentRequest.Classification !== undefined && currentRequest.Classification !== null && currentRequest.Classification !== "") {
            selectedLabel = classificationLabels === null || classificationLabels === void 0 ? void 0 : classificationLabels.find(function (cl) { return cl.name === currentRequest.Classification; });
        }
        else {
        }
        return selectedLabel;
    };
    var setSelectedClassificationLabel = function (labelKey) {
        if (labelKey !== null) {
            if (currentRequest.Classification === null || currentRequest.Classification !== labelKey) {
                dispatch(RequestCurrentStore.Update({ Classification: labelKey }));
            }
        }
        else {
            dispatch(RequestCurrentStore.Update({ Classification: null }));
        }
    };
    React.useEffect(function () {
        if (sensitivityPermissionOverride === undefined) {
            if (currentTemplate !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.PermissionType) !== undefined) {
                if ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.PermissionType) !== "Choice") {
                    dispatch(RequestCurrentStore.Update({ PermissionType: currentTemplate.PermissionType }));
                }
            }
        }
        else {
            dispatch(RequestCurrentStore.Update({ PermissionType: sensitivityPermissionOverride }));
        }
    }, [sensitivityPermissionOverride]);
    React.useEffect(function () {
        if (currentTemplate !== undefined && currentTemplate !== null && currentTenantConfiguration !== undefined && currentTenantConfiguration.GUID !== undefined) {
            var tmplt_1 = currentTemplate;
            SensitivityLabelsService.doesTenantHaveLabelsEnabled().then(function (isEnabled) {
                if (isEnabled === true) {
                    if (tmplt_1.SensitivityLabelType !== undefined && tmplt_1.SensitivityLabelType !== null && tmplt_1.SensitivityLabelType !== "None") {
                        var tenantLabelConfig = null;
                        if (currentTenantConfiguration.SensitivityLabelConfigJson !== undefined &&
                            currentTenantConfiguration.SensitivityLabelConfigJson !== null &&
                            currentTenantConfiguration.SensitivityLabelConfigJson !== "") {
                            tenantLabelConfig = JSON.parse(currentTenantConfiguration.SensitivityLabelConfigJson);
                            //console.log("Setting Sen Config", tenantLabelConfig)
                            if (tenantLabelConfig !== undefined && tenantLabelConfig.IsEnabled !== undefined) {
                                if (tenantLabelConfig.IsEnabled === true) {
                                    setLabelMode("Sensitivity");
                                }
                            }
                            setSensitivityConfig(tenantLabelConfig);
                        }
                        if (tmplt_1.SensitivityLabelsConfigJson !== undefined && tmplt_1.SensitivityLabelsConfigJson !== null && tenantLabelConfig !== null) {
                            var labels = JSON.parse(tmplt_1.SensitivityLabelsConfigJson);
                            if (labels.length > 0 && tmplt_1.GUID !== undefined) {
                                setSensitivityLabelsLoading(true);
                                SensitivityLabelsService.getTemplateLabels(tmplt_1.GUID).then(function (labels) {
                                    setSensitivityLabels(labels);
                                    setSensitivityLabelsLoading(false);
                                });
                            }
                        }
                    }
                    else {
                        setSensitivityLabels(undefined);
                    }
                }
                else {
                    if (tmplt_1.ClassificationType !== undefined && tmplt_1.ClassificationType !== null && tmplt_1.ClassificationType !== "None") {
                        var tenantClassificationConfig_1 = null;
                        if (currentTenantConfiguration.ClassificationConfigJson !== undefined && currentTenantConfiguration.ClassificationConfigJson !== null) {
                            tenantClassificationConfig_1 = JSON.parse(currentTenantConfiguration.ClassificationConfigJson);
                            if (tenantClassificationConfig_1 !== undefined && tenantClassificationConfig_1.IsEnabled !== undefined) {
                                if (tenantClassificationConfig_1.IsEnabled === true) {
                                    setLabelMode("Classification");
                                }
                            }
                            setClassificationTenantConfig(tenantClassificationConfig_1);
                        }
                        if (tmplt_1.ClassificationsConfigJson !== undefined && tmplt_1.ClassificationsConfigJson !== null && tenantClassificationConfig_1 !== null) {
                            var labels = JSON.parse(tmplt_1.ClassificationsConfigJson);
                            if (labels.length > 0 && tmplt_1.GUID !== undefined) {
                                setClassificationTemplateConfig(labels);
                                setClassificationLabelsLoading(true);
                                GroupSettingsService.getGlobalSettings().then(function () {
                                    var getIcon = function (key) {
                                        var returnIcon = "";
                                        if (tenantClassificationConfig_1 !== null && tenantClassificationConfig_1.ClassifiedConfigs !== null) {
                                            var foundConfig = tenantClassificationConfig_1.ClassifiedConfigs.find(function (clc) { return clc.Key === key; });
                                            if (foundConfig !== undefined) {
                                                returnIcon = foundConfig.Icon !== null ? foundConfig.Icon : "";
                                            }
                                        }
                                        return returnIcon;
                                    };
                                    var classifications = GroupSettingsService.getClassificationListTableData().map(function (td) { return (__assign(__assign({}, td), { icon: getIcon(td.name) })); });
                                    console.log("classifications", classifications);
                                    setClassificationLabels(classifications);
                                    setClassificationLabelsLoading(false);
                                });
                            }
                        }
                    }
                    else {
                        setSensitivityLabels(undefined);
                    }
                }
            });
            // if(currentTemplate.SensitivityLabelType !== undefined && currentTemplate.SensitivityLabelType !== null && currentTemplate.SensitivityLabelType !== "None") {
            //    let tenantLabelConfig:SensitivityConfig|null = null;
            //    if(currentTenantConfiguration.SensitivityLabelConfigJson !== undefined && currentTenantConfiguration.SensitivityLabelConfigJson !== null  && currentTenantConfiguration.SensitivityLabelConfigJson !== "") {
            //       tenantLabelConfig = JSON.parse(currentTenantConfiguration.SensitivityLabelConfigJson) as SensitivityConfig
            //       //console.log("Setting Sen Config", tenantLabelConfig)
            //       setSensitivityConfig(tenantLabelConfig);
            //    }
            //    if(currentTemplate.SensitivityLabelsConfigJson !== undefined && currentTemplate.SensitivityLabelsConfigJson !== null && tenantLabelConfig !== null){
            //       const labels = JSON.parse(currentTemplate.SensitivityLabelsConfigJson) as string[];
            //       if(labels.length > 0 && currentTemplate.GUID !== undefined) {
            //          setSensitivityLabelsLoading(true);
            //          SensitivityLabelsService.getTemplateLabels(currentTemplate.GUID).then((labels:SensitivityLabel[]) => {
            //             setSensitivityLabels(labels);
            //             setSensitivityLabelsLoading(false);
            //          })
            //       }
            //    }
            // } else {
            //    setSensitivityLabels(undefined);
            // }
        }
    }, [currentTemplate, currentTenantConfiguration]);
    return (React.createElement(Portlet, { className: "request-details-header request-details" + (props.EditModeOn ? " edit-mode-on" : "") },
        React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.details.title", defaultMessage: "Request Details" }) }),
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "kt-widget kt-widget--user-profile-3" },
                React.createElement("div", { className: "kt-widget__top" },
                    React.createElement("div", { className: "kt-widget__media kt-hidden-" },
                        React.createElement(RenderWithLinkIfCan, { currentRequest: currentRequest }, currentRequest.Image !== undefined && currentRequest.Image !== null && currentRequest.Image !== "" ? (React.createElement("div", { className: "preview-image", style: { backgroundImage: "url('" + currentRequest.Image + "')" } })) : (React.createElement("div", { className: "kt-media kt-media--xl kt-media--circle kt-media--info" },
                            React.createElement("span", null, getStringTwoLetterAcronym(currentRequest.Name !== undefined ? currentRequest.Name : "")))))),
                    React.createElement("div", { className: "kt-widget__content" },
                        React.createElement("div", { className: "kt-widget__head", style: { marginBottom: "10px" } },
                            React.createElement("div", { className: "kt-widget__username", "data-pw": "workspace-name", style: { flex: "1" } },
                                React.createElement(RenderWithLinkIfCan, { currentRequest: currentRequest }, props.EditModeOn ? (React.createElement(Form.Control, { placeholder: intl.formatMessage({ id: "page.request_details.details.enter_name_placeholder", defaultMessage: "Enter Name" }), value: currentRequest.Name, 
                                    // onChange={(event:React.ChangeEvent<HTMLInputElement>) => dispatch(RequestCurrentStore.Update({Name:event.target.value.replace(/[^a-zA-Z0-9!_-]+/g,"")}))}
                                    onChange: function (event) { return dispatch(RequestCurrentStore.Update({ Name: event.target.value.replace(/&#/g, "&") })); } })) : (React.createElement(React.Fragment, null, currentRequest.Name))))),
                        React.createElement(Row, { className: "form-group form-group-xs form-group-template" },
                            React.createElement(Col, { lg: 3, className: "rd-title" },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.template_label", defaultMessage: "Template:" }))),
                            React.createElement(Col, { lg: 9, className: "rd-value" },
                                React.createElement("div", { className: "edit-mode-locked" },
                                    React.createElement(NavLink, { to: "/workspaces/templates/edit/" + ((_a = currentRequest.Template) === null || _a === void 0 ? void 0 : _a.GUID) }, (_b = currentRequest.Template) === null || _b === void 0 ? void 0 : _b.Title)))),
                        React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    ((_c = currentRequest.Template) === null || _c === void 0 ? void 0 : _c.TemplateType) === "Team" ? (React.createElement(FormattedMessage, { id: "page.request_details.details.group_name_label", defaultMessage: "Group Name" })) : (React.createElement(FormattedMessage, { id: "page.request_details.details.site_url_label", defaultMessage: "Site URL" })),
                                    ":")),
                            React.createElement(Col, { lg: 9 }, props.EditModeOn ? (React.createElement(React.Fragment, null,
                                React.createElement(Form.Control, { placeholder: intl.formatMessage({ id: "page.request_details.details.enter_url_placeholder", defaultMessage: "Enter Url" }), value: currentRequest.SiteURL, onChange: function (event) { return urlOnChangeNoSpaces(event.target.value); } }),
                                React.createElement("div", { className: "site-url-validation" },
                                    React.createElement(Collapse, { in: siteUrlIsLoading && currentRequest.SiteURL !== undefined && currentRequest.SiteURL.length > 1, appear: true },
                                        React.createElement("div", null,
                                            React.createElement("div", { className: "url-loading" },
                                                React.createElement(AnimatedLoader, { size: "xxsmall" })))),
                                    React.createElement(Collapse, { in: currentRequest.SiteURL !== undefined && currentRequest.SiteURL.length > 1 && siteUrlIsLoading === false, appear: true },
                                        React.createElement("div", { className: "url-validation" },
                                            React.createElement("div", { className: "url" }, siteUrlFullUrl),
                                            React.createElement("div", { className: "results" }, siteUrlIsValid ? (React.createElement("div", { className: "is-valid" },
                                                React.createElement(FormattedMessage, { id: "page.request_details.details.available_label", defaultMessage: "Available" }))) : (React.createElement("div", { className: "is-not-valid" },
                                                React.createElement(FormattedMessage, { id: "page.request_details.details.not_available_label", defaultMessage: "Not Available" }))))))))) : (React.createElement("span", null, currentRequest.SiteURL)))),
                        React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.workspace_type_label", defaultMessage: "Workspace Type:" }))),
                            React.createElement(Col, { lg: 9 },
                                React.createElement("div", { className: "edit-mode-locked" },
                                    React.createElement("span", null, renderType(currentRequest)),
                                    React.createElement("span", null, getWorkspaceTypeName(intl, currentRequest.RequestType, currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SharePointTemplateType))))),
                        (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) !== null && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) !== "None" && labelMode === "Sensitivity" ? (React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.sensitivity_label", defaultMessage: "Sensitivity Label:" }))),
                            React.createElement(Col, { lg: 9 }, props.EditModeOn ? (sensitivityLabelsLoading === true ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (sensitivityLabels !== undefined && (React.createElement(FormDropdownSelect, { list: sensitivityLabels.map(function (sl, slIndex) {
                                    return ({
                                        id: slIndex.toString(),
                                        title: sl.Name,
                                        customIcon: sl.Icon,
                                        value: sl.Id,
                                        object: sl
                                    });
                                }), disabled: (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) !== undefined &&
                                    (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) !== null &&
                                    (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) === "Enforced", defaultValue: getSensitivityLabel() !== null ? getSensitivityLabel().Id : undefined, onChange: function (sl) { return setSelectedSensitivityLabel(sl); } })))) : getSensitivityLabel() !== null ? (React.createElement(React.Fragment, null,
                                React.createElement("span", null,
                                    React.createElement("div", { className: "sensitivity-type" },
                                        React.createElement("i", { className: getSensitivityLabel().icon !== null ? getSensitivityLabel().Icon : "la la-tag" }))),
                                React.createElement("span", null, (_d = getSensitivityLabel()) === null || _d === void 0 ? void 0 : _d.Name))) : null))) : null,
                        (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ClassificationType) !== null && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ClassificationType) !== "None" && classificationTemplateConfig !== undefined && labelMode === "Classification" ? (React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.classification_label", defaultMessage: "Classification:" }))),
                            React.createElement(Col, { lg: 9 }, props.EditModeOn ? (classificationLabelsLoading === true ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (classificationLabels !== undefined && (React.createElement(FormDropdownSelect, { disabled: (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ClassificationType) !== undefined && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ClassificationType) !== null && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.ClassificationType) === "Enforced", list: classificationLabels
                                    .filter(function (cl) { return classificationTemplateConfig.some(function (ctc) { return ctc === cl.name; }); })
                                    .map(function (cl, slIndex) {
                                    return ({
                                        id: slIndex.toString(),
                                        title: cl.name,
                                        description: cl.description,
                                        customIcon: cl.icon,
                                        value: cl.name,
                                        object: cl
                                    });
                                }), defaultValue: getClassificationLabelObject() !== undefined ? getClassificationLabelObject().name : undefined, 
                                // defaultValue={getClass() !== null ? (getSensitivityLabel() as SensitivityLabel).Id : undefined }
                                onChange: function (dsi) { return setSelectedClassificationLabel(dsi.name); } })))) : classificationLabelsLoading === true ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (getClassificationLabelObject() !== undefined && (React.createElement(React.Fragment, null,
                                React.createElement("span", null,
                                    React.createElement("div", { className: "sensitivity-type" },
                                        React.createElement("i", { className: getClassificationLabelObject().icon !== undefined ? getClassificationLabelObject().icon : "la la-tag" }))),
                                React.createElement("span", null, (_e = getClassificationLabelObject()) === null || _e === void 0 ? void 0 : _e.name))))))) : null,
                        (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TeamTemplateSpecialization) !== null ? (React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.team_specialization_label", defaultMessage: "Specialization:" }))),
                            React.createElement(Col, { lg: 9 }, (_f = TeamSpecializations.find(function (s) { return s.Value === (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TeamTemplateSpecialization); })) === null || _f === void 0 ? void 0 : _f.Title))) : null,
                        (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TeamTemplateSpecialization) !== "educationClass" ? (React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.permissions_type_label", defaultMessage: "Permission Type:" }))),
                            React.createElement(Col, { lg: 9 }, props.EditModeOn ? (React.createElement(FormDropdownSelect, { list: permissionType, disabled: (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) !== null && (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.SensitivityLabelType) !== "None" && ((_g = getSensitivityLabel()) === null || _g === void 0 ? void 0 : _g.PermissionOverride) !== null, 
                                //defaultValue={props.currentTemplate.permissionType? props.currentTemplate.permissionType : TemplateTypes.TemplatePermissionType.Public}
                                value: currentRequest.PermissionType, onChange: function (value) { return dispatch(RequestCurrentStore.Update({ PermissionType: value })); } })) : (React.createElement(React.Fragment, null,
                                React.createElement("span", null, PermissionTypes.filter(function (pt) { return (pt.Value === currentRequest.PermissionType ? true : false); }).map(function (pt, ptindex) { return (React.createElement("div", { className: "ws-permission-type", key: ptindex },
                                    React.createElement(FontAwesomeIcon, { icon: pt.Icon }))); })),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.permissions_type.".concat((_h = currentRequest.PermissionType) === null || _h === void 0 ? void 0 : _h.toLowerCase(), "_label"), defaultMessage: currentRequest.PermissionType }))))))) : null,
                        (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.TemplateMultiGeoDataLocationSource) && currentRequest.RequestMultiGeoDataLocation ? (React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.geography_label", defaultMessage: "Geography:" }))),
                            React.createElement(Col, { lg: 9 },
                                React.createElement("span", null,
                                    currentRequest.RequestMultiGeoDataLocation,
                                    " (",
                                    SHAREPOINT_MULTIGEO_CODES[currentRequest.RequestMultiGeoDataLocation],
                                    ")")))) : null,
                        React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.requested_on_label", defaultMessage: "Requested On:" }))),
                            React.createElement(Col, { lg: 9 },
                                React.createElement("div", { className: "edit-mode-locked" },
                                    React.createElement("span", null, moment
                                        .utc(currentRequest.RequestedDate)
                                        .local()
                                        .format("llll"))))),
                        React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.description_label", defaultMessage: "Description:" }))),
                            React.createElement(Col, { lg: 9 }, props.EditModeOn ? (React.createElement(Form.Control, { as: "textarea", 
                                //placeholder="Tell people the purpose of this template"
                                rows: 3, defaultValue: currentRequest.Description, onChange: function (event) { return dispatch(RequestCurrentStore.Update({ Description: event.target.value })); } })) : (React.createElement("span", null, currentRequest.Description !== "" && currentRequest.Description !== null ? currentRequest.Description : "-- no description provided --")))),
                        currentRequest.Status === "Provisioned" || currentRequest.Status === "Archived" ? (React.createElement(Row, { className: "form-group form-group-xs" },
                            React.createElement(Col, { lg: 3 },
                                React.createElement("label", null,
                                    React.createElement(FormattedMessage, { id: "page.request_details.details.provisioned_link_label", defaultMessage: "Provisioned Link:" }))),
                            React.createElement(Col, { lg: 9 }, currentRequest.RequestType === "SharePoint" ? (React.createElement("a", { href: currentRequest.ProvisionedSitecollectionUrl, target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("span", { className: "provision-link" }, currentRequest.ProvisionedSitecollectionUrl))) : currentRequest.RequestType === "VivaEngageCommunity" ? (React.createElement("a", { href: currentRequest.ProvisionedVivaEngageCommunityUrl, target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("span", { className: "provision-link" }, currentRequest.ProvisionedVivaEngageCommunityUrl))) : (React.createElement(React.Fragment, null, currentRequest.ProvisionedGroupGuidId !== undefined ? (React.createElement(TeamLink, { teamGuid: currentRequest.ProvisionedGroupGuidId },
                                React.createElement("span", { className: "provision-link" }, currentRequest.Name))) : null))))) : null)),
                React.createElement("div", { className: "kt-widget__bottom" },
                    React.createElement("div", { className: "kt-widget__item" },
                        React.createElement("div", { className: "kt-widget__icon" },
                            React.createElement("i", { className: "flaticon-app" })),
                        React.createElement("div", { className: "kt-widget__details" },
                            React.createElement("span", { className: "kt-widget__title" },
                                React.createElement(FormattedMessage, { id: "page.request_details.details.features_label", defaultMessage: "Features" })),
                            React.createElement("span", { className: "kt-widget__value" },
                                React.createElement(FormattedNumber, { value: (_j = currentRequest.AssociatedFeatures) === null || _j === void 0 ? void 0 : _j.length })))),
                    React.createElement("div", { className: "kt-widget__item" },
                        React.createElement("div", { className: "kt-widget__icon" },
                            React.createElement("i", { className: "flaticon2-files-and-folders" })),
                        React.createElement("div", { className: "kt-widget__details" },
                            React.createElement("span", { className: "kt-widget__title" },
                                React.createElement(FormattedMessage, { id: "page.request_details.details.document_template_label", defaultMessage: "Document Templates" })),
                            React.createElement("span", { className: "kt-widget__value" },
                                React.createElement(FormattedNumber, { value: (_k = currentRequest.AssociatedDocumentTemplates) === null || _k === void 0 ? void 0 : _k.length })))),
                    React.createElement("div", { className: "kt-widget__item" },
                        React.createElement("div", { className: "kt-widget__icon" },
                            React.createElement("i", { className: "flaticon-signs-1" })),
                        React.createElement("div", { className: "kt-widget__details" },
                            React.createElement("span", { className: "kt-widget__title" },
                                React.createElement(FormattedMessage, { id: "page.request_details.details.channels_label", defaultMessage: "Channels" })),
                            React.createElement("span", { className: "kt-widget__value" }, props.ChannelCount === undefined ? (React.createElement(AnimatedLoader, { size: "xxsmall" })) : (React.createElement(React.Fragment, null,
                                React.createElement(FormattedNumber, { value: props.ChannelCount })))))),
                    React.createElement("div", { className: "kt-widget__item" },
                        React.createElement("div", { className: "kt-widget__icon" },
                            React.createElement("i", { className: "fa fa-user" })),
                        React.createElement("div", { className: "kt-widget__details" },
                            React.createElement("span", { className: "kt-widget__title" },
                                React.createElement(FormattedMessage, { id: "page.request_details.details.owners_label", defaultMessage: "Owners" })),
                            React.createElement("span", { className: "kt-widget__value" }, props.OwnerCount !== undefined ? React.createElement(FormattedNumber, { value: props.OwnerCount }) : React.createElement(FormattedNumber, { value: 0 })))),
                    React.createElement("div", { className: "kt-widget__item" },
                        React.createElement("div", { className: "kt-widget__icon" },
                            React.createElement("i", { className: "fa fa-user" })),
                        React.createElement("div", { className: "kt-widget__details" },
                            React.createElement("span", { className: "kt-widget__title" },
                                React.createElement(FormattedMessage, { id: "page.request_details.details.users_label", defaultMessage: "Users" })),
                            React.createElement("span", { className: "kt-widget__value" }, props.MemberCount !== undefined ? React.createElement(FormattedNumber, { value: props.MemberCount }) : React.createElement(FormattedNumber, { value: 0 })))))))));
});
export default RequestDetailsHeader;
