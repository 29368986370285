import * as React from "react";
import "./user-profile-picture.scss";
import UsersService from "app/services/users";
import getStringTwoLetterAcronym from "app/utils/getStringTwoLetterAcronym";
import FontIcon from "app/components/common/font-icon";
import AnimatedLoader from "app/components/common/animated-loader";
import { getRandomInt } from "app/utils/randomGen";
import orchLogo from "app/assets/img/icon-orchestry-xsmall.png";
import * as ProfileListStore from "app/store/profile-images/list";
import { useDispatch, useSelector } from "react-redux";
var UserProfilePicture = React.memo(function (props) {
    var _a = React.useState(null), base64Image = _a[0], setBase64Image = _a[1];
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    var dispatch = useDispatch();
    var profileImages = useSelector(function (state) { return state.profileImageListState.items; });
    var colors = ["kt-media--success", "kt-media--danger", "kt-media--warning", "kt-media--info", "kt-media--dark", "kt-media--brand"];
    var placeHolderClasses = "profile-picture-placeholder";
    if (props.size !== undefined) {
        placeHolderClasses = "kt-media kt-media--circle";
        placeHolderClasses += " kt-media--" + props.size;
        placeHolderClasses += " " + colors[getRandomInt(0, 5)];
    }
    // On first run, get the suggested users
    React.useEffect(function () {
        //console.log("UID", props.uid);
        var _a;
        var sessionKey = "profile_pic_48x48_" + props.uid;
        if (props.uid === "00000000-0000-0000-0000-000000000000") {
            setLoading(false);
            setBase64Image(orchLogo);
        }
        else {
            if (profileImages.some(function (ui) { return ui.id === props.uid && ui.base64image !== null; })) {
                setLoading(false);
                //console.log("key-match", sessionKey, LocalSessionService.Get(sessionKey) as string);
                setBase64Image((_a = profileImages.find(function (ui) { return ui.id === props.uid; })) === null || _a === void 0 ? void 0 : _a.base64image);
            }
            else {
                if (props.uid !== undefined) {
                    UsersService.getUserBase64Photo(props.uid).then(function (resultData) {
                        setLoading(false);
                        setBase64Image(resultData);
                        dispatch(ProfileListStore.AddUpdateItem({
                            id: props.uid,
                            base64image: resultData,
                            base64image48: null,
                            color: null
                        }));
                    });
                }
                else {
                    setLoading(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uid]);
    // if(loading && props.uid !== undefined){
    //    return (
    //          <AnimatedLoader size="xsmall" theme="dark" />
    //    )
    // } else if(base64Image !== ""){
    //    return (
    //       <img src={base64Image} alt={props.alt ? props.alt : ""} />
    //    )
    // } else if (props.displayName !== undefined) {
    //    return (
    //       <div className={placeHolderClasses}><span>{getStringTwoLetterAcronym(props.displayName)}</span></div>
    //    )
    // } else {
    //    return(<div className={"profile-picture-placeholder icon" + (props.size !== undefined ? "size-" + props.size: "")}><FontIcon iconString="user" /></div>)
    // }
    React.useEffect(function () {
        // console.log("base64Image set", props.uid, base64Image);
    }, [base64Image]);
    return loading && props.uid !== undefined ? (React.createElement(AnimatedLoader, { size: "xsmall", theme: "dark" })) : base64Image !== null && base64Image !== "" ? (React.createElement("img", { src: base64Image, alt: props.alt ? props.alt : "" })) : props.displayName !== undefined ? (React.createElement("div", { className: placeHolderClasses },
        React.createElement("span", null, getStringTwoLetterAcronym(props.displayName)))) : (React.createElement("div", { className: "profile-picture-placeholder icon" + (props.size !== undefined ? "size-" + props.size : "") },
        React.createElement(FontIcon, { iconString: "user" })));
});
export default UserProfilePicture;
