function mergeArrays(original, newdata, selector) {
    if (selector === void 0) { selector = "key"; }
    newdata.forEach(function (dat) {
        var foundIndex = original.findIndex(function (ori) { return ori[selector] === dat[selector]; });
        if (foundIndex >= 0)
            original.splice(foundIndex, 1, dat);
        else
            original.push(dat);
    });
    return original;
}
export default mergeArrays;
