var lineAwesomeHelper = /** @class */ (function () {
    function lineAwesomeHelper() {
    }
    lineAwesomeHelper.getTitleOfIcon = function (icon) {
        var tempIcon = icon.replace(" la-", "");
        tempIcon = tempIcon.replace("las", "");
        tempIcon = tempIcon.replace("lar", "");
        tempIcon = tempIcon.replace("lab", "");
        tempIcon = tempIcon.split("-").join(" ");
        return tempIcon;
    };
    lineAwesomeHelper.getIconClass = function (icon) {
        return icon;
    };
    lineAwesomeHelper.getAvailableIcons = [
        "las la-address-book",
        "las la-address-card",
        "las la-adjust",
        "las la-align-center",
        "las la-align-justify",
        "las la-align-left",
        "las la-align-right",
        "las la-allergies",
        "las la-ambulance",
        "las la-american-sign-language-interpreting",
        "las la-anchor",
        "las la-angle-double-down",
        "las la-angle-double-left",
        "las la-angle-double-right",
        "las la-angle-double-up",
        "las la-angle-down",
        "las la-angle-left",
        "las la-angle-right",
        "las la-angle-up",
        "las la-archive",
        "las la-arrow-alt-circle-down",
        "las la-arrow-alt-circle-left",
        "las la-arrow-alt-circle-right",
        "las la-arrow-alt-circle-up",
        "las la-arrow-circle-down",
        "las la-arrow-circle-left",
        "las la-arrow-circle-right",
        "las la-arrow-circle-up",
        "las la-arrow-down",
        "las la-arrow-left",
        "las la-arrow-right",
        "las la-arrow-up",
        "las la-arrows-alt",
        "las la-arrows-alt-h",
        "las la-arrows-alt-v",
        "las la-assistive-listening-systems",
        "las la-asterisk",
        "las la-at",
        "las la-audio-description",
        "las la-backward",
        "las la-balance-scale",
        "las la-ban",
        "las la-band-aid",
        "las la-barcode",
        "las la-bars",
        "las la-baseball-ball",
        "las la-basketball-ball",
        "las la-bath",
        "las la-battery-empty",
        "las la-battery-full",
        "las la-battery-half",
        "las la-battery-quarter",
        "las la-battery-three-quarters",
        "las la-bed",
        "las la-beer",
        "las la-bell",
        "las la-bell-slash",
        "las la-bicycle",
        "las la-binoculars",
        "las la-birthday-cake",
        "las la-blind",
        "las la-bold",
        "las la-bolt",
        "las la-bomb",
        "las la-book",
        "las la-bookmark",
        "las la-bowling-ball",
        "las la-box",
        "las la-box-open",
        "las la-boxes",
        "las la-braille",
        "las la-briefcase",
        "las la-briefcase-medical",
        "las la-bug",
        "las la-building",
        "las la-bullhorn",
        "las la-bullseye",
        "las la-burn",
        "las la-bus",
        "las la-calculator",
        "las la-calendar",
        "las la-calendar-alt",
        "las la-calendar-check",
        "las la-calendar-minus",
        "las la-calendar-plus",
        "las la-calendar-times",
        "las la-camera",
        "las la-camera-retro",
        "las la-capsules",
        "las la-car",
        "las la-caret-down",
        "las la-caret-left",
        "las la-caret-right",
        "las la-caret-square-down",
        "las la-caret-square-left",
        "las la-caret-square-right",
        "las la-caret-square-up",
        "las la-caret-up",
        "las la-cart-arrow-down",
        "las la-cart-plus",
        "las la-certificate",
        "las la-chart-area",
        "las la-chart-bar",
        "las la-chart-line",
        "las la-chart-pie",
        "las la-check",
        "las la-check-circle",
        "las la-check-square",
        "las la-chess",
        "las la-chess-bishop",
        "las la-chess-board",
        "las la-chess-king",
        "las la-chess-knight",
        "las la-chess-pawn",
        "las la-chess-queen",
        "las la-chess-rook",
        "las la-chevron-circle-down",
        "las la-chevron-circle-left",
        "las la-chevron-circle-right",
        "las la-chevron-circle-up",
        "las la-chevron-down",
        "las la-chevron-left",
        "las la-chevron-right",
        "las la-chevron-up",
        "las la-child",
        "las la-circle",
        "las la-circle-notch",
        "las la-clipboard",
        "las la-clipboard-check",
        "las la-clipboard-list",
        "las la-clock",
        "las la-clone",
        "las la-closed-captioning",
        "las la-cloud",
        "las la-cloud-download-alt",
        "las la-cloud-upload-alt",
        "las la-code",
        "las la-code-branch",
        "las la-coffee",
        "las la-cog",
        "las la-cogs",
        "las la-columns",
        "las la-comment",
        "las la-comment-alt",
        "las la-comment-dots",
        "las la-comment-slash",
        "las la-comments",
        "las la-compass",
        "las la-compress",
        "las la-copy",
        "las la-copyright",
        "las la-couch",
        "las la-credit-card",
        "las la-crop",
        "las la-crosshairs",
        "las la-cube",
        "las la-cubes",
        "las la-cut",
        "las la-database",
        "las la-deaf",
        "las la-desktop",
        "las la-diagnoses",
        "las la-dna",
        "las la-dollar-sign",
        "las la-dolly",
        "las la-dolly-flatbed",
        "las la-donate",
        "las la-dot-circle",
        "las la-dove",
        "las la-download",
        "las la-edit",
        "las la-eject",
        "las la-ellipsis-h",
        "las la-ellipsis-v",
        "las la-envelope",
        "las la-envelope-open",
        "las la-envelope-square",
        "las la-eraser",
        "las la-euro-sign",
        "las la-exchange-alt",
        "las la-exclamation",
        "las la-exclamation-circle",
        "las la-exclamation-triangle",
        "las la-expand",
        "las la-expand-arrows-alt",
        "las la-external-link-alt",
        "las la-external-link-square-alt",
        "las la-eye",
        "las la-eye-dropper",
        "las la-eye-slash",
        "las la-fast-backward",
        "las la-fast-forward",
        "las la-fax",
        "las la-female",
        "las la-fighter-jet",
        "las la-file",
        "las la-file-alt",
        "las la-file-archive",
        "las la-file-audio",
        "las la-file-code",
        "las la-file-excel",
        "las la-file-image",
        "las la-file-medical",
        "las la-file-medical-alt",
        "las la-file-pdf",
        "las la-file-powerpoint",
        "las la-file-video",
        "las la-file-word",
        "las la-film",
        "las la-filter",
        "las la-fire",
        "las la-fire-extinguisher",
        "las la-first-aid",
        "las la-flag",
        "las la-flag-checkered",
        "las la-flask",
        "las la-folder",
        "las la-folder-open",
        "las la-font",
        "las la-football-ball",
        "las la-forward",
        "las la-frown",
        "las la-futbol",
        "las la-gamepad",
        "las la-gavel",
        "las la-gem",
        "las la-genderless",
        "las la-gift",
        "las la-glass-martini",
        "las la-globe",
        "las la-golf-ball",
        "las la-graduation-cap",
        "las la-h-square",
        "las la-hand-holding",
        "las la-hand-holding-heart",
        "las la-hand-holding-usd",
        "las la-hand-lizard",
        "las la-hand-paper",
        "las la-hand-peace",
        "las la-hand-point-down",
        "las la-hand-point-left",
        "las la-hand-point-right",
        "las la-hand-point-up",
        "las la-hand-pointer",
        "las la-hand-rock",
        "las la-hand-scissors",
        "las la-hand-spock",
        "las la-hands",
        "las la-hands-helping",
        "las la-handshake",
        "las la-hashtag",
        "las la-hdd",
        "las la-heading",
        "las la-headphones",
        "las la-heart",
        "las la-heartbeat",
        "las la-history",
        "las la-hockey-puck",
        "las la-home",
        "las la-hospital",
        "las la-hospital-alt",
        "las la-hospital-symbol",
        "las la-hourglass",
        "las la-hourglass-end",
        "las la-hourglass-half",
        "las la-hourglass-start",
        "las la-i-cursor",
        "las la-id-badge",
        "las la-id-card",
        "las la-id-card-alt",
        "las la-image",
        "las la-images",
        "las la-inbox",
        "las la-indent",
        "las la-industry",
        "las la-info",
        "las la-info-circle",
        "las la-italic",
        "las la-key",
        "las la-keyboard",
        "las la-language",
        "las la-laptop",
        "las la-leaf",
        "las la-lemon",
        "las la-level-down-alt",
        "las la-level-up-alt",
        "las la-life-ring",
        "las la-lightbulb",
        "las la-link",
        "las la-lira-sign",
        "las la-list",
        "las la-list-alt",
        "las la-list-ol",
        "las la-list-ul",
        "las la-location-arrow",
        "las la-lock",
        "las la-lock-open",
        "las la-long-arrow-alt-down",
        "las la-long-arrow-alt-left",
        "las la-long-arrow-alt-right",
        "las la-long-arrow-alt-up",
        "las la-low-vision",
        "las la-magic",
        "las la-magnet",
        "las la-male",
        "las la-map",
        "las la-map-marker",
        "las la-map-marker-alt",
        "las la-map-pin",
        "las la-map-signs",
        "las la-mars",
        "las la-mars-double",
        "las la-mars-stroke",
        "las la-mars-stroke-h",
        "las la-mars-stroke-v",
        "las la-medkit",
        "las la-meh",
        "las la-mercury",
        "las la-microchip",
        "las la-microphone",
        "las la-microphone-slash",
        "las la-minus",
        "las la-minus-circle",
        "las la-minus-square",
        "las la-mobile",
        "las la-mobile-alt",
        "las la-money-bill-alt",
        "las la-moon",
        "las la-motorcycle",
        "las la-mouse-pointer",
        "las la-music",
        "las la-neuter",
        "las la-newspaper",
        "las la-notes-medical",
        "las la-object-group",
        "las la-object-ungroup",
        "las la-outdent",
        "las la-paint-brush",
        "las la-pallet",
        "las la-paper-plane",
        "las la-paperclip",
        "las la-parachute-box",
        "las la-paragraph",
        "las la-paste",
        "las la-pause",
        "las la-pause-circle",
        "las la-paw",
        "las la-pen-square",
        "las la-pencil-alt",
        "las la-people-carry",
        "las la-percent",
        "las la-phone",
        "las la-phone-slash",
        "las la-phone-square",
        "las la-phone-volume",
        "las la-piggy-bank",
        "las la-pills",
        "las la-plane",
        "las la-play",
        "las la-play-circle",
        "las la-plug",
        "las la-plus",
        "las la-plus-circle",
        "las la-plus-square",
        "las la-podcast",
        "las la-poo",
        "las la-pound-sign",
        "las la-power-off",
        "las la-prescription-bottle",
        "las la-prescription-bottle-alt",
        "las la-print",
        "las la-procedures",
        "las la-puzzle-piece",
        "las la-qrcode",
        "las la-question",
        "las la-question-circle",
        "las la-quidditch",
        "las la-quote-left",
        "las la-quote-right",
        "las la-random",
        "las la-recycle",
        "las la-redo",
        "las la-redo-alt",
        "las la-registered",
        "las la-reply",
        "las la-reply-all",
        "las la-retweet",
        "las la-ribbon",
        "las la-road",
        "las la-rocket",
        "las la-rss",
        "las la-rss-square",
        "las la-ruble-sign",
        "las la-rupee-sign",
        "las la-save",
        "las la-search",
        "las la-search-minus",
        "las la-search-plus",
        "las la-seedling",
        "las la-server",
        "las la-share",
        "las la-share-alt",
        "las la-share-alt-square",
        "las la-share-square",
        "las la-shekel-sign",
        "las la-shield-alt",
        "las la-ship",
        "las la-shipping-fast",
        "las la-shopping-bag",
        "las la-shopping-basket",
        "las la-shopping-cart",
        "las la-shower",
        "las la-sign",
        "las la-sign-in-alt",
        "las la-sign-language",
        "las la-sign-out-alt",
        "las la-signal",
        "las la-sitemap",
        "las la-sliders-h",
        "las la-smile",
        "las la-smoking",
        "las la-snowflake",
        "las la-sort",
        "las la-sort-alpha-down",
        "las la-sort-alpha-up",
        "las la-sort-amount-down",
        "las la-sort-amount-up",
        "las la-sort-down",
        "las la-sort-numeric-down",
        "las la-sort-numeric-up",
        "las la-sort-up",
        "las la-space-shuttle",
        "las la-spinner",
        "las la-square",
        "las la-square-full",
        "las la-star",
        "las la-star-half",
        "las la-step-backward",
        "las la-step-forward",
        "las la-stethoscope",
        "las la-sticky-note",
        "las la-stop",
        "las la-stop-circle",
        "las la-stopwatch",
        "las la-street-view",
        "las la-strikethrough",
        "las la-subscript",
        "las la-subway",
        "las la-suitcase",
        "las la-sun",
        "las la-superscript",
        "las la-sync",
        "las la-sync-alt",
        "las la-syringe",
        "las la-table",
        "las la-table-tennis",
        "las la-tablet",
        "las la-tablet-alt",
        "las la-tablets",
        "las la-tachometer-alt",
        "las la-tag",
        "las la-tags",
        "las la-tape",
        "las la-tasks",
        "las la-taxi",
        "las la-terminal",
        "las la-text-height",
        "las la-text-width",
        "las la-th",
        "las la-th-large",
        "las la-th-list",
        "las la-thermometer",
        "las la-thermometer-empty",
        "las la-thermometer-full",
        "las la-thermometer-half",
        "las la-thermometer-quarter",
        "las la-thermometer-three-quarters",
        "las la-thumbs-down",
        "las la-thumbs-up",
        "las la-thumbtack",
        "las la-ticket-alt",
        "las la-times",
        "las la-times-circle",
        "las la-tint",
        "las la-toggle-off",
        "las la-toggle-on",
        "las la-trademark",
        "las la-train",
        "las la-transgender",
        "las la-transgender-alt",
        "las la-trash",
        "las la-trash-alt",
        "las la-tree",
        "las la-trophy",
        "las la-truck",
        "las la-truck-loading",
        "las la-truck-moving",
        "las la-tty",
        "las la-tv",
        "las la-umbrella",
        "las la-underline",
        "las la-undo",
        "las la-undo-alt",
        "las la-universal-access",
        "las la-university",
        "las la-unlink",
        "las la-unlock",
        "las la-unlock-alt",
        "las la-upload",
        "las la-user",
        "las la-user-circle",
        "las la-user-md",
        "las la-user-plus",
        "las la-user-secret",
        "las la-user-times",
        "las la-users",
        "las la-utensil-spoon",
        "las la-utensils",
        "las la-venus",
        "las la-venus-double",
        "las la-venus-mars",
        "las la-vial",
        "las la-vials",
        "las la-video",
        "las la-video-slash",
        "las la-volleyball-ball",
        "las la-volume-down",
        "las la-volume-off",
        "las la-volume-up",
        "las la-warehouse",
        "las la-weight",
        "las la-wheelchair",
        "las la-wifi",
        "las la-window-close",
        "las la-window-maximize",
        "las la-window-minimize",
        "las la-window-restore",
        "las la-wine-glass",
        "las la-won-sign",
        "las la-wrench",
        "las la-x-ray",
        "las la-yen-sign",
        "lar la-address-book",
        "lar la-address-card",
        "lar la-arrow-alt-circle-down",
        "lar la-arrow-alt-circle-left",
        "lar la-arrow-alt-circle-right",
        "lar la-arrow-alt-circle-up",
        "lar la-bell",
        "lar la-bell-slash",
        "lar la-bookmark",
        "lar la-building",
        "lar la-calendar",
        "lar la-calendar-alt",
        "lar la-calendar-check",
        "lar la-calendar-minus",
        "lar la-calendar-plus",
        "lar la-calendar-times",
        "lar la-caret-square-down",
        "lar la-caret-square-left",
        "lar la-caret-square-right",
        "lar la-caret-square-up",
        "lar la-chart-bar",
        "lar la-check-circle",
        "lar la-check-square",
        "lar la-circle",
        "lar la-clipboard",
        "lar la-clock",
        "lar la-clone",
        "lar la-closed-captioning",
        "lar la-comment",
        "lar la-comment-alt",
        "lar la-comments",
        "lar la-compass",
        "lar la-copy",
        "lar la-copyright",
        "lar la-credit-card",
        "lar la-dot-circle",
        "lar la-edit",
        "lar la-envelope",
        "lar la-envelope-open",
        "lar la-eye-slash",
        "lar la-file",
        "lar la-file-alt",
        "lar la-file-archive",
        "lar la-file-audio",
        "lar la-file-code",
        "lar la-file-excel",
        "lar la-file-image",
        "lar la-file-pdf",
        "lar la-file-powerpoint",
        "lar la-file-video",
        "lar la-file-word",
        "lar la-flag",
        "lar la-folder",
        "lar la-folder-open",
        "lar la-frown",
        "lar la-futbol",
        "lar la-gem",
        "lar la-hand-lizard",
        "lar la-hand-paper",
        "lar la-hand-peace",
        "lar la-hand-point-down",
        "lar la-hand-point-left",
        "lar la-hand-point-right",
        "lar la-hand-point-up",
        "lar la-hand-pointer",
        "lar la-hand-rock",
        "lar la-hand-scissors",
        "lar la-hand-spock",
        "lar la-handshake",
        "lar la-hdd",
        "lar la-heart",
        "lar la-hospital",
        "lar la-hourglass",
        "lar la-id-badge",
        "lar la-id-card",
        "lar la-image",
        "lar la-images",
        "lar la-keyboard",
        "lar la-lemon",
        "lar la-life-ring",
        "lar la-lightbulb",
        "lar la-list-alt",
        "lar la-map",
        "lar la-meh",
        "lar la-minus-square",
        "lar la-money-bill-alt",
        "lar la-moon",
        "lar la-newspaper",
        "lar la-object-group",
        "lar la-object-ungroup",
        "lar la-paper-plane",
        "lar la-pause-circle",
        "lar la-play-circle",
        "lar la-plus-square",
        "lar la-question-circle",
        "lar la-registered",
        "lar la-save",
        "lar la-share-square",
        "lar la-smile",
        "lar la-snowflake",
        "lar la-square",
        "lar la-star",
        "lar la-star-half",
        "lar la-sticky-note",
        "lar la-stop-circle",
        "lar la-sun",
        "lar la-thumbs-down",
        "lar la-thumbs-up",
        "lar la-times-circle",
        "lar la-trash-alt",
        "lar la-user",
        "lar la-user-circle",
        "lar la-window-close",
        "lar la-window-maximize",
        "lar la-window-minimize",
        "lar la-window-restore",
        "lab la-500px",
        "lab la-accessible-icon",
        "lab la-accusoft",
        "lab la-adn",
        "lab la-adversal",
        "lab la-affiliatetheme",
        "lab la-algolia",
        "lab la-amazon",
        "lab la-amazon-pay",
        "lab la-amilia",
        "lab la-android",
        "lab la-angellist",
        "lab la-angrycreative",
        "lab la-angular",
        "lab la-app-store",
        "lab la-app-store-ios",
        "lab la-apper",
        "lab la-apple",
        "lab la-apple-pay",
        "lab la-asymmetrik",
        "lab la-audible",
        "lab la-autoprefixer",
        "lab la-avianex",
        "lab la-aviato",
        "lab la-aws",
        "lab la-bandcamp",
        "lab la-behance",
        "lab la-behance-square",
        "lab la-bimobject",
        "lab la-bitbucket",
        "lab la-bitcoin",
        "lab la-bity",
        "lab la-black-tie",
        "lab la-blackberry",
        "lab la-blogger",
        "lab la-blogger-b",
        "lab la-bluetooth",
        "lab la-bluetooth-b",
        "lab la-btc",
        "lab la-buromobelexperte",
        "lab la-buysellads",
        "lab la-cc-amazon-pay",
        "lab la-cc-amex",
        "lab la-cc-apple-pay",
        "lab la-cc-diners-club",
        "lab la-cc-discover",
        "lab la-cc-jcb",
        "lab la-cc-mastercard",
        "lab la-cc-paypal",
        "lab la-cc-stripe",
        "lab la-cc-visa",
        "lab la-centercode",
        "lab la-chrome",
        "lab la-cloudscale",
        "lab la-cloudsmith",
        "lab la-cloudversify",
        "lab la-codepen",
        "lab la-codiepie",
        "lab la-connectdevelop",
        "lab la-contao",
        "lab la-cpanel",
        "lab la-creative-commons",
        "lab la-css3",
        "lab la-css3-alt",
        "lab la-cuttlefish",
        "lab la-d-and-d",
        "lab la-dashcube",
        "lab la-delicious",
        "lab la-deploydog",
        "lab la-deskpro",
        "lab la-deviantart",
        "lab la-digg",
        "lab la-digital-ocean",
        "lab la-discord",
        "lab la-discourse",
        "lab la-dochub",
        "lab la-docker",
        "lab la-draft2digital",
        "lab la-dribbble",
        "lab la-dribbble-square",
        "lab la-dropbox",
        "lab la-drupal",
        "lab la-dyalog",
        "lab la-earlybirds",
        "lab la-edge",
        "lab la-elementor",
        "lab la-ember",
        "lab la-empire",
        "lab la-envira",
        "lab la-erlang",
        "lab la-ethereum",
        "lab la-etsy",
        "lab la-expeditedssl",
        "lab la-facebook",
        "lab la-facebook-f",
        "lab la-facebook-messenger",
        "lab la-facebook-square",
        "lab la-firefox",
        "lab la-first-order",
        "lab la-firstdraft",
        "lab la-flickr",
        "lab la-flipboard",
        "lab la-fly",
        "lab la-font-awesome",
        "lab la-font-awesome-alt",
        "lab la-font-awesome-flag",
        "lab la-fonticons",
        "lab la-fonticons-fi",
        "lab la-fort-awesome",
        "lab la-fort-awesome-alt",
        "lab la-forumbee",
        "lab la-foursquare",
        "lab la-free-code-camp",
        "lab la-freebsd",
        "lab la-get-pocket",
        "lab la-gg",
        "lab la-gg-circle",
        "lab la-git",
        "lab la-git-square",
        "lab la-github",
        "lab la-github-alt",
        "lab la-github-square",
        "lab la-gitkraken",
        "lab la-gitlab",
        "lab la-gitter",
        "lab la-glide",
        "lab la-glide-g",
        "lab la-gofore",
        "lab la-goodreads",
        "lab la-goodreads-g",
        "lab la-google",
        "lab la-google-drive",
        "lab la-google-play",
        "lab la-google-plus",
        "lab la-google-plus-g",
        "lab la-google-plus-square",
        "lab la-google-wallet",
        "lab la-gratipay",
        "lab la-grav",
        "lab la-gripfire",
        "lab la-grunt",
        "lab la-gulp",
        "lab la-hacker-news",
        "lab la-hacker-news-square",
        "lab la-hips",
        "lab la-hire-a-helper",
        "lab la-hooli",
        "lab la-hotjar",
        "lab la-houzz",
        "lab la-html5",
        "lab la-hubspot",
        "lab la-imdb",
        "lab la-instagram",
        "lab la-internet-explorer",
        "lab la-ioxhost",
        "lab la-itunes",
        "lab la-itunes-note",
        "lab la-jenkins",
        "lab la-joget",
        "lab la-joomla",
        "lab la-js",
        "lab la-js-square",
        "lab la-jsfiddle",
        "lab la-keycdn",
        "lab la-kickstarter",
        "lab la-kickstarter-k",
        "lab la-korvue",
        "lab la-laravel",
        "lab la-lastfm",
        "lab la-lastfm-square",
        "lab la-leanpub",
        "lab la-less",
        "lab la-line",
        "lab la-linkedin",
        "lab la-linkedin-in",
        "lab la-linode",
        "lab la-linux",
        "lab la-lyft",
        "lab la-magento",
        "lab la-maxcdn",
        "lab la-medapps",
        "lab la-medium",
        "lab la-medium-m",
        "lab la-medrt",
        "lab la-meetup",
        "lab la-microsoft",
        "lab la-mix",
        "lab la-mixcloud",
        "lab la-mizuni",
        "lab la-modx",
        "lab la-monero",
        "lab la-napster",
        "lab la-nintendo-switch",
        "lab la-node",
        "lab la-node-js",
        "lab la-npm",
        "lab la-ns8",
        "lab la-nutritionix",
        "lab la-odnoklassniki",
        "lab la-odnoklassniki-square",
        "lab la-opencart",
        "lab la-openid",
        "lab la-opera",
        "lab la-optin-monster",
        "lab la-osi",
        "lab la-page4",
        "lab la-pagelines",
        "lab la-palfed",
        "lab la-patreon",
        "lab la-paypal",
        "lab la-periscope",
        "lab la-phabricator",
        "lab la-phoenix-framework",
        "lab la-php",
        "lab la-pied-piper",
        "lab la-pied-piper-alt",
        "lab la-pied-piper-pp",
        "lab la-pinterest",
        "lab la-pinterest-p",
        "lab la-pinterest-square",
        "lab la-playstation",
        "lab la-product-hunt",
        "lab la-pushed",
        "lab la-python",
        "lab la-qq",
        "lab la-quinscape",
        "lab la-quora",
        "lab la-ravelry",
        "lab la-react",
        "lab la-readme",
        "lab la-rebel",
        "lab la-red-river",
        "lab la-reddit",
        "lab la-reddit-alien",
        "lab la-reddit-square",
        "lab la-rendact",
        "lab la-renren",
        "lab la-replyd",
        "lab la-resolving",
        "lab la-rocketchat",
        "lab la-rockrms",
        "lab la-safari",
        "lab la-sass",
        "lab la-schlix",
        "lab la-scribd",
        "lab la-searchengin",
        "lab la-sellcast",
        "lab la-sellsy",
        "lab la-servicestack",
        "lab la-shirtsinbulk",
        "lab la-simplybuilt",
        "lab la-sistrix",
        "lab la-skyatlas",
        "lab la-skype",
        "lab la-slack",
        "lab la-slack-hash",
        "lab la-slideshare",
        "lab la-snapchat",
        "lab la-snapchat-ghost",
        "lab la-snapchat-square",
        "lab la-soundcloud",
        "lab la-speakap",
        "lab la-spotify",
        "lab la-stack-exchange",
        "lab la-stack-overflow",
        "lab la-staylinked",
        "lab la-steam",
        "lab la-steam-square",
        "lab la-steam-symbol",
        "lab la-sticker-mule",
        "lab la-strava",
        "lab la-stripe",
        "lab la-stripe-s",
        "lab la-studiovinari",
        "lab la-stumbleupon",
        "lab la-stumbleupon-circle",
        "lab la-superpowers",
        "lab la-supple",
        "lab la-telegram",
        "lab la-telegram-plane",
        "lab la-tencent-weibo",
        "lab la-themeisle",
        "lab la-trello",
        "lab la-tripadvisor",
        "lab la-tumblr",
        "lab la-tumblr-square",
        "lab la-twitch",
        "lab la-twitter",
        "lab la-twitter-square",
        "lab la-typo3",
        "lab la-uber",
        "lab la-uikit",
        "lab la-uniregistry",
        "lab la-untappd",
        "lab la-usb",
        "lab la-ussunnah",
        "lab la-vaadin",
        "lab la-viacoin",
        "lab la-viadeo",
        "lab la-viadeo-square",
        "lab la-viber",
        "lab la-vimeo",
        "lab la-vimeo-square",
        "lab la-vimeo-v",
        "lab la-vine",
        "lab la-vk",
        "lab la-vnv",
        "lab la-vuejs",
        "lab la-weibo",
        "lab la-weixin",
        "lab la-whatsapp",
        "lab la-whatsapp-square",
        "lab la-whmcs",
        "lab la-wikipedia-w",
        "lab la-windows",
        "lab la-wordpress",
        "lab la-wordpress-simple",
        "lab la-wpbeginner",
        "lab la-wpexplorer",
        "lab la-wpforms",
        "lab la-xbox",
        "lab la-xing",
        "lab la-xing-square",
        "lab la-y-combinator",
        "lab la-yahoo",
        "lab la-yandex",
        "lab la-yandex-international",
        "lab la-yelp",
        "lab la-yoast",
        "lab la-youtube",
        "lab la-youtube-square"
    ];
    lineAwesomeHelper.getIconOptionsForDropDown = function () {
        //let optionList:Option = [];
        //console.log("Icon LIst", library["definitions"].fas);
        var optionList = [];
        lineAwesomeHelper.getAvailableIcons.forEach(function (i, index) {
            optionList.push({
                id: String(index),
                title: lineAwesomeHelper.getTitleOfIcon(i),
                value: lineAwesomeHelper.getIconClass(i),
                laIcon: lineAwesomeHelper.getIconClass(i)
            });
        });
        //console.log("font-match", optionList);
        return optionList;
    };
    return lineAwesomeHelper;
}());
export default lineAwesomeHelper;
