import PrincipalItem from "app/components/common/principal-item";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import UsersService from "app/services/users";
import * as React from "react";
import PrincipalItemNotFound from "../principal-item-not-found";
var PrincipalItemDynamic = React.memo(function (_a) {
    var principalIdOrEmail = _a.principalIdOrEmail, showBorder = _a.showBorder, size = _a.size, hideGuestNote = _a.hideGuestNote, onRemove = _a.onRemove, showBadge = _a.showBadge;
    var _b = React.useState(), user = _b[0], setUser = _b[1];
    var orchestryUserIds = React.useState([
        "95323290-fd89-4b03-9ac7-e5a563c8ecc1",
        "57d856dc-04b0-4737-b18d-ff089c99732b",
        "2c52f9db-a29f-4394-9b45-2867b387b48e",
        "6b88b0b8-c153-41e8-8aee-cac4c76740d3",
        "9e0d10ea-21a3-4a6c-af10-38b5169a5ee0",
        "1dfeb9c8-dc8f-477b-b1b5-92a7ce794732",
        "00000000-0000-0000-0000-000000000000" // All Environments
    ])[0];
    React.useEffect(function () {
        if (orchestryUserIds.some(function (id) { return id === principalIdOrEmail; })) {
            setUser({
                GUID: principalIdOrEmail,
                Id: principalIdOrEmail,
                Type: "User",
                Company: "",
                CountryOrRegion: "",
                Department: "",
                DisplayName: "Orchestry App",
                GivenName: "Orchestry App",
                JobTitle: "",
                Office: "",
                StateOrProvince: "",
                Surname: "",
                UserPrincipalName: ""
            });
        }
        else {
            UsersService.getUserByID(principalIdOrEmail)
                .then(function (user) {
                //console.log("Principal", principalIdOrEmail, user);
                setUser(user);
                //console.log("UsersService cachedUsers", UsersService.cachedUsers);
            })
                .catch(function (error) {
                //console.log("Principal", principalIdOrEmail, user);
                setUser(null);
                //console.log("UsersService cachedUsers error", error);
            });
        }
    }, [principalIdOrEmail]);
    return user === undefined ? (React.createElement(React.Fragment, null,
        React.createElement(SkeletonLoaderFakeBox, null))) : user === null ? (React.createElement(PrincipalItemNotFound, { showBorder: showBorder, size: size })) : (React.createElement(PrincipalItem, { principal: user, showBorder: showBorder, size: size, hideGuestNote: hideGuestNote, onRemove: onRemove, showBadge: showBadge }));
});
export default PrincipalItemDynamic;
