import { Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { BenchmarksSection } from "./sections/benchmarks-section/benchmarks-section.component";
import { LeaderboardSection } from "./sections/leaderboard-section/leaderboard-section.component";
import { OrchestryUsageSection } from "./sections/orchestry-usage-section/orchestry-usage-section.component";
import { RecommendationsSection } from "./sections/recommendations-section/recommendations-section.component";
import { BeaconSavingsSection } from "./sections/savings-section/beacon-savings-section.component";
import { PaywalledBeaconSavingsSection } from "./sections/savings-section/paywalled-beacon-savings-section.component";
import { SavingsSection } from "./sections/savings-section/savings-section.component";
import { SharingLinksCreatedSection } from "./sections/sharing-links-created-section/sharing-links-created-section.component";
import { StorageAnalysisSection } from "./sections/storage-analysis-section/storage-analysis-section.component";
import { TeamEngagementSection } from "./sections/team-engagement-section/team-engagement-section.component";
import { WorkspacesSection } from "./sections/workspaces-section/workspaces-section.component";
import { useHealthCheckActivity } from "./use-health-check-activity.hook";
var MINUMUM_TOTAL_HOURS_FOR_SAVINGS = 3;
export var HealthCheckActivityTab = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, showBeaconPaywall = _a.showBeaconPaywall, showBeaconSavings = _a.showBeaconSavings, hasOrchestryUsage = _a.hasOrchestryUsage, isLoading = _a.isLoading, hasLeaderboard = _a.hasLeaderboard, shouldRenderSubtext = _a.shouldRenderSubtext, totalAnnotationCount = _a.totalAnnotationCount, isAnnotationsLoading = _a.isAnnotationsLoading, _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.MetricsData, _e = _d === void 0 ? {} : _d, _f = _e.YourSavings, _g = _f === void 0 ? {} : _f, TotalHours = _g.TotalHours;
    return (React.createElement(Stack, { gap: 0 },
        shouldRenderSubtext && (React.createElement(Group, { justify: "space-between", mt: 24 },
            React.createElement(OrchestryText, { skeletonWidth: 250, isLoading: isLoading, c: "var(--mantine-color-text-secondary-0)", size: "14px", fw: 400, lh: "20px" }, intl.formatMessage({
                id: "page.health_check.description",
                defaultMessage: "The Orchestry Tenant Health Check provides a holistic overview of key Microsoft 365 metrics reflecting the health of your environment."
            })),
            React.createElement(Group, { gap: 8 },
                React.createElement(OrchestryFontIcon, { isLoading: isAnnotationsLoading, iconClassName: ORCH_ICONS.messageNotificationCircle, color: "var(--mantine-color-error-7)", fontSize: 24, lineHeight: "24px" }),
                React.createElement(OrchestryText, { skeletonWidth: 300, isLoading: isAnnotationsLoading, c: "var(--mantine-color-error-7)", size: "14px", fw: 500, lh: "38px" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "pages.health_check.annotation_count", defaultMessage: "{count} Annotations in this Health Check" }, { count: totalAnnotationCount }))))),
        hasOrchestryUsage && React.createElement(OrchestryUsageSection, null),
        React.createElement(Stack, { gap: "48px" },
            React.createElement(WorkspacesSection, { hasOrchestryUsage: hasOrchestryUsage }),
            React.createElement(BenchmarksSection, null),
            React.createElement(SharingLinksCreatedSection, null),
            React.createElement(StorageAnalysisSection, null),
            React.createElement(TeamEngagementSection, null),
            hasLeaderboard && React.createElement(LeaderboardSection, null),
            React.createElement(RecommendationsSection, null),
            !!TotalHours &&
                TotalHours >= MINUMUM_TOTAL_HOURS_FOR_SAVINGS &&
                (showBeaconPaywall ? React.createElement(PaywalledBeaconSavingsSection, null) : showBeaconSavings ? React.createElement(BeaconSavingsSection, null) : React.createElement(SavingsSection, null)))));
};
